<template>
  <div>
    <span class="circle" :style="`background-color: ${object.color}`"></span>{{ object.translatedTitle }}
  </div>
</template>

<script>
export default {
  name: "CRMDatatableTDCRMStageTitle",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">
  .circle {
    margin-right: 12px;
  }
</style>
