<template>
  <div>
    <b-row class="pt-sm-3 pt-1 align-items-center">
      <b-col sm="auto"
             cols="12">
        <div class="patient-search-bar form-group">
          <input type="text"
                 class="form-control"
                 v-model="search"
                 :placeholder="$t('patient_search')"/>
        </div>
      </b-col>
      <b-col sm="auto" cols="6">
        <div class="form-group">
          <div class="row no-gutters align-items-center">
            <div class="col-auto text-muted">
              {{ $t('status') }}:
            </div>
            <div class="col">
              <SelectGroup v-model="status"
                           class="mb-0 sm ml-2"
                           :label="$t('status')"
                           :showlabel="false"
                           :options="taskStatuses"
                           variant="white"/>
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="auto" cols="6">
        <CheckboxGroup :label="$t('overdue')"
                       fid="overdue"
                       @input="overdueClicked"
                       v-model="overdue"/>
      </b-col>
      <b-col sm="auto" cols="6">
        <CheckboxGroup :label="$t('my_tasks')"
                       fid="my_tasks"
                       @input="tasksClicked"
                       v-model="myTasks"/>

      </b-col>
      <b-col sm="auto" cols="6">
        <CheckboxGroup :label="$t('all_days')"
                       fid="all_days"
                       @input="allDaysClicked"
                       v-model="allDays"/>

      </b-col>
    </b-row>
    <DataTable :options="options"
               class="mt-4"
               ref="dataTable"
               :wiAuto="true"
               v-if="initiated"
               @checkboxSelected="selectedChange"
               :params="{ date: selectedDay }"
               module="crm">
      <template #bottom-bar>
        <div>
          <b-dropdown-item v-if="rights.includes('crmtask_delete')"
                           @click="deleteSelected">
            {{ $t('delete') }}
          </b-dropdown-item>
          <b-dropdown-item @click="markSelectedAsFinished">
            {{ $t('mark_as_finished') }}
          </b-dropdown-item>
        </div>
      </template>
    </DataTable>
    <PatientSMSModal/>
    <LeadShowModal/>
  </div>
</template>

<script>
import { mapState } from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/crm/task"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import moment from "moment/moment"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { CalendarService, EloquentService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { taskStatuses } from "@/dictionaries/dictionary"
import LeadShowModal from "@/components/parts/crm/LeadShowModal.vue"

export default {
  name: "CRMTasks",
  components: {
    LeadShowModal,
    SelectGroup,
    CheckboxGroup,
    PatientSMSModal,
    DataTable,
  },
  head() {
    return {
      title: this.$t('tasks_and_calls'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
    }),
    selectedDay() {
      if(this.allDays) return null
      return this.date
    }
  },
  data() {
    return {
      taskStatuses,
      options,
      date: moment().format('YYYY-MM-DD'),
      search: '',
      status: null,
      overdue: false,
      myTasks: false,
      allDays: false,
      initiated: false,
      timer: null,
      selected: []
    }
  },
  methods: {
    daySelected(val) {
      this.date = val
      this.$refs.dataTable.filterChange({ id: 'date_from' }, 'delete')
      this.$refs.dataTable.filterChange({ id: 'date_to' }, 'delete')
      this.$nextTick(() => {
        this.$refs.dataTable.load()
      })
    },
    async overdueClicked(val) {
      this.date = val ? null : moment().format('YYYY-MM-DD')
      this.$emit('nullifyDay', !val)
      const filter = {
        id: 'overdue',
        title: 'overdue',
        condition: '1',
        type: 'equals'
      }
      this.$refs.dataTable?.filterChange(filter, val ? 'add' : 'delete')
    },
    tasksClicked(val) {
      const filter = {
        id: 'my_tasks',
        title: 'my_tasks',
        condition: '1',
        type: 'equals'
      }
      this.$refs.dataTable?.filterChange(filter, val ? 'add' : 'delete')
    },
    allDaysClicked(val) {
      const queryParams = { ...this.$route.query }
      if(val) {
        queryParams.all = '1'
      } else {
        delete queryParams.all
      }
      this.$router.replace({ query: queryParams })
      this.$emit('nullifyDay', !val)
      this.$nextTick(() => {
        this.$refs.dataTable.load()
      })
    },
    selectedChange(selected) {
      this.selected = selected
    },
    async deleteSelected() {
      const answer = confirm(this.$t('are_you_sure_you_want_to_delete_task'))
      if(!answer) return
      const res = await EloquentService.deleteBulk('task', this.selected)
      if(res.data) {
        this.$refs.dataTable.cancelSelection()
        this.$refs.dataTable.load()
      }
    },
    async markSelectedAsFinished() {
      const res = await CalendarService.finishTaskBulk(this.selected)
      if(res.data) {
        this.$refs.dataTable.cancelSelection()
        this.$refs.dataTable.load()
      }
    },
    async loadFromQuery() {
      const initialTaskId = this.$route?.query?.id || null
      if(!initialTaskId) return
      const task = await EloquentService.show('task', initialTaskId)
      if(!task?.data) return
      this.$store.commit('setTask', this.copyObject(task.data))
      this.$bvModal.show('task-show-modal')
    }
  },
  async created() {
    let filters = this.$route.query.filters
    if(filters) {
      filters = JSON.parse(filters)
      if(filters['my_tasks'] && filters['my_tasks'].condition == '1') this.myTasks = true
      if(filters['overdue'] && filters['overdue'].condition == '1') {
        this.overdue = true
        await this.$nextTick()
        await this.overdueClicked(true)
      }
      if(filters['status']) this.status = filters['status'].condition
    }
    this.initiated = true
    if(this.$route.query.all === '1') {
      this.allDays = true
      this.$emit('nullifyDay', false)
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.loadFromQuery()
  },
  watch: {
    search(val) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let datafilter = this.$refs.dataTable.$refs.datatablefilter
        if(val) {
          datafilter.filters.patient_id = {
            "id": "patient_id",
            "title": "patient",
            "condition": val,
            "type": "text",
            "ctype": "like"
          }
        } else {
          delete datafilter.filters.patient_id
        }
        datafilter.refresh()
      }, 600)
    },
    status(val) {
      if(val == '-') val = ''
      const filter = { id: 'status', title: 'status', condition: val, type: 'equals'}
      this.$refs.dataTable?.filterChange(filter, val ? 'add' : 'delete', val)
    }
  }
}
</script>

<style scoped lang="scss">
.patient-search-bar {
  input {
    border: none;
    background: #E0E7EE;
    border-radius: 25px;
    max-width: 250px;
    height: 33px;
    padding-left: 25px;
  }
}
@media(max-width: 768px) {
  .patient-search-bar {
    input {
      width: 100%;
      max-width: none;
    }
  }
}
</style>
