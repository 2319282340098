<template>
  <b-modal id="anketa-modal" size="xl" :title="$t('questionnaire')" centered>
    <div class="mb-4" v-if="patient">{{ patient.full_name }}</div>
    <b-row class="no-print row-narrow">
      <b-col>
        <input type="text" :placeholder="$t('add_question')"
               class="form-control"
               v-model="newQuestionText"/>
      </b-col>
      <b-col cols="auto">
        <button @click="addQuestion"
                :disabled="newQuestionText.length === 0"
                :loading="loading"
                class="btn-themed btn-themed-squared btn-themed-outline h-100">
          {{ $t('add') }}
        </button>
      </b-col>
    </b-row>
<!--    <div class="py-5 text-center" v-if="loading">-->
<!--      <b-spinner label="Spinning"></b-spinner>-->
<!--    </div>-->
    <div class="mt-5">
      <div v-for="(a, aind) in anketa" :key="`anketa-id-${a.id}`">
        <div v-if="aind === 0 || (a.category !== anketa[(aind - 1)].category)" class="mt-3 mb-2">
          <h4 class="font-weight-bold text-center">{{ $t(anketaDictionary[a.category]) }}</h4>
        </div>
        <b-row>
          <b-col md cols="12">
            <div class="question-title">
              {{ a.name }}
              <a class="pointer delete-trash" @click="deleteQuestion(a.id)">
                <TrashSvg/>
              </a>
            </div>
          </b-col>
          <b-col md="auto" cols="12" class="mt-md-0 mt-2">
            <div class="radio-inline">
              <RadioGroup :options="responses"
                          v-model="a.response"
                          :validationId="`response-${a.id}`"/>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons  no-print">
        <b-button class="cancel mr-md-3 mr-2 d-inline-block align-middle" variant="themed" @click="close()">{{ $t('cancel') }}</b-button>
        <button @click="print" class="btn-themed align-middle btn-themed-squared mr-md-3 mr-2">{{ $t('print_noun') }}</button>
        <button @click="printEmpty" class="d-none d-md-inline btn-themed align-middle btn-themed-squared mw-auto w-auto px-3 mr-md-3 mr-2">{{ $t('print_empty') }}</button>
        <button :disabled="loading" @click="save" class="btn-themed align-middle btn-themed-squared">{{ $t('save') }}</button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { PatientService, EloquentService } from "@/services/api.service"
import { mapState } from "vuex"
import RadioGroup from "@/components/form/RadioGroup"
import TrashSvg from '@/assets/svg-vue/general/trash.svg'
import {anketaCategories} from "@/dictionaries/dictionary"
import JwtService from "@/services/jwt.service"

export default {
  name: "PatientAnketaModal",
  components: {
    RadioGroup,
    TrashSvg
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      loading: state => state.dom.loading,
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      anketa: [],
      newQuestionText: '',
      anketaDictionary: anketaCategories,
      responses: [
        { id: '1', title: 'yes' },
        { id: '2', title: 'no' },
        { id: '0', title: 'unknown' },
      ]
    }
  },
  methods: {
    getAnketa() {
      PatientService.getClientAnketa(this.patient.id).then(res => {
        this.anketa = res.data
      })
    },
    save() {
      PatientService.saveClientAnketa(this.patient.id, { anketa: this.anketa }).then(res => {
        this.anketa = res.data
        this.$bvModal.hide('anketa-modal')
      })
    },
    addQuestion() {
      let formData = new FormData()
      formData.append('name', this.newQuestionText)
      EloquentService.create('anketa_question', formData).then(() => this.getAnketa())
    },
    deleteQuestion(id) {
      EloquentService.delete('anketa_question', id).then(() => this.getAnketa())
    },
    async print() {
      await PatientService.saveClientAnketa(this.patient.id, { anketa: this.anketa })
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/anketa/'+this.patient.id, '_blank')
    },
    printEmpty() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/anketa/'+this.patient.id+"?empty=1", '_blank')
    }
  },
  mounted() {
    this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
      if (modalId === 'anketa-modal') {
        this.getAnketa()
      }
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'anketa-modal') {
        this.anketa = []
        this.newQuestionText = ''
      }
    })
  }
}
</script>

<style scoped lang="scss">
.question-title {
  position: relative;
  .delete-trash {
    position: absolute;
    top: -13px;
    left: -13px;
    display: none;
  }
  &:hover {
    .delete-trash {
      display: inline-block;
    }
  }
}
@media screen and (max-width: 768px) {
  .modal-dialog .modal-content .modal-footer .modal-footer-buttons .btn-themed {
    width: 95px;
  }
}
</style>
