<template>
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.0625 7.1875C12.4077 7.1875 12.6875 6.90768 12.6875 6.5625C12.6875 6.21733 12.4077 5.9375 12.0625 5.9375C11.7173 5.9375 11.4375 6.21733 11.4375 6.5625C11.4375 6.90768 11.7173 7.1875 12.0625 7.1875Z" fill="#87939E"/>
<path d="M13.5 1.25H12.6875V0.625C12.6875 0.279813 12.4077 0 12.0625 0C11.7173 0 11.4375 0.279813 11.4375 0.625V1.25H8.59375V0.625C8.59375 0.279813 8.31394 0 7.96875 0C7.62356 0 7.34375 0.279813 7.34375 0.625V1.25H4.53125V0.625C4.53125 0.279813 4.25144 0 3.90625 0C3.56106 0 3.28125 0.279813 3.28125 0.625V1.25H2.5C1.1215 1.25 0 2.3715 0 3.75V13.5C0 14.8785 1.1215 16 2.5 16H7.28125C7.62644 16 7.90625 15.7202 7.90625 15.375C7.90625 15.0298 7.62644 14.75 7.28125 14.75H2.5C1.81075 14.75 1.25 14.1892 1.25 13.5V3.75C1.25 3.06075 1.81075 2.5 2.5 2.5H3.28125V3.125C3.28125 3.47019 3.56106 3.75 3.90625 3.75C4.25144 3.75 4.53125 3.47019 4.53125 3.125V2.5H7.34375V3.125C7.34375 3.47019 7.62356 3.75 7.96875 3.75C8.31394 3.75 8.59375 3.47019 8.59375 3.125V2.5H11.4375V3.125C11.4375 3.47019 11.7173 3.75 12.0625 3.75C12.4077 3.75 12.6875 3.47019 12.6875 3.125V2.5H13.5C14.1892 2.5 14.75 3.06075 14.75 3.75V7.3125C14.75 7.65769 15.0298 7.9375 15.375 7.9375C15.7202 7.9375 16 7.65769 16 7.3125V3.75C16 2.3715 14.8785 1.25 13.5 1.25Z" fill="#87939E"/>
<path d="M13.5 16L3.75 16L3.75 14.75L13.5 14.75C14.1892 14.75 14.75 14.1892 14.75 13.5L14.75 8.71874L14.75 6.99999L16 6.99999L16 8.71874L16 13.5C16 14.8785 14.8785 16 13.5 16Z" fill="#87939E"/>
<path d="M9.34375 7.1875C9.68893 7.1875 9.96875 6.90768 9.96875 6.5625C9.96875 6.21733 9.68893 5.9375 9.34375 5.9375C8.99857 5.9375 8.71875 6.21733 8.71875 6.5625C8.71875 6.90768 8.99857 7.1875 9.34375 7.1875Z" fill="#87939E"/>
<path d="M6.625 9.90625C6.97018 9.90625 7.25 9.62643 7.25 9.28125C7.25 8.93608 6.97018 8.65625 6.625 8.65625C6.27982 8.65625 6 8.93608 6 9.28125C6 9.62643 6.27982 9.90625 6.625 9.90625Z" fill="#87939E"/>
<path d="M12.0649 9.90624C12.4101 9.90624 12.6899 9.62642 12.6899 9.28124C12.6899 8.93606 12.4101 8.65624 12.0649 8.65624C11.7198 8.65624 11.4399 8.93606 11.4399 9.28124C11.4399 9.62642 11.7198 9.90624 12.0649 9.90624Z" fill="#87939E"/>
<path d="M3.90625 7.1875C4.25143 7.1875 4.53125 6.90768 4.53125 6.5625C4.53125 6.21733 4.25143 5.9375 3.90625 5.9375C3.56107 5.9375 3.28125 6.21733 3.28125 6.5625C3.28125 6.90768 3.56107 7.1875 3.90625 7.1875Z" fill="#87939E"/>
<path d="M3.90625 9.90625C4.25143 9.90625 4.53125 9.62643 4.53125 9.28125C4.53125 8.93608 4.25143 8.65625 3.90625 8.65625C3.56107 8.65625 3.28125 8.93608 3.28125 9.28125C3.28125 9.62643 3.56107 9.90625 3.90625 9.90625Z" fill="#87939E"/>
<path d="M9.34717 9.90624C9.69235 9.90624 9.97217 9.62642 9.97217 9.28124C9.97217 8.93606 9.69235 8.65624 9.34717 8.65624C9.00199 8.65624 8.72217 8.93606 8.72217 9.28124C8.72217 9.62642 9.00199 9.90624 9.34717 9.90624Z" fill="#87939E"/>
<path d="M3.90625 12.625C4.25143 12.625 4.53125 12.3452 4.53125 12C4.53125 11.6548 4.25143 11.375 3.90625 11.375C3.56107 11.375 3.28125 11.6548 3.28125 12C3.28125 12.3452 3.56107 12.625 3.90625 12.625Z" fill="#87939E"/>
<path d="M9.34521 12.625C9.69039 12.625 9.97021 12.3452 9.97021 12C9.97021 11.6548 9.69039 11.375 9.34521 11.375C9.00004 11.375 8.72021 11.6548 8.72021 12C8.72021 12.3452 9.00004 12.625 9.34521 12.625Z" fill="#87939E"/>
<path d="M6.625 12.625C6.97018 12.625 7.25 12.3452 7.25 12C7.25 11.6548 6.97018 11.375 6.625 11.375C6.27982 11.375 6 11.6548 6 12C6 12.3452 6.27982 12.625 6.625 12.625Z" fill="#87939E"/>
<path d="M12.0649 12.625C12.4101 12.625 12.6899 12.3452 12.6899 12C12.6899 11.6548 12.4101 11.375 12.0649 11.375C11.7198 11.375 11.4399 11.6548 11.4399 12C11.4399 12.3452 11.7198 12.625 12.0649 12.625Z" fill="#87939E"/>
<path d="M6.625 7.1875C6.97018 7.1875 7.25 6.90768 7.25 6.5625C7.25 6.21733 6.97018 5.9375 6.625 5.9375C6.27982 5.9375 6 6.21733 6 6.5625C6 6.90768 6.27982 7.1875 6.625 7.1875Z" fill="#87939E"/>
</svg>
</template>
