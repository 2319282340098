<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportGuarantee",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    patients: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('warranty_case'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'payment_date',
          field: 'payment_date',
          label: this.$t('payment_date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'date',
          field: 'date',
          label: this.$t('visit_date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'id',
          field: 'id',
          label: this.$t('visit_id'),
          sortable: true,
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          tdcomponent: 'PatientsDatatableTDName',
          search_select: true,
          sortable: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'price',
          field: 'price',
          label: this.$t('sum'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'author_name',
          field: 'author_name',
          label: this.$t('who_created'),
          search_select: true,
          sortable: true,
        },

      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('guarantee', {
        from: this.from,
        to: this.to,
        doctors: this.doctors.map(a => a.id),
        patients: this.patients.map(a => a.id),
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>
