<template>
<svg fill="none"
     width="17"
     height="17"
     stroke-linecap="round"
     stroke-linejoin="round"
     stroke-width="2"
     viewBox="0 0 24 24"
     xmlns="http://www.w3.org/2000/svg">
  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
  <polyline points="17 8 12 3 7 8"/>
  <line x1="12" x2="12" y1="3" y2="15"/>
</svg>
</template>
