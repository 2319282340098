<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M17.1739 13.5904V9.78262C17.1739 6.43117 14.8637 3.60953 11.7522 2.82555C11.8827 2.56355 11.9565 2.26859 11.9565 1.95656C11.9565 0.877695 11.0788 0 9.99997 0C8.92114 0 8.04345 0.877695 8.04345 1.95652C8.04345 2.26855 8.11724 2.56352 8.2477 2.82551C5.13622 3.60949 2.82606 6.43117 2.82606 9.78258V13.5904C2.06696 13.8596 1.52173 14.5845 1.52173 15.4347C1.52173 16.5136 2.39942 17.3913 3.47825 17.3913H6.80474C7.10767 18.8779 8.42521 20 9.99997 20C11.5747 20 12.8922 18.8779 13.1952 17.3913H16.5217C17.6006 17.3913 18.4783 16.5136 18.4783 15.4348C18.4782 14.5846 17.933 13.8596 17.1739 13.5904ZM9.99997 1.30434C10.3596 1.30434 10.6522 1.59691 10.6522 1.95652C10.6522 2.31613 10.3596 2.60871 9.99997 2.60871C9.64036 2.60871 9.34778 2.31613 9.34778 1.95652C9.34778 1.59691 9.64036 1.30434 9.99997 1.30434ZM9.99997 18.6957C9.14974 18.6957 8.42485 18.1461 8.15563 17.387H11.8443C11.5751 18.1461 10.8502 18.6957 9.99997 18.6957ZM16.5217 16.087H3.47825C3.11864 16.087 2.82606 15.7944 2.82606 15.4348C2.82606 15.0752 3.11864 14.7826 3.47825 14.7826H4.78263C5.14278 14.7826 5.43481 14.4906 5.43481 14.1304C5.43481 13.7702 5.14282 13.4782 4.78263 13.4782H4.1304V9.78254C4.1304 6.54605 6.76349 3.91297 9.99997 3.91297C13.2365 3.91297 15.8695 6.54605 15.8695 9.78254V13.4782H15.2168C14.8567 13.4782 14.5646 13.7702 14.5646 14.1304C14.5646 14.4906 14.8566 14.7826 15.2168 14.7826H16.5217C16.8813 14.7826 17.1739 15.0752 17.1739 15.4348C17.1739 15.7944 16.8813 16.087 16.5217 16.087Z"
      fill="#BAC0C6"/>
  <path
      d="M10 5.21738C7.48278 5.21738 5.43481 7.26535 5.43481 9.78262C5.43481 10.1428 5.72681 10.4348 6.087 10.4348C6.4472 10.4348 6.73919 10.1428 6.73919 9.78262C6.73919 7.98457 8.202 6.52176 10 6.52176C10.3602 6.52176 10.6522 6.22977 10.6522 5.86957C10.6522 5.50938 10.3602 5.21738 10 5.21738Z"
      fill="#BAC0C6"/>
</svg>
</template>
