export const diagnosisTemplateAdapter = ({
                                             title_plain,
                                             mkb_code,
                                             template_type,
                                         }) => ({
    title: title_plain || null,
    mkb_code: mkb_code || null,
    template_type: template_type,
})


