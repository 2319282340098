<template>
  <div class="patient-visit-wrap" v-if="object">
    <b-row>
      <b-col lg="6">
        <InputGroup :label="$t('title')"
                    :required="true"
                    v-model="visit.title"/>
      </b-col>
      <b-col lg="3">
        <DateGroup v-model="visit.date_to"
                   :showlabel="true"
                   :label="$t('date_to')"/>
      </b-col>
      <b-col lg="3">
        <SelectGroup :options="manualStatuses"
                     variant="white"
                     :label="$t('status')"
                     :translate="true"
                     :null-option="false"
                     :showlabel="true"
                     v-model="visit.manual_status"/>
      </b-col>
      <b-col lg="6">
        <SelectGroup :options="doctors"
                     title="full_name"
                     variant="white"
                     :label="$t('doctor')"
                     :showlabel="true"
                     v-model="visit.doctor_id"/>
      </b-col>
      <b-col lg="6">
        <ServiceMultiSelect :label="$t('fields')"
                            :dblclick="false"
                            :multiple="false"
                            title="translatedTitle"
                            model="field"
                            :dbsearch="true"
                            v-model="visit.fields"/>
      </b-col>


      <b-col lg="12">
        <AutosizeTextArea v-model="visit.description"
                          :min-h="46"
                          :rows="1"
                          :label="$t('level_description')"/>
      </b-col>

    </b-row>
    <div class="pt-4">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-toothcard`">
        {{ $t('teeth_card') }}
      </b-button>
      <spaceduck-collapse :id="`collapse-toothcard`" :visible="true">
        <PatientFormula mode="select" @input="patientFormulaPressed"/>
      </spaceduck-collapse>
    </div>
<!--    <div class="mt-4" v-if="templates.length">-->
<!--      <SelectGroup :showlabel="true"-->
<!--                   :label="$t('general_template')"-->
<!--                   v-model="template"-->
<!--                   :translate="false"-->
<!--                   :readonly="!teeth.length"-->
<!--                   :options="templates"-->
<!--                   variant="white"/>-->
<!--    </div>-->
    <div class="mt-4" v-if="templates.length && teeth.length">
      <a @click="initTemplate"
         class="d-block btn-themed mt-4 text-center btn-themed-outline btn-themed-squared">
        {{ $t('apply_treatment_template') }}
      </a>
    </div>

    <div class="mt-4" v-if="!diagnosesShown">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-selected-teeth`">
        {{ $t('selected_teeth') }}
      </b-button>
      <spaceduck-collapse :visible="true"
                          :id="`collapse-selected-teeth`">
        <VisitSelectedTeeth v-if="object"
                            :usedTeeth="usedTeeth"
                            ref="selectedTeethPart"
                            :visit="visit"
                            view="plan"
                            :insuranceCompanyId="insuranceCompanyId"
                            @addedMaterial="addedMaterial"
                            @removedMaterial="removedMaterial"
                            @materialAmountChanged="materialAmountChanged"
                            @addedPackage="addedPackage"
                            @removedPackage="removedPackage"
                            :teethDescriptions="teethDescriptions"
                            :versionCount="versionCount"
                            @watchServices="watchServices"
                            @versionChange="versionCount++"/>
      </spaceduck-collapse>
    </div>

    <div class="mt-4" v-if="diagnosesShown">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-diagnosis`">
        {{ $t('diagnosis') }}
      </b-button>
      <spaceduck-collapse :id="`collapse-diagnosis`" :visible="true">
        <VisitDiagnoses v-if="object"
                        :visit="visit"
                        view="plan"
                        ref="diagnosesPart"/>
      </spaceduck-collapse>
    </div>
    <div class="mt-4">
      <b-button class="btn-collapse"
                v-spaceduck-collapse="`collapse-services`">
        {{ $t('services') }}
      </b-button>
      <spaceduck-collapse :id="`collapse-services`"
                          :visible="true">
        <VisitServices v-if="object"
                       ref="servicesPart"
                       view="plan"
                       :insuranceCompanyId="insuranceCompanyId"
                       :visit="visit"
                       :readonly="false"
                       @costTotal="costs"
                       :versionCount="versionCount"
                       @versionChange="versionCount++"/>
      </spaceduck-collapse>
    </div>
    <div v-if="object">
      <TemplateCollapse class="mt-4"
                        :saveAsTemplate="false"
                        v-model="visit.planned_research"
                        model="planned_research"
                        :key="`p-research-${textCounter}`"
                        :opened="true"
                        :title="$t('researches')"
                        :dictation="false"
                        :disableSearch="true"/>
      <TemplateCollapse class="mt-4"
                        :saveAsTemplate="false"
                        v-model="visit.planned_treatment"
                        model="planned_treatment"
                        :key="`p-treatment-${textCounter}`"
                        :opened="true"
                        :title="$t('planned_treatment')"
                        :dictation="false"
                        :disableSearch="true"/>
      <TemplateCollapse class="mt-4"
                        :saveAsTemplate="false"
                        v-model="visit.planned_result"
                        model="planned_result"
                        :key="`p-result-${textCounter}`"
                        :opened="true"
                        :title="$t('planned_result')"
                        :dictation="false"
                        :disableSearch="true"/>
    </div>
    <div class="text-right mt-3">
      <a class="d-inline-block btn-themed-squared btn-themed btn-themed-outline btn-outline-danger"
         @click="$emit('deleteVisit')">
        {{ $t('delete_complex') }}
      </a>
    </div>
    <PlanningTemplateOptionsModal :templates="templates"
                                  @applyTemplate="applyTemplate"/>
  </div>

</template>

<script>

import TemplateCollapse from "@/components/form/TemplateCollapse"
import PatientFormula from "@/components/pages/patients/PatientFormula"
import { mapState } from "vuex"
import VisitServices from "@/components/parts/patients/visit/VisitServices"
import VisitDiagnoses from "@/components/parts/patients/visit/VisitDiagnoses"
import SelectGroup from "@/components/form/SelectGroup"
import InputGroup from "@/components/form/InputGroup"
import DateGroup from "@/components/form/DateGroup"
import VisitSelectedTeeth from "@/components/parts/patients/visit/VisitSelectedTeeth"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import PlanningTemplateOptionsModal from "@/components/parts/planning/PlanningTemplateOptionsModal.vue"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import { EloquentService } from "@/services/api.service"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "PlanTreatmentModalVisit",
  components: {
    SpaceduckCollapse,
    ServiceMultiSelect,
    PlanningTemplateOptionsModal,
    SelectGroup,
    InputGroup,
    DateGroup,
    VisitDiagnoses,
    VisitServices,
    PatientFormula,
    TemplateCollapse,
    VisitSelectedTeeth,
    AutosizeTextArea
  },
  props: {
    value: {},
    templates: {},
    insuranceCompanyId: {}
  },
  data() {
    return {
      diagnosesShown: false,
      teethDescriptions: {},
      object: null,
      loading: false,
      visit: this.value,
      versionCount: 0,
      textCounter: 0,
      visitIndex: 0,
      changeServiceWatcher: false,
      template: null,
      manualStatuses: [
        { id: 'finished', title: 'finished' },
        { id: 'in_process', title: 'in_process' },
        { id: 'new', title: 'new' },
      ],
    }
  },
  computed: {
    ...mapState({
      doctors: state => state.auth.doctors,
      teeth:  state => state.teeth.recordTeeth,
      patient:  state => state.patient.patient,
      units:  state => state.auth.units,
      diagnoses: state => state.treatment.diagnoses,
      services: state => state.treatment.services,
      complexServices: state => state.treatment.complexServices,
      packages: state => state.treatment.packages,
      materials: state => state.treatment.materials,
    }),
    usedTeeth() {
      let arr = []
      for(const ind in this.services) {
        arr = arr.concat(this.services[ind].teeth)
      }
      for(const ind in this.diagnoses) {
        arr = arr.concat(this.diagnoses[ind].teeth)
      }
      return [...new Set(arr)]
    },
    doctorId() {
      return this.visit.doctor_id
    }
  },
  methods: {
    patientFormulaPressed(v) {
      v = v.toString()
      if(this.teeth.includes(v) && this.usedTeeth.includes(v)) {
        this.$noty.error(this.$t('tooth_is_assigned'))
        return
      }
      this.$store.commit('toggleRecordTeeth', v)
      // this.$set(this.visit, 'selectedTeeth', this.copyObject(this.teeth))
    },
    watchServices() {
      this.visit.services = this.copyObject(this.services)
    },
    watchDiagnoses() {
      this.visit.diagnoses = this.copyObject(this.diagnoses)
    },
    watchComplexServices() {
      this.visit.complexServices = this.copyObject(this.complexServices)
    },
    costs(costTotal, costTotalDiscounted) {
      this.$emit('costTotal', costTotal, costTotalDiscounted)
    },
    initTemplate() {
      this.$bvModal.show('planning-template-options-modal')
    },
    async applyTemplate({ template, teeth, options }) {
      if(options.includes('diagnoses')) {
        const diagnoses = template.diagnoses
        diagnoses.forEach(diagnosis => {
          if(this.diagnosesShown) {
            this.$refs.diagnosesPart.newDiagnosisFound(diagnosis)
          } else {
            this.$store.commit('newDiagnosisFound', { diagnosis, teeth })
          }
        })
      }
      if(options.includes('services')) {
        let serviceIds = [];
        for(const service of template.fullServices) {
          if(serviceIds.includes(service.id)) {
            for (const t of teeth) {
              await this.$refs.servicesPart.quantityChangedManually(service.id, 'plus', t)
            }
          } else {
            await this.$refs.servicesPart.newServiceFound(service, teeth)
          }
          serviceIds.push(service.id)
        }
        for(const complex_service of template.complex_services) {
          if(!this.visit.complexServices) this.$set(this.visit, 'complexServices', {})
          for (const t of teeth) {
            if(!this.visit.complexServices[t]) this.$set(this.visit.complexServices, t, [])
            this.visit.complexServices[t].push(complex_service)
          }
        }
        this.$store.commit('setComplexServices', this.visit.complexServices) // to fill the inputs cause they read from store
      }
      const arr = ['planned_treatment', 'planned_result', 'description', 'date_to']
      arr.forEach(item => {
        if(!this.visit[item] && template[item] && options.includes(item)) this.$set(this.visit, item, template[item])
      })
      this.textCounter++
      this.$emit('applyTemplate', template)
    },
    addedMaterial({ serviceId, material }) {
      this.$refs.servicesPart.addedMaterial({ serviceId, material })
    },
    removedMaterial({ serviceId, material }) {
      this.$refs.servicesPart.removedMaterial({ serviceId, material })
    },
    materialAmountChanged({ serviceId, material }) {
      this.$refs.servicesPart.materialAmountChanged({ serviceId, material })
    },
    addedPackage({ serviceId, kit }) {
      this.$refs.servicesPart.addedPackage({ serviceId, kit })
    },
    removedPackage({ serviceId, kit }) {
      this.$refs.servicesPart.removedPackage({ serviceId, kit })
    },
  },
  mounted() {
    let teeth = this.visit && this.visit.teeth ? this.visit.teeth : []
    this.$store.commit('setRecordTeeth',teeth.map(a => a.tooth))
    teeth.forEach(item => {
      this.$set(this.teethDescriptions, item.tooth, {
        plansurvey: item.plansurvey,
        medication: item.medication,
        recommendations: item.recommendations
      })
    })
    this.object = this.copyObject(this.visit)
    setTimeout(() => {
      this.changeServiceWatcher = true
    }, 200)
  },
  watch: {
    diagnoses: {
      deep: true,
      handler() {
        this.watchDiagnoses()
      }
    },
    services: {
      deep: true,
      handler() {
        this.watchServices()
      }
    },
    complexServices: {
      deep: true,
      handler() {
        this.watchComplexServices()
      }
    },
    packages: {
      deep: true,
      handler(val) {
        this.visit.packages = this.copyObject(val)
        if(this.changeServiceWatcher) {
          this.$emit('servicesChanged')
        }
      }
    },
    materials: {
      deep: true,
      handler(val) {
        this.visit.materials = val
        if(this.changeServiceWatcher) {
          this.$emit('servicesChanged')
        }
      }
    },
    async doctorId(val) {
      const { data: { fields : fields } } = await EloquentService.show('doctor', val)
      if(fields.length && !this.visit.fields) {
        this.visit.fields = fields[0]
      }
    },
    teeth(val) {
      val.forEach(tooth => {
        if(!(tooth in this.teethDescriptions)) {
          this.$set(this.teethDescriptions, tooth, {
            plansurvey: null,
            medication: null,
            recommendations: null
          })
        }
      })
      for(let t in this.teethDescriptions) {
        if(!val.includes(t)) this.$delete(this.teethDescriptions, t)
      }
      this.$set(this.visit, 'selectedTeeth', this.copyObject(val))
    },
    teethDescriptions: {
      handler(val) {
        this.$emit('parseTeethDescriptions', val)
        this.$set(this.visit, 'teethDescriptions', val)
      },
      deep: true
    },
    versionCount(val) {
      if(val) this.$emit('servicesChanged')
    }
  }
}
</script>
