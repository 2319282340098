<template>
  <div>
    <router-link class="tdclickable" :to="{ name: 'WarehousesStocks', query: params }">
      {{ object.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "WarehouseDatatableTDWarehouseTitle",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    params() {
      return {
        filters: JSON.stringify({
          warehouse_id: {
            id: 'warehouse_id',
            condition: this.object.id,
            type: 'equals'
          }
        })
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
