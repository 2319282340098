<template>
  <div>
    {{ teethString }}
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDTeeth",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    teethString() {
      if(this.object.record_teeth) {
        return this.object.record_teeth.map((item) => {
          return item.teeth
        }).join(', ')
      }
      if(this.object.teeth) {
        return this.object.teeth.map((item) => {
          return item.teeth
        }).join(', ')
      }
      return ''
    }
  }
}
</script>

<style scoped>

</style>
