export default {
    abs: "abs.",
    activate: "Aktivləşdir",
    add_category: "Kateqoriya əlavə et",
    additional_materials: "Əlavə materiallar",
    archive: "Arxiv",
    are_you_sure_you_want_to_delete_category: "Kateqoriyanı silmək istədiyinizə əminsiniz?",
    are_you_sure_you_want_to_delete_service: "Xidməti silmək istədiyinizə əminsiniz?",
    average_price: "Orta qiymət",
    category: "Kateqoriya",
    categories: "Kateqoriyalar",
    change_category: "Kateqoriyanı dəyiş",
    change_pricelist: "Qiymət siyahısını dəyiş",
    complex_service: "Kompleks xidmət",
    complex_services: "Kompleks xidmətlər",
    current_value: "Cari dəyər",
    decrease_price: "Qiyməti azaldın",
    discounted_price: "Endirimli qiymət",
    doctor_reward: "Həkim mükafatı",
    import_standard_services: "Standart xidmətləri idxal edin",
    increase_price: "Qiyməti artırın",
    manager: "Administrator",
    manager_reward: "Administrator mükafatı",
    money_lost_on_discount: "Endirimlərdə itirilən pul",
    ndfl_code: "Şəxsi gəlir vergisi kodu",
    packages_cost: "Komplektlərin maya dəyəri",
    personal_percent_for_assistant: "Assistent üçün fərdi faiz",
    personal_percent_for_doctor: "Həkim üçün fərdi faiz",
    personal_percent_for_manager: "Administrator üçün fərdi faiz",
    personal_percent_for_technic: "Texnik üçün fərdi faiz",
    price_can_be_changed: "Müalicə kartında qiymət dəyişə bilər",
    pricelist_price: "Qiymət siyahısı üzrə qiymət",
    pricelist_short: "Qiymət siyahısı",
    reset_selection: "Seçimi sıfırla",
    send_to_archive: "Arxivə göndər",
    Service: "Xidmət",
    service_category: "Xidmət kateqoriyası",
    service_code: "Xidmət kodu",
    technic: "Texnik",
    technic_reward: "Texnik mükafatı"
}
