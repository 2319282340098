<template>
<svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_4737_57966)">
    <path
        d="M5.11448 11.4286H3.4002C2.94554 11.4286 2.50951 11.248 2.18802 10.9265C1.86652 10.605 1.68591 10.169 1.68591 9.71431V2.85717C1.68591 2.40251 1.86652 1.96648 2.18802 1.64499C2.50951 1.32349 2.94554 1.14288 3.4002 1.14288H10.2573C10.712 1.14288 11.148 1.32349 11.4695 1.64499C11.791 1.96648 11.9716 2.40251 11.9716 2.85717V4.57145L5.11448 11.4286ZM6.82877 14.8572H13.6859C14.1406 14.8572 14.5766 14.6766 14.8981 14.3551C15.2196 14.0336 15.4002 13.5975 15.4002 13.1429V6.28574C15.4002 5.83108 15.2196 5.39505 14.8981 5.07356C14.5766 4.75207 14.1406 4.57145 13.6859 4.57145H6.82877C6.37411 4.57145 5.93808 4.75207 5.61659 5.07356C5.2951 5.39505 5.11448 5.83108 5.11448 6.28574V13.1429C5.11448 13.5975 5.2951 14.0336 5.61659 14.3551C5.93808 14.6766 6.37411 14.8572 6.82877 14.8572Z"
        stroke="#87939E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_4737_57966">
      <rect width="16" height="16" fill="white" transform="translate(0.543091)"/>
    </clipPath>
  </defs>
</svg>
</template>
