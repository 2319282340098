<template>
  <b-modal id="scan-modal" :title="$t('scan')" centered>
    <div>
      <ToothSelectGroup/>
      <AutosizeTextArea/>
      <FileGroup/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel" variant="themed" @click="close()">{{ $t('cancel') }}</b-button>
        <b-button class="ml-2" variant="themed" :disabled="loading"  @click="save">{{ $t('save') }}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>


import ToothSelectGroup from "@/components/form/ToothSelectGroup"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import FileGroup from "@/components/form/FileGroup"
export default {
  name: "ScanModal",
  components: {FileGroup, AutosizeTextArea, ToothSelectGroup},
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    save() {

    }
  }
}
</script>

<style scoped>

</style>
