<template>
  <div>
    <div v-if="parent_family_accounts.length > 0" class="mb-3">
      <div class="form-label">{{ $t('user_attached_to_family_account') }}:</div>
      <span v-for="(p, pind) in parent_family_accounts" :key="`parent-id-${pind}`">{{ p.full_name }}: <span class="font-weight-bold">{{ formatCurrency(p.advance_calculated) }}</span></span>
    </div>

    <div class="data-table-wrapper">
      <div v-if="family_accounts.length > 0">
        <div class="form-label mb-3">{{ $t('users_attached_to_user_family_account') }}:</div>
        <table class="table data-table">
          <thead>
          <tr>
            <th>{{ $t('name') }}</th>
            <th>{{ $t('actions') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr ></tr>
          <tr v-for="(p, pind) in family_accounts" :key="`family_account-${pind}`">
            <td>{{ p.full_name }}</td>
            <td>
              <a class="data-table-action"
                 :title="$t('delete')"
                 v-b-tooltip.hover
                 @click="deleteRow(pind)">
                <DeleteSvg/><span class="action-button-mob-title">{{ $t('delete') }}</span>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <Autocomplete :placeholder="$t('attach_new_user')"
                    model="patient"
                    class="autocomplete-operation-material"
                    v-model="newUser"/>
    </div>

  </div>
</template>

<script>

import {mapActions, mapState} from "vuex"
import DeleteSvg from '@/assets/svg-vue/general/delete.svg'
import Autocomplete from "@/components/parts/general/Autocomplete"
import { PatientService } from "@/services/api.service"
import {formatCurrency} from "@/extensions/filters/filters"

export default {
  name: "PatientFamilyAccount",
  head() {
    return {
      title: this.$t('family_account'),
    }
  },
  components: { Autocomplete, DeleteSvg },
  data() {
    return {
      newUser: null,
      family_accounts: [],
      parent_family_accounts: [],
    }
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
    }),
  },
  methods: {
    formatCurrency,
    ...mapActions(['getPatient']),
    deleteRow(index) {
      this.family_accounts.splice(index, 1)
      this.sync()
    },
    sync() {
      PatientService.syncFamilyAccount(this.patient.id, this.family_accounts.map(a => a.id)).then(res => {
        this.family_accounts = res.data
      })
    }
  },
  watch: {
    newUser(val) {
      if(val) {
        if (!this.family_accounts.some(e => e.id === val.id)) {
          this.family_accounts.push(val)
          this.sync()
        }
      }
    }
  },
  mounted() {
    this.getPatient(this.$route.params.id).then(() => {
      this.family_accounts = this.patient.connected_family_accounts
      this.parent_family_accounts = this.patient.parent_family_accounts
    })
  },
}
</script>

<style scoped>

</style>
