<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   :initialOrder="false"
                   initialSort="asc"
                   :transfer-to-excel="transferToExcel"
                   :rows="rows">
      <template v-slot:dt-footer>
        <tr>
          <td colspan="5" class="bg-white">&nbsp;</td>
        </tr>
        <tr>
          <td>{{ $t('average_conversion_per_doctor_per_period') }}</td>
          <td>{{ formatNumber(periodCalculated.primary, true) }}</td>
          <td>{{ formatNumber(periodCalculated.came_again, true) }}</td>
          <td>{{ formatNumber(periodCalculated.did_not_come_again, true) }}</td>
          <td>{{ formatNumber(periodCalculated.conversion, true) }}%</td>
        </tr>
        <tr>
          <td>{{ $t('average_conversion_per_doctor_per_day') }}</td>
          <td>{{ formatNumber(dayCalculated.primary, true) }}</td>
          <td>{{ formatNumber(dayCalculated.came_again, true) }}</td>
          <td>{{ formatNumber(dayCalculated.did_not_come_again, true) }}</td>
          <td>{{ formatNumber(dayCalculated.conversion, true) }}%</td>
        </tr>
      </template>
    </DataTableHtml>
  </div>
</template>

<script setup>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch } from "vue"
import { useStore } from "vuex"
import { useHead } from "@unhead/vue"
import moment from "moment"
import { formatNumber } from "@/extensions/filters/filters"

let i18n = null
const title = ref('')
const datatable = ref(null)

useHead({
  title: () => title.value,
})

const props = defineProps({
  from: {},
  to: {},
  chairs: {},
  selectedBranches: {},
  fields: {},
  doctors: {},
  selectedProfessions: {},
})

const store = useStore()
const counter = computed(() => store.state.datatable.dataTableCounter)

const rows = ref([])
const columns = computed(() => {
  return [
    {
      id: 'doctor_name',
      field: 'doctor_name',
      label: i18n?.t('doctor'),
      search_select: true,
      sortable: true
    },
    {
      id: 'primary_patients_cnt',
      field: 'primary_patients_cnt',
      label: i18n?.t('total_primary'),
      format_number: true,
      totals: true
    },
    {
      id: 'came_later_cnt',
      field: 'came_later_cnt',
      label: i18n?.t('came_again'),
      format_number: true,
      totals: true
    },
    {
      id: 'did_not_came_later_cnt',
      field: 'did_not_came_later_cnt',
      label: i18n?.t('did_not_came_again'),
      format_number: true,
      totals: true
    },
    {
      id: 'conversion',
      field: 'conversion',
      label: i18n?.t('conversion') + ' (%)',
      format_percent: true,
      totalsFn: (totals) => {
        return Math.round((totals.came_later_cnt / totals.primary_patients_cnt) * 100)
      },
      totalscomponent: 'ReportDoctorConversionDatatableHtmlConversionTotal'
    },
  ]
})

const doctorsCnt = computed(() => {
  return rows.value.length
})

const totalPrimaryCnt = computed(() => {
  return rows.value.reduce((acc, curr) => acc + parseFloat(curr.primary_patients_cnt), 0)
})

const totalCameLaterCnt = computed(() => {
  return rows.value.reduce((acc, curr) => acc + parseFloat(curr.came_later_cnt), 0)
})

const daysInPeriod = computed(() => {
  return moment(props.to).diff(moment(props.from), 'days') + 1
})

const periodCalculated = computed(() => {
  return {
    primary: Math.round((totalPrimaryCnt.value / doctorsCnt.value) * 100) / 100,
    came_again: Math.round((totalCameLaterCnt.value / doctorsCnt.value) * 100) / 100,
    did_not_come_again: Math.round(((totalPrimaryCnt.value - totalCameLaterCnt.value) / doctorsCnt.value) * 100) / 100,
    conversion: Math.round((totalCameLaterCnt.value / totalPrimaryCnt.value) * 100)
  }
})

const dayCalculated = computed(() => {
  return {
    primary: Math.round((periodCalculated.value.primary / daysInPeriod.value) * 100) / 100,
    came_again: Math.round((periodCalculated.value.came_again / daysInPeriod.value) * 100) / 100,
    did_not_come_again: Math.round((periodCalculated.value.did_not_come_again / daysInPeriod.value) * 100) / 100,
    conversion: periodCalculated.value.conversion
  }
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('visits_by_source')
})

onMounted(() => {
  loadReport()
})

watch(() => counter, () => {
  loadReport()
})

const loadReport = async () => {
  const res = await ReportsService.loadReport('doctors_conversion', {
    from: props.from,
    to: props.to,
    doctors: props.doctors.map(s => s.id),
    fields: props.fields.map(s => s.id),
    chairs: props.chairs.map(a => a.id),
    professions: props.selectedProfessions,
    branch_id: props.selectedBranches,
  })
  rows.value = res.data
}

const excel = () => {
  datatable.value.excel()
}

const transferToExcel = (data) => {
  const dataTmp = JSON.parse(JSON.stringify(data))
  dataTmp.push({})
  dataTmp.push({
    doctor_name: i18n?.t('average_conversion_per_doctor_per_period'),
    primary_patients_cnt: periodCalculated.value.primary,
    came_later_cnt: periodCalculated.value.came_again,
    did_not_came_later_cnt: periodCalculated.value.did_not_come_again,
    conversion: periodCalculated.value.conversion,
  })
  dataTmp.push({
    doctor_name: i18n?.t('average_conversion_per_doctor_per_day'),
    primary_patients_cnt: dayCalculated.value.primary,
    came_later_cnt: dayCalculated.value.came_again,
    did_not_came_later_cnt: dayCalculated.value.did_not_come_again,
    conversion: dayCalculated.value.conversion,
  })
  return dataTmp
}

defineExpose({
  loadReport,
  excel
})

</script>
