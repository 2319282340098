<template>
  <div/>
</template>

<script setup>
import { watch } from "vue"
import moment from "moment"

const props = defineProps({
  object: {
    type: Object
  },
  options: {},
})

watch(() => props.object.valid_from, (newVal) => {
  if(newVal && !props.object.valid_to) {
    props.object.valid_to = moment(newVal).add(1, 'year').format('YYYY-MM-DD')
  }
})
</script>
