<template>
  <div class="d-month-view">
    <DMonthWeek v-for="(week, ind) in monthDates"
                :key="week.week"
                :last="(ind + 1) === monthDates.length"
                :week="week" />
  </div>
</template>

<script>

import DMonthWeek from "@/components/pages/calendar/calendar/DMonthWeek"

export default {
  name: "DMonth",
  components: {
    DMonthWeek
  },
  props: {
    monthDates: Array
  },
  beforeUnmount() {
    this.$store.commit('setDoctorSchedules', {})
  }
}
</script>
