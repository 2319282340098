<template>
  <span>
    <a @click="toggleDoctor"
       :title="object.blocked == 0 ? $t('block') : $t('activate')"
       v-b-tooltip.hover
       class="data-table-action">
    <BlockSvg v-if="object.blocked == 0"/>
    <ActivateSvg class="svg-light" v-if="object.blocked == 1"/>
    <span class="action-button-mob-title">{{ object.blocked == 0 ? $t('block') : $t('activate') }}</span>
  </a>
    <a @click="editSchedule"
       v-if="rights.includes('assistant_schedule_edit')"
       :title="$t('schedule')"
       v-b-tooltip.hover
       class="data-table-action">
      <ClockSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('schedule') }}</span>
    </a>
    </span>
</template>

<script>

import ClockSvg from "@/assets/svg-vue/calendar/clock2.svg.vue"
import { mapState } from "vuex"
import BlockSvg from '@/assets/svg-vue/general/block.svg'
import ActivateSvg from '@/assets/svg-vue/form/tick.svg'
import {doctorAdapter} from "@/adapters/staff/doctor"
import {EloquentService} from "@/services/api.service"

export default {
  name: "StaffDatatableAssistantActions",
  components: {
    BlockSvg,
    ActivateSvg,
    ClockSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    editSchedule() {
      this.$store.commit('setDoctor', this.copyObject(this.object))
      this.$nextTick(() => {
        this.$bvModal.show('doctor-schedule-modal')
      })
    },
    toggleDoctor() {
      let formData = new FormData()
      let data = doctorAdapter(this.object)
      delete data.full
      data.blocked = this.object.blocked == 1 ? 0 : 1
      formData = this.appendFormdata(formData, data)
      EloquentService.update('assistant', this.object.id, formData).then(() => {
        this.$store.commit('incrementDataTableCounter')
      })
    },
  }
}
</script>
