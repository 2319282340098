<template>
  <div>
    <AddObject module="settings"
               btnclass="btn-themed-outline btn-themed-squared mt-2"/>
    <CheckboxGroup fid="ask_result_on_saving"
                   v-if="form"
                   v-model="form.ask_result_on_saving"
                   @input="saveSetting"
                   class="mb-0 mt-3"
                   :checkboxvalue="1"
                   :label="$t('ask_result_on_saving')"/>
    <DataTable :options="options"
               class="mt-4"
               module="settings"/>
  </div>
</template>

<script setup>
import AddObject from "@/components/parts/datatable/AddObject"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/visit_result"
import { useHead } from "@unhead/vue"
import {getCurrentInstance, onBeforeMount, onMounted, ref, computed, nextTick} from "vue"
import { useStore } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import { EloquentService } from "@/services/api.service"
import {appendFormdata, copyObject} from "@/extensions/prototypes/prototypes"

const store = useStore()
let i18n = null
const title = ref('')
const form = ref(null)

useHead({
  title: () => title.value,
})

const clinic = computed(() => store.state.auth.clinic)
const additional = computed(() => clinic.value.clinic_additional)

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('visit_results')
})

onMounted(() => {
  store.commit('setEloquentOptions', options)
  form.value = copyObject(additional.value)
})

const saveSetting = async () => {
  await nextTick()
  let formData = new FormData()
  formData = appendFormdata(formData, form.value)
  try {
    await EloquentService.update('clinic_additional', clinic.value.id, formData)
    await store.dispatch('init')
  } catch (e) {
    console.log(e)
  }
}
</script>
