<template>
  <div class="position-relative">
    <a ref="rangeBtn"
       href="#"
       @click="showPopup"
       class="d-inline-block mr-md-3 mr-2 current-view-range">
      {{ $t(activeRange) }} <Arrow />
    </a>
    <transition name="fade" mode="out-in">
      <div v-if="popupShowed">
        <div class="view-range-selector view-dd-selector position-absolute"
             v-closable="{
            exclude: ['rangeBtn'],
            handler: 'onClose'
        }">
          <a href="#"
             size="sm"
             @click="clickRange('MONTH')">
            {{ $t('MONTH') }}
          </a>
          <a href="#"
             size="sm"
             @click="clickRange('WEEK')">
            {{ $t('WEEK') }}
          </a>
          <a href="#"
             size="sm"
             @click="clickRange('DAY')">
            {{ $t('DAY') }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import Arrow from '@/assets/svg-vue/calendar/arrow.svg'
import { Closable } from "@/components/pages/calendar/calendar/directives/CloseOutside"

export default {
  name: "DRangeSwitcher",
  directives: {
    Closable
  },
  components: {
    Arrow
  },
  data: function() {
    return {
      popupShowed: false,
    }
  },
  computed: {
    ...mapState({
      scheduleView: state => state.calendar.scheduleView,
    }),
    ...mapGetters([
      'activeRange'
    ]),
  },
  watch: {},
  methods: {
    ...mapActions([
      'changeRange'
    ]),
    showPopup() {
      this.popupShowed = !this.popupShowed
    },
    onClose() {
      this.popupShowed = false
    },
    clickRange(value) {
      this.popupShowed = false
      this.changeRange(value)
    }
  },
}
</script>

<style scoped lang="scss">
.view-range-selector {
  top:30px;
  min-width: 150px;
  padding:10px 0;
}
.view-range-selector a {
  font-size: 16px;
  display: block;
  padding: 9px 20px;
  color: #5D6D7C;
}
.view-range-selector a:hover {
  background-color: #F3F3F3;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .view-range-selector {
    top: 25px;
    padding: 7px 0;
    a {
      font-size: 13px;
      padding: 5px 15px;
    }
  }
}
</style>
