<template>
  <div v-if="visit">
    <a class="btn-themed btn-themed-outline btn-themed-squared d-inline-block mb-3"
       @click="addScan">{{ $t('add') }}</a>
    <DataTable :options="options"
               :params="{ client_id: this.patient.id, record_id: visit.id }"
               module="patients"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/patients/visit_scan"
import { mapState } from "vuex"

export default {
  name: "PatientVisitXray",
  components: {
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
      visit:  state => state.patient.visit,
    })
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  methods: {
    addScan() {
      this.$store.commit("setViewModel", 'scan')
      this.$store.commit('setSelectedModel', {
        record_id: this.visit.id,
        client_id: this.patient.id,
        record_immutable: true
      })
      this.$store.commit('setShowModal', true)
    }
  },
}
</script>
