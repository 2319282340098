<template>
  <g>
    <!--   SAME AS 32  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M14.1956 77.5598L12.1118 78.8296H8.62813L6.52813 77.5598L8.13975 76.0133L6.05604 74.4668L7.94441 72.9203L5.64906 71.2273L7.47231 69.6808L5.17697 68.2808L7.19557 66.8645L5.17697 65.2529L6.86999 63.7064L4.63976 62.2901L6.3979 60.825L4.10255 59.2785L6.25139 57.732L4.10255 56.1203L5.64906 54.7692L3.56535 53.2227L5.3886 51.7413L3.30488 50.1948L5.11185 48.4529L4.10255 46.7762V32.5157L5.99092 25.1902H12.1118" fill="#8889BE"/>
      <path d="M6.78828 77.5598L8.872 78.8296H12.3394L14.4557 77.5598L12.8278 76.0133L14.9278 74.4668L13.0394 72.9203L15.3185 71.2273L13.5115 69.6808L15.7906 68.2808L13.772 66.8645L15.7906 65.2529L14.1138 63.7064L16.3278 62.2901L14.5859 60.825L16.865 59.2785L14.7162 57.732L16.865 56.1203L15.3185 54.7692L17.4022 53.2227L15.5952 51.7413L17.679 50.1948L15.8557 48.4529L16.865 46.7762V32.5157L14.9929 25.1902H11.0859" fill="#8889BE"/>
      <path d="M21.4442 4.45804L19.5959 26.05C18.8048 28.6004 17.6087 31.0069 16.0532 33.1773C15.5739 33.8927 14.9545 34.5035 14.2325 34.9728C13.5105 35.4421 12.7008 35.7602 11.8524 35.9078C6.92355 36.3699 5.84535 34.2135 3.3809 30.2087C0.916451 26.204 1.56057 11.8654 1.56057 11.8654L2.17668 2.60971C2.17668 2.60971 2.02265 1.58752 6.95155 1.4895C10.8206 1.50629 14.6843 1.77759 18.5177 2.30165C18.5177 2.30165 21.5702 2.98778 21.4442 4.45804Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M2.96111 29.3884V34.6113L3.73125 50.2662C3.73125 50.2662 3.80126 57.8976 3.73125 63.7506C3.86644 68.5899 4.40507 73.4095 5.34154 78.1593C5.34154 78.1593 6.88183 83.9423 7.73598 83.6343C8.59014 83.3262 8.73016 80.0916 8.73016 80.0916L12.5949 61.1322L14.9053 46.0934L16.4036 32.2029" fill="#DEE1E9"/>
      <path d="M21.3046 4.23978L19.4562 25.8317C18.6652 28.3821 17.469 30.7886 15.9136 32.959C15.4342 33.6744 14.8149 34.2852 14.0929 34.7545C13.3708 35.2238 12.5612 35.5419 11.7128 35.6895C6.7839 36.1516 5.7057 33.9952 3.24125 29.9905C0.776802 25.9858 1.42092 11.6471 1.42092 11.6471L2.03703 2.39144C2.03703 2.39144 1.883 1.36926 6.8119 1.27124C10.6809 1.28803 14.5446 1.55933 18.378 2.08339C18.378 2.08339 21.4306 2.76951 21.3046 4.23978Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M6.60189 26.4197C5.28565 27.5259 6.60189 31.7127 6.89594 40.7444C7.04997 44.8471 6.89594 48.2077 6.89594 50.3361C6.71391 55.5311 6.41985 56.3292 6.16781 63.0364C6.08379 65.3889 6.01378 68.1754 6.16781 71.704C6.30783 74.3085 7.13192 78.7887 7.31395 78.8167C7.49598 78.8447 7.58207 71.9701 8.00214 68.8895C8.26819 66.7051 8.3102 65.0948 8.3382 64.1846C8.48462 60.4964 8.82113 56.8182 9.34639 53.1646C10.1725 46.5554 10.5786 43.2508 11.7268 39.9742C12.875 36.6976 13.3231 33.421 14.4153 30.2844C14.6113 29.6823 15.1294 28.2261 14.6954 27.4839C14.2613 26.7418 13.2951 27.3299 12.3149 27.4839C9.06634 28.1561 7.7781 25.4956 6.60189 26.4197Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M2.96111 29.3884V34.6113L3.73125 50.2662C3.73125 50.2662 3.80126 57.8976 3.73125 63.7506C3.86644 68.5899 4.40507 73.4095 5.34154 78.1593C5.34154 78.1593 6.88183 83.9423 7.73598 83.6343C8.59014 83.3262 8.73016 80.0916 8.73016 80.0916L12.5949 61.1322L14.9053 46.0934L16.4036 32.2029" fill="#DEE1E9"/>
      <path d="M21.3046 4.23978L19.4562 25.8317C18.6652 28.3821 17.469 30.7886 15.9136 32.959C15.4342 33.6744 14.8149 34.2852 14.0929 34.7545C13.3708 35.2238 12.5612 35.5419 11.7128 35.6895C6.7839 36.1516 5.7057 33.9952 3.24125 29.9905C0.776802 25.9858 1.42092 11.6471 1.42092 11.6471L2.03703 2.39144C2.03703 2.39144 1.883 1.36926 6.8119 1.27124C10.6809 1.28803 14.5446 1.55933 18.378 2.08339C18.378 2.08339 21.4306 2.76951 21.3046 4.23978Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M6.60189 26.4197C5.28565 27.5259 6.60189 31.7127 6.89594 40.7444C7.04997 44.8471 6.89594 48.2077 6.89594 50.3361C6.71391 55.5311 6.41985 56.3292 6.16781 63.0364C6.08379 65.3889 6.01378 68.1754 6.16781 71.704C6.30783 74.3085 7.13192 78.7887 7.31395 78.8167C7.49598 78.8447 7.58207 71.9701 8.00214 68.8895C8.26819 66.7051 8.3102 65.0948 8.3382 64.1846C8.48462 60.4964 8.82113 56.8182 9.34639 53.1646C10.1725 46.5554 10.5786 43.2508 11.7268 39.9742C12.875 36.6976 13.3231 33.421 14.4153 30.2844C14.6113 29.6823 15.1294 28.2261 14.6954 27.4839C14.2613 26.7418 13.2951 27.3299 12.3149 27.4839C9.06634 28.1561 7.7781 25.4956 6.60189 26.4197Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="7" cy="81" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M2.09279 28.5855V33.8084L2.86293 49.4493C2.86293 49.4493 2.94695 57.0807 2.86293 62.9338C3.00678 67.7778 3.55006 72.6017 4.48723 77.3564C4.48723 77.3564 6.02751 83.1395 6.88167 82.8174C7.73582 82.4953 7.87585 79.2747 7.87585 79.2747L11.7266 60.3153L14.037 45.2905L15.6053 31.4C16.8867 29.418 17.8903 27.2696 18.5878 25.0148L20.4361 3.43689C20.5622 2.03663 17.5096 1.28049 17.5096 1.28049C13.6761 0.755709 9.81266 0.479753 5.9435 0.454346C1.0146 0.552364 1.16862 1.58855 1.16862 1.58855L0.552511 10.8302C0.552511 10.8302 -0.00759125 23.9366 2.09279 28.5855Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M2.09279 28.5855V33.8084L2.86293 49.4493C2.86293 49.4493 2.94695 57.0807 2.86293 62.9338C3.00678 67.7778 3.55006 72.6017 4.48723 77.3564C4.48723 77.3564 6.02751 83.1395 6.88167 82.8174C7.73582 82.4953 7.87585 79.2747 7.87585 79.2747L11.7266 60.3153L14.037 45.2905L15.6053 31.4C16.8867 29.418 17.8903 27.2696 18.5878 25.0148L20.4361 3.43689C20.5622 2.03663 17.5096 1.28049 17.5096 1.28049C13.6761 0.755709 9.81266 0.479753 5.9435 0.454346C1.0146 0.552364 1.16862 1.58855 1.16862 1.58855L0.552511 10.8302C0.552511 10.8302 -0.00759125 23.9366 2.09279 28.5855Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M0.799548 7.81957V2.59662L3.32281 5L7.32281 3.5L10.3228 6L14.3228 3.5L12.7437 19.3017L10.4333 34.3404L6.5686 53.2999C6.5686 53.2999 6.42858 56.5345 5.57442 56.8425C4.72027 57.1506 3.17998 51.3675 3.17998 51.3675C2.24351 46.6178 1.70488 41.7982 1.56969 36.9589C1.6397 31.1058 1.56969 23.4744 1.56969 23.4744L0.799548 7.81957Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M4.73438 23.5443C4.73438 21.4159 4.88841 18.0553 4.73438 13.9526C4.49173 6.49982 3.82281 3 3.97185 2L5.32281 3.5L7.82281 2.5L10.3228 3.5L12.5338 2.19218C12.9679 2.93432 12.4498 4.39058 12.2538 4.99269C11.1616 8.12927 10.7135 9.90584 9.56526 13.1824C8.42778 16.4284 8.01863 19.7019 7.20788 26.1884L7.18483 26.3728C6.65957 30.0264 6.32306 33.7046 6.17664 37.3929C6.14864 38.303 6.10663 39.9133 5.84058 42.0977C5.42051 45.1783 5.33442 52.0529 5.15239 52.0249C4.97036 51.9969 4.14627 47.5167 4.00625 44.9122C3.85222 41.3836 3.92223 38.5971 4.00625 36.2447C4.25829 29.5374 4.55235 28.7393 4.73438 23.5443Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M13.1695 10.0111C8.29208 12.3435 5.95151 11.7259 2.33866 7.85048" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M3.1032 29.1171V34.3401L3.87334 49.9949C3.87334 49.9949 3.94335 57.6263 3.87334 63.4794C4.00853 68.3187 4.54716 73.1383 5.48363 77.888C5.48363 77.888 7.02392 83.6711 7.87807 83.363C8.73223 83.055 8.87225 79.8204 8.87225 79.8204L12.737 60.8609L15.0474 45.8222L16.5457 31.9316" fill="#DEE1E9"/>
      <path d="M19.5983 25.5605L21.4466 3.96854C21.5727 2.49827 18.5201 1.81215 18.5201 1.81215C14.6867 1.28809 10.823 1.01679 6.95399 1C6.59775 1.00708 6.26805 1.019 5.96293 1.035C5.86355 1.04022 5.83372 1.17103 5.92055 1.21966L7.28774 1.98553L8.62646 5.02051L4.81738 6.53525L6.02496 8.84866L4.81738 11.3155L2.62646 12.5205H1.525C1.43344 15.9978 1.3423 26.4026 3.38334 29.7192C5.84779 33.724 6.92599 35.8804 11.8549 35.4183C12.7033 35.2707 13.5129 34.9526 14.2349 34.4833C14.957 34.014 15.5763 33.4032 16.0557 32.6878C17.6111 30.5174 18.8073 28.1109 19.5983 25.5605Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M2.85645 19.1715C12.2568 16.4205 14.1512 12.6731 12.207 2.5205" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M6.74398 26.1485C5.42774 27.2547 6.74398 31.4415 7.03803 40.4731C7.19206 44.5759 7.03803 47.9365 7.03803 50.0649C6.856 55.2598 6.56194 56.058 6.3099 62.7652C6.22588 65.1176 6.15587 67.9041 6.3099 71.4328C6.44992 74.0372 7.27401 78.5175 7.45604 78.5455C7.63807 78.5735 7.72415 71.6988 8.14423 68.6183C8.41028 66.4339 8.45229 64.8236 8.48029 63.9134C8.62671 60.2252 8.96322 56.5469 9.48848 52.8934C10.3146 46.2842 10.7207 42.9796 11.8689 39.703C13.0171 36.4264 13.4652 33.1498 14.5574 30.0132C14.7534 29.4111 15.2715 27.9548 14.8375 27.2127C14.4034 26.4706 13.4372 27.0587 12.457 27.2127C9.20843 27.8848 7.92019 25.2243 6.74398 26.1485Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M1.25061 17.7494L2.72734 34.069L3.49748 49.7238C3.49748 49.7238 3.5675 57.3552 3.49748 63.2083C3.63267 68.0476 4.17131 72.8672 5.10778 77.6169C5.10778 77.6169 6.64806 83.4 7.50222 83.0919C8.35637 82.7839 8.4964 79.5493 8.4964 79.5493L12.3611 60.5898L14.6715 45.5511L19.2506 16.7494" fill="#DEE1E9"/>
      <path d="M19.2224 25.2894L21.0708 3.69742C21.1968 2.22716 18.1443 1.54103 18.1443 1.54103C14.3109 1.01697 10.4471 0.745668 6.57814 0.728882C1.64924 0.8269 1.80327 1.84909 1.80327 1.84909L1.18715 11.1048C1.18715 11.1048 0.543037 25.4434 3.00749 29.4481C3.10736 29.6104 3.20496 29.7697 3.30064 29.9259H4.85925C5.36723 29.9259 5.79451 29.545 5.85267 29.0404L6.75061 21.2494C9.47469 20.4533 11.0246 20.4518 13.7506 21.2494L14.1963 28.9834C14.2268 29.5125 14.6647 29.9259 15.1946 29.9259H17.2506C18.0618 28.4532 18.7229 26.9 19.2224 25.2894Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M1.6264 18.5L3.10314 34.8196L3.87328 50.4744C3.87328 50.4744 3.94329 58.1058 3.87328 63.9589C4.00847 68.7982 4.5471 73.6178 5.48357 78.3675C5.48357 78.3675 7.02385 84.1506 7.87801 83.8425C8.73217 83.5345 8.87219 80.2999 8.87219 80.2999L12.7369 61.3404L15.0473 46.3017L19.6264 17.5" fill="#DEE1E9"/>
      <path d="M19.5982 26.04L21.4466 4.44803C21.5726 2.97777 18.52 2.29164 18.52 2.29164C14.6867 1.76758 10.8229 1.49628 6.95393 1.47949C2.02503 1.57751 2.17906 2.5997 2.17906 2.5997L1.56295 11.8554C1.56295 11.8554 0.918831 26.194 3.38328 30.1987C3.48316 30.361 3.58076 30.5203 3.67643 30.6765H5.23504C5.74302 30.6765 6.1703 30.2956 6.22846 29.791L7.1264 22C9.85048 21.2039 11.4004 21.2025 14.1264 22L14.5721 29.734C14.6026 30.2631 15.0405 30.6765 15.5704 30.6765H17.6264C18.4376 29.2038 19.0987 27.6506 19.5982 26.04Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M1.25568 18.5L2.73241 34.8196L3.50255 50.4744C3.50255 50.4744 3.57256 58.1058 3.50255 63.9589C3.63774 68.7982 4.17637 73.6178 5.11285 78.3675C5.11285 78.3675 6.65313 84.1506 7.50728 83.8425C8.36144 83.5345 8.50146 80.2999 8.50146 80.2999L12.3662 61.3404L14.6766 46.3017L19.2557 17.5" fill="#DEE1E9"/>
      <mask id="mask2032" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="31">
        <path d="M19.4035 25.5605L21.2519 3.96854C21.3779 2.49827 18.3253 1.81215 18.3253 1.81215C14.492 1.28809 10.6282 1.01679 6.75923 1C1.83033 1.09802 1.98436 2.1202 1.98436 2.1202L1.36825 11.3759C1.36825 11.3759 0.724129 25.7145 3.18858 29.7192C3.28846 29.8815 3.38605 30.0408 3.48173 30.197H5.04034C5.54832 30.197 5.9756 29.8161 6.03376 29.3115L6.9317 21.5205C9.65578 20.7244 11.2057 20.723 13.9317 21.5205L14.3774 29.2545C14.4079 29.7836 14.8458 30.197 15.3757 30.197H17.4317C18.2429 28.7243 18.904 27.1711 19.4035 25.5605Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2032)">
        <path d="M19.4035 25.5605L21.2519 3.96854C21.3779 2.49827 18.3253 1.81215 18.3253 1.81215C14.492 1.28809 10.6282 1.01679 6.75923 1C1.83033 1.09802 1.98436 2.1202 1.98436 2.1202L1.36825 11.3759C1.36825 11.3759 0.724129 25.7145 3.18858 29.7192C3.28846 29.8815 3.38605 30.0408 3.48173 30.197H5.04034C5.54832 30.197 5.9756 29.8161 6.03376 29.3115L6.9317 21.5205C9.65578 20.7244 11.2057 20.723 13.9317 21.5205L14.3774 29.2545C14.4079 29.7836 14.8458 30.197 15.3757 30.197H17.4317C18.2429 28.7243 18.904 27.1711 19.4035 25.5605Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M4.18048 45.317H17.227V19.7002C12.132 17.7687 9.27545 17.7491 4.18048 19.7002V45.317Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M2.12712 28.7642V33.9871L2.89726 49.642C2.89726 49.642 2.96728 57.2734 2.89726 63.1264C3.03245 67.9657 3.57109 72.7853 4.50756 77.5351C4.50756 77.5351 6.04784 83.3181 6.902 83.0101C7.75615 82.702 7.89618 79.4674 7.89618 79.4674L11.7609 60.508L14.0713 45.4692L15.5696 31.5787" fill="#DEE1E9"/>
      <path d="M20.4706 3.61552L18.6222 25.2075C17.8312 27.7578 16.635 30.1643 15.0796 32.3348C14.6003 33.0502 13.9809 33.661 13.2589 34.1303C12.5369 34.5996 11.7272 34.9176 10.8788 35.0653C5.94992 35.5273 4.87172 33.371 2.40727 29.3662C-0.0571823 25.3615 0.586935 11.0229 0.586935 11.0229L1.20305 1.76718C1.20305 1.76718 1.04902 0.744991 5.97792 0.646973C9.84693 0.663758 13.7106 0.93506 17.544 1.45912C17.544 1.45912 20.5966 2.14525 20.4706 3.61552Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M5.7679 25.7954C4.45166 26.9016 5.7679 31.0884 6.06196 40.12C6.21598 44.2228 6.06196 47.5834 6.06196 49.7118C5.87992 54.9067 5.58587 55.7049 5.33382 62.4121C5.24981 64.7645 5.17979 67.551 5.33382 71.0797C5.47385 73.6842 6.29793 78.1644 6.47997 78.1924C6.662 78.2204 6.74808 71.3457 7.16816 68.2652C7.43421 66.0808 7.47621 64.4705 7.50422 63.5603C7.65064 59.8721 7.98715 56.1939 8.5124 52.5403C9.33855 45.9311 9.74463 42.6265 10.8928 39.3499C12.041 36.0733 12.4891 32.7967 13.5813 29.6601C13.7774 29.058 14.2955 27.6017 13.8614 26.8596C13.4273 26.1175 12.4611 26.7056 11.4809 26.8596C8.23235 27.5317 6.94412 24.8712 5.7679 25.7954Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M2.81909 58.7877C6.43954 60.9512 8.46124 60.8526 12.0602 58.7877" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M2.73235 29.5967V34.8196L3.50249 50.4745C3.50249 50.4745 3.5725 58.1059 3.50249 63.959C3.63768 68.7983 4.17631 73.6178 5.11278 78.3676C5.11278 78.3676 6.65307 84.1506 7.50722 83.8426C8.36138 83.5345 8.5014 80.2999 8.5014 80.2999L12.3661 61.3405L14.6765 46.3017L16.1748 32.4112" fill="#DEE1E9"/>
      <mask id="mask2232" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M21.2518 3.96854L19.4035 25.5605C18.6125 28.1109 17.4163 30.5174 15.8608 32.6878C15.3815 33.4032 14.7621 34.014 14.0401 34.4833C13.3181 34.9526 12.5085 35.2707 11.6601 35.4183C6.73117 35.8804 5.65297 33.724 3.18852 29.7192C0.724068 25.7145 1.36819 11.3759 1.36819 11.3759L1.9843 2.1202C1.9843 2.1202 1.83027 1.09802 6.75917 1C10.6282 1.01679 14.4919 1.28809 18.3253 1.81215C18.3253 1.81215 21.3778 2.49827 21.2518 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2232)">
        <path d="M21.2518 3.96854L19.4035 25.5605C18.6125 28.1109 17.4163 30.5174 15.8608 32.6878C15.3815 33.4032 14.7621 34.014 14.0401 34.4833C13.3181 34.9526 12.5085 35.2707 11.6601 35.4183C6.73117 35.8804 5.65297 33.724 3.18852 29.7192C0.724068 25.7145 1.36819 11.3759 1.36819 11.3759L1.9843 2.1202C1.9843 2.1202 1.83027 1.09802 6.75917 1C10.6282 1.01679 14.4919 1.28809 18.3253 1.81215C18.3253 1.81215 21.3778 2.49827 21.2518 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.4989 16C10.4368 16 9.19116 10.7018 9.19116 1.10345L11.1142 -0.758619L14.1912 -2C14.1912 7.59833 12.5609 16 11.4989 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.77586 14.2438C5.58426 15.6109 6.77586 20.7852 7.04207 31.9472C7.18151 37.0177 7.04207 41.171 7.04207 43.8014C6.87728 50.2217 6.61107 51.2082 6.38289 59.4974C6.30683 62.4048 6.24345 65.8485 6.38289 70.2095C6.50965 73.4283 7.2557 78.9653 7.4205 78.9999C7.58529 79.0345 7.66323 70.5383 8.04352 66.7311C8.28438 64.0315 8.32241 62.0413 8.34776 60.9165C8.48032 56.3583 8.78496 51.8125 9.26048 47.2971C10.0084 39.129 10.376 35.0449 11.4155 30.9954C12.455 26.946 12.8606 22.8965 13.8494 19.0201C14.0269 18.276 14.4959 16.4762 14.1029 15.559C13.71 14.6418 12.8353 15.3686 11.9479 15.559C9.00694 16.3897 7.8407 13.1016 6.77586 14.2438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 7.25562 82.2009)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M2.73229 29.5967V34.8196L3.50243 50.4745C3.50243 50.4745 3.57244 58.1059 3.50243 63.959C3.63762 68.7983 4.17625 73.6178 5.11272 78.3676C5.11272 78.3676 6.653 84.1506 7.50716 83.8426C8.36132 83.5345 8.50134 80.2999 8.50134 80.2999L12.366 61.3405L14.6765 46.3017L16.1747 32.4112" fill="#DEE1E9"/>
      <mask id="mask2332" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M21.2518 3.96854L19.4034 25.5605C18.6124 28.1109 17.4162 30.5174 15.8608 32.6878C15.3814 33.4032 14.7621 34.014 14.0401 34.4833C13.318 34.9526 12.5084 35.2707 11.66 35.4183C6.7311 35.8804 5.65291 33.724 3.18846 29.7192C0.724007 25.7145 1.36812 11.3759 1.36812 11.3759L1.98424 2.1202C1.98424 2.1202 1.83021 1.09802 6.75911 1C10.6281 1.01679 14.4918 1.28809 18.3252 1.81215C18.3252 1.81215 21.3778 2.49827 21.2518 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2332)">
        <path d="M21.2518 3.96854L19.4034 25.5605C18.6124 28.1109 17.4162 30.5174 15.8608 32.6878C15.3814 33.4032 14.7621 34.014 14.0401 34.4833C13.318 34.9526 12.5084 35.2707 11.66 35.4183C6.7311 35.8804 5.65291 33.724 3.18846 29.7192C0.724007 25.7145 1.36812 11.3759 1.36812 11.3759L1.98424 2.1202C1.98424 2.1202 1.83021 1.09802 6.75911 1C10.6281 1.01679 14.4918 1.28809 18.3252 1.81215C18.3252 1.81215 21.3778 2.49827 21.2518 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.4988 16C10.4367 16 9.1911 10.7018 9.1911 1.10345L11.1142 -0.758619L14.1911 -2C14.1911 7.59833 12.5609 16 11.4988 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.7758 14.2438C5.5842 15.6109 6.7758 20.7852 7.04201 31.9472C7.18145 37.0177 7.04201 41.171 7.04201 43.8014C6.87721 50.2217 6.61101 51.2082 6.38283 59.4974C6.30677 62.4048 6.24338 65.8485 6.38283 70.2095C6.50959 73.4283 7.25564 78.9653 7.42044 78.9999C7.58523 79.0345 7.66316 70.5383 8.04346 66.7311C8.28432 64.0315 8.32235 62.0413 8.3477 60.9165C8.48025 56.3583 8.7849 51.8125 9.26042 47.2971C10.0083 39.129 10.376 35.0449 11.4154 30.9954C12.4549 26.946 12.8606 22.8965 13.8493 19.0201C14.0268 18.276 14.4959 16.4762 14.1029 15.559C13.7099 14.6418 12.8352 15.3686 11.9479 15.559C9.00688 16.3897 7.84064 13.1016 6.7758 14.2438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 7.25555 82.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2332)">
        <path d="M7.25555 78L6.43265 81.7391L3.39883 79.4037L5.1719 82.797L1.34671 82.9581L4.88611 84.4178L2.0594 87L5.70901 85.8431L5.20343 89.6382L7.25555 86.406L9.30768 89.6382L8.8021 85.8431L12.4517 87L9.625 84.4178L13.1644 82.9581L9.33921 82.797L11.1123 79.4037L8.07845 81.7391L7.25555 78Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2332" x="1.14668" y="77.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M2.73229 29.5967V34.8196L3.50243 50.4745C3.50243 50.4745 3.57244 58.1059 3.50243 63.959C3.63762 68.7983 4.17625 73.6178 5.11272 78.3676C5.11272 78.3676 6.653 84.1506 7.50716 83.8426C8.36132 83.5345 8.50134 80.2999 8.50134 80.2999L12.366 61.3405L14.6765 46.3017L16.1747 32.4112" fill="#DEE1E9"/>
      <mask id="mask2332" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M21.2518 3.96854L19.4034 25.5605C18.6124 28.1109 17.4162 30.5174 15.8608 32.6878C15.3814 33.4032 14.7621 34.014 14.0401 34.4833C13.318 34.9526 12.5084 35.2707 11.66 35.4183C6.7311 35.8804 5.65291 33.724 3.18846 29.7192C0.724007 25.7145 1.36812 11.3759 1.36812 11.3759L1.98424 2.1202C1.98424 2.1202 1.83021 1.09802 6.75911 1C10.6281 1.01679 14.4918 1.28809 18.3252 1.81215C18.3252 1.81215 21.3778 2.49827 21.2518 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2332)">
        <path d="M21.2518 3.96854L19.4034 25.5605C18.6124 28.1109 17.4162 30.5174 15.8608 32.6878C15.3814 33.4032 14.7621 34.014 14.0401 34.4833C13.318 34.9526 12.5084 35.2707 11.66 35.4183C6.7311 35.8804 5.65291 33.724 3.18846 29.7192C0.724007 25.7145 1.36812 11.3759 1.36812 11.3759L1.98424 2.1202C1.98424 2.1202 1.83021 1.09802 6.75911 1C10.6281 1.01679 14.4918 1.28809 18.3252 1.81215C18.3252 1.81215 21.3778 2.49827 21.2518 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.4988 16C10.4367 16 9.1911 10.7018 9.1911 1.10345L11.1142 -0.758619L14.1911 -2C14.1911 7.59833 12.5609 16 11.4988 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.7758 14.2438C5.5842 15.6109 6.7758 20.7852 7.04201 31.9472C7.18145 37.0177 7.04201 41.171 7.04201 43.8014C6.87721 50.2217 6.61101 51.2082 6.38283 59.4974C6.30677 62.4048 6.24338 65.8485 6.38283 70.2095C6.50959 73.4283 7.25564 78.9653 7.42044 78.9999C7.58523 79.0345 7.66316 70.5383 8.04346 66.7311C8.28432 64.0315 8.32235 62.0413 8.3477 60.9165C8.48025 56.3583 8.7849 51.8125 9.26042 47.2971C10.0083 39.129 10.376 35.0449 11.4154 30.9954C12.4549 26.946 12.8606 22.8965 13.8493 19.0201C14.0268 18.276 14.4959 16.4762 14.1029 15.559C13.7099 14.6418 12.8352 15.3686 11.9479 15.559C9.00688 16.3897 7.84064 13.1016 6.7758 14.2438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 7.25555 82.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2332)">
        <path d="M7.25555 78L6.43265 81.7391L3.39883 79.4037L5.1719 82.797L1.34671 82.9581L4.88611 84.4178L2.0594 87L5.70901 85.8431L5.20343 89.6382L7.25555 86.406L9.30768 89.6382L8.8021 85.8431L12.4517 87L9.625 84.4178L13.1644 82.9581L9.33921 82.797L11.1123 79.4037L8.07845 81.7391L7.25555 78Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2332" x="1.14668" y="77.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M2.73229 29.3958V34.6187L3.50243 50.2736C3.50243 50.2736 3.57244 57.905 3.50243 63.758C3.63762 68.5973 4.17625 73.4169 5.11272 78.1667C5.11272 78.1667 6.653 83.9497 7.50716 83.6417C8.36132 83.3336 8.50134 80.099 8.50134 80.099L12.366 61.1395L14.6765 46.1008L16.1747 32.2103" fill="#DEE1E9"/>
      <mask id="mask2532" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M21.2518 3.76761L19.4034 25.3596C18.6124 27.9099 17.4162 30.3164 15.8608 32.4869C15.3814 33.2023 14.7621 33.8131 14.0401 34.2824C13.318 34.7517 12.5084 35.0697 11.66 35.2174C6.7311 35.6794 5.65291 33.5231 3.18846 29.5183C0.724007 25.5136 1.36812 11.175 1.36812 11.175L1.98424 1.91928C1.98424 1.91928 1.83021 0.89709 6.75911 0.799072C10.6281 0.815858 14.4918 1.08716 18.3252 1.61122C18.3252 1.61122 21.3778 2.29735 21.2518 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2532)">
        <path d="M21.2518 3.76761L19.4034 25.3596C18.6124 27.9099 17.4162 30.3164 15.8608 32.4869C15.3814 33.2023 14.7621 33.8131 14.0401 34.2824C13.318 34.7517 12.5084 35.0697 11.66 35.2174C6.7311 35.6794 5.65291 33.5231 3.18846 29.5183C0.724007 25.5136 1.36812 11.175 1.36812 11.175L1.98424 1.91928C1.98424 1.91928 1.83021 0.89709 6.75911 0.799072C10.6281 0.815858 14.4918 1.08716 18.3252 1.61122C18.3252 1.61122 21.3778 2.29735 21.2518 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.4988 15.7991C10.4367 15.7991 9.1911 10.5008 9.1911 0.902521L11.1142 -0.959547L14.1911 -2.20093C14.1911 7.3974 12.5609 15.7991 11.4988 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.7758 14.0429C5.5842 15.41 6.7758 20.5843 7.04201 31.7463C7.18145 36.8168 7.04201 40.9701 7.04201 43.6005C6.87721 50.0208 6.61101 51.0072 6.38283 59.2965C6.30677 62.2038 6.24338 65.6476 6.38283 70.0086C6.50959 73.2274 7.25564 78.7644 7.42044 78.799C7.58523 78.8336 7.66316 70.3374 8.04346 66.5302C8.28432 63.8305 8.32235 61.8404 8.3477 60.7156C8.48025 56.1574 8.7849 51.6116 9.26042 47.0962C10.0083 38.9281 10.376 34.844 11.4154 30.7945C12.4549 26.745 12.8606 22.6956 13.8493 18.8192C14.0268 18.075 14.4959 16.2753 14.1029 15.3581C13.7099 14.4409 12.8352 15.1677 11.9479 15.3581C9.00688 16.1887 7.84064 12.9007 6.7758 14.0429Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 7.25555 82)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2632" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="84">
        <path d="M2.61608 29.1172V34.3401L3.38622 49.995C3.38622 49.995 3.45623 57.6264 3.38622 63.4795C3.52141 68.3188 4.06004 73.1383 4.99651 77.8881C4.99651 77.8881 6.53679 83.6712 7.39095 83.3631C8.24511 83.055 8.38513 79.8204 8.38513 79.8204L12.2498 60.861L14.5603 45.8222L16.0585 31.9317" fill="#DEE1E9"/>
        <path d="M20.9595 3.96854L19.1112 25.5605C18.3202 28.1109 17.124 30.5174 15.5685 32.6878C15.0892 33.4032 14.4698 34.014 13.7478 34.4833C13.0258 34.9526 12.2162 35.2707 11.3678 35.4183C6.43887 35.8804 5.36067 33.724 2.89622 29.7192C0.43177 25.7145 1.07589 11.3759 1.07589 11.3759L1.692 2.1202C1.692 2.1202 1.53797 1.09802 6.46687 1C10.3359 1.01679 14.1996 1.28809 18.033 1.81215C18.033 1.81215 21.0855 2.49827 20.9595 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2632)">
        <path d="M2.61608 29.1172V34.3401L3.38622 49.995C3.38622 49.995 3.45623 57.6264 3.38622 63.4795C3.52141 68.3188 4.06004 73.1383 4.99651 77.8881C4.99651 77.8881 6.53679 83.6712 7.39095 83.3631C8.24511 83.055 8.38513 79.8204 8.38513 79.8204L12.2498 60.861L14.5603 45.8222L16.0585 31.9317" fill="#DEE1E9"/>
        <path d="M20.9595 3.96854L19.1112 25.5605C18.3202 28.1109 17.124 30.5174 15.5685 32.6878C15.0892 33.4032 14.4698 34.014 13.7478 34.4833C13.0258 34.9526 12.2162 35.2707 11.3678 35.4183C6.43887 35.8804 5.36067 33.724 2.89622 29.7192C0.43177 25.7145 1.07589 11.3759 1.07589 11.3759L1.692 2.1202C1.692 2.1202 1.53797 1.09802 6.46687 1C10.3359 1.01679 14.1996 1.28809 18.033 1.81215C18.033 1.81215 21.0855 2.49827 20.9595 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M9.98922 21.6762H21.9104C19.7483 27.046 19.3913 30.3063 19.6879 36.0446C19.699 36.2609 19.4497 36.3955 19.2595 36.2689C18.2554 35.601 15.8972 33.7849 15.3255 31.1432C14.6331 27.9432 12.6299 27.1661 12.4173 27.092C12.4043 27.0875 12.3927 27.083 12.3803 27.0773L11.1974 26.5307C11.0528 26.4639 10.9942 26.3014 11.0665 26.1678L11.5954 25.1901C11.6402 25.1074 11.6361 25.0092 11.5846 24.9299L9.73826 22.0858C9.62122 21.9056 9.76174 21.6762 9.98922 21.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.9143 35.155C17.8534 34.3071 16.4454 32.8702 16.043 31.0106C15.2998 27.5761 13.1238 26.6201 12.6908 26.4648L11.9172 26.1073L12.2498 25.4925C12.4065 25.203 12.3922 24.8596 12.212 24.5821L10.7646 22.3524H20.8671C19.0904 27.0171 18.7144 30.2129 18.9143 35.155ZM12.7075 26.4725C12.7077 26.4726 12.7074 26.4724 12.7075 26.4725V26.4725ZM18.832 36.8178C19.5609 37.3027 20.4566 36.7463 20.4187 36.0123C20.1259 30.3462 20.4751 27.18 22.5961 21.9121L22.9633 21H9.98921C9.19302 21 8.70123 21.8028 9.11087 22.4338L10.8332 25.0869L10.4121 25.8654C10.1591 26.333 10.3642 26.9017 10.8702 27.1355L12.0531 27.6822C12.0938 27.701 12.1297 27.7145 12.1592 27.7248C12.1958 27.7376 13.9766 28.3577 14.6081 31.2758C15.2376 34.1849 17.801 36.1319 18.832 36.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask132" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="84">
        <path d="M2.61608 29.1172V34.3401L3.38622 49.995C3.38622 49.995 3.45623 57.6264 3.38622 63.4795C3.52141 68.3188 4.06004 73.1383 4.99651 77.8881C4.99651 77.8881 6.53679 83.6712 7.39095 83.3631C8.24511 83.055 8.38513 79.8204 8.38513 79.8204L12.2498 60.861L14.5603 45.8222L16.0585 31.9317" fill="#DEE1E9"/>
        <path d="M20.9595 3.96854L19.1112 25.5605C18.3202 28.1109 17.124 30.5174 15.5685 32.6878C15.0892 33.4032 14.4698 34.014 13.7478 34.4833C13.0258 34.9526 12.2162 35.2707 11.3678 35.4183C6.43887 35.8804 5.36067 33.724 2.89622 29.7192C0.43177 25.7145 1.07589 11.3759 1.07589 11.3759L1.692 2.1202C1.692 2.1202 1.53797 1.09802 6.46687 1C10.3359 1.01679 14.1996 1.28809 18.033 1.81215C18.033 1.81215 21.0855 2.49827 20.9595 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask132)">
        <path d="M2.61608 29.1172V34.3401L3.38622 49.995C3.38622 49.995 3.45623 57.6264 3.38622 63.4795C3.52141 68.3188 4.06004 73.1383 4.99651 77.8881C4.99651 77.8881 6.53679 83.6712 7.39095 83.3631C8.24511 83.055 8.38513 79.8204 8.38513 79.8204L12.2498 60.861L14.5603 45.8222L16.0585 31.9317" fill="#DEE1E9"/>
        <path d="M20.9595 3.96854L19.1112 25.5605C18.3202 28.1109 17.124 30.5174 15.5685 32.6878C15.0892 33.4032 14.4698 34.014 13.7478 34.4833C13.0258 34.9526 12.2162 35.2707 11.3678 35.4183C6.43887 35.8804 5.36067 33.724 2.89622 29.7192C0.43177 25.7145 1.07589 11.3759 1.07589 11.3759L1.692 2.1202C1.692 2.1202 1.53797 1.09802 6.46687 1C10.3359 1.01679 14.1996 1.28809 18.033 1.81215C18.033 1.81215 21.0855 2.49827 20.9595 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M12.097 26.5H20.2438C18.7663 30.4705 18.5223 32.8811 18.725 37.1241C18.7326 37.2841 18.5622 37.3836 18.4322 37.29C17.746 36.7961 16.1345 35.4532 15.7438 33.5C15.2706 31.1339 13.9016 30.5593 13.7563 30.5045C13.7475 30.5012 13.7396 30.4978 13.7311 30.4936L12.9227 30.0894C12.8239 30.04 12.7838 29.9199 12.8332 29.8211L13.1947 29.0982C13.2253 29.037 13.2225 28.9645 13.1873 28.9058L11.9255 26.8029C11.8456 26.6696 11.9416 26.5 12.097 26.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1963 36.4664C17.4713 35.8394 16.5091 34.777 16.2341 33.4019C15.7262 30.8625 14.2391 30.1555 13.9433 30.0407L13.4146 29.7764L13.6419 29.3218C13.7489 29.1077 13.7392 28.8538 13.616 28.6486L12.6269 27H19.5308C18.3166 30.4491 18.0597 32.8121 18.1963 36.4664ZM13.9547 30.0464C13.9548 30.0465 13.9546 30.0464 13.9547 30.0464V30.0464ZM18.1401 37.6958C18.6382 38.0544 19.2503 37.643 19.2244 37.1003C19.0243 32.9106 19.2629 30.5695 20.7124 26.6744L20.9633 26H12.097C11.5529 26 11.2168 26.5936 11.4968 27.0601L12.6738 29.0219L12.386 29.5975C12.2131 29.9433 12.3533 30.3638 12.6991 30.5367L13.5075 30.9409C13.5353 30.9548 13.5598 30.9648 13.58 30.9724C13.605 30.9818 14.8219 31.4403 15.2535 33.5981C15.6837 35.749 17.4355 37.1887 18.1401 37.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M2.44005 29.5967V34.8196L3.21019 50.4745C3.21019 50.4745 3.2802 58.1059 3.21019 63.959C3.34538 68.7983 3.88401 73.6178 4.82049 78.3676C4.82049 78.3676 6.36077 84.1506 7.21492 83.8426C8.06908 83.5345 8.20911 80.2999 8.20911 80.2999L12.0738 61.3405L14.3842 46.3017L15.8825 32.4112" fill="#DEE1E9"/>
      <mask id="mask232" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M20.9595 3.96854L19.1112 25.5605C18.3202 28.1109 17.124 30.5174 15.5685 32.6878C15.0892 33.4032 14.4698 34.014 13.7478 34.4833C13.0258 34.9526 12.2162 35.2707 11.3678 35.4183C6.43887 35.8804 5.36067 33.724 2.89622 29.7192C0.43177 25.7145 1.07589 11.3759 1.07589 11.3759L1.692 2.1202C1.692 2.1202 1.53797 1.09802 6.46687 1C10.3359 1.01679 14.1996 1.28809 18.033 1.81215C18.033 1.81215 21.0855 2.49827 20.9595 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask232)">
        <path d="M20.9595 3.96854L19.1112 25.5605C18.3202 28.1109 17.124 30.5174 15.5685 32.6878C15.0892 33.4032 14.4698 34.014 13.7478 34.4833C13.0258 34.9526 12.2162 35.2707 11.3678 35.4183C6.43887 35.8804 5.36067 33.724 2.89622 29.7192C0.43177 25.7145 1.07589 11.3759 1.07589 11.3759L1.692 2.1202C1.692 2.1202 1.53797 1.09802 6.46687 1C10.3359 1.01679 14.1996 1.28809 18.033 1.81215C18.033 1.81215 21.0855 2.49827 20.9595 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.2066 16C10.1445 16 8.89886 10.7018 8.89886 1.10345L10.8219 -0.758619L13.8989 -2C13.8989 7.59833 12.2686 16 11.2066 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.48357 14.2438C5.29197 15.6109 6.48357 20.7852 6.74977 31.9472C6.88922 37.0177 6.74977 41.171 6.74977 43.8014C6.58498 50.2217 6.31877 51.2082 6.09059 59.4974C6.01453 62.4048 5.95115 65.8485 6.09059 70.2095C6.21736 73.4283 6.96341 78.9653 7.1282 78.9999C7.293 79.0345 7.37093 70.5383 7.75123 66.7311C7.99208 64.0315 8.03011 62.0413 8.05546 60.9165C8.18802 56.3583 8.49266 51.8125 8.96818 47.2971C9.7161 39.129 10.0837 35.0449 11.1232 30.9954C12.1627 26.946 12.5683 22.8965 13.5571 19.0201C13.7346 18.276 14.2036 16.4762 13.8106 15.559C13.4177 14.6418 12.543 15.3686 11.6556 15.559C8.71465 16.3897 7.5484 13.1016 6.48357 14.2438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M2.73229 29.3958V34.6187L3.50243 50.2736C3.50243 50.2736 3.57244 57.905 3.50243 63.758C3.63762 68.5973 4.17625 73.4169 5.11272 78.1667C5.11272 78.1667 6.653 83.9497 7.50716 83.6417C8.36132 83.3336 8.50134 80.099 8.50134 80.099L12.366 61.1395L14.6765 46.1008L16.1747 32.2103" fill="#DEE1E9"/>
      <mask id="mask332" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M21.2518 3.76761L19.4034 25.3596C18.6124 27.9099 17.4162 30.3164 15.8608 32.4869C15.3814 33.2023 14.7621 33.8131 14.0401 34.2824C13.318 34.7517 12.5084 35.0697 11.66 35.2174C6.7311 35.6794 5.65291 33.5231 3.18846 29.5183C0.724007 25.5136 1.36812 11.175 1.36812 11.175L1.98424 1.91928C1.98424 1.91928 1.83021 0.89709 6.75911 0.799072C10.6281 0.815858 14.4918 1.08716 18.3252 1.61122C18.3252 1.61122 21.3778 2.29735 21.2518 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask332)">
        <path d="M21.2518 3.76761L19.4034 25.3596C18.6124 27.9099 17.4162 30.3164 15.8608 32.4869C15.3814 33.2023 14.7621 33.8131 14.0401 34.2824C13.318 34.7517 12.5084 35.0697 11.66 35.2174C6.7311 35.6794 5.65291 33.5231 3.18846 29.5183C0.724007 25.5136 1.36812 11.175 1.36812 11.175L1.98424 1.91928C1.98424 1.91928 1.83021 0.89709 6.75911 0.799072C10.6281 0.815858 14.4918 1.08716 18.3252 1.61122C18.3252 1.61122 21.3778 2.29735 21.2518 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.4988 15.7991C10.4367 15.7991 9.1911 10.5008 9.1911 0.902521L11.1142 -0.959547L14.1911 -2.20093C14.1911 7.3974 12.5609 15.7991 11.4988 15.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M2.47673 29.1172V34.3401L3.24687 49.995C3.24687 49.995 3.31689 57.6264 3.24687 63.4795C3.38206 68.3188 3.9207 73.1383 4.85717 77.8881C4.85717 77.8881 6.39745 83.6712 7.25161 83.3631C8.10576 83.055 8.24579 79.8204 8.24579 79.8204L12.1105 60.861L14.4209 45.8222L15.9192 31.9317" fill="#DEE1E9"/>
      <path d="M20.8202 3.96854L18.9718 25.5605C18.1808 28.1109 16.9846 30.5174 15.4292 32.6878C14.9499 33.4032 14.3305 34.014 13.6085 34.4833C12.8865 34.9526 12.0768 35.2707 11.2284 35.4183C6.29953 35.8804 5.22133 33.724 2.75688 29.7192C0.292427 25.7145 0.936544 11.3759 0.936544 11.3759L1.55266 2.1202C1.55266 2.1202 1.39863 1.09802 6.32753 1C10.1965 1.01679 14.0603 1.28809 17.8936 1.81215C17.8936 1.81215 20.9462 2.49827 20.8202 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M8 1.52051L6.13333 4.02137L4.5 2.52566V5.83971L1.93782 5.2718L3.33333 7.0224L1 9.02309L3.33333 11.0238L1.93782 12.7744L4.26667 12.0241L4.5 15.5205L6.7499 14.0235L7.53333 15.0251L9.2501 14.0235L11.5 15.5205L12.2 13.5246L14.0622 12.7744L13.6 10.0234L15 9.02309L12.6667 7.52257L14.0622 5.2718L12.2 5.02171L11.5 2.52566L8.46667 4.52154L8 1.52051Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M2.47673 29.1172V34.3401L3.24687 49.995C3.24687 49.995 3.31689 57.6264 3.24687 63.4795C3.38206 68.3188 3.9207 73.1383 4.85717 77.8881C4.85717 77.8881 6.39745 83.6712 7.25161 83.3631C8.10576 83.055 8.24579 79.8204 8.24579 79.8204L12.1105 60.861L14.4209 45.8222L15.9192 31.9317" fill="#DEE1E9"/>
      <path d="M20.8202 3.96854L18.9718 25.5605C18.1808 28.1109 16.9846 30.5174 15.4292 32.6878C14.9499 33.4032 14.3305 34.014 13.6085 34.4833C12.8865 34.9526 12.0768 35.2707 11.2284 35.4183C6.29953 35.8804 5.22133 33.724 2.75688 29.7192C0.292427 25.7145 0.936544 11.3759 0.936544 11.3759L1.55266 2.1202C1.55266 2.1202 1.39863 1.09802 6.32753 1C10.1965 1.01679 14.0603 1.28809 17.8936 1.81215C17.8936 1.81215 20.9462 2.49827 20.8202 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M7 1.52051L5.4 3.6641L4 2.38207V5.22268L1.80385 4.7359L3 6.23641L1 7.95129L3 9.66616L1.80385 11.1667L3.8 10.5236L4 13.5205L5.92849 12.2373L6.6 13.0959L8.07151 12.2373L10 13.5205L10.6 11.8098L12.1962 11.1667L11.8 8.80873L13 7.95129L11 6.66513L12.1962 4.7359L10.6 4.52154L10 2.38207L7.4 4.09282L7 1.52051Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M2.47673 29.5967V34.8196L3.24687 50.4745C3.24687 50.4745 3.31689 58.1059 3.24687 63.959C3.38206 68.7983 3.9207 73.6178 4.85717 78.3676C4.85717 78.3676 6.39745 84.1506 7.25161 83.8426C8.10576 83.5345 8.24579 80.2999 8.24579 80.2999L12.1105 61.3405L14.4209 46.3017L15.9192 32.4112" fill="#DEE1E9"/>
      <path d="M20.8202 4.44803L18.9718 26.04C18.1808 28.5904 16.9846 30.9969 15.4292 33.1673C14.9499 33.8827 14.3305 34.4935 13.6085 34.9628C12.8865 35.4321 12.0768 35.7502 11.2284 35.8978C6.29953 36.3599 5.22133 34.2035 2.75688 30.1987C0.292427 26.194 0.936544 11.8554 0.936544 11.8554L1.55266 2.5997C1.55266 2.5997 1.39863 1.57751 6.32753 1.47949C10.1965 1.49628 14.0603 1.76758 17.8936 2.29164C17.8936 2.29164 20.9462 2.97777 20.8202 4.44803Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M4.5 1L3.56667 2.25043L2.75 1.50258V3.1596L1.46891 2.87564L2.16667 3.75094L1 4.75129L2.16667 5.75163L1.46891 6.62693L2.63333 6.2518L2.75 8L3.87495 7.25148L4.26667 7.75232L5.12505 7.25148L6.25 8L6.6 7.00206L7.53109 6.62693L7.3 5.25146L8 4.75129L6.83333 4.00103L7.53109 2.87564L6.6 2.7506L6.25 1.50258L4.73333 2.50052L4.5 1Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M13.9277 74.6565L11.844 75.9263H8.3603L6.26031 74.6565L7.87193 73.11L5.78822 71.5635L7.67658 70.017L5.38124 68.324L7.20449 66.7775L4.90915 65.3775L6.92775 63.9612L4.90915 62.3496L6.60217 60.803L4.37194 59.3868L6.13008 57.9217L3.83473 56.3751L5.98356 54.8286L3.83473 53.217L5.38124 51.8659L3.29752 50.3193L5.12078 48.838L3.03706 47.2914L4.84403 45.5496L3.83473 43.8728V29.6124L5.7231 22.2868H11.844" fill="#8889BE"/>
      <path d="M13.9277 74.6565L11.844 75.9263H8.3603L6.26031 74.6565L7.87193 73.11L5.78822 71.5635L7.67658 70.017L5.38124 68.324L7.20449 66.7775L4.90915 65.3775L6.92775 63.9612L4.90915 62.3496L6.60217 60.803L4.37194 59.3868L6.13008 57.9217L3.83473 56.3751L5.98356 54.8286L3.83473 53.217L5.38124 51.8659L3.29752 50.3193L5.12078 48.838L3.03706 47.2914L4.84403 45.5496L3.83473 43.8728V29.6124L5.7231 22.2868H11.844" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M6.52046 74.6565L8.60418 75.9263H12.0716L14.1879 74.6565L12.56 73.11L14.66 71.5635L12.7716 70.017L15.0507 68.324L13.2437 66.7775L15.5228 65.3775L13.5042 63.9612L15.5228 62.3496L13.846 60.803L16.06 59.3868L14.3181 57.9217L16.5972 56.3751L14.4483 54.8286L16.5972 53.217L15.0507 51.8659L17.1344 50.3193L15.3274 48.838L17.4111 47.2914L15.5879 45.5496L16.5972 43.8728V29.6124L14.7251 22.2868H10.8181" fill="#8889BE"/>
      <path d="M6.52046 74.6565L8.60418 75.9263H12.0716L14.1879 74.6565L12.56 73.11L14.66 71.5635L12.7716 70.017L15.0507 68.324L13.2437 66.7775L15.5228 65.3775L13.5042 63.9612L15.5228 62.3496L13.846 60.803L16.06 59.3868L14.3181 57.9217L16.5972 56.3751L14.4483 54.8286L16.5972 53.217L15.0507 51.8659L17.1344 50.3193L15.3274 48.838L17.4111 47.2914L15.5879 45.5496L16.5972 43.8728V29.6124L14.7251 22.2868H10.8181" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask532" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="32">
        <path d="M19.395 26.0317L21.2433 4.43973C21.3694 2.96947 18.3168 2.28334 18.3168 2.28334C14.4834 1.75928 10.6197 1.48798 6.75069 1.47119C1.82178 1.56921 1.97581 2.5914 1.97581 2.5914L1.3597 11.8471C1.3597 11.8471 0.715584 26.1857 3.18003 30.1904C3.27991 30.3527 3.37751 30.512 3.47318 30.6682H5.03179C5.53978 30.6682 5.96705 30.2873 6.02522 29.7827L6.92316 21.9917C9.64723 21.1956 11.1972 21.1942 13.9232 21.9917L14.3688 29.7257C14.3993 30.2548 14.8373 30.6682 15.3672 30.6682H17.4232C18.2343 29.1955 18.8954 27.6423 19.395 26.0317Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask532)">
        <path d="M19.395 26.0317L21.2433 4.43973C21.3694 2.96947 18.3168 2.28334 18.3168 2.28334C14.4834 1.75928 10.6197 1.48798 6.75069 1.47119C1.82178 1.56921 1.97581 2.5914 1.97581 2.5914L1.3597 11.8471C1.3597 11.8471 0.715584 26.1857 3.18003 30.1904C3.27991 30.3527 3.37751 30.512 3.47318 30.6682H5.03179C5.53978 30.6682 5.96705 30.2873 6.02522 29.7827L6.92316 21.9917C9.64723 21.1956 11.1972 21.1942 13.9232 21.9917L14.3688 29.7257C14.3993 30.2548 14.8373 30.6682 15.3672 30.6682H17.4232C18.2343 29.1955 18.8954 27.6423 19.395 26.0317Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M4.17194 45.7881H17.2184V20.1713C12.1235 18.2398 9.26691 18.2202 4.17194 20.1713V45.7881Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M13.5198 73.948L11.4361 75.2178H7.95241L5.85241 73.948L7.46403 72.4015L5.38032 70.855L7.26869 69.3085L4.97334 67.6155L6.79659 66.069L4.50125 64.669L6.51985 63.2527L4.50125 61.6411L6.19427 60.0945L3.96404 58.6783L5.72218 57.2132L3.42683 55.6667L5.57567 54.1201L3.42683 52.5085L4.97334 51.1574L2.88963 49.6109L4.71288 48.1295L2.62916 46.5829L4.43613 44.8411L3.42683 43.1644V28.9039L5.3152 21.5784H11.4361" fill="#8889BE"/>
      <path d="M13.5198 73.948L11.4361 75.2178H7.95241L5.85241 73.948L7.46403 72.4015L5.38032 70.855L7.26869 69.3085L4.97334 67.6155L6.79659 66.069L4.50125 64.669L6.51985 63.2527L4.50125 61.6411L6.19427 60.0945L3.96404 58.6783L5.72218 57.2132L3.42683 55.6667L5.57567 54.1201L3.42683 52.5085L4.97334 51.1574L2.88963 49.6109L4.71288 48.1295L2.62916 46.5829L4.43613 44.8411L3.42683 43.1644V28.9039L5.3152 21.5784H11.4361" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M6.11256 73.948L8.19628 75.2178H11.6637L13.78 73.948L12.1521 72.4015L14.2521 70.855L12.3637 69.3085L14.6428 67.6155L12.8358 66.069L15.1149 64.669L13.0963 63.2527L15.1149 61.6411L13.4381 60.0945L15.6521 58.6783L13.9102 57.2132L16.1893 55.6667L14.0405 54.1201L16.1893 52.5085L14.6428 51.1574L16.7265 49.6109L14.9195 48.1295L17.0032 46.5829L15.18 44.8411L16.1893 43.1644V28.9039L14.3172 21.5784H10.4102" fill="#8889BE"/>
      <path d="M6.11256 73.948L8.19628 75.2178H11.6637L13.78 73.948L12.1521 72.4015L14.2521 70.855L12.3637 69.3085L14.6428 67.6155L12.8358 66.069L15.1149 64.669L13.0963 63.2527L15.1149 61.6411L13.4381 60.0945L15.6521 58.6783L13.9102 57.2132L16.1893 55.6667L14.0405 54.1201L16.1893 52.5085L14.6428 51.1574L16.7265 49.6109L14.9195 48.1295L17.0032 46.5829L15.18 44.8411L16.1893 43.1644V28.9039L14.3172 21.5783H10.4102" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask532" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="31">
        <path d="M18.9871 25.3232L20.8354 3.73124C20.9615 2.26097 17.9089 1.57484 17.9089 1.57484C14.0755 1.05078 10.2118 0.779481 6.34279 0.762695C1.41389 0.860713 1.56791 1.8829 1.56791 1.8829L0.951803 11.1386C0.951803 11.1386 0.307686 25.4772 2.77214 29.4819C2.87201 29.6442 2.96961 29.8035 3.06528 29.9597H4.62389C5.13188 29.9597 5.55915 29.5788 5.61732 29.0742L6.51526 21.2832C9.23933 20.4871 10.7893 20.4857 13.5153 21.2832L13.9609 29.0172C13.9914 29.5463 14.4294 29.9597 14.9593 29.9597H17.0153C17.8264 28.487 18.4875 26.9338 18.9871 25.3232Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask532)">
        <path d="M18.9871 25.3232L20.8354 3.73124C20.9615 2.26097 17.9089 1.57484 17.9089 1.57484C14.0755 1.05078 10.2118 0.779481 6.34279 0.762695C1.41389 0.860713 1.56791 1.8829 1.56791 1.8829L0.951803 11.1386C0.951803 11.1386 0.307686 25.4772 2.77214 29.4819C2.87201 29.6442 2.96961 29.8035 3.06528 29.9597H4.62389C5.13188 29.9597 5.55915 29.5788 5.61732 29.0742L6.51526 21.2832C9.23933 20.4871 10.7893 20.4857 13.5153 21.2832L13.9609 29.0172C13.9914 29.5463 14.4294 29.9597 14.9593 29.9597H17.0153C17.8264 28.487 18.4875 26.9338 18.9871 25.3232Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M43.2556 0.259766H22.2556V5.25977H43.2556V0.259766Z" fill="#838AA2"/>
      <path d="M21.2556 0.259766H0.255554V5.25977H21.2556V0.259766Z" fill="#838AA2"/>
      <path d="M12.4316 21.1777L13.9083 37.4973L14.6785 53.1522C14.6785 53.1522 14.7485 60.7836 14.6785 66.6366C14.8136 71.4759 15.3523 76.2955 16.2887 81.0453C16.2887 81.0453 17.829 86.8283 18.6832 86.5203C19.5373 86.2122 19.6774 82.9776 19.6774 82.9776L23.5421 64.0182L25.8525 48.9794L30.4316 20.1777" fill="#DEE1E9"/>
      <path d="M30.4034 28.7177L32.2518 7.12577C32.3778 5.6555 29.3252 4.96938 29.3252 4.96938C25.4918 4.44531 21.6281 4.17401 17.7591 4.15723C12.8302 4.25524 12.9842 5.27743 12.9842 5.27743L12.3681 14.5331C12.3681 14.5331 11.724 28.8717 14.1885 32.8765C14.2883 33.0388 14.3859 33.198 14.4816 33.3542H16.0402C16.5482 33.3542 16.9755 32.9734 17.0336 32.4687L17.9316 24.6777C20.6557 23.8816 22.2056 23.8802 24.9316 24.6777L25.3773 32.4117C25.4078 32.9408 25.8457 33.3542 26.3756 33.3542H28.4316C29.2427 31.8816 29.9039 30.3283 30.4034 28.7177Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M2.31981 29.2021V34.4251L3.08995 50.08C3.08995 50.08 3.15997 57.7114 3.08995 63.5644C3.22514 68.4037 3.76377 73.2233 4.70025 77.9731C4.70025 77.9731 6.24053 83.7561 7.09469 83.4481C7.94884 83.14 8.08887 79.9054 8.08887 79.9054L11.9536 60.9459L14.264 45.9072L15.7623 32.0167" fill="#DEE1E9"/>
      <path d="M20.6633 4.0535L18.8149 25.6454C18.0239 28.1958 16.8277 30.6023 15.2723 32.7727C14.7929 33.4881 14.1736 34.0989 13.4516 34.5683C12.7295 35.0376 11.9199 35.3556 11.0715 35.5033C6.1426 35.9653 5.06441 33.8089 2.59996 29.8042C0.135506 25.7995 0.779623 11.4609 0.779623 11.4609L1.39573 2.20517C1.39573 2.20517 1.24171 1.18298 6.17061 1.08496C10.0396 1.10175 13.9033 1.37305 17.7367 1.89711C17.7367 1.89711 20.7893 2.58323 20.6633 4.0535Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M5.96059 26.2334C4.64435 27.3396 5.96059 31.5264 6.25464 40.558C6.40867 44.6608 6.25464 48.0214 6.25464 50.1498C6.07261 55.3447 5.77855 56.1429 5.52651 62.8501C5.44249 65.2025 5.37248 67.989 5.52651 71.5177C5.66653 74.1221 6.49062 78.6024 6.67265 78.6304C6.85469 78.6584 6.94077 71.7837 7.36085 68.7032C7.62689 66.5188 7.6689 64.9085 7.69691 63.9983C7.84333 60.3101 8.17984 56.6318 8.70509 52.9783C9.53124 46.3691 9.93732 43.0645 11.0855 39.7879C12.2337 36.5113 12.6818 33.2347 13.774 30.0981C13.9701 29.496 14.4881 28.0397 14.0541 27.2976C13.62 26.5555 12.6538 27.1436 11.6736 27.2976C8.42504 27.9697 7.1368 25.3092 5.96059 26.2334Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M2.31981 29.2021V34.4251L3.08995 50.08C3.08995 50.08 3.15997 57.7114 3.08995 63.5644C3.22514 68.4037 3.76377 73.2233 4.70025 77.9731C4.70025 77.9731 6.24053 83.7561 7.09469 83.4481C7.94884 83.14 8.08887 79.9054 8.08887 79.9054L11.9536 60.9459L14.264 45.9072L15.7623 32.0167" fill="#DEE1E9"/>
      <path d="M20.6633 4.0535L18.8149 25.6454C18.0239 28.1958 16.8277 30.6023 15.2723 32.7727C14.7929 33.4881 14.1736 34.0989 13.4516 34.5683C12.7295 35.0376 11.9199 35.3556 11.0715 35.5033C6.1426 35.9653 5.06441 33.8089 2.59996 29.8042C0.135506 25.7995 0.779623 11.4609 0.779623 11.4609L1.39573 2.20517C1.39573 2.20517 1.24171 1.18298 6.17061 1.08496C10.0396 1.10175 13.9033 1.37305 17.7367 1.89711C17.7367 1.89711 20.7893 2.58323 20.6633 4.0535Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.4" d="M5.96059 26.2334C4.64435 27.3396 5.96059 31.5264 6.25464 40.558C6.40867 44.6608 6.25464 48.0214 6.25464 50.1498C6.07261 55.3447 5.77855 56.1429 5.52651 62.8501C5.44249 65.2025 5.37248 67.989 5.52651 71.5177C5.66653 74.1221 6.49062 78.6024 6.67265 78.6304C6.85469 78.6584 6.94077 71.7837 7.36085 68.7032C7.62689 66.5188 7.6689 64.9085 7.69691 63.9983C7.84333 60.3101 8.17984 56.6318 8.70509 52.9783C9.53124 46.3691 9.93732 43.0645 11.0855 39.7879C12.2337 36.5113 12.6818 33.2347 13.774 30.0981C13.9701 29.496 14.4881 28.0397 14.0541 27.2976C13.62 26.5555 12.6538 27.1436 11.6736 27.2976C8.42504 27.9697 7.1368 25.3092 5.96059 26.2334Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M2.31981 29.2021V34.4251L3.08995 50.08C3.08995 50.08 3.15997 57.7114 3.08995 63.5644C3.22514 68.4037 3.76377 73.2233 4.70025 77.9731C4.70025 77.9731 6.24053 83.7561 7.09469 83.4481C7.94884 83.14 8.08887 79.9054 8.08887 79.9054L11.9536 60.9459L14.264 45.9072L15.7623 32.0167" fill="#DEE1E9"/>
      <path d="M20.6633 4.0535L18.8149 25.6454C18.0239 28.1958 16.8277 30.6023 15.2723 32.7727C14.7929 33.4881 14.1736 34.0989 13.4516 34.5683C12.7295 35.0376 11.9199 35.3556 11.0715 35.5033C6.1426 35.9653 5.06441 33.8089 2.59996 29.8042C0.135506 25.7995 0.779623 11.4609 0.779623 11.4609L1.39573 2.20517C1.39573 2.20517 1.24171 1.18298 6.17061 1.08496C10.0396 1.10175 13.9033 1.37305 17.7367 1.89711C17.7367 1.89711 20.7893 2.58323 20.6633 4.0535Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask532" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="17" width="17" height="36">
        <path d="M3.55387 52.3335L11.6307 52.3333L16.3467 17.6365H0.717834L3.55387 52.3335Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask532)">
        <path opacity="0.2" d="M5.96059 26.2334C4.64435 27.3396 5.96059 31.5264 6.25464 40.558C6.40867 44.6608 6.25464 48.0214 6.25464 50.1498C6.07261 55.3447 5.77855 56.1429 5.52651 62.8501C5.44249 65.2025 5.37248 67.989 5.52651 71.5177C5.66653 74.1221 6.49062 78.6024 6.67265 78.6304C6.85469 78.6584 6.94077 71.7837 7.36085 68.7032C7.62689 66.5188 7.6689 64.9085 7.69691 63.9983C7.84333 60.3101 8.17984 56.6318 8.70509 52.9783C9.53124 46.3691 9.93732 43.0645 11.0855 39.7879C12.2337 36.5113 12.6818 33.2347 13.774 30.0981C13.9701 29.496 14.4881 28.0397 14.0541 27.2976C13.62 26.5555 12.6538 27.1436 11.6736 27.2976C8.42504 27.9697 7.1368 25.3092 5.96059 26.2334Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5032" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="52" width="10" height="29">
        <path d="M5.073 78.53L5.77286 80.5649H7.17267L8.16077 79.265L12.6896 52.3337H3.92017L4.2907 64.2844L4.74362 74.8555L5.073 78.53Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5032)">
        <path opacity="0.4" d="M5.96053 26.2334C4.64429 27.3396 5.96053 31.5264 6.25458 40.558C6.40861 44.6608 6.25458 48.0214 6.25458 50.1498C6.07255 55.3447 5.77849 56.1429 5.52645 62.8501C5.44243 65.2025 5.37242 67.989 5.52645 71.5177C5.66647 74.1221 6.49056 78.6024 6.67259 78.6304C6.85463 78.6584 6.94071 71.7837 7.36078 68.7032C7.62683 66.5188 7.66884 64.9085 7.69685 63.9983C7.84327 60.3101 8.17977 56.6318 8.70503 52.9783C9.53118 46.3691 9.93726 43.0645 11.0855 39.7879C12.2337 36.5113 12.6818 33.2347 13.774 30.0981C13.97 29.496 14.4881 28.0397 14.054 27.2976C13.6199 26.5555 12.6538 27.1436 11.6736 27.2976C8.42498 27.9697 7.13674 25.3092 5.96053 26.2334Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M2.09279 28.5855V33.8084L2.86293 49.4493C2.86293 49.4493 2.94695 57.0807 2.86293 62.9338C3.00678 67.7778 3.55006 72.6017 4.48723 77.3564C4.48723 77.3564 6.02751 83.1395 6.88167 82.8174C7.73582 82.4953 7.87585 79.2747 7.87585 79.2747L11.7266 60.3153L14.037 45.2905L15.6053 31.4C16.8867 29.418 17.8903 27.2696 18.5878 25.0148L20.4361 3.43689C20.5622 2.03663 17.5096 1.28049 17.5096 1.28049C13.6761 0.755709 9.81266 0.479753 5.9435 0.454346C1.0146 0.552364 1.16862 1.58855 1.16862 1.58855L0.552511 10.8302C0.552511 10.8302 -0.00759125 23.9366 2.09279 28.5855Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M2.96111 29.4755V34.6985L3.73125 50.3533C3.73125 50.3533 3.80126 57.9847 3.73125 63.8378C3.86644 68.6771 4.40507 73.4967 5.34154 78.2464C5.34154 78.2464 6.88183 84.0295 7.73598 83.7214C8.59014 83.4134 8.73016 80.1788 8.73016 80.1788L12.5949 61.2193L14.9053 46.1806L16.4036 32.29" fill="#DEE1E9"/>
      <path d="M21.3046 4.32694L19.4562 25.9189C18.6652 28.4693 17.469 30.8758 15.9136 33.0462C15.4342 33.7616 14.8149 34.3724 14.0929 34.8417C13.3708 35.311 12.5612 35.6291 11.7128 35.7767C6.7839 36.2388 5.7057 34.0824 3.24125 30.0776C0.776802 26.0729 1.42092 11.7343 1.42092 11.7343L2.03703 2.4786C2.03703 2.4786 1.883 1.45642 6.8119 1.3584C10.6809 1.37518 14.5446 1.64649 18.378 2.17055C18.378 2.17055 21.4306 2.85667 21.3046 4.32694Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth72",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
