export default {
    primaryKey: "id",
    model: 'log',
    order: 'id',
    sort: 'desc',
    actions: [],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'subject_id',
            title: 'model_id',
            table: true,
            sortable: true,
            filter: true,
        },
        {
            id: 'subject_type',
            title: 'model_type',
            table: true,
            sortable: true,
            tdcomponent: 'LogsDatatableTDModelType',
            filter: true,
            filter_list_select: true,
            form: {
                options: {
                    'Manager': { id: 'App\\Models\\Users\\Manager', title: 'Manager' },
                    'DoctorAssistant': { id: 'App\\Models\\Users\\DoctorAssistant', title: 'DoctorAssistant' },
                    'Doctor': { id: 'App\\Models\\Users\\Doctor', title: 'Doctor' },
                    'Record': { id: 'App\\Models\\Specific\\Treatment\\Record', title: 'Record' },
                    'Payment': { id: 'App\\Models\\Specific\\Accounting\\Payment', title: 'Payment' },
                    'Advance': { id: 'App\\Models\\Specific\\Accounting\\Advance', title: 'Advance' },
                    'Service': { id: 'App\\Models\\Specific\\Treatment\\Service', title: 'Service' },
                    'Kit': { id: 'App\\Models\\Specific\\Warehouse\\Kit', title: 'Kit' },
                    'Patient': { id: 'App\\Models\\Users\\Patient', title: 'Patient' },
                    'PlanTreatment': { id: 'App\\Models\\Specific\\Treatment\\PlanTreatment', title: 'PlanTreatment' },
                    // 'User': { id: 'App\\Models\\Users\\User', title: 'User' },
                }
            },
        },
        {
            id: 'causer_id',
            title: 'user_id',
            table: true,
            sortable: true,
            table_show: ['causer_id', 'causer.full_name'],
            // table_show: 'causer_id',
            filter: true
        },
        {
            id: 'properties',
            title: 'data',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'description',
            title: 'description',
            table: true,
            sortable: true,
            format_translate: true,
            filter_list_select: true,
            form: {
                options: {
                    'created': { id: 'created', title: 'created' },
                    'updated': { id: 'updated', title: 'updated' },
                    'deleted': { id: 'deleted', title: 'deleted' },
                    'visit services sync': { id: 'visit services sync', title: 'visit services sync' },
                }
            },
            filter: true
        },
        {
            id: 'created_at',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true
        }
    ]
}
