import {currenciesForBilling } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'billing_payment',
    order: 'id',
    sort: 'desc',
    actions: [
        // "add",
        // "edit",
        // "delete"
    ],
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'sum',
            title: 'sum',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            filter: true,
            filter_number: true,
            form: {
                type: "number",
                required: true,
            },
        },
        {
            id: 'currency',
            title: 'currency',
            table: true,
            sortable: true,
            // table_show: 'payment_type',
            format_translate: false,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                required: true,
                translate: false,
                select_type: "list",
                options: currenciesForBilling
            }
        },
        {
            id: 'pay_system',
            title: 'pay_system',
            table: true,
            sortable: true,
            filter: true,
            format_translate: true,
            filter_list_select: true,
            form: {
                options: {
                    "manual": { id: "manual", title: "manual" },
                    "ioka": { id: "ioka", title: "ioka" },
                }
            }
        },
        {
            id: 'paid',
            title: 'paid',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            tdcomponent: "SuperAdminDatatableTDPaymentStatus",
            form: {
                type: 'select',
                options: {
                    "0": { id: "0", title: "unpaid" },
                    "1": { id: "1", title: "paid" },
                }
            }
        },
        {
            id: 'created_at',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true
        }
    ]
}
