<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M26.2878 73.0204L24.2041 74.2902H20.7204L18.6204 73.0204L20.232 71.4739L18.1483 69.9274L20.0367 68.3809L17.7413 66.6879L19.5646 65.1413L17.2693 63.7413L19.2879 62.3251L17.2693 60.7134L18.9623 59.1669L16.732 57.7507L18.4902 56.2855L16.1948 54.739L18.3437 53.1925L16.1948 51.5809L17.7413 50.2297L15.6576 48.6832L17.4809 47.2018L15.3972 45.6553L17.2041 43.9135L16.1948 42.2367V27.9763L18.0832 20.6507H24.2041" fill="#8889BE"/>
      <path d="M18.8806 73.0204L20.9643 74.2902H24.4317L26.548 73.0204L24.9201 71.4739L27.0201 69.9274L25.1317 68.3809L27.4108 66.6879L25.6038 65.1413L27.8829 63.7413L25.8643 62.3251L27.8829 60.7134L26.2061 59.1669L28.4201 57.7507L26.6782 56.2855L28.9573 54.739L26.8085 53.1925L28.9573 51.5809L27.4108 50.2297L29.4945 48.6832L27.6875 47.2018L29.7712 45.6553L27.948 43.9135L28.9573 42.2367V27.9763L27.0852 20.6507H23.1782" fill="#8889BE"/>
      <path d="M2.67952 21.3995C2.94557 22.1416 3.42166 23.1918 4.30382 25.1101L10.549 26.9445C10.549 26.9445 14.5537 27.5606 17.0181 26.0343C19.4826 24.508 24.5795 26.0343 24.5795 26.0343C24.5795 26.0343 31.6648 26.9445 33.9752 26.9445C35.5452 26.8361 37.0294 26.188 38.176 25.1101C39.8283 21.9036 42.5728 15.7284 40.9765 10.4495C40.2136 8.20246 38.8182 6.22352 36.9578 4.75043C35.4718 3.55491 33.7338 2.71225 31.8749 2.28597C30.2562 1.92524 28.584 1.87298 26.946 2.13195C25.5925 2.41837 24.2556 2.7783 22.9412 3.21014C20.4488 3.93827 20.0287 4.16232 19.2305 3.98028C17.9703 3.70023 17.9703 2.93009 16.304 2.13195C15.1403 1.61601 13.8802 1.35348 12.6073 1.3618C11.0401 1.20749 9.45851 1.41838 7.9865 1.97792C5.74231 3.01001 3.94347 4.81382 2.91757 7.06084C1.96688 8.88385 1.39079 10.8787 1.22326 12.9279C1.06455 15.8254 1.56236 18.7213 2.67952 21.3995Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <!--        <path d="M36.9455 4.96744C38.7849 6.41652 40.1739 8.35921 40.9502 10.5685C42.8405 16.6036 38.9478 23.8429 37.5616 26.4474C37.2312 27.0302 36.9726 27.6508 36.7914 28.2957C36.3898 29.6577 36.2801 31.0891 36.4694 32.4965C36.8308 35.1605 37.402 37.7918 38.1777 40.3659C39.048 43.5588 39.4629 46.8587 39.4099 50.1677C39.1631 54.3216 38.1669 58.3966 36.4694 62.1959C35.7132 64.2123 33.7809 69.3652 32.6187 69.1972C32.1426 69.0991 31.9465 68.091 31.6945 65.6545C31.1764 60.4036 31.4705 58.6532 31.2324 55.8527C31.2324 55.2646 30.6163 49.0195 27.2277 44.7487C26.8575 44.1936 26.3811 43.7172 25.826 43.3471C25.2708 42.977 24.6479 42.7205 23.9931 42.5923C23.5268 42.5161 23.0494 42.5458 22.5961 42.6791C22.1429 42.8124 21.7254 43.046 21.3746 43.3624C21.0526 43.7265 20.8565 44.2166 20.9125 46.289C20.8783 47.5798 20.9816 48.8707 21.2206 50.1397C21.8087 52.9402 22.7329 53.2623 23.685 55.8527C24.1471 57.127 24.3011 59.7314 24.6092 64.9404C24.7632 67.6149 24.7632 68.8051 24.1471 69.1412C23.531 69.4772 21.9487 68.175 21.5286 67.7409C19.5683 65.8226 19.6103 64.1983 18.28 61.5798C16.9498 58.9613 16.4737 59.6614 13.9673 55.8807C12.6555 53.9783 11.57 51.9294 10.7327 49.7756C9.8085 47.3252 9.8365 46.0929 9.19239 41.7662C8.81551 38.2209 8.14577 34.713 7.19002 31.2782C6.78394 30.06 6.48989 29.948 4.85159 26.3913C3.63337 23.7589 3.01725 22.4426 2.7092 21.5745C1.61001 18.8873 1.13137 15.9868 1.30894 13.0889C1.47253 11.031 2.05885 9.02891 3.03126 7.20785C4.0285 4.99897 5.77425 3.21355 7.96016 2.16693C9.42949 1.59519 11.0097 1.36534 12.581 1.49481C13.8532 1.49315 15.112 1.75539 16.2777 2.26495C17.958 3.06309 17.944 3.84723 19.2042 4.12729C20.0024 4.29532 20.4224 4.12729 22.9149 3.34314C25.0713 2.71303 26.1635 2.39097 26.9196 2.26495C28.5581 2.01352 30.2289 2.06573 31.8485 2.41897C33.7168 2.87643 35.4585 3.74731 36.9455 4.96744Z" fill="#DEE1E9"/>-->
      <!--        <path d="M2.67952 21.5464C2.94557 22.2886 3.42166 23.3388 4.30382 25.2571L10.549 27.0914C10.549 27.0914 14.5537 27.7076 17.0181 26.1813C19.4826 24.655 24.5795 26.1813 24.5795 26.1813C24.5795 26.1813 31.6648 27.0914 33.9752 27.0914C35.5452 26.983 37.0294 26.335 38.176 25.2571C39.8283 22.0505 42.5728 15.8754 40.9765 10.5964C40.2136 8.34943 38.8182 6.37049 36.9578 4.8974C35.4718 3.70188 33.7338 2.85923 31.8749 2.43295C30.2562 2.07221 28.584 2.01996 26.946 2.27892C25.5925 2.56534 24.2556 2.92527 22.9412 3.35711C20.4488 4.08524 20.0287 4.30929 19.2305 4.12726C17.9703 3.8472 17.9703 3.07706 16.304 2.27892C15.1403 1.76298 13.8802 1.50045 12.6073 1.50877C11.0401 1.35447 9.45851 1.56536 7.9865 2.12489C5.74231 3.15698 3.94347 4.96079 2.91757 7.20781C1.96688 9.03082 1.39079 11.0257 1.22326 13.0749C1.06455 15.9724 1.56236 18.8683 2.67952 21.5464Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
      <!--        <path opacity="0.2" d="M34.8034 19.5442C36.1196 22.0226 33.6132 23.7449 32.647 30.1861C32.2494 32.978 32.3537 35.8184 32.9551 38.5736C33.7252 41.6402 34.7614 42.3823 35.7556 44.7348C38.7241 51.9881 35.3915 60.5296 34.6774 60.4596C34.4253 60.4596 34.6774 59.0594 34.2293 54.7606C34.1279 52.4164 33.7615 50.0911 33.1371 47.8293C31.7368 43.8386 30.0845 44.2727 28.5022 39.9599C27.5361 37.3134 27.7741 36.0952 26.3459 34.7229C25.7455 34.1288 25.0164 33.6808 24.2151 33.4137C23.4138 33.1466 22.5618 33.0675 21.725 33.1826C19.2045 33.7708 18.1964 36.7113 17.8743 37.6495C17.2302 39.5118 17.4402 41.2481 17.8743 44.7348C18.0022 46.3837 18.293 48.016 18.7425 49.6076C19.5686 52.2961 20.1427 52.4082 21.0529 55.4607C21.2489 56.1328 21.487 57.295 21.9771 59.6615C22.3831 61.5798 22.4111 61.9159 22.2851 61.9719C21.809 62.2099 19.4846 58.2892 18.8965 57.197C17.7001 55.1547 16.6201 53.0462 15.6619 50.8819C14.5447 48.5579 13.6628 46.1281 13.0294 43.6286C12.5769 41.1843 12.2683 38.7156 12.1052 36.2352C11.9544 33.7234 11.6033 31.2277 11.0551 28.7718C9.72481 25.2292 7.86247 24.445 8.25455 22.4427C8.59061 20.8464 10.1029 19.8382 11.4891 18.9C12.7932 17.7983 14.4197 17.1497 16.124 17.0517C17.9303 17.0517 18.4484 18.1019 20.4368 18.746C22.4635 19.2801 24.6004 19.2267 26.5979 18.592C29.3984 17.9059 30.0285 16.8557 31.6948 17.1917C32.3496 17.3423 32.9652 17.6297 33.5009 18.0352C34.0367 18.4406 34.4806 18.9549 34.8034 19.5442Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

      <path d="M36.7274 4.51383C38.5669 5.9629 39.9559 7.90559 40.7322 10.1148C42.6225 16.15 38.7298 23.3893 37.3436 25.9937C37.0132 26.5765 36.7546 27.1972 36.5734 27.8421C36.1718 29.2041 36.0621 30.6355 36.2514 32.0429C36.6128 34.7069 37.184 37.3382 37.9597 39.9123C38.83 43.1052 39.2449 46.4051 39.1919 49.7141C38.9451 53.868 37.9489 57.943 36.2514 61.7423C35.4952 63.7586 33.5629 68.9116 32.4006 68.7436C31.9246 68.6455 31.7285 67.6374 31.4765 65.2009C30.9584 59.9499 31.2524 58.1996 31.0144 55.3991C31.0144 54.811 30.3983 48.5659 27.0097 44.2951C26.6395 43.74 26.1631 43.2636 25.608 42.8935C25.0528 42.5234 24.4299 42.2669 23.7751 42.1387C23.3088 42.0625 22.8314 42.0922 22.3781 42.2255C21.9249 42.3588 21.5074 42.5924 21.1566 42.9088C20.8345 43.2729 20.6385 43.763 20.6945 45.8354C20.6603 47.1262 20.7636 48.4171 21.0026 49.6861C21.5907 52.4866 22.5148 52.8086 23.467 55.3991C23.9291 56.6734 24.0831 59.2778 24.3912 64.4868C24.5452 67.1613 24.5452 68.3515 23.9291 68.6875C23.313 69.0236 21.7307 67.7214 21.3106 67.2873C19.3503 65.3689 19.3923 63.7446 18.062 61.1262C16.7318 58.5077 16.2557 59.2078 13.7492 55.4271C12.4375 53.5247 11.352 51.4758 10.5146 49.322C9.59048 46.8716 9.61849 45.6393 8.97437 41.3125C8.59749 37.7673 7.92775 34.2594 6.972 30.8246C6.56593 29.6064 6.27188 29.4944 4.63358 25.9377C3.41536 23.3053 2.79924 21.989 2.49118 21.1209C1.39199 18.4337 0.913349 15.5331 1.09093 12.6353C1.25451 10.5774 1.84083 8.5753 2.81324 6.75423C3.81049 4.54536 5.55623 2.75994 7.74214 1.71331C9.21148 1.14157 10.7917 0.911728 12.363 1.04119C13.6352 1.03954 14.8939 1.30177 16.0597 1.81133C17.74 2.60948 17.726 3.39362 18.9862 3.67367C19.7843 3.8417 20.2044 3.67367 22.6969 2.88953C24.8533 2.25941 25.9455 1.93736 26.7016 1.81133C28.3401 1.55991 30.0109 1.61212 31.6305 1.96536C33.4988 2.42282 35.2405 3.2937 36.7274 4.51383Z" fill="#DEE1E9"/>
      <mask id="mask437" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask437)">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.5633 18.7991C19.5012 18.7991 18.2556 13.5008 18.2556 3.90252L20.1787 2.04045L23.2556 0.799072C23.2556 10.3974 21.6254 18.7991 20.5633 18.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M34.5854 19.0906C35.9016 21.5691 33.3952 23.2914 32.429 29.7325C32.0314 32.5244 32.1357 35.3649 32.737 38.1201C33.5072 41.1866 34.5434 41.9288 35.5376 44.2812C38.5061 51.5345 33.6332 66.8627 32.9191 66.7927C32.667 66.7927 34.4594 58.6058 34.0113 54.307C33.9099 51.9628 33.5435 49.6376 32.9191 47.3758C31.5188 43.385 29.8665 43.8191 28.2842 39.5063C27.3181 36.8598 27.5561 35.6416 26.1278 34.2694C25.5275 33.6752 24.7984 33.2273 23.9971 32.9601C23.1958 32.693 22.3438 32.614 21.507 32.7291C18.9865 33.3172 17.9784 36.2577 17.6563 37.1959C17.0122 39.0582 17.2222 40.7946 17.6563 44.2812C17.7842 45.9302 18.075 47.5624 18.5245 49.1541C19.3506 51.8426 19.9247 51.9546 20.8349 55.0072C21.0309 55.6793 21.269 56.8415 21.759 59.2079C22.1651 61.1263 23.5655 67.2367 23.4395 67.2927C22.9634 67.5307 19.2666 57.8357 18.6785 56.7435C17.4821 54.7011 16.4021 52.5927 15.4439 50.4283C14.3267 48.1044 13.4448 45.6745 12.8114 43.175C12.3589 40.7308 12.0503 38.2621 11.8872 35.7816C11.7364 33.2699 11.3852 30.7741 10.837 28.3183C9.5068 24.7756 7.64446 23.9915 8.03653 21.9891C8.37259 20.3928 9.88487 19.3847 11.2711 18.4465C12.5752 17.3448 14.2016 16.6962 15.906 16.5981C17.7123 16.5981 18.2304 17.6483 20.2188 18.2925C22.2455 18.8266 24.3824 18.7731 26.3799 18.1384C29.1804 17.4523 29.8105 16.4021 31.4768 16.7382C32.1316 16.8887 32.7471 17.1762 33.2829 17.5816C33.8187 17.9871 34.2626 18.5013 34.5854 19.0906Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M36.9455 4.96744C38.7849 6.41652 40.1739 8.35921 40.9502 10.5685C42.8405 16.6036 38.9478 23.8429 37.5616 26.4474C37.2312 27.0302 36.9726 27.6508 36.7914 28.2957C36.3898 29.6577 36.2801 31.0891 36.4694 32.4965C36.8308 35.1605 37.402 37.7918 38.1777 40.3659C39.048 43.5588 39.4629 46.8587 39.4099 50.1677C39.1631 54.3216 38.1669 58.3966 36.4694 62.1959C35.7132 64.2123 33.7809 69.3652 32.6187 69.1972C32.1426 69.0991 31.9465 68.091 31.6945 65.6545C31.1764 60.4036 31.4705 58.6532 31.2324 55.8527C31.2324 55.2646 30.6163 49.0195 27.2277 44.7487C26.8575 44.1936 26.3811 43.7172 25.826 43.3471C25.2708 42.977 24.6479 42.7205 23.9931 42.5923C23.5268 42.5161 23.0494 42.5458 22.5961 42.6791C22.1429 42.8124 21.7254 43.046 21.3746 43.3624C21.0526 43.7265 20.8565 44.2166 20.9125 46.289C20.8783 47.5798 20.9816 48.8707 21.2206 50.1397C21.8087 52.9402 22.7329 53.2623 23.685 55.8527C24.1471 57.127 24.3011 59.7314 24.6092 64.9404C24.7632 67.6149 24.7632 68.8051 24.1471 69.1412C23.531 69.4772 21.9487 68.175 21.5286 67.7409C19.5683 65.8226 19.6103 64.1983 18.28 61.5798C16.9498 58.9613 16.4737 59.6614 13.9673 55.8807C12.6555 53.9783 11.57 51.9294 10.7327 49.7756C9.8085 47.3252 9.8365 46.0929 9.19239 41.7662C8.81551 38.2209 8.14577 34.713 7.19002 31.2782C6.78394 30.06 6.48989 29.948 4.85159 26.3913C3.63337 23.7589 3.01725 22.4426 2.7092 21.5745C1.61001 18.8873 1.13137 15.9868 1.30894 13.0889C1.47253 11.031 2.05885 9.02891 3.03126 7.20785C4.0285 4.99897 5.77425 3.21355 7.96016 2.16693C9.42949 1.59519 11.0097 1.36534 12.581 1.49481C13.8532 1.49315 15.112 1.75539 16.2777 2.26495C17.958 3.06309 17.944 3.84723 19.2042 4.12729C20.0024 4.29532 20.4224 4.12729 22.9149 3.34314C25.0713 2.71303 26.1635 2.39097 26.9196 2.26495C28.5581 2.01352 30.2289 2.06573 31.8485 2.41897C33.7168 2.87643 35.4585 3.74731 36.9455 4.96744Z" fill="#DEE1E9"/>
      <path d="M2.67952 21.5464C2.94557 22.2886 3.42166 23.3388 4.30382 25.2571L10.549 27.0914C10.549 27.0914 14.5537 27.7076 17.0181 26.1813C19.4826 24.655 24.5795 26.1813 24.5795 26.1813C24.5795 26.1813 31.6648 27.0914 33.9752 27.0914C35.5452 26.983 37.0294 26.335 38.176 25.2571C39.8283 22.0505 42.5728 15.8754 40.9765 10.5964C40.2136 8.34943 38.8182 6.37049 36.9578 4.8974C35.4718 3.70188 33.7338 2.85923 31.8749 2.43295C30.2562 2.07221 28.584 2.01996 26.946 2.27892C25.5925 2.56534 24.2556 2.92527 22.9412 3.35711C20.4488 4.08524 20.0287 4.30929 19.2305 4.12726C17.9703 3.8472 17.9703 3.07706 16.304 2.27892C15.1403 1.76298 13.8802 1.50045 12.6073 1.50877C11.0401 1.35447 9.45851 1.56536 7.9865 2.12489C5.74231 3.15698 3.94347 4.96079 2.91757 7.20781C1.96688 9.03082 1.39079 11.0257 1.22326 13.0749C1.06455 15.9724 1.56236 18.8683 2.67952 21.5464Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M34.8034 19.5442C36.1196 22.0226 33.6132 23.7449 32.647 30.1861C32.2494 32.978 32.3537 35.8184 32.9551 38.5736C33.7252 41.6402 34.7614 42.3823 35.7556 44.7348C38.7241 51.9881 35.3915 60.5296 34.6774 60.4596C34.4253 60.4596 34.6774 59.0594 34.2293 54.7606C34.1279 52.4164 33.7615 50.0911 33.1371 47.8293C31.7368 43.8386 30.0845 44.2727 28.5022 39.9599C27.5361 37.3134 27.7741 36.0952 26.3459 34.7229C25.7455 34.1288 25.0164 33.6808 24.2151 33.4137C23.4138 33.1466 22.5618 33.0675 21.725 33.1826C19.2045 33.7708 18.1964 36.7113 17.8743 37.6495C17.2302 39.5118 17.4402 41.2481 17.8743 44.7348C18.0022 46.3837 18.293 48.016 18.7425 49.6076C19.5686 52.2961 20.1427 52.4082 21.0529 55.4607C21.2489 56.1328 21.487 57.295 21.9771 59.6615C22.3831 61.5798 22.4111 61.9159 22.2851 61.9719C21.809 62.2099 19.4846 58.2892 18.8965 57.197C17.7001 55.1547 16.6201 53.0462 15.6619 50.8819C14.5447 48.5579 13.6628 46.1281 13.0294 43.6286C12.5769 41.1843 12.2683 38.7156 12.1052 36.2352C11.9544 33.7234 11.6033 31.2277 11.0551 28.7718C9.72481 25.2292 7.86247 24.445 8.25455 22.4427C8.59061 20.8464 10.1029 19.8382 11.4891 18.9C12.7932 17.7983 14.4197 17.1497 16.124 17.0517C17.9303 17.0517 18.4484 18.1019 20.4368 18.746C22.4635 19.2801 24.6004 19.2267 26.5979 18.592C29.3984 17.9059 30.0285 16.8557 31.6948 17.1917C32.3496 17.3423 32.9652 17.6297 33.5009 18.0352C34.0367 18.4406 34.4806 18.9549 34.8034 19.5442Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="23" cy="69" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="33" cy="69" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M35.9455 4.42276C37.7849 5.87184 39.1739 7.81453 39.9502 10.0238C41.8405 16.0589 37.9478 23.2982 36.5616 25.9027C36.2312 26.4855 35.9726 27.1061 35.7914 27.751C35.3898 29.113 35.2801 30.5445 35.4694 31.9518C35.8308 34.6158 36.402 37.2471 37.1777 39.8212C38.048 43.0141 38.4629 46.314 38.4099 49.623C38.1631 53.7769 37.1669 57.8519 35.4694 61.6512C34.7132 63.6676 32.7809 68.8205 31.6187 68.6525C31.1426 68.5545 30.9465 67.5463 30.6945 65.1098C30.1764 59.8589 30.4705 58.1086 30.2324 55.3081C30.2324 54.7199 29.6163 48.4748 26.2277 44.204C25.8575 43.6489 25.3811 43.1726 24.826 42.8024C24.2708 42.4323 23.6479 42.1758 22.9931 42.0476C22.5268 41.9715 22.0494 42.0011 21.5961 42.1344C21.1429 42.2677 20.7254 42.5013 20.3746 42.8178C20.0526 43.1818 19.8565 43.6719 19.9125 45.7443C19.8783 47.0352 19.9816 48.326 20.2206 49.595C20.8087 52.3955 21.7329 52.7176 22.685 55.3081C23.1471 56.5823 23.3011 59.1868 23.6092 64.3957C23.7632 67.0702 23.7632 68.2604 23.1471 68.5965C22.531 68.9325 20.9487 67.6303 20.5286 67.1962C18.5683 65.2779 18.6103 63.6536 17.28 61.0351C15.9498 58.4166 15.4737 59.1167 12.9673 55.3361C11.6555 53.4336 10.57 51.3847 9.73266 49.2309C8.8085 46.7805 8.8365 45.5483 8.19239 41.2215C7.81551 37.6762 7.14577 34.1683 6.19002 30.7336C5.78394 29.5153 5.48989 29.4033 3.85159 25.8467C2.63337 23.2142 2.01725 21.898 1.7092 21.0298C0.610008 18.3426 0.131367 15.4421 0.308945 12.5442C0.472527 10.4863 1.05885 8.48424 2.03126 6.66317C3.0285 4.45429 4.77425 2.66888 6.96016 1.62225C8.42949 1.05051 10.0097 0.820663 11.581 0.950127C12.8532 0.948473 14.112 1.21071 15.2777 1.72027C16.958 2.51841 16.944 3.30256 18.2042 3.58261C19.0024 3.75064 19.4224 3.58261 21.9149 2.79846C24.0713 2.16835 25.1635 1.84629 25.9196 1.72027C27.5581 1.46884 29.2289 1.52105 30.8485 1.8743C32.7168 2.33175 34.4585 3.20264 35.9455 4.42276Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M35.9455 4.42276C37.7849 5.87184 39.1739 7.81453 39.9502 10.0238C41.8405 16.0589 37.9478 23.2982 36.5616 25.9027C36.2312 26.4855 35.9726 27.1061 35.7914 27.751C35.3898 29.113 35.2801 30.5445 35.4694 31.9518C35.8308 34.6158 36.402 37.2471 37.1777 39.8212C38.048 43.0141 38.4629 46.314 38.4099 49.623C38.1631 53.7769 37.1669 57.8519 35.4694 61.6512C34.7132 63.6676 32.7809 68.8205 31.6187 68.6525C31.1426 68.5545 30.9465 67.5463 30.6945 65.1098C30.1764 59.8589 30.4705 58.1086 30.2324 55.3081C30.2324 54.7199 29.6163 48.4748 26.2277 44.204C25.8575 43.6489 25.3811 43.1726 24.826 42.8024C24.2708 42.4323 23.6479 42.1758 22.9931 42.0476C22.5268 41.9715 22.0494 42.0011 21.5961 42.1344C21.1429 42.2677 20.7254 42.5013 20.3746 42.8178C20.0526 43.1818 19.8565 43.6719 19.9125 45.7443C19.8783 47.0352 19.9816 48.326 20.2206 49.595C20.8087 52.3955 21.7329 52.7176 22.685 55.3081C23.1471 56.5823 23.3011 59.1868 23.6092 64.3957C23.7632 67.0702 23.7632 68.2604 23.1471 68.5965C22.531 68.9325 20.9487 67.6303 20.5286 67.1962C18.5683 65.2779 18.6103 63.6536 17.28 61.0351C15.9498 58.4166 15.4737 59.1167 12.9673 55.3361C11.6555 53.4336 10.57 51.3847 9.73266 49.2309C8.8085 46.7805 8.8365 45.5483 8.19239 41.2215C7.81551 37.6762 7.14577 34.1683 6.19002 30.7336C5.78394 29.5153 5.48989 29.4033 3.85159 25.8467C2.63337 23.2142 2.01725 21.898 1.7092 21.0298C0.610008 18.3426 0.131367 15.4421 0.308945 12.5442C0.472527 10.4863 1.05885 8.48424 2.03126 6.66317C3.0285 4.45429 4.77425 2.66888 6.96016 1.62225C8.42949 1.05051 10.0097 0.820663 11.581 0.950127C12.8532 0.948473 14.112 1.21071 15.2777 1.72027C16.958 2.51841 16.944 3.30256 18.2042 3.58261C19.0024 3.75064 19.4224 3.58261 21.9149 2.79846C24.0713 2.16835 25.1635 1.84629 25.9196 1.72027C27.5581 1.46884 29.2289 1.52105 30.8485 1.8743C32.7168 2.33175 34.4585 3.20264 35.9455 4.42276Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M34.3057 9.75156C34.4869 9.10664 34.7455 8.48602 35.0759 7.90322C35.3933 7.30688 35.8421 6.46755 36.3228 5.45533L29.9879 3.03033L23.7462 5.45533L17.2176 3.42764L11.9193 5.20577L0.700985 4.20855C1.06421 5.04044 1.59426 6.17979 2.36589 7.84721C4.00419 11.4039 4.29824 11.5159 4.70432 12.7341C5.66007 16.1688 6.32981 19.6768 6.70669 23.222C7.35081 27.5488 7.3228 28.781 8.24697 31.2315C9.08432 33.3853 10.1698 35.4341 11.4816 37.3366C13.988 41.1173 14.4641 40.4172 15.7943 43.0356C17.1246 45.6541 17.0826 47.2784 19.0429 49.1968C19.463 49.6308 21.0453 50.9331 21.6614 50.597C22.2775 50.261 22.2775 49.0707 22.1235 46.3963C21.8155 41.1873 21.6614 38.5828 21.1993 37.3086C20.2472 34.7181 19.323 34.3961 18.7349 31.5956C18.4959 30.3265 18.3926 29.0357 18.4268 27.7448C18.3708 25.6725 18.5669 25.1824 18.8889 24.8183C19.2397 24.5018 19.6572 24.2683 20.1104 24.135C20.5637 24.0017 21.0411 23.972 21.5074 24.0482C22.1622 24.1764 22.7851 24.4329 23.3403 24.803C23.8954 25.1731 24.3718 25.6494 24.742 26.2046C28.1306 30.4753 28.7467 36.7205 28.7467 37.3086C28.9848 40.1091 28.6907 41.8594 29.2088 47.1104C29.4608 49.5468 29.6569 50.555 30.133 50.653C31.2952 50.8211 33.2275 45.6681 33.9837 43.6518C35.6812 39.8525 36.6774 35.7775 36.9242 31.6236C36.9772 28.3146 36.5623 25.0147 35.692 21.8218C34.9163 19.2477 34.3451 16.6164 33.9837 13.9523C33.7944 12.545 33.9042 11.1136 34.3057 9.75156Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M30.4693 20.0295C29.868 17.2743 29.7637 14.4338 30.1613 11.6419C31.0333 5.82885 33.1598 3.85921 32.5987 1.70853L28.2112 1L24.2818 2.4979L19.2393 1L15.0303 2.4979L11.2818 1.56521L6.28296 2.70853C6.04118 3.06817 5.86088 3.46129 5.76883 3.89853C5.37676 5.9009 7.2391 6.68504 8.56934 10.2277C9.11754 12.6835 9.46872 15.1793 9.61953 17.691C9.78262 20.1715 10.0912 22.6402 10.5437 25.0844C11.1771 27.5839 12.059 30.0138 13.1762 32.3377C14.1344 34.5021 15.2144 36.6105 16.4108 38.6529C16.9989 39.7451 20.6957 49.4401 21.1718 49.2021C21.2978 49.1461 19.8974 43.0357 19.4913 41.1173L19.4287 40.8146C18.9806 38.6505 18.7547 37.5594 18.5672 36.9166C17.657 33.864 17.0829 33.752 16.2568 31.0635C15.8073 29.4718 15.5165 27.8396 15.3886 26.1906C14.9545 22.704 14.7445 20.9676 15.3886 19.1053L15.3891 19.1038C15.7117 18.164 16.7202 15.2263 19.2393 14.6385C20.0761 14.5234 20.9281 14.6024 21.7294 14.8696C22.5307 15.1367 23.2598 15.5846 23.8601 16.1788C25.2884 17.551 25.0504 18.7692 26.0165 21.4157C27.5988 25.7285 29.2511 25.2944 30.6514 29.2852C31.2758 31.547 31.6422 33.8722 31.7436 36.2164C32.1917 40.5152 30.3993 48.7021 30.6514 48.7021C31.3655 48.7721 36.2384 33.4439 33.2699 26.1906C32.2757 23.8382 31.2395 23.096 30.4693 20.0295Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M31.9537 8.43184C21.1131 11.7838 15.2296 11.191 5.00191 6.57201" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M37.0983 4.50772C38.9377 5.9568 40.3268 7.89949 41.103 10.1087C42.9934 16.1438 39.1007 23.3832 37.7144 25.9876C37.3841 26.5704 37.1255 27.1911 36.9443 27.836C36.5427 29.198 36.4329 30.6294 36.6222 32.0367C36.9836 34.7008 37.5548 37.3321 38.3305 39.9062C39.2009 43.0991 39.6157 46.399 39.5627 49.708C39.3159 53.8619 38.3197 57.9369 36.6222 61.7362C35.8661 63.7525 33.9337 68.9055 32.7715 68.7374C32.2954 68.6394 32.0994 67.6312 31.8473 65.1948C31.3292 59.9438 31.6233 58.1935 31.3852 55.393C31.3852 54.8049 30.7691 48.5598 27.3805 44.289C27.0104 43.7339 26.534 43.2575 25.9788 42.8874C25.4236 42.5173 24.8007 42.2608 24.1459 42.1326C23.6797 42.0564 23.2022 42.0861 22.749 42.2194C22.2957 42.3527 21.8782 42.5862 21.5274 42.9027C21.2054 43.2668 21.0093 43.7569 21.0654 45.8293C21.0312 47.1201 21.1344 48.411 21.3734 49.68C21.9615 52.4805 22.8857 52.8025 23.8379 55.393C24.3 56.6672 24.454 59.2717 24.762 64.4807C24.9161 67.1552 24.9161 68.3454 24.3 68.6814C23.6838 69.0175 22.1015 67.7153 21.6815 67.2812C19.7211 65.3628 19.7631 63.7385 18.4329 61.1201C17.1026 58.5016 16.6265 59.2017 14.1201 55.421C12.8084 53.5186 11.7229 51.4697 10.8855 49.3159C9.96133 46.8655 9.98934 45.6332 9.34522 41.3064C8.96835 37.7612 8.2986 34.2533 7.34285 30.8185C6.93678 29.6003 6.64272 29.4883 5.00443 25.9316C3.7862 23.2992 3.17009 21.9829 2.86203 21.1148C1.76284 18.4276 7.16528 18.1908 7.34285 15.293C7.50644 13.235 10.6541 12.114 11.6265 10.293C12.6237 8.08409 5.92708 2.75384 8.11299 1.70721C9.58232 1.13547 11.1625 0.905624 12.7338 1.03509C14.0061 1.03343 15.2648 1.29567 16.4305 1.80523C18.1108 2.60337 18.0968 3.38752 19.357 3.66757C20.1552 3.8356 20.5753 3.66757 23.0677 2.88343C25.2241 2.25331 26.3163 1.93125 27.0725 1.80523C28.711 1.5538 30.3817 1.60601 32.0014 1.95926C33.8696 2.41671 35.6114 3.2876 37.0983 4.50772Z" fill="#DEE1E9"/>
      <path d="M4.45665 24.7974C3.57449 22.879 3.0984 21.8289 2.83236 21.0867C2.05601 19.2256 1.57876 17.2593 1.41309 15.2604H2.12646H6.03356L5.12646 12.6152L6.33404 10.1483L5.12646 7.83487L8.93555 6.32013L7.59682 3.28516L6.81788 2.39453C7.23776 2.12085 7.67918 1.87679 8.13933 1.66517C9.61134 1.10564 11.193 0.894751 12.7602 1.04906C14.0331 1.04073 15.2932 1.30326 16.4568 1.8192C18.1231 2.61735 18.1231 3.38749 19.3834 3.66754C20.1815 3.84957 20.6016 3.62553 23.0941 2.89739C24.4084 2.46555 25.7453 2.10563 27.0988 1.8192C28.7368 1.56024 30.409 1.6125 32.0277 1.97323C33.8867 2.39951 35.6246 3.24216 37.1106 4.43768C38.971 5.91078 40.3665 7.88971 41.1294 10.1367C42.7256 15.4157 39.9811 21.5908 38.3288 24.7974C37.1822 25.8752 35.698 26.5233 34.1281 26.6317C31.8177 26.6317 24.7324 25.7216 24.7324 25.7216C24.7324 25.7216 19.6354 24.1953 17.171 25.7216C14.7065 27.2478 10.7018 26.6317 10.7018 26.6317L4.45665 24.7974Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M4.95069 23.8375C15.0497 19.8424 17.3627 15.3495 16.207 3.79298" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M34.9562 19.0844C36.2725 21.5629 33.766 23.2852 32.7998 29.7264C32.4022 32.5183 32.5066 35.3587 33.1079 38.1139C33.878 41.1805 34.9142 41.9226 35.9084 44.275C38.877 51.5284 34.0041 66.8565 33.2899 66.7865C33.0379 66.7865 34.8302 58.5997 34.3821 54.3009C34.2807 51.9566 33.9143 49.6314 33.2899 47.3696C31.8897 43.3789 30.2374 43.813 28.6551 39.5002C27.6889 36.8537 27.927 35.6355 26.4987 34.2632C25.8983 33.6691 25.1693 33.2211 24.368 32.954C23.5667 32.6869 22.7146 32.6078 21.8778 32.7229C19.3574 33.311 18.3492 36.2516 18.0271 37.1897C17.383 39.0521 17.5931 40.7884 18.0271 44.275C18.155 45.924 18.4459 47.5563 18.8953 49.1479C19.7215 51.8364 20.2956 51.9484 21.2057 55.001C21.4018 55.6731 21.6398 56.8353 22.1299 59.2018C22.536 61.1201 23.9363 67.2305 23.8103 67.2865C23.3342 67.5245 19.6374 57.8295 19.0493 56.7373C17.8529 54.6949 16.773 52.5865 15.8147 50.4222C14.6976 48.0982 13.8157 45.6683 13.1823 43.1688C12.7298 40.7246 12.4212 38.2559 12.2581 35.7755C12.1073 33.2637 11.7561 30.768 11.2079 28.3121C9.87765 24.7695 8.01531 23.9853 8.40738 21.983C8.74344 20.3867 10.2557 19.3785 11.642 18.4403C12.946 17.3386 14.5725 16.69 16.2768 16.592C18.0832 16.592 18.6012 17.6422 20.5896 18.2863C22.6164 18.8204 24.7532 18.767 26.7507 18.1323C29.5513 17.4461 30.1814 16.3959 31.8477 16.732C32.5025 16.8826 33.118 17.17 33.6538 17.5755C34.1895 17.9809 34.6334 18.4952 34.9562 19.0844Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M36.7226 4.96512C38.562 6.4142 39.951 8.35689 40.7273 10.5661C42.6176 16.6012 38.7249 23.8406 37.3387 26.445C37.0083 27.0278 36.7497 27.6485 36.5685 28.2934C36.1669 29.6554 36.0572 31.0868 36.2465 32.4941C36.6079 35.1582 37.1791 37.7895 37.9548 40.3636C38.8251 43.5565 39.24 46.8564 39.187 50.1654C38.9402 54.3193 37.944 58.3943 36.2465 62.1936C35.4903 64.2099 33.558 69.3629 32.3958 69.1948C31.9197 69.0968 31.7236 68.0886 31.4716 65.6522C30.9535 60.4012 31.2476 58.6509 31.0095 55.8504C31.0095 55.2623 30.3934 49.0172 27.0048 44.7464C26.6346 44.1913 26.1582 43.7149 25.6031 43.3448C25.0479 42.9747 24.425 42.7182 23.7702 42.59C23.3039 42.5138 22.8265 42.5435 22.3732 42.6768C21.92 42.8101 21.5025 43.0436 21.1517 43.3601C20.8297 43.7242 20.6336 44.2143 20.6896 46.2867C20.6554 47.5775 20.7587 48.8684 20.9977 50.1374C21.5858 52.9379 22.51 53.2599 23.4621 55.8504C23.9242 57.1246 24.0782 59.7291 24.3863 64.9381C24.5403 67.6126 24.5403 68.8028 23.9242 69.1388C23.3081 69.4749 21.7258 68.1727 21.3057 67.7386C19.3454 65.8202 19.3874 64.1959 18.0571 61.5775C16.7269 58.959 16.2508 59.6591 13.7444 55.8784C12.4326 53.9759 11.3471 51.9271 10.5098 49.7733C9.5856 47.3229 9.6136 46.0906 8.96949 41.7638C8.59261 38.2186 7.92287 34.7106 6.96712 31.2759C6.56104 30.0577 6.26699 29.9457 4.62869 26.389C3.41047 23.7565 2.79435 22.4403 2.4863 21.5722C1.38711 18.885 0.908466 15.9844 1.08604 13.0866C1.24963 11.0287 1.83595 9.02659 2.80836 7.20553C3.8056 4.99665 5.55135 3.21123 7.73726 2.16461C9.20659 1.59287 10.7868 1.36302 12.3581 1.49249C13.6303 1.49083 14.8891 1.75307 16.0548 2.26263C17.7351 3.06077 17.7211 3.84492 18.9813 4.12497C19.7795 4.293 20.1995 4.12497 22.692 3.34082C24.8484 2.71071 25.9406 2.38865 26.6967 2.26263C28.3352 2.0112 30.006 2.06341 31.6256 2.41665C33.4939 2.87411 35.2356 3.745 36.7226 4.96512Z" fill="#DEE1E9"/>
      <path d="M4.08092 25.2548C3.19876 23.3364 2.72267 22.2863 2.45662 21.5441C1.33946 18.866 0.841648 15.9701 1.00036 13.0726C1.16789 11.0234 1.74398 9.0285 2.69467 7.20549C3.72057 4.95847 5.51941 3.15466 7.7636 2.12257C9.23561 1.56304 10.8172 1.35215 12.3844 1.50645C13.6573 1.49813 14.9174 1.76066 16.0811 2.2766C17.7474 3.07474 17.7474 3.84488 19.0076 4.12494C19.8058 4.30697 20.2259 4.08292 22.7183 3.35479C24.0327 2.92295 25.3696 2.56302 26.7231 2.2766C28.3611 2.01764 30.0333 2.06989 31.652 2.43063C33.5109 2.85691 35.2489 3.69956 36.7349 4.89508C38.5953 6.36817 39.9907 8.34711 40.7536 10.5941C42.3499 15.8731 39.6054 22.0482 37.9531 25.2548C36.8065 26.3326 35.3223 26.9807 33.7523 27.0891C32.8525 27.0891 29.4705 26.952 27.603 26.7835C27.3749 26.7629 27.1749 26.6478 27.1146 26.4269C26.6639 24.7738 26.2507 17.7494 26.2507 17.7494C21.5643 16.7692 19.9372 16.6027 15.2507 17.7494L13.9132 25.7746C13.8232 26.3148 13.3153 26.6821 12.7741 26.5984L4.08092 25.2548Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M37.0983 4.71573C38.9377 6.16481 40.3268 8.1075 41.103 10.3168C42.9934 16.3519 39.1007 23.5912 37.7144 26.1957C37.3841 26.7784 37.1255 27.3991 36.9443 28.044C36.5427 29.406 36.4329 30.8374 36.6222 32.2448C36.9836 34.9088 37.5548 37.5401 38.3305 40.1142C39.2009 43.3071 39.6157 46.607 39.5627 49.916C39.3159 54.0699 38.3197 58.1449 36.6222 61.9442C35.8661 63.9605 33.9337 69.1135 32.7715 68.9455C32.2954 68.8474 32.0994 67.8393 31.8473 65.4028C31.3292 60.1519 31.6233 58.4015 31.3852 55.601C31.3852 55.0129 30.7691 48.7678 27.3805 44.497C27.0104 43.9419 26.534 43.4655 25.9788 43.0954C25.4236 42.7253 24.8007 42.4688 24.1459 42.3406C23.6796 42.2644 23.2022 42.2941 22.749 42.4274C22.2957 42.5607 21.8782 42.7943 21.5274 43.1107C21.2054 43.4748 21.0093 43.9649 21.0654 46.0373C21.0312 47.3281 21.1344 48.619 21.3734 49.888C21.9615 52.6885 22.8857 53.0105 23.8379 55.601C24.3 56.8753 24.454 59.4797 24.762 64.6887C24.9161 67.3632 24.9161 68.5534 24.3 68.8895C23.6838 69.2255 22.1015 67.9233 21.6815 67.4892C19.7211 65.5708 19.7631 63.9465 18.4329 61.3281C17.1026 58.7096 16.6265 59.4097 14.1201 55.629C12.8084 53.7266 11.7229 51.6777 10.8855 49.5239C9.96133 47.0735 9.98934 45.8412 9.34522 41.5145C8.96834 37.9692 8.2986 34.4613 7.34285 31.0265C6.93678 29.8083 6.64273 29.6963 5.00443 26.1396C3.78621 23.5072 3.17009 22.1909 2.86203 21.3228C1.76284 18.6356 1.2842 15.7351 1.46178 12.8372C1.62536 10.7793 2.21168 8.7772 3.18409 6.95614C4.18134 4.74726 5.92708 2.96184 8.11299 1.91522C9.58232 1.34348 11.1625 1.11363 12.7338 1.2431C14.0061 1.24144 15.2648 1.50368 16.4305 2.01324C18.1108 2.81138 18.0968 3.59553 19.357 3.87558C20.1552 4.04361 20.5753 3.87558 23.0677 3.09143C25.2241 2.46132 26.3163 2.13926 27.0725 2.01324C28.711 1.76181 30.3817 1.81402 32.0014 2.16726C33.8696 2.62472 35.6114 3.49561 37.0983 4.71573Z" fill="#DEE1E9"/>
      <path d="M4.45665 25.0054C3.57449 23.0871 3.0984 22.0369 2.83236 21.2947C1.71519 18.6166 1.21738 15.7207 1.37609 12.8232C1.54362 10.774 2.11972 8.77911 3.0704 6.9561C4.0963 4.70908 5.89514 2.90527 8.13933 1.87318C9.61134 1.31365 11.193 1.10276 12.7602 1.25706C14.0331 1.24874 15.2932 1.51127 16.4568 2.02721C18.1231 2.82535 18.1231 3.59549 19.3834 3.87555C20.1815 4.05758 20.6016 3.83353 23.0941 3.1054C24.4084 2.67356 25.7453 2.31363 27.0988 2.02721C28.7368 1.76825 30.409 1.82051 32.0277 2.18124C33.8867 2.60752 35.6246 3.45017 37.1106 4.64569C38.971 6.11878 40.3665 8.09772 41.1294 10.3447C42.7256 15.6237 39.9811 21.7988 38.3288 25.0054C37.1822 26.0832 35.698 26.7313 34.1281 26.8397C33.2282 26.8397 29.8463 26.7026 27.9787 26.5341C27.7507 26.5135 27.5506 26.3984 27.4904 26.1775C27.0396 24.5244 26.6265 17.5 26.6265 17.5C21.9401 16.5198 20.3129 16.3533 15.6265 17.5L14.2889 25.5252C14.1989 26.0654 13.691 26.4327 13.1498 26.349L4.45665 25.0054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M36.7274 4.71573C38.5669 6.16481 39.9559 8.1075 40.7322 10.3168C42.6225 16.3519 38.7298 23.5912 37.3436 26.1957C37.0132 26.7784 36.7546 27.3991 36.5734 28.044C36.1718 29.406 36.0621 30.8374 36.2514 32.2448C36.6128 34.9088 37.184 37.5401 37.9597 40.1142C38.83 43.3071 39.2449 46.607 39.1919 49.916C38.9451 54.0699 37.9489 58.1449 36.2514 61.9442C35.4952 63.9605 33.5629 69.1135 32.4006 68.9455C31.9246 68.8474 31.7285 67.8393 31.4765 65.4028C30.9584 60.1519 31.2524 58.4015 31.0144 55.601C31.0144 55.0129 30.3983 48.7678 27.0097 44.497C26.6395 43.9419 26.1631 43.4655 25.608 43.0954C25.0528 42.7253 24.4299 42.4688 23.7751 42.3406C23.3088 42.2644 22.8314 42.2941 22.3781 42.4274C21.9249 42.5607 21.5074 42.7943 21.1566 43.1107C20.8345 43.4748 20.6385 43.9649 20.6945 46.0373C20.6603 47.3281 20.7636 48.619 21.0026 49.888C21.5907 52.6885 22.5148 53.0105 23.467 55.601C23.9291 56.8753 24.0831 59.4797 24.3912 64.6887C24.5452 67.3632 24.5452 68.5534 23.9291 68.8895C23.313 69.2255 21.7307 67.9233 21.3106 67.4892C19.3503 65.5708 19.3923 63.9465 18.062 61.3281C16.7318 58.7096 16.2557 59.4097 13.7492 55.629C12.4375 53.7266 11.352 51.6777 10.5146 49.5239C9.59048 47.0735 9.61849 45.8412 8.97437 41.5145C8.59749 37.9692 7.92775 34.4613 6.972 31.0265C6.56593 29.8083 6.27188 29.6963 4.63358 26.1396C3.41536 23.5072 2.79924 22.1909 2.49118 21.3228C1.39199 18.6356 0.913349 15.7351 1.09093 12.8372C1.25451 10.7793 1.84083 8.7772 2.81324 6.95614C3.81049 4.74726 5.55623 2.96184 7.74214 1.91522C9.21148 1.34348 10.7917 1.11363 12.363 1.2431C13.6352 1.24144 14.8939 1.50368 16.0597 2.01324C17.74 2.81138 17.726 3.59553 18.9862 3.87558C19.7843 4.04361 20.2044 3.87558 22.6969 3.09143C24.8533 2.46132 25.9455 2.13926 26.7016 2.01324C28.3401 1.76181 30.0109 1.81402 31.6305 2.16726C33.4988 2.62472 35.2405 3.49561 36.7274 4.71573Z" fill="#DEE1E9"/>
      <mask id="mask2037" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M4.10118 24.7974C3.21902 22.879 2.74294 21.8289 2.47689 21.0867C1.35972 18.4086 0.861912 15.5127 1.02062 12.6152C1.18816 10.566 1.76425 8.57111 2.71493 6.7481C3.74083 4.50107 5.53967 2.69726 7.78386 1.66517C9.25587 1.10564 10.8375 0.894751 12.4047 1.04906C13.6776 1.04073 14.9377 1.30326 16.1014 1.8192C17.7677 2.61735 17.7677 3.38749 19.0279 3.66754C19.8261 3.84957 20.2461 3.62553 22.7386 2.89739C24.0529 2.46555 25.3898 2.10563 26.7433 1.8192C28.3814 1.56024 30.0535 1.6125 31.6722 1.97323C33.5312 2.39951 35.2692 3.24216 36.7552 4.43768C38.6155 5.91078 40.011 7.88971 40.7739 10.1367C42.3702 15.4157 39.6257 21.5908 37.9734 24.7974C36.8267 25.8752 35.3426 26.5233 33.7726 26.6317C32.8727 26.6317 29.4908 26.4946 27.6232 26.3261C27.3952 26.3055 27.1951 26.1904 27.1349 25.9695C26.6841 24.3164 26.271 17.292 26.271 17.292C21.5846 16.3118 19.9575 16.1453 15.271 17.292L13.9335 25.3172C13.8434 25.8574 13.3356 26.2247 12.7943 26.141L4.10118 24.7974Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2037)">
        <path d="M4.10118 24.7974C3.21902 22.879 2.74294 21.8289 2.47689 21.0867C1.35972 18.4086 0.861912 15.5127 1.02062 12.6152C1.18816 10.566 1.76425 8.57111 2.71493 6.7481C3.74083 4.50107 5.53967 2.69726 7.78386 1.66517C9.25587 1.10564 10.8375 0.894751 12.4047 1.04906C13.6776 1.04073 14.9377 1.30326 16.1014 1.8192C17.7677 2.61735 17.7677 3.38749 19.0279 3.66754C19.8261 3.84957 20.2461 3.62553 22.7386 2.89739C24.0529 2.46555 25.3898 2.10563 26.7433 1.8192C28.3814 1.56024 30.0535 1.6125 31.6722 1.97323C33.5312 2.39951 35.2692 3.24216 36.7552 4.43768C38.6155 5.91078 40.011 7.88971 40.7739 10.1367C42.3702 15.4157 39.6257 21.5908 37.9734 24.7974C36.8267 25.8752 35.3426 26.5233 33.7726 26.6317C32.8727 26.6317 29.4908 26.4946 27.6232 26.3261C27.3952 26.3055 27.1951 26.1904 27.1349 25.9695C26.6841 24.3164 26.271 17.292 26.271 17.292C21.5846 16.3118 19.9575 16.1453 15.271 17.292L13.9335 25.3172C13.8434 25.8574 13.3356 26.2247 12.7943 26.141L4.10118 24.7974Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M8.25562 40H32.2556V14.452C22.883 12.5258 17.6282 12.5062 8.25562 14.452V40Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M36.253 4.1547C38.0924 5.60377 39.4814 7.54646 40.2577 9.75572C42.148 15.7908 38.2553 23.0301 36.8691 25.6346C36.5387 26.2174 36.2801 26.838 36.0989 27.483C35.6973 28.845 35.5876 30.2764 35.7769 31.6837C36.1383 34.3477 36.7095 36.9791 37.4852 39.5532C38.3555 42.7461 38.7704 46.046 38.7174 49.3549C38.4706 53.5089 37.4744 57.5839 35.7769 61.3831C35.0207 63.3995 33.0884 68.5525 31.9262 68.3844C31.4501 68.2864 31.254 67.2782 31.002 64.8418C30.4839 59.5908 30.778 57.8405 30.5399 55.04C30.5399 54.4519 29.9238 48.2067 26.5352 43.936C26.165 43.3808 25.6886 42.9045 25.1335 42.5344C24.5783 42.1643 23.9554 41.9077 23.3006 41.7796C22.8343 41.7034 22.3569 41.7331 21.9036 41.8664C21.4504 41.9997 21.0329 42.2332 20.6821 42.5497C20.36 42.9138 20.164 43.4039 20.22 45.4762C20.1858 46.7671 20.2891 48.0579 20.5281 49.3269C21.1162 52.1275 22.0404 52.4495 22.9925 55.04C23.4546 56.3142 23.6086 58.9187 23.9167 64.1276C24.0707 66.8021 24.0707 67.9924 23.4546 68.3284C22.8385 68.6645 21.2562 67.3622 20.8361 66.9282C18.8758 65.0098 18.9178 63.3855 17.5875 60.767C16.2573 58.1486 15.7812 58.8487 13.2748 55.068C11.963 53.1655 10.8775 51.1167 10.0402 48.9629C9.11599 46.5124 9.144 45.2802 8.49988 40.9534C8.123 37.4082 7.45326 33.9002 6.49751 30.4655C6.09144 29.2473 5.79739 29.1353 4.15909 25.5786C2.94087 22.9461 2.32475 21.6299 2.01669 20.7617C0.917503 18.0746 0.438862 15.174 0.61644 12.2762C0.780022 10.2182 1.36634 8.21617 2.33875 6.3951C3.336 4.18623 5.08174 2.40081 7.26765 1.35418C8.73699 0.782442 10.3172 0.552597 11.8885 0.682061C13.1607 0.680407 14.4195 0.942643 15.5852 1.4522C17.2655 2.25035 17.2515 3.03449 18.5117 3.31454C19.3099 3.48257 19.7299 3.31454 22.2224 2.5304C24.3788 1.90028 25.471 1.57822 26.2271 1.4522C27.8656 1.20078 29.5364 1.25299 31.156 1.60623C33.0243 2.06368 34.766 2.93457 36.253 4.1547Z" fill="#DEE1E9"/>
      <path d="M1.98702 20.7337C2.25307 21.4758 2.72916 22.526 3.61132 24.4444L9.85646 26.2787C9.85646 26.2787 13.8612 26.8948 16.3256 25.3685C18.7901 23.8423 23.887 25.3685 23.887 25.3685C23.887 25.3685 30.9723 26.2787 33.2827 26.2787C34.8527 26.1703 36.3369 25.5222 37.4835 24.4444C39.1358 21.2378 41.8803 15.0627 40.284 9.7837C39.5211 7.53669 38.1257 5.55775 36.2653 4.08466C34.7793 2.88913 33.0413 2.04648 31.1824 1.6202C29.5637 1.25947 27.8915 1.20721 26.2535 1.46617C24.9 1.7526 23.5631 2.11253 22.2487 2.54437C19.7563 3.2725 19.3362 3.49654 18.538 3.31451C17.2778 3.03446 17.2778 2.26432 15.6115 1.46617C14.4478 0.950234 13.1877 0.687708 11.9148 0.696029C10.3476 0.541723 8.76601 0.752611 7.29399 1.31214C5.0498 2.34424 3.25096 4.14804 2.22506 6.39507C1.27438 8.21808 0.698288 10.213 0.530754 12.2621C0.372047 15.1596 0.869854 18.0555 1.98702 20.7337Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M34.1109 18.7312C35.4271 21.2097 32.9207 22.932 31.9545 29.3732C31.5569 32.1651 31.6612 35.0055 32.2626 37.7607C33.0327 40.8273 34.0689 41.5694 35.0631 43.9218C38.0316 51.1752 33.1587 66.5033 32.4446 66.4333C32.1925 66.4333 33.9849 58.2464 33.5368 53.9477C33.4354 51.6034 33.069 49.2782 32.4446 47.0164C31.0443 43.0257 29.392 43.4597 27.8098 39.147C26.8436 36.5005 27.0816 35.2822 25.6534 33.91C25.053 33.3159 24.3239 32.8679 23.5226 32.6008C22.7213 32.3337 21.8693 32.2546 21.0325 32.3697C18.5121 32.9578 17.5039 35.8984 17.1818 36.8365C16.5377 38.6989 16.7477 40.4352 17.1818 43.9218C17.3097 45.5708 17.6005 47.203 18.05 48.7947C18.8761 51.4832 19.4502 51.5952 20.3604 54.6478C20.5564 55.3199 20.7945 56.4821 21.2846 58.8486C21.6906 60.7669 23.091 66.8773 22.965 66.9333C22.4889 67.1713 18.7921 57.4763 18.204 56.3841C17.0076 54.3417 15.9276 52.2333 14.9694 50.069C13.8522 47.745 12.9703 45.3151 12.3369 42.8156C11.8844 40.3714 11.5758 37.9027 11.4127 35.4223C11.2619 32.9105 10.9108 30.4148 10.3626 27.9589C9.03231 24.4163 7.16997 23.6321 7.56204 21.6298C7.89811 20.0335 9.41038 19.0253 10.7966 18.0871C12.1007 16.9854 13.7272 16.3368 15.4315 16.2388C17.2378 16.2388 17.7559 17.289 19.7443 17.9331C21.771 18.4672 23.9079 18.4138 25.9054 17.7791C28.7059 17.0929 29.336 16.0427 31.0023 16.3788C31.6571 16.5294 32.2727 16.8168 32.8084 17.2223C33.3442 17.6277 33.7881 18.142 34.1109 18.7312Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M30.8224 57.1731C33.3308 59.2551 34.7315 59.1602 37.2251 57.1731" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M15.0504 57.1731C18.3453 59.2551 20.1852 59.1602 23.4605 57.1731" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M36.7274 4.71475C38.5669 6.16383 39.9559 8.10652 40.7322 10.3158C42.6225 16.3509 38.7298 23.5902 37.3436 26.1947C37.0132 26.7775 36.7546 27.3981 36.5734 28.043C36.1718 29.405 36.0621 30.8365 36.2514 32.2438C36.6128 34.9078 37.184 37.5391 37.9597 40.1132C38.83 43.3061 39.2449 46.606 39.1919 49.915C38.9451 54.0689 37.9489 58.1439 36.2514 61.9432C35.4952 63.9596 33.5629 69.1125 32.4006 68.9445C31.9246 68.8465 31.7285 67.8383 31.4765 65.4018C30.9584 60.1509 31.2524 58.4006 31.0144 55.6C31.0144 55.0119 30.3983 48.7668 27.0097 44.496C26.6395 43.9409 26.1631 43.4645 25.608 43.0944C25.0528 42.7243 24.4299 42.4678 23.7751 42.3396C23.3088 42.2635 22.8314 42.2931 22.3781 42.4264C21.9249 42.5597 21.5074 42.7933 21.1566 43.1098C20.8345 43.4738 20.6385 43.9639 20.6945 46.0363C20.6603 47.3272 20.7636 48.618 21.0026 49.887C21.5907 52.6875 22.5148 53.0096 23.467 55.6C23.9291 56.8743 24.0831 59.4788 24.3912 64.6877C24.5452 67.3622 24.5452 68.5524 23.9291 68.8885C23.313 69.2245 21.7307 67.9223 21.3106 67.4882C19.3503 65.5699 19.3923 63.9456 18.062 61.3271C16.7318 58.7086 16.2557 59.4087 13.7492 55.6281C12.4375 53.7256 11.352 51.6767 10.5146 49.5229C9.59048 47.0725 9.61849 45.8403 8.97437 41.5135C8.59749 37.9682 7.92775 34.4603 6.972 31.0256C6.56593 29.8073 6.27188 29.6953 4.63358 26.1387C3.41536 23.5062 2.79924 22.1899 2.49118 21.3218C1.39199 18.6346 0.913349 15.7341 1.09093 12.8362C1.25451 10.7783 1.84083 8.77623 2.81324 6.95516C3.81049 4.74628 5.55623 2.96087 7.74214 1.91424C9.21148 1.3425 10.7917 1.11266 12.363 1.24212C13.6352 1.24047 14.8939 1.5027 16.0597 2.01226C17.74 2.81041 17.726 3.59455 18.9862 3.8746C19.7843 4.04263 20.2044 3.8746 22.6969 3.09046C24.8533 2.46034 25.9455 2.13828 26.7016 2.01226C28.3401 1.76083 30.0109 1.81305 31.6305 2.16629C33.4988 2.62374 35.2405 3.49463 36.7274 4.71475Z" fill="#DEE1E9"/>
      <mask id="mask2237" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M2.47689 21.0867C2.74294 21.8289 3.21902 22.879 4.10118 24.7974L10.3463 26.6317C10.3463 26.6317 14.3511 27.2478 16.8155 25.7216C19.28 24.1953 24.3769 25.7216 24.3769 25.7216C24.3769 25.7216 31.4622 26.6317 33.7726 26.6317C35.3426 26.5233 36.8267 25.8752 37.9734 24.7974C39.6257 21.5908 42.3702 15.4157 40.7739 10.1367C40.011 7.88971 38.6155 5.91078 36.7552 4.43768C35.2692 3.24216 33.5312 2.39951 31.6722 1.97323C30.0535 1.6125 28.3814 1.56024 26.7433 1.8192C25.3898 2.10563 24.0529 2.46555 22.7386 2.89739C20.2461 3.62553 19.8261 3.84957 19.0279 3.66754C17.7677 3.38749 17.7677 2.61735 16.1014 1.8192C14.9377 1.30326 13.6776 1.04073 12.4047 1.04906C10.8375 0.894751 9.25587 1.10564 7.78386 1.66517C5.53967 2.69726 3.74083 4.50107 2.71493 6.7481C1.76425 8.57111 1.18816 10.566 1.02062 12.6152C0.861916 15.5127 1.35972 18.4086 2.47689 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2237)">
        <path d="M2.47689 21.0867C2.74294 21.8289 3.21902 22.879 4.10118 24.7974L10.3463 26.6317C10.3463 26.6317 14.3511 27.2478 16.8155 25.7216C19.28 24.1953 24.3769 25.7216 24.3769 25.7216C24.3769 25.7216 31.4622 26.6317 33.7726 26.6317C35.3426 26.5233 36.8267 25.8752 37.9734 24.7974C39.6257 21.5908 42.3702 15.4157 40.7739 10.1367C40.011 7.88971 38.6155 5.91078 36.7552 4.43768C35.2692 3.24216 33.5312 2.39951 31.6722 1.97323C30.0535 1.6125 28.3814 1.56024 26.7433 1.8192C25.3898 2.10563 24.0529 2.46555 22.7386 2.89739C20.2461 3.62553 19.8261 3.84957 19.0279 3.66754C17.7677 3.38749 17.7677 2.61735 16.1014 1.8192C14.9377 1.30326 13.6776 1.04073 12.4047 1.04906C10.8375 0.894751 9.25587 1.10564 7.78386 1.66517C5.53967 2.69726 3.74083 4.50107 2.71493 6.7481C1.76425 8.57111 1.18816 10.566 1.02062 12.6152C0.861916 15.5127 1.35972 18.4086 2.47689 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.5633 19C19.5012 19 18.2556 13.7018 18.2556 4.10345L20.1787 2.24138L23.2556 1C23.2556 10.5983 21.6254 19 20.5633 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M34.5854 19.2915C35.9016 21.77 33.3952 23.4923 32.429 29.9335C32.0314 32.7254 32.1357 35.5658 32.737 38.321C33.5072 41.3876 34.5434 42.1297 35.5376 44.4821C38.5061 51.7355 33.6332 67.0636 32.9191 66.9936C32.667 66.9936 34.4594 58.8067 34.0113 54.508C33.9099 52.1637 33.5435 49.8385 32.9191 47.5767C31.5188 43.586 29.8665 44.02 28.2842 39.7073C27.3181 37.0608 27.5561 35.8426 26.1278 34.4703C25.5275 33.8762 24.7984 33.4282 23.9971 33.1611C23.1958 32.894 22.3438 32.8149 21.507 32.93C18.9865 33.5181 17.9784 36.4587 17.6563 37.3968C17.0122 39.2592 17.2222 40.9955 17.6563 44.4821C17.7842 46.1311 18.075 47.7633 18.5245 49.355C19.3506 52.0435 19.9247 52.1555 20.8349 55.2081C21.0309 55.8802 21.269 57.0424 21.759 59.4089C22.1651 61.3272 23.5655 67.4376 23.4395 67.4936C22.9634 67.7316 19.2666 58.0366 18.6785 56.9444C17.4821 54.902 16.4021 52.7936 15.4439 50.6293C14.3267 48.3053 13.4448 45.8754 12.8114 43.3759C12.3589 40.9317 12.0503 38.463 11.8872 35.9826C11.7364 33.4708 11.3852 30.9751 10.837 28.5192C9.5068 24.9766 7.64446 24.1924 8.03653 22.1901C8.37259 20.5938 9.88487 19.5856 11.2711 18.6474C12.5752 17.5457 14.2016 16.8971 15.906 16.7991C17.7123 16.7991 18.2304 17.8493 20.2188 18.4934C22.2455 19.0275 24.3824 18.9741 26.3799 18.3394C29.1804 17.6532 29.8105 16.603 31.4768 16.9391C32.1316 17.0897 32.7471 17.3771 33.2829 17.7826C33.8187 18.188 34.2626 18.7023 34.5854 19.2915Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 33.2556 67)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 23.2556 67)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M36.7274 4.71475C38.5669 6.16383 39.9559 8.10652 40.7322 10.3158C42.6225 16.3509 38.7298 23.5902 37.3436 26.1947C37.0132 26.7775 36.7546 27.3981 36.5734 28.043C36.1718 29.405 36.0621 30.8365 36.2514 32.2438C36.6128 34.9078 37.184 37.5391 37.9597 40.1132C38.83 43.3061 39.2449 46.606 39.1919 49.915C38.9451 54.0689 37.9489 58.1439 36.2514 61.9432C35.4952 63.9596 33.5629 69.1125 32.4006 68.9445C31.9246 68.8465 31.7285 67.8383 31.4765 65.4018C30.9584 60.1509 31.2524 58.4006 31.0144 55.6C31.0144 55.0119 30.3983 48.7668 27.0097 44.496C26.6395 43.9409 26.1631 43.4645 25.608 43.0944C25.0528 42.7243 24.4299 42.4678 23.7751 42.3396C23.3088 42.2635 22.8314 42.2931 22.3781 42.4264C21.9249 42.5597 21.5074 42.7933 21.1566 43.1098C20.8345 43.4738 20.6385 43.9639 20.6945 46.0363C20.6603 47.3272 20.7636 48.618 21.0026 49.887C21.5907 52.6875 22.5148 53.0096 23.467 55.6C23.9291 56.8743 24.0831 59.4788 24.3912 64.6877C24.5452 67.3622 24.5452 68.5524 23.9291 68.8885C23.313 69.2245 21.7307 67.9223 21.3106 67.4882C19.3503 65.5699 19.3923 63.9456 18.062 61.3271C16.7318 58.7086 16.2557 59.4087 13.7492 55.6281C12.4375 53.7256 11.352 51.6767 10.5146 49.5229C9.59048 47.0725 9.61849 45.8403 8.97437 41.5135C8.59749 37.9682 7.92775 34.4603 6.972 31.0256C6.56593 29.8073 6.27188 29.6953 4.63358 26.1387C3.41536 23.5062 2.79924 22.1899 2.49118 21.3218C1.39199 18.6346 0.913349 15.7341 1.09093 12.8362C1.25451 10.7783 1.84083 8.77623 2.81324 6.95516C3.81049 4.74628 5.55623 2.96087 7.74214 1.91424C9.21148 1.3425 10.7917 1.11266 12.363 1.24212C13.6352 1.24047 14.8939 1.5027 16.0597 2.01226C17.74 2.81041 17.726 3.59455 18.9862 3.8746C19.7843 4.04263 20.2044 3.8746 22.6969 3.09046C24.8533 2.46034 25.9455 2.13828 26.7016 2.01226C28.3401 1.76083 30.0109 1.81305 31.6305 2.16629C33.4988 2.62374 35.2405 3.49463 36.7274 4.71475Z" fill="#DEE1E9"/>
      <mask id="mask2337" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M2.47689 21.0867C2.74294 21.8289 3.21902 22.879 4.10118 24.7974L10.3463 26.6317C10.3463 26.6317 14.3511 27.2478 16.8155 25.7216C19.28 24.1953 24.3769 25.7216 24.3769 25.7216C24.3769 25.7216 31.4622 26.6317 33.7726 26.6317C35.3426 26.5233 36.8267 25.8752 37.9734 24.7974C39.6257 21.5908 42.3702 15.4157 40.7739 10.1367C40.011 7.88971 38.6155 5.91078 36.7552 4.43768C35.2692 3.24216 33.5312 2.39951 31.6722 1.97323C30.0535 1.6125 28.3814 1.56024 26.7433 1.8192C25.3898 2.10563 24.0529 2.46555 22.7386 2.89739C20.2461 3.62553 19.8261 3.84957 19.0279 3.66754C17.7677 3.38749 17.7677 2.61735 16.1014 1.8192C14.9377 1.30326 13.6776 1.04073 12.4047 1.04906C10.8375 0.894751 9.25587 1.10564 7.78386 1.66517C5.53967 2.69726 3.74083 4.50107 2.71493 6.7481C1.76425 8.57111 1.18816 10.566 1.02062 12.6152C0.861916 15.5127 1.35972 18.4086 2.47689 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2337)">
        <path d="M2.47689 21.0867C2.74294 21.8289 3.21902 22.879 4.10118 24.7974L10.3463 26.6317C10.3463 26.6317 14.3511 27.2478 16.8155 25.7216C19.28 24.1953 24.3769 25.7216 24.3769 25.7216C24.3769 25.7216 31.4622 26.6317 33.7726 26.6317C35.3426 26.5233 36.8267 25.8752 37.9734 24.7974C39.6257 21.5908 42.3702 15.4157 40.7739 10.1367C40.011 7.88971 38.6155 5.91078 36.7552 4.43768C35.2692 3.24216 33.5312 2.39951 31.6722 1.97323C30.0535 1.6125 28.3814 1.56024 26.7433 1.8192C25.3898 2.10563 24.0529 2.46555 22.7386 2.89739C20.2461 3.62553 19.8261 3.84957 19.0279 3.66754C17.7677 3.38749 17.7677 2.61735 16.1014 1.8192C14.9377 1.30326 13.6776 1.04073 12.4047 1.04906C10.8375 0.894751 9.25587 1.10564 7.78386 1.66517C5.53967 2.69726 3.74083 4.50107 2.71493 6.7481C1.76425 8.57111 1.18816 10.566 1.02062 12.6152C0.861916 15.5127 1.35972 18.4086 2.47689 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.5633 19C19.5012 19 18.2556 13.7018 18.2556 4.10345L20.1787 2.24138L23.2556 1C23.2556 10.5983 21.6254 19 20.5633 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M34.5854 19.2915C35.9016 21.77 33.3952 23.4923 32.429 29.9335C32.0314 32.7254 32.1357 35.5658 32.737 38.321C33.5072 41.3876 34.5434 42.1297 35.5376 44.4821C38.5061 51.7355 33.6332 67.0636 32.9191 66.9936C32.667 66.9936 34.4594 58.8067 34.0113 54.508C33.9099 52.1637 33.5435 49.8385 32.9191 47.5767C31.5188 43.586 29.8665 44.02 28.2842 39.7073C27.3181 37.0608 27.5561 35.8426 26.1278 34.4703C25.5275 33.8762 24.7984 33.4282 23.9971 33.1611C23.1958 32.894 22.3438 32.8149 21.507 32.93C18.9865 33.5181 17.9784 36.4587 17.6563 37.3968C17.0122 39.2592 17.2222 40.9955 17.6563 44.4821C17.7842 46.1311 18.075 47.7633 18.5245 49.355C19.3506 52.0435 19.9247 52.1555 20.8349 55.2081C21.0309 55.8802 21.269 57.0424 21.759 59.4089C22.1651 61.3272 23.5655 67.4376 23.4395 67.4936C22.9634 67.7316 19.2666 58.0366 18.6785 56.9444C17.4821 54.902 16.4021 52.7936 15.4439 50.6293C14.3267 48.3053 13.4448 45.8754 12.8114 43.3759C12.3589 40.9317 12.0503 38.463 11.8872 35.9826C11.7364 33.4708 11.3852 30.9751 10.837 28.5192C9.5068 24.9766 7.64446 24.1924 8.03653 22.1901C8.37259 20.5938 9.88487 19.5856 11.2711 18.6474C12.5752 17.5457 14.2016 16.8971 15.906 16.7991C17.7123 16.7991 18.2304 17.8493 20.2188 18.4934C22.2455 19.0275 24.3824 18.9741 26.3799 18.3394C29.1804 17.6532 29.8105 16.603 31.4768 16.9391C32.1316 17.0897 32.7471 17.3771 33.2829 17.7826C33.8187 18.188 34.2626 18.7023 34.5854 19.2915Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 33.2556 67)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 23.2556 67)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2337)">
        <path d="M23.2556 64L22.4327 67.7391L19.3989 65.4037L21.172 68.797L17.3468 68.9581L20.8862 70.4178L18.0595 73L21.7091 71.8431L21.2035 75.6382L23.2556 72.406L25.3077 75.6382L24.8022 71.8431L28.4518 73L25.6251 70.4178L29.1645 68.9581L25.3393 68.797L27.1123 65.4037L24.0785 67.7391L23.2556 64Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2337" x="17.1468" y="63.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M36.7274 4.71475C38.5669 6.16383 39.9559 8.10652 40.7322 10.3158C42.6225 16.3509 38.7298 23.5902 37.3436 26.1947C37.0132 26.7775 36.7546 27.3981 36.5734 28.043C36.1718 29.405 36.0621 30.8365 36.2514 32.2438C36.6128 34.9078 37.184 37.5391 37.9597 40.1132C38.83 43.3061 39.2449 46.606 39.1919 49.915C38.9451 54.0689 37.9489 58.1439 36.2514 61.9432C35.4952 63.9596 33.5629 69.1125 32.4006 68.9445C31.9246 68.8465 31.7285 67.8383 31.4765 65.4018C30.9584 60.1509 31.2524 58.4006 31.0144 55.6C31.0144 55.0119 30.3983 48.7668 27.0097 44.496C26.6395 43.9409 26.1631 43.4645 25.608 43.0944C25.0528 42.7243 24.4299 42.4678 23.7751 42.3396C23.3088 42.2635 22.8314 42.2931 22.3781 42.4264C21.9249 42.5597 21.5074 42.7933 21.1566 43.1098C20.8345 43.4738 20.6385 43.9639 20.6945 46.0363C20.6603 47.3272 20.7636 48.618 21.0026 49.887C21.5907 52.6875 22.5148 53.0096 23.467 55.6C23.9291 56.8743 24.0831 59.4788 24.3912 64.6877C24.5452 67.3622 24.5452 68.5524 23.9291 68.8885C23.313 69.2245 21.7307 67.9223 21.3106 67.4882C19.3503 65.5699 19.3923 63.9456 18.062 61.3271C16.7318 58.7086 16.2557 59.4087 13.7492 55.6281C12.4375 53.7256 11.352 51.6767 10.5146 49.5229C9.59048 47.0725 9.61849 45.8403 8.97437 41.5135C8.59749 37.9682 7.92775 34.4603 6.972 31.0256C6.56593 29.8073 6.27188 29.6953 4.63358 26.1387C3.41536 23.5062 2.79924 22.1899 2.49118 21.3218C1.39199 18.6346 0.913349 15.7341 1.09093 12.8362C1.25451 10.7783 1.84083 8.77623 2.81324 6.95516C3.81049 4.74628 5.55623 2.96087 7.74214 1.91424C9.21148 1.3425 10.7917 1.11266 12.363 1.24212C13.6352 1.24047 14.8939 1.5027 16.0597 2.01226C17.74 2.81041 17.726 3.59455 18.9862 3.8746C19.7843 4.04263 20.2044 3.8746 22.6969 3.09046C24.8533 2.46034 25.9455 2.13828 26.7016 2.01226C28.3401 1.76083 30.0109 1.81305 31.6305 2.16629C33.4988 2.62374 35.2405 3.49463 36.7274 4.71475Z" fill="#DEE1E9"/>
      <mask id="mask2337" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M2.47689 21.0867C2.74294 21.8289 3.21902 22.879 4.10118 24.7974L10.3463 26.6317C10.3463 26.6317 14.3511 27.2478 16.8155 25.7216C19.28 24.1953 24.3769 25.7216 24.3769 25.7216C24.3769 25.7216 31.4622 26.6317 33.7726 26.6317C35.3426 26.5233 36.8267 25.8752 37.9734 24.7974C39.6257 21.5908 42.3702 15.4157 40.7739 10.1367C40.011 7.88971 38.6155 5.91078 36.7552 4.43768C35.2692 3.24216 33.5312 2.39951 31.6722 1.97323C30.0535 1.6125 28.3814 1.56024 26.7433 1.8192C25.3898 2.10563 24.0529 2.46555 22.7386 2.89739C20.2461 3.62553 19.8261 3.84957 19.0279 3.66754C17.7677 3.38749 17.7677 2.61735 16.1014 1.8192C14.9377 1.30326 13.6776 1.04073 12.4047 1.04906C10.8375 0.894751 9.25587 1.10564 7.78386 1.66517C5.53967 2.69726 3.74083 4.50107 2.71493 6.7481C1.76425 8.57111 1.18816 10.566 1.02062 12.6152C0.861916 15.5127 1.35972 18.4086 2.47689 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2337)">
        <path d="M2.47689 21.0867C2.74294 21.8289 3.21902 22.879 4.10118 24.7974L10.3463 26.6317C10.3463 26.6317 14.3511 27.2478 16.8155 25.7216C19.28 24.1953 24.3769 25.7216 24.3769 25.7216C24.3769 25.7216 31.4622 26.6317 33.7726 26.6317C35.3426 26.5233 36.8267 25.8752 37.9734 24.7974C39.6257 21.5908 42.3702 15.4157 40.7739 10.1367C40.011 7.88971 38.6155 5.91078 36.7552 4.43768C35.2692 3.24216 33.5312 2.39951 31.6722 1.97323C30.0535 1.6125 28.3814 1.56024 26.7433 1.8192C25.3898 2.10563 24.0529 2.46555 22.7386 2.89739C20.2461 3.62553 19.8261 3.84957 19.0279 3.66754C17.7677 3.38749 17.7677 2.61735 16.1014 1.8192C14.9377 1.30326 13.6776 1.04073 12.4047 1.04906C10.8375 0.894751 9.25587 1.10564 7.78386 1.66517C5.53967 2.69726 3.74083 4.50107 2.71493 6.7481C1.76425 8.57111 1.18816 10.566 1.02062 12.6152C0.861916 15.5127 1.35972 18.4086 2.47689 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.5633 19C19.5012 19 18.2556 13.7018 18.2556 4.10345L20.1787 2.24138L23.2556 1C23.2556 10.5983 21.6254 19 20.5633 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M34.5854 19.2915C35.9016 21.77 33.3952 23.4923 32.429 29.9335C32.0314 32.7254 32.1357 35.5658 32.737 38.321C33.5072 41.3876 34.5434 42.1297 35.5376 44.4821C38.5061 51.7355 33.6332 67.0636 32.9191 66.9936C32.667 66.9936 34.4594 58.8067 34.0113 54.508C33.9099 52.1637 33.5435 49.8385 32.9191 47.5767C31.5188 43.586 29.8665 44.02 28.2842 39.7073C27.3181 37.0608 27.5561 35.8426 26.1278 34.4703C25.5275 33.8762 24.7984 33.4282 23.9971 33.1611C23.1958 32.894 22.3438 32.8149 21.507 32.93C18.9865 33.5181 17.9784 36.4587 17.6563 37.3968C17.0122 39.2592 17.2222 40.9955 17.6563 44.4821C17.7842 46.1311 18.075 47.7633 18.5245 49.355C19.3506 52.0435 19.9247 52.1555 20.8349 55.2081C21.0309 55.8802 21.269 57.0424 21.759 59.4089C22.1651 61.3272 23.5655 67.4376 23.4395 67.4936C22.9634 67.7316 19.2666 58.0366 18.6785 56.9444C17.4821 54.902 16.4021 52.7936 15.4439 50.6293C14.3267 48.3053 13.4448 45.8754 12.8114 43.3759C12.3589 40.9317 12.0503 38.463 11.8872 35.9826C11.7364 33.4708 11.3852 30.9751 10.837 28.5192C9.5068 24.9766 7.64446 24.1924 8.03653 22.1901C8.37259 20.5938 9.88487 19.5856 11.2711 18.6474C12.5752 17.5457 14.2016 16.8971 15.906 16.7991C17.7123 16.7991 18.2304 17.8493 20.2188 18.4934C22.2455 19.0275 24.3824 18.9741 26.3799 18.3394C29.1804 17.6532 29.8105 16.603 31.4768 16.9391C32.1316 17.0897 32.7471 17.3771 33.2829 17.7826C33.8187 18.188 34.2626 18.7023 34.5854 19.2915Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 33.2556 67)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 23.2556 67)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2337)">
        <path d="M23.2556 64L22.4327 67.7391L19.3989 65.4037L21.172 68.797L17.3468 68.9581L20.8862 70.4178L18.0595 73L21.7091 71.8431L21.2035 75.6382L23.2556 72.406L25.3077 75.6382L24.8022 71.8431L28.4518 73L25.6251 70.4178L29.1645 68.9581L25.3393 68.797L27.1123 65.4037L24.0785 67.7391L23.2556 64Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2337" x="17.1468" y="63.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M36.7274 4.51383C38.5669 5.9629 39.9559 7.90559 40.7322 10.1148C42.6225 16.15 38.7298 23.3893 37.3436 25.9937C37.0132 26.5765 36.7546 27.1972 36.5734 27.8421C36.1718 29.2041 36.0621 30.6355 36.2514 32.0429C36.6128 34.7069 37.184 37.3382 37.9597 39.9123C38.83 43.1052 39.2449 46.4051 39.1919 49.7141C38.9451 53.868 37.9489 57.943 36.2514 61.7423C35.4952 63.7586 33.5629 68.9116 32.4006 68.7436C31.9246 68.6455 31.7285 67.6374 31.4765 65.2009C30.9584 59.9499 31.2524 58.1996 31.0144 55.3991C31.0144 54.811 30.3983 48.5659 27.0097 44.2951C26.6395 43.74 26.1631 43.2636 25.608 42.8935C25.0528 42.5234 24.4299 42.2669 23.7751 42.1387C23.3088 42.0625 22.8314 42.0922 22.3781 42.2255C21.9249 42.3588 21.5074 42.5924 21.1566 42.9088C20.8345 43.2729 20.6385 43.763 20.6945 45.8354C20.6603 47.1262 20.7636 48.4171 21.0026 49.6861C21.5907 52.4866 22.5148 52.8086 23.467 55.3991C23.9291 56.6734 24.0831 59.2778 24.3912 64.4868C24.5452 67.1613 24.5452 68.3515 23.9291 68.6875C23.313 69.0236 21.7307 67.7214 21.3106 67.2873C19.3503 65.3689 19.3923 63.7446 18.062 61.1262C16.7318 58.5077 16.2557 59.2078 13.7492 55.4271C12.4375 53.5247 11.352 51.4758 10.5146 49.322C9.59048 46.8716 9.61849 45.6393 8.97437 41.3125C8.59749 37.7673 7.92775 34.2594 6.972 30.8246C6.56593 29.6064 6.27188 29.4944 4.63358 25.9377C3.41536 23.3053 2.79924 21.989 2.49118 21.1209C1.39199 18.4337 0.913349 15.5331 1.09093 12.6353C1.25451 10.5774 1.84083 8.5753 2.81324 6.75423C3.81049 4.54536 5.55623 2.75994 7.74214 1.71331C9.21148 1.14157 10.7917 0.911728 12.363 1.04119C13.6352 1.03954 14.8939 1.30177 16.0597 1.81133C17.74 2.60948 17.726 3.39362 18.9862 3.67367C19.7843 3.8417 20.2044 3.67367 22.6969 2.88953C24.8533 2.25941 25.9455 1.93736 26.7016 1.81133C28.3401 1.55991 30.0109 1.61212 31.6305 1.96536C33.4988 2.42282 35.2405 3.2937 36.7274 4.51383Z" fill="#DEE1E9"/>
      <mask id="mask2537" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2537)">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.5633 18.7991C19.5012 18.7991 18.2556 13.5008 18.2556 3.90252L20.1787 2.04045L23.2556 0.799072C23.2556 10.3974 21.6254 18.7991 20.5633 18.7991Z" fill="#404040"/>
      </g>
      <circle r="4" transform="matrix(-1 0 0 1 24.2556 67)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2637" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="69">
        <path d="M36.4506 4.50772C38.29 5.9568 39.6791 7.89949 40.4553 10.1087C42.3457 16.1438 38.453 23.3832 37.0667 25.9876C36.7364 26.5704 36.4778 27.1911 36.2966 27.836C35.895 29.198 35.7852 30.6294 35.9745 32.0367C36.3359 34.7008 36.9071 37.3321 37.6828 39.9062C38.5532 43.0991 38.968 46.399 38.915 49.708C38.6682 53.8619 37.672 57.9369 35.9745 61.7362C35.2184 63.7525 33.286 68.9055 32.1238 68.7374C31.6477 68.6394 31.4517 67.6312 31.1996 65.1948C30.6815 59.9438 30.9756 58.1935 30.7375 55.393C30.7375 54.8049 30.1214 48.5598 26.7328 44.289C26.3626 43.7339 25.8863 43.2575 25.3311 42.8874C24.7759 42.5173 24.153 42.2608 23.4982 42.1326C23.0319 42.0564 22.5545 42.0861 22.1013 42.2194C21.648 42.3527 21.2305 42.5862 20.8797 42.9027C20.5577 43.2668 20.3616 43.7569 20.4177 45.8293C20.3835 47.1201 20.4867 48.411 20.7257 49.68C21.3138 52.4805 22.238 52.8025 23.1902 55.393C23.6522 56.6672 23.8063 59.2717 24.1143 64.4807C24.2684 67.1552 24.2684 68.3454 23.6522 68.6814C23.0361 69.0175 21.4538 67.7153 21.0338 67.2812C19.0734 65.3628 19.1154 63.7385 17.7852 61.1201C16.4549 58.5016 15.9788 59.2017 13.4724 55.421C12.1607 53.5186 11.0752 51.4697 10.2378 49.3159C9.31362 46.8655 9.34163 45.6332 8.69751 41.3064C8.32064 37.7612 7.65089 34.2533 6.69514 30.8185C6.28907 29.6003 5.99502 29.4883 4.35672 25.9316C3.1385 23.2992 2.52238 21.9829 2.21432 21.1148C1.11514 18.4276 0.636494 15.527 0.814072 12.6292C0.977654 10.5713 1.56398 8.5692 2.53638 6.74813C3.53363 4.53925 5.27937 2.75384 7.46529 1.70721C8.93462 1.13547 10.5148 0.905624 12.0861 1.03509C13.3584 1.03343 14.6171 1.29567 15.7828 1.80523C17.4631 2.60337 17.4491 3.38752 18.7093 3.66757C19.5075 3.8356 19.9276 3.66757 22.42 2.88343C24.5764 2.25331 25.6686 1.93125 26.4248 1.80523C28.0633 1.5538 29.734 1.60601 31.3537 1.95926C33.2219 2.41671 34.9637 3.2876 36.4506 4.50772Z" fill="#DEE1E9"/>
        <path d="M2.18465 21.0867C2.4507 21.8289 2.92679 22.879 3.80895 24.7974L10.0541 26.6317C10.0541 26.6317 14.0588 27.2478 16.5233 25.7216C18.9877 24.1953 24.0847 25.7216 24.0847 25.7216C24.0847 25.7216 31.1699 26.6317 33.4804 26.6317C35.0503 26.5233 36.5345 25.8752 37.6811 24.7974C39.3334 21.5908 42.0779 15.4157 40.4816 10.1367C39.7188 7.88971 38.3233 5.91078 36.4629 4.43768C34.9769 3.24216 33.239 2.39951 31.38 1.97323C29.7613 1.6125 28.0891 1.56024 26.4511 1.8192C25.0976 2.10563 23.7607 2.46555 22.4464 2.89739C19.9539 3.62553 19.5338 3.84957 18.7357 3.66754C17.4754 3.38749 17.4754 2.61735 15.8091 1.8192C14.6455 1.30326 13.3854 1.04073 12.1125 1.04906C10.5453 0.894751 8.96364 1.10564 7.49162 1.66517C5.24744 2.69726 3.44859 4.50107 2.4227 6.7481C1.47201 8.57111 0.89592 10.566 0.728386 12.6152C0.569679 15.5127 1.06749 18.4086 2.18465 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2637)">
        <path d="M36.4506 4.50772C38.29 5.9568 39.6791 7.89949 40.4553 10.1087C42.3457 16.1438 38.453 23.3832 37.0667 25.9876C36.7364 26.5704 36.4778 27.1911 36.2966 27.836C35.895 29.198 35.7852 30.6294 35.9745 32.0367C36.3359 34.7008 36.9071 37.3321 37.6828 39.9062C38.5532 43.0991 38.968 46.399 38.915 49.708C38.6682 53.8619 37.672 57.9369 35.9745 61.7362C35.2184 63.7525 33.286 68.9055 32.1238 68.7374C31.6477 68.6394 31.4517 67.6312 31.1996 65.1948C30.6815 59.9438 30.9756 58.1935 30.7375 55.393C30.7375 54.8049 30.1214 48.5598 26.7328 44.289C26.3626 43.7339 25.8863 43.2575 25.3311 42.8874C24.7759 42.5173 24.153 42.2608 23.4982 42.1326C23.0319 42.0564 22.5545 42.0861 22.1013 42.2194C21.648 42.3527 21.2305 42.5862 20.8797 42.9027C20.5577 43.2668 20.3616 43.7569 20.4177 45.8293C20.3835 47.1201 20.4867 48.411 20.7257 49.68C21.3138 52.4805 22.238 52.8025 23.1902 55.393C23.6522 56.6672 23.8063 59.2717 24.1143 64.4807C24.2684 67.1552 24.2684 68.3454 23.6522 68.6814C23.0361 69.0175 21.4538 67.7153 21.0338 67.2812C19.0734 65.3628 19.1154 63.7385 17.7852 61.1201C16.4549 58.5016 15.9788 59.2017 13.4724 55.421C12.1607 53.5186 11.0752 51.4697 10.2378 49.3159C9.31362 46.8655 9.34163 45.6332 8.69751 41.3064C8.32064 37.7612 7.65089 34.2533 6.69514 30.8185C6.28907 29.6003 5.99502 29.4883 4.35672 25.9316C3.1385 23.2992 2.52238 21.9829 2.21432 21.1148C1.11514 18.4276 0.636494 15.527 0.814072 12.6292C0.977654 10.5713 1.56398 8.5692 2.53638 6.74813C3.53363 4.53925 5.27937 2.75384 7.46529 1.70721C8.93462 1.13547 10.5148 0.905624 12.0861 1.03509C13.3584 1.03343 14.6171 1.29567 15.7828 1.80523C17.4631 2.60337 17.4491 3.38752 18.7093 3.66757C19.5075 3.8356 19.9276 3.66757 22.42 2.88343C24.5764 2.25331 25.6686 1.93125 26.4248 1.80523C28.0633 1.5538 29.734 1.60601 31.3537 1.95926C33.2219 2.41671 34.9637 3.2876 36.4506 4.50772Z" fill="#DEE1E9"/>
        <path d="M2.18465 21.0867C2.4507 21.8289 2.92679 22.879 3.80895 24.7974L10.0541 26.6317C10.0541 26.6317 14.0588 27.2478 16.5233 25.7216C18.9877 24.1953 24.0847 25.7216 24.0847 25.7216C24.0847 25.7216 31.1699 26.6317 33.4804 26.6317C35.0503 26.5233 36.5345 25.8752 37.6811 24.7974C39.3334 21.5908 42.0779 15.4157 40.4816 10.1367C39.7188 7.88971 38.3233 5.91078 36.4629 4.43768C34.9769 3.24216 33.239 2.39951 31.38 1.97323C29.7613 1.6125 28.0891 1.56024 26.4511 1.8192C25.0976 2.10563 23.7607 2.46555 22.4464 2.89739C19.9539 3.62553 19.5338 3.84957 18.7357 3.66754C17.4754 3.38749 17.4754 2.61735 15.8091 1.8192C14.6455 1.30326 13.3854 1.04073 12.1125 1.04906C10.5453 0.894751 8.96364 1.10564 7.49162 1.66517C5.24744 2.69726 3.44859 4.50107 2.4227 6.7481C1.47201 8.57111 0.89592 10.566 0.728386 12.6152C0.569679 15.5127 1.06749 18.4086 2.18465 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M27.9893 19.6762H39.9105C37.7484 25.046 37.3914 28.3063 37.6879 34.0446C37.6991 34.2609 37.4497 34.3955 37.2595 34.2689C36.2554 33.601 33.8972 31.7849 33.3256 29.1432C32.6331 25.9432 30.6299 25.1661 30.4173 25.092C30.4044 25.0875 30.3928 25.083 30.3804 25.0773L29.1975 24.5307C29.0529 24.4639 28.9943 24.3014 29.0666 24.1678L29.5955 23.1901C29.6402 23.1074 29.6362 23.0092 29.5847 22.9299L27.7383 20.0858C27.6213 19.9056 27.7618 19.6762 27.9893 19.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M36.9144 33.155C35.8534 32.3071 34.4454 30.8702 34.043 29.0106C33.2998 25.5761 31.1238 24.6201 30.6909 24.4648L29.9173 24.1073L30.2499 23.4925C30.4065 23.203 30.3922 22.8596 30.2121 22.5821L28.7646 20.3524H38.8672C37.0904 25.0171 36.7144 28.2129 36.9144 33.155ZM30.7076 24.4725C30.7077 24.4726 30.7074 24.4724 30.7076 24.4725V24.4725ZM36.8321 34.8178C37.5609 35.3027 38.4567 34.7463 38.4187 34.0123C38.1259 28.3462 38.4751 25.18 40.5962 19.9121L40.9634 19H27.9893C27.1931 19 26.7013 19.8028 27.1109 20.4338L28.8333 23.0869L28.4122 23.8654C28.1592 24.333 28.3643 24.9017 28.8702 25.1355L30.0532 25.6822C30.0939 25.701 30.1297 25.7145 30.1593 25.7248C30.1959 25.7376 31.9767 26.3577 32.6081 29.2758C33.2376 32.1849 35.801 34.1319 36.8321 34.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask137" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="69">
        <path d="M36.4506 4.50772C38.29 5.9568 39.6791 7.89949 40.4553 10.1087C42.3457 16.1438 38.453 23.3832 37.0667 25.9876C36.7364 26.5704 36.4778 27.1911 36.2966 27.836C35.895 29.198 35.7852 30.6294 35.9745 32.0367C36.3359 34.7008 36.9071 37.3321 37.6828 39.9062C38.5532 43.0991 38.968 46.399 38.915 49.708C38.6682 53.8619 37.672 57.9369 35.9745 61.7362C35.2184 63.7525 33.286 68.9055 32.1238 68.7374C31.6477 68.6394 31.4517 67.6312 31.1996 65.1948C30.6815 59.9438 30.9756 58.1935 30.7375 55.393C30.7375 54.8049 30.1214 48.5598 26.7328 44.289C26.3626 43.7339 25.8863 43.2575 25.3311 42.8874C24.7759 42.5173 24.153 42.2608 23.4982 42.1326C23.0319 42.0564 22.5545 42.0861 22.1013 42.2194C21.648 42.3527 21.2305 42.5862 20.8797 42.9027C20.5577 43.2668 20.3616 43.7569 20.4177 45.8293C20.3835 47.1201 20.4867 48.411 20.7257 49.68C21.3138 52.4805 22.238 52.8025 23.1902 55.393C23.6522 56.6672 23.8063 59.2717 24.1143 64.4807C24.2684 67.1552 24.2684 68.3454 23.6522 68.6814C23.0361 69.0175 21.4538 67.7153 21.0338 67.2812C19.0734 65.3628 19.1154 63.7385 17.7852 61.1201C16.4549 58.5016 15.9788 59.2017 13.4724 55.421C12.1607 53.5186 11.0752 51.4697 10.2378 49.3159C9.31362 46.8655 9.34163 45.6332 8.69751 41.3064C8.32064 37.7612 7.65089 34.2533 6.69514 30.8185C6.28907 29.6003 5.99502 29.4883 4.35672 25.9316C3.1385 23.2992 2.52238 21.9829 2.21432 21.1148C1.11514 18.4276 0.636494 15.527 0.814072 12.6292C0.977654 10.5713 1.56398 8.5692 2.53638 6.74813C3.53363 4.53925 5.27937 2.75384 7.46529 1.70721C8.93462 1.13547 10.5148 0.905624 12.0861 1.03509C13.3584 1.03343 14.6171 1.29567 15.7828 1.80523C17.4631 2.60337 17.4491 3.38752 18.7093 3.66757C19.5075 3.8356 19.9276 3.66757 22.42 2.88343C24.5764 2.25331 25.6686 1.93125 26.4248 1.80523C28.0633 1.5538 29.734 1.60601 31.3537 1.95926C33.2219 2.41671 34.9637 3.2876 36.4506 4.50772Z" fill="#DEE1E9"/>
        <path d="M2.18465 21.0867C2.4507 21.8289 2.92679 22.879 3.80895 24.7974L10.0541 26.6317C10.0541 26.6317 14.0588 27.2478 16.5233 25.7216C18.9877 24.1953 24.0847 25.7216 24.0847 25.7216C24.0847 25.7216 31.1699 26.6317 33.4804 26.6317C35.0503 26.5233 36.5345 25.8752 37.6811 24.7974C39.3334 21.5908 42.0779 15.4157 40.4816 10.1367C39.7188 7.88971 38.3233 5.91078 36.4629 4.43768C34.9769 3.24216 33.239 2.39951 31.38 1.97323C29.7613 1.6125 28.0891 1.56024 26.4511 1.8192C25.0976 2.10563 23.7607 2.46555 22.4464 2.89739C19.9539 3.62553 19.5338 3.84957 18.7357 3.66754C17.4754 3.38749 17.4754 2.61735 15.8091 1.8192C14.6455 1.30326 13.3854 1.04073 12.1125 1.04906C10.5453 0.894751 8.96364 1.10564 7.49162 1.66517C5.24744 2.69726 3.44859 4.50107 2.4227 6.7481C1.47201 8.57111 0.89592 10.566 0.728386 12.6152C0.569679 15.5127 1.06749 18.4086 2.18465 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask137)">
        <path d="M36.4506 4.50772C38.29 5.9568 39.6791 7.89949 40.4553 10.1087C42.3457 16.1438 38.453 23.3832 37.0667 25.9876C36.7364 26.5704 36.4778 27.1911 36.2966 27.836C35.895 29.198 35.7852 30.6294 35.9745 32.0367C36.3359 34.7008 36.9071 37.3321 37.6828 39.9062C38.5532 43.0991 38.968 46.399 38.915 49.708C38.6682 53.8619 37.672 57.9369 35.9745 61.7362C35.2184 63.7525 33.286 68.9055 32.1238 68.7374C31.6477 68.6394 31.4517 67.6312 31.1996 65.1948C30.6815 59.9438 30.9756 58.1935 30.7375 55.393C30.7375 54.8049 30.1214 48.5598 26.7328 44.289C26.3626 43.7339 25.8863 43.2575 25.3311 42.8874C24.7759 42.5173 24.153 42.2608 23.4982 42.1326C23.0319 42.0564 22.5545 42.0861 22.1013 42.2194C21.648 42.3527 21.2305 42.5862 20.8797 42.9027C20.5577 43.2668 20.3616 43.7569 20.4177 45.8293C20.3835 47.1201 20.4867 48.411 20.7257 49.68C21.3138 52.4805 22.238 52.8025 23.1902 55.393C23.6522 56.6672 23.8063 59.2717 24.1143 64.4807C24.2684 67.1552 24.2684 68.3454 23.6522 68.6814C23.0361 69.0175 21.4538 67.7153 21.0338 67.2812C19.0734 65.3628 19.1154 63.7385 17.7852 61.1201C16.4549 58.5016 15.9788 59.2017 13.4724 55.421C12.1607 53.5186 11.0752 51.4697 10.2378 49.3159C9.31362 46.8655 9.34163 45.6332 8.69751 41.3064C8.32064 37.7612 7.65089 34.2533 6.69514 30.8185C6.28907 29.6003 5.99502 29.4883 4.35672 25.9316C3.1385 23.2992 2.52238 21.9829 2.21432 21.1148C1.11514 18.4276 0.636494 15.527 0.814072 12.6292C0.977654 10.5713 1.56398 8.5692 2.53638 6.74813C3.53363 4.53925 5.27937 2.75384 7.46529 1.70721C8.93462 1.13547 10.5148 0.905624 12.0861 1.03509C13.3584 1.03343 14.6171 1.29567 15.7828 1.80523C17.4631 2.60337 17.4491 3.38752 18.7093 3.66757C19.5075 3.8356 19.9276 3.66757 22.42 2.88343C24.5764 2.25331 25.6686 1.93125 26.4248 1.80523C28.0633 1.5538 29.734 1.60601 31.3537 1.95926C33.2219 2.41671 34.9637 3.2876 36.4506 4.50772Z" fill="#DEE1E9"/>
        <path d="M2.18465 21.0867C2.4507 21.8289 2.92679 22.879 3.80895 24.7974L10.0541 26.6317C10.0541 26.6317 14.0588 27.2478 16.5233 25.7216C18.9877 24.1953 24.0847 25.7216 24.0847 25.7216C24.0847 25.7216 31.1699 26.6317 33.4804 26.6317C35.0503 26.5233 36.5345 25.8752 37.6811 24.7974C39.3334 21.5908 42.0779 15.4157 40.4816 10.1367C39.7188 7.88971 38.3233 5.91078 36.4629 4.43768C34.9769 3.24216 33.239 2.39951 31.38 1.97323C29.7613 1.6125 28.0891 1.56024 26.4511 1.8192C25.0976 2.10563 23.7607 2.46555 22.4464 2.89739C19.9539 3.62553 19.5338 3.84957 18.7357 3.66754C17.4754 3.38749 17.4754 2.61735 15.8091 1.8192C14.6455 1.30326 13.3854 1.04073 12.1125 1.04906C10.5453 0.894751 8.96364 1.10564 7.49162 1.66517C5.24744 2.69726 3.44859 4.50107 2.4227 6.7481C1.47201 8.57111 0.89592 10.566 0.728386 12.6152C0.569679 15.5127 1.06749 18.4086 2.18465 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M32.097 19.5H40.2438C38.7663 23.4705 38.5223 25.8811 38.725 30.1241C38.7326 30.2841 38.5622 30.3836 38.4322 30.29C37.746 29.7961 36.1345 28.4532 35.7438 26.5C35.2706 24.1339 33.9016 23.5593 33.7563 23.5045C33.7475 23.5012 33.7396 23.4978 33.7311 23.4936L32.9227 23.0894C32.8239 23.04 32.7838 22.9199 32.8332 22.8211L33.1947 22.0982C33.2253 22.037 33.2225 21.9645 33.1873 21.9058L31.9255 19.8029C31.8456 19.6696 31.9416 19.5 32.097 19.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M38.1964 29.4664C37.4713 28.8394 36.5091 27.777 36.2341 26.4019C35.7262 23.8625 34.2392 23.1555 33.9433 23.0407L33.4147 22.7764L33.642 22.3218C33.749 22.1077 33.7392 21.8538 33.6161 21.6486L32.6269 20H39.5309C38.3167 23.4491 38.0597 25.8121 38.1964 29.4664ZM33.9547 23.0464C33.9548 23.0465 33.9546 23.0464 33.9547 23.0464V23.0464ZM38.1401 30.6958C38.6382 31.0544 39.2503 30.643 39.2244 30.1003C39.0243 25.9106 39.263 23.5695 40.7124 19.6744L40.9634 19H32.0971C31.553 19 31.2169 19.5936 31.4968 20.0601L32.6739 22.0219L32.3861 22.5975C32.2132 22.9433 32.3533 23.3638 32.6991 23.5367L33.5075 23.9409C33.5353 23.9548 33.5598 23.9648 33.58 23.9724C33.605 23.9818 34.822 24.4403 35.2535 26.5981C35.6837 28.749 37.4355 30.1887 38.1401 30.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M36.7274 4.51383C38.5669 5.9629 39.9559 7.90559 40.7322 10.1148C42.6225 16.15 38.7298 23.3893 37.3436 25.9937C37.0132 26.5765 36.7546 27.1972 36.5734 27.8421C36.1718 29.2041 36.0621 30.6355 36.2514 32.0429C36.6128 34.7069 37.184 37.3382 37.9597 39.9123C38.83 43.1052 39.2449 46.4051 39.1919 49.7141C38.9451 53.868 37.9489 57.943 36.2514 61.7423C35.4952 63.7586 33.5629 68.9116 32.4006 68.7436C31.9246 68.6455 31.7285 67.6374 31.4765 65.2009C30.9584 59.9499 31.2524 58.1996 31.0144 55.3991C31.0144 54.811 30.3983 48.5659 27.0097 44.2951C26.6395 43.74 26.1631 43.2636 25.608 42.8935C25.0528 42.5234 24.4299 42.2669 23.7751 42.1387C23.3088 42.0625 22.8314 42.0922 22.3781 42.2255C21.9249 42.3588 21.5074 42.5924 21.1566 42.9088C20.8345 43.2729 20.6385 43.763 20.6945 45.8354C20.6603 47.1262 20.7636 48.4171 21.0026 49.6861C21.5907 52.4866 22.5148 52.8086 23.467 55.3991C23.9291 56.6734 24.0831 59.2778 24.3912 64.4868C24.5452 67.1613 24.5452 68.3515 23.9291 68.6875C23.313 69.0236 21.7307 67.7214 21.3106 67.2873C19.3503 65.3689 19.3923 63.7446 18.062 61.1262C16.7318 58.5077 16.2557 59.2078 13.7492 55.4271C12.4375 53.5247 11.352 51.4758 10.5146 49.322C9.59048 46.8716 9.61849 45.6393 8.97437 41.3125C8.59749 37.7673 7.92775 34.2594 6.972 30.8246C6.56593 29.6064 6.27188 29.4944 4.63358 25.9377C3.41536 23.3053 2.79924 21.989 2.49118 21.1209C1.39199 18.4337 0.913349 15.5331 1.09093 12.6353C1.25451 10.5774 1.84083 8.5753 2.81324 6.75423C3.81049 4.54536 5.55623 2.75994 7.74214 1.71331C9.21148 1.14157 10.7917 0.911728 12.363 1.04119C13.6352 1.03954 14.8939 1.30177 16.0597 1.81133C17.74 2.60948 17.726 3.39362 18.9862 3.67367C19.7843 3.8417 20.2044 3.67367 22.6969 2.88953C24.8533 2.25941 25.9455 1.93736 26.7016 1.81133C28.3401 1.55991 30.0109 1.61212 31.6305 1.96536C33.4988 2.42282 35.2405 3.2937 36.7274 4.51383Z" fill="#DEE1E9"/>
      <mask id="mask237" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask237)">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.9095 16C24.5206 16 29.2556 8.53185 29.2556 0L22.2172 1.10345L11.7556 0C11.7556 8.53185 17.2984 16 20.9095 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M34.3872 15.6547C35.6802 18.2946 33.218 20.129 32.2689 26.9896C31.8784 29.9633 31.9809 32.9887 32.5716 35.9233C33.3281 39.1895 34.3459 39.98 35.3225 42.4856C38.2386 50.2112 33.4519 66.5374 32.7504 66.4628C32.5028 66.4628 34.2634 57.7429 33.8233 53.1642C33.7236 50.6674 33.3637 48.1908 32.7504 45.7817C31.3749 41.5311 29.7518 41.9934 28.1975 37.3998C27.2484 34.581 27.4822 33.2835 26.0792 31.8219C25.4895 31.189 24.7733 30.7119 23.9862 30.4274C23.199 30.1429 22.3621 30.0587 21.5401 30.1813C19.0642 30.8077 18.0738 33.9397 17.7575 34.939C17.1247 36.9226 17.3311 38.7719 17.7575 42.4856C17.8831 44.2419 18.1688 45.9805 18.6103 47.6758C19.4218 50.5393 19.9858 50.6586 20.8798 53.91C21.0724 54.6258 21.3063 55.8637 21.7877 58.3842C22.1866 60.4275 23.5622 66.9357 23.4384 66.9954C22.9707 67.249 19.3393 56.9226 18.7616 55.7593C17.5863 53.584 16.5255 51.3383 15.5842 49.033C14.4868 46.5577 13.6205 43.9696 12.9983 41.3074C12.5538 38.704 12.2506 36.0745 12.0904 33.4326C11.9423 30.7573 11.5973 28.099 11.0588 25.4833C9.75208 21.71 7.92268 20.8748 8.30781 18.742C8.63793 17.0418 10.1235 15.9679 11.4852 14.9687C12.7662 13.7953 14.3639 13.1044 16.0381 13C17.8125 13 18.3214 14.1186 20.2746 14.8046C22.2655 15.3735 24.3646 15.3166 26.3268 14.6406C29.0778 13.9098 29.6968 12.7912 31.3336 13.1491C31.9768 13.3095 32.5815 13.6157 33.1078 14.0475C33.6341 14.4794 34.0701 15.0271 34.3872 15.6547Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M36.7274 4.51383C38.5669 5.9629 39.9559 7.90559 40.7322 10.1148C42.6225 16.15 38.7298 23.3893 37.3436 25.9937C37.0132 26.5765 36.7546 27.1972 36.5734 27.8421C36.1718 29.2041 36.0621 30.6355 36.2514 32.0429C36.6128 34.7069 37.184 37.3382 37.9597 39.9123C38.83 43.1052 39.2449 46.4051 39.1919 49.7141C38.9451 53.868 37.9489 57.943 36.2514 61.7423C35.4952 63.7586 33.5629 68.9116 32.4006 68.7436C31.9246 68.6455 31.7285 67.6374 31.4765 65.2009C30.9584 59.9499 31.2524 58.1996 31.0144 55.3991C31.0144 54.811 30.3983 48.5659 27.0097 44.2951C26.6395 43.74 26.1631 43.2636 25.608 42.8935C25.0528 42.5234 24.4299 42.2669 23.7751 42.1387C23.3088 42.0625 22.8314 42.0922 22.3781 42.2255C21.9249 42.3588 21.5074 42.5924 21.1566 42.9088C20.8345 43.2729 20.6385 43.763 20.6945 45.8354C20.6603 47.1262 20.7636 48.4171 21.0026 49.6861C21.5907 52.4866 22.5148 52.8086 23.467 55.3991C23.9291 56.6734 24.0831 59.2778 24.3912 64.4868C24.5452 67.1613 24.5452 68.3515 23.9291 68.6875C23.313 69.0236 21.7307 67.7214 21.3106 67.2873C19.3503 65.3689 19.3923 63.7446 18.062 61.1262C16.7318 58.5077 16.2557 59.2078 13.7492 55.4271C12.4375 53.5247 11.352 51.4758 10.5146 49.322C9.59048 46.8716 9.61849 45.6393 8.97437 41.3125C8.59749 37.7673 7.92775 34.2594 6.972 30.8246C6.56593 29.6064 6.27188 29.4944 4.63358 25.9377C3.41536 23.3053 2.79924 21.989 2.49118 21.1209C1.39199 18.4337 0.913349 15.5331 1.09093 12.6353C1.25451 10.5774 1.84083 8.5753 2.81324 6.75423C3.81049 4.54536 5.55623 2.75994 7.74214 1.71331C9.21148 1.14157 10.7917 0.911728 12.363 1.04119C13.6352 1.03954 14.8939 1.30177 16.0597 1.81133C17.74 2.60948 17.726 3.39362 18.9862 3.67367C19.7843 3.8417 20.2044 3.67367 22.6969 2.88953C24.8533 2.25941 25.9455 1.93736 26.7016 1.81133C28.3401 1.55991 30.0109 1.61212 31.6305 1.96536C33.4988 2.42282 35.2405 3.2937 36.7274 4.51383Z" fill="#DEE1E9"/>
      <mask id="mask337" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask337)">
        <path d="M2.47689 20.8858C2.74294 21.6279 3.21902 22.6781 4.10118 24.5965L10.3463 26.4308C10.3463 26.4308 14.3511 27.0469 16.8155 25.5206C19.28 23.9944 24.3769 25.5206 24.3769 25.5206C24.3769 25.5206 31.4622 26.4308 33.7726 26.4308C35.3426 26.3224 36.8267 25.6743 37.9734 24.5965C39.6257 21.3899 42.3702 15.2148 40.7739 9.9358C40.011 7.68879 38.6155 5.70985 36.7552 4.23676C35.2692 3.04123 33.5312 2.19858 31.6722 1.7723C30.0535 1.41157 28.3814 1.35931 26.7433 1.61827C25.3898 1.9047 24.0529 2.26463 22.7386 2.69647C20.2461 3.4246 19.8261 3.64864 19.0279 3.46661C17.7677 3.18656 17.7677 2.41642 16.1014 1.61827C14.9377 1.10233 13.6776 0.839807 12.4047 0.848129C10.8375 0.693823 9.25587 0.904711 7.78386 1.46424C5.53967 2.49634 3.74083 4.30014 2.71493 6.54717C1.76425 8.37018 1.18816 10.3651 1.02062 12.4142C0.861916 15.3117 1.35972 18.2076 2.47689 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.5633 18.7991C19.5012 18.7991 18.2556 13.5008 18.2556 3.90252L20.1787 2.04045L23.2556 0.799072C23.2556 10.3974 21.6254 18.7991 20.5633 18.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M36.4718 4.71573C38.3113 6.16481 39.7003 8.1075 40.4766 10.3168C42.3669 16.3519 38.4742 23.5912 37.0879 26.1957C36.7576 26.7784 36.499 27.3991 36.3178 28.044C35.9162 29.406 35.8065 30.8374 35.9957 32.2448C36.3571 34.9088 36.9284 37.5401 37.704 40.1142C38.5744 43.3071 38.9892 46.607 38.9363 49.916C38.6895 54.0699 37.6932 58.1449 35.9957 61.9442C35.2396 63.9605 33.3072 69.1135 32.145 68.9455C31.6689 68.8474 31.4729 67.8393 31.2209 65.4028C30.7028 60.1519 30.9968 58.4015 30.7588 55.601C30.7588 55.0129 30.1427 48.7678 26.754 44.497C26.3839 43.9419 25.9075 43.4655 25.3523 43.0954C24.7972 42.7253 24.1742 42.4688 23.5195 42.3406C23.0532 42.2644 22.5758 42.2941 22.1225 42.4274C21.6692 42.5607 21.2518 42.7943 20.901 43.1107C20.5789 43.4748 20.3829 43.9649 20.4389 46.0373C20.4047 47.3281 20.508 48.619 20.747 49.888C21.3351 52.6885 22.2592 53.0105 23.2114 55.601C23.6735 56.8753 23.8275 59.4797 24.1356 64.6887C24.2896 67.3632 24.2896 68.5534 23.6735 68.8895C23.0574 69.2255 21.4751 67.9233 21.055 67.4892C19.0947 65.5708 19.1367 63.9465 17.8064 61.3281C16.4762 58.7096 16.0001 59.4097 13.4936 55.629C12.1819 53.7266 11.0964 51.6777 10.259 49.5239C9.33486 47.0735 9.36287 45.8412 8.71875 41.5145C8.34188 37.9692 7.67213 34.4613 6.71638 31.0265C6.31031 29.8083 6.01626 29.6963 4.37796 26.1396C3.15974 23.5072 2.54362 22.1909 2.23557 21.3228C1.13638 18.6356 0.657734 15.7351 0.835312 12.8372C0.998894 10.7793 1.58522 8.7772 2.55762 6.95614C3.55487 4.74726 5.30061 2.96184 7.48653 1.91522C8.95586 1.34348 10.536 1.11363 12.1074 1.2431C13.3796 1.24144 14.6383 1.50368 15.8041 2.01324C17.4844 2.81138 17.4704 3.59553 18.7306 3.87558C19.5287 4.04361 19.9488 3.87558 22.4413 3.09143C24.5977 2.46132 25.6899 2.13926 26.446 2.01324C28.0845 1.76181 29.7553 1.81402 31.3749 2.16726C33.2431 2.62472 34.9849 3.49561 36.4718 4.71573Z" fill="#DEE1E9"/>
      <path d="M2.20589 21.2947C2.47194 22.0369 2.94803 23.0871 3.83019 25.0054L10.0753 26.8397C10.0753 26.8397 14.0801 27.4559 16.5445 25.9296C19.009 24.4033 24.1059 25.9296 24.1059 25.9296C24.1059 25.9296 31.1912 26.8397 33.5016 26.8397C35.0716 26.7313 36.5557 26.0832 37.7024 25.0054C39.3547 21.7988 42.0992 15.6237 40.5029 10.3447C39.74 8.09772 38.3445 6.11878 36.4842 4.64569C34.9982 3.45017 33.2602 2.60752 31.4012 2.18124C29.7826 1.82051 28.1104 1.76825 26.4723 2.02721C25.1188 2.31363 23.7819 2.67356 22.4676 3.1054C19.9751 3.83353 19.5551 4.05758 18.7569 3.87555C17.4967 3.5955 17.4967 2.82535 15.8304 2.02721C14.6667 1.51127 13.4066 1.24874 12.1337 1.25706C10.5665 1.10276 8.98488 1.31365 7.51286 1.87318C5.26868 2.90527 3.46983 4.70908 2.44394 6.9561C1.49325 8.77911 0.91716 10.774 0.749626 12.8232C0.590919 15.7207 1.08873 18.6166 2.20589 21.2947Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M9 1L7.13333 3.50086L5.5 2.00515V5.3192L2.93782 4.75129L4.33333 6.50189L2 8.50258L4.33333 10.5033L2.93782 12.2539L5.26667 11.5036L5.5 15L7.7499 13.503L8.53333 14.5046L10.2501 13.503L12.5 15L13.2 13.0041L15.0622 12.2539L14.6 9.50292L16 8.50258L13.6667 7.00206L15.0622 4.75129L13.2 4.5012L12.5 2.00515L9.46667 4.00103L9 1Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M36.4718 4.50772C38.3113 5.9568 39.7003 7.89949 40.4766 10.1087C42.3669 16.1438 38.4742 23.3832 37.0879 25.9876C36.7576 26.5704 36.499 27.1911 36.3178 27.836C35.9162 29.198 35.8065 30.6294 35.9957 32.0367C36.3571 34.7008 36.9284 37.3321 37.704 39.9062C38.5744 43.0991 38.9892 46.399 38.9363 49.708C38.6895 53.8619 37.6932 57.9369 35.9957 61.7362C35.2396 63.7525 33.3072 68.9055 32.145 68.7374C31.6689 68.6394 31.4729 67.6312 31.2209 65.1948C30.7028 59.9438 30.9968 58.1935 30.7588 55.393C30.7588 54.8049 30.1427 48.5598 26.754 44.289C26.3839 43.7339 25.9075 43.2575 25.3523 42.8874C24.7972 42.5173 24.1742 42.2608 23.5195 42.1326C23.0532 42.0564 22.5758 42.0861 22.1225 42.2194C21.6692 42.3527 21.2518 42.5862 20.901 42.9027C20.5789 43.2668 20.3829 43.7569 20.4389 45.8293C20.4047 47.1201 20.508 48.411 20.747 49.68C21.3351 52.4805 22.2592 52.8025 23.2114 55.393C23.6735 56.6672 23.8275 59.2717 24.1356 64.4807C24.2896 67.1552 24.2896 68.3454 23.6735 68.6814C23.0574 69.0175 21.4751 67.7153 21.055 67.2812C19.0947 65.3628 19.1367 63.7385 17.8064 61.1201C16.4762 58.5016 16.0001 59.2017 13.4936 55.421C12.1819 53.5186 11.0964 51.4697 10.259 49.3159C9.33486 46.8655 9.36287 45.6332 8.71875 41.3064C8.34188 37.7612 7.67213 34.2533 6.71638 30.8185C6.31031 29.6003 6.01626 29.4883 4.37796 25.9316C3.15974 23.2992 2.54362 21.9829 2.23557 21.1148C1.13638 18.4276 0.657734 15.527 0.835312 12.6292C0.998894 10.5713 1.58522 8.5692 2.55762 6.74813C3.55487 4.53925 5.30061 2.75384 7.48653 1.70721C8.95586 1.13547 10.536 0.905624 12.1074 1.03509C13.3796 1.03343 14.6383 1.29567 15.8041 1.80523C17.4844 2.60337 17.4704 3.38752 18.7306 3.66757C19.5287 3.8356 19.9488 3.66757 22.4413 2.88343C24.5977 2.25331 25.6899 1.93125 26.446 1.80523C28.0845 1.5538 29.7553 1.60601 31.3749 1.95926C33.2431 2.41671 34.9849 3.2876 36.4718 4.50772Z" fill="#DEE1E9"/>
      <path d="M2.20589 21.0867C2.47194 21.8289 2.94803 22.879 3.83019 24.7974L10.0753 26.6317C10.0753 26.6317 14.0801 27.2478 16.5445 25.7216C19.009 24.1953 24.1059 25.7216 24.1059 25.7216C24.1059 25.7216 31.1912 26.6317 33.5016 26.6317C35.0716 26.5233 36.5557 25.8752 37.7024 24.7974C39.3547 21.5908 42.0992 15.4157 40.5029 10.1367C39.74 7.88971 38.3445 5.91078 36.4842 4.43768C34.9982 3.24216 33.2602 2.39951 31.4012 1.97323C29.7826 1.6125 28.1104 1.56024 26.4723 1.8192C25.1188 2.10563 23.7819 2.46555 22.4676 2.89739C19.9751 3.62553 19.5551 3.84957 18.7569 3.66754C17.4967 3.38749 17.4967 2.61735 15.8304 1.8192C14.6667 1.30326 13.4066 1.04073 12.1337 1.04906C10.5665 0.894751 8.98488 1.10564 7.51286 1.66517C5.26868 2.69726 3.46983 4.50107 2.44394 6.7481C1.49325 8.57111 0.91716 10.566 0.749626 12.6152C0.590919 15.5127 1.08873 18.4086 2.20589 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M9 1.79199L7.4 3.93559L6 2.65355V5.49416L3.80385 5.00738L5 6.5079L3 8.22277L5 9.93765L3.80385 11.4382L5.8 10.7951L6 13.792L7.92849 12.5088L8.6 13.3674L10.0715 12.5088L12 13.792L12.6 12.0812L14.1962 11.4382L13.8 9.08021L15 8.22277L13 6.93662L14.1962 5.00738L12.6 4.79302L12 2.65355L9.4 4.3643L9 1.79199Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M36.4718 4.50772C38.3113 5.9568 39.7003 7.89949 40.4766 10.1087C42.3669 16.1438 38.4742 23.3832 37.0879 25.9876C36.7576 26.5704 36.499 27.1911 36.3178 27.836C35.9162 29.198 35.8065 30.6294 35.9957 32.0367C36.3571 34.7008 36.9284 37.3321 37.704 39.9062C38.5744 43.0991 38.9892 46.399 38.9363 49.708C38.6895 53.8619 37.6932 57.9369 35.9957 61.7362C35.2396 63.7525 33.3072 68.9055 32.145 68.7374C31.6689 68.6394 31.4729 67.6312 31.2209 65.1948C30.7028 59.9438 30.9968 58.1935 30.7588 55.393C30.7588 54.8049 30.1427 48.5598 26.754 44.289C26.3839 43.7339 25.9075 43.2575 25.3523 42.8874C24.7972 42.5173 24.1742 42.2608 23.5195 42.1326C23.0532 42.0564 22.5758 42.0861 22.1225 42.2194C21.6692 42.3527 21.2518 42.5862 20.901 42.9027C20.5789 43.2668 20.3829 43.7569 20.4389 45.8293C20.4047 47.1201 20.508 48.411 20.747 49.68C21.3351 52.4805 22.2592 52.8025 23.2114 55.393C23.6735 56.6672 23.8275 59.2717 24.1356 64.4807C24.2896 67.1552 24.2896 68.3454 23.6735 68.6814C23.0574 69.0175 21.4751 67.7153 21.055 67.2812C19.0947 65.3628 19.1367 63.7385 17.8064 61.1201C16.4762 58.5016 16.0001 59.2017 13.4936 55.421C12.1819 53.5186 11.0964 51.4697 10.259 49.3159C9.33486 46.8655 9.36287 45.6332 8.71875 41.3064C8.34188 37.7612 7.67213 34.2533 6.71638 30.8185C6.31031 29.6003 6.01626 29.4883 4.37796 25.9316C3.15974 23.2992 2.54362 21.9829 2.23557 21.1148C1.13638 18.4276 0.657734 15.527 0.835312 12.6292C0.998894 10.5713 1.58522 8.5692 2.55762 6.74813C3.55487 4.53925 5.30061 2.75384 7.48653 1.70721C8.95586 1.13547 10.536 0.905624 12.1074 1.03509C13.3796 1.03343 14.6383 1.29567 15.8041 1.80523C17.4844 2.60337 17.4704 3.38752 18.7306 3.66757C19.5287 3.8356 19.9488 3.66757 22.4413 2.88343C24.5977 2.25331 25.6899 1.93125 26.446 1.80523C28.0845 1.5538 29.7553 1.60601 31.3749 1.95926C33.2431 2.41671 34.9849 3.2876 36.4718 4.50772Z" fill="#DEE1E9"/>
      <path d="M2.20589 21.0867C2.47194 21.8289 2.94803 22.879 3.83019 24.7974L10.0753 26.6317C10.0753 26.6317 14.0801 27.2478 16.5445 25.7216C19.009 24.1953 24.1059 25.7216 24.1059 25.7216C24.1059 25.7216 31.1912 26.6317 33.5016 26.6317C35.0716 26.5233 36.5557 25.8752 37.7024 24.7974C39.3547 21.5908 42.0992 15.4157 40.5029 10.1367C39.74 7.88971 38.3445 5.91078 36.4842 4.43768C34.9982 3.24216 33.2602 2.39951 31.4012 1.97323C29.7826 1.6125 28.1104 1.56024 26.4723 1.8192C25.1188 2.10563 23.7819 2.46555 22.4676 2.89739C19.9751 3.62553 19.5551 3.84957 18.7569 3.66754C17.4967 3.38749 17.4967 2.61735 15.8304 1.8192C14.6667 1.30326 13.4066 1.04073 12.1337 1.04906C10.5665 0.894751 8.98488 1.10564 7.51286 1.66517C5.26868 2.69726 3.46983 4.50107 2.44394 6.7481C1.49325 8.57111 0.91716 10.566 0.749626 12.6152C0.590919 15.5127 1.08873 18.4086 2.20589 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M7.5 1.79199L6.56667 3.04242L5.75 2.29457V3.95159L4.46891 3.66764L5.16667 4.54294L4 5.54328L5.16667 6.54362L4.46891 7.41892L5.63333 7.0438L5.75 8.79199L6.87495 8.04347L7.26667 8.54431L8.12505 8.04347L9.25 8.79199L9.6 7.79405L10.5311 7.41892L10.3 6.04345L11 5.54328L9.83333 4.79302L10.5311 3.66764L9.6 3.54259L9.25 2.29457L7.73333 3.29251L7.5 1.79199Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M24.3542 69.6565L22.2705 70.9263H18.7868L16.6868 69.6565L18.2984 68.11L16.2147 66.5635L18.1031 65.017L15.8078 63.324L17.631 61.7775L15.3357 60.3775L17.3543 58.9612L15.3357 57.3496L17.0287 55.803L14.7985 54.3868L16.5566 52.9217L14.2612 51.3751L16.4101 49.8286L14.2612 48.217L15.8078 46.8659L13.724 45.3193L15.5473 43.838L13.4636 42.2914L15.2705 40.5496L14.2612 38.8728V24.6124L16.1496 17.2868H22.2705" fill="#8889BE"/>
      <path d="M24.3542 69.6565L22.2705 70.9263H18.7868L16.6868 69.6565L18.2984 68.11L16.2147 66.5635L18.1031 65.017L15.8078 63.324L17.631 61.7775L15.3357 60.3775L17.3543 58.9612L15.3357 57.3496L17.0287 55.803L14.7985 54.3868L16.5566 52.9217L14.2612 51.3751L16.4101 49.8286L14.2612 48.217L15.8078 46.8659L13.724 45.3193L15.5473 43.838L13.4636 42.2914L15.2705 40.5496L14.2612 38.8728V24.6124L16.1496 17.2868H22.2705" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M16.947 69.6565L19.0307 70.9263H22.4981L24.6144 69.6565L22.9865 68.11L25.0865 66.5635L23.1981 65.017L25.4772 63.324L23.6702 61.7775L25.9493 60.3775L23.9307 58.9612L25.9493 57.3496L24.2725 55.803L26.4865 54.3868L24.7446 52.9217L27.0237 51.3751L24.8749 49.8286L27.0237 48.217L25.4772 46.8659L27.5609 45.3193L25.7539 43.838L27.8376 42.2914L26.0144 40.5496L27.0237 38.8728V24.6124L25.1516 17.2868H21.2446" fill="#8889BE"/>
      <path d="M16.947 69.6565L19.0307 70.9263H22.4981L24.6144 69.6565L22.9865 68.11L25.0865 66.5635L23.1981 65.017L25.4772 63.324L23.6702 61.7775L25.9493 60.3775L23.9307 58.9612L25.9493 57.3496L24.2725 55.803L26.4865 54.3868L24.7446 52.9217L27.0237 51.3751L24.8749 49.8286L27.0237 48.217L25.4772 46.8659L27.5609 45.3193L25.7539 43.838L27.8376 42.2914L26.0144 40.5496L27.0237 38.8728V24.6124L25.1516 17.2868H21.2446" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask537" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M4.09276 24.8726C3.2106 22.9542 2.73451 21.9041 2.46846 21.1619C1.3513 18.4838 0.853489 15.5878 1.0122 12.6904C1.17973 10.6412 1.75583 8.6463 2.70651 6.82329C3.73241 4.57627 5.53125 2.77246 7.77544 1.74037C9.24745 1.18083 10.8291 0.969946 12.3963 1.12425C13.6692 1.11593 14.9293 1.37846 16.093 1.8944C17.7593 2.69254 17.7593 3.46268 19.0195 3.74273C19.8176 3.92477 20.2377 3.70072 22.7302 2.97259C24.0445 2.54075 25.3814 2.18082 26.7349 1.8944C28.3729 1.63544 30.0451 1.68769 31.6638 2.04843C33.5228 2.4747 35.2607 3.31736 36.7467 4.51288C38.6071 5.98597 40.0026 7.96491 40.7655 10.2119C42.3618 15.4909 39.6173 21.666 37.965 24.8726C36.8183 25.9504 35.3342 26.5985 33.7642 26.7069C32.8643 26.7069 29.4824 26.5698 27.6148 26.4013C27.3868 26.3807 27.1867 26.2656 27.1265 26.0447C26.6757 24.3916 26.2626 17.3672 26.2626 17.3672C21.5762 16.387 19.949 16.2205 15.2626 17.3672L13.925 25.3924C13.835 25.9326 13.3272 26.2999 12.7859 26.2162L4.09276 24.8726Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask537)">
        <path d="M4.09276 24.8726C3.2106 22.9542 2.73451 21.9041 2.46846 21.1619C1.3513 18.4838 0.853489 15.5878 1.0122 12.6904C1.17973 10.6412 1.75583 8.6463 2.70651 6.82329C3.73241 4.57627 5.53125 2.77246 7.77544 1.74037C9.24745 1.18083 10.8291 0.969946 12.3963 1.12425C13.6692 1.11593 14.9293 1.37846 16.093 1.8944C17.7593 2.69254 17.7593 3.46268 19.0195 3.74273C19.8176 3.92477 20.2377 3.70072 22.7302 2.97259C24.0445 2.54075 25.3814 2.18082 26.7349 1.8944C28.3729 1.63544 30.0451 1.68769 31.6638 2.04843C33.5228 2.4747 35.2607 3.31736 36.7467 4.51288C38.6071 5.98597 40.0026 7.96491 40.7655 10.2119C42.3618 15.4909 39.6173 21.666 37.965 24.8726C36.8183 25.9504 35.3342 26.5985 33.7642 26.7069C32.8643 26.7069 29.4824 26.5698 27.6148 26.4013C27.3868 26.3807 27.1867 26.2656 27.1265 26.0447C26.6757 24.3916 26.2626 17.3672 26.2626 17.3672C21.5762 16.387 19.949 16.2205 15.2626 17.3672L13.925 25.3924C13.835 25.9326 13.3272 26.2999 12.7859 26.2162L4.09276 24.8726Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M8.24719 40.0752H32.2472V14.5272C22.8746 12.601 17.6198 12.5814 8.24719 14.5272V40.0752Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M23.9465 69.9475L21.8628 71.2173H18.3791L16.2791 69.9475L17.8907 68.401L15.807 66.8545L17.6954 65.308L15.4 63.615L17.2233 62.0685L14.9279 60.6685L16.9465 59.2522L14.9279 57.6406L16.621 56.0941L14.3907 54.6778L16.1489 53.2127L13.8535 51.6662L16.0024 50.1197L13.8535 48.508L15.4 47.1569L13.3163 45.6104L15.1396 44.129L13.0559 42.5825L14.8628 40.8406L13.8535 39.1639V24.9034L15.7419 17.5779H21.8628" fill="#8889BE"/>
      <path d="M23.9465 69.9475L21.8628 71.2173H18.3791L16.2791 69.9475L17.8907 68.401L15.807 66.8545L17.6954 65.308L15.4 63.615L17.2233 62.0685L14.9279 60.6685L16.9465 59.2522L14.9279 57.6406L16.621 56.0941L14.3907 54.6778L16.1489 53.2127L13.8535 51.6662L16.0024 50.1197L13.8535 48.508L15.4 47.1569L13.3163 45.6104L15.1396 44.129L13.0559 42.5825L14.8628 40.8406L13.8535 39.1639V24.9034L15.7419 17.5779H21.8628" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M16.5393 69.9475L18.623 71.2173H22.0904L24.2067 69.9475L22.5788 68.401L24.6788 66.8545L22.7904 65.308L25.0695 63.615L23.2625 62.0685L25.5416 60.6685L23.523 59.2522L25.5416 57.6406L23.8648 56.0941L26.0788 54.6778L24.3369 53.2127L26.616 51.6662L24.4671 50.1197L26.616 48.508L25.0695 47.1569L27.1532 45.6104L25.3462 44.129L27.4299 42.5825L25.6067 40.8406L26.616 39.1639V24.9034L24.7439 17.5779H20.8369" fill="#8889BE"/>
      <path d="M16.5393 69.9475L18.623 71.2173H22.0904L24.2067 69.9475L22.5788 68.401L24.6788 66.8545L22.7904 65.308L25.0695 63.615L23.2625 62.0685L25.5416 60.6685L23.523 59.2522L25.5416 57.6406L23.8648 56.0941L26.0788 54.6778L24.3369 53.2127L26.616 51.6662L24.4671 50.1197L26.616 48.508L25.0695 47.1569L27.1532 45.6104L25.3462 44.129L27.4299 42.5825L25.6067 40.8406L26.616 39.1639V24.9034L24.7439 17.5779H20.8369" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask537" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M3.68493 25.1636C2.80276 23.2453 2.32668 22.1951 2.06063 21.4529C0.943462 18.7748 0.445652 15.8789 0.604362 12.9814C0.771896 10.9322 1.34799 8.93732 2.29867 7.11431C3.32457 4.86728 5.12341 3.06348 7.3676 2.03138C8.83961 1.47185 10.4212 1.26096 11.9884 1.41527C13.2613 1.40695 14.5214 1.66947 15.6851 2.18541C17.3514 2.98356 17.3514 3.7537 18.6117 4.03375C19.4098 4.21578 19.8299 3.99174 22.3223 3.2636C23.6367 2.83176 24.9736 2.47184 26.3271 2.18541C27.9651 1.92645 29.6373 1.97871 31.256 2.33944C33.1149 2.76572 34.8529 3.60837 36.3389 4.80389C38.1993 6.27699 39.5947 8.25593 40.3576 10.5029C41.9539 15.7819 39.2094 21.957 37.5571 25.1636C36.4105 26.2414 34.9263 26.8895 33.3563 26.9979C32.4565 26.9979 29.0745 26.8608 27.207 26.6923C26.9789 26.6717 26.7789 26.5566 26.7186 26.3357C26.2679 24.6826 25.8547 17.6582 25.8547 17.6582C21.1683 16.678 19.5412 16.5115 14.8547 17.6582L13.5172 25.6834C13.4272 26.2236 12.9193 26.5909 12.3781 26.5072L3.68493 25.1636Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask537)">
        <path d="M3.68493 25.1636C2.80276 23.2453 2.32668 22.1951 2.06063 21.4529C0.943462 18.7748 0.445652 15.8789 0.604362 12.9814C0.771896 10.9322 1.34799 8.93732 2.29867 7.11431C3.32457 4.86728 5.12341 3.06348 7.3676 2.03138C8.83961 1.47185 10.4212 1.26096 11.9884 1.41527C13.2613 1.40695 14.5214 1.66947 15.6851 2.18541C17.3514 2.98356 17.3514 3.7537 18.6117 4.03375C19.4098 4.21578 19.8299 3.99174 22.3223 3.2636C23.6367 2.83176 24.9736 2.47184 26.3271 2.18541C27.9651 1.92645 29.6373 1.97871 31.256 2.33944C33.1149 2.76572 34.8529 3.60837 36.3389 4.80389C38.1993 6.27699 39.5947 8.25593 40.3576 10.5029C41.9539 15.7819 39.2094 21.957 37.5571 25.1636C36.4105 26.2414 34.9263 26.8895 33.3563 26.9979C32.4565 26.9979 29.0745 26.8608 27.207 26.6923C26.9789 26.6717 26.7789 26.5566 26.7186 26.3357C26.2679 24.6826 25.8547 17.6582 25.8547 17.6582C21.1683 16.678 19.5412 16.5115 14.8547 17.6582L13.5172 25.6834C13.4272 26.2236 12.9193 26.5909 12.3781 26.5072L3.68493 25.1636Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M43.2556 0.259766H22.2556V5.25977H43.2556V0.259766Z" fill="#838AA2"/>
      <path d="M21.2556 0.259766H0.255615V5.25977H21.2556V0.259766Z" fill="#838AA2"/>
      <path d="M36.7428 8.39346C38.5823 9.84254 39.9713 11.7852 40.7476 13.9945C42.6379 20.0296 38.7452 27.2689 37.3589 29.8734C37.0286 30.4562 36.77 31.0768 36.5888 31.7217C36.1872 33.0837 36.0775 34.5152 36.2667 35.9225C36.6281 38.5865 37.1994 41.2178 37.975 43.7919C38.8454 46.9848 39.2602 50.2847 39.2073 53.5937C38.9605 57.7476 37.9642 61.8226 36.2667 65.6219C35.5106 67.6383 33.5782 72.7912 32.416 72.6232C31.9399 72.5252 31.7439 71.517 31.4919 69.0805C30.9738 63.8296 31.2678 62.0793 31.0298 59.2788C31.0298 58.6906 30.4137 52.4455 27.025 48.1747C26.6549 47.6196 26.1785 47.1433 25.6233 46.7732C25.0682 46.403 24.4452 46.1465 23.7905 46.0183C23.3242 45.9422 22.8467 45.9718 22.3935 46.1051C21.9402 46.2384 21.5228 46.472 21.172 46.7885C20.8499 47.1525 20.6539 47.6426 20.7099 49.715C20.6757 51.0059 20.779 52.2967 21.0179 53.5657C21.6061 56.3662 22.5302 56.6883 23.4824 59.2788C23.9445 60.553 24.0985 63.1575 24.4066 68.3664C24.5606 71.0409 24.5606 72.2311 23.9445 72.5672C23.3284 72.9032 21.7461 71.601 21.326 71.1669C19.3656 69.2486 19.4076 67.6243 18.0774 65.0058C16.7472 62.3873 16.2711 63.0875 13.7646 59.3068C12.4529 57.4043 11.3674 55.3554 10.53 53.2016C9.60586 50.7512 9.63387 49.519 8.98975 45.1922C8.61287 41.6469 7.94313 38.139 6.98738 34.7043C6.58131 33.486 6.28726 33.374 4.64896 29.8174C3.43074 27.1849 2.81462 25.8687 2.50656 25.0005C1.40737 22.3133 0.92873 19.4128 1.10631 16.5149C1.26989 14.457 1.85621 12.4549 2.82862 10.6339C3.82587 8.425 5.57161 6.63958 7.75752 5.59295C9.22686 5.02121 10.807 4.79137 12.3784 4.92083C13.6506 4.91918 14.9093 5.18141 16.075 5.69097C17.7554 6.48912 17.7413 7.27326 19.0016 7.55331C19.7997 7.72134 20.2198 7.55331 22.7123 6.76917C24.8687 6.13905 25.9609 5.81699 26.717 5.69097C28.3555 5.43955 30.0263 5.49176 31.6459 5.845C33.5141 6.30245 35.2559 7.17334 36.7428 8.39346Z" fill="#DEE1E9"/>
      <path d="M4.10118 28.6831C3.21902 26.7648 2.74294 25.7146 2.47689 24.9725C1.35972 22.2943 0.861912 19.3984 1.02062 16.5009C1.18816 14.4517 1.76425 12.4568 2.71493 10.6338C3.74083 8.38681 5.53967 6.58301 7.78386 5.55091C9.25587 4.99138 10.8375 4.78049 12.4047 4.9348C13.6776 4.92648 14.9377 5.189 16.1014 5.70494C17.7677 6.50309 17.7677 7.27323 19.0279 7.55328C19.8261 7.73531 20.2461 7.51127 22.7386 6.78314C24.0529 6.3513 25.3898 5.99137 26.7433 5.70494C28.3814 5.44598 30.0535 5.49824 31.6722 5.85897C33.5312 6.28525 35.2692 7.1279 36.7552 8.32343C38.6155 9.79652 40.011 11.7755 40.7739 14.0225C42.3702 19.3014 39.6257 25.4766 37.9734 28.6831C36.8267 29.761 35.3426 30.4091 33.7726 30.5175C32.8727 30.5175 29.4908 30.3803 27.6232 30.2118C27.3952 30.1913 27.1951 30.0761 27.1349 29.8552C26.6841 28.2021 26.271 21.1777 26.271 21.1777C21.5846 20.1976 19.9575 20.031 15.271 21.1777L13.9335 29.2029C13.8434 29.7431 13.3356 30.1104 12.7943 30.0268L4.10118 28.6831Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M37.1881 5.13761C39.0276 6.58668 40.4166 8.52937 41.1929 10.7386C43.0832 16.7737 39.1905 24.0131 37.8042 26.6175C37.4739 27.2003 37.2153 27.8209 37.0341 28.4659C36.6325 29.8279 36.5228 31.2593 36.712 32.6666C37.0735 35.3307 37.6447 37.962 38.4204 40.5361C39.2907 43.729 39.7055 47.0289 39.6526 50.3379C39.4058 54.4918 38.4096 58.5668 36.712 62.3661C35.9559 64.3824 34.0236 69.5354 32.8613 69.3673C32.3853 69.2693 32.1892 68.2611 31.9372 65.8247C31.4191 60.5737 31.7131 58.8234 31.4751 56.0229C31.4751 55.4348 30.859 49.1896 27.4704 44.9189C27.1002 44.3637 26.6238 43.8874 26.0686 43.5173C25.5135 43.1472 24.8906 42.8907 24.2358 42.7625C23.7695 42.6863 23.2921 42.716 22.8388 42.8493C22.3855 42.9826 21.9681 43.2161 21.6173 43.5326C21.2952 43.8967 21.0992 44.3868 21.1552 46.4591C21.121 47.75 21.2243 49.0408 21.4633 50.3099C22.0514 53.1104 22.9755 53.4324 23.9277 56.0229C24.3898 57.2971 24.5438 59.9016 24.8519 65.1106C25.0059 67.785 25.0059 68.9753 24.3898 69.3113C23.7737 69.6474 22.1914 68.3452 21.7713 67.9111C19.811 65.9927 19.853 64.3684 18.5227 61.7499C17.1925 59.1315 16.7164 59.8316 14.2099 56.0509C12.8982 54.1484 11.8127 52.0996 10.9753 49.9458C10.0512 47.4953 10.0792 46.2631 9.43506 41.9363C9.05819 38.3911 8.38844 34.8831 7.43269 31.4484C7.02662 30.2302 6.73257 30.1182 5.09427 26.5615C3.87605 23.929 3.25993 22.6128 2.95187 21.7446C1.85268 19.0575 1.37405 16.1569 1.55162 13.2591C1.71521 11.2012 2.30153 9.19908 3.27393 7.37801C4.27118 5.16914 6.01692 3.38372 8.20284 2.33709C9.67217 1.76535 11.2524 1.53551 12.8237 1.66497C14.0959 1.66332 15.3546 1.92555 16.5204 2.43511C18.2007 3.23326 18.1867 4.0174 19.4469 4.29745C20.245 4.46548 20.6651 4.29745 23.1576 3.51331C25.314 2.88319 26.4062 2.56113 27.1623 2.43511C28.8008 2.18369 30.4716 2.2359 32.0912 2.58914C33.9594 3.04659 35.7012 3.91748 37.1881 5.13761Z" fill="#DEE1E9"/>
      <path d="M24.599 60.417L19.2163 47.5137L22.2163 35.5137L31.5752 46.0137V58.0137C31.7234 65.3056 31.1308 65.3056 31.1308 65.3056C29.1241 69.293 27.7908 67.4905 26.4575 66.3571C25.1242 65.2237 24.599 60.417 24.599 60.417Z" fill="#DEE1E9"/>
      <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="28">
        <path d="M2.93758 21.5096C3.20363 22.2517 3.67972 23.3019 4.56188 25.2202L10.807 27.0546C10.807 27.0546 14.8118 27.6707 17.2762 26.1444C19.7407 24.6181 24.8376 26.1444 24.8376 26.1444C24.8376 26.1444 31.9229 27.0546 34.2333 27.0546C35.8033 26.9462 37.2874 26.2981 38.4341 25.2202C40.0864 22.0137 42.8309 15.8385 41.2346 10.5596C40.4717 8.31257 39.0762 6.33363 37.2158 4.86053C35.7298 3.66501 33.9919 2.82236 32.1329 2.39608C30.5142 2.03535 28.8421 1.98309 27.204 2.24205C25.8505 2.52848 24.5136 2.88841 23.1993 3.32025C20.7068 4.04838 20.2868 4.27242 19.4886 4.09039C18.2284 3.81034 18.2284 3.0402 16.5621 2.24205C15.3984 1.72611 14.1383 1.46359 12.8654 1.47191C11.2982 1.3176 9.71657 1.52849 8.24455 2.08802C6.00037 3.12012 4.20152 4.92392 3.17562 7.17095C2.22494 8.99396 1.64885 10.9889 1.48132 13.038C1.32261 15.9355 1.82042 18.8314 2.93758 21.5096Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask536)">
        <path d="M2.93758 21.5096C3.20363 22.2517 3.67972 23.3019 4.56188 25.2202L10.807 27.0546C10.807 27.0546 14.8118 27.6707 17.2762 26.1444C19.7407 24.6181 24.8376 26.1444 24.8376 26.1444C24.8376 26.1444 31.9229 27.0546 34.2333 27.0546C35.8033 26.9462 37.2874 26.2981 38.4341 25.2202C40.0864 22.0137 42.8309 15.8385 41.2346 10.5596C40.4717 8.31257 39.0762 6.33363 37.2158 4.86053C35.7298 3.66501 33.9919 2.82236 32.1329 2.39608C30.5142 2.03535 28.8421 1.98309 27.204 2.24205C25.8505 2.52848 24.5136 2.88841 23.1993 3.32025C20.7068 4.04838 20.2868 4.27242 19.4886 4.09039C18.2284 3.81034 18.2284 3.0402 16.5621 2.24205C15.3984 1.72611 14.1383 1.46359 12.8654 1.47191C11.2982 1.3176 9.71657 1.52849 8.24455 2.08802C6.00037 3.12012 4.20152 4.92392 3.17562 7.17095C2.22494 8.99396 1.64885 10.9889 1.48132 13.038C1.32261 15.9355 1.82042 18.8314 2.93758 21.5096Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <path opacity="0.2" d="M13.1531 18.5047C12.3496 23.4417 12.8816 28.3786 12.9793 32.3651C13.0879 36.7343 12.5776 36.0061 12.7948 39.499C13.0137 43.0347 13.6192 46.5256 14.5971 49.8912C14.9446 51.0637 15.3789 52.2856 15.8458 54.3838C16.3126 56.482 21.5317 63.0137 21.7162 63.0137C22.1397 63.0137 16.7469 53.3358 16.7469 47.7381C16.4863 44.1835 16.6166 42.6833 16.7469 39.499C16.8555 36.5245 17.3332 33.1797 17.8327 33.1797C18.3321 33.1797 18.3213 35.1422 18.9184 38.2771C19.7545 42.7327 20.5037 46.7563 22.686 50.5083C24.3907 53.4458 25.4221 53.5569 27.0291 57.025C28.18 59.5922 29.2549 65.5749 29.5372 65.5132C29.8195 65.4515 29.0486 57.926 28.2885 54.3838C27.5285 50.8416 26.6708 50.0393 25.7696 46.8427C24.3798 41.9057 24.2712 36.0555 25.4113 35.6235C26.2147 35.3396 27.5828 37.6723 29.5372 41.1282C31.0572 43.7694 31.6327 45.2382 33.3047 48.6693C34.9768 52.1005 33.8037 61.7235 34.2162 61.5137C34.6288 61.3039 36.9173 54.2727 35.6144 49.8912C34.4309 45.917 34.2819 45.3521 33.3047 41.9456C32.5549 39.6196 32.1372 36.8254 31.687 34.4016C31.2375 31.9933 31.0588 29.5298 31.1549 27.0703C31.3069 23.96 31.8824 22.7999 31.8715 18.912C31.8715 16.3202 31.6001 15.3204 30.9704 14.6293C30.3406 13.9381 29.1571 13.7159 27.3874 13.6172C25.754 13.366 24.0913 13.5736 22.5449 14.222C20.0042 15.4562 19.9825 17.5791 18.2018 17.6901C16.4212 17.8012 15.4223 15.6907 14.2605 16.2708C13.4897 16.6287 13.2725 17.8012 13.1531 18.5047Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M37.1881 4.22843C39.0276 5.6775 40.4166 7.62019 41.1929 9.82945C43.0832 15.8645 39.1905 23.1039 37.8042 25.7083C37.4739 26.2911 37.2153 26.9118 37.0341 27.5567C36.6325 28.9187 36.5228 30.3501 36.712 31.7575C37.0735 34.4215 37.6447 37.0528 38.4204 39.6269C39.2907 42.8198 39.7055 46.1197 39.6526 49.4287C39.4058 53.5826 38.4096 57.6576 36.712 61.4569C35.9559 63.4732 34.0236 68.6262 32.8613 68.4582C32.3853 68.3601 32.1892 67.352 31.9372 64.9155C31.4191 59.6645 31.7131 57.9142 31.4751 55.1137C31.4751 54.5256 30.859 48.2805 27.4704 44.0097C27.1002 43.4546 26.6238 42.9782 26.0686 42.6081C25.5135 42.238 24.8906 41.9815 24.2358 41.8533C23.7695 41.7771 23.2921 41.8068 22.8388 41.9401C22.3855 42.0734 21.9681 42.3069 21.6173 42.6234C21.2952 42.9875 21.0992 43.4776 21.1552 45.55C21.121 46.8408 21.2243 48.1317 21.4633 49.4007C22.0514 52.2012 22.9755 52.5232 23.9277 55.1137C24.3898 56.3879 24.5438 58.9924 24.8519 64.2014C25.0059 66.8759 25.0059 68.0661 24.3898 68.4021C23.7737 68.7382 22.1914 67.436 21.7713 67.0019C19.811 65.0835 19.853 63.4592 18.5227 60.8408C17.1925 58.2223 16.7164 58.9224 14.2099 55.1417C12.8982 53.2393 11.8127 51.1904 10.9753 49.0366C10.0512 46.5862 10.0792 45.3539 9.43506 41.0271C9.05819 37.4819 8.38844 33.974 7.43269 30.5392C7.02662 29.321 6.73257 29.209 5.09427 25.6523C3.87605 23.0199 3.25993 21.7036 2.95187 20.8355C1.85268 18.1483 1.37405 15.2477 1.55162 12.3499C1.71521 10.292 2.30153 8.2899 3.27393 6.46883C4.27118 4.25996 6.01692 2.47454 8.20284 1.42791C9.67217 0.856172 11.2524 0.626327 12.8237 0.755791C14.0959 0.754137 15.3546 1.01637 16.5204 1.52593C18.2007 2.32408 18.1867 3.10822 19.4469 3.38827C20.245 3.5563 20.6651 3.38827 23.1576 2.60413C25.314 1.97401 26.4062 1.65196 27.1623 1.52593C28.8008 1.27451 30.4716 1.32672 32.0912 1.67996C33.9594 2.13741 35.7012 3.0083 37.1881 4.22843Z" fill="#DEE1E9"/>
      <path d="M24.599 59.5078L19.2163 46.6045L22.2163 34.6045L31.5752 45.1045V57.1045C31.7234 64.3964 31.1308 64.3964 31.1308 64.3964C29.1241 68.3838 27.7908 66.5813 26.4575 65.4479C25.1242 64.3145 24.599 59.5078 24.599 59.5078Z" fill="#DEE1E9"/>
      <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="27">
        <path d="M2.93758 20.6004C3.20363 21.3425 3.67972 22.3927 4.56188 24.3111L10.807 26.1454C10.807 26.1454 14.8118 26.7615 17.2762 25.2352C19.7407 23.709 24.8376 25.2352 24.8376 25.2352C24.8376 25.2352 31.9229 26.1454 34.2333 26.1454C35.8033 26.037 37.2874 25.3889 38.4341 24.3111C40.0864 21.1045 42.8309 14.9294 41.2346 9.6504C40.4717 7.40339 39.0762 5.42445 37.2158 3.95136C35.7298 2.75583 33.9919 1.91318 32.1329 1.4869C30.5142 1.12617 28.8421 1.07391 27.204 1.33287C25.8505 1.6193 24.5136 1.97923 23.1993 2.41107C20.7068 3.1392 20.2868 3.36324 19.4886 3.18121C18.2284 2.90116 18.2284 2.13102 16.5621 1.33287C15.3984 0.816933 14.1383 0.554407 12.8654 0.562728C11.2982 0.408423 9.71657 0.619311 8.24455 1.17884C6.00037 2.21094 4.20152 4.01474 3.17562 6.26177C2.22494 8.08478 1.64885 10.0797 1.48132 12.1288C1.32261 15.0263 1.82042 17.9222 2.93758 20.6004Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask536)">
        <path d="M2.93758 20.6004C3.20363 21.3425 3.67972 22.3927 4.56188 24.3111L10.807 26.1454C10.807 26.1454 14.8118 26.7615 17.2762 25.2352C19.7407 23.709 24.8376 25.2352 24.8376 25.2352C24.8376 25.2352 31.9229 26.1454 34.2333 26.1454C35.8033 26.037 37.2874 25.3889 38.4341 24.3111C40.0864 21.1045 42.8309 14.9294 41.2346 9.6504C40.4717 7.40339 39.0762 5.42445 37.2158 3.95136C35.7298 2.75583 33.9919 1.91318 32.1329 1.4869C30.5142 1.12617 28.8421 1.07391 27.204 1.33287C25.8505 1.6193 24.5136 1.97923 23.1993 2.41107C20.7068 3.1392 20.2868 3.36324 19.4886 3.18121C18.2284 2.90116 18.2284 2.13102 16.5621 1.33287C15.3984 0.816933 14.1383 0.554407 12.8654 0.562728C11.2982 0.408423 9.71657 0.619311 8.24455 1.17884C6.00037 2.21094 4.20152 4.01474 3.17562 6.26177C2.22494 8.08478 1.64885 10.0797 1.48132 12.1288C1.32261 15.0263 1.82042 17.9222 2.93758 20.6004Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <path opacity="0.4" d="M13.1531 17.5956C12.3496 22.5325 12.8816 27.4694 12.9793 31.456C13.0879 35.8251 12.5776 35.0969 12.7948 38.5898C13.0137 42.1255 13.6192 45.6164 14.5971 48.982C14.9446 50.1545 15.3789 51.3764 15.8458 53.4746C16.3126 55.5728 21.5317 62.1045 21.7162 62.1045C22.1397 62.1045 16.7469 52.4267 16.7469 46.8289C16.4863 43.2743 16.6166 41.7741 16.7469 38.5898C16.8555 35.6153 17.3332 32.2706 17.8327 32.2706C18.3321 32.2706 18.3213 34.233 18.9184 37.3679C19.7545 41.8235 20.5037 45.8471 22.686 49.5991C24.3907 52.5366 25.4221 52.6477 27.0291 56.1159C28.18 58.6831 29.2549 64.6657 29.5372 64.604C29.8195 64.5423 29.0486 57.0169 28.2885 53.4746C27.5285 49.9324 26.6708 49.1301 25.7696 45.9335C24.3798 40.9966 24.2712 35.1463 25.4113 34.7143C26.2147 34.4305 27.5828 36.7632 29.5372 40.219C31.0572 42.8603 31.6327 44.329 33.3047 47.7601C34.9768 51.1913 33.8037 60.8143 34.2162 60.6045C34.6288 60.3947 36.9173 53.3635 35.6144 48.982C34.4309 45.0078 34.2819 44.4429 33.3047 41.0365C32.5549 38.7104 32.1372 35.9162 31.687 33.4924C31.2375 31.0841 31.0588 28.6206 31.1549 26.1611C31.3069 23.0509 31.8824 21.8907 31.8715 18.0029C31.8715 15.411 31.6001 14.4113 30.9704 13.7201C30.3406 13.0289 29.1571 12.8067 27.3874 12.708C25.754 12.4568 24.0913 12.6645 22.5449 13.3128C20.0042 14.547 19.9825 16.6699 18.2018 16.781C16.4212 16.892 15.4223 14.7815 14.2605 15.3616C13.4897 15.7195 13.2725 16.8921 13.1531 17.5956Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M36.4551 5.22794C38.2945 6.67702 39.6836 8.6197 40.4598 10.829C42.3502 16.8641 38.4575 24.1034 37.0712 26.7079C36.7409 27.2906 36.4823 27.9113 36.3011 28.5562C35.8995 29.9182 35.7898 31.3496 35.979 32.757C36.3404 35.421 36.9116 38.0523 37.6873 40.6264C38.5577 43.8193 38.9725 47.1192 38.9196 50.4282C38.6727 54.5821 37.6765 58.6571 35.979 62.4564C35.2229 64.4728 33.2905 69.6257 32.1283 69.4577C31.6522 69.3596 31.4562 68.3515 31.2041 65.915C30.686 60.6641 30.9801 58.9137 30.7421 56.1132C30.7421 55.5251 30.1259 49.28 26.7373 45.0092C26.3672 44.4541 25.8908 43.9777 25.3356 43.6076C24.7805 43.2375 24.1575 42.981 23.5027 42.8528C23.0365 42.7766 22.559 42.8063 22.1058 42.9396C21.6525 43.0729 21.235 43.3065 20.8843 43.6229C20.5622 43.987 20.3662 44.4771 20.4222 46.5495C20.388 47.8403 20.4913 49.1312 20.7302 50.4002C21.3183 53.2007 22.2425 53.5228 23.1947 56.1132C23.6568 57.3875 23.8108 59.9919 24.1188 65.2009C24.2729 67.8754 24.2729 69.0656 23.6568 69.4017C23.0407 69.7377 21.4584 68.4355 21.0383 68.0014C19.0779 66.0831 19.1199 64.4588 17.7897 61.8403C16.4594 59.2218 15.9834 59.9219 13.4769 56.1412C12.1652 54.2388 11.0797 52.1899 10.2423 50.0361C9.31814 47.5857 9.34615 46.3534 8.70203 42.0267C8.32516 38.4814 7.65541 34.9735 6.69966 31.5387C6.29359 30.3205 5.99953 30.2085 4.36124 26.6518C3.14301 24.0194 2.5269 22.7031 2.21884 21.835C1.11965 19.1478 0.641014 16.2473 0.818588 13.3494C0.982174 11.2915 1.56849 9.28941 2.5409 7.46835C3.53815 5.25947 5.28389 3.47405 7.4698 2.42743C8.93914 1.85568 10.5193 1.62584 12.0906 1.7553C13.3629 1.75365 14.6216 2.01589 15.7873 2.52544C17.4676 3.32359 17.4536 4.10773 18.7139 4.38778C19.512 4.55581 19.9321 4.38778 22.4245 3.60364C24.5809 2.97353 25.6731 2.65147 26.4293 2.52544C28.0678 2.27402 29.7386 2.32623 31.3582 2.67947C33.2264 3.13693 34.9682 4.00781 36.4551 5.22794Z" fill="#DEE1E9"/>
      <path d="M23.866 60.5073L18.4833 47.604L21.4833 35.604L30.8422 46.104V58.104C30.9903 65.396 30.3977 65.396 30.3977 65.396C28.3911 69.3833 27.0578 67.5808 25.7245 66.4474C24.3912 65.314 23.866 60.5073 23.866 60.5073Z" fill="#DEE1E9"/>
      <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="1" width="42" height="27">
        <path d="M2.20455 21.5999C2.4706 22.342 2.94669 23.3922 3.82885 25.3106L10.074 27.1449C10.074 27.1449 14.0787 27.761 16.5432 26.2348C19.0076 24.7085 24.1046 26.2348 24.1046 26.2348C24.1046 26.2348 31.1898 27.1449 33.5003 27.1449C35.0702 27.0365 36.5544 26.3884 37.701 25.3106C39.3533 22.104 42.0978 15.9289 40.5015 10.6499C39.7387 8.4029 38.3432 6.42396 36.4828 4.95087C34.9968 3.75535 33.2588 2.91269 31.3999 2.48641C29.7812 2.12568 28.109 2.07342 26.471 2.33238C25.1175 2.61881 23.7806 2.97874 22.4663 3.41058C19.9738 4.13871 19.5537 4.36276 18.7556 4.18072C17.4953 3.90067 17.4953 3.13053 15.829 2.33238C14.6654 1.81645 13.4053 1.55392 12.1324 1.56224C10.5652 1.40793 8.98354 1.61882 7.51152 2.17836C5.26733 3.21045 3.46849 5.01425 2.44259 7.26128C1.49191 9.08429 0.915817 11.0792 0.748283 13.1284C0.589577 16.0258 1.08738 18.9218 2.20455 21.5999Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask536)">
        <path d="M2.20455 21.5999C2.4706 22.342 2.94669 23.3922 3.82885 25.3106L10.074 27.1449C10.074 27.1449 14.0787 27.761 16.5432 26.2348C19.0076 24.7085 24.1046 26.2348 24.1046 26.2348C24.1046 26.2348 31.1898 27.1449 33.5003 27.1449C35.0702 27.0365 36.5544 26.3884 37.701 25.3106C39.3533 22.104 42.0978 15.9289 40.5015 10.6499C39.7387 8.4029 38.3432 6.42396 36.4828 4.95087C34.9968 3.75535 33.2588 2.91269 31.3999 2.48641C29.7812 2.12568 28.109 2.07342 26.471 2.33238C25.1175 2.61881 23.7806 2.97874 22.4663 3.41058C19.9738 4.13871 19.5537 4.36276 18.7556 4.18072C17.4953 3.90067 17.4953 3.13053 15.829 2.33238C14.6654 1.81645 13.4053 1.55392 12.1324 1.56224C10.5652 1.40793 8.98354 1.61882 7.51152 2.17836C5.26733 3.21045 3.46849 5.01425 2.44259 7.26128C1.49191 9.08429 0.915817 11.0792 0.748283 13.1284C0.589577 16.0258 1.08738 18.9218 2.20455 21.5999Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5037" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="35" width="30" height="36">
        <rect x="8.03149" y="35.5137" width="29.1809" height="35.4105" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5037)">
        <path opacity="0.4" d="M11.1252 16.5043C10.3218 21.4412 10.8538 26.3781 10.9515 30.3647C11.0601 34.7338 10.5498 34.0056 10.7669 37.4985C10.9859 41.0342 11.5913 44.5251 12.5693 47.8907C12.9167 49.0632 13.351 50.2851 13.8179 52.3833C14.2848 54.4815 19.5038 61.0132 19.6884 61.0132C20.1119 61.0132 14.7191 51.3353 14.7191 45.7376C14.4585 42.183 14.5888 40.6828 14.7191 37.4985C14.8277 34.524 15.3054 31.1792 15.8049 31.1792C16.3043 31.1792 16.2934 33.1417 16.8906 36.2766C17.7266 40.7322 18.4758 44.7558 20.6582 48.5078C22.3628 51.4453 23.3943 51.5564 25.0012 55.0246C26.1521 57.5918 27.227 63.5744 27.5093 63.5127C27.7916 63.451 27.0207 55.9256 26.2607 52.3833C25.5007 48.8411 24.6429 48.0388 23.7417 44.8422C22.352 39.9052 22.2434 34.055 23.3834 33.623C24.1869 33.3391 25.555 35.6719 27.5093 39.1277C29.0294 41.7689 29.6048 43.2377 31.2769 46.6688C32.949 50.1 31.7758 59.723 32.1884 59.5132C32.601 59.3034 34.8895 52.2722 33.5866 47.8907C32.4031 43.9165 32.2541 43.3516 31.2769 39.9451C30.5271 37.6191 30.1094 34.8249 29.6591 32.4011C29.2097 29.9928 29.0309 27.5293 29.1271 25.0698C29.2791 21.9596 29.8546 20.7994 29.8437 16.9116C29.8437 14.3197 29.5723 13.3199 28.9425 12.6288C28.3128 11.9376 27.1293 11.7154 25.3595 11.6167C23.7261 11.3655 22.0635 11.5732 20.517 12.2215C17.9764 13.4557 17.9547 15.5786 16.174 15.6897C14.3934 15.8007 13.3945 13.6902 12.2327 14.2703C11.4618 14.6282 11.2447 15.8007 11.1252 16.5043Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
      <mask id="mask50037" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="0" width="30" height="36">
        <rect x="8.03162" y="0.103027" width="29.1809" height="35.4105" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask50037)">
        <path opacity="0.2" d="M11.1252 16.5043C10.3218 21.4412 10.8538 26.3781 10.9515 30.3647C11.0601 34.7338 10.5498 34.0056 10.7669 37.4985C10.9859 41.0342 11.5913 44.5251 12.5693 47.8907C12.9167 49.0632 13.351 50.2851 13.8179 52.3833C14.2848 54.4815 19.5038 61.0132 19.6884 61.0132C20.1119 61.0132 14.7191 51.3353 14.7191 45.7376C14.4585 42.183 14.5888 40.6828 14.7191 37.4985C14.8277 34.524 15.3054 31.1792 15.8049 31.1792C16.3043 31.1792 16.2934 33.1417 16.8906 36.2766C17.7266 40.7322 18.4758 44.7558 20.6582 48.5078C22.3628 51.4453 23.3943 51.5564 25.0012 55.0246C26.1521 57.5918 27.227 63.5744 27.5093 63.5127C27.7916 63.451 27.0207 55.9256 26.2607 52.3833C25.5007 48.8411 24.6429 48.0388 23.7417 44.8422C22.352 39.9052 22.2434 34.055 23.3834 33.623C24.1869 33.3391 25.555 35.6719 27.5093 39.1277C29.0294 41.7689 29.6048 43.2377 31.2769 46.6688C32.949 50.1 31.7758 59.723 32.1884 59.5132C32.601 59.3034 34.8895 52.2722 33.5866 47.8907C32.4031 43.9165 32.2541 43.3516 31.2769 39.9451C30.5271 37.6191 30.1094 34.8249 29.6591 32.4011C29.2097 29.9928 29.0309 27.5293 29.1271 25.0698C29.2791 21.9596 29.8546 20.7994 29.8437 16.9116C29.8437 14.3197 29.5723 13.3199 28.9425 12.6288C28.3128 11.9376 27.1293 11.7154 25.3595 11.6167C23.7261 11.3655 22.0635 11.5732 20.517 12.2215C17.9764 13.4557 17.9547 15.5786 16.174 15.6897C14.3934 15.8007 13.3945 13.6902 12.2327 14.2703C11.4618 14.6282 11.2447 15.8007 11.1252 16.5043Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M35.9455 4.42276C37.7849 5.87184 39.1739 7.81453 39.9502 10.0238C41.8405 16.0589 37.9478 23.2982 36.5616 25.9027C36.2312 26.4855 35.9726 27.1061 35.7914 27.751C35.3898 29.113 35.2801 30.5445 35.4694 31.9518C35.8308 34.6158 36.402 37.2471 37.1777 39.8212C38.048 43.0141 38.4629 46.314 38.4099 49.623C38.1631 53.7769 37.1669 57.8519 35.4694 61.6512C34.7132 63.6676 32.7809 68.8205 31.6187 68.6525C31.1426 68.5545 30.9465 67.5463 30.6945 65.1098C30.1764 59.8589 30.4705 58.1086 30.2324 55.3081C30.2324 54.7199 29.6163 48.4748 26.2277 44.204C25.8575 43.6489 25.3811 43.1726 24.826 42.8024C24.2708 42.4323 23.6479 42.1758 22.9931 42.0476C22.5268 41.9715 22.0494 42.0011 21.5961 42.1344C21.1429 42.2677 20.7254 42.5013 20.3746 42.8178C20.0526 43.1818 19.8565 43.6719 19.9125 45.7443C19.8783 47.0352 19.9816 48.326 20.2206 49.595C20.8087 52.3955 21.7329 52.7176 22.685 55.3081C23.1471 56.5823 23.3011 59.1868 23.6092 64.3957C23.7632 67.0702 23.7632 68.2604 23.1471 68.5965C22.531 68.9325 20.9487 67.6303 20.5286 67.1962C18.5683 65.2779 18.6103 63.6536 17.28 61.0351C15.9498 58.4166 15.4737 59.1167 12.9673 55.3361C11.6555 53.4336 10.57 51.3847 9.73266 49.2309C8.8085 46.7805 8.8365 45.5483 8.19239 41.2215C7.81551 37.6762 7.14577 34.1683 6.19002 30.7336C5.78394 29.5153 5.48989 29.4033 3.85159 25.8467C2.63337 23.2142 2.01725 21.898 1.7092 21.0298C0.610008 18.3426 0.131367 15.4421 0.308945 12.5442C0.472527 10.4863 1.05885 8.48424 2.03126 6.66317C3.0285 4.45429 4.77425 2.66888 6.96016 1.62225C8.42949 1.05051 10.0097 0.820663 11.581 0.950127C12.8532 0.948473 14.112 1.21071 15.2777 1.72027C16.958 2.51841 16.944 3.30256 18.2042 3.58261C19.0024 3.75064 19.4224 3.58261 21.9149 2.79846C24.0713 2.16835 25.1635 1.84629 25.9196 1.72027C27.5581 1.46884 29.2289 1.52105 30.8485 1.8743C32.7168 2.33175 34.4585 3.20264 35.9455 4.42276Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M36.9455 4.0546C38.7849 5.50368 40.1739 7.44636 40.9502 9.65562C42.8405 15.6907 38.9478 22.93 37.5616 25.5345C37.2312 26.1173 36.9726 26.7379 36.7914 27.3829C36.3898 28.7449 36.2801 30.1763 36.4694 31.5836C36.8308 34.2476 37.402 36.879 38.1777 39.4531C39.048 42.646 39.4629 45.9459 39.4099 49.2548C39.1631 53.4088 38.1669 57.4838 36.4694 61.283C35.7132 63.2994 33.7809 68.4524 32.6187 68.2843C32.1426 68.1863 31.9465 67.1781 31.6945 64.7417C31.1764 59.4907 31.4705 57.7404 31.2324 54.9399C31.2324 54.3518 30.6163 48.1066 27.2277 43.8359C26.8575 43.2807 26.3811 42.8044 25.826 42.4343C25.2708 42.0642 24.6479 41.8076 23.9931 41.6795C23.5268 41.6033 23.0494 41.633 22.5961 41.7663C22.1429 41.8996 21.7254 42.1331 21.3746 42.4496C21.0526 42.8137 20.8565 43.3038 20.9125 45.3761C20.8783 46.667 20.9816 47.9578 21.2206 49.2268C21.8087 52.0274 22.7329 52.3494 23.685 54.9399C24.1471 56.2141 24.3011 58.8186 24.6092 64.0275C24.7632 66.702 24.7632 67.8923 24.1471 68.2283C23.531 68.5644 21.9487 67.2621 21.5286 66.8281C19.5683 64.9097 19.6103 63.2854 18.28 60.6669C16.9498 58.0485 16.4737 58.7486 13.9673 54.9679C12.6555 53.0654 11.57 51.0166 10.7327 48.8628C9.8085 46.4123 9.8365 45.1801 9.19239 40.8533C8.81551 37.3081 8.14577 33.8001 7.19002 30.3654C6.78394 29.1472 6.48989 29.0352 4.85159 25.4785C3.63337 22.846 3.01725 21.5298 2.7092 20.6616C1.61001 17.9745 1.13137 15.0739 1.30894 12.1761C1.47253 10.1181 2.05885 8.11607 3.03126 6.29501C4.0285 4.08613 5.77425 2.30071 7.96016 1.25409C9.42949 0.682344 11.0097 0.452499 12.581 0.581963C13.8532 0.580309 15.112 0.842546 16.2777 1.3521C17.958 2.15025 17.944 2.93439 19.2042 3.21444C20.0024 3.38247 20.4224 3.21444 22.9149 2.4303C25.0713 1.80019 26.1635 1.47813 26.9196 1.3521C28.5581 1.10068 30.2289 1.15289 31.8485 1.50613C33.7168 1.96359 35.4585 2.83447 36.9455 4.0546Z" fill="#DEE1E9"/>
      <path d="M2.67952 20.6336C2.94557 21.3757 3.42166 22.4259 4.30382 24.3443L10.549 26.1786C10.549 26.1786 14.5537 26.7947 17.0181 25.2684C19.4826 23.7422 24.5795 25.2684 24.5795 25.2684C24.5795 25.2684 31.6648 26.1786 33.9752 26.1786C35.5452 26.0702 37.0294 25.4221 38.176 24.3443C39.8283 21.1377 42.5728 14.9626 40.9765 9.6836C40.2136 7.43659 38.8182 5.45765 36.9578 3.98456C35.4718 2.78904 33.7338 1.94638 31.8749 1.52011C30.2562 1.15937 28.584 1.10712 26.946 1.36608C25.5925 1.6525 24.2556 2.01243 22.9412 2.44427C20.4488 3.1724 20.0287 3.39645 19.2305 3.21441C17.9703 2.93436 17.9703 2.16422 16.304 1.36608C15.1403 0.850136 13.8802 0.58761 12.6073 0.595931C11.0401 0.441626 9.45851 0.652514 7.9865 1.21205C5.74231 2.24414 3.94347 4.04795 2.91757 6.29497C1.96688 8.11798 1.39079 10.1129 1.22326 12.162C1.06455 15.0595 1.56236 17.9554 2.67952 20.6336Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth37",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
