export const billingPaymentAdapter =
    ({
         clinic_id,
         sum,
         currency,
         months,
         comment,
     }) => ({
        clinic_id: clinic_id ? clinic_id.code : null,
        sum: sum ?? null,
        currency: currency ? currency : null,
        months: months || 1,
        comment: comment || null,
        pay_system: 'manual',
        paid: 1
    })

