<template>
  <b-modal id="reason-prompt-modal"
           size="sm"
           :title="$t('write_reason')"
           @hide="onHide"
           v-model="visible"
           centered>
    <div class="">

        <b-list-group>
          <b-list-group-item v-for="record_reason in record_reasons"
                             href="#"
               :key="`r-r-${record_reason.id}`"
                             class="pointer py-2"
                             @click="selectOption(record_reason.title)">
            {{ record_reason.title }}
<!--            <a class="btn-themed-outline d-block w-100 pointer"-->
<!--               @click="selectOption(record_reason.title)">-->
<!--              {{ record_reason.title }}-->
<!--            </a>-->
          </b-list-group-item>
        </b-list-group>

      <div class="pt-3">

        <InputGroup v-model="inputValue"
                    :placeholder="$t('or_write_other_reason')"/>
<!--        <div v-for="record_reason in record_reasons"-->
<!--             :key="`r-r-${record_reason.id}`">-->
<!--          <a class="btn-themed-outline d-block w-100 pointer"-->
<!--             @click="selectOption(record_reason.title)">-->
<!--            {{ record_reason.title }}-->
<!--          </a>-->
<!--        </div>-->
      </div>
    </div>
    <template #modal-footer>
      <div class="modal-footer-buttons no-print">
        <b-button class="cancel mr-md-3 mr-2 d-inline-block align-middle"
                  variant="themed"
                  @click="cancel">
          {{ $t('cancel') }}
        </b-button>
        <button @click="submit"
                class="btn-themed align-middle btn-themed-squared">OK</button>
      </div>

    </template>
  </b-modal>
</template>

<script>
import InputGroup from "@/components/form/InputGroup.vue"
import {mapState} from "vuex"

export default {
  name: "ReasonPrompt",
  components: {
    InputGroup,
  },
  data() {
    return {
      visible: false,
      inputValue: '',
      resolve: null,
      reject: null,
    }
  },
  computed: {
    ...mapState({
      record_reasons: state  => state.auth.record_reasons,
    }),
  },
  methods: {
    showPrompt() {
      this.visible = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    submit() {
      this.resolve(this.inputValue)
      this.hide()
    },
    cancel() {
      this.resolve(null)
      this.hide()
    },
    hide() {
      this.visible = false
      this.inputValue = ''
    },
    onHide() {
      this.resolve(null)
    },
    selectOption(text) {
      this.resolve(text)
      this.hide()
    }
  }
}
</script>
