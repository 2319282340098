<template>
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path
        d="M3.33064 13.1694C3.20264 13.1694 3.07464 13.1207 2.97731 13.0227C2.78198 12.8274 2.78198 12.5107 2.97731 12.3154L11.7093 3.58336C11.9046 3.38803 12.2213 3.38803 12.4166 3.58336C12.612 3.77869 12.612 4.09536 12.4166 4.29069L3.68398 13.0234C3.58664 13.1207 3.45864 13.1694 3.33064 13.1694V13.1694Z"/>
    <path
        d="M0.500089 16C0.368756 16 0.240756 15.948 0.146756 15.8534C0.0280889 15.7354 -0.0225778 15.5654 0.0100889 15.402L0.953422 10.688C0.972756 10.5907 1.02076 10.502 1.09009 10.4327L10.8028 0.719355C11.7294 -0.207978 13.3474 -0.208645 14.2768 0.719355L15.2808 1.72336C16.2388 2.68136 16.2388 4.23936 15.2808 5.19736L5.56809 14.91C5.49876 14.98 5.40942 15.0274 5.31276 15.0467L0.598756 15.99C0.566089 15.9967 0.532756 16 0.500089 16V16ZM1.90342 11.032L1.13742 14.8627L4.96742 14.096L14.5734 4.49069C15.1414 3.92269 15.1414 2.99869 14.5734 2.43069L13.5694 1.42669C13.0174 0.876022 12.0574 0.876689 11.5094 1.42669L1.90342 11.032ZM5.21409 14.5567H5.22076H5.21409Z"/>
    <path
        d="M5.2141 15.0567C5.0861 15.0567 4.9581 15.008 4.86077 14.91L1.0901 11.1393C0.89477 10.944 0.89477 10.6273 1.0901 10.432C1.28544 10.2367 1.6021 10.2367 1.79744 10.432L5.5681 14.2027C5.76344 14.398 5.76344 14.7147 5.5681 14.91C5.4701 15.008 5.34277 15.0567 5.2141 15.0567V15.0567Z"/>
    <path
        d="M13.9486 6.32195C13.8206 6.32195 13.6926 6.27328 13.5952 6.17528L9.82457 2.40461C9.62924 2.20928 9.62924 1.89261 9.82457 1.69728C10.0199 1.50195 10.3366 1.50195 10.5319 1.69728L14.3026 5.46795C14.4979 5.66328 14.4979 5.97995 14.3026 6.17528C14.2046 6.27328 14.0766 6.32195 13.9486 6.32195Z"/>
  </g>
</svg>
</template>
