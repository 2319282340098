<template>
  <div v-if="!(object.clinic_id == 0 && object.id)">
    <SelectGroup :options="selectOptions"
                 variant="white"
                 :label="$t(field.title)"
                 :required="false"
                 :translate="false"
                 :show-errors="true"
                 validation-id="parent_id"
                 :showlabel="true"
                 v-model="object[field.id]"/>
  </div>
</template>

<script>

import SelectGroup from "@/components/form/SelectGroup"

export default {
  name: "ServiceCategoryDatatableFormParentId",
  components: {
    SelectGroup,
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
}
</script>

<style scoped>

</style>
