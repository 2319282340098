import Vue from "vue"
import {EloquentService} from "@/services/api.service"

const state = {
    diagnoses: {},
    services: {},
    packages: {},
    materials: {},
    complexServices: {},
    diagnosisTooth: null,
    dropdownServiceIdOpened: null,
    myOpenedVisit: null
}

const getters = {
    materialsAndPackagesByTeeth(state, getters, rootState) {
        let materialsArray = []
        let packagesArray = []
        const materialsByTeeth = {}
        const packagesByTeeth = {}
        const materialAmountLeft = {}
        const packageAmountLeft = {}
        const materialsObject = JSON.parse(JSON.stringify(state.materials))
        const packagesObject = JSON.parse(JSON.stringify(state.packages))
        for(const serviceId in materialsObject) {
            materialsObject[serviceId].forEach(material => {
                materialsArray.push({ ...material, serviceId: Number(serviceId), title: material.name })
            })
        }
        for(const serviceId in packagesObject) {
            packagesObject[serviceId].forEach(kit => {
                // const index = packagesArray.findIndex(item => item.package_id === kit.package_id)
                // if(index !== -1) {
                //     packagesArray[index].amount++
                // } else {
                    packagesArray.push({ ...kit, serviceId: Number(serviceId), amount: 1 })
                // }
            })
        }
        materialsArray.forEach((item, index) => {
            materialAmountLeft[index] = item.pivot.amount
        })
        packagesArray.forEach((item, index) => {
            packageAmountLeft[index] = item.amount
        })
        rootState.teeth.recordTeeth.forEach(tooth => {
            materialsByTeeth[tooth] = []
            packagesByTeeth[tooth] = []
            const services = Object.values(state.services).filter(service => service.teeth.includes(tooth))
            services.forEach(service => {
                const amountOfTeethServiceCovers = [...new Set(service.teeth)].length
                // const amountOfTeethServiceCovers = service.teeth.length
                materialsArray.forEach((material, index) => {
                    if(material.serviceId !== Number(service.id)) return
                    const amountToBeAssigned = Math.min(Math.ceil(material.pivot.amount / amountOfTeethServiceCovers), materialAmountLeft[index])
                    if(amountToBeAssigned > 0) {
                        materialAmountLeft[index] -= amountToBeAssigned
                        materialsByTeeth[tooth].push({ ...material, pivot: { ...material.pivot, amount: amountToBeAssigned } })
                    }
                })
                packagesArray.forEach((kit, index) => {
                    if(kit.serviceId !== Number(service.id)) return
                    const amountToBeAssigned = Math.min(Math.ceil(kit.amount / amountOfTeethServiceCovers), packageAmountLeft[index])
                    if(amountToBeAssigned > 0) {
                        packageAmountLeft[index] -= amountToBeAssigned
                        packagesByTeeth[tooth].push(kit)
                    }
                })
            })
        })
        return { materialsByTeeth, packagesByTeeth }
    },
}

const mutations = {
    parseDiagnoses(state, { view, visit }) {
        let arr = {}
        let visit_teeth = view === 'plan' ? 'teeth' : 'record_teeth'
        let tooth_teeth = view === 'plan' ? 'tooth' : 'teeth'
        if(!visit || !visit[visit_teeth]) {
            state.diagnoses = arr
            return
        }
        visit[visit_teeth].forEach(tooth => {
            tooth.diagnoses.forEach(diagnose => {
                if(!arr[diagnose.id]) {
                    diagnose.teeth = [tooth[tooth_teeth]]
                    arr[diagnose.id] = diagnose
                } else {
                    arr[diagnose.id].teeth.push(tooth[tooth_teeth])
                }
            })
        })
        state.diagnoses = arr
    },
    parseComplexServices(state, { view, visit }) {
        let arr = {}
        let visit_teeth = view === 'plan' ? 'teeth' : 'record_teeth'
        let tooth_teeth = view === 'plan' ? 'tooth' : 'teeth'
        if(!visit || !visit[visit_teeth]) {
            state.complexServices = arr
            return
        }
        visit[visit_teeth].forEach(tooth => {
            arr[tooth[tooth_teeth]] = tooth.complex_services
        })
        state.complexServices = arr
    },
    setComplexServices(state, complexServices) {
        state.complexServices = complexServices
    },
    setComplexServiceForTooth(state, { service, tooth }) {
        state.complexServices[tooth] = service
    },
    setDiagnosisTooth(state, tooth) {
        state.diagnosisTooth = tooth
    },
    setDiagnoses(state, value) {
        state.diagnoses = value
    },
    newDiagnosisFound(state, { diagnosis, teeth }) {
        if(!state.diagnoses[diagnosis.id]) {
            diagnosis.teeth = teeth
            Vue.set(state.diagnoses, diagnosis.id, diagnosis)
        }
    },
    deleteDiagnosis(state, id) {
        Vue.delete(state.diagnoses, id)
    },
    setServices(state, value) {
        state.services = value
    },
    deleteService(state, id) {
        Vue.delete(state.services, id)
        Vue.delete(state.materials, id)
        Vue.delete(state.packages, id)
        let cnt = 1
        for(var i in state.services) {
            Vue.set(state.services[i], 'order', cnt)
            cnt++
        }
    },
    setPackages(state, value) {
        state.packages = value
    },
    setMaterials(state, value) {
        state.materials = value
    },
    setDropdownServiceIdOpened(state, value) {
        state.dropdownServiceIdOpened = value
    },
    nullifyTreatmentCard(state) {
        state.diagnoses = {}
        state.services = {}
        state.packages = {}
        state.materials = {}
        state.complexServices = {}
    },
    setMyOpenedVisit(state, value) {
        state.myOpenedVisit = value
    }
}

const actions = {
    newServiceFound({ state, dispatch }, { service, patient, teeth, visit, selectedTooth }) {
        if(!state.services[service.id]) {
            service.teeth = selectedTooth ? [selectedTooth] : teeth
            service.pivot = {
                amount:         service.pivot?.amount ?? 1,
                discount:       patient.discount ?? 0,
                discount_type:  'percent',
                price_original: service.cost_branch,
                technic_id:     service.technics?.length > 0 ? service.technics[0].id : null,
                doctor_id:      visit.doctor_id ?? patient.doctor_id,
            }
            service.order = Object.keys(state.services).length + 1
            for(var i = 1; i <= service.teeth.length; i++) {
                if(service.packages && service.packages.length > 0) {
                    if(!(state.packages[service.id])) {
                        Vue.set(state.packages, service.id, [])
                    }
                    state.packages[service.id] = state.packages[service.id].concat(service.packages)
                    service.packages.forEach(p => {
                        dispatch('addMaterialToService', { service_id: service.id, materials: JSON.parse(JSON.stringify(p.materials)) })
                    })
                }
                if(!(state.materials[service.id])) {
                    Vue.set(state.materials, service.id, [])
                }
                if(service.materials && service.materials.length > 0) {
                    service.materials.forEach(m => {
                        dispatch('addMaterialToService', { service_id: service.id, materials: [JSON.parse(JSON.stringify(m))] })
                    })
                }
            }
            Vue.set(state.services, service.id, service)
        }
    },
    addMaterialToService({state}, { service_id, materials }) {
        if(!state.materials[service_id]) {
            Vue.set(state.materials, service_id, materials)
            return
        }
        materials.forEach(material => {
            let index = state.materials[service_id].findIndex(el => el.material_id === material.material_id)
            if(index !== -1) {
                state.materials[service_id][index]['pivot']['amount'] = parseFloat(state.materials[service_id][index]['pivot']['amount']) + parseFloat(material['pivot']['amount'])
            } else {
                state.materials[service_id].push(material)
            }
        })
    },
    removePackageMaterialsFromService({ state }, { pack, serviceId }) {
        let removeMaterials = pack.materials
        if(!removeMaterials) return
        let tmpMaterials = JSON.parse(JSON.stringify(state.materials))
        if(serviceId) tmpMaterials = tmpMaterials[serviceId]
        if(!tmpMaterials) return
        removeMaterials.forEach(toremove => {
            tmpMaterials.forEach((current, index) => {
                if(current.material_id == toremove.material_id) {
                    if(parseFloat(toremove.pivot.amount) >= parseFloat(current.pivot.amount)) {
                        tmpMaterials.splice(index, 1)
                    } else {
                        current.pivot.amount -= toremove.pivot.amount
                    }
                }
            })
        })
        if(serviceId) Vue.set(state.materials, serviceId, tmpMaterials)
        if(!serviceId) state.materials = tmpMaterials
    },
    async quantityChangedManually({state, dispatch}, { service_id, sign, teeth, new_tooth, qty }) {
        let teeth_length = teeth.length
        let service = (await EloquentService.show('service', service_id, false, true)).data
        for(var i = 0; i < qty; i++) {
            if(sign === 'minus') {
                if(new_tooth) {
                    const index = teeth.indexOf(new_tooth)
                    if (index !== -1) teeth.splice(index, 1)
                } else {
                    teeth.length = Math.max(0, (teeth_length - 1))
                }
                if(service.packages && service.packages.length > 0) {
                    let statePackages = state.packages[service.id]
                    service.packages.forEach(p => {
                        const index = statePackages.findIndex(x => x.package_id === p.package_id)
                        if(index > -1) statePackages.splice(index, 1);
                        Vue.set(state.packages, service.id, statePackages)
                        dispatch('removePackageMaterialsFromService', {
                            serviceId: service.id,
                            pack: p
                        })
                    })
                }
                if(service.materials && service.materials.length > 0) {
                    dispatch('removePackageMaterialsFromService', { serviceId: service.id, pack: { materials: service.materials }})
                }
            }
            if(sign === 'plus') {
                // let new_tooth = this.teeth.find(t => !teeth.includes(t))
                // let new_tooth = null // decided not to add new tooth on plus, cause otherwise it is impossible to add more than 1 amount on 1 tooth without selecting other tooth
                if(!new_tooth && teeth_length > 0) new_tooth = teeth[(teeth_length - 1)]
                if(!new_tooth) new_tooth = teeth[0]
                teeth.push(new_tooth)

                if(service.packages && service.packages.length > 0) {
                    state.packages[service.id] = state.packages[service.id].concat(service.packages)
                    service.packages.forEach(p => {
                        dispatch('addMaterialToService', { service_id: service.id, materials: p.materials})
                    })
                }
                if(service.materials && service.materials.length > 0) {
                    service.materials.forEach(m => {
                        dispatch('addMaterialToService', { service_id: service.id, materials: [m]})
                    })
                }
            }
        }
        if(!state.services[service_id]) state.services[service_id] = {}
        Vue.set(state.services[service_id], 'teeth', teeth)
    },
    nullifyOpenedVisit(context) {
        context.commit('setMyOpenedVisit', null)
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}
