<template>
    <span>
      <a class="tdclickable data-table-action"
         v-b-tooltip.hover
         @click="functionalityClicked"
         :title="$t('functionality')">
      <ActivateSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('functionality') }}</span>
        </a>
    </span>
</template>

<script>

import ActivateSvg from '@/assets/svg-vue/header/note.svg'

export default {
  name: "SuperAdminTariffActions",
  components: { ActivateSvg },
  props: {
    object: {}
  },
  methods: {
    functionalityClicked() {
      this.$store.commit('setTariff', this.copyObject(this.object))
      this.$nextTick(() => {
        this.$bvModal.show('tariff-functionality-modal')
      })

    }
  }
}
</script>

<style scoped>

</style>
