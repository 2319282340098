const state = {
    task: null,
    lead: null,
    patientCanban: null,
    siteForm: null,
    showCallModal: false,
    wazzupIframeUrl: '',
    wazzupEnabled: false
}

const getters = {

}

const mutations = {
    setTask(state, value) {
        state.task = value
    },
    setLead(state, value) {
        state.lead = value
    },
    setPatientCanban(state, value) {
        state.patientCanban = value
    },
    setSiteForm(state, value) {
        state.siteForm = value
    },
    setShowCallModal(state, value) {
        state.showCallModal = value
    },
    setWazzupIframeUrl(state, value) {
        state.wazzupIframeUrl = value
    },
    setWazzupEnabled(state, value) {
        state.wazzupEnabled = value
    }
}

const actions = {

}

export default {
    state,
    actions,
    mutations,
    getters
}
