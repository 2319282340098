<template>
  <div>
    <!--    <b-spinner v-if="!loaded" label="Spinning"></b-spinner>-->
    <div  v-if="loaded">
      <div class="mb-3" v-if="!disabled && record.status === 'draft'">
        <b-button class="btn btn-themed btn-themed-squared btn-themed-outline"
                  variant="themed"
                  @click="refresh">
          {{ $t('refresh') }}
        </b-button>
      </div>
      <b-row>
        <b-col md="4">
          <h5 class="mb-4">Визит<ErrorPopup :errorIds="errorSorting.visit"/></h5>
          <ServiceMultiSelect label="Исход заболевания"
                              model="egiszDictionary"
                              title="title"
                              valueId="egisz_id"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="false"
                              :readonly="disabled"
                              validation-id="result_id"
                              :show-errors="true"
                              :prepopulatedOptions="dictionaries.getCaseResults"
                              v-model="record.result_id"/>
          <ServiceMultiSelect label="Цель посещения"
                              model="egiszDictionary"
                              title="title"
                              valueId="egisz_id"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="false"
                              :readonly="disabled"
                              validation-id="purpose_id"
                              :show-errors="true"
                              :prepopulatedOptions="dictionaries.getVisitPurposes"
                              v-model="record.purpose_id"/>
          <AutosizeTextArea v-model="record.disease_anamnez"
                            :readonly="disabled"
                            validation-id="disease_anamnez"
                            :show-errors="true"
                            :label="$t('disease_anamnez')"/>
          <AutosizeTextArea v-model="record.life_anamnez"
                            :readonly="disabled"
                            validation-id="life_anamnez"
                            :show-errors="true"
                            :label="$t('life_anamnez')"/>
          <AutosizeTextArea v-model="record.objectively"
                            :readonly="disabled"
                            validation-id="objectively"
                            :show-errors="true"
                            :label="$t('objectively')"/>
          <AutosizeTextArea v-model="record.treatment"
                            :readonly="disabled"
                            validation-id="treatment"
                            :show-errors="true"
                            :label="$t('treatment')"/>
          <AutosizeTextArea v-model="record.diagnosis"
                            :readonly="disabled"
                            validation-id="diagnosis"
                            :show-errors="true"
                            :label="$t('diagnosis_comments')"/>
          <ServiceMultiSelect label="МКБ Код"
                              model="egiszDictionary"
                              title="title"
                              valueId="egisz_id"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="false"
                              :readonly="disabled"
                              validation-id="mkb"
                              :show-errors="true"
                              :prepopulatedOptions="dictionaries.getMKBCodes"
                              v-model="record.mkb"/>
          <ServiceMultiSelect label="Источник финансирования"
                              model="egiszDictionary"
                              title="title"
                              valueId="egisz_id"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="false"
                              :readonly="disabled"
                              :show-errors="true"
                              validation-id="paytype_id"
                              :prepopulatedOptions="dictionaries.getPaymentTypes"
                              v-model="record.paytype_id"/>
        </b-col>
        <b-col md="4">
          <h5 class="mb-4">Врач / пациент<ErrorPopup :errorIds="errorSorting.doctorPatient"/></h5>
          <InputGroup v-model="record.snils_doctor"
                      validation-id="snils_doctor"
                      :show-errors="true"
                      :readonly="disabled"
                      label="СНИЛС доктора"/>
          <ServiceMultiSelect label="Специальность врача"
                              model="egiszDictionary"
                              title="title"
                              valueId="egisz_id"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="false"
                              :readonly="disabled"
                              :show-errors="true"
                              validation-id="specialty_id"
                              :prepopulatedOptions="dictionaries.getSpecialties"
                              v-model="record.specialty_id"/>
          <ServiceMultiSelect label="Должность врача"
                              model="egiszDictionary"
                              title="title"
                              valueId="egisz_id"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="false"
                              :readonly="disabled"
                              :show-errors="true"
                              validation-id="position_id"
                              :prepopulatedOptions="dictionaries.getDoctorPositions"
                              v-model="record.position_id"/>
          <InputGroup v-model="record.snils_patient"
                      :readonly="disabled"
                      :show-errors="true"
                      validation-id="snils_patient"
                      label="СНИЛС пациента"/>
          <ValidationError :validationId="['patient', 'main_doctor', 'clinic_id', 'id']" class="mt-4"/>
        </b-col>
        <b-col md="4">
          <h5 class="mb-4">В случае наличия законного представителя / опекуна пациента:<ErrorPopup :errorIds="errorSorting.representative"/></h5>
          <ServiceMultiSelect label="Официальный представитель пациента"
                              model="patient"
                              title="full_name"
                              :prepopulated-options="record.representative_user ? [record.representative_user] : null"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="true"
                              :readonly="disabled"
                              :show-errors="true"
                              validation-id="representative_id"
                              v-model="record.representative_id"/>
          <ServiceMultiSelect label="Отношение к пациенту"
                              model="egiszDictionary"
                              title="title"
                              valueId="egisz_id"
                              :onlyId="true"
                              :multiple="false"
                              :dblclick="false"
                              :dbsearch="false"
                              :readonly="disabled"
                              :show-errors="true"
                              validation-id="representative_type"
                              :prepopulatedOptions="dictionaries.getGuardianRelationships"
                              v-model="record.representative_type"/>
          <AutosizeTextArea v-model="record.representative_document"
                            :readonly="disabled"
                            :show-errors="true"
                            validation-id="representative_document"
                            label="Реквизиты документа, удостоверяющего право представлять пациента"/>
        </b-col>
      </b-row>
      <div v-if="disabled"
           class="alert text-center mt-4"
           :class="{
          'alert-warning': record.status !== 'sent_to_egisz',
          'alert-info': record.status === 'sent_to_egisz'
        }">
        {{ $t(record.status) }}
      </div>
      <div class="text-right" v-if="!disabled">
        <button class="btn-themed btn-themed-squared btn-themed-outline mt-4"
                v-if="record.status === 'draft' && view === 'record'"
                @click="toSign">
          {{ $t('to_sign') }}
        </button>
        <button class="btn-themed btn-themed-squared btn-themed-outline mt-4 ml-3"
                v-if="record.status === 'requires_attention' && view === 'record'"
                @click="sign">
          {{ $t('sign') }}
        </button>
        <button :disabled="loading"
                class="btn-themed btn-themed-squared mt-4 ml-3"
                @click="save">
          {{ $t('save') }}
        </button>
      </div>
      <EgiszSignModal v-if="record.status === 'requires_attention' && view === 'record'"
                      :records="[record]"
                      @signed="signed"/>
    </div>
  </div>
</template>

<script>
import InputGroup from "@/components/form/InputGroup.vue"
import { CalendarService, EloquentService } from "@/services/api.service"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import ValidationError from "@/components/form/ValidationError.vue"
import { mapState } from "vuex"
import ErrorPopup from "@/components/parts/general/ErrorPopup.vue"
import EgiszSignModal from "@/components/parts/patients/EgiszSignModal.vue"

export default {
  name: "EGISZForm",
  components: {
    EgiszSignModal,
    ErrorPopup,
    ValidationError,
    AutosizeTextArea,
    ServiceMultiSelect,
    InputGroup,
  },
  props: {
    recordId: {
      type: Number,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    view: {
      type: String,
      default: 'list'
    }
  },
  computed: {
    ...mapState({
      errors: state => state.datatable.validationErrors,
    }),
  },
  data() {
    return {
      loaded: false,
      loading: false,
      dictionaries: {},
      record: null,
      errorSorting: {
        visit: ['id', 'epikriz', 'diagnosis', 'mkb', 'purpose_id', 'result_id', 'paytype_id'],
        doctorPatient: ['doctor', 'main_doctor', 'clinic_id', 'patient', 'snils_patient', 'snils_doctor', 'specialty_id', 'position_id'],
        representative: ['representative_type', 'representative_document']
      },
    }
  },
  async mounted() {
    await this.loadDictionaries()
    await this.loadEGISZRecord()
    await this.validateEGISZ()
    this.loaded = true
  },
  methods: {
    async loadDictionaries() {
      const res = await CalendarService.getEGISZDictionaries()
      this.dictionaries = res.data
    },
    async loadEGISZRecord() {
      const res = await EloquentService.show('egiszRecord', this.recordId)
      this.record = res.data
    },
    async save() {
      this.loading = true
      try {
        let formData = new FormData()
        formData = this.appendFormdata(formData, this.record)
        const res = await EloquentService.update('egiszRecord', this.record.id, formData)
        if(res.data.id) {
          this.$noty.info(this.$t('success_message'))
          this.$emit('success')
          await this.validateEGISZ()
        }
      } finally {
        this.loading = false
      }
    },
    async validateEGISZ() {
      try {
        this.$store.commit('cleanValidationErrors')
        await CalendarService.validateEGISZ(this.recordId)
      } catch (e) {
        console.log(e.response)
      }
    },
    async refresh() {
      this.loaded = false
      const res = await CalendarService.autoupdateEGIZS(this.recordId)
      this.record = res.data
      await this.validateEGISZ()
      this.loaded = true
    },
    async toSign() {
      this.loaded = false
      try {
        const res = await CalendarService.EGISZNextStep(this.recordId)
        this.record = res.data
      } catch (e) {
        console.log(e.response)
      }
      this.loaded = true
    },
    async sign() {
      this.$bvModal.show('sign-document-modal')
    },
    signed() {
      this.loadEGISZRecord()
    },
  }
}
</script>
