<template>
  <g>
    <!--   SAME AS 31  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M14.5232 77.5598L12.4395 78.8296H8.95576L6.85577 77.5598L8.46739 76.0133L6.38367 74.4668L8.27204 72.9203L5.9767 71.2273L7.79995 69.6808L5.50461 68.2808L7.52321 66.8645L5.50461 65.2529L7.19763 63.7064L4.9674 62.2901L6.72553 60.825L4.43019 59.2785L6.57902 57.732L4.43019 56.1203L5.9767 54.7692L3.89298 53.2227L5.71623 51.7413L3.63252 50.1948L5.43949 48.4529L4.43019 46.7762V32.5157L6.31856 25.1902H12.4395" fill="#8889BE"/>
      <path d="M7.11592 77.5598L9.19963 78.8296H12.6671L14.7833 77.5598L13.1554 76.0133L15.2554 74.4668L13.3671 72.9203L15.6461 71.2273L13.8392 69.6808L16.1182 68.2808L14.0996 66.8645L16.1182 65.2529L14.4415 63.7064L16.6554 62.2901L14.9136 60.825L17.1926 59.2785L15.0438 57.732L17.1926 56.1203L15.6461 54.7692L17.7298 53.2227L15.9229 51.7413L18.0066 50.1948L16.1833 48.4529L17.1926 46.7762V32.5157L15.3206 25.1902H11.4136" fill="#8889BE"/>
      <path d="M20.9783 1.49595V10.0515L19.578 26.2245C19.3958 28.6717 18.617 31.037 17.3096 33.1137C16.5106 34.3016 15.4213 35.2656 14.145 35.9142C9.0621 38.4627 6.51363 34.416 6.51363 34.416C5.56185 33.2438 4.71825 31.9877 3.99317 30.6633C3.00763 28.94 2.22749 27.1072 1.66875 25.2023C0.0444489 19.6013 1.08064 12.2499 0.744577 3.82037C0.655037 3.10436 0.845324 2.38143 1.27577 1.80229C1.70621 1.22315 2.34358 0.832504 3.055 0.711804C3.055 0.711804 20.9783 0.557776 20.9783 1.49595Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M17.3098 33.6826L16.2316 49.6876C16.2316 49.6876 15.0694 59.2933 13.4311 75.0182C11.7928 90.7431 9.60841 84.2599 9.60841 84.2599L7.18597 72.4697L5.30962 51.3119L4.04939 31.2602" fill="#DEE1E9"/>
      <path d="M20.9783 2.0648V10.6204L19.578 26.7933C19.3958 29.2405 18.617 31.6059 17.3096 33.6826C16.5106 34.8704 15.4213 35.8344 14.145 36.4831C9.0621 39.0315 6.51363 34.9848 6.51363 34.9848C5.56185 33.8126 4.71825 32.5566 3.99317 31.2321C3.00763 29.5089 2.22749 27.676 1.66875 25.7711C0.0444489 20.1701 1.08064 12.8188 0.744577 4.38922C0.655037 3.67321 0.845324 2.95028 1.27577 2.37114C1.70621 1.792 2.34358 1.40135 3.055 1.28065C3.055 1.28065 20.9783 1.12662 20.9783 2.0648Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.2" d="M6.89175 29.3698C5.67352 30.588 7.32583 34.6488 8.44603 43.6104C8.95012 47.6992 9.13216 51.0318 9.24418 53.1601C9.52423 58.3691 9.24418 59.1812 9.63625 65.8885C9.76227 68.2409 9.9303 71.0274 10.4344 74.5281C10.7985 77.1045 10.9491 81.8223 11.1451 81.8363C11.3412 81.8503 11.8066 74.6121 11.9187 71.5595C11.9759 69.9862 11.9479 68.4109 11.8347 66.8406C11.6246 62.9899 11.7226 60.0774 11.8347 55.7786C12.0727 49.1274 12.1847 45.7948 13.0389 42.4342C13.893 39.0736 14.1031 35.755 14.9012 32.5344C15.0552 31.9183 15.4333 30.42 14.9012 29.7339C14.3691 29.0477 13.501 29.7339 12.5488 29.9579C9.44021 30.8961 7.91393 28.3616 6.89175 29.3698Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M17.3098 33.6826L16.2316 49.6876C16.2316 49.6876 15.0694 59.2933 13.4311 75.0182C11.7928 90.7431 9.60841 84.2599 9.60841 84.2599L7.18597 72.4697L5.30962 51.3119L4.04939 31.2602" fill="#DEE1E9"/>
      <path d="M20.9783 2.0648V10.6204L19.578 26.7933C19.3958 29.2405 18.617 31.6059 17.3096 33.6826C16.5106 34.8704 15.4213 35.8344 14.145 36.4831C9.0621 39.0315 6.51363 34.9848 6.51363 34.9848C5.56185 33.8126 4.71825 32.5566 3.99317 31.2321C3.00763 29.5089 2.22749 27.676 1.66875 25.7711C0.0444489 20.1701 1.08064 12.8188 0.744577 4.38922C0.655037 3.67321 0.845324 2.95028 1.27577 2.37114C1.70621 1.792 2.34358 1.40135 3.055 1.28065C3.055 1.28065 20.9783 1.12662 20.9783 2.0648Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.2" d="M6.89175 29.3698C5.67352 30.588 7.32583 34.6488 8.44603 43.6104C8.95012 47.6992 9.13216 51.0318 9.24418 53.1601C9.52423 58.3691 9.24418 59.1812 9.63625 65.8885C9.76227 68.2409 9.9303 71.0274 10.4344 74.5281C10.7985 77.1045 10.9491 81.8223 11.1451 81.8363C11.3412 81.8503 11.8066 74.6121 11.9187 71.5595C11.9759 69.9862 11.9479 68.4109 11.8347 66.8406C11.6246 62.9899 11.7226 60.0774 11.8347 55.7786C12.0727 49.1274 12.1847 45.7948 13.0389 42.4342C13.893 39.0736 14.1031 35.755 14.9012 32.5344C15.0552 31.9183 15.4333 30.42 14.9012 29.7339C14.3691 29.0477 13.501 29.7339 12.5488 29.9579C9.44021 30.8961 7.91393 28.3616 6.89175 29.3698Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="11" cy="85" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M1.92818 24.7013C2.49875 26.6199 3.27823 28.4699 4.2526 30.2183L5.51283 50.256L7.47319 71.4139L9.90964 83.204C9.90964 83.204 12.094 89.6872 13.7183 73.9623C15.3426 58.2375 16.5188 48.6457 16.5188 48.6457L17.569 32.6268C18.8766 30.5543 19.66 28.1946 19.8515 25.7515L21.2517 9.56456V1.009C21.2517 0.0848296 3.32843 0.224859 3.32843 0.224859C2.62488 0.351875 1.99654 0.74315 1.57218 1.31851C1.14783 1.89387 0.959576 2.60974 1.04602 3.31942C1.35407 11.763 0.303881 19.1563 1.92818 24.7013Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M1.92818 24.7013C2.49875 26.6199 3.27823 28.4699 4.2526 30.2183L5.51283 50.256L7.47319 71.4139L9.90964 83.204C9.90964 83.204 12.094 89.6872 13.7183 73.9623C15.3426 58.2375 16.5188 48.6457 16.5188 48.6457L17.569 32.6268C18.8766 30.5543 19.66 28.1946 19.8515 25.7515L21.2517 9.56456V1.009C21.2517 0.0848296 3.32843 0.224859 3.32843 0.224859C2.62488 0.351875 1.99654 0.74315 1.57218 1.31851C1.14783 1.89387 0.959576 2.60974 1.04602 3.31942C1.35407 11.763 0.303881 19.1563 1.92818 24.7013Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M12.1822 20.9537L12.7997 2.55786L9.29974 4.55786L5.79974 3.05786L2.79974 4.55786L1.62125e-05 2.52631L1.26025 22.578L3.13659 43.7358L5.55903 55.526C5.55903 55.526 7.74343 62.0092 9.38173 46.2843C11.02 30.5594 12.1822 20.9537 12.1822 20.9537Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M5.1948 24.4263C5.08278 22.2979 4.90075 18.9653 4.39665 14.8765C3.41533 7.02596 2.02567 2.93639 2.51515 1.22404L4.29974 2.55788L6.79974 1.55788L8.98949 2.30824L10.8518 1C11.3813 1.68277 11.0095 3.16976 10.8541 3.79141L10.8518 3.80051C10.0537 7.0211 9.84365 10.3397 8.98949 13.7003C8.14365 17.0282 8.02557 20.3286 7.79219 26.8514L7.78527 27.0448C7.67325 31.3435 7.57523 34.2561 7.78527 38.1068C7.89847 39.6771 7.92652 41.2523 7.86929 42.8256C7.75727 45.8782 7.2918 53.1164 7.09577 53.1024C6.89973 53.0884 6.74908 48.3707 6.38502 45.7942C5.88093 42.2935 5.71289 39.507 5.58687 37.1546C5.1948 30.4474 5.47485 29.6352 5.1948 24.4263Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M11.9288 9.16066C7.05142 11.4931 4.71085 10.8755 1.098 7.00001" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M17.5871 33.4114L16.5089 49.4163C16.5089 49.4163 15.3467 59.0221 13.7084 74.7469C12.0701 90.4718 9.88569 83.9886 9.88569 83.9886L7.46325 72.1985L5.58691 51.0406L4.32668 30.989" fill="#DEE1E9"/>
      <path d="M21.2556 10.3491V1.79355C21.2556 1.08963 11.1652 1.00058 6.1264 1L6.74081 1.69049L8.07954 4.72547L4.27045 6.24021L5.47803 8.55362L4.27045 11.0205L5.17755 13.6658H1.27045H1.02466C0.95463 18.1443 0.970934 22.1375 1.94603 25.4999C2.50477 27.4048 3.28491 29.2376 4.27045 30.9609C4.99553 32.2853 5.83913 33.5414 6.79091 34.7136C6.79091 34.7136 9.33938 38.7603 14.4223 36.2118C15.6985 35.5632 16.7879 34.5992 17.5869 33.4113C18.8943 31.3346 19.6731 28.9693 19.8553 26.5221L21.2556 10.3491Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2.69037 19.4671C12.1516 16.6077 14.0824 12.7954 12.207 2.5205" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M7.16903 29.0986C5.95081 30.3168 7.60311 34.3775 8.72331 43.3392C9.22741 47.4279 9.40944 50.7605 9.52146 52.8889C9.80151 58.0979 9.52146 58.91 9.91353 65.6172C10.0396 67.9697 10.2076 70.7562 10.7117 74.2568C11.0757 76.8333 11.2264 81.551 11.4224 81.565C11.6185 81.5791 12.0839 74.3408 12.1959 71.2883C12.2532 69.715 12.2251 68.1397 12.1119 66.5694C11.9019 62.7187 11.9999 59.8062 12.1119 55.5074C12.35 48.8562 12.462 45.5236 13.3162 42.1629C14.1703 38.8023 14.3803 35.4837 15.1785 32.2631C15.3325 31.647 15.7106 30.1488 15.1785 29.4626C14.6464 28.7765 13.7782 29.4626 12.8261 29.6867C9.7175 30.6248 8.19122 28.0904 7.16903 29.0986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M19.7506 19.2494L16.1331 49.1452C16.1331 49.1452 14.9709 58.751 13.3326 74.4758C11.6943 90.2007 9.5099 83.7175 9.5099 83.7175L7.08746 71.9274L5.21111 50.7695L1.75061 18.2494" fill="#DEE1E9"/>
      <path d="M20.8798 10.078V1.52244C20.8798 0.584265 2.95649 0.738293 2.95649 0.738293C2.24507 0.858994 1.6077 1.24964 1.17726 1.82878C0.746813 2.40792 0.556526 3.13085 0.646067 3.84686C0.982128 12.2764 -0.0540619 19.6277 1.57023 25.2288C2.12898 27.1337 2.90911 28.9665 3.89466 30.6898C4.35785 31.5358 4.8694 32.354 5.42663 33.1402L6.75061 32.7494L8.25061 22.7494C10.4 22.0428 11.6008 22.0646 13.7506 22.7494L15.2506 33.1402H17.2111C18.5185 31.0635 19.2973 28.6982 19.4795 26.251L20.8798 10.078Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M20.1264 20L16.5089 49.8958C16.5089 49.8958 15.3467 59.5016 13.7084 75.2264C12.0701 90.9513 9.88569 84.4681 9.88569 84.4681L7.46325 72.678L5.58691 51.5201L2.1264 19" fill="#DEE1E9"/>
      <path d="M21.2556 10.8286V2.27305C21.2556 1.33488 3.33228 1.4889 3.33228 1.4889C2.62086 1.6096 1.98349 2.00025 1.55305 2.57939C1.12261 3.15853 0.93232 3.88146 1.02186 4.59747C1.35792 13.027 0.321732 20.3784 1.94603 25.9794C2.50477 27.8843 3.28491 29.7171 4.27045 31.4404C4.73364 32.2865 5.2452 33.1046 5.80242 33.8908L7.1264 33.5L8.6264 23.5C10.7758 22.7934 11.9766 22.8152 14.1264 23.5L15.6264 33.8908H17.5869C18.8943 31.8141 19.6731 29.4488 19.8553 27.0016L21.2556 10.8286Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M19.7556 20L16.1381 49.8958C16.1381 49.8958 14.9759 59.5016 13.3376 75.2264C11.6993 90.9513 9.5149 84.4681 9.5149 84.4681L7.09246 72.678L5.21612 51.5201L1.75562 19" fill="#DEE1E9"/>
      <mask id="mask2031" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="34">
        <path d="M21.2556 10.1748V1.77876C21.2556 0.858079 3.33234 1.00924 3.33234 1.00924C2.62093 1.12769 1.98355 1.51105 1.55311 2.07939C1.12267 2.64774 0.932381 3.3572 1.02192 4.05986C1.35798 12.3323 0.321793 19.5466 1.94609 25.0432C2.50484 26.9126 3.28497 28.7113 4.27052 30.4024C4.7337 31.2327 5.24526 32.0356 5.80248 32.8072L7.12646 32.4236L8.62646 25.0432C10.7759 24.3498 11.9767 24.3712 14.1265 25.0432L15.6265 32.8072H17.5869C18.8943 30.7692 19.6732 28.4479 19.8554 26.0463L21.2556 10.1748Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2031)">
        <path d="M21.2556 10.3491V1.79355C21.2556 0.855384 3.33234 1.00941 3.33234 1.00941C2.62093 1.13011 1.98355 1.52075 1.55311 2.0999C1.12267 2.67904 0.932381 3.40197 1.02192 4.11798C1.35798 12.5475 0.321793 19.8989 1.94609 25.4999C2.50484 27.4048 3.28497 29.2376 4.27052 30.9609C4.7337 31.807 5.24526 32.6251 5.80248 33.4113L7.12646 33.0205L8.62646 24.6821C10.7759 23.9755 11.9767 23.9973 14.1265 24.6821L15.6265 33.4113H17.5869C18.8943 31.3346 19.6732 28.9693 19.8554 26.5221L21.2556 10.3491Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.46881 46H17.7868V23.1468C12.5858 21.4237 9.66982 21.4062 4.46881 23.1468V46Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M17.8427 33.0582L16.7645 49.0631C16.7645 49.0631 15.6022 58.6689 13.9639 74.3937C12.3256 90.1186 10.1412 83.6354 10.1412 83.6354L7.7188 71.8453L5.84246 50.6874L4.58223 30.6357" fill="#DEE1E9"/>
      <path d="M21.5111 1.44053V9.99609L20.1109 26.169C19.9287 28.6163 19.1498 30.9816 17.8424 33.0583C17.0434 34.2462 15.9541 35.2102 14.6779 35.8588C9.59493 38.4073 7.04647 34.3605 7.04647 34.3605C6.09469 33.1883 5.25109 31.9323 4.52601 30.6079C3.54046 28.8846 2.76033 27.0518 2.20158 25.1469C0.577286 19.5458 1.61348 12.1945 1.27741 3.76495C1.18787 3.04894 1.37816 2.32601 1.8086 1.74687C2.23905 1.16773 2.87642 0.777084 3.58784 0.656384C3.58784 0.656384 21.5111 0.502356 21.5111 1.44053Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.2" d="M7.42458 28.7454C6.20636 29.9636 7.85866 34.0244 8.97887 42.986C9.48296 47.0748 9.66499 50.4074 9.77702 52.5358C10.0571 57.7447 9.77701 58.5569 10.1691 65.2641C10.2951 67.6165 10.4631 70.403 10.9672 73.9037C11.3313 76.4801 11.4819 81.1979 11.678 81.2119C11.874 81.2259 12.3395 73.9877 12.4515 70.9351C12.5087 69.3618 12.4807 67.7865 12.3675 66.2163C12.1574 62.3656 12.2555 59.453 12.3675 55.1542C12.6055 48.503 12.7176 45.1704 13.5717 41.8098C14.4259 38.4492 14.6359 35.1306 15.434 31.91C15.5881 31.2939 15.9661 29.7956 15.434 29.1095C14.902 28.4234 14.0338 29.1095 13.0816 29.3335C9.97305 30.2717 8.44677 27.7372 7.42458 28.7454Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M6.93738 60.7518C10.2523 62.6612 12.1033 62.5741 15.3986 60.7518" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M17.2162 33.8909L16.1381 49.8959C16.1381 49.8959 14.9758 59.5016 13.3375 75.2265C11.6992 90.9514 9.51484 84.4682 9.51484 84.4682L7.0924 72.678L5.21606 51.5202L3.95583 31.4685" fill="#DEE1E9"/>
      <mask id="mask2231" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2231)">
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.4988 16C9.43671 16 8.1911 10.7018 8.1911 1.10345L10.1142 -0.758619L13.1911 -2C13.1911 7.59833 11.5609 16 10.4988 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.69831 15.307C5.43991 16.8555 7.1467 22.0174 8.30385 33.409C8.82457 38.6064 9.01261 42.8427 9.12832 45.5482C9.41761 52.1696 9.12832 53.2019 9.53332 61.7278C9.6635 64.7181 9.83708 68.2602 10.3578 72.7101C10.7339 75.9852 10.8895 81.9822 11.092 82C11.2945 82.0178 11.7753 72.8169 11.891 68.9366C11.9501 66.9367 11.9212 64.9343 11.8042 62.9382C11.5873 58.0434 11.6885 54.3411 11.8042 48.8767C12.0501 40.422 12.1658 36.1857 13.0482 31.9138C13.9305 27.642 14.1475 23.4235 14.9719 19.3297C15.131 18.5465 15.5216 16.6419 14.9719 15.7698C14.4223 14.8976 13.5255 15.7698 12.5419 16.0546C9.33082 17.2471 7.75421 14.0254 6.69831 15.307Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 10.2556 84.2009)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M17.2162 33.8909L16.1381 49.8959C16.1381 49.8959 14.9758 59.5016 13.3375 75.2265C11.6992 90.9514 9.51484 84.4682 9.51484 84.4682L7.0924 72.678L5.21606 51.5202L3.95583 31.4685" fill="#DEE1E9"/>
      <mask id="mask2331" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2331)">
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.4988 16C9.43671 16 8.1911 10.7018 8.1911 1.10345L10.1142 -0.758619L13.1911 -2C13.1911 7.59833 11.5609 16 10.4988 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.69831 15.307C5.43991 16.8555 7.1467 22.0174 8.30385 33.409C8.82457 38.6064 9.01261 42.8427 9.12832 45.5482C9.41761 52.1696 9.12832 53.2019 9.53332 61.7278C9.6635 64.7181 9.83708 68.2602 10.3578 72.7101C10.7339 75.9852 10.8895 81.9822 11.092 82C11.2945 82.0178 11.7753 72.8169 11.891 68.9366C11.9501 66.9367 11.9212 64.9343 11.8042 62.9382C11.5873 58.0434 11.6885 54.3411 11.8042 48.8767C12.0501 40.422 12.1658 36.1857 13.0482 31.9138C13.9305 27.642 14.1475 23.4235 14.9719 19.3297C15.131 18.5465 15.5216 16.6419 14.9719 15.7698C14.4223 14.8976 13.5255 15.7698 12.5419 16.0546C9.33082 17.2471 7.75421 14.0254 6.69831 15.307Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 10.2556 84.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2331)">
        <path d="M11.2556 80L10.4327 83.7391L7.39883 81.4037L9.1719 84.797L5.34671 84.9581L8.88611 86.4178L6.0594 89L9.70901 87.8431L9.20343 91.6382L11.2556 88.406L13.3077 91.6382L12.8021 87.8431L16.4517 89L13.625 86.4178L17.1644 84.9581L13.3392 84.797L15.1123 81.4037L12.0785 83.7391L11.2556 80Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2331" x="5.14668" y="79.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M17.2162 33.8909L16.1381 49.8959C16.1381 49.8959 14.9758 59.5016 13.3375 75.2265C11.6992 90.9514 9.51484 84.4682 9.51484 84.4682L7.0924 72.678L5.21606 51.5202L3.95583 31.4685" fill="#DEE1E9"/>
      <mask id="mask2331" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2331)">
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.4988 16C9.43671 16 8.1911 10.7018 8.1911 1.10345L10.1142 -0.758619L13.1911 -2C13.1911 7.59833 11.5609 16 10.4988 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.69831 15.307C5.43991 16.8555 7.1467 22.0174 8.30385 33.409C8.82457 38.6064 9.01261 42.8427 9.12832 45.5482C9.41761 52.1696 9.12832 53.2019 9.53332 61.7278C9.6635 64.7181 9.83708 68.2602 10.3578 72.7101C10.7339 75.9852 10.8895 81.9822 11.092 82C11.2945 82.0178 11.7753 72.8169 11.891 68.9366C11.9501 66.9367 11.9212 64.9343 11.8042 62.9382C11.5873 58.0434 11.6885 54.3411 11.8042 48.8767C12.0501 40.422 12.1658 36.1857 13.0482 31.9138C13.9305 27.642 14.1475 23.4235 14.9719 19.3297C15.131 18.5465 15.5216 16.6419 14.9719 15.7698C14.4223 14.8976 13.5255 15.7698 12.5419 16.0546C9.33082 17.2471 7.75421 14.0254 6.69831 15.307Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 10.2556 84.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2331)">
        <path d="M11.2556 80L10.4327 83.7391L7.39883 81.4037L9.1719 84.797L5.34671 84.9581L8.88611 86.4178L6.0594 89L9.70901 87.8431L9.20343 91.6382L11.2556 88.406L13.3077 91.6382L12.8021 87.8431L16.4517 89L13.625 86.4178L17.1644 84.9581L13.3392 84.797L15.1123 81.4037L12.0785 83.7391L11.2556 80Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2331" x="5.14668" y="79.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M17.2162 33.69L16.1381 49.6949C16.1381 49.6949 14.9758 59.3007 13.3375 75.0256C11.6992 90.7504 9.51484 84.2673 9.51484 84.2673L7.0924 72.4771L5.21606 51.3192L3.95583 31.2676" fill="#DEE1E9"/>
      <mask id="mask2531" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M21.2556 1.59263V10.1482L19.8553 26.3211C19.6731 28.7684 18.8943 31.1337 17.5869 33.2104C16.7879 34.3983 15.6985 35.3623 14.4223 36.0109C9.33938 38.5594 6.79091 34.5126 6.79091 34.5126C5.83913 33.3404 4.99553 32.0844 4.27045 30.76C3.28491 29.0367 2.50477 27.2039 1.94603 25.299C0.321732 19.6979 1.35792 12.3466 1.02186 3.91705C0.93232 3.20104 1.12261 2.47811 1.55305 1.89897C1.98349 1.31983 2.62086 0.929184 3.33228 0.808484C3.33228 0.808484 21.2556 0.654456 21.2556 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2531)">
        <path d="M21.2556 1.59263V10.1482L19.8553 26.3211C19.6731 28.7684 18.8943 31.1337 17.5869 33.2104C16.7879 34.3983 15.6985 35.3623 14.4223 36.0109C9.33938 38.5594 6.79091 34.5126 6.79091 34.5126C5.83913 33.3404 4.99553 32.0844 4.27045 30.76C3.28491 29.0367 2.50477 27.2039 1.94603 25.299C0.321732 19.6979 1.35792 12.3466 1.02186 3.91705C0.93232 3.20104 1.12261 2.47811 1.55305 1.89897C1.98349 1.31983 2.62086 0.929184 3.33228 0.808484C3.33228 0.808484 21.2556 0.654456 21.2556 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.4988 15.7991C9.43671 15.7991 8.1911 10.5008 8.1911 0.902521L10.1142 -0.959547L13.1911 -2.20093C13.1911 7.3974 11.5609 15.7991 10.4988 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.69831 15.1061C5.43991 16.6546 7.1467 21.8164 8.30385 33.2081C8.82457 38.4055 9.01261 42.6417 9.12832 45.3473C9.41761 51.9686 9.12832 53.001 9.53332 61.5269C9.6635 64.5172 9.83708 68.0593 10.3578 72.5092C10.7339 75.7842 10.8895 81.7812 11.092 81.799C11.2945 81.8168 11.7753 72.6159 11.891 68.7357C11.9501 66.7357 11.9212 64.7333 11.8042 62.7373C11.5873 57.8424 11.6885 54.1402 11.8042 48.6757C12.0501 40.221 12.1658 35.9848 13.0482 31.7129C13.9305 27.4411 14.1475 23.2226 14.9719 19.1287C15.131 18.3456 15.5216 16.441 14.9719 15.5688C14.4223 14.6967 13.5255 15.5688 12.5419 15.8536C9.33082 17.0462 7.75421 13.8245 6.69831 15.1061Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 10.2556 84)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2631" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="86">
        <path d="M17.5871 33.4115L16.5089 49.4164C16.5089 49.4164 15.3467 59.0221 13.7084 74.747C12.0701 90.4719 9.88569 83.9887 9.88569 83.9887L7.46325 72.1985L5.58691 51.0407L4.32668 30.989" fill="#DEE1E9"/>
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2631)">
        <path d="M17.5871 33.4115L16.5089 49.4164C16.5089 49.4164 15.3467 59.0221 13.7084 74.747C12.0701 90.4719 9.88569 83.9887 9.88569 83.9887L7.46325 72.1985L5.58691 51.0407L4.32668 30.989" fill="#DEE1E9"/>
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.2815 21.6762H23.2026C21.0406 27.046 20.6836 30.3063 20.9801 36.0446C20.9913 36.2609 20.7419 36.3955 20.5517 36.2689C19.5476 35.601 17.1894 33.7849 16.6178 31.1432C15.9253 27.9432 13.9221 27.1661 13.7095 27.092C13.6965 27.0875 13.6849 27.083 13.6726 27.0773L12.4896 26.5307C12.3451 26.4639 12.2865 26.3014 12.3587 26.1678L12.8877 25.1901C12.9324 25.1074 12.9283 25.0092 12.8769 24.9299L11.0305 22.0858C10.9135 21.9056 11.054 21.6762 11.2815 21.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2066 35.155C19.1456 34.3071 17.7376 32.8702 17.3352 31.0106C16.592 27.5761 14.416 26.6201 13.9831 26.4648L13.2095 26.1073L13.5421 25.4925C13.6987 25.203 13.6844 24.8596 13.5042 24.5821L12.0568 22.3524H22.1593C20.3826 27.0171 20.0066 30.2129 20.2066 35.155ZM13.9998 26.4725C13.9999 26.4726 13.9996 26.4724 13.9998 26.4725V26.4725ZM20.1243 36.8178C20.8531 37.3027 21.7488 36.7463 21.7109 36.0123C21.4181 30.3462 21.7673 27.18 23.8883 21.9121L24.2556 21H11.2814C10.4853 21 9.99347 21.8028 10.4031 22.4338L12.1255 25.0869L11.7043 25.8654C11.4513 26.333 11.6564 26.9017 12.1624 27.1355L13.3453 27.6822C13.3861 27.701 13.4219 27.7145 13.4514 27.7248C13.488 27.7376 15.2688 28.3577 15.9003 31.2758C16.5298 34.1849 19.0932 36.1319 20.1243 36.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask131" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="86">
        <path d="M17.5871 33.4115L16.5089 49.4164C16.5089 49.4164 15.3467 59.0221 13.7084 74.747C12.0701 90.4719 9.88569 83.9887 9.88569 83.9887L7.46325 72.1985L5.58691 51.0407L4.32668 30.989" fill="#DEE1E9"/>
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask131)">
        <path d="M17.5871 33.4115L16.5089 49.4164C16.5089 49.4164 15.3467 59.0221 13.7084 74.747C12.0701 90.4719 9.88569 83.9887 9.88569 83.9887L7.46325 72.1985L5.58691 51.0407L4.32668 30.989" fill="#DEE1E9"/>
        <path d="M21.2556 1.79355V10.3491L19.8553 26.5221C19.6731 28.9693 18.8943 31.3346 17.5869 33.4113C16.7879 34.5992 15.6985 35.5632 14.4223 36.2118C9.33938 38.7603 6.79091 34.7136 6.79091 34.7136C5.83913 33.5414 4.99553 32.2853 4.27045 30.9609C3.28491 29.2376 2.50477 27.4048 1.94603 25.4999C0.321732 19.8989 1.35792 12.5475 1.02186 4.11798C0.93232 3.40197 1.12261 2.67904 1.55305 2.0999C1.98349 1.52075 2.62086 1.13011 3.33228 1.00941C3.33228 1.00941 21.2556 0.855384 21.2556 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.3893 24.5H20.5361C19.0586 28.4705 18.8146 30.8811 19.0173 35.1241C19.0249 35.2841 18.8545 35.3836 18.7245 35.29C18.0383 34.7961 16.4268 33.4532 16.0361 31.5C15.5629 29.1339 14.1939 28.5593 14.0486 28.5045C14.0398 28.5012 14.0318 28.4978 14.0234 28.4936L13.215 28.0894C13.1162 28.04 13.0761 27.9199 13.1255 27.8211L13.487 27.0982C13.5176 27.037 13.5148 26.9645 13.4796 26.9058L12.2178 24.8029C12.1379 24.6696 12.2339 24.5 12.3893 24.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.4886 34.4664C17.7636 33.8394 16.8014 32.777 16.5264 31.4019C16.0185 28.8625 14.5314 28.1555 14.2356 28.0407L13.7069 27.7764L13.9342 27.3218C14.0412 27.1077 14.0315 26.8538 13.9083 26.6486L12.9192 25H19.8231C18.6089 28.4491 18.352 30.8121 18.4886 34.4664ZM14.247 28.0464C14.2471 28.0465 14.2469 28.0464 14.247 28.0464V28.0464ZM18.4324 35.6958C18.9305 36.0544 19.5426 35.643 19.5167 35.1003C19.3166 30.9106 19.5552 28.5695 21.0047 24.6744L21.2556 24H12.3893C11.8452 24 11.5091 24.5936 11.7891 25.0601L12.9661 27.0219L12.6783 27.5975C12.5054 27.9433 12.6456 28.3638 12.9914 28.5367L13.7998 28.9409C13.8276 28.9548 13.8521 28.9648 13.8723 28.9724C13.8973 28.9818 15.1142 29.4403 15.5458 31.5981C15.976 33.749 17.7278 35.1887 18.4324 35.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M17.2162 33.69L16.1381 49.6949C16.1381 49.6949 14.9758 59.3007 13.3375 75.0256C11.6992 90.7504 9.51484 84.2673 9.51484 84.2673L7.0924 72.4771L5.21606 51.3192L3.95583 31.2676" fill="#DEE1E9"/>
      <mask id="mask231" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M21.2556 1.59263V10.1482L19.8553 26.3211C19.6731 28.7684 18.8943 31.1337 17.5869 33.2104C16.7879 34.3983 15.6985 35.3623 14.4223 36.0109C9.33938 38.5594 6.79091 34.5126 6.79091 34.5126C5.83913 33.3404 4.99553 32.0844 4.27045 30.76C3.28491 29.0367 2.50477 27.2039 1.94603 25.299C0.321732 19.6979 1.35792 12.3466 1.02186 3.91705C0.93232 3.20104 1.12261 2.47811 1.55305 1.89897C1.98349 1.31983 2.62086 0.929184 3.33228 0.808484C3.33228 0.808484 21.2556 0.654456 21.2556 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask231)">
        <path d="M21.2556 1.59263V10.1482L19.8553 26.3211C19.6731 28.7684 18.8943 31.1337 17.5869 33.2104C16.7879 34.3983 15.6985 35.3623 14.4223 36.0109C9.33938 38.5594 6.79091 34.5126 6.79091 34.5126C5.83913 33.3404 4.99553 32.0844 4.27045 30.76C3.28491 29.0367 2.50477 27.2039 1.94603 25.299C0.321732 19.6979 1.35792 12.3466 1.02186 3.91705C0.93232 3.20104 1.12261 2.47811 1.55305 1.89897C1.98349 1.31983 2.62086 0.929184 3.33228 0.808484C3.33228 0.808484 21.2556 0.654456 21.2556 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.4988 15.7991C9.43671 15.7991 8.1911 10.5008 8.1911 0.902521L10.1142 -0.959547L13.1911 -2.20093C13.1911 7.3974 11.5609 15.7991 10.4988 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M6.69831 15.1061C5.43991 16.6546 7.1467 21.8164 8.30385 33.2081C8.82457 38.4055 9.01261 42.6417 9.12832 45.3473C9.41761 51.9686 9.12832 53.001 9.53332 61.5269C9.6635 64.5172 9.83708 68.0593 10.3578 72.5092C10.7339 75.7842 10.8895 81.7812 11.092 81.799C11.2945 81.8168 11.7753 72.6159 11.891 68.7357C11.9501 66.7357 11.9212 64.7333 11.8042 62.7373C11.5873 57.8424 11.6885 54.1402 11.8042 48.6757C12.0501 40.221 12.1658 35.9848 13.0482 31.7129C13.9305 27.4411 14.1475 23.2226 14.9719 19.1287C15.131 18.3456 15.5216 16.441 14.9719 15.5688C14.4223 14.6967 13.5255 15.5688 12.5419 15.8536C9.33082 17.0462 7.75421 13.8245 6.69831 15.1061Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M17.2162 33.69L16.1381 49.6949C16.1381 49.6949 14.9758 59.3007 13.3375 75.0256C11.6992 90.7504 9.51484 84.2673 9.51484 84.2673L7.0924 72.4771L5.21606 51.3192L3.95583 31.2676" fill="#DEE1E9"/>
      <mask id="mask331" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M21.2556 1.59263V10.1482L19.8553 26.3211C19.6731 28.7684 18.8943 31.1337 17.5869 33.2104C16.7879 34.3983 15.6985 35.3623 14.4223 36.0109C9.33938 38.5594 6.79091 34.5126 6.79091 34.5126C5.83913 33.3404 4.99553 32.0844 4.27045 30.76C3.28491 29.0367 2.50477 27.2039 1.94603 25.299C0.321732 19.6979 1.35792 12.3466 1.02186 3.91705C0.93232 3.20104 1.12261 2.47811 1.55305 1.89897C1.98349 1.31983 2.62086 0.929184 3.33228 0.808484C3.33228 0.808484 21.2556 0.654456 21.2556 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask331)">
        <path d="M21.2556 1.59263V10.1482L19.8553 26.3211C19.6731 28.7684 18.8943 31.1337 17.5869 33.2104C16.7879 34.3983 15.6985 35.3623 14.4223 36.0109C9.33938 38.5594 6.79091 34.5126 6.79091 34.5126C5.83913 33.3404 4.99553 32.0844 4.27045 30.76C3.28491 29.0367 2.50477 27.2039 1.94603 25.299C0.321732 19.6979 1.35792 12.3466 1.02186 3.91705C0.93232 3.20104 1.12261 2.47811 1.55305 1.89897C1.98349 1.31983 2.62086 0.929184 3.33228 0.808484C3.33228 0.808484 21.2556 0.654456 21.2556 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.4988 15.7991C9.43671 15.7991 8.1911 10.5008 8.1911 0.902521L10.1142 -0.959547L13.1911 -2.20093C13.1911 7.3974 11.5609 15.7991 10.4988 15.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M17.9607 33.8909L16.8825 49.8959C16.8825 49.8959 15.7203 59.5016 14.082 75.2265C12.4437 90.9514 10.2593 84.4682 10.2593 84.4682L7.83685 72.678L5.9605 51.5202L4.70027 31.4685" fill="#DEE1E9"/>
      <path d="M21.6292 2.27305V10.8286L20.2289 27.0016C20.0467 29.4488 19.2679 31.8141 17.9605 33.8908C17.1615 35.0787 16.0721 36.0427 14.7959 36.6913C9.71297 39.2398 7.16451 35.1931 7.16451 35.1931C6.21273 34.0209 5.36913 32.7648 4.64405 31.4404C3.6585 29.7171 2.87837 27.8843 2.31962 25.9794C0.695328 20.3784 1.73152 13.027 1.39546 4.59747C1.30592 3.88146 1.4962 3.15853 1.92665 2.57939C2.35709 2.00025 2.99446 1.6096 3.70588 1.4889C3.70588 1.4889 21.6292 1.33488 21.6292 2.27305Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8 1L6.13333 3.50086L4.5 2.00515V5.3192L1.93782 4.75129L3.33333 6.50189L1 8.50258L3.33333 10.5033L1.93782 12.2539L4.26667 11.5036L4.5 15L6.7499 13.503L7.53333 14.5046L9.2501 13.503L11.5 15L12.2 13.0041L14.0622 12.2539L13.6 9.50292L15 8.50258L12.6667 7.00206L14.0622 4.75129L12.2 4.5012L11.5 2.00515L8.46667 4.00103L8 1Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M17.9607 33.8909L16.8825 49.8959C16.8825 49.8959 15.7203 59.5016 14.082 75.2265C12.4437 90.9514 10.2593 84.4682 10.2593 84.4682L7.83685 72.678L5.9605 51.5202L4.70027 31.4685" fill="#DEE1E9"/>
      <path d="M21.6292 2.27305V10.8286L20.2289 27.0016C20.0467 29.4488 19.2679 31.8141 17.9605 33.8908C17.1615 35.0787 16.0721 36.0427 14.7959 36.6913C9.71297 39.2398 7.16451 35.1931 7.16451 35.1931C6.21273 34.0209 5.36913 32.7648 4.64405 31.4404C3.6585 29.7171 2.87837 27.8843 2.31962 25.9794C0.695328 20.3784 1.73152 13.027 1.39546 4.59747C1.30592 3.88146 1.4962 3.15853 1.92665 2.57939C2.35709 2.00025 2.99446 1.6096 3.70588 1.4889C3.70588 1.4889 21.6292 1.33488 21.6292 2.27305Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M7 1L5.4 3.14359L4 1.86156V4.70217L1.80385 4.21539L3 5.71591L1 7.43078L3 9.14566L1.80385 10.6462L3.8 10.0031L4 13L5.92849 11.7168L6.6 12.5754L8.07151 11.7168L10 13L10.6 11.2892L12.1962 10.6462L11.8 8.28822L13 7.43078L11 6.14462L12.1962 4.21539L10.6 4.00103L10 1.86156L7.4 3.57231L7 1Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M17.9607 33.8909L16.8825 49.8959C16.8825 49.8959 15.7203 59.5016 14.082 75.2265C12.4437 90.9514 10.2593 84.4682 10.2593 84.4682L7.83685 72.678L5.9605 51.5202L4.70027 31.4685" fill="#DEE1E9"/>
      <path d="M21.6292 2.27305V10.8286L20.2289 27.0016C20.0467 29.4488 19.2679 31.8141 17.9605 33.8908C17.1615 35.0787 16.0721 36.0427 14.7959 36.6913C9.71297 39.2398 7.16451 35.1931 7.16451 35.1931C6.21273 34.0209 5.36913 32.7648 4.64405 31.4404C3.6585 29.7171 2.87837 27.8843 2.31962 25.9794C0.695328 20.3784 1.73152 13.027 1.39546 4.59747C1.30592 3.88146 1.4962 3.15853 1.92665 2.57939C2.35709 2.00025 2.99446 1.6096 3.70588 1.4889C3.70588 1.4889 21.6292 1.33488 21.6292 2.27305Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M4.5 1L3.56667 2.25043L2.75 1.50258V3.1596L1.46891 2.87564L2.16667 3.75094L1 4.75129L2.16667 5.75163L1.46891 6.62693L2.63333 6.2518L2.75 8L3.87495 7.25148L4.26667 7.75232L5.12505 7.25148L6.25 8L6.6 7.00206L7.53109 6.62693L7.3 5.25146L8 4.75129L6.83333 4.00103L7.53109 2.87564L6.6 2.7506L6.25 1.50258L4.73333 2.50052L4.5 1Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M14.823 76.6565L12.7393 77.9263H9.25557L7.15557 76.6565L8.7672 75.11L6.68348 73.5635L8.57185 72.017L6.2765 70.324L8.09976 68.7775L5.80441 67.3775L7.82301 65.9612L5.80441 64.3496L7.49743 62.803L5.2672 61.3868L7.02534 59.9217L4.73 58.3751L6.87883 56.8286L4.73 55.217L6.2765 53.8659L4.19279 52.3193L6.01604 50.838L3.93232 49.2914L5.7393 47.5496L4.73 45.8728V31.6124L6.61836 24.2868H12.7393" fill="#8889BE"/>
      <path d="M14.823 76.6565L12.7393 77.9263H9.25557L7.15557 76.6565L8.7672 75.11L6.68348 73.5635L8.57185 72.017L6.2765 70.324L8.09976 68.7775L5.80441 67.3775L7.82301 65.9612L5.80441 64.3496L7.49743 62.803L5.2672 61.3868L7.02534 59.9217L4.73 58.3751L6.87883 56.8286L4.73 55.217L6.2765 53.8659L4.19279 52.3193L6.01604 50.838L3.93232 49.2914L5.7393 47.5496L4.73 45.8728V31.6124L6.61836 24.2868H12.7393" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M7.41572 76.6565L9.49944 77.9263H12.9669L15.0831 76.6565L13.4552 75.11L15.5552 73.5635L13.6669 72.017L15.9459 70.324L14.139 68.7775L16.418 67.3775L14.3994 65.9612L16.418 64.3496L14.7413 62.803L16.9552 61.3868L15.2134 59.9217L17.4924 58.3751L15.3436 56.8286L17.4924 55.217L15.9459 53.8659L18.0297 52.3193L16.2227 50.838L18.3064 49.2914L16.4831 47.5496L17.4924 45.8728V31.6124L15.6204 24.2868H11.7134" fill="#8889BE"/>
      <path d="M7.41572 76.6565L9.49944 77.9263H12.9669L15.0831 76.6565L13.4552 75.11L15.5552 73.5635L13.6669 72.017L15.9459 70.324L14.139 68.7775L16.418 67.3775L14.3994 65.9612L16.418 64.3496L14.7413 62.803L16.9552 61.3868L15.2134 59.9217L17.4924 58.3751L15.3436 56.8286L17.4924 55.217L15.9459 53.8659L18.0297 52.3193L16.2227 50.838L18.3064 49.2914L16.4831 47.5496L17.4924 45.8728V31.6124L15.6204 24.2868H11.7134" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask531" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="34">
        <path d="M21.2471 10.5979V2.20186C21.2471 1.28117 3.32386 1.43233 3.32386 1.43233C2.61244 1.55078 1.97507 1.93414 1.54463 2.50249C1.11418 3.07083 0.923897 3.78029 1.01344 4.48296C1.3495 12.7554 0.313309 19.9697 1.9376 25.4663C2.49635 27.3357 3.27649 29.1344 4.26203 30.8255C4.72522 31.6558 5.23677 32.4587 5.794 33.2303L7.11798 32.8467L8.61798 25.4663C10.7674 24.7729 11.9682 24.7943 14.118 25.4663L15.618 33.2303H17.5785C18.8859 31.1923 19.6647 28.871 19.8469 26.4694L21.2471 10.5979Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask531)">
        <path d="M21.2471 10.7722V2.21665C21.2471 1.27848 3.32386 1.43251 3.32386 1.43251C2.61244 1.55321 1.97507 1.94385 1.54463 2.52299C1.11418 3.10213 0.923897 3.82507 1.01344 4.54107C1.3495 12.9706 0.313309 20.322 1.9376 25.923C2.49635 27.8279 3.27649 29.6607 4.26203 31.384C4.72522 32.2301 5.23677 33.0482 5.794 33.8344L7.11798 33.4436L8.61798 25.1052C10.7674 24.3986 11.9682 24.4204 14.118 25.1052L15.618 33.8344H17.5785C18.8859 31.7577 19.6647 29.3924 19.8469 26.9452L21.2471 10.7722Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.46033 46.4231H17.7784V23.5699C12.5773 21.8468 9.66134 21.8293 4.46033 23.5699V46.4231Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M14.4151 75.948L12.3314 77.2178H8.84767L6.74767 75.948L8.3593 74.4015L6.27558 72.855L8.16395 71.3085L5.86861 69.6155L7.69186 68.069L5.39651 66.669L7.41511 65.2527L5.39651 63.6411L7.08953 62.0945L4.85931 60.6783L6.61744 59.2132L4.3221 57.6667L6.47093 56.1201L4.3221 54.5085L5.86861 53.1574L3.78489 51.6109L5.60814 50.1295L3.52442 48.5829L5.3314 46.8411L4.3221 45.1644V30.9039L6.21047 23.5784H12.3314" fill="#8889BE"/>
      <path d="M14.4151 75.948L12.3314 77.2178H8.84767L6.74767 75.948L8.3593 74.4015L6.27558 72.855L8.16395 71.3085L5.86861 69.6155L7.69186 68.069L5.39651 66.669L7.41511 65.2527L5.39651 63.6411L7.08953 62.0945L4.85931 60.6783L6.61744 59.2132L4.3221 57.6667L6.47093 56.1201L4.3221 54.5085L5.86861 53.1574L3.78489 51.6109L5.60814 50.1295L3.52442 48.5829L5.3314 46.8411L4.3221 45.1644V30.9039L6.21047 23.5784H12.3314" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M7.00782 75.948L9.09154 77.2178H12.559L14.6752 75.948L13.0473 74.4015L15.1473 72.855L13.259 71.3085L15.538 69.6155L13.7311 68.069L16.0101 66.669L13.9915 65.2527L16.0101 63.6411L14.3334 62.0945L16.5473 60.6783L14.8055 59.2132L17.0845 57.6667L14.9357 56.1201L17.0845 54.5085L15.538 53.1574L17.6218 51.6109L15.8148 50.1295L17.8985 48.5829L16.0752 46.8411L17.0845 45.1644V30.9039L15.2125 23.5784H11.3055" fill="#8889BE"/>
      <path d="M7.00782 75.948L9.09154 77.2178H12.559L14.6752 75.948L13.0473 74.4015L15.1473 72.855L13.259 71.3085L15.538 69.6155L13.7311 68.069L16.0101 66.669L13.9915 65.2527L16.0101 63.6411L14.3334 62.0945L16.5473 60.6783L14.8055 59.2132L17.0845 57.6667L14.9357 56.1201L17.0845 54.5085L15.538 53.1574L17.6218 51.6109L15.8148 50.1295L17.8985 48.5829L16.0752 46.8411L17.0845 45.1644V30.9039L15.2125 23.5783H11.3055" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask531" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="34">
        <path d="M20.8392 9.8892V1.49312C20.8392 0.572435 2.91596 0.723592 2.91596 0.723592C2.20454 0.842042 1.56717 1.2254 1.13673 1.79375C0.706285 2.36209 0.515999 3.07155 0.605539 3.77422C0.941601 12.0466 -0.0945892 19.2609 1.52971 24.7576C2.08845 26.627 2.86859 28.4256 3.85413 30.1168C4.31732 30.9471 4.82888 31.75 5.3861 32.5215L6.71008 32.138L8.21008 24.7576C10.3595 24.0641 11.5603 24.0855 13.7101 24.7576L15.2101 32.5215H17.1706C18.478 30.4835 19.2568 28.1623 19.439 25.7607L20.8392 9.8892Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask531)">
        <path d="M20.8392 10.0635V1.50791C20.8392 0.569739 2.91596 0.723767 2.91596 0.723767C2.20454 0.844467 1.56717 1.23511 1.13673 1.81425C0.706285 2.39339 0.515999 3.11633 0.605539 3.83233C0.941601 12.2619 -0.0945892 19.6132 1.52971 25.2142C2.08845 27.1192 2.86859 28.952 3.85413 30.6752C4.31732 31.5213 4.82888 32.3395 5.3861 33.1257L6.71008 32.7349L8.21008 24.3965C10.3595 23.6899 11.5603 23.7117 13.7101 24.3965L15.2101 33.1257H17.1706C18.478 31.049 19.2568 28.6836 19.439 26.2364L20.8392 10.0635Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M30.2556 0.259766H9.25555V5.25977H30.2556V0.259766Z" fill="#838AA2"/>
      <path d="M19.1264 22.6777L15.5089 52.5735C15.5089 52.5735 14.3467 62.1793 12.7084 77.9042C11.0701 93.629 8.88569 87.1459 8.88569 87.1459L6.46325 75.3557L4.58691 54.1978L1.1264 21.6777" fill="#DEE1E9"/>
      <path d="M20.2556 13.5063V4.95078C20.2556 4.01261 2.33228 4.16664 2.33228 4.16664C1.62086 4.28734 0.983492 4.67798 0.553049 5.25712C0.122606 5.83626 -0.0676804 6.5592 0.0218601 7.27521C0.357922 15.7047 -0.678268 23.0561 0.946028 28.6571C1.50477 30.562 2.28491 32.3949 3.27045 34.1181C3.73364 34.9642 4.2452 35.7823 4.80242 36.5686L6.1264 36.1777L7.6264 26.1777C9.7758 25.4711 10.9766 25.4929 13.1264 26.1777L14.6264 36.5686H16.5869C17.8943 34.4918 18.6731 32.1265 18.8553 29.6793L20.2556 13.5063Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M17.8038 33.4962L16.7256 49.5011C16.7256 49.5011 15.5634 59.1068 13.9251 74.8317C12.2868 90.5566 10.1024 84.0734 10.1024 84.0734L7.67992 72.2833L5.80358 51.1254L4.54335 31.0737" fill="#DEE1E9"/>
      <path d="M21.4722 1.87852V10.4341L20.072 26.607C19.8898 29.0542 19.111 31.4196 17.8036 33.4963C17.0045 34.6842 15.9152 35.6482 14.639 36.2968C9.55605 38.8453 7.00759 34.7985 7.00759 34.7985C6.05581 33.6263 5.21221 32.3703 4.48713 31.0458C3.50158 29.3226 2.72145 27.4898 2.1627 25.5848C0.538406 19.9838 1.5746 12.6325 1.23853 4.20294C1.14899 3.48693 1.33928 2.764 1.76972 2.18486C2.20017 1.60572 2.83754 1.21507 3.54896 1.09437C3.54896 1.09437 21.4722 0.940345 21.4722 1.87852Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.2" d="M7.3857 29.1834C6.16748 30.4016 7.81978 34.4624 8.93999 43.424C9.44408 47.5127 9.62612 50.8454 9.73814 52.9737C10.0182 58.1827 9.73813 58.9948 10.1302 65.7021C10.2562 68.0545 10.4243 70.841 10.9284 74.3417C11.2924 76.9181 11.4431 81.6359 11.6391 81.6499C11.8351 81.6639 12.3006 74.4257 12.4126 71.3731C12.4699 69.7998 12.4418 68.2245 12.3286 66.6542C12.1186 62.8035 12.2166 59.891 12.3286 55.5922C12.5667 48.941 12.6787 45.6084 13.5328 42.2478C14.387 38.8872 14.597 35.5686 15.3952 32.348C15.5492 31.7319 15.9273 30.2336 15.3952 29.5475C14.8631 28.8613 13.9949 29.5475 13.0427 29.7715C9.93417 30.7097 8.40789 28.1752 7.3857 29.1834Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M17.8038 33.4962L16.7256 49.5011C16.7256 49.5011 15.5634 59.1068 13.9251 74.8317C12.2868 90.5566 10.1024 84.0734 10.1024 84.0734L7.67992 72.2833L5.80358 51.1254L4.54335 31.0737" fill="#DEE1E9"/>
      <path d="M21.4722 1.87852V10.4341L20.072 26.607C19.8898 29.0542 19.111 31.4196 17.8036 33.4963C17.0045 34.6842 15.9152 35.6482 14.639 36.2968C9.55605 38.8453 7.00759 34.7985 7.00759 34.7985C6.05581 33.6263 5.21221 32.3703 4.48713 31.0458C3.50158 29.3226 2.72145 27.4898 2.1627 25.5848C0.538406 19.9838 1.5746 12.6325 1.23853 4.20294C1.14899 3.48693 1.33928 2.764 1.76972 2.18486C2.20017 1.60572 2.83754 1.21507 3.54896 1.09437C3.54896 1.09437 21.4722 0.940345 21.4722 1.87852Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.4" d="M7.3857 29.1834C6.16748 30.4016 7.81978 34.4624 8.93999 43.424C9.44408 47.5127 9.62612 50.8454 9.73814 52.9737C10.0182 58.1827 9.73813 58.9948 10.1302 65.7021C10.2562 68.0545 10.4243 70.841 10.9284 74.3417C11.2924 76.9181 11.4431 81.6359 11.6391 81.6499C11.8351 81.6639 12.3006 74.4257 12.4126 71.3731C12.4699 69.7998 12.4418 68.2245 12.3286 66.6542C12.1186 62.8035 12.2166 59.891 12.3286 55.5922C12.5667 48.941 12.6787 45.6084 13.5328 42.2478C14.387 38.8872 14.597 35.5686 15.3952 32.348C15.5492 31.7319 15.9273 30.2336 15.3952 29.5475C14.8631 28.8613 13.9949 29.5475 13.0427 29.7715C9.93417 30.7097 8.40789 28.1752 7.3857 29.1834Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M17.8038 33.4962L16.7256 49.5011C16.7256 49.5011 15.5634 59.1068 13.9251 74.8317C12.2868 90.5566 10.1024 84.0734 10.1024 84.0734L7.67992 72.2833L5.80358 51.1254L4.54335 31.0737" fill="#DEE1E9"/>
      <path d="M21.4722 1.87852V10.4341L20.072 26.607C19.8898 29.0542 19.111 31.4196 17.8036 33.4963C17.0045 34.6842 15.9152 35.6482 14.639 36.2968C9.55605 38.8453 7.00759 34.7985 7.00759 34.7985C6.05581 33.6263 5.21221 32.3703 4.48713 31.0458C3.50158 29.3226 2.72145 27.4898 2.1627 25.5848C0.538406 19.9838 1.5746 12.6325 1.23853 4.20294C1.14899 3.48693 1.33928 2.764 1.76972 2.18486C2.20017 1.60572 2.83754 1.21507 3.54896 1.09437C3.54896 1.09437 21.4722 0.940345 21.4722 1.87852Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <mask id="mask531" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="19" width="17" height="35">
        <path d="M6.36607 53.9736L14.4429 53.9735L19.1589 19.2767H3.53003L6.36607 53.9736Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask531)">
        <path opacity="0.2" d="M7.3857 29.1834C6.16748 30.4016 7.81978 34.4624 8.93999 43.424C9.44408 47.5127 9.62612 50.8454 9.73814 52.9737C10.0182 58.1827 9.73813 58.9948 10.1302 65.7021C10.2562 68.0545 10.4243 70.841 10.9284 74.3417C11.2924 76.9181 11.4431 81.6359 11.6391 81.6499C11.8351 81.6639 12.3006 74.4257 12.4126 71.3731C12.4699 69.7998 12.4418 68.2245 12.3286 66.6542C12.1186 62.8035 12.2166 59.891 12.3286 55.5922C12.5667 48.941 12.6787 45.6084 13.5328 42.2478C14.387 38.8872 14.597 35.5686 15.3952 32.348C15.5492 31.7319 15.9273 30.2336 15.3952 29.5475C14.8631 28.8613 13.9949 29.5475 13.0427 29.7715C9.93417 30.7097 8.40789 28.1752 7.3857 29.1834Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5031" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="53" width="10" height="30">
        <path d="M9.76483 80.1701L10.4647 82.2051H11.8645L12.8526 80.9051L17.3814 53.9738H8.612L8.98254 65.9246L9.43546 76.4956L9.76483 80.1701Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5031)">
        <path opacity="0.4" d="M7.3857 29.1834C6.16748 30.4016 7.81978 34.4624 8.93999 43.424C9.44408 47.5127 9.62612 50.8454 9.73814 52.9737C10.0182 58.1827 9.73813 58.9948 10.1302 65.7021C10.2562 68.0545 10.4243 70.841 10.9284 74.3417C11.2924 76.9181 11.4431 81.6359 11.6391 81.6499C11.8351 81.6639 12.3006 74.4257 12.4126 71.3731C12.4699 69.7998 12.4418 68.2245 12.3286 66.6542C12.1186 62.8035 12.2166 59.891 12.3286 55.5922C12.5667 48.941 12.6787 45.6084 13.5328 42.2478C14.387 38.8872 14.597 35.5686 15.3952 32.348C15.5492 31.7319 15.9273 30.2336 15.3952 29.5475C14.8631 28.8613 13.9949 29.5475 13.0427 29.7715C9.93417 30.7097 8.40789 28.1752 7.3857 29.1834Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M1.92818 24.7013C2.49875 26.6199 3.27823 28.4699 4.2526 30.2183L5.51283 50.256L7.47319 71.4139L9.90964 83.204C9.90964 83.204 12.094 89.6872 13.7183 73.9623C15.3426 58.2375 16.5188 48.6457 16.5188 48.6457L17.569 32.6268C18.8766 30.5543 19.66 28.1946 19.8515 25.7515L21.2517 9.56456V1.009C21.2517 0.0848296 3.32843 0.224859 3.32843 0.224859C2.62488 0.351875 1.99654 0.74315 1.57218 1.31851C1.14783 1.89387 0.959576 2.60974 1.04602 3.31942C1.35407 11.763 0.303881 19.1563 1.92818 24.7013Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M17.3098 33.7698L16.2316 49.7747C16.2316 49.7747 15.0694 59.3805 13.4311 75.1053C11.7928 90.8302 9.60841 84.347 9.60841 84.347L7.18597 72.5569L5.30962 51.399L4.04939 31.3474" fill="#DEE1E9"/>
      <path d="M20.9783 2.15195V10.7075L19.578 26.8805C19.3958 29.3277 18.617 31.693 17.3096 33.7697C16.5106 34.9576 15.4213 35.9216 14.145 36.5702C9.0621 39.1187 6.51363 35.072 6.51363 35.072C5.56185 33.8998 4.71825 32.6437 3.99317 31.3193C3.00763 29.596 2.22749 27.7632 1.66875 25.8583C0.0444489 20.2573 1.08064 12.9059 0.744577 4.47638C0.655037 3.76037 0.845324 3.03743 1.27577 2.45829C1.70621 1.87915 2.34358 1.48851 3.055 1.36781C3.055 1.36781 20.9783 1.21378 20.9783 2.15195Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth71",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
