<template>
  <div class="form-group">
    <label class="form-label" :data-content="$t(field.title)">
      <span class="">{{ $t(field.title) }}</span>
      <span v-if="required"> *</span>
    </label>
    <table class="table data-table sm" v-if="object.materials && object.materials.length > 0">
      <thead>
      <tr>
        <th>{{ $t('title') }}</th>
        <th>{{ $t('amount') }}</th>
        <th>{{ $t('cost') }}</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(m, mind) in object.materials" :key="`material-row-${mind}`">
        <td>{{ m.name }}</td>
        <td>
          <input type="text" class="form-control sm"
                 @keyup="amountChanged(mind, $event)"
                 :value="m.pivot.amount"/>
        </td>
        <td>{{ formatCurrency(m.price * m.pivot.amount) }}</td>
        <td>
          <a class="pointer" @click="deleteMaterial(mind)">
            <DeleteSvg/>
          </a>
        </td>
      </tr>
      <tr class="font-weight-bold">
        <td colspan="2">{{ $t('total') }}</td>
        <td colspan="2">{{ formatCurrency(totalCost) }}</td>
      </tr>
      </tbody>
    </table>
    <Autocomplete :placeholder="$t('add_material')"
                  model="material"
                  class="autocomplete-operation-material w-100"
                  v-model="autocompleteMaterial"/>
  </div>
</template>

<script>
// import moment from "moment"
import DeleteSvg from '@/assets/svg-vue/general/delete.svg'
import Autocomplete from "@/components/parts/general/Autocomplete"
import {formatCurrency} from "@/extensions/filters/filters"

export default {
  name: "WarehouseDatatableFormKitMaterials",
  components: {
    Autocomplete,
    DeleteSvg
  },
  data() {
    return {
      autocompleteMaterial: null
    }
  },
  props: {
    object: {
      type: Object
    },
    field: {},
    required: {},
    value: {},
  },
  methods: {
    formatCurrency,
    deleteMaterial(index) {
      this.object.materials.splice(index, 1)
    },
    emit(materials) {
      this.$emit('input', materials)
    },
    amountChanged(index, e) {
      let val = e.target.value
      let materials = this.object.materials ? JSON.parse(JSON.stringify(this.object.materials)) : []
      materials[index].pivot.amount = val
      this.emit(materials)
    }
  },
  mounted() {
  },
  computed: {
    totalCost() {
      return this.object.materials ? this.object.materials.reduce((acc, curr) => acc + (curr.pivot.amount * curr.price), 0) : 0
    }
  },
  watch: {
    autocompleteMaterial(val) {
      if(val) {
        val.name = val.title
        val.material_id = val.id
        val.pivot = { amount: 1 }
        let materials = this.object.materials ? JSON.parse(JSON.stringify(this.object.materials)) : []
        materials.push(val)
        this.emit(materials)
      }
    },
  }
}
</script>
