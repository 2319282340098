export default {
    accounting: "Accounting",
    action_log: "Action_log",
    calendar: "Calendar",
    control: "Control",
    doctors: "Doctors",
    insurance: "Insurance",
    patients: "Patients",
    reports: "Reports",
    services: "Services",
    settings: "Settings",
    users: "Users",
    warehouse: "Warehouse",
}
