<template>
  <div v-if="object.record">
    <router-link class="font-weight-bold"
                 v-if="!isPatient"
                 :to="{ name: 'PatientVisitPage', params: { visitid: object.record.id, id: object.record.client_id, page: 'description' } }">
      <div>#{{ object.record.id}} {{ $t('from') }} {{ formatDate(object.record.start, 'DD.MM.YYYY, HH:mm') }}</div>
    </router-link>
    <div v-else>
      #{{ object.record.id}} {{ $t('from') }} {{ formatDate(object.record.start, 'DD.MM.YYYY, HH:mm') }}
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/extensions/filters/filters"

export default {
  name: "PatientsDatatableTDPaymentVisit",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    isPatient() {
      return this.$store.getters.isPatient
    }
  },
  methods: {
    formatDate
  }
}
</script>
