<template>
  <div>
    <div class="radio-inline">
      <RadioGroup :options="channels"
                  :showlabel="true"
                  :label="$t('notification_channel')"
                  v-model="object.notification_channel_id"/>
    </div>
    <div class="radio-inline">
      <RadioGroup :options="timeOptions"
                  :showlabel="true"
                  :label="$t('send_time')"
                  v-model="hasTime"/>
    </div>
    <div v-if="hasTime === '1'">
      <b-row class="align-items-center">
        <b-col>
          <DateGroup :label="$t('send_date')"
                     v-model="date"/>
        </b-col>
        <b-col>
          <TimeGroup :label="$t('time')"
                     v-model="time"/>
        </b-col>
      </b-row>
    </div>
    <AutosizeTextArea
        :label="$t('text')"
        :show-errors="true"
        validation-id="text"
        :max-length="850"
        :required="true"
        v-model="object.text"/>
    <TagsList type="mailing"
              class="mt-0">
      {{ $t('tags_for_marketing_mailing') }}
    </TagsList>
  </div>
</template>

<script>

import RadioGroup from '@/components/form/RadioGroup'
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import DateGroup from "@/components/form/DateGroup"
import TimeGroup from "@/components/form/TimeGroup"
import TagsList from "@/components/parts/crm/TagsList.vue"

export default {
  name: "MarketingMailingAdditionalFormComponent",
  components: {
    TagsList,
    TimeGroup,
    DateGroup,
    AutosizeTextArea,
    RadioGroup
  },
  props: {
    object: {
      type: Object
    },
    options: {},
  },
  computed: {
    datetime() {
      return this.date+' '+this.time+':00'
    },
  },
  data() {
    return {
      channels: [
        { id: '1', title: 'sms_message' },
        // { id: '3', title: 'push_notification' },
        { id: '4', title: 'whatsapp' }
      ],
      timeOptions: [
        { id: '0', title: 'immediately_after_creating' },
        { id: '1', title: 'given_time' },
      ],
      hasTime: this.object.send_time ? '1' : '0',
      date: this.object.send_time ? this.object.send_time.substr(0, 10) : null,
      time: this.object.send_time ? this.object.send_time.substr(11, 5) : null,
    }
  },
  mounted() {
  },
  methods: {

  },
  watch: {
    date() {
      this.object.send_time = this.datetime
    },
    time() {
      this.object.send_time = this.datetime
    }
  }
}
</script>

<style scoped>

</style>
