<template>
  <div>
    <div class="mb-3" v-if="ppoData.vendor === 'kkm'">
      <div class="mt-4">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline"
                :disabled="loading"
                @click="xReport">
          {{ $t('xreport') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import { KKMService } from "@/services/kkm.service"

export default {
  name: "CashdeskKKMReports",
  components: {
  },
  head() {
    return {
      title: this.$t('reports')
    }
  },
  computed: {
    ...mapState({
      ppoData: state => state.ppo.ppoData,
      counter: state => state.datatable.dataTableCounter,
    }),
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async xReport() {
      this.loading = true
      const res = await KKMService.printXReport()
      this.loading = false
      if(res.error) {
        this.$noty.error(res.error)
      }
    },
  },
}
</script>
