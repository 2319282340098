import { chairAdapter } from '@/adapters/staff/chair'

export default {
    primaryKey: "id",
    model: 'chair',
    order: 'chair_name',
    sort: 'asc',
    adapter: chairAdapter,
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        edit: "create_chairs",
        delete: "create_chairs",
    },
    actionscomponent: 'StaffDatatableChairActions',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'chair_name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'doctors',
            title: 'doctors',
            table: true,
            sortable: false,
            form: {
                type: "text",
                component: "StaffDatatableFormChairDoctors"
            },
            tdcomponent: 'StaffDatatableTDChairDoctors',
        },
        {
            id: 'warehouse_id',
            title: 'warehouse',
            table: true,
            table_show: 'warehouse.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
            }
        },
    ]

}
