<template>
    <SelectGroup :options="finalPayMethods"
                 variant="white"
                 :title="null"
                 :label="$t('payment_type')"
                 :required="true"
                 :showlabel="true"
                 v-model="object.forma"/>
</template>

<script>
import SelectGroup from "@/components/form/SelectGroup"
import {mapState} from "vuex"

export default {
  name: "AccountingDatatableFormPaymentType",
  components: {
    SelectGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  mounted() {
  },
  computed: {
    ...mapState({
      pay_methods: state => state.auth.pay_methods,
    }),
    finalPayMethods() {
      return this.pay_methods.filter(x => x.id != 100)
    }
  },
}
</script>
