<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M21.551 79.6444L19.4673 80.9142H15.9836L13.8836 79.6444L15.4952 78.0979L13.4115 76.5514L15.2999 75.0049L13.0045 73.3119L14.8278 71.7654L12.5324 70.3654L14.551 68.9491L12.5324 67.3375L14.2255 65.791L11.9952 64.3747L13.7534 62.9096L11.458 61.3631L13.6069 59.8166L11.458 58.2049L13.0045 56.8538L10.9208 55.3073L12.7441 53.8259L10.6603 52.2794L12.4673 50.5375L11.458 48.8608V34.6003L13.3464 27.2748H19.4673" fill="#8889BE"/>
      <path d="M14.1437 79.6444L16.2275 80.9142H19.6949L21.8112 79.6444L20.1833 78.0979L22.2833 76.5514L20.3949 75.0049L22.674 73.3119L20.867 71.7654L23.1461 70.3654L21.1275 68.9491L23.1461 67.3375L21.4693 65.791L23.6833 64.3747L21.9414 62.9096L24.2205 61.3631L22.0716 59.8166L24.2205 58.2049L22.674 56.8538L24.7577 55.3073L22.9507 53.8259L25.0344 52.2794L23.2112 50.5375L24.2205 48.8608V34.6003L22.3484 27.2748H18.4414" fill="#8889BE"/>
      <path d="M34.4963 14.7751C34.4963 14.7751 33.7962 32.8104 29.8755 35.1348C25.9548 37.4592 21.0819 40.2177 17.3852 39.5176C13.6047 38.443 10.0706 36.6403 6.98131 34.2106C6.51923 33.5105 1.19825 17.5616 1.19825 17.5616C1.19825 17.5616 10.2159 -1.69195 17.8473 0.884518C25.4787 3.46099 22.4681 4.14711 34.4963 14.7751Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <!--      <path d="M6.98131 34.8347L7.27536 46.9749C8.33956 57.9809 8.95567 64.7581 9.40375 69.743C9.94985 75.8341 10.3699 80.665 13.2264 86.2801C14.9768 89.7387 18.7435 95.6198 21.4319 94.9476C22.3981 94.7096 23.2243 93.5474 24.2325 89.6267C25.4098 84.5826 25.8391 79.3928 25.5067 74.2239C25.3136 67.6354 25.5334 61.0413 26.1648 54.4802C26.6193 50.3511 27.3538 46.2576 28.3632 42.228L29.8755 35.7588" fill="#DEE1E9"/>-->
      <!--      <path d="M34.4963 15.3991C34.4963 15.3991 33.7962 33.4344 29.8755 35.7588C25.9548 38.0832 21.0819 40.8417 17.3852 40.1416C13.6047 39.067 10.0706 37.2643 6.98131 34.8346C6.51923 34.1345 1.19825 18.1856 1.19825 18.1856C1.19825 18.1856 10.2159 -1.06793 17.8473 1.50854C25.4787 4.08501 22.4681 4.77114 34.4963 15.3991Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>-->
      <!--      <path opacity="0.2" d="M12.3164 31.0539C10.4541 32.4542 12.8625 37.047 14.4028 47.2409C14.9311 50.8431 15.2769 54.4697 15.439 58.1069C15.761 64.03 15.439 64.9541 15.789 72.5575C15.9303 75.8432 16.2763 79.1168 16.8252 82.3593C17.3293 85.2999 19.9359 91.6196 20.216 91.6336C20.7761 91.6336 19.4017 83.4746 19.1356 79.0127C19.2568 77.2318 19.2568 75.4447 19.1356 73.6637C18.9481 69.4617 19.037 65.2518 19.4017 61.0614C19.8778 53.514 20.1298 49.7334 21.4881 45.9107C22.8463 42.088 23.2104 38.3633 24.4846 34.7086C24.7367 34.0085 25.3388 32.3282 24.5966 31.6C23.8545 30.8719 22.5663 31.516 21.026 31.8241C16.1391 32.7763 13.8987 29.9757 12.3164 31.0539Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

      <path d="M6.90831 34.7571L7.20236 46.8973C8.26656 57.9033 8.88267 64.6805 9.33075 69.6655C9.87685 75.7566 10.2969 80.5874 13.1534 86.2025C14.9038 89.6611 18.6705 95.5422 21.3589 94.8701C22.3251 94.632 23.1513 93.4698 24.1595 89.5491C25.3368 84.505 25.7661 79.3152 25.4337 74.1463C25.2406 67.5578 25.4604 60.9637 26.0918 54.4027C26.5463 50.2735 27.2808 46.18 28.2902 42.1504L29.8025 35.6812" fill="#DEE1E9"/>
      <mask id="mask434" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask434)">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M16.5633 15.7991C15.5012 15.7991 14.2556 10.5008 14.2556 0.902521L16.1787 -0.959547L19.2556 -2.20093C19.2556 7.3974 17.6254 15.7991 16.5633 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.9673 15.117C10.0092 16.8895 12.5415 22.7031 14.161 35.6066C14.7165 40.1663 15.0801 44.7569 15.2505 49.3608C15.5891 56.8582 15.2505 58.0281 15.6186 67.6525C15.7671 71.8114 16.1309 75.9552 16.7081 80.0596C17.2381 83.7818 19.9788 91.7813 20.2732 91.7991C20.8621 91.7991 19.4171 81.4714 19.1373 75.8235C19.2647 73.5691 19.2647 71.307 19.1373 69.0527C18.9402 63.7337 19.0336 58.4049 19.4171 53.1006C19.9176 43.5471 20.1827 38.7615 21.6108 33.9227C23.0389 29.084 23.4217 24.3692 24.7615 19.7431C25.0265 18.8569 25.6596 16.73 24.8792 15.8083C24.0989 14.8866 22.7444 15.702 21.1249 16.0919C15.9866 17.2972 13.631 13.7523 11.9673 15.117Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M6.98131 34.8347L7.27536 46.9749C8.33956 57.9809 8.95567 64.7581 9.40375 69.743C9.94985 75.8341 10.3699 80.665 13.2264 86.2801C14.9768 89.7387 18.7435 95.6198 21.4319 94.9476C22.3981 94.7096 23.2243 93.5474 24.2325 89.6267C25.4098 84.5826 25.8391 79.3928 25.5067 74.2239C25.3136 67.6354 25.5334 61.0413 26.1648 54.4802C26.6193 50.3511 27.3538 46.2576 28.3632 42.228L29.8755 35.7588" fill="#DEE1E9"/>
      <path d="M34.4963 15.3991C34.4963 15.3991 33.7962 33.4344 29.8755 35.7588C25.9548 38.0832 21.0819 40.8417 17.3852 40.1416C13.6047 39.067 10.0706 37.2643 6.98131 34.8346C6.51923 34.1345 1.19825 18.1856 1.19825 18.1856C1.19825 18.1856 10.2159 -1.06793 17.8473 1.50854C25.4787 4.08501 22.4681 4.77114 34.4963 15.3991Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M12.3164 31.0539C10.4541 32.4542 12.8625 37.047 14.4028 47.2409C14.9311 50.8431 15.2769 54.4697 15.439 58.1069C15.761 64.03 15.439 64.9541 15.789 72.5575C15.9303 75.8432 16.2763 79.1168 16.8252 82.3593C17.3293 85.2999 19.9359 91.6196 20.216 91.6336C20.7761 91.6336 19.4017 83.4746 19.1356 79.0127C19.2568 77.2318 19.2568 75.4447 19.1356 73.6637C18.9481 69.4617 19.037 65.2518 19.4017 61.0614C19.8778 53.514 20.1298 49.7334 21.4881 45.9107C22.8463 42.088 23.2104 38.3633 24.4846 34.7086C24.7367 34.0085 25.3388 32.3282 24.5966 31.6C23.8545 30.8719 22.5663 31.516 21.026 31.8241C16.1391 32.7763 13.8987 29.9757 12.3164 31.0539Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="21" cy="94" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M0.282238 17.0026C0.282238 17.0026 5.60321 32.9655 6.0653 33.6516L6.41536 45.7919C7.43755 56.7839 8.05366 63.5751 8.48774 68.574C9.03384 74.6511 9.45391 79.496 12.3104 85.097C14.0748 88.5697 17.8414 94.4508 20.5159 93.7786C21.4821 93.5266 22.3083 92.3784 23.3164 88.4576C24.4937 83.4136 24.9231 78.2238 24.5907 73.0548C24.4207 66.4562 24.6545 59.8533 25.2908 53.2832C25.7453 49.1541 26.4798 45.0606 27.4892 41.031L28.9595 34.5758C32.8802 32.2654 33.5803 14.2161 33.5803 14.2161C21.5661 3.57415 24.5627 2.90203 16.9313 0.325558C9.29989 -2.25091 0.282238 17.0026 0.282238 17.0026Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M0.282238 17.0026C0.282238 17.0026 5.60321 32.9655 6.0653 33.6516L6.41536 45.7919C7.43755 56.7839 8.05366 63.5751 8.48774 68.574C9.03384 74.6511 9.45391 79.496 12.3104 85.097C14.0748 88.5697 17.8414 94.4508 20.5159 93.7786C21.4821 93.5266 22.3083 92.3784 23.3164 88.4576C24.4937 83.4136 24.9231 78.2238 24.5907 73.0548C24.4207 66.4562 24.6545 59.8533 25.2908 53.2832C25.7453 49.1541 26.4798 45.0606 27.4892 41.031L28.9595 34.5758C32.8802 32.2654 33.5803 14.2161 33.5803 14.2161C21.5661 3.57415 24.5627 2.90203 16.9313 0.325558C9.29989 -2.25091 0.282238 17.0026 0.282238 17.0026Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M0.651825 17.5982L0.357773 5.45795L6.20508 4.5L11.4877 6.68106L17.2051 3.69122L23.252 6.38212L21.7397 12.8513C20.7303 16.8809 19.9958 20.9744 19.5413 25.1035C18.9099 31.6645 18.6901 38.2587 18.8832 44.8471C19.2156 50.0161 18.7862 55.2059 17.6089 60.25C16.6007 64.1707 15.7746 65.3329 14.8084 65.5709C12.1199 66.2431 8.35323 60.362 6.60291 56.9034C3.76206 51.3191 3.331 46.5105 2.78919 40.4665L2.78021 40.3663C2.33213 35.3814 1.71602 28.6042 0.651825 17.5982Z" fill="#DEE1E9"/>
      <path d="M19.9287 11.5273C12.2013 14.1567 8.36957 13.2022 2.24716 8" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M7.77924 17.8642C6.23896 7.67036 3.83052 3.07752 5.69286 1.67727L8.47377 2.90442L10.9738 1.40442L13.4738 2.90442L15.7253 1.40442L16.2051 1L17.9731 2.22336C18.7152 2.9515 18.1131 4.6318 17.8611 5.33193C16.5868 8.9866 16.2228 12.7113 14.8645 16.534C13.5063 20.3567 13.2542 24.1374 12.7782 31.6847C12.4135 35.8752 12.3246 40.085 12.5121 44.2871C12.6332 46.068 12.6332 47.8551 12.5121 49.636C12.7782 54.0979 14.1525 62.257 13.5924 62.257C13.3124 62.243 10.7058 55.9232 10.2017 52.9826C9.65279 49.7401 9.30672 46.4665 9.16549 43.1809C8.81543 35.5775 9.13749 34.6533 8.81543 28.7302C8.65338 25.0931 8.30754 21.4664 7.77924 17.8642Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M6.27916 34.5634L6.57321 46.7036C7.63741 57.7096 8.25352 64.4869 8.7016 69.4718C9.2477 75.5629 9.66778 80.3938 12.5243 86.0088C14.2746 89.4674 18.0413 95.3485 20.7298 94.6764C21.696 94.4384 22.5221 93.2761 23.5303 89.3554C24.7076 84.3113 25.1369 79.1216 24.8045 73.9526C24.6115 67.3641 24.8313 60.77 25.4627 54.209C25.9171 50.0799 26.6516 45.9863 27.6611 41.9568L29.1733 35.4876" fill="#DEE1E9"/>
      <path d="M29.1733 35.4876C33.0941 33.1631 33.7942 15.1278 33.7942 15.1278C21.766 4.4999 24.7765 3.81377 17.1451 1.2373C15.1717 0.571019 13.1054 1.36462 11.1265 2.90761L12.8892 3.74234L14.228 6.77732L10.4189 8.29206L11.6265 10.6055L10.4189 13.0724L11.326 15.7176H7.41889L3.78848 11.8984C1.77983 15.1735 0.496105 17.9143 0.496105 17.9143C0.496105 17.9143 5.81708 33.8633 6.27916 34.5634C9.36843 36.993 12.9026 38.7958 16.6831 39.8704C20.3797 40.5705 25.2526 37.812 29.1733 35.4876Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M6.95069 24.65C17.0497 20.6549 19.3627 16.162 18.207 4.60548" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M11.6142 30.7827C9.75191 32.183 12.1603 36.7758 13.7006 46.9697C14.2289 50.5719 14.5748 54.1985 14.7368 57.8356C15.0589 63.7587 14.7368 64.6829 15.0869 72.2863C15.2281 75.5719 15.5742 78.8455 16.1231 82.0881C16.6272 85.0286 19.2338 91.3484 19.5138 91.3624C20.0739 91.3624 18.6995 83.2034 18.4335 78.7415C18.5546 76.9605 18.5546 75.1734 18.4335 73.3925C18.246 69.1904 18.3349 64.9806 18.6995 60.7902C19.1756 53.2428 19.4277 49.4621 20.7859 45.6394C22.1442 41.8167 22.5082 38.092 23.7825 34.4374C24.0345 33.7372 24.6366 32.0569 23.8945 31.3288C23.1524 30.6007 21.8641 31.2448 20.3238 31.5528C15.4369 32.505 13.1965 29.7045 11.6142 30.7827Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M7.19736 17.2494V46.3485C8.26155 57.3545 8.87766 64.1318 9.32575 69.1167C9.87185 75.2078 10.2919 80.0387 13.1484 85.6537C14.8988 89.1123 18.6655 94.9934 21.3539 94.3213C22.3201 94.0833 23.1463 92.921 24.1545 89.0003C25.3318 83.9562 25.7611 78.7665 25.4287 73.5975C25.2356 67.009 25.4554 60.4149 26.0868 53.8539C26.5413 49.7248 27.2758 45.6312 28.2852 41.6017L33.7506 17.2494" fill="#DEE1E9"/>
      <path d="M29.7975 35.1325C33.7182 32.808 34.4183 14.7727 34.4183 14.7727C22.3901 4.14479 25.4007 3.45867 17.7693 0.882198C10.1379 -1.69427 1.12025 17.5592 1.12025 17.5592C1.12025 17.5592 6.44123 33.5082 6.90331 34.2083L10.66 34.9245C11.243 35.0357 11.7939 34.6175 11.8437 34.0261L12.7506 23.2494C16.8521 22.6959 19.1485 22.681 23.2506 23.2494L24.6402 34.2577C24.7033 34.7576 25.1285 35.1325 25.6323 35.1325H29.7975Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M6.57321 18V47.0991C7.63741 58.1052 8.25352 64.8824 8.7016 69.8673C9.2477 75.9584 9.66778 80.7893 12.5243 86.4043C14.2746 89.863 18.0413 95.744 20.7298 95.0719C21.696 94.8339 22.5221 93.6716 23.5303 89.7509C24.7076 84.7068 25.1369 79.5171 24.8045 74.3481C24.6115 67.7596 24.8313 61.1655 25.4627 54.6045C25.9171 50.4754 26.6516 46.3819 27.6611 42.3523L33.1265 18" fill="#DEE1E9"/>
      <path d="M29.1733 35.8831C33.0941 33.5586 33.7942 15.5233 33.7942 15.5233C21.766 4.8954 24.7765 4.20928 17.1451 1.63281C9.51376 -0.943662 0.496105 18.3099 0.496105 18.3099C0.496105 18.3099 5.81708 34.2588 6.27916 34.9589L10.0358 35.6751C10.6188 35.7863 11.1698 35.3681 11.2196 34.7767L12.1265 24C16.228 23.4466 18.5243 23.4316 22.6265 24L24.016 35.0083C24.0791 35.5082 24.5043 35.8831 25.0082 35.8831H29.1733Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M7.20236 18V47.0991C8.26656 58.1052 8.88267 64.8824 9.33075 69.8673C9.87685 75.9584 10.2969 80.7893 13.1534 86.4043C14.9038 89.863 18.6705 95.744 21.3589 95.0719C22.3251 94.8339 23.1513 93.6716 24.1595 89.7509C25.3368 84.7068 25.7661 79.5171 25.4337 74.3481C25.2406 67.7596 25.4604 61.1655 26.0918 54.6045C26.5463 50.4754 27.2808 46.3819 28.2902 42.3523L33.7556 18" fill="#DEE1E9"/>
      <mask id="mask2034" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
        <path d="M29.6348 35.4876C33.5555 33.1631 34.2556 15.1278 34.2556 15.1278C22.2274 4.4999 25.238 3.81377 17.6066 1.2373C9.97518 -1.33917 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 6.27851 33.8633 6.74059 34.5634L10.4972 35.2796C11.0803 35.3908 11.6312 34.9726 11.681 34.3812L12.5879 23.6045C16.6894 23.051 18.9857 23.0361 23.0879 23.6045L24.4775 34.6128C24.5406 35.1127 24.9657 35.4876 25.4696 35.4876H29.6348Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2034)">
        <path d="M29.6348 35.4876C33.5555 33.1631 34.2556 15.1278 34.2556 15.1278C22.2274 4.4999 25.238 3.81377 17.6066 1.2373C9.97518 -1.33917 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 6.27851 33.8633 6.74059 34.5634L10.4972 35.2796C11.0803 35.3908 11.6312 34.9726 11.681 34.3812L12.5879 23.6045C16.6894 23.051 18.9857 23.0361 23.0879 23.6045L24.4775 34.6128C24.5406 35.1127 24.9657 35.4876 25.4696 35.4876H29.6348Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M7.20239 47H28.2024V21.452C20.0014 19.5258 15.4034 19.5062 7.20239 21.452V47Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M6.60252 34.2104L6.89658 46.3507C7.96077 57.3567 8.57688 64.1339 9.02497 69.1188C9.57106 75.2099 9.99114 80.0408 12.8477 85.6558C14.598 89.1145 18.3647 94.9956 21.0532 94.3234C22.0193 94.0854 22.8455 92.9232 23.8537 89.0025C25.031 83.9584 25.4603 78.7686 25.1279 73.5996C24.9348 67.0112 25.1546 60.417 25.786 53.856C26.2405 49.7269 26.975 45.6334 27.9844 41.6038L29.4967 35.1346" fill="#DEE1E9"/>
      <path d="M34.1176 14.7748C34.1176 14.7748 33.4174 32.8101 29.4967 35.1345C25.576 37.459 20.7031 40.2175 17.0064 39.5173C13.2259 38.4428 9.6918 36.64 6.60253 34.2104C6.14044 33.5102 0.819469 17.5613 0.819469 17.5613C0.819469 17.5613 9.83712 -1.6922 17.4685 0.884274C25.0999 3.46074 22.0894 4.14687 34.1176 14.7748Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M11.9376 30.4299C10.0753 31.8302 12.4837 36.423 14.024 46.6169C14.5523 50.2191 14.8981 53.8457 15.0602 57.4829C15.3822 63.4059 15.0602 64.3301 15.4102 71.9335C15.5515 75.2191 15.8975 78.4928 16.4464 81.7353C16.9505 84.6758 19.5571 90.9956 19.8372 91.0096C20.3973 91.0096 19.0229 82.8506 18.7569 78.3887C18.878 76.6077 18.878 74.8206 18.7569 73.0397C18.5694 68.8376 18.6582 64.6278 19.0229 60.4374C19.499 52.89 19.751 49.1093 21.1093 45.2866C22.4675 41.4639 22.8316 37.7392 24.1058 34.0846C24.3579 33.3845 24.96 31.7041 24.2179 30.976C23.4757 30.2479 22.1875 30.892 20.6472 31.2001C15.7603 32.1522 13.5199 29.3517 11.9376 30.4299Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M8.89783 73.3127C15.268 76.7191 18.8252 76.5638 25.1576 73.3127" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M6.90831 34.958L7.20236 47.0982C8.26656 58.1042 8.88267 64.8815 9.33075 69.8664C9.87685 75.9575 10.2969 80.7884 13.1534 86.4034C14.9038 89.862 18.6705 95.7431 21.3589 95.071C22.3251 94.8329 23.1513 93.6707 24.1595 89.75C25.3368 84.7059 25.7661 79.5162 25.4337 74.3472C25.2406 67.7587 25.4604 61.1646 26.0918 54.6036C26.5463 50.4744 27.2808 46.3809 28.2902 42.3514L29.8025 35.8822" fill="#DEE1E9"/>
      <mask id="mask2234" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M34.2556 15.1278C34.2556 15.1278 33.5555 33.1631 29.6348 35.4876C25.7141 37.812 20.8412 40.5705 17.1445 39.8704C13.364 38.7958 9.82986 36.993 6.74059 34.5634C6.27851 33.8633 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 9.97518 -1.33917 17.6066 1.2373C25.238 3.81377 22.2274 4.4999 34.2556 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2234)">
        <path d="M34.2556 15.1278C34.2556 15.1278 33.5555 33.1631 29.6348 35.4876C25.7141 37.812 20.8412 40.5705 17.1445 39.8704C13.364 38.7958 9.82986 36.993 6.74059 34.5634C6.27851 33.8633 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 9.97518 -1.33917 17.6066 1.2373C25.238 3.81377 22.2274 4.4999 34.2556 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M16.5633 16C15.5012 16 14.2556 10.7018 14.2556 1.10345L16.1787 -0.758619L19.2556 -2C19.2556 7.59833 17.6254 16 16.5633 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.9673 15.318C10.0092 17.0904 12.5415 22.9041 14.161 35.8075C14.7165 40.3672 15.0801 44.9578 15.2505 49.5617C15.5891 57.0592 15.2505 58.229 15.6186 67.8534C15.7671 72.0124 16.1309 76.1561 16.7081 80.2605C17.2381 83.9827 19.9788 91.9823 20.2732 92C20.8621 92 19.4171 81.6723 19.1373 76.0244C19.2647 73.7701 19.2647 71.5079 19.1373 69.2536C18.9402 63.9346 19.0336 58.6058 19.4171 53.3016C19.9176 43.7481 20.1827 38.9625 21.6108 34.1237C23.0389 29.2849 23.4217 24.5702 24.7615 19.9441C25.0265 19.0578 25.6596 16.9309 24.8792 16.0092C24.0989 15.0876 22.7444 15.9029 21.1249 16.2928C15.9866 17.4981 13.631 13.9532 11.9673 15.318Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 21.2556 92.2009)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M6.90831 34.958L7.20236 47.0982C8.26656 58.1042 8.88267 64.8815 9.33075 69.8664C9.87685 75.9575 10.2969 80.7884 13.1534 86.4034C14.9038 89.862 18.6705 95.7431 21.3589 95.071C22.3251 94.8329 23.1513 93.6707 24.1595 89.75C25.3368 84.7059 25.7661 79.5162 25.4337 74.3472C25.2406 67.7587 25.4604 61.1646 26.0918 54.6036C26.5463 50.4744 27.2808 46.3809 28.2902 42.3514L29.8025 35.8822" fill="#DEE1E9"/>
      <mask id="mask2334" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M34.2556 15.1278C34.2556 15.1278 33.5555 33.1631 29.6348 35.4876C25.7141 37.812 20.8412 40.5705 17.1445 39.8704C13.364 38.7958 9.82986 36.993 6.74059 34.5634C6.27851 33.8633 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 9.97518 -1.33917 17.6066 1.2373C25.238 3.81377 22.2274 4.4999 34.2556 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2334)">
        <path d="M34.2556 15.1278C34.2556 15.1278 33.5555 33.1631 29.6348 35.4876C25.7141 37.812 20.8412 40.5705 17.1445 39.8704C13.364 38.7958 9.82986 36.993 6.74059 34.5634C6.27851 33.8633 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 9.97518 -1.33917 17.6066 1.2373C25.238 3.81377 22.2274 4.4999 34.2556 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M16.5633 16C15.5012 16 14.2556 10.7018 14.2556 1.10345L16.1787 -0.758619L19.2556 -2C19.2556 7.59833 17.6254 16 16.5633 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.9673 15.318C10.0092 17.0904 12.5415 22.9041 14.161 35.8075C14.7165 40.3672 15.0801 44.9578 15.2505 49.5617C15.5891 57.0592 15.2505 58.229 15.6186 67.8534C15.7671 72.0124 16.1309 76.1561 16.7081 80.2605C17.2381 83.9827 19.9788 91.9823 20.2732 92C20.8621 92 19.4171 81.6723 19.1373 76.0244C19.2647 73.7701 19.2647 71.5079 19.1373 69.2536C18.9402 63.9346 19.0336 58.6058 19.4171 53.3016C19.9176 43.7481 20.1827 38.9625 21.6108 34.1237C23.0389 29.2849 23.4217 24.5702 24.7615 19.9441C25.0265 19.0578 25.6596 16.9309 24.8792 16.0092C24.0989 15.0876 22.7444 15.9029 21.1249 16.2928C15.9866 17.4981 13.631 13.9532 11.9673 15.318Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 21.2556 92.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2334)">
        <path d="M21.2556 89L20.4327 92.7391L17.3989 90.4037L19.172 93.797L15.3468 93.9581L18.8862 95.4178L16.0595 98L19.7091 96.8431L19.2035 100.638L21.2556 97.406L23.3077 100.638L22.8022 96.8431L26.4518 98L23.6251 95.4178L27.1645 93.9581L23.3393 93.797L25.1123 90.4037L22.0785 92.7391L21.2556 89Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2334" x="15.1468" y="88.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M6.90831 34.958L7.20236 47.0982C8.26656 58.1042 8.88267 64.8815 9.33075 69.8664C9.87685 75.9575 10.2969 80.7884 13.1534 86.4034C14.9038 89.862 18.6705 95.7431 21.3589 95.071C22.3251 94.8329 23.1513 93.6707 24.1595 89.75C25.3368 84.7059 25.7661 79.5162 25.4337 74.3472C25.2406 67.7587 25.4604 61.1646 26.0918 54.6036C26.5463 50.4744 27.2808 46.3809 28.2902 42.3514L29.8025 35.8822" fill="#DEE1E9"/>
      <mask id="mask2334" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M34.2556 15.1278C34.2556 15.1278 33.5555 33.1631 29.6348 35.4876C25.7141 37.812 20.8412 40.5705 17.1445 39.8704C13.364 38.7958 9.82986 36.993 6.74059 34.5634C6.27851 33.8633 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 9.97518 -1.33917 17.6066 1.2373C25.238 3.81377 22.2274 4.4999 34.2556 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2334)">
        <path d="M34.2556 15.1278C34.2556 15.1278 33.5555 33.1631 29.6348 35.4876C25.7141 37.812 20.8412 40.5705 17.1445 39.8704C13.364 38.7958 9.82986 36.993 6.74059 34.5634C6.27851 33.8633 0.957531 17.9143 0.957531 17.9143C0.957531 17.9143 9.97518 -1.33917 17.6066 1.2373C25.238 3.81377 22.2274 4.4999 34.2556 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M16.5633 16C15.5012 16 14.2556 10.7018 14.2556 1.10345L16.1787 -0.758619L19.2556 -2C19.2556 7.59833 17.6254 16 16.5633 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.9673 15.318C10.0092 17.0904 12.5415 22.9041 14.161 35.8075C14.7165 40.3672 15.0801 44.9578 15.2505 49.5617C15.5891 57.0592 15.2505 58.229 15.6186 67.8534C15.7671 72.0124 16.1309 76.1561 16.7081 80.2605C17.2381 83.9827 19.9788 91.9823 20.2732 92C20.8621 92 19.4171 81.6723 19.1373 76.0244C19.2647 73.7701 19.2647 71.5079 19.1373 69.2536C18.9402 63.9346 19.0336 58.6058 19.4171 53.3016C19.9176 43.7481 20.1827 38.9625 21.6108 34.1237C23.0389 29.2849 23.4217 24.5702 24.7615 19.9441C25.0265 19.0578 25.6596 16.9309 24.8792 16.0092C24.0989 15.0876 22.7444 15.9029 21.1249 16.2928C15.9866 17.4981 13.631 13.9532 11.9673 15.318Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 21.2556 92.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2334)">
        <path d="M21.2556 89L20.4327 92.7391L17.3989 90.4037L19.172 93.797L15.3468 93.9581L18.8862 95.4178L16.0595 98L19.7091 96.8431L19.2035 100.638L21.2556 97.406L23.3077 100.638L22.8022 96.8431L26.4518 98L23.6251 95.4178L27.1645 93.9581L23.3393 93.797L25.1123 90.4037L22.0785 92.7391L21.2556 89Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2334" x="15.1468" y="88.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M6.90831 34.7571L7.20236 46.8973C8.26656 57.9033 8.88267 64.6805 9.33075 69.6655C9.87685 75.7566 10.2969 80.5874 13.1534 86.2025C14.9038 89.6611 18.6705 95.5422 21.3589 94.8701C22.3251 94.632 23.1513 93.4698 24.1595 89.5491C25.3368 84.505 25.7661 79.3152 25.4337 74.1463C25.2406 67.5578 25.4604 60.9637 26.0918 54.4027C26.5463 50.2735 27.2808 46.18 28.2902 42.1504L29.8025 35.6812" fill="#DEE1E9"/>
      <mask id="mask2534" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2534)">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M16.5633 15.7991C15.5012 15.7991 14.2556 10.5008 14.2556 0.902521L16.1787 -0.959547L19.2556 -2.20093C19.2556 7.3974 17.6254 15.7991 16.5633 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.9673 15.117C10.0092 16.8895 12.5415 22.7031 14.161 35.6066C14.7165 40.1663 15.0801 44.7569 15.2505 49.3608C15.5891 56.8582 15.2505 58.0281 15.6186 67.6525C15.7671 71.8114 16.1309 75.9552 16.7081 80.0596C17.2381 83.7818 19.9788 91.7813 20.2732 91.7991C20.8621 91.7991 19.4171 81.4714 19.1373 75.8235C19.2647 73.5691 19.2647 71.307 19.1373 69.0527C18.9402 63.7337 19.0336 58.4049 19.4171 53.1006C19.9176 43.5471 20.1827 38.7615 21.6108 33.9227C23.0389 29.084 23.4217 24.3692 24.7615 19.7431C25.0265 18.8569 25.6596 16.73 24.8792 15.8083C24.0989 14.8866 22.7444 15.702 21.1249 16.0919C15.9866 17.2972 13.631 13.7523 11.9673 15.117Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 21.2556 92)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2634" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="95">
        <path d="M6.44835 34.5635L6.7424 46.7037C7.8066 57.7097 8.42271 64.4869 8.87079 69.4719C9.41689 75.563 9.83697 80.3938 12.6935 86.0089C14.4438 89.4675 18.2105 95.3486 20.899 94.6765C21.8652 94.4384 22.6913 93.2762 23.6995 89.3555C24.8768 84.3114 25.3061 79.1216 24.9737 73.9527C24.7806 67.3642 25.0004 60.7701 25.6319 54.2091C26.0863 50.0799 26.8208 45.9864 27.8303 41.9568L29.3425 35.4876" fill="#DEE1E9"/>
        <path d="M33.9634 15.1278C33.9634 15.1278 33.2633 33.1631 29.3425 35.4876C25.4218 37.812 20.5489 40.5705 16.8523 39.8704C13.0718 38.7958 9.53762 36.993 6.44835 34.5634C5.98627 33.8633 0.665295 17.9143 0.665295 17.9143C0.665295 17.9143 9.68295 -1.33917 17.3143 1.2373C24.9457 3.81377 21.9352 4.4999 33.9634 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2634)">
        <path d="M6.44835 34.5635L6.7424 46.7037C7.8066 57.7097 8.42271 64.4869 8.87079 69.4719C9.41689 75.563 9.83697 80.3938 12.6935 86.0089C14.4438 89.4675 18.2105 95.3486 20.899 94.6765C21.8652 94.4384 22.6913 93.2762 23.6995 89.3555C24.8768 84.3114 25.3061 79.1216 24.9737 73.9527C24.7806 67.3642 25.0004 60.7701 25.6319 54.2091C26.0863 50.0799 26.8208 45.9864 27.8303 41.9568L29.3425 35.4876" fill="#DEE1E9"/>
        <path d="M33.9634 15.1278C33.9634 15.1278 33.2633 33.1631 29.3425 35.4876C25.4218 37.812 20.5489 40.5705 16.8523 39.8704C13.0718 38.7958 9.53762 36.993 6.44835 34.5634C5.98627 33.8633 0.665295 17.9143 0.665295 17.9143C0.665295 17.9143 9.68295 -1.33917 17.3143 1.2373C24.9457 3.81377 21.9352 4.4999 33.9634 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M22.9893 24.6762H34.9105C32.7484 30.046 32.3914 33.3063 32.6879 39.0446C32.6991 39.2609 32.4497 39.3955 32.2595 39.2689C31.2554 38.601 28.8972 36.7849 28.3256 34.1432C27.6331 30.9432 25.6299 30.1661 25.4173 30.092C25.4044 30.0875 25.3928 30.083 25.3804 30.0773L24.1975 29.5307C24.0529 29.4639 23.9943 29.3014 24.0666 29.1678L24.5955 28.1901C24.6402 28.1074 24.6362 28.0092 24.5847 27.9299L22.7383 25.0858C22.6213 24.9056 22.7618 24.6762 22.9893 24.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.9144 38.155C30.8534 37.3071 29.4454 35.8702 29.043 34.0106C28.2998 30.5761 26.1238 29.6201 25.6909 29.4648L24.9173 29.1073L25.2499 28.4925C25.4065 28.203 25.3922 27.8596 25.2121 27.5821L23.7646 25.3524H33.8672C32.0904 30.0171 31.7144 33.2129 31.9144 38.155ZM25.7076 29.4725C25.7077 29.4726 25.7074 29.4724 25.7076 29.4725V29.4725ZM31.8321 39.8178C32.5609 40.3027 33.4567 39.7463 33.4187 39.0123C33.1259 33.3462 33.4751 30.18 35.5962 24.9121L35.9634 24H22.9893C22.1931 24 21.7013 24.8028 22.1109 25.4338L23.8333 28.0869L23.4122 28.8654C23.1592 29.333 23.3643 29.9017 23.8702 30.1355L25.0532 30.6822C25.0939 30.701 25.1297 30.7145 25.1593 30.7248C25.1959 30.7376 26.9767 31.3577 27.6081 34.2758C28.2376 37.1849 30.801 39.1319 31.8321 39.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask134" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="95">
        <path d="M6.44835 34.5635L6.7424 46.7037C7.8066 57.7097 8.42271 64.4869 8.87079 69.4719C9.41689 75.563 9.83697 80.3938 12.6935 86.0089C14.4438 89.4675 18.2105 95.3486 20.899 94.6765C21.8652 94.4384 22.6913 93.2762 23.6995 89.3555C24.8768 84.3114 25.3061 79.1216 24.9737 73.9527C24.7806 67.3642 25.0004 60.7701 25.6319 54.2091C26.0863 50.0799 26.8208 45.9864 27.8303 41.9568L29.3425 35.4876" fill="#DEE1E9"/>
        <path d="M33.9634 15.1278C33.9634 15.1278 33.2633 33.1631 29.3425 35.4876C25.4218 37.812 20.5489 40.5705 16.8523 39.8704C13.0718 38.7958 9.53762 36.993 6.44835 34.5634C5.98627 33.8633 0.665295 17.9143 0.665295 17.9143C0.665295 17.9143 9.68295 -1.33917 17.3143 1.2373C24.9457 3.81377 21.9352 4.4999 33.9634 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask134)">
        <path d="M6.44835 34.5635L6.7424 46.7037C7.8066 57.7097 8.42271 64.4869 8.87079 69.4719C9.41689 75.563 9.83697 80.3938 12.6935 86.0089C14.4438 89.4675 18.2105 95.3486 20.899 94.6765C21.8652 94.4384 22.6913 93.2762 23.6995 89.3555C24.8768 84.3114 25.3061 79.1216 24.9737 73.9527C24.7806 67.3642 25.0004 60.7701 25.6319 54.2091C26.0863 50.0799 26.8208 45.9864 27.8303 41.9568L29.3425 35.4876" fill="#DEE1E9"/>
        <path d="M33.9634 15.1278C33.9634 15.1278 33.2633 33.1631 29.3425 35.4876C25.4218 37.812 20.5489 40.5705 16.8523 39.8704C13.0718 38.7958 9.53762 36.993 6.44835 34.5634C5.98627 33.8633 0.665295 17.9143 0.665295 17.9143C0.665295 17.9143 9.68295 -1.33917 17.3143 1.2373C24.9457 3.81377 21.9352 4.4999 33.9634 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M25.097 26.5H33.2438C31.7663 30.4705 31.5223 32.8811 31.725 37.1241C31.7326 37.2841 31.5622 37.3836 31.4322 37.29C30.746 36.7961 29.1345 35.4532 28.7438 33.5C28.2706 31.1339 26.9016 30.5593 26.7563 30.5045C26.7475 30.5012 26.7396 30.4978 26.7311 30.4936L25.9227 30.0894C25.8239 30.04 25.7838 29.9199 25.8332 29.8211L26.1947 29.0982C26.2253 29.037 26.2225 28.9645 26.1873 28.9058L24.9255 26.8029C24.8456 26.6696 24.9416 26.5 25.097 26.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1964 36.4664C30.4713 35.8394 29.5091 34.777 29.2341 33.4019C28.7262 30.8625 27.2392 30.1555 26.9433 30.0407L26.4147 29.7764L26.642 29.3218C26.749 29.1077 26.7392 28.8538 26.6161 28.6486L25.6269 27H32.5309C31.3167 30.4491 31.0597 32.8121 31.1964 36.4664ZM26.9547 30.0464C26.9548 30.0465 26.9546 30.0464 26.9547 30.0464V30.0464ZM31.1401 37.6958C31.6382 38.0544 32.2503 37.643 32.2244 37.1003C32.0243 32.9106 32.263 30.5695 33.7124 26.6744L33.9634 26H25.0971C24.553 26 24.2169 26.5936 24.4968 27.0601L25.6739 29.0219L25.3861 29.5975C25.2132 29.9433 25.3533 30.3638 25.6991 30.5367L26.5075 30.9409C26.5353 30.9548 26.5598 30.9648 26.58 30.9724C26.605 30.9818 27.822 31.4403 28.2535 33.5981C28.6837 35.749 30.4355 37.1887 31.1401 37.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M6.90831 34.7571L7.20236 46.8973C8.26656 57.9033 8.88267 64.6805 9.33075 69.6655C9.87685 75.7566 10.2969 80.5874 13.1534 86.2025C14.9038 89.6611 18.6705 95.5422 21.3589 94.8701C22.3251 94.632 23.1513 93.4698 24.1595 89.5491C25.3368 84.505 25.7661 79.3152 25.4337 74.1463C25.2406 67.5578 25.4604 60.9637 26.0918 54.4027C26.5463 50.2735 27.2808 46.18 28.2902 42.1504L29.8025 35.6812" fill="#DEE1E9"/>
      <mask id="mask234" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask234)">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M16.5633 15.7991C15.5012 15.7991 14.2556 10.5008 14.2556 0.902521L16.1787 -0.959547L19.2556 -2.20093C19.2556 7.3974 17.6254 15.7991 16.5633 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.9673 15.117C10.0092 16.8895 12.5415 22.7031 14.161 35.6066C14.7165 40.1663 15.0801 44.7569 15.2505 49.3608C15.5891 56.8582 15.2505 58.0281 15.6186 67.6525C15.7671 71.8114 16.1309 75.9552 16.7081 80.0596C17.2381 83.7818 19.9788 91.7813 20.2732 91.7991C20.8621 91.7991 19.4171 81.4714 19.1373 75.8235C19.2647 73.5691 19.2647 71.307 19.1373 69.0527C18.9402 63.7337 19.0336 58.4049 19.4171 53.1006C19.9176 43.5471 20.1827 38.7615 21.6108 33.9227C23.0389 29.084 23.4217 24.3692 24.7615 19.7431C25.0265 18.8569 25.6596 16.73 24.8792 15.8083C24.0989 14.8866 22.7444 15.702 21.1249 16.0919C15.9866 17.2972 13.631 13.7523 11.9673 15.117Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M6.90831 34.7571L7.20236 46.8973C8.26656 57.9033 8.88267 64.6805 9.33075 69.6655C9.87685 75.7566 10.2969 80.5874 13.1534 86.2025C14.9038 89.6611 18.6705 95.5422 21.3589 94.8701C22.3251 94.632 23.1513 93.4698 24.1595 89.5491C25.3368 84.505 25.7661 79.3152 25.4337 74.1463C25.2406 67.5578 25.4604 60.9637 26.0918 54.4027C26.5463 50.2735 27.2808 46.18 28.2902 42.1504L29.8025 35.6812" fill="#DEE1E9"/>
      <mask id="mask334" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask334)">
        <path d="M34.2556 14.9269C34.2556 14.9269 33.5555 32.9622 29.6348 35.2866C25.7141 37.6111 20.8412 40.3696 17.1445 39.6694C13.364 38.5949 9.82986 36.7921 6.74059 34.3625C6.27851 33.6623 0.957531 17.7134 0.957531 17.7134C0.957531 17.7134 9.97518 -1.5401 17.6066 1.03637C25.238 3.61284 22.2274 4.29897 34.2556 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M16.5633 15.7991C15.5012 15.7991 14.2556 10.5008 14.2556 0.902521L16.1787 -0.959547L19.2556 -2.20093C19.2556 7.3974 17.6254 15.7991 16.5633 15.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M6.65269 34.5635L6.94675 46.7037C8.01094 57.7097 8.62705 64.4869 9.07514 69.4719C9.62123 75.563 10.0413 80.3938 12.8978 86.0089C14.6482 89.4675 18.4148 95.3486 21.1033 94.6765C22.0695 94.4384 22.8957 93.2762 23.9038 89.3555C25.0811 84.3114 25.5105 79.1216 25.1781 73.9527C24.985 67.3642 25.2048 60.7701 25.8362 54.2091C26.2907 50.0799 27.0252 45.9864 28.0346 41.9568L29.5469 35.4876" fill="#DEE1E9"/>
      <path d="M34.1677 15.1278C34.1677 15.1278 33.4676 33.1631 29.5469 35.4876C25.6262 37.812 20.7533 40.5705 17.0566 39.8704C13.2761 38.7958 9.74197 36.993 6.6527 34.5634C6.19061 33.8633 0.86964 17.9143 0.86964 17.9143C0.86964 17.9143 9.88729 -1.33917 17.5187 1.2373C25.1501 3.81377 22.1395 4.4999 34.1677 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M12 3.60449L10.1333 6.10535L8.5 4.60965V7.92369L5.93782 7.35578L7.33333 9.10638L5 11.1071L7.33333 13.1078L5.93782 14.8584L8.26667 14.1081L8.5 17.6045L10.7499 16.1075L11.5333 17.1091L13.2501 16.1075L15.5 17.6045L16.2 15.6086L18.0622 14.8584L17.6 12.1074L19 11.1071L16.6667 9.60655L18.0622 7.35578L16.2 7.1057L15.5 4.60965L12.4667 6.60552L12 3.60449Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M6.65269 34.5635L6.94675 46.7037C8.01094 57.7097 8.62705 64.4869 9.07514 69.4719C9.62123 75.563 10.0413 80.3938 12.8978 86.0089C14.6482 89.4675 18.4148 95.3486 21.1033 94.6765C22.0695 94.4384 22.8957 93.2762 23.9038 89.3555C25.0811 84.3114 25.5105 79.1216 25.1781 73.9527C24.985 67.3642 25.2048 60.7701 25.8362 54.2091C26.2907 50.0799 27.0252 45.9864 28.0346 41.9568L29.5469 35.4876" fill="#DEE1E9"/>
      <path d="M34.1677 15.1278C34.1677 15.1278 33.4676 33.1631 29.5469 35.4876C25.6262 37.812 20.7533 40.5705 17.0566 39.8704C13.2761 38.7958 9.74197 36.993 6.6527 34.5634C6.19061 33.8633 0.86964 17.9143 0.86964 17.9143C0.86964 17.9143 9.88729 -1.33917 17.5187 1.2373C25.1501 3.81377 22.1395 4.4999 34.1677 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M12 3.60449L10.4 5.74809L9 4.46605V7.30666L6.80385 6.81988L8 8.3204L6 10.0353L8 11.7501L6.80385 13.2507L8.8 12.6076L9 15.6045L10.9285 14.3213L11.6 15.1799L13.0715 14.3213L15 15.6045L15.6 13.8937L17.1962 13.2507L16.8 10.8927L18 10.0353L16 8.74912L17.1962 6.81988L15.6 6.60552L15 4.46605L12.4 6.1768L12 3.60449Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M6.65269 34.5635L6.94675 46.7037C8.01094 57.7097 8.62705 64.4869 9.07514 69.4719C9.62123 75.563 10.0413 80.3938 12.8978 86.0089C14.6482 89.4675 18.4148 95.3486 21.1033 94.6765C22.0695 94.4384 22.8957 93.2762 23.9038 89.3555C25.0811 84.3114 25.5105 79.1216 25.1781 73.9527C24.985 67.3642 25.2048 60.7701 25.8362 54.2091C26.2907 50.0799 27.0252 45.9864 28.0346 41.9568L29.5469 35.4876" fill="#DEE1E9"/>
      <path d="M34.1677 15.1278C34.1677 15.1278 33.4676 33.1631 29.5469 35.4876C25.6262 37.812 20.7533 40.5705 17.0566 39.8704C13.2761 38.7958 9.74197 36.993 6.6527 34.5634C6.19061 33.8633 0.86964 17.9143 0.86964 17.9143C0.86964 17.9143 9.88729 -1.33917 17.5187 1.2373C25.1501 3.81377 22.1395 4.4999 34.1677 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M9.5 5.60449L8.56667 6.85492L7.75 6.10707V7.76409L6.46891 7.48014L7.16667 8.35544L6 9.35578L7.16667 10.3561L6.46891 11.2314L7.63333 10.8563L7.75 12.6045L8.87495 11.856L9.26667 12.3568L10.125 11.856L11.25 12.6045L11.6 11.6066L12.5311 11.2314L12.3 9.85595L13 9.35578L11.8333 8.60552L12.5311 7.48014L11.6 7.35509L11.25 6.10707L9.73333 7.10501L9.5 5.60449Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M21.793 75.6565L19.7093 76.9263H16.2255L14.1255 75.6565L15.7372 74.11L13.6535 72.5635L15.5418 71.017L13.2465 69.324L15.0697 67.7775L12.7744 66.3775L14.793 64.9612L12.7744 63.3496L14.4674 61.803L12.2372 60.3868L13.9953 58.9217L11.7 57.3751L13.8488 55.8286L11.7 54.217L13.2465 52.8659L11.1628 51.3193L12.986 49.838L10.9023 48.2914L12.7093 46.5496L11.7 44.8728V30.6124L13.5883 23.2868H19.7093" fill="#8889BE"/>
      <path d="M21.793 75.6565L19.7093 76.9263H16.2255L14.1255 75.6565L15.7372 74.11L13.6535 72.5635L15.5418 71.017L13.2465 69.324L15.0697 67.7775L12.7744 66.3775L14.793 64.9612L12.7744 63.3496L14.4674 61.803L12.2372 60.3868L13.9953 58.9217L11.7 57.3751L13.8488 55.8286L11.7 54.217L13.2465 52.8659L11.1628 51.3193L12.986 49.838L10.9023 48.2914L12.7093 46.5496L11.7 44.8728V30.6124L13.5883 23.2868H19.7093" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M14.3857 75.6565L16.4694 76.9263H19.9368L22.0531 75.6565L20.4252 74.11L22.5252 72.5635L20.6368 71.017L22.9159 69.324L21.1089 67.7775L23.388 66.3775L21.3694 64.9612L23.388 63.3496L21.7113 61.803L23.9252 60.3868L22.1834 58.9217L24.4624 57.3751L22.3136 55.8286L24.4624 54.217L22.9159 52.8659L24.9996 51.3193L23.1927 49.838L25.2764 48.2914L23.4531 46.5496L24.4624 44.8728V30.6124L22.5903 23.2868H18.6834" fill="#8889BE"/>
      <path d="M14.3857 75.6565L16.4694 76.9263H19.9368L22.0531 75.6565L20.4252 74.11L22.5252 72.5635L20.6368 71.017L22.9159 69.324L21.1089 67.7775L23.388 66.3775L21.3694 64.9612L23.388 63.3496L21.7113 61.803L23.9252 60.3868L22.1834 58.9217L24.4624 57.3751L22.3136 55.8286L24.4624 54.217L22.9159 52.8659L24.9996 51.3193L23.1927 49.838L25.2764 48.2914L23.4531 46.5496L24.4624 44.8728V30.6124L22.5903 23.2868H18.6834" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask534" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="37">
        <path d="M29.6263 35.9588C33.5471 33.6343 34.2472 15.599 34.2472 15.599C22.219 4.97109 25.2295 4.28496 17.5982 1.70849C9.96676 -0.867979 0.949108 18.3855 0.949108 18.3855C0.949108 18.3855 6.27008 34.3345 6.73217 35.0346L10.4888 35.7508C11.0718 35.862 11.6228 35.4438 11.6726 34.8524L12.5795 24.0757C16.681 23.5222 18.9773 23.5073 23.0795 24.0757L24.469 35.084C24.5321 35.5839 24.9573 35.9588 25.4612 35.9588H29.6263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask534)">
        <path d="M29.6263 35.9588C33.5471 33.6343 34.2472 15.599 34.2472 15.599C22.219 4.97109 25.2295 4.28496 17.5982 1.70849C9.96676 -0.867979 0.949108 18.3855 0.949108 18.3855C0.949108 18.3855 6.27008 34.3345 6.73217 35.0346L10.4888 35.7508C11.0718 35.862 11.6228 35.4438 11.6726 34.8524L12.5795 24.0757C16.681 23.5222 18.9773 23.5073 23.0795 24.0757L24.469 35.084C24.5321 35.5839 24.9573 35.9588 25.4612 35.9588H29.6263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M7.19397 47.4712H28.194V21.9232C19.993 19.997 15.395 19.9774 7.19397 21.9232V47.4712Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M21.385 74.948L19.3013 76.2178H15.8176L13.7176 74.948L15.3292 73.4015L13.2455 71.855L15.1339 70.3085L12.8385 68.6155L14.6618 67.069L12.3664 65.669L14.385 64.2527L12.3664 62.6411L14.0594 61.0945L11.8292 59.6783L13.5874 58.2132L11.292 56.6667L13.4408 55.1201L11.292 53.5085L12.8385 52.1574L10.7548 50.6109L12.5781 49.1295L10.4943 47.5829L12.3013 45.8411L11.292 44.1644V29.9039L13.1804 22.5784H19.3013" fill="#8889BE"/>
      <path d="M21.385 74.948L19.3013 76.2178H15.8176L13.7176 74.948L15.3292 73.4015L13.2455 71.855L15.1339 70.3085L12.8385 68.6155L14.6618 67.069L12.3664 65.669L14.385 64.2527L12.3664 62.6411L14.0594 61.0945L11.8292 59.6783L13.5874 58.2132L11.292 56.6667L13.4408 55.1201L11.292 53.5085L12.8385 52.1574L10.7548 50.6109L12.5781 49.1295L10.4943 47.5829L12.3013 45.8411L11.292 44.1644V29.9039L13.1804 22.5784H19.3013" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M13.9777 74.948L16.0615 76.2178H19.5289L21.6452 74.948L20.0173 73.4015L22.1173 71.855L20.2289 70.3085L22.5079 68.6155L20.701 67.069L22.98 65.669L20.9614 64.2527L22.98 62.6411L21.3033 61.0945L23.5172 59.6783L21.7754 58.2132L24.0545 56.6667L21.9056 55.1201L24.0545 53.5085L22.5079 52.1574L24.5917 50.6109L22.7847 49.1295L24.8684 47.5829L23.0452 45.8411L24.0545 44.1644V29.9039L22.1824 22.5784H18.2754" fill="#8889BE"/>
      <path d="M13.9777 74.948L16.0615 76.2178H19.5289L21.6452 74.948L20.0173 73.4015L22.1173 71.855L20.2289 70.3085L22.5079 68.6155L20.701 67.069L22.98 65.669L20.9614 64.2527L22.98 62.6411L21.3033 61.0945L23.5172 59.6783L21.7754 58.2132L24.0545 56.6667L21.9056 55.1201L24.0545 53.5085L22.5079 52.1574L24.5917 50.6109L22.7847 49.1295L24.8684 47.5829L23.0452 45.8411L24.0545 44.1644V29.9039L22.1824 22.5783H18.2754" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask534" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
        <path d="M29.2184 35.2503C33.1391 32.9258 33.8392 14.8905 33.8392 14.8905C21.811 4.26259 24.8216 3.57647 17.1902 0.999996C9.5588 -1.57647 0.541149 17.677 0.541149 17.677C0.541149 17.677 5.86212 33.626 6.32421 34.3261L10.0809 35.0423C10.6639 35.1535 11.2148 34.7353 11.2646 34.1439L12.1715 23.3672C16.273 22.8137 18.5694 22.7988 22.6715 23.3672L24.0611 34.3755C24.1242 34.8754 24.5494 35.2503 25.0532 35.2503H29.2184Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask534)">
        <path d="M29.2184 35.2503C33.1391 32.9258 33.8392 14.8905 33.8392 14.8905C21.811 4.26259 24.8216 3.57647 17.1902 0.999996C9.5588 -1.57647 0.541149 17.677 0.541149 17.677C0.541149 17.677 5.86212 33.626 6.32421 34.3261L10.0809 35.0423C10.6639 35.1535 11.2148 34.7353 11.2646 34.1439L12.1715 23.3672C16.273 22.8137 18.5694 22.7988 22.6715 23.3672L24.0611 34.3755C24.1242 34.8754 24.5494 35.2503 25.0532 35.2503H29.2184Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M43.2556 0.259766H22.2556V5.25977H43.2556V0.259766Z" fill="#838AA2"/>
      <path d="M21.2556 0.259766H0.255615V5.25977H21.2556V0.259766Z" fill="#838AA2"/>
      <path d="M11.0346 19.6777V48.7769C12.0988 59.7829 12.7149 66.5601 13.163 71.545C13.7091 77.6362 14.1292 82.467 16.9857 88.0821C18.736 91.5407 22.5027 97.4218 25.1912 96.7496C26.1574 96.5116 26.9836 95.3494 27.9917 91.4287C29.169 86.3846 29.5984 81.1948 29.266 76.0259C29.0729 69.4374 29.2927 62.8433 29.9241 56.2822C30.3786 52.1531 31.1131 48.0596 32.1225 44.03L37.5879 19.6777" fill="#DEE1E9"/>
      <path d="M33.6348 37.5608C37.5555 35.2364 38.2556 17.2011 38.2556 17.2011C26.2274 6.57314 29.238 5.88701 21.6066 3.31054C13.9752 0.734072 4.95753 19.9876 4.95753 19.9876C4.95753 19.9876 10.2785 35.9365 10.7406 36.6366L14.4972 37.3529C15.0803 37.464 15.6312 37.0458 15.681 36.4544L16.5879 25.6777C20.6894 25.1243 22.9857 25.1093 27.0879 25.6777L28.4775 36.686C28.5406 37.1859 28.9657 37.5608 29.4696 37.5608H33.6348Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M6.49571 34.5635L6.78976 46.7037C7.85396 57.7097 8.47007 64.4869 8.91815 69.4719C9.46425 75.563 9.88433 80.3938 12.7409 86.0089C14.4912 89.4675 18.2579 95.3486 20.9463 94.6765C21.9125 94.4384 22.7387 93.2762 23.7469 89.3555C24.9242 84.3114 25.3535 79.1216 25.0211 73.9527C24.828 67.3642 25.0478 60.7701 25.6792 54.2091C26.1337 50.0799 26.8682 45.9864 27.8776 41.9568L29.3899 35.4876" fill="#DEE1E9"/>
      <path d="M34.0107 15.1278C34.0107 15.1278 33.3106 33.1631 29.3899 35.4876C25.4692 37.812 20.5963 40.5705 16.8996 39.8704C13.1191 38.7958 9.58499 36.993 6.49572 34.5634C6.03363 33.8633 0.712658 17.9143 0.712658 17.9143C0.712658 17.9143 9.73031 -1.33917 17.3617 1.2373C24.9931 3.81377 21.9825 4.4999 34.0107 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M11.8308 30.7825C9.96846 32.1827 12.3769 36.7756 13.9172 46.9694C14.4455 50.5716 14.7913 54.1983 14.9534 57.8354C15.2754 63.7585 14.9534 64.6826 15.3034 72.286C15.4447 75.5717 15.7907 78.8453 16.3396 82.0878C16.8437 85.0284 19.4503 91.3481 19.7304 91.3621C20.2905 91.3621 18.9161 83.2031 18.65 78.7412C18.7712 76.9603 18.7712 75.1732 18.65 73.3922C18.4626 69.1902 18.5514 64.9803 18.9161 60.7899C19.3922 53.2426 19.6442 49.4619 21.0025 45.6392C22.3607 41.8165 22.7248 38.0918 23.999 34.4371C24.2511 33.737 24.8532 32.0567 24.111 31.3286C23.3689 30.6004 22.0807 31.2445 20.5404 31.5526C15.6535 32.5048 13.4131 29.7043 11.8308 30.7825Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M6.49571 34.5635L6.78976 46.7037C7.85396 57.7097 8.47007 64.4869 8.91815 69.4719C9.46425 75.563 9.88433 80.3938 12.7409 86.0089C14.4912 89.4675 18.2579 95.3486 20.9463 94.6765C21.9125 94.4384 22.7387 93.2762 23.7469 89.3555C24.9242 84.3114 25.3535 79.1216 25.0211 73.9527C24.828 67.3642 25.0478 60.7701 25.6792 54.2091C26.1337 50.0799 26.8682 45.9864 27.8776 41.9568L29.3899 35.4876" fill="#DEE1E9"/>
      <path d="M34.0107 15.1278C34.0107 15.1278 33.3106 33.1631 29.3899 35.4876C25.4692 37.812 20.5963 40.5705 16.8996 39.8704C13.1191 38.7958 9.58499 36.993 6.49572 34.5634C6.03363 33.8633 0.712658 17.9143 0.712658 17.9143C0.712658 17.9143 9.73031 -1.33917 17.3617 1.2373C24.9931 3.81377 21.9825 4.4999 34.0107 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.4" d="M11.8308 30.7825C9.96846 32.1827 12.3769 36.7756 13.9172 46.9694C14.4455 50.5716 14.7913 54.1983 14.9534 57.8354C15.2754 63.7585 14.9534 64.6826 15.3034 72.286C15.4447 75.5717 15.7907 78.8453 16.3396 82.0878C16.8437 85.0284 19.4503 91.3481 19.7304 91.3621C20.2905 91.3621 18.9161 83.2031 18.65 78.7412C18.7712 76.9603 18.7712 75.1732 18.65 73.3922C18.4626 69.1902 18.5514 64.9803 18.9161 60.7899C19.3922 53.2426 19.6442 49.4619 21.0025 45.6392C22.3607 41.8165 22.7248 38.0918 23.999 34.4371C24.2511 33.737 24.8532 32.0567 24.111 31.3286C23.3689 30.6004 22.0807 31.2445 20.5404 31.5526C15.6535 32.5048 13.4131 29.7043 11.8308 30.7825Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M6.49596 34.5635L6.79001 46.7037C7.8542 57.7097 8.47032 64.4869 8.9184 69.4719C9.4645 75.563 9.88457 80.3938 12.7411 86.0089C14.4914 89.4675 18.2581 95.3486 20.9466 94.6765C21.9128 94.4384 22.7389 93.2762 23.7471 89.3555C24.9244 84.3114 25.3537 79.1216 25.0213 73.9527C24.8282 67.3642 25.0481 60.7701 25.6795 54.2091C26.1339 50.0799 26.8684 45.9864 27.8779 41.9568L29.3901 35.4876" fill="#DEE1E9"/>
      <path d="M34.011 15.1278C34.011 15.1278 33.3109 33.1631 29.3901 35.4876C25.4694 37.812 20.5965 40.5705 16.8999 39.8704C13.1194 38.7958 9.58523 36.993 6.49596 34.5634C6.03388 33.8633 0.712902 17.9143 0.712902 17.9143C0.712902 17.9143 9.73055 -1.33917 17.3619 1.2373C24.9933 3.81377 21.9828 4.4999 34.011 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <mask id="mask534" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="29" width="16" height="36">
        <path d="M13.0816 64.5361L21.1585 64.536L25.8745 29.8392H10.2456L13.0816 64.5361Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask534)">
        <path opacity="0.2" d="M11.831 30.7825C9.9687 32.1827 12.3771 36.7756 13.9174 46.9694C14.4457 50.5716 14.7916 54.1983 14.9536 57.8354C15.2757 63.7585 14.9536 64.6826 15.3037 72.286C15.4449 75.5717 15.791 78.8453 16.3399 82.0878C16.844 85.0284 19.4506 91.3481 19.7306 91.3621C20.2907 91.3621 18.9163 83.2031 18.6503 78.7412C18.7714 76.9603 18.7714 75.1732 18.6503 73.3922C18.4628 69.1902 18.5517 64.9803 18.9163 60.7899C19.3924 53.2426 19.6445 49.4619 21.0027 45.6392C22.361 41.8165 22.725 38.0918 23.9993 34.4371C24.2513 33.737 24.8534 32.0567 24.1113 31.3286C23.3692 30.6004 22.0809 31.2445 20.5406 31.5526C15.6537 32.5048 13.4133 29.7043 11.831 30.7825Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5034" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="64" width="10" height="29">
        <path d="M20.5939 90.843L19.894 92.8779H18.4942L17.5061 91.578L12.9773 64.6467H21.7467L21.3762 76.5974L20.9232 87.1685L20.5939 90.843Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5034)">
        <path opacity="0.4" d="M11.8312 30.7825C9.96882 32.1827 12.3773 36.7756 13.9175 46.9694C14.4458 50.5716 14.7917 54.1983 14.9537 57.8354C15.2758 63.7585 14.9537 64.6826 15.3038 72.286C15.445 75.5717 15.7911 78.8453 16.34 82.0878C16.8441 85.0284 19.4507 91.3481 19.7307 91.3621C20.2908 91.3621 18.9165 83.2031 18.6504 78.7412C18.7715 76.9603 18.7715 75.1732 18.6504 73.3922C18.4629 69.1902 18.5518 64.9803 18.9165 60.7899C19.3925 53.2426 19.6446 49.4619 21.0028 45.6392C22.3611 41.8165 22.7252 38.0918 23.9994 34.4371C24.2514 33.737 24.8535 32.0567 24.1114 31.3286C23.3693 30.6004 22.081 31.2445 20.5408 31.5526C15.6539 32.5048 13.4135 29.7043 11.8312 30.7825Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M0.282238 17.0026C0.282238 17.0026 5.60321 32.9655 6.0653 33.6516L6.41536 45.7919C7.43755 56.7839 8.05366 63.5751 8.48774 68.574C9.03384 74.6511 9.45391 79.496 12.3104 85.097C14.0748 88.5697 17.8414 94.4508 20.5159 93.7786C21.4821 93.5266 22.3083 92.3784 23.3164 88.4576C24.4937 83.4136 24.9231 78.2238 24.5907 73.0548C24.4207 66.4562 24.6545 59.8533 25.2908 53.2832C25.7453 49.1541 26.4798 45.0606 27.4892 41.031L28.9595 34.5758C32.8802 32.2654 33.5803 14.2161 33.5803 14.2161C21.5661 3.57415 24.5627 2.90203 16.9313 0.325558C9.29989 -2.25091 0.282238 17.0026 0.282238 17.0026Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M6.98131 34.9218L7.27536 47.062C8.33956 58.068 8.95567 64.8453 9.40375 69.8302C9.94985 75.9213 10.3699 80.7522 13.2264 86.3672C14.9768 89.8258 18.7435 95.7069 21.4319 95.0348C22.3981 94.7968 23.2243 93.6345 24.2325 89.7138C25.4098 84.6697 25.8391 79.48 25.5067 74.311C25.3136 67.7225 25.5334 61.1284 26.1648 54.5674C26.6193 50.4383 27.3538 46.3447 28.3632 42.3152L29.8755 35.846" fill="#DEE1E9"/>
      <path d="M34.4963 15.4862C34.4963 15.4862 33.7962 33.5215 29.8755 35.846C25.9548 38.1704 21.0819 40.9289 17.3852 40.2288C13.6047 39.1542 10.0706 37.3514 6.98131 34.9218C6.51923 34.2217 1.19825 18.2727 1.19825 18.2727C1.19825 18.2727 10.2159 -0.980772 17.8473 1.5957C25.4787 4.17217 22.4681 4.8583 34.4963 15.4862Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth34",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
