<template>
  <div>
    <DataTable :options="options"
               class="mt-4"
               module="marketing"/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/marketing/marketing_mailing"

export default {
  name: "MarketingMailing",
  components: {
    DataTable,
  },
  head() {
    return {
      title: this.$t('notifications')
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      options: options
    }
  },
  methods: {
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
