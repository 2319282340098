<template>
  <div class="nowrap">
    <span>{{ fieldType ? $t(object[fieldType]) : $t(object.task_type) }}</span>
    <span class="circle ml-2 opacity-100" :class="'importance-'+object.importance"></span>
  </div>
</template>

<script>
export default {
  name: "CRMDatatableTDTaskType",
  props: {
    object: {
      type: Object
    },
    fieldType: {

    }
  },
  computed: {

  },
  methods: {
    tickTask() {

    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
  .circle {
    width: 9px;
    height: 9px;
    &.importance-high {
      background: #DD1515;
    }
    &.importance-medium {
      background: #FEC943;
    }
    //&.importance-low {
    //  background: #DD1515;
    //}
  }
</style>
