export default {
    accounting: "Бухгалтерія",
    action_log: "Журнал дій",
    calendar: "Календар",
    control: "Керування",
    doctors: "Лікарі",
    insurance: "Страхові",
    patients: "Пацієнти",
    reports: "Звіти",
    services: "Послуги",
    settings: "Налаштування",
    users: "Користувачі",
    warehouse: "Склад",
}
