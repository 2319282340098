<template>
  <div class="position-relative">
    <input type="text"
           v-model="q"
           @focus="search"
           @keyup.enter="onEnter"
           class="form-control w-100"
           :placeholder="placeholder"/>
    <SearchSvg/>
    <div class="autocomplete-dropdown bg-white w-100 shadow-sm rounded"
         v-if="found.length > 0 && showDropdown"
         v-click-outside="clickedOutside">
      <template v-if="model == 'service'">
        <span v-for="(value, vind) in found"
              :key="`cat-${vind}`">
          <div class="service-category">{{ value.category }}</div>
        <a class="d-block pointer"
           @click="select(f)"
           v-for="(f, find) in value.services"
           :key="`found-${find}`">{{ renderTitle(f) }}</a>
        </span>
      </template>
      <template v-else>
        <a class="d-block pointer"
           @click="select(f)"
           v-for="(f, find) in found"
           :key="`found-${find}`">{{ f.title }}</a>
      </template>
    </div>
  </div>
</template>

<script>

import SearchSvg from '@/assets/svg-vue/header/magnifier.svg'
import { EloquentService } from "@/services/api.service"
import { mapState } from "vuex"

export default {
  name: "Autocomplete",
  components: {
    SearchSvg
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      required: true
    },
    renderTitle: {
      type: Function,
      default: (object) => object.title
    },
    autocompleteAdditionalParams: {
      type: Object,
      default: null
    }
  },
  computed: {
    ...mapState({
      superadmin: state => state.auth.superadmin,
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
    }),
  },
  data() {
    return {
      found: [],
      timer: null,
      q: '',
      cachedObjects: {},
      showDropdown: false,
      searchEnabled: true
    }
  },
  methods: {
    search() {
      if(this.clinic && this.clinic.id in this.cachedObjects && this.q in this.cachedObjects[this.clinic.id]) {
        this.found = this.cachedObjects[this.clinic.id][this.q]
        setTimeout(() => {
          this.showDropdown = true
        },150)
        return
      }
      EloquentService.autocomplete(
          this.model,
          this.q,
          24,
          true,
          this.superadmin,
          this.superadmin && this.clinic ? this.clinic.id : null,
          this.branch,
          this.autocompleteAdditionalParams
      ).then(res => {
        this.found = res.data.result
        if(this.clinic) {
          if(!this.cachedObjects[this.clinic.id]) this.$set(this.cachedObjects, this.clinic.id, {})
          this.cachedObjects[this.clinic.id][res.data.q] = res.data.result
        }
        this.showDropdown = true
      })
    },
    select(object) {
      this.q = ''
      this.found = []
      this.showDropdown = false
      this.$emit('input', object)
      this.searchEnabled = false
      setTimeout(() => {
        this.searchEnabled = true
      }, 300)
    },
    onEnter() {
      if(this.found.length === 1) {
        this.select(this.found[0])
      }
    },
    clickedOutside() {
      this.showDropdown = false
    }
  },
  watch: {
    q() {
      if(!this.searchEnabled) return
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.search()
      }, 250)
    }
  }
}
</script>

<style scoped lang="scss">
.autocomplete-search-bar {
  max-width: 300px;
  input {
    border: none;
    background: #E0E7EE;
    border-radius: 25px;
    height: 33px;
    padding-left: 25px;
    &::placeholder {
      font-style: italic;
    }
  }
  svg {
    position: absolute;
    right: 15px;
    top: 10px;
  }
}
.autocomplete-operation-material {
  border: 1px solid $gray-icon;
  border-radius: 5px;
  width: 304px;
  @media screen and (max-width: 768px) {
    width: auto;
  }
  .form-control {
    border-radius: 5px;
    height: 32px;
  }
  svg {
    display: none;
  }
}
.service-category {
  padding: 5px 15px;
  background: #ededed!important;
  color: #a6a6a6!important;
}
.autocomplete-form-control {
  svg {
    display: none;
  }
}
</style>
