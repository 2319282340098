<template>
  <div class="d-inline-block align-middle position-relative">
    <div class="form-control gray-input d-flex align-items-center justify-content-center pointer"
         v-b-tooltip.hover
         :title="$t('today')"
         @click="goToToday">
      <CalendarSvg class="svg-gray"/>
    </div>
  </div>
</template>

<script>

import CalendarSvg from '@/assets/svg-vue/calendar/calendar2.svg'
import { mapGetters, mapState } from "vuex"
import moment from "moment/moment"
import { ViewRange } from "@/components/pages/calendar/calendar/const/CalendarConst"

export default {
  name: "CalendarToday",
  components: {
    CalendarSvg,
  },
  data() {
    return {

    }
  },
  computed: {
    ...mapState({
      activeDate: state => state.calendar.activeDate,
    }),
    ...mapGetters([
      'activeRange'
    ]),
  },
  methods: {
    goToToday() {
      if(this.activeRange === ViewRange.WEEK) {
        this.$store.commit('setActiveDate', moment())
        this.$store.commit('refreshViewDates')
      } else {
        this.$store.commit('goToDate', moment())
      }
    },
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">
  svg {
    width: 16px;
    height: 16px;
  }
</style>
