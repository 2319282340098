import  { EloquentService } from "@/services/api.service"

const state = {
    tariff: null,
    payStatus: null,
    billingPayment: null
}

const getters = {

}

const mutations = {
    setTariff(state, data) {
        state.tariff = data
    },
    setPayStatus(state, data) {
        state.payStatus = data
    },
    setBillingPayment(state, data) {
        state.billingPayment = data
    },
}

const actions = {
    getSuperAdminClinic(context, id) {
        return new Promise((resolve, reject) => {
            EloquentService.show('clinic', id, true).then(data => {
                let d = data.data
                resolve(d)
            }).catch(err => {
                reject(err.response.data.errors)
            })
        })
    },
};

export default {
    state,
    actions,
    mutations,
    getters
}
