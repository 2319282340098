import options from "@/data/staff/technic"
import { doctorAdapter } from "@/adapters/staff/doctor"
import { copyObject } from "@/extensions/prototypes/prototypes"
import store from "@/store"

export const superadmin_technic_options = () => {
    const technic_options = copyObject(options)
    technic_options.fields.splice((technic_options.fields.length - 1), 0, {
        id: 'branch_id',
        title: 'branch',
        table: false,
        form: {
            type: "select",
            select_type: "model",
            select_data: {
                "title": "title"
            },
            required: true,
            classes: 'col-md-4',
        },
    })
    technic_options.fields.splice((technic_options.fields.length - 1), 0, {
        id: 'blocked',
        title: 'status',
        table: true,
        sortable: true,
        tdcomponent: "SuperAdminDatatableTDUserBlocked"
    })
    technic_options.prepareParams = ['id', 'clinic_id']
    technic_options.adapter = doctorAdapter
    technic_options.actionscomponent = 'SuperAdminUsersActions'
    if(store.state.auth.user?.role === 'tech_specialist') {
        technic_options.actions = ['excel']
    }
    return technic_options
}
