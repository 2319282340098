<template>
  <div class="position-relative">
    <InputGroup :label="$t(field.title)"
                type="text"
                :required="true"
                @keyup="changed"
                v-model="object[field.id]">
      <template #label-right>
        <a class="pointer" @click="toggleShow">
          <EditSvg class="svg-size-sm svg-light"/>
        </a>
      </template>
    </InputGroup>
    <div v-if="popShow"
         class="position-absolute px-3 pt-3 pop-show bg-white box-shadow"
         v-click-outside="clickOutside">
      <InputGroup :label="$t('comment_to_phone')"
                  v-if="view === 'mphone'"
                  type="text"
                  v-model="object.mphone_descr"/>
      <InputGroup :label="$t('comment_to_phone_2')"
                  v-if="view === 'mphone2'"
                  type="text"
                  v-model="object.mphone2_descr"/>
    </div>
    <div class="validation-error small"
         v-if="userWithSamePhone">
      {{ $t('this_phone_is_in_system') }}: {{ userWithSamePhone.full_name }}
    </div>
  </div>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import { PatientService } from "@/services/api.service"
import EditSvg from '@/assets/svg-vue/general/edit_s0.svg'
import { mapState } from "vuex"

export default {
  name: "PatientsDatatableFormMPhone",
  components: {
    InputGroup,
    EditSvg
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {},
    view: {
      type: String,
      default: 'mphone'
    }
  },
  data() {
    return {
      userWithSamePhone: null,
      timer: null,
      popShow: false,
      justOpened: false
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    toggleShow() {
      this.popShow = !this.popShow
      this.justOpened = true
      setTimeout(() => {
        this.justOpened = false
      }, 20)
    },
    clickOutside() {
      if(!this.justOpened) {
        this.popShow = false
      }
    },
    changed(e) {
      if(this.view === 'mphone2') {
        return
      }
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        const val = e.target.value
        if(val) {
          PatientService.checkIfPhoneExists(this.object.id, val).then(res => {
            this.userWithSamePhone = res.data.user
          })
        }
      }, 250)
    }
  }
}
</script>

<style scoped lang="scss">
.pop-show {
  bottom: 100%;
  right: 0;
  left: auto;
  width: 100%;
  border-radius: 8px;
}
</style>
