<template>
  <div>
    <router-link class="tdclickable" :to="{ name: 'WarehousesStocks', query: params }">
      {{ object.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "WarehouseDatatableTDMaterialTitle",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    params() {
      return {
        filters: JSON.stringify({
          material_id: {
            id: 'material_id',
            condition: this.object.material_id,
            type: 'equals'
          }
        })
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
