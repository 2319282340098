import { EloquentService } from "@/services/api.service"

const state = {
    accountingmonth: null,
    salaryRow: null,
}

const getters = {

}

const mutations = {
    setAccountingMonth(state, month) {
        state.accountingmonth = month
    },
    setSalaryRow(state, salaryRow) {
        state.salaryRow = salaryRow
    },
}

const actions = {
    getAccountingMonth(context, id) {
        return new Promise((resolve, reject) => {
            EloquentService.show('accountingmonth', id).then(data => {
                let d = data.data
                context.commit('setAccountingMonth', d)
                resolve(d)
            }).catch(err => {
                reject(err.response?.data?.errors || null)
            })
        })
    },
}

export default {
    state,
    actions,
    mutations,
    getters
}
