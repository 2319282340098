<template>
  <div class="position-relative patient-avatar-img">
    <img v-if="hasPhoto"
         :src="user.photo"
         alt=""
         :title="user.full_name"/>
    <span v-else>{{ initials }}</span>
  </div>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
})

const hasPhoto = computed(() => !props.user.photo.includes('images/zaglushka.png'))

const initials = computed(() => {
  return (props.user.fname?.charAt(0)?.toUpperCase() || '') + (props.user.lname?.charAt(0)?.toUpperCase() || '')
})
</script>
