<template>
  <div class="notfound">
    <div class="container">
      <div class="">
        <h1>404</h1>
        <p class="text-muted">{{ $t('page_not_found') }}</p>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    head() {
      return {
        title: this.$t('page_not_found'),
      }
    },
    mounted() {
    }
  }
</script>

<style scoped>
  .notfound {
    padding-bottom: 150px;
    padding-top: 150px;
  }
</style>
