<template>
  <div>
    <div class="mt-2">
      <AddObject module="settings" btnclass="btn-themed-outline btn-themed-squared"/>
      <a class="btn-themed btn-themed-outline btn-themed-squared d-inline-block ml-2 align-middle" @click="addStandard">{{ $t('add_standard') }}</a>
    </div>
    <DataTable :options="options"
               class="mt-4"
               module="settings"/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/notification_setting"
import AddObject from "@/components/parts/datatable/AddObject"

export default {
  name: "SettingsNotifications",
  components: {
    DataTable,
    AddObject
  },
  head() {
    return {
      title: this.$t('notifications'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      options: options
    }
  },
  methods: {
    addStandard() {

    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
