<template>
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path
        d="M11.414 1.50897L10.491 0.586029C10.116 0.21048 9.60754 0 9.077 0H1.37503C0.615509 0 0 0.615509 0 1.37503V10.625C0 11.3845 0.615509 12 1.37503 12H10.625C11.3845 12 12 11.3845 12 10.625V2.923C12 2.39246 11.7895 1.88397 11.414 1.50897ZM1.99997 3.12497V1.875C1.99997 1.668 2.16797 1.5 2.37497 1.5H7.62497C7.83197 1.5 7.99997 1.668 7.99997 1.875V3.12497C7.99997 3.33197 7.83197 3.49997 7.62497 3.49997H2.37497C2.16797 3.49997 1.99997 3.33197 1.99997 3.12497ZM6 10C4.75754 10 3.75 8.99249 3.75 7.75003C3.75 6.50748 4.75754 5.50003 6 5.50003C7.24246 5.50003 8.25 6.50748 8.25 7.75003C8.25 8.99249 7.24246 10 6 10Z"
        fill="#0787C9"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="12" height="12" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
