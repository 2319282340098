import Vue from "vue"
import Vuex from "vuex"

import auth from "@/store/auth.store";
import calendar from "@/store/calendar.store";
import dom from "@/store/dom.store";
import datatable from "@/store/datatable.store";
import warehouseoperation from "@/store/warehouseoperation.store";
import warehousegroup from "@/store/warehousegroup.store";
import patient from "@/store/patient.store";
import doctor from "@/store/doctor.store";
import teeth from "@/store/teeth.store";
import treatment from "@/store/treatment.store";
import accounting from "@/store/accounting.store";
import ppo from "@/store/ppo.store";
import crm from "@/store/crm.store";
import marketing from "@/store/marketing.store";
import egisz from "@/store/egisz.store";
import superadmin from "@/store/superadmin.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    dom,
    auth,
    calendar,
    datatable,
    warehouseoperation,
    warehousegroup,
    patient,
    doctor,
    accounting,
    ppo,
    crm,
    marketing,
    egisz,
    superadmin,
    teeth,
    treatment
  },
});
