<template>
  <div>
    <template v-if="object[field.id]">
      <div v-for="(c, d) in object[field.id]" :key="`td-cur-${object.id}-${field.id}-${d}`">
        {{ formatNumber(c, true) }} {{ c > 0 ? d : '' }}
      </div>
    </template>
    <template v-else>-</template>
  </div>
</template>

<script>
import { formatNumber } from "@/extensions/filters/filters"

export default {
  name: "CurrencyJsonTD",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  methods: {
    formatNumber
  }
}
</script>
