<template>
<svg viewBox="0 0 32 32.000001" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
     xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
     xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
     xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd" xmlns:svg="http://www.w3.org/2000/svg">
  <g id="layer1" transform="translate(0,-1020.3622)">
    <path d="m 23.49998,1038.1122 -10e-6,9" id="path11734"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 25.99998,1038.1122 -10e-6,9" id="path11736"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 22.49997,1048.8622 0,-0.75 c 0,-0.5523 0.44772,-1 1,-1 l 2.5,0 c 0.55228,0 1,0.4477 1,1 l 0,0.75"
          id="path11738"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path
        d="m 22.49997,1036.3622 10e-6,0.75 c 10e-6,0.5523 0.44771,1 1,1 l 2.5,0 c 0.55228,0 1.00001,-0.4477 1,-1 l -10e-6,-0.75"
        id="path11740"
        style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 14.24998,1038.1122 -1e-5,9" id="path11744"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 16.74998,1038.1122 -10e-6,9" id="path11746"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 13.24997,1048.8622 0,-0.75 c 0,-0.5523 0.44772,-1 1,-1 l 2.5,0 c 0.55228,0 1,0.4477 1,1 l 0,0.75"
          id="path11748"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path
        d="m 13.24997,1036.3622 1e-5,0.75 c 10e-6,0.5523 0.44771,1 1,1 l 2.5,0 c 0.55228,0 1.00001,-0.4477 1,-1 l -10e-6,-0.75"
        id="path11750"
        style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 30.49284,1051.8622 -29.98572,0" id="path11754"
          style="opacity:1;fill:none;fill-opacity:1;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
    <path d="m 15.49999,1027.8622 0,-6.5 5.5,0 0,3 -4.25003,0" id="path11756"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:round;stroke-opacity:1"/>
    <path d="m 1.99999,1049.8622 27,0" id="path11758"
          style="opacity:1;fill:none;fill-opacity:1;stroke-width:0.99999994;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
    <path d="m 4.99999,1038.1122 -1e-5,9" id="path11760"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 7.49999,1038.1122 -1e-5,9" id="path11762"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 3.99998,1048.8622 0,-0.75 c 0,-0.5523 0.44772,-1 1,-1 l 2.5,0 c 0.55228,0 1,0.4477 1,1 l 0,0.75"
          id="path11764"
          style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path
        d="m 3.99998,1036.3622 1e-5,0.75 c 1e-5,0.5523 0.44771,1 1,1 l 2.5,0 c 0.55228,0 1.00001,-0.4477 1,-1 l -10e-6,-0.75"
        id="path11766"
        style="fill:none;fill-rule:evenodd;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"/>
    <path d="m 15.49999,1027.3622 14,6.5 0,1.5 -28,0 0,-1.5 z" id="path11770"
          style="opacity:1;fill:none;fill-opacity:1;stroke-width:0.99999994;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
    <path
        d="m 16.99998,1031.8621 a 1.4999835,1.4999835 0 0 1 -1.49998,1.5 1.4999835,1.4999835 0 0 1 -1.49998,-1.5 1.4999835,1.4999835 0 0 1 1.49998,-1.4999 1.4999835,1.4999835 0 0 1 1.49998,1.4999 z"
        id="circle11772"
        style="opacity:1;fill:none;fill-opacity:1;stroke-width:1;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
  </g>
</svg>
</template>
