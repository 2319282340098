<template>
  <b-modal id="services-bulk-edit-modal"
           size="sm"
           @show="onShow"
           hide-footer
           :title="$t('actions')"
           centered>
    <div>

      <ServiceMultiSelect :label="$t('change_category')"
                          :dblclick="false"
                          model="service_category"
                          title="title"
                          :dbsearch="true"
                          :multiple="false"
                          v-model="category"/>

      <ServiceMultiSelect :label="$t('change_pricelist')"
                          :dblclick="false"
                          model="price"
                          title="title"
                          :dbsearch="true"
                          :multiple="false"
                          v-model="priceList"/>

      <InputGroup v-model="increaseBy"
                  type="number"
                  :show-errors="true"
                  validation-id="increase_by"
                  :label="`${$t('increase_price')} (%)`"/>

      <InputGroup v-model="decreaseBy"
                  type="number"
                  :show-errors="true"
                  validation-id="decrease_by"
                  :label="`${$t('decrease_price')} (%)`"/>

      <div class="text-right mt-5">
        <button class="btn btn-themed btn-themed-squared"
                :disabled="loading"
                @click="save">
          {{ $t('save') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import { computed, defineEmits, ref } from "vue"
import { useStore } from "vuex"
import InputGroup from "@/components/form/InputGroup.vue"
import { ClinicService } from "@/services/api.service"

const props = defineProps({
  selected: {
    type: Array,
    default: () => []
  }
})

const store = useStore()
const emit = defineEmits(['finished'])

const category = ref(null)
const priceList = ref(null)
const increaseBy = ref(0)
const decreaseBy= ref(0)

const loading = computed(() => store.state.dom.loading)

const onShow = () => {
  category.value = null
  priceList.value = null
  increaseBy.value = 0
  decreaseBy.value = 0
}

const save = () => {
  ClinicService.bulkServiceChange({
    category_id: category.value?.id || null,
    price_id: priceList.value?.id || null,
    increase_by: increaseBy.value || null,
    decrease_by: decreaseBy.value || null,
    services: props.selected
  }).then(() => {
    emit('finished')
  }).catch((e) => {
    console.log(e)
  })
}
</script>
