export default {
    primaryKey: 'id',
    model: 'scan',
    order: 'id',
    sort: 'desc',
    prepareParams: ['id', 'client_id'],
    deletemessage: 'are_you_sure_you_want_to_delete_scan',
    editAdditional: {
        record_immutable: true
    },
    actions: [
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'scan',
            title: 'scan',
            table: true,
            sortable: false,
            tabletdclass: "order-mob-0",
            tdcomponent: "PatientsDatatableTDScanImg",
        },
        // {
        //     id: 'record',
        //     title: 'visit',
        //     table_show: 'record.start',
        //     format_date: true,
        //     table: true,
        //     sortable: false,
        // },
        // {
        //     id: 'record_id',
        //     title: 'visit',
        //     table: false,
            // form: {
            //     type: "select",
            //     select_type: "model",
            //     select_data: {
            //         "title": "start"
            //     },
            // }
        // },
        {
            id: 'tooth',
            title: 'teeth',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDTeeth",
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                component: "PatientsDatatableFormTeeth"
            }
        },
        {
            id: 'image',
            title: 'file',
            form: {
                type: "file",
                filetype: "image",
                required: true
            }
        },
        {
            id: 'comment',
            title: 'description',
            table: true,
            sortable: false,
            form: {
                type: "textarea"
            }
        },
    ]
}
