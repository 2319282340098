<template>
  <b-modal id="payment-return-modal"
           :title="$t('return')"
           size="sm"
           centered
           @hide="emit('hide')">
    <InputGroup type="number"
                :label="$t('sum')"
                :required="true"
                v-model="amount"/>
    <ValidationError validationId="paid"/>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="ml-auto cancel d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>
        <button :disabled="loading || !amount"
                @click="submit"
                class="ml-3 btn-themed align-middle d-inline-block btn-themed-squared">
          {{ $t('send') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"
import { getCurrentInstance, onBeforeMount, ref } from "vue"
import { PatientService} from "@/services/api.service"
import { KKMService } from "@/services/kkm.service"

const emit = defineEmits(['hide'])
const amount = ref(0)
const loading = ref(false)

let i18n = null
let noty = null

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  noty = getCurrentInstance()?.appContext?.app?.noty
})

const props = defineProps({
  payment: Object,
})

const submit = async () => {
  loading.value = true
  try {
    const res = await PatientService.refundPay(props.payment.id, {
      amount: amount.value
    })
    if(props.payment.fiscal_vendor === 'kkm' && props.payment.uuid) {
      await kkmPrintCheque(res.data)
    }
    emit('hide')
    noty.info(i18n.t('success_message'))
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}

const kkmPrintCheque = async (payment) => {
  const res = await KKMService.printCheque({
    totalPrice: -Number(payment.paid),
    totalPaid: -Number(payment.paid),
    payId: -payment.id,
    amount: -Number(payment.paid),
    payMethod: payment.forma,
    services: [],
    numDevice: 0,
    transactionType: 'refund'
  })
  if(res?.error) {
    noty.error(i18n.t('error_printing_cheque') + ': ' + res.error)
  }
  if(res?.data?.CheckNumber) {
    await PatientService.setUUIDForPayment(payment.id, { uuid: res.data.CheckNumber })
  }
}
</script>
