import { notificationStatuses, notificationTypes } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'notification',
    order: 'id',
    sort: 'desc',
    showTotalRecords: true,
    actionscomponent: 'MarketingNotificationAllDatatableActions',
    actions: [
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
            filter: true,
        },
        {
            id: 'recipient_id',
            title: 'recipient',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            filter: true,
            table_show: 'recipient.full_name',
        },
        {
            id: 'recipient_id',
            title: 'phone',
            table: true,
            sortable: false,
            filter: false,
            table_show: 'recipient.phone',
        },
        {
            id: 'text',
            title: 'text',
            table: true,
            filter: true,
            sortable: true,
        },
        {
            id: 'channelsLoaded',
            title: 'channel',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select_equals: true,
        },
        {
            id: 'typeTitle',
            title: 'type',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select_equals: true,
            form: {
                options: notificationTypes,
            },
        },
        {
            id: 'sent_at_timezoned',
            title: 'date',
            format_datetime: true,
            table: true,
            sortable: false,
            filter: true,
            filter_date: true,
            tdcomponent: 'MarketingDatatableTDNotificationDate',
        },
        {
            id: 'status',
            title: 'status',
            table: true,
            sortable: false,
            filter: true,
            filter_list_select_equals: true,
            form: {
                options: notificationStatuses,
            },
            tdcomponent: 'MarketingDatatableTDNotificationStatus',
        }
    ]
}
