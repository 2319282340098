<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M8.25244 77.0703L10.3362 78.3401H13.8199L15.9199 77.0703L14.3082 75.5238L16.392 73.9773L14.5036 72.4308L16.7989 70.7378L14.9757 69.1913L17.271 67.7913L15.2524 66.375L17.271 64.7634L15.578 63.2169L17.8082 61.8006L16.0501 60.3355L18.3454 58.789L16.1966 57.2425L18.3454 55.6308L16.7989 54.2797L18.8827 52.7332L17.0594 51.2518L19.1431 49.7053L17.3361 47.9634L18.3454 46.2867V32.0262L16.4571 24.7007H10.3362" fill="#8889BE"/>
      <path d="M15.6597 77.0703L13.576 78.3401H10.1086L7.99229 77.0703L9.6202 75.5238L7.5202 73.9773L9.40857 72.4308L7.1295 70.7378L8.93648 69.1913L6.65741 67.7913L8.67601 66.375L6.65741 64.7634L8.33415 63.2169L6.1202 61.8006L7.86206 60.3355L5.58299 58.789L7.73183 57.2425L5.58299 55.6308L7.1295 54.2797L5.04579 52.7332L6.85276 51.2518L4.76904 49.7053L6.59229 47.9634L5.58299 46.2867V32.0262L7.45508 24.7007H11.3621" fill="#8889BE"/>
      <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <!--        <path d="M19.7071 29.6027V34.8257L18.937 50.4805C18.937 50.4805 18.867 58.1119 18.937 63.965C18.8018 68.8043 18.2631 73.6239 17.3267 78.3736C17.3267 78.3736 15.7864 84.1567 14.9322 83.8486C14.0781 83.5406 13.938 80.306 13.938 80.306L10.0733 61.3465L7.76292 46.3078L6.26465 32.4172" fill="#DEE1E9"/>-->
      <!--        <path d="M1.36366 4.45414L3.212 26.0461C4.00303 28.5965 5.19919 31.003 6.75465 33.1734C7.23397 33.8888 7.85335 34.4996 8.57536 34.9689C9.29737 35.4382 10.107 35.7563 10.9554 35.9039C15.8843 36.366 16.9625 34.2096 19.427 30.2048C21.8914 26.2001 21.2473 11.8615 21.2473 11.8615L20.6312 2.6058C20.6312 2.6058 20.7852 1.58361 15.8563 1.4856C11.9873 1.50238 8.12358 1.77368 4.2902 2.29774C4.2902 2.29774 1.23764 2.98387 1.36366 4.45414Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
      <!--        <path opacity="0.2" d="M16.0663 26.6341C17.3826 27.7403 16.0663 31.9271 15.7723 40.9587C15.6182 45.0615 15.7723 48.4221 15.7723 50.5505C15.9543 55.7454 16.2484 56.5436 16.5004 63.2508C16.5844 65.6032 16.6544 68.3897 16.5004 71.9184C16.3604 74.5228 15.5363 79.0031 15.3543 79.0311C15.1722 79.0591 15.0861 72.1844 14.6661 69.1039C14.4 66.9195 14.358 65.3092 14.33 64.399C14.1836 60.7108 13.8471 57.0325 13.3218 53.379C12.4957 46.7698 12.0896 43.4652 10.9414 40.1886C9.79318 36.912 9.3451 33.6354 8.2529 30.4988C8.05686 29.8967 7.53877 28.4404 7.97285 27.6983C8.40693 26.9562 9.3731 27.5443 10.3533 27.6983C13.6019 28.3704 14.8901 25.7099 16.0663 26.6341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

      <path d="M19.5233 29.3958V34.6187L18.7531 50.2736C18.7531 50.2736 18.6831 57.905 18.7531 63.758C18.6179 68.5973 18.0793 73.4169 17.1428 78.1667C17.1428 78.1667 15.6025 83.9497 14.7484 83.6417C13.8942 83.3336 13.7542 80.099 13.7542 80.099L9.88951 61.1395L7.57908 46.1008L6.08081 32.2103" fill="#DEE1E9"/>
      <mask id="mask442" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask442)">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7568 15.7991C11.8188 15.7991 13.0645 10.5008 13.0645 0.902521L11.1414 -0.959547L8.06445 -2.20093C8.06445 7.3974 9.69467 15.7991 10.7568 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.4798 14.0429C16.6714 15.41 15.4798 20.5843 15.2135 31.7463C15.0741 36.8168 15.2135 40.9701 15.2135 43.6005C15.3783 50.0208 15.6445 51.0072 15.8727 59.2965C15.9488 62.2038 16.0122 65.6476 15.8727 70.0086C15.746 73.2274 14.9999 78.7644 14.8351 78.799C14.6703 78.8336 14.5924 70.3374 14.2121 66.5302C13.9712 63.8305 13.9332 61.8404 13.9079 60.7156C13.7753 56.1574 13.4707 51.6116 12.9951 47.0962C12.2472 38.9281 11.8796 34.844 10.8401 30.7945C9.80064 26.745 9.39498 22.6956 8.40621 18.8192C8.22874 18.075 7.7597 16.2753 8.15268 15.3581C8.54565 14.4409 9.42034 15.1677 10.3077 15.3581C13.2487 16.1887 14.4149 12.9007 15.4798 14.0429Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M19.7071 29.6027V34.8257L18.937 50.4805C18.937 50.4805 18.867 58.1119 18.937 63.965C18.8018 68.8043 18.2631 73.6239 17.3267 78.3736C17.3267 78.3736 15.7864 84.1567 14.9322 83.8486C14.0781 83.5406 13.938 80.306 13.938 80.306L10.0733 61.3465L7.76292 46.3078L6.26465 32.4172" fill="#DEE1E9"/>
      <path d="M1.36366 4.45414L3.212 26.0461C4.00303 28.5965 5.19919 31.003 6.75465 33.1734C7.23397 33.8888 7.85335 34.4996 8.57536 34.9689C9.29737 35.4382 10.107 35.7563 10.9554 35.9039C15.8843 36.366 16.9625 34.2096 19.427 30.2048C21.8914 26.2001 21.2473 11.8615 21.2473 11.8615L20.6312 2.6058C20.6312 2.6058 20.7852 1.58361 15.8563 1.4856C11.9873 1.50238 8.12358 1.77368 4.2902 2.29774C4.2902 2.29774 1.23764 2.98387 1.36366 4.45414Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M16.0663 26.6341C17.3826 27.7403 16.0663 31.9271 15.7723 40.9587C15.6182 45.0615 15.7723 48.4221 15.7723 50.5505C15.9543 55.7454 16.2484 56.5436 16.5004 63.2508C16.5844 65.6032 16.6544 68.3897 16.5004 71.9184C16.3604 74.5228 15.5363 79.0031 15.3543 79.0311C15.1722 79.0591 15.0861 72.1844 14.6661 69.1039C14.4 66.9195 14.358 65.3092 14.33 64.399C14.1836 60.7108 13.8471 57.0325 13.3218 53.379C12.4957 46.7698 12.0896 43.4652 10.9414 40.1886C9.79318 36.912 9.3451 33.6354 8.2529 30.4988C8.05686 29.8967 7.53877 28.4404 7.97285 27.6983C8.40693 26.9562 9.3731 27.5443 10.3533 27.6983C13.6019 28.3704 14.8901 25.7099 16.0663 26.6341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="81" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M18.5754 28.7998V34.0228L17.8053 49.6636C17.8053 49.6636 17.7213 57.295 17.8053 63.1481C17.6614 67.9921 17.1182 72.8161 16.181 77.5707C16.181 77.5707 14.6407 83.3538 13.7865 83.0317C12.9324 82.7097 12.7924 79.4891 12.7924 79.4891L8.94166 60.5296L6.63124 45.5049L5.06295 31.6144C3.78147 29.6324 2.77793 27.4839 2.08041 25.2292L0.232069 3.65124C0.106046 2.25099 3.1586 1.49485 3.1586 1.49485C6.9921 0.970065 10.8556 0.694109 14.7247 0.668701C19.6536 0.766719 19.4996 1.8029 19.4996 1.8029L20.1157 11.0446C20.1157 11.0446 20.6758 24.151 18.5754 28.7998Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M18.5754 28.7998V34.0228L17.8053 49.6636C17.8053 49.6636 17.7213 57.295 17.8053 63.1481C17.6614 67.9921 17.1182 72.8161 16.181 77.5707C16.181 77.5707 14.6407 83.3538 13.7865 83.0317C12.9324 82.7097 12.7924 79.4891 12.7924 79.4891L8.94166 60.5296L6.63124 45.5049L5.06295 31.6144C3.78147 29.6324 2.77793 27.4839 2.08041 25.2292L0.232069 3.65124C0.106046 2.25099 3.1586 1.49485 3.1586 1.49485C6.9921 0.970065 10.8556 0.694109 14.7247 0.668701C19.6536 0.766719 19.4996 1.8029 19.4996 1.8029L20.1157 11.0446C20.1157 11.0446 20.6758 24.151 18.5754 28.7998Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M14.1292 7.81957V2.59662L11.606 5L7.60596 3.5L4.60596 6L0.605957 3.5L2.18504 19.3017L4.49546 34.3404L8.36017 53.2999C8.36017 53.2999 8.50019 56.5345 9.35435 56.8425C10.2085 57.1506 11.7488 51.3675 11.7488 51.3675C12.6853 46.6178 13.2239 41.7982 13.3591 36.9589C13.2891 31.1058 13.3591 23.4744 13.3591 23.4744L14.1292 7.81957Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M10.1944 23.5443C10.1944 21.4159 10.0404 18.0553 10.1944 13.9526C10.437 6.49982 11.106 3 10.9569 2L9.60596 3.5L7.10596 2.5L4.60596 3.5L2.39497 2.19218C1.96089 2.93432 2.47898 4.39058 2.67502 4.99269C3.76722 8.12927 4.2153 9.90584 5.36351 13.1824C6.50099 16.4284 6.91014 19.7019 7.72089 26.1884L7.74394 26.3728C8.2692 30.0264 8.60571 33.7046 8.75213 37.3929C8.78013 38.303 8.82214 39.9133 9.08819 42.0977C9.50827 45.1783 9.59435 52.0529 9.77638 52.0249C9.95842 51.9969 10.7825 47.5167 10.9225 44.9122C11.0766 41.3836 11.0065 38.5971 10.9225 36.2447C10.6705 29.5374 10.3764 28.7393 10.1944 23.5443Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M1.75928 10.0111C6.63669 12.3435 8.97726 11.7259 12.5901 7.85048" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M19.5233 29.1171V34.3401L18.7531 49.9949C18.7531 49.9949 18.6831 57.6263 18.7531 63.4794C18.6179 68.3187 18.0793 73.1383 17.1428 77.888C17.1428 77.888 15.6025 83.6711 14.7484 83.363C13.8942 83.055 13.7542 79.8204 13.7542 79.8204L9.88951 60.8609L7.57908 45.8222L6.08081 31.9316" fill="#DEE1E9"/>
      <path d="M3.02816 25.5605L1.17982 3.96854C1.0538 2.49827 4.10636 1.81215 4.10636 1.81215C7.93975 1.28809 11.8035 1.01679 15.6725 1C16.0287 1.00708 16.3584 1.019 16.6635 1.035C16.7629 1.04022 16.7927 1.17103 16.7059 1.21966L15.3387 1.98553L14 5.02051L17.8091 6.53525L16.6015 8.84866L17.8091 11.3155L20 12.5205H21.1015C21.193 15.9978 21.2842 26.4026 19.2431 29.7192C16.7787 33.724 15.7005 35.8804 10.7716 35.4183C9.92319 35.2707 9.11353 34.9526 8.39152 34.4833C7.66951 34.014 7.05014 33.4032 6.57081 32.6878C5.01535 30.5174 3.81919 28.1109 3.02816 25.5605Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M19.77 19.1715C10.3697 16.4205 8.4753 12.6731 10.4194 2.5205" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M15.8825 26.1485C17.1987 27.2547 15.8825 31.4415 15.5884 40.4731C15.4344 44.5759 15.5884 47.9365 15.5884 50.0649C15.7705 55.2598 16.0645 56.058 16.3166 62.7652C16.4006 65.1176 16.4706 67.9041 16.3166 71.4328C16.1765 74.0372 15.3525 78.5175 15.1704 78.5455C14.9884 78.5735 14.9023 71.6988 14.4822 68.6183C14.2162 66.4339 14.1742 64.8236 14.1462 63.9134C13.9998 60.2252 13.6632 56.5469 13.138 52.8934C12.3118 46.2842 11.9058 42.9796 10.7576 39.703C9.60934 36.4264 9.16126 33.1498 8.06906 30.0132C7.87303 29.4111 7.35493 27.9548 7.78901 27.2127C8.22309 26.4706 9.18927 27.0587 10.1694 27.2127C13.418 27.8848 14.7063 25.2243 15.8825 26.1485Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M21 17.7494L19.5233 34.069L18.7531 49.7238C18.7531 49.7238 18.6831 57.3552 18.7531 63.2083C18.6179 68.0476 18.0793 72.8672 17.1428 77.6169C17.1428 77.6169 15.6025 83.4 14.7484 83.0919C13.8942 82.7839 13.7542 79.5493 13.7542 79.5493L9.88951 60.5898L7.57908 45.5511L3 16.7494" fill="#DEE1E9"/>
      <path d="M3.02816 25.2894L1.17982 3.69742C1.0538 2.22716 4.10636 1.54103 4.10636 1.54103C7.93975 1.01697 11.8035 0.745668 15.6725 0.728882C20.6014 0.8269 20.4473 1.84909 20.4473 1.84909L21.0635 11.1048C21.0635 11.1048 21.7076 25.4434 19.2431 29.4481C19.1432 29.6104 19.0456 29.7697 18.95 29.9259H17.3914C16.8834 29.9259 16.4561 29.545 16.3979 29.0404L15.5 21.2494C12.7759 20.4533 11.226 20.4518 8.5 21.2494L8.05431 28.9834C8.02382 29.5125 7.5859 29.9259 7.05597 29.9259H5C4.18884 28.4532 3.52771 26.9 3.02816 25.2894Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M21 18.5L19.5233 34.8196L18.7531 50.4744C18.7531 50.4744 18.6831 58.1058 18.7531 63.9589C18.6179 68.7982 18.0793 73.6178 17.1428 78.3675C17.1428 78.3675 15.6025 84.1506 14.7484 83.8425C13.8942 83.5345 13.7542 80.2999 13.7542 80.2999L9.88951 61.3404L7.57908 46.3017L3 17.5" fill="#DEE1E9"/>
      <path d="M3.02816 26.04L1.17982 4.44803C1.0538 2.97777 4.10636 2.29164 4.10636 2.29164C7.93975 1.76758 11.8035 1.49628 15.6725 1.47949C20.6014 1.57751 20.4473 2.5997 20.4473 2.5997L21.0635 11.8554C21.0635 11.8554 21.7076 26.194 19.2431 30.1987C19.1432 30.361 19.0456 30.5203 18.95 30.6765H17.3914C16.8834 30.6765 16.4561 30.2956 16.3979 29.791L15.5 22C12.7759 21.2039 11.226 21.2025 8.5 22L8.05431 29.734C8.02382 30.2631 7.5859 30.6765 7.05597 30.6765H5C4.18884 29.2038 3.52771 27.6506 3.02816 26.04Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M21 18.5L19.5233 34.8196L18.7531 50.4744C18.7531 50.4744 18.6831 58.1058 18.7531 63.9589C18.6179 68.7982 18.0793 73.6178 17.1428 78.3675C17.1428 78.3675 15.6025 84.1506 14.7484 83.8425C13.8942 83.5345 13.7542 80.2999 13.7542 80.2999L9.88951 61.3404L7.57908 46.3017L3 17.5" fill="#DEE1E9"/>
      <mask id="mask2042" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="31">
        <path d="M2.85214 25.5605L1.0038 3.96854C0.877774 2.49827 3.93033 1.81215 3.93033 1.81215C7.76372 1.28809 11.6274 1.01679 15.4964 1C20.4253 1.09802 20.2713 2.1202 20.2713 2.1202L20.8874 11.3759C20.8874 11.3759 21.5315 25.7145 19.0671 29.7192C18.9672 29.8815 18.8696 30.0408 18.7739 30.197H17.2153C16.7074 30.197 16.2801 29.8161 16.2219 29.3115L15.324 21.5205C12.5999 20.7244 11.05 20.723 8.32397 21.5205L7.87829 29.2545C7.8478 29.7836 7.40988 30.197 6.87994 30.197H4.82397C4.01282 28.7243 3.35169 27.1711 2.85214 25.5605Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2042)">
        <path d="M2.85214 25.5605L1.0038 3.96854C0.877774 2.49827 3.93033 1.81215 3.93033 1.81215C7.76372 1.28809 11.6274 1.01679 15.4964 1C20.4253 1.09802 20.2713 2.1202 20.2713 2.1202L20.8874 11.3759C20.8874 11.3759 21.5315 25.7145 19.0671 29.7192C18.9672 29.8815 18.8696 30.0408 18.7739 30.197H17.2153C16.7074 30.197 16.2801 29.8161 16.2219 29.3115L15.324 21.5205C12.5999 20.7244 11.05 20.723 8.32397 21.5205L7.87829 29.2545C7.8478 29.7836 7.40988 30.197 6.87994 30.197H4.82397C4.01282 28.7243 3.35169 27.1711 2.85214 25.5605Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M18.0752 45.317H5.0287V19.7002C10.1237 17.7687 12.9802 17.7491 18.0752 19.7002V45.317Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M19.8176 28.7642V33.9871L19.0474 49.642C19.0474 49.642 18.9774 57.2734 19.0474 63.1264C18.9122 67.9657 18.3736 72.7853 17.4371 77.5351C17.4371 77.5351 15.8969 83.3181 15.0427 83.0101C14.1885 82.702 14.0485 79.4674 14.0485 79.4674L10.1838 60.508L7.8734 45.4692L6.37512 31.5787" fill="#DEE1E9"/>
      <path d="M1.47413 3.61552L3.32247 25.2075C4.1135 27.7578 5.30966 30.1643 6.86512 32.3348C7.34445 33.0502 7.96383 33.661 8.68584 34.1303C9.40785 34.5996 10.2175 34.9176 11.0659 35.0653C15.9948 35.5273 17.073 33.371 19.5374 29.3662C22.0019 25.3615 21.3578 11.0229 21.3578 11.0229L20.7417 1.76718C20.7417 1.76718 20.8957 0.744991 15.9668 0.646973C12.0978 0.663758 8.23406 0.93506 4.40067 1.45912C4.40067 1.45912 1.34811 2.14525 1.47413 3.61552Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M16.1768 25.7954C17.493 26.9016 16.1768 31.0884 15.8827 40.12C15.7287 44.2228 15.8827 47.5834 15.8827 49.7118C16.0648 54.9067 16.3588 55.7049 16.6109 62.4121C16.6949 64.7645 16.7649 67.551 16.6109 71.0797C16.4709 73.6842 15.6468 78.1644 15.4647 78.1924C15.2827 78.2204 15.1966 71.3457 14.7765 68.2652C14.5105 66.0808 14.4685 64.4705 14.4405 63.5603C14.2941 59.8721 13.9576 56.1939 13.4323 52.5403C12.6061 45.9311 12.2001 42.6265 11.0519 39.3499C9.90365 36.0733 9.45557 32.7967 8.36337 29.6601C8.16734 29.058 7.64924 27.6017 8.08332 26.8596C8.5174 26.1175 9.48358 26.7056 10.4638 26.8596C13.7123 27.5317 15.0006 24.8712 16.1768 25.7954Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M19.1256 58.7877C15.5052 60.9512 13.4835 60.8526 9.88447 58.7877" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M19.5233 29.5967V34.8196L18.7531 50.4745C18.7531 50.4745 18.6831 58.1059 18.7531 63.959C18.6179 68.7983 18.0793 73.6178 17.1428 78.3676C17.1428 78.3676 15.6025 84.1506 14.7484 83.8426C13.8942 83.5345 13.7542 80.2999 13.7542 80.2999L9.88951 61.3405L7.57908 46.3017L6.08081 32.4112" fill="#DEE1E9"/>
      <mask id="mask2242" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2242)">
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7568 16C11.8188 16 13.0645 10.7018 13.0645 1.10345L11.1414 -0.758619L8.06445 -2C8.06445 7.59833 9.69467 16 10.7568 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.4798 14.2438C16.6714 15.6109 15.4798 20.7852 15.2135 31.9472C15.0741 37.0177 15.2135 41.171 15.2135 43.8014C15.3783 50.2217 15.6445 51.2082 15.8727 59.4974C15.9488 62.4048 16.0122 65.8485 15.8727 70.2095C15.746 73.4283 14.9999 78.9653 14.8351 78.9999C14.6703 79.0345 14.5924 70.5383 14.2121 66.7311C13.9712 64.0315 13.9332 62.0413 13.9079 60.9165C13.7753 56.3583 13.4707 51.8125 12.9951 47.2971C12.2472 39.129 11.8796 35.0449 10.8401 30.9954C9.80064 26.946 9.39498 22.8965 8.40621 19.0201C8.22874 18.276 7.7597 16.4762 8.15268 15.559C8.54565 14.6418 9.42034 15.3686 10.3077 15.559C13.2487 16.3897 14.4149 13.1016 15.4798 14.2438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="82.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M19.5233 29.5967V34.8196L18.7531 50.4745C18.7531 50.4745 18.6831 58.1059 18.7531 63.959C18.6179 68.7983 18.0793 73.6178 17.1428 78.3676C17.1428 78.3676 15.6025 84.1506 14.7484 83.8426C13.8942 83.5345 13.7542 80.2999 13.7542 80.2999L9.88951 61.3405L7.57908 46.3017L6.08081 32.4112" fill="#DEE1E9"/>
      <mask id="mask2342" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2342)">
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7568 16C11.8188 16 13.0645 10.7018 13.0645 1.10345L11.1414 -0.758619L8.06445 -2C8.06445 7.59833 9.69467 16 10.7568 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.4798 14.2438C16.6714 15.6109 15.4798 20.7852 15.2135 31.9472C15.0741 37.0177 15.2135 41.171 15.2135 43.8014C15.3783 50.2217 15.6445 51.2082 15.8727 59.4974C15.9488 62.4048 16.0122 65.8485 15.8727 70.2095C15.746 73.4283 14.9999 78.9653 14.8351 78.9999C14.6703 79.0345 14.5924 70.5383 14.2121 66.7311C13.9712 64.0315 13.9332 62.0413 13.9079 60.9165C13.7753 56.3583 13.4707 51.8125 12.9951 47.2971C12.2472 39.129 11.8796 35.0449 10.8401 30.9954C9.80064 26.946 9.39498 22.8965 8.40621 19.0201C8.22874 18.276 7.7597 16.4762 8.15268 15.559C8.54565 14.6418 9.42034 15.3686 10.3077 15.559C13.2487 16.3897 14.4149 13.1016 15.4798 14.2438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="82.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2342)">
        <path d="M15 78L15.8229 81.7391L18.8567 79.4037L17.0837 82.797L20.9088 82.9581L17.3694 84.4178L20.1962 87L16.5465 85.8431L17.0521 89.6382L15 86.406L12.9479 89.6382L13.4535 85.8431L9.80385 87L12.6306 84.4178L9.09115 82.9581L12.9163 82.797L11.1433 79.4037L14.1771 81.7391L15 78Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2342" x="8.89113" y="77.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M19.5233 29.5967V34.8196L18.7531 50.4745C18.7531 50.4745 18.6831 58.1059 18.7531 63.959C18.6179 68.7983 18.0793 73.6178 17.1428 78.3676C17.1428 78.3676 15.6025 84.1506 14.7484 83.8426C13.8942 83.5345 13.7542 80.2999 13.7542 80.2999L9.88951 61.3405L7.57908 46.3017L6.08081 32.4112" fill="#DEE1E9"/>
      <mask id="mask2342" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2342)">
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7568 16C11.8188 16 13.0645 10.7018 13.0645 1.10345L11.1414 -0.758619L8.06445 -2C8.06445 7.59833 9.69467 16 10.7568 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.4798 14.2438C16.6714 15.6109 15.4798 20.7852 15.2135 31.9472C15.0741 37.0177 15.2135 41.171 15.2135 43.8014C15.3783 50.2217 15.6445 51.2082 15.8727 59.4974C15.9488 62.4048 16.0122 65.8485 15.8727 70.2095C15.746 73.4283 14.9999 78.9653 14.8351 78.9999C14.6703 79.0345 14.5924 70.5383 14.2121 66.7311C13.9712 64.0315 13.9332 62.0413 13.9079 60.9165C13.7753 56.3583 13.4707 51.8125 12.9951 47.2971C12.2472 39.129 11.8796 35.0449 10.8401 30.9954C9.80064 26.946 9.39498 22.8965 8.40621 19.0201C8.22874 18.276 7.7597 16.4762 8.15268 15.559C8.54565 14.6418 9.42034 15.3686 10.3077 15.559C13.2487 16.3897 14.4149 13.1016 15.4798 14.2438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="82.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2342)">
        <path d="M15 78L15.8229 81.7391L18.8567 79.4037L17.0837 82.797L20.9088 82.9581L17.3694 84.4178L20.1962 87L16.5465 85.8431L17.0521 89.6382L15 86.406L12.9479 89.6382L13.4535 85.8431L9.80385 87L12.6306 84.4178L9.09115 82.9581L12.9163 82.797L11.1433 79.4037L14.1771 81.7391L15 78Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2342" x="8.89113" y="77.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M19.5233 29.3958V34.6187L18.7531 50.2736C18.7531 50.2736 18.6831 57.905 18.7531 63.758C18.6179 68.5973 18.0793 73.4169 17.1428 78.1667C17.1428 78.1667 15.6025 83.9497 14.7484 83.6417C13.8942 83.3336 13.7542 80.099 13.7542 80.099L9.88951 61.1395L7.57908 46.1008L6.08081 32.2103" fill="#DEE1E9"/>
      <mask id="mask2542" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2542)">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7568 15.7991C11.8188 15.7991 13.0645 10.5008 13.0645 0.902521L11.1414 -0.959547L8.06445 -2.20093C8.06445 7.3974 9.69467 15.7991 10.7568 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.4798 14.0429C16.6714 15.41 15.4798 20.5843 15.2135 31.7463C15.0741 36.8168 15.2135 40.9701 15.2135 43.6005C15.3783 50.0208 15.6445 51.0072 15.8727 59.2965C15.9488 62.2038 16.0122 65.6476 15.8727 70.0086C15.746 73.2274 14.9999 78.7644 14.8351 78.799C14.6703 78.8336 14.5924 70.3374 14.2121 66.5302C13.9712 63.8305 13.9332 61.8404 13.9079 60.7156C13.7753 56.1574 13.4707 51.6116 12.9951 47.0962C12.2472 38.9281 11.8796 34.844 10.8401 30.7945C9.80064 26.745 9.39498 22.6956 8.40621 18.8192C8.22874 18.075 7.7597 16.2753 8.15268 15.3581C8.54565 14.4409 9.42034 15.1677 10.3077 15.3581C13.2487 16.1887 14.4149 12.9007 15.4798 14.0429Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="82" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2642" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="84">
        <path d="M19.3472 29.1172V34.3401L18.5771 49.995C18.5771 49.995 18.5071 57.6264 18.5771 63.4795C18.4419 68.3188 17.9033 73.1383 16.9668 77.8881C16.9668 77.8881 15.4265 83.6712 14.5724 83.3631C13.7182 83.055 13.5782 79.8204 13.5782 79.8204L9.71348 60.861L7.40306 45.8222L5.90479 31.9317" fill="#DEE1E9"/>
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2642)">
        <path d="M19.3472 29.1172V34.3401L18.5771 49.995C18.5771 49.995 18.5071 57.6264 18.5771 63.4795C18.4419 68.3188 17.9033 73.1383 16.9668 77.8881C16.9668 77.8881 15.4265 83.6712 14.5724 83.3631C13.7182 83.055 13.5782 79.8204 13.5782 79.8204L9.71348 60.861L7.40306 45.8222L5.90479 31.9317" fill="#DEE1E9"/>
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.9741 21.6762H0.0529232C2.21498 27.046 2.57199 30.3063 2.27545 36.0446C2.26427 36.2609 2.51364 36.3955 2.70387 36.2689C3.70794 35.601 6.06614 33.7849 6.63778 31.1432C7.33025 27.9432 9.33345 27.1661 9.54606 27.092C9.55902 27.0875 9.57061 27.083 9.58299 27.0773L10.7659 26.5307C10.9105 26.4639 10.9691 26.3014 10.8968 26.1678L10.3679 25.1901C10.3231 25.1074 10.3272 25.0092 10.3787 24.9299L12.2251 22.0858C12.3421 21.9056 12.2016 21.6762 11.9741 21.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.04897 35.155C4.10995 34.3071 5.51793 32.8702 5.92035 31.0106C6.66356 27.5761 8.83953 26.6201 9.2725 26.4648L10.0461 26.1073L9.71349 25.4925C9.55687 25.203 9.57114 24.8596 9.75132 24.5821L11.1988 22.3524H1.09621C2.87294 27.0171 3.24894 30.2129 3.04897 35.155ZM9.25579 26.4725C9.25565 26.4726 9.25593 26.4724 9.25579 26.4725V26.4725ZM3.1313 36.8178C2.40244 37.3027 1.50671 36.7463 1.54464 36.0123C1.83745 30.3462 1.48824 27.18 -0.632782 21.9121L-1 21H11.9741C12.7703 21 13.2621 21.8028 12.8524 22.4338L11.1301 25.0869L11.5512 25.8654C11.8042 26.333 11.5991 26.9017 11.0931 27.1355L9.91021 27.6822C9.86949 27.701 9.83364 27.7145 9.80412 27.7248C9.76753 27.7376 7.98671 28.3577 7.35523 31.2758C6.72573 34.1849 4.16235 36.1319 3.1313 36.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask142" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="84">
        <path d="M19.3472 29.1172V34.3401L18.5771 49.995C18.5771 49.995 18.5071 57.6264 18.5771 63.4795C18.4419 68.3188 17.9033 73.1383 16.9668 77.8881C16.9668 77.8881 15.4265 83.6712 14.5724 83.3631C13.7182 83.055 13.5782 79.8204 13.5782 79.8204L9.71348 60.861L7.40306 45.8222L5.90479 31.9317" fill="#DEE1E9"/>
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask142)">
        <path d="M19.3472 29.1172V34.3401L18.5771 49.995C18.5771 49.995 18.5071 57.6264 18.5771 63.4795C18.4419 68.3188 17.9033 73.1383 16.9668 77.8881C16.9668 77.8881 15.4265 83.6712 14.5724 83.3631C13.7182 83.055 13.5782 79.8204 13.5782 79.8204L9.71348 60.861L7.40306 45.8222L5.90479 31.9317" fill="#DEE1E9"/>
        <path d="M1.0038 3.96854L2.85214 25.5605C3.64316 28.1109 4.83933 30.5174 6.39478 32.6878C6.87411 33.4032 7.49349 34.014 8.2155 34.4833C8.93751 34.9526 9.74716 35.2707 10.5955 35.4183C15.5244 35.8804 16.6026 33.724 19.0671 29.7192C21.5315 25.7145 20.8874 11.3759 20.8874 11.3759L20.2713 2.1202C20.2713 2.1202 20.4253 1.09802 15.4964 1C11.6274 1.01679 7.76372 1.28809 3.93033 1.81215C3.93033 1.81215 0.877774 2.49827 1.0038 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M9.86628 26.5H1.71952C3.19704 30.4705 3.44101 32.8811 3.23836 37.1241C3.23072 37.2841 3.40114 37.3836 3.53113 37.29C4.2173 36.7961 5.82886 35.4532 6.21952 33.5C6.69274 31.1339 8.0617 30.5593 8.20699 30.5045C8.21585 30.5012 8.22377 30.4978 8.23223 30.4936L9.04063 30.0894C9.13943 30.04 9.17947 29.9199 9.13008 29.8211L8.76862 29.0982C8.73804 29.037 8.74082 28.9645 8.776 28.9058L10.0378 26.8029C10.1178 26.6696 10.0217 26.5 9.86628 26.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76699 36.4664C4.49205 35.8394 5.45424 34.777 5.72925 33.4019C6.23715 30.8625 7.72418 30.1555 8.02006 30.0407L8.54872 29.7764L8.32143 29.3218C8.21439 29.1077 8.22415 28.8538 8.34728 28.6486L9.33644 27H2.4325C3.64669 30.4491 3.90365 32.8121 3.76699 36.4664ZM8.00864 30.0464C8.00854 30.0465 8.00874 30.0464 8.00864 30.0464V30.0464ZM3.82326 37.6958C3.32516 38.0544 2.71303 37.643 2.73895 37.1003C2.93905 32.9106 2.70041 30.5695 1.25093 26.6744L0.999982 26H9.8663C10.4104 26 10.7465 26.5936 10.4665 27.0601L9.2895 29.0219L9.57731 29.5975C9.7502 29.9433 9.61005 30.3638 9.26426 30.5367L8.45586 30.9409C8.42803 30.9548 8.40354 30.9648 8.38336 30.9724C8.35836 30.9818 7.14137 31.4403 6.70983 33.5981C6.27964 35.749 4.52786 37.1887 3.82326 37.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M19.5233 29.3958V34.6187L18.7531 50.2736C18.7531 50.2736 18.6831 57.905 18.7531 63.758C18.6179 68.5973 18.0793 73.4169 17.1428 78.1667C17.1428 78.1667 15.6025 83.9497 14.7484 83.6417C13.8942 83.3336 13.7542 80.099 13.7542 80.099L9.88951 61.1395L7.57908 46.1008L6.08081 32.2103" fill="#DEE1E9"/>
      <mask id="mask242" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask242)">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7568 15.7991C11.8188 15.7991 13.0645 10.5008 13.0645 0.902521L11.1414 -0.959547L8.06445 -2.20093C8.06445 7.3974 9.69467 15.7991 10.7568 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.4798 14.0429C16.6714 15.41 15.4798 20.5843 15.2135 31.7463C15.0741 36.8168 15.2135 40.9701 15.2135 43.6005C15.3783 50.0208 15.6445 51.0072 15.8727 59.2965C15.9488 62.2038 16.0122 65.6476 15.8727 70.0086C15.746 73.2274 14.9999 78.7644 14.8351 78.799C14.6703 78.8336 14.5924 70.3374 14.2121 66.5302C13.9712 63.8305 13.9332 61.8404 13.9079 60.7156C13.7753 56.1574 13.4707 51.6116 12.9951 47.0962C12.2472 38.9281 11.8796 34.844 10.8401 30.7945C9.80064 26.745 9.39498 22.6956 8.40621 18.8192C8.22874 18.075 7.7597 16.2753 8.15268 15.3581C8.54565 14.4409 9.42034 15.1677 10.3077 15.3581C13.2487 16.1887 14.4149 12.9007 15.4798 14.0429Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M19.5233 29.3958V34.6187L18.7531 50.2736C18.7531 50.2736 18.6831 57.905 18.7531 63.758C18.6179 68.5973 18.0793 73.4169 17.1428 78.1667C17.1428 78.1667 15.6025 83.9497 14.7484 83.6417C13.8942 83.3336 13.7542 80.099 13.7542 80.099L9.88951 61.1395L7.57908 46.1008L6.08081 32.2103" fill="#DEE1E9"/>
      <mask id="mask342" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="36">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask342)">
        <path d="M1.0038 3.76761L2.85214 25.3596C3.64316 27.9099 4.83933 30.3164 6.39478 32.4869C6.87411 33.2023 7.49349 33.8131 8.2155 34.2824C8.93751 34.7517 9.74716 35.0697 10.5955 35.2174C15.5244 35.6794 16.6026 33.5231 19.0671 29.5183C21.5315 25.5136 20.8874 11.175 20.8874 11.175L20.2713 1.91928C20.2713 1.91928 20.4253 0.89709 15.4964 0.799072C11.6274 0.815858 7.76372 1.08716 3.93033 1.61122C3.93033 1.61122 0.877774 2.29735 1.0038 3.76761Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7568 15.7991C11.8188 15.7991 13.0645 10.5008 13.0645 0.902521L11.1414 -0.959547L8.06445 -2.20093C8.06445 7.3974 9.69467 15.7991 10.7568 15.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M19.5233 29.1172V34.3401L18.7531 49.995C18.7531 49.995 18.6831 57.6264 18.7531 63.4795C18.6179 68.3188 18.0793 73.1383 17.1428 77.8881C17.1428 77.8881 15.6025 83.6712 14.7484 83.3631C13.8942 83.055 13.7542 79.8204 13.7542 79.8204L9.88951 60.861L7.57908 45.8222L6.08081 31.9317" fill="#DEE1E9"/>
      <path d="M1.17982 3.96854L3.02816 25.5605C3.81919 28.1109 5.01535 30.5174 6.57081 32.6878C7.05014 33.4032 7.66951 34.014 8.39152 34.4833C9.11353 34.9526 9.92319 35.2707 10.7716 35.4183C15.7005 35.8804 16.7787 33.724 19.2431 29.7192C21.7076 25.7145 21.0635 11.3759 21.0635 11.3759L20.4473 2.1202C20.4473 2.1202 20.6014 1.09802 15.6725 1C11.8035 1.01679 7.93975 1.28809 4.10636 1.81215C4.10636 1.81215 1.0538 2.49827 1.17982 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M14 1.52051L15.8667 4.02137L17.5 2.52566V5.83971L20.0622 5.2718L18.6667 7.0224L21 9.02309L18.6667 11.0238L20.0622 12.7744L17.7333 12.0241L17.5 15.5205L15.2501 14.0235L14.4667 15.0251L12.7499 14.0235L10.5 15.5205L9.8 13.5246L7.93782 12.7744L8.4 10.0234L7 9.02309L9.33333 7.52257L7.93782 5.2718L9.8 5.02171L10.5 2.52566L13.5333 4.52154L14 1.52051Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M19.5233 29.1172V34.3401L18.7531 49.995C18.7531 49.995 18.6831 57.6264 18.7531 63.4795C18.6179 68.3188 18.0793 73.1383 17.1428 77.8881C17.1428 77.8881 15.6025 83.6712 14.7484 83.3631C13.8942 83.055 13.7542 79.8204 13.7542 79.8204L9.88951 60.861L7.57908 45.8222L6.08081 31.9317" fill="#DEE1E9"/>
      <path d="M1.17982 3.96854L3.02816 25.5605C3.81919 28.1109 5.01535 30.5174 6.57081 32.6878C7.05014 33.4032 7.66951 34.014 8.39152 34.4833C9.11353 34.9526 9.92319 35.2707 10.7716 35.4183C15.7005 35.8804 16.7787 33.724 19.2431 29.7192C21.7076 25.7145 21.0635 11.3759 21.0635 11.3759L20.4473 2.1202C20.4473 2.1202 20.6014 1.09802 15.6725 1C11.8035 1.01679 7.93975 1.28809 4.10636 1.81215C4.10636 1.81215 1.0538 2.49827 1.17982 3.96854Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M15 1.52051L16.6 3.6641L18 2.38207V5.22268L20.1962 4.7359L19 6.23641L21 7.95129L19 9.66616L20.1962 11.1667L18.2 10.5236L18 13.5205L16.0715 12.2373L15.4 13.0959L13.9285 12.2373L12 13.5205L11.4 11.8098L9.80385 11.1667L10.2 8.80873L9 7.95129L11 6.66513L9.80385 4.7359L11.4 4.52154L12 2.38207L14.6 4.09282L15 1.52051Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M19.5233 29.5967V34.8196L18.7531 50.4745C18.7531 50.4745 18.6831 58.1059 18.7531 63.959C18.6179 68.7983 18.0793 73.6178 17.1428 78.3676C17.1428 78.3676 15.6025 84.1506 14.7484 83.8426C13.8942 83.5345 13.7542 80.2999 13.7542 80.2999L9.88951 61.3405L7.57908 46.3017L6.08081 32.4112" fill="#DEE1E9"/>
      <path d="M1.17982 4.44803L3.02816 26.04C3.81919 28.5904 5.01535 30.9969 6.57081 33.1673C7.05014 33.8827 7.66951 34.4935 8.39152 34.9628C9.11353 35.4321 9.92319 35.7502 10.7716 35.8978C15.7005 36.3599 16.7787 34.2035 19.2431 30.1987C21.7076 26.194 21.0635 11.8554 21.0635 11.8554L20.4473 2.5997C20.4473 2.5997 20.6014 1.57751 15.6725 1.47949C11.8035 1.49628 7.93975 1.76758 4.10636 2.29164C4.10636 2.29164 1.0538 2.97777 1.17982 4.44803Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M17.5 1L18.4333 2.25043L19.25 1.50258V3.1596L20.5311 2.87564L19.8333 3.75094L21 4.75129L19.8333 5.75163L20.5311 6.62693L19.3667 6.2518L19.25 8L18.125 7.25148L17.7333 7.75232L16.875 7.25148L15.75 8L15.4 7.00206L14.4689 6.62693L14.7 5.25146L14 4.75129L15.1667 4.00103L14.4689 2.87564L15.4 2.7506L15.75 1.50258L17.2667 2.50052L17.5 1Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M8.3194 74.6565L10.4031 75.9263H13.8868L15.9868 74.6565L14.3752 73.11L16.4589 71.5635L14.5705 70.017L16.8659 68.324L15.0426 66.7775L17.338 65.3775L15.3194 63.9612L17.338 62.3496L15.645 60.803L17.8752 59.3868L16.1171 57.9217L18.4124 56.3751L16.2636 54.8286L18.4124 53.217L16.8659 51.8659L18.9496 50.3193L17.1264 48.838L19.2101 47.2914L17.4031 45.5496L18.4124 43.8728V29.6124L16.524 22.2868H10.4031" fill="#8889BE"/>
      <path d="M8.3194 74.6565L10.4031 75.9263H13.8868L15.9868 74.6565L14.3752 73.11L16.4589 71.5635L14.5705 70.017L16.8659 68.324L15.0426 66.7775L17.338 65.3775L15.3194 63.9612L17.338 62.3496L15.645 60.803L17.8752 59.3868L16.1171 57.9217L18.4124 56.3751L16.2636 54.8286L18.4124 53.217L16.8659 51.8659L18.9496 50.3193L17.1264 48.838L19.2101 47.2914L17.4031 45.5496L18.4124 43.8728V29.6124L16.524 22.2868H10.4031" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M15.7267 74.6565L13.643 75.9263H10.1755L8.05925 74.6565L9.68715 73.11L7.58716 71.5635L9.47552 70.017L7.19646 68.324L9.00343 66.7775L6.72437 65.3775L8.74297 63.9612L6.72437 62.3496L8.40111 60.803L6.18716 59.3868L7.92902 57.9217L5.64995 56.3751L7.79878 54.8286L5.64995 53.217L7.19646 51.8659L5.11274 50.3193L6.91971 48.838L4.836 47.2914L6.65925 45.5496L5.64995 43.8728V29.6124L7.52204 22.2868H11.429" fill="#8889BE"/>
      <path d="M15.7267 74.6565L13.643 75.9263H10.1755L8.05925 74.6565L9.68715 73.11L7.58716 71.5635L9.47552 70.017L7.19646 68.324L9.00343 66.7775L6.72437 65.3775L8.74297 63.9612L6.72437 62.3496L8.40111 60.803L6.18716 59.3868L7.92902 57.9217L5.64995 56.3751L7.79878 54.8286L5.64995 53.217L7.19646 51.8659L5.11274 50.3193L6.91971 48.838L4.836 47.2914L6.65925 45.5496L5.64995 43.8728V29.6124L7.52204 22.2868H11.429" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask542" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="32">
        <path d="M2.85214 26.0317L1.0038 4.43973C0.877774 2.96947 3.93033 2.28334 3.93033 2.28334C7.76372 1.75928 11.6274 1.48798 15.4964 1.47119C20.4253 1.56921 20.2713 2.5914 20.2713 2.5914L20.8874 11.8471C20.8874 11.8471 21.5315 26.1857 19.0671 30.1904C18.9672 30.3527 18.8696 30.512 18.7739 30.6682H17.2153C16.7074 30.6682 16.2801 30.2873 16.2219 29.7827L15.324 21.9917C12.5999 21.1956 11.05 21.1942 8.32397 21.9917L7.87829 29.7257C7.8478 30.2548 7.40988 30.6682 6.87994 30.6682H4.82397C4.01282 29.1955 3.35169 27.6423 2.85214 26.0317Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask542)">
        <path d="M2.85214 26.0317L1.0038 4.43973C0.877774 2.96947 3.93033 2.28334 3.93033 2.28334C7.76372 1.75928 11.6274 1.48798 15.4964 1.47119C20.4253 1.56921 20.2713 2.5914 20.2713 2.5914L20.8874 11.8471C20.8874 11.8471 21.5315 26.1857 19.0671 30.1904C18.9672 30.3527 18.8696 30.512 18.7739 30.6682H17.2153C16.7074 30.6682 16.2801 30.2873 16.2219 29.7827L15.324 21.9917C12.5999 21.1956 11.05 21.1942 8.32397 21.9917L7.87829 29.7257C7.8478 30.2548 7.40988 30.6682 6.87994 30.6682H4.82397C4.01282 29.1955 3.35169 27.6423 2.85214 26.0317Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M18.0752 45.7881H5.0287V20.1713C10.1237 18.2398 12.9802 18.2202 18.0752 20.1713V45.7881Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M8.3194 73.948L10.4031 75.2178H13.8868L15.9868 73.948L14.3752 72.4015L16.4589 70.855L14.5705 69.3085L16.8659 67.6155L15.0426 66.069L17.338 64.669L15.3194 63.2527L17.338 61.6411L15.645 60.0945L17.8752 58.6783L16.1171 57.2132L18.4124 55.6667L16.2636 54.1201L18.4124 52.5085L16.8659 51.1574L18.9496 49.6109L17.1264 48.1295L19.2101 46.5829L17.4031 44.8411L18.4124 43.1644V28.9039L16.524 21.5784H10.4031" fill="#8889BE"/>
      <path d="M8.3194 73.948L10.4031 75.2178H13.8868L15.9868 73.948L14.3752 72.4015L16.4589 70.855L14.5705 69.3085L16.8659 67.6155L15.0426 66.069L17.338 64.669L15.3194 63.2527L17.338 61.6411L15.645 60.0945L17.8752 58.6783L16.1171 57.2132L18.4124 55.6667L16.2636 54.1201L18.4124 52.5085L16.8659 51.1574L18.9496 49.6109L17.1264 48.1295L19.2101 46.5829L17.4031 44.8411L18.4124 43.1644V28.9039L16.524 21.5784H10.4031" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M15.7267 73.948L13.643 75.2178H10.1755L8.05925 73.948L9.68715 72.4015L7.58716 70.855L9.47552 69.3085L7.19646 67.6155L9.00343 66.069L6.72437 64.669L8.74297 63.2527L6.72437 61.6411L8.40111 60.0945L6.18716 58.6783L7.92902 57.2132L5.64995 55.6667L7.79878 54.1201L5.64995 52.5085L7.19646 51.1574L5.11274 49.6109L6.91971 48.1295L4.836 46.5829L6.65925 44.8411L5.64995 43.1644V28.9039L7.52204 21.5784H11.429" fill="#8889BE"/>
      <path d="M15.7267 73.948L13.643 75.2178H10.1755L8.05925 73.948L9.68715 72.4015L7.58716 70.855L9.47552 69.3085L7.19646 67.6155L9.00343 66.069L6.72437 64.669L8.74297 63.2527L6.72437 61.6411L8.40111 60.0945L6.18716 58.6783L7.92902 57.2132L5.64995 55.6667L7.79878 54.1201L5.64995 52.5085L7.19646 51.1574L5.11274 49.6109L6.91971 48.1295L4.836 46.5829L6.65925 44.8411L5.64995 43.1644V28.9039L7.52204 21.5783H11.429" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask542" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="31">
        <path d="M2.85214 25.3232L1.0038 3.73124C0.877774 2.26097 3.93033 1.57484 3.93033 1.57484C7.76372 1.05078 11.6274 0.779481 15.4964 0.762695C20.4253 0.860713 20.2713 1.8829 20.2713 1.8829L20.8874 11.1386C20.8874 11.1386 21.5315 25.4772 19.0671 29.4819C18.9672 29.6442 18.8696 29.8035 18.7739 29.9597H17.2153C16.7074 29.9597 16.2801 29.5788 16.2219 29.0742L15.324 21.2832C12.5999 20.4871 11.05 20.4857 8.32397 21.2832L7.87829 29.0172C7.8478 29.5463 7.40988 29.9597 6.87994 29.9597H4.82397C4.01282 28.487 3.35169 26.9338 2.85214 25.3232Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask542)">
        <path d="M2.85214 25.3232L1.0038 3.73124C0.877774 2.26097 3.93033 1.57484 3.93033 1.57484C7.76372 1.05078 11.6274 0.779481 15.4964 0.762695C20.4253 0.860713 20.2713 1.8829 20.2713 1.8829L20.8874 11.1386C20.8874 11.1386 21.5315 25.4772 19.0671 29.4819C18.9672 29.6442 18.8696 29.8035 18.7739 29.9597H17.2153C16.7074 29.9597 16.2801 29.5788 16.2219 29.0742L15.324 21.2832C12.5999 20.4871 11.05 20.4857 8.32397 21.2832L7.87829 29.0172C7.8478 29.5463 7.40988 29.9597 6.87994 29.9597H4.82397C4.01282 28.487 3.35169 26.9338 2.85214 25.3232Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M0 0.259766H21V5.25977H0V0.259766Z" fill="#838AA2"/>
      <path d="M22 0.259766H43V5.25977H22V0.259766Z" fill="#838AA2"/>
      <path d="M30.824 21.1777L29.3472 37.4973L28.5771 53.1522C28.5771 53.1522 28.5071 60.7836 28.5771 66.6366C28.4419 71.4759 27.9033 76.2955 26.9668 81.0453C26.9668 81.0453 25.4265 86.8283 24.5724 86.5203C23.7182 86.2122 23.5782 82.9776 23.5782 82.9776L19.7135 64.0182L17.4031 48.9794L12.824 20.1777" fill="#DEE1E9"/>
      <path d="M12.8521 28.7177L11.0038 7.12577C10.8778 5.6555 13.9303 4.96938 13.9303 4.96938C17.7637 4.44531 21.6274 4.17401 25.4964 4.15723C30.4253 4.25524 30.2713 5.27743 30.2713 5.27743L30.8874 14.5331C30.8874 14.5331 31.5315 28.8717 29.0671 32.8765C28.9672 33.0388 28.8696 33.198 28.7739 33.3542H27.2153C26.7074 33.3542 26.2801 32.9734 26.2219 32.4687L25.324 24.6777C22.5999 23.8816 21.05 23.8802 18.324 24.6777L17.8783 32.4117C17.8478 32.9408 17.4099 33.3542 16.8799 33.3542H14.824C14.0128 31.8816 13.3517 30.3283 12.8521 28.7177Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M19.3658 29.2021V34.4251L18.5957 50.08C18.5957 50.08 18.5256 57.7114 18.5957 63.5644C18.4605 68.4037 17.9218 73.2233 16.9854 77.9731C16.9854 77.9731 15.4451 83.7561 14.5909 83.4481C13.7368 83.14 13.5967 79.9054 13.5967 79.9054L9.73204 60.9459L7.42161 45.9072L5.92334 32.0167" fill="#DEE1E9"/>
      <path d="M1.02235 4.0535L2.87069 25.6454C3.66172 28.1958 4.85788 30.6023 6.41334 32.7727C6.89267 33.4881 7.51204 34.0989 8.23405 34.5683C8.95606 35.0376 9.76572 35.3556 10.6141 35.5033C15.543 35.9653 16.6212 33.8089 19.0857 29.8042C21.5501 25.7995 20.906 11.4609 20.906 11.4609L20.2899 2.20517C20.2899 2.20517 20.4439 1.18298 15.515 1.08496C11.646 1.10175 7.78228 1.37305 3.94889 1.89711C3.94889 1.89711 0.896329 2.58323 1.02235 4.0535Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M15.725 26.2334C17.0413 27.3396 15.725 31.5264 15.431 40.558C15.2769 44.6608 15.431 48.0214 15.431 50.1498C15.613 55.3447 15.9071 56.1429 16.1591 62.8501C16.2431 65.2025 16.3131 67.989 16.1591 71.5177C16.0191 74.1221 15.195 78.6024 15.013 78.6304C14.8309 78.6584 14.7448 71.7837 14.3248 68.7032C14.0587 66.5188 14.0167 64.9085 13.9887 63.9983C13.8423 60.3101 13.5058 56.6318 12.9805 52.9783C12.1544 46.3691 11.7483 43.0645 10.6001 39.7879C9.45187 36.5113 9.00379 33.2347 7.91159 30.0981C7.71555 29.496 7.19746 28.0397 7.63154 27.2976C8.06562 26.5555 9.0318 27.1436 10.012 27.2976C13.2606 27.9697 14.5488 25.3092 15.725 26.2334Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M19.3658 29.2021V34.4251L18.5957 50.08C18.5957 50.08 18.5256 57.7114 18.5957 63.5644C18.4605 68.4037 17.9218 73.2233 16.9854 77.9731C16.9854 77.9731 15.4451 83.7561 14.5909 83.4481C13.7368 83.14 13.5967 79.9054 13.5967 79.9054L9.73204 60.9459L7.42161 45.9072L5.92334 32.0167" fill="#DEE1E9"/>
      <path d="M1.02235 4.0535L2.87069 25.6454C3.66172 28.1958 4.85788 30.6023 6.41334 32.7727C6.89267 33.4881 7.51204 34.0989 8.23405 34.5683C8.95606 35.0376 9.76572 35.3556 10.6141 35.5033C15.543 35.9653 16.6212 33.8089 19.0857 29.8042C21.5501 25.7995 20.906 11.4609 20.906 11.4609L20.2899 2.20517C20.2899 2.20517 20.4439 1.18298 15.515 1.08496C11.646 1.10175 7.78228 1.37305 3.94889 1.89711C3.94889 1.89711 0.896329 2.58323 1.02235 4.0535Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.4" d="M15.725 26.2334C17.0413 27.3396 15.725 31.5264 15.431 40.558C15.2769 44.6608 15.431 48.0214 15.431 50.1498C15.613 55.3447 15.9071 56.1429 16.1591 62.8501C16.2431 65.2025 16.3131 67.989 16.1591 71.5177C16.0191 74.1221 15.195 78.6024 15.013 78.6304C14.8309 78.6584 14.7448 71.7837 14.3248 68.7032C14.0587 66.5188 14.0167 64.9085 13.9887 63.9983C13.8423 60.3101 13.5058 56.6318 12.9805 52.9783C12.1544 46.3691 11.7483 43.0645 10.6001 39.7879C9.45187 36.5113 9.00379 33.2347 7.91159 30.0981C7.71555 29.496 7.19746 28.0397 7.63154 27.2976C8.06562 26.5555 9.0318 27.1436 10.012 27.2976C13.2606 27.9697 14.5488 25.3092 15.725 26.2334Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M19.3658 29.2021V34.4251L18.5957 50.08C18.5957 50.08 18.5256 57.7114 18.5957 63.5644C18.4605 68.4037 17.9218 73.2233 16.9854 77.9731C16.9854 77.9731 15.4451 83.7561 14.5909 83.4481C13.7368 83.14 13.5967 79.9054 13.5967 79.9054L9.73204 60.9459L7.42161 45.9072L5.92334 32.0167" fill="#DEE1E9"/>
      <path d="M1.02235 4.0535L2.87069 25.6454C3.66172 28.1958 4.85788 30.6023 6.41334 32.7727C6.89267 33.4881 7.51204 34.0989 8.23405 34.5683C8.95606 35.0376 9.76572 35.3556 10.6141 35.5033C15.543 35.9653 16.6212 33.8089 19.0857 29.8042C21.5501 25.7995 20.906 11.4609 20.906 11.4609L20.2899 2.20517C20.2899 2.20517 20.4439 1.18298 15.515 1.08496C11.646 1.10175 7.78228 1.37305 3.94889 1.89711C3.94889 1.89711 0.896329 2.58323 1.02235 4.0535Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask542" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="17" width="16" height="36">
        <path d="M18.1317 52.3335L10.0549 52.3333L5.33892 17.6365H20.9678L18.1317 52.3335Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask542)">
        <path opacity="0.2" d="M15.725 26.2334C17.0413 27.3396 15.725 31.5264 15.431 40.558C15.2769 44.6608 15.431 48.0214 15.431 50.1498C15.613 55.3447 15.9071 56.1429 16.1591 62.8501C16.2431 65.2025 16.3131 67.989 16.1591 71.5177C16.0191 74.1221 15.195 78.6024 15.013 78.6304C14.8309 78.6584 14.7448 71.7837 14.3248 68.7032C14.0587 66.5188 14.0167 64.9085 13.9887 63.9983C13.8423 60.3101 13.5058 56.6318 12.9805 52.9783C12.1544 46.3691 11.7483 43.0645 10.6001 39.7879C9.45187 36.5113 9.00379 33.2347 7.91159 30.0981C7.71555 29.496 7.19746 28.0397 7.63154 27.2976C8.06562 26.5555 9.0318 27.1436 10.012 27.2976C13.2606 27.9697 14.5488 25.3092 15.725 26.2334Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5042" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="52" width="10" height="29">
        <path d="M16.6126 78.53L15.9128 80.5649H14.5129L13.5248 79.265L8.99604 52.3337H17.7654L17.3949 64.2844L16.942 74.8555L16.6126 78.53Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5042)">
        <path opacity="0.4" d="M15.7251 26.2334C17.0413 27.3396 15.7251 31.5264 15.431 40.558C15.277 44.6608 15.431 48.0214 15.431 50.1498C15.6131 55.3447 15.9071 56.1429 16.1592 62.8501C16.2432 65.2025 16.3132 67.989 16.1592 71.5177C16.0191 74.1221 15.195 78.6024 15.013 78.6304C14.831 78.6584 14.7449 71.7837 14.3248 68.7032C14.0588 66.5188 14.0168 64.9085 13.9888 63.9983C13.8423 60.3101 13.5058 56.6318 12.9806 52.9783C12.1544 46.3691 11.7484 43.0645 10.6001 39.7879C9.45193 36.5113 9.00385 33.2347 7.91165 30.0981C7.71562 29.496 7.19752 28.0397 7.6316 27.2976C8.06568 26.5555 9.03186 27.1436 10.012 27.2976C13.2606 27.9697 14.5489 25.3092 15.7251 26.2334Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M18.5754 28.7998V34.0228L17.8053 49.6636C17.8053 49.6636 17.7213 57.295 17.8053 63.1481C17.6614 67.9921 17.1182 72.8161 16.181 77.5707C16.181 77.5707 14.6407 83.3538 13.7865 83.0317C12.9324 82.7097 12.7924 79.4891 12.7924 79.4891L8.94166 60.5296L6.63124 45.5049L5.06295 31.6144C3.78147 29.6324 2.77793 27.4839 2.08041 25.2292L0.232069 3.65124C0.106046 2.25099 3.1586 1.49485 3.1586 1.49485C6.9921 0.970065 10.8556 0.694109 14.7247 0.668701C19.6536 0.766719 19.4996 1.8029 19.4996 1.8029L20.1157 11.0446C20.1157 11.0446 20.6758 24.151 18.5754 28.7998Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M19.7071 28.6899V33.9128L18.937 49.5677C18.937 49.5677 18.867 57.1991 18.937 63.0522C18.8018 67.8915 18.2631 72.711 17.3267 77.4608C17.3267 77.4608 15.7864 83.2438 14.9322 82.9358C14.0781 82.6277 13.938 79.3931 13.938 79.3931L10.0733 60.4337L7.76292 45.3949L6.26465 31.5044" fill="#DEE1E9"/>
      <path d="M1.36366 3.5413L3.212 25.1332C4.00303 27.6836 5.19919 30.0901 6.75465 32.2605C7.23397 32.9759 7.85335 33.5867 8.57536 34.056C9.29737 34.5254 10.107 34.8434 10.9554 34.991C15.8843 35.4531 16.9625 33.2967 19.427 29.292C21.8914 25.2873 21.2473 10.9486 21.2473 10.9486L20.6312 1.69296C20.6312 1.69296 20.7852 0.670772 15.8563 0.572754C11.9873 0.58954 8.12358 0.860842 4.2902 1.3849C4.2902 1.3849 1.23764 2.07103 1.36366 3.5413Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth42",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
