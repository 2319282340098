<template>
  <div class="form-group">
    <div class="select-wrapper">
      <label class="form-label d-block" :data-content="label" v-if="showlabel">
        <span class="">{{ label }}</span>
        <span v-if="required"> *</span>
      </label>
      <label class="radio-group"
             v-for="(o, oind) in options"
             :key="'opt-'+o.id+'-'+label">
        <span>{{ translate ? $t(o.title) : o.title }}</span>
        <input type="radio"
               :disabled="readonly"
               :name="validationId"
               :checked="value == o.id"
               :class="{ 'gray-input': variant === 'gray' }"
               :value="valuekey ? o[valuekey] : o.id"
               :id="`radio-id-${validationId}-${oind}`"
               @input="handleInput"/>
        <span class="radiomark"></span>
      </label>
    </div>
    <ValidationError class="mt-1"
                     v-if="showErrors"
                     :validationId="validationId"/>
  </div>
</template>

<script>
import ValidationError from "@/components/form/ValidationError"
export default {
  name: "RadioGroup",
  components: {
    ValidationError
  },
  data() {
    return {
      content: this.value
    }
  },
  props: {
    label: {
      type: String
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    validationId: {},
    value: {},
    valuekey: {},
    options: {
      type: Array,
      required: true
    },
    showlabel: {
      type: Boolean,
      default: false
    },
    translate: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    variant: {
      type: String,
      default: 'gray'
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {},
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>
