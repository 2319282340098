<template>
  <span v-if="!isPatient && patientId">
    <router-link :to="{ name: 'PatientVisits', params: { id: patientId }}">
      {{ fullName }}
    </router-link>
  </span>
  <span v-else>{{ fullName }}</span>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "EgiszPatientsDatatableTDName",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    isPatient() {
      return this.$store.getters.isPatient
    },
    patient() {
      return this.object?.record?.patient || null
    },
    patientId() {
      return this.patient?.id || null
    },
    fullName() {
      if(this.patient && this.patient.patient_name) return this.patient.patient_name
      if(this.patient && this.patient.full_name) return this.patient.full_name
      return ''
    }
  },
}
</script>
