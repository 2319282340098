<template>
  <div class="d-record__resize" />
</template>

<script>
export default {
  name: "DRecordResize"
}
</script>

<style scoped>
.d-record__resize {
  left: 0;
  right: 0;
  bottom: 0;
  height: 5px;
  cursor: ns-resize;
  position: absolute;
  background-color: transparent;
}
</style>
