<template>
  <div class="position-relative">
    <a ref="rangeBtn"
       href="#"
       @click="showPopup"
       class="mr-3 current-view-range text-capitalize">
      {{ professionString }} <Arrow />
    </a>
    <transition name="fade" mode="out-in">
      <div v-if="popupShowed">
        <div class="view-range-selector view-dd-selector position-absolute"
             v-closable="{
            exclude: ['rangeBtn'],
            handler: 'onClose'
        }">
<!--          <a href="#"-->
<!--             class="text-capitalize"-->
<!--             @click="professionSelected('all')">-->
<!--            {{ $t('all') }}-->
<!--          </a>-->
          <a href="#"
             size="sm"
             class="text-capitalize"
             @click="professionSelected('all')">
          <CheckboxGroup fid="all-prof-check"
                         :label="$t('all')"
                         ref="checkbox"
                         class="mb-0 sm mt-1 user-select-none"
                         v-model="noneSelected"/>
          </a>
          <a href="#"
             size="sm"
             class="text-capitalize"
             v-for="p in professions"
             :key="`prof-${p.doctors_profession_id}`"
             @click="professionSelected(p)">
            <CheckboxGroup :fid="`${p.doctors_profession_id}-prof-check`"
                           :checkboxvalue="p.doctors_profession_id"
                           :label="p.title"
                           ref="checkbox"
                           class="mb-0 sm mt-1 user-select-none"
                           v-model="selectedProfessionIds"/>
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapState} from "vuex"
import Arrow from '@/assets/svg-vue/calendar/arrow.svg'
import {Closable} from "@/components/pages/calendar/calendar/directives/CloseOutside";
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"

export default {
  name: "DProfessionSwitcher",
  directives: {
    Closable
  },
  components: {
    CheckboxGroup,
    Arrow
  },
  computed: {
    ...mapState({
      professions: state => state.auth.professions,
      selectedProfessions: state => state.calendar.selectedProfessions
    }),
    professionString() {
      if(this.noneSelected) return this.$t('all')
      return this.selectedProfessions.map(x => x.title).join(', ')
    },
    allSelected() {
      return (!this.selectedProfessions || !this.selectedProfessions.length || this.selectedProfessions.length === this.professions.length)
    },
    noneSelected() {
      return !this.selectedProfessions.length
    },
    selectedProfessionIds() {
      return this.selectedProfessions.map(x => x.doctors_profession_id)
    }
  },
  data() {
    return {
      popupShowed: false,
    }
  },
  methods: {
    showPopup() {
      this.popupShowed = !this.popupShowed
    },
    onClose() {
      this.popupShowed = false
    },
    professionSelected(profession) {
      let professions = this.copyObject(this.selectedProfessions)
      if(profession === 'all') {
        // professions = this.selectedProfessions.length === this.professions.length ? [] : this.copyObject(this.professions)
        professions = []
      } else {
        const index = this.selectedProfessionIds.findIndex(x => x === profession.doctors_profession_id)
        if(index === -1) {
          professions.push(profession)
        } else {
          professions.splice(index, 1)
        }
      }
      this.$store.commit('setSelectedProfessions', professions)
    }
  }
}
</script>

<style scoped lang="scss">
.view-range-selector {
  top: 30px;
  min-width: 150px;
  padding: 10px 0;
  left: 0;
}
.view-range-selector a {
  font-size: 16px;
  display: block;
  padding: 9px 20px;
  color: #5D6D7C;
}
.current-view-range {
  max-width: 120px;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}
.view-range-selector a:hover {
  background-color: #F3F3F3;
  text-decoration: none;
}
a {
  text-transform: lowercase;
}
@media screen and (max-width: 768px) {
  .view-range-selector {
    top: 25px;
    padding: 7px 0;
    a {
      font-size: 13px;
      padding: 5px 15px;
    }
  }
}
</style>
