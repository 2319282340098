export default {
    add_to_egisz: "Add to EGISZ",
    comment_to_deal: "Comment to deal",
    diagnosis_comments: "Diagnosis comments",
    download_all: "Download all",
    egisz: "EGISZ",
    for_rework: "To rework",
    funnel: "Kanban",
    list: "List",
    main_doctor: "Chief physician",
    patient_rejected_to_send_to_egisz: "Patient rejected to send data to EGISZ",
    prepare_document_for_sending_to_egisz: "Prepare document for sending to EGISZ?",
    processing_status: "Processing status",
    requires_attention: "Requires attention",
    representative_relation: "Who is the representative to the patient",
    send_all: "Send all",
    send_to_egisz: "Send to EGISZ",
    sent_to_egisz: "Sent to EGISZ",
    sign: "Sign",
    sign_all: "Sign all",
    signed_by_doctor: "Signed by doctor",
    signed_by_main_doctor: "Signed by main doctor",
    show_more: "Show more",
    show_only_not_sent: "Show only not sent",
    to_egisz_page: "To EGISZ page",
    to_rework: "To rework",
    to_sign: "Send to signature",
}
