<template>
  <div class="alert alert-warning">{{  $t('in_development')  }}</div>
</template>

<script>

export default {
  name: "PatientClientService",
  head() {
    return {
      title: this.$t('client_service'),
    }
  },
}
</script>
