<template>
  <div>
    <SelectGroup :options="selectOptions"
                 variant="white"
                 :title="null"
                 :label="object.operation_type == 'movement_from' ? $t('from_where') : $t('warehouse')"
                 :translate="false"
                 :required="true"
                 :showlabel="true"
                 v-model="object.warehouse_id"/>
    <ValidationError validation-id="warehouse_id"/>
    <SelectGroup :options="selectOptions" v-if="selectOptions.length > 0 && type == 'movement_from'"
                 variant="white"
                 :title="null"
                 :required="true"
                 :label="$t(field.title)"
                 :translate="false"
                 :showlabel="true"
                 v-model="object.related_warehouse_id"/>
  </div>
</template>

<script>
import SelectGroup from "@/components/form/SelectGroup"
import ValidationError from "@/components/form/ValidationError"

export default {
  name: "WarehouseDatatableFormDestinationWarehouse",
  components: {
    ValidationError,
    SelectGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  methods: {
  },
  mounted() {
  },
  computed: {
    relatedWarehouse() {
      return this.object.related_warehouse_id
    },
    mainWarehouse() {
      return this.object.warehouse_id
    },
    type() {
      return this.object.operation_type
    }
  },
  watch: {
    relatedWarehouse(val) {
      if(val && val == this.object.warehouse_id) {
        this.object.related_warehouse_id = null
      }
    },
    mainWarehouse(val) {
      if(this.object.related_warehouse_id && this.object.related_warehouse_id == val) {
        this.object.related_warehouse_id = null
      }
    },
    type(val) {
      if(val != 'movement_from') {
        this.object.related_warehouse_id = null
      }
    }
  }
}
</script>

<style scoped>

</style>
