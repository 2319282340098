export default {
    primaryKey: "id",
    model: 'crm_stage',
    order: 'sort_order',
    sort: 'asc',
    modaltitle: 'stage',
    // deletemessage: 'are_you_sure_you_want_to_delete_task',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    deleteCondition(object) {
        return object.clinic_id
    },
    editCondition(object) {
        return object.clinic_id || [2,3].includes(object.id)
    },
    fields: [
        {
            id: 'title',
            title: 'title',
            table_show: 'translatedTitle',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                conditionFunc(store, object) {
                    return !(object.id && !object.clinic_id)
                }
            },
            tdcomponent: 'CRMDatatableTDCRMStageTitle',
        },
        {
            id: 'color',
            title: 'color',
            table: false,
            form: {
                type: "color",
                required: true,
                conditionFunc(store, object) {
                    return !(object.id && !object.clinic_id)
                }
            }
        },
        {
            id: 'custom_data_sort_order',
            title: 'sort_order',
            table: true,
            // sortable: true,
            form: {
                type: "number",
                note: "higher_number_higher_priority",
                conditionFunc(store, object) {
                    return !(object.id && !object.clinic_id)
                }
            },
            format_number: true
        },
        {
            id: 'custom_data_active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]

}
