<template><svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<ellipse cx="13" cy="13.5" rx="13" ry="13.5" fill="#E5E5E5"/>
<g clip-path="url(#clip0)">
<path d="M14.3 5.4H11.7C10.2664 5.4 9.10002 6.61124 9.10002 8.1V11.475H8.45002C7.73306 11.475 7.15002 12.0805 7.15002 12.825V20.25C7.15002 20.9945 7.73306 21.6 8.45002 21.6H17.55C18.267 21.6 18.85 20.9945 18.85 20.25V12.825C18.85 12.0805 18.267 11.475 17.55 11.475H16.9V8.1C16.9 6.61124 15.7336 5.4 14.3 5.4ZM10.4 8.1C10.4 7.35546 10.9831 6.75 11.7 6.75H14.3C15.017 6.75 15.6 7.35546 15.6 8.1V11.475H10.4V8.1ZM17.55 20.25H8.45002L8.44939 12.825H17.55V20.25Z" fill="#5D6D7C"/>
<path d="M13 13.8375C11.9245 13.8375 11.05 14.7457 11.05 15.8625C11.05 16.7423 11.5937 17.4907 12.35 17.7693V18.5625C12.35 18.9351 12.6412 19.2375 13 19.2375C13.3588 19.2375 13.65 18.9351 13.65 18.5625V17.7693C14.4063 17.4907 14.95 16.7423 14.95 15.8625C14.95 14.7457 14.0754 13.8375 13 13.8375V13.8375ZM13 16.5375C12.6415 16.5375 12.35 16.2348 12.35 15.8625C12.35 15.4902 12.6415 15.1875 13 15.1875C13.3585 15.1875 13.65 15.4902 13.65 15.8625C13.65 16.2348 13.3585 16.5375 13 16.5375Z" fill="#5D6D7C"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="15.6" height="16.2" fill="white" transform="translate(5.20001 5.4)"/>
</clipPath>
</defs>
</svg>
</template>
