<template>
  <div class="clinic-control-system page pb-5 pt-4" style="max-width: 450px;">
    <CheckboxGroup :label="$t('record_timing_enabled')"
                   :checkboxvalue="1"
                   fid="record_timing_enabled"
                   v-model="form.record_timing_enabled"/>
    <div v-if="form.record_timing_enabled">
      <div class="mb-4 mt-5">{{ $t('required_fields_to_close_visit') }}:</div>
      <CheckboxGroup v-for="(f, find) in fields"
                     :label="$t(f.title)"
                     :key="`f-${find}`"
                     :checkboxvalue="1"
                     :readonly="(f.requires && clinic[f.requires] === 0)"
                     :fid="f.id"
                     v-model="form[f.id]"/>
    </div>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { EloquentService } from "@/services/api.service"

export default {
  name: "SettingsControlSystem",
  head() {
    return {
      title: this.$t('clinic_data'),
    }
  },
  components: {
    CheckboxGroup,
  },
  data() {
    return {
      form: {},
      fields: [
        { id: 'close_record_requires_services', title: 'services' },
        { id: 'close_record_requires_diagnoses', title: 'diagnoses' },
        { id: 'close_record_requires_complaints', title: 'complaints' },
        { id: 'close_record_requires_inspection', title: 'inspection' },
        { id: 'close_record_requires_objectively', title: 'objectively' },
        { id: 'close_record_requires_subjectively', title: 'subjectively' },
        { id: 'close_record_requires_diseases', title: 'diseases' },
        { id: 'close_record_requires_development', title: 'development' },
        // { id: 'close_record_requires_life_anamnez', title: 'life_anamnez' },
        { id: 'close_record_requires_bite', title: 'bite' },
        { id: 'close_record_requires_research', title: 'research' },
        { id: 'close_record_requires_plansurvey', title: 'plansurvey', requires: 'show_teeth_general' },
        { id: 'close_record_requires_medication', title: 'medication', requires: 'show_teeth_general' },
        { id: 'close_record_requires_recommendations', title: 'recommendations', requires: 'show_teeth_general' },
      ]
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
    }),
    additional() {
      return this.clinic.clinic_additional
    },
  },
  methods: {
    async save() {
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      await EloquentService.update('clinic_additional', this.clinic.id, formData)
      await this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
  },
  mounted() {
    this.form = this.copyObject(this.additional)
  }
}
</script>
