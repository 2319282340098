const state = {
    sidebarCollapsed: true,
    loading: false,
    isMobile: false,
    rightPaneOpened: false,
    phoneForJetApi: null,
    backLink: null,
    spaceduckCollapse: {}
}

const getters = {

}

const mutations = {
    setLoading(state, loading) {
        state.loading = loading
    },
    toggleCollapsed(state) {
        state.sidebarCollapsed = !state.sidebarCollapsed
    },
    setSidebarCollapsed(state, collapsed) {
        state.sidebarCollapsed = collapsed
    },
    updateMobile(state) {
        state.isMobile = window && window.innerWidth <= 768
    },
    setBackLink(state, link) {
        state.backLink = link
    },
    setSpaceduckCollapse(state, data) {
        state.spaceduckCollapse[data.key] = data.value
    },
    toggleSpaceduckCollapse(state, key) {
        state.spaceduckCollapse[key] = !state.spaceduckCollapse[key]
    },
    removeSpaceduckCollapse(state, key) {
        delete state.spaceduckCollapse[key]
    },
    setRightPaneOpened(state, value) {
        state.rightPaneOpened = value
    },
    setPhoneForJetApi(state, value) {
        state.phoneForJetApi = value
    }
}

const actions = {

};

export default {
    state,
    actions,
    mutations,
    getters
}
