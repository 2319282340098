<template>
  <div>
    <b-row>
      <b-col md="6" cols="12" v-if="visit.author">
        <PatientCol label="author"
                    :title="visit.author.full_name"/>
      </b-col>
      <b-col md="6" cols="12" v-if="created_at">
        <PatientCol label="creation_date"
                    :title="created_at"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import PatientCol from "@/components/parts/patients/PatientCol"
import moment from "moment"

export default {
  name: "VisitModalInfoPart",
  components: {
    PatientCol
  },
  props: {
    visit: {}
  },
  computed: {
    created_at() {
      return this.visit.created_at ? moment(this.visit.created_at).format('DD.MM.YYYY, HH:mm')  : ''
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped lang="scss">


@media screen and (max-width: 768px) {

}
</style>
