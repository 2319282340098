<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path d="M11.0494 5.51652L9.79692 5.47058L9.52539 12.8857L10.7778 12.9315L11.0494 5.51652Z" fill="#B5C6D8"/>
    <path d="M8.62666 5.49358H7.37341V12.9087H8.62666V5.49358Z" fill="#B5C6D8"/>
    <path d="M6.47465 12.8855L6.20312 5.47032L4.95068 5.51629L5.22225 12.9314L6.47465 12.8855Z" fill="#B5C6D8"/>
    <path
        d="M0.543213 2.40215V3.6554H1.84912L2.88512 15.4284C2.91353 15.7519 3.18446 16.0001 3.50924 16.0001H12.4699C12.7947 16.0001 13.0658 15.7517 13.094 15.4281L14.13 3.6554H15.457V2.40215H0.543213ZM11.8959 14.7468H4.08306L3.10718 3.6554H12.872L11.8959 14.7468Z"
        fill="#B5C6D8"/>
    <path
        d="M10.0471 0H5.95306C5.37719 0 4.90869 0.4685 4.90869 1.04437V3.02872H6.16194V1.25325H9.83818V3.02872H11.0914V1.04437C11.0914 0.4685 10.6229 0 10.0471 0Z"
        fill="#B5C6D8"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
