<template>
  <section id="planning" class="planning">
    <div>
      <transition name="fade" mode="out-in" >
        <div class="section-top-row">
          <div class="row row-narrow align-items-center no-print position-relative">
            <div class="col order-md-0 order-0">
              <h1>{{ $t('calls_planning') }}</h1>
            </div>
            <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
              <SearchTable/>
            </div>
            <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
              <DataTableFilterButton/>
            </div>
            <div class="col-auto order-md-4 order-1 table-view-navigator">
              <DataTableExcel/>
            </div>
            <div class="col-auto order-md-5 order-2 table-view-navigator" v-if="canAdd">
              <AddObject module="planning"/>
            </div>
          </div>
        </div>
      </transition>
      <div class="sub-menu no-print">
        <template v-for="(m, mind) in menu">
          <router-link :to="{ name: m.to }"
                       v-if="!m.right || rights.includes(m.right)"
                       :key="`planning-link-${mind}}`">
            {{ $t(m.title) }}
          </router-link>
        </template>
      </div>
    </div>
    <router-view/>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import {mapState} from "vuex"

export default {
  name: "PlanningGeneral",
  components: {
    DataTableFilterButton,
    AddObject,
    SearchTable,
    DataTableExcel
  },
  data() {
    return {
      menu: [
        { to: 'CallPlanning', title: 'calls' },
        { to: 'CallVisits', title: 'visits' },
      ]
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights
    }),
    canAdd() {
      return true
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
