<template>
  <div>
    <a @click="editPlanTreatment" class="tdclickable">
      {{ object.message }}
    </a>
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDTreatmentMessage",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    editPlanTreatment() {
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$bvModal.show('plan-treatment-modal')
    }
  }
}
</script>

<style scoped>

</style>
