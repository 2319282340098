<template>
  <b-modal id="patients-send-message-modal"
           @hide="onHide"
           @show="onShow"
           :title="`${$t('send_message')}`"
           centered>
    <b-row>
      <b-col>
        <div>
          <div>
            <label class="form-label">
              <span>{{ $t('channel') }} *</span>
            </label>
          </div>
          <CheckboxGroup v-for="n in channels"
                         class="d-inline-block mr-4"
                         :fid="`tag-${n.id}`"
                         :key="`ch-${n.id}`"
                         :checkboxvalue="n.id"
                         v-model="channel"
                         :label="n.title"/>
        </div>
        <ValidationError validation-id="channel"/>
      </b-col>
    </b-row>
    <div class="mt-4">
      <label class="form-label">
        <span>{{ $t('message') }} *</span>
      </label>
      <TemplateGroup v-model="message"
                     :dictation="true"
                     :label="$t('message')"
                     :saveAsTemplate="true"
                     model="sms_template"/>
    </div>
    <TagsList type="custom_notifications"
              class="mt-0">
      {{ $t('tags_for_custom_notifications') }}
    </TagsList>
    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-row class="align-items-center">
          <b-col md cols="12">
            {{ $t('symbols_amount') }}: {{ message.length }}
          </b-col>
          <b-col md="auto"
                 cols="12"
                 class="mt-md-0 mt-3 text-right">
            <div class="modal-footer-buttons">
              <b-button class="cancel mr-3 d-inline-block align-middle"
                        variant="themed"
                        @click="close()">{{ $t('cancel') }}</b-button>
              <button @click="send"
                      :disabled="message.length < 1 || !channel"
                      class="w-auto px-3 btn-themed align-middle btn-themed-squared">
                {{ $t('send') }}
              </button>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import TemplateGroup from "@/components/form/TemplateGroup"
import { MarketingService, SendService } from "@/services/api.service"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import ValidationError from "@/components/form/ValidationError.vue"
import TagsList from "@/components/parts/crm/TagsList.vue"

export default {
  name: "PatientsSendMessageModal",
  components: {
    TagsList,
    ValidationError,
    CheckboxGroup,
    TemplateGroup
  },
  props: {
    selected: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      message: '',
      channel: null,
      channels: [],
      // perMessage: 70
    }
  },
  methods: {
    async send() {
      this.$store.commit('cleanValidationErrors')
      const res = await SendService.sendBulkMessage({
        selected: this.selected,
        channel: this.channel,
        message: this.message
      })
      if(res.data.success) {
        this.$noty.info(this.$t('success_message'))
        this.message = ''
        this.$bvModal.hide('patients-send-message-modal')
        this.$emit('cleanSelected')
      } else {
        this.$noty.error(res.data.message ? res.data.message : (res.data.error ? res.data.error : this.$t('server_error')))
      }
    },
    onHide() {
      this.message = ''
      this.channel = null
    },
    async onShow() {
      this.$store.commit('cleanValidationErrors')
      this.message = ''
      this.channel = null
      const res = await MarketingService.getNotificationChannels()
      // this.channels = res.data.filter(x => ![3].includes(x.id))
      this.channels = res.data.filter(x => ![2,3].includes(x.id))
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      clinic:  state => state.auth.clinic,
    }),
  },
}
</script>
