<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportStatDoctors",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('doctor_statistics'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_date: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'hours',
          field: 'hours',
          label: this.$t('worked_hours'),
          sortable: true,
          totals: true
        },
        {
          id: 'visits',
          field: 'visits',
          label: this.$t('visits'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'revenue',
          field: 'revenue',
          label: this.$t('revenue'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'average_cheque',
          field: 'average_cheque',
          label: this.$t('average_cheque'),
          sortable: true,
          format_number: true,
          totals: true,
          totalscomponent: 'ReportStatDoctorsDatatableHtmlChequeTotal'
        },
        {
          id: 'revenue_per_hour',
          field: 'revenue_per_hour',
          label: this.$t('revenue_per_hour'),
          sortable: true,
          format_number: true,
          totals: true,
          totalscomponent: 'ReportStatDoctorsDatatableHtmlPerHourTotal'
        },
        {
          id: 'salary_percent',
          field: 'salary_percent',
          label: this.$t('doctor_salary'),
          sortable: true,
          format_number: true,
          totals: true
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('doctor_statistics', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctors: this.doctors.map(a => a.id),
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped>

</style>
