export const complexServiceAdapter =
    ({
         title,
         services
     }) => ({
        title: title ?? null,
        services: services.map(s => ({
            service_id: s.id,
            amount: s.pivot.amount
        }))
    })
