<template>
  <div>
    <div class="text-right">
      <a @click="add" class="btn btn-themed-squared btn-themed-outline">{{ $t('add') }}</a>
    </div>
    <DataTable :options="options"
               class="mt-4"
               module="crm"/>
  </div>
</template>

<script setup>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/crm/crm_stage"
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, onMounted, ref } from "vue"
import { useStore } from "vuex"

const store = useStore()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('stages')
})

onMounted(() => {
  store.commit('setEloquentOptions', options)
})

const add = () => {
  store.commit('setModule', 'crm')
  store.commit('setSelectedModel', {})
  store.commit('setShowModal', true)
}
</script>
