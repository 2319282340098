<template>
  <div>
    <DataTable :options="options"
               module="services"/>
  </div>
</template>

<script>

import DataTable from "../../parts/general/DataTable"
import options from "../../../data/services/price"

export default {
  name: "ServicePrices",
  head() {
    return {
      title: this.$t('prices_price'),
    }
  },
  components: {
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
