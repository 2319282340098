<template>
  <div>
    <div class="mb-3" v-if="ppoData.vendor === 'checkbox'">
      <button class="btn btn-themed btn-themed-squared btn-themed-outline" @click="openShift">
        {{ $t('open_shift') }}
      </button>
    </div>
    <DataTableHtml :columns="columns"
                   v-if="loaded"
                   :key="`dt-${counter}`"
                   primaryKey="none"
                   initialOrder="serial"
                   initialSort="desc"
                   :rows="shifts"/>
  </div>
</template>

<script>

import { mapState } from "vuex"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { CashdeskService } from "@/services/api.service"

export default {
  name: "CashdeskShifts",
  components: {
    DataTableHtml
  },
  head() {
    return {
      title: this.$t('shifts')
    }
  },
  computed: {
    ...mapState({
      ppoData: state => state.ppo.ppoData,
      counter: state => state.datatable.dataTableCounter,
    }),
    shifts() {
      if(!this.ppoData || !this.ppoData.shifts) return []
      if(this.ppoData.vendor === 'aqsi') return this.ppoData.shifts
      return this.ppoData.shifts.results
    },
    columns() {
      if(this.ppoData.vendor === 'checkbox') return this.checkboxColumns
      if(this.ppoData.vendor === 'webkassa') return this.webkassaColumns
      if(this.ppoData.vendor === 'aqsi') return this.aqsiColumns
      return []
    }
  },
  data() {
    return {
      loaded: false,
      checkboxColumns: [
        {
          id: 'serial',
          field: 'serial',
          label: this.$t('shift_number'),
          sortable: true,
        },
        {
          id: 'opened_at',
          field: 'opened_at',
          label: this.$t('opened_at'),
          format_datetime: true,
          sortable: true
        },
        {
          id: 'status',
          field: 'status',
          label: this.$t('status'),
          search_select: true,
          sortable: true
        },
        {
          id: 'cash_sales',
          field: 'cash_sales',
          tdcomponent: 'CashdeskDatatableTDSalesCash',
          label: this.$t('sales') + ' ('+this.$t('cash')+')',
        },
        {
          id: 'card_sales',
          field: 'card_sales',
          tdcomponent: 'CashdeskDatatableTDSalesCard',
          label: this.$t('sales') + ' ('+this.$t('cashless')+')',
        },
        {
          id: 'sales',
          field: 'sales',
          tdcomponent: 'CashdeskDatatableTDSales',
          label: this.$t('sales'),
        },
        {
          tdcomponent: 'CashdeskDatatableTDShiftClose',
          label: this.$t('actions'),
        }
      ],
      webkassaColumns: [
        {
          id: 'ShiftNumber',
          field: 'shift_number',
          label: this.$t('shift_number'),
          sortable: true,
        },
        {
          id: 'OpenDate',
          field: 'opened_at',
          label: this.$t('opened_at'),
          sortable: true
        },
        {
          id: 'CloseDate',
          field: 'closed',
          label: this.$t('closed'),
          sortable: true
        },
        {
          tdcomponent: 'CashdeskDatatableTDShiftClose',
          label: this.$t('actions'),
        }
      ],
      aqsiColumns: [
        {
          id: 'number',
          field: 'shift_number',
          label: this.$t('shift_number'),
          sortable: true,
        },
        {
          id: 'startDate',
          field: 'opened_at',
          label: this.$t('opened_at'),
          sortable: true
        },
        {
          id: 'dateClose',
          field: 'closed',
          label: this.$t('closed'),
          sortable: true
        },
      ],
    }
  },
  methods: {
    openShift() {
      CashdeskService.openShift().then(res => {
        if(res.data.response && res.data.response.message) {
          this.$noty.error(res.data.response.message)
        }
        this.$store.commit('setShifts', res.data.shifts)
      })
    }
  },
  async mounted() {
    if(this.ppoData.vendor === 'webkassa') {
      const res = await CashdeskService.webkassaData('shifts')
      this.$store.commit('setShifts', res.data)
    }
    if(this.ppoData.vendor === 'aqsi') {
      const res = await CashdeskService.aqsiData('shifts')
      this.$store.commit('setShifts', res.data.rows)
    }
    this.loaded = true
  },
  watch: {
    counter() {
      location.reload()
    }
  }
}
</script>
