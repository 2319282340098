<template>
  <section id="unpaid" class="unpaid">
    <div class="section-top-row">
      <h1>{{ $t('billing') }}</h1>
    </div>
    <div class="alert alert-danger">
      {{ $t('unpaid_message') }}
    </div>
  </section>
</template>

<script>
export default {
  name: "Unpaid"
}
</script>

<style scoped>

</style>
