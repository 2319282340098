const state = {
    egiszSelectedRecordEdit: null,
    egiszSelectedRecordSign: null,
}

const getters = {
}

const mutations = {
    setEGISZSelectedRecordEdit(state, data) {
        state.egiszSelectedRecordEdit = data
    },
    setEGISZSelectedRecordSign(state, data) {
        state.egiszSelectedRecordSign = data
    },
}

const actions = {
};

export default {
    state,
    actions,
    mutations,
    getters
}
