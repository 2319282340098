<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   :hideRowsCounter="false"
                   initialSort="desc"
                   :excel-columns-adjustment="excelColumnsAdjustment"
                   :rows="rows"/>
  </div>
</template>

<script>
import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { mapState } from "vuex"

export default {
  name: "ReportsCashFlows",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('cash_flow_statement'),
    }
  },
  computed: {
    ...mapState({
      branchId: state => state.auth.branch,
    })
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'operation_type',
          field: 'operation_type',
          label: this.$t('operation_type'),
          tabletdclass: "order-mob-0",
          search_select: true,
          sortable: true
        },
        {
          id: 'amount',
          field: 'amount',
          label: this.$t('sum'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'visit_date',
          field: 'visit_date',
          label: this.$t('visit_date'),
          table_show: 'visit_readable_date',
          sortable: true
        },
        {
          id: 'visit_id',
          field: 'visit_id',
          label: this.$t('visit_id'),
          sortable: true
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          tdcomponent: 'PatientsDatatableTDName',
          search_select: true,
          sortable: true
        },
        {
          id: 'patient_card',
          field: 'patient_card',
          label: this.$t('card'),
          sortable: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
        {
          id: 'category',
          field: 'category',
          label: this.$t('category'),
          search_select: true,
          sortable: true
        },
        {
          id: 'payment_type',
          field: 'payment_type',
          label: this.$t('payment_type'),
          search_select: true,
          search_select_strict: true,
          sortable: true
        },
        {
          id: 'balance_amount',
          field: 'balance_amount',
          label: this.$t('paid_from_account'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'comment',
          field: 'comment',
          label: this.$t('comment'),
          sortable: true
        },
        {
          id: 'acceptant_name',
          field: 'acceptant_name',
          label: this.$t('accepted_by'),
          search_select: true,
          sortable: true
        },
        {
          id: 'fiscal_passed',
          field: 'fiscal_passed',
          label: this.$t('fiscal_cheque'),
          format_boolean: true,
          search_select: true,
          sortable: true
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('cash_flow', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    },
    excelColumnsAdjustment(columns)  {
      columns.splice(5, 0, {
        id: 'patient_id',
        field: 'patient_id',
        label: this.$t('patient_id'),
      })
      columns.push({
        id: 'curator_id',
        field: 'curator_id',
        label: this.$t('curator_id'),
      })
      columns.push({
        id: 'curator_name',
        field: 'curator_name',
        label: this.$t('curator_name'),
      })
      return columns
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>
