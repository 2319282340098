import  { PatientService } from "@/services/api.service"

const state = {
    teeth: [],
    selectedTooth: null, // for patient general formula
    selectedToothArea: null, // for patient general formula
    selectedToothSelectionType: 'status',
    recordTeeth: [], // record teeth for specific visit (ids)
    toothStatuses: {}, // dictionary
    templates: [],
    visitResults: [],
}

const getters = {

}

const mutations = {
    setTeeth(state, teeth) {
        state.teeth = teeth
    },
    setSelectedTooth(state, params) {
        state.selectedTooth = state.selectedTooth == params.tooth ? null : params.tooth
        state.selectedToothArea = params.area
        if(params.type) {
            state.selectedToothSelectionType = params.type
        }
    },
    setStatuses(state, statuses) {
        state.toothStatuses = statuses
    },
    setRecordTeeth(state, teeth) {
        state.recordTeeth = teeth
    },
    setTemplates(state, value) {
        state.templates = value
    },
    setVisitResults(state, value) {
        state.visitResults = value
    },
    toggleRecordTeeth(state, tooth) {
        let ind = state.recordTeeth.indexOf(tooth)
        if (ind === -1) {
            state.recordTeeth.push(tooth)
        } else {
            state.recordTeeth.splice(ind, 1)
        }
    }
}

const actions = {
    getPatientTeeth(context, id) {
        return new Promise((resolve, reject) => {
            PatientService.getClientTeeth(id).then(data => {
                const d = data.data
                context.commit('setTeeth', d.teeth)
                context.commit('setStatuses', d.statuses)
                resolve(d)
            }).catch(err => {
                reject(err.response.data.errors)
            })
        })
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}
