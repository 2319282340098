<template>
  <section id="patients" class="patients">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print">
        <div class="col order-md-0 order-0">
          <h1>
            <span class="d-inline-block align-middle">{{ $t('patients') }}</span>
            <PatientsHeaderDoctorsFilter class="ml-3 d-md-inline-block align-middle d-none"/>
          </h1>
        </div>
        <div class="col-md-auto col mt-md-0 mt-2 table-view-navigator order-md-1 order-3">
          <SearchTable/>
        </div>
        <div class="col-auto table-view-navigator order-md-2 mt-md-0 mt-2 order-4">
          <DataTableFilterButton/>
        </div>
        <div class="col-auto table-view-navigator order-md-4 order-1" v-if="rights.includes('excel_clients')">
          <DataTableExcel/>
        </div>
        <div class="col-auto table-view-navigator  order-md-5 order-2" v-if="rights.includes('add_clients')">
          <AddObject module="patients"/>
        </div>
      </div>
    </div>
    <div>
      <DataTable :options="options"
                 :params="{ blocked: 0 }"
                 ref="datatable"
                 :can-hide-rows="true"
                 @checkboxSelected="selectedChange"
                 module="patients">
        <template #bottom-bar>
          <div>
            <b-dropdown-item @click="edit"
                             v-if="rights.includes('clinic_services')">
              {{ $t('edit') }}
            </b-dropdown-item>
            <b-dropdown-item v-if="rights.includes('clinic_services')"
                             @click="sendToArchive">
              {{ $t('send_to_archive') }}
            </b-dropdown-item>
            <b-dropdown-item @click="addToFunnel"
                             v-if="rights.includes('crm')">
              {{ $t('add_to_funnel_came_and_repeated') }}
            </b-dropdown-item>
          </div>
        </template>
      </DataTable>
      <PatientSMSModal/>

      <PatientsBulkModal :selected="selected"
                         @finished="bulkPostChange"/>

    </div>
  </section>
</template>

<script>
import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTable from "@/components/parts/general/DataTable"
import options from "../../../data/patients/patient"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import PatientsHeaderDoctorsFilter from "@/components/parts/datatable/custom/patients/PatientsHeaderDoctorsFilter"
import { mapState } from "vuex"
import { CRMService, EloquentService } from "@/services/api.service"
import PatientsBulkModal from "@/components/parts/patients/PatientsBulkModal.vue"

export default {
  name: "Patients",
  components: {
    PatientsBulkModal,
    PatientsHeaderDoctorsFilter,
    DataTable,
    AddObject,
    DataTableExcel,
    DataTableFilterButton,
    SearchTable,
    PatientSMSModal,
  },
  head() {
    return {
      title: this.$t('patients'),
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
      rights: state => state.auth.rights,
    })
  },
  data() {
    return {
      options,
      selected: []
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  methods: {
    selectedChange(selected) {
      this.selected = selected
    },
    edit() {
      this.$bvModal.show('patients-bulk-edit-modal')
    },
    resetSelection() {
      this.$refs.datatable.cancelSelection()
    },
    async sendToArchive() {
      const answer = confirm(this.$t('are_you_sure_you_want_to_delete_users'))
      if(!answer) return
      await EloquentService.deleteBulk('patient', this.selected)
      this.$store.commit('incrementDataTableCounter')
    },
    bulkPostChange() {
      this.$bvModal.hide('patients-bulk-edit-modal')
      this.resetSelection()
      this.$store.commit('incrementDataTableCounter')
    },
    async addToFunnel() {
      const res = await CRMService.bulkFunnelCreation({ patients: this.selected })
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
        this.resetSelection()
      }
    }
  }
}
</script>
