<template>
  <div class="form-group" v-if="['addition','return'].includes(object.operation_type)">
    <SelectGroup :options="selectModels['supplier_id']"
                 v-if="!creatingNew"
                 variant="white"
                 :title="field.form.select_data && field.form.select_data.title ? field.form.select_data.title : null"
                 :label="$t(field.title)"
                 :translate="true"
                 :showlabel="true"
                 v-model="object[field.id]"/>

    <div v-if="creatingNew" class="sub-form">
      <div class="font-weight-bold mb-3">{{ $t('adding_new_supplier') }}</div>
      <b-row>
        <b-col md="6">
          <InputGroup :label="$t('title')" type="text" :required="true" v-model="newSupplier.title"/>
          <div class="validation-error" v-if="errors['title']">{{ errors['title'].join(', ') }}</div>
        </b-col>
        <b-col md="6">
          <InputGroup :label="$t('bin')" type="text" :required="true" v-model="newSupplier.bin"/>
          <div class="validation-error" v-if="errors['bin']">{{ errors['bin'].join(', ') }}</div>
        </b-col>
        <b-col md="6">
          <InputGroup :label="$t('phone')" type="text" :required="true" v-model="newSupplier.phone"/>
          <div class="validation-error" v-if="errors['phone']">{{ errors['phone'].join(', ') }}</div>
        </b-col>
        <b-col md="6">
          <InputGroup :label="$t('email')" type="text" :required="true" v-model="newSupplier.email"/>
          <div class="validation-error" v-if="errors['email']">{{ errors['email'].join(', ') }}</div>
        </b-col>
        <b-col md="12">
          <InputGroup :label="$t('address')" type="text" :required="true" v-model="newSupplier.address"/>
          <div class="validation-error" v-if="errors['address']">{{ errors['address'].join(', ') }}</div>
        </b-col>
      </b-row>
      <div class="text-right mt-3">
        <button @click="creatingNew = false" class="mr-3 btn-themed btn-themed-outline btn-themed-squared">{{ $t('cancel') }}</button>
        <button @click="saveSupplier" :disabled="loading" class="btn-themed btn-themed-squared">{{ $t('add') }}</button>
      </div>
    </div>
    <a class="pointer" @click="creatingNew = !creatingNew">
      <span v-if="!creatingNew">{{ $t('add_new') }}</span>
    </a>
  </div>
</template>

<script>

import SelectGroup from "@/components/form/SelectGroup"
import InputGroup from "@/components/form/InputGroup"
import {EloquentService} from "@/services/api.service"
import { mapState } from "vuex"

export default {
  name: "WarehouseDatatableFormSupplier",
  components: {
    InputGroup,
    SelectGroup
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    object: {
      type: Object,
    },
    selectModels: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      creatingNew: false,
      newSupplier: {},
      loading: false,
    }
  },
  methods: {
    saveSupplier() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.newSupplier)
      this.loading = true
      EloquentService.create('supplier', formData).then((res) => {
        console.log(res.data)
        let m = this.selectModels
        m['supplier_id'].push({id: res.data.id, title: res.data.title })
        this.$store.commit('setSelectModels', m)
        this.newSupplier = {}
        this.creatingNew = false
        this.loading = false
        this.$nextTick(() => {
          this.object[this.field.id] = res.data.id
        })
      }).catch(this.loading = false)
    }
  },
  computed: {
    ...mapState({
      errors: state => state.datatable.validationErrors,
    }),
  },
}
</script>

<style scoped>

</style>
