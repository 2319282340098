<template>
  <div>
    <div><a class="pointer text-primary" @click="taskClicked">{{ task.title }}</a></div>
    <div v-html="text"></div>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import { getCurrentInstance } from "vue"
import { copyObject } from "@/extensions/prototypes/prototypes"
import { EloquentService } from "@/services/api.service"

const store = useStore()
const instance = (getCurrentInstance())

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  task: {
    type: Object
  }
})

const taskClicked = async () => {
  const bvModal = instance?.ctx?._bv__modal
  const taskFull = await EloquentService.show('task', props.task.id)
  store.commit('setTask', copyObject(taskFull.data))
  bvModal.show('task-show-modal')
}
</script>

<style scoped>

</style>
