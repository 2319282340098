<template>
  <div class="mb-4">

    <div v-for="r in roles" :key="`role-s-${r}`">
      <CheckboxGroup :label="$t(`personal_percent_for_${r.slice(0, -1)}`)"
                     class="mt-4"
                     :fid="`${r}-check`"
                     v-model="show[r]"/>
      <div v-if="show[r]">

        <ServiceUserPercentFormPart v-for="(t, tind) in object[r]"
                                    :key="`${r}-row-${tind}`"
                                    :user="t"
                                    :label="$t(`${r.slice(0, -1)}`)"
                                    :view="r.slice(0, -1)"
                                    percent-field="value"
                                    :service="object"
                                    :absolute-enabled="false"
                                    :options="selectModels[r]"
                                    @removeUser="removeUser(r, tind)"
        />
        <div>
          <a class="d-inline-block btn-themed btn-themed-outline btn-themed-squared"
             @click="addPercent(r)">{{ $t('add') }}</a>
        </div>
        <hr/>
      </div>
    </div>
  </div>

</template>

<script>

import CheckboxGroup from "@/components/form/CheckboxGroup"
import ServiceUserPercentFormPart from "@/components/parts/datatable/form/services/ServiceUserPercentFormPart"

export default {
  name: "ServiceCategoryDatatableFormCard",
  components: {
    ServiceUserPercentFormPart,
    CheckboxGroup,
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  data() {
    return {
      roles: ['technics', 'doctors', 'managers', 'assistants'],
      show: {
        technics: this.object.technics && this.object.technics.length > 0,
        doctors: this.object.doctors && this.object.doctors.length > 0,
        managers: this.object.managers && this.object.managers.length > 0,
        assistants: this.object.assistants && this.object.assistants.length > 0,
      },
      initiated: false
    }
  },
  computed: {
    showTechnics() {
      return this.show.technics
    },
    showDoctors() {
      return this.show.doctors
    },
    showManagers() {
      return this.show.managers
    },
    showAssistants() {
      return this.show.assistants
    },
  },
  mounted() {
    this.roles.forEach(role => {
      if(!this.object[role]) {
        this.$set(this.object, role, [])
      }
      this.setPivotPercentType(role)
    })
    this.initiated = true
  },
  methods: {
    setPivotPercentType(user_field) {
      this.object[user_field].forEach((item, index) => {
        if(!this.object[user_field][index].pivot) {
          this.$set(this.object[user_field][index].pivot, 'rate_type', 'percent')
        }
      })
    },
    addPercent(role) {
      this.object[role].push({
        id: null,
        service_id: this.object.id,
        pivot: {
          percent: 0,
          rate_type: 'percent'
        }
      })
    },
    removeUser(role, index) {
      this.object[role].splice(index, 1)
    },
    showChanged(role, val) {
      if(!val) {
        this.$set(this.object, role, [])
      }
      if(val) {
        this.addPercent(role)
      }
    }
  },
  watch: {
    showTechnics(val) {
      this.showChanged('technics', val)
    },
    showDoctors(val) {
      this.showChanged('doctors', val)
    },
    showManagers(val) {
      this.showChanged('managers', val)
    },
    showAssistants(val) {
      this.showChanged('assistants', val)
    },
  },
  unmounted() {
    this.$store.commit('nullifyTreatmentCard')
  }
}
</script>
