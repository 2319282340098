<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M26.1592 78.768L24.0755 80.0378H20.5917L18.4918 78.768L20.1034 77.2215L18.0197 75.675L19.908 74.1285L17.6127 72.4355L19.4359 70.889L17.1406 69.489L19.1592 68.0727L17.1406 66.4611L18.8336 64.9146L16.6034 63.4983L18.3615 62.0332L16.0662 60.4867L18.215 58.9402L16.0662 57.3285L17.6127 55.9774L15.529 54.4309L17.3522 52.9495L15.2685 51.403L17.0755 49.6611L16.0662 47.9844V33.7239L17.9545 26.3984H24.0755" fill="#8889BE"/>
      <path d="M18.7519 78.768L20.8356 80.0378H24.3031L26.4193 78.768L24.7914 77.2215L26.8914 75.675L25.0031 74.1285L27.2821 72.4355L25.4751 70.889L27.7542 69.489L25.7356 68.0727L27.7542 66.4611L26.0775 64.9146L28.2914 63.4983L26.5496 62.0332L28.8286 60.4867L26.6798 58.9402L28.8286 57.3285L27.2821 55.9774L29.3658 54.4309L27.5589 52.9495L29.6426 51.403L27.8193 49.6611L28.8286 47.9844V33.7239L26.9565 26.3984H23.0496" fill="#8889BE"/>
      <path d="M1.9332 20.5292C3.15142 26.4803 4.07559 27.8806 5.32182 33.0195L7.18416 33.8597C9.46416 33.0312 11.9527 32.9722 14.2695 33.6917C18.5822 34.8259 17.6581 29.6309 24.6033 32.4034C31.5486 35.1759 33.9991 32.4034 33.9991 32.4034L39.7121 29.0148C39.7121 28.4127 39.8101 27.8806 39.8801 27.3905C40.5242 22.8537 42.1485 22.0555 41.8685 17.9108C41.6612 14.9936 40.6801 12.1843 39.0261 9.77236C37.3722 7.36039 35.1053 5.43286 32.4588 4.18826C30.3724 3.19408 28.6361 2.36793 26.6057 2.788C23.8052 3.37611 23.2171 5.68653 21.3547 5.40648C19.7725 5.16844 19.7164 3.40411 17.8121 2.17189C15.1096 0.407566 11.4969 1.54177 9.95666 2.01786C9.56459 2.14388 3.16542 4.24427 1.16306 10.1954C0.33691 12.6598 0.855003 15.2783 1.9332 20.5292Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <!--      <path d="M38.2777 8.58861C40.3337 11.2048 41.5667 14.3725 41.8203 17.6903C42.1004 21.891 40.4201 22.6332 39.832 27.17C39.762 27.6601 39.706 28.1922 39.6639 28.7943C39.3419 33.2331 40.4201 33.3591 40.126 38.19C39.804 43.483 38.4877 43.567 38.1237 48.3699C37.8576 51.8845 38.4037 54.9791 39.5239 61.1682C39.8971 64.5981 39.8971 68.0583 39.5239 71.4881C39.5239 71.9782 39.3839 73.2804 38.5998 73.6445C37.8156 74.0086 36.9895 72.9304 35.6732 73.1824C34.609 73.3925 34.511 74.2466 33.3628 74.7227C32.2877 75.057 31.1275 74.9873 30.1002 74.5267C27.7634 73.6787 25.6915 72.2298 24.0931 70.3259C19.3462 64.7249 17.5539 66.2652 13.6052 61.3923C9.97853 56.8974 8.85832 52.2766 7.44406 46.4375C5.88977 39.9823 7.03798 40.0664 5.28767 32.869C4.04144 27.7161 3.11727 26.3018 1.89905 20.3788C0.82085 15.1698 0.302757 12.5093 1.12891 10.0589C3.11727 4.10779 9.53045 2.00741 9.90851 1.88139C11.4488 1.4053 15.0755 0.271091 17.7779 2.03541C19.7243 3.19763 19.7243 4.96195 21.3206 5.19999C23.1829 5.48004 23.813 3.16962 26.5576 2.58151C28.5879 2.14743 30.3242 2.97358 32.4246 3.98177C34.693 5.0646 36.6922 6.63818 38.2777 8.58861Z" fill="#DEE1E9"/>-->
      <!--      <path d="M1.9332 20.311C3.15142 26.2621 4.07559 27.6623 5.32182 32.8013L7.18416 33.6414C9.46416 32.813 11.9527 32.7539 14.2695 33.4734C18.5822 34.6076 17.6581 29.4126 24.6033 32.1852C31.5486 34.9577 33.9991 32.1852 33.9991 32.1852L39.7121 28.7965C39.7121 28.1944 39.8101 27.6623 39.8801 27.1722C40.5242 22.6354 42.1485 21.8373 41.8685 17.6925C41.6612 14.7753 40.6801 11.9661 39.0261 9.5541C37.3722 7.14213 35.1053 5.2146 32.4588 3.97C30.3724 2.97582 28.6361 2.14967 26.6057 2.56974C23.8052 3.15785 23.2171 5.46827 21.3547 5.18822C19.7725 4.95018 19.7164 3.18585 17.8121 1.95363C15.1096 0.189304 11.4969 1.32351 9.95666 1.7996C9.56459 1.92562 3.16542 4.02601 1.16306 9.9771C0.33691 12.4415 0.855003 15.06 1.9332 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
      <!--      <path opacity="0.2" d="M34.4402 27.352C33.6981 31.2868 35.7004 32.827 35.3644 36.9158C35.1683 39.4222 34.5242 39.3242 33.8241 42.5168C32.9559 46.4655 33.334 49.9242 34.1322 56.7014C34.6643 61.1962 38.0532 72.0792 37.3951 72.2612C36.7369 72.4433 33.166 62.7085 31.9478 62.8485C30.7295 62.9886 32.1108 73.0157 30.9766 73.3657C29.4924 73.7718 26.3047 62.8485 26.0947 62.5685C24.3584 59.768 25.0165 58.3677 23.9383 55.7772C22.524 52.1226 21.9862 51.5193 19.4657 47.9206C17.5334 45.1201 16.867 45.4433 14.7106 39.9964C12.0221 33.2191 9.7677 31.8889 10.3978 28.5983C10.5891 27.5406 11.0256 26.5424 11.6725 25.684C12.3194 24.8256 13.1585 24.1308 14.1225 23.6554C16.5029 22.7172 17.6791 25.6297 21.5159 25.8118C23.4762 25.9098 23.4202 25.2237 28.9232 23.9634C33.8661 22.8292 34.8323 25.2657 34.4402 27.352Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

      <path d="M38.6327 8.38768C40.6887 11.0039 41.9217 14.1716 42.1753 17.4893C42.4554 21.6901 40.7751 22.4322 40.187 26.9691C40.1169 27.4592 40.0609 27.9913 40.0189 28.5934C39.6969 33.0322 40.7751 33.1582 40.481 37.9891C40.159 43.2821 38.8427 43.3661 38.4786 48.169C38.2126 51.6836 38.7587 54.7782 39.8789 60.9673C40.2521 64.3971 40.2521 67.8573 39.8789 71.2872C39.8789 71.7773 39.7389 73.0795 38.9547 73.4436C38.1706 73.8076 37.3444 72.7294 36.0282 72.9815C34.964 73.1915 34.866 74.0457 33.7178 74.5218C32.6427 74.8561 31.4825 74.7864 30.4552 74.3257C28.1184 73.4778 26.0465 72.0288 24.4481 70.125C19.7012 64.5239 17.9089 66.0642 13.9602 61.1913C10.3335 56.6965 9.2133 52.0757 7.79904 46.2366C6.24475 39.7814 7.39296 39.8654 5.64265 32.6681C4.39642 27.5152 3.47225 26.1009 2.25403 20.1778C1.17583 14.9689 0.657738 12.3084 1.48389 9.85795C3.47225 3.90686 9.88543 1.80648 10.2635 1.68046C11.8038 1.20437 15.4304 0.0701634 18.1329 1.83449C20.0793 2.9967 20.0793 4.76102 21.6756 4.99906C23.5379 5.27912 24.168 2.96869 26.9125 2.38059C28.9429 1.94651 30.6792 2.77266 32.7796 3.78084C35.0479 4.86368 37.0472 6.43725 38.6327 8.38768Z" fill="#DEE1E9"/>
      <mask id="mask438" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask438)">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 19.7991C20.5012 19.7991 19.2556 14.5008 19.2556 4.90252L21.1787 3.04045L24.2556 1.79907C24.2556 11.3974 22.6254 19.7991 21.5633 19.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M35.1256 20.2332C34.3613 24.7271 36.4235 26.4863 36.0774 31.1562C35.8755 34.0189 35.2121 33.9069 34.4911 37.5532C33.597 42.0632 33.9864 46.0133 34.8084 53.7538C35.3563 58.8874 38.8464 71.3171 38.1687 71.525C37.4909 71.7329 33.8133 60.6146 32.5587 60.7745C31.3042 60.9345 32.7267 72.3866 31.5586 72.7865C30.0301 73.2502 26.7472 60.7745 26.5309 60.4547C24.7428 57.2562 25.4206 55.6569 24.3102 52.6983C22.8537 48.5242 22.2998 47.8351 19.7041 43.725C17.7141 40.5265 17.0278 40.8957 14.807 34.6746C12.0383 26.9341 9.71655 25.4148 10.3655 21.6566C10.5624 20.4485 11.012 19.3085 11.6782 18.3281C12.3444 17.3477 13.2086 16.5541 14.2013 16.0112C16.6528 14.9397 17.8642 18.2661 21.8154 18.474C23.8343 18.586 23.7766 17.8023 29.4439 16.363C34.5344 15.0676 35.5294 17.8503 35.1256 20.2332Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M38.2777 8.58861C40.3337 11.2048 41.5667 14.3725 41.8203 17.6903C42.1004 21.891 40.4201 22.6332 39.832 27.17C39.762 27.6601 39.706 28.1922 39.6639 28.7943C39.3419 33.2331 40.4201 33.3591 40.126 38.19C39.804 43.483 38.4877 43.567 38.1237 48.3699C37.8576 51.8845 38.4037 54.9791 39.5239 61.1682C39.8971 64.5981 39.8971 68.0583 39.5239 71.4881C39.5239 71.9782 39.3839 73.2804 38.5998 73.6445C37.8156 74.0086 36.9895 72.9304 35.6732 73.1824C34.609 73.3925 34.511 74.2466 33.3628 74.7227C32.2877 75.057 31.1275 74.9873 30.1002 74.5267C27.7634 73.6787 25.6915 72.2298 24.0931 70.3259C19.3462 64.7249 17.5539 66.2652 13.6052 61.3923C9.97853 56.8974 8.85832 52.2766 7.44406 46.4375C5.88977 39.9823 7.03798 40.0664 5.28767 32.869C4.04144 27.7161 3.11727 26.3018 1.89905 20.3788C0.82085 15.1698 0.302757 12.5093 1.12891 10.0589C3.11727 4.10779 9.53045 2.00741 9.90851 1.88139C11.4488 1.4053 15.0755 0.271091 17.7779 2.03541C19.7243 3.19763 19.7243 4.96195 21.3206 5.19999C23.1829 5.48004 23.813 3.16962 26.5576 2.58151C28.5879 2.14743 30.3242 2.97358 32.4246 3.98177C34.693 5.0646 36.6922 6.63818 38.2777 8.58861Z" fill="#DEE1E9"/>
      <path d="M1.9332 20.311C3.15142 26.2621 4.07559 27.6623 5.32182 32.8013L7.18416 33.6414C9.46416 32.813 11.9527 32.7539 14.2695 33.4734C18.5822 34.6076 17.6581 29.4126 24.6033 32.1852C31.5486 34.9577 33.9991 32.1852 33.9991 32.1852L39.7121 28.7965C39.7121 28.1944 39.8101 27.6623 39.8801 27.1722C40.5242 22.6354 42.1485 21.8373 41.8685 17.6925C41.6612 14.7753 40.6801 11.9661 39.0261 9.5541C37.3722 7.14213 35.1053 5.2146 32.4588 3.97C30.3724 2.97582 28.6361 2.14967 26.6057 2.56974C23.8052 3.15785 23.2171 5.46827 21.3547 5.18822C19.7725 4.95018 19.7164 3.18585 17.8121 1.95363C15.1096 0.189304 11.4969 1.32351 9.95666 1.7996C9.56459 1.92562 3.16542 4.02601 1.16306 9.9771C0.33691 12.4415 0.855003 15.06 1.9332 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M34.4402 27.352C33.6981 31.2868 35.7004 32.827 35.3644 36.9158C35.1683 39.4222 34.5242 39.3242 33.8241 42.5168C32.9559 46.4655 33.334 49.9242 34.1322 56.7014C34.6643 61.1962 38.0532 72.0792 37.3951 72.2612C36.7369 72.4433 33.166 62.7085 31.9478 62.8485C30.7295 62.9886 32.1108 73.0157 30.9766 73.3657C29.4924 73.7718 26.3047 62.8485 26.0947 62.5685C24.3584 59.768 25.0165 58.3677 23.9383 55.7772C22.524 52.1226 21.9862 51.5193 19.4657 47.9206C17.5334 45.1201 16.867 45.4433 14.7106 39.9964C12.0221 33.2191 9.7677 31.8889 10.3978 28.5983C10.5891 27.5406 11.0256 26.5424 11.6725 25.684C12.3194 24.8256 13.1585 24.1308 14.1225 23.6554C16.5029 22.7172 17.6791 25.6297 21.5159 25.8118C23.4762 25.9098 23.4202 25.2237 28.9232 23.9634C33.8661 22.8292 34.8323 25.2657 34.4402 27.352Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="30" cy="73" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="38" cy="73" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M38.2777 8.04393C40.3337 10.6601 41.5667 13.8279 41.8203 17.1456C42.1004 21.3464 40.4201 22.0885 39.832 26.6253C39.762 27.1154 39.706 27.6475 39.6639 28.2496C39.3419 32.6884 40.4201 32.8145 40.126 37.6453C39.804 42.9383 38.4877 43.0223 38.1237 47.8252C37.8576 51.3398 38.4037 54.4344 39.5239 60.6235C39.8971 64.0534 39.8971 67.5136 39.5239 70.9434C39.5239 71.4335 39.3839 72.7358 38.5998 73.0998C37.8156 73.4639 36.9895 72.3857 35.6732 72.6377C34.609 72.8478 34.511 73.7019 33.3628 74.178C32.2877 74.5123 31.1275 74.4426 30.1002 73.982C27.7634 73.134 25.6915 71.6851 24.0931 69.7812C19.3462 64.1802 17.5539 65.7205 13.6052 60.8476C9.97853 56.3528 8.85832 51.7319 7.44406 45.8928C5.88977 39.4377 7.03798 39.5217 5.28767 32.3244C4.04144 27.1714 3.11727 25.7572 1.89905 19.8341C0.82085 14.6251 0.302757 11.9647 1.12891 9.5142C3.11727 3.56311 9.53045 1.46273 9.90851 1.33671C11.4488 0.860621 15.0755 -0.273587 17.7779 1.49074C19.7243 2.65295 19.7243 4.41727 21.3206 4.65531C23.1829 4.93537 23.813 2.62494 26.5576 2.03684C28.5879 1.60276 30.3242 2.42891 32.4246 3.43709C34.693 4.51993 36.6922 6.0935 38.2777 8.04393Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M38.2777 8.04393C40.3337 10.6601 41.5667 13.8279 41.8203 17.1456C42.1004 21.3464 40.4201 22.0885 39.832 26.6253C39.762 27.1154 39.706 27.6475 39.6639 28.2496C39.3419 32.6884 40.4201 32.8145 40.126 37.6453C39.804 42.9383 38.4877 43.0223 38.1237 47.8252C37.8576 51.3398 38.4037 54.4344 39.5239 60.6235C39.8971 64.0534 39.8971 67.5136 39.5239 70.9434C39.5239 71.4335 39.3839 72.7358 38.5998 73.0998C37.8156 73.4639 36.9895 72.3857 35.6732 72.6377C34.609 72.8478 34.511 73.7019 33.3628 74.178C32.2877 74.5123 31.1275 74.4426 30.1002 73.982C27.7634 73.134 25.6915 71.6851 24.0931 69.7812C19.3462 64.1802 17.5539 65.7205 13.6052 60.8476C9.97853 56.3528 8.85832 51.7319 7.44406 45.8928C5.88977 39.4377 7.03798 39.5217 5.28767 32.3244C4.04144 27.1714 3.11727 25.7572 1.89905 19.8341C0.82085 14.6251 0.302757 11.9647 1.12891 9.5142C3.11727 3.56311 9.53045 1.46273 9.90851 1.33671C11.4488 0.860621 15.0755 -0.273587 17.7779 1.49074C19.7243 2.65295 19.7243 4.41727 21.3206 4.65531C23.1829 4.93537 23.813 2.62494 26.5576 2.03684C28.5879 1.60276 30.3242 2.42891 32.4246 3.43709C34.693 4.51993 36.6922 6.0935 38.2777 8.04393Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M36.6921 6.29217C36.7341 5.69006 36.7901 5.15796 36.8601 4.66787L28.4288 6.29217L17.4288 4.66787L8.92877 7.49798L0.928772 4.66782C1.54037 6.68803 1.60948 7.44632 2.31582 10.3669C4.06614 17.5642 2.91793 17.4802 4.47221 23.9354C5.88647 29.7745 7.00668 34.3953 10.6333 38.8901C14.5821 43.763 16.3744 42.2227 21.1213 47.8238C22.7196 49.7276 24.7916 51.1765 27.1284 52.0245C28.1557 52.4852 29.3158 52.5549 30.3909 52.2206C31.5392 51.7445 31.6372 50.8903 32.7014 50.6803C34.0176 50.4282 34.8438 51.5064 35.6279 51.1424C36.412 50.7783 36.5521 49.4761 36.5521 48.986C36.9252 45.5561 36.9252 42.0959 36.5521 38.6661C35.4319 32.477 34.8858 29.3824 35.1518 25.8678C35.5159 21.0649 36.8321 20.9809 37.1542 15.6879C37.4482 10.857 36.37 10.731 36.6921 6.29217Z" fill="#DEE1E9"/>
      <path d="M32.9065 9.73224C22.2532 13.6389 16.3469 13.3503 5.8946 9.265" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M32.3926 14.4136C32.7286 10.3249 30.7263 8.7846 31.4684 4.84988C31.5851 4.22912 31.5815 3.57737 31.3887 2.9979L27.3582 1L23.4288 2.4979L18.3864 1L14.1773 2.4979L10.4288 1.56521C9.76543 1.99901 9.18024 2.54547 8.7007 3.18184C8.05384 4.04027 7.61726 5.0384 7.42601 6.09611C6.7959 9.38671 9.05031 10.717 11.7388 17.4942C13.8952 22.9412 14.5616 22.6179 16.4939 25.4184C19.0144 29.0171 19.5522 29.6204 20.9665 33.2751C22.0447 35.8656 21.3866 37.2658 23.1229 40.0663C23.3329 40.3464 26.5206 51.2696 28.0048 50.8636C29.139 50.5135 27.7577 40.4864 28.976 40.3464C30.1942 40.2064 33.7651 49.9411 34.4233 49.7591C35.0814 49.5771 31.6925 38.6941 31.1604 34.1993C30.3622 27.422 29.9841 23.9634 30.8523 20.0147C31.5524 16.8221 32.1965 16.9201 32.3926 14.4136Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M38.0035 8.58861C40.0595 11.2048 41.2925 14.3725 41.5462 17.6903C41.8262 21.891 40.1459 22.6332 39.5578 27.17C39.4878 27.6601 39.4318 28.1922 39.3898 28.7943C39.0677 33.2331 40.1459 33.3591 39.8519 38.19C39.5298 43.483 38.2136 43.567 37.8495 48.3699C37.5834 51.8845 38.1295 54.9791 39.2497 61.1682C39.6229 64.5981 39.6229 68.0583 39.2497 71.4881C39.2497 71.9782 39.1097 73.2804 38.3256 73.6445C37.5414 74.0086 36.7153 72.9304 35.399 73.1824C34.3349 73.3925 34.2368 74.2466 33.0886 74.7227C32.0135 75.057 30.8534 74.9873 29.826 74.5267C27.4893 73.6787 25.4173 72.2298 23.8189 70.3259C19.0721 64.7249 17.2797 66.2652 13.331 61.3923C9.70435 56.8974 8.58415 52.2766 7.16989 46.4375C5.61561 39.9823 6.76382 40.0664 5.0135 32.869C3.76727 27.7161 2.8431 26.3018 1.62488 20.3788C0.54668 15.1698 5.80032 15.9504 6.62646 13.5C8.61483 7.54891 9.25627 2.00741 9.63434 1.88139C11.1746 1.4053 14.8013 0.271091 17.5038 2.03541C19.4501 3.19763 19.4501 4.96195 21.0464 5.19999C22.9088 5.48004 23.5389 3.16962 26.2834 2.58151C28.3138 2.14743 30.0501 2.97358 32.1505 3.98177C34.4188 5.0646 36.418 6.63818 38.0035 8.58861Z" fill="#DEE1E9"/>
      <path d="M5.04765 32.8013C3.80142 27.6623 2.87725 26.2621 1.65903 20.311C1.35023 18.8071 1.08738 17.5191 0.888889 16.3889L1.48013 15.5201L5.62646 15.5L3.70893 10.5L7.51801 10L3.70893 6.51912L7.51801 5.00439L6.90999 3.06688C8.36296 2.23097 9.52255 1.85101 9.68249 1.7996L9.7133 1.79007C11.2705 1.30865 14.8533 0.200964 17.5379 1.95363C19.4423 3.18585 19.4983 4.95018 21.0806 5.18822C22.9429 5.46827 23.531 3.15785 26.3315 2.56974C28.3619 2.14967 30.0982 2.97582 32.1846 3.97C34.8311 5.2146 37.098 7.14213 38.752 9.5541C40.4059 11.9661 41.387 14.7753 41.5943 17.6925C41.8744 21.8373 40.2501 22.6354 39.606 27.1722C39.5359 27.6623 39.4379 28.1944 39.4379 28.7965L33.7249 32.1852C33.7249 32.1852 31.2744 34.9577 24.3292 32.1852C17.3839 29.4126 18.3081 34.6076 13.9953 33.4734C11.6786 32.7539 9.18999 32.813 6.90999 33.6414L5.04765 32.8013Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M3.55094 23.8344C14.5517 19.4825 17.0713 14.5884 15.8124 1.99999" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M34.166 27.352C33.4239 31.2868 35.4263 32.827 35.0902 36.9158C34.8942 39.4222 34.2501 39.3242 33.5499 42.5168C32.6818 46.4655 33.0598 49.9242 33.858 56.7014C34.3901 61.1962 37.779 72.0792 37.1209 72.2612C36.4628 72.4433 32.8918 62.7085 31.6736 62.8485C30.4554 62.9886 31.8367 73.0157 30.7025 73.3657C29.2182 73.7718 26.0306 62.8485 25.8205 62.5685C24.0842 59.768 24.7423 58.3677 23.6641 55.7772C22.2499 52.1226 21.712 51.5193 19.1916 47.9206C17.2592 45.1201 16.5928 45.4433 14.4364 39.9964C11.7479 33.2191 9.49353 31.8889 10.1236 28.5983C10.3149 27.5406 10.7515 26.5424 11.3983 25.684C12.0452 24.8256 12.8843 24.1308 13.8483 23.6554C16.2288 22.7172 17.405 25.6297 21.2417 25.8118C23.202 25.9098 23.146 25.2237 28.649 23.9634C33.5919 22.8292 34.5581 25.2657 34.166 27.352Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M38.6278 8.838C40.6838 11.4542 41.9168 14.6219 42.1704 17.9397C42.4505 22.1404 40.7702 22.8826 40.1821 27.4194C40.1121 27.9095 40.0561 28.4416 40.014 29.0437C39.692 33.4825 40.7702 33.6085 40.4761 38.4394C40.1541 43.7324 38.8378 43.8164 38.4738 48.6193C38.2077 52.1339 38.7538 55.2285 39.874 61.4176C40.2472 64.8475 40.2472 68.3077 39.874 71.7375C39.874 72.2276 39.734 73.5298 38.9498 73.8939C38.1657 74.258 37.3396 73.1798 36.0233 73.4318C34.9591 73.6418 34.8611 74.496 33.7129 74.9721C32.6378 75.3064 31.4776 75.2367 30.4503 74.7761C28.1135 73.9281 26.0416 72.4791 24.4432 70.5753C19.6963 64.9743 17.904 66.5145 13.9553 61.6417C10.3286 57.1468 9.20842 52.526 7.79416 46.6869C6.23987 40.2317 7.38808 40.3158 5.63776 33.1184C4.39153 27.9655 3.46737 26.5512 2.24915 20.6282C1.17095 15.4192 0.652855 12.7587 1.47901 10.3083C3.46737 4.35718 9.88054 2.2568 10.2586 2.13078C11.7989 1.65469 15.4256 0.520481 18.128 2.2848C20.0744 3.44702 20.0744 5.21134 21.6707 5.44938C23.533 5.72943 24.1631 3.41901 26.9076 2.8309C28.938 2.39682 30.6743 3.22297 32.7747 4.23116C35.0431 5.31399 37.0423 6.88757 38.6278 8.838Z" fill="#DEE1E9"/>
      <path d="M2.2833 20.5604C3.50152 26.5115 4.42569 27.9117 5.67192 33.0506L7.53426 33.8908C9.50419 33.175 11.3545 32.5319 13.3156 32.8342C13.8459 32.916 14.3944 32.6512 14.5573 32.1399C16.2507 26.826 16.2507 21.7494 16.2507 21.7494C21.1318 19.9398 23.8697 19.8996 28.7507 21.7494L31.0387 30.9013C31.1601 31.387 31.622 31.708 32.1195 31.6526L40.2302 30.7494C40.2302 30.1473 40.1602 27.9117 40.2302 27.4216C40.8743 22.8848 42.4986 22.0866 42.2186 17.9419C42.0113 15.0247 41.0302 12.2155 39.3762 9.80349C37.7223 7.39152 35.4554 5.46399 32.8089 4.21939C30.7225 3.22521 28.9862 2.39906 26.9558 2.81913C24.1553 3.40724 23.5672 5.71766 21.7048 5.43761C20.1226 5.19957 20.0665 3.43524 18.1622 2.20302C15.4597 0.438694 11.847 1.5729 10.3068 2.04899C9.91469 2.17501 3.51552 4.2754 1.51316 10.2265C0.687008 12.6909 1.2051 15.3094 2.2833 20.5604Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M39.0035 8.58861C41.0595 11.2048 42.2925 14.3725 42.5462 17.6903C42.8262 21.891 41.1459 22.6332 40.5578 27.17C40.4878 27.6601 40.4318 28.1922 40.3898 28.7943C40.0677 33.2331 41.1459 33.3591 40.8519 38.19C40.5298 43.483 39.2136 43.567 38.8495 48.3699C38.5834 51.8845 39.1295 54.9791 40.2497 61.1682C40.6229 64.5981 40.6229 68.0583 40.2497 71.4881C40.2497 71.9782 40.1097 73.2804 39.3256 73.6445C38.5414 74.0086 37.7153 72.9304 36.399 73.1824C35.3349 73.3925 35.2368 74.2466 34.0886 74.7227C33.0135 75.057 31.8534 74.9873 30.826 74.5267C28.4893 73.6787 26.4173 72.2298 24.8189 70.3259C20.0721 64.7249 18.2797 66.2652 14.331 61.3923C10.7044 56.8974 9.58415 52.2766 8.16989 46.4375C6.6156 39.9823 7.76381 40.0664 6.0135 32.869C4.76727 27.7161 3.8431 26.3018 2.62488 20.3788C1.54668 15.1698 1.02859 12.5093 1.85474 10.0589C3.8431 4.10779 10.2563 2.00741 10.6343 1.88139C12.1746 1.4053 15.8013 0.271091 18.5038 2.03541C20.4501 3.19763 20.4501 4.96195 22.0464 5.19999C23.9088 5.48004 24.5389 3.16962 27.2834 2.58151C29.3138 2.14743 31.0501 2.97358 33.1505 3.98177C35.4188 5.0646 37.418 6.63818 39.0035 8.58861Z" fill="#DEE1E9"/>
      <path d="M2.65903 20.311C3.87725 26.2621 4.80142 27.6623 6.04765 32.8013L7.90999 33.6414C9.87992 32.9256 11.7302 32.2825 13.6913 32.5848C14.2216 32.6666 14.7701 32.4018 14.9331 31.8905C16.6265 26.5766 16.6265 21.5 16.6265 21.5C21.5075 19.6904 24.2454 19.6502 29.1265 21.5L31.4144 30.6519C31.5359 31.1376 31.9977 31.4587 32.4953 31.4032L40.606 30.5C40.606 29.8979 40.5359 27.6623 40.606 27.1722C41.2501 22.6354 42.8744 21.8373 42.5943 17.6925C42.387 14.7753 41.4059 11.9661 39.752 9.5541C38.098 7.14213 35.8311 5.2146 33.1846 3.97C31.0982 2.97582 29.3619 2.14967 27.3315 2.56974C24.531 3.15785 23.9429 5.46827 22.0806 5.18822C20.4983 4.95018 20.4423 3.18585 18.5379 1.95363C15.8354 0.189304 12.2228 1.32351 10.6825 1.7996C10.2904 1.92562 3.89125 4.02601 1.88889 9.9771C1.06274 12.4415 1.58083 15.06 2.65903 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M38.6327 8.58861C40.6887 11.2048 41.9217 14.3725 42.1753 17.6903C42.4554 21.891 40.7751 22.6332 40.187 27.17C40.1169 27.6601 40.0609 28.1922 40.0189 28.7943C39.6969 33.2331 40.7751 33.3591 40.481 38.19C40.159 43.483 38.8427 43.567 38.4786 48.3699C38.2126 51.8845 38.7587 54.9791 39.8789 61.1682C40.2521 64.5981 40.2521 68.0583 39.8789 71.4881C39.8789 71.9782 39.7389 73.2804 38.9547 73.6445C38.1706 74.0086 37.3444 72.9304 36.0282 73.1824C34.964 73.3925 34.866 74.2466 33.7178 74.7227C32.6427 75.057 31.4825 74.9873 30.4552 74.5267C28.1184 73.6787 26.0465 72.2298 24.4481 70.3259C19.7012 64.7249 17.9089 66.2652 13.9602 61.3923C10.3335 56.8974 9.2133 52.2766 7.79904 46.4375C6.24475 39.9823 7.39296 40.0664 5.64265 32.869C4.39642 27.7161 3.47225 26.3018 2.25403 20.3788C1.17583 15.1698 0.657738 12.5093 1.48389 10.0589C3.47225 4.10779 9.88543 2.00741 10.2635 1.88139C11.8038 1.4053 15.4304 0.271091 18.1329 2.03541C20.0793 3.19763 20.0793 4.96195 21.6756 5.19999C23.5379 5.48004 24.168 3.16962 26.9125 2.58151C28.9429 2.14743 30.6792 2.97358 32.7796 3.98177C35.0479 5.0646 37.0472 6.63818 38.6327 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2038" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.50907 32.9256 11.3594 32.2825 13.3204 32.5848C13.8508 32.6666 14.3993 32.4018 14.5622 31.8905C16.2556 26.5766 16.2556 21.5 16.2556 21.5C21.1367 19.6904 23.8745 19.6502 28.7556 21.5L31.0436 30.6519C31.165 31.1376 31.6269 31.4587 32.1244 31.4032L40.2351 30.5C40.2351 29.8979 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2038)">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.50907 32.9256 11.3594 32.2825 13.3204 32.5848C13.8508 32.6666 14.3993 32.4018 14.5622 31.8905C16.2556 26.5766 16.2556 21.5 16.2556 21.5C21.1367 19.6904 23.8745 19.6502 28.7556 21.5L31.0436 30.6519C31.165 31.1376 31.6269 31.4587 32.1244 31.4032L40.2351 30.5C40.2351 29.8979 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.2556 44H35.2556V18.452C25.883 16.5258 20.6282 16.5062 11.2556 18.452V44Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M38.3219 8.23534C40.3779 10.8515 41.6109 14.0193 41.8645 17.337C42.1446 21.5378 40.4643 22.2799 39.8762 26.8167C39.8062 27.3068 39.7501 27.8389 39.7081 28.441C39.3861 32.8798 40.4643 33.0059 40.1702 37.8367C39.8482 43.1297 38.5319 43.2137 38.1679 48.0166C37.9018 51.5313 38.4479 54.6258 39.5681 60.8149C39.9413 64.2448 39.9413 67.705 39.5681 71.1348C39.5681 71.6249 39.4281 72.9272 38.6439 73.2912C37.8598 73.6553 37.0336 72.5771 35.7174 72.8291C34.6532 73.0392 34.5552 73.8933 33.407 74.3694C32.3319 74.7037 31.1717 74.634 30.1444 74.1734C27.8076 73.3254 25.7357 71.8765 24.1373 69.9726C19.3904 64.3716 17.5981 65.9119 13.6494 61.039C10.0227 56.5442 8.90251 51.9233 7.48825 46.0843C5.93396 39.6291 7.08217 39.7131 5.33186 32.5158C4.08562 27.3628 3.16146 25.9486 1.94324 20.0255C0.86504 14.8165 0.346947 12.1561 1.1731 9.70561C3.16146 3.75452 9.57463 1.65414 9.9527 1.52811C11.493 1.05203 15.1196 -0.0821804 17.8221 1.68214C19.7685 2.84435 19.7685 4.60868 21.3648 4.84672C23.2271 5.12677 23.8572 2.81635 26.6017 2.22824C28.6321 1.79416 30.3684 2.62031 32.4688 3.6285C34.7372 4.71133 36.7364 6.2849 38.3219 8.23534Z" fill="#DEE1E9"/>
      <path d="M1.97739 19.958C3.19561 25.909 4.11978 27.3093 5.36601 32.4482L7.22835 33.2884C9.50835 32.4599 11.9969 32.4009 14.3136 33.1204C18.6264 34.2546 17.7023 29.0596 24.6475 31.8321C31.5928 34.6046 34.0432 31.8321 34.0432 31.8321L39.7563 28.4435C39.7563 27.8414 39.8543 27.3093 39.9243 26.8192C40.5684 22.2824 42.1927 21.4842 41.9127 17.3395C41.7054 14.4223 40.7243 11.613 39.0703 9.20107C37.4164 6.7891 35.1495 4.86158 32.503 3.61697C30.4166 2.62279 28.6803 1.79664 26.6499 2.21672C23.8494 2.80482 23.2613 5.11524 21.3989 4.83519C19.8166 4.59715 19.7606 2.83282 17.8563 1.6006C15.1538 -0.163723 11.5411 0.970483 10.0009 1.44657C9.60878 1.57259 3.20961 3.67298 1.20725 9.62407C0.3811 12.0885 0.899193 14.707 1.97739 19.958Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M34.4844 26.9991C33.7423 30.9338 35.7446 32.4741 35.4086 36.5628C35.2125 39.0693 34.5684 38.9713 33.8683 42.1638C33.0001 46.1126 33.3782 49.5712 34.1763 56.3484C34.7084 60.8433 38.0974 71.7262 37.4393 71.9083C36.7811 72.0903 33.2102 62.3555 31.9919 62.4956C30.7737 62.6356 32.155 72.6627 31.0208 73.0128C29.5366 73.4188 26.3489 62.4956 26.1389 62.2155C24.4026 59.415 25.0607 58.0147 23.9825 55.4243C22.5682 51.7696 22.0304 51.1663 19.5099 47.5676C17.5776 44.7671 16.9112 45.0904 14.7548 39.6434C12.0663 32.8661 9.81189 31.5359 10.442 28.2453C10.6333 27.1876 11.0698 26.1895 11.7167 25.331C12.3636 24.4726 13.2027 23.7778 14.1667 23.3024C16.5471 22.3642 17.7233 25.2768 21.56 25.4588C23.5204 25.5568 23.4644 24.8707 28.9674 23.6105C33.9103 22.4762 34.8765 24.9127 34.4844 26.9991Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M12.0502 57.3775C22.6152 62.8417 28.5149 62.5927 39.0174 57.3775" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M38.6327 8.58861C40.6887 11.2048 41.9217 14.3725 42.1753 17.6903C42.4554 21.891 40.7751 22.6332 40.187 27.17C40.1169 27.6601 40.0609 28.1922 40.0189 28.7943C39.6969 33.2331 40.7751 33.3591 40.481 38.19C40.159 43.483 38.8427 43.567 38.4786 48.3699C38.2126 51.8845 38.7587 54.9791 39.8789 61.1682C40.2521 64.5981 40.2521 68.0583 39.8789 71.4881C39.8789 71.9782 39.7389 73.2804 38.9547 73.6445C38.1706 74.0086 37.3444 72.9304 36.0282 73.1824C34.964 73.3925 34.866 74.2466 33.7178 74.7227C32.6427 75.057 31.4825 74.9873 30.4552 74.5267C28.1184 73.6787 26.0465 72.2298 24.4481 70.3259C19.7012 64.7249 17.9089 66.2652 13.9602 61.3923C10.3335 56.8974 9.2133 52.2766 7.79904 46.4375C6.24475 39.9823 7.39296 40.0664 5.64265 32.869C4.39642 27.7161 3.47225 26.3018 2.25403 20.3788C1.17583 15.1698 0.657738 12.5093 1.48389 10.0589C3.47225 4.10779 9.88543 2.00741 10.2635 1.88139C11.8038 1.4053 15.4304 0.271091 18.1329 2.03541C20.0793 3.19763 20.0793 4.96195 21.6756 5.19999C23.5379 5.48004 24.168 3.16962 26.9125 2.58151C28.9429 2.14743 30.6792 2.97358 32.7796 3.98177C35.0479 5.0646 37.0472 6.63818 38.6327 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2238" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.81915 32.813 12.3077 32.7539 14.6244 33.4734C18.9372 34.6076 18.0131 29.4126 24.9583 32.1852C31.9036 34.9577 34.354 32.1852 34.354 32.1852L40.0671 28.7965C40.0671 28.1944 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2238)">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.81915 32.813 12.3077 32.7539 14.6244 33.4734C18.9372 34.6076 18.0131 29.4126 24.9583 32.1852C31.9036 34.9577 34.354 32.1852 34.354 32.1852L40.0671 28.7965C40.0671 28.1944 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 20C20.5012 20 19.2556 14.7018 19.2556 5.10345L21.1787 3.24138L24.2556 2C24.2556 11.5983 22.6254 20 21.5633 20Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M35.1256 20.4341C34.3613 24.9281 36.4235 26.6873 36.0774 31.3571C35.8755 34.2198 35.2121 34.1078 34.4911 37.7542C33.597 42.2641 33.9864 46.2143 34.8084 53.9547C35.3563 59.0883 38.8464 71.518 38.1687 71.7259C37.4909 71.9338 33.8133 60.8155 32.5587 60.9755C31.3042 61.1354 32.7267 72.5876 31.5586 72.9874C30.0301 73.4512 26.7472 60.9755 26.5309 60.6556C24.7428 57.4571 25.4206 55.8578 24.3102 52.8992C22.8537 48.7251 22.2998 48.0361 19.7041 43.926C17.7141 40.7274 17.0278 41.0966 14.807 34.8755C12.0383 27.1351 9.71655 25.6158 10.3655 21.8575C10.5624 20.6495 11.012 19.5095 11.6782 18.529C12.3444 17.5486 13.2086 16.7551 14.2013 16.2121C16.6528 15.1406 17.8642 18.4671 21.8154 18.675C23.8343 18.7869 23.7766 18.0033 29.4439 16.5639C34.5344 15.2685 35.5294 18.0512 35.1256 20.4341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 40.2556 72)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 31.2556 73)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M38.6327 8.58861C40.6887 11.2048 41.9217 14.3725 42.1753 17.6903C42.4554 21.891 40.7751 22.6332 40.187 27.17C40.1169 27.6601 40.0609 28.1922 40.0189 28.7943C39.6969 33.2331 40.7751 33.3591 40.481 38.19C40.159 43.483 38.8427 43.567 38.4786 48.3699C38.2126 51.8845 38.7587 54.9791 39.8789 61.1682C40.2521 64.5981 40.2521 68.0583 39.8789 71.4881C39.8789 71.9782 39.7389 73.2804 38.9547 73.6445C38.1706 74.0086 37.3444 72.9304 36.0282 73.1824C34.964 73.3925 34.866 74.2466 33.7178 74.7227C32.6427 75.057 31.4825 74.9873 30.4552 74.5267C28.1184 73.6787 26.0465 72.2298 24.4481 70.3259C19.7012 64.7249 17.9089 66.2652 13.9602 61.3923C10.3335 56.8974 9.2133 52.2766 7.79904 46.4375C6.24475 39.9823 7.39296 40.0664 5.64265 32.869C4.39642 27.7161 3.47225 26.3018 2.25403 20.3788C1.17583 15.1698 0.657738 12.5093 1.48389 10.0589C3.47225 4.10779 9.88543 2.00741 10.2635 1.88139C11.8038 1.4053 15.4304 0.271091 18.1329 2.03541C20.0793 3.19763 20.0793 4.96195 21.6756 5.19999C23.5379 5.48004 24.168 3.16962 26.9125 2.58151C28.9429 2.14743 30.6792 2.97358 32.7796 3.98177C35.0479 5.0646 37.0472 6.63818 38.6327 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2338" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.81915 32.813 12.3077 32.7539 14.6244 33.4734C18.9372 34.6076 18.0131 29.4126 24.9583 32.1852C31.9036 34.9577 34.354 32.1852 34.354 32.1852L40.0671 28.7965C40.0671 28.1944 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2338)">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.81915 32.813 12.3077 32.7539 14.6244 33.4734C18.9372 34.6076 18.0131 29.4126 24.9583 32.1852C31.9036 34.9577 34.354 32.1852 34.354 32.1852L40.0671 28.7965C40.0671 28.1944 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 20C20.5012 20 19.2556 14.7018 19.2556 5.10345L21.1787 3.24138L24.2556 2C24.2556 11.5983 22.6254 20 21.5633 20Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M35.1256 20.4341C34.3613 24.9281 36.4235 26.6873 36.0774 31.3571C35.8755 34.2198 35.2121 34.1078 34.4911 37.7542C33.597 42.2641 33.9864 46.2143 34.8084 53.9547C35.3563 59.0883 38.8464 71.518 38.1687 71.7259C37.4909 71.9338 33.8133 60.8155 32.5587 60.9755C31.3042 61.1354 32.7267 72.5876 31.5586 72.9874C30.0301 73.4512 26.7472 60.9755 26.5309 60.6556C24.7428 57.4571 25.4206 55.8578 24.3102 52.8992C22.8537 48.7251 22.2998 48.0361 19.7041 43.926C17.7141 40.7274 17.0278 41.0966 14.807 34.8755C12.0383 27.1351 9.71655 25.6158 10.3655 21.8575C10.5624 20.6495 11.012 19.5095 11.6782 18.529C12.3444 17.5486 13.2086 16.7551 14.2013 16.2121C16.6528 15.1406 17.8642 18.4671 21.8154 18.675C23.8343 18.7869 23.7766 18.0033 29.4439 16.5639C34.5344 15.2685 35.5294 18.0512 35.1256 20.4341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 40.2556 72)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 31.2556 73)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2338)">
        <path d="M31.2556 70L30.4327 73.7391L27.3989 71.4037L29.172 74.797L25.3468 74.9581L28.8862 76.4178L26.0595 79L29.7091 77.8431L29.2035 81.6382L31.2556 78.406L33.3077 81.6382L32.8022 77.8431L36.4518 79L33.6251 76.4178L37.1645 74.9581L33.3393 74.797L35.1123 71.4037L32.0785 73.7391L31.2556 70Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2338" x="25.1468" y="69.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M38.6327 8.58861C40.6887 11.2048 41.9217 14.3725 42.1753 17.6903C42.4554 21.891 40.7751 22.6332 40.187 27.17C40.1169 27.6601 40.0609 28.1922 40.0189 28.7943C39.6969 33.2331 40.7751 33.3591 40.481 38.19C40.159 43.483 38.8427 43.567 38.4786 48.3699C38.2126 51.8845 38.7587 54.9791 39.8789 61.1682C40.2521 64.5981 40.2521 68.0583 39.8789 71.4881C39.8789 71.9782 39.7389 73.2804 38.9547 73.6445C38.1706 74.0086 37.3444 72.9304 36.0282 73.1824C34.964 73.3925 34.866 74.2466 33.7178 74.7227C32.6427 75.057 31.4825 74.9873 30.4552 74.5267C28.1184 73.6787 26.0465 72.2298 24.4481 70.3259C19.7012 64.7249 17.9089 66.2652 13.9602 61.3923C10.3335 56.8974 9.2133 52.2766 7.79904 46.4375C6.24475 39.9823 7.39296 40.0664 5.64265 32.869C4.39642 27.7161 3.47225 26.3018 2.25403 20.3788C1.17583 15.1698 0.657738 12.5093 1.48389 10.0589C3.47225 4.10779 9.88543 2.00741 10.2635 1.88139C11.8038 1.4053 15.4304 0.271091 18.1329 2.03541C20.0793 3.19763 20.0793 4.96195 21.6756 5.19999C23.5379 5.48004 24.168 3.16962 26.9125 2.58151C28.9429 2.14743 30.6792 2.97358 32.7796 3.98177C35.0479 5.0646 37.0472 6.63818 38.6327 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2338" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.81915 32.813 12.3077 32.7539 14.6244 33.4734C18.9372 34.6076 18.0131 29.4126 24.9583 32.1852C31.9036 34.9577 34.354 32.1852 34.354 32.1852L40.0671 28.7965C40.0671 28.1944 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2338)">
        <path d="M2.28818 20.311C3.5064 26.2621 4.43057 27.6623 5.6768 32.8013L7.53914 33.6414C9.81915 32.813 12.3077 32.7539 14.6244 33.4734C18.9372 34.6076 18.0131 29.4126 24.9583 32.1852C31.9036 34.9577 34.354 32.1852 34.354 32.1852L40.0671 28.7965C40.0671 28.1944 40.1651 27.6623 40.2351 27.1722C40.8792 22.6354 42.5035 21.8373 42.2235 17.6925C42.0162 14.7753 41.035 11.9661 39.3811 9.5541C37.7272 7.14213 35.4603 5.2146 32.8138 3.97C30.7274 2.97582 28.9911 2.14967 26.9607 2.56974C24.1602 3.15785 23.5721 5.46827 21.7097 5.18822C20.1274 4.95018 20.0714 3.18585 18.1671 1.95363C15.4646 0.189304 11.8519 1.32351 10.3116 1.7996C9.91957 1.92562 3.5204 4.02601 1.51804 9.9771C0.691891 12.4415 1.20998 15.06 2.28818 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 20C20.5012 20 19.2556 14.7018 19.2556 5.10345L21.1787 3.24138L24.2556 2C24.2556 11.5983 22.6254 20 21.5633 20Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M35.1256 20.4341C34.3613 24.9281 36.4235 26.6873 36.0774 31.3571C35.8755 34.2198 35.2121 34.1078 34.4911 37.7542C33.597 42.2641 33.9864 46.2143 34.8084 53.9547C35.3563 59.0883 38.8464 71.518 38.1687 71.7259C37.4909 71.9338 33.8133 60.8155 32.5587 60.9755C31.3042 61.1354 32.7267 72.5876 31.5586 72.9874C30.0301 73.4512 26.7472 60.9755 26.5309 60.6556C24.7428 57.4571 25.4206 55.8578 24.3102 52.8992C22.8537 48.7251 22.2998 48.0361 19.7041 43.926C17.7141 40.7274 17.0278 41.0966 14.807 34.8755C12.0383 27.1351 9.71655 25.6158 10.3655 21.8575C10.5624 20.6495 11.012 19.5095 11.6782 18.529C12.3444 17.5486 13.2086 16.7551 14.2013 16.2121C16.6528 15.1406 17.8642 18.4671 21.8154 18.675C23.8343 18.7869 23.7766 18.0033 29.4439 16.5639C34.5344 15.2685 35.5294 18.0512 35.1256 20.4341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 40.2556 72)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 31.2556 73)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2338)">
        <path d="M31.2556 70L30.4327 73.7391L27.3989 71.4037L29.172 74.797L25.3468 74.9581L28.8862 76.4178L26.0595 79L29.7091 77.8431L29.2035 81.6382L31.2556 78.406L33.3077 81.6382L32.8022 77.8431L36.4518 79L33.6251 76.4178L37.1645 74.9581L33.3393 74.797L35.1123 71.4037L32.0785 73.7391L31.2556 70Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2338" x="25.1468" y="69.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M38.6327 8.38768C40.6887 11.0039 41.9217 14.1716 42.1753 17.4893C42.4554 21.6901 40.7751 22.4322 40.187 26.9691C40.1169 27.4592 40.0609 27.9913 40.0189 28.5934C39.6969 33.0322 40.7751 33.1582 40.481 37.9891C40.159 43.2821 38.8427 43.3661 38.4786 48.169C38.2126 51.6836 38.7587 54.7782 39.8789 60.9673C40.2521 64.3971 40.2521 67.8573 39.8789 71.2872C39.8789 71.7773 39.7389 73.0795 38.9547 73.4436C38.1706 73.8076 37.3444 72.7294 36.0282 72.9815C34.964 73.1915 34.866 74.0457 33.7178 74.5218C32.6427 74.8561 31.4825 74.7864 30.4552 74.3257C28.1184 73.4778 26.0465 72.0288 24.4481 70.125C19.7012 64.5239 17.9089 66.0642 13.9602 61.1913C10.3335 56.6965 9.2133 52.0757 7.79904 46.2366C6.24475 39.7814 7.39296 39.8654 5.64265 32.6681C4.39642 27.5152 3.47225 26.1009 2.25403 20.1778C1.17583 14.9689 0.657738 12.3084 1.48389 9.85795C3.47225 3.90686 9.88543 1.80648 10.2635 1.68046C11.8038 1.20437 15.4304 0.0701634 18.1329 1.83449C20.0793 2.9967 20.0793 4.76102 21.6756 4.99906C23.5379 5.27912 24.168 2.96869 26.9125 2.38059C28.9429 1.94651 30.6792 2.77266 32.7796 3.78084C35.0479 4.86368 37.0472 6.43725 38.6327 8.38768Z" fill="#DEE1E9"/>
      <mask id="mask2538" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2538)">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 19.7991C20.5012 19.7991 19.2556 14.5008 19.2556 4.90252L21.1787 3.04045L24.2556 1.79907C24.2556 11.3974 22.6254 19.7991 21.5633 19.7991Z" fill="#404040"/>
      </g>
      <circle r="4" transform="matrix(-1 0 0 1 39.2556 71)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2638" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="75">
        <path d="M38.3404 8.58861C40.3964 11.2048 41.6294 14.3725 41.8831 17.6903C42.1631 21.891 40.4828 22.6332 39.8947 27.17C39.8247 27.6601 39.7687 28.1922 39.7267 28.7943C39.4046 33.2331 40.4828 33.3591 40.1888 38.19C39.8667 43.483 38.5505 43.567 38.1864 48.3699C37.9204 51.8845 38.4665 54.9791 39.5867 61.1682C39.9598 64.5981 39.9598 68.0583 39.5867 71.4881C39.5867 71.9782 39.4466 73.2804 38.6625 73.6445C37.8784 74.0086 37.0522 72.9304 35.736 73.1824C34.6718 73.3925 34.5737 74.2466 33.4255 74.7227C32.3504 75.057 31.1903 74.9873 30.1629 74.5267C27.8262 73.6787 25.7542 72.2298 24.1558 70.3259C19.409 64.7249 17.6167 66.2652 13.6679 61.3923C10.0413 56.8974 8.92106 52.2766 7.50681 46.4375C5.95252 39.9823 7.10073 40.0664 5.35041 32.869C4.10418 27.7161 3.18002 26.3018 1.96179 20.3788C0.883595 15.1698 0.365501 12.5093 1.19165 10.0589C3.18002 4.10779 9.59319 2.00741 9.97126 1.88139C11.5115 1.4053 15.1382 0.271091 17.8407 2.03541C19.787 3.19763 19.787 4.96195 21.3833 5.19999C23.2457 5.48004 23.8758 3.16962 26.6203 2.58151C28.6507 2.14743 30.387 2.97358 32.4874 3.98177C34.7557 5.0646 36.755 6.63818 38.3404 8.58861Z" fill="#DEE1E9"/>
        <path d="M1.99594 20.311C3.21416 26.2621 4.13834 27.6623 5.38456 32.8013L7.2469 33.6414C9.52691 32.813 12.0155 32.7539 14.3322 33.4734C18.645 34.6076 17.7208 29.4126 24.6661 32.1852C31.6114 34.9577 34.0618 32.1852 34.0618 32.1852L39.7748 28.7965C39.7748 28.1944 39.8729 27.6623 39.9429 27.1722C40.587 22.6354 42.2113 21.8373 41.9312 17.6925C41.7239 14.7753 40.7428 11.9661 39.0889 9.5541C37.435 7.14213 35.168 5.2146 32.5215 3.97C30.4351 2.97582 28.6988 2.14967 26.6684 2.56974C23.8679 3.15785 23.2798 5.46827 21.4175 5.18822C19.8352 4.95018 19.7792 3.18585 17.8748 1.95363C15.1723 0.189304 11.5597 1.32351 10.0194 1.7996C9.62733 1.92562 3.22817 4.02601 1.2258 9.9771C0.399654 12.4415 0.917747 15.06 1.99594 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2638)">
        <path d="M38.3404 8.58861C40.3964 11.2048 41.6294 14.3725 41.8831 17.6903C42.1631 21.891 40.4828 22.6332 39.8947 27.17C39.8247 27.6601 39.7687 28.1922 39.7267 28.7943C39.4046 33.2331 40.4828 33.3591 40.1888 38.19C39.8667 43.483 38.5505 43.567 38.1864 48.3699C37.9204 51.8845 38.4665 54.9791 39.5867 61.1682C39.9598 64.5981 39.9598 68.0583 39.5867 71.4881C39.5867 71.9782 39.4466 73.2804 38.6625 73.6445C37.8784 74.0086 37.0522 72.9304 35.736 73.1824C34.6718 73.3925 34.5737 74.2466 33.4255 74.7227C32.3504 75.057 31.1903 74.9873 30.1629 74.5267C27.8262 73.6787 25.7542 72.2298 24.1558 70.3259C19.409 64.7249 17.6167 66.2652 13.6679 61.3923C10.0413 56.8974 8.92106 52.2766 7.50681 46.4375C5.95252 39.9823 7.10073 40.0664 5.35041 32.869C4.10418 27.7161 3.18002 26.3018 1.96179 20.3788C0.883595 15.1698 0.365501 12.5093 1.19165 10.0589C3.18002 4.10779 9.59319 2.00741 9.97126 1.88139C11.5115 1.4053 15.1382 0.271091 17.8407 2.03541C19.787 3.19763 19.787 4.96195 21.3833 5.19999C23.2457 5.48004 23.8758 3.16962 26.6203 2.58151C28.6507 2.14743 30.387 2.97358 32.4874 3.98177C34.7557 5.0646 36.755 6.63818 38.3404 8.58861Z" fill="#DEE1E9"/>
        <path d="M1.99594 20.311C3.21416 26.2621 4.13834 27.6623 5.38456 32.8013L7.2469 33.6414C9.52691 32.813 12.0155 32.7539 14.3322 33.4734C18.645 34.6076 17.7208 29.4126 24.6661 32.1852C31.6114 34.9577 34.0618 32.1852 34.0618 32.1852L39.7748 28.7965C39.7748 28.1944 39.8729 27.6623 39.9429 27.1722C40.587 22.6354 42.2113 21.8373 41.9312 17.6925C41.7239 14.7753 40.7428 11.9661 39.0889 9.5541C37.435 7.14213 35.168 5.2146 32.5215 3.97C30.4351 2.97582 28.6988 2.14967 26.6684 2.56974C23.8679 3.15785 23.2798 5.46827 21.4175 5.18822C19.8352 4.95018 19.7792 3.18585 17.8748 1.95363C15.1723 0.189304 11.5597 1.32351 10.0194 1.7996C9.62733 1.92562 3.22817 4.02601 1.2258 9.9771C0.399654 12.4415 0.917747 15.06 1.99594 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M30.9893 20.6762H42.9105C40.7484 26.046 40.3914 29.3063 40.6879 35.0446C40.6991 35.2609 40.4497 35.3955 40.2595 35.2689C39.2554 34.601 36.8972 32.7849 36.3256 30.1432C35.6331 26.9432 33.6299 26.1661 33.4173 26.092C33.4044 26.0875 33.3928 26.083 33.3804 26.0773L32.1975 25.5307C32.0529 25.4639 31.9943 25.3014 32.0666 25.1678L32.5955 24.1901C32.6402 24.1074 32.6362 24.0092 32.5847 23.9299L30.7383 21.0858C30.6213 20.9056 30.7618 20.6762 30.9893 20.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9144 34.155C38.8534 33.3071 37.4454 31.8702 37.043 30.0106C36.2998 26.5761 34.1238 25.6201 33.6909 25.4648L32.9173 25.1073L33.2499 24.4925C33.4065 24.203 33.3922 23.8596 33.2121 23.5821L31.7646 21.3524H41.8672C40.0904 26.0171 39.7144 29.2129 39.9144 34.155ZM33.7076 25.4725C33.7077 25.4726 33.7074 25.4724 33.7076 25.4725V25.4725ZM39.8321 35.8178C40.5609 36.3027 41.4567 35.7463 41.4187 35.0123C41.1259 29.3462 41.4751 26.18 43.5962 20.9121L43.9634 20H30.9893C30.1931 20 29.7013 20.8028 30.1109 21.4338L31.8333 24.0869L31.4122 24.8654C31.1592 25.333 31.3643 25.9017 31.8702 26.1355L33.0532 26.6822C33.0939 26.701 33.1297 26.7145 33.1593 26.7248C33.1959 26.7376 34.9767 27.3577 35.6081 30.2758C36.2376 33.1849 38.801 35.1319 39.8321 35.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask138" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="75">
        <path d="M38.3404 8.58861C40.3964 11.2048 41.6294 14.3725 41.8831 17.6903C42.1631 21.891 40.4828 22.6332 39.8947 27.17C39.8247 27.6601 39.7687 28.1922 39.7267 28.7943C39.4046 33.2331 40.4828 33.3591 40.1888 38.19C39.8667 43.483 38.5505 43.567 38.1864 48.3699C37.9204 51.8845 38.4665 54.9791 39.5867 61.1682C39.9598 64.5981 39.9598 68.0583 39.5867 71.4881C39.5867 71.9782 39.4466 73.2804 38.6625 73.6445C37.8784 74.0086 37.0522 72.9304 35.736 73.1824C34.6718 73.3925 34.5737 74.2466 33.4255 74.7227C32.3504 75.057 31.1903 74.9873 30.1629 74.5267C27.8262 73.6787 25.7542 72.2298 24.1558 70.3259C19.409 64.7249 17.6167 66.2652 13.6679 61.3923C10.0413 56.8974 8.92106 52.2766 7.50681 46.4375C5.95252 39.9823 7.10073 40.0664 5.35041 32.869C4.10418 27.7161 3.18002 26.3018 1.96179 20.3788C0.883595 15.1698 0.365501 12.5093 1.19165 10.0589C3.18002 4.10779 9.59319 2.00741 9.97126 1.88139C11.5115 1.4053 15.1382 0.271091 17.8407 2.03541C19.787 3.19763 19.787 4.96195 21.3833 5.19999C23.2457 5.48004 23.8758 3.16962 26.6203 2.58151C28.6507 2.14743 30.387 2.97358 32.4874 3.98177C34.7557 5.0646 36.755 6.63818 38.3404 8.58861Z" fill="#DEE1E9"/>
        <path d="M1.99594 20.311C3.21416 26.2621 4.13834 27.6623 5.38456 32.8013L7.2469 33.6414C9.52691 32.813 12.0155 32.7539 14.3322 33.4734C18.645 34.6076 17.7208 29.4126 24.6661 32.1852C31.6114 34.9577 34.0618 32.1852 34.0618 32.1852L39.7748 28.7965C39.7748 28.1944 39.8729 27.6623 39.9429 27.1722C40.587 22.6354 42.2113 21.8373 41.9312 17.6925C41.7239 14.7753 40.7428 11.9661 39.0889 9.5541C37.435 7.14213 35.168 5.2146 32.5215 3.97C30.4351 2.97582 28.6988 2.14967 26.6684 2.56974C23.8679 3.15785 23.2798 5.46827 21.4175 5.18822C19.8352 4.95018 19.7792 3.18585 17.8748 1.95363C15.1723 0.189304 11.5597 1.32351 10.0194 1.7996C9.62733 1.92562 3.22817 4.02601 1.2258 9.9771C0.399654 12.4415 0.917747 15.06 1.99594 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask138)">
        <path d="M38.3404 8.58861C40.3964 11.2048 41.6294 14.3725 41.8831 17.6903C42.1631 21.891 40.4828 22.6332 39.8947 27.17C39.8247 27.6601 39.7687 28.1922 39.7267 28.7943C39.4046 33.2331 40.4828 33.3591 40.1888 38.19C39.8667 43.483 38.5505 43.567 38.1864 48.3699C37.9204 51.8845 38.4665 54.9791 39.5867 61.1682C39.9598 64.5981 39.9598 68.0583 39.5867 71.4881C39.5867 71.9782 39.4466 73.2804 38.6625 73.6445C37.8784 74.0086 37.0522 72.9304 35.736 73.1824C34.6718 73.3925 34.5737 74.2466 33.4255 74.7227C32.3504 75.057 31.1903 74.9873 30.1629 74.5267C27.8262 73.6787 25.7542 72.2298 24.1558 70.3259C19.409 64.7249 17.6167 66.2652 13.6679 61.3923C10.0413 56.8974 8.92106 52.2766 7.50681 46.4375C5.95252 39.9823 7.10073 40.0664 5.35041 32.869C4.10418 27.7161 3.18002 26.3018 1.96179 20.3788C0.883595 15.1698 0.365501 12.5093 1.19165 10.0589C3.18002 4.10779 9.59319 2.00741 9.97126 1.88139C11.5115 1.4053 15.1382 0.271091 17.8407 2.03541C19.787 3.19763 19.787 4.96195 21.3833 5.19999C23.2457 5.48004 23.8758 3.16962 26.6203 2.58151C28.6507 2.14743 30.387 2.97358 32.4874 3.98177C34.7557 5.0646 36.755 6.63818 38.3404 8.58861Z" fill="#DEE1E9"/>
        <path d="M1.99594 20.311C3.21416 26.2621 4.13834 27.6623 5.38456 32.8013L7.2469 33.6414C9.52691 32.813 12.0155 32.7539 14.3322 33.4734C18.645 34.6076 17.7208 29.4126 24.6661 32.1852C31.6114 34.9577 34.0618 32.1852 34.0618 32.1852L39.7748 28.7965C39.7748 28.1944 39.8729 27.6623 39.9429 27.1722C40.587 22.6354 42.2113 21.8373 41.9312 17.6925C41.7239 14.7753 40.7428 11.9661 39.0889 9.5541C37.435 7.14213 35.168 5.2146 32.5215 3.97C30.4351 2.97582 28.6988 2.14967 26.6684 2.56974C23.8679 3.15785 23.2798 5.46827 21.4175 5.18822C19.8352 4.95018 19.7792 3.18585 17.8748 1.95363C15.1723 0.189304 11.5597 1.32351 10.0194 1.7996C9.62733 1.92562 3.22817 4.02601 1.2258 9.9771C0.399654 12.4415 0.917747 15.06 1.99594 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M33.097 24.5H41.2438C39.7663 28.4705 39.5223 30.8811 39.725 35.1241C39.7326 35.2841 39.5622 35.3836 39.4322 35.29C38.746 34.7961 37.1345 33.4532 36.7438 31.5C36.2706 29.1339 34.9016 28.5593 34.7563 28.5045C34.7475 28.5012 34.7396 28.4978 34.7311 28.4936L33.9227 28.0894C33.8239 28.04 33.7838 27.9199 33.8332 27.8211L34.1947 27.0982C34.2253 27.037 34.2225 26.9645 34.1873 26.9058L32.9255 24.8029C32.8456 24.6696 32.9416 24.5 33.097 24.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M39.1964 34.4664C38.4713 33.8394 37.5091 32.777 37.2341 31.4019C36.7262 28.8625 35.2392 28.1555 34.9433 28.0407L34.4147 27.7764L34.642 27.3218C34.749 27.1077 34.7392 26.8538 34.6161 26.6486L33.6269 25H40.5309C39.3167 28.4491 39.0597 30.8121 39.1964 34.4664ZM34.9547 28.0464C34.9548 28.0465 34.9546 28.0464 34.9547 28.0464V28.0464ZM39.1401 35.6958C39.6382 36.0544 40.2503 35.643 40.2244 35.1003C40.0243 30.9106 40.263 28.5695 41.7124 24.6744L41.9634 24H33.0971C32.553 24 32.2169 24.5936 32.4968 25.0601L33.6739 27.0219L33.3861 27.5975C33.2132 27.9433 33.3533 28.3638 33.6991 28.5367L34.5075 28.9409C34.5353 28.9548 34.5598 28.9648 34.58 28.9724C34.605 28.9818 35.822 29.4403 36.2535 31.5981C36.6837 33.749 38.4355 35.1887 39.1401 35.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M38.6327 8.38768C40.6887 11.0039 41.9217 14.1716 42.1753 17.4893C42.4554 21.6901 40.7751 22.4322 40.187 26.9691C40.1169 27.4592 40.0609 27.9913 40.0189 28.5934C39.6969 33.0322 40.7751 33.1582 40.481 37.9891C40.159 43.2821 38.8427 43.3661 38.4786 48.169C38.2126 51.6836 38.7587 54.7782 39.8789 60.9673C40.2521 64.3971 40.2521 67.8573 39.8789 71.2872C39.8789 71.7773 39.7389 73.0795 38.9547 73.4436C38.1706 73.8076 37.3444 72.7294 36.0282 72.9815C34.964 73.1915 34.866 74.0457 33.7178 74.5218C32.6427 74.8561 31.4825 74.7864 30.4552 74.3257C28.1184 73.4778 26.0465 72.0288 24.4481 70.125C19.7012 64.5239 17.9089 66.0642 13.9602 61.1913C10.3335 56.6965 9.2133 52.0757 7.79904 46.2366C6.24475 39.7814 7.39296 39.8654 5.64265 32.6681C4.39642 27.5152 3.47225 26.1009 2.25403 20.1778C1.17583 14.9689 0.657738 12.3084 1.48389 9.85795C3.47225 3.90686 9.88543 1.80648 10.2635 1.68046C11.8038 1.20437 15.4304 0.0701634 18.1329 1.83449C20.0793 2.9967 20.0793 4.76102 21.6756 4.99906C23.5379 5.27912 24.168 2.96869 26.9125 2.38059C28.9429 1.94651 30.6792 2.77266 32.7796 3.78084C35.0479 4.86368 37.0472 6.43725 38.6327 8.38768Z" fill="#DEE1E9"/>
      <mask id="mask238" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask238)">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.4095 17C25.0206 17 29.7556 9.53185 29.7556 1L22.7172 2.10345L12.2556 1C12.2556 9.53185 17.7984 17 21.4095 17Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M35.1256 18.5897C34.3613 23.2413 36.4235 25.0623 36.0774 29.896C35.8755 32.8591 35.2121 32.7432 34.4911 36.5175C33.597 41.1856 33.9864 45.2744 34.8084 53.2864C35.3563 58.6002 38.8464 71.466 38.1687 71.6812C37.4909 71.8964 33.8133 60.388 32.5587 60.5536C31.3042 60.7191 32.7267 72.5731 31.5586 72.9869C30.0301 73.467 26.7472 60.5536 26.5309 60.2225C24.7428 56.9117 25.4206 55.2563 24.3102 52.1939C22.8537 47.8734 22.2998 47.1601 19.7041 42.9058C17.7141 39.5951 17.0278 39.9772 14.807 33.5378C12.0383 25.5258 9.71655 23.9532 10.3655 20.063C10.5624 18.8126 11.012 17.6326 11.6782 16.6178C12.3444 15.6029 13.2086 14.7816 14.2013 14.2195C16.6528 13.1104 17.8642 16.5536 21.8154 16.7688C23.8343 16.8847 23.7766 16.0736 29.4439 14.5837C34.5344 13.2429 35.5294 16.1232 35.1256 18.5897Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M38.6327 8.38768C40.6887 11.0039 41.9217 14.1716 42.1753 17.4893C42.4554 21.6901 40.7751 22.4322 40.187 26.9691C40.1169 27.4592 40.0609 27.9913 40.0189 28.5934C39.6969 33.0322 40.7751 33.1582 40.481 37.9891C40.159 43.2821 38.8427 43.3661 38.4786 48.169C38.2126 51.6836 38.7587 54.7782 39.8789 60.9673C40.2521 64.3971 40.2521 67.8573 39.8789 71.2872C39.8789 71.7773 39.7389 73.0795 38.9547 73.4436C38.1706 73.8076 37.3444 72.7294 36.0282 72.9815C34.964 73.1915 34.866 74.0457 33.7178 74.5218C32.6427 74.8561 31.4825 74.7864 30.4552 74.3257C28.1184 73.4778 26.0465 72.0288 24.4481 70.125C19.7012 64.5239 17.9089 66.0642 13.9602 61.1913C10.3335 56.6965 9.2133 52.0757 7.79904 46.2366C6.24475 39.7814 7.39296 39.8654 5.64265 32.6681C4.39642 27.5152 3.47225 26.1009 2.25403 20.1778C1.17583 14.9689 0.657738 12.3084 1.48389 9.85795C3.47225 3.90686 9.88543 1.80648 10.2635 1.68046C11.8038 1.20437 15.4304 0.0701634 18.1329 1.83449C20.0793 2.9967 20.0793 4.76102 21.6756 4.99906C23.5379 5.27912 24.168 2.96869 26.9125 2.38059C28.9429 1.94651 30.6792 2.77266 32.7796 3.78084C35.0479 4.86368 37.0472 6.43725 38.6327 8.38768Z" fill="#DEE1E9"/>
      <mask id="mask338" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask338)">
        <path d="M2.28818 20.1101C3.5064 26.0611 4.43057 27.4614 5.6768 32.6003L7.53914 33.4405C9.81915 32.612 12.3077 32.553 14.6244 33.2725C18.9372 34.4067 18.0131 29.2117 24.9583 31.9842C31.9036 34.7567 34.354 31.9842 34.354 31.9842L40.0671 28.5956C40.0671 27.9935 40.1651 27.4614 40.2351 26.9713C40.8792 22.4345 42.5035 21.6363 42.2235 17.4916C42.0162 14.5744 41.035 11.7651 39.3811 9.35317C37.7272 6.9412 35.4603 5.01368 32.8138 3.76907C30.7274 2.77489 28.9911 1.94874 26.9607 2.36882C24.1602 2.95692 23.5721 5.26734 21.7097 4.98729C20.1274 4.74925 20.0714 2.98492 18.1671 1.7527C15.4646 -0.0116233 11.8519 1.12258 10.3116 1.59867C9.91957 1.72469 3.5204 3.82508 1.51804 9.77617C0.691891 12.2406 1.20998 14.8591 2.28818 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 19.7991C20.5012 19.7991 19.2556 14.5008 19.2556 4.90252L21.1787 3.04045L24.2556 1.79907C24.2556 11.3974 22.6254 19.7991 21.5633 19.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M38.3771 8.58861C40.4331 11.2048 41.666 14.3725 41.9197 17.6903C42.1998 21.891 40.5195 22.6332 39.9313 27.17C39.8613 27.6601 39.8053 28.1922 39.7633 28.7943C39.4413 33.2331 40.5194 33.3591 40.2254 38.19C39.9033 43.483 38.5871 43.567 38.223 48.3699C37.957 51.8845 38.5031 54.9791 39.6233 61.1682C39.9964 64.5981 39.9964 68.0583 39.6233 71.4881C39.6233 71.9782 39.4833 73.2804 38.6991 73.6445C37.915 74.0086 37.0888 72.9304 35.7726 73.1824C34.7084 73.3925 34.6104 74.2466 33.4622 74.7227C32.3871 75.057 31.2269 74.9873 30.1996 74.5267C27.8628 73.6787 25.7908 72.2298 24.1925 70.3259C19.4456 64.7249 17.6533 66.2652 13.7046 61.3923C10.0779 56.8974 8.95768 52.2766 7.54343 46.4375C5.98914 39.9823 7.13735 40.0664 5.38703 32.869C4.1408 27.7161 3.21664 26.3018 1.99841 20.3788C0.920216 15.1698 0.402122 12.5093 1.22828 10.0589C3.21664 4.10779 9.62981 2.00741 10.0079 1.88139C11.5482 1.4053 15.1748 0.271091 17.8773 2.03541C19.8237 3.19763 19.8237 4.96195 21.42 5.19999C23.2823 5.48004 23.9124 3.16962 26.6569 2.58151C28.6873 2.14743 30.4236 2.97358 32.524 3.98177C34.7923 5.0646 36.7916 6.63818 38.3771 8.58861Z" fill="#DEE1E9"/>
      <path d="M2.03257 20.311C3.25079 26.2621 4.17496 27.6623 5.42118 32.8013L7.28352 33.6414C9.56353 32.813 12.0521 32.7539 14.3688 33.4734C18.6816 34.6076 17.7574 29.4126 24.7027 32.1852C31.648 34.9577 34.0984 32.1852 34.0984 32.1852L39.8115 28.7965C39.8115 28.1944 39.9095 27.6623 39.9795 27.1722C40.6236 22.6354 42.2479 21.8373 41.9679 17.6925C41.7606 14.7753 40.7794 11.9661 39.1255 9.5541C37.4716 7.14213 35.2046 5.2146 32.5581 3.97C30.4718 2.97582 28.7354 2.14967 26.7051 2.56974C23.9046 3.15785 23.3165 5.46827 21.4541 5.18822C19.8718 4.95018 19.8158 3.18585 17.9115 1.95363C15.209 0.189304 11.5963 1.32351 10.056 1.7996C9.66396 1.92562 3.26479 4.02601 1.26242 9.9771C0.436275 12.4415 0.954369 15.06 2.03257 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M10 3L8.13333 5.50086L6.5 4.00515V7.3192L3.93782 6.75129L5.33333 8.50189L3 10.5026L5.33333 12.5033L3.93782 14.2539L6.26667 13.5036L6.5 17L8.7499 15.503L9.53333 16.5046L11.2501 15.503L13.5 17L14.2 15.0041L16.0622 14.2539L15.6 11.5029L17 10.5026L14.6667 9.00206L16.0622 6.75129L14.2 6.5012L13.5 4.00515L10.4667 6.00103L10 3Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M38.3771 8.58861C40.4331 11.2048 41.666 14.3725 41.9197 17.6903C42.1998 21.891 40.5195 22.6332 39.9313 27.17C39.8613 27.6601 39.8053 28.1922 39.7633 28.7943C39.4413 33.2331 40.5194 33.3591 40.2254 38.19C39.9033 43.483 38.5871 43.567 38.223 48.3699C37.957 51.8845 38.5031 54.9791 39.6233 61.1682C39.9964 64.5981 39.9964 68.0583 39.6233 71.4881C39.6233 71.9782 39.4833 73.2804 38.6991 73.6445C37.915 74.0086 37.0888 72.9304 35.7726 73.1824C34.7084 73.3925 34.6104 74.2466 33.4622 74.7227C32.3871 75.057 31.2269 74.9873 30.1996 74.5267C27.8628 73.6787 25.7908 72.2298 24.1925 70.3259C19.4456 64.7249 17.6533 66.2652 13.7046 61.3923C10.0779 56.8974 8.95768 52.2766 7.54343 46.4375C5.98914 39.9823 7.13735 40.0664 5.38703 32.869C4.1408 27.7161 3.21664 26.3018 1.99841 20.3788C0.920216 15.1698 0.402122 12.5093 1.22828 10.0589C3.21664 4.10779 9.62981 2.00741 10.0079 1.88139C11.5482 1.4053 15.1748 0.271091 17.8773 2.03541C19.8237 3.19763 19.8237 4.96195 21.42 5.19999C23.2823 5.48004 23.9124 3.16962 26.6569 2.58151C28.6873 2.14743 30.4236 2.97358 32.524 3.98177C34.7923 5.0646 36.7916 6.63818 38.3771 8.58861Z" fill="#DEE1E9"/>
      <path d="M2.03257 20.311C3.25079 26.2621 4.17496 27.6623 5.42118 32.8013L7.28352 33.6414C9.56353 32.813 12.0521 32.7539 14.3688 33.4734C18.6816 34.6076 17.7574 29.4126 24.7027 32.1852C31.648 34.9577 34.0984 32.1852 34.0984 32.1852L39.8115 28.7965C39.8115 28.1944 39.9095 27.6623 39.9795 27.1722C40.6236 22.6354 42.2479 21.8373 41.9679 17.6925C41.7606 14.7753 40.7794 11.9661 39.1255 9.5541C37.4716 7.14213 35.2046 5.2146 32.5581 3.97C30.4718 2.97582 28.7354 2.14967 26.7051 2.56974C23.9046 3.15785 23.3165 5.46827 21.4541 5.18822C19.8718 4.95018 19.8158 3.18585 17.9115 1.95363C15.209 0.189304 11.5963 1.32351 10.056 1.7996C9.66396 1.92562 3.26479 4.02601 1.26242 9.9771C0.436275 12.4415 0.954369 15.06 2.03257 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M9 3L7.4 5.14359L6 3.86156V6.70217L3.80385 6.21539L5 7.71591L3 9.43078L5 11.1457L3.80385 12.6462L5.8 12.0031L6 15L7.92849 13.7168L8.6 14.5754L10.0715 13.7168L12 15L12.6 13.2892L14.1962 12.6462L13.8 10.2882L15 9.43078L13 8.14462L14.1962 6.21539L12.6 6.00103L12 3.86156L9.4 5.57231L9 3Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M38.3771 8.58861C40.4331 11.2048 41.666 14.3725 41.9197 17.6903C42.1998 21.891 40.5195 22.6332 39.9313 27.17C39.8613 27.6601 39.8053 28.1922 39.7633 28.7943C39.4413 33.2331 40.5194 33.3591 40.2254 38.19C39.9033 43.483 38.5871 43.567 38.223 48.3699C37.957 51.8845 38.5031 54.9791 39.6233 61.1682C39.9964 64.5981 39.9964 68.0583 39.6233 71.4881C39.6233 71.9782 39.4833 73.2804 38.6991 73.6445C37.915 74.0086 37.0888 72.9304 35.7726 73.1824C34.7084 73.3925 34.6104 74.2466 33.4622 74.7227C32.3871 75.057 31.2269 74.9873 30.1996 74.5267C27.8628 73.6787 25.7908 72.2298 24.1925 70.3259C19.4456 64.7249 17.6533 66.2652 13.7046 61.3923C10.0779 56.8974 8.95768 52.2766 7.54343 46.4375C5.98914 39.9823 7.13735 40.0664 5.38703 32.869C4.1408 27.7161 3.21664 26.3018 1.99841 20.3788C0.920216 15.1698 0.402122 12.5093 1.22828 10.0589C3.21664 4.10779 9.62981 2.00741 10.0079 1.88139C11.5482 1.4053 15.1748 0.271091 17.8773 2.03541C19.8237 3.19763 19.8237 4.96195 21.42 5.19999C23.2823 5.48004 23.9124 3.16962 26.6569 2.58151C28.6873 2.14743 30.4236 2.97358 32.524 3.98177C34.7923 5.0646 36.7916 6.63818 38.3771 8.58861Z" fill="#DEE1E9"/>
      <path d="M2.03257 20.311C3.25079 26.2621 4.17496 27.6623 5.42118 32.8013L7.28352 33.6414C9.56353 32.813 12.0521 32.7539 14.3688 33.4734C18.6816 34.6076 17.7574 29.4126 24.7027 32.1852C31.648 34.9577 34.0984 32.1852 34.0984 32.1852L39.8115 28.7965C39.8115 28.1944 39.9095 27.6623 39.9795 27.1722C40.6236 22.6354 42.2479 21.8373 41.9679 17.6925C41.7606 14.7753 40.7794 11.9661 39.1255 9.5541C37.4716 7.14213 35.2046 5.2146 32.5581 3.97C30.4718 2.97582 28.7354 2.14967 26.7051 2.56974C23.9046 3.15785 23.3165 5.46827 21.4541 5.18822C19.8718 4.95018 19.8158 3.18585 17.9115 1.95363C15.209 0.189304 11.5963 1.32351 10.056 1.7996C9.66396 1.92562 3.26479 4.02601 1.26242 9.9771C0.436275 12.4415 0.954369 15.06 2.03257 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M7.5 3L6.56667 4.25043L5.75 3.50258V5.1596L4.46891 4.87564L5.16667 5.75094L4 6.75129L5.16667 7.75163L4.46891 8.62693L5.63333 8.2518L5.75 10L6.87495 9.25148L7.26667 9.75232L8.12505 9.25148L9.25 10L9.6 9.00206L10.5311 8.62693L10.3 7.25146L11 6.75129L9.83333 6.00103L10.5311 4.87564L9.6 4.7506L9.25 3.50258L7.73333 4.50052L7.5 3Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M26.5259 72.6565L24.4422 73.9263H20.9584L18.8585 72.6565L20.4701 71.11L18.3864 69.5635L20.2747 68.017L17.9794 66.324L19.8026 64.7775L17.5073 63.3775L19.5259 61.9612L17.5073 60.3496L19.2003 58.803L16.9701 57.3868L18.7282 55.9217L16.4329 54.3751L18.5817 52.8286L16.4329 51.217L17.9794 49.8659L15.8957 48.3193L17.7189 46.838L15.6352 45.2914L17.4422 43.5496L16.4329 41.8728V27.6124L18.3212 20.2868H24.4422" fill="#8889BE"/>
      <path d="M26.5259 72.6565L24.4422 73.9263H20.9584L18.8585 72.6565L20.4701 71.11L18.3864 69.5635L20.2747 68.017L17.9794 66.324L19.8026 64.7775L17.5073 63.3775L19.5259 61.9612L17.5073 60.3496L19.2003 58.803L16.9701 57.3868L18.7282 55.9217L16.4329 54.3751L18.5817 52.8286L16.4329 51.217L17.9794 49.8659L15.8957 48.3193L17.7189 46.838L15.6352 45.2914L17.4422 43.5496L16.4329 41.8728V27.6124L18.3212 20.2868H24.4422" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M19.1186 72.6565L21.2023 73.9263H24.6698L26.786 72.6565L25.1581 71.11L27.2581 69.5635L25.3698 68.017L27.6488 66.324L25.8418 64.7775L28.1209 63.3775L26.1023 61.9612L28.1209 60.3496L26.4442 58.803L28.6581 57.3868L26.9163 55.9217L29.1953 54.3751L27.0465 52.8286L29.1953 51.217L27.6488 49.8659L29.7325 48.3193L27.9256 46.838L30.0093 45.2914L28.186 43.5496L29.1953 41.8728V27.6124L27.3232 20.2868H23.4163" fill="#8889BE"/>
      <path d="M19.1186 72.6565L21.2023 73.9263H24.6698L26.786 72.6565L25.1581 71.11L27.2581 69.5635L25.3698 68.017L27.6488 66.324L25.8418 64.7775L28.1209 63.3775L26.1023 61.9612L28.1209 60.3496L26.4442 58.803L28.6581 57.3868L26.9163 55.9217L29.1953 54.3751L27.0465 52.8286L29.1953 51.217L27.6488 49.8659L29.7325 48.3193L27.9256 46.838L30.0093 45.2914L28.186 43.5496L29.1953 41.8728V27.6124L27.3232 20.2868H23.4163" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask538" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M2.27976 19.915C3.49798 25.8661 4.42215 27.2663 5.66838 32.4053L7.53072 33.2454C9.51358 32.5249 12.0192 31.878 14.1625 32.1949C14.6933 32.2734 15.2421 32.0058 15.4067 31.4951C17.1187 26.1809 17.2901 21.104 17.2901 21.104C22.1712 19.2944 23.1673 19.2542 28.0484 21.104L30.5161 30.3037C30.6438 30.7798 31.0994 31.0918 31.5895 31.0388L40.2267 30.104C40.2267 29.5019 40.1567 27.2663 40.2267 26.7762C40.8708 22.2394 42.4951 21.4413 42.215 17.2965C42.0077 14.3793 41.0266 11.5701 39.3727 9.1581C37.7188 6.74613 35.4518 4.81861 32.8053 3.574C30.719 2.57982 28.9826 1.75367 26.9523 2.17375C24.1518 2.76185 23.5636 5.07227 21.7013 4.79222C20.119 4.55418 20.063 2.78986 18.1587 1.55763C15.4562 -0.206692 11.8435 0.927515 10.3032 1.4036C9.91115 1.52962 3.51198 3.63001 1.50962 9.5811C0.683468 12.0456 1.20156 14.664 2.27976 19.915Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask538)">
        <path d="M2.27976 19.915C3.49798 25.8661 4.42215 27.2663 5.66838 32.4053L7.53072 33.2454C9.50065 32.5296 11.351 31.8865 13.312 32.1888C13.8424 32.2706 14.3909 32.0058 14.5538 31.4945C16.2472 26.1806 16.2472 21.104 16.2472 21.104C21.1283 19.2944 23.8661 19.2542 28.7472 21.104L31.0352 30.2559C31.1566 30.7416 31.6184 31.0627 32.116 31.0072L40.2267 30.104C40.2267 29.5019 40.1567 27.2663 40.2267 26.7762C40.8708 22.2394 42.4951 21.4413 42.215 17.2965C42.0077 14.3793 41.0266 11.5701 39.3727 9.1581C37.7188 6.74613 35.4518 4.81861 32.8053 3.574C30.719 2.57982 28.9826 1.75367 26.9523 2.17375C24.1518 2.76185 23.5636 5.07227 21.7013 4.79222C20.119 4.55418 20.063 2.78986 18.1587 1.55763C15.4562 -0.206692 11.8435 0.927515 10.3032 1.4036C9.91115 1.52962 3.51198 3.63001 1.50962 9.5811C0.683468 12.0456 1.20156 14.664 2.27976 19.915Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.2472 43.604H35.2472V18.056C25.8746 16.1298 20.6198 16.1102 11.2472 18.056V43.604Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M26.118 72.948L24.0343 74.2178H20.5506L18.4506 72.948L20.0622 71.4015L17.9785 69.855L19.8669 68.3085L17.5715 66.6155L19.3948 65.069L17.0995 63.669L19.1181 62.2527L17.0995 60.6411L18.7925 59.0945L16.5622 57.6783L18.3204 56.2132L16.025 54.6667L18.1739 53.1201L16.025 51.5085L17.5715 50.1574L15.4878 48.6109L17.3111 47.1295L15.2274 45.5829L17.0343 43.8411L16.025 42.1644V27.9039L17.9134 20.5784H24.0343" fill="#8889BE"/>
      <path d="M26.118 72.948L24.0343 74.2178H20.5506L18.4506 72.948L20.0622 71.4015L17.9785 69.855L19.8669 68.3085L17.5715 66.6155L19.3948 65.069L17.0995 63.669L19.1181 62.2527L17.0995 60.6411L18.7925 59.0945L16.5622 57.6783L18.3204 56.2132L16.025 54.6667L18.1739 53.1201L16.025 51.5085L17.5715 50.1574L15.4878 48.6109L17.3111 47.1295L15.2274 45.5829L17.0343 43.8411L16.025 42.1644V27.9039L17.9134 20.5784H24.0343" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M18.7108 72.948L20.7945 74.2178H24.2619L26.3782 72.948L24.7503 71.4015L26.8503 69.855L24.9619 68.3085L27.241 66.6155L25.434 65.069L27.7131 63.669L25.6945 62.2527L27.7131 60.6411L26.0363 59.0945L28.2503 57.6783L26.5084 56.2132L28.7875 54.6667L26.6387 53.1201L28.7875 51.5085L27.241 50.1574L29.3247 48.6109L27.5177 47.1295L29.6014 45.5829L27.7782 43.8411L28.7875 42.1644V27.9039L26.9154 20.5784H23.0084" fill="#8889BE"/>
      <path d="M18.7108 72.948L20.7945 74.2178H24.2619L26.3782 72.948L24.7503 71.4015L26.8503 69.855L24.9619 68.3085L27.241 66.6155L25.434 65.069L27.7131 63.669L25.6945 62.2527L27.7131 60.6411L26.0363 59.0945L28.2503 57.6783L26.5084 56.2132L28.7875 54.6667L26.6387 53.1201L28.7875 51.5085L27.241 50.1574L29.3247 48.6109L27.5177 47.1295L29.6014 45.5829L27.7782 43.8411L28.7875 42.1644V27.9039L26.9154 20.5783H23.0084" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask538" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M1.87192 20.2065C3.09015 26.1576 4.01431 27.5578 5.26054 32.6968L7.12288 33.5369C9.10574 32.8164 11.6114 32.1695 13.7546 32.4864C14.2855 32.5649 14.8342 32.2973 15 31.7869C16.7232 26.4831 17 21.5 17 21.5C21.8811 19.6904 22.6721 19.6502 27.5532 21.5L30.1018 30.6066C30.2336 31.0774 30.6864 31.3839 31.1724 31.3313L39.8188 30.3955C39.8188 29.7934 39.7488 27.5578 39.8188 27.0677C40.463 22.5309 42.0873 21.7328 41.8072 17.588C41.5999 14.6708 40.6188 11.8616 38.9649 9.44961C37.3109 7.03764 35.044 5.11011 32.3975 3.86551C30.3111 2.87132 28.5748 2.04517 26.5444 2.46525C23.7439 3.05336 23.1558 5.36378 21.2935 5.08373C19.7112 4.84568 19.6552 3.08136 17.7508 1.84913C15.0483 0.0848122 11.4357 1.21902 9.89538 1.69511C9.50331 1.82113 3.10415 3.92152 1.10178 9.87261C0.275631 12.3371 0.793724 14.9555 1.87192 20.2065Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask538)">
        <path d="M1.87192 20.2065C3.09015 26.1576 4.01431 27.5578 5.26054 32.6968L7.12288 33.5369C9.09281 32.8211 10.9431 32.178 12.9042 32.4803C13.4345 32.5621 13.9823 32.2973 14.1522 31.7883C15.9265 26.4735 16.671 21.3955 16.671 21.3955C21.5521 19.5859 23.1325 19.5457 28.0136 21.3955L30.6088 30.5798C30.7418 31.0504 31.1958 31.3558 31.6818 31.3017L39.8188 30.3955C39.8188 29.7934 39.7488 27.5578 39.8188 27.0677C40.463 22.5309 42.0873 21.7328 41.8072 17.588C41.5999 14.6708 40.6188 11.8616 38.9649 9.44961C37.3109 7.03764 35.044 5.11011 32.3975 3.86551C30.3111 2.87132 28.5748 2.04517 26.5444 2.46525C23.7439 3.05336 23.1558 5.36378 21.2935 5.08373C19.7112 4.84568 19.6552 3.08136 17.7508 1.84913C15.0483 0.0848122 11.4357 1.21902 9.89538 1.69511C9.50331 1.82113 3.10415 3.92152 1.10178 9.87261C0.275631 12.3371 0.793724 14.9555 1.87192 20.2065Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M21.2556 0.259766H0.255615V5.25977H21.2556V0.259766Z" fill="#838AA2"/>
      <path d="M39.6327 10.2666C41.6887 12.8828 42.9217 16.0505 43.1753 19.3683C43.4554 23.569 41.7751 24.3112 41.187 28.848C41.1169 29.3381 41.0609 29.8702 41.0189 30.4723C40.6969 34.9111 41.7751 35.0371 41.481 39.868C41.159 45.161 39.8427 45.245 39.4786 50.0479C39.2126 53.5625 39.7587 56.6571 40.8789 62.8462C41.2521 66.276 41.2521 69.7362 40.8789 73.1661C40.8789 73.6562 40.7389 74.9584 39.9547 75.3225C39.1706 75.6865 38.3444 74.6084 37.0282 74.8604C35.964 75.0704 35.866 75.9246 34.7178 76.4007C33.6427 76.735 32.4825 76.6653 31.4552 76.2046C29.1184 75.3567 27.0465 73.9077 25.4481 72.0039C20.7012 66.4029 18.9089 67.9431 14.9602 63.0702C11.3335 58.5754 10.2133 53.9546 8.79904 48.1155C7.24475 41.6603 8.39296 41.7443 6.64265 34.547C5.39642 29.3941 4.47225 27.9798 3.25403 22.0567C2.17583 16.8478 1.65774 14.1873 2.48389 11.7369C4.47225 5.78577 10.8854 3.68539 11.2635 3.55936C12.8038 3.08328 16.4304 1.94907 19.1329 3.71339C21.0793 4.8756 21.0793 6.63993 22.6756 6.87797C24.5379 7.15802 25.168 4.8476 27.9125 4.25949C29.9429 3.82541 31.6792 4.65156 33.7796 5.65975C36.0479 6.74258 38.0472 8.31615 39.6327 10.2666Z" fill="#DEE1E9"/>
      <path d="M3.28818 21.9887C4.5064 27.9398 5.43057 29.3401 6.6768 34.479L8.53914 35.3191C10.5091 34.6034 12.3594 33.9602 14.3204 34.2626C14.8508 34.3443 15.3993 34.0796 15.5622 33.5683C17.2556 28.2543 17.2556 23.1777 17.2556 23.1777C22.1367 21.3682 24.8745 21.328 29.7556 23.1777L32.0436 32.3297C32.165 32.8153 32.6269 33.1364 33.1244 33.081L41.2351 32.1777C41.2351 31.5756 41.1651 29.3401 41.2351 28.85C41.8792 24.3131 43.5035 23.515 43.2235 19.3702C43.0162 16.453 42.035 13.6438 40.3811 11.2318C38.7272 8.81986 36.4603 6.89234 33.8138 5.64773C31.7274 4.65355 29.9911 3.8274 27.9607 4.24748C25.1602 4.83558 24.5721 7.14601 22.7097 6.86595C21.1274 6.62791 21.0714 4.86359 19.1671 3.63136C16.4646 1.86704 12.8519 3.00125 11.3116 3.47733C10.9196 3.60336 4.5204 5.70374 2.51804 11.6548C1.69189 14.1193 2.20998 16.7378 3.28818 21.9887Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M38.0934 9.01171C40.1494 11.6279 41.3824 14.7956 41.636 18.1134C41.9161 22.3141 40.2358 23.0563 39.6477 27.5931C39.5776 28.0832 39.5216 28.6153 39.4796 29.2174C39.1576 33.6562 40.2358 33.7822 39.9417 38.6131C39.6196 43.9061 38.3034 43.9901 37.9393 48.793C37.6733 52.3076 38.2194 55.4022 39.3396 61.5913C39.7128 65.0212 39.7128 68.4814 39.3396 71.9112C39.3396 72.4013 39.1996 73.7035 38.4154 74.0676C37.6313 74.4317 36.8051 73.3535 35.4889 73.6055C34.4247 73.8156 34.3267 74.6697 33.1785 75.1458C32.1034 75.4801 30.9432 75.4104 29.9159 74.9498C27.5791 74.1018 25.5072 72.6528 23.9088 70.749C19.1619 65.148 17.3696 66.6882 13.4209 61.8154C9.7942 57.3205 8.67399 52.6997 7.25974 46.8606C5.70545 40.4054 6.85366 40.4895 5.10334 33.2921C3.85711 28.1392 2.93295 26.7249 1.71472 20.8019C0.636524 15.5929 0.118431 12.9324 0.944584 10.482C2.93295 4.53089 9.34612 2.4305 9.72419 2.30448C11.2645 1.82839 14.8911 0.694187 17.5936 2.45851C19.54 3.62072 19.54 5.38504 21.1363 5.62309C22.9986 5.90314 23.6287 3.59272 26.3732 3.00461C28.4036 2.57053 30.1399 3.39668 32.2403 4.40486C34.5086 5.4877 36.5079 7.06127 38.0934 9.01171Z" fill="#DEE1E9"/>
      <mask id="mask538" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M1.74887 20.7338C2.96709 26.6849 3.89127 28.0852 5.13749 33.2241L6.99983 34.0643C9.27984 33.2358 11.7684 33.1768 14.0851 33.8962C18.3979 35.0304 17.4737 29.8355 24.419 32.608C31.3643 35.3805 33.8147 32.608 33.8147 32.608L39.5278 29.2194C39.5278 28.6173 39.6258 28.0852 39.6958 27.5951C40.3399 23.0583 41.9642 22.2601 41.6842 18.1154C41.4769 15.1981 40.4957 12.3889 38.8418 9.97695C37.1879 7.56498 34.921 5.63745 32.2744 4.39285C30.1881 3.39867 28.4517 2.57252 26.4214 2.99259C23.6209 3.5807 23.0328 5.89112 21.1704 5.61107C19.5881 5.37303 19.5321 3.6087 17.6278 2.37648C14.9253 0.612156 11.3126 1.74636 9.77234 2.22245C9.38026 2.34847 2.9811 4.44886 0.978733 10.3999C0.152584 12.8644 0.670677 15.4829 1.74887 20.7338Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask538)">
        <path d="M1.74887 20.7338C2.96709 26.6849 3.89127 28.0852 5.13749 33.2241L6.99983 34.0643C9.27984 33.2358 11.7684 33.1768 14.0851 33.8962C18.3979 35.0304 17.4737 29.8355 24.419 32.608C31.3643 35.3805 33.8147 32.608 33.8147 32.608L39.5278 29.2194C39.5278 28.6173 39.6258 28.0852 39.6958 27.5951C40.3399 23.0583 41.9642 22.2601 41.6842 18.1154C41.4769 15.1981 40.4957 12.3889 38.8418 9.97695C37.1879 7.56498 34.921 5.63745 32.2744 4.39285C30.1881 3.39867 28.4517 2.57252 26.4214 2.99259C23.6209 3.5807 23.0328 5.89112 21.1704 5.61107C19.5881 5.37303 19.5321 3.6087 17.6278 2.37648C14.9253 0.612156 11.3126 1.74636 9.77234 2.22245C9.38026 2.34847 2.9811 4.44886 0.978733 10.3999C0.152584 12.8644 0.670677 15.4829 1.74887 20.7338Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <g opacity="0.2">
        <path d="M35.538 31.78C35.8841 27.1101 33.8219 25.3509 34.5862 20.857C34.99 18.4741 33.995 15.6914 28.9045 16.9868C23.2373 18.4261 23.2949 19.2098 21.276 19.0978C17.3248 18.8899 16.1135 15.5634 13.662 16.6349C12.6692 17.1779 11.805 17.9715 11.1388 18.9519C10.4727 19.9323 10.0231 21.0723 9.8261 22.2803C9.17718 26.0386 11.4989 27.5579 14.2676 35.2983L17.2163 44.6933C17.1442 47.9818 17.5403 49.9011 17.9364 53.3781L17.9492 53.4904C18.2622 56.2396 18.4541 57.9254 20.1688 62.3797C21.6934 66.4747 24.4845 69.5137 25.2163 69.5137C25.9336 69.5137 24.2834 66.6306 23.2345 64.798L23.1721 64.689C22.2162 61.5137 21.9678 60.9464 21.6476 57.2206C21.5104 55.5423 22.1599 52.9535 22.7849 52.7857C23.6844 52.5508 25.4528 57.3842 25.834 58.3408C29.4013 67.991 31.5729 71.3123 32.7162 71.5137C33.8596 71.7151 31.695 69.2638 30.2162 62.0137C29.6369 59.1774 29.1917 57.9221 27.9416 54.2971C25.6854 47.8189 26.9468 41.5413 27.3737 41.4238C27.8006 41.3063 30.9079 56.008 34.4295 63.5603C35.4814 65.8092 37.8688 68.6815 38.4786 68.5137C39.0884 68.3458 35.3174 64.7354 35.2259 58.5425C35.2259 54.2758 33.8504 44.7785 33.9638 38.1162C34.6802 34.5319 35.3372 34.6266 35.538 31.78Z" fill="#F32A66"/>
        <path d="M33.9517 38.177C34.6728 34.5307 35.3361 34.6426 35.538 31.78C35.8841 27.1101 33.8219 25.3509 34.5862 20.857C34.99 18.4741 33.995 15.6914 28.9045 16.9868C23.2373 18.4261 23.2949 19.2098 21.276 19.0978C17.3248 18.8899 16.1135 15.5634 13.662 16.6349C12.6692 17.1779 11.805 17.9715 11.1388 18.9519C10.4727 19.9323 10.0231 21.0723 9.8261 22.2803C9.17718 26.0386 11.4989 27.5579 14.2676 35.2983L17.2163 44.6933C17.1442 47.9818 17.5403 49.9011 17.9364 53.3781C18.2573 56.1944 18.4309 57.8651 20.1688 62.3797C21.6934 66.4747 24.4845 69.5137 25.2163 69.5137C25.948 69.5137 24.2162 66.5137 23.1721 64.689C22.2162 61.5137 21.9678 60.9464 21.6476 57.2206C21.5104 55.5423 22.1599 52.9535 22.7849 52.7857C23.6844 52.5508 25.4528 57.3842 25.834 58.3408C29.4013 67.991 31.5729 71.3123 32.7162 71.5137C33.8596 71.7151 31.695 69.2638 30.2162 62.0137C29.6369 59.1774 29.1917 57.9221 27.9416 54.2971C25.6854 47.8189 26.9468 41.5413 27.3737 41.4238C27.8006 41.3063 30.9079 56.008 34.4295 63.5603C35.4814 65.8092 37.8688 68.6815 38.4786 68.5137C39.0884 68.3458 35.3174 64.7354 35.2259 58.5425C35.2259 54.1454 33.765 44.1932 33.9785 37.5137" stroke="#F32A66" fill="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M38.0934 8.10253C40.1494 10.7187 41.3824 13.8865 41.636 17.2042C41.9161 21.405 40.2358 22.1471 39.6477 26.6839C39.5776 27.174 39.5216 27.7061 39.4796 28.3082C39.1576 32.747 40.2358 32.8731 39.9417 37.7039C39.6196 42.9969 38.3034 43.0809 37.9393 47.8838C37.6733 51.3984 38.2194 54.493 39.3396 60.6821C39.7128 64.112 39.7128 67.5722 39.3396 71.002C39.3396 71.4921 39.1996 72.7943 38.4154 73.1584C37.6313 73.5225 36.8051 72.4443 35.4889 72.6963C34.4247 72.9064 34.3267 73.7605 33.1785 74.2366C32.1034 74.5709 30.9432 74.5012 29.9159 74.0406C27.5791 73.1926 25.5072 71.7437 23.9088 69.8398C19.1619 64.2388 17.3696 65.7791 13.4209 60.9062C9.7942 56.4114 8.67399 51.7905 7.25974 45.9514C5.70545 39.4963 6.85366 39.5803 5.10334 32.383C3.85711 27.23 2.93295 25.8158 1.71472 19.8927C0.636524 14.6837 0.118431 12.0232 0.944584 9.5728C2.93295 3.62171 9.34612 1.52132 9.72419 1.3953C11.2645 0.919214 14.8911 -0.214993 17.5936 1.54933C19.54 2.71154 19.54 4.47586 21.1363 4.71391C22.9986 4.99396 23.6287 2.68354 26.3732 2.09543C28.4036 1.66135 30.1399 2.4875 32.2403 3.49569C34.5086 4.57852 36.5079 6.15209 38.0934 8.10253Z" fill="#DEE1E9"/>
      <mask id="mask538" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M1.74887 19.8247C2.96709 25.7757 3.89127 27.176 5.13749 32.3149L6.99983 33.1551C9.27984 32.3266 11.7684 32.2676 14.0851 32.9871C18.3979 34.1213 17.4737 28.9263 24.419 31.6988C31.3643 34.4713 33.8147 31.6988 33.8147 31.6988L39.5278 28.3102C39.5278 27.7081 39.6258 27.176 39.6958 26.6859C40.3399 22.1491 41.9642 21.3509 41.6842 17.2062C41.4769 14.289 40.4957 11.4797 38.8418 9.06777C37.1879 6.6558 34.921 4.72827 32.2744 3.48367C30.1881 2.48949 28.4517 1.66334 26.4214 2.08341C23.6209 2.67152 23.0328 4.98194 21.1704 4.70189C19.5881 4.46385 19.5321 2.69952 17.6278 1.4673C14.9253 -0.297024 11.3126 0.837183 9.77234 1.31327C9.38026 1.43929 2.9811 3.53968 0.978733 9.49077C0.152584 11.9552 0.670677 14.5737 1.74887 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask538)">
        <path d="M1.74887 19.8247C2.96709 25.7757 3.89127 27.176 5.13749 32.3149L6.99983 33.1551C9.27984 32.3266 11.7684 32.2676 14.0851 32.9871C18.3979 34.1213 17.4737 28.9263 24.419 31.6988C31.3643 34.4713 33.8147 31.6988 33.8147 31.6988L39.5278 28.3102C39.5278 27.7081 39.6258 27.176 39.6958 26.6859C40.3399 22.1491 41.9642 21.3509 41.6842 17.2062C41.4769 14.289 40.4957 11.4797 38.8418 9.06777C37.1879 6.6558 34.921 4.72827 32.2744 3.48367C30.1881 2.48949 28.4517 1.66334 26.4214 2.08341C23.6209 2.67152 23.0328 4.98194 21.1704 4.70189C19.5881 4.46385 19.5321 2.69952 17.6278 1.4673C14.9253 -0.297024 11.3126 0.837183 9.77234 1.31327C9.38026 1.43929 2.9811 3.53968 0.978733 9.49077C0.152584 11.9552 0.670677 14.5737 1.74887 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <g opacity="0.4">
        <path d="M35.538 30.8708C35.8841 26.2009 33.8219 24.4417 34.5862 19.9478C34.99 17.5649 33.995 14.7822 28.9045 16.0776C23.2373 17.5169 23.2949 18.3006 21.276 18.1886C17.3248 17.9807 16.1135 14.6543 13.662 15.7258C12.6692 16.2687 11.805 17.0623 11.1388 18.0427C10.4727 19.0231 10.0231 20.1631 9.8261 21.3712C9.17718 25.1294 11.4989 26.6487 14.2676 34.3892L17.2163 43.7841C17.1442 47.0727 17.5403 48.9919 17.9364 52.4689L17.9492 52.5812C18.2622 55.3304 18.4541 57.0162 20.1688 61.4705C21.6934 65.5655 24.4845 68.6045 25.2163 68.6045C25.9336 68.6045 24.2834 65.7214 23.2345 63.8888L23.1721 63.7798C22.2162 60.6045 21.9678 60.0372 21.6476 56.3114C21.5104 54.6332 22.1599 52.0444 22.7849 51.8765C23.6844 51.6416 25.4528 56.475 25.834 57.4317C29.4013 67.0818 31.5729 70.4031 32.7162 70.6045C33.8596 70.8059 31.695 68.3547 30.2162 61.1045C29.6369 58.2682 29.1917 57.013 27.9416 53.3879C25.6854 46.9097 26.9468 40.6321 27.3737 40.5146C27.8006 40.3971 30.9079 55.0989 34.4295 62.6511C35.4814 64.9 37.8688 67.7723 38.4786 67.6045C39.0884 67.4367 35.3174 63.8262 35.2259 57.6333C35.2259 53.3666 33.8504 43.8693 33.9638 37.207C34.6802 33.6227 35.3372 33.7175 35.538 30.8708Z" fill="#462AF3"/>
        <path d="M33.9517 37.2678C34.6728 33.6215 35.3361 33.7335 35.538 30.8708C35.8841 26.2009 33.8219 24.4417 34.5862 19.9478C34.99 17.5649 33.995 14.7822 28.9045 16.0776C23.2373 17.5169 23.2949 18.3006 21.276 18.1886C17.3248 17.9807 16.1135 14.6543 13.662 15.7258C12.6692 16.2687 11.805 17.0623 11.1388 18.0427C10.4727 19.0231 10.0231 20.1631 9.8261 21.3712C9.17718 25.1294 11.4989 26.6487 14.2676 34.3892L17.2163 43.7841C17.1442 47.0727 17.5403 48.9919 17.9364 52.4689C18.2573 55.2852 18.4309 56.9559 20.1688 61.4705C21.6934 65.5655 24.4845 68.6045 25.2163 68.6045C25.948 68.6045 24.2162 65.6045 23.1721 63.7798C22.2162 60.6045 21.9678 60.0372 21.6476 56.3114C21.5104 54.6332 22.1599 52.0444 22.7849 51.8765C23.6844 51.6416 25.4528 56.475 25.834 57.4317C29.4013 67.0818 31.5729 70.4031 32.7162 70.6045C33.8596 70.8059 31.695 68.3547 30.2162 61.1045C29.6369 58.2682 29.1917 57.013 27.9416 53.3879C25.6854 46.9097 26.9468 40.6321 27.3737 40.5146C27.8006 40.3971 30.9079 55.0989 34.4295 62.6511C35.4814 64.9 37.8688 67.7723 38.4786 67.6045C39.0884 67.4367 35.3174 63.8262 35.2259 57.6333C35.2259 53.2362 33.765 43.284 33.9785 36.6045" stroke="#462AF3" fill="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M38.3603 8.10253C40.4163 10.7187 41.6493 13.8865 41.903 17.2042C42.183 21.405 40.5027 22.1471 39.9146 26.6839C39.8446 27.174 39.7886 27.7061 39.7466 28.3082C39.4245 32.747 40.5027 32.8731 40.2087 37.7039C39.8866 42.9969 38.5704 43.0809 38.2063 47.8838C37.9403 51.3984 38.4864 54.493 39.6066 60.6821C39.9797 64.112 39.9797 67.5722 39.6066 71.002C39.6066 71.4921 39.4665 72.7943 38.6824 73.1584C37.8982 73.5225 37.0721 72.4443 35.7559 72.6963C34.6917 72.9064 34.5936 73.7605 33.4454 74.2366C32.3703 74.5709 31.2102 74.5012 30.1828 74.0406C27.8461 73.1926 25.7741 71.7437 24.1757 69.8398C19.4289 64.2388 17.6366 65.7791 13.6878 60.9062C10.0612 56.4114 8.94096 51.7905 7.5267 45.9514C5.97242 39.4963 7.12062 39.5803 5.37031 32.383C4.12408 27.23 3.19991 25.8158 1.98169 19.8927C0.903492 14.6837 0.385399 12.0232 1.21155 9.5728C3.19991 3.62171 9.61309 1.52132 9.99115 1.3953C11.5314 0.919214 15.1581 -0.214993 17.8606 1.54933C19.8069 2.71154 19.8069 4.47586 21.4032 4.71391C23.2656 4.99396 23.8957 2.68354 26.6402 2.09543C28.6706 1.66135 30.4069 2.4875 32.5073 3.49569C34.7756 4.57852 36.7749 6.15209 38.3603 8.10253Z" fill="#DEE1E9"/>
      <mask id="mask538" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M2.01584 19.8247C3.23406 25.7757 4.15823 27.176 5.40446 32.3149L7.2668 33.1551C9.54681 32.3266 12.0354 32.2676 14.3521 32.9871C18.6649 34.1213 17.7407 28.9263 24.686 31.6988C31.6312 34.4713 34.0817 31.6988 34.0817 31.6988L39.7947 28.3102C39.7947 27.7081 39.8928 27.176 39.9628 26.6859C40.6069 22.1491 42.2312 21.3509 41.9511 17.2062C41.7438 14.289 40.7627 11.4797 39.1088 9.06777C37.4549 6.6558 35.1879 4.72827 32.5414 3.48367C30.455 2.48949 28.7187 1.66334 26.6883 2.08341C23.8878 2.67152 23.2997 4.98194 21.4374 4.70189C19.8551 4.46385 19.7991 2.69952 17.8947 1.4673C15.1922 -0.297024 11.5796 0.837183 10.0393 1.31327C9.64723 1.43929 3.24807 3.53968 1.2457 9.49077C0.419552 11.9552 0.937645 14.5737 2.01584 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask538)">
        <path d="M2.01584 19.8247C3.23406 25.7757 4.15823 27.176 5.40446 32.3149L7.2668 33.1551C9.54681 32.3266 12.0354 32.2676 14.3521 32.9871C18.6649 34.1213 17.7407 28.9263 24.686 31.6988C31.6312 34.4713 34.0817 31.6988 34.0817 31.6988L39.7947 28.3102C39.7947 27.7081 39.8928 27.176 39.9628 26.6859C40.6069 22.1491 42.2312 21.3509 41.9511 17.2062C41.7438 14.289 40.7627 11.4797 39.1088 9.06777C37.4549 6.6558 35.1879 4.72827 32.5414 3.48367C30.455 2.48949 28.7187 1.66334 26.6883 2.08341C23.8878 2.67152 23.2997 4.98194 21.4374 4.70189C19.8551 4.46385 19.7991 2.69952 17.8947 1.4673C15.1922 -0.297024 11.5796 0.837183 10.0393 1.31327C9.64723 1.43929 3.24807 3.53968 1.2457 9.49077C0.419552 11.9552 0.937645 14.5737 2.01584 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5038" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="36" width="30" height="33">
        <rect x="7.98328" y="36.5137" width="29" height="32" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5038)">
        <g opacity="0.4">
          <path d="M33.976 27.8708C34.3221 23.2009 32.2599 21.4417 33.0242 16.9478C33.428 14.5649 32.433 11.7822 27.3425 13.0776C21.6752 14.5169 21.7329 15.3006 19.714 15.1886C15.7628 14.9807 14.5515 11.6543 12.1 12.7258C11.1072 13.2687 10.243 14.0623 9.57684 15.0427C8.91067 16.0231 8.46105 17.1631 8.26409 18.3712C7.61517 22.1294 9.93688 23.6487 12.7056 31.3892L15.6543 40.7841C15.5822 44.0727 15.9783 45.9919 16.3744 49.4689L16.3872 49.5812C16.7002 52.3304 16.8921 54.0162 18.6068 58.4705C20.1313 62.5655 22.9225 65.6045 23.6543 65.6045C24.3716 65.6045 22.7214 62.7214 21.6725 60.8888L21.6101 60.7798C20.6542 57.6045 20.4058 57.0372 20.0856 53.3114C19.9484 51.6332 20.5979 49.0444 21.2229 48.8765C22.1224 48.6416 23.8908 53.475 24.2719 54.4317C27.8393 64.0818 30.0109 67.4031 31.1542 67.6045C32.2976 67.8059 30.133 65.3547 28.6542 58.1045C28.0749 55.2682 27.6297 54.013 26.3796 50.3879C24.1234 43.9097 25.3848 37.6321 25.8117 37.5146C26.2386 37.3971 29.3459 52.0989 32.8675 59.6511C33.9194 61.9 36.3068 64.7723 36.9166 64.6045C37.5264 64.4367 33.7554 60.8262 33.6639 54.6333C33.6639 50.3666 32.2884 40.8693 32.4018 34.207C33.1182 30.6227 33.7752 30.7175 33.976 27.8708Z" fill="#462AF3"/>
          <path d="M32.3897 34.2678C33.1108 30.6215 33.7741 30.7335 33.976 27.8708C34.3221 23.2009 32.2599 21.4417 33.0242 16.9478C33.428 14.5649 32.433 11.7822 27.3425 13.0776C21.6752 14.5169 21.7329 15.3006 19.714 15.1886C15.7628 14.9807 14.5515 11.6543 12.1 12.7258C11.1072 13.2687 10.243 14.0623 9.57684 15.0427C8.91067 16.0231 8.46105 17.1631 8.26409 18.3712C7.61517 22.1294 9.93688 23.6487 12.7056 31.3892L15.6543 40.7841C15.5822 44.0727 15.9783 45.9919 16.3744 49.4689C16.6953 52.2852 16.8689 53.9559 18.6068 58.4705C20.1313 62.5655 22.9225 65.6045 23.6543 65.6045C24.386 65.6045 22.6542 62.6045 21.6101 60.7798C20.6542 57.6045 20.4058 57.0372 20.0856 53.3114C19.9484 51.6332 20.5979 49.0444 21.2229 48.8765C22.1224 48.6416 23.8908 53.475 24.2719 54.4317C27.8393 64.0818 30.0109 67.4031 31.1542 67.6045C32.2976 67.8059 30.133 65.3547 28.6542 58.1045C28.0749 55.2682 27.6297 54.013 26.3796 50.3879C24.1234 43.9097 25.3848 37.6321 25.8117 37.5146C26.2386 37.3971 29.3459 52.0989 32.8675 59.6511C33.9194 61.9 36.3068 64.7723 36.9166 64.6045C37.5264 64.4367 33.7554 60.8262 33.6639 54.6333C33.6639 50.2362 32.203 40.284 32.4165 33.6045" stroke="#462AF3" fill="#462AF3" stroke-miterlimit="10"/>
        </g>
      </g>
      <mask id="mask50038" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="1" width="30" height="36">
        <rect x="8.03162" y="1.10352" width="29.1809" height="35.4105" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask50038)">
        <g opacity="0.2">
          <path d="M33.976 27.8708C34.3221 23.2009 32.2599 21.4417 33.0242 16.9478C33.428 14.5649 32.433 11.7822 27.3425 13.0776C21.6752 14.5169 21.7329 15.3006 19.714 15.1886C15.7628 14.9807 14.5515 11.6543 12.1 12.7258C11.1072 13.2687 10.243 14.0623 9.57684 15.0427C8.91067 16.0231 8.46105 17.1631 8.26409 18.3712C7.61517 22.1294 9.93688 23.6487 12.7056 31.3892L15.6543 40.7841C15.5822 44.0727 15.9783 45.9919 16.3744 49.4689L16.3872 49.5812C16.7002 52.3304 16.8921 54.0162 18.6068 58.4705C20.1313 62.5655 22.9225 65.6045 23.6543 65.6045C24.3716 65.6045 22.7214 62.7214 21.6725 60.8888L21.6101 60.7798C20.6542 57.6045 20.4058 57.0372 20.0856 53.3114C19.9484 51.6332 20.5979 49.0444 21.2229 48.8765C22.1224 48.6416 23.8908 53.475 24.2719 54.4317C27.8393 64.0818 30.0109 67.4031 31.1542 67.6045C32.2976 67.8059 30.133 65.3547 28.6542 58.1045C28.0749 55.2682 27.6297 54.013 26.3796 50.3879C24.1234 43.9097 25.3848 37.6321 25.8117 37.5146C26.2386 37.3971 29.3459 52.0989 32.8675 59.6511C33.9194 61.9 36.3068 64.7723 36.9166 64.6045C37.5264 64.4367 33.7554 60.8262 33.6639 54.6333C33.6639 50.3666 32.2884 40.8693 32.4018 34.207C33.1182 30.6227 33.7752 30.7175 33.976 27.8708Z" fill="#F32A66"/>
          <path d="M32.3897 34.2678C33.1108 30.6215 33.7741 30.7335 33.976 27.8708C34.3221 23.2009 32.2599 21.4417 33.0242 16.9478C33.428 14.5649 32.433 11.7822 27.3425 13.0776C21.6752 14.5169 21.7329 15.3006 19.714 15.1886C15.7628 14.9807 14.5515 11.6543 12.1 12.7258C11.1072 13.2687 10.243 14.0623 9.57684 15.0427C8.91067 16.0231 8.46105 17.1631 8.26409 18.3712C7.61517 22.1294 9.93688 23.6487 12.7056 31.3892L15.6543 40.7841C15.5822 44.0727 15.9783 45.9919 16.3744 49.4689C16.6953 52.2852 16.8689 53.9559 18.6068 58.4705C20.1313 62.5655 22.9225 65.6045 23.6543 65.6045C24.386 65.6045 22.6542 62.6045 21.6101 60.7798C20.6542 57.6045 20.4058 57.0372 20.0856 53.3114C19.9484 51.6332 20.5979 49.0444 21.2229 48.8765C22.1224 48.6416 23.8908 53.475 24.2719 54.4317C27.8393 64.0818 30.0109 67.4031 31.1542 67.6045C32.2976 67.8059 30.133 65.3547 28.6542 58.1045C28.0749 55.2682 27.6297 54.013 26.3796 50.3879C24.1234 43.9097 25.3848 37.6321 25.8117 37.5146C26.2386 37.3971 29.3459 52.0989 32.8675 59.6511C33.9194 61.9 36.3068 64.7723 36.9166 64.6045C37.5264 64.4367 33.7554 60.8262 33.6639 54.6333C33.6639 50.2362 32.203 40.284 32.4165 33.6045" stroke="#F32A66" fill="#F32A66" stroke-miterlimit="10"/>
        </g>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M38.2777 8.04393C40.3337 10.6601 41.5667 13.8279 41.8203 17.1456C42.1004 21.3464 40.4201 22.0885 39.832 26.6253C39.762 27.1154 39.706 27.6475 39.6639 28.2496C39.3419 32.6884 40.4201 32.8145 40.126 37.6453C39.804 42.9383 38.4877 43.0223 38.1237 47.8252C37.8576 51.3398 38.4037 54.4344 39.5239 60.6235C39.8971 64.0534 39.8971 67.5136 39.5239 70.9434C39.5239 71.4335 39.3839 72.7358 38.5998 73.0998C37.8156 73.4639 36.9895 72.3857 35.6732 72.6377C34.609 72.8478 34.511 73.7019 33.3628 74.178C32.2877 74.5123 31.1275 74.4426 30.1002 73.982C27.7634 73.134 25.6915 71.6851 24.0931 69.7812C19.3462 64.1802 17.5539 65.7205 13.6052 60.8476C9.97853 56.3528 8.85832 51.7319 7.44406 45.8928C5.88977 39.4377 7.03798 39.5217 5.28767 32.3244C4.04144 27.1714 3.11727 25.7572 1.89905 19.8341C0.82085 14.6251 0.302757 11.9647 1.12891 9.5142C3.11727 3.56311 9.53045 1.46273 9.90851 1.33671C11.4488 0.860621 15.0755 -0.273587 17.7779 1.49074C19.7243 2.65295 19.7243 4.41727 21.3206 4.65531C23.1829 4.93537 23.813 2.62494 26.5576 2.03684C28.5879 1.60276 30.3242 2.42891 32.4246 3.43709C34.693 4.51993 36.6922 6.0935 38.2777 8.04393Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M38.2777 8.67577C40.3337 11.292 41.5667 14.4597 41.8203 17.7774C42.1004 21.9782 40.4201 22.7203 39.832 27.2572C39.762 27.7473 39.706 28.2794 39.6639 28.8815C39.3419 33.3203 40.4201 33.4463 40.126 38.2772C39.804 43.5701 38.4877 43.6542 38.1237 48.457C37.8576 51.9717 38.4037 55.0662 39.5239 61.2554C39.8971 64.6852 39.8971 68.1454 39.5239 71.5753C39.5239 72.0654 39.3839 73.3676 38.5998 73.7317C37.8156 74.0957 36.9895 73.0175 35.6732 73.2696C34.609 73.4796 34.511 74.3338 33.3628 74.8099C32.2877 75.1442 31.1275 75.0745 30.1002 74.6138C27.7634 73.7658 25.6915 72.3169 24.0931 70.4131C19.3462 64.812 17.5539 66.3523 13.6052 61.4794C9.97853 56.9846 8.85832 52.3638 7.44406 46.5247C5.88977 40.0695 7.03798 40.1535 5.28767 32.9562C4.04144 27.8033 3.11727 26.389 1.89905 20.4659C0.82085 15.257 0.302757 12.5965 1.12891 10.146C3.11727 4.19495 9.53045 2.09457 9.90851 1.96854C11.4488 1.49246 15.0755 0.358249 17.7779 2.12257C19.7243 3.28478 19.7243 5.04911 21.3206 5.28715C23.1829 5.5672 23.813 3.25678 26.5576 2.66867C28.5879 2.23459 30.3242 3.06074 32.4246 4.06893C34.693 5.15176 36.6922 6.72533 38.2777 8.67577Z" fill="#DEE1E9"/>
      <path d="M1.9332 20.3981C3.15142 26.3492 4.07559 27.7495 5.32182 32.8884L7.18416 33.7286C9.46416 32.9001 11.9527 32.8411 14.2695 33.5605C18.5822 34.6948 17.6581 29.4998 24.6033 32.2723C31.5486 35.0448 33.9991 32.2723 33.9991 32.2723L39.7121 28.8837C39.7121 28.2816 39.8101 27.7495 39.8801 27.2594C40.5242 22.7226 42.1485 21.9244 41.8685 17.7797C41.6612 14.8625 40.6801 12.0532 39.0261 9.64126C37.3722 7.22929 35.1053 5.30176 32.4588 4.05716C30.3724 3.06298 28.6361 2.23682 26.6057 2.6569C23.8052 3.24501 23.2171 5.55543 21.3547 5.27538C19.7725 5.03733 19.7164 3.27301 17.8121 2.04078C15.1096 0.276463 11.4969 1.41067 9.95666 1.88676C9.56459 2.01278 3.16542 4.11317 1.16306 10.0643C0.33691 12.5287 0.855003 15.1472 1.9332 20.3981Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth38",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
