import { warehouseAdapter } from "@/adapters/warehouse/warehouse"

export default {
    primaryKey: "id",
    model: 'warehouse',
    order: 'title',
    sort: 'asc',
    adapter: warehouseAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_warehouse',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    actionscomponent: 'WarehouseDatatableActionsWarehouse',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            tdcomponent: 'WarehouseDatatableTDWarehouseTitle',
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'address',
            title: 'address',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'branch_id',
            title: 'branch',
            table: true,
            table_show: 'branch.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title",
                },
                required: true
            }
        },
        {
            id: 'type',
            title: 'type',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                select_type: "list",
                options: {
                    "physical": { id: "physical", title: "physical" },
                    "virtual": { id: "virtual", title: "virtual" }
                },
                required: true
            }
        },
    ]

}
