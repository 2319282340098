<template>
  <table>
    <tbody>
    <template v-for="(d, dind) in displayData">
      <template v-if="d.deposits.length">
        <tr :key="`br-${dind}`"
            v-if="branches.length > 1">
          <td colspan="2" class="font-weight-bold">{{ d.title }}</td>
        </tr>
        <tr v-for="acc in d.deposits"
            :key="`br-${dind}-${acc.id}`">
          <td class="nowrap pl-3"
              :class="{ 'py-1': size === 'md' }">
            <span :style="`background-color: ${acc.deposit_account.color}`"
                  class="circle sm mr-1"></span> {{ acc.deposit_account.title }}
          </td>
          <td class="nowrap pl-3 pr-3"
              :class="{ 'py-1': size === 'md' }">
            {{ formatCurrency(acc.amount) }}
          </td>
        </tr>
      </template>
    </template>
    </tbody>
  </table>
</template>

<script setup>
import { formatCurrency } from "@/extensions/filters/filters"
import { useStore } from "vuex"
import { computed } from "vue"

const store = useStore()

const props = defineProps({
  patient: Object,
  size: String
})

const branches = computed(() => store.state.auth.branches)
const branchId = computed(() => Number(store.state.auth.branch))

const displayData = computed(() =>
    branches.value.map(obj => {
      obj.deposits = props.patient.deposit_by_accounts.filter(acc => Number(acc.branch_id) === Number(obj.id))
      return obj
    }).sort((a, b) => Number(a.id) === branchId.value ? -1 : (Number(b.id) === branchId.value ? 1 : 0))
)
</script>
