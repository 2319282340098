<template>
  <div>
    <span :class="{ 'text-muted': object.sent_notifications == 0 }">{{ object.sent_notifications }}</span> /
    <span :class="{'text-danger': object.error_notifications > 0, 'text-muted': object.error_notifications == 0 }">{{ object.error_notifications }}</span>
  </div>
</template>

<script>
export default {
  name: "MarketingDatatableTDSentError",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">

</style>
