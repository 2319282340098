<template>
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.84861 9.6519C2.06806 9.49093 2.11543 9.18256 1.95446 8.96311C1.32105 8.09967 0.986256 7.07529 0.986256 6.00072C0.986256 3.23613 3.23541 0.986967 6 0.986967C8.76459 0.986967 11.0137 3.23613 11.0137 6.00072C11.0137 7.07529 10.6789 8.09968 10.0455 8.96314C9.88454 9.18258 9.93194 9.49096 10.1514 9.65193C10.3713 9.81322 10.6795 9.76505 10.8402 9.54607C12.5529 7.21132 12.3812 3.89779 10.2421 1.75866C7.89756 -0.585886 4.10277 -0.586238 1.75792 1.75866C-0.381232 3.89779 -0.552878 7.21134 1.15985 9.54607C1.32079 9.76547 1.62917 9.81285 1.84861 9.6519Z"
              fill="#B5C6D8"/>
        <path d="M11.5064 11.0144H0.493526C0.22138 11.0144 0.000763331 11.235 0.000763331 11.5071C0.000763331 11.7793 0.22138 11.9999 0.493526 11.9999H11.5064C11.7785 11.9999 11.9991 11.7793 11.9991 11.5071C11.9991 11.235 11.7785 11.0144 11.5064 11.0144Z"
              fill="#B5C6D8"/>
        <path d="M5.46349 4.21276C5.9403 3.73598 6.75869 4.07786 6.75869 4.74924C6.75869 5.02138 6.9793 5.242 7.25145 5.242C7.5236 5.242 7.74422 5.02138 7.74422 4.74924C7.74422 3.95859 7.21524 3.28956 6.49276 3.07635V2.74691C6.49276 2.47476 6.27214 2.25414 5.99999 2.25414C5.72785 2.25414 5.50723 2.47476 5.50723 2.74691V3.07556C4.66798 3.32137 4.12643 4.16224 4.31784 5.04623C4.43565 5.59039 4.81245 6.04218 5.32575 6.2548L6.29711 6.65712C6.7463 6.84318 6.88094 7.41971 6.55564 7.7688C6.09078 8.26724 5.2413 7.94216 5.2413 7.25215C5.2413 6.98 5.02068 6.75939 4.74854 6.75939C4.47639 6.75939 4.25577 6.98 4.25577 7.25215C4.25577 8.02206 4.76461 8.70834 5.50723 8.92468V9.25451C5.50723 9.52665 5.72785 9.74727 5.99999 9.74727C6.27214 9.74727 6.49276 9.52665 6.49276 9.25451V8.924C6.76767 8.84387 7.01955 8.69717 7.22691 8.49192C8.08303 7.64505 7.7774 6.20355 6.67424 5.74662L5.70288 5.34429C5.24855 5.1561 5.1121 4.5648 5.46349 4.21276Z"
              fill="#B5C6D8"/>
    </svg>
</template>
