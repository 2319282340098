export default {
    after_scanning_qr: "QR kodu skan etdikdən sonra",
    application_reason: "Müraciət səbəbi",
    are_you_sure_you_want_to_delete_lead: "Siz həqiqətən müraciəti silmək istəyirsiniz?",
    check_status: 'Statusu yoxla',
    click_here: "buraya klikləyin",
    connect: "Qoşul",
    connection_is_right_can_be_used: "Qoşulma düzgündür - istifadə edilə bilər",
    'crm-form': "CRM-forma",
    forms: "Formalar",
    lead: "Müraciət",
    leads: "Müraciətlər",
    not_reached: "Zəng çatmadı",
    site_form: "Forma",
    stale: "Köhnəlmiş",
    telegram: "Telegram",
    thinking: "Düşünür",
    unpaid_message: 'Hesabınızın ödəniş müddəti keçib. Zəhmət olmasa balansınızı artırın və ya texniki dəstəyə müraciət edin.',
    urgent: "Təcili",
    whatsapp: "Whatsapp",
    whatsapp_qr_instructions: "Telefonunuzda whatsapp ayarlarında 'cihazı bağlayın' seçin və QR kodu oxudun",
}
