import { BinotelService, CRMService } from "@/services/api.service"
import { formatPhone } from "@/extensions/filters/filters"

export const makeCall = async (clinic, phone) => {
    phone = formatPhone(phone)
    let outgoingCallThrough = clinic?.clinic_additional?.outgoing_calls || 'standard'
    const ppoSettings = clinic.ppo_settings ? JSON.parse(clinic.ppo_settings) : null
    const binotelConnected = ppoSettings?.binotel_key && ppoSettings?.binotel_secret && ppoSettings?.binotel_internal
    const sipuniConnected = ppoSettings?.sipuni_key && ppoSettings?.sipuni_secret && ppoSettings?.sipuni_internal
    const uisConnected = !!ppoSettings?.uis_secret
    let res = null
    if(outgoingCallThrough === 'binotel') {
        if(binotelConnected) {
            res = await BinotelService.initiateCall(phone)
        } else {
            outgoingCallThrough = 'standard'
        }
    }
    if(!res && outgoingCallThrough === 'sipuni') {
        if(sipuniConnected) {
            res = await CRMService.initiateSipuniCall(phone)
        } else {
            outgoingCallThrough = 'standard'
        }
    }
    if(!res && outgoingCallThrough === 'uis') {
        if(uisConnected) {
            res = await CRMService.initiateUISCall(phone)
        } else {
            outgoingCallThrough = 'standard'
        }
    }
    if(!res && outgoingCallThrough === 'standard') {
        window.location = `tel:${phone}`
        res = true
    }
    return res
}
