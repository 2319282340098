import { serviceCategoryAdapter } from "@/adapters/services/serviceCategory"

export default {
    primaryKey: "id",
    model: 'service_category',
    order: 'level_sorting',
    sort: 'asc',
    adapter: serviceCategoryAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_category',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    rights: {
        edit: "clinic_services",
        delete: "clinic_services",
    },
    pagination: 999,
    hidesorting: true,
    customListSorting(list) {
        let children = {}
        let parents = []
        list.forEach(item => {
            if(!item.parent_id || item.parent_id == 0) {
                parents.push(item)
            } else {
                if(!children[item.parent_id]) children[item.parent_id] = []
                children[item.parent_id].push(item)
            }
        })
        parents.sort((a, b) => a.title.localeCompare(b.title))
        let arr = []
        parents.forEach(item => {
            arr.push(item)
            if(children[item.id] && children[item.id].length) arr.push(...children[item.id])
        })
        return arr
    },
    actionscomponent: 'ServicesDatatableActionsServiceCategory',
    trcomponent: "ServicesDatatableTRServiceCategory",
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: false
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: false,
            filter: true,
            filter_field_id: 'name_russian',
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'parent_id',
            title: 'parent_group',
            table: true,
            table_show: 'parent.title',
            sortable: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title",
                },
                component: "ServiceCategoryDatatableFormParentId"
            }
        },
        {
            id: 'color',
            title: 'color',
            form: {
                type: "color"
            }
        },
        {
            id: 'percents',
            form: {
                component: "ServiceCategoryDatatableFormCard"
            }
        }
    ]
}
