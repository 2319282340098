<template>
  <div class="form-group">
    <div class="row no-gutters" v-if="label">
      <div class="col">
        <label class="form-label" :data-content="label">
          <span>{{ label }}</span>
          <span v-if="required"> *</span>
        </label>
      </div>
      <div class="col-auto"><slot name="label-right"/></div>
    </div>
    <div class="position-relative">
      <input :type="type && !showPass ? type : 'text'"
             class="form-control"
             ref="input"
             :placeholder="placeholder ? placeholder : label"
             :autocomplete="autocomplete"
             @focus="$emit('focus')"
             @input="handleInput"
             @change="$emit('change', $event)"
             @keyup="$emit('keyup', $event)"
             @keyup.enter="$emit('enter')"
             :readonly="readonly ? readonly : false"
             :value="value"/>
      <slot/>
      <a class="show-password"
         v-if="type === 'password'"
         @click="showPass = !showPass">
        <EyeSvg v-if="showPass" class="svg-light"/>
        <NoEyeSvg v-if="!showPass" class="svg-light"/>
      </a>
    </div>
    <ValidationError :class="{ 'mt-3': validationMargin }"
                     v-if="showErrors"
                     :validationId="validationId"/>
  </div>
</template>

<script>

import ValidationError from "@/components/form/ValidationError"
import EyeSvg from '@/assets/svg-vue/form/eye.svg'
import NoEyeSvg from '@/assets/svg-vue/form/noeye.svg'

export default {
  name: "InputGroup",
  components: {
    ValidationError,
    EyeSvg,
    NoEyeSvg
  },
  data() {
    return {
      content: this.value,
      showPass: false
    }
  },
  props: {
    label: {},
    autocomplete: {
      type: String,
      default: 'on'
    },
    showErrors: {
      type: Boolean,
      default: false
    },
    validationId: {},
    value: {},
    type: {},
    readonly: {},
    placeholder: String,
    required: {
      type: Boolean,
      default: false
    },
    validationMargin: {
      type: Boolean,
      default: true
    },
    renderResult: {
      type: Function,
      required: false
    }
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      let res = e.target.value
      if(this.renderResult) {
        res = this.renderResult(res, this.validationId)
      }
      this.$emit('input', res)
    },
    focus() {
      this.$refs.input.focus()
    }
  },

}
</script>
