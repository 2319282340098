<template>
  <div style="max-width: 400px;">
    <div class="mb-3" v-if="ppoData.vendor === 'kkm'">
      <InputGroup type="number"
                  v-model="amount"
                  class="mb-3"
                  :label="$t('sum')"/>
      <div class="mt-4">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline w-100"
                :disabled="amount <= 0"
                @click="depositMoney">
          {{ $t('deposit_to_cashdesk') }}
        </button>
      </div>
      <div class="mt-3">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline w-100"
                :disabled="amount <= 0"
                @click="withdrawMoney">
          {{ $t('withdraw_from_cashdesk') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import { KKMService } from "@/services/kkm.service"
import InputGroup from "@/components/form/InputGroup.vue"

export default {
  name: "CashdeskKKMOperations",
  components: {
    InputGroup
  },
  head() {
    return {
      title: this.$t('warehouseoperation')
    }
  },
  computed: {
    ...mapState({
      ppoData: state => state.ppo.ppoData,
      counter: state => state.datatable.dataTableCounter,
    }),
  },
  data() {
    return {
      loaded: false,
      amount: 0
    }
  },
  methods: {
    async depositMoney() {
      this.loading = true
      const res = await KKMService.depositToCashdesk()
      this.loading = false
      if(res.error) {
        this.$noty.error(res.error)
      } else {
        this.amount = 0
        this.$noty.info(this.$t('success_message'))
      }
    },
    async withdrawMoney() {
      this.loading = true
      const res = await KKMService.withdrawFromCashdesk()
      this.loading = false
      if(res.error) {
        this.$noty.error(res.error)
      } else {
        this.amount = 0
        this.$noty.info(this.$t('success_message'))
      }
    }
  },
}
</script>
