<template>
  <span>
  <a class="data-table-action"
     :title="$t('instruction')"
     v-b-tooltip.hover
     @click="show">
    <EyeSvg class="svg-light"/>
    <span class="action-button-mob-title">{{ $t('instruction') }}</span>
  </a>
    </span>
</template>

<script>

import EyeSvg from '@/assets/svg-vue/form/eye.svg'
import {mapState} from "vuex"

export default {
  name: "CRMSiteFormDatatableActions",
  components: {
    EyeSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    show() {
      this.$store.commit('setSiteForm', this.copyObject(this.object))
      this.$nextTick(() => {
        this.$bvModal.show('site-form-show-modal')
      })
    },
  }
}
</script>

<style scoped>
.data-table-action svg {
  width: 16px;
  height: 16px;
}
</style>
