<template>
  <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.6123 3.39267C18.2985 3.28615 17.9516 3.39129 17.7496 3.65412C17.7209 3.69141 14.8248 7.3911 10.1106 7.3911H4.02283C1.80472 7.3911 0 9.19582 0 11.4139C0 13.6322 1.80472 15.437 4.02283 15.437H4.14998V20.1921C4.14998 21.5732 5.27351 22.6967 6.65453 22.6967C8.03534 22.6967 9.15887 21.5732 9.15887 20.1921V15.437H10.1106C12.0659 15.437 13.9391 16.0582 15.6781 17.2839C17.0004 18.2158 17.7433 19.1658 17.7496 19.1739C17.899 19.3683 18.1275 19.4764 18.3636 19.4764C18.4467 19.4764 18.5306 19.463 18.6123 19.4352C18.9261 19.3287 19.1372 19.0339 19.1372 18.7024V4.12563C19.1374 3.79416 18.9261 3.49939 18.6123 3.39267ZM1.54784 11.4139C1.54784 10.0492 2.65808 8.93894 4.02283 8.93894H9.33661V13.8891H4.02283C2.65808 13.8891 1.54784 12.7789 1.54784 11.4139ZM7.61104 20.1921C7.61104 20.7198 7.18198 21.1489 6.65433 21.1489C6.12688 21.1489 5.69781 20.7198 5.69781 20.1921V15.437H7.61104V20.1921ZM17.5896 16.8158C17.3061 16.5708 16.9814 16.3113 16.6168 16.0521C14.8545 14.7982 12.8873 14.068 10.8844 13.9181V8.90998C12.8873 8.75982 14.8545 8.02984 16.6168 6.77597C16.9812 6.51671 17.3061 6.25705 17.5896 6.01227V16.8158Z"/>
    <path
        d="M25.2262 10.6401H23.4453C23.0178 10.6401 22.6714 10.9867 22.6714 11.414C22.6714 11.8414 23.0178 12.188 23.4453 12.188H25.2262C25.6535 12.188 26 11.8414 26 11.414C26 10.9867 25.6535 10.6401 25.2262 10.6401Z"/>
    <path
        d="M21.7933 9.63628C21.9914 9.63628 22.1894 9.5607 22.3406 9.40955L23.5998 8.15033C23.9019 7.84802 23.9019 7.35806 23.5998 7.05576C23.2975 6.75345 22.8075 6.75365 22.5052 7.05576L21.246 8.31517C20.9439 8.61728 20.9439 9.10724 21.246 9.40955C21.3971 9.5607 21.5953 9.63628 21.7933 9.63628Z"/>
    <path
        d="M22.3406 13.4184C22.0385 13.1161 21.5483 13.1161 21.2462 13.4184C20.9439 13.7205 20.9439 14.2104 21.2462 14.5128L22.5054 15.772C22.6564 15.9233 22.8545 15.9987 23.0525 15.9987C23.2505 15.9987 23.4486 15.9231 23.5998 15.772C23.9019 15.4699 23.9019 14.9799 23.5998 14.6776L22.3406 13.4184Z"/>
    <path
        d="M4.17632 10.6401C3.77166 10.6401 3.38386 10.996 3.4025 11.414C3.42135 11.8333 3.7425 12.188 4.17632 12.188C4.58119 12.188 4.96899 11.8319 4.95034 11.414C4.9315 10.9948 4.61015 10.6401 4.17632 10.6401Z"/>
  </svg>
</template>
