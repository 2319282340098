<template>
  <div>
    <ServiceMultiSelect :label="$t('patient')"
                        :dblclick="false"
                        model="patient"
                        :multiple="false"
                        title="full_name"
                        :required="true"
                        :dbsearch="true"
                        v-model="patient"/>
    <div v-if="object.patient_id">
      <SelectGroup :options="planTreatments"
                   variant="white"
                   title="message"
                   valuekey="planning_id"
                   :label="$t('treatment_plan')"
                   :translate="false"
                   :showlabel="true"
                   v-model="object.treatment_plan_id"/>
    </div>
  </div>
</template>

<script setup>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { ref, watch } from "vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { CRMService } from "@/services/api.service"

const props = defineProps({
  object: {
    type: Object
  },
})

const patient = ref(null)
const planTreatments = ref([])

patient.value = props.object.patient
if(patient.value) {
  loadPlanTreatments(patient.value.id)
}

watch(() => props.object.patient_id, async (patientId) => {
  await loadPlanTreatments(patientId)
  props.object.treatment_plan_id = null
})

const loadPlanTreatments = async (patientId) => {
  const res = await CRMService.getPlanTreatments({
    client_id: {
      id: 'client_id',
      type: 'equals',
      condition: patientId
    }
  })
  planTreatments.value = res.data.data
}

watch(() => patient.value, (val) => {
  props.object.patient_id = val.id
})
</script>
