import { contractTemplateAdapter } from "@/adapters/templates/contract_template"

export default {
    primaryKey: "id",
    model: 'contract_template',
    order: 'id',
    modalsize: 'xl',
    sort: 'desc',
    adapter: contractTemplateAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_template',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'text26',
            title: 'text',
            table: false,
            form: {
                type: "textarea",
                component: "AgreementDatatableFormText",
                required: false
            }
        },
        {
            id: 'filename',
            title: 'file',
            table: false,
            sortable: true,
            form: {
                type: "file",
                filetype: "word",
                sizelimit: 10,
                deletefile: true,
                multiple: false,
                required: false,
                note: 'document_template_word_file_note'
            },
            validation_id: 'filename.0',
        },
    ]

}
