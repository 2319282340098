import { waitingListAdapter } from "@/adapters/patients/waitingList"

export default {
    primaryKey: "id",
    model: 'waiting_list',
    order: 'id',
    // modalsize: 'xl',
    sort: 'asc',
    actionscomponent: 'PatientWaitingListDatatableActions',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    showTotalRecords: true,
    adapter: waitingListAdapter,
    actions: [
        "add",
        "edit",
        "excel",
        "delete"
    ],
    fields: [
        // {
        //     id: 'id',
        //     title: 'id',
        //     table: true,
        //     sortable: true
        // },
        {
            id: 'client_id',
            title: 'patient_fio',
            table_show: 'patient.full_name',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                component: "PatientsDatatableFormWaitingClient"
            },
            tdcomponent: 'PatientsDatatableTDName',
        },
        {
            id: 'doctor_id',
            title: 'doctor',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select: true,
            tdcomponent: 'PatientsDatatableTDDoctorName',
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "full_name"
                },
            }
        },
        {
            id: 'client_phone',
            title: 'patient_phone',
            table_show: 'patient.phone',
            table: true,
            sortable: true,
            filter: true,
            right_excludes: 'hide_patient_phone',
        },
        {
            id: 'interval_from',
            title: 'desired_date_from',
            table: true,
            sortable: true,
            format_date: true,
            filter: true,
            filter_date: true,
            form: {
                classes: 'col-md-6',
                type: "date"
            }
        },
        {
            id: 'interval_to',
            title: 'desired_date_to',
            table: true,
            sortable: true,
            format_date: true,
            filter: true,
            filter_date: true,
            form: {
                classes: 'col-md-6',
                type: "date"
            }
        },
        {
            id: 'time_from',
            title: 'desired_time_from',
            table: true,
            sortable: true,
            filter: true,
            filter_time: true,
            form: {
                classes: 'col-md-auto',
                type: "time"
            }
        },
        {
            id: 'time_to',
            title: 'desired_time_to',
            table: true,
            sortable: true,
            filter: true,
            filter_time: true,
            form: {
                classes: 'col-md-auto',
                type: "time"
            }
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            sortable: false,
            filter: true,
            form: {
                type: "textarea"
            }
        },
        {
            id: 'recorded',
            title: 'recorded',
            table_show: 'status',
            format_translate: true,
            table: true,
            sortable: true,
            filter: true,
            filter_boolean: true,
            form: {
                type: "checkbox",
            },
        },

    ]
}
