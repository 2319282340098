<template>
  <div>
    <ServiceMultiSelect :label="$t('patient')"
                        :dblclick="false"
                        model="patient"
                        :multiple="false"
                        title="full_name"
                        :required="true"
                        :dbsearch="true"
                        v-model="patient"/>
    <div class="under-form-group text-right">
    <a @click="addPatient"
       tabindex="#"
       class="link pointer small text-underlined">
      {{ $t('create_patient') }}
    </a>
    </div>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import {mapState} from "vuex"

export default {
  name: "PatientsDatatableFormWaitingClient",
  components: {
    ServiceMultiSelect
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
    })
  },
  data() {
    return {
      patient: this.object.patient
    }
  },
  watch: {
    patient(val) {
      this.$set(this.object, this.field.id, val.id)
    }
  },
  mounted() {

  },
  methods: {
    addPatient() {
      this.$store.commit('setShowModal', false)
      this.$nextTick(() => {
        this.$store.commit('setViewModel', 'patient')
        this.$store.commit('setModule', 'patients')
        this.$store.commit('setSelectedModel', {
          branch_id: this.branch
        })
        this.$store.commit('setShowModal', true)
      })
    },
  }
}
</script>
