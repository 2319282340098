// import { patientAdapter } from "@/adapters/patients/patient"

export default {
    primaryKey: "id",
    model: 'staff',
    order: 'lname',
    modalsize: 'xl',
    sort: 'asc',
    showTotalRecords: true,
    deletemessage: 'are_you_sure_you_want_to_delete_user',
    actions: [
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'full_name',
            title: 'fio',
            table: true,
            sortable: 'lname',
            tabletdclass: "order-mob-0"
        },
        {
            id: 'lname',
            title: 'surname',
            table: false,
            filter: true,
        },
        {
            id: 'fname',
            title: 'name',
            table: false,
            filter: true,
        },
        {
            id: 'mname',
            title: 'middle_name',
            table: false,
            filter: true,
        },
        {
            id: 'clinic_id',
            title: 'clinic',
            table: true,
            sortable: true,
            table_show: 'clinic.name',
        },
        {
            id: 'gender',
            title: 'gender',
            table: false,
            filter: true,
            filter_list_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "list",
                options: {
                    "0": { id: "0", title: "male" },
                    "1": { id: "1", title: "female" },
                }
            }
        },
        {
            id: 'mphone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            format_phone: true,
        },
        {
            id: 'email',
            title: 'email',
            table: false,
            filter: true,
        },
        {
            id: 'role_id',
            title: 'role',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                select_type: "list",
                options: {
                    "2": { id: "2", title: "superadministrator" },
                    "3": { id: "3", title: "manager" },
                    "4": { id: "4", title: "doctor" },
                    "7": { id: "7", title: "assistant" },
                }
            }
        },
        {
            id: 'country_id',
            title: 'country',
            table: true,
            sortable: false,
            filter: true,
            filter_model_select: true,
            table_show: 'clinic.city.country.name',
            form: {
                type: "select",
                select_data: {
                    "title": "name",
                },
            }
        },
    ]

}
