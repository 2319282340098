<template>
  <div>
    <InputGroup :label="$t(field.title)"
                type="text"
                :required="true"
                v-model="object[field.id]">
      <a class="snils-a text-primary"
         v-if="showSnilsFetch"
         :class="{ disabled: !snilsCanBeRequested }"
         @click="getSnils">
        {{ $t('request_snils') }}
      </a>
      <sup v-if="showSnilsFetch">
        <a :title="$t('request_snils_note')"
           class="info ml-2"
           v-b-tooltip.hover>
          <InfoSvg class="info-svg"/>
        </a>
      </sup>
    </InputGroup>
  </div>
</template>

<script setup>
import InputGroup from "@/components/form/InputGroup"
import { PatientService } from "@/services/api.service"
import { computed, getCurrentInstance, onMounted } from "vue"
import InfoSvg from "@/assets/svg-vue/general/question.svg.vue"
import { useStore } from "vuex"

const props = defineProps({
  object: {
    type: Object,
  },
  field: {
    type: Object,
  }
})
const store = useStore()

let noty = null

onMounted(() => {
  noty = getCurrentInstance()?.appContext?.app?.noty
})

const getSnils = async () => {
  if(!snilsCanBeRequested.value) return
  try {
    const patient = props.object
    const res = await PatientService.guessSnils(props.object.id, {
      data: {
        patient: {
          familyName: patient.lname,
          firstName: patient.fname,
          patronymic: patient.mname,
          birthDate: patient.date_of_birth,
          gender: (patient.gender + 1)
        },
        patientPersDocs: {
          type: patient.document_type_id,
          series: patient.passport_series,
          number: patient.passport,
          issueDate: patient.passport_issue_date,
          issuer: patient.passport_given_by
        },
      }
    })
    const snils = res.data.snils
    const error = res.data.error
    if(snils) {
      props.object.snils = snils
      return
    }
    if(error) noty.error(error)
  } catch (e) {
    console.log(e)
  }
}

const clinic = computed(() => store.state.auth.clinic)
const clinicAdditional = computed(() => clinic.value.clinic_additional || {})
const showSnilsFetch = computed(() => !!clinicAdditional.value.n3_snils_guid)

const snilsCanBeRequested = computed(() => {
  const o = props.object
  return o.id && o.date_of_birth
      && o.lname
      && o.fname
      && o.gender != null
      && o.document_type_id
      && o.passport
      && o.passport_series
      && o.passport_issue_date
      && o.passport_given_by
})
</script>

<style scoped lang="scss">
.snils-a {
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>
