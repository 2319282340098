<template>
  <div class="form-group">
    <div class="select-wrapper">
      <label class="form-label" :data-content="label" v-if="showlabel">
        <span>{{ label }}</span>
        <span v-if="required"> *</span>
      </label>
      <select class="form-control"
              :disabled="readonly"
              :class="{ 'gray-input': variant === 'gray' }"
              @input="handleInput"
              :value="value">
        <option :value="null">-</option>
        <optgroup v-for="(t, tind) in teeth" :key="`optgroup-${tind}`" :label="$t(tind)">
          <option v-for="opt in t" :key="`option-${tind}-${opt.id}`" :value="opt.id" :disabled="opt.disabled">{{ opt.title }}</option>
        </optgroup>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "ToothSelectGroup",
  data() {
    return {
      content: this.value,
      teeth: {
        'jaws': [
          { id: "В", title: this.$t('top_jaw') },
          { id: "Н", title: this.$t('bottom_jaw') },
          { id: "Р", title: this.$t('oral_cavity') },
        ],
        'top_molars': [
          { id: 11, title: 11 },
          { id: 12, title: 12 },
          { id: 13, title: 13 },
          { id: 14, title: 14 },
          { id: 15, title: 15 },
          { id: 16, title: 16 },
          { id: 17, title: 17 },
          { id: 18, title: 18 },
          { id: '1x', title: '----', disabled: true },
          { id: 21, title: 21 },
          { id: 22, title: 22 },
          { id: 23, title: 23 },
          { id: 24, title: 24 },
          { id: 25, title: 25 },
          { id: 26, title: 26 },
          { id: 27, title: 27 },
          { id: 28, title: 28 },
        ],
        'bottom_molars': [
          { id: 31, title: 31 },
          { id: 32, title: 32 },
          { id: 33, title: 33 },
          { id: 34, title: 34 },
          { id: 35, title: 35 },
          { id: 36, title: 36 },
          { id: 37, title: 37 },
          { id: 38, title: 38 },
          { id: '3x', title: '----', disabled: true },
          { id: 41, title: 41 },
          { id: 42, title: 42 },
          { id: 43, title: 43 },
          { id: 44, title: 44 },
          { id: 45, title: 45 },
          { id: 46, title: 46 },
          { id: 47, title: 47 },
          { id: 48, title: 48 },
        ],
        'top_baby_teeth': [
          { id: 51, title: 51 },
          { id: 52, title: 52 },
          { id: 53, title: 53 },
          { id: 54, title: 54 },
          { id: 55, title: 55 },
          { id: '5x', title: '----', disabled: true },
          { id: 61, title: 61 },
          { id: 62, title: 62 },
          { id: 63, title: 63 },
          { id: 64, title: 64 },
          { id: 65, title: 65 },
        ],
        'bottom_baby_teeth': [
          { id: 71, title: 71 },
          { id: 72, title: 72 },
          { id: 73, title: 73 },
          { id: 74, title: 74 },
          { id: 75, title: 75 },
          { id: '7x', title: '----', disabled: true },
          { id: 81, title: 81 },
          { id: 82, title: 82 },
          { id: 83, title: 83 },
          { id: 84, title: 84 },
          { id: 85, title: 85 },
        ]
      },
    }
  },
  props: {
    label: {
      type: String
    },
    value: {},
    valuekey: {},
    options: {
      type: Array,
      required: true
    },
    showlabel: {
      type: Boolean,
      default: false
    },
    translate: {
      type: Boolean,
      default: true
    },
    title: {
      type: String
    },
    variant: {
      type: String,
      default: 'gray'
    },
    required: {
      type: Boolean,
      default: false
    },
    readonly: {},
  },
  mounted() {
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style scoped lang="scss">

</style>