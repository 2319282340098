export default {
    agreement_print: "Razılığı çap et",
    agreement_template: "Razılaşma şablonu",
    agreements: "Razılaşmalar",
    are_you_sure_you_want_to_delete_template: "Şablonu silmək istədiyinizə əminsiniz?",
    bite_templates: "Dişlərin düzülüşü",
    choose_fields_for_autocomplete: "Avto-doldurma üçün lazım olan dəyərləri seçin:",
    complaints_templates: "Şikayətlər",
    consents: "Razılıqlar",
    contracts: "Müqavilələr",
    contract_template: "Müqavilə şablonu",
    development: "İnkişaf",
    development_templates: "İnkişaf",
    diseases: "Xəstəliklər",
    diseases_templates: "Xəstəliklər",
    document_template_word_file_note: "Word faylını şablon kimi əlavə edə bilərsiniz. Word faylında təqləri dollar işarəsi və mötərizə ilə qeyd etmək lazımdır və yalnız böyük hərflərdən istifadə olunmalıdır. Məsələn: ${FIO_CLIENT}.",
    full_treatment_template: "Müalicə şablonu",
    general_template: "Şablon",
    generate: "Formalaşdır",
    generated: "Formalaşdırıldı",
    inspection_templates: "Müayinə",
    medication_templates: "Müalicə",
    not_generated: "Formalaşdırılmadı",
    objectively_templates: "Obyektiv",
    plansurvey_templates: "Müayinə planı",
    plansurvey: "Müayinə planı",
    plan_treatment_template: "Müalicə planı şablonu",
    recommendations_templates: "Tövsiyələr",
    research: "Tədqiqat məlumatları",
    researches: "Tədqiqatlar",
    research_templates: "Tədqiqat məlumatları",
    see_in_trustme: "TrustMe-də bax",
    sign_via_trustme: "TrustMe vasitəsilə imzala",
    signed: "İmzalandı",
    signed_via_trustme: "TrustMe vasitəsilə imzalandı",
    subjectively_templates: "Subyektiv",
    teeth_medication: "Müalicə",
    teeth_plansurvey: "Müayinə planı",
    teeth_recommendations: "Tövsiyələr",
    templates_treatments_categories: "Müalicə şablonlarının kateqoriyaları",
    upload_signed: "İmzalanmış faylı yüklə",
    uploaded: "Yükləndi"
}