import  { CashdeskService } from "@/services/api.service"

const state = {
    ppoData: {},
}

const getters = {

}

const mutations = {
    setPPOData(state, data) {
        state.ppoData = data
    },
    setShifts(state, shifts) {
        state.ppoData.shifts = shifts
    },
    setCheques(state, cheques) {
        state.ppoData.cheques = cheques
    },
    setXReports(state, reports) {
        state.ppoData.reports = reports
    }
}

const actions = {
    getCashdeskData(context) {
        return new Promise((resolve, reject) => {
            CashdeskService.getData().then(data => {
                let d = data.data
                if(d.cheques) {
                    let cheques = d.cheques
                    let new_ch = []
                    if(cheques.results) {
                        cheques.results.forEach(item => {
                            item.total_sum = item.total_sum / 100
                            new_ch.push(item)
                        })
                    }
                    d.cheques.results = new_ch
                }
                context.commit('setPPOData', d)
                resolve(d)
            }).catch(err => {
                reject(err.response.data.errors)
            })
        })
    },
};

export default {
    state,
    actions,
    mutations,
    getters
}
