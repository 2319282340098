import Vue from "vue"

import options from "@/data/patients/patient"
import { patientAdapter } from "@/adapters/patients/patient"

let new_options = Vue.prototype.copyObject(options)
new_options.prepareParams = ['id', 'clinic_id']
new_options.adapter = patientAdapter
new_options.fields.forEach(item => {
    if(['full_name'].includes(item.id)) { delete item.tdcomponent }
})
new_options.fields.splice((new_options.fields.length - 3), 0 , {
    id: 'branch_id',
    title: 'branch',
    table: false,
    form: {
        type: "select",
        select_type: "model",
        select_data: {
            "title": "title"
        },
        required: true,
        classes: 'col-md-4',
    },
})
new_options.fields.splice((new_options.fields.length - 1), 0 , {
    id: 'blocked',
    title: 'status',
    table: true,
    sortable: true,
    tdcomponent: "SuperAdminDatatableTDUserBlocked"
})
delete new_options.actionscomponent
// new_options.actionscomponent = 'SuperAdminPatientsActions'

export default new_options
