export default {
    primaryKey: "id",
    model: 'marketing_group',
    modaltitle: 'group',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    actionscomponent: 'MarketingGroupDatatableActions',
    deletemessage: 'are_you_sure_you_want_to_delete_tag',
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
            tdcomponent: 'MarketingDatatableTDGroupTitle',
        },
        {
            id: 'patientsCount',
            title: 'amount_of_patients',
            table: true,
        },

    ]
}
