<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path d="M11.0489 5.51652L9.79643 5.47058L9.5249 12.8857L10.7773 12.9315L11.0489 5.51652Z" fill="#DD1515"/>
    <path d="M8.6263 5.49353H7.37305V12.9087H8.6263V5.49353Z" fill="#DD1515"/>
    <path d="M6.47416 12.8855L6.20263 5.47034L4.9502 5.51631L5.22176 12.9314L6.47416 12.8855Z" fill="#DD1515"/>
    <path
        d="M0.542969 2.4021V3.65535H1.84888L2.88488 15.4283C2.91328 15.7518 3.18422 16 3.509 16H12.4696C12.7944 16 13.0656 15.7517 13.0938 15.4281L14.1298 3.65535H15.4568V2.4021H0.542969ZM11.8957 14.7468H4.08281L3.10694 3.65535H12.8717L11.8957 14.7468Z"
        fill="#DD1515"/>
    <path
        d="M10.0471 0H5.95306C5.37719 0 4.90869 0.4685 4.90869 1.04437V3.02872H6.16194V1.25325H9.83818V3.02872H11.0914V1.04437C11.0914 0.4685 10.6229 0 10.0471 0Z"
        fill="#DD1515"/>
  </g>
</svg>
</template>
