<template>
  <a @click="click" class="d-inline-block align-middle pointer">
    <div class="form-control gray-input d-flex align-items-center settings-btn">
      <CalendarSetSvg/>
    </div>
  </a>
</template>

<script>
import CalendarSetSvg from '@/assets/svg-vue/calendar/calendar_settings.svg'

export default {
  name: "DCalendarSettings",
  components: {
    CalendarSetSvg,
  },
  methods: {
    click() {
      this.$bvModal.show('calendar-settings-modal')
    }
  }
}
</script>

<style scoped>

</style>
