<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M5.0625 7.40625H3.15625C2.81106 7.40625 2.53125 7.68606 2.53125 8.03125C2.53125 8.37644 2.81106 8.65625 3.15625 8.65625H5.0625C5.40769 8.65625 5.6875 8.37644 5.6875 8.03125C5.6875 7.68606 5.40769 7.40625 5.0625 7.40625Z"/>
    <path
        d="M5.0625 9.84375H3.15625C2.81106 9.84375 2.53125 10.1236 2.53125 10.4688C2.53125 10.8139 2.81106 11.0938 3.15625 11.0938H5.0625C5.40769 11.0938 5.6875 10.8139 5.6875 10.4688C5.6875 10.1236 5.40769 9.84375 5.0625 9.84375Z"/>
    <path
        d="M5.0625 12.2499H3.15625C2.81106 12.2499 2.53125 12.5298 2.53125 12.8749C2.53125 13.2201 2.81106 13.4999 3.15625 13.4999H5.0625C5.40769 13.4999 5.6875 13.2201 5.6875 12.8749C5.6875 12.5298 5.40769 12.2499 5.0625 12.2499Z"/>
    <path
        d="M9.125 12.2499H7.21875C6.87356 12.2499 6.59375 12.5298 6.59375 12.8749C6.59375 13.2201 6.87356 13.4999 7.21875 13.4999H9.125C9.47019 13.4999 9.75 13.2201 9.75 12.8749C9.75 12.5298 9.47019 12.2499 9.125 12.2499Z"/>
    <path
        d="M9.12497 2.50003H3.15625C2.81106 2.50003 2.53125 2.77984 2.53125 3.12503V5.59379C2.53125 5.93898 2.81106 6.21879 3.15625 6.21879H9.12497C9.47016 6.21879 9.74997 5.93898 9.74997 5.59379V3.12503C9.74997 2.77984 9.47016 2.50003 9.12497 2.50003ZM8.49997 4.96879H3.78125V3.75003H8.49997V4.96879Z"/>
    <path
        d="M15.375 11.0009C15.7202 11.0009 16 10.7211 16 10.3759V8.81344C16 8.46826 15.7202 8.18844 15.375 8.18844H12.25V0.626007C12.25 0.28082 11.9702 0.00100708 11.625 0.00100708H0.625094C0.279938 0.00100708 9.375e-05 0.28082 9.375e-05 0.626007L0 13.5008C0 14.8793 1.12147 16.0008 2.5 16.0009L13.4999 16.0009C13.5047 16.0009 13.5095 16.0009 13.5142 16.0008C14.1767 15.9971 14.7989 15.7374 15.2677 15.2687C15.7399 14.7965 16 14.1686 16 13.5008C16 13.1556 15.7202 12.8758 15.375 12.8758C15.0298 12.8758 14.75 13.1556 14.75 13.5008C14.75 13.8348 14.62 14.1486 14.3838 14.3848C14.1477 14.6208 13.8339 14.7508 13.5 14.7508C13.5 14.7508 13.4999 14.7508 13.4999 14.7508C12.8107 14.7508 12.25 14.19 12.25 13.5008V9.43848H14.75V10.376C14.75 10.7212 15.0298 11.0009 15.375 11.0009ZM2.5 14.7509C1.81075 14.7508 1.25 14.1901 1.25 13.5008L1.25009 1.25101H11V13.5008C11 13.9559 11.1223 14.383 11.3356 14.7509L2.5 14.7509Z"/>
    <path
        d="M9.125 7.40625H7.21875C6.87356 7.40625 6.59375 7.68606 6.59375 8.03125C6.59375 8.37644 6.87356 8.65625 7.21875 8.65625H9.125C9.47019 8.65625 9.75 8.37644 9.75 8.03125C9.75 7.68606 9.47019 7.40625 9.125 7.40625Z"/>
    <path
        d="M9.125 9.84375H7.21875C6.87356 9.84375 6.59375 10.1236 6.59375 10.4688C6.59375 10.8139 6.87356 11.0938 7.21875 11.0938H9.125C9.47019 11.0938 9.75 10.8139 9.75 10.4688C9.75 10.1236 9.47019 9.84375 9.125 9.84375Z"/>
  </svg>
</template>
