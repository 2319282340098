<template>
  <section id="doctor" class="doctor">
    <div class="section-top-row">
      <div class="action-buttons-td position-relative">
        <div class="d-md-none d-inline-block">
          <h1>{{ doctor ? doctor.full_name : '' }}</h1>
        </div>
        <a class="d-md-none d-inline-block action-dots" @click="dotsPressed"><DotsSvg/></a>
        <div class="action-buttons-div row row-narrow align-items-center no-print d-md-flex"
             :class="{ expanded: mob_expanded }"
             v-click-outside="clickedOutside">
          <div class="col d-md-block d-none">
            <h1>
            <span class="d-md-inline d-none">
            <router-link :to="{name: 'StaffDoctors' }">{{ $t('doctors') }}</router-link>
              </span>
              <span v-if="doctor"><span class="d-md-inline d-none"> / </span>{{ doctor.full_name }}</span>
            </h1>
          </div>
          <div class="col-auto table-view-navigator" v-if="rights.includes('work_time_edit')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="schedulePressed">
                <ClockSvg class="mr-2 file-svg"/>{{ $t('work_schedule') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator" v-if="rights.includes('create_docs')">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed gray" @click="editDoctor">
                <EditSvg class="mr-2"/>{{ $t('edit') }}
              </a>
            </div>
          </div>
          <div class="col-auto table-view-navigator">
            <div class="d-inline-flex align-middle">
              <a class="btn btn-themed d-inline-flex align-items-center" @click="addVisit">
                <CalendarSvg class="svg-white mr-2"/>
                {{ $t('add') }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="patient-card no-print">
      <b-row v-if="doctor">
        <b-col md="4" cols="12">
          <b-row>
            <b-col cols="auto">
              <PatientImg view="doctor" :bordercolor="doctor.color"/>
            </b-col>
            <b-col>
              <h2 class="mb-1">{{ doctor.full_name }}</h2>
              <div>
                <div class="text-muted">{{ oneLinerFromProperty(doctor.professions, 'title') }}</div>
                <StarRating :rating="doctor.rating"
                            class="mt-1"
                            v-if="rights.includes('view_feedbacks') && doctor.rating"/>
                <div class="mt-3" v-if="doctor.mphone">
                  <a :href="`tel:${doctor.mphone}`">{{ doctor.mphone }}</a>
                  <a class="ml-3" :href="`tel:${doctor.mphone}`"><PhoneSvg/></a>
                  <a v-if="clinic && clinic.sms_info && rights.includes('sms_send')" class="ml-3 pointer" @click="smsModal"><SmsSvg/></a>
                </div>
                <div><a :href="`mailto:${doctor.email}`">{{ doctor.email }}</a></div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="8" cols="12">
          <a class="d-block d-md-none more-info-link collapse-btn-arrow" v-spaceduck-collapse="`collapse-patient-info`">{{ $t('detailed_information') }}</a>
          <spaceduck-collapse id="collapse-patient-info" :visible="!isMobile">
            <b-row>
              <b-col md cols="12">
                <PatientCol label="date_of_birth" :title="formatDate(doctor.date_of_birth)"/>
                <PatientCol label="gender" :title="$t(doctor.genderString)"/>
              </b-col>
              <b-col md cols="12">
                <PatientCol label="warehouse" :title="doctor.warehouse ? doctor.warehouse.title : ''"/>
              </b-col>
              <b-col md cols="12">
                <!--                <PatientCol label="percent" :title="doctor.percent+'%'"/>-->
                <!--                <PatientCol label="rate" :title="$options.filters.formatCurrency(doctor.rate)"/>-->
              </b-col>
            </b-row>
          </spaceduck-collapse>
        </b-col>
      </b-row>
    </div>
    <div class="d-md-none d-block mobile-big-separator"></div>
    <div class="sub-menu no-print">
      <template v-for="(m, mind) in menu">
        <router-link :to="{ name: m.to }"
                     v-if="!m.right || rights.includes(m.right)"
                     :key="`patient-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <!--    <div class="patient-add-wrap d-md-block d-none no-print" v-if="!['PatientPersonal','PatientTreatmentPlan','PatientVisits','StaffDoctorSchedule'].includes($route.name)">-->
    <!--      <AddObject module="patients" btnclass="btn-themed-outline"/>-->
    <!--    </div>-->
    <router-view class="no-print" v-if="doctor"/>
    <PatientSMSModal/>
    <DoctorScheduleModal/>
    <AddNewVisitModal/>
  </section>
</template>

<script>

import EditSvg from '@/assets/svg-vue/general/edit.svg'
import SmsSvg from '@/assets/svg-vue/general/sms.svg'
import ClockSvg from '@/assets/svg-vue/calendar/clock2.svg'
import PhoneSvg from '@/assets/svg-vue/header/phone.svg'
import CalendarSvg from '@/assets/svg-vue/calendar/calendar_plus.svg'
import DotsSvg from '@/assets/svg-vue/header/vertical_dots.svg'
import { mapState, mapActions } from "vuex"
import PatientCol from "@/components/parts/patients/PatientCol"
import PatientImg from "@/components/parts/patients/PatientImg"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import DoctorScheduleModal from "@/components/parts/staff/DoctorScheduleModal"
import AddNewVisitModal from "@/components/parts/calendar/AddNewVisitModal"
import moment from "moment/moment"
import StarRating from "@/components/parts/staff/StarRating.vue"
import { formatDate, oneLinerFromProperty } from "@/extensions/filters/filters"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "DoctorGeneral",
  components: {
    StarRating,
    AddNewVisitModal,
    DoctorScheduleModal,
    PatientSMSModal,
    PatientImg,
    PatientCol,
    CalendarSvg,
    EditSvg,
    ClockSvg,
    PhoneSvg,
    SmsSvg,
    DotsSvg,
    SpaceduckCollapse
  },
  data() {
    return {
      menu: [
        { to: 'StaffDoctorVisits', title: 'visits' },
        { to: 'StaffDoctorSchedule', title: 'schedule', right: 'work_time_edit' },
        { to: 'StaffDoctorFeedbacks', title: 'feedbacks', right: 'view_feedbacks' },
      ],
      mob_expanded: false,
      justOpened: false
    }
  },
  computed: {
    ...mapState({
      clinic:  state => state.auth.clinic,
      doctor:  state => state.doctor.doctor,
      counter: state => state.datatable.dataTableCounter,
      isMobile: state => state.dom.isMobile,
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    oneLinerFromProperty,
    formatDate,
    ...mapActions(['getDoctor']),
    addVisit() {
      let now = moment().format('YYYY-MM-DD HH')+":30:00"
      if(moment().format('mm') > 30) {
        now = moment().add(1, 'hours').format('YYYY-MM-DD HH')+":00:00"
      }
      this.$store.commit('setNewVisit', {
        time: now,
        doctor: this.doctor
      })
      this.$bvModal.show('add-new-visit-modal')
    },
    schedulePressed() {
      this.$bvModal.show('doctor-schedule-modal')
    },
    editDoctor() {
      this.$store.commit("setViewModel", 'doctor')
      this.$store.commit('setSelectedModel', this.copyObject(this.doctor))
      this.$store.commit('setShowModal', true)
    },
    smsModal() {
      this.$bvModal.show('patient-sms-modal')
    },
    dotsPressed() {
      this.justOpened = true
      this.mob_expanded = !this.mob_expanded
      setTimeout(() => {
        this.justOpened = false
      },20)
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.mob_expanded = false
      }
    },
  },
  mounted() {
    this.$store.commit('setModule', 'staff')
    this.getDoctor(this.$route.params.id).then(() => {
      if(this.$route.query.modal && this.$route.query.modal === 'schedule') {
        this.schedulePressed()
      }
    })
  },
  unmounted() {
    this.$store.commit('setDoctor', null)
  },
  watch: {
    counter() {
      this.getDoctor(this.$route.params.id)
    },
    $route() {
      if(this.doctor && this.$route.params.id != this.doctor.id) {
        this.getDoctor(this.$route.params.id)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#doctor {
  h2 {
    font-size: 22px;
    margin-bottom: 16px;
    font-weight: bold;
  }
  .section-top-row {
    border-bottom: 1px solid #e5e5e5;
  }
  .patient-card {
    padding-top: 20px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .file-svg {
    fill: $label-color;
  }
}


@media screen and (max-width: 768px) {
  .more-info-link {
    color: $gray-text;
    font-weight: bold;
    margin-top: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid $calendar-border-color;
  }
  #doctor {
    .sub-menu {
      padding-top: 4px;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 8px;
    }
    .section-top-row {
      border-bottom: none;
    }
    .patient-card {
      font-size: 12px;
      margin-bottom: 0;
    }
  }
}
</style>
