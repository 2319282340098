export const openWindowWithPost = (url, data) => {
    const form = document.createElement("form")
    form.target = "_blank"
    form.method = "POST"
    form.action = url
    form.style.display = "none"

    for (let key in data) {
        const input = document.createElement("input")
        input.type = "hidden"
        input.name = key
        input.value = data[key]
        form.appendChild(input)
    }
    document.body.appendChild(form)

    setTimeout(() => {
        form.submit()
        document.body.removeChild(form)
    }, 100)

    // form.submit()
    // document.body.removeChild(form)


}
