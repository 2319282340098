<template>
    <div>
        <slot/>
    </div>
</template>

<script>
    export default {
        components: {
        },
        beforeCreate() {
        }
    }
</script>