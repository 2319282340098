<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
        <path d="M17.0449 2.26976L14.9612 1H11.4775L9.3775 2.26976L10.9891 3.81627L8.9054 5.36278L10.7938 6.90929L8.49843 8.60231L10.3217 10.1488L8.02634 11.5488L10.0449 12.9651L8.02634 14.5767L9.71936 16.1232L7.48913 17.5395L9.24726 19.0046L6.95192 20.5511L9.10075 22.0976L6.95192 23.7093L8.49843 25.0604L6.41471 26.6069L8.23796 28.0883L6.15425 29.6348L7.96122 31.3767L6.95192 33.0534V47.3139L8.84029 54.6394H14.9612" fill="#8889BE"/>
        <path d="M9.63765 2.26976L11.7214 1H15.1888L17.3051 2.26976L15.6772 3.81627L17.7772 5.36278L15.8888 6.90929L18.1679 8.60231L16.3609 10.1488L18.64 11.5488L16.6214 12.9651L18.64 14.5767L16.9632 16.1232L19.1772 17.5395L17.4353 19.0046L19.7144 20.5511L17.5655 22.0976L19.7144 23.7093L18.1679 25.0604L20.2516 26.6069L18.4446 28.0883L20.5283 29.6348L18.7051 31.3767L19.7144 33.0534V47.3139L17.8423 54.6394H13.9353" fill="#8889BE"/>
        <path d="M1.54601 64.4439C2.06834 66.8618 3.07471 69.149 4.50449 71.1677C5.65164 73.0109 7.29118 74.4967 9.23804 75.4575C10.8974 76.3003 12.7919 76.5608 14.6171 76.1971C15.7467 75.9416 15.8139 75.6054 18.9203 73.6824C22.0267 71.7594 22.4167 71.8266 23.2101 70.9929C24.0035 70.1591 24.9583 68.895 25.429 64.928C25.7488 61.2018 25.4489 57.4484 24.5414 53.8203C24.3442 52.7265 24.1559 51.6373 23.9766 50.5525L19.512 46.8678C18.018 45.3235 16.0178 44.3688 13.8775 44.1783C12.7812 44.101 11.6804 44.238 10.6366 44.5817L4.55828 48.0378C3.21352 51.3997 2.40666 52.3679 1.74773 55.434C0.998598 58.3832 0.929623 61.4641 1.54601 64.4439Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--        <path d="M21.1714 2.01147C23.7533 8.14359 22.3548 19.9237 22.3548 19.9237C21.3065 28.1859 21.406 36.5534 22.6506 44.7883C23.2558 48.4461 23.7533 52.1173 24.4257 55.7481C25.3399 59.3752 25.6399 63.1297 25.3132 66.8559C24.856 70.8229 23.7668 72.2349 23.0944 72.9207C22.422 73.6066 22.032 73.5931 18.8046 75.6102C15.5772 77.6274 15.631 77.8695 14.5148 78.125C12.6897 78.4919 10.7941 78.2312 9.13577 77.3853C7.18604 76.4288 5.54544 74.942 4.40221 73.0956C2.97243 71.0769 1.96607 68.7897 1.44374 66.3717C0.805593 63.3874 0.85618 60.2968 1.59166 57.3349C2.25059 54.2689 3.0709 53.3007 4.40221 49.9388C5.07459 48.2309 5.54526 46.7382 7.36069 38.831C8.70545 32.672 9.71402 28.584 11.0588 21.9543C11.9329 17.7317 13.5062 13.6437 14.0307 9.36732C14.1921 8.02256 14.6224 3.98827 17.1371 1.52736C17.5674 1.09704 18.6298 0.0884792 19.6518 0.343984C20.6738 0.599488 21.0235 1.64839 21.1714 2.01147Z" fill="#DEE1E9"/>-->
<!--        <path d="M1.42517 66.3737C1.94749 68.7917 2.95386 71.0789 4.38364 73.0975C5.53079 74.9407 7.17033 76.4266 9.1172 77.3873C10.7765 78.2301 12.671 78.4906 14.4962 78.1269C15.6258 77.8714 15.6931 77.5352 18.7995 75.6122C21.9059 73.6892 22.2959 73.7565 23.0893 72.9227C23.8827 72.089 24.8375 70.8249 25.3081 66.8578C25.628 63.1316 25.3281 59.3782 24.4206 55.7501C24.2233 54.6564 24.0351 53.5671 23.8558 52.4823L19.3912 48.7977C17.8971 47.2533 15.8969 46.2986 13.7566 46.1082C12.6604 46.0308 11.5596 46.1678 10.5157 46.5116L4.43743 49.9676C3.09267 53.3295 2.28581 54.2978 1.62688 57.3638C0.877748 60.313 0.808773 63.3939 1.42517 66.3737Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--        <path opacity="0.2" d="M13.2481 55.2398C14.3239 55.428 16.3007 50.9634 17.2824 46.6736L18.2238 34.3287C18.3986 30.402 18.5062 27.2015 18.5734 24.9961C18.7213 19.9935 18.6675 19.375 18.8558 17.2503C19.3265 11.7905 19.1384 1.84137 18.856 1.72034C18.5736 1.59931 18.5734 10.8895 17.7262 13.3773C16.8818 16.5136 16.3458 19.7249 16.1259 22.9655C15.7933 26.5381 15.2364 30.0863 14.4584 33.5891C13.7322 36.4131 13.1137 37.7579 12.253 41.8056C11.8008 43.6777 11.5617 45.595 11.5403 47.5208C11.5503 49.0549 11.7262 50.5833 12.0647 52.0796C12.522 54.1505 12.764 55.2398 13.2481 55.2398Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M20.8511 2.06653C23.433 8.19864 22.0345 19.9788 22.0345 19.9788C20.9861 28.241 21.0857 36.6085 22.3303 44.8434C22.9355 48.5011 23.433 52.1723 24.1054 55.8032C25.0196 59.4302 25.3196 63.1848 24.9929 66.9109C24.5357 70.878 23.4465 72.2899 22.7741 72.9758C22.1017 73.6616 21.7117 73.6482 18.4843 75.6653C15.2569 77.6824 15.3107 77.9245 14.1945 78.18C12.3694 78.5469 10.4738 78.2863 8.81546 77.4404C6.86573 76.4839 5.22513 74.9971 4.0819 73.1506C2.65212 71.1319 1.64575 68.8447 1.12343 66.4268C0.485281 63.4424 0.535868 60.3519 1.27135 57.39C1.93028 54.3239 2.75059 53.3557 4.0819 49.9938C4.75428 48.286 5.22495 46.7933 7.04037 38.8861C8.38514 32.7271 9.39371 28.639 10.7385 22.0093C11.6126 17.7868 13.1859 13.6987 13.7104 9.42237C13.8718 8.07761 14.3021 4.04333 16.8168 1.58241C17.2471 1.15209 18.3095 0.143533 19.3315 0.399038C20.3535 0.654542 20.7031 1.70344 20.8511 2.06653Z" fill="#DEE1E9"/>
        <mask id="mask425" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="26" height="34">
          <path d="M1.10485 66.4289C1.62718 68.8468 2.63354 71.134 4.06333 73.1527C5.21047 74.9959 6.85002 76.4817 8.79688 77.4425C10.4562 78.2853 12.3507 78.5458 14.1759 78.1821C15.3055 77.9266 15.3728 77.5904 18.4792 75.6674C21.5856 73.7444 21.9755 73.8116 22.769 72.9778C23.5624 72.1441 24.5171 70.88 24.9878 66.913C25.3076 63.1868 25.0077 59.4334 24.1003 55.8052C23.903 54.7115 23.7148 53.6222 23.5355 52.5375L19.0709 48.8528C17.5768 47.3085 15.5766 46.3538 13.4363 46.1633C12.3401 46.086 11.2393 46.223 10.1954 46.5667L4.11712 50.0228C2.77235 53.3847 1.9655 54.3529 1.30657 57.419C0.557436 60.3682 0.488461 63.4491 1.10485 66.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask425)">
          <path d="M1.10485 66.4877C1.62718 68.9056 2.63354 71.1928 4.06333 73.2115C5.21047 75.0547 6.85002 76.5405 8.79688 77.5013C10.4562 78.3441 12.3507 78.6046 14.1759 78.2409C15.3055 77.9854 15.3728 77.6492 18.4792 75.7262C21.5856 73.8032 21.9755 73.8704 22.769 73.0367C23.5624 72.2029 24.5171 70.9389 24.9878 66.9718C25.3076 63.2456 25.0077 59.4922 24.1003 55.8641C23.903 54.7703 23.7148 53.6811 23.5355 52.5963L19.0709 48.9117C17.5768 47.3673 15.5766 46.4126 13.4363 46.2221C12.3401 46.1448 11.2393 46.2818 10.1954 46.6256L4.11712 50.0816C2.77235 53.4435 1.9655 54.4117 1.30657 57.4778C0.557436 60.427 0.488461 63.5079 1.10485 66.4877Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M12.6061 63.2012C13.5864 63.2012 15.1208 70.6693 15.1208 79.2012H13.1978H10.1208C10.1208 70.6693 11.6257 63.2012 12.6061 63.2012Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M12.1427 65.1942C13.4163 65.4157 15.7565 60.1609 16.9187 55.1119L18.0331 40.5821C18.2401 35.9604 18.3674 32.1934 18.447 29.5976C18.6221 23.7097 18.5585 22.9816 18.7813 20.4809C19.3385 14.0548 19.116 2.34476 18.7816 2.20231C18.4473 2.05986 18.447 12.9944 17.4441 15.9225C16.4444 19.6139 15.8099 23.3936 15.5496 27.2076C15.1558 31.4126 14.4965 35.5888 13.5755 39.7115C12.7158 43.0353 11.9835 44.6181 10.9646 49.3823C10.4292 51.5857 10.1462 53.8423 10.1208 56.109C10.1327 57.9146 10.3409 59.7136 10.7417 61.4746C11.283 63.9121 11.5696 65.1942 12.1427 65.1942Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
        <path d="M21.1714 5.16864C23.7533 11.3008 22.3548 23.0809 22.3548 23.0809C21.3065 31.3431 21.406 39.7106 22.6506 47.9455C23.2558 51.6032 23.7533 55.2745 24.4257 58.9053C25.3399 62.5323 25.6399 66.2869 25.3132 70.013C24.856 73.9801 23.7668 75.3921 23.0944 76.0779C22.422 76.7637 22.032 76.7503 18.8046 78.7674C15.5772 80.7846 15.631 81.0266 14.5148 81.2821C12.6897 81.649 10.7941 81.3884 9.13577 80.5425C7.18604 79.586 5.54544 78.0992 4.40221 76.2527C2.97243 74.234 1.96607 71.9469 1.44374 69.5289C0.805593 66.5446 0.85618 63.454 1.59166 60.4921C2.25059 57.4261 3.0709 56.4578 4.40221 53.0959C5.07459 51.3881 5.54526 49.8954 7.36069 41.9882C8.70545 35.8292 9.71402 31.7411 11.0588 25.1115C11.9329 20.8889 13.5062 16.8008 14.0307 12.5245C14.1921 11.1797 14.6224 7.14544 17.1371 4.68453C17.5674 4.2542 18.6298 3.24564 19.6518 3.50115C20.6738 3.75665 21.0235 4.80555 21.1714 5.16864Z" fill="#DEE1E9"/>
        <path d="M1.42517 69.5309C1.94749 71.9489 2.95386 74.236 4.38364 76.2547C5.53079 78.0979 7.17033 79.5838 9.1172 80.5445C10.7765 81.3873 12.671 81.6478 14.4962 81.2841C15.6258 81.0286 15.6931 80.6924 18.7995 78.7694C21.9059 76.8464 22.2959 76.9137 23.0893 76.0799C23.8827 75.2461 24.8375 73.9821 25.3081 70.015C25.628 66.2888 25.3281 62.5354 24.4206 58.9073C24.2233 57.8136 24.0351 56.7243 23.8558 55.6395L19.3912 51.9549C17.8971 50.4105 15.8969 49.4558 13.7566 49.2654C12.6604 49.188 11.5596 49.325 10.5157 49.6688L4.43743 53.1248C3.09267 56.4867 2.28581 57.455 1.62688 60.521C0.877748 63.4702 0.808773 66.5511 1.42517 69.5309Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M13.2481 58.3969C14.3239 58.5852 16.3007 54.1206 17.2824 49.8308L18.2238 37.4859C18.3986 33.5592 18.5062 30.3586 18.5734 28.1532C18.7213 23.1507 18.6675 22.5321 18.8558 20.4074C19.3265 14.9477 19.1384 4.99853 18.856 4.8775C18.5736 4.75648 18.5734 14.0467 17.7262 16.5345C16.8818 19.6708 16.3458 22.8821 16.1259 26.1226C15.7933 29.6953 15.2364 33.2435 14.4584 36.7462C13.7322 39.5702 13.1137 40.915 12.253 44.9627C11.8008 46.8349 11.5617 48.7521 11.5403 50.678C11.5503 52.212 11.7262 53.7405 12.0647 55.2367C12.522 57.3077 12.764 58.3969 13.2481 58.3969Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="19" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
        <path d="M20.1714 2.25031C22.7533 8.38242 21.3548 20.1625 21.3548 20.1625C20.3065 28.4247 20.406 36.7922 21.6506 45.0272C22.2558 48.6849 22.7533 52.3561 23.4257 55.987C24.3399 59.614 24.6399 63.3685 24.3132 67.0947C23.856 71.0617 22.7668 72.4737 22.0944 73.1596C21.422 73.8454 21.032 73.8319 17.8046 75.8491C14.5772 77.8662 14.631 78.1083 13.5148 78.3638C11.6897 78.7307 9.79414 78.4701 8.13577 77.6242C6.18604 76.6676 4.54544 75.1808 3.40221 73.3344C1.97243 71.3157 0.966066 69.0285 0.443739 66.6106C-0.194407 63.6262 -0.14382 60.5357 0.591661 57.5738C1.25059 54.5077 2.0709 53.5395 3.40221 50.1776C4.07459 48.4697 4.54526 46.9771 6.36069 39.0699C7.70545 32.9109 8.71402 28.8228 10.0588 22.1931C10.9329 17.9706 12.5062 13.8825 13.0307 9.60615C13.1921 8.26139 13.6224 4.2271 16.1371 1.76619C16.5674 1.33587 17.6298 0.32731 18.6518 0.582814C19.6738 0.838319 20.0235 1.88722 20.1714 2.25031Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M20.1714 2.25031C22.7533 8.38242 21.3548 20.1625 21.3548 20.1625C20.3065 28.4247 20.406 36.7922 21.6506 45.0272C22.2558 48.6849 22.7533 52.3561 23.4257 55.987C24.3399 59.614 24.6399 63.3685 24.3132 67.0947C23.856 71.0617 22.7668 72.4737 22.0944 73.1596C21.422 73.8454 21.032 73.8319 17.8046 75.8491C14.5772 77.8662 14.631 78.1083 13.5148 78.3638C11.6897 78.7307 9.79414 78.4701 8.13577 77.6242C6.18604 76.6676 4.54544 75.1808 3.40221 73.3344C1.97243 71.3157 0.966066 69.0285 0.443739 66.6106C-0.194407 63.6262 -0.14382 60.5357 0.591661 57.5738C1.25059 54.5077 2.0709 53.5395 3.40221 50.1776C4.07459 48.4697 4.54526 46.9771 6.36069 39.0699C7.70545 32.9109 8.71402 28.8228 10.0588 22.1931C10.9329 17.9706 12.5062 13.8825 13.0307 9.60615C13.1921 8.26139 13.6224 4.2271 16.1371 1.76619C16.5674 1.33587 17.6298 0.32731 18.6518 0.582814C19.6738 0.838319 20.0235 1.88722 20.1714 2.25031Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
        <path d="M19.4174 19.7645C19.4174 19.7645 20.8159 7.98434 18.234 1.85223C18.0861 1.48915 17.7364 0.440248 16.7144 0.184743C15.6924 -0.0707615 14.63 0.937795 14.1997 1.36812C11.685 3.82903 11.2547 7.86332 11.0933 9.20808C10.5688 13.4844 8.99548 17.5725 8.12139 21.795C6.77662 28.4247 5.76805 32.5128 4.42329 38.6718C2.60786 46.579 2.1372 48.0717 1.46482 49.7795C0.133501 53.1414 0.117664 53.3264 0.117664 53.3264L7.19456 52.3579L13.3702 53.3264L20.9433 52.3579C20.2709 48.727 20.3184 48.2868 19.7132 44.6291C18.4686 36.3942 18.3691 28.0267 19.4174 19.7645Z" fill="#DEE1E9"/>
        <path d="M19.2545 46.2271C11.9658 44.7595 8.27958 45.7019 2.27244 49.9269" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M15.2864 34.1695L14.345 46.5144L13.977 53.766L12.0645 53.3015L10.0153 53.766L9.12737 51.9203C8.7888 50.4241 8.61296 48.8956 8.60291 47.3616C8.62432 45.4357 8.86341 43.5185 9.31563 41.6464C10.1763 37.5986 10.7949 36.2538 11.521 33.4299C12.299 29.9271 12.8559 26.3789 13.1885 22.8062C13.4084 19.5657 13.9444 16.3544 14.7888 13.2181C15.636 10.7303 15.6363 1.44008 15.9187 1.56111C16.2011 1.68214 16.3891 11.6313 15.9184 17.091C15.7301 19.2157 15.7839 19.8343 15.636 24.8368C15.5688 27.0422 15.4612 30.2428 15.2864 34.1695Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M22.4199 20.3524C22.4199 20.3524 23.8185 8.57227 21.2366 2.44015C21.0886 2.07707 20.739 1.02817 19.717 0.772664C18.695 0.51716 17.6326 1.52572 17.2023 1.95604C14.6876 4.41695 14.2572 8.45124 14.0959 9.796C13.5714 14.0723 11.998 18.1604 11.124 22.383C9.77919 29.0126 8.77062 33.1007 7.42586 39.2597C5.61043 47.1669 5.13976 48.6596 4.46738 50.3674C3.13607 53.7293 2.31577 54.6976 1.65683 57.7636L24.4909 56.1768C23.8185 52.546 23.3209 48.8748 22.7158 45.217C21.4712 36.9821 21.3716 28.6146 22.4199 20.3524Z" fill="#DEE1E9"/>
        <path d="M4.44882 73.5262C3.01904 71.5075 2.01267 69.2203 1.49034 66.8024C0.873953 63.8226 0.942928 60.7417 1.69206 57.7925C2.35099 54.7264 3.15784 53.7582 4.50261 50.3963L10.5809 46.9403C11.6248 46.5965 12.7256 46.4595 13.8218 46.5368C15.9621 46.7273 17.9623 47.682 19.4564 49.2264L23.921 52.911C24.1003 53.9958 24.2885 55.085 24.4858 56.1788C24.5735 56.5295 24.6555 56.8814 24.7319 57.2343L20.8693 59.0686L21.7764 61.7138L20.5688 64.1807L21.7764 66.4941L17.9673 68.0089L19.306 71.0438L15.7385 70.2727L17.9673 74.6119L18.8647 76.0409C15.7583 77.9639 15.691 78.3001 14.5614 78.5556C12.7362 78.9193 10.8417 78.6588 9.18237 77.816C7.23551 76.8552 5.59596 75.3694 4.44882 73.5262Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M23.5012 55.0693C12.8253 58.8426 10.5005 63.39 12.126 75.3255" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M13.3133 55.6684C14.3891 55.8567 16.3659 51.3921 17.3476 47.1023L18.2889 34.7574C18.4638 30.8307 18.5713 27.6301 18.6386 25.4247C18.7865 20.4222 18.7327 19.8037 18.921 17.6789C19.3916 12.2192 19.2036 2.27005 18.9212 2.14902C18.6388 2.02799 18.6386 11.3182 17.7914 13.806C16.947 16.9423 16.411 20.1536 16.1911 23.3942C15.8585 26.9668 15.3016 30.515 14.5236 34.0178C13.7974 36.8418 13.1788 38.1865 12.3182 42.2343C11.866 44.1064 11.6269 46.0236 11.6055 47.9495C11.6155 49.4835 11.7914 51.012 12.1299 52.5082C12.5871 54.5792 12.8292 55.6684 13.3133 55.6684Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M20.7754 1.74809C23.3573 7.8802 21.9588 19.6603 21.9588 19.6603C20.9105 27.9225 21.01 36.29 22.2546 44.5249C22.8598 48.1827 23.3573 51.8539 24.0297 55.4848C24.9439 59.1118 25.2439 62.8663 24.9173 66.5925C24.46 70.5595 23.3708 71.9715 22.6984 72.6573C22.026 73.3432 21.636 73.3297 18.4086 75.3469C15.1812 77.364 15.235 77.6061 14.1188 77.8616C12.2937 78.2285 10.3981 77.9678 8.73978 77.122C6.79004 76.1654 5.14945 74.6786 4.00622 72.8322C2.57644 70.8135 1.57007 68.5263 1.04774 66.1084C0.409597 63.124 0.460184 60.0334 1.19567 57.0716C1.8546 54.0055 2.6749 53.0373 4.00622 49.6754C4.6786 47.9675 5.14926 46.4749 6.96469 38.5677C8.30945 32.4087 9.31802 28.3206 10.6628 21.6909C11.5369 17.4684 13.1102 13.3803 13.6347 9.10394C13.7961 7.75918 14.2264 3.72489 16.7411 1.26398C17.1714 0.833654 18.2338 -0.174903 19.2558 0.0806019C20.2778 0.336106 20.6275 1.38501 20.7754 1.74809Z" fill="#DEE1E9"/>
        <path d="M3.98764 72.8341C2.55786 70.8155 1.5515 68.5283 1.02917 66.1103C0.412779 63.1306 0.481754 60.0497 1.23088 57.1004C1.88982 54.0344 2.69667 53.0662 4.04143 49.7043H7.62378C8.17606 49.7043 8.62378 50.152 8.62378 50.7043V58.8971C13.3206 60.275 13.1414 60.3026 17.8365 58.8971V50.7043C17.8365 50.152 18.2842 49.7043 18.8365 49.7043H22.6933C22.8726 50.789 23.8274 54.393 24.0246 55.4867C24.9321 59.1148 25.232 62.8683 24.9121 66.5945C24.4415 70.5615 23.4867 71.8256 22.6933 72.6593C21.8999 73.4931 21.5099 73.4258 18.4035 75.3489C15.2971 77.2719 15.2298 77.608 14.1002 77.8636C12.275 78.2273 10.3805 77.9668 8.7212 77.1239C6.77433 76.1632 5.13479 74.6773 3.98764 72.8341Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M20.7754 1.99888C23.3573 8.13099 21.9588 19.9111 21.9588 19.9111C20.9105 28.1733 21.01 36.5408 22.2546 44.7757C22.8598 48.4335 23.3573 52.1047 24.0297 55.7355C24.9439 59.3626 25.2439 63.1171 24.9173 66.8433C24.46 70.8103 23.3708 72.2223 22.6984 72.9081C22.026 73.594 21.636 73.5805 18.4086 75.5976C15.1812 77.6148 15.235 77.8569 14.1188 78.1124C12.2937 78.4793 10.3981 78.2186 8.73978 77.3727C6.79004 76.4162 5.14945 74.9294 4.00622 73.083C2.57644 71.0643 1.57007 68.7771 1.04774 66.3591C0.409597 63.3748 0.460184 60.2842 1.19567 57.3224C1.8546 54.2563 2.6749 53.2881 4.00622 49.9262C4.6786 48.2183 5.14926 46.7256 6.96469 38.8184C8.30945 32.6594 9.31802 28.5714 10.6628 21.9417C11.5369 17.7191 13.1102 13.6311 13.6347 9.35472C13.7961 8.00996 14.2264 3.97568 16.7411 1.51476C17.1714 1.08444 18.2338 0.0758831 19.2558 0.331388C20.2778 0.586892 20.6275 1.63579 20.7754 1.99888Z" fill="#DEE1E9"/>
        <path d="M3.98764 73.0849C2.55786 71.0663 1.5515 68.7791 1.02917 66.3611C0.412779 63.3813 0.481754 60.3004 1.23088 57.3512C1.88982 54.2852 2.69667 53.3169 4.04143 49.955H7.62378C8.17606 49.955 8.62378 50.4027 8.62378 50.955V59.1479C13.3206 60.5258 13.1414 60.5533 17.8365 59.1479V50.955C17.8365 50.4027 18.2842 49.955 18.8365 49.955H22.6933C22.8726 51.0398 23.8274 54.6438 24.0246 55.7375C24.9321 59.3656 25.232 63.119 24.9121 66.8452C24.4415 70.8123 23.4867 72.0764 22.6933 72.9101C21.8999 73.7439 21.5099 73.6766 18.4035 75.5996C15.2971 77.5226 15.2298 77.8588 14.1002 78.1143C12.275 78.478 10.3805 78.2176 8.7212 77.3747C6.77433 76.414 5.13479 74.9281 3.98764 73.0849Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M21.2725 2.06653C23.8544 8.19864 22.4558 19.9788 22.4558 19.9788C21.4075 28.241 21.5071 36.6085 22.7517 44.8434C23.3568 48.5011 23.8544 52.1723 24.5268 55.8032C25.441 59.4302 25.741 63.1848 25.4143 66.9109C24.9571 70.878 23.8678 72.2899 23.1955 72.9758C22.5231 73.6616 22.1331 73.6482 18.9057 75.6653C15.6783 77.6824 15.732 77.9245 14.6159 78.18C12.7908 78.5469 10.8952 78.2863 9.23685 77.4404C7.28712 76.4839 5.64652 74.9971 4.50329 73.1506C3.07351 71.1319 2.06714 68.8447 1.54481 66.4268C0.906668 63.4424 0.957254 60.3519 1.69274 57.39C2.35167 54.3239 3.17197 53.3557 4.50329 49.9938C5.17567 48.286 5.64633 46.7933 7.46176 38.8861C8.80652 32.7271 9.81509 28.639 11.1599 22.0093C12.0339 17.7868 13.6073 13.6987 14.1318 9.42237C14.2931 8.07761 14.7235 4.04333 17.2382 1.58241C17.6685 1.15209 18.7309 0.143533 19.7529 0.399038C20.7749 0.654542 21.1245 1.70344 21.2725 2.06653Z" fill="#DEE1E9"/>
        <path d="M4.48471 73.1526C3.05493 71.1339 2.04857 68.8467 1.52624 66.4288C0.909849 63.449 0.978825 60.3681 1.72795 57.4189C2.38689 54.3528 3.19374 53.3846 4.5385 50.0227H9.12085V59.2155C13.8177 60.5934 13.6385 60.621 18.3335 59.2155V50.0227H23.1903C23.3696 51.1074 24.3244 54.7114 24.5217 55.8051C25.4291 59.4333 25.729 63.1867 25.4092 66.9129C24.9385 70.8799 23.9837 72.144 23.1903 72.9778C22.3969 73.8115 22.0069 73.7443 18.9006 75.6673C15.7942 77.5903 15.7269 77.9265 14.5973 78.182C12.7721 78.5457 10.8776 78.2852 9.21827 77.4424C7.2714 76.4816 5.63186 74.9958 4.48471 73.1526Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask2025" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="27" height="29">
          <path d="M4.47568 73.1526C3.0459 71.1339 2.03953 68.8467 1.51721 66.4288C0.900816 63.449 0.969791 60.3681 1.71892 57.4189C2.33427 54.5556 3.07862 54.2812 4.26958 51.5195C4.4315 51.144 4.7976 50.8934 5.2065 50.8934H8.11182C8.6641 50.8934 9.11182 51.3411 9.11182 51.8934V58.4668C9.11182 58.9106 9.40688 59.3023 9.8328 59.4273C13.578 60.526 13.8618 60.5464 17.6086 59.4296C18.032 59.3034 18.3245 58.9129 18.3245 58.471V51.8934C18.3245 51.3411 18.7722 50.8934 19.3245 50.8934H22.333C22.8224 50.8934 23.2449 51.2463 23.3706 51.7192C23.7134 53.0093 24.355 54.9309 24.5126 55.8051C25.4201 59.4333 25.72 63.1867 25.4002 66.9129C24.9295 70.8799 23.9747 72.144 23.1813 72.9777C22.3879 73.8115 21.9979 73.7443 18.8915 75.6673C15.7851 77.5903 15.7179 77.9265 14.5883 78.182C12.7631 78.5457 10.8686 78.2852 9.20924 77.4424C7.26237 76.4816 5.62283 74.9958 4.47568 73.1526Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2025)">
          <path d="M21.8579 36.3316H5.7236V61.8796C12.0244 63.8058 15.5571 63.8254 21.8579 61.8796V36.3316Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M21.4878 1.73621C24.0697 7.86832 22.6712 19.6484 22.6712 19.6484C21.6229 27.9106 21.7224 36.2781 22.967 44.5131C23.5722 48.1708 24.0697 51.842 24.7421 55.4729C25.6563 59.0999 25.9563 62.8544 25.6297 66.5806C25.1724 70.5476 24.0832 71.9596 23.4108 72.6455C22.7384 73.3313 22.3484 73.3178 19.121 75.335C15.8936 77.3521 15.9474 77.5942 14.8312 77.8497C13.0061 78.2166 11.1105 77.956 9.45218 77.1101C7.50245 76.1535 5.86185 74.6667 4.71862 72.8203C3.28884 70.8016 2.28247 68.5144 1.76015 66.0965C1.122 63.1121 1.17259 60.0216 1.90807 57.0597C2.567 53.9936 3.3873 53.0254 4.71862 49.6635C5.391 47.9556 5.86167 46.463 7.67709 38.5558C9.02185 32.3968 10.0304 28.3087 11.3752 21.679C12.2493 17.4565 13.8227 13.3684 14.3471 9.09205C14.5085 7.74729 14.9388 3.713 17.4535 1.25209C17.8838 0.821768 18.9462 -0.186789 19.9682 0.0687153C20.9902 0.32422 21.3399 1.37312 21.4878 1.73621Z" fill="#DEE1E9"/>
        <path d="M1.74157 66.0985C2.2639 68.5165 3.27026 70.8037 4.70004 72.8223C5.84719 74.6655 7.48673 76.1514 9.4336 77.1121C11.0929 77.955 12.9874 78.2155 14.8126 77.8517C15.9422 77.5962 16.0095 77.2601 19.1159 75.337C22.2223 73.414 22.6123 73.4813 23.4057 72.6475C24.1991 71.8138 25.1539 70.5497 25.6245 66.5826C25.9444 62.8565 25.6445 59.103 24.737 55.4749C24.5398 54.3812 24.3515 53.2919 24.1722 52.2071L19.7076 48.5225C18.2135 46.9782 16.2133 46.0234 14.073 45.833C12.9768 45.7556 11.876 45.8927 10.8322 46.2364L4.75383 49.6925C3.40907 53.0544 2.60222 54.0226 1.94328 57.0886C1.19415 60.0378 1.12518 63.1188 1.74157 66.0985Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M13.5645 54.9644C14.6403 55.1527 16.6171 50.6881 17.5988 46.3983L18.5402 34.0534C18.715 30.1267 18.8226 26.9261 18.8898 24.7207C19.0377 19.7182 18.9839 19.0996 19.1722 16.9749C19.6429 11.5152 19.4549 1.56604 19.1725 1.44501C18.8901 1.32398 18.8898 10.6142 18.0426 13.102C17.1982 16.2383 16.6622 19.4496 16.4423 22.6902C16.1097 26.2628 15.5528 29.811 14.7748 33.3138C14.0487 36.1378 13.4301 37.4825 12.5694 41.5303C12.1172 43.4024 11.8781 45.3196 11.8567 47.2455C11.8667 48.7795 12.0426 50.308 12.3812 51.8042C12.8384 53.8752 13.0804 54.9644 13.5645 54.9644Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M22.874 13.5122C19.216 12.148 17.1733 12.2101 13.5369 13.5122" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M21.585 5.06659C24.1669 11.1987 22.7683 22.9788 22.7683 22.9788C21.72 31.241 21.8196 39.6085 23.0642 47.8434C23.6693 51.5012 24.1669 55.1724 24.8393 58.8033C25.7535 62.4303 26.0535 66.1848 25.7268 69.911C25.2696 73.878 24.1803 75.29 23.508 75.9758C22.8356 76.6617 22.4456 76.6482 19.2182 78.6654C15.9908 80.6825 16.0445 80.9246 14.9284 81.1801C13.1033 81.547 11.2077 81.2863 9.54935 80.4405C7.59962 79.4839 5.95902 77.9971 4.81579 76.1507C3.38601 74.132 2.37964 71.8448 1.85731 69.4269C1.21917 66.4425 1.26975 63.3519 2.00524 60.3901C2.66417 57.324 3.48447 56.3558 4.81579 52.9939C5.48817 51.286 5.95883 49.7934 7.77426 41.8862C9.11902 35.7271 10.1276 31.6391 11.4724 25.0094C12.3464 20.7868 13.9198 16.6988 14.4443 12.4224C14.6056 11.0777 15.036 7.04339 17.5507 4.58247C17.981 4.15215 19.0434 3.14359 20.0654 3.3991C21.0874 3.6546 21.437 4.7035 21.585 5.06659Z" fill="#DEE1E9"/>
        <circle r="4" transform="matrix(-1 0 0 1 20.1208 4.20099)" fill="rgba(243, 42, 102, 0.6)"/>
        <mask id="mask2225" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="27" height="34">
          <path d="M1.83874 69.4289C2.36107 71.8468 3.36743 74.134 4.79721 76.1527C5.94436 77.9959 7.5839 79.4817 9.53077 80.4425C11.1901 81.2853 13.0846 81.5458 14.9098 81.1821C16.0394 80.9266 16.1067 80.5904 19.2131 78.6674C22.3194 76.7444 22.7094 76.8116 23.5028 75.9778C24.2962 75.1441 25.251 73.88 25.7217 69.913C26.0415 66.1868 25.7416 62.4334 24.8342 58.8052C24.6369 57.7115 24.4487 56.6222 24.2694 55.5375L19.8047 51.8528C18.3107 50.3085 16.3105 49.3538 14.1702 49.1633C13.074 49.086 11.9731 49.223 10.9293 49.5667L4.851 53.0228C3.50624 56.3847 2.69938 57.3529 2.04045 60.419C1.29132 63.3682 1.22235 66.4491 1.83874 69.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2225)">
          <path d="M1.83874 69.4876C2.36107 71.9055 3.36743 74.1927 4.79721 76.2114C5.94436 78.0546 7.5839 79.5404 9.53077 80.5012C11.1901 81.344 13.0846 81.6045 14.9098 81.2408C16.0394 80.9853 16.1067 80.6491 19.2131 78.7261C22.3194 76.8031 22.7094 76.8703 23.5028 76.0366C24.2962 75.2028 25.251 73.9387 25.7217 69.9717C26.0415 66.2455 25.7416 62.4921 24.8342 58.864C24.6369 57.7702 24.4487 56.681 24.2694 55.5962L19.8047 51.9115C18.3107 50.3672 16.3105 49.4125 14.1702 49.222C13.074 49.1447 11.9731 49.2817 10.9293 49.6254L4.851 53.0815C3.50624 56.4434 2.69938 57.4116 2.04045 60.4777C1.29132 63.4269 1.22235 66.5078 1.83874 69.4876Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.3399 66.201C14.3203 66.201 15.8547 73.6692 15.8547 82.201H13.9317H10.8547C10.8547 73.6692 12.3596 66.201 13.3399 66.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M12.8766 68.1943C14.1502 68.4159 16.4904 63.1611 17.6526 58.112L18.767 43.5822C18.9739 38.9605 19.1013 35.1935 19.1809 32.5977C19.356 26.7098 19.2923 25.9818 19.5152 23.481C20.0724 17.0549 19.8498 5.34488 19.5155 5.20243C19.1812 5.05998 19.1809 15.9945 18.1779 18.9226C17.1783 22.614 16.5438 26.3937 16.2835 30.2078C15.8897 34.4127 15.2304 38.5889 14.3094 42.7116C13.4497 46.0355 12.7174 47.6182 11.6985 52.3824C11.1631 54.5859 10.8801 56.8425 10.8547 59.1092C10.8666 60.9147 11.0748 62.7137 11.4756 64.4748C12.0169 66.9122 12.3035 68.1943 12.8766 68.1943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M21.585 9.06659C24.1669 15.1987 22.7683 26.9788 22.7683 26.9788C21.72 35.241 21.8196 43.6085 23.0642 51.8434C23.6693 55.5012 24.1669 59.1724 24.8393 62.8033C25.7535 66.4303 26.0535 70.1848 25.7268 73.911C25.2696 77.878 24.1803 79.29 23.508 79.9758C22.8356 80.6617 22.4456 80.6482 19.2182 82.6654C15.9908 84.6825 16.0445 84.9246 14.9284 85.1801C13.1033 85.547 11.2077 85.2863 9.54935 84.4405C7.59962 83.4839 5.95902 81.9971 4.81579 80.1507C3.38601 78.132 2.37964 75.8448 1.85731 73.4269C1.21917 70.4425 1.26975 67.3519 2.00524 64.3901C2.66417 61.324 3.48447 60.3558 4.81579 56.9939C5.48817 55.286 5.95883 53.7934 7.77426 45.8862C9.11902 39.7271 10.1276 35.6391 11.4724 29.0094C12.3464 24.7868 13.9198 20.6988 14.4443 16.4224C14.6056 15.0777 15.036 11.0434 17.5507 8.58247C17.981 8.15215 19.0434 7.14359 20.0654 7.3991C21.0874 7.6546 21.437 8.7035 21.585 9.06659Z" fill="#DEE1E9"/>
        <circle r="4" transform="matrix(-1 0 0 1 20.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2325)">
          <path d="M19.1208 0.200989L18.2979 3.94009L15.2641 1.60472L17.0372 4.99799L13.212 5.1591L16.7514 6.61879L13.9247 9.20099L17.5743 8.04409L17.0687 11.8391L19.1208 8.60699L21.173 11.8391L20.6674 8.04409L24.317 9.20099L21.4903 6.61879L25.0297 5.1591L21.2045 4.99799L22.9776 1.60472L19.9437 3.94009L19.1208 0.200989Z" fill="#98B250"/>
        </g>
        <mask id="mask2325" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="52" width="27" height="34">
          <path d="M1.83874 73.4289C2.36107 75.8468 3.36743 78.134 4.79721 80.1527C5.94436 81.9959 7.5839 83.4817 9.53077 84.4425C11.1901 85.2853 13.0846 85.5458 14.9098 85.1821C16.0394 84.9266 16.1067 84.5904 19.2131 82.6674C22.3194 80.7444 22.7094 80.8116 23.5028 79.9778C24.2962 79.1441 25.251 77.88 25.7217 73.913C26.0415 70.1868 25.7416 66.4334 24.8342 62.8052C24.6369 61.7115 24.4487 60.6222 24.2694 59.5375L19.8047 55.8528C18.3107 54.3085 16.3105 53.3538 14.1702 53.1633C13.074 53.086 11.9731 53.223 10.9293 53.5667L4.851 57.0228C3.50624 60.3847 2.69938 61.3529 2.04045 64.419C1.29132 67.3682 1.22235 70.4491 1.83874 73.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2325)">
          <path d="M1.83874 73.4876C2.36107 75.9055 3.36743 78.1927 4.79721 80.2114C5.94436 82.0546 7.5839 83.5404 9.53077 84.5012C11.1901 85.344 13.0846 85.6045 14.9098 85.2408C16.0394 84.9853 16.1067 84.6491 19.2131 82.7261C22.3194 80.8031 22.7094 80.8703 23.5028 80.0366C24.2962 79.2028 25.251 77.9387 25.7217 73.9717C26.0415 70.2455 25.7416 66.4921 24.8342 62.864C24.6369 61.7702 24.4487 60.681 24.2694 59.5962L19.8047 55.9115C18.3107 54.3672 16.3105 53.4125 14.1702 53.222C13.074 53.1447 11.9731 53.2817 10.9293 53.6254L4.851 57.0815C3.50624 60.4434 2.69938 61.4116 2.04045 64.4777C1.29132 67.4269 1.22235 70.5078 1.83874 73.4876Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.3399 70.201C14.3203 70.201 15.8547 77.6692 15.8547 86.201H13.9317H10.8547C10.8547 77.6692 12.3596 70.201 13.3399 70.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M12.8766 72.1943C14.1502 72.4159 16.4904 67.1611 17.6526 62.112L18.767 47.5822C18.9739 42.9605 19.1013 39.1935 19.1809 36.5977C19.356 30.7098 19.2923 29.9818 19.5152 27.481C20.0724 21.0549 19.8498 9.34488 19.5155 9.20243C19.1812 9.05998 19.1809 19.9945 18.1779 22.9226C17.1783 26.614 16.5438 30.3937 16.2835 34.2078C15.8897 38.4127 15.2304 42.5889 14.3094 46.7116C13.4497 50.0355 12.7174 51.6182 11.6985 56.3824C11.1631 58.5859 10.8801 60.8425 10.8547 63.1092C10.8666 64.9147 11.0748 66.7137 11.4756 68.4748C12.0169 70.9122 12.3035 72.1943 12.8766 72.1943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <defs>
          <filter id="filter2325" x="13.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M21.585 9.06659C24.1669 15.1987 22.7683 26.9788 22.7683 26.9788C21.72 35.241 21.8196 43.6085 23.0642 51.8434C23.6693 55.5012 24.1669 59.1724 24.8393 62.8033C25.7535 66.4303 26.0535 70.1848 25.7268 73.911C25.2696 77.878 24.1803 79.29 23.508 79.9758C22.8356 80.6617 22.4456 80.6482 19.2182 82.6654C15.9908 84.6825 16.0445 84.9246 14.9284 85.1801C13.1033 85.547 11.2077 85.2863 9.54935 84.4405C7.59962 83.4839 5.95902 81.9971 4.81579 80.1507C3.38601 78.132 2.37964 75.8448 1.85731 73.4269C1.21917 70.4425 1.26975 67.3519 2.00524 64.3901C2.66417 61.324 3.48447 60.3558 4.81579 56.9939C5.48817 55.286 5.95883 53.7934 7.77426 45.8862C9.11902 39.7271 10.1276 35.6391 11.4724 29.0094C12.3464 24.7868 13.9198 20.6988 14.4443 16.4224C14.6056 15.0777 15.036 11.0434 17.5507 8.58247C17.981 8.15215 19.0434 7.14359 20.0654 7.3991C21.0874 7.6546 21.437 8.7035 21.585 9.06659Z" fill="#DEE1E9"/>
      <circle r="4" transform="matrix(-1 0 0 1 20.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2325)">
        <path d="M19.1208 0.200989L18.2979 3.94009L15.2641 1.60472L17.0372 4.99799L13.212 5.1591L16.7514 6.61879L13.9247 9.20099L17.5743 8.04409L17.0687 11.8391L19.1208 8.60699L21.173 11.8391L20.6674 8.04409L24.317 9.20099L21.4903 6.61879L25.0297 5.1591L21.2045 4.99799L22.9776 1.60472L19.9437 3.94009L19.1208 0.200989Z" fill="#98B250"/>
      </g>
      <mask id="mask2325" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="52" width="27" height="34">
        <path d="M1.83874 73.4289C2.36107 75.8468 3.36743 78.134 4.79721 80.1527C5.94436 81.9959 7.5839 83.4817 9.53077 84.4425C11.1901 85.2853 13.0846 85.5458 14.9098 85.1821C16.0394 84.9266 16.1067 84.5904 19.2131 82.6674C22.3194 80.7444 22.7094 80.8116 23.5028 79.9778C24.2962 79.1441 25.251 77.88 25.7217 73.913C26.0415 70.1868 25.7416 66.4334 24.8342 62.8052C24.6369 61.7115 24.4487 60.6222 24.2694 59.5375L19.8047 55.8528C18.3107 54.3085 16.3105 53.3538 14.1702 53.1633C13.074 53.086 11.9731 53.223 10.9293 53.5667L4.851 57.0228C3.50624 60.3847 2.69938 61.3529 2.04045 64.419C1.29132 67.3682 1.22235 70.4491 1.83874 73.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2325)">
        <path d="M1.83874 73.4876C2.36107 75.9055 3.36743 78.1927 4.79721 80.2114C5.94436 82.0546 7.5839 83.5404 9.53077 84.5012C11.1901 85.344 13.0846 85.6045 14.9098 85.2408C16.0394 84.9853 16.1067 84.6491 19.2131 82.7261C22.3194 80.8031 22.7094 80.8703 23.5028 80.0366C24.2962 79.2028 25.251 77.9387 25.7217 73.9717C26.0415 70.2455 25.7416 66.4921 24.8342 62.864C24.6369 61.7702 24.4487 60.681 24.2694 59.5962L19.8047 55.9115C18.3107 54.3672 16.3105 53.4125 14.1702 53.222C13.074 53.1447 11.9731 53.2817 10.9293 53.6254L4.851 57.0815C3.50624 60.4434 2.69938 61.4116 2.04045 64.4777C1.29132 67.4269 1.22235 70.5078 1.83874 73.4876Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.3399 70.201C14.3203 70.201 15.8547 77.6692 15.8547 86.201H13.9317H10.8547C10.8547 77.6692 12.3596 70.201 13.3399 70.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M12.8766 72.1943C14.1502 72.4159 16.4904 67.1611 17.6526 62.112L18.767 47.5822C18.9739 42.9605 19.1013 39.1935 19.1809 36.5977C19.356 30.7098 19.2923 29.9818 19.5152 27.481C20.0724 21.0549 19.8498 9.34488 19.5155 9.20243C19.1812 9.05998 19.1809 19.9945 18.1779 22.9226C17.1783 26.614 16.5438 30.3937 16.2835 34.2078C15.8897 38.4127 15.2304 42.5889 14.3094 46.7116C13.4497 50.0355 12.7174 51.6182 11.6985 56.3824C11.1631 58.5859 10.8801 60.8425 10.8547 63.1092C10.8666 64.9147 11.0748 66.7137 11.4756 68.4748C12.0169 70.9122 12.3035 72.1943 12.8766 72.1943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <defs>
        <filter id="filter2325" x="13.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M21.585 5.06653C24.1669 11.1986 22.7683 22.9788 22.7683 22.9788C21.72 31.241 21.8196 39.6085 23.0642 47.8434C23.6693 51.5011 24.1669 55.1723 24.8393 58.8032C25.7535 62.4302 26.0535 66.1848 25.7268 69.9109C25.2696 73.878 24.1803 75.2899 23.508 75.9758C22.8356 76.6616 22.4456 76.6482 19.2182 78.6653C15.9908 80.6824 16.0445 80.9245 14.9284 81.18C13.1033 81.5469 11.2077 81.2863 9.54935 80.4404C7.59962 79.4839 5.95902 77.9971 4.81579 76.1506C3.38601 74.1319 2.37964 71.8447 1.85731 69.4268C1.21917 66.4424 1.26975 63.3519 2.00524 60.39C2.66417 57.3239 3.48447 56.3557 4.81579 52.9938C5.48817 51.286 5.95883 49.7933 7.77426 41.8861C9.11902 35.7271 10.1276 31.639 11.4724 25.0093C12.3464 20.7868 13.9198 16.6987 14.4443 12.4224C14.6056 11.0776 15.036 7.04333 17.5507 4.58241C17.981 4.15209 19.0434 3.14353 20.0654 3.39904C21.0874 3.65454 21.437 4.70344 21.585 5.06653Z" fill="#DEE1E9"/>
        <mask id="mask2525" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="27" height="34">
          <path d="M1.83874 69.4287C2.36107 71.8467 3.36743 74.1339 4.79721 76.1525C5.94436 77.9957 7.5839 79.4816 9.53077 80.4423C11.1901 81.2852 13.0846 81.5457 14.9098 81.1819C16.0394 80.9264 16.1067 80.5903 19.2131 78.6672C22.3194 76.7442 22.7094 76.8115 23.5028 75.9777C24.2962 75.144 25.251 73.8799 25.7217 69.9128C26.0415 66.1867 25.7416 62.4332 24.8342 58.8051C24.6369 57.7114 24.4487 56.6221 24.2694 55.5373L19.8047 51.8527C18.3107 50.3084 16.3105 49.3536 14.1702 49.1632C13.074 49.0858 11.9731 49.2229 10.9293 49.5666L4.851 53.0227C3.50624 56.3846 2.69938 57.3528 2.04045 60.4188C1.29132 63.368 1.22235 66.449 1.83874 69.4287Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2525)">
          <path d="M1.83874 69.4874C2.36107 71.9054 3.36743 74.1926 4.79721 76.2113C5.94436 78.0545 7.5839 79.5403 9.53077 80.501C11.1901 81.3439 13.0846 81.6044 14.9098 81.2407C16.0394 80.9852 16.1067 80.649 19.2131 78.726C22.3194 76.803 22.7094 76.8702 23.5028 76.0364C24.2962 75.2027 25.251 73.9386 25.7217 69.9716C26.0415 66.2454 25.7416 62.492 24.8342 58.8638C24.6369 57.7701 24.4487 56.6808 24.2694 55.5961L19.8047 51.9114C18.3107 50.3671 16.3105 49.4123 14.1702 49.2219C13.074 49.1446 11.9731 49.2816 10.9293 49.6253L4.851 53.0814C3.50624 56.4433 2.69938 57.4115 2.04045 60.4776C1.29132 63.4268 1.22235 66.5077 1.83874 69.4874Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.6061 72.2009C14.5864 72.2009 16.1208 76.8685 16.1208 82.2009H14.1978H11.1208C11.1208 76.8685 12.6257 72.2009 13.6061 72.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M13.1427 74.1936C14.4163 74.4328 16.7565 68.7609 17.9187 63.3112L19.0331 47.6282C19.2401 42.6397 19.3674 38.5737 19.447 35.772C19.6221 29.4167 19.5585 28.6309 19.7813 25.9317C20.3385 18.9956 20.116 6.35617 19.7816 6.20242C19.4473 6.04866 19.447 17.851 18.4441 21.0115C17.4444 24.9959 16.8099 29.0755 16.5496 33.1923C16.1558 37.731 15.4965 42.2386 14.5755 46.6885C13.7158 50.2762 12.9835 51.9846 11.9646 57.1268C11.4292 59.5052 11.1462 61.9408 11.1208 64.3875C11.1327 66.3363 11.3409 68.2781 11.7417 70.1789C12.283 72.8098 12.5696 74.1936 13.1427 74.1936Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 20.1208 4.20093)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2625" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="80">
          <path d="M20.7754 2.55188C23.3573 8.68399 21.9588 20.4641 21.9588 20.4641C20.9105 28.7263 21.01 37.0938 22.2546 45.3287C22.8598 48.9865 23.3573 52.6577 24.0297 56.2885C24.9439 59.9156 25.2439 63.6701 24.9173 67.3963C24.46 71.3633 23.3708 72.7753 22.6984 73.4611C22.026 74.147 21.636 74.1335 18.4086 76.1507C15.1812 78.1678 15.235 78.4099 14.1188 78.6654C12.2937 79.0323 10.3981 78.7716 8.73978 77.9258C6.79004 76.9692 5.14945 75.4824 4.00622 73.636C2.57644 71.6173 1.57007 69.3301 1.04774 66.9121C0.409597 63.9278 0.460184 60.8372 1.19567 57.8754C1.8546 54.8093 2.6749 53.8411 4.00622 50.4792C4.6786 48.7713 5.14926 47.2786 6.96469 39.3714C8.30945 33.2124 9.31802 29.1244 10.6628 22.4947C11.5369 18.2721 13.1102 14.1841 13.6347 9.90772C13.7961 8.56296 14.2264 4.52868 16.7411 2.06776C17.1714 1.63744 18.2338 0.628884 19.2558 0.884389C20.2778 1.13989 20.6275 2.18879 20.7754 2.55188Z" fill="#DEE1E9"/>
          <path d="M1.02917 66.9141C1.5515 69.332 2.55786 71.6192 3.98764 73.6379C5.13479 75.4811 6.77433 76.9669 8.7212 77.9277C10.3805 78.7705 12.275 79.031 14.1002 78.6673C15.2298 78.4118 15.2971 78.0756 18.4035 76.1526C21.5099 74.2296 21.8999 74.2968 22.6933 73.4631C23.4867 72.6293 24.4415 71.3652 24.9121 67.3982C25.232 63.672 24.9321 59.9186 24.0246 56.2905C23.8274 55.1967 23.6391 54.1075 23.4598 53.0227L18.9952 49.3381C17.5011 47.7937 15.5009 46.839 13.3606 46.6485C12.2644 46.5712 11.1636 46.7082 10.1198 47.052L4.04143 50.508C2.69667 53.8699 1.88981 54.8381 1.23088 57.9042C0.481752 60.8534 0.412777 63.9343 1.02917 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2625)">
          <path d="M20.7754 2.55188C23.3573 8.68399 21.9588 20.4641 21.9588 20.4641C20.9105 28.7263 21.01 37.0938 22.2546 45.3287C22.8598 48.9865 23.3573 52.6577 24.0297 56.2885C24.9439 59.9156 25.2439 63.6701 24.9173 67.3963C24.46 71.3633 23.3708 72.7753 22.6984 73.4611C22.026 74.147 21.636 74.1335 18.4086 76.1507C15.1812 78.1678 15.235 78.4099 14.1188 78.6654C12.2937 79.0323 10.3981 78.7716 8.73978 77.9258C6.79004 76.9692 5.14945 75.4824 4.00622 73.636C2.57644 71.6173 1.57007 69.3301 1.04774 66.9121C0.409597 63.9278 0.460184 60.8372 1.19567 57.8754C1.8546 54.8093 2.6749 53.8411 4.00622 50.4792C4.6786 48.7713 5.14926 47.2786 6.96469 39.3714C8.30945 33.2124 9.31802 29.1244 10.6628 22.4947C11.5369 18.2721 13.1102 14.1841 13.6347 9.90772C13.7961 8.56296 14.2264 4.52868 16.7411 2.06776C17.1714 1.63744 18.2338 0.628884 19.2558 0.884389C20.2778 1.13989 20.6275 2.18879 20.7754 2.55188Z" fill="#DEE1E9"/>
          <path d="M1.02917 66.9141C1.5515 69.332 2.55786 71.6192 3.98764 73.6379C5.13479 75.4811 6.77433 76.9669 8.7212 77.9277C10.3805 78.7705 12.275 79.031 14.1002 78.6673C15.2298 78.4118 15.2971 78.0756 18.4035 76.1526C21.5099 74.2296 21.8999 74.2968 22.6933 73.4631C23.4867 72.6293 24.4415 71.3652 24.9121 67.3982C25.232 63.672 24.9321 59.9186 24.0246 56.2905C23.8274 55.1967 23.6391 54.1075 23.4598 53.0227L18.9952 49.3381C17.5011 47.7937 15.5009 46.839 13.3606 46.6485C12.2644 46.5712 11.1636 46.7082 10.1198 47.052L4.04143 50.508C2.69667 53.8699 1.88981 54.8381 1.23088 57.9042C0.481752 60.8534 0.412777 63.9343 1.02917 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.5978 50.5247H1.67667C3.83873 45.1549 4.19573 41.8947 3.8992 36.1564C3.88802 35.94 4.13739 35.8054 4.32761 35.932C5.33169 36.6 7.68988 38.4161 8.26153 41.0577C8.954 44.2577 10.9572 45.0348 11.1698 45.1089C11.1828 45.1134 11.1944 45.1179 11.2067 45.1236L12.3897 45.6703C12.5342 45.7371 12.5928 45.8996 12.5206 46.0332L11.9916 47.0109C11.9469 47.0936 11.951 47.1917 12.0024 47.271L13.8488 50.1151C13.9658 50.2954 13.8253 50.5247 13.5978 50.5247Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.67275 37.0459C5.73373 37.8938 7.14171 39.3307 7.54413 41.1903C8.28734 44.6248 10.4633 45.5809 10.8963 45.7361L11.6699 46.0936L11.3373 46.7084C11.1806 46.9979 11.1949 47.3413 11.3751 47.6189L12.8225 49.8485H2.71999C4.49672 45.1838 4.87272 41.9881 4.67275 37.0459ZM10.8796 45.7284C10.8794 45.7283 10.8797 45.7285 10.8796 45.7284V45.7284ZM4.75508 35.3832C4.02622 34.8983 3.13049 35.4547 3.16842 36.1886C3.46123 41.8548 3.11202 45.0209 0.990997 50.2889L0.623778 51.2009H13.5979C14.3941 51.2009 14.8859 50.3982 14.4762 49.7672L12.7539 47.114L13.175 46.3356C13.428 45.8679 13.2229 45.2992 12.7169 45.0654L11.534 44.5188C11.4933 44.5 11.4574 44.4864 11.4279 44.4761C11.3913 44.4634 9.61049 43.8432 8.97901 40.9251C8.34951 38.0161 5.78613 36.0691 4.75508 35.3832Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask125" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="80">
          <path d="M20.7754 2.55188C23.3573 8.68399 21.9588 20.4641 21.9588 20.4641C20.9105 28.7263 21.01 37.0938 22.2546 45.3287C22.8598 48.9865 23.3573 52.6577 24.0297 56.2885C24.9439 59.9156 25.2439 63.6701 24.9173 67.3963C24.46 71.3633 23.3708 72.7753 22.6984 73.4611C22.026 74.147 21.636 74.1335 18.4086 76.1507C15.1812 78.1678 15.235 78.4099 14.1188 78.6654C12.2937 79.0323 10.3981 78.7716 8.73978 77.9258C6.79004 76.9692 5.14945 75.4824 4.00622 73.636C2.57644 71.6173 1.57007 69.3301 1.04774 66.9121C0.409597 63.9278 0.460184 60.8372 1.19567 57.8754C1.8546 54.8093 2.6749 53.8411 4.00622 50.4792C4.6786 48.7713 5.14926 47.2786 6.96469 39.3714C8.30945 33.2124 9.31802 29.1244 10.6628 22.4947C11.5369 18.2721 13.1102 14.1841 13.6347 9.90772C13.7961 8.56296 14.2264 4.52868 16.7411 2.06776C17.1714 1.63744 18.2338 0.628884 19.2558 0.884389C20.2778 1.13989 20.6275 2.18879 20.7754 2.55188Z" fill="#DEE1E9"/>
          <path d="M1.02917 66.9141C1.5515 69.332 2.55786 71.6192 3.98764 73.6379C5.13479 75.4811 6.77433 76.9669 8.7212 77.9277C10.3805 78.7705 12.275 79.031 14.1002 78.6673C15.2298 78.4118 15.2971 78.0756 18.4035 76.1526C21.5099 74.2296 21.8999 74.2968 22.6933 73.4631C23.4867 72.6293 24.4415 71.3652 24.9121 67.3982C25.232 63.672 24.9321 59.9186 24.0246 56.2905C23.8274 55.1967 23.6391 54.1075 23.4598 53.0227L18.9952 49.3381C17.5011 47.7937 15.5009 46.839 13.3606 46.6485C12.2644 46.5712 11.1636 46.7082 10.1198 47.052L4.04143 50.508C2.69667 53.8699 1.88981 54.8381 1.23088 57.9042C0.481752 60.8534 0.412777 63.9343 1.02917 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask125)">
          <path d="M20.7754 2.55188C23.3573 8.68399 21.9588 20.4641 21.9588 20.4641C20.9105 28.7263 21.01 37.0938 22.2546 45.3287C22.8598 48.9865 23.3573 52.6577 24.0297 56.2885C24.9439 59.9156 25.2439 63.6701 24.9173 67.3963C24.46 71.3633 23.3708 72.7753 22.6984 73.4611C22.026 74.147 21.636 74.1335 18.4086 76.1507C15.1812 78.1678 15.235 78.4099 14.1188 78.6654C12.2937 79.0323 10.3981 78.7716 8.73978 77.9258C6.79004 76.9692 5.14945 75.4824 4.00622 73.636C2.57644 71.6173 1.57007 69.3301 1.04774 66.9121C0.409597 63.9278 0.460184 60.8372 1.19567 57.8754C1.8546 54.8093 2.6749 53.8411 4.00622 50.4792C4.6786 48.7713 5.14926 47.2786 6.96469 39.3714C8.30945 33.2124 9.31802 29.1244 10.6628 22.4947C11.5369 18.2721 13.1102 14.1841 13.6347 9.90772C13.7961 8.56296 14.2264 4.52868 16.7411 2.06776C17.1714 1.63744 18.2338 0.628884 19.2558 0.884389C20.2778 1.13989 20.6275 2.18879 20.7754 2.55188Z" fill="#DEE1E9"/>
          <path d="M1.02917 66.9141C1.5515 69.332 2.55786 71.6192 3.98764 73.6379C5.13479 75.4811 6.77433 76.9669 8.7212 77.9277C10.3805 78.7705 12.275 79.031 14.1002 78.6673C15.2298 78.4118 15.2971 78.0756 18.4035 76.1526C21.5099 74.2296 21.8999 74.2968 22.6933 73.4631C23.4867 72.6293 24.4415 71.3652 24.9121 67.3982C25.232 63.672 24.9321 59.9186 24.0246 56.2905C23.8274 55.1967 23.6391 54.1075 23.4598 53.0227L18.9952 49.3381C17.5011 47.7937 15.5009 46.839 13.3606 46.6485C12.2644 46.5712 11.1636 46.7082 10.1198 47.052L4.04143 50.508C2.69667 53.8699 1.88981 54.8381 1.23088 57.9042C0.481752 60.8534 0.412777 63.9343 1.02917 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M10.9227 49.5315H2.77592C4.25343 45.561 4.49741 43.1504 4.29476 38.9074C4.28712 38.7474 4.45754 38.6479 4.58753 38.7415C5.2737 39.2354 6.88526 40.5782 7.27592 42.5315C7.74913 44.8976 9.1181 45.4722 9.26339 45.527C9.27224 45.5303 9.28016 45.5337 9.28863 45.5379L10.097 45.9421C10.1958 45.9915 10.2359 46.1116 10.1865 46.2104L9.82501 46.9333C9.79443 46.9945 9.79722 47.067 9.8324 47.1257L11.0942 49.2286C11.1742 49.3619 11.0781 49.5315 10.9227 49.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.82339 39.5651C5.54845 40.1921 6.51064 41.2545 6.78565 42.6295C7.29354 45.169 8.78057 45.876 9.07646 45.9908L9.60512 46.2551L9.37782 46.7097C9.27079 46.9238 9.28054 47.1777 9.40368 47.3829L10.3928 49.0315H3.4889C4.70309 45.5824 4.96005 43.2194 4.82339 39.5651ZM9.06504 45.9851C9.06494 45.985 9.06514 45.9851 9.06504 45.9851V45.9851ZM4.87965 38.3357C4.38156 37.9771 3.76943 38.3885 3.79535 38.9312C3.99545 43.1208 3.75681 45.4619 2.30733 49.3571L2.05638 50.0315H10.9227C11.4668 50.0315 11.8029 49.4379 11.5229 48.9713L10.3459 47.0096L10.6337 46.434C10.8066 46.0882 10.6664 45.6677 10.3207 45.4948L9.51226 45.0906C9.48443 45.0767 9.45993 45.0667 9.43976 45.0591C9.41475 45.0497 8.19777 44.5911 7.76623 42.4334C7.33604 40.2825 5.58425 38.8428 4.87965 38.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M20.8511 2.06653C23.433 8.19864 22.0345 19.9788 22.0345 19.9788C20.9861 28.241 21.0857 36.6085 22.3303 44.8434C22.9355 48.5011 23.433 52.1723 24.1054 55.8032C25.0196 59.4302 25.3196 63.1848 24.9929 66.9109C24.5357 70.878 23.4465 72.2899 22.7741 72.9758C22.1017 73.6616 21.7117 73.6482 18.4843 75.6653C15.2569 77.6824 15.3107 77.9245 14.1945 78.18C12.3694 78.5469 10.4738 78.2863 8.81546 77.4404C6.86573 76.4839 5.22513 74.9971 4.0819 73.1506C2.65212 71.1319 1.64575 68.8447 1.12343 66.4268C0.485281 63.4424 0.535868 60.3519 1.27135 57.39C1.93028 54.3239 2.75059 53.3557 4.0819 49.9938C4.75428 48.286 5.22495 46.7933 7.04037 38.8861C8.38514 32.7271 9.39371 28.639 10.7385 22.0093C11.6126 17.7868 13.1859 13.6987 13.7104 9.42237C13.8718 8.07761 14.3021 4.04333 16.8168 1.58241C17.2471 1.15209 18.3095 0.143533 19.3315 0.399038C20.3535 0.654542 20.7031 1.70344 20.8511 2.06653Z" fill="#DEE1E9"/>
        <mask id="mask225" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="26" height="34">
          <path d="M1.10485 66.4289C1.62718 68.8468 2.63354 71.134 4.06333 73.1527C5.21047 74.9959 6.85002 76.4817 8.79688 77.4425C10.4562 78.2853 12.3507 78.5458 14.1759 78.1821C15.3055 77.9266 15.3728 77.5904 18.4792 75.6674C21.5856 73.7444 21.9755 73.8116 22.769 72.9778C23.5624 72.1441 24.5171 70.88 24.9878 66.913C25.3076 63.1868 25.0077 59.4334 24.1003 55.8052C23.903 54.7115 23.7148 53.6222 23.5355 52.5375L19.0709 48.8528C17.5768 47.3085 15.5766 46.3538 13.4363 46.1633C12.3401 46.086 11.2393 46.223 10.1954 46.5667L4.11712 50.0228C2.77235 53.3847 1.9655 54.3529 1.30657 57.419C0.557436 60.3682 0.488461 63.4491 1.10485 66.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask225)">
          <path d="M1.10485 66.4877C1.62718 68.9056 2.63354 71.1928 4.06333 73.2115C5.21047 75.0547 6.85002 76.5405 8.79688 77.5013C10.4562 78.3441 12.3507 78.6046 14.1759 78.2409C15.3055 77.9854 15.3728 77.6492 18.4792 75.7262C21.5856 73.8032 21.9755 73.8704 22.769 73.0367C23.5624 72.2029 24.5171 70.9389 24.9878 66.9718C25.3076 63.2456 25.0077 59.4922 24.1003 55.8641C23.903 54.7703 23.7148 53.6811 23.5355 52.5963L19.0709 48.9117C17.5768 47.3673 15.5766 46.4126 13.4363 46.2221C12.3401 46.1448 11.2393 46.2818 10.1954 46.6256L4.11712 50.0816C2.77235 53.4435 1.9655 54.4117 1.30657 57.4778C0.557436 60.427 0.488461 63.5079 1.10485 66.4877Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M12.6001 63.2012C13.9727 63.2012 16.1208 70.6693 16.1208 79.2012H13.4285H9.12085C9.12085 70.6693 11.2276 63.2012 12.6001 63.2012Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M12.1427 65.1942C13.4163 65.4157 15.7565 60.1609 16.9187 55.1119L18.0331 40.5821C18.2401 35.9604 18.3674 32.1934 18.447 29.5976C18.6221 23.7097 18.5585 22.9816 18.7813 20.4809C19.3385 14.0548 19.116 2.34476 18.7816 2.20231C18.4473 2.05986 18.447 12.9944 17.4441 15.9225C16.4444 19.6139 15.8099 23.3936 15.5496 27.2076C15.1558 31.4126 14.4965 35.5888 13.5755 39.7115C12.7158 43.0353 11.9835 44.6181 10.9646 49.3823C10.4292 51.5857 10.1462 53.8423 10.1208 56.109C10.1327 57.9146 10.3409 59.7136 10.7417 61.4746C11.283 63.9121 11.5696 65.1942 12.1427 65.1942Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M20.8511 2.06653C23.433 8.19864 22.0345 19.9788 22.0345 19.9788C20.9861 28.241 21.0857 36.6085 22.3303 44.8434C22.9355 48.5011 23.433 52.1723 24.1054 55.8032C25.0196 59.4302 25.3196 63.1848 24.9929 66.9109C24.5357 70.878 23.4465 72.2899 22.7741 72.9758C22.1017 73.6616 21.7117 73.6482 18.4843 75.6653C15.2569 77.6824 15.3107 77.9245 14.1945 78.18C12.3694 78.5469 10.4738 78.2863 8.81546 77.4404C6.86573 76.4839 5.22513 74.9971 4.0819 73.1506C2.65212 71.1319 1.64575 68.8447 1.12343 66.4268C0.485281 63.4424 0.535868 60.3519 1.27135 57.39C1.93028 54.3239 2.75059 53.3557 4.0819 49.9938C4.75428 48.286 5.22495 46.7933 7.04037 38.8861C8.38514 32.7271 9.39371 28.639 10.7385 22.0093C11.6126 17.7868 13.1859 13.6987 13.7104 9.42237C13.8718 8.07761 14.3021 4.04333 16.8168 1.58241C17.2471 1.15209 18.3095 0.143533 19.3315 0.399038C20.3535 0.654542 20.7031 1.70344 20.8511 2.06653Z" fill="#DEE1E9"/>
        <mask id="mask325" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="26" height="34">
          <path d="M1.10485 66.4289C1.62718 68.8468 2.63354 71.134 4.06333 73.1527C5.21047 74.9959 6.85002 76.4817 8.79688 77.4425C10.4562 78.2853 12.3507 78.5458 14.1759 78.1821C15.3055 77.9266 15.3728 77.5904 18.4792 75.6674C21.5856 73.7444 21.9755 73.8116 22.769 72.9778C23.5624 72.1441 24.5171 70.88 24.9878 66.913C25.3076 63.1868 25.0077 59.4334 24.1003 55.8052C23.903 54.7115 23.7148 53.6222 23.5355 52.5375L19.0709 48.8528C17.5768 47.3085 15.5766 46.3538 13.4363 46.1633C12.3401 46.086 11.2393 46.223 10.1954 46.5667L4.11712 50.0228C2.77235 53.3847 1.9655 54.3529 1.30657 57.419C0.557436 60.3682 0.488461 63.4491 1.10485 66.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask325)">
          <path d="M1.10485 66.4877C1.62718 68.9056 2.63354 71.1928 4.06333 73.2115C5.21047 75.0547 6.85002 76.5405 8.79688 77.5013C10.4562 78.3441 12.3507 78.6046 14.1759 78.2409C15.3055 77.9854 15.3728 77.6492 18.4792 75.7262C21.5856 73.8032 21.9755 73.8704 22.769 73.0367C23.5624 72.2029 24.5171 70.9389 24.9878 66.9718C25.3076 63.2456 25.0077 59.4922 24.1003 55.8641C23.903 54.7703 23.7148 53.6811 23.5355 52.5963L19.0709 48.9117C17.5768 47.3673 15.5766 46.4126 13.4363 46.2221C12.3401 46.1448 11.2393 46.2818 10.1954 46.6256L4.11712 50.0816C2.77235 53.4435 1.9655 54.4117 1.30657 57.4778C0.557436 60.427 0.488461 63.5079 1.10485 66.4877Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M12.6061 68.2012C13.5864 68.2012 15.1208 73.8023 15.1208 80.2012H13.1978H10.1208C10.1208 73.8023 11.6257 68.2012 12.6061 68.2012Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M21.0415 2.55188C23.6234 8.68399 22.2249 20.4641 22.2249 20.4641C21.1766 28.7263 21.2761 37.0938 22.5207 45.3287C23.1259 48.9865 23.6234 52.6577 24.2958 56.2885C25.21 59.9156 25.51 63.6701 25.1834 67.3963C24.7261 71.3633 23.6369 72.7753 22.9645 73.4611C22.2921 74.147 21.9021 74.1335 18.6747 76.1507C15.4473 78.1678 15.5011 78.4099 14.3849 78.6654C12.5598 79.0323 10.6643 78.7716 9.00589 77.9258C7.05616 76.9692 5.41556 75.4824 4.27233 73.636C2.84255 71.6173 1.83618 69.3301 1.31386 66.9121C0.675711 63.9278 0.726297 60.8372 1.46178 57.8754C2.12071 54.8093 2.94102 53.8411 4.27233 50.4792C4.94471 48.7713 5.41538 47.2786 7.2308 39.3714C8.57557 33.2124 9.58414 29.1244 10.9289 22.4947C11.803 18.2721 13.3764 14.1841 13.9008 9.90772C14.0622 8.56296 14.4925 4.52868 17.0072 2.06776C17.4375 1.63744 18.4999 0.628884 19.5219 0.884389C20.5439 1.13989 20.8936 2.18879 21.0415 2.55188Z" fill="#DEE1E9"/>
        <path d="M1.29528 66.9142C1.81761 69.3322 2.82397 71.6193 4.25376 73.638C5.4009 75.4812 7.04045 76.9671 8.98731 77.9278C10.6466 78.7706 12.5411 79.0311 14.3664 78.6674C15.496 78.4119 15.5632 78.0757 18.6696 76.1527C21.776 74.2297 22.166 74.297 22.9594 73.4632C23.7528 72.6294 24.7076 71.3654 25.1782 67.3983C25.4981 63.6721 25.1982 59.9187 24.2907 56.2906C24.0935 55.1969 23.9052 54.1076 23.7259 53.0228L19.2613 49.3382C17.7672 47.7938 15.767 46.8391 13.6267 46.6487C12.5305 46.5713 11.4297 46.7083 10.3859 47.0521L4.30754 50.5081C2.96278 53.87 2.15593 54.8383 1.497 57.9043C0.747866 60.8535 0.67889 63.9344 1.29528 66.9142Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M15.6238 61.0679L13.7571 63.5687L12.1238 62.073V65.3871L9.5616 64.8192L10.9571 66.5698L8.62378 68.5704L10.9571 70.5711L9.5616 72.3217L11.8904 71.5715L12.1238 75.0679L14.3737 73.5708L15.1571 74.5725L16.8739 73.5708L19.1238 75.0679L19.8238 73.072L21.686 72.3217L21.2238 69.5708L22.6238 68.5704L20.2904 67.0699L21.686 64.8192L19.8238 64.5691L19.1238 62.073L16.0904 64.0689L15.6238 61.0679Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M21.0415 2.55188C23.6234 8.68399 22.2249 20.4641 22.2249 20.4641C21.1766 28.7263 21.2761 37.0938 22.5207 45.3287C23.1259 48.9865 23.6234 52.6577 24.2958 56.2885C25.21 59.9156 25.51 63.6701 25.1834 67.3963C24.7261 71.3633 23.6369 72.7753 22.9645 73.4611C22.2921 74.147 21.9021 74.1335 18.6747 76.1507C15.4473 78.1678 15.5011 78.4099 14.3849 78.6654C12.5598 79.0323 10.6643 78.7716 9.00589 77.9258C7.05616 76.9692 5.41556 75.4824 4.27233 73.636C2.84255 71.6173 1.83618 69.3301 1.31386 66.9121C0.675711 63.9278 0.726297 60.8372 1.46178 57.8754C2.12071 54.8093 2.94102 53.8411 4.27233 50.4792C4.94471 48.7713 5.41538 47.2786 7.2308 39.3714C8.57557 33.2124 9.58414 29.1244 10.9289 22.4947C11.803 18.2721 13.3764 14.1841 13.9008 9.90772C14.0622 8.56296 14.4925 4.52868 17.0072 2.06776C17.4375 1.63744 18.4999 0.628884 19.5219 0.884389C20.5439 1.13989 20.8936 2.18879 21.0415 2.55188Z" fill="#DEE1E9"/>
        <path d="M1.29528 66.9142C1.81761 69.3322 2.82397 71.6193 4.25376 73.638C5.4009 75.4812 7.04045 76.9671 8.98731 77.9278C10.6466 78.7706 12.5411 79.0311 14.3664 78.6674C15.496 78.4119 15.5632 78.0757 18.6696 76.1527C21.776 74.2297 22.166 74.297 22.9594 73.4632C23.7528 72.6294 24.7076 71.3654 25.1782 67.3983C25.4981 63.6721 25.1982 59.9187 24.2907 56.2906C24.0935 55.1969 23.9052 54.1076 23.7259 53.0228L19.2613 49.3382C17.7672 47.7938 15.767 46.8391 13.6267 46.6487C12.5305 46.5713 11.4297 46.7083 10.3859 47.0521L4.30754 50.5081C2.96278 53.87 2.15593 54.8383 1.497 57.9043C0.747866 60.8535 0.67889 63.9344 1.29528 66.9142Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M16.6238 63.0679L15.0238 65.2115L13.6238 63.9294V66.77L11.4276 66.2833L12.6238 67.7838L10.6238 69.4987L12.6238 71.2135L11.4276 72.714L13.4238 72.071L13.6238 75.0679L15.5523 73.7847L16.2238 74.6433L17.6953 73.7847L19.6238 75.0679L20.2238 73.3571L21.8199 72.714L21.4238 70.3561L22.6238 69.4987L20.6238 68.2125L21.8199 66.2833L20.2238 66.0689L19.6238 63.9294L17.0238 65.6402L16.6238 63.0679Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M20.7754 2.61731C23.3573 8.74942 21.9588 20.5295 21.9588 20.5295C20.9105 28.7917 21.01 37.1592 22.2546 45.3942C22.8598 49.0519 23.3573 52.7231 24.0297 56.354C24.9439 59.981 25.2439 63.7355 24.9173 67.4617C24.46 71.4287 23.3708 72.8407 22.6984 73.5266C22.026 74.2124 21.636 74.1989 18.4086 76.2161C15.1812 78.2332 15.235 78.4753 14.1188 78.7308C12.2937 79.0977 10.3981 78.8371 8.73978 77.9912C6.79004 77.0346 5.14945 75.5479 4.00622 73.7014C2.57644 71.6827 1.57007 69.3955 1.04774 66.9776C0.409597 63.9932 0.460184 60.9027 1.19567 57.9408C1.8546 54.8747 2.6749 53.9065 4.00622 50.5446C4.6786 48.8368 5.14926 47.3441 6.96469 39.4369C8.30945 33.2779 9.31802 29.1898 10.6628 22.5601C11.5369 18.3376 13.1102 14.2495 13.6347 9.97315C13.7961 8.62839 14.2264 4.59411 16.7411 2.13319C17.1714 1.70287 18.2338 0.694314 19.2558 0.949819C20.2778 1.20532 20.6275 2.25422 20.7754 2.61731Z" fill="#DEE1E9"/>
        <path d="M1.02917 66.9796C1.5515 69.3976 2.55786 71.6848 3.98764 73.7034C5.13479 75.5466 6.77433 77.0325 8.7212 77.9932C10.3805 78.8361 12.275 79.0966 14.1002 78.7328C15.2298 78.4773 15.2971 78.1412 18.4035 76.2182C21.5099 74.2951 21.8999 74.3624 22.6933 73.5286C23.4867 72.6949 24.4415 71.4308 24.9121 67.4638C25.232 63.7376 24.9321 59.9841 24.0246 56.356C23.8274 55.2623 23.6391 54.173 23.4598 53.0883L18.9952 49.4036C17.5011 47.8593 15.5009 46.9045 13.3606 46.7141C12.2644 46.6368 11.1636 46.7738 10.1198 47.1175L4.04143 50.5736C2.69667 53.9355 1.88981 54.9037 1.23088 57.9697C0.481752 60.919 0.412777 63.9999 1.02917 66.9796Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M17.8577 69.1333L16.9243 70.3837L16.1077 69.6359V71.2929L14.8266 71.0089L15.5243 71.8842L14.3577 72.8846L15.5243 73.8849L14.8266 74.7602L15.991 74.3851L16.1077 76.1333L17.2326 75.3848L17.6243 75.8856L18.4827 75.3848L19.6077 76.1333L19.9577 75.1354L20.8888 74.7602L20.6577 73.3848L21.3577 72.8846L20.191 72.1343L20.8888 71.0089L19.9577 70.8839L19.6077 69.6359L18.091 70.6338L17.8577 69.1333Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
        <path d="M17.7902 2.68065L15.7064 1.41089H12.2227L10.1227 2.68065L11.7344 4.22716L9.65064 5.77367L11.539 7.32018L9.24367 9.0132L11.0669 10.5597L8.77157 11.9597L10.7902 13.376L8.77157 14.9876L10.4646 16.5341L8.23437 17.9504L9.9925 19.4155L7.69716 20.962L9.84599 22.5085L7.69716 24.1201L9.24367 25.4713L7.15995 27.0178L8.9832 28.4992L6.89948 30.0457L8.70646 31.7876L7.69716 33.4643V47.7247L9.58553 55.0503H15.7064" fill="#8889BE"/>
        <path d="M17.7902 2.68065L15.7064 1.41089H12.2227L10.1227 2.68065L11.7344 4.22716L9.65064 5.77367L11.539 7.32018L9.24367 9.0132L11.0669 10.5597L8.77157 11.9597L10.7902 13.376L8.77157 14.9876L10.4646 16.5341L8.23437 17.9504L9.9925 19.4155L7.69716 20.962L9.84599 22.5085L7.69716 24.1201L9.24367 25.4713L7.15995 27.0178L8.9832 28.4992L6.89948 30.0457L8.70646 31.7876L7.69716 33.4643V47.7247L9.58553 55.0503H15.7064" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M10.3829 2.68065L12.4666 1.41089H15.934L18.0503 2.68065L16.4224 4.22716L18.5224 5.77367L16.634 7.32018L18.9131 9.0132L17.1061 10.5597L19.3852 11.9597L17.3666 13.376L19.3852 14.9876L17.7085 16.5341L19.9224 17.9504L18.1805 19.4155L20.4596 20.962L18.3108 22.5085L20.4596 24.1201L18.9131 25.4713L20.9968 27.0178L19.1898 28.4992L21.2736 30.0457L19.4503 31.7876L20.4596 33.4643V47.7247L18.5875 55.0503H14.6806" fill="#8889BE"/>
        <path d="M10.3829 2.68065L12.4666 1.41089H15.934L18.0503 2.68065L16.4224 4.22716L18.5224 5.77367L16.634 7.32018L18.9131 9.0132L17.1061 10.5597L19.3852 11.9597L17.3666 13.376L19.3852 14.9876L17.7085 16.5341L19.9224 17.9504L18.1805 19.4155L20.4596 20.962L18.3108 22.5085L20.4596 24.1201L18.9131 25.4713L20.9968 27.0178L19.1898 28.4992L21.2736 30.0457L19.4503 31.7876L20.4596 33.4643V47.7247L18.5875 55.0503H14.6806" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M4.75083 68.8479C3.32104 66.8292 2.31468 64.542 1.79235 62.1241C1.17596 59.1443 1.24494 56.0634 1.99407 53.1142C2.653 50.0481 3.45985 49.0799 4.80462 45.718H8.38696C8.93925 45.718 9.38696 46.1657 9.38696 46.718V54.9109C14.0838 56.2887 13.9046 56.3163 18.5996 54.9109V46.718C18.5996 46.1657 19.0474 45.718 19.5996 45.718H23.4565C23.6358 46.8028 24.5905 50.4067 24.7878 51.5005C25.6952 55.1286 25.9951 58.882 25.6753 62.6082C25.2046 66.5753 24.2499 67.8393 23.4565 68.6731C22.663 69.5068 22.2731 69.4396 19.1667 71.3626C16.0603 73.2856 15.993 73.6218 14.8634 73.8773C13.0382 74.241 11.1437 73.9805 9.48438 73.1377C7.53752 72.1769 5.89797 70.6911 4.75083 68.8479Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask525" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="46" width="27" height="29">
          <path d="M4.74179 68.848C3.31201 66.8293 2.30565 64.5421 1.78332 62.1242C1.16693 59.1444 1.2359 56.0635 1.98503 53.1143C2.60038 50.251 3.34473 49.9767 4.53569 47.2149C4.69761 46.8394 5.06371 46.5889 5.47261 46.5889H8.37793C8.93021 46.5889 9.37793 47.0366 9.37793 47.5889V54.1622C9.37793 54.6061 9.673 54.9978 10.0989 55.1227C13.8441 56.2215 14.1279 56.2418 17.8747 55.1251C18.2981 54.9989 18.5906 54.6083 18.5906 54.1665V47.5889C18.5906 47.0366 19.0383 46.5889 19.5906 46.5889H22.5991C23.0885 46.5889 23.5111 46.9417 23.6367 47.4147C23.9795 48.7048 24.6211 50.6264 24.7787 51.5006C25.6862 55.1287 25.9861 58.8821 25.6663 62.6083C25.1956 66.5753 24.2408 67.8394 23.4474 68.6732C22.654 69.5069 22.264 69.4397 19.1576 71.3627C16.0512 73.2857 15.984 73.6219 14.8544 73.8774C13.0292 74.2411 11.1347 73.9806 9.47535 73.1378C7.52848 72.177 5.88894 70.6912 4.74179 68.848Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask525)">
          <path d="M22.124 32.0271H5.98971V57.5751C12.2905 59.5013 15.8232 59.5209 22.124 57.5751V32.0271Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
        <path d="M17.602 2.5559L15.5182 1.28613H12.0345L9.93453 2.5559L11.5462 4.10241L9.46244 5.64891L11.3508 7.19542L9.05546 8.88844L10.8787 10.435L8.58337 11.8349L10.602 13.2512L8.58337 14.8628L10.2764 16.4094L8.04616 17.8256L9.8043 19.2907L7.50896 20.8373L9.65779 22.3838L7.50896 23.9954L9.05546 25.3465L6.97175 26.8931L8.795 28.3744L6.71128 29.921L8.51826 31.6628L7.50896 33.3396V47.6L9.39732 54.9256H15.5182" fill="#8889BE"/>
        <path d="M17.602 2.5559L15.5182 1.28613H12.0345L9.93453 2.5559L11.5462 4.10241L9.46244 5.64891L11.3508 7.19542L9.05546 8.88844L10.8787 10.435L8.58337 11.8349L10.602 13.2512L8.58337 14.8628L10.2764 16.4094L8.04616 17.8256L9.8043 19.2907L7.50896 20.8373L9.65779 22.3838L7.50896 23.9954L9.05546 25.3465L6.97175 26.8931L8.795 28.3744L6.71128 29.921L8.51826 31.6628L7.50896 33.3396V47.6L9.39732 54.9256H15.5182" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M10.1947 2.5559L12.2784 1.28613H15.7458L17.8621 2.5559L16.2342 4.10241L18.3342 5.64891L16.4458 7.19542L18.7249 8.88844L16.9179 10.435L19.197 11.8349L17.1784 13.2512L19.197 14.8628L17.5202 16.4094L19.7342 17.8256L17.9923 19.2907L20.2714 20.8373L18.1226 22.3838L20.2714 23.9954L18.7249 25.3465L20.8086 26.8931L19.0016 28.3744L21.0854 29.921L19.2621 31.6628L20.2714 33.3396V47.6L18.3993 54.9256H14.4923" fill="#8889BE"/>
        <path d="M10.1947 2.5559L12.2784 1.28613H15.7458L17.8621 2.5559L16.2342 4.10241L18.3342 5.64891L16.4458 7.19542L18.7249 8.88844L16.9179 10.435L19.197 11.8349L17.1784 13.2512L19.197 14.8628L17.5202 16.4094L19.7342 17.8256L17.9923 19.2907L20.2714 20.8373L18.1226 22.3838L20.2714 23.9954L18.7249 25.3465L20.8086 26.8931L19.0016 28.3744L21.0854 29.921L19.2621 31.6628L20.2714 33.3396V47.6L18.3993 54.9256H14.4923" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M4.46198 65.2876C3.0322 63.2689 2.02583 60.9817 1.5035 58.5638C0.887114 55.584 0.956089 52.5031 1.70522 49.5539C2.36415 46.4878 3.17101 45.5196 4.51577 42.1577H8.09811C8.6504 42.1577 9.09811 42.6054 9.09811 43.1577V51.3506C13.7949 52.7284 13.6157 52.756 18.3108 51.3506V43.1577C18.3108 42.6054 18.7585 42.1577 19.3108 42.1577H23.1676C23.3469 43.2425 24.3017 46.8464 24.4989 47.9402C25.4064 51.5683 25.7063 55.3217 25.3865 59.0479C24.9158 63.015 23.961 64.279 23.1676 65.1128C22.3742 65.9465 21.9842 65.8793 18.8778 67.8023C15.7714 69.7253 15.7042 70.0615 14.5746 70.317C12.7494 70.6807 10.8549 70.4202 9.19553 69.5774C7.24867 68.6166 5.60913 67.1308 4.46198 65.2876Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M43.8093 76.771H22.8093V81.771H43.8093V76.771Z" fill="#838AA2"/>
        <path d="M21.8093 76.771H0.809326V81.771H21.8093V76.771Z" fill="#838AA2"/>
        <path d="M29.5395 2.25525C32.1215 8.38736 30.7229 20.1675 30.7229 20.1675C29.6746 28.4297 29.7742 36.7972 31.0188 45.0321C31.6239 48.6898 32.1215 52.3611 32.7939 55.9919C33.708 59.6189 34.008 63.3735 33.6814 67.0996C33.2242 71.0667 32.1349 72.4787 31.4626 73.1645C30.7902 73.8503 30.4002 73.8369 27.1728 75.854C23.9453 77.8712 23.9991 78.1132 22.883 78.3687C21.0579 78.7356 19.1623 78.475 17.5039 77.6291C15.5542 76.6726 13.9136 75.1858 12.7704 73.3393C11.3406 71.3207 10.3342 69.0335 9.8119 66.6155C9.17376 63.6312 9.22434 60.5406 9.95983 57.5787C10.6188 54.5127 11.4391 53.5444 12.7704 50.1825C13.4428 48.4747 13.9134 46.982 15.7289 39.0748C17.0736 32.9158 18.0822 28.8277 19.4269 22.1981C20.301 17.9755 21.8744 13.8874 22.3989 9.61109C22.5602 8.26633 22.9906 4.23205 25.5053 1.77113C25.9356 1.34081 26.9979 0.332254 28.02 0.587758C29.042 0.843263 29.3916 1.89216 29.5395 2.25525Z" fill="#DEE1E9"/>
        <path d="M12.7518 73.3413C11.322 71.3227 10.3157 69.0355 9.79333 66.6175C9.17694 63.6377 9.24591 60.5568 9.99504 57.6076C10.654 54.5416 11.4608 53.5733 12.8056 50.2114H16.3879C16.9402 50.2114 17.3879 50.6591 17.3879 51.2114V59.4043C22.0848 60.7821 21.9055 60.8097 26.6006 59.4043V51.2114C26.6006 50.6591 27.0483 50.2114 27.6006 50.2114H31.4574C31.6367 51.2962 32.5915 54.9002 32.7887 55.9939C33.6962 59.622 33.9961 63.3754 33.6763 67.1016C33.2056 71.0687 32.2508 72.3328 31.4574 73.1665C30.664 74.0003 30.274 73.933 27.1676 75.856C24.0612 77.779 23.994 78.1152 22.8644 78.3707C21.0392 78.7344 19.1447 78.4739 17.4854 77.6311C15.5385 76.6704 13.899 75.1845 12.7518 73.3413Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M21.1714 2.16882C23.7533 8.30093 22.3548 20.0811 22.3548 20.0811C21.3065 28.3433 21.406 36.7108 22.6506 44.9457C23.2558 48.6034 23.7533 52.2746 24.4257 55.9055C25.3399 59.5325 25.6399 63.287 25.3132 67.0132C24.856 70.9803 23.7668 72.3922 23.0944 73.0781C22.422 73.7639 22.032 73.7505 18.8046 75.7676C15.5772 77.7847 15.631 78.0268 14.5148 78.2823C12.6897 78.6492 10.7941 78.3886 9.13577 77.5427C7.18604 76.5862 5.54544 75.0994 4.40221 73.2529C2.97243 71.2342 1.96607 68.947 1.44374 66.5291C0.805593 63.5447 0.85618 60.4542 1.59166 57.4923C2.25059 54.4262 3.0709 53.458 4.40221 50.0961C5.07459 48.3883 5.54526 46.8956 7.36069 38.9884C8.70545 32.8294 9.71402 28.7413 11.0588 22.1116C11.9329 17.8891 13.5062 13.801 14.0307 9.52467C14.1921 8.17991 14.6224 4.14562 17.1371 1.68471C17.5674 1.25438 18.6298 0.245828 19.6518 0.501332C20.6738 0.756837 21.0235 1.80574 21.1714 2.16882Z" fill="#DEE1E9"/>
        <path d="M1.42517 66.5312C1.94749 68.9491 2.95386 71.2363 4.38364 73.255C5.53079 75.0982 7.17033 76.584 9.1172 77.5448C10.7765 78.3876 12.671 78.6481 14.4962 78.2844C15.6258 78.0289 15.6931 77.6927 18.7995 75.7697C21.9059 73.8467 22.2959 73.9139 23.0893 73.0801C23.8827 72.2464 24.8375 70.9823 25.3081 67.0153C25.628 63.2891 25.3281 59.5357 24.4206 55.9075C24.2233 54.8138 24.0351 53.7245 23.8558 52.6398L19.3912 48.9551C17.8971 47.4108 15.8969 46.456 13.7566 46.2656C12.6604 46.1883 11.5596 46.3253 10.5157 46.669L4.43743 50.1251C3.09267 53.487 2.28581 54.4552 1.62688 57.5213C0.877748 60.4705 0.808773 63.5514 1.42517 66.5312Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M13.2481 55.397C14.3239 55.5853 16.3007 51.1207 17.2824 46.8309L18.2238 34.486C18.3986 30.5593 18.5062 27.3588 18.5734 25.1533C18.7213 20.1508 18.6675 19.5323 18.8558 17.4075C19.3265 11.9478 19.1384 1.99866 18.856 1.87763C18.5736 1.7566 18.5734 11.0468 17.7262 13.5346C16.8818 16.6709 16.3458 19.8822 16.1259 23.1228C15.7933 26.6954 15.2364 30.2436 14.4584 33.7464C13.7322 36.5704 13.1137 37.9151 12.253 41.9629C11.8008 43.835 11.5617 45.7523 11.5403 47.6781C11.5503 49.2121 11.7262 50.7406 12.0647 52.2368C12.522 54.3078 12.764 55.397 13.2481 55.397Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M21.1714 2.16882C23.7533 8.30093 22.3548 20.0811 22.3548 20.0811C21.3065 28.3433 21.406 36.7108 22.6506 44.9457C23.2558 48.6034 23.7533 52.2746 24.4257 55.9055C25.3399 59.5325 25.6399 63.287 25.3132 67.0132C24.856 70.9803 23.7668 72.3922 23.0944 73.0781C22.422 73.7639 22.032 73.7505 18.8046 75.7676C15.5772 77.7847 15.631 78.0268 14.5148 78.2823C12.6897 78.6492 10.7941 78.3886 9.13577 77.5427C7.18604 76.5862 5.54544 75.0994 4.40221 73.2529C2.97243 71.2342 1.96607 68.947 1.44374 66.5291C0.805593 63.5447 0.85618 60.4542 1.59166 57.4923C2.25059 54.4262 3.0709 53.458 4.40221 50.0961C5.07459 48.3883 5.54526 46.8956 7.36069 38.9884C8.70545 32.8294 9.71402 28.7413 11.0588 22.1116C11.9329 17.8891 13.5062 13.801 14.0307 9.52467C14.1921 8.17991 14.6224 4.14562 17.1371 1.68471C17.5674 1.25438 18.6298 0.245828 19.6518 0.501332C20.6738 0.756837 21.0235 1.80574 21.1714 2.16882Z" fill="#DEE1E9"/>
        <path d="M1.42517 66.5312C1.94749 68.9491 2.95386 71.2363 4.38364 73.255C5.53079 75.0982 7.17033 76.584 9.1172 77.5448C10.7765 78.3876 12.671 78.6481 14.4962 78.2844C15.6258 78.0289 15.6931 77.6927 18.7995 75.7697C21.9059 73.8467 22.2959 73.9139 23.0893 73.0801C23.8827 72.2464 24.8375 70.9823 25.3081 67.0153C25.628 63.2891 25.3281 59.5357 24.4206 55.9075C24.2233 54.8138 24.0351 53.7245 23.8558 52.6398L19.3912 48.9551C17.8971 47.4108 15.8969 46.456 13.7566 46.2656C12.6604 46.1883 11.5596 46.3253 10.5157 46.669L4.43743 50.1251C3.09267 53.487 2.28581 54.4552 1.62688 57.5213C0.877748 60.4705 0.808773 63.5514 1.42517 66.5312Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.4" d="M13.2481 55.397C14.3239 55.5853 16.3007 51.1207 17.2824 46.8309L18.2238 34.486C18.3986 30.5593 18.5062 27.3588 18.5734 25.1533C18.7213 20.1508 18.6675 19.5323 18.8558 17.4075C19.3265 11.9478 19.1384 1.99866 18.856 1.87763C18.5736 1.7566 18.5734 11.0468 17.7262 13.5346C16.8818 16.6709 16.3458 19.8822 16.1259 23.1228C15.7933 26.6954 15.2364 30.2436 14.4584 33.7464C13.7322 36.5704 13.1137 37.9151 12.253 41.9629C11.8008 43.835 11.5617 45.7523 11.5403 47.6781C11.5503 49.2121 11.7262 50.7406 12.0647 52.2368C12.522 54.3078 12.764 55.397 13.2481 55.397Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M21.4897 1.86609C24.0717 7.9982 22.6731 19.7783 22.6731 19.7783C21.6248 28.0405 21.7244 36.408 22.969 44.6429C23.5741 48.3007 24.0717 51.9719 24.7441 55.6028C25.6582 59.2298 25.9582 62.9843 25.6316 66.7105C25.1744 70.6775 24.0851 72.0895 23.4128 72.7753C22.7404 73.4612 22.3504 73.4477 19.123 75.4649C15.8955 77.482 15.9493 77.7241 14.8332 77.9796C13.0081 78.3465 11.1125 78.0858 9.45413 77.24C7.5044 76.2834 5.8638 74.7966 4.72057 72.9502C3.29079 70.9315 2.28443 68.6443 1.7621 66.2264C1.12395 63.242 1.17454 60.1514 1.91002 57.1896C2.56895 54.1235 3.38926 53.1553 4.72057 49.7934C5.39295 48.0855 5.86362 46.5928 7.67905 38.6857C9.02381 32.5266 10.0324 28.4386 11.3771 21.8089C12.2512 17.5863 13.8246 13.4983 14.3491 9.22193C14.5104 7.87717 14.9408 3.84289 17.4555 1.38197C17.8858 0.95165 18.9481 -0.0569065 19.9702 0.198598C20.9922 0.454103 21.3418 1.503 21.4897 1.86609Z" fill="#DEE1E9"/>
        <path d="M1.74352 66.2284C2.26585 68.6464 3.27222 70.9336 4.702 72.9522C5.84915 74.7954 7.48869 76.2813 9.43555 77.242C11.0949 78.0849 12.9894 78.3453 14.8146 77.9816C15.9442 77.7261 16.0114 77.3899 19.1178 75.4669C22.2242 73.5439 22.6142 73.6112 23.4076 72.7774C24.201 71.9437 25.1558 70.6796 25.6265 66.7125C25.9463 62.9863 25.6464 59.2329 24.7389 55.6048C24.5417 54.5111 24.3534 53.4218 24.1741 52.337L19.7095 48.6524C18.2155 47.1081 16.2153 46.1533 14.075 45.9629C12.9787 45.8855 11.8779 46.0226 10.8341 46.3663L4.75579 49.8223C3.41103 53.1842 2.60417 54.1525 1.94524 57.2185C1.19611 60.1677 1.12713 63.2486 1.74352 66.2284Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask525" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="20" width="15" height="38">
          <path d="M21.4533 20.9844L14.6906 20.9844L7.29079 55.147L19.9658 57.4182L21.4533 20.9844Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask525)">
          <path opacity="0.2" d="M13.5665 55.0943C14.6423 55.2826 16.6191 50.818 17.6008 46.5282L18.5421 34.1833C18.7169 30.2566 18.8245 27.056 18.8917 24.8506C19.0397 19.8481 18.9859 19.2295 19.1741 17.1048C19.6448 11.6451 19.4568 1.69592 19.1744 1.57489C18.892 1.45386 18.8917 10.7441 18.0445 13.2319C17.2001 16.3682 16.6642 19.5795 16.4443 22.82C16.1117 26.3927 15.5547 29.9409 14.7768 33.4436C14.0506 36.2676 13.432 37.6124 12.5714 41.6601C12.1192 43.5323 11.8801 45.4495 11.8586 47.3754C11.8687 48.9094 12.0445 50.4379 12.3831 51.9341C12.8403 54.005 13.0824 55.0943 13.5665 55.0943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5025" mask-type="alpha" maskUnits="userSpaceOnUse" x="13" y="0" width="9" height="21">
          <path d="M20.82 1.84046L20.1201 0.358398H18.7203L17.7322 1.30515L13.2034 20.9191H21.9728L21.6023 12.2154L21.1494 4.51657L20.82 1.84046Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5025)">
          <path opacity="0.4" d="M13.5665 55.0943C14.6423 55.2826 16.6191 50.818 17.6008 46.5282L18.5421 34.1833C18.7169 30.2566 18.8245 27.056 18.8917 24.8506C19.0397 19.8481 18.9859 19.2295 19.1741 17.1048C19.6448 11.6451 19.4568 1.69592 19.1744 1.57489C18.892 1.45386 18.8917 10.7441 18.0445 13.2319C17.2001 16.3682 16.6642 19.5795 16.4443 22.82C16.1117 26.3927 15.5547 29.9409 14.7768 33.4436C14.0506 36.2676 13.432 37.6124 12.5714 41.6601C12.1192 43.5323 11.8801 45.4495 11.8586 47.3754C11.8687 48.9094 12.0445 50.4379 12.3831 51.9341C12.8403 54.005 13.0824 55.0943 13.5665 55.0943Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

<g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M20.1714 2.25031C22.7533 8.38242 21.3548 20.1625 21.3548 20.1625C20.3065 28.4247 20.406 36.7922 21.6506 45.0272C22.2558 48.6849 22.7533 52.3561 23.4257 55.987C24.3399 59.614 24.6399 63.3685 24.3132 67.0947C23.856 71.0617 22.7668 72.4737 22.0944 73.1596C21.422 73.8454 21.032 73.8319 17.8046 75.8491C14.5772 77.8662 14.631 78.1083 13.5148 78.3638C11.6897 78.7307 9.79414 78.4701 8.13577 77.6242C6.18604 76.6676 4.54544 75.1808 3.40221 73.3344C1.97243 71.3157 0.966066 69.0285 0.443739 66.6106C-0.194407 63.6262 -0.14382 60.5357 0.591661 57.5738C1.25059 54.5077 2.0709 53.5395 3.40221 50.1776C4.07459 48.4697 4.54526 46.9771 6.36069 39.0699C7.70545 32.9109 8.71402 28.8228 10.0588 22.1931C10.9329 17.9706 12.5062 13.8825 13.0307 9.60615C13.1921 8.26139 13.6224 4.2271 16.1371 1.76619C16.5674 1.33587 17.6298 0.32731 18.6518 0.582814C19.6738 0.838319 20.0235 1.88722 20.1714 2.25031Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
        <path d="M21.1714 2.22388C23.7533 8.35599 22.3548 20.1361 22.3548 20.1361C21.3065 28.3983 21.406 36.7658 22.6506 45.0007C23.2558 48.6585 23.7533 52.3297 24.4257 55.9605C25.3399 59.5876 25.6399 63.3421 25.3132 67.0683C24.856 71.0353 23.7668 72.4473 23.0944 73.1331C22.422 73.819 22.032 73.8055 18.8046 75.8226C15.5772 77.8398 15.631 78.0819 14.5148 78.3374C12.6897 78.7043 10.7941 78.4436 9.13577 77.5977C7.18604 76.6412 5.54544 75.1544 4.40221 73.308C2.97243 71.2893 1.96607 69.0021 1.44374 66.5841C0.805593 63.5998 0.85618 60.5092 1.59166 57.5474C2.25059 54.4813 3.0709 53.5131 4.40221 50.1512C5.07459 48.4433 5.54526 46.9506 7.36069 39.0434C8.70545 32.8844 9.71402 28.7964 11.0588 22.1667C11.9329 17.9441 13.5062 13.8561 14.0307 9.57972C14.1921 8.23496 14.6224 4.20068 17.1371 1.73976C17.5674 1.30944 18.6298 0.300882 19.6518 0.556386C20.6738 0.811891 21.0235 1.86079 21.1714 2.22388Z" fill="#DEE1E9"/>
        <path d="M1.42517 66.5861C1.94749 69.0041 2.95386 71.2913 4.38364 73.3099C5.53079 75.1531 7.17033 76.639 9.1172 77.5997C10.7765 78.4426 12.671 78.703 14.4962 78.3393C15.6258 78.0838 15.6931 77.7476 18.7995 75.8246C21.9059 73.9016 22.2959 73.9689 23.0893 73.1351C23.8827 72.3014 24.8375 71.0373 25.3081 67.0702C25.628 63.344 25.3281 59.5906 24.4206 55.9625C24.2233 54.8688 24.0351 53.7795 23.8558 52.6947L19.3912 49.0101C17.8971 47.4657 15.8969 46.511 13.7566 46.3206C12.6604 46.2432 11.5596 46.3803 10.5157 46.724L4.43743 50.18C3.09267 53.5419 2.28581 54.5102 1.62688 57.5762C0.877748 60.5254 0.808773 63.6063 1.42517 66.5861Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
name: "Tooth25",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
