<template>
  <div class="pb-4">
    <button @click="editPatient"
            v-if="rights.includes('patients_update')"
            class="btn btn-themed btn-themed-outline mr-3">
      {{ $t('edit') }}
    </button>
    <button v-if="rights.includes('combine_patients')"
            @click="unifyCard"
            class="btn btn-themed btn-themed-outline">
      {{ $t('unify_card') }}
    </button>
    <b-row class="row-narrow" v-if="patient">
      <b-col md="3" cols="6" v-if="clinic.country_id == countries.KAZAKHSTAN">
        <PatientCol label="iin" :title="patient.iin"/>
      </b-col>
      <b-col md="3" cols="6" v-if="clinic.country_id == countries.RUSSIA">
        <PatientCol label="snils" :title="patient.snils"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="creation_date" :title="patient.date_of_registration"/>
      </b-col>
      <b-col md="3" cols="6" v-if="clinic.country_id == countries.RUSSIA">
        <PatientCol label="inn" :title="patient.taxcode"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="patient_source" :title="patient.acquisition_source ? patient.acquisition_source.name : ''"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="added_by" :title="patient.author ? patient.author.full_name : ''"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="passport" :title="patient.passport"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="series" :title="patient.passport_series"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="issued_by" :title="patient.passport_given_by"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="issue_date" :title="patient.passport_issue_date"/>
      </b-col>
      <b-col md="3" cols="6" v-if="clinic.country_id == countries.RUSSIA">
        <PatientCol label="department_code" :title="patient.passport_dep_code"/>
      </b-col>
      <b-col md="3" cols="6">
        <PatientCol label="address" :title="patient.address"/>
      </b-col>
      <b-col md="3" cols="6" v-if="clinic.country_id == countries.RUSSIA">
        <PatientCol label="pesel" :title="patient.pesel"/>
      </b-col>
      <b-col md="3" cols="6" v-if="patient.representative_fio">
        <PatientCol label="representative" :title="patient.representative_fio"/>
      </b-col>
      <b-col md="3" cols="6" v-if="patient.representative_phone">
        <PatientCol label="representative_phone" :title="patient.representative_phone"/>
      </b-col>
      <b-col md="3" cols="6" v-if="patient.representative_address">
        <PatientCol label="representative_address" :title="patient.representative_address"/>
      </b-col>
      <b-col md="3" cols="6" v-if="patient.representative_passport">
        <PatientCol label="representative_passport" :title="patient.representative_passport"/>
      </b-col>
    </b-row>
    <!--    <DataTable :options="options"/>-->

    <b-modal id="unify-card-modal"
             :title="$t('choose_patient')"
             v-if="patient"
             centered
             hide-footer>
      <ServiceMultiSelect :label="$t('patient')"
                          :dblclick="false"
                          model="patient"
                          :ignore-ids="[patient.id]"
                          :multiple="false"
                          title="full_name"
                          :dbsearch="true"
                          v-model="combineUser"/>
      <div class="mb-5">{{ $t('combine_user_note') }}</div>
      <div class="text-right">
        <button :disabled="!combineUser || loading"
                @click="unifyUser"
                class="btn-themed btn-themed-squared">
          {{ $t('unify') }}
        </button>
      </div>
    </b-modal>


  </div>
</template>

<script>

import PatientCol from "@/components/parts/patients/PatientCol"
// import Autocomplete from "@/components/parts/general/Autocomplete"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { mapState } from "vuex"
import { PatientService } from "@/services/api.service"
import { countries } from "@/dictionaries/dictionary"

export default {
  name: "PatientPersonal",
  components: {
    PatientCol,
    // Autocomplete,
    ServiceMultiSelect
  },
  head() {
    return {
      title: this.$t("personal_data"),
    }
  },
  // components: { DataTable },
  data() {
    return {
      combineUser: null
    }
  },
  computed: {
    countries() {
      return countries
    },
    ...mapState({
      patient: state => state.patient.patient,
      rights: state => state.auth.rights,
      loading: state => state.dom.loading,
      clinic: state => state.auth.clinic,
    })
  },
  mounted() {
    this.$store.commit('setEloquentOptions', null)
  },
  methods: {
    editPatient() {
      this.$store.commit("setViewModel", 'patient')
      this.$store.commit('setSelectedModel', JSON.parse(JSON.stringify(this.patient)))
      this.$store.commit('setShowModal', true)
    },
    unifyCard() {
      this.$bvModal.show('unify-card-modal')
    },
    unifyUser() {
      if(!this.combineUser) return
      PatientService.unifyCard(this.patient.id, this.combineUser.id).then(res => {
        if(res.data) {
          this.$noty.info(this.$t('success_message'))
          this.$bvModal.hide('unify-card-modal')
          this.$store.commit('incrementDataTableCounter')
        } else {
          this.$noty.error(this.$t('error_message'))
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.btn-themed-outline {
  border-radius: 5px;
  margin-bottom: 20px;
}
</style>
