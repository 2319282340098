<template>
  <div class="clinic-marketing-data page pb-5 pt-4" style="max-width: 450px;">
    <p class="pb-3">{{ $t('show_preinstalled_templates') }}:</p>
    <div v-for="o in options" :key="`add-opt-${o.id}`">
      <CheckboxGroup :label="$t(o.title)"
                     :checkboxvalue="1"
                     :fid="o.id"
                     v-model="form[o.id]"/>
    </div>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { EloquentService } from "@/services/api.service"

export default {
  name: "SettingsTemplates",
  head() {
    return {
      title: this.$t('templates'),
    }
  },
  components: {
    CheckboxGroup,
  },
  data() {
    return {
      form: {},
      options: [
        { id: 'standard_categories', title: 'templates_categories' },
        { id: 'treatment_templates', title: 'templates_treatments' },
        { id: 'text_templates', title: 'templates_text' },
        { id: 'contract_templates', title: 'templates_contracts' },
        { id: 'agreement_templates', title: 'templates_agreements' },
      ]
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
    }),
    additional() {
      return this.clinic.clinic_additional
    },
  },
  watch: {

  },
  methods: {
    async save() {
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      await EloquentService.update('clinic_additional', this.clinic.id, formData)
      this.$noty.info(this.$t('success_message'))
      await this.$store.dispatch('init')
    },
  },
  mounted() {
    this.form = this.copyObject(this.additional)
  }
}
</script>
