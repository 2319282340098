const TOKEN_KEY = "interes_lang"
import moment from 'moment-timezone'

export const getLanguage = () => {
  let lang = getLowcaseLanguage()
  window.lang = lang.charAt(0).toUpperCase() + lang.slice(1)
  return lang
}

export const getLowcaseLanguage = () => {
  var lang = window.localStorage.getItem(TOKEN_KEY)
  if(!lang) {
    lang = process.env.VUE_APP_DEFAULT_LANG
  }
  return lang
}


export const saveLanguage = (lang) => {
  window.localStorage.setItem(TOKEN_KEY, lang)
  window.lang = lang.charAt(0).toUpperCase() + lang.slice(1)
}


export const interfaceLangs = () => {
  return [
      { id: 'en', title: "english" },
      { id: 'ru', title: "russian" },
      { id: 'uz', title: "uzbek" },
      { id: 'uk', title: "ukrainian" },
      { id: 'az', title: "azerbaijani" },
  ]
}

export const getInterfaceLangFull = () => {
  const lang = getLanguage()
  return interfaceLangs().find(item => item.id === lang)
}

export const timezonesList = () => {
  let names = moment.tz.names()
  var timezones = []
  names.forEach(item => {
    let offset_num = moment.tz(item)._offset / 60
    let offset_str = ""
    if (offset_num > 0) {
      offset_str = "+"+offset_num
    } else {
      offset_str = ""+offset_num
    }
    timezones.push({
      id: item,
      offset: offset_num,
      title: "(UTC "+ offset_str + ") " + item
    })
    timezones.sort((a, b) => (a.offset > b.offset) ? 1 : -1)
  })
  return timezones
}

export default { getLanguage, saveLanguage, getLowcaseLanguage, timezonesList, interfaceLangs }
