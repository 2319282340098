<template>
  <InputGroup :label="$t('mkb_code')"
              v-if="object.template_type === 'diagnosis'"
              :show-errors="true"
              validation-id="mkb_code"
              v-model="object.mkb_code"/>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"

export default {
  name: "GeneralTemplateMKBInput",
  components: {
    InputGroup,
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
  },
  mounted() {
    if(this.object.id === 'id') {
      delete this.object.id
    }
  }
}
</script>
