import { clientNoteAdapter } from '@/adapters/patients/clientNote'

export default {
    primaryKey: "id",
    model: 'client_note',
    modaltitle: 'note',
    order: 'id',
    sort: 'desc',
    adapter: clientNoteAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'name',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'description',
            title: 'description',
            table: true,
            form: {
                type: "textarea",
                required: true
            }
        },
        {
            id: 'type',
            title: 'importance',
            table: true,
            form: {
                type: "checkbox",
            },
            tdcomponent: "PatientsDatatableTDImportance"
        },
        {
            id: 'date_add',
            title: 'created',
            table: true,
            sortable: true,
            format_datetime: true
        },
    ]

}
