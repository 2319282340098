<template>
  <div class="mt-4 patient-notifications">

    <b-row>
      <b-col lg="12" class="mb-5">
        <div v-if="!notifications || !notifications.length && loaded">
          {{ $t('no_data_available') }}
        </div>
        <DataTable :options="options"
                   :params="{ recipient_id: patient.id }"
                   module="patients"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { mapState } from "vuex"
import DataTable from "@/components/parts/general/DataTable.vue"
import options from "../../../data/patients/notification"

export default {
  name: "PatientNotifications",
  components: {
    DataTable
  },
  head() {
    return {
      title: this.$t("notifications"),
    }
  },
  data() {
    return {
      options: options,
      checked: [],
      notifications: [],
      loaded: false,
      paginator: null,
      page: 1,
      keyCounter: 1,
      dataTableWatcher: true
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      loading: state => state.dom.loading,
      counter: state => state.datatable.dataTableCounter,
    }),
  },
}
</script>>
