<template>
  <b-modal id="patient-topay-modal"
           size="lg"
           @show="onShow"
           @hide="onHide"
           v-if="patient"
           :title="$t('payment')"
           centered
           hide-footer>
    <b-row>
      <b-col cols="auto">
        <label class="form-label">{{ $t('balance') }}</label>
        <div class="emphasized-text">{{ formatCurrency(advanceForBranch) }}</div>
      </b-col>
      <b-col cols="auto">
        <div class="ml-md-4">
          <label class="form-label">{{ $t('debt') }}</label>
          <div class="emphasized-text text-danger">{{ formatCurrency(debtForBranch) }}</div>
        </div>
      </b-col>
    </b-row>
<!--    <hr/>-->
    <div class="data-table-wrapper pt-md-3">
      <table class="table data-table">
        <thead>
        <tr>
          <th>{{ $t('date') }}</th>
          <th>{{ $t('services') }}</th>
          <th>{{ $t('sum') }}</th>
          <th>{{ $t('paid') }}</th>
          <th>{{ $t('left_due') }}</th>
          <th v-if="rights.includes('accept_payments')">{{ $t('actions') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(visit, vind) in visits" :key="`unpaid-visit-${vind}`">
          <td :data-title="$t('date')">{{ visit.readable_start }}</td>
          <td class="order-mob-0" :data-title="$t('services')">{{ oneLinerFromProperty(visit.services, 'name') }}</td>
          <td :data-title="$t('sum')">{{ formatCurrency(visit.price) }}</td>
          <td :data-title="$t('paid')">{{ formatCurrency(visit.paid_sum) }}</td>
          <td :class="{'text-danger': visit.price - visit.paid_sum > 0}" :data-title="$t('left_due')">{{ formatCurrency(visit.price - visit.paid_sum) }}</td>
          <td :data-title="$t('actions')" v-if="rights.includes('accept_payments')">
            <a @click="payPressed(visit)" class="pointer">
              <DollarSvg class="svg-light"/>
            </a>
          </td>
          <MobileMoreArrow/>
        </tr>
        </tbody>
      </table>
    </div>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import { PatientService } from "@/services/api.service"
import DollarSvg from '@/assets/svg-vue/patients/dollar.svg'
import MobileMoreArrow from "@/components/parts/datatable/MobileMoreArrow"
import { formatCurrency, oneLinerFromProperty } from "@/extensions/filters/filters"

export default {
  name: "PatientToPayModal",
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      rights: state => state.auth.rights,
      branchId:  state => state.auth.branch,
    }),
    debtForBranch() {
      if(!this.patient) return 0
      if(this.patient.debt_calculated_branched) {
        return this.patient.debt_calculated_branched[this.branchId]
      }
      return this.patient.debt_calculated
    },
    advanceForBranch() {
      if(!this.patient) return 0
      if(this.patient.advance_calculated_branched) {
        return this.patient.advance_calculated_branched[this.branchId]
      }
      return this.patient.advance_calculated
    },
  },
  components: {
    MobileMoreArrow,
    DollarSvg
  },
  data() {
    return {
      visits: []
    }
  },
  // created() {
    // this.$root.$on('bv::modal::shown', (bvEvent, modalId) => {
    //   if (modalId === 'patient-topay-modal') {
    //
    //   }
    // })
    // this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
    //   if (modalId === 'patient-topay-modal') {
    //
    //   }
    // })
  // },
  methods: {
    formatCurrency,
    oneLinerFromProperty,
    payPressed(visit) {
      this.$store.commit('setVisit', this.copyObject(visit))
      this.$bvModal.hide('patient-topay-modal')
      this.$nextTick(() => {
        this.$bvModal.show('visit-pay-modal')
      })
    },
    onShow() {
      if(!this.patient) {
        this.$bvModal.hide('patient-topay-modal')
        return
      }
      PatientService.getUnpaidServices(this.patient.id).then(res => {
        this.visits = res.data
      })
    },
    onHide() {
      this.visits = []
    }
  }
}
</script>
