<template>
  <g>
    <!--   SAME AS 41  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
        <path d="M7.00977 77.7742L9.09348 79.0439H12.5772L14.6772 77.7742L13.0656 76.2277L15.1493 74.6812L13.2609 73.1347L15.5563 71.4416L13.733 69.8951L16.0284 68.4951L14.0098 67.0789L16.0284 65.4672L14.3353 63.9207L16.5656 62.5044L14.8074 61.0393L17.1028 59.4928L14.9539 57.9463L17.1028 56.3347L15.5563 54.9835L17.64 53.437L15.8167 51.9556L17.9004 50.4091L16.0935 48.6673L17.1028 46.9905V32.7301L15.2144 25.4045H9.09348" fill="#8889BE"/>
        <path d="M14.417 77.7742L12.3333 79.0439H8.86589L6.74962 77.7742L8.37752 76.2277L6.27752 74.6812L8.16589 73.1347L5.88683 71.4416L7.6938 69.8951L5.41474 68.4951L7.43334 67.0789L5.41474 65.4672L7.09148 63.9207L4.87753 62.5044L6.61938 61.0393L4.34032 59.4928L6.48915 57.9463L4.34032 56.3347L5.88683 54.9835L3.80311 53.437L5.61008 51.9556L3.52637 50.4091L5.34962 48.6673L4.34032 46.9905V32.7301L6.21241 25.4045H10.1194" fill="#8889BE"/>
        <path d="M0.554688 1.7103V10.2659L1.95494 26.4388C2.13713 28.886 2.91596 31.2514 4.22336 33.3281C5.02239 34.516 6.1117 35.4799 7.38794 36.1286C12.4709 38.6771 15.0193 34.6303 15.0193 34.6303C15.9711 33.4581 16.8147 32.2021 17.5398 30.8776C18.5253 29.1544 19.3055 27.3215 19.8642 25.4166C21.4885 19.8156 20.4523 12.4643 20.7884 4.03473C20.8779 3.31872 20.6876 2.59578 20.2572 2.01664C19.8267 1.4375 19.1894 1.04686 18.478 0.92616C18.478 0.92616 0.554688 0.772132 0.554688 1.7103Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
        <path d="M4.22314 33.897L5.30134 49.9019C5.30134 49.9019 6.46355 59.5077 8.10185 75.2325C9.74015 90.9574 11.9246 84.4742 11.9246 84.4742L14.347 72.6841L16.2233 51.5262L17.4836 31.4745" fill="#DEE1E9"/>
        <path d="M0.554688 2.27915V10.8347L1.95494 27.0077C2.13713 29.4549 2.91596 31.8202 4.22336 33.8969C5.02239 35.0848 6.1117 36.0488 7.38794 36.6974C12.4709 39.2459 15.0193 35.1992 15.0193 35.1992C15.9711 34.027 16.8147 32.7709 17.5398 31.4465C18.5253 29.7232 19.3055 27.8904 19.8642 25.9855C21.4885 20.3845 20.4523 13.0331 20.7884 4.60357C20.8779 3.88757 20.6876 3.16463 20.2572 2.58549C19.8267 2.00635 19.1894 1.61571 18.478 1.49501C18.478 1.49501 0.554688 1.34098 0.554688 2.27915Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path opacity="0.2" d="M14.6412 29.5842C15.8594 30.8024 14.2071 34.8631 13.0869 43.8248C12.5828 47.9135 12.4008 51.2461 12.2888 53.3745C12.0087 58.5835 12.2888 59.3956 11.8967 66.1028C11.7707 68.4553 11.6027 71.2418 11.0986 74.7424C10.7345 77.3189 10.5839 82.0366 10.3878 82.0506C10.1918 82.0646 9.72631 74.8264 9.61429 71.7739C9.55706 70.2005 9.58511 68.6253 9.69831 67.055C9.90835 63.2043 9.81033 60.2918 9.69831 55.993C9.46026 49.3418 9.34824 46.0092 8.49409 42.6485C7.63993 39.2879 7.42989 35.9693 6.63175 32.7487C6.47772 32.1326 6.09965 30.6343 6.63175 29.9482C7.16385 29.2621 8.032 29.9482 8.98418 30.1723C12.0927 31.1104 13.619 28.576 14.6412 29.5842Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M4.22314 33.897L5.30134 49.9019C5.30134 49.9019 6.46355 59.5077 8.10185 75.2325C9.74015 90.9574 11.9246 84.4742 11.9246 84.4742L14.347 72.6841L16.2233 51.5262L17.4836 31.4745" fill="#DEE1E9"/>
      <path d="M0.554688 2.27915V10.8347L1.95494 27.0077C2.13713 29.4549 2.91596 31.8202 4.22336 33.8969C5.02239 35.0848 6.1117 36.0488 7.38794 36.6974C12.4709 39.2459 15.0193 35.1992 15.0193 35.1992C15.9711 34.027 16.8147 32.7709 17.5398 31.4465C18.5253 29.7232 19.3055 27.8904 19.8642 25.9855C21.4885 20.3845 20.4523 13.0331 20.7884 4.60357C20.8779 3.88757 20.6876 3.16463 20.2572 2.58549C19.8267 2.00635 19.1894 1.61571 18.478 1.49501C18.478 1.49501 0.554688 1.34098 0.554688 2.27915Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.2" d="M14.6412 29.5842C15.8594 30.8024 14.2071 34.8631 13.0869 43.8248C12.5828 47.9135 12.4008 51.2461 12.2888 53.3745C12.0087 58.5835 12.2888 59.3956 11.8967 66.1028C11.7707 68.4553 11.6027 71.2418 11.0986 74.7424C10.7345 77.3189 10.5839 82.0366 10.3878 82.0506C10.1918 82.0646 9.72631 74.8264 9.61429 71.7739C9.55706 70.2005 9.58511 68.6253 9.69831 67.055C9.90835 63.2043 9.81033 60.2918 9.69831 55.993C9.46026 49.3418 9.34824 46.0092 8.49409 42.6485C7.63993 39.2879 7.42989 35.9693 6.63175 32.7487C6.47772 32.1326 6.09965 30.6343 6.63175 29.9482C7.16385 29.2621 8.032 29.9482 8.98418 30.1723C12.0927 31.1104 13.619 28.576 14.6412 29.5842Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="11" cy="85" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
        <path d="M19.6048 24.9157C19.0342 26.8342 18.2547 28.6843 17.2804 30.4327L16.0201 50.4703L14.0598 71.6282L11.6233 83.4184C11.6233 83.4184 9.43892 89.9016 7.81463 74.1767C6.19033 58.4518 5.01411 48.8601 5.01411 48.8601L3.96392 32.8411C2.65632 30.7686 1.87296 28.4089 1.68151 25.9659L0.28125 9.77892V1.22335C0.28125 0.299185 18.2045 0.439214 18.2045 0.439214C18.9081 0.566231 19.5364 0.957506 19.9608 1.53286C20.3851 2.10822 20.5734 2.8241 20.4869 3.53378C20.1789 11.9773 21.2291 19.3707 19.6048 24.9157Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M19.6048 24.9157C19.0342 26.8342 18.2547 28.6843 17.2804 30.4327L16.0201 50.4703L14.0598 71.6282L11.6233 83.4184C11.6233 83.4184 9.43892 89.9016 7.81463 74.1767C6.19033 58.4518 5.01411 48.8601 5.01411 48.8601L3.96392 32.8411C2.65632 30.7686 1.87296 28.4089 1.68151 25.9659L0.28125 9.77892V1.22335C0.28125 0.299185 18.2045 0.439214 18.2045 0.439214C18.9081 0.566231 19.5364 0.957506 19.9608 1.53286C20.3851 2.10822 20.5734 2.8241 20.4869 3.53378C20.1789 11.9773 21.2291 19.3707 19.6048 24.9157Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M0.746593 20.9537L0.129089 2.55786L3.62909 4.55786L7.12909 3.05786L10.1291 4.55786L12.9288 2.52631L11.6686 22.578L9.79224 43.7358L7.3698 55.526C7.3698 55.526 5.1854 62.0092 3.5471 46.2843C1.9088 30.5594 0.746593 20.9537 0.746593 20.9537Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M7.73403 24.4263C7.84605 22.2979 8.02809 18.9653 8.53218 14.8765C9.5135 7.02596 10.9032 2.93639 10.4137 1.22404L8.62909 2.55788L6.12909 1.55788L3.93934 2.30824L2.077 1C1.54751 1.68277 1.91929 3.16976 2.07472 3.79141L2.077 3.80051C2.87515 7.0211 3.08518 10.3397 3.93934 13.7003C4.78518 17.0282 4.90326 20.3286 5.13664 26.8514L5.14356 27.0448C5.25558 31.3435 5.3536 34.2561 5.14356 38.1068C5.03036 39.6771 5.00231 41.2523 5.05954 42.8256C5.17157 45.8782 5.63703 53.1164 5.83307 53.1024C6.0291 53.0884 6.17975 48.3707 6.54382 45.7942C7.04791 42.2935 7.21594 39.507 7.34196 37.1546C7.73403 30.4474 7.45398 29.6352 7.73403 24.4263Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M1.00001 9.16066C5.87741 11.4931 8.21798 10.8755 11.8308 7.00001" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M5.03931 33.4114L6.1175 49.4163C6.1175 49.4163 7.27972 59.0221 8.91801 74.7469C10.5563 90.4718 12.7407 83.9886 12.7407 83.9886L15.1632 72.1985L17.0395 51.0406L18.2997 30.989" fill="#DEE1E9"/>
      <path d="M1.37085 10.3491V1.79355C1.37085 1.08963 11.4612 1.00058 16.5 1L15.8856 1.69049L14.5469 4.72547L18.3559 6.24021L17.1484 8.55362L18.3559 11.0205L17.4489 13.6658H21.3559H21.6017C21.6718 18.1443 21.6555 22.1375 20.6804 25.4999C20.1216 27.4048 19.3415 29.2376 18.3559 30.9609C17.6309 32.2853 16.7873 33.5414 15.8355 34.7136C15.8355 34.7136 13.287 38.7603 8.2041 36.2118C6.92787 35.5632 5.83855 34.5992 5.03952 33.4113C3.73212 31.3346 2.95329 28.9693 2.77111 26.5221L1.37085 10.3491Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M19.936 19.4671C10.4748 16.6077 8.544 12.7954 10.4194 2.5205" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M15.4574 29.0986C16.6756 30.3168 15.0233 34.3775 13.9031 43.3392C13.399 47.4279 13.217 50.7605 13.1049 52.8889C12.8249 58.0979 13.1049 58.91 12.7129 65.6172C12.5868 67.9697 12.4188 70.7562 11.9147 74.2568C11.5507 76.8333 11.4 81.551 11.204 81.565C11.0079 81.5791 10.5425 74.3408 10.4305 71.2883C10.3732 69.715 10.4013 68.1397 10.5145 66.5694C10.7245 62.7187 10.6265 59.8062 10.5145 55.5074C10.2764 48.8562 10.1644 45.5236 9.31025 42.1629C8.45609 38.8023 8.24606 35.4837 7.44791 32.2631C7.29388 31.647 6.91581 30.1488 7.44791 29.4626C7.98001 28.7765 8.84817 29.4626 9.80034 29.6867C12.9089 30.6248 14.4352 28.0904 15.4574 29.0986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M2.5 19.2494L6.1175 49.1452C6.1175 49.1452 7.27972 58.751 8.91801 74.4758C10.5563 90.2007 12.7407 83.7175 12.7407 83.7175L15.1632 71.9274L17.0395 50.7695L20.5 18.2494" fill="#DEE1E9"/>
      <path d="M1.37085 10.078V1.52244C1.37085 0.584265 19.2941 0.738293 19.2941 0.738293C20.0055 0.858994 20.6429 1.24964 21.0734 1.82878C21.5038 2.40792 21.6941 3.13085 21.6045 3.84686C21.2685 12.2764 22.3047 19.6277 20.6804 25.2288C20.1216 27.1337 19.3415 28.9665 18.3559 30.6898C17.8928 31.5358 17.3812 32.354 16.824 33.1402L15.5 32.7494L14 22.7494C11.8506 22.0428 10.6498 22.0646 8.5 22.7494L7 33.1402H5.03952C3.73212 31.0635 2.95329 28.6982 2.77111 26.251L1.37085 10.078Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M2.5 20L6.1175 49.8958C6.1175 49.8958 7.27972 59.5016 8.91801 75.2264C10.5563 90.9513 12.7407 84.4681 12.7407 84.4681L15.1632 72.678L17.0395 51.5201L20.5 19" fill="#DEE1E9"/>
      <path d="M1.37085 10.8286V2.27305C1.37085 1.33488 19.2941 1.4889 19.2941 1.4889C20.0055 1.6096 20.6429 2.00025 21.0734 2.57939C21.5038 3.15853 21.6941 3.88146 21.6045 4.59747C21.2685 13.027 22.3047 20.3784 20.6804 25.9794C20.1216 27.8843 19.3415 29.7171 18.3559 31.4404C17.8928 32.2865 17.3812 33.1046 16.824 33.8908L15.5 33.5L14 23.5C11.8506 22.7934 10.6498 22.8152 8.5 23.5L7 33.8908H5.03952C3.73212 31.8141 2.95329 29.4488 2.77111 27.0016L1.37085 10.8286Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M2.5 20L6.1175 49.8958C6.1175 49.8958 7.27972 59.5016 8.91801 75.2264C10.5563 90.9513 12.7407 84.4681 12.7407 84.4681L15.1632 72.678L17.0395 51.5201L20.5 19" fill="#DEE1E9"/>
      <mask id="mask2041" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="34">
        <path d="M1 10.1748V1.77876C1 0.858079 18.9233 1.00924 18.9233 1.00924C19.6347 1.12769 20.2721 1.51105 20.7025 2.07939C21.1329 2.64774 21.3232 3.3572 21.2337 4.05986C20.8976 12.3323 21.9338 19.5466 20.3095 25.0432C19.7508 26.9126 18.9706 28.7113 17.9851 30.4024C17.5219 31.2327 17.0104 32.0356 16.4531 32.8072L15.1292 32.4236L13.6292 25.0432C11.4798 24.3498 10.2789 24.3712 8.12915 25.0432L6.62915 32.8072H4.66867C3.36127 30.7692 2.58244 28.4479 2.40026 26.0463L1 10.1748Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2041)">
        <path d="M1 10.3491V1.79355C1 0.855384 18.9233 1.00941 18.9233 1.00941C19.6347 1.13011 20.2721 1.52075 20.7025 2.0999C21.1329 2.67904 21.3232 3.40197 21.2337 4.11798C20.8976 12.5475 21.9338 19.8989 20.3095 25.4999C19.7508 27.4048 18.9706 29.2376 17.9851 30.9609C17.5219 31.807 17.0104 32.6251 16.4531 33.4113L15.1292 33.0205L13.6292 24.6821C11.4798 23.9755 10.2789 23.9973 8.12915 24.6821L6.62915 33.4113H4.66867C3.36127 31.3346 2.58244 28.9693 2.40026 26.5221L1 10.3491Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.7868 46H4.46877V23.1468C9.66978 21.4237 12.5858 21.4062 17.7868 23.1468V46Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M5.10211 33.0582L6.18031 49.0631C6.18031 49.0631 7.34252 58.6689 8.98082 74.3937C10.6191 90.1186 12.8035 83.6354 12.8035 83.6354L15.226 71.8453L17.1023 50.6874L18.3625 30.6357" fill="#DEE1E9"/>
      <path d="M1.43365 1.44053V9.99609L2.83391 26.169C3.01609 28.6163 3.79492 30.9816 5.10232 33.0583C5.90135 34.2462 6.99067 35.2102 8.2669 35.8588C13.3498 38.4073 15.8983 34.3605 15.8983 34.3605C16.8501 33.1883 17.6937 31.9323 18.4188 30.6079C19.4043 28.8846 20.1844 27.0518 20.7432 25.1469C22.3675 19.5458 21.3313 12.1945 21.6673 3.76495C21.7569 3.04894 21.5666 2.32601 21.1362 1.74687C20.7057 1.16773 20.0683 0.777084 19.3569 0.656384C19.3569 0.656384 1.43365 0.502356 1.43365 1.44053Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.2" d="M15.5202 28.7454C16.7384 29.9636 15.0861 34.0244 13.9659 42.986C13.4618 47.0748 13.2798 50.4074 13.1677 52.5358C12.8877 57.7447 13.1677 58.5569 12.7757 65.2641C12.6497 67.6165 12.4816 70.403 11.9775 73.9037C11.6135 76.4801 11.4628 81.1979 11.2668 81.2119C11.0707 81.2259 10.6053 73.9877 10.4933 70.9351C10.436 69.3618 10.4641 67.7865 10.5773 66.2163C10.7873 62.3656 10.6893 59.453 10.5773 55.1542C10.3392 48.503 10.2272 45.1704 9.37306 41.8098C8.5189 38.4492 8.30886 35.1306 7.51072 31.91C7.35669 31.2939 6.97862 29.7956 7.51072 29.1095C8.04281 28.4234 8.91097 29.1095 9.86314 29.3335C12.9717 30.2717 14.498 27.7372 15.5202 28.7454Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M16.0074 60.7518C12.6925 62.6612 10.8414 62.5741 7.54616 60.7518" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M5.03931 33.8909L6.1175 49.8959C6.1175 49.8959 7.27972 59.5016 8.91801 75.2265C10.5563 90.9514 12.7407 84.4682 12.7407 84.4682L15.1632 72.678L17.0395 51.5202L18.2997 31.4685" fill="#DEE1E9"/>
      <mask id="mask2241" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2241)">
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7568 16C12.8188 16 14.0645 10.7018 14.0645 1.10345L12.1414 -0.758619L9.06445 -2C9.06445 7.59833 10.6947 16 11.7568 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.5572 15.307C16.8156 16.8555 15.1089 22.0174 13.9517 33.409C13.431 38.6064 13.2429 42.8427 13.1272 45.5482C12.8379 52.1696 13.1272 53.2019 12.7222 61.7278C12.5921 64.7181 12.4185 68.2602 11.8978 72.7101C11.5217 75.9852 11.3661 81.9822 11.1636 82C10.9611 82.0178 10.4803 72.8169 10.3645 68.9366C10.3054 66.9367 10.3344 64.9343 10.4513 62.9382C10.6683 58.0434 10.567 54.3411 10.4513 48.8767C10.2054 40.422 10.0897 36.1857 9.20739 31.9138C8.32506 27.642 8.1081 23.4235 7.28363 19.3297C7.12452 18.5465 6.73398 16.6419 7.28363 15.7698C7.83327 14.8976 8.73006 15.7698 9.71364 16.0546C12.9247 17.2471 14.5013 14.0254 15.5572 15.307Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="12" cy="84.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M5.03931 33.8909L6.1175 49.8959C6.1175 49.8959 7.27972 59.5016 8.91801 75.2265C10.5563 90.9514 12.7407 84.4682 12.7407 84.4682L15.1632 72.678L17.0395 51.5202L18.2997 31.4685" fill="#DEE1E9"/>
      <mask id="mask2341" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2341)">
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7568 16C12.8188 16 14.0645 10.7018 14.0645 1.10345L12.1414 -0.758619L9.06445 -2C9.06445 7.59833 10.6947 16 11.7568 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.5572 15.307C16.8156 16.8555 15.1089 22.0174 13.9517 33.409C13.431 38.6064 13.2429 42.8427 13.1272 45.5482C12.8379 52.1696 13.1272 53.2019 12.7222 61.7278C12.5921 64.7181 12.4185 68.2602 11.8978 72.7101C11.5217 75.9852 11.3661 81.9822 11.1636 82C10.9611 82.0178 10.4803 72.8169 10.3645 68.9366C10.3054 66.9367 10.3344 64.9343 10.4513 62.9382C10.6683 58.0434 10.567 54.3411 10.4513 48.8767C10.2054 40.422 10.0897 36.1857 9.20739 31.9138C8.32506 27.642 8.1081 23.4235 7.28363 19.3297C7.12452 18.5465 6.73398 16.6419 7.28363 15.7698C7.83327 14.8976 8.73006 15.7698 9.71364 16.0546C12.9247 17.2471 14.5013 14.0254 15.5572 15.307Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="12" cy="84.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2341)">
        <path d="M11 80L11.8229 83.7391L14.8567 81.4037L13.0837 84.797L16.9088 84.9581L13.3694 86.4178L16.1962 89L12.5465 87.8431L13.0521 91.6382L11 88.406L8.94788 91.6382L9.45345 87.8431L5.80385 89L8.63055 86.4178L5.09115 84.9581L8.91634 84.797L7.14327 81.4037L10.1771 83.7391L11 80Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2341" x="4.89113" y="79.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.03931 33.8909L6.1175 49.8959C6.1175 49.8959 7.27972 59.5016 8.91801 75.2265C10.5563 90.9514 12.7407 84.4682 12.7407 84.4682L15.1632 72.678L17.0395 51.5202L18.2997 31.4685" fill="#DEE1E9"/>
      <mask id="mask2341" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2341)">
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7568 16C12.8188 16 14.0645 10.7018 14.0645 1.10345L12.1414 -0.758619L9.06445 -2C9.06445 7.59833 10.6947 16 11.7568 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.5572 15.307C16.8156 16.8555 15.1089 22.0174 13.9517 33.409C13.431 38.6064 13.2429 42.8427 13.1272 45.5482C12.8379 52.1696 13.1272 53.2019 12.7222 61.7278C12.5921 64.7181 12.4185 68.2602 11.8978 72.7101C11.5217 75.9852 11.3661 81.9822 11.1636 82C10.9611 82.0178 10.4803 72.8169 10.3645 68.9366C10.3054 66.9367 10.3344 64.9343 10.4513 62.9382C10.6683 58.0434 10.567 54.3411 10.4513 48.8767C10.2054 40.422 10.0897 36.1857 9.20739 31.9138C8.32506 27.642 8.1081 23.4235 7.28363 19.3297C7.12452 18.5465 6.73398 16.6419 7.28363 15.7698C7.83327 14.8976 8.73006 15.7698 9.71364 16.0546C12.9247 17.2471 14.5013 14.0254 15.5572 15.307Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="12" cy="84.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2341)">
        <path d="M11 80L11.8229 83.7391L14.8567 81.4037L13.0837 84.797L16.9088 84.9581L13.3694 86.4178L16.1962 89L12.5465 87.8431L13.0521 91.6382L11 88.406L8.94788 91.6382L9.45345 87.8431L5.80385 89L8.63055 86.4178L5.09115 84.9581L8.91634 84.797L7.14327 81.4037L10.1771 83.7391L11 80Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2341" x="4.89113" y="79.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M5.03931 33.69L6.1175 49.6949C6.1175 49.6949 7.27972 59.3007 8.91801 75.0256C10.5563 90.7504 12.7407 84.2673 12.7407 84.2673L15.1632 72.4771L17.0395 51.3192L18.2997 31.2676" fill="#DEE1E9"/>
      <mask id="mask2541" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M1 1.59263V10.1482L2.40026 26.3211C2.58244 28.7684 3.36127 31.1337 4.66867 33.2104C5.4677 34.3983 6.55702 35.3623 7.83325 36.0109C12.9162 38.5594 15.4646 34.5126 15.4646 34.5126C16.4164 33.3404 17.26 32.0844 17.9851 30.76C18.9706 29.0367 19.7508 27.2039 20.3095 25.299C21.9338 19.6979 20.8976 12.3466 21.2337 3.91705C21.3232 3.20104 21.1329 2.47811 20.7025 1.89897C20.2721 1.31983 19.6347 0.929184 18.9233 0.808484C18.9233 0.808484 1 0.654456 1 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2541)">
        <path d="M1 1.59263V10.1482L2.40026 26.3211C2.58244 28.7684 3.36127 31.1337 4.66867 33.2104C5.4677 34.3983 6.55702 35.3623 7.83325 36.0109C12.9162 38.5594 15.4646 34.5126 15.4646 34.5126C16.4164 33.3404 17.26 32.0844 17.9851 30.76C18.9706 29.0367 19.7508 27.2039 20.3095 25.299C21.9338 19.6979 20.8976 12.3466 21.2337 3.91705C21.3232 3.20104 21.1329 2.47811 20.7025 1.89897C20.2721 1.31983 19.6347 0.929184 18.9233 0.808484C18.9233 0.808484 1 0.654456 1 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7568 15.7991C12.8188 15.7991 14.0645 10.5008 14.0645 0.902521L12.1414 -0.959547L9.06445 -2.20093C9.06445 7.3974 10.6947 15.7991 11.7568 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.5572 15.1061C16.8156 16.6546 15.1089 21.8164 13.9517 33.2081C13.431 38.4055 13.2429 42.6417 13.1272 45.3473C12.8379 51.9686 13.1272 53.001 12.7222 61.5269C12.5921 64.5172 12.4185 68.0593 11.8978 72.5092C11.5217 75.7842 11.3661 81.7812 11.1636 81.799C10.9611 81.8168 10.4803 72.6159 10.3645 68.7357C10.3054 66.7357 10.3344 64.7333 10.4513 62.7373C10.6683 57.8424 10.567 54.1402 10.4513 48.6757C10.2054 40.221 10.0897 35.9848 9.20739 31.7129C8.32506 27.4411 8.1081 23.2226 7.28363 19.1287C7.12452 18.3456 6.73398 16.441 7.28363 15.5688C7.83327 14.6967 8.73006 15.5688 9.71364 15.8536C12.9247 17.0462 14.5013 13.8245 15.5572 15.1061Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="12" cy="84" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2641" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="86">
        <path d="M4.66846 33.4115L5.74665 49.4164C5.74665 49.4164 6.90887 59.0221 8.54716 74.747C10.1855 90.4719 12.3699 83.9887 12.3699 83.9887L14.7923 72.1985L16.6686 51.0407L17.9289 30.989" fill="#DEE1E9"/>
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2641)">
        <path d="M4.66846 33.4115L5.74665 49.4164C5.74665 49.4164 6.90887 59.0221 8.54716 74.747C10.1855 90.4719 12.3699 83.9887 12.3699 83.9887L14.7923 72.1985L16.6686 51.0407L17.9289 30.989" fill="#DEE1E9"/>
        <path d="M1 1.79355V10.3491L2.40026 26.5221C2.58244 28.9693 3.36127 31.3346 4.66867 33.4113C5.4677 34.5992 6.55702 35.5632 7.83325 36.2118C12.9162 38.7603 15.4646 34.7136 15.4646 34.7136C16.4164 33.5414 17.26 32.2853 17.9851 30.9609C18.9706 29.2376 19.7508 27.4048 20.3095 25.4999C21.9338 19.8989 20.8976 12.5475 21.2337 4.11798C21.3232 3.40197 21.1329 2.67904 20.7025 2.0999C20.2721 1.52075 19.6347 1.13011 18.9233 1.00941C18.9233 1.00941 1 0.855384 1 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.9741 21.6762H-0.947077C1.21498 27.046 1.57199 30.3063 1.27545 36.0446C1.26427 36.2609 1.51364 36.3955 1.70387 36.2689C2.70794 35.601 5.06614 33.7849 5.63778 31.1432C6.33025 27.9432 8.33345 27.1661 8.54606 27.092C8.55902 27.0875 8.57061 27.083 8.58299 27.0773L9.76593 26.5307C9.9105 26.4639 9.96909 26.3014 9.89681 26.1678L9.36789 25.1901C9.32314 25.1074 9.32722 25.0092 9.3787 24.9299L11.2251 22.0858C11.3421 21.9056 11.2016 21.6762 10.9741 21.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.04897 35.155C3.10995 34.3071 4.51793 32.8702 4.92035 31.0106C5.66356 27.5761 7.83953 26.6201 8.2725 26.4648L9.04609 26.1073L8.71349 25.4925C8.55687 25.203 8.57114 24.8596 8.75132 24.5821L10.1988 22.3524H0.0962133C1.87294 27.0171 2.24894 30.2129 2.04897 35.155ZM8.25579 26.4725C8.25565 26.4726 8.25593 26.4724 8.25579 26.4725V26.4725ZM2.1313 36.8178C1.40244 37.3027 0.506713 36.7463 0.544641 36.0123C0.837448 30.3462 0.488242 27.18 -1.63278 21.9121L-2 21H10.9741C11.7703 21 12.2621 21.8028 11.8524 22.4338L10.1301 25.0869L10.5512 25.8654C10.8042 26.333 10.5991 26.9017 10.0931 27.1355L8.91021 27.6822C8.86949 27.701 8.83364 27.7145 8.80412 27.7248C8.76753 27.7376 6.98671 28.3577 6.35523 31.2758C5.72573 34.1849 3.16235 36.1319 2.1313 36.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask141" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="86">
        <path d="M4.66852 33.4115L5.74671 49.4164C5.74671 49.4164 6.90893 59.0221 8.54723 74.747C10.1855 90.4719 12.3699 83.9887 12.3699 83.9887L14.7924 72.1985L16.6687 51.0407L17.9289 30.989" fill="#DEE1E9"/>
        <path d="M1.00006 1.79355V10.3491L2.40032 26.5221C2.5825 28.9693 3.36133 31.3346 4.66873 33.4113C5.46776 34.5992 6.55708 35.5632 7.83331 36.2118C12.9162 38.7603 15.4647 34.7136 15.4647 34.7136C16.4165 33.5414 17.2601 32.2853 17.9852 30.9609C18.9707 29.2376 19.7508 27.4048 20.3096 25.4999C21.9339 19.8989 20.8977 12.5475 21.2338 4.11798C21.3233 3.40197 21.133 2.67904 20.7026 2.0999C20.2721 1.52075 19.6348 1.13011 18.9233 1.00941C18.9233 1.00941 1.00006 0.855384 1.00006 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask141)">
        <path d="M4.66852 33.4115L5.74671 49.4164C5.74671 49.4164 6.90893 59.0221 8.54723 74.747C10.1855 90.4719 12.3699 83.9887 12.3699 83.9887L14.7924 72.1985L16.6687 51.0407L17.9289 30.989" fill="#DEE1E9"/>
        <path d="M1.00006 1.79355V10.3491L2.40032 26.5221C2.5825 28.9693 3.36133 31.3346 4.66873 33.4113C5.46776 34.5992 6.55708 35.5632 7.83331 36.2118C12.9162 38.7603 15.4647 34.7136 15.4647 34.7136C16.4165 33.5414 17.2601 32.2853 17.9852 30.9609C18.9707 29.2376 19.7508 27.4048 20.3096 25.4999C21.9339 19.8989 20.8977 12.5475 21.2338 4.11798C21.3233 3.40197 21.133 2.67904 20.7026 2.0999C20.2721 1.52075 19.6348 1.13011 18.9233 1.00941C18.9233 1.00941 1.00006 0.855384 1.00006 1.79355Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.86628 24.5H1.71952C3.19704 28.4705 3.44101 30.8811 3.23836 35.1241C3.23072 35.2841 3.40114 35.3836 3.53113 35.29C4.2173 34.7961 5.82886 33.4532 6.21952 31.5C6.69274 29.1339 8.0617 28.5593 8.20699 28.5045C8.21585 28.5012 8.22377 28.4978 8.23223 28.4936L9.04063 28.0894C9.13943 28.04 9.17947 27.9199 9.13008 27.8211L8.76862 27.0982C8.73804 27.037 8.74082 26.9645 8.776 26.9058L10.0378 24.8029C10.1178 24.6696 10.0217 24.5 9.86628 24.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76699 34.4664C4.49205 33.8394 5.45424 32.777 5.72925 31.4019C6.23715 28.8625 7.72418 28.1555 8.02006 28.0407L8.54872 27.7764L8.32143 27.3218C8.21439 27.1077 8.22415 26.8538 8.34728 26.6486L9.33644 25H2.4325C3.64669 28.4491 3.90365 30.8121 3.76699 34.4664ZM8.00864 28.0464C8.00854 28.0465 8.00874 28.0464 8.00864 28.0464V28.0464ZM3.82326 35.6958C3.32516 36.0544 2.71303 35.643 2.73895 35.1003C2.93905 30.9106 2.70041 28.5695 1.25093 24.6744L0.999982 24H9.8663C10.4104 24 10.7465 24.5936 10.4665 25.0601L9.2895 27.0219L9.57731 27.5975C9.7502 27.9433 9.61005 28.3638 9.26426 28.5367L8.45586 28.9409C8.42803 28.9548 8.40354 28.9648 8.38336 28.9724C8.35836 28.9818 7.14137 29.4403 6.70983 31.5981C6.27964 33.749 4.52786 35.1887 3.82326 35.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M5.03931 33.69L6.1175 49.6949C6.1175 49.6949 7.27972 59.3007 8.91801 75.0256C10.5563 90.7504 12.7407 84.2673 12.7407 84.2673L15.1632 72.4771L17.0395 51.3192L18.2997 31.2676" fill="#DEE1E9"/>
      <mask id="mask241" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M1 1.59263V10.1482L2.40026 26.3211C2.58244 28.7684 3.36127 31.1337 4.66867 33.2104C5.4677 34.3983 6.55702 35.3623 7.83325 36.0109C12.9162 38.5594 15.4646 34.5126 15.4646 34.5126C16.4164 33.3404 17.26 32.0844 17.9851 30.76C18.9706 29.0367 19.7508 27.2039 20.3095 25.299C21.9338 19.6979 20.8976 12.3466 21.2337 3.91705C21.3232 3.20104 21.1329 2.47811 20.7025 1.89897C20.2721 1.31983 19.6347 0.929184 18.9233 0.808484C18.9233 0.808484 1 0.654456 1 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask241)">
        <path d="M1 1.59263V10.1482L2.40026 26.3211C2.58244 28.7684 3.36127 31.1337 4.66867 33.2104C5.4677 34.3983 6.55702 35.3623 7.83325 36.0109C12.9162 38.5594 15.4646 34.5126 15.4646 34.5126C16.4164 33.3404 17.26 32.0844 17.9851 30.76C18.9706 29.0367 19.7508 27.2039 20.3095 25.299C21.9338 19.6979 20.8976 12.3466 21.2337 3.91705C21.3232 3.20104 21.1329 2.47811 20.7025 1.89897C20.2721 1.31983 19.6347 0.929184 18.9233 0.808484C18.9233 0.808484 1 0.654456 1 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7568 15.7991C12.8188 15.7991 14.0645 10.5008 14.0645 0.902521L12.1414 -0.959547L9.06445 -2.20093C9.06445 7.3974 10.6947 15.7991 11.7568 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.5572 15.1061C16.8156 16.6546 15.1089 21.8164 13.9517 33.2081C13.431 38.4055 13.2429 42.6417 13.1272 45.3473C12.8379 51.9686 13.1272 53.001 12.7222 61.5269C12.5921 64.5172 12.4185 68.0593 11.8978 72.5092C11.5217 75.7842 11.3661 81.7812 11.1636 81.799C10.9611 81.8168 10.4803 72.6159 10.3645 68.7357C10.3054 66.7357 10.3344 64.7333 10.4513 62.7373C10.6683 57.8424 10.567 54.1402 10.4513 48.6757C10.2054 40.221 10.0897 35.9848 9.20739 31.7129C8.32506 27.4411 8.1081 23.2226 7.28363 19.1287C7.12452 18.3456 6.73398 16.441 7.28363 15.5688C7.83327 14.6967 8.73006 15.5688 9.71364 15.8536C12.9247 17.0462 14.5013 13.8245 15.5572 15.1061Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M5.03931 33.69L6.1175 49.6949C6.1175 49.6949 7.27972 59.3007 8.91801 75.0256C10.5563 90.7504 12.7407 84.2673 12.7407 84.2673L15.1632 72.4771L17.0395 51.3192L18.2997 31.2676" fill="#DEE1E9"/>
      <mask id="mask341" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="38">
        <path d="M1 1.59263V10.1482L2.40026 26.3211C2.58244 28.7684 3.36127 31.1337 4.66867 33.2104C5.4677 34.3983 6.55702 35.3623 7.83325 36.0109C12.9162 38.5594 15.4646 34.5126 15.4646 34.5126C16.4164 33.3404 17.26 32.0844 17.9851 30.76C18.9706 29.0367 19.7508 27.2039 20.3095 25.299C21.9338 19.6979 20.8976 12.3466 21.2337 3.91705C21.3232 3.20104 21.1329 2.47811 20.7025 1.89897C20.2721 1.31983 19.6347 0.929184 18.9233 0.808484C18.9233 0.808484 1 0.654456 1 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask341)">
        <path d="M1 1.59263V10.1482L2.40026 26.3211C2.58244 28.7684 3.36127 31.1337 4.66867 33.2104C5.4677 34.3983 6.55702 35.3623 7.83325 36.0109C12.9162 38.5594 15.4646 34.5126 15.4646 34.5126C16.4164 33.3404 17.26 32.0844 17.9851 30.76C18.9706 29.0367 19.7508 27.2039 20.3095 25.299C21.9338 19.6979 20.8976 12.3466 21.2337 3.91705C21.3232 3.20104 21.1329 2.47811 20.7025 1.89897C20.2721 1.31983 19.6347 0.929184 18.9233 0.808484C18.9233 0.808484 1 0.654456 1 1.59263Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.7568 15.7991C12.8188 15.7991 14.0645 10.5008 14.0645 0.902521L12.1414 -0.959547L9.06445 -2.20093C9.06445 7.3974 10.6947 15.7991 11.7568 15.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M5.03931 33.8909L6.1175 49.8959C6.1175 49.8959 7.27972 59.5016 8.91801 75.2265C10.5563 90.9514 12.7407 84.4682 12.7407 84.4682L15.1632 72.678L17.0395 51.5202L18.2997 31.4685" fill="#DEE1E9"/>
      <path d="M1.37085 2.27305V10.8286L2.77111 27.0016C2.95329 29.4488 3.73212 31.8141 5.03952 33.8908C5.83855 35.0787 6.92787 36.0427 8.2041 36.6913C13.287 39.2398 15.8355 35.1931 15.8355 35.1931C16.7873 34.0209 17.6309 32.7648 18.3559 31.4404C19.3415 29.7171 20.1216 27.8843 20.6804 25.9794C22.3047 20.3784 21.2685 13.027 21.6045 4.59747C21.6941 3.88146 21.5038 3.15853 21.0734 2.57939C20.6429 2.00025 20.0055 1.6096 19.2941 1.4889C19.2941 1.4889 1.37085 1.33488 1.37085 2.27305Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15 1L16.8667 3.50086L18.5 2.00515V5.3192L21.0622 4.75129L19.6667 6.50189L22 8.50258L19.6667 10.5033L21.0622 12.2539L18.7333 11.5036L18.5 15L16.2501 13.503L15.4667 14.5046L13.7499 13.503L11.5 15L10.8 13.0041L8.93782 12.2539L9.4 9.50292L8 8.50258L10.3333 7.00206L8.93782 4.75129L10.8 4.5012L11.5 2.00515L14.5333 4.00103L15 1Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M5.03931 33.8909L6.1175 49.8959C6.1175 49.8959 7.27972 59.5016 8.91801 75.2265C10.5563 90.9514 12.7407 84.4682 12.7407 84.4682L15.1632 72.678L17.0395 51.5202L18.2997 31.4685" fill="#DEE1E9"/>
      <path d="M1.37085 2.27305V10.8286L2.77111 27.0016C2.95329 29.4488 3.73212 31.8141 5.03952 33.8908C5.83855 35.0787 6.92787 36.0427 8.2041 36.6913C13.287 39.2398 15.8355 35.1931 15.8355 35.1931C16.7873 34.0209 17.6309 32.7648 18.3559 31.4404C19.3415 29.7171 20.1216 27.8843 20.6804 25.9794C22.3047 20.3784 21.2685 13.027 21.6045 4.59747C21.6941 3.88146 21.5038 3.15853 21.0734 2.57939C20.6429 2.00025 20.0055 1.6096 19.2941 1.4889C19.2941 1.4889 1.37085 1.33488 1.37085 2.27305Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M16 1L17.6 3.14359L19 1.86156V4.70217L21.1962 4.21539L20 5.71591L22 7.43078L20 9.14566L21.1962 10.6462L19.2 10.0031L19 13L17.0715 11.7168L16.4 12.5754L14.9285 11.7168L13 13L12.4 11.2892L10.8038 10.6462L11.2 8.28822L10 7.43078L12 6.14462L10.8038 4.21539L12.4 4.00103L13 1.86156L15.6 3.57231L16 1Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M5.03931 33.8909L6.1175 49.8959C6.1175 49.8959 7.27972 59.5016 8.91801 75.2265C10.5563 90.9514 12.7407 84.4682 12.7407 84.4682L15.1632 72.678L17.0395 51.5202L18.2997 31.4685" fill="#DEE1E9"/>
      <path d="M1.37085 2.27305V10.8286L2.77111 27.0016C2.95329 29.4488 3.73212 31.8141 5.03952 33.8908C5.83855 35.0787 6.92787 36.0427 8.2041 36.6913C13.287 39.2398 15.8355 35.1931 15.8355 35.1931C16.7873 34.0209 17.6309 32.7648 18.3559 31.4404C19.3415 29.7171 20.1216 27.8843 20.6804 25.9794C22.3047 20.3784 21.2685 13.027 21.6045 4.59747C21.6941 3.88146 21.5038 3.15853 21.0734 2.57939C20.6429 2.00025 20.0055 1.6096 19.2941 1.4889C19.2941 1.4889 1.37085 1.33488 1.37085 2.27305Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M18.5 1L19.4333 2.25043L20.25 1.50258V3.1596L21.5311 2.87564L20.8333 3.75094L22 4.75129L20.8333 5.75163L21.5311 6.62693L20.3667 6.2518L20.25 8L19.125 7.25148L18.7333 7.75232L17.875 7.25148L16.75 8L16.4 7.00206L15.4689 6.62693L15.7 5.25146L15 4.75129L16.1667 4.00103L15.4689 2.87564L16.4 2.7506L16.75 1.50258L18.2667 2.50052L18.5 1Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M7.42413 76.6565L9.50785 77.9263H12.9916L15.0916 76.6565L13.4799 75.11L15.5637 73.5635L13.6753 72.017L15.9706 70.324L14.1474 68.7775L16.4427 67.3775L14.4241 65.9612L16.4427 64.3496L14.7497 62.803L16.9799 61.3868L15.2218 59.9217L17.5171 58.3751L15.3683 56.8286L17.5171 55.217L15.9706 53.8659L18.0543 52.3193L16.2311 50.838L18.3148 49.2914L16.5078 47.5496L17.5171 45.8728V31.6124L15.6288 24.2868H9.50785" fill="#8889BE"/>
      <path d="M7.42413 76.6565L9.50785 77.9263H12.9916L15.0916 76.6565L13.4799 75.11L15.5637 73.5635L13.6753 72.017L15.9706 70.324L14.1474 68.7775L16.4427 67.3775L14.4241 65.9612L16.4427 64.3496L14.7497 62.803L16.9799 61.3868L15.2218 59.9217L17.5171 58.3751L15.3683 56.8286L17.5171 55.217L15.9706 53.8659L18.0543 52.3193L16.2311 50.838L18.3148 49.2914L16.5078 47.5496L17.5171 45.8728V31.6124L15.6288 24.2868H9.50785" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M14.8314 76.6565L12.7477 77.9263H9.28026L7.16398 76.6565L8.79189 75.11L6.69189 73.5635L8.58026 72.017L6.30119 70.324L8.10817 68.7775L5.8291 67.3775L7.8477 65.9612L5.8291 64.3496L7.50584 62.803L5.29189 61.3868L7.03375 59.9217L4.75469 58.3751L6.90352 56.8286L4.75469 55.217L6.30119 53.8659L4.21748 52.3193L6.02445 50.838L3.94073 49.2914L5.76399 47.5496L4.75469 45.8728V31.6124L6.62678 24.2868H10.5337" fill="#8889BE"/>
      <path d="M14.8314 76.6565L12.7477 77.9263H9.28026L7.16398 76.6565L8.79189 75.11L6.69189 73.5635L8.58026 72.017L6.30119 70.324L8.10817 68.7775L5.8291 67.3775L7.8477 65.9612L5.8291 64.3496L7.50584 62.803L5.29189 61.3868L7.03375 59.9217L4.75469 58.3751L6.90352 56.8286L4.75469 55.217L6.30119 53.8659L4.21748 52.3193L6.02445 50.838L3.94073 49.2914L5.76399 47.5496L4.75469 45.8728V31.6124L6.62678 24.2868H10.5337" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask541" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="34">
        <path d="M1 10.5979V2.20186C1 1.28117 18.9233 1.43233 18.9233 1.43233C19.6347 1.55078 20.2721 1.93414 20.7025 2.50249C21.1329 3.07083 21.3232 3.78029 21.2337 4.48296C20.8976 12.7554 21.9338 19.9697 20.3095 25.4663C19.7508 27.3357 18.9706 29.1344 17.9851 30.8255C17.5219 31.6558 17.0104 32.4587 16.4531 33.2303L15.1292 32.8467L13.6292 25.4663C11.4798 24.7729 10.2789 24.7943 8.12915 25.4663L6.62915 33.2303H4.66867C3.36127 31.1923 2.58244 28.871 2.40026 26.4694L1 10.5979Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask541)">
        <path d="M1 10.7722V2.21665C1 1.27848 18.9233 1.43251 18.9233 1.43251C19.6347 1.55321 20.2721 1.94385 20.7025 2.52299C21.1329 3.10213 21.3232 3.82507 21.2337 4.54107C20.8976 12.9706 21.9338 20.322 20.3095 25.923C19.7508 27.8279 18.9706 29.6607 17.9851 31.384C17.5219 32.2301 17.0104 33.0482 16.4531 33.8344L15.1292 33.4436L13.6292 25.1052C11.4798 24.3986 10.2789 24.4204 8.12915 25.1052L6.62915 33.8344H4.66867C3.36127 31.7577 2.58244 29.3924 2.40026 26.9452L1 10.7722Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M17.7868 46.4231H4.46877V23.5699C9.66978 21.8468 12.5858 21.8293 17.7868 23.5699V46.4231Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M7.42413 75.948L9.50785 77.2178H12.9916L15.0916 75.948L13.4799 74.4015L15.5637 72.855L13.6753 71.3085L15.9706 69.6155L14.1474 68.069L16.4427 66.669L14.4241 65.2527L16.4427 63.6411L14.7497 62.0945L16.9799 60.6783L15.2218 59.2132L17.5171 57.6667L15.3683 56.1201L17.5171 54.5085L15.9706 53.1574L18.0543 51.6109L16.2311 50.1295L18.3148 48.5829L16.5078 46.8411L17.5171 45.1644V30.9039L15.6288 23.5784H9.50785" fill="#8889BE"/>
      <path d="M7.42413 75.948L9.50785 77.2178H12.9916L15.0916 75.948L13.4799 74.4015L15.5637 72.855L13.6753 71.3085L15.9706 69.6155L14.1474 68.069L16.4427 66.669L14.4241 65.2527L16.4427 63.6411L14.7497 62.0945L16.9799 60.6783L15.2218 59.2132L17.5171 57.6667L15.3683 56.1201L17.5171 54.5085L15.9706 53.1574L18.0543 51.6109L16.2311 50.1295L18.3148 48.5829L16.5078 46.8411L17.5171 45.1644V30.9039L15.6288 23.5784H9.50785" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M14.8314 75.948L12.7477 77.2178H9.28026L7.16398 75.948L8.79189 74.4015L6.69189 72.855L8.58026 71.3085L6.30119 69.6155L8.10817 68.069L5.8291 66.669L7.8477 65.2527L5.8291 63.6411L7.50584 62.0945L5.29189 60.6783L7.03375 59.2132L4.75469 57.6667L6.90352 56.1201L4.75469 54.5085L6.30119 53.1574L4.21748 51.6109L6.02445 50.1295L3.94073 48.5829L5.76399 46.8411L4.75469 45.1644V30.9039L6.62678 23.5784H10.5337" fill="#8889BE"/>
      <path d="M14.8314 75.948L12.7477 77.2178H9.28026L7.16398 75.948L8.79189 74.4015L6.69189 72.855L8.58026 71.3085L6.30119 69.6155L8.10817 68.069L5.8291 66.669L7.8477 65.2527L5.8291 63.6411L7.50584 62.0945L5.29189 60.6783L7.03375 59.2132L4.75469 57.6667L6.90352 56.1201L4.75469 54.5085L6.30119 53.1574L4.21748 51.6109L6.02445 50.1295L3.94073 48.5829L5.76399 46.8411L4.75469 45.1644V30.9039L6.62678 23.5783H10.5337" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask541" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="34">
        <path d="M1 9.8892V1.49312C1 0.572435 18.9233 0.723592 18.9233 0.723592C19.6347 0.842042 20.2721 1.2254 20.7025 1.79375C21.1329 2.36209 21.3232 3.07155 21.2337 3.77422C20.8976 12.0466 21.9338 19.2609 20.3095 24.7576C19.7508 26.627 18.9706 28.4256 17.9851 30.1168C17.5219 30.9471 17.0104 31.75 16.4531 32.5215L15.1292 32.138L13.6292 24.7576C11.4798 24.0641 10.2789 24.0855 8.12915 24.7576L6.62915 32.5215H4.66867C3.36127 30.4835 2.58244 28.1623 2.40026 25.7607L1 9.8892Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask541)">
        <path d="M1 10.0635V1.50791C1 0.569739 18.9233 0.723767 18.9233 0.723767C19.6347 0.844467 20.2721 1.23511 20.7025 1.81425C21.1329 2.39339 21.3232 3.11633 21.2337 3.83233C20.8976 12.2619 21.9338 19.6132 20.3095 25.2142C19.7508 27.1192 18.9706 28.952 17.9851 30.6752C17.5219 31.5213 17.0104 32.3395 16.4531 33.1257L15.1292 32.7349L13.6292 24.3965C11.4798 23.6899 10.2789 23.7117 8.12915 24.3965L6.62915 33.1257H4.66867C3.36127 31.049 2.58244 28.6836 2.40026 26.2364L1 10.0635Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M0 0.259766H21V5.25977H0V0.259766Z" fill="#838AA2"/>
      <path d="M11.1292 22.6777L14.7467 52.5735C14.7467 52.5735 15.9089 62.1793 17.5472 77.9042C19.1855 93.629 21.3699 87.1459 21.3699 87.1459L23.7923 75.3557L25.6686 54.1978L29.1292 21.6777" fill="#DEE1E9"/>
      <path d="M10 13.5063V4.95078C10 4.01261 27.9233 4.16664 27.9233 4.16664C28.6347 4.28734 29.2721 4.67798 29.7025 5.25712C30.1329 5.83626 30.3232 6.5592 30.2337 7.27521C29.8976 15.7047 30.9338 23.0561 29.3095 28.6571C28.7508 30.562 27.9706 32.3949 26.9851 34.1181C26.5219 34.9642 26.0104 35.7823 25.4531 36.5686L24.1292 36.1777L22.6292 26.1777C20.4798 25.4711 19.2789 25.4929 17.1292 26.1777L15.6292 36.5686H13.6687C12.3613 34.4918 11.5824 32.1265 11.4003 29.6793L10 13.5063Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M4.88184 33.4962L5.96003 49.5011C5.96003 49.5011 7.12224 59.1068 8.76054 74.8317C10.3988 90.5566 12.5832 84.0734 12.5832 84.0734L15.0057 72.2833L16.882 51.1254L18.1423 31.0737" fill="#DEE1E9"/>
      <path d="M1.21338 1.87852V10.4341L2.61363 26.607C2.79582 29.0542 3.57465 31.4196 4.88205 33.4963C5.68108 34.6842 6.77039 35.6482 8.04663 36.2968C13.1296 38.8453 15.678 34.7985 15.678 34.7985C16.6298 33.6263 17.4734 32.3703 18.1985 31.0458C19.184 29.3226 19.9642 27.4898 20.5229 25.5848C22.1472 19.9838 21.111 12.6325 21.4471 4.20294C21.5366 3.48693 21.3463 2.764 20.9159 2.18486C20.4854 1.60572 19.8481 1.21507 19.1367 1.09437C19.1367 1.09437 1.21338 0.940345 1.21338 1.87852Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.2" d="M15.2999 29.1834C16.5181 30.4016 14.8658 34.4624 13.7456 43.424C13.2415 47.5127 13.0595 50.8454 12.9475 52.9737C12.6674 58.1827 12.9475 58.9948 12.5554 65.7021C12.4294 68.0545 12.2613 70.841 11.7573 74.3417C11.3932 76.9181 11.2425 81.6359 11.0465 81.6499C10.8505 81.6639 10.385 74.4257 10.273 71.3731C10.2158 69.7998 10.2438 68.2245 10.357 66.6542C10.567 62.8035 10.469 59.891 10.357 55.5922C10.119 48.941 10.0069 45.6084 9.15278 42.2478C8.29862 38.8872 8.08859 35.5686 7.29044 32.348C7.13641 31.7319 6.75834 30.2336 7.29044 29.5475C7.82254 28.8613 8.6907 29.5475 9.64287 29.7715C12.7514 30.7097 14.2777 28.1752 15.2999 29.1834Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M4.88184 33.4962L5.96003 49.5011C5.96003 49.5011 7.12224 59.1068 8.76054 74.8317C10.3988 90.5566 12.5832 84.0734 12.5832 84.0734L15.0057 72.2833L16.882 51.1254L18.1423 31.0737" fill="#DEE1E9"/>
      <path d="M1.21338 1.87852V10.4341L2.61363 26.607C2.79582 29.0542 3.57465 31.4196 4.88205 33.4963C5.68108 34.6842 6.77039 35.6482 8.04663 36.2968C13.1296 38.8453 15.678 34.7985 15.678 34.7985C16.6298 33.6263 17.4734 32.3703 18.1985 31.0458C19.184 29.3226 19.9642 27.4898 20.5229 25.5848C22.1472 19.9838 21.111 12.6325 21.4471 4.20294C21.5366 3.48693 21.3463 2.764 20.9159 2.18486C20.4854 1.60572 19.8481 1.21507 19.1367 1.09437C19.1367 1.09437 1.21338 0.940345 1.21338 1.87852Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <path opacity="0.4" d="M15.2999 29.1834C16.5181 30.4016 14.8658 34.4624 13.7456 43.424C13.2415 47.5127 13.0595 50.8454 12.9475 52.9737C12.6674 58.1827 12.9475 58.9948 12.5554 65.7021C12.4294 68.0545 12.2613 70.841 11.7573 74.3417C11.3932 76.9181 11.2425 81.6359 11.0465 81.6499C10.8505 81.6639 10.385 74.4257 10.273 71.3731C10.2158 69.7998 10.2438 68.2245 10.357 66.6542C10.567 62.8035 10.469 59.891 10.357 55.5922C10.119 48.941 10.0069 45.6084 9.15278 42.2478C8.29862 38.8872 8.08859 35.5686 7.29044 32.348C7.13641 31.7319 6.75834 30.2336 7.29044 29.5475C7.82254 28.8613 8.6907 29.5475 9.64287 29.7715C12.7514 30.7097 14.2777 28.1752 15.2999 29.1834Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M4.88184 33.4962L5.96003 49.5011C5.96003 49.5011 7.12224 59.1068 8.76054 74.8317C10.3988 90.5566 12.5832 84.0734 12.5832 84.0734L15.0057 72.2833L16.882 51.1254L18.1423 31.0737" fill="#DEE1E9"/>
      <path d="M1.21338 1.87852V10.4341L2.61363 26.607C2.79582 29.0542 3.57465 31.4196 4.88205 33.4963C5.68108 34.6842 6.77039 35.6482 8.04663 36.2968C13.1296 38.8453 15.678 34.7985 15.678 34.7985C16.6298 33.6263 17.4734 32.3703 18.1985 31.0458C19.184 29.3226 19.9642 27.4898 20.5229 25.5848C22.1472 19.9838 21.111 12.6325 21.4471 4.20294C21.5366 3.48693 21.3463 2.764 20.9159 2.18486C20.4854 1.60572 19.8481 1.21507 19.1367 1.09437C19.1367 1.09437 1.21338 0.940345 1.21338 1.87852Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
      <mask id="mask541" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="19" width="17" height="35">
        <path d="M16.3195 53.9736L8.24267 53.9735L3.52673 19.2767H19.1556L16.3195 53.9736Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask541)">
        <path opacity="0.2" d="M15.2999 29.1834C16.5181 30.4016 14.8658 34.4624 13.7456 43.424C13.2415 47.5127 13.0595 50.8454 12.9475 52.9737C12.6674 58.1827 12.9475 58.9948 12.5554 65.7021C12.4294 68.0545 12.2613 70.841 11.7573 74.3417C11.3932 76.9181 11.2425 81.6359 11.0465 81.6499C10.8505 81.6639 10.385 74.4257 10.273 71.3731C10.2158 69.7998 10.2438 68.2245 10.357 66.6542C10.567 62.8035 10.469 59.891 10.357 55.5922C10.119 48.941 10.0069 45.6084 9.15278 42.2478C8.29862 38.8872 8.08859 35.5686 7.29044 32.348C7.13641 31.7319 6.75834 30.2336 7.29044 29.5475C7.82254 28.8613 8.6907 29.5475 9.64287 29.7715C12.7514 30.7097 14.2777 28.1752 15.2999 29.1834Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5041" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="53" width="10" height="30">
        <path d="M12.9208 80.1701L12.2209 82.2051H10.8211L9.833 80.9051L5.30421 53.9738H14.0736L13.7031 65.9246L13.2502 76.4956L12.9208 80.1701Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5041)">
        <path opacity="0.4" d="M15.2999 29.1834C16.5181 30.4016 14.8658 34.4624 13.7456 43.424C13.2415 47.5127 13.0595 50.8454 12.9475 52.9737C12.6674 58.1827 12.9475 58.9948 12.5554 65.7021C12.4294 68.0545 12.2613 70.841 11.7573 74.3417C11.3932 76.9181 11.2425 81.6359 11.0465 81.6499C10.8505 81.6639 10.385 74.4257 10.273 71.3731C10.2158 69.7998 10.2438 68.2245 10.357 66.6542C10.567 62.8035 10.469 59.891 10.357 55.5922C10.119 48.941 10.0069 45.6084 9.15278 42.2478C8.29862 38.8872 8.08859 35.5686 7.29044 32.348C7.13641 31.7319 6.75834 30.2336 7.29044 29.5475C7.82254 28.8613 8.6907 29.5475 9.64287 29.7715C12.7514 30.7097 14.2777 28.1752 15.2999 29.1834Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M19.6048 24.9157C19.0342 26.8342 18.2547 28.6843 17.2804 30.4327L16.0201 50.4703L14.0598 71.6282L11.6233 83.4184C11.6233 83.4184 9.43892 89.9016 7.81463 74.1767C6.19033 58.4518 5.01411 48.8601 5.01411 48.8601L3.96392 32.8411C2.65632 30.7686 1.87296 28.4089 1.68151 25.9659L0.28125 9.77892V1.22335C0.28125 0.299185 18.2045 0.439214 18.2045 0.439214C18.9081 0.566231 19.5364 0.957506 19.9608 1.53286C20.3851 2.10822 20.5734 2.8241 20.4869 3.53378C20.1789 11.9773 21.2291 19.3707 19.6048 24.9157Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
        <path d="M4.22314 32.9841L5.30134 48.9891C5.30134 48.9891 6.46355 58.5948 8.10185 74.3197C9.74015 90.0446 11.9246 83.5614 11.9246 83.5614L14.347 71.7712L16.2233 50.6134L17.4836 30.5617" fill="#DEE1E9"/>
        <path d="M0.554688 1.36631V9.92187L1.95494 26.0948C2.13713 28.542 2.91596 30.9074 4.22336 32.9841C5.02239 34.172 6.1117 35.136 7.38794 35.7846C12.4709 38.3331 15.0193 34.2863 15.0193 34.2863C15.9711 33.1141 16.8147 31.8581 17.5398 30.5336C18.5253 28.8104 19.3055 26.9775 19.8642 25.0726C21.4885 19.4716 20.4523 12.1203 20.7884 3.69073C20.8779 2.97473 20.6876 2.25179 20.2572 1.67265C19.8267 1.09351 19.1894 0.702866 18.478 0.582166C18.478 0.582166 0.554688 0.428137 0.554688 1.36631Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="round"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth81",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
