<template>
<svg width="26" height="27" viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="13" cy="13.5" rx="13" ry="13.5" fill="#EBA5A5"/>
  <path
      d="M13 5.4C8.69929 5.4 5.20001 9.03386 5.20001 13.5C5.20001 17.9661 8.69929 21.6 13 21.6C17.3007 21.6 20.8 17.9661 20.8 13.5C20.8 9.03386 17.3007 5.4 13 5.4ZM13 20.5875C9.23651 20.5875 6.17501 17.4083 6.17501 13.5C6.17501 9.59175 9.23651 6.4125 13 6.4125C16.7635 6.4125 19.825 9.59175 19.825 13.5C19.825 17.4083 16.7635 20.5875 13 20.5875Z"
      fill="#8B2525"/>
  <path
      d="M13 12.9938C12.2073 12.9938 11.5375 12.53 11.5375 11.9813C11.5375 11.4325 12.2073 10.9688 13 10.9688C13.4485 10.9688 13.8639 11.1146 14.1388 11.3677C14.3397 11.553 14.6468 11.5358 14.8272 11.3252C15.0066 11.1166 14.988 10.7966 14.7862 10.6103C14.445 10.2954 13.9867 10.0828 13.4875 9.99878V9.45C13.4875 9.17055 13.2691 8.94375 13 8.94375C12.7309 8.94375 12.5125 9.17055 12.5125 9.45V9.99675C11.401 10.1851 10.5625 11.0032 10.5625 11.9813C10.5625 13.098 11.6565 14.0063 13 14.0063C13.7927 14.0063 14.4625 14.47 14.4625 15.0188C14.4625 15.5675 13.7927 16.0313 13 16.0313C12.5515 16.0313 12.1362 15.8855 11.8612 15.6323C11.6604 15.446 11.3523 15.4643 11.1729 15.6749C10.9935 15.8834 11.012 16.2034 11.2138 16.3897C11.5551 16.7056 12.0133 16.9172 12.5125 17.0022V17.55C12.5125 17.8295 12.7309 18.0563 13 18.0563C13.2691 18.0563 13.4875 17.8295 13.4875 17.55V17.0033C14.599 16.8149 15.4375 15.9968 15.4375 15.0188C15.4375 13.902 14.3436 12.9938 13 12.9938Z"
      fill="#8B2525"/>
</svg>
</template>
