<template>
  <div class="container">
    <div style="height: 100vh" class="d-flex justify-content-center align-items-center">
      <div class="text-center">
        <img src="/images/general/logo.png" alt="logo"/>
        <h4 class="mt-5" style="max-width: 350px;">{{ $t('app_is_in_maintenance_mode') }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SettingsMaintenance"
}
</script>