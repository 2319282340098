// The core Firebase JS SDK is always required and must be listed first
// importScripts('https://www.gstatic.com/firebasejs/8.6.8/firebase-app.js');
//
// importScripts('https://www.gstatic.com/firebasejs/8.6.8/firebase-messaging.js');
// importScripts('https://www.gstatic.com/firebasejs/8.6.8/firebase-analytics.js');
import Vue from 'vue'

import firebase from "firebase/app"
import "firebase/analytics"
import "firebase/messaging"
// import "firebase/notifications"

const firebaseConfig = {
    apiKey: "AIzaSyAKe30TwxPK-I0GWvZ3hEYuF870q3yN-q8",
    authDomain: "dentist-plus-3a565.firebaseapp.com",
    projectId: "dentist-plus-3a565",
    storageBucket: "dentist-plus-3a565.appspot.com",
    messagingSenderId: "1088303696671",
    appId: "1:1088303696671:web:37a119270085fcf8d33e2a",
    measurementId: "G-PEB5QJR6J6"
};
firebase.initializeApp(firebaseConfig)

if(firebase.messaging.isSupported()) {
    firebase.messaging()
}
firebase.analytics()

Vue.prototype.$firebase = firebase
