<template>
  <div class="form-group" v-if="['after_service'].includes(object.notification_type)">
    <ServiceMultiSelect :label="$t('services')"
                        class="mt-2"
                        title="title"
                        :dblclick="true"
                        :dbsearch="true"
                        v-model="object.services"/>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"

export default {
  name: "SettingsDatatableFormServices",
  components: {
    ServiceMultiSelect,
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  methods: {

  },
  mounted() {

  },
  computed: {
  },
  watch: {

  }
}
</script>
