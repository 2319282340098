export default {
    additional: "Додатковий",
    alternative: "Альтернативний",
    amount_completed: "Сума виконана",
    apply_plan_treatment: "Застосувати план лікування",
    are_you_sure_you_want_to_cancel_plan: "Ви впевнені, що хочете скасувати план лікування?",
    calls_planning: "Планування дзвінків",
    choose_criterias_for_fill: "Виберіть потрібні критерії для заповнення",
    comments: "Коментарі",
    completed_sum: "Виконана сума",
    grouped: "Згрупований",
    grouped_by_teeth: "Згрупований по зубах",
    grouped_by_services: "Згрупований по послугах",
    last_update: "Остання зміна",
    level_description: "Опис етапу",
    main_complaints: "Основні скарги",
    not_signed: "Не підписаний",
    notification_date: "Дата повідомлення",
    plan: "План",
    plan_treatment_status: "Статус плану лікування",
    plan_type: "Тип плану",
    recommended: "Рекомендований",
    reset_all: "Скинути все",
    route_list: "Маршрутний лист",
    select_all: "Вибрати все",
    sign_without_upload: "Підписати без завантаження",
    signed_at: "Підписаний",
    total_sum: "Всього сума",
    treatment_plan_amount: "Сума плану лікування",
}
