<template>
  <div v-if="lead && form">
    <a class="pointer text-danger d-inline-flex align-items-center mb-3"
       v-if="rights.includes('crmtask_delete')"
       @click="deleteLead">
      <TrashSvg class="svg-red svg-size-md mr-2"/>{{ $t('delete') }}
    </a>
    <div>
      <b-row>
        <b-col md="4" cols="12">
          {{ $t('status') }}:
          <span class="font-weight-bold"
                v-if="statusObject"
                :style="`color: ${statusObject.color}`">{{ statusObject.translatedTitle }}</span>
          <div>
            <div cols="12">
              {{ $t('responsible') }}:
            </div>
            <b-row class="align-items-center row-narrow">
              <b-col>
                <span v-if="!responsibleEditing">
                  <span v-if="listLeadResponsible(lead)">{{ listLeadResponsible(lead) }}</span>
                </span>
                <ServiceMultiSelect v-if="responsibleEditing"
                                    :dblclick="false"
                                    model="staff"
                                    class="mb-0"
                                    :multiple="true"
                                    title="full_name"
                                    :dbsearch="true"
                                    v-model="form.multiple_responsible"/>
              </b-col>
              <b-col cols="auto">
                <a class="ml-2 pointer"
                   @click="responsibleEditingClick">
                  <CloseSvg class="svg-light svg-size-sm"
                            v-if="responsibleEditing"/>
                  <EditSvg v-if="!responsibleEditing"/>
                </a>
                <a v-if="responsibleEditing"
                   class="pointer px-3"
                   @click="responsibleChanged">
                  <TickSvg class="svg-light svg-size-sm"/>
                </a>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col md="4" cols="12">
          {{ $t('lead_channel') }}: {{ $t(lead.source) }}<br/>
          <b-row class="align-items-center mt-1">
            <b-col cols="auto">
              {{ $t('source') }}:
            </b-col>
            <b-col v-if="clientSources.length">
              <SelectGroup :options="clientSources"
                           class="mb-0 sm w-100"
                           :nullOption="true"
                           :showlabel="false"
                           :required="false"
                           @input="sourceChanged"
                           :label="$t('source')"
                           variant="white"
                           v-model="form.source_id"/>
            </b-col>
          </b-row>
          <div class="mt-1" v-if="lead.utm">UTM: {{ lead.utm }}</div>
        </b-col>
        <b-col md="4" cols="12">
          <div>{{ $t('date_add') }}: {{ formatDateTime(lead.created_at) }}</div>
          <div v-if="lead.record">{{ $t('recorded_on_visit') }}: {{ lead.record.readableStart }}</div>
        </b-col>
      </b-row>
      <hr/>
      <b-row class="align-items-center">
        <b-col>
          <b-row class="align-items-center">
            <b-col cols="auto">
              <div v-if="!titleEditing">
                {{ lead.patient_name }}
                <span v-if="lead.urgent"
                      class="notification-bubble position-relative d-inline-block"
                      style="top: 0; left: 10px"></span>
              </div>
              <b-row>
                <b-col>
                  <InputGroup class="mb-0 sm w-100"
                              v-if="titleEditing"
                              :placeholder="$t('name')"
                              @input="changed = true"
                              v-model="form.fname"/>
                </b-col>
                <b-col>
                  <InputGroup class="mb-0 sm w-100"
                              v-if="titleEditing"
                              :placeholder="$t('surname')"
                              @input="changed = true"
                              v-model="form.lname"/>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="auto">
              <a class="pointer"
                 @click="titleEditingClick">
                <CloseSvg class="svg-light svg-size-sm" v-if="titleEditing"/>
                <EditSvg v-if="!titleEditing"/>
              </a>
              <a v-if="titleEditing"
                 class="pointer px-3"
                 @click="saveChange">
                <TickSvg class="svg-light svg-size-sm"/>
              </a>
            </b-col>
          </b-row>

          <div style="max-height: 24px;">
            {{ $t('phone') }}: <OutgoingCallElement :phone="lead.phone"/>
            <WhatsappTelegramIcon :phone="lead.phone"
                                  :patient-id="form.patient_id"/>
          </div>
          <div>Email: {{ lead.email }}</div>
          <div v-if="callVendor || recordLink">
            <a class="btn btn-themed-squared btn-themed-outline mt-2" @click="listen">{{ $t('listen') }}</a>
          </div>
          <ValidationError validationId="phone" class="mt-3"/>
        </b-col>
        <b-col md="4" cols="12" v-if="possiblePatients.length">
          <div>{{ $t('by_phone_found', { phone: lead.phone}) }}:</div>
          <a class="d-block text-underlined pointer"
             v-for="p in possiblePatients"
             @click="lead.patient = p; form.patient_id = p.id; changed = true"
             :key="`pat-p-${p.id}`">{{ p.full_name }} ({{ p.phone }})
          </a>
        </b-col>
        <b-col md="auto" cols="12">
          <ServiceMultiSelect :label="$t('patient')"
                              :dblclick="false"
                              model="patient"
                              :multiple="false"
                              :readonly="['recorded'].includes(lead.status)"
                              title="full_name"
                              class="mb-1"
                              @input="patientSelected"
                              :dbsearch="true"
                              v-model="lead.patient"/>
          <button class="btn-themed btn-success btn-themed-squared mt-2"
                  v-if="!['recorded'].includes(lead.status)"
                  @click="recordPatient">
            {{ $t('record_on_visit') }}
          </button>
          <div v-if="lead.patient" class="mt-1">
            <router-link :to="{ name: 'PatientVisits', params: { id: lead.patient.id } }">{{ $t('patient_card') }}</router-link>
          </div>
        </b-col>
      </b-row>
      <hr/>
      <div class="mb-3 overflow-scroll nowrap">
        <div class="d-flex flex-nowrap">
          <button v-for="l in CRMStages"
                  class="btn-themed btn-themed-outline btn-themed-squared mr-2 lead-status-btn"
                  :style="`border-color: ${l.color}; color: ${l.color};`"
                  @click="changeStatus(l)"
                  :key="`le-${l.id}`">
            {{ l.translatedTitle }}
          </button>
        </div>
      </div>
      <b-row>
        <b-col md="6">
          <div>
            <AutosizeTextArea v-model="form.reason"
                              @input="changed = true"
                              :label="$t('application_reason')"/>
          </div>
          <div>
            <AutosizeTextArea v-model="form.comment"
                              @input="changed = true"
                              :label="$t('client_comment')"/>
          </div>
          <DateGroup :label="$t('recall')"
                     :readonly="!!form.call_date"
                     @input="changed = true"
                     :clearable="true"
                     v-if="form.call_date"
                     v-model="form.call_date"/>
          <GeneralCommentsBlock :object="lead"
                                :black-label="false"
                                model="lead_comment"
                                parent-column="lead_id"/>
        </b-col>
        <b-col md="6">
          <b-row class="mb-3">
            <b-col>
              <button class="btn btn-themed btn-themed-outline btn-themed-squared w-100"
                      @click="addRecallTask">
                {{ $t('recall') }}
              </button>
            </b-col>
            <b-col>
              <button class="btn btn-themed btn-themed-outline btn-themed-squared w-100"
                      @click="addTask">
                {{ $t('add_task') }}
              </button>
            </b-col>
          </b-row>
          <div class="mt-3 mb-3" v-if="lead.tasks && lead.tasks.length">
            <TaskTeaser v-for="(task, tInd) in lead.tasks"
                        :show-info="false"
                        @loadTasks="parse"
                        :key="`task-${task.id}-${tInd}`"
                        :task="task"/>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="modal-footer-buttons no-print text-right mt-4">
      <b-button class="cancel mr-md-3 mr-2 px-3 d-inline-block align-middle w-auto btn-themed-squared btn-themed-outline"
                variant="themed"
                @click="edit">
        {{ $t('edit') }}
      </b-button>
      <button :disabled="loading"
              @click="finished"
              class="btn-themed align-middle btn-themed-squared">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import TaskTeaser from "@/components/parts/header/TaskTeaser.vue"
import ValidationError from "@/components/form/ValidationError.vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import DateGroup from "@/components/form/DateGroup.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import GeneralCommentsBlock from "@/components/parts/general/GeneralCommentsBlock.vue"
import EditSvg from "@/assets/svg-vue/general/edit.svg.vue"
import TrashSvg from '@/assets/svg-vue/general/trash_tr.svg'
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import TickSvg from "@/assets/svg-vue/form/tick.svg.vue"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"
import InputGroup from "@/components/form/InputGroup.vue"
import CloseSvg from "@/assets/svg-vue/general/close.svg.vue"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"
import { useStore } from "vuex"
import { formatDateTime } from "@/extensions/filters/filters"
import { BinotelService, EloquentService, PatientService } from "@/services/api.service"
import moment from "moment/moment"
import { computed, getCurrentInstance, nextTick, onBeforeMount, onBeforeUnmount, onMounted, ref, watch} from "vue"
import { appendFormdata, copyObject} from "@/extensions/prototypes/prototypes"
import { useRoute, useRouter } from "vue-router"

const store = useStore()
const route = useRoute()
const router = useRouter()

const form = ref(null)
const changed = ref(false)
const possiblePatients = ref([])
const responsibleEditing = ref(false)
const titleEditing = ref(false)

const loading = computed(() => store.state.dom.loading)
const rightPaneOpened = computed(() => store.state.dom.rightPaneOpened)
const lead = computed(() => store.state.crm.lead)
const clientSources = computed(() => store.state.auth.clientSources)
const user = computed(() => store.state.auth.user)
const counter = computed(() => store.state.datatable.dataTableCounter)
const CRMStages = computed(() => store.state.auth.CRMStages)
const rights = computed(() => store.state.auth.rights)
const listLeadResponsible = computed(() => store.getters.listLeadResponsible)
const recordLink = computed(() => {
  if(lead.value?.record_link) return lead.value?.record_link
  return lead.value?.patient_comment?.variables?.record_link || null
})
const callVendor = computed(() => lead.value?.patient_comment?.variables?.vendor || null)
const statusObject = computed(() => CRMStages.value.find(status => status.id === form.value?.status_id) || null)

let bvModal = null
let noty = null
let i18n = null

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  if(!lead.value) {
    store.commit('setLead', copyObject({ id: route.params.id }))
  }
})

onMounted(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
  noty = instance?.appContext?.app?.noty
  parse()
})

const edit = async () => {
  bvModal.hide('lead-show-modal')
  const newObject = JSON.parse(JSON.stringify(lead.value))
  store.commit('setModule', 'crm')
  store.commit('setSelectedModel', newObject)
  store.commit('setViewModel', 'lead')
  await nextTick()
  store.commit('setShowModal', true)
}

const saveChange = async () => {
  store.commit('cleanValidationErrors')
  try {
    let formData = new FormData()
    const formTmp = copyObject(form.value)
    if(formTmp.source_id && !formTmp.source) {
      formTmp.source = clientSources.value.find(obj => Number(obj.id) === Number(formTmp.source_id)).title
    }
    formTmp.multiple_responsible = formTmp.multiple_responsible.map(obj => obj.id)
    formData = appendFormdata(formData, formTmp)
    await EloquentService.update('lead', lead.value.id, formData)
    changed.value = false
    responsibleEditing.value = false
    titleEditing.value = false
    store.commit('incrementDataTableCounter')
  } catch (e) {
    noty.error(e?.response?.data?.message || i18n.t('error'))
  }
}

const parse = async () => {
  if(!lead.value) return
  possiblePatients.value = []
  if(!clientSources.value.length) store.dispatch('getClientSources')
  if(!CRMStages.value.length) store.dispatch('getCRMStages')
  const res = await EloquentService.show('lead', lead.value.id)
  const data = res.data
  store.commit('setLead', copyObject(data))
  form.value = {
    reason: data.reason,
    comment: data.comment,
    manual_title: data.manual_title,
    source: data.source || null,
    source_id: data.source_id,
    fname: data.fname,
    lname: data.lname,
    phone: data.phone,
    patient_id: data.patient_id,
    call_date: data.call_date,
    status_id: data.status_id,
    multiple_responsible: copyObject(data.multiple_responsible)
  }
  if(!lead.value.patient) {
    possiblePatients.value = res.data?.possiblePatients || []
    if(possiblePatients.value.length === 1) lead.value.patient = possiblePatients.value[0]
  }
}

const changeStatus = async(status) => {
  if(status.id === 4 && form.value.patient_id) { // id = 4 is recorded
    const res = await PatientService.patientHasFutureRecords(form.value.patient_id)
    if(!res.data) {
      const res = confirm(i18n.t('patient_has_no_future_visits_continue'))
      if(!res) return;
    }
  }
  form.value.status_id = status.id
  lead.value.status_id = status.id
  await saveChange()
}

const sourceChanged = async () => {
  await nextTick()
  await saveChange()
  lead.value.source_id = form.value.source_id
}

const recordPatient = async () => {
  if(lead.value.patient && Number(lead.value.patient.id) !== Number(form.value.patient_id)) {
    form.value.patient_id = lead.value.patient.id
    await saveChange()
  }
  router.push({ name: 'Calendar' }).catch(() => {})
  if(lead.value.patient) {
    store.commit('setPatientFromCall', copyObject(lead.value.patient))
  }
  store.commit('setLeadForRecord', copyObject(lead.value))
  bvModal.hide('lead-show-modal')
}

const listen = async () => {
  if(recordLink.value) {
    window.open(recordLink.value, '_blank')
    return
  }
  if(callVendor.value === 'binotel' && lead.value?.patient_comment?.variables?.id) {
    const res = await BinotelService.getCallRecording(lead.value.patient_comment.variables.id)
    if(res.data?.url) {
      window.open(res.data.url, '_blank')
    } else {
      noty.error(i18n.t('record_not_available'))
    }
  }
  if(callVendor.value === 'uis' && lead.value?.patient_comment?.id) {
    const res = await BinotelService.getUISCallRecording(lead.value?.patient_comment?.id)
    if(res.data?.url) {
      window.open(res.data.url, '_blank')
    } else {
      noty.error(i18n.t('record_not_available'))
    }
  }
}

const patientSelected = (p) => {
  if(!p || form.value.patient_id === p.id) return
  form.value.patient_id = p.id
  changed.value = true
}

const onHide = () => {
  responsibleEditing.value = false
  store.commit('cleanValidationErrors')
  changed.value = false
}

onBeforeUnmount(() => {
  onHide()
})

const finished = async() => {
  if(changed.value) await saveChange()
  bvModal.hide('lead-show-modal')
}

const responsibleEditingClick = () => {
  if(!responsibleEditing.value) {
    responsibleEditing.value = true
    return
  }
  if(responsibleEditing.value) {
    responsibleEditing.value = false
    form.value.multiple_responsible = copyObject(lead.value.multiple_responsible)
  }
}

const titleEditingClick = () => {
  if(!titleEditing.value) {
    titleEditing.value = true
    return
  }
  if(titleEditing.value) {
    titleEditing.value = false
    form.value.fname = lead.value.fname
    form.value.lname = lead.value.lname
  }
}

const responsibleChanged = async () => {
  await nextTick()
  await saveChange()
  lead.value.multiple_responsible = copyObject(form.value.multiple_responsible)
}

const addRecallTask = () => {
  store.commit('setModule', 'crm')
  store.commit('setViewModel', 'task')
  store.commit('setSelectedModel', {
    lead_id: lead.value.id,
    task_type: 'call',
    title: i18n.t('recall_on_lead', { title: lead.value.title }),
    deadline: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
    start: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
    patient_id: lead.value.patient_id,
    patient: lead.value.patient,
    phone: lead.value.phone || '',
    importance: 'high',
    user: user.value,
    user_id: user.value.id
  })
  store.commit('setShowModal', true)
}

const addTask = () => {
  store.commit('setModule', 'crm')
  store.commit('setViewModel', 'task')
  store.commit('setSelectedModel', {
    lead_id: lead.value.id,
    patient_id: lead.value.patient_id,
    patient: lead.value.patient,
  })
  store.commit('setShowModal', true)
}

const deleteLead = async () => {
  if(!confirm(i18n.t('confirm_deletion'))) return
  await EloquentService.delete('lead', lead.value.id)
  store.commit('setLead', null)
  bvModal.hide('lead-show-modal')
  noty.info(i18n.t('success_message'))
  store.commit('incrementDataTableCounter')
}

watch(rightPaneOpened, async (val) => {
  if(val) {
    bvModal.hide('lead-show-modal')
  }
})
watch(counter, () => {
  parse()
})


</script>

<style scoped lang="scss">
.lead-status-btn {
  line-height: 30px;
  border-width: 3px;
}
@media screen and (max-width: 768px) {
  .lead-status-btn {
    line-height: 24px;
  }
}
</style>
