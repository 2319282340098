export default {
    agreement_print: "Print agreement",
    agreement_template: "Agreement template",
    agreements: "Agreements",
    are_you_sure_you_want_to_delete_template: "Are you sure you want to delete the template?",
    bite_templates: "Dental occlusion",
    choose_fields_for_autocomplete: "Select the required values for autofill:",
    complaints_templates: "Complaints",
    consents: "Consents",
    contracts: "Contracts",
    contract_template: "Contract template",
    development: "Development",
    development_templates: "Development",
    diseases: "Diseases",
    diseases_templates: "Diseases",
    document_template_word_file_note: "You can attach a word file as a template. In word file placeholders must be marked with $ sign and curly braces and only capital letters should be used. For example: ${FIO_CLIENT}.",
    full_treatment_template: "Еreatment pattern",
    general_template: "Template",
    generate: "Generate",
    generated: "Generated",
    inspection_templates: "Examination",
    medication_templates: "Treatment",
    not_generated: "Not generated",
    objectively_templates: "Objectively",
    plansurvey_templates: "Survey plan",
    plansurvey: "Survey plan",
    plan_treatment_template: "Treatment plan pattern",
    recommendations_templates: "Recommendations",
    research: "Research data",
    researches: "Research",
    research_templates: "Research data",
    see_in_trustme: "See in TrustMe",
    sign_via_trustme: "Sign via TrustMe",
    signed: "Signed",
    signed_via_trustme: "Signed via TrustMe",
    subjectively_templates: "Subjectively",
    teeth_medication: "Treatment",
    teeth_plansurvey: "Examination plan",
    teeth_recommendations: "Recommendations",
    templates_treatments_categories: "Treatment templates' categories",
    upload_signed: "Upload signed",
    uploaded: "Uploaded"
}
