<template>
  <div>
    <b-row class="align-items-center">
      <b-col cols="5">
        <label class="form-label">{{ $t(label) }}</label>
      </b-col>
      <b-col cols="7">
        <div class="form-value">
          <b-row>
            <b-col>
              {{ title ? title : '-' }}
              <slot/>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
export default {
  name: "PatientColInline",
  props: {
    title: {},
    label: String
  }
}
</script>

<style scoped lang="scss">
  .form-label {
    font-size: 14px;
    color: #AEBFD0;
    margin-bottom: 0;
  }
  .form-value {
    margin-bottom: 5px;
  }

  @media screen and (max-width: 768px) {
    .form-label {
      font-size: 11px;
    }
  }
</style>
