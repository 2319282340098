<template>
  <b-modal id="planning-template-options-modal"
           size="lg"
           @show="onShow"
           @hide="onHide"
           :hide-footer="!template"
           :title="$t('treatment_template')+':'"
           centered>
    <div v-if="!template">
      <SelectGroup :showlabel="true"
                   :label="$t('general_template')"
                   @input="templateSelected"
                   :translate="false"
                   :options="templates"
                   variant="white"/>
    </div>
    <div v-if="template">
      <div class="font-weight-bold mb-4">{{ template.title }}</div>
      <b-row class="row-narrow align-items-center mb-4">
        <b-col cols="auto">
          <ToothSvg/>
        </b-col>
        <b-col cols="auto">
          <div class="font-weight-bold">
            {{ $t('apply_to_teeth') }}:
          </div>
        </b-col>
        <b-col>
          <div class="pl-2 position-relative" style="top: 1px">
            <div v-for="(t, tind) in teeth"
                 :key="`tem-t-${t}`"
                 :class="{ 'ml-3': tind > 0 }"
                 class="d-inline-block">
              <input type="checkbox"
                     :id="`st-${t}`"
                     :value="t"
                     v-model="appliedTeeth"/>
              <label class="ml-1 mb-0" :for="`st-${t}`">{{ filterTeeth(t) }}</label>
            </div>
            <div class="ml-3 d-none">
              <input type="checkbox"
                     id="general"
                     value="general"
                     v-model="appliedTeeth"/>
              <label class="ml-1 mb-0" for="general">{{ $t('general') }}</label>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="mt-4 mb-3">{{ $t('choose_fields_for_autocomplete') }}</div>
      <div v-for="(tg) in templateGroups"
           :key="`template-g-${tg}`">
        <div class="option-row" v-if="template[tg]">
          <CheckboxGroup :label="$t(tg)"
                         class="sm mb-0 opaque"
                         :checkboxvalue="tg"
                         :fid="`option-${tg}`"
                         v-model="selectedOptions" />

          <div class="small mb-3 pl-5">
            <div v-if="tg === 'services'">
              <div>
                <span class="d-inline-block mr-2 tag-code"
                      v-for="s in template.services"
                      :key="`si-i-${s.id}`">
                  {{ s.title }} x {{ s.pivot.quantity }}
                </span>
              </div>
              <div v-if="template.complex_services.length" class="mt-1">
                {{ $t('complex_services') }}:
                <span class="d-inline-block mr-2 tag-code"
                      v-for="s in template.complex_services"
                      :key="`si-i-${s.id}`">
                  {{ s.title }}
                </span>
              </div>
            </div>
            <div v-else-if="tg === 'diagnoses'">
            <span class="d-inline-block mr-2 tag-code"
                  v-for="d in template.diagnoses"
                  :key="`di-i-${d.id}`">{{ d.title }}</span>
            </div>
            <div v-else>
              {{ template[tg] }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle" variant="themed" @click="close()">{{ $t('cancel') }}</b-button>
        <button @click="save" class="btn-themed align-middle btn-themed-squared">{{ $t('save') }}</button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import { planTemplateGroups } from "@/dictionaries/dictionary"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { EloquentService } from "@/services/api.service"
import ToothSvg from '@/assets/svg-vue/patients/tooth.svg'
import SelectGroup from "@/components/form/SelectGroup.vue"
import { filterTeeth } from "@/extensions/filters/filters"

export default {
  name: "PlanningTemplateOptionsModal",
  components: {
    SelectGroup,
    CheckboxGroup,
    ToothSvg
  },
  props: {
    object: {},
    templates: {}
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
      teeth:  state => state.teeth.recordTeeth,
    })
  },
  data() {
    return {
      template: null,
      templateGroups: planTemplateGroups,
      selectedOptions: [],
      appliedTeeth: []
    }
  },
  methods: {
    filterTeeth,
    onShow() {
      this.template = null
      this.selectedOptions = []
    },
    onHide() {
      this.template = null
      this.selectedOptions = []
    },
    async templateSelected(val) {
      let res = await EloquentService.show('plan_treatment_template', val)
      this.template = res.data
      this.appliedTeeth = this.copyObject(this.teeth)
      if(this.template.services.length) {
        this.selectedOptions.push('services')
      }
      if(this.template.diagnoses.length) {
        this.selectedOptions.push('diagnoses')
      }
      this.templateGroups.forEach(item => {
        if(this.template[item] && !['services', 'diagnoses'].includes(item)) {
          this.selectedOptions.push(item)
        }
      })
    },
    async save() {
      this.$emit('applyTemplate', {
        template: this.template,
        options: this.selectedOptions,
        teeth: this.appliedTeeth
      })
      this.$bvModal.hide('planning-template-options-modal')
    },
  }
}
</script>
