export let ViewType = Object.freeze({
    DOCTOR: "DOCTOR",
    CHAIR: "CHAIR",
});

export let ViewRange = Object.freeze({
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH",
});
