<template>
  <div>
    <template v-if="object[field.id] && object[field.id] > 0">
      {{ object[field.id] }}
    </template>
    <template v-else>Неограниченно</template>
  </div>
</template>

<script>
export default {
  name: "LimitationTD",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
