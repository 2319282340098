<template>
  <div class="mt-3 pb-5" v-if="rows">
    <b-row>
      <b-col xl="5" lg="6" md="8" sm="9">
        <div class="card">
          <div>
            <table class="sub-items">
              <tbody>
              <tr class="font-weight-bold">
                <td>{{ $t('opening_balance') }}</td>
                <td>{{ formatNumber(rows.openingBalance, true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('paid_invoices') }} <span class="text-lowercase">({{ $t('cash') }})</span></td>
                <td>{{ formatNumber(rows.cash_sales, true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('balance_refill') }} <span class="text-lowercase">({{ $t('cash') }})</span></td>
                <td>{{ formatNumber(rows.deposit_cash_fills, true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('additional_income') }} <span class="text-lowercase">({{ $t('cash') }})</span></td>
                <td>{{ formatNumber(rows.otherProfitCash, true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('materials') }} <span class="text-lowercase">({{ $t('cash') }})</span></td>
                <td>{{ formatNumber((-rows.materials), true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('salaries') }} <span class="text-lowercase">({{ $t('cash') }})</span></td>
                <td>{{ formatNumber((-rows.salaries), true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('other_expense') }} <span class="text-lowercase">({{ $t('cash') }})</span></td>
                <td>{{ formatNumber((-rows.otherExpenseCash), true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('filling') }} (<a class="text-primary pointer" @click="addMovement('injection')"><span class="text-lowercase">{{$t('add')}}</span></a>)</td>
                <td>{{ formatNumber(rows.injections, true) }}</td>
              </tr>
              <tr>
                <td>{{ $t('withdrawal') }} (<a class="text-primary pointer" @click="addMovement('withdrawal')"><span class="text-lowercase">{{$t('add')}}</span></a>)</td>
                <td>{{ formatNumber((-rows.withdrawals), true) }}</td>
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <td>{{ $t('closing_balance') }}</td>
                <td>{{ formatNumber((rows.openingBalance + rows.total_change), true) }}</td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </b-col>
      <b-col xl="12">
        <div class="mt-4">{{ $t('filling') }} / {{ $t('withdrawal') }}:</div>
        <DataTable :options="options"
                   ref="dataTable"
                   class="mt-1"
                   :params="{ date: this.from, branch_id: branchId }"
                   module="accounting"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/accounting/cashdesk_movement"
import {formatNumber} from "@/extensions/filters/filters"

export default {
  name: "ReportCashdesk",
  components: {
    DataTable
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('cashdesk_report'),
    }
  },
  data() {
    return {
      rows: null,
      options: options,
    }
  },
  computed: {
    ...mapState({
      counter: state => state.datatable.dataTableCounter,
      branchId: state => state.auth.branch,
    }),
  },
  methods: {
    formatNumber,
    async loadReport() {
      const res = await ReportsService.loadReport('cashdesk_report', {
        from: this.from,
        to: this.from,
        branch_id: this.selectedBranches,
      })
      this.rows = res.data
      await this.$nextTick()
      this.$refs.dataTable.load()
    },
    addMovement(type) {
      this.$store.commit('setViewModel', 'cashdesk_movement')
      this.$store.commit('setModule', 'accounting')
      this.$store.commit('setSelectedModel', {
        operation_type: type
      })
      this.$store.commit('setShowModal', true)
    }
  },
  mounted() {
    this.loadReport()
    this.$store.commit('setEloquentOptions', options)
  },
  watch: {
    counter() {
      this.loadReport()
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  padding: 16px;
  border: none;
  background: #FFFFFF;
  box-shadow: 0 1px 10px -3px rgba(78, 78, 78, 0.25);
  border-radius: 5px;
}
.sub-items {
  width: 100%;
  tfoot {
    td {
      font-weight: bold;
    }
    tr {
      &:last-of-type {
        border-top: 1px solid $calendar-border-color;
        border-bottom: none;
      }
    }
  }
  tbody, tfoot {
    td:last-of-type {
      text-align: right;
    }
  }
  tr {
    border-bottom: 1px solid $calendar-border-color;
  }
  tbody {
    tr {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }
  td {
    padding: 5px 3px;
  }
}

@media screen and (max-width: 768px) {
}
</style>
