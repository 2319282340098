<template>
  <div>
    {{ object.f043 ? '+' : '' }}
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDF043",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>