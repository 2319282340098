<template>
  <div>
    <router-link class="tdclickable" :to="{ name: 'WarehousesDocumentItem', params: { id: object.id } }">
      {{ object.title }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "WarehouseDatatableTDDocumentTitle",
  props: {
    object: {
      type: Object
    }
  },
  methods: {

  }
}
</script>

<style scoped>

</style>