<template>
  <div class="d-doc-line" :class="checkedClass">
    <div class="d-flex user-select-none">
      <div class="d-doc__img" v-bind:style="{
            'background-image': 'url(' + doc.photo + ')',
            'border-color': doctorColor(doc)
            }" />
      <div class="d-doc__name flex-grow-1">{{ doc.lname }} {{ doc.fname }}</div>
      <CheckboxGroup :fid="`${doc.id}-doc-check`"
                     :checkboxvalue="doc.id"
                     ref="checkbox"
                     class="mb-0 sm mt-1"
                     @input="$emit('input', selected)"
                     v-model="selected"/>
    </div>
  </div>
</template>

<script>
import CheckboxGroup from "@/components/form/CheckboxGroup"
export default {
  name: "DDoctorItem",
  components: {
    CheckboxGroup
  },
  props: {
    doc: Object,
    checked: Boolean,
    value: Array,
  },
  data() {
    return {
      selected: [],
    }
  },
  computed: {
    checkedClass() {
      return this.value.includes(this.doc.id)
    }
  },
  methods: {
    doctorColor(doctor) {
      return doctor.color ? doctor.color : '#fff';
    },
    docPressed() {
      // console.log(this.$refs.checkbox.$el)
      this.$refs.checkbox.$el.click()
    }
  },
  mounted() {
    this.selected = this.value
  },
  watch: {
    value(val) {
      this.selected = val
    }
  }
}
</script>

<style scoped lang="scss">
.unselected {
  opacity: .6;
}
.d-doc-line {
  padding: 10px 15px;
  cursor: pointer;
}
.d-doc-line:hover {
  opacity: 1;
}
.d-doc__img {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border:2px solid white;
  background-size: cover;
  margin-right: 10px;
}
.d-doc__name {
  padding-top:3px;
  font-weight: bold;
  color: #5D6D7C;
}

@media screen and (max-width: 768px) {
  .d-doc-line {
    padding: 8px 13px;
  }
}
</style>
