<template>
  <div>
    <div v-if="!initiated" class="text-center">
      <b-spinner></b-spinner>
    </div>
    <div v-if="initiated">
      <div v-if="!pluginSupported" class="alert alert-danger">Ваш браузер не поддерживает работу Крипто-Про. Следуйте инструкции по <a href="https://www.cryptopro.ru/sites/default/files/products/cades/demopage/main.html" target="_blank">ссылке</a></div>
      <div  v-if="pluginSupported">
        <div class="text-primary mb-3">Ваш браузер поддерживает работу Крипто-Про.</div>

        <div v-if="!certificates.length" class="alert alert-danger">У вас не установлен ни один сертификат для подписи</div>

        <div class="font-weight-bold mb-3">Выберите сертификат для подписи:</div>

        <label class="radio-group mb-4"
               v-for="cert in certificates"
               :key="`cert-${cert.id}`">
          <div>
            <div class="text-black">{{ cert.title }}</div>
            <div><span>{{ cert.valid ? '&#9989;' :	'&#10060;' }}</span> Годен до: {{ cert.validTo }}</div>
          </div>
          <input type="radio"
                 :value="cert.id"
                 v-model="selectedCertificate"
          />
          <span class="radiomark"></span>
        </label>
        <div class="mt-5">
          <button class="btn-themed btn-themed-squared btn-themed-outline w-100"
                  :disabled="!selectedCertificate || !dataToSign || loading"
                  @click="sign">
            {{ $t('sign') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  isValidSystemSetup,
  getUserCertificates,
  createDetachedSignature,
  createHash
} from 'crypto-pro'
import { ref, defineEmits, onMounted } from 'vue'

const selectedCertificate = ref(null)
const certificates = ref([])
const pluginSupported = ref(false)
const initiated = ref(false)
const loading = ref(false)

const emit = defineEmits(['signed'])

const props = defineProps({
  dataToSign: {
    type: Array,
    required: true,
  }
})

const initCertificates = async() => {
  pluginSupported.value = await isValidSystemSetup()
  let tmpCertificates
  try {
    tmpCertificates = await getUserCertificates()
  } catch (e) {
    tmpCertificates = []
  }
  certificates.value = []
  try {
    for(const cert of tmpCertificates) {
      // const isValid = await cert.isValid()
      certificates.value.push({
        id: cert.thumbprint,
        title: cert.subjectName,
        validTo: cert.validTo,
        // valid: isValid && (cert.validTo > (new Date()).toISOString())
        valid: cert.validTo > (new Date()).toISOString()
      })
    }
  } catch (e) {
    console.log(e)
  }
  initiated.value = true
}

onMounted(() => {
  initCertificates()
})

const sign = async () => {
  if(!selectedCertificate.value) return
  loading.value = true
  const messages = props.dataToSign
  let signatures = []
  for(const message of messages) {
    const messageHash = await createHash(message.dataToSign)
    try {
      const signature = await createDetachedSignature(selectedCertificate.value, messageHash)
      signatures.push({
        signature: signature.replace(' ', '').replace(/(\r\n|\n|\r)/gm, ""),
        record: message.record
      })
    } catch (e) {
      console.log(e)
      signatures = []
      break;
    }
  }
  loading.value = false
  emit('signed', signatures)
}
</script>
