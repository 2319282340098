import { notificationRoles, typesOfNotifications } from "@/dictionaries/dictionary"
import { notificationSettingAdapter } from "@/adapters/settings/notificationSetting"

export default {
    primaryKey: "id",
    model: 'notification_setting',
    order: 'title',
    modalsize: 'lg',
    prepareParams: ['id'],
    sort: 'asc',
    adapter: notificationSettingAdapter,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    deletemessage: 'are_you_sure_you_want_to_delete_notification',
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
        },
        {
            id: 'notification_type',
            title: 'notification_type',
            table: true,
            sortable: true,
            format_translate: true,
            form: {
                type: "select",
                required: true,
                select_type: "list",
                classes: 'col-md-6',
                options: typesOfNotifications
            }
        },
        {
            id: 'recipient_role_id',
            title: 'recipient',
            table: true,
            sortable: true,
            format_translate: true,
            tdcomponent: "SettingsDatatableTDNotificationRole",
            form: {
                type: "select",
                required: true,
                select_type: "list",
                classes: 'col-md-6',
                options: notificationRoles
            }
        },
        {
            id: 'text',
            title: 'message',
            table: false,
            form: {
                type: "textarea",
                max_length: 850,
                required: true
            }
        },
        {
            id: 'created_at',
            table: false,
            form: {
                component: "SettingsDatatableFormServices"
            }
        },
        {
            id: 'notification_channels',
            title: 'title',
            table: false,
            form: {
                type: "text",
                required: true,
                component: "SettingsDatatableFormChannels"
            },
        },
        {
            id: 'active',
            title: 'active',
            table: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]
}
