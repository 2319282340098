<template>
  <b-modal id="wazzup-iframe-modal"
           :title="`Wazzup`"
           size="xl"
           hide-footer
           centered>
    <iframe class="wazzup-iframe" :src="wazzupIframeUrl" allow="microphone *" ></iframe>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"

export default {
  name: "WazzupIframeModal",
  components: {
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
      wazzupIframeUrl: state => state.crm.wazzupIframeUrl,
      // rights: state => state.auth.rights,
    }),
  },
  data() {
    return {

    }
  },
  methods: {
    
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
.wazzup-iframe {
  width: 100%;
  border: 1px solid #eee;
  height: calc(100vh - 200px);
}
</style>
