export default {
    primaryKey: 'planning_id',
    model: 'call_planning',
    modaltitle: 'calls_planning',
    order: 'planning_id',
    sort: 'desc',
    actionscomponent: 'PlanningPatientDatatableActions',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "excel",
        "delete"
    ],
    // rights: {
        // delete: "patients",
    // },
    fields: [
        {
            id: 'planning_id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'client_id',
            title: 'patient_fio',
            table_show: 'patient.full_name',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                component: "PatientsDatatableFormWaitingClient"
            },
            tdcomponent: 'PatientsDatatableTDName',
        },
        {
            id: 'user_add_id',
            title: 'added_by',
            table_show: 'author.full_name',
            table: true,
            sortable: true,
            filter: true,
            // filter_model_select: true,
            // tabletdclass: "order-mob-0",
            // form: {
            //     type: "text",
            //     required: true,
            //     component: "PatientsDatatableFormWaitingClient"
            // },
            // tdcomponent: 'PatientsDatatableTDName',
        },
        {
            id: 'client_id',
            title: 'phone',
            table: true,
            sortable: true,
            // filter: true,
            table_show: 'patient.phone',
            format_phone: true,
            right_excludes: 'hide_patient_phone',
        },
        {
            id: 'date_show',
            title: 'notification_date',
            table: true,
            sortable: true,
            format_date: true,
            form: {
                type: "date",
                required: true
            },
            filter: true,
            filter_date: true
        },
        {
            id: 'date_add',
            title: 'creation_date',
            table: true,
            sortable: true,
            format_date: true,
            filter: true,
            filter_date: true
        },
        {
            id: 'message',
            title: 'message',
            table: true,
            sortable: true,
            filter: true,
            form: {
                // classes: 'col-md-4',
                required: true,
                type: "textarea"
            }
        },
        {
            id: 'mphone',
            title: 'phone',
            filter: true
        },
    ]
}
