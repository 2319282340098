<template>
  <span>
  <router-link
      v-if="!isPatient && isActive && belongsToCurrentBranch"
      :title="$t('edit')"
      v-b-tooltip.hover
      class="data-table-action"
      :to="{name: 'PatientVisitPage', params: { visitid: object.id, id: object.client_id, page: 'description' } }">
    <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
  </router-link>
    <a class="data-table-action"
       v-if="!isPatient && rights.includes('accept_payments') && isActive && belongsToCurrentBranch"
       :title="$t('payment')"
       v-b-tooltip.hover
       @click="payment">
      <DollarSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('payment') }}</span>
    </a>
    <a class="data-table-action"
       v-if="!isPatient && rights.includes('agreements') && isActive && belongsToCurrentBranch"
       @click="agreementPressed"
       :title="$t('agreement')"
       v-b-tooltip.hover>
      <ContractSvg/><span class="action-button-mob-title">{{ $t('agreement') }}</span>
    </a>
    <a class="data-table-action"
       :title="$t('print')"
       v-if="isActive"
       v-b-tooltip.hover
       @click="printVisit">
      <PrintSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('print') }}</span>
    </a>
    <b-dropdown right
                v-if="object.id && isActive && belongsToCurrentBranch"
                variant="link"
                no-caret
                toggle-tag="span"
                toggle-class="action-0"
                class="data-table-action">
      <template #button-content>
        <a v-b-tooltip.hover :title="$t('akt_print')">
          <NoteSvg/><span class="action-button-mob-title">{{ $t('akt_print') }}</span>
        </a>
      </template>
          <b-dropdown-item @click="printAkt('admin')">{{ $t('manager') }}</b-dropdown-item>
          <b-dropdown-item @click="printAkt('doctor')">{{ $t('doctor') }}</b-dropdown-item>
    </b-dropdown>
    <a class="data-table-action"
       :title="isActive ? $t('cancel') : $t('restore')"
       v-b-tooltip.hover
       v-if="belongsToCurrentBranch && canDelete"
       @click="cancelRecoverVisit">
      <DeleteSvg v-if="isActive" class="svg-light"/><RestoreSvg v-if="!isActive" class="svg-light"/><span class="action-button-mob-title">{{ isActive ? $t('cancel') : $t('restore') }}</span>
    </a>
    <OptionsPopup v-if="optionsModalOpen"
                  :title="$t('choose_signer')"
                  @changed="modalResolve"
                  :options="signerOptions"/>
    </span>
</template>

<script>

import EditSvg from '@/assets/svg-vue/general/edit.svg'
import PrintSvg from '@/assets/svg-vue/general/print2.svg'
import ContractSvg from '@/assets/svg-vue/patients/contract.svg'
import DollarSvg from '@/assets/svg-vue/patients/dollar.svg'
import NoteSvg from '@/assets/svg-vue/header/note.svg'
import DeleteSvg from '@/assets/svg-vue/general/delete.svg'
import RestoreSvg from '@/assets/svg-vue/accounting/back.svg'
import JwtService from "@/services/jwt.service"
import {mapState} from "vuex"
import {CalendarService} from "@/services/api.service"
import OptionsPopup from "@/components/parts/general/OptionsPopup.vue"

export default {
  name: "PatientDatatableRecordActions",
  components: {
    OptionsPopup,
    EditSvg,
    ContractSvg,
    DollarSvg,
    PrintSvg,
    NoteSvg,
    DeleteSvg,
    RestoreSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  data() {
    return {
      optionsModalOpen: false,
      modalResolve: () => {}
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      patient: state => state.patient.patient,
      rights: state => state.auth.rights,
      branchId: state => state.auth.branch
    }),
    isPatient() {
      return this.$store.getters.isPatient
    },
    isActive() {
      return this.object.status != 2
    },
    belongsToCurrentBranch() {
      if(!this.object) return false
      if(!this.object.branch_id) return true
      return this.object.branch_id == this.branchId
    },
    canDelete() {
      return this.rights.includes('recording_status')
    },
    additional() {
      return this.clinic.clinic_additional
    },
    hasRepresentative() {
      return this.patient.representative_id || this.patient.representative_fio
    },
    signerOptions() {
      return [
        { id: 'patient', title: this.$t('patient') },
        { id: 'representative', title: this.$t('representative_noun') },
      ]
    }
  },
  methods: {
    agreementPressed() {
      this.$store.commit('setPrintAgreements', true)
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$bvModal.show('contract-modal')
    },
    payment() {
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$nextTick(() => {
        this.$bvModal.show('visit-pay-modal')
      })
    },
    printVisit() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/document/'+this.object.client_id+'/disease_history?visit_id='+this.object.id, '_blank')
    },
    async printAkt(signer) {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/akt/${this.object.id}?signer=${signer}&patientSigner=${patientSigner}`, '_blank')
    },
    async openOptionsModal() {
      this.optionsModalOpen = true
      await this.$nextTick()
      this.$bvModal.show('options-popup-modal')
      return new Promise((resolve) => {
        this.modalResolve = resolve
      });
    },
    async cancelRecoverVisit() {
      if(this.isActive) {
        let reason = ''
        if(this.additional.ask_reason_cancel) {
          reason = await this.$parent?.$parent?.$parent?.$parent?.$refs.reasonPrompt.showPrompt()
          if(!reason) return
        }
        CalendarService.cancelRecord(this.object.id, reason).then(() => {
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
        })
      }
      if(!this.isActive) {
        CalendarService.recoverRecord(this.object.id).then(() => {
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
        })
      }
    }
  }
}
</script>
