<template>
<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
  <ellipse cx="11.6308" cy="12.5" rx="8.59715" ry="8.5" fill="#D8F0FC"/>
  <g>
    <path
        d="M21.4082 16.5769C19.9661 14.1235 17.6661 12.305 14.9314 11.4571C14.6623 11.3727 14.3679 11.4448 14.167 11.6426C13.2255 12.5687 12.0955 13.3346 11.5105 13.7041C10.8772 13.2863 9.62451 12.4238 8.8333 11.6434C8.63165 11.444 8.33804 11.3719 8.06738 11.4571C5.33038 12.3065 3.03038 14.1251 1.59212 16.5769C1.45106 16.8168 1.45106 17.1128 1.59212 17.3527C3.6353 20.8341 7.43107 22.9961 11.499 22.9961C15.5669 22.9961 19.3642 20.8341 21.4081 17.3535C21.5492 17.1135 21.5492 16.8168 21.4082 16.5769ZM11.499 21.4628C8.12646 21.4628 4.97083 19.7508 3.15459 16.9648C4.32836 15.1608 6.06104 13.7938 8.10341 13.0609C9.32012 14.1503 11.0129 15.2114 11.0919 15.2604C11.3311 15.4099 11.6339 15.4168 11.8792 15.275C11.9498 15.2344 13.5015 14.3389 14.8976 13.0616C16.9369 13.7938 18.6688 15.1608 19.8449 16.9647C18.028 19.7509 14.8724 21.4628 11.499 21.4628Z"
        fill="black"/>
    <path
        d="M8.92298 11.7499C8.68071 11.4034 8.20306 11.3183 7.855 11.5598C6.32245 12.6285 4.98382 14.605 3.98485 17.2745C3.77017 17.8495 3.56856 18.4644 3.38685 19.1015C3.27108 19.5086 3.50724 19.9333 3.91356 20.0491C3.98408 20.069 4.05461 20.0782 4.12437 20.0782C4.45864 20.0782 4.76532 19.8589 4.8619 19.5224C5.03211 18.9244 5.21993 18.3486 5.42082 17.8127C6.3109 15.4352 7.45556 13.7079 8.73282 12.8179C9.08016 12.5748 9.16524 12.0972 8.92298 11.7499Z"
        fill="black"/>
    <path
        d="M19.6369 19.1022C19.4605 18.4774 19.2597 17.8625 19.0396 17.2729C18.0422 14.6057 16.7044 12.6292 15.1695 11.5582C14.8222 11.3159 14.343 11.4018 14.1015 11.7483C13.86 12.0964 13.9443 12.574 14.2916 12.8163C15.5513 13.6956 16.7273 15.469 17.6028 17.8104C17.8083 18.3601 17.9954 18.9351 18.1602 19.5193C18.256 19.8574 18.5635 20.0782 18.8977 20.0782C18.9667 20.0782 19.0372 20.0697 19.107 20.0483C19.5142 19.9332 19.7511 19.5093 19.6369 19.1022Z"
        fill="black"/>
    <path
        d="M11.4983 0C8.70763 0 6.4375 2.2885 6.4375 5.10142V6.48142C6.4375 9.29434 8.70759 11.5828 11.4983 11.5828C14.289 11.5828 16.5598 9.29434 16.5598 6.48142V5.10142C16.5598 2.2885 14.289 0 11.4983 0ZM15.0265 6.48142C15.0265 8.44868 13.4441 10.0495 11.4983 10.0495C9.55324 10.0495 7.97082 8.44868 7.97082 6.48142V5.10142C7.97082 3.13415 9.55324 1.53336 11.4983 1.53336C13.4441 1.53336 15.0265 3.13415 15.0265 5.10142V6.48142Z"
        fill="black"/>
    <path
        d="M11.4981 13.594C11.0749 13.594 10.7314 13.9367 10.7314 14.3607V22.2336C10.7314 22.6576 11.0749 23.0003 11.4981 23.0003C11.9213 23.0003 12.2648 22.6576 12.2648 22.2336V14.3607C12.2648 13.9367 11.9213 13.594 11.4981 13.594Z"
        fill="black"/>
    <path
        d="M16.5085 17.1726H13.6466C13.2226 17.1726 12.8799 17.5153 12.8799 17.9393C12.8799 18.3633 13.2226 18.706 13.6466 18.706H16.5085C16.9317 18.706 17.2752 18.3633 17.2752 17.9393C17.2752 17.5153 16.9325 17.1726 16.5085 17.1726Z"
        fill="black"/>
    <path
        d="M15.0767 15.7419C14.6528 15.7419 14.3101 16.0847 14.3101 16.5086V19.3706C14.3101 19.7946 14.6528 20.1373 15.0767 20.1373C15.4999 20.1373 15.8434 19.7938 15.8434 19.3706V16.5086C15.8434 16.0847 15.5007 15.7419 15.0767 15.7419Z"
        fill="black"/>
  </g>
</svg>
</template>
