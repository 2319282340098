export default {
    primaryKey: 'id',
    model: 'other_income',
    order: 'date',
    sort: 'desc',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'description',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            filter: true,
            form: {
                type: "text",
                required: true
            },
        },
        {
            id: 'date',
            title: 'date',
            table: true,
            sortable: true,
            format_date: true,
            form: {
                type: "date",
                required: true
            },
            tabletdclass: "order-mob-0",
            filter: true,
        },
        {
            id: 'cost',
            title: 'cost',
            table: true,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
            form: {
                type: "number",
                required: true
            }
        },
        {
            id: 'forma',
            title: 'payment_type',
            table: true,
            sortable: true,
            table_show: 'payment_type',
            tdcomponent: 'AccountingDatatablePaymentTypeTD',
            form: {
                component: 'AccountingDatatableFormPaymentType',
            }
        },
        {
            id: 'category_id',
            title: 'category',
            table: true,
            table_show: 'category.name',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                required: true,
                select_data: {
                    "title": "name",
                },
            }
        },
    ]
}
