<template>
  <div v-if="clinicFull" class="mb-3">
    <SelectGroup :options="months"
                 :showlabel="true"
                 v-model="object.months"
                 variant="white"
                 :translate="false"
                 :label="$t('months')"
                 :required="true"/>
    <div>Валюта: {{ clinicFull.currency.title }}</div>
    <div>Тариф: {{ clinicFull.tariffInfo ? clinicFull.tariffInfo.tariff.title : '-' }}</div>
    <div>Месячный платеж: {{ formatNumber(amountPerMonth) }}</div>
    <div v-if="clinicTariff">
      <div>Комментарий: {{ clinicTariff.comment ? clinicTariff.comment : '-' }}
        <a @click="editComment" class="pointer ml-2"><EditSvg/></a>
        <div v-if="editingComment" class="text-right">
          <AutosizeTextArea v-model="newComment"/>
          <button @click="saveComment"
                  class="btn-themed btn-themed-squared">
            {{ $t('save') }}
          </button>
        </div>
      </div>
      <div>Доп функционал: {{ paidFunctionalityString }}</div>
    </div>
  </div>
</template>

<script>
import { EloquentService, SuperadminService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { formatNumber } from "@/extensions/filters/filters"
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"

export default {
  name: "BillingPaymentFormCard",
  components: {
    AutosizeTextArea,
    SelectGroup,
    EditSvg
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      clinicFull: null,
      currencyMapping: {
        1: 'uah',
        2: 'rub',
        3: 'usd',
        5: 'kzt'
      },
      months: [ ...Array(12).keys() ].map( i => ({ id: (i+1), title: (i+1)})),
      editingComment: false,
      newComment: ''
    }

  },
  computed: {
    clinicId() {
      return this.object.clinic_id || null
    },
    amountPerMonth() {
      return this.clinicFull.tariffPrices === 0 ? 0 : this.clinicFull.tariffPrices.total[this.object.currency]
    },
    clinicTariff() {
      return this.clinicFull?.clinic_tariff || null
    },
    paidFunctionalityString() {
      if(!this.clinicTariff?.paid_functionality) return '-'
      return this.clinicTariff?.paid_functionality?.map(x => `[${x.id}] ${x.title}`).join(', ')
    }
  },
  watch: {
    async clinicId(val) {
      if(!val) return
      await this.reload()
      this.object.currency = this.currencyMapping[this.clinicFull.currency.id] || 'usd'
      this.recountSum()
    },
    'object.months'() {
      this.recountSum()
    }
  },
  methods: {
    formatNumber,
    recountSum() {
      this.object.sum = this.object.months * this.amountPerMonth
    },
    async reload() {
      this.clinicFull = (await EloquentService.show('clinic', this.clinicId.code, true)).data
    },
    editComment() {
      if(this.editingComment) {
        this.editingComment = false
        this.newComment = ''
        return
      }
      this.editingComment = true
      this.newComment = this.clinicTariff.comment
    },
    async saveComment() {
      if(!this.clinicId || !this.clinicId.code) return
      await SuperadminService.saveTariffsForClinic(this.clinicId.code, { comment: this.newComment })
      await this.reload()
      this.editingComment = false
    }
  }
}
</script>
