<template>
  <span>
    {{ formatDateTime(object.send_time ? object.send_time : object.created_at) }}
  </span>
</template>

<script>
import {formatDateTime} from "@/extensions/filters/filters"

export default {
  name: "MarketingDatatableTDMailingDate",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    formatDateTime
  }
}
</script>
