<template>
  <div>
    <b-row class="pt-sm-3 pt-1 align-items-center">
      <b-col sm="auto"
             cols="12"
             class="no-print">
        <DateGroup :label="$t('date_from')"
                   :required="true"
                   v-model="from"/>
      </b-col>
      <b-col sm="auto"
             cols="12"
             class="no-print">
        <DateGroup :label="$t('date_to')"
                   :required="true"
                   v-model="to"/>
      </b-col>
      <b-col sm="auto"
             cols="12"
             class="no-print">
        <SelectGroup v-model="status"
                     :label="$t('status')"
                     :showlabel="true"
                     :options="recordStatusesWithCancelled"
                     variant="white"/>
      </b-col>
      <b-col sm="auto"
             cols="12"
             class="no-print">
          <InputGroup v-model="search"
                      :label="$t('patient_search')"/>
      </b-col>
    </b-row>
    <DataTable :options="options"
               class="mt-4"
               ref="dataTable"
               :load-on-init="false"
               @checkboxSelected="selectedChange"
               :params="{ record_type: ['treatment', 'consultation', 'checkup', 'diagnostics'], branch_id: branch }"
               module="crm">
      <template #bottom-bar>
        <div>
          <b-dropdown-item @click="sendBulkMessage">
            {{ $t('send_message') }}
          </b-dropdown-item>
        </div>
      </template>
    </DataTable>
    <PatientSMSModal/>
    <AddNewVisitModal/>
    <PatientsSendMessageModal @cleanSelected="cleanSelected" :selected="selected"/>
    <ReasonPrompt ref="reasonPrompt"/>
  </div>
</template>

<script>

import { mapState } from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/crm/record"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import AddNewVisitModal from "@/components/parts/calendar/AddNewVisitModal"
import DateGroup from "@/components/form/DateGroup.vue"
import { recordStatusesWithCancelled } from "@/dictionaries/dictionary"
import SelectGroup from "@/components/form/SelectGroup.vue"
import InputGroup from "@/components/form/InputGroup.vue"
import PatientsSendMessageModal from "@/components/parts/crm/PatientsSendMessageModal.vue"
import moment from "moment/moment"
import ReasonPrompt from "@/components/parts/general/ReasonPrompt.vue"

export default {
  name: "CRMVisits",
  components: {
    ReasonPrompt,
    PatientsSendMessageModal,
    InputGroup,
    SelectGroup,
    DateGroup,
    AddNewVisitModal,
    DataTable,
    PatientSMSModal
  },
  head() {
    return {
      title: this.$t('visits'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch
    }),
  },
  data() {
    return {
      recordStatusesWithCancelled,
      options,
      timer: null,
      search: '',
      to: '',
      from: '',
      status: null,
      selected: [],
      watchRoute: false
    }
  },
  methods: {
    filterChange(field, value, title = null) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let datafilter = this.$refs.dataTable.$refs.datatablefilter
        if(value) {
          let data = {
            "id": field,
            "title": title || field,
            "condition": value,
            "type": "date",
            "ctype": "equals"
          }
          if(field === 'records_statuses_id') {
            data.type = "select"
            data.translate = true
            data.options = this.recordStatusesWithCancelled
          }
          datafilter.filters[field] = data
        } else {
          delete datafilter.filters[field]
        }
        datafilter.refresh()
      }, 100)
    },
    selectedChange(selected) {
      this.selected = selected
    },
    sendBulkMessage() {
      this.$bvModal.show('patients-send-message-modal')
    },
    cleanSelected() {
      this.$refs.dataTable.cancelSelection()
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.dispatch('getAdmins')
    const today = moment().format('YYYY-MM-DD')
    const filter = {
      "date_from": { "id": "date_from", "condition": today, "type":"date", "ctype": "equals", "title": "" },
      "date_to": { "id": "date_to", "condition": today, "type":"equals", "ctype": "equals", "title": "" }
    }
    this.from = today
    this.to = today
    const dataFilter = this.$refs.dataTable.$refs.datatablefilter
    dataFilter.filters = filter
    dataFilter.refresh()
    setTimeout(() => {
      this.watchRoute = true
    }, 100)
  },
  watch: {
    search(val) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let datafilter = this.$refs.dataTable.$refs.datatablefilter
        if(val) {
          datafilter.filters.client_id = {
            "id": "client_id",
            "title": "patient",
            "condition": val,
            "type": "text",
            "ctype": "like"
          }
        } else {
          delete datafilter.filters.client_id
        }
        datafilter.refresh()
      }, 600)
    },
    from(val) {
      if(!this.watchRoute) return
      this.filterChange('date_from', val)
    },
    to(val) {
      if(!this.watchRoute) return
      this.filterChange('date_to', val)
    },
    status(val) {
      this.filterChange('records_statuses_id', val, 'status')
    },
    $route(val) {
      if(!this.watchRoute) return
      const filters = val?.query?.filters || "{}"
      try {
        const parsedFilters = JSON.parse(filters)
        const fields = [
          { id: 'date_from', attr: 'from' },
          { id: 'date_to', attr: 'to' },
          { id: 'records_statuses_id', attr: 'status' },
          { id: 'client_id', attr: 'search' }
        ]
        fields.forEach(field => {
          this[field.attr] = parsedFilters[field.id]?.condition || ''
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.patient-search-bar {
  input {
    border: none;
    background: #E0E7EE;
    border-radius: 25px;
    max-width: 250px;
    height: 33px;
    padding-left: 25px;
  }
}
@media(max-width: 768px) {
  .patient-search-bar {
    input {
      width: 100%;
      max-width: none;
    }
  }
}
</style>
