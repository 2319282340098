<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M12.7493 2.40276L14.833 1.133H18.3167L20.4167 2.40276L18.8051 3.94927L20.8888 5.49578L19.0004 7.04229L21.2958 8.73531L19.4725 10.2818L21.7679 11.6818L19.7493 13.0981L21.7679 14.7097L20.0748 16.2562L22.3051 17.6725L20.5469 19.1376L22.8423 20.6841L20.6934 22.2306L22.8423 23.8423L21.2958 25.1934L23.3795 26.7399L21.5562 28.2213L23.6399 29.7678L21.833 31.5097L22.8423 33.1864V47.4469L20.9539 54.7724H14.833" fill="#8889BE"/>
      <path d="M20.1565 2.40276L18.0728 1.133H14.6054L12.4891 2.40276L14.117 3.94927L12.017 5.49578L13.9054 7.04229L11.6263 8.73531L13.4333 10.2818L11.1542 11.6818L13.1728 13.0981L11.1542 14.7097L12.831 16.2562L10.617 17.6725L12.3589 19.1376L10.0798 20.6841L12.2287 22.2306L10.0798 23.8423L11.6263 25.1934L9.54261 26.7399L11.3496 28.2213L9.26587 29.7678L11.0891 31.5097L10.0798 33.1864V47.4469L11.9519 54.7724H15.8589" fill="#8889BE"/>
      <path d="M32.3464 70.5952C32.1985 76.8887 31.1227 78.4082 30.1276 79.1748C29.1325 79.9413 27.9356 80.0219 24.0493 80.0623C13.7081 80.1968 14.5553 80.4119 12.8071 80.2102C6.87667 79.5647 3.9182 79.2285 2.04898 77.2517C0.179761 75.2749 -0.00850505 72.6796 1.90106 57.5644C2.51965 52.6561 2.85584 50.4103 3.24582 47.5863L5.28985 44.3992C6.45996 41.9292 8.45162 39.9423 10.9244 38.7781C13.5492 37.668 16.4797 37.511 19.2081 38.3344C20.774 38.7014 22.2688 39.3238 23.6324 40.1767L29.2535 45.6633C29.6704 49.3076 29.8183 48.3528 31.1765 56.919C32.0072 61.4292 32.399 66.0094 32.3464 70.5952Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M4.76771 49.7796C3.67845 58.4802 3.42295 59.193 2.40093 67.4363C0.491369 82.5515 0.65274 85.1737 2.54885 87.1236C4.44497 89.0736 7.43034 89.4366 13.3069 90.0821C15.0551 90.2838 14.2079 90.0821 24.5626 89.9342C28.4355 89.9342 29.5113 89.9342 30.6275 89.0466C31.7436 88.1591 32.6984 86.7605 32.8463 80.4671C32.9173 75.8913 32.539 71.3196 31.7167 66.8177C30.372 58.292 30.2106 59.2064 29.7937 55.5621C28.9331 48.0314 29.9551 47.3725 29.0541 40.7697C28.3835 36.6782 27.4447 32.6352 26.2435 28.6669C25.258 24.4814 23.968 20.3734 22.3841 16.3758C19.6946 10.2168 18.8608 11.1178 15.2837 3.34503C14.06 0.65551 13.3338 0.432777 12.5 0.500015C11.6662 0.567253 11.1284 1.30099 9.95849 8.99302C8.47925 18.1643 7.72618 24.3905 7.72618 24.3905C7.20173 28.7879 7.20173 29.1779 6.38142 36.09C5.97799 39.6805 5.39975 44.7233 4.76771 49.7796Z" fill="#DEE1E9"/>-->
<!--      <path d="M32.8435 80.4696C32.6956 86.7631 31.6198 88.2826 30.6247 89.0492C29.6295 89.8157 28.4327 89.8963 24.5463 89.9367C14.2051 90.0712 15.0523 90.2863 13.3041 90.0846C7.37374 89.4391 4.41527 89.1029 2.54605 87.1261C0.676831 85.1493 0.488565 82.5539 2.39813 67.4388C3.01672 62.5305 3.35291 60.2847 3.74289 57.4607L5.78692 54.2736C6.95703 51.8036 8.94869 49.8167 11.4215 48.6525C14.0463 47.5424 16.9768 47.3854 19.7052 48.2088C21.2711 48.5757 22.7659 49.1982 24.1295 50.0511L29.7506 55.5377C30.1674 59.182 30.3154 58.2272 31.6736 66.7934C32.5042 71.3036 32.896 75.8837 32.8435 80.4696Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--      <path opacity="0.2" d="M18.3425 56.4874C20.1065 54.3089 18.681 51.2563 18.1169 40.431C17.6143 30.8563 18.5784 30.3049 17.44 25.2352C16.599 21.5102 15.7888 20.5016 14.5068 14.6385C13.4812 9.9049 13.1838 2.34892 12.9171 2.38926C12.6505 2.4296 12.9171 11.8682 12.9171 19.5065C12.9171 26.822 12.5274 25.9882 12.4659 33.2634C12.3838 43.8467 13.1427 45.0435 12.0146 49.3198C11.1018 52.7893 9.9634 54.3493 10.6608 55.9092C11.7172 58.3163 16.3221 58.9618 18.3425 56.4874Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->
        <path d="M5.03382 50.1378C3.94457 58.8384 3.68906 59.5511 2.66704 67.7945C0.757482 82.9096 0.918854 85.5319 2.81497 87.4818C4.71108 89.4317 7.69645 89.7948 13.5731 90.4403C15.3212 90.642 14.474 90.4403 24.8287 90.2924C28.7016 90.2924 29.7774 90.2923 30.8936 89.4048C32.0097 88.5173 32.9645 87.1187 33.1124 80.8252C33.1834 76.2494 32.8051 71.6778 31.9828 67.1759C30.6381 58.6501 30.4767 59.5646 30.0598 55.9203C29.1992 48.3896 30.2212 47.7307 29.3202 41.1279C28.6497 37.0364 27.7108 32.9934 26.5097 29.025C25.5241 24.8395 24.2342 20.7316 22.6502 16.7339C19.9607 10.5749 19.1269 11.4759 15.5499 3.70319C14.3261 1.01366 13.5999 0.790932 12.7661 0.85817C11.9324 0.925408 11.3945 1.65915 10.2246 9.35118C8.74536 18.5224 7.9923 24.7487 7.9923 24.7487C7.46784 29.1461 7.46784 29.536 6.64754 36.4481C6.24411 40.0386 5.66586 45.0815 5.03382 50.1378Z" fill="#DEE1E9"/>
        <mask id="mask411" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="47" width="34" height="45">
          <path d="M33.0574 80.9824C32.9095 87.2759 31.8337 88.7955 30.8385 89.562C29.8434 90.3285 28.6466 90.4092 24.7602 90.4495C14.419 90.584 15.2662 90.7991 13.518 90.5974C7.58761 89.9519 4.62913 89.6158 2.75992 87.639C0.890699 85.6622 0.702432 83.0668 2.61199 67.9516C3.23058 63.0433 3.56677 60.7975 3.95675 57.9735L6.00079 54.7864C7.17089 52.3164 9.16256 50.3295 11.6353 49.1653C14.2602 48.0552 17.1906 47.8982 19.9191 48.7216C21.485 49.0886 22.9798 49.711 24.3433 50.5639L29.9644 56.0505C30.3813 59.6948 30.5292 58.74 31.8874 67.3062C32.7181 71.8164 33.1099 76.3966 33.0574 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask411)">
          <path d="M33.0574 80.9824C32.9095 87.2759 31.8337 88.7955 30.8385 89.562C29.8434 90.3285 28.6466 90.4092 24.7602 90.4495C14.419 90.584 15.2662 90.7991 13.518 90.5974C7.58761 89.9519 4.62913 89.6158 2.75992 87.639C0.890699 85.6622 0.702432 83.0668 2.61199 67.9516C3.23058 63.0433 3.56677 60.7975 3.95675 57.9735L6.00079 54.7864C7.17089 52.3164 9.16256 50.3295 11.6353 49.1653C14.2602 48.0552 17.1906 47.8982 19.9191 48.7216C21.485 49.0886 22.9798 49.711 24.3433 50.5639L29.9644 56.0505C30.3813 59.6948 30.5292 58.74 31.8874 67.3062C32.7181 71.8164 33.1099 76.3966 33.0574 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7809 75.2009C15.8005 75.2009 14.2661 83.6026 14.2661 93.2009H16.1892H19.2661C19.2661 83.6026 17.7613 75.2009 16.7809 75.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M20.1926 75.1141C22.4099 72.2182 20.618 68.1603 19.909 53.7702C19.2774 41.0425 20.4891 40.3096 19.0582 33.5703C18.0012 28.6187 16.9828 27.278 15.3714 19.4841C14.0823 13.1917 13.7085 3.14751 13.3733 3.20114C13.0381 3.25477 13.3733 15.8016 13.3733 25.9552C13.3733 35.6797 12.8834 34.5714 12.8061 44.2423C12.703 58.3107 13.6569 59.9016 12.2389 65.5862C11.0916 70.1982 9.66069 72.2718 10.5373 74.3454C11.8651 77.5452 17.6531 78.4033 20.1926 75.1141Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M4.76771 52.9368C3.67845 61.6374 3.42295 62.3501 2.40093 70.5935C0.491369 85.7086 0.65274 88.3309 2.54885 90.2808C4.44497 92.2307 7.43034 92.5938 13.3069 93.2393C15.0551 93.441 14.2079 93.2393 24.5626 93.0914C28.4355 93.0914 29.5113 93.0914 30.6275 92.2038C31.7436 91.3163 32.6984 89.9177 32.8463 83.6242C32.9173 79.0485 32.539 74.4768 31.7167 69.9749C30.372 61.4491 30.2106 62.3636 29.7937 58.7193C28.9331 51.1886 29.9551 50.5297 29.0541 43.9269C28.3835 39.8354 27.4447 35.7924 26.2435 31.8241C25.258 27.6385 23.968 23.5306 22.3841 19.5329C19.6946 13.3739 18.8608 14.2749 15.2837 6.5022C14.06 3.81268 13.3338 3.58994 12.5 3.65718C11.6662 3.72442 11.1284 4.45816 9.95849 12.1502C8.47925 21.3215 7.72618 27.5477 7.72618 27.5477C7.20173 31.9451 7.20173 32.335 6.38142 39.2471C5.97799 42.8376 5.39975 47.8805 4.76771 52.9368Z" fill="#DEE1E9"/>
      <path d="M32.8435 83.6267C32.6956 89.9202 31.6198 91.4398 30.6247 92.2063C29.6295 92.9728 28.4327 93.0535 24.5463 93.0938C14.2051 93.2283 15.0523 93.4435 13.3041 93.2418C7.37374 92.5963 4.41527 92.2601 2.54605 90.2833C0.676831 88.3065 0.488565 85.7111 2.39813 70.596C3.01672 65.6876 3.35291 63.4419 3.74289 60.6179L5.78692 57.4308C6.95703 54.9608 8.94869 52.9739 11.4215 51.8097C14.0463 50.6996 16.9768 50.5426 19.7052 51.3659C21.2711 51.7329 22.7659 52.3553 24.1295 53.2082L29.7506 58.6949C30.1674 62.3392 30.3154 61.3844 31.6736 69.9505C32.5042 74.4608 32.896 79.0409 32.8435 83.6267Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M18.3425 59.6446C20.1065 57.4661 18.681 54.4135 18.1169 43.5881C17.6143 34.0134 18.5784 33.4621 17.44 28.3923C16.599 24.6673 15.7888 23.6588 14.5068 17.7956C13.4812 13.0621 13.1838 5.50608 12.9171 5.54643C12.6505 5.58677 12.9171 15.0254 12.9171 22.6637C12.9171 29.9792 12.5274 29.1454 12.4659 36.4206C12.3838 47.0038 13.1427 48.2007 12.0146 52.477C11.1018 55.9465 9.9634 57.5064 10.6608 59.0663C11.7172 61.4735 16.3221 62.1189 18.3425 59.6446Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="13" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M4.27064 50.2566C3.18138 58.9572 2.92588 59.67 1.90386 67.9134C-0.0057013 83.0285 0.15567 85.6508 2.05178 87.6007C3.9479 89.5506 6.93327 89.9137 12.8099 90.5591C14.5581 90.7608 13.7109 90.5591 24.0655 90.4112C27.9384 90.4112 29.0142 90.4112 30.1304 89.5237C31.2466 88.6361 32.2013 87.2376 32.3493 80.9441C32.4203 76.3683 32.0419 71.7966 31.2197 67.2948C29.8749 58.769 29.7135 59.6834 29.2966 56.0391C28.436 48.5085 29.458 47.8495 28.557 41.2467C27.8865 37.1552 26.9476 33.1122 25.7465 29.1439C24.7609 24.9584 23.471 20.8504 21.887 16.8528C19.1975 10.6938 18.3637 11.5948 14.7867 3.82204C13.5629 1.13252 12.3338 0.932739 11.5 0.999977C10.6662 1.06722 10.6314 1.778 9.46142 9.47004C7.98218 18.6413 7.22911 24.8675 7.22911 24.8675C6.70466 29.2649 6.70466 29.6549 5.88435 36.567C5.48092 40.1575 4.90268 45.2003 4.27064 50.2566Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M4.27064 50.2566C3.18138 58.9572 2.92588 59.67 1.90386 67.9134C-0.0057013 83.0285 0.15567 85.6508 2.05178 87.6007C3.9479 89.5506 6.93327 89.9137 12.8099 90.5591C14.5581 90.7608 13.7109 90.5591 24.0655 90.4112C27.9384 90.4112 29.0142 90.4112 30.1304 89.5237C31.2466 88.6361 32.2013 87.2376 32.3493 80.9441C32.4203 76.3683 32.0419 71.7966 31.2197 67.2948C29.8749 58.769 29.7135 59.6834 29.2966 56.0391C28.436 48.5085 29.458 47.8495 28.557 41.2467C27.8865 37.1552 26.9476 33.1122 25.7465 29.1439C24.7609 24.9584 23.471 20.8504 21.887 16.8528C19.1975 10.6938 18.3637 11.5948 14.7867 3.82204C13.5629 1.13252 12.3338 0.932739 11.5 0.999977C10.6662 1.06722 10.6314 1.778 9.46142 9.47004C7.98218 18.6413 7.22911 24.8675 7.22911 24.8675C6.70466 29.2649 6.70466 29.6549 5.88435 36.567C5.48092 40.1575 4.90268 45.2003 4.27064 50.2566Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M0.842403 60.9699C1.86442 52.7265 2.1226 58.2247 3.21185 49.5241C3.84389 44.4678 4.42214 39.425 4.82557 35.8345C5.64587 28.9224 5.64587 28.5324 6.17033 24.135C6.17033 24.135 6.92339 17.9088 8.40263 8.73753C9.57257 1.04549 10.1104 0.311755 10.9441 0.244517C11.7779 0.177279 12.5042 0.400012 13.7279 3.08953C17.305 10.8623 18.1387 9.96126 20.8282 16.1203C22.4122 20.1179 23.7021 24.2259 24.6877 28.4114C25.8888 32.3797 26.8277 36.4227 27.4982 40.5142C28.3992 47.117 28.2379 55.3066 28.2379 55.3066C28.2379 55.3066 29.1364 60.3513 30.1582 60.3513L24.6877 60.4667L19.3895 58.6886L12.8608 60.7163L6.61907 58.2913L0.842403 60.9699Z" fill="#DEE1E9"/>
      <path d="M4.32792 51.5419C14.1158 49.0168 18.9964 50.2924 26.8387 56.4463" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M16.561 43.0747C17.1251 54.725 18.5507 58.0103 16.7866 60.3548H13.2428L11.4342 61.4113L9.17346 60.0549L10.4587 52.641C11.5869 48.0387 10.8279 46.7507 10.91 35.3609C10.9715 27.5313 11.3612 28.4286 11.3612 20.5555C11.3612 12.3352 11.0946 2.1772 11.3612 2.13378C11.6279 2.09036 11.9253 10.2222 12.9509 15.3165C14.2329 21.6265 15.0431 22.7119 15.8841 26.7208C17.0225 32.1769 16.0585 32.7703 16.561 43.0747Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M1.84509 67.7687C2.86711 59.5254 3.12262 58.8126 4.21187 50.112C4.84391 45.0557 5.42216 40.0129 5.82559 36.4223C6.64589 29.5103 6.64589 29.1203 7.17035 24.7229C7.17035 24.7229 7.92341 18.4967 9.40265 9.32542C10.5726 1.63338 11.1104 0.899645 11.9442 0.832407C12.7779 0.765169 13.5042 0.987902 14.7279 3.67742C18.305 11.4501 19.1387 10.5492 21.8282 16.7082C23.4122 20.7058 24.7021 24.8138 25.6877 28.9993C26.8888 32.9676 27.8277 37.0106 28.4983 41.1021C29.3993 47.7049 28.3772 48.3638 29.2379 55.8945C29.6548 59.5388 29.8161 58.6244 31.1609 67.1501L1.84509 67.7687Z" fill="#DEE1E9"/>
      <path d="M30.0688 89.3815C31.064 88.615 32.1398 87.0955 32.2877 80.802C32.3402 76.2161 31.9484 71.636 31.1178 67.1257C29.7595 58.5596 29.6116 59.5144 29.1947 55.8701L23.5736 50.3835C22.2101 49.5306 20.7153 48.9081 19.1494 48.5412C16.421 47.7178 13.4905 47.8748 10.8656 48.9849C8.39287 50.1491 6.4012 52.136 5.2311 54.606L3.18706 57.7931C2.79708 60.6171 2.46089 62.8629 1.8423 67.7712C1.66932 69.1404 1.51356 70.4069 1.37469 71.5795H3.18706L4.39464 74.0464L3.18706 76.3598L6.99614 77.8746L5.65742 80.9095L9.22495 80.1384L6.99614 84.4776L9.22495 86.8755L13.0106 84.4776L12.9495 88.4213L12.7483 90.417C14.4965 90.6187 13.6493 90.4036 23.9905 90.2691C27.8769 90.2287 29.0737 90.1481 30.0688 89.3815Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M5.07517 69.26C15.7511 73.0333 18.0759 77.5807 16.4503 89.5162" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M17.7867 56.8198C19.5507 54.6413 18.1251 51.5887 17.561 40.7634C17.0585 31.1887 18.0226 30.6373 16.8841 25.5676C16.0432 21.8426 15.2329 20.834 13.9509 14.9709C12.9253 10.2373 12.6279 2.68131 12.3613 2.72166C12.0946 2.762 12.3613 12.2006 12.3613 19.8389C12.3613 27.1544 11.9715 26.3206 11.91 33.5958C11.828 44.1791 12.5869 45.3759 11.4587 49.6522C10.546 53.1217 9.40755 54.6817 10.105 56.2416C11.1613 58.6487 15.7662 59.2942 17.7867 56.8198Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M4.53675 49.985C3.4475 58.6856 3.19199 59.3984 2.16997 67.6418C0.260412 82.7569 0.421783 85.3792 2.3179 87.3291C4.21401 89.279 7.19938 89.6421 13.076 90.2875C14.8242 90.4893 13.977 90.2875 24.3316 90.1396C28.2046 90.1396 29.2804 90.1396 30.3965 89.2521C31.5127 88.3645 32.4674 86.966 32.6154 80.6725C32.6864 76.0967 32.308 71.525 31.4858 67.0232C30.141 58.4974 29.9796 59.4118 29.5628 55.7675C28.7021 48.2369 29.7241 47.5779 28.8231 40.9751C28.1526 36.8836 27.2137 32.8406 26.0126 28.8723C25.027 24.6868 23.7371 20.5788 22.1531 16.5812C19.4636 10.4222 18.6299 11.3232 15.0528 3.55044C13.8291 0.860921 13.0999 0.824028 12.2661 0.891266C11.4324 0.958504 10.8975 1.5064 9.72753 9.19844C8.24829 18.3697 7.49523 24.5959 7.49523 24.5959C6.97077 28.9933 6.97077 29.3833 6.15047 36.2954C5.74704 39.8859 5.16879 44.9287 4.53675 49.985Z" fill="#DEE1E9"/>
      <path d="M30.3937 89.2546C31.3888 88.4881 32.4646 86.9685 32.6126 80.675C32.6651 76.0892 32.2733 71.5091 31.4426 66.9988C30.0844 58.4327 29.6897 57.1706 29.2728 53.5263H23.3589C22.8067 53.5263 22.3589 53.974 22.3589 54.5263V66.9988C16.3913 68.2161 16.7065 68.2038 10.7411 66.9988V54.5263C10.7411 53.974 10.2934 53.5263 9.74112 53.5263H4.39971L3.51193 57.6661C3.12195 60.4901 2.78576 62.7359 2.16717 67.6443C0.257608 82.7594 0.445874 85.3548 2.31509 87.3316C4.18431 89.3084 7.14279 89.6446 13.0732 90.29C14.8214 90.4918 13.9742 90.2766 24.3154 90.1421C28.2017 90.1018 29.3986 90.0211 30.3937 89.2546Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M4.53675 49.2358C3.4475 57.9364 3.19199 58.6492 2.16997 66.8925C0.260412 82.0077 0.421783 84.6299 2.3179 86.5798C4.21401 88.5297 7.19938 88.8928 13.076 89.5383C14.8242 89.74 13.977 89.5383 24.3316 89.3904C28.2046 89.3904 29.2804 89.3904 30.3965 88.5028C31.5127 87.6153 32.4674 86.2167 32.6154 79.9233C32.6864 75.3475 32.308 70.7758 31.4858 66.2739C30.141 57.7482 29.9796 58.6626 29.5628 55.0183C28.7021 47.4876 29.7241 46.8287 28.8231 40.2259C28.1526 36.1344 27.2137 32.0914 26.0126 28.1231C25.027 23.9375 23.7371 19.8296 22.1531 15.832C19.4636 9.67294 18.6299 10.5739 15.0528 2.80121C13.8291 0.111692 13.0999 0.0747984 12.2661 0.142036C11.4324 0.209274 10.8975 0.757173 9.72753 8.44921C8.24829 17.6205 7.49523 23.8467 7.49523 23.8467C6.97077 28.2441 6.97077 28.6341 6.15047 35.5461C5.74704 39.1366 5.16879 44.1795 4.53675 49.2358Z" fill="#DEE1E9"/>
      <path d="M30.3937 88.5054C31.3888 87.7388 32.4646 86.2193 32.6126 79.9258C32.6651 75.34 32.2733 70.7598 31.4426 66.2496C30.0844 57.6834 29.6897 56.4214 29.2728 52.7771H23.3589C22.8067 52.7771 22.3589 53.2248 22.3589 53.7771V66.2496C16.3913 67.4669 16.7065 67.4546 10.7411 66.2496V53.7771C10.7411 53.2248 10.2934 52.7771 9.74112 52.7771H4.39971L3.51193 56.9169C3.12195 59.7409 2.78576 61.9867 2.16717 66.895C0.257608 82.0102 0.445874 84.6055 2.31509 86.5823C4.18431 88.5591 7.14279 88.8953 13.0732 89.5408C14.8214 89.7425 13.9742 89.5274 24.3154 89.3929C28.2017 89.3525 29.3986 89.2719 30.3937 88.5054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M4.53675 49.3035C3.4475 58.0041 3.19199 58.7168 2.16997 66.9602C0.260412 82.0753 0.421783 84.6976 2.3179 86.6475C4.21401 88.5974 7.19938 88.9605 13.076 89.606C14.8242 89.8077 13.977 89.606 24.3316 89.458C28.2046 89.458 29.2804 89.458 30.3965 88.5705C31.5127 87.6829 32.4674 86.2844 32.6154 79.9909C32.6864 75.4151 32.308 70.8434 31.4858 66.3416C30.141 57.8158 29.9796 58.7302 29.5628 55.0859C28.7021 47.5553 29.7241 46.8963 28.8231 40.2936C28.1526 36.202 27.2137 32.159 26.0126 28.1907C25.027 24.0052 23.7371 19.8972 22.1531 15.8996C19.4636 9.74059 18.6299 10.6416 15.0528 2.86886C13.8291 0.179342 13.0999 0.142448 12.2661 0.209686C11.4324 0.276924 10.8975 0.824823 9.72753 8.51686C8.24829 17.6881 7.49523 23.9144 7.49523 23.9144C6.97077 28.3117 6.97077 28.7017 6.15047 35.6138C5.74704 39.2043 5.16879 44.2472 4.53675 49.3035Z" fill="#DEE1E9"/>
      <path d="M30.3937 88.573C31.3888 87.8065 32.4646 86.2869 32.6126 79.9934C32.6651 75.4076 32.2733 70.8275 31.4426 66.3172C30.0844 57.7511 29.6897 56.489 29.2728 52.8447L23.6496 52.8447V66.3172C17.6819 67.5345 15.9423 67.5222 9.97695 66.3172V52.8447L4.39971 52.8447L3.51193 56.9846C3.12195 59.8086 2.78576 62.0543 2.16717 66.9627C0.257608 82.0778 0.445874 84.6732 2.31509 86.65C4.18431 88.6268 7.14279 88.963 13.0732 89.6085C14.8214 89.8102 13.9742 89.595 24.3154 89.4605C28.2017 89.4202 29.3986 89.3395 30.3937 88.573Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask2011" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="34" height="38">
        <path d="M30.3869 88.573C31.382 87.8065 32.4578 86.2869 32.6057 79.9934C32.6582 75.4076 32.2664 70.8275 31.4358 66.3172C30.0776 57.7511 29.6829 57.4046 29.266 53.7603L24.6428 53.7603C24.0905 53.7603 23.6428 54.208 23.6428 54.7603V66.3172C17.6751 67.5345 15.9355 67.5222 9.97011 66.3172V54.7603C9.97011 54.208 9.5224 53.7603 8.97011 53.7603L4.39288 53.7603L3.50509 56.9846C3.11511 59.8086 2.77892 62.0543 2.16033 66.9627C0.250772 82.0778 0.439038 84.6732 2.30826 86.65C4.17747 88.6268 7.13595 88.963 13.0663 89.6085C14.8145 89.8102 13.9673 89.595 24.3085 89.4605C28.1949 89.4202 29.3917 89.3395 30.3869 88.573Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2011)">
        <path d="M6.69037 45.5855H26.7344V71.1335C18.9067 73.0597 14.5181 73.0793 6.69037 71.1335V45.5855Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M5.15272 49.8072C4.06346 58.5078 3.80796 59.2206 2.78594 67.4639C0.876379 82.579 1.03775 85.2013 2.93386 87.1512C4.82998 89.1011 7.81535 89.4642 13.692 90.1097C15.4401 90.3114 14.5929 90.1097 24.9476 89.9618C28.8205 89.9618 29.8963 89.9618 31.0125 89.0742C32.1286 88.1867 33.0834 86.7881 33.2313 80.4947C33.3023 75.9189 32.924 71.3472 32.1017 66.8453C30.757 58.3195 30.5956 59.234 30.1787 55.5897C29.3181 48.059 30.3401 47.4001 29.4391 40.7973C28.7686 36.7058 27.8297 32.6628 26.6286 28.6945C25.643 24.5089 24.3531 20.401 22.7691 16.4034C20.0796 10.2444 19.2458 11.1453 15.6688 3.37262C14.445 0.683098 13.7188 0.460365 12.885 0.527603C12.0513 0.594841 11.5134 1.32858 10.3435 9.02061C8.86426 18.1919 8.11119 24.4181 8.11119 24.4181C7.58674 28.8155 7.58674 29.2055 6.76643 36.1175C6.363 39.708 5.78476 44.7509 5.15272 49.8072Z" fill="#DEE1E9"/>
      <path d="M33.2285 80.4973C33.0806 86.7908 32.0048 88.3103 31.0097 89.0769C30.0145 89.8434 28.8177 89.924 24.9314 89.9644C14.5901 90.0989 15.4373 90.314 13.6891 90.1123C7.75875 89.4668 4.80028 89.1306 2.93106 87.1538C1.06184 85.177 0.873575 82.5817 2.78314 67.4665C3.40173 62.5582 3.73792 60.3124 4.1279 57.4884L6.17193 54.3013C7.34204 51.8313 9.3337 49.8444 11.8065 48.6802C14.4313 47.5701 17.3618 47.4131 20.0902 48.2365C21.6561 48.6035 23.1509 49.2259 24.5145 50.0788L30.1356 55.5654C30.5525 59.2097 30.7004 58.2549 32.0586 66.8211C32.8892 71.3313 33.2811 75.9115 33.2285 80.4973Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M18.7275 56.5148C20.4916 54.3363 19.066 51.2837 18.5019 40.4584C17.9994 30.8837 18.9634 30.3323 17.825 25.2626C16.984 21.5376 16.1738 20.529 14.8918 14.6659C13.8662 9.9323 13.5688 2.37632 13.3021 2.41666C13.0355 2.45701 13.3021 11.8957 13.3021 19.5339C13.3021 26.8494 12.9124 26.0156 12.8509 33.2908C12.7688 43.8741 13.5278 45.0709 12.3996 49.3472C11.4868 52.8167 10.3484 54.3767 11.0458 55.9366C12.1022 58.3437 16.7071 58.9892 18.7275 56.5148Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M7.48364 25.7777C14.5226 22.0482 18.4532 22.2182 25.4505 25.7777" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M5.03382 51.1378C3.94457 59.8385 3.68906 60.5512 2.66704 68.7946C0.757482 83.9097 0.918854 86.532 2.81497 88.4819C4.71108 90.4318 7.69645 90.7949 13.5731 91.4403C15.3212 91.6421 14.474 91.4403 24.8287 91.2924C28.7016 91.2924 29.7774 91.2924 30.8936 90.4049C32.0097 89.5173 32.9645 88.1188 33.1124 81.8253C33.1834 77.2495 32.8051 72.6778 31.9828 68.176C30.6381 59.6502 30.4767 60.5646 30.0598 56.9203C29.1992 49.3897 30.2212 48.7307 29.3202 42.128C28.6497 38.0364 27.7108 33.9934 26.5097 30.0251C25.5241 25.8396 24.2342 21.7316 22.6502 17.734C19.9607 11.575 19.1269 12.476 15.5499 4.70325C14.3261 2.01373 13.5999 1.79099 12.7661 1.85823C11.9324 1.92547 11.3945 2.65921 10.2246 10.3512C8.74536 19.5225 7.9923 25.7487 7.9923 25.7487C7.46784 30.1461 7.46784 30.5361 6.64754 37.4482C6.24411 41.0387 5.66586 46.0815 5.03382 51.1378Z" fill="#DEE1E9"/>
      <mask id="mask2211" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="34" height="45">
        <path d="M33.0574 81.9825C32.9095 88.276 31.8337 89.7956 30.8385 90.5621C29.8434 91.3286 28.6466 91.4093 24.7602 91.4496C14.419 91.5841 15.2662 91.7993 13.518 91.5975C7.58761 90.9521 4.62913 90.6159 2.75992 88.6391C0.890699 86.6623 0.702432 84.0669 2.61199 68.9518C3.23058 64.0434 3.56677 61.7976 3.95675 58.9736L6.00079 55.7866C7.17089 53.3166 9.16256 51.3297 11.6353 50.1655C14.2602 49.0553 17.1906 48.8984 19.9191 49.7217C21.485 50.0887 22.9798 50.7111 24.3433 51.564L29.9644 57.0506C30.3813 60.6949 30.5292 59.7402 31.8874 68.3063C32.7181 72.8166 33.1099 77.3967 33.0574 81.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2211)">
        <path d="M33.0574 81.9825C32.9095 88.276 31.8337 89.7956 30.8385 90.5621C29.8434 91.3286 28.6466 91.4093 24.7602 91.4496C14.419 91.5841 15.2662 91.7993 13.518 91.5975C7.58761 90.9521 4.62913 90.6159 2.75992 88.6391C0.890699 86.6623 0.702432 84.0669 2.61199 68.9518C3.23058 64.0434 3.56677 61.7976 3.95675 58.9736L6.00079 55.7866C7.17089 53.3166 9.16256 51.3297 11.6353 50.1655C14.2602 49.0553 17.1906 48.8984 19.9191 49.7217C21.485 50.0887 22.9798 50.7111 24.3433 51.564L29.9644 57.0506C30.3813 60.6949 30.5292 59.7402 31.8874 68.3063C32.7181 72.8166 33.1099 77.3967 33.0574 81.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M16.7809 76.201C15.8005 76.201 14.2661 84.6027 14.2661 94.201H16.1892H19.2661C19.2661 84.6027 17.7613 76.201 16.7809 76.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M20.1926 76.1142C22.4099 73.2183 20.618 69.1605 19.909 54.7703C19.2774 42.0426 20.4891 41.3097 19.0582 34.5704C18.0012 29.6188 16.9828 28.2781 15.3714 20.4842C14.0823 14.1918 13.7085 4.14764 13.3733 4.20126C13.0381 4.25489 13.3733 16.8017 13.3733 26.9553C13.3733 36.6798 12.8834 35.5715 12.8061 45.2424C12.703 59.3108 13.6569 60.9018 12.2389 66.5863C11.0916 71.1983 9.66069 73.2719 10.5373 75.3456C11.8651 78.5454 17.6531 79.4034 20.1926 76.1142Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="13" cy="4.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M5.03382 56.1378C3.94457 64.8385 3.68906 65.5512 2.66704 73.7946C0.757482 88.9097 0.918854 91.532 2.81497 93.4819C4.71108 95.4318 7.69645 95.7949 13.5731 96.4403C15.3212 96.6421 14.474 96.4403 24.8287 96.2924C28.7016 96.2924 29.7774 96.2924 30.8936 95.4049C32.0097 94.5173 32.9645 93.1188 33.1124 86.8253C33.1834 82.2495 32.8051 77.6778 31.9828 73.176C30.6381 64.6502 30.4767 65.5646 30.0598 61.9203C29.1992 54.3897 30.2212 53.7307 29.3202 47.128C28.6497 43.0364 27.7108 38.9934 26.5097 35.0251C25.5241 30.8396 24.2342 26.7316 22.6502 22.734C19.9607 16.575 19.1269 17.476 15.5499 9.70325C14.3261 7.01373 13.5999 6.79099 12.7661 6.85823C11.9324 6.92547 11.3945 7.65921 10.2246 15.3512C8.74536 24.5225 7.9923 30.7487 7.9923 30.7487C7.46784 35.1461 7.46784 35.5361 6.64754 42.4482C6.24411 46.0387 5.66586 51.0815 5.03382 56.1378Z" fill="#DEE1E9"/>
        <mask id="mask2311" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="34" height="45">
          <path d="M33.0574 86.9825C32.9095 93.276 31.8337 94.7956 30.8385 95.5621C29.8434 96.3286 28.6466 96.4093 24.7602 96.4496C14.419 96.5841 15.2662 96.7993 13.518 96.5975C7.58761 95.9521 4.62913 95.6159 2.75992 93.6391C0.890699 91.6623 0.702432 89.0669 2.61199 73.9518C3.23058 69.0434 3.56677 66.7976 3.95675 63.9736L6.00079 60.7866C7.17089 58.3166 9.16256 56.3297 11.6353 55.1655C14.2602 54.0553 17.1906 53.8984 19.9191 54.7217C21.485 55.0887 22.9798 55.7111 24.3433 56.564L29.9644 62.0506C30.3813 65.6949 30.5292 64.7402 31.8874 73.3063C32.7181 77.8166 33.1099 82.3967 33.0574 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2311)">
          <path d="M33.0574 86.9825C32.9095 93.276 31.8337 94.7956 30.8385 95.5621C29.8434 96.3286 28.6466 96.4093 24.7602 96.4496C14.419 96.5841 15.2662 96.7993 13.518 96.5975C7.58761 95.9521 4.62913 95.6159 2.75992 93.6391C0.890699 91.6623 0.702432 89.0669 2.61199 73.9518C3.23058 69.0434 3.56677 66.7976 3.95675 63.9736L6.00079 60.7866C7.17089 58.3166 9.16256 56.3297 11.6353 55.1655C14.2602 54.0553 17.1906 53.8984 19.9191 54.7217C21.485 55.0887 22.9798 55.7111 24.3433 56.564L29.9644 62.0506C30.3813 65.6949 30.5292 64.7402 31.8874 73.3063C32.7181 77.8166 33.1099 82.3967 33.0574 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7809 81.201C15.8005 81.201 14.2661 89.6027 14.2661 99.201H16.1892H19.2661C19.2661 89.6027 17.7613 81.201 16.7809 81.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M20.1926 81.1142C22.4099 78.2183 20.618 74.1605 19.909 59.7703C19.2774 47.0426 20.4891 46.3097 19.0582 39.5704C18.0012 34.6188 16.9828 33.2781 15.3714 25.4842C14.0823 19.1918 13.7085 9.14764 13.3733 9.20126C13.0381 9.25489 13.3733 21.8017 13.3733 31.9553C13.3733 41.6798 12.8834 40.5715 12.8061 50.2424C12.703 64.3108 13.6569 65.9018 12.2389 71.5863C11.0916 76.1983 9.66069 78.2719 10.5373 80.3456C11.8651 83.5454 17.6531 84.4034 20.1926 81.1142Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="13" cy="9.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2311)">
          <path d="M13 0.200989L13.8229 3.94009L16.8567 1.60472L15.0837 4.99799L18.9088 5.1591L15.3694 6.61879L18.1962 9.20099L14.5465 8.04409L15.0521 11.8391L13 8.60699L10.9479 11.8391L11.4535 8.04409L7.80385 9.20099L10.6306 6.61879L7.09115 5.1591L10.9163 4.99799L9.14327 1.60472L12.1771 3.94009L13 0.200989Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2311" x="6.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.03382 56.1378C3.94457 64.8385 3.68906 65.5512 2.66704 73.7946C0.757482 88.9097 0.918854 91.532 2.81497 93.4819C4.71108 95.4318 7.69645 95.7949 13.5731 96.4403C15.3212 96.6421 14.474 96.4403 24.8287 96.2924C28.7016 96.2924 29.7774 96.2924 30.8936 95.4049C32.0097 94.5173 32.9645 93.1188 33.1124 86.8253C33.1834 82.2495 32.8051 77.6778 31.9828 73.176C30.6381 64.6502 30.4767 65.5646 30.0598 61.9203C29.1992 54.3897 30.2212 53.7307 29.3202 47.128C28.6497 43.0364 27.7108 38.9934 26.5097 35.0251C25.5241 30.8396 24.2342 26.7316 22.6502 22.734C19.9607 16.575 19.1269 17.476 15.5499 9.70325C14.3261 7.01373 13.5999 6.79099 12.7661 6.85823C11.9324 6.92547 11.3945 7.65921 10.2246 15.3512C8.74536 24.5225 7.9923 30.7487 7.9923 30.7487C7.46784 35.1461 7.46784 35.5361 6.64754 42.4482C6.24411 46.0387 5.66586 51.0815 5.03382 56.1378Z" fill="#DEE1E9"/>
      <mask id="mask2311" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="34" height="45">
        <path d="M33.0574 86.9825C32.9095 93.276 31.8337 94.7956 30.8385 95.5621C29.8434 96.3286 28.6466 96.4093 24.7602 96.4496C14.419 96.5841 15.2662 96.7993 13.518 96.5975C7.58761 95.9521 4.62913 95.6159 2.75992 93.6391C0.890699 91.6623 0.702432 89.0669 2.61199 73.9518C3.23058 69.0434 3.56677 66.7976 3.95675 63.9736L6.00079 60.7866C7.17089 58.3166 9.16256 56.3297 11.6353 55.1655C14.2602 54.0553 17.1906 53.8984 19.9191 54.7217C21.485 55.0887 22.9798 55.7111 24.3433 56.564L29.9644 62.0506C30.3813 65.6949 30.5292 64.7402 31.8874 73.3063C32.7181 77.8166 33.1099 82.3967 33.0574 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2311)">
        <path d="M33.0574 86.9825C32.9095 93.276 31.8337 94.7956 30.8385 95.5621C29.8434 96.3286 28.6466 96.4093 24.7602 96.4496C14.419 96.5841 15.2662 96.7993 13.518 96.5975C7.58761 95.9521 4.62913 95.6159 2.75992 93.6391C0.890699 91.6623 0.702432 89.0669 2.61199 73.9518C3.23058 69.0434 3.56677 66.7976 3.95675 63.9736L6.00079 60.7866C7.17089 58.3166 9.16256 56.3297 11.6353 55.1655C14.2602 54.0553 17.1906 53.8984 19.9191 54.7217C21.485 55.0887 22.9798 55.7111 24.3433 56.564L29.9644 62.0506C30.3813 65.6949 30.5292 64.7402 31.8874 73.3063C32.7181 77.8166 33.1099 82.3967 33.0574 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M16.7809 81.201C15.8005 81.201 14.2661 89.6027 14.2661 99.201H16.1892H19.2661C19.2661 89.6027 17.7613 81.201 16.7809 81.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M20.1926 81.1142C22.4099 78.2183 20.618 74.1605 19.909 59.7703C19.2774 47.0426 20.4891 46.3097 19.0582 39.5704C18.0012 34.6188 16.9828 33.2781 15.3714 25.4842C14.0823 19.1918 13.7085 9.14764 13.3733 9.20126C13.0381 9.25489 13.3733 21.8017 13.3733 31.9553C13.3733 41.6798 12.8834 40.5715 12.8061 50.2424C12.703 64.3108 13.6569 65.9018 12.2389 71.5863C11.0916 76.1983 9.66069 78.2719 10.5373 80.3456C11.8651 83.5454 17.6531 84.4034 20.1926 81.1142Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="13" cy="9.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2311)">
        <path d="M13 0.200989L13.8229 3.94009L16.8567 1.60472L15.0837 4.99799L18.9088 5.1591L15.3694 6.61879L18.1962 9.20099L14.5465 8.04409L15.0521 11.8391L13 8.60699L10.9479 11.8391L11.4535 8.04409L7.80385 9.20099L10.6306 6.61879L7.09115 5.1591L10.9163 4.99799L9.14327 1.60472L12.1771 3.94009L13 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2311" x="6.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M5.03382 53.1378C3.94457 61.8384 3.68906 62.5511 2.66704 70.7945C0.757482 85.9096 0.918854 88.5319 2.81497 90.4818C4.71108 92.4317 7.69645 92.7948 13.5731 93.4403C15.3212 93.642 14.474 93.4403 24.8287 93.2924C28.7016 93.2924 29.7774 93.2923 30.8936 92.4048C32.0097 91.5173 32.9645 90.1187 33.1124 83.8252C33.1834 79.2494 32.8051 74.6778 31.9828 70.1759C30.6381 61.6501 30.4767 62.5646 30.0598 58.9203C29.1992 51.3896 30.2212 50.7307 29.3202 44.1279C28.6497 40.0364 27.7108 35.9934 26.5097 32.025C25.5241 27.8395 24.2342 23.7316 22.6502 19.7339C19.9607 13.5749 19.1269 14.4759 15.5499 6.70319C14.3261 4.01366 13.5999 3.79093 12.7661 3.85817C11.9324 3.92541 11.3945 4.65915 10.2246 12.3512C8.74536 21.5224 7.9923 27.7487 7.9923 27.7487C7.46784 32.1461 7.46784 32.536 6.64754 39.4481C6.24411 43.0386 5.66586 48.0815 5.03382 53.1378Z" fill="#DEE1E9"/>
        <mask id="mask2511" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="34" height="45">
          <path d="M33.0574 83.9824C32.9095 90.2759 31.8337 91.7955 30.8385 92.562C29.8434 93.3285 28.6466 93.4092 24.7602 93.4495C14.419 93.584 15.2662 93.7991 13.518 93.5974C7.58761 92.9519 4.62913 92.6158 2.75992 90.639C0.890699 88.6622 0.702432 86.0668 2.61199 70.9516C3.23058 66.0433 3.56677 63.7975 3.95675 60.9735L6.00079 57.7864C7.17089 55.3164 9.16256 53.3295 11.6353 52.1653C14.2602 51.0552 17.1906 50.8982 19.9191 51.7216C21.485 52.0886 22.9798 52.711 24.3433 53.5639L29.9644 59.0505C30.3813 62.6948 30.5292 61.74 31.8874 70.3062C32.7181 74.8164 33.1099 79.3966 33.0574 83.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2511)">
          <path d="M33.0574 83.9824C32.9095 90.2759 31.8337 91.7955 30.8385 92.562C29.8434 93.3285 28.6466 93.4092 24.7602 93.4495C14.419 93.584 15.2662 93.7991 13.518 93.5974C7.58761 92.9519 4.62913 92.6158 2.75992 90.639C0.890699 88.6622 0.702432 86.0668 2.61199 70.9516C3.23058 66.0433 3.56677 63.7975 3.95675 60.9735L6.00079 57.7864C7.17089 55.3164 9.16256 53.3295 11.6353 52.1653C14.2602 51.0552 17.1906 50.8982 19.9191 51.7216C21.485 52.0886 22.9798 52.711 24.3433 53.5639L29.9644 59.0505C30.3813 62.6948 30.5292 61.74 31.8874 70.3062C32.7181 74.8164 33.1099 79.3966 33.0574 83.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.5148 85.2009C15.5344 85.2009 14 90.3353 14 96.2009H15.9231H19C19 90.3353 17.4952 85.2009 16.5148 85.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M19.9265 83.9449C22.1438 80.8142 20.3519 76.4273 19.6429 60.8704C19.0112 47.1107 20.223 46.3184 18.7921 39.0327C17.735 33.6796 16.7167 32.2302 15.1053 23.8043C13.8162 17.0018 13.4423 6.14318 13.1072 6.20116C12.772 6.25914 13.1072 19.8233 13.1072 30.8001C13.1072 41.3131 12.6173 40.1149 12.54 50.57C12.4369 65.779 13.3908 67.499 11.9728 73.6445C10.8255 78.6304 9.39458 80.8722 10.2712 83.1139C11.5989 86.5732 17.387 87.5008 19.9265 83.9449Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="14" cy="4.20093" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2611" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="91">
          <path d="M4.53675 49.7888C3.4475 58.4894 3.19199 59.2022 2.16997 67.4455C0.260412 82.5607 0.421783 85.1829 2.3179 87.1328C4.21401 89.0827 7.19938 89.4458 13.076 90.0913C14.8242 90.293 13.977 90.0913 24.3316 89.9434C28.2046 89.9434 29.2804 89.9434 30.3965 89.0558C31.5127 88.1683 32.4674 86.7697 32.6154 80.4763C32.6864 75.9005 32.308 71.3288 31.4858 66.8269C30.141 58.3012 29.9796 59.2156 29.5628 55.5713C28.7021 48.0406 29.7241 47.3817 28.8231 40.7789C28.1526 36.6874 27.2137 32.6444 26.0126 28.6761C25.027 24.4905 23.7371 20.3826 22.1531 16.385C19.4636 10.2259 18.6299 11.1269 15.0528 3.35422C13.8291 0.664693 13.0999 0.6278 12.2661 0.695038C11.4324 0.762276 10.8975 1.31017 9.72753 9.00221C8.24829 18.1735 7.49523 24.3997 7.49523 24.3997C6.97077 28.7971 6.97077 29.1871 6.15047 36.0991C5.74704 39.6896 5.16879 44.7325 4.53675 49.7888Z" fill="#DEE1E9"/>
          <path d="M32.6126 80.4787C32.4646 86.7722 31.3888 88.2918 30.3937 89.0583C29.3986 89.8248 28.2017 89.9055 24.3154 89.9458C13.9742 90.0803 14.8214 90.2955 13.0732 90.0938C7.14279 89.4483 4.18431 89.1121 2.31509 87.1353C0.445874 85.1585 0.257608 82.5631 2.16717 67.448C2.78576 62.5396 3.12195 60.2939 3.51193 57.4699L5.55597 54.2828C6.72607 51.8128 8.71773 49.8259 11.1905 48.6617C13.8154 47.5516 16.7458 47.3946 19.4742 48.2179C21.0402 48.5849 22.5349 49.2073 23.8985 50.0602L29.5196 55.5469C29.9365 59.1912 30.0844 58.2364 31.4426 66.8025C32.2733 71.3128 32.6651 75.8929 32.6126 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2611)">
          <path d="M4.53675 49.7888C3.4475 58.4894 3.19199 59.2022 2.16997 67.4455C0.260412 82.5607 0.421783 85.1829 2.3179 87.1328C4.21401 89.0827 7.19938 89.4458 13.076 90.0913C14.8242 90.293 13.977 90.0913 24.3316 89.9434C28.2046 89.9434 29.2804 89.9434 30.3965 89.0558C31.5127 88.1683 32.4674 86.7697 32.6154 80.4763C32.6864 75.9005 32.308 71.3288 31.4858 66.8269C30.141 58.3012 29.9796 59.2156 29.5628 55.5713C28.7021 48.0406 29.7241 47.3817 28.8231 40.7789C28.1526 36.6874 27.2137 32.6444 26.0126 28.6761C25.027 24.4905 23.7371 20.3826 22.1531 16.385C19.4636 10.2259 18.6299 11.1269 15.0528 3.35422C13.8291 0.664693 13.0999 0.6278 12.2661 0.695038C11.4324 0.762276 10.8975 1.31017 9.72753 9.00221C8.24829 18.1735 7.49523 24.3997 7.49523 24.3997C6.97077 28.7971 6.97077 29.1871 6.15047 36.0991C5.74704 39.6896 5.16879 44.7325 4.53675 49.7888Z" fill="#DEE1E9"/>
          <path d="M32.6126 80.4787C32.4646 86.7722 31.3888 88.2918 30.3937 89.0583C29.3986 89.8248 28.2017 89.9055 24.3154 89.9458C13.9742 90.0803 14.8214 90.2955 13.0732 90.0938C7.14279 89.4483 4.18431 89.1121 2.31509 87.1353C0.445874 85.1585 0.257608 82.5631 2.16717 67.448C2.78576 62.5396 3.12195 60.2939 3.51193 57.4699L5.55597 54.2828C6.72607 51.8128 8.71773 49.8259 11.1905 48.6617C13.8154 47.5516 16.7458 47.3946 19.4742 48.2179C21.0402 48.5849 22.5349 49.2073 23.8985 50.0602L29.5196 55.5469C29.9365 59.1912 30.0844 58.2364 31.4426 66.8025C32.2733 71.3128 32.6651 75.8929 32.6126 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M22.0259 55.5247H33.9471C31.785 50.1549 31.428 46.8947 31.7245 41.1564C31.7357 40.94 31.4864 40.8054 31.2961 40.932C30.2921 41.6 27.9339 43.4161 27.3622 46.0577C26.6698 49.2577 24.6665 50.0348 24.4539 50.1089C24.441 50.1134 24.4294 50.1179 24.417 50.1236L23.2341 50.6703C23.0895 50.7371 23.0309 50.8996 23.1032 51.0332L23.6321 52.0109C23.6769 52.0936 23.6728 52.1917 23.6213 52.271L21.7749 55.1151C21.6579 55.2954 21.7984 55.5247 22.0259 55.5247Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M30.951 42.0459C29.89 42.8938 28.4821 44.3307 28.0797 46.1903C27.3364 49.6248 25.1605 50.5809 24.7275 50.7361L23.9539 51.0936L24.2865 51.7084C24.4431 51.9979 24.4289 52.3413 24.2487 52.6189L22.8012 54.8485H32.9038C31.1271 50.1838 30.7511 46.9881 30.951 42.0459ZM24.7442 50.7284C24.7444 50.7283 24.7441 50.7285 24.7442 50.7284V50.7284ZM30.8687 40.3832C31.5976 39.8983 32.4933 40.4547 32.4554 41.1886C32.1626 46.8548 32.5118 50.0209 34.6328 55.2889L35 56.2009H22.0259C21.2297 56.2009 20.7379 55.3982 21.1476 54.7672L22.8699 52.114L22.4488 51.3356C22.1958 50.8679 22.4009 50.2992 22.9069 50.0654L24.0898 49.5188C24.1305 49.5 24.1664 49.4864 24.1959 49.4761C24.2325 49.4634 26.0133 48.8432 26.6448 45.9251C27.2743 43.0161 29.8377 41.0691 30.8687 40.3832Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask111" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="91">
          <path d="M4.53675 49.7888C3.4475 58.4894 3.19199 59.2022 2.16997 67.4455C0.260412 82.5607 0.421783 85.1829 2.3179 87.1328C4.21401 89.0827 7.19938 89.4458 13.076 90.0913C14.8242 90.293 13.977 90.0913 24.3316 89.9434C28.2046 89.9434 29.2804 89.9434 30.3965 89.0558C31.5127 88.1683 32.4674 86.7697 32.6154 80.4763C32.6864 75.9005 32.308 71.3288 31.4858 66.8269C30.141 58.3012 29.9796 59.2156 29.5628 55.5713C28.7021 48.0406 29.7241 47.3817 28.8231 40.7789C28.1526 36.6874 27.2137 32.6444 26.0126 28.6761C25.027 24.4905 23.7371 20.3826 22.1531 16.385C19.4636 10.2259 18.6299 11.1269 15.0528 3.35422C13.8291 0.664693 13.0999 0.6278 12.2661 0.695038C11.4324 0.762276 10.8975 1.31017 9.72753 9.00221C8.24829 18.1735 7.49523 24.3997 7.49523 24.3997C6.97077 28.7971 6.97077 29.1871 6.15047 36.0991C5.74704 39.6896 5.16879 44.7325 4.53675 49.7888Z" fill="#DEE1E9"/>
          <path d="M32.6126 80.4787C32.4646 86.7722 31.3888 88.2918 30.3937 89.0583C29.3986 89.8248 28.2017 89.9055 24.3154 89.9458C13.9742 90.0803 14.8214 90.2955 13.0732 90.0938C7.14279 89.4483 4.18431 89.1121 2.31509 87.1353C0.445874 85.1585 0.257608 82.5631 2.16717 67.448C2.78576 62.5396 3.12195 60.2939 3.51193 57.4699L5.55597 54.2828C6.72607 51.8128 8.71773 49.8259 11.1905 48.6617C13.8154 47.5516 16.7458 47.3946 19.4742 48.2179C21.0402 48.5849 22.5349 49.2073 23.8985 50.0602L29.5196 55.5469C29.9365 59.1912 30.0844 58.2364 31.4426 66.8025C32.2733 71.3128 32.6651 75.8929 32.6126 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask111)">
          <path d="M4.53675 49.7888C3.4475 58.4894 3.19199 59.2022 2.16997 67.4455C0.260412 82.5607 0.421783 85.1829 2.3179 87.1328C4.21401 89.0827 7.19938 89.4458 13.076 90.0913C14.8242 90.293 13.977 90.0913 24.3316 89.9434C28.2046 89.9434 29.2804 89.9434 30.3965 89.0558C31.5127 88.1683 32.4674 86.7697 32.6154 80.4763C32.6864 75.9005 32.308 71.3288 31.4858 66.8269C30.141 58.3012 29.9796 59.2156 29.5628 55.5713C28.7021 48.0406 29.7241 47.3817 28.8231 40.7789C28.1526 36.6874 27.2137 32.6444 26.0126 28.6761C25.027 24.4905 23.7371 20.3826 22.1531 16.385C19.4636 10.2259 18.6299 11.1269 15.0528 3.35422C13.8291 0.664693 13.0999 0.6278 12.2661 0.695038C11.4324 0.762276 10.8975 1.31017 9.72753 9.00221C8.24829 18.1735 7.49523 24.3997 7.49523 24.3997C6.97077 28.7971 6.97077 29.1871 6.15047 36.0991C5.74704 39.6896 5.16879 44.7325 4.53675 49.7888Z" fill="#DEE1E9"/>
          <path d="M32.6126 80.4787C32.4646 86.7722 31.3888 88.2918 30.3937 89.0583C29.3986 89.8248 28.2017 89.9055 24.3154 89.9458C13.9742 90.0803 14.8214 90.2955 13.0732 90.0938C7.14279 89.4483 4.18431 89.1121 2.31509 87.1353C0.445874 85.1585 0.257608 82.5631 2.16717 67.448C2.78576 62.5396 3.12195 60.2939 3.51193 57.4699L5.55597 54.2828C6.72607 51.8128 8.71773 49.8259 11.1905 48.6617C13.8154 47.5516 16.7458 47.3946 19.4742 48.2179C21.0402 48.5849 22.5349 49.2073 23.8985 50.0602L29.5196 55.5469C29.9365 59.1912 30.0844 58.2364 31.4426 66.8025C32.2733 71.3128 32.6651 75.8929 32.6126 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M23.7011 55.5315H31.8479C30.3703 51.561 30.1264 49.1504 30.329 44.9074C30.3367 44.7474 30.1662 44.6479 30.0362 44.7415C29.3501 45.2354 27.7385 46.5782 27.3479 48.5315C26.8746 50.8976 25.5057 51.4722 25.3604 51.527C25.3515 51.5303 25.3436 51.5337 25.3352 51.5379L24.5267 51.9421C24.428 51.9915 24.3879 52.1116 24.4373 52.2104L24.7988 52.9333C24.8293 52.9945 24.8266 53.067 24.7914 53.1257L23.5296 55.2286C23.4496 55.3619 23.5456 55.5315 23.7011 55.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M29.8004 45.5651C29.0753 46.1921 28.1131 47.2545 27.8381 48.6295C27.3302 51.169 25.8432 51.876 25.5473 51.9908L25.0187 52.2551L25.246 52.7097C25.353 52.9238 25.3432 53.1777 25.2201 53.3829L24.2309 55.0315H31.1349C29.9207 51.5824 29.6637 49.2194 29.8004 45.5651ZM25.5587 51.9851C25.5588 51.985 25.5586 51.9851 25.5587 51.9851V51.9851ZM29.7441 44.3357C30.2422 43.9771 30.8543 44.3885 30.8284 44.9312C30.6283 49.1208 30.867 51.4619 32.3164 55.3571L32.5674 56.0315H23.7011C23.157 56.0315 22.8209 55.4379 23.1008 54.9713L24.2779 53.0096L23.9901 52.434C23.8172 52.0882 23.9573 51.6677 24.3031 51.4948L25.1115 51.0906C25.1393 51.0767 25.1638 51.0667 25.184 51.0591C25.209 51.0497 26.426 50.5911 26.8576 48.4334C27.2877 46.2825 29.0395 44.8428 29.7441 44.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M5.03382 50.1378C3.94457 58.8384 3.68906 59.5511 2.66704 67.7945C0.757482 82.9096 0.918854 85.5319 2.81497 87.4818C4.71108 89.4317 7.69645 89.7948 13.5731 90.4403C15.3212 90.642 14.474 90.4403 24.8287 90.2924C28.7016 90.2924 29.7774 90.2923 30.8936 89.4048C32.0097 88.5173 32.9645 87.1187 33.1124 80.8252C33.1834 76.2494 32.8051 71.6778 31.9828 67.1759C30.6381 58.6501 30.4767 59.5646 30.0598 55.9203C29.1992 48.3896 30.2212 47.7307 29.3202 41.1279C28.6497 37.0364 27.7108 32.9934 26.5097 29.025C25.5241 24.8395 24.2342 20.7316 22.6502 16.7339C19.9607 10.5749 19.1269 11.4759 15.5499 3.70319C14.3261 1.01366 13.5999 0.790932 12.7661 0.85817C11.9324 0.925408 11.3945 1.65915 10.2246 9.35118C8.74536 18.5224 7.9923 24.7487 7.9923 24.7487C7.46784 29.1461 7.46784 29.536 6.64754 36.4481C6.24411 40.0386 5.66586 45.0815 5.03382 50.1378Z" fill="#DEE1E9"/>
        <mask id="mask211" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="47" width="34" height="45">
          <path d="M33.0574 80.9824C32.9095 87.2759 31.8337 88.7955 30.8385 89.562C29.8434 90.3285 28.6466 90.4092 24.7602 90.4495C14.419 90.584 15.2662 90.7991 13.518 90.5974C7.58761 89.9519 4.62913 89.6158 2.75992 87.639C0.890699 85.6622 0.702432 83.0668 2.61199 67.9516C3.23058 63.0433 3.56677 60.7975 3.95675 57.9735L6.00079 54.7864C7.17089 52.3164 9.16256 50.3295 11.6353 49.1653C14.2602 48.0552 17.1906 47.8982 19.9191 48.7216C21.485 49.0886 22.9798 49.711 24.3433 50.5639L29.9644 56.0505C30.3813 59.6948 30.5292 58.74 31.8874 67.3062C32.7181 71.8164 33.1099 76.3966 33.0574 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask211)">
          <path d="M33.0574 80.9824C32.9095 87.2759 31.8337 88.7955 30.8385 89.562C29.8434 90.3285 28.6466 90.4092 24.7602 90.4495C14.419 90.584 15.2662 90.7991 13.518 90.5974C7.58761 89.9519 4.62913 89.6158 2.75992 87.639C0.890699 85.6622 0.702432 83.0668 2.61199 67.9516C3.23058 63.0433 3.56677 60.7975 3.95675 57.9735L6.00079 54.7864C7.17089 52.3164 9.16256 50.3295 11.6353 49.1653C14.2602 48.0552 17.1906 47.8982 19.9191 48.7216C21.485 49.0886 22.9798 49.711 24.3433 50.5639L29.9644 56.0505C30.3813 59.6948 30.5292 58.74 31.8874 67.3062C32.7181 71.8164 33.1099 76.3966 33.0574 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7868 75.2009C15.4143 75.2009 13.2661 83.6026 13.2661 93.2009H15.9584H20.2661C20.2661 83.6026 18.1594 75.2009 16.7868 75.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M20.1926 75.1141C22.4099 72.2182 20.618 68.1603 19.909 53.7702C19.2774 41.0425 20.4891 40.3096 19.0582 33.5703C18.0012 28.6187 16.9828 27.278 15.3714 19.4841C14.0823 13.1917 13.7085 3.14751 13.3733 3.20114C13.0381 3.25477 13.3733 15.8016 13.3733 25.9552C13.3733 35.6797 12.8834 34.5714 12.8061 44.2423C12.703 58.3107 13.6569 59.9016 12.2389 65.5862C11.0916 70.1982 9.66069 72.2718 10.5373 74.3454C11.8651 77.5452 17.6531 78.4033 20.1926 75.1141Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M5.03382 50.1378C3.94457 58.8384 3.68906 59.5511 2.66704 67.7945C0.757482 82.9096 0.918854 85.5319 2.81497 87.4818C4.71108 89.4317 7.69645 89.7948 13.5731 90.4403C15.3212 90.642 14.474 90.4403 24.8287 90.2924C28.7016 90.2924 29.7774 90.2923 30.8936 89.4048C32.0097 88.5173 32.9645 87.1187 33.1124 80.8252C33.1834 76.2494 32.8051 71.6778 31.9828 67.1759C30.6381 58.6501 30.4767 59.5646 30.0598 55.9203C29.1992 48.3896 30.2212 47.7307 29.3202 41.1279C28.6497 37.0364 27.7108 32.9934 26.5097 29.025C25.5241 24.8395 24.2342 20.7316 22.6502 16.7339C19.9607 10.5749 19.1269 11.4759 15.5499 3.70319C14.3261 1.01366 13.5999 0.790932 12.7661 0.85817C11.9324 0.925408 11.3945 1.65915 10.2246 9.35118C8.74536 18.5224 7.9923 24.7487 7.9923 24.7487C7.46784 29.1461 7.46784 29.536 6.64754 36.4481C6.24411 40.0386 5.66586 45.0815 5.03382 50.1378Z" fill="#DEE1E9"/>
        <mask id="mask311" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="47" width="34" height="45">
          <path d="M33.0574 80.9824C32.9095 87.2759 31.8337 88.7955 30.8385 89.562C29.8434 90.3285 28.6466 90.4092 24.7602 90.4495C14.419 90.584 15.2662 90.7991 13.518 90.5974C7.58761 89.9519 4.62913 89.6158 2.75992 87.639C0.890699 85.6622 0.702432 83.0668 2.61199 67.9516C3.23058 63.0433 3.56677 60.7975 3.95675 57.9735L6.00079 54.7864C7.17089 52.3164 9.16256 50.3295 11.6353 49.1653C14.2602 48.0552 17.1906 47.8982 19.9191 48.7216C21.485 49.0886 22.9798 49.711 24.3433 50.5639L29.9644 56.0505C30.3813 59.6948 30.5292 58.74 31.8874 67.3062C32.7181 71.8164 33.1099 76.3966 33.0574 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask311)">
          <path d="M33.0574 80.9824C32.9095 87.2759 31.8337 88.7955 30.8385 89.562C29.8434 90.3285 28.6466 90.4092 24.7602 90.4495C14.419 90.584 15.2662 90.7991 13.518 90.5974C7.58761 89.9519 4.62913 89.6158 2.75992 87.639C0.890699 85.6622 0.702432 83.0668 2.61199 67.9516C3.23058 63.0433 3.56677 60.7975 3.95675 57.9735L6.00079 54.7864C7.17089 52.3164 9.16256 50.3295 11.6353 49.1653C14.2602 48.0552 17.1906 47.8982 19.9191 48.7216C21.485 49.0886 22.9798 49.711 24.3433 50.5639L29.9644 56.0505C30.3813 59.6948 30.5292 58.74 31.8874 67.3062C32.7181 71.8164 33.1099 76.3966 33.0574 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7809 81.2009C15.8005 81.2009 14.2661 87.2688 14.2661 94.2009H16.1892H19.2661C19.2661 87.2688 17.7613 81.2009 16.7809 81.2009Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
        <path d="M4.53675 49.9219C3.4475 58.6225 3.19199 59.3352 2.16997 67.5786C0.260412 82.6937 0.421783 85.316 2.3179 87.2659C4.21401 89.2158 7.19938 89.5789 13.076 90.2244C14.8242 90.4261 13.977 90.2244 24.3316 90.0764C28.2046 90.0764 29.2804 90.0764 30.3965 89.1889C31.5127 88.3014 32.4674 86.9028 32.6154 80.6093C32.6864 76.0335 32.308 71.4619 31.4858 66.96C30.141 58.4342 29.9796 59.3486 29.5628 55.7043C28.7021 48.1737 29.7241 47.5148 28.8231 40.912C28.1526 36.8204 27.2137 32.7774 26.0126 28.8091C25.027 24.6236 23.7371 20.5156 22.1531 16.518C19.4636 10.359 18.6299 11.26 15.0528 3.48727C13.8291 0.79775 13.0999 0.760856 12.2661 0.828094C11.4324 0.895333 10.8975 1.44323 9.72753 9.13526C8.24829 18.3065 7.49523 24.5328 7.49523 24.5328C6.97077 28.9301 6.97077 29.3201 6.15047 36.2322C5.74704 39.8227 5.16879 44.8656 4.53675 49.9219Z" fill="#DEE1E9"/>
        <path d="M32.6126 80.6118C32.4646 86.9053 31.3888 88.4249 30.3937 89.1914C29.3986 89.9579 28.2017 90.0386 24.3154 90.0789C13.9742 90.2134 14.8214 90.4285 13.0732 90.2268C7.14279 89.5813 4.18431 89.2451 2.31509 87.2683C0.445874 85.2915 0.257608 82.6962 2.16717 67.581C2.78576 62.6727 3.12195 60.4269 3.51193 57.6029L5.55597 54.4158C6.72607 51.9458 8.71773 49.9589 11.1905 48.7947C13.8154 47.6846 16.7458 47.5276 19.4742 48.351C21.0402 48.718 22.5349 49.3404 23.8985 50.1933L29.5196 55.6799C29.9365 59.3242 30.0844 58.3694 31.4426 66.9356C32.2733 71.4458 32.6651 76.026 32.6126 80.6118Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.2661 75.2009L15.1328 77.7018L16.7661 76.2061V79.5201L19.3283 78.9522L17.9328 80.7028L20.2661 82.7035L17.9328 84.7042L19.3283 86.4548L16.9994 85.7045L16.7661 89.2009L14.5162 87.7039L13.7328 88.7056L12.016 87.7039L9.76611 89.2009L9.06611 87.2051L7.20394 86.4548L7.66611 83.7038L6.26611 82.7035L8.59945 81.203L7.20394 78.9522L9.06611 78.7021L9.76611 76.2061L12.7994 78.202L13.2661 75.2009Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M4.53675 49.9219C3.4475 58.6225 3.19199 59.3352 2.16997 67.5786C0.260412 82.6937 0.421783 85.316 2.3179 87.2659C4.21401 89.2158 7.19938 89.5789 13.076 90.2244C14.8242 90.4261 13.977 90.2244 24.3316 90.0764C28.2046 90.0764 29.2804 90.0764 30.3965 89.1889C31.5127 88.3014 32.4674 86.9028 32.6154 80.6093C32.6864 76.0335 32.308 71.4619 31.4858 66.96C30.141 58.4342 29.9796 59.3486 29.5628 55.7043C28.7021 48.1737 29.7241 47.5148 28.8231 40.912C28.1526 36.8204 27.2137 32.7774 26.0126 28.8091C25.027 24.6236 23.7371 20.5156 22.1531 16.518C19.4636 10.359 18.6299 11.26 15.0528 3.48727C13.8291 0.79775 13.0999 0.760856 12.2661 0.828094C11.4324 0.895333 10.8975 1.44323 9.72753 9.13526C8.24829 18.3065 7.49523 24.5328 7.49523 24.5328C6.97077 28.9301 6.97077 29.3201 6.15047 36.2322C5.74704 39.8227 5.16879 44.8656 4.53675 49.9219Z" fill="#DEE1E9"/>
        <path d="M32.6126 80.6118C32.4646 86.9053 31.3888 88.4249 30.3937 89.1914C29.3986 89.9579 28.2017 90.0386 24.3154 90.0789C13.9742 90.2134 14.8214 90.4285 13.0732 90.2268C7.14279 89.5813 4.18431 89.2451 2.31509 87.2683C0.445874 85.2915 0.257608 82.6962 2.16717 67.581C2.78576 62.6727 3.12195 60.4269 3.51193 57.6029L5.55597 54.4158C6.72607 51.9458 8.71773 49.9589 11.1905 48.7947C13.8154 47.6846 16.7458 47.5276 19.4742 48.351C21.0402 48.718 22.5349 49.3404 23.8985 50.1933L29.5196 55.6799C29.9365 59.3242 30.0844 58.3694 31.4426 66.9356C32.2733 71.4458 32.6651 76.026 32.6126 80.6118Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M8.26611 77.2009L9.86611 79.3445L11.2661 78.0625V80.9031L13.4623 80.4163L12.2661 81.9168L14.2661 83.6317L12.2661 85.3466L13.4623 86.8471L11.4661 86.204L11.2661 89.2009L9.33762 87.9178L8.66611 88.7763L7.1946 87.9178L5.26611 89.2009L4.66611 87.4902L3.06996 86.8471L3.46611 84.4891L2.26611 83.6317L4.26611 82.3456L3.06996 80.4163L4.66611 80.202L5.26611 78.0625L7.86611 79.7732L8.26611 77.2009Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M4.53675 49.8542C3.4475 58.5548 3.19199 59.2676 2.16997 67.511C0.260412 82.6261 0.421783 85.2484 2.3179 87.1983C4.21401 89.1482 7.19938 89.5113 13.076 90.1567C14.8242 90.3585 13.977 90.1567 24.3316 90.0088C28.2046 90.0088 29.2804 90.0088 30.3965 89.1213C31.5127 88.2337 32.4674 86.8352 32.6154 80.5417C32.6864 75.9659 32.308 71.3942 31.4858 66.8924C30.141 58.3666 29.9796 59.281 29.5628 55.6367C28.7021 48.1061 29.7241 47.4471 28.8231 40.8443C28.1526 36.7528 27.2137 32.7098 26.0126 28.7415C25.027 24.556 23.7371 20.448 22.1531 16.4504C19.4636 10.2914 18.6299 11.1924 15.0528 3.41965C13.8291 0.730123 13.0999 0.693229 12.2661 0.760468C11.4324 0.827706 10.8975 1.3756 9.72753 9.06764C8.24829 18.2389 7.49523 24.4651 7.49523 24.4651C6.97077 28.8625 6.97077 29.2525 6.15047 36.1646C5.74704 39.7551 5.16879 44.7979 4.53675 49.8542Z" fill="#DEE1E9"/>
        <path d="M32.6126 80.5442C32.4646 86.8376 31.3888 88.3572 30.3937 89.1237C29.3986 89.8903 28.2017 89.9709 24.3154 90.0113C13.9742 90.1457 14.8214 90.3609 13.0732 90.1592C7.14279 89.5137 4.18431 89.1775 2.31509 87.2007C0.445874 85.2239 0.257608 82.6285 2.16717 67.5134C2.78576 62.605 3.12195 60.3593 3.51193 57.5353L5.55597 54.3482C6.72607 51.8782 8.71773 49.8913 11.1905 48.7271C13.8154 47.617 16.7458 47.46 19.4742 48.2833C21.0402 48.6503 22.5349 49.2728 23.8985 50.1257L29.5196 55.6123C29.9365 59.2566 30.0844 58.3018 31.4426 66.8679C32.2733 71.3782 32.6651 75.9583 32.6126 80.5442Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M9.76611 82.1333L10.6994 83.3837L11.5161 82.6359V84.2929L12.7972 84.0089L12.0994 84.8842L13.2661 85.8846L12.0994 86.8849L12.7972 87.7602L11.6328 87.3851L11.5161 89.1333L10.3912 88.3848L9.99945 88.8856L9.14106 88.3848L8.01611 89.1333L7.66611 88.1354L6.73502 87.7602L6.96611 86.3848L6.26611 85.8846L7.43278 85.1343L6.73502 84.0089L7.66611 83.8839L8.01611 82.6359L9.53278 83.6338L9.76611 82.1333Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M13.0156 2.68065L15.0993 1.41089H18.5831L20.6831 2.68065L19.0714 4.22716L21.1551 5.77367L19.2668 7.32018L21.5621 9.0132L19.7389 10.5597L22.0342 11.9597L20.0156 13.376L22.0342 14.9876L20.3412 16.5341L22.5714 17.9504L20.8133 19.4155L23.1086 20.962L20.9598 22.5085L23.1086 24.1201L21.5621 25.4713L23.6458 27.0178L21.8226 28.4992L23.9063 30.0457L22.0993 31.7876L23.1086 33.4643V47.7247L21.2203 55.0503H15.0993" fill="#8889BE"/>
        <path d="M13.0156 2.68065L15.0993 1.41089H18.5831L20.6831 2.68065L19.0714 4.22716L21.1551 5.77367L19.2668 7.32018L21.5621 9.0132L19.7389 10.5597L22.0342 11.9597L20.0156 13.376L22.0342 14.9876L20.3412 16.5341L22.5714 17.9504L20.8133 19.4155L23.1086 20.962L20.9598 22.5085L23.1086 24.1201L21.5621 25.4713L23.6458 27.0178L21.8226 28.4992L23.9063 30.0457L22.0993 31.7876L23.1086 33.4643V47.7247L21.2203 55.0503H15.0993" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M20.4229 2.68065L18.3392 1.41089H14.8718L12.7555 2.68065L14.3834 4.22716L12.2834 5.77367L14.1718 7.32018L11.8927 9.0132L13.6997 10.5597L11.4206 11.9597L13.4392 13.376L11.4206 14.9876L13.0973 16.5341L10.8834 17.9504L12.6252 19.4155L10.3462 20.962L12.495 22.5085L10.3462 24.1201L11.8927 25.4713L9.80897 27.0178L11.6159 28.4992L9.53223 30.0457L11.3555 31.7876L10.3462 33.4643V47.7247L12.2183 55.0503H16.1252" fill="#8889BE"/>
        <path d="M20.4229 2.68065L18.3392 1.41089H14.8718L12.7555 2.68065L14.3834 4.22716L12.2834 5.77367L14.1718 7.32018L11.8927 9.0132L13.6997 10.5597L11.4206 11.9597L13.4392 13.376L11.4206 14.9876L13.0973 16.5341L10.8834 17.9504L12.6252 19.4155L10.3462 20.962L12.495 22.5085L10.3462 24.1201L11.8927 25.4713L9.80897 27.0178L11.6159 28.4992L9.53223 30.0457L11.3555 31.7876L10.3462 33.4643V47.7247L12.2183 55.0503H16.1252" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M30.3937 73.2683C31.3888 72.5018 32.4646 70.9822 32.6126 64.6888C32.6651 60.1029 32.2733 55.5228 31.4426 51.0125C30.0844 42.4464 29.6897 41.1843 29.2728 37.54L24.6496 37.54C24.0973 37.54 23.6496 37.9877 23.6496 38.54V51.0125C17.6819 52.2298 15.9423 52.2176 9.97695 51.0125V38.54C9.97695 37.9877 9.52923 37.54 8.97695 37.54L4.39971 37.54L3.51193 41.6799C3.12195 44.5039 2.78576 46.7496 2.16717 51.658C0.257608 66.7731 0.445874 69.3685 2.31509 71.3453C4.18431 73.3221 7.14279 73.6583 13.0732 74.3038C14.8214 74.5055 13.9742 74.2903 24.3154 74.1559C28.2017 74.1155 29.3986 74.0348 30.3937 73.2683Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask511" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="37" width="34" height="38">
          <path d="M30.3869 73.2683C31.382 72.5018 32.4578 70.9822 32.6057 64.6887C32.6582 60.1029 32.2664 55.5228 31.4358 51.0125C30.0776 42.4464 29.6829 42.0999 29.266 38.4556L24.6428 38.4556C24.0905 38.4556 23.6428 38.9033 23.6428 39.4556V51.0125C17.6751 52.2298 15.9355 52.2175 9.97011 51.0125V39.4556C9.97011 38.9033 9.5224 38.4556 8.97011 38.4556L4.39288 38.4556L3.50509 41.6798C3.11511 44.5038 2.77892 46.7496 2.16033 51.658C0.250772 66.7731 0.439038 69.3685 2.30826 71.3453C4.17747 73.3221 7.13595 73.6583 13.0663 74.3037C14.8145 74.5055 13.9673 74.2903 24.3085 74.1558C28.1949 74.1155 29.3917 74.0348 30.3869 73.2683Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask511)">
          <path d="M6.69037 30.2808H26.7344V55.8287C18.9067 57.755 14.5181 57.7745 6.69037 55.8287V30.2808Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
        <path d="M13.0156 2.3557L15.0993 1.08594H18.5831L20.6831 2.3557L19.0714 3.90221L21.1551 5.44872L19.2668 6.99523L21.5621 8.68825L19.7389 10.2348L22.0342 11.6348L20.0156 13.051L22.0342 14.6627L20.3412 16.2092L22.5714 17.6254L20.8133 19.0906L23.1086 20.6371L20.9598 22.1836L23.1086 23.7952L21.5621 25.1464L23.6458 26.6929L21.8226 28.1743L23.9063 29.7208L22.0993 31.4626L23.1086 33.1394V47.3998L21.2203 54.7254H15.0993" fill="#8889BE"/>
        <path d="M13.0156 2.3557L15.0993 1.08594H18.5831L20.6831 2.3557L19.0714 3.90221L21.1551 5.44872L19.2668 6.99523L21.5621 8.68825L19.7389 10.2348L22.0342 11.6348L20.0156 13.051L22.0342 14.6627L20.3412 16.2092L22.5714 17.6254L20.8133 19.0906L23.1086 20.6371L20.9598 22.1836L23.1086 23.7952L21.5621 25.1464L23.6458 26.6929L21.8226 28.1743L23.9063 29.7208L22.0993 31.4626L23.1086 33.1394V47.3998L21.2203 54.7254H15.0993" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M20.4229 2.3557L18.3392 1.08594H14.8718L12.7555 2.3557L14.3834 3.90221L12.2834 5.44872L14.1718 6.99523L11.8927 8.68825L13.6997 10.2348L11.4206 11.6348L13.4392 13.051L11.4206 14.6627L13.0973 16.2092L10.8834 17.6254L12.6252 19.0906L10.3462 20.6371L12.495 22.1836L10.3462 23.7952L11.8927 25.1464L9.80897 26.6929L11.6159 28.1743L9.53223 29.7208L11.3555 31.4626L10.3462 33.1394V47.3998L12.2183 54.7254H16.1252" fill="#8889BE"/>
        <path d="M20.4229 2.3557L18.3392 1.08594H14.8718L12.7555 2.3557L14.3834 3.90221L12.2834 5.44872L14.1718 6.99523L11.8927 8.68825L13.6997 10.2348L11.4206 11.6348L13.4392 13.051L11.4206 14.6627L13.0973 16.2092L10.8834 17.6254L12.6252 19.0906L10.3462 20.6371L12.495 22.1836L10.3462 23.7952L11.8927 25.1464L9.80897 26.6929L11.6159 28.1743L9.53223 29.7208L11.3555 31.4626L10.3462 33.1394V47.3998L12.2183 54.7254H16.1252" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M30.3937 73.7083C31.3888 72.9418 32.4646 71.4222 32.6126 65.1287C32.6651 60.5429 32.2733 55.9627 31.4426 51.4525C30.0844 42.8863 29.6897 41.6243 29.2728 37.98H23.3589C22.8067 37.98 22.3589 38.4277 22.3589 38.98V51.4525C16.3913 52.6698 16.7065 52.6575 10.7411 51.4525V38.98C10.7411 38.4277 10.2934 37.98 9.74112 37.98H4.39971L3.51193 42.1198C3.12195 44.9438 2.78576 47.1896 2.16717 52.098C0.257608 67.2131 0.445874 69.8085 2.31509 71.7853C4.18431 73.7621 7.14279 74.0982 13.0732 74.7437C14.8214 74.9454 13.9742 74.7303 24.3154 74.5958C28.2017 74.5555 29.3986 74.4748 30.3937 73.7083Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M0 87.771H21V92.771H0V87.771Z" fill="#838AA2"/>
        <path d="M7.7223 49.4924C6.63304 58.193 6.37754 58.9058 5.35552 67.1492C3.44596 82.2643 3.60733 84.8866 5.50344 86.8365C7.39956 88.7864 10.3849 89.1494 16.2615 89.7949C18.0097 89.9966 17.1625 89.7949 27.5172 89.647C31.3901 89.647 32.4659 89.647 33.5821 88.7595C34.6982 87.8719 35.653 86.4734 35.8009 80.1799C35.8719 75.6041 35.4936 71.0324 34.6713 66.5306C33.3266 58.0048 33.1652 58.9192 32.7483 55.2749C31.8877 47.7442 32.9097 47.0853 32.0087 40.4825C31.3381 36.391 30.3993 32.348 29.1981 28.3797C28.2126 24.1942 26.9226 20.0862 25.3387 16.0886C22.6492 9.92956 21.8154 10.8305 18.2383 3.05783C17.0146 0.368306 16.2854 0.331413 15.4517 0.398651C14.6179 0.465889 14.083 1.01379 12.9131 8.70582C11.4338 17.8771 10.6808 24.1033 10.6808 24.1033C10.1563 28.5007 10.1563 28.8907 9.33601 35.8027C8.93258 39.3933 8.35434 44.4361 7.7223 49.4924Z" fill="#DEE1E9"/>
        <path d="M33.5793 88.762C34.5744 87.9955 35.6502 86.4759 35.7981 80.1824C35.8506 75.5966 35.4588 71.0165 34.6282 66.5062C33.27 57.9401 32.8753 56.678 32.4584 53.0337H26.5445C25.9922 53.0337 25.5445 53.4814 25.5445 54.0337V66.5062C19.5768 67.7235 19.892 67.7112 13.9267 66.5062V54.0337C13.9267 53.4814 13.4789 53.0337 12.9267 53.0337H7.58526L6.69748 57.1735C6.3075 59.9975 5.97131 62.2433 5.35272 67.1517C3.44316 82.2668 3.63142 84.8622 5.50064 86.839C7.36986 88.8158 10.3283 89.152 16.2587 89.7974C18.0069 89.9992 17.1597 89.784 27.5009 89.6495C31.3873 89.6092 32.5841 89.5285 33.5793 88.762Z" fill="#838AA2"/>

    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M4.7223 49.2892C3.63304 57.9898 3.37754 58.7025 2.35552 66.9459C0.445959 82.061 0.60733 84.6833 2.50344 86.6332C4.39956 88.5831 7.38493 88.9462 13.2615 89.5916C15.0097 89.7934 14.1625 89.5916 24.5172 89.4437C28.3901 89.4437 29.4659 89.4437 30.5821 88.5562C31.6982 87.6686 32.653 86.2701 32.8009 79.9766C32.8719 75.4008 32.4936 70.8291 31.6713 66.3273C30.3266 57.8015 30.1652 58.7159 29.7483 55.0716C28.8877 47.541 29.9097 46.882 29.0087 40.2793C28.3381 36.1877 27.3993 32.1447 26.1981 28.1764C25.2126 23.9909 23.9226 19.8829 22.3387 15.8853C19.6492 9.72628 18.8154 10.6273 15.2383 2.85455C14.0146 0.165031 13.2883 -0.0577013 12.4546 0.00953674C11.6208 0.0767748 11.083 0.810513 9.91308 8.50255C8.43384 17.6738 7.68077 23.9001 7.68077 23.9001C7.15632 28.2974 7.15632 28.6874 6.33601 35.5995C5.93258 39.19 5.35434 44.2329 4.7223 49.2892Z" fill="#DEE1E9"/>
        <path d="M32.7981 79.9792C32.6502 86.2727 31.5744 87.7923 30.5793 88.5588C29.5841 89.3253 28.3873 89.406 24.5009 89.4463C14.1597 89.5808 15.0069 89.796 13.2587 89.5942C7.32833 88.9488 4.36986 88.6126 2.50064 86.6358C0.631421 84.659 0.443155 82.0636 2.35272 66.9485C2.97131 62.0401 3.3075 59.7943 3.69748 56.9704L5.74151 53.7833C6.91162 51.3133 8.90328 49.3264 11.3761 48.1622C14.0009 47.0521 16.9314 46.8951 19.6598 47.7184C21.2257 48.0854 22.7205 48.7078 24.0841 49.5607L29.7052 55.0473C30.122 58.6916 30.27 57.7369 31.6282 66.303C32.4588 70.8133 32.8506 75.3934 32.7981 79.9792Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M18.2971 55.9968C20.0611 53.8182 18.6356 50.7656 18.0715 39.9403C17.5689 30.3656 18.533 29.8142 17.3946 24.7445C16.5536 21.0195 15.7434 20.011 14.4614 14.1478C13.4358 9.41424 13.1384 1.85826 12.8717 1.8986C12.6051 1.93894 12.8717 11.3776 12.8717 19.0158C12.8717 26.3313 12.482 25.4976 12.4204 32.7727C12.3384 43.356 13.0973 44.5528 11.9692 48.8292C11.0564 52.2987 9.91799 53.8586 10.6154 55.4185C11.6718 57.8256 16.2767 58.4711 18.2971 55.9968Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M4.76771 49.9366C3.67845 58.6372 3.42295 59.3499 2.40093 67.5933C0.491369 82.7084 0.65274 85.3307 2.54885 87.2806C4.44497 89.2305 7.43034 89.5936 13.3069 90.2391C15.0551 90.4408 14.2079 90.2391 24.5626 90.0912C28.4355 90.0912 29.5113 90.0912 30.6275 89.2036C31.7436 88.3161 32.6984 86.9175 32.8463 80.624C32.9173 76.0483 32.539 71.4766 31.7167 66.9747C30.372 58.4489 30.2106 59.3634 29.7937 55.7191C28.9331 48.1884 29.9551 47.5295 29.0541 40.9267C28.3835 36.8352 27.4447 32.7922 26.2435 28.8239C25.258 24.6383 23.968 20.5304 22.3841 16.5328C19.6946 10.3737 18.8608 11.2747 15.2837 3.50201C14.06 0.812492 13.3338 0.58976 12.5 0.656998C11.6662 0.724236 11.1284 1.45797 9.95849 9.15001C8.47925 18.3213 7.72618 24.5475 7.72618 24.5475C7.20173 28.9449 7.20173 29.3349 6.38142 36.2469C5.97799 39.8374 5.39975 44.8803 4.76771 49.9366Z" fill="#DEE1E9"/>
        <path d="M32.8435 80.6267C32.6956 86.9202 31.6198 88.4397 30.6247 89.2063C29.6295 89.9728 28.4327 90.0534 24.5463 90.0938C14.2051 90.2283 15.0523 90.4434 13.3041 90.2417C7.37374 89.5962 4.41527 89.26 2.54605 87.2832C0.676831 85.3064 0.488565 82.7111 2.39813 67.5959C3.01672 62.6876 3.35291 60.4418 3.74289 57.6178L5.78692 54.4307C6.95703 51.9607 8.94869 49.9738 11.4215 48.8096C14.0463 47.6995 16.9768 47.5425 19.7052 48.3659C21.2711 48.7328 22.7659 49.3553 24.1295 50.2082L29.7506 55.6948C30.1674 59.3391 30.3154 58.3843 31.6736 66.9505C32.5042 71.4607 32.896 76.0409 32.8435 80.6267Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.4" d="M18.3425 56.6442C20.1065 54.4657 18.681 51.4131 18.1169 40.5878C17.6143 31.0131 18.5784 30.4617 17.44 25.392C16.599 21.667 15.7888 20.6584 14.5068 14.7953C13.4812 10.0617 13.1838 2.50572 12.9171 2.54606C12.6505 2.5864 12.9171 12.025 12.9171 19.6633C12.9171 26.9788 12.5274 26.145 12.4659 33.4202C12.3838 44.0035 13.1427 45.2003 12.0146 49.4766C11.1018 52.9461 9.9634 54.5061 10.6608 56.066C11.7172 58.4731 16.3221 59.1186 18.3425 56.6442Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
        <path d="M4.76783 49.9371C3.67858 58.6377 3.42307 59.3504 2.40105 67.5938C0.491491 82.7089 0.652862 85.3312 2.54898 87.2811C4.44509 89.231 7.43046 89.5941 13.3071 90.2396C15.0553 90.4413 14.2081 90.2396 24.5627 90.0917C28.4356 90.0917 29.5114 90.0917 30.6276 89.2041C31.7437 88.3166 32.6985 86.918 32.8464 80.6245C32.9175 76.0488 32.5391 71.4771 31.7168 66.9752C30.3721 58.4494 30.2107 59.3639 29.7938 55.7196C28.9332 48.1889 29.9552 47.53 29.0542 40.9272C28.3837 36.8357 27.4448 32.7927 26.2437 28.8244C25.2581 24.6388 23.9682 20.5309 22.3842 16.5332C19.6947 10.3742 18.8609 11.2752 15.2839 3.5025C14.0601 0.812981 13.3339 0.590248 12.5001 0.657486C11.6664 0.724724 11.1286 1.45846 9.95861 9.1505C8.47937 18.3218 7.72631 24.548 7.72631 24.548C7.20185 28.9454 7.20185 29.3354 6.38155 36.2474C5.97812 39.8379 5.39987 44.8808 4.76783 49.9371Z" fill="#DEE1E9"/>
        <path d="M32.8436 80.6272C32.6957 86.9206 31.6199 88.4402 30.6248 89.2067C29.6297 89.9733 28.4328 90.0539 24.5465 90.0943C14.2052 90.2287 15.0525 90.4439 13.3043 90.2422C7.37386 89.5967 4.41539 89.2605 2.54617 87.2837C0.676953 85.3069 0.488687 82.7115 2.39825 67.5964C3.01684 62.688 3.35303 60.4423 3.74301 57.6183L5.78705 54.4312C6.95715 51.9612 8.94881 49.9743 11.4216 48.8101C14.0464 47.7 16.9769 47.543 19.7053 48.3663C21.2712 48.7333 22.766 49.3558 24.1296 50.2087L29.7507 55.6953C30.1676 59.3396 30.3155 58.3848 31.6737 66.9509C32.5044 71.4612 32.8962 76.0413 32.8436 80.6272Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask511" mask-type="alpha" maskUnits="userSpaceOnUse" x="10" y="1" width="10" height="25">
          <path d="M11.9393 3.53054L12.6392 1.84033H14.039L15.0271 2.92005L19.5559 25.2887H10.7865L11.157 15.3626L11.61 6.5825L11.9393 3.53054Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask511)">
          <path opacity="0.4" d="M18.3426 56.6447C20.1067 54.4662 18.6811 51.4136 18.117 40.5883C17.6145 31.0136 18.5785 30.4622 17.4401 25.3924C16.5991 21.6675 15.7889 20.6589 14.5069 14.7957C13.4813 10.0622 13.1839 2.5062 12.9172 2.54655C12.6506 2.58689 12.9172 12.0255 12.9172 19.6638C12.9172 26.9793 12.5275 26.1455 12.466 33.4207C12.3839 44.0039 13.1429 45.2008 12.0147 49.4771C11.1019 52.9466 9.96352 54.5065 10.6609 56.0665C11.7173 58.4736 16.3222 59.1191 18.3426 56.6447Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5112" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="25" width="12" height="37">
          <path d="M19.7462 25.355H11.3691L9.57952 61.4703L20.7629 59.437L19.7462 25.355Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5112)">
          <path opacity="0.2" d="M18.3426 56.6447C20.1067 54.4662 18.6811 51.4136 18.117 40.5883C17.6145 31.0136 18.5785 30.4622 17.4401 25.3924C16.5991 21.6675 15.7889 20.6589 14.5069 14.7957C13.4813 10.0622 13.1839 2.5062 12.9172 2.54655C12.6506 2.58689 12.9172 12.0255 12.9172 19.6638C12.9172 26.9793 12.5275 26.1455 12.466 33.4207C12.3839 44.0039 13.1429 45.2008 12.0147 49.4771C11.1019 52.9466 9.96352 54.5065 10.6609 56.0665C11.7173 58.4736 16.3222 59.1191 18.3426 56.6447Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M4.27064 50.2566C3.18138 58.9572 2.92588 59.67 1.90386 67.9134C-0.0057013 83.0285 0.15567 85.6508 2.05178 87.6007C3.9479 89.5506 6.93327 89.9137 12.8099 90.5591C14.5581 90.7608 13.7109 90.5591 24.0655 90.4112C27.9384 90.4112 29.0142 90.4112 30.1304 89.5237C31.2466 88.6361 32.2013 87.2376 32.3493 80.9441C32.4203 76.3683 32.0419 71.7966 31.2197 67.2948C29.8749 58.769 29.7135 59.6834 29.2966 56.0391C28.436 48.5085 29.458 47.8495 28.557 41.2467C27.8865 37.1552 26.9476 33.1122 25.7465 29.1439C24.7609 24.9584 23.471 20.8504 21.887 16.8528C19.1975 10.6938 18.3637 11.5948 14.7867 3.82204C13.5629 1.13252 12.3338 0.932739 11.5 0.999977C10.6662 1.06722 10.6314 1.778 9.46142 9.47004C7.98218 18.6413 7.22911 24.8675 7.22911 24.8675C6.70466 29.2649 6.70466 29.6549 5.88435 36.567C5.48092 40.1575 4.90268 45.2003 4.27064 50.2566Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M4.27064 49.5937C3.18138 58.2943 2.92588 59.0071 1.90386 67.2505C-0.0057013 82.3656 0.15567 84.9879 2.05178 86.9378C3.9479 88.8877 6.93327 89.2508 12.8099 89.8962C14.5581 90.098 13.7109 89.8962 24.0655 89.7483C27.9384 89.7483 29.0142 89.7483 30.1304 88.8608C31.2466 87.9732 32.2013 86.5747 32.3493 80.2812C32.4203 75.7054 32.0419 71.1337 31.2197 66.6319C29.8749 58.1061 29.7135 59.0205 29.2966 55.3762C28.436 47.8456 29.458 47.1866 28.557 40.5839C27.8865 36.4923 26.9476 32.4493 25.7465 28.481C24.7609 24.2955 23.471 20.1875 21.887 16.1899C19.1975 10.0309 18.3637 10.9319 14.7867 3.15915C13.5629 0.469625 12.8338 0.432731 12 0.499969C11.1662 0.567208 10.6314 1.11511 9.46142 8.80714C7.98218 17.9784 7.22911 24.2046 7.22911 24.2046C6.70466 28.602 6.70466 28.992 5.88435 35.9041C5.48092 39.4946 4.90268 44.5374 4.27064 49.5937Z" fill="#DEE1E9"/>
      <path d="M32.3464 80.2837C32.1985 86.5772 31.1227 88.0968 30.1276 88.8633C29.1325 89.6298 27.9356 89.7105 24.0493 89.7508C13.7081 89.8853 14.5553 90.1005 12.8071 89.8987C6.87667 89.2533 3.9182 88.9171 2.04898 86.9403C0.179761 84.9635 -0.00850505 82.3681 1.90106 67.253C2.51965 62.3446 2.85584 60.0988 3.24582 57.2748L5.28985 54.0878C6.45996 51.6178 8.45162 49.6309 10.9244 48.4667C13.5492 47.3565 16.4797 47.1996 19.2081 48.0229C20.774 48.3899 22.2688 49.0123 23.6324 49.8652L29.2535 55.3518C29.6704 58.9961 29.8183 58.0414 31.1765 66.6075C32.0072 71.1178 32.399 75.6979 32.3464 80.2837Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth11",
  props: {
    status: {}
  },
}
</script>
