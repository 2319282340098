<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M7 0C3.14017 0 0 3.14017 0 7C0 10.8598 3.14017 14 7 14C10.8598 14 14 10.8598 14 7C14 3.14017 10.8598 0 7 0ZM7 11.686C4.41619 11.686 2.31405 9.58387 2.31405 7C2.31405 4.41613 4.41619 2.31405 7 2.31405C9.58381 2.31405 11.686 4.41613 11.686 7C11.686 9.58387 9.58381 11.686 7 11.686Z"
      fill="#0787C9"/>
  <path d="M7.9224 4.9224V6.49047H6.0776V4.9224C6.0776 4.41296 6.49056 4 7 4C7.50944 4 7.9224 4.41296 7.9224 4.9224Z"
        fill="#0787C9"/>
  <path
      d="M6.0776 9.0776L6.0776 6.49047H7.9224V9.0776C7.9224 9.58704 7.50944 10 7 10C6.49056 10 6.0776 9.58704 6.0776 9.0776Z"
      fill="#0787C9"/>
  <path d="M4.9224 6.0776H6.49047V7.9224H4.9224C4.41296 7.9224 4 7.50944 4 7C4 6.49056 4.41296 6.0776 4.9224 6.0776Z"
        fill="#0787C9"/>
  <path d="M9.0776 7.9224H6.49047V6.0776H9.0776C9.58704 6.0776 10 6.49056 10 7C10 7.50944 9.58704 7.9224 9.0776 7.9224Z"
        fill="#0787C9"/>
</svg>
</template>
