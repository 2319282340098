<template>
  <b-modal id="calendar-settings-modal"
           size="lg"
           :title="$t('calendar_settings')"
           centered>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'showDoctorColor'"
             :id="'showDoctorColor'"
             :value="true === showDoctorColor ? 1 : 0"
             :checked="true === showDoctorColor"
             @input="handleShowDoctorColor"/>
      <label class="mb-0"
             :for="'showDoctorColor'">{{ $t('showDoctorColor') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'showServiceColor'"
             :id="'showServiceColor'"
             :value="true === showServiceColor ? 1 : 0"
             :checked="true === showServiceColor"
             @input="handleShowServiceColor"/>
      <label class="mb-0"
             :for="'showServiceColor'">{{ $t('showServiceColor') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'showStatusColor'"
             :id="'showStatusColor'"
             :value="true === showStatusColor ? 1 : 0"
             :checked="true === showStatusColor"
             @input="handleShowStatusColor"/>
      <label class="mb-0"
             :for="'showStatusColor'">{{ $t('showStatusColor') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'showOverflowScreen'"
             :id="'showOverflowScreen'"
             :value="true === showOverflowScreen ? 1 : 0"
             :checked="true === showOverflowScreen"
             @input="handleShowOverflowScreen"/>
      <label class="mb-0"
             :for="'showOverflowScreen'">{{ $t('showOverflowScreen') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'handleShowClientTags'"
             :id="'handleShowClientTags'"
             :value="true === showClientTags ? 1 : 0"
             :checked="true === showClientTags"
             @input="handleShowClientTags"/>
      <label class="mb-0"
             :for="'handleShowClientTags'">{{ $t('showClientTags') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'handleFillDoctorColor'"
             :id="'handleFillDoctorColor'"
             :value="true === fillDoctorColor ? 1 : 0"
             :checked="true === fillDoctorColor"
             @input="handleFillDoctorColor"/>
      <label class="mb-0"
             :for="'handleFillDoctorColor'">{{ $t('fillDoctorColor') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'handleHideDoctorsWithoutSchedule'"
             id="handleHideDoctorsWithoutSchedule"
             :value="true === hideDoctorsWithoutSchedule ? 1 : 0"
             :checked="true === hideDoctorsWithoutSchedule"
             @input="handleHideDoctorsWithoutSchedule"/>
      <label class="mb-0"
             for="handleHideDoctorsWithoutSchedule">{{ $t('hideDoctorsWithoutSchedule') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'handleHideChairsWithoutSchedule'"
             id="handleHideChairsWithoutSchedule"
             :value="true === hideChairsWithoutSchedule ? 1 : 0"
             :checked="true === hideChairsWithoutSchedule"
             @input="handleHideChairsWithoutSchedule"/>
      <label class="mb-0"
             for="handleHideChairsWithoutSchedule">{{ $t('hideChairsWithoutSchedule') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'handleShowPlannedServices'"
             :id="'handleShowPlannedServices'"
             :value="true === showPlannedServices ? 1 : 0"
             :checked="true === showPlannedServices"
             @input="handleShowPlannedServices"/>
      <label class="mb-0"
             :for="'handleShowPlannedServices'">{{ $t('showPlannedServices') }}</label>
    </div>
    <div class="checkbox form-group">
      <input type="checkbox"
             :name="'showRecordTagColor'"
             :id="'showRecordTagColor'"
             :value="true === showRecordTagColor ? 1 : 0"
             :checked="true === showRecordTagColor"
             @input="handleShowRecordTagColor"/>
      <label class="mb-0"
             :for="'showRecordTagColor'">{{ $t('showRecordTagColor') }}</label>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>
<!--        <b-button class="ml-2" variant="themed" :disabled="loading"  @click="save">{{ $t('save') }}</b-button>-->
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "DCalendarSettingsModal",
  computed: {
    ...mapState({
      showDoctorColor: state => state.calendar.showDoctorColor,
      showRecordTagColor: state => state.calendar.showRecordTagColor,
      showServiceColor: state => state.calendar.showServiceColor,
      showStatusColor: state => state.calendar.showStatusColor,
      showOverflowScreen: state => state.calendar.showOverflowScreen,
      showClientTags: state => state.calendar.showClientTags,
      showPlannedServices: state => state.calendar.showPlannedServices,
      fillDoctorColor: state => state.calendar.fillDoctorColor,
      hideDoctorsWithoutSchedule: state => state.calendar.hideDoctorsWithoutSchedule,
      hideChairsWithoutSchedule: state => state.calendar.hideChairsWithoutSchedule,
    }),
  },
  methods: {
    handleShowDoctorColor(e) {
      this.$store.commit('setShowDoctorColor', e.target.checked)
    },
    handleShowServiceColor(e) {
      this.$store.commit('setShowServiceColor', e.target.checked)
    },
    handleShowStatusColor(e) {
      let val = e.target.checked
      this.$store.commit('setShowStatusColor', e.target.checked)
      if(val) {
        this.$store.commit('setFillDoctorColor', false)
        this.$store.commit('setShowRecordTagColor', false)
      }
    },
    handleShowOverflowScreen(e) {
      this.$store.commit('setShowOverflowScreen', e.target.checked)
    },
    handleShowClientTags(e) {
      this.$store.commit('setShowClientTags', e.target.checked)
      if(e.target.checked) this.$store.commit('incrementDataTableCounter')
    },
    handleShowPlannedServices(e) {
      this.$store.commit('setShowPlannedServices', e.target.checked)
    },
    handleFillDoctorColor(e) {
      let val = e.target.checked
      this.$store.commit('setFillDoctorColor', val)
      if(val) {
        this.$store.commit('setShowStatusColor', false)
        this.$store.commit('setShowRecordTagColor', false)
      }
    },
    handleHideDoctorsWithoutSchedule(e) {
      this.$store.commit('setHideDoctorsWithoutSchedule', e.target.checked)
    },
    handleHideChairsWithoutSchedule(e) {
      this.$store.commit('setHideChairsWithoutSchedule', e.target.checked)
    },
    handleShowRecordTagColor(e) {
      this.$store.commit('setShowRecordTagColor', e.target.checked)
      if(e.target.checked) {
        this.$store.commit('setShowStatusColor', false)
        this.$store.commit('setFillDoctorColor', false)
      }
    },
  },
}
</script>
