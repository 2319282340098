export const joditConfig = {
	config: {
		maxHeight: 600,
		i18n: 'ru',
		language: 'ru',
		theme: 'dentist',
	},
	buttons: [
		'bold', 'italic', 'underline', 'strikethrough', 'eraser',
		'|',
		'ul', 'ol',
		'|',
		'font', 'fontsize', 'paragraph', 'lineHeight',
		'|',
		'superscript', 'subscript', 'spellcheck',
		'|',
		'cut', 'copy', 'paste', 'copyformat',
		'|',
		'hr', 'table', 'symbols',
		'---',
		'indent', 'outdent', 'left', 'center', 'right',
		'|',
		'undo', 'redo',
		'|',
		'fullsize', 'source', 'print'
	]
}
