import { materialGroupAdapter } from "@/adapters/warehouse/materialGroup"

export default {
    primaryKey: "id",
    model: 'materialgroup',
    order: 'level_sorting',
    sort: 'asc',
    adapter: materialGroupAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_group',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    pagination: 999,
    hidesorting: true,
    trcomponent: "WarehouseDatatableTRMaterialGroup",
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: false
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: false,
            filter: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'parent_id',
            title: 'parent_group',
            table: true,
            table_show: 'parent.title',
            sortable: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title",
                },
            }
        },
    ]
}
