<template>
  <b-modal id="options-popup-modal"
           hide-footer
           :title="title"
           size="sm"
           @close="emit('changed', selected)"
           centered>
    <a v-for="option in options"
       class="d-flex justify-content-between align-items-center pointer py-2 text-black border-bottom"
       :key="`opt-o-${option.id}`"
       @click="saveResult(option.id)">
      <span>{{ option.title }}</span>
    </a>
  </b-modal>
</template>

<script setup>
import {getCurrentInstance, onMounted, ref} from "vue"

defineProps({
  options: {
    type: Array,
    default: () => []
  },
  title: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['changed'])
const selected = ref(null)

let bvModal = null

onMounted(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
})

const saveResult = async (option) => {
  bvModal.hide('options-popup-modal')
  emit('changed', option)
}
</script>
