<template>
  <a @click="modal" class="pointer position-relative">
    <BirthdaySvg/>
    <span class="notification-bubble" v-if="birthdaysCounter > 0">
      {{ birthdaysCounter }}
    </span>
  </a>
</template>

<script>

import BirthdaySvg from '@/assets/svg-vue/header/birthday.svg'
import {mapState} from "vuex"

export default {
  name: "HeaderBirthday",
  components: {
    BirthdaySvg
  },
  computed: {
    ...mapState({
      headerCounters: state => state.auth.headerCounters
    }),
    birthdaysCounter() {
      return this.headerCounters.birthdays
    }
  },
  methods: {
    modal() {
      this.$bvModal.show('happy-birthday-modal')
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
.notification-bubble {
  top: 0;
  right: 8px;
}
</style>
