<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>
    <g class="implant_corona" v-if="status && status.id == 11">
        <path d="M12.2998 2.40276L14.3835 1.133H17.8672L19.9672 2.40276L18.3556 3.94927L20.4393 5.49578L18.551 7.04229L20.8463 8.73531L19.023 10.2818L21.3184 11.6818L19.2998 13.0981L21.3184 14.7097L19.6254 16.2562L21.8556 17.6725L20.0975 19.1376L22.3928 20.6841L20.244 22.2306L22.3928 23.8423L20.8463 25.1934L22.93 26.7399L21.1068 28.2213L23.1905 29.7678L21.3835 31.5097L22.3928 33.1864V47.4469L20.5044 54.7724H14.3835" fill="#8889BE"/>
        <path d="M19.7071 2.40276L17.6234 1.133H14.1559L12.0397 2.40276L13.6676 3.94927L11.5676 5.49578L13.4559 7.04229L11.1769 8.73531L12.9838 10.2818L10.7048 11.6818L12.7234 13.0981L10.7048 14.7097L12.3815 16.2562L10.1676 17.6725L11.9094 19.1376L9.63036 20.6841L11.7792 22.2306L9.63036 23.8423L11.1769 25.1934L9.09315 26.7399L10.9001 28.2213L8.81641 29.7678L10.6397 31.5097L9.63036 33.1864V47.4469L11.5024 54.7724H15.4094" fill="#8889BE"/>
        <path d="M30.8243 71.2115C30.1653 72.5563 28.592 73.901 25.4452 76.4023C24.0811 77.6418 22.589 78.7327 20.9941 79.6566C19.1123 80.7465 16.9523 81.2608 14.7813 81.1358C11.7555 80.7324 11.5942 78.6615 4.85693 72.8387C2.40946 70.7274 1.61605 70.2971 1.01091 68.8044C0.0426795 66.4645 0.674718 64.1918 1.75053 60.8165C3.45837 55.4374 4.30557 52.7479 5.44862 50.0584C5.66378 49.5474 6.01342 48.3775 6.53788 46.4948L8.9181 44.5987C9.90114 43.1999 11.2249 42.0751 12.7641 41.3309C14.5607 40.4828 16.5648 40.1745 18.5331 40.4434C18.5331 40.4434 22.2043 39.65 28.2827 48.1757C28.8206 50.4215 29.4526 53.0034 29.6274 53.7565C30.5645 57.3956 31.1588 61.1144 31.4025 64.8642C31.6715 69.3423 31.1201 70.6198 30.8243 71.2115Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--        <path d="M16.5185 21.4311C16.2764 22.48 15.9268 24.0265 15.483 25.8688C14.7165 29.1366 13.318 35.0401 10.4536 45.852C7.7641 56.1529 6.41934 61.3033 5.86799 62.5808C4.72494 65.2703 3.8643 67.9599 2.15645 73.3389C1.09409 76.7277 0.462052 78.9869 1.41683 81.3402C2.02197 82.7926 2.76159 83.2095 5.2763 85.3745C12.0001 91.1839 12.1615 93.2682 15.1872 93.6582C17.3635 93.7947 19.5311 93.2798 21.4134 92.179C23.0066 91.2589 24.4947 90.1676 25.8511 88.9247C28.9575 86.3831 30.5175 85.0787 31.2302 83.7474C31.526 83.1557 32.0639 81.8781 31.8219 77.3732C31.5793 73.6276 30.985 69.9132 30.0468 66.2789C29.7241 64.9342 27.8683 57.2556 27.8279 57.0942C26.5639 51.7151 26.9404 41.9253 26.9404 39.3299C26.9404 29.3383 27.2497 27.2271 26.6446 21.5656C26.3284 18.4195 25.7845 15.3004 25.0174 12.233C24.189 9.22421 23.2015 6.26155 22.0589 3.35753C21.4672 1.77071 21.0907 0.842834 20.5797 0.842834C19.3559 0.842834 18.4281 6.04705 18.1995 7.32457C17.5136 11.2244 17.8633 12.8919 17.164 17.8675C16.9085 19.414 16.6799 20.6512 16.5185 21.4311Z" fill="#DEE1E9"/>-->
<!--        <path d="M31.2361 83.737C30.5772 85.0818 29.0038 86.4265 25.8571 88.9278C24.4929 90.1673 23.0008 91.2582 21.4059 92.1821C19.5242 93.272 17.3641 93.7863 15.1931 93.6614C12.1674 93.2579 12.006 91.187 5.26879 85.3642C2.82133 83.2529 2.02792 82.8226 1.42277 81.3299C0.454545 78.99 1.08658 76.7174 2.16239 73.342C3.87024 67.963 4.71744 65.2734 5.86048 62.5839C6.07565 62.0729 6.42528 60.903 6.94974 59.0203L9.32997 57.1242C10.313 55.7254 11.6368 54.6006 13.176 53.8564C14.9725 53.0083 16.9766 52.7 18.945 52.9689C18.945 52.9689 22.6162 52.1755 28.6945 60.7013C29.2324 62.947 29.8645 65.5289 30.0393 66.282C30.9763 69.9211 31.5706 73.6399 31.8144 77.3897C32.0833 81.8678 31.532 83.1453 31.2361 83.737Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--        <path opacity="0.2" d="M17.8003 69.9905C16.0655 69.9905 14.1963 63.7642 13.8601 57.9952L15.7966 41.8581C16.59 36.7345 17.3027 32.5792 17.8003 29.7552C18.9433 23.26 19.145 22.48 19.4947 19.6964C20.2477 12.5288 19.9384 3.61375 20.5301 3.53307C21.1218 3.45238 21.7808 11.5606 22.3052 14.9628C22.9641 19.2795 22.5204 21.6866 22.1573 27.6843C21.6918 32.3581 21.5929 37.0612 21.8614 41.7505C22.2111 45.5562 22.7759 47.4254 23.0448 52.8582C23.246 55.3813 23.0966 57.9202 22.6011 60.4023C22.1474 62.4066 21.452 64.3483 20.5301 66.1848C19.266 68.7129 18.6206 69.9905 17.8003 69.9905Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->
        <path d="M16.7846 20.7892C16.5426 21.8381 16.1929 23.3846 15.7491 25.2269C14.9826 28.4947 13.5841 34.3982 10.7197 45.2101C8.03022 55.511 6.68546 60.6614 6.1341 61.9389C4.99106 64.6284 4.13041 67.318 2.42256 72.697C1.3602 76.0858 0.728165 78.345 1.68295 80.6983C2.28809 82.1507 3.02771 82.5676 5.54241 84.7326C12.2662 90.542 12.4276 92.6263 15.4533 93.0163C17.6296 93.1528 19.7972 92.6379 21.6795 91.5371C23.2727 90.6169 24.7608 89.5257 26.1173 88.2828C29.2237 85.7412 30.7836 84.4368 31.4963 83.1054C31.7921 82.5137 32.3301 81.2362 32.088 76.7313C31.8454 72.9857 31.2511 69.2713 30.3129 65.637C29.9902 64.2923 28.1344 56.6137 28.0941 56.4523C26.83 51.0732 27.2065 41.2834 27.2065 38.688C27.2065 28.6964 27.5158 26.5851 26.9107 20.9237C26.5945 17.7776 26.0507 14.6585 25.2835 11.5911C24.4551 8.5823 23.4676 5.61964 22.325 2.71562C21.7333 1.12881 21.3568 0.200928 20.8458 0.200928C19.6221 0.200928 18.6942 5.40514 18.4656 6.68267C17.7797 10.5825 18.1294 12.25 17.4301 17.2256C17.1746 18.7721 16.946 20.0093 16.7846 20.7892Z" fill="#DEE1E9"/>
        <mask id="413" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="33" height="43">
          <path d="M31.5577 83.0635C30.8987 84.4082 29.3254 85.753 26.1786 88.2543C24.8145 89.4938 23.3224 90.5847 21.7275 91.5086C19.8457 92.5985 17.6857 93.1128 15.5147 92.9878C12.4889 92.5844 12.3276 90.5135 5.59032 84.6907C3.14286 82.5794 2.34945 82.1491 1.74431 80.6564C0.776078 78.3165 1.40812 76.0438 2.48392 72.6685C4.19177 67.2894 5.03897 64.5999 6.18202 61.9104C6.39718 61.3994 6.74682 60.2294 7.27127 58.3468L9.6515 56.4507C10.6345 55.0519 11.9583 53.9271 13.4975 53.1829C15.2941 52.3348 17.2982 52.0265 19.2665 52.2954C19.2665 52.2954 22.9377 51.5019 29.0161 60.0277C29.554 62.2735 30.186 64.8554 30.3608 65.6085C31.2979 69.2476 31.8922 72.9664 32.1359 76.7162C32.4049 81.1943 31.8535 82.4718 31.5577 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#413)">
          <path d="M31.5577 83.0635C30.8987 84.4082 29.3254 85.753 26.1786 88.2543C24.8145 89.4938 23.3224 90.5847 21.7275 91.5086C19.8457 92.5985 17.6857 93.1128 15.5147 92.9878C12.4889 92.5844 12.3276 90.5135 5.59032 84.6907C3.14286 82.5794 2.34945 82.1491 1.74431 80.6564C0.776078 78.3165 1.40812 76.0438 2.48392 72.6685C4.19177 67.2894 5.03897 64.5999 6.18202 61.9104C6.39718 61.3994 6.74682 60.2294 7.27127 58.3468L9.6515 56.4507C10.6345 55.0519 11.9583 53.9271 13.4975 53.1829C15.2941 52.3348 17.2982 52.0265 19.2665 52.2954C19.2665 52.2954 22.9377 51.5019 29.0161 60.0277C29.554 62.2735 30.186 64.8554 30.3608 65.6085C31.2979 69.2476 31.8922 72.9664 32.1359 76.7162C32.4049 81.1943 31.8535 82.4718 31.5577 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7809 76.2009C15.8005 76.2009 14.2661 84.6026 14.2661 94.2009H16.1892H19.2661C19.2661 84.6026 17.7613 76.2009 16.7809 76.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.9409 78.2009C14.8827 78.2009 12.665 71.1744 12.2661 64.6639L14.5636 46.4526C15.5049 40.6705 16.3506 35.9811 16.9409 32.7941C18.297 25.4641 18.5364 24.5839 18.9512 21.4424C19.8447 13.3536 19.4777 3.29266 20.1797 3.2016C20.8817 3.11054 21.6635 12.2609 22.2858 16.1005C23.0675 20.972 22.541 23.6885 22.1102 30.457C21.558 35.7316 21.4406 41.0392 21.7592 46.3312C22.1741 50.626 22.8442 52.7355 23.1633 58.8666C23.4019 61.714 23.2247 64.5792 22.6368 67.3804C22.0985 69.6422 21.2735 71.8335 20.1797 73.9061C18.68 76.7592 17.9141 78.2009 16.9409 78.2009Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
        <path d="M16.5185 23.5883C16.2764 24.6372 15.9268 26.1837 15.483 28.026C14.7165 31.2938 13.318 37.1973 10.4536 48.0092C7.7641 58.31 6.41934 63.4605 5.86799 64.738C4.72494 67.4275 3.8643 70.117 2.15645 75.4961C1.09409 78.8849 0.462052 81.1441 1.41683 83.4974C2.02197 84.9497 2.76159 85.3666 5.2763 87.5317C12.0001 93.3411 12.1615 95.4254 15.1872 95.8154C17.3635 95.9519 19.5311 95.4369 21.4134 94.3362C23.0066 93.416 24.4947 92.3248 25.8511 91.0819C28.9575 88.5403 30.5175 87.2358 31.2302 85.9045C31.526 85.3128 32.0639 84.0353 31.8219 79.5304C31.5793 75.7848 30.985 72.0704 30.0468 68.4361C29.7241 67.0913 27.8683 59.4127 27.8279 59.2514C26.5639 53.8723 26.9404 44.0824 26.9404 41.4871C26.9404 31.4955 27.2497 29.3842 26.6446 23.7228C26.3284 20.5767 25.7845 17.4576 25.0174 14.3901C24.189 11.3814 23.2015 8.41872 22.0589 5.5147C21.4672 3.92788 21.0907 3 20.5797 3C19.3559 3 18.4281 8.20422 18.1995 9.48174C17.5136 13.3815 17.8633 15.0491 17.164 20.0247C16.9085 21.5712 16.6799 22.8083 16.5185 23.5883Z" fill="#DEE1E9"/>
        <path d="M31.2361 85.8942C30.5772 87.2389 29.0038 88.5837 25.8571 91.0849C24.4929 92.3245 23.0008 93.4154 21.4059 94.3393C19.5242 95.4292 17.3641 95.9435 15.1931 95.8185C12.1674 95.4151 12.006 93.3442 5.26879 87.5213C2.82133 85.4101 2.02792 84.9797 1.42277 83.4871C0.454545 81.1472 1.08658 78.8745 2.16239 75.4992C3.87024 70.1201 4.71744 67.4306 5.86048 64.7411C6.07565 64.2301 6.42528 63.0601 6.94974 61.1775L9.32997 59.2814C10.313 57.8826 11.6368 56.7578 13.176 56.0136C14.9725 55.1655 16.9766 54.8571 18.945 55.126C18.945 55.126 22.6162 54.3326 28.6945 62.8584C29.2324 65.1042 29.8645 67.6861 30.0393 68.4392C30.9763 72.0782 31.5706 75.7971 31.8144 79.5469C32.0833 84.025 31.532 85.3025 31.2361 85.8942Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M17.8003 72.1476C16.0655 72.1476 14.1963 65.9214 13.8601 60.1524L15.7966 44.0152C16.59 38.8917 17.3027 34.7364 17.8003 31.9124C18.9433 25.4172 19.145 24.6372 19.4947 21.8536C20.2477 14.686 19.9384 5.77092 20.5301 5.69023C21.1218 5.60955 21.7808 13.7178 22.3052 17.12C22.9641 21.4367 22.5204 23.8438 22.1573 29.8414C21.6918 34.5153 21.5929 39.2184 21.8614 43.9076C22.2111 47.7133 22.7759 49.5825 23.0448 55.0154C23.246 57.5385 23.0966 60.0773 22.6011 62.5595C22.1474 64.5637 21.452 66.5054 20.5301 68.342C19.266 70.8701 18.6206 72.1476 17.8003 72.1476Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="20" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
        <path d="M16.1066 21.2571C15.8646 22.306 15.5149 23.8524 15.0712 25.6948C14.3047 28.9625 12.9061 34.866 10.0418 45.6779C7.35224 55.9788 6.00748 61.1292 5.45613 62.4067C4.31308 65.0963 3.45243 67.7858 1.74459 73.1648C0.682224 76.5536 0.0501867 78.8128 1.00497 81.1662C1.61011 82.6185 2.34973 83.0354 4.86443 85.2004C11.5882 91.0098 11.7496 93.0942 14.7753 93.4842C16.9516 93.6207 19.1192 93.1057 21.0016 92.0049C22.5948 91.0848 24.0828 89.9936 25.4393 88.7506C28.5457 86.209 30.1056 84.9046 30.8183 83.5733C31.1142 82.9816 31.6521 81.7041 31.41 77.1991C31.1674 73.4536 30.5731 69.7391 29.6349 66.1048C29.3122 64.7601 27.4564 57.0815 27.4161 56.9201C26.152 51.5411 26.5285 41.7512 26.5285 39.1558C26.5285 29.1642 26.8378 27.053 26.2327 21.3915C25.9165 18.2454 25.3727 15.1264 24.6055 12.0589C23.7772 9.05013 22.7896 6.08748 21.6471 3.18346C21.0554 1.59664 20.6788 0.668762 20.1678 0.668762C18.9441 0.668762 18.0162 5.87298 17.7876 7.1505C17.1018 11.0503 17.4514 12.7178 16.7521 17.6934C16.4966 19.2399 16.268 20.4771 16.1066 21.2571Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M16.1066 21.2571C15.8646 22.306 15.5149 23.8524 15.0712 25.6948C14.3047 28.9625 12.9061 34.866 10.0418 45.6779C7.35224 55.9788 6.00748 61.1292 5.45613 62.4067C4.31308 65.0963 3.45243 67.7858 1.74459 73.1648C0.682224 76.5536 0.0501867 78.8128 1.00497 81.1662C1.61011 82.6185 2.34973 83.0354 4.86443 85.2004C11.5882 91.0098 11.7496 93.0942 14.7753 93.4842C16.9516 93.6207 19.1192 93.1057 21.0016 92.0049C22.5948 91.0848 24.0828 89.9936 25.4393 88.7506C28.5457 86.209 30.1056 84.9046 30.8183 83.5733C31.1142 82.9816 31.6521 81.7041 31.41 77.1991C31.1674 73.4536 30.5731 69.7391 29.6349 66.1048C29.3122 64.7601 27.4564 57.0815 27.4161 56.9201C26.152 51.5411 26.5285 41.7512 26.5285 39.1558C26.5285 29.1642 26.8378 27.053 26.2327 21.3915C25.9165 18.2454 25.3727 15.1264 24.6055 12.0589C23.7772 9.05013 22.7896 6.08748 21.6471 3.18346C21.0554 1.59664 20.6788 0.668762 20.1678 0.668762C18.9441 0.668762 18.0162 5.87298 17.7876 7.1505C17.1018 11.0503 17.4514 12.7178 16.7521 17.6934C16.4966 19.2399 16.268 20.4771 16.1066 21.2571Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
        <path d="M10.3931 25.3527C10.8369 23.5104 11.1865 21.9639 11.4285 20.915C11.5899 20.135 11.8185 18.8979 12.074 17.3514C12.7733 12.3758 12.4237 10.7083 13.1095 6.80846L13.1114 6.79789C13.3432 5.50251 14.2693 0.326721 15.4897 0.326721C16.0007 0.326721 16.3773 1.2546 16.969 2.84142C18.1115 5.74544 19.0991 8.70809 19.9274 11.7168C20.6946 14.7843 21.2384 17.9034 21.5546 21.0495C22.1597 26.7109 21.8504 28.8222 21.8504 38.8138C21.8504 41.4092 21.4739 51.199 22.738 56.5781C22.7783 56.7395 24.6341 64.418 24.9568 65.7628L18.1567 64.1347L11.4285 65.3544L6.28975 63.6852L0.805773 65.0337C1.35712 63.7562 2.67415 55.6367 5.36368 45.3359C8.22802 34.524 9.62657 28.6205 10.3931 25.3527Z" fill="#DEE1E9"/>
        <path d="M2.76547 58.7874C11.5864 57.6536 16.1282 58.7824 23.6586 63.3393" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M10.7066 41.342L8.77014 57.4791V66.0977L11.3534 65.6687H15.4402C16.3621 63.8321 17.0575 61.8904 17.5111 59.8862C18.0067 57.4041 18.156 54.8652 17.9549 52.3421C17.6859 46.9093 17.1211 45.04 16.7715 41.2344C16.5029 36.5451 16.6018 31.842 17.0673 27.1682C17.4304 21.1705 17.8742 18.7634 17.2152 14.4467C16.6908 11.0445 16.0319 2.93627 15.4402 3.01696C14.8485 3.09764 15.1578 12.0127 14.4047 19.1803C14.0551 21.9639 13.8533 22.7439 12.7103 29.2391C12.2127 32.0631 11.5 36.2184 10.7066 41.342Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M15.3931 25.9406C15.8368 24.0983 16.1865 22.5518 16.4285 21.5029C16.5899 20.723 16.8185 19.4858 17.074 17.9393C17.7733 12.9637 17.4236 11.2962 18.1095 7.39638L18.1114 7.38581C18.3432 6.09043 19.2693 0.914642 20.4897 0.914642C21.0007 0.914642 21.3772 1.84252 21.9689 3.42934C23.1115 6.33336 24.0991 9.29601 24.9274 12.3048C25.6946 15.3723 26.2384 18.4913 26.5546 21.6374C27.1597 27.2989 26.8504 29.4101 26.8504 39.4017C26.8504 41.9971 26.4739 51.787 27.738 57.166C27.7783 57.3274 29.6341 65.006 29.9568 66.3507L5.77802 62.6526C6.32936 61.3751 7.67411 56.2248 10.3636 45.924L10.3637 45.9238C13.228 35.1119 14.6265 29.2084 15.3931 25.9406Z" fill="#DEE1E9"/>
        <path d="M25.7671 88.9996C28.9139 86.4983 30.4872 85.1536 31.1462 83.8088C31.442 83.2171 31.9934 81.9396 31.7244 77.4616C31.4807 73.7117 30.8864 69.9929 29.9493 66.3538C29.7745 65.6007 29.1425 63.0188 28.6046 60.7731C22.5262 52.2473 18.855 53.0407 18.855 53.0407C16.8867 52.7718 14.8826 53.0801 13.086 53.9282C11.5468 54.6724 10.223 55.7972 9.24001 57.196L6.85978 59.0921C6.33532 60.9748 5.98568 62.1447 5.77052 62.6557C4.65316 65.2848 3.8185 67.9139 2.18626 73.0553L2.07243 73.4138C1.87311 74.0392 1.68902 74.6267 1.52753 75.1838L5.77052 78.5688L11.2505 78.1898L10.3434 80.835L11.551 83.3019L10.3434 85.6153L14.1525 87.1301L12.8137 90.1651L11.7942 91.8822C12.91 92.9984 13.6975 93.5457 15.1032 93.7332C17.2742 93.8581 19.4342 93.3438 21.316 92.2539C22.9109 91.33 24.403 90.2391 25.7671 88.9996Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M5.28672 70.8955C15.9626 74.6688 18.2874 79.2162 16.6619 91.1517" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M17.7103 70.0623C15.9756 70.0623 14.1063 63.836 13.7701 58.067L15.7066 41.9299C16.5 36.8063 17.2127 32.651 17.7103 29.827C18.8533 23.3318 19.0551 22.5519 19.4047 19.7682C20.1578 12.6006 19.8485 3.68556 20.4402 3.60487C21.0319 3.52419 21.6908 11.6324 22.2152 15.0347C22.8742 19.3513 22.4304 21.7584 22.0673 27.7561C21.6018 32.4299 21.5029 37.133 21.7715 41.8223C22.1211 45.628 22.6859 47.4972 22.9549 52.93C23.156 55.4531 23.0067 57.992 22.5111 60.4741C22.0575 62.4784 21.3621 64.4201 20.4402 66.2566C19.1761 68.7848 18.5306 70.0623 17.7103 70.0623Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M16.2879 1.07636L12.7378 10.9872C12.7378 10.9872 9.76584 18.3969 10.2096 22.3908C10.6534 26.3848 9.17415 37.7883 7.99076 40.7468C7.39906 42.2261 6.14844 47.9278 5.03228 53.2531C3.91613 58.5783 2.96135 63.5405 2.96135 63.5405C2.96135 63.5405 -0.346761 72.1336 1.2804 77.163C2.90756 82.1924 14.1632 82.542 14.1632 82.542C14.1632 82.542 20.5239 83.4295 24.0741 81.6545C27.6243 79.8794 25.7013 70.3585 25.7013 70.3585L23.6303 51.7201L21.1156 35.1257L19.3405 21.0595L18.8968 9.06423L17.8613 1.81597C17.8613 1.81597 16.5972 0.0543443 16.2879 1.07636Z" fill="#DEE1E9"/>
        <path d="M14.1665 82.5487C14.1665 82.5487 20.5273 83.4228 24.0774 81.6477C27.6411 79.8726 25.6911 70.3651 25.6911 70.3651L23.424 51.7671H19.6541C19.1018 51.7671 18.6541 52.2148 18.6541 52.7671V61.6049C14.4585 62.7986 13.6123 62.6835 9.41659 61.6049V52.7671C9.41659 52.2148 8.96887 51.7671 8.41659 51.7671H5.30457L4.98182 53.3136C3.87912 58.6388 2.91089 63.601 2.91089 63.601C2.91089 63.601 -0.343431 72.0999 1.28373 77.1696C2.91089 82.2394 14.1665 82.5487 14.1665 82.5487Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M16.3727 21.2362C16.1307 22.2851 15.7811 23.8316 15.3373 25.6739C14.5708 28.9417 13.1722 34.8452 10.3079 45.6571C7.61835 55.958 6.27359 61.1084 5.72224 62.3859C4.57919 65.0754 3.71855 67.765 2.0107 73.144C0.948338 76.5328 0.3163 78.792 1.27108 81.1453C1.87622 82.5977 2.61584 83.0145 5.13054 85.1796C11.8544 90.989 12.0157 93.0733 15.0414 93.4633C17.2177 93.5998 19.3853 93.0849 21.2677 91.9841C22.8609 91.0639 24.3489 89.9727 25.7054 88.7298C28.8118 86.1882 30.3717 84.8838 31.0844 83.5524C31.3803 82.9607 31.9182 81.6832 31.6761 77.1783C31.4335 73.4327 30.8392 69.7183 29.901 66.084C29.5783 64.7393 27.7225 57.0607 27.6822 56.8993C26.4181 51.5202 26.7946 41.7304 26.7946 39.135C26.7946 29.1434 27.1039 27.0321 26.4988 21.3707C26.1826 18.2246 25.6388 15.1055 24.8716 12.0381C24.0433 9.0293 23.0557 6.06664 21.9132 3.16262C21.3215 1.57581 20.9449 0.647926 20.4339 0.647926C19.2102 0.647926 18.2823 5.85214 18.0537 7.12966C17.3679 11.0295 17.7175 12.697 17.0182 17.6726C16.7627 19.2191 16.5341 20.4563 16.3727 21.2362Z" fill="#DEE1E9"/>
        <path d="M25.7079 89.6954C28.8546 87.1942 30.428 85.8494 31.0869 84.5046C31.3827 83.9129 31.9341 82.6354 31.6651 78.1574C31.4214 74.4075 30.8271 70.6887 29.8901 67.0496C29.7152 66.2966 28.6087 62.0337 28.0708 59.7879H24.271C23.7187 59.7879 23.271 60.2357 23.271 60.7879V70.7588C18.7869 71.8756 16.2669 71.8637 11.7828 70.7588V60.7879C11.7828 60.2357 11.3351 59.7879 10.7828 59.7879H6.80051C6.27605 61.6706 5.92642 62.8405 5.71125 63.3516C4.56821 66.0411 3.72101 68.7306 2.01316 74.1096C0.937352 77.485 0.305314 79.7576 1.27354 82.0975C1.87868 83.5902 2.67209 84.0205 5.11956 86.1318C11.8568 91.9546 12.0182 94.0256 15.0439 94.429C17.2149 94.5539 19.3749 94.0397 21.2567 92.9498C22.8516 92.0259 24.3437 90.9349 25.7079 89.6954Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M16.3727 21.3039C16.1307 22.3528 15.7811 23.8993 15.3373 25.7416C14.5708 29.0094 13.1722 34.9129 10.3079 45.7247C7.61835 56.0256 6.27359 61.176 5.72224 62.4536C4.57919 65.1431 3.71855 67.8326 2.0107 73.2117C0.948338 76.6004 0.3163 78.8596 1.27108 81.213C1.87622 82.6653 2.61584 83.0822 5.13054 85.2473C11.8544 91.0566 12.0157 93.141 15.0414 93.531C17.2177 93.6675 19.3853 93.1525 21.2677 92.0518C22.8609 91.1316 24.3489 90.0404 25.7054 88.7974C28.8118 86.2558 30.3717 84.9514 31.0844 83.6201C31.3803 83.0284 31.9182 81.7509 31.6761 77.2459C31.4335 73.5004 30.8392 69.7859 29.901 66.1517C29.5783 64.8069 27.7225 57.1283 27.6822 56.9669C26.4181 51.5879 26.7946 41.798 26.7946 39.2026C26.7946 29.2111 27.1039 27.0998 26.4988 21.4384C26.1826 18.2922 25.6388 15.1732 24.8716 12.1057C24.0433 9.09695 23.0557 6.13429 21.9132 3.23027C21.3215 1.64345 20.9449 0.715576 20.4339 0.715576C19.2102 0.715576 18.2823 5.91979 18.0537 7.19731C17.3679 11.0971 17.7175 12.7646 17.0182 17.7403C16.7627 19.2867 16.5341 20.5239 16.3727 21.3039Z" fill="#DEE1E9"/>
        <path d="M25.7079 89.7631C28.8546 87.2618 30.428 85.917 31.0869 84.5723C31.3827 83.9806 31.9341 82.7031 31.6651 78.225C31.4214 74.4752 30.8271 70.7564 29.8901 67.1173C29.7152 66.3642 28.6087 62.1013 28.0708 59.8556H23.271V70.8264C18.7869 71.9432 16.2669 71.9314 11.7828 70.8264V59.8556H6.80051C6.27605 61.7383 5.92642 62.9082 5.71125 63.4192C4.56821 66.1087 3.72101 68.7982 2.01316 74.1773C0.937352 77.5526 0.305314 79.8253 1.27354 82.1652C1.87868 83.6579 2.67209 84.0882 5.11956 86.1995C11.8568 92.0223 12.0182 94.0932 15.0439 94.4966C17.2149 94.6216 19.3749 94.1073 21.2567 93.0174C22.8516 92.0935 24.3437 91.0026 25.7079 89.7631Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask2013" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="60" width="33" height="36">
          <path d="M25.7079 89.7631C28.8546 87.2618 30.428 85.917 31.0869 84.5723C31.3827 83.9806 31.9341 82.7031 31.6651 78.225C31.4214 74.4752 30.8271 70.7563 29.8901 67.1173C29.7152 66.3642 28.6087 63.033 28.0708 60.7873H24.271C23.7187 60.7873 23.271 61.235 23.271 61.7873V70.8264C18.7869 71.9432 16.2669 71.9314 11.7828 70.8264V61.7873C11.7828 61.235 11.3351 60.7873 10.7828 60.7873L6.80051 60.7873C6.27605 62.6699 5.92642 62.9082 5.71125 63.4192C4.56821 66.1087 3.72101 68.7982 2.01316 74.1773C0.937352 77.5526 0.305314 79.8253 1.27354 82.1652C1.87868 83.6578 2.67209 84.0882 5.11956 86.1994C11.8568 92.0223 12.0182 94.0932 15.0439 94.4966C17.2149 94.6216 19.3749 94.1073 21.2567 93.0174C22.8516 92.0935 24.3437 91.0026 25.7079 89.7631Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2013)">
          <path d="M8.97382 49.6524H25.8684V75.2004C19.2706 77.1266 15.5715 77.1462 8.97382 75.2004V49.6524Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M16.6673 21.4589C16.4252 22.5078 16.0756 24.0543 15.6318 25.8966C14.8653 29.1644 13.4668 35.0679 10.6024 45.8798C7.91291 56.1806 6.56815 61.3311 6.0168 62.6086C4.87375 65.2981 4.0131 67.9876 2.30525 73.3667C1.24289 76.7555 0.610856 79.0147 1.56564 81.368C2.17078 82.8204 2.9104 83.2372 5.4251 85.4023C12.1489 91.2117 12.3103 93.296 15.336 93.686C17.5123 93.8225 19.6799 93.3075 21.5622 92.2068C23.1554 91.2866 24.6435 90.1954 25.9999 88.9525C29.1063 86.4109 30.6663 85.1064 31.379 83.7751C31.6748 83.1834 32.2127 81.9059 31.9707 77.401C31.7281 73.6554 31.1338 69.941 30.1956 66.3067C29.8729 64.9619 28.0171 57.2833 27.9767 57.122C26.7127 51.7429 27.0892 41.9531 27.0892 39.3577C27.0892 29.3661 27.3985 27.2548 26.7934 21.5934C26.4772 18.4473 25.9334 15.3282 25.1662 12.2607C24.3378 9.25198 23.3503 6.28932 22.2077 3.3853C21.616 1.79848 21.2395 0.870605 20.7285 0.870605C19.5048 0.870605 18.5769 6.07482 18.3483 7.35234C17.6624 11.2522 18.0121 12.9197 17.3128 17.8953C17.0573 19.4418 16.8287 20.6789 16.6673 21.4589Z" fill="#DEE1E9"/>
        <path d="M31.3849 83.7649C30.726 85.1097 29.1526 86.4544 26.0059 88.9557C24.6417 90.1952 23.1496 91.2861 21.5547 92.21C19.673 93.2999 17.5129 93.8142 15.3419 93.6892C12.3162 93.2858 12.1548 91.2149 5.41759 85.3921C2.97013 83.2808 2.17672 82.8505 1.57158 81.3578C0.603348 79.0179 1.23539 76.7452 2.3112 73.3699C4.01904 67.9909 4.86624 65.3013 6.00929 62.6118C6.22445 62.1008 6.57409 60.9309 7.09855 59.0482L9.47877 57.1521C10.4618 55.7533 11.7856 54.6285 13.3248 53.8843C15.1213 53.0362 17.1254 52.7279 19.0938 52.9968C19.0938 52.9968 22.765 52.2034 28.8433 60.7291C29.3812 62.9749 30.0133 65.5568 30.1881 66.3099C31.1251 69.949 31.7194 73.6678 31.9632 77.4176C32.2321 81.8957 31.6808 83.1732 31.3849 83.7649Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M17.9491 70.0181C16.2143 70.0181 14.3451 63.7918 14.0089 58.0228L15.9454 41.8857C16.7388 36.7621 17.4515 32.6068 17.9491 29.7828C19.0921 23.2876 19.2938 22.5076 19.6435 19.724C20.3965 12.5564 20.0872 3.64134 20.6789 3.56065C21.2706 3.47997 21.9296 11.5882 22.454 14.9904C23.1129 19.3071 22.6692 21.7142 22.3061 27.7119C21.8406 32.3857 21.7417 37.0888 22.0102 41.7781C22.3599 45.5837 22.9247 47.453 23.1936 52.8858C23.3948 55.4089 23.2454 57.9478 22.7499 60.4299C22.2962 62.4341 21.6008 64.3759 20.6789 66.2124C19.4149 68.7405 18.7694 70.0181 17.9491 70.0181Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M15.3056 27.8272C20.0453 25.2332 22.692 25.3514 27.4036 27.8272" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M16.7846 22.7893C16.5426 23.8382 16.1929 25.3847 15.7491 27.227C14.9826 30.4948 13.5841 36.3983 10.7197 47.2101C8.03022 57.511 6.68546 62.6615 6.1341 63.939C4.99106 66.6285 4.13041 69.318 2.42256 74.6971C1.3602 78.0859 0.728165 80.3451 1.68295 82.6984C2.28809 84.1507 3.02771 84.5676 5.54241 86.7327C12.2662 92.542 12.4276 94.6264 15.4533 95.0164C17.6296 95.1529 19.7972 94.6379 21.6795 93.5372C23.2727 92.617 24.7608 91.5258 26.1173 90.2828C29.2237 87.7412 30.7836 86.4368 31.4963 85.1055C31.7921 84.5138 32.3301 83.2363 32.088 78.7313C31.8454 74.9858 31.2511 71.2713 30.3129 67.6371C29.9902 66.2923 28.1344 58.6137 28.0941 58.4524C26.83 53.0733 27.2065 43.2834 27.2065 40.688C27.2065 30.6965 27.5158 28.5852 26.9107 22.9238C26.5945 19.7777 26.0507 16.6586 25.2835 13.5911C24.4551 10.5824 23.4676 7.61971 22.325 4.71569C21.7333 3.12887 21.3568 2.20099 20.8458 2.20099C19.6221 2.20099 18.6942 7.4052 18.4656 8.68273C17.7797 12.5825 18.1294 14.2501 17.4301 19.2257C17.1746 20.7721 16.946 22.0093 16.7846 22.7893Z" fill="#DEE1E9"/>
        <mask id="mask2213" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="33" height="43">
          <path d="M31.5577 85.0635C30.8987 86.4083 29.3254 87.7531 26.1786 90.2543C24.8145 91.4938 23.3224 92.5848 21.7275 93.5087C19.8457 94.5986 17.6857 95.1128 15.5147 94.9879C12.4889 94.5845 12.3276 92.5135 5.59032 86.6907C3.14286 84.5794 2.34945 84.1491 1.74431 82.6564C0.776078 80.3165 1.40812 78.0439 2.48392 74.6685C4.19177 69.2895 5.03897 66.6 6.18202 63.9105C6.39718 63.3994 6.74682 62.2295 7.27127 60.3468L9.6515 58.4507C10.6345 57.0519 11.9583 55.9272 13.4975 55.183C15.2941 54.3348 17.2982 54.0265 19.2665 54.2954C19.2665 54.2954 22.9377 53.502 29.0161 62.0278C29.554 64.2735 30.186 66.8555 30.3608 67.6085C31.2979 71.2476 31.8922 74.9664 32.1359 78.7163C32.4049 83.1943 31.8535 84.4718 31.5577 85.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2213)">
          <path d="M31.5577 85.0635C30.8987 86.4083 29.3254 87.7531 26.1786 90.2543C24.8145 91.4938 23.3224 92.5848 21.7275 93.5087C19.8457 94.5986 17.6857 95.1128 15.5147 94.9879C12.4889 94.5845 12.3276 92.5135 5.59032 86.6907C3.14286 84.5794 2.34945 84.1491 1.74431 82.6564C0.776078 80.3165 1.40812 78.0439 2.48392 74.6685C4.19177 69.2895 5.03897 66.6 6.18202 63.9105C6.39718 63.3994 6.74682 62.2295 7.27127 60.3468L9.6515 58.4507C10.6345 57.0519 11.9583 55.9272 13.4975 55.183C15.2941 54.3348 17.2982 54.0265 19.2665 54.2954C19.2665 54.2954 22.9377 53.502 29.0161 62.0278C29.554 64.2735 30.186 66.8555 30.3608 67.6085C31.2979 71.2476 31.8922 74.9664 32.1359 78.7163C32.4049 83.1943 31.8535 84.4718 31.5577 85.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7809 78.201C15.8005 78.201 14.2661 86.6027 14.2661 96.201H16.1892H19.2661C19.2661 86.6027 17.7613 78.201 16.7809 78.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.9409 80.201C14.8827 80.201 12.665 73.1745 12.2661 66.6639L14.5636 48.4527C15.5049 42.6706 16.3506 37.9812 16.9409 34.7942C18.297 27.4642 18.5364 26.5839 18.9512 23.4425C19.8447 15.3537 19.4777 5.29272 20.1797 5.20166C20.8817 5.11061 21.6635 14.261 22.2858 18.1005C23.0675 22.972 22.541 25.6886 22.1102 32.4571C21.558 37.7316 21.4406 43.0393 21.7592 48.3312C22.1741 52.6261 22.8442 54.7355 23.1633 60.8667C23.4019 63.7141 23.2247 66.5793 22.6368 69.3804C22.0985 71.6423 21.2735 73.8336 20.1797 75.9062C18.68 78.7592 17.9141 80.201 16.9409 80.201Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="21" cy="4.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M16.7846 26.7893C16.5426 27.8382 16.1929 29.3847 15.7491 31.227C14.9826 34.4948 13.5841 40.3983 10.7197 51.2101C8.03022 61.511 6.68546 66.6615 6.1341 67.939C4.99106 70.6285 4.13041 73.318 2.42256 78.6971C1.3602 82.0859 0.728165 84.3451 1.68295 86.6984C2.28809 88.1507 3.02771 88.5676 5.54241 90.7327C12.2662 96.542 12.4276 98.6264 15.4533 99.0164C17.6296 99.1529 19.7972 98.6379 21.6795 97.5372C23.2727 96.617 24.7608 95.5258 26.1173 94.2828C29.2237 91.7412 30.7836 90.4368 31.4963 89.1055C31.7921 88.5138 32.3301 87.2363 32.088 82.7313C31.8454 78.9858 31.2511 75.2713 30.3129 71.6371C29.9902 70.2923 28.1344 62.6137 28.0941 62.4524C26.83 57.0733 27.2065 47.2834 27.2065 44.688C27.2065 34.6965 27.5158 32.5852 26.9107 26.9238C26.5945 23.7777 26.0507 20.6586 25.2835 17.5911C24.4551 14.5824 23.4676 11.6197 22.325 8.71569C21.7333 7.12887 21.3568 6.20099 20.8458 6.20099C19.6221 6.20099 18.6942 11.4052 18.4656 12.6827C17.7797 16.5825 18.1294 18.2501 17.4301 23.2257C17.1746 24.7721 16.946 26.0093 16.7846 26.7893Z" fill="#DEE1E9"/>
        <mask id="mask2313" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="57" width="33" height="43">
          <path d="M31.5577 89.0635C30.8987 90.4083 29.3254 91.7531 26.1786 94.2543C24.8145 95.4938 23.3224 96.5848 21.7275 97.5087C19.8457 98.5986 17.6857 99.1128 15.5147 98.9879C12.4889 98.5845 12.3276 96.5135 5.59032 90.6907C3.14286 88.5794 2.34945 88.1491 1.74431 86.6564C0.776078 84.3165 1.40812 82.0439 2.48392 78.6685C4.19177 73.2895 5.03897 70.6 6.18202 67.9105C6.39718 67.3994 6.74682 66.2295 7.27127 64.3468L9.6515 62.4507C10.6345 61.0519 11.9583 59.9272 13.4975 59.183C15.2941 58.3348 17.2982 58.0265 19.2665 58.2954C19.2665 58.2954 22.9377 57.502 29.0161 66.0278C29.554 68.2735 30.186 70.8555 30.3608 71.6085C31.2979 75.2476 31.8922 78.9664 32.1359 82.7163C32.4049 87.1943 31.8535 88.4718 31.5577 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2313)">
          <path d="M31.5577 89.0635C30.8987 90.4083 29.3254 91.7531 26.1786 94.2543C24.8145 95.4938 23.3224 96.5848 21.7275 97.5087C19.8457 98.5986 17.6857 99.1128 15.5147 98.9879C12.4889 98.5845 12.3276 96.5135 5.59032 90.6907C3.14286 88.5794 2.34945 88.1491 1.74431 86.6564C0.776078 84.3165 1.40812 82.0439 2.48392 78.6685C4.19177 73.2895 5.03897 70.6 6.18202 67.9105C6.39718 67.3994 6.74682 66.2295 7.27127 64.3468L9.6515 62.4507C10.6345 61.0519 11.9583 59.9272 13.4975 59.183C15.2941 58.3348 17.2982 58.0265 19.2665 58.2954C19.2665 58.2954 22.9377 57.502 29.0161 66.0278C29.554 68.2735 30.186 70.8555 30.3608 71.6085C31.2979 75.2476 31.8922 78.9664 32.1359 82.7163C32.4049 87.1943 31.8535 88.4718 31.5577 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7809 82.201C15.8005 82.201 14.2661 90.6027 14.2661 100.201H16.1892H19.2661C19.2661 90.6027 17.7613 82.201 16.7809 82.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.9409 84.201C14.8827 84.201 12.665 77.1745 12.2661 70.6639L14.5636 52.4527C15.5049 46.6706 16.3506 41.9812 16.9409 38.7942C18.297 31.4642 18.5364 30.5839 18.9512 27.4425C19.8447 19.3537 19.4777 9.29272 20.1797 9.20166C20.8817 9.11061 21.6635 18.261 22.2858 22.1005C23.0675 26.972 22.541 29.6886 22.1102 36.4571C21.558 41.7316 21.4406 47.0393 21.7592 52.3312C22.1741 56.6261 22.8442 58.7355 23.1633 64.8667C23.4019 67.7141 23.2247 70.5793 22.6368 73.3804C22.0985 75.6423 21.2735 77.8336 20.1797 79.9062C18.68 82.7592 17.9141 84.201 16.9409 84.201Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="21" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2313)">
          <path d="M20 0.200989L20.8229 3.94009L23.8567 1.60472L22.0837 4.99799L25.9088 5.1591L22.3694 6.61879L25.1962 9.20099L21.5465 8.04409L22.0521 11.8391L20 8.60699L17.9479 11.8391L18.4535 8.04409L14.8038 9.20099L17.6306 6.61879L14.0912 5.1591L17.9163 4.99799L16.1433 1.60472L19.1771 3.94009L20 0.200989Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2313" x="13.8911" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M16.7846 26.7893C16.5426 27.8382 16.1929 29.3847 15.7491 31.227C14.9826 34.4948 13.5841 40.3983 10.7197 51.2101C8.03022 61.511 6.68546 66.6615 6.1341 67.939C4.99106 70.6285 4.13041 73.318 2.42256 78.6971C1.3602 82.0859 0.728165 84.3451 1.68295 86.6984C2.28809 88.1507 3.02771 88.5676 5.54241 90.7327C12.2662 96.542 12.4276 98.6264 15.4533 99.0164C17.6296 99.1529 19.7972 98.6379 21.6795 97.5372C23.2727 96.617 24.7608 95.5258 26.1173 94.2828C29.2237 91.7412 30.7836 90.4368 31.4963 89.1055C31.7921 88.5138 32.3301 87.2363 32.088 82.7313C31.8454 78.9858 31.2511 75.2713 30.3129 71.6371C29.9902 70.2923 28.1344 62.6137 28.0941 62.4524C26.83 57.0733 27.2065 47.2834 27.2065 44.688C27.2065 34.6965 27.5158 32.5852 26.9107 26.9238C26.5945 23.7777 26.0507 20.6586 25.2835 17.5911C24.4551 14.5824 23.4676 11.6197 22.325 8.71569C21.7333 7.12887 21.3568 6.20099 20.8458 6.20099C19.6221 6.20099 18.6942 11.4052 18.4656 12.6827C17.7797 16.5825 18.1294 18.2501 17.4301 23.2257C17.1746 24.7721 16.946 26.0093 16.7846 26.7893Z" fill="#DEE1E9"/>
      <mask id="mask2313" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="57" width="33" height="43">
        <path d="M31.5577 89.0635C30.8987 90.4083 29.3254 91.7531 26.1786 94.2543C24.8145 95.4938 23.3224 96.5848 21.7275 97.5087C19.8457 98.5986 17.6857 99.1128 15.5147 98.9879C12.4889 98.5845 12.3276 96.5135 5.59032 90.6907C3.14286 88.5794 2.34945 88.1491 1.74431 86.6564C0.776078 84.3165 1.40812 82.0439 2.48392 78.6685C4.19177 73.2895 5.03897 70.6 6.18202 67.9105C6.39718 67.3994 6.74682 66.2295 7.27127 64.3468L9.6515 62.4507C10.6345 61.0519 11.9583 59.9272 13.4975 59.183C15.2941 58.3348 17.2982 58.0265 19.2665 58.2954C19.2665 58.2954 22.9377 57.502 29.0161 66.0278C29.554 68.2735 30.186 70.8555 30.3608 71.6085C31.2979 75.2476 31.8922 78.9664 32.1359 82.7163C32.4049 87.1943 31.8535 88.4718 31.5577 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2313)">
        <path d="M31.5577 89.0635C30.8987 90.4083 29.3254 91.7531 26.1786 94.2543C24.8145 95.4938 23.3224 96.5848 21.7275 97.5087C19.8457 98.5986 17.6857 99.1128 15.5147 98.9879C12.4889 98.5845 12.3276 96.5135 5.59032 90.6907C3.14286 88.5794 2.34945 88.1491 1.74431 86.6564C0.776078 84.3165 1.40812 82.0439 2.48392 78.6685C4.19177 73.2895 5.03897 70.6 6.18202 67.9105C6.39718 67.3994 6.74682 66.2295 7.27127 64.3468L9.6515 62.4507C10.6345 61.0519 11.9583 59.9272 13.4975 59.183C15.2941 58.3348 17.2982 58.0265 19.2665 58.2954C19.2665 58.2954 22.9377 57.502 29.0161 66.0278C29.554 68.2735 30.186 70.8555 30.3608 71.6085C31.2979 75.2476 31.8922 78.9664 32.1359 82.7163C32.4049 87.1943 31.8535 88.4718 31.5577 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M16.7809 82.201C15.8005 82.201 14.2661 90.6027 14.2661 100.201H16.1892H19.2661C19.2661 90.6027 17.7613 82.201 16.7809 82.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M16.9409 84.201C14.8827 84.201 12.665 77.1745 12.2661 70.6639L14.5636 52.4527C15.5049 46.6706 16.3506 41.9812 16.9409 38.7942C18.297 31.4642 18.5364 30.5839 18.9512 27.4425C19.8447 19.3537 19.4777 9.29272 20.1797 9.20166C20.8817 9.11061 21.6635 18.261 22.2858 22.1005C23.0675 26.972 22.541 29.6886 22.1102 36.4571C21.558 41.7316 21.4406 47.0393 21.7592 52.3312C22.1741 56.6261 22.8442 58.7355 23.1633 64.8667C23.4019 67.7141 23.2247 70.5793 22.6368 73.3804C22.0985 75.6423 21.2735 77.8336 20.1797 79.9062C18.68 82.7592 17.9141 84.201 16.9409 84.201Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="21" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2313)">
        <path d="M20 0.200989L20.8229 3.94009L23.8567 1.60472L22.0837 4.99799L25.9088 5.1591L22.3694 6.61879L25.1962 9.20099L21.5465 8.04409L22.0521 11.8391L20 8.60699L17.9479 11.8391L18.4535 8.04409L14.8038 9.20099L17.6306 6.61879L14.0912 5.1591L17.9163 4.99799L16.1433 1.60472L19.1771 3.94009L20 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2313" x="13.8911" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M16.7846 23.7892C16.5426 24.8381 16.1929 26.3846 15.7491 28.2269C14.9826 31.4947 13.5841 37.3982 10.7197 48.2101C8.03022 58.511 6.68546 63.6614 6.1341 64.9389C4.99106 67.6284 4.13041 70.318 2.42256 75.697C1.3602 79.0858 0.728165 81.345 1.68295 83.6983C2.28809 85.1507 3.02771 85.5676 5.54241 87.7326C12.2662 93.542 12.4276 95.6263 15.4533 96.0163C17.6296 96.1528 19.7972 95.6379 21.6795 94.5371C23.2727 93.6169 24.7608 92.5257 26.1173 91.2828C29.2237 88.7412 30.7836 87.4368 31.4963 86.1054C31.7921 85.5137 32.3301 84.2362 32.088 79.7313C31.8454 75.9857 31.2511 72.2713 30.3129 68.637C29.9902 67.2923 28.1344 59.6137 28.0941 59.4523C26.83 54.0732 27.2065 44.2834 27.2065 41.688C27.2065 31.6964 27.5158 29.5851 26.9107 23.9237C26.5945 20.7776 26.0507 17.6585 25.2835 14.5911C24.4551 11.5823 23.4676 8.61964 22.325 5.71562C21.7333 4.12881 21.3568 3.20093 20.8458 3.20093C19.6221 3.20093 18.6942 8.40514 18.4656 9.68267C17.7797 13.5825 18.1294 15.25 17.4301 20.2256C17.1746 21.7721 16.946 23.0093 16.7846 23.7892Z" fill="#DEE1E9"/>
        <mask id="mask2513" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="33" height="43">
          <path d="M31.5577 86.0635C30.8987 87.4082 29.3254 88.753 26.1786 91.2543C24.8145 92.4938 23.3224 93.5847 21.7275 94.5086C19.8457 95.5985 17.6857 96.1128 15.5147 95.9878C12.4889 95.5844 12.3276 93.5135 5.59032 87.6907C3.14286 85.5794 2.34945 85.1491 1.74431 83.6564C0.776078 81.3165 1.40812 79.0438 2.48392 75.6685C4.19177 70.2894 5.03897 67.5999 6.18202 64.9104C6.39718 64.3994 6.74682 63.2294 7.27127 61.3468L9.6515 59.4507C10.6345 58.0519 11.9583 56.9271 13.4975 56.1829C15.2941 55.3348 17.2982 55.0265 19.2665 55.2954C19.2665 55.2954 22.9377 54.5019 29.0161 63.0277C29.554 65.2735 30.186 67.8554 30.3608 68.6085C31.2979 72.2476 31.8922 75.9664 32.1359 79.7162C32.4049 84.1943 31.8535 85.4718 31.5577 86.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2513)">
          <path d="M31.5577 86.0635C30.8987 87.4082 29.3254 88.753 26.1786 91.2543C24.8145 92.4938 23.3224 93.5847 21.7275 94.5086C19.8457 95.5985 17.6857 96.1128 15.5147 95.9878C12.4889 95.5844 12.3276 93.5135 5.59032 87.6907C3.14286 85.5794 2.34945 85.1491 1.74431 83.6564C0.776078 81.3165 1.40812 79.0438 2.48392 75.6685C4.19177 70.2894 5.03897 67.5999 6.18202 64.9104C6.39718 64.3994 6.74682 63.2294 7.27127 61.3468L9.6515 59.4507C10.6345 58.0519 11.9583 56.9271 13.4975 56.1829C15.2941 55.3348 17.2982 55.0265 19.2665 55.2954C19.2665 55.2954 22.9377 54.5019 29.0161 63.0277C29.554 65.2735 30.186 67.8554 30.3608 68.6085C31.2979 72.2476 31.8922 75.9664 32.1359 79.7162C32.4049 84.1943 31.8535 85.4718 31.5577 86.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.5148 86.2009C15.5344 86.2009 14 91.3353 14 97.2009H15.9231H19C19 91.3353 17.4952 86.2009 16.5148 86.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.6748 87.2009C14.6166 87.2009 12.3989 79.6123 12 72.5809L14.2975 52.9127C15.2388 46.6681 16.0844 41.6035 16.6748 38.1616C18.0309 30.2452 18.2703 29.2945 18.6851 25.9018C19.5785 17.1658 19.2116 6.3 19.9136 6.20165C20.6156 6.10331 21.3974 15.9857 22.0196 20.1324C22.8014 25.3937 22.2749 28.3275 21.8441 35.6375C21.2919 41.334 21.1745 47.0663 21.4931 52.7816C21.908 57.42 22.5781 59.6982 22.8972 66.3199C23.1358 69.3951 22.9586 72.4895 22.3706 75.5147C21.8324 77.9575 21.0074 80.3241 19.9136 82.5625C18.4138 85.6438 17.648 87.2009 16.6748 87.2009Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="21" cy="4.20093" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2613" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="95">
          <path d="M17.3727 20.7892C17.1307 21.8381 16.7811 23.3846 16.3373 25.2269C15.5708 28.4947 14.1722 34.3982 11.3079 45.2101C8.61835 55.511 7.27359 60.6614 6.72224 61.9389C5.57919 64.6284 4.71855 67.318 3.0107 72.697C1.94834 76.0858 1.3163 78.345 2.27108 80.6983C2.87622 82.1507 3.61584 82.5676 6.13054 84.7326C12.8544 90.542 13.0157 92.6263 16.0414 93.0163C18.2177 93.1528 20.3853 92.6379 22.2677 91.5371C23.8609 90.6169 25.3489 89.5257 26.7054 88.2828C29.8118 85.7412 31.3717 84.4368 32.0844 83.1054C32.3803 82.5137 32.9182 81.2362 32.6761 76.7313C32.4335 72.9857 31.8392 69.2713 30.901 65.637C30.5783 64.2923 28.7225 56.6137 28.6822 56.4523C27.4181 51.0732 27.7946 41.2834 27.7946 38.688C27.7946 28.6964 28.1039 26.5851 27.4988 20.9237C27.1826 17.7776 26.6388 14.6585 25.8716 11.5911C25.0433 8.5823 24.0557 5.61964 22.9132 2.71562C22.3215 1.12881 21.9449 0.200928 21.4339 0.200928C20.2102 0.200928 19.2823 5.40514 19.0537 6.68267C18.3679 10.5825 18.7175 12.25 18.0182 17.2256C17.7627 18.7721 17.5341 20.0093 17.3727 20.7892Z" fill="#DEE1E9"/>
          <path d="M31.5577 84.0576C30.8987 85.4024 29.3254 86.7471 26.1786 89.2484C24.8145 90.4879 23.3224 91.5788 21.7275 92.5027C19.8457 93.5926 17.6857 94.1069 15.5147 93.982C12.4889 93.5785 12.3276 91.5076 5.59032 85.6848C3.14286 83.5735 2.34945 83.1432 1.74431 81.6505C0.776078 79.3106 1.40812 77.038 2.48392 73.6626C4.19177 68.2836 5.03897 65.5941 6.18202 62.9045C6.39718 62.3935 6.74682 61.2236 7.27127 59.3409L9.6515 57.4448C10.6345 56.046 11.9583 54.9213 13.4975 54.177C15.2941 53.3289 17.2982 53.0206 19.2665 53.2895C19.2665 53.2895 22.9377 52.4961 29.0161 61.0219C29.554 63.2676 30.186 65.8496 30.3608 66.6026C31.2979 70.2417 31.8922 73.9605 32.1359 77.7104C32.4049 82.1884 31.8535 83.4659 31.5577 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2613)">
          <path d="M17.3727 20.7892C17.1307 21.8381 16.7811 23.3846 16.3373 25.2269C15.5708 28.4947 14.1722 34.3982 11.3079 45.2101C8.61835 55.511 7.27359 60.6614 6.72224 61.9389C5.57919 64.6284 4.71855 67.318 3.0107 72.697C1.94834 76.0858 1.3163 78.345 2.27108 80.6983C2.87622 82.1507 3.61584 82.5676 6.13054 84.7326C12.8544 90.542 13.0157 92.6263 16.0414 93.0163C18.2177 93.1528 20.3853 92.6379 22.2677 91.5371C23.8609 90.6169 25.3489 89.5257 26.7054 88.2828C29.8118 85.7412 31.3717 84.4368 32.0844 83.1054C32.3803 82.5137 32.9182 81.2362 32.6761 76.7313C32.4335 72.9857 31.8392 69.2713 30.901 65.637C30.5783 64.2923 28.7225 56.6137 28.6822 56.4523C27.4181 51.0732 27.7946 41.2834 27.7946 38.688C27.7946 28.6964 28.1039 26.5851 27.4988 20.9237C27.1826 17.7776 26.6388 14.6585 25.8716 11.5911C25.0433 8.5823 24.0557 5.61964 22.9132 2.71562C22.3215 1.12881 21.9449 0.200928 21.4339 0.200928C20.2102 0.200928 19.2823 5.40514 19.0537 6.68267C18.3679 10.5825 18.7175 12.25 18.0182 17.2256C17.7627 18.7721 17.5341 20.0093 17.3727 20.7892Z" fill="#DEE1E9"/>
          <path d="M31.5577 84.0576C30.8987 85.4024 29.3254 86.7471 26.1786 89.2484C24.8145 90.4879 23.3224 91.5788 21.7275 92.5027C19.8457 93.5926 17.6857 94.1069 15.5147 93.982C12.4889 93.5785 12.3276 91.5076 5.59032 85.6848C3.14286 83.5735 2.34945 83.1432 1.74431 81.6505C0.776078 79.3106 1.40812 77.038 2.48392 73.6626C4.19177 68.2836 5.03897 65.5941 6.18202 62.9045C6.39718 62.3935 6.74682 61.2236 7.27127 59.3409L9.6515 57.4448C10.6345 56.046 11.9583 54.9213 13.4975 54.177C15.2941 53.3289 17.2982 53.0206 19.2665 53.2895C19.2665 53.2895 22.9377 52.4961 29.0161 61.0219C29.554 63.2676 30.186 65.8496 30.3608 66.6026C31.2979 70.2417 31.8922 73.9605 32.1359 77.7104C32.4049 82.1884 31.8535 83.4659 31.5577 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M20.0259 60.5247H31.9471C29.785 55.1549 29.428 51.8947 29.7245 46.1564C29.7357 45.94 29.4864 45.8054 29.2961 45.932C28.2921 46.6 25.9339 48.4161 25.3622 51.0577C24.6698 54.2577 22.6665 55.0348 22.4539 55.1089C22.441 55.1134 22.4294 55.1179 22.417 55.1236L21.2341 55.6703C21.0895 55.7371 21.0309 55.8996 21.1032 56.0332L21.6321 57.0109C21.6769 57.0936 21.6728 57.1917 21.6213 57.271L19.7749 60.1151C19.6579 60.2954 19.7984 60.5247 20.0259 60.5247Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.951 47.0459C27.89 47.8938 26.4821 49.3307 26.0797 51.1903C25.3364 54.6248 23.1605 55.5809 22.7275 55.7361L21.9539 56.0936L22.2865 56.7084C22.4431 56.9979 22.4289 57.3413 22.2487 57.6189L20.8012 59.8485H30.9038C29.1271 55.1838 28.7511 51.9881 28.951 47.0459ZM22.7442 55.7284C22.7444 55.7283 22.7441 55.7285 22.7442 55.7284V55.7284ZM28.8687 45.3832C29.5976 44.8983 30.4933 45.4547 30.4554 46.1886C30.1626 51.8548 30.5118 55.0209 32.6328 60.2889L33 61.2009H20.0259C19.2297 61.2009 18.7379 60.3982 19.1476 59.7672L20.8699 57.114L20.4488 56.3356C20.1958 55.8679 20.4009 55.2992 20.9069 55.0654L22.0898 54.5188C22.1305 54.5 22.1664 54.4864 22.1959 54.4761C22.2325 54.4634 24.0133 53.8432 24.6448 50.9251C25.2743 48.0161 27.8377 46.0691 28.8687 45.3832Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask113" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="95">
          <path d="M17.3727 20.7892C17.1307 21.8381 16.7811 23.3846 16.3373 25.2269C15.5708 28.4947 14.1722 34.3982 11.3079 45.2101C8.61835 55.511 7.27359 60.6614 6.72224 61.9389C5.57919 64.6284 4.71855 67.318 3.0107 72.697C1.94834 76.0858 1.3163 78.345 2.27108 80.6983C2.87622 82.1507 3.61584 82.5676 6.13054 84.7326C12.8544 90.542 13.0157 92.6263 16.0414 93.0163C18.2177 93.1528 20.3853 92.6379 22.2677 91.5371C23.8609 90.6169 25.3489 89.5257 26.7054 88.2828C29.8118 85.7412 31.3717 84.4368 32.0844 83.1054C32.3803 82.5137 32.9182 81.2362 32.6761 76.7313C32.4335 72.9857 31.8392 69.2713 30.901 65.637C30.5783 64.2923 28.7225 56.6137 28.6822 56.4523C27.4181 51.0732 27.7946 41.2834 27.7946 38.688C27.7946 28.6964 28.1039 26.5851 27.4988 20.9237C27.1826 17.7776 26.6388 14.6585 25.8716 11.5911C25.0433 8.5823 24.0557 5.61964 22.9132 2.71562C22.3215 1.12881 21.9449 0.200928 21.4339 0.200928C20.2102 0.200928 19.2823 5.40514 19.0537 6.68267C18.3679 10.5825 18.7175 12.25 18.0182 17.2256C17.7627 18.7721 17.5341 20.0093 17.3727 20.7892Z" fill="#DEE1E9"/>
          <path d="M31.5577 84.0576C30.8987 85.4024 29.3254 86.7471 26.1786 89.2484C24.8145 90.4879 23.3224 91.5788 21.7275 92.5027C19.8457 93.5926 17.6857 94.1069 15.5147 93.982C12.4889 93.5785 12.3276 91.5076 5.59032 85.6848C3.14286 83.5735 2.34945 83.1432 1.74431 81.6505C0.776078 79.3106 1.40812 77.038 2.48392 73.6626C4.19177 68.2836 5.03897 65.5941 6.18202 62.9045C6.39718 62.3935 6.74682 61.2236 7.27127 59.3409L9.6515 57.4448C10.6345 56.046 11.9583 54.9213 13.4975 54.177C15.2941 53.3289 17.2982 53.0206 19.2665 53.2895C19.2665 53.2895 22.9377 52.4961 29.0161 61.0219C29.554 63.2676 30.186 65.8496 30.3608 66.6026C31.2979 70.2417 31.8922 73.9605 32.1359 77.7104C32.4049 82.1884 31.8535 83.4659 31.5577 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask113)">
          <path d="M17.3727 20.7892C17.1307 21.8381 16.7811 23.3846 16.3373 25.2269C15.5708 28.4947 14.1722 34.3982 11.3079 45.2101C8.61835 55.511 7.27359 60.6614 6.72224 61.9389C5.57919 64.6284 4.71855 67.318 3.0107 72.697C1.94834 76.0858 1.3163 78.345 2.27108 80.6983C2.87622 82.1507 3.61584 82.5676 6.13054 84.7326C12.8544 90.542 13.0157 92.6263 16.0414 93.0163C18.2177 93.1528 20.3853 92.6379 22.2677 91.5371C23.8609 90.6169 25.3489 89.5257 26.7054 88.2828C29.8118 85.7412 31.3717 84.4368 32.0844 83.1054C32.3803 82.5137 32.9182 81.2362 32.6761 76.7313C32.4335 72.9857 31.8392 69.2713 30.901 65.637C30.5783 64.2923 28.7225 56.6137 28.6822 56.4523C27.4181 51.0732 27.7946 41.2834 27.7946 38.688C27.7946 28.6964 28.1039 26.5851 27.4988 20.9237C27.1826 17.7776 26.6388 14.6585 25.8716 11.5911C25.0433 8.5823 24.0557 5.61964 22.9132 2.71562C22.3215 1.12881 21.9449 0.200928 21.4339 0.200928C20.2102 0.200928 19.2823 5.40514 19.0537 6.68267C18.3679 10.5825 18.7175 12.25 18.0182 17.2256C17.7627 18.7721 17.5341 20.0093 17.3727 20.7892Z" fill="#DEE1E9"/>
          <path d="M31.5577 84.0576C30.8987 85.4024 29.3254 86.7471 26.1786 89.2484C24.8145 90.4879 23.3224 91.5788 21.7275 92.5027C19.8457 93.5926 17.6857 94.1069 15.5147 93.982C12.4889 93.5785 12.3276 91.5076 5.59032 85.6848C3.14286 83.5735 2.34945 83.1432 1.74431 81.6505C0.776078 79.3106 1.40812 77.038 2.48392 73.6626C4.19177 68.2836 5.03897 65.5941 6.18202 62.9045C6.39718 62.3935 6.74682 61.2236 7.27127 59.3409L9.6515 57.4448C10.6345 56.046 11.9583 54.9213 13.4975 54.177C15.2941 53.3289 17.2982 53.0206 19.2665 53.2895C19.2665 53.2895 22.9377 52.4961 29.0161 61.0219C29.554 63.2676 30.186 65.8496 30.3608 66.6026C31.2979 70.2417 31.8922 73.9605 32.1359 77.7104C32.4049 82.1884 31.8535 83.4659 31.5577 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M22.7011 60.5315H30.8479C29.3703 56.561 29.1264 54.1504 29.329 49.9074C29.3367 49.7474 29.1662 49.6479 29.0362 49.7415C28.3501 50.2354 26.7385 51.5782 26.3479 53.5315C25.8746 55.8976 24.5057 56.4722 24.3604 56.527C24.3515 56.5303 24.3436 56.5337 24.3352 56.5379L23.5267 56.9421C23.428 56.9915 23.3879 57.1116 23.4373 57.2104L23.7988 57.9333C23.8293 57.9945 23.8266 58.067 23.7914 58.1257L22.5296 60.2286C22.4496 60.3619 22.5456 60.5315 22.7011 60.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.8004 50.5651C28.0753 51.1921 27.1131 52.2545 26.8381 53.6295C26.3302 56.169 24.8432 56.876 24.5473 56.9908L24.0187 57.2551L24.246 57.7097C24.353 57.9238 24.3432 58.1777 24.2201 58.3829L23.2309 60.0315H30.1349C28.9207 56.5824 28.6637 54.2194 28.8004 50.5651ZM24.5587 56.9851C24.5588 56.985 24.5586 56.9851 24.5587 56.9851V56.9851ZM28.7441 49.3357C29.2422 48.9771 29.8543 49.3885 29.8284 49.9312C29.6283 54.1208 29.867 56.4619 31.3164 60.3571L31.5674 61.0315H22.7011C22.157 61.0315 21.8209 60.4379 22.1008 59.9713L23.2779 58.0096L22.9901 57.434C22.8172 57.0882 22.9573 56.6677 23.3031 56.4948L24.1115 56.0906C24.1393 56.0767 24.1638 56.0667 24.184 56.0591C24.209 56.0497 25.426 55.5911 25.8576 53.4334C26.2877 51.2825 28.0395 49.8428 28.7441 49.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M16.7846 20.7892C16.5426 21.8381 16.1929 23.3846 15.7491 25.2269C14.9826 28.4947 13.5841 34.3982 10.7197 45.2101C8.03022 55.511 6.68546 60.6614 6.1341 61.9389C4.99106 64.6284 4.13041 67.318 2.42256 72.697C1.3602 76.0858 0.728165 78.345 1.68295 80.6983C2.28809 82.1507 3.02771 82.5676 5.54241 84.7326C12.2662 90.542 12.4276 92.6263 15.4533 93.0163C17.6296 93.1528 19.7972 92.6379 21.6795 91.5371C23.2727 90.6169 24.7608 89.5257 26.1173 88.2828C29.2237 85.7412 30.7836 84.4368 31.4963 83.1054C31.7921 82.5137 32.3301 81.2362 32.088 76.7313C31.8454 72.9857 31.2511 69.2713 30.3129 65.637C29.9902 64.2923 28.1344 56.6137 28.0941 56.4523C26.83 51.0732 27.2065 41.2834 27.2065 38.688C27.2065 28.6964 27.5158 26.5851 26.9107 20.9237C26.5945 17.7776 26.0507 14.6585 25.2835 11.5911C24.4551 8.5823 23.4676 5.61964 22.325 2.71562C21.7333 1.12881 21.3568 0.200928 20.8458 0.200928C19.6221 0.200928 18.6942 5.40514 18.4656 6.68267C17.7797 10.5825 18.1294 12.25 17.4301 17.2256C17.1746 18.7721 16.946 20.0093 16.7846 20.7892Z" fill="#DEE1E9"/>
        <mask id="mask213" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="33" height="43">
          <path d="M31.5577 83.0635C30.8987 84.4082 29.3254 85.753 26.1786 88.2543C24.8145 89.4938 23.3224 90.5847 21.7275 91.5086C19.8457 92.5985 17.6857 93.1128 15.5147 92.9878C12.4889 92.5844 12.3276 90.5135 5.59032 84.6907C3.14286 82.5794 2.34945 82.1491 1.74431 80.6564C0.776078 78.3165 1.40812 76.0438 2.48392 72.6685C4.19177 67.2894 5.03897 64.5999 6.18202 61.9104C6.39718 61.3994 6.74682 60.2294 7.27127 58.3468L9.6515 56.4507C10.6345 55.0519 11.9583 53.9271 13.4975 53.1829C15.2941 52.3348 17.2982 52.0265 19.2665 52.2954C19.2665 52.2954 22.9377 51.5019 29.0161 60.0277C29.554 62.2735 30.186 64.8554 30.3608 65.6085C31.2979 69.2476 31.8922 72.9664 32.1359 76.7162C32.4049 81.1943 31.8535 82.4718 31.5577 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask213)">
          <path d="M31.5577 83.0635C30.8987 84.4082 29.3254 85.753 26.1786 88.2543C24.8145 89.4938 23.3224 90.5847 21.7275 91.5086C19.8457 92.5985 17.6857 93.1128 15.5147 92.9878C12.4889 92.5844 12.3276 90.5135 5.59032 84.6907C3.14286 82.5794 2.34945 82.1491 1.74431 80.6564C0.776078 78.3165 1.40812 76.0438 2.48392 72.6685C4.19177 67.2894 5.03897 64.5999 6.18202 61.9104C6.39718 61.3994 6.74682 60.2294 7.27127 58.3468L9.6515 56.4507C10.6345 55.0519 11.9583 53.9271 13.4975 53.1829C15.2941 52.3348 17.2982 52.0265 19.2665 52.2954C19.2665 52.2954 22.9377 51.5019 29.0161 60.0277C29.554 62.2735 30.186 64.8554 30.3608 65.6085C31.2979 69.2476 31.8922 72.9664 32.1359 76.7162C32.4049 81.1943 31.8535 82.4718 31.5577 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7868 76.2009C15.4143 76.2009 13.2661 84.6026 13.2661 94.2009H15.9584H20.2661C20.2661 84.6026 18.1594 76.2009 16.7868 76.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.9409 78.2009C14.8827 78.2009 12.665 71.1744 12.2661 64.6639L14.5636 46.4526C15.5049 40.6705 16.3506 35.9811 16.9409 32.7941C18.297 25.4641 18.5364 24.5839 18.9512 21.4424C19.8447 13.3536 19.4777 3.29266 20.1797 3.2016C20.8817 3.11054 21.6635 12.2609 22.2858 16.1005C23.0675 20.972 22.541 23.6885 22.1102 30.457C21.558 35.7316 21.4406 41.0392 21.7592 46.3312C22.1741 50.626 22.8442 52.7355 23.1633 58.8666C23.4019 61.714 23.2247 64.5792 22.6368 67.3804C22.0985 69.6422 21.2735 71.8335 20.1797 73.9061C18.68 76.7592 17.9141 78.2009 16.9409 78.2009Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M16.7846 20.7892C16.5426 21.8381 16.1929 23.3846 15.7491 25.2269C14.9826 28.4947 13.5841 34.3982 10.7197 45.2101C8.03022 55.511 6.68546 60.6614 6.1341 61.9389C4.99106 64.6284 4.13041 67.318 2.42256 72.697C1.3602 76.0858 0.728165 78.345 1.68295 80.6983C2.28809 82.1507 3.02771 82.5676 5.54241 84.7326C12.2662 90.542 12.4276 92.6263 15.4533 93.0163C17.6296 93.1528 19.7972 92.6379 21.6795 91.5371C23.2727 90.6169 24.7608 89.5257 26.1173 88.2828C29.2237 85.7412 30.7836 84.4368 31.4963 83.1054C31.7921 82.5137 32.3301 81.2362 32.088 76.7313C31.8454 72.9857 31.2511 69.2713 30.3129 65.637C29.9902 64.2923 28.1344 56.6137 28.0941 56.4523C26.83 51.0732 27.2065 41.2834 27.2065 38.688C27.2065 28.6964 27.5158 26.5851 26.9107 20.9237C26.5945 17.7776 26.0507 14.6585 25.2835 11.5911C24.4551 8.5823 23.4676 5.61964 22.325 2.71562C21.7333 1.12881 21.3568 0.200928 20.8458 0.200928C19.6221 0.200928 18.6942 5.40514 18.4656 6.68267C17.7797 10.5825 18.1294 12.25 17.4301 17.2256C17.1746 18.7721 16.946 20.0093 16.7846 20.7892Z" fill="#DEE1E9"/>
        <mask id="mask313" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="33" height="43">
          <path d="M31.5577 83.0635C30.8987 84.4082 29.3254 85.753 26.1786 88.2543C24.8145 89.4938 23.3224 90.5847 21.7275 91.5086C19.8457 92.5985 17.6857 93.1128 15.5147 92.9878C12.4889 92.5844 12.3276 90.5135 5.59032 84.6907C3.14286 82.5794 2.34945 82.1491 1.74431 80.6564C0.776078 78.3165 1.40812 76.0438 2.48392 72.6685C4.19177 67.2894 5.03897 64.5999 6.18202 61.9104C6.39718 61.3994 6.74682 60.2294 7.27127 58.3468L9.6515 56.4507C10.6345 55.0519 11.9583 53.9271 13.4975 53.1829C15.2941 52.3348 17.2982 52.0265 19.2665 52.2954C19.2665 52.2954 22.9377 51.5019 29.0161 60.0277C29.554 62.2735 30.186 64.8554 30.3608 65.6085C31.2979 69.2476 31.8922 72.9664 32.1359 76.7162C32.4049 81.1943 31.8535 82.4718 31.5577 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask313)">
          <path d="M31.5577 83.0635C30.8987 84.4082 29.3254 85.753 26.1786 88.2543C24.8145 89.4938 23.3224 90.5847 21.7275 91.5086C19.8457 92.5985 17.6857 93.1128 15.5147 92.9878C12.4889 92.5844 12.3276 90.5135 5.59032 84.6907C3.14286 82.5794 2.34945 82.1491 1.74431 80.6564C0.776078 78.3165 1.40812 76.0438 2.48392 72.6685C4.19177 67.2894 5.03897 64.5999 6.18202 61.9104C6.39718 61.3994 6.74682 60.2294 7.27127 58.3468L9.6515 56.4507C10.6345 55.0519 11.9583 53.9271 13.4975 53.1829C15.2941 52.3348 17.2982 52.0265 19.2665 52.2954C19.2665 52.2954 22.9377 51.5019 29.0161 60.0277C29.554 62.2735 30.186 64.8554 30.3608 65.6085C31.2979 69.2476 31.8922 72.9664 32.1359 76.7162C32.4049 81.1943 31.8535 82.4718 31.5577 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.7809 82.2009C15.8005 82.2009 14.2661 88.2688 14.2661 95.2009H16.1892H19.2661C19.2661 88.2688 17.7613 82.2009 16.7809 82.2009Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M17.3727 20.9223C17.1307 21.9712 16.7811 23.5177 16.3373 25.36C15.5708 28.6278 14.1722 34.5313 11.3079 45.3431C8.61835 55.644 7.27359 60.7944 6.72224 62.072C5.57919 64.7615 4.71855 67.451 3.0107 72.8301C1.94834 76.2189 1.3163 78.4781 2.27108 80.8314C2.87622 82.2837 3.61584 82.7006 6.13054 84.8657C12.8544 90.675 13.0157 92.7594 16.0414 93.1494C18.2177 93.2859 20.3853 92.7709 22.2677 91.6702C23.8609 90.75 25.3489 89.6588 26.7054 88.4158C29.8118 85.8742 31.3717 84.5698 32.0844 83.2385C32.3803 82.6468 32.9182 81.3693 32.6761 76.8643C32.4335 73.1188 31.8392 69.4043 30.901 65.7701C30.5783 64.4253 28.7225 56.7467 28.6822 56.5853C27.4181 51.2063 27.7946 41.4164 27.7946 38.821C27.7946 28.8295 28.1039 26.7182 27.4988 21.0568C27.1826 17.9107 26.6388 14.7916 25.8716 11.7241C25.0433 8.71536 24.0557 5.7527 22.9132 2.84868C22.3215 1.26186 21.9449 0.333984 21.4339 0.333984C20.2102 0.333984 19.2823 5.5382 19.0537 6.81572C18.3679 10.7155 18.7175 12.383 18.0182 17.3587C17.7627 18.9051 17.5341 20.1423 17.3727 20.9223Z" fill="#DEE1E9"/>
        <path d="M31.5577 84.1907C30.8987 85.5354 29.3254 86.8802 26.1786 89.3815C24.8145 90.621 23.3224 91.7119 21.7275 92.6358C19.8457 93.7257 17.6857 94.24 15.5147 94.115C12.4889 93.7116 12.3276 91.6407 5.59032 85.8178C3.14286 83.7066 2.34945 83.2763 1.74431 81.7836C0.776078 79.4437 1.40812 77.171 2.48392 73.7957C4.19177 68.4166 5.03897 65.7271 6.18202 63.0376C6.39718 62.5266 6.74682 61.3566 7.27127 59.474L9.6515 57.5779C10.6345 56.1791 11.9583 55.0543 13.4975 54.3101C15.2941 53.462 17.2982 53.1537 19.2665 53.4226C19.2665 53.4226 22.9377 52.6291 29.0161 61.1549C29.554 63.4007 30.186 65.9826 30.3608 66.7357C31.2979 70.3747 31.8922 74.0936 32.1359 77.8434C32.4049 82.3215 31.8535 83.599 31.5577 84.1907Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.2661 76.2009L15.1328 78.7018L16.7661 77.2061V80.5201L19.3283 79.9522L17.9328 81.7028L20.2661 83.7035L17.9328 85.7042L19.3283 87.4548L16.9994 86.7045L16.7661 90.2009L14.5162 88.7039L13.7328 89.7056L12.016 88.7039L9.76611 90.2009L9.06611 88.2051L7.20394 87.4548L7.66611 84.7038L6.26611 83.7035L8.59945 82.203L7.20394 79.9522L9.06611 79.7021L9.76611 77.2061L12.7994 79.202L13.2661 76.2009Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M17.3727 20.9223C17.1307 21.9712 16.7811 23.5177 16.3373 25.36C15.5708 28.6278 14.1722 34.5313 11.3079 45.3431C8.61835 55.644 7.27359 60.7944 6.72224 62.072C5.57919 64.7615 4.71855 67.451 3.0107 72.8301C1.94834 76.2189 1.3163 78.4781 2.27108 80.8314C2.87622 82.2837 3.61584 82.7006 6.13054 84.8657C12.8544 90.675 13.0157 92.7594 16.0414 93.1494C18.2177 93.2859 20.3853 92.7709 22.2677 91.6702C23.8609 90.75 25.3489 89.6588 26.7054 88.4158C29.8118 85.8742 31.3717 84.5698 32.0844 83.2385C32.3803 82.6468 32.9182 81.3693 32.6761 76.8643C32.4335 73.1188 31.8392 69.4043 30.901 65.7701C30.5783 64.4253 28.7225 56.7467 28.6822 56.5853C27.4181 51.2063 27.7946 41.4164 27.7946 38.821C27.7946 28.8295 28.1039 26.7182 27.4988 21.0568C27.1826 17.9107 26.6388 14.7916 25.8716 11.7241C25.0433 8.71536 24.0557 5.7527 22.9132 2.84868C22.3215 1.26186 21.9449 0.333984 21.4339 0.333984C20.2102 0.333984 19.2823 5.5382 19.0537 6.81572C18.3679 10.7155 18.7175 12.383 18.0182 17.3587C17.7627 18.9051 17.5341 20.1423 17.3727 20.9223Z" fill="#DEE1E9"/>
        <path d="M31.5577 84.1907C30.8987 85.5354 29.3254 86.8802 26.1786 89.3815C24.8145 90.621 23.3224 91.7119 21.7275 92.6358C19.8457 93.7257 17.6857 94.24 15.5147 94.115C12.4889 93.7116 12.3276 91.6407 5.59032 85.8178C3.14286 83.7066 2.34945 83.2763 1.74431 81.7836C0.776078 79.4437 1.40812 77.171 2.48392 73.7957C4.19177 68.4166 5.03897 65.7271 6.18202 63.0376C6.39718 62.5266 6.74682 61.3566 7.27127 59.474L9.6515 57.5779C10.6345 56.1791 11.9583 55.0543 13.4975 54.3101C15.2941 53.462 17.2982 53.1537 19.2665 53.4226C19.2665 53.4226 22.9377 52.6291 29.0161 61.1549C29.554 63.4007 30.186 65.9826 30.3608 66.7357C31.2979 70.3747 31.8922 74.0936 32.1359 77.8434C32.4049 82.3215 31.8535 83.599 31.5577 84.1907Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M12.2661 77.2009L13.8661 79.3445L15.2661 78.0625V80.9031L17.4623 80.4163L16.2661 81.9168L18.2661 83.6317L16.2661 85.3466L17.4623 86.8471L15.4661 86.204L15.2661 89.2009L13.3376 87.9178L12.6661 88.7763L11.1946 87.9178L9.26611 89.2009L8.66611 87.4902L7.06996 86.8471L7.46611 84.4891L6.26611 83.6317L8.26611 82.3456L7.06996 80.4163L8.66611 80.202L9.26611 78.0625L11.8661 79.7732L12.2661 77.2009Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M17.3727 20.8546C17.1307 21.9036 16.7811 23.45 16.3373 25.2924C15.5708 28.5601 14.1722 34.4636 11.3079 45.2755C8.61835 55.5764 7.27359 60.7268 6.72224 62.0043C5.57919 64.6939 4.71855 67.3834 3.0107 72.7624C1.94834 76.1512 1.3163 78.4104 2.27108 80.7638C2.87622 82.2161 3.61584 82.633 6.13054 84.798C12.8544 90.6074 13.0157 92.6918 16.0414 93.0818C18.2177 93.2183 20.3853 92.7033 22.2677 91.6025C23.8609 90.6824 25.3489 89.5911 26.7054 88.3482C29.8118 85.8066 31.3717 84.5022 32.0844 83.1709C32.3803 82.5792 32.9182 81.3017 32.6761 76.7967C32.4335 73.0512 31.8392 69.3367 30.901 65.7024C30.5783 64.3577 28.7225 56.6791 28.6822 56.5177C27.4181 51.1387 27.7946 41.3488 27.7946 38.7534C27.7946 28.7618 28.1039 26.6506 27.4988 20.9891C27.1826 17.843 26.6388 14.724 25.8716 11.6565C25.0433 8.64773 24.0557 5.68507 22.9132 2.78105C22.3215 1.19424 21.9449 0.266357 21.4339 0.266357C20.2102 0.266357 19.2823 5.47057 19.0537 6.7481C18.3679 10.6479 18.7175 12.3154 18.0182 17.291C17.7627 18.8375 17.5341 20.0747 17.3727 20.8546Z" fill="#DEE1E9"/>
        <path d="M31.5577 84.1231C30.8987 85.4678 29.3254 86.8126 26.1786 89.3138C24.8145 90.5533 23.3224 91.6443 21.7275 92.5682C19.8457 93.6581 17.6857 94.1723 15.5147 94.0474C12.4889 93.644 12.3276 91.573 5.59032 85.7502C3.14286 83.6389 2.34945 83.2086 1.74431 81.7159C0.776078 79.3761 1.40812 77.1034 2.48392 73.7281C4.19177 68.349 5.03897 65.6595 6.18202 62.97C6.39718 62.459 6.74682 61.289 7.27127 59.4064L9.6515 57.5102C10.6345 56.1114 11.9583 54.9867 13.4975 54.2425C15.2941 53.3943 17.2982 53.086 19.2665 53.3549C19.2665 53.3549 22.9377 52.5615 29.0161 61.0873C29.554 63.3331 30.186 65.915 30.3608 66.6681C31.2979 70.3071 31.8922 74.0259 32.1359 77.7758C32.4049 82.2539 31.8535 83.5314 31.5577 84.1231Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M12.7661 84.1333L13.6994 85.3837L14.5161 84.6359V86.2929L15.7972 86.0089L15.0994 86.8842L16.2661 87.8846L15.0994 88.8849L15.7972 89.7602L14.6328 89.3851L14.5161 91.1333L13.3912 90.3848L12.9994 90.8856L12.1411 90.3848L11.0161 91.1333L10.6661 90.1354L9.73502 89.7602L9.96611 88.3848L9.26611 87.8846L10.4328 87.1343L9.73502 86.0089L10.6661 85.8839L11.0161 84.6359L12.5328 85.6338L12.7661 84.1333Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M13.5918 2.68065L15.6755 1.41089H19.1592L21.2592 2.68065L19.6476 4.22716L21.7313 5.77367L19.8429 7.32018L22.1383 9.0132L20.315 10.5597L22.6104 11.9597L20.5918 13.376L22.6104 14.9876L20.9174 16.5341L23.1476 17.9504L21.3895 19.4155L23.6848 20.962L21.536 22.5085L23.6848 24.1201L22.1383 25.4713L24.222 27.0178L22.3988 28.4992L24.4825 30.0457L22.6755 31.7876L23.6848 33.4643V47.7247L21.7964 55.0503H15.6755" fill="#8889BE"/>
        <path d="M13.5918 2.68065L15.6755 1.41089H19.1592L21.2592 2.68065L19.6476 4.22716L21.7313 5.77367L19.8429 7.32018L22.1383 9.0132L20.315 10.5597L22.6104 11.9597L20.5918 13.376L22.6104 14.9876L20.9174 16.5341L23.1476 17.9504L21.3895 19.4155L23.6848 20.962L21.536 22.5085L23.6848 24.1201L22.1383 25.4713L24.222 27.0178L22.3988 28.4992L24.4825 30.0457L22.6755 31.7876L23.6848 33.4643V47.7247L21.7964 55.0503H15.6755" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M20.9991 2.68065L18.9154 1.41089H15.4479L13.3316 2.68065L14.9596 4.22716L12.8596 5.77367L14.7479 7.32018L12.4689 9.0132L14.2758 10.5597L11.9968 11.9597L14.0154 13.376L11.9968 14.9876L13.6735 16.5341L11.4596 17.9504L13.2014 19.4155L10.9224 20.962L13.0712 22.5085L10.9224 24.1201L12.4689 25.4713L10.3851 27.0178L12.1921 28.4992L10.1084 30.0457L11.9317 31.7876L10.9224 33.4643V47.7247L12.7944 55.0503H16.7014" fill="#8889BE"/>
        <path d="M20.9991 2.68065L18.9154 1.41089H15.4479L13.3316 2.68065L14.9596 4.22716L12.8596 5.77367L14.7479 7.32018L12.4689 9.0132L14.2758 10.5597L11.9968 11.9597L14.0154 13.376L11.9968 14.9876L13.6735 16.5341L11.4596 17.9504L13.2014 19.4155L10.9224 20.962L13.0712 22.5085L10.9224 24.1201L12.4689 25.4713L10.3851 27.0178L12.1921 28.4992L10.1084 30.0457L11.9317 31.7876L10.9224 33.4643V47.7247L12.7944 55.0503H16.7014" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M25.7079 71.4585C28.8546 68.9572 30.428 67.6125 31.0869 66.2677C31.3827 65.676 31.9341 64.3985 31.6651 59.9205C31.4214 56.1706 30.8271 52.4518 29.8901 48.8127C29.7152 48.0597 28.6087 43.7968 28.0708 41.551H24.271C23.7187 41.551 23.271 41.9987 23.271 42.551V52.5219C18.7869 53.6386 16.2669 53.6268 11.7828 52.5219V42.551C11.7828 41.9987 11.3351 41.551 10.7828 41.551H6.80051C6.27605 43.4337 5.92642 44.6036 5.71125 45.1146C4.56821 47.8042 3.72101 50.4937 2.01316 55.8727C0.937352 59.2481 0.305314 61.5207 1.27354 63.8606C1.87868 65.3533 2.67209 65.7836 5.11956 67.8949C11.8568 73.7177 12.0182 75.7886 15.0439 76.1921C17.2149 76.317 19.3749 75.8027 21.2567 74.7128C22.8516 73.789 24.3437 72.698 25.7079 71.4585Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask513" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="41" width="33" height="36">
          <path d="M25.7079 71.4585C28.8546 68.9572 30.428 67.6124 31.0869 66.2677C31.3827 65.676 31.9341 64.3985 31.6651 59.9204C31.4214 56.1706 30.8271 52.4518 29.8901 48.8127C29.7152 48.0596 28.6087 44.7284 28.0708 42.4827H24.271C23.7187 42.4827 23.271 42.9304 23.271 43.4827V52.5218C18.7869 53.6386 16.2669 53.6268 11.7828 52.5218V43.4827C11.7828 42.9304 11.3351 42.4827 10.7828 42.4827L6.80051 42.4827C6.27605 44.3653 5.92642 44.6036 5.71125 45.1146C4.56821 47.8041 3.72101 50.4936 2.01316 55.8727C0.937352 59.248 0.305314 61.5207 1.27354 63.8606C1.87868 65.3533 2.67209 65.7836 5.11956 67.8948C11.8568 73.7177 12.0182 75.7886 15.0439 76.192C17.2149 76.317 19.3749 75.8027 21.2567 74.7128C22.8516 73.7889 24.3437 72.698 25.7079 71.4585Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask513)">
          <path d="M8.97382 31.3479H25.8684V56.8959C19.2706 58.8221 15.5715 58.8417 8.97382 56.8959V31.3479Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M13.9121 2.03344L15.9958 0.763672H19.4795L21.5795 2.03344L19.9679 3.57995L22.0516 5.12645L20.1633 6.67296L22.4586 8.36598L20.6354 9.91249L22.9307 11.3125L20.9121 12.7288L22.9307 14.3404L21.2377 15.8869L23.4679 17.3032L21.7098 18.7683L24.0051 20.3148L21.8563 21.8613L24.0051 23.4729L22.4586 24.8241L24.5423 26.3706L22.7191 27.852L24.8028 29.3985L22.9958 31.1404L24.0051 32.8171V47.0775L22.1167 54.4031H15.9958" fill="#8889BE"/>
        <path d="M13.9121 2.03344L15.9958 0.763672H19.4795L21.5795 2.03344L19.9679 3.57995L22.0516 5.12645L20.1633 6.67296L22.4586 8.36598L20.6354 9.91249L22.9307 11.3125L20.9121 12.7288L22.9307 14.3404L21.2377 15.8869L23.4679 17.3032L21.7098 18.7683L24.0051 20.3148L21.8563 21.8613L24.0051 23.4729L22.4586 24.8241L24.5423 26.3706L22.7191 27.852L24.8028 29.3985L22.9958 31.1404L24.0051 32.8171V47.0775L22.1167 54.4031H15.9958" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M21.3194 2.03344L19.2357 0.763672H15.7682L13.652 2.03344L15.2799 3.57995L13.1799 5.12645L15.0682 6.67296L12.7892 8.36598L14.5961 9.91249L12.3171 11.3125L14.3357 12.7288L12.3171 14.3404L13.9938 15.8869L11.7799 17.3032L13.5217 18.7683L11.2427 20.3148L13.3915 21.8613L11.2427 23.4729L12.7892 24.8241L10.7055 26.3706L12.5124 27.852L10.4287 29.3985L12.252 31.1404L11.2427 32.8171V47.0775L13.1148 54.4031H17.0217" fill="#8889BE"/>
        <path d="M21.3194 2.03344L19.2357 0.763672H15.7682L13.652 2.03344L15.2799 3.57995L13.1799 5.12645L15.0682 6.67296L12.7892 8.36598L14.5961 9.91249L12.3171 11.3125L14.3357 12.7288L12.3171 14.3404L13.9938 15.8869L11.7799 17.3032L13.5217 18.7683L11.2427 20.3148L13.3915 21.8613L11.2427 23.4729L12.7892 24.8241L10.7055 26.3706L12.5124 27.852L10.4287 29.3985L12.252 31.1404L11.2427 32.8171V47.0775L13.1148 54.4031H17.0217" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M25.7079 69.8982C28.8546 67.3969 30.428 66.0522 31.0869 64.7074C31.3827 64.1157 31.9341 62.8382 31.6651 58.3602C31.4214 54.6103 30.8271 50.8915 29.8901 47.2524C29.7152 46.4994 28.6087 42.2365 28.0708 39.9907H24.271C23.7187 39.9907 23.271 40.4384 23.271 40.9907V50.9616C18.7869 52.0783 16.2669 52.0665 11.7828 50.9616V40.9907C11.7828 40.4384 11.3351 39.9907 10.7828 39.9907H6.80051C6.27605 41.8734 5.92642 43.0433 5.71125 43.5543C4.56821 46.2439 3.72101 48.9334 2.01316 54.3124C0.937352 57.6878 0.305314 59.9604 1.27354 62.3003C1.87868 63.793 2.67209 64.2233 5.11956 66.3346C11.8568 72.1574 12.0182 74.2283 15.0439 74.6318C17.2149 74.7567 19.3749 74.2424 21.2567 73.1525C22.8516 72.2286 24.3437 71.1377 25.7079 69.8982Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M0 90.771H21V95.771H0V90.771Z" fill="#838AA2"/>
        <path d="M22 90.771H43V95.771H22V90.771Z" fill="#838AA2"/>
        <path d="M21.5774 21.4926C21.3353 22.5415 20.9857 24.088 20.5419 25.9303C19.7754 29.1981 18.3769 35.1016 15.5125 45.9135C12.823 56.2143 11.4782 61.3648 10.9269 62.6423C9.78384 65.3318 8.9232 68.0213 7.21535 73.4004C6.15299 76.7892 5.52095 79.0484 6.47573 81.4017C7.08087 82.854 7.82049 83.2709 10.3352 85.436C17.059 91.2454 17.2204 93.3297 20.2461 93.7197C22.4224 93.8562 24.59 93.3412 26.4723 92.2405C28.0655 91.3203 29.5536 90.2291 30.91 88.9862C34.0164 86.4445 35.5764 85.1401 36.2891 83.8088C36.5849 83.2171 37.1228 81.9396 36.8808 77.4347C36.6382 73.6891 36.0439 69.9747 35.1057 66.3404C34.783 64.9956 32.9272 57.317 32.8868 57.1557C31.6228 51.7766 31.9993 41.9867 31.9993 39.3914C31.9993 29.3998 32.3086 27.2885 31.7035 21.6271C31.3873 18.481 30.8434 15.3619 30.0763 12.2944C29.2479 9.28567 28.2604 6.32301 27.1178 3.41899C26.5261 1.83218 26.1496 0.904297 25.6386 0.904297C24.4148 0.904297 23.487 6.10851 23.2584 7.38603C22.5725 11.2858 22.9222 12.9534 22.2229 17.929C21.9674 19.4755 21.7388 20.7126 21.5774 21.4926Z" fill="#DEE1E9"/>
        <path d="M30.9125 89.9519C34.0592 87.4506 35.6326 86.1059 36.2915 84.7611C36.5874 84.1694 37.1387 82.8919 36.8698 78.4139C36.626 74.664 36.0318 70.9452 35.0947 67.3061C34.9199 66.5531 33.8133 62.2902 33.2754 60.0444H29.4756C28.9233 60.0444 28.4756 60.4921 28.4756 61.0444V71.0153C23.9915 72.132 21.4716 72.1202 16.9875 71.0153V61.0444C16.9875 60.4921 16.5398 60.0444 15.9875 60.0444H12.0052C11.4807 61.9271 11.1311 63.097 10.9159 63.608C9.77286 66.2976 8.92566 68.9871 7.21781 74.3661C6.142 77.7415 5.50996 80.0141 6.47819 82.354C7.08334 83.8467 7.87674 84.277 10.3242 86.3883C17.0615 92.2111 17.2228 94.2821 20.2485 94.6855C22.4196 94.8104 24.5796 94.2962 26.4613 93.2063C28.0562 92.2824 29.5484 91.1914 30.9125 89.9519Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M16.5185 20.5883C16.2764 21.6372 15.9268 23.1837 15.483 25.026C14.7165 28.2938 13.318 34.1973 10.4536 45.0092C7.7641 55.31 6.41934 60.4605 5.86799 61.738C4.72494 64.4275 3.8643 67.117 2.15645 72.4961C1.09409 75.8849 0.462052 78.1441 1.41683 80.4974C2.02197 81.9497 2.76159 82.3666 5.2763 84.5317C12.0001 90.3411 12.1615 92.4254 15.1872 92.8154C17.3635 92.9519 19.5311 92.4369 21.4134 91.3362C23.0066 90.416 24.4947 89.3248 25.8511 88.0819C28.9575 85.5403 30.5175 84.2358 31.2302 82.9045C31.526 82.3128 32.0639 81.0353 31.8219 76.5304C31.5793 72.7848 30.985 69.0704 30.0468 65.4361C29.7241 64.0913 27.8683 56.4127 27.8279 56.2514C26.5639 50.8723 26.9404 41.0824 26.9404 38.4871C26.9404 28.4955 27.2497 26.3842 26.6446 20.7228C26.3284 17.5767 25.7845 14.4576 25.0174 11.3901C24.189 8.38137 23.2015 5.41872 22.0589 2.5147C21.4672 0.927879 21.0907 0 20.5797 0C19.3559 0 18.4281 5.20422 18.1995 6.48174C17.5136 10.3815 17.8633 12.0491 17.164 17.0247C16.9085 18.5712 16.6799 19.8083 16.5185 20.5883Z" fill="#DEE1E9"/>
        <path d="M31.2361 82.8943C30.5772 84.2391 29.0038 85.5838 25.8571 88.0851C24.4929 89.3246 23.0008 90.4155 21.4059 91.3394C19.5242 92.4293 17.3641 92.9436 15.1931 92.8186C12.1674 92.4152 12.006 90.3443 5.26879 84.5215C2.82133 82.4102 2.02792 81.9799 1.42277 80.4872C0.454545 78.1473 1.08658 75.8746 2.16239 72.4993C3.87024 67.1202 4.71744 64.4307 5.86048 61.7412C6.07565 61.2302 6.42528 60.0603 6.94974 58.1776L9.32997 56.2815C10.313 54.8827 11.6368 53.7579 13.176 53.0137C14.9725 52.1656 16.9766 51.8573 18.945 52.1262C18.945 52.1262 22.6162 51.3328 28.6945 59.8585C29.2324 62.1043 29.8645 64.6862 30.0393 65.4393C30.9763 69.0784 31.5706 72.7972 31.8144 76.547C32.0833 81.0251 31.532 82.3026 31.2361 82.8943Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M17.8003 69.1475C16.0655 69.1475 14.1963 62.9212 13.8601 57.1522L15.7966 41.0151C16.59 35.8915 17.3027 31.7362 17.8003 28.9122C18.9433 22.417 19.145 21.637 19.4947 18.8534C20.2477 11.6858 19.9384 2.77073 20.5301 2.69005C21.1218 2.60936 21.7808 10.7176 22.3052 14.1198C22.9641 18.4365 22.5204 20.8436 22.1573 26.8413C21.6918 31.5151 21.5929 36.2182 21.8614 40.9075C22.2111 44.7131 22.7759 46.5824 23.0448 52.0152C23.246 54.5383 23.0966 57.0772 22.6011 59.5593C22.1474 61.5635 21.452 63.5052 20.5301 65.3418C19.266 67.8699 18.6206 69.1475 17.8003 69.1475Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M16.5185 20.5883C16.2764 21.6372 15.9268 23.1837 15.483 25.026C14.7165 28.2938 13.318 34.1973 10.4536 45.0092C7.7641 55.31 6.41934 60.4605 5.86799 61.738C4.72494 64.4275 3.8643 67.117 2.15645 72.4961C1.09409 75.8849 0.462052 78.1441 1.41683 80.4974C2.02197 81.9497 2.76159 82.3666 5.2763 84.5317C12.0001 90.3411 12.1615 92.4254 15.1872 92.8154C17.3635 92.9519 19.5311 92.4369 21.4134 91.3362C23.0066 90.416 24.4947 89.3248 25.8511 88.0819C28.9575 85.5403 30.5175 84.2358 31.2302 82.9045C31.526 82.3128 32.0639 81.0353 31.8219 76.5304C31.5793 72.7848 30.985 69.0704 30.0468 65.4361C29.7241 64.0913 27.8683 56.4127 27.8279 56.2514C26.5639 50.8723 26.9404 41.0824 26.9404 38.4871C26.9404 28.4955 27.2497 26.3842 26.6446 20.7228C26.3284 17.5767 25.7845 14.4576 25.0174 11.3901C24.189 8.38137 23.2015 5.41872 22.0589 2.5147C21.4672 0.927879 21.0907 0 20.5797 0C19.3559 0 18.4281 5.20422 18.1995 6.48174C17.5136 10.3815 17.8633 12.0491 17.164 17.0247C16.9085 18.5712 16.6799 19.8083 16.5185 20.5883Z" fill="#DEE1E9"/>
        <path d="M31.2361 82.8943C30.5772 84.2391 29.0038 85.5838 25.8571 88.0851C24.4929 89.3246 23.0008 90.4155 21.4059 91.3394C19.5242 92.4293 17.3641 92.9436 15.1931 92.8186C12.1674 92.4152 12.006 90.3443 5.26879 84.5215C2.82133 82.4102 2.02792 81.9799 1.42277 80.4872C0.454545 78.1473 1.08658 75.8746 2.16239 72.4993C3.87024 67.1202 4.71744 64.4307 5.86048 61.7412C6.07565 61.2302 6.42528 60.0603 6.94974 58.1776L9.32997 56.2815C10.313 54.8827 11.6368 53.7579 13.176 53.0137C14.9725 52.1656 16.9766 51.8573 18.945 52.1262C18.945 52.1262 22.6162 51.3328 28.6945 59.8585C29.2324 62.1043 29.8645 64.6862 30.0393 65.4393C30.9763 69.0784 31.5706 72.7972 31.8144 76.547C32.0833 81.0251 31.532 82.3026 31.2361 82.8943Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.4" d="M17.8003 69.1475C16.0655 69.1475 14.1963 62.9212 13.8601 57.1522L15.7966 41.0151C16.59 35.8915 17.3027 31.7362 17.8003 28.9122C18.9433 22.417 19.145 21.637 19.4947 18.8534C20.2477 11.6858 19.9384 2.77073 20.5301 2.69005C21.1218 2.60936 21.7808 10.7176 22.3052 14.1198C22.9641 18.4365 22.5204 20.8436 22.1573 26.8413C21.6918 31.5151 21.5929 36.2182 21.8614 40.9075C22.2111 44.7131 22.7759 46.5824 23.0448 52.0152C23.246 54.5383 23.0966 57.0772 22.6011 59.5593C22.1474 61.5635 21.452 63.5052 20.5301 65.3418C19.266 67.8699 18.6206 69.1475 17.8003 69.1475Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M16.5186 20.5883C16.2766 21.6372 15.9269 23.1837 15.4832 25.026C14.7166 28.2938 13.3181 34.1973 10.4537 45.0092C7.76423 55.31 6.41947 60.4605 5.86811 61.738C4.72507 64.4275 3.86442 67.117 2.15657 72.4961C1.09421 75.8849 0.462174 78.1441 1.41695 80.4974C2.0221 81.9497 2.76172 82.3666 5.27642 84.5317C12.0002 90.3411 12.1616 92.4254 15.1873 92.8154C17.3636 92.9519 19.5312 92.4369 21.4136 91.3362C23.0067 90.416 24.4948 89.3248 25.8513 88.0819C28.9577 85.5403 30.5176 84.2358 31.2303 82.9045C31.5262 82.3128 32.0641 81.0353 31.822 76.5304C31.5794 72.7848 30.9851 69.0704 30.0469 65.4361C29.7242 64.0913 27.8684 56.4127 27.8281 56.2514C26.564 50.8723 26.9405 41.0824 26.9405 38.4871C26.9405 28.4955 27.2498 26.3842 26.6447 20.7228C26.3285 17.5767 25.7847 14.4576 25.0175 11.3901C24.1892 8.38137 23.2016 5.41872 22.059 2.5147C21.4673 0.927879 21.0908 0 20.5798 0C19.3561 0 18.4282 5.20422 18.1996 6.48174C17.5137 10.3815 17.8634 12.0491 17.1641 17.0247C16.9086 18.5712 16.68 19.8083 16.5186 20.5883Z" fill="#DEE1E9"/>
        <path d="M31.2363 82.8943C30.5773 84.2391 29.0039 85.5838 25.8572 88.0851C24.4931 89.3246 23.0009 90.4155 21.406 91.3394C19.5243 92.4293 17.3643 92.9436 15.1932 92.8186C12.1675 92.4152 12.0062 90.3443 5.26891 84.5215C2.82145 82.4102 2.02804 81.9799 1.42289 80.4872C0.454667 78.1473 1.0867 75.8746 2.16251 72.4993C3.87036 67.1202 4.71756 64.4307 5.86061 61.7412C6.07577 61.2302 6.42541 60.0603 6.94986 58.1776L9.33009 56.2815C10.3131 54.8827 11.6369 53.7579 13.1761 53.0137C14.9726 52.1656 16.9768 51.8573 18.9451 52.1262C18.9451 52.1262 22.6163 51.3328 28.6947 59.8585C29.2326 62.1043 29.8646 64.6862 30.0394 65.4393C30.9765 69.0784 31.5707 72.7972 31.8145 76.547C32.0834 81.0251 31.5321 82.3026 31.2363 82.8943Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask513" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="28" width="17" height="45">
          <path d="M23.4786 28.3593H16.7159L9.35217 68.1474L25.1165 72.962L23.4786 28.3593Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask513)">
          <path opacity="0.2" d="M17.8004 69.1475C16.0656 69.1475 14.1964 62.9212 13.8602 57.1522L15.7967 41.0151C16.5901 35.8915 17.3028 31.7362 17.8004 28.9122C18.9434 22.417 19.1451 21.637 19.4948 18.8534C20.2478 11.6858 19.9386 2.77073 20.5302 2.69005C21.1219 2.60936 21.7809 10.7176 22.3053 14.1198C22.9643 18.4365 22.5205 20.8436 22.1574 26.8413C21.6919 31.5151 21.593 36.2182 21.8616 40.9075C22.2112 44.7131 22.776 46.5824 23.0449 52.0152C23.2461 54.5383 23.0968 57.0772 22.6012 59.5593C22.1475 61.5635 21.4521 63.5052 20.5302 65.3418C19.2662 67.8699 18.6207 69.1475 17.8004 69.1475Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5013" mask-type="alpha" maskUnits="userSpaceOnUse" x="15" y="0" width="9" height="29">
          <path d="M22.7395 2.03498L22.0396 0H20.6398L19.6517 1.29995L15.1229 28.2313H23.8923L23.5218 16.2805L23.0689 5.70945L22.7395 2.03498Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5013)">
          <path opacity="0.4" d="M17.8004 69.1475C16.0656 69.1475 14.1964 62.9212 13.8602 57.1522L15.7967 41.0151C16.5901 35.8915 17.3028 31.7362 17.8004 28.9122C18.9434 22.417 19.1451 21.637 19.4948 18.8534C20.2478 11.6858 19.9386 2.77073 20.5302 2.69005C21.1219 2.60936 21.7809 10.7176 22.3053 14.1198C22.9643 18.4365 22.5205 20.8436 22.1574 26.8413C21.6919 31.5151 21.593 36.2182 21.8616 40.9075C22.2112 44.7131 22.776 46.5824 23.0449 52.0152C23.2461 54.5383 23.0968 57.0772 22.6012 59.5593C22.1475 61.5635 21.4521 63.5052 20.5302 65.3418C19.2662 67.8699 18.6207 69.1475 17.8004 69.1475Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M16.1066 21.2571C15.8646 22.306 15.5149 23.8524 15.0712 25.6948C14.3047 28.9625 12.9061 34.866 10.0418 45.6779C7.35224 55.9788 6.00748 61.1292 5.45613 62.4067C4.31308 65.0963 3.45243 67.7858 1.74459 73.1648C0.682224 76.5536 0.0501867 78.8128 1.00497 81.1662C1.61011 82.6185 2.34973 83.0354 4.86443 85.2004C11.5882 91.0098 11.7496 93.0942 14.7753 93.4842C16.9516 93.6207 19.1192 93.1057 21.0016 92.0049C22.5948 91.0848 24.0828 89.9936 25.4393 88.7506C28.5457 86.209 30.1056 84.9046 30.8183 83.5733C31.1142 82.9816 31.6521 81.7041 31.41 77.1991C31.1674 73.4536 30.5731 69.7391 29.6349 66.1048C29.3122 64.7601 27.4564 57.0815 27.4161 56.9201C26.152 51.5411 26.5285 41.7512 26.5285 39.1558C26.5285 29.1642 26.8378 27.053 26.2327 21.3915C25.9165 18.2454 25.3727 15.1264 24.6055 12.0589C23.7772 9.05013 22.7896 6.08748 21.6471 3.18346C21.0554 1.59664 20.6788 0.668762 20.1678 0.668762C18.9441 0.668762 18.0162 5.87298 17.7876 7.1505C17.1018 11.0503 17.4514 12.7178 16.7521 17.6934C16.4966 19.2399 16.268 20.4771 16.1066 21.2571Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
        <path d="M17.1066 20.5941C16.8646 21.6431 16.5149 23.1895 16.0712 25.0319C15.3047 28.2996 13.9061 34.2031 11.0418 45.015C8.35224 55.3159 7.00748 60.4663 6.45613 61.7438C5.31308 64.4334 4.45243 67.1229 2.74459 72.5019C1.68222 75.8907 1.05019 78.1499 2.00497 80.5033C2.61011 81.9556 3.34973 82.3725 5.86443 84.5375C12.5882 90.3469 12.7496 92.4313 15.7753 92.8213C17.9516 92.9578 20.1192 92.4428 22.0016 91.342C23.5948 90.4219 25.0828 89.3307 26.4393 88.0877C29.5457 85.5461 31.1056 84.2417 31.8183 82.9104C32.1142 82.3187 32.6521 81.0412 32.41 76.5362C32.1674 72.7907 31.5731 69.0762 30.6349 65.4419C30.3122 64.0972 28.4564 56.4186 28.4161 56.2572C27.152 50.8782 27.5285 41.0883 27.5285 38.4929C27.5285 28.5013 27.8378 26.3901 27.2327 20.7286C26.9165 17.5825 26.3727 14.4635 25.6055 11.396C24.7772 8.38723 23.7896 5.42458 22.6471 2.52056C22.0554 0.933738 21.6788 0.00585938 21.1678 0.00585938C19.9441 0.00585938 19.0162 5.21007 18.7876 6.4876C18.1018 10.3874 18.4514 12.0549 17.7521 17.0305C17.4966 18.577 17.268 19.8142 17.1066 20.5941Z" fill="#DEE1E9"/>
        <path d="M31.2915 83.8626C30.6326 85.2073 29.0592 86.5521 25.9125 89.0533C24.5484 90.2929 23.0562 91.3838 21.4613 92.3077C19.5796 93.3976 17.4196 93.9118 15.2485 93.7869C12.2228 93.3835 12.0615 91.3125 5.32421 85.4897C2.87675 83.3784 2.08334 82.9481 1.47819 81.4554C0.509965 79.1156 1.142 76.8429 2.21781 73.4676C3.92566 68.0885 4.77286 65.399 5.9159 62.7095C6.13107 62.1985 6.4807 61.0285 7.00516 59.1459L9.38539 57.2497C10.3684 55.8509 11.6922 54.7262 13.2314 53.982C15.0279 53.1339 17.032 52.8255 19.0004 53.0944C19.0004 53.0944 22.6716 52.301 28.7499 60.8268C29.2879 63.0726 29.9199 65.6545 30.0947 66.4076C31.0318 70.0466 31.626 73.7654 31.8698 77.5153C32.1387 81.9934 31.5874 83.2709 31.2915 83.8626Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>
  </g>
</template>

<script>
export default {
name: "Tooth13",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
