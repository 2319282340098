export const materialAdapter =
    ({
         material_id,
         name,
         artikul,
         barcode,
         price,
         material_group_id,
         new_order_amount,
         min_stock,
         unit_id,
         flag
     }) => ({
        material_id: material_id ?? null,
        name: name ?? null,
        artikul: artikul ?? null,
        barcode: barcode ?? null,
        price: price ?? null,
        material_group_id: material_group_id ?? null,
        new_order_amount: new_order_amount ?? null,
        min_stock: min_stock ?? null,
        unit_id: unit_id ?? null,
        flag: flag ?? null

    })
