<template>
  <div class="">
    <ServiceMultiSelect :label="$t('complex_services')"
                        class="mt-2"
                        title="title"
                        model="complex_service"
                        v-model="object.complex_services"
                        @select="serviceAdded"
                        @remove="serviceDeleted"
                        :dblclick="true"
                        :dbsearch="true"/>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"

export default {
  name: "PlanTreatmentTemplateDatatableFormComplexServices",
  components: {
    ServiceMultiSelect,
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  methods: {
    serviceAdded(complexService) {
      if(!this.object.services) this.object.services = []
      for(const service of complexService.services) {
        for(let i = 1; i <= service.pivot.amount; i++) {
          this.object.services.push(service)
        }
      }
      this.object.services.forEach(s => {
        s.qty = this.object.services.filter(ser => ser.id === s.id).length
      })
    },
    serviceDeleted(complexService) {
      for(const service of complexService.services) {
        for(let i = 1; i <= service.pivot.amount; i++) {
          this.object.services.some((srv, index) => {
            if (srv.id === service.id) {
              this.object.services.splice(index, 1)
              return true;
            }
          })
        }
      }
    }
  }
}
</script>
