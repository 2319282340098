<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M12.9871 80.7742L15.0708 82.0439H18.5545L20.6545 80.7742L19.0429 79.2277L21.1266 77.6812L19.2382 76.1347L21.5336 74.4416L19.7103 72.8951L22.0056 71.4951L19.987 70.0789L22.0056 68.4672L20.3126 66.9207L22.5429 65.5044L20.7847 64.0393L23.0801 62.4928L20.9312 60.9463L23.0801 59.3347L21.5336 57.9835L23.6173 56.437L21.794 54.9556L23.8777 53.4091L22.0708 51.6673L23.0801 49.9905V35.7301L21.1917 28.4045H15.0708" fill="#8889BE"/>
      <path d="M20.3943 80.7742L18.3106 82.0439H14.8432L12.7269 80.7742L14.3548 79.2277L12.2548 77.6812L14.1432 76.1347L11.8641 74.4416L13.6711 72.8951L11.392 71.4951L13.4106 70.0789L11.392 68.4672L13.0688 66.9207L10.8548 65.5044L12.5967 64.0393L10.3176 62.4928L12.4664 60.9463L10.3176 59.3347L11.8641 57.9835L9.78041 56.437L11.5874 54.9556L9.50366 53.4091L11.3269 51.6673L10.3176 49.9905V35.7301L12.1897 28.4045H16.0967" fill="#8889BE"/>
      <path d="M1.7011 13.6519C1.37904 15.5702 -0.0212154 25.232 6.23793 32.3733C8.27714 34.9073 11.0662 36.7307 14.2054 37.5823C14.5835 37.6663 21.7528 39.3326 26.3456 34.9218C28.39 32.9614 28.9781 30.651 30.0563 26.1282C31.0262 22.0414 31.4964 17.852 31.4565 13.6519L21.8088 3.23399C21.8088 3.23399 17.44 -1.49888 12.7911 3.23399C8.14228 7.96685 1.7011 13.6519 1.7011 13.6519Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M27.2418 33.4211L26.9757 44.8892C25.7475 51.8846 25.3667 59.0025 25.8415 66.0891C26.2852 70.1829 26.0916 74.3208 25.2674 78.3553C24.9733 79.7555 23.153 87.919 20.5205 87.947C18.7142 87.947 17.2859 84.0963 16.8239 82.8641C15.4236 79.0834 16.0957 76.801 15.6756 72.9223C15.2836 69.3797 14.3734 67.9654 12.6651 63.2045C10.7348 57.7806 9.23191 52.2139 8.17028 46.5555L6.77002 32.3149" fill="#DEE1E9"/>-->
<!--      <path d="M1.7011 13.0334C1.37904 14.9517 -0.0212154 24.6135 6.23793 31.7548C8.27714 34.2888 11.0662 36.1122 14.2054 36.9637C14.5835 37.0477 21.7528 38.7141 26.3456 34.3032C28.39 32.3429 28.9781 30.0325 30.0563 25.5096C31.0262 21.4229 31.4964 17.2335 31.4565 13.0334L21.8088 2.61546C21.8088 2.61546 17.44 -2.11741 12.7911 2.61546C8.14228 7.34832 1.7011 13.0334 1.7011 13.0334Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>-->
<!--      <path opacity="0.2" d="M10.8029 30.4666C9.15064 31.8668 11.9511 35.9276 14.4856 45.1693C15.3731 48.4387 16.0882 51.7526 16.628 55.0971C17.5522 60.5161 17.2861 61.3982 18.4623 68.3855C18.9524 71.412 19.321 74.2534 19.9069 78.2411C20.705 80.8876 20.2127 85.4479 20.4927 85.4339C21.0248 85.4339 22.5091 77.2211 22.355 73.9865C22.2793 72.3268 22.0827 70.6748 21.7669 69.0436C21.1452 65.1987 20.7896 61.3154 20.7027 57.4215C20.3807 50.4202 20.2127 46.8916 21.1228 43.2229C22.033 39.5542 21.991 36.0536 22.8311 32.5389C22.9852 31.8668 23.3912 30.2425 22.6071 29.6544C21.823 29.0663 20.6467 29.8084 19.1905 30.2565C14.6396 31.6708 12.1892 29.2203 10.8029 30.4666Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M27.0579 33.2141L26.7919 44.6822C25.5637 51.6776 25.1828 58.7956 25.6577 65.8821C26.1014 69.9759 25.9077 74.1138 25.0836 78.1483C24.7895 79.5486 22.9692 87.7121 20.3367 87.7401C18.5304 87.7401 17.1021 83.8894 16.64 82.6571C15.2398 78.8765 15.9119 76.594 15.4918 72.7153C15.0997 69.1727 14.1896 67.7584 12.4813 62.9976C10.5509 57.5736 9.04807 52.007 7.98644 46.3485L6.58618 32.1079" fill="#DEE1E9"/>
        <mask id="mask445" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask445)">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M17.6923 16.7991C18.7544 16.7991 20 11.5008 20 1.90252L18.0769 0.0404528L15 -1.20093C15 8.3974 16.6302 16.7991 17.6923 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.5046 16.0862C8.82213 17.8367 11.6738 22.9129 14.2546 34.4656C15.1583 38.5526 15.8865 42.6952 16.4361 46.876C17.3772 53.6501 17.1063 54.7529 18.304 63.4874C18.803 67.2707 19.1784 70.8228 19.7749 75.8076C20.5876 79.1159 20.0863 84.8165 20.3714 84.799C20.9133 84.799 22.4247 74.5326 22.2678 70.4891C22.1906 68.4143 21.9905 66.3492 21.669 64.3101C21.0359 59.5037 20.6738 54.6494 20.5853 49.7817C20.2574 41.0296 20.0863 36.6186 21.0131 32.0325C21.9399 27.4464 21.8971 23.0704 22.7526 18.6769C22.9094 17.8367 23.3229 15.8062 22.5245 15.071C21.726 14.3358 20.5283 15.2635 19.0454 15.8237C14.4114 17.5916 11.9162 14.5284 10.5046 16.0862Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M27.2418 33.4211L26.9757 44.8892C25.7475 51.8846 25.3667 59.0025 25.8415 66.0891C26.2852 70.1829 26.0916 74.3208 25.2674 78.3553C24.9733 79.7555 23.153 87.919 20.5205 87.947C18.7142 87.947 17.2859 84.0963 16.8239 82.8641C15.4236 79.0834 16.0957 76.801 15.6756 72.9223C15.2836 69.3797 14.3734 67.9654 12.6651 63.2045C10.7348 57.7806 9.23191 52.2139 8.17028 46.5555L6.77002 32.3149" fill="#DEE1E9"/>
      <path d="M1.7011 13.0334C1.37904 14.9517 -0.0212154 24.6135 6.23793 31.7548C8.27714 34.2888 11.0662 36.1122 14.2054 36.9637C14.5835 37.0477 21.7528 38.7141 26.3456 34.3032C28.39 32.3429 28.9781 30.0325 30.0563 25.5096C31.0262 21.4229 31.4964 17.2335 31.4565 13.0334L21.8088 2.61546C21.8088 2.61546 17.44 -2.11741 12.7911 2.61546C8.14228 7.34832 1.7011 13.0334 1.7011 13.0334Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M10.8029 30.4666C9.15064 31.8668 11.9511 35.9276 14.4856 45.1693C15.3731 48.4387 16.0882 51.7526 16.628 55.0971C17.5522 60.5161 17.2861 61.3982 18.4623 68.3855C18.9524 71.412 19.321 74.2534 19.9069 78.2411C20.705 80.8876 20.2127 85.4479 20.4927 85.4339C21.0248 85.4339 22.5091 77.2211 22.355 73.9865C22.2793 72.3268 22.0827 70.6748 21.7669 69.0436C21.1452 65.1987 20.7896 61.3154 20.7027 57.4215C20.3807 50.4202 20.2127 46.8916 21.1228 43.2229C22.033 39.5542 21.991 36.0536 22.8311 32.5389C22.9852 31.8668 23.3912 30.2425 22.6071 29.6544C21.823 29.0663 20.6467 29.8084 19.1905 30.2565C14.6396 31.6708 12.1892 29.2203 10.8029 30.4666Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="20" cy="87" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M30.4395 12.891C30.4854 17.0914 30.0151 21.2816 29.0392 25.3673C28.1431 29.19 27.639 31.4304 26.2387 33.2087L25.9727 44.6768C24.7669 51.6986 24.4143 58.8403 24.9225 65.9467C25.3662 70.0405 25.1726 74.1784 24.3484 78.2129C24.0543 79.6132 22.234 87.7767 19.6015 87.8047C17.7952 87.8047 16.3669 83.954 15.9048 82.7218C14.5046 78.9411 15.1767 76.6587 14.7566 72.78C14.3645 69.2373 13.4544 67.823 11.7461 63.0622C9.79674 57.6398 8.27514 52.0731 7.19524 46.4131L5.79498 32.1725L5.30489 31.6124C-0.926243 24.4711 0.474012 14.8094 0.796071 12.891C0.796071 12.891 7.26525 7.28999 11.8861 2.47311C16.5069 -2.34377 20.9037 2.47311 20.9037 2.47311L30.4395 12.891Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M30.4395 12.891C30.4854 17.0914 30.0151 21.2816 29.0392 25.3673C28.1431 29.19 27.639 31.4304 26.2387 33.2087L25.9727 44.6768C24.7669 51.6986 24.4143 58.8403 24.9225 65.9467C25.3662 70.0405 25.1726 74.1784 24.3484 78.2129C24.0543 79.6132 22.234 87.7767 19.6015 87.8047C17.7952 87.8047 16.3669 83.954 15.9048 82.7218C14.5046 78.9411 15.1767 76.6587 14.7566 72.78C14.3645 69.2373 13.4544 67.823 11.7461 63.0622C9.79674 57.6398 8.27514 52.0731 7.19524 46.4131L5.79498 32.1725L5.30489 31.6124C-0.926243 24.4711 0.474012 14.8094 0.796071 12.891C0.796071 12.891 7.26525 7.28999 11.8861 2.47311C16.5069 -2.34377 20.9037 2.47311 20.9037 2.47311L30.4395 12.891Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M20.4107 15.8831L20.6768 4.41498L15.6188 3.30878L10.3362 5.48984L4.61884 2.5L0.205017 3.30878L1.60527 17.5494C2.66691 23.2078 4.16977 28.7745 6.10009 34.1984C7.80841 38.9593 8.71857 40.3735 9.11064 43.9162C9.53072 47.7949 8.8586 50.0773 10.2589 53.858L10.2707 53.8897C10.745 55.1549 12.1644 58.9409 13.9555 58.9409C16.588 58.9129 18.4083 50.7494 18.7024 49.3492C19.5266 45.3147 19.7202 41.1768 19.2765 37.083C18.8017 29.9964 19.1825 22.8785 20.4107 15.8831Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M7.92059 16.1632C5.38612 6.92146 4.23792 1.46046 4.23792 1.46046L5.38753 1.09558L5.86731 1.5L8.11884 3L10.6188 1.5L13.1188 3L16.1188 1C16.1188 1 16.4201 2.86072 16.2661 3.53284C15.426 7.04749 15.468 10.5481 14.5578 14.2168C13.6524 17.8662 13.8139 21.377 14.1327 28.3054L14.1377 28.4154C14.2246 32.3093 14.5802 36.1926 15.2019 40.0375C15.5177 41.6687 15.7142 43.3207 15.79 44.9804C15.9441 48.215 14.4598 56.4277 13.9277 56.4277C13.6476 56.4418 14.14 51.8815 13.3418 49.235C12.756 45.2473 12.3874 42.4059 11.8973 39.3794C10.7211 32.3921 10.9871 31.51 10.063 26.091C9.52319 22.7465 8.80808 19.4326 7.92059 16.1632Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M1.93016 11.1249C9.65757 13.7543 13.4893 12.7998 19.6117 7.5976" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M27.0579 33.9091L26.7919 45.3772C25.5637 52.3726 25.1828 59.4906 25.6577 66.5771C26.1014 70.6709 25.9077 74.8088 25.0836 78.8433C24.7895 80.2436 22.9692 88.4071 20.3367 88.4351C18.5304 88.4351 17.1021 84.5844 16.64 83.3522C15.2398 79.5715 15.9119 77.289 15.4918 73.4103C15.0997 69.8677 14.1896 68.4534 12.4813 63.6926C10.5509 58.2686 9.04807 52.702 7.98644 47.0435L6.58618 32.8029" fill="#DEE1E9"/>
        <path d="M6.05409 32.2428C-0.205054 25.1015 1.1952 15.4397 1.51726 13.5214C1.51726 13.5214 7.95844 7.83636 12.6073 3.1035C17.2561 -1.62937 21.6249 3.1035 21.6249 3.1035L23.2924 4.90409L22.0296 5.16412L20.6909 8.1991L24.5 9.71384L23.2924 12.0273L24.5 14.4941L23.5929 17.1394H27.5L31.2727 14.507C31.236 18.3764 30.7665 22.2307 29.8724 25.9977C28.7942 30.5205 28.2061 32.8309 26.1618 34.7913C21.5689 39.2021 14.3996 37.5358 14.0215 37.4518C10.8824 36.6003 8.0933 34.7768 6.05409 32.2428Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M27.6758 24.5387C17.5767 20.5435 15.2637 16.0507 16.4195 4.49416" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M10.6191 30.9546C8.9668 32.3549 11.7673 36.4156 14.3018 45.6573C15.1893 48.9268 15.9044 52.2406 16.4442 55.5851C17.3683 61.0041 17.1023 61.8863 18.2785 68.8735C18.7686 71.9 19.1372 74.7415 19.723 78.7291C20.5212 81.3756 20.0288 85.9359 20.3089 85.9219C20.841 85.9219 22.3252 77.7092 22.1712 74.4746C22.0954 72.8148 21.8989 71.1628 21.5831 69.5317C20.9614 65.6867 20.6058 61.8034 20.5189 57.9095C20.1968 50.9083 20.0288 47.3796 20.939 43.7109C21.8492 40.0423 21.8071 36.5416 22.6473 33.027C22.8013 32.3549 23.2074 30.7306 22.4233 30.1425C21.6391 29.5544 20.4629 30.2965 19.0066 30.7446C14.4558 32.1588 12.0054 29.7084 10.6191 30.9546Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M26.7919 17.7494V45.1325C25.5637 52.1279 25.1828 59.2459 25.6577 66.3324C26.1014 70.4262 25.9077 74.5641 25.0836 78.5986C24.7895 79.9989 22.9692 88.1623 20.3367 88.1903C18.5304 88.1903 17.1021 84.3396 16.64 83.1074C15.2398 79.3267 15.9119 77.0443 15.4918 73.1656C15.0997 69.623 14.1896 68.2087 12.4813 63.4479C10.5509 58.0239 9.04807 52.4572 7.98644 46.7988L6.58618 15.6432" fill="#DEE1E9"/>
        <path d="M6.05409 31.9981C-0.205054 24.8568 1.1952 15.195 1.51726 13.2766C1.51726 13.2766 7.95844 7.59161 12.6073 2.85874C17.2561 -1.87412 21.6249 2.85874 21.6249 2.85874L31.2727 13.2766C31.3126 17.4767 30.8424 21.6662 29.8724 25.7529C28.9363 29.68 28.3696 31.9391 26.8983 33.7494H23L21.5 23.7494C17.7897 22.6024 15.7107 22.5746 12 23.7494L10.8524 33.7494H7.70475C7.11242 33.212 6.56016 32.6269 6.05409 31.9981Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M26.7919 17.5V44.8831C25.5637 51.8785 25.1828 58.9965 25.6577 66.083C26.1014 70.1768 25.9077 74.3147 25.0836 78.3492C24.7895 79.7495 22.9692 87.9129 20.3367 87.9409C18.5304 87.9409 17.1021 84.0903 16.64 82.858C15.2398 79.0774 15.9119 76.795 15.4918 72.9162C15.0997 69.3736 14.1896 67.9593 12.4813 63.1985C10.5509 57.7746 9.04807 52.2078 7.98644 46.5494L6.58618 15.3938" fill="#DEE1E9"/>
        <path d="M6.05409 31.7487C-0.205054 24.6074 1.1952 14.9456 1.51726 13.0273C1.51726 13.0273 7.95844 7.34222 12.6073 2.60935C17.2561 -2.12351 21.6249 2.60935 21.6249 2.60935L31.2727 13.0273C31.3126 17.2274 30.8424 21.4168 29.8724 25.5035C28.9363 29.4306 28.3696 31.6897 26.8983 33.5H23L21.5 23.5C17.7897 22.353 15.7107 22.3252 12 23.5L10.8524 33.5H7.70475C7.11242 32.9627 6.56016 32.3775 6.05409 31.7487Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M26.7919 17.5V44.8831C25.5637 51.8785 25.1828 58.9965 25.6577 66.083C26.1014 70.1768 25.9077 74.3147 25.0836 78.3492C24.7895 79.7495 22.9692 87.9129 20.3367 87.9409C18.5304 87.9409 17.1021 84.0903 16.64 82.858C15.2398 79.0773 15.9119 76.7949 15.4918 72.9162C15.0997 69.3736 14.1896 67.9593 12.4813 63.1985C10.5509 57.7745 9.04807 52.2078 7.98644 46.5494L6.58618 15.3938" fill="#DEE1E9"/>
        <mask id="mask2045" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="35">
          <path d="M5.89662 32.2428C-0.362525 25.1015 1.03773 15.4397 1.35979 13.5214C1.35979 13.5214 7.80097 7.83636 12.4498 3.1035C17.0987 -1.62937 21.4675 3.1035 21.4675 3.1035L31.1152 13.5214C31.1551 17.7215 30.6849 21.9109 29.715 25.9977C28.7788 29.9248 28.2121 32.1839 26.7408 33.9941H22.8425L21.3425 23.9941C17.6322 22.8472 15.5532 22.8193 11.8425 23.9941L10.6949 33.9941H7.54728C6.95495 33.4568 6.40269 32.8717 5.89662 32.2428Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2045)">
          <path d="M5.89662 32.2428C-0.362525 25.1015 1.03773 15.4397 1.35979 13.5214C1.35979 13.5214 7.80097 7.83636 12.4498 3.1035C17.0987 -1.62937 21.4675 3.1035 21.4675 3.1035L31.1152 13.5214C31.1551 17.7215 30.6849 21.9109 29.715 25.9977C28.7788 29.9248 28.2121 32.1839 26.7408 33.9941H22.8425L21.3425 23.9941C17.6322 22.8472 15.5532 22.8193 11.8425 23.9941L10.6949 33.9941H7.54728C6.95495 33.4568 6.40269 32.8717 5.89662 32.2428Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M26 47H6V21.452C13.8105 19.5258 18.1895 19.5062 26 21.452V47Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M26.6101 33.5559L26.3441 45.024C25.1158 52.0194 24.735 59.1374 25.2099 66.2239C25.6536 70.3177 25.4599 74.4556 24.6357 78.4901C24.3417 79.8904 22.5214 88.0539 19.8889 88.0819C18.0825 88.0819 16.6543 84.2312 16.1922 82.9989C14.7919 79.2183 15.4641 76.9358 15.044 73.0571C14.6519 69.5145 13.7418 68.1002 12.0334 63.3394C10.1031 57.9154 8.60026 52.3488 7.53862 46.6903L6.13837 32.4497" fill="#DEE1E9"/>
        <path d="M1.06945 13.1684C0.747387 15.0867 -0.652868 24.7485 5.60627 31.8898C7.64549 34.4238 10.4345 36.2472 13.5737 37.0987C13.9518 37.1828 21.1211 38.8491 25.7139 34.4383C27.7583 32.4779 28.3464 30.1675 29.4246 25.6447C30.3946 21.5579 30.8648 17.3685 30.8249 13.1684L21.1771 2.75047C21.1771 2.75047 16.8083 -1.9824 12.1595 2.75047C7.51062 7.48333 1.06945 13.1684 1.06945 13.1684Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M10.1713 30.6015C8.51898 32.0017 11.3195 36.0625 13.854 45.3041C14.7414 48.5736 15.4566 51.8875 15.9963 55.232C16.9205 60.6509 16.6545 61.5331 17.8307 68.5204C18.3208 71.5469 18.6894 74.3883 19.2752 78.376C20.0734 81.0225 19.581 85.5827 19.8611 85.5687C20.3932 85.5687 21.8774 77.356 21.7234 74.1214C21.6476 72.4617 21.4511 70.8097 21.1353 69.1785C20.5135 65.3336 20.158 61.4503 20.0711 57.5564C19.749 50.5551 19.581 47.0265 20.4912 43.3578C21.4013 39.6891 21.3593 36.1885 22.1995 32.6738C22.3535 32.0017 22.7596 30.3774 21.9754 29.7893C21.1913 29.2012 20.0151 29.9433 18.5588 30.3914C14.008 31.8057 11.5575 29.3552 10.1713 30.6015Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M25.1855 66.1442C20.7041 68.4861 18.2017 68.3794 13.7468 66.1442" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M27.0579 33.415L26.7919 44.8831C25.5637 51.8785 25.1828 58.9965 25.6577 66.083C26.1014 70.1769 25.9077 74.3147 25.0836 78.3493C24.7895 79.7495 22.9692 87.913 20.3367 87.941C18.5304 87.941 17.1021 84.0903 16.64 82.8581C15.2398 79.0774 15.9119 76.795 15.4918 72.9163C15.0997 69.3736 14.1896 67.9594 12.4813 63.1985C10.5509 57.7746 9.04807 52.2079 7.98644 46.5494L6.58618 32.3088" fill="#DEE1E9"/>
        <mask id="mask2245" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2245)">
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M17.6923 17C18.7544 17 20 11.7018 20 2.10345L18.0769 0.241381L15 -1C15 8.59833 16.6302 17 17.6923 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.5046 16.2872C8.82213 18.0376 11.6738 23.1138 14.2546 34.6665C15.1583 38.7536 15.8865 42.8961 16.4361 47.0769C17.3772 53.851 17.1063 54.9538 18.304 63.6884C18.803 67.4717 19.1784 71.0237 19.7749 76.0085C20.5876 79.3168 20.0863 85.0175 20.3714 85C20.9133 85 22.4247 74.7335 22.2678 70.69C22.1906 68.6153 21.9905 66.5501 21.669 64.5111C21.0359 59.7046 20.6738 54.8503 20.5853 49.9826C20.2574 41.2306 20.0863 36.8195 21.0131 32.2334C21.9399 27.6474 21.8971 23.2713 22.7526 18.8778C22.9094 18.0376 23.3229 16.0071 22.5245 15.2719C21.726 14.5368 20.5283 15.4645 19.0454 16.0246C14.4114 17.7925 11.9162 14.7293 10.5046 16.2872Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="20" cy="86.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M27.0579 33.415L26.7919 44.8831C25.5637 51.8785 25.1828 58.9965 25.6577 66.083C26.1014 70.1769 25.9077 74.3147 25.0836 78.3493C24.7895 79.7495 22.9692 87.913 20.3367 87.941C18.5304 87.941 17.1021 84.0903 16.64 82.8581C15.2398 79.0774 15.9119 76.795 15.4918 72.9163C15.0997 69.3736 14.1896 67.9594 12.4813 63.1985C10.5509 57.7746 9.04807 52.2079 7.98644 46.5494L6.58618 32.3088" fill="#DEE1E9"/>
        <mask id="mask2345" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2345)">
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M17.6923 17C18.7544 17 20 11.7018 20 2.10345L18.0769 0.241381L15 -1C15 8.59833 16.6302 17 17.6923 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.5046 16.2872C8.82213 18.0376 11.6738 23.1138 14.2546 34.6665C15.1583 38.7536 15.8865 42.8961 16.4361 47.0769C17.3772 53.851 17.1063 54.9538 18.304 63.6884C18.803 67.4717 19.1784 71.0237 19.7749 76.0085C20.5876 79.3168 20.0863 85.0175 20.3714 85C20.9133 85 22.4247 74.7335 22.2678 70.69C22.1906 68.6153 21.9905 66.5501 21.669 64.5111C21.0359 59.7046 20.6738 54.8503 20.5853 49.9826C20.2574 41.2306 20.0863 36.8195 21.0131 32.2334C21.9399 27.6474 21.8971 23.2713 22.7526 18.8778C22.9094 18.0376 23.3229 16.0071 22.5245 15.2719C21.726 14.5368 20.5283 15.4645 19.0454 16.0246C14.4114 17.7925 11.9162 14.7293 10.5046 16.2872Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="20" cy="86.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2345)">
          <path d="M20 84L20.8229 87.7391L23.8567 85.4037L22.0837 88.797L25.9088 88.9581L22.3694 90.4178L25.1962 93L21.5465 91.8431L22.0521 95.6382L20 92.406L17.9479 95.6382L18.4535 91.8431L14.8038 93L17.6306 90.4178L14.0912 88.9581L17.9163 88.797L16.1433 85.4037L19.1771 87.7391L20 84Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2345" x="13.8911" y="83.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M27.0579 33.415L26.7919 44.8831C25.5637 51.8785 25.1828 58.9965 25.6577 66.083C26.1014 70.1769 25.9077 74.3147 25.0836 78.3493C24.7895 79.7495 22.9692 87.913 20.3367 87.941C18.5304 87.941 17.1021 84.0903 16.64 82.8581C15.2398 79.0774 15.9119 76.795 15.4918 72.9163C15.0997 69.3736 14.1896 67.9594 12.4813 63.1985C10.5509 57.7746 9.04807 52.2079 7.98644 46.5494L6.58618 32.3088" fill="#DEE1E9"/>
      <mask id="mask2345" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
        <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2345)">
        <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M17.6923 17C18.7544 17 20 11.7018 20 2.10345L18.0769 0.241381L15 -1C15 8.59833 16.6302 17 17.6923 17Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M10.5046 16.2872C8.82213 18.0376 11.6738 23.1138 14.2546 34.6665C15.1583 38.7536 15.8865 42.8961 16.4361 47.0769C17.3772 53.851 17.1063 54.9538 18.304 63.6884C18.803 67.4717 19.1784 71.0237 19.7749 76.0085C20.5876 79.3168 20.0863 85.0175 20.3714 85C20.9133 85 22.4247 74.7335 22.2678 70.69C22.1906 68.6153 21.9905 66.5501 21.669 64.5111C21.0359 59.7046 20.6738 54.8503 20.5853 49.9826C20.2574 41.2306 20.0863 36.8195 21.0131 32.2334C21.9399 27.6474 21.8971 23.2713 22.7526 18.8778C22.9094 18.0376 23.3229 16.0071 22.5245 15.2719C21.726 14.5368 20.5283 15.4645 19.0454 16.0246C14.4114 17.7925 11.9162 14.7293 10.5046 16.2872Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="20" cy="86.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2345)">
        <path d="M20 84L20.8229 87.7391L23.8567 85.4037L22.0837 88.797L25.9088 88.9581L22.3694 90.4178L25.1962 93L21.5465 91.8431L22.0521 95.6382L20 92.406L17.9479 95.6382L18.4535 91.8431L14.8038 93L17.6306 90.4178L14.0912 88.9581L17.9163 88.797L16.1433 85.4037L19.1771 87.7391L20 84Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2345" x="13.8911" y="83.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M27.0579 33.2141L26.7919 44.6822C25.5637 51.6776 25.1828 58.7956 25.6577 65.8821C26.1014 69.9759 25.9077 74.1138 25.0836 78.1483C24.7895 79.5486 22.9692 87.7121 20.3367 87.7401C18.5304 87.7401 17.1021 83.8894 16.64 82.6571C15.2398 78.8765 15.9119 76.594 15.4918 72.7153C15.0997 69.1727 14.1896 67.7584 12.4813 62.9976C10.5509 57.5736 9.04807 52.007 7.98644 46.3485L6.58618 32.1079" fill="#DEE1E9"/>
        <mask id="mask2545" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2545)">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M17.6923 16.7991C18.7544 16.7991 20 11.5008 20 1.90252L18.0769 0.0404528L15 -1.20093C15 8.3974 16.6302 16.7991 17.6923 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.5046 16.0862C8.82213 17.8367 11.6738 22.9129 14.2546 34.4656C15.1583 38.5526 15.8865 42.6952 16.4361 46.876C17.3772 53.6501 17.1063 54.7529 18.304 63.4874C18.803 67.2707 19.1784 70.8228 19.7749 75.8076C20.5876 79.1159 20.0863 84.8165 20.3714 84.799C20.9133 84.799 22.4247 74.5326 22.2678 70.4891C22.1906 68.4143 21.9905 66.3492 21.669 64.3101C21.0359 59.5037 20.6738 54.6494 20.5853 49.7817C20.2574 41.0296 20.0863 36.6186 21.0131 32.0325C21.9399 27.4464 21.8971 23.0704 22.7526 18.6769C22.9094 17.8367 23.3229 15.8062 22.5245 15.071C21.726 14.3358 20.5283 15.2635 19.0454 15.8237C14.4114 17.5916 11.9162 14.5284 10.5046 16.0862Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="20" cy="86" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2645" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="89">
          <path d="M26.9005 33.9092L26.6344 45.3773C25.4062 52.3727 25.0254 59.4906 25.5002 66.5772C25.9439 70.671 25.7503 74.8089 24.9261 78.8434C24.632 80.2436 22.8117 88.4071 20.1792 88.4351C18.3729 88.4351 16.9446 84.5844 16.4825 83.3522C15.0823 79.5715 15.7544 77.2891 15.3343 73.4104C14.9423 69.8677 14.0321 68.4535 12.3238 63.6926C10.3935 58.2687 8.8906 52.702 7.82897 47.0436L6.42871 32.803" fill="#DEE1E9"/>
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2645)">
          <path d="M26.9005 33.9092L26.6344 45.3773C25.4062 52.3727 25.0254 59.4906 25.5002 66.5772C25.9439 70.671 25.7503 74.8089 24.9261 78.8434C24.632 80.2436 22.8117 88.4071 20.1792 88.4351C18.3729 88.4351 16.9446 84.5844 16.4825 83.3522C15.0823 79.5715 15.7544 77.2891 15.3343 73.4104C14.9423 69.8677 14.0321 68.4535 12.3238 63.6926C10.3935 58.2687 8.8906 52.702 7.82897 47.0436L6.42871 32.803" fill="#DEE1E9"/>
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M11.9741 20.6762H0.0529232C2.21498 26.046 2.57199 29.3063 2.27545 35.0446C2.26427 35.2609 2.51364 35.3955 2.70387 35.2689C3.70794 34.601 6.06614 32.7849 6.63778 30.1432C7.33025 26.9432 9.33345 26.1661 9.54606 26.092C9.55902 26.0875 9.57061 26.083 9.58299 26.0773L10.7659 25.5307C10.9105 25.4639 10.9691 25.3014 10.8968 25.1678L10.3679 24.1901C10.3231 24.1074 10.3272 24.0092 10.3787 23.9299L12.2251 21.0858C12.3421 20.9056 12.2016 20.6762 11.9741 20.6762Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.04897 34.155C4.10995 33.3071 5.51793 31.8702 5.92035 30.0106C6.66356 26.5761 8.83953 25.6201 9.2725 25.4648L10.0461 25.1073L9.71349 24.4925C9.55687 24.203 9.57114 23.8596 9.75132 23.5821L11.1988 21.3524H1.09621C2.87294 26.0171 3.24894 29.2129 3.04897 34.155ZM9.25579 25.4725C9.25565 25.4726 9.25593 25.4724 9.25579 25.4725V25.4725ZM3.1313 35.8178C2.40244 36.3027 1.50671 35.7463 1.54464 35.0123C1.83745 29.3462 1.48824 26.18 -0.632782 20.9121L-1 20H11.9741C12.7703 20 13.2621 20.8028 12.8524 21.4338L11.1301 24.0869L11.5512 24.8654C11.8042 25.333 11.5991 25.9017 11.0931 26.1355L9.91021 26.6822C9.86949 26.701 9.83364 26.7145 9.80412 26.7248C9.76753 26.7376 7.98671 27.3577 7.35523 30.2758C6.72573 33.1849 4.16235 35.1319 3.1313 35.8178Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask145" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="89">
          <path d="M26.9005 33.9092L26.6344 45.3773C25.4062 52.3727 25.0254 59.4906 25.5002 66.5772C25.9439 70.671 25.7503 74.8089 24.9261 78.8434C24.632 80.2436 22.8117 88.4071 20.1792 88.4351C18.3729 88.4351 16.9446 84.5844 16.4825 83.3522C15.0823 79.5715 15.7544 77.2891 15.3343 73.4104C14.9423 69.8677 14.0321 68.4535 12.3238 63.6926C10.3935 58.2687 8.8906 52.702 7.82897 47.0436L6.42871 32.803" fill="#DEE1E9"/>
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask145)">
          <path d="M26.9005 33.9092L26.6344 45.3773C25.4062 52.3727 25.0254 59.4906 25.5002 66.5772C25.9439 70.671 25.7503 74.8089 24.9261 78.8434C24.632 80.2436 22.8117 88.4071 20.1792 88.4351C18.3729 88.4351 16.9446 84.5844 16.4825 83.3522C15.0823 79.5715 15.7544 77.2891 15.3343 73.4104C14.9423 69.8677 14.0321 68.4535 12.3238 63.6926C10.3935 58.2687 8.8906 52.702 7.82897 47.0436L6.42871 32.803" fill="#DEE1E9"/>
          <path d="M1.35979 13.5214C1.03773 15.4397 -0.362524 25.1015 5.89662 32.2428C7.93583 34.7768 10.7249 36.6003 13.8641 37.4518C14.2421 37.5358 21.4115 39.2021 26.0043 34.7913C28.0487 32.8309 28.6368 30.5205 29.715 25.9977C30.6849 21.9109 31.1551 17.7215 31.1152 13.5214L21.4675 3.1035C21.4675 3.1035 17.0987 -1.62937 12.4498 3.1035C7.80097 7.83636 1.35979 13.5214 1.35979 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M9.86628 26.5H1.71952C3.19704 30.4705 3.44101 32.8811 3.23836 37.1241C3.23072 37.2841 3.40114 37.3836 3.53113 37.29C4.2173 36.7961 5.82886 35.4532 6.21952 33.5C6.69274 31.1339 8.0617 30.5593 8.20699 30.5045C8.21585 30.5012 8.22377 30.4978 8.23223 30.4936L9.04063 30.0894C9.13943 30.04 9.17947 29.9199 9.13008 29.8211L8.76862 29.0982C8.73804 29.037 8.74082 28.9645 8.776 28.9058L10.0378 26.8029C10.1178 26.6696 10.0217 26.5 9.86628 26.5Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76699 36.4664C4.49205 35.8394 5.45424 34.777 5.72925 33.4019C6.23715 30.8625 7.72418 30.1555 8.02006 30.0407L8.54872 29.7764L8.32143 29.3218C8.21439 29.1077 8.22415 28.8538 8.34728 28.6486L9.33644 27H2.4325C3.64669 30.4491 3.90365 32.8121 3.76699 36.4664ZM8.00864 30.0464C8.00854 30.0465 8.00874 30.0464 8.00864 30.0464V30.0464ZM3.82326 37.6958C3.32516 38.0544 2.71303 37.643 2.73895 37.1003C2.93905 32.9106 2.70041 30.5695 1.25093 26.6744L0.999982 26H9.8663C10.4104 26 10.7465 26.5936 10.4665 27.0601L9.2895 29.0219L9.57731 29.5975C9.7502 29.9433 9.61005 30.3638 9.26426 30.5367L8.45586 30.9409C8.42803 30.9548 8.40354 30.9648 8.38336 30.9724C8.35836 30.9818 7.14137 31.4403 6.70983 33.5981C6.27964 35.749 4.52786 37.1887 3.82326 37.6958Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M27.0579 33.2141L26.7919 44.6822C25.5637 51.6776 25.1828 58.7956 25.6577 65.8821C26.1014 69.9759 25.9077 74.1138 25.0836 78.1483C24.7895 79.5486 22.9692 87.7121 20.3367 87.7401C18.5304 87.7401 17.1021 83.8894 16.64 82.6571C15.2398 78.8765 15.9119 76.594 15.4918 72.7153C15.0997 69.1727 14.1896 67.7584 12.4813 62.9976C10.5509 57.5736 9.04807 52.007 7.98644 46.3485L6.58618 32.1079" fill="#DEE1E9"/>
        <mask id="mask245" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask245)">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M17.6923 16.7991C18.7544 16.7991 20 11.5008 20 1.90252L18.0769 0.0404528L15 -1.20093C15 8.3974 16.6302 16.7991 17.6923 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.5046 16.0862C8.82213 17.8367 11.6738 22.9129 14.2546 34.4656C15.1583 38.5526 15.8865 42.6952 16.4361 46.876C17.3772 53.6501 17.1063 54.7529 18.304 63.4874C18.803 67.2707 19.1784 70.8228 19.7749 75.8076C20.5876 79.1159 20.0863 84.8165 20.3714 84.799C20.9133 84.799 22.4247 74.5326 22.2678 70.4891C22.1906 68.4143 21.9905 66.3492 21.669 64.3101C21.0359 59.5037 20.6738 54.6494 20.5853 49.7817C20.2574 41.0296 20.0863 36.6186 21.0131 32.0325C21.9399 27.4464 21.8971 23.0704 22.7526 18.6769C22.9094 17.8367 23.3229 15.8062 22.5245 15.071C21.726 14.3358 20.5283 15.2635 19.0454 15.8237C14.4114 17.5916 11.9162 14.5284 10.5046 16.0862Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M27.0579 33.2141L26.7919 44.6822C25.5637 51.6776 25.1828 58.7956 25.6577 65.8821C26.1014 69.9759 25.9077 74.1138 25.0836 78.1483C24.7895 79.5486 22.9692 87.7121 20.3367 87.7401C18.5304 87.7401 17.1021 83.8894 16.64 82.6571C15.2398 78.8765 15.9119 76.594 15.4918 72.7153C15.0997 69.1727 14.1896 67.7584 12.4813 62.9976C10.5509 57.5736 9.04807 52.007 7.98644 46.3485L6.58618 32.1079" fill="#DEE1E9"/>
        <mask id="mask345" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask345)">
          <path d="M1.35979 13.3205C1.03773 15.2388 -0.362524 24.9006 5.89662 32.0419C7.93583 34.5759 10.7249 36.3993 13.8641 37.2508C14.2421 37.3349 21.4115 39.0012 26.0043 34.5904C28.0487 32.63 28.6368 30.3196 29.715 25.7968C30.6849 21.71 31.1551 17.5206 31.1152 13.3205L21.4675 2.90257C21.4675 2.90257 17.0987 -1.8303 12.4498 2.90257C7.80097 7.63543 1.35979 13.3205 1.35979 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M17.6923 16.7991C18.7544 16.7991 20 11.5008 20 1.90252L18.0769 0.0404528L15 -1.20093C15 8.3974 16.6302 16.7991 17.6923 16.7991Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M27.0579 33.9092L26.7919 45.3773C25.5637 52.3727 25.1828 59.4906 25.6577 66.5772C26.1014 70.671 25.9077 74.8089 25.0836 78.8434C24.7895 80.2436 22.9692 88.4071 20.3367 88.4351C18.5304 88.4351 17.1021 84.5844 16.64 83.3522C15.2398 79.5715 15.9119 77.2891 15.4918 73.4104C15.0997 69.8677 14.1896 68.4535 12.4813 63.6926C10.5509 58.2687 9.04807 52.702 7.98644 47.0436L6.58618 32.803" fill="#DEE1E9"/>
        <path d="M1.51726 13.5214C1.1952 15.4397 -0.205053 25.1015 6.05409 32.2428C8.0933 34.7768 10.8824 36.6003 14.0215 37.4518C14.3996 37.5358 21.5689 39.2021 26.1618 34.7913C28.2061 32.8309 28.7942 30.5205 29.8724 25.9977C30.8424 21.9109 31.3126 17.7215 31.2727 13.5214L21.6249 3.1035C21.6249 3.1035 17.2561 -1.62937 12.6073 3.1035C7.95844 7.83636 1.51726 13.5214 1.51726 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M20 3.49414L21.8667 5.995L23.5 4.4993V7.81334L26.0622 7.24543L24.6667 8.99603L27 10.9967L24.6667 12.9974L26.0622 14.748L23.7333 13.9977L23.5 17.4941L21.2501 15.9971L20.4667 16.9988L18.7499 15.9971L16.5 17.4941L15.8 15.4983L13.9378 14.748L14.4 11.9971L13 10.9967L15.3333 9.4962L13.9378 7.24543L15.8 6.99534L16.5 4.4993L19.5333 6.49517L20 3.49414Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M27.0579 33.9092L26.7919 45.3773C25.5637 52.3727 25.1828 59.4906 25.6577 66.5772C26.1014 70.671 25.9077 74.8089 25.0836 78.8434C24.7895 80.2436 22.9692 88.4071 20.3367 88.4351C18.5304 88.4351 17.1021 84.5844 16.64 83.3522C15.2398 79.5715 15.9119 77.2891 15.4918 73.4104C15.0997 69.8677 14.1896 68.4535 12.4813 63.6926C10.5509 58.2687 9.04807 52.702 7.98644 47.0436L6.58618 32.803" fill="#DEE1E9"/>
        <path d="M1.51726 13.5214C1.1952 15.4397 -0.205053 25.1015 6.05409 32.2428C8.0933 34.7768 10.8824 36.6003 14.0215 37.4518C14.3996 37.5358 21.5689 39.2021 26.1618 34.7913C28.2061 32.8309 28.7942 30.5205 29.8724 25.9977C30.8424 21.9109 31.3126 17.7215 31.2727 13.5214L21.6249 3.1035C21.6249 3.1035 17.2561 -1.62937 12.6073 3.1035C7.95844 7.83636 1.51726 13.5214 1.51726 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M21 3.49414L22.6 5.63773L24 4.3557V7.19631L26.1962 6.70953L25 8.21005L27 9.92492L25 11.6398L26.1962 13.1403L24.2 12.4972L24 15.4941L22.0715 14.211L21.4 15.0695L19.9285 14.211L18 15.4941L17.4 13.7834L15.8038 13.1403L16.2 10.7824L15 9.92492L17 8.63876L15.8038 6.70953L17.4 6.49517L18 4.3557L20.6 6.06645L21 3.49414Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M27.0579 33.9092L26.7919 45.3773C25.5637 52.3727 25.1828 59.4906 25.6577 66.5772C26.1014 70.671 25.9077 74.8089 25.0836 78.8434C24.7895 80.2436 22.9692 88.4071 20.3367 88.4351C18.5304 88.4351 17.1021 84.5844 16.64 83.3522C15.2398 79.5715 15.9119 77.2891 15.4918 73.4104C15.0997 69.8677 14.1896 68.4535 12.4813 63.6926C10.5509 58.2687 9.04807 52.702 7.98644 47.0436L6.58618 32.803" fill="#DEE1E9"/>
        <path d="M1.51726 13.5214C1.1952 15.4397 -0.205053 25.1015 6.05409 32.2428C8.0933 34.7768 10.8824 36.6003 14.0215 37.4518C14.3996 37.5358 21.5689 39.2021 26.1618 34.7913C28.2061 32.8309 28.7942 30.5205 29.8724 25.9977C30.8424 21.9109 31.3126 17.7215 31.2727 13.5214L21.6249 3.1035C21.6249 3.1035 17.2561 -1.62937 12.6073 3.1035C7.95844 7.83636 1.51726 13.5214 1.51726 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M21.5 3.49414L22.4333 4.74457L23.25 3.99672V5.65374L24.5311 5.36978L23.8333 6.24509L25 7.24543L23.8333 8.24577L24.5311 9.12107L23.3667 8.74594L23.25 10.4941L22.125 9.74562L21.7333 10.2465L20.875 9.74562L19.75 10.4941L19.4 9.4962L18.4689 9.12107L18.7 7.7456L18 7.24543L19.1667 6.49517L18.4689 5.36978L19.4 5.24474L19.75 3.99672L21.2667 4.99466L21.5 3.49414Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M12.8574 75.6565L14.9411 76.9263H18.4249L20.5248 75.6565L18.9132 74.11L20.9969 72.5635L19.1086 71.017L21.4039 69.324L19.5807 67.7775L21.876 66.3775L19.8574 64.9612L21.876 63.3496L20.183 61.803L22.4132 60.3868L20.6551 58.9217L22.9504 57.3751L20.8016 55.8286L22.9504 54.217L21.4039 52.8659L23.4876 51.3193L21.6644 49.838L23.7481 48.2914L21.9411 46.5496L22.9504 44.8728V30.6124L21.0621 23.2868H14.9411" fill="#8889BE"/>
        <path d="M12.8574 75.6565L14.9411 76.9263H18.4249L20.5248 75.6565L18.9132 74.11L20.9969 72.5635L19.1086 71.017L21.4039 69.324L19.5807 67.7775L21.876 66.3775L19.8574 64.9612L21.876 63.3496L20.183 61.803L22.4132 60.3868L20.6551 58.9217L22.9504 57.3751L20.8016 55.8286L22.9504 54.217L21.4039 52.8659L23.4876 51.3193L21.6644 49.838L23.7481 48.2914L21.9411 46.5496L22.9504 44.8728V30.6124L21.0621 23.2868H14.9411" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M20.2647 75.6565L18.181 76.9263H14.7135L12.5973 75.6565L14.2252 74.11L12.1252 72.5635L14.0135 71.017L11.7345 69.324L13.5415 67.7775L11.2624 66.3775L13.281 64.9612L11.2624 63.3496L12.9391 61.803L10.7252 60.3868L12.467 58.9217L10.188 57.3751L12.3368 55.8286L10.188 54.217L11.7345 52.8659L9.65077 51.3193L11.4577 49.838L9.37402 48.2914L11.1973 46.5496L10.188 44.8728V30.6124L12.0601 23.2868H15.967" fill="#8889BE"/>
        <path d="M20.2647 75.6565L18.181 76.9263H14.7135L12.5973 75.6565L14.2252 74.11L12.1252 72.5635L14.0135 71.017L11.7345 69.324L13.5415 67.7775L11.2624 66.3775L13.281 64.9612L11.2624 63.3496L12.9391 61.803L10.7252 60.3868L12.467 58.9217L10.188 57.3751L12.3368 55.8286L10.188 54.217L11.7345 52.8659L9.65077 51.3193L11.4577 49.838L9.37402 48.2914L11.1973 46.5496L10.188 44.8728V30.6124L12.0601 23.2868H15.967" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask545" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="35">
          <path d="M5.89662 31.8954C-0.362525 24.7541 1.03773 15.0923 1.35979 13.174C1.35979 13.174 7.80097 7.48895 12.4498 2.75608C17.0987 -1.97678 21.4675 2.75608 21.4675 2.75608L31.1152 13.174C31.1551 17.3741 30.6849 21.5635 29.715 25.6503C28.7788 29.5773 28.2121 31.8365 26.7408 33.6467H22.8425L21.3425 23.6467C17.6322 22.4998 15.5532 22.4719 11.8425 23.6467L10.6949 33.6467H7.54728C6.95495 33.1094 6.40269 32.5243 5.89662 31.8954Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask545)">
          <path d="M5.89662 31.8954C-0.362525 24.7541 1.03773 15.0923 1.35979 13.174C1.35979 13.174 7.80097 7.48895 12.4498 2.75608C17.0987 -1.97678 21.4675 2.75608 21.4675 2.75608L31.1152 13.174C31.1551 17.3741 30.6849 21.5635 29.715 25.6503C28.7788 29.5773 28.2121 31.8365 26.7408 33.6467H22.8425L21.3425 23.6467C17.6322 22.4998 15.5532 22.4719 11.8425 23.6467L10.6949 33.6467H7.54728C6.95495 33.1094 6.40269 32.5243 5.89662 31.8954Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M26 46.6526H6V21.1046C13.8105 19.1784 18.1895 19.1588 26 21.1046V46.6526Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M12.8574 75.948L14.9411 77.2178H18.4249L20.5248 75.948L18.9132 74.4015L20.9969 72.855L19.1086 71.3085L21.4039 69.6155L19.5807 68.069L21.876 66.669L19.8574 65.2527L21.876 63.6411L20.183 62.0945L22.4132 60.6783L20.6551 59.2132L22.9504 57.6667L20.8016 56.1201L22.9504 54.5085L21.4039 53.1574L23.4876 51.6109L21.6644 50.1295L23.7481 48.5829L21.9411 46.8411L22.9504 45.1644V30.9039L21.0621 23.5784H14.9411" fill="#8889BE"/>
        <path d="M12.8574 75.948L14.9411 77.2178H18.4249L20.5248 75.948L18.9132 74.4015L20.9969 72.855L19.1086 71.3085L21.4039 69.6155L19.5807 68.069L21.876 66.669L19.8574 65.2527L21.876 63.6411L20.183 62.0945L22.4132 60.6783L20.6551 59.2132L22.9504 57.6667L20.8016 56.1201L22.9504 54.5085L21.4039 53.1574L23.4876 51.6109L21.6644 50.1295L23.7481 48.5829L21.9411 46.8411L22.9504 45.1644V30.9039L21.0621 23.5784H14.9411" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M20.2647 75.948L18.181 77.2178H14.7135L12.5973 75.948L14.2252 74.4015L12.1252 72.855L14.0135 71.3085L11.7345 69.6155L13.5415 68.069L11.2624 66.669L13.281 65.2527L11.2624 63.6411L12.9391 62.0945L10.7252 60.6783L12.467 59.2132L10.188 57.6667L12.3368 56.1201L10.188 54.5085L11.7345 53.1574L9.65077 51.6109L11.4577 50.1295L9.37402 48.5829L11.1973 46.8411L10.188 45.1644V30.9039L12.0601 23.5784H15.967" fill="#8889BE"/>
        <path d="M20.2647 75.948L18.181 77.2178H14.7135L12.5973 75.948L14.2252 74.4015L12.1252 72.855L14.0135 71.3085L11.7345 69.6155L13.5415 68.069L11.2624 66.669L13.281 65.2527L11.2624 63.6411L12.9391 62.0945L10.7252 60.6783L12.467 59.2132L10.188 57.6667L12.3368 56.1201L10.188 54.5085L11.7345 53.1574L9.65077 51.6109L11.4577 50.1295L9.37402 48.5829L11.1973 46.8411L10.188 45.1644V30.9039L12.0601 23.5783H15.967" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask545" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="35">
          <path d="M5.89662 32.1867C-0.362525 25.0454 1.03773 15.3836 1.35979 13.4652C1.35979 13.4652 7.80097 7.78021 12.4498 3.04734C17.0987 -1.68552 21.4675 3.04734 21.4675 3.04734L31.1152 13.4652C31.1551 17.6653 30.6849 21.8548 29.715 25.9415C28.7788 29.8686 28.2121 32.1277 26.7408 33.938H22.8425L21.3425 23.938C17.6322 22.791 15.5532 22.7632 11.8425 23.938L10.6949 33.938H7.54728C6.95495 33.4006 6.40269 32.8155 5.89662 32.1867Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask545)">
          <path d="M5.89662 32.1867C-0.362525 25.0454 1.03773 15.3836 1.35979 13.4652C1.35979 13.4652 7.80097 7.78021 12.4498 3.04734C17.0987 -1.68552 21.4675 3.04734 21.4675 3.04734L31.1152 13.4652C31.1551 17.6653 30.6849 21.8548 29.715 25.9415C28.7788 29.8686 28.2121 32.1277 26.7408 33.938H22.8425L21.3425 23.938C17.6322 22.791 15.5532 22.7632 11.8425 23.938L10.6949 33.938H7.54728C6.95495 33.4006 6.40269 32.8155 5.89662 32.1867Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </g>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M0 0.259766H21V5.25977H0V0.259766Z" fill="#838AA2"/>
        <path d="M22 0.259766H43V5.25977H22V0.259766Z" fill="#838AA2"/>
        <path d="M31.6344 21.178V48.561C30.4062 55.5565 30.0254 62.6744 30.5002 69.761C30.9439 73.8548 30.7503 77.9927 29.9261 82.0272C29.632 83.4275 27.8117 91.5909 25.1792 91.6189C23.3729 91.6189 21.9446 87.7682 21.4825 86.536C20.0823 82.7553 20.7544 80.4729 20.3343 76.5942C19.9423 73.0516 19.0321 71.6373 17.3238 66.8764C15.3935 61.4525 13.8906 55.8858 12.829 50.2274L11.4287 19.0718" fill="#DEE1E9"/>
        <path d="M10.8966 35.4264C4.63748 28.2851 6.03773 18.6233 6.35979 16.705C6.35979 16.705 12.801 11.02 17.4498 6.28709C22.0987 1.55422 26.4675 6.28709 26.4675 6.28709L36.1152 16.705C36.1551 20.9051 35.6849 25.0945 34.715 29.1813C33.7788 33.1083 33.2121 35.3675 31.7408 37.1777H27.8425L26.3425 27.1777C22.6322 26.0308 20.5532 26.0029 16.8425 27.1777L15.6949 37.1777H12.5473C11.955 36.6404 11.4027 36.0553 10.8966 35.4264Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M26.9005 34.0203L26.6344 45.4884C25.4062 52.4838 25.0254 59.6017 25.5002 66.6882C25.9439 70.7821 25.7503 74.92 24.9261 78.9545C24.632 80.3547 22.8117 88.5182 20.1792 88.5462C18.3729 88.5462 16.9446 84.6955 16.4825 83.4633C15.0823 79.6826 15.7544 77.4002 15.3343 73.5215C14.9423 69.9788 14.0321 68.5646 12.3238 63.8037C10.3935 58.3798 8.8906 52.8131 7.82897 47.1547L6.42871 32.9141" fill="#DEE1E9"/>
        <path d="M1.35979 13.6327C1.03773 15.5511 -0.362524 25.2128 5.89662 32.3541C7.93583 34.8882 10.7249 36.7116 13.8641 37.5631C14.2421 37.6471 21.4115 39.3134 26.0043 34.9026C28.0487 32.9423 28.6368 30.6318 29.715 26.109C30.6849 22.0222 31.1551 17.8328 31.1152 13.6327L21.4675 3.21482C21.4675 3.21482 17.0987 -1.51804 12.4498 3.21482C7.80097 7.94769 1.35979 13.6327 1.35979 13.6327Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M10.4616 31.0658C8.80933 32.4661 11.6098 36.5268 14.1443 45.7685C15.0318 49.038 15.7469 52.3518 16.2867 55.6963C17.2109 61.1153 16.9448 61.9975 18.121 68.9847C18.6111 72.0112 18.9797 74.8527 19.5656 78.8403C20.3637 81.4868 19.8713 86.0471 20.1514 86.0331C20.6835 86.0331 22.1678 77.8204 22.0137 74.5858C21.938 72.926 21.7414 71.274 21.4256 69.6429C20.8039 65.7979 20.4483 61.9146 20.3614 58.0207C20.0394 51.0195 19.8713 47.4908 20.7815 43.8221C21.6917 40.1535 21.6497 36.6528 22.4898 33.1382C22.6439 32.4661 23.0499 30.8418 22.2658 30.2537C21.4816 29.6656 20.3054 30.4077 18.8492 30.8558C14.2983 32.27 11.8479 29.8196 10.4616 31.0658Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M26.9005 34.0203L26.6344 45.4884C25.4062 52.4838 25.0254 59.6017 25.5002 66.6882C25.9439 70.7821 25.7503 74.92 24.9261 78.9545C24.632 80.3547 22.8117 88.5182 20.1792 88.5462C18.3729 88.5462 16.9446 84.6955 16.4825 83.4633C15.0823 79.6826 15.7544 77.4002 15.3343 73.5215C14.9423 69.9788 14.0321 68.5646 12.3238 63.8037C10.3935 58.3798 8.8906 52.8131 7.82897 47.1547L6.42871 32.9141" fill="#DEE1E9"/>
        <path d="M1.35979 13.6327C1.03773 15.5511 -0.362524 25.2128 5.89662 32.3541C7.93583 34.8882 10.7249 36.7116 13.8641 37.5631C14.2421 37.6471 21.4115 39.3134 26.0043 34.9026C28.0487 32.9423 28.6368 30.6318 29.715 26.109C30.6849 22.0222 31.1551 17.8328 31.1152 13.6327L21.4675 3.21482C21.4675 3.21482 17.0987 -1.51804 12.4498 3.21482C7.80097 7.94769 1.35979 13.6327 1.35979 13.6327Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.4" d="M10.4616 31.0658C8.80933 32.4661 11.6098 36.5268 14.1443 45.7685C15.0318 49.038 15.7469 52.3518 16.2867 55.6963C17.2109 61.1153 16.9448 61.9975 18.121 68.9847C18.6111 72.0112 18.9797 74.8527 19.5656 78.8403C20.3637 81.4868 19.8713 86.0471 20.1514 86.0331C20.6835 86.0331 22.1678 77.8204 22.0137 74.5858C21.938 72.926 21.7414 71.274 21.4256 69.6429C20.8039 65.7979 20.4483 61.9146 20.3614 58.0207C20.0394 51.0195 19.8713 47.4908 20.7815 43.8221C21.6917 40.1535 21.6497 36.6528 22.4898 33.1382C22.6439 32.4661 23.0499 30.8418 22.2658 30.2537C21.4816 29.6656 20.3054 30.4077 18.8492 30.8558C14.2983 32.27 11.8479 29.8196 10.4616 31.0658Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M26.9005 34.0203L26.6344 45.4884C25.4062 52.4838 25.0254 59.6017 25.5002 66.6882C25.9439 70.7821 25.7503 74.92 24.9261 78.9545C24.632 80.3547 22.8117 88.5182 20.1792 88.5462C18.3729 88.5462 16.9446 84.6955 16.4825 83.4633C15.0823 79.6826 15.7544 77.4002 15.3343 73.5215C14.9423 69.9788 14.0321 68.5646 12.3238 63.8037C10.3935 58.3798 8.8906 52.8131 7.82897 47.1547L6.42871 32.9141" fill="#DEE1E9"/>
        <path d="M1.35979 13.6327C1.03773 15.5511 -0.362524 25.2128 5.89662 32.3541C7.93583 34.8882 10.7249 36.7116 13.8641 37.5631C14.2421 37.6471 21.4115 39.3134 26.0043 34.9026C28.0487 32.9423 28.6368 30.6318 29.715 26.109C30.6849 22.0222 31.1551 17.8328 31.1152 13.6327L21.4675 3.21482C21.4675 3.21482 17.0987 -1.51804 12.4498 3.21482C7.80097 7.94769 1.35979 13.6327 1.35979 13.6327Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <mask id="mask545" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="26" width="16" height="33">
          <path d="M23.4403 58.707H15.4028L8.03101 27.9743L23.4403 26.9204L23.4403 58.707Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask545)">
          <path opacity="0.2" d="M10.4616 31.0658C8.80933 32.4661 11.6098 36.5268 14.1443 45.7685C15.0318 49.038 15.7469 52.3518 16.2867 55.6963C17.2109 61.1153 16.9448 61.9975 18.121 68.9847C18.6111 72.0112 18.9797 74.8527 19.5656 78.8403C20.3637 81.4868 19.8713 86.0471 20.1514 86.0331C20.6835 86.0331 22.1678 77.8204 22.0137 74.5858C21.938 72.926 21.7414 71.274 21.4256 69.6429C20.8039 65.7979 20.4483 61.9146 20.3614 58.0207C20.0394 51.0195 19.8713 47.4908 20.7815 43.8221C21.6917 40.1535 21.6497 36.6528 22.4898 33.1382C22.6439 32.4661 23.0499 30.8418 22.2658 30.2537C21.4816 29.6656 20.3054 30.4077 18.8492 30.8558C14.2983 32.27 11.8479 29.8196 10.4616 31.0658Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5045" mask-type="alpha" maskUnits="userSpaceOnUse" x="14" y="58" width="10" height="29">
          <path d="M22.0746 84.9401L21.3747 86.9751H19.9749L18.9868 85.6751L14.458 58.7438H23.2274L22.8569 70.6946L22.404 81.2656L22.0746 84.9401Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5045)">
          <path opacity="0.4" d="M10.4616 31.0658C8.80933 32.4661 11.6098 36.5268 14.1443 45.7685C15.0318 49.038 15.7469 52.3518 16.2867 55.6963C17.2109 61.1153 16.9448 61.9975 18.121 68.9847C18.6111 72.0112 18.9797 74.8527 19.5656 78.8403C20.3637 81.4868 19.8713 86.0471 20.1514 86.0331C20.6835 86.0331 22.1678 77.8204 22.0137 74.5858C21.938 72.926 21.7414 71.274 21.4256 69.6429C20.8039 65.7979 20.4483 61.9146 20.3614 58.0207C20.0394 51.0195 19.8713 47.4908 20.7815 43.8221C21.6917 40.1535 21.6497 36.6528 22.4898 33.1382C22.6439 32.4661 23.0499 30.8418 22.2658 30.2537C21.4816 29.6656 20.3054 30.4077 18.8492 30.8558C14.2983 32.27 11.8479 29.8196 10.4616 31.0658Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M30.4395 12.891C30.4854 17.0914 30.0151 21.2816 29.0392 25.3673C28.1431 29.19 27.639 31.4304 26.2387 33.2087L25.9727 44.6768C24.7669 51.6986 24.4143 58.8403 24.9225 65.9467C25.3662 70.0405 25.1726 74.1784 24.3484 78.2129C24.0543 79.6132 22.234 87.7767 19.6015 87.8047C17.7952 87.8047 16.3669 83.954 15.9048 82.7218C14.5046 78.9411 15.1767 76.6587 14.7566 72.78C14.3645 69.2373 13.4544 67.823 11.7461 63.0622C9.79674 57.6398 8.27514 52.0731 7.19524 46.4131L5.79498 32.1725L5.30489 31.6124C-0.926243 24.4711 0.474012 14.8094 0.796071 12.891C0.796071 12.891 7.26525 7.28999 11.8861 2.47311C16.5069 -2.34377 20.9037 2.47311 20.9037 2.47311L30.4395 12.891Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M27.2418 33.5084L26.9757 44.9765C25.7475 51.9719 25.3667 59.0898 25.8415 66.1763C26.2852 70.2702 26.0916 74.4081 25.2674 78.4426C24.9733 79.8428 23.153 88.0063 20.5205 88.0343C18.7142 88.0343 17.2859 84.1836 16.8239 82.9514C15.4236 79.1707 16.0957 76.8883 15.6756 73.0096C15.2836 69.4669 14.3734 68.0527 12.6651 63.2918C10.7348 57.8679 9.23191 52.3012 8.17028 46.6428L6.77002 32.4022" fill="#DEE1E9"/>
      <path d="M1.7011 13.1206C1.37904 15.039 -0.0212154 24.7008 6.23793 31.8421C8.27714 34.3761 11.0662 36.1995 14.2054 37.051C14.5835 37.135 21.7528 38.8013 26.3456 34.3905C28.39 32.4302 28.9781 30.1197 30.0563 25.5969C31.0262 21.5102 31.4964 17.3207 31.4565 13.1206L21.8088 2.70274C21.8088 2.70274 17.44 -2.03013 12.7911 2.70274C8.14228 7.4356 1.7011 13.1206 1.7011 13.1206Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth45",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
