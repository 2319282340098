<template>
  <span>
    {{ payMethod ? payMethod.translatedTitle : '' }}
  </span>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "AccountingDatatablePaymentTypeTD",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      pay_methods: state => state.auth.pay_methods,
    }),
    payMethod() {
      return this.pay_methods.find(x => x.id == this.object.forma)
    }
  },
}
</script>
