<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M11.487 8.02841C11.1796 7.88225 10.8659 7.75634 10.5466 7.65088C11.5805 6.87488 12.2505 5.63922 12.2505 4.25C12.2505 1.90656 10.3439 0 8.00048 0C5.65701 0 3.75048 1.90656 3.75048 4.25C3.75048 5.64075 4.42198 6.87762 5.45776 7.65347C4.50876 7.96591 3.61829 8.45206 2.83891 9.09313C1.40979 10.2687 0.416133 11.9083 0.0410387 13.7101C-0.0765238 14.2747 0.06432 14.8545 0.427382 15.301C0.788664 15.7452 1.32438 16 1.89713 16H9.59423C9.93941 16 10.2192 15.7202 10.2192 15.375C10.2192 15.0298 9.93941 14.75 9.59423 14.75H1.89713C1.63032 14.75 1.46935 14.6011 1.39716 14.5123C1.27251 14.359 1.22426 14.1595 1.26479 13.9648C1.91338 10.8494 4.66138 8.57356 7.83479 8.49681C7.88976 8.49894 7.94498 8.5 8.00048 8.5C8.05651 8.5 8.11229 8.49891 8.16779 8.49675C9.14029 8.51956 10.0756 8.7415 10.9504 9.15738C11.2622 9.3055 11.635 9.17297 11.7832 8.86119C11.9314 8.54944 11.7988 8.17659 11.487 8.02841ZM8.15291 7.24616C8.10219 7.24525 8.05138 7.24478 8.00048 7.24478C7.95004 7.24478 7.8996 7.24528 7.84923 7.24622C6.26501 7.16719 5.00048 5.8535 5.00048 4.25C5.00048 2.59578 6.34626 1.25 8.00048 1.25C9.6547 1.25 11.0005 2.59578 11.0005 4.25C11.0005 5.85309 9.73657 7.16653 8.15291 7.24616Z"
      fill="#B5C6D8"/>
  <path
      d="M15.375 12.4062H13.6562V10.6875C13.6562 10.3423 13.3764 10.0625 13.0312 10.0625C12.6861 10.0625 12.4062 10.3423 12.4062 10.6875V12.4062H10.6875C10.3423 12.4062 10.0625 12.6861 10.0625 13.0312C10.0625 13.3764 10.3423 13.6562 10.6875 13.6562H12.4062V15.375C12.4062 15.7202 12.6861 16 13.0312 16C13.3764 16 13.6562 15.7202 13.6562 15.375V13.6562H15.375C15.7202 13.6562 16 13.3764 16 13.0312C16 12.6861 15.7202 12.4062 15.375 12.4062Z"
      fill="#B5C6D8"/>
</svg>
</template>
