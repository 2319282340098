<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M9.80859 2.40276L11.8923 1.133H15.376L17.476 2.40276L15.8644 3.94927L17.9481 5.49578L16.0597 7.04229L18.3551 8.73531L16.5318 10.2818L18.8272 11.6818L16.8086 13.0981L18.8272 14.7097L17.1342 16.2562L19.3644 17.6725L17.6063 19.1376L19.9016 20.6841L17.7528 22.2306L19.9016 23.8423L18.3551 25.1934L20.4388 26.7399L18.6156 28.2213L20.6993 29.7678L18.8923 31.5097L19.9016 33.1864V47.4469L18.0132 54.7724H11.8923" fill="#8889BE"/>
      <path d="M17.2159 2.40276L15.1322 1.133H11.6647L9.54844 2.40276L11.1763 3.94927L9.07635 5.49578L10.9647 7.04229L8.68566 8.73531L10.4926 10.2818L8.21356 11.6818L10.2322 13.0981L8.21356 14.7097L9.8903 16.2562L7.67636 17.6725L9.41821 19.1376L7.13915 20.6841L9.28798 22.2306L7.13915 23.8423L8.68566 25.1934L6.60194 26.7399L8.40891 28.2213L6.3252 29.7678L8.14845 31.5097L7.13915 33.1864V47.4469L9.01124 54.7724H12.9182" fill="#8889BE"/>
      <path d="M23.8113 79.5677C20.2611 81.3428 13.9004 80.4687 13.9004 80.4687C13.9004 80.4687 2.64478 80.1594 1.01762 75.0896C-0.609544 70.0199 2.64478 61.521 2.64478 61.521C2.64478 61.521 3.61301 56.5588 4.71571 51.2336L5.03845 49.6871L9.46272 45.8276C10.5599 45.0702 11.8333 44.6071 13.1608 44.4829C14.8724 44.3479 16.5825 44.7625 18.0423 45.6663C18.0423 45.6663 23.1524 48.3558 23.4213 50.3191L25.425 68.2852C25.425 68.2852 27.3749 77.7926 23.8113 79.5677Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M16.0221 0.985008L12.4719 10.8959C12.4719 10.8959 9.49998 18.3055 9.94375 22.2995C10.3875 26.2934 8.90828 37.697 7.72489 40.6555C7.1332 42.1347 5.88257 47.8365 4.76642 53.1617C3.65026 58.487 2.69548 63.4492 2.69548 63.4492C2.69548 63.4492 -0.61263 72.0422 1.01453 77.0716C2.64169 82.101 13.8973 82.4506 13.8973 82.4506C13.8973 82.4506 20.2581 83.3382 23.8082 81.5631C27.3584 79.788 25.4354 70.2671 25.4354 70.2671L23.3645 51.6287L20.8498 35.0344L19.0747 20.9682L18.6309 8.97287L17.5954 1.72461C17.5954 1.72461 16.3314 -0.0370101 16.0221 0.985008Z" fill="#DEE1E9"/>-->
<!--      <path d="M23.8116 81.5563C20.2614 83.3314 13.9007 82.4573 13.9007 82.4573C13.9007 82.4573 2.64502 82.148 1.01786 77.0783C-0.6093 72.0085 2.64502 63.5096 2.64502 63.5096C2.64502 63.5096 3.61325 58.5475 4.71595 53.2222L5.0387 51.6757L9.46296 47.8163C10.5602 47.0588 11.8336 46.5957 13.1611 46.4715C14.8727 46.3365 16.5827 46.7511 18.0425 47.6549C18.0425 47.6549 23.1526 50.3444 23.4216 52.3078L25.4253 70.2738C25.4253 70.2738 27.3752 79.7812 23.8116 81.5563Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--      <path opacity="0.2" d="M15.4095 52.6507C16.6601 50.9294 15.8398 48.3744 15.8667 39.4855C15.8667 31.6187 16.5525 31.1884 16.0012 27.0061C15.5978 23.9266 15.3342 22.1506 14.7156 17.296C14.218 13.3827 16.8744 2.84059 16.713 2.86748C16.5517 2.89438 13.5672 15.9119 13.2579 22.1785C12.962 28.1761 12.7603 27.4634 12.451 33.4341C11.9938 42.1078 12.451 43.1164 11.55 46.5858C10.8104 49.3964 10.017 50.6201 10.407 51.9649C10.5838 52.454 10.8909 52.8854 11.2953 53.2124C11.6996 53.5394 12.1857 53.7496 12.7009 53.8203C13.2161 53.8909 13.7408 53.8193 14.2183 53.6131C14.6957 53.407 15.1076 53.0742 15.4095 52.6507Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->
        <path d="M16.2882 0.343041L12.738 10.2539C12.738 10.2539 9.76609 17.6636 10.2099 21.6575C10.6536 25.6514 9.17439 37.055 7.991 40.0135C7.39931 41.4927 6.14868 47.1945 5.03253 52.5198C3.91638 57.845 2.9616 62.8072 2.9616 62.8072C2.9616 62.8072 -0.346516 71.4002 1.28064 76.4296C2.90781 81.459 14.1635 81.8087 14.1635 81.8087C14.1635 81.8087 20.5242 82.6962 24.0743 80.9211C27.6245 79.146 25.7015 69.6251 25.7015 69.6251L23.6306 50.9867L21.1159 34.3924L19.3408 20.3262L18.897 8.3309L17.8616 1.08264C17.8616 1.08264 16.5975 -0.678978 16.2882 0.343041Z" fill="#DEE1E9"/>
        <mask id="mask412" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="28" height="38">
          <path d="M24.5154 81.3107C20.9653 83.0858 14.6045 82.2117 14.6045 82.2117C14.6045 82.2117 3.34888 81.9024 1.72172 76.8327C0.0945573 71.7629 3.34888 63.264 3.34888 63.264C3.34888 63.264 4.31711 58.3019 5.41981 52.9766L5.74255 51.4301L10.1668 47.5707C11.264 46.8132 12.5374 46.3501 13.8649 46.2259C15.5765 46.0909 17.2866 46.5055 18.7464 47.4093C18.7464 47.4093 23.8565 50.0988 24.1254 52.0622L26.1291 70.0282C26.1291 70.0282 28.079 79.5356 24.5154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask412)">
          <path d="M24.5154 81.3107C20.9653 83.0858 14.6045 82.2117 14.6045 82.2117C14.6045 82.2117 3.34888 81.9024 1.72172 76.8327C0.0945573 71.7629 3.34888 63.264 3.34888 63.264C3.34888 63.264 4.31711 58.3019 5.41981 52.9766L5.74255 51.4301L10.1668 47.5707C11.264 46.8132 12.5374 46.3501 13.8649 46.2259C15.5765 46.0909 17.2866 46.5055 18.7464 47.4093C18.7464 47.4093 23.8565 50.0988 24.1254 52.0622L26.1291 70.0282C26.1291 70.0282 28.079 79.5356 24.5154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.7809 66.2009C12.8005 66.2009 11.2661 74.6026 11.2661 84.2009H13.1892H16.2661C16.2661 74.6026 14.7613 66.2009 13.7809 66.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.6319 65.6759C18.1913 63.4812 17.1685 60.2234 17.202 48.8898C17.202 38.8594 18.0572 38.3107 17.3697 32.9783C16.8667 29.0518 16.538 26.7873 15.7667 20.5976C15.1463 15.6081 17.4673 2.16645 17.2661 2.20074C17.0649 2.23504 14.3347 18.8328 13.9491 26.8229C13.5802 34.47 13.3286 33.5613 12.943 41.1741C12.3729 52.2333 12.943 53.5193 11.8195 57.943C10.8973 61.5265 9.90802 63.0868 10.3943 64.8014C10.6147 65.425 10.9977 65.975 11.5019 66.392C12.006 66.809 12.6121 67.077 13.2545 67.1671C13.897 67.2571 14.5512 67.1658 15.1465 66.903C15.7418 66.6401 16.2555 66.2158 16.6319 65.6759Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M16.0221 4.14217L12.4719 14.0531C12.4719 14.0531 9.49998 21.4627 9.94375 25.4566C10.3875 29.4506 8.90828 40.8542 7.72489 43.8126C7.1332 45.2919 5.88257 50.9936 4.76642 56.3189C3.65026 61.6441 2.69548 66.6063 2.69548 66.6063C2.69548 66.6063 -0.61263 75.1994 1.01453 80.2288C2.64169 85.2582 13.8973 85.6078 13.8973 85.6078C13.8973 85.6078 20.2581 86.4954 23.8082 84.7203C27.3584 82.9452 25.4354 73.4243 25.4354 73.4243L23.3645 54.7859L20.8498 38.1915L19.0747 24.1253L18.6309 12.13L17.5954 4.88178C17.5954 4.88178 16.3314 3.12016 16.0221 4.14217Z" fill="#DEE1E9"/>
      <path d="M23.8116 84.7135C20.2614 86.4886 13.9007 85.6145 13.9007 85.6145C13.9007 85.6145 2.64502 85.3052 1.01786 80.2354C-0.6093 75.1657 2.64502 66.6668 2.64502 66.6668C2.64502 66.6668 3.61325 61.7046 4.71595 56.3794L5.0387 54.8329L9.46296 50.9734C10.5602 50.2159 11.8336 49.7529 13.1611 49.6287C14.8727 49.4937 16.5827 49.9083 18.0425 50.8121C18.0425 50.8121 23.1526 53.5016 23.4216 55.4649L25.4253 73.4309C25.4253 73.4309 27.3752 82.9384 23.8116 84.7135Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M15.4095 55.8079C16.6601 54.0866 15.8398 51.5316 15.8667 42.6427C15.8667 34.7758 16.5525 34.3455 16.0012 30.1633C15.5978 27.0838 15.3342 25.3077 14.7156 20.4532C14.218 16.5399 16.8744 5.99775 16.713 6.02465C16.5517 6.05154 13.5672 19.069 13.2579 25.3356C12.962 31.3333 12.7603 30.6205 12.451 36.5913C11.9938 45.265 12.451 46.2735 11.55 49.743C10.8104 52.5536 10.017 53.7773 10.407 55.1221C10.5838 55.6111 10.8909 56.0425 11.2953 56.3696C11.6996 56.6966 12.1857 56.9068 12.7009 56.9774C13.2161 57.0481 13.7408 56.9764 14.2183 56.7703C14.6957 56.5642 15.1076 56.2313 15.4095 55.8079Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="16" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M16.0218 0.347862L12.4717 10.2587C12.4717 10.2587 9.49973 17.6684 9.9435 21.6623C10.3873 25.6563 8.90804 37.0598 7.72465 40.0183C7.13295 41.4976 5.88232 47.1993 4.76617 52.5246C3.65002 57.8498 2.69524 62.812 2.69524 62.812C2.69524 62.812 -0.612874 71.4051 1.01429 76.4345C2.64145 81.4639 13.8971 81.8135 13.8971 81.8135C13.8971 81.8135 20.2578 82.701 23.808 80.926C27.3582 79.1509 25.4352 69.63 25.4352 69.63L23.3642 50.9916L20.8495 34.3972L19.0744 20.331L18.6307 8.33573L17.5952 1.08746C17.5952 1.08746 16.3311 -0.674156 16.0218 0.347862Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M16.0218 0.347862L12.4717 10.2587C12.4717 10.2587 9.49973 17.6684 9.9435 21.6623C10.3873 25.6563 8.90804 37.0598 7.72465 40.0183C7.13295 41.4976 5.88232 47.1993 4.76617 52.5246C3.65002 57.8498 2.69524 62.812 2.69524 62.812C2.69524 62.812 -0.612874 71.4051 1.01429 76.4345C2.64145 81.4639 13.8971 81.8135 13.8971 81.8135C13.8971 81.8135 20.2578 82.701 23.808 80.926C27.3582 79.1509 25.4352 69.63 25.4352 69.63L23.3642 50.9916L20.8495 34.3972L19.0744 20.331L18.6307 8.33573L17.5952 1.08746C17.5952 1.08746 16.3311 -0.674156 16.0218 0.347862Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
    <path d="M10.174 10.5124L13.7242 0.601525C14.0335 -0.420494 15.2976 1.34113 15.2976 1.34113L16.333 8.58939L16.7768 20.5847L18.5519 34.6509L21.0666 51.2452L21.0667 58.7152L15.5112 57.3353L10.7156 58.7152L4.74361 57.3353L0.397705 58.7152C0.397705 58.7152 1.3524 58.1035 2.46856 52.7782C3.58471 47.453 4.83534 41.7512 5.42703 40.272C6.61042 37.3135 8.08966 25.9099 7.64589 21.916C7.20212 17.922 10.174 10.5124 10.174 10.5124Z" fill="#DEE1E9"/>
    <path d="M4.18973 52.0832C11.2547 50.5669 14.8161 51.482 20.6009 55.6586" stroke="#404040" stroke-width="1.4" fill="none"/>
    <path opacity="0.2" d="M13.7033 29.1174C14.2547 33.7319 13.5689 34.2067 13.5689 42.8866L13.3198 59.3993L10.8153 58.6967L7.22675 59.0665L8.10914 56.6557C7.71916 55.1719 8.51257 53.8217 9.25219 50.7207C10.1532 46.8927 9.69596 45.7799 10.1532 36.2097C10.4625 29.6219 10.6642 30.4083 10.96 23.7908C11.2693 16.8765 14.2538 2.51368 14.4152 2.484C14.5766 2.45433 11.9202 14.086 12.4178 18.4037C13.0363 23.76 13.2999 25.7197 13.7033 29.1174Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
  </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M12.1741 11.1003L15.7243 1.18938C16.0336 0.167366 17.2977 1.92899 17.2977 1.92899L18.3331 9.17725L18.7769 21.1725L20.552 35.2387L23.0667 51.8331L23.6912 58.3986L2.39771 63.6535C2.39771 63.6535 3.35249 58.6914 4.46864 53.3661C5.58479 48.0408 6.83542 42.3391 7.42711 40.8598C8.6105 37.9014 10.0897 26.4978 9.64597 22.5038C9.2022 18.5099 12.1741 11.1003 12.1741 11.1003Z" fill="#DEE1E9"/>
      <path d="M13.6029 82.6617C13.6029 82.6617 19.9636 83.5358 23.5138 81.7607C27.0774 79.9856 25.1275 70.4782 25.1275 70.4782L23.1238 52.5121C22.8549 50.5488 17.7448 47.8593 17.7448 47.8593C16.285 46.9555 14.5749 46.5409 12.8633 46.6759C11.5358 46.8001 10.2624 47.2632 9.16519 48.0206L4.74093 51.8801L4.41818 53.4266C3.31548 58.7518 2.34725 63.714 2.34725 63.714C2.34725 63.714 1.56299 65.7621 0.95697 68.4473H2.91028L8.39024 68.0683L7.48314 70.7136L8.69072 73.1804L7.48314 75.4939L11.2922 77.0086L9.9535 80.0436L8.69072 82.0727C11.3365 82.5994 13.6029 82.6617 13.6029 82.6617Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M4.96601 62.3507C14.5908 65.7759 16.693 69.8861 15.2485 80.6611" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M15.1117 52.8551C16.3623 51.1338 15.542 48.5788 15.5689 39.6899C15.5689 31.823 16.2548 31.3927 15.7034 27.2105C15.3 24.131 15.0364 22.3549 14.4178 17.5004C13.9203 13.5871 16.5766 3.04496 16.4153 3.07186C16.2539 3.09875 13.2694 16.1162 12.9601 22.3828C12.6642 28.3805 12.4625 27.6677 12.1532 33.6385C11.696 42.3122 12.1532 43.3207 11.2522 46.7902C10.5126 49.6008 9.71922 50.8245 10.1092 52.1693C10.286 52.6583 10.5932 53.0897 10.9975 53.4168C11.4018 53.7438 11.8879 53.954 12.4031 54.0247C12.9183 54.0953 13.443 54.0237 13.9205 53.8175C14.3979 53.6114 14.8098 53.2786 15.1117 52.8551Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M16.2879 1.07636L12.7378 10.9872C12.7378 10.9872 9.76584 18.3969 10.2096 22.3908C10.6534 26.3848 9.17415 37.7883 7.99076 40.7468C7.39906 42.2261 6.14844 47.9278 5.03228 53.2531C3.91613 58.5783 2.96135 63.5405 2.96135 63.5405C2.96135 63.5405 -0.346761 72.1336 1.2804 77.163C2.90756 82.1924 14.1632 82.542 14.1632 82.542C14.1632 82.542 20.5239 83.4295 24.0741 81.6545C27.6243 79.8794 25.7013 70.3585 25.7013 70.3585L23.6303 51.7201L21.1156 35.1257L19.3405 21.0595L18.8968 9.06423L17.8613 1.81597C17.8613 1.81597 16.5972 0.0543443 16.2879 1.07636Z" fill="#DEE1E9"/>
      <path d="M14.1665 82.5487C14.1665 82.5487 20.5273 83.4228 24.0774 81.6477C27.6411 79.8726 25.6911 70.3651 25.6911 70.3651L23.424 51.7671H19.6541C19.1018 51.7671 18.6541 52.2148 18.6541 52.7671V61.6049C14.4585 62.7986 13.6123 62.6835 9.41659 61.6049V52.7671C9.41659 52.2148 8.96887 51.7671 8.41659 51.7671H5.30457L4.98182 53.3136C3.87912 58.6388 2.91089 63.601 2.91089 63.601C2.91089 63.601 -0.343431 72.0999 1.28373 77.1696C2.91089 82.2394 14.1665 82.5487 14.1665 82.5487Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M16.2879 0.327149L12.7378 10.238C12.7378 10.238 9.76584 17.6477 10.2096 21.6416C10.6534 25.6355 9.17415 37.0391 7.99076 39.9976C7.39906 41.4768 6.14844 47.1786 5.03228 52.5039C3.91613 57.8291 2.96135 62.7913 2.96135 62.7913C2.96135 62.7913 -0.346761 71.3843 1.2804 76.4137C2.90756 81.4432 14.1632 81.7928 14.1632 81.7928C14.1632 81.7928 20.5239 82.6803 24.0741 80.9052C27.6243 79.1302 25.7013 69.6092 25.7013 69.6092L23.6303 50.9708L21.1156 34.3765L19.3405 20.3103L18.8968 8.31501L17.8613 1.06675C17.8613 1.06675 16.5972 -0.69487 16.2879 0.327149Z" fill="#DEE1E9"/>
      <path d="M14.1665 81.7994C14.1665 81.7994 20.5273 82.6735 24.0774 80.8985C27.6411 79.1234 25.6911 69.6159 25.6911 69.6159L23.424 51.0179H19.6541C19.1018 51.0179 18.6541 51.4656 18.6541 52.0179V60.8557C14.4585 62.0494 13.6123 61.9343 9.41659 60.8557V52.0179C9.41659 51.4656 8.96887 51.0179 8.41659 51.0179H5.30457L4.98182 52.5643C3.87912 57.8896 2.91089 62.8518 2.91089 62.8518C2.91089 62.8518 -0.343431 71.3507 1.28373 76.4204C2.91089 81.4902 14.1665 81.7994 14.1665 81.7994Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M16.4598 0.318871L12.9096 10.2298C12.9096 10.2298 9.93772 17.6394 10.3815 21.6333C10.8253 25.6273 9.34602 37.0309 8.16263 39.9893C7.57094 41.4686 6.32031 47.1703 5.20416 52.4956C4.08801 57.8208 3.13323 62.783 3.13323 62.783C3.13323 62.783 -0.174886 71.3761 1.45228 76.4055C3.07944 81.4349 14.3351 81.7845 14.3351 81.7845C14.3351 81.7845 20.6958 82.6721 24.246 80.897C27.7962 79.1219 25.8731 69.601 25.8731 69.601L23.8022 50.9626L21.2875 34.3682L19.5124 20.302L19.0686 8.30674L18.0332 1.05847C18.0332 1.05847 16.7691 -0.703148 16.4598 0.318871Z" fill="#DEE1E9"/>
      <path d="M14.3384 81.7912C14.3384 81.7912 20.6991 82.6653 24.2493 80.8902C27.8129 79.1151 25.863 69.6076 25.863 69.6076L23.5958 51.0096H19.8097V60.8475C15.6141 62.0411 13.2611 61.926 9.06542 60.8475V51.0096H5.47644L5.1537 52.5561C4.05099 57.8813 3.08277 62.8435 3.08277 62.8435C3.08277 62.8435 -0.171556 71.3424 1.45561 76.4121C3.08277 81.4819 14.3384 81.7912 14.3384 81.7912Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask2012" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="27" height="32">
        <path d="M14.3406 81.8584C14.3406 81.8584 20.7013 82.7325 24.2515 80.9574C27.8151 79.1824 25.8652 69.6749 25.8652 69.6749L23.598 51.7874H20.8119C20.2597 51.7874 19.8119 52.2351 19.8119 52.7874V60.9147C15.6163 62.1083 13.2633 61.9933 9.06761 60.9147V52.7874C9.06761 52.2351 8.6199 51.7874 8.06761 51.7874H5.47864L5.1559 52.6233C4.05319 57.9486 3.08496 62.9108 3.08496 62.9108C3.08496 62.9108 -0.169359 71.4096 1.4578 76.4794C3.08496 81.5491 14.3406 81.8584 14.3406 81.8584Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2012)">
        <path d="M6.10815 39.136H23.0027V64.684C16.405 66.6103 12.7059 66.6298 6.10815 64.684V39.136Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M16.4948 1.01272L12.9447 10.9236C12.9447 10.9236 9.97275 18.3332 10.4165 22.3272C10.8603 26.3211 9.38106 37.7247 8.19767 40.6832C7.60597 42.1624 6.35535 47.8642 5.23919 53.1894C4.12304 58.5147 3.16826 63.4769 3.16826 63.4769C3.16826 63.4769 -0.139851 72.0699 1.48731 77.0993C3.11447 82.1287 14.3701 82.4784 14.3701 82.4784C14.3701 82.4784 20.7308 83.3659 24.281 81.5908C27.8312 79.8157 25.9082 70.2948 25.9082 70.2948L23.8372 51.6564L21.3225 35.0621L19.5475 20.9959L19.1037 9.00058L18.0682 1.75232C18.0682 1.75232 16.8041 -0.00930011 16.4948 1.01272Z" fill="#DEE1E9"/>
      <path d="M24.2843 81.5839C20.7342 83.359 14.3735 82.4849 14.3735 82.4849C14.3735 82.4849 3.1178 82.1756 1.49064 77.1059C-0.136522 72.0361 3.1178 63.5372 3.1178 63.5372C3.1178 63.5372 4.08603 58.575 5.18873 53.2498L5.51148 51.7033L9.93574 47.8439C11.033 47.0864 12.3063 46.6233 13.6338 46.4991C15.3455 46.3641 17.0555 46.7787 18.5153 47.6825C18.5153 47.6825 23.6254 50.372 23.8944 52.3354L25.8981 70.3014C25.8981 70.3014 27.848 79.8088 24.2843 81.5839Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M15.8823 52.6783C17.1329 50.957 16.3126 48.402 16.3395 39.5131C16.3395 31.6463 17.0253 31.2159 16.474 27.0337C16.0705 23.9542 15.807 22.1782 15.1884 17.3236C14.6908 13.4103 17.3472 2.86818 17.1858 2.89507C17.0244 2.92197 14.04 15.9395 13.7307 22.206C13.4348 28.2037 13.2331 27.4909 12.9238 33.4617C12.4666 42.1354 12.9238 43.144 12.0228 46.6134C11.2832 49.424 10.4898 50.6477 10.8798 51.9925C11.0565 52.4815 11.3637 52.9129 11.768 53.24C12.1724 53.567 12.6585 53.7772 13.1737 53.8479C13.6889 53.9185 14.2136 53.8469 14.691 53.6407C15.1685 53.4346 15.5804 53.1018 15.8823 52.6783Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M10.8364 18.7781C14.1348 17.1064 15.9766 17.1826 19.2555 18.7781" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">

      <path d="M16.2882 3.3431L12.738 13.254C12.738 13.254 9.76609 20.6636 10.2099 24.6576C10.6536 28.6515 9.17439 40.0551 7.991 43.0136C7.39931 44.4928 6.14868 50.1946 5.03253 55.5198C3.91638 60.8451 2.9616 65.8073 2.9616 65.8073C2.9616 65.8073 -0.346516 74.4003 1.28064 79.4297C2.90781 84.4591 14.1635 84.8087 14.1635 84.8087C14.1635 84.8087 20.5242 85.6963 24.0743 83.9212C27.6245 82.1461 25.7015 72.6252 25.7015 72.6252L23.6306 53.9868L21.1159 37.3924L19.3408 23.3263L18.897 11.331L17.8616 4.0827C17.8616 4.0827 16.5975 2.32108 16.2882 3.3431Z" fill="#DEE1E9"/>
      <mask id="mask2212" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="28" height="38">
        <path d="M24.5154 84.3107C20.9653 86.0858 14.6045 85.2117 14.6045 85.2117C14.6045 85.2117 3.34888 84.9024 1.72172 79.8327C0.0945573 74.7629 3.34888 66.264 3.34888 66.264C3.34888 66.264 4.31711 61.3019 5.41981 55.9766L5.74255 54.4301L10.1668 50.5707C11.264 49.8132 12.5374 49.3501 13.8649 49.2259C15.5765 49.0909 17.2866 49.5055 18.7464 50.4093C18.7464 50.4093 23.8565 53.0988 24.1254 55.0622L26.1291 73.0282C26.1291 73.0282 28.079 82.5356 24.5154 84.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2212)">
        <path d="M24.5154 84.3107C20.9653 86.0858 14.6045 85.2117 14.6045 85.2117C14.6045 85.2117 3.34888 84.9024 1.72172 79.8327C0.0945573 74.7629 3.34888 66.264 3.34888 66.264C3.34888 66.264 4.31711 61.3019 5.41981 55.9766L5.74255 54.4301L10.1668 50.5707C11.264 49.8132 12.5374 49.3501 13.8649 49.2259C15.5765 49.0909 17.2866 49.5055 18.7464 50.4093C18.7464 50.4093 23.8565 53.0988 24.1254 55.0622L26.1291 73.0282C26.1291 73.0282 28.079 82.5356 24.5154 84.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7809 69.2009C12.8005 69.2009 11.2661 77.6026 11.2661 87.2009H13.1892H16.2661C16.2661 77.6026 14.7613 69.2009 13.7809 69.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M16.6319 68.676C18.1913 66.4813 17.1685 63.2236 17.202 51.89C17.202 41.8596 18.0572 41.3109 17.3697 35.9785C16.8667 32.052 16.538 29.7875 15.7667 23.5977C15.1463 18.6082 17.4673 5.16664 17.2661 5.20093C17.0649 5.23522 14.3347 21.833 13.9491 29.823C13.5802 37.4702 13.3286 36.5614 12.943 44.1743C12.3729 55.2335 12.943 56.5195 11.8195 60.9431C10.8973 64.5267 9.90802 66.087 10.3943 67.8016C10.6147 68.4252 10.9977 68.9752 11.5019 69.3922C12.006 69.8092 12.6121 70.0772 13.2545 70.1673C13.897 70.2573 14.5512 70.166 15.1465 69.9031C15.7418 69.6403 16.2555 69.216 16.6319 68.676Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="17" cy="4.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>


    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M16.2882 8.3431L12.738 18.254C12.738 18.254 9.76609 25.6636 10.2099 29.6576C10.6536 33.6515 9.17439 45.0551 7.991 48.0136C7.39931 49.4928 6.14868 55.1946 5.03253 60.5198C3.91638 65.8451 2.9616 70.8073 2.9616 70.8073C2.9616 70.8073 -0.346516 79.4003 1.28064 84.4297C2.90781 89.4591 14.1635 89.8087 14.1635 89.8087C14.1635 89.8087 20.5242 90.6963 24.0743 88.9212C27.6245 87.1461 25.7015 77.6252 25.7015 77.6252L23.6306 58.9868L21.1159 42.3924L19.3408 28.3263L18.897 16.331L17.8616 9.0827C17.8616 9.0827 16.5975 7.32108 16.2882 8.3431Z" fill="#DEE1E9"/>
      <mask id="mask2312" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="28" height="38">
        <path d="M24.5154 89.3107C20.9653 91.0858 14.6045 90.2117 14.6045 90.2117C14.6045 90.2117 3.34888 89.9024 1.72172 84.8327C0.0945573 79.7629 3.34888 71.264 3.34888 71.264C3.34888 71.264 4.31711 66.3019 5.41981 60.9766L5.74255 59.4301L10.1668 55.5707C11.264 54.8132 12.5374 54.3501 13.8649 54.2259C15.5765 54.0909 17.2866 54.5055 18.7464 55.4093C18.7464 55.4093 23.8565 58.0988 24.1254 60.0622L26.1291 78.0282C26.1291 78.0282 28.079 87.5356 24.5154 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2312)">
        <path d="M24.5154 89.3107C20.9653 91.0858 14.6045 90.2117 14.6045 90.2117C14.6045 90.2117 3.34888 89.9024 1.72172 84.8327C0.0945573 79.7629 3.34888 71.264 3.34888 71.264C3.34888 71.264 4.31711 66.3019 5.41981 60.9766L5.74255 59.4301L10.1668 55.5707C11.264 54.8132 12.5374 54.3501 13.8649 54.2259C15.5765 54.0909 17.2866 54.5055 18.7464 55.4093C18.7464 55.4093 23.8565 58.0988 24.1254 60.0622L26.1291 78.0282C26.1291 78.0282 28.079 87.5356 24.5154 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7809 74.2009C12.8005 74.2009 11.2661 82.6026 11.2661 92.2009H13.1892H16.2661C16.2661 82.6026 14.7613 74.2009 13.7809 74.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M16.6319 73.676C18.1913 71.4813 17.1685 68.2236 17.202 56.89C17.202 46.8596 18.0572 46.3109 17.3697 40.9785C16.8667 37.052 16.538 34.7875 15.7667 28.5977C15.1463 23.6082 17.4673 10.1666 17.2661 10.2009C17.0649 10.2352 14.3347 26.833 13.9491 34.823C13.5802 42.4702 13.3286 41.5614 12.943 49.1743C12.3729 60.2335 12.943 61.5195 11.8195 65.9431C10.8973 69.5267 9.90802 71.087 10.3943 72.8016C10.6147 73.4252 10.9977 73.9752 11.5019 74.3922C12.006 74.8092 12.6121 75.0772 13.2545 75.1673C13.897 75.2573 14.5512 75.166 15.1465 74.9031C15.7418 74.6403 16.2555 74.216 16.6319 73.676Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="17" cy="9.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2312)">
        <path d="M18 0.200989L18.8229 3.94009L21.8567 1.60472L20.0837 4.99799L23.9088 5.1591L20.3694 6.61879L23.1962 9.20099L19.5465 8.04409L20.0521 11.8391L18 8.60699L15.9479 11.8391L16.4535 8.04409L12.8038 9.20099L15.6306 6.61879L12.0912 5.1591L15.9163 4.99799L14.1433 1.60472L17.1771 3.94009L18 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2312" x="11.8911" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M16.2882 8.3431L12.738 18.254C12.738 18.254 9.76609 25.6636 10.2099 29.6576C10.6536 33.6515 9.17439 45.0551 7.991 48.0136C7.39931 49.4928 6.14868 55.1946 5.03253 60.5198C3.91638 65.8451 2.9616 70.8073 2.9616 70.8073C2.9616 70.8073 -0.346516 79.4003 1.28064 84.4297C2.90781 89.4591 14.1635 89.8087 14.1635 89.8087C14.1635 89.8087 20.5242 90.6963 24.0743 88.9212C27.6245 87.1461 25.7015 77.6252 25.7015 77.6252L23.6306 58.9868L21.1159 42.3924L19.3408 28.3263L18.897 16.331L17.8616 9.0827C17.8616 9.0827 16.5975 7.32108 16.2882 8.3431Z" fill="#DEE1E9"/>
      <mask id="mask2312" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="28" height="38">
        <path d="M24.5154 89.3107C20.9653 91.0858 14.6045 90.2117 14.6045 90.2117C14.6045 90.2117 3.34888 89.9024 1.72172 84.8327C0.0945573 79.7629 3.34888 71.264 3.34888 71.264C3.34888 71.264 4.31711 66.3019 5.41981 60.9766L5.74255 59.4301L10.1668 55.5707C11.264 54.8132 12.5374 54.3501 13.8649 54.2259C15.5765 54.0909 17.2866 54.5055 18.7464 55.4093C18.7464 55.4093 23.8565 58.0988 24.1254 60.0622L26.1291 78.0282C26.1291 78.0282 28.079 87.5356 24.5154 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2312)">
        <path d="M24.5154 89.3107C20.9653 91.0858 14.6045 90.2117 14.6045 90.2117C14.6045 90.2117 3.34888 89.9024 1.72172 84.8327C0.0945573 79.7629 3.34888 71.264 3.34888 71.264C3.34888 71.264 4.31711 66.3019 5.41981 60.9766L5.74255 59.4301L10.1668 55.5707C11.264 54.8132 12.5374 54.3501 13.8649 54.2259C15.5765 54.0909 17.2866 54.5055 18.7464 55.4093C18.7464 55.4093 23.8565 58.0988 24.1254 60.0622L26.1291 78.0282C26.1291 78.0282 28.079 87.5356 24.5154 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7809 74.2009C12.8005 74.2009 11.2661 82.6026 11.2661 92.2009H13.1892H16.2661C16.2661 82.6026 14.7613 74.2009 13.7809 74.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M16.6319 73.676C18.1913 71.4813 17.1685 68.2236 17.202 56.89C17.202 46.8596 18.0572 46.3109 17.3697 40.9785C16.8667 37.052 16.538 34.7875 15.7667 28.5977C15.1463 23.6082 17.4673 10.1666 17.2661 10.2009C17.0649 10.2352 14.3347 26.833 13.9491 34.823C13.5802 42.4702 13.3286 41.5614 12.943 49.1743C12.3729 60.2335 12.943 61.5195 11.8195 65.9431C10.8973 69.5267 9.90802 71.087 10.3943 72.8016C10.6147 73.4252 10.9977 73.9752 11.5019 74.3922C12.006 74.8092 12.6121 75.0772 13.2545 75.1673C13.897 75.2573 14.5512 75.166 15.1465 74.9031C15.7418 74.6403 16.2555 74.216 16.6319 73.676Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="17" cy="9.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2312)">
        <path d="M18 0.200989L18.8229 3.94009L21.8567 1.60472L20.0837 4.99799L23.9088 5.1591L20.3694 6.61879L23.1962 9.20099L19.5465 8.04409L20.0521 11.8391L18 8.60699L15.9479 11.8391L16.4535 8.04409L12.8038 9.20099L15.6306 6.61879L12.0912 5.1591L15.9163 4.99799L14.1433 1.60472L17.1771 3.94009L18 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2312" x="11.8911" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M16.2882 5.34304L12.738 15.2539C12.738 15.2539 9.76609 22.6636 10.2099 26.6575C10.6536 30.6514 9.17439 42.055 7.991 45.0135C7.39931 46.4927 6.14868 52.1945 5.03253 57.5198C3.91638 62.845 2.9616 67.8072 2.9616 67.8072C2.9616 67.8072 -0.346516 76.4002 1.28064 81.4296C2.90781 86.459 14.1635 86.8087 14.1635 86.8087C14.1635 86.8087 20.5242 87.6962 24.0743 85.9211C27.6245 84.146 25.7015 74.6251 25.7015 74.6251L23.6306 55.9867L21.1159 39.3924L19.3408 25.3262L18.897 13.3309L17.8616 6.08264C17.8616 6.08264 16.5975 4.32102 16.2882 5.34304Z" fill="#DEE1E9"/>
      <circle cx="17" cy="4.20093" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <mask id="mask2512" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="28" height="38">
        <path d="M24.5154 86.3107C20.9653 88.0858 14.6045 87.2117 14.6045 87.2117C14.6045 87.2117 3.34888 86.9024 1.72172 81.8327C0.0945573 76.7629 3.34888 68.264 3.34888 68.264C3.34888 68.264 4.31711 63.3019 5.41981 57.9766L5.74255 56.4301L10.1668 52.5707C11.264 51.8132 12.5374 51.3501 13.8649 51.2259C15.5765 51.0909 17.2866 51.5055 18.7464 52.4093C18.7464 52.4093 23.8565 55.0988 24.1254 57.0622L26.1291 75.0282C26.1291 75.0282 28.079 84.5356 24.5154 86.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2512)">
        <path d="M24.5154 86.3107C20.9653 88.0858 14.6045 87.2117 14.6045 87.2117C14.6045 87.2117 3.34888 86.9024 1.72172 81.8327C0.0945573 76.7629 3.34888 68.264 3.34888 68.264C3.34888 68.264 4.31711 63.3019 5.41981 57.9766L5.74255 56.4301L10.1668 52.5707C11.264 51.8132 12.5374 51.3501 13.8649 51.2259C15.5765 51.0909 17.2866 51.5055 18.7464 52.4093C18.7464 52.4093 23.8565 55.0988 24.1254 57.0622L26.1291 75.0282C26.1291 75.0282 28.079 84.5356 24.5154 86.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.5148 78.2009C12.5344 78.2009 11 83.3353 11 89.2009H12.9231H16C16 83.3353 14.4952 78.2009 13.5148 78.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M16.909 76.5587C18.6015 74.1952 17.4914 70.6869 17.5278 58.4815C17.5278 47.6795 18.4559 47.0886 17.7098 41.346C17.1638 37.1175 16.8071 34.6788 15.97 28.0129C15.2966 22.6396 17.8157 8.16406 17.5974 8.20099C17.379 8.23792 14.4158 26.1124 13.9972 34.7171C13.5969 42.9525 13.3239 41.9738 12.9053 50.1723C12.2866 62.0822 12.9053 63.467 11.686 68.231C10.6851 72.0902 9.61135 73.7705 10.1391 75.617C10.3783 76.2885 10.794 76.8809 11.3412 77.33C11.8884 77.779 12.5462 78.0677 13.2435 78.1646C13.9407 78.2616 14.6508 78.1633 15.2969 77.8802C15.943 77.5972 16.5005 77.1402 16.909 76.5587Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2612" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="27" height="84">
        <path d="M16.2879 0.88015L12.7378 10.791C12.7378 10.791 9.76584 18.2007 10.2096 22.1946C10.6534 26.1885 9.17415 37.5921 7.99076 40.5506C7.39906 42.0298 6.14844 47.7316 5.03228 53.0569C3.91613 58.3821 2.96135 63.3443 2.96135 63.3443C2.96135 63.3443 -0.346761 71.9373 1.2804 76.9667C2.90756 81.9962 14.1632 82.3458 14.1632 82.3458C14.1632 82.3458 20.5239 83.2333 24.0741 81.4582C27.6243 79.6832 25.7013 70.1622 25.7013 70.1622L23.6303 51.5238L21.1156 34.9295L19.3405 20.8633L18.8968 8.86801L17.8613 1.61975C17.8613 1.61975 16.5972 -0.141868 16.2879 0.88015Z" fill="#DEE1E9"/>
        <path d="M24.0774 81.4515C20.5273 83.2265 14.1665 82.3525 14.1665 82.3525C14.1665 82.3525 2.91089 82.0432 1.28373 76.9734C-0.343431 71.9037 2.91089 63.4048 2.91089 63.4048C2.91089 63.4048 3.87912 58.4426 4.98182 53.1173L5.30457 51.5709L9.72883 47.7114C10.8261 46.9539 12.0994 46.4909 13.4269 46.3666C15.1385 46.2317 16.8486 46.6462 18.3084 47.55C18.3084 47.55 23.4185 50.2396 23.6875 52.2029L25.6911 70.1689C25.6911 70.1689 27.6411 79.6764 24.0774 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2612)">
        <path d="M16.2879 0.88015L12.7378 10.791C12.7378 10.791 9.76584 18.2007 10.2096 22.1946C10.6534 26.1885 9.17415 37.5921 7.99076 40.5506C7.39906 42.0298 6.14844 47.7316 5.03228 53.0569C3.91613 58.3821 2.96135 63.3443 2.96135 63.3443C2.96135 63.3443 -0.346761 71.9373 1.2804 76.9667C2.90756 81.9962 14.1632 82.3458 14.1632 82.3458C14.1632 82.3458 20.5239 83.2333 24.0741 81.4582C27.6243 79.6832 25.7013 70.1622 25.7013 70.1622L23.6303 51.5238L21.1156 34.9295L19.3405 20.8633L18.8968 8.86801L17.8613 1.61975C17.8613 1.61975 16.5972 -0.141868 16.2879 0.88015Z" fill="#DEE1E9"/>
        <path d="M24.0774 81.4515C20.5273 83.2265 14.1665 82.3525 14.1665 82.3525C14.1665 82.3525 2.91089 82.0432 1.28373 76.9734C-0.343431 71.9037 2.91089 63.4048 2.91089 63.4048C2.91089 63.4048 3.87912 58.4426 4.98182 53.1173L5.30457 51.5709L9.72883 47.7114C10.8261 46.9539 12.0994 46.4909 13.4269 46.3666C15.1385 46.2317 16.8486 46.6462 18.3084 47.55C18.3084 47.55 23.4185 50.2396 23.6875 52.2029L25.6911 70.1689C25.6911 70.1689 27.6411 79.6764 24.0774 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M15.0259 50.5249H26.9471C24.785 45.155 24.428 41.8948 24.7245 36.1565C24.7357 35.9401 24.4864 35.8056 24.2961 35.9321C23.2921 36.6001 20.9339 38.4162 20.3622 41.0579C19.6698 44.2578 17.6665 45.0349 17.4539 45.109C17.441 45.1135 17.4294 45.118 17.417 45.1237L16.2341 45.6704C16.0895 45.7372 16.0309 45.8997 16.1032 46.0333L16.6321 47.011C16.6769 47.0937 16.6728 47.1918 16.6213 47.2711L14.7749 50.1152C14.6579 50.2955 14.7984 50.5249 15.0259 50.5249Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M23.951 37.0461C22.89 37.8939 21.4821 39.3308 21.0797 41.1904C20.3364 44.6249 18.1605 45.581 17.7275 45.7363L16.9539 46.0937L17.2865 46.7085C17.4431 46.998 17.4289 47.3414 17.2487 47.619L15.8012 49.8486H25.9038C24.1271 45.184 23.7511 41.9882 23.951 37.0461ZM17.7442 45.7285C17.7444 45.7285 17.7441 45.7286 17.7442 45.7285V45.7285ZM23.8687 35.3833C24.5976 34.8984 25.4933 35.4548 25.4554 36.1887C25.1626 41.8549 25.5118 45.0211 27.6328 50.289L28 51.201H15.0259C14.2297 51.201 13.7379 50.3983 14.1476 49.7673L15.8699 47.1142L15.4488 46.3357C15.1958 45.868 15.4009 45.2994 15.9069 45.0655L17.0898 44.5189C17.1305 44.5001 17.1664 44.4865 17.1959 44.4762C17.2325 44.4635 19.0133 43.8434 19.6448 40.9252C20.2743 38.0162 22.8377 36.0692 23.8687 35.3833Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask112" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="27" height="84">
        <path d="M16.2879 0.88015L12.7378 10.791C12.7378 10.791 9.76584 18.2007 10.2096 22.1946C10.6534 26.1885 9.17415 37.5921 7.99076 40.5506C7.39906 42.0298 6.14844 47.7316 5.03228 53.0569C3.91613 58.3821 2.96135 63.3443 2.96135 63.3443C2.96135 63.3443 -0.346761 71.9373 1.2804 76.9667C2.90756 81.9962 14.1632 82.3458 14.1632 82.3458C14.1632 82.3458 20.5239 83.2333 24.0741 81.4582C27.6243 79.6832 25.7013 70.1622 25.7013 70.1622L23.6303 51.5238L21.1156 34.9295L19.3405 20.8633L18.8968 8.86801L17.8613 1.61975C17.8613 1.61975 16.5972 -0.141868 16.2879 0.88015Z" fill="#DEE1E9"/>
        <path d="M24.0774 81.4515C20.5273 83.2265 14.1665 82.3525 14.1665 82.3525C14.1665 82.3525 2.91089 82.0432 1.28373 76.9734C-0.343431 71.9037 2.91089 63.4048 2.91089 63.4048C2.91089 63.4048 3.87912 58.4426 4.98182 53.1173L5.30457 51.5709L9.72883 47.7114C10.8261 46.9539 12.0994 46.4909 13.4269 46.3666C15.1385 46.2317 16.8486 46.6462 18.3084 47.55C18.3084 47.55 23.4185 50.2396 23.6875 52.2029L25.6911 70.1689C25.6911 70.1689 27.6411 79.6764 24.0774 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask112)">
        <path d="M16.2879 0.88015L12.7378 10.791C12.7378 10.791 9.76584 18.2007 10.2096 22.1946C10.6534 26.1885 9.17415 37.5921 7.99076 40.5506C7.39906 42.0298 6.14844 47.7316 5.03228 53.0569C3.91613 58.3821 2.96135 63.3443 2.96135 63.3443C2.96135 63.3443 -0.346761 71.9373 1.2804 76.9667C2.90756 81.9962 14.1632 82.3458 14.1632 82.3458C14.1632 82.3458 20.5239 83.2333 24.0741 81.4582C27.6243 79.6832 25.7013 70.1622 25.7013 70.1622L23.6303 51.5238L21.1156 34.9295L19.3405 20.8633L18.8968 8.86801L17.8613 1.61975C17.8613 1.61975 16.5972 -0.141868 16.2879 0.88015Z" fill="#DEE1E9"/>
        <path d="M24.0774 81.4515C20.5273 83.2265 14.1665 82.3525 14.1665 82.3525C14.1665 82.3525 2.91089 82.0432 1.28373 76.9734C-0.343431 71.9037 2.91089 63.4048 2.91089 63.4048C2.91089 63.4048 3.87912 58.4426 4.98182 53.1173L5.30457 51.5709L9.72883 47.7114C10.8261 46.9539 12.0994 46.4909 13.4269 46.3666C15.1385 46.2317 16.8486 46.6462 18.3084 47.55C18.3084 47.55 23.4185 50.2396 23.6875 52.2029L25.6911 70.1689C25.6911 70.1689 27.6411 79.6764 24.0774 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M16.7011 51.5315H24.8479C23.3703 47.561 23.1264 45.1504 23.329 40.9074C23.3367 40.7474 23.1662 40.6479 23.0362 40.7415C22.3501 41.2354 20.7385 42.5782 20.3479 44.5315C19.8746 46.8976 18.5057 47.4722 18.3604 47.527C18.3515 47.5303 18.3436 47.5337 18.3352 47.5379L17.5267 47.9421C17.428 47.9915 17.3879 48.1116 17.4373 48.2104L17.7988 48.9333C17.8293 48.9945 17.8266 49.067 17.7914 49.1257L16.5296 51.2286C16.4496 51.3619 16.5456 51.5315 16.7011 51.5315Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M22.8004 41.5653C22.0753 42.1922 21.1131 43.2546 20.8381 44.6297C20.3302 47.1692 18.8432 47.8761 18.5473 47.9909L18.0187 48.2552L18.246 48.7098C18.353 48.9239 18.3432 49.1778 18.2201 49.383L17.2309 51.0316H24.1349C22.9207 47.5825 22.6637 45.2195 22.8004 41.5653ZM18.5587 47.9852C18.5588 47.9851 18.5586 47.9852 18.5587 47.9852V47.9852ZM22.7441 40.3358C23.2422 39.9773 23.8543 40.3887 23.8284 40.9313C23.6283 45.121 23.867 47.4621 25.3164 51.3572L25.5674 52.0316H16.7011C16.157 52.0316 15.8209 51.438 16.1008 50.9715L17.2779 49.0097L16.9901 48.4341C16.8172 48.0883 16.9573 47.6679 17.3031 47.495L18.1115 47.0908C18.1393 47.0768 18.1638 47.0668 18.184 47.0592C18.209 47.0498 19.426 46.5913 19.8576 44.4336C20.2877 42.2826 22.0395 40.843 22.7441 40.3358Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M16.2882 0.343163L12.738 10.254C12.738 10.254 9.76609 17.6637 10.2099 21.6576C10.6536 25.6516 9.17439 37.0551 7.991 40.0136C7.39931 41.4929 6.14868 47.1946 5.03253 52.5199C3.91638 57.8451 2.9616 62.8073 2.9616 62.8073C2.9616 62.8073 -0.346516 71.4004 1.28064 76.4298C2.90781 81.4592 14.1635 81.8088 14.1635 81.8088C14.1635 81.8088 20.5242 82.6963 24.0743 80.9213C27.6245 79.1462 25.7015 69.6253 25.7015 69.6253L23.6306 50.9869L21.1159 34.3925L19.3408 20.3263L18.897 8.33103L17.8616 1.08277C17.8616 1.08277 16.5975 -0.678856 16.2882 0.343163Z" fill="#DEE1E9"/>
      <mask id="mask212" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="28" height="38">
        <path d="M24.5154 81.3107C20.9653 83.0858 14.6045 82.2117 14.6045 82.2117C14.6045 82.2117 3.34888 81.9024 1.72172 76.8327C0.0945573 71.7629 3.34888 63.264 3.34888 63.264C3.34888 63.264 4.31711 58.3019 5.41981 52.9766L5.74255 51.4301L10.1668 47.5707C11.264 46.8132 12.5374 46.3501 13.8649 46.2259C15.5765 46.0909 17.2866 46.5055 18.7464 47.4093C18.7464 47.4093 23.8565 50.0988 24.1254 52.0622L26.1291 70.0282C26.1291 70.0282 28.079 79.5356 24.5154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask212)">
        <path d="M24.5154 81.3107C20.9653 83.0858 14.6045 82.2117 14.6045 82.2117C14.6045 82.2117 3.34888 81.9024 1.72172 76.8327C0.0945573 71.7629 3.34888 63.264 3.34888 63.264C3.34888 63.264 4.31711 58.3019 5.41981 52.9766L5.74255 51.4301L10.1668 47.5707C11.264 46.8132 12.5374 46.3501 13.8649 46.2259C15.5765 46.0909 17.2866 46.5055 18.7464 47.4093C18.7464 47.4093 23.8565 50.0988 24.1254 52.0622L26.1291 70.0282C26.1291 70.0282 28.079 79.5356 24.5154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7868 66.2009C12.4143 66.2009 10.2661 74.6026 10.2661 84.2009H12.9584H17.2661C17.2661 74.6026 15.1594 66.2009 13.7868 66.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M16.6319 65.6761C18.1913 63.4814 17.1685 60.2236 17.202 48.8901C17.202 38.8596 18.0572 38.311 17.3697 32.9785C16.8667 29.0521 16.538 26.7875 15.7667 20.5978C15.1463 15.6083 17.4673 2.1667 17.2661 2.20099C17.0649 2.23528 14.3347 18.833 13.9491 26.8231C13.5802 34.4702 13.3286 33.5615 12.943 41.1744C12.3729 52.2336 12.943 53.5195 11.8195 57.9432C10.8973 61.5267 9.90802 63.087 10.3943 64.8016C10.6147 65.4252 10.9977 65.9753 11.5019 66.3922C12.006 66.8092 12.6121 67.0773 13.2545 67.1673C13.897 67.2574 14.5512 67.1661 15.1465 66.9032C15.7418 66.6404 16.2555 66.216 16.6319 65.6761Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M16.2882 0.343041L12.738 10.2539C12.738 10.2539 9.76609 17.6636 10.2099 21.6575C10.6536 25.6514 9.17439 37.055 7.991 40.0135C7.39931 41.4927 6.14868 47.1945 5.03253 52.5198C3.91638 57.845 2.9616 62.8072 2.9616 62.8072C2.9616 62.8072 -0.346516 71.4002 1.28064 76.4296C2.90781 81.459 14.1635 81.8087 14.1635 81.8087C14.1635 81.8087 20.5242 82.6962 24.0743 80.9211C27.6245 79.146 25.7015 69.6251 25.7015 69.6251L23.6306 50.9867L21.1159 34.3924L19.3408 20.3262L18.897 8.3309L17.8616 1.08264C17.8616 1.08264 16.5975 -0.678978 16.2882 0.343041Z" fill="#DEE1E9"/>
      <mask id="mask312" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="28" height="38">
        <path d="M24.5154 81.3107C20.9653 83.0858 14.6045 82.2117 14.6045 82.2117C14.6045 82.2117 3.34888 81.9024 1.72172 76.8327C0.0945573 71.7629 3.34888 63.264 3.34888 63.264C3.34888 63.264 4.31711 58.3019 5.41981 52.9766L5.74255 51.4301L10.1668 47.5707C11.264 46.8132 12.5374 46.3501 13.8649 46.2259C15.5765 46.0909 17.2866 46.5055 18.7464 47.4093C18.7464 47.4093 23.8565 50.0988 24.1254 52.0622L26.1291 70.0282C26.1291 70.0282 28.079 79.5356 24.5154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask312)">
        <path d="M24.5154 81.3107C20.9653 83.0858 14.6045 82.2117 14.6045 82.2117C14.6045 82.2117 3.34888 81.9024 1.72172 76.8327C0.0945573 71.7629 3.34888 63.264 3.34888 63.264C3.34888 63.264 4.31711 58.3019 5.41981 52.9766L5.74255 51.4301L10.1668 47.5707C11.264 46.8132 12.5374 46.3501 13.8649 46.2259C15.5765 46.0909 17.2866 46.5055 18.7464 47.4093C18.7464 47.4093 23.8565 50.0988 24.1254 52.0622L26.1291 70.0282C26.1291 70.0282 28.079 79.5356 24.5154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7809 72.2009C12.8005 72.2009 11.2661 78.2688 11.2661 85.2009H13.1892H16.2661C16.2661 78.2688 14.7613 72.2009 13.7809 72.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

      <path d="M16.2879 1.01296L12.7378 10.9238C12.7378 10.9238 9.76584 18.3335 10.2096 22.3274C10.6534 26.3214 9.17415 37.7249 7.99076 40.6834C7.39906 42.1627 6.14844 47.8644 5.03228 53.1897C3.91613 58.5149 2.96135 63.4771 2.96135 63.4771C2.96135 63.4771 -0.346761 72.0702 1.2804 77.0996C2.90756 82.129 14.1632 82.4786 14.1632 82.4786C14.1632 82.4786 20.5239 83.3661 24.0741 81.5911C27.6243 79.816 25.7013 70.2951 25.7013 70.2951L23.6303 51.6567L21.1156 35.0623L19.3405 20.9961L18.8968 9.00083L17.8613 1.75256C17.8613 1.75256 16.5972 -0.00905597 16.2879 1.01296Z" fill="#DEE1E9"/>
      <path d="M24.0774 81.5844C20.5273 83.3595 14.1665 82.4854 14.1665 82.4854C14.1665 82.4854 2.91089 82.1761 1.28373 77.1063C-0.343431 72.0366 2.91089 63.5377 2.91089 63.5377C2.91089 63.5377 3.87912 58.5755 4.98182 53.2503L5.30457 51.7038L9.72883 47.8443C10.8261 47.0869 12.0994 46.6238 13.4269 46.4996C15.1385 46.3646 16.8486 46.7792 18.3084 47.683C18.3084 47.683 23.4185 50.3725 23.6875 52.3358L25.6911 70.3019C25.6911 70.3019 27.6411 79.8093 24.0774 81.5844Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M10.2661 67.2009L12.1328 69.7018L13.7661 68.2061V71.5201L16.3283 70.9522L14.9328 72.7028L17.2661 74.7035L14.9328 76.7042L16.3283 78.4548L13.9994 77.7045L13.7661 81.2009L11.5162 79.7039L10.7328 80.7056L9.01602 79.7039L6.76611 81.2009L6.06611 79.2051L4.20394 78.4548L4.66611 75.7038L3.26611 74.7035L5.59945 73.203L4.20394 70.9522L6.06611 70.7021L6.76611 68.2061L9.79945 70.202L10.2661 67.2009Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

      <path d="M16.2879 1.01296L12.7378 10.9238C12.7378 10.9238 9.76584 18.3335 10.2096 22.3274C10.6534 26.3214 9.17415 37.7249 7.99076 40.6834C7.39906 42.1627 6.14844 47.8644 5.03228 53.1897C3.91613 58.5149 2.96135 63.4771 2.96135 63.4771C2.96135 63.4771 -0.346761 72.0702 1.2804 77.0996C2.90756 82.129 14.1632 82.4786 14.1632 82.4786C14.1632 82.4786 20.5239 83.3661 24.0741 81.5911C27.6243 79.816 25.7013 70.2951 25.7013 70.2951L23.6303 51.6567L21.1156 35.0623L19.3405 20.9961L18.8968 9.00083L17.8613 1.75256C17.8613 1.75256 16.5972 -0.00905597 16.2879 1.01296Z" fill="#DEE1E9"/>
      <path d="M24.0774 81.5844C20.5273 83.3595 14.1665 82.4854 14.1665 82.4854C14.1665 82.4854 2.91089 82.1761 1.28373 77.1063C-0.343431 72.0366 2.91089 63.5377 2.91089 63.5377C2.91089 63.5377 3.87912 58.5755 4.98182 53.2503L5.30457 51.7038L9.72883 47.8443C10.8261 47.0869 12.0994 46.6238 13.4269 46.4996C15.1385 46.3646 16.8486 46.7792 18.3084 47.683C18.3084 47.683 23.4185 50.3725 23.6875 52.3358L25.6911 70.3019C25.6911 70.3019 27.6411 79.8093 24.0774 81.5844Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M8.26611 68.2009L9.86611 70.3445L11.2661 69.0625V71.9031L13.4623 71.4163L12.2661 72.9168L14.2661 74.6317L12.2661 76.3466L13.4623 77.8471L11.4661 77.204L11.2661 80.2009L9.33762 78.9178L8.66611 79.7763L7.1946 78.9178L5.26611 80.2009L4.66611 78.4902L3.06996 77.8471L3.46611 75.4891L2.26611 74.6317L4.26611 73.3456L3.06996 71.4163L4.66611 71.202L5.26611 69.0625L7.86611 70.7732L8.26611 68.2009Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

      <path d="M16.2879 0.945336L12.7378 10.8562C12.7378 10.8562 9.76584 18.2659 10.2096 22.2598C10.6534 26.2537 9.17415 37.6573 7.99076 40.6158C7.39906 42.095 6.14844 47.7968 5.03228 53.1221C3.91613 58.4473 2.96135 63.4095 2.96135 63.4095C2.96135 63.4095 -0.346761 72.0025 1.2804 77.0319C2.90756 82.0613 14.1632 82.411 14.1632 82.411C14.1632 82.411 20.5239 83.2985 24.0741 81.5234C27.6243 79.7483 25.7013 70.2274 25.7013 70.2274L23.6303 51.589L21.1156 34.9947L19.3405 20.9285L18.8968 8.9332L17.8613 1.68494C17.8613 1.68494 16.5972 -0.0766829 16.2879 0.945336Z" fill="#DEE1E9"/>
      <path d="M24.0774 81.5165C20.5273 83.2916 14.1665 82.4175 14.1665 82.4175C14.1665 82.4175 2.91089 82.1082 1.28373 77.0385C-0.343431 71.9687 2.91089 63.4698 2.91089 63.4698C2.91089 63.4698 3.87912 58.5077 4.98182 53.1824L5.30457 51.6359L9.72883 47.7765C10.8261 47.019 12.0994 46.5559 13.4269 46.4317C15.1385 46.2968 16.8486 46.7113 18.3084 47.6151C18.3084 47.6151 23.4185 50.3046 23.6875 52.268L25.6911 70.234C25.6911 70.234 27.6411 79.7414 24.0774 81.5165Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M6.76611 73.1333L7.69945 74.3837L8.51611 73.6359V75.2929L9.7972 75.0089L9.09945 75.8842L10.2661 76.8846L9.09945 77.8849L9.7972 78.7602L8.63278 78.3851L8.51611 80.1333L7.39116 79.3848L6.99945 79.8856L6.14106 79.3848L5.01611 80.1333L4.66611 79.1354L3.73502 78.7602L3.96611 77.3848L3.26611 76.8846L4.43278 76.1343L3.73502 75.0089L4.66611 74.8839L5.01611 73.6359L6.53278 74.6338L6.76611 73.1333Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

      <path d="M10.7106 2.68065L12.7943 1.41089H16.278L18.378 2.68065L16.7664 4.22716L18.8501 5.77367L16.9617 7.32018L19.2571 9.0132L17.4338 10.5597L19.7292 11.9597L17.7106 13.376L19.7292 14.9876L18.0361 16.5341L20.2664 17.9504L18.5082 19.4155L20.8036 20.962L18.6547 22.5085L20.8036 24.1201L19.2571 25.4713L21.3408 27.0178L19.5175 28.4992L21.6012 30.0457L19.7943 31.7876L20.8036 33.4643V47.7247L18.9152 55.0503H12.7943" fill="#8889BE"/>
      <path d="M10.7106 2.68065L12.7943 1.41089H16.278L18.378 2.68065L16.7664 4.22716L18.8501 5.77367L16.9617 7.32018L19.2571 9.0132L17.4338 10.5597L19.7292 11.9597L17.7106 13.376L19.7292 14.9876L18.0361 16.5341L20.2664 17.9504L18.5082 19.4155L20.8036 20.962L18.6547 22.5085L20.8036 24.1201L19.2571 25.4713L21.3408 27.0178L19.5175 28.4992L21.6012 30.0457L19.7943 31.7876L20.8036 33.4643V47.7247L18.9152 55.0503H12.7943" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M18.1178 2.68065L16.0341 1.41089H12.5667L10.4504 2.68065L12.0783 4.22716L9.97833 5.77367L11.8667 7.32018L9.58763 9.0132L11.3946 10.5597L9.11554 11.9597L11.1341 13.376L9.11554 14.9876L10.7923 16.5341L8.57833 17.9504L10.3202 19.4155L8.04112 20.962L10.19 22.5085L8.04112 24.1201L9.58763 25.4713L7.50392 27.0178L9.31089 28.4992L7.22717 30.0457L9.05042 31.7876L8.04112 33.4643V47.7247L9.91321 55.0503H13.8202" fill="#8889BE"/>
      <path d="M18.1178 2.68065L16.0341 1.41089H12.5667L10.4504 2.68065L12.0783 4.22716L9.97833 5.77367L11.8667 7.32018L9.58763 9.0132L11.3946 10.5597L9.11554 11.9597L11.1341 13.376L9.11554 14.9876L10.7923 16.5341L8.57833 17.9504L10.3202 19.4155L8.04112 20.962L10.19 22.5085L8.04112 24.1201L9.58763 25.4713L7.50392 27.0178L9.31089 28.4992L7.22717 30.0457L9.05042 31.7876L8.04112 33.4643V47.7247L9.91321 55.0503H13.8202" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M14.1665 74.5623C14.1665 74.5623 20.5273 75.4364 24.0774 73.6614C27.6411 71.8863 25.6911 62.3788 25.6911 62.3788L23.424 43.7808H20.6379C20.0856 43.7808 19.6379 44.2285 19.6379 44.7808V53.6186C15.4423 54.8123 13.0893 54.6972 8.89354 53.6186V44.7808C8.89354 44.2285 8.44583 43.7808 7.89354 43.7808H5.30457L4.98182 45.3272C3.87912 50.6525 2.91089 55.6147 2.91089 55.6147C2.91089 55.6147 -0.343431 64.1136 1.28373 69.1833C2.91089 74.2531 14.1665 74.5623 14.1665 74.5623Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask512" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="44" width="27" height="32">
        <path d="M14.1687 74.6297C14.1687 74.6297 20.5295 75.5038 24.0796 73.7287C27.6432 71.9536 25.6933 62.4461 25.6933 62.4461L23.4262 44.5586H20.6401C20.0878 44.5586 19.6401 45.0063 19.6401 45.5586V53.686C15.4444 54.8796 13.0915 54.7645 8.89574 53.686V45.5586C8.89574 45.0063 8.44802 44.5586 7.89574 44.5586H5.30676L4.98402 45.3946C3.88132 50.7198 2.91309 55.682 2.91309 55.682C2.91309 55.682 -0.341234 64.1809 1.28593 69.2506C2.91309 74.3204 14.1687 74.6297 14.1687 74.6297Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask512)">
        <path d="M5.93628 31.9072H22.8308V57.4552C16.2331 59.3814 12.534 59.401 5.93628 57.4552V31.9072Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M10.4361 2.03344L12.5198 0.763672H16.0035L18.1035 2.03344L16.4919 3.57995L18.5756 5.12645L16.6872 6.67296L18.9826 8.36598L17.1593 9.91249L19.4547 11.3125L17.4361 12.7288L19.4547 14.3404L17.7617 15.8869L19.9919 17.3032L18.2338 18.7683L20.5291 20.3148L18.3803 21.8613L20.5291 23.4729L18.9826 24.8241L21.0663 26.3706L19.2431 27.852L21.3268 29.3985L19.5198 31.1404L20.5291 32.8171V47.0775L18.6407 54.4031H12.5198" fill="#8889BE"/>
      <path d="M10.4361 2.03344L12.5198 0.763672H16.0035L18.1035 2.03344L16.4919 3.57995L18.5756 5.12645L16.6872 6.67296L18.9826 8.36598L17.1593 9.91249L19.4547 11.3125L17.4361 12.7288L19.4547 14.3404L17.7617 15.8869L19.9919 17.3032L18.2338 18.7683L20.5291 20.3148L18.3803 21.8613L20.5291 23.4729L18.9826 24.8241L21.0663 26.3706L19.2431 27.852L21.3268 29.3985L19.5198 31.1404L20.5291 32.8171V47.0775L18.6407 54.4031H12.5198" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M17.8434 2.03344L15.7597 0.763672H12.2922L10.1759 2.03344L11.8039 3.57995L9.70385 5.12645L11.5922 6.67296L9.31316 8.36598L11.1201 9.91249L8.84107 11.3125L10.8597 12.7288L8.84107 14.3404L10.5178 15.8869L8.30386 17.3032L10.0457 18.7683L7.76665 20.3148L9.91548 21.8613L7.76665 23.4729L9.31316 24.8241L7.22944 26.3706L9.03641 27.852L6.9527 29.3985L8.77595 31.1404L7.76665 32.8171V47.0775L9.63874 54.4031H13.5457" fill="#8889BE"/>
      <path d="M17.8434 2.03344L15.7597 0.763672H12.2922L10.1759 2.03344L11.8039 3.57995L9.70385 5.12645L11.5922 6.67296L9.31316 8.36598L11.1201 9.91249L8.84107 11.3125L10.8597 12.7288L8.84107 14.3404L10.5178 15.8869L8.30386 17.3032L10.0457 18.7683L7.76665 20.3148L9.91548 21.8613L7.76665 23.4729L9.31316 24.8241L7.22944 26.3706L9.03641 27.852L6.9527 29.3985L8.77595 31.1404L7.76665 32.8171V47.0775L9.63874 54.4031H13.5457" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M14.1665 73.0023C14.1665 73.0023 20.5273 73.8764 24.0774 72.1013C27.6411 70.3262 25.6911 60.8188 25.6911 60.8188L23.424 42.2207H19.6541C19.1018 42.2207 18.6541 42.6684 18.6541 43.2207V52.0586C14.4585 53.2522 13.6123 53.1371 9.41659 52.0586V43.2207C9.41659 42.6684 8.96887 42.2207 8.41659 42.2207H5.30457L4.98182 43.7672C3.87912 49.0924 2.91089 54.0546 2.91089 54.0546C2.91089 54.0546 -0.343431 62.5535 1.28373 67.6232C2.91089 72.693 14.1665 73.0023 14.1665 73.0023Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M0 79.771H21V84.771H0V79.771Z" fill="#838AA2"/>
      <path d="M22 79.771H43V84.771H22V79.771Z" fill="#838AA2"/>
      <path d="M23.4598 0.583519L19.9096 10.4944C19.9096 10.4944 16.9377 17.904 17.3815 21.898C17.8253 25.8919 16.346 37.2955 15.1626 40.254C14.5709 41.7332 13.3203 47.435 12.2042 52.7602C11.088 58.0855 10.1332 63.0477 10.1332 63.0477C10.1332 63.0477 6.82511 71.6407 8.45228 76.6701C10.0794 81.6995 21.3351 82.0492 21.3351 82.0492C21.3351 82.0492 27.6958 82.9367 31.246 81.1616C34.7962 79.3865 32.8731 69.8656 32.8731 69.8656L30.8022 51.2272L28.2875 34.6329L26.5124 20.5667L26.0686 8.57138L25.0332 1.32312C25.0332 1.32312 23.7691 -0.438499 23.4598 0.583519Z" fill="#DEE1E9"/>
      <path d="M21.3384 82.056C21.3384 82.056 27.6991 82.9301 31.2493 81.155C34.8129 79.3799 32.863 69.8725 32.863 69.8725L30.5958 51.2744H26.826C26.2737 51.2744 25.826 51.7221 25.826 52.2744V61.1123C21.6304 62.3059 20.7842 62.1909 16.5885 61.1123V52.2744C16.5885 51.7221 16.1407 51.2744 15.5885 51.2744H12.4764L12.1537 52.8209C11.051 58.1461 10.0828 63.1083 10.0828 63.1083C10.0828 63.1083 6.82844 71.6072 8.45561 76.677C10.0828 81.7467 21.3384 82.056 21.3384 82.056Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M16.0221 1.14211L12.4719 11.053C12.4719 11.053 9.49998 18.4626 9.94375 22.4566C10.3875 26.4505 8.90828 37.8541 7.72489 40.8126C7.1332 42.2918 5.88257 47.9936 4.76642 53.3188C3.65026 58.6441 2.69548 63.6063 2.69548 63.6063C2.69548 63.6063 -0.61263 72.1993 1.01453 77.2287C2.64169 82.2581 13.8973 82.6077 13.8973 82.6077C13.8973 82.6077 20.2581 83.4953 23.8082 81.7202C27.3584 79.9451 25.4354 70.4242 25.4354 70.4242L23.3645 51.7858L20.8498 35.1915L19.0747 21.1253L18.6309 9.12998L17.5954 1.88172C17.5954 1.88172 16.3314 0.120094 16.0221 1.14211Z" fill="#DEE1E9"/>
      <path d="M23.8116 81.7133C20.2614 83.4884 13.9007 82.6143 13.9007 82.6143C13.9007 82.6143 2.64502 82.305 1.01786 77.2352C-0.6093 72.1655 2.64502 63.6666 2.64502 63.6666C2.64502 63.6666 3.61325 58.7044 4.71595 53.3792L5.0387 51.8327L9.46296 47.9732C10.5602 47.2158 11.8336 46.7527 13.1611 46.6285C14.8727 46.4935 16.5827 46.9081 18.0425 47.8119C18.0425 47.8119 23.1526 50.5014 23.4216 52.4647L25.4253 70.4308C25.4253 70.4308 27.3752 79.9382 23.8116 81.7133Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M15.4095 52.8077C16.6601 51.0864 15.8398 48.5314 15.8667 39.6425C15.8667 31.7756 16.5525 31.3453 16.0012 27.1631C15.5978 24.0836 15.3342 22.3076 14.7156 17.453C14.218 13.5397 16.8744 2.99757 16.713 3.02447C16.5517 3.05136 13.5672 16.0689 13.2579 22.3354C12.962 28.3331 12.7603 27.6203 12.451 33.5911C11.9938 42.2648 12.451 43.2733 11.55 46.7428C10.8104 49.5534 10.017 50.7771 10.407 52.1219C10.5838 52.6109 10.8909 53.0423 11.2953 53.3694C11.6996 53.6964 12.1857 53.9066 12.7009 53.9773C13.2161 54.0479 13.7408 53.9763 14.2183 53.7701C14.6957 53.564 15.1076 53.2312 15.4095 52.8077Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M16.0221 1.14211L12.4719 11.053C12.4719 11.053 9.49998 18.4626 9.94375 22.4566C10.3875 26.4505 8.90828 37.8541 7.72489 40.8126C7.1332 42.2918 5.88257 47.9936 4.76642 53.3188C3.65026 58.6441 2.69548 63.6063 2.69548 63.6063C2.69548 63.6063 -0.61263 72.1993 1.01453 77.2287C2.64169 82.2581 13.8973 82.6077 13.8973 82.6077C13.8973 82.6077 20.2581 83.4953 23.8082 81.7202C27.3584 79.9451 25.4354 70.4242 25.4354 70.4242L23.3645 51.7858L20.8498 35.1915L19.0747 21.1253L18.6309 9.12998L17.5954 1.88172C17.5954 1.88172 16.3314 0.120094 16.0221 1.14211Z" fill="#DEE1E9"/>
      <path d="M23.8116 81.7133C20.2614 83.4884 13.9007 82.6143 13.9007 82.6143C13.9007 82.6143 2.64502 82.305 1.01786 77.2352C-0.6093 72.1655 2.64502 63.6666 2.64502 63.6666C2.64502 63.6666 3.61325 58.7044 4.71595 53.3792L5.0387 51.8327L9.46296 47.9732C10.5602 47.2158 11.8336 46.7527 13.1611 46.6285C14.8727 46.4935 16.5827 46.9081 18.0425 47.8119C18.0425 47.8119 23.1526 50.5014 23.4216 52.4647L25.4253 70.4308C25.4253 70.4308 27.3752 79.9382 23.8116 81.7133Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.4" d="M15.4095 52.8077C16.6601 51.0864 15.8398 48.5314 15.8667 39.6425C15.8667 31.7756 16.5525 31.3453 16.0012 27.1631C15.5978 24.0836 15.3342 22.3076 14.7156 17.453C14.218 13.5397 16.8744 2.99757 16.713 3.02447C16.5517 3.05136 13.5672 16.0689 13.2579 22.3354C12.962 28.3331 12.7603 27.6203 12.451 33.5911C11.9938 42.2648 12.451 43.2733 11.55 46.7428C10.8104 49.5534 10.017 50.7771 10.407 52.1219C10.5838 52.6109 10.8909 53.0423 11.2953 53.3694C11.6996 53.6964 12.1857 53.9066 12.7009 53.9773C13.2161 54.0479 13.7408 53.9763 14.2183 53.7701C14.6957 53.564 15.1076 53.2312 15.4095 52.8077Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

      <path d="M16.0222 1.14065L12.472 11.0515C12.472 11.0515 9.5001 18.4612 9.94387 22.4551C10.3876 26.449 8.9084 37.8526 7.72501 40.8111C7.13332 42.2903 5.88269 47.9921 4.76654 53.3174C3.65039 58.6426 2.69561 63.6048 2.69561 63.6048C2.69561 63.6048 -0.612508 72.1978 1.01465 77.2272C2.64181 82.2567 13.8975 82.6063 13.8975 82.6063C13.8975 82.6063 20.2582 83.4938 23.8084 81.7187C27.3585 79.9437 25.4355 70.4227 25.4355 70.4227L23.3646 51.7843L20.8499 35.19L19.0748 21.1238L18.631 9.12851L17.5956 1.88025C17.5956 1.88025 16.3315 0.11863 16.0222 1.14065Z" fill="#DEE1E9"/>
      <path d="M23.8117 81.7118C20.2615 83.4869 13.9008 82.6128 13.9008 82.6128C13.9008 82.6128 2.64514 82.3035 1.01798 77.2338C-0.609178 72.164 2.64514 63.6651 2.64514 63.6651C2.64514 63.6651 3.61337 58.703 4.71608 53.3777L5.03882 51.8312L9.46308 47.9718C10.5603 47.2143 11.8337 46.7513 13.1612 46.627C14.8728 46.4921 16.5829 46.9066 18.0427 47.8104C18.0427 47.8104 23.1528 50.4999 23.4217 52.4633L25.4254 70.4293C25.4254 70.4293 27.3753 79.9368 23.8117 81.7118Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask512" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="1" width="7" height="20">
        <path d="M17.6101 2.70695L17.2472 2.16339L16.4182 1.62117L15.7487 2.16339L11.8591 20.2772H17.7891L17.2472 12.1741L17.9395 5.17149L17.6101 2.70695Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask512)">
        <path opacity="0.4" d="M15.4096 52.8063C16.6603 51.085 15.8399 48.5299 15.8668 39.641C15.8668 31.7742 16.5527 31.3439 16.0013 27.1617C15.5979 24.0822 15.3343 22.3061 14.7157 17.4515C14.2182 13.5382 16.8745 2.99611 16.7132 3.023C16.5518 3.0499 13.5673 16.0674 13.258 22.334C12.9622 28.3316 12.7604 27.6189 12.4511 33.5896C11.9939 42.2633 12.4511 43.2719 11.5502 46.7414C10.8105 49.5519 10.0171 50.7757 10.4071 52.1204C10.5839 52.6095 10.8911 53.0409 11.2954 53.3679C11.6997 53.6949 12.1858 53.9052 12.701 53.9758C13.2162 54.0464 13.7409 53.9748 14.2184 53.7687C14.6958 53.5625 15.1077 53.2297 15.4096 52.8063Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
      <mask id="mask501" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="20" width="12" height="37">
        <path d="M17.7462 20.3237H11.6462L7.57958 56.4391L18.7629 54.4058L17.7462 20.3237Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask501)">
        <path opacity="0.2" d="M15.4096 52.8063C16.6603 51.085 15.8399 48.5299 15.8668 39.641C15.8668 31.7742 16.5527 31.3439 16.0013 27.1617C15.5979 24.0822 15.3343 22.3061 14.7157 17.4515C14.2182 13.5382 16.8745 2.99611 16.7132 3.023C16.5518 3.0499 13.5673 16.0674 13.258 22.334C12.9622 28.3316 12.7604 27.6189 12.4511 33.5896C11.9939 42.2633 12.4511 43.2719 11.5502 46.7414C10.8105 49.5519 10.0171 50.7757 10.4071 52.1204C10.5839 52.6095 10.8911 53.0409 11.2954 53.3679C11.6997 53.6949 12.1858 53.9052 12.701 53.9758C13.2162 54.0464 13.7409 53.9748 14.2184 53.7687C14.6958 53.5625 15.1077 53.2297 15.4096 52.8063Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M16.0218 0.347862L12.4717 10.2587C12.4717 10.2587 9.49973 17.6684 9.9435 21.6623C10.3873 25.6563 8.90804 37.0598 7.72465 40.0183C7.13295 41.4976 5.88232 47.1993 4.76617 52.5246C3.65002 57.8498 2.69524 62.812 2.69524 62.812C2.69524 62.812 -0.612874 71.4051 1.01429 76.4345C2.64145 81.4639 13.8971 81.8135 13.8971 81.8135C13.8971 81.8135 20.2578 82.701 23.808 80.926C27.3582 79.1509 25.4352 69.63 25.4352 69.63L23.3642 50.9916L20.8495 34.3972L19.0744 20.331L18.6307 8.33573L17.5952 1.08746C17.5952 1.08746 16.3311 -0.674156 16.0218 0.347862Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M16.0218 0.68496L12.4717 10.5958C12.4717 10.5958 9.49973 18.0055 9.9435 21.9994C10.3873 25.9934 8.90804 37.3969 7.72465 40.3554C7.13295 41.8347 5.88232 47.5364 4.76617 52.8617C3.65002 58.1869 2.69524 63.1491 2.69524 63.1491C2.69524 63.1491 -0.612874 71.7421 1.01429 76.7716C2.64145 81.801 13.8971 82.1506 13.8971 82.1506C13.8971 82.1506 20.2578 83.0381 23.808 81.2631C27.3582 79.488 25.4352 69.967 25.4352 69.967L23.3642 51.3287L20.8495 34.7343L19.0744 20.6681L18.6307 8.67282L17.5952 1.42456C17.5952 1.42456 16.3311 -0.337059 16.0218 0.68496Z" fill="#DEE1E9"/>
      <path d="M23.8113 81.2563C20.2611 83.0314 13.9004 82.1573 13.9004 82.1573C13.9004 82.1573 2.64478 81.848 1.01762 76.7782C-0.609544 71.7085 2.64478 63.2096 2.64478 63.2096C2.64478 63.2096 3.61301 58.2474 4.71571 52.9222L5.03845 51.3757L9.46272 47.5162C10.5599 46.7587 11.8333 46.2957 13.1608 46.1715C14.8724 46.0365 16.5825 46.4511 18.0423 47.3548C18.0423 47.3548 23.1524 50.0444 23.4213 52.0077L25.425 69.9737C25.425 69.9737 27.3749 79.4812 23.8113 81.2563Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth12",
  props: {
    status: {}
  },
}
</script>
