import ru from './ru/ru'
import en from './en/en'
import uk from './uk/uk'
import uz from './uz/uz'
import az from './az/az'

export default {
    ru,
    en,
    uk,
    uz,
    az
}
