<template>
  <div v-if="string">
    {{ string }}
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "CRMDatatableTDAdminName",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      admins: state => state.auth.admins,
      superadmins: state => state.auth.superadmins,
    }),
    bothRoles() {
      return this.admins.concat(this.superadmins)
    },
    admin() {
      return this.bothRoles.find(admin => Number(admin.id) === Number(this.object.admin_id))
    },
    string() {
      return this.admin?.title || this.admin?.full_name
    }
  },
}
</script>
