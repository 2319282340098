<template>
  <div v-if="phone && !rights.includes('hide_patient_phone')">
    <OutgoingCallElement :phone="phone"/>
  </div>
</template>

<script>
import { mapState } from "vuex"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement"

export default {
  name: "PatientsDatatableTDPhone",
  components: {
    OutgoingCallElement
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    patient() {
      return this.object && this.object.patient ? this.object.patient : null
    },
    phone() {
      let p = this.patient?.mphone || ''
      if(!p && this.object.phone) {
        return this.object.phone
      }
      return p
    }
  },
}
</script>

<style scoped>

</style>
