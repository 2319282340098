export default {
    "1_month_ago": "менше 1 місяця",
    "3_months_ago": "менше 3 місяців",
    "6_months_ago": "менше 6 місяців",
    "12_months_ago": "менше 12 місяців",
    "above_1_month": "більше 1 місяця",
    "above_2_months": "більше 2 місяців",
    "above_3_months": "більше 3 місяців",
    "above_6_months": "більше 6 місяців",
    "above_1_year": "більше 1 року",
    add_patients: "Додати пацієнтів",
    add_standard: "Додати стандартні",
    after_service: "Після отримання послуги",
    amount_of_patients: "Кількість пацієнтів",
    are_you_sure_you_want_to_delete_mailing: "Ви впевнені, що хочете видалити розсилку?",
    birth_day: "Birth day",
    birth_month: "День народження",
    cannot_be_delivered: "Не може бути доставлено",
    channel: "Канал",
    channels: "Канали",
    complex_status_changed: "Зміна статусу комплексу із плану лікування",
    create_mailing: "Створити розсилку",
    does_not_contain: "Не містить",
    duplicate: "Копіювати",
    given_time: "Заданий час",
    has_future_visit: "Є візит на майбутні дати",
    has_tags: "Має мітки",
    has_treatment_plan_with_status: "Має план лікування зі статусом",
    has_visit_with_status: "Має візит зі статусом",
    immediately_after_creating: "Відразу після створення",
    launch_mailing: "Запустити розсилку",
    mailing: "Розсилка",
    mailing_date: "Дата розсилки",
    mailing_report: "Звіт по розсилкам",
    mailings: "Розсилки",
    marketing: "Маркетинг",
    marketing_mailing: "Розсилка",
    no_push_token: "Відсутня токен",
    not_enough_balance: "Недостатньо коштів на рахунку",
    of_days: "днів",
    of_hours: "годин",
    passed_since_last_visit: "Минуло з останнього візиту",
    plan_status_changed: "Змінено статус плану лікування",
    push_notification: "Push-повідомлення",
    push_notifications: "Push-повідомлення",
    ready: "Готово",
    retry: "Спробувати ще раз",
    save_filter: "Зберегти фільтр",
    send_again: "Надіслати повторно",
    send_date: "Дата відправки",
    send_info: "Інформація про відправку",
    sent_slash_error: "Відправлено / Помилка",
    since_last_visit: "Після останнього візиту",
    sms_message: "СМС-повідомлення",
    stop_mailing: "Зупинити розсилку",
    tags_for_marketing_mailing: "Теги для автоматичного виведення інформації:",
    time: "Час",
    tokens: "Токени",
    too_many_attempts: "Занадто багато спроб відправлення",
    total_in_group: "Всього в групі",
    treatment_plan_created: "Створено новий план лікування",
    visit_time_changed: "Час візиту перенесено",
    users_selected: "Обрано користувачів",
    wrong_credentials: "Невірні авторизовані дані сервісу відправки",
    wrong_phone_number: "Невірний номер телефону",

    january: "Січень",
    february: "Лютий",
    march: "Березень",
    april: "Квітень",
    may: "Травень",
    june: "Червень",
    july: "Липень",
    august: "Серпень",
    september: "Вересень",
    october: "Жовтень",
    november: "Листопад",
    december: "Грудень",
}
