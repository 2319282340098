<template>
  <div>
    <AddObject module="settings"
               btnclass="btn-themed-outline btn-themed-squared mt-2"/>
    <DataTable :options="options"
               class="mt-4"
               module="settings"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/field"
import AddObject from "@/components/parts/datatable/AddObject"
import { mapState } from "vuex"

export default {
  name: "SettingsFields",
  head() {
    return {
      title: this.$t('fields'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    })
  },
  components: {
    AddObject,
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  // beforeUnmount() {
  //   this.$store.dispatch('init')
  // }
}
</script>
