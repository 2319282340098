<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M25.0708 74.8998L22.9871 76.1696H19.5034L17.4034 74.8998L19.015 73.3533L16.9313 71.8068L18.8197 70.2603L16.5243 68.5672L18.3476 67.0207L16.0522 65.6207L18.0708 64.2045L16.0522 62.5928L17.7452 61.0463L15.515 59.6301L17.2731 58.1649L14.9778 56.6184L17.1266 55.0719L14.9778 53.4603L16.5243 52.1091L14.4406 50.5626L16.2638 49.0812L14.1801 47.5347L15.9871 45.7929L14.9778 44.1161V29.8557L16.8662 22.5301H22.9871" fill="#8889BE"/>
      <path d="M17.6635 74.8998L19.7472 76.1696H23.2147L25.331 74.8998L23.703 73.3533L25.803 71.8068L23.9147 70.2603L26.1937 68.5672L24.3868 67.0207L26.6658 65.6207L24.6472 64.2045L26.6658 62.5928L24.9891 61.0463L27.203 59.6301L25.4612 58.1649L27.7402 56.6184L25.5914 55.0719L27.7402 53.4603L26.1937 52.1091L28.2775 50.5626L26.4705 49.0812L28.5542 47.5347L26.7309 45.7929L27.7402 44.1161V29.8557L25.8682 22.5301H21.9612" fill="#8889BE"/>
      <path d="M2.42393 21.7193L4.73435 26.8022C5.90378 27.3765 7.12218 27.8451 8.37502 28.2025C11.4953 29.1949 14.8465 29.1949 17.9668 28.2025C21.0893 26.8022 25.7242 28.5525 31.2692 28.8886C32.9178 28.9916 34.5722 28.8308 36.1701 28.4125C37.724 27.9795 39.1922 27.2833 40.5109 26.3541C40.5109 26.0601 40.5809 25.8781 40.5949 25.836C41.6031 22.8815 43.0594 20.053 43.2134 15.3481C43.2974 12.5476 43.4234 8.50088 40.7489 5.3363C37.8364 1.89167 33.2996 1.38758 31.3392 1.13553C29.1889 0.724377 26.9716 0.829963 24.87 1.44359C24.0159 1.75165 19.9691 3.09589 18.7229 2.84385C18.0788 2.67582 17.7707 2.46577 17.7707 2.57779C17.7707 2.68982 9.9153 -1.11889 5.58851 1.34556C1.26172 3.81001 1.58378 5.67236 0.659611 11.0633C0.238289 14.7105 0.849598 18.4026 2.42393 21.7193Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M4.81957 27.3553C4.96069 35.8147 5.998 44.2352 7.91414 52.4759C10.9947 65.0782 11.3028 62.0396 13.1511 67.1226C14.9994 72.2055 31.1864 84.2337 26.1035 74.5299C22.3114 66.9314 20.5049 58.4965 20.8525 50.0115C21.0065 45.2366 23.793 46.1607 23.793 46.1607C23.793 46.1607 25.6834 46.6088 26.7616 50.8096C27.8267 54.1527 29.2739 57.3618 31.0744 60.3733C33.2791 63.8157 36.0761 66.8403 39.3359 69.307C44.0687 72.5556 44.7969 69.307 44.7969 69.307C42.6707 62.8905 41.2252 56.2682 40.4841 49.5494C39.8034 42.0461 39.8784 34.4935 40.7081 27.0052C39.3594 27.8973 37.8674 28.5509 36.2973 28.9376C34.6994 29.3559 33.045 29.5167 31.3964 29.4137C25.7954 29.0776 21.2166 27.3413 18.094 28.7276C14.9738 29.72 11.6225 29.72 8.50225 28.7276C7.236 28.3816 6.00346 27.9223 4.81957 27.3553Z" fill="#DEE1E9"/>-->
<!--      <path d="M2.42393 22.2054L4.73435 27.2883C5.90378 27.8626 7.12218 28.3312 8.37502 28.6886C11.4953 29.681 14.8465 29.681 17.9668 28.6886C21.0893 27.2883 25.7242 29.0386 31.2692 29.3747C32.9178 29.4776 34.5722 29.3169 36.1701 28.8986C37.724 28.4656 39.1922 27.7694 40.5109 26.8402C40.5109 26.5462 40.5809 26.3641 40.5949 26.3221C41.6031 23.3676 43.0594 20.5391 43.2134 15.8342C43.2974 13.0337 43.4234 8.98696 40.7489 5.82238C37.8364 2.37775 33.2996 1.87366 31.3392 1.62162C29.1889 1.21046 26.9716 1.31605 24.87 1.92967C24.0159 2.23773 19.9691 3.58198 18.7229 3.32993C18.0788 3.1619 17.7707 2.95186 17.7707 3.06388C17.7707 3.1759 9.9153 -0.632804 5.58851 1.83165C1.26172 4.2961 1.58378 6.15844 0.659611 11.5494C0.238289 15.1965 0.849598 18.8887 2.42393 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--      <path opacity="0.2" d="M9.2175 32.8928C9.21091 32.9439 9.21091 32.9955 9.2175 33.0466C9.81911 46.3389 10.9241 48.1624 12.2378 54.7536C13.5515 61.3448 21.532 74.0111 23.5578 75.4173C25.5836 76.8234 25.0802 75.4173 25.0802 75.4173C19.8131 69.606 16.6087 55.8522 16.6087 55.8522C16.6087 55.8522 15.6878 38.8798 16.1912 35.2986C16.6946 31.7174 20.8567 31.0363 23.5578 32.5742C26.2589 34.1122 27.6709 47.3605 27.6709 47.3605C28.3093 51.6668 30.9896 55.8522 36.388 63.6957C36.8668 63.9594 42.6791 69.4759 43.2561 68.9706C43.8332 68.4652 37.5175 61.4437 35.4672 57.1594C33.4995 52.7234 32.6041 47.965 32.8398 43.1971C32.8398 42.4391 32.8398 42.7686 33.4414 35.2986C33.5027 34.5736 33.5519 33.8705 33.601 33.2114C33.7729 31.0143 33.9079 29.1907 34.0061 27.8615C33.8599 27.1951 33.5521 26.5655 33.1045 26.017C32.6569 25.4685 32.0804 25.0144 31.4155 24.6867C30.7364 24.5074 30.0216 24.4636 29.3215 24.5585C28.6214 24.6535 27.9532 24.8847 27.3639 25.236C21.8881 27.8615 18.8432 26.0489 18.8432 26.0489C18.8432 26.0489 8.60362 19.4687 9.2175 32.8928Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M4.85863 27.0227C4.99975 35.482 6.03707 43.9026 7.9532 52.1433C11.0338 64.7456 11.3418 61.707 13.1902 66.7899C15.0385 71.8729 31.2255 83.9011 26.1425 74.1973C22.3504 66.5988 20.544 58.1639 20.8916 49.6788C21.0456 44.9039 23.8321 45.8281 23.8321 45.8281C23.8321 45.8281 25.7224 46.2762 26.8006 50.477C27.8658 53.8201 29.313 57.0292 31.1134 60.0407C33.3181 63.4831 36.1152 66.5076 39.3749 68.9743C44.1078 72.2229 44.8359 68.9743 44.8359 68.9743C42.7098 62.5578 41.2642 55.9356 40.5231 49.2167C39.8425 41.7135 39.9175 34.1608 40.7472 26.6726C39.3985 27.5646 37.9065 28.2183 36.3364 28.605C34.7385 29.0233 33.084 29.184 31.4355 29.081C25.8345 28.745 21.2556 27.0087 18.1331 28.3949C15.0128 29.3873 11.6615 29.3873 8.54131 28.3949C7.27506 28.0489 6.04253 27.5897 4.85863 27.0227Z" fill="#DEE1E9"/>
        <mask id="mask436" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
          <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask436)">
          <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M21.5633 18.7991C20.5012 18.7991 19.2556 13.5008 19.2556 3.90252L21.1787 2.04045L24.2556 0.799072C24.2556 10.3974 22.6254 18.7991 21.5633 18.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M9.28207 25.631C9.2755 25.6905 9.2755 25.7505 9.28207 25.8099C9.88188 41.2642 10.9836 43.3844 12.2933 51.0477C13.6031 58.711 21.5597 73.4376 23.5794 75.0725C25.5991 76.7073 25.0973 75.0725 25.0973 75.0725C19.8459 68.316 16.6511 52.3249 16.6511 52.3249C16.6511 52.3249 15.733 32.5919 16.2349 28.4282C16.7368 24.2644 20.8864 23.4726 23.5794 25.2607C26.2724 27.0488 27.6801 42.452 27.6801 42.452C28.3166 47.4587 30.9889 52.3249 36.3711 61.4443C36.8485 61.7508 42.6433 68.1647 43.2186 67.5772C43.794 66.9897 37.4973 58.826 35.453 53.8448C33.4913 48.6873 32.5985 43.1548 32.8335 37.6114C32.8335 36.7301 32.8335 37.1133 33.4333 28.4282C33.4945 27.5852 33.5435 26.7678 33.5924 26.0014C33.7638 23.447 33.8985 21.3268 33.9964 19.7814C33.8505 19.0066 33.5437 18.2745 33.0975 17.6368C32.6512 16.9991 32.0764 16.4711 31.4136 16.0902C30.7365 15.8817 30.0238 15.8308 29.3258 15.9412C28.6278 16.0515 27.9616 16.3204 27.3741 16.7288C21.9146 19.7814 18.8789 17.674 18.8789 17.674C18.8789 17.674 8.67003 10.0234 9.28207 25.631Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M4.81957 27.3553C4.96069 35.8147 5.998 44.2352 7.91414 52.4759C10.9947 65.0782 11.3028 62.0396 13.1511 67.1226C14.9994 72.2055 31.1864 84.2337 26.1035 74.5299C22.3114 66.9314 20.5049 58.4965 20.8525 50.0115C21.0065 45.2366 23.793 46.1607 23.793 46.1607C23.793 46.1607 25.6834 46.6088 26.7616 50.8096C27.8267 54.1527 29.2739 57.3618 31.0744 60.3733C33.2791 63.8157 36.0761 66.8403 39.3359 69.307C44.0687 72.5556 44.7969 69.307 44.7969 69.307C42.6707 62.8905 41.2252 56.2682 40.4841 49.5494C39.8034 42.0461 39.8784 34.4935 40.7081 27.0052C39.3594 27.8973 37.8674 28.5509 36.2973 28.9376C34.6994 29.3559 33.045 29.5167 31.3964 29.4137C25.7954 29.0776 21.2166 27.3413 18.094 28.7276C14.9738 29.72 11.6225 29.72 8.50225 28.7276C7.236 28.3816 6.00346 27.9223 4.81957 27.3553Z" fill="#DEE1E9"/>
      <path d="M2.42393 22.2054L4.73435 27.2883C5.90378 27.8626 7.12218 28.3312 8.37502 28.6886C11.4953 29.681 14.8465 29.681 17.9668 28.6886C21.0893 27.2883 25.7242 29.0386 31.2692 29.3747C32.9178 29.4776 34.5722 29.3169 36.1701 28.8986C37.724 28.4656 39.1922 27.7694 40.5109 26.8402C40.5109 26.5462 40.5809 26.3641 40.5949 26.3221C41.6031 23.3676 43.0594 20.5391 43.2134 15.8342C43.2974 13.0337 43.4234 8.98696 40.7489 5.82238C37.8364 2.37775 33.2996 1.87366 31.3392 1.62162C29.1889 1.21046 26.9716 1.31605 24.87 1.92967C24.0159 2.23773 19.9691 3.58198 18.7229 3.32993C18.0788 3.1619 17.7707 2.95186 17.7707 3.06388C17.7707 3.1759 9.9153 -0.632804 5.58851 1.83165C1.26172 4.2961 1.58378 6.15844 0.659611 11.5494C0.238289 15.1965 0.849598 18.8887 2.42393 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M9.2175 32.8928C9.21091 32.9439 9.21091 32.9955 9.2175 33.0466C9.81911 46.3389 10.9241 48.1624 12.2378 54.7536C13.5515 61.3448 21.532 74.0111 23.5578 75.4173C25.5836 76.8234 25.0802 75.4173 25.0802 75.4173C19.8131 69.606 16.6087 55.8522 16.6087 55.8522C16.6087 55.8522 15.6878 38.8798 16.1912 35.2986C16.6946 31.7174 20.8567 31.0363 23.5578 32.5742C26.2589 34.1122 27.6709 47.3605 27.6709 47.3605C28.3093 51.6668 30.9896 55.8522 36.388 63.6957C36.8668 63.9594 42.6791 69.4759 43.2561 68.9706C43.8332 68.4652 37.5175 61.4437 35.4672 57.1594C33.4995 52.7234 32.6041 47.965 32.8398 43.1971C32.8398 42.4391 32.8398 42.7686 33.4414 35.2986C33.5027 34.5736 33.5519 33.8705 33.601 33.2114C33.7729 31.0143 33.9079 29.1907 34.0061 27.8615C33.8599 27.1951 33.5521 26.5655 33.1045 26.017C32.6569 25.4685 32.0804 25.0144 31.4155 24.6867C30.7364 24.5074 30.0216 24.4636 29.3215 24.5585C28.6214 24.6535 27.9532 24.8847 27.3639 25.236C21.8881 27.8615 18.8432 26.0489 18.8432 26.0489C18.8432 26.0489 8.60362 19.4687 9.2175 32.8928Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="25" cy="77" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="44" cy="70" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M2.57571 21.4475L4.94214 26.5024C5.08263 34.9618 6.11995 43.3824 8.03671 51.623C11.1173 64.2253 11.4253 61.1867 13.2737 66.2697C15.122 71.3526 31.309 83.3948 26.226 73.677C22.4357 66.0829 20.6292 57.653 20.9751 49.1725C21.1291 44.3837 23.9156 45.3218 23.9156 45.3218C23.9156 45.3218 25.7639 45.7699 26.8421 49.9287C27.9073 53.2718 29.3545 56.4809 31.1549 59.4924C33.3596 62.9348 36.1567 65.9594 39.4164 68.4261C44.1493 71.6747 44.8774 68.4261 44.8774 68.4261C42.7328 62.0211 41.2684 55.4081 40.5086 48.6964C39.8247 41.1746 39.8997 33.6031 40.7327 26.0963C40.7327 25.8023 40.7327 25.6342 40.8167 25.5782C41.8249 22.6237 43.2811 19.7952 43.4352 15.0903C43.5192 12.2898 43.6452 8.24306 40.9707 5.07848C38.0582 1.64785 33.5214 1.12976 31.561 0.877716C29.4107 0.46656 27.1934 0.572141 25.0918 1.18577C24.2377 1.49382 20.1909 2.85207 18.9447 2.58602C18.3006 2.41799 17.9925 2.20796 17.9925 2.31998C17.9925 2.432 10.1371 -1.3767 5.8103 1.08775C1.48351 3.5522 1.80557 5.42855 0.881401 10.8055C0.440971 14.4415 1.02792 18.1281 2.57571 21.4475Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M2.57571 21.4475L4.94214 26.5024C5.08263 34.9618 6.11995 43.3824 8.03671 51.623C11.1173 64.2253 11.4253 61.1867 13.2737 66.2697C15.122 71.3526 31.309 83.3948 26.226 73.677C22.4357 66.0829 20.6292 57.653 20.9751 49.1725C21.1291 44.3837 23.9156 45.3218 23.9156 45.3218C23.9156 45.3218 25.7639 45.7699 26.8421 49.9287C27.9073 53.2718 29.3545 56.4809 31.1549 59.4924C33.3596 62.9348 36.1567 65.9594 39.4164 68.4261C44.1493 71.6747 44.8774 68.4261 44.8774 68.4261C42.7328 62.0211 41.2684 55.4081 40.5086 48.6964C39.8247 41.1746 39.8997 33.6031 40.7327 26.0963C40.7327 25.8023 40.7327 25.6342 40.8167 25.5782C41.8249 22.6237 43.2811 19.7952 43.4352 15.0903C43.5192 12.2898 43.6452 8.24306 40.9707 5.07848C38.0582 1.64785 33.5214 1.12976 31.561 0.877716C29.4107 0.46656 27.1934 0.572141 25.0918 1.18577C24.2377 1.49382 20.1909 2.85207 18.9447 2.58602C18.3006 2.41799 17.9925 2.20796 17.9925 2.31998C17.9925 2.432 10.1371 -1.3767 5.8103 1.08775C1.48351 3.5522 1.80557 5.42855 0.881401 10.8055C0.440971 14.4415 1.02792 18.1281 2.57571 21.4475Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M0.723747 3.11929C0.864864 11.5786 1.90218 19.9992 3.81831 28.2399C6.89888 40.8422 7.20693 37.8036 9.05527 42.8866C10.9036 47.9695 27.0906 59.9977 22.0076 50.2939C18.2155 42.6954 16.4091 34.2605 16.7567 25.7754C16.9107 21.0006 19.6972 21.9247 19.6972 21.9247C19.6972 21.9247 21.5876 22.3728 22.6658 26.5736C23.7309 29.9167 25.1781 33.1258 26.9785 36.1373C29.1833 39.5797 31.9803 42.6043 35.2401 45.071C39.9729 48.3195 40.701 45.071 40.701 45.071C38.5749 38.6545 37.1293 32.0322 36.3883 25.3133C35.7076 17.8101 35.7826 10.2575 36.6123 2.76923C36.1207 3.11929 30.6908 3.00911 29.1207 3.39581C27.5228 3.81415 23.7693 5.99878 22.1207 5.89581L14.1207 3.39581C11.0005 4.38823 5.12073 5.39581 5.12073 5.39581C3.85448 5.04983 1.90764 3.68629 0.723747 3.11929Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M5.12168 8.81059C5.11508 8.75949 5.11508 8.70789 5.12168 8.65679C5.02305 6.50012 5.20456 4.85976 5.57874 3.6255L9.19132 1.56521L12.9398 2.4979L17.1488 1L22.1913 2.4979L26.1207 1L29.9103 3.6255C29.8121 4.95473 29.677 6.77829 29.5051 8.97536C29.456 9.63448 29.4069 10.3376 29.3455 11.0626C28.7439 18.5326 28.7439 18.2031 28.7439 18.9611C28.5083 23.7289 29.4037 28.4874 31.3713 32.9234C33.4217 37.2077 39.7373 44.2292 39.1603 44.7346C38.5832 45.2399 32.771 39.7233 32.2922 39.4597C26.8937 31.6162 24.2135 27.4307 23.575 23.1245C23.575 23.1245 22.1631 9.87617 19.462 8.33823C16.7609 6.80028 12.5988 7.48136 12.0954 11.0626C11.592 14.6438 12.5128 31.6162 12.5128 31.6162C12.5128 31.6162 15.7173 45.37 20.9844 51.1813C20.9844 51.1813 21.4878 52.5874 19.462 51.1813C17.4362 49.7751 9.4557 37.1088 8.14199 30.5176C6.82828 23.9264 5.72328 22.1029 5.12168 8.81059Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M30.9629 9.73164C20.051 12.8436 14.182 12.121 4.0587 7.27752" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M5.22948 27.3166C5.3706 35.776 6.40792 44.1965 8.32405 52.4372C11.4046 65.0395 11.7127 62.001 13.561 67.0839C15.4093 72.1668 31.5963 84.195 26.5134 74.4912C22.7213 66.8927 20.9148 58.4578 21.2624 49.9728C21.4164 45.1979 24.2029 46.1221 24.2029 46.1221C24.2029 46.1221 26.0933 46.5701 27.1715 50.7709C28.2366 54.114 29.6838 57.3231 31.4843 60.3347C33.689 63.777 36.486 66.8016 39.7458 69.2683C44.4787 72.5169 45.2068 69.2683 45.2068 69.2683C43.0806 62.8518 41.6351 56.2295 40.894 49.5107C40.2133 42.0074 40.2884 34.4548 41.118 26.9666C39.7693 27.8586 38.2773 28.5122 36.7072 28.8989C35.1093 29.3172 33.4549 29.478 31.8063 29.375C26.2053 29.0389 21.6265 27.3026 18.5039 28.6889C15.3837 29.6813 12.0324 29.6813 8.91216 28.6889C7.64591 28.3429 6.41338 27.8836 5.22948 27.3166Z" fill="#DEE1E9"/>
      <path d="M5.14426 27.2496L2.83384 22.1667C1.25951 18.85 0.648201 15.1579 1.06952 11.5107C1.22334 10.6135 2.51451 12.814 2.62646 12.0931L3.81738 12.2315H7.72448L6.81738 9.58629L8.02496 7.1194L6.81738 4.80599L10.6265 3.29125V1C14.3912 1.28943 18.1806 3.103 18.1806 3.02519C18.1806 2.91317 18.4887 3.12321 19.1328 3.29125C20.379 3.54329 24.4258 2.19905 25.2799 1.89099C27.3815 1.27736 29.5988 1.17178 31.7491 1.58293C33.7095 1.83498 38.2463 2.33907 41.1588 5.7837C43.8289 8.94302 43.7077 12.9816 43.6237 15.7815L43.6233 15.7955C43.4693 20.5004 42.013 23.3289 41.0048 26.2834C40.9908 26.3255 40.9208 26.5075 40.9208 26.8015C39.6021 27.7307 38.1339 28.4269 36.58 28.8599C34.9821 29.2782 33.3277 29.439 31.6791 29.336C26.1341 28.9999 21.4993 27.2496 18.3767 28.6499C15.2564 29.6423 11.9052 29.6423 8.78493 28.6499C7.53209 28.2925 6.31369 27.8239 5.14426 27.2496Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M4.95069 23.1377C15.0497 19.1425 17.3627 14.6497 16.207 3.09315" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M9.62741 32.8541C9.62082 32.9052 9.62082 32.9568 9.62741 33.0079C10.229 46.3002 11.334 48.1237 12.6477 54.7149C13.9614 61.3061 21.9419 73.9724 23.9677 75.3786C25.9935 76.7847 25.4902 75.3786 25.4902 75.3786C20.223 69.5673 17.0186 55.8135 17.0186 55.8135C17.0186 55.8135 16.0977 38.8411 16.6011 35.2599C17.1045 31.6787 21.2666 30.9976 23.9677 32.5355C26.6688 34.0735 28.0808 47.3218 28.0808 47.3218C28.7192 51.6281 31.3995 55.8135 36.7979 63.657C37.2767 63.9207 43.089 69.4372 43.666 68.9319C44.2431 68.4266 37.9275 61.405 35.8771 57.1207C33.9095 52.6848 33.014 47.9263 33.2497 43.1584C33.2497 42.4004 33.2497 42.7299 33.8513 35.2599C33.9127 34.5349 33.9618 33.8318 34.0109 33.1727C34.1828 30.9756 34.3178 29.152 34.416 27.8228C34.2698 27.1564 33.962 26.5268 33.5144 25.9783C33.0668 25.4298 32.4903 24.9757 31.8255 24.648C31.1463 24.4687 30.4315 24.4249 29.7314 24.5198C29.0313 24.6148 28.3631 24.846 27.7738 25.1973C22.298 27.8228 19.2531 26.0102 19.2531 26.0102C19.2531 26.0102 9.01353 19.43 9.62741 32.8541Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M4.85363 27.4729C4.99475 35.9322 6.03206 44.3528 7.9482 52.5935C11.0288 65.1958 11.3368 62.1572 13.1852 67.2402C15.0335 72.3231 31.2204 84.3513 26.1375 74.6475C22.3454 67.049 20.539 58.6141 20.8866 50.129C21.0406 45.3541 23.8271 46.2783 23.8271 46.2783C23.8271 46.2783 25.7174 46.7264 26.7956 50.9272C27.8608 54.2703 29.3079 57.4794 31.1084 60.4909C33.3131 63.9333 36.1102 66.9579 39.3699 69.4246C44.1028 72.6732 44.8309 69.4246 44.8309 69.4246C42.7048 63.0081 41.2592 56.3858 40.5181 49.6669C39.8374 42.1637 39.9125 34.6111 40.7422 27.1228C39.3935 28.0148 38.2216 14.3866 36.6515 14.7733C35.0536 15.1916 33.3992 15.3524 31.7506 15.2494C26.1496 14.9133 21.5708 13.177 18.4482 14.5633C15.3279 15.5557 11.9767 15.5557 8.85643 14.5633C7.59018 14.2173 6.03752 28.0399 4.85363 27.4729Z" fill="#DEE1E9"/>
      <path d="M2.45799 22.3229L4.76841 27.4059C5.74878 27.8873 10.869 28.9574 13.2371 29.5014C13.7922 29.6289 14.3608 29.2868 14.4927 28.7327L16.7506 19.2494C21.3253 18.0192 23.8361 18.1092 28.2506 19.2494L30.51 28.7389C30.6414 29.2906 31.2043 29.6291 31.7559 29.4969C34.2281 28.9048 39.4212 27.7496 40.5449 26.9578C40.5449 26.6637 40.615 26.4817 40.629 26.4397C41.6371 23.4851 43.0934 20.6566 43.2474 15.9518C43.3315 13.1512 43.4575 9.10452 40.783 5.93994C37.8705 2.49531 33.3336 1.99122 31.3733 1.73917C29.2229 1.32801 27.0056 1.4336 24.9041 2.04723C24.0499 2.35528 20.0032 3.69953 18.757 3.44748C18.1129 3.27945 17.8048 3.06941 17.8048 3.18143C17.8048 3.29345 9.94936 -0.51525 5.62257 1.9492C1.29578 4.41365 1.61784 6.27599 0.693668 11.667C0.272346 15.3141 0.883659 19.0062 2.45799 22.3229Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M5.22948 27.2235C5.3706 35.6828 6.40792 44.1034 8.32405 52.3441C11.4046 64.9464 11.7127 61.9078 13.561 66.9908C15.4093 72.0737 31.5963 84.1019 26.5134 74.3981C22.7213 66.7996 20.9148 58.3647 21.2624 49.8796C21.4164 45.1047 24.2029 46.0289 24.2029 46.0289C24.2029 46.0289 26.0933 46.477 27.1715 50.6778C28.2366 54.0209 29.6838 57.23 31.4843 60.2415C33.689 63.6839 36.486 66.7085 39.7458 69.1752C44.4787 72.4238 45.2068 69.1752 45.2068 69.1752C43.0806 62.7587 41.6351 56.1364 40.894 49.4175C40.2133 41.9143 40.2884 34.3617 41.118 26.8734C39.7693 27.7655 38.5975 14.1372 37.0274 14.5239C35.4294 14.9422 33.775 15.103 32.1265 15C26.5254 14.6639 21.9466 12.9276 18.824 14.3139C15.7038 15.3063 12.3525 15.3063 9.23228 14.3139C7.96603 13.9679 6.41338 27.7905 5.22948 27.2235Z" fill="#DEE1E9"/>
      <path d="M2.83384 22.0735L5.14426 27.1565C6.12464 27.6379 11.2449 28.708 13.6129 29.252C14.1681 29.3795 14.7366 29.0374 14.8685 28.4833L17.1265 19C21.7012 17.7698 24.2119 17.8599 28.6265 19L30.8859 28.4895C31.0172 29.0412 31.5801 29.3797 32.1317 29.2475C34.6039 28.6554 39.797 27.5002 40.9208 26.7084C40.9208 26.4143 40.9908 26.2323 41.0048 26.1903C42.013 23.2358 43.4693 20.4072 43.6233 15.7024C43.7073 12.9019 43.8333 8.85513 41.1588 5.69055C38.2463 2.24592 33.7095 1.74183 31.7491 1.48978C29.5988 1.07862 27.3815 1.18421 25.2799 1.79784C24.4258 2.10589 20.379 3.45014 19.1328 3.19809C18.4887 3.03006 18.1806 2.82002 18.1806 2.93204C18.1806 3.04406 10.3252 -0.76464 5.99842 1.69981C1.67163 4.16426 1.99369 6.0266 1.06952 11.4176C0.648201 15.0647 1.25951 18.7568 2.83384 22.0735Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M4.85863 27.2235C4.99975 35.6828 6.03707 44.1034 7.9532 52.3441C11.0338 64.9464 11.3418 61.9078 13.1902 66.9908C15.0385 72.0737 31.2255 84.1019 26.1425 74.3981C22.3504 66.7996 20.544 58.3647 20.8916 49.8796C21.0456 45.1048 23.8321 46.0289 23.8321 46.0289C23.8321 46.0289 25.7224 46.477 26.8006 50.6778C27.8658 54.0209 29.3129 57.23 31.1134 60.2415C33.3181 63.6839 36.1152 66.7085 39.3749 69.1752C44.1078 72.4238 44.8359 69.1752 44.8359 69.1752C42.7098 62.7587 41.2642 56.1364 40.5231 49.4175C39.8425 41.9143 39.9175 34.3617 40.7472 26.8734C39.3985 27.7655 38.2266 14.1372 36.6565 14.5239C35.0586 14.9422 33.4042 15.103 31.7556 15C26.1546 14.6639 21.5758 12.9276 18.4532 14.3139C15.3329 15.3063 11.9817 15.3063 8.86143 14.3139C7.59518 13.9679 6.04253 27.7905 4.85863 27.2235Z" fill="#DEE1E9"/>
      <mask id="mask2036" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.724 27.7697 10.8442 28.8398 13.2123 29.3838C13.7675 29.5113 14.336 29.1692 14.4679 28.6151L16.7258 19.1318C21.3006 17.9017 23.8113 17.9917 28.2258 19.1318L30.4852 28.6213C30.6166 29.1731 31.1795 29.5115 31.7311 29.3794C34.2033 28.7872 39.3964 27.632 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858879 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2036)">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.724 27.7697 10.8442 28.8398 13.2123 29.3838C13.7675 29.5113 14.336 29.1692 14.4679 28.6151L16.7258 19.1318C21.3006 17.9017 23.8113 17.9917 28.2258 19.1318L30.4852 28.6213C30.6166 29.1731 31.1795 29.5115 31.7311 29.3794C34.2033 28.7872 39.3964 27.632 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858879 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.2556 43H34.2556V17.452C24.883 15.5258 19.6282 15.5062 10.2556 17.452V43Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M5.52453 27.0024C5.66565 35.4618 6.70296 43.8823 8.61909 52.123C11.6997 64.7253 12.0077 61.6867 13.8561 66.7697C15.7044 71.8526 31.8913 83.8808 26.8084 74.177C23.0163 66.5785 21.2099 58.1436 21.5575 49.6585C21.7115 44.8837 24.498 45.8078 24.498 45.8078C24.498 45.8078 26.3883 46.2559 27.4665 50.4567C28.5317 53.7998 29.9788 57.0089 31.7793 60.0204C33.984 63.4628 36.7811 66.4874 40.0408 68.9541C44.7737 72.2027 45.5018 68.9541 45.5018 68.9541C43.3757 62.5376 41.9301 55.9153 41.189 49.1965C40.5083 41.6932 40.5834 34.1406 41.4131 26.6523C40.0644 27.5444 38.5724 28.198 37.0023 28.5847C35.4044 29.003 33.7499 29.1637 32.1014 29.0608C26.5004 28.7247 21.9215 26.9884 18.799 28.3747C15.6787 29.3671 12.3274 29.3671 9.2072 28.3747C7.94096 28.0287 6.70842 27.5694 5.52453 27.0024Z" fill="#DEE1E9"/>
      <path d="M3.12889 21.8523L5.43931 26.9353C6.60874 27.5095 7.82714 27.9781 9.07997 28.3355C12.2002 29.328 15.5515 29.328 18.6717 28.3355C21.7943 26.9353 26.4291 28.6856 31.9742 29.0216C33.6227 29.1246 35.2771 28.9639 36.8751 28.5456C38.429 28.1125 39.8972 27.4163 41.2158 26.4872C41.2158 26.1931 41.2859 26.0111 41.2999 25.9691C42.308 23.0146 43.7643 20.186 43.9183 15.4812C44.0024 12.6807 44.1284 8.63393 41.4539 5.46936C38.5414 2.02473 34.0045 1.52064 32.0442 1.26859C29.8938 0.857434 27.6765 0.96302 25.575 1.57665C24.7208 1.8847 20.6741 3.22895 19.4279 2.9769C18.7837 2.80887 18.4757 2.59883 18.4757 2.71085C18.4757 2.82287 10.6203 -0.985831 6.29346 1.47862C1.96667 3.94307 2.28873 5.80541 1.36457 11.1964C0.943245 14.8435 1.55455 18.5357 3.12889 21.8523Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M9.92246 32.54C9.91586 32.5911 9.91586 32.6427 9.92246 32.6937C10.5241 45.986 11.6291 47.8096 12.9428 54.4008C14.2565 60.992 22.237 73.6583 24.2628 75.0644C26.2886 76.4705 25.7852 75.0644 25.7852 75.0644C20.5181 69.2532 17.3136 55.4993 17.3136 55.4993C17.3136 55.4993 16.3928 38.527 16.8962 34.9457C17.3996 31.3645 21.5617 30.6834 24.2628 32.2214C26.9639 33.7593 28.3758 47.0077 28.3758 47.0077C29.0142 51.3139 31.6945 55.4993 37.093 63.3429C37.5718 63.6065 43.384 69.1231 43.9611 68.6177C44.5381 68.1124 38.2225 61.0909 36.1721 56.8066C34.2045 52.3706 33.3091 47.6121 33.5447 42.8442C33.5447 42.0862 33.5447 42.4158 34.1463 34.9457C34.2077 34.2207 34.2568 33.5176 34.3059 32.8585C34.4778 30.6615 34.6129 28.8379 34.7111 27.5087C34.5648 26.8423 34.257 26.2126 33.8095 25.6641C33.3619 25.1156 32.7854 24.6615 32.1205 24.3339C31.4414 24.1546 30.7265 24.1108 30.0265 24.2057C29.3264 24.3006 28.6581 24.5319 28.0689 24.8832C22.593 27.5087 19.5482 25.6961 19.5482 25.6961C19.5482 25.6961 9.30857 19.1159 9.92246 32.54Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M10.7783 58.1306C14.9993 61.7881 17.3564 61.6214 21.5524 58.1306" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M31.0408 57.7493C35.4039 60.8718 37.8404 60.7295 42.1777 57.7493" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M4.85863 27.2236C4.99975 35.6829 6.03707 44.1035 7.9532 52.3442C11.0338 64.9465 11.3418 61.9079 13.1902 66.9909C15.0385 72.0738 31.2255 84.102 26.1425 74.3982C22.3504 66.7997 20.544 58.3648 20.8916 49.8797C21.0456 45.1049 23.8321 46.029 23.8321 46.029C23.8321 46.029 25.7224 46.4771 26.8006 50.6779C27.8658 54.021 29.313 57.2301 31.1134 60.2416C33.3181 63.684 36.1152 66.7086 39.3749 69.1753C44.1078 72.4239 44.8359 69.1753 44.8359 69.1753C42.7098 62.7588 41.2642 56.1365 40.5231 49.4177C39.8425 41.9144 39.9175 34.3618 40.7472 26.8735C39.3985 27.7656 37.9065 28.4192 36.3364 28.8059C34.7385 29.2242 33.084 29.3849 31.4355 29.282C25.8345 28.9459 21.2556 27.2096 18.1331 28.5958C15.0128 29.5883 11.6615 29.5883 8.54131 28.5958C7.27506 28.2499 6.04253 27.7906 4.85863 27.2236Z" fill="#DEE1E9"/>
      <mask id="mask2236" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.91306 27.8626 7.13146 28.3312 8.38429 28.6886C11.5045 29.681 14.8558 29.681 17.976 28.6886C21.0986 27.2883 25.7335 29.0386 31.2785 29.3747C32.927 29.4776 34.5815 29.3169 36.1794 28.8986C37.7333 28.4656 39.2015 27.7694 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858875 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2236)">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.91306 27.8626 7.13146 28.3312 8.38429 28.6886C11.5045 29.681 14.8558 29.681 17.976 28.6886C21.0986 27.2883 25.7335 29.0386 31.2785 29.3747C32.927 29.4776 34.5815 29.3169 36.1794 28.8986C37.7333 28.4656 39.2015 27.7694 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858875 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 19C20.5012 19 19.2556 13.7018 19.2556 4.10345L21.1787 2.24138L24.2556 1C24.2556 10.5983 22.6254 19 21.5633 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M9.28207 25.832C9.2755 25.8914 9.2755 25.9514 9.28207 26.0108C9.88188 41.4652 10.9836 43.5853 12.2933 51.2487C13.6031 58.912 21.5597 73.6386 23.5794 75.2734C25.5991 76.9082 25.0973 75.2734 25.0973 75.2734C19.8459 68.5169 16.6511 52.5259 16.6511 52.5259C16.6511 52.5259 15.733 32.7928 16.2349 28.6291C16.7368 24.4654 20.8864 23.6735 23.5794 25.4616C26.2724 27.2497 27.6801 42.653 27.6801 42.653C28.3166 47.6597 30.9889 52.5259 36.3711 61.6452C36.8485 61.9518 42.6433 68.3656 43.2186 67.7781C43.794 67.1906 37.4973 59.0269 35.453 54.0458C33.4913 48.8882 32.5985 43.3557 32.8335 37.8123C32.8335 36.931 32.8335 37.3142 33.4333 28.6291C33.4945 27.7861 33.5435 26.9687 33.5924 26.2024C33.7638 23.6479 33.8985 21.5277 33.9964 19.9823C33.8505 19.2075 33.5437 18.4755 33.0975 17.8377C32.6512 17.2 32.0764 16.6721 31.4136 16.2911C30.7365 16.0826 30.0238 16.0317 29.3258 16.1421C28.6278 16.2524 27.9616 16.5213 27.3741 16.9297C21.9146 19.9823 18.8789 17.8749 18.8789 17.8749C18.8789 17.8749 8.67003 10.2243 9.28207 25.832Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 43.2556 69)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 24.2556 76)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M4.85863 27.2236C4.99975 35.6829 6.03707 44.1035 7.9532 52.3442C11.0338 64.9465 11.3418 61.9079 13.1902 66.9909C15.0385 72.0738 31.2255 84.102 26.1425 74.3982C22.3504 66.7997 20.544 58.3648 20.8916 49.8797C21.0456 45.1049 23.8321 46.029 23.8321 46.029C23.8321 46.029 25.7224 46.4771 26.8006 50.6779C27.8658 54.021 29.313 57.2301 31.1134 60.2416C33.3181 63.684 36.1152 66.7086 39.3749 69.1753C44.1078 72.4239 44.8359 69.1753 44.8359 69.1753C42.7098 62.7588 41.2642 56.1365 40.5231 49.4177C39.8425 41.9144 39.9175 34.3618 40.7472 26.8735C39.3985 27.7656 37.9065 28.4192 36.3364 28.8059C34.7385 29.2242 33.084 29.3849 31.4355 29.282C25.8345 28.9459 21.2556 27.2096 18.1331 28.5958C15.0128 29.5883 11.6615 29.5883 8.54131 28.5958C7.27506 28.2499 6.04253 27.7906 4.85863 27.2236Z" fill="#DEE1E9"/>
      <mask id="mask2336" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.91306 27.8626 7.13146 28.3312 8.38429 28.6886C11.5045 29.681 14.8558 29.681 17.976 28.6886C21.0986 27.2883 25.7335 29.0386 31.2785 29.3747C32.927 29.4776 34.5815 29.3169 36.1794 28.8986C37.7333 28.4656 39.2015 27.7694 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858875 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2336)">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.91306 27.8626 7.13146 28.3312 8.38429 28.6886C11.5045 29.681 14.8558 29.681 17.976 28.6886C21.0986 27.2883 25.7335 29.0386 31.2785 29.3747C32.927 29.4776 34.5815 29.3169 36.1794 28.8986C37.7333 28.4656 39.2015 27.7694 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858875 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 19C20.5012 19 19.2556 13.7018 19.2556 4.10345L21.1787 2.24138L24.2556 1C24.2556 10.5983 22.6254 19 21.5633 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M9.28207 25.832C9.2755 25.8914 9.2755 25.9514 9.28207 26.0108C9.88188 41.4652 10.9836 43.5853 12.2933 51.2487C13.6031 58.912 21.5597 73.6386 23.5794 75.2734C25.5991 76.9082 25.0973 75.2734 25.0973 75.2734C19.8459 68.5169 16.6511 52.5259 16.6511 52.5259C16.6511 52.5259 15.733 32.7928 16.2349 28.6291C16.7368 24.4654 20.8864 23.6735 23.5794 25.4616C26.2724 27.2497 27.6801 42.653 27.6801 42.653C28.3166 47.6597 30.9889 52.5259 36.3711 61.6452C36.8485 61.9518 42.6433 68.3656 43.2186 67.7781C43.794 67.1906 37.4973 59.0269 35.453 54.0458C33.4913 48.8882 32.5985 43.3557 32.8335 37.8123C32.8335 36.931 32.8335 37.3142 33.4333 28.6291C33.4945 27.7861 33.5435 26.9687 33.5924 26.2024C33.7638 23.6479 33.8985 21.5277 33.9964 19.9823C33.8505 19.2075 33.5437 18.4755 33.0975 17.8377C32.6512 17.2 32.0764 16.6721 31.4136 16.2911C30.7365 16.0826 30.0238 16.0317 29.3258 16.1421C28.6278 16.2524 27.9616 16.5213 27.3741 16.9297C21.9146 19.9823 18.8789 17.8749 18.8789 17.8749C18.8789 17.8749 8.67003 10.2243 9.28207 25.832Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 43.2556 69)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 24.2556 76)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2336)">
        <path d="M24.2556 73L23.4327 76.7391L20.3989 74.4037L22.172 77.797L18.3468 77.9581L21.8862 79.4178L19.0595 82L22.7091 80.8431L22.2035 84.6382L24.2556 81.406L26.3077 84.6382L25.8022 80.8431L29.4518 82L26.6251 79.4178L30.1645 77.9581L26.3393 77.797L28.1123 74.4037L25.0785 76.7391L24.2556 73Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2336" x="18.1468" y="72.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M4.85863 27.2236C4.99975 35.6829 6.03707 44.1035 7.9532 52.3442C11.0338 64.9465 11.3418 61.9079 13.1902 66.9909C15.0385 72.0738 31.2255 84.102 26.1425 74.3982C22.3504 66.7997 20.544 58.3648 20.8916 49.8797C21.0456 45.1049 23.8321 46.029 23.8321 46.029C23.8321 46.029 25.7224 46.4771 26.8006 50.6779C27.8658 54.021 29.313 57.2301 31.1134 60.2416C33.3181 63.684 36.1152 66.7086 39.3749 69.1753C44.1078 72.4239 44.8359 69.1753 44.8359 69.1753C42.7098 62.7588 41.2642 56.1365 40.5231 49.4177C39.8425 41.9144 39.9175 34.3618 40.7472 26.8735C39.3985 27.7656 37.9065 28.4192 36.3364 28.8059C34.7385 29.2242 33.084 29.3849 31.4355 29.282C25.8345 28.9459 21.2556 27.2096 18.1331 28.5958C15.0128 29.5883 11.6615 29.5883 8.54131 28.5958C7.27506 28.2499 6.04253 27.7906 4.85863 27.2236Z" fill="#DEE1E9"/>
      <mask id="mask2336" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.91306 27.8626 7.13146 28.3312 8.38429 28.6886C11.5045 29.681 14.8558 29.681 17.976 28.6886C21.0986 27.2883 25.7335 29.0386 31.2785 29.3747C32.927 29.4776 34.5815 29.3169 36.1794 28.8986C37.7333 28.4656 39.2015 27.7694 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858875 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2336)">
        <path d="M2.43321 22.2054L4.74363 27.2883C5.91306 27.8626 7.13146 28.3312 8.38429 28.6886C11.5045 29.681 14.8558 29.681 17.976 28.6886C21.0986 27.2883 25.7335 29.0386 31.2785 29.3747C32.927 29.4776 34.5815 29.3169 36.1794 28.8986C37.7333 28.4656 39.2015 27.7694 40.5202 26.8402C40.5202 26.5462 40.5902 26.3641 40.6042 26.3221C41.6124 23.3676 43.0686 20.5391 43.2227 15.8342C43.3067 13.0337 43.4327 8.98696 40.7582 5.82238C37.8457 2.37775 33.3089 1.87366 31.3485 1.62162C29.1981 1.21046 26.9809 1.31605 24.8793 1.92967C24.0252 2.23773 19.9784 3.58198 18.7322 3.32993C18.0881 3.1619 17.78 2.95186 17.78 3.06388C17.78 3.1759 9.92458 -0.632804 5.59779 1.83165C1.271 4.2961 1.59306 6.15844 0.668888 11.5494C0.247566 15.1965 0.858875 18.8887 2.43321 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 19C20.5012 19 19.2556 13.7018 19.2556 4.10345L21.1787 2.24138L24.2556 1C24.2556 10.5983 22.6254 19 21.5633 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M9.28207 25.832C9.2755 25.8914 9.2755 25.9514 9.28207 26.0108C9.88188 41.4652 10.9836 43.5853 12.2933 51.2487C13.6031 58.912 21.5597 73.6386 23.5794 75.2734C25.5991 76.9082 25.0973 75.2734 25.0973 75.2734C19.8459 68.5169 16.6511 52.5259 16.6511 52.5259C16.6511 52.5259 15.733 32.7928 16.2349 28.6291C16.7368 24.4654 20.8864 23.6735 23.5794 25.4616C26.2724 27.2497 27.6801 42.653 27.6801 42.653C28.3166 47.6597 30.9889 52.5259 36.3711 61.6452C36.8485 61.9518 42.6433 68.3656 43.2186 67.7781C43.794 67.1906 37.4973 59.0269 35.453 54.0458C33.4913 48.8882 32.5985 43.3557 32.8335 37.8123C32.8335 36.931 32.8335 37.3142 33.4333 28.6291C33.4945 27.7861 33.5435 26.9687 33.5924 26.2024C33.7638 23.6479 33.8985 21.5277 33.9964 19.9823C33.8505 19.2075 33.5437 18.4755 33.0975 17.8377C32.6512 17.2 32.0764 16.6721 31.4136 16.2911C30.7365 16.0826 30.0238 16.0317 29.3258 16.1421C28.6278 16.2524 27.9616 16.5213 27.3741 16.9297C21.9146 19.9823 18.8789 17.8749 18.8789 17.8749C18.8789 17.8749 8.67003 10.2243 9.28207 25.832Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 43.2556 69)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 24.2556 76)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2336)">
        <path d="M24.2556 73L23.4327 76.7391L20.3989 74.4037L22.172 77.797L18.3468 77.9581L21.8862 79.4178L19.0595 82L22.7091 80.8431L22.2035 84.6382L24.2556 81.406L26.3077 84.6382L25.8022 80.8431L29.4518 82L26.6251 79.4178L30.1645 77.9581L26.3393 77.797L28.1123 74.4037L25.0785 76.7391L24.2556 73Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2336" x="18.1468" y="72.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M4.85863 27.0227C4.99975 35.482 6.03707 43.9026 7.9532 52.1433C11.0338 64.7456 11.3418 61.707 13.1902 66.7899C15.0385 71.8729 31.2255 83.9011 26.1425 74.1973C22.3504 66.5988 20.544 58.1639 20.8916 49.6788C21.0456 44.9039 23.8321 45.8281 23.8321 45.8281C23.8321 45.8281 25.7224 46.2762 26.8006 50.477C27.8658 53.8201 29.313 57.0292 31.1134 60.0407C33.3181 63.4831 36.1152 66.5076 39.3749 68.9743C44.1078 72.2229 44.8359 68.9743 44.8359 68.9743C42.7098 62.5578 41.2642 55.9356 40.5231 49.2167C39.8425 41.7135 39.9175 34.1608 40.7472 26.6726C39.3985 27.5646 37.9065 28.2183 36.3364 28.605C34.7385 29.0233 33.084 29.184 31.4355 29.081C25.8345 28.745 21.2556 27.0087 18.1331 28.3949C15.0128 29.3873 11.6615 29.3873 8.54131 28.3949C7.27506 28.0489 6.04253 27.5897 4.85863 27.0227Z" fill="#DEE1E9"/>
      <mask id="mask2536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
        <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2536)">
        <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.5633 18.7991C20.5012 18.7991 19.2556 13.5008 19.2556 3.90252L21.1787 2.04045L24.2556 0.799072C24.2556 10.3974 22.6254 18.7991 21.5633 18.7991Z" fill="#404040"/>
      </g>
      <circle r="4" transform="matrix(-1 0 0 1 24.2556 76)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2636" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="79">
        <path d="M4.98608 27.3553C5.12719 35.8147 6.16451 44.2352 8.08064 52.4759C11.1612 65.0782 11.4693 62.0396 13.3176 67.1226C15.1659 72.2055 31.3529 84.2337 26.27 74.5299C22.4779 66.9314 20.6714 58.4965 21.019 50.0115C21.173 45.2366 23.9595 46.1607 23.9595 46.1607C23.9595 46.1607 25.8499 46.6088 26.9281 50.8096C27.9932 54.1527 29.4404 57.3618 31.2409 60.3733C33.4456 63.8157 36.2426 66.8403 39.5024 69.307C44.2352 72.5556 44.9634 69.307 44.9634 69.307C42.8372 62.8905 41.3917 56.2682 40.6506 49.5494C39.9699 42.0461 40.045 34.4935 40.8746 27.0052C39.5259 27.8973 38.0339 28.5509 36.4638 28.9376C34.8659 29.3559 33.2115 29.5167 31.5629 29.4137C25.9619 29.0776 21.3831 27.3413 18.2605 28.7276C15.1403 29.72 11.789 29.72 8.66875 28.7276C7.4025 28.3816 6.16997 27.9223 4.98608 27.3553Z" fill="#DEE1E9"/>
        <path d="M2.59044 22.2054L4.90086 27.2883C6.07029 27.8626 7.28868 28.3312 8.54152 28.6886C11.6618 29.681 15.013 29.681 18.1333 28.6886C21.2558 27.2883 25.8907 29.0386 31.4357 29.3747C33.0843 29.4776 34.7387 29.3169 36.3366 28.8986C37.8905 28.4656 39.3587 27.7694 40.6774 26.8402C40.6774 26.5462 40.7474 26.3641 40.7614 26.3221C41.7696 23.3676 43.2259 20.5391 43.3799 15.8342C43.4639 13.0337 43.5899 8.98696 40.9154 5.82238C38.0029 2.37775 33.4661 1.87366 31.5057 1.62162C29.3554 1.21046 27.1381 1.31605 25.0365 1.92967C24.1824 2.23773 20.1356 3.58198 18.8894 3.32993C18.2453 3.1619 17.9372 2.95186 17.9372 3.06388C17.9372 3.1759 10.0818 -0.632804 5.75501 1.83165C1.42822 4.2961 1.75028 6.15844 0.826115 11.5494C0.404793 15.1965 1.0161 18.8887 2.59044 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2636)">
        <path d="M4.98608 27.3553C5.12719 35.8147 6.16451 44.2352 8.08064 52.4759C11.1612 65.0782 11.4693 62.0396 13.3176 67.1226C15.1659 72.2055 31.3529 84.2337 26.27 74.5299C22.4779 66.9314 20.6714 58.4965 21.019 50.0115C21.173 45.2366 23.9595 46.1607 23.9595 46.1607C23.9595 46.1607 25.8499 46.6088 26.9281 50.8096C27.9932 54.1527 29.4404 57.3618 31.2409 60.3733C33.4456 63.8157 36.2426 66.8403 39.5024 69.307C44.2352 72.5556 44.9634 69.307 44.9634 69.307C42.8372 62.8905 41.3917 56.2682 40.6506 49.5494C39.9699 42.0461 40.045 34.4935 40.8746 27.0052C39.5259 27.8973 38.0339 28.5509 36.4638 28.9376C34.8659 29.3559 33.2115 29.5167 31.5629 29.4137C25.9619 29.0776 21.3831 27.3413 18.2605 28.7276C15.1403 29.72 11.789 29.72 8.66875 28.7276C7.4025 28.3816 6.16997 27.9223 4.98608 27.3553Z" fill="#DEE1E9"/>
        <path d="M2.59044 22.2054L4.90086 27.2883C6.07029 27.8626 7.28868 28.3312 8.54152 28.6886C11.6618 29.681 15.013 29.681 18.1333 28.6886C21.2558 27.2883 25.8907 29.0386 31.4357 29.3747C33.0843 29.4776 34.7387 29.3169 36.3366 28.8986C37.8905 28.4656 39.3587 27.7694 40.6774 26.8402C40.6774 26.5462 40.7474 26.3641 40.7614 26.3221C41.7696 23.3676 43.2259 20.5391 43.3799 15.8342C43.4639 13.0337 43.5899 8.98696 40.9154 5.82238C38.0029 2.37775 33.4661 1.87366 31.5057 1.62162C29.3554 1.21046 27.1381 1.31605 25.0365 1.92967C24.1824 2.23773 20.1356 3.58198 18.8894 3.32993C18.2453 3.1619 17.9372 2.95186 17.9372 3.06388C17.9372 3.1759 10.0818 -0.632804 5.75501 1.83165C1.42822 4.2961 1.75028 6.15844 0.826115 11.5494C0.404793 15.1965 1.0161 18.8887 2.59044 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M31.9893 19.6762H43.9105C41.7484 25.046 41.3914 28.3063 41.6879 34.0446C41.6991 34.2609 41.4497 34.3955 41.2595 34.2689C40.2554 33.601 37.8972 31.7849 37.3256 29.1432C36.6331 25.9432 34.6299 25.1661 34.4173 25.092C34.4044 25.0875 34.3928 25.083 34.3804 25.0773L33.1975 24.5307C33.0529 24.4639 32.9943 24.3014 33.0666 24.1678L33.5955 23.1901C33.6402 23.1074 33.6362 23.0092 33.5847 22.9299L31.7383 20.0858C31.6213 19.9056 31.7618 19.6762 31.9893 19.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.9144 33.155C39.8534 32.3071 38.4454 30.8702 38.043 29.0106C37.2998 25.5761 35.1238 24.6201 34.6909 24.4648L33.9173 24.1073L34.2499 23.4925C34.4065 23.203 34.3922 22.8596 34.2121 22.5821L32.7646 20.3524H42.8672C41.0904 25.0171 40.7144 28.2129 40.9144 33.155ZM34.7076 24.4725C34.7077 24.4726 34.7074 24.4724 34.7076 24.4725V24.4725ZM40.8321 34.8178C41.5609 35.3027 42.4567 34.7463 42.4187 34.0123C42.1259 28.3462 42.4751 25.18 44.5962 19.9121L44.9634 19H31.9893C31.1931 19 30.7013 19.8028 31.1109 20.4338L32.8333 23.0869L32.4122 23.8654C32.1592 24.333 32.3643 24.9017 32.8702 25.1355L34.0532 25.6822C34.0939 25.701 34.1297 25.7145 34.1593 25.7248C34.1959 25.7376 35.9767 26.3577 36.6081 29.2758C37.2376 32.1849 39.801 34.1319 40.8321 34.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask136" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="79">
          <path d="M4.98608 27.3553C5.12719 35.8147 6.16451 44.2352 8.08064 52.4759C11.1612 65.0782 11.4693 62.0396 13.3176 67.1226C15.1659 72.2055 31.3529 84.2337 26.27 74.5299C22.4779 66.9314 20.6714 58.4965 21.019 50.0115C21.173 45.2366 23.9595 46.1607 23.9595 46.1607C23.9595 46.1607 25.8499 46.6088 26.9281 50.8096C27.9932 54.1527 29.4404 57.3618 31.2409 60.3733C33.4456 63.8157 36.2426 66.8403 39.5024 69.307C44.2352 72.5556 44.9634 69.307 44.9634 69.307C42.8372 62.8905 41.3917 56.2682 40.6506 49.5494C39.9699 42.0461 40.045 34.4935 40.8746 27.0052C39.5259 27.8973 38.0339 28.5509 36.4638 28.9376C34.8659 29.3559 33.2115 29.5167 31.5629 29.4137C25.9619 29.0776 21.3831 27.3413 18.2605 28.7276C15.1403 29.72 11.789 29.72 8.66875 28.7276C7.4025 28.3816 6.16997 27.9223 4.98608 27.3553Z" fill="#DEE1E9"/>
          <path d="M2.59044 22.2054L4.90086 27.2883C6.07029 27.8626 7.28868 28.3312 8.54152 28.6886C11.6618 29.681 15.013 29.681 18.1333 28.6886C21.2558 27.2883 25.8907 29.0386 31.4357 29.3747C33.0843 29.4776 34.7387 29.3169 36.3366 28.8986C37.8905 28.4656 39.3587 27.7694 40.6774 26.8402C40.6774 26.5462 40.7474 26.3641 40.7614 26.3221C41.7696 23.3676 43.2259 20.5391 43.3799 15.8342C43.4639 13.0337 43.5899 8.98696 40.9154 5.82238C38.0029 2.37775 33.4661 1.87366 31.5057 1.62162C29.3554 1.21046 27.1381 1.31605 25.0365 1.92967C24.1824 2.23773 20.1356 3.58198 18.8894 3.32993C18.2453 3.1619 17.9372 2.95186 17.9372 3.06388C17.9372 3.1759 10.0818 -0.632804 5.75501 1.83165C1.42822 4.2961 1.75028 6.15844 0.826115 11.5494C0.404793 15.1965 1.0161 18.8887 2.59044 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask136)">
          <path d="M4.98608 27.3553C5.12719 35.8147 6.16451 44.2352 8.08064 52.4759C11.1612 65.0782 11.4693 62.0396 13.3176 67.1226C15.1659 72.2055 31.3529 84.2337 26.27 74.5299C22.4779 66.9314 20.6714 58.4965 21.019 50.0115C21.173 45.2366 23.9595 46.1607 23.9595 46.1607C23.9595 46.1607 25.8499 46.6088 26.9281 50.8096C27.9932 54.1527 29.4404 57.3618 31.2409 60.3733C33.4456 63.8157 36.2426 66.8403 39.5024 69.307C44.2352 72.5556 44.9634 69.307 44.9634 69.307C42.8372 62.8905 41.3917 56.2682 40.6506 49.5494C39.9699 42.0461 40.045 34.4935 40.8746 27.0052C39.5259 27.8973 38.0339 28.5509 36.4638 28.9376C34.8659 29.3559 33.2115 29.5167 31.5629 29.4137C25.9619 29.0776 21.3831 27.3413 18.2605 28.7276C15.1403 29.72 11.789 29.72 8.66875 28.7276C7.4025 28.3816 6.16997 27.9223 4.98608 27.3553Z" fill="#DEE1E9"/>
          <path d="M2.59044 22.2054L4.90086 27.2883C6.07029 27.8626 7.28868 28.3312 8.54152 28.6886C11.6618 29.681 15.013 29.681 18.1333 28.6886C21.2558 27.2883 25.8907 29.0386 31.4357 29.3747C33.0843 29.4776 34.7387 29.3169 36.3366 28.8986C37.8905 28.4656 39.3587 27.7694 40.6774 26.8402C40.6774 26.5462 40.7474 26.3641 40.7614 26.3221C41.7696 23.3676 43.2259 20.5391 43.3799 15.8342C43.4639 13.0337 43.5899 8.98696 40.9154 5.82238C38.0029 2.37775 33.4661 1.87366 31.5057 1.62162C29.3554 1.21046 27.1381 1.31605 25.0365 1.92967C24.1824 2.23773 20.1356 3.58198 18.8894 3.32993C18.2453 3.1619 17.9372 2.95186 17.9372 3.06388C17.9372 3.1759 10.0818 -0.632804 5.75501 1.83165C1.42822 4.2961 1.75028 6.15844 0.826115 11.5494C0.404793 15.1965 1.0161 18.8887 2.59044 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M36.097 20.5H44.2438C42.7663 24.4705 42.5223 26.8811 42.725 31.1241C42.7326 31.2841 42.5622 31.3836 42.4322 31.29C41.746 30.7961 40.1345 29.4532 39.7438 27.5C39.2706 25.1339 37.9016 24.5593 37.7563 24.5045C37.7475 24.5012 37.7396 24.4978 37.7311 24.4936L36.9227 24.0894C36.8239 24.04 36.7838 23.9199 36.8332 23.8211L37.1947 23.0982C37.2253 23.037 37.2225 22.9645 37.1873 22.9058L35.9255 20.8029C35.8456 20.6696 35.9416 20.5 36.097 20.5Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M42.1964 30.4664C41.4713 29.8394 40.5091 28.777 40.2341 27.4019C39.7262 24.8625 38.2392 24.1555 37.9433 24.0407L37.4147 23.7764L37.642 23.3218C37.749 23.1077 37.7392 22.8538 37.6161 22.6486L36.6269 21H43.5309C42.3167 24.4491 42.0597 26.8121 42.1964 30.4664ZM37.9547 24.0464C37.9548 24.0465 37.9546 24.0464 37.9547 24.0464V24.0464ZM42.1401 31.6958C42.6382 32.0544 43.2503 31.643 43.2244 31.1003C43.0243 26.9106 43.263 24.5695 44.7124 20.6744L44.9634 20H36.0971C35.553 20 35.2169 20.5936 35.4968 21.0601L36.6739 23.0219L36.3861 23.5975C36.2132 23.9433 36.3533 24.3638 36.6991 24.5367L37.5075 24.9409C37.5353 24.9548 37.5598 24.9648 37.58 24.9724C37.605 24.9818 38.822 25.4403 39.2535 27.5981C39.6837 29.749 41.4355 31.1887 42.1401 31.6958Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M4.85863 27.0227C4.99975 35.482 6.03707 43.9026 7.9532 52.1433C11.0338 64.7456 11.3418 61.707 13.1902 66.7899C15.0385 71.8729 31.2255 83.9011 26.1425 74.1973C22.3504 66.5988 20.544 58.1639 20.8916 49.6788C21.0456 44.9039 23.8321 45.8281 23.8321 45.8281C23.8321 45.8281 25.7224 46.2762 26.8006 50.477C27.8658 53.8201 29.313 57.0292 31.1134 60.0407C33.3181 63.4831 36.1152 66.5076 39.3749 68.9743C44.1078 72.2229 44.8359 68.9743 44.8359 68.9743C42.7098 62.5578 41.2642 55.9356 40.5231 49.2167C39.8425 41.7135 39.9175 34.1608 40.7472 26.6726C39.3985 27.5646 37.9065 28.2183 36.3364 28.605C34.7385 29.0233 33.084 29.184 31.4355 29.081C25.8345 28.745 21.2556 27.0087 18.1331 28.3949C15.0128 29.3873 11.6615 29.3873 8.54131 28.3949C7.27506 28.0489 6.04253 27.5897 4.85863 27.0227Z" fill="#DEE1E9"/>
        <mask id="mask236" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
          <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask236)">
          <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M20.9095 16C24.5206 16 29.2556 8.53185 29.2556 0L22.2172 1.10345L11.7556 0C11.7556 8.53185 17.2984 16 20.9095 16Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M9.28207 23.3236C9.2755 23.3859 9.2755 23.4489 9.28207 23.5113C9.88188 39.7384 10.9836 41.9646 12.2933 50.0111C13.6031 58.0576 21.5597 73.5205 23.5794 75.2371C25.5991 76.9537 25.0973 75.2371 25.0973 75.2371C19.8459 68.1428 16.6511 51.3522 16.6511 51.3522C16.6511 51.3522 15.733 30.6325 16.2349 26.2605C16.7368 21.8886 20.8864 21.0572 23.5794 22.9347C26.2724 24.8122 27.6801 40.9856 27.6801 40.9856C28.3166 46.2426 30.9889 51.3522 36.3711 60.9275C36.8485 61.2493 42.6433 67.9839 43.2186 67.367C43.794 66.7501 37.4973 58.1783 35.453 52.9481C33.4913 47.5327 32.5985 41.7235 32.8335 35.9029C32.8335 34.9776 32.8335 35.3799 33.4333 26.2605C33.4945 25.3754 33.5435 24.5171 33.5924 23.7125C33.7638 21.0303 33.8985 18.8041 33.9964 17.1814C33.8505 16.3679 33.5437 15.5992 33.0975 14.9296C32.6512 14.26 32.0764 13.7057 31.4136 13.3057C30.7365 13.0868 30.0238 13.0333 29.3258 13.1492C28.6278 13.2651 27.9616 13.5474 27.3741 13.9762C21.9146 17.1814 18.8789 14.9686 18.8789 14.9686C18.8789 14.9686 8.67003 6.93556 9.28207 23.3236Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M4.85863 27.0227C4.99975 35.482 6.03707 43.9026 7.9532 52.1433C11.0338 64.7456 11.3418 61.707 13.1902 66.7899C15.0385 71.8729 31.2255 83.9011 26.1425 74.1973C22.3504 66.5988 20.544 58.1639 20.8916 49.6788C21.0456 44.9039 23.8321 45.8281 23.8321 45.8281C23.8321 45.8281 25.7224 46.2762 26.8006 50.477C27.8658 53.8201 29.313 57.0292 31.1134 60.0407C33.3181 63.4831 36.1152 66.5076 39.3749 68.9743C44.1078 72.2229 44.8359 68.9743 44.8359 68.9743C42.7098 62.5578 41.2642 55.9356 40.5231 49.2167C39.8425 41.7135 39.9175 34.1608 40.7472 26.6726C39.3985 27.5646 37.9065 28.2183 36.3364 28.605C34.7385 29.0233 33.084 29.184 31.4355 29.081C25.8345 28.745 21.2556 27.0087 18.1331 28.3949C15.0128 29.3873 11.6615 29.3873 8.54131 28.3949C7.27506 28.0489 6.04253 27.5897 4.85863 27.0227Z" fill="#DEE1E9"/>
        <mask id="mask336" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
          <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask336)">
          <path d="M2.43321 22.0044L4.74363 27.0874C5.91306 27.6616 7.13146 28.1302 8.38429 28.4876C11.5045 29.4801 14.8558 29.4801 17.976 28.4876C21.0986 27.0874 25.7335 28.8377 31.2785 29.1737C32.927 29.2767 34.5815 29.116 36.1794 28.6977C37.7333 28.2646 39.2015 27.5684 40.5202 26.6393C40.5202 26.3452 40.5902 26.1632 40.6042 26.1212C41.6124 23.1667 43.0686 20.3381 43.2227 15.6333C43.3067 12.8328 43.4327 8.78603 40.7582 5.62146C37.8457 2.17683 33.3089 1.67274 31.3485 1.42069C29.1981 1.00953 26.9809 1.11512 24.8793 1.72875C24.0252 2.0368 19.9784 3.38105 18.7322 3.129C18.0881 2.96097 17.78 2.75093 17.78 2.86295C17.78 2.97497 9.92458 -0.833731 5.59779 1.63072C1.271 4.09517 1.59306 5.95751 0.668888 11.3485C0.247566 14.9956 0.858875 18.6878 2.43321 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M21.5633 18.7991C20.5012 18.7991 19.2556 13.5008 19.2556 3.90252L21.1787 2.04045L24.2556 0.799072C24.2556 10.3974 22.6254 18.7991 21.5633 18.7991Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M5.60302 27.3554C5.74414 35.8148 6.78145 44.2353 8.69759 52.476C11.7781 65.0783 12.0862 62.0398 13.9345 67.1227C15.7829 72.2056 31.9698 84.2338 26.8869 74.5301C23.0948 66.9316 21.2884 58.4966 21.636 50.0116C21.79 45.2367 24.5765 46.1609 24.5765 46.1609C24.5765 46.1609 26.4668 46.609 27.545 50.8097C28.6102 54.1528 30.0573 57.362 31.8578 60.3735C34.0625 63.8158 36.8596 66.8404 40.1193 69.3071C44.8522 72.5557 45.5803 69.3071 45.5803 69.3071C43.4542 62.8906 42.0086 56.2683 41.2675 49.5495C40.5868 42.0462 40.6619 34.4936 41.4916 27.0054C40.1429 27.8974 38.6509 28.551 37.0808 28.9377C35.4829 29.3561 33.8284 29.5168 32.1799 29.4138C26.5788 29.0777 22 27.3414 18.8774 28.7277C15.7572 29.7201 12.4059 29.7201 9.28569 28.7277C8.01945 28.3817 6.78691 27.9224 5.60302 27.3554Z" fill="#DEE1E9"/>
        <path d="M3.20738 22.2054L5.5178 27.2883C6.68723 27.8626 7.90563 28.3312 9.15846 28.6886C12.2787 29.681 15.63 29.681 18.7502 28.6886C21.8728 27.2883 26.5076 29.0386 32.0526 29.3747C33.7012 29.4776 35.3556 29.3169 36.9535 28.8986C38.5075 28.4656 39.9757 27.7694 41.2943 26.8402C41.2943 26.5462 41.3644 26.3641 41.3784 26.3221C42.3865 23.3676 43.8428 20.5391 43.9968 15.8342C44.0808 13.0337 44.2069 8.98696 41.5324 5.82238C38.6199 2.37775 34.083 1.87366 32.1227 1.62162C29.9723 1.21046 27.755 1.31605 25.6535 1.92967C24.7993 2.23773 20.7526 3.58198 19.5064 3.32993C18.8622 3.1619 18.5542 2.95186 18.5542 3.06388C18.5542 3.1759 10.6987 -0.632804 6.37196 1.83165C2.04517 4.2961 2.36723 6.15844 1.44306 11.5494C1.02174 15.1965 1.63305 18.8887 3.20738 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M9 1.13184L7.13333 3.6327L5.5 2.13699V5.45104L2.93782 4.88312L4.33333 6.63373L2 8.63441L4.33333 10.6351L2.93782 12.3857L5.26667 11.6354L5.5 15.1318L7.7499 13.6348L8.53333 14.6365L10.2501 13.6348L12.5 15.1318L13.2 13.136L15.0622 12.3857L14.6 9.63476L16 8.63441L13.6667 7.1339L15.0622 4.88312L13.2 4.63304L12.5 2.13699L9.46667 4.13287L9 1.13184Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M5.60302 27.3554C5.74414 35.8148 6.78145 44.2353 8.69759 52.476C11.7781 65.0783 12.0862 62.0398 13.9345 67.1227C15.7829 72.2056 31.9698 84.2338 26.8869 74.5301C23.0948 66.9316 21.2884 58.4966 21.636 50.0116C21.79 45.2367 24.5765 46.1609 24.5765 46.1609C24.5765 46.1609 26.4668 46.609 27.545 50.8097C28.6102 54.1528 30.0573 57.362 31.8578 60.3735C34.0625 63.8158 36.8596 66.8404 40.1193 69.3071C44.8522 72.5557 45.5803 69.3071 45.5803 69.3071C43.4542 62.8906 42.0086 56.2683 41.2675 49.5495C40.5868 42.0462 40.6619 34.4936 41.4916 27.0054C40.1429 27.8974 38.6509 28.551 37.0808 28.9377C35.4829 29.3561 33.8284 29.5168 32.1799 29.4138C26.5788 29.0777 22 27.3414 18.8774 28.7277C15.7572 29.7201 12.4059 29.7201 9.28569 28.7277C8.01945 28.3817 6.78691 27.9224 5.60302 27.3554Z" fill="#DEE1E9"/>
        <path d="M3.20738 22.2054L5.5178 27.2883C6.68723 27.8626 7.90563 28.3312 9.15846 28.6886C12.2787 29.681 15.63 29.681 18.7502 28.6886C21.8728 27.2883 26.5076 29.0386 32.0526 29.3747C33.7012 29.4776 35.3556 29.3169 36.9535 28.8986C38.5075 28.4656 39.9757 27.7694 41.2943 26.8402C41.2943 26.5462 41.3644 26.3641 41.3784 26.3221C42.3865 23.3676 43.8428 20.5391 43.9968 15.8342C44.0808 13.0337 44.2069 8.98696 41.5324 5.82238C38.6199 2.37775 34.083 1.87366 32.1227 1.62162C29.9723 1.21046 27.755 1.31605 25.6535 1.92967C24.7993 2.23773 20.7526 3.58198 19.5064 3.32993C18.8622 3.1619 18.5542 2.95186 18.5542 3.06388C18.5542 3.1759 10.6987 -0.632804 6.37196 1.83165C2.04517 4.2961 2.36723 6.15844 1.44306 11.5494C1.02174 15.1965 1.63305 18.8887 3.20738 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M8 1.13184L6.4 3.27543L5 1.9934V4.83401L2.80385 4.34723L4 5.84774L2 7.56262L4 9.27749L2.80385 10.778L4.8 10.1349L5 13.1318L6.92849 11.8487L7.6 12.7072L9.07151 11.8487L11 13.1318L11.6 11.4211L13.1962 10.778L12.8 8.42005L14 7.56262L12 6.27646L13.1962 4.34723L11.6 4.13287L11 1.9934L8.4 3.70415L8 1.13184Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M5.60302 27.3554C5.74414 35.8148 6.78145 44.2353 8.69759 52.476C11.7781 65.0783 12.0862 62.0398 13.9345 67.1227C15.7829 72.2056 31.9698 84.2338 26.8869 74.5301C23.0948 66.9316 21.2884 58.4966 21.636 50.0116C21.79 45.2367 24.5765 46.1609 24.5765 46.1609C24.5765 46.1609 26.4668 46.609 27.545 50.8097C28.6102 54.1528 30.0573 57.362 31.8578 60.3735C34.0625 63.8158 36.8596 66.8404 40.1193 69.3071C44.8522 72.5557 45.5803 69.3071 45.5803 69.3071C43.4542 62.8906 42.0086 56.2683 41.2675 49.5495C40.5868 42.0462 40.6619 34.4936 41.4916 27.0054C40.1429 27.8974 38.6509 28.551 37.0808 28.9377C35.4829 29.3561 33.8284 29.5168 32.1799 29.4138C26.5788 29.0777 22 27.3414 18.8774 28.7277C15.7572 29.7201 12.4059 29.7201 9.28569 28.7277C8.01945 28.3817 6.78691 27.9224 5.60302 27.3554Z" fill="#DEE1E9"/>
        <path d="M3.20738 22.2054L5.5178 27.2883C6.68723 27.8626 7.90563 28.3312 9.15846 28.6886C12.2787 29.681 15.63 29.681 18.7502 28.6886C21.8728 27.2883 26.5076 29.0386 32.0526 29.3747C33.7012 29.4776 35.3556 29.3169 36.9535 28.8986C38.5075 28.4656 39.9757 27.7694 41.2943 26.8402C41.2943 26.5462 41.3644 26.3641 41.3784 26.3221C42.3865 23.3676 43.8428 20.5391 43.9968 15.8342C44.0808 13.0337 44.2069 8.98696 41.5324 5.82238C38.6199 2.37775 34.083 1.87366 32.1227 1.62162C29.9723 1.21046 27.755 1.31605 25.6535 1.92967C24.7993 2.23773 20.7526 3.58198 19.5064 3.32993C18.8622 3.1619 18.5542 2.95186 18.5542 3.06388C18.5542 3.1759 10.6987 -0.632804 6.37196 1.83165C2.04517 4.2961 2.36723 6.15844 1.44306 11.5494C1.02174 15.1965 1.63305 18.8887 3.20738 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M7.5 2.13184L6.56667 3.38227L5.75 2.63441V4.29144L4.46891 4.00748L5.16667 4.88278L4 5.88312L5.16667 6.88347L4.46891 7.75877L5.63333 7.38364L5.75 9.13184L6.87495 8.38332L7.26667 8.88416L8.12505 8.38332L9.25 9.13184L9.6 8.1339L10.5311 7.75877L10.3 6.3833L11 5.88312L9.83333 5.13287L10.5311 4.00748L9.6 3.88244L9.25 2.63441L7.73333 3.63235L7.5 2.13184Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M26.2349 70.6565L24.1511 71.9263H20.6674L18.5674 70.6565L20.1791 69.11L18.0953 67.5635L19.9837 66.017L17.6884 64.324L19.5116 62.7775L17.2163 61.3775L19.2349 59.9612L17.2163 58.3496L18.9093 56.803L16.6791 55.3868L18.4372 53.9217L16.1419 52.3751L18.2907 50.8286L16.1419 49.217L17.6884 47.8659L15.6047 46.3193L17.4279 44.838L15.3442 43.2914L17.1512 41.5496L16.1419 39.8728V25.6124L18.0302 18.2868H24.1511" fill="#8889BE"/>
        <path d="M26.2349 70.6565L24.1511 71.9263H20.6674L18.5674 70.6565L20.1791 69.11L18.0953 67.5635L19.9837 66.017L17.6884 64.324L19.5116 62.7775L17.2163 61.3775L19.2349 59.9612L17.2163 58.3496L18.9093 56.803L16.6791 55.3868L18.4372 53.9217L16.1419 52.3751L18.2907 50.8286L16.1419 49.217L17.6884 47.8659L15.6047 46.3193L17.4279 44.838L15.3442 43.2914L17.1512 41.5496L16.1419 39.8728V25.6124L18.0302 18.2868H24.1511" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M18.8276 70.6565L20.9113 71.9263H24.3787L26.495 70.6565L24.8671 69.11L26.9671 67.5635L25.0787 66.017L27.3578 64.324L25.5508 62.7775L27.8299 61.3775L25.8113 59.9612L27.8299 58.3496L26.1532 56.803L28.3671 55.3868L26.6252 53.9217L28.9043 52.3751L26.7555 50.8286L28.9043 49.217L27.3578 47.8659L29.4415 46.3193L27.6345 44.838L29.7183 43.2914L27.895 41.5496L28.9043 39.8728V25.6124L27.0322 18.2868H23.1253" fill="#8889BE"/>
        <path d="M18.8276 70.6565L20.9113 71.9263H24.3787L26.495 70.6565L24.8671 69.11L26.9671 67.5635L25.0787 66.017L27.3578 64.324L25.5508 62.7775L27.8299 61.3775L25.8113 59.9612L27.8299 58.3496L26.1532 56.803L28.3671 55.3868L26.6252 53.9217L28.9043 52.3751L26.7555 50.8286L28.9043 49.217L27.3578 47.8659L29.4415 46.3193L27.6345 44.838L29.7183 43.2914L27.895 41.5496L28.9043 39.8728V25.6124L27.0322 18.2868H23.1253" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="30">
          <path d="M2.42479 22.2806L4.73521 27.3635C5.71558 27.8449 10.8358 28.915 13.2039 29.459C13.759 29.5865 14.3276 29.2444 14.4595 28.6903L16.7174 19.207C21.2921 17.9769 23.8029 18.0669 28.2174 19.207L30.4768 28.6965C30.6082 29.2483 31.1711 29.5867 31.7227 29.4546C34.1949 28.8624 39.388 27.7072 40.5117 26.9154C40.5117 26.6214 40.5818 26.4393 40.5958 26.3973C41.6039 23.4428 43.0602 20.6143 43.2142 15.9094C43.2983 13.1089 43.4243 9.06216 40.7498 5.89758C37.8373 2.45295 33.3004 1.94886 31.3401 1.69681C29.1897 1.28566 26.9724 1.39124 24.8709 2.00487C24.0167 2.31293 19.97 3.65717 18.7238 3.40513C18.0796 3.2371 17.7716 3.02705 17.7716 3.13907C17.7716 3.25109 9.91615 -0.557608 5.58936 1.90684C1.26257 4.37129 1.58463 6.23364 0.660465 11.6246C0.239143 15.2717 0.850456 18.9639 2.42479 22.2806Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask536)">
          <path d="M2.42479 22.2806L4.73521 27.3635C5.71558 27.8449 10.8358 28.915 13.2039 29.459C13.759 29.5865 14.3276 29.2444 14.4595 28.6903L16.7174 19.207C21.2921 17.9769 23.8029 18.0669 28.2174 19.207L30.4768 28.6965C30.6082 29.2483 31.1711 29.5867 31.7227 29.4546C34.1949 28.8624 39.388 27.7072 40.5117 26.9154C40.5117 26.6214 40.5818 26.4393 40.5958 26.3973C41.6039 23.4428 43.0602 20.6143 43.2142 15.9094C43.2983 13.1089 43.4243 9.06216 40.7498 5.89758C37.8373 2.45295 33.3004 1.94886 31.3401 1.69681C29.1897 1.28566 26.9724 1.39124 24.8709 2.00487C24.0167 2.31293 19.97 3.65717 18.7238 3.40513C18.0796 3.2371 17.7716 3.02705 17.7716 3.13907C17.7716 3.25109 9.91615 -0.557608 5.58936 1.90684C1.26257 4.37129 1.58463 6.23364 0.660465 11.6246C0.239143 15.2717 0.850456 18.9639 2.42479 22.2806Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M10.2472 43.0752H34.2472V17.5272C24.8746 15.601 19.6198 15.5814 10.2472 17.5272V43.0752Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M26.8271 70.9475L24.7434 72.2173H21.2597L19.1597 70.9475L20.7713 69.401L18.6876 67.8545L20.576 66.308L18.2807 64.615L20.1039 63.0685L17.8086 61.6685L19.8272 60.2522L17.8086 58.6406L19.5016 57.0941L17.2714 55.6778L19.0295 54.2127L16.7341 52.6662L18.883 51.1197L16.7341 49.508L18.2807 48.1569L16.1969 46.6104L18.0202 45.129L15.9365 43.5825L17.7434 41.8406L16.7341 40.1639V25.9034L18.6225 18.5779H24.7434" fill="#8889BE"/>
        <path d="M26.8271 70.9475L24.7434 72.2173H21.2597L19.1597 70.9475L20.7713 69.401L18.6876 67.8545L20.576 66.308L18.2807 64.615L20.1039 63.0685L17.8086 61.6685L19.8272 60.2522L17.8086 58.6406L19.5016 57.0941L17.2714 55.6778L19.0295 54.2127L16.7341 52.6662L18.883 51.1197L16.7341 49.508L18.2807 48.1569L16.1969 46.6104L18.0202 45.129L15.9365 43.5825L17.7434 41.8406L16.7341 40.1639V25.9034L18.6225 18.5779H24.7434" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M19.4199 70.9475L21.5036 72.2173H24.971L27.0873 70.9475L25.4594 69.401L27.5594 67.8545L25.671 66.308L27.9501 64.615L26.1431 63.0685L28.4222 61.6685L26.4036 60.2522L28.4222 58.6406L26.7454 57.0941L28.9594 55.6778L27.2175 54.2127L29.4966 52.6662L27.3478 51.1197L29.4966 49.508L27.9501 48.1569L30.0338 46.6104L28.2268 45.129L30.3105 43.5825L28.4873 41.8406L29.4966 40.1639V25.9034L27.6245 18.5779H23.7175" fill="#8889BE"/>
        <path d="M19.4199 70.9475L21.5036 72.2173H24.971L27.0873 70.9475L25.4594 69.401L27.5594 67.8545L25.671 66.308L27.9501 64.615L26.1431 63.0685L28.4222 61.6685L26.4036 60.2522L28.4222 58.6406L26.7454 57.0941L28.9594 55.6778L27.2175 54.2127L29.4966 52.6662L27.3478 51.1197L29.4966 49.508L27.9501 48.1569L30.0338 46.6104L28.2268 45.129L30.3105 43.5825L28.4873 41.8406L29.4966 40.1639V25.9034L27.6245 18.5779H23.7175" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="31">
          <path d="M3.01695 22.5716L5.32737 27.6545C6.30774 28.1359 11.428 29.206 13.7961 29.75C14.3512 29.8776 14.9197 29.5354 15.0516 28.9813L17.3096 19.498C21.8843 18.2679 24.395 18.3579 28.8096 19.498L31.069 28.9875C31.2003 29.5393 31.7633 29.8777 32.3148 29.7456C34.787 29.1534 39.9801 27.9982 41.1039 27.2064C41.1039 26.9124 41.1739 26.7303 41.1879 26.6883C42.1961 23.7338 43.6524 20.9053 43.8064 16.2004C43.8904 13.3999 44.0164 9.35317 41.342 6.1886C38.4294 2.74397 33.8926 2.23987 31.9322 1.98783C29.7819 1.57667 27.5646 1.68226 25.4631 2.29589C24.6089 2.60394 20.5622 3.94819 19.3159 3.69614C18.6718 3.52811 18.3638 3.31807 18.3638 3.43009C18.3638 3.54211 10.5083 -0.266593 6.18153 2.19786C1.85474 4.66231 2.1768 6.52465 1.25263 11.9156C0.831306 15.5628 1.44262 19.2549 3.01695 22.5716Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask536)">
          <path d="M3.01695 22.5716L5.32737 27.6545C6.30358 28.1339 12.1224 29.197 14.7473 29.7431C15.3429 29.867 15.9423 29.459 16.025 28.8562L17.3096 19.498C21.8843 18.2679 24.395 18.3579 28.8096 19.498L30.1561 28.8714C30.2419 29.4687 30.8347 29.8659 31.424 29.7363C34.1283 29.1414 39.986 27.9941 41.1039 27.2064C41.1039 26.9124 41.1739 26.7303 41.1879 26.6883C42.1961 23.7338 43.6524 20.9053 43.8064 16.2004C43.8904 13.3999 44.0164 9.35317 41.342 6.1886C38.4294 2.74397 33.8926 2.23987 31.9322 1.98783C29.7819 1.57667 27.5646 1.68226 25.4631 2.29589C24.6089 2.60394 20.5622 3.94819 19.3159 3.69614C18.6718 3.52811 18.3638 3.31807 18.3638 3.43009C18.3638 3.54211 10.5083 -0.266593 6.18153 2.19786C1.85474 4.66231 2.1768 6.52465 1.25263 11.9156C0.831306 15.5628 1.44262 19.2549 3.01695 22.5716Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M44.2556 0.259766H23.2556V5.25977H44.2556V0.259766Z" fill="#838AA2"/>
        <path d="M22.2556 0.259766H1.25562V5.25977H22.2556V0.259766Z" fill="#838AA2"/>
        <path d="M5.27831 29.9014C5.41943 38.3607 6.45675 46.7813 8.37288 55.0219C11.4534 67.6242 11.7615 64.5857 13.6098 69.6686C15.4582 74.7516 31.6451 86.7798 26.5622 77.076C22.7701 69.4775 20.9636 61.0425 21.3112 52.5575C21.4653 47.7826 24.2518 48.7068 24.2518 48.7068C24.2518 48.7068 26.1421 49.1549 27.2203 53.3556C28.2855 56.6987 29.7326 59.9079 31.5331 62.9194C33.7378 66.3617 36.5349 69.3863 39.7946 71.853C44.5275 75.1016 45.2556 71.853 45.2556 71.853C43.1294 65.4365 41.6839 58.8142 40.9428 52.0954C40.2621 44.5922 40.3372 37.0395 41.1669 29.5513C39.8181 30.4433 38.6463 16.8151 37.0762 17.2018C35.4783 17.6201 33.8238 17.7808 32.1753 17.6779C26.5743 17.3418 21.9954 15.6055 18.8729 16.9917C15.7526 17.9842 12.4014 17.9842 9.28111 16.9917C8.01486 16.6458 6.4622 30.4684 5.27831 29.9014Z" fill="#DEE1E9"/>
        <path d="M2.88267 24.7513L5.19309 29.8342C6.17347 30.3156 11.2937 31.3857 13.6618 31.9297C14.2169 32.0572 14.7854 31.7151 14.9174 31.161L17.1753 21.6777C21.75 20.4476 24.2608 20.5376 28.6753 21.6777L30.9347 31.1672C31.0661 31.719 31.629 32.0574 32.1806 31.9253C34.6528 31.3331 39.8458 30.1779 40.9696 29.3861C40.9696 29.0921 41.0396 28.91 41.0536 28.868C42.0618 25.9135 43.5181 23.085 43.6721 18.3801C43.7561 15.5796 43.8822 11.5329 41.2077 8.36828C38.2951 4.92365 33.7583 4.41956 31.798 4.16752C29.6476 3.75636 27.4303 3.86195 25.3288 4.47557C24.4746 4.78363 20.4279 6.12788 19.1817 5.87583C18.5375 5.7078 18.2295 5.49776 18.2295 5.60978C18.2295 5.7218 10.374 1.91309 6.04725 4.37754C1.72046 6.842 2.04252 8.70434 1.11835 14.0953C0.697029 17.7424 1.30834 21.4346 2.88267 24.7513Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M5.31933 27.6465C5.46045 36.1058 6.49776 44.5264 8.41389 52.767C11.4945 65.3693 11.8025 62.3308 13.6509 67.4137C15.4992 72.4966 27.7992 81.2174 22.7163 71.5137C18.9242 63.9152 21.0047 58.7876 21.3523 50.3026C21.5063 45.5277 24.2928 46.4519 24.2928 46.4519C24.2928 46.4519 26.1831 46.9 27.2613 51.1007C28.3265 54.4438 29.7736 57.653 31.5741 60.6645C33.7788 64.1068 36.5759 67.1314 39.8356 69.5981C44.5685 72.8467 45.2966 69.5981 45.2966 69.5981C43.1705 63.1816 41.7249 56.5593 40.9838 49.8405C40.3031 42.3373 40.3782 34.7846 41.2079 27.2964C39.8592 28.1884 38.3672 28.842 36.7971 29.2287C35.1992 29.6471 33.5447 29.8078 31.8962 29.7048C26.2952 29.3688 21.7163 27.6324 18.5938 29.0187C15.4735 30.0111 12.1222 30.0111 9.002 29.0187C7.73575 28.6727 6.50322 28.2135 5.31933 27.6465Z" fill="#DEE1E9"/>
        <path d="M21.3523 61.5137C21.5009 62.4596 23.9595 64.6082 25.7163 68.0137C27.6488 71.6624 31.5741 70.5137 31.5741 70.5137L33.2163 60.0137C33.0812 58.9461 25.6623 44.7299 24.2163 45.0137C22.7703 45.2975 14.8346 52.6625 21.3523 61.5137Z" fill="#DEE1E9"/>
        <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="31">
          <path d="M2.8939 22.6282L5.20432 27.7112C6.37375 28.2854 7.59215 28.754 8.84499 29.1114C11.9652 30.1038 15.3165 30.1038 18.4367 29.1114C21.5593 27.7112 26.1942 29.4615 31.7392 29.7975C33.3877 29.9005 35.0422 29.7398 36.6401 29.3214C38.194 28.8884 39.6622 28.1922 40.9809 27.2631C40.9809 26.969 41.0509 26.787 41.0649 26.745C42.0731 23.7904 43.5293 20.9619 43.6834 16.2571C43.7674 13.4565 43.8934 9.40981 41.2189 6.24524C38.3064 2.80061 33.7695 2.29651 31.8092 2.04447C29.6588 1.63331 27.4416 1.7389 25.34 2.35253C24.4858 2.66058 20.4391 4.00483 19.1929 3.75278C18.5488 3.58475 18.2407 3.37471 18.2407 3.48673C18.2407 3.59875 10.3853 -0.209952 6.05848 2.2545C1.73169 4.71895 2.05375 6.58129 1.12958 11.9723C0.70826 15.6194 1.31957 19.3115 2.8939 22.6282Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask536)">
          <path d="M2.8939 22.6282L5.20432 27.7112C6.37375 28.2854 7.59215 28.754 8.84499 29.1114C11.9652 30.1038 15.3165 30.1038 18.4367 29.1114C21.5593 27.7112 26.1942 29.4615 31.7392 29.7975C33.3877 29.9005 35.0422 29.7398 36.6401 29.3214C38.194 28.8884 39.6622 28.1922 40.9809 27.2631C40.9809 26.969 41.0509 26.787 41.0649 26.745C42.0731 23.7904 43.5293 20.9619 43.6834 16.2571C43.7674 13.4565 43.8934 9.40981 41.2189 6.24524C38.3064 2.80061 33.7695 2.29651 31.8092 2.04447C29.6588 1.63331 27.4416 1.7389 25.34 2.35253C24.4858 2.66058 20.4391 4.00483 19.1929 3.75278C18.5488 3.58475 18.2407 3.37471 18.2407 3.48673C18.2407 3.59875 10.3853 -0.209952 6.05848 2.2545C1.73169 4.71895 2.05375 6.58129 1.12958 11.9723C0.70826 15.6194 1.31957 19.3115 2.8939 22.6282Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>
        <path opacity="0.2" d="M11.234 16.6566C10.2817 22.7328 10.9123 28.809 11.0281 33.7155C11.1568 39.093 10.552 38.1967 10.8093 42.4956C11.0688 46.8473 11.7864 51.1438 12.9455 55.2861C13.3573 56.7292 13.872 58.233 14.4253 60.8154C14.9787 63.3978 17.9975 68.8471 18.2163 68.8471C18.7182 68.8471 15.4934 59.5256 15.4934 52.6361C15.1845 48.2612 15.339 46.4148 15.4934 42.4956C15.6221 38.8347 16.1883 34.7181 16.7802 34.7181C17.3721 34.7181 17.3593 37.1334 18.067 40.9918C19.0579 46.4755 19.9458 51.4277 22.5323 56.0456C24.5526 59.6609 25.7751 59.7976 27.6796 64.0662C29.0436 67.2258 29.3817 68.5896 29.7163 68.5137C30.0509 68.4377 30.0731 65.1751 29.1723 60.8154C28.2715 56.4557 27.2549 55.4683 26.1869 51.534C24.5397 45.4578 24.4111 38.2575 25.7622 37.7258C26.7145 37.3764 28.3359 40.2475 30.6522 44.5008C32.4537 47.7516 33.1357 49.5592 35.1174 53.7822C37.0992 58.0052 42.1838 69.1054 42.6728 68.8471C43.1618 68.5889 39.399 60.6787 37.8548 55.2861C36.4522 50.3947 36.2756 49.6995 35.1174 45.5069C34.2288 42.6441 33.7337 39.2051 33.2001 36.222C32.6674 33.2578 32.4556 30.2259 32.5695 27.1988C32.7497 23.3708 33.4317 21.9429 33.4188 17.1579C33.4188 13.9679 33.0971 12.7374 32.3508 11.8868C31.6044 11.0361 30.2018 10.7626 28.1043 10.6411C26.1684 10.332 24.1978 10.5875 22.365 11.3855C19.3538 12.9045 19.3281 15.5173 17.2177 15.654C15.1073 15.7907 13.9235 13.1931 12.5466 13.9071C11.6329 14.3476 11.3755 15.7907 11.234 16.6566Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M5.31933 26.7373C5.46045 35.1966 6.49776 43.6172 8.41389 51.8579C11.4945 64.4602 11.8025 61.4216 13.6509 66.5045C15.4992 71.5875 27.7992 80.3083 22.7163 70.6045C18.9242 63.006 21.0047 57.8785 21.3523 49.3934C21.5063 44.6185 24.2928 45.5427 24.2928 45.5427C24.2928 45.5427 26.1831 45.9908 27.2613 50.1916C28.3265 53.5347 29.7736 56.7438 31.5741 59.7553C33.7788 63.1977 36.5759 66.2222 39.8356 68.6889C44.5685 71.9375 45.2966 68.6889 45.2966 68.6889C43.1705 62.2724 41.7249 55.6502 40.9838 48.9313C40.3031 41.4281 40.3782 33.8754 41.2079 26.3872C39.8592 27.2792 38.3672 27.9329 36.7971 28.3196C35.1992 28.7379 33.5447 28.8986 31.8962 28.7956C26.2952 28.4596 21.7163 26.7233 18.5938 28.1095C15.4735 29.1019 12.1222 29.1019 9.002 28.1095C7.73575 27.7635 6.50322 27.3043 5.31933 26.7373Z" fill="#DEE1E9"/>
        <path d="M21.3523 60.6045C21.5009 61.5504 23.9595 63.699 25.7163 67.1045C27.6488 70.7532 31.5741 69.6045 31.5741 69.6045L33.2163 59.1045C33.0812 58.0369 25.6623 43.8207 24.2163 44.1045C22.7703 44.3883 14.8346 51.7533 21.3523 60.6045Z" fill="#DEE1E9"/>
        <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="30">
          <path d="M2.8939 21.719L5.20432 26.802C6.37375 27.3762 7.59215 27.8448 8.84499 28.2022C11.9652 29.1947 15.3165 29.1947 18.4367 28.2022C21.5593 26.802 26.1942 28.5523 31.7392 28.8883C33.3877 28.9913 35.0422 28.8306 36.6401 28.4123C38.194 27.9792 39.6622 27.283 40.9809 26.3539C40.9809 26.0598 41.0509 25.8778 41.0649 25.8358C42.0731 22.8813 43.5293 20.0527 43.6834 15.3479C43.7674 12.5474 43.8934 8.50063 41.2189 5.33606C38.3064 1.89143 33.7695 1.38733 31.8092 1.13529C29.6588 0.724133 27.4416 0.829719 25.34 1.44335C24.4858 1.7514 20.4391 3.09565 19.1929 2.8436C18.5488 2.67557 18.2407 2.46553 18.2407 2.57755C18.2407 2.68957 10.3853 -1.11913 6.05848 1.34532C1.73169 3.80977 2.05375 5.67211 1.12958 11.0631C0.70826 14.7102 1.31957 18.4024 2.8939 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask536)">
          <path d="M2.8939 21.719L5.20432 26.802C6.37375 27.3762 7.59215 27.8448 8.84499 28.2022C11.9652 29.1947 15.3165 29.1947 18.4367 28.2022C21.5593 26.802 26.1942 28.5523 31.7392 28.8883C33.3877 28.9913 35.0422 28.8306 36.6401 28.4123C38.194 27.9792 39.6622 27.283 40.9809 26.3539C40.9809 26.0598 41.0509 25.8778 41.0649 25.8358C42.0731 22.8813 43.5293 20.0527 43.6834 15.3479C43.7674 12.5474 43.8934 8.50063 41.2189 5.33606C38.3064 1.89143 33.7695 1.38733 31.8092 1.13529C29.6588 0.724133 27.4416 0.829719 25.34 1.44335C24.4858 1.7514 20.4391 3.09565 19.1929 2.8436C18.5488 2.67557 18.2407 2.46553 18.2407 2.57755C18.2407 2.68957 10.3853 -1.11913 6.05848 1.34532C1.73169 3.80977 2.05375 5.67211 1.12958 11.0631C0.70826 14.7102 1.31957 18.4024 2.8939 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>
        <path opacity="0.4" d="M11.234 15.7474C10.2817 21.8236 10.9123 27.8998 11.0281 32.8063C11.1568 38.1838 10.552 37.2875 10.8093 41.5865C11.0688 45.9381 11.7864 50.2346 12.9455 54.3769C13.3573 55.82 13.872 57.3238 14.4253 59.9062C14.9787 62.4886 17.9975 67.938 18.2163 67.938C18.7182 67.938 15.4934 58.6164 15.4934 51.7269C15.1845 47.352 15.339 45.5056 15.4934 41.5865C15.6221 37.9255 16.1883 33.8089 16.7802 33.8089C17.3721 33.8089 17.3593 36.2242 18.067 40.0826C19.0579 45.5664 19.9458 50.5185 22.5323 55.1364C24.5526 58.7518 25.7751 58.8885 27.6796 63.157C29.0436 66.3166 29.3817 67.6804 29.7163 67.6045C30.0509 67.5285 30.0731 64.2659 29.1723 59.9062C28.2715 55.5465 27.2549 54.5592 26.1869 50.6248C24.5397 44.5486 24.4111 37.3483 25.7622 36.8166C26.7145 36.4673 28.3359 39.3383 30.6522 43.5916C32.4537 46.8424 33.1357 48.6501 35.1174 52.873C37.0992 57.096 42.1838 68.1962 42.6728 67.938C43.1618 67.6797 39.399 59.7695 37.8548 54.3769C36.4522 49.4855 36.2756 48.7903 35.1174 44.5977C34.2288 41.7349 33.7337 38.2959 33.2001 35.3128C32.6674 32.3486 32.4556 29.3167 32.5695 26.2896C32.7497 22.4616 33.4317 21.0337 33.4188 16.2487C33.4188 13.0587 33.0971 11.8282 32.3508 10.9776C31.6044 10.1269 30.2018 9.85346 28.1043 9.73193C26.1684 9.42277 24.1978 9.67835 22.365 10.4763C19.3538 11.9953 19.3281 14.6081 17.2177 14.7448C15.1073 14.8815 13.9235 12.284 12.5466 12.9979C11.6329 13.4384 11.3755 14.8815 11.234 15.7474Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M5.5863 26.7373C5.72741 35.1966 6.76473 43.6172 8.68086 51.8579C11.7614 64.4602 12.0695 61.4216 13.9178 66.5045C15.7662 71.5875 28.0662 80.3083 22.9833 70.6045C19.1912 63.006 21.2716 57.8785 21.6192 49.3934C21.7733 44.6185 24.5598 45.5427 24.5598 45.5427C24.5598 45.5427 26.4501 45.9908 27.5283 50.1916C28.5935 53.5347 30.0406 56.7438 31.8411 59.7553C34.0458 63.1977 36.8428 66.2222 40.1026 68.6889C44.8355 71.9375 45.5636 68.6889 45.5636 68.6889C43.4374 62.2724 41.9919 55.6502 41.2508 48.9313C40.5701 41.4281 40.6452 33.8754 41.4749 26.3872C40.1261 27.2792 38.6341 27.9329 37.064 28.3196C35.4661 28.7379 33.8117 28.8986 32.1631 28.7956C26.5621 28.4596 21.9833 26.7233 18.8607 28.1095C15.7405 29.1019 12.3892 29.1019 9.26897 28.1095C8.00272 27.7635 6.77019 27.3043 5.5863 26.7373Z" fill="#DEE1E9"/>
        <path d="M21.6192 60.6045C21.7679 61.5504 24.2265 63.699 25.9833 67.1045C27.9157 70.7532 31.8411 69.6045 31.8411 69.6045L33.4833 59.1045C33.3481 58.0369 25.9292 43.8207 24.4833 44.1045C23.0373 44.3883 15.1016 51.7533 21.6192 60.6045Z" fill="#DEE1E9"/>
        <mask id="mask536" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="30">
          <path d="M3.16087 21.719L5.47129 26.802C6.64072 27.3762 7.85912 27.8448 9.11195 28.2022C12.2322 29.1947 15.5835 29.1947 18.7037 28.2022C21.8263 26.802 26.4611 28.5523 32.0061 28.8883C33.6547 28.9913 35.3091 28.8306 36.907 28.4123C38.461 27.9792 39.9292 27.283 41.2478 26.3539C41.2478 26.0598 41.3178 25.8778 41.3318 25.8358C42.34 22.8813 43.7963 20.0527 43.9503 15.3479C44.0343 12.5474 44.1604 8.50063 41.4859 5.33606C38.5733 1.89143 34.0365 1.38733 32.0762 1.13529C29.9258 0.724133 27.7085 0.829719 25.607 1.44335C24.7528 1.7514 20.7061 3.09565 19.4598 2.8436C18.8157 2.67557 18.5077 2.46553 18.5077 2.57755C18.5077 2.68957 10.6522 -1.11913 6.32545 1.34532C1.99866 3.80977 2.32072 5.67211 1.39655 11.0631C0.975227 14.7102 1.58654 18.4024 3.16087 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask536)">
          <path d="M3.16087 21.719L5.47129 26.802C6.64072 27.3762 7.85912 27.8448 9.11195 28.2022C12.2322 29.1947 15.5835 29.1947 18.7037 28.2022C21.8263 26.802 26.4611 28.5523 32.0061 28.8883C33.6547 28.9913 35.3091 28.8306 36.907 28.4123C38.461 27.9792 39.9292 27.283 41.2478 26.3539C41.2478 26.0598 41.3178 25.8778 41.3318 25.8358C42.34 22.8813 43.7963 20.0527 43.9503 15.3479C44.0343 12.5474 44.1604 8.50063 41.4859 5.33606C38.5733 1.89143 34.0365 1.38733 32.0762 1.13529C29.9258 0.724133 27.7085 0.829719 25.607 1.44335C24.7528 1.7514 20.7061 3.09565 19.4598 2.8436C18.8157 2.67557 18.5077 2.46553 18.5077 2.57755C18.5077 2.68957 10.6522 -1.11913 6.32545 1.34532C1.99866 3.80977 2.32072 5.67211 1.39655 11.0631C0.975227 14.7102 1.58654 18.4024 3.16087 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5036" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="39" width="35" height="32">
          <rect x="9.80518" y="39.4048" width="34.1781" height="30.9131" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5036)">
          <path opacity="0.4" d="M11.501 15.6566C10.5487 21.7328 11.1792 27.809 11.2951 32.7155C11.4237 38.093 10.8189 37.1967 11.0763 41.4956C11.3358 45.8473 12.0534 50.1438 13.2124 54.2861C13.6242 55.7292 14.139 57.233 14.6923 59.8154C15.2456 62.3978 18.2645 67.8471 18.4833 67.8471C18.9851 67.8471 15.7603 58.5256 15.7603 51.6361C15.4515 47.2612 15.6059 45.4148 15.7603 41.4956C15.889 37.8347 16.4552 33.7181 17.0472 33.7181C17.6391 33.7181 17.6262 36.1334 18.334 39.9918C19.3249 45.4755 20.2128 50.4277 22.7993 55.0456C24.8196 58.6609 26.0421 58.7976 27.9466 63.0662C29.3106 66.2258 29.6487 67.5896 29.9833 67.5137C30.3179 67.4377 30.3401 64.1751 29.4393 59.8154C28.5385 55.4557 27.5219 54.4683 26.4539 50.534C24.8067 44.4578 24.678 37.2575 26.0292 36.7258C26.9814 36.3764 28.6028 39.2475 30.9191 43.5008C32.7207 46.7516 33.4027 48.5592 35.3844 52.7822C37.3661 57.0052 42.4508 68.1054 42.9398 67.8471C43.4288 67.5889 39.666 59.6787 38.1218 54.2861C36.7191 49.3947 36.5426 48.6995 35.3844 44.5069C34.4958 41.6441 34.0007 38.2051 33.467 35.222C32.9344 32.2578 32.7225 29.2259 32.8365 26.1988C33.0167 22.3708 33.6987 20.9429 33.6858 16.1579C33.6858 12.9679 33.3641 11.7374 32.6177 10.8868C31.8714 10.0361 30.4687 9.76264 28.3712 9.64111C26.4354 9.33195 24.4648 9.58753 22.632 10.3855C19.6208 11.9045 19.5951 14.5173 17.4847 14.654C15.3743 14.7907 14.1904 12.1931 12.8135 12.9071C11.8999 13.3476 11.6425 14.7907 11.501 15.6566Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>
        <mask id="mask50036" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="3" width="30" height="37">
          <rect x="8.03467" y="3.99414" width="29.1809" height="35.4105" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask50036)">
          <path opacity="0.2" d="M11.501 15.6566C10.5487 21.7328 11.1792 27.809 11.2951 32.7155C11.4237 38.093 10.8189 37.1967 11.0763 41.4956C11.3358 45.8473 12.0534 50.1438 13.2124 54.2861C13.6242 55.7292 14.139 57.233 14.6923 59.8154C15.2456 62.3978 18.2645 67.8471 18.4833 67.8471C18.9851 67.8471 15.7603 58.5256 15.7603 51.6361C15.4515 47.2612 15.6059 45.4148 15.7603 41.4956C15.889 37.8347 16.4552 33.7181 17.0472 33.7181C17.6391 33.7181 17.6262 36.1334 18.334 39.9918C19.3249 45.4755 20.2128 50.4277 22.7993 55.0456C24.8196 58.6609 26.0421 58.7976 27.9466 63.0662C29.3106 66.2258 29.6487 67.5896 29.9833 67.5137C30.3179 67.4377 30.3401 64.1751 29.4393 59.8154C28.5385 55.4557 27.5219 54.4683 26.4539 50.534C24.8067 44.4578 24.678 37.2575 26.0292 36.7258C26.9814 36.3764 28.6028 39.2475 30.9191 43.5008C32.7207 46.7516 33.4027 48.5592 35.3844 52.7822C37.3661 57.0052 42.4508 68.1054 42.9398 67.8471C43.4288 67.5889 39.666 59.6787 38.1218 54.2861C36.7191 49.3947 36.5426 48.6995 35.3844 44.5069C34.4958 41.6441 34.0007 38.2051 33.467 35.222C32.9344 32.2578 32.7225 29.2259 32.8365 26.1988C33.0167 22.3708 33.6987 20.9429 33.6858 16.1579C33.6858 12.9679 33.3641 11.7374 32.6177 10.8868C31.8714 10.0361 30.4687 9.76264 28.3712 9.64111C26.4354 9.33195 24.4648 9.58753 22.632 10.3855C19.6208 11.9045 19.5951 14.5173 17.4847 14.654C15.3743 14.7907 14.1904 12.1931 12.8135 12.9071C11.8999 13.3476 11.6425 14.7907 11.501 15.6566Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M2.57571 21.4475L4.94214 26.5024C5.08263 34.9618 6.11995 43.3824 8.03671 51.623C11.1173 64.2253 11.4253 61.1867 13.2737 66.2697C15.122 71.3526 31.309 83.3948 26.226 73.677C22.4357 66.0829 20.6292 57.653 20.9751 49.1725C21.1291 44.3837 23.9156 45.3218 23.9156 45.3218C23.9156 45.3218 25.7639 45.7699 26.8421 49.9287C27.9073 53.2718 29.3545 56.4809 31.1549 59.4924C33.3596 62.9348 36.1567 65.9594 39.4164 68.4261C44.1493 71.6747 44.8774 68.4261 44.8774 68.4261C42.7328 62.0211 41.2684 55.4081 40.5086 48.6964C39.8247 41.1746 39.8997 33.6031 40.7327 26.0963C40.7327 25.8023 40.7327 25.6342 40.8167 25.5782C41.8249 22.6237 43.2811 19.7952 43.4352 15.0903C43.5192 12.2898 43.6452 8.24306 40.9707 5.07848C38.0582 1.64785 33.5214 1.12976 31.561 0.877716C29.4107 0.46656 27.1934 0.572141 25.0918 1.18577C24.2377 1.49382 20.1909 2.85207 18.9447 2.58602C18.3006 2.41799 17.9925 2.20796 17.9925 2.31998C17.9925 2.432 10.1371 -1.3767 5.8103 1.08775C1.48351 3.5522 1.80557 5.42855 0.881401 10.8055C0.440971 14.4415 1.02792 18.1281 2.57571 21.4475Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M4.81957 27.4425C4.96069 35.9018 5.998 44.3224 7.91414 52.5631C10.9947 65.1654 11.3028 62.1268 13.1511 67.2097C14.9994 72.2927 31.1864 84.3209 26.1035 74.6171C22.3114 67.0186 20.5049 58.5837 20.8525 50.0986C21.0065 45.3237 23.793 46.2479 23.793 46.2479C23.793 46.2479 25.6834 46.696 26.7616 50.8968C27.8267 54.2399 29.2739 57.449 31.0744 60.4605C33.2791 63.9029 36.0761 66.9274 39.3359 69.3941C44.0687 72.6427 44.7969 69.3941 44.7969 69.3941C42.6707 62.9776 41.2252 56.3554 40.4841 49.6365C39.8034 42.1333 39.8784 34.5806 40.7081 27.0924C39.3594 27.9844 37.8674 28.6381 36.2973 29.0248C34.6994 29.4431 33.045 29.6038 31.3964 29.5008C25.7954 29.1648 21.2166 27.4285 18.094 28.8147C14.9738 29.8071 11.6225 29.8071 8.50225 28.8147C7.236 28.4687 6.00346 28.0095 4.81957 27.4425Z" fill="#DEE1E9"/>
      <path d="M2.42393 22.2925L4.73435 27.3755C5.90378 27.9497 7.12218 28.4183 8.37502 28.7757C11.4953 29.7681 14.8465 29.7681 17.9668 28.7757C21.0893 27.3755 25.7242 29.1258 31.2692 29.4618C32.9178 29.5648 34.5722 29.4041 36.1701 28.9858C37.724 28.5527 39.1922 27.8565 40.5109 26.9274C40.5109 26.6333 40.5809 26.4513 40.5949 26.4093C41.6031 23.4547 43.0594 20.6262 43.2134 15.9214C43.2974 13.1209 43.4234 9.07412 40.7489 5.90954C37.8364 2.46491 33.2996 1.96082 31.3392 1.70877C29.1889 1.29762 26.9716 1.40321 24.87 2.01683C24.0159 2.32489 19.9691 3.66913 18.7229 3.41709C18.0788 3.24906 17.7707 3.03902 17.7707 3.15104C17.7707 3.26306 9.9153 -0.545645 5.58851 1.9188C1.26172 4.38326 1.58378 6.2456 0.659611 11.6366C0.238289 15.2837 0.849598 18.9758 2.42393 22.2925Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth36",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
