<template>
  <div class="formula-wrapper">
    <div class="text-center" v-if="!imageMaking">
      <a v-for="v in views"
         :key="`view-toggler-${v.id}`"
         class="teeth-toggler pointer"
         :class="{ active: v.id === view }"
         @click="view = v.id">
        {{ $t(v.title) }}
      </a>
    </div>
    <div class="mt-5 text-center" ref="pf">
      <div v-if="mode == 'select'" class="mb-4">
        <div class="d-inline-block align-middle"
             :class="{'ml-4': zind > 0}"
             v-for="(z, zind) in additionalSelectZones"
             :key="`zone-${z.id}`">
          <div class="checkbox sm form-group">
            <input type="checkbox"
                   :disabled="disabled"
                   :checked="recordTeeth.includes(z.id)"
                   :id="`zone-${z.id}`"
                   :value="z.id"/>
            <label @click.prevent="selectZone(z.id)"
                   :for="`zone-${z.id}`">
              {{ $t(z.title) }}
            </label>
          </div>
        </div>
      </div>
      <component :is="view"
                 :disabled="disabled"
                 id="patientFormulaSelectedView"
                 :mode="mode"/>
    </div>
    <ToothStatusModal v-if="statuses"/>
  </div>
</template>

<script>

import AdultTeeth from "@/components/parts/patients/teeth/AdultTeeth"
import BabyTeeth from "@/components/parts/patients/teeth/BabyTeeth"
import Jaws from "@/components/parts/patients/teeth/Jaws"
import { mapActions, mapState } from "vuex"
import ToothStatusModal from "@/components/parts/patients/teeth/ToothStatusModal"

export default {
  name: "PatientFormula",
  head() {
    return {
      title: this.$t("formula"),
    }
  },
  components: { ToothStatusModal, AdultTeeth, Jaws, BabyTeeth },
  props: {
    mode: {
      type: String,
      default: 'status'
    },
    imageMaking: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      view: 'AdultTeeth',
      views: [
        { id: 'AdultTeeth', title: 'permanent_teeth' },
        { id: 'BabyTeeth', title: 'baby_teeth' },
        { id: 'Jaws', title: 'jaws' },
      ],
      additionalSelectZones: [
        { id: "В", title: "top_jaw" },
        { id: "Н", title: "bottom_jaw" },
        { id: "Р", title: "oral_cavity" },
      ],
    }
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
      selectedTooth:  state => state.teeth.selectedTooth,
      selectedToothSelectionType:  state => state.teeth.selectedToothSelectionType,
      recordTeeth:  state => state.teeth.recordTeeth,
      statuses:  state => state.teeth.toothStatuses,
    })
  },
  methods: {
    ...mapActions(['getPatientTeeth']),
    selectZone(zone) {
      this.$store.commit('setSelectedTooth', { tooth: zone, area: 'tooth', type: 'select' })
    },
  },
  mounted() {
    if(this.patient) {
      this.getPatientTeeth(this.patient.id)
      this.$store.commit('setEloquentOptions', null)
    }
  },
  watch: {
    selectedTooth(val) {
      if(val) {
        if(['status','select'].includes(this.mode) && val && this.selectedToothSelectionType !== 'select' ) {
          this.$bvModal.show('tooth-status-modal')
        }
        if(['select'].includes(this.mode) && val && this.selectedToothSelectionType === 'select' ) {
            this.$emit('input', val)
            this.$store.commit("setSelectedTooth", { tooth: null, area: null })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.formula-wrapper {
  background: #F4F6FD;
  padding: 22px;
  .teeth-toggler {
    border-radius: 15px;
    background: #fff;
    color: $gray-text;
    text-transform: uppercase;
    padding: 7px 20px;
    margin: 0 9px;
    &.active, &:hover {
      text-decoration: none;
      background: $blue-color;
      color: #fff;
    }
  }
}

@media screen and (max-width: 768px) {
  .formula-wrapper {
    padding: 20px 12px;
    overflow: scroll;
    white-space: nowrap;
    .teeth-toggler {
      border-radius: 15px;
      margin: 0 4px;
      font-size: 10px;
      padding: 7px 10px;
    }
  }
}

</style>
