<template>
<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M4.3125 10.75C4.65768 10.75 4.9375 10.4702 4.9375 10.125C4.9375 9.77982 4.65768 9.5 4.3125 9.5C3.96732 9.5 3.6875 9.77982 3.6875 10.125C3.6875 10.4702 3.96732 10.75 4.3125 10.75Z"/>
  <path
      d="M4.3125 13.875C4.65768 13.875 4.9375 13.5952 4.9375 13.25C4.9375 12.9048 4.65768 12.625 4.3125 12.625C3.96732 12.625 3.6875 12.9048 3.6875 13.25C3.6875 13.5952 3.96732 13.875 4.3125 13.875Z"/>
  <path
      d="M3.125 12.3125H5.5C5.84519 12.3125 6.125 12.0327 6.125 11.6875C6.125 11.3423 5.84519 11.0625 5.5 11.0625H3.125C2.77981 11.0625 2.5 11.3423 2.5 11.6875C2.5 12.0327 2.77981 12.3125 3.125 12.3125Z"/>
  <path
      d="M3.125 4.9375H3.6875V5.5C3.6875 5.84519 3.96731 6.125 4.3125 6.125C4.65769 6.125 4.9375 5.84519 4.9375 5.5V4.9375H5.5C5.84519 4.9375 6.125 4.65769 6.125 4.3125C6.125 3.96731 5.84519 3.6875 5.5 3.6875H4.9375V3.125C4.9375 2.77981 4.65769 2.5 4.3125 2.5C3.96731 2.5 3.6875 2.77981 3.6875 3.125V3.6875H3.125C2.77981 3.6875 2.5 3.96731 2.5 4.3125C2.5 4.65769 2.77981 4.9375 3.125 4.9375Z"/>
  <path
      d="M15.375 11C15.7202 11 16 10.7202 16 10.375V2.5C16 1.1215 14.8785 0 13.5 0H2.5C1.1215 0 0 1.1215 0 2.5V13.5C0 14.8785 1.1215 16 2.5 16H13.5C14.8785 16 16 14.8785 16 13.5C16 13.1548 15.7202 12.875 15.375 12.875C15.0298 12.875 14.75 13.1548 14.75 13.5C14.75 14.1892 14.1892 14.75 13.5 14.75H8.625V8.65625H14.75V10.375C14.75 10.7202 15.0298 11 15.375 11ZM7.375 14.75H2.5C1.81075 14.75 1.25 14.1892 1.25 13.5V8.65625H7.375V14.75ZM7.375 7.40625H1.25V2.5C1.25 1.81075 1.81075 1.25 2.5 1.25H7.375V7.40625ZM8.625 7.40625V1.25H13.5C14.1892 1.25 14.75 1.81075 14.75 2.5V7.40625H8.625Z"/>
  <path
      d="M13.0501 10.3286C12.806 10.0846 12.4103 10.0846 12.1662 10.3286L11.6863 10.8086L11.2064 10.3286C10.9623 10.0846 10.5666 10.0846 10.3225 10.3286C10.0784 10.5727 10.0784 10.9684 10.3225 11.2125L10.8024 11.6924L10.3225 12.1723C10.0784 12.4164 10.0784 12.8122 10.3225 13.0562C10.4445 13.1782 10.6044 13.2393 10.7644 13.2393C10.9244 13.2393 11.0843 13.1782 11.2064 13.0562L11.6863 12.5763L12.1662 13.0562C12.2883 13.1782 12.4482 13.2393 12.6082 13.2393C12.7681 13.2393 12.9281 13.1782 13.0501 13.0562C13.2942 12.8122 13.2942 12.4164 13.0501 12.1723L12.5702 11.6924L13.0501 11.2125C13.2942 10.9684 13.2942 10.5727 13.0501 10.3286Z"/>
  <path
      d="M12.875 3.6875H10.5C10.1548 3.6875 9.875 3.96731 9.875 4.3125C9.875 4.65769 10.1548 4.9375 10.5 4.9375H12.875C13.2202 4.9375 13.5 4.65769 13.5 4.3125C13.5 3.96731 13.2202 3.6875 12.875 3.6875Z"/>
</svg>
</template>
