export default {
    accounting: "Mühasibatlıq",
    action_log: "Fəaliyyət jurnalı",
    calendar: "Təqvim",
    control: "İdarəetmə",
    doctors: "Həkimlər",
    insurance: "Sığorta",
    patients: "Pasiyentlər",
    reports: "Hesabatlar",
    services: "Xidmətlər",
    settings: "Parametrlər",
    users: "İstifadəçilər",
    warehouse: "Anbar",
}