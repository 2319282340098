<template>
  <div class="spaceduck-collapse"
       :id="id"
       :class="{ show: stateCollapsed }">
    <slot/>
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "SpaceduckCollapse",
  props: {
    visible: {},
    id: {}
  },
  data() {
    return {
      collapsing: false,
      timer: null
    }
  },
  computed: {
    ...mapState({
      collapseDictionary: state => state.dom.spaceduckCollapse,
    }),
    stateCollapsed() {
      return this.collapseDictionary[this.id]
    }
  },
  mounted() {
    this.$store.commit('setSpaceduckCollapse', { // do not delete, immediate watcher not always working
      key: this.id,
      value: this.visible
    })
  },
  watch: {
    stateCollapsed(val) {
      if(val) this.$emit('show')
      if(!val) this.$emit('hide')
    },
    visible: {
      immediate: true,
      handler(val) {
        this.$store.commit('setSpaceduckCollapse', {
          key: this.id,
          value: val
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .spaceduck-collapse {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.15s ease-in-out;
    &.show {
      max-height: none;
      //overflow: visible;
    }
  }
</style>
