<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M14.4036 4.01071L10.5607 0.167857C10.4536 0.0607143 10.3089 0 10.1571 0H2.57143C2.25536 0 2 0.255357 2 0.571429V15.4286C2 15.7446 2.25536 16 2.57143 16H14C14.3161 16 14.5714 15.7446 14.5714 15.4286V4.41607C14.5714 4.26429 14.5107 4.11786 14.4036 4.01071ZM13.2536 4.67857H9.89286V1.31786L13.2536 4.67857ZM13.2857 14.7143H3.28571V1.28571H8.67857V5.14286C8.67857 5.34177 8.75759 5.53254 8.89824 5.67319C9.03889 5.81384 9.22966 5.89286 9.42857 5.89286H13.2857V14.7143Z"
      fill="#B5C6D8"/>
  <path
      d="M8.44595 6.96509C8.5277 6.96509 8.59459 7.03198 8.59459 7.11374V9.12049H10.6014C10.6831 9.12049 10.75 9.18739 10.75 9.26914V10.161C10.75 10.2428 10.6831 10.3097 10.6014 10.3097H8.59459V12.3164C8.59459 12.3982 8.5277 12.4651 8.44595 12.4651H7.55405C7.4723 12.4651 7.40541 12.3982 7.40541 12.3164V10.3097H5.39865C5.31689 10.3097 5.25 10.2428 5.25 10.161V9.26914C5.25 9.18739 5.31689 9.12049 5.39865 9.12049H7.40541V7.11374C7.40541 7.03198 7.4723 6.96509 7.55405 6.96509H8.44595Z"
      fill="#B5C6D8"/>
</svg>
</template>
