import { planTreatmentStatuses, planTreatmentTypes } from "@/dictionaries/dictionary"

export default {
    primaryKey: 'planning_id',
    model: 'plan_treatment',
    order: 'planning_id',
    sort: 'desc',
    actionscomponent: 'GeneralDatatablePlanTreatmentActions',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    showTotalRecords: true,
    actions: [
        "excel",
        "delete"
    ],
    fields: [
        {
            id: 'planning_id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'date_add',
            title: 'date_add',
            table: true,
            sortable: true,
            format_date: true,
            // filter: true,
            // filter_date: true
        },
        {
            id: 'client_id',
            title: 'patient_name',
            table: true,
            table_show: 'patient.full_name',
            sortable: true,
            // filter: true,
            // filter_model_select: true
        },
        {
            id: 'patient_name',
            title: 'patient_name',
            filter: true,
        },
        {
            id: 'message',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "textarea",
                required: true
            },
            filter: true,
            // tdcomponent: "PatientsDatatableTDTreatmentMessage"
        },
        {
            id: 'visits',
            title: 'complexes',
            table: true,
            tdcomponent: "PatientsDatatableTDTreatmentServices"
        },
        {
            id: 'complex',
            title: 'complex',
            filter: true,
        },
        {
            id: 'user_add_id',
            title: 'author',
            table: true,
            table_show: 'author.full_name',
            sortable: true,
            // filter: true,
            // filter_model_select: true
        },
        {
            id: 'author_name',
            title: 'author',
            filter: true,
        },
        {
            id: 'status',
            title: 'status',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDTreatmentStatus",
        },
        {
            id: 'recounted_status',
            title: 'status',
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                required: true,
                select_type: "list",
                options: planTreatmentStatuses
            }
        },
        {
            id: 'total_sum',
            title: 'treatment_plan_amount',
            table: true,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
        },
        {
            id: 'completed_sum',
            title: 'amount_completed',
            table: true,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
        },
        {
            id: 'plan_type',
            title: 'plan_type',
            table: true,
            sortable: true,
            format_translate: true,
            filter_list_select: true,
            filter: true,
            form: {
                type: "select",
                select_type: "list",
                options: planTreatmentTypes
            }
        },
        {
            id: 'signed_at',
            title: 'signed',
            table: true,
            sortable: true,
            filter: true,
            filter_boolean: true,
            tdcomponent: "PatientsDatatableTDTreatmentSignedAt"
        },
        {
            id: 'first_visit_date',
            title: 'first_visit_date',
            table: true,
            format_date: true,
        },
        {
            id: 'last_visit_date',
            title: 'last_visit_date',
            table: true,
            format_date: true,
        },
        {
            id: 'date_from',
            title: 'date_from',
            filter: true,
            filter_date: true,
        },
        {
            id: 'date_to',
            title: 'date_to',
            filter: true,
            filter_date: true,
        }
    ]
}
