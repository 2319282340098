<template>
  <div>
    <div class="row">
      <div v-for="setting in settings"
           class="col-auto"
           :key="`set-${setting.id}`">
        <CheckboxGroup :label="$t(setting.id)"
                       :fid="`setting-${setting.id}`"
                       @input="save"
                       :checkboxvalue="1"
                       v-model="form[setting.id]"/>
      </div>
    </div>

    <AddObject module="settings"
               btnclass="btn-themed-outline btn-themed-squared mt-2"/>
    <DataTable :options="options"
               class="mt-4"
               module="settings"/>
  </div>
</template>

<script>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/record_reason"
import AddObject from "@/components/parts/datatable/AddObject"
import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import { EloquentService } from "@/services/api.service"
import { copyObject } from "@/extensions/prototypes/prototypes"

export default {
  name: "SettingsRecordReasons",
  head() {
    return {
      title: this.$t('cancel_and_move_reasons'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
    additional() {
      return this.clinic.clinic_additional
    },
  },
  components: {
    CheckboxGroup,
    AddObject,
    DataTable
  },
  data() {
    return {
      options,
      form: {},
      settings: [
        { id: 'ask_reason_cancel' },
        { id: 'ask_reason_reschedule' },
        { id: 'ask_reason_not_come' },
      ]
    }
  },
  methods: {
    async save() {
      await this.$nextTick()
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      await EloquentService.update('clinic_additional', this.clinic.id, formData)
    },
  },
  mounted() {
    this.form = copyObject(this.additional)
    this.$store.commit('setEloquentOptions', options)
  },
  beforeUnmount() {
    this.$store.dispatch('init')
  }
}
</script>
