<template>
  <span class="data-table-additional-action-span">
  <a class="data-table-action"
     :title="$t('call')"
     v-if="object.patient && object.patient.mphone && !rights.includes('hide_patient_phone')"
     v-b-tooltip.hover
     :href="`tel:${object.patient.mphone}`">
    <PhoneSvg/><span class="action-button-mob-title">{{ $t('call') }}</span>
  </a>
  <a :title="$t('sms_sending')"
     v-if="rights.includes('sms_send') && object.patient && object.patient.mphone && !rights.includes('hide_patient_phone')"
     v-b-tooltip.hover
     class="data-table-action"
     @click="sendSms">
    <SmsSvg/><span class="action-button-mob-title">{{ $t('sms_sending') }}</span>
  </a>
    <a :title="$t('edit')"
       v-if="canBeEdited"
       v-b-tooltip.hover
       class="data-table-action"
       @click="edit">
    <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
  </a>
    <a class="data-table-action"
       v-if="canBeEdited && isTreatment"
       v-b-tooltip.hover
       :title="$t('change_status')">
      <b-dropdown right variant="link" no-caret>
        <template #button-content>
          <DotsSvg class="ch-st-svg"/><span class="action-button-mob-title">{{ $t('change_status') }}</span>
        </template>
        <div class="pt-0">
          <a v-for="(rs, tind) in recordStatuses"
             class="dropdown-item pointer"
             :key="`rs-${tind}`"
             @click="attachStatus(rs)">
              {{ $t(rs.title) }}
             </a>
        </div>
      </b-dropdown>
    </a>
    <a class="data-table-action"
       v-if="isTreatment && canBeEdited"
       v-b-tooltip.hover
       :title="$t('cancel_record')"
       @click="cancelRecord">
      <BlockSvg/><span class="action-button-mob-title">{{ $t('cancel_record') }}</span>
    </a>
    </span>
</template>

<script>

import SmsSvg from '@/assets/svg-vue/general/sms.svg'
import PhoneSvg from '@/assets/svg-vue/header/phone.svg'
import DotsSvg from '@/assets/svg-vue/crm/menu_confirm.svg'
import BlockSvg from '@/assets/svg-vue/general/block.svg'
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import {mapState} from "vuex"
import {recordStatuses} from "@/dictionaries/dictionary"
import {CalendarService, EloquentService} from "@/services/api.service"

export default {
  name: "CRMRecordDatatableActions",
  components: {
    PhoneSvg,
    SmsSvg,
    DotsSvg,
    BlockSvg,
    EditSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
    }),
    canBeEdited() {
      if(!this.object) return false
      if(this.object.status == 2) return false
      return !(this.object.hasPassed && !this.rights.includes('edit_history'))
    },
    isTreatment() {
      return this.object && this.object.record_type !== 'block'
    },
    additional() {
      return this.clinic.clinic_additional
    },
  },
  data() {
    return {
      recordStatuses: recordStatuses,
    }
  },
  methods: {
    sendSms() {
      this.$store.commit('setPatient', this.object.patient)
      this.$nextTick(() => {
        this.$bvModal.show('patient-sms-modal')
      })
    },
    attachStatus(status) {
      let formData = new FormData()
      formData = this.appendFormdata(formData, { records_statuses_id: status.id })
      EloquentService.update('record', this.object.id, formData).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      })
    },
    async cancelRecord() {
      let reason = ''
      if(this.additional.ask_reason_cancel) {
        reason = await this.$parent?.$parent?.$parent?.$parent?.$refs.reasonPrompt.showPrompt()
        if(!reason) return
      }
      CalendarService.cancelRecord(this.object.id, reason).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }).catch((e) => {
        console.log(e)
      })
    },
    edit() {
      this.$store.commit('setNewVisit', {
        id: this.object.id,
        client_id: this.object.client_id
      })
      this.$bvModal.show('add-new-visit-modal')
    }
  }
}
</script>

<style scoped>
.ch-st-svg {
  position: relative;
  top: -1px;
}
</style>
