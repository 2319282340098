import authorized from "@/middleware/authorized"
import paid from "@/middleware/paid"
import InsuranceGeneral from "@/components/pages/insurance/InsuranceGeneral"

export default {
    path: '/insurance',
    meta: { middleware: [authorized, paid], layout: "default" },
    component: InsuranceGeneral,
    children: [
        { path: '', redirect: '/insurance/companies' },
        {
            path: 'companies', name: "InsuranceCompanies",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['insurance'] },
            component: () => import('../components/pages/insurance/InsuranceCompanies')
        },
        {
            path: 'polises', name: "InsurancePolises",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['insurance'] },
            component: () => import('../components/pages/insurance/InsurancePolises')
        },
    ]

}
