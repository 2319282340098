<template>
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M18.15 0H3.84999C2.78849 0 1.92499 0.8635 1.92499 1.925V20.075C1.92499 21.1362 2.78849 22 3.84999 22H18.15C19.2112 22 20.075 21.1362 20.075 20.075V1.925C20.075 0.8635 19.2112 0 18.15 0ZM18.425 20.075C18.425 20.2241 18.299 20.35 18.15 20.35H3.84999C3.70093 20.35 3.57499 20.2241 3.57499 20.075V1.925C3.57499 1.77594 3.70093 1.65 3.84999 1.65H18.15C18.299 1.65 18.425 1.77594 18.425 1.925V20.075Z"/>
    <path
        d="M13.475 9.35001H8.24999C7.7943 9.35001 7.42499 9.71932 7.42499 10.175C7.42499 10.6307 7.7943 11 8.24999 11H13.475C13.9307 11 14.3 10.6307 14.3 10.175C14.3 9.71932 13.9307 9.35001 13.475 9.35001Z"/>
    <path
        d="M15.4 12.65H6.59996C6.14428 12.65 5.77496 13.0193 5.77496 13.475C5.77496 13.9307 6.14428 14.3 6.59996 14.3H15.4C15.8556 14.3 16.225 13.9307 16.225 13.475C16.225 13.0193 15.8556 12.65 15.4 12.65Z"/>
    <path
        d="M15.4 15.95H6.59996C6.14428 15.95 5.77496 16.3193 5.77496 16.775C5.77496 17.2307 6.14428 17.6 6.59996 17.6H15.4C15.8556 17.6 16.225 17.2307 16.225 16.775C16.225 16.3193 15.8556 15.95 15.4 15.95Z"/>
    <path
        d="M13.475 0.824982V3.57498H8.525V0.824982H6.875V4.39998C6.875 4.85567 7.24432 5.22498 7.7 5.22498H14.3C14.7557 5.22498 15.125 4.85567 15.125 4.39998V0.824982H13.475Z"/>
  </svg>
</template>
