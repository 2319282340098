<template>
  <transition name="_fade" mode="out-in" >
    <div>
      <DataTableFilter ref="datatablefilter"
                       v-if="enableFilter"
                       :options="options"/>
      <div class="small text-muted mb-3"
           v-if="options.showTotalRecords && paginator">
        {{ $t('records')}}: {{ formatNumber(paginator.total) }}
      </div>
      <div class="mb-3" v-if="hiddenColumns && hiddenColumns.length">
        <span class="text-muted mr-2">{{ $t('hidden_columns') }}:</span>
        <span v-for="c in hiddenColumns"
              @click="toggleColumn(c)"
              class="tags-note tag-code pointer mr-2"
              :key="`hidden-col-${c}`">{{ $t(c) }}
        </span>
      </div>
      <div class="data-table-wrapper">
        <table class="table data-table"
               :class="[{ 'more-button': needsMoreButton, 'wi-auto': wiAuto, canHideRows }, 'data-table-'+options.model]">
          <thead>
          <tr :class="`order-${sort}`">
            <th v-if="options.checks" class="checkboxed">
              <input @click="checkboxAll" v-model="checkboxMain" type="checkbox"/>
            </th>
            <template v-for="(f, find) in options.fields">
              <th @click="resort(f)"
                  v-if="f.table && (!f.right_excludes || !rights.includes(f.right_excludes))"
                  :class="[
                  {
                    sortable: f.sortable,
                    sorted: !options.hidesorting && order === (f.sortable && f.sortable !== true ? f.sortable : f.id),
                    primary: options.primaryKey == f.id,
                    hidden: hiddenColumns && hiddenColumns.includes(f.id)
                  },
               `th-${f.id}`]"
                  :key="`data-table-header-${f.id}-${find}`">
                {{ $t(f.title) }}
                <span v-if="f.thhint"
                      class="ml-1"
                      v-b-popover.hover="$t(f.thhint)">
                  <InfoSvg/>
                </span>
                <span v-if="canHideRows && hiddenColumns"
                      @click.stop="toggleColumn(f.id)"
                      class="datatable-toggle-column">
                  <EyeSvg v-b-tooltip.hover
                          :title="f.label"
                          v-if="hiddenColumns.includes(f.id)"/>
                  <NoEyeSvg v-if="!hiddenColumns.includes(f.id)"/>
                </span>
              </th>
            </template>
            <th class="actions no-print"
                v-if="(options.actions && options.actions.some(r => ['edit','delete'].includes(r))) || options.actionscomponent">
              {{ $t('actions') }}
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-if="options.trcomponent">
            <component v-for="(l, lind) in list"
                       :is="trcomponents[options.trcomponent]"
                       :key="`custom-tr-${lind}`"
                       :object="l">
              <ActionButtons :options="options"
                             :index="lind"
                             :key="`custom-tr-actions-${lind}`"
                             :object="l"
                             :module="module"/>
            </component>
          </template>
          <tr v-else
              v-for="(l, lind) in list"
              :class="options.trclass ? options.trclass.reduce((acc,curr)=> (acc[curr.class]=l[curr.condition],acc),{}) : ''"
              :key="`data-table-${lind}`">
            <td v-if="options.checks">
              <input type="checkbox"
                     :value="l.id"
                     v-model="selected"
                     @click="checkboxChecked($event, lind)"/>
            </td>
            <template v-for="(f, find) in options.fields">
              <td :class="[f.tabletdclass, { hidden: hiddenColumns.includes(f.id) }]"
                  :data-title="$t(f.title)"
                  v-if="f.table && (!f.right_excludes || !rights.includes(f.right_excludes))"
                  :key="`data-table-${lind}-${f.id}-${find}`">
                <component :is="tdcomponents[f.tdcomponent] ? tdcomponents[f.tdcomponent] : 'TDComponent'"
                           :object="l"
                           :field="f"/>
              </td>
            </template>
            <MobileMoreArrow v-if="needsMoreButton"/>
            <ActionButtons :options="options"
                           :index="lind"
                           :object="l"
                           :module="module"/>
          </tr>
          </tbody>
        </table>
        <Paginator v-if="paginator && !loading"
                   :perPage="pagination"
                   :showPerPage="options.showTotalRecords"
                   :showAllOption="options.showAllOption"
                   @perPageChanged="perPageChanged"
                   :paginator="paginator"/>
      </div>

      <SelectedBottomBar v-if="options.checks"
                         @reset-selection="cancelSelection"
                         :selected="selected">
        <slot name="bottom-bar"/>
      </SelectedBottomBar>

    </div>
  </transition>
</template>

<script>
import  { EloquentService } from "@/services/api.service"
import Paginator from "./Paginator"
import { mapState } from "vuex"
import TDComponent from "@/components/parts/datatable/TDComponent"
import ActionButtons from "@/components/parts/datatable/ActionButtons"
import InfoSvg from '@/assets/svg-vue/general/info.svg'
import DataTableFilter from "@/components/parts/datatable/DataTableFilter"
import MobileMoreArrow from "@/components/parts/datatable/MobileMoreArrow"
import { formatNumber } from "@/extensions/filters/filters"
import EyeSvg from "@/assets/svg-vue/form/eye.svg.vue"
import NoEyeSvg from "@/assets/svg-vue/form/noeye.svg.vue"
import SelectedBottomBar from "@/components/parts/datatable/SelectedBottomBar.vue"
import { copyObject } from "@/extensions/prototypes/prototypes"

export default {
  name: "DataTable",
  components: {
    SelectedBottomBar,
    NoEyeSvg,
    EyeSvg,
    MobileMoreArrow,
    DataTableFilter,
    ActionButtons,
    TDComponent,
    Paginator,
    InfoSvg,
  },
  props: {
    options: {
      type: Object
    },
    module: {
      type: String
    },
    resource: {
      type: String,
      default: ''
    },
    collection: {
      type: String,
      default: ''
    },
    params: {
      type: Object
    },
    wiAuto: {
      type: Boolean,
      default: false
    },
    loadOnInit: {
      type: Boolean,
      default: true
    },
    canHideRows: {
      type: Boolean,
      default: false
    },
    enableFilter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      page: this.$route.query.page ? this.$route.query.page : 1,
      list: [],
      paginator: null,
      order: 'id',
      sort: 'asc',
      timer: null,
      loading: false,
      pagination: 10,
      filterWatcher: false,
      selected: [],
      checkboxMain: false,
      lastCheckedIndex: null,
      hiddenColumns: window.localStorage.getItem(this.$route.name+'_hiddencolumns'),
    }
  },
  mounted() {
    if(this.options.sort) this.sort = this.options.sort
    if(this.options.order) this.order = this.options.order
    if(this.options.pagination) this.pagination = this.options.pagination
    this.$store.commit('setSearch','')
    this.parseFilters()
    if(this.loadOnInit) this.load()
    // console.log(this.options.model)
    this.$store.commit('setViewModel', this.options.model)
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if(modalId === 'form-modal') {
        this.$store.commit("setViewModel", this.options.model)
      }
    })
    this.hiddenColumns = this.hiddenColumns ? JSON.parse(this.hiddenColumns) : []
  },
  methods: {
    formatNumber,
    load() {
      this.list = []
      this.loading = true
      EloquentService.dataTable(this.options.model, this.page, this.generateParams(), true, this.superadmin).then(res => {
        this.loading = false
        if(this.options.customListSorting) {
          this.list = this.options.customListSorting(res.data.result.data)
        } else {
          this.list = res.data.result.data
        }
        this.paginator = res.data.result
        this.$emit('totalRows', res.data.result.total)
      }).catch(() => this.loading = false)
    },
    removeConflictionConditionsAndFilters() {
      let params = copyObject(this.params)
      let paramKeys = params ? Object.keys(params) : []
      let filterKeys = this.filters ? Object.keys(this.filters) : []
      let commonKeys = paramKeys.filter(pk => filterKeys.includes(pk))
      commonKeys.forEach(ck => delete params[ck])
      return params
    },
    generateParams() {
      let params = this.removeConflictionConditionsAndFilters()
      return {
        condition:   params,
        sort:        this.sort,
        order:       this.order,
        pagination:  this.pagination,
        search:      this.search,
        filters:     this.filters,
        branch_id:   this.branch,
        resource:    this.resource,
        collection:  this.collection
      }
    },
    emitAllParams() {
      let params = this.generateParams()
      params.page = this.page
      params.model = this.options.model
      this.$emit('allParams', params)
    },
    resort(f) {
      if(!f.sortable) return
      const sortable_field = f.sortable === true ? f.id : f.sortable
      if(this.order === sortable_field) {
        this.sort = this.sort === 'asc' ? 'desc': 'asc'
      } else {
        this.sort = 'asc'
      }
      this.order = sortable_field
      this.load()
    },
    parseFilters() {
      let payload = {}
      if(this.$route.query.filters) {
        payload = JSON.parse(this.$route.query.filters)
      }
      this.$store.commit('setFilters', payload)
      setTimeout(() => this.filterWatcher = true, 200)
    },
    checkboxChecked(event, index) {
      if (event.shiftKey && (this.lastCheckedIndex !== null) && (this.lastCheckedIndex !== index)) {
        const dir = index > this.lastCheckedIndex ? 1 : -1
        for (let i = (this.lastCheckedIndex + dir); i !== index; i += dir) {
          let id = this.list[i].id
          if(!this.selected.includes(id)) {
            this.selected.push(id)
          } else {
            this.selected.splice(this.selected.indexOf(id), 1)
          }
        }
      }
      this.lastCheckedIndex = index
    },
    checkboxAll(e) {
      let checked = e.target.checked
      if(checked) {
        this.selected = this.list.map(x => x.id)
      } else {
        this.selected = []
      }
    },
    cancelSelection() {
      this.selected = []
      this.checkboxMain = false
    },
    perPageChanged(val) {
      if(this.pagination != val) {
        this.pagination = val
        this.load()
      }
    },
    filterChange(filter, action = 'add', condition = 1) {
      if(action == 'add') this.$refs.datatablefilter.filterSelected(filter, condition)
      if(action == 'delete') this.$refs.datatablefilter.removeFilter(filter.id)
      this.$refs.datatablefilter.refresh()
    },
    toggleColumn(id) {
      const index = this.hiddenColumns.indexOf(id)
      if (index > -1) {
        this.hiddenColumns.splice(index, 1)
      } else {
        this.hiddenColumns.push(id)
      }
      window.localStorage.setItem(this.$route.name+'_hiddencolumns', JSON.stringify(this.hiddenColumns))
    }
  },
  watch: {
    $route() {
      if(this.$route.query.page !== this.page) {
        this.page = this.$route.query.page
      }
      this.load()
    },
    search() {
      if(this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.page = 1
        this.load()
      }, 250)
    },
    counter() {
      this.load()
    },
    selected(val) {
      console.log(val)
      this.$emit('checkboxSelected', val)
    }
  },
  computed: {
    ...mapState({
      search: state => state.datatable.search,
      counter: state => state.datatable.dataTableCounter,
      filters: state => state.datatable.filters,
      superadmin: state => state.auth.superadmin,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
    }),
    mobile() {
      return window && window.innerWidth < 768
    },
    needsMoreButton() {
      if(!this.options.fields) { return false }
      return (this.options.fields.reduce((acc, cur) => cur.table ? ++acc : acc, 0) > 3)
    },
    tdcomponents () {
      const components = require.context(`@/components/parts/datatable/td/`, true, /\.vue$/i)
      let arr = {}
      components.keys().map(x => {
        arr[x.split('/').pop().split('.')[0]] = components(x).default
      })
      return arr
    },
    trcomponents () {
      const components = require.context(`@/components/parts/datatable/tr/`, true, /\.vue$/i)
      let arr = {}
      components.keys().map(x => {
        arr[x.split('/').pop().split('.')[0]] = components(x).default
      })
      return arr
    }
  },
  unmounted() {
    this.$store.commit('setEloquentOptions', null)
  }
}
</script>
