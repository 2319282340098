<template>
  <div class="form-control gray-input d-flex align-items-center position-relative">
    <span ref="docListBtn"
          class="doctor-span pointer"
          @click="showHide">
      {{ text }}
    </span>
    <transition name="fade" mode="out-in">
      <div v-if="popupShowed">
        <div class="d-doctor-selector view-dd-selector position-absolute pb-1"
             v-closable="{
            exclude: ['docListBtn'],
            handler: 'onClose'
        }">
          <div class="px-3 py-2 d-flex flex-fill">
            <div class="w-100 py-1">
              <a @click="onSelectAll" href="#">{{ $t('choose_all') }}</a>
            </div>
            <div class="w-100 py-1">
              <a @click="onDeselectAll" href="#">{{ $t('clear') }}</a>
            </div>
          </div>
          <div class="d-doctor-selector__container"
               :style="{'max-height': maxHeight() + 'px' }">
            <DDoctorItem v-for="employee in employees"
                         :key="employee.id"
                         @click.native.stop="onDocTap(employee)"
                         v-model="selected"
                         :doc="employee" />
          </div>
          <!--      <div class="d-doctor-selector__submit p-2"-->
          <!--           @click="saveSelected">-->
          <!--        {{ $t('save') }}-->
          <!--      </div>-->
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DDoctorItem from "@/components/pages/calendar/calendar/DDoctorItem"
import { Closable } from "@/components/pages/calendar/calendar/directives/CloseOutside"

export default {
  name: "DDoctors",
  components: {
    DDoctorItem
  },
  props: {
    onSaveList: Function
  },
  computed: {
    ...mapState({
      doctors: state => state.calendar.doctors,
      staff: state => state.calendar.staff,
      selectedDoctors: state => state.calendar.selectedDoctors,
      selectedAdministrators: state => state.calendar.selectedAdministrators,
      selectedAssistants: state => state.calendar.selectedAssistants,
      scheduleView: state => state.calendar.scheduleView,
    }),
    employees() {
      if(this.scheduleView === 'doctors') return this.doctors
      return this.staff
    },
    selectedEmployees() {
      if(this.scheduleView === 'administrators') return this.selectedAdministrators
      if(this.scheduleView === 'assistants') return this.selectedAssistants
      return this.selectedDoctors
    },
    commitName() {
      if(this.scheduleView === 'administrators') return 'setSelectedAdministrators'
      if(this.scheduleView === 'assistants') return 'setSelectedAssistants'
      return 'setSelectedDoctors'
    },
    text() {
      const length = this.selected.length
      if (length === 0) return this.scheduleView === 'doctors' ? this.$t('select_a_doctor') : this.$t('select_an_employee')
      if (length === 1) {
        const name = this.employees.find(x => x.id === this.selected[0])?.full_name || null
        return name ? `${this.$t('selected')}: ${name}` : this.$t('select_an_employee')
      }
      return `${this.scheduleView === 'doctors' ? this.$t('doctors_selected') : this.$t('employees_selected')}: ${length}`
    },
  },
  data() {
    return {
      selected: [],
      popupShowed: false,
    }
  },
  methods: {
    showHide() {
      this.popupShowed = !this.popupShowed
    },
    onSelectAll() {
      this.selected = this.employees.map(item => item.id)
      this.saveSelected()
    },
    onDeselectAll() {
      this.selected = []
      this.saveSelected()
    },
    async saveSelected() {
      // if (!this.selected.length) {
      //   alert(this.scheduleView === 'doctors' ? this.$t('need_to_select_at_least_one_doctor') : this.$t('need_to_select_at_least_one_employee'))
      //   return
      // }
      const newList = this.employees.filter(item => this.selected.includes(item.id))
      this.$store.commit(this.commitName, this.copyObject(newList))
      await this.$nextTick()
      // this.popupShowed = false
      this.onSaveList()

    },
    onDocTap(doc) {
      // console.log(this.selected)
      if (!this.selected.includes(doc.id)) {
        this.selected.push(doc.id)
      } else {
        this.selected.splice(this.selected.indexOf(doc.id), 1)
      }
      this.saveSelected()
      // if(!this.select.length) { // we do not allow to deselect all
      //   this.selected.push(doc.id)
      // }
    },
    onClose() {
      this.popupShowed = false
    },
    maxHeight() {
      return window.innerHeight - 250
    }
  },
  directives: {
    Closable
  },
  watch: {
    doctors() {
      this.selected = this.selectedEmployees.map(item => item.id)
    },
    staff() {
      this.selected = this.selectedEmployees.map(item => item.id)
    },
    scheduleView() {
      this.selected = this.selectedEmployees.map(item => item.id)
    }
  }
}
</script>

<style scoped lang="scss">
.doctor-span {
  line-height: 1.2;
}
.d-doctor-selector {
  width: 300px;
  top:45px;
}
.d-doctor-selector__container {
  border-top: 1px solid #E5E5E5;
  overflow-y: auto;
}
.d-doctor-selector__submit {
  color: white;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  background-color: #0787C9;
  cursor: pointer;
}
.d-doctor-selector__submit:hover {
  background-color: #0977AF;
}

@media screen and (max-width: 768px) {
  .d-doctor-selector {
    top: 35px;
    width: 250px;
  }
}
</style>
