import options from "@/data/staff/assistant"
import { copyObject } from "@/extensions/prototypes/prototypes"

let technic_options = copyObject(options)
technic_options.model = 'technic'

technic_options.fields.forEach(item => {
    if(['sort_order'].includes(item.id)) { item.table = false }
    if(['rate_per_day', 'report_days_limit'].includes(item.id)) { delete item.form }
})
technic_options.actionscomponent = 'StaffDatatableTechnicActions'
export default technic_options
