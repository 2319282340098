<template>
  <span>
    <a @click="addService"
       :title="$t('add_service')"
       v-b-tooltip.hover
       v-if="rights.includes('clinic_services') && !superadmin"
       class="tdclickable data-table-action">
      <NoteSvg/><span class="action-button-mob-title">{{ $t('add_service') }}</span>
    </a>
    <a v-if="(!object.parent_id || object.parent_id == 0) && rights.includes('clinic_services') && !superadmin"
       @click="addSubcategory"
       :title="$t('add_category')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
      <FolderSvg/><span class="action-button-mob-title">{{ $t('add_category') }}</span>
    </a>
    <a @click="printCategory"
       v-if="!superadmin"
       :title="$t('print')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
      <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
    </a>
  </span>
</template>

<script>

import NoteSvg from '@/assets/svg-vue/services/note_add.svg'
import FolderSvg from '@/assets/svg-vue/services/folder_add.svg'
import PrintSvg from '@/assets/svg-vue/general/print2.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
// import  { EloquentService } from "@/services/api.service"
// import { serviceAdapter } from '@/adapters/services/service'

export default {
  name: "ServicesDatatableActionsServiceCategory",
  components: {
    NoteSvg,
    PrintSvg,
    FolderSvg

  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
      superadmin: state => state.auth.superadmin,
    })
  },
  methods: {
    addService() {
      this.$store.commit('setViewModel', 'service')
      this.$store.commit('setModule', 'services')
      this.$store.commit('setSelectedModel', {
        category_id: this.object.id,
        visible: 1
      })
      this.$store.commit('setShowModal', true)
    },
    addSubcategory() {
      this.$store.commit('setModule', 'services')
      this.$store.commit('setSelectedModel', {
        parent_id: this.object.id
      })
      this.$store.commit('setShowModal', true)
    },
    printCategory() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/pricelist/'+this.object.id+'?branchId='+this.branch, '_blank')
    }
  }
}
</script>
