<template>
  <router-link :to="{ name: 'MarketingMailingItem', params: {id: object.id }}" class="font-weight-bold">
    {{ object.title }}
  </router-link>
</template>

<script>
export default {
  name: "MarketingDatatableTDMailingTitle",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">

</style>
