<template>
  <b-modal id="module-not-in-tariff-modal"
           :title="$t('module_unavailable')"
           centered>
    {{ $t('this_module_is_not_in_tariff') }}
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons no-print">
        <b-button class="cancel d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"

export default {
  name: "ModuleNotInTariffModal",
  components: {
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
    }),
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

</style>
