<template>
  <div class="d-record__icon" :class="{ playing: isOpenedNow }">
    <component
        :title="$t(record.iconStatus)"
        :is="icon"/>
  </div>
</template>

<script>

import PlaySvg from '@/assets/svg-vue/calendar/record_play.svg'
import PaidSvg from '@/assets/svg-vue/calendar/record_success_payment.svg'
import DebtSvg from '@/assets/svg-vue/calendar/record_debt.svg'
import DidNotComeSvg from '@/assets/svg-vue/calendar/record_dint_come.svg'
import CameSvg from '@/assets/svg-vue/calendar/record_came.svg'
import NotConfirmedSvg from '@/assets/svg-vue/calendar/record_wait_for_approve_visit.svg'
import ConfirmedSvg from '@/assets/svg-vue/calendar/record_confirmed.svg'
import LockSvg from '@/assets/svg-vue/calendar/record_blocked.svg'
import moment from "moment"
import { shallowRef } from "vue"

export default {
  name: "DRecordStatusIcon",
  components: {
    PaidSvg,
    LockSvg,
    DebtSvg,
    CameSvg,
    NotConfirmedSvg,
    DidNotComeSvg,
    ConfirmedSvg,
    PlaySvg
  },
  props: {
    record: Object,
  },
  computed: {
    isOpenedNow() {
      return this.record.opened_visit?.id
    },
    icon() {
      if(this.isOpenedNow) return PlaySvg
      return this.components[this.record.iconStatus]
    }
  },
  data() {
    return {
      now: moment().format('YYYY-MM-DD HH:mm:ss'),
      components: {
        'blocked': shallowRef(LockSvg),
        'paid': shallowRef(PaidSvg),
        'debt': shallowRef(DebtSvg),
        'did_not_come': shallowRef(DidNotComeSvg),
        'confirmed': shallowRef(ConfirmedSvg),
        'not_confirmed': shallowRef(NotConfirmedSvg),
        'in_chair': shallowRef(CameSvg),
        'came': shallowRef(CameSvg),
      }
    }
  },
}
</script>

<style scoped lang="scss">
.d-record__icon {
  position: relative;
  svg {
    margin-right: 5px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  &.playing::after {
    animation: pulse 1000ms cubic-bezier(0.9, 0.7, 0.5, 0.9) infinite;
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
    background: rgba(94, 146, 70, 0.4);
    border-radius: 50%;
    z-index: -1;
  }
}
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.1;
  }
  50% {
    transform: scale(1.3);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0.1;
  }
}
</style>
