<template>
  <div :class="{'text-danger': object.valid_to < today } ">
    {{ formatDate(object.valid_to) }}
  </div>
</template>

<script setup>
import { formatDate } from "@/extensions/filters/filters"
import moment from 'moment'

defineProps({
  object: {
    type: Object
  },
})

const today = moment().format('YYYY-MM-DD')
</script>
