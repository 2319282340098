export const warehouseAdapter =
    ({
         id,
         title,
         address,
         type,
         branch_id,
     }) => ({
        id: id ?? null,
        title: title ?? null,
        address: address ?? null,
        type: type ?? null,
        branch_id: branch_id ?? null,
    })
