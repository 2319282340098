<template>
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M19.5224 5.74086C19.0705 5.95947 18.8814 6.50324 19.1 6.9549C19.6973 8.1887 20 9.5186 20 10.9091C20 15.9217 15.9217 20 10.9091 20C5.89644 20 1.81818 15.9217 1.81818 10.9091C1.81818 5.89644 5.89644 1.81818 10.9091 1.81818C12.9863 1.81818 14.9376 2.49645 16.5527 3.77952C16.9445 4.09224 17.5173 4.02677 17.83 3.6337C18.1428 3.24086 18.0773 2.66868 17.684 2.3564C15.7719 0.836736 13.3658 0 10.9091 0C4.89413 0 0 4.89413 0 10.9091C0 16.924 4.89413 21.8182 10.9091 21.8182C16.924 21.8182 21.8182 16.924 21.8182 10.9091C21.8182 9.24228 21.4542 7.64493 20.7364 6.16322C20.5182 5.71045 19.9727 5.52179 19.5224 5.74086Z"
      fill="#0787C9"/>
  <path
      d="M10.9091 3.63636C10.4073 3.63636 10 4.04363 10 4.54545V10.9091C10 11.4109 10.4073 11.8182 10.9091 11.8182H15.4545C15.9564 11.8182 16.3636 11.4109 16.3636 10.9091C16.3636 10.4073 15.9564 10 15.4545 10H11.8182V4.54545C11.8182 4.04363 11.4109 3.63636 10.9091 3.63636Z"
      fill="#0787C9"/>
  <path
      d="M18.5454 24.5C21.834 24.5 24.5 21.8341 24.5 18.5455C24.5 15.2569 21.834 12.5909 18.5454 12.5909C15.2568 12.5909 12.5909 15.2569 12.5909 18.5455C12.5909 21.8341 15.2568 24.5 18.5454 24.5Z"
      fill="#D8F0FC" stroke="white"/>
  <path
      d="M18.6276 20.6222C18.5651 20.6896 18.4799 20.7273 18.3913 20.7273C18.3028 20.7273 18.2175 20.6896 18.155 20.6222L16.2681 18.5968C16.0723 18.3866 16.0723 18.0458 16.2681 17.836L16.5044 17.5824C16.7002 17.3722 17.0174 17.3722 17.2132 17.5824L18.3913 18.8468L21.5746 15.4303C21.7705 15.2202 22.088 15.2202 22.2835 15.4303L22.5198 15.684C22.7156 15.8941 22.7156 16.2349 22.5198 16.4447L18.6276 20.6222Z"
      fill="#0787C9"/>
</svg>
</template>
