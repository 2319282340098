<template>
  <div class="mt-4 patient-comments">
    <div class="nowrap overflow-scroll">
      <CheckboxGroup v-for="c in checks"
                     :value="checked"
                     :checkboxvalue="c.id"
                     class="d-inline-block mr-3"
                     :key="`check-${c.id}`"
                     :fid="`check-${c.id}`"
                     @input="(...args) => checkboxInput(c.id, ...args)"
                     :label="$t(c.title)"/>
    </div>
    <div>
      <CheckboxGroup :label="$t('show_images')"
                     class="sm"
                     fid="show_images"
                     v-model="showImages"/>
    </div>
    <b-row>
      <b-col v-if="patient && patient.id" lg="12">
        <div>
          <AutosizeTextArea v-model="comment"
                            labelclass="black"
                            :label="$t('leave_comment')"/>
          <div class="text-right" v-if="comment">
            <label class="mr-3 text-muted pointer" for="files" v-if="!files.length">
              <ClipSvg class="mr-2"/>{{ $t('add_attachment') }}
            </label>
            <div v-if="files.length" class="d-inline-block mr-3">
              <div v-for="(f, find) in files" :key="`k-${find}`">
                {{ f.name }}
                <CloseSvg class="svg-gray close-svg svg-size-sm ml-2 pointer" @click="deleteFile(find)"/>
              </div>
            </div>
            <input type="file"
                   accept=".xls, .jpg, .xlsx, .doc, .docx, .pdf, .txt, .jpeg, .png, .gif"
                   class="d-none"
                   :key="`key-${keyCounter}`"
                   id="files"
                   @change="processFile($event)"/>
            <button class="btn-themed btn-themed-squared"
                    @click="saveComment"
                    :disabled="loading || !comment.length">
              {{ $t('send') }}
            </button>
          </div>

        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12" class="mb-5">
        <PatientsCommentsList :patient="patient"
                              :checked="checked"
                              ref="commentsList"
                              :show-images="showImages"/>
      </b-col>
    </b-row>
    <LeadShowModal/>
  </div>
</template>

<script>

import { mapState } from "vuex"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import { EloquentService } from "@/services/api.service"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import ClipSvg from '@/assets/svg-vue/form/clip.svg'
import CloseSvg from '@/assets/svg-vue/general/close.svg'
import LeadShowModal from "@/components/parts/crm/LeadShowModal.vue"
import PatientsCommentsList from "@/components/parts/patients/comment/PatientsCommentsList.vue"
import { patientCommentTypes } from "@/dictionaries/dictionary"
import { appendFormdata, copyObject } from "@/extensions/prototypes/prototypes"

export default {
  name: "PatientComments",
  components: {
    PatientsCommentsList,
    LeadShowModal,
    CheckboxGroup,
    AutosizeTextArea,
    ClipSvg,
    CloseSvg
  },
  head() {
    return {
      title: this.$t("live_feed"),
    }
  },
  data() {
    return {
      checks: patientCommentTypes,
      checked: [],
      comment: '',
      files: [],
      keyCounter: 1,
      showImages: true
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      loading: state => state.dom.loading,
      // counter: state => state.datatable.dataTableCounter,
    }),
  },
  mounted() {
    const savedFilters = window.localStorage.getItem('patient_comments_filters')
    this.checked = savedFilters ? JSON.parse(savedFilters) : this.checks.map(x => x.id)
  },
  methods: {
    async saveComment() {
      if(!this.comment) return
      if(!this.patient || !this.patient.id) return
      const formData = appendFormdata(new FormData(), {
        user_id: this.patient.id,
        text: this.comment
      })
      if(this.files.length) {
        appendFormdata(formData, { files: this.files })
      }
      await EloquentService.create('patient_comment', formData)
      this.comment = ''
      this.files = []
      this.keyCounter++
      await this.$refs.commentsList.getComments(true)
    },
    checkboxInput(clicked, all) {
      if(clicked === 'all' && all.includes('all')) {
        this.checked = copyObject(this.checks.map(x => x.id))
        this.saveFilters()
        return
      }
      if(clicked === 'all' && !all.includes('all')) {
        this.checked = []
        this.saveFilters()
        return
      }
      this.checked = all.filter(x => x !== 'all')
      if(this.checked.length === (this.checks.length - 1)) {
        this.checked.push('all')
      }
      this.saveFilters()
    },
    saveFilters() {
      window.localStorage.setItem('patient_comments_filters', JSON.stringify(this.checked))
    },
    processFile(event) {
      let file = event.target.files[0]
      if(!file) return
      if(file.size > (this.sizelimit * 1024 * 1024)) {
        this.$noty.error(this.$t('file_size_is_too_big'))
        return
      }
      this.files.push(file)
    },
    deleteFile(index) {
      this.files.splice(index, 1)
      this.keyCounter++
    },
  },
}
</script>

<style scoped lang="scss">
.img-preview {
  width: 220px;
  height: 180px;
  object-fit: cover;
  margin-right: 15px;
}
.patient-comments {
  max-width: 1200px;
  .comment-block {
    white-space: break-spaces;
    word-break: break-word;
  }
}
</style>
