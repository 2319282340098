<template>
  <div>
    <div class="mb-3" v-if="ppoData.vendor === 'kkm'">
      <div v-if="kkmData">
        <div v-if="kkmData.SessionNumber">{{ $t('shift_number') }}: <b>{{ kkmData.SessionNumber }}</b></div>
        <div v-if="kkmData.CheckNumber">{{ $t('document_number') }}: <b>{{ kkmData.CheckNumber }}</b></div>
      </div>
      <div v-else>
        <b-spinner/>
      </div>
      <div class="mt-4">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline"
                :disabled="loading"
                @click="openShift">
          {{ $t('open_shift') }}
        </button>
      </div>
      <div class="mt-3">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline"
                :disabled="loading"
                @click="closeShift">
          {{ $t('close_shift') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import { KKMService } from "@/services/kkm.service"

export default {
  name: "CashdeskKKMShifts",
  components: {
  },
  head() {
    return {
      title: this.$t('shifts')
    }
  },
  computed: {
    ...mapState({
      ppoData: state => state.ppo.ppoData,
      counter: state => state.datatable.dataTableCounter,
    }),
  },
  data() {
    return {
      loading: false,
      kkmData: null,
    }
  },
  methods: {
    async openShift() {
      this.loading = true
      const res = await KKMService.openShift()
      if(res.error) {
        this.loading = false
        this.$noty.error(res.error)
      } else {
        await this.loadKKMData()
        this.$noty.info(this.$t('success_message'))
      }
    },
    async closeShift() {
      this.loading = true
      const res = await KKMService.closeShift()
      if(res.error) {
        this.loading = false
        this.$noty.error(res.error)
      } else {
        await this.loadKKMData()
        this.$noty.info(this.$t('success_message'))
      }
    },
    async loadKKMData() {
      const res = await KKMService.getKKMInfo()
      this.kkmData = res.data
      this.loading = false
    }
  },
  mounted() {
    this.loadKKMData()
  }
}
</script>
