<template>
  <b-modal id="visit-result-modal"
           hide-footer
           :title="$t('visit_result')"
           size="sm"
           centered>
    <a v-for="result in visitResults"
       class="d-flex justify-content-between align-items-center pointer py-2 text-black border-bottom"
       :key="`stage-${result.id}`"
       @click="saveResult(result.id)">
      <span>{{ result.translatedTitle }}</span>
      <span class="text-muted font-weight-bold">{{ result.translatedShortTitle }}</span>
    </a>
  </b-modal>
</template>

<script setup>
import { useStore } from "vuex"
import { computed, getCurrentInstance, onMounted } from "vue"

const store = useStore()

const emit = defineEmits(['changed'])

const visitResults = computed(() => store.state.teeth.visitResults)

let bvModal = null

onMounted(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
})

const saveResult = async (resultId) => {
  bvModal.hide('visit-result-modal')
  emit('changed', resultId)
}
</script>
