<template>
  <span>
  <a class="data-table-action"
     :title="$t('retry')"
     v-b-tooltip.hover
     v-if="object.status === 'error'"
     @click="retry">
    <RefreshSvg/><span class="action-button-mob-title">{{ $t('retry') }}</span>
  </a>
  <a :title="$t('cancel_sending')"
     v-if="object.status === 'waiting'"
     v-b-tooltip.hover
     class="data-table-action"
     @click="cancelNotification">
    <BlockSvg/><span class="action-button-mob-title">{{ $t('cancel_sending') }}</span>
  </a>
    </span>
</template>

<script>

import RefreshSvg from '@/assets/svg-vue/general/refresh2.svg'
import BlockSvg from '@/assets/svg-vue/general/block.svg'
import { mapState } from "vuex"
import { EloquentService, MarketingService } from "@/services/api.service"

export default {
  name: "MarketingNotificationAllDatatableActions",
  components: {
    RefreshSvg,
    BlockSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    async retry() {
      let res = await MarketingService.resendNotification(this.object.id)
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }
    },
    async cancelNotification() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, { sms_error_code: 999 })
      await EloquentService.update('notification', this.object.id, formData, this.superadmin)
      this.$store.commit('incrementDataTableCounter')
    },
  }
}
</script>
