<template>
  <div class="right-pane"
       :class="{ opened }"
       v-if="jetAPIPanelEnabled">
    <div class="container" style="height: 50px">
      <div class="text-right">
        <a @click="toggleJetAPIChat" class="d-inline-flex pointer py-3">
          <CloseSvg class="svg-light svg-size-md"/>
        </a>
      </div>
    </div>
<!--    <div style="word-break: break-all">{{ fullIframeUrl }}</div>-->
    <div v-if="iframeUrl">
      <iframe :src="fullIframeUrl" class="jetapi-iframe"></iframe>
    </div>
  </div>
</template>

<script setup>
import CloseSvg from '@/assets/svg-vue/general/close.svg'
import { computed, ref, watch } from "vue"
import { useStore } from "vuex"
import { ClinicService, WhatsappService } from "@/services/api.service"

const store = useStore()
const iframeUrl = ref(null)
if(store.getters.getPPOAttribute('jetapi_iframe_url')) {
  iframeUrl.value = store.getters.getPPOAttribute('jetapi_iframe_url')
  if(store.state.dom.phoneForJetApi) {
    iframeUrl.value += `&phone=${store.state.dom.phoneForJetApi}`
  }
}

const opened = computed(() => store.state.dom.rightPaneOpened)
const clinicId = computed(() => store.state.auth.clinic.id)
const superadmin = computed(() => store.state.auth.superadmin)
const jetAPIPanelEnabled = computed(() => store.getters.jetAPIPanelEnabled)
const fullIframeUrl = computed(() => {
  const url = iframeUrl.value
  if(store.state.dom.phoneForJetApi) {
    return `${url}&phone=${store.state.dom.phoneForJetApi}`
  }
  return url
})

const toggleJetAPIChat = () => {
  store.state.dom.rightPaneOpened = !store.state.dom.rightPaneOpened
}

watch(opened, async (val) => {
  if(!val) {
    store.state.dom.phoneForJetApi = null
  }
  if(iframeUrl.value) return
  if(val) {
    const link = await WhatsappService.getJetApiIFrameLink()
    iframeUrl.value = link?.data?.link || null
    if(iframeUrl.value) {
      await ClinicService.savePPOAttribute([
        'jetapi_iframe_url',
      ], [
        iframeUrl.value
      ], superadmin.value, clinicId.value)
    }
  } else {
    iframeUrl.value = null
  }
})
</script>

<style>
.right-pane {
  position: fixed;
  right: 0;
  width: 0;
  height: 100vh;
  top: 0;
  background: #fff;
  z-index: 10;
  border-left: 1px solid #dee2e6;
  transition: width 0.2s;
  &.opened {
    width: 320px;
  }
}
.jetapi-iframe {
  width: 100%;
  border: none;
  height: calc(100vh - 50px);
}
@media screen and (max-width: 768px) {
  .right-pane {
    &.opened {
      width: 100%;
    }
  }
}
</style>
