<template>
  <div>
    <SortBtn model-name="doctor"/>
    <DataTable :options="options"
               :params="{ branch_id: branch }"
               module="staff"/>
    <DoctorScheduleModal/>
  </div>
</template>

<script>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/staff/doctor"
import DoctorScheduleModal from "@/components/parts/staff/DoctorScheduleModal"
import { mapActions, mapState } from "vuex"
import SortBtn from "@/components/parts/datatable/SortBtn.vue"

export default {
  name: "Doctors",
  head() {
    return {
      title: this.$t('doctors'),
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
      doctors: state => state.calendar.doctors,
      counter: state => state.datatable.dataTableCounter,
      selectedDoctors: state => state.calendar.selectedDoctors,
    }),
  },
  components: {
    SortBtn,
    DataTable,
    DoctorScheduleModal
  },
  data() {
    return {
      options: options,
      allDoctorsSelected: false
    }
  },
  async mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', { branch_id: this.branch })
    if(!this.doctors.length) await this.getDoctors(this.branch)
    this.allDoctorsSelected = this.selectedDoctors.length === this.doctors.length
  },
  beforeUnmount() {
    this.$store.commit('setAdditionalParams', {})
  },
  methods: {
    ...mapActions([
      'getDoctors',
    ]),
  },
  watch: {
    async counter() { // in case amount of doctors increased
      await this.getDoctors(this.branch)
      await this.$nextTick()
      if(this.allDoctorsSelected) {
        this.$store.commit('setSelectedDoctors', this.doctors)
      }
    }
  }
}
</script>
