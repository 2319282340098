export default {
    primaryKey: 'id',
    model: 'other_income_category',
    modaltitle: 'category',
    order: 'name',
    sort: 'asc',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            tabletdclass: "order-mob-0",
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true
            },
        },
        {
            id: 'type',
            title: 'type',
            table: true,
            sortable: true,
            format_translate: true,
            table_show: 'type',
            form: {
                type: "select",
                required: true,
                select_type: "list",
                options: [
                    { id: "profit", title: "profit" },
                    { id: "expense", title: "expense" },
                ]
            }
        },
    ]
}
