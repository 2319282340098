<template>
  <div>
    <DataTable :options="options"
               module="staff"/>
  </div>
</template>

<script setup>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/staff/custom_role"
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, onMounted, ref } from "vue"
import { useStore } from "vuex"

const store = useStore()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('roles')
})

onMounted(() => {
  store.commit('setEloquentOptions', options)
})
</script>
