<template>
  <div class="mt-3 pb-5">
    <div>
      <div style="max-width: 800px;" class="text-muted">
        Для автоматизации заполнения отчета привяжите услуги к указанным ниже категориям и нажмите сохранить.
        <!--      Таким образом будет подсчитываться кол-во зубов, по которым были оказаны данные услуги, и полученные данные будут подставляться в соответствующие категории отчета.-->
      </div>
      <div v-for="(c, cind) in f057Categories" :key="`ind-${cind}`">
        <template v-if="c.type === 'title'">
          <h5 class="mt-4">{{ c.title }}</h5>
        </template>
        <template v-if="c.type === 'category'">
          <div class="font-weight-bold mt-3 mb-3">{{ c.title }}</div>
        </template>
        <template v-if="c.type === 'item'">
          <b-row class="align-items-center mb-2">
            <b-col lg="2" md="3" sm="6" cols="12">
              {{ c.title }}
            </b-col>
            <b-col lg="4" md="5" sm="6" cols="12">
              <ServiceMultiSelect :dblclick="false"
                                  class="mb-0"
                                  v-model="mapping[c.id]"/>
            </b-col>
          </b-row>
        </template>
      </div>
      <b-row class="mt-4">
        <b-col lg="6" md="8" class="text-right">
          <button class="btn btn-themed btn-themed-squared"
                  @click="save"
                  :disabled="loading">
            {{ $t('save') }}
          </button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

// import { ReportsService } from "@/services/api.service"
// import DataTableHtml from "@/components/parts/general/DataTableHtml"

import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import { f057Categories } from "@/dictionaries/dictionary"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { ReportsService } from "@/services/api.service"

export default {
  name: "Report57",
  components: {
    ServiceMultiSelect
  },
  props: {
    from: {},
    to: {},
    oneDoctor: Number,
    f57Type: String,
  },
  head() {
    return {
      title: this.$t('f057'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branchId: state => state.auth.branch,
      loading: state => state.dom.loading,
    }),
  },
  data() {
    return {
      rows: [],
      f057Categories,
      mapping: {}
    }
  },
  methods: {
    loadReport() {
      if(!this.oneDoctor) {
        this.$noty.error(this.$t('need_to_select_at_least_one_doctor'))
        return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      let url = link +'/document/f57?t=r'
      if(this.from) { url += '&from='+this.from }
      if(this.to) { url += '&to='+this.to }
      if(this.oneDoctor) { url += '&doctor='+this.oneDoctor }
      if(this.f57Type) { url += '&type='+this.f57Type }
      url += `&branch_id=${this.branchId}`
      window.open(url, '_blank')
    },
    async loadMapping() {
      let res = await ReportsService.getF057Mapping()
      this.mapping = res.data
    },
    async save() {
      const data = []
      Object.keys(this.mapping).forEach(mappingId => {
        const services = this.mapping[mappingId]
        if(services) {
          services.forEach(service => {
            data.push({ service_id: service.id, mapping: mappingId })
          })
        }
      })
      const res = await ReportsService.saveF057Mapping({ mapping: data })
      this.$noty.info(this.$t('success_message'))
      this.mapping = res.data
    }
  },
  mounted() {
    this.loadMapping()
  }
}
</script>
