<template>
  <section id="countries" class="countries">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row" >
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('countries') }}</h1>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <DataTable :options="options"
                 module="superadmin"/>
    </div>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/country"
import { mapState } from "vuex"

export default {
  name: "SuperAdminCountries",
  components: {
    DataTable
  },
  head() {
    return {
      title: this.$t('users'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
