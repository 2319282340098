<template>
    <svg width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.50006 3.16669C2.17508 3.16669 2.7223 2.61948 2.7223 1.94446C2.7223 1.26944 2.17508 0.722229 1.50006 0.722229C0.825044 0.722229 0.277832 1.26944 0.277832 1.94446C0.277832 2.61948 0.825044 3.16669 1.50006 3.16669Z"
              fill="#87939E"/>
        <path d="M1.50006 7.44446C2.17508 7.44446 2.7223 6.89725 2.7223 6.22223C2.7223 5.54721 2.17508 5 1.50006 5C0.825044 5 0.277832 5.54721 0.277832 6.22223C0.277832 6.89725 0.825044 7.44446 1.50006 7.44446Z"
              fill="#87939E"/>
        <path d="M1.50006 11.7222C2.17508 11.7222 2.7223 11.175 2.7223 10.5C2.7223 9.82498 2.17508 9.27776 1.50006 9.27776C0.825044 9.27776 0.277832 9.82498 0.277832 10.5C0.277832 11.175 0.825044 11.7222 1.50006 11.7222Z"
              fill="#87939E"/>
    </svg>
</template>
