<template>
  <div class="d-week d-flex">
    <DMonthWeekDay v-for="day in week.days"
                   :key="day.format('L')"
                   :day="day"
                   :last="last"
                   :recordMinutes="dayRecordMinutes(day)"
                   :quantity="dayRecords(day)" />
  </div>
</template>

<script>
import DMonthWeekDay from "@/components/pages/calendar/calendar/DMonthWeekDay"
import { mapState } from "vuex"

export default {
  name: "DMonthWeek",
  components: {
    DMonthWeekDay
  },
  props: {
    week: Object,
    last: Boolean
  },
  computed: {
    ...mapState({
      records: state => state.calendar.records
    }),
  },
  methods: {
    dayRecords(day) {
      const dayStr = day.format('YYYY-MM-DD')
      return this.records && this.records[dayStr] ? parseInt(this.records[dayStr].cnt) : 0
    },
    dayRecordMinutes(day) {
      const dayStr = day.format('YYYY-MM-DD')
      return this.records && this.records[dayStr] ? parseInt(this.records[dayStr].recordMinutes) : 0
    }
  }
}
</script>

<style scoped>

.d-week {
  border-bottom: 1px dashed #E5E5E5;
}
</style>
