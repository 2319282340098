<template>
  <div class="row align-items-center">
    <div class="col-auto" v-if="!editing">{{ name }}</div>
    <div class="col-auto">
      <div class="row align-items-center">
        <div class="col" v-if="editing">
          <ServiceMultiSelect :dblclick="false"
                              :model="modelName"
                              class="mb-0 sm"
                              :prepopulated-options="options"
                              :autocomplete-additional-params="autocompleteAdditionalParams"
                              :multiple="false"
                              :title="title"
                              :dbsearch="dbSearch"
                              :valueId="valueId"
                              v-model="obj"/>
        </div>
        <div class="col-auto">
          <a @click="editToggle" class="pointer d-inline-flex">
            <EditSvg v-if="!editing" class="svg-light svg-size-sm"/>
            <CloseSvg v-if="editing" class="svg-light svg-size-sm"/>
          </a>
          <a v-if="editing"
             class="pointer px-3"
             @click="accept">
            <TickSvg class="svg-primary svg-size-sm"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import {computed, defineEmits, ref} from "vue"
import TickSvg from "@/assets/svg-vue/form/tick.svg.vue"
import CloseSvg from "@/assets/svg-vue/general/close.svg.vue"

const props = defineProps({
  value: {},
  modelName: {
    type: String,
    required: true
  },
  title: {
    type: String,
    default: 'full_name'
  },
  autocompleteAdditionalParams: {
    type: Object,
    default: null
  },
  dbSearch: {
    type: Boolean,
    default: true
  },
  valueId: {
    type: String,
    default: 'id'
  },
  options: {

  }
})

const emit = defineEmits(['update:modelValue', 'input'])

const editing = ref(false)
const obj = ref(null)

const name = computed(() => props.value?.[props.title] || '-')

const editToggle = () => {
  if(!editing.value) {
    editing.value = true
    obj.value = props.value
    return
  }
  obj.value = props.value
  editing.value = false
}

const accept = () => {
  editing.value = false
  emit('input', obj.value)

}
</script>
