export const chairAdapter =
    ({
         id,
         chair_name,
         doctors,
         branch_id,
         warehouse_id,
     }) => ({
        id: id ?? null,
        chair_name: chair_name ?? null ,
        doctors: minimizeDoctors(doctors),
        branch_id: branch_id ?? null,
        warehouse_id: warehouse_id ?? null
    })

function minimizeDoctors(doctors) {
    return doctors ? [...new Set(doctors.map(({id}) => id))] : []
}

