<template>
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.59991 8.40002H5.3999V9.60003H6.59991V8.40002Z" fill="#B5C6D8"/>
  <path
      d="M5.99999 0C2.6863 0 0 2.6863 0 5.99999C0 9.31367 2.6863 12 5.99999 12C9.31367 12 12 9.31367 12 5.99999C12 2.6863 9.3137 0 5.99999 0ZM5.99999 10.914C3.28607 10.914 1.08599 8.71391 1.08599 5.99999C1.08599 3.28607 3.28607 1.08599 5.99999 1.08599C8.71253 1.08928 10.9107 3.28742 10.914 5.99999C10.914 8.71393 8.71393 10.914 5.99999 10.914Z"
      fill="#B5C6D8"/>
  <path
      d="M8.33412 4.23011C8.02548 2.94106 6.73029 2.14631 5.44124 2.45495C4.3575 2.71443 3.59495 3.68575 3.60012 4.80012H4.80013C4.85647 4.13739 5.43939 3.64579 6.10212 3.70212C6.76486 3.75846 7.25646 4.34138 7.20012 5.00411C7.07755 5.60047 6.54256 6.02136 5.93413 6.0001C5.63921 6.0001 5.40012 6.23919 5.40012 6.53411V7.8001H6.60013V7.1161C7.86913 6.78887 8.64102 5.5042 8.33412 4.23011Z"
      fill="#B5C6D8"/>
</svg>
</template>
