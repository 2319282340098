export const warehouseDocumentAdapter =
    ({
         id,
         title,
         warehouse_id,
         supplier_id,
         operation_type,
         comment,
         related_operation_id,
         related_warehouse_id,
         forma,
     }) => ({
        id: id ? id : null,
        title: title ? title : null,
        warehouse_id: warehouse_id ? warehouse_id : null,
        supplier_id: supplier_id ? supplier_id : null,
        operation_type: operation_type ? operation_type : null,
        comment: comment ? comment : null,
        related_operation_id: related_operation_id ? related_operation_id : null,
        related_warehouse_id: related_warehouse_id ? related_warehouse_id : null,
        forma: forma ? forma : null,
    })
