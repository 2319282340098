<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M19.3296 75.7742L21.4133 77.0439H24.897L26.997 75.7742L25.3854 74.2277L27.4691 72.6812L25.5807 71.1347L27.8761 69.4416L26.0528 67.8951L28.3482 66.4951L26.3296 65.0789L28.3482 63.4672L26.6552 61.9207L28.8854 60.5044L27.1272 59.0393L29.4226 57.4928L27.2738 55.9463L29.4226 54.3347L27.8761 52.9835L29.9598 51.437L28.1365 49.9556L30.2203 48.4091L28.4133 46.6673L29.4226 44.9905V30.7301L27.5342 23.4045H21.4133" fill="#8889BE"/>
      <path d="M26.7369 75.7742L24.6531 77.0439H21.1857L19.0694 75.7742L20.6973 74.2277L18.5973 72.6812L20.4857 71.1347L18.2067 69.4416L20.0136 67.8951L17.7346 66.4951L19.7532 65.0789L17.7346 63.4672L19.4113 61.9207L17.1974 60.5044L18.9392 59.0393L16.6601 57.4928L18.809 55.9463L16.6601 54.3347L18.2067 52.9835L16.1229 51.437L17.9299 49.9556L15.8462 48.4091L17.6694 46.6673L16.6601 44.9905V30.7301L18.5322 23.4045H22.4392" fill="#8889BE"/>
      <path d="M41.9765 22.5937L39.666 27.6766C38.4966 28.2509 37.2782 28.7195 36.0254 29.0769C32.9051 30.0693 29.5539 30.0693 26.4336 29.0769C23.311 27.6766 18.6762 29.4269 13.1312 29.763C11.4826 29.8659 9.82821 29.7052 8.23029 29.2869C6.67637 28.8539 5.20817 28.1577 3.8895 27.2285C3.8895 26.9345 3.81949 26.7524 3.80548 26.7104C2.7973 23.7559 1.34103 20.9274 1.187 16.2225C1.10299 13.422 0.976966 9.37527 3.65145 6.21069C6.56399 2.76606 11.1008 2.26197 13.0612 2.00992C15.2115 1.59877 17.4288 1.70435 19.5304 2.31798C20.3845 2.62604 24.4313 3.97028 25.6775 3.71824C26.3216 3.55021 26.6297 3.34016 26.6297 3.45218C26.6297 3.5642 34.4851 -0.244498 38.8119 2.21995C43.1387 4.6844 42.8166 6.54675 43.7408 11.9377C44.1621 15.5848 43.5508 19.277 41.9765 22.5937Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M40.5808 27.2297C40.4397 35.689 39.4024 44.1096 37.4863 52.3503C34.4057 64.9526 34.0976 61.914 32.2493 66.997C30.401 72.0799 14.214 84.1081 19.2969 74.4043C23.089 66.8058 24.8955 58.3709 24.5479 49.8858C24.3939 45.111 21.6074 46.0351 21.6074 46.0351C21.6074 46.0351 19.717 46.4832 18.6388 50.684C17.5737 54.0271 16.1265 57.2362 14.326 60.2477C12.1213 63.6901 9.32426 66.7147 6.06451 69.1814C1.33165 72.43 0.603516 69.1814 0.603516 69.1814C2.72969 62.7649 4.17523 56.1426 4.9163 49.4238C5.597 41.9205 5.52194 34.3679 4.69226 26.8796C6.04099 27.7717 7.53297 28.4253 9.10307 28.812C10.701 29.2303 12.3554 29.391 14.004 29.2881C19.605 28.952 24.1838 27.2157 27.3064 28.602C30.4266 29.5944 33.7779 29.5944 36.8981 28.602C38.1644 28.256 39.3969 27.7967 40.5808 27.2297Z" fill="#DEE1E9"/>-->
<!--      <path d="M42.9765 22.0798L40.666 27.1627C39.4966 27.7369 38.2782 28.2056 37.0254 28.563C33.9051 29.5554 30.5539 29.5554 27.4336 28.563C24.311 27.1627 19.6762 28.913 14.1312 29.2491C12.4826 29.352 10.8282 29.1913 9.23029 28.773C7.67637 28.34 6.20817 27.6437 4.8895 26.7146C4.8895 26.4206 4.81949 26.2385 4.80548 26.1965C3.7973 23.242 2.34103 20.4135 2.187 15.7086C2.10299 12.9081 1.97697 8.86135 4.65145 5.69677C7.56399 2.25214 12.1008 1.74805 14.0612 1.49601C16.2115 1.08485 18.4288 1.19044 20.5304 1.80406C21.3845 2.11212 25.4313 3.45637 26.6775 3.20432C27.3216 3.03629 27.6297 2.82625 27.6297 2.93827C27.6297 3.05029 35.4851 -0.758414 39.8119 1.70604C44.1387 4.17049 43.8166 6.03283 44.7408 11.4238C45.1621 15.0709 44.5508 18.7631 42.9765 22.0798Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--      <path opacity="0.2" d="M36.1829 32.7672C36.1895 32.8183 36.1895 32.8699 36.1829 32.921C35.5813 46.2133 34.4763 48.0368 33.1626 54.628C31.8489 61.2192 23.8684 73.8855 21.8426 75.2917C19.8168 76.6978 20.3201 75.2917 20.3201 75.2917C25.5873 69.4804 28.7917 55.7266 28.7917 55.7266C28.7917 55.7266 29.7126 38.7542 29.2092 35.173C28.7058 31.5918 24.5437 30.9107 21.8426 32.4486C19.1415 33.9866 17.7295 47.2349 17.7295 47.2349C17.0911 51.5411 14.4108 55.7266 9.01239 63.5701C8.53356 63.8337 2.72132 69.3503 2.14427 68.845C1.56722 68.3396 7.88285 61.3181 9.93322 57.0338C11.9009 52.5978 12.7963 47.8393 12.5606 43.0714C12.5606 42.3135 12.5606 42.643 11.959 35.173C11.8976 34.448 11.8485 33.7449 11.7994 33.0858C11.6275 30.8887 11.4925 29.0651 11.3943 27.7359C11.5405 27.0695 11.8483 26.4399 12.2959 25.8914C12.7435 25.3428 13.32 24.8888 13.9848 24.5611C14.664 24.3818 15.3788 24.338 16.0789 24.4329C16.779 24.5278 17.4472 24.7591 18.0365 25.1104C23.5123 27.7359 26.5572 25.9233 26.5572 25.9233C26.5572 25.9233 36.7968 19.3431 36.1829 32.7672Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M40.397 27.0227C40.2559 35.482 39.2185 43.9026 37.3024 52.1433C34.2219 64.7456 33.9138 61.707 32.0655 66.7899C30.2171 71.8729 14.0302 83.9011 19.1131 74.1973C22.9052 66.5988 24.7116 58.1639 24.364 49.6788C24.21 44.9039 21.4235 45.8281 21.4235 45.8281C21.4235 45.8281 19.5332 46.2762 18.455 50.477C17.3898 53.8201 15.9427 57.0292 14.1422 60.0407C11.9375 63.4831 9.14043 66.5076 5.88068 68.9743C1.14781 72.2229 0.419678 68.9743 0.419678 68.9743C2.54585 62.5578 3.99139 55.9356 4.73247 49.2167C5.41316 41.7135 5.3381 34.1608 4.50842 26.6726C5.85715 27.5646 7.34913 28.2183 8.91923 28.605C10.5171 29.0233 12.1716 29.184 13.8201 29.081C19.4212 28.745 24 27.0087 27.1226 28.3949C30.2428 29.3873 33.5941 29.3873 36.7143 28.3949C37.9806 28.0489 39.2131 27.5897 40.397 27.0227Z" fill="#DEE1E9"/>
        <mask id="mask446" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="30">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask446)">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M23.6923 18.7991C24.7544 18.7991 26 13.5008 26 3.90252L24.0769 2.04045L21 0.799072C21 10.3974 22.6302 18.7991 23.6923 18.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M35.9735 25.631C35.9801 25.6905 35.9801 25.7505 35.9735 25.8099C35.3737 41.2642 34.2721 43.3844 32.9623 51.0477C31.6525 58.711 23.696 73.4376 21.6762 75.0725C19.6565 76.7073 20.1584 75.0725 20.1584 75.0725C25.4097 68.316 28.6045 52.3249 28.6045 52.3249C28.6045 52.3249 29.5226 32.5919 29.0207 28.4282C28.5189 24.2644 24.3692 23.4726 21.6762 25.2607C18.9832 27.0488 17.5755 42.452 17.5755 42.452C16.939 47.4587 14.2668 52.3249 8.88451 61.4443C8.40712 61.7508 2.61231 68.1647 2.03698 67.5772C1.46166 66.9897 7.75835 58.826 9.80258 53.8448C11.7643 48.6873 12.6571 43.1548 12.4221 37.6114C12.4221 36.7301 12.4221 37.1133 11.8223 28.4282C11.7611 27.5852 11.7121 26.7678 11.6632 26.0014C11.4918 23.447 11.3572 21.3268 11.2592 19.7814C11.4051 19.0066 11.7119 18.2745 12.1582 17.6368C12.6044 16.9991 13.1792 16.4711 13.8421 16.0902C14.5191 15.8817 15.2318 15.8308 15.9298 15.9412C16.6278 16.0515 17.2941 16.3204 17.8815 16.7288C23.341 19.7814 26.3767 17.674 26.3767 17.674C26.3767 17.674 36.5856 10.0234 35.9735 25.631Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M43.5808 27.2297C43.4397 35.689 42.4024 44.1096 40.4863 52.3503C37.4057 64.9526 37.0976 61.914 35.2493 66.997C33.401 72.0799 17.214 84.1081 22.2969 74.4043C26.089 66.8058 27.8955 58.3709 27.5479 49.8858C27.3939 45.111 24.6074 46.0351 24.6074 46.0351C24.6074 46.0351 22.717 46.4832 21.6388 50.684C20.5737 54.0271 19.1265 57.2362 17.326 60.2477C15.1213 63.6901 12.3243 66.7147 9.06451 69.1814C4.33165 72.43 3.60352 69.1814 3.60352 69.1814C5.72969 62.7649 7.17523 56.1426 7.9163 49.4238C8.597 41.9205 8.52194 34.3679 7.69226 26.8796C9.04099 27.7717 10.533 28.4253 12.1031 28.812C13.701 29.2303 15.3554 29.391 17.004 29.2881C22.605 28.952 27.1838 27.2157 30.3064 28.602C33.4266 29.5944 36.7779 29.5944 39.8981 28.602C41.1644 28.256 42.3969 27.7967 43.5808 27.2297Z" fill="#DEE1E9"/>
      <path d="M45.9765 22.0798L43.666 27.1627C42.4966 27.7369 41.2782 28.2056 40.0254 28.563C36.9051 29.5554 33.5539 29.5554 30.4336 28.563C27.311 27.1627 22.6762 28.913 17.1312 29.2491C15.4826 29.352 13.8282 29.1913 12.2303 28.773C10.6764 28.34 9.20817 27.6437 7.8895 26.7146C7.8895 26.4206 7.81949 26.2385 7.80548 26.1965C6.7973 23.242 5.34103 20.4135 5.187 15.7086C5.10299 12.9081 4.97697 8.86135 7.65145 5.69677C10.564 2.25214 15.1008 1.74805 17.0612 1.49601C19.2115 1.08485 21.4288 1.19044 23.5304 1.80406C24.3845 2.11212 28.4313 3.45637 29.6775 3.20432C30.3216 3.03629 30.6297 2.82625 30.6297 2.93827C30.6297 3.05029 38.4851 -0.758414 42.8119 1.70604C47.1387 4.17049 46.8166 6.03283 47.7408 11.4238C48.1621 15.0709 47.5508 18.7631 45.9765 22.0798Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M39.1829 32.7672C39.1895 32.8183 39.1895 32.8699 39.1829 32.921C38.5813 46.2133 37.4763 48.0368 36.1626 54.628C34.8489 61.2192 26.8684 73.8855 24.8426 75.2917C22.8168 76.6978 23.3201 75.2917 23.3201 75.2917C28.5873 69.4804 31.7917 55.7266 31.7917 55.7266C31.7917 55.7266 32.7126 38.7542 32.2092 35.173C31.7058 31.5918 27.5437 30.9107 24.8426 32.4486C22.1415 33.9866 20.7295 47.2349 20.7295 47.2349C20.0911 51.5411 17.4108 55.7266 12.0124 63.5701C11.5336 63.8337 5.72132 69.3503 5.14427 68.845C4.56722 68.3396 10.8828 61.3181 12.9332 57.0338C14.9009 52.5978 15.7963 47.8393 15.5606 43.0714C15.5606 42.3135 15.5606 42.643 14.959 35.173C14.8976 34.448 14.8485 33.7449 14.7994 33.0858C14.6275 30.8887 14.4925 29.0651 14.3943 27.7359C14.5405 27.0695 14.8483 26.4399 15.2959 25.8914C15.7435 25.3428 16.32 24.8888 16.9848 24.5611C17.664 24.3818 18.3788 24.338 19.0789 24.4329C19.779 24.5278 20.4472 24.7591 21.0365 25.1104C26.5123 27.7359 29.5572 25.9233 29.5572 25.9233C29.5572 25.9233 39.7968 19.3431 39.1829 32.7672Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="22" cy="77" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="4" cy="70" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M42.8247 21.3217L40.4582 26.3767C40.3178 34.8361 39.2804 43.2567 37.3637 51.4973C34.2831 64.0996 33.9751 61.061 32.1267 66.1439C30.2784 71.2269 14.0914 83.2691 19.1744 73.5513C22.9647 65.9572 24.7711 57.5272 24.4253 49.0468C24.2713 44.2579 21.4848 45.1961 21.4848 45.1961C21.4848 45.1961 19.6364 45.6442 18.5582 49.8029C17.4931 53.146 16.0459 56.3552 14.2455 59.3667C12.0407 62.809 9.2437 65.8336 5.98395 68.3003C1.25108 71.5489 0.522949 68.3003 0.522949 68.3003C2.66763 61.8954 4.13197 55.2824 4.89175 48.5707C5.57574 41.0489 5.50068 33.4774 4.66771 25.9706C4.66771 25.6765 4.66771 25.5085 4.58369 25.4525C3.57551 22.4979 2.11924 19.6694 1.96521 14.9646C1.8812 12.1641 1.75517 8.11732 4.42966 4.95275C7.3422 1.52212 11.879 1.00403 13.8394 0.751983C15.9897 0.340828 18.207 0.446408 20.3086 1.06004C21.1627 1.36809 25.2095 2.72634 26.4557 2.46029C27.0998 2.29226 27.4079 2.08222 27.4079 2.19425C27.4079 2.30627 35.2633 -1.50243 39.5901 0.962018C43.9169 3.42647 43.5948 5.30282 44.519 10.6798C44.9594 14.3157 44.3725 18.0023 42.8247 21.3217Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M42.8247 21.3217L40.4582 26.3767C40.3178 34.8361 39.2804 43.2567 37.3637 51.4973C34.2831 64.0996 33.9751 61.061 32.1267 66.1439C30.2784 71.2269 14.0914 83.2691 19.1744 73.5513C22.9647 65.9572 24.7711 57.5272 24.4253 49.0468C24.2713 44.2579 21.4848 45.1961 21.4848 45.1961C21.4848 45.1961 19.6364 45.6442 18.5582 49.8029C17.4931 53.146 16.0459 56.3552 14.2455 59.3667C12.0407 62.809 9.2437 65.8336 5.98395 68.3003C1.25108 71.5489 0.522949 68.3003 0.522949 68.3003C2.66763 61.8954 4.13197 55.2824 4.89175 48.5707C5.57574 41.0489 5.50068 33.4774 4.66771 25.9706C4.66771 25.6765 4.66771 25.5085 4.58369 25.4525C3.57551 22.4979 2.11924 19.6694 1.96521 14.9646C1.8812 12.1641 1.75517 8.11732 4.42966 4.95275C7.3422 1.52212 11.879 1.00403 13.8394 0.751983C15.9897 0.340828 18.207 0.446408 20.3086 1.06004C21.1627 1.36809 25.2095 2.72634 26.4557 2.46029C27.0998 2.29226 27.4079 2.08222 27.4079 2.19425C27.4079 2.30627 35.2633 -1.50243 39.5901 0.962018C43.9169 3.42647 43.5948 5.30282 44.519 10.6798C44.9594 14.3157 44.3725 18.0023 42.8247 21.3217Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M40.205 3.11929C40.0639 11.5786 39.0266 19.9992 37.1105 28.2399C34.0299 40.8422 33.7218 37.8036 31.8735 42.8866C30.0252 47.9695 13.8382 59.9977 18.9211 50.2939C22.7132 42.6954 24.5197 34.2605 24.1721 25.7754C24.0181 21.0006 21.2316 21.9247 21.2316 21.9247C21.2316 21.9247 19.3412 22.3728 18.263 26.5736C17.1979 29.9167 15.7507 33.1258 13.9502 36.1373C11.7455 39.5797 8.94847 42.6043 5.68872 45.071C0.955856 48.3195 0.227722 45.071 0.227722 45.071C2.35389 38.6545 3.79944 32.0322 4.54051 25.3133C5.22121 17.8101 5.14615 10.2575 4.31647 2.76923C4.80804 3.11929 10.2379 3.00911 11.808 3.39581C13.406 3.81415 17.1595 5.99878 18.808 5.89581L26.808 3.39581C29.9283 4.38823 35.808 5.39581 35.808 5.39581C37.0743 5.04983 39.0211 3.68629 40.205 3.11929Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M35.8071 8.81059C35.8137 8.75949 35.8137 8.70789 35.8071 8.65679C35.9057 6.50012 35.7242 4.85976 35.35 3.6255L31.7374 1.56521L27.989 2.4979L23.7799 1L18.7374 2.4979L14.808 1L11.0185 3.6255C11.1167 4.95473 11.2517 6.77829 11.4236 8.97536C11.4727 9.63448 11.5218 10.3376 11.5832 11.0626C12.1848 18.5326 12.1848 18.2031 12.1848 18.9611C12.4205 23.7289 11.5251 28.4874 9.55743 32.9234C7.50705 37.2077 1.19143 44.2292 1.76848 44.7346C2.34553 45.2399 8.15777 39.7233 8.6366 39.4597C14.035 31.6162 16.7153 27.4307 17.3537 23.1245C17.3537 23.1245 18.7657 9.87617 21.4668 8.33823C24.1679 6.80028 28.33 7.48136 28.8334 11.0626C29.3368 14.6438 28.4159 31.6162 28.4159 31.6162C28.4159 31.6162 25.2115 45.37 19.9443 51.1813C19.9443 51.1813 19.441 52.5874 21.4668 51.1813C23.4926 49.7751 31.4731 37.1088 32.7868 30.5176C34.1005 23.9264 35.2055 22.1029 35.8071 8.81059Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M9.96586 9.73164C20.8778 12.8436 26.7468 12.121 36.8701 7.27752" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M40.397 27.3166C40.2559 35.776 39.2185 44.1965 37.3024 52.4372C34.2219 65.0395 33.9138 62.001 32.0655 67.0839C30.2171 72.1668 14.0302 84.195 19.1131 74.4912C22.9052 66.8927 24.7116 58.4578 24.364 49.9728C24.21 45.1979 21.4235 46.1221 21.4235 46.1221C21.4235 46.1221 19.5332 46.5701 18.455 50.7709C17.3898 54.114 15.9427 57.3231 14.1422 60.3347C11.9375 63.777 9.14043 66.8016 5.88068 69.2683C1.14781 72.5169 0.419678 69.2683 0.419678 69.2683C2.54585 62.8518 3.99139 56.2295 4.73247 49.5107C5.41316 42.0074 5.3381 34.4548 4.50842 26.9666C5.85715 27.8586 7.34913 28.5122 8.91923 28.8989C10.5171 29.3172 12.1716 29.478 13.8201 29.375C19.4212 29.0389 24 27.3026 27.1226 28.6889C30.2428 29.6813 33.5941 29.6813 36.7143 28.6889C37.9806 28.3429 39.2131 27.8836 40.397 27.3166Z" fill="#DEE1E9"/>
        <path d="M40.4822 27.2496L42.7926 22.1667C44.367 18.85 44.9783 15.1579 44.5569 11.5107C44.4031 10.6135 43.1119 12.814 43 12.0931L41.8091 12.2315H37.902L38.8091 9.58629L37.6015 7.1194L38.8091 4.80599L35 3.29125V1C31.2353 1.28943 27.4458 3.103 27.4458 3.02519C27.4458 2.91317 27.1378 3.12321 26.4936 3.29125C25.2474 3.54329 21.2007 2.19905 20.3465 1.89099C18.245 1.27736 16.0277 1.17178 13.8773 1.58293C11.917 1.83498 7.38015 2.33907 4.46762 5.7837C1.79757 8.94302 1.91874 12.9816 2.00275 15.7815L2.00317 15.7955C2.15719 20.5004 3.61346 23.3289 4.62165 26.2834C4.63565 26.3255 4.70566 26.5075 4.70566 26.8015C6.02434 27.7307 7.49253 28.4269 9.04645 28.8599C10.6444 29.2782 12.2988 29.439 13.9474 29.336C19.4924 28.9999 24.1272 27.2496 27.2498 28.6499C30.37 29.6423 33.7213 29.6423 36.8415 28.6499C38.0944 28.2925 39.3128 27.8239 40.4822 27.2496Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M40.6758 23.1377C30.5767 19.1425 28.2637 14.6497 29.4195 3.09315" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M35.9991 32.8541C36.0056 32.9052 36.0056 32.9568 35.9991 33.0079C35.3974 46.3002 34.2924 48.1237 32.9787 54.7149C31.665 61.3061 23.6845 73.9724 21.6587 75.3786C19.6329 76.7847 20.1363 75.3786 20.1363 75.3786C25.4034 69.5673 28.6079 55.8135 28.6079 55.8135C28.6079 55.8135 29.5287 38.8411 29.0253 35.2599C28.5219 31.6787 24.3598 30.9976 21.6587 32.5355C18.9576 34.0735 17.5457 47.3218 17.5457 47.3218C16.9073 51.6281 14.227 55.8135 8.82856 63.657C8.34973 63.9207 2.53749 69.4372 1.96044 68.9319C1.38339 68.4266 7.69901 61.405 9.74938 57.1207C11.717 52.6848 12.6125 47.9263 12.3768 43.1584C12.3768 42.4004 12.3768 42.7299 11.7752 35.2599C11.7138 34.5349 11.6647 33.8318 11.6156 33.1727C11.4437 30.9756 11.3086 29.152 11.2104 27.8228C11.3567 27.1564 11.6645 26.5268 12.112 25.9783C12.5596 25.4298 13.1361 24.9757 13.801 24.648C14.4801 24.4687 15.195 24.4249 15.895 24.5198C16.5951 24.6148 17.2634 24.846 17.8526 25.1973C23.3285 27.8228 26.3734 26.0102 26.3734 26.0102C26.3734 26.0102 36.6129 19.43 35.9991 32.8541Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M40.397 27.4729C40.2559 35.9322 39.2185 44.3528 37.3024 52.5935C34.2219 65.1958 33.9138 62.1572 32.0655 67.2402C30.2171 72.3231 14.0302 84.3513 19.1131 74.6475C22.9052 67.049 24.7116 58.6141 24.364 50.129C24.21 45.3541 21.4235 46.2783 21.4235 46.2783C21.4235 46.2783 19.5332 46.7264 18.455 50.9272C17.3898 54.2703 15.9427 57.4794 14.1422 60.4909C11.9375 63.9333 9.14043 66.9579 5.88068 69.4246C1.14781 72.6732 0.419678 69.4246 0.419678 69.4246C2.54585 63.0081 3.99139 56.3858 4.73247 49.6669C5.41316 42.1637 5.3381 34.6111 4.50842 27.1228C5.85715 28.0148 7.029 14.3866 8.5991 14.7733C10.197 15.1916 11.8514 15.3524 13.5 15.2494C19.101 14.9133 23.6799 13.177 26.8024 14.5633C29.9227 15.5557 33.2739 15.5557 36.3942 14.5633C37.6604 14.2173 39.2131 28.0399 40.397 27.4729Z" fill="#DEE1E9"/>
        <path d="M42.7926 22.3229L40.4822 27.4059C39.5018 27.8873 34.3816 28.9574 32.0135 29.5014C31.4584 29.6289 30.8899 29.2868 30.7579 28.7327L28.5 19.2494C23.9253 18.0192 21.4145 18.1092 17 19.2494L14.7406 28.7389C14.6092 29.2906 14.0463 29.6291 13.4947 29.4969C11.0225 28.9048 5.82945 27.7496 4.70566 26.9578C4.70566 26.6637 4.63565 26.4817 4.62165 26.4397C3.61346 23.4851 2.15719 20.6566 2.00317 15.9518C1.91915 13.1512 1.79313 9.10452 4.46762 5.93994C7.38015 2.49531 11.917 1.99122 13.8773 1.73917C16.0277 1.32801 18.245 1.4336 20.3465 2.04723C21.2007 2.35528 25.2474 3.69953 26.4936 3.44748C27.1378 3.27945 27.4458 3.06941 27.4458 3.18143C27.4458 3.29345 35.3013 -0.51525 39.628 1.9492C43.9548 4.41365 43.6328 6.27599 44.5569 11.667C44.9783 15.3141 44.367 19.0062 42.7926 22.3229Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M40.397 27.2235C40.2559 35.6828 39.2185 44.1034 37.3024 52.3441C34.2219 64.9464 33.9138 61.9078 32.0655 66.9908C30.2171 72.0737 14.0302 84.1019 19.1131 74.3981C22.9052 66.7996 24.7116 58.3647 24.364 49.8796C24.21 45.1047 21.4235 46.0289 21.4235 46.0289C21.4235 46.0289 19.5332 46.477 18.455 50.6778C17.3898 54.0209 15.9427 57.23 14.1422 60.2415C11.9375 63.6839 9.14043 66.7085 5.88068 69.1752C1.14781 72.4238 0.419678 69.1752 0.419678 69.1752C2.54585 62.7587 3.99139 56.1364 4.73247 49.4175C5.41316 41.9143 5.3381 34.3617 4.50842 26.8734C5.85715 27.7655 7.029 14.1372 8.5991 14.5239C10.197 14.9422 11.8514 15.103 13.5 15C19.101 14.6639 23.6799 12.9276 26.8024 14.3139C29.9227 15.3063 33.2739 15.3063 36.3942 14.3139C37.6604 13.9679 39.2131 27.7905 40.397 27.2235Z" fill="#DEE1E9"/>
        <path d="M42.7926 22.0735L40.4822 27.1565C39.5018 27.6379 34.3816 28.708 32.0135 29.252C31.4584 29.3795 30.8899 29.0374 30.7579 28.4833L28.5 19C23.9253 17.7698 21.4145 17.8599 17 19L14.7406 28.4895C14.6092 29.0412 14.0463 29.3797 13.4947 29.2475C11.0225 28.6554 5.82945 27.5002 4.70566 26.7084C4.70566 26.4143 4.63565 26.2323 4.62165 26.1903C3.61346 23.2358 2.15719 20.4072 2.00317 15.7024C1.91915 12.9019 1.79313 8.85513 4.46762 5.69055C7.38015 2.24592 11.917 1.74183 13.8773 1.48978C16.0277 1.07862 18.245 1.18421 20.3465 1.79784C21.2007 2.10589 25.2474 3.45014 26.4936 3.19809C27.1378 3.03006 27.4458 2.82002 27.4458 2.93204C27.4458 3.04406 35.3013 -0.76464 39.628 1.69981C43.9548 4.16426 43.6328 6.0266 44.5569 11.4176C44.9783 15.0647 44.367 18.7568 42.7926 22.0735Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M40.397 27.2235C40.2559 35.6828 39.2185 44.1034 37.3024 52.3441C34.2219 64.9464 33.9138 61.9078 32.0655 66.9908C30.2171 72.0737 14.0302 84.1019 19.1131 74.3981C22.9052 66.7996 24.7116 58.3647 24.364 49.8796C24.21 45.1048 21.4235 46.0289 21.4235 46.0289C21.4235 46.0289 19.5332 46.477 18.455 50.6778C17.3898 54.0209 15.9427 57.23 14.1422 60.2415C11.9375 63.6839 9.14043 66.7085 5.88068 69.1752C1.14781 72.4238 0.419678 69.1752 0.419678 69.1752C2.54585 62.7587 3.99139 56.1364 4.73247 49.4175C5.41316 41.9143 5.3381 34.3617 4.50842 26.8734C5.85715 27.7655 7.029 14.1372 8.5991 14.5239C10.197 14.9422 11.8514 15.103 13.5 15C19.101 14.6639 23.6799 12.9276 26.8024 14.3139C29.9227 15.3063 33.2739 15.3063 36.3942 14.3139C37.6604 13.9679 39.2131 27.7905 40.397 27.2235Z" fill="#DEE1E9"/>
        <mask id="mask2046" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="30">
          <path d="M42.8224 22.2054L40.512 27.2883C39.5316 27.7697 34.4114 28.8398 32.0433 29.3838C31.4882 29.5113 30.9196 29.1692 30.7877 28.6151L28.5298 19.1318C23.9551 17.9017 21.4443 17.9917 17.0298 19.1318L14.7704 28.6213C14.639 29.1731 14.0761 29.5115 13.5245 29.3794C11.0523 28.7872 5.85924 27.632 4.73545 26.8402C4.73545 26.5462 4.66543 26.3641 4.65143 26.3221C3.64325 23.3676 2.18698 20.5391 2.03295 15.8342C1.94894 13.0337 1.82291 8.98696 4.4974 5.82238C7.40993 2.37775 11.9468 1.87366 13.9071 1.62162C16.0575 1.21046 18.2747 1.31605 20.3763 1.92967C21.2305 2.23773 25.2772 3.58198 26.5234 3.32993C27.1675 3.1619 27.4756 2.95186 27.4756 3.06388C27.4756 3.1759 35.331 -0.632804 39.6578 1.83165C43.9846 4.2961 43.6626 6.15844 44.5867 11.5494C45.008 15.1965 44.3967 18.8887 42.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2046)">
          <path d="M42.8224 22.2054L40.512 27.2883C39.5316 27.7697 34.4114 28.8398 32.0433 29.3838C31.4882 29.5113 30.9196 29.1692 30.7877 28.6151L28.5298 19.1318C23.9551 17.9017 21.4443 17.9917 17.0298 19.1318L14.7704 28.6213C14.639 29.1731 14.0761 29.5115 13.5245 29.3794C11.0523 28.7872 5.85924 27.632 4.73545 26.8402C4.73545 26.5462 4.66543 26.3641 4.65143 26.3221C3.64325 23.3676 2.18698 20.5391 2.03295 15.8342C1.94894 13.0337 1.82291 8.98696 4.4974 5.82238C7.40993 2.37775 11.9468 1.87366 13.9071 1.62162C16.0575 1.21046 18.2747 1.31605 20.3763 1.92967C21.2305 2.23773 25.2772 3.58198 26.5234 3.32993C27.1675 3.1619 27.4756 2.95186 27.4756 3.06388C27.4756 3.1759 35.331 -0.632804 39.6578 1.83165C43.9846 4.2961 43.6626 6.15844 44.5867 11.5494C45.008 15.1965 44.3967 18.8887 42.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M35 43H11V17.452C20.3726 15.5258 25.6274 15.5062 35 17.452V43Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M40.4202 27.0024C40.2791 35.4618 39.2417 43.8823 37.3256 52.123C34.245 64.7253 33.937 61.6867 32.0886 66.7697C30.2403 71.8526 14.0534 83.8808 19.1363 74.177C22.9284 66.5785 24.7348 58.1436 24.3872 49.6585C24.2332 44.8837 21.4467 45.8078 21.4467 45.8078C21.4467 45.8078 19.5564 46.2559 18.4782 50.4567C17.413 53.7998 15.9659 57.0089 14.1654 60.0204C11.9607 63.4628 9.16362 66.4874 5.90387 68.9541C1.171 72.2027 0.442871 68.9541 0.442871 68.9541C2.56904 62.5376 4.01459 55.9153 4.75566 49.1965C5.43635 41.6932 5.3613 34.1406 4.53162 26.6523C5.88034 27.5444 7.37232 28.198 8.94242 28.5847C10.5403 29.003 12.1948 29.1637 13.8433 29.0608C19.4443 28.7247 24.0232 26.9884 27.1457 28.3747C30.266 29.3671 33.6173 29.3671 36.7375 28.3747C38.0037 28.0287 39.2363 27.5694 40.4202 27.0024Z" fill="#DEE1E9"/>
        <path d="M42.8158 21.8523L40.5054 26.9353C39.336 27.5095 38.1176 27.9781 36.8647 28.3355C33.7445 29.328 30.3932 29.328 27.273 28.3355C24.1504 26.9353 19.5156 28.6856 13.9705 29.0216C12.322 29.1246 10.6676 28.9639 9.06965 28.5456C7.51572 28.1125 6.04753 27.4163 4.72885 26.4872C4.72885 26.1931 4.65884 26.0111 4.64484 25.9691C3.63665 23.0146 2.18039 20.186 2.02636 15.4812C1.94234 12.6807 1.81632 8.63393 4.49081 5.46936C7.40334 2.02473 11.9402 1.52064 13.9005 1.26859C16.0509 0.857434 18.2682 0.96302 20.3697 1.57665C21.2239 1.8847 25.2706 3.22895 26.5168 2.9769C27.161 2.80887 27.469 2.59883 27.469 2.71085C27.469 2.82287 35.3244 -0.985831 39.6512 1.47862C43.978 3.94307 43.656 5.80541 44.5801 11.1964C45.0015 14.8435 44.3901 18.5357 42.8158 21.8523Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M36.0222 32.54C36.0288 32.5911 36.0288 32.6427 36.0222 32.6937C35.4206 45.986 34.3156 47.8096 33.0019 54.4008C31.6882 60.992 23.7077 73.6583 21.6819 75.0644C19.6561 76.4705 20.1595 75.0644 20.1595 75.0644C25.4266 69.2532 28.6311 55.4993 28.6311 55.4993C28.6311 55.4993 29.5519 38.527 29.0485 34.9457C28.5451 31.3645 24.383 30.6834 21.6819 32.2214C18.9808 33.7593 17.5689 47.0077 17.5689 47.0077C16.9305 51.3139 14.2502 55.4993 8.85175 63.3429C8.37292 63.6065 2.56068 69.1231 1.98363 68.6177C1.40658 68.1124 7.7222 61.0909 9.77257 56.8066C11.7402 52.3706 12.6357 47.6121 12.4 42.8442C12.4 42.0862 12.4 42.4158 11.7984 34.9457C11.737 34.2207 11.6879 33.5176 11.6388 32.8585C11.4669 30.6615 11.3318 28.8379 11.2336 27.5087C11.3799 26.8423 11.6877 26.2126 12.1352 25.6641C12.5828 25.1156 13.1593 24.6615 13.8242 24.3339C14.5033 24.1546 15.2182 24.1108 15.9182 24.2057C16.6183 24.3006 17.2866 24.5319 17.8758 24.8832C23.3517 27.5087 26.3965 25.6961 26.3965 25.6961C26.3965 25.6961 36.6361 19.1159 36.0222 32.54Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M35.1664 58.1306C30.9454 61.7881 28.5883 61.6214 24.3923 58.1306" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path d="M14.904 57.7493C10.5408 60.8718 8.10438 60.7295 3.76707 57.7493" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M42.397 27.2236C42.2559 35.6829 41.2185 44.1035 39.3024 52.3442C36.2219 64.9465 35.9138 61.9079 34.0655 66.9909C32.2171 72.0738 16.0302 84.102 21.1131 74.3982C24.9052 66.7997 26.7116 58.3648 26.364 49.8797C26.21 45.1049 23.4235 46.029 23.4235 46.029C23.4235 46.029 21.5332 46.4771 20.455 50.6779C19.3898 54.021 17.9427 57.2301 16.1422 60.2416C13.9375 63.684 11.1404 66.7086 7.88068 69.1753C3.14781 72.4239 2.41968 69.1753 2.41968 69.1753C4.54585 62.7588 5.99139 56.1365 6.73247 49.4177C7.41316 41.9144 7.3381 34.3618 6.50842 26.8735C7.85715 27.7656 9.34913 28.4192 10.9192 28.8059C12.5171 29.2242 14.1716 29.3849 15.8201 29.282C21.4212 28.9459 26 27.2096 29.1226 28.5958C32.2428 29.5883 35.5941 29.5883 38.7143 28.5958C39.9806 28.2499 41.2131 27.7906 42.397 27.2236Z" fill="#DEE1E9"/>
        <mask id="mask2246" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="45" height="30">
          <path d="M44.8224 22.2054L42.512 27.2883C41.3426 27.8626 40.1242 28.3312 38.8713 28.6886C35.7511 29.681 32.3998 29.681 29.2796 28.6886C26.157 27.2883 21.5222 29.0386 15.9771 29.3747C14.3286 29.4776 12.6742 29.3169 11.0762 28.8986C9.52231 28.4656 8.05412 27.7694 6.73545 26.8402C6.73545 26.5462 6.66543 26.3641 6.65143 26.3221C5.64325 23.3676 4.18698 20.5391 4.03295 15.8342C3.94894 13.0337 3.82291 8.98696 6.4974 5.82238C9.40993 2.37775 13.9468 1.87366 15.9071 1.62162C18.0575 1.21046 20.2747 1.31605 22.3763 1.92967C23.2305 2.23773 27.2772 3.58198 28.5234 3.32993C29.1675 3.1619 29.4756 2.95186 29.4756 3.06388C29.4756 3.1759 37.331 -0.632804 41.6578 1.83165C45.9846 4.2961 45.6626 6.15844 46.5867 11.5494C47.008 15.1965 46.3967 18.8887 44.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2246)">
          <path d="M44.8224 22.2054L42.512 27.2883C41.3426 27.8626 40.1242 28.3312 38.8713 28.6886C35.7511 29.681 32.3998 29.681 29.2796 28.6886C26.157 27.2883 21.5222 29.0386 15.9771 29.3747C14.3286 29.4776 12.6742 29.3169 11.0762 28.8986C9.52231 28.4656 8.05412 27.7694 6.73545 26.8402C6.73545 26.5462 6.66543 26.3641 6.65143 26.3221C5.64325 23.3676 4.18698 20.5391 4.03295 15.8342C3.94894 13.0337 3.82291 8.98696 6.4974 5.82238C9.40993 2.37775 13.9468 1.87366 15.9071 1.62162C18.0575 1.21046 20.2747 1.31605 22.3763 1.92967C23.2305 2.23773 27.2772 3.58198 28.5234 3.32993C29.1675 3.1619 29.4756 2.95186 29.4756 3.06388C29.4756 3.1759 37.331 -0.632804 41.6578 1.83165C45.9846 4.2961 45.6626 6.15844 46.5867 11.5494C47.008 15.1965 46.3967 18.8887 44.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M25.6923 19C26.7544 19 28 13.7018 28 4.10345L26.0769 2.24138L23 1C23 10.5983 24.6302 19 25.6923 19Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M37.9735 25.832C37.9801 25.8914 37.9801 25.9514 37.9735 26.0108C37.3737 41.4652 36.2721 43.5853 34.9623 51.2487C33.6525 58.912 25.696 73.6386 23.6762 75.2734C21.6565 76.9082 22.1584 75.2734 22.1584 75.2734C27.4097 68.5169 30.6045 52.5259 30.6045 52.5259C30.6045 52.5259 31.5226 32.7928 31.0207 28.6291C30.5189 24.4654 26.3692 23.6735 23.6762 25.4616C20.9832 27.2497 19.5755 42.653 19.5755 42.653C18.939 47.6597 16.2668 52.5259 10.8845 61.6452C10.4071 61.9518 4.61231 68.3656 4.03698 67.7781C3.46166 67.1906 9.75835 59.0269 11.8026 54.0458C13.7643 48.8882 14.6571 43.3557 14.4221 37.8123C14.4221 36.931 14.4221 37.3142 13.8223 28.6291C13.7611 27.7861 13.7121 26.9687 13.6632 26.2024C13.4918 23.6479 13.3572 21.5277 13.2592 19.9823C13.4051 19.2075 13.7119 18.4755 14.1582 17.8377C14.6044 17.2 15.1792 16.6721 15.8421 16.2911C16.5191 16.0826 17.2318 16.0317 17.9298 16.1421C18.6278 16.2524 19.2941 16.5213 19.8815 16.9297C25.341 19.9823 28.3767 17.8749 28.3767 17.8749C28.3767 17.8749 38.5856 10.2243 37.9735 25.832Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="4" cy="69" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <circle cx="23" cy="76" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M42.397 27.2236C42.2559 35.6829 41.2185 44.1035 39.3024 52.3442C36.2219 64.9465 35.9138 61.9079 34.0655 66.9909C32.2171 72.0738 16.0302 84.102 21.1131 74.3982C24.9052 66.7997 26.7116 58.3648 26.364 49.8797C26.21 45.1049 23.4235 46.029 23.4235 46.029C23.4235 46.029 21.5332 46.4771 20.455 50.6779C19.3898 54.021 17.9427 57.2301 16.1422 60.2416C13.9375 63.684 11.1404 66.7086 7.88068 69.1753C3.14781 72.4239 2.41968 69.1753 2.41968 69.1753C4.54585 62.7588 5.99139 56.1365 6.73247 49.4177C7.41316 41.9144 7.3381 34.3618 6.50842 26.8735C7.85715 27.7656 9.34913 28.4192 10.9192 28.8059C12.5171 29.2242 14.1716 29.3849 15.8201 29.282C21.4212 28.9459 26 27.2096 29.1226 28.5958C32.2428 29.5883 35.5941 29.5883 38.7143 28.5958C39.9806 28.2499 41.2131 27.7906 42.397 27.2236Z" fill="#DEE1E9"/>
        <mask id="mask2346" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="45" height="30">
          <path d="M44.8224 22.2054L42.512 27.2883C41.3426 27.8626 40.1242 28.3312 38.8713 28.6886C35.7511 29.681 32.3998 29.681 29.2796 28.6886C26.157 27.2883 21.5222 29.0386 15.9771 29.3747C14.3286 29.4776 12.6742 29.3169 11.0762 28.8986C9.52231 28.4656 8.05412 27.7694 6.73545 26.8402C6.73545 26.5462 6.66543 26.3641 6.65143 26.3221C5.64325 23.3676 4.18698 20.5391 4.03295 15.8342C3.94894 13.0337 3.82291 8.98696 6.4974 5.82238C9.40993 2.37775 13.9468 1.87366 15.9071 1.62162C18.0575 1.21046 20.2747 1.31605 22.3763 1.92967C23.2305 2.23773 27.2772 3.58198 28.5234 3.32993C29.1675 3.1619 29.4756 2.95186 29.4756 3.06388C29.4756 3.1759 37.331 -0.632804 41.6578 1.83165C45.9846 4.2961 45.6626 6.15844 46.5867 11.5494C47.008 15.1965 46.3967 18.8887 44.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2346)">
          <path d="M44.8224 22.2054L42.512 27.2883C41.3426 27.8626 40.1242 28.3312 38.8713 28.6886C35.7511 29.681 32.3998 29.681 29.2796 28.6886C26.157 27.2883 21.5222 29.0386 15.9771 29.3747C14.3286 29.4776 12.6742 29.3169 11.0762 28.8986C9.52231 28.4656 8.05412 27.7694 6.73545 26.8402C6.73545 26.5462 6.66543 26.3641 6.65143 26.3221C5.64325 23.3676 4.18698 20.5391 4.03295 15.8342C3.94894 13.0337 3.82291 8.98696 6.4974 5.82238C9.40993 2.37775 13.9468 1.87366 15.9071 1.62162C18.0575 1.21046 20.2747 1.31605 22.3763 1.92967C23.2305 2.23773 27.2772 3.58198 28.5234 3.32993C29.1675 3.1619 29.4756 2.95186 29.4756 3.06388C29.4756 3.1759 37.331 -0.632804 41.6578 1.83165C45.9846 4.2961 45.6626 6.15844 46.5867 11.5494C47.008 15.1965 46.3967 18.8887 44.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M25.6923 19C26.7544 19 28 13.7018 28 4.10345L26.0769 2.24138L23 1C23 10.5983 24.6302 19 25.6923 19Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M37.9735 25.832C37.9801 25.8914 37.9801 25.9514 37.9735 26.0108C37.3737 41.4652 36.2721 43.5853 34.9623 51.2487C33.6525 58.912 25.696 73.6386 23.6762 75.2734C21.6565 76.9082 22.1584 75.2734 22.1584 75.2734C27.4097 68.5169 30.6045 52.5259 30.6045 52.5259C30.6045 52.5259 31.5226 32.7928 31.0207 28.6291C30.5189 24.4654 26.3692 23.6735 23.6762 25.4616C20.9832 27.2497 19.5755 42.653 19.5755 42.653C18.939 47.6597 16.2668 52.5259 10.8845 61.6452C10.4071 61.9518 4.61231 68.3656 4.03698 67.7781C3.46166 67.1906 9.75835 59.0269 11.8026 54.0458C13.7643 48.8882 14.6571 43.3557 14.4221 37.8123C14.4221 36.931 14.4221 37.3142 13.8223 28.6291C13.7611 27.7861 13.7121 26.9687 13.6632 26.2024C13.4918 23.6479 13.3572 21.5277 13.2592 19.9823C13.4051 19.2075 13.7119 18.4755 14.1582 17.8377C14.6044 17.2 15.1792 16.6721 15.8421 16.2911C16.5191 16.0826 17.2318 16.0317 17.9298 16.1421C18.6278 16.2524 19.2941 16.5213 19.8815 16.9297C25.341 19.9823 28.3767 17.8749 28.3767 17.8749C28.3767 17.8749 38.5856 10.2243 37.9735 25.832Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="4" cy="69" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <circle cx="23" cy="76" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2346)">
          <path d="M23 73L23.8229 76.7391L26.8567 74.4037L25.0837 77.797L28.9088 77.9581L25.3694 79.4178L28.1962 82L24.5465 80.8431L25.0521 84.6382L23 81.406L20.9479 84.6382L21.4535 80.8431L17.8038 82L20.6306 79.4178L17.0912 77.9581L20.9163 77.797L19.1433 74.4037L22.1771 76.7391L23 73Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2346" x="16.8911" y="72.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M42.397 27.2236C42.2559 35.6829 41.2185 44.1035 39.3024 52.3442C36.2219 64.9465 35.9138 61.9079 34.0655 66.9909C32.2171 72.0738 16.0302 84.102 21.1131 74.3982C24.9052 66.7997 26.7116 58.3648 26.364 49.8797C26.21 45.1049 23.4235 46.029 23.4235 46.029C23.4235 46.029 21.5332 46.4771 20.455 50.6779C19.3898 54.021 17.9427 57.2301 16.1422 60.2416C13.9375 63.684 11.1404 66.7086 7.88068 69.1753C3.14781 72.4239 2.41968 69.1753 2.41968 69.1753C4.54585 62.7588 5.99139 56.1365 6.73247 49.4177C7.41316 41.9144 7.3381 34.3618 6.50842 26.8735C7.85715 27.7656 9.34913 28.4192 10.9192 28.8059C12.5171 29.2242 14.1716 29.3849 15.8201 29.282C21.4212 28.9459 26 27.2096 29.1226 28.5958C32.2428 29.5883 35.5941 29.5883 38.7143 28.5958C39.9806 28.2499 41.2131 27.7906 42.397 27.2236Z" fill="#DEE1E9"/>
      <mask id="mask2346" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="0" width="45" height="30">
        <path d="M44.8224 22.2054L42.512 27.2883C41.3426 27.8626 40.1242 28.3312 38.8713 28.6886C35.7511 29.681 32.3998 29.681 29.2796 28.6886C26.157 27.2883 21.5222 29.0386 15.9771 29.3747C14.3286 29.4776 12.6742 29.3169 11.0762 28.8986C9.52231 28.4656 8.05412 27.7694 6.73545 26.8402C6.73545 26.5462 6.66543 26.3641 6.65143 26.3221C5.64325 23.3676 4.18698 20.5391 4.03295 15.8342C3.94894 13.0337 3.82291 8.98696 6.4974 5.82238C9.40993 2.37775 13.9468 1.87366 15.9071 1.62162C18.0575 1.21046 20.2747 1.31605 22.3763 1.92967C23.2305 2.23773 27.2772 3.58198 28.5234 3.32993C29.1675 3.1619 29.4756 2.95186 29.4756 3.06388C29.4756 3.1759 37.331 -0.632804 41.6578 1.83165C45.9846 4.2961 45.6626 6.15844 46.5867 11.5494C47.008 15.1965 46.3967 18.8887 44.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2346)">
        <path d="M44.8224 22.2054L42.512 27.2883C41.3426 27.8626 40.1242 28.3312 38.8713 28.6886C35.7511 29.681 32.3998 29.681 29.2796 28.6886C26.157 27.2883 21.5222 29.0386 15.9771 29.3747C14.3286 29.4776 12.6742 29.3169 11.0762 28.8986C9.52231 28.4656 8.05412 27.7694 6.73545 26.8402C6.73545 26.5462 6.66543 26.3641 6.65143 26.3221C5.64325 23.3676 4.18698 20.5391 4.03295 15.8342C3.94894 13.0337 3.82291 8.98696 6.4974 5.82238C9.40993 2.37775 13.9468 1.87366 15.9071 1.62162C18.0575 1.21046 20.2747 1.31605 22.3763 1.92967C23.2305 2.23773 27.2772 3.58198 28.5234 3.32993C29.1675 3.1619 29.4756 2.95186 29.4756 3.06388C29.4756 3.1759 37.331 -0.632804 41.6578 1.83165C45.9846 4.2961 45.6626 6.15844 46.5867 11.5494C47.008 15.1965 46.3967 18.8887 44.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M25.6923 19C26.7544 19 28 13.7018 28 4.10345L26.0769 2.24138L23 1C23 10.5983 24.6302 19 25.6923 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M37.9735 25.832C37.9801 25.8914 37.9801 25.9514 37.9735 26.0108C37.3737 41.4652 36.2721 43.5853 34.9623 51.2487C33.6525 58.912 25.696 73.6386 23.6762 75.2734C21.6565 76.9082 22.1584 75.2734 22.1584 75.2734C27.4097 68.5169 30.6045 52.5259 30.6045 52.5259C30.6045 52.5259 31.5226 32.7928 31.0207 28.6291C30.5189 24.4654 26.3692 23.6735 23.6762 25.4616C20.9832 27.2497 19.5755 42.653 19.5755 42.653C18.939 47.6597 16.2668 52.5259 10.8845 61.6452C10.4071 61.9518 4.61231 68.3656 4.03698 67.7781C3.46166 67.1906 9.75835 59.0269 11.8026 54.0458C13.7643 48.8882 14.6571 43.3557 14.4221 37.8123C14.4221 36.931 14.4221 37.3142 13.8223 28.6291C13.7611 27.7861 13.7121 26.9687 13.6632 26.2024C13.4918 23.6479 13.3572 21.5277 13.2592 19.9823C13.4051 19.2075 13.7119 18.4755 14.1582 17.8377C14.6044 17.2 15.1792 16.6721 15.8421 16.2911C16.5191 16.0826 17.2318 16.0317 17.9298 16.1421C18.6278 16.2524 19.2941 16.5213 19.8815 16.9297C25.341 19.9823 28.3767 17.8749 28.3767 17.8749C28.3767 17.8749 38.5856 10.2243 37.9735 25.832Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="4" cy="69" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="23" cy="76" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2346)">
        <path d="M23 73L23.8229 76.7391L26.8567 74.4037L25.0837 77.797L28.9088 77.9581L25.3694 79.4178L28.1962 82L24.5465 80.8431L25.0521 84.6382L23 81.406L20.9479 84.6382L21.4535 80.8431L17.8038 82L20.6306 79.4178L17.0912 77.9581L20.9163 77.797L19.1433 74.4037L22.1771 76.7391L23 73Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2346" x="16.8911" y="72.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M40.397 27.0227C40.2559 35.482 39.2185 43.9026 37.3024 52.1433C34.2219 64.7456 33.9138 61.707 32.0655 66.7899C30.2171 71.8729 14.0302 83.9011 19.1131 74.1973C22.9052 66.5988 24.7116 58.1639 24.364 49.6788C24.21 44.9039 21.4235 45.8281 21.4235 45.8281C21.4235 45.8281 19.5332 46.2762 18.455 50.477C17.3898 53.8201 15.9427 57.0292 14.1422 60.0407C11.9375 63.4831 9.14043 66.5076 5.88068 68.9743C1.14781 72.2229 0.419678 68.9743 0.419678 68.9743C2.54585 62.5578 3.99139 55.9356 4.73247 49.2167C5.41316 41.7135 5.3381 34.1608 4.50842 26.6726C5.85715 27.5646 7.34913 28.2183 8.91923 28.605C10.5171 29.0233 12.1716 29.184 13.8201 29.081C19.4212 28.745 24 27.0087 27.1226 28.3949C30.2428 29.3873 33.5941 29.3873 36.7143 28.3949C37.9806 28.0489 39.2131 27.5897 40.397 27.0227Z" fill="#DEE1E9"/>
        <mask id="mask2546" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="30">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2546)">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M23.6923 18.7991C24.7544 18.7991 26 13.5008 26 3.90252L24.0769 2.04045L21 0.799072C21 10.3974 22.6302 18.7991 23.6923 18.7991Z" fill="#404040"/>
        </g>
        <circle cx="21" cy="76" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2646" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="79">
          <path d="M39.9773 27.3553C39.8362 35.8147 38.7989 44.2352 36.8827 52.4759C33.8022 65.0782 33.4941 62.0396 31.6458 67.1226C29.7974 72.2055 13.6105 84.2337 18.6934 74.5299C22.4855 66.9314 24.292 58.4965 23.9444 50.0115C23.7903 45.2366 21.0038 46.1607 21.0038 46.1607C21.0038 46.1607 19.1135 46.6088 18.0353 50.8096C16.9701 54.1527 15.523 57.3618 13.7225 60.3733C11.5178 63.8157 8.72075 66.8403 5.461 69.307C0.728134 72.5556 0 69.307 0 69.307C2.12617 62.8905 3.57171 56.2682 4.31279 49.5494C4.99348 42.0461 4.91843 34.4935 4.08875 27.0052C5.43747 27.8973 6.92945 28.5509 8.49955 28.9376C10.0975 29.3559 11.7519 29.5167 13.4004 29.4137C19.0015 29.0776 23.5803 27.3413 26.7029 28.7276C29.8231 29.72 33.1744 29.72 36.2946 28.7276C37.5609 28.3816 38.7934 27.9223 39.9773 27.3553Z" fill="#DEE1E9"/>
          <path d="M42.3729 22.2054L40.0625 27.2883C38.8931 27.8626 37.6747 28.3312 36.4219 28.6886C33.3016 29.681 29.9503 29.681 26.8301 28.6886C23.7075 27.2883 19.0727 29.0386 13.5277 29.3747C11.8791 29.4776 10.2247 29.3169 8.62678 28.8986C7.07285 28.4656 5.60466 27.7694 4.28598 26.8402C4.28598 26.5462 4.21597 26.3641 4.20197 26.3221C3.19378 23.3676 1.73752 20.5391 1.58349 15.8342C1.49947 13.0337 1.37345 8.98696 4.04794 5.82238C6.96047 2.37775 11.4973 1.87366 13.4577 1.62162C15.608 1.21046 17.8253 1.31605 19.9268 1.92967C20.781 2.23773 24.8277 3.58198 26.074 3.32993C26.7181 3.1619 27.0261 2.95186 27.0261 3.06388C27.0261 3.1759 34.8816 -0.632804 39.2084 1.83165C43.5352 4.2961 43.2131 6.15844 44.1373 11.5494C44.5586 15.1965 43.9473 18.8887 42.3729 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2646)">
          <path d="M39.9773 27.3553C39.8362 35.8147 38.7989 44.2352 36.8827 52.4759C33.8022 65.0782 33.4941 62.0396 31.6458 67.1226C29.7974 72.2055 13.6105 84.2337 18.6934 74.5299C22.4855 66.9314 24.292 58.4965 23.9444 50.0115C23.7903 45.2366 21.0038 46.1607 21.0038 46.1607C21.0038 46.1607 19.1135 46.6088 18.0353 50.8096C16.9701 54.1527 15.523 57.3618 13.7225 60.3733C11.5178 63.8157 8.72075 66.8403 5.461 69.307C0.728134 72.5556 0 69.307 0 69.307C2.12617 62.8905 3.57171 56.2682 4.31279 49.5494C4.99348 42.0461 4.91843 34.4935 4.08875 27.0052C5.43747 27.8973 6.92945 28.5509 8.49955 28.9376C10.0975 29.3559 11.7519 29.5167 13.4004 29.4137C19.0015 29.0776 23.5803 27.3413 26.7029 28.7276C29.8231 29.72 33.1744 29.72 36.2946 28.7276C37.5609 28.3816 38.7934 27.9223 39.9773 27.3553Z" fill="#DEE1E9"/>
          <path d="M42.3729 22.2054L40.0625 27.2883C38.8931 27.8626 37.6747 28.3312 36.4219 28.6886C33.3016 29.681 29.9503 29.681 26.8301 28.6886C23.7075 27.2883 19.0727 29.0386 13.5277 29.3747C11.8791 29.4776 10.2247 29.3169 8.62678 28.8986C7.07285 28.4656 5.60466 27.7694 4.28598 26.8402C4.28598 26.5462 4.21597 26.3641 4.20197 26.3221C3.19378 23.3676 1.73752 20.5391 1.58349 15.8342C1.49947 13.0337 1.37345 8.98696 4.04794 5.82238C6.96047 2.37775 11.4973 1.87366 13.4577 1.62162C15.608 1.21046 17.8253 1.31605 19.9268 1.92967C20.781 2.23773 24.8277 3.58198 26.074 3.32993C26.7181 3.1619 27.0261 2.95186 27.0261 3.06388C27.0261 3.1759 34.8816 -0.632804 39.2084 1.83165C43.5352 4.2961 43.2131 6.15844 44.1373 11.5494C44.5586 15.1965 43.9473 18.8887 42.3729 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M12.9741 19.6762H1.05292C3.21498 25.046 3.57199 28.3063 3.27545 34.0446C3.26427 34.2609 3.51364 34.3955 3.70387 34.2689C4.70794 33.601 7.06614 31.7849 7.63778 29.1432C8.33025 25.9432 10.3335 25.1661 10.5461 25.092C10.559 25.0875 10.5706 25.083 10.583 25.0773L11.7659 24.5307C11.9105 24.4639 11.9691 24.3014 11.8968 24.1678L11.3679 23.1901C11.3231 23.1074 11.3272 23.0092 11.3787 22.9299L13.2251 20.0858C13.3421 19.9056 13.2016 19.6762 12.9741 19.6762Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.04897 33.155C5.10995 32.3071 6.51793 30.8702 6.92035 29.0106C7.66356 25.5761 9.83953 24.6201 10.2725 24.4648L11.0461 24.1073L10.7135 23.4925C10.5569 23.203 10.5711 22.8596 10.7513 22.5821L12.1988 20.3524H2.09621C3.87294 25.0171 4.24894 28.2129 4.04897 33.155ZM10.2558 24.4725C10.2556 24.4726 10.2559 24.4724 10.2558 24.4725V24.4725ZM4.1313 34.8178C3.40244 35.3027 2.50671 34.7463 2.54464 34.0123C2.83745 28.3462 2.48824 25.18 0.367218 19.9121L-9.53674e-07 19H12.9741C13.7703 19 14.2621 19.8028 13.8524 20.4338L12.1301 23.0869L12.5512 23.8654C12.8042 24.333 12.5991 24.9017 12.0931 25.1355L10.9102 25.6822C10.8695 25.701 10.8336 25.7145 10.8041 25.7248C10.7675 25.7376 8.98671 26.3577 8.35523 29.2758C7.72573 32.1849 5.16235 34.1319 4.1313 34.8178Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask146" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="79">
          <path d="M39.9773 27.3553C39.8362 35.8147 38.7989 44.2352 36.8827 52.4759C33.8022 65.0782 33.4941 62.0396 31.6458 67.1226C29.7974 72.2055 13.6105 84.2337 18.6934 74.5299C22.4855 66.9314 24.292 58.4965 23.9444 50.0115C23.7903 45.2366 21.0038 46.1607 21.0038 46.1607C21.0038 46.1607 19.1135 46.6088 18.0353 50.8096C16.9701 54.1527 15.523 57.3618 13.7225 60.3733C11.5178 63.8157 8.72075 66.8403 5.461 69.307C0.728134 72.5556 0 69.307 0 69.307C2.12617 62.8905 3.57171 56.2682 4.31279 49.5494C4.99348 42.0461 4.91843 34.4935 4.08875 27.0052C5.43747 27.8973 6.92945 28.5509 8.49955 28.9376C10.0975 29.3559 11.7519 29.5167 13.4004 29.4137C19.0015 29.0776 23.5803 27.3413 26.7029 28.7276C29.8231 29.72 33.1744 29.72 36.2946 28.7276C37.5609 28.3816 38.7934 27.9223 39.9773 27.3553Z" fill="#DEE1E9"/>
          <path d="M42.3729 22.2054L40.0625 27.2883C38.8931 27.8626 37.6747 28.3312 36.4219 28.6886C33.3016 29.681 29.9503 29.681 26.8301 28.6886C23.7075 27.2883 19.0727 29.0386 13.5277 29.3747C11.8791 29.4776 10.2247 29.3169 8.62678 28.8986C7.07285 28.4656 5.60466 27.7694 4.28598 26.8402C4.28598 26.5462 4.21597 26.3641 4.20197 26.3221C3.19378 23.3676 1.73752 20.5391 1.58349 15.8342C1.49947 13.0337 1.37345 8.98696 4.04794 5.82238C6.96047 2.37775 11.4973 1.87366 13.4577 1.62162C15.608 1.21046 17.8253 1.31605 19.9268 1.92967C20.781 2.23773 24.8277 3.58198 26.074 3.32993C26.7181 3.1619 27.0261 2.95186 27.0261 3.06388C27.0261 3.1759 34.8816 -0.632804 39.2084 1.83165C43.5352 4.2961 43.2131 6.15844 44.1373 11.5494C44.5586 15.1965 43.9473 18.8887 42.3729 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask146)">
          <path d="M39.9773 27.3553C39.8362 35.8147 38.7989 44.2352 36.8827 52.4759C33.8022 65.0782 33.4941 62.0396 31.6458 67.1226C29.7974 72.2055 13.6105 84.2337 18.6934 74.5299C22.4855 66.9314 24.292 58.4965 23.9444 50.0115C23.7903 45.2366 21.0038 46.1607 21.0038 46.1607C21.0038 46.1607 19.1135 46.6088 18.0353 50.8096C16.9701 54.1527 15.523 57.3618 13.7225 60.3733C11.5178 63.8157 8.72075 66.8403 5.461 69.307C0.728134 72.5556 0 69.307 0 69.307C2.12617 62.8905 3.57171 56.2682 4.31279 49.5494C4.99348 42.0461 4.91843 34.4935 4.08875 27.0052C5.43747 27.8973 6.92945 28.5509 8.49955 28.9376C10.0975 29.3559 11.7519 29.5167 13.4004 29.4137C19.0015 29.0776 23.5803 27.3413 26.7029 28.7276C29.8231 29.72 33.1744 29.72 36.2946 28.7276C37.5609 28.3816 38.7934 27.9223 39.9773 27.3553Z" fill="#DEE1E9"/>
          <path d="M42.3729 22.2054L40.0625 27.2883C38.8931 27.8626 37.6747 28.3312 36.4219 28.6886C33.3016 29.681 29.9503 29.681 26.8301 28.6886C23.7075 27.2883 19.0727 29.0386 13.5277 29.3747C11.8791 29.4776 10.2247 29.3169 8.62678 28.8986C7.07285 28.4656 5.60466 27.7694 4.28598 26.8402C4.28598 26.5462 4.21597 26.3641 4.20197 26.3221C3.19378 23.3676 1.73752 20.5391 1.58349 15.8342C1.49947 13.0337 1.37345 8.98696 4.04794 5.82238C6.96047 2.37775 11.4973 1.87366 13.4577 1.62162C15.608 1.21046 17.8253 1.31605 19.9268 1.92967C20.781 2.23773 24.8277 3.58198 26.074 3.32993C26.7181 3.1619 27.0261 2.95186 27.0261 3.06388C27.0261 3.1759 34.8816 -0.632804 39.2084 1.83165C43.5352 4.2961 43.2131 6.15844 44.1373 11.5494C44.5586 15.1965 43.9473 18.8887 42.3729 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M8.86628 20.5H0.719519C2.19704 24.4705 2.44101 26.8811 2.23836 31.1241C2.23072 31.2841 2.40114 31.3836 2.53113 31.29C3.2173 30.7961 4.82886 29.4532 5.21952 27.5C5.69274 25.1339 7.0617 24.5593 7.20699 24.5045C7.21585 24.5012 7.22377 24.4978 7.23223 24.4936L8.04063 24.0894C8.13943 24.04 8.17947 23.9199 8.13008 23.8211L7.76862 23.0982C7.73804 23.037 7.74082 22.9645 7.776 22.9058L9.03778 20.8029C9.11776 20.6696 9.02174 20.5 8.86628 20.5Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2.76699 30.4664C3.49205 29.8394 4.45424 28.777 4.72925 27.4019C5.23715 24.8625 6.72418 24.1555 7.02006 24.0407L7.54872 23.7764L7.32143 23.3218C7.21439 23.1077 7.22415 22.8538 7.34728 22.6486L8.33644 21H1.4325C2.64669 24.4491 2.90365 26.8121 2.76699 30.4664ZM7.00864 24.0464C7.00854 24.0465 7.00874 24.0464 7.00864 24.0464V24.0464ZM2.82326 31.6958C2.32516 32.0544 1.71303 31.643 1.73895 31.1003C1.93905 26.9106 1.70041 24.5695 0.250934 20.6744L-1.81198e-05 20H8.8663C9.41041 20 9.74649 20.5936 9.46655 21.0601L8.2895 23.0219L8.57731 23.5975C8.7502 23.9433 8.61005 24.3638 8.26426 24.5367L7.45586 24.9409C7.42803 24.9548 7.40354 24.9648 7.38336 24.9724C7.35836 24.9818 6.14137 25.4403 5.70983 27.5981C5.27964 29.749 3.52786 31.1887 2.82326 31.6958Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M40.397 27.0227C40.2559 35.482 39.2185 43.9026 37.3024 52.1433C34.2219 64.7456 33.9138 61.707 32.0655 66.7899C30.2171 71.8729 14.0302 83.9011 19.1131 74.1973C22.9052 66.5988 24.7116 58.1639 24.364 49.6788C24.21 44.9039 21.4235 45.8281 21.4235 45.8281C21.4235 45.8281 19.5332 46.2762 18.455 50.477C17.3898 53.8201 15.9427 57.0292 14.1422 60.0407C11.9375 63.4831 9.14043 66.5076 5.88068 68.9743C1.14781 72.2229 0.419678 68.9743 0.419678 68.9743C2.54585 62.5578 3.99139 55.9356 4.73247 49.2167C5.41316 41.7135 5.3381 34.1608 4.50842 26.6726C5.85715 27.5646 7.34913 28.2183 8.91923 28.605C10.5171 29.0233 12.1716 29.184 13.8201 29.081C19.4212 28.745 24 27.0087 27.1226 28.3949C30.2428 29.3873 33.5941 29.3873 36.7143 28.3949C37.9806 28.0489 39.2131 27.5897 40.397 27.0227Z" fill="#DEE1E9"/>
        <mask id="mask246" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="30">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask246)">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M24.3462 16C20.7351 16 16 8.53185 16 0L23.0385 1.10345L33.5 0C33.5 8.53185 27.9572 16 24.3462 16Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M35.9735 23.3236C35.9801 23.3859 35.9801 23.4489 35.9735 23.5113C35.3737 39.7384 34.2721 41.9646 32.9623 50.0111C31.6525 58.0576 23.696 73.5205 21.6762 75.2371C19.6565 76.9537 20.1584 75.2371 20.1584 75.2371C25.4097 68.1428 28.6045 51.3522 28.6045 51.3522C28.6045 51.3522 29.5226 30.6325 29.0207 26.2605C28.5189 21.8886 24.3692 21.0572 21.6762 22.9347C18.9832 24.8122 17.5755 40.9856 17.5755 40.9856C16.939 46.2426 14.2668 51.3522 8.88451 60.9275C8.40712 61.2493 2.61231 67.9839 2.03698 67.367C1.46166 66.7501 7.75835 58.1783 9.80258 52.9481C11.7643 47.5327 12.6571 41.7235 12.4221 35.9029C12.4221 34.9776 12.4221 35.3799 11.8223 26.2605C11.7611 25.3754 11.7121 24.5171 11.6632 23.7125C11.4918 21.0303 11.3572 18.8041 11.2592 17.1814C11.4051 16.3679 11.7119 15.5992 12.1582 14.9296C12.6044 14.26 13.1792 13.7057 13.8421 13.3057C14.5191 13.0868 15.2318 13.0333 15.9298 13.1492C16.6278 13.2651 17.2941 13.5474 17.8815 13.9762C23.341 17.1814 26.3767 14.9686 26.3767 14.9686C26.3767 14.9686 36.5856 6.93556 35.9735 23.3236Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M40.397 27.0227C40.2559 35.482 39.2185 43.9026 37.3024 52.1433C34.2219 64.7456 33.9138 61.707 32.0655 66.7899C30.2171 71.8729 14.0302 83.9011 19.1131 74.1973C22.9052 66.5988 24.7116 58.1639 24.364 49.6788C24.21 44.9039 21.4235 45.8281 21.4235 45.8281C21.4235 45.8281 19.5332 46.2762 18.455 50.477C17.3898 53.8201 15.9427 57.0292 14.1422 60.0407C11.9375 63.4831 9.14043 66.5076 5.88068 68.9743C1.14781 72.2229 0.419678 68.9743 0.419678 68.9743C2.54585 62.5578 3.99139 55.9356 4.73247 49.2167C5.41316 41.7135 5.3381 34.1608 4.50842 26.6726C5.85715 27.5646 7.34913 28.2183 8.91923 28.605C10.5171 29.0233 12.1716 29.184 13.8201 29.081C19.4212 28.745 24 27.0087 27.1226 28.3949C30.2428 29.3873 33.5941 29.3873 36.7143 28.3949C37.9806 28.0489 39.2131 27.5897 40.397 27.0227Z" fill="#DEE1E9"/>
        <mask id="mask346" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="30">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask346)">
          <path d="M42.8224 22.0044L40.512 27.0874C39.3426 27.6616 38.1242 28.1302 36.8713 28.4876C33.7511 29.4801 30.3998 29.4801 27.2796 28.4876C24.157 27.0874 19.5222 28.8377 13.9771 29.1737C12.3286 29.2767 10.6742 29.116 9.07624 28.6977C7.52231 28.2646 6.05412 27.5684 4.73545 26.6393C4.73545 26.3452 4.66543 26.1632 4.65143 26.1212C3.64325 23.1667 2.18698 20.3381 2.03295 15.6333C1.94894 12.8328 1.82291 8.78603 4.4974 5.62146C7.40993 2.17683 11.9468 1.67274 13.9071 1.42069C16.0575 1.00953 18.2747 1.11512 20.3763 1.72875C21.2305 2.0368 25.2772 3.38105 26.5234 3.129C27.1675 2.96097 27.4756 2.75093 27.4756 2.86295C27.4756 2.97497 35.331 -0.833731 39.6578 1.63072C43.9846 4.09517 43.6626 5.95751 44.5867 11.3485C45.008 14.9956 44.3967 18.6878 42.8224 22.0044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M23.6923 18.7991C24.7544 18.7991 26 13.5008 26 3.90252L24.0769 2.04045L21 0.799072C21 10.3974 22.6302 18.7991 23.6923 18.7991Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M40.397 27.3554C40.2559 35.8148 39.2185 44.2353 37.3024 52.476C34.2219 65.0783 33.9138 62.0398 32.0655 67.1227C30.2171 72.2056 14.0302 84.2338 19.1131 74.5301C22.9052 66.9316 24.7116 58.4966 24.364 50.0116C24.21 45.2367 21.4235 46.1609 21.4235 46.1609C21.4235 46.1609 19.5332 46.609 18.455 50.8097C17.3898 54.1528 15.9427 57.362 14.1422 60.3735C11.9375 63.8158 9.14043 66.8404 5.88068 69.3071C1.14781 72.5557 0.419678 69.3071 0.419678 69.3071C2.54585 62.8906 3.99139 56.2683 4.73247 49.5495C5.41316 42.0462 5.3381 34.4936 4.50842 27.0054C5.85715 27.8974 7.34913 28.551 8.91923 28.9377C10.5171 29.3561 12.1716 29.5168 13.8201 29.4138C19.4212 29.0777 24 27.3414 27.1226 28.7277C30.2428 29.7201 33.5941 29.7201 36.7143 28.7277C37.9806 28.3817 39.2131 27.9224 40.397 27.3554Z" fill="#DEE1E9"/>
        <path d="M42.7926 22.2054L40.4822 27.2883C39.3128 27.8626 38.0944 28.3312 36.8415 28.6886C33.7213 29.681 30.37 29.681 27.2498 28.6886C24.1272 27.2883 19.4924 29.0386 13.9474 29.3747C12.2988 29.4776 10.6444 29.3169 9.04645 28.8986C7.49253 28.4656 6.02434 27.7694 4.70566 26.8402C4.70566 26.5462 4.63565 26.3641 4.62165 26.3221C3.61346 23.3676 2.15719 20.5391 2.00317 15.8342C1.91915 13.0337 1.79313 8.98696 4.46762 5.82238C7.38015 2.37775 11.917 1.87366 13.8773 1.62162C16.0277 1.21046 18.245 1.31605 20.3465 1.92967C21.2007 2.23773 25.2474 3.58198 26.4936 3.32993C27.1378 3.1619 27.4458 2.95186 27.4458 3.06388C27.4458 3.1759 35.3013 -0.632804 39.628 1.83165C43.9548 4.2961 43.6328 6.15844 44.5569 11.5494C44.9783 15.1965 44.367 18.8887 42.7926 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M37 1.13184L38.8667 3.6327L40.5 2.13699V5.45104L43.0622 4.88312L41.6667 6.63373L44 8.63441L41.6667 10.6351L43.0622 12.3857L40.7333 11.6354L40.5 15.1318L38.2501 13.6348L37.4667 14.6365L35.7499 13.6348L33.5 15.1318L32.8 13.136L30.9378 12.3857L31.4 9.63476L30 8.63441L32.3333 7.1339L30.9378 4.88312L32.8 4.63304L33.5 2.13699L36.5333 4.13287L37 1.13184Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M40.397 27.3554C40.2559 35.8148 39.2185 44.2353 37.3024 52.476C34.2219 65.0783 33.9138 62.0398 32.0655 67.1227C30.2171 72.2056 14.0302 84.2338 19.1131 74.5301C22.9052 66.9316 24.7116 58.4966 24.364 50.0116C24.21 45.2367 21.4235 46.1609 21.4235 46.1609C21.4235 46.1609 19.5332 46.609 18.455 50.8097C17.3898 54.1528 15.9427 57.362 14.1422 60.3735C11.9375 63.8158 9.14043 66.8404 5.88068 69.3071C1.14781 72.5557 0.419678 69.3071 0.419678 69.3071C2.54585 62.8906 3.99139 56.2683 4.73247 49.5495C5.41316 42.0462 5.3381 34.4936 4.50842 27.0054C5.85715 27.8974 7.34913 28.551 8.91923 28.9377C10.5171 29.3561 12.1716 29.5168 13.8201 29.4138C19.4212 29.0777 24 27.3414 27.1226 28.7277C30.2428 29.7201 33.5941 29.7201 36.7143 28.7277C37.9806 28.3817 39.2131 27.9224 40.397 27.3554Z" fill="#DEE1E9"/>
        <path d="M42.7926 22.2054L40.4822 27.2883C39.3128 27.8626 38.0944 28.3312 36.8415 28.6886C33.7213 29.681 30.37 29.681 27.2498 28.6886C24.1272 27.2883 19.4924 29.0386 13.9474 29.3747C12.2988 29.4776 10.6444 29.3169 9.04645 28.8986C7.49253 28.4656 6.02434 27.7694 4.70566 26.8402C4.70566 26.5462 4.63565 26.3641 4.62165 26.3221C3.61346 23.3676 2.15719 20.5391 2.00317 15.8342C1.91915 13.0337 1.79313 8.98696 4.46762 5.82238C7.38015 2.37775 11.917 1.87366 13.8773 1.62162C16.0277 1.21046 18.245 1.31605 20.3465 1.92967C21.2007 2.23773 25.2474 3.58198 26.4936 3.32993C27.1378 3.1619 27.4458 2.95186 27.4458 3.06388C27.4458 3.1759 35.3013 -0.632804 39.628 1.83165C43.9548 4.2961 43.6328 6.15844 44.5569 11.5494C44.9783 15.1965 44.367 18.8887 42.7926 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M38 1.13184L39.6 3.27543L41 1.9934V4.83401L43.1962 4.34723L42 5.84774L44 7.56262L42 9.27749L43.1962 10.778L41.2 10.1349L41 13.1318L39.0715 11.8487L38.4 12.7072L36.9285 11.8487L35 13.1318L34.4 11.4211L32.8038 10.778L33.2 8.42005L32 7.56262L34 6.27646L32.8038 4.34723L34.4 4.13287L35 1.9934L37.6 3.70415L38 1.13184Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M40.397 27.3554C40.2559 35.8148 39.2185 44.2353 37.3024 52.476C34.2219 65.0783 33.9138 62.0398 32.0655 67.1227C30.2171 72.2056 14.0302 84.2338 19.1131 74.5301C22.9052 66.9316 24.7116 58.4966 24.364 50.0116C24.21 45.2367 21.4235 46.1609 21.4235 46.1609C21.4235 46.1609 19.5332 46.609 18.455 50.8097C17.3898 54.1528 15.9427 57.362 14.1422 60.3735C11.9375 63.8158 9.14043 66.8404 5.88068 69.3071C1.14781 72.5557 0.419678 69.3071 0.419678 69.3071C2.54585 62.8906 3.99139 56.2683 4.73247 49.5495C5.41316 42.0462 5.3381 34.4936 4.50842 27.0054C5.85715 27.8974 7.34913 28.551 8.91923 28.9377C10.5171 29.3561 12.1716 29.5168 13.8201 29.4138C19.4212 29.0777 24 27.3414 27.1226 28.7277C30.2428 29.7201 33.5941 29.7201 36.7143 28.7277C37.9806 28.3817 39.2131 27.9224 40.397 27.3554Z" fill="#DEE1E9"/>
        <path d="M42.7926 22.2054L40.4822 27.2883C39.3128 27.8626 38.0944 28.3312 36.8415 28.6886C33.7213 29.681 30.37 29.681 27.2498 28.6886C24.1272 27.2883 19.4924 29.0386 13.9474 29.3747C12.2988 29.4776 10.6444 29.3169 9.04645 28.8986C7.49253 28.4656 6.02434 27.7694 4.70566 26.8402C4.70566 26.5462 4.63565 26.3641 4.62165 26.3221C3.61346 23.3676 2.15719 20.5391 2.00317 15.8342C1.91915 13.0337 1.79313 8.98696 4.46762 5.82238C7.38015 2.37775 11.917 1.87366 13.8773 1.62162C16.0277 1.21046 18.245 1.31605 20.3465 1.92967C21.2007 2.23773 25.2474 3.58198 26.4936 3.32993C27.1378 3.1619 27.4458 2.95186 27.4458 3.06388C27.4458 3.1759 35.3013 -0.632804 39.628 1.83165C43.9548 4.2961 43.6328 6.15844 44.5569 11.5494C44.9783 15.1965 44.367 18.8887 42.7926 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M38.5 2.13184L39.4333 3.38227L40.25 2.63441V4.29144L41.5311 4.00748L40.8333 4.88278L42 5.88312L40.8333 6.88347L41.5311 7.75877L40.3667 7.38364L40.25 9.13184L39.125 8.38332L38.7333 8.88416L37.875 8.38332L36.75 9.13184L36.4 8.1339L35.4689 7.75877L35.7 6.3833L35 5.88312L36.1667 5.13287L35.4689 4.00748L36.4 3.88244L36.75 2.63441L38.2667 3.63235L38.5 2.13184Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M18.0123 70.6565L20.096 71.9263H23.5797L25.6797 70.6565L24.0681 69.11L26.1518 67.5635L24.2634 66.017L26.5588 64.324L24.7355 62.7775L27.0309 61.3775L25.0123 59.9612L27.0309 58.3496L25.3378 56.803L27.5681 55.3868L25.8099 53.9217L28.1053 52.3751L25.9564 50.8286L28.1053 49.217L26.5588 47.8659L28.6425 46.3193L26.8192 44.838L28.9029 43.2914L27.096 41.5496L28.1053 39.8728V25.6124L26.2169 18.2868H20.096" fill="#8889BE"/>
        <path d="M18.0123 70.6565L20.096 71.9263H23.5797L25.6797 70.6565L24.0681 69.11L26.1518 67.5635L24.2634 66.017L26.5588 64.324L24.7355 62.7775L27.0309 61.3775L25.0123 59.9612L27.0309 58.3496L25.3378 56.803L27.5681 55.3868L25.8099 53.9217L28.1053 52.3751L25.9564 50.8286L28.1053 49.217L26.5588 47.8659L28.6425 46.3193L26.8192 44.838L28.9029 43.2914L27.096 41.5496L28.1053 39.8728V25.6124L26.2169 18.2868H20.096" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M25.4195 70.6565L23.3358 71.9263H19.8684L17.7521 70.6565L19.38 69.11L17.28 67.5635L19.1684 66.017L16.8893 64.324L18.6963 62.7775L16.4172 61.3775L18.4358 59.9612L16.4172 58.3496L18.094 56.803L15.88 55.3868L17.6219 53.9217L15.3428 52.3751L17.4917 50.8286L15.3428 49.217L16.8893 47.8659L14.8056 46.3193L16.6126 44.838L14.5289 43.2914L16.3521 41.5496L15.3428 39.8728V25.6124L17.2149 18.2868H21.1219" fill="#8889BE"/>
        <path d="M25.4195 70.6565L23.3358 71.9263H19.8684L17.7521 70.6565L19.38 69.11L17.28 67.5635L19.1684 66.017L16.8893 64.324L18.6963 62.7775L16.4172 61.3775L18.4358 59.9612L16.4172 58.3496L18.094 56.803L15.88 55.3868L17.6219 53.9217L15.3428 52.3751L17.4917 50.8286L15.3428 49.217L16.8893 47.8659L14.8056 46.3193L16.6126 44.838L14.5289 43.2914L16.3521 41.5496L15.3428 39.8728V25.6124L17.2149 18.2868H21.1219" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask546" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="30">
          <path d="M41.8224 22.2806L39.512 27.3635C38.5316 27.8449 33.4114 28.915 31.0433 29.459C30.4882 29.5865 29.9196 29.2444 29.7877 28.6903L27.5298 19.207C22.9551 17.9769 20.4443 18.0669 16.0298 19.207L13.7704 28.6965C13.639 29.2483 13.0761 29.5867 12.5245 29.4546C10.0523 28.8624 4.85924 27.7072 3.73545 26.9154C3.73545 26.6214 3.66543 26.4393 3.65143 26.3973C2.64325 23.4428 1.18698 20.6143 1.03295 15.9094C0.948937 13.1089 0.822913 9.06216 3.4974 5.89758C6.40993 2.45295 10.9468 1.94886 12.9071 1.69681C15.0575 1.28566 17.2747 1.39124 19.3763 2.00487C20.2305 2.31293 24.2772 3.65717 25.5234 3.40513C26.1675 3.2371 26.4756 3.02705 26.4756 3.13907C26.4756 3.25109 34.331 -0.557608 38.6578 1.90684C42.9846 4.37129 42.6626 6.23364 43.5867 11.6246C44.008 15.2717 43.3967 18.9639 41.8224 22.2806Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask546)">
          <path d="M41.8224 22.2806L39.512 27.3635C38.5316 27.8449 33.4114 28.915 31.0433 29.459C30.4882 29.5865 29.9196 29.2444 29.7877 28.6903L27.5298 19.207C22.9551 17.9769 20.4443 18.0669 16.0298 19.207L13.7704 28.6965C13.639 29.2483 13.0761 29.5867 12.5245 29.4546C10.0523 28.8624 4.85924 27.7072 3.73545 26.9154C3.73545 26.6214 3.66543 26.4393 3.65143 26.3973C2.64325 23.4428 1.18698 20.6143 1.03295 15.9094C0.948937 13.1089 0.822913 9.06216 3.4974 5.89758C6.40993 2.45295 10.9468 1.94886 12.9071 1.69681C15.0575 1.28566 17.2747 1.39124 19.3763 2.00487C20.2305 2.31293 24.2772 3.65717 25.5234 3.40513C26.1675 3.2371 26.4756 3.02705 26.4756 3.13907C26.4756 3.25109 34.331 -0.557608 38.6578 1.90684C42.9846 4.37129 42.6626 6.23364 43.5867 11.6246C44.008 15.2717 43.3967 18.9639 41.8224 22.2806Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M34 43.0752H10V17.5272C19.3726 15.601 24.6274 15.5814 34 17.5272V43.0752Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M18.0123 70.9475L20.096 72.2173H23.5797L25.6797 70.9475L24.0681 69.401L26.1518 67.8545L24.2634 66.308L26.5588 64.615L24.7355 63.0685L27.0309 61.6685L25.0123 60.2522L27.0309 58.6406L25.3378 57.0941L27.5681 55.6778L25.8099 54.2127L28.1053 52.6662L25.9564 51.1197L28.1053 49.508L26.5588 48.1569L28.6425 46.6104L26.8192 45.129L28.9029 43.5825L27.096 41.8406L28.1053 40.1639V25.9034L26.2169 18.5779H20.096" fill="#8889BE"/>
        <path d="M18.0123 70.9475L20.096 72.2173H23.5797L25.6797 70.9475L24.0681 69.401L26.1518 67.8545L24.2634 66.308L26.5588 64.615L24.7355 63.0685L27.0309 61.6685L25.0123 60.2522L27.0309 58.6406L25.3378 57.0941L27.5681 55.6778L25.8099 54.2127L28.1053 52.6662L25.9564 51.1197L28.1053 49.508L26.5588 48.1569L28.6425 46.6104L26.8192 45.129L28.9029 43.5825L27.096 41.8406L28.1053 40.1639V25.9034L26.2169 18.5779H20.096" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M25.4195 70.9475L23.3358 72.2173H19.8684L17.7521 70.9475L19.38 69.401L17.28 67.8545L19.1684 66.308L16.8893 64.615L18.6963 63.0685L16.4172 61.6685L18.4358 60.2522L16.4172 58.6406L18.094 57.0941L15.88 55.6778L17.6219 54.2127L15.3428 52.6662L17.4917 51.1197L15.3428 49.508L16.8893 48.1569L14.8056 46.6104L16.6126 45.129L14.5289 43.5825L16.3521 41.8406L15.3428 40.1639V25.9034L17.2149 18.5779H21.1219" fill="#8889BE"/>
        <path d="M25.4195 70.9475L23.3358 72.2173H19.8684L17.7521 70.9475L19.38 69.401L17.28 67.8545L19.1684 66.308L16.8893 64.615L18.6963 63.0685L16.4172 61.6685L18.4358 60.2522L16.4172 58.6406L18.094 57.0941L15.88 55.6778L17.6219 54.2127L15.3428 52.6662L17.4917 51.1197L15.3428 49.508L16.8893 48.1569L14.8056 46.6104L16.6126 45.129L14.5289 43.5825L16.3521 41.8406L15.3428 40.1639V25.9034L17.2149 18.5779H21.1219" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask546" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="45" height="30">
          <path d="M41.8224 22.2054L39.512 27.2883C38.5316 27.7697 33.4114 28.8398 31.0433 29.3838C30.4882 29.5113 29.9196 29.1692 29.7877 28.6151L27.5298 19.1318C22.9551 17.9017 20.4443 17.9917 16.0298 19.1318L13.7704 28.6213C13.639 29.1731 13.0761 29.5115 12.5245 29.3794C10.0523 28.7872 4.85924 27.632 3.73545 26.8402C3.73545 26.5462 3.66543 26.3641 3.65143 26.3221C2.64325 23.3676 1.18698 20.5391 1.03295 15.8342C0.948937 13.0337 0.822913 8.98696 3.4974 5.82238C6.40993 2.37775 10.9468 1.87366 12.9071 1.62162C15.0575 1.21046 17.2747 1.31605 19.3763 1.92967C20.2305 2.23773 24.2772 3.58198 25.5234 3.32993C26.1675 3.1619 26.4756 2.95186 26.4756 3.06388C26.4756 3.1759 34.331 -0.632804 38.6578 1.83165C42.9846 4.2961 42.6626 6.15844 43.5867 11.5494C44.008 15.1965 43.3967 18.8887 41.8224 22.2054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask546)">
          <path d="M41.8224 22.2054L39.512 27.2883C38.5358 27.7677 32.717 28.8308 30.092 29.3769C29.4964 29.5008 28.8971 29.0928 28.8143 28.49L27.5298 19.1318C22.9551 17.9017 20.4443 17.9917 16.0298 19.1318L14.6832 28.5052C14.5974 29.1025 14.0046 29.4997 13.4153 29.3701C10.711 28.7752 4.85338 27.6279 3.73545 26.8402C3.73545 26.5462 3.66543 26.3641 3.65143 26.3221C2.64325 23.3676 1.18698 20.5391 1.03295 15.8342C0.948937 13.0337 0.822913 8.98696 3.4974 5.82238C6.40993 2.37775 10.9468 1.87366 12.9071 1.62162C15.0575 1.21046 17.2747 1.31605 19.3763 1.92967C20.2305 2.23773 24.2772 3.58198 25.5234 3.32993C26.1675 3.1619 26.4756 2.95186 26.4756 3.06388C26.4756 3.1759 34.331 -0.632804 38.6578 1.83165C42.9846 4.2961 42.6626 6.15844 43.5867 11.5494C44.008 15.1965 43.3967 18.8887 41.8224 22.2054Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M1 0.259766H22V5.25977H1V0.259766Z" fill="#838AA2"/>
        <path d="M23 0.259766H44V5.25977H23V0.259766Z" fill="#838AA2"/>
        <path d="M39.9773 29.9014C39.8362 38.3607 38.7989 46.7813 36.8827 55.0219C33.8022 67.6242 33.4941 64.5857 31.6458 69.6686C29.7974 74.7516 13.6105 86.7798 18.6934 77.076C22.4855 69.4775 24.292 61.0425 23.9444 52.5575C23.7903 47.7826 21.0038 48.7068 21.0038 48.7068C21.0038 48.7068 19.1135 49.1549 18.0353 53.3556C16.9701 56.6987 15.523 59.9079 13.7225 62.9194C11.5178 66.3617 8.72075 69.3863 5.461 71.853C0.728134 75.1016 0 71.853 0 71.853C2.12617 65.4365 3.57171 58.8142 4.31279 52.0954C4.99348 44.5922 4.91843 37.0395 4.08875 29.5513C5.43747 30.4433 6.60932 16.8151 8.17943 17.2018C9.77734 17.6201 11.4318 17.7808 13.0803 17.6779C18.6813 17.3418 23.2602 15.6055 26.3828 16.9917C29.503 17.9842 32.8543 17.9842 35.9745 16.9917C37.2408 16.6458 38.7934 30.4684 39.9773 29.9014Z" fill="#DEE1E9"/>
        <path d="M42.3729 24.7513L40.0625 29.8342C39.0821 30.3156 33.9619 31.3857 31.5938 31.9297C31.0387 32.0572 30.4702 31.7151 30.3382 31.161L28.0803 21.6777C23.5056 20.4476 20.9949 20.5376 16.5803 21.6777L14.3209 31.1672C14.1895 31.719 13.6266 32.0574 13.075 31.9253C10.6029 31.3331 5.40977 30.1779 4.28598 29.3861C4.28598 29.0921 4.21597 28.91 4.20197 28.868C3.19378 25.9135 1.73752 23.085 1.58349 18.3801C1.49947 15.5796 1.37345 11.5329 4.04794 8.36828C6.96047 4.92365 11.4973 4.41956 13.4577 4.16752C15.608 3.75636 17.8253 3.86195 19.9268 4.47557C20.781 4.78363 24.8277 6.12788 26.074 5.87583C26.7181 5.7078 27.0261 5.49776 27.0261 5.60978C27.0261 5.7218 34.8816 1.91309 39.2084 4.37754C43.5352 6.842 43.2131 8.70434 44.1373 14.0953C44.5586 17.7424 43.9473 21.4346 42.3729 24.7513Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M40.397 27.6465C40.2559 36.1058 39.2185 44.5264 37.3024 52.767C34.2219 65.3693 33.9138 62.3308 32.0655 67.4137C30.2171 72.4966 17.9171 81.2174 23 71.5137C26.7921 63.9152 24.7116 58.7876 24.364 50.3026C24.21 45.5277 21.4235 46.4519 21.4235 46.4519C21.4235 46.4519 19.5332 46.9 18.455 51.1007C17.3898 54.4438 15.9427 57.653 14.1422 60.6645C11.9375 64.1068 9.14043 67.1314 5.88068 69.5981C1.14781 72.8467 0.419678 69.5981 0.419678 69.5981C2.54585 63.1816 3.99139 56.5593 4.73247 49.8405C5.41316 42.3373 5.3381 34.7846 4.50842 27.2964C5.85715 28.1884 7.34913 28.842 8.91923 29.2287C10.5171 29.6471 12.1716 29.8078 13.8201 29.7048C19.4212 29.3688 24 27.6324 27.1226 29.0187C30.2428 30.0111 33.5941 30.0111 36.7143 29.0187C37.9806 28.6727 39.2131 28.2135 40.397 27.6465Z" fill="#DEE1E9"/>
        <path d="M24.364 61.5137C24.2154 62.4596 21.7568 64.6082 20 68.0137C18.0675 71.6624 14.1422 70.5137 14.1422 70.5137L12.5 60.0137C12.6351 58.9461 20.054 44.7299 21.5 45.0137C22.946 45.2975 30.8817 52.6625 24.364 61.5137Z" fill="#DEE1E9"/>
        <mask id="mask546" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="31">
          <path d="M42.8224 22.6282L40.512 27.7112C39.3426 28.2854 38.1242 28.754 36.8713 29.1114C33.7511 30.1038 30.3998 30.1038 27.2796 29.1114C24.157 27.7112 19.5222 29.4615 13.9771 29.7975C12.3286 29.9005 10.6742 29.7398 9.07624 29.3214C7.52231 28.8884 6.05412 28.1922 4.73545 27.2631C4.73545 26.969 4.66543 26.787 4.65143 26.745C3.64325 23.7904 2.18698 20.9619 2.03295 16.2571C1.94894 13.4565 1.82291 9.40981 4.4974 6.24524C7.40993 2.80061 11.9468 2.29651 13.9071 2.04447C16.0575 1.63331 18.2747 1.7389 20.3763 2.35253C21.2305 2.66058 25.2772 4.00483 26.5234 3.75278C27.1675 3.58475 27.4756 3.37471 27.4756 3.48673C27.4756 3.59875 35.331 -0.209952 39.6578 2.2545C43.9846 4.71895 43.6626 6.58129 44.5867 11.9723C45.008 15.6194 44.3967 19.3115 42.8224 22.6282Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask546)">
          <path d="M42.8224 22.6282L40.512 27.7112C39.3426 28.2854 38.1242 28.754 36.8713 29.1114C33.7511 30.1038 30.3998 30.1038 27.2796 29.1114C24.157 27.7112 19.5222 29.4615 13.9771 29.7975C12.3286 29.9005 10.6742 29.7398 9.07624 29.3214C7.52231 28.8884 6.05412 28.1922 4.73545 27.2631C4.73545 26.969 4.66543 26.787 4.65143 26.745C3.64325 23.7904 2.18698 20.9619 2.03295 16.2571C1.94894 13.4565 1.82291 9.40981 4.4974 6.24524C7.40993 2.80061 11.9468 2.29651 13.9071 2.04447C16.0575 1.63331 18.2747 1.7389 20.3763 2.35253C21.2305 2.66058 25.2772 4.00483 26.5234 3.75278C27.1675 3.58475 27.4756 3.37471 27.4756 3.48673C27.4756 3.59875 35.331 -0.209952 39.6578 2.2545C43.9846 4.71895 43.6626 6.58129 44.5867 11.9723C45.008 15.6194 44.3967 19.3115 42.8224 22.6282Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>
        <path opacity="0.2" d="M34.4823 16.6566C35.4346 22.7328 34.804 28.809 34.6882 33.7155C34.5595 39.093 35.1643 38.1967 34.907 42.4956C34.6475 46.8473 33.9299 51.1438 32.7708 55.2861C32.3591 56.7292 31.8443 58.233 31.291 60.8154C30.7377 63.3978 27.7188 68.8471 27.5 68.8471C26.9981 68.8471 30.2229 59.5256 30.2229 52.6361C30.5318 48.2612 30.3773 46.4148 30.2229 42.4956C30.0942 38.8347 29.528 34.7181 28.9361 34.7181C28.3442 34.7181 28.357 37.1334 27.6493 40.9918C26.6584 46.4755 25.7705 51.4277 23.184 56.0456C21.1637 59.6609 19.9412 59.7976 18.0367 64.0662C16.6727 67.2258 16.3346 68.5896 16 68.5137C15.6654 68.4377 15.6432 65.1751 16.544 60.8154C17.4448 56.4557 18.4614 55.4683 19.5294 51.534C21.1766 45.4578 21.3052 38.2575 19.9541 37.7258C19.0018 37.3764 17.3804 40.2475 15.0641 44.5008C13.2626 47.7516 12.5806 49.5592 10.5989 53.7822C8.61716 58.0052 3.53249 69.1054 3.0435 68.8471C2.5545 68.5889 6.31731 60.6787 7.8615 55.2861C9.26414 50.3947 9.44072 49.6995 10.5989 45.5069C11.4875 42.6441 11.9826 39.2051 12.5162 36.222C13.0489 33.2578 13.2607 30.2259 13.1468 27.1988C12.9666 23.3708 12.2846 21.9429 12.2975 17.1579C12.2975 13.9679 12.6192 12.7374 13.3655 11.8868C14.1119 11.0361 15.5145 10.7626 17.6121 10.6411C19.5479 10.332 21.5185 10.5875 23.3513 11.3855C26.3625 12.9045 26.3882 15.5173 28.4986 15.654C30.609 15.7907 31.7929 13.1931 33.1698 13.9071C34.0834 14.3476 34.3408 15.7907 34.4823 16.6566Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M40.397 26.7373C40.2559 35.1966 39.2185 43.6172 37.3024 51.8579C34.2219 64.4602 33.9138 61.4216 32.0655 66.5045C30.2171 71.5875 17.9171 80.3083 23 70.6045C26.7921 63.006 24.7116 57.8785 24.364 49.3934C24.21 44.6185 21.4235 45.5427 21.4235 45.5427C21.4235 45.5427 19.5332 45.9908 18.455 50.1916C17.3898 53.5347 15.9427 56.7438 14.1422 59.7553C11.9375 63.1977 9.14043 66.2222 5.88068 68.6889C1.14781 71.9375 0.419678 68.6889 0.419678 68.6889C2.54585 62.2724 3.99139 55.6502 4.73247 48.9313C5.41316 41.4281 5.3381 33.8754 4.50842 26.3872C5.85715 27.2792 7.34913 27.9329 8.91923 28.3196C10.5171 28.7379 12.1716 28.8986 13.8201 28.7956C19.4212 28.4596 24 26.7233 27.1226 28.1095C30.2428 29.1019 33.5941 29.1019 36.7143 28.1095C37.9806 27.7635 39.2131 27.3043 40.397 26.7373Z" fill="#DEE1E9"/>
        <path d="M24.364 60.6045C24.2154 61.5504 21.7568 63.699 20 67.1045C18.0675 70.7532 14.1422 69.6045 14.1422 69.6045L12.5 59.1045C12.6351 58.0369 20.054 43.8207 21.5 44.1045C22.946 44.3883 30.8817 51.7533 24.364 60.6045Z" fill="#DEE1E9"/>
        <mask id="mask546" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="30">
          <path d="M42.8224 21.719L40.512 26.802C39.3426 27.3762 38.1242 27.8448 36.8713 28.2022C33.7511 29.1947 30.3998 29.1947 27.2796 28.2022C24.157 26.802 19.5222 28.5523 13.9771 28.8883C12.3286 28.9913 10.6742 28.8306 9.07624 28.4123C7.52231 27.9792 6.05412 27.283 4.73545 26.3539C4.73545 26.0598 4.66543 25.8778 4.65143 25.8358C3.64325 22.8813 2.18698 20.0527 2.03295 15.3479C1.94894 12.5474 1.82291 8.50063 4.4974 5.33606C7.40993 1.89143 11.9468 1.38733 13.9071 1.13529C16.0575 0.724133 18.2747 0.829719 20.3763 1.44335C21.2305 1.7514 25.2772 3.09565 26.5234 2.8436C27.1675 2.67557 27.4756 2.46553 27.4756 2.57755C27.4756 2.68957 35.331 -1.11913 39.6578 1.34532C43.9846 3.80977 43.6626 5.67211 44.5867 11.0631C45.008 14.7102 44.3967 18.4024 42.8224 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask546)">
          <path d="M42.8224 21.719L40.512 26.802C39.3426 27.3762 38.1242 27.8448 36.8713 28.2022C33.7511 29.1947 30.3998 29.1947 27.2796 28.2022C24.157 26.802 19.5222 28.5523 13.9771 28.8883C12.3286 28.9913 10.6742 28.8306 9.07624 28.4123C7.52231 27.9792 6.05412 27.283 4.73545 26.3539C4.73545 26.0598 4.66543 25.8778 4.65143 25.8358C3.64325 22.8813 2.18698 20.0527 2.03295 15.3479C1.94894 12.5474 1.82291 8.50063 4.4974 5.33606C7.40993 1.89143 11.9468 1.38733 13.9071 1.13529C16.0575 0.724133 18.2747 0.829719 20.3763 1.44335C21.2305 1.7514 25.2772 3.09565 26.5234 2.8436C27.1675 2.67557 27.4756 2.46553 27.4756 2.57755C27.4756 2.68957 35.331 -1.11913 39.6578 1.34532C43.9846 3.80977 43.6626 5.67211 44.5867 11.0631C45.008 14.7102 44.3967 18.4024 42.8224 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>
        <path opacity="0.4" d="M34.4823 15.7474C35.4346 21.8236 34.804 27.8998 34.6882 32.8063C34.5595 38.1838 35.1643 37.2875 34.907 41.5865C34.6475 45.9381 33.9299 50.2346 32.7708 54.3769C32.3591 55.82 31.8443 57.3238 31.291 59.9062C30.7377 62.4886 27.7188 67.938 27.5 67.938C26.9981 67.938 30.2229 58.6164 30.2229 51.7269C30.5318 47.352 30.3773 45.5056 30.2229 41.5865C30.0942 37.9255 29.528 33.8089 28.9361 33.8089C28.3442 33.8089 28.357 36.2242 27.6493 40.0826C26.6584 45.5664 25.7705 50.5185 23.184 55.1364C21.1637 58.7518 19.9412 58.8885 18.0367 63.157C16.6727 66.3166 16.3346 67.6804 16 67.6045C15.6654 67.5285 15.6432 64.2659 16.544 59.9062C17.4448 55.5465 18.4614 54.5592 19.5294 50.6248C21.1766 44.5486 21.3052 37.3483 19.9541 36.8166C19.0018 36.4673 17.3804 39.3383 15.0641 43.5916C13.2626 46.8424 12.5806 48.6501 10.5989 52.873C8.61716 57.096 3.53249 68.1962 3.0435 67.938C2.5545 67.6797 6.31731 59.7695 7.8615 54.3769C9.26414 49.4855 9.44072 48.7903 10.5989 44.5977C11.4875 41.7349 11.9826 38.2959 12.5162 35.3128C13.0489 32.3486 13.2607 29.3167 13.1468 26.2896C12.9666 22.4616 12.2846 21.0337 12.2975 16.2487C12.2975 13.0587 12.6192 11.8282 13.3655 10.9776C14.1119 10.1269 15.5145 9.85346 17.6121 9.73193C19.5479 9.42277 21.5185 9.67835 23.3513 10.4763C26.3625 11.9953 26.3882 14.6081 28.4986 14.7448C30.609 14.8815 31.7929 12.284 33.1698 12.9979C34.0834 13.4384 34.3408 14.8815 34.4823 15.7474Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M40.397 26.7373C40.2559 35.1966 39.2185 43.6172 37.3024 51.8579C34.2219 64.4602 33.9138 61.4216 32.0655 66.5045C30.2171 71.5875 17.9171 80.3083 23 70.6045C26.7921 63.006 24.7116 57.8785 24.364 49.3934C24.21 44.6185 21.4235 45.5427 21.4235 45.5427C21.4235 45.5427 19.5332 45.9908 18.455 50.1916C17.3898 53.5347 15.9427 56.7438 14.1422 59.7553C11.9375 63.1977 9.14043 66.2222 5.88068 68.6889C1.14781 71.9375 0.419678 68.6889 0.419678 68.6889C2.54585 62.2724 3.99139 55.6502 4.73247 48.9313C5.41316 41.4281 5.3381 33.8754 4.50842 26.3872C5.85715 27.2792 7.34913 27.9329 8.91923 28.3196C10.5171 28.7379 12.1716 28.8986 13.8201 28.7956C19.4212 28.4596 24 26.7233 27.1226 28.1095C30.2428 29.1019 33.5941 29.1019 36.7143 28.1095C37.9806 27.7635 39.2131 27.3043 40.397 26.7373Z" fill="#DEE1E9"/>
        <path d="M24.364 60.6045C24.2154 61.5504 21.7568 63.699 20 67.1045C18.0675 70.7532 14.1422 69.6045 14.1422 69.6045L12.5 59.1045C12.6351 58.0369 20.054 43.8207 21.5 44.1045C22.946 44.3883 30.8817 51.7533 24.364 60.6045Z" fill="#DEE1E9"/>
        <mask id="mask546" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="45" height="30">
          <path d="M42.8224 21.719L40.512 26.802C39.3426 27.3762 38.1242 27.8448 36.8713 28.2022C33.7511 29.1947 30.3998 29.1947 27.2796 28.2022C24.157 26.802 19.5222 28.5523 13.9771 28.8883C12.3286 28.9913 10.6742 28.8306 9.07624 28.4123C7.52231 27.9792 6.05412 27.283 4.73545 26.3539C4.73545 26.0598 4.66543 25.8778 4.65143 25.8358C3.64325 22.8813 2.18698 20.0527 2.03295 15.3479C1.94894 12.5474 1.82291 8.50063 4.4974 5.33606C7.40993 1.89143 11.9468 1.38733 13.9071 1.13529C16.0575 0.724133 18.2747 0.829719 20.3763 1.44335C21.2305 1.7514 25.2772 3.09565 26.5234 2.8436C27.1675 2.67557 27.4756 2.46553 27.4756 2.57755C27.4756 2.68957 35.331 -1.11913 39.6578 1.34532C43.9846 3.80977 43.6626 5.67211 44.5867 11.0631C45.008 14.7102 44.3967 18.4024 42.8224 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask546)">
          <path d="M42.8224 21.719L40.512 26.802C39.3426 27.3762 38.1242 27.8448 36.8713 28.2022C33.7511 29.1947 30.3998 29.1947 27.2796 28.2022C24.157 26.802 19.5222 28.5523 13.9771 28.8883C12.3286 28.9913 10.6742 28.8306 9.07624 28.4123C7.52231 27.9792 6.05412 27.283 4.73545 26.3539C4.73545 26.0598 4.66543 25.8778 4.65143 25.8358C3.64325 22.8813 2.18698 20.0527 2.03295 15.3479C1.94894 12.5474 1.82291 8.50063 4.4974 5.33606C7.40993 1.89143 11.9468 1.38733 13.9071 1.13529C16.0575 0.724133 18.2747 0.829719 20.3763 1.44335C21.2305 1.7514 25.2772 3.09565 26.5234 2.8436C27.1675 2.67557 27.4756 2.46553 27.4756 2.57755C27.4756 2.68957 35.331 -1.11913 39.6578 1.34532C43.9846 3.80977 43.6626 5.67211 44.5867 11.0631C45.008 14.7102 44.3967 18.4024 42.8224 21.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5046" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="39" width="35" height="32">
          <rect width="34.1781" height="30.9131" transform="matrix(-1 0 0 1 36.1781 39.4048)" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5046)">
          <path opacity="0.4" d="M34.4823 15.6566C35.4346 21.7328 34.804 27.809 34.6882 32.7155C34.5595 38.093 35.1643 37.1967 34.907 41.4956C34.6475 45.8473 33.9299 50.1438 32.7708 54.2861C32.3591 55.7292 31.8443 57.233 31.291 59.8154C30.7377 62.3978 27.7188 67.8471 27.5 67.8471C26.9981 67.8471 30.2229 58.5256 30.2229 51.6361C30.5318 47.2612 30.3773 45.4148 30.2229 41.4956C30.0942 37.8347 29.528 33.7181 28.9361 33.7181C28.3442 33.7181 28.357 36.1334 27.6493 39.9918C26.6584 45.4755 25.7705 50.4277 23.184 55.0456C21.1637 58.6609 19.9412 58.7976 18.0367 63.0662C16.6727 66.2258 16.3346 67.5896 16 67.5137C15.6654 67.4377 15.6432 64.1751 16.544 59.8154C17.4448 55.4557 18.4614 54.4683 19.5294 50.534C21.1766 44.4578 21.3052 37.2575 19.9541 36.7258C19.0018 36.3764 17.3804 39.2475 15.0641 43.5008C13.2626 46.7516 12.5806 48.5592 10.5989 52.7822C8.61716 57.0052 3.53249 68.1054 3.0435 67.8471C2.5545 67.5889 6.31731 59.6787 7.8615 54.2861C9.26414 49.3947 9.44072 48.6995 10.5989 44.5069C11.4875 41.6441 11.9826 38.2051 12.5162 35.222C13.0489 32.2578 13.2607 29.2259 13.1468 26.1988C12.9666 22.3708 12.2846 20.9429 12.2975 16.1579C12.2975 12.9679 12.6192 11.7374 13.3655 10.8868C14.1119 10.0361 15.5145 9.76264 17.6121 9.64111C19.5479 9.33195 21.5185 9.58753 23.3513 10.3855C26.3625 11.9045 26.3882 14.5173 28.4986 14.654C30.609 14.7907 31.7929 12.1931 33.1698 12.9071C34.0834 13.3476 34.3408 14.7907 34.4823 15.6566Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>
        <mask id="mask50046" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="3" width="30" height="37">
          <rect width="29.1809" height="35.4105" transform="matrix(-1 0 0 1 37.9486 3.99414)" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask50046)">
          <path opacity="0.2" d="M34.4823 15.6566C35.4346 21.7328 34.804 27.809 34.6882 32.7155C34.5595 38.093 35.1643 37.1967 34.907 41.4956C34.6475 45.8473 33.9299 50.1438 32.7708 54.2861C32.3591 55.7292 31.8443 57.233 31.291 59.8154C30.7377 62.3978 27.7188 67.8471 27.5 67.8471C26.9981 67.8471 30.2229 58.5256 30.2229 51.6361C30.5318 47.2612 30.3773 45.4148 30.2229 41.4956C30.0942 37.8347 29.528 33.7181 28.9361 33.7181C28.3442 33.7181 28.357 36.1334 27.6493 39.9918C26.6584 45.4755 25.7705 50.4277 23.184 55.0456C21.1637 58.6609 19.9412 58.7976 18.0367 63.0662C16.6727 66.2258 16.3346 67.5896 16 67.5137C15.6654 67.4377 15.6432 64.1751 16.544 59.8154C17.4448 55.4557 18.4614 54.4683 19.5294 50.534C21.1766 44.4578 21.3052 37.2575 19.9541 36.7258C19.0018 36.3764 17.3804 39.2475 15.0641 43.5008C13.2626 46.7516 12.5806 48.5592 10.5989 52.7822C8.61716 57.0052 3.53249 68.1054 3.0435 67.8471C2.5545 67.5889 6.31731 59.6787 7.8615 54.2861C9.26414 49.3947 9.44072 48.6995 10.5989 44.5069C11.4875 41.6441 11.9826 38.2051 12.5162 35.222C13.0489 32.2578 13.2607 29.2259 13.1468 26.1988C12.9666 22.3708 12.2846 20.9429 12.2975 16.1579C12.2975 12.9679 12.6192 11.7374 13.3655 10.8868C14.1119 10.0361 15.5145 9.76264 17.6121 9.64111C19.5479 9.33195 21.5185 9.58753 23.3513 10.3855C26.3625 11.9045 26.3882 14.5173 28.4986 14.654C30.609 14.7907 31.7929 12.1931 33.1698 12.9071C34.0834 13.3476 34.3408 14.7907 34.4823 15.6566Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M42.8247 21.3217L40.4582 26.3767C40.3178 34.8361 39.2804 43.2567 37.3637 51.4973C34.2831 64.0996 33.9751 61.061 32.1267 66.1439C30.2784 71.2269 14.0914 83.2691 19.1744 73.5513C22.9647 65.9572 24.7711 57.5272 24.4253 49.0468C24.2713 44.2579 21.4848 45.1961 21.4848 45.1961C21.4848 45.1961 19.6364 45.6442 18.5582 49.8029C17.4931 53.146 16.0459 56.3552 14.2455 59.3667C12.0407 62.809 9.2437 65.8336 5.98395 68.3003C1.25108 71.5489 0.522949 68.3003 0.522949 68.3003C2.66763 61.8954 4.13197 55.2824 4.89175 48.5707C5.57574 41.0489 5.50068 33.4774 4.66771 25.9706C4.66771 25.6765 4.66771 25.5085 4.58369 25.4525C3.57551 22.4979 2.11924 19.6694 1.96521 14.9646C1.8812 12.1641 1.75517 8.11732 4.42966 4.95275C7.3422 1.52212 11.879 1.00403 13.8394 0.751983C15.9897 0.340828 18.207 0.446408 20.3086 1.06004C21.1627 1.36809 25.2095 2.72634 26.4557 2.46029C27.0998 2.29226 27.4079 2.08222 27.4079 2.19425C27.4079 2.30627 35.2633 -1.50243 39.5901 0.962018C43.9169 3.42647 43.5948 5.30282 44.519 10.6798C44.9594 14.3157 44.3725 18.0023 42.8247 21.3217Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M40.5808 27.317C40.4397 35.7763 39.4024 44.1969 37.4863 52.4376C34.4057 65.0399 34.0976 62.0013 32.2493 67.0843C30.401 72.1672 14.214 84.1954 19.2969 74.4916C23.089 66.8931 24.8955 58.4582 24.5479 49.9731C24.3939 45.1982 21.6074 46.1224 21.6074 46.1224C21.6074 46.1224 19.717 46.5705 18.6388 50.7713C17.5737 54.1144 16.1265 57.3235 14.326 60.335C12.1213 63.7774 9.32426 66.802 6.06451 69.2686C1.33165 72.5172 0.603516 69.2686 0.603516 69.2686C2.72969 62.8522 4.17523 56.2299 4.9163 49.511C5.597 42.0078 5.52194 34.4551 4.69226 26.9669C6.04099 27.8589 7.53297 28.5126 9.10307 28.8993C10.701 29.3176 12.3554 29.4783 14.004 29.3754C19.605 29.0393 24.1838 27.303 27.3064 28.6892C30.4266 29.6817 33.7779 29.6817 36.8981 28.6892C38.1644 28.3433 39.3969 27.884 40.5808 27.317Z" fill="#DEE1E9"/>
      <path d="M42.9765 22.167L40.666 27.25C39.4966 27.8242 38.2782 28.2928 37.0254 28.6502C33.9051 29.6427 30.5539 29.6427 27.4336 28.6502C24.311 27.25 19.6762 29.0003 14.1312 29.3363C12.4826 29.4393 10.8282 29.2786 9.23029 28.8603C7.67637 28.4272 6.20817 27.731 4.8895 26.8019C4.8895 26.5078 4.81949 26.3258 4.80548 26.2838C3.7973 23.3293 2.34103 20.5007 2.187 15.7959C2.10299 12.9954 1.97697 8.94863 4.65145 5.78405C7.56399 2.33942 12.1008 1.83533 14.0612 1.58329C16.2115 1.17213 18.4288 1.27772 20.5304 1.89134C21.3845 2.1994 25.4313 3.54365 26.6775 3.2916C27.3216 3.12357 27.6297 2.91353 27.6297 3.02555C27.6297 3.13757 35.4851 -0.671134 39.8119 1.79332C44.1387 4.25777 43.8166 6.12011 44.7408 11.5111C45.1621 15.1582 44.5508 18.8503 42.9765 22.167Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth46",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
