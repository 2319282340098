<template>
  <span v-if="object.status != 2">
  <router-link
      :title="$t('edit')"
      v-b-tooltip.hover
      class="data-table-action"
      :to="{name: 'PatientVisitPage', params: { visitid: object.id, id: object.client_id, page: 'description' } }">
    <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
  </router-link>
    </span>
</template>

<script>

import EditSvg from '@/assets/svg-vue/general/edit.svg'
import {mapState} from "vuex"

export default {
  name: "PatientDatatableRecordActions",
  components: {
    EditSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
