<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M14.8712 4.03117H13.0791V1.10509C13.0791 0.837867 12.8625 0.621338 12.5953 0.621338H3.40467C3.13751 0.621338 2.92092 0.837867 2.92092 1.10509V4.03117H1.12876C0.506396 4.03117 0 4.5375 0 5.15993V11.3665C0 11.9889 0.506396 12.4953 1.12876 12.4953H2.92092V14.8949C2.92092 15.1621 3.13751 15.3787 3.40467 15.3787H12.5953C12.8624 15.3787 13.079 15.1621 13.079 14.8949V12.4953H14.8712C15.4935 12.4953 15.9999 11.9889 15.9999 11.3665V5.15993C16 4.53757 15.4936 4.03117 14.8712 4.03117ZM3.88843 1.58885H12.1115V4.03117H3.88843V1.58885ZM12.1115 14.4112H3.88843V9.61204H12.1115V14.4112ZM15.0325 11.3665C15.0325 11.4539 14.9586 11.5278 14.8712 11.5278H13.0791V9.12828C13.0791 8.86105 12.8625 8.64453 12.5953 8.64453H3.40467C3.13751 8.64453 2.92092 8.86105 2.92092 9.12828V11.5278H1.12876C1.04137 11.5278 0.967512 11.4539 0.967512 11.3665V5.15993C0.967512 5.07247 1.04137 4.99868 1.12876 4.99868H3.40467H12.5953H14.8712C14.9586 4.99868 15.0324 5.07247 15.0324 5.15993V11.3665H15.0325Z"
      fill="#87939E"/>
  <path
      d="M12.5943 7.50973C12.9744 7.50973 13.2824 7.20166 13.2824 6.82164C13.2824 6.44162 12.9744 6.13354 12.5943 6.13354C12.2143 6.13354 11.9062 6.44162 11.9062 6.82164C11.9062 7.20166 12.2143 7.50973 12.5943 7.50973Z"
      fill="#87939E"/>
  <path
      d="M10.201 10.5667H5.79919C5.53202 10.5667 5.31543 10.7832 5.31543 11.0504C5.31543 11.3176 5.53202 11.5342 5.79919 11.5342H10.201C10.4681 11.5342 10.6847 11.3176 10.6847 11.0504C10.6847 10.7832 10.4682 10.5667 10.201 10.5667Z"
      fill="#87939E"/>
  <path
      d="M10.201 12.4888H5.79919C5.53202 12.4888 5.31543 12.7053 5.31543 12.9725C5.31543 13.2398 5.53202 13.4563 5.79919 13.4563H10.201C10.4681 13.4563 10.6847 13.2398 10.6847 12.9725C10.6847 12.7053 10.4682 12.4888 10.201 12.4888Z"
      fill="#87939E"/>
</svg>
</template>
