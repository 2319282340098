export default {
    primaryKey: 'id',
    model: 'record',
    order: 'start',
    sort: 'desc',
    actionscomponent: 'StaffDatatableRecordActions',
    actions: [
        // "add",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'start',
            title: 'visit',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            tdcomponent: "PatientsDatatableTDStart"
        },
        {
            id: 'start',
            title: 'date',
            filter: true,
            filter_date: true
        },
        {
            id: 'record_type',
            title: 'type',
            table: true,
            sortable: true,
            format_translate: true,
        },
        {
            id: 'client_id',
            title: 'patient',
            table: true,
            sortable: true,
            filter: true,
            tdcomponent: 'PatientsDatatableTDName',
            table_show: 'patient.full_name',
        },
        {
            id: 'records_statuses_id',
            title: 'status',
            table: true,
            table_show: 'readableStatus',
            sortable: true,
            format_translate: true,
            tdcomponent: "PatientsDatatableTDRecordStatus"
        },
        {
            id: 'services',
            title: 'services',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDServices"
        },
        {
            id: 'scans',
            title: 'scan',
            table: false,
            sortable: false,
            tdcomponent: "PatientsDatatableTDScan"
        },
        {
            id: 'teeth',
            title: 'teeth',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDTeeth"
        },
        {
            id: 'f043',
            title: 'f043',
            table: false,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: "PatientsDatatableTDF043"
        },
        {
            id: 'price',
            title: 'price',
            table: true,
            sortable: false,
            format_currency: true,
        },
    ]
}
