<template>
  <b-modal id="doctor-schedule-modal"
           :title="$t('schedule')"
           @show="loadConfigs()"
           @hide="onHide"
           size="xl"
           centered>
    <div class="mb-3" v-if="loaded">
      <a class="d-inline-block btn-themed btn-themed-outline btn-themed-squared" @click="addConfig">{{ $t('add') }}</a>
    </div>
    <div v-for="(schedule, sind) in doctorScheduleSettings"
         :key="`setting-${sind}`"
         :class="{ 'mt-4': sind > 0 && !shortDayVersion(sind) }">
      <h6 class="position-relative" v-if="!shortDayVersion(sind)">
        {{ $t('config') }} <span class="text-lowercase">{{ schedule.config_type === 'by_days' ? $t('by_days') : (doctorScheduleSettings.length - sind) }}</span>
        <a @click="deleteConfig(sind)"
           class="d-inline-block pointer position-absolute delete-config"
           v-if="schedule.config_type !== 'by_days'">
          <TrashSvg class="svg-red"/>
        </a>
      </h6>
      <b-row v-if="mainByDayDay(sind)">
        <b-col lg="3" md="4" cols="12">
          <SelectGroup :options="types"
                       variant="white"
                       :label="$t('type')"
                       :nullOption="false"
                       :showlabel="true"
                       @input="typeChanged(sind)"
                       v-model="schedule.config_type"/>
        </b-col>
        <b-col lg="3" md="4" cols="6">
          <DateGroup :required="true"
                     :close-on-click="false"
                     :attrs="byDaysDays"
                     :valueText="byDaysDays[0].formatted.join(', ')"
                     :multiple="true"
                     @dayClicked="byDayClicked"
                     :label="$t('work_days')"/>
        </b-col>
      </b-row>
      <b-row class="align-items-center">
        <b-col lg="3" md="4" cols="12" v-if="chairs.length && doctor.role_id == 4">
          <ServiceMultiSelect :label="schedule.config_type === 'by_days' ? '' : $t('chair')"
                              :dblclick="false"
                              model="chair"
                              placeholder="chair"
                              :required="chairs.length > 0"
                              :multiple="false"
                              :prepopulated-options="chairs"
                              :internalSearch="true"
                              title="chair_name"
                              :dbsearch="false"
                              @input="schedule.chair_id = $event ? $event.id : null"
                              v-model="schedule.chair"/>
        </b-col>
        <b-col lg="3" md="4" cols="12" v-if="schedule.config_type !== 'by_days'">
          <SelectGroup :options="types"
                       variant="white"
                       :label="$t('type')"
                       :nullOption="false"
                       :showlabel="true"
                       @input="typeChanged(sind)"
                       v-model="schedule.config_type"/>
        </b-col>
        <b-col lg="3" md="4" cols="6">
          <DateGroup :required="true"
                     :label="schedule.config_type == 'by_days' ? '' : $t('period_from')"
                     v-model="schedule.active_from"/>
        </b-col>
        <b-col md="3" cols="6" v-if="schedule.config_type != 'by_days'">
          <DateGroup :required="true"
                     class="right-popup"
                     :label="$t('period_to')"
                     v-model="schedule.active_to"/>
        </b-col>
        <b-col lg="3" md="4" cols="6" v-if="schedule.config_type === 'by_days'">
          <div class="form-group">
            <b-row class="row-narrow">
              <b-col cols="auto">
                <the-mask mask="##:##"
                          class="form-control time-slot"
                          v-model="doctorScheduleSettings[sind].config.f"
                          :masked="true"/>
              </b-col>
              <b-col cols="auto">
                <the-mask mask="##:##"
                          class="form-control time-slot"
                          v-model="doctorScheduleSettings[sind].config.t"
                          :masked="true"/>
              </b-col>
            </b-row>
          </div>
        </b-col>
        <b-col cols="auto" class="position-relative" v-if="schedule.config_type === 'by_days'">
          <div class="delete-config-day form-group">
            <a @click="copyConfig(sind)"  class="d-inline-block pointer mr-3">
              <PlusSvg/>
            </a>
            <a @click="applyConfigTimeToDays(sind)"  class="d-inline-block pointer mr-3">
              <CopySvg/>
            </a>
            <a @click="deleteConfig(sind)" class="d-inline-block pointer">
              <TrashSvg class="svg-red"/>
            </a>
          </div>
        </b-col>
      </b-row>

      <div v-if="schedule.config_type === 'days_of_week'">
        <label class="form-label">{{ $t('work_days') }}</label>
        <div class="bg-gray p-3">
          <div v-for="(c, cind) in schedule.config"
               :key="`sch-${sind}-${cind}`">
            <b-row class="align-items-center mb-2 row-narrow" :class="{ 'inactive': c.a == 0 }">
              <b-col cols="6" md="4">
                <CheckboxGroup :label="weekdays[cind]"
                               class="mb-0"
                               :checkboxvalue="1"
                               :fid="`sch-chbx-${sind}-${cind}`"
                               v-model="c.a"/>
              </b-col>
              <b-col cols="auto">
                <the-mask mask="##:##"
                          class="form-control time-slot"
                          v-model="c.f"
                          :masked="true"/>
              </b-col>
              <b-col cols="auto">
                <the-mask mask="##:##"
                          class="form-control time-slot"
                          v-model="c.t"
                          :masked="true"/>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>

      <div v-if="schedule.config_type === 'even_days' || schedule.config_type === 'odd_days'">
        <label class="form-label">{{ $t('work_time') }}</label>
        <b-row>
          <b-col cols="auto">
            <the-mask mask="##:##"
                      class="form-control time-slot"
                      v-model="schedule.config.f"
                      :masked="true"/>
          </b-col>
          <b-col cols="auto">
            <the-mask mask="##:##"
                      class="form-control time-slot"
                      v-model="schedule.config.t"
                      :masked="true"/>
          </b-col>
        </b-row>
        <label class="form-label mt-3">{{ $t('weekend') }}</label>
        <div class="bg-gray p-3">
          <div v-for="(w, wind) in weekdaysShort" :key="`weekend-${sind}-${wind}`" class="d-inline-block mr-4">
            <CheckboxGroup :label="w"
                           class="mb-md-0 mb-2"
                           :checkboxvalue="wind.toString()"
                           :fid="`weekend-chbx-${sind}-${wind}`"
                           v-model="schedule.config.h"/>
          </div>
        </div>
      </div>

      <hr v-if="sind < (doctorScheduleSettings.length - 1) && !shortDayVersion((sind + 1))"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button @click="save"
                class="w-auto px-3 btn-themed align-middle btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import SelectGroup from "@/components/form/SelectGroup"
import DateGroup from "@/components/form/DateGroup"
import { EloquentService } from "@/services/api.service"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import moment from "moment"
import { TheMask } from 'vue-the-mask'
import TrashSvg from '@/assets/svg-vue/general/trash_tr.svg'
import PlusSvg from '@/assets/svg-vue/patients/plus_tag.svg'
import CopySvg from '@/assets/svg-vue/patients/copy.svg'

export default {
  name: "DoctorScheduleModal",
  components: {
    SelectGroup,
    DateGroup,
    CheckboxGroup,
    ServiceMultiSelect,
    // InputGroup,
    TheMask,
    TrashSvg,
    PlusSvg,
    CopySvg
  },
  computed: {
    ...mapState({
      clinic:     state => state.auth.clinic,
      doctor:     state => state.doctor.doctor,
      day:        state => state.doctor.doctorScheduleDay,
      branch_id:  state => state.auth.branch,
      chairs:     state => state.auth.branchChairs,
    }),
    weekdays() {
      moment.locale(this.$i18n.locale)
      return moment.weekdays(true)
    },
    weekdaysShort() {
      moment.locale(this.$i18n.locale)
      return moment.weekdaysShort(true)
    },
    clinicTimeStart() {
      return this.clinic && this.clinic.time_start ? this.clinic.time_start.substr(0, 5) : '10:00'
    },
    clinicTimeEnd() {
      return this.clinic && this.clinic.time_end ? this.clinic.time_end.substr(0, 5) : '19:00'
    },
    byDaysSettings() {
      return this.doctorScheduleSettings.filter(setting => setting.config_type === 'by_days')
    },
    byDaysDays() {
      const dates = this.byDaysSettings.map(setting => {
        return {
          day: setting.active_from,
          formatted: moment(setting.active_from).format('DD.MM.YY')
        }
      })
      return [{ highlight: true, dates: dates.map(d => d.day), formatted: dates.map(d => d.formatted) }]
    },
    defaultChairId() {
      for(const setting of this.doctorScheduleSettings) {
        if(setting.chair_id) return setting.chair_id
      }
      return null
    }
  },
  data() {
    return {
      types: [
        { id: 'days_of_week', title: 'by_days_of_week' },
        { id: 'even_days', title: 'by_even_days' },
        { id: 'odd_days', title: 'by_odd_days' },
        { id: 'by_days', title: 'by_days' },
      ],
      initialConfigs: {},
      doctorScheduleSettings: [],
      loaded: false,
      justNull: null
    }
  },
  methods: {
    save() {
      if(!this.doctor) return
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        doctor_id: this.doctor.id,
        branch_id: this.branch_id,
        configs: this.doctorScheduleSettings
      })
      EloquentService.create('schedule_configuration', formData).then((res) => {
        this.$noty.info(this.$t('success_message'))
        this.$bvModal.hide('doctor-schedule-modal')
        this.$store.commit('incrementDataTableCounter')
        if(res.data.conflicts && res.data.conflicts.length > 0) {
          this.$noty.warning(this.$t('schedule_conflicts_found') + ': '+res.data.conflicts.length)
        }
      })
    },
    initInitialConfigs() {
      this.initialConfigs = {
        'days_of_week': [
          { f: this.clinicTimeStart, t: this.clinicTimeEnd, a: 1 },
          { f: this.clinicTimeStart, t: this.clinicTimeEnd, a: 1 },
          { f: this.clinicTimeStart, t: this.clinicTimeEnd, a: 1 },
          { f: this.clinicTimeStart, t: this.clinicTimeEnd, a: 1 },
          { f: this.clinicTimeStart, t: this.clinicTimeEnd, a: 1 },
          { f: this.clinicTimeStart, t: this.clinicTimeEnd, a: 1 },
          { f: this.clinicTimeStart, t: this.clinicTimeEnd, a: 1 },
        ],
        'even_days': { f: this.clinicTimeStart, t: this.clinicTimeEnd, h: [] },
        'odd_days': { f: this.clinicTimeStart, t: this.clinicTimeEnd, h: [] },
        'by_days': { f: this.clinicTimeStart, t: this.clinicTimeEnd },
      }
    },
    loadConfigs() {
      if(!this.doctor) return
      this.initInitialConfigs()
      EloquentService.dataTable('schedule_configuration',1, {
        pagination: 999,
        sort: 'desc',
        order: 'id',
        condition: { doctor_id: this.doctor.id, branch_id: this.branch_id }
      }).then(res => {
        this.loaded = true
        this.doctorScheduleSettings = res.data.result.data.map(x => x)
        this.doctorScheduleSettings.sort((a, b) => {
          if(a.config_type === b.config_type && b.config_type === 'by_days')
            return (a.active_from > b.active_from) ? -1 : ((b.active_from > a.active_from) ? 1 : 0)
          return (a.config_type > b.config_type) ? 1 : ((b.config_type > a.config_type) ? -1 : 0)
        })
      })
    },
    addConfig() {
      this.doctorScheduleSettings.unshift({
        active_from: moment().format('YYYY-MM-DD'),
        active_to: moment().format('YYYY-MM-DD'),
        doctor_id: this.doctor.id,
        branch_id: this.branch_id,
        config_type: 'days_of_week',
        config: this.copyObject(this.initialConfigs['days_of_week'])
      })
    },
    deleteConfig(index) {
      this.doctorScheduleSettings.splice(index, 1)
    },
    applyConfigTimeToDays(index) {
      const setting = this.doctorScheduleSettings[index]
      this.doctorScheduleSettings.forEach(set => {
        if(set.config_type === 'by_days') {
          set.config.f = setting.config.f
          set.config.t = setting.config.t
        }
      })
    },
    copyConfig(index) {
      const setting = this.doctorScheduleSettings[index]
      this.doctorScheduleSettings.splice(index, 0,{
        active_from: moment(setting.active_from).add(1, 'day').format('YYYY-MM-DD'),
        active_to: null,
        doctor_id: this.doctor.id,
        chair_id: setting.chair_id,
        chair: setting.chair,
        branch_id: this.branch_id,
        config_type: 'by_days',
        config: this.copyObject(setting.config)
      })
    },
    async typeChanged(index) {
      await this.$nextTick()
      this.doctorScheduleSettings[index].config = this.copyObject(this.initialConfigs[this.doctorScheduleSettings[index].config_type])
    },
    onHide() {
      this.doctorScheduleSettings = []
      this.$store.commit('setDoctorScheduleDay', null)
      this.loaded = false
    },
    shortDayVersion(index) {
      if (index <= 0) return false
      const setting = this.doctorScheduleSettings[index]
      return setting.config_type === 'by_days' && this.doctorScheduleSettings[(index - 1)].config_type === 'by_days'
    },
    mainByDayDay(index) {
      const setting = this.doctorScheduleSettings[index]
      if(setting.config_type === 'by_days') {
        if(index <= 0) return true
        return this.doctorScheduleSettings[(index - 1)].config_type !== 'by_days'
      }
      return false
    },
    byDayClicked(day) {
      let found = false
      for(let i = 0; i < this.doctorScheduleSettings.length; i++ ) {
        if(this.doctorScheduleSettings[i].config_type === 'by_days'
            && this.doctorScheduleSettings[i].active_from === day)
        {
          this.doctorScheduleSettings.splice(i, 1)
          found = true
        }
      }
      if(!found) {
        this.doctorScheduleSettings.unshift({
          active_from: day,
          active_to: null,
          doctor_id: this.doctor.id,
          chair_id: this.defaultChairId,
          chair: this.chairs.find(c => c.id === this.defaultChairId),
          branch_id: this.branch_id,
          config_type: 'by_days',
          config: this.copyObject(this.initialConfigs['by_days'])
        })
      }
    }
  },
}
</script>

<style scoped lang="scss">
.inactive {
  opacity: 0.3;
}
.delete-config {
  right: 15px;
  top: 0;
}
.delete-config-day {
  //right: 15px;
  bottom: 30px;
}
.time-slot {
  max-width: 100px;
}
@media screen and (max-width: 768px) {
  .time-slot {
    max-width: 60px;
  }
}
</style>
