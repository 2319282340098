<template>
  <div>
    <DataTableFilter :options="options"
                     ref="dataTableFilter"
                     class="mt-3"/>

    <div class="row align-items-center">
      <div class="col col-md-3 col-xl-2">
        <InputGroup v-model="nameSearch"
                    class="mb-0"
                    :placeholder="$t('search_by_name')"/>
      </div>
      <div class="col col-md-3 col-xl-2">
        <InputGroup v-model="phoneSearch"
                    class="mb-0"
                    :placeholder="$t('search_by_phone')"/>
      </div>
      <div class="col-auto">
        <CheckboxGroup fid="tasksToday"
                       v-model="withTasksForToday"
                       class="mb-0"
                       :label="$t('has_today_task')"
                       :placeholder="$t('search_by_phone')"/>
      </div>
      <div class="col-auto">
        <CheckboxGroup fid="noTasks"
                       :label="$t('without_tasks')"
                       class="mb-0"
                       v-model="withoutTasks"
                       :placeholder="$t('search_by_phone')"/>
      </div>
    </div>

    <div style="overflow-x: auto; overflow-y: hidden"
         class="pt-3">

      <b-row class="flex-nowrap"
             no-gutters>
        <b-col v-for="(p, pind) in patientCanbanStatuses"
               :key="`funnel-${pind}`">
          <div class="patient-canban-wrap h-100"
               :class="{ 'ml-3': pind > 0 }">
            <div class="nowrap">
              {{ $t(p.title) }} |
              <span :class="{ 'text-danger': !!patients[p.id] }">
<!--                {{ patients[p.id] ? patients[p.id].length : 0 }} <span class="text-muted">/ {{ totals[p.id] ? totals[p.id] : 0 }}</span>-->
                {{ patients[p.id] ? patients[p.id].length : 0 }}
            </span>
              <div class="separator w-100 mt-2 mb-3"
                   :style="`background-color: ${p.color}`"/>
            </div>

            <div v-if="initiated && !patients[p.id].length">
              <ScrollToEventElement @visible="loadMore(p.id)">&nbsp;</ScrollToEventElement>
            </div>

            <draggable class="list-group h-100"
                       :list="patients[p.id]"
                       group="canbans"
                       :data-status="p.id"
                       item-key="id"
                       :move="itemMoved">
              <template #item="{ element, index }">
                <PatientCanbanTeaser :element="element"
                                     @click.native="showEditModal(element)">
                  <div v-if="totals[p.id] && totals[p.id] > patients[p.id].length && initiated && (index + 1) === patients[p.id].length">
                    <ScrollToEventElement @visible="loadMore(p.id)">&nbsp;</ScrollToEventElement>
                  </div>
                </PatientCanbanTeaser>
              </template>
            </draggable>

          </div>
        </b-col>
      </b-row>

      <PatientCanbanModal/>

    </div>
  </div>
</template>

<script setup>
import draggable from 'vuedraggable'
import options from "@/data/crm/patient_canban"
import DataTableFilter from "@/components/parts/datatable/DataTableFilter"
import { useStore } from "vuex"
import { computed, getCurrentInstance, onBeforeMount, onBeforeUnmount, onMounted, ref, watch } from "vue"
import { useHead } from "@unhead/vue"
import { copyObject } from "@/extensions/prototypes/prototypes"
import { CRMService } from "@/services/api.service"
import { useRoute } from "vue-router"
import PatientCanbanModal from "@/components/parts/crm/PatientCanbanModal.vue"
import PatientCanbanTeaser from "@/components/parts/crm/PatientCanbanTeaser.vue"
import InputGroup from "@/components/form/InputGroup.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import ScrollToEventElement from "@/components/parts/general/ScrollToEventElement.vue"

const store = useStore()
const route = useRoute()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('funnel_came_and_repeated')
  draggable.compatConfig = { MODE: 3 }
})

const patients = ref({})
const totals = ref({})
const initiated = ref(false)
const nameSearch = ref('')
const phoneSearch = ref('')
const withTasksForToday = ref(false)
const withoutTasks = ref(false)
const timer = ref(null)
const dataTableFilter = ref(null)

const filters = computed(() => store.state.datatable.filters)
const counter = computed(() => store.state.datatable.dataTableCounter)
const patientCanbanStatuses = computed(() => store.state.auth.patientCanbanStatuses)

onMounted(async () => {
  store.commit("setViewModel", 'patient_canban')
  store.commit('setEloquentOptions', options)
  store.commit('setFilters', {})
  await store.dispatch('getPatientCanbanStatuses')
  await loadData()
})

onBeforeUnmount(() => {
  store.commit('setFilters', {})
})

const instance = (getCurrentInstance())

const initialState = () => {
  patients.value = {}
  patientCanbanStatuses.value.forEach(status => {
    patients.value[status.id] = []
  })
}

const showEditModal = (item) => {
  store.commit('setPatientCanban', copyObject(item))
  const bvModal = instance?.ctx?._bv__modal
  bvModal?.show('patient-canban-modal')
}

const loadData = async (initial = true, statusId = null, lastId = null) => {
  try {
    if(initial) {
      initiated.value = false
      initialState()
    }
    let filtersTmp = copyObject(filters.value)
    if(statusId) filtersTmp.status_id = {
      id: 'status_id',
      condition: statusId,
      type: 'equals'
    }
    const data = await CRMService.getPatientCanban(filtersTmp, lastId)
    data.data.data.forEach(item => {
      if(!patients.value[item.status_id]) {
        patients.value[item.status_id] = []
      }
      patients.value[item.status_id].push(item)
    })
    if(initial) totals.value = data.data.totals
  } catch (e) {
    console.log(e)
  } finally {
    initiated.value = true
  }
}

const itemMoved = (e) => {
  const itemId = String(e.dragged.getAttribute('data-id'))
  // const patient_id = String(e.dragged.getAttribute('data-patient-id'))
  const status_id = e.to.getAttribute('data-status')
  CRMService.canbanStatusMoved(itemId, { status_id })
  // const formData = appendFormdata(new FormData(), { status_id, patient_id })
  // EloquentService.update('patient_canban', itemId, formData, false, false)
}

const loadMore = (statusId) => {
  const patientsWithStatus = patients.value[statusId] || []
  const lastId = patientsWithStatus.length > 0 ? patientsWithStatus[patientsWithStatus.length - 1] : null
  loadData(false, statusId, lastId?.id)
}

watch(counter, () => {
  loadData()
})

watch(route, () => {
  loadData()
})

watch(nameSearch, (val) => {
  if(timer.value) clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    if(val) {
      dataTableFilter.value.filters.patient_id = {
        "id": "patient_id",
        "title": "patient",
        "condition": val,
        "type": "text",
        "ctype": "like"
      }
    } else {
      delete dataTableFilter.value.filters.patient_id
    }
    dataTableFilter.value.refresh()
  }, 600)
})

watch(phoneSearch, (val) => {
  if(timer.value) clearTimeout(timer.value)
  timer.value = setTimeout(() => {
    if(val) {
      dataTableFilter.value.filters.phone = {
        "id": "phone",
        "title": "phone",
        "condition": val,
        "type": "text",
        "ctype": "like"
      }
    } else {
      delete dataTableFilter.value.filters.phone
    }
    dataTableFilter.value.refresh()
  }, 600)
})

watch(withoutTasks, (val) => {
  if(val) {
    dataTableFilter.value.filters.has_tasks = {
      "id": "has_tasks",
      "title": "has_tasks",
      "condition": 0,
      "type": "boolean",
      "ctype": "equals"
    }
  } else {
    delete dataTableFilter.value.filters.has_tasks
  }
  dataTableFilter.value.refresh()
})

watch(withTasksForToday, (val) => {
  if(val) {
    dataTableFilter.value.filters.has_today_tasks = {
      "id": "has_today_tasks",
      "title": "has_today_tasks",
      "condition": 1,
      "type": "boolean",
      "ctype": "equals"
    }
  } else {
    delete dataTableFilter.value.filters.has_today_tasks
  }
  dataTableFilter.value.refresh()
})
</script>

<style scoped lang="scss">
.separator {
  height: 2px;
}
.patient-canban-wrap {
  min-width: 220px;
}
.patient-canban-card {
  border-radius: 8px;
  &:hover {
    background-color: $light-blue;
  }
}
@media screen and (max-width: 768px) {
  .plan-wrap {
    min-width: 150px;
  }
  .circle {
    width: 10px;
    height: 10px;
  }
}
</style>
