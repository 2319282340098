<template>
  <div class="form-group">
    <label class="form-label" :data-content="$t(field.title)">
      <span class="">{{ $t(field.title) }}</span>
      <span v-if="required"> *</span>
    </label>
    <input type="text"
           class="form-control"
           @input="handleInput"
           readonly
           @keyup.enter="$emit('enter')"
           :value="value"/>
  </div>
</template>

<script>

import moment from "moment"

export default {
  name: "WarehouseDatatableFormTitle",
  props: {
    object: {
      type: Object
    },
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
    checkOp() {
      if(this.object.id) { return }
      let str = this.$t(this.type ? this.type : 'operation')
      str += ' #' + this.nextId
      str += ' ' + this.$t('from')
      str += ' '+moment().format('DD.MM.YYYY')
      this.$emit('input', str)
    }
  },
  mounted() {
    this.checkOp()
  },
  computed: {
    type() {
      return this.object.operation_type
    },
    nextId() {
      return this.selectModels.last_id + 1
    }
  },
  watch: {
    type() {
      console.log('type changed')
      this.checkOp()
    }
  }
}
</script>

<style scoped>

</style>
