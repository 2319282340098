<template>
  <div v-if="object.tags">
    <div v-for="(t, tind) in object.tags" :key="`t-${tind}`">{{ t.title }}</div>
  </div>
</template>

<script>

export default {
  name: "PatientsDatatableTDTags",
  props: {
    object: {
      type: Object
    }
  },
}
</script>