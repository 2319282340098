<template>
  <a class="btn btn-plain position-relative d-none d-sm-flex"
     v-if="jetAPIPanelEnabled"
     @click="toggleJetAPIChat">
    <WazzupSvg class="svg-wazzup svg-light"/>
  </a>
</template>

<script setup>
import WazzupSvg from '@/assets/svg-vue/header/chat.svg'
import { computed } from "vue"
import { useStore } from "vuex"

const store = useStore()

const toggleJetAPIChat = () => {
  store.state.dom.rightPaneOpened = !store.state.dom.rightPaneOpened

}

const jetAPIPanelEnabled = computed(() => store.getters.jetAPIPanelEnabled)
</script>
