export default {
    primaryKey: "id",
    model: 'deposit_account',
    order: 'id',
    sort: 'desc',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    deleteCondition(object) {
        return !object.is_default
    },
    editCondition(object) {
        return !object.is_default
    },
    deletemessage: 'confirm_deletion',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
            tdcomponent: 'SettingsDatatableTDTagTitle',
        },
        {
            id: 'color',
            title: 'color',
            table: false,
            // sortable: true,
            form: {
                type: "color",
                required: true
            }
        },
    ]
}
