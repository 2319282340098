<template>
  <div>
    <div v-for="(n, nind) in visit.record_notifications" :key="`notification-${nind}`">
      <div class="notification">
        <b-row class="row-narrow align-items-center mb-3">
          <b-col md="auto" col>
            <b-row class="row-narrow align-items-center">
              <b-col cols="auto">
                {{ $t('for') }}
              </b-col>
              <b-col cols="auto">
                <InputGroup class="sm mb-0" type="number" v-model="n.period" :label="false"/>
              </b-col>
              <b-col md="auto" col>
                <SelectGroup :options="periodTypes"
                             class="sm mb-0"
                             style="width: 90px;"
                             variant="white"
                             :showlabel="false"
                             v-model="n.period_type"/>
              </b-col>
              <b-col cols="auto">
                <b-row class="row-narrow align-items-center mt-md-0 mt-2">
                  <b-col cols="auto">
                    <CheckboxGroup label="SMS"
                                   :fid="`sms-${nind}`"
                                   class="sm mb-0 mt-1 ml-md-3"
                                   v-model="n.sms"/>
                  </b-col>
                  <b-col cols="auto">
                    <CheckboxGroup label="E-mail"
                                   :fid="`email-${nind}`"
                                   class="sm mb-0 mt-1 ml-md-3"
                                   v-model="n.email"/>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="auto" cols="auto">
            <div class="mb-1 ml-md-3">
              <a @click="removeNotification(nind)" class="pointer">
                <TrashSvg/>
              </a>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="mt-3">
      <a class="btn btn-themed btn-themed-outline btn-themed-squared" @click="addNotification">
        {{ $t('create_notification') }}
      </a>
    </div>
  </div>
</template>

<script>

import TrashSvg from '@/assets/svg-vue/general/trash.svg'
import CheckboxGroup from "@/components/form/CheckboxGroup"
import InputGroup from "@/components/form/InputGroup"
import SelectGroup from "@/components/form/SelectGroup"

export default {
  name: "VisitModalNotificationsPart",
  components: {
    SelectGroup,
    InputGroup,
    CheckboxGroup,
    TrashSvg
  },
  props: {
    visit: {}
  },
  data() {
    return {
      periodTypes: [
        { id: 'day', title: 'day_short' },
        { id: 'hour', title: 'hour_short' },
        { id: 'minute', title: 'min_short' },
      ]
    }
  },
  methods: {
    addNotification() {
      this.visit.record_notifications.push({
        importance: 'low',
        title: '',
        sms: false,
        email: false,
        period: 1,
        period_type: 'day'
      })
    },
    removeNotification(index) {
      this.visit.record_notifications.splice(index, 1)
    }
  },
}
</script>

<style scoped lang="scss">
@media screen and (max-width: 768px) {
  .notification {
    border-bottom: 1px solid $calendar-border-color;
    margin-bottom: 25px;
  }
}
</style>
