import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import WarehouseGeneral from "@/components/pages/warehouse/WarehouseGeneral"
import Warehouses from "@/components/pages/warehouse/Warehouses"
import Groups from "@/components/pages/warehouse/Groups"
import Kits from "@/components/pages/warehouse/Kits"
import Suppliers from "@/components/pages/warehouse/Suppliers"
import Stocks from "@/components/pages/warehouse/Stocks"
import StocksArchive from "@/components/pages/warehouse/StocksArchive"
import Documents from "@/components/pages/warehouse/Documents"
import DocumentItem from "@/components/pages/warehouse/DocumentItem"

export default {
    path: '/warehouses',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
    // component: () => import('../components/pages/warehouse/WarehouseGeneral.vue'),
    component: WarehouseGeneral,
    children: [
        { path: '', redirect: '/warehouses/stocks' },
        {
            path: 'warehouses', name: "WarehousesWarehouses",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            // component: () => import('../components/pages/warehouse/Warehouses.vue')
            component: Warehouses
        },
        {
            path: 'groups', name: "WarehousesGroups",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            // component: () => import('../components/pages/warehouse/Groups.vue')
            component: Groups
        },
        {
            path: 'kits', name: "WarehousesKits",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            // component: () => import('../components/pages/warehouse/Kits.vue')
            component: Kits
        },
        {
            path: 'suppliers', name: "WarehousesSuppliers",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            // component: () => import('../components/pages/warehouse/Suppliers.vue')
            component: Suppliers
        },
        {
            path: 'stocks', name: "WarehousesStocks",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            // component: () => import('../components/pages/warehouse/Stocks.vue')
            component: Stocks
        },
        {
            path: 'stocks_archive', name: "WarehousesStocksArchive",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            component: StocksArchive
        },
        {
            path: 'documents', name: "WarehousesDocuments",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            // component: () => import('../components/pages/warehouse/Documents.vue')
            component: Documents
        },
        {
            path: 'documents/:id', name: "WarehousesDocumentItem",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['materials'] },
            // component: () => import('../components/pages/warehouse/DocumentItem.vue')
            component: DocumentItem
        },
    ]
}
