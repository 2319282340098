export const accountingMonthAdapter =
    ({
         rent,
         communal,
         clinic_id,
         date,
     }) => ({
        rent: rent ? rent : null,
        communal: communal ? communal : null,
        clinic_id: clinic_id ? clinic_id : null,
        date: date ? date : null,
    })

