<template>
  <div>
    <router-link :to="{
      name: ['superadmin', 'supermanager', 'tech_specialist'].includes(role) ? 'SuperAdminClinicGeneral' : 'SuperAdminManagerClinic',
      params: { id: object.id }
    }">
      {{ object.name }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "SuperAdminDatatableTDClinicTitle",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    },
  }
}
</script>

<style scoped>
  div {
    word-break: break-word;
  }
</style>
