<template>
  <section id="service" class="service" v-if="loaded && clinic">
    <div class="section-top-row" >
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col order-md-0 order-0">
          <h1>{{ clinic.name }}</h1>
        </div>
      </div>
    </div>
    <SuperAdminClinicTariffs v-if="loaded"/>
  </section>
</template>

<script>

import { mapState } from "vuex"
import SuperAdminClinicTariffs from "@/components/pages/superadmin/clinics/SuperAdminClinicTariffs"

export default {
  name: "SuperAdminManagerClinic",
  components: {
    SuperAdminClinicTariffs,
  },
  data() {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
  },
  async mounted() {
    let clinic = await this.$store.dispatch('getSuperAdminClinic', this.$route.params.id)
    this.$store.commit('setClinic', {clinic: clinic})
    if(clinic.currency) {
      window.localStorage.setItem('currency', clinic.currency.name)
    }
    await this.$nextTick()
    this.loaded = true
  },
  unmounted() {
    this.$store.commit('setClinic', {clinic: null})
    window.localStorage.removeItem('currency')
  },
}
</script>
