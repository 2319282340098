<template>
  <span v-if="rights.includes('publish_feedbacks')">
  <a class="data-table-action"
     :title="object.active ? $t('unpublish') : $t('publish')"
     v-b-tooltip.hover
     @click="toggleStatus">
    <BlockSvg v-if="object.active"/>
    <TickSvg v-if="!object.active" class="svg-light" style="position: relative; top: 2px"/>
    <span class="action-button-mob-title">{{ object.active ? $t('unpublish') : $t('publish') }}</span>
  </a>
    </span>
</template>

<script>

import TickSvg from '@/assets/svg-vue/form/tick.svg'
import BlockSvg from '@/assets/svg-vue/general/block.svg'
import { mapState } from "vuex"
import { EloquentService } from "@/services/api.service"

export default {
  name: "CRMFeedbackDatatableActions",
  components: {
    TickSvg,
    BlockSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    async toggleStatus() {
      if(this.loading) return
      this.loading = true
      let formData = new FormData()
      formData.append('active', this.object.active ? "0" : "1")
      await EloquentService.update('feedback', this.object.id, formData)
      this.$store.commit('incrementDataTableCounter')
      this.loading = false
    },
  }
}
</script>

<style scoped>
.data-table-action svg {
  width: 16px;
  height: 16px;
}
</style>
