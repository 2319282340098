export default {
    primaryKey: "id",
    model: 'tariff',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    showTotalRecords: true,
    actionscomponent: 'SuperAdminTariffActions',
    deletemessage: 'are_you_sure_you_want_to_delete_tariff',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
        },
        {
            id: 'monthly_payment',
            title: 'monthly_payment',
            table: true,
            sortable: true,
            form: {
                type: "currency_json",
                required: true
            },
            tdcomponent: 'CurrencyJsonTD'
        },
        {
            id: 'advance_payment',
            title: 'advance_payment',
            table: true,
            sortable: true,
            form: {
                type: "currency_json",
                required: true
            },
            tdcomponent: 'CurrencyJsonTD'
        },
        {
            id: 'doctors_limit',
            title: 'doctors_limit',
            table: true,
            sortable: true,
            form: {
                type: "number",
                required: true,
                note: "zero_unlimited"
            },
            tdcomponent: 'LimitationTD'
        },
        {
            id: 'admins_limit',
            title: 'admins_limit',
            table: true,
            sortable: true,
            form: {
                type: "number",
                required: true,
                note: "zero_unlimited"
            },
            tdcomponent: 'LimitationTD'
        },
        {
            id: 'disk_space_limit',
            title: 'disk_space_limit',
            table: true,
            sortable: true,
            form: {
                type: "number",
                required: true,
            },
            tdcomponent: 'LimitationTD'
        },
        {
            id: 'price_per_additional_space',
            title: 'price_per_additional_space',
            table: false,
            form: {
                type: "currency_json",
                required: true,
            },
        },
        {
            id: 'price_per_additional_doctor',
            title: 'price_per_additional_doctor',
            table: false,
            form: {
                type: "currency_json",
                required: true,
            },
        },
        {
            id: 'active',
            title: 'active',
            table: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },

    ]
}
