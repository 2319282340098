<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9.33186 11.4806L11.3456 13.5L9.32623 15.5194L10.125 16.3125L12.9375 13.5L10.125 10.6875L9.33186 11.4806Z"
        fill="#B5C6D8"/>
  <path d="M13.2694 11.4806L15.2831 13.5L13.2637 15.5194L14.0625 16.3125L16.875 13.5L14.0625 10.6875L13.2694 11.4806Z"
        fill="#B5C6D8"/>
  <path
      d="M7.875 12.9375H2.25V4.44937L8.67938 8.89875C8.77354 8.96407 8.8854 8.99907 9 8.99907C9.1146 8.99907 9.22647 8.96407 9.32063 8.89875L15.75 4.44937V9.5625H16.875V3.9375C16.875 3.63913 16.7565 3.35298 16.5455 3.142C16.3345 2.93103 16.0484 2.8125 15.75 2.8125H2.25C1.95163 2.8125 1.66548 2.93103 1.4545 3.142C1.24353 3.35298 1.125 3.63913 1.125 3.9375V12.9375C1.125 13.2359 1.24353 13.522 1.4545 13.733C1.66548 13.944 1.95163 14.0625 2.25 14.0625H7.875V12.9375ZM14.5125 3.9375L9 7.75125L3.4875 3.9375H14.5125Z"
      fill="#B5C6D8"/>
</svg>
</template>
