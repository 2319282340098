<template>
  <div>
    <DataTable :options="options"
               :params="{ branch_id: branch }"
               module="warehouse"/>
  </div>
</template>

<script>
import DataTable from "../../parts/general/DataTable"
import options from "../../../data/warehouse/warehouse"
import {mapState} from "vuex"

export default {
  name: "Warehouses",
  head() {
    return {
      title: this.$t('warehouses'),
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch
    })
  },
  components: { DataTable },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
