<template>
  <b-modal id="plan-sign-modal"
           :title="signed ? $t('document') : $t('sign')"
           @show="onShow"
           @hidden="onHidden"
           centered>
    <div v-if="signed">
      <div>{{ $t('date') }}: {{ formatDateTime(plan.signed_at) }}</div>
      <div>{{ $t('comment') }}: {{ plan.sign_comment ? plan.sign_comment : '-' }}</div>
      <div v-if="loaded">
        <div v-if="existingDocument">
          {{ $t('link') }}:
          <a target="_blank" :href="existingDocument.fileUrl">
            <FileSvg class="svg-primary ml-1 mr-2"/>{{ existingDocument.original }}
          </a>
        </div>
        <div v-else>{{ $t('no_attachment') }}</div>
        <div v-if="signer">
          {{ $t('author') }}: {{ signer.full_name }}
        </div>
      </div>
    </div>
    <div v-if="!signed">
      <FileGroup filetype="pdf"
                 :fid="`file-sign-${plan.id}`"
                 v-model="file"/>
      <AutosizeTextArea v-model="comment"
                        :label="$t('comment')"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel d-inline-block align-middle"
                  v-if="!signed"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button @click="save"
                v-if="!signed"
                class="w-auto ml-3 px-3 btn-themed align-middle btn-themed-squared">
          {{ file ? $t('save') : $t('sign_without_upload') }}
        </button>
        <b-button v-if="signed"
                  @click="deleteSignature"
                  class="cancel d-inline-block align-middle"
                  variant="themed">
          {{ $t('delete') }}
        </b-button>
      </div>
    </template>
    <!--    <div class="text-right mt-5">-->
    <!--      <a @click="hideModal" class="btn btn-themed btn-themed-outline btn-themed-squared">-->
    <!--        {{ $t('cancel') }}-->
    <!--      </a>-->
    <!--      <a @click="success" class="btn btn-themed btn-themed-squared ml-3">-->
    <!--        {{ $t('payment_accepted') }}-->
    <!--      </a>-->
    <!--    </div>-->
  </b-modal>
</template>

<script setup>
import { computed, defineEmits, getCurrentInstance, ref } from 'vue'
import FileGroup from "@/components/form/FileGroup.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import { appendFormdata } from "@/extensions/prototypes/prototypes"
import {EloquentService, PatientService} from "@/services/api.service"
import { formatDateTime } from "@/extensions/filters/filters"
import { useStore } from "vuex"
import FileSvg from '@/assets/svg-vue/patients/file_pdf.svg'

const props = defineProps({
  plan: {
    type: Object,
    required: true
  }
})

const instance = (getCurrentInstance())
const loaded = ref(false)
const existingDocument = ref(null)
const signer = ref(null)
const file = ref(null)
const comment = ref('')
const emit = defineEmits(['hidden'])
const store = useStore()

const save = async () => {
  let formData = new FormData()
  formData = appendFormdata(formData, {
    comment: comment.value,
    file: file.value || ''
  })
  try {
    const res = await PatientService.signTreatmentPlan(props.plan.planning_id, formData)
    if(res.data) {
      store.commit('incrementDataTableCounter')
      hideModal()
    }
  } catch (e) {
    console.log(e)
  }
}

const deleteSignature = async () => {
  try {
    const res = await PatientService.deleteSignTreatmentPlan(props.plan.planning_id)
    if(res.data) {
      store.commit('incrementDataTableCounter')
      hideModal()
    }
  } catch (e) {
    console.log(e)
  }
}

const signed = computed(() => {
  return !!props.plan.signed_at
})

const hideModal = () => {
  const bvModal = instance?.ctx?._bv__modal
  bvModal?.hide('plan-sign-modal')
}

const onHidden = () => {
  loaded.value = false
  emit('hidden')
  existingDocument.value = null
  signer.value = null
}

const onShow = async () => {
  comment.value = ''
  file.value = null
  if(props.plan.planning_id) {
    const res = await EloquentService.show('plan_treatment', props.plan.planning_id)
    existingDocument.value = res.data.signed_client_document
    signer.value = res.data.signer
  }
  loaded.value = true
}
</script>
