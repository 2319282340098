<template>
  <div>
    {{ roleName }}
  </div>
</template>

<script>
import {notificationRoles} from "@/dictionaries/dictionary"

export default {
  name: "SettingsDatatableTDNotificationRole",
  props: {
    object: {
      type: Object
    }
  },
  data() {
    return {
      roles: notificationRoles
    }
  },
  computed: {
    roleName() {
      let role = this.roles.find(x => x.id == this.object.recipient_role_id)
      return role ? this.$t(role.title) : ''
    }
  }
}
</script>

<style scoped>

</style>
