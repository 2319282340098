export default {
    primaryKey: 'planning_id',
    model: 'plan_treatment',
    order: 'planning_id',
    sort: 'desc',
    actionscomponent: 'PatientDatatablePlanTreatmentActions',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    showTotalRecords: true,
    actions: [
        "delete"
    ],
    fields: [
        {
            id: 'planning_id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'message',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "textarea",
                required: true
            },
            tdcomponent: "PatientsDatatableTDTreatmentMessage"
        },
        {
            id: 'visits',
            title: 'complexes',
            table: true,
            tdcomponent: "PatientsDatatableTDTreatmentServices"
        },
        {
            id: 'user_add_id',
            title: 'author',
            table: true,
            table_show: 'author.full_name',
            sortable: true,
            filter: false,
            filter_model_select: true
        },
        {
            id: 'patient_name',
            title: 'patient_name',
            filter: true,
        },
        {
            id: 'date_add',
            title: 'date_add',
            table: true,
            sortable: true,
            format_date: true,
            filter: false,
            filter_date: true
        },
        {
            id: 'status',
            title: 'status',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDTreatmentStatus"
        },
        {
            id: 'signed_at',
            title: 'signed',
            table: true,
            sortable: true,
            tdcomponent: "PatientsDatatableTDTreatmentSignedAt"
        },
        {
            id: 'complex',
            title: 'complex',
            filter: true,
        },
        {
            id: 'date_from',
            title: 'date_from',
            filter: true,
            filter_date: true,
        },
        {
            id: 'date_to',
            title: 'date_to',
            filter: true,
            filter_date: true,
        }
    ]
}
