export default {
    add_to_egisz: "Добавить в ЕГИСЗ",
    comment_to_deal: "Комментарий к сделке",
    diagnosis_comments: "Комментарий к диагнозу",
    download_all: "Скачать все",
    egisz: "ЕГИСЗ",
    for_rework: "На доработку",
    funnel: "Воронка",
    list: "Список",
    main_doctor: "Главный врач",
    patient_rejected_to_send_to_egisz: "Пациент отказался отправлять данные в ЕГИСЗ",
    prepare_document_for_sending_to_egisz: "Подготовить документ к отправке в ЕГИСЗ?",
    processing_status: "Статус обработки",
    requires_attention: "Требует внимания",
    representative_relation: "Кем является представитель пациенту",
    send_all: "Отправить все",
    send_to_egisz: "Отправить в ЕГИСЗ",
    sent_to_egisz: "Отправлено в ЕГИСЗ",
    sign: "Подписать",
    sign_all: "Подписать все",
    signed_by_doctor: "Подписано врачом",
    signed_by_main_doctor: "Подписано глав. врачом",
    show_more: "Подробнее",
    show_only_not_sent: "Показать только не отправленные",
    to_egisz_page: "На страницу ЕГИСЗ",
    to_rework: "На доработку",
    to_sign: "На подпись",
}
