<template>
  <section>
    <div class="section-top-row">
      <h1 id="page-title">{{  $t('payment')  }} #{{ paymentId }}</h1>
    </div>
    <div class="form-narrow">
      <PaymentStatusTable :status="status" :payment="payment"/>
      <div class="mt-4">
        <a class="btn-themed btn-themed-squared d-inline-block"
           @click="goToPage"
           v-if="payment && payment.pay_url && status && status.error_code">
          {{ $t('on_pay_page') }}
        </a>
        <router-link class="btn-themed btn-themed-squared d-inline-block"
                      v-if="!status || !status.error_code" to="/billing">
          {{ $t('to_billing') }}
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import {BillingService} from "@/services/api.service"
import PaymentStatusTable from "@/components/parts/billing/PaymentStatusTable"

export default {
  name: "PaymentBack",
  components: {
    PaymentStatusTable
  },
  head() {
    return {
      title: this.$t('payment'),
    }
  },
  data() {
    return {
      paymentId: this.$route.query.pay_id,
      status: null,
      payment: null,
    }
  },
  async mounted() {
    await this.getStatus()
  },
  methods: {
    async getStatus() {
      let res = await BillingService.paymentStatus(this.paymentId)
      this.status = res.data.status
      this.payment = res.data.payment
    },
    goToPage() {
      if(!this.payment || !this.payment.pay_url) return
      window.open(this.payment.pay_url,"_self")
    }
  }
}
</script>

<style scoped>

</style>
