export const clinicTariffAdapter =
    ({
         tariff_id,
         paid_functionality,
         additional_space,
         additional_doctors,
         free_day,
         paid_till,
         can_use_unblock,
         jetapi_autoprolong,
         custom_payment,
         comment,
     }) => ({
        tariff_id: tariff_id ?? null,
        paid_functionality: getFunctionality(paid_functionality),
        additional_space: additional_space ?? 0,
        additional_doctors: additional_doctors ?? 0,
        free_day: free_day ? free_day : null,
        paid_till: paid_till ? paid_till : null,
        can_use_unblock: can_use_unblock ? can_use_unblock : false,
        jetapi_autoprolong: jetapi_autoprolong ? jetapi_autoprolong : false,
        custom_payment: parseCustomPayment(custom_payment),
        comment: comment ? comment : null
    })

function getFunctionality(list) {
    if(!list) return []
    return list.map(x => x.id)
}

function parseCustomPayment(data) {
    for(const d in data) {
        if(data[d]) return data;
    }
    return null;
}

