<template>
  <div v-if="object.clinic_id">
    <InputGroup :label="$t('title')"
                v-model="object.name"/>
  </div>
</template>

<script>
import InputGroup from "@/components/form/InputGroup"

export default {
  name: "SettingsDatatableFormClientSourceTitle",
  components: {
    InputGroup,
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
}
</script>
