export default {
    primaryKey: "id",
    model: 'visit_result',
    order: 'sort_order',
    sort: 'asc',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        // "sort",
        "delete"
    ],
    deleteCondition(object) {
        return object.clinic_id
    },
    // editCondition(object) {
    //     return object.clinic_id
    // },
    deletemessage: 'confirm_deletion',
    fields: [
        {
            id: 'title',
            title: 'title',
            table_show: 'translatedTitle',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                conditionFunc(store, object) {
                    return !(object.id && !object.clinic_id)
                }
            },
        },
        {
            id: 'short_title',
            title: 'short_title',
            table_show: 'translatedShortTitle',
            table: true,
            form: {
                type: "text",
                required: true,
                conditionFunc(store, object) {
                    return !(object.id && !object.clinic_id)
                }
            }
        },
        {
            id: 'custom_data_sort_order',
            title: 'sort_order',
            table: true,
            // sortable: true,
            form: {
                type: "number",
                note: "higher_number_higher_priority",
                conditionFunc(store, object) {
                    return !(object.id && !object.clinic_id)
                }
            },
            format_number: true
        },
        {
            id: 'custom_data_active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]
}
