<template>
  <span class="ml-2 pointer"
        :title="currentErrors.join(', ')"
        v-b-tooltip.hover>
    <DangerSvg v-if="currentErrors.length" class="svg-size-md"/>
    <TickSvg v-else class="svg-primary"/>
  </span>
</template>

<script setup>
import { useStore } from "vuex"
import { computed } from "vue"
import TickSvg from '@/assets/svg-vue/form/tick.svg'
import DangerSvg from '@/assets/svg-vue/calendar/danger.svg'

const store = useStore()
const errors = store.state.datatable.validationErrors

const props = defineProps({
  errorIds: {
    type: Array,
    default: () => []
  }
})

const currentErrors = computed(() => {
  return props.errorIds.map(id => errors[id] ?? null).filter(e => e)
})
</script>
