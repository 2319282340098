<template>
  <div>
    {{ $t(object.payment_type) }}
    <div v-if="object.deposit_account && object.deposit_account.title
    && object.forma == 5
    && !object.family_account"
         class="text-muted small">
      {{ object.deposit_account.title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDPaymentForma",
  props: {
    object: {
      type: Object
    }
  },
}
</script>
