export const materialGroupAdapter =
    ({
         id,
         title,
         parent_id,
     }) => ({
        id: id ?? null,
        title: title ?? null,
        parent_id: parent_id ?? null,
    })
