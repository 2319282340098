import Echo from 'laravel-echo'
window.io = require('socket.io-client')

export default function initEcho(useProxy = true) {
    return new Echo({
        broadcaster: "socket.io",
        host: useProxy ?
            'https://proxyapi.dentist-plus.com:6002'
            : process.env.VUE_APP_API_DOMAIN + ':6001',
        auth: {
            headers: {
                Authorization: 'Bearer '
            }
        }
    })
}
