<template>
  <div>
    <DataTableHtml :columns="columns"
                   primaryKey="none"
                   initialOrder="name"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { AccountingService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "AccountingMonthMaterials",
  head() {
    return {
      title: this.$t('materials'),
    }
  },
  components: {
    DataTableHtml
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          format_datetime: true,
          sortable: true
        },
        {
          id: 'operation_type',
          field: 'operation_type',
          label: this.$t('operation_type'),
          tabletdclass: "order-mob-0",
          search_select: true,
          sortable: true
        },
        {
          id: 'payment_type',
          field: 'payment_type',
          label: this.$t('payment_type'),
          search_select: true,
          sortable: true
        },
        {
          id: 'amount',
          field: 'amount',
          label: this.$t('sum'),
          format_number: true,
          sortable: true,
          totals: true
        },
      ],
      rows: [],
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      AccountingService.materials(this.$route.params.id).then(res => {
        this.rows = res.data
      })
    }
  },
}
</script>

<style scoped>

</style>
