<template>
  <router-link class="btn btn-plain" :to="{ name: rights.includes('crm') ? 'CRMSalesFunnelApplications' : 'CRMTasks'}">
    <PhoneSvg/>
    <span class="d-none d-xl-inline">{{ $t('crm') }}</span>
  </router-link>
</template>

<script>

import PhoneSvg from '@/assets/svg-vue/header/phone.svg'
import {mapState} from "vuex"

export default {
  name: "HeaderCRM",
  components: {
    PhoneSvg
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    })
  },
}
</script>
