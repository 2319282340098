<template>
<svg width="3" height="11" viewBox="0 0 3 11" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M1.22223 2.44446C1.89725 2.44446 2.44446 1.89725 2.44446 1.22223C2.44446 0.547212 1.89725 0 1.22223 0C0.547212 0 0 0.547212 0 1.22223C0 1.89725 0.547212 2.44446 1.22223 2.44446Z"
      fill="#87939E"/>
  <path
      d="M1.22223 6.72223C1.89725 6.72223 2.44446 6.17502 2.44446 5.5C2.44446 4.82498 1.89725 4.27777 1.22223 4.27777C0.547212 4.27777 0 4.82498 0 5.5C0 6.17502 0.547212 6.72223 1.22223 6.72223Z"
      fill="#87939E"/>
  <path
      d="M1.22223 11C1.89725 11 2.44446 10.4528 2.44446 9.77777C2.44446 9.10275 1.89725 8.55554 1.22223 8.55554C0.547212 8.55554 0 9.10275 0 9.77777C0 10.4528 0.547212 11 1.22223 11Z"
      fill="#87939E"/>
</svg>
</template>
