import {months, recordStatuses} from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'patient',
    order: 'lname',
    sort: 'asc',
    showTotalRecords: true,
    doNotCacheFilter: true,
    actions: [
        "excel",
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'full_name',
            title: 'fio',
            table: true,
            sortable: 'lname',
            tabletdclass: "order-mob-0",
            tdcomponent: 'PatientsDatatableTDName',
        },
        {
            id: 'mphone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            format_phonecall: true,
        },
        {
            id: 'age',
            title: 'age',
            table: true,
            sortable: 'date_of_birth',
            tdcomponent: 'PatientsDatatableTDAge',
        },
        {
            id: 'gender',
            title: 'gender',
            table: false,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                select_type: "list",
                options: {
                    "0": { id: "0", title: "male" },
                    "1": { id: "1", title: "female" },
                }
            }
        },
        {
            id: 'email',
            title: 'email',
            table: true,
            sortable: true,
            filter: true,
        },
        {
            id: 'lname',
            title: 'surname',
            filter: true,
        },
        {
            id: 'fname',
            title: 'name',
            filter: true,
        },
        {
            id: 'mname',
            title: 'middle_name',
            filter: true,
        },
        {
            id: 'visit_date',
            title: 'visit_date',
            filter: true,
            filter_date: true
        },
        {
            id: 'card',
            title: 'card',
            filter: true,
        },
        {
            id: 'address',
            title: 'address',
            filter: true
        },
        {
            id: 'debt_calculated',
            title: 'debt',
            filter: true,
            filter_number: true,
        },
        {
            id: 'iin',
            title: 'iin',
            filter: true,
        },
        {
            id: 'taxcode',
            title: 'inn',
            filter: true,
        },
        {
            id: 'passport',
            title: 'passport',
            filter: true,
        },
        {
            id: 'representative_fio',
            title: 'representative',
            filter: true,
        },
        {
            id: 'discount',
            title: 'discount',
            filter: true,
            filter_number: true,
        },
        {
            id: 'doctor_id',
            title: 'treating_doctor',
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'curator_id',
            title: 'curator',
            filter: true,
            filter_model_select_equals: true,
        },
        {
            id: 'status_id',
            title: 'status',
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                select_type: "list",
                options: {
                    "2": { id: "2", title: "regular_client" },
                    "3": { id: "3", title: "first_visit" },
                }
            }
        },
        {
            id: 'source',
            title: 'came_from',
            table: false,
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'patient_condition_id',
            title: 'condition',
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'description',
            title: 'description',
            filter: true,
        },
        {
            id: 'has_tags',
            title: 'has_tags',
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'tag_id',
            title: 'client_tag',
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'has_treatment_plan_with_status',
            title: 'has_treatment_plan_with_status',
            filter: true,
            filter_list_select_equals: true,
            form: {
                options: {
                    "preliminary": { id: "preliminary", title: "preliminary" },
                    "active": { id: "active", title: "active" },
                    "treatment_complete": { id: "treatment_complete", title: "treatment_complete" },
                    "rejected": { id: "rejected", title: "rejected" },
                    "stopped": { id: "stopped", title: "stopped" },
                    "confirmed": { id: "confirmed", title: "confirmed" },
                }
            }
        },
        {
            id: 'passed_since_last_visit',
            title: 'passed_since_last_visit',
            filter: true,
            filter_list_select_equals: true,
            form: {
                options: {
                    "1_months": { id: "1_months", title: "above_1_month" },
                    "2_months": { id: "2_months", title: "above_2_months" },
                    "3_months": { id: "3_months", title: "above_3_months" },
                    "6_months": { id: "6_months", title: "above_6_months" },
                    // "12_months": { id: "12_months", title: "12_months_ago" },
                    "above_1_year": { id: "above_1_year", title: "above_1_year" },
                }
            }
        },
        {
            id: 'has_future_visit',
            title: 'has_future_visit',
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'has_visit_with_status',
            title: 'has_visit_with_status',
            filter: true,
            filter_list_select_equals: true,
            form: {
                options: recordStatuses.concat([
                    { id: 'paid', title: 'paid' },
                    { id: 'unpaid', title: 'unpaid' },
                ])
            }
        },
        {
            id: 'activity_status',
            title: 'activity',
            filter: true,
            filter_list_select: true,
            form: {
                options: {
                    "new": { id: "new", title: "new" },
                    "active": { id: "active", title: "active" },
                    "passive": { id: "passive", title: "passive" },
                    "lost": { id: "lost", title: "lost" },
                }
            }
        },
        {
            id: 'birth_month',
            title: 'birth_month',
            filter: true,
            filter_list_select: true,
            form: {
                options: months
            }
        },
        {
            id: 'birth_day',
            title: 'birth_day',
            filter: true,
        },
        {
            id: 'visits_count',
            title: 'records_count',
            filter_number: true,
            filter: true,
        },
        {
            id: 'age',
            title: 'age',
            filter: true,
            filter_number: true
        },
    ]

}
