export default {
    abs: "абс.",
    activate: "Активировать",
    add_category: "Добавить категорию",
    additional_materials: "Дополнительные материалы",
    archive: "Архив",
    are_you_sure_you_want_to_delete_category: "Вы уверены, что хотите удалить категорию?",
    are_you_sure_you_want_to_delete_service: "Вы уверены, что хотите удалить услугу?",
    average_price: "Средняя цена",
    category: "Категория",
    categories: "Категории",
    change_category: "Изменить категорию",
    change_pricelist: "Изменить прайс",
    complex_service: "Комплексная услуга",
    complex_services: "Комплексные услуги",
    current_value: "Текущая стоимость",
    decrease_price: "Уменьшить цену",
    discounted_price: "Цена со скидкой",
    doctor_reward: "Вознаграждение доктора",
    import_standard_services: "Импорт стандартных услуг",
    increase_price: "Увеличить цену",
    manager: "Администратор",
    manager_reward: "Вознаграждение администратора",
    money_lost_on_discount: "Потеря денег на скидках",
    ndfl_code: "Код НДФЛ",
    packages_cost: "Себестоимость комплектов",
    personal_percent_for_assistant: "Персональный процент для ассистента",
    personal_percent_for_doctor: "Персональный процент для доктора",
    personal_percent_for_manager: "Персональный процент для администратора",
    personal_percent_for_technic: "Персональный процент для техника",
    price_can_be_changed: "Цена может меняться в карточке лечения",
    pricelist_price: "Цена по прайсу",
    pricelist_short: "Прайс",
    reset_selection: "Сбросить выборку",
    send_to_archive: "Отправить в архив",
    Service: "Услуга",
    service_category: "Категория",
    service_code: "Код услуги",
    technic: "Техник",
    technic_reward: "Вознаграждение техника"
}
