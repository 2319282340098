<template>
<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M22.4933 8.91844C22.3845 8.6557 22.1281 8.48438 21.8437 8.48438H2.15624C1.87185 8.48438 1.61545 8.6557 1.50665 8.91844C1.39785 9.18117 1.45799 9.48361 1.65909 9.68471C1.83566 9.86133 9.71399 17.7396 9.89062 17.9163V21.8907C9.89062 22.157 10.0411 22.4004 10.2793 22.5196L13.0918 23.9258C13.3094 24.0346 13.5683 24.0234 13.7759 23.895C13.9832 23.7669 14.1094 23.5406 14.1094 23.2969V17.9163C14.286 17.7396 22.1643 9.86128 22.3409 9.68471C22.542 9.48361 22.6022 9.18117 22.4933 8.91844ZM12.7031 22.1592L11.2969 21.4561V18.3281H12.7031V22.1592ZM13.115 16.9219H10.885L6.66623 12.7031H17.3338L13.115 16.9219ZM18.74 11.2969H5.25998L3.85373 9.89063H20.1463L18.74 11.2969Z"/>
  <path
      d="M6.375 2.85938C5.21189 2.85938 4.26562 3.80564 4.26562 4.96875C4.26562 6.13186 5.21189 7.07812 6.375 7.07812C7.53811 7.07812 8.48438 6.13186 8.48438 4.96875C8.48438 3.80564 7.53811 2.85938 6.375 2.85938ZM6.375 5.67188C5.9873 5.67188 5.67188 5.35645 5.67188 4.96875C5.67188 4.58105 5.9873 4.26562 6.375 4.26562C6.7627 4.26562 7.07812 4.58105 7.07812 4.96875C7.07812 5.35645 6.7627 5.67188 6.375 5.67188Z"/>
  <path
      d="M18.3281 4.26562C17.5527 4.26562 16.9219 4.89647 16.9219 5.67188C16.9219 6.44728 17.5527 7.07812 18.3281 7.07812C19.1035 7.07812 19.7344 6.44728 19.7344 5.67188C19.7344 4.89647 19.1035 4.26562 18.3281 4.26562Z"/>
  <path
      d="M12.7031 0C11.1523 0 9.89062 1.26169 9.89062 2.8125C9.89062 4.36331 11.1523 5.625 12.7031 5.625C14.2539 5.625 15.5156 4.36331 15.5156 2.8125C15.5156 1.26169 14.2539 0 12.7031 0ZM12.7031 4.21875C11.9277 4.21875 11.2969 3.58791 11.2969 2.8125C11.2969 2.03709 11.9277 1.40625 12.7031 1.40625C13.4785 1.40625 14.1094 2.03709 14.1094 2.8125C14.1094 3.58791 13.4785 4.21875 12.7031 4.21875Z"/>
</svg>
</template>
