<template>
  <div>
    <DataTable :options="options"
               class="mt-4"
               module="crm"/>
    <SiteFormShowModal/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/crm/feedback"
import SiteFormShowModal from "@/components/parts/crm/SiteFormShowModal"

export default {
  name: "CRMFeedbacks",
  components: {
    SiteFormShowModal,
    DataTable,
  },
  head() {
    return {
      title: this.$t('forms'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
  },
  data() {
    return {
      options: options
    }
  },
  methods: {
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
