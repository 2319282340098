<template>
  <div>
    <a class="tdclickable" v-spaceduck-collapse="'kit-collapse-'+object.package_id">
      {{ object.name }}
    </a>
    <spaceduck-collapse :id="`kit-collapse-${object.package_id}`">
      <div v-for="m in object.materials" :key="`kit-${object.package_id}-mat-${m.material_id}`">
        {{ m.name }}: <b>{{ m.pivot.amount }}</b>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "WarehouseDatatableTDKitTitle",
  components: {
    SpaceduckCollapse
  },
  props: {
    object: {
      type: Object
    }
  }
}
</script>
