<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path
        d="M6.16667 6.66663H2.5C2.224 6.66663 2 6.44263 2 6.16663C2 5.89063 2.224 5.66663 2.5 5.66663H6.16667C6.44267 5.66663 6.66667 5.89063 6.66667 6.16663C6.66667 6.44263 6.44267 6.66663 6.16667 6.66663Z"
        fill="#B5C6D8"/>
    <path
        d="M6.16667 8.66663H2.5C2.224 8.66663 2 8.44263 2 8.16663C2 7.89063 2.224 7.66663 2.5 7.66663H6.16667C6.44267 7.66663 6.66667 7.89063 6.66667 8.16663C6.66667 8.44263 6.44267 8.66663 6.16667 8.66663Z"
        fill="#B5C6D8"/>
    <path
        d="M6.16667 10.6666H2.5C2.224 10.6666 2 10.4426 2 10.1666C2 9.89063 2.224 9.66663 2.5 9.66663H6.16667C6.44267 9.66663 6.66667 9.89063 6.66667 10.1666C6.66667 10.4426 6.44267 10.6666 6.16667 10.6666Z"
        fill="#B5C6D8"/>
    <path
        d="M6.16667 12.6666H2.5C2.224 12.6666 2 12.4426 2 12.1666C2 11.8906 2.224 11.6666 2.5 11.6666H6.16667C6.44267 11.6666 6.66667 11.8906 6.66667 12.1666C6.66667 12.4426 6.44267 12.6666 6.16667 12.6666Z"
        fill="#B5C6D8"/>
    <path
        d="M14.1667 15.3333H1.83333C0.822 15.3333 0 14.5113 0 13.5V2.49996C0 1.48863 0.822 0.666626 1.83333 0.666626H14.1667C15.178 0.666626 16 1.48863 16 2.49996V13.5C16 14.5113 15.178 15.3333 14.1667 15.3333ZM1.83333 1.66663C1.374 1.66663 1 2.04063 1 2.49996V13.5C1 13.9593 1.374 14.3333 1.83333 14.3333H14.1667C14.626 14.3333 15 13.9593 15 13.5V2.49996C15 2.04063 14.626 1.66663 14.1667 1.66663H1.83333Z"
        fill="#B5C6D8"/>
    <path
        d="M15.5 4H0.5C0.224 4 0 3.776 0 3.5C0 3.224 0.224 3 0.5 3H15.5C15.776 3 16 3.224 16 3.5C16 3.776 15.776 4 15.5 4Z"
        fill="#B5C6D8"/>
    <path
        d="M8.49983 12.1C8.37183 12.1 8.24383 12.0513 8.1465 11.9533C7.95117 11.758 7.95117 11.4413 8.1465 11.246L9.17317 10.2193C9.62983 9.76335 10.3712 9.76468 10.8265 10.2193C10.8632 10.2567 10.9712 10.3393 11.0765 10.2073L11.4232 9.77401C11.8618 9.21468 12.8012 9.21068 13.2465 9.77801L13.8898 10.5807C14.0625 10.796 14.0285 11.1107 13.8125 11.2833C13.5972 11.4573 13.2825 11.422 13.1098 11.206L12.4632 10.3993C12.3738 10.286 12.2892 10.29 12.2072 10.3953L11.8572 10.8327C11.4425 11.3507 10.5892 11.396 10.1198 10.9273C10.0545 10.8627 9.94517 10.8627 9.87983 10.9273L8.85317 11.954C8.75583 12.0513 8.62783 12.1 8.49983 12.1Z"
        fill="#B5C6D8"/>
    <path
        d="M12.8333 13.3334H9.16667C8.52333 13.3334 8 12.81 8 12.1667V6.50004C8 5.85671 8.52333 5.33337 9.16667 5.33337H12.8333C13.4767 5.33337 14 5.85671 14 6.50004V12.1667C14 12.81 13.4767 13.3334 12.8333 13.3334ZM9.16667 6.33337C9.07467 6.33337 9 6.40804 9 6.50004V12.1667C9 12.2587 9.07467 12.3334 9.16667 12.3334H12.8333C12.9253 12.3334 13 12.2587 13 12.1667V6.50004C13 6.40804 12.9253 6.33337 12.8333 6.33337H9.16667Z"
        fill="#B5C6D8"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
