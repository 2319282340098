import { leadAdapter } from "@/adapters/crm/lead"

export default {
    primaryKey: "id",
    model: 'lead',
    order: 'id',
    sort: 'desc',
    adapter: leadAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_lead',
    actionscomponent: 'CRMLeadDatatableActions',
    showTotalRecords: true,
    checks: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        delete: "crmtask_delete",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
            form: {
                component: "CRMDatatableLeadFormCard"
            }
        },
        {
            id: 'phone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            format_phonecall: true,
        },
        {
            id: 'fname',
            title: 'name',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
        },
        {
            id: 'patient_id',
            title: 'patient',
            table_show: 'patient.full_name',
            filter: true,
        },
        {
            id: 'manual_title',
            title: 'title',
            table: false,
            filter: false,
            form: {
                type: "text",
            }
        },
        {
            id: 'reason',
            title: 'application_reason',
            table: true,
            filter: true,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            filter: true,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'created_by',
            title: 'author',
            table: true,
            sortable: true,
            table_show: 'author.full_name',
            filter: true,
            filter_model_select: true,
        },
        {
            id: 'created_at',
            title: 'created',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: false,
            filter_date: true
        },
        {
            id: 'status_id',
            title: 'status',
            table_show: 'crm_stage.translatedTitle',
            filter: true,
            filter_model_select_translate: true,
            table: true,
            sortable: true,
            form: {
                type: "select",
                select_type: "model",
                disabled_on_edit: true,
                required: true,
                select_data: {
                    "title": "translatedTitle",
                },
            }
        },
        {
            id: 'source',
            title: 'channel',
            table: true,
            filter: true,
            format_translate: true,
            form: {
                type: "text",
                disabled_on_edit: true,
            },
        },
        {
            id: 'source_id',
            title: 'source',
            table: true,
            table_show: 'acquisition_source.title',
            filter: true,
            filter_model_select: true,
            format_translate: true,
            form: {
                type: "select",
                select_type: "model",
                disabled_on_edit: true,
                required: true,
                select_data: {
                    "title": "title",
                },
            }
        },
        {
            id: 'call_date',
            title: 'recall',
            table: false,
            sortable: true,
            format_datetime: true,
            filter: true,
            filter_date: true,
            form: {
                type: "date"
            },
        },
        {
            id: 'urgent',
            title: 'urgent',
            table: true,
            sortable: true,
            tdcomponent: 'CRMDatatableTDYesNoBoolean',
            form: {
                type: "checkbox",
            },
        },
        {
            id: 'date_from',
            title: 'date_from',
            filter: true,
            filter_date: true,
        },
        {
            id: 'date_to',
            title: 'date_to',
            filter: true,
            filter_date: true,
        },
        {
            id: 'has_tasks',
            title: 'has_tasks',
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'has_overdue_tasks',
            title: 'has_overdue_tasks',
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'has_today_tasks',
            title: 'has_today_task',
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'task_date',
            title: 'task_date',
            filter: true,
            filter_date: true,
        },
    ]

}
