<template>
  <a @click="expand" class="d-inline-block d-md-none more-info">
    <ArrowSvg/>
  </a>
</template>

<script>

import ArrowSvg from '@/assets/svg-vue/warehouse/circlearrow.svg'

export default {
  name: "MobileMoreArrow",
  components: {
    ArrowSvg
  },
  methods: {
    expand(e) {
      let tr = e.target.closest('tr')
      if(tr) {
        tr.classList.toggle('expanded')
      }
    }
  }
}
</script>

<style scoped>

</style>
