<template>
  <div v-if="loaded" class="description-form">
    <div v-for="field in fields" :key="`f-${field.id}`">
      <label class="form-label">
        <span>{{ $t(field.title) }}</span>
      </label>
      <TemplateGroup v-model="otherData[field.id]"
                     :dictation="false"
                     :label="$t(field.title)"
                     :saveAsTemplate="false"
                     :disable-search="true"
                     model=""/>
    </div>

    <div class="pt-5 pb-5">
      <button :disabled="loading"
              class="btn-themed btn-themed-squared"
              @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>

import { CalendarService } from "@/services/api.service"
import { mapState } from "vuex"
import TemplateGroup from "@/components/form/TemplateGroup"

export default {
  name: "PatientVisitOther",
  components: {
    TemplateGroup,
  },
  props: {
  },
  data() {
    return {
      otherData: {},
      loaded: false,
      fields: [
        { id: 'hygiene_index', title: 'hygiene_index' },
        { id: 'kpu_index', title: 'kpu_index' },
        { id: 'allergy', title: 'allergy_diagnosis' },
        { id: 'termometry', title: 'termometry' },
        { id: 'tonometry', title: 'tonometry' },
        { id: 'used_medication', title: 'used_medication' },
      ]
    }
  },
  computed: {
    ...mapState({
      visit:  state => state.patient.visit,
      loading: state => state.dom.loading,
      clinic: state => state.auth.clinic,
    }),
  },
  methods: {
    async getInfo() {
      let res = await CalendarService.getAdditionalInfo(this.visit.id)
      if(res.data) this.otherData = res.data
      this.loaded = true
    },
    async save() {
      this.$store.commit('cleanValidationErrors')
      let res = await CalendarService.recordAdditionalInfo(this.visit.id, this.otherData)
      if(res.data) this.$noty.info(this.$t('success_message'))
    },
  },
  watch: {

  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style scoped lang="scss">
.description-form {
  max-width: 1000px;
}
</style>
