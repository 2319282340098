<template>
  <div>
    {{ formatNumber(averageCheque, true) }}
  </div>
</template>

<script>

import {formatNumber} from "@/extensions/filters/filters"

export default {
  name: "ReportStatDoctorsDatatableHtmlChequeTotal",
  components: {
  },
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    },
  },
  computed: {
      averageCheque() {
        return this.object.visits > 0 ? Math.round(this.object.revenue / this.object.visits) : 0
      }
  },
  methods: {
    formatNumber
  }
}
</script>
