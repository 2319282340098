const state = {
    groupsopened: [],
}

const getters = {
}

const mutations = {
    toggleGroup(state, object) {
        const id = object.id
        let ind = state.groupsopened.indexOf(id)
        if (ind === -1) {
            state.groupsopened.push(id)
        } else {
            state.groupsopened.splice(ind, 1)
            if(object.children.length > 0) {
                object.children.forEach(el => {
                    let index = state.groupsopened.indexOf(el.id)
                    if (index !== -1) {
                        state.groupsopened.splice(ind, 1)
                    }
                })
            }
        }
    }
}

const actions = {
};

export default {
    state,
    actions,
    mutations,
    getters
}