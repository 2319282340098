<template>
  <span v-if="rights.includes('patient_card') && !isPatient && patientId">
    <router-link :to="{ name: 'PatientVisits', params: { id: patientId }}">
      {{ fullName }}
    </router-link>
  </span>
  <span v-else>{{ fullName }}</span>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "PatientsDatatableTDName",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    isPatient() {
      return this.$store.getters.isPatient
    },
    patient() {
      return this.object.patient ? this.object.patient : this.object
    },
    patientId() {
      if(this.patient && this.patient.patient_id) return this.patient.patient_id
      if(this.patient && this.patient.id) return this.patient.id
      return null
    },
    fullName() {
      if(this.patient && this.patient.patient_name) return this.patient.patient_name
      if(this.patient && this.patient.full_name) return this.patient.full_name
      return ''
    }
  },
}
</script>
