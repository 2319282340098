<template>
  <div class="form-narrow pb-5">

    <div class="pb-5">

      <div class="mb-4">
        <b-button v-spaceduck-collapse="`sms-c`" class="btn-collapse">SMS</b-button>
        <spaceduck-collapse id="sms-c" class="form-group-standard">
          <div class="pt-2">
            <div class="font-weight-bold pt-3">{{ standardSMSTitle }}</div>
            <b-row>
              <b-col>
                <InputGroup v-model="login"
                            :label="$t('login')"
                            :required="true"/>
              </b-col>
              <b-col>
                <InputGroup v-model="pass"
                            :label="$t('password')"
                            :required="true"/>
              </b-col>
              <b-col>
                <InputGroup v-model="alfaname"
                            label="Alfaname"
                            :required="false"/>
              </b-col>
            </b-row>

            <!--  Russia-->
            <div v-if="clinic.country_id === countries.RUSSIA">
              <div class="font-weight-bold">Prosto-r</div>
              <b-row>
                <b-col>
                  <InputGroup v-model="prostorLogin"
                              :label="$t('login')"
                              :required="false"/>
                </b-col>
                <b-col>
                  <InputGroup v-model="prostorPass"
                              :label="$t('password')"
                              :required="false"/>
                </b-col>
                <b-col>
                  <InputGroup v-model="prostorAlfaname"
                              label="Alfaname"
                              :required="false"/>
                </b-col>
              </b-row>
            </div>

            <!--  Kazakhstan-->
            <div v-if="clinic.country_id === countries.KAZAKHSTAN">
              <div class="font-weight-bold">Kaz-Info-Tech</div>
              <b-row>
                <b-col>
                  <InputGroup v-model="kitLogin"
                              :label="$t('login')"
                              :required="false"/>
                </b-col>
                <b-col>
                  <InputGroup v-model="kitPass"
                              :label="$t('password')"
                              :required="false"/>
                </b-col>
                <b-col>
                  <InputGroup v-model="kitAlfaname"
                              label="Alfaname"
                              :required="false"/>
                </b-col>
              </b-row>
            </div>


            <div>
              <button class="btn-themed btn-themed-squared d-inline-block"
                      :disabled="((!login || !pass) && (!prostorLogin || !prostorPass) && (!kitLogin || !kitPass)) || loading"
                      @click="saveSMSSettings">
                {{ $t('save') }}
              </button>
              <button class="btn-themed btn-themed-outline btn-themed-squared ml-2 d-inline-block"
                      v-if="clinic.sms_info && clinic.sms_info.sms_settings"
                      :disabled="loading"
                      @click="turnOffSMS">
                {{ $t('turn_off') }}
              </button>
            </div>
          </div>
        </spaceduck-collapse>
      </div>

<!--      <RadistAPIIntegration/>-->

      <ProdoctorovIntegration/>

      <WazzupAPIIntegration/>

      <JetAPIIntegration/>

      <BinotelIntegration/>

      <SipuniIntegration/>

      <UISIntegration/>

      <VoipIntegration/>

      <TildaIntegration/>

      <TrustMeIntegration/>

    </div>
  </div>
</template>

<script>

import InputGroup from '@/components/form/InputGroup'
import { mapState } from "vuex"
import { ClinicService } from "@/services/api.service"
// import RadistAPIIntegration from '@/components/parts/settings/RadistAPIIntegration'
import BinotelIntegration from '@/components/parts/settings/BinotelIntegration'
import SipuniIntegration from '@/components/parts/settings/SipuniIntegration'
import UISIntegration from '@/components/parts/settings/UISIntegration'
import WazzupAPIIntegration from "@/components/parts/settings/WazzupAPIIntegration"
import ProdoctorovIntegration from "@/components/parts/settings/ProdoctorovIntegration"
import VoipIntegration from "@/components/parts/settings/VoipIntegration"
import JetAPIIntegration from "@/components/parts/settings/JetAPIIntegration"
import { countries } from "@/dictionaries/dictionary"
import TildaIntegration from "@/components/parts/settings/TildaIntegration.vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import TrustMeIntegration from "@/components/parts/settings/TrustMeIntegration.vue"

export default {
  name: "SettingsIntegrations",
  components: {
    TrustMeIntegration,
    SpaceduckCollapse,
    TildaIntegration,
    JetAPIIntegration,
    ProdoctorovIntegration,
    WazzupAPIIntegration,
    VoipIntegration,
    InputGroup,
    // RadistAPIIntegration,
    BinotelIntegration,
    SipuniIntegration,
    UISIntegration
  },
  head() {
    return {
      title: this.$t('integrations'),
    }
  },
  computed: {
    countries() {
      return countries
    },
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      loading: state => state.dom.loading,
      superadmin: state => state.auth.superadmin,
    }),
    smsSettings() {
      return (this.clinic.sms_info && this.clinic.sms_info.sms_settings) ? JSON.parse(this.clinic.sms_info.sms_settings) : {
        login: '',
        pass: '',
        prostorLogin: '',
        prostorPass: '',
        prostorAlfaname: '',
        kitLogin: '',
        kitPass: '',
        kitAlfaname: '',
      }
    },
    standardSMSTitle() {
      if(this.clinic.country_id === countries.UKRAINE) return 'FlySMS'
      if(this.clinic.country_id === countries.AZERBAIJAN) return 'MSM.AZ'
      return 'SMSC'
    }
  },
  data() {
    return {
      login: '',
      pass: '',
      alfaname: '',
      prostorLogin: '',
      prostorPass: '',
      prostorAlfaname: '',
      kitLogin: '',
      kitPass: '',
      kitAlfaname: '',
    }
  },
  methods: {
    saveSMSSettings() {
      ClinicService.saveSMSSettings({
        login: this.login,
        pass: this.pass,
        alfaname: this.alfaname,
        prostorLogin: this.prostorLogin,
        prostorPass: this.prostorPass,
        prostorAlfaname: this.prostorAlfaname,
        kitLogin: this.kitLogin,
        kitPass: this.kitPass,
        kitAlfaname: this.kitAlfaname,
      }, this.superadmin, this.clinic.id).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.dispatch('init')
      })
    },
    turnOffSMS() {
      ClinicService.saveSMSSettings(null, this.superadmin, this.clinic.id).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.dispatch('init')
        this.login = ''
        this.pass = ''
        this.alfaname = ''
        this.prostorLogin = ''
        this.prostorPass = ''
        this.prostorAlfaname = ''
        this.kitLogin = ''
        this.kitPass = ''
        this.kitAlfaname = ''
      })
    }
  },
  mounted() {
    this.login = this.smsSettings.login
    this.alfaname = this.clinic.sms_info ? this.clinic.sms_info.alfaname : ''
    this.pass = this.smsSettings.pass
    this.prostorLogin = this.smsSettings.prostorLogin
    this.prostorPass = this.smsSettings.prostorPass
    this.prostorAlfaname = this.smsSettings.prostorAlfaname
    this.kitLogin = this.smsSettings.kitLogin
    this.kitPass = this.smsSettings.kitPass
    this.kitAlfaname = this.smsSettings.kitAlfaname
  }
}
</script>
