<template>
  <g>
    <!--   SAME AS 44  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M13.9614 79.7742L16.0451 81.0439H19.5289L21.6289 79.7742L20.0172 78.2277L22.1009 76.6812L20.2126 75.1347L22.5079 73.4416L20.6847 71.8951L22.98 70.4951L20.9614 69.0789L22.98 67.4672L21.287 65.9207L23.5172 64.5044L21.7591 63.0393L24.0544 61.4928L21.9056 59.9463L24.0544 58.3347L22.5079 56.9835L24.5916 55.437L22.7684 53.9556L24.8521 52.4091L23.0451 50.6673L24.0544 48.9905V34.7301L22.1661 27.4045H16.0451" fill="#8889BE"/>
      <path d="M21.3687 79.7742L19.285 81.0439H15.8176L13.7013 79.7742L15.3292 78.2277L13.2292 76.6812L15.1176 75.1347L12.8385 73.4416L14.6455 71.8951L12.3664 70.4951L14.385 69.0789L12.3664 67.4672L14.0431 65.9207L11.8292 64.5044L13.571 63.0393L11.292 61.4928L13.4408 59.9463L11.292 58.3347L12.8385 56.9835L10.7548 55.437L12.5617 53.9556L10.478 52.4091L12.3013 50.6673L11.292 48.9905V34.7301L13.1641 27.4045H17.071" fill="#8889BE"/>
      <path d="M1.01611 14.9048C1.01611 14.9048 1.71624 32.9401 5.63696 35.2645C9.55767 37.589 14.4306 40.3475 18.1272 39.6473C21.9077 38.5728 25.4419 36.77 28.5311 34.3404C28.9932 33.6402 34.3142 17.6913 34.3142 17.6913C34.3142 17.6913 25.2965 -1.56219 17.6652 1.01428C10.0338 3.59075 13.0443 4.27687 1.01611 14.9048Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M28.5311 34.9645L28.2371 47.1048C27.1729 58.1108 26.5568 64.888 26.1087 69.8729C25.5626 75.964 25.1425 80.7949 22.286 86.4099C20.5357 89.8686 16.769 95.7496 14.0805 95.0775C13.1143 94.8395 12.2882 93.6773 11.28 89.7565C10.1027 84.7125 9.67336 79.5227 10.0058 74.3537C10.1989 67.7652 9.97905 61.1711 9.34764 54.6101C8.89315 50.481 8.15866 46.3875 7.14924 42.3579L5.63696 35.8887" fill="#DEE1E9"/>
      <path d="M1.01611 15.529C1.01611 15.529 1.71624 33.5643 5.63696 35.8887C9.55767 38.2131 14.4306 40.9716 18.1272 40.2715C21.9077 39.1969 25.4419 37.3942 28.5311 34.9645C28.9932 34.2644 34.3142 18.3155 34.3142 18.3155C34.3142 18.3155 25.2965 -0.938047 17.6652 1.63842C10.0338 4.21489 13.0443 4.90102 1.01611 15.529Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M23.1961 31.1838C25.0584 32.5841 22.65 37.1769 21.1097 47.3708C20.5814 50.973 20.2355 54.5996 20.0735 58.2368C19.7514 64.1598 20.0735 65.084 19.7234 72.6874C19.5822 75.973 19.2361 79.2467 18.6872 82.4892C18.1831 85.4297 15.5765 91.7495 15.2965 91.7635C14.7364 91.7635 16.1108 83.6045 16.3768 79.1426C16.2557 77.3616 16.2557 75.5745 16.3768 73.7936C16.5643 69.5915 16.4754 65.3817 16.1108 61.1913C15.6347 53.6439 15.3826 49.8632 14.0244 46.0405C12.6661 42.2178 12.3021 38.4932 11.0278 34.8385C10.7758 34.1384 10.1737 32.4581 10.9158 31.7299C11.6579 31.0018 12.9462 31.6459 14.4865 31.954C19.3734 32.9061 21.6138 30.1056 23.1961 31.1838Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M28.5311 34.9645L28.2371 47.1048C27.1729 58.1108 26.5568 64.888 26.1087 69.8729C25.5626 75.964 25.1425 80.7949 22.286 86.4099C20.5357 89.8686 16.769 95.7496 14.0805 95.0775C13.1143 94.8395 12.2882 93.6773 11.28 89.7565C10.1027 84.7125 9.67336 79.5227 10.0058 74.3537C10.1989 67.7652 9.97905 61.1711 9.34764 54.6101C8.89315 50.481 8.15866 46.3875 7.14924 42.3579L5.63696 35.8887" fill="#DEE1E9"/>
      <path d="M1.01611 15.529C1.01611 15.529 1.71624 33.5643 5.63696 35.8887C9.55767 38.2131 14.4306 40.9716 18.1272 40.2715C21.9077 39.1969 25.4419 37.3942 28.5311 34.9645C28.9932 34.2644 34.3142 18.3155 34.3142 18.3155C34.3142 18.3155 25.2965 -0.938047 17.6652 1.63842C10.0338 4.21489 13.0443 4.90102 1.01611 15.529Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M23.1961 31.1838C25.0584 32.5841 22.65 37.1769 21.1097 47.3708C20.5814 50.973 20.2355 54.5996 20.0735 58.2368C19.7514 64.1598 20.0735 65.084 19.7234 72.6874C19.5822 75.973 19.2361 79.2467 18.6872 82.4892C18.1831 85.4297 15.5765 91.7495 15.2965 91.7635C14.7364 91.7635 16.1108 83.6045 16.3768 79.1426C16.2557 77.3616 16.2557 75.5745 16.3768 73.7936C16.5643 69.5915 16.4754 65.3817 16.1108 61.1913C15.6347 53.6439 15.3826 49.8632 14.0244 46.0405C12.6661 42.2178 12.3021 38.4932 11.0278 34.8385C10.7758 34.1384 10.1737 32.4581 10.9158 31.7299C11.6579 31.0018 12.9462 31.6459 14.4865 31.954C19.3734 32.9061 21.6138 30.1056 23.1961 31.1838Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="14" cy="94" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M34.2302 17.1324C34.2302 17.1324 28.9092 33.0953 28.4472 33.7814L28.0971 45.9216C27.0749 56.9136 26.4588 63.7049 26.0247 68.7038C25.4786 74.7809 25.0585 79.6258 22.202 85.2268C20.4377 88.6994 16.671 94.5805 13.9965 93.9084C13.0303 93.6563 12.2042 92.5081 11.196 88.5874C10.0187 83.5433 9.58937 78.3536 9.92177 73.1846C10.0917 66.5859 9.85793 59.9831 9.22164 53.413C8.76716 49.2838 8.03266 45.1903 7.02324 41.1608L5.55297 34.7056C1.63226 32.3952 0.932129 14.3459 0.932129 14.3459C12.9463 3.70391 9.94978 3.03179 17.5812 0.455319C25.2126 -2.12115 34.2302 17.1324 34.2302 17.1324Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M34.2302 17.1324C34.2302 17.1324 28.9092 33.0953 28.4472 33.7814L28.0971 45.9216C27.0749 56.9136 26.4588 63.7049 26.0247 68.7038C25.4786 74.7809 25.0585 79.6258 22.202 85.2268C20.4377 88.6994 16.671 94.5805 13.9965 93.9084C13.0303 93.6563 12.2042 92.5081 11.196 88.5874C10.0187 83.5433 9.58937 78.3536 9.92177 73.1846C10.0917 66.5859 9.85793 59.9831 9.22164 53.413C8.76716 49.2838 8.03266 45.1903 7.02324 41.1608L5.55297 34.7056C1.63226 32.3952 0.932129 14.3459 0.932129 14.3459C12.9463 3.70391 9.94978 3.03179 17.5812 0.455319C25.2126 -2.12115 34.2302 17.1324 34.2302 17.1324Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M23.2769 17.5982L23.5709 5.45795L17.7236 4.5L12.441 6.68106L6.72363 3.69122L0.676758 6.38212L2.18903 12.8513C3.19846 16.8809 3.93295 20.9744 4.38744 25.1035C5.01884 31.6645 5.23865 38.2587 5.04556 44.8471C4.71315 50.0161 5.14249 55.2059 6.31979 60.25C7.32797 64.1707 8.15412 65.3329 9.1203 65.5709C11.8088 66.2431 15.5755 60.362 17.3258 56.9034C20.1666 51.3191 20.5977 46.5105 21.1395 40.4665L21.1485 40.3663C21.5966 35.3814 22.2127 28.6042 23.2769 17.5982Z" fill="#DEE1E9"/>
      <path d="M3.99999 11.5273C11.7274 14.1567 15.5591 13.2022 21.6815 8" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M16.1495 17.8642C17.6898 7.67036 20.0982 3.07752 18.2359 1.67727L15.4549 2.90442L12.9549 1.40442L10.4549 2.90442L8.20341 1.40442L7.72363 1L5.95561 2.22336C5.21347 2.9515 5.81558 4.6318 6.06763 5.33193C7.34186 8.9866 7.70593 12.7113 9.06418 16.534C10.4224 20.3567 10.6745 24.1374 11.1506 31.6847C11.5152 35.8752 11.6041 40.085 11.4166 44.2871C11.2955 46.068 11.2955 47.8551 11.4166 49.636C11.1506 54.0979 9.77618 62.257 10.3363 62.257C10.6163 62.243 13.2229 55.9232 13.727 52.9826C14.2759 49.7401 14.622 46.4665 14.7632 43.1809C15.1133 35.5775 14.7912 34.6533 15.1133 28.7302C15.2753 25.0931 15.6212 21.4664 16.1495 17.8642Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M28.3473 34.5634L28.0533 46.7036C26.9891 57.7096 26.3729 64.4869 25.9249 69.4718C25.3788 75.5629 24.9587 80.3938 22.1022 86.0088C20.3518 89.4674 16.5852 95.3485 13.8967 94.6764C12.9305 94.4384 12.1043 93.2761 11.0962 89.3554C9.91886 84.3113 9.48952 79.1216 9.82192 73.9526C10.015 67.3641 9.79521 60.77 9.1638 54.209C8.70932 50.0799 7.97482 45.9863 6.9654 41.9568L5.45312 35.4876" fill="#DEE1E9"/>
      <path d="M5.45312 35.4876C1.5324 33.1631 0.832275 15.1278 0.832275 15.1278C12.8605 4.4999 9.84992 3.81377 17.4813 1.2373C19.4548 0.571019 21.521 1.36462 23.5 2.90761L21.7372 3.74234L20.3985 6.77732L24.2076 8.29206L23 10.6055L24.2076 13.0724L23.3005 15.7176H27.2076L30.838 11.8984C32.8466 15.1735 34.1304 17.9143 34.1304 17.9143C34.1304 17.9143 28.8094 33.8633 28.3473 34.5634C25.258 36.993 21.7239 38.7958 17.9434 39.8704C14.2467 40.5705 9.37384 37.812 5.45312 35.4876Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M27.6758 24.65C17.5767 20.6549 15.2637 16.162 16.4195 4.60548" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M23.0122 30.7827C24.8746 32.183 22.4661 36.7758 20.9258 46.9697C20.3975 50.5719 20.0517 54.1985 19.8896 57.8356C19.5676 63.7587 19.8896 64.6829 19.5396 72.2863C19.3984 75.5719 19.0523 78.8455 18.5034 82.0881C17.9993 85.0286 15.3927 91.3484 15.1126 91.3624C14.5525 91.3624 15.9269 83.2034 16.193 78.7415C16.0719 76.9605 16.0719 75.1734 16.193 73.3925C16.3805 69.1904 16.2916 64.9806 15.9269 60.7902C15.4508 53.2428 15.1988 49.4621 13.8405 45.6394C12.4823 41.8167 12.1182 38.092 10.844 34.4374C10.5919 33.7372 9.98984 32.0569 10.732 31.3288C11.4741 30.6007 12.7623 31.2448 14.3026 31.5528C19.1895 32.505 21.4299 29.7045 23.0122 30.7827Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M28.0533 17.2494V46.3485C26.9891 57.3545 26.3729 64.1318 25.9249 69.1167C25.3788 75.2078 24.9587 80.0387 22.1022 85.6537C20.3518 89.1123 16.5852 94.9934 13.8967 94.3213C12.9305 94.0833 12.1043 92.921 11.0962 89.0003C9.91886 83.9562 9.48952 78.7665 9.82192 73.5975C10.015 67.009 9.79521 60.4149 9.1638 53.8539C8.70932 49.7248 7.97482 45.6312 6.9654 41.6017L1.5 17.2494" fill="#DEE1E9"/>
      <path d="M5.45312 35.1325C1.5324 32.808 0.832275 14.7727 0.832275 14.7727C12.8605 4.14479 9.84992 3.45867 17.4813 0.882198C25.1127 -1.69427 34.1304 17.5592 34.1304 17.5592C34.1304 17.5592 28.8094 33.5082 28.3473 34.2083L24.5907 34.9245C24.0076 35.0357 23.4567 34.6175 23.4069 34.0261L22.5 23.2494C18.3985 22.6959 16.1021 22.681 12 23.2494L10.6104 34.2577C10.5473 34.7576 10.1222 35.1325 9.61829 35.1325H5.45312Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M28.0533 18V47.0991C26.9891 58.1052 26.3729 64.8824 25.9249 69.8673C25.3788 75.9584 24.9587 80.7893 22.1022 86.4043C20.3518 89.863 16.5852 95.744 13.8967 95.0719C12.9305 94.8339 12.1043 93.6716 11.0962 89.7509C9.91886 84.7068 9.48952 79.5171 9.82192 74.3481C10.015 67.7596 9.79521 61.1655 9.1638 54.6045C8.70932 50.4754 7.97482 46.3819 6.9654 42.3523L1.5 18" fill="#DEE1E9"/>
      <path d="M5.45312 35.8831C1.5324 33.5586 0.832275 15.5233 0.832275 15.5233C12.8605 4.8954 9.84992 4.20928 17.4813 1.63281C25.1127 -0.943662 34.1304 18.3099 34.1304 18.3099C34.1304 18.3099 28.8094 34.2588 28.3473 34.9589L24.5907 35.6751C24.0076 35.7863 23.4567 35.3681 23.4069 34.7767L22.5 24C18.3985 23.4466 16.1021 23.4316 12 24L10.6104 35.0083C10.5473 35.5082 10.1222 35.8831 9.61829 35.8831H5.45312Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M28.0533 18V47.0991C26.9891 58.1052 26.3729 64.8824 25.9249 69.8673C25.3788 75.9584 24.9587 80.7893 22.1022 86.4043C20.3518 89.863 16.5852 95.744 13.8967 95.0719C12.9305 94.8339 12.1043 93.6716 11.0962 89.7509C9.91886 84.7068 9.48952 79.5171 9.82192 74.3481C10.015 67.7596 9.79521 61.1655 9.1638 54.6045C8.70932 50.4754 7.97482 46.3819 6.9654 42.3523L1.5 18" fill="#DEE1E9"/>
      <mask id="mask2044" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
        <path d="M5.62084 35.4876C1.70013 33.1631 1 15.1278 1 15.1278C13.0282 4.4999 10.0176 3.81377 17.649 1.2373C25.2804 -1.33917 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 28.9771 33.8633 28.515 34.5634L24.7584 35.2796C24.1754 35.3908 23.6244 34.9726 23.5746 34.3812L22.6677 23.6045C18.5662 23.051 16.2699 23.0361 12.1677 23.6045L10.7781 34.6128C10.715 35.1127 10.2899 35.4876 9.78602 35.4876H5.62084Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2044)">
        <path d="M5.62084 35.4876C1.70013 33.1631 1 15.1278 1 15.1278C13.0282 4.4999 10.0176 3.81377 17.649 1.2373C25.2804 -1.33917 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 28.9771 33.8633 28.515 34.5634L24.7584 35.2796C24.1754 35.3908 23.6244 34.9726 23.5746 34.3812L22.6677 23.6045C18.5662 23.051 16.2699 23.0361 12.1677 23.6045L10.7781 34.6128C10.715 35.1127 10.2899 35.4876 9.78602 35.4876H5.62084Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M28.0532 47H7.05322V21.452C15.2542 19.5258 19.8522 19.5062 28.0532 21.452V47Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M28.3422 34.2104L28.0482 46.3507C26.984 57.3567 26.3679 64.1339 25.9198 69.1188C25.3737 75.2099 24.9536 80.0408 22.0971 85.6558C20.3468 89.1145 16.5801 94.9956 13.8916 94.3234C12.9254 94.0854 12.0993 92.9232 11.0911 89.0025C9.91379 83.9584 9.48446 78.7686 9.81686 73.5996C10.0099 67.0112 9.79015 60.417 9.15874 53.856C8.70425 49.7269 7.96976 45.6334 6.96034 41.6038L5.44806 35.1346" fill="#DEE1E9"/>
      <path d="M0.827209 14.7748C0.827209 14.7748 1.52734 32.8101 5.44805 35.1345C9.36877 37.459 14.2417 40.2175 17.9383 39.5173C21.7188 38.4428 25.253 36.64 28.3422 34.2104C28.8043 33.5102 34.1253 17.5613 34.1253 17.5613C34.1253 17.5613 25.1076 -1.6922 17.4762 0.884274C9.84486 3.46074 12.8554 4.14687 0.827209 14.7748Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M23.0072 30.4299C24.8695 31.8302 22.4611 36.423 20.9208 46.6169C20.3925 50.2191 20.0466 53.8457 19.8846 57.4829C19.5625 63.4059 19.8846 64.3301 19.5345 71.9335C19.3933 75.2191 19.0472 78.4928 18.4983 81.7353C17.9942 84.6758 15.3876 90.9956 15.1076 91.0096C14.5475 91.0096 15.9219 82.8506 16.1879 78.3887C16.0668 76.6077 16.0668 74.8206 16.1879 73.0397C16.3754 68.8376 16.2865 64.6278 15.9219 60.4374C15.4458 52.89 15.1937 49.1093 13.8355 45.2866C12.4772 41.4639 12.1132 37.7392 10.8389 34.0846C10.5869 33.3845 9.98477 31.7041 10.7269 30.976C11.469 30.2479 12.7573 30.892 14.2976 31.2001C19.1845 32.1522 21.4249 29.3517 23.0072 30.4299Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M26.2075 70.0231C19.8373 73.4295 16.2801 73.2743 9.94771 70.0231" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M28.3473 34.958L28.0533 47.0982C26.9891 58.1042 26.3729 64.8815 25.9249 69.8664C25.3788 75.9575 24.9587 80.7884 22.1022 86.4034C20.3518 89.862 16.5852 95.7431 13.8967 95.071C12.9305 94.8329 12.1043 93.6707 11.0962 89.75C9.91886 84.7059 9.48952 79.5162 9.82192 74.3472C10.015 67.7587 9.79521 61.1646 9.1638 54.6036C8.70932 50.4744 7.97482 46.3809 6.9654 42.3514L5.45312 35.8822" fill="#DEE1E9"/>
      <mask id="mask2244" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2244)">
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M18.6923 16C19.7544 16 21 10.7018 21 1.10345L19.0769 -0.758619L16 -2C16 7.59833 17.6302 16 18.6923 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M23.2883 15.318C25.2465 17.0904 22.7141 22.9041 21.0946 35.8075C20.5391 40.3672 20.1755 44.9578 20.0051 49.5617C19.6665 57.0592 20.0051 58.229 19.637 67.8534C19.4886 72.0124 19.1247 76.1561 18.5476 80.2605C18.0175 83.9827 15.2768 91.9823 14.9824 92C14.3935 92 15.8385 81.6723 16.1183 76.0244C15.9909 73.7701 15.9909 71.5079 16.1183 69.2536C16.3154 63.9346 16.222 58.6058 15.8385 53.3016C15.338 43.7481 15.073 38.9625 13.6448 34.1237C12.2167 29.2849 11.8339 24.5702 10.4941 19.9441C10.2291 19.0578 9.59605 16.9309 10.3764 16.0092C11.1567 15.0876 12.5112 15.9029 14.1307 16.2928C19.269 17.4981 21.6246 13.9532 23.2883 15.318Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="14" cy="92.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M28.3473 34.958L28.0533 47.0982C26.9891 58.1042 26.3729 64.8815 25.9249 69.8664C25.3788 75.9575 24.9587 80.7884 22.1022 86.4034C20.3518 89.862 16.5852 95.7431 13.8967 95.071C12.9305 94.8329 12.1043 93.6707 11.0962 89.75C9.91886 84.7059 9.48952 79.5162 9.82192 74.3472C10.015 67.7587 9.79521 61.1646 9.1638 54.6036C8.70932 50.4744 7.97482 46.3809 6.9654 42.3514L5.45312 35.8822" fill="#DEE1E9"/>
      <mask id="mask2344" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2344)">
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M18.6923 16C19.7544 16 21 10.7018 21 1.10345L19.0769 -0.758619L16 -2C16 7.59833 17.6302 16 18.6923 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M23.2883 15.318C25.2465 17.0904 22.7141 22.9041 21.0946 35.8075C20.5391 40.3672 20.1755 44.9578 20.0051 49.5617C19.6665 57.0592 20.0051 58.229 19.637 67.8534C19.4886 72.0124 19.1247 76.1561 18.5476 80.2605C18.0175 83.9827 15.2768 91.9823 14.9824 92C14.3935 92 15.8385 81.6723 16.1183 76.0244C15.9909 73.7701 15.9909 71.5079 16.1183 69.2536C16.3154 63.9346 16.222 58.6058 15.8385 53.3016C15.338 43.7481 15.073 38.9625 13.6448 34.1237C12.2167 29.2849 11.8339 24.5702 10.4941 19.9441C10.2291 19.0578 9.59605 16.9309 10.3764 16.0092C11.1567 15.0876 12.5112 15.9029 14.1307 16.2928C19.269 17.4981 21.6246 13.9532 23.2883 15.318Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="14" cy="92.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2344)">
        <path d="M14 89L14.8229 92.7391L17.8567 90.4037L16.0837 93.797L19.9088 93.9581L16.3694 95.4178L19.1962 98L15.5465 96.8431L16.0521 100.638L14 97.406L11.9479 100.638L12.4535 96.8431L8.80385 98L11.6306 95.4178L8.09115 93.9581L11.9163 93.797L10.1433 90.4037L13.1771 92.7391L14 89Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2344" x="7.89113" y="88.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M28.3473 34.958L28.0533 47.0982C26.9891 58.1042 26.3729 64.8815 25.9249 69.8664C25.3788 75.9575 24.9587 80.7884 22.1022 86.4034C20.3518 89.862 16.5852 95.7431 13.8967 95.071C12.9305 94.8329 12.1043 93.6707 11.0962 89.75C9.91886 84.7059 9.48952 79.5162 9.82192 74.3472C10.015 67.7587 9.79521 61.1646 9.1638 54.6036C8.70932 50.4744 7.97482 46.3809 6.9654 42.3514L5.45312 35.8822" fill="#DEE1E9"/>
      <mask id="mask2344" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2344)">
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M18.6923 16C19.7544 16 21 10.7018 21 1.10345L19.0769 -0.758619L16 -2C16 7.59833 17.6302 16 18.6923 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M23.2883 15.318C25.2465 17.0904 22.7141 22.9041 21.0946 35.8075C20.5391 40.3672 20.1755 44.9578 20.0051 49.5617C19.6665 57.0592 20.0051 58.229 19.637 67.8534C19.4886 72.0124 19.1247 76.1561 18.5476 80.2605C18.0175 83.9827 15.2768 91.9823 14.9824 92C14.3935 92 15.8385 81.6723 16.1183 76.0244C15.9909 73.7701 15.9909 71.5079 16.1183 69.2536C16.3154 63.9346 16.222 58.6058 15.8385 53.3016C15.338 43.7481 15.073 38.9625 13.6448 34.1237C12.2167 29.2849 11.8339 24.5702 10.4941 19.9441C10.2291 19.0578 9.59605 16.9309 10.3764 16.0092C11.1567 15.0876 12.5112 15.9029 14.1307 16.2928C19.269 17.4981 21.6246 13.9532 23.2883 15.318Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="14" cy="92.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2344)">
        <path d="M14 89L14.8229 92.7391L17.8567 90.4037L16.0837 93.797L19.9088 93.9581L16.3694 95.4178L19.1962 98L15.5465 96.8431L16.0521 100.638L14 97.406L11.9479 100.638L12.4535 96.8431L8.80385 98L11.6306 95.4178L8.09115 93.9581L11.9163 93.797L10.1433 90.4037L13.1771 92.7391L14 89Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2344" x="7.89113" y="88.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M28.3473 34.7571L28.0533 46.8973C26.9891 57.9033 26.3729 64.6805 25.9249 69.6655C25.3788 75.7566 24.9587 80.5874 22.1022 86.2025C20.3518 89.6611 16.5852 95.5422 13.8967 94.8701C12.9305 94.632 12.1043 93.4698 11.0962 89.5491C9.91886 84.505 9.48952 79.3152 9.82192 74.1463C10.015 67.5578 9.79521 60.9637 9.1638 54.4027C8.70932 50.2735 7.97482 46.18 6.9654 42.1504L5.45312 35.6812" fill="#DEE1E9"/>
      <mask id="mask2544" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M1 14.9269C1 14.9269 1.70013 32.9622 5.62084 35.2866C9.54156 37.6111 14.4144 40.3696 18.1111 39.6694C21.8916 38.5949 25.4258 36.7921 28.515 34.3625C28.9771 33.6623 34.2981 17.7134 34.2981 17.7134C34.2981 17.7134 25.2804 -1.5401 17.649 1.03637C10.0176 3.61284 13.0282 4.29897 1 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2544)">
        <path d="M1 14.9269C1 14.9269 1.70013 32.9622 5.62084 35.2866C9.54156 37.6111 14.4144 40.3696 18.1111 39.6694C21.8916 38.5949 25.4258 36.7921 28.515 34.3625C28.9771 33.6623 34.2981 17.7134 34.2981 17.7134C34.2981 17.7134 25.2804 -1.5401 17.649 1.03637C10.0176 3.61284 13.0282 4.29897 1 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M18.6923 15.7991C19.7544 15.7991 21 10.5008 21 0.902521L19.0769 -0.959547L16 -2.20093C16 7.3974 17.6302 15.7991 18.6923 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M23.2883 15.117C25.2465 16.8895 22.7141 22.7031 21.0946 35.6066C20.5391 40.1663 20.1755 44.7569 20.0051 49.3608C19.6665 56.8582 20.0051 58.0281 19.637 67.6525C19.4886 71.8114 19.1247 75.9552 18.5476 80.0596C18.0175 83.7818 15.2768 91.7813 14.9824 91.7991C14.3935 91.7991 15.8385 81.4714 16.1183 75.8235C15.9909 73.5691 15.9909 71.307 16.1183 69.0527C16.3154 63.7337 16.222 58.4049 15.8385 53.1006C15.338 43.5471 15.073 38.7615 13.6448 33.9227C12.2167 29.084 11.8339 24.3692 10.4941 19.7431C10.2291 18.8569 9.59605 16.73 10.3764 15.8083C11.1567 14.8866 12.5112 15.702 14.1307 16.0919C19.269 17.2972 21.6246 13.7523 23.2883 15.117Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="14" cy="92" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2644" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="95">
        <path d="M28.515 34.5635L28.221 46.7037C27.1568 57.7097 26.5407 64.4869 26.0926 69.4719C25.5465 75.563 25.1264 80.3938 22.2699 86.0089C20.5196 89.4675 16.7529 95.3486 14.0644 94.6765C13.0982 94.4384 12.2721 93.2762 11.2639 89.3555C10.0866 84.3114 9.65725 79.1216 9.98965 73.9527C10.1827 67.3642 9.96294 60.7701 9.33153 54.2091C8.87704 50.0799 8.14255 45.9864 7.13313 41.9568L5.62085 35.4876" fill="#DEE1E9"/>
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2644)">
        <path d="M28.515 34.5635L28.221 46.7037C27.1568 57.7097 26.5407 64.4869 26.0926 69.4719C25.5465 75.563 25.1264 80.3938 22.2699 86.0089C20.5196 89.4675 16.7529 95.3486 14.0644 94.6765C13.0982 94.4384 12.2721 93.2762 11.2639 89.3555C10.0866 84.3114 9.65725 79.1216 9.98965 73.9527C10.1827 67.3642 9.96294 60.7701 9.33153 54.2091C8.87704 50.0799 8.14255 45.9864 7.13313 41.9568L5.62085 35.4876" fill="#DEE1E9"/>
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M11.9741 24.6762H0.0529232C2.21498 30.046 2.57199 33.3063 2.27545 39.0446C2.26427 39.2609 2.51364 39.3955 2.70387 39.2689C3.70794 38.601 6.06614 36.7849 6.63778 34.1432C7.33025 30.9432 9.33345 30.1661 9.54606 30.092C9.55902 30.0875 9.57061 30.083 9.58299 30.0773L10.7659 29.5307C10.9105 29.4639 10.9691 29.3014 10.8968 29.1678L10.3679 28.1901C10.3231 28.1074 10.3272 28.0092 10.3787 27.9299L12.2251 25.0858C12.3421 24.9056 12.2016 24.6762 11.9741 24.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.04897 38.155C4.10995 37.3071 5.51793 35.8702 5.92035 34.0106C6.66356 30.5761 8.83953 29.6201 9.2725 29.4648L10.0461 29.1073L9.71349 28.4925C9.55687 28.203 9.57114 27.8596 9.75132 27.5821L11.1988 25.3524H1.09621C2.87294 30.0171 3.24894 33.2129 3.04897 38.155ZM9.25579 29.4725C9.25565 29.4726 9.25593 29.4724 9.25579 29.4725V29.4725ZM3.1313 39.8178C2.40244 40.3027 1.50671 39.7463 1.54464 39.0123C1.83745 33.3462 1.48824 30.18 -0.632782 24.9121L-1 24H11.9741C12.7703 24 13.2621 24.8028 12.8524 25.4338L11.1301 28.0869L11.5512 28.8654C11.8042 29.333 11.5991 29.9017 11.0931 30.1355L9.91021 30.6822C9.86949 30.701 9.83364 30.7145 9.80412 30.7248C9.76753 30.7376 7.98671 31.3577 7.35523 34.2758C6.72573 37.1849 4.16235 39.1319 3.1313 39.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask144" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="95">
        <path d="M28.515 34.5635L28.221 46.7037C27.1568 57.7097 26.5407 64.4869 26.0926 69.4719C25.5465 75.563 25.1264 80.3938 22.2699 86.0089C20.5196 89.4675 16.7529 95.3486 14.0644 94.6765C13.0982 94.4384 12.2721 93.2762 11.2639 89.3555C10.0866 84.3114 9.65725 79.1216 9.98965 73.9527C10.1827 67.3642 9.96294 60.7701 9.33153 54.2091C8.87704 50.0799 8.14255 45.9864 7.13313 41.9568L5.62085 35.4876" fill="#DEE1E9"/>
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask144)">
        <path d="M28.515 34.5635L28.221 46.7037C27.1568 57.7097 26.5407 64.4869 26.0926 69.4719C25.5465 75.563 25.1264 80.3938 22.2699 86.0089C20.5196 89.4675 16.7529 95.3486 14.0644 94.6765C13.0982 94.4384 12.2721 93.2762 11.2639 89.3555C10.0866 84.3114 9.65725 79.1216 9.98965 73.9527C10.1827 67.3642 9.96294 60.7701 9.33153 54.2091C8.87704 50.0799 8.14255 45.9864 7.13313 41.9568L5.62085 35.4876" fill="#DEE1E9"/>
        <path d="M1 15.1278C1 15.1278 1.70013 33.1631 5.62084 35.4876C9.54156 37.812 14.4144 40.5705 18.1111 39.8704C21.8916 38.7958 25.4258 36.993 28.515 34.5634C28.9771 33.8633 34.2981 17.9143 34.2981 17.9143C34.2981 17.9143 25.2804 -1.33917 17.649 1.2373C10.0176 3.81377 13.0282 4.4999 1 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M9.86628 26.5H1.71952C3.19704 30.4705 3.44101 32.8811 3.23836 37.1241C3.23072 37.2841 3.40114 37.3836 3.53113 37.29C4.2173 36.7961 5.82886 35.4532 6.21952 33.5C6.69274 31.1339 8.0617 30.5593 8.20699 30.5045C8.21585 30.5012 8.22377 30.4978 8.23223 30.4936L9.04063 30.0894C9.13943 30.04 9.17947 29.9199 9.13008 29.8211L8.76862 29.0982C8.73804 29.037 8.74082 28.9645 8.776 28.9058L10.0378 26.8029C10.1178 26.6696 10.0217 26.5 9.86628 26.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76699 36.4664C4.49205 35.8394 5.45424 34.777 5.72925 33.4019C6.23715 30.8625 7.72418 30.1555 8.02006 30.0407L8.54872 29.7764L8.32143 29.3218C8.21439 29.1077 8.22415 28.8538 8.34728 28.6486L9.33644 27H2.4325C3.64669 30.4491 3.90365 32.8121 3.76699 36.4664ZM8.00864 30.0464C8.00854 30.0465 8.00874 30.0464 8.00864 30.0464V30.0464ZM3.82326 37.6958C3.32516 38.0544 2.71303 37.643 2.73895 37.1003C2.93905 32.9106 2.70041 30.5695 1.25093 26.6744L0.999982 26H9.8663C10.4104 26 10.7465 26.5936 10.4665 27.0601L9.2895 29.0219L9.57731 29.5975C9.7502 29.9433 9.61005 30.3638 9.26426 30.5367L8.45586 30.9409C8.42803 30.9548 8.40354 30.9648 8.38336 30.9724C8.35836 30.9818 7.14137 31.4403 6.70983 33.5981C6.27964 35.749 4.52786 37.1887 3.82326 37.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M28.3473 34.7571L28.0533 46.8973C26.9891 57.9033 26.3729 64.6805 25.9249 69.6655C25.3788 75.7566 24.9587 80.5874 22.1022 86.2025C20.3518 89.6611 16.5852 95.5422 13.8967 94.8701C12.9305 94.632 12.1043 93.4698 11.0962 89.5491C9.91886 84.505 9.48952 79.3152 9.82192 74.1463C10.015 67.5578 9.79521 60.9637 9.1638 54.4027C8.70932 50.2735 7.97482 46.18 6.9654 42.1504L5.45312 35.6812" fill="#DEE1E9"/>
      <mask id="mask244" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M1 14.9269C1 14.9269 1.70013 32.9622 5.62084 35.2866C9.54156 37.6111 14.4144 40.3696 18.1111 39.6694C21.8916 38.5949 25.4258 36.7921 28.515 34.3625C28.9771 33.6623 34.2981 17.7134 34.2981 17.7134C34.2981 17.7134 25.2804 -1.5401 17.649 1.03637C10.0176 3.61284 13.0282 4.29897 1 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask244)">
        <path d="M1 14.9269C1 14.9269 1.70013 32.9622 5.62084 35.2866C9.54156 37.6111 14.4144 40.3696 18.1111 39.6694C21.8916 38.5949 25.4258 36.7921 28.515 34.3625C28.9771 33.6623 34.2981 17.7134 34.2981 17.7134C34.2981 17.7134 25.2804 -1.5401 17.649 1.03637C10.0176 3.61284 13.0282 4.29897 1 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M18.6923 15.7991C19.7544 15.7991 21 10.5008 21 0.902521L19.0769 -0.959547L16 -2.20093C16 7.3974 17.6302 15.7991 18.6923 15.7991Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M23.2883 15.117C25.2465 16.8895 22.7141 22.7031 21.0946 35.6066C20.5391 40.1663 20.1755 44.7569 20.0051 49.3608C19.6665 56.8582 20.0051 58.0281 19.637 67.6525C19.4886 71.8114 19.1247 75.9552 18.5476 80.0596C18.0175 83.7818 15.2768 91.7813 14.9824 91.7991C14.3935 91.7991 15.8385 81.4714 16.1183 75.8235C15.9909 73.5691 15.9909 71.307 16.1183 69.0527C16.3154 63.7337 16.222 58.4049 15.8385 53.1006C15.338 43.5471 15.073 38.7615 13.6448 33.9227C12.2167 29.084 11.8339 24.3692 10.4941 19.7431C10.2291 18.8569 9.59605 16.73 10.3764 15.8083C11.1567 14.8866 12.5112 15.702 14.1307 16.0919C19.269 17.2972 21.6246 13.7523 23.2883 15.117Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M28.3473 34.7571L28.0533 46.8973C26.9891 57.9033 26.3729 64.6805 25.9249 69.6655C25.3788 75.7566 24.9587 80.5874 22.1022 86.2025C20.3518 89.6611 16.5852 95.5422 13.8967 94.8701C12.9305 94.632 12.1043 93.4698 11.0962 89.5491C9.91886 84.505 9.48952 79.3152 9.82192 74.1463C10.015 67.5578 9.79521 60.9637 9.1638 54.4027C8.70932 50.2735 7.97482 46.18 6.9654 42.1504L5.45312 35.6812" fill="#DEE1E9"/>
      <mask id="mask344" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="41">
        <path d="M1 14.9269C1 14.9269 1.70013 32.9622 5.62084 35.2866C9.54156 37.6111 14.4144 40.3696 18.1111 39.6694C21.8916 38.5949 25.4258 36.7921 28.515 34.3625C28.9771 33.6623 34.2981 17.7134 34.2981 17.7134C34.2981 17.7134 25.2804 -1.5401 17.649 1.03637C10.0176 3.61284 13.0282 4.29897 1 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask344)">
        <path d="M1 14.9269C1 14.9269 1.70013 32.9622 5.62084 35.2866C9.54156 37.6111 14.4144 40.3696 18.1111 39.6694C21.8916 38.5949 25.4258 36.7921 28.515 34.3625C28.9771 33.6623 34.2981 17.7134 34.2981 17.7134C34.2981 17.7134 25.2804 -1.5401 17.649 1.03637C10.0176 3.61284 13.0282 4.29897 1 14.9269Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M18.6923 15.7991C19.7544 15.7991 21 10.5008 21 0.902521L19.0769 -0.959547L16 -2.20093C16 7.3974 17.6302 15.7991 18.6923 15.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M28.3473 34.5635L28.0533 46.7037C26.9891 57.7097 26.3729 64.4869 25.9249 69.4719C25.3788 75.563 24.9587 80.3938 22.1022 86.0089C20.3518 89.4675 16.5852 95.3486 13.8967 94.6765C12.9305 94.4384 12.1043 93.2762 11.0962 89.3555C9.91886 84.3114 9.48952 79.1216 9.82192 73.9527C10.015 67.3642 9.79521 60.7701 9.1638 54.2091C8.70932 50.0799 7.97482 45.9864 6.9654 41.9568L5.45312 35.4876" fill="#DEE1E9"/>
      <path d="M0.832275 15.1278C0.832275 15.1278 1.5324 33.1631 5.45312 35.4876C9.37384 37.812 14.2467 40.5705 17.9434 39.8704C21.7239 38.7958 25.258 36.993 28.3473 34.5634C28.8094 33.8633 34.1304 17.9143 34.1304 17.9143C34.1304 17.9143 25.1127 -1.33917 17.4813 1.2373C9.84992 3.81377 12.8605 4.4999 0.832275 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M23 3.60449L24.8667 6.10535L26.5 4.60965V7.92369L29.0622 7.35578L27.6667 9.10638L30 11.1071L27.6667 13.1078L29.0622 14.8584L26.7333 14.1081L26.5 17.6045L24.2501 16.1075L23.4667 17.1091L21.7499 16.1075L19.5 17.6045L18.8 15.6086L16.9378 14.8584L17.4 12.1074L16 11.1071L18.3333 9.60655L16.9378 7.35578L18.8 7.1057L19.5 4.60965L22.5333 6.60552L23 3.60449Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M28.3473 34.5635L28.0533 46.7037C26.9891 57.7097 26.3729 64.4869 25.9249 69.4719C25.3788 75.563 24.9587 80.3938 22.1022 86.0089C20.3518 89.4675 16.5852 95.3486 13.8967 94.6765C12.9305 94.4384 12.1043 93.2762 11.0962 89.3555C9.91886 84.3114 9.48952 79.1216 9.82192 73.9527C10.015 67.3642 9.79521 60.7701 9.1638 54.2091C8.70932 50.0799 7.97482 45.9864 6.9654 41.9568L5.45312 35.4876" fill="#DEE1E9"/>
      <path d="M0.832275 15.1278C0.832275 15.1278 1.5324 33.1631 5.45312 35.4876C9.37384 37.812 14.2467 40.5705 17.9434 39.8704C21.7239 38.7958 25.258 36.993 28.3473 34.5634C28.8094 33.8633 34.1304 17.9143 34.1304 17.9143C34.1304 17.9143 25.1127 -1.33917 17.4813 1.2373C9.84992 3.81377 12.8605 4.4999 0.832275 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M23 3.60449L24.6 5.74809L26 4.46605V7.30666L28.1962 6.81988L27 8.3204L29 10.0353L27 11.7501L28.1962 13.2507L26.2 12.6076L26 15.6045L24.0715 14.3213L23.4 15.1799L21.9285 14.3213L20 15.6045L19.4 13.8937L17.8038 13.2507L18.2 10.8927L17 10.0353L19 8.74912L17.8038 6.81988L19.4 6.60552L20 4.46605L22.6 6.1768L23 3.60449Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M28.3473 34.5635L28.0533 46.7037C26.9891 57.7097 26.3729 64.4869 25.9249 69.4719C25.3788 75.563 24.9587 80.3938 22.1022 86.0089C20.3518 89.4675 16.5852 95.3486 13.8967 94.6765C12.9305 94.4384 12.1043 93.2762 11.0962 89.3555C9.91886 84.3114 9.48952 79.1216 9.82192 73.9527C10.015 67.3642 9.79521 60.7701 9.1638 54.2091C8.70932 50.0799 7.97482 45.9864 6.9654 41.9568L5.45312 35.4876" fill="#DEE1E9"/>
      <path d="M0.832275 15.1278C0.832275 15.1278 1.5324 33.1631 5.45312 35.4876C9.37384 37.812 14.2467 40.5705 17.9434 39.8704C21.7239 38.7958 25.258 36.993 28.3473 34.5634C28.8094 33.8633 34.1304 17.9143 34.1304 17.9143C34.1304 17.9143 25.1127 -1.33917 17.4813 1.2373C9.84992 3.81377 12.8605 4.4999 0.832275 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path d="M25.5 5.60449L26.4333 6.85492L27.25 6.10707V7.76409L28.5311 7.48014L27.8333 8.35544L29 9.35578L27.8333 10.3561L28.5311 11.2314L27.3667 10.8563L27.25 12.6045L26.125 11.856L25.7333 12.3568L24.875 11.856L23.75 12.6045L23.4 11.6066L22.4689 11.2314L22.7 9.85595L22 9.35578L23.1667 8.60552L22.4689 7.48014L23.4 7.35509L23.75 6.10707L25.2667 7.10501L25.5 5.60449Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M13.4543 75.6565L15.538 76.9263H19.0217L21.1217 75.6565L19.5101 74.11L21.5938 72.5635L19.7054 71.017L22.0008 69.324L20.1775 67.7775L22.4729 66.3775L20.4543 64.9612L22.4729 63.3496L20.7799 61.803L23.0101 60.3868L21.2519 58.9217L23.5473 57.3751L21.3985 55.8286L23.5473 54.217L22.0008 52.8659L24.0845 51.3193L22.2612 49.838L24.345 48.2914L22.538 46.5496L23.5473 44.8728V30.6124L21.6589 23.2868H15.538" fill="#8889BE"/>
      <path d="M13.4543 75.6565L15.538 76.9263H19.0217L21.1217 75.6565L19.5101 74.11L21.5938 72.5635L19.7054 71.017L22.0008 69.324L20.1775 67.7775L22.4729 66.3775L20.4543 64.9612L22.4729 63.3496L20.7799 61.803L23.0101 60.3868L21.2519 58.9217L23.5473 57.3751L21.3985 55.8286L23.5473 54.217L22.0008 52.8659L24.0845 51.3193L22.2612 49.838L24.345 48.2914L22.538 46.5496L23.5473 44.8728V30.6124L21.6589 23.2868H15.538" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M20.8616 75.6565L18.7778 76.9263H15.3104L13.1941 75.6565L14.822 74.11L12.722 72.5635L14.6104 71.017L12.3313 69.324L14.1383 67.7775L11.8593 66.3775L13.8779 64.9612L11.8593 63.3496L13.536 61.803L11.322 60.3868L13.0639 58.9217L10.7848 57.3751L12.9337 55.8286L10.7848 54.217L12.3313 52.8659L10.2476 51.3193L12.0546 49.838L9.97089 48.2914L11.7941 46.5496L10.7848 44.8728V30.6124L12.6569 23.2868H16.5639" fill="#8889BE"/>
      <path d="M20.8616 75.6565L18.7778 76.9263H15.3104L13.1941 75.6565L14.822 74.11L12.722 72.5635L14.6104 71.017L12.3313 69.324L14.1383 67.7775L11.8593 66.3775L13.8779 64.9612L11.8593 63.3496L13.536 61.803L11.322 60.3868L13.0639 58.9217L10.7848 57.3751L12.9337 55.8286L10.7848 54.217L12.3313 52.8659L10.2476 51.3193L12.0546 49.838L9.97089 48.2914L11.7941 46.5496L10.7848 44.8728V30.6124L12.6569 23.2868H16.5639" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask544" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="37">
        <path d="M5.62084 35.9588C1.70013 33.6343 1 15.599 1 15.599C13.0282 4.97109 10.0176 4.28496 17.649 1.70849C25.2804 -0.867979 34.2981 18.3855 34.2981 18.3855C34.2981 18.3855 28.9771 34.3345 28.515 35.0346L24.7584 35.7508C24.1754 35.862 23.6244 35.4438 23.5746 34.8524L22.6677 24.0757C18.5662 23.5222 16.2699 23.5073 12.1677 24.0757L10.7781 35.084C10.715 35.5839 10.2899 35.9588 9.78602 35.9588H5.62084Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask544)">
        <path d="M5.62084 35.9588C1.70013 33.6343 1 15.599 1 15.599C13.0282 4.97109 10.0176 4.28496 17.649 1.70849C25.2804 -0.867979 34.2981 18.3855 34.2981 18.3855C34.2981 18.3855 28.9771 34.3345 28.515 35.0346L24.7584 35.7508C24.1754 35.862 23.6244 35.4438 23.5746 34.8524L22.6677 24.0757C18.5662 23.5222 16.2699 23.5073 12.1677 24.0757L10.7781 35.084C10.715 35.5839 10.2899 35.9588 9.78602 35.9588H5.62084Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M28.0532 47.4712H7.05322V21.9232C15.2542 19.997 19.8522 19.9774 28.0532 21.9232V47.4712Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M13.4543 74.948L15.538 76.2178H19.0217L21.1217 74.948L19.5101 73.4015L21.5938 71.855L19.7054 70.3085L22.0008 68.6155L20.1775 67.069L22.4729 65.669L20.4543 64.2527L22.4729 62.6411L20.7799 61.0945L23.0101 59.6783L21.2519 58.2132L23.5473 56.6667L21.3985 55.1201L23.5473 53.5085L22.0008 52.1574L24.0845 50.6109L22.2612 49.1295L24.345 47.5829L22.538 45.8411L23.5473 44.1644V29.9039L21.6589 22.5784H15.538" fill="#8889BE"/>
      <path d="M13.4543 74.948L15.538 76.2178H19.0217L21.1217 74.948L19.5101 73.4015L21.5938 71.855L19.7054 70.3085L22.0008 68.6155L20.1775 67.069L22.4729 65.669L20.4543 64.2527L22.4729 62.6411L20.7799 61.0945L23.0101 59.6783L21.2519 58.2132L23.5473 56.6667L21.3985 55.1201L23.5473 53.5085L22.0008 52.1574L24.0845 50.6109L22.2612 49.1295L24.345 47.5829L22.538 45.8411L23.5473 44.1644V29.9039L21.6589 22.5784H15.538" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M20.8616 74.948L18.7778 76.2178H15.3104L13.1941 74.948L14.822 73.4015L12.722 71.855L14.6104 70.3085L12.3313 68.6155L14.1383 67.069L11.8593 65.669L13.8779 64.2527L11.8593 62.6411L13.536 61.0945L11.322 59.6783L13.0639 58.2132L10.7848 56.6667L12.9337 55.1201L10.7848 53.5085L12.3313 52.1574L10.2476 50.6109L12.0546 49.1295L9.97089 47.5829L11.7941 45.8411L10.7848 44.1644V29.9039L12.6569 22.5784H16.5639" fill="#8889BE"/>
      <path d="M20.8616 74.948L18.7778 76.2178H15.3104L13.1941 74.948L14.822 73.4015L12.722 71.855L14.6104 70.3085L12.3313 68.6155L14.1383 67.069L11.8593 65.669L13.8779 64.2527L11.8593 62.6411L13.536 61.0945L11.322 59.6783L13.0639 58.2132L10.7848 56.6667L12.9337 55.1201L10.7848 53.5085L12.3313 52.1574L10.2476 50.6109L12.0546 49.1295L9.97089 47.5829L11.7941 45.8411L10.7848 44.1644V29.9039L12.6569 22.5783H16.5639" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask544" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="35" height="36">
        <path d="M5.62084 35.2503C1.70013 32.9258 1 14.8905 1 14.8905C13.0282 4.26259 10.0176 3.57647 17.649 0.999996C25.2804 -1.57647 34.2981 17.677 34.2981 17.677C34.2981 17.677 28.9771 33.626 28.515 34.3261L24.7584 35.0423C24.1754 35.1535 23.6244 34.7353 23.5746 34.1439L22.6677 23.3672C18.5662 22.8137 16.2699 22.7988 12.1677 23.3672L10.7781 34.3755C10.715 34.8754 10.2899 35.2503 9.78602 35.2503H5.62084Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask544)">
        <path d="M5.62084 35.2503C1.70013 32.9258 1 14.8905 1 14.8905C13.0282 4.26259 10.0176 3.57647 17.649 0.999996C25.2804 -1.57647 34.2981 17.677 34.2981 17.677C34.2981 17.677 28.9771 33.626 28.515 34.3261L24.7584 35.0423C24.1754 35.1535 23.6244 34.7353 23.5746 34.1439L22.6677 23.3672C18.5662 22.8137 16.2699 22.7988 12.1677 23.3672L10.7781 34.3755C10.715 34.8754 10.2899 35.2503 9.78602 35.2503H5.62084Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M0 0.259766H21V5.25977H0V0.259766Z" fill="#838AA2"/>
      <path d="M22 0.259766H43V5.25977H22V0.259766Z" fill="#838AA2"/>
      <path d="M32.221 19.6777V48.7769C31.1568 59.7829 30.5407 66.5601 30.0926 71.545C29.5465 77.6362 29.1264 82.467 26.2699 88.0821C24.5196 91.5407 20.7529 97.4218 18.0644 96.7496C17.0982 96.5116 16.2721 95.3494 15.2639 91.4287C14.0866 86.3846 13.6572 81.1948 13.9896 76.0259C14.1827 69.4374 13.9629 62.8433 13.3315 56.2822C12.877 52.1531 12.1425 48.0596 11.1331 44.03L5.66772 19.6777" fill="#DEE1E9"/>
      <path d="M9.62084 37.5608C5.70013 35.2364 5 17.2011 5 17.2011C17.0282 6.57314 14.0176 5.88701 21.649 3.31054C29.2804 0.734072 38.2981 19.9876 38.2981 19.9876C38.2981 19.9876 32.9771 35.9365 32.515 36.6366L28.7584 37.3529C28.1754 37.464 27.6244 37.0458 27.5746 36.4544L26.6677 25.6777C22.5662 25.1243 20.2699 25.1093 16.1677 25.6777L14.7781 36.686C14.715 37.1859 14.2899 37.5608 13.786 37.5608H9.62084Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M28.1898 34.5635L27.8958 46.7037C26.8316 57.7097 26.2155 64.4869 25.7674 69.4719C25.2213 75.563 24.8012 80.3938 21.9447 86.0089C20.1944 89.4675 16.4277 95.3486 13.7392 94.6765C12.773 94.4384 11.9469 93.2762 10.9387 89.3555C9.76138 84.3114 9.33205 79.1216 9.66445 73.9527C9.85754 67.3642 9.63774 60.7701 9.00633 54.2091C8.55185 50.0799 7.81735 45.9864 6.80793 41.9568L5.29565 35.4876" fill="#DEE1E9"/>
      <path d="M0.674805 15.1278C0.674805 15.1278 1.37493 33.1631 5.29565 35.4876C9.21636 37.812 14.0893 40.5705 17.7859 39.8704C21.5664 38.7958 25.1006 36.993 28.1898 34.5634C28.6519 33.8633 33.9729 17.9143 33.9729 17.9143C33.9729 17.9143 24.9552 -1.33917 17.3238 1.2373C9.69245 3.81377 12.703 4.4999 0.674805 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M22.8547 30.7825C24.7171 32.1827 22.3086 36.7756 20.7684 46.9694C20.2401 50.5716 19.8942 54.1983 19.7322 57.8354C19.4101 63.7585 19.7322 64.6826 19.3821 72.286C19.2409 75.5717 18.8948 78.8453 18.3459 82.0878C17.8418 85.0284 15.2352 91.3481 14.9552 91.3621C14.3951 91.3621 15.7695 83.2031 16.0355 78.7412C15.9144 76.9603 15.9144 75.1732 16.0355 73.3922C16.223 69.1902 16.1341 64.9803 15.7695 60.7899C15.2934 53.2426 15.0413 49.4619 13.6831 45.6392C12.3248 41.8165 11.9608 38.0918 10.6865 34.4371C10.4345 33.737 9.83237 32.0567 10.5745 31.3286C11.3166 30.6004 12.6049 31.2445 14.1452 31.5526C19.0321 32.5048 21.2725 29.7043 22.8547 30.7825Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M28.1898 34.5635L27.8958 46.7037C26.8316 57.7097 26.2155 64.4869 25.7674 69.4719C25.2213 75.563 24.8012 80.3938 21.9447 86.0089C20.1944 89.4675 16.4277 95.3486 13.7392 94.6765C12.773 94.4384 11.9469 93.2762 10.9387 89.3555C9.76138 84.3114 9.33205 79.1216 9.66445 73.9527C9.85754 67.3642 9.63774 60.7701 9.00633 54.2091C8.55185 50.0799 7.81735 45.9864 6.80793 41.9568L5.29565 35.4876" fill="#DEE1E9"/>
      <path d="M0.674805 15.1278C0.674805 15.1278 1.37493 33.1631 5.29565 35.4876C9.21636 37.812 14.0893 40.5705 17.7859 39.8704C21.5664 38.7958 25.1006 36.993 28.1898 34.5634C28.6519 33.8633 33.9729 17.9143 33.9729 17.9143C33.9729 17.9143 24.9552 -1.33917 17.3238 1.2373C9.69245 3.81377 12.703 4.4999 0.674805 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.4" d="M22.8547 30.7825C24.7171 32.1827 22.3086 36.7756 20.7684 46.9694C20.2401 50.5716 19.8942 54.1983 19.7322 57.8354C19.4101 63.7585 19.7322 64.6826 19.3821 72.286C19.2409 75.5717 18.8948 78.8453 18.3459 82.0878C17.8418 85.0284 15.2352 91.3481 14.9552 91.3621C14.3951 91.3621 15.7695 83.2031 16.0355 78.7412C15.9144 76.9603 15.9144 75.1732 16.0355 73.3922C16.223 69.1902 16.1341 64.9803 15.7695 60.7899C15.2934 53.2426 15.0413 49.4619 13.6831 45.6392C12.3248 41.8165 11.9608 38.0918 10.6865 34.4371C10.4345 33.737 9.83237 32.0567 10.5745 31.3286C11.3166 30.6004 12.6049 31.2445 14.1452 31.5526C19.0321 32.5048 21.2725 29.7043 22.8547 30.7825Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M28.1898 34.5635L27.8958 46.7037C26.8316 57.7097 26.2155 64.4869 25.7674 69.4719C25.2213 75.563 24.8012 80.3938 21.9447 86.0089C20.1944 89.4675 16.4277 95.3486 13.7392 94.6765C12.773 94.4384 11.9469 93.2762 10.9387 89.3555C9.76138 84.3114 9.33205 79.1216 9.66445 73.9527C9.85754 67.3642 9.63774 60.7701 9.00633 54.2091C8.55185 50.0799 7.81735 45.9864 6.80793 41.9568L5.29565 35.4876" fill="#DEE1E9"/>
      <path d="M0.674805 15.1278C0.674805 15.1278 1.37493 33.1631 5.29565 35.4876C9.21636 37.812 14.0893 40.5705 17.7859 39.8704C21.5664 38.7958 25.1006 36.993 28.1898 34.5634C28.6519 33.8633 33.9729 17.9143 33.9729 17.9143C33.9729 17.9143 24.9552 -1.33917 17.3238 1.2373C9.69245 3.81377 12.703 4.4999 0.674805 15.1278Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <mask id="mask544" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="29" width="17" height="36">
        <path d="M21.6043 64.5361L13.5274 64.536L8.81145 29.8392H24.4403L21.6043 64.5361Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask544)">
        <path opacity="0.2" d="M22.8547 30.7825C24.7171 32.1827 22.3086 36.7756 20.7684 46.9694C20.2401 50.5716 19.8942 54.1983 19.7322 57.8354C19.4101 63.7585 19.7322 64.6826 19.3821 72.286C19.2409 75.5717 18.8948 78.8453 18.3459 82.0878C17.8418 85.0284 15.2352 91.3481 14.9552 91.3621C14.3951 91.3621 15.7695 83.2031 16.0355 78.7412C15.9144 76.9603 15.9144 75.1732 16.0355 73.3922C16.223 69.1902 16.1341 64.9803 15.7695 60.7899C15.2934 53.2426 15.0413 49.4619 13.6831 45.6392C12.3248 41.8165 11.9608 38.0918 10.6865 34.4371C10.4345 33.737 9.83237 32.0567 10.5745 31.3286C11.3166 30.6004 12.6049 31.2445 14.1452 31.5526C19.0321 32.5048 21.2725 29.7043 22.8547 30.7825Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5044" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="64" width="10" height="29">
        <path d="M14.092 90.843L14.7918 92.8779H16.1916L17.1798 91.578L21.7086 64.6467H12.9391L13.3097 76.5974L13.7626 87.1685L14.092 90.843Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5044)">
        <path opacity="0.4" d="M22.8547 30.7825C24.7171 32.1827 22.3086 36.7756 20.7684 46.9694C20.2401 50.5716 19.8942 54.1983 19.7322 57.8354C19.4101 63.7585 19.7322 64.6826 19.3821 72.286C19.2409 75.5717 18.8948 78.8453 18.3459 82.0878C17.8418 85.0284 15.2352 91.3481 14.9552 91.3621C14.3951 91.3621 15.7695 83.2031 16.0355 78.7412C15.9144 76.9603 15.9144 75.1732 16.0355 73.3922C16.223 69.1902 16.1341 64.9803 15.7695 60.7899C15.2934 53.2426 15.0413 49.4619 13.6831 45.6392C12.3248 41.8165 11.9608 38.0918 10.6865 34.4371C10.4345 33.737 9.83237 32.0567 10.5745 31.3286C11.3166 30.6004 12.6049 31.2445 14.1452 31.5526C19.0321 32.5048 21.2725 29.7043 22.8547 30.7825Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M34.2302 17.1324C34.2302 17.1324 28.9092 33.0953 28.4472 33.7814L28.0971 45.9216C27.0749 56.9136 26.4588 63.7049 26.0247 68.7038C25.4786 74.7809 25.0585 79.6258 22.202 85.2268C20.4377 88.6994 16.671 94.5805 13.9965 93.9084C13.0303 93.6563 12.2042 92.5081 11.196 88.5874C10.0187 83.5433 9.58937 78.3536 9.92177 73.1846C10.0917 66.5859 9.85793 59.9831 9.22164 53.413C8.76716 49.2838 8.03266 45.1903 7.02324 41.1608L5.55297 34.7056C1.63226 32.3952 0.932129 14.3459 0.932129 14.3459C12.9463 3.70391 9.94978 3.03179 17.5812 0.455319C25.2126 -2.12115 34.2302 17.1324 34.2302 17.1324Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M28.5311 35.0517L28.2371 47.1919C27.1729 58.1979 26.5568 64.9752 26.1087 69.9601C25.5626 76.0512 25.1425 80.8821 22.286 86.4971C20.5357 89.9557 16.769 95.8368 14.0805 95.1647C13.1143 94.9266 12.2882 93.7644 11.28 89.8437C10.1027 84.7996 9.67336 79.6099 10.0058 74.4409C10.1989 67.8524 9.97905 61.2583 9.34764 54.6973C8.89315 50.5681 8.15866 46.4746 7.14924 42.445L5.63696 35.9759" fill="#DEE1E9"/>
      <path d="M1.01611 15.6161C1.01611 15.6161 1.71624 33.6514 5.63696 35.9758C9.55767 38.3003 14.4306 41.0588 18.1272 40.3586C21.9077 39.2841 25.4419 37.4813 28.5311 35.0517C28.9932 34.3515 34.3142 18.4026 34.3142 18.4026C34.3142 18.4026 25.2965 -0.850889 17.6652 1.72558C10.0338 4.30205 13.0443 4.98818 1.01611 15.6161Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth83",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
