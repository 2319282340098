<template>
  <tr class="materialgroup-tr"
      :data-parent="`parent-${object.parent_id}`"
      :class="{
        haschildren: object.children.length > 0,
        collapse: object.parent_id,
        show: groupsopened.includes(parseInt(object.parent_id))
      }">
    <td :data-title="$t('id')">
      {{ object.id }}
    </td>
    <td class="tdclickable order-mob-0" @click="show(object)">
      <div :style="`padding-left: ${(object.deep_level - 1)*30}px;`">
        <PlusSvg v-if="object.children.length > 0 && !groupsopened.includes(parseInt(object.id))" class="mr-3 d-inline-block align-middle"/>
        <MinusSvg v-if="object.children.length > 0 && groupsopened.includes(parseInt(object.id))" class="mr-3 d-inline-block align-middle"/>
        <span class="d-inline-block align-middle">{{ object.title }}</span>
      </div>
    </td>
    <td :data-title="$t('parent_group')">
      {{ object.parent ? object.parent.title : '-' }}
    </td>
    <slot></slot>
  </tr>
</template>

<script>

import PlusSvg from '@/assets/svg-vue/general/boxplus.svg'
import { mapState } from "vuex"
import MinusSvg from '@/assets/svg-vue/general/boxminus.svg'

export default {
  name: "WarehouseDatatableTRMaterialGroup",
  components: {
    PlusSvg,
    MinusSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      groupsopened: state => state.warehousegroup.groupsopened,
    }),
  },
  methods: {
    show(object) {
      this.$store.commit('toggleGroup', object)
    }
  }
}
</script>

<style scoped lang="scss">
.materialgroup-tr {
  &.haschildren {
    .tdclickable {
      color: $blue-color;
      cursor: pointer;
      font-weight: 600;
    }
  }
}
</style>
