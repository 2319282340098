<template>
  <div>
    {{ $t(object.subject_type.split('\\').pop()) }}
  </div>
</template>

<script>
export default {
  name: "LogsDatatableTDModelType",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">

</style>
