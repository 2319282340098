<template>
  <div class="d-calendar position-relative"
       :style="{ width: bodyWidth+'px'}">
    <!-- header -->
    <div class="d-calendar-header"
         ref="d-calendar-header"
         id="d-calendar-header"
         :class="{ sticky: stickyCalendar, 'px-0': bodyWidth === 0 }">
      <div class="d-calendar-header__content">
        <span class="d-calendar-header__title">{{ calendarTitleAdjustedForBlocks }}</span>
        <span v-if="isToday" class="ml-1 today-bubble"></span>
        <a v-if="scheduleView !== 'doctors' && rights.includes(staffScheduleEditRight)"
           class="ml-2 pointer"
           @click="addSchedule">
          <ScheduleSvg class="svg-size-md"/>
        </a>
      </div>
    </div>
    <div class="position-relative">
      <div class="position-absolute d-calendar__time-column"
           v-if="!isMonth && hasTimeRows"
           ref="time-col"
           :class="{ 'd-none': colsInThisDay <= 0 && 1 == 2, 'onTop': scrollLeft > 0 }">
        <div v-for="item in timeRows"
             :key="`time-${item}`">
          <div class="time-marker"
               :style="{ height: timeHeight + 'px' }">
            {{ item }}
          </div>
        </div>
      </div>

      <div class="d-calendar-content"
           ref="d-calendar-content"
           @scroll="handleCalendarScroll"
           :class="{ nonScrollable: hasMultipleBlocks }"
           id="d-calendar-content">
        <div class="d-calendar-titles"
             ref="d-calendar-titles"
             :class="{ sticky: titlesSticky }"
             id="d-calendar-titles">
          <div class="position-relative d-flex">
              <DColumnTitle
                  v-for="(item, iind) in viewTitlesSeen"
                  :day="day"
                  :style="{ width: colWidth }"
                  ref="d-calendar-title"
                  :colWidth="colWidth"
                  :index="iind"
                  :count="colsInThisDay"
                  :key="item ? item.id : `it-${iind}`"
                  :item="item"/>
            <DColumnTitle
                v-if="colsInThisDay === 0"
                :day="day"
                :style="{ width: colWidth }"
                ref="d-calendar-title"
                :colWidth="colWidth"
                :item="null"/>
          </div>
        </div>

        <!-- body not month -->
        <div v-if="!isMonth"
             class="d-calendar-body"
             id="d-calendar-body"
             ref="d-calendar-body"
             :style="{'padding-top': paddingTop+'px'}">
          <div class="position-relative d-flex">
            <template v-for="item in viewColumnsAdjustedForBlocks">
              <DColumn
                  ref="d-columns"
                  v-if="columnSeen(item)"
                  :time-over="overTime"
                  :key="item.id"
                  :day="day"
                  :item="item"
                  :is-first="viewColumnsAdjustedForBlocks.indexOf(item) === 0"/>
            </template>
            <DColumn ref="d-columns"
                v-if="colsInThisDay === 0"
                :time-over="overTime"
                :day="day"
                :item="null"
                :is-first="true"/>
          </div>
        </div>

        <!-- body month -->
        <b-row no-gutters v-if="isMonth" class="d-calendar-body" >
          <b-col lg>
            <DMonth :month-dates="monthDates" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>


<script>

import DColumn from "@/components/pages/calendar/calendar/DColumn";
import DColumnTitle from "@/components/pages/calendar/calendar/DColumnTitle";
import { mapGetters, mapState } from "vuex";
import { ViewRange, ViewType } from "@/components/pages/calendar/calendar/const/CalendarConst";
import DMonth from "@/components/pages/calendar/calendar/DMonth";
import moment from "moment-timezone"
import ColumnData from "@/components/pages/calendar/calendar/classes/ColumnData"
import ScheduleSvg from '@/assets/svg-vue/calendar/add_schedule.svg'

export default {
  name: 'DBody',
  props: {
    timeOver: Function,
    hasTimeRows: {
      type: Boolean,
      default: true
    },
    day: {}
  },
  components: {
    ScheduleSvg,
    DMonth,
    DColumnTitle,
    DColumn,
  },
  data() {
    return {
      paddingTop: 0,
      titlesSticky: false,
      // startProductBarPos: -1,
      calendarWidth: 0,
      colsInAllDays: 1,
      scrollLeft: 0
    }
  },
  computed: {
    ColumnData() {
      return ColumnData
    },
    ...mapState({
      curView: state => state.calendar.curView,
      calendarDays: state => state.calendar.calendarDays,
      monthDates: state => state.calendar.monthDates,
      viewDates: state => state.calendar.viewDates,
      timeHeight: state => state.calendar.timeHeight,
      hideDoctorsWithoutSchedule: state => state.calendar.hideDoctorsWithoutSchedule,
      hideChairsWithoutSchedule: state => state.calendar.hideChairsWithoutSchedule,
      showOverflowScreen: state => state.calendar.showOverflowScreen,
      isMobile: state => state.dom.isMobile,
      doctorSchedules: state => state.calendar.doctorSchedules,
      rights: state => state.auth.rights,
      records: state => state.calendar.records,
      staffSchedules: state => state.calendar.staffSchedules,
      doctors: state => state.calendar.doctors,
      selectedProfessions: state => state.calendar.selectedProfessions,
      stickyCalendar: state => state.calendar.stickyCalendar,
      barTopPosition: state => state.calendar.barTopPosition,
      user: state => state.auth.user,
      counter: state => state.datatable.dataTableCounter,
      rightPaneOpened: state => state.dom.rightPaneOpened,
      scheduleView: state => state.calendar.scheduleView,
    }),
    ...mapGetters([
      'calendarTitle',
      'viewColumns',
      'viewTitles',
      'timeRows',
      'isDoctor',
      'blockDates',
      'doctorHasProfession',
      'staffScheduleEditRight',
      'activeRange'
    ]),
    isMonth() {
      return this.activeRange === ViewRange.MONTH
    },
    isToday() {
      if(this.activeRange !== ViewRange.DAY) return false
      return this.day.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    },
    hasMultipleBlocks() {
      return this.blockDates.length > 1
    },
    calendarTitleAdjustedForBlocks() {
      if(this.hasMultipleBlocks) {
        return moment(this.day).format('D MMMM, YYYY (dddd)');
      }
      return this.calendarTitle
    },
    viewColumnsAdjustedForBlocks() {
      if(this.hasMultipleBlocks) {
        return this.$store.getters.viewColumnsForDay(this.day)
      }
      return this.viewColumns
    },
    dayFormatted() {
      return moment(this.day).format('YYYY-MM-DD')
    },
    defaultColWidth() {
        return this.activeRange === ViewRange.MONTH ? 80 : 180
    },
    colWidth() {
      if(!this.calendarWidth || !this.colsInAllDays) return this.defaultColWidth
      const wPerItem = (this.calendarWidth / this.colsInAllDays)
      return (this.showOverflowScreen || this.isMobile) && wPerItem < this.defaultColWidth ? this.defaultColWidth : wPerItem
    },
    viewTitlesSeen() {
      return this.viewTitles.filter(item => this.columnSeen(item))
    },
    colsInThisDay() {
      return this.viewTitlesSeen.length
    },
    bodyWidth() {
      return this.colWidth * Math.max(1, this.colsInThisDay)
    },
  },
  methods: {
    overTime(bool, time) {
      this.timeOver(bool, time)
    },
    findPosY(obj) {
      let curtop = 0
      if (typeof (obj.offsetParent) != 'undefined' && obj.offsetParent) {
        while (obj.offsetParent) {
          curtop += obj.offsetTop
          obj = obj.offsetParent
        }
        curtop += obj.offsetTop
      } else if (obj.y) curtop += obj.y
      return curtop
    },
    titlesFixingOnScroll() {
      if(!this.isMonth) {
        const bar = this.$refs['d-calendar-titles']
        const barTop = this.$refs['d-calendar-header']
        const calendarTopMenu = document.getElementById('calendar-top-menu')
        // if(this.startProductBarPos <= 0) this.startProductBarPos = this.findPosY(barTop)
        if(this.barTopPosition <= 0) {
          this.$store.commit('setBarTopPosition', this.findPosY(calendarTopMenu))
        }
        let bodyContainer = document.getElementById('d-body-container')
        let content = this.$refs['d-calendar-content']
        this.scrollLeft = (content?.scrollLeft || 0) + (bodyContainer?.scrollLeft || 0)
        this.$emit('scrollLeft', this.scrollLeft)
        if(window.pageYOffset > this.barTopPosition) {
          this.titlesSticky = true
          this.$store.commit('setStickyCalendar', true)
          if(bar && barTop) {
            bar.style.width = this.bodyWidth+'px'
            barTop.style.top = calendarTopMenu.clientHeight+'px'
            barTop.style.width = this.bodyWidth+'px'
            barTop.style.marginLeft = -this.scrollLeft + 'px'
            bar.style.marginLeft = -this.scrollLeft + 'px'
            this.paddingTop = bar.offsetHeight + barTop.offsetHeight
          }
        } else {
          this.titlesSticky = false
          this.$store.commit('setStickyCalendar', false)
          if(bar && barTop) {
            bar.style.width = 'auto'
            barTop.style.width = 'auto'
            this.paddingTop = 0
            barTop.style.marginLeft = 0
            bar.style.marginLeft = 0
          }
        }
        const timeCol = this.$refs['time-col']
        if(timeCol) {
          timeCol.style.left = (this.scrollLeft - (this.isMobile ? 30 : 40))+'px'
        }

      }
    },
    handleCalendarScroll(e) {
      let bar = this.$refs['d-calendar-titles']
      if(bar) bar.scrollLeft = e.target.scrollLeft
    },
    async makeAllHeadersEqualHeight() {
      let h = 0
      let headers = document.getElementsByClassName('d-calendar__title')
      headers.forEach(header => header.style.height = null)
      await this.$nextTick()
      headers.forEach(header => h = Math.max(h, header.clientHeight))
      headers.forEach(header => header.style.height = h+'px')

      const $el = document.getElementById('d-body-container')

      if($el) this.calendarWidth = ($el.clientWidth - (this.activeRange === ViewRange.MONTH ? 0 : 40))
      this.colsInAllDays = document.getElementsByClassName('d-calendar__column').length

      this.titlesFixingOnScroll()
    },
    makingSureHeadersEqual() {
      this.makeAllHeadersEqualHeight()
      setTimeout(this.makeAllHeadersEqualHeight,1000)
    },
    columnSeen(item) {
      if(item.doctor) return this.checkColumn(item.doctor)
      if(item.chair) return this.checkColumn(item.chair)
      if(item.item) return this.checkColumn(item.item)
      return this.checkColumn(item)
    },
    chairHasDoctorWithProfession(chair) {
      if(!chair) return false
      const doctors = chair.doctors
      if(!doctors || !doctors.length) return false
      let hasProfession = false
      doctors.forEach(doctor => {
        if(this.doctorHasProfession(doctor)) {
          if(this.hideChairsWithoutSchedule) {
            if(this.doctorSchedules && this.doctorSchedules[doctor.id]) {
              this.doctorSchedules[doctor.id].forEach((sch) => {
                if(sch.time_from && sch.time_to && sch.day == this.dayFormatted) {
                  hasProfession = true
                }
              })
            }
          } else {
            hasProfession = true
          }
        }
      })
      return hasProfession
    },
    checkColumn(item) {
      if(this.activeRange !== ViewRange.DAY) return true
      if(this.scheduleView !== 'doctors') {
        const employeeSchedules = this.staffSchedules[item.id] || []
        const employeeSchedule = employeeSchedules.find(sch => sch.day === this.dayFormatted)
        return !!employeeSchedule
      }
      if(this.isDoctor && !this.rights.includes('docs_timetable')
          && this.curView === ViewType.DOCTOR
          && this.activeRange === ViewRange.DAY
          && item && item.id !== Number(this.user.id)) { // doctor cannot see other doctors without permission
        return false
      }
      if(this.selectedProfessions && this.selectedProfessions.length && this.activeRange === ViewRange.DAY) { // if some professions are hidden
        if(this.curView === ViewType.DOCTOR) {
          if(!this.doctorHasProfession(item)) return false
        }
        if(this.curView === ViewType.CHAIR) {
          if(!this.chairHasDoctorWithProfession(item)) return false
        }
      }
      if(this.activeRange === ViewRange.DAY && this.viewDates.length) {
        const selectedDay = this.viewDates[0]
        if(selectedDay < moment().subtract(30, 'day')) return true // old ones we show, cause old schedule might be already deleted
      }
      if(this.curView === ViewType.CHAIR) {
        if(!this.hideChairsWithoutSchedule) return true // not hiding, showing all
        const doctorIds = item.doctors.map(x => x.id)
        if(Object.values(this.doctorSchedules).flat().find(x => x.day === this.dayFormatted && doctorIds.includes(Number(x.doctor_id)))) return true // doctors who are attached to chair have schedules (even if on different chair)
        if(this.doctorSchedules) {
          if(Object.values(this.doctorSchedules).flat().find(x => Number(x.chair_id) === item.id && x.day === this.dayFormatted)) return true // has doctors with actual schedules
        }
        if((this.records.filter(x => Number(x.chair_id) === item.id && x.start.slice(0, -9) === this.dayFormatted)).length) return true // chair has records
      }
      if(this.curView === ViewType.DOCTOR) {
        if(!this.hideDoctorsWithoutSchedule) return true // not hiding, showing all
        if(this.doctorSchedules && this.doctorSchedules[item.id]) {
          if(this.doctorSchedules[item.id].find(x => x.day === this.dayFormatted)) return true // doctor has actual schedule
        }
        if((this.records.filter(x => Number(x.doctor_id) === item.id && x.start.slice(0, -9) === this.dayFormatted)).length) return true // doctor has records
      }
      return false
    },
    async addSchedule() {
      this.$store.commit('setDoctorPreconfig', [{
        day: moment(this.day).format('YYYY-MM-DD'),
        doctor_id: null,
        chair_id:null,
        time_from: this.$store.state.calendar.timeFromHour.toString().padStart(2, '0') + ':00',
        time_to: this.$store.state.calendar.timeToHour.toString().padStart(2, '0') + ':00',
      }])
      this.$store.commit('setDoctor', null)
      this.$store.commit('setDoctorScheduleDay', this.day)
      await this.$nextTick()
      this.$bvModal.show('doctor-day-edit-modal')
    }
  },
  watch: {
    curView() {
      this.makingSureHeadersEqual()
    },
    counter() {
      this.makingSureHeadersEqual()
    },
    hideDoctorsWithoutSchedule() {
      this.makingSureHeadersEqual()
    },
    hideChairsWithoutSchedule() {
      this.makingSureHeadersEqual()
    },
    showOverflowScreen() {
      this.makingSureHeadersEqual()
    },
    scheduleView() {
      setTimeout(this.makingSureHeadersEqual, 1)
      setTimeout(this.makingSureHeadersEqual, 30)
      setTimeout(this.makingSureHeadersEqual, 100)
      setTimeout(this.makingSureHeadersEqual, 200)
    },
    records: {
      deep: true,
      handler() {
        this.makingSureHeadersEqual()
      }
    },
    staffSchedules: {
      deep: true,
      handler() {
        this.makingSureHeadersEqual()
      }
    },
    selectedProfessions: {
      deep: true,
      handler() {
        this.makingSureHeadersEqual()
      }
    },
    rightPaneOpened() {
      this.makeAllHeadersEqualHeight()
      setTimeout(this.makeAllHeadersEqualHeight, 210)
    }
  },
  unmounted() {
    window.removeEventListener('scroll', this.titlesFixingOnScroll)
    window.removeEventListener('resize', this.makeAllHeadersEqualHeight)
    const $el = document.getElementById('d-body-container')
    if($el) $el.removeEventListener('scroll', this.titlesFixingOnScroll)

  },
  mounted() {
    window.addEventListener('scroll', this.titlesFixingOnScroll)
    window.addEventListener('resize', this.makeAllHeadersEqualHeight)
    const $el = document.getElementById('d-body-container')
    if($el) $el.addEventListener('scroll', this.titlesFixingOnScroll)
    this.makingSureHeadersEqual()
  }
}
</script>

<style scoped lang="scss">
.d-calendar {
  background-color: #e2e2e2;
  transition: all 0.2s ease-out;
}
.today-bubble {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  display: inline-block;
  background: $green-color;
}
.d-calendar-header {
  background-color: #F2F6FA;
  border-left: 1px solid #B5C6D8;
  font-size: 15px;
  font-weight: bold;
  padding: 13px 10px;
  border-right: 1px solid #E5E5E5;
  z-index: 6;
  height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .d-calendar-header__content {
    display: flex;
    align-items: center;
  }
  .d-calendar-header__title {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.sticky {
    position: fixed;
    top: 0;
    overflow: hidden;
  }
}
.d-calendar-titles {
  z-index: 6;
  &.sticky {
    position: fixed;
    top: 113px;
    //overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .d-calendar-header {
    font-size: 13px;
    padding: 10px 10px;
    height: 39px;
  }
  .d-calendar-titles {
    &.sticky {
      top: calc(39px + 88px);
    }
  }
}
</style>

