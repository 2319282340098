<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M11.5127 84.7742L13.5964 86.044H17.0801L19.1801 84.7742L17.5685 83.2277L19.6522 81.6812L17.7638 80.1347L20.0592 78.4417L18.2359 76.8952L20.5313 75.4952L18.5127 74.0789L20.5313 72.4673L18.8383 70.9208L21.0685 69.5045L19.3104 68.0394L21.6057 66.4929L19.4569 64.9464L21.6057 63.3348L20.0592 61.9836L22.1429 60.4371L20.3197 58.9557L22.4034 57.4092L20.5964 55.6673L21.6057 53.9906V39.7301L19.7173 32.4046H13.5964" fill="#8889BE"/>
      <path d="M18.92 84.7742L16.8363 86.044H13.3688L11.2525 84.7742L12.8804 83.2277L10.7805 81.6812L12.6688 80.1347L10.3898 78.4417L12.1967 76.8952L9.91766 75.4952L11.9363 74.0789L9.91766 72.4673L11.5944 70.9208L9.38046 69.5045L11.1223 68.0394L8.84325 66.4929L10.9921 64.9464L8.84325 63.3348L10.3898 61.9836L8.30604 60.4371L10.113 58.9557L8.0293 57.4092L9.85255 55.6673L8.84325 53.9906V39.7301L10.7153 32.4046H14.6223" fill="#8889BE"/>
      <path d="M21.0339 1.98198L30.5837 13.6741C30.5837 13.6741 34.7844 29.399 27.3491 38.8787C19.9137 48.3585 12.2403 45.1239 12.2403 45.1239C12.2403 45.1239 6.07919 44.1577 2.69057 34.958C-0.698047 25.7583 3.76877 11.8258 3.76877 11.8258L9.77587 6.58882L17.5193 1.98198C17.5193 1.98198 20.0677 0.749757 21.0339 1.98198Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M5.14893 40.4001L9.65775 78.6411L14.1246 93.8619C14.1246 93.8619 17.3732 100.863 18.7594 95.9623C20.1457 91.0614 21.868 78.6411 21.868 78.6411L23.4083 65.2827C23.4083 65.2827 23.4083 42.8786 27.427 36.9975" fill="#DEE1E9"/>-->
<!--      <path d="M20.1736 1.45903L29.7233 13.1512C29.7233 13.1512 33.9241 28.876 26.4887 38.3558C19.0534 47.8355 11.38 44.6009 11.38 44.6009C11.38 44.6009 5.21884 43.6347 1.83022 34.4351C-1.5584 25.2354 2.90842 11.3028 2.90842 11.3028L8.91551 6.06587L16.6589 1.45903C16.6589 1.45903 19.2074 0.226807 20.1736 1.45903Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>-->
<!--      <path opacity="0.2" d="M10.3159 34.4071C8.45359 35.7653 10.876 40.4001 12.4583 50.636C12.9866 54.2382 13.3325 57.8649 13.4945 61.502C13.8166 67.4111 13.4945 68.3353 13.8446 75.9526C13.9858 79.2383 14.3319 82.5119 14.8808 85.7544C15.3849 88.681 16.9251 93.5293 17.2052 93.5433C17.7653 93.5433 16.9812 85.8665 17.2052 82.4078C17.3281 80.6223 17.3281 78.8304 17.2052 77.0448C17.0171 72.8428 17.106 68.6329 17.4712 64.4425C17.9473 56.8812 18.1994 53.1145 19.5576 49.2918C20.9159 45.4691 21.2939 41.7304 22.5542 38.0897C22.8062 37.3896 23.4083 35.6953 22.6802 34.9672C21.9521 34.239 20.6358 34.8971 19.0955 35.2052C14.1386 36.1714 11.8982 33.2588 10.3159 34.4071Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M5.96509 40.1931L10.4739 78.4341L14.9407 93.6549C14.9407 93.6549 18.1893 100.656 19.5756 95.7553C20.9618 90.8544 22.6841 78.4341 22.6841 78.4341L24.2244 65.0757C24.2244 65.0757 24.2244 42.6716 28.2432 36.7905" fill="#DEE1E9"/>
        <mask id="mask443" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask443)">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6923 16.7991C19.7544 16.7991 21 11.5008 21 1.90252L19.0769 0.0404528L16 -1.20093C16 8.3974 17.6302 16.7991 18.6923 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.7048 15.1615C8.75697 16.9676 11.2906 23.1309 12.9454 36.7423C13.498 41.5324 13.8597 46.355 14.0292 51.1915C14.366 59.0492 14.0292 60.2782 14.3953 70.4076C14.543 74.7767 14.905 79.1299 15.479 83.4417C16.0063 87.3333 17.6172 93.7805 17.9101 93.7991C18.4959 93.7991 17.6758 83.5907 17.9101 78.9915C18.0387 76.6171 18.0387 74.2343 17.9101 71.8599C17.7134 66.2722 17.8063 60.674 18.1884 55.1018C18.6863 45.0469 18.9499 40.038 20.3705 34.9547C21.791 29.8714 22.1865 24.8998 23.5045 20.0586C23.7681 19.1276 24.3979 16.8745 23.6363 15.9063C22.8748 14.938 21.4981 15.8132 19.8872 16.2228C14.7029 17.5076 12.3596 13.6346 10.7048 15.1615Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M5.14893 40.4001L9.65775 78.6411L14.1246 93.8619C14.1246 93.8619 17.3732 100.863 18.7594 95.9623C20.1457 91.0614 21.868 78.6411 21.868 78.6411L23.4083 65.2827C23.4083 65.2827 23.4083 42.8786 27.427 36.9975" fill="#DEE1E9"/>
      <path d="M20.1736 1.45903L29.7233 13.1512C29.7233 13.1512 33.9241 28.876 26.4887 38.3558C19.0534 47.8355 11.38 44.6009 11.38 44.6009C11.38 44.6009 5.21884 43.6347 1.83022 34.4351C-1.5584 25.2354 2.90842 11.3028 2.90842 11.3028L8.91551 6.06587L16.6589 1.45903C16.6589 1.45903 19.2074 0.226807 20.1736 1.45903Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      <path opacity="0.2" d="M10.3159 34.4071C8.45359 35.7653 10.876 40.4001 12.4583 50.636C12.9866 54.2382 13.3325 57.8649 13.4945 61.502C13.8166 67.4111 13.4945 68.3353 13.8446 75.9526C13.9858 79.2383 14.3319 82.5119 14.8808 85.7544C15.3849 88.681 16.9251 93.5293 17.2052 93.5433C17.7653 93.5433 16.9812 85.8665 17.2052 82.4078C17.3281 80.6223 17.3281 78.8304 17.2052 77.0448C17.0171 72.8428 17.106 68.6329 17.4712 64.4425C17.9473 56.8812 18.1994 53.1145 19.5576 49.2918C20.9159 45.4691 21.2939 41.7304 22.5542 38.0897C22.8062 37.3896 23.4083 35.6953 22.6802 34.9672C21.9521 34.239 20.6358 34.8971 19.0955 35.2052C14.1386 36.1714 11.8982 33.2588 10.3159 34.4071Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="17" cy="96" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M27.1424 36.7034C23.1796 42.6965 23.1656 64.9466 23.1656 64.9466L21.6253 78.305C21.6253 78.305 19.861 90.7393 18.4608 95.6682C17.0605 100.597 13.8259 93.5678 13.8259 93.5678L9.41512 78.347L4.86429 40.12C3.43897 38.3291 2.32214 36.3131 1.55968 34.1549C-1.84294 24.8993 2.63788 11.0227 2.63788 11.0227L8.65898 5.79977L16.3604 1.19293C16.3604 1.19293 18.9369 0.00270844 19.917 1.19293L29.4668 12.8851C29.4668 12.8851 33.2475 27.2237 27.1424 36.7034Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M27.1424 36.7034C23.1796 42.6965 23.1656 64.9466 23.1656 64.9466L21.6253 78.305C21.6253 78.305 19.861 90.7393 18.4608 95.6682C17.0605 100.597 13.8259 93.5678 13.8259 93.5678L9.41512 78.347L4.86429 40.12C3.43897 38.3291 2.32214 36.3131 1.55968 34.1549C-1.84294 24.8993 2.63788 11.0227 2.63788 11.0227L8.65898 5.79977L16.3604 1.19293C16.3604 1.19293 18.9369 0.00270844 19.917 1.19293L29.4668 12.8851C29.4668 12.8851 33.2475 27.2237 27.1424 36.7034Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M5.07975 45.6385L0.570923 5.50339L6.10583 3.10931L9.10583 5.50339L14.1058 4.10931L17.6058 4.60931L21.6058 3.10934C19.8627 5.61789 18.8303 32.28 18.8303 32.28L17.29 45.6385C17.29 45.6385 15.5677 58.0587 14.1814 62.9596C12.7952 67.8605 9.54656 60.8592 9.54656 60.8592L5.07975 45.6385Z" fill="#DEE1E9"/>
      <path d="M1.99999 11.5273C9.7274 14.1567 13.5591 13.2022 19.6815 8" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M8.91651 28.4994C8.75446 24.8622 8.40862 21.2356 7.88032 17.6334C6.59798 9.33787 4.76381 4.72114 5.10583 2.54591L8.12606 0.99997L8.60583 1.40439L10.8574 2.90439L13.3574 1.40439L15.8574 2.90439L18.1022 1.96452C18.8303 2.69265 18.2282 4.38697 17.9762 5.08709C16.7159 8.72776 16.3379 12.4664 14.9796 16.2891C13.6214 20.1118 13.3693 23.8785 12.8932 31.4399C12.528 35.6303 12.4391 39.8402 12.6272 44.0422C12.7501 45.8278 12.7501 47.6196 12.6272 49.4052C12.4031 52.8638 13.1873 60.5406 12.6272 60.5406C12.3471 60.5266 10.8069 55.6783 10.3028 52.7518C9.75387 49.5093 9.4078 46.2356 9.26657 42.95C8.91651 35.3326 9.23857 34.4084 8.91651 28.4994Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M5.96509 40.4887L10.4739 78.7297L14.9407 93.9505C14.9407 93.9505 18.1893 100.952 19.5756 96.0509C20.9618 91.15 22.6841 78.7297 22.6841 78.7297L24.2244 65.3713C24.2244 65.3713 24.2244 42.9672 28.2432 37.0861" fill="#DEE1E9"/>
        <path d="M27.3049 38.4444C34.7402 28.9647 30.5395 13.2398 30.5395 13.2398L27.3049 17.0947H23.3978L24.3049 14.4495L23.0973 11.9826L24.3049 9.66917L20.4958 8.15443L21.8345 5.11945L23.0973 4.12805L20.9897 1.54766C20.0236 0.315431 17.4751 1.54766 17.4751 1.54766L9.73168 6.1545L3.72458 11.3915C3.72458 11.3915 -0.742237 25.324 2.64638 34.5237C6.035 43.7234 12.1961 44.6895 12.1961 44.6895C12.1961 44.6895 19.8695 47.9241 27.3049 38.4444Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M27.6758 25.1393C17.5767 21.1441 15.2637 16.6513 16.4195 5.09474" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M11.1321 34.4957C9.26975 35.8539 11.6922 40.4888 13.2745 50.7246C13.8028 54.3269 14.1486 57.9535 14.3107 61.5906C14.6327 67.4997 14.3107 68.4239 14.6607 76.0413C14.802 79.3269 15.148 82.6005 15.6969 85.8431C16.201 88.7696 17.7413 93.6179 18.0214 93.6319C18.5815 93.6319 17.7973 85.9551 18.0214 82.4964C18.1443 80.7109 18.1443 78.919 18.0214 77.1335C17.8333 72.9314 17.9221 68.7215 18.2874 64.5312C18.7635 56.9698 19.0155 53.2031 20.3738 49.3804C21.732 45.5577 22.1101 41.819 23.3703 38.1783C23.6224 37.4782 24.2245 35.7839 23.4964 35.0558C22.7682 34.3276 21.452 34.9858 19.9117 35.2938C14.9548 36.26 12.7144 33.3475 11.1321 34.4957Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M2 23.2494L10.4739 78.8844L14.9407 94.1052C14.9407 94.1052 18.1893 101.106 19.5756 96.2056C20.9618 91.3047 22.6841 78.8844 22.6841 78.8844L24.2244 65.5259C24.2244 65.5259 27.4813 28.6305 31.5 22.7494" fill="#DEE1E9"/>
        <path d="M30.5395 13.3945L20.9897 1.70232C20.0236 0.470094 17.4751 1.70232 17.4751 1.70232L9.73168 6.30916L3.72458 11.5461C3.72458 11.5461 -0.742237 25.4787 2.64638 34.6783C3.06146 35.8052 3.51814 36.8086 4 37.7018H9L11 27.2494C14.7101 25.6957 16.7902 25.6466 20.5 27.2494L23.5 37.7018H27.9622C34.4843 28.1612 30.5395 13.3945 30.5395 13.3945Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M2 23L10.4739 78.635L14.9407 93.8558C14.9407 93.8558 18.1893 100.857 19.5756 95.9562C20.9618 91.0553 22.6841 78.635 22.6841 78.635L24.2244 65.2766C24.2244 65.2766 27.4813 28.3811 31.5 22.5" fill="#DEE1E9"/>
        <path d="M30.5395 13.1451L20.9897 1.45293C20.0236 0.220704 17.4751 1.45293 17.4751 1.45293L9.73168 6.05977L3.72458 11.2967C3.72458 11.2967 -0.742237 25.2293 2.64638 34.429C3.06146 35.5558 3.51814 36.5592 4 37.4524H9L11 27C14.7101 25.4463 16.7902 25.3972 20.5 27L23.5 37.4524H27.9622C34.4843 27.9118 30.5395 13.1451 30.5395 13.1451Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M2 23L10.4739 78.635L14.9407 93.8558C14.9407 93.8558 18.1893 100.857 19.5756 95.9562C20.9618 91.0553 22.6841 78.635 22.6841 78.635L24.2244 65.2766C24.2244 65.2766 27.4813 28.3811 31.5 22.5" fill="#DEE1E9"/>
        <mask id="mask2043" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M30.1628 13.2398L20.613 1.54766C19.6468 0.315431 17.0984 1.54766 17.0984 1.54766L9.35497 6.1545L3.34787 11.3915C3.34787 11.3915 -1.11895 25.324 2.26967 34.5237C2.68475 35.6506 3.14143 36.6539 3.62329 37.5471H8.62329L10.6233 27.0947C14.3334 25.541 16.4135 25.4919 20.1233 27.0947L23.1233 37.5471H27.5855C34.1076 28.0065 30.1628 13.2398 30.1628 13.2398Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2043)">
          <path d="M30.1628 13.2398L20.613 1.54766C19.6468 0.315431 17.0984 1.54766 17.0984 1.54766L9.35497 6.1545L3.34787 11.3915C3.34787 11.3915 -1.11895 25.324 2.26967 34.5237C2.68475 35.6506 3.14143 36.6539 3.62329 37.5471H8.62329L10.6233 27.0947C14.3334 25.541 16.4135 25.4919 20.1233 27.0947L23.1233 37.5471H27.5855C34.1076 28.0065 30.1628 13.2398 30.1628 13.2398Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M26.2032 49.5H5.20325V23.952C13.4043 22.0258 18.0022 22.0062 26.2032 23.952V49.5Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M5.71368 40.1355L10.2225 78.3765L14.6893 93.5973C14.6893 93.5973 17.9379 100.599 19.3242 95.6977C20.7104 90.7968 22.4327 78.3765 22.4327 78.3765L23.973 65.0181C23.973 65.0181 23.973 42.614 27.9918 36.7329" fill="#DEE1E9"/>
        <path d="M20.7383 1.19463L30.2881 12.8868C30.2881 12.8868 34.4888 28.6116 27.0535 38.0914C19.6181 47.5711 11.9447 44.3365 11.9447 44.3365C11.9447 44.3365 5.7836 43.3703 2.39498 34.1707C-0.993641 24.971 3.47318 11.0384 3.47318 11.0384L9.48027 5.80147L17.2237 1.19463C17.2237 1.19463 19.7722 -0.0375968 20.7383 1.19463Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M10.8807 34.1426C9.01835 35.5009 11.4408 40.1357 13.0231 50.3716C13.5514 53.9738 13.8972 57.6004 14.0593 61.2376C14.3813 67.1467 14.0593 68.0708 14.4093 75.6882C14.5506 78.9739 14.8966 82.2475 15.4455 85.49C15.9496 88.4166 17.4899 93.2649 17.7699 93.2789C18.3301 93.2789 17.5459 85.6021 17.7699 82.1434C17.8929 80.3579 17.8929 78.566 17.7699 76.7804C17.5818 72.5784 17.6707 68.3685 18.036 64.1781C18.5121 56.6168 18.7641 52.8501 20.1224 49.0274C21.4806 45.2047 21.8587 41.466 23.1189 37.8253C23.371 37.1252 23.9731 35.4309 23.2449 34.7027C22.5168 33.9746 21.2006 34.6327 19.6603 34.9408C14.7034 35.907 12.463 32.9944 10.8807 34.1426Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M23.4291 74.735C17.8414 78.1414 14.7211 77.9862 9.16644 74.735" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M5.96509 40.3941L10.4739 78.6351L14.9407 93.8558C14.9407 93.8558 18.1893 100.857 19.5756 95.9562C20.9618 91.0553 22.6841 78.6351 22.6841 78.6351L24.2244 65.2766C24.2244 65.2766 24.2244 42.8725 28.2432 36.9915" fill="#DEE1E9"/>
        <mask id="mask2243" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2243)">
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6923 17C19.7544 17 21 11.7018 21 2.10345L19.0769 0.241381L16 -1C16 8.59833 17.6302 17 18.6923 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.7048 15.3624C8.75697 17.1686 11.2906 23.3318 12.9454 36.9432C13.498 41.7333 13.8597 46.5559 14.0292 51.3925C14.366 59.2502 14.0292 60.4791 14.3953 70.6085C14.543 74.9776 14.905 79.3308 15.479 83.6426C16.0063 87.5342 17.6172 93.9814 17.9101 94C18.4959 94 17.6758 83.7916 17.9101 79.1924C18.0387 76.818 18.0387 74.4352 17.9101 72.0609C17.7134 66.4731 17.8063 60.8749 18.1884 55.3027C18.6863 45.2478 18.9499 40.239 20.3705 35.1557C21.791 30.0724 22.1865 25.1008 23.5045 20.2595C23.7681 19.3285 24.3979 17.0755 23.6363 16.1072C22.8748 15.139 21.4981 16.0141 19.8872 16.4238C14.7029 17.7085 12.3596 13.8355 10.7048 15.3624Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="17" cy="95.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M5.96509 40.3941L10.4739 78.6351L14.9407 93.8558C14.9407 93.8558 18.1893 100.857 19.5756 95.9562C20.9618 91.0553 22.6841 78.6351 22.6841 78.6351L24.2244 65.2766C24.2244 65.2766 24.2244 42.8725 28.2432 36.9915" fill="#DEE1E9"/>
        <mask id="mask2343" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2343)">
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6923 17C19.7544 17 21 11.7018 21 2.10345L19.0769 0.241381L16 -1C16 8.59833 17.6302 17 18.6923 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.7048 15.3624C8.75697 17.1686 11.2906 23.3318 12.9454 36.9432C13.498 41.7333 13.8597 46.5559 14.0292 51.3925C14.366 59.2502 14.0292 60.4791 14.3953 70.6085C14.543 74.9776 14.905 79.3308 15.479 83.6426C16.0063 87.5342 17.6172 93.9814 17.9101 94C18.4959 94 17.6758 83.7916 17.9101 79.1924C18.0387 76.818 18.0387 74.4352 17.9101 72.0609C17.7134 66.4731 17.8063 60.8749 18.1884 55.3027C18.6863 45.2478 18.9499 40.239 20.3705 35.1557C21.791 30.0724 22.1865 25.1008 23.5045 20.2595C23.7681 19.3285 24.3979 17.0755 23.6363 16.1072C22.8748 15.139 21.4981 16.0141 19.8872 16.4238C14.7029 17.7085 12.3596 13.8355 10.7048 15.3624Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="17" cy="95.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2343)">
          <path d="M17 91L17.8229 94.7391L20.8567 92.4037L19.0837 95.797L22.9088 95.9581L19.3694 97.4178L22.1962 100L18.5465 98.8431L19.0521 102.638L17 99.406L14.9479 102.638L15.4535 98.8431L11.8038 100L14.6306 97.4178L11.0912 95.9581L14.9163 95.797L13.1433 92.4037L16.1771 94.7391L17 91Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2343" x="10.8911" y="90.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.96509 40.3941L10.4739 78.6351L14.9407 93.8558C14.9407 93.8558 18.1893 100.857 19.5756 95.9562C20.9618 91.0553 22.6841 78.6351 22.6841 78.6351L24.2244 65.2766C24.2244 65.2766 24.2244 42.8725 28.2432 36.9915" fill="#DEE1E9"/>
      <mask id="mask2343" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
        <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2343)">
        <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M18.6923 17C19.7544 17 21 11.7018 21 2.10345L19.0769 0.241381L16 -1C16 8.59833 17.6302 17 18.6923 17Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M10.7048 15.3624C8.75697 17.1686 11.2906 23.3318 12.9454 36.9432C13.498 41.7333 13.8597 46.5559 14.0292 51.3925C14.366 59.2502 14.0292 60.4791 14.3953 70.6085C14.543 74.9776 14.905 79.3308 15.479 83.6426C16.0063 87.5342 17.6172 93.9814 17.9101 94C18.4959 94 17.6758 83.7916 17.9101 79.1924C18.0387 76.818 18.0387 74.4352 17.9101 72.0609C17.7134 66.4731 17.8063 60.8749 18.1884 55.3027C18.6863 45.2478 18.9499 40.239 20.3705 35.1557C21.791 30.0724 22.1865 25.1008 23.5045 20.2595C23.7681 19.3285 24.3979 17.0755 23.6363 16.1072C22.8748 15.139 21.4981 16.0141 19.8872 16.4238C14.7029 17.7085 12.3596 13.8355 10.7048 15.3624Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="17" cy="95.2009" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2343)">
        <path d="M17 91L17.8229 94.7391L20.8567 92.4037L19.0837 95.797L22.9088 95.9581L19.3694 97.4178L22.1962 100L18.5465 98.8431L19.0521 102.638L17 99.406L14.9479 102.638L15.4535 98.8431L11.8038 100L14.6306 97.4178L11.0912 95.9581L14.9163 95.797L13.1433 92.4037L16.1771 94.7391L17 91Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2343" x="10.8911" y="90.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M5.96509 40.1931L10.4739 78.4341L14.9407 93.6549C14.9407 93.6549 18.1893 100.656 19.5756 95.7553C20.9618 90.8544 22.6841 78.4341 22.6841 78.4341L24.2244 65.0757C24.2244 65.0757 24.2244 42.6716 28.2432 36.7905" fill="#DEE1E9"/>
        <mask id="mask2543" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2543)">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6923 16.7991C19.7544 16.7991 21 11.5008 21 1.90252L19.0769 0.0404528L16 -1.20093C16 8.3974 17.6302 16.7991 18.6923 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.7048 15.1615C8.75697 16.9676 11.2906 23.1309 12.9454 36.7423C13.498 41.5324 13.8597 46.355 14.0292 51.1915C14.366 59.0492 14.0292 60.2782 14.3953 70.4076C14.543 74.7767 14.905 79.1299 15.479 83.4417C16.0063 87.3333 17.6172 93.7805 17.9101 93.7991C18.4959 93.7991 17.6758 83.5907 17.9101 78.9915C18.0387 76.6171 18.0387 74.2343 17.9101 71.8599C17.7134 66.2722 17.8063 60.674 18.1884 55.1018C18.6863 45.0469 18.9499 40.038 20.3705 34.9547C21.791 29.8714 22.1865 24.8998 23.5045 20.0586C23.7681 19.1276 24.3979 16.8745 23.6363 15.9063C22.8748 14.938 21.4981 15.8132 19.8872 16.2228C14.7029 17.5076 12.3596 13.6346 10.7048 15.1615Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="17" cy="95" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2643" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="98">
          <path d="M5.58838 40.4888L10.0972 78.7298L14.564 93.9506C14.564 93.9506 17.8126 100.952 19.1989 96.051C20.5851 91.1501 22.3074 78.7298 22.3074 78.7298L23.8477 65.3713C23.8477 65.3713 23.8477 42.9673 27.8665 37.0862" fill="#DEE1E9"/>
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2643)">
          <path d="M5.58838 40.4888L10.0972 78.7298L14.564 93.9506C14.564 93.9506 17.8126 100.952 19.1989 96.051C20.5851 91.1501 22.3074 78.7298 22.3074 78.7298L23.8477 65.3713C23.8477 65.3713 23.8477 42.9673 27.8665 37.0862" fill="#DEE1E9"/>
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M9.9741 23.6762H-1.94708C0.214981 29.046 0.571986 32.3063 0.275452 38.0446C0.26427 38.2609 0.513642 38.3955 0.703865 38.2689C1.70794 37.601 4.06614 35.7849 4.63778 33.1432C5.33025 29.9432 7.33345 29.1661 7.54606 29.092C7.55902 29.0875 7.57061 29.083 7.58299 29.0773L8.76593 28.5307C8.9105 28.4639 8.96909 28.3014 8.89681 28.1678L8.36789 27.1901C8.32314 27.1074 8.32722 27.0092 8.3787 26.9299L10.2251 24.0858C10.3421 23.9056 10.2016 23.6762 9.9741 23.6762Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.04897 37.155C2.10995 36.3071 3.51793 34.8702 3.92035 33.0106C4.66356 29.5761 6.83953 28.6201 7.2725 28.4648L8.04609 28.1073L7.71349 27.4925C7.55687 27.203 7.57114 26.8596 7.75132 26.5821L9.19877 24.3524H-0.903787C0.87294 29.0171 1.24894 32.2129 1.04897 37.155ZM7.25579 28.4725C7.25565 28.4726 7.25593 28.4724 7.25579 28.4725V28.4725ZM1.1313 38.8178C0.402441 39.3027 -0.493287 38.7463 -0.455359 38.0123C-0.162552 32.3462 -0.511758 29.18 -2.63278 23.9121L-3 23H9.97411C10.7703 23 11.2621 23.8028 10.8524 24.4338L9.13007 27.0869L9.55123 27.8654C9.80422 28.333 9.59913 28.9017 9.09314 29.1355L7.91021 29.6822C7.86949 29.701 7.83364 29.7145 7.80412 29.7248C7.76753 29.7376 5.98671 30.3577 5.35523 33.2758C4.72573 36.1849 2.16235 38.1319 1.1313 38.8178Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask143" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="98">
          <path d="M5.58838 40.4888L10.0972 78.7298L14.564 93.9506C14.564 93.9506 17.8126 100.952 19.1989 96.051C20.5851 91.1501 22.3074 78.7298 22.3074 78.7298L23.8477 65.3713C23.8477 65.3713 23.8477 42.9673 27.8665 37.0862" fill="#DEE1E9"/>
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask143)">
          <path d="M5.58838 40.4888L10.0972 78.7298L14.564 93.9506C14.564 93.9506 17.8126 100.952 19.1989 96.051C20.5851 91.1501 22.3074 78.7298 22.3074 78.7298L23.8477 65.3713C23.8477 65.3713 23.8477 42.9673 27.8665 37.0862" fill="#DEE1E9"/>
          <path d="M20.613 1.54766L30.1628 13.2398C30.1628 13.2398 34.3635 28.9647 26.9282 38.4444C19.4928 47.9241 11.8194 44.6895 11.8194 44.6895C11.8194 44.6895 5.65829 43.7234 2.26967 34.5237C-1.11895 25.324 3.34787 11.3915 3.34787 11.3915L9.35497 6.1545L17.0984 1.54766C17.0984 1.54766 19.6468 0.315431 20.613 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M7.86628 30.5H-0.280481C1.19704 34.4705 1.44101 36.8811 1.23836 41.1241C1.23072 41.2841 1.40114 41.3836 1.53113 41.29C2.2173 40.7961 3.82886 39.4532 4.21952 37.5C4.69274 35.1339 6.0617 34.5593 6.20699 34.5045C6.21585 34.5012 6.22377 34.4978 6.23223 34.4936L7.04063 34.0894C7.13943 34.04 7.17947 33.9199 7.13008 33.8211L6.76862 33.0982C6.73804 33.037 6.74082 32.9645 6.776 32.9058L8.03778 30.8029C8.11776 30.6696 8.02174 30.5 7.86628 30.5Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M1.76699 40.4664C2.49205 39.8394 3.45424 38.777 3.72925 37.4019C4.23715 34.8625 5.72418 34.1555 6.02006 34.0407L6.54872 33.7764L6.32143 33.3218C6.21439 33.1077 6.22415 32.8538 6.34728 32.6486L7.33644 31H0.432505C1.64669 34.4491 1.90365 36.8121 1.76699 40.4664ZM6.00864 34.0464C6.00854 34.0465 6.00874 34.0464 6.00864 34.0464V34.0464ZM1.82326 41.6958C1.32516 42.0544 0.713034 41.643 0.738954 41.1003C0.939054 36.9106 0.700411 34.5695 -0.749066 30.6744L-1.00002 30H7.8663C8.41041 30 8.74649 30.5936 8.46655 31.0601L7.2895 33.0219L7.57731 33.5975C7.7502 33.9433 7.61005 34.3638 7.26426 34.5367L6.45586 34.9409C6.42803 34.9548 6.40354 34.9648 6.38336 34.9724C6.35836 34.9818 5.14137 35.4403 4.70983 37.5981C4.27964 39.749 2.52786 41.1887 1.82326 41.6958Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M5.96509 40.1931L10.4739 78.4341L14.9407 93.6549C14.9407 93.6549 18.1893 100.656 19.5756 95.7553C20.9618 90.8544 22.6841 78.4341 22.6841 78.4341L24.2244 65.0757C24.2244 65.0757 24.2244 42.6716 28.2432 36.7905" fill="#DEE1E9"/>
        <mask id="mask243" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask243)">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6923 16.7991C19.7544 16.7991 21 11.5008 21 1.90252L19.0769 0.0404528L16 -1.20093C16 8.3974 17.6302 16.7991 18.6923 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M10.7048 15.1615C8.75697 16.9676 11.2906 23.1309 12.9454 36.7423C13.498 41.5324 13.8597 46.355 14.0292 51.1915C14.366 59.0492 14.0292 60.2782 14.3953 70.4076C14.543 74.7767 14.905 79.1299 15.479 83.4417C16.0063 87.3333 17.6172 93.7805 17.9101 93.7991C18.4959 93.7991 17.6758 83.5907 17.9101 78.9915C18.0387 76.6171 18.0387 74.2343 17.9101 71.8599C17.7134 66.2722 17.8063 60.674 18.1884 55.1018C18.6863 45.0469 18.9499 40.038 20.3705 34.9547C21.791 29.8714 22.1865 24.8998 23.5045 20.0586C23.7681 19.1276 24.3979 16.8745 23.6363 15.9063C22.8748 14.938 21.4981 15.8132 19.8872 16.2228C14.7029 17.5076 12.3596 13.6346 10.7048 15.1615Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M5.96509 40.1931L10.4739 78.4341L14.9407 93.6549C14.9407 93.6549 18.1893 100.656 19.5756 95.7553C20.9618 90.8544 22.6841 78.4341 22.6841 78.4341L24.2244 65.0757C24.2244 65.0757 24.2244 42.6716 28.2432 36.7905" fill="#DEE1E9"/>
        <mask id="mask343" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask343)">
          <path d="M20.613 1.34673L30.1628 13.0389C30.1628 13.0389 34.3635 28.7637 26.9282 38.2435C19.4928 47.7232 11.8194 44.4886 11.8194 44.4886C11.8194 44.4886 5.65829 43.5224 2.26967 34.3228C-1.11895 25.1231 3.34787 11.1905 3.34787 11.1905L9.35497 5.95357L17.0984 1.34673C17.0984 1.34673 19.6468 0.114503 20.613 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M18.6923 16.7991C19.7544 16.7991 21 11.5008 21 1.90252L19.0769 0.0404528L16 -1.20093C16 8.3974 17.6302 16.7991 18.6923 16.7991Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M5.96509 40.4888L10.4739 78.7298L14.9407 93.9506C14.9407 93.9506 18.1893 100.952 19.5756 96.051C20.9618 91.1501 22.6841 78.7298 22.6841 78.7298L24.2244 65.3713C24.2244 65.3713 24.2244 42.9673 28.2432 37.0862" fill="#DEE1E9"/>
        <path d="M20.9897 1.54766L30.5395 13.2398C30.5395 13.2398 34.7402 28.9647 27.3049 38.4444C19.8695 47.9241 12.1961 44.6895 12.1961 44.6895C12.1961 44.6895 6.035 43.7234 2.64638 34.5237C-0.742237 25.324 3.72458 11.3915 3.72458 11.3915L9.73168 6.1545L17.4751 1.54766C17.4751 1.54766 20.0236 0.315431 20.9897 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M21 4.09473L22.8667 6.59559L24.5 5.09988V8.41393L27.0622 7.84602L25.6667 9.59662L28 11.5973L25.6667 13.598L27.0622 15.3486L24.7333 14.5983L24.5 18.0947L22.2501 16.5977L21.4667 17.5994L19.7499 16.5977L17.5 18.0947L16.8 16.0989L14.9378 15.3486L15.4 12.5976L14 11.5973L16.3333 10.0968L14.9378 7.84602L16.8 7.59593L17.5 5.09988L20.5333 7.09576L21 4.09473Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M5.96509 40.4888L10.4739 78.7298L14.9407 93.9506C14.9407 93.9506 18.1893 100.952 19.5756 96.051C20.9618 91.1501 22.6841 78.7298 22.6841 78.7298L24.2244 65.3713C24.2244 65.3713 24.2244 42.9673 28.2432 37.0862" fill="#DEE1E9"/>
        <path d="M20.9897 1.54766L30.5395 13.2398C30.5395 13.2398 34.7402 28.9647 27.3049 38.4444C19.8695 47.9241 12.1961 44.6895 12.1961 44.6895C12.1961 44.6895 6.035 43.7234 2.64638 34.5237C-0.742237 25.324 3.72458 11.3915 3.72458 11.3915L9.73168 6.1545L17.4751 1.54766C17.4751 1.54766 20.0236 0.315431 20.9897 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M21 4.09473L22.6 6.23832L24 4.95629V7.7969L26.1962 7.31012L25 8.81063L27 10.5255L25 12.2404L26.1962 13.7409L24.2 13.0978L24 16.0947L22.0715 14.8116L21.4 15.6701L19.9285 14.8116L18 16.0947L17.4 14.384L15.8038 13.7409L16.2 11.3829L15 10.5255L17 9.23935L15.8038 7.31012L17.4 7.09576L18 4.95629L20.6 6.66704L21 4.09473Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M5.96509 40.4888L10.4739 78.7298L14.9407 93.9506C14.9407 93.9506 18.1893 100.952 19.5756 96.051C20.9618 91.1501 22.6841 78.7298 22.6841 78.7298L24.2244 65.3713C24.2244 65.3713 24.2244 42.9673 28.2432 37.0862" fill="#DEE1E9"/>
        <path d="M20.9897 1.54766L30.5395 13.2398C30.5395 13.2398 34.7402 28.9647 27.3049 38.4444C19.8695 47.9241 12.1961 44.6895 12.1961 44.6895C12.1961 44.6895 6.035 43.7234 2.64638 34.5237C-0.742237 25.324 3.72458 11.3915 3.72458 11.3915L9.73168 6.1545L17.4751 1.54766C17.4751 1.54766 20.0236 0.315431 20.9897 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M21.5 4.09473L22.4333 5.34516L23.25 4.5973V6.25433L24.5311 5.97037L23.8333 6.84567L25 7.84602L23.8333 8.84636L24.5311 9.72166L23.3667 9.34653L23.25 11.0947L22.125 10.3462L21.7333 10.847L20.875 10.3462L19.75 11.0947L19.4 10.0968L18.4689 9.72166L18.7 8.34619L18 7.84602L19.1667 7.09576L18.4689 5.97037L19.4 5.84533L19.75 4.5973L21.2667 5.59524L21.5 4.09473Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M12.4689 75.6565L14.5526 76.9263H18.0363L20.1363 75.6565L18.5247 74.11L20.6084 72.5635L18.72 71.017L21.0154 69.324L19.1921 67.7775L21.4875 66.3775L19.4689 64.9612L21.4875 63.3496L19.7944 61.803L22.0247 60.3868L20.2665 58.9217L22.5619 57.3751L20.413 55.8286L22.5619 54.217L21.0154 52.8659L23.0991 51.3193L21.2758 49.838L23.3595 48.2914L21.5526 46.5496L22.5619 44.8728V30.6124L20.6735 23.2868H14.5526" fill="#8889BE"/>
        <path d="M12.4689 75.6565L14.5526 76.9263H18.0363L20.1363 75.6565L18.5247 74.11L20.6084 72.5635L18.72 71.017L21.0154 69.324L19.1921 67.7775L21.4875 66.3775L19.4689 64.9612L21.4875 63.3496L19.7944 61.803L22.0247 60.3868L20.2665 58.9217L22.5619 57.3751L20.413 55.8286L22.5619 54.217L21.0154 52.8659L23.0991 51.3193L21.2758 49.838L23.3595 48.2914L21.5526 46.5496L22.5619 44.8728V30.6124L20.6735 23.2868H14.5526" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M19.8761 75.6565L17.7924 76.9263H14.325L12.2087 75.6565L13.8366 74.11L11.7366 72.5635L13.625 71.017L11.3459 69.324L13.1529 67.7775L10.8738 66.3775L12.8924 64.9612L10.8738 63.3496L12.5506 61.803L10.3366 60.3868L12.0785 58.9217L9.79943 57.3751L11.9483 55.8286L9.79943 54.217L11.3459 52.8659L9.26222 51.3193L11.0692 49.838L8.98547 48.2914L10.8087 46.5496L9.79943 44.8728V30.6124L11.6715 23.2868H15.5785" fill="#8889BE"/>
        <path d="M19.8761 75.6565L17.7924 76.9263H14.325L12.2087 75.6565L13.8366 74.11L11.7366 72.5635L13.625 71.017L11.3459 69.324L13.1529 67.7775L10.8738 66.3775L12.8924 64.9612L10.8738 63.3496L12.5506 61.803L10.3366 60.3868L12.0785 58.9217L9.79943 57.3751L11.9483 55.8286L9.79943 54.217L11.3459 52.8659L9.26222 51.3193L11.0692 49.838L8.98547 48.2914L10.8087 46.5496L9.79943 44.8728V30.6124L11.6715 23.2868H15.5785" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask543" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="38">
          <path d="M30.1628 12.8924L20.613 1.20024C19.6468 -0.0319816 17.0984 1.20024 17.0984 1.20024L9.35497 5.80709L3.34787 11.044C3.34787 11.044 -1.11895 24.9766 2.26967 34.1763C2.68475 35.3032 3.14143 36.3065 3.62329 37.1997H8.62329L10.6233 26.7473C14.3334 25.1936 16.4135 25.1445 20.1233 26.7473L23.1233 37.1997H27.5855C34.1076 27.6591 30.1628 12.8924 30.1628 12.8924Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask543)">
          <path d="M30.1628 12.8924L20.613 1.20024C19.6468 -0.0319816 17.0984 1.20024 17.0984 1.20024L9.35497 5.80709L3.34787 11.044C3.34787 11.044 -1.11895 24.9766 2.26967 34.1763C2.68475 35.3032 3.14143 36.3065 3.62329 37.1997H8.62329L10.6233 26.7473C14.3334 25.1936 16.4135 25.1445 20.1233 26.7473L23.1233 37.1997H27.5855C34.1076 27.6591 30.1628 12.8924 30.1628 12.8924Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M26.2032 49.1526H5.20325V23.6046C13.4043 21.6784 18.0022 21.6588 26.2032 23.6046V49.1526Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M12.4689 75.948L14.5526 77.2178H18.0363L20.1363 75.948L18.5247 74.4015L20.6084 72.855L18.72 71.3085L21.0154 69.6155L19.1921 68.069L21.4875 66.669L19.4689 65.2527L21.4875 63.6411L19.7944 62.0945L22.0247 60.6783L20.2665 59.2132L22.5619 57.6667L20.413 56.1201L22.5619 54.5085L21.0154 53.1574L23.0991 51.6109L21.2758 50.1295L23.3595 48.5829L21.5526 46.8411L22.5619 45.1644V30.9039L20.6735 23.5784H14.5526" fill="#8889BE"/>
        <path d="M12.4689 75.948L14.5526 77.2178H18.0363L20.1363 75.948L18.5247 74.4015L20.6084 72.855L18.72 71.3085L21.0154 69.6155L19.1921 68.069L21.4875 66.669L19.4689 65.2527L21.4875 63.6411L19.7944 62.0945L22.0247 60.6783L20.2665 59.2132L22.5619 57.6667L20.413 56.1201L22.5619 54.5085L21.0154 53.1574L23.0991 51.6109L21.2758 50.1295L23.3595 48.5829L21.5526 46.8411L22.5619 45.1644V30.9039L20.6735 23.5784H14.5526" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M19.8761 75.948L17.7924 77.2178H14.325L12.2087 75.948L13.8366 74.4015L11.7366 72.855L13.625 71.3085L11.3459 69.6155L13.1529 68.069L10.8738 66.669L12.8924 65.2527L10.8738 63.6411L12.5506 62.0945L10.3366 60.6783L12.0785 59.2132L9.79943 57.6667L11.9483 56.1201L9.79943 54.5085L11.3459 53.1574L9.26222 51.6109L11.0692 50.1295L8.98547 48.5829L10.8087 46.8411L9.79943 45.1644V30.9039L11.6715 23.5784H15.5785" fill="#8889BE"/>
        <path d="M19.8761 75.948L17.7924 77.2178H14.325L12.2087 75.948L13.8366 74.4015L11.7366 72.855L13.625 71.3085L11.3459 69.6155L13.1529 68.069L10.8738 66.669L12.8924 65.2527L10.8738 63.6411L12.5506 62.0945L10.3366 60.6783L12.0785 59.2132L9.79943 57.6667L11.9483 56.1201L9.79943 54.5085L11.3459 53.1574L9.26222 51.6109L11.0692 50.1295L8.98547 48.5829L10.8087 46.8411L9.79943 45.1644V30.9039L11.6715 23.5783H15.5785" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask543" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="38">
          <path d="M30.1628 13.1836L20.613 1.4915C19.6468 0.259278 17.0984 1.4915 17.0984 1.4915L9.35497 6.09834L3.34787 11.3353C3.34787 11.3353 -1.11895 25.2678 2.26967 34.4675C2.68475 35.5944 3.14143 36.5978 3.62329 37.4909H8.62329L10.6233 27.0386C14.3334 25.4849 16.4135 25.4358 20.1233 27.0386L23.1233 37.4909H27.5855C34.1076 27.9504 30.1628 13.1836 30.1628 13.1836Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask543)">
          <path d="M30.1628 13.1836L20.613 1.4915C19.6468 0.259278 17.0984 1.4915 17.0984 1.4915L9.35497 6.09834L3.34787 11.3353C3.34787 11.3353 -1.11895 25.2678 2.26967 34.4675C2.68475 35.5944 3.14143 36.5978 3.62329 37.4909H8.62329L10.6233 27.0386C14.3334 25.4849 16.4135 25.4358 20.1233 27.0386L23.1233 37.4909H27.5855C34.1076 27.9504 30.1628 13.1836 30.1628 13.1836Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </g>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M0 0.259766H21V5.25977H0V0.259766Z" fill="#838AA2"/>
        <path d="M22 0.259766H43V5.25977H22V0.259766Z" fill="#838AA2"/>
        <path d="M5.62329 25.6777L14.0972 81.3127L18.564 96.5335C18.564 96.5335 21.8126 103.535 23.1989 98.6339C24.5851 93.733 26.3074 81.3127 26.3074 81.3127L27.8477 67.9543C27.8477 67.9543 31.1046 31.0588 35.1233 25.1777" fill="#DEE1E9"/>
        <path d="M34.1628 15.8228L24.613 4.13066C23.6468 2.89844 21.0984 4.13066 21.0984 4.13066L13.355 8.7375L7.34787 13.9745C7.34787 13.9745 2.88105 27.907 6.26967 37.1067C6.68475 38.2336 7.14143 39.2369 7.62329 40.1301H12.6233L14.6233 29.6777C18.3334 28.124 20.4135 28.0749 24.1233 29.6777L27.1233 40.1301H31.5855C38.1076 30.5896 34.1628 15.8228 34.1628 15.8228Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M5.80762 39.9993L10.3164 78.2403L14.7833 93.4611C14.7833 93.4611 18.0319 100.462 19.4181 95.5615C20.8044 90.6606 22.5267 78.2403 22.5267 78.2403L24.067 64.8818C24.067 64.8818 24.067 42.4778 28.0857 36.5967" fill="#DEE1E9"/>
        <path d="M20.8323 1.0584L30.382 12.7505C30.382 12.7505 34.5828 28.4754 27.1474 37.9551C19.7121 47.4349 12.0387 44.2003 12.0387 44.2003C12.0387 44.2003 5.87753 43.2341 2.48891 34.0344C-0.899708 24.8347 3.56711 10.9022 3.56711 10.9022L9.57421 5.66524L17.3176 1.0584C17.3176 1.0584 19.8661 -0.173827 20.8323 1.0584Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M10.9746 34.0064C9.11228 35.3647 11.5347 39.9995 13.117 50.2354C13.6453 53.8376 13.9912 57.4642 14.1532 61.1014C14.4753 67.0104 14.1532 67.9346 14.5033 75.552C14.6445 78.8376 14.9906 82.1113 15.5395 85.3538C16.0435 88.2803 17.5838 93.1286 17.8639 93.1426C18.424 93.1426 17.6398 85.4658 17.8639 82.0072C17.9868 80.2216 17.9868 78.4298 17.8639 76.6442C17.6758 72.4422 17.7647 68.2323 18.1299 64.0419C18.606 56.4805 18.8581 52.7138 20.2163 48.8911C21.5746 45.0684 21.9526 41.3298 23.2129 37.6891C23.4649 36.989 24.067 35.2947 23.3389 34.5665C22.6107 33.8384 21.2945 34.4965 19.7542 34.8046C14.7973 35.7707 12.5569 32.8582 10.9746 34.0064Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M5.80762 39.9993L10.3164 78.2403L14.7833 93.4611C14.7833 93.4611 18.0319 100.462 19.4181 95.5615C20.8044 90.6606 22.5267 78.2403 22.5267 78.2403L24.067 64.8818C24.067 64.8818 24.067 42.4778 28.0857 36.5967" fill="#DEE1E9"/>
        <path d="M20.8323 1.0584L30.382 12.7505C30.382 12.7505 34.5828 28.4754 27.1474 37.9551C19.7121 47.4349 12.0387 44.2003 12.0387 44.2003C12.0387 44.2003 5.87753 43.2341 2.48891 34.0344C-0.899708 24.8347 3.56711 10.9022 3.56711 10.9022L9.57421 5.66524L17.3176 1.0584C17.3176 1.0584 19.8661 -0.173827 20.8323 1.0584Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.4" d="M10.9746 34.0064C9.11228 35.3647 11.5347 39.9995 13.117 50.2354C13.6453 53.8376 13.9912 57.4642 14.1532 61.1014C14.4753 67.0104 14.1532 67.9346 14.5033 75.552C14.6445 78.8376 14.9906 82.1113 15.5395 85.3538C16.0435 88.2803 17.5838 93.1286 17.8639 93.1426C18.424 93.1426 17.6398 85.4658 17.8639 82.0072C17.9868 80.2216 17.9868 78.4298 17.8639 76.6442C17.6758 72.4422 17.7647 68.2323 18.1299 64.0419C18.606 56.4805 18.8581 52.7138 20.2163 48.8911C21.5746 45.0684 21.9526 41.3298 23.2129 37.6891C23.4649 36.989 24.067 35.2947 23.3389 34.5665C22.6107 33.8384 21.2945 34.4965 19.7542 34.8046C14.7973 35.7707 12.5569 32.8582 10.9746 34.0064Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M5.80762 39.9993L10.3164 78.2403L14.7833 93.4611C14.7833 93.4611 18.0319 100.462 19.4181 95.5615C20.8044 90.6606 22.5267 78.2403 22.5267 78.2403L24.067 64.8818C24.067 64.8818 24.067 42.4778 28.0857 36.5967" fill="#DEE1E9"/>
        <path d="M20.8323 1.0584L30.382 12.7505C30.382 12.7505 34.5828 28.4754 27.1474 37.9551C19.7121 47.4349 12.0387 44.2003 12.0387 44.2003C12.0387 44.2003 5.87753 43.2341 2.48891 34.0344C-0.899708 24.8347 3.56711 10.9022 3.56711 10.9022L9.57421 5.66524L17.3176 1.0584C17.3176 1.0584 19.8661 -0.173827 20.8323 1.0584Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <mask id="mask543" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="31" width="17" height="36">
          <path d="M21.6043 66.3398L13.5274 66.3397L8.81145 31.7083H24.4403L21.6043 66.3398Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask543)">
          <path opacity="0.2" d="M10.9746 34.0064C9.11228 35.3647 11.5347 39.9995 13.117 50.2354C13.6453 53.8376 13.9912 57.4642 14.1532 61.1014C14.4753 67.0104 14.1532 67.9346 14.5033 75.552C14.6445 78.8376 14.9906 82.1113 15.5395 85.3538C16.0435 88.2803 17.5838 93.1286 17.8639 93.1426C18.424 93.1426 17.6398 85.4658 17.8639 82.0072C17.9868 80.2216 17.9868 78.4298 17.8639 76.6442C17.6758 72.4422 17.7647 68.2323 18.1299 64.0419C18.606 56.4805 18.8581 52.7138 20.2163 48.8911C21.5746 45.0684 21.9526 41.3298 23.2129 37.6891C23.4649 36.989 24.067 35.2947 23.3389 34.5665C22.6107 33.8384 21.2945 34.4965 19.7542 34.8046C14.7973 35.7707 12.5569 32.8582 10.9746 34.0064Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5043" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="66" width="10" height="29">
          <path d="M18.9852 92.6017L18.2854 94.6367H16.8856L15.8975 93.3368L11.3687 66.4055H20.1381L19.7675 78.3562L19.3146 88.9273L18.9852 92.6017Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5043)">
          <path opacity="0.4" d="M10.9746 34.0064C9.11228 35.3647 11.5347 39.9995 13.117 50.2354C13.6453 53.8376 13.9912 57.4642 14.1532 61.1014C14.4753 67.0104 14.1532 67.9346 14.5033 75.552C14.6445 78.8376 14.9906 82.1113 15.5395 85.3538C16.0435 88.2803 17.5838 93.1286 17.8639 93.1426C18.424 93.1426 17.6398 85.4658 17.8639 82.0072C17.9868 80.2216 17.9868 78.4298 17.8639 76.6442C17.6758 72.4422 17.7647 68.2323 18.1299 64.0419C18.606 56.4805 18.8581 52.7138 20.2163 48.8911C21.5746 45.0684 21.9526 41.3298 23.2129 37.6891C23.4649 36.989 24.067 35.2947 23.3389 34.5665C22.6107 33.8384 21.2945 34.4965 19.7542 34.8046C14.7973 35.7707 12.5569 32.8582 10.9746 34.0064Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M27.1424 36.7034C23.1796 42.6965 23.1656 64.9466 23.1656 64.9466L21.6253 78.305C21.6253 78.305 19.861 90.7393 18.4608 95.6682C17.0605 100.597 13.8259 93.5678 13.8259 93.5678L9.41512 78.347L4.86429 40.12C3.43897 38.3291 2.32214 36.3131 1.55968 34.1549C-1.84294 24.8993 2.63788 11.0227 2.63788 11.0227L8.65898 5.79977L16.3604 1.19293C16.3604 1.19293 18.9369 0.00270844 19.917 1.19293L29.4668 12.8851C29.4668 12.8851 33.2475 27.2237 27.1424 36.7034Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M5.14893 40.4873L9.65775 78.7283L14.1246 93.949C14.1246 93.949 17.3732 100.95 18.7594 96.0494C20.1457 91.1485 21.868 78.7283 21.868 78.7283L23.4083 65.3698C23.4083 65.3698 23.4083 42.9657 27.427 37.0847" fill="#DEE1E9"/>
      <path d="M20.1736 1.54619L29.7233 13.2383C29.7233 13.2383 33.9241 28.9632 26.4887 38.4429C19.0534 47.9227 11.38 44.6881 11.38 44.6881C11.38 44.6881 5.21884 43.7219 1.83022 34.5222C-1.5584 25.3225 2.90842 11.39 2.90842 11.39L8.91551 6.15303L16.6589 1.54619C16.6589 1.54619 19.2074 0.313966 20.1736 1.54619Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth43",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
