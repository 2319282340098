export default {
    accept_payment: "To‘lovni qabul qilish",
    added: "Qo‘shdi",
    add_new_record: "+ Tashrifni qo‘shish",
    additionally: "Qo‘shimcha ravishda",
    are_you_sure_want_to_move_visit: "Haqiqatan ham tashrifni boshqa joyga o‘tkazmoqchimisiz?",
    are_you_sure_want_to_change_time: "Siz haqiqatan ham tashrif vaqtini o‘zgartirmoqchisizmi?",
    are_you_sure_you_want_to_delete_record: "Haqiqatan ham yozuvni o‘chirmoqchimisiz?",
    blocked: "Bloklangan",
    by_phone_found: "Telefon raqami bo‘yicha {phone} quyidagi bemorlar topildi",
    by_phone_nobody_found: "Telefon raqami bo‘yicha {phone} bemor topilmadi",
    calendar_settings: "Kalendar sozlamalari",
    cancel_record: "Yozuvni bekor qilish",
    change_record: "Yozuvni o‘zgartirish",
    checkup: "Ko‘rib chiqish",
    child_age: "18 yoshdan kichik",
    choose_all: "Hammasini tanlang",
    clear: "Tozalash",
    create_notification: "Bildirishnoma yaratish",
    create_patient: "Bemorni yaratish",
    create_task: "Vazifa yaratish",
    confirm_visit: "Tashrifni tasdiqlash",
    curation: "Kurasiya",
    DAY: "Kunda",
    day_short: "kun",
    days: "kunlar",
    delete_record: "Tashrifni o‘chirish",
    diagnostics: "Tashxis",
    doctor_available_time: "Shifokorning bo‘sh vaqti",
    doctor_has_no_available_time_at_this_day: "Shifokorning bu kuni bo‘sh vaqti yo‘q",
    doctor_is_not_working_on_this_date_continue: "Bu vaqtda shifokor ishlamayapti. Davom ettirilsinmi?",
    doctors_selected: "Shifokorlar tanlandi",
    edit_schedule: "Jadvalni tahrirlash",
    edit_visit: "Tashrifni tahrirlash",
    email_text: "Xat matni",
    email_topic: "Xat mavzusi",
    employees_selected: "Xodimlar tanlandi",
    fillDoctorColor: "Yozuvni shifokor rangi bilan bo‘yash",
    fill_treatment: "To'liq davolash",
    for: "uchun",
    free_minutes: "Bepul daqiqalar",
    hideChairsWithoutSchedule: "Belgilangan kunda ish jadvali bilan shifokorlarsiz stullarni yashiring",
    hideDoctorsWithoutSchedule: "Tanlangan kunda ishlamaydigan shifokorlarni yashirish",
    hour_short: "s",
    info: "Ma’lumot",
    load_demo_data_to_account: "Demo ma’lumotlarni hisobga yuklash?",
    min_short: "daq",
    move_record: "Transfer rekordi",
    need_to_select_at_least_one_doctor: "Kamida bitta shifokorni tanlash kerak",
    need_to_select_at_least_one_employee: "Kamida bitta xodimni tanlash kerak",
    new_visit: "Yangi tashrif",
    no_birthdays_on_this_date: "Belgilangan sanada tug‘ilgan kunlar yo‘q",
    no_free_time: "Bo'sh vaqt yo'q",
    no_schedule: "Vaqt jadvali yo'q",
    no_visits: "Tashriflar yo‘q",
    not_working: "Ishlamayapti",
    of_day: "kun",
    online_consultation: "Onlayn maslahat",
    patient_came: "Bemor keldi",
    patient_card: "Bemor kartochkasi",
    patient_notifications: "Bemorga bildirishnoma",
    postponed: "Transfer",
    quick_view: "Tez ko‘rikdan o‘tkazish",
    record_again: "Yana yozib oling",
    record_conflict: "Qarama-qarshilikni yozing",
    record_has_no_paid_services: "Yozuvda pullik xizmatlar yo‘q",
    record_type: "Yozuv turi",
    related_tasks: "Bog‘langan vazifalar",
    requires_confirmation: "Tasdiqlash talab qiladi",
    return_visit: "Qayta tashrif",
    sanation: "Sanatsiya",
    schedule: "Jadval",
    select_a_doctor: "Shifokorni tanlang",
    select_an_employee: "Xodimni tanlang",
    selected: "Tanlandi",
    send_sms: "SMS yuborish",
    sms_text: "SMS matni",
    showClientTags: "Bemorlarni belgilash ranglaridan foydalanish",
    showDoctorColor: "Shifokorlarning ranglaridan foydalanish",
    showPlannedServices: "Xizmatlarni matnda ko'rsatish",
    showRecordTagColor: "Tashrif foni sifatida teglarning ranglarini ishlating",
    showServiceColor: "Xizmat toifasidagi ranglardan foydalanish",
    showStatusColor: "Tashrif holati ranglaridan foydalanish",
    showOverflowScreen: "Katta ma’lumotlar to‘plami mavjud bo‘lganda ekranni aylantirishdan foydalanish",
    till: "oldin",
    time_has_already_passed: "Vaqt o‘tdi",
    tomorrow: "Ertaga",
    visits_count: "Tashriflar yo‘ | tashrif | tashrif",
    visits_count_much: "tashrif",
    warranty_case: "Kafolat ishi",
    write_reason_for_cancellation: "Bekor qilish sababini ko‘rsating",
    yesterday: "Kecha",
    you_have_no_doctors: "Sizda bironta ham shifokor yo‘q. Siz shifokorni <a href='{href}'>bu yerda</a>qo‘shishingiz mumkin",
    WEEK: "Haftaga",
    MONTH: "Oyga",
    CHAIR: "O‘rindiq bo‘yicha",
    DOCTOR: "Shifokor bo‘yicha",
}
