<template>
  <div class="task-status nowrap" :class="`status-${object.status}`">
    {{ $t(object.status) }}
  </div>
</template>

<script>
export default {
  name: "CRMDatatableTDTaskStatus",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">
  .task-status {
    display: inline-block;
    padding: 3px 7px;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    &.status-planned {
      background: $label-color;
    }
    &.status-inprogress {
      background: $warning-yellow;
    }
    &.status-for_rework {
      background: $label-color;
    }
    &.status-cancelled {
      background: $danger-red;
    }
    &.status-done {
      background: $green-color;
    }
  }
</style>
