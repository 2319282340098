export default {
    primaryKey: "id",
    model: 'marketing_mailing',
    modaltitle: 'mailing',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    actionscomponent: 'MarketingMailingDatatableActions',
    deletemessage: 'are_you_sure_you_want_to_delete_mailing',
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
            tdcomponent: 'MarketingDatatableTDMailingTitle',
        },
        {
            id: 'group_id',
            title: 'group',
            table: true,
            table_show: 'marketing_group.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                required: true,
                select_data: {
                    "title": "title",
                },
            },
            tdcomponent: 'MarketingDatatableTDMailingGroupTitle'
        },
        {
            id: 'group_id',
            title: 'total_in_group',
            table: true,
            table_show: 'marketing_group.patientsCount',
            sortable: false,
        },
        {
            id: 'send_time',
            title: 'mailing_date',
            table: true,
            sortable: false,
            tdcomponent: 'MarketingDatatableTDMailingDate',
        },
        {
            id: 'sent',
            title: 'sent_slash_error',
            table: true,
            tdcomponent: 'MarketingDatatableTDSentError',
        },
        {
            id: 'notification_channel_id',
            title: 'notification_channel',
            table: false,
            form: {
                type: "select",
                select_type: "model",
                required: true,
                select_data: {
                    "title": "title",
                },
                component: 'MarketingMailingAdditionalFormComponent'
            }
        },
        {
            id: 'text',
            title: 'text',
            table: false,
            // form: {
            //     type: "textarea",
            //     required: true
            // }
        },
    ]
}
