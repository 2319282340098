<template>
  <b-modal id="lead-show-modal"
           :title="`${$t('lead')} #${lead ? lead.id : '-'}`"
           @show="show = true"
           @hide="show = false"
           hide-footer
           size="xl"
           centered>
    <LeadShowContent v-if="show"/>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import LeadShowContent from "@/components/parts/crm/LeadShowContent.vue"

export default {
  name: "LeadShowModal",
  components: {
    LeadShowContent,
  },
  computed: {
    ...mapState({
      lead: state => state.crm.lead,
    }),
  },
  data() {
    return {
      show: false,
    }
  },
  methods: {

  },
}
</script>
