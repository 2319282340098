<template>
  <div>
    <b-row class="align-items-end" v-if="loaded && !editBlock">

      <b-col md="6" cols="12" v-if="chairs.length">
        <ServiceMultiSelect :label="$t('chair')"
                            :dblclick="false"
                            model="chair"
                            :readonly="!canBeEdited"
                            :required="chairs.length > 0"
                            :multiple="false"
                            :prepopulated-options="chairs"
                            :internalSearch="true"
                            title="chair_name"
                            :dbsearch="false"
                            v-model="chair"/>
        <ValidationError validationId="chair_id"/>
      </b-col>
      <b-col md="6" cols="12">
        <ServiceMultiSelect :label="$t('doctor')"
                            :dblclick="false"
                            model="doctor"
                            :readonly="!canBeEdited"
                            :required="true"
                            :multiple="false"
                            :prepopulated-options="doctors"
                            :internalSearch="true"
                            title="full_name"
                            :dbsearch="false"
                            v-model="doctor"/>
        <!--        <SelectGroup :options="doctors"-->
        <!--                     title="full_name"-->
        <!--                     :showlabel="true"-->
        <!--                     :required="true"-->
        <!--                     :readonly="!canBeEdited"-->
        <!--                     :label="$t('doctor')"-->
        <!--                     variant="white"-->
        <!--                     v-model="visit.doctor_id"/>-->
        <ValidationError validationId="doctor_id"/>
      </b-col>
    </b-row>
    <b-row class="align-items-end" v-if="loaded && !editBlock">
      <b-col md="3" cols="6">
        <SelectGroup :options="recordTypes"
                     :showlabel="true"
                     :required="true"
                     :nullOption="false"
                     :label="$t('record_type')"
                     variant="white"
                     v-model="visit.record_type"/>
      </b-col>
      <b-col md="3" cols="6">
        <DateGroup :required="true"
                   class="right-popup"
                   @input="dateChanged"
                   :readonly="!canBeEdited"
                   :label="$t('visit_date')"
                   v-model="date"/>
        <ValidationError validationId="start"/>
      </b-col>
      <b-col md="3" cols="6">
        <TimeGroup :required="true"
                   :key="`time-${timecounter}`"
                   class="w-100"
                   :readonly="!canBeEdited"
                   :label="$t('visit_time')"
                   v-model="time"/>
      </b-col>
      <b-col md="3" cols="6">
        <div class="position-relative d-flex align-items-end">
          <InputGroup :label="$t('duration')"
                      :show-errors="true"
                      validation-id="end"
                      :validation-margin="false"
                      type="number"
                      class="d-inline-block align-middle no-right-radius no-arrows-number-input"
                      :readonly="!canBeEdited"
                      v-model="duration"/>
          <div class="d-inline-flex form-group align-middle flex-column plus-minus-block lg" :class="{ disabled: !canBeEdited }">
            <a class="plus"
               tabindex="#"
               @click="durationChange('plus')">
              +
            </a>
            <a class="minus"
               tabindex="#"
               @click="durationChange('minus')">
              -
            </a>
          </div>
        </div>
      </b-col>
      <b-col cols="12" v-if="visit.doctor_id && canBeEdited" class="available-times">
        <b-row class="align-items-center row-narrow mb-3">
          <b-col cols="auto" v-if="doctorAvailableTimes && doctorAvailableTimes.length">
            <div class="pr-2">
              <b>{{ $t('doctor_available_time') }}</b>
            </div>
          </b-col>
          <b-col v-if="!doctorAvailableTimes || doctorAvailableTimes.length === 0">
            <span class="text-danger mr-2">{{ $t('doctor_has_no_available_time_at_this_day') }}:</span>
            <router-link :to="{ name: 'StaffDoctorSchedule', params: { id: visit.doctor_id }, query: { modal: 'schedule'} }">{{ $t('edit_schedule') }}</router-link>
          </b-col>
          <b-col cols="auto" v-for="(d, dind) in doctorAvailableTimes" :key="`doctor-av-${dind}`">
            <span class="d-inline-block available-time-item m-1 pointer" @click="timeSlotSelected(d)">{{ d.time }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" class="mb-3" v-if="isOnlineBooking">

        <div class="card bg-light p-3">
          <div v-if="visit.possiblePatients.length">
            {{ $t('by_phone_found', { phone: visit.online_booking_record.phone}) }}:
            <a class="text-underlined d-block pointer" v-for="p in visit.possiblePatients"
               @click="patient = p"
               :key="`pat-p-${p.id}`">{{ p.full_name }} ({{ p.phone }})
            </a>
          </div>
          <div v-else>{{ $t('by_phone_nobody_found', { phone: visit.online_booking_record.phone}) }}</div>
        </div>

      </b-col>
      <b-col md="8" cols="12">
        <ServiceMultiSelect :label="$t('patient')"
                            :dblclick="false"
                            model="patient"
                            :custom-label="(e) => `<div class='row align-items-center no-gutters'>
<div class='col'>
<div>${e.full_name}</div>
<div style='opacity: 0.55; line-height: normal; font-size: 85%; padding-bottom: 7px;'>${e.phone}</div>
</div>
<div class='col-auto'>
<div style='max-width: 130px; opacity: 0.55; word-break: break-all; overflow-wrap: break-word; text-align: right; line-height: normal'>${(e.tags || []).map(x => x.title).join('<br/>')}</div>
</div>
</div>`"
                            :readonly="!patientCanBeEdited"
                            :required="visit.record_type !== 'block'"
                            :multiple="false"
                            :internalSearch="false"
                            title="full_name"
                            ref="patientSelect"
                            :key="`patient-select-key-${patientSelectKey}`"
                            :dbsearch="true"
                            v-model="patient"/>
        <ValidationError validationId="client_id"/>
      </b-col>
      <b-col md="4" cols="12" v-if="canBeEdited">
        <div class="form-group">
          <a @click="addPatient"
             class="btn btn-themed btn-themed-outline btn-themed-squared w-100 add-patient-btn">
            {{ $t('create_patient') }}
          </a>
        </div>
      </b-col>
      <b-col cols="12">
        <SelectGroup :options="statuses"
                     :nullOption="false"
                     :showlabel="true"
                     :required="true"
                     :label="$t('status')"
                     v-if="rights.includes('recording_status')"
                     variant="white"
                     v-model="visit.records_statuses_id"/>
        <ValidationError validationId="records_statuses_id"/>
      </b-col>
      <b-col cols="12">
        <SelectGroup :options="clientSources"
                     :nullOption="true"
                     :showlabel="true"
                     :translate="false"
                     :required="false"
                     :label="$t('source')"
                     variant="white"
                     v-model="visit.source_id"/>
        <ValidationError validationId="source_id"/>
      </b-col>
      <b-col cols="12">
        <ServiceMultiSelect :label="$t('fields')"
                            :dblclick="false"
                            :multiple="false"
                            title="translatedTitle"
                            model="field"
                            :dbsearch="true"
                            v-model="visit.fields"/>
      </b-col>

    </b-row>
    <AutosizeTextArea v-model="visit.description"
                      :label="$t('description')"/>
    <div v-if="!editBlock">
      <div class="d-inline-block align-middle" v-if="recordTags.length">
        <span class="mr-3">{{ $t('tags') }}:</span>
        <span v-for="(t, tind) in visit.tags"
              :key="`made-tag-${tind}`"
              class="mr-3 pointer"
              @click="toggleTag(t, 'remove', tind)">
            <a class="circle mr-1"
               :style="`background-color: ${t.color}`"
               :title="t.title"
               v-b-tooltip.hover
            ></a>
            {{ t.title }}
          </span>
      </div>
      <b-dropdown right variant="link" no-caret v-if="currentRecordTags.length < recordTags.length">
        <template #button-content>
          <a class="pointer position-relative">
            <PlusSvg/>
            <span class="ml-1">{{ $t('tag') }}</span>
          </a>
        </template>
        <div class="pt-0">
          <template v-for="(t, tind) in recordTags">
            <a class="dropdown-item pointer"
               :key="`tag-${tind}`"
               @click="toggleTag(t, 'add')"
               v-if="!currentRecordTags.includes(t.id)">
              <span class="circle mr-2" :style="`background-color: ${t.color}`"></span>
              {{ t.title }}
            </a>
          </template>
        </div>
      </b-dropdown>
    </div>
  </div>
</template>

<script>

import ValidationError from "@/components/form/ValidationError"
import SelectGroup from "@/components/form/SelectGroup"
import DateGroup from "@/components/form/DateGroup"
import TimeGroup from "@/components/form/TimeGroup"
import InputGroup from "@/components/form/InputGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { mapState } from "vuex"
import { DoctorService, EloquentService } from "@/services/api.service"
import moment from "moment"
import { recordStatuses, recordTypes } from "@/dictionaries/dictionary"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import PlusSvg from '@/assets/svg-vue/patients/plus_tag.svg'

export default {
  name: "VisitModalDescriptionPart",
  props: {
    visit: {}
  },
  data() {
    return {
      date: null,
      time: null,
      duration: 60,
      patient: null,
      chair: null,
      doctor: null,
      doctorAvailableTimes: null,
      statuses: recordStatuses,
      recordTypes: recordTypes,
      loaded: false,
      timecounter: 1,
      patientSelectKey: 1,
      conflictingRecord: null
    }
  },
  components: {
    AutosizeTextArea,
    ValidationError,
    SelectGroup,
    DateGroup,
    TimeGroup,
    InputGroup,
    ServiceMultiSelect,
    PlusSvg
  },
  computed: {
    ...mapState({
      // errors: state => state.datatable.validationErrors,
      // loading: state => state.dom.loading,
      doctorsFromAuth: state => state.auth.doctors,
      doctorsFromCalendar: state => state.calendar.doctors,
      doctorsWithChairs: state => state.calendar.doctors,
      chairs: state => state.auth.branchChairs,
      timeStep: state => state.calendar.timeStep,
      newVisitTime: state => state.calendar.newVisitTime,
      newVisitDoctor: state => state.calendar.newVisitDoctor,
      newVisitChair: state => state.calendar.newVisitChair,
      phoneFromCall: state => state.calendar.phoneFromCall,
      newEloquentObject: state => state.datatable.newEloquentObject,
      rights: state => state.auth.rights,
      branch: state => state.auth.branch,
      recordTags: state => state.auth.record_tags,
      records: state => state.calendar.records,
      leadForRecord: state => state.calendar.leadForRecord,
      clientSources: state => state.auth.clientSources
    }),
    doctors() {
      return this.doctorsFromCalendar.length ? this.doctorsFromCalendar : this.doctorsFromAuth
    },
    datetime() {
      return this.date+' '+this.time+':00'
    },
    doctor_id() {
      return this.visit ? this.visit.doctor_id : null
    },
    chair_id() {
      return this.visit ? this.visit.chair_id : null
    },
    canBeEdited() {
      return this.visit && this.visit.is_paid !== '1'
    },
    patientCanBeEdited() {
      if(this.isOnlineBooking) return this.canBeEdited
      if(this.visit?.id && this.visit.client_id) return false
      return this.canBeEdited
    },
    currentRecordTags() {
      return (this.visit && this.visit.tags) ? this.visit.tags.map(el => el.id) : []
    },
    dateIsInSchedule() {
      if(!this.visit.start) return true
      if(!this.doctorAvailableTimes) return false
      let res = false
      let endTime = this.visit.end.substr(11, 5);
      for(const ind in this.doctorAvailableTimes) {
        let x = this.doctorAvailableTimes[ind]
        let end = this.findEndTime(x, ind)
        if(this.time >= x.start && endTime <= end) {
          return true
        }
      }
      return res
    },
    editBlock() {
      return this.visit?.id && this.visit?.record_type === 'block'
    },
    isOnlineBooking() {
      return this.visit?.record_type == 'online'
    },
  },
  methods: {
    calcEnd() {
      this.$set(this.visit, 'end', moment(this.datetime).add(this.duration, 'minutes').format('YYYY-MM-DD HH:mm:ss'))
    },
    findEndTime(time, ind) {
      let end = time.time.substr(8,5)
      ind = parseInt(ind)
      const nextSlot = this.doctorAvailableTimes[((ind) + 1)] ?? null
      if(!nextSlot || nextSlot.start != end) return end
      return this.findEndTime(nextSlot, (ind + 1))
    },
    reloadAvailableTimes() {
      if(this.doctor_id) {
        DoctorService.doctorsAvailabilityForDay(this.doctor_id, this.branch, this.date).then(res => {
          this.doctorAvailableTimes = res.data
        })
      } else {
        this.doctorAvailableTimes = null
      }
    },
    addPatient() {
      this.$store.commit('setViewModel', 'patient')
      this.$store.commit('setModule', 'patients')
      let payload = {
        branch_id: this.branch,
        mphone: this.phoneFromCall ? this.phoneFromCall : null
      }
      if(this.leadForRecord) {
        payload.fname = this.leadForRecord.fname
        payload.lname = this.leadForRecord.lname
        payload.email = this.leadForRecord.email
        payload.mphone = this.leadForRecord.phone || payload.mphone
        payload.source = this.leadForRecord.source_id
      } else if(this.visit.lead) {
        payload.fname = this.visit.lead.fname
        payload.lname = this.visit.lead.lname
        payload.email = this.visit.lead.email
        payload.mphone = this.visit.lead.phone || payload.mphone
        payload.source = this.visit.lead.source_id || 1293 // online-booking
      }
      console.log(payload)
      this.$store.commit('setSelectedModel', payload)
      this.$store.commit('setShowModal', true)
    },
    dateChanged(val) {
      this.date = val
      this.reloadAvailableTimes()
    },
    timeSlotSelected(timeObject) {
      this.time = timeObject.start
      this.duration = parseInt(timeObject.duration)
      this.timecounter++
    },
    durationChange(sign = 'plus') {
      if(!this.canBeEdited) return
      this.duration = parseInt(this.duration)
      if(sign === 'plus') this.duration += parseInt(this.timeStep)
      if(sign === 'minus' && this.duration > this.timeStep) this.duration -= parseInt(this.timeStep)
    },
    getConflictingRecord() {
      if(!this.datetime) return null
      if(!this.chair_id) return null
      this.conflictingRecord = this.records.find(x => {
        return x.id != this.visit.id && x.chair_id == this.chair_id &&
            (moment(this.datetime).isBetween(x.startMoment, x.endMoment, null, '[)') ||
                moment(this.visit.end).isBetween(x.startMoment, x.endMoment, null, '(]'))
      })
      this.$emit('conflictingRecord', this.conflictingRecord)
    },
    toggleTag(tag, action = 'add', index = null) {
      if(action === 'add') {
        this.visit.tags.push(tag)
      }
      if(action === 'remove' && index !== null) {
        this.visit.tags.splice(index, 1)
      }
    },
    openPatientDropdown() {
      this.$refs.patientSelect.openDropdown()
    },
    async findDefaultFieldId() {
      if(!this.doctor_id) return
      if(this.visit.fields?.id) return
      const { data: { fields } } = await EloquentService.show('doctor', this.doctor_id)
      if(fields.length) {
        this.visit.fields = fields[0]
      }
    }
  },
  mounted() {
    if(this.visit.id) {
      this.date = this.visit.start.substr(0,10)
      this.time = this.visit.start.substr(11,5)
      this.duration = parseInt(this.visit.durationMinutes)
      this.patient = this.visit.patient
      this.recordTypes = this.recordTypes.filter(x => x.id != 'block')
    } else {
      this.date = this.newVisitTime.substr(0,10)
      this.time = this.newVisitTime.substr(11,5)
      this.duration = parseInt(this.visit.durationMinutes ? this.visit.durationMinutes : this.timeStep)
      if(this.visit.patient) {
        this.patient = this.visit.patient
      }
      this.$set(this.visit, 'tags', [])
    }
    if(this.visit.doctor_id) {
      this.reloadAvailableTimes()
      let d = this.doctorsWithChairs.find(x => x.id == this.visit.doctor_id)
      if(d && d.chairs && d.chairs.length && !this.visit.chair_id) {
        let branchDoctorChairs = d.chairs.filter(c => c.branch_id == this.branch)
        if(branchDoctorChairs.length) this.visit.chair_id = branchDoctorChairs[0].id
      }
      if(d) this.doctor = d
      this.findDefaultFieldId()
    }
    if(this.visit.chair_id) {
      this.chair = this.chairs.find(c => c.id == this.visit.chair_id)
    }
    this.loaded = true
  },
  watch: {
    datetime(val) {
      this.visit.start = val
      this.calcEnd()
      this.getConflictingRecord()
    },
    duration() {
      this.calcEnd()
      this.getConflictingRecord()
    },
    patient(val) {
      let v = val ? val.id : null
      this.visit.client_id = v
      this.$emit('changedPatient', v)
      if(val.source && (!this.visit.source_id || this.visit.source_id === '-')) {
        this.visit.source_id = val.source
      }
    },
    doctor(val) {
      this.visit.doctor_id = val ? val.id : null
    },
    doctor_id() {
      this.reloadAvailableTimes()
      this.findDefaultFieldId()
    },
    chair(val) {
      this.visit.chair_id = val ? val.id : null
    },
    chair_id() {
      this.getConflictingRecord()
    },
    newEloquentObject(val) {
      if(['patient', 'lead'].includes(val.model_name) && val.role_id && val.role_id === 5) { // also check for lead, cause modal can be opened from lead
        val.title = val.full_name
        this.patient = val
        this.patientSelectKey++
      }
    },
    dateIsInSchedule(val) {
      this.$emit('dateIsInSchedule', val)
    }
  }
}
</script>

<style scoped lang="scss">
.available-times {
  font-size: 11px;
  span.available-time-item {
    background: #D8F0FC;
    font-size: 13px;
    border-radius: 4px;
    padding: 3px 7px;
    display: inline-block;
    &:hover {
      background: $blue-color;
      color: #fff;
    }
  }
}
.add-patient-btn {
  line-height: 44px;
  height: 46px;
}
</style>
