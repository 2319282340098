<template>
  <a class="pointer link"
     @click="closeShift"
     v-if="object.status === 'OPENED' || !object.CloseDate">
    {{ $t('close_shift') }}
  </a>
</template>

<script>

import { CashdeskService } from "@/services/api.service"
import { mapState } from "vuex"

export default {
  name: "CashdeskDatatableTDShiftClose",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
  },
  methods: {
    closeShift() {
      CashdeskService.closeShift().then(res => {
        if(res.data.response && res.data.response.message) {
          this.$noty.error(res.data.response.message)
        }
        this.$store.commit('setShifts', res.data.shifts)
        this.$store.commit('incrementDataTableCounter')
      })
    }
  }
}
</script>