<template>
  <b-row v-if="loaded" class="mt-4  mb-5">
    <b-col md="4"
           sm="6"
           cols="12"
           class="mt-md-0 mt-4"
           v-for="(chart, index) in charts"
           :key="`chart-${index}`">
      <h5 class="text-center">{{ chart.title }}</h5>
      <div v-if="!chart.data.labels.length" class="mt-3 text-muted text-center">{{ $t('no_data_available') }}</div>
      <Doughnut v-if="chart.data"
                :data="chart.data"
                :options="options"
                :chart-id="`chartId-${index}`"
                :height="height"/>
    </b-col>
  </b-row>
</template>

<script>
// import Chart from 'chart.js/auto'
import { Doughnut } from 'vue-chartjs'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: "DayReportCharts",
  components: {
    Doughnut
  },
  props: {
    charts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      loaded: false,
      height: 300,
      options: {
        aspectRatio: 2,
        responsive: true,
        // maintainAspectRatio: false,
        plugins: {
          tooltip: {
            callbacks: {
              label: (context) => ` ${context.label}: ${context.formattedValue}`,
            }
          },
          legend: {
            display: true,
            position: 'right',
            labels: {
              generateLabels(context) {
                return context.data.datasets[0].data.map((value, index) => ({
                  text: `${context.data.labels[index]}: ${value}`,
                  fillStyle: context.data.datasets[0].backgroundColor[index],
                  lineWidth: 0,
                  index
                }))
              }
            }
          }
        }
      }
    }
  },
  mounted() {
    this.loaded = true
  }
}
</script>
