export default {
    accept_payment: "Принять оплату",
    added: "Добавил(-а)",
    add_new_record: "+ Добавить визит",
    additionally: "Дополнительно",
    are_you_sure_want_to_move_visit: "Вы действительно хотите переместить визит?",
    are_you_sure_want_to_change_time: "Вы действительно изменить время визита?",
    are_you_sure_you_want_to_delete_record: "Вы действительно хотите удалить запись?",
    blocked: "Заблокировано",
    by_phone_found: "По номеру телефона {phone} найдены следующие пациенты",
    by_phone_nobody_found: "По номеру телефона {phone} не найдено ни одного пациента",
    calendar_settings: "Настройки календаря",
    cancel_record: "Отменить запись",
    change_record: "Изменить запись",
    checkup: "Осмотр",
    child_age: "моложе 18-ти",
    choose_all: "Выбрать все",
    clear: "Очистить",
    create_notification: "Создать уведомление",
    create_patient: "Создать пациента",
    create_task: "Создать задачу",
    confirm_visit: "Подтвердить визит",
    curation: "Курация",
    DAY: "На день",
    day_short: "дн",
    days: "дней",
    delete_record: "Удалить визит",
    diagnostics: "Диагностика",
    doctor_available_time: "Доступное время врача",
    doctor_has_no_available_time_at_this_day: "У врача нет доступного времени в этот день",
    doctor_is_not_working_on_this_date_continue: "Врач не работает в это время или это время занято. Продолжить?",
    doctors_selected: "Выбрано докторов",
    edit_schedule: "Редактировать расписание",
    edit_visit: "Редактировать визит",
    email_text: "Текст письма",
    email_topic: "Тема письма",
    employees_selected: "Выбрано сотрудников",
    fillDoctorColor: "Закрашивать запись цветом врача",
    fill_treatment: "Заполнить лечение",
    for: "За",
    free_minutes: "Свободных минут",
    hideChairsWithoutSchedule: "Прятать кресла без врачей с графиком работы в выбранный день",
    hideDoctorsWithoutSchedule: "Прятать врачей, не работающих в выбранный день",
    hour_short: "ч",
    info: "Инфо",
    load_demo_data_to_account: "Загрузить демо-данные в аккаунт?",
    min_short: "мин",
    move_record: "Перенести запись",
    need_to_select_at_least_one_doctor: "Необходимо выбрать хотя бы одного доктора",
    need_to_select_at_least_one_employee: "Необходимо выбрать хотя бы одного сотрудника",
    new_visit: "Новый визит",
    no_birthdays_on_this_date: "Нет дней рождений на указанную дату",
    no_free_time: "Нет свободного времени",
    no_schedule: "Нет расписания",
    no_visits: "Визитов нет",
    not_working: "Не работает",
    of_day: "дня",
    online_consultation: "Онлайн консультация",
    patient_came: "Пациент пришел",
    patient_card: "Карточка пациента",
    patient_notifications: "Уведомления пациенту",
    postponed: "Перенос",
    quick_view: "Быстрый просмотр",
    record_again: "Записать повторно",
    record_conflict: "Конфликт записи",
    record_has_no_paid_services: "У записи нет платных услуг",
    record_type: "Тип записи",
    related_tasks: "Связанные задачи",
    requires_confirmation: "Требует подтверждения",
    return_visit: "Повторный визит",
    sanation: "Санация",
    schedule: "Расписание",
    select_a_doctor: "Выберите доктора",
    select_an_employee: "Выберите сотрудника",
    selected: "Выбран",
    send_sms: "Отправить SMS",
    sms_text: "Текст SMS",
    showClientTags: "Использовать цвета пометок пациентов",
    showDoctorColor: "Использовать цвета врачей",
    showPlannedServices: "Выводить услуги текстом",
    showRecordTagColor: "Использовать цвета ярлыков, как фон визита",
    showServiceColor: "Использовать цвета категорий услуг",
    showStatusColor: "Использовать цвета статусов визита",
    showOverflowScreen: "Использовать прокрутку экрана при большом наборе данных",
    till: "до",
    time_has_already_passed: "Время прошло",
    tomorrow: "Завтра",
    visits_count: "Визитов нет | визит | визита",
    visits_count_much: "визитов",
    warranty_case: "Гарантийный случай",
    write_reason_for_cancellation: "Укажите причину отмены",
    yesterday: "Вчера",
    you_have_no_doctors: "У вас не заведено ни одного врача. Добавить врача можно <a href='{href}'>здесь</a>",
    WEEK: "На неделю",
    MONTH: "На месяц",
    CHAIR: "По креслам",
    DOCTOR: "По докторам",
}
