<template>
  <g>
    <!--   SAME AS 28 -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M24.2036 2.26976L22.1199 1H18.6362L16.5362 2.26976L18.1478 3.81627L16.0641 5.36278L17.9525 6.90929L15.6571 8.60231L17.4804 10.1488L15.185 11.5488L17.2036 12.9651L15.185 14.5767L16.878 16.1232L14.6478 17.5395L16.406 19.0046L14.1106 20.5511L16.2594 22.0976L14.1106 23.7093L15.6571 25.0604L13.5734 26.6069L15.3967 28.0883L13.3129 29.6348L15.1199 31.3767L14.1106 33.0534V47.3139L15.999 54.6394H22.1199" fill="#8889BE"/>
      <path d="M16.7963 2.26976L18.8801 1H22.3475L24.4638 2.26976L22.8359 3.81627L24.9359 5.36278L23.0475 6.90929L25.3266 8.60231L23.5196 10.1488L25.7986 11.5488L23.78 12.9651L25.7986 14.5767L24.1219 16.1232L26.3359 17.5395L24.594 19.0046L26.8731 20.5511L24.7242 22.0976L26.8731 23.7093L25.3266 25.0604L27.4103 26.6069L25.6033 28.0883L27.687 29.6348L25.8638 31.3767L26.8731 33.0534V47.3139L25.001 54.6394H21.094" fill="#8889BE"/>
      <path d="M1.0712 67.1549C1.0712 67.1549 -0.0729904 78.5692 12.0995 80.5021C24.272 82.4351 17.2691 78.3293 26.1469 78.5692C35.0247 78.809 35.2452 76.3117 37.8369 73.758C38.59 73.008 39.1711 72.0963 39.5382 71.0887C39.9054 70.081 40.0494 69.0025 39.9598 67.9309C39.9598 67.9309 40.5664 57.8288 37.0649 54.7953C35.0936 53.0881 35.5 47.0494 33.5 47.5212L27.4289 49.843C25.4858 49.7009 23.558 49.3894 21.6666 48.9118C18.2203 48.0653 16.9382 47.2046 14.3741 47.0494C12.411 46.9462 10.4484 47.2634 8.61182 47.9806L4.88976 49.843C4.04884 52.6648 3.09765 54.4708 2.46352 54.8941C0.878201 55.9805 1.00227 59.3949 1.00227 59.3949L1.0712 67.1549Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M20 7.39336L22.1548 21.0487L26.9762 9.7967C27.1244 2.50474 26.5318 2.50475 26.5318 2.50475C24.5251 -1.48261 23.1918 0.319893 21.8585 1.45329C20.5252 2.58668 20 7.39336 20 7.39336Z" fill="#DEE1E9"/>
      <path d="M1.04361 70.4408C1.04361 70.4408 -0.10017 81.6266 12.068 83.5347C24.2363 85.4427 17.2771 81.4053 26.1518 81.6681C35.0264 81.9308 35.2469 79.4696 37.8377 76.9531C38.5905 76.2182 39.1714 75.3248 39.5384 74.3372C39.9054 73.3497 40.0493 72.2928 39.9599 71.2427C39.9599 71.2427 40.5662 61.3566 37.0659 58.3839C35.0953 56.7109 34.5303 53.3648 34.4063 50.7654C34.3502 49.5436 34.3778 48.3193 34.489 47.1013C34.8904 43.1754 34.8164 39.2152 34.2685 35.3071C33.9929 33.399 32.6424 24.7574 32.2152 19.6968C32.0784 18.2718 32.1998 16.8336 32.5735 15.452C32.9007 14.2546 33.4221 13.1195 34.1169 12.0921C34.6167 11.1537 34.9209 10.123 35.0109 9.06279C35.1009 8.00261 34.9749 6.93514 34.6406 5.92539C31.6088 3.71312 27.2129 9.72774 27.2129 9.72774L22.2794 21.1486L20.0745 7.32189C20.0745 7.32189 19.6198 2.75908 17.0428 3.06327C16.2857 3.19269 15.5873 3.5549 15.0442 4.09982C14.5011 4.64473 14.1401 5.34545 14.0111 6.10514C14.1627 12.8802 12.633 13.8619 12.633 13.8619C12.633 13.8619 5.05375 20.8721 6.41803 34.2563C7.15073 40.6925 6.63124 47.2102 4.88839 53.4478C4.04777 56.2131 3.09692 57.9691 2.46301 58.3839C0.87825 59.4486 1.00227 62.8084 1.00227 62.8084L1.04361 70.4408Z" fill="#DEE1E9"/>
      <path d="M1.0712 70.1824C1.0712 70.1824 -0.0729904 81.5967 12.0995 83.5296C24.272 85.4626 17.2691 81.3568 26.1469 81.5967C35.0247 81.8365 35.2452 79.3392 37.8369 76.7855C38.59 76.0355 39.1711 75.1238 39.5382 74.1161C39.9054 73.1085 40.0494 72.03 39.9598 70.9584C39.9598 70.9584 40.5664 60.8563 37.0649 57.8228C35.0936 56.1156 35.5 50.0769 33.5 50.5487L27.4289 52.8705C25.4858 52.7284 23.558 52.4169 21.6666 51.9393C18.2203 51.0928 16.9382 50.2321 14.3741 50.0769C12.411 49.9737 10.4484 50.2909 8.61182 51.0081L4.88976 52.8705C4.04884 55.6923 3.09765 57.4983 2.46352 57.9216C0.878201 59.008 1.00227 62.4224 1.00227 62.4224L1.0712 70.1824Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M7.75978 61.1456C5.59498 57.5876 11.765 53.1737 12.4968 44.7823C12.9541 39.2608 12.0852 38.9283 12.2376 30.369C12.1655 27.0805 12.5617 25.1612 12.9578 21.6843C13.2787 18.8679 13.4523 17.1973 15.1902 12.6827C16.7147 8.5877 16.1191 4.30637 16.8509 4.30637C17.5826 4.30637 18.1173 8.05737 18.1935 10.3734C18.3002 13.26 16.9891 14.116 16.669 17.8417C16.5318 19.52 17.1812 22.1088 17.8063 22.2766C18.7058 22.5116 20.4742 17.6781 20.8553 16.7215C24.4227 7.07141 22.8566 1.2501 24 1.04871C25.1434 0.847313 26.7164 5.79852 25.2376 13.0487C24.6583 15.885 24.2131 17.1402 22.963 20.7653C20.7067 27.2435 21.9682 33.5211 22.3951 33.6386C22.8219 33.756 25.9293 19.0543 29.4509 11.5021C30.5028 9.25317 32.8902 6.38086 33.5 6.54869C34.1098 6.71652 30.3387 10.327 30.2473 16.5199C30.2473 20.9169 28.7864 30.8691 28.9998 37.5487C29.1523 42.5835 29.2375 41.7947 29.4509 45.5877C29.6643 49.6659 31.222 52.5024 31.2372 57.7051C31.2372 61.1456 31.039 62.3539 30.231 63.2602C28.7827 64.9385 26.3131 64.5692 24.6971 64.3678C19.6967 63.7469 18.8734 60.7428 15.6262 61.3134C13.2937 61.733 12.3943 63.5287 10.3514 62.9917C9.31359 62.7405 8.39317 62.0849 7.75978 61.1456Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M20 10.5505L22.1548 24.2059L26.9762 12.9539C27.1244 5.66191 26.5318 5.66191 26.5318 5.66191C24.5251 1.67455 23.1918 3.47706 21.8585 4.61045C20.5252 5.74385 20 10.5505 20 10.5505Z" fill="#DEE1E9"/>
      <path d="M1.04361 73.5979C1.04361 73.5979 -0.10017 84.7837 12.068 86.6918C24.2363 88.5999 17.2771 84.5625 26.1518 84.8252C35.0264 85.0879 35.2469 82.6268 37.8377 80.1103C38.5905 79.3754 39.1714 78.4819 39.5384 77.4944C39.9054 76.5069 40.0493 75.45 39.9599 74.3999C39.9599 74.3999 40.5662 64.5138 37.0659 61.5411C35.0953 59.868 34.5303 56.522 34.4063 53.9225C34.3502 52.7007 34.3778 51.4765 34.489 50.2585C34.8904 46.3326 34.8164 42.3723 34.2685 38.4643C33.9929 36.5562 32.6424 27.9145 32.2152 22.8539C32.0784 21.4289 32.1998 19.9908 32.5735 18.6092C32.9007 17.4118 33.4221 16.2766 34.1169 15.2493C34.6167 14.3108 34.9209 13.2801 35.0109 12.22C35.1009 11.1598 34.9749 10.0923 34.6406 9.08256C31.6088 6.87029 27.2129 12.8849 27.2129 12.8849L22.2794 24.3058L20.0745 10.4791C20.0745 10.4791 19.6198 5.91624 17.0428 6.22043C16.2857 6.34986 15.5873 6.71206 15.0442 7.25698C14.5011 7.8019 14.1401 8.50262 14.0111 9.2623C14.1627 16.0374 12.633 17.0191 12.633 17.0191C12.633 17.0191 5.05375 24.0292 6.41803 37.4135C7.15073 43.8497 6.63124 50.3674 4.88839 56.6049C4.04777 59.3703 3.09692 61.1263 2.46301 61.5411C0.87825 62.6057 1.00227 65.9656 1.00227 65.9656L1.04361 73.5979Z" fill="#DEE1E9"/>
      <path d="M1.0712 73.3396C1.0712 73.3396 -0.0729904 84.7539 12.0995 86.6868C24.272 88.6198 17.2691 84.514 26.1469 84.7538C35.0247 84.9937 35.2452 82.4964 37.8369 79.9426C38.59 79.1927 39.1711 78.281 39.5382 77.2733C39.9054 76.2656 40.0494 75.1871 39.9598 74.1156C39.9598 74.1156 40.5664 64.0134 37.0649 60.98C35.0936 59.2728 35.5 53.2341 33.5 53.7059L27.4289 56.0277C25.4858 55.8856 23.558 55.5741 21.6666 55.0965C18.2203 54.2499 16.9382 53.3893 14.3741 53.2341C12.411 53.1309 10.4484 53.448 8.61182 54.1653L4.88976 56.0277C4.04884 58.8495 3.09765 60.6555 2.46352 61.0787C0.878201 62.1652 1.00227 65.5796 1.00227 65.5796L1.0712 73.3396Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M7.75978 64.3027C5.59498 60.7448 11.765 56.3309 12.4968 47.9395C12.9541 42.418 12.0852 42.0854 12.2376 33.5262C12.1655 30.2377 12.5617 28.3184 12.9578 24.8414C13.2787 22.0251 13.4523 20.3544 15.1902 15.8399C16.7147 11.7449 16.1191 7.46353 16.8509 7.46353C17.5826 7.46353 18.1173 11.2145 18.1935 13.5306C18.3002 16.4172 16.9891 17.2731 16.669 20.9989C16.5318 22.6772 17.1812 25.266 17.8063 25.4338C18.7058 25.6688 20.4742 20.8353 20.8553 19.8787C24.4227 10.2286 22.8566 4.40727 24 4.20587C25.1434 4.00448 26.7164 8.95569 25.2376 16.2059C24.6583 19.0421 24.2131 20.2974 22.963 23.9225C20.7067 30.4006 21.9682 36.6782 22.3951 36.7957C22.8219 36.9132 25.9293 22.2115 29.4509 14.6592C30.5028 12.4103 32.8902 9.53803 33.5 9.70585C34.1098 9.87368 30.3387 13.4842 30.2473 19.677C30.2473 24.0741 28.7864 34.0263 28.9998 40.7059C29.1523 45.7407 29.2375 44.9519 29.4509 48.7448C29.6643 52.823 31.222 55.6596 31.2372 60.8623C31.2372 64.3027 31.039 65.5111 30.231 66.4174C28.7827 68.0956 26.3131 67.7264 24.6971 67.525C19.6967 66.904 18.8734 63.8999 15.6262 64.4706C13.2937 64.8901 12.3943 66.6859 10.3514 66.1488C9.31359 65.8977 8.39317 65.242 7.75978 64.3027Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="17" cy="7" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="24" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="33" cy="10" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M0.0436134 67.934C0.0436134 67.934 -1.10017 79.1198 11.068 81.0279C23.2363 82.936 16.2771 78.8986 25.1518 79.1613C34.0264 79.424 34.2469 76.9628 36.8377 74.4464C37.5905 73.7114 38.1714 72.818 38.5384 71.8305C38.9054 70.843 39.0493 69.786 38.9599 68.7359C38.9599 68.7359 39.5662 58.8499 36.0659 55.8771C34.0953 54.2041 33.5303 50.858 33.4063 48.2586C33.3502 47.0368 33.3778 45.8125 33.489 44.5945C33.8904 40.6686 33.8164 36.7084 33.2685 32.8004C32.9929 30.8923 31.6424 22.2506 31.2152 17.19C31.0784 15.765 31.1998 14.3269 31.5735 12.9452C31.9007 11.7478 32.4221 10.6127 33.1169 9.58532C33.6167 8.64689 33.9209 7.61619 34.0109 6.55601C34.1009 5.49583 33.9749 4.42836 33.6406 3.41862C30.6088 1.20635 26.2129 7.22096 26.2129 7.22096L21.2794 18.6418L19.0745 4.81511C19.0745 4.81511 18.6198 0.252304 16.0428 0.556492C15.2857 0.68592 14.5873 1.04812 14.0442 1.59304C13.5011 2.13796 13.1401 2.83868 13.0111 3.59836C13.1627 10.3734 11.633 11.3551 11.633 11.3551C11.633 11.3551 4.05375 18.3653 5.41803 31.7495C6.15073 38.1857 5.63124 44.7034 3.88839 50.941C3.04777 53.7063 2.09692 55.4623 1.46301 55.8771C-0.12175 56.9418 0.00227356 60.3017 0.00227356 60.3017L0.0436134 67.934Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M0.0436134 67.934C0.0436134 67.934 -1.10017 79.1198 11.068 81.0279C23.2363 82.936 16.2771 78.8986 25.1518 79.1613C34.0264 79.424 34.2469 76.9628 36.8377 74.4464C37.5905 73.7114 38.1714 72.818 38.5384 71.8305C38.9054 70.843 39.0493 69.786 38.9599 68.7359C38.9599 68.7359 39.5662 58.8499 36.0659 55.8771C34.0953 54.2041 33.5303 50.858 33.4063 48.2586C33.3502 47.0368 33.3778 45.8125 33.489 44.5945C33.8904 40.6686 33.8164 36.7084 33.2685 32.8004C32.9929 30.8923 31.6424 22.2506 31.2152 17.19C31.0784 15.765 31.1998 14.3269 31.5735 12.9452C31.9007 11.7478 32.4221 10.6127 33.1169 9.58532C33.6167 8.64689 33.9209 7.61619 34.0109 6.55601C34.1009 5.49583 33.9749 4.42836 33.6406 3.41862C30.6088 1.20635 26.2129 7.22096 26.2129 7.22096L21.2794 18.6418L19.0745 4.81511C19.0745 4.81511 18.6198 0.252304 16.0428 0.556492C15.2857 0.68592 14.5873 1.04812 14.0442 1.59304C13.5011 2.13796 13.1401 2.83868 13.0111 3.59836C13.1627 10.3734 11.633 11.3551 11.633 11.3551C11.633 11.3551 4.05375 18.3653 5.41803 31.7495C6.15073 38.1857 5.63124 44.7034 3.88839 50.941C3.04777 53.7063 2.09692 55.4623 1.46301 55.8771C-0.12175 56.9418 0.00227356 60.3017 0.00227356 60.3017L0.0436134 67.934Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M15.2784 7.96209L17.4332 21.6174L22.2546 10.3654C22.4028 3.07347 21.8102 3.07347 21.8102 3.07347C19.8035 -0.913886 18.4702 0.888618 17.1369 2.02201C15.8036 3.15541 15.2784 7.96209 15.2784 7.96209Z" fill="#C7CAD5"/>
      <path d="M29.7674 47.67C29.6562 48.888 29.4908 56.7363 29.5469 57.9581C25.9641 58.7707 23.9147 57.1148 21.2182 57.0934C17.5578 57.9581 11.5062 57.851 7.91144 56.5614C5.37731 55.6523 3.1763 58.6268 0.42378 57.9581C2.16663 51.7205 2.42912 41.2612 1.69642 34.825C0.332144 21.4408 7.91144 14.4306 7.91144 14.4306C7.91144 14.4306 9.44108 13.4489 9.28949 6.67386C9.41849 5.91418 9.77948 5.21346 10.3226 4.66854C10.8657 4.12362 11.5641 3.76142 12.3212 3.63199C14.8982 3.3278 15.3529 7.89061 15.3529 7.89061L17.5578 21.7173L22.4912 10.2965C22.4912 10.2965 26.8872 4.28185 29.919 6.49412C30.2533 7.50387 30.3793 8.57133 30.2893 9.63151C30.1993 10.6917 29.8951 11.7224 29.3953 12.6608C28.7005 13.6882 28.1791 14.8233 27.8519 16.0207C27.4782 17.4024 27.3568 18.8405 27.4936 20.2655C27.9208 25.3261 29.2713 33.9678 29.5469 35.8759C30.0948 39.7839 30.1688 43.7441 29.7674 47.67Z" fill="#DEE1E9"/>
      <path d="M15.2784 7.96209L17.4332 21.6174L22.2546 10.3654C22.4028 3.07347 21.8102 3.07347 21.8102 3.07347C19.8035 -0.913886 18.4702 0.888618 17.1369 2.02201C15.8036 3.15541 15.2784 7.96209 15.2784 7.96209Z" fill="#C7CAD5"/>
      <path opacity="0.2" d="M6.85249 34.4502C6.69577 44.1542 7.58905 44.5312 7.1189 50.7913C6.86872 53.9554 6.00019 56.6206 5.03414 58.9173L8.04266 60.4152L12.2517 58.9173L17.2942 60.4152L21.2236 58.9173L25.9702 60.4152C25.4756 57.3125 24.6958 54.8301 24.5475 51.7043C24.3281 47.4041 24.2405 48.2984 24.0838 42.5901C23.8644 35.0171 25.3661 23.7338 25.3661 18.7486C25.4602 11.7274 29.3367 7.63403 28.7098 7.44376C28.083 7.25348 25.6288 10.51 24.5474 13.0597C20.9273 21.6221 17.733 38.2902 17.2942 38.157C16.8554 38.0238 15.5586 30.9065 17.878 23.5619C19.1631 19.4519 19.6207 18.0288 20.2163 14.8132C21.7364 6.59325 20.1194 0.979803 18.944 1.20813C17.7686 1.43646 19.3785 8.03639 15.7113 18.9772C15.3195 20.0618 13.5016 25.5417 12.577 25.2754C11.9345 25.0851 11.2668 22.15 11.4079 20.2473C11.737 16.0232 13.0847 15.0528 12.975 11.78C12.8967 9.15423 12.3471 4.90152 11.5948 4.90152C10.8426 4.90152 11.4549 9.75549 9.88769 14.3982C8.12499 19.4482 7.9277 21.3595 7.60596 24.4765L7.59281 24.6038C7.18562 28.5458 6.77837 30.7218 6.85249 34.4502Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M29.4166 41.2548C18.2043 39.5113 12.4692 40.9516 3.01993 47.0058" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M20.2811 7.54998L22.4359 21.2053L27.2573 9.95331C27.4054 2.66136 26.8129 2.66136 26.8129 2.66136C24.8062 -1.326 23.4729 0.476509 22.1396 1.6099C20.8063 2.7433 20.2811 7.54998 20.2811 7.54998Z" fill="#C7CAD5"/>
      <path d="M34.7701 47.2579C34.6589 48.4759 34.6313 49.7002 34.6874 50.922C25.7612 63.899 19.2936 64.4644 5.16946 53.6044C6.91232 47.3668 7.4318 40.8491 6.6991 34.4129C5.33483 21.0287 12.9141 14.0185 12.9141 14.0185C12.9141 14.0185 14.4438 13.0368 14.2922 6.26175C14.4212 5.50207 14.7822 4.80135 15.3253 4.25643C15.8684 3.71151 16.5667 3.34931 17.3239 3.21988C19.9009 2.9157 20.3556 7.4785 20.3556 7.4785L22.5605 21.3052L27.4939 9.88435C27.4939 9.88435 31.8899 3.86974 34.9216 6.08201C35.256 7.09176 35.382 8.15923 35.292 9.2194C35.202 10.2796 34.8978 11.3103 34.398 12.2487C33.7032 13.2761 33.1818 14.4112 32.8546 15.6086C32.4808 16.9903 32.3595 18.4284 32.4963 19.8534C32.9235 24.914 34.274 33.5557 34.5496 35.4637C35.0975 39.3718 35.1715 43.332 34.7701 47.2579Z" fill="#DEE1E9"/>
      <path d="M20.2811 7.54998L22.4359 21.2053L27.2573 9.95331C27.4054 2.66136 26.8129 2.66136 26.8129 2.66136C24.8062 -1.326 23.4729 0.476509 22.1396 1.6099C20.8063 2.7433 20.2811 7.54998 20.2811 7.54998Z" fill="#C7CAD5"/>
      <path d="M12.3806 83.6863C0.208107 81.7533 1.3523 70.339 1.3523 70.339L1.28337 62.579C1.28337 62.579 1.1593 59.1646 2.74463 58.0782C3.37875 57.6549 4.32994 55.849 5.17086 53.0271L8.89292 51.1647C10.7295 50.4475 12.6921 50.1303 14.6552 50.2335C17.2193 50.3887 18.5014 51.2494 21.9477 52.0959C23.8391 52.5735 25.7669 52.8851 27.71 53.0271L33.7811 50.7053C35.7811 50.2335 36.6525 58.4336 38.6238 60.1408L33.7811 61.0921L36.2515 64.044L35.0439 66.5109L36.2515 68.8243L32.4424 70.339L33.7811 73.374L30.2136 72.6029L32.4424 76.9421L30.2136 79.34L26.428 76.9421L25.3833 79.34L26.428 81.7533C17.5502 81.5134 24.5531 85.6192 12.3806 83.6863Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M7.25201 68.931C5.02663 64.8972 11.3693 59.8929 12.1216 50.3792C12.5917 44.1191 11.6985 43.7421 11.8552 34.038C11.7811 30.3097 12.1883 28.1337 12.5955 24.1916C12.9253 20.9986 13.1038 19.1045 14.8904 13.9861C16.4575 9.34338 15.8453 4.4894 16.5975 4.4894C17.3498 4.4894 17.8994 8.74211 17.9777 11.3679C18.0874 14.6406 16.7397 15.6111 16.4105 19.8352C16.2695 21.7379 16.9371 24.673 17.5797 24.8633C18.5043 25.1296 20.3222 19.6497 20.714 18.5651C24.3812 7.62427 22.7713 1.02435 23.9467 0.796019C25.1221 0.567688 26.7391 6.18114 25.2189 14.401C24.6234 17.6167 24.1658 19.0398 22.8807 23.1498C20.5613 30.4944 21.858 37.6117 22.2969 37.7449C22.7357 37.8781 25.93 21.2099 29.5501 12.6475C30.6315 10.0979 33.0857 6.84137 33.7125 7.03164C34.3394 7.22192 30.4628 11.3153 30.3688 18.3365C30.3688 23.3217 28.8671 34.605 29.0865 42.178C29.2432 47.8862 29.3307 46.992 29.5501 51.2922C29.7696 55.9159 31.3708 59.1319 31.3864 65.0304C31.3864 68.931 31.1827 70.301 30.3521 71.3285C28.8633 73.2313 26.3245 72.8126 24.6633 72.5843C19.523 71.8803 18.6767 68.4744 15.3386 69.1213C12.9408 69.597 12.0162 71.6329 9.9162 71.0241C8.84931 70.7393 7.90313 69.9959 7.25201 68.931Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M35.8188 58.2173C24.818 62.5692 22.2985 67.4632 23.5574 80.0516" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M19.2932 7.44273L21.448 21.0981L26.2694 9.84606C26.4176 2.55411 25.825 2.55411 25.825 2.55411C23.8183 -1.43325 22.485 0.369255 21.1517 1.50265C19.8184 2.63604 19.2932 7.44273 19.2932 7.44273Z" fill="#C7CAD5"/>
      <path d="M0.336834 70.4901C0.336834 70.4901 -0.80695 81.6759 11.3613 83.584C23.5295 85.4921 16.5703 81.4547 25.445 81.7174C34.3196 81.9801 34.5401 79.519 37.1309 77.0025C37.8837 76.2676 38.4646 75.3741 38.8316 74.3866C39.1986 73.3991 39.3426 72.3422 39.2531 71.2921C39.2531 71.2921 39.8594 61.406 36.3592 58.4333C34.3885 56.7602 33.8235 53.4142 33.6995 50.8147C33.6434 49.5929 33.671 48.3687 33.7822 47.1507C34.1837 43.2248 34.1096 39.2645 33.5617 35.3565C33.2861 33.4484 31.9356 24.8067 31.5084 19.7461C31.3716 18.3211 31.493 16.883 31.8667 15.5013C32.1939 14.304 32.7153 13.1688 33.4101 12.1415C33.9099 11.203 34.2141 10.1723 34.3041 9.11215C34.3942 8.05197 34.2681 6.9845 33.9338 5.97476C30.9021 3.76248 26.5061 9.7771 26.5061 9.7771L21.5726 21.1979L19.3678 7.37125C19.3678 7.37125 18.913 2.80844 16.336 3.11263C15.5789 3.24206 14.8805 3.60426 14.3374 4.14918C13.7943 4.6941 13.4333 5.39482 13.3043 6.1545C13.4559 12.9296 11.9263 13.9113 11.9263 13.9113C11.9263 13.9113 4.34697 20.9214 5.71125 34.3057C6.44395 40.7419 5.92446 47.2596 4.18161 53.4971C3.34099 56.2625 2.39014 58.0185 1.75623 58.4333C0.171471 59.4979 0.295494 62.8578 0.295494 62.8578L0.336834 70.4901Z" fill="#DEE1E9"/>
      <path d="M0.694984 70.057C0.694984 70.057 -0.449203 81.4713 11.7233 83.4043C23.8958 85.3372 16.8928 81.2314 25.7706 81.4713C34.6485 81.7112 34.869 79.2138 37.4607 76.6601C38.2138 75.9101 38.7949 74.9984 39.162 73.9908C39.5292 72.9831 39.6732 71.9046 39.5836 70.833C39.5836 70.833 40.1902 60.7309 36.6887 57.6974C34.7174 55.9902 35.1238 49.9515 33.1238 50.4233L27.0527 52.7451C25.1095 52.6031 25.8999 63.866 25.8999 63.866C21.9947 65.6074 16.0815 65.6077 12.1763 63.866C12.1763 63.866 11.9604 50.1799 10.1238 50.8971L4.51354 52.7451C3.67263 55.567 2.72144 57.3729 2.08731 57.7962C0.501987 58.8826 0.626057 62.297 0.626057 62.297L0.694984 70.057Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M19.2932 7.69351L21.448 21.3489L26.2694 10.0968C26.4176 2.80489 25.825 2.8049 25.825 2.8049C23.8183 -1.18246 22.485 0.620041 21.1517 1.75343C19.8184 2.88683 19.2932 7.69351 19.2932 7.69351Z" fill="#C7CAD5"/>
      <path d="M0.336834 70.7409C0.336834 70.7409 -0.80695 81.9267 11.3613 83.8348C23.5295 85.7429 16.5703 81.7055 25.445 81.9682C34.3196 82.2309 34.5401 79.7698 37.1309 77.2533C37.8837 76.5184 38.4646 75.6249 38.8316 74.6374C39.1986 73.6499 39.3426 72.593 39.2531 71.5429C39.2531 71.5429 39.8594 61.6568 36.3592 58.684C34.3885 57.011 33.8235 53.6649 33.6995 51.0655C33.6434 49.8437 33.671 48.6195 33.7822 47.4015C34.1837 43.4755 34.1096 39.5153 33.5617 35.6073C33.2861 33.6992 31.9356 25.0575 31.5084 19.9969C31.3716 18.5719 31.493 17.1338 31.8667 15.7521C32.1939 14.5548 32.7153 13.4196 33.4101 12.3922C33.9099 11.4538 34.2141 10.4231 34.3041 9.36294C34.3942 8.30276 34.2681 7.23529 33.9338 6.22554C30.9021 4.01327 26.5061 10.0279 26.5061 10.0279L21.5726 21.4487L19.3678 7.62204C19.3678 7.62204 18.913 3.05923 16.336 3.36341C15.5789 3.49284 14.8805 3.85505 14.3374 4.39996C13.7943 4.94488 13.4333 5.6456 13.3043 6.40528C13.4559 13.1804 11.9263 14.1621 11.9263 14.1621C11.9263 14.1621 4.34697 21.1722 5.71125 34.5564C6.44395 40.9926 5.92446 47.5103 4.18161 53.7479C3.34099 56.5132 2.39014 58.2692 1.75623 58.684C0.171471 59.7487 0.295494 63.1086 0.295494 63.1086L0.336834 70.7409Z" fill="#DEE1E9"/>
      <path d="M0.694984 70.3078C0.694984 70.3078 -0.449203 81.7221 11.7233 83.655C23.8958 85.588 16.8928 81.4822 25.7706 81.7221C34.6485 81.9619 34.869 79.4646 37.4607 76.9109C38.2138 76.1609 38.7949 75.2492 39.162 74.2416C39.5292 73.2339 39.6732 72.1554 39.5836 71.0838C39.5836 71.0838 40.1902 60.9817 36.6887 57.9482C34.7174 56.241 35.1238 50.2023 33.1238 50.6741L27.0527 52.9959C25.1095 52.8538 25.8999 64.1168 25.8999 64.1168C21.9947 65.8582 16.0815 65.8585 12.1763 64.1168C12.1763 64.1168 11.9604 50.4307 10.1238 51.1479L4.51354 52.9959C3.67263 55.8177 2.72144 57.6237 2.08731 58.047C0.501987 59.1334 0.626057 62.5478 0.626057 62.5478L0.694984 70.3078Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M19.7903 7.76116L21.9451 21.4165L26.7665 10.1645C26.9147 2.87254 26.3221 2.87255 26.3221 2.87255C24.3154 -1.11481 22.9821 0.687691 21.6488 1.82108C20.3155 2.95448 19.7903 7.76116 19.7903 7.76116Z" fill="#C7CAD5"/>
      <path d="M0.833904 70.8086C0.833904 70.8086 -0.309879 81.9944 11.8583 83.9025C24.0266 85.8105 17.0674 81.7731 25.942 82.0359C34.8167 82.2986 35.0372 79.8374 37.6279 77.3209C38.3808 76.586 38.9617 75.6926 39.3287 74.705C39.6957 73.7175 39.8396 72.6606 39.7501 71.6105C39.7501 71.6105 40.3565 61.7244 36.8562 58.7517C34.8856 57.0787 34.3206 53.7326 34.1966 51.1332C34.1405 49.9114 34.1681 48.6871 34.2793 47.4691C34.6807 43.5432 34.6067 39.583 34.0588 35.6749C33.7832 33.7668 32.4327 25.1252 32.0055 20.0646C31.8687 18.6395 31.9901 17.2014 32.3638 15.8198C32.691 14.6224 33.2124 13.4873 33.9072 12.4599C34.407 11.5215 34.7112 10.4908 34.8012 9.43059C34.8912 8.37041 34.7652 7.30294 34.4309 6.29319C31.3991 4.08092 27.0031 10.0955 27.0031 10.0955L22.0697 21.5164L19.8648 7.68968C19.8648 7.68968 19.4101 3.12688 16.8331 3.43106C16.076 3.56049 15.3776 3.9227 14.8345 4.46761C14.2914 5.01253 13.9304 5.71325 13.8014 6.47293C13.953 13.248 12.4233 14.2297 12.4233 14.2297C12.4233 14.2297 4.84404 21.2398 6.20832 34.6241C6.94102 41.0603 6.42153 47.578 4.67868 53.8156C3.83806 56.5809 2.88721 58.3369 2.2533 58.7517C0.668541 59.8163 0.792564 63.1762 0.792564 63.1762L0.833904 70.8086Z" fill="#DEE1E9"/>
      <path d="M1.19205 70.3755C1.19205 70.3755 0.0478668 81.7897 12.2204 83.7227C24.3929 85.6556 17.3899 81.5499 26.2677 81.7897C35.1455 82.0296 35.3661 79.5323 37.9577 76.9785C38.7109 76.2286 39.292 75.3169 39.6591 74.3092C40.0262 73.3015 40.1702 72.223 40.0807 71.1515C40.0807 71.1515 40.6873 61.0493 37.1858 58.0159C35.2144 56.3087 35.6209 50.27 33.6209 50.7418L27.5498 53.0636C25.6066 52.9215 26.8428 64.0184 24.9514 63.5408C21.5051 62.6943 17.5155 63.696 14.9514 63.5408C12.9883 63.4376 12.4574 50.4983 10.6209 51.2156L5.01061 53.0636C4.1697 55.8854 3.21851 57.6914 2.58438 58.1146C0.999058 59.201 1.12313 62.6154 1.12313 62.6154L1.19205 70.3755Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask2028" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="35">
        <path d="M1.19205 70.3754C1.19205 70.3754 0.0478668 81.7897 12.2204 83.7227C24.3929 85.6556 17.3899 81.5499 26.2677 81.7897C35.1455 82.0296 35.3661 79.5323 37.9577 76.9785C38.7109 76.2286 39.292 75.3169 39.6591 74.3092C40.0262 73.3015 40.1702 72.223 40.0807 71.1515C40.0807 71.1515 40.6873 61.0493 37.1858 58.0159C35.2144 56.3087 36.192 50.922 34.192 51.3938L28.1209 53.7156C26.1777 53.5735 26.8428 64.0184 24.9514 63.5408C21.3717 64.03 17.4017 64.8393 14.9514 63.5408C12.9883 63.4376 12.4574 51.4983 10.6209 52.2156L5.12086 54.2156C4.27995 57.0374 3.21851 57.6914 2.58438 58.1146C0.999058 59.201 1.12313 62.6154 1.12313 62.6154L1.19205 70.3754Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2028)">
        <path d="M31.1208 42.7156H8.12085V68.2635C17.1029 70.1898 22.1388 70.2094 31.1208 68.2635V42.7156Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M20.4215 7.55413L22.5763 21.2095L27.3977 9.95746C27.5459 2.66551 26.9533 2.66551 26.9533 2.66551C24.9466 -1.32185 23.6133 0.480659 22.28 1.61405C20.9467 2.74745 20.4215 7.55413 20.4215 7.55413Z" fill="#DEE1E9"/>
      <path d="M1.46512 70.6015C1.46512 70.6015 0.321339 81.7873 12.4896 83.6954C24.6578 85.6035 17.6986 81.5661 26.5733 81.8288C35.4479 82.0915 35.6684 79.6304 38.2592 77.1139C39.012 76.379 39.5929 75.4855 39.9599 74.498C40.3269 73.5105 40.4709 72.4536 40.3814 71.4035C40.3814 71.4035 40.9877 61.5174 37.4875 58.5447C35.5168 56.8716 34.9518 53.5256 34.8278 50.9261C34.7717 49.7043 34.7993 48.4801 34.9105 47.2621C35.312 43.3362 35.2379 39.3759 34.69 35.4679C34.4144 33.5598 33.0639 24.9181 32.6367 19.8575C32.4999 18.4325 32.6213 16.9944 32.995 15.6128C33.3222 14.4154 33.8436 13.2802 34.5384 12.2529C35.0382 11.3144 35.3424 10.2837 35.4324 9.22355C35.5224 8.16338 35.3964 7.09591 35.0621 6.08616C32.0304 3.87389 27.6344 9.8885 27.6344 9.8885L22.7009 21.3094L20.496 7.48265C20.496 7.48265 20.0413 2.91985 17.4643 3.22403C16.7072 3.35346 16.0088 3.71566 15.4657 4.26058C14.9226 4.8055 14.5616 5.50622 14.4326 6.2659C14.5842 13.041 13.0546 14.0227 13.0546 14.0227C13.0546 14.0227 5.47526 21.0328 6.83953 34.4171C7.57224 40.8533 7.05275 47.371 5.30989 53.6085C4.46928 56.3739 3.51843 58.1299 2.88452 58.5447C1.29976 59.6093 1.42378 62.9692 1.42378 62.9692L1.46512 70.6015Z" fill="#DEE1E9"/>
      <path d="M1.49271 70.3433C1.49271 70.3433 0.348518 81.7576 12.521 83.6905C24.6936 85.6235 17.6906 81.5177 26.5684 81.7576C35.4462 81.9974 35.6667 79.5001 38.2584 76.9464C39.0115 76.1964 39.5926 75.2847 39.9598 74.277C40.3269 73.2694 40.4709 72.1908 40.3813 71.1193C40.3813 71.1193 40.9879 61.0172 37.4864 57.9837C35.5151 56.2765 35.9215 50.2378 33.9215 50.7096L27.8504 53.0314C25.9073 52.8893 23.9795 52.5778 22.0881 52.1002C18.6418 51.2537 17.3597 50.393 14.7956 50.2378C12.8325 50.1346 10.8699 50.4517 9.03333 51.169L5.31126 53.0314C4.47035 55.8532 3.51916 57.6592 2.88503 58.0825C1.29971 59.1689 1.42378 62.5833 1.42378 62.5833L1.49271 70.3433Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M8.18129 61.3064C6.01649 57.7484 12.1865 53.3346 12.9183 44.9432C13.3757 39.4216 12.5067 39.0891 12.6591 30.5299C12.587 27.2414 12.9832 25.3221 13.3793 21.8451C13.7002 19.0288 13.8738 17.3581 15.6117 12.8435C17.1362 8.74853 16.5406 4.4672 17.2724 4.4672C18.0042 4.4672 18.5388 8.21819 18.615 10.5342C18.7217 13.4209 17.4107 14.2768 17.0905 18.0026C16.9533 19.6808 17.6028 22.2696 18.2278 22.4375C19.1273 22.6724 20.8957 17.839 21.2768 16.8823C24.8442 7.23223 23.2781 1.41093 24.4215 1.20953C25.5649 1.00814 27.1379 5.95935 25.6591 13.2095C25.0798 16.0458 24.6346 17.301 23.3845 20.9261C21.1282 27.4043 22.3897 33.6819 22.8166 33.7994C23.2434 33.9169 26.3508 19.2151 29.8724 11.6629C30.9243 9.41399 33.3117 6.54169 33.9215 6.70952C34.5313 6.87734 30.7603 10.4878 30.6688 16.6807C30.6688 21.0778 29.2079 31.03 29.4213 37.7095C29.5738 42.7444 29.659 41.9556 29.8724 45.7485C30.0858 49.8267 31.6435 52.6633 31.6587 57.8659C31.6587 61.3064 31.4605 62.5148 30.6525 63.421C29.2043 65.0993 26.7346 64.7301 25.1186 64.5287C20.1182 63.9077 19.2949 60.9036 16.0477 61.4742C13.7152 61.8938 12.8158 63.6895 10.773 63.1525C9.7351 62.9013 8.81468 62.2457 8.18129 61.3064Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M32.4215 18.8706C29.6791 17.5064 28.1477 17.5685 25.4215 18.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M24.4215 18.8706C22.0709 17.5064 20.7582 17.5685 18.4215 18.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M17.4215 18.8706C14.6791 17.5064 13.1477 17.5685 10.4215 18.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M19.7903 9.88451L21.9451 23.5399L26.7665 12.2878C26.9147 4.99589 26.3221 4.9959 26.3221 4.9959C24.3154 1.00854 22.9821 2.81104 21.6488 3.94444C20.3155 5.07783 19.7903 9.88451 19.7903 9.88451Z" fill="#DEE1E9"/>
      <path d="M0.833904 72.9319C0.833904 72.9319 -0.309879 84.1177 11.8583 86.0258C24.0266 87.9339 17.0674 83.8965 25.942 84.1592C34.8167 84.4219 35.0372 81.9608 37.6279 79.4443C38.3808 78.7094 38.9617 77.8159 39.3287 76.8284C39.6957 75.8409 39.8396 74.784 39.7501 73.7339C39.7501 73.7339 40.3565 63.8478 36.8562 60.875C34.8856 59.202 34.3206 55.8559 34.1966 53.2565C34.1405 52.0347 34.1681 50.8105 34.2793 49.5925C34.6807 45.6665 34.6067 41.7063 34.0588 37.7983C33.7832 35.8902 32.4327 27.2485 32.0055 22.1879C31.8687 20.7629 31.9901 19.3248 32.3638 17.9431C32.691 16.7458 33.2124 15.6106 33.9072 14.5832C34.407 13.6448 34.7112 12.6141 34.8012 11.5539C34.8912 10.4938 34.7652 9.42629 34.4309 8.41654C31.3991 6.20427 27.0031 12.2189 27.0031 12.2189L22.0697 23.6397L19.8648 9.81304C19.8648 9.81304 19.4101 5.25023 16.8331 5.55442C16.076 5.68384 15.3776 6.04605 14.8345 6.59097C14.2914 7.13588 13.9304 7.8366 13.8014 8.59629C13.953 15.3714 12.4233 16.3531 12.4233 16.3531C12.4233 16.3531 4.84404 23.3632 6.20832 36.7474C6.94102 43.1836 6.42153 49.7013 4.67868 55.9389C3.83806 58.7042 2.88721 60.4602 2.2533 60.875C0.668541 61.9397 0.792564 65.2996 0.792564 65.2996L0.833904 72.9319Z" fill="#DEE1E9"/>
      <mask id="mask2228" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="52" width="41" height="36">
        <path d="M0.925941 73.3256C0.925941 73.3256 -0.218246 84.7399 11.9543 86.6728C24.1268 88.6058 17.1238 84.5 26.0016 84.7399C34.8794 84.9797 35.1 82.4824 37.6916 79.9287C38.4448 79.1787 39.0259 78.267 39.393 77.2593C39.7601 76.2517 39.9041 75.1731 39.8146 74.1016C39.8146 74.1016 40.4211 63.9995 36.9196 60.966C34.9483 59.2588 35.3547 53.2201 33.3547 53.6919L27.2836 56.0137C25.3405 55.8716 23.4127 55.5601 21.5213 55.0825C18.075 54.236 16.7929 53.3753 14.2289 53.2201C12.2657 53.1169 10.3031 53.434 8.46656 54.1513L4.7445 56.0137C3.90359 58.8355 2.9524 60.6415 2.31827 61.0648C0.732944 62.1512 0.857014 65.5656 0.857014 65.5656L0.925941 73.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2228)">
        <path d="M0.925941 73.3256C0.925941 73.3256 -0.218246 84.7399 11.9543 86.6728C24.1268 88.6058 17.1238 84.5 26.0016 84.7399C34.8794 84.9797 35.1 82.4824 37.6916 79.9287C38.4448 79.1787 39.0259 78.267 39.393 77.2593C39.7601 76.2517 39.9041 75.1731 39.8146 74.1016C39.8146 74.1016 40.4211 63.9995 36.9196 60.966C34.9483 59.2588 35.3547 53.2201 33.3547 53.6919L27.2836 56.0137C25.3405 55.8716 23.4127 55.5601 21.5213 55.0825C18.075 54.236 16.7929 53.3753 14.2289 53.2201C12.2657 53.1169 10.3031 53.434 8.46656 54.1513L4.7445 56.0137C3.90359 58.8355 2.9524 60.6415 2.31827 61.0648C0.732944 62.1512 0.857014 65.5656 0.857014 65.5656L0.925941 73.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M19.547 71.201C20.6091 71.201 21.8547 76.4993 21.8547 86.0976L19.9317 87.9597L16.8547 89.201C16.8547 79.6027 18.485 71.201 19.547 71.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.32568 71.3428C5.1003 67.3089 11.443 62.3047 12.1953 52.7909C12.6654 46.5308 11.7721 46.1538 11.9288 36.4498C11.8547 32.7214 12.262 30.5454 12.6692 26.6034C12.999 23.4104 13.1775 21.5163 14.964 16.3978C16.5312 11.7551 15.9189 6.90115 16.6712 6.90115C17.4234 6.90115 17.973 11.1539 18.0514 13.7797C18.1611 17.0524 16.8133 18.0228 16.4842 22.2469C16.3432 24.1497 17.0108 27.0847 17.6533 27.275C18.578 27.5414 20.3959 22.0614 20.7877 20.9769C24.4549 10.036 22.845 3.43609 24.0204 3.20776C25.1958 2.97943 26.8128 8.59288 25.2926 16.8128C24.6971 20.0284 24.2394 21.4516 22.9544 25.5615C20.635 32.9061 21.9317 40.0234 22.3705 40.1566C22.8093 40.2898 26.0036 23.6217 29.6238 15.0593C30.7051 12.5096 33.1593 9.25311 33.7862 9.44339C34.4131 9.63366 30.5365 13.7271 30.4425 20.7482C30.4425 25.7334 28.9407 37.0168 29.1601 44.5897C29.3168 50.298 29.4044 49.4037 29.6238 53.7039C29.8432 58.3276 31.4444 61.5436 31.4601 67.4421C31.4601 71.3428 31.2564 72.7128 30.4258 73.7402C28.937 75.643 26.3982 75.2244 24.737 74.996C19.5966 74.292 18.7503 70.8861 15.4123 71.533C13.0145 72.0087 12.0899 74.0447 9.98987 73.4358C8.92298 73.151 7.9768 72.4077 7.32568 71.3428Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 33.1209 11.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 24.1208 4.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 16.1208 7.20099)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M19.7903 13.8845L21.9451 27.5399L26.7665 16.2878C26.9147 8.99589 26.3221 8.9959 26.3221 8.9959C24.3154 5.00854 22.9821 6.81104 21.6488 7.94444C20.3155 9.07783 19.7903 13.8845 19.7903 13.8845Z" fill="#DEE1E9"/>
      <path d="M0.833904 76.9319C0.833904 76.9319 -0.309879 88.1177 11.8583 90.0258C24.0266 91.9339 17.0674 87.8965 25.942 88.1592C34.8167 88.4219 35.0372 85.9608 37.6279 83.4443C38.3808 82.7094 38.9617 81.8159 39.3287 80.8284C39.6957 79.8409 39.8396 78.784 39.7501 77.7339C39.7501 77.7339 40.3565 67.8478 36.8562 64.875C34.8856 63.202 34.3206 59.8559 34.1966 57.2565C34.1405 56.0347 34.1681 54.8105 34.2793 53.5925C34.6807 49.6665 34.6067 45.7063 34.0588 41.7983C33.7832 39.8902 32.4327 31.2485 32.0055 26.1879C31.8687 24.7629 31.9901 23.3248 32.3638 21.9431C32.691 20.7458 33.2124 19.6106 33.9072 18.5832C34.407 17.6448 34.7112 16.6141 34.8012 15.5539C34.8912 14.4938 34.7652 13.4263 34.4309 12.4165C31.3991 10.2043 27.0031 16.2189 27.0031 16.2189L22.0697 27.6397L19.8648 13.813C19.8648 13.813 19.4101 9.25023 16.8331 9.55442C16.076 9.68384 15.3776 10.046 14.8345 10.591C14.2914 11.1359 13.9304 11.8366 13.8014 12.5963C13.953 19.3714 12.4233 20.3531 12.4233 20.3531C12.4233 20.3531 4.84404 27.3632 6.20832 40.7474C6.94102 47.1836 6.42153 53.7013 4.67868 59.9389C3.83806 62.7042 2.88721 64.4602 2.2533 64.875C0.668541 65.9397 0.792564 69.2996 0.792564 69.2996L0.833904 76.9319Z" fill="#DEE1E9"/>
      <mask id="mask2328" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="41" height="36">
        <path d="M0.925941 77.3256C0.925941 77.3256 -0.218246 88.7399 11.9543 90.6728C24.1268 92.6058 17.1238 88.5 26.0016 88.7399C34.8794 88.9797 35.1 86.4824 37.6916 83.9287C38.4448 83.1787 39.0259 82.267 39.393 81.2593C39.7601 80.2517 39.9041 79.1731 39.8146 78.1016C39.8146 78.1016 40.4211 67.9995 36.9196 64.966C34.9483 63.2588 35.3547 57.2201 33.3547 57.6919L27.2836 60.0137C25.3405 59.8716 23.4127 59.5601 21.5213 59.0825C18.075 58.236 16.7929 57.3753 14.2289 57.2201C12.2657 57.1169 10.3031 57.434 8.46656 58.1513L4.7445 60.0137C3.90359 62.8355 2.9524 64.6415 2.31827 65.0648C0.732944 66.1512 0.857014 69.5656 0.857014 69.5656L0.925941 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2328)">
        <path d="M0.925941 77.3256C0.925941 77.3256 -0.218246 88.7399 11.9543 90.6728C24.1268 92.6058 17.1238 88.5 26.0016 88.7399C34.8794 88.9797 35.1 86.4824 37.6916 83.9287C38.4448 83.1787 39.0259 82.267 39.393 81.2593C39.7601 80.2517 39.9041 79.1731 39.8146 78.1016C39.8146 78.1016 40.4211 67.9995 36.9196 64.966C34.9483 63.2588 35.3547 57.2201 33.3547 57.6919L27.2836 60.0137C25.3405 59.8716 23.4127 59.5601 21.5213 59.0825C18.075 58.236 16.7929 57.3753 14.2289 57.2201C12.2657 57.1169 10.3031 57.434 8.46656 58.1513L4.7445 60.0137C3.90359 62.8355 2.9524 64.6415 2.31827 65.0648C0.732944 66.1512 0.857014 69.5656 0.857014 69.5656L0.925941 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M19.547 75.201C20.6091 75.201 21.8547 80.4993 21.8547 90.0976L19.9317 91.9597L16.8547 93.201C16.8547 83.6027 18.485 75.201 19.547 75.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.32568 75.3428C5.1003 71.3089 11.443 66.3047 12.1953 56.7909C12.6654 50.5308 11.7721 50.1538 11.9288 40.4498C11.8547 36.7214 12.262 34.5454 12.6692 30.6034C12.999 27.4104 13.1775 25.5163 14.964 20.3978C16.5312 15.7551 15.9189 10.9011 16.6712 10.9011C17.4234 10.9011 17.973 15.1539 18.0514 17.7797C18.1611 21.0524 16.8133 22.0228 16.4842 26.2469C16.3432 28.1497 17.0108 31.0847 17.6533 31.275C18.578 31.5414 20.3959 26.0614 20.7877 24.9769C24.4549 14.036 22.845 7.43609 24.0204 7.20776C25.1958 6.97943 26.8128 12.5929 25.2926 20.8128C24.6971 24.0284 24.2394 25.4516 22.9544 29.5615C20.635 36.9061 21.9317 44.0234 22.3705 44.1566C22.8093 44.2898 26.0036 27.6217 29.6238 19.0593C30.7051 16.5096 33.1593 13.2531 33.7862 13.4434C34.4131 13.6337 30.5365 17.7271 30.4425 24.7482C30.4425 29.7334 28.9407 41.0168 29.1601 48.5897C29.3168 54.298 29.4044 53.4037 29.6238 57.7039C29.8432 62.3276 31.4444 65.5436 31.4601 71.4421C31.4601 75.3428 31.2564 76.7128 30.4258 77.7402C28.937 79.643 26.3982 79.2244 24.737 78.996C19.5966 78.292 18.7503 74.8861 15.4123 75.533C13.0145 76.0087 12.0899 78.0447 9.98987 77.4358C8.92298 77.151 7.9768 76.4077 7.32568 75.3428Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 33.1209 15.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 24.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2328)">
        <path d="M25.1208 0.200989L24.2979 3.94009L21.2641 1.60472L23.0372 4.99799L19.212 5.1591L22.7514 6.61879L19.9247 9.20099L23.5743 8.04409L23.0687 11.8391L25.1208 8.60699L27.173 11.8391L26.6674 8.04409L30.317 9.20099L27.4903 6.61879L31.0297 5.1591L27.2045 4.99799L28.9776 1.60472L25.9437 3.94009L25.1208 0.200989Z" fill="#98B250"/>
      </g>
      <circle r="4" transform="matrix(-1 0 0 1 16.1208 11.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <defs>
        <filter id="filter2328" x="19.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M19.7903 13.8845L21.9451 27.5399L26.7665 16.2878C26.9147 8.99589 26.3221 8.9959 26.3221 8.9959C24.3154 5.00854 22.9821 6.81104 21.6488 7.94444C20.3155 9.07783 19.7903 13.8845 19.7903 13.8845Z" fill="#DEE1E9"/>
      <path d="M0.833904 76.9319C0.833904 76.9319 -0.309879 88.1177 11.8583 90.0258C24.0266 91.9339 17.0674 87.8965 25.942 88.1592C34.8167 88.4219 35.0372 85.9608 37.6279 83.4443C38.3808 82.7094 38.9617 81.8159 39.3287 80.8284C39.6957 79.8409 39.8396 78.784 39.7501 77.7339C39.7501 77.7339 40.3565 67.8478 36.8562 64.875C34.8856 63.202 34.3206 59.8559 34.1966 57.2565C34.1405 56.0347 34.1681 54.8105 34.2793 53.5925C34.6807 49.6665 34.6067 45.7063 34.0588 41.7983C33.7832 39.8902 32.4327 31.2485 32.0055 26.1879C31.8687 24.7629 31.9901 23.3248 32.3638 21.9431C32.691 20.7458 33.2124 19.6106 33.9072 18.5832C34.407 17.6448 34.7112 16.6141 34.8012 15.5539C34.8912 14.4938 34.7652 13.4263 34.4309 12.4165C31.3991 10.2043 27.0031 16.2189 27.0031 16.2189L22.0697 27.6397L19.8648 13.813C19.8648 13.813 19.4101 9.25023 16.8331 9.55442C16.076 9.68384 15.3776 10.046 14.8345 10.591C14.2914 11.1359 13.9304 11.8366 13.8014 12.5963C13.953 19.3714 12.4233 20.3531 12.4233 20.3531C12.4233 20.3531 4.84404 27.3632 6.20832 40.7474C6.94102 47.1836 6.42153 53.7013 4.67868 59.9389C3.83806 62.7042 2.88721 64.4602 2.2533 64.875C0.668541 65.9397 0.792564 69.2996 0.792564 69.2996L0.833904 76.9319Z" fill="#DEE1E9"/>
      <mask id="mask2328" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="41" height="36">
        <path d="M0.925941 77.3256C0.925941 77.3256 -0.218246 88.7399 11.9543 90.6728C24.1268 92.6058 17.1238 88.5 26.0016 88.7399C34.8794 88.9797 35.1 86.4824 37.6916 83.9287C38.4448 83.1787 39.0259 82.267 39.393 81.2593C39.7601 80.2517 39.9041 79.1731 39.8146 78.1016C39.8146 78.1016 40.4211 67.9995 36.9196 64.966C34.9483 63.2588 35.3547 57.2201 33.3547 57.6919L27.2836 60.0137C25.3405 59.8716 23.4127 59.5601 21.5213 59.0825C18.075 58.236 16.7929 57.3753 14.2289 57.2201C12.2657 57.1169 10.3031 57.434 8.46656 58.1513L4.7445 60.0137C3.90359 62.8355 2.9524 64.6415 2.31827 65.0648C0.732944 66.1512 0.857014 69.5656 0.857014 69.5656L0.925941 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2328)">
        <path d="M0.925941 77.3256C0.925941 77.3256 -0.218246 88.7399 11.9543 90.6728C24.1268 92.6058 17.1238 88.5 26.0016 88.7399C34.8794 88.9797 35.1 86.4824 37.6916 83.9287C38.4448 83.1787 39.0259 82.267 39.393 81.2593C39.7601 80.2517 39.9041 79.1731 39.8146 78.1016C39.8146 78.1016 40.4211 67.9995 36.9196 64.966C34.9483 63.2588 35.3547 57.2201 33.3547 57.6919L27.2836 60.0137C25.3405 59.8716 23.4127 59.5601 21.5213 59.0825C18.075 58.236 16.7929 57.3753 14.2289 57.2201C12.2657 57.1169 10.3031 57.434 8.46656 58.1513L4.7445 60.0137C3.90359 62.8355 2.9524 64.6415 2.31827 65.0648C0.732944 66.1512 0.857014 69.5656 0.857014 69.5656L0.925941 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M19.547 75.201C20.6091 75.201 21.8547 80.4993 21.8547 90.0976L19.9317 91.9597L16.8547 93.201C16.8547 83.6027 18.485 75.201 19.547 75.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.32568 75.3428C5.1003 71.3089 11.443 66.3047 12.1953 56.7909C12.6654 50.5308 11.7721 50.1538 11.9288 40.4498C11.8547 36.7214 12.262 34.5454 12.6692 30.6034C12.999 27.4104 13.1775 25.5163 14.964 20.3978C16.5312 15.7551 15.9189 10.9011 16.6712 10.9011C17.4234 10.9011 17.973 15.1539 18.0514 17.7797C18.1611 21.0524 16.8133 22.0228 16.4842 26.2469C16.3432 28.1497 17.0108 31.0847 17.6533 31.275C18.578 31.5414 20.3959 26.0614 20.7877 24.9769C24.4549 14.036 22.845 7.43609 24.0204 7.20776C25.1958 6.97943 26.8128 12.5929 25.2926 20.8128C24.6971 24.0284 24.2394 25.4516 22.9544 29.5615C20.635 36.9061 21.9317 44.0234 22.3705 44.1566C22.8093 44.2898 26.0036 27.6217 29.6238 19.0593C30.7051 16.5096 33.1593 13.2531 33.7862 13.4434C34.4131 13.6337 30.5365 17.7271 30.4425 24.7482C30.4425 29.7334 28.9407 41.0168 29.1601 48.5897C29.3168 54.298 29.4044 53.4037 29.6238 57.7039C29.8432 62.3276 31.4444 65.5436 31.4601 71.4421C31.4601 75.3428 31.2564 76.7128 30.4258 77.7402C28.937 79.643 26.3982 79.2244 24.737 78.996C19.5966 78.292 18.7503 74.8861 15.4123 75.533C13.0145 76.0087 12.0899 78.0447 9.98987 77.4358C8.92298 77.151 7.9768 76.4077 7.32568 75.3428Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 33.1209 15.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 24.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2328)">
        <path d="M25.1208 0.200989L24.2979 3.94009L21.2641 1.60472L23.0372 4.99799L19.212 5.1591L22.7514 6.61879L19.9247 9.20099L23.5743 8.04409L23.0687 11.8391L25.1208 8.60699L27.173 11.8391L26.6674 8.04409L30.317 9.20099L27.4903 6.61879L31.0297 5.1591L27.2045 4.99799L28.9776 1.60472L25.9437 3.94009L25.1208 0.200989Z" fill="#98B250"/>
      </g>
      <circle r="4" transform="matrix(-1 0 0 1 16.1208 11.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <defs>
        <filter id="filter2328" x="19.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M19.7903 7.88445L21.9451 21.5398L26.7665 10.2878C26.9147 2.99583 26.3221 2.99584 26.3221 2.99584C24.3154 -0.991523 22.9821 0.810982 21.6488 1.94438C20.3155 3.07777 19.7903 7.88445 19.7903 7.88445Z" fill="#DEE1E9"/>
      <path d="M0.833904 70.9319C0.833904 70.9319 -0.309879 82.1177 11.8583 84.0257C24.0266 85.9338 17.0674 81.8964 25.942 82.1591C34.8167 82.4218 35.0372 79.9607 37.6279 77.4442C38.3808 76.7093 38.9617 75.8158 39.3287 74.8283C39.6957 73.8408 39.8396 72.7839 39.7501 71.7338C39.7501 71.7338 40.3565 61.8477 36.8562 58.875C34.8856 57.202 34.3206 53.8559 34.1966 51.2565C34.1405 50.0347 34.1681 48.8104 34.2793 47.5924C34.6807 43.6665 34.6067 39.7062 34.0588 35.7982C33.7832 33.8901 32.4327 25.2484 32.0055 20.1879C31.8687 18.7628 31.9901 17.3247 32.3638 15.9431C32.691 14.7457 33.2124 13.6106 33.9072 12.5832C34.407 11.6448 34.7112 10.6141 34.8012 9.55388C34.8912 8.4937 34.7652 7.42623 34.4309 6.41648C31.3991 4.20421 27.0031 10.2188 27.0031 10.2188L22.0697 21.6397L19.8648 7.81298C19.8648 7.81298 19.4101 3.25017 16.8331 3.55436C16.076 3.68378 15.3776 4.04599 14.8345 4.5909C14.2914 5.13582 13.9304 5.83654 13.8014 6.59623C13.953 13.3713 12.4233 14.353 12.4233 14.353C12.4233 14.353 4.84404 21.3631 6.20832 34.7474C6.94102 41.1836 6.42153 47.7013 4.67868 53.9388C3.83806 56.7042 2.88721 58.4602 2.2533 58.875C0.668541 59.9396 0.792564 63.2995 0.792564 63.2995L0.833904 70.9319Z" fill="#DEE1E9"/>
      <circle r="4" transform="matrix(-1 0 0 1 33.1209 7.20093)" fill="rgba(243, 42, 102, 0.6)"/>
      <mask id="mask2528" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="36">
        <path d="M0.925941 71.3255C0.925941 71.3255 -0.218246 82.7398 11.9543 84.6727C24.1268 86.6057 17.1238 82.4999 26.0016 82.7397C34.8794 82.9796 35.1 80.4823 37.6916 77.9285C38.4448 77.1786 39.0259 76.2669 39.393 75.2592C39.7601 74.2515 39.9041 73.173 39.8146 72.1015C39.8146 72.1015 40.4211 61.9993 36.9196 58.9659C34.9483 57.2587 35.3547 51.22 33.3547 51.6918L27.2836 54.0136C25.3405 53.8715 23.4127 53.56 21.5213 53.0824C18.075 52.2358 16.7929 51.3752 14.2289 51.22C12.2657 51.1168 10.3031 51.4339 8.46656 52.1512L4.7445 54.0136C3.90359 56.8354 2.9524 58.6414 2.31827 59.0646C0.732944 60.1511 0.857014 63.5655 0.857014 63.5655L0.925941 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2528)">
        <path d="M0.925941 71.3255C0.925941 71.3255 -0.218246 82.7398 11.9543 84.6727C24.1268 86.6057 17.1238 82.4999 26.0016 82.7397C34.8794 82.9796 35.1 80.4823 37.6916 77.9285C38.4448 77.1786 39.0259 76.2669 39.393 75.2592C39.7601 74.2515 39.9041 73.173 39.8146 72.1015C39.8146 72.1015 40.4211 61.9993 36.9196 58.9659C34.9483 57.2587 35.3547 51.22 33.3547 51.6918L27.2836 54.0136C25.3405 53.8715 23.4127 53.56 21.5213 53.0824C18.075 52.2358 16.7929 51.3752 14.2289 51.22C12.2657 51.1168 10.3031 51.4339 8.46656 52.1512L4.7445 54.0136C3.90359 56.8354 2.9524 58.6414 2.31827 59.0646C0.732944 60.1511 0.857014 63.5655 0.857014 63.5655L0.925941 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M19.547 73.2009C20.6091 73.2009 21.8547 77.3218 21.8547 84.7871L19.9317 86.2354L16.8547 87.2009C16.8547 79.7356 18.485 73.2009 19.547 73.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2628" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="86">
        <path d="M20.2932 8.24651L22.448 21.9019L27.2694 10.6498C27.4176 3.35789 26.825 3.3579 26.825 3.3579C24.8183 -0.629462 23.485 1.17304 22.1518 2.30644C20.8185 3.43983 20.2932 8.24651 20.2932 8.24651Z" fill="#C7CAD5"/>
        <path d="M1.33683 71.2939C1.33683 71.2939 0.19305 82.4797 12.3613 84.3878C24.5295 86.2959 17.5703 82.2585 26.445 82.5212C35.3196 82.7839 35.5401 80.3228 38.1309 77.8063C38.8837 77.0714 39.4646 76.1779 39.8316 75.1904C40.1986 74.2029 40.3426 73.146 40.2531 72.0959C40.2531 72.0959 40.8594 62.2098 37.3592 59.237C35.3885 57.564 34.8235 54.2179 34.6995 51.6185C34.6434 50.3967 34.671 49.1725 34.7822 47.9545C35.1837 44.0285 35.1096 40.0683 34.5617 36.1603C34.2861 34.2522 32.9356 25.6105 32.5084 20.5499C32.3716 19.1249 32.493 17.6868 32.8667 16.3051C33.1939 15.1078 33.7153 13.9726 34.4101 12.9452C34.9099 12.0068 35.2141 10.9761 35.3041 9.91594C35.3942 8.85576 35.2681 7.78829 34.9338 6.77854C31.9021 4.56627 27.5061 10.5809 27.5061 10.5809L22.5726 22.0017L20.3678 8.17504C20.3678 8.17504 19.913 3.61223 17.336 3.91642C16.5789 4.04584 15.8805 4.40805 15.3374 4.95296C14.7943 5.49788 14.4333 6.1986 14.3043 6.95829C14.4559 13.7334 12.9263 14.7151 12.9263 14.7151C12.9263 14.7151 5.34697 21.7252 6.71125 35.1094C7.44395 41.5456 6.92446 48.0633 5.18161 54.3009C4.34099 57.0662 3.39014 58.8222 2.75623 59.237C1.17147 60.3017 1.29549 63.6616 1.29549 63.6616L1.33683 71.2939Z" fill="#DEE1E9"/>
        <path d="M1.36442 71.0356C1.36442 71.0356 0.22023 82.4498 12.3927 84.3828C24.5653 86.3157 17.5623 82.21 26.4401 82.4498C35.3179 82.6897 35.5385 80.1924 38.1301 77.6386C38.8832 76.8887 39.4643 75.977 39.8315 74.9693C40.1986 73.9616 40.3426 72.8831 40.2531 71.8116C40.2531 71.8116 40.8596 61.7094 37.3581 58.676C35.3868 56.9688 35.7932 50.9301 33.7932 51.4019L27.7221 53.7237C25.779 53.5816 23.8512 53.2701 21.9598 52.7925C18.5135 51.9459 17.2314 51.0853 14.6673 50.9301C12.7042 50.8268 10.7416 51.144 8.90504 51.8613L5.18298 53.7237C4.34206 56.5455 3.39087 58.3515 2.75674 58.7747C1.17142 59.8611 1.29549 63.2755 1.29549 63.2755L1.36442 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2628)">
        <path d="M20.2932 8.24651L22.448 21.9019L27.2694 10.6498C27.4176 3.35789 26.825 3.3579 26.825 3.3579C24.8183 -0.629462 23.485 1.17304 22.1518 2.30644C20.8185 3.43983 20.2932 8.24651 20.2932 8.24651Z" fill="#C7CAD5"/>
        <path d="M1.33683 71.2939C1.33683 71.2939 0.19305 82.4797 12.3613 84.3878C24.5295 86.2959 17.5703 82.2585 26.445 82.5212C35.3196 82.7839 35.5401 80.3228 38.1309 77.8063C38.8837 77.0714 39.4646 76.1779 39.8316 75.1904C40.1986 74.2029 40.3426 73.146 40.2531 72.0959C40.2531 72.0959 40.8594 62.2098 37.3592 59.237C35.3885 57.564 34.8235 54.2179 34.6995 51.6185C34.6434 50.3967 34.671 49.1725 34.7822 47.9545C35.1837 44.0285 35.1096 40.0683 34.5617 36.1603C34.2861 34.2522 32.9356 25.6105 32.5084 20.5499C32.3716 19.1249 32.493 17.6868 32.8667 16.3051C33.1939 15.1078 33.7153 13.9726 34.4101 12.9452C34.9099 12.0068 35.2141 10.9761 35.3041 9.91594C35.3942 8.85576 35.2681 7.78829 34.9338 6.77854C31.9021 4.56627 27.5061 10.5809 27.5061 10.5809L22.5726 22.0017L20.3678 8.17504C20.3678 8.17504 19.913 3.61223 17.336 3.91642C16.5789 4.04584 15.8805 4.40805 15.3374 4.95296C14.7943 5.49788 14.4333 6.1986 14.3043 6.95829C14.4559 13.7334 12.9263 14.7151 12.9263 14.7151C12.9263 14.7151 5.34697 21.7252 6.71125 35.1094C7.44395 41.5456 6.92446 48.0633 5.18161 54.3009C4.34099 57.0662 3.39014 58.8222 2.75623 59.237C1.17147 60.3017 1.29549 63.6616 1.29549 63.6616L1.33683 71.2939Z" fill="#DEE1E9"/>
        <path d="M1.36442 71.0356C1.36442 71.0356 0.22023 82.4498 12.3927 84.3828C24.5653 86.3157 17.5623 82.21 26.4401 82.4498C35.3179 82.6897 35.5385 80.1924 38.1301 77.6386C38.8832 76.8887 39.4643 75.977 39.8315 74.9693C40.1986 73.9616 40.3426 72.8831 40.2531 71.8116C40.2531 71.8116 40.8596 61.7094 37.3581 58.676C35.3868 56.9688 35.7932 50.9301 33.7932 51.4019L27.7221 53.7237C25.779 53.5816 23.8512 53.2701 21.9598 52.7925C18.5135 51.9459 17.2314 51.0853 14.6673 50.9301C12.7042 50.8268 10.7416 51.144 8.90504 51.8613L5.18298 53.7237C4.34206 56.5455 3.39087 58.3515 2.75674 58.7747C1.17142 59.8611 1.29549 63.2755 1.29549 63.2755L1.36442 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M17.5978 53.5247H5.67667C7.83873 48.1549 8.19573 44.8947 7.8992 39.1564C7.88802 38.94 8.13739 38.8054 8.32761 38.932C9.33169 39.6 11.6899 41.4161 12.2615 44.0577C12.954 47.2577 14.9572 48.0348 15.1698 48.1089C15.1828 48.1134 15.1944 48.1179 15.2067 48.1236L16.3897 48.6703C16.5342 48.7371 16.5928 48.8996 16.5206 49.0332L15.9916 50.0109C15.9469 50.0936 15.951 50.1917 16.0024 50.271L17.8488 53.1151C17.9658 53.2954 17.8253 53.5247 17.5978 53.5247Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67275 40.0459C9.73373 40.8938 11.1417 42.3307 11.5441 44.1903C12.2873 47.6248 14.4633 48.5809 14.8963 48.7361L15.6699 49.0936L15.3373 49.7084C15.1806 49.9979 15.1949 50.3413 15.3751 50.6189L16.8225 52.8485H6.71999C8.49672 48.1838 8.87272 44.9881 8.67275 40.0459ZM14.8796 48.7284C14.8794 48.7283 14.8797 48.7285 14.8796 48.7284V48.7284ZM8.75508 38.3832C8.02622 37.8983 7.13049 38.4547 7.16842 39.1886C7.46123 44.8548 7.11202 48.0209 4.991 53.2889L4.62378 54.2009H17.5979C18.3941 54.2009 18.8859 53.3982 18.4762 52.7672L16.7539 50.114L17.175 49.3356C17.428 48.8679 17.2229 48.2992 16.7169 48.0654L15.534 47.5188C15.4933 47.5 15.4574 47.4864 15.4279 47.4761C15.3913 47.4634 13.6105 46.8432 12.979 43.9251C12.3495 41.0161 9.78613 39.0691 8.75508 38.3832Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask128" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="86">
        <path d="M20.2932 8.24651L22.448 21.9019L27.2694 10.6498C27.4176 3.35789 26.825 3.3579 26.825 3.3579C24.8183 -0.629462 23.485 1.17304 22.1518 2.30644C20.8185 3.43983 20.2932 8.24651 20.2932 8.24651Z" fill="#C7CAD5"/>
        <path d="M1.33683 71.2939C1.33683 71.2939 0.19305 82.4797 12.3613 84.3878C24.5295 86.2959 17.5703 82.2585 26.445 82.5212C35.3196 82.7839 35.5401 80.3228 38.1309 77.8063C38.8837 77.0714 39.4646 76.1779 39.8316 75.1904C40.1986 74.2029 40.3426 73.146 40.2531 72.0959C40.2531 72.0959 40.8594 62.2098 37.3592 59.237C35.3885 57.564 34.8235 54.2179 34.6995 51.6185C34.6434 50.3967 34.671 49.1725 34.7822 47.9545C35.1837 44.0285 35.1096 40.0683 34.5617 36.1603C34.2861 34.2522 32.9356 25.6105 32.5084 20.5499C32.3716 19.1249 32.493 17.6868 32.8667 16.3051C33.1939 15.1078 33.7153 13.9726 34.4101 12.9452C34.9099 12.0068 35.2141 10.9761 35.3041 9.91594C35.3942 8.85576 35.2681 7.78829 34.9338 6.77854C31.9021 4.56627 27.5061 10.5809 27.5061 10.5809L22.5726 22.0017L20.3678 8.17504C20.3678 8.17504 19.913 3.61223 17.336 3.91642C16.5789 4.04584 15.8805 4.40805 15.3374 4.95296C14.7943 5.49788 14.4333 6.1986 14.3043 6.95829C14.4559 13.7334 12.9263 14.7151 12.9263 14.7151C12.9263 14.7151 5.34697 21.7252 6.71125 35.1094C7.44395 41.5456 6.92446 48.0633 5.18161 54.3009C4.34099 57.0662 3.39014 58.8222 2.75623 59.237C1.17147 60.3017 1.29549 63.6616 1.29549 63.6616L1.33683 71.2939Z" fill="#DEE1E9"/>
        <path d="M1.36442 71.0356C1.36442 71.0356 0.22023 82.4498 12.3927 84.3828C24.5653 86.3157 17.5623 82.21 26.4401 82.4498C35.3179 82.6897 35.5385 80.1924 38.1301 77.6386C38.8832 76.8887 39.4643 75.977 39.8315 74.9693C40.1986 73.9616 40.3426 72.8831 40.2531 71.8116C40.2531 71.8116 40.8596 61.7094 37.3581 58.676C35.3868 56.9688 35.7932 50.9301 33.7932 51.4019L27.7221 53.7237C25.779 53.5816 23.8512 53.2701 21.9598 52.7925C18.5135 51.9459 17.2314 51.0853 14.6673 50.9301C12.7042 50.8268 10.7416 51.144 8.90504 51.8613L5.18298 53.7237C4.34206 56.5455 3.39087 58.3515 2.75674 58.7747C1.17142 59.8611 1.29549 63.2755 1.29549 63.2755L1.36442 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask128)">
        <path d="M20.2932 8.24651L22.448 21.9019L27.2694 10.6498C27.4176 3.35789 26.825 3.3579 26.825 3.3579C24.8183 -0.629462 23.485 1.17304 22.1518 2.30644C20.8185 3.43983 20.2932 8.24651 20.2932 8.24651Z" fill="#C7CAD5"/>
        <path d="M1.33683 71.2939C1.33683 71.2939 0.19305 82.4797 12.3613 84.3878C24.5295 86.2959 17.5703 82.2585 26.445 82.5212C35.3196 82.7839 35.5401 80.3228 38.1309 77.8063C38.8837 77.0714 39.4646 76.1779 39.8316 75.1904C40.1986 74.2029 40.3426 73.146 40.2531 72.0959C40.2531 72.0959 40.8594 62.2098 37.3592 59.237C35.3885 57.564 34.8235 54.2179 34.6995 51.6185C34.6434 50.3967 34.671 49.1725 34.7822 47.9545C35.1837 44.0285 35.1096 40.0683 34.5617 36.1603C34.2861 34.2522 32.9356 25.6105 32.5084 20.5499C32.3716 19.1249 32.493 17.6868 32.8667 16.3051C33.1939 15.1078 33.7153 13.9726 34.4101 12.9452C34.9099 12.0068 35.2141 10.9761 35.3041 9.91594C35.3942 8.85576 35.2681 7.78829 34.9338 6.77854C31.9021 4.56627 27.5061 10.5809 27.5061 10.5809L22.5726 22.0017L20.3678 8.17504C20.3678 8.17504 19.913 3.61223 17.336 3.91642C16.5789 4.04584 15.8805 4.40805 15.3374 4.95296C14.7943 5.49788 14.4333 6.1986 14.3043 6.95829C14.4559 13.7334 12.9263 14.7151 12.9263 14.7151C12.9263 14.7151 5.34697 21.7252 6.71125 35.1094C7.44395 41.5456 6.92446 48.0633 5.18161 54.3009C4.34099 57.0662 3.39014 58.8222 2.75623 59.237C1.17147 60.3017 1.29549 63.6616 1.29549 63.6616L1.33683 71.2939Z" fill="#DEE1E9"/>
        <path d="M1.36442 71.0356C1.36442 71.0356 0.22023 82.4498 12.3927 84.3828C24.5653 86.3157 17.5623 82.21 26.4401 82.4498C35.3179 82.6897 35.5385 80.1924 38.1301 77.6386C38.8832 76.8887 39.4643 75.977 39.8315 74.9693C40.1986 73.9616 40.3426 72.8831 40.2531 71.8116C40.2531 71.8116 40.8596 61.7094 37.3581 58.676C35.3868 56.9688 35.7932 50.9301 33.7932 51.4019L27.7221 53.7237C25.779 53.5816 23.8512 53.2701 21.9598 52.7925C18.5135 51.9459 17.2314 51.0853 14.6673 50.9301C12.7042 50.8268 10.7416 51.144 8.90504 51.8613L5.18298 53.7237C4.34206 56.5455 3.39087 58.3515 2.75674 58.7747C1.17142 59.8611 1.29549 63.2755 1.29549 63.2755L1.36442 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M13.9227 52.5315H5.77592C7.25343 48.561 7.49741 46.1504 7.29476 41.9074C7.28712 41.7474 7.45754 41.6479 7.58753 41.7415C8.2737 42.2354 9.88526 43.5782 10.2759 45.5315C10.7491 47.8976 12.1181 48.4722 12.2634 48.527C12.2722 48.5303 12.2802 48.5337 12.2886 48.5379L13.097 48.9421C13.1958 48.9915 13.2359 49.1116 13.1865 49.2104L12.825 49.9333C12.7944 49.9945 12.7972 50.067 12.8324 50.1257L14.0942 52.2286C14.1742 52.3619 14.0781 52.5315 13.9227 52.5315Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.82339 42.5651C8.54845 43.1921 9.51064 44.2545 9.78565 45.6295C10.2935 48.169 11.7806 48.876 12.0765 48.9908L12.6051 49.2551L12.3778 49.7097C12.2708 49.9238 12.2805 50.1777 12.4037 50.3829L13.3928 52.0315H6.4889C7.70309 48.5824 7.96005 46.2194 7.82339 42.5651ZM12.065 48.9851C12.0649 48.985 12.0651 48.9851 12.065 48.9851V48.9851ZM7.87965 41.3357C7.38156 40.9771 6.76943 41.3885 6.79535 41.9312C6.99545 46.1208 6.75681 48.4619 5.30733 52.3571L5.05638 53.0315H13.9227C14.4668 53.0315 14.8029 52.4379 14.5229 51.9713L13.3459 50.0096L13.6337 49.434C13.8066 49.0882 13.6664 48.6677 13.3207 48.4948L12.5123 48.0906C12.4844 48.0767 12.4599 48.0667 12.4398 48.0591C12.4148 48.0497 11.1978 47.5911 10.7662 45.4334C10.336 43.2825 8.58425 41.8428 7.87965 41.3357Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M20.0564 7.88445L22.2112 21.5398L27.0326 10.2878C27.1808 2.99583 26.5882 2.99584 26.5882 2.99584C24.5815 -0.991523 23.2482 0.810982 21.9149 1.94438C20.5816 3.07777 20.0564 7.88445 20.0564 7.88445Z" fill="#DEE1E9"/>
      <path d="M1.10002 70.9319C1.10002 70.9319 -0.043766 82.1177 12.1244 84.0257C24.2927 85.9338 17.3335 81.8964 26.2082 82.1591C35.0828 82.4218 35.3033 79.9607 37.8941 77.4442C38.6469 76.7093 39.2278 75.8158 39.5948 74.8283C39.9618 73.8408 40.1057 72.7839 40.0163 71.7338C40.0163 71.7338 40.6226 61.8477 37.1223 58.875C35.1517 57.202 34.5867 53.8559 34.4627 51.2565C34.4066 50.0347 34.4342 48.8104 34.5454 47.5924C34.9469 43.6665 34.8728 39.7062 34.3249 35.7982C34.0493 33.8901 32.6988 25.2484 32.2716 20.1879C32.1348 18.7628 32.2562 17.3247 32.6299 15.9431C32.9571 14.7457 33.4785 13.6106 34.1733 12.5832C34.6731 11.6448 34.9773 10.6141 35.0673 9.55388C35.1573 8.4937 35.0313 7.42623 34.697 6.41648C31.6653 4.20421 27.2693 10.2188 27.2693 10.2188L22.3358 21.6397L20.1309 7.81298C20.1309 7.81298 19.6762 3.25017 17.0992 3.55436C16.3421 3.68378 15.6437 4.04599 15.1006 4.5909C14.5575 5.13582 14.1965 5.83654 14.0675 6.59623C14.2191 13.3713 12.6895 14.353 12.6895 14.353C12.6895 14.353 5.11016 21.3631 6.47443 34.7474C7.20713 41.1836 6.68764 47.7013 4.94479 53.9388C4.10418 56.7042 3.15332 58.4602 2.51942 58.875C0.934654 59.9396 1.05868 63.2995 1.05868 63.2995L1.10002 70.9319Z" fill="#DEE1E9"/>
      <mask id="mask228" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="36">
        <path d="M1.19205 71.3255C1.19205 71.3255 0.0478668 82.7398 12.2204 84.6727C24.3929 86.6057 17.3899 82.4999 26.2677 82.7397C35.1455 82.9796 35.3661 80.4823 37.9577 77.9285C38.7109 77.1786 39.292 76.2669 39.6591 75.2592C40.0262 74.2515 40.1702 73.173 40.0807 72.1015C40.0807 72.1015 40.6873 61.9993 37.1858 58.9659C35.2144 57.2587 35.6209 51.22 33.6209 51.6918L27.5498 54.0136C25.6066 53.8715 23.6789 53.56 21.7875 53.0824C18.3411 52.2358 17.0591 51.3752 14.495 51.22C12.5318 51.1168 10.5692 51.4339 8.73267 52.1512L5.01061 54.0136C4.1697 56.8354 3.21851 58.6414 2.58438 59.0646C0.999058 60.1511 1.12313 63.5655 1.12313 63.5655L1.19205 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask228)">
        <path d="M1.19205 71.3255C1.19205 71.3255 0.0478668 82.7398 12.2204 84.6727C24.3929 86.6057 17.3899 82.4999 26.2677 82.7397C35.1455 82.9796 35.3661 80.4823 37.9577 77.9285C38.7109 77.1786 39.292 76.2669 39.6591 75.2592C40.0262 74.2515 40.1702 73.173 40.0807 72.1015C40.0807 72.1015 40.6873 61.9993 37.1858 58.9659C35.2144 57.2587 35.6209 51.22 33.6209 51.6918L27.5498 54.0136C25.6066 53.8715 23.6789 53.56 21.7875 53.0824C18.3411 52.2358 17.0591 51.3752 14.495 51.22C12.5318 51.1168 10.5692 51.4339 8.73267 52.1512L5.01061 54.0136C4.1697 56.8354 3.21851 58.6414 2.58438 59.0646C0.999058 60.1511 1.12313 63.5655 1.12313 63.5655L1.19205 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M20.2747 71.2009C23.8858 71.2009 28.1208 75.9105 28.1208 84.4423L21.5824 86.0975L11.1208 87.2009C11.1208 78.6691 16.6636 71.2009 20.2747 71.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.5918 69.3426C5.36642 65.3088 11.7091 60.3046 12.4614 50.7908C12.9315 44.5307 12.0382 44.1537 12.195 34.4497C12.1208 30.7213 12.5281 28.5453 12.9353 24.6033C13.2651 21.4102 13.4436 19.5161 15.2302 14.3977C16.7973 9.755 16.1851 4.90103 16.9373 4.90103C17.6895 4.90103 18.2391 9.15373 18.3175 11.7795C18.4272 15.0523 17.0794 16.0227 16.7503 20.2468C16.6093 22.1495 17.2769 25.0846 17.9195 25.2749C18.8441 25.5413 20.662 20.0613 21.0538 18.9767C24.721 8.03589 23.1111 1.43597 24.2865 1.20764C25.4619 0.97931 27.0789 6.59276 25.5587 14.8127C24.9632 18.0283 24.5056 19.4514 23.2205 23.5614C20.9011 30.906 22.1978 38.0233 22.6366 38.1565C23.0754 38.2897 26.2697 21.6216 29.8899 13.0592C30.9713 10.5095 33.4254 7.25299 34.0523 7.44326C34.6792 7.63354 30.8026 11.7269 30.7086 18.7481C30.7086 23.7333 29.2068 35.0166 29.4262 42.5896C29.583 48.2979 29.6705 47.4036 29.8899 51.7038C30.1093 56.3275 31.7105 59.5435 31.7262 65.442C31.7262 69.3427 31.5225 70.7126 30.6919 71.7401C29.2031 73.6429 26.6643 73.2242 25.0031 72.9959C19.8628 72.2919 19.0165 68.886 15.6784 69.5329C13.2806 70.0086 12.356 72.0446 10.256 71.4357C9.18909 71.1509 8.24291 70.4076 7.5918 69.3426Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M20.0564 7.88445L22.2112 21.5398L27.0326 10.2878C27.1808 2.99583 26.5882 2.99584 26.5882 2.99584C24.5815 -0.991523 23.2482 0.810982 21.9149 1.94438C20.5816 3.07777 20.0564 7.88445 20.0564 7.88445Z" fill="#DEE1E9"/>
      <path d="M1.10002 70.9319C1.10002 70.9319 -0.043766 82.1177 12.1244 84.0257C24.2927 85.9338 17.3335 81.8964 26.2082 82.1591C35.0828 82.4218 35.3033 79.9607 37.8941 77.4442C38.6469 76.7093 39.2278 75.8158 39.5948 74.8283C39.9618 73.8408 40.1057 72.7839 40.0163 71.7338C40.0163 71.7338 40.6226 61.8477 37.1223 58.875C35.1517 57.202 34.5867 53.8559 34.4627 51.2565C34.4066 50.0347 34.4342 48.8104 34.5454 47.5924C34.9469 43.6665 34.8728 39.7062 34.3249 35.7982C34.0493 33.8901 32.6988 25.2484 32.2716 20.1879C32.1348 18.7628 32.2562 17.3247 32.6299 15.9431C32.9571 14.7457 33.4785 13.6106 34.1733 12.5832C34.6731 11.6448 34.9773 10.6141 35.0673 9.55388C35.1573 8.4937 35.0313 7.42623 34.697 6.41648C31.6653 4.20421 27.2693 10.2188 27.2693 10.2188L22.3358 21.6397L20.1309 7.81298C20.1309 7.81298 19.6762 3.25017 17.0992 3.55436C16.3421 3.68378 15.6437 4.04599 15.1006 4.5909C14.5575 5.13582 14.1965 5.83654 14.0675 6.59623C14.2191 13.3713 12.6895 14.353 12.6895 14.353C12.6895 14.353 5.11016 21.3631 6.47443 34.7474C7.20713 41.1836 6.68764 47.7013 4.94479 53.9388C4.10418 56.7042 3.15332 58.4602 2.51942 58.875C0.934654 59.9396 1.05868 63.2995 1.05868 63.2995L1.10002 70.9319Z" fill="#DEE1E9"/>
      <mask id="mask328" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="36">
        <path d="M1.19205 71.3255C1.19205 71.3255 0.0478668 82.7398 12.2204 84.6727C24.3929 86.6057 17.3899 82.4999 26.2677 82.7397C35.1455 82.9796 35.3661 80.4823 37.9577 77.9285C38.7109 77.1786 39.292 76.2669 39.6591 75.2592C40.0262 74.2515 40.1702 73.173 40.0807 72.1015C40.0807 72.1015 40.6873 61.9993 37.1858 58.9659C35.2144 57.2587 35.6209 51.22 33.6209 51.6918L27.5498 54.0136C25.6066 53.8715 23.6789 53.56 21.7875 53.0824C18.3411 52.2358 17.0591 51.3752 14.495 51.22C12.5318 51.1168 10.5692 51.4339 8.73267 52.1512L5.01061 54.0136C4.1697 56.8354 3.21851 58.6414 2.58438 59.0646C0.999058 60.1511 1.12313 63.5655 1.12313 63.5655L1.19205 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask328)">
        <path d="M1.19205 71.3255C1.19205 71.3255 0.0478668 82.7398 12.2204 84.6727C24.3929 86.6057 17.3899 82.4999 26.2677 82.7397C35.1455 82.9796 35.3661 80.4823 37.9577 77.9285C38.7109 77.1786 39.292 76.2669 39.6591 75.2592C40.0262 74.2515 40.1702 73.173 40.0807 72.1015C40.0807 72.1015 40.6873 61.9993 37.1858 58.9659C35.2144 57.2587 35.6209 51.22 33.6209 51.6918L27.5498 54.0136C25.6066 53.8715 23.6789 53.56 21.7875 53.0824C18.3411 52.2358 17.0591 51.3752 14.495 51.22C12.5318 51.1168 10.5692 51.4339 8.73267 52.1512L5.01061 54.0136C4.1697 56.8354 3.21851 58.6414 2.58438 59.0646C0.999058 60.1511 1.12313 63.5655 1.12313 63.5655L1.19205 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M19.8132 73.2009C20.8752 73.2009 22.1208 77.3218 22.1208 84.7871L20.1978 86.2354L17.1208 87.2009C17.1208 79.7356 18.7511 73.2009 19.8132 73.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M0.60294 68.2939C0.60294 68.2939 -0.540844 79.4797 11.6274 81.3878C23.7956 83.2959 16.8364 79.2585 25.7111 79.5212C34.5857 79.7839 34.8062 77.3228 37.397 74.8063C38.1499 74.0714 38.7307 73.1779 39.0977 72.1904C39.4647 71.2029 39.6087 70.146 39.5192 69.0959C39.5192 69.0959 40.1255 59.2098 36.6253 56.237C34.6547 54.564 34.0896 51.2179 33.9656 48.6185C33.9095 47.3967 33.9371 46.1725 34.0483 44.9545C34.4498 41.0285 34.3757 37.0683 33.8278 33.1603C33.5522 31.2522 32.2017 22.6105 31.7745 17.5499C31.6377 16.1249 31.7591 14.6868 32.1328 13.3051C32.46 12.1078 32.9815 10.9726 33.6762 9.94525C34.176 9.00681 34.4802 7.97612 34.5702 6.91594C34.6603 5.85576 34.5343 4.78829 34.1999 3.77854C31.1682 1.56627 26.7722 7.58088 26.7722 7.58088L21.8388 19.0017L19.6339 5.17504C19.6339 5.17504 19.1791 0.612229 16.6021 0.916416C15.845 1.04584 15.1466 1.40805 14.6035 1.95296C14.0604 2.49788 13.6994 3.1986 13.5704 3.95829C13.722 10.7334 12.1924 11.7151 12.1924 11.7151C12.1924 11.7151 4.61308 18.7252 5.97735 32.1094C6.71005 38.5456 6.19057 45.0633 4.44771 51.3009C3.6071 54.0662 2.65625 55.8222 2.02234 56.237C0.437576 57.3017 0.5616 60.6616 0.5616 60.6616L0.60294 68.2939Z" fill="#DEE1E9"/>
      <path d="M0.630524 68.0357C0.630524 68.0357 -0.513664 79.45 11.6589 81.3829C23.8314 83.3159 16.8284 79.2101 25.7062 79.45C34.584 79.6898 34.8046 77.1925 37.3962 74.6387C38.1494 73.8888 38.7304 72.9771 39.0976 71.9694C39.4647 70.9617 39.6087 69.8832 39.5192 68.8117C39.5192 68.8117 40.1257 58.7096 36.6242 55.6761C34.6529 53.9689 35.0593 47.9302 33.0593 48.402L26.9882 50.7238C25.0451 50.5817 23.1173 50.2702 21.2259 49.7926C17.7796 48.946 16.4975 48.0854 13.9334 47.9302C11.9703 47.827 10.0077 48.1441 8.17114 48.8614L4.44908 50.7238C3.60817 53.5456 2.65698 55.3516 2.02285 55.7749C0.437527 56.8613 0.561596 60.2757 0.561596 60.2757L0.630524 68.0357Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M31.6238 63.0679L29.7571 65.5687L28.1238 64.073V67.3871L25.5616 66.8192L26.9571 68.5698L24.6238 70.5704L26.9571 72.5711L25.5616 74.3217L27.8904 73.5715L28.1238 77.0679L30.3737 75.5708L31.1571 76.5725L32.8739 75.5708L35.1238 77.0679L35.8238 75.072L37.686 74.3217L37.2238 71.5708L38.6238 70.5704L36.2904 69.0699L37.686 66.8192L35.8238 66.5691L35.1238 64.073L32.0904 66.0689L31.6238 63.0679Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M0.60294 68.2939C0.60294 68.2939 -0.540844 79.4797 11.6274 81.3878C23.7956 83.2959 16.8364 79.2585 25.7111 79.5212C34.5857 79.7839 34.8062 77.3228 37.397 74.8063C38.1499 74.0714 38.7307 73.1779 39.0977 72.1904C39.4647 71.2029 39.6087 70.146 39.5192 69.0959C39.5192 69.0959 40.1255 59.2098 36.6253 56.237C34.6547 54.564 34.0896 51.2179 33.9656 48.6185C33.9095 47.3967 33.9371 46.1725 34.0483 44.9545C34.4498 41.0285 34.3757 37.0683 33.8278 33.1603C33.5522 31.2522 32.2017 22.6105 31.7745 17.5499C31.6377 16.1249 31.7591 14.6868 32.1328 13.3051C32.46 12.1078 32.9815 10.9726 33.6762 9.94525C34.176 9.00681 34.4802 7.97612 34.5702 6.91594C34.6603 5.85576 34.5343 4.78829 34.1999 3.77854C31.1682 1.56627 26.7722 7.58088 26.7722 7.58088L21.8388 19.0017L19.6339 5.17504C19.6339 5.17504 19.1791 0.612229 16.6021 0.916416C15.845 1.04584 15.1466 1.40805 14.6035 1.95296C14.0604 2.49788 13.6994 3.1986 13.5704 3.95829C13.722 10.7334 12.1924 11.7151 12.1924 11.7151C12.1924 11.7151 4.61308 18.7252 5.97735 32.1094C6.71005 38.5456 6.19057 45.0633 4.44771 51.3009C3.6071 54.0662 2.65625 55.8222 2.02234 56.237C0.437576 57.3017 0.5616 60.6616 0.5616 60.6616L0.60294 68.2939Z" fill="#DEE1E9"/>
      <path d="M0.630524 68.0357C0.630524 68.0357 -0.513664 79.45 11.6589 81.3829C23.8314 83.3159 16.8284 79.2101 25.7062 79.45C34.584 79.6898 34.8046 77.1925 37.3962 74.6387C38.1494 73.8888 38.7304 72.9771 39.0976 71.9694C39.4647 70.9617 39.6087 69.8832 39.5192 68.8117C39.5192 68.8117 40.1257 58.7096 36.6242 55.6761C34.6529 53.9689 35.0593 47.9302 33.0593 48.402L26.9882 50.7238C25.0451 50.5817 23.1173 50.2702 21.2259 49.7926C17.7796 48.946 16.4975 48.0854 13.9334 47.9302C11.9703 47.827 10.0077 48.1441 8.17114 48.8614L4.44908 50.7238C3.60817 53.5456 2.65698 55.3516 2.02285 55.7749C0.437527 56.8613 0.561596 60.2757 0.561596 60.2757L0.630524 68.0357Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M32.6238 65.0679L31.0238 67.2115L29.6238 65.9294V68.77L27.4276 68.2833L28.6238 69.7838L26.6238 71.4987L28.6238 73.2135L27.4276 74.714L29.4238 74.071L29.6238 77.0679L31.5523 75.7847L32.2238 76.6433L33.6953 75.7847L35.6238 77.0679L36.2238 75.3571L37.8199 74.714L37.4238 72.3561L38.6238 71.4987L36.6238 70.2125L37.8199 68.2833L36.2238 68.0689L35.6238 65.9294L33.0238 67.6402L32.6238 65.0679Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M20.2932 8.31194L22.448 21.9673L27.2694 10.7153C27.4176 3.42332 26.825 3.42333 26.825 3.42333C24.8183 -0.564033 23.485 1.23847 22.1517 2.37187C20.8184 3.50526 20.2932 8.31194 20.2932 8.31194Z" fill="#C7CAD5"/>
      <path d="M1.33683 71.3593C1.33683 71.3593 0.193043 82.5452 12.3613 84.4532C24.5295 86.3613 17.5703 82.3239 26.445 82.5866C35.3196 82.8493 35.5401 80.3882 38.1309 77.8717C38.8837 77.1368 39.4646 76.2433 39.8316 75.2558C40.1986 74.2683 40.3426 73.2114 40.2531 72.1613C40.2531 72.1613 40.8594 62.2752 37.3592 59.3025C35.3885 57.6294 34.8235 54.2834 34.6995 51.684C34.6434 50.4621 34.671 49.2379 34.7822 48.0199C35.1837 44.094 35.1096 40.1337 34.5617 36.2257C34.2861 34.3176 32.9356 25.6759 32.5084 20.6154C32.3716 19.1903 32.493 17.7522 32.8667 16.3706C33.1939 15.1732 33.7153 14.0381 34.4101 13.0107C34.9099 12.0722 35.2141 11.0415 35.3041 9.98137C35.3941 8.92119 35.2681 7.85372 34.9338 6.84397C31.9021 4.6317 27.5061 10.6463 27.5061 10.6463L22.5726 22.0672L20.3678 8.24047C20.3678 8.24047 19.913 3.67766 17.336 3.98185C16.5789 4.11127 15.8805 4.47348 15.3374 5.01839C14.7943 5.56331 14.4333 6.26403 14.3043 7.02372C14.4559 13.7988 12.9263 14.7805 12.9263 14.7805C12.9263 14.7805 5.34697 21.7906 6.71124 35.1749C7.44394 41.6111 6.92445 48.1288 5.1816 54.3663C4.34098 57.1317 3.39013 58.8877 2.75623 59.3025C1.17146 60.3671 1.29549 63.727 1.29549 63.727L1.33683 71.3593Z" fill="#DEE1E9"/>
      <path d="M1.36441 71.1011C1.36441 71.1011 0.220222 82.5154 12.3927 84.4483C24.5653 86.3813 17.5623 82.2755 26.4401 82.5154C35.3179 82.7552 35.5384 80.2579 38.1301 77.7042C38.8832 76.9542 39.4643 76.0425 39.8315 75.0348C40.1986 74.0272 40.3426 72.9487 40.2531 71.8771C40.2531 71.8771 40.8596 61.775 37.3581 58.7415C35.3868 57.0343 35.7932 50.9956 33.7932 51.4674L27.7221 53.7892C25.779 53.6471 23.8512 53.3356 21.9598 52.858C18.5135 52.0115 17.2314 51.1508 14.6673 50.9956C12.7042 50.8924 10.7416 51.2096 8.90503 51.9268L5.18297 53.7892C4.34206 56.611 3.39087 58.417 2.75674 58.8403C1.17141 59.9267 1.29548 63.3411 1.29548 63.3411L1.36441 71.1011Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M34.8577 72.1333L33.9243 73.3837L33.1077 72.6359V74.2929L31.8266 74.0089L32.5243 74.8842L31.3577 75.8846L32.5243 76.8849L31.8266 77.7602L32.991 77.3851L33.1077 79.1333L34.2326 78.3848L34.6243 78.8856L35.4827 78.3848L36.6077 79.1333L36.9577 78.1354L37.8888 77.7602L37.6577 76.3848L38.3577 75.8846L37.191 75.1343L37.8888 74.0089L36.9577 73.8839L36.6077 72.6359L35.091 73.6338L34.8577 72.1333Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M23.6878 2.68065L21.6041 1.41089H18.1204L16.0204 2.68065L17.632 4.22716L15.5483 5.77367L17.4367 7.32018L15.1413 9.0132L16.9646 10.5597L14.6692 11.9597L16.6878 13.376L14.6692 14.9876L16.3623 16.5341L14.132 17.9504L15.8902 19.4155L13.5948 20.962L15.7436 22.5085L13.5948 24.1201L15.1413 25.4713L13.0576 27.0178L14.8809 28.4992L12.7971 30.0457L14.6041 31.7876L13.5948 33.4643V47.7247L15.4832 55.0503H21.6041" fill="#8889BE"/>
      <path d="M23.6878 2.68065L21.6041 1.41089H18.1204L16.0204 2.68065L17.632 4.22716L15.5483 5.77367L17.4367 7.32018L15.1413 9.0132L16.9646 10.5597L14.6692 11.9597L16.6878 13.376L14.6692 14.9876L16.3623 16.5341L14.132 17.9504L15.8902 19.4155L13.5948 20.962L15.7436 22.5085L13.5948 24.1201L15.1413 25.4713L13.0576 27.0178L14.8809 28.4992L12.7971 30.0457L14.6041 31.7876L13.5948 33.4643V47.7247L15.4832 55.0503H21.6041" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M16.2805 2.68065L18.3643 1.41089H21.8317L23.948 2.68065L22.3201 4.22716L24.4201 5.77367L22.5317 7.32018L24.8108 9.0132L23.0038 10.5597L25.2829 11.9597L23.2643 13.376L25.2829 14.9876L23.6061 16.5341L25.8201 17.9504L24.0782 19.4155L26.3573 20.962L24.2084 22.5085L26.3573 24.1201L24.8108 25.4713L26.8945 27.0178L25.0875 28.4992L27.1712 30.0457L25.348 31.7876L26.3573 33.4643V47.7247L24.4852 55.0503H20.5782" fill="#8889BE"/>
      <path d="M16.2805 2.68065L18.3643 1.41089H21.8317L23.948 2.68065L22.3201 4.22716L24.4201 5.77367L22.5317 7.32018L24.8108 9.0132L23.0038 10.5597L25.2829 11.9597L23.2643 13.376L25.2829 14.9876L23.6061 16.5341L25.8201 17.9504L24.0782 19.4155L26.3573 20.962L24.2084 22.5085L26.3573 24.1201L24.8108 25.4713L26.8945 27.0178L25.0875 28.4992L27.1712 30.0457L25.348 31.7876L26.3573 33.4643V47.7247L24.4852 55.0503H20.5782" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M1.45818 60.3466C1.45818 60.3466 0.313988 71.7609 12.4865 73.6939C24.659 75.6268 17.656 71.5211 26.5338 71.7609C35.4116 72.0008 35.6322 69.5035 38.2239 66.9497C38.977 66.1998 39.5581 65.2881 39.9252 64.2804C40.2923 63.2727 40.4363 62.1942 40.3468 61.1227C40.3468 61.1227 40.9534 51.0205 37.4519 47.9871C35.4806 46.2799 35.887 40.2411 33.887 40.713C33.887 40.713 29.4484 43.1012 28.1663 43.6962C26.8843 44.2913 27.1089 53.9896 25.2175 53.512C21.7507 54.1052 17.7636 55.0522 15.2175 53.512C13.2544 53.4088 13.0137 41.6884 10.7531 42.1622C8.49244 42.636 5.27673 43.0348 5.27673 43.0348C4.43582 45.8566 3.48463 47.6626 2.8505 48.0858C1.26518 49.1722 1.38925 52.5866 1.38925 52.5866L1.45818 60.3466Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask528" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="40" width="41" height="35">
        <path d="M1.45818 60.3466C1.45818 60.3466 0.313988 71.761 12.4865 73.6939C24.659 75.6269 17.656 71.5211 26.5338 71.7609C35.4116 72.0008 35.6322 69.5035 38.2239 66.9497C38.977 66.1998 39.5581 65.2881 39.9252 64.2804C40.2923 63.2727 40.4363 62.1942 40.3468 61.1227C40.3468 61.1227 40.9534 51.0205 37.4519 47.9871C35.4806 46.2799 35.6064 41.0103 33.6064 41.4822L28.5053 43.486C26.5621 43.3439 27.1089 53.9896 25.2175 53.512C21.6378 54.0012 17.6679 54.8105 15.2175 53.512C13.2544 53.4088 13.3386 42.3018 10.8336 42.3018L5.38698 44.1868C4.54607 47.0086 3.48463 47.6626 2.8505 48.0858C1.26518 49.1722 1.38925 52.5866 1.38925 52.5866L1.45818 60.3466Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask528)">
        <path d="M31.387 32.6868H8.38698V58.2347C17.369 60.161 22.4049 60.1805 31.387 58.2347V32.6868Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M23.1537 2.04711L21.0699 0.777344H17.5862L15.4862 2.04711L17.0979 3.59362L15.0141 5.14013L16.9025 6.68663L14.6072 8.37965L16.4304 9.92616L14.1351 11.3262L16.1537 12.7424L14.1351 14.3541L15.8281 15.9006L13.5979 17.3168L15.356 18.782L13.0607 20.3285L15.2095 21.875L13.0607 23.4866L14.6072 24.8378L12.5234 26.3843L14.3467 27.8657L12.263 29.4122L14.07 31.154L13.0607 32.8308V47.0912L14.949 54.4168H21.0699" fill="#8889BE"/>
      <path d="M23.1537 2.04711L21.0699 0.777344H17.5862L15.4862 2.04711L17.0979 3.59362L15.0141 5.14013L16.9025 6.68663L14.6072 8.37965L16.4304 9.92616L14.1351 11.3262L16.1537 12.7424L14.1351 14.3541L15.8281 15.9006L13.5979 17.3168L15.356 18.782L13.0607 20.3285L15.2095 21.875L13.0607 23.4866L14.6072 24.8378L12.5234 26.3843L14.3467 27.8657L12.263 29.4122L14.07 31.154L13.0607 32.8308V47.0912L14.949 54.4168H21.0699" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M15.7464 2.04711L17.8301 0.777344H21.2975L23.4138 2.04711L21.7859 3.59362L23.8859 5.14013L21.9975 6.68663L24.2766 8.37965L22.4696 9.92616L24.7487 11.3262L22.7301 12.7424L24.7487 14.3541L23.0719 15.9006L25.2859 17.3168L23.544 18.782L25.8231 20.3285L23.6743 21.875L25.8231 23.4866L24.2766 24.8378L26.3603 26.3843L24.5533 27.8657L26.6371 29.4122L24.8138 31.154L25.8231 32.8308V47.0912L23.951 54.4168H20.044" fill="#8889BE"/>
      <path d="M15.7464 2.04711L17.8301 0.777344H21.2975L23.4138 2.04711L21.7859 3.59362L23.8859 5.14013L21.9975 6.68663L24.2766 8.37965L22.4696 9.92616L24.7487 11.3262L22.7301 12.7424L24.7487 14.3541L23.0719 15.9006L25.2859 17.3168L23.544 18.782L25.8231 20.3285L23.6743 21.875L25.8231 23.4866L24.2766 24.8378L26.3603 26.3843L24.5533 27.8657L26.6371 29.4122L24.8138 31.154L25.8231 32.8308V47.0912L23.951 54.4168H20.044" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M1.16931 56.511C1.16931 56.511 0.0251236 67.9252 12.1976 69.8582C24.3702 71.7911 17.3672 67.6854 26.245 67.9252C35.1228 68.1651 35.3433 65.6678 37.935 63.114C38.6881 62.3641 39.2692 61.4524 39.6364 60.4447C40.0035 59.437 40.1475 58.3585 40.058 57.287C40.058 57.287 40.6645 47.1848 37.163 44.1514C35.1917 42.4442 35.5981 36.4055 33.5981 36.8773L27.527 39.1991C25.5839 39.057 26.0092 50.3199 26.0092 50.3199C22.1039 52.0614 16.9605 52.0617 13.0552 50.3199C13.0552 50.3199 12.4347 36.6338 10.5981 37.3511L4.98787 39.1991C4.14696 42.0209 3.19577 43.8269 2.56164 44.2501C0.976315 45.3365 1.10038 48.7509 1.10038 48.7509L1.16931 56.511Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M21.8093 82.771H0.809326V87.771H21.8093V82.771Z" fill="#838AA2"/>
      <path d="M22.4788 7.95013L24.6336 21.6055L29.455 10.3535C29.6031 3.06151 29.0106 3.06151 29.0106 3.06151C27.0039 -0.925849 25.6706 0.876656 24.3373 2.01005C23.004 3.14344 22.4788 7.95013 22.4788 7.95013Z" fill="#C7CAD5"/>
      <path d="M3.52237 70.9975C3.52237 70.9975 2.37859 82.1833 14.5468 84.0914C26.715 85.9995 19.7558 81.9621 28.6305 82.2248C37.5052 82.4875 37.7257 80.0264 40.3164 77.5099C41.0693 76.775 41.6502 75.8815 42.0172 74.894C42.3842 73.9065 42.5281 72.8496 42.4386 71.7995C42.4386 71.7995 43.045 61.9134 39.5447 58.9407C37.5741 57.2676 37.0091 53.9216 36.8851 51.3221C36.8289 50.1003 36.8566 48.8761 36.9677 47.6581C37.3692 43.7322 37.2952 39.7719 36.7473 35.8639C36.4716 33.9558 35.1211 25.3141 34.694 20.2535C34.5571 18.8285 34.6785 17.3904 35.0522 16.0087C35.3794 14.8114 35.9009 13.6762 36.5957 12.6489C37.0955 11.7104 37.3996 10.6797 37.4897 9.61955C37.5797 8.55937 37.4537 7.4919 37.1193 6.48216C34.0876 4.26988 29.6916 10.2845 29.6916 10.2845L24.7582 21.7053L22.5533 7.87865C22.5533 7.87865 22.0985 3.31584 19.5216 3.62003C18.7644 3.74946 18.066 4.11166 17.5229 4.65658C16.9799 5.2015 16.6189 5.90222 16.4899 6.6619C16.6414 13.437 15.1118 14.4187 15.1118 14.4187C15.1118 14.4187 7.53251 21.4288 8.89679 34.8131C9.62949 41.2493 9.11 47.767 7.36715 54.0045C6.52653 56.7699 5.57568 58.5259 4.94177 58.9407C3.35701 60.0053 3.48103 63.3652 3.48103 63.3652L3.52237 70.9975Z" fill="#DEE1E9"/>
      <path d="M3.88052 70.5642C3.88052 70.5642 2.73634 81.9785 14.9089 83.9114C27.0814 85.8444 20.0784 81.7386 28.9562 81.9785C37.834 82.2183 38.0546 79.721 40.6462 77.1672C41.3994 76.4173 41.9804 75.5056 42.3476 74.4979C42.7147 73.4902 42.8587 72.4117 42.7692 71.3402C42.7692 71.3402 43.3757 61.2381 39.8742 58.2046C37.9029 56.4974 38.3093 50.4587 36.3093 50.9305L30.2382 53.2523C28.2951 53.1102 29.0854 64.3731 29.0854 64.3731C25.1802 66.1146 19.267 66.1149 15.3618 64.3731C15.3618 64.3731 15.1459 50.687 13.3093 51.4043L7.69908 53.2523C6.85817 56.0741 5.90698 57.8801 5.27285 58.3034C3.68753 59.3898 3.8116 62.8042 3.8116 62.8042L3.88052 70.5642Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M19.9357 7.39153L22.0905 21.0469L26.9119 9.79487C27.06 2.50291 26.4675 2.50292 26.4675 2.50292C24.4608 -1.48444 23.1275 0.318062 21.7942 1.45146C20.4609 2.58485 19.9357 7.39153 19.9357 7.39153Z" fill="#DEE1E9"/>
      <path d="M0.979282 70.4389C0.979282 70.4389 -0.164501 81.6247 12.0037 83.5328C24.1719 85.4409 17.2128 81.4035 26.0874 81.6662C34.9621 81.9289 35.1826 79.4678 37.7733 76.9513C38.5262 76.2164 39.1071 75.3229 39.4741 74.3354C39.8411 73.3479 39.985 72.291 39.8955 71.2409C39.8955 71.2409 40.5019 61.3548 37.0016 58.3821C35.031 56.709 34.466 53.363 34.342 50.7635C34.2858 49.5417 34.3135 48.3175 34.4246 47.0995C34.8261 43.1736 34.7521 39.2133 34.2042 35.3053C33.9285 33.3972 32.5781 24.7555 32.1509 19.695C32.014 18.2699 32.1354 16.8318 32.5092 15.4502C32.8363 14.2528 33.3578 13.1176 34.0526 12.0903C34.5524 11.1518 34.8565 10.1211 34.9466 9.06096C35.0366 8.00078 34.9106 6.93331 34.5762 5.92356C31.5445 3.71129 27.1485 9.7259 27.1485 9.7259L22.2151 21.1468L20.0102 7.32006C20.0102 7.32006 19.5555 2.75725 16.9785 3.06144C16.2213 3.19086 15.523 3.55307 14.9799 4.09798C14.4368 4.6429 14.0758 5.34362 13.9468 6.10331C14.0984 12.8784 12.5687 13.8601 12.5687 13.8601C12.5687 13.8601 4.98942 20.8702 6.35369 34.2545C7.0864 40.6907 6.56691 47.2084 4.82405 53.4459C3.98344 56.2113 3.03259 57.9673 2.39868 58.3821C0.813919 59.4467 0.937943 62.8066 0.937943 62.8066L0.979282 70.4389Z" fill="#DEE1E9"/>
      <path d="M1.00687 70.1807C1.00687 70.1807 -0.137321 81.595 12.0352 83.5279C24.2077 85.4609 17.2047 81.3551 26.0825 81.595C34.9603 81.8348 35.1809 79.3375 37.7726 76.7838C38.5257 76.0338 39.1068 75.1221 39.4739 74.1144C39.841 73.1068 39.985 72.0282 39.8955 70.9567C39.8955 70.9567 40.5021 60.8546 37.0006 57.8211C35.0293 56.1139 35.4357 50.0752 33.4357 50.547L27.3646 52.8688C25.4214 52.7267 23.4937 52.4152 21.6023 51.9376C18.1559 51.0911 16.8739 50.2304 14.3098 50.0752C12.3466 49.972 10.3841 50.2892 8.54749 51.0064L4.82542 52.8688C3.98451 55.6906 3.03332 57.4966 2.39919 57.9199C0.813869 59.0063 0.937939 62.4207 0.937939 62.4207L1.00687 70.1807Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M7.69545 61.1438C5.53065 57.5858 11.7007 53.172 12.4325 44.7806C12.8898 39.259 12.0208 38.9265 12.1733 30.3673C12.1012 27.0788 12.4974 25.1595 12.8935 21.6825C13.2143 18.8662 13.3879 17.1955 15.1259 12.6809C16.6504 8.58593 16.0548 4.3046 16.7865 4.3046C17.5183 4.3046 18.0529 8.0556 18.1292 10.3716C18.2359 13.2583 16.9248 14.1142 16.6047 17.84C16.4675 19.5182 17.1169 22.107 17.742 22.2749C18.6414 22.5098 20.4099 17.6764 20.791 16.7197C24.3583 7.06964 22.7923 1.24833 23.9357 1.04694C25.079 0.845543 26.652 5.79675 25.1733 13.0469C24.594 15.8832 24.1488 17.1384 22.8987 20.7635C20.6424 27.2417 21.9039 33.5193 22.3307 33.6368C22.7576 33.7543 25.8649 19.0525 29.3865 11.5003C30.4385 9.2514 32.8258 6.37909 33.4356 6.54692C34.0454 6.71475 30.2744 10.3252 30.1829 16.5181C30.1829 20.9152 28.7221 30.8674 28.9355 37.5469C29.088 42.5818 29.1731 41.793 29.3866 45.5859C29.6 49.6641 31.1576 52.5007 31.1729 57.7033C31.1729 61.1438 30.9747 62.3522 30.1667 63.2584C28.7184 64.9367 26.2487 64.5675 24.6327 64.3661C19.6324 63.7451 18.8091 60.741 15.5619 61.3116C13.2294 61.7312 12.33 63.527 10.2871 62.9899C9.24926 62.7387 8.32884 62.0831 7.69545 61.1438Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M19.9983 7.42376L22.1531 21.0791L26.9745 9.82709C27.1227 2.53514 26.5301 2.53514 26.5301 2.53514C24.5234 -1.45222 23.1901 0.350288 21.8568 1.48368C20.5235 2.61708 19.9983 7.42376 19.9983 7.42376Z" fill="#DEE1E9"/>
      <path d="M1.0419 70.4712C1.0419 70.4712 -0.101879 81.657 12.0663 83.565C24.2346 85.4731 17.2754 81.4357 26.15 81.6984C35.0247 81.9612 35.2452 79.5 37.8359 76.9835C38.5888 76.2486 39.1697 75.3551 39.5367 74.3676C39.9037 73.3801 40.0476 72.3232 39.9581 71.2731C39.9581 71.2731 40.5645 61.387 37.0642 58.4143C35.0936 56.7413 34.5286 53.3952 34.4046 50.7958C34.3485 49.574 34.3761 48.3497 34.4873 47.1317C34.8887 43.2058 34.8147 39.2456 34.2668 35.3375C33.9912 33.4294 32.6407 24.7878 32.2135 19.7272C32.0767 18.3021 32.1981 16.864 32.5718 15.4824C32.899 14.285 33.4204 13.1499 34.1152 12.1225C34.615 11.1841 34.9192 10.1534 35.0092 9.09318C35.0992 8.03301 34.9732 6.96554 34.6389 5.95579C31.6071 3.74352 27.2111 9.75813 27.2111 9.75813L22.2777 21.179L20.0728 7.35228C20.0728 7.35228 19.6181 2.78947 17.0411 3.09366C16.284 3.22309 15.5856 3.58529 15.0425 4.13021C14.4994 4.67513 14.1384 5.37585 14.0094 6.13553C14.161 12.9106 12.6313 13.8923 12.6313 13.8923C12.6313 13.8923 5.05204 20.9024 6.41632 34.2867C7.14902 40.7229 6.62953 47.2406 4.88668 53.4781C4.04606 56.2435 3.09521 57.9995 2.4613 58.4143C0.876541 59.4789 1.00056 62.8388 1.00056 62.8388L1.0419 70.4712Z" fill="#DEE1E9"/>
      <path d="M1.06949 70.2129C1.06949 70.2129 -0.0746994 81.6272 12.0978 83.5602C24.2703 85.4931 17.2673 81.3873 26.1452 81.6272C35.023 81.8671 35.2435 79.3697 37.8352 76.816C38.5883 76.066 39.1694 75.1543 39.5365 74.1467C39.9037 73.139 40.0477 72.0605 39.9581 70.9889C39.9581 70.9889 40.5647 60.8868 37.0632 57.8533C35.0919 56.1461 35.4983 50.1074 33.4983 50.5792L27.4272 52.901C25.484 52.759 23.5563 52.4474 21.6649 51.9698C18.2185 51.1233 16.9365 50.2626 14.3724 50.1074C12.4093 50.0042 10.4467 50.3214 8.61011 51.0386L4.88805 52.901C4.04713 55.7229 3.09594 57.5288 2.46181 57.9521C0.876492 59.0385 1.00056 62.4529 1.00056 62.4529L1.06949 70.2129Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.4" d="M7.75807 61.176C5.59327 57.6181 11.7633 53.2042 12.4951 44.8128C12.9524 39.2913 12.0835 38.9587 12.2359 30.3995C12.1638 27.111 12.56 25.1917 12.9561 21.7147C13.2769 18.8984 13.4506 17.2277 15.1885 12.7132C16.713 8.61816 16.1174 4.33683 16.8492 4.33683C17.5809 4.33683 18.1156 8.08782 18.1918 10.4038C18.2985 13.2905 16.9874 14.1464 16.6673 17.8722C16.5301 19.5505 17.1795 22.1393 17.8046 22.3071C18.704 22.542 20.4725 17.7086 20.8536 16.752C24.4209 7.10186 22.8549 1.28056 23.9983 1.07916C25.1417 0.877769 26.7147 5.82898 25.2359 13.0791C24.6566 15.9154 24.2114 17.1707 22.9613 20.7958C20.705 27.2739 21.9665 33.5515 22.3934 33.669C22.8202 33.7865 25.9276 19.0848 29.4492 11.5325C30.5011 9.28362 32.8885 6.41132 33.4983 6.57915C34.1081 6.74697 30.337 10.3575 30.2456 16.5503C30.2456 20.9474 28.7847 30.8996 28.9981 37.5791C29.1506 42.614 29.2358 41.8252 29.4492 45.6181C29.6626 49.6963 31.2202 52.5329 31.2355 57.7356C31.2355 61.176 31.0373 62.3844 30.2293 63.2907C28.781 64.9689 26.3113 64.5997 24.6954 64.3983C19.695 63.7773 18.8717 60.7732 15.6245 61.3439C13.292 61.7634 12.3926 63.5592 10.3497 63.0221C9.31188 62.771 8.39146 62.1153 7.75807 61.176Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M19.5017 9.10149L21.6565 22.7568L26.4779 11.5048C26.6261 4.21287 26.0335 4.21288 26.0335 4.21288C24.0268 0.225518 22.6935 2.02802 21.3602 3.16142C20.0269 4.29481 19.5017 9.10149 19.5017 9.10149Z" fill="#DEE1E9"/>
      <path d="M0.545322 72.1489C0.545322 72.1489 -0.598461 83.3347 11.5698 85.2428C23.738 87.1509 16.7788 83.1135 25.6535 83.3762C34.5281 83.6389 34.7486 81.1777 37.3394 78.6613C38.0922 77.9263 38.6731 77.0329 39.0401 76.0454C39.4071 75.0579 39.5511 74.0009 39.4616 72.9509C39.4616 72.9509 40.0679 63.0648 36.5677 60.092C34.597 58.419 34.032 55.0729 33.908 52.4735C33.8519 51.2517 33.8795 50.0275 33.9907 48.8094C34.3922 44.8835 34.3181 40.9233 33.7702 37.0153C33.4946 35.1072 32.1441 26.4655 31.7169 21.4049C31.5801 19.9799 31.7015 18.5418 32.0752 17.1601C32.4024 15.9627 32.9238 14.8276 33.6186 13.8002C34.1184 12.8618 34.4226 11.8311 34.5126 10.7709C34.6026 9.71074 34.4766 8.64327 34.1423 7.63352C31.1106 5.42125 26.7146 11.4359 26.7146 11.4359L21.7811 22.8567L19.5762 9.03002C19.5762 9.03002 19.1215 4.46721 16.5445 4.7714C15.7874 4.90082 15.089 5.26303 14.5459 5.80795C14.0028 6.35286 13.6418 7.05358 13.5128 7.81327C13.6644 14.5883 12.1348 15.57 12.1348 15.57C12.1348 15.57 4.55546 22.5802 5.91973 35.9644C6.65244 42.4006 6.13295 48.9183 4.39009 55.1559C3.54948 57.9212 2.59863 59.6772 1.96472 60.092C0.379959 61.1567 0.503983 64.5166 0.503983 64.5166L0.545322 72.1489Z" fill="#DEE1E9"/>
      <path d="M0.572906 71.8902C0.572906 71.8902 -0.571281 83.3045 11.6012 85.2374C23.7738 87.1704 16.7708 83.0646 25.6486 83.3044C34.5264 83.5443 34.7469 81.047 37.3386 78.4932C38.0917 77.7433 38.6728 76.8316 39.04 75.8239C39.4071 74.8162 39.5511 73.7377 39.4615 72.6662C39.4615 72.6662 40.0681 62.564 36.5666 59.5306C34.5953 57.8234 35.0017 51.7847 33.0017 52.2565L26.9306 54.5783C24.9875 54.4362 23.0597 54.1247 21.1683 53.6471C17.722 52.8005 16.4399 51.9399 13.8758 51.7847C11.9127 51.6815 9.9501 51.9986 8.11353 52.7159L4.39146 54.5783C3.55055 57.4001 2.59936 59.2061 1.96523 59.6293C0.37991 60.7157 0.503979 64.1301 0.503979 64.1301L0.572906 71.8902Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask528" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="0" width="27" height="28">
        <path d="M31.3827 6.34887L27.6742 7.28878L24.5529 0.850098L14.96 4.65554L8.9123 27.6238H30.2878L31.3827 17.4819L34.5108 7.28878L31.3827 6.34887Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask528)">
        <path opacity="0.4" d="M7.32735 62.468C5.16255 58.9101 11.3326 54.4962 12.0644 46.1048C12.5217 40.5832 11.6527 40.2507 11.8052 31.6915C11.7331 28.403 12.1293 26.4837 12.5254 23.0067C12.8462 20.1904 13.0198 18.5197 14.7578 14.0052C16.2823 9.91015 15.6867 5.62882 16.4184 5.62882C17.1502 5.62882 17.6848 9.37982 17.7611 11.6958C17.8678 14.5825 16.5567 15.4384 16.2366 19.1642C16.0994 20.8425 16.7488 23.4313 17.3739 23.5991C18.2733 23.834 20.0418 19.0006 20.4229 18.044C23.9902 8.39385 22.4242 2.57255 23.5676 2.37115C24.7109 2.16976 26.2839 7.12097 24.8052 14.3711C24.2259 17.2074 23.7807 18.4627 22.5306 22.0877C20.2743 28.5659 21.5358 34.8435 21.9626 34.961C22.3895 35.0785 25.4968 20.3768 29.0184 12.8245C30.0704 10.5756 32.4577 7.70331 33.0675 7.87114C33.6773 8.03897 29.9063 11.6495 29.8148 17.8423C29.8148 22.2394 28.354 32.1916 28.5674 38.8711C28.7198 43.906 28.805 43.1172 29.0185 46.9101C29.2319 50.9883 30.7895 53.8249 30.8048 59.0276C30.8048 62.468 30.6066 63.6764 29.7986 64.5827C28.3503 66.2609 25.8806 65.8917 24.2646 65.6903C19.2643 65.0693 18.441 62.0652 15.1938 62.6358C12.8613 63.0554 11.9618 64.8512 9.91901 64.3141C8.88116 64.063 7.96074 63.4073 7.32735 62.468Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5028" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="27" width="27" height="42">
        <path d="M31.6248 27.6582H8.96409L9.20918 36.0383L5.76529 64.7854L31.6248 68.0882V27.6582Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5028)">
        <path opacity="0.2" d="M7.32735 62.4731C5.16255 58.9182 11.3326 54.5082 12.0644 46.124C12.5217 40.6073 11.6527 40.275 11.8052 31.7232C11.7331 28.4376 12.1293 26.5199 12.5254 23.046C12.8462 20.2321 13.0198 18.5629 14.7578 14.0522C16.2823 9.96075 15.6867 5.68312 16.4184 5.68312C17.1502 5.68312 17.6848 9.43087 17.7611 11.7449C17.8678 14.629 16.5567 15.4842 16.2366 19.2068C16.0994 20.8836 16.7488 23.4701 17.3739 23.6378C18.2733 23.8726 20.0418 19.0433 20.4229 18.0875C23.9902 8.44576 22.4242 2.6295 23.5676 2.42828C24.7109 2.22706 26.2839 7.17398 24.8052 14.4179C24.2259 17.2517 23.7807 18.5059 22.5306 22.1278C20.2743 28.6003 21.5358 34.8725 21.9626 34.9899C22.3895 35.1073 25.4968 20.4183 29.0184 12.8726C30.0704 10.6256 32.4577 7.75582 33.0675 7.9235C33.6773 8.09118 29.9063 11.6985 29.8148 17.886C29.8148 22.2793 28.354 32.2229 28.5674 38.8967C28.7198 43.9271 28.805 43.139 29.0185 46.9287C29.2319 51.0033 30.7895 53.8375 30.8048 59.0356C30.8048 62.4731 30.6066 63.6804 29.7986 64.5859C28.3503 66.2627 25.8806 65.8938 24.2646 65.6926C19.2643 65.0722 18.441 62.0707 15.1938 62.6408C12.8613 63.06 11.9618 64.8542 9.91901 64.3176C8.88116 64.0667 7.96074 63.4116 7.32735 62.4731Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M0.0436134 67.934C0.0436134 67.934 -1.10017 79.1198 11.068 81.0279C23.2363 82.936 16.2771 78.8986 25.1518 79.1613C34.0264 79.424 34.2469 76.9628 36.8377 74.4464C37.5905 73.7114 38.1714 72.818 38.5384 71.8305C38.9054 70.843 39.0493 69.786 38.9599 68.7359C38.9599 68.7359 39.5662 58.8499 36.0659 55.8771C34.0953 54.2041 33.5303 50.858 33.4063 48.2586C33.3502 47.0368 33.3778 45.8125 33.489 44.5945C33.8904 40.6686 33.8164 36.7084 33.2685 32.8004C32.9929 30.8923 31.6424 22.2506 31.2152 17.19C31.0784 15.765 31.1998 14.3269 31.5735 12.9452C31.9007 11.7478 32.4221 10.6127 33.1169 9.58532C33.6167 8.64689 33.9209 7.61619 34.0109 6.55601C34.1009 5.49583 33.9749 4.42836 33.6406 3.41862C30.6088 1.20635 26.2129 7.22096 26.2129 7.22096L21.2794 18.6418L19.0745 4.81511C19.0745 4.81511 18.6198 0.252304 16.0428 0.556492C15.2857 0.68592 14.5873 1.04812 14.0442 1.59304C13.5011 2.13796 13.1401 2.83868 13.0111 3.59836C13.1627 10.3734 11.633 11.3551 11.633 11.3551C11.633 11.3551 4.05375 18.3653 5.41803 31.7495C6.15073 38.1857 5.63124 44.7034 3.88839 50.941C3.04777 53.7063 2.09692 55.4623 1.46301 55.8771C-0.12175 56.9418 0.00227356 60.3017 0.00227356 60.3017L0.0436134 67.934Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M20 7.91839L22.1548 21.5737L26.9762 10.3217C27.1244 3.02977 26.5318 3.02977 26.5318 3.02977C24.5251 -0.957587 23.1918 0.844917 21.8585 1.97831C20.5252 3.1117 20 7.91839 20 7.91839Z" fill="#C7CAD5"/>
      <path d="M1.04361 70.9658C1.04361 70.9658 -0.10017 82.1516 12.068 84.0597C24.2363 85.9678 17.2771 81.9304 26.1518 82.1931C35.0264 82.4558 35.2469 79.9946 37.8377 77.4782C38.5905 76.7432 39.1714 75.8498 39.5384 74.8623C39.9054 73.8748 40.0493 72.8178 39.9599 71.7677C39.9599 71.7677 40.5662 61.8817 37.0659 58.9089C35.0953 57.2359 34.5303 53.8898 34.4063 51.2904C34.3502 50.0686 34.3778 48.8443 34.489 47.6263C34.8904 43.7004 34.8164 39.7402 34.2685 35.8322C33.9929 33.9241 32.6424 25.2824 32.2152 20.2218C32.0784 18.7968 32.1998 17.3587 32.5735 15.977C32.9007 14.7796 33.4221 13.6445 34.1169 12.6171C34.6167 11.6787 34.9209 10.648 35.0109 9.58781C35.1009 8.52763 34.9749 7.46016 34.6406 6.45042C31.6088 4.23815 27.2129 10.2528 27.2129 10.2528L22.2794 21.6736L20.0745 7.84691C20.0745 7.84691 19.6198 3.2841 17.0428 3.58829C16.2857 3.71772 15.5873 4.07992 15.0442 4.62484C14.5011 5.16976 14.1401 5.87048 14.0111 6.63016C14.1627 13.4052 12.633 14.3869 12.633 14.3869C12.633 14.3869 5.05375 21.3971 6.41803 34.7813C7.15073 41.2175 6.63124 47.7352 4.88839 53.9728C4.04777 56.7381 3.09692 58.4941 2.46301 58.9089C0.87825 59.9736 1.00227 63.3335 1.00227 63.3335L1.04361 70.9658Z" fill="#DEE1E9"/>
      <path d="M1.0712 70.7074C1.0712 70.7074 -0.0729904 82.1217 12.0995 84.0547C24.272 85.9876 17.2691 81.8818 26.1469 82.1217C35.0247 82.3616 35.2452 79.8643 37.8369 77.3105C38.59 76.5605 39.1711 75.6488 39.5382 74.6412C39.9054 73.6335 40.0494 72.555 39.9598 71.4834C39.9598 71.4834 40.5664 61.3813 37.0649 58.3478C35.0936 56.6406 35.5 50.6019 33.5 51.0737L27.4289 53.3955C25.4858 53.2535 23.558 52.9419 21.6666 52.4643C18.2203 51.6178 16.9382 50.7571 14.3741 50.6019C12.411 50.4987 10.4484 50.8159 8.61182 51.5331L4.88976 53.3955C4.04884 56.2174 3.09765 58.0233 2.46352 58.4466C0.878201 59.533 1.00227 62.9474 1.00227 62.9474L1.0712 70.7074Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth65",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
