<template>
  <div v-if="object.id">
    <AutosizeTextArea v-model="comment"
                      :labelclass="blackLabel ? 'black' : ''"
                      :label="$t('leave_comment')"/>
    <div class="text-right">
      <button class="btn-themed btn-themed-squared"
              @click="saveComment"
              :disabled="loading || !comment.length">
        {{ $t('send') }}
      </button>
    </div>
    <slot/>
    <div class="mt-md-2 mt-3">
      <div v-for="c in comments"
           :key="`comment-${c.id}`"
           class="comment">
        <div>
          <span class="comment-author text-primary mr-2" v-if="c.author">
            {{ c.author ? c.author.full_name : '' }}
          </span>
          <span class="comment-date text-muted">
            {{ formatDateTime(c.created_at) }}
          </span>
        </div>
        <div class="comment-block"
             v-dompurify-html="processTextForLinks(c.text)"></div>
      </div>
      <div v-if="hasMore">
        <b-button class="btn btn-themed btn-themed-squared btn-themed-outline"
                  :disabled="loadingMore"
                  variant="themed"
                  @click="loadMore">
          {{ $t('load_more') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import { EloquentService } from "@/services/api.service"
import { mapState } from "vuex"
import { formatDateTime } from "@/extensions/filters/filters"
import { processTextForLinks } from "@/services/text.service"

export default {
  name: "GeneralCommentsBlock",
  components: {
    AutosizeTextArea
  },
  props: {
    model: String,
    parentColumn: String,
    object: {},
    blackLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      comment: '',
      comments: [],
      page: 1,
      hasMore: false,
      loadingMore: false
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading
    }),
  },
  methods: {
    processTextForLinks,
    formatDateTime,
    async saveComment() {
      if(!this.comment) return
      if(!this.object.id) return
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        [this.parentColumn]: this.object.id,
        text: this.comment
      })
      const commentRes = await EloquentService.create(this.model, formData)
      this.comment = ''
      const newComment = await EloquentService.show(this.model, commentRes.data.id)
      // this.comments.push(newComment.data)
      this.comments.unshift(newComment.data)
    },
    async getComments() {
      if(!this.object.id) return
      const res = await EloquentService.dataTable(this.model, this.page, {
        sort: 'desc',
        order: 'id',
        condition: {
          [this.parentColumn]: this.object.id
        },
      })
      const data = res.data.result
      this.comments = this.comments.concat(data.data)
      this.hasMore = data.last_page > data.current_page
    },
    async loadMore() {
      if(this.loadingMore) return
      this.loadingMore = true
      this.page++
      await this.getComments()
      this.loadingMore = false
    }
  },
  mounted() {
    this.getComments()
  }

}
</script>

<style scoped lang="scss">
.comment-block {
  background: #F8FAFC;
  padding: 9px 13px;
  white-space: pre-wrap;
}
.comment {
  //font-size:  14px;
  margin-bottom: 15px;
  &-author {
    font-size: 15px;
  }
  &-date {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .comment {
    &-author {
      font-size: 13px;
    }
    &-date {
      font-size: 12px;
    }
  }
}
</style>
