<template>
  <div>
    <div>
      <table class="table-blue" v-if="payment">
        <tbody>
        <tr>
          <td>{{ $t('payment_id') }} ({{ payment.pay_system }})</td>
          <td>{{ payment.pay_id }}</td>
        </tr>
        <tr>
          <td>{{ $t('sum') }}</td>
          <td>{{ formatNumber(payment.sum) }} {{ payment.currency }}</td>
        </tr>
        <tr>
          <td>{{ $t('status') }}</td>
          <td>{{ payment.paid == 1 ? $t('paid') : $t('unpaid') }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="alert mt-3"
         v-if="status && (status.error_message || status.error_code)"
         :class="{'alert-warning': status.error_code, 'alert-info': !status.error_code}">
      <span v-if="status.error_code">{{ $t('error_code') }}: {{ status.error_code }}, </span>
      <span v-if="status.error_message">{{ status.error_message }}</span>
    </div>
    <div class="alert mt-3"
         v-if="status && (status.error)"
         :class="{'alert-warning': status.error.code, 'alert-info': !status.error.code}">
      <span v-if="status.error.code">{{ $t('error_code') }}: {{ status.error.code }}, </span>
      <span v-if="status.error.message">{{ status.error.message }}</span>
    </div>
  </div>
</template>

<script>
import { formatNumber } from "@/extensions/filters/filters"

export default {
  name: "PaymentStatusTable",
  props: {
    payment: Object,
    status: Object
  },
  methods: {
    formatNumber
  }
}
</script>
