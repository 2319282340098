<template>
  <div>
    <DataTable :options="options"
               :params="{ user_id: this.doctor.id }"
               module="staff"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/staff/feedback"
import { mapState } from "vuex"

export default {
  name: "DoctorFeedbacks",
  head() {
    return {
      title: this.$t("feedbacks"),
    }
  },
  components: {
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  computed: {
    ...mapState({
      doctor:  state => state.doctor.doctor,
    })
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
