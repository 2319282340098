const state = {
    marketingGroup: null,
    marketingMailing: null,
    marketingNotification: null,
}

const getters = {

}

const mutations = {
    setMarketingGroup(state, value) {
        state.marketingGroup = value
    },
    setMarketingMailing(state, value) {
        state.marketingMailing = value
    },
    setMarketingNotification(state, value) {
        state.marketingNotification = value
    },
}

const actions = {

}

export default {
    state,
    actions,
    mutations,
    getters
}
