<template>
  <router-link :to="{ name: 'MarketingGroup', params: {id: object.marketing_group.id }, query: {filters: object.marketing_group.filters} }"
               class="font-weight-bold">
    {{ object.marketing_group.title }}
  </router-link>
</template>

<script>
export default {
  name: "MarketingDatatableTDMailingGroupTitle",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">

</style>
