<template>
  <b-modal id="notification-info-modal"
           size="md"
           :title="$t('send_info')"
           centered>
    <div>
      <b-row class="row-notification">
        <b-col cols="3" class="mb-2">
          {{ $t('status') }}
        </b-col>
        <b-col cols="9" class="mb-2">
          <div class="d-inline-block">
            <MarketingDatatableTDNotificationStatus :object="notification"/>
          </div>
          <div class="d-inline-block ml-2 text-muted"  v-if="notification.error_string">
            {{ $t(notification.error_string) }}
          </div>
        </b-col>
        <b-col cols="3" class="mb-2">
          {{ $t('time') }}
        </b-col>
        <b-col cols="9" class="mb-2">
          <div class="text-muted">
            {{ formatDateTime(notification.sent_at ? notification.sent_at : notification.updated_at) }}
          </div>
        </b-col>
        <b-col v-if="notification.contacts" cols="3" class="mb-2">
          {{ $t('channel') }}
        </b-col>
        <b-col cols="9" class="mb-2" v-if="notification.contacts">
          <div v-for="(value, channel) in notification.contacts" :key="`not-c-${channel}`">
            {{ $t(channel) }}: {{ value ? value : '-' }}
          </div>
        </b-col>
        <b-col cols="3" class="mb-2">
          {{ $t('text') }}
        </b-col>
        <b-col cols="9" class="mb-2">
          {{ notification.text }}
        </b-col>
      </b-row>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons w-100">
        <b-row class="justify-content-between">
          <b-col class="text-left">
            <button class="btn-themed btn-themed-squared w-auto px-4"
                    @click="retry"
                    v-if="notification.status === 'error'">
              {{ $t('send_again') }}
            </button>
          </b-col>
          <b-col>
            <b-button class="ml-auto cancel d-inline-block align-middle"
                      variant="themed" @click="close()">
              {{ $t('ready') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import MarketingDatatableTDNotificationStatus
  from "@/components/parts/datatable/td/marketing/MarketingDatatableTDNotificationStatus"
import {MarketingService} from "@/services/api.service"
import {formatDateTime} from "@/extensions/filters/filters"

export default {
  name: "NotificationInfoModal",
  components: {
    MarketingDatatableTDNotificationStatus
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      notification: state => state.marketing.marketingNotification,
      loading: state => state.dom.loading
    }),
  },
  methods: {
    formatDateTime,
    async retry() {
      let res = await MarketingService.resendNotification(this.notification.id)
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
        this.$bvModal.hide('notification-info-modal')
      }
    },
  },
  mounted() {

  },
}
</script>

<style scoped lang="scss">
.row-notification {
  td {
    padding: 5px;
  }
}

@media screen and (max-width: 768px) {

}
</style>
