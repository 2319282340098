import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'
import store from '@/store'

import StaffGeneral from "@/components/pages/staff/StaffGeneral"
import Doctors from "@/components/pages/staff/Doctors"
import Assistants from "@/components/pages/staff/Assistants"
import Administrators from "@/components/pages/staff/Administrators"
import Curators from "@/components/pages/staff/Curators"
import Technics from "@/components/pages/staff/Technics"
import Chairs from "@/components/pages/staff/Chairs"
import DoctorGeneral from "@/components/pages/staff/DoctorGeneral"
import DoctorVisits from "@/components/pages/staff/DoctorVisits"
import DoctorSchedule from "@/components/pages/staff/DoctorSchedule"
import DoctorFeedbacks from "@/components/pages/staff/DoctorFeedbacks"
import StaffRoles from "@/components/pages/staff/StaffRoles"

export default {

    path: '/staff',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['staff'] },
    component: StaffGeneral,
    children: [
        {
            path: '',
            redirect: () => {
                if(store.state.auth.rights.includes('doctors_page')) return { path: '/staff/doctors' }
                return { path: '/staff/technics' }
            }
        },
        {
            path: 'doctors', name: "StaffDoctors",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['doctors_page', 'staff'] },
            component: Doctors
        },
        {
            path: 'assistants', name: "StaffAssistants",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['doctors_page', 'staff', 'assistants'] },
            component: Assistants
        },
        {
            path: 'administrators', name: "StaffAdministrators",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['create_admin','staff'] },
            component: Administrators
        },
        {
            path: 'curators', name: "StaffCurators",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['staff', 'curators'] },
            component: Curators
        },
        {
            path: 'technics', name: "StaffTechnics",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['staff'] },
            component: Technics
        },
        {
            path: 'chairs', name: "StaffChairs",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['create_chairs', 'staff'] },
            component: Chairs
        },
        {
            path: 'roles', name: "StaffRoles",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['staff_roles', 'staff'] },
            component: StaffRoles
        },
        {
            path: 'doctors/:id',
            meta: { middleware: [authorized, paid], layout: "default", rights: ['doctors_page'] },
            component: DoctorGeneral,
            children: [
                { path: '', redirect: '/doctors/:id/visits' },
                {
                    path: 'visits', name: "StaffDoctorVisits",
                    meta: { middleware: [authorized, paid], layout: "default" },
                    component: DoctorVisits
                },
                {
                    path: 'schedule', name: "StaffDoctorSchedule",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['work_time_edit'] },
                    component: DoctorSchedule
                },
                {
                    path: 'feedbacks', name: "StaffDoctorFeedbacks",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['view_feedbacks'] },
                    component: DoctorFeedbacks
                },

            ]
        },

    ]

}
