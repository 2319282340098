<template>
  <div class="clinic-ips-data page pb-5 pt-4" style="max-width: 450px;" v-if="form">
    <div class="alert alert-warning">
      {{ $t('ip_note') }}
    </div>

    <label>{{ $t('allowed_ips') }}:</label>
    <VueMultiselect v-model="form.whitelist_ips"
                    :multiple="true"
                    :options="[]"
                    :tagPlaceholder="$t('add')"
                    placeholder=""
                    selectLabel=""
                    selectedLabel=""
                    deselectLabel=""
                    @tag="addTag"
                    :taggable="true">
      <template #noOptions>{{ $t('enter_ip_address') }}</template>
    </VueMultiselect>
    <div class="text-muted mt-2" v-if="ip">{{ $t('your_current_ip') }}: <b>{{ ip }}</b></div>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared"
              @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import {computed, getCurrentInstance, nextTick, onBeforeMount, onMounted, ref} from "vue"
import {useStore} from "vuex"
import {useHead} from "@unhead/vue"
import {appendFormdata, copyObject} from "@/extensions/prototypes/prototypes"
import { EloquentService, UsersService } from "@/services/api.service"
import VueMultiselect from 'vue-multiselect'

const store = useStore()
let i18n = null
let noty = null

const title = ref('')
const form = ref(null)
const ip = ref(null)

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('access_restriction')
  noty = getCurrentInstance()?.appContext?.app?.noty
})

onMounted(() => {
  form.value = copyObject(additional.value)
  if(!form.value.whitelist_ips) {
    form.value.whitelist_ips = []
  }
  UsersService.getCurrentIp().then(data => {
    ip.value = data.data
  }).catch(e => {
    console.log(e)
  })
})

const clinic = computed(() => store.state.auth.clinic)
const additional = computed(() => clinic.value.clinic_additional)

const addTag = (newTag) => {
  form.value.whitelist_ips.push(newTag)
}

const save = async () => {
  await nextTick()
  let formData = new FormData()
  const data = copyObject(form.value)
  if(!data.whitelist_ips.length) data.whitelist_ips = ''
  formData = appendFormdata(formData, data)
  try {
    await EloquentService.update('clinic_additional', clinic.value.id, formData)
    noty.info(i18n.t('success_message'))
    await store.dispatch('init')
  } catch (e) {
    console.log(e)
  }
}
</script>
