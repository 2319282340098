<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportStatAdmins",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('admin_statistics_future'),
    }
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_date: true
        },
        {
          id: 'admin',
          field: 'admin',
          label: this.$t('manager'),
          search_select: true,
          sortable: true
        },
        {
          id: 'total',
          field: 'total',
          label: this.$t('total_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'primary',
          field: 'primary',
          label: this.$t('primary_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'permanent',
          field: 'permanent',
          label: this.$t('permanent_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'came',
          field: 'came',
          label: this.$t('came_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
        {
          id: 'did_not_come',
          field: 'did_not_come',
          label: this.$t('did_not_come_among_patients'),
          sortable: true,
          format_number: true,
          totals: true
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('admin_statistics_future', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped>

</style>
