export default {
    add_call_task: "Добавить задачу на обзвон",
    add_to_funnel: "Добавить в воронку",
    add_to_funnel_came_and_repeated: "Добавить в воронку 'Дошедшие и повторные'",
    all_days: "Все дни",
    amo_deal_id: "ID сделки Amo",
    api_key: "Ключ API",
    applications_and_appeals: "Заявки и обращения",
    are_you_sure_you_want_to_delete_task: "Вы уверены, что хотите удалить задачу?",
    attach_file: "Прикрепить файл",
    binotel: "Binotel",
    binotel_integration: "Интеграция с Binotel",
    binotel_integration_instructions: "Для интеграции Dentist Plus с Binotel необходимо: <br>1. создать аккаунт в Binotel<br>2. написать письмо в поддержку Binotel с просьбой настроить следующий webhook для <b>API CALL SETTINGS</b> и <b>API CALL COMPLETED</b>:<br><span class='text-primary'>{url}</span><br>3. в поле ниже указать пользователей из персонала клиники, которые будут использовать функционал binotel:",
    binotel_key: "Binotel ключ",
    binotel_secret: "Binotel секретный ключ",
    binotel_internal: "Binotel внутренний телефон",
    button_title: "Название кнопки",
    call_from_patient: "Звонок от пациента",
    call_from_phone: "Звонок с номера",
    call_noun: "Звонок",
    callee: "Тот, кому звонят",
    cancel_sending: "Отменить отправку",
    change_status: "Изменить статус",
    client_comment: "Комментарий клиента",
    copied: "Скопировано",
    copy_to_clipboard: "Скопировать в буфер",
    create_application_on_call: "Создавать автоматически карточку звонка в Заявки и обращения",
    create_card_automatically: "Создавать карточку автоматически",
    create_lead: "Создать обращение",
    create_lead_on_incoming_messages: "Создавать заявку на входящие сообщения в whatsapp с неизвестного номера",
    crm: "CRM",
    curator_assigned: "Назначен куратор",
    deadline: "Срок исполнения",
    deal: "Сделка",
    do_not_create_duplicates: "Не создавать дубликаты",
    do_not_create_duplicates_on_call: "Не создавать дубликаты по звонкам, если уже есть активная заявка до статуса \"Записан\"",
    do_not_show_anymore: "Больше не показывать",
    feedback: "Отзыв",
    feedbacks: "Отзывы",
    follow: "Перейти",
    for_came_from_lead_funnel: "Для дошедших из воронки 'Заявки и обращения'",
    for_came_on_first_visit: "Для дошедших на первый визит",
    for_came_to_consultation: "Для дошедших на консультацию",
    for_incoming: "Для входящих",
    for_missed: "Для пропущенных",
    for_outcoming: "Для исходящих",
    funnel_came_and_repeated: "Воронка: Дошедшие и повторные",
    generate_key: "Сгенерировать ключ",
    has_overdue_tasks: "Имеет просроченные задачи",
    has_tasks: "Имеет задачи",
    has_today_task: "Имеет задачи на сегодня",
    if_lead_has_no_responsible_then_assign_to_this_person_on_first_save: "Если у заявки нет ответственного, то назначить на этого человека при первом сохранении",
    inprogress: "В работе",
    insert_script_one_time_instruction: "Установите данный скрипт на сайт в секцию <head>",
    insert_widget_button_instruction: "Установите данный код кнопки в место, где она должна находиться",
    instruction: "Инструкция",
    integration_key: "Ключ интеграции",
    internal_phone: "Внутренний телефон",
    lead_channel: "Канал заявки",
    leave_comment: "Оставить комментарий",
    manually_selected_user: "Вручную выбранный пользователь",
    mark_as_completed: "Отметить как выполненное",
    mark_as_finished: "Отметить как выполненное",
    mark_as_in_progress: "Отметить как в работе",
    move_to_funnel_came_and_repeated: "Перенести в воронку 'Дошедшие и повторные'",
    my_tasks: "Мои задачи",
    no_rating: "Нет рейтинга",
    nobody: "Никто",
    open_whatsapp: "Открыть whatsapp",
    outgoing_calls_through: "Исходящие звонки через",
    overdue: "Просрочено",
    patient_has_no_future_visits_continue: "Не найдено визитов у пациента на будущие даты. Всё равно продолжить?",
    patient_not_derived: "Пациент не определен",
    patient_not_derived_from_phone: "Звонок с номера {phone} - пациент не определен",
    plan_treatments: "Планы лечений",
    planned: "Запланировано",
    publish: "Опубликовать",
    published: "Опубликовано",
    rating: "Рейтинг",
    recall: "Перезвонить",
    recall_on_lead: "Перезвонить по заявке '{title}'",
    record_myself: "Записаться",
    record_on_visit: "Записать на прием",
    recorded_on_visit: "Записан на прием",
    responsible: "Ответственный",
    responsible_on_call: "Ответственный при звонке",
    responsible_on_other_leads_not_created_manually: "Ответственный за остальные заявки не созданные вручную",
    sales_funnel: "Воронка продаж",
    search_by_name: "Поиск по имени",
    search_by_phone: "Поиск по телефону",
    see_later: "Посмотреть позже",
    sipuni: "Sipuni",
    sipuni_integration_instructions: "Для интеграции Dentist Plus с Sipuni необходимо: <br>1. создать аккаунт в Sipuni<br>2. в личном кабинете Sipuni в разделе API сгенерировать ключ интеграции, нажать 'подключить услугу' в пунктах 'Звонок на номер' и 'События на АТС', а также в пункте 'События на АТС' указать 'URL принимающего скрипта':<br><span class='text-primary'>{url}</span><br>3. в поле ниже указать пользователей из персонала клиники, которые будут использовать функционал sipuni, и заполнить остальные поля ниже:",
    soon: "Скоро",
    stage: "Этап",
    stages: "Этапы",
    start_period: "Срок начала",
    system_number: "Номер в системе",
    task: "Задача",
    task_date: "Дата задачи",
    task_type: "Тип задачи",
    tasks_and_calls: "Задачи и звонки",
    tilda_integration_instructions: "Чтобы получать вебхуки от форм на вашем Tilda сайте и заводить лиды в системе dentist-plus, необходимо следующее:<br/>1. В личном кабинете Tilda зайти в Настройки сайта → Формы → Webhook<br/>2. В поле Webhook URL ввести <span class='text-primary'>{url}</span><br>3. В поле API METHOD выбрать 'HEADER', в 'Field name' заполнить 'API-KEY', а в само поле 'Api key' скопировать ключ, который вы можете сгенерировать ниже.<br/>4. В дополнительных настройках активируйте 'Посылать Cookies'.<br/>5. Следующие поля будут из форм будут автоматически заполняться в системе dentist-plus: <b>name, phone, email, comment</b>.",
    treatment_plan_signed: "Подписан план лечения",
    treatment_refused: "Отказ от лечения",
    treatment_started: "Начал лечение",
    turn_on_chat_panel: "Включить панель чатов",
    uis: "UIS",
    uis_integration_instructions: "Для интеграции Dentist Plus с UIS необходимо: <br>1. создать аккаунт в UIS<br>2. в личном кабинете UIS открыть настройки нужного пользователя (например, Администратора) и в разделе Доступ к функциональности API активировать настройку 'Использовать ключ API'<br/>3. В пункте меню 'Уведомления' создать 2 уведомления: для ожидания ответа и окончания разговора. В обоих случаях активировать метод уведомлений HTTP и в поле URL указать ссылку:<br><span class='text-primary'>{url}</span><br>4. в поле ниже указать пользователей из персонала клиники, которые будут использовать функционал uis, и заполнить их виртуальные номера (для приема и инициализации звонков):",
    unpublish: "Снять с публикации",
    user_add_id: "Автор",
    view: "Просмотр",
    visit_confirm: "Подтверждение приёма",
    without_tasks: "Без задач",
    write_in_messenger: "Написать в мессенджер",
    you_also_can_use_link: "Вы также можете использовать ссылку на форму напрямую",
}
