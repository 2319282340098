<template>
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M19.5224 5.74086C19.0705 5.95947 18.8814 6.50324 19.1 6.9549C19.6973 8.1887 20 9.5186 20 10.9091C20 15.9217 15.9217 20 10.9091 20C5.89644 20 1.81818 15.9217 1.81818 10.9091C1.81818 5.89644 5.89644 1.81818 10.9091 1.81818C12.9863 1.81818 14.9376 2.49645 16.5527 3.77952C16.9445 4.09224 17.5173 4.02677 17.83 3.6337C18.1428 3.24086 18.0773 2.66868 17.684 2.3564C15.7719 0.836736 13.3658 0 10.9091 0C4.89413 0 0 4.89413 0 10.9091C0 16.924 4.89413 21.8182 10.9091 21.8182C16.924 21.8182 21.8182 16.924 21.8182 10.9091C21.8182 9.24228 21.4542 7.64493 20.7364 6.16322C20.5182 5.71045 19.9727 5.52179 19.5224 5.74086Z"
      fill="#DD1515"/>
  <path
      d="M10.9091 3.63636C10.4073 3.63636 10 4.04363 10 4.54545V10.9091C10 11.4109 10.4073 11.8182 10.9091 11.8182H15.4545C15.9564 11.8182 16.3636 11.4109 16.3636 10.9091C16.3636 10.4073 15.9564 10 15.4545 10H11.8182V4.54545C11.8182 4.04363 11.4109 3.63636 10.9091 3.63636Z"
      fill="#DD1515"/>
  <path
      d="M21.3171 16.0158L20.9635 16.3694C21.0119 16.4178 21.0121 16.4978 20.9634 16.5466L19.2177 18.2923L18.8641 18.6459L19.2177 18.9994L20.9635 20.745C20.9635 20.745 20.9635 20.745 20.9635 20.745C21.0119 20.7934 21.0121 20.8734 20.9634 20.9222L21.3171 21.2756L20.9637 20.9219C20.915 20.9706 20.8349 20.9704 20.7863 20.922C20.7863 20.922 20.7863 20.9219 20.7863 20.9219L19.0411 19.1761L18.6916 18.8265L18.3381 19.1719L16.5508 20.9182L16.5507 20.9182L16.5466 20.9223C16.4982 20.9707 16.4182 20.9709 16.3694 20.9221C16.3212 20.8737 16.321 20.7938 16.3697 20.7451L18.1154 18.9994L18.469 18.6458L18.1154 18.2923L16.3697 16.5466C16.321 16.4978 16.3212 16.4178 16.3696 16.3694L16.016 16.0158L16.3694 16.3695C16.4182 16.3208 16.4982 16.321 16.5466 16.3694L16.5466 16.3694L16.5508 16.3735L18.3381 18.1197L18.6916 18.4652L19.0411 18.1156L20.7864 16.3694C20.7865 16.3694 20.7865 16.3694 20.7865 16.3693C20.7865 16.3693 20.7865 16.3693 20.7865 16.3693C20.835 16.321 20.9149 16.3208 20.9637 16.3695L21.3171 16.0158ZM21.3171 16.0158C21.0727 15.7717 20.6762 15.7725 20.4329 16.0158L21.3171 16.9C21.5608 16.656 21.5608 16.2596 21.3171 16.0158ZM18.6875 12.8333C15.4871 12.8333 12.8333 15.4353 12.8333 18.6458C12.8333 21.8514 15.482 24.5 18.6875 24.5C21.8981 24.5 24.5 21.8463 24.5 18.6458C24.5 15.4403 21.893 12.8333 18.6875 12.8333Z"
      fill="#DD1515" stroke="white"/>
</svg>
</template>
