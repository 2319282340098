<template>
  <DataTable :options="options"
             :params="{ client_id: patient.id }"
             module="patients"/>
</template>

<script>

import DataTable from "../../parts/general/DataTable"
import options from "../../../data/patients/client_note"
import {mapState} from "vuex"

export default {
  name: "PatientNotes",
  head() {
    return {
      title: this.$t('notes'),
    }
  },
  components: { DataTable },
  data() {
    return {
      options: options
    }
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
    })
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setAdditionalParams', { client_id: this.patient.id })
  },
  beforeUnmount() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
