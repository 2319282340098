<template>
  <div class="patient-search-bar">
    <Autocomplete :placeholder="$t('patient_search')"
                  model="patient"
                  @input="clicked"
                  class="autocomplete-search-bar"/>
  </div>
</template>

<script>
import Autocomplete from "../general/Autocomplete"

export default {
  name: "SearchPatient",
  components: {
    Autocomplete,
  },
  methods: {
    clicked(patient) {
      this.$router.push({ name: 'PatientVisits', params: { id: patient.id }}).catch(()=>{})
    }
  }

}
</script>



<style scoped lang="scss">
/*.patient-search-bar {*/
/*    max-width: 214px;*/
/*    input {*/
/*        background: #E0E7EE;*/
/*        border-radius: 25px;*/
/*        height: 26px;*/
/*    }*/
/*    svg {*/
/*        position: absolute;*/
/*        right: 15px;*/
/*        top: 6px;*/
/*    }*/
/*}*/
</style>
