<template>
  <div v-if="options">
    <DataTable :options="options"
               :params="{ clinic_id: clinic.id }"
               module="superadmin"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import { superadmin_administrator_options } from "@/data/superadmin/administrator"
import { mapState } from "vuex"

export default {
  name: "SuperAdminClinicManagers",
  components: { DataTable },
  head() {
    return {
      title: this.$t('administrators'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: null
    }
  },
  mounted() {
    this.options = superadmin_administrator_options()
    this.$store.commit('setEloquentOptions', this.options)
  }
}
</script>
