<template>
  <div v-if="['addition','return'].includes(type)">
    <SelectGroup :options="finalPayMethods"
                 variant="white"
                 :title="null"
                 :label="$t('payment_type')"
                 :required="true"
                 :showlabel="true"
                 v-model="object.forma"/>
  </div>
</template>

<script>
import SelectGroup from "@/components/form/SelectGroup"
// import pay_methods from "@/data/accounting/pay_methods"
import {mapState} from "vuex"

export default {
  name: "WarehouseDatatableFormPaymentType",
  components: {
    SelectGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  data() {
    return {
      // pay_methods,  
    }
  },
  methods: {
  },
  mounted() {
  },
  computed: {
    ...mapState({
      pay_methods: state => state.auth.pay_methods,
    }),
    type() {
      return this.object.operation_type
    },
    finalPayMethods() {
      return this.pay_methods.filter(x => x.id != 100)
    }
  },
  watch: {
    type(val) {
      if(!['addition','return'].includes(val)) {
        this.object.forma = null
      }
    }
  }
}
</script>
