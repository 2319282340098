export default {
    primaryKey: "id",
    model: 'full_treatment_template_category',
    order: 'id',
    sort: 'desc',
    modaltitle: 'category',
    deletemessage: 'are_you_sure_you_want_to_delete_category',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'parent_id',
            title: 'parent_category',
            table: true,
            table_show: 'parent.title',
            sortable: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title",
                },
            }
        },
    ]
}
