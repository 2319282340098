<template>
  <div>
    {{oneLinerFromProperty(object.profession, 'title') }}
  </div>
</template>

<script>

import {oneLinerFromProperty} from "@/extensions/filters/filters"

export default {
  name: "StaffDatatableTDProfession",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    oneLinerFromProperty
  }
}
</script>
