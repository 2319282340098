export const currenciesAdapter = (arr) => ({
    currencies: minimizeCurrencies(arr),
})

function minimizeCurrencies(val) {
    let data = {}
    val.forEach(item => {
        if(item.currency_id) {
            data[item.currency_id] = {
                value: item.value
            }
        }
    })
    return data


}

