<template>
  <div class="clinic-marketing-data page pb-5 pt-4">
    <CheckboxGroup :label="$t('do_not_send_notifications_if_period_passed')"
                   :checkboxvalue="1"
                   fid="do_not_send_if_passed"
                   v-model="do_not_send_if_passed"/>
    <CheckboxGroup :label="$t('sendouts_only_in_day_time')"
                   :checkboxvalue="1"
                   fid="sendouts_day_time"
                   v-model="sendouts_day_time"/>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">{{ $t('save') }}</button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { ClinicService } from "@/services/api.service"

export default {
  name: "SettingsMarketing",
  head() {
    return {
      title: this.$t('marketing'),
    }
  },
  components: {
    CheckboxGroup,
  },
  data() {
    return {
      do_not_send_if_passed: 0,
      sendouts_day_time: 0,
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        do_not_send_if_passed: 0,
        sendouts_day_time: 0,
      }
    },
  },
  watch: {

  },
  methods: {
    async save() {
      await ClinicService.savePPOAttribute([
        'do_not_send_if_passed',
        'sendouts_day_time',
      ], [
        this.do_not_send_if_passed,
        this.sendouts_day_time,
      ], this.superadmin, this.clinic.id)
      this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
  },
  mounted() {
    this.do_not_send_if_passed = this.ppoSettings.do_not_send_if_passed
    this.sendouts_day_time = this.ppoSettings.sendouts_day_time
  }
}
</script>
