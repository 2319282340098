<template>
  <div v-html="servicesString"></div>
</template>

<script>
export default {
  name: "PatientsDatatableTDServices",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    servicesString() {
      let grouped = this.groupBy(this.object.actual_services, 'id')
      let arr = []
      for(const g in grouped) {
        arr.push(grouped[g][0].name+' x '+grouped[g].length)
      }
      return arr.join(',<br>')
    }
  },
  methods: {
    groupBy(arr, key) {
      return arr.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }
  }
}
</script>

<style scoped>

</style>
