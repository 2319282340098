<template>
  <span>
  <a class="data-table-action"
     v-if="object.status === 'waiting'"
     @click="cancelNotification"
     v-b-tooltip.hover
     :title="$t('cancel_sending')"
  >
    <CancelSvg/><span class="action-button-mob-title">{{ $t('cancel_sending') }}</span>
  </a>
    <a class="data-table-action" v-if="superadmin"
       @click="deleteNotification"
       v-b-tooltip.hover
       :title="$t('delete')">
<TrashSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('delete') }}</span>
    </a>
  </span>
</template>

<script>

import CancelSvg from '@/assets/svg-vue/general/block.svg'
import TrashSvg from "@/assets/svg-vue/general/trash_tr.svg"
import { EloquentService } from "@/services/api.service"
import { mapState } from "vuex"

export default {
  name: "PatientDatatableAdvanceActions",
  components: {
    CancelSvg,
    TrashSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      superadmin: state => state.auth.superadmin,
    }),
  },
  methods: {
    async cancelNotification() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, { sms_error_code: 999 })
      await EloquentService.update('notification', this.object.id, formData, this.superadmin)
      this.$store.commit('incrementDataTableCounter')
    },
    async deleteNotification() {
      await EloquentService.delete('notification', this.object.id, this.superadmin)
      this.$store.commit('incrementDataTableCounter')
    }
  }
}
</script>
