export default {
    abs: "абс.",
    activate: "Активувати",
    add_category: "Додати категорію",
    additional_materials: "Додаткові матеріали",
    archive: "Архів",
    are_you_sure_you_want_to_delete_category: "Ви впевнені, що хочете видалити категорію?",
    are_you_sure_you_want_to_delete_service: "Ви впевнені, що хочете видалити послугу?",
    average_price: "Середня ціна",
    category: "Категорія",
    categories: "Категорії",
    change_category: "Змінити категорію",
    change_pricelist: "Змінити прайс",
    complex_service: "Комплексна послуга",
    complex_services: "Комплексні послуги",
    current_value: "Поточна вартість",
    decrease_price: "Зменшити ціну",
    discounted_price: "Ціна зі знижкою",
    doctor_reward: "Винагорода лікаря",
    import_standard_services: "Імпорт стандартних послуг",
    increase_price: "Збільшити ціну",
    manager: "Адміністратор",
    manager_reward: "Винагорода адміністратора",
    money_lost_on_discount: "Втрата грошей на знижках",
    ndfl_code: "Код НДФЛ",
    packages_cost: "Собівартість комплектів",
    personal_percent_for_assistant: "Персональний відсоток для помічника",
    personal_percent_for_doctor: "Персональний відсоток для лікаря",
    personal_percent_for_manager: "Персональний відсоток для адміністратора",
    personal_percent_for_technic: "Персональний відсоток для техніка",
    price_can_be_changed: "Ціна може змінюватися в картці лікування",
    pricelist_price: "Ціна по прайсу",
    pricelist_short: "Прайс",
    reset_selection: "Скинути вибірку",
    send_to_archive: "Відправити в архів",
    Service: "Послуга",
    service_category: "Категорія",
    service_code: "Код послуги",
    technic: "Технік",
    technic_reward: "Винагорода техніка"
}
