<template>
  <div class="">
    <ServiceMultiSelectManual :label="$t('services')"
                              class="mt-2"
                              title="title"
                              :dblclick="true"
                              :dbsearch="true"
                              :prepopulatedOptions="selectModels.services"
                              @select="serviceAdded"
                              @remove="serviceDeleted"
                              v-model="object.services"/>
  </div>
</template>

<script>

// import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import ServiceMultiSelectManual from "@/components/form/ServiceMultiSelectManual"

export default {
  name: "PlanTreatmentTemplateDatatableFormServices",
  components: {
    // ServiceMultiSelect,
    ServiceMultiSelectManual
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      servicesLocal: []
    }
  },
  computed: {

  },
  mounted() {
    if(this.object && this.object.services && this.object.services.length) {
      this.object.services.forEach(srv => {
        if(srv.pivot && parseInt(srv.pivot.quantity) > 1) {
          for (var i = 0; i < (parseInt(srv.pivot.quantity) - 1); i++) {
            this.object.services.push(srv)
          }
        }
      })
    }
  },
  methods: {
    serviceAdded(service, qty = 1) {
      if(!this.object.services) this.object.services = []
      for(var i = 0; i < qty; i++) {
        this.object.services.push(service)
      }
    },
    serviceDeleted(service, qty = 1) {
      for (var i = 0; i < qty; i++) {
        this.object.services.some((srv, index) => {
          if (srv.id === service.id) {
            this.object.services.splice(index, 1)
            return true;
          }
        })
      }
    }
  }
}
</script>
