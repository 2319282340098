export default {
    primaryKey: "id",
    model: 'site_form',
    order: 'id',
    sort: 'desc',
    // modaltitle: 'form',
    // modalsize: 'lg',
    // adapter: taskAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_task',
    actionscomponent: 'CRMSiteFormDatatableActions',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
        },
        {
            id: 'active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]

}
