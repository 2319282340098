<template>
  <div>
    {{ mailing && mailing.notification_channel ? mailing.notification_channel.title : '' }}
  </div>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MarketingDatatableTDNotificationChannel",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      mailing: state => state.marketing.marketingMailing,
    }),
  },
  mounted() {
  }
}
</script>
