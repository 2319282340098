<template>
  <div>
    <div v-if="loaded">
      <CheckboxGroup v-model="customPrices"
                     :label="$t('use_custom_prices_for_insurance')"
                     fid="custom_prices" />
      <div v-if="customPrices">
        <div v-for="company in companies"
             :key="`company-${company.id}`">
          <InputGroup :label="company.title"
                      type="number"
                      v-model="object.insurance_company_prices[company.id]"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue"
import { EloquentService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"

const props = defineProps({ object: Object })

const companies = ref([])
const loaded = ref(false)
const customPrices = ref(false)

onMounted(async () => {
  const res = await EloquentService.autocomplete('insurance_company', '', 999)
  companies.value = res.data.result
  props.object.insurance_company_prices = {}
  if(props.object.id) {
    const pricesResponse = await EloquentService.dataTable('insurance_company_price', 1, {
      condition: {
        service_id: props.object.id
      }
    })
    const prices = pricesResponse?.data?.result?.data || []
    if(prices.length) customPrices.value = true
    for(const price of prices) {
      props.object.insurance_company_prices[price.insurance_company_id] = Number(price.price)
    }
  }
  loaded.value = true
})

watch(customPrices, (value) => {
  if(!value) {
    props.object.insurance_company_prices = {}
  }
})
</script>
