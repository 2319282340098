export default {
    accept_payment: "Accept payment",
    added: "Added",
    add_new_record: "+ Add new appointment",
    additionally: "Additionally",
    are_you_sure_want_to_move_visit: "Are you sure you want to reschedule your appointment?",
    are_you_sure_want_to_change_time: "Are you sure you want to change time?",
    are_you_sure_you_want_to_delete_record: "Are you sure you want to delete an appointment?",
    blocked: "Blocked",
    by_phone_found: "By phone number {phone} following patients were found",
    by_phone_nobody_found: "By phone number {phone} no patients were found",
    calendar_settings: "Calendar settings",
    cancel_record: "Cancel an appointment",
    change_record: "Change an appointment",
    checkup: "Examination",
    child_age: "below 18 years",
    choose_all: "Choose all",
    clear: "Clear",
    create_notification: "Create notification",
    create_patient: "Create patient",
    create_task: "Create task",
    confirm_visit: "Confirm an appointment",
    curation: "Curation",
    DAY: "Day",
    day_short: "D",
    days: "days",
    delete_record: "Delete an appointment",
    diagnostics: "Diagnostics",
    doctor_available_time: "Doctor’s available time",
    doctor_has_no_available_time_at_this_day: "Doctor has no available time at this day",
    doctor_is_not_working_on_this_date_continue: "Doctor does not work on this time or this time is busy. Continue?",
    doctors_selected: "Doctors selected",
    edit_schedule: "Edit schedule",
    edit_visit: "Edit visit",
    email_text: "E-mail text",
    email_topic: "E-mail topic",
    employees_selected: "Employees selected",
    fillDoctorColor: "Fill the entry with the doctor's color",
    fill_treatment: "Fill treatment",
    for: "For",
    free_minutes: "Free minutes",
    hideChairsWithoutSchedule: "Hide chairs without doctors with a work schedule on a selected day",
    hideDoctorsWithoutSchedule: "Hide doctors who is not working on the selected day",
    hour_short: "H",
    info: "Info",
    load_demo_data_to_account: "Load demo data to account?",
    min_short: "Min",
    move_record: "Move record",
    need_to_select_at_least_one_doctor: "Need to select at least one doctor",
    need_to_select_at_least_one_employee: "Need to select at least one employee",
    new_visit: "New appointment",
    no_birthdays_on_this_date: "No birthdays on this date",
    no_free_time: "No free time",
    no_schedule: "No schedule",
    no_visits: "No appointments",
    not_working: "Not working",
    of_day: "days",
    online_consultation: "Online consultation",
    patient_came: "Patient came",
    patient_card: "Patient card",
    patient_notifications: "Patient notifications",
    postponed: "Postponed",
    quick_view: "Quick view",
    record_again: "Copy record",
    record_conflict: "Record conflict",
    record_has_no_paid_services: "Appointment has no paid services",
    record_type: "Appointment type",
    related_tasks: "Related tasks",
    requires_confirmation: "Requires confirmation",
    return_visit: "Return visit",
    sanation: "Sanation",
    schedule: "Schedule",
    select_a_doctor: "Select a doctor",
    select_an_employee: "Select an employee",
    selected: "Selected",
    send_sms: "Send SMS",
    sms_text: "SMS text",
    showClientTags: "Use patient markup colors",
    showDoctorColor: "Use Doctors’ colors",
    showPlannedServices: "Show planned services as text",
    showRecordTagColor: "User record tag color as record background",
    showServiceColor: "Use colors of service categories",
    showStatusColor: "Use colors for appointment status",
    showOverflowScreen: "Use screen scrolling when there is a large dataset",
    till: "till",
    time_has_already_passed: "Time has already passed",
    tomorrow: "Tomorrow",
    visits_count: "No appointments | visit | visits",
    visits_count_much: "visits",
    warranty_case: "Warranty case",
    write_reason_for_cancellation: "Specify reason for cancellation",
    yesterday: "Yesterday",
    you_have_no_doctors: "You don't have any doctors. You can add a doctor  <a href='{href}'>here</a>",
    WEEK: "Week",
    MONTH: "Month",
    CHAIR: "Chair",
    DOCTOR: "Doctor",
}
