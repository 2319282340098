<template>
  <div v-if="month">
    <DataTable :options="options"
               :params="{ bookkeeping_id: month.id }"
               module="accounting"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/accounting/other_income"
import { mapState } from "vuex"

export default {
  name: "AccountingMonthOtherIncome",
  components: {
      DataTable,
  },
  head() {
    return {
      title: this.$t('other_income'),
    }
  },
  computed: {
    ...mapState({
      month: state => state.accounting.accountingmonth
    })
  },
  data() {
    return {
      options: options
    }
  },
  watch: {
      month(val) {
        this.$store.commit('setAdditionalParams', { bookkeeping_id: val.id })
      }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  beforeUnmount() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>

<style scoped>

</style>
