import { serviceAdapter } from '@/adapters/services/service'

export default {
    primaryKey: "id",
    model: 'service',
    order: 'name',
    sort: 'asc',
    adapter: serviceAdapter,
    showTotalRecords: true,
    actions: [
        "add"
    ],
    actionscomponent: 'ServicesDatatableActionsArchive',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'cod',
            title: 'code',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
            }
        },
        {
            id: 'category_id',
            title: 'category',
            table: true,
            table_show: 'category.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                required: true,
                select_data: {
                    "title": "title",
                },
            }
        },
        {
            id: 'cost',
            title: 'cost',
            table: true,
            sortable: true,
            filter: true,
            filter_number: true,
            tdcomponent: 'ServicesDatatableTDCost',
            form: {
                type: "number",
                required: true,
                component: "ServicesDatatableFormCard"
            }
        },
    ]

}
