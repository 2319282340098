<template>
  <g>
    <!--   SAME AS 24  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M18.6089 2.26976L16.5252 1H13.0415L10.9415 2.26976L12.5531 3.81627L10.4694 5.36278L12.3577 6.90929L10.0624 8.60231L11.8856 10.1488L9.5903 11.5488L11.6089 12.9651L9.5903 14.5767L11.2833 16.1232L9.05309 17.5395L10.8112 19.0046L8.51588 20.5511L10.6647 22.0976L8.51588 23.7093L10.0624 25.0604L7.97868 26.6069L9.80193 28.0883L7.71821 29.6348L9.52518 31.3767L8.51588 33.0534V47.3139L10.4043 54.6394H16.5252" fill="#8889BE"/>
      <path d="M11.2016 2.26976L13.2853 1H16.7528L18.869 2.26976L17.2411 3.81627L19.3411 5.36278L17.4528 6.90929L19.7318 8.60231L17.9249 10.1488L20.2039 11.5488L18.1853 12.9651L20.2039 14.5767L18.5272 16.1232L20.7411 17.5395L18.9993 19.0046L21.2783 20.5511L19.1295 22.0976L21.2783 23.7093L19.7318 25.0604L21.8155 26.6069L20.0086 28.0883L22.0923 29.6348L20.269 31.3767L21.2783 33.0534V47.3139L19.4062 54.6394H15.4993" fill="#8889BE"/>
      <path d="M1.83427 71.81C2.68147 72.9665 3.25972 72.7648 5.53236 74.7685C7.52261 76.5302 7.33435 76.9067 8.94806 78.1842C10.3374 79.5639 12.173 80.403 14.1254 80.551C15.4953 80.2459 16.7985 79.6946 17.9714 78.9238C19.8731 77.9797 21.7025 76.8965 23.4446 75.683C24.9362 74.7887 26.2944 73.6886 27.4789 72.4152C29.6305 69.4164 28.8909 65.409 27.9226 60.1375C27.6133 58.47 27.0889 56.5739 26.0534 52.7548C26.0534 52.7548 26.0534 52.6472 26.0534 52.58C25.3004 50.8049 20.2709 48.2902 20.2709 48.2902C18.4163 47.268 16.3231 46.7575 14.2061 46.8109C12.2935 46.9128 10.424 47.4169 8.71945 48.2902C7.57427 48.8149 6.48791 49.4595 5.47857 50.2132C5.38444 50.4956 5.29031 50.7914 5.16928 51.1007C4.2145 53.6154 3.59591 53.3734 2.47976 56.1301C1.41448 59.0717 0.912655 62.1875 1.00052 65.3148C0.866045 68.7036 0.866043 70.4249 1.83427 71.81Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M24.334 52.2226C24.7912 53.8228 25.3022 55.4366 26.1629 58.6236C27.1984 62.4293 27.7228 64.3389 28.0321 65.9929C29.0003 71.2644 29.7534 75.2852 27.5883 78.2841C26.4008 79.5504 25.043 80.6456 23.5541 81.5384C21.8078 82.7568 19.974 83.8446 18.0674 84.7927C16.8977 85.5694 15.5934 86.1212 14.2214 86.4199C12.2672 86.279 10.4292 85.4387 9.04409 84.0531C7.43037 82.789 7.63209 82.4125 5.64184 80.6508C3.35575 78.6337 2.7775 78.8354 1.9303 77.6924C0.92173 76.3476 0.948626 74.5725 1.04276 71.1703C0.954979 68.0474 1.45683 64.9359 2.522 61.999C3.6247 59.2422 4.22984 59.4709 5.21152 56.9561C6.39491 53.8901 6.1663 51.6981 6.26043 47.9328C6.39545 44.1154 6.74113 40.3085 7.2959 36.5292C8.26413 29.644 9.31304 30.5316 10.5502 23.3506C12.0429 14.6231 12.796 10.266 12.6212 7.6572C12.5539 6.67552 12.2984 3.70361 14.1004 1.88818C14.2349 1.75371 15.3376 0.664439 16.0234 0.973734C16.9513 1.39061 16.0234 3.87842 17.0589 7.69754C17.3278 8.73301 17.7447 10.266 18.5381 10.3871C19.3315 10.5081 20.1115 8.4775 20.9049 8.59853C21.6983 8.71956 22.1555 12.0142 22.2496 13.1976C22.539 16.4455 22.539 19.7127 22.2496 22.9606C21.3691 28.4917 21.1704 34.1099 21.6579 39.6894C22.2058 43.9333 23.1008 48.1251 24.334 52.2226Z" fill="#DEE1E9"/>
      <path d="M1.92289 77.679C2.77009 78.8355 3.34834 78.6338 5.62099 80.6375C7.61123 82.3991 7.42297 82.7756 9.03668 84.0532C10.426 85.4328 12.2616 86.272 14.214 86.4199C15.584 86.1149 16.8871 85.5635 18.06 84.7928C19.9617 83.8487 21.7911 82.7655 23.5332 81.5519C25.0249 80.6577 26.383 79.5576 27.5675 78.2842C29.7191 75.2853 28.9795 71.2779 28.0113 66.0065C27.702 64.339 27.1775 62.4428 26.142 58.6237C26.142 58.6237 26.142 58.5162 26.142 58.4489C25.389 56.6738 20.3596 54.1591 20.3596 54.1591C18.5049 53.137 16.4117 52.6265 14.2947 52.6799C12.3822 52.7818 10.5126 53.2858 8.80807 54.1591C7.66289 54.6839 6.57653 55.3285 5.5672 56.0821C5.47306 56.3645 5.37893 56.6604 5.2579 56.9697C4.30312 59.4844 3.68453 59.2423 2.56838 61.9991C1.5031 64.9406 1.00128 68.0565 1.08914 71.1838C0.954668 74.5726 0.954666 76.2939 1.92289 77.679Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M14.5013 64.0162C15.846 64.1372 17.7287 59.2558 18.4414 54.6029L18.2666 41.3973C18.0783 37.2017 17.8632 33.7725 17.7152 31.4327C17.3656 26.0536 17.2446 25.4216 17.2446 23.1489C17.2446 17.2992 15.0257 3.43868 14.5819 3.31765C14.1382 3.19662 16.1553 16.4251 15.4829 19.1146C14.6357 22.5707 14.7837 24.4937 14.5819 29.4424C14.56 33.2605 14.2589 37.0718 13.681 40.846C13.1162 43.8986 12.5379 45.3644 11.9059 49.7483C11.5469 51.7761 11.4565 53.8424 11.6369 55.8938C11.7899 57.544 12.1421 59.1695 12.6858 60.735C13.5196 62.8732 13.9096 63.9624 14.5013 64.0162Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M24.334 54.3797C24.7912 55.98 25.3022 57.5937 26.1629 60.7808C27.1984 64.5865 27.7228 66.4961 28.0321 68.1501C29.0003 73.4216 29.7534 77.4424 27.5883 80.4412C26.4008 81.7076 25.043 82.8028 23.5541 83.6955C21.8078 84.914 19.974 86.0017 18.0674 86.9499C16.8977 87.7266 15.5934 88.2784 14.2214 88.577C12.2672 88.4361 10.4292 87.5959 9.04409 86.2102C7.43037 84.9462 7.63209 84.5696 5.64184 82.808C3.35575 80.7909 2.7775 80.9926 1.9303 79.8495C0.92173 78.5048 0.948626 76.7297 1.04276 73.3274C0.954979 70.2045 1.45683 67.0931 2.522 64.1562C3.6247 61.3994 4.22984 61.628 5.21152 59.1133C6.39491 56.0473 6.1663 53.8553 6.26043 50.09C6.39545 46.2726 6.74113 42.4656 7.2959 38.6864C8.26413 31.8012 9.31304 32.6887 10.5502 25.5077C12.0429 16.7802 12.796 12.4232 12.6212 9.81436C12.5539 8.83269 12.2984 5.86078 14.1004 4.04535C14.2349 3.91087 15.3376 2.8216 16.0234 3.1309C16.9513 3.54778 16.0234 6.03558 17.0589 9.85471C17.3278 10.8902 17.7447 12.4232 18.5381 12.5442C19.3315 12.6653 20.1115 10.6347 20.9049 10.7557C21.6983 10.8767 22.1555 14.1714 22.2496 15.3548C22.539 18.6027 22.539 21.8699 22.2496 25.1178C21.3691 30.6489 21.1704 36.2671 21.6579 41.8466C22.2058 46.0905 23.1008 50.2822 24.334 54.3797Z" fill="#DEE1E9"/>
      <path d="M1.92289 79.8362C2.77009 80.9927 3.34834 80.791 5.62099 82.7946C7.61123 84.5563 7.42297 84.9328 9.03668 86.2103C10.426 87.59 12.2616 88.4292 14.214 88.5771C15.584 88.272 16.8871 87.7207 18.06 86.95C19.9617 86.0059 21.7911 84.9226 23.5332 83.7091C25.0249 82.8148 26.383 81.7148 27.5675 80.4413C29.7191 77.4425 28.9795 73.4351 28.0113 68.1636C27.702 66.4961 27.1775 64.6 26.142 60.7809C26.142 60.7809 26.142 60.6733 26.142 60.6061C25.389 58.831 20.3596 56.3163 20.3596 56.3163C18.5049 55.2942 16.4117 54.7836 14.2947 54.8371C12.3822 54.939 10.5126 55.443 8.80807 56.3163C7.66289 56.841 6.57653 57.4856 5.5672 58.2393C5.47306 58.5217 5.37893 58.8175 5.2579 59.1268C4.30312 61.6415 3.68453 61.3995 2.56838 64.1562C1.5031 67.0978 1.00128 70.2137 1.08914 73.341C0.954668 76.7298 0.954666 78.4511 1.92289 79.8362Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M14.5013 66.1734C15.846 66.2944 17.7287 61.4129 18.4414 56.7601L18.2666 43.5545C18.0783 39.3588 17.8632 35.9297 17.7152 33.5898C17.3656 28.2108 17.2446 27.5787 17.2446 25.3061C17.2446 19.4564 15.0257 5.59585 14.5819 5.47482C14.1382 5.35379 16.1553 18.5823 15.4829 21.2718C14.6357 24.7278 14.7837 26.6508 14.5819 31.5996C14.56 35.4176 14.2589 39.229 13.681 43.0032C13.1162 46.0558 12.5379 47.5215 11.9059 51.9055C11.5469 53.9333 11.4565 55.9996 11.6369 58.051C11.7899 59.7011 12.1421 61.3267 12.6858 62.8922C13.5196 65.0303 13.9096 66.1196 14.5013 66.1734Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M24.2454 51.7051C24.7026 53.3053 25.2136 54.919 26.0743 58.1061C27.1097 61.9118 27.6342 63.8214 27.9435 65.4754C28.9117 70.7469 29.6648 74.7677 27.4997 77.7665C26.3121 79.0329 24.9544 80.1281 23.4654 81.0209C21.7192 82.2393 19.8854 83.327 17.9788 84.2752C16.8091 85.0519 15.5048 85.6037 14.1328 85.9024C12.1786 85.7614 10.3406 84.9212 8.95547 83.5356C7.34175 82.2715 7.54346 81.895 5.55322 80.1333C3.26712 78.1162 2.68888 78.3179 1.84168 77.1748C0.833107 75.8301 0.860003 74.055 0.954136 70.6527C0.866356 67.5299 1.3682 64.4184 2.43337 61.4815C3.53608 58.7247 4.14122 58.9533 5.1229 56.4386C6.30629 53.3726 6.07767 51.1806 6.17181 47.4153C6.30683 43.5979 6.65251 39.7909 7.20727 36.0117C8.1755 29.1265 9.22442 30.0141 10.4616 22.833C11.9543 14.1055 12.7073 9.74852 12.5325 7.13968C12.4653 6.15801 12.2098 3.18609 14.0118 1.37067C14.1462 1.23619 15.2489 0.146922 15.9348 0.456217C16.8627 0.873093 15.9348 3.3609 16.9702 7.18002C17.2392 8.21549 17.6561 9.74852 18.4495 9.86954C19.2429 9.99057 20.0228 7.95998 20.8163 8.08101C21.6097 8.20204 22.0669 11.4967 22.161 12.6801C22.4504 15.928 22.4504 19.1952 22.161 22.4431C21.2805 27.9742 21.0818 33.5924 21.5693 39.1719C22.1172 43.4158 23.0122 47.6075 24.2454 51.7051Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M24.2454 51.7051C24.7026 53.3053 25.2136 54.919 26.0743 58.1061C27.1097 61.9118 27.6342 63.8214 27.9435 65.4754C28.9117 70.7469 29.6648 74.7677 27.4997 77.7665C26.3121 79.0329 24.9544 80.1281 23.4654 81.0209C21.7192 82.2393 19.8854 83.327 17.9788 84.2752C16.8091 85.0519 15.5048 85.6037 14.1328 85.9024C12.1786 85.7614 10.3406 84.9212 8.95547 83.5356C7.34175 82.2715 7.54346 81.895 5.55322 80.1333C3.26712 78.1162 2.68888 78.3179 1.84168 77.1748C0.833107 75.8301 0.860003 74.055 0.954136 70.6527C0.866356 67.5299 1.3682 64.4184 2.43337 61.4815C3.53608 58.7247 4.14122 58.9533 5.1229 56.4386C6.30629 53.3726 6.07767 51.1806 6.17181 47.4153C6.30683 43.5979 6.65251 39.7909 7.20727 36.0117C8.1755 29.1265 9.22442 30.0141 10.4616 22.833C11.9543 14.1055 12.7073 9.74852 12.5325 7.13968C12.4653 6.15801 12.2098 3.18609 14.0118 1.37067C14.1462 1.23619 15.2489 0.146922 15.9348 0.456217C16.8627 0.873093 15.9348 3.3609 16.9702 7.18002C17.2392 8.21549 17.6561 9.74852 18.4495 9.86954C19.2429 9.99057 20.0228 7.95998 20.8163 8.08101C21.6097 8.20204 22.0669 11.4967 22.161 12.6801C22.4504 15.928 22.4504 19.1952 22.161 22.4431C21.2805 27.9742 21.0818 33.5924 21.5693 39.1719C22.1172 43.4158 23.0122 47.6075 24.2454 51.7051Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M21.855 60.3764C20.9943 57.1894 21.4169 52.9667 20.9597 51.3664C19.7265 47.2689 18.8315 43.0771 18.2836 38.8333C17.7961 33.2538 17.9948 27.6356 18.8753 22.1044C19.1647 18.8566 19.1647 15.5894 18.8753 12.3415C18.7812 11.1581 18.324 7.86342 17.5306 7.74239C16.7372 7.62136 15.9572 9.65195 15.1638 9.53092C14.3704 9.40989 13.9535 7.87687 13.6846 6.8414C12.6491 3.02228 13.577 0.53447 12.6491 0.117594C11.9633 -0.191701 10.8606 0.897566 10.7261 1.03204C8.9241 2.84747 9.1796 5.81938 9.24684 6.80106C9.41949 9.37743 8.68719 13.6588 7.23129 22.1706L7.17591 22.4944C5.93873 29.6754 4.88981 28.7879 3.92159 35.6731C3.36682 39.4523 3.02114 43.2593 2.88612 47.0766C2.79199 50.842 3.0206 53.034 1.83721 56.1C0.855532 58.6147 0.605097 61.1156 0.605097 61.1156L6.37796 60.3764L11.8247 61.1156L17.0421 59.6257L21.855 60.3764Z" fill="#DEE1E9"/>
      <path d="M19.954 52.4463C12.6653 50.9787 8.9791 51.9211 2.97197 56.1461" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M14.8923 40.5412L15.0671 53.7468L14.7189 60.8015L12.3718 59.8789L10.8585 61.0795L9.31153 59.8789C8.76783 58.3134 8.41563 56.6878 8.26262 55.0377C8.08222 52.9863 8.17264 50.92 8.53157 48.8922C9.16361 44.5082 9.74185 43.0425 10.3067 39.9899C10.8846 36.2157 11.1857 32.4043 11.2076 28.5863C11.4094 23.6375 11.2614 21.7145 12.1086 18.2585C12.781 15.569 10.7639 2.34049 11.2076 2.46152C11.6514 2.58255 13.8703 16.4431 13.8703 22.2928C13.8703 24.5654 13.9913 25.1975 14.3409 30.5765C14.4889 32.9164 14.704 36.3455 14.8923 40.5412Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M25.7913 58.3554C24.9306 55.1683 24.4196 53.5546 23.9624 51.9544C22.7292 47.8568 21.8342 43.6651 21.2863 39.4212C20.7988 33.8417 20.9975 28.2235 21.878 22.6924C22.1674 19.4445 22.1674 16.1773 21.878 12.9294C21.7839 11.746 21.3267 8.45134 20.5333 8.33031C19.7399 8.20928 18.9599 10.2399 18.1665 10.1188C17.3731 9.99781 16.9562 8.46479 16.6873 7.42932C15.6518 3.6102 16.5797 1.12239 15.6518 0.705515C14.966 0.39622 13.8633 1.48549 13.7288 1.61996C11.9268 3.43539 12.1823 6.4073 12.2495 7.38898C12.4222 9.96535 11.6899 14.2467 10.234 22.7585L10.1786 23.0823C8.94143 30.2634 7.89252 29.3758 6.92429 36.261C6.36953 40.0402 6.02385 43.8472 5.88882 47.6646C5.79469 51.4299 6.0233 53.6219 4.83991 56.6879C3.85824 59.2026 3.25309 58.974 2.15039 61.7308L25.7913 58.3554Z" fill="#DEE1E9"/>
      <path d="M5.24938 80.3693C2.97673 78.3656 2.39849 78.5673 1.55129 77.4108C0.583059 76.0257 0.583061 74.3044 0.717537 70.9156C0.629671 67.7883 1.13149 64.6724 2.19677 61.7309C3.31293 58.9741 3.93152 59.2162 4.8863 56.7015C5.00733 56.3922 5.10146 56.0963 5.19559 55.8139C6.20493 55.0602 7.29128 54.4156 8.43647 53.8909C10.141 53.0176 12.0106 52.5136 13.9231 52.4117C16.0401 52.3583 18.1333 52.8688 19.988 53.8909C19.988 53.8909 25.0174 56.4056 25.7704 58.1807V58.3555C26.8059 62.1746 27.3304 64.0708 27.6397 65.7383L27.6435 65.7591C27.7213 66.1827 27.7976 66.5982 27.871 67.0056L22.8358 66.0929L18.9732 67.9272L19.8803 70.5725L18.6727 73.0394L19.8803 75.3528L16.0712 76.8675L17.4099 79.9025L13.8424 79.1313L16.0712 83.4706L17.0295 84.9268C16.0346 85.4895 14.9608 85.9027 13.8424 86.1517C11.89 86.0038 10.0544 85.1646 8.66508 83.785C7.05136 82.5074 7.23963 82.1309 5.24938 80.3693Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M22.8839 61.6207C12.208 65.394 9.88314 69.9414 11.5087 81.8769" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M14.1296 63.748C15.4744 63.869 17.3571 58.9875 18.0698 54.3347L17.895 41.1291C17.7067 36.9334 17.4915 33.5043 17.3436 31.1644C16.994 25.7854 16.873 25.1533 16.873 22.8807C16.873 17.031 14.6541 3.17046 14.2103 3.04943C13.7666 2.9284 15.7837 16.1569 15.1113 18.8464C14.2641 22.3024 14.412 24.2255 14.2103 29.1742C14.1884 32.9922 13.8873 36.8036 13.3093 40.5778C12.7445 43.6304 12.1663 45.0962 11.5343 49.4801C11.1753 51.5079 11.0849 53.5742 11.2653 55.6256C11.4183 57.2758 11.7705 58.9013 12.3142 60.4668C13.148 62.6049 13.5379 63.6942 14.1296 63.748Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M24.3341 51.3039C24.7914 52.9041 25.3024 54.5179 26.163 57.7049C27.1985 61.5106 27.7229 63.4202 28.0322 65.0742C29.0005 70.3457 29.7535 74.3665 27.5885 77.3654C26.4009 78.6317 25.0431 79.7269 23.5542 80.6197C21.808 81.8381 19.9741 82.9259 18.0676 83.874C16.8978 84.6507 15.5935 85.2025 14.2215 85.5012C12.2674 85.3603 10.4294 84.52 9.04421 83.1344C7.4305 81.8703 7.63221 81.4938 5.64196 79.7321C3.35587 77.715 2.77762 77.9167 1.93042 76.7737C0.921852 75.4289 0.948748 73.6538 1.04288 70.2516C0.955101 67.1287 1.45695 64.0172 2.52212 61.0803C3.62482 58.3235 4.22997 58.5522 5.21164 56.0374C6.39503 52.9714 6.16642 50.7794 6.26055 47.0141C6.39557 43.1967 6.74126 39.3898 7.29602 35.6105C8.26425 28.7253 9.31316 29.6129 10.5503 22.4319C12.043 13.7044 12.7961 9.34733 12.6213 6.7385C12.554 5.75682 12.2985 2.78491 14.1005 0.969481C14.235 0.835005 15.3377 -0.254262 16.0235 0.0550331C16.9514 0.471909 16.0235 2.95972 17.059 6.77884C17.3279 7.8143 17.7448 9.34733 18.5382 9.46836C19.3316 9.58939 20.1116 7.5588 20.905 7.67983C21.6984 7.80085 22.1556 11.0955 22.2498 12.2789C22.5392 15.5268 22.5392 18.794 22.2498 22.0419C21.3693 27.573 21.1705 33.1912 21.6581 38.7707C22.2059 43.0146 23.1009 47.2064 24.3341 51.3039Z" fill="#DEE1E9"/>
      <path d="M5.62111 79.7188C3.34846 77.7151 2.77022 77.9168 1.92302 76.7603C0.954788 75.3752 0.95479 73.6539 1.08927 70.2651C1.0014 67.1378 1.50322 64.0219 2.5685 61.0804C3.68465 58.3236 4.30325 58.5657 5.25803 56.051C5.37906 55.7417 5.47319 55.4458 5.56732 55.1634H9.47311C10.0254 55.1634 10.4731 55.6111 10.4731 56.1634V66.0612C14.2824 67.6382 16.4127 67.6689 20.223 66.0612V56.1634C20.223 55.6111 20.6707 55.1634 21.223 55.1634H25.0309C25.0309 55.2307 26.1422 57.705 26.1422 57.705C27.1776 61.5241 27.7021 63.4203 28.0114 65.0878L28.0152 65.1086C28.9816 70.3698 29.7164 74.3706 27.5676 77.3654C26.3831 78.6389 25.025 79.7389 23.5333 80.6332C21.7912 81.8467 19.9618 82.93 18.0602 83.8741C16.8872 84.6448 15.5841 85.1961 14.2141 85.5012C12.2618 85.3533 10.4261 84.5141 9.0368 83.1345C7.42309 81.8569 7.61135 81.4804 5.62111 79.7188Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M24.3341 51.3039C24.7914 52.9041 25.3024 54.5179 26.163 57.7049C27.1985 61.5106 27.7229 63.4202 28.0322 65.0742C29.0005 70.3457 29.7535 74.3665 27.5885 77.3654C26.4009 78.6317 25.0431 79.7269 23.5542 80.6197C21.808 81.8381 19.9741 82.9259 18.0676 83.874C16.8978 84.6507 15.5935 85.2025 14.2215 85.5012C12.2674 85.3603 10.4294 84.52 9.04421 83.1344C7.4305 81.8703 7.63221 81.4938 5.64196 79.7321C3.35587 77.715 2.77762 77.9167 1.93042 76.7737C0.921852 75.4289 0.948748 73.6538 1.04288 70.2516C0.955101 67.1287 1.45695 64.0172 2.52212 61.0803C3.62482 58.3235 4.22997 58.5522 5.21164 56.0374C6.39503 52.9714 6.16642 50.7794 6.26055 47.0141C6.39557 43.1967 6.74126 39.3898 7.29602 35.6105C8.26425 28.7253 9.31316 29.6129 10.5503 22.4319C12.043 13.7044 12.7961 9.34733 12.6213 6.7385C12.554 5.75682 12.2985 2.78491 14.1005 0.969481C14.235 0.835005 15.3377 -0.254262 16.0235 0.0550331C16.9514 0.471909 16.0235 2.95972 17.059 6.77884C17.3279 7.8143 17.7448 9.34733 18.5382 9.46836C19.3316 9.58939 20.1116 7.5588 20.905 7.67983C21.6984 7.80085 22.1556 11.0955 22.2498 12.2789C22.5392 15.5268 22.5392 18.794 22.2498 22.0419C21.3693 27.573 21.1705 33.1912 21.6581 38.7707C22.2059 43.0146 23.1009 47.2064 24.3341 51.3039Z" fill="#DEE1E9"/>
      <path d="M5.62111 79.7188C3.34846 77.7151 2.77022 77.9168 1.92302 76.7603C0.954788 75.3752 0.95479 73.6539 1.08927 70.2651C1.0014 67.1378 1.50322 64.0219 2.5685 61.0804C3.68465 58.3236 4.30325 58.5657 5.25803 56.051C5.37906 55.7417 5.47319 55.4458 5.56732 55.1634H9.47311C10.0254 55.1634 10.4731 55.6111 10.4731 56.1634V66.0612C14.2824 67.6382 16.4127 67.6689 20.223 66.0612V56.1634C20.223 55.6111 20.6707 55.1634 21.223 55.1634H25.0309C25.0309 55.2307 26.1422 57.705 26.1422 57.705C27.1776 61.5241 27.7021 63.4203 28.0114 65.0878L28.0152 65.1086C28.9816 70.3698 29.7164 74.3706 27.5676 77.3654C26.3831 78.6389 25.025 79.739 23.5333 80.6332C21.7912 81.8468 19.9618 82.93 18.0602 83.8741C16.8872 84.6448 15.5841 85.1962 14.2141 85.5012C12.2618 85.3533 10.4261 84.5141 9.0368 83.1345C7.42309 81.8569 7.61135 81.4804 5.62111 79.7188Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M24.2222 51.8849C24.6794 53.4852 25.1904 55.0989 26.0511 58.286C27.0865 62.0917 27.611 64.0012 27.9203 65.6553C28.8885 70.9268 29.6416 74.9476 27.4765 77.9464C26.2889 79.2128 24.9312 80.308 23.4422 81.2007C21.696 82.4192 19.8622 83.5069 17.9556 84.4551C16.7859 85.2318 15.4816 85.7836 14.1096 86.0822C12.1554 85.9413 10.3174 85.1011 8.93227 83.7154C7.31856 82.4514 7.52027 82.0748 5.53003 80.3132C3.24393 78.296 2.66568 78.4978 1.81848 77.3547C0.809914 76.01 0.836809 74.2349 0.930943 70.8326C0.843163 67.7097 1.34501 64.5983 2.41018 61.6614C3.51288 58.9046 4.11803 59.1332 5.0997 56.6185C6.28309 53.5524 6.05448 51.3605 6.14861 47.5951C6.28364 43.7778 6.62932 39.9708 7.18408 36.1916C8.15231 29.3064 9.20122 30.1939 10.4384 23.0129C11.9311 14.2854 12.6842 9.92839 12.5093 7.31955C12.4421 6.33788 12.1866 3.36596 13.9886 1.55054C14.123 1.41606 15.2258 0.326793 15.9116 0.636088C16.8395 1.05296 15.9116 3.54077 16.947 7.35989C17.216 8.39536 17.6329 9.92839 18.4263 10.0494C19.2197 10.1704 19.9997 8.13985 20.7931 8.26088C21.5865 8.38191 22.0437 11.6766 22.1378 12.86C22.4272 16.1078 22.4272 19.3751 22.1378 22.6229C21.2573 28.154 21.0586 33.7723 21.5461 39.3518C22.094 43.5956 22.989 47.7874 24.2222 51.8849Z" fill="#DEE1E9"/>
      <path d="M5.50917 80.2998C3.23652 78.2961 2.65828 78.4978 1.81108 77.3413C0.84285 75.9562 0.842852 74.2349 0.977327 70.8461C0.889462 67.7189 1.39128 64.603 2.45656 61.6614C3.57272 58.9047 4.19131 59.1467 5.14609 56.632C5.26712 56.3227 5.36125 56.0269 5.45538 55.7445H9.46358V66.6422H20.9188V55.7445H24.919C24.919 55.8117 26.0302 58.2861 26.0302 58.2861C27.0657 62.1052 27.5902 64.0013 27.8994 65.6688L27.9033 65.6896C28.8696 70.9508 29.6045 74.9516 27.4557 77.9465C26.2712 79.2199 24.913 80.32 23.4214 81.2143C21.6793 82.4278 19.8499 83.511 17.9482 84.4551C16.7753 85.2259 15.4721 85.7772 14.1022 86.0823C12.1498 85.9343 10.3142 85.0952 8.92487 83.7155C7.31115 82.438 7.49942 82.0615 5.50917 80.2998Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask2024" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="30" height="31">
        <path d="M5.48305 80.2997C3.2104 78.296 2.63215 78.4977 1.78495 77.3412C0.816727 75.9561 0.816729 74.2348 0.951204 70.846C0.863338 67.7187 1.36516 64.6029 2.43044 61.6613C3.54659 58.9046 4.16518 59.1466 5.11996 56.6319C5.24099 56.3226 5.38891 56.9143 5.48305 56.6319H8.48586C9.04025 56.6319 9.48882 57.0829 9.48585 57.6373L9.43746 66.6421C13.5743 67.5778 15.8899 67.6052 20.0263 66.6421L20.0747 57.6265C20.0777 57.0764 20.5245 56.6319 21.0747 56.6319L24.9466 56.6319C24.9466 56.6991 26.0041 58.286 26.0041 58.286C27.0396 62.1051 27.564 64.0012 27.8733 65.6687L27.8771 65.6895C28.8435 70.9507 29.5783 74.9515 27.4296 77.9464C26.245 79.2198 24.8869 80.3199 23.3953 81.2141C21.6531 82.4277 19.8238 83.5109 17.9221 84.455C16.7492 85.2258 15.446 85.7771 14.0761 86.0822C12.1237 85.9342 10.288 85.0951 8.89874 83.7154C7.28503 82.4379 7.47329 82.0614 5.48305 80.2997Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2024)">
        <path d="M23.3033 44.6177H6.40874V70.1656C13.0065 72.0919 16.7056 72.1115 23.3033 70.1656V44.6177Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M24.6623 52.2507C25.1195 53.8509 25.6305 55.4646 26.4911 58.6517C27.5266 62.4574 28.0511 64.367 28.3604 66.021C29.3286 71.2925 30.0817 75.3133 27.9166 78.3121C26.729 79.5785 25.3713 80.6737 23.8823 81.5665C22.1361 82.7849 20.3022 83.8726 18.3957 84.8208C17.2259 85.5975 15.9217 86.1493 14.5497 86.4479C12.5955 86.307 10.7575 85.4668 9.37234 84.0812C7.75862 82.8171 7.96033 82.4405 5.97009 80.6789C3.68399 78.6618 3.10575 78.8635 2.25855 77.7204C1.24998 76.3757 1.27687 74.6006 1.37101 71.1983C1.28323 68.0754 1.78507 64.964 2.85024 62.0271C3.95295 59.2703 4.55809 59.4989 5.53977 56.9842C6.72316 53.9182 6.49454 51.7262 6.58868 47.9609C6.7237 44.1435 7.06938 40.3365 7.62414 36.5573C8.59237 29.6721 9.64129 30.5597 10.8785 23.3786C12.3712 14.6511 13.1242 10.2941 12.9494 7.68528C12.8822 6.7036 12.6267 3.73169 14.4286 1.91626C14.5631 1.78178 15.6658 0.692515 16.3516 1.00181C17.2795 1.41869 16.3516 3.90649 17.3871 7.72562C17.6561 8.76108 18.0729 10.2941 18.8663 10.4151C19.6598 10.5362 20.4397 8.50558 21.2331 8.6266C22.0265 8.74763 22.4838 12.0423 22.5779 13.2257C22.8673 16.4736 22.8673 19.7408 22.5779 22.9887C21.6974 28.5198 21.4987 34.138 21.9862 39.7175C22.534 43.9614 23.4291 48.1531 24.6623 52.2507Z" fill="#DEE1E9"/>
      <path d="M2.25114 77.707C3.09834 78.8635 3.67659 78.6618 5.94923 80.6655C7.93948 82.4271 7.75122 82.8037 9.36493 84.0812C10.7542 85.4609 12.5899 86.3 14.5423 86.448C15.9122 86.1429 17.2153 85.5915 18.3883 84.8208C20.29 83.8767 22.1193 82.7935 23.8615 81.5799C25.3531 80.6857 26.7112 79.5856 27.8957 78.3122C30.0474 75.3133 29.3077 71.306 28.3395 66.0345C28.0302 64.367 27.5058 62.4709 26.4703 58.6517C26.4703 58.6517 26.4703 58.5442 26.4703 58.4769C25.7172 56.7018 20.6878 54.1871 20.6878 54.1871C18.8331 53.165 16.74 52.6545 14.6229 52.7079C12.7104 52.8098 10.8409 53.3138 9.13632 54.1871C7.99114 54.7119 6.90478 55.3565 5.89544 56.1101C5.80131 56.3925 5.70718 56.6884 5.58615 56.9977C4.63137 59.5124 4.01278 59.2703 2.89663 62.0271C1.83135 64.9687 1.32952 68.0845 1.41739 71.2118C1.28292 74.6006 1.28291 76.3219 2.25114 77.707Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M14.8295 64.0441C16.1743 64.1651 18.0569 59.2837 18.7697 54.6308L18.5948 41.4252C18.4066 37.2296 18.1914 33.8004 18.0435 31.4605C17.6938 26.0815 17.5728 25.4495 17.5728 23.1768C17.5728 17.3271 15.354 3.46657 14.9102 3.34555C14.4664 3.22452 16.4836 16.453 15.8112 19.1425C14.964 22.5986 15.1119 24.5216 14.9102 29.4703C14.8883 33.2884 14.5871 37.0997 14.0092 40.8739C13.4444 43.9265 12.8662 45.3923 12.2341 49.7762C11.8752 51.804 11.7848 53.8703 11.9652 55.9217C12.1182 57.5719 12.4704 59.1974 13.0141 60.7629C13.8478 62.9011 14.2378 63.9903 14.8295 64.0441Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M22.0971 20.5122C18.1303 18.643 15.9153 18.7282 11.972 20.5122" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M24.3108 54.5808C24.768 56.1811 25.2791 57.7948 26.1397 60.9819C27.1752 64.7875 27.6996 66.6971 28.0089 68.3512C28.9771 73.6226 29.7302 77.6435 27.5652 80.6423C26.3776 81.9086 25.0198 83.0039 23.5309 83.8966C21.7847 85.115 19.9508 86.2028 18.0442 87.1509C16.8745 87.9276 15.5702 88.4794 14.1982 88.7781C12.244 88.6372 10.406 87.7969 9.0209 86.4113C7.40718 85.1472 7.60889 84.7707 5.61865 83.009C3.33255 80.9919 2.75431 81.1936 1.90711 80.0506C0.898537 78.7058 0.925432 76.9307 1.01957 73.5285C0.931786 70.4056 1.43363 67.2942 2.4988 64.3572C3.60151 61.6005 4.20665 61.8291 5.18833 59.3144C6.37172 56.2483 6.1431 54.0563 6.23724 50.291C6.37226 46.4737 6.71794 42.6667 7.2727 38.8874C8.24093 32.0023 9.28985 32.8898 10.527 25.7088C12.0197 16.9813 12.7728 12.6243 12.598 10.0154C12.5307 9.03374 12.2752 6.06183 14.0772 4.2464C14.2117 4.11192 15.3144 3.02265 16.0002 3.33195C16.9281 3.74883 16.0002 6.23663 17.0357 10.0558C17.3046 11.0912 17.7215 12.6242 18.5149 12.7453C19.3083 12.8663 20.0883 10.8357 20.8817 10.9567C21.6751 11.0778 22.1323 14.3724 22.2264 15.5558C22.5159 18.8037 22.5159 22.0709 22.2264 25.3188C21.3459 30.8499 21.1472 36.4681 21.6348 42.0476C22.1826 46.2915 23.0776 50.4833 24.3108 54.5808Z" fill="#DEE1E9"/>
      <mask id="mask2224" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="30" height="36">
        <path d="M1.81572 80.204C2.66292 81.3605 3.24116 81.1588 5.51381 83.1624C7.50406 84.9241 7.31579 85.3006 8.9295 86.5781C10.3188 87.9578 12.1545 88.797 14.1068 88.9449C15.4768 88.6398 16.7799 88.0885 17.9529 87.3178C19.8545 86.3737 21.6839 85.2904 23.426 84.0769C24.9177 83.1826 26.2758 82.0825 27.4603 80.8091C29.6119 77.8103 28.8723 73.8029 27.9041 68.5314C27.5948 66.8639 27.0703 64.9678 26.0349 61.1487C26.0349 61.1487 26.0349 61.0411 26.0349 60.9739C25.2818 59.1988 20.2524 56.6841 20.2524 56.6841C18.3977 55.662 16.3045 55.1514 14.1875 55.2048C12.275 55.3068 10.4054 55.8108 8.7009 56.6841C7.55571 57.2088 6.46935 57.8534 5.46002 58.6071C5.36589 58.8895 5.27175 59.1853 5.15073 59.4946C4.19595 62.0093 3.57735 61.7673 2.4612 64.524C1.39592 67.4656 0.8941 70.5815 0.981966 73.7088C0.84749 77.0976 0.847488 78.8189 1.81572 80.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2224)">
        <path d="M1.81572 80.204C2.66292 81.3605 3.24116 81.1588 5.51381 83.1624C7.50406 84.9241 7.31579 85.3006 8.9295 86.5781C10.3188 87.9578 12.1545 88.797 14.1068 88.9449C15.4768 88.6398 16.7799 88.0885 17.9529 87.3178C19.8545 86.3737 21.6839 85.2904 23.426 84.0769C24.9177 83.1826 26.2758 82.0825 27.4603 80.8091C29.6119 77.8103 28.8723 73.8029 27.9041 68.5314C27.5948 66.8639 27.0703 64.9678 26.0349 61.1487C26.0349 61.1487 26.0349 61.0411 26.0349 60.9739C25.2818 59.1988 20.2524 56.6841 20.2524 56.6841C18.3977 55.662 16.3045 55.1514 14.1875 55.2048C12.275 55.3068 10.4054 55.8108 8.7009 56.6841C7.55571 57.2088 6.46935 57.8534 5.46002 58.6071C5.36589 58.8895 5.27175 59.1853 5.15073 59.4946C4.19595 62.0093 3.57735 61.7673 2.4612 64.524C1.39592 67.4656 0.8941 70.5815 0.981966 73.7088C0.84749 77.0976 0.847488 78.8189 1.81572 80.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.3399 72.201C15.3203 72.201 16.8547 80.6027 16.8547 90.201H14.9317H11.8547C11.8547 80.6027 13.3596 72.201 14.3399 72.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.14 73.1986C16.0904 73.3342 18.821 67.8658 19.8547 62.6534L19.6012 47.8601C19.3281 43.16 19.0161 39.3186 18.8015 36.6974C18.2944 30.6716 18.1189 29.9635 18.1189 27.4176C18.1189 20.8646 14.9007 5.33755 14.257 5.20197C13.6134 5.06639 16.539 19.8854 15.5638 22.8983C14.335 26.7699 14.5496 28.9241 14.257 34.4678C14.2252 38.7449 13.7885 43.0146 12.9502 47.2425C12.131 50.6621 11.2924 52.3041 10.3757 57.2152C9.85508 59.4868 9.72393 61.8015 9.98558 64.0996C10.2075 65.9482 10.7183 67.7691 11.5069 69.5228C12.7162 71.9181 13.2818 73.1383 14.14 73.1986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 15.1208 4.20099)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M24.3108 58.5808C24.768 60.1811 25.2791 61.7948 26.1397 64.9819C27.1752 68.7875 27.6996 70.6971 28.0089 72.3512C28.9771 77.6226 29.7302 81.6435 27.5652 84.6423C26.3776 85.9086 25.0198 87.0039 23.5309 87.8966C21.7847 89.115 19.9508 90.2028 18.0442 91.1509C16.8745 91.9276 15.5702 92.4794 14.1982 92.7781C12.244 92.6372 10.406 91.7969 9.0209 90.4113C7.40718 89.1472 7.60889 88.7707 5.61865 87.009C3.33255 84.9919 2.75431 85.1936 1.90711 84.0506C0.898537 82.7058 0.925432 80.9307 1.01957 77.5285C0.931786 74.4056 1.43363 71.2942 2.4988 68.3572C3.60151 65.6005 4.20665 65.8291 5.18833 63.3144C6.37172 60.2483 6.1431 58.0563 6.23724 54.291C6.37226 50.4737 6.71794 46.6667 7.2727 42.8874C8.24093 36.0023 9.28985 36.8898 10.527 29.7088C12.0197 20.9813 12.7728 16.6243 12.598 14.0154C12.5307 13.0337 12.2752 10.0618 14.0772 8.2464C14.2117 8.11192 15.3144 7.02265 16.0002 7.33195C16.9281 7.74883 16.0002 10.2366 17.0357 14.0558C17.3046 15.0912 17.7215 16.6242 18.5149 16.7453C19.3083 16.8663 20.0883 14.8357 20.8817 14.9567C21.6751 15.0778 22.1323 18.3724 22.2264 19.5558C22.5159 22.8037 22.5159 26.0709 22.2264 29.3188C21.3459 34.8499 21.1472 40.4681 21.6348 46.0476C22.1826 50.2915 23.0776 54.4833 24.3108 58.5808Z" fill="#DEE1E9"/>
      <mask id="mask2324" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="58" width="30" height="36">
        <path d="M1.81572 84.204C2.66292 85.3605 3.24116 85.1588 5.51381 87.1624C7.50406 88.9241 7.31579 89.3006 8.9295 90.5781C10.3188 91.9578 12.1545 92.797 14.1068 92.9449C15.4768 92.6398 16.7799 92.0885 17.9529 91.3178C19.8545 90.3737 21.6839 89.2904 23.426 88.0769C24.9177 87.1826 26.2758 86.0825 27.4603 84.8091C29.6119 81.8103 28.8723 77.8029 27.9041 72.5314C27.5948 70.8639 27.0703 68.9678 26.0349 65.1487C26.0349 65.1487 26.0349 65.0411 26.0349 64.9739C25.2818 63.1988 20.2524 60.6841 20.2524 60.6841C18.3977 59.662 16.3045 59.1514 14.1875 59.2048C12.275 59.3068 10.4054 59.8108 8.7009 60.6841C7.55571 61.2088 6.46935 61.8534 5.46002 62.6071C5.36589 62.8895 5.27175 63.1853 5.15073 63.4946C4.19595 66.0093 3.57735 65.7673 2.4612 68.524C1.39592 71.4656 0.8941 74.5815 0.981966 77.7088C0.84749 81.0976 0.847488 82.8189 1.81572 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2324)">
        <path d="M1.81572 84.204C2.66292 85.3605 3.24116 85.1588 5.51381 87.1624C7.50406 88.9241 7.31579 89.3006 8.9295 90.5781C10.3188 91.9578 12.1545 92.797 14.1068 92.9449C15.4768 92.6398 16.7799 92.0885 17.9529 91.3178C19.8545 90.3737 21.6839 89.2904 23.426 88.0769C24.9177 87.1826 26.2758 86.0825 27.4603 84.8091C29.6119 81.8103 28.8723 77.8029 27.9041 72.5314C27.5948 70.8639 27.0703 68.9678 26.0349 65.1487C26.0349 65.1487 26.0349 65.0411 26.0349 64.9739C25.2818 63.1988 20.2524 60.6841 20.2524 60.6841C18.3977 59.662 16.3045 59.1514 14.1875 59.2048C12.275 59.3068 10.4054 59.8108 8.7009 60.6841C7.55571 61.2088 6.46935 61.8534 5.46002 62.6071C5.36589 62.8895 5.27175 63.1853 5.15073 63.4946C4.19595 66.0093 3.57735 65.7673 2.4612 68.524C1.39592 71.4656 0.8941 74.5815 0.981966 77.7088C0.84749 81.0976 0.847488 82.8189 1.81572 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.3399 76.201C15.3203 76.201 16.8547 84.6027 16.8547 94.201H14.9317H11.8547C11.8547 84.6027 13.3596 76.201 14.3399 76.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.14 77.1986C16.0904 77.3342 18.821 71.8658 19.8547 66.6534L19.6012 51.8601C19.3281 47.16 19.0161 43.3186 18.8015 40.6974C18.2944 34.6716 18.1189 33.9635 18.1189 31.4176C18.1189 24.8646 14.9007 9.33755 14.257 9.20197C13.6134 9.06639 16.539 23.8854 15.5638 26.8983C14.335 30.7699 14.5496 32.9241 14.257 38.4678C14.2252 42.7449 13.7885 47.0146 12.9502 51.2425C12.131 54.6621 11.2924 56.3041 10.3757 61.2152C9.85508 63.4868 9.72393 65.8015 9.98558 68.0996C10.2075 69.9482 10.7183 71.7691 11.5069 73.5228C12.7162 75.9181 13.2818 77.1383 14.14 77.1986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 15.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2324)">
        <path d="M15.1208 0.200989L14.2979 3.94009L11.2641 1.60472L13.0372 4.99799L9.212 5.1591L12.7514 6.61879L9.9247 9.20099L13.5743 8.04409L13.0687 11.8391L15.1208 8.60699L17.173 11.8391L16.6674 8.04409L20.317 9.20099L17.4903 6.61879L21.0297 5.1591L17.2045 4.99799L18.9776 1.60472L15.9437 3.94009L15.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2324" x="9.01201" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M24.3108 58.5808C24.768 60.1811 25.2791 61.7948 26.1397 64.9819C27.1752 68.7875 27.6996 70.6971 28.0089 72.3512C28.9771 77.6226 29.7302 81.6435 27.5652 84.6423C26.3776 85.9086 25.0198 87.0039 23.5309 87.8966C21.7847 89.115 19.9508 90.2028 18.0442 91.1509C16.8745 91.9276 15.5702 92.4794 14.1982 92.7781C12.244 92.6372 10.406 91.7969 9.0209 90.4113C7.40718 89.1472 7.60889 88.7707 5.61865 87.009C3.33255 84.9919 2.75431 85.1936 1.90711 84.0506C0.898537 82.7058 0.925432 80.9307 1.01957 77.5285C0.931786 74.4056 1.43363 71.2942 2.4988 68.3572C3.60151 65.6005 4.20665 65.8291 5.18833 63.3144C6.37172 60.2483 6.1431 58.0563 6.23724 54.291C6.37226 50.4737 6.71794 46.6667 7.2727 42.8874C8.24093 36.0023 9.28985 36.8898 10.527 29.7088C12.0197 20.9813 12.7728 16.6243 12.598 14.0154C12.5307 13.0337 12.2752 10.0618 14.0772 8.2464C14.2117 8.11192 15.3144 7.02265 16.0002 7.33195C16.9281 7.74883 16.0002 10.2366 17.0357 14.0558C17.3046 15.0912 17.7215 16.6242 18.5149 16.7453C19.3083 16.8663 20.0883 14.8357 20.8817 14.9567C21.6751 15.0778 22.1323 18.3724 22.2264 19.5558C22.5159 22.8037 22.5159 26.0709 22.2264 29.3188C21.3459 34.8499 21.1472 40.4681 21.6348 46.0476C22.1826 50.2915 23.0776 54.4833 24.3108 58.5808Z" fill="#DEE1E9"/>
      <mask id="mask2324" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="58" width="30" height="36">
        <path d="M1.81572 84.204C2.66292 85.3605 3.24116 85.1588 5.51381 87.1624C7.50406 88.9241 7.31579 89.3006 8.9295 90.5781C10.3188 91.9578 12.1545 92.797 14.1068 92.9449C15.4768 92.6398 16.7799 92.0885 17.9529 91.3178C19.8545 90.3737 21.6839 89.2904 23.426 88.0769C24.9177 87.1826 26.2758 86.0825 27.4603 84.8091C29.6119 81.8103 28.8723 77.8029 27.9041 72.5314C27.5948 70.8639 27.0703 68.9678 26.0349 65.1487C26.0349 65.1487 26.0349 65.0411 26.0349 64.9739C25.2818 63.1988 20.2524 60.6841 20.2524 60.6841C18.3977 59.662 16.3045 59.1514 14.1875 59.2048C12.275 59.3068 10.4054 59.8108 8.7009 60.6841C7.55571 61.2088 6.46935 61.8534 5.46002 62.6071C5.36589 62.8895 5.27175 63.1853 5.15073 63.4946C4.19595 66.0093 3.57735 65.7673 2.4612 68.524C1.39592 71.4656 0.8941 74.5815 0.981966 77.7088C0.84749 81.0976 0.847488 82.8189 1.81572 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2324)">
        <path d="M1.81572 84.204C2.66292 85.3605 3.24116 85.1588 5.51381 87.1624C7.50406 88.9241 7.31579 89.3006 8.9295 90.5781C10.3188 91.9578 12.1545 92.797 14.1068 92.9449C15.4768 92.6398 16.7799 92.0885 17.9529 91.3178C19.8545 90.3737 21.6839 89.2904 23.426 88.0769C24.9177 87.1826 26.2758 86.0825 27.4603 84.8091C29.6119 81.8103 28.8723 77.8029 27.9041 72.5314C27.5948 70.8639 27.0703 68.9678 26.0349 65.1487C26.0349 65.1487 26.0349 65.0411 26.0349 64.9739C25.2818 63.1988 20.2524 60.6841 20.2524 60.6841C18.3977 59.662 16.3045 59.1514 14.1875 59.2048C12.275 59.3068 10.4054 59.8108 8.7009 60.6841C7.55571 61.2088 6.46935 61.8534 5.46002 62.6071C5.36589 62.8895 5.27175 63.1853 5.15073 63.4946C4.19595 66.0093 3.57735 65.7673 2.4612 68.524C1.39592 71.4656 0.8941 74.5815 0.981966 77.7088C0.84749 81.0976 0.847488 82.8189 1.81572 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.3399 76.201C15.3203 76.201 16.8547 84.6027 16.8547 94.201H14.9317H11.8547C11.8547 84.6027 13.3596 76.201 14.3399 76.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.14 77.1986C16.0904 77.3342 18.821 71.8658 19.8547 66.6534L19.6012 51.8601C19.3281 47.16 19.0161 43.3186 18.8015 40.6974C18.2944 34.6716 18.1189 33.9635 18.1189 31.4176C18.1189 24.8646 14.9007 9.33755 14.257 9.20197C13.6134 9.06639 16.539 23.8854 15.5638 26.8983C14.335 30.7699 14.5496 32.9241 14.257 38.4678C14.2252 42.7449 13.7885 47.0146 12.9502 51.2425C12.131 54.6621 11.2924 56.3041 10.3757 61.2152C9.85508 63.4868 9.72393 65.8015 9.98558 68.0996C10.2075 69.9482 10.7183 71.7691 11.5069 73.5228C12.7162 75.9181 13.2818 77.1383 14.14 77.1986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 15.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2324)">
        <path d="M15.1208 0.200989L14.2979 3.94009L11.2641 1.60472L13.0372 4.99799L9.212 5.1591L12.7514 6.61879L9.9247 9.20099L13.5743 8.04409L13.0687 11.8391L15.1208 8.60699L17.173 11.8391L16.6674 8.04409L20.317 9.20099L17.4903 6.61879L21.0297 5.1591L17.2045 4.99799L18.9776 1.60472L15.9437 3.94009L15.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2324" x="9.01201" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M24.3108 54.5807C24.768 56.181 25.2791 57.7947 26.1397 60.9818C27.1752 64.7875 27.6996 66.697 28.0089 68.3511C28.9771 73.6226 29.7302 77.6434 27.5652 80.6422C26.3776 81.9086 25.0198 83.0038 23.5309 83.8965C21.7847 85.115 19.9508 86.2027 18.0442 87.1509C16.8745 87.9276 15.5702 88.4794 14.1982 88.778C12.244 88.6371 10.406 87.7969 9.0209 86.4112C7.40718 85.1472 7.60889 84.7706 5.61865 83.009C3.33255 80.9918 2.75431 81.1936 1.90711 80.0505C0.898537 78.7058 0.925432 76.9307 1.01957 73.5284C0.931786 70.4055 1.43363 67.2941 2.4988 64.3572C3.60151 61.6004 4.20665 61.829 5.18833 59.3143C6.37172 56.2482 6.1431 54.0563 6.23724 50.2909C6.37226 46.4736 6.71794 42.6666 7.2727 38.8874C8.24093 32.0022 9.28985 32.8897 10.527 25.7087C12.0197 16.9812 12.7728 12.6242 12.598 10.0154C12.5307 9.03368 12.2752 6.06176 14.0772 4.24634C14.2117 4.11186 15.3144 3.02259 16.0002 3.33189C16.9281 3.74876 16.0002 6.23657 17.0357 10.0557C17.3046 11.0912 17.7215 12.6242 18.5149 12.7452C19.3083 12.8662 20.0883 10.8357 20.8817 10.9567C21.6751 11.0777 22.1323 14.3724 22.2264 15.5558C22.5159 18.8036 22.5159 22.0709 22.2264 25.3187C21.3459 30.8498 21.1472 36.4681 21.6348 42.0476C22.1826 46.2914 23.0776 50.4832 24.3108 54.5807Z" fill="#DEE1E9"/>
      <circle r="4" transform="matrix(-1 0 0 1 15.1208 4.20093)" fill="rgba(243, 42, 102, 0.6)"/>
      <mask id="mask2524" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="30" height="36">
        <path d="M1.81572 80.2038C2.66292 81.3603 3.24116 81.1586 5.51381 83.1623C7.50406 84.924 7.31579 85.3005 8.9295 86.578C10.3188 87.9577 12.1545 88.7968 14.1068 88.9448C15.4768 88.6397 16.7799 88.0884 17.9529 87.3176C19.8545 86.3735 21.6839 85.2903 23.426 84.0768C24.9177 83.1825 26.2758 82.0824 27.4603 80.809C29.6119 77.8102 28.8723 73.8028 27.9041 68.5313C27.5948 66.8638 27.0703 64.9677 26.0349 61.1486C26.0349 61.1486 26.0349 61.041 26.0349 60.9738C25.2818 59.1987 20.2524 56.684 20.2524 56.684C18.3977 55.6618 16.3045 55.1513 14.1875 55.2047C12.275 55.3066 10.4054 55.8107 8.7009 56.684C7.55571 57.2087 6.46935 57.8533 5.46002 58.607C5.36589 58.8894 5.27175 59.1852 5.15073 59.4945C4.19595 62.0092 3.57735 61.7672 2.4612 64.5239C1.39592 67.4655 0.8941 70.5813 0.981966 73.7086C0.84749 77.0974 0.847488 78.8187 1.81572 80.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2524)">
        <path d="M1.81572 80.2038C2.66292 81.3603 3.24116 81.1586 5.51381 83.1623C7.50406 84.924 7.31579 85.3005 8.9295 86.578C10.3188 87.9577 12.1545 88.7968 14.1068 88.9448C15.4768 88.6397 16.7799 88.0884 17.9529 87.3176C19.8545 86.3735 21.6839 85.2903 23.426 84.0768C24.9177 83.1825 26.2758 82.0824 27.4603 80.809C29.6119 77.8102 28.8723 73.8028 27.9041 68.5313C27.5948 66.8638 27.0703 64.9677 26.0349 61.1486C26.0349 61.1486 26.0349 61.041 26.0349 60.9738C25.2818 59.1987 20.2524 56.684 20.2524 56.684C18.3977 55.6618 16.3045 55.1513 14.1875 55.2047C12.275 55.3066 10.4054 55.8107 8.7009 56.684C7.55571 57.2087 6.46935 57.8533 5.46002 58.607C5.36589 58.8894 5.27175 59.1852 5.15073 59.4945C4.19595 62.0092 3.57735 61.7672 2.4612 64.5239C1.39592 67.4655 0.8941 70.5813 0.981966 73.7086C0.84749 77.0974 0.847488 78.8187 1.81572 80.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.6061 79.2009C15.5864 79.2009 17.1208 84.3353 17.1208 90.2009H15.1978H12.1208C12.1208 84.3353 13.6257 79.2009 14.6061 79.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.4061 79.1983C16.3565 79.3438 19.0871 73.4733 20.1208 67.8778L19.8673 51.9967C19.5942 46.951 19.2822 42.8271 19.0676 40.0131C18.5605 33.5443 18.385 32.7842 18.385 30.0511C18.385 23.0162 15.1668 6.34746 14.5231 6.20191C13.8795 6.05636 16.8051 21.965 15.8299 25.1994C14.6011 29.3557 14.8157 31.6683 14.5231 37.6196C14.4913 42.2113 14.0546 46.7948 13.2163 51.3337C12.3972 55.0047 11.5585 56.7675 10.6418 62.0396C10.1212 64.4783 9.99004 66.9632 10.2517 69.4303C10.4736 71.4147 10.9844 73.3696 11.773 75.2523C12.9823 77.8236 13.5479 79.1336 14.4061 79.1983Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2624" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="87">
        <path d="M24.7251 51.3703C25.1824 52.9706 25.6934 54.5843 26.554 57.7713C27.5895 61.577 28.1139 63.4866 28.4232 65.1406C29.3915 70.4121 30.1445 74.4329 27.9795 77.4318C26.7919 78.6981 25.4341 79.7934 23.9452 80.6861C22.199 81.9045 20.3651 82.9923 18.4585 83.9404C17.2888 84.7171 15.9845 85.2689 14.6125 85.5676C12.6584 85.4267 10.8204 84.5864 9.4352 83.2008C7.82149 81.9367 8.0232 81.5602 6.03296 79.7985C3.74686 77.7814 3.16861 77.9831 2.32141 76.8401C1.31284 75.4953 1.33974 73.7202 1.43387 70.318C1.34609 67.1951 1.84794 64.0836 2.91311 61.1467C4.01581 58.3899 4.62096 58.6186 5.60263 56.1039C6.78602 53.0378 6.55741 50.8458 6.65154 47.0805C6.78657 43.2631 7.13225 39.4562 7.68701 35.6769C8.65524 28.7917 9.70415 29.6793 10.9413 22.4983C12.434 13.7708 13.1871 9.41374 13.0123 6.8049C12.945 5.82323 12.6895 2.85132 14.4915 1.03589C14.626 0.901411 15.7287 -0.187856 16.4145 0.121439C17.3424 0.538315 16.4145 3.02612 17.45 6.84524C17.7189 7.88071 18.1358 9.41374 18.9292 9.53477C19.7226 9.6558 20.5026 7.6252 21.296 7.74623C22.0894 7.86726 22.5466 11.1619 22.6408 12.3453C22.9302 15.5932 22.9302 18.8604 22.6408 22.1083C21.7603 27.6394 21.5615 33.2576 22.0491 38.8371C22.5969 43.081 23.4919 47.2728 24.7251 51.3703Z" fill="#DEE1E9"/>
        <path d="M2.31401 76.8266C3.16121 77.9831 3.73945 77.7814 6.0121 79.7851C8.00235 81.5468 7.81408 81.9233 9.4278 83.2008C10.8171 84.5805 12.6528 85.4196 14.6051 85.5676C15.9751 85.2625 17.2782 84.7112 18.4511 83.9404C20.3528 82.9963 22.1822 81.9131 23.9243 80.6996C25.416 79.8053 26.7741 78.7052 27.9586 77.4318C30.1102 74.433 29.3706 70.4256 28.4024 65.1541C28.0931 63.4866 27.5686 61.5905 26.5332 57.7714C26.5332 57.7714 26.5332 57.6638 26.5332 57.5966C25.7801 55.8215 20.7507 53.3068 20.7507 53.3068C18.896 52.2846 16.8028 51.7741 14.6858 51.8275C12.7733 51.9294 10.9037 52.4335 9.19919 53.3068C8.054 53.8315 6.96765 54.4761 5.95831 55.2298C5.86418 55.5122 5.77004 55.808 5.64902 56.1173C4.69424 58.632 4.07565 58.39 2.95949 61.1467C1.89421 64.0883 1.39239 67.2041 1.48026 70.3314C1.34578 73.7202 1.34578 75.4415 2.31401 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2624)">
        <path d="M24.7251 51.3703C25.1824 52.9706 25.6934 54.5843 26.554 57.7713C27.5895 61.577 28.1139 63.4866 28.4232 65.1406C29.3915 70.4121 30.1445 74.4329 27.9795 77.4318C26.7919 78.6981 25.4341 79.7934 23.9452 80.6861C22.199 81.9045 20.3651 82.9923 18.4585 83.9404C17.2888 84.7171 15.9845 85.2689 14.6125 85.5676C12.6584 85.4267 10.8204 84.5864 9.4352 83.2008C7.82149 81.9367 8.0232 81.5602 6.03296 79.7985C3.74686 77.7814 3.16861 77.9831 2.32141 76.8401C1.31284 75.4953 1.33974 73.7202 1.43387 70.318C1.34609 67.1951 1.84794 64.0836 2.91311 61.1467C4.01581 58.3899 4.62096 58.6186 5.60263 56.1039C6.78602 53.0378 6.55741 50.8458 6.65154 47.0805C6.78657 43.2631 7.13225 39.4562 7.68701 35.6769C8.65524 28.7917 9.70415 29.6793 10.9413 22.4983C12.434 13.7708 13.1871 9.41374 13.0123 6.8049C12.945 5.82323 12.6895 2.85132 14.4915 1.03589C14.626 0.901411 15.7287 -0.187856 16.4145 0.121439C17.3424 0.538315 16.4145 3.02612 17.45 6.84524C17.7189 7.88071 18.1358 9.41374 18.9292 9.53477C19.7226 9.6558 20.5026 7.6252 21.296 7.74623C22.0894 7.86726 22.5466 11.1619 22.6408 12.3453C22.9302 15.5932 22.9302 18.8604 22.6408 22.1083C21.7603 27.6394 21.5615 33.2576 22.0491 38.8371C22.5969 43.081 23.4919 47.2728 24.7251 51.3703Z" fill="#DEE1E9"/>
        <path d="M2.31401 76.8266C3.16121 77.9831 3.73945 77.7814 6.0121 79.7851C8.00235 81.5468 7.81408 81.9233 9.4278 83.2008C10.8171 84.5805 12.6528 85.4196 14.6051 85.5676C15.9751 85.2625 17.2782 84.7112 18.4511 83.9404C20.3528 82.9963 22.1822 81.9131 23.9243 80.6996C25.416 79.8053 26.7741 78.7052 27.9586 77.4318C30.1102 74.433 29.3706 70.4256 28.4024 65.1541C28.0931 63.4866 27.5686 61.5905 26.5332 57.7714C26.5332 57.7714 26.5332 57.6638 26.5332 57.5966C25.7801 55.8215 20.7507 53.3068 20.7507 53.3068C18.896 52.2846 16.8028 51.7741 14.6858 51.8275C12.7733 51.9294 10.9037 52.4335 9.19919 53.3068C8.054 53.8315 6.96765 54.4761 5.95831 55.2298C5.86418 55.5122 5.77004 55.808 5.64902 56.1173C4.69424 58.632 4.07565 58.39 2.95949 61.1467C1.89421 64.0883 1.39239 67.2041 1.48026 70.3314C1.34578 73.7202 1.34578 75.4415 2.31401 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.5978 55.5247H1.67667C3.83873 50.1549 4.19573 46.8947 3.8992 41.1564C3.88802 40.94 4.13739 40.8054 4.32761 40.932C5.33169 41.6 7.68988 43.4161 8.26153 46.0577C8.954 49.2577 10.9572 50.0348 11.1698 50.1089C11.1828 50.1134 11.1944 50.1179 11.2067 50.1236L12.3897 50.6703C12.5342 50.7371 12.5928 50.8996 12.5206 51.0332L11.9916 52.0109C11.9469 52.0936 11.951 52.1917 12.0024 52.271L13.8488 55.1151C13.9658 55.2954 13.8253 55.5247 13.5978 55.5247Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.67275 42.0459C5.73373 42.8938 7.14171 44.3307 7.54413 46.1903C8.28734 49.6248 10.4633 50.5809 10.8963 50.7361L11.6699 51.0936L11.3373 51.7084C11.1806 51.9979 11.1949 52.3413 11.3751 52.6189L12.8225 54.8485H2.71999C4.49672 50.1838 4.87272 46.9881 4.67275 42.0459ZM10.8796 50.7284C10.8794 50.7283 10.8797 50.7285 10.8796 50.7284V50.7284ZM4.75508 40.3832C4.02622 39.8983 3.13049 40.4547 3.16842 41.1886C3.46123 46.8548 3.11202 50.0209 0.990997 55.2889L0.623778 56.2009H13.5979C14.3941 56.2009 14.8859 55.3982 14.4762 54.7672L12.7539 52.114L13.175 51.3356C13.428 50.8679 13.2229 50.2992 12.7169 50.0654L11.534 49.5188C11.4933 49.5 11.4574 49.4864 11.4279 49.4761C11.3913 49.4634 9.61049 48.8432 8.97901 45.9251C8.34951 43.0161 5.78613 41.0691 4.75508 40.3832Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask124" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="87">
        <path d="M24.7251 51.3703C25.1824 52.9706 25.6934 54.5843 26.554 57.7713C27.5895 61.577 28.1139 63.4866 28.4232 65.1406C29.3915 70.4121 30.1445 74.4329 27.9795 77.4318C26.7919 78.6981 25.4341 79.7934 23.9452 80.6861C22.199 81.9045 20.3651 82.9923 18.4585 83.9404C17.2888 84.7171 15.9845 85.2689 14.6125 85.5676C12.6584 85.4267 10.8204 84.5864 9.4352 83.2008C7.82149 81.9367 8.0232 81.5602 6.03296 79.7985C3.74686 77.7814 3.16861 77.9831 2.32141 76.8401C1.31284 75.4953 1.33974 73.7202 1.43387 70.318C1.34609 67.1951 1.84794 64.0836 2.91311 61.1467C4.01581 58.3899 4.62096 58.6186 5.60263 56.1039C6.78602 53.0378 6.55741 50.8458 6.65154 47.0805C6.78657 43.2631 7.13225 39.4562 7.68701 35.6769C8.65524 28.7917 9.70415 29.6793 10.9413 22.4983C12.434 13.7708 13.1871 9.41374 13.0123 6.8049C12.945 5.82323 12.6895 2.85132 14.4915 1.03589C14.626 0.901411 15.7287 -0.187856 16.4145 0.121439C17.3424 0.538315 16.4145 3.02612 17.45 6.84524C17.7189 7.88071 18.1358 9.41374 18.9292 9.53477C19.7226 9.6558 20.5026 7.6252 21.296 7.74623C22.0894 7.86726 22.5466 11.1619 22.6408 12.3453C22.9302 15.5932 22.9302 18.8604 22.6408 22.1083C21.7603 27.6394 21.5615 33.2576 22.0491 38.8371C22.5969 43.081 23.4919 47.2728 24.7251 51.3703Z" fill="#DEE1E9"/>
        <path d="M2.31401 76.8266C3.16121 77.9831 3.73945 77.7814 6.0121 79.7851C8.00235 81.5468 7.81408 81.9233 9.4278 83.2008C10.8171 84.5805 12.6528 85.4196 14.6051 85.5676C15.9751 85.2625 17.2782 84.7112 18.4511 83.9404C20.3528 82.9963 22.1822 81.9131 23.9243 80.6996C25.416 79.8053 26.7741 78.7052 27.9586 77.4318C30.1102 74.433 29.3706 70.4256 28.4024 65.1541C28.0931 63.4866 27.5686 61.5905 26.5332 57.7714C26.5332 57.7714 26.5332 57.6638 26.5332 57.5966C25.7801 55.8215 20.7507 53.3068 20.7507 53.3068C18.896 52.2846 16.8028 51.7741 14.6858 51.8275C12.7733 51.9294 10.9037 52.4335 9.19919 53.3068C8.054 53.8315 6.96765 54.4761 5.95831 55.2298C5.86418 55.5122 5.77004 55.808 5.64902 56.1173C4.69424 58.632 4.07565 58.39 2.95949 61.1467C1.89421 64.0883 1.39239 67.2041 1.48026 70.3314C1.34578 73.7202 1.34578 75.4415 2.31401 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask124)">
        <path d="M24.7251 51.3703C25.1824 52.9706 25.6934 54.5843 26.554 57.7713C27.5895 61.577 28.1139 63.4866 28.4232 65.1406C29.3915 70.4121 30.1445 74.4329 27.9795 77.4318C26.7919 78.6981 25.4341 79.7934 23.9452 80.6861C22.199 81.9045 20.3651 82.9923 18.4585 83.9404C17.2888 84.7171 15.9845 85.2689 14.6125 85.5676C12.6584 85.4267 10.8204 84.5864 9.4352 83.2008C7.82149 81.9367 8.0232 81.5602 6.03296 79.7985C3.74686 77.7814 3.16861 77.9831 2.32141 76.8401C1.31284 75.4953 1.33974 73.7202 1.43387 70.318C1.34609 67.1951 1.84794 64.0836 2.91311 61.1467C4.01581 58.3899 4.62096 58.6186 5.60263 56.1039C6.78602 53.0378 6.55741 50.8458 6.65154 47.0805C6.78657 43.2631 7.13225 39.4562 7.68701 35.6769C8.65524 28.7917 9.70415 29.6793 10.9413 22.4983C12.434 13.7708 13.1871 9.41374 13.0123 6.8049C12.945 5.82323 12.6895 2.85132 14.4915 1.03589C14.626 0.901411 15.7287 -0.187856 16.4145 0.121439C17.3424 0.538315 16.4145 3.02612 17.45 6.84524C17.7189 7.88071 18.1358 9.41374 18.9292 9.53477C19.7226 9.6558 20.5026 7.6252 21.296 7.74623C22.0894 7.86726 22.5466 11.1619 22.6408 12.3453C22.9302 15.5932 22.9302 18.8604 22.6408 22.1083C21.7603 27.6394 21.5615 33.2576 22.0491 38.8371C22.5969 43.081 23.4919 47.2728 24.7251 51.3703Z" fill="#DEE1E9"/>
        <path d="M2.31401 76.8266C3.16121 77.9831 3.73945 77.7814 6.0121 79.7851C8.00235 81.5468 7.81408 81.9233 9.4278 83.2008C10.8171 84.5805 12.6528 85.4196 14.6051 85.5676C15.9751 85.2625 17.2782 84.7112 18.4511 83.9404C20.3528 82.9963 22.1822 81.9131 23.9243 80.6996C25.416 79.8053 26.7741 78.7052 27.9586 77.4318C30.1102 74.433 29.3706 70.4256 28.4024 65.1541C28.0931 63.4866 27.5686 61.5905 26.5332 57.7714C26.5332 57.7714 26.5332 57.6638 26.5332 57.5966C25.7801 55.8215 20.7507 53.3068 20.7507 53.3068C18.896 52.2846 16.8028 51.7741 14.6858 51.8275C12.7733 51.9294 10.9037 52.4335 9.19919 53.3068C8.054 53.8315 6.96765 54.4761 5.95831 55.2298C5.86418 55.5122 5.77004 55.808 5.64902 56.1173C4.69424 58.632 4.07565 58.39 2.95949 61.1467C1.89421 64.0883 1.39239 67.2041 1.48026 70.3314C1.34578 73.7202 1.34578 75.4415 2.31401 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.9227 54.5315H3.77592C5.25343 50.561 5.49741 48.1504 5.29476 43.9074C5.28712 43.7474 5.45754 43.6479 5.58753 43.7415C6.2737 44.2354 7.88526 45.5782 8.27592 47.5315C8.74913 49.8976 10.1181 50.4722 10.2634 50.527C10.2722 50.5303 10.2802 50.5337 10.2886 50.5379L11.097 50.9421C11.1958 50.9915 11.2359 51.1116 11.1865 51.2104L10.825 51.9333C10.7944 51.9945 10.7972 52.067 10.8324 52.1257L12.0942 54.2286C12.1742 54.3619 12.0781 54.5315 11.9227 54.5315Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.82339 44.5651C6.54845 45.1921 7.51064 46.2545 7.78565 47.6295C8.29354 50.169 9.78057 50.876 10.0765 50.9908L10.6051 51.2551L10.3778 51.7097C10.2708 51.9238 10.2805 52.1777 10.4037 52.3829L11.3928 54.0315H4.4889C5.70309 50.5824 5.96005 48.2194 5.82339 44.5651ZM10.065 50.9851C10.0649 50.985 10.0651 50.9851 10.065 50.9851V50.9851ZM5.87965 43.3357C5.38156 42.9771 4.76943 43.3885 4.79535 43.9312C4.99545 48.1208 4.75681 50.4619 3.30733 54.3571L3.05638 55.0315H11.9227C12.4668 55.0315 12.8029 54.4379 12.5229 53.9713L11.3459 52.0096L11.6337 51.434C11.8066 51.0882 11.6664 50.6677 11.3207 50.4948L10.5123 50.0906C10.4844 50.0767 10.4599 50.0667 10.4398 50.0591C10.4148 50.0497 9.19777 49.5911 8.76623 47.4334C8.33604 45.2825 6.58425 43.8428 5.87965 43.3357Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M24.5769 51.5807C25.0342 53.181 25.5452 54.7947 26.4058 57.9818C27.4413 61.7875 27.9657 63.697 28.275 65.3511C29.2433 70.6226 29.9963 74.6434 27.8313 77.6422C26.6437 78.9086 25.2859 80.0038 23.797 80.8965C22.0508 82.115 20.2169 83.2027 18.3104 84.1509C17.1406 84.9276 15.8363 85.4794 14.4643 85.778C12.5102 85.6371 10.6722 84.7969 9.28701 83.4112C7.67329 82.1472 7.87501 81.7706 5.88476 80.009C3.59867 77.9918 3.02042 78.1936 2.17322 77.0505C1.16465 75.7058 1.19155 73.9307 1.28568 70.5284C1.1979 67.4055 1.69975 64.2941 2.76492 61.3572C3.86762 58.6004 4.47276 58.829 5.45444 56.3143C6.63783 53.2482 6.40922 51.0563 6.50335 47.2909C6.63837 43.4736 6.98405 39.6666 7.53882 35.8874C8.50705 29.0022 9.55596 29.8897 10.7931 22.7087C12.2858 13.9812 13.0389 9.62419 12.8641 7.01535C12.7968 6.03368 12.5413 3.06176 14.3433 1.24634C14.4778 1.11186 15.5805 0.0225935 16.2663 0.331889C17.1942 0.748765 16.2663 3.23657 17.3018 7.05569C17.5707 8.09116 17.9876 9.62419 18.781 9.74522C19.5744 9.86625 20.3544 7.83565 21.1478 7.95668C21.9412 8.07771 22.3984 11.3724 22.4926 12.5558C22.782 15.8036 22.782 19.0709 22.4926 22.3187C21.6121 27.8498 21.4133 33.4681 21.9009 39.0476C22.4487 43.2914 23.3437 47.4832 24.5769 51.5807Z" fill="#DEE1E9"/>
      <mask id="mask224" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="30" height="36">
        <path d="M2.08183 77.2038C2.92903 78.3603 3.50728 78.1586 5.77992 80.1623C7.77017 81.924 7.58191 82.3005 9.19562 83.578C10.5849 84.9577 12.4206 85.7968 14.3729 85.9448C15.7429 85.6397 17.046 85.0884 18.219 84.3176C20.1206 83.3735 21.95 82.2903 23.6921 81.0768C25.1838 80.1825 26.5419 79.0824 27.7264 77.809C29.878 74.8102 29.1384 70.8028 28.1702 65.5313C27.8609 63.8638 27.3364 61.9677 26.301 58.1486C26.301 58.1486 26.301 58.041 26.301 57.9738C25.5479 56.1987 20.5185 53.684 20.5185 53.684C18.6638 52.6618 16.5707 52.1513 14.4536 52.2047C12.5411 52.3066 10.6716 52.8107 8.96701 53.684C7.82183 54.2087 6.73547 54.8533 5.72613 55.607C5.632 55.8894 5.53787 56.1852 5.41684 56.4945C4.46206 59.0092 3.84347 58.7672 2.72732 61.5239C1.66204 64.4655 1.16021 67.5813 1.24808 70.7086C1.1136 74.0974 1.1136 75.8187 2.08183 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask224)">
        <path d="M2.08183 77.2038C2.92903 78.3603 3.50728 78.1586 5.77992 80.1623C7.77017 81.924 7.58191 82.3005 9.19562 83.578C10.5849 84.9577 12.4206 85.7968 14.3729 85.9448C15.7429 85.6397 17.046 85.0884 18.219 84.3176C20.1206 83.3735 21.95 82.2903 23.6921 81.0768C25.1838 80.1825 26.5419 79.0824 27.7264 77.809C29.878 74.8102 29.1384 70.8028 28.1702 65.5313C27.8609 63.8638 27.3364 61.9677 26.301 58.1486C26.301 58.1486 26.301 58.041 26.301 57.9738C25.5479 56.1987 20.5185 53.684 20.5185 53.684C18.6638 52.6618 16.5707 52.1513 14.4536 52.2047C12.5411 52.3066 10.6716 52.8107 8.96701 53.684C7.82183 54.2087 6.73547 54.8533 5.72613 55.607C5.632 55.8894 5.53787 56.1852 5.41684 56.4945C4.46206 59.0092 3.84347 58.7672 2.72732 61.5239C1.66204 64.4655 1.16021 67.5813 1.24808 70.7086C1.1136 74.0974 1.1136 75.8187 2.08183 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.6001 69.2009C15.9727 69.2009 18.1208 77.6026 18.1208 87.2009H15.4285H11.1208C11.1208 77.6026 13.2276 69.2009 14.6001 69.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.4061 70.1985C16.3565 70.334 19.0871 64.8656 20.1208 59.6533L19.8673 44.86C19.5942 40.1599 19.2822 36.3184 19.0676 33.6972C18.5605 27.6714 18.385 26.9634 18.385 24.4175C18.385 17.8645 15.1668 2.33743 14.5231 2.20184C13.8795 2.06626 16.8051 16.8853 15.8299 19.8982C14.6011 23.7697 14.8157 25.9239 14.5231 31.4677C14.4913 35.7448 14.0546 40.0144 13.2163 44.2424C12.3972 47.662 11.5585 49.304 10.6418 54.215C10.1212 56.4867 9.99004 58.8014 10.2517 61.0995C10.4736 62.948 10.9844 64.769 11.773 66.5227C12.9823 68.918 13.5479 70.1382 14.4061 70.1985Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M24.5769 51.5807C25.0342 53.181 25.5452 54.7947 26.4058 57.9818C27.4413 61.7875 27.9657 63.697 28.275 65.3511C29.2433 70.6226 29.9963 74.6434 27.8313 77.6422C26.6437 78.9086 25.2859 80.0038 23.797 80.8965C22.0508 82.115 20.2169 83.2027 18.3104 84.1509C17.1406 84.9276 15.8363 85.4794 14.4643 85.778C12.5102 85.6371 10.6722 84.7969 9.28701 83.4112C7.67329 82.1472 7.87501 81.7706 5.88476 80.009C3.59867 77.9918 3.02042 78.1936 2.17322 77.0505C1.16465 75.7058 1.19155 73.9307 1.28568 70.5284C1.1979 67.4055 1.69975 64.2941 2.76492 61.3572C3.86762 58.6004 4.47276 58.829 5.45444 56.3143C6.63783 53.2482 6.40922 51.0563 6.50335 47.2909C6.63837 43.4736 6.98405 39.6666 7.53882 35.8874C8.50705 29.0022 9.55596 29.8897 10.7931 22.7087C12.2858 13.9812 13.0389 9.62419 12.8641 7.01535C12.7968 6.03368 12.5413 3.06176 14.3433 1.24634C14.4778 1.11186 15.5805 0.0225935 16.2663 0.331889C17.1942 0.748765 16.2663 3.23657 17.3018 7.05569C17.5707 8.09116 17.9876 9.62419 18.781 9.74522C19.5744 9.86625 20.3544 7.83565 21.1478 7.95668C21.9412 8.07771 22.3984 11.3724 22.4926 12.5558C22.782 15.8036 22.782 19.0709 22.4926 22.3187C21.6121 27.8498 21.4133 33.4681 21.9009 39.0476C22.4487 43.2914 23.3437 47.4832 24.5769 51.5807Z" fill="#DEE1E9"/>
      <mask id="mask324" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="30" height="36">
        <path d="M2.08183 77.2038C2.92903 78.3603 3.50728 78.1586 5.77992 80.1623C7.77017 81.924 7.58191 82.3005 9.19562 83.578C10.5849 84.9577 12.4206 85.7968 14.3729 85.9448C15.7429 85.6397 17.046 85.0884 18.219 84.3176C20.1206 83.3735 21.95 82.2903 23.6921 81.0768C25.1838 80.1825 26.5419 79.0824 27.7264 77.809C29.878 74.8102 29.1384 70.8028 28.1702 65.5313C27.8609 63.8638 27.3364 61.9677 26.301 58.1486C26.301 58.1486 26.301 58.041 26.301 57.9738C25.5479 56.1987 20.5185 53.684 20.5185 53.684C18.6638 52.6618 16.5707 52.1513 14.4536 52.2047C12.5411 52.3066 10.6716 52.8107 8.96701 53.684C7.82183 54.2087 6.73547 54.8533 5.72613 55.607C5.632 55.8894 5.53787 56.1852 5.41684 56.4945C4.46206 59.0092 3.84347 58.7672 2.72732 61.5239C1.66204 64.4655 1.16021 67.5813 1.24808 70.7086C1.1136 74.0974 1.1136 75.8187 2.08183 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask324)">
        <path d="M2.08183 77.2038C2.92903 78.3603 3.50728 78.1586 5.77992 80.1623C7.77017 81.924 7.58191 82.3005 9.19562 83.578C10.5849 84.9577 12.4206 85.7968 14.3729 85.9448C15.7429 85.6397 17.046 85.0884 18.219 84.3176C20.1206 83.3735 21.95 82.2903 23.6921 81.0768C25.1838 80.1825 26.5419 79.0824 27.7264 77.809C29.878 74.8102 29.1384 70.8028 28.1702 65.5313C27.8609 63.8638 27.3364 61.9677 26.301 58.1486C26.301 58.1486 26.301 58.041 26.301 57.9738C25.5479 56.1987 20.5185 53.684 20.5185 53.684C18.6638 52.6618 16.5707 52.1513 14.4536 52.2047C12.5411 52.3066 10.6716 52.8107 8.96701 53.684C7.82183 54.2087 6.73547 54.8533 5.72613 55.607C5.632 55.8894 5.53787 56.1852 5.41684 56.4945C4.46206 59.0092 3.84347 58.7672 2.72732 61.5239C1.66204 64.4655 1.16021 67.5813 1.24808 70.7086C1.1136 74.0974 1.1136 75.8187 2.08183 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.6061 75.2009C15.5864 75.2009 17.1208 81.2688 17.1208 88.2009H15.1978H12.1208C12.1208 81.2688 13.6257 75.2009 14.6061 75.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M23.9912 51.3703C24.4485 52.9706 24.9595 54.5843 25.8201 57.7713C26.8556 61.577 27.38 63.4866 27.6893 65.1406C28.6576 70.4121 29.4106 74.4329 27.2456 77.4318C26.058 78.6981 24.7003 79.7934 23.2113 80.6861C21.4651 81.9045 19.6312 82.9923 17.7247 83.9404C16.5549 84.7171 15.2506 85.2689 13.8786 85.5676C11.9245 85.4267 10.0865 84.5864 8.70131 83.2008C7.0876 81.9367 7.28931 81.5602 5.29907 79.7985C3.01297 77.7814 2.43473 77.9831 1.58753 76.8401C0.578957 75.4953 0.605852 73.7202 0.699986 70.318C0.612206 67.1951 1.11405 64.0836 2.17922 61.1467C3.28193 58.3899 3.88707 58.6186 4.86875 56.1039C6.05214 53.0378 5.82352 50.8458 5.91766 47.0805C6.05268 43.2631 6.39836 39.4562 6.95312 35.6769C7.92135 28.7917 8.97027 29.6793 10.2074 22.4983C11.7001 13.7708 12.4532 9.41374 12.2784 6.8049C12.2111 5.82323 11.9556 2.85132 13.7576 1.03589C13.8921 0.901411 14.9948 -0.187856 15.6806 0.121439C16.6085 0.538315 15.6806 3.02612 16.7161 6.84524C16.985 7.88071 17.4019 9.41374 18.1953 9.53477C18.9887 9.6558 19.7687 7.6252 20.5621 7.74623C21.3555 7.86726 21.8127 11.1619 21.9069 12.3453C22.1963 15.5932 22.1963 18.8604 21.9069 22.1083C21.0264 27.6394 20.8277 33.2576 21.3152 38.8371C21.863 43.081 22.758 47.2728 23.9912 51.3703Z" fill="#DEE1E9"/>
      <path d="M1.58012 76.8266C2.42732 77.9831 3.00557 77.7814 5.27821 79.7851C7.26846 81.5468 7.0802 81.9233 8.69391 83.2008C10.0832 84.5805 11.9189 85.4196 13.8712 85.5676C15.2412 85.2625 16.5443 84.7112 17.7173 83.9404C19.6189 82.9963 21.4483 81.9131 23.1904 80.6996C24.6821 79.8053 26.0402 78.7052 27.2247 77.4318C29.3763 74.433 28.6367 70.4256 27.6685 65.1541C27.3592 63.4866 26.8347 61.5905 25.7993 57.7714C25.7993 57.7714 25.7993 57.6638 25.7993 57.5966C25.0462 55.8215 20.0168 53.3068 20.0168 53.3068C18.1621 52.2846 16.0689 51.7741 13.9519 51.8275C12.0394 51.9294 10.1699 52.4335 8.4653 53.3068C7.32012 53.8315 6.23376 54.4761 5.22442 55.2298C5.13029 55.5122 5.03616 55.808 4.91513 56.1173C3.96035 58.632 3.34176 58.39 2.22561 61.1467C1.16033 64.0883 0.658504 67.2041 0.74637 70.3314C0.611895 73.7202 0.611893 75.4415 1.58012 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M18.6238 68.0679L16.7571 70.5687L15.1238 69.073V72.3871L12.5616 71.8192L13.9571 73.5698L11.6238 75.5704L13.9571 77.5711L12.5616 79.3217L14.8904 78.5715L15.1238 82.0679L17.3737 80.5708L18.1571 81.5725L19.8739 80.5708L22.1238 82.0679L22.8238 80.072L24.686 79.3217L24.2238 76.5708L25.6238 75.5704L23.2904 74.0699L24.686 71.8192L22.8238 71.5691L22.1238 69.073L19.0904 71.0689L18.6238 68.0679Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M23.9912 51.3703C24.4485 52.9706 24.9595 54.5843 25.8201 57.7713C26.8556 61.577 27.38 63.4866 27.6893 65.1406C28.6576 70.4121 29.4106 74.4329 27.2456 77.4318C26.058 78.6981 24.7003 79.7934 23.2113 80.6861C21.4651 81.9045 19.6312 82.9923 17.7247 83.9404C16.5549 84.7171 15.2506 85.2689 13.8786 85.5676C11.9245 85.4267 10.0865 84.5864 8.70131 83.2008C7.0876 81.9367 7.28931 81.5602 5.29907 79.7985C3.01297 77.7814 2.43473 77.9831 1.58753 76.8401C0.578957 75.4953 0.605852 73.7202 0.699986 70.318C0.612206 67.1951 1.11405 64.0836 2.17922 61.1467C3.28193 58.3899 3.88707 58.6186 4.86875 56.1039C6.05214 53.0378 5.82352 50.8458 5.91766 47.0805C6.05268 43.2631 6.39836 39.4562 6.95312 35.6769C7.92135 28.7917 8.97027 29.6793 10.2074 22.4983C11.7001 13.7708 12.4532 9.41374 12.2784 6.8049C12.2111 5.82323 11.9556 2.85132 13.7576 1.03589C13.8921 0.901411 14.9948 -0.187856 15.6806 0.121439C16.6085 0.538315 15.6806 3.02612 16.7161 6.84524C16.985 7.88071 17.4019 9.41374 18.1953 9.53477C18.9887 9.6558 19.7687 7.6252 20.5621 7.74623C21.3555 7.86726 21.8127 11.1619 21.9069 12.3453C22.1963 15.5932 22.1963 18.8604 21.9069 22.1083C21.0264 27.6394 20.8277 33.2576 21.3152 38.8371C21.863 43.081 22.758 47.2728 23.9912 51.3703Z" fill="#DEE1E9"/>
      <path d="M1.58012 76.8266C2.42732 77.9831 3.00557 77.7814 5.27821 79.7851C7.26846 81.5468 7.0802 81.9233 8.69391 83.2008C10.0832 84.5805 11.9189 85.4196 13.8712 85.5676C15.2412 85.2625 16.5443 84.7112 17.7173 83.9404C19.6189 82.9963 21.4483 81.9131 23.1904 80.6996C24.6821 79.8053 26.0402 78.7052 27.2247 77.4318C29.3763 74.433 28.6367 70.4256 27.6685 65.1541C27.3592 63.4866 26.8347 61.5905 25.7993 57.7714C25.7993 57.7714 25.7993 57.6638 25.7993 57.5966C25.0462 55.8215 20.0168 53.3068 20.0168 53.3068C18.1621 52.2846 16.0689 51.7741 13.9519 51.8275C12.0394 51.9294 10.1699 52.4335 8.4653 53.3068C7.32012 53.8315 6.23376 54.4761 5.22442 55.2298C5.13029 55.5122 5.03616 55.808 4.91513 56.1173C3.96035 58.632 3.34176 58.39 2.22561 61.1467C1.16033 64.0883 0.658504 67.2041 0.74637 70.3314C0.611895 73.7202 0.611893 75.4415 1.58012 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M19.6238 69.0679L18.0238 71.2115L16.6238 69.9294V72.77L14.4276 72.2833L15.6238 73.7838L13.6238 75.4987L15.6238 77.2135L14.4276 78.714L16.4238 78.071L16.6238 81.0679L18.5523 79.7847L19.2238 80.6433L20.6953 79.7847L22.6238 81.0679L23.2238 79.3571L24.8199 78.714L24.4238 76.3561L25.6238 75.4987L23.6238 74.2125L24.8199 72.2833L23.2238 72.0689L22.6238 69.9294L20.0238 71.6402L19.6238 69.0679Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M24.7251 51.4355C25.1824 53.0357 25.6934 54.6494 26.554 57.8365C27.5895 61.6422 28.1139 63.5518 28.4232 65.2058C29.3915 70.4773 30.1445 74.4981 27.9795 77.4969C26.7919 78.7633 25.4341 79.8585 23.9452 80.7513C22.199 81.9697 20.3651 83.0574 18.4585 84.0056C17.2888 84.7823 15.9845 85.3341 14.6125 85.6328C12.6584 85.4919 10.8204 84.6516 9.4352 83.266C7.82149 82.0019 8.0232 81.6254 6.03296 79.8637C3.74686 77.8466 3.16861 78.0483 2.32141 76.9053C1.31284 75.5605 1.33974 73.7854 1.43387 70.3831C1.34609 67.2603 1.84794 64.1488 2.91311 61.2119C4.01581 58.4551 4.62096 58.6837 5.60263 56.169C6.78602 53.103 6.55741 50.911 6.65154 47.1457C6.78657 43.3283 7.13225 39.5214 7.68701 35.7421C8.65524 28.8569 9.70415 29.7445 10.9413 22.5634C12.434 13.8359 13.1871 9.47893 13.0123 6.87009C12.945 5.88841 12.6895 2.9165 14.4915 1.10107C14.626 0.966597 15.7287 -0.12267 16.4145 0.186625C17.3424 0.603501 16.4145 3.09131 17.45 6.91043C17.7189 7.9459 18.1358 9.47892 18.9292 9.59995C19.7226 9.72098 20.5026 7.69039 21.296 7.81142C22.0894 7.93245 22.5466 11.2271 22.6408 12.4105C22.9302 15.6584 22.9302 18.9256 22.6408 22.1735C21.7603 27.7046 21.5615 33.3228 22.0491 38.9023C22.5969 43.1462 23.4919 47.3379 24.7251 51.4355Z" fill="#DEE1E9"/>
      <path d="M2.31401 76.8918C3.16121 78.0483 3.73945 77.8466 6.0121 79.8503C8.00235 81.6119 7.81408 81.9885 9.4278 83.266C10.8171 84.6457 12.6528 85.4848 14.6051 85.6328C15.9751 85.3277 17.2782 84.7764 18.4511 84.0056C20.3528 83.0615 22.1822 81.9783 23.9243 80.7647C25.416 79.8705 26.7741 78.7704 27.9586 77.497C30.1102 74.4982 29.3706 70.4908 28.4024 65.2193C28.0931 63.5518 27.5686 61.6557 26.5332 57.8366C26.5332 57.8366 26.5332 57.729 26.5332 57.6617C25.7801 55.8867 20.7507 53.372 20.7507 53.372C18.896 52.3498 16.8028 51.8393 14.6858 51.8927C12.7733 51.9946 10.9037 52.4987 9.19919 53.372C8.054 53.8967 6.96765 54.5413 5.95831 55.295C5.86418 55.5774 5.77004 55.8732 5.64902 56.1825C4.69424 58.6972 4.07565 58.4552 2.95949 61.2119C1.89421 64.1535 1.39239 67.2693 1.48026 70.3966C1.34578 73.7854 1.34578 75.5067 2.31401 76.8918Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M19.8577 75.1333L18.9243 76.3837L18.1077 75.6359V77.2929L16.8266 77.0089L17.5243 77.8842L16.3577 78.8846L17.5243 79.8849L16.8266 80.7602L17.991 80.3851L18.1077 82.1333L19.2326 81.3848L19.6243 81.8856L20.4827 81.3848L21.6077 82.1333L21.9577 81.1354L22.8888 80.7602L22.6577 79.3848L23.3577 78.8846L22.191 78.1343L22.8888 77.0089L21.9577 76.8839L21.6077 75.6359L20.091 76.6338L19.8577 75.1333Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M18.8519 2.68065L16.7682 1.41089H13.2845L11.1845 2.68065L12.7961 4.22716L10.7124 5.77367L12.6008 7.32018L10.3054 9.0132L12.1287 10.5597L9.83334 11.9597L11.8519 13.376L9.83334 14.9876L11.5264 16.5341L9.29613 17.9504L11.0543 19.4155L8.75893 20.962L10.9078 22.5085L8.75893 24.1201L10.3054 25.4713L8.22172 27.0178L10.045 28.4992L7.96125 30.0457L9.76823 31.7876L8.75893 33.4643V47.7247L10.6473 55.0503H16.7682" fill="#8889BE"/>
      <path d="M18.8519 2.68065L16.7682 1.41089H13.2845L11.1845 2.68065L12.7961 4.22716L10.7124 5.77367L12.6008 7.32018L10.3054 9.0132L12.1287 10.5597L9.83334 11.9597L11.8519 13.376L9.83334 14.9876L11.5264 16.5341L9.29613 17.9504L11.0543 19.4155L8.75893 20.962L10.9078 22.5085L8.75893 24.1201L10.3054 25.4713L8.22172 27.0178L10.045 28.4992L7.96125 30.0457L9.76823 31.7876L8.75893 33.4643V47.7247L10.6473 55.0503H16.7682" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M11.4447 2.68065L13.5284 1.41089H16.9958L19.1121 2.68065L17.4842 4.22716L19.5842 5.77367L17.6958 7.32018L19.9749 9.0132L18.1679 10.5597L20.447 11.9597L18.4284 13.376L20.447 14.9876L18.7702 16.5341L20.9842 17.9504L19.2423 19.4155L21.5214 20.962L19.3725 22.5085L21.5214 24.1201L19.9749 25.4713L22.0586 27.0178L20.2516 28.4992L22.3353 30.0457L20.5121 31.7876L21.5214 33.4643V47.7247L19.6493 55.0503H15.7423" fill="#8889BE"/>
      <path d="M11.4447 2.68065L13.5284 1.41089H16.9958L19.1121 2.68065L17.4842 4.22716L19.5842 5.77367L17.6958 7.32018L19.9749 9.0132L18.1679 10.5597L20.447 11.9597L18.4284 13.376L20.447 14.9876L18.7702 16.5341L20.9842 17.9504L19.2423 19.4155L21.5214 20.962L19.3725 22.5085L21.5214 24.1201L19.9749 25.4713L22.0586 27.0178L20.2516 28.4992L22.3353 30.0457L20.5121 31.7876L21.5214 33.4643V47.7247L19.6493 55.0503H15.7423" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M5.77528 68.9951C3.50264 66.9914 2.92439 67.1931 2.07719 66.0366C1.10896 64.6515 1.10896 62.9302 1.24344 59.5414C1.15557 56.4141 1.6574 53.2982 2.72268 50.3566C3.83883 47.5999 4.45742 47.8419 5.4122 45.3272C5.53323 45.0179 5.62736 44.7221 5.72149 44.4397H8.7297C9.28198 44.4397 9.7297 44.8874 9.7297 45.4397V55.3374C14.2034 56.6248 16.7104 56.5857 21.1849 55.3374V45.4397C21.1849 44.8874 21.6326 44.4397 22.1849 44.4397H25.1851C25.1851 44.5069 26.2963 46.9813 26.2963 46.9813C27.3318 50.8004 27.8563 52.6965 28.1656 54.364L28.1694 54.3849C29.1357 59.6461 29.8706 63.6468 27.7218 66.6417C26.5373 67.9152 25.1792 69.0152 23.6875 69.9095C21.9454 71.123 20.116 72.2063 18.2143 73.1504C17.0414 73.9211 15.7383 74.4724 14.3683 74.7775C12.4159 74.6296 10.5803 73.7904 9.19098 72.4107C7.57726 71.1332 7.76553 70.7567 5.77528 68.9951Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask524" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="44" width="30" height="32">
        <path d="M5.74916 68.9949C3.47651 66.9912 2.89827 67.1929 2.05107 66.0364C1.08284 64.6513 1.08284 62.93 1.21732 59.5412C1.12945 56.4139 1.63127 53.298 2.69655 50.3565C3.81271 47.5997 4.4313 47.8418 5.38608 45.3271C5.50711 45.0178 5.65503 45.6095 5.74916 45.3271H8.75197C9.30636 45.3271 9.75494 45.7781 9.75196 46.3324L9.70357 55.3373C13.8405 56.2729 16.1561 56.3004 20.2924 55.3373L20.3408 46.3217C20.3438 45.7715 20.7906 45.3271 21.3408 45.3271L25.2128 45.3271C25.2128 45.3943 26.2702 46.9811 26.2702 46.9811C27.3057 50.8002 27.8301 52.6964 28.1394 54.3639L28.1433 54.3847C29.1096 59.6459 29.8444 63.6467 27.6957 66.6415C26.5112 67.915 25.153 69.0151 23.6614 69.9093C21.9193 71.1228 20.0899 72.2061 18.1882 73.1502C17.0153 73.9209 15.7121 74.4723 14.3422 74.7773C12.3898 74.6294 10.5541 73.7902 9.16486 72.4106C7.55114 71.133 7.73941 70.7565 5.74916 68.9949Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask524)">
        <path d="M23.5694 33.3127H6.67485V58.8607C13.2726 60.787 16.9717 60.8065 23.5694 58.8607V33.3127Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M19.1132 1.95092L17.0295 0.681152H13.5458L11.4458 1.95092L13.0574 3.49743L10.9737 5.04393L12.862 6.59044L10.5667 8.28346L12.3899 9.82997L10.0946 11.23L12.1132 12.6462L10.0946 14.2579L11.7876 15.8044L9.55739 17.2207L11.3155 18.6858L9.02019 20.2323L11.169 21.7788L9.02019 23.3904L10.5667 24.7416L8.48298 26.2881L10.3062 27.7695L8.22251 29.316L10.0295 31.0578L9.02019 32.7346V46.995L10.9086 54.3206H17.0295" fill="#8889BE"/>
      <path d="M19.1132 1.95092L17.0295 0.681152H13.5458L11.4458 1.95092L13.0574 3.49743L10.9737 5.04393L12.862 6.59044L10.5667 8.28346L12.3899 9.82997L10.0946 11.23L12.1132 12.6462L10.0946 14.2579L11.7876 15.8044L9.55739 17.2207L11.3155 18.6858L9.02019 20.2323L11.169 21.7788L9.02019 23.3904L10.5667 24.7416L8.48298 26.2881L10.3062 27.7695L8.22251 29.316L10.0295 31.0578L9.02019 32.7346V46.995L10.9086 54.3206H17.0295" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M11.7059 1.95092L13.7896 0.681152H17.2571L19.3733 1.95092L17.7454 3.49743L19.8454 5.04393L17.9571 6.59044L20.2361 8.28346L18.4292 9.82997L20.7082 11.23L18.6896 12.6462L20.7082 14.2579L19.0315 15.8044L21.2454 17.2207L19.5036 18.6858L21.7826 20.2323L19.6338 21.7788L21.7826 23.3904L20.2361 24.7416L22.3198 26.2881L20.5129 27.7695L22.5966 29.316L20.7733 31.0578L21.7826 32.7346V46.995L19.9105 54.3206H16.0036" fill="#8889BE"/>
      <path d="M11.7059 1.95092L13.7896 0.681152H17.2571L19.3733 1.95092L17.7454 3.49743L19.8454 5.04393L17.9571 6.59044L20.2361 8.28346L18.4292 9.82997L20.7082 11.23L18.6896 12.6462L20.7082 14.2579L19.0315 15.8044L21.2454 17.2207L19.5036 18.6858L21.7826 20.2323L19.6338 21.7788L21.7826 23.3904L20.2361 24.7416L22.3198 26.2881L20.5129 27.7695L22.5966 29.316L20.7733 31.0578L21.7826 32.7346V46.995L19.9105 54.3206H16.0036" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M5.48643 64.4352C3.21379 62.4315 2.63554 62.6332 1.78834 61.4767C0.820114 60.0916 0.820116 58.3703 0.954592 54.9815C0.866726 51.8543 1.36855 48.7384 2.43383 45.7968C3.54998 43.0401 4.16857 43.2821 5.12335 40.7674C5.24438 40.4581 5.33851 40.1623 5.43265 39.8799H9.33843C9.89072 39.8799 10.3384 40.3276 10.3384 40.8799V50.7776C14.1477 52.3547 16.278 52.3854 20.0883 50.7776V40.8799C20.0883 40.3276 20.536 39.8799 21.0883 39.8799H24.8962C24.8962 39.9471 26.0075 42.4215 26.0075 42.4215C27.043 46.2406 27.5674 48.1367 27.8767 49.8042L27.8805 49.825C28.8469 55.0862 29.5817 59.087 27.4329 62.0819C26.2484 63.3553 24.8903 64.4554 23.3987 65.3497C21.6565 66.5632 19.8272 67.6464 17.9255 68.5905C16.7525 69.3613 15.4494 69.9126 14.0795 70.2177C12.1271 70.0697 10.2914 69.2306 8.90213 67.8509C7.28842 66.5734 7.47668 66.1969 5.48643 64.4352Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M43.8093 83.771H22.8093V88.771H43.8093V83.771Z" fill="#838AA2"/>
      <path d="M21.8093 83.771H0.809326V88.771H21.8093V83.771Z" fill="#838AA2"/>
      <path d="M32.1553 52.0739C32.6125 53.6742 33.1235 55.2879 33.9842 58.475C35.0197 62.2806 35.5441 64.1902 35.8534 65.8443C36.8216 71.1157 37.5747 75.1366 35.4096 78.1354C34.2221 79.4017 32.8643 80.497 31.3753 81.3897C29.6291 82.6081 27.7953 83.6959 25.8887 84.644C24.719 85.4207 23.4147 85.9725 22.0427 86.2712C20.0885 86.1303 18.2505 85.29 16.8654 83.9044C15.2517 82.6403 15.4534 82.2638 13.4631 80.5022C11.177 78.485 10.5988 78.6867 9.75159 77.5437C8.74302 76.1989 8.76991 74.4238 8.86405 71.0216C8.77627 67.8987 9.27811 64.7873 10.3433 61.8503C11.446 59.0936 12.0511 59.3222 13.0328 56.8075C14.2162 53.7414 13.9876 51.5494 14.0817 47.7841C14.2167 43.9668 14.5624 40.1598 15.1172 36.3805C16.0854 29.4954 17.1343 30.3829 18.3715 23.2019C19.8642 14.4744 20.6173 10.1174 20.4424 7.50852C20.3752 6.52684 20.1197 3.55493 21.9217 1.7395C22.0562 1.60502 23.1589 0.515758 23.8447 0.825053C24.7726 1.24193 23.8447 3.72974 24.8802 7.54886C25.1491 8.58432 25.566 10.1174 26.3594 10.2384C27.1528 10.3594 27.9328 8.32882 28.7262 8.44985C29.5196 8.57087 29.9768 11.8655 30.0709 13.0489C30.3603 16.2968 30.3603 19.564 30.0709 22.8119C29.1904 28.343 28.9917 33.9612 29.4792 39.5407C30.0271 43.7846 30.9221 47.9764 32.1553 52.0739Z" fill="#DEE1E9"/>
      <path d="M13.4423 80.4889C11.1696 78.4853 10.5914 78.6869 9.74418 77.5305C8.77596 76.1454 8.77596 74.4241 8.91043 71.0353C8.82257 67.908 9.32439 64.7921 10.3897 61.8505C11.5058 59.0938 12.1244 59.3358 13.0792 56.8211C13.2002 56.5118 13.2944 56.216 13.3885 55.9336H17.2943C17.8466 55.9336 18.2943 56.3813 18.2943 56.9336V66.8313C22.1036 68.4084 24.2338 68.4391 28.0441 66.8313V56.9336C28.0441 56.3813 28.4919 55.9336 29.0441 55.9336H32.8521C32.8521 56.0008 33.9633 58.4752 33.9633 58.4752C34.9988 62.2943 35.5233 64.1904 35.8326 65.8579L35.8364 65.8788C36.8027 71.14 37.5376 75.1407 35.3888 78.1356C34.2043 79.409 32.8461 80.5091 31.3545 81.4034C29.6124 82.6169 27.783 83.7002 25.8813 84.6443C24.7084 85.415 23.4052 85.9663 22.0353 86.2714C20.0829 86.1235 18.2473 85.2843 16.858 83.9046C15.2443 82.6271 15.4325 82.2506 13.4423 80.4889Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M24.334 51.3801C24.7912 52.9803 25.3022 54.594 26.1629 57.7811C27.1983 61.5868 27.7228 63.4964 28.0321 65.1504C29.0003 70.4219 29.7534 74.4427 27.5883 77.4415C26.4007 78.7079 25.043 79.8031 23.554 80.6958C21.8078 81.9143 19.9739 83.002 18.0674 83.9502C16.8977 84.7269 15.5934 85.2787 14.2214 85.5773C12.2672 85.4364 10.4292 84.5962 9.04406 83.2105C7.43034 81.9465 7.63206 81.5699 5.64181 79.8083C3.35572 77.7912 2.77747 77.9929 1.93027 76.8498C0.9217 75.5051 0.948595 73.73 1.04273 70.3277C0.954948 67.2048 1.45679 64.0934 2.52197 61.1565C3.62467 58.3997 4.22981 58.6283 5.21149 56.1136C6.39488 53.0476 6.16627 50.8556 6.2604 47.0903C6.39542 43.2729 6.7411 39.4659 7.29587 35.6867C8.2641 28.8015 9.31301 29.6891 10.5502 22.508C12.0429 13.7805 12.7959 9.42351 12.6211 6.81467C12.5539 5.83299 12.2984 2.86108 14.1004 1.04565C14.2348 0.911177 15.3375 -0.17809 16.0234 0.131205C16.9513 0.548081 16.0234 3.03589 17.0588 6.85501C17.3278 7.89048 17.7447 9.4235 18.5381 9.54453C19.3315 9.66556 20.1114 7.63497 20.9049 7.756C21.6983 7.87703 22.1555 11.1717 22.2496 12.3551C22.539 15.603 22.539 18.8702 22.2496 22.1181C21.3691 27.6492 21.1704 33.2674 21.6579 38.8469C22.2058 43.0908 23.1008 47.2825 24.334 51.3801Z" fill="#DEE1E9"/>
      <path d="M1.92286 76.8364C2.77006 77.9929 3.34831 77.7912 5.62096 79.7949C7.6112 81.5565 7.42294 81.9331 9.03665 83.2106C10.4259 84.5903 12.2616 85.4294 14.214 85.5774C15.5839 85.2723 16.8871 84.7209 18.06 83.9502C19.9617 83.0061 21.791 81.9229 23.5332 80.7093C25.0248 79.8151 26.3829 78.715 27.5675 77.4416C29.7191 74.4427 28.9795 70.4353 28.0112 65.1639C27.7019 63.4964 27.1775 61.6003 26.142 57.7811C26.142 57.7811 26.142 57.6736 26.142 57.6063C25.3889 55.8312 20.3595 53.3165 20.3595 53.3165C18.5048 52.2944 16.4117 51.7839 14.2947 51.8373C12.3821 51.9392 10.5126 52.4432 8.80804 53.3165C7.66286 53.8413 6.5765 54.4859 5.56717 55.2395C5.47303 55.5219 5.3789 55.8178 5.25787 56.1271C4.30309 58.6418 3.6845 58.3997 2.56835 61.1565C1.50307 64.0981 1.00125 67.2139 1.08911 70.3412C0.954638 73.73 0.954636 75.4513 1.92286 76.8364Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M14.5012 63.1735C15.846 63.2945 17.7287 58.4131 18.4414 53.7602L18.2666 40.5546C18.0783 36.359 17.8631 32.9298 17.7152 30.5899C17.3656 25.2109 17.2445 24.5789 17.2445 22.3062C17.2445 16.4565 15.0257 2.59597 14.5819 2.47494C14.1381 2.35391 16.1553 15.5824 15.4829 18.2719C14.6357 21.728 14.7836 23.651 14.5819 28.5997C14.56 32.4178 14.2589 36.2291 13.6809 40.0033C13.1161 43.0559 12.5379 44.5217 11.9058 48.9056C11.5469 50.9334 11.4565 52.9997 11.6369 55.0511C11.7899 56.7013 12.1421 58.3268 12.6858 59.8923C13.5196 62.0305 13.9095 63.1197 14.5012 63.1735Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M24.334 51.3801C24.7912 52.9803 25.3022 54.594 26.1629 57.7811C27.1983 61.5868 27.7228 63.4964 28.0321 65.1504C29.0003 70.4219 29.7534 74.4427 27.5883 77.4415C26.4007 78.7079 25.043 79.8031 23.554 80.6958C21.8078 81.9143 19.9739 83.002 18.0674 83.9502C16.8977 84.7269 15.5934 85.2787 14.2214 85.5773C12.2672 85.4364 10.4292 84.5962 9.04406 83.2105C7.43034 81.9465 7.63206 81.5699 5.64181 79.8083C3.35572 77.7912 2.77747 77.9929 1.93027 76.8498C0.9217 75.5051 0.948595 73.73 1.04273 70.3277C0.954948 67.2048 1.45679 64.0934 2.52197 61.1565C3.62467 58.3997 4.22981 58.6283 5.21149 56.1136C6.39488 53.0476 6.16627 50.8556 6.2604 47.0903C6.39542 43.2729 6.7411 39.4659 7.29587 35.6867C8.2641 28.8015 9.31301 29.6891 10.5502 22.508C12.0429 13.7805 12.7959 9.42351 12.6211 6.81467C12.5539 5.83299 12.2984 2.86108 14.1004 1.04565C14.2348 0.911177 15.3375 -0.17809 16.0234 0.131205C16.9513 0.548081 16.0234 3.03589 17.0588 6.85501C17.3278 7.89048 17.7447 9.4235 18.5381 9.54453C19.3315 9.66556 20.1114 7.63497 20.9049 7.756C21.6983 7.87703 22.1555 11.1717 22.2496 12.3551C22.539 15.603 22.539 18.8702 22.2496 22.1181C21.3691 27.6492 21.1704 33.2674 21.6579 38.8469C22.2058 43.0908 23.1008 47.2825 24.334 51.3801Z" fill="#DEE1E9"/>
      <path d="M1.92286 76.8364C2.77006 77.9929 3.34831 77.7912 5.62096 79.7949C7.6112 81.5565 7.42294 81.9331 9.03665 83.2106C10.4259 84.5903 12.2616 85.4294 14.214 85.5774C15.5839 85.2723 16.8871 84.7209 18.06 83.9502C19.9617 83.0061 21.791 81.9229 23.5332 80.7093C25.0248 79.8151 26.3829 78.715 27.5675 77.4416C29.7191 74.4427 28.9795 70.4353 28.0112 65.1639C27.7019 63.4964 27.1775 61.6003 26.142 57.7811C26.142 57.7811 26.142 57.6736 26.142 57.6063C25.3889 55.8312 20.3595 53.3165 20.3595 53.3165C18.5048 52.2944 16.4117 51.7839 14.2947 51.8373C12.3821 51.9392 10.5126 52.4432 8.80804 53.3165C7.66286 53.8413 6.5765 54.4859 5.56717 55.2395C5.47303 55.5219 5.3789 55.8178 5.25787 56.1271C4.30309 58.6418 3.6845 58.3997 2.56835 61.1565C1.50307 64.0981 1.00125 67.2139 1.08911 70.3412C0.954638 73.73 0.954636 75.4513 1.92286 76.8364Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.4" d="M14.5012 63.1735C15.846 63.2945 17.7287 58.4131 18.4414 53.7602L18.2666 40.5546C18.0783 36.359 17.8631 32.9298 17.7152 30.5899C17.3656 25.2109 17.2445 24.5789 17.2445 22.3062C17.2445 16.4565 15.0257 2.59597 14.5819 2.47494C14.1381 2.35391 16.1553 15.5824 15.4829 18.2719C14.6357 21.728 14.7836 23.651 14.5819 28.5997C14.56 32.4178 14.2589 36.2291 13.6809 40.0033C13.1161 43.0559 12.5379 44.5217 11.9058 48.9056C11.5469 50.9334 11.4565 52.9997 11.6369 55.0511C11.7899 56.7013 12.1421 58.3268 12.6858 59.8923C13.5196 62.0305 13.9095 63.1197 14.5012 63.1735Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M24.2156 51.3815C24.6728 52.9818 25.1838 54.5955 26.0445 57.7826C27.08 61.5882 27.6044 63.4978 27.9137 65.1519C28.8819 70.4233 29.635 74.4442 27.4699 77.443C26.2824 78.7093 24.9246 79.8046 23.4357 80.6973C21.6894 81.9158 19.8556 83.0035 17.949 83.9516C16.7793 84.7283 15.475 85.2802 14.103 85.5788C12.1488 85.4379 10.3108 84.5977 8.92568 83.212C7.31197 81.9479 7.51368 81.5714 5.52343 79.8098C3.23734 77.7926 2.65909 77.9943 1.81189 76.8513C0.803322 75.5065 0.830217 73.7314 0.924351 70.3292C0.836571 67.2063 1.33842 64.0949 2.40359 61.1579C3.50629 58.4012 4.11143 58.6298 5.09311 56.1151C6.2765 53.049 6.04789 50.8571 6.14202 47.0917C6.27704 43.2744 6.62273 39.4674 7.17749 35.6882C8.14572 28.803 9.19463 29.6905 10.4318 22.5095C11.9245 13.782 12.6776 9.42497 12.5027 6.81613C12.4355 5.83446 12.18 2.86255 13.982 1.04712C14.1165 0.912642 15.2192 -0.176625 15.905 0.13267C16.8329 0.549546 15.905 3.03735 16.9405 6.85648C17.2094 7.89194 17.6263 9.42497 18.4197 9.546C19.2131 9.66703 19.9931 7.63643 20.7865 7.75746C21.5799 7.87849 22.0371 11.1732 22.1312 12.3565C22.4206 15.6044 22.4206 18.8716 22.1312 22.1195C21.2507 27.6506 21.052 33.2689 21.5395 38.8484C22.0874 43.0922 22.9824 47.284 24.2156 51.3815Z" fill="#DEE1E9"/>
      <path d="M1.80449 76.8379C2.65169 77.9944 3.22993 77.7927 5.50258 79.7964C7.49282 81.558 7.30456 81.9345 8.91827 83.212C10.3076 84.5917 12.1432 85.4309 14.0956 85.5788C15.4655 85.2737 16.7687 84.7224 17.9416 83.9517C19.8433 83.0076 21.6727 81.9243 23.4148 80.7108C24.9064 79.8165 26.2646 78.7165 27.4491 77.443C29.6007 74.4442 28.8611 70.4368 27.8929 65.1653C27.5836 63.4978 27.0591 61.6017 26.0236 57.7826C26.0236 57.7826 26.0236 57.675 26.0236 57.6078C25.2706 55.8327 20.2412 53.318 20.2412 53.318C18.3865 52.2959 16.2933 51.7853 14.1763 51.8388C12.2638 51.9407 10.3942 52.4447 8.68966 53.318C7.54448 53.8427 6.45812 54.4873 5.44879 55.241C5.35466 55.5234 5.26052 55.8193 5.1395 56.1286C4.18472 58.6433 3.56612 58.4012 2.44997 61.158C1.38469 64.0995 0.88287 67.2154 0.970736 70.3427C0.83626 73.7315 0.836258 75.4528 1.80449 76.8379Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask524" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="24" width="15" height="42">
        <path d="M20.578 24.1606L13.8154 24.1606L6.45161 63.6676L18.4418 65.0224L20.578 24.1606Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask524)">
        <path opacity="0.2" d="M14.3828 63.175C15.7276 63.296 17.6103 58.4145 18.323 53.7616L18.1482 40.5561C17.9599 36.3604 17.7448 32.9313 17.5968 30.5914C17.2472 25.2124 17.1262 24.5803 17.1262 22.3077C17.1262 16.458 14.9073 2.59743 14.4635 2.4764C14.0198 2.35538 16.0369 15.5839 15.3645 18.2734C14.5173 21.7294 14.6652 23.6524 14.4635 28.6011C14.4416 32.4192 14.1405 36.2306 13.5625 40.0047C12.9977 43.0573 12.4195 44.5231 11.7875 48.9071C11.4285 50.9349 11.3381 53.0012 11.5185 55.0526C11.6715 56.7027 12.0237 58.3283 12.5674 59.8937C13.4012 62.0319 13.7912 63.1212 14.3828 63.175Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5024" mask-type="alpha" maskUnits="userSpaceOnUse" x="11" y="0" width="10" height="25">
        <path d="M13.0365 2.30301L13.7364 0.612793H15.1362L16.1243 1.69251L20.6531 24.0612H11.8837L12.2542 14.1351L12.7072 5.35496L13.0365 2.30301Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5024)">
        <path opacity="0.4" d="M14.3828 63.175C15.7276 63.296 17.6103 58.4145 18.323 53.7616L18.1482 40.5561C17.9599 36.3604 17.7448 32.9313 17.5968 30.5914C17.2472 25.2124 17.1262 24.5803 17.1262 22.3077C17.1262 16.458 14.9073 2.59743 14.4635 2.4764C14.0198 2.35538 16.0369 15.5839 15.3645 18.2734C14.5173 21.7294 14.6652 23.6524 14.4635 28.6011C14.4416 32.4192 14.1405 36.2306 13.5625 40.0047C12.9977 43.0573 12.4195 44.5231 11.7875 48.9071C11.4285 50.9349 11.3381 53.0012 11.5185 55.0526C11.6715 56.7027 12.0237 58.3283 12.5674 59.8937C13.4012 62.0319 13.7912 63.1212 14.3828 63.175Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M24.2454 51.7051C24.7026 53.3053 25.2136 54.919 26.0743 58.1061C27.1097 61.9118 27.6342 63.8214 27.9435 65.4754C28.9117 70.7469 29.6648 74.7677 27.4997 77.7665C26.3121 79.0329 24.9544 80.1281 23.4654 81.0209C21.7192 82.2393 19.8854 83.327 17.9788 84.2752C16.8091 85.0519 15.5048 85.6037 14.1328 85.9024C12.1786 85.7614 10.3406 84.9212 8.95547 83.5356C7.34175 82.2715 7.54346 81.895 5.55322 80.1333C3.26712 78.1162 2.68888 78.3179 1.84168 77.1748C0.833107 75.8301 0.860003 74.055 0.954136 70.6527C0.866356 67.5299 1.3682 64.4184 2.43337 61.4815C3.53608 58.7247 4.14122 58.9533 5.1229 56.4386C6.30629 53.3726 6.07767 51.1806 6.17181 47.4153C6.30683 43.5979 6.65251 39.7909 7.20727 36.0117C8.1755 29.1265 9.22442 30.0141 10.4616 22.833C11.9543 14.1055 12.7073 9.74852 12.5325 7.13968C12.4653 6.15801 12.2098 3.18609 14.0118 1.37067C14.1462 1.23619 15.2489 0.146922 15.9348 0.456217C16.8627 0.873093 15.9348 3.3609 16.9702 7.18002C17.2392 8.21549 17.6561 9.74852 18.4495 9.86954C19.2429 9.99057 20.0228 7.95998 20.8163 8.08101C21.6097 8.20204 22.0669 11.4967 22.161 12.6801C22.4504 15.928 22.4504 19.1952 22.161 22.4431C21.2805 27.9742 21.0818 33.5924 21.5693 39.1719C22.1172 43.4158 23.0122 47.6075 24.2454 51.7051Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M24.2454 52.0422C24.7026 53.6424 25.2136 55.2561 26.0743 58.4432C27.1097 62.2489 27.6342 64.1585 27.9435 65.8125C28.9117 71.084 29.6648 75.1048 27.4997 78.1036C26.3121 79.37 24.9544 80.4652 23.4654 81.358C21.7192 82.5764 19.8854 83.6641 17.9788 84.6123C16.8091 85.389 15.5048 85.9408 14.1328 86.2394C12.1786 86.0985 10.3406 85.2583 8.95547 83.8727C7.34175 82.6086 7.54346 82.232 5.55322 80.4704C3.26712 78.4533 2.68888 78.655 1.84168 77.5119C0.833107 76.1672 0.860003 74.3921 0.954136 70.9898C0.866356 67.867 1.3682 64.7555 2.43337 61.8186C3.53608 59.0618 4.14122 59.2904 5.1229 56.7757C6.30629 53.7097 6.07767 51.5177 6.17181 47.7524C6.30683 43.935 6.65251 40.128 7.20727 36.3488C8.1755 29.4636 9.22442 30.3512 10.4616 23.1701C11.9543 14.4426 12.7073 10.0856 12.5325 7.47678C12.4653 6.4951 12.2098 3.52319 14.0118 1.70776C14.1462 1.57329 15.2489 0.484019 15.9348 0.793314C16.8627 1.21019 15.9348 3.698 16.9702 7.51712C17.2392 8.55259 17.6561 10.0856 18.4495 10.2066C19.2429 10.3277 20.0228 8.29708 20.8163 8.41811C21.6097 8.53914 22.0669 11.8338 22.161 13.0172C22.4504 16.2651 22.4504 19.5323 22.161 22.7802C21.2805 28.3113 21.0818 33.9295 21.5693 39.509C22.1172 43.7529 23.0122 47.9446 24.2454 52.0422Z" fill="#DEE1E9"/>
      <path d="M1.83427 77.4986C2.68147 78.6551 3.25972 78.4534 5.53236 80.4571C7.52261 82.2187 7.33435 82.5952 8.94806 83.8727C10.3374 85.2524 12.173 86.0916 14.1254 86.2395C15.4953 85.9344 16.7985 85.3831 17.9714 84.6124C19.8731 83.6683 21.7025 82.585 23.4446 81.3715C24.9362 80.4772 26.2944 79.3772 27.4789 78.1037C29.6305 75.1049 28.8909 71.0975 27.9226 65.8261C27.6133 64.1586 27.0889 62.2624 26.0534 58.4433C26.0534 58.4433 26.0534 58.3357 26.0534 58.2685C25.3004 56.4934 20.2709 53.9787 20.2709 53.9787C18.4163 52.9566 16.3231 52.4461 14.2061 52.4995C12.2935 52.6014 10.424 53.1054 8.71945 53.9787C7.57427 54.5034 6.48791 55.148 5.47857 55.9017C5.38444 56.1841 5.29031 56.48 5.16928 56.7893C4.2145 59.304 3.59591 59.0619 2.47976 61.8187C1.41448 64.7602 0.912655 67.8761 1.00052 71.0034C0.866045 74.3922 0.866043 76.1135 1.83427 77.4986Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth63",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>

