<template>
  <b-modal id="pay-status-modal"
           :title="$t('payment')+' '+(payment ? payment.id : '')"
           @hide="onHide"
           size="lg"
           centered>
    <PaymentStatusTable v-if="payment"
                        :status="status"
                        :payment="payment"/>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import PaymentStatusTable from "@/components/parts/billing/PaymentStatusTable"

export default {
  name: "PayStatusModal",
  components: {
    PaymentStatusTable
  },
  computed: {
    ...mapState({
      payment: state => state.superadmin.billingPayment,
      status: state => state.superadmin.payStatus,
    }),
  },
  data() {
    return {
    }
  },
  methods: {
    onHide() {
      if(this.status.error_code == 0) {
        this.$store.commit('incrementDataTableCounter')
      }
      this.$store.commit('setPayStatus', null)
      this.$store.commit('setBillingPayment', null)

    }

  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

</style>
