<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>
    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M18.2964 2.40276L20.3801 1.133H23.8638L25.9638 2.40276L24.3522 3.94927L26.4359 5.49578L24.5475 7.04229L26.8429 8.73531L25.0196 10.2818L27.315 11.6818L25.2964 13.0981L27.315 14.7097L25.622 16.2562L27.8522 17.6725L26.094 19.1376L28.3894 20.6841L26.2406 22.2306L28.3894 23.8423L26.8429 25.1934L28.9266 26.7399L27.1033 28.2213L29.1871 29.7678L27.3801 31.5097L28.3894 33.1864V47.4469L26.501 54.7724H20.3801" fill="#8889BE"/>
      <path d="M25.7037 2.40276L23.6199 1.133H20.1525L18.0362 2.40276L19.6641 3.94927L17.5641 5.49578L19.4525 7.04229L17.1734 8.73531L18.9804 10.2818L16.7014 11.6818L18.72 13.0981L16.7014 14.7097L18.3781 16.2562L16.1641 17.6725L17.906 19.1376L15.6269 20.6841L17.7758 22.2306L15.6269 23.8423L17.1734 25.1934L15.0897 26.7399L16.8967 28.2213L14.813 29.7678L16.6362 31.5097L15.6269 33.1864V47.4469L17.499 54.7724H21.406" fill="#8889BE"/>
      <path d="M1.3309 73.287C1.64171 79.8411 8.46612 78.6924 11.1418 77.8276C13.8175 76.9627 20.9663 74.7735 24.6555 76.4762C28.3447 78.1789 29.4934 81.4492 37.6016 79.3005C45.7098 77.1519 41.8449 54.2327 41.8449 54.2327C41.8449 54.2327 40.1287 47.4758 31.1286 47.165C30.3071 47.1444 29.4922 47.3157 28.7486 47.6653C28.0049 48.0148 27.353 48.533 26.8447 49.1786C25.3582 51.3408 20.8176 51.6381 16.2095 49.9218C11.6013 48.2056 7.60125 48.7596 6.61475 50.4489C6.04142 51.3219 5.51368 52.224 5.03365 53.1516C4.13009 54.9026 3.33577 56.7079 2.65524 58.5571C1.4657 63.3708 1.01909 68.3382 1.3309 73.287Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M23.2161 19.4502C23.0675 20.3961 21.8648 27.6935 20.108 31.0989C18.1755 34.7476 16.0539 31.0989 16.0539 31.0989L13.8376 18.0582C13.9728 16.9907 15.7296 3.70672 17.1755 3.9905C18.6215 4.27429 19.135 3.44996 23.2161 19.4502Z" fill="#DEE1E9"/>-->
<!--      <path d="M4.56787 56.0226C4.68499 53.3919 4.82463 50.7252 4.9868 48.0225C5.29311 42.7791 5.68951 37.671 6.176 32.698C6.86274 27.2446 7.11128 21.7449 6.91925 16.2518C6.68602 12.0883 7.14263 7.91488 8.27062 3.90031C8.27062 3.90031 9.98686 1.19758 11.1761 4.8733C12.1184 8.36892 12.8361 11.9213 13.3247 15.5086L15.9329 30.9141C15.9329 30.9141 18.0951 34.4818 19.987 30.8331C21.8789 27.1844 23.1086 19.0762 23.1086 19.0762C23.1086 19.0762 24.0005 9.61659 22.7438 5.56249C21.487 1.50838 24.2303 0.427285 24.2303 0.427285C24.2303 0.427285 27.7168 -2.16734 31.8114 9.50848C35.9061 21.1843 35.1628 28.0763 35.1628 28.0763C35.1628 28.0763 34.0817 38.8196 35.1628 41.4953C36.5421 44.6507 37.527 47.964 38.0953 51.3603" fill="#DEE1E9"/>-->
<!--      <path d="M1.25692 75.3877C1.56774 81.9418 8.39215 80.7932 11.0679 79.9283C13.7436 79.0634 20.8923 76.8742 24.5815 78.5769C28.2708 80.2797 29.4194 83.55 37.5276 81.4013C45.6358 79.2526 41.7709 56.3334 41.7709 56.3334C41.7709 56.3334 40.0547 49.5766 31.0546 49.2658C30.2331 49.2452 29.4182 49.4165 28.6746 49.766C27.9309 50.1155 27.2791 50.6337 26.7707 51.2793C25.2842 53.4415 20.7436 53.7388 16.1355 52.0226C11.5273 50.3063 7.52727 50.8604 6.54077 52.5496C5.96744 53.4226 5.4397 54.3247 4.95967 55.2523C4.05612 57.0034 3.2618 58.8086 2.58126 60.6578C1.39172 65.4716 0.945117 70.4389 1.25692 75.3877Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>-->
<!--      <path opacity="0.2" d="M33.3922 53.5619C34.4328 51.6303 32.4328 49.4922 30.9327 44.3019C29.8224 40.7835 29.363 37.0597 29.5814 33.3462C29.9597 28.7456 31.2976 27.6545 31.149 23.1277C30.8898 20.7346 30.4376 18.3711 29.7976 16.0647C27.1624 4.66663 24.9146 1.47101 24.5903 1.52999C24.266 1.58897 26.1624 3.8114 26.0002 11.6854C25.9056 16.5071 25.8921 17.9374 25.784 20.2081C25.6354 22.7738 25.5138 23.3636 25.1083 27.7577C24.3381 36.0593 24.3245 38.9346 23.9867 38.9493C23.6489 38.9641 23.5002 34.0539 22.2029 27.0204C21.0137 20.5178 18.791 5.51074 17.5748 5.70243C16.9126 5.7909 16.6893 14.7966 16.4055 19.2349C15.8244 28.2001 16.5407 32.9038 15.7298 33.1102C14.919 33.3167 12.7704 27.4776 11.946 24.8234C10.3784 19.869 10.049 4.16261 9.64355 4.1921C9.23814 4.22159 9.59464 20.3113 9.27031 27.507C8.98652 33.6116 8.51354 36.4721 10.1622 39.6866C11.0811 41.5003 11.5136 41.1611 11.946 42.6357C12.946 46.2188 9.81086 48.2536 9.93248 53.3407C9.93248 54.6826 10.0271 57.086 11.5001 58.4573C12.9731 59.8287 15.1217 59.3421 19.5272 58.4573C22.3245 57.897 21.8651 57.7496 29.1219 55.5083C32.2436 54.5646 32.9328 54.4024 33.3922 53.5619Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M23.4823 19.8084C23.3336 20.7543 22.1309 28.0517 20.3741 31.4572C18.4417 35.1059 16.32 31.4572 16.32 31.4572L14.1038 18.4165C14.2389 17.3489 15.9957 4.06493 17.4416 4.34872C18.8876 4.63251 19.4011 3.80817 23.4823 19.8084Z" fill="#DEE1E9"/>
        <path d="M4.83398 56.3807C4.9511 53.75 5.09074 51.0833 5.25291 48.3806C5.55922 43.1373 5.95562 38.0291 6.44211 33.0561C7.12885 27.6027 7.3774 22.1031 7.18536 16.61C6.95213 12.4465 7.40874 8.27304 8.53673 4.25846C8.53673 4.25846 10.253 1.55573 11.4422 5.23145C12.3846 8.72707 13.1022 12.2794 13.5908 15.8667L16.199 31.2723C16.199 31.2723 18.3612 34.8399 20.2531 31.1912C22.145 27.5425 23.3748 19.4343 23.3748 19.4343C23.3748 19.4343 24.2667 9.97474 23.0099 5.92064C21.7531 1.86654 24.4964 0.78544 24.4964 0.78544C24.4964 0.78544 27.9829 -1.80918 32.0776 9.86663C36.1722 21.5424 35.429 28.4344 35.429 28.4344C35.429 28.4344 34.3479 39.1778 35.429 41.8535C36.8082 45.0088 37.7931 48.3221 38.3614 51.7185" fill="#DEE1E9"/>
        <mask id="417" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="49" width="44" height="35">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#417)">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M21.7809 65.2009C20.8005 65.2009 19.2661 72.2023 19.2661 80.2009H21.1892H24.2661C24.2661 72.2023 22.7613 65.2009 21.7809 65.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M33.9744 63.4331C35.0277 61.1599 33.0032 58.6438 31.4848 52.5359C30.3609 48.3954 29.8959 44.0132 30.1169 39.6432C30.4999 34.2293 31.8542 32.9452 31.7037 27.618C31.4414 24.8018 30.9836 22.0205 30.3358 19.3063C27.6684 5.89307 25.3931 2.13246 25.0648 2.20187C24.7365 2.27128 26.6561 4.88664 26.492 14.1527C26.3962 19.8269 26.3825 21.5101 26.2731 24.1823C26.1226 27.2016 25.9995 27.8957 25.5892 33.0667C24.8094 42.836 24.7958 46.2196 24.4538 46.237C24.1118 46.2543 23.9613 40.4761 22.6482 32.199C21.4444 24.5467 19.1945 6.88642 17.9634 7.112C17.2931 7.21611 17.0671 17.814 16.7798 23.0371C16.1916 33.5872 16.9166 39.1226 16.0959 39.3655C15.2751 39.6085 13.1002 32.737 12.2657 29.6136C10.679 23.7832 10.3455 5.29994 9.93509 5.33464C9.52471 5.36935 9.88557 24.3038 9.55728 32.7717C9.27002 39.9555 8.79125 43.3218 10.4601 47.1046C11.3903 49.2389 11.828 48.8398 12.2657 50.5751C13.278 54.7916 10.1044 57.1863 10.2276 63.1728C10.2276 64.7518 10.3233 67.5803 11.8143 69.194C13.3053 70.8078 15.4803 70.2352 19.9397 69.194C22.7713 68.5346 22.3062 68.3611 29.6518 65.7236C32.8117 64.613 33.5093 64.4221 33.9744 63.4331Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M23.2161 22.6073C23.0675 23.5533 21.8648 30.8507 20.108 34.2561C18.1755 37.9048 16.0539 34.2561 16.0539 34.2561L13.8376 21.2154C13.9728 20.1478 15.7296 6.86388 17.1755 7.14767C18.6215 7.43146 19.135 6.60712 23.2161 22.6073Z" fill="#DEE1E9"/>
      <path d="M4.56787 59.1797C4.68499 56.5491 4.82463 53.8824 4.9868 51.1796C5.29311 45.9363 5.68951 40.8282 6.176 35.8551C6.86274 30.4017 7.11128 24.9021 6.91925 19.409C6.68602 15.2455 7.14263 11.0721 8.27062 7.05748C8.27062 7.05748 9.98686 4.35474 11.1761 8.03046C12.1184 11.5261 12.8361 15.0784 13.3247 18.6657L15.9329 34.0713C15.9329 34.0713 18.0951 37.6389 19.987 33.9902C21.8789 30.3415 23.1086 22.2333 23.1086 22.2333C23.1086 22.2333 24.0005 12.7738 22.7438 8.71965C21.487 4.66555 24.2303 3.58445 24.2303 3.58445C24.2303 3.58445 27.7168 0.989826 31.8114 12.6656C35.9061 24.3415 35.1628 31.2334 35.1628 31.2334C35.1628 31.2334 34.0817 41.9768 35.1628 44.6525C36.5421 47.8078 37.527 51.1211 38.0953 54.5175" fill="#DEE1E9"/>
      <path d="M1.25692 78.5449C1.56774 85.099 8.39215 83.9503 11.0679 83.0855C13.7436 82.2206 20.8923 80.0314 24.5815 81.7341C28.2708 83.4368 29.4194 86.7071 37.5276 84.5584C45.6358 82.4098 41.7709 59.4906 41.7709 59.4906C41.7709 59.4906 40.0547 52.7337 31.0546 52.4229C30.2331 52.4023 29.4182 52.5736 28.6746 52.9232C27.9309 53.2727 27.2791 53.7909 26.7707 54.4365C25.2842 56.5987 20.7436 56.896 16.1355 55.1797C11.5273 53.4635 7.52727 54.0175 6.54077 55.7068C5.96744 56.5798 5.4397 57.4819 4.95967 58.4095C4.05612 60.1605 3.2618 61.9658 2.58126 63.815C1.39172 68.6288 0.945117 73.5961 1.25692 78.5449Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M33.3922 56.7191C34.4328 54.7874 32.4328 52.6494 30.9327 47.459C29.8224 43.9407 29.363 40.2168 29.5814 36.5033C29.9597 31.9028 31.2976 30.8116 31.149 26.2848C30.8898 23.8917 30.4376 21.5282 29.7976 19.2219C27.1624 7.82378 24.9146 4.62816 24.5903 4.68714C24.266 4.74612 26.1624 6.96855 26.0002 14.8425C25.9056 19.6642 25.8921 21.0945 25.784 23.3653C25.6354 25.931 25.5138 26.5208 25.1083 30.9148C24.3381 39.2164 24.3245 42.0918 23.9867 42.1065C23.6489 42.1212 23.5002 37.2111 22.2029 30.1776C21.0137 23.6749 18.791 8.66789 17.5748 8.85958C16.9126 8.94805 16.6893 17.9538 16.4055 22.3921C15.8244 31.3572 16.5407 36.061 15.7298 36.2674C14.919 36.4738 12.7704 30.6347 11.946 27.9806C10.3784 23.0261 10.049 7.31976 9.64355 7.34925C9.23814 7.37874 9.59464 23.4685 9.27031 30.6642C8.98652 36.7687 8.51354 39.6293 10.1622 42.8438C11.0811 44.6574 11.5136 44.3183 11.946 45.7928C12.946 49.3759 9.81086 51.4108 9.93248 56.4979C9.93248 57.8397 10.0271 60.2432 11.5001 61.6145C12.9731 62.9858 15.1217 62.4992 19.5272 61.6145C22.3245 61.0542 21.8651 60.9067 29.1219 58.6654C32.2436 57.7217 32.9328 57.5595 33.3922 56.7191Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="9" cy="7" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="17" cy="7" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="24" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M36.8712 81.9757C28.763 84.1244 27.6549 80.7865 23.9386 79.1379C20.2223 77.4892 13.1276 79.6649 10.4249 80.4892C7.72217 81.3136 0.884253 82.6244 0.519384 76.0162C0.20872 71.0213 0.664454 66.0082 1.87075 61.1512C2.44174 59.5906 3.10508 58.0653 3.85726 56.5835C3.96537 53.9439 4.10051 51.2772 4.26267 48.5834C4.587 43.3401 4.9834 38.232 5.45188 33.2589C6.13861 27.8055 6.38716 22.3059 6.19513 16.8128C5.95739 12.6492 6.41408 8.47499 7.5465 4.46127C7.5465 4.46127 9.24922 1.75854 10.4384 5.43426C11.4129 8.95056 12.1622 12.5254 12.6817 16.1371L13.0736 18.4614C13.2087 17.3939 14.9655 4.12343 16.398 4.40722C17.8304 4.69101 18.4115 3.81262 22.4791 19.7993C22.4817 19.7678 22.4817 19.7362 22.4791 19.7047C22.4791 19.7047 23.3845 10.2451 22.1142 6.19102C20.844 2.13692 23.6007 1.05583 23.6007 1.05583C23.6007 1.05583 27.1008 -1.5388 31.1954 10.137C35.2901 21.8128 34.5333 28.7048 34.5333 28.7048C34.5333 28.7048 33.4522 39.5158 34.5333 42.1239C35.9125 45.2792 36.8975 48.5925 37.4658 51.9889C39.1809 53.1976 40.4454 54.9423 41.0604 56.9484C41.0604 56.9484 44.9794 79.8135 36.8712 81.9757Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M36.8712 81.9757C28.763 84.1244 27.6549 80.7865 23.9386 79.1379C20.2223 77.4892 13.1276 79.6649 10.4249 80.4892C7.72217 81.3136 0.884253 82.6244 0.519384 76.0162C0.20872 71.0213 0.664454 66.0082 1.87075 61.1512C2.44174 59.5906 3.10508 58.0653 3.85726 56.5835C3.96537 53.9439 4.10051 51.2772 4.26267 48.5834C4.587 43.3401 4.9834 38.232 5.45188 33.2589C6.13861 27.8055 6.38716 22.3059 6.19513 16.8128C5.95739 12.6492 6.41408 8.47499 7.5465 4.46127C7.5465 4.46127 9.24922 1.75854 10.4384 5.43426C11.4129 8.95056 12.1622 12.5254 12.6817 16.1371L13.0736 18.4614C13.2087 17.3939 14.9655 4.12343 16.398 4.40722C17.8304 4.69101 18.4115 3.81262 22.4791 19.7993C22.4817 19.7678 22.4817 19.7362 22.4791 19.7047C22.4791 19.7047 23.3845 10.2451 22.1142 6.19102C20.844 2.13692 23.6007 1.05583 23.6007 1.05583C23.6007 1.05583 27.1008 -1.5388 31.1954 10.137C35.2901 21.8128 34.5333 28.7048 34.5333 28.7048C34.5333 28.7048 33.4522 39.5158 34.5333 42.1239C35.9125 45.2792 36.8975 48.5925 37.4658 51.9889C39.1809 53.1976 40.4454 54.9423 41.0604 56.9484C41.0604 56.9484 44.9794 79.8135 36.8712 81.9757Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M19.1956 19.5048C19.047 20.4507 17.8443 27.7481 16.0875 31.1536C14.155 34.8023 12.0334 31.1536 12.0334 31.1536L9.81714 18.1129C9.95228 17.0453 11.7091 3.76134 13.155 4.04513C14.601 4.32892 15.1145 3.50458 19.1956 19.5048Z" fill="#C7CAD5"/>
      <path d="M0.966287 48.0771C0.804123 50.7798 0.664482 53.4465 0.547363 56.0772L13.9821 54.4717L24.1111 56.0772L32.7711 54.4717C32.2028 51.0753 32.5216 44.7053 31.1423 41.55C30.0612 38.8743 31.1423 28.1309 31.1423 28.1309C31.1423 28.1309 31.8856 21.2389 27.7909 9.5631C23.6963 -2.11271 20.2098 0.481912 20.2098 0.481912C20.2098 0.481912 17.4665 1.56301 18.7233 5.61711C19.98 9.67122 19.0881 19.1308 19.0881 19.1308C19.0881 19.1308 17.8584 27.239 15.9665 30.8877C14.0746 34.5364 11.9124 30.9688 11.9124 30.9688L9.30423 15.5632C8.81562 11.9759 8.09793 8.42354 7.15555 4.92792C5.96635 1.2522 4.25011 3.95494 4.25011 3.95494C3.12212 7.96951 2.66551 12.1429 2.89874 16.3064C3.09077 21.7995 2.84223 27.2992 2.15549 32.7526C1.669 37.7256 1.2726 42.8338 0.966287 48.0771Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M25.6619 38.6437C25.4409 43.0137 25.9058 47.3959 27.0298 51.5363C27.3682 52.8976 27.7317 54.0804 28.0807 55.1219L22.1053 58.0434L13.0561 56.9051L6.56795 57.588C7.2616 53.6758 8.63182 52.9959 7.81072 49.5755C7.37299 47.8403 6.93526 48.2394 6.00508 46.1051C4.33623 42.3223 4.815 38.956 5.10226 31.7722C5.43056 23.3043 5.0697 4.36984 5.48007 4.33513C5.89044 4.30043 6.22394 22.7837 7.81072 28.6141C8.64514 31.7375 10.8201 38.6089 11.6409 38.366C12.4616 38.1231 11.7366 32.5877 12.3248 22.0375C12.6121 16.8145 12.8381 6.2166 13.5084 6.11249C14.7395 5.88691 16.9894 23.5472 18.1931 31.1995C19.5063 39.4765 19.6568 45.2548 19.9988 45.2375C20.3408 45.2201 20.3544 41.8364 21.1341 32.0671C21.5445 26.8962 21.6676 26.2021 21.8181 23.1828C21.9275 20.5106 21.9412 18.8274 22.037 13.1532C22.2011 3.88712 20.2815 1.27176 20.6098 1.20236C20.9381 1.13295 23.2134 4.89355 25.8808 18.3068C26.5286 21.021 26.9863 23.8023 27.2487 26.6185C27.3992 31.9457 26.0449 33.2298 25.6619 38.6437Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M1.50177 43.2378C13.9991 41.7743 20.4518 43.3709 31.1784 49.7034" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M21.1956 20.0927C21.047 21.0387 19.8443 28.336 18.0875 31.7415C16.155 35.3902 14.0334 31.7415 14.0334 31.7415L11.8171 18.7008C11.9523 17.6332 13.7091 4.34927 15.155 4.63305C16.601 4.91684 17.1145 4.0925 21.1956 20.0927Z" fill="#C7CAD5"/>
      <path d="M2.54736 56.6651C2.66448 54.0344 2.80412 51.3677 2.96629 48.665C3.2726 43.4217 3.669 38.3135 4.15549 33.3405C4.84223 27.8871 5.09077 22.3875 4.89874 16.8944C4.66551 12.7309 5.12212 8.55743 6.25011 4.54286C6.25011 4.54286 7.96635 1.84012 9.15555 5.51584C10.0979 9.01146 10.8156 12.5638 11.3042 16.1511L13.9124 31.5567C13.9124 31.5567 16.0746 35.1243 17.9665 31.4756C19.8584 27.8269 21.0881 19.7187 21.0881 19.7187C21.0881 19.7187 21.98 10.2591 20.7233 6.20503C19.4665 2.15093 22.2098 1.06983 22.2098 1.06983C22.2098 1.06983 25.6963 -1.52479 29.7909 10.151C33.8856 21.8268 33.1423 28.7188 33.1423 28.7188C33.1423 28.7188 32.0612 39.4622 33.1423 42.1379C34.5216 45.2932 35.5065 48.6065 36.0748 52.0029" fill="#DEE1E9"/>
      <path d="M35.5071 82.0438C27.3989 84.1925 26.2503 80.9222 22.561 79.2195C20.3627 78.2048 16.9359 78.5722 13.9591 79.2178V75.7596L10.1735 78.1575L7.9447 75.7596L10.1735 71.4204L6.60598 72.1915L7.9447 69.1566L4.13562 67.6418L5.3432 65.3284L4.13562 62.8615L6.60598 59.9097L1.42627 59.1159L2.93918 55.8949C3.41921 54.9673 3.94695 54.0652 4.52028 53.1921C5.50678 51.5029 9.50682 50.9489 14.115 52.6651C18.7232 54.3813 23.2637 54.084 24.7503 51.9219C25.2586 51.2762 25.9104 50.7581 26.6541 50.4086C27.3977 50.059 28.2127 49.8877 29.0341 49.9083C38.0342 50.2191 39.7504 56.976 39.7504 56.976C39.7504 56.976 43.6153 79.8952 35.5071 82.0438Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M6.00357 55.7875C17.0044 60.1394 19.5239 65.0334 18.265 77.6219" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M31.5194 63.0214C32.5727 60.7483 30.5482 58.2322 29.0298 52.1242C27.9058 47.9838 27.4409 43.6016 27.6619 39.2315C28.0449 33.8176 29.3992 32.5336 29.2487 27.2064C28.9863 24.3902 28.5286 21.6089 27.8808 18.8947C25.2134 5.48145 22.9381 1.72084 22.6098 1.79025C22.2815 1.85965 24.2011 4.47501 24.037 13.7411C23.9412 19.4153 23.9275 21.0984 23.8181 23.7707C23.6676 26.79 23.5445 27.4841 23.1341 32.655C22.3544 42.4243 22.3408 45.808 21.9988 45.8254C21.6568 45.8427 21.5063 40.0644 20.1931 31.7874C18.9894 24.1351 16.7395 6.4748 15.5084 6.70038C14.8381 6.80449 14.6121 17.4024 14.3248 22.6254C13.7366 33.1756 14.4616 38.711 13.6409 38.9539C12.8201 39.1968 10.6451 32.3254 9.81072 29.202C8.22394 23.3716 7.89044 4.88832 7.48007 4.92302C7.0697 4.95773 7.43056 23.8922 7.10226 32.36C6.815 39.5439 6.33623 42.9102 8.00508 46.693C8.93526 48.8273 9.37299 48.4282 9.81072 50.1634C10.823 54.38 7.64942 56.7747 7.77253 62.7612C7.77253 64.3402 7.86829 67.1686 9.35931 68.7824C10.8503 70.3962 13.0253 69.8235 17.4847 68.7824C20.3162 68.123 19.8512 67.9495 27.1968 65.3119C30.3567 64.2014 31.0543 64.0105 31.5194 63.0214Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M23.4823 19.4391C23.3336 20.385 22.1309 27.6824 20.3741 31.0879C18.4417 34.7366 16.32 31.0879 16.32 31.0879L14.1038 18.0472C14.2389 16.9796 15.9957 3.69565 17.4416 3.97944C18.8876 4.26323 19.4011 3.43889 23.4823 19.4391Z" fill="#C7CAD5"/>
      <path d="M5.25291 72.3971C5.37003 69.7665 5.09077 50.7141 5.25294 48.0114C5.55925 42.7681 5.95565 37.6599 6.44214 32.6869C7.12888 27.2335 7.37742 21.7338 7.18539 16.2407C6.95216 12.0772 7.40877 7.90382 8.53676 3.88925C8.53676 3.88925 10.253 1.18651 11.4422 4.86223C12.3846 8.35785 13.1023 11.9102 13.5909 15.4975L16.199 30.9031C16.199 30.9031 18.3612 34.4707 20.2531 30.822C22.145 27.1733 23.3748 19.0651 23.3748 19.0651C23.3748 19.0651 24.2667 9.60553 23.0099 5.55142C21.7531 1.49732 24.4964 0.416223 24.4964 0.416223C24.4964 0.416223 27.9829 -2.1784 32.0776 9.49742C36.1722 21.1732 35.429 28.0652 35.429 28.0652C35.429 28.0652 34.3479 38.8086 35.429 41.4843C36.8082 44.6396 38.212 64.3386 38.7803 67.7349" fill="#DEE1E9"/>
      <path d="M10.9072 79.3914C8.2315 80.2563 1.40709 81.4049 1.09628 74.8508C0.784473 69.902 1.23108 64.9347 2.42062 60.1209C3.10115 58.2717 4.66969 52.1223 5.57324 50.3713C8.69343 49.4809 11.469 48.8262 14.0732 48.3982C14.9228 48.2586 14.2541 61.963 15.0732 61.8713C15.2805 63.5277 25.5836 63.4033 28.5419 61.8713C29.3757 61.9634 27.6779 48.2592 28.5419 48.3982C31.2402 48.8323 34.1166 49.4934 37.367 50.3713C38.867 50.3713 41.6103 55.7965 41.6103 55.7965C41.6103 55.7965 45.4752 78.7157 37.367 80.8644C29.2588 83.0131 28.1101 79.7428 24.4209 78.04C20.7317 76.3373 13.5829 78.5265 10.9072 79.3914Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M23.4823 19.6899C23.3336 20.6358 22.1309 27.9332 20.3741 31.3387C18.4417 34.9874 16.32 31.3387 16.32 31.3387L14.1038 18.298C14.2389 17.2304 15.9957 3.94644 17.4416 4.23023C18.8876 4.51402 19.4011 3.68968 23.4823 19.6899Z" fill="#C7CAD5"/>
      <path d="M5.25291 72.6479C5.37003 70.0173 5.09077 50.9649 5.25294 48.2622C5.55925 43.0189 5.95565 37.9107 6.44214 32.9377C7.12888 27.4843 7.37742 21.9846 7.18539 16.4915C6.95216 12.328 7.40877 8.15461 8.53676 4.14003C8.53676 4.14003 10.253 1.4373 11.4422 5.11302C12.3846 8.60864 13.1023 12.161 13.5909 15.7483L16.199 31.1539C16.199 31.1539 18.3612 34.7215 20.2531 31.0728C22.145 27.4241 23.3748 19.3159 23.3748 19.3159C23.3748 19.3159 24.2667 9.85631 23.0099 5.80221C21.7531 1.74811 24.4964 0.667009 24.4964 0.667009C24.4964 0.667009 27.9829 -1.92762 32.0776 9.7482C36.1722 21.424 35.429 28.316 35.429 28.316C35.429 28.316 34.3479 39.0594 35.429 41.7351C36.8082 44.8904 38.212 64.5894 38.7803 67.9857" fill="#DEE1E9"/>
      <path d="M10.9072 79.6422C8.2315 80.5071 1.40709 81.6557 1.09628 75.1016C0.784473 70.1528 1.23108 65.1855 2.42062 60.3717C3.10115 58.5225 4.66969 52.3731 5.57324 50.6221C8.69343 49.7317 11.469 49.077 14.0732 48.649C14.9228 48.5094 14.2541 62.2138 15.0732 62.1221C15.2805 63.7785 25.5836 63.654 28.5419 62.1221C29.3757 62.2142 27.6779 48.51 28.5419 48.649C31.2402 49.0831 34.1166 49.7442 37.367 50.6221C38.867 50.6221 41.6103 56.0473 41.6103 56.0473C41.6103 56.0473 45.4752 78.9665 37.367 81.1152C29.2588 83.2638 28.1101 79.9935 24.4209 78.2908C20.7317 76.5881 13.5829 78.7773 10.9072 79.6422Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M23.4823 19.7575C23.3336 20.7035 22.1309 28.0009 20.3741 31.4063C18.4417 35.055 16.32 31.4063 16.32 31.4063L14.1038 18.3656C14.2389 17.298 15.9957 4.01409 17.4416 4.29788C18.8876 4.58167 19.4011 3.75733 23.4823 19.7575Z" fill="#C7CAD5"/>
      <path d="M5.25291 72.7156C5.37003 70.0849 5.09077 51.0326 5.25294 48.3298C5.55925 43.0865 5.95565 37.9784 6.44214 33.0053C7.12888 27.5519 7.37742 22.0523 7.18539 16.5592C6.95216 12.3957 7.40877 8.22226 8.53676 4.20768C8.53676 4.20768 10.253 1.50495 11.4422 5.18067C12.3846 8.67629 13.1023 12.2286 13.5909 15.8159L16.199 31.2215C16.199 31.2215 18.3612 34.7891 20.2531 31.1404C22.145 27.4917 23.3748 19.3835 23.3748 19.3835C23.3748 19.3835 24.2667 9.92396 23.0099 5.86986C21.7531 1.81576 24.4964 0.734658 24.4964 0.734658C24.4964 0.734658 27.9829 -1.85997 32.0776 9.81585C36.1722 21.4917 35.429 28.3836 35.429 28.3836C35.429 28.3836 34.3479 39.127 35.429 41.8027C36.8082 44.958 38.212 64.657 38.7803 68.0534" fill="#DEE1E9"/>
      <path d="M10.9072 79.7098C8.2315 80.5747 1.40709 81.7234 1.09628 75.1692C0.784473 70.2205 1.23108 65.2531 2.42062 60.4393C3.10115 58.5902 4.66969 52.4408 5.57324 50.6897C8.69343 49.7993 11.469 49.1446 14.0732 48.7166C14.9228 48.577 14.2541 62.2814 15.0732 62.1897C15.0732 62.1897 25.3469 61.8368 28.5419 62.1897C29.3757 62.2818 27.6779 48.5776 28.5419 48.7166C31.2402 49.1507 34.1166 49.8118 37.367 50.6897C38.867 50.6897 41.6103 56.115 41.6103 56.115C41.6103 56.115 45.4752 79.0341 37.367 81.1828C29.2588 83.3315 28.1101 80.0612 24.4209 78.3585C20.7317 76.6557 13.5829 78.845 10.9072 79.7098Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask2017" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="44" height="35">
        <path d="M10.9072 79.7098C8.2315 80.5747 1.40709 81.7234 1.09628 75.1692C0.784473 70.2205 1.23108 65.2531 2.42062 60.4393C3.10115 58.5902 4.59645 53.4666 5.5 51.7156C5.5 51.7156 8.07248 50.6898 14 49.7156C14.8496 49.576 14.2541 62.2814 15.0732 62.1897C20.3319 63.2805 23.283 63.3529 28.5419 62.1897C29.3757 62.2818 28.136 49.0766 29 49.2156C31.6983 49.6497 34.1166 50.8377 37.367 51.7156C38.867 51.7156 41.6103 56.115 41.6103 56.115C41.6103 56.115 45.4752 79.0341 37.367 81.1828C29.2588 83.3315 28.1101 80.0612 24.4209 78.3585C20.7317 76.6558 13.5829 78.845 10.9072 79.7098Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2017)">
        <path d="M9 40.7156H34V66.2635C24.2369 68.1898 18.7631 68.2094 9 66.2635V40.7156Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M23.0555 19.4778C22.9068 20.4238 21.7041 27.7211 19.9473 31.1266C18.0149 34.7753 15.8932 31.1266 15.8932 31.1266L13.677 18.0859C13.8121 17.0183 15.5689 3.73437 17.0149 4.01815C18.4608 4.30194 18.9744 3.4776 23.0555 19.4778Z" fill="#DEE1E9"/>
        <path d="M4.40723 56.0504C4.52435 53.4197 4.66399 50.753 4.82615 48.0503C5.13246 42.807 5.52886 37.6988 6.01535 32.7258C6.70209 27.2724 6.95064 21.7727 6.75861 16.2796C6.52538 12.1161 6.98199 7.94272 8.10997 3.92814C8.10997 3.92814 9.82621 1.22541 11.0154 4.90113C11.9578 8.39675 12.6755 11.9491 13.1641 15.5364L15.7722 30.942C15.7722 30.942 17.9344 34.5096 19.8263 30.8609C21.7182 27.2122 22.948 19.104 22.948 19.104C22.948 19.104 23.8399 9.64442 22.5831 5.59032C21.3264 1.53622 24.0696 0.455117 24.0696 0.455117C24.0696 0.455117 27.5562 -2.13951 31.6508 9.53631C35.7454 21.2121 35.0022 28.1041 35.0022 28.1041C35.0022 28.1041 33.9211 38.8475 35.0022 41.5232C36.3814 44.6785 37.3663 47.9918 37.9347 51.3882" fill="#DEE1E9"/>
        <path d="M1.09628 75.4156C1.40709 81.9697 8.2315 80.8211 10.9072 79.9562C13.5829 79.0913 20.7317 76.9021 24.4209 78.6048C28.1101 80.3075 29.2588 83.5779 37.367 81.4292C45.4752 79.2805 41.6103 56.3613 41.6103 56.3613C41.6103 56.3613 39.894 49.6045 30.8939 49.2936C30.0725 49.2731 29.2576 49.4444 28.5139 49.7939C27.7703 50.1434 27.1184 50.6616 26.6101 51.3072C25.1236 53.4694 20.583 53.7667 15.9748 52.0505C11.3667 50.3342 7.36663 50.8883 6.38013 52.5775C5.8068 53.4505 5.27906 54.3526 4.79903 55.2802C3.89547 57.0313 3.10115 58.8365 2.42062 60.6857C1.23108 65.4995 0.784473 70.4668 1.09628 75.4156Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path opacity="0.2" d="M33.2316 53.5898C34.2721 51.6582 32.2721 49.5202 30.7721 44.3298C29.6617 40.8115 29.2024 37.0876 29.4207 33.3741C29.7991 28.7736 31.137 27.6824 30.9883 23.1556C30.7291 20.7625 30.2769 18.399 29.6369 16.0927C27.0018 4.69457 24.754 1.49895 24.4297 1.55793C24.1053 1.61691 26.0018 3.83934 25.8396 11.7133C25.745 16.535 25.7315 17.9653 25.6234 20.2361C25.4747 22.8017 25.3531 23.3915 24.9477 27.7856C24.1774 36.0872 24.1639 38.9625 23.8261 38.9773C23.4882 38.992 23.3396 34.0819 22.0423 27.0484C20.8531 20.5457 18.6303 5.53868 17.4141 5.73037C16.7519 5.81884 16.5287 14.8246 16.2449 19.2629C15.6638 28.228 16.38 32.9317 15.5692 33.1382C14.7584 33.3446 12.6097 27.5055 11.7854 24.8513C10.2178 19.8969 9.88832 4.19055 9.48291 4.22004C9.0775 4.24953 9.43399 20.3393 9.10966 27.535C8.82588 33.6395 8.3529 36.5001 10.0016 39.7146C10.9205 41.5282 11.3529 41.1891 11.7854 42.6636C12.7854 46.2467 9.65021 48.2816 9.77183 53.3687C9.77183 54.7105 9.86643 57.114 11.3394 58.4853C12.8124 59.8566 14.9611 59.37 19.3665 58.4853C22.1639 57.925 21.7044 57.7775 28.9613 55.5362C32.0829 54.5925 32.7721 54.4303 33.2316 53.5898Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M6.90381 17.8706C9.25446 16.5064 10.5671 16.5685 12.9038 17.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path d="M14.9038 17.8706C17.6462 16.5064 19.1776 16.5685 21.9038 17.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path d="M23.9038 17.8706C27.4298 16.5064 29.3987 16.5685 32.9038 17.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M23.4823 21.8084C23.3336 22.7543 22.1309 30.0517 20.3741 33.4572C18.4417 37.1059 16.32 33.4572 16.32 33.4572L14.1038 20.4165C14.2389 19.3489 15.9957 6.06493 17.4416 6.34872C18.8876 6.63251 19.4011 5.80817 23.4823 21.8084Z" fill="#DEE1E9"/>
        <path d="M4.83398 58.3808C4.9511 55.7501 5.09074 53.0834 5.25291 50.3807C5.55922 45.1374 5.95562 40.0292 6.44211 35.0562C7.12885 29.6028 7.3774 24.1031 7.18536 18.61C6.95213 14.4465 7.40874 10.2731 8.53673 6.25853C8.53673 6.25853 10.253 3.55579 11.4422 7.23151C12.3846 10.7271 13.1022 14.2795 13.5908 17.8668L16.199 33.2724C16.199 33.2724 18.3612 36.84 20.2531 33.1913C22.145 29.5426 23.3748 21.4344 23.3748 21.4344C23.3748 21.4344 24.2667 11.9748 23.0099 7.9207C21.7531 3.8666 24.4964 2.7855 24.4964 2.7855C24.4964 2.7855 27.9829 0.190876 32.0776 11.8667C36.1722 23.5425 35.429 30.4345 35.429 30.4345C35.429 30.4345 34.3479 41.1779 35.429 43.8536C36.8082 47.0089 37.7931 50.3222 38.3614 53.7186" fill="#DEE1E9"/>
        <mask id="mask2217" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="44" height="35">
          <path d="M1.36239 78.3247C1.67321 84.8788 8.49761 83.7301 11.1733 82.8652C13.849 82.0004 20.9978 79.8112 24.687 81.5139C28.3762 83.2166 29.5249 86.4869 37.6331 84.3382C45.7413 82.1896 41.8764 59.2704 41.8764 59.2704C41.8764 59.2704 40.1602 52.5135 31.1601 52.2027C30.3386 52.1821 29.5237 52.3534 28.78 52.703C28.0364 53.0525 27.3845 53.5706 26.8762 54.2163C25.3897 56.3784 20.8491 56.6757 16.241 54.9595C11.6328 53.2433 7.63274 53.7973 6.64624 55.4865C6.07291 56.3596 5.54517 57.2617 5.06514 58.1893C4.16159 59.9403 3.36726 61.7456 2.68673 63.5947C1.49719 68.4085 1.05059 73.3759 1.36239 78.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask2217)">
          <path d="M1.36239 78.3247C1.67321 84.8788 8.49761 83.7301 11.1733 82.8652C13.849 82.0004 20.9978 79.8112 24.687 81.5139C28.3762 83.2166 29.5249 86.4869 37.6331 84.3382C45.7413 82.1896 41.8764 59.2704 41.8764 59.2704C41.8764 59.2704 40.1602 52.5135 31.1601 52.2027C30.3386 52.1821 29.5237 52.3534 28.78 52.703C28.0364 53.0525 27.3845 53.5706 26.8762 54.2163C25.3897 56.3784 20.8491 56.6757 16.241 54.9595C11.6328 53.2433 7.63274 53.7973 6.64624 55.4865C6.07291 56.3596 5.54517 57.2617 5.06514 58.1893C4.16159 59.9403 3.36726 61.7456 2.68673 63.5947C1.49719 68.4085 1.05059 73.3759 1.36239 78.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M21.7809 67.201C20.8005 67.201 19.2661 74.2024 19.2661 82.2011H21.1892H24.2661C24.2661 74.2024 22.7613 67.201 21.7809 67.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M33.9744 65.4332C35.0277 63.16 33.0032 60.644 31.4848 54.536C30.3609 50.3956 29.8959 46.0133 30.1169 41.6433C30.4999 36.2294 31.8542 34.9453 31.7037 29.6182C31.4414 26.802 30.9836 24.0206 30.3358 21.3065C27.6684 7.89319 25.3931 4.13258 25.0648 4.20199C24.7365 4.2714 26.6561 6.88676 26.492 16.1529C26.3962 21.827 26.3825 23.5102 26.2731 26.1824C26.1226 29.2017 25.9995 29.8958 25.5892 35.0668C24.8094 44.8361 24.7958 48.2198 24.4538 48.2371C24.1118 48.2545 23.9613 42.4762 22.6482 34.1992C21.4444 26.5468 19.1945 8.88654 17.9634 9.11212C17.2931 9.21624 17.0671 19.8142 16.7798 25.0372C16.1916 35.5873 16.9166 41.1227 16.0959 41.3656C15.2751 41.6086 13.1002 34.7371 12.2657 31.6137C10.679 25.7833 10.3455 7.30006 9.93509 7.33476C9.52471 7.36947 9.88557 26.3039 9.55728 34.7718C9.27002 41.9556 8.79125 45.3219 10.4601 49.1047C11.3903 51.2391 11.828 50.84 12.2657 52.5752C13.278 56.7918 10.1044 59.1864 10.2276 65.1729C10.2276 66.752 10.3233 69.5804 11.8143 71.1941C13.3053 72.8079 15.4803 72.2353 19.9397 71.1941C22.7713 70.5348 22.3062 70.3612 29.6518 67.7237C32.8117 66.6131 33.5093 66.4223 33.9744 65.4332Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="9" cy="6.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <circle cx="18" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <circle cx="25" cy="4.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M23.2161 27.6074C23.0675 28.5533 21.8648 35.8507 20.108 39.2562C18.1755 42.9049 16.0539 39.2562 16.0539 39.2562L13.8376 26.2155C13.9728 25.1479 15.7296 11.8639 17.1755 12.1477C18.6215 12.4315 19.135 11.6072 23.2161 27.6074Z" fill="#DEE1E9"/>
        <path d="M4.56787 64.1798C4.68499 61.5491 4.82463 58.8824 4.9868 56.1797C5.29311 50.9364 5.68951 45.8282 6.176 40.8552C6.86274 35.4018 7.11128 29.9021 6.91925 24.409C6.68602 20.2455 7.14263 16.0721 8.27062 12.0575C8.27062 12.0575 9.98686 9.3548 11.1761 13.0305C12.1184 16.5261 12.8361 20.0785 13.3247 23.6658L15.9329 39.0714C15.9329 39.0714 18.0951 42.639 19.987 38.9903C21.8789 35.3416 23.1086 27.2334 23.1086 27.2334C23.1086 27.2334 24.0005 17.7738 22.7438 13.7197C21.487 9.66561 24.2303 8.58451 24.2303 8.58451C24.2303 8.58451 27.7168 5.98989 31.8114 17.6657C35.9061 29.3415 35.1628 36.2335 35.1628 36.2335C35.1628 36.2335 34.0817 46.9769 35.1628 49.6526C36.5421 52.8079 37.527 56.1212 38.0953 59.5176" fill="#DEE1E9"/>
        <mask id="mask2317" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="57" width="44" height="35">
          <path d="M1.09628 84.1237C1.40709 90.6778 8.2315 89.5291 10.9072 88.6643C13.5829 87.7994 20.7317 85.6102 24.4209 87.3129C28.1101 89.0156 29.2588 92.2859 37.367 90.1372C45.4752 87.9886 41.6103 65.0694 41.6103 65.0694C41.6103 65.0694 39.894 58.3125 30.8939 58.0017C30.0725 57.9811 29.2576 58.1524 28.5139 58.502C27.7703 58.8515 27.1184 59.3697 26.6101 60.0153C25.1236 62.1775 20.583 62.4748 15.9748 60.7585C11.3667 59.0423 7.36663 59.5963 6.38013 61.2856C5.8068 62.1586 5.27906 63.0607 4.79903 63.9883C3.89547 65.7393 3.10115 67.5446 2.42062 69.3938C1.23108 74.2075 0.784473 79.1749 1.09628 84.1237Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask2317)">
          <path d="M1.09628 84.1237C1.40709 90.6778 8.2315 89.5291 10.9072 88.6643C13.5829 87.7994 20.7317 85.6102 24.4209 87.3129C28.1101 89.0156 29.2588 92.2859 37.367 90.1372C45.4752 87.9886 41.6103 65.0694 41.6103 65.0694C41.6103 65.0694 39.894 58.3125 30.8939 58.0017C30.0725 57.9811 29.2576 58.1524 28.5139 58.502C27.7703 58.8515 27.1184 59.3697 26.6101 60.0153C25.1236 62.1775 20.583 62.4748 15.9748 60.7585C11.3667 59.0423 7.36663 59.5963 6.38013 61.2856C5.8068 62.1586 5.27906 63.0607 4.79903 63.9883C3.89547 65.7393 3.10115 67.5446 2.42062 69.3938C1.23108 74.2075 0.784473 79.1749 1.09628 84.1237Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M21.5148 73.0001C20.5344 73.0001 19 80.0015 19 88.0001H20.9231H24C24 80.0015 22.4952 73.0001 21.5148 73.0001Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M33.7083 71.2322C34.7616 68.9591 32.7371 66.443 31.2187 60.335C30.0948 56.1946 29.6298 51.8123 29.8508 47.4423C30.2338 42.0284 31.5881 40.7443 31.4376 35.4172C31.1752 32.601 30.7175 29.8196 30.0697 27.1055C27.4023 13.6922 25.127 9.93159 24.7987 10.001C24.4704 10.0704 26.39 12.6858 26.2259 21.9519C26.1301 27.626 26.1164 29.3092 26.007 31.9814C25.8565 35.0007 25.7334 35.6948 25.323 40.8658C24.5433 50.6351 24.5297 54.0188 24.1877 54.0361C23.8457 54.0535 23.6952 48.2752 22.382 39.9982C21.1783 32.3458 18.9284 14.6856 17.6973 14.9111C17.027 15.0152 16.801 25.6132 16.5137 30.8362C15.9255 41.3864 16.6505 46.9217 15.8298 47.1647C15.009 47.4076 12.834 40.5361 11.9996 37.4127C10.4128 31.5824 10.0793 13.0991 9.66897 13.1338C9.2586 13.1685 9.61946 32.1029 9.29116 40.5708C9.0039 47.7546 8.52514 51.1209 10.194 54.9037C11.1242 57.0381 11.5619 56.639 11.9996 58.3742C13.0119 62.5908 9.83833 64.9854 9.96144 70.9719C9.96144 72.551 10.0572 75.3794 11.5482 76.9931C13.0392 78.6069 15.2142 78.0343 19.6736 76.9931C22.5052 76.3338 22.0401 76.1602 29.3857 73.5227C32.5456 72.4122 33.2432 72.2213 33.7083 71.2322Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="8.73389" cy="12" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <circle cx="17.7339" cy="14" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <circle cx="24.7339" cy="10" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2317)">
          <path d="M24.7339 1L25.5568 4.7391L28.5906 2.40373L26.8175 5.797L30.6427 5.95811L27.1033 7.4178L29.93 10L26.2804 8.8431L26.786 12.6382L24.7339 9.406L22.6818 12.6382L23.1873 8.8431L19.5377 10L22.3644 7.4178L18.825 5.95811L22.6502 5.797L20.8772 2.40373L23.911 4.7391L24.7339 1Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2317" x="18.625" y="0.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M23.2161 27.6074C23.0675 28.5533 21.8648 35.8507 20.108 39.2562C18.1755 42.9049 16.0539 39.2562 16.0539 39.2562L13.8376 26.2155C13.9728 25.1479 15.7296 11.8639 17.1755 12.1477C18.6215 12.4315 19.135 11.6072 23.2161 27.6074Z" fill="#DEE1E9"/>
      <path d="M4.56787 64.1798C4.68499 61.5491 4.82463 58.8824 4.9868 56.1797C5.29311 50.9364 5.68951 45.8282 6.176 40.8552C6.86274 35.4018 7.11128 29.9021 6.91925 24.409C6.68602 20.2455 7.14263 16.0721 8.27062 12.0575C8.27062 12.0575 9.98686 9.3548 11.1761 13.0305C12.1184 16.5261 12.8361 20.0785 13.3247 23.6658L15.9329 39.0714C15.9329 39.0714 18.0951 42.639 19.987 38.9903C21.8789 35.3416 23.1086 27.2334 23.1086 27.2334C23.1086 27.2334 24.0005 17.7738 22.7438 13.7197C21.487 9.66561 24.2303 8.58451 24.2303 8.58451C24.2303 8.58451 27.7168 5.98989 31.8114 17.6657C35.9061 29.3415 35.1628 36.2335 35.1628 36.2335C35.1628 36.2335 34.0817 46.9769 35.1628 49.6526C36.5421 52.8079 37.527 56.1212 38.0953 59.5176" fill="#DEE1E9"/>
      <mask id="mask2317" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="57" width="44" height="35">
        <path d="M1.09628 84.1237C1.40709 90.6778 8.2315 89.5291 10.9072 88.6643C13.5829 87.7994 20.7317 85.6102 24.4209 87.3129C28.1101 89.0156 29.2588 92.2859 37.367 90.1372C45.4752 87.9886 41.6103 65.0694 41.6103 65.0694C41.6103 65.0694 39.894 58.3125 30.8939 58.0017C30.0725 57.9811 29.2576 58.1524 28.5139 58.502C27.7703 58.8515 27.1184 59.3697 26.6101 60.0153C25.1236 62.1775 20.583 62.4748 15.9748 60.7585C11.3667 59.0423 7.36663 59.5963 6.38013 61.2856C5.8068 62.1586 5.27906 63.0607 4.79903 63.9883C3.89547 65.7393 3.10115 67.5446 2.42062 69.3938C1.23108 74.2075 0.784473 79.1749 1.09628 84.1237Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2317)">
        <path d="M1.09628 84.1237C1.40709 90.6778 8.2315 89.5291 10.9072 88.6643C13.5829 87.7994 20.7317 85.6102 24.4209 87.3129C28.1101 89.0156 29.2588 92.2859 37.367 90.1372C45.4752 87.9886 41.6103 65.0694 41.6103 65.0694C41.6103 65.0694 39.894 58.3125 30.8939 58.0017C30.0725 57.9811 29.2576 58.1524 28.5139 58.502C27.7703 58.8515 27.1184 59.3697 26.6101 60.0153C25.1236 62.1775 20.583 62.4748 15.9748 60.7585C11.3667 59.0423 7.36663 59.5963 6.38013 61.2856C5.8068 62.1586 5.27906 63.0607 4.79903 63.9883C3.89547 65.7393 3.10115 67.5446 2.42062 69.3938C1.23108 74.2075 0.784473 79.1749 1.09628 84.1237Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.5148 73.0001C20.5344 73.0001 19 80.0015 19 88.0001H20.9231H24C24 80.0015 22.4952 73.0001 21.5148 73.0001Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M33.7083 71.2322C34.7616 68.9591 32.7371 66.443 31.2187 60.335C30.0948 56.1946 29.6298 51.8123 29.8508 47.4423C30.2338 42.0284 31.5881 40.7443 31.4376 35.4172C31.1752 32.601 30.7175 29.8196 30.0697 27.1055C27.4023 13.6922 25.127 9.93159 24.7987 10.001C24.4704 10.0704 26.39 12.6858 26.2259 21.9519C26.1301 27.626 26.1164 29.3092 26.007 31.9814C25.8565 35.0007 25.7334 35.6948 25.323 40.8658C24.5433 50.6351 24.5297 54.0188 24.1877 54.0361C23.8457 54.0535 23.6952 48.2752 22.382 39.9982C21.1783 32.3458 18.9284 14.6856 17.6973 14.9111C17.027 15.0152 16.801 25.6132 16.5137 30.8362C15.9255 41.3864 16.6505 46.9217 15.8298 47.1647C15.009 47.4076 12.834 40.5361 11.9996 37.4127C10.4128 31.5824 10.0793 13.0991 9.66897 13.1338C9.2586 13.1685 9.61946 32.1029 9.29116 40.5708C9.0039 47.7546 8.52514 51.1209 10.194 54.9037C11.1242 57.0381 11.5619 56.639 11.9996 58.3742C13.0119 62.5908 9.83833 64.9854 9.96144 70.9719C9.96144 72.551 10.0572 75.3794 11.5482 76.9931C13.0392 78.6069 15.2142 78.0343 19.6736 76.9931C22.5052 76.3338 22.0401 76.1602 29.3857 73.5227C32.5456 72.4122 33.2432 72.2213 33.7083 71.2322Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="8.73389" cy="12" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="17.7339" cy="14" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="24.7339" cy="10" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2317)">
        <path d="M24.7339 1L25.5568 4.7391L28.5906 2.40373L26.8175 5.797L30.6427 5.95811L27.1033 7.4178L29.93 10L26.2804 8.8431L26.786 12.6382L24.7339 9.406L22.6818 12.6382L23.1873 8.8431L19.5377 10L22.3644 7.4178L18.825 5.95811L22.6502 5.797L20.8772 2.40373L23.911 4.7391L24.7339 1Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2317" x="18.625" y="0.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M23.4823 19.8084C23.3336 20.7543 22.1309 28.0517 20.3741 31.4572C18.4417 35.1059 16.32 31.4572 16.32 31.4572L14.1038 18.4165C14.2389 17.3489 15.9957 4.06493 17.4416 4.34872C18.8876 4.63251 19.4011 3.80817 23.4823 19.8084Z" fill="#DEE1E9"/>
        <path d="M4.83398 56.3807C4.9511 53.75 5.09074 51.0833 5.25291 48.3806C5.55922 43.1373 5.95562 38.0291 6.44211 33.0561C7.12885 27.6027 7.3774 22.1031 7.18536 16.61C6.95213 12.4465 7.40874 8.27304 8.53673 4.25846C8.53673 4.25846 10.253 1.55573 11.4422 5.23145C12.3846 8.72707 13.1022 12.2794 13.5908 15.8667L16.199 31.2723C16.199 31.2723 18.3612 34.8399 20.2531 31.1912C22.145 27.5425 23.3748 19.4343 23.3748 19.4343C23.3748 19.4343 24.2667 9.97474 23.0099 5.92064C21.7531 1.86654 24.4964 0.78544 24.4964 0.78544C24.4964 0.78544 27.9829 -1.80918 32.0776 9.86663C36.1722 21.5424 35.429 28.4344 35.429 28.4344C35.429 28.4344 34.3479 39.1778 35.429 41.8535C36.8082 45.0088 37.7931 48.3221 38.3614 51.7185" fill="#DEE1E9"/>
        <circle cx="10" cy="4.20093" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <mask id="mask2517" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="49" width="44" height="35">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask2517)">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M21.7809 70.2009C20.8005 70.2009 19.2661 75.3353 19.2661 81.2009H21.1892H24.2661C24.2661 75.3353 22.7613 70.2009 21.7809 70.2009Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2617" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="84">
          <path d="M23.4823 20.2429C23.3336 21.1889 22.1309 28.4863 20.3741 31.8917C18.4417 35.5404 16.32 31.8917 16.32 31.8917L14.1038 18.851C14.2389 17.7834 15.9957 4.4995 17.4416 4.78329C18.8876 5.06708 19.4011 4.24274 23.4823 20.2429Z" fill="#C7CAD5"/>
          <path d="M4.83398 56.8153C4.9511 54.1846 5.09074 51.5179 5.25291 48.8152C5.55922 43.5719 5.95562 38.4637 6.44211 33.4907C7.12885 28.0373 7.3774 22.5376 7.18536 17.0445C6.95213 12.881 7.40874 8.70761 8.53673 4.69303C8.53673 4.69303 10.253 1.9903 11.4422 5.66602C12.3846 9.16164 13.1022 12.714 13.5908 16.3013L16.199 31.7069C16.199 31.7069 18.3612 35.2745 20.2531 31.6258C22.145 27.9771 23.3748 19.8689 23.3748 19.8689C23.3748 19.8689 24.2667 10.4093 23.0099 6.35521C21.7531 2.30111 24.4964 1.22001 24.4964 1.22001C24.4964 1.22001 27.9829 -1.37461 32.0776 10.3012C36.1722 21.977 35.429 28.869 35.429 28.869C35.429 28.869 34.3479 39.6124 35.429 42.2881C36.8082 45.4434 37.7931 48.7567 38.3614 52.1531" fill="#DEE1E9"/>
          <path d="M1.52304 76.1805C1.83385 82.7346 8.65826 81.586 11.334 80.7211C14.0097 79.8562 21.1584 77.667 24.8476 79.3697C28.5369 81.0724 29.6855 84.3427 37.7937 82.1941C45.902 80.0454 42.037 57.1262 42.037 57.1262C42.037 57.1262 40.3208 50.3694 31.3207 50.0585C30.4993 50.038 29.6843 50.2093 28.9407 50.5588C28.197 50.9083 27.5452 51.4265 27.0369 52.0721C25.5504 54.2343 21.0098 54.5316 16.4016 52.8153C11.7934 51.0991 7.79338 51.6532 6.80689 53.3424C6.23356 54.2154 5.70582 55.1175 5.22579 56.0451C4.32223 57.7961 3.52791 59.6014 2.84738 61.4506C1.65784 66.2644 1.21123 71.2317 1.52304 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask2617)">
          <path d="M23.4823 20.2429C23.3336 21.1889 22.1309 28.4863 20.3741 31.8917C18.4417 35.5404 16.32 31.8917 16.32 31.8917L14.1038 18.851C14.2389 17.7834 15.9957 4.4995 17.4416 4.78329C18.8876 5.06708 19.4011 4.24274 23.4823 20.2429Z" fill="#C7CAD5"/>
          <path d="M4.83398 56.8153C4.9511 54.1846 5.09074 51.5179 5.25291 48.8152C5.55922 43.5719 5.95562 38.4637 6.44211 33.4907C7.12885 28.0373 7.3774 22.5376 7.18536 17.0445C6.95213 12.881 7.40874 8.70761 8.53673 4.69303C8.53673 4.69303 10.253 1.9903 11.4422 5.66602C12.3846 9.16164 13.1022 12.714 13.5908 16.3013L16.199 31.7069C16.199 31.7069 18.3612 35.2745 20.2531 31.6258C22.145 27.9771 23.3748 19.8689 23.3748 19.8689C23.3748 19.8689 24.2667 10.4093 23.0099 6.35521C21.7531 2.30111 24.4964 1.22001 24.4964 1.22001C24.4964 1.22001 27.9829 -1.37461 32.0776 10.3012C36.1722 21.977 35.429 28.869 35.429 28.869C35.429 28.869 34.3479 39.6124 35.429 42.2881C36.8082 45.4434 37.7931 48.7567 38.3614 52.1531" fill="#DEE1E9"/>
          <path d="M1.52304 76.1805C1.83385 82.7346 8.65826 81.586 11.334 80.7211C14.0097 79.8562 21.1584 77.667 24.8476 79.3697C28.5369 81.0724 29.6855 84.3427 37.7937 82.1941C45.902 80.0454 42.037 57.1262 42.037 57.1262C42.037 57.1262 40.3208 50.3694 31.3207 50.0585C30.4993 50.038 29.6843 50.2093 28.9407 50.5588C28.197 50.9083 27.5452 51.4265 27.0369 52.0721C25.5504 54.2343 21.0098 54.5316 16.4016 52.8153C11.7934 51.0991 7.79338 51.6532 6.80689 53.3424C6.23356 54.2154 5.70582 55.1175 5.22579 56.0451C4.32223 57.7961 3.52791 59.6014 2.84738 61.4506C1.65784 66.2644 1.21123 71.2317 1.52304 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M26.0259 52.5247H37.9471C35.785 47.1549 35.428 43.8947 35.7245 38.1564C35.7357 37.94 35.4864 37.8054 35.2961 37.932C34.2921 38.6 31.9339 40.4161 31.3622 43.0577C30.6698 46.2577 28.6665 47.0348 28.4539 47.1089C28.441 47.1134 28.4294 47.1179 28.417 47.1236L27.2341 47.6703C27.0895 47.7371 27.0309 47.8996 27.1032 48.0332L27.6321 49.0109C27.6769 49.0936 27.6728 49.1917 27.6213 49.271L25.7749 52.1151C25.6579 52.2954 25.7984 52.5247 26.0259 52.5247Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.951 39.0459C33.89 39.8938 32.4821 41.3307 32.0797 43.1903C31.3364 46.6248 29.1605 47.5809 28.7275 47.7361L27.9539 48.0936L28.2865 48.7084C28.4431 48.9979 28.4289 49.3413 28.2487 49.6189L26.8012 51.8485H36.9038C35.1271 47.1838 34.7511 43.9881 34.951 39.0459ZM28.7442 47.7284C28.7444 47.7283 28.7441 47.7285 28.7442 47.7284V47.7284ZM34.8687 37.3832C35.5976 36.8983 36.4933 37.4547 36.4554 38.1886C36.1626 43.8548 36.5118 47.0209 38.6328 52.2889L39 53.2009H26.0259C25.2297 53.2009 24.7379 52.3982 25.1476 51.7672L26.8699 49.114L26.4488 48.3356C26.1958 47.8679 26.4009 47.2992 26.9069 47.0654L28.0898 46.5188C28.1305 46.5 28.1664 46.4864 28.1959 46.4761C28.2325 46.4634 30.0133 45.8432 30.6448 42.9251C31.2743 40.0161 33.8377 38.0691 34.8687 37.3832Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask117" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="44" height="84">
          <path d="M23.4823 20.2429C23.3336 21.1889 22.1309 28.4863 20.3741 31.8917C18.4417 35.5404 16.32 31.8917 16.32 31.8917L14.1038 18.851C14.2389 17.7834 15.9957 4.4995 17.4416 4.78329C18.8876 5.06708 19.4011 4.24274 23.4823 20.2429Z" fill="#C7CAD5"/>
          <path d="M4.83398 56.8153C4.9511 54.1846 5.09074 51.5179 5.25291 48.8152C5.55922 43.5719 5.95562 38.4637 6.44211 33.4907C7.12885 28.0373 7.3774 22.5376 7.18536 17.0445C6.95213 12.881 7.40874 8.70761 8.53673 4.69303C8.53673 4.69303 10.253 1.9903 11.4422 5.66602C12.3846 9.16164 13.1022 12.714 13.5908 16.3013L16.199 31.7069C16.199 31.7069 18.3612 35.2745 20.2531 31.6258C22.145 27.9771 23.3748 19.8689 23.3748 19.8689C23.3748 19.8689 24.2667 10.4093 23.0099 6.35521C21.7531 2.30111 24.4964 1.22001 24.4964 1.22001C24.4964 1.22001 27.9829 -1.37461 32.0776 10.3012C36.1722 21.977 35.429 28.869 35.429 28.869C35.429 28.869 34.3479 39.6124 35.429 42.2881C36.8082 45.4434 37.7931 48.7567 38.3614 52.1531" fill="#DEE1E9"/>
          <path d="M1.52304 76.1805C1.83385 82.7346 8.65826 81.586 11.334 80.7211C14.0097 79.8562 21.1584 77.667 24.8476 79.3697C28.5369 81.0724 29.6855 84.3427 37.7937 82.1941C45.902 80.0454 42.037 57.1262 42.037 57.1262C42.037 57.1262 40.3208 50.3694 31.3207 50.0585C30.4993 50.038 29.6843 50.2093 28.9407 50.5588C28.197 50.9083 27.5452 51.4265 27.0369 52.0721C25.5504 54.2343 21.0098 54.5316 16.4016 52.8153C11.7934 51.0991 7.79338 51.6532 6.80689 53.3424C6.23356 54.2154 5.70582 55.1175 5.22579 56.0451C4.32223 57.7961 3.52791 59.6014 2.84738 61.4506C1.65784 66.2644 1.21123 71.2317 1.52304 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask117)">
          <path d="M23.4823 20.2429C23.3336 21.1889 22.1309 28.4863 20.3741 31.8917C18.4417 35.5404 16.32 31.8917 16.32 31.8917L14.1038 18.851C14.2389 17.7834 15.9957 4.4995 17.4416 4.78329C18.8876 5.06708 19.4011 4.24274 23.4823 20.2429Z" fill="#C7CAD5"/>
          <path d="M4.83398 56.8153C4.9511 54.1846 5.09074 51.5179 5.25291 48.8152C5.55922 43.5719 5.95562 38.4637 6.44211 33.4907C7.12885 28.0373 7.3774 22.5376 7.18536 17.0445C6.95213 12.881 7.40874 8.70761 8.53673 4.69303C8.53673 4.69303 10.253 1.9903 11.4422 5.66602C12.3846 9.16164 13.1022 12.714 13.5908 16.3013L16.199 31.7069C16.199 31.7069 18.3612 35.2745 20.2531 31.6258C22.145 27.9771 23.3748 19.8689 23.3748 19.8689C23.3748 19.8689 24.2667 10.4093 23.0099 6.35521C21.7531 2.30111 24.4964 1.22001 24.4964 1.22001C24.4964 1.22001 27.9829 -1.37461 32.0776 10.3012C36.1722 21.977 35.429 28.869 35.429 28.869C35.429 28.869 34.3479 39.6124 35.429 42.2881C36.8082 45.4434 37.7931 48.7567 38.3614 52.1531" fill="#DEE1E9"/>
          <path d="M1.52304 76.1805C1.83385 82.7346 8.65826 81.586 11.334 80.7211C14.0097 79.8562 21.1584 77.667 24.8476 79.3697C28.5369 81.0724 29.6855 84.3427 37.7937 82.1941C45.902 80.0454 42.037 57.1262 42.037 57.1262C42.037 57.1262 40.3208 50.3694 31.3207 50.0585C30.4993 50.038 29.6843 50.2093 28.9407 50.5588C28.197 50.9083 27.5452 51.4265 27.0369 52.0721C25.5504 54.2343 21.0098 54.5316 16.4016 52.8153C11.7934 51.0991 7.79338 51.6532 6.80689 53.3424C6.23356 54.2154 5.70582 55.1175 5.22579 56.0451C4.32223 57.7961 3.52791 59.6014 2.84738 61.4506C1.65784 66.2644 1.21123 71.2317 1.52304 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M29.7011 52.5315H37.8479C36.3703 48.561 36.1264 46.1504 36.329 41.9074C36.3367 41.7474 36.1662 41.6479 36.0362 41.7415C35.3501 42.2354 33.7385 43.5782 33.3479 45.5315C32.8746 47.8976 31.5057 48.4722 31.3604 48.527C31.3515 48.5303 31.3436 48.5337 31.3352 48.5379L30.5267 48.9421C30.428 48.9915 30.3879 49.1116 30.4373 49.2104L30.7988 49.9333C30.8293 49.9945 30.8266 50.067 30.7914 50.1257L29.5296 52.2286C29.4496 52.3619 29.5456 52.5315 29.7011 52.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M35.8004 42.5651C35.0753 43.1921 34.1131 44.2545 33.8381 45.6295C33.3302 48.169 31.8432 48.876 31.5473 48.9908L31.0187 49.2551L31.246 49.7097C31.353 49.9238 31.3432 50.1777 31.2201 50.3829L30.2309 52.0315H37.1349C35.9207 48.5824 35.6637 46.2194 35.8004 42.5651ZM31.5587 48.9851C31.5588 48.985 31.5586 48.9851 31.5587 48.9851V48.9851ZM35.7441 41.3357C36.2422 40.9771 36.8543 41.3885 36.8284 41.9312C36.6283 46.1208 36.867 48.4619 38.3164 52.3571L38.5674 53.0315H29.7011C29.157 53.0315 28.8209 52.4379 29.1008 51.9713L30.2779 50.0096L29.9901 49.434C29.8172 49.0882 29.9573 48.6677 30.3031 48.4948L31.1115 48.0906C31.1393 48.0767 31.1638 48.0667 31.184 48.0591C31.209 48.0497 32.426 47.5911 32.8576 45.4334C33.2877 43.2825 35.0395 41.8428 35.7441 41.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M23.4823 19.8084C23.3336 20.7543 22.1309 28.0517 20.3741 31.4572C18.4417 35.1059 16.32 31.4572 16.32 31.4572L14.1038 18.4165C14.2389 17.3489 15.9957 4.06493 17.4416 4.34872C18.8876 4.63251 19.4011 3.80817 23.4823 19.8084Z" fill="#DEE1E9"/>
        <path d="M4.83398 56.3807C4.9511 53.75 5.09074 51.0833 5.25291 48.3806C5.55922 43.1373 5.95562 38.0291 6.44211 33.0561C7.12885 27.6027 7.3774 22.1031 7.18536 16.61C6.95213 12.4465 7.40874 8.27304 8.53673 4.25846C8.53673 4.25846 10.253 1.55573 11.4422 5.23145C12.3846 8.72707 13.1022 12.2794 13.5908 15.8667L16.199 31.2723C16.199 31.2723 18.3612 34.8399 20.2531 31.1912C22.145 27.5425 23.3748 19.4343 23.3748 19.4343C23.3748 19.4343 24.2667 9.97474 23.0099 5.92064C21.7531 1.86654 24.4964 0.78544 24.4964 0.78544C24.4964 0.78544 27.9829 -1.80918 32.0776 9.86663C36.1722 21.5424 35.429 28.4344 35.429 28.4344C35.429 28.4344 34.3479 39.1778 35.429 41.8535C36.8082 45.0088 37.7931 48.3221 38.3614 51.7185" fill="#DEE1E9"/>
        <mask id="mask217" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="49" width="44" height="35">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask217)">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M21.1123 67.2009C17.5012 67.2009 13.2661 71.9105 13.2661 80.4423L19.8046 82.0975L30.2661 83.2009C30.2661 74.6691 24.7234 67.2009 21.1123 67.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M33.9744 63.4331C35.0277 61.1599 33.0032 58.6438 31.4848 52.5359C30.3609 48.3954 29.8959 44.0132 30.1169 39.6432C30.4999 34.2293 31.8542 32.9452 31.7037 27.618C31.4414 24.8018 30.9836 22.0205 30.3358 19.3063C27.6684 5.89307 25.3931 2.13246 25.0648 2.20187C24.7365 2.27128 26.6561 4.88664 26.492 14.1527C26.3962 19.8269 26.3825 21.5101 26.2731 24.1823C26.1226 27.2016 25.9995 27.8957 25.5892 33.0667C24.8094 42.836 24.7958 46.2196 24.4538 46.237C24.1118 46.2543 23.9613 40.4761 22.6482 32.199C21.4444 24.5467 19.1945 6.88642 17.9634 7.112C17.2931 7.21611 17.0671 17.814 16.7798 23.0371C16.1916 33.5872 16.9166 39.1226 16.0959 39.3655C15.2751 39.6085 13.1002 32.737 12.2657 29.6136C10.679 23.7832 10.3455 5.29994 9.93509 5.33464C9.52471 5.36935 9.88557 24.3038 9.55728 32.7717C9.27002 39.9555 8.79125 43.3218 10.4601 47.1046C11.3903 49.2389 11.828 48.8398 12.2657 50.5751C13.278 54.7916 10.1044 57.1863 10.2276 63.1728C10.2276 64.7518 10.3233 67.5803 11.8143 69.194C13.3053 70.8078 15.4803 70.2352 19.9397 69.194C22.7713 68.5346 22.3062 68.3611 29.6518 65.7236C32.8117 64.613 33.5093 64.4221 33.9744 63.4331Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M23.4823 19.8084C23.3336 20.7543 22.1309 28.0517 20.3741 31.4572C18.4417 35.1059 16.32 31.4572 16.32 31.4572L14.1038 18.4165C14.2389 17.3489 15.9957 4.06493 17.4416 4.34872C18.8876 4.63251 19.4011 3.80817 23.4823 19.8084Z" fill="#DEE1E9"/>
        <path d="M4.83398 56.3807C4.9511 53.75 5.09074 51.0833 5.25291 48.3806C5.55922 43.1373 5.95562 38.0291 6.44211 33.0561C7.12885 27.6027 7.3774 22.1031 7.18536 16.61C6.95213 12.4465 7.40874 8.27304 8.53673 4.25846C8.53673 4.25846 10.253 1.55573 11.4422 5.23145C12.3846 8.72707 13.1022 12.2794 13.5908 15.8667L16.199 31.2723C16.199 31.2723 18.3612 34.8399 20.2531 31.1912C22.145 27.5425 23.3748 19.4343 23.3748 19.4343C23.3748 19.4343 24.2667 9.97474 23.0099 5.92064C21.7531 1.86654 24.4964 0.78544 24.4964 0.78544C24.4964 0.78544 27.9829 -1.80918 32.0776 9.86663C36.1722 21.5424 35.429 28.4344 35.429 28.4344C35.429 28.4344 34.3479 39.1778 35.429 41.8535C36.8082 45.0088 37.7931 48.3221 38.3614 51.7185" fill="#DEE1E9"/>
        <mask id="mask317" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="49" width="44" height="35">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask317)">
          <path d="M1.36239 76.3245C1.67321 82.8787 8.49761 81.73 11.1733 80.8651C13.849 80.0003 20.9978 77.811 24.687 79.5138C28.3762 81.2165 29.5249 84.4868 37.6331 82.3381C45.7413 80.1894 41.8764 57.2703 41.8764 57.2703C41.8764 57.2703 40.1602 50.5134 31.1601 50.2026C30.3386 50.182 29.5237 50.3533 28.78 50.7028C28.0364 51.0524 27.3845 51.5705 26.8762 52.2161C25.3897 54.3783 20.8491 54.6756 16.241 52.9594C11.6328 51.2432 7.63274 51.7972 6.64624 53.4864C6.07291 54.3594 5.54517 55.2616 5.06514 56.1892C4.16159 57.9402 3.36726 59.7455 2.68673 61.5946C1.49719 66.4084 1.05059 71.3758 1.36239 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
          <path d="M21.7809 70.2009C20.8005 70.2009 19.2661 75.3353 19.2661 81.2009H21.1892H24.2661C24.2661 75.3353 22.7613 70.2009 21.7809 70.2009Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
        <path d="M23.4823 19.376C23.3336 20.322 22.1309 27.6193 20.3741 31.0248C18.4417 34.6735 16.32 31.0248 16.32 31.0248L14.1038 17.9841C14.2389 16.9165 15.9957 3.63256 17.4416 3.91635C18.8876 4.20013 19.4011 3.3758 23.4823 19.376Z" fill="#C7CAD5"/>
        <path d="M4.83398 55.9483C4.9511 53.3177 5.09074 50.651 5.25291 47.9482C5.55922 42.7049 5.95562 37.5968 6.44211 32.6237C7.12885 27.1703 7.3774 21.6707 7.18536 16.1776C6.95213 12.0141 7.40874 7.84067 8.53673 3.82609C8.53673 3.82609 10.253 1.12336 11.4422 4.79908C12.3846 8.2947 13.1022 11.847 13.5908 15.4343L16.199 30.8399C16.199 30.8399 18.3612 34.4075 20.2531 30.7588C22.145 27.1101 23.3748 19.0019 23.3748 19.0019C23.3748 19.0019 24.2667 9.54237 23.0099 5.48827C21.7531 1.43416 24.4964 0.353067 24.4964 0.353067C24.4964 0.353067 27.9829 -2.24156 32.0776 9.43426C36.1722 21.1101 35.429 28.0021 35.429 28.0021C35.429 28.0021 34.3479 38.7454 35.429 41.4211C36.8082 44.5764 37.7931 47.8898 38.3614 51.2861" fill="#DEE1E9"/>
        <path d="M1.52304 75.3135C1.83385 81.8677 8.65826 80.719 11.334 79.8541C14.0097 78.9893 21.1584 76.8 24.8476 78.5028C28.5369 80.2055 29.6855 83.4758 37.7937 81.3271C45.902 79.1785 42.037 56.2593 42.037 56.2593C42.037 56.2593 40.3208 49.5024 31.3207 49.1916C30.4993 49.171 29.6843 49.3423 28.9407 49.6918C28.197 50.0414 27.5452 50.5595 27.0369 51.2051C25.5504 53.3673 21.0098 53.6646 16.4016 51.9484C11.7934 50.2322 7.79338 50.7862 6.80689 52.4754C6.23356 53.3485 5.70582 54.2506 5.22579 55.1782C4.32223 56.9292 3.52791 58.7345 2.84738 60.5836C1.65784 65.3974 1.21123 70.3648 1.52304 75.3135Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M10.2661 65.2009L12.1328 67.7018L13.7661 66.2061V69.5201L16.3283 68.9522L14.9328 70.7028L17.2661 72.7035L14.9328 74.7042L16.3283 76.4548L13.9994 75.7045L13.7661 79.2009L11.5162 77.7039L10.7328 78.7056L9.01602 77.7039L6.76611 79.2009L6.06611 77.2051L4.20394 76.4548L4.66611 73.7038L3.26611 72.7035L5.59945 71.203L4.20394 68.9522L6.06611 68.7021L6.76611 66.2061L9.79945 68.202L10.2661 65.2009Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
        <path d="M23.4823 19.376C23.3336 20.322 22.1309 27.6193 20.3741 31.0248C18.4417 34.6735 16.32 31.0248 16.32 31.0248L14.1038 17.9841C14.2389 16.9165 15.9957 3.63256 17.4416 3.91635C18.8876 4.20013 19.4011 3.3758 23.4823 19.376Z" fill="#C7CAD5"/>
        <path d="M4.83398 55.9483C4.9511 53.3177 5.09074 50.651 5.25291 47.9482C5.55922 42.7049 5.95562 37.5968 6.44211 32.6237C7.12885 27.1703 7.3774 21.6707 7.18536 16.1776C6.95213 12.0141 7.40874 7.84067 8.53673 3.82609C8.53673 3.82609 10.253 1.12336 11.4422 4.79908C12.3846 8.2947 13.1022 11.847 13.5908 15.4343L16.199 30.8399C16.199 30.8399 18.3612 34.4075 20.2531 30.7588C22.145 27.1101 23.3748 19.0019 23.3748 19.0019C23.3748 19.0019 24.2667 9.54237 23.0099 5.48827C21.7531 1.43416 24.4964 0.353067 24.4964 0.353067C24.4964 0.353067 27.9829 -2.24156 32.0776 9.43426C36.1722 21.1101 35.429 28.0021 35.429 28.0021C35.429 28.0021 34.3479 38.7454 35.429 41.4211C36.8082 44.5764 37.7931 47.8898 38.3614 51.2861" fill="#DEE1E9"/>
        <path d="M1.52304 75.3135C1.83385 81.8677 8.65826 80.719 11.334 79.8541C14.0097 78.9893 21.1584 76.8 24.8476 78.5028C28.5369 80.2055 29.6855 83.4758 37.7937 81.3271C45.902 79.1785 42.037 56.2593 42.037 56.2593C42.037 56.2593 40.3208 49.5024 31.3207 49.1916C30.4993 49.171 29.6843 49.3423 28.9407 49.6918C28.197 50.0414 27.5452 50.5595 27.0369 51.2051C25.5504 53.3673 21.0098 53.6646 16.4016 51.9484C11.7934 50.2322 7.79338 50.7862 6.80689 52.4754C6.23356 53.3485 5.70582 54.2506 5.22579 55.1782C4.32223 56.9292 3.52791 58.7345 2.84738 60.5836C1.65784 65.3974 1.21123 70.3648 1.52304 75.3135Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M9.26611 67.2009L10.8661 69.3445L12.2661 68.0625V70.9031L14.4623 70.4163L13.2661 71.9168L15.2661 73.6317L13.2661 75.3466L14.4623 76.8471L12.4661 76.204L12.2661 79.2009L10.3376 77.9178L9.66611 78.7763L8.1946 77.9178L6.26611 79.2009L5.66611 77.4902L4.06996 76.8471L4.46611 74.4891L3.26611 73.6317L5.26611 72.3456L4.06996 70.4163L5.66611 70.202L6.26611 68.0625L8.86611 69.7732L9.26611 67.2009Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
        <path d="M23.4823 19.3084C23.3336 20.2543 22.1309 27.5517 20.3741 30.9572C18.4417 34.6059 16.32 30.9572 16.32 30.9572L14.1038 17.9165C14.2389 16.8489 15.9957 3.56493 17.4416 3.84872C18.8876 4.13251 19.4011 3.30817 23.4823 19.3084Z" fill="#C7CAD5"/>
        <path d="M4.83398 55.8807C4.9511 53.25 5.09074 50.5833 5.25291 47.8806C5.55922 42.6373 5.95562 37.5291 6.44211 32.5561C7.12885 27.1027 7.3774 21.6031 7.18536 16.11C6.95213 11.9465 7.40874 7.77304 8.53673 3.75846C8.53673 3.75846 10.253 1.05573 11.4422 4.73145C12.3846 8.22707 13.1022 11.7794 13.5908 15.3667L16.199 30.7723C16.199 30.7723 18.3612 34.3399 20.2531 30.6912C22.145 27.0425 23.3748 18.9343 23.3748 18.9343C23.3748 18.9343 24.2667 9.47474 23.0099 5.42064C21.7531 1.36654 24.4964 0.28544 24.4964 0.28544C24.4964 0.28544 27.9829 -2.30918 32.0776 9.36663C36.1722 21.0424 35.429 27.9344 35.429 27.9344C35.429 27.9344 34.3479 38.6778 35.429 41.3535C36.8082 44.5088 37.7931 47.8221 38.3614 51.2185" fill="#DEE1E9"/>
        <path d="M1.52304 75.2459C1.83385 81.8001 8.65826 80.6514 11.334 79.7865C14.0097 78.9216 21.1584 76.7324 24.8476 78.4351C28.5369 80.1379 29.6855 83.4082 37.7937 81.2595C45.902 79.1108 42.037 56.1916 42.037 56.1916C42.037 56.1916 40.3208 49.4348 31.3207 49.124C30.4993 49.1034 29.6843 49.2747 28.9407 49.6242C28.197 49.9738 27.5452 50.4919 27.0369 51.1375C25.5504 53.2997 21.0098 53.597 16.4016 51.8808C11.7934 50.1645 7.79338 50.7186 6.80689 52.4078C6.23356 53.2808 5.70582 54.1829 5.22579 55.1105C4.32223 56.8616 3.52791 58.6669 2.84738 60.516C1.65784 65.3298 1.21123 70.2971 1.52304 75.2459Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M9.76611 73.1333L10.6994 74.3837L11.5161 73.6359V75.2929L12.7972 75.0089L12.0994 75.8842L13.2661 76.8846L12.0994 77.8849L12.7972 78.7602L11.6328 78.3851L11.5161 80.1333L10.3912 79.3848L9.99945 79.8856L9.14106 79.3848L8.01611 80.1333L7.66611 79.1354L6.73502 78.7602L6.96611 77.3848L6.26611 76.8846L7.43278 76.1343L6.73502 75.0089L7.66611 74.8839L8.01611 73.6359L9.53278 74.6338L9.76611 73.1333Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
        <path d="M18.0618 2.68065L20.1455 1.41089H23.6293L25.7293 2.68065L24.1176 4.22716L26.2013 5.77367L24.313 7.32018L26.6083 9.0132L24.7851 10.5597L27.0804 11.9597L25.0618 13.376L27.0804 14.9876L25.3874 16.5341L27.6176 17.9504L25.8595 19.4155L28.1548 20.962L26.006 22.5085L28.1548 24.1201L26.6083 25.4713L28.692 27.0178L26.8688 28.4992L28.9525 30.0457L27.1455 31.7876L28.1548 33.4643V47.7247L26.2665 55.0503H20.1455" fill="#8889BE"/>
        <path d="M18.0618 2.68065L20.1455 1.41089H23.6293L25.7293 2.68065L24.1176 4.22716L26.2013 5.77367L24.313 7.32018L26.6083 9.0132L24.7851 10.5597L27.0804 11.9597L25.0618 13.376L27.0804 14.9876L25.3874 16.5341L27.6176 17.9504L25.8595 19.4155L28.1548 20.962L26.006 22.5085L28.1548 24.1201L26.6083 25.4713L28.692 27.0178L26.8688 28.4992L28.9525 30.0457L27.1455 31.7876L28.1548 33.4643V47.7247L26.2665 55.0503H20.1455" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M25.4691 2.68065L23.3854 1.41089H19.918L17.8017 2.68065L19.4296 4.22716L17.3296 5.77367L19.218 7.32018L16.9389 9.0132L18.7459 10.5597L16.4668 11.9597L18.4854 13.376L16.4668 14.9876L18.1435 16.5341L15.9296 17.9504L17.6714 19.4155L15.3924 20.962L17.5412 22.5085L15.3924 24.1201L16.9389 25.4713L14.8552 27.0178L16.6621 28.4992L14.5784 30.0457L16.4017 31.7876L15.3924 33.4643V47.7247L17.2645 55.0503H21.1714" fill="#8889BE"/>
        <path d="M25.4691 2.68065L23.3854 1.41089H19.918L17.8017 2.68065L19.4296 4.22716L17.3296 5.77367L19.218 7.32018L16.9389 9.0132L18.7459 10.5597L16.4668 11.9597L18.4854 13.376L16.4668 14.9876L18.1435 16.5341L15.9296 17.9504L17.6714 19.4155L15.3924 20.962L17.5412 22.5085L15.3924 24.1201L16.9389 25.4713L14.8552 27.0178L16.6621 28.4992L14.5784 30.0457L16.4017 31.7876L15.3924 33.4643V47.7247L17.2645 55.0503H21.1714" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M10.9072 71.8566C8.2315 72.7214 1.40709 73.8701 1.09628 67.316C0.784473 62.3672 1.23108 57.3998 2.42062 52.5861C3.10115 50.7369 4.66969 44.5875 5.57324 42.8365C8.69343 41.946 11.469 41.2914 14.0732 40.8634C14.9228 40.7237 14.2541 54.4281 15.0732 54.3364C15.5882 55.9047 25.9131 55.7125 28.5419 54.3364C29.3757 54.4286 27.6779 40.7244 28.5419 40.8634C31.2402 41.2974 34.1166 41.9586 37.367 42.8365C38.867 42.8365 41.6103 48.2617 41.6103 48.2617C41.6103 48.2617 45.4752 71.1809 37.367 73.3295C29.2588 75.4782 28.1101 72.2079 24.4209 70.5052C20.7317 68.8025 13.5829 70.9917 10.9072 71.8566Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <mask id="mask517" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="40" width="44" height="35">
          <path d="M10.9072 71.8567C8.2315 72.7215 1.40709 73.8702 1.09628 67.3161C0.784473 62.3673 1.23108 57.3999 2.42062 52.5862C3.10115 50.737 4.59645 45.6135 5.5 43.8624C5.5 43.8624 9.13722 42.8366 15.0647 41.8624C15.9143 41.7228 15.3189 54.4282 16.138 54.3365C21.3966 55.4273 21.9807 55.4997 27.2396 54.3365C28.0735 54.4286 27.1679 41.2234 28.0318 41.3624C30.7301 41.7965 34.1166 42.9845 37.367 43.8624C38.867 43.8624 41.6103 48.2618 41.6103 48.2618C41.6103 48.2618 45.4752 71.181 37.367 73.3296C29.2588 75.4783 28.1101 72.208 24.4209 70.5053C20.7317 68.8026 13.5829 70.9918 10.9072 71.8567Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        </mask>
        <g mask="url(#mask517)">
          <path d="M9 32.8623H34V58.4103C24.2369 60.3365 18.7631 60.3561 9 58.4103V32.8623Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
        <path d="M17.7992 2.17846L19.8829 0.908691H23.3666L25.4666 2.17846L23.855 3.72496L25.9387 5.27147L24.0503 6.81798L26.3457 8.511L24.5224 10.0575L26.8178 11.4575L24.7992 12.8738L26.8178 14.4854L25.1248 16.0319L27.355 17.4482L25.5969 18.9133L27.8922 20.4598L25.7434 22.0063L27.8922 23.6179L26.3457 24.9691L28.4294 26.5156L26.6062 27.997L28.6899 29.5435L26.8829 31.2854L27.8922 32.9621V47.2225L26.0038 54.5481H19.8829" fill="#8889BE"/>
        <path d="M17.7992 2.17846L19.8829 0.908691H23.3666L25.4666 2.17846L23.855 3.72496L25.9387 5.27147L24.0503 6.81798L26.3457 8.511L24.5224 10.0575L26.8178 11.4575L24.7992 12.8738L26.8178 14.4854L25.1248 16.0319L27.355 17.4482L25.5969 18.9133L27.8922 20.4598L25.7434 22.0063L27.8922 23.6179L26.3457 24.9691L28.4294 26.5156L26.6062 27.997L28.6899 29.5435L26.8829 31.2854L27.8922 32.9621V47.2225L26.0038 54.5481H19.8829" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M25.2065 2.17846L23.1228 0.908691H19.6553L17.539 2.17846L19.1669 3.72496L17.067 5.27147L18.9553 6.81798L16.6763 8.511L18.4832 10.0575L16.2042 11.4575L18.2228 12.8738L16.2042 14.4854L17.8809 16.0319L15.667 17.4482L17.4088 18.9133L15.1297 20.4598L17.2786 22.0063L15.1297 23.6179L16.6763 24.9691L14.5925 26.5156L16.3995 27.997L14.3158 29.5435L16.139 31.2854L15.1297 32.9621V47.2225L17.0018 54.5481H20.9088" fill="#8889BE"/>
        <path d="M25.2065 2.17846L23.1228 0.908691H19.6553L17.539 2.17846L19.1669 3.72496L17.067 5.27147L18.9553 6.81798L16.6763 8.511L18.4832 10.0575L16.2042 11.4575L18.2228 12.8738L16.2042 14.4854L17.8809 16.0319L15.667 17.4482L17.4088 18.9133L15.1297 20.4598L17.2786 22.0063L15.1297 23.6179L16.6763 24.9691L14.5925 26.5156L16.3995 27.997L14.3158 29.5435L16.139 31.2854L15.1297 32.9621V47.2225L17.0018 54.5481H20.9088" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M10.9072 67.8449C8.2315 68.7097 1.40709 69.8584 1.09628 63.3043C0.784473 58.3555 1.23108 53.3881 2.42062 48.5743C3.10115 46.7252 4.66969 40.5758 5.57324 38.8248C8.69343 37.9343 11.469 37.2796 14.0732 36.8516C14.9228 36.712 14.2541 50.4164 15.0732 50.3247C15.2805 51.9812 24.9244 51.8567 27.8826 50.3247C28.7164 50.4168 27.6779 36.7127 28.5419 36.8516C31.2402 37.2857 34.1166 37.9468 37.367 38.8248C38.867 38.8248 41.6103 44.25 41.6103 44.25C41.6103 44.25 45.4752 67.1692 37.367 69.3178C29.2588 71.4665 28.1101 68.1962 24.4209 66.4935C20.7317 64.7908 13.5829 66.98 10.9072 67.8449Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
        <path d="M0 80.771H21V85.771H0V80.771Z" fill="#838AA2"/>
        <path d="M22 80.771H43V85.771H22V80.771Z" fill="#838AA2"/>
        <path d="M22.4823 19.9461C22.3336 20.892 21.1309 28.1894 19.3741 31.5949C17.4417 35.2435 15.32 31.5949 15.32 31.5949L13.1038 18.5542C13.2389 17.4866 14.9957 4.20263 16.4416 4.48642C17.8876 4.7702 18.4011 3.94587 22.4823 19.9461Z" fill="#C7CAD5"/>
        <path d="M4.25291 72.9043C4.37003 70.2736 4.09077 51.2213 4.25294 48.5186C4.55925 43.2752 4.95565 38.1671 5.44214 33.194C6.12888 27.7407 6.37742 22.241 6.18539 16.7479C5.95216 12.5844 6.40877 8.41098 7.53676 4.3964C7.53676 4.3964 9.253 1.69367 10.4422 5.36939C11.3846 8.86501 12.1023 12.4174 12.5909 16.0046L15.199 31.4102C15.199 31.4102 17.3612 34.9778 19.2531 31.3292C21.145 27.6805 22.3748 19.5723 22.3748 19.5723C22.3748 19.5723 23.2667 10.1127 22.0099 6.05858C20.7531 2.00448 23.4964 0.923379 23.4964 0.923379C23.4964 0.923379 26.9829 -1.67125 31.0776 10.0046C35.1722 21.6804 34.429 28.5724 34.429 28.5724C34.429 28.5724 33.3479 39.3157 34.429 41.9914C35.8082 45.1467 37.212 64.8457 37.7803 68.2421" fill="#DEE1E9"/>
        <path d="M9.90721 79.8986C7.2315 80.7634 0.407095 81.9121 0.09628 75.358C-0.215527 70.4092 0.231079 65.4418 1.42062 60.6281C2.10115 58.7789 3.66969 52.6295 4.57324 50.8785C7.69343 49.988 10.469 49.3334 13.0732 48.9054C13.9228 48.7657 13.2541 62.4701 14.0732 62.3784C14.2805 64.0349 24.5836 63.9104 27.5419 62.3784C28.3757 62.4705 26.6779 48.7664 27.5419 48.9054C30.2402 49.3394 33.1166 50.0005 36.367 50.8785C37.867 50.8785 40.6103 56.3037 40.6103 56.3037C40.6103 56.3037 44.4752 79.2229 36.367 81.3715C28.2588 83.5202 27.1101 80.2499 23.4209 78.5472C19.7317 76.8445 12.5829 79.0337 9.90721 79.8986Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
        <path d="M23.1518 19.5115C23.0032 20.4574 21.8004 27.7548 20.0437 31.1603C18.1112 34.809 15.9896 31.1603 15.9896 31.1603L13.7733 18.1196C13.9085 17.052 15.6652 3.76806 17.1112 4.05185C18.5572 4.33563 19.0707 3.5113 23.1518 19.5115Z" fill="#DEE1E9"/>
        <path d="M4.50354 56.0841C4.62066 53.4534 4.7603 50.7867 4.92246 48.084C5.22877 42.8407 5.62518 37.7325 6.11167 32.7595C6.79841 27.3061 7.04695 21.8064 6.85492 16.3133C6.62169 12.1498 7.0783 7.97641 8.20629 3.96183C8.20629 3.96183 9.92252 1.2591 11.1117 4.93482C12.0541 8.43044 12.7718 11.9828 13.2604 15.5701L15.8685 30.9757C15.8685 30.9757 18.0307 34.5433 19.9226 30.8946C21.8146 27.2459 23.0443 19.1377 23.0443 19.1377C23.0443 19.1377 23.9362 9.67811 22.6794 5.62401C21.4227 1.56991 24.1659 0.488809 24.1659 0.488809C24.1659 0.488809 27.6525 -2.10582 31.7471 9.57C35.8418 21.2458 35.0985 28.1378 35.0985 28.1378C35.0985 28.1378 34.0174 38.8812 35.0985 41.5569C36.4777 44.7122 37.4627 48.0255 38.031 51.4219" fill="#DEE1E9"/>
        <path d="M1.19259 75.4493C1.50341 82.0034 8.32782 80.8548 11.0035 79.9899C13.6792 79.125 20.828 76.9358 24.5172 78.6385C28.2064 80.3412 29.3551 83.6115 37.4633 81.4629C45.5715 79.3142 41.7066 56.395 41.7066 56.395C41.7066 56.395 39.9904 49.6382 30.9903 49.3273C30.1688 49.3068 29.3539 49.4781 28.6102 49.8276C27.8666 50.1771 27.2147 50.6953 26.7064 51.3409C25.2199 53.5031 20.6793 53.8004 16.0712 52.0841C11.463 50.3679 7.46294 50.922 6.47644 52.6112C5.90311 53.4842 5.37537 54.3863 4.89534 55.3139C3.99179 57.0649 3.19747 58.8702 2.51693 60.7194C1.32739 65.5332 0.880786 70.5005 1.19259 75.4493Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path opacity="0.2" d="M33.3279 53.6235C34.3684 51.6919 32.3684 49.5538 30.8684 44.3635C29.758 40.8451 29.2987 37.1213 29.517 33.4078C29.8954 28.8073 31.2333 27.7161 31.0846 23.1893C30.8254 20.7962 30.3732 18.4327 29.7333 16.1263C27.0981 4.72826 24.8503 1.53264 24.526 1.59162C24.2017 1.6506 26.0981 3.87303 25.9359 11.747C25.8413 16.5687 25.8278 17.999 25.7197 20.2698C25.571 22.8354 25.4494 23.4252 25.044 27.8193C24.2737 36.1209 24.2602 38.9962 23.9224 39.011C23.5845 39.0257 23.4359 34.1156 22.1386 27.0821C20.9494 20.5794 18.7267 5.57237 17.5104 5.76406C16.8483 5.85253 16.625 14.8582 16.3412 19.2966C15.7601 28.2617 16.4763 32.9654 15.6655 33.1719C14.8547 33.3783 12.706 27.5392 11.8817 24.885C10.3141 19.9306 9.98463 4.22424 9.57922 4.25373C9.17381 4.28322 9.53031 20.373 9.20598 27.5687C8.92219 33.6732 8.44921 36.5338 10.0979 39.7482C11.0168 41.5619 11.4492 41.2228 11.8817 42.6973C12.8817 46.2804 9.74652 48.3153 9.86815 53.4024C9.86815 54.7442 9.96274 57.1477 11.4357 58.519C12.9087 59.8903 15.0574 59.4037 19.4629 58.519C22.2602 57.9587 21.8007 57.8112 29.0576 55.5699C32.1792 54.6262 32.8684 54.464 33.3279 53.6235Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
        <path d="M23.1518 19.3474C23.0032 20.2934 21.8004 27.5908 20.0437 30.9962C18.1112 34.6449 15.9896 30.9962 15.9896 30.9962L13.7733 17.9555C13.9085 16.8879 15.6652 3.604 17.1112 3.88778C18.5572 4.17157 19.0707 3.34723 23.1518 19.3474Z" fill="#DEE1E9"/>
        <path d="M4.50354 55.92C4.62066 53.2893 4.7603 50.6227 4.92246 47.9199C5.22877 42.6766 5.62518 37.5684 6.11167 32.5954C6.79841 27.142 7.04695 21.6424 6.85492 16.1493C6.62169 11.9858 7.0783 7.81235 8.20629 3.79777C8.20629 3.79777 9.92252 1.09504 11.1117 4.77076C12.0541 8.26638 12.7718 11.8187 13.2604 15.406L15.8685 30.8116C15.8685 30.8116 18.0307 34.3792 19.9226 30.7305C21.8146 27.0818 23.0443 18.9736 23.0443 18.9736C23.0443 18.9736 23.9362 9.51405 22.6794 5.45995C21.4227 1.40584 24.1659 0.324746 24.1659 0.324746C24.1659 0.324746 27.6525 -2.26988 31.7471 9.40594C35.8418 21.0818 35.0985 27.9737 35.0985 27.9737C35.0985 27.9737 34.0174 38.7171 35.0985 41.3928C36.4777 44.5481 37.4627 47.8614 38.031 51.2578" fill="#DEE1E9"/>
        <path d="M1.19259 75.2852C1.50341 81.8394 8.32782 80.6907 11.0035 79.8258C13.6792 78.9609 20.828 76.7717 24.5172 78.4745C28.2064 80.1772 29.3551 83.4475 37.4633 81.2988C45.5715 79.1501 41.7066 56.2309 41.7066 56.2309C41.7066 56.2309 39.9904 49.4741 30.9903 49.1633C30.1688 49.1427 29.3539 49.314 28.6102 49.6635C27.8666 50.0131 27.2147 50.5312 26.7064 51.1768C25.2199 53.339 20.6793 53.6363 16.0712 51.9201C11.463 50.2038 7.46294 50.7579 6.47644 52.4471C5.90311 53.3201 5.37537 54.2223 4.89534 55.1498C3.99179 56.9009 3.19747 58.7062 2.51693 60.5553C1.32739 65.3691 0.880786 70.3365 1.19259 75.2852Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path opacity="0.4" d="M33.3279 53.4595C34.3684 51.5278 32.3684 49.3898 30.8684 44.1995C29.758 40.6811 29.2987 36.9572 29.517 33.2437C29.8954 28.6432 31.2333 27.552 31.0846 23.0253C30.8254 20.6321 30.3732 18.2687 29.7333 15.9623C27.0981 4.5642 24.8503 1.36857 24.526 1.42756C24.2017 1.48654 26.0981 3.70897 25.9359 11.5829C25.8413 16.4046 25.8278 17.8349 25.7197 20.1057C25.571 22.6714 25.4494 23.2612 25.044 27.6553C24.2737 35.9568 24.2602 38.8322 23.9224 38.8469C23.5845 38.8617 23.4359 33.9515 22.1386 26.918C20.9494 20.4153 18.7267 5.40831 17.5104 5.6C16.8483 5.68847 16.625 14.6942 16.3412 19.1325C15.7601 28.0976 16.4763 32.8014 15.6655 33.0078C14.8547 33.2142 12.706 27.3751 11.8817 24.721C10.3141 19.7666 9.98463 4.06018 9.57922 4.08967C9.17381 4.11916 9.53031 20.2089 9.20598 27.4046C8.92219 33.5091 8.44921 36.3697 10.0979 39.5842C11.0168 41.3978 11.4492 41.0587 11.8817 42.5332C12.8817 46.1163 9.74652 48.1512 9.86815 53.2383C9.86815 54.5801 9.96274 56.9836 11.4357 58.3549C12.9087 59.7262 15.0574 59.2396 19.4629 58.3549C22.2602 57.7946 21.8007 57.6471 29.0576 55.4059C32.1792 54.4622 32.8684 54.3 33.3279 53.4595Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M23.1518 20.03C23.0032 20.976 21.8004 28.2734 20.0437 31.6788C18.1112 35.3275 15.9896 31.6788 15.9896 31.6788L13.7733 18.6381C13.9085 17.5706 15.6652 4.28661 17.1112 4.5704C18.5572 4.85419 19.0707 4.02985 23.1518 20.03Z" fill="#DEE1E9"/>
        <path d="M4.50354 56.6026C4.62066 53.972 4.7603 51.3053 4.92246 48.6025C5.22877 43.3592 5.62518 38.2511 6.11167 33.278C6.79841 27.8246 7.04695 22.325 6.85492 16.8319C6.62169 12.6684 7.0783 8.49496 8.20629 4.48039C8.20629 4.48039 9.92252 1.77765 11.1117 5.45337C12.0541 8.94899 12.7718 12.5013 13.2604 16.0886L15.8685 31.4942C15.8685 31.4942 18.0307 35.0618 19.9226 31.4131C21.8146 27.7644 23.0443 19.6562 23.0443 19.6562C23.0443 19.6562 23.9362 10.1967 22.6794 6.14256C21.4227 2.08846 24.1659 1.00736 24.1659 1.00736C24.1659 1.00736 27.6525 -1.58726 31.7471 10.0886C35.8418 21.7644 35.0985 28.6564 35.0985 28.6564C35.0985 28.6564 34.0174 39.3997 35.0985 42.0754C36.4777 45.2307 37.4627 48.5441 38.031 51.9404" fill="#DEE1E9"/>
        <path d="M1.19259 75.9678C1.50341 82.522 8.32782 81.3733 11.0035 80.5084C13.6792 79.6436 20.828 77.4543 24.5172 79.1571C28.2064 80.8598 29.3551 84.1301 37.4633 81.9814C45.5715 79.8327 41.7066 56.9136 41.7066 56.9136C41.7066 56.9136 39.9904 50.1567 30.9903 49.8459C30.1688 49.8253 29.3539 49.9966 28.6102 50.3461C27.8666 50.6957 27.2147 51.2138 26.7064 51.8594C25.2199 54.0216 20.6793 54.3189 16.0712 52.6027C11.463 50.8865 7.46294 51.4405 6.47644 53.1297C5.90311 54.0027 5.37537 54.9049 4.89534 55.8325C3.99179 57.5835 3.19747 59.3888 2.51693 61.2379C1.32739 66.0517 0.880786 71.0191 1.19259 75.9678Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path opacity="0.2" d="M30.8684 45.0126C32.3684 50.1578 34.3684 52.2773 33.3279 54.1921C32.8684 55.0253 32.1792 55.1861 29.0576 56.1215C21.8007 58.3433 22.2602 58.4895 19.4629 59.0449C15.0574 59.922 12.9087 60.4043 11.4357 59.0449C9.96274 57.6856 9.86815 55.303 9.86815 53.9728C9.74652 48.93 12.8817 46.9128 11.8817 43.3609C11.4492 41.8992 11.0168 42.2354 10.0979 40.4375C8.44921 37.251 8.92219 34.4153 9.20598 28.3639C9.24189 27.574 9.26946 26.6761 9.29063 25.7036H11.8817C12.706 28.3347 14.8547 34.123 15.6655 33.9183C16.2867 33.7616 16.0116 30.9883 16.1102 25.7036H21.7503C21.8888 26.499 22.0189 27.2329 22.1386 27.8815C23.4359 34.8538 23.5845 39.7213 23.9224 39.7067C24.2602 39.692 24.2737 36.8417 25.044 28.6124C25.1581 27.3868 25.2497 26.4597 25.3257 25.7036H31.0677C30.8824 28.8748 29.8432 30.2209 29.517 34.1522C29.2987 37.8334 29.758 41.5249 30.8684 45.0126Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <g opacity="0.4">
          <path d="M29.7333 16.1822C30.3733 18.4151 30.8255 20.7034 31.0847 23.0203C31.1052 23.625 31.156 24.1663 31.1263 24.662H25.4205C25.6147 22.7759 25.6129 21.9788 25.7198 20.1937C25.8279 17.9952 25.8414 16.6105 25.936 11.9422C26.0982 4.31893 24.2017 2.16725 24.5261 2.11015C24.8504 2.05304 27.0982 5.14694 29.7333 16.1822Z" fill="#462AF3"/>
          <path d="M16.3413 19.2515C16.6251 14.9544 16.8483 6.23542 17.5105 6.14977C18.6044 5.98285 20.3513 17.7186 21.5893 24.662H16.1103C16.1404 23.0862 16.2054 21.2817 16.3413 19.2515Z" fill="#462AF3"/>
          <path d="M9.5793 4.68752C9.98471 4.65896 9.9843 19.8654 11.5519 24.662H9.29071C9.46078 17.0344 9.21878 4.71291 9.5793 4.68752Z" fill="#462AF3"/>
          <path d="M29.7333 16.1822C30.3733 18.4151 30.8255 20.7034 31.0847 23.0203C31.1052 23.625 31.156 24.1663 31.1263 24.662H25.4205C25.6147 22.7759 25.6129 21.9788 25.7198 20.1937C25.8279 17.9952 25.8414 16.6105 25.936 11.9422C26.0982 4.31893 24.2017 2.16725 24.5261 2.11015C24.8504 2.05304 27.0982 5.14694 29.7333 16.1822Z" stroke="#462AF3" stroke-miterlimit="10"/>
          <path d="M16.3413 19.2515C16.6251 14.9544 16.8483 6.23542 17.5105 6.14977C18.6044 5.98285 20.3513 17.7186 21.5893 24.662H16.1103C16.1404 23.0862 16.2054 21.2817 16.3413 19.2515Z" stroke="#462AF3" stroke-miterlimit="10"/>
          <path d="M9.5793 4.68752C9.98471 4.65896 9.9843 19.8654 11.5519 24.662H9.29071C9.46078 17.0344 9.21878 4.71291 9.5793 4.68752Z" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M36.8712 81.9757C28.763 84.1244 27.6549 80.7865 23.9386 79.1379C20.2223 77.4892 13.1276 79.6649 10.4249 80.4892C7.72217 81.3136 0.884253 82.6244 0.519384 76.0162C0.20872 71.0213 0.664454 66.0082 1.87075 61.1512C2.44174 59.5906 3.10508 58.0653 3.85726 56.5835C3.96537 53.9439 4.10051 51.2772 4.26267 48.5834C4.587 43.3401 4.9834 38.232 5.45188 33.2589C6.13861 27.8055 6.38716 22.3059 6.19513 16.8128C5.95739 12.6492 6.41408 8.47499 7.5465 4.46127C7.5465 4.46127 9.24922 1.75854 10.4384 5.43426C11.4129 8.95056 12.1622 12.5254 12.6817 16.1371L13.0736 18.4614C13.2087 17.3939 14.9655 4.12343 16.398 4.40722C17.8304 4.69101 18.4115 3.81262 22.4791 19.7993C22.4817 19.7678 22.4817 19.7362 22.4791 19.7047C22.4791 19.7047 23.3845 10.2451 22.1142 6.19102C20.844 2.13692 23.6007 1.05583 23.6007 1.05583C23.6007 1.05583 27.1008 -1.5388 31.1954 10.137C35.2901 21.8128 34.5333 28.7048 34.5333 28.7048C34.5333 28.7048 33.4522 39.5158 34.5333 42.1239C35.9125 45.2792 36.8975 48.5925 37.4658 51.9889C39.1809 53.1976 40.4454 54.9423 41.0604 56.9484C41.0604 56.9484 44.9794 79.8135 36.8712 81.9757Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M23.2161 20.0478C23.0675 20.9938 21.8648 28.2912 20.108 31.6966C18.1755 35.3453 16.0539 31.6966 16.0539 31.6966L13.8376 18.6559C13.9728 17.5883 15.7296 4.30437 17.1755 4.58816C18.6215 4.87195 19.135 4.04761 23.2161 20.0478Z" fill="#C7CAD5"/>
      <path d="M4.56787 56.6202C4.68499 53.9895 4.82463 51.3228 4.9868 48.6201C5.29311 43.3768 5.68951 38.2686 6.176 33.2956C6.86274 27.8422 7.11128 22.3426 6.91925 16.8495C6.68602 12.686 7.14263 8.51254 8.27062 4.49797C8.27062 4.49797 9.98686 1.79523 11.1761 5.47095C12.1184 8.96657 12.8361 12.5189 13.3247 16.1062L15.9329 31.5118C15.9329 31.5118 18.0951 35.0794 19.987 31.4307C21.8789 27.782 23.1086 19.6738 23.1086 19.6738C23.1086 19.6738 24.0005 10.2142 22.7438 6.16014C21.487 2.10604 24.2303 1.02494 24.2303 1.02494C24.2303 1.02494 27.7168 -1.56968 31.8114 10.1061C35.9061 21.782 35.1628 28.6739 35.1628 28.6739C35.1628 28.6739 34.0817 39.4173 35.1628 42.093C36.5421 45.2483 37.527 48.5616 38.0953 51.958" fill="#DEE1E9"/>
      <path d="M1.25692 75.9854C1.56774 82.5395 8.39215 81.3908 11.0679 80.526C13.7436 79.6611 20.8923 77.4719 24.5815 79.1746C28.2708 80.8773 29.4194 84.1476 37.5276 81.9989C45.6358 79.8503 41.7709 56.9311 41.7709 56.9311C41.7709 56.9311 40.0547 50.1742 31.0546 49.8634C30.2331 49.8428 29.4182 50.0141 28.6746 50.3637C27.9309 50.7132 27.2791 51.2314 26.7707 51.877C25.2842 54.0391 20.7436 54.3365 16.1355 52.6202C11.5273 50.904 7.52727 51.458 6.54077 53.1472C5.96744 54.0203 5.4397 54.9224 4.95967 55.85C4.05612 57.601 3.2618 59.4063 2.58126 61.2555C1.39172 66.0692 0.945117 71.0366 1.25692 75.9854Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth17",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
