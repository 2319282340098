<template>
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M5.28455 0C2.37073 0 0 2.37073 0 5.28455C0 8.19853 2.37073 10.5691 5.28455 10.5691C8.19853 10.5691 10.5691 8.19853 10.5691 5.28455C10.5691 2.37073 8.19853 0 5.28455 0ZM5.28455 9.59351C2.90862 9.59351 0.975609 7.6605 0.975609 5.28457C0.975609 2.90864 2.90862 0.975609 5.28455 0.975609C7.66048 0.975609 9.59348 2.90862 9.59348 5.28455C9.59348 7.66048 7.66048 9.59351 5.28455 9.59351Z"
      fill="#87939E"/>
  <path
      d="M11.8571 11.1673L9.06034 8.37056C8.86977 8.17999 8.56114 8.17999 8.37057 8.37056C8.18 8.56097 8.18 8.86992 8.37057 9.06033L11.1673 11.8571C11.2626 11.9524 11.3873 12 11.5122 12C11.6369 12 11.7618 11.9524 11.8571 11.8571C12.0477 11.6667 12.0477 11.3577 11.8571 11.1673Z"
      fill="#87939E"/>
</svg>
</template>
