import {countryPaySystems} from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'country',
    order: 'id',
    sort: 'desc',
    actions: [
        "edit",
    ],
    showTotalRecords: true,
    deletemessage: 'are_you_sure_you_want_to_delete_branch',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
            filter: true,
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            filter: true
        },
        {
            id: 'pay_system',
            title: 'pay_system',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "select",
                required: true,
                select_type: "list",
                options: countryPaySystems
            }
        },
    ]
}
