<template>
<svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_5429_64499)">
    <path
        d="M2.46481 14.3693C2.75741 14.1546 2.82057 13.7435 2.60595 13.4509C1.7614 12.2996 1.31501 10.9338 1.31501 9.50103C1.31501 5.81491 4.31388 2.81604 7.99999 2.81604C11.6861 2.81604 14.685 5.81491 14.685 9.50103C14.685 10.9338 14.2385 12.2996 13.394 13.4509C13.1794 13.7435 13.2426 14.1547 13.5351 14.3693C13.8283 14.5844 14.2393 14.5201 14.4535 14.2282C16.7371 11.1152 16.5083 6.69713 13.6561 3.84496C10.5301 0.718907 5.47035 0.718438 2.34389 3.84496C-0.508308 6.69713 -0.737169 11.1152 1.54646 14.2282C1.76106 14.5207 2.17222 14.5839 2.46481 14.3693Z"
        fill="#495A6B"/>
    <path
        d="M15.3408 16.1858H0.657016C0.294156 16.1858 0 16.4799 0 16.8428C0 17.2057 0.294156 17.4998 0.657016 17.4998H15.3408C15.7037 17.4998 15.9978 17.2057 15.9978 16.8428C15.9978 16.4799 15.7037 16.1858 15.3408 16.1858Z"
        fill="#495A6B"/>
    <path
        d="M7.28412 7.1171C7.91986 6.48139 9.01104 6.93724 9.01104 7.83241C9.01104 8.19527 9.3052 8.48942 9.66806 8.48942C10.0309 8.48942 10.3251 8.19527 10.3251 7.83241C10.3251 6.77821 9.61979 5.88616 8.65647 5.6019V5.16263C8.65647 4.79977 8.36232 4.50562 7.99945 4.50562C7.63659 4.50562 7.34244 4.79977 7.34244 5.16263V5.60083C6.22344 5.92859 5.50138 7.04974 5.75658 8.2284C5.91367 8.95393 6.41607 9.55632 7.10046 9.83981L8.3956 10.3763C8.99452 10.6243 9.17405 11.393 8.74032 11.8585C8.1205 12.5231 6.98786 12.0896 6.98786 11.1696C6.98786 10.8068 6.69371 10.5126 6.33085 10.5126C5.96798 10.5126 5.67383 10.8068 5.67383 11.1696C5.67383 12.1962 6.35228 13.1112 7.34244 13.3997V13.8394C7.34244 14.2023 7.63659 14.4964 7.99945 14.4964C8.36232 14.4964 8.65647 14.2023 8.65647 13.8394V13.3987C9.02303 13.2919 9.35886 13.0963 9.63534 12.8226C10.7768 11.6935 10.3693 9.77148 8.89844 9.16224L7.6033 8.6258C6.99753 8.37488 6.8156 7.58649 7.28412 7.1171Z"
        fill="#495A6B"/>
  </g>
  <defs>
    <clipPath id="clip0_5429_64499">
      <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
    </clipPath>
  </defs>
</svg>
</template>
