<template>
  <b-modal id="change-tariff-modal"
           :title="$t('change_tariff')"
           centered>
    <div class="alert alert-warning">{{ $t('when_changing_tariff_all_payments_will_nullify') }}</div>
    <SelectGroup :options="tariffsToChangeOurself"
                 variant="white"
                 :translate="false"
                 v-model="tariffId"
                 :showErrors="true"
                 validationId="tariff_id"
                 :label="$t('tariff')"/>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons no-print">
        <b-button class="cancel d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>
        <button :disabled="loading"
                @click="save"
                class="ml-3 btn-themed align-middle d-inline-block d-sm-inline-block btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import SelectGroup from "@/components/form/SelectGroup"
import { tariffsToChangeOurself } from "@/dictionaries/dictionary"
import {BillingService} from "@/services/api.service"

export default {
  name: "ChangeTariffModal",
  components: {
    SelectGroup
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
    }),
  },
  data() {
    return {
      tariffsToChangeOurself: tariffsToChangeOurself,
      tariffId: null
    }
  },
  methods: {
    save() {
      BillingService.changeTariff(this.tariffId).then(res => {
        if(res.data) {
          location.reload()
        }
      })
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

</style>
