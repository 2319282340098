<template>
  <div>
    <DataTable :options="options"
               class="mt-4"
               @checkboxSelected="checkboxSelected"
               ref="datatable"
               :params="{ branch_id: branch, blocked: 0, nogroup_id: groupId }"
               module="marketing"/>
    <SelectedBottomPanel :selected="selected"
                         @cancel="cancel"
                         view="add"
                         @actionWithSelected="addToGroup"
                         :group="group"/>
  </div>
</template>

<script>

import {mapState} from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import { EloquentService, MarketingService } from "@/services/api.service"
import options from "@/data/marketing/patient"
import SelectedBottomPanel from "@/components/parts/datatable/SelectedBottomPanel"

export default {
  name: "MarketingGroupAdd",
  components: {
    SelectedBottomPanel,
    DataTable,
  },
  head() {
    return {
      title: this.$t('group')
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
      collapsed: state => state.dom.sidebarCollapsed,
      filters: state => state.datatable.filters,
    }),
    groupId() {
      return this.$route.params.id
    }
  },
  data() {
    return {
      group: null,
      options: options,
      selected: []
    }
  },
  methods: {
    checkboxSelected(val) {
      this.selected = val
    },
    cancel() {
      this.$refs.datatable.cancelSelection()
    },
    addToGroup() {
      if(!this.group) return
      MarketingService.addToGroup(this.group.id, this.selected).then(res => {
        if(res.data) {
          this.$noty.info(this.$t('success_message'))
          this.cancel()
          this.$store.commit('incrementDataTableCounter')
        }
      })
    }
  },
  mounted() {
    EloquentService.show('marketing_group', this.groupId).then(res => {
      this.$store.commit('setMarketingGroup', res.data)
      this.group = res.data
    })
    this.$store.commit('setEloquentOptions', options)
  },
  watch: {
    filters: {
      handler(val) {
        let formData = new FormData()
        formData.append('filters', JSON.stringify(val))
        EloquentService.update('marketing_group', this.groupId, formData)
        console.log('filter changed')
      },
      deep: true
    }
  }
}
</script>
