<template>
  <span v-if="['ioka','tinkoff'].includes(object.pay_system) && role === 'superadmin'">
    <a :title="$t('status')"
       v-b-tooltip.hover
       @click="payStatus"
       class="tdclickable data-table-action">
      <ActivateSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('status') }}</span>
    </a>
    <a :title="$t('return')"
       v-if="object.paid == 1 && !object.refunded_at"
       v-b-tooltip.hover
       @click="refundPayment"
       class="tdclickable data-table-action">
      <BackSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('return') }}</span>
    </a>
    </span>
</template>

<script>

import ActivateSvg from '@/assets/svg-vue/header/note.svg'
import BackSvg from '@/assets/svg-vue/general/refresh2.svg'
import {BillingService} from "@/services/api.service"

export default {
  name: "SuperAdminPaymentActions",
  components: {
    ActivateSvg,
    BackSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    },
  },
  methods: {
    async payStatus() {
      let res = await BillingService.paymentStatus(this.object.id, true)
      this.$store.commit('setPayStatus', res.data.status)
      this.$store.commit('setBillingPayment', res.data.payment)
      this.$nextTick(() => {
        this.$bvModal.show('pay-status-modal')
      })

    },
    async refundPayment() {
      if(confirm('Вы уверены, что хотите вернуть деньги?')) {
        let res = await BillingService.refundPay(this.object.id)
        if(res.data.id || res.data.Success) {
          this.$noty.info("Запрос на возврат принят")
        } else {
          this.$noty.error(res.data.message ? res.data.message : res.data.Details)
        }
        this.$store.commit('incrementDataTableCounter')
      }
    }
  }
}
</script>

<style scoped>

</style>
