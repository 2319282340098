<template>
  <div>
    <SelectGroup :options="finalPayMethods"
                 variant="white"
                 :title="null"
                 :label="$t('payment_type')"
                 :required="true"
                 :readonly="readonly"
                 :showlabel="true"
                 v-model="object.forma"/>
    <InputGroup v-model="object.paid"
                type="number"
                :readonly="readonly"
                :label="$t('sum')"
                :required="true"/>
  </div>
</template>

<script>
import SelectGroup from "@/components/form/SelectGroup"
import {mapState} from "vuex"
import InputGroup from "@/components/form/InputGroup.vue"

export default {
  name: "PaymentsDatatableFormPaymentType",
  components: {
    InputGroup,
    SelectGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  mounted() {
  },
  computed: {
    ...mapState({
      pay_methods: state => state.auth.pay_methods,
    }),
    readonly() {
      return this.object.forma === 4 // insurance
          || this.object.forma === 5 // deposit
          || this.object.forma === 6 // bonus
    },
    finalPayMethods() {
      return this.pay_methods.filter(x => {
        const num = Number(x.id)
        if(num === 100) return false
        if(num === 4 && this.object.forma !== 4) return false
        if(num === 5 && this.object.forma !== 5) return false
        if(num === 6 && this.object.forma !== 6) return false
        return true
      })
    }
  },
}
</script>
