<template>
  <div class="position-relative">
    <InputGroup :label="$t(field.title)"
                type="text"
                @focus="focus"
                :required="true"
                @input="checkChange"
                v-model="object[field.id]"/>
    <div v-if="dropdown && options.length"
         class="dropdown-search"
         v-click-outside="clickOutside">
      <div v-for="(option, oind) in options" :key="`opt-${oind}`">
        <a @click="optionSelected(option)">{{ option.value }}</a>
      </div>
    </div>
  </div>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import { PatientService } from "@/services/api.service"

export default {
  name: "PatientsDatatableFormAddress",
  components: {
    InputGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {},
  },
  data() {
    return {
      options: [],
      timer: null,
      dropdown: false,
      justOpened: false
    }
  },
  computed: {
    address() {
      return this.object[this.field.id]
    }
  },
  methods: {
    optionSelected(option) {
      if(option.postal_code) this.object.zip_code = option.postal_code
      if(option.value) this.object.address = option.value
      this.dropdown = false
    },
    openDropdown() {
      this.dropdown = true
      this.justOpened = true
      setTimeout(() => this.justOpened = false, 150)
    },
    checkChange() {
      if(this.timer) clearTimeout(this.timer)
      console.log(this.address)
      this.timer = setTimeout(() => {
        PatientService.guessAddress(this.address).then(res => {
          this.options = res.data
          if(this.options.length) this.openDropdown()
        })
      }, 250)
    },
    clickOutside() {
      if(!this.justOpened) this.dropdown = false
    },
    focus() {
      if(this.options.length) this.openDropdown()
    }
  },
}
</script>

<style scoped lang="scss">
.dropdown-search {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid $gray-icon;
  border-top: none;
  z-index: 2;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  overflow: hidden;
  a {
    display: block;
    padding: 3px 10px;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      background-color: $blue-color !important;
      color: #fff;
    }
  }
}
</style>
