<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path
        d="M14.5 2H1.50001C0.672854 2 0 2.67285 0 3.50001V12.5C0 13.3272 0.672854 14 1.50001 14H14.5C15.3271 14 16 13.3272 16 12.5V3.50001C16 2.67285 15.3271 2 14.5 2ZM14.5 3C14.5679 3 14.6325 3.01409 14.6916 3.03871L8 8.83839L1.30833 3.03871C1.36742 3.01412 1.43204 3 1.49998 3H14.5ZM14.5 13H1.50001C1.22414 13 0.999996 12.7759 0.999996 12.5V4.09521L7.67236 9.87793C7.76661 9.95945 7.8833 10 8 10C8.1167 10 8.23339 9.95949 8.32764 9.87793L15 4.09521V12.5C15 12.7759 14.7759 13 14.5 13Z"
        fill="#B5C6D8"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
