<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path
        d="M2.46481 12.8692C2.75741 12.6546 2.82057 12.2434 2.60595 11.9508C1.7614 10.7996 1.31501 9.43373 1.31501 8.00097C1.31501 4.31485 4.31388 1.31598 7.99999 1.31598C11.6861 1.31598 14.685 4.31485 14.685 8.00097C14.685 9.43373 14.2385 10.7996 13.394 11.9509C13.1794 12.2434 13.2426 12.6546 13.5351 12.8692C13.8283 13.0843 14.2393 13.0201 14.4535 12.7281C16.7371 9.6151 16.5083 5.19707 13.6561 2.3449C10.5301 -0.781154 5.47035 -0.781623 2.34389 2.3449C-0.508308 5.19707 -0.737169 9.61513 1.54646 12.7281C1.76106 13.0206 2.17222 13.0838 2.46481 12.8692Z"/>
    <path
        d="M15.3418 14.6858H0.657993C0.295132 14.6858 0.000976562 14.98 0.000976562 15.3428C0.000976562 15.7057 0.295132 15.9998 0.657993 15.9998H15.3418C15.7046 15.9998 15.9988 15.7057 15.9988 15.3428C15.9988 14.98 15.7046 14.6858 15.3418 14.6858Z"/>
    <path
        d="M7.28461 5.61701C7.92035 4.9813 9.01153 5.43714 9.01153 6.33231C9.01153 6.69518 9.30569 6.98933 9.66855 6.98933C10.0314 6.98933 10.3256 6.69518 10.3256 6.33231C10.3256 5.27811 9.62027 4.38607 8.65696 4.1018V3.66254C8.65696 3.29968 8.3628 3.00552 7.99994 3.00552C7.63708 3.00552 7.34292 3.29968 7.34292 3.66254V4.10074C6.22393 4.4285 5.50186 5.54965 5.75707 6.72831C5.91416 7.45384 6.41656 8.05623 7.10095 8.33972L8.39609 8.87616C8.99501 9.12423 9.17453 9.89294 8.74081 10.3584C8.12099 11.023 6.98835 10.5895 6.98835 9.66952C6.98835 9.30666 6.6942 9.01251 6.33133 9.01251C5.96847 9.01251 5.67432 9.30666 5.67432 9.66952C5.67432 10.6961 6.35277 11.6111 7.34292 11.8996V12.3393C7.34292 12.7022 7.63708 12.9963 7.99994 12.9963C8.3628 12.9963 8.65696 12.7022 8.65696 12.3393V11.8987C9.02351 11.7918 9.35934 11.5962 9.63582 11.3225C10.7773 10.1934 10.3698 8.27139 8.89893 7.66215L7.60379 7.12571C6.99802 6.87479 6.81609 6.0864 7.28461 5.61701Z"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
