export default {
    insurance_companies: "Sığorta şirkətləri",
    insurance_polis: "Sığorta polisi",
    limit: "Limit",
    number: "Nömrə",
    polis: "Polis",
    polises: "Polislər",
    use_custom_prices_for_insurance: "Sığorta şirkətləri üçün fərdi qiymətlərdən istifadə et",
    valid_from: "Nə vaxtdan etibarlıdır",
    valid_to: "Nə vaxta qədər etibarlıdır",
    visit_should_have_polis_set_in_order_to_use_insurance_payment: "Sığorta ödənişindən istifadə etmək üçün səfərin tamamlanmış sığorta polisi olmalıdır",
}
