<template>
  <b-modal id="stocks-modal"
           :title="`${$t('general_stock')}: ${object.name}`"
           hide-footer centered>
    <b-row class="justify-content-end">
      <b-col class="text-right d-md-block d-none">
        <a class="btn btn-themed gray d-inline-flex align-items-center" @click="excel"
           style="margin-top: -20px;">
          <ExcelSvg class="svg-gray mr-2"/>
          Excel
        </a>
      </b-col>
    </b-row>
    <div v-if="object && object.quantities">
      <div class="data-table-wrapper pt-md-3">
        <table class="table data-table">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('title') }}</th>
            <th>{{ $t('amount') }}</th>
            <th>{{ $t('warehouse') }}</th>
            <th>{{ $t('sum') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(amount, warehouse_id, index) in object.quantities" :key="`war-item-${warehouse_id}`">
            <td :data-title="$t('id')">
              {{ (index + 1) }}
            </td>
            <td class="order-mob-0" :data-title="$t('title')">
              {{ object.name }}
            </td>
            <td :class="{ 'accountingdown': amount < 0 }" :data-title="$t('amount')">
              {{ formatNumber(amount) }}
            </td>
            <td :data-title="$t('warehouse')">
              {{ (warehouses && warehouses.length > 0) ? warehouses.find(x => x.id == warehouse_id).title : '' }}
            </td>
            <td :class="{ 'accountingdown': amount < 0 }" :data-title="$t('sum')">
              {{ formatCurrency(amount * object.price) }}
            </td>
            <MobileMoreArrow/>
          </tr>
          <tr class="font-weight-bold d-none d-md-table-row">
            <td></td>
            <td>{{ $t('total') }}</td>
            <td>{{ formatNumber(object.total_quantity) }}</td>
            <td></td>
            <td>{{ formatCurrency(object.total_quantity * object.price) }}</td>
          </tr>
          </tbody>
        </table>
        <div class="d-md-none d-block font-weight-bold">
          {{ $t('total') }}:
          {{ formatNumber(object.total_quantity) }} {{ units[object.unit_id] }} =
          {{ formatCurrency(object.total_quantity * object.price) }}
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import { WarehouseService } from "@/services/api.service"
import ExcelSvg from "@/assets/svg-vue/general/excel.svg"
import MobileMoreArrow from "@/components/parts/datatable/MobileMoreArrow"
import JwtService from "@/services/jwt.service"
import { formatNumber, formatCurrency } from "@/extensions/filters/filters"

export default {
  name: "StocksModal",
  components: {
    MobileMoreArrow,
    ExcelSvg,
  },
  computed: {
    ...mapState({
      object: state => state.datatable.selectedModel,
      warehouses: state => state.datatable.warehouses,
      clinic: state => state.auth.clinic,
      units:  state => state.auth.units,
    }),
  },
  methods: {
    formatNumber,
    formatCurrency,
    excel() {
      WarehouseService.materialStocksExcel(this.object.material_id).then(res => {
        let link = JwtService.getPrintPrelink(this.clinic)
        location.href = link+'/excel/'+res.data.result
      })
    },
  },
  mounted() {
  }
}
</script>
