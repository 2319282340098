<template>
  <div>
    <ServiceMultiSelect :label="$t('doctors')"
                        :dblclick="false"
                        model="doctor"
                        title="full_name"
                        :dbsearch="false"
                        :prepopulatedOptions="doctors"
                        v-model="object.doctors"/>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { mapState } from "vuex"

export default {
  name: "StaffDatatableFormChairDoctors",
  components: {
    ServiceMultiSelect
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  methods: {
  },
  mounted() {
  },
  computed: {
    ...mapState({
      doctors: state => state.auth.doctors,
    })
  },
  watch: {

  }
}
</script>

<style scoped>

</style>
