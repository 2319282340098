<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M9.61645 14.5731C9.48452 14.606 9.35 14.6352 9.21648 14.6598C8.86745 14.7247 8.6367 15.0604 8.70126 15.4097C8.73314 15.5816 8.83075 15.7247 8.96327 15.8182C9.09985 15.9143 9.27368 15.9577 9.45081 15.9248C9.60981 15.8953 9.77006 15.8606 9.92731 15.8213C10.272 15.7355 10.4819 15.3864 10.396 15.0419C10.3102 14.6971 9.96132 14.4873 9.61645 14.5731Z"
      fill="#B5C6D8"/>
  <path
      d="M14.3727 5.95174C14.4178 6.08752 14.5038 6.19838 14.6124 6.27497C14.7735 6.38844 14.9843 6.42661 15.1855 6.3601C15.5227 6.24811 15.7055 5.88443 15.5939 5.54733C15.5431 5.39366 15.4867 5.23951 15.4266 5.08941C15.2946 4.75957 14.9205 4.59899 14.5905 4.73092C14.2609 4.86279 14.1002 5.23703 14.2322 5.56697C14.2827 5.69313 14.33 5.82262 14.3727 5.95174Z"
      fill="#B5C6D8"/>
  <path
      d="M11.7061 13.6548C11.5926 13.7297 11.4755 13.8022 11.3576 13.8701C11.0498 14.0477 10.9443 14.4411 11.1218 14.7487C11.17 14.8324 11.2342 14.9008 11.3081 14.9531C11.5065 15.0927 11.776 15.1136 12.0003 14.9844C12.1406 14.9035 12.2801 14.8174 12.4152 14.7281C12.7115 14.5323 12.793 14.1333 12.5972 13.8369C12.4014 13.5403 12.0025 13.4589 11.7061 13.6548Z"
      fill="#B5C6D8"/>
  <path
      d="M15.9939 7.74819C15.9799 7.39318 15.6809 7.11686 15.3258 7.13072C14.9711 7.14472 14.6946 7.44384 14.7085 7.79871C14.7138 7.9344 14.7152 8.07211 14.7121 8.20766C14.7071 8.43029 14.816 8.62874 14.9852 8.74802C15.086 8.81901 15.2084 8.86196 15.341 8.86499C15.696 8.87287 15.9902 8.59129 15.9981 8.2361C16.0017 8.07414 16.0003 7.91005 15.9939 7.74819Z"
      fill="#B5C6D8"/>
  <path
      d="M14.2677 11.9627C13.983 11.7491 13.5803 11.8071 13.367 12.0913C13.2854 12.2001 13.1994 12.3076 13.1114 12.4111C12.8813 12.6814 12.9138 13.0875 13.1841 13.3177C13.1995 13.3308 13.2151 13.3428 13.2313 13.3541C13.5 13.5436 13.8735 13.5 14.0908 13.245C14.196 13.1215 14.2985 12.9931 14.396 12.8632C14.6093 12.579 14.5516 12.176 14.2677 11.9627Z"
      fill="#B5C6D8"/>
  <path
      d="M15.2132 9.63758C14.8743 9.53131 14.5133 9.71999 14.4071 10.0589C14.3664 10.1884 14.3214 10.3185 14.2728 10.446C14.166 10.7265 14.2683 11.0351 14.5028 11.2005C14.5459 11.2308 14.5933 11.2564 14.6448 11.2758C14.9766 11.4025 15.3482 11.236 15.4747 10.904C15.5325 10.7525 15.5861 10.5977 15.6345 10.4437C15.7406 10.1047 15.5521 9.74386 15.2132 9.63758Z"
      fill="#B5C6D8"/>
  <path
      d="M6.80993 14.6656C6.23486 14.5623 5.68322 14.3865 5.16012 14.1413C5.15393 14.138 5.1484 14.1344 5.1419 14.1314C5.01863 14.0734 4.89557 14.0112 4.77629 13.9463C4.77588 13.9458 4.77512 13.9455 4.77447 13.9452C4.55562 13.8247 4.34203 13.6914 4.13454 13.5453C1.10884 11.4139 0.381345 7.21828 2.51287 4.19262C2.97636 3.53495 3.53725 2.98626 4.16257 2.55139C4.17027 2.54602 4.17797 2.54069 4.18561 2.53529C6.38912 1.01701 9.38114 0.914688 11.7209 2.47486L11.2184 3.20095C11.0787 3.40305 11.1646 3.55032 11.4092 3.52831L13.5921 3.33288C13.837 3.31087 13.9834 3.09904 13.9175 2.86255L13.3314 0.75056C13.2657 0.513793 13.0977 0.485453 12.9579 0.687516L12.4542 1.41532C10.7371 0.262684 8.67765 -0.176834 6.63352 0.177628C6.42764 0.21326 6.22465 0.256871 6.02444 0.307637C6.02289 0.307912 6.02166 0.308084 6.02042 0.308359C6.01268 0.310251 6.00484 0.312761 5.9973 0.314859C4.23462 0.767516 2.6967 1.79558 1.5993 3.26241C1.59005 3.27338 1.58052 3.28411 1.57178 3.29605C1.53529 3.34519 1.49908 3.39548 1.46362 3.44576C1.40563 3.52817 1.34847 3.61264 1.29378 3.69711C1.28693 3.70729 1.28171 3.71765 1.27572 3.72793C0.370133 5.13123 -0.0666682 6.75359 0.00824147 8.40525C0.00841344 8.41068 0.0081039 8.41615 0.00824147 8.42172C0.0154986 8.58306 0.0282931 8.74664 0.0456619 8.90767C0.0465906 8.91806 0.048895 8.9279 0.050649 8.93828C0.0686026 9.10021 0.0911306 9.26251 0.119368 9.42478C0.40635 11.0804 1.18736 12.5703 2.35768 13.7295C2.3604 13.7322 2.36322 13.7352 2.36597 13.738C2.36693 13.7391 2.368 13.7396 2.36893 13.7406C2.68336 14.0507 3.02537 14.3376 3.39362 14.597C4.35734 15.276 5.43046 15.7249 6.58293 15.9317C6.93261 15.9946 7.26664 15.7618 7.32938 15.4123C7.39208 15.0625 7.15951 14.7282 6.80993 14.6656Z"
      fill="#B5C6D8"/>
  <path
      d="M7.60509 2.86147C7.31746 2.86147 7.08447 3.09466 7.08447 3.38188V8.56664L11.8263 11.0179C11.9027 11.0574 11.9844 11.076 12.0648 11.076C12.2531 11.076 12.435 10.9735 12.5275 10.7945C12.6594 10.5391 12.5596 10.2254 12.3042 10.0935L8.12512 7.9329V3.38188C8.12509 3.09466 7.89238 2.86147 7.60509 2.86147Z"
      fill="#B5C6D8"/>
</svg>
</template>
