export default {
    add_task: "Topshiriq qo‘shish",
    add_patient: "Bemor qo‘shish",
    calls: "Qo‘ng‘iroqlar",
    chat_panel: "Chat panel",
    clinic_id: "Id klinikalar",
    expenses: "Xarajatlar",
    high: "Yuqori",
    low: "Past",
    medium: "O‘rta",
    no_notifications: "Bildirishnomalar yo‘q",
    tasks: "Topshiriqlar"
}
