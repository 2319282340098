<template>
  <div>
    <hr/>
    <div class="row">
      <b-col md="4">
        <SelectGroup :options="selectOptions"
                     variant="white"
                     :label="$t(field.title)"
                     :required="false"
                     :translate="false"
                     :showlabel="true"
                     v-model="object[field.id]"/>
      </b-col>
      <b-col md="4">
        <SelectGroup :options="selectOptionsTwo"
                     variant="white"
                     v-if="object[field.id]"
                     :label="$t(field.title)+ ' 2'"
                     :required="false"
                     :translate="false"
                     :showlabel="true"
                     v-model="object.doctors_second_profession_id"/>
      </b-col>
      <b-col md="4">
        <SelectGroup :options="selectOptionsThree"
                     variant="white"
                     :label="$t(field.title) + ' 3'"
                     :required="false"
                     v-if="object.doctors_second_profession_id"
                     :translate="false"
                     :showlabel="true"
                     v-model="object.doctors_third_profession_id"/>
      </b-col>
    </div>
  </div>
</template>

<script>

import SelectGroup from "@/components/form/SelectGroup"
import {mapState} from "vuex"

export default {
  name: "StaffDatatableFormProfession",
  components: {
    SelectGroup,
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      clinic: state => state.auth.clinic,
      model: state => state.datatable.viewModel,
    }),
    selectOptionsTwo() {
      return this.selectOptions.filter(el => el.id !== this.object.doctors_profession_id)
    },
    selectOptionsThree() {
      return this.selectOptionsTwo.filter(el => el.id !== this.object.doctors_second_profession_id)
    },
    doctors_profession_id() {
      return this.object.doctors_profession_id
    },
    doctors_second_profession_id() {
      return this.object.doctors_second_profession_id
    },
    doctors_third_profession_id() {
      return this.object.doctors_third_profession_id
    },
  },
  mounted() {
  },
  watch: {
    doctors_profession_id(val) {
      if(val === '-') {
        this.object.doctors_profession_id = ''
        this.object.doctors_second_profession_id = ''
        this.object.doctors_third_profession_id = ''
      }
    },
    doctors_second_profession_id(val) {
      if(val === '-') this.object.doctors_second_profession_id = ''
    },
    doctors_third_profession_id(val) {
      if(val === '-') this.object.doctors_third_profession_id = ''
    },
  }
}
</script>
