<template>
  <div class="d-none">
    <div class="text-right">
      <a @click="sort"
         class="btn btn-themed-outline btn-themed-squared mb-3 d-inline-flex align-items-center">
        <SortSvg class="svg-primary svg-size-lg mr-2 svg-stroke-primary"/>
        {{ $t('sorting') }}
      </a>
    </div>
    <SortModal :model-name="modelName"/>
  </div>
</template>

<script setup>
import SortModal from "@/components/parts/general/SortModal.vue"
import SortSvg from "@/assets/svg-vue/general/sort.svg"
import { getCurrentInstance, onMounted } from "vue"

defineProps({
  modelName: {
    type: String,
    required: true
  }
})

let bvModal = null

const sort = () => {
  bvModal?.show('sort-modal')
}

onMounted(() => {
  bvModal = (getCurrentInstance())?.ctx?._bv__modal
})
</script>
