<template>
  <b-modal id="doctor-day-edit-modal"
           :title="`${$t('schedule')} - ${formatDate(day)}`"
           size="lg"
           @show="onShow"
           @hide="onHide"
           centered>
    <div v-if="newconfig">
      <div class="radio-inline">
        <RadioGroup :options="dayTypes"
                    v-model="configType"
                    :validationId="`config_type`"/>
      </div>
      <template v-for="(conf, ind) in newconfig">
        <b-row v-if="conf.type == '1'" class="align-items-center" :key="`val-${ind}`">
          <b-col lg="4"
                 md="4"
                 cols="12"
                 v-if="staffOptions && staffOptions.length">
            <ServiceMultiSelect :label="$t('employee')"
                                :dblclick="false"
                                model="doctor"
                                title="full_name"
                                :dbsearch="false"
                                :multiple="false"
                                :prepopulatedOptions="staffOptions"
                                @input="conf.doctor_id = $event ? $event.id : null"
                                v-model="conf.doctor"/>
          </b-col>
          <b-col lg="3" md="4" cols="12" v-if="chairs.length && scheduleView === 'doctors'">
            <ServiceMultiSelect :label="$t('chair')"
                                :dblclick="false"
                                model="chair"
                                :required="chairs.length > 0"
                                :multiple="false"
                                :prepopulated-options="chairs"
                                :internalSearch="true"
                                title="chair_name"
                                :dbsearch="false"
                                @input="conf.chair_id = $event ? $event.id : null"
                                v-model="conf.chair"/>
          </b-col>
          <b-col lg="2" md="3" cols="6">
            <div class="form-group">
              <label class="form-label">{{ $t('from') }}</label>
              <the-mask mask="##:##"
                        class="form-control time-slot"
                        v-model="conf.time_from"
                        :masked="true"/>
            </div>
            <ValidationError :validation-id="`days.${ind}.time_from`"/>
          </b-col>
          <b-col lg="2" md="3" cols="6">
            <div class="form-group">
              <label class="form-label">{{ $t('till') }}</label>
              <the-mask mask="##:##"
                        class="form-control time-slot"
                        v-model="conf.time_to"
                        :masked="true"/>
            </div>
            <ValidationError :validation-id="`days.${ind}.time_to`"/>
          </b-col>
          <b-col cols="auto">
            <div class="form-group">
              <a class="pointer"
                 @click="deleteConfig(conf, ind)">
                <TrashSvg class="svg-red"/>
              </a>
            </div>
          </b-col>
        </b-row>
      </template>
      <a class="pointer btn btn-themed-outline d-inline-flex px-4"
         v-if="configType == '1'"
         @click="addNewConfig">
        <AddSvg/>
      </a>

    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button @click="save"
                class="w-auto px-3 btn-themed align-middle btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import { DoctorService } from "@/services/api.service"
import { TheMask } from 'vue-the-mask'
import RadioGroup from "@/components/form/RadioGroup"
import ValidationError from "@/components/form/ValidationError"
import TrashSvg from '@/assets/svg-vue/general/trash_tr.svg'
import AddSvg from '@/assets/svg-vue/general/add.svg'
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import moment from "moment/moment"
import { formatDate } from "@/extensions/filters/filters"

export default {
  name: "DoctorDayEditModal",
  components: {
    ValidationError,
    TheMask,
    TrashSvg,
    AddSvg,
    RadioGroup,
    ServiceMultiSelect
  },
  computed: {
    ...mapState({
      clinic:       state => state.auth.clinic,
      doctor:       state => state.doctor.doctor,
      chairs:       state => state.auth.branchChairs,
      doctors:      state => state.calendar.doctors,
      staff:        state => state.calendar.staff,
      branch_id:    state => state.auth.branch,
      day:          state => state.doctor.doctorScheduleDay,
      preconfig:    state => state.doctor.doctorScheduleDayPreconfig,
      scheduleView: state => state.calendar.scheduleView,
    }),
    dayFormatted() {
      if(!this.day) return null
      return moment(this.day).format('YYYY-MM-DD')
    },
    staffOptions() {
      if(this.scheduleView === 'doctors') return this.doctors
      return this.staff
    }
  },
  data() {
    return {
      loaded: false,
      newconfig: null,
      dayTypes: [
        { id: '1', title: 'work_day' },
        { id: '0', title: 'weekend' },
      ],
      configType: '1'
    }
  },
  methods: {
    formatDate,
    async save() {
      this.$store.commit('cleanValidationErrors')
      for(const config of this.newconfig) {
        let res = await DoctorService.saveDoctorScheduleDay(config.doctor_id ? config.doctor_id : this.doctor.id, this.branch_id,{ days: [config] })
        if(res.data.conflicts && res.data.conflicts.length > 0) {
          this.$noty.warning(this.$t('schedule_conflicts_found') + ': '+res.data.conflicts.length)
        }
      }
      this.$noty.info(this.$t('success_message'))
      this.$bvModal.hide('doctor-day-edit-modal')
      this.$store.commit('incrementDataTableCounter')
    },
    onShow() {
      this.$store.commit('cleanValidationErrors')
      const data = this.copyObject(this.preconfig)
      data.forEach(item => {
        item.type = '1'
        item.currentTimeFrom = item.time_from
        item.chair = item.chair_id ? this.chairs.find(c => c.id === Number(item.chair_id)) : null
        item.doctor = item.doctor_id ? this.staffOptions.find(d => d.id === Number(item.doctor_id)) : null
      })
      this.configType = '1'
      this.newconfig = data
    },
    onHide() {
      setTimeout(() => {
        this.$store.commit('setDoctorScheduleDay', null)
        this.$store.commit('setDoctorPreconfig', null)
        this.newconfig = null
      }, 200)

    },
    addNewConfig() {
      const prevConfig = this.newconfig[this.newconfig.length - 1]
      const doctor_id = prevConfig ? null : (this.doctor ? this.doctor.id : null)
      this.newconfig.push({
        type: this.configType,
        time_from: prevConfig ? prevConfig.time_to : this.clinic.time_start,
        time_to: prevConfig ? prevConfig.time_to : this.clinic.time_end,
        day: prevConfig ? prevConfig.day : this.dayFormatted,
        currentTimeFrom: prevConfig ? prevConfig.time_to : this.clinic.time_start,
        chair_id: prevConfig ? prevConfig.chair_id : null,
        chair: prevConfig ? prevConfig.chair : null,
        doctor_id: doctor_id,
        doctor: doctor_id ? this.doctors.find(d => d.id === Number(doctor_id)) : null
      })
    },
    async deleteConfig(config, ind) {
      if(config.id && config.doctor_id) {
        await DoctorService.deleteDoctorScheduleDay(config.doctor_id, this.branch_id, config.id)
        this.$store.commit('incrementDataTableCounter')
      }
      this.newconfig.splice(ind, 1)
    }
  },
  mounted() {
  },
  watch: {
    configType(val) {
      this.newconfig.forEach(item => {
        item.type = val
      })
    }
  }
}
</script>
