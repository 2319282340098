<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 1.25H12.6875V0.625C12.6875 0.279813 12.4077 0 12.0625 0C11.7173 0 11.4375 0.279813 11.4375 0.625V1.25H8.59375V0.625C8.59375 0.279813 8.31394 0 7.96875 0C7.62356 0 7.34375 0.279813 7.34375 0.625V1.25H4.53125V0.625C4.53125 0.279813 4.25144 0 3.90625 0C3.56106 0 3.28125 0.279813 3.28125 0.625V1.25H2.5C1.1215 1.25 0 2.3715 0 3.75V13.5C0 14.8785 1.1215 16 2.5 16H6.28125C6.62644 16 6.90625 15.7202 6.90625 15.375C6.90625 15.0298 6.62644 14.75 6.28125 14.75H2.5C1.81075 14.75 1.25 14.1892 1.25 13.5V3.75C1.25 3.06075 1.81075 2.5 2.5 2.5H3.28125V3.125C3.28125 3.47019 3.56106 3.75 3.90625 3.75C4.25144 3.75 4.53125 3.47019 4.53125 3.125V2.5H7.34375V3.125C7.34375 3.47019 7.62356 3.75 7.96875 3.75C8.31394 3.75 8.59375 3.47019 8.59375 3.125V2.5H11.4375V3.125C11.4375 3.47019 11.7173 3.75 12.0625 3.75C12.4077 3.75 12.6875 3.47019 12.6875 3.125V2.5H13.5C14.1892 2.5 14.75 3.06075 14.75 3.75V6.3125C14.75 6.65769 15.0298 6.9375 15.375 6.9375C15.7202 6.9375 16 6.65769 16 6.3125V3.75C16 2.3715 14.8785 1.25 13.5 1.25Z"/>
<path d="M11 6C8.24297 6 6 8.24297 6 11C6 13.757 8.24297 16 11 16C13.757 16 16 13.757 16 11C16 8.24297 13.757 6 11 6ZM11 14.3471C9.15442 14.3471 7.65289 12.8456 7.65289 11C7.65289 9.15438 9.15442 7.65289 11 7.65289C12.8456 7.65289 14.3471 9.15438 14.3471 11C14.3471 12.8456 12.8456 14.3471 11 14.3471Z"/>
<path d="M11.6149 9.61493V10.6603H10.3851V9.61493C10.3851 9.27531 10.6604 9 11 9C11.3396 9 11.6149 9.27531 11.6149 9.61493Z"/>
<path d="M10.3851 12.3851L10.3851 10.6603H11.6149V12.3851C11.6149 12.7247 11.3396 13 11 13C10.6604 13 10.3851 12.7247 10.3851 12.3851Z"/>
<path d="M9.61493 10.3851L10.6603 10.3851V11.6149H9.61493C9.27531 11.6149 9 11.3396 9 11C9 10.6604 9.27531 10.3851 9.61493 10.3851Z"/>
<path d="M12.3851 11.6149H10.6603V10.3851H12.3851C12.7247 10.3851 13 10.6604 13 11C13 11.3396 12.7247 11.6149 12.3851 11.6149Z"/>
<path d="M3.90625 7.1875C4.25143 7.1875 4.53125 6.90768 4.53125 6.5625C4.53125 6.21732 4.25143 5.9375 3.90625 5.9375C3.56107 5.9375 3.28125 6.21732 3.28125 6.5625C3.28125 6.90768 3.56107 7.1875 3.90625 7.1875Z"/>
<path d="M3.90625 9.90625C4.25143 9.90625 4.53125 9.62643 4.53125 9.28125C4.53125 8.93607 4.25143 8.65625 3.90625 8.65625C3.56107 8.65625 3.28125 8.93607 3.28125 9.28125C3.28125 9.62643 3.56107 9.90625 3.90625 9.90625Z"/>
<path d="M3.90625 12.625C4.25143 12.625 4.53125 12.3452 4.53125 12C4.53125 11.6548 4.25143 11.375 3.90625 11.375C3.56107 11.375 3.28125 11.6548 3.28125 12C3.28125 12.3452 3.56107 12.625 3.90625 12.625Z"/>
<path d="M6.625 7.1875C6.97018 7.1875 7.25 6.90768 7.25 6.5625C7.25 6.21732 6.97018 5.9375 6.625 5.9375C6.27982 5.9375 6 6.21732 6 6.5625C6 6.90768 6.27982 7.1875 6.625 7.1875Z"/>
</svg>
</template>
