<template>
  <div class="clinic-marketing-data page pb-5 pt-4" style="max-width: 450px;">
    <CheckboxGroup :label="$t('activate_bonus_system')"
                   :checkboxvalue="1"
                   fid="do_not_send_if_passed"
                   v-model="form.bonuses_enabled"/>
    <div v-if="form.bonuses_enabled">
      <InputGroup type="number"
                  :label="$t('bonus_percent_of_cheque')"
                  validation-id="bonus_accrual"
                  :showErrors="true"
                  v-model="form.bonus_accrual"/>
      <InputGroup type="number"
                  :label="$t('bonus_percent_can_be_used')"
                  validation-id="bonus_use"
                  :showErrors="true"
                  v-model="form.bonus_use"/>
      <CheckboxGroup :label="$t('accrue_bonuses_on_bonus_payments')"
                     :checkboxvalue="1"
                     fid="bonuses_on_bonuses"
                     v-model="form.bonuses_on_bonuses"/>
      <CheckboxGroup :label="$t('use_bonuses_on_salary')"
                     :checkboxvalue="1"
                     fid="use_bonuses_on_salary"
                     v-model="form.use_bonuses_on_salary"/>
    </div>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared" @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>

import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { EloquentService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup.vue"

export default {
  name: "SettingsBonuses",
  head() {
    return {
      title: this.$t('clinic_data'),
    }
  },
  components: {
    InputGroup,
    CheckboxGroup,
  },
  data() {
    return {
      form: {},
    }
  },
  computed: {
    ...mapState({
      clinic: state     => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
    }),
    additional() {
      return this.clinic.clinic_additional
    },
  },
  watch: {

  },
  methods: {
    async save() {
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      await EloquentService.update('clinic_additional', this.clinic.id, formData)
      this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
  },
  mounted() {
    this.form = this.copyObject(this.additional)
  }
}
</script>
