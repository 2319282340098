<template>
  <div class="d-inline-block">
    <input type="checkbox"
           :checked="object.finished"
           @click="tickTask"/>
  </div>

</template>

<script>
import { CalendarService } from "@/services/api.service"

export default {
  name: "CRMDatatableTDTaskCheckbox",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    async tickTask() {
      let res = await CalendarService.finishTask(this.object.id)
      if(res.data) this.$store.commit('incrementDataTableCounter')
    }
  },
}
</script>
