<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path
          d="M4.69502 11.7497L2.69502 10.8997C2.44302 10.7917 2.14802 10.9107 2.04002 11.1647C1.93202 11.4187 2.05002 11.7127 2.30502 11.8197L4.30502 12.6697C4.36802 12.6967 4.43502 12.7097 4.50002 12.7097C4.69402 12.7097 4.87902 12.5957 4.96002 12.4047C5.06802 12.1507 4.95002 11.8567 4.69502 11.7497Z"
          fill="#B5C6D8"/>
      <path
          d="M15.993 3.47659C15.99 3.42559 15.987 3.37459 15.968 3.32359C15.957 3.29459 15.937 3.27459 15.921 3.24859C15.91 3.23059 15.902 3.21459 15.89 3.19859C15.834 3.12559 15.765 3.06959 15.682 3.03759C15.679 3.03659 15.678 3.03359 15.675 3.03259L7.675 0.0335898C7.554 -0.0134102 7.421 -0.0104102 7.303 0.0405898L0.303 3.03959C0.296 3.04259 0.293 3.05059 0.286 3.05359C0.217 3.08659 0.159 3.13759 0.111 3.20059C0.097 3.21859 0.085 3.23459 0.073 3.25459C0.063 3.27159 0.048 3.28359 0.04 3.30259C0.013 3.36659 0 3.43259 0 3.49859V3.49959V12.4996C0 12.6996 0.119 12.8806 0.303 12.9596L7.303 15.9596H7.304C7.365 15.9856 7.431 15.9996 7.5 15.9996C7.56 15.9996 7.619 15.9886 7.676 15.9676L7.694 15.9606C7.694 15.9606 7.694 15.9606 7.695 15.9606L15.676 12.9676C15.871 12.8946 16 12.7076 16 12.4996V3.49959C16 3.49059 15.993 3.48459 15.993 3.47659ZM7 14.7406L1 12.1696V4.25856L7 6.82956V14.7406ZM7.512 5.96059L1.77 3.49959L7.512 1.03959L14.076 3.49959L12.511 4.08659L9.705 2.82959C9.563 2.76559 9.401 2.77259 9.266 2.84659L7.305 3.67959C7.12 3.75759 7 3.94159 7 4.14259C7 4.34459 7.122 4.52759 7.31 4.60459L9.129 5.35559L7.512 5.96059ZM11 5.72059L12 5.34559V7.78259L11 8.15759V5.72059ZM10.502 4.83959L8.792 4.13459L9.5 3.83359L11.18 4.58559L10.502 4.83959ZM15 12.1526L8 14.7776V6.84559L10 6.09559V8.87959C10 9.04359 10.081 9.19659 10.215 9.29059C10.3 9.34959 10.399 9.37959 10.5 9.37959C10.559 9.37959 10.619 9.36859 10.676 9.34759L12.676 8.59759C12.871 8.52359 13 8.33759 13 8.12959V4.97059L15 4.22059V12.1526Z"
          fill="#B5C6D8"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
