<template>
  <div>
    <DataTableFilter ref="dataTableFilter"
                     :options="options"
                     class="mt-3"/>

    <div class="row align-items-center">
      <div class="col col-md-3 col-xl-2">
        <InputGroup v-model="nameSearch"
                    class="mb-0"
                    :placeholder="$t('search_by_name')"/>
      </div>
      <div class="col col-md-3 col-xl-2">
        <InputGroup v-model="phoneSearch"
                    class="mb-0"
                    :placeholder="$t('search_by_phone')"/>
      </div>
      <div class="col-auto">
        <CheckboxGroup fid="tasksToday"
                       v-model="withTasksForToday"
                       class="mb-0"
                       :label="$t('has_today_task')"
                       :placeholder="$t('search_by_phone')"/>
      </div>
      <div class="col-auto">
        <CheckboxGroup fid="noTasks"
                       :label="$t('without_tasks')"
                       class="mb-0"
                       v-model="withoutTasks"
                       :placeholder="$t('search_by_phone')"/>
      </div>
    </div>

    <div style="overflow-x: auto; overflow-y: hidden" class="pt-3">

      <b-row class="flex-nowrap" no-gutters>
        <b-col v-for="(l, lind) in CRMStages"
               :key="`lead-${lind}`">
          <div class="lead-wrap h-100"
               :class="{ 'ml-3': lind > 0 }">
            <div>
              {{ $t(l.title) }} | <span :class="{ 'text-danger': !!leads[l.id] }">{{ leads[l.id] ? leads[l.id].length : 0 }}</span>
              <div class="separator w-100 mt-2 mb-3" :style="`background-color: ${l.color}`"/>
            </div>

            <draggable class="list-group h-100"
                       :list="leads[l.id]"
                       group="leads"
                       item-key="id"
                       :data-status="l.id"
                       @change="changed"
                       :move="leadMoved">
              <template #item="{ element }">
                <div @click="showLeadModal(element)"
                     :data-id="element.id"
                     class="lead-card card p-md-3 p-2 mb-3 pointer">
                  <div class="font-weight-bold pr-4">
                    {{ element.patient_name }}
                    <span v-if="element.urgent" class="notification-bubble mr-md-3 mt-md-3 mr-2 mt-2"></span>
                  </div>
                  <div>{{ element.phone }}</div>
                  <div v-if="element.patient_id && element.patient && element.patient.full_name">
                    <router-link :to="{ name: 'PatientVisits', params: { id: element.patient_id } }">{{ element.patient.full_name }}</router-link>
                  </div>
                  <div v-html="element.comment"/>
                  <div>{{ element.reason }}</div>
                  <div>{{ $t('date_add') }}: {{ formatDateTime(element.created_at) }}</div>
                  <div v-if="element.call_date" class="text-danger">
                    {{ $t('recall') }}: {{ formatDate(element.call_date) }}
                  </div>
                  <div v-if="listLeadResponsible(element)">
                    {{ $t('responsible') }}: {{ listLeadResponsible(element) }}
                  </div>
                  <div class="task-bubbles">
                    <div class="d-flex flex-row">
                      <div class="task-bubble success" v-if="getTasksNotStarted(element.tasks) > 0">{{ getTasksNotStarted(element.tasks) }}</div>
                      <div class="task-bubble danger" v-if="getTasksPassed(element.tasks) > 0">{{ getTasksPassed(element.tasks) }}</div>
                      <div class="task-bubble danger" v-if="getNoTasks(element.tasks)">0</div>
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
        </b-col>
      </b-row>

      <LeadShowModal/>

    </div>
  </div>
</template>

<script>

import { EloquentService } from "@/services/api.service"
import LeadShowModal from "@/components/parts/crm/LeadShowModal"
import { mapGetters, mapState } from "vuex"
import options from "@/data/crm/lead"
import draggable from 'vuedraggable'
import DataTableFilter from "@/components/parts/datatable/DataTableFilter"
import moment from "moment/moment"
import { formatDate, formatDateTime } from "@/extensions/filters/filters"
import InputGroup from "@/components/form/InputGroup.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"

export default {
  name: "CRMSalesFunnel",
  components: {
    CheckboxGroup,
    InputGroup,
    DataTableFilter,
    LeadShowModal,
    draggable
  },
  head() {
    return {
      title: this.$t('sales_funnel')
    }
  },
  data() {
    return {
      leads: {},
      options,
      dataToSend: null,
      timer: null,
      nameSearch: '',
      phoneSearch: '',
      withoutTasks: false,
      withTasksForToday: false
    }
  },
  computed: {
    ...mapState({
      counter: state => state.datatable.dataTableCounter,
      filters: state => state.datatable.filters,
      CRMStages: state => state.auth.CRMStages
    }),
    ...mapGetters(['listLeadResponsible'])
  },
  methods: {
    formatDate,
    formatDateTime,
    async loadData() {
      try {
        this.initialLeadsState()
        let filters = this.copyObject(this.filters)
        if(Object.keys(filters).length === 0) {
          filters = {
            custom: {
              id: 'custom',
              condition: true
            }
          }
        }
        const data = await EloquentService.dataTable('lead', 1, {
          pagination: 1000,
          filters,
          order: 'updated_at',
          sort: 'desc'
        })
        data.data.result.data.forEach(lead => {
          if(!this.leads[lead.status_id]) {
            this.$set(this.leads, lead.status_id, [])
          }
          this.leads[lead.status_id].push(lead)
        })
      } catch (e) {
        console.log(e)
      }
    },
    showLeadModal(lead) {
      this.$store.commit('setLead', this.copyObject(lead))
      this.$bvModal.show('lead-show-modal')
    },
    leadMoved(e) {
      const leadId = String(e.dragged.getAttribute('data-id'))
      const newStatus = e.to.getAttribute('data-status')
      const oldStatus = e.from.getAttribute('data-status')
      const lead = this.leads[oldStatus].find(x => String(x.id) === leadId)
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        status_id: newStatus,
        source: lead.source,
        fname: lead.fname,
        phone: lead.phone,
      })
      this.dataToSend = { leadId, formData }
      // EloquentService.update('lead', leadId, formData, false, false)
    },
    async changed() {
      if(!this.dataToSend) return
      try {
        await EloquentService.update('lead', this.dataToSend.leadId, this.dataToSend.formData, false, false)
      } catch (e) {
        this.$noty.error(e?.response?.data?.message || this.$t('error'))
      }
      this.dataToSend = null
    },
    initialLeadsState() {
      this.leads = {}
      this.CRMStages.forEach(status => {
        this.$set(this.leads, status.id, [])
      })
    },
    async loadStatuses() {
      await this.$store.dispatch('getCRMStages')
    },
    getTasksNotStarted(tasks) {
      return tasks.filter(task => !task.finished && task.start > moment().format('YYYY-MM-DD HH:mm:ss')).length
    },
    getTasksPassed(tasks) {
      return tasks.filter(task => !task.finished && task.deadline < moment().format('YYYY-MM-DD HH:mm:ss')).length
    },
    getNoTasks(tasks) {
      return tasks.length === 0 || (tasks.length > 0 && tasks.every(task => task.finished))
    }
  },
  async mounted() {
    this.$store.commit("setViewModel", 'lead')
    this.$store.commit('setEloquentOptions', options)
    this.$store.commit('setFilters', {})
    await this.loadStatuses()
    await this.loadData()
  },
  watch: {
    counter() {
      this.loadData()
    },
    $route() {
      this.loadData()
    },
    nameSearch(val) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const dataFilter = this.$refs.dataTableFilter
        if(val) {
          dataFilter.filters.patient_id = {
            "id": "patient_id",
            "title": "patient",
            "condition": val,
            "type": "text",
            "ctype": "like"
          }
        } else {
          delete dataFilter.filters.patient_id
        }
        dataFilter.refresh()
      }, 600)
    },
    phoneSearch(val) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        const dataFilter = this.$refs.dataTableFilter
        if(val) {
          dataFilter.filters.phone = {
            "id": "phone",
            "title": "phone",
            "condition": val,
            "type": "text",
            "ctype": "like"
          }
        } else {
          delete dataFilter.filters.phone
        }
        dataFilter.refresh()
      }, 600)
    },
    withoutTasks(val) {
      const dataFilter = this.$refs.dataTableFilter
      if(val) {
        dataFilter.filters.has_tasks = {
          "id": "has_tasks",
          "title": "has_tasks",
          "condition": 0,
          "type": "boolean",
          "ctype": "equals"
        }
      } else {
        delete dataFilter.filters.has_tasks
      }
      dataFilter.refresh()
    },
    withTasksForToday(val) {
      const dataFilter = this.$refs.dataTableFilter
      if(val) {
        dataFilter.filters.has_today_tasks = {
          "id": "has_today_tasks",
          "title": "has_today_tasks",
          "condition": 1,
          "type": "boolean",
          "ctype": "equals"
        }
      } else {
        delete dataFilter.filters.has_today_tasks
      }
      dataFilter.refresh()
    }
  },
  created() {
    draggable.compatConfig = { MODE: 3 }
  },
  beforeUnmount() {
    this.$store.commit('setFilters', {})
  }
}
</script>

<style scoped lang="scss">
.separator {
  height: 2px;
}
.lead-wrap {
  min-width: 220px;
}
.lead-card {
  border-radius: 8px;
  &:hover {
    background-color: $light-blue;
  }
}
@media screen and (max-width: 768px) {
  .lead-wrap {
    min-width: 150px;
  }
}
</style>
