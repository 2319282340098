<template>
  <section id="staff" class="staff">
    <div>
      <transition name="fade" mode="out-in" >
        <div class="section-top-row">
          <div class="row row-narrow align-items-center no-print position-relative">
            <div class="col order-md-0 order-0">
              <h1>{{ $t('cashdesk') }}</h1>
            </div>
          </div>
        </div>
      </transition>
      <div class="sub-menu no-print">
        <template v-for="(m, mind) in menu">
          <router-link :to="{ name: m.to }"
                       v-if="!m.right || rights.includes(m.right)"
                       :key="`cashdesk-link-${mind}}`">
            {{ $t(m.title) }}
          </router-link>
        </template>
      </div>
    </div>
    <router-view v-if="loaded" @update="update"/>
  </section>
</template>

<script>

import { mapActions, mapState } from "vuex"

export default {
  name: "CashdeskGeneral",
  components: {
  },
  data() {
    return {
      checkboxMenu: [
        { to: 'CashdeskSettings', title: 'settings', right: 'ppo' },
        { to: 'CashdeskCheques', title: 'cheques', right: 'ppo' },
        { to: 'CashdeskXReports', title: 'xreports', right: 'ppo' },
        { to: 'CashdeskShifts', title: 'shifts', right: 'ppo' },
      ],
      webkassaMenu: [
        { to: 'CashdeskSettings', title: 'settings', right: 'webkassa' },
        { to: 'CashdeskCheques', title: 'cheques', right: 'webkassa' },
        { to: 'CashdeskXReports', title: 'xreports', right: 'webkassa' },
        { to: 'CashdeskShifts', title: 'shifts', right: 'webkassa' },
      ],
      aqsiMenu: [
        { to: 'CashdeskSettings', title: 'settings', right: 'aqsi' },
        { to: 'CashdeskCheques', title: 'cheques', right: 'aqsi' },
        { to: 'CashdeskShifts', title: 'shifts', right: 'aqsi' },
      ],
      kkmMenu: [
        { to: 'CashdeskSettings', title: 'settings', right: 'kkm' },
        // { to: 'CashdeskCheques', title: 'cheques', right: 'kkm' },
        { to: 'CashdeskKKMShifts', title: 'shifts', right: 'kkm' },
        { to: 'CashdeskKKMReports', title: 'reports', right: 'kkm' },
        { to: 'CashdeskKKMOperations', title: 'warehouseoperation', right: 'kkm' },
        { to: 'CashdeskKKMTerminal', title: 'terminal', right: 'kkm' },
      ],
      loaded: false
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
      ppoData: state => state.ppo.ppoData
    }),
    menu() {
      if(this.ppoData.vendor === 'checkbox') return this.checkboxMenu
      if(this.ppoData.vendor === 'webkassa') return this.webkassaMenu
      if(this.ppoData.vendor === 'aqsi') return this.aqsiMenu
      if(this.ppoData.vendor === 'kkm') return this.kkmMenu
      return []
    }
  },
  methods: {
    ...mapActions(['getCashdeskData']),
    update() {
      this.$store.dispatch('init')
      this.getCashdeskData()
    }
  },
  async mounted() {
    await this.getCashdeskData()
    this.loaded = true
  }
}
</script>
