import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import ServicesGeneral from "@/components/pages/services/ServicesGeneral"
import Services from "@/components/pages/services/Services"
import ComplexServices from "@/components/pages/services/ComplexServices"
import Prices from "@/components/pages/services/Prices"
import Categories from "@/components/pages/services/Categories"
import Archive from "@/components/pages/services/Archive"

export default {
    path: '/services',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['clinic_services'] },
    // component:() => import('../components/pages/services/ServicesGeneral.vue'),
    component: ServicesGeneral,
    children: [
        { path: '', redirect: '/services/services' },
        {
            path: 'services', name: "ServicesServices",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['clinic_services'] },
            // component: () => import('../components/pages/services/Services.vue')
            component: Services
        },
        {
            path: 'categories', name: "ServicesCategories",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['clinic_services'] },
            component: Categories
        },
        {
            path: 'archive', name: "ServicesArchive",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['clinic_services'] },
            component: Archive
        },
        {
            path: 'prices', name: "ServicesPrices",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['clinic_services'] },
            component: Prices
        },
        {
            path: 'complex_services', name: "ServicesComplexServices",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['clinic_services'] },
            component: ComplexServices
        },
    ]
}
