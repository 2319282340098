<template>
  <section id="logs" class="logs">
      <DataTable :options="options"
                 :params="{ clinic_id: clinic.id }"
                 module="superadmin"/>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/settings/log"
import {mapState} from "vuex"

export default {
  name: "Logs",
  components: {
    DataTable,
  },
  head() {
    return {
      title: this.$t('logs'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
