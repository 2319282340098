<template>
  <div>
    <span class="text-warning" v-if="object.refunded_at">{{ $t('return') }}</span>
    <span class="text-primary" v-else-if="object.paid == 1">{{ $t('paid') }}</span>
    <span class="text-muted" v-else-if="object.paid == 0">{{ $t('unpaid') }}</span>
  </div>
</template>

<script>

export default {
  name: "SuperAdminDatatableTDPaymentStatus",
  props: {
    object: {
      type: Object
    }
  },
  computed: {

  }
}
</script>
