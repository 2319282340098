<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path
        d="M3.33089 13.1693C3.20289 13.1693 3.07489 13.1206 2.97755 13.0226C2.78222 12.8273 2.78222 12.5106 2.97755 12.3153L11.7096 3.58327C11.9049 3.38793 12.2216 3.38793 12.4169 3.58327C12.6122 3.7786 12.6122 4.09527 12.4169 4.2906L3.68422 13.0233C3.58689 13.1206 3.45889 13.1693 3.33089 13.1693Z"
        fill="#87939E"/>
    <path
        d="M0.499723 16.0001C0.368389 16.0001 0.240389 15.9481 0.146389 15.8534C0.0277227 15.7354 -0.022944 15.5654 0.00972269 15.4021L0.953056 10.6881C0.972389 10.5908 1.02039 10.5021 1.08972 10.4328L10.8024 0.719432C11.7291 -0.207902 13.3471 -0.208568 14.2764 0.719432L15.2804 1.72343C16.2384 2.68143 16.2384 4.23943 15.2804 5.19743L5.56772 14.9101C5.49839 14.9801 5.40906 15.0274 5.31239 15.0468L0.598389 15.9901C0.565723 15.9968 0.532389 16.0001 0.499723 16.0001ZM1.90306 11.0321L1.13706 14.8628L4.96706 14.0961L14.5731 4.49077C15.1411 3.92277 15.1411 2.99876 14.5731 2.43077L13.5691 1.42677C13.0171 0.876099 12.0571 0.876765 11.5091 1.42677L1.90306 11.0321ZM5.21372 14.5568H5.22039H5.21372Z"
        fill="#87939E"/>
    <path
        d="M5.21386 15.0566C5.08586 15.0566 4.95786 15.0079 4.86053 14.9099L1.08986 11.1392C0.894526 10.9439 0.894526 10.6272 1.08986 10.4319C1.28519 10.2366 1.60186 10.2366 1.79719 10.4319L5.56786 14.2026C5.76319 14.3979 5.76319 14.7146 5.56786 14.9099C5.46986 15.0079 5.34253 15.0566 5.21386 15.0566Z"
        fill="#87939E"/>
    <path
        d="M13.9482 6.32195C13.8202 6.32195 13.6922 6.27328 13.5949 6.17528L9.82423 2.40461C9.6289 2.20928 9.6289 1.89261 9.82423 1.69728C10.0196 1.50195 10.3362 1.50195 10.5316 1.69728L14.3022 5.46795C14.4976 5.66328 14.4976 5.97995 14.3022 6.17528C14.2042 6.27328 14.0762 6.32195 13.9482 6.32195Z"
        fill="#87939E"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="16" height="16" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
