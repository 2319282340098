<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
        <path d="M19.3726 80.9559L17.2888 82.2257H13.8051L11.7051 80.9559L13.3168 79.4094L11.233 77.8629L13.1214 76.3164L10.8261 74.6234L12.6493 73.0769L10.354 71.6769L12.3726 70.2606L10.354 68.649L12.047 67.1025L9.81676 65.6862L11.5749 64.2211L9.27956 62.6746L11.4284 61.1281L9.27956 59.5165L10.8261 58.1653L8.74235 56.6188L10.5656 55.1374L8.48188 53.5909L10.2889 51.849L9.27956 50.1723V35.9118L11.1679 28.5863H17.2888" fill="#8889BE"/>
        <path d="M11.9653 80.9559L14.049 82.2257H17.5164L19.6327 80.9559L18.0048 79.4094L20.1048 77.8629L18.2164 76.3164L20.4955 74.6234L18.6885 73.0769L20.9676 71.6769L18.949 70.2606L20.9676 68.649L19.2908 67.1025L21.5048 65.6862L19.7629 64.2211L22.042 62.6746L19.8932 61.1281L22.042 59.5165L20.4955 58.1653L22.5792 56.6188L20.7722 55.1374L22.856 53.5909L21.0327 51.849L22.042 50.1723V35.9118L20.1699 28.5863H16.2629" fill="#8889BE"/>
        <path d="M30.6585 13.8337C30.9806 15.752 32.3808 25.4138 26.1217 32.5551C24.0825 35.0891 21.2934 36.9125 18.1542 37.764C17.7762 37.848 10.6069 39.5143 6.01402 35.1035C3.96964 33.1432 3.38154 30.8328 2.30334 26.3099C1.33339 22.2232 0.863197 18.0338 0.903084 13.8337L10.5508 3.41575C10.5508 3.41575 14.9196 -1.31711 19.5685 3.41575C24.2173 8.14862 30.6585 13.8337 30.6585 13.8337Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--        <path d="M5.11786 33.6028L5.38391 45.0709C6.61213 52.0664 6.99294 59.1843 6.51811 66.2708C6.07438 70.3647 6.26804 74.5026 7.09222 78.5371C7.38627 79.9373 9.20661 88.1008 11.8391 88.1288C13.6454 88.1288 15.0737 84.2781 15.5358 83.0459C16.936 79.2652 16.2639 76.9828 16.684 73.1041C17.076 69.5614 17.9862 68.1472 19.6945 63.3863C21.6248 57.9624 23.1277 52.3957 24.1893 46.7372L25.5896 32.4966" fill="#DEE1E9"/>-->
<!--        <path d="M30.6585 13.2151C30.9806 15.1335 32.3808 24.7952 26.1217 31.9365C24.0825 34.4706 21.2934 36.294 18.1542 37.1455C17.7762 37.2295 10.6069 38.8958 6.01402 34.485C3.96964 32.5247 3.38154 30.2142 2.30334 25.6914C1.33339 21.6046 0.863197 17.4152 0.903084 13.2151L10.5508 2.79722C10.5508 2.79722 14.9196 -1.93564 19.5685 2.79722C24.2173 7.53009 30.6585 13.2151 30.6585 13.2151Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>-->
<!--        <path opacity="0.2" d="M21.5567 30.6483C23.209 32.0486 20.4085 36.1093 17.874 45.351C16.9865 48.6205 16.2714 51.9343 15.7316 55.2788C14.8074 60.6978 15.0735 61.58 13.8973 68.5673C13.4072 71.5937 13.0386 74.4352 12.4527 78.4228C11.6546 81.0693 12.147 85.6296 11.8669 85.6156C11.3348 85.6156 9.85054 77.4029 10.0046 74.1683C10.0804 72.5086 10.2769 70.8566 10.5927 69.2254C11.2144 65.3804 11.57 61.4972 11.6569 57.6033C11.9789 50.602 12.147 47.0733 11.2368 43.4047C10.3266 39.736 10.3686 36.2354 9.52848 32.7207C9.37446 32.0486 8.96838 30.4243 9.75253 29.8362C10.5367 29.2481 11.7129 29.9902 13.1691 30.4383C17.72 31.8526 20.1704 29.4021 21.5567 30.6483Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M5.19769 33.2141L5.46374 44.6822C6.69196 51.6776 7.07278 58.7956 6.59795 65.8821C6.15421 69.9759 6.34788 74.1138 7.17205 78.1483C7.46611 79.5486 9.28644 87.7121 11.9189 87.7401C13.7253 87.7401 15.1535 83.8894 15.6156 82.6571C17.0159 78.8765 16.3437 76.594 16.7638 72.7153C17.1559 69.1727 18.066 67.7584 19.7744 62.9976C21.7047 57.5736 23.2075 52.007 24.2692 46.3485L25.6694 32.1079" fill="#DEE1E9"/>
        <mask id="mask435" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask435)">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M14.5633 16.7991C13.5012 16.7991 12.2556 11.5008 12.2556 1.90252L14.1787 0.0404528L17.2556 -1.20093C17.2556 8.3974 15.6254 16.7991 14.5633 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.751 16.0862C23.4335 17.8367 20.5818 22.9129 18.001 34.4656C17.0973 38.5526 16.3691 42.6952 15.8195 46.876C14.8784 53.6501 15.1493 54.7529 13.9516 63.4874C13.4526 67.2707 13.0773 70.8228 12.4807 75.8076C11.668 79.1159 12.1693 84.8165 11.8842 84.799C11.3423 84.799 9.83095 74.5326 9.9878 70.4891C10.065 68.4143 10.2651 66.3492 10.5867 64.3101C11.2198 59.5037 11.5818 54.6494 11.6703 49.7817C11.9982 41.0296 12.1693 36.6186 11.2425 32.0325C10.3157 27.4464 10.3585 23.0704 9.50301 18.6769C9.34617 17.8367 8.93267 15.8062 9.73115 15.071C10.5296 14.3358 11.7273 15.2635 13.2102 15.8237C17.8442 17.5916 20.3394 14.5284 21.751 16.0862Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
        <path d="M5.11786 33.6028L5.38391 45.0709C6.61213 52.0664 6.99294 59.1843 6.51811 66.2708C6.07438 70.3647 6.26804 74.5026 7.09222 78.5371C7.38627 79.9373 9.20661 88.1008 11.8391 88.1288C13.6454 88.1288 15.0737 84.2781 15.5358 83.0459C16.936 79.2652 16.2639 76.9828 16.684 73.1041C17.076 69.5614 17.9862 68.1472 19.6945 63.3863C21.6248 57.9624 23.1277 52.3957 24.1893 46.7372L25.5896 32.4966" fill="#DEE1E9"/>
        <path d="M30.6585 13.2151C30.9806 15.1335 32.3808 24.7952 26.1217 31.9365C24.0825 34.4706 21.2934 36.294 18.1542 37.1455C17.7762 37.2295 10.6069 38.8958 6.01402 34.485C3.96964 32.5247 3.38154 30.2142 2.30334 25.6914C1.33339 21.6046 0.863197 17.4152 0.903084 13.2151L10.5508 2.79722C10.5508 2.79722 14.9196 -1.93564 19.5685 2.79722C24.2173 7.53009 30.6585 13.2151 30.6585 13.2151Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M21.5567 30.6483C23.209 32.0486 20.4085 36.1093 17.874 45.351C16.9865 48.6205 16.2714 51.9343 15.7316 55.2788C14.8074 60.6978 15.0735 61.58 13.8973 68.5673C13.4072 71.5937 13.0386 74.4352 12.4527 78.4228C11.6546 81.0693 12.147 85.6296 11.8669 85.6156C11.3348 85.6156 9.85054 77.4029 10.0046 74.1683C10.0804 72.5086 10.2769 70.8566 10.5927 69.2254C11.2144 65.3804 11.57 61.4972 11.6569 57.6033C11.9789 50.602 12.147 47.0733 11.2368 43.4047C10.3266 39.736 10.3686 36.2354 9.52848 32.7207C9.37446 32.0486 8.96838 30.4243 9.75253 29.8362C10.5367 29.2481 11.7129 29.9902 13.1691 30.4383C17.72 31.8526 20.1704 29.4021 21.5567 30.6483Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="12" cy="88" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
        <path d="M0.920134 13.0728C0.874233 17.2732 1.34451 21.4633 2.32039 25.5491C3.21655 29.3718 3.72065 31.6122 5.1209 33.3905L5.38695 44.8586C6.59273 51.8803 6.94535 59.0221 6.43714 66.1285C5.9934 70.2223 6.18707 74.3602 7.01124 78.3947C7.3053 79.795 9.12563 87.9585 11.7581 87.9865C13.5644 87.9865 14.9927 84.1358 15.4548 82.9035C16.855 79.1228 16.1829 76.8404 16.603 72.9617C16.9951 69.4191 17.9052 68.0048 19.6135 63.2439C21.5629 57.8216 23.0845 52.2549 24.1644 46.5949L25.5646 32.3543L26.0547 31.7942C32.2859 24.6529 30.8856 14.9911 30.5635 13.0728C30.5635 13.0728 24.0944 7.47175 19.4735 2.65487C14.8527 -2.16201 10.4559 2.65487 10.4559 2.65487L0.920134 13.0728Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M0.920134 13.0728C0.874233 17.2732 1.34451 21.4633 2.32039 25.5491C3.21655 29.3718 3.72065 31.6122 5.1209 33.3905L5.38695 44.8586C6.59273 51.8803 6.94535 59.0221 6.43714 66.1285C5.9934 70.2223 6.18707 74.3602 7.01124 78.3947C7.3053 79.795 9.12563 87.9585 11.7581 87.9865C13.5644 87.9865 14.9927 84.1358 15.4548 82.9035C16.855 79.1228 16.1829 76.8404 16.603 72.9617C16.9951 69.4191 17.9052 68.0048 19.6135 63.2439C21.5629 57.8216 23.0845 52.2549 24.1644 46.5949L25.5646 32.3543L26.0547 31.7942C32.2859 24.6529 30.8856 14.9911 30.5635 13.0728C30.5635 13.0728 24.0944 7.47175 19.4735 2.65487C14.8527 -2.16201 10.4559 2.65487 10.4559 2.65487L0.920134 13.0728Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
        <path d="M0.518063 15.8831L0.252014 4.41498L5.30994 3.30878L10.5926 5.48984L16.3099 2.5L20.7238 3.30878L19.3235 17.5494C18.2619 23.2078 16.759 28.7745 14.8287 34.1984C13.1204 38.9593 12.2102 40.3735 11.8181 43.9162C11.3981 47.7949 12.0702 50.0773 10.6699 53.858L10.658 53.8897C10.1837 55.1549 8.76435 58.9409 6.97324 58.9409C4.34076 58.9129 2.52043 50.7494 2.22637 49.3492C1.4022 45.3147 1.20853 41.1768 1.65227 37.083C2.1271 29.9964 1.74628 22.8785 0.518063 15.8831Z" fill="#DEE1E9"/>
        <path opacity="0.2" d="M13.0081 16.1632C15.5426 6.92146 16.6908 1.46046 16.6908 1.46046L15.5412 1.09558L15.0614 1.5L12.8099 3L10.3099 1.5L7.80988 3L4.80988 1C4.80988 1 4.50857 2.86072 4.6626 3.53284C5.50275 7.04749 5.46074 10.5481 6.37091 14.2168C7.27629 17.8662 7.11478 21.377 6.79605 28.3054L6.79099 28.4154C6.70411 32.3093 6.34854 36.1926 5.72679 40.0375C5.41103 41.6687 5.21447 43.3207 5.13868 44.9804C4.98466 48.215 6.46893 56.4277 7.00102 56.4277C7.28108 56.4418 6.78872 51.8815 7.58686 49.235C8.1727 45.2473 8.54132 42.4059 9.0314 39.3794C10.2076 32.3921 9.94156 31.51 10.8657 26.091C11.4055 22.7465 12.1206 19.4326 13.0081 16.1632Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M18.9985 11.1249C11.2711 13.7543 7.43939 12.7998 1.31699 7.5976" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M5.56854 33.9091L5.83459 45.3772C7.06281 52.3726 7.44363 59.4906 6.9688 66.5771C6.52506 70.6709 6.71873 74.8088 7.5429 78.8433C7.83696 80.2436 9.65729 88.4071 12.2898 88.4351C14.0961 88.4351 15.5244 84.5844 15.9864 83.3522C17.3867 79.5715 16.7146 77.289 17.1347 73.4103C17.5267 69.8677 18.4369 68.4534 20.1452 63.6926C22.0755 58.2686 23.5784 52.702 24.64 47.0435L26.0403 32.8029" fill="#DEE1E9"/>
        <path d="M26.5724 32.2428C32.8315 25.1015 31.4313 15.4397 31.1092 13.5214C31.1092 13.5214 24.668 7.83636 20.0192 3.1035C15.3703 -1.62937 11.0015 3.1035 11.0015 3.1035L9.33404 4.90409L10.5968 5.16412L11.9355 8.1991L8.12646 9.71384L9.33404 12.0273L8.12646 14.4941L9.03356 17.1394H5.12646L1.35377 14.507C1.39051 18.3764 1.85996 22.2307 2.75402 25.9977C3.83222 30.5205 4.42033 32.8309 6.4647 34.7913C11.0575 39.2021 18.2269 37.5358 18.6049 37.4518C21.7441 36.6003 24.5332 34.7768 26.5724 32.2428Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M4.95069 24.5387C15.0497 20.5435 17.3627 16.0507 16.207 4.49416" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M22.0074 30.9546C23.6597 32.3549 20.8592 36.4156 18.3247 45.6573C17.4372 48.9268 16.7221 52.2406 16.1823 55.5851C15.2581 61.0041 15.5242 61.8863 14.348 68.8735C13.8579 71.9 13.4893 74.7415 12.9034 78.7291C12.1053 81.3756 12.5976 85.9359 12.3176 85.9219C11.7855 85.9219 10.3012 77.7092 10.4553 74.4746C10.531 72.8148 10.7276 71.1628 11.0434 69.5317C11.6651 65.6867 12.0207 61.8034 12.1076 57.9095C12.4296 50.9083 12.5976 47.3796 11.6875 43.7109C10.7773 40.0423 10.8193 36.5416 9.97917 33.027C9.82514 32.3549 9.41907 30.7306 10.2032 30.1425C10.9874 29.5544 12.1636 30.2965 13.6198 30.7446C18.1707 32.1588 20.6211 29.7084 22.0074 30.9546Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M5.45874 17.7494V45.1325C6.68696 52.1279 7.06777 59.2459 6.59294 66.3324C6.1492 70.4262 6.34287 74.5641 7.16705 78.5986C7.4611 79.9989 9.28144 88.1623 11.9139 88.1903C13.7202 88.1903 15.1485 84.3396 15.6106 83.1074C17.0108 79.3267 16.3387 77.0443 16.7588 73.1656C17.1509 69.623 18.061 68.2087 19.7694 63.4479C21.6997 58.0239 23.2025 52.4572 24.2642 46.7988L25.6644 15.6432" fill="#DEE1E9"/>
        <path d="M26.1965 31.9981C32.4557 24.8568 31.0554 15.195 30.7333 13.2766C30.7333 13.2766 24.2922 7.59161 19.6433 2.85874C14.9945 -1.87412 10.6257 2.85874 10.6257 2.85874L0.977913 13.2766C0.938026 17.4767 1.40822 21.6662 2.37817 25.7529C3.31434 29.68 3.88104 31.9391 5.35233 33.7494H9.25061L10.7506 23.7494C14.461 22.6024 16.5399 22.5746 20.2506 23.7494L21.3982 33.7494H24.5459C25.1382 33.212 25.6904 32.6269 26.1965 31.9981Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M5.83459 17.5V44.8831C7.06281 51.8785 7.44363 58.9965 6.9688 66.083C6.52506 70.1768 6.71873 74.3147 7.5429 78.3492C7.83696 79.7495 9.65729 87.9129 12.2898 87.9409C14.0961 87.9409 15.5244 84.0903 15.9864 82.858C17.3867 79.0774 16.7146 76.795 17.1347 72.9162C17.5267 69.3736 18.4369 67.9593 20.1452 63.1985C22.0755 57.7746 23.5784 52.2078 24.64 46.5494L26.0403 15.3938" fill="#DEE1E9"/>
        <path d="M26.5724 31.7487C32.8315 24.6074 31.4313 14.9456 31.1092 13.0273C31.1092 13.0273 24.668 7.34222 20.0192 2.60935C15.3703 -2.12351 11.0015 2.60935 11.0015 2.60935L1.35377 13.0273C1.31388 17.2274 1.78407 21.4168 2.75402 25.5035C3.6902 29.4306 4.25689 31.6897 5.72819 33.5H9.62646L11.1265 23.5C14.8368 22.353 16.9158 22.3252 20.6265 23.5L21.7741 33.5H24.9217C25.514 32.9627 26.0663 32.3775 26.5724 31.7487Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M5.46374 17.5V44.8831C6.69196 51.8785 7.07278 58.9965 6.59795 66.083C6.15421 70.1768 6.34788 74.3147 7.17205 78.3492C7.46611 79.7495 9.28644 87.9129 11.9189 87.9409C13.7253 87.9409 15.1535 84.0903 15.6156 82.858C17.0159 79.0773 16.3437 76.7949 16.7638 72.9162C17.1559 69.3736 18.066 67.9593 19.7744 63.1985C21.7047 57.7745 23.2075 52.2078 24.2692 46.5494L25.6694 15.3938" fill="#DEE1E9"/>
        <mask id="mask2035" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="35">
          <path d="M26.359 32.2428C32.6181 25.1015 31.2179 15.4397 30.8958 13.5214C30.8958 13.5214 24.4546 7.83636 19.8058 3.1035C15.1569 -1.62937 10.7882 3.1035 10.7882 3.1035L1.14039 13.5214C1.1005 17.7215 1.57069 21.9109 2.54064 25.9977C3.47682 29.9248 4.04351 32.1839 5.51481 33.9941H9.41309L10.9131 23.9941C14.6234 22.8472 16.7024 22.8193 20.4131 23.9941L21.5607 33.9941H24.7083C25.3007 33.4568 25.8529 32.8717 26.359 32.2428Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2035)">
          <path d="M26.359 32.2428C32.6181 25.1015 31.2179 15.4397 30.8958 13.5214C30.8958 13.5214 24.4546 7.83636 19.8058 3.1035C15.1569 -1.62937 10.7882 3.1035 10.7882 3.1035L1.14039 13.5214C1.1005 17.7215 1.57069 21.9109 2.54064 25.9977C3.47682 29.9248 4.04351 32.1839 5.51481 33.9941H9.41309L10.9131 23.9941C14.6234 22.8472 16.7024 22.8193 20.4131 23.9941L21.5607 33.9941H24.7083C25.3007 33.4568 25.8529 32.8717 26.359 32.2428Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M6.25562 47H26.2556V21.452C18.4451 19.5258 14.0661 19.5062 6.25562 21.452V47Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M5.33466 33.5559L5.6007 45.024C6.82893 52.0194 7.20974 59.1374 6.73491 66.2239C6.29117 70.3177 6.48484 74.4556 7.30902 78.4901C7.60307 79.8904 9.4234 88.0539 12.0559 88.0819C13.8622 88.0819 15.2905 84.2312 15.7526 82.9989C17.1528 79.2183 16.4807 76.9358 16.9008 73.0571C17.2928 69.5145 18.203 68.1002 19.9113 63.3394C21.8416 57.9154 23.3445 52.3488 24.4061 46.6903L25.8064 32.4497" fill="#DEE1E9"/>
        <path d="M30.8753 13.1684C31.1974 15.0867 32.5976 24.7485 26.3385 31.8898C24.2993 34.4238 21.5102 36.2472 18.371 37.0987C17.993 37.1828 10.8237 38.8491 6.23081 34.4383C4.18644 32.4779 3.59833 30.1675 2.52014 25.6447C1.55019 21.5579 1.07999 17.3685 1.11988 13.1684L10.7676 2.75047C10.7676 2.75047 15.1364 -1.9824 19.7853 2.75047C24.4341 7.48333 30.8753 13.1684 30.8753 13.1684Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M21.7735 30.6015C23.4258 32.0017 20.6253 36.0625 18.0908 45.3041C17.2033 48.5736 16.4882 51.8875 15.9484 55.232C15.0242 60.6509 15.2903 61.5331 14.1141 68.5204C13.624 71.5469 13.2554 74.3883 12.6695 78.376C11.8714 81.0225 12.3638 85.5827 12.0837 85.5687C11.5516 85.5687 10.0673 77.356 10.2214 74.1214C10.2972 72.4617 10.4937 70.8097 10.8095 69.1785C11.4312 65.3336 11.7868 61.4503 11.8737 57.5564C12.1957 50.5551 12.3638 47.0265 11.4536 43.3578C10.5434 39.6891 10.5854 36.1885 9.74528 32.6738C9.59125 32.0017 9.18518 30.3774 9.96932 29.7893C10.7535 29.2012 11.9297 29.9433 13.3859 30.3914C17.9368 31.8057 20.3872 29.3552 21.7735 30.6015Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M6.75916 66.1442C11.2406 68.4861 13.743 68.3794 18.1979 66.1442" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M5.19769 33.415L5.46374 44.8831C6.69196 51.8785 7.07278 58.9965 6.59795 66.083C6.15421 70.1769 6.34788 74.3147 7.17205 78.3493C7.46611 79.7495 9.28644 87.913 11.9189 87.941C13.7253 87.941 15.1535 84.0903 15.6156 82.8581C17.0159 79.0774 16.3437 76.795 16.7638 72.9163C17.1559 69.3736 18.066 67.9594 19.7744 63.1985C21.7047 57.7746 23.2075 52.2079 24.2692 46.5494L25.6694 32.3088" fill="#DEE1E9"/>
        <mask id="mask2235" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M30.8958 13.5214C31.2179 15.4397 32.6181 25.1015 26.359 32.2428C24.3198 34.7768 21.5307 36.6003 18.3915 37.4518C18.0135 37.5358 10.8442 39.2021 6.25132 34.7913C4.20695 32.8309 3.61884 30.5205 2.54064 25.9977C1.57069 21.9109 1.1005 17.7215 1.14039 13.5214L10.7882 3.1035C10.7882 3.1035 15.1569 -1.62937 19.8058 3.1035C24.4546 7.83636 30.8958 13.5214 30.8958 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2235)">
          <path d="M30.8958 13.5214C31.2179 15.4397 32.6181 25.1015 26.359 32.2428C24.3198 34.7768 21.5307 36.6003 18.3915 37.4518C18.0135 37.5358 10.8442 39.2021 6.25132 34.7913C4.20695 32.8309 3.61884 30.5205 2.54064 25.9977C1.57069 21.9109 1.1005 17.7215 1.14039 13.5214L10.7882 3.1035C10.7882 3.1035 15.1569 -1.62937 19.8058 3.1035C24.4546 7.83636 30.8958 13.5214 30.8958 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M14.5633 17C13.5012 17 12.2556 11.7018 12.2556 2.10345L14.1787 0.241381L17.2556 -1C17.2556 8.59833 15.6254 17 14.5633 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.751 16.2872C23.4335 18.0376 20.5818 23.1138 18.001 34.6665C17.0973 38.7536 16.3691 42.8961 15.8195 47.0769C14.8784 53.851 15.1493 54.9538 13.9516 63.6884C13.4526 67.4717 13.0773 71.0237 12.4807 76.0085C11.668 79.3168 12.1693 85.0175 11.8842 85C11.3423 85 9.83095 74.7335 9.9878 70.69C10.065 68.6153 10.2651 66.5501 10.5867 64.5111C11.2198 59.7046 11.5818 54.8503 11.6703 49.9826C11.9982 41.2306 12.1693 36.8195 11.2425 32.2334C10.3157 27.6474 10.3585 23.2713 9.50301 18.8778C9.34617 18.0376 8.93267 16.0071 9.73115 15.2719C10.5296 14.5368 11.7273 15.4645 13.2102 16.0246C17.8442 17.7925 20.3394 14.7293 21.751 16.2872Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 12.2556 86.2009)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M5.19769 33.415L5.46374 44.8831C6.69196 51.8785 7.07278 58.9965 6.59795 66.083C6.15421 70.1769 6.34788 74.3147 7.17205 78.3493C7.46611 79.7495 9.28644 87.913 11.9189 87.941C13.7253 87.941 15.1535 84.0903 15.6156 82.8581C17.0159 79.0774 16.3437 76.795 16.7638 72.9163C17.1559 69.3736 18.066 67.9594 19.7744 63.1985C21.7047 57.7746 23.2075 52.2079 24.2692 46.5494L25.6694 32.3088" fill="#DEE1E9"/>
        <mask id="mask2335" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M30.8958 13.5214C31.2179 15.4397 32.6181 25.1015 26.359 32.2428C24.3198 34.7768 21.5307 36.6003 18.3915 37.4518C18.0135 37.5358 10.8442 39.2021 6.25132 34.7913C4.20695 32.8309 3.61884 30.5205 2.54064 25.9977C1.57069 21.9109 1.1005 17.7215 1.14039 13.5214L10.7882 3.1035C10.7882 3.1035 15.1569 -1.62937 19.8058 3.1035C24.4546 7.83636 30.8958 13.5214 30.8958 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2335)">
          <path d="M30.8958 13.5214C31.2179 15.4397 32.6181 25.1015 26.359 32.2428C24.3198 34.7768 21.5307 36.6003 18.3915 37.4518C18.0135 37.5358 10.8442 39.2021 6.25132 34.7913C4.20695 32.8309 3.61884 30.5205 2.54064 25.9977C1.57069 21.9109 1.1005 17.7215 1.14039 13.5214L10.7882 3.1035C10.7882 3.1035 15.1569 -1.62937 19.8058 3.1035C24.4546 7.83636 30.8958 13.5214 30.8958 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M14.5633 17C13.5012 17 12.2556 11.7018 12.2556 2.10345L14.1787 0.241381L17.2556 -1C17.2556 8.59833 15.6254 17 14.5633 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.751 16.2872C23.4335 18.0376 20.5818 23.1138 18.001 34.6665C17.0973 38.7536 16.3691 42.8961 15.8195 47.0769C14.8784 53.851 15.1493 54.9538 13.9516 63.6884C13.4526 67.4717 13.0773 71.0237 12.4807 76.0085C11.668 79.3168 12.1693 85.0175 11.8842 85C11.3423 85 9.83095 74.7335 9.9878 70.69C10.065 68.6153 10.2651 66.5501 10.5867 64.5111C11.2198 59.7046 11.5818 54.8503 11.6703 49.9826C11.9982 41.2306 12.1693 36.8195 11.2425 32.2334C10.3157 27.6474 10.3585 23.2713 9.50301 18.8778C9.34617 18.0376 8.93267 16.0071 9.73115 15.2719C10.5296 14.5368 11.7273 15.4645 13.2102 16.0246C17.8442 17.7925 20.3394 14.7293 21.751 16.2872Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 12.2556 86.2009)" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2335)">
          <path d="M12.2556 84L11.4327 87.7391L8.39889 85.4037L10.172 88.797L6.34677 88.9581L9.88617 90.4178L7.05946 93L10.7091 91.8431L10.2035 95.6382L12.2556 92.406L14.3077 95.6382L13.8022 91.8431L17.4518 93L14.6251 90.4178L18.1645 88.9581L14.3393 88.797L16.1123 85.4037L13.0785 87.7391L12.2556 84Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2335" x="6.1468" y="83.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.19769 33.415L5.46374 44.8831C6.69196 51.8785 7.07278 58.9965 6.59795 66.083C6.15421 70.1769 6.34788 74.3147 7.17205 78.3493C7.46611 79.7495 9.28644 87.913 11.9189 87.941C13.7253 87.941 15.1535 84.0903 15.6156 82.8581C17.0159 79.0774 16.3437 76.795 16.7638 72.9163C17.1559 69.3736 18.066 67.9594 19.7744 63.1985C21.7047 57.7746 23.2075 52.2079 24.2692 46.5494L25.6694 32.3088" fill="#DEE1E9"/>
      <mask id="mask2335" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
        <path d="M30.8958 13.5214C31.2179 15.4397 32.6181 25.1015 26.359 32.2428C24.3198 34.7768 21.5307 36.6003 18.3915 37.4518C18.0135 37.5358 10.8442 39.2021 6.25132 34.7913C4.20695 32.8309 3.61884 30.5205 2.54064 25.9977C1.57069 21.9109 1.1005 17.7215 1.14039 13.5214L10.7882 3.1035C10.7882 3.1035 15.1569 -1.62937 19.8058 3.1035C24.4546 7.83636 30.8958 13.5214 30.8958 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2335)">
        <path d="M30.8958 13.5214C31.2179 15.4397 32.6181 25.1015 26.359 32.2428C24.3198 34.7768 21.5307 36.6003 18.3915 37.4518C18.0135 37.5358 10.8442 39.2021 6.25132 34.7913C4.20695 32.8309 3.61884 30.5205 2.54064 25.9977C1.57069 21.9109 1.1005 17.7215 1.14039 13.5214L10.7882 3.1035C10.7882 3.1035 15.1569 -1.62937 19.8058 3.1035C24.4546 7.83636 30.8958 13.5214 30.8958 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M14.5633 17C13.5012 17 12.2556 11.7018 12.2556 2.10345L14.1787 0.241381L17.2556 -1C17.2556 8.59833 15.6254 17 14.5633 17Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M21.751 16.2872C23.4335 18.0376 20.5818 23.1138 18.001 34.6665C17.0973 38.7536 16.3691 42.8961 15.8195 47.0769C14.8784 53.851 15.1493 54.9538 13.9516 63.6884C13.4526 67.4717 13.0773 71.0237 12.4807 76.0085C11.668 79.3168 12.1693 85.0175 11.8842 85C11.3423 85 9.83095 74.7335 9.9878 70.69C10.065 68.6153 10.2651 66.5501 10.5867 64.5111C11.2198 59.7046 11.5818 54.8503 11.6703 49.9826C11.9982 41.2306 12.1693 36.8195 11.2425 32.2334C10.3157 27.6474 10.3585 23.2713 9.50301 18.8778C9.34617 18.0376 8.93267 16.0071 9.73115 15.2719C10.5296 14.5368 11.7273 15.4645 13.2102 16.0246C17.8442 17.7925 20.3394 14.7293 21.751 16.2872Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 12.2556 86.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2335)">
        <path d="M12.2556 84L11.4327 87.7391L8.39889 85.4037L10.172 88.797L6.34677 88.9581L9.88617 90.4178L7.05946 93L10.7091 91.8431L10.2035 95.6382L12.2556 92.406L14.3077 95.6382L13.8022 91.8431L17.4518 93L14.6251 90.4178L18.1645 88.9581L14.3393 88.797L16.1123 85.4037L13.0785 87.7391L12.2556 84Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2335" x="6.1468" y="83.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M5.19769 33.2141L5.46374 44.6822C6.69196 51.6776 7.07278 58.7956 6.59795 65.8821C6.15421 69.9759 6.34788 74.1138 7.17205 78.1483C7.46611 79.5486 9.28644 87.7121 11.9189 87.7401C13.7253 87.7401 15.1535 83.8894 15.6156 82.6571C17.0159 78.8765 16.3437 76.594 16.7638 72.7153C17.1559 69.1727 18.066 67.7584 19.7744 62.9976C21.7047 57.5736 23.2075 52.007 24.2692 46.3485L25.6694 32.1079" fill="#DEE1E9"/>
        <mask id="mask2535" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2535)">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M14.5633 16.7991C13.5012 16.7991 12.2556 11.5008 12.2556 1.90252L14.1787 0.0404528L17.2556 -1.20093C17.2556 8.3974 15.6254 16.7991 14.5633 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.751 16.0862C23.4335 17.8367 20.5818 22.9129 18.001 34.4656C17.0973 38.5526 16.3691 42.6952 15.8195 46.876C14.8784 53.6501 15.1493 54.7529 13.9516 63.4874C13.4526 67.2707 13.0773 70.8228 12.4807 75.8076C11.668 79.1159 12.1693 84.8165 11.8842 84.799C11.3423 84.799 9.83095 74.5326 9.9878 70.4891C10.065 68.4143 10.2651 66.3492 10.5867 64.3101C11.2198 59.5037 11.5818 54.6494 11.6703 49.7817C11.9982 41.0296 12.1693 36.6186 11.2425 32.0325C10.3157 27.4464 10.3585 23.0704 9.50301 18.6769C9.34617 17.8367 8.93267 15.8062 9.73115 15.071C10.5296 14.3358 11.7273 15.2635 13.2102 15.8237C17.8442 17.5916 20.3394 14.5284 21.751 16.0862Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 12.2556 86)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2635" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="89">
          <path d="M5.06293 33.9092L5.32898 45.3773C6.5572 52.3727 6.93801 59.4906 6.46318 66.5772C6.01944 70.671 6.21311 74.8089 7.03729 78.8434C7.33134 80.2436 9.15167 88.4071 11.7842 88.4351C13.5905 88.4351 15.0187 84.5844 15.4808 83.3522C16.8811 79.5715 16.209 77.2891 16.629 73.4104C17.0211 69.8677 17.9313 68.4535 19.6396 63.6926C21.5699 58.2687 23.0728 52.702 24.1344 47.0436L25.5347 32.803" fill="#DEE1E9"/>
          <path d="M30.6036 13.5214C30.9256 15.4397 32.3259 25.1015 26.0668 32.2428C24.0275 34.7768 21.2385 36.6003 18.0993 37.4518C17.7212 37.5358 10.5519 39.2021 5.95909 34.7913C3.91471 32.8309 3.3266 30.5205 2.24841 25.9977C1.27846 21.9109 0.808266 17.7215 0.848152 13.5214L10.4959 3.1035C10.4959 3.1035 14.8647 -1.62937 19.5136 3.1035C24.1624 7.83636 30.6036 13.5214 30.6036 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2635)">
          <path d="M5.06293 33.9092L5.32898 45.3773C6.5572 52.3727 6.93801 59.4906 6.46318 66.5772C6.01944 70.671 6.21311 74.8089 7.03729 78.8434C7.33134 80.2436 9.15167 88.4071 11.7842 88.4351C13.5905 88.4351 15.0187 84.5844 15.4808 83.3522C16.8811 79.5715 16.209 77.2891 16.629 73.4104C17.0211 69.8677 17.9313 68.4535 19.6396 63.6926C21.5699 58.2687 23.0728 52.702 24.1344 47.0436L25.5347 32.803" fill="#DEE1E9"/>
          <path d="M30.6036 13.5214C30.9256 15.4397 32.3259 25.1015 26.0668 32.2428C24.0275 34.7768 21.2385 36.6003 18.0993 37.4518C17.7212 37.5358 10.5519 39.2021 5.95909 34.7913C3.91471 32.8309 3.3266 30.5205 2.24841 25.9977C1.27846 21.9109 0.808266 17.7215 0.848152 13.5214L10.4959 3.1035C10.4959 3.1035 14.8647 -1.62937 19.5136 3.1035C24.1624 7.83636 30.6036 13.5214 30.6036 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M19.9893 20.6762H31.9105C29.7484 26.046 29.3914 29.3063 29.6879 35.0446C29.6991 35.2609 29.4497 35.3955 29.2595 35.2689C28.2554 34.601 25.8972 32.7849 25.3256 30.1432C24.6331 26.9432 22.6299 26.1661 22.4173 26.092C22.4044 26.0875 22.3928 26.083 22.3804 26.0773L21.1975 25.5307C21.0529 25.4639 20.9943 25.3014 21.0666 25.1678L21.5955 24.1901C21.6402 24.1074 21.6362 24.0092 21.5847 23.9299L19.7383 21.0858C19.6213 20.9056 19.7618 20.6762 19.9893 20.6762Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.9144 34.155C27.8534 33.3071 26.4454 31.8702 26.043 30.0106C25.2998 26.5761 23.1238 25.6201 22.6909 25.4648L21.9173 25.1073L22.2499 24.4925C22.4065 24.203 22.3922 23.8596 22.2121 23.5821L20.7646 21.3524H30.8672C29.0904 26.0171 28.7144 29.2129 28.9144 34.155ZM22.7076 25.4725C22.7077 25.4726 22.7074 25.4724 22.7076 25.4725V25.4725ZM28.8321 35.8178C29.5609 36.3027 30.4567 35.7463 30.4187 35.0123C30.1259 29.3462 30.4751 26.18 32.5962 20.9121L32.9634 20H19.9893C19.1931 20 18.7013 20.8028 19.1109 21.4338L20.8333 24.0869L20.4122 24.8654C20.1592 25.333 20.3643 25.9017 20.8702 26.1355L22.0532 26.6822C22.0939 26.701 22.1297 26.7145 22.1593 26.7248C22.1959 26.7376 23.9767 27.3577 24.6081 30.2758C25.2376 33.1849 27.801 35.1319 28.8321 35.8178Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask135" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="89">
          <path d="M5.06293 33.9092L5.32898 45.3773C6.5572 52.3727 6.93801 59.4906 6.46318 66.5772C6.01944 70.671 6.21311 74.8089 7.03729 78.8434C7.33134 80.2436 9.15167 88.4071 11.7842 88.4351C13.5905 88.4351 15.0187 84.5844 15.4808 83.3522C16.8811 79.5715 16.209 77.2891 16.629 73.4104C17.0211 69.8677 17.9313 68.4535 19.6396 63.6926C21.5699 58.2687 23.0728 52.702 24.1344 47.0436L25.5347 32.803" fill="#DEE1E9"/>
          <path d="M30.6036 13.5214C30.9256 15.4397 32.3259 25.1015 26.0668 32.2428C24.0275 34.7768 21.2385 36.6003 18.0993 37.4518C17.7212 37.5358 10.5519 39.2021 5.95909 34.7913C3.91471 32.8309 3.3266 30.5205 2.24841 25.9977C1.27846 21.9109 0.808266 17.7215 0.848152 13.5214L10.4959 3.1035C10.4959 3.1035 14.8647 -1.62937 19.5136 3.1035C24.1624 7.83636 30.6036 13.5214 30.6036 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask135)">
          <path d="M5.06293 33.9092L5.32898 45.3773C6.5572 52.3727 6.93801 59.4906 6.46318 66.5772C6.01944 70.671 6.21311 74.8089 7.03729 78.8434C7.33134 80.2436 9.15167 88.4071 11.7842 88.4351C13.5905 88.4351 15.0187 84.5844 15.4808 83.3522C16.8811 79.5715 16.209 77.2891 16.629 73.4104C17.0211 69.8677 17.9313 68.4535 19.6396 63.6926C21.5699 58.2687 23.0728 52.702 24.1344 47.0436L25.5347 32.803" fill="#DEE1E9"/>
          <path d="M30.6036 13.5214C30.9256 15.4397 32.3259 25.1015 26.0668 32.2428C24.0275 34.7768 21.2385 36.6003 18.0993 37.4518C17.7212 37.5358 10.5519 39.2021 5.95909 34.7913C3.91471 32.8309 3.3266 30.5205 2.24841 25.9977C1.27846 21.9109 0.808266 17.7215 0.848152 13.5214L10.4959 3.1035C10.4959 3.1035 14.8647 -1.62937 19.5136 3.1035C24.1624 7.83636 30.6036 13.5214 30.6036 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M22.097 26.5H30.2438C28.7663 30.4705 28.5223 32.8811 28.725 37.1241C28.7326 37.2841 28.5622 37.3836 28.4322 37.29C27.746 36.7961 26.1345 35.4532 25.7438 33.5C25.2706 31.1339 23.9016 30.5593 23.7563 30.5045C23.7475 30.5012 23.7396 30.4978 23.7311 30.4936L22.9227 30.0894C22.8239 30.04 22.7838 29.9199 22.8332 29.8211L23.1947 29.0982C23.2253 29.037 23.2225 28.9645 23.1873 28.9058L21.9255 26.8029C21.8456 26.6696 21.9416 26.5 22.097 26.5Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M28.1964 36.4664C27.4713 35.8394 26.5091 34.777 26.2341 33.4019C25.7262 30.8625 24.2392 30.1555 23.9433 30.0407L23.4147 29.7764L23.642 29.3218C23.749 29.1077 23.7392 28.8538 23.6161 28.6486L22.6269 27H29.5309C28.3167 30.4491 28.0597 32.8121 28.1964 36.4664ZM23.9547 30.0464C23.9548 30.0465 23.9546 30.0464 23.9547 30.0464V30.0464ZM28.1401 37.6958C28.6382 38.0544 29.2503 37.643 29.2244 37.1003C29.0243 32.9106 29.263 30.5695 30.7124 26.6744L30.9634 26H22.0971C21.553 26 21.2169 26.5936 21.4968 27.0601L22.6739 29.0219L22.3861 29.5975C22.2132 29.9433 22.3533 30.3638 22.6991 30.5367L23.5075 30.9409C23.5353 30.9548 23.5598 30.9648 23.58 30.9724C23.605 30.9818 24.822 31.4403 25.2535 33.5981C25.6837 35.749 27.4355 37.1887 28.1401 37.6958Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M5.19769 33.2141L5.46374 44.6822C6.69196 51.6776 7.07278 58.7956 6.59795 65.8821C6.15421 69.9759 6.34788 74.1138 7.17205 78.1483C7.46611 79.5486 9.28644 87.7121 11.9189 87.7401C13.7253 87.7401 15.1535 83.8894 15.6156 82.6571C17.0159 78.8765 16.3437 76.594 16.7638 72.7153C17.1559 69.1727 18.066 67.7584 19.7744 62.9976C21.7047 57.5736 23.2075 52.007 24.2692 46.3485L25.6694 32.1079" fill="#DEE1E9"/>
        <mask id="mask235" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask235)">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M14.5633 16.7991C13.5012 16.7991 12.2556 11.5008 12.2556 1.90252L14.1787 0.0404528L17.2556 -1.20093C17.2556 8.3974 15.6254 16.7991 14.5633 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.751 16.0862C23.4335 17.8367 20.5818 22.9129 18.001 34.4656C17.0973 38.5526 16.3691 42.6952 15.8195 46.876C14.8784 53.6501 15.1493 54.7529 13.9516 63.4874C13.4526 67.2707 13.0773 70.8228 12.4807 75.8076C11.668 79.1159 12.1693 84.8165 11.8842 84.799C11.3423 84.799 9.83095 74.5326 9.9878 70.4891C10.065 68.4143 10.2651 66.3492 10.5867 64.3101C11.2198 59.5037 11.5818 54.6494 11.6703 49.7817C11.9982 41.0296 12.1693 36.6186 11.2425 32.0325C10.3157 27.4464 10.3585 23.0704 9.50301 18.6769C9.34617 17.8367 8.93267 15.8062 9.73115 15.071C10.5296 14.3358 11.7273 15.2635 13.2102 15.8237C17.8442 17.5916 20.3394 14.5284 21.751 16.0862Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M5.19769 33.2141L5.46374 44.6822C6.69196 51.6776 7.07278 58.7956 6.59795 65.8821C6.15421 69.9759 6.34788 74.1138 7.17205 78.1483C7.46611 79.5486 9.28644 87.7121 11.9189 87.7401C13.7253 87.7401 15.1535 83.8894 15.6156 82.6571C17.0159 78.8765 16.3437 76.594 16.7638 72.7153C17.1559 69.1727 18.066 67.7584 19.7744 62.9976C21.7047 57.5736 23.2075 52.007 24.2692 46.3485L25.6694 32.1079" fill="#DEE1E9"/>
        <mask id="mask335" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask335)">
          <path d="M30.8958 13.3205C31.2179 15.2388 32.6181 24.9006 26.359 32.0419C24.3198 34.5759 21.5307 36.3993 18.3915 37.2508C18.0135 37.3349 10.8442 39.0012 6.25132 34.5904C4.20695 32.63 3.61884 30.3196 2.54064 25.7968C1.57069 21.71 1.1005 17.5206 1.14039 13.3205L10.7882 2.90257C10.7882 2.90257 15.1569 -1.8303 19.8058 2.90257C24.4546 7.63543 30.8958 13.3205 30.8958 13.3205Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M14.5633 16.7991C13.5012 16.7991 12.2556 11.5008 12.2556 1.90252L14.1787 0.0404528L17.2556 -1.20093C17.2556 8.3974 15.6254 16.7991 14.5633 16.7991Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M4.94208 33.9092L5.20813 45.3773C6.43635 52.3727 6.81716 59.4906 6.34233 66.5772C5.89859 70.671 6.09226 74.8089 6.91644 78.8434C7.21049 80.2436 9.03082 88.4071 11.6633 88.4351C13.4696 88.4351 14.8979 84.5844 15.36 83.3522C16.7602 79.5715 16.0881 77.2891 16.5082 73.4104C16.9003 69.8677 17.8104 68.4535 19.5187 63.6926C21.4491 58.2687 22.9519 52.702 24.0136 47.0436L25.4138 32.803" fill="#DEE1E9"/>
        <path d="M30.4827 13.5214C30.8048 15.4397 32.2051 25.1015 25.9459 32.2428C23.9067 34.7768 21.1176 36.6003 17.9785 37.4518C17.6004 37.5358 10.4311 39.2021 5.83824 34.7913C3.79386 32.8309 3.20576 30.5205 2.12756 25.9977C1.15761 21.9109 0.687416 17.7215 0.727303 13.5214L10.3751 3.1035C10.3751 3.1035 14.7439 -1.62937 19.3927 3.1035C24.0416 7.83636 30.4827 13.5214 30.4827 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M12 3.49414L10.1333 5.995L8.5 4.4993V7.81334L5.93782 7.24543L7.33333 8.99603L5 10.9967L7.33333 12.9974L5.93782 14.748L8.26667 13.9977L8.5 17.4941L10.7499 15.9971L11.5333 16.9988L13.2501 15.9971L15.5 17.4941L16.2 15.4983L18.0622 14.748L17.6 11.9971L19 10.9967L16.6667 9.4962L18.0622 7.24543L16.2 6.99534L15.5 4.4993L12.4667 6.49517L12 3.49414Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M4.94208 33.9092L5.20813 45.3773C6.43635 52.3727 6.81716 59.4906 6.34233 66.5772C5.89859 70.671 6.09226 74.8089 6.91644 78.8434C7.21049 80.2436 9.03082 88.4071 11.6633 88.4351C13.4696 88.4351 14.8979 84.5844 15.36 83.3522C16.7602 79.5715 16.0881 77.2891 16.5082 73.4104C16.9003 69.8677 17.8104 68.4535 19.5187 63.6926C21.4491 58.2687 22.9519 52.702 24.0136 47.0436L25.4138 32.803" fill="#DEE1E9"/>
        <path d="M30.4827 13.5214C30.8048 15.4397 32.2051 25.1015 25.9459 32.2428C23.9067 34.7768 21.1176 36.6003 17.9785 37.4518C17.6004 37.5358 10.4311 39.2021 5.83824 34.7913C3.79386 32.8309 3.20576 30.5205 2.12756 25.9977C1.15761 21.9109 0.687416 17.7215 0.727303 13.5214L10.3751 3.1035C10.3751 3.1035 14.7439 -1.62937 19.3927 3.1035C24.0416 7.83636 30.4827 13.5214 30.4827 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M11 3.49414L9.4 5.63773L8 4.3557V7.19631L5.80385 6.70953L7 8.21005L5 9.92492L7 11.6398L5.80385 13.1403L7.8 12.4972L8 15.4941L9.92849 14.211L10.6 15.0695L12.0715 14.211L14 15.4941L14.6 13.7834L16.1962 13.1403L15.8 10.7824L17 9.92492L15 8.63876L16.1962 6.70953L14.6 6.49517L14 4.3557L11.4 6.06645L11 3.49414Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M4.94208 33.9092L5.20813 45.3773C6.43635 52.3727 6.81716 59.4906 6.34233 66.5772C5.89859 70.671 6.09226 74.8089 6.91644 78.8434C7.21049 80.2436 9.03082 88.4071 11.6633 88.4351C13.4696 88.4351 14.8979 84.5844 15.36 83.3522C16.7602 79.5715 16.0881 77.2891 16.5082 73.4104C16.9003 69.8677 17.8104 68.4535 19.5187 63.6926C21.4491 58.2687 22.9519 52.702 24.0136 47.0436L25.4138 32.803" fill="#DEE1E9"/>
        <path d="M30.4827 13.5214C30.8048 15.4397 32.2051 25.1015 25.9459 32.2428C23.9067 34.7768 21.1176 36.6003 17.9785 37.4518C17.6004 37.5358 10.4311 39.2021 5.83824 34.7913C3.79386 32.8309 3.20576 30.5205 2.12756 25.9977C1.15761 21.9109 0.687416 17.7215 0.727303 13.5214L10.3751 3.1035C10.3751 3.1035 14.7439 -1.62937 19.3927 3.1035C24.0416 7.83636 30.4827 13.5214 30.4827 13.5214Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M10.5 3.49414L9.56667 4.74457L8.75 3.99672V5.65374L7.46891 5.36978L8.16667 6.24509L7 7.24543L8.16667 8.24577L7.46891 9.12107L8.63333 8.74594L8.75 10.4941L9.87495 9.74562L10.2667 10.2465L11.125 9.74562L12.25 10.4941L12.6 9.4962L13.5311 9.12107L13.3 7.7456L14 7.24543L12.8333 6.49517L13.5311 5.36978L12.6 5.24474L12.25 3.99672L10.7333 4.99466L10.5 3.49414Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M19.3898 75.6565L17.3061 76.9263H13.8223L11.7223 75.6565L13.334 74.11L11.2503 72.5635L13.1386 71.017L10.8433 69.324L12.6665 67.7775L10.3712 66.3775L12.3898 64.9612L10.3712 63.3496L12.0642 61.803L9.83398 60.3868L11.5921 58.9217L9.29677 57.3751L11.4456 55.8286L9.29677 54.217L10.8433 52.8659L8.75956 51.3193L10.5828 49.838L8.49909 48.2914L10.3061 46.5496L9.29677 44.8728V30.6124L11.1851 23.2868H17.3061" fill="#8889BE"/>
        <path d="M19.3898 75.6565L17.3061 76.9263H13.8223L11.7223 75.6565L13.334 74.11L11.2503 72.5635L13.1386 71.017L10.8433 69.324L12.6665 67.7775L10.3712 66.3775L12.3898 64.9612L10.3712 63.3496L12.0642 61.803L9.83398 60.3868L11.5921 58.9217L9.29677 57.3751L11.4456 55.8286L9.29677 54.217L10.8433 52.8659L8.75956 51.3193L10.5828 49.838L8.49909 48.2914L10.3061 46.5496L9.29677 44.8728V30.6124L11.1851 23.2868H17.3061" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M11.9825 75.6565L14.0662 76.9263H17.5336L19.6499 75.6565L18.022 74.11L20.122 72.5635L18.2336 71.017L20.5127 69.324L18.7057 67.7775L20.9848 66.3775L18.9662 64.9612L20.9848 63.3496L19.3081 61.803L21.522 60.3868L19.7802 58.9217L22.0592 57.3751L19.9104 55.8286L22.0592 54.217L20.5127 52.8659L22.5964 51.3193L20.7895 49.838L22.8732 48.2914L21.0499 46.5496L22.0592 44.8728V30.6124L20.1871 23.2868H16.2802" fill="#8889BE"/>
        <path d="M11.9825 75.6565L14.0662 76.9263H17.5336L19.6499 75.6565L18.022 74.11L20.122 72.5635L18.2336 71.017L20.5127 69.324L18.7057 67.7775L20.9848 66.3775L18.9662 64.9612L20.9848 63.3496L19.3081 61.803L21.522 60.3868L19.7802 58.9217L22.0592 57.3751L19.9104 55.8286L22.0592 54.217L20.5127 52.8659L22.5964 51.3193L20.7895 49.838L22.8732 48.2914L21.0499 46.5496L22.0592 44.8728V30.6124L20.1871 23.2868H16.2802" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask535" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="35">
          <path d="M26.3506 31.8954C32.6097 24.7541 31.2095 15.0923 30.8874 13.174C30.8874 13.174 24.4462 7.48895 19.7974 2.75608C15.1485 -1.97678 10.7797 2.75608 10.7797 2.75608L1.13197 13.174C1.09208 17.3741 1.56227 21.5635 2.53222 25.6503C3.4684 29.5773 4.03509 31.8365 5.50639 33.6467H9.40466L10.9047 23.6467C14.615 22.4998 16.694 22.4719 20.4047 23.6467L21.5523 33.6467H24.6999C25.2922 33.1094 25.8445 32.5243 26.3506 31.8954Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask535)">
          <path d="M26.3506 31.8954C32.6097 24.7541 31.2095 15.0923 30.8874 13.174C30.8874 13.174 24.4462 7.48895 19.7974 2.75608C15.1485 -1.97678 10.7797 2.75608 10.7797 2.75608L1.13197 13.174C1.09208 17.3741 1.56227 21.5635 2.53222 25.6503C3.4684 29.5773 4.03509 31.8365 5.50639 33.6467H9.40466L10.9047 23.6467C14.615 22.4998 16.694 22.4719 20.4047 23.6467L21.5523 33.6467H24.6999C25.2922 33.1094 25.8445 32.5243 26.3506 31.8954Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M6.24719 46.6526H26.2472V21.1046C18.4367 19.1784 14.0577 19.1588 6.24719 21.1046V46.6526Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M18.9818 75.948L16.8981 77.2178H13.4144L11.3144 75.948L12.926 74.4015L10.8423 72.855L12.7307 71.3085L10.4353 69.6155L12.2586 68.069L9.96323 66.669L11.9818 65.2527L9.96323 63.6411L11.6562 62.0945L9.42602 60.6783L11.1842 59.2132L8.88881 57.6667L11.0376 56.1201L8.88881 54.5085L10.4353 53.1574L8.3516 51.6109L10.1749 50.1295L8.09114 48.5829L9.89811 46.8411L8.88881 45.1644V30.9039L10.7772 23.5784H16.8981" fill="#8889BE"/>
        <path d="M18.9818 75.948L16.8981 77.2178H13.4144L11.3144 75.948L12.926 74.4015L10.8423 72.855L12.7307 71.3085L10.4353 69.6155L12.2586 68.069L9.96323 66.669L11.9818 65.2527L9.96323 63.6411L11.6562 62.0945L9.42602 60.6783L11.1842 59.2132L8.88881 57.6667L11.0376 56.1201L8.88881 54.5085L10.4353 53.1574L8.3516 51.6109L10.1749 50.1295L8.09114 48.5829L9.89811 46.8411L8.88881 45.1644V30.9039L10.7772 23.5784H16.8981" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M11.5745 75.948L13.6583 77.2178H17.1257L19.242 75.948L17.6141 74.4015L19.7141 72.855L17.8257 71.3085L20.1047 69.6155L18.2978 68.069L20.5768 66.669L18.5582 65.2527L20.5768 63.6411L18.9001 62.0945L21.1141 60.6783L19.3722 59.2132L21.6513 57.6667L19.5024 56.1201L21.6513 54.5085L20.1047 53.1574L22.1885 51.6109L20.3815 50.1295L22.4652 48.5829L20.642 46.8411L21.6513 45.1644V30.9039L19.7792 23.5784H15.8722" fill="#8889BE"/>
        <path d="M11.5745 75.948L13.6583 77.2178H17.1257L19.242 75.948L17.6141 74.4015L19.7141 72.855L17.8257 71.3085L20.1047 69.6155L18.2978 68.069L20.5768 66.669L18.5582 65.2527L20.5768 63.6411L18.9001 62.0945L21.1141 60.6783L19.3722 59.2132L21.6513 57.6667L19.5024 56.1201L21.6513 54.5085L20.1047 53.1574L22.1885 51.6109L20.3815 50.1295L22.4652 48.5829L20.642 46.8411L21.6513 45.1644V30.9039L19.7792 23.5783H15.8722" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask535" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="35">
          <path d="M25.9426 32.1867C32.2018 25.0454 30.8015 15.3836 30.4794 13.4652C30.4794 13.4652 24.0383 7.78021 19.3894 3.04734C14.7406 -1.68552 10.3718 3.04734 10.3718 3.04734L0.724007 13.4652C0.68412 17.6653 1.15431 21.8548 2.12426 25.9415C3.06044 29.8686 3.62713 32.1277 5.09843 33.938H8.9967L10.4967 23.938C14.207 22.791 16.286 22.7632 19.9967 23.938L21.1443 33.938H24.292C24.8843 33.4006 25.4365 32.8155 25.9426 32.1867Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask535)">
          <path d="M25.9426 32.1867C32.2018 25.0454 30.8015 15.3836 30.4794 13.4652C30.4794 13.4652 24.0383 7.78021 19.3894 3.04734C14.7406 -1.68552 10.3718 3.04734 10.3718 3.04734L0.724007 13.4652C0.68412 17.6653 1.15431 21.8548 2.12426 25.9415C3.06044 29.8686 3.62713 32.1277 5.09843 33.938H8.9967L10.4967 23.938C14.207 22.791 16.286 22.7632 19.9967 23.938L21.1443 33.938H24.292C24.8843 33.4006 25.4365 32.8155 25.9426 32.1867Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </g>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M43.2556 0.259766H22.2556V5.25977H43.2556V0.259766Z" fill="#838AA2"/>
        <path d="M21.2556 0.259766H0.255615V5.25977H21.2556V0.259766Z" fill="#838AA2"/>
        <path d="M11.6212 21.178V48.561C12.8494 55.5565 13.2302 62.6744 12.7554 69.761C12.3117 73.8548 12.5053 77.9927 13.3295 82.0272C13.6236 83.4275 15.4439 91.5909 18.0764 91.6189C19.8827 91.6189 21.311 87.7682 21.7731 86.536C23.1733 82.7553 22.5012 80.4729 22.9213 76.5942C23.3133 73.0516 24.2235 71.6373 25.9318 66.8764C27.8622 61.4525 29.365 55.8858 30.4266 50.2274L31.8269 19.0718" fill="#DEE1E9"/>
        <path d="M32.359 35.4264C38.6181 28.2851 37.2179 18.6233 36.8958 16.705C36.8958 16.705 30.4546 11.02 25.8058 6.28709C21.1569 1.55422 16.7882 6.28709 16.7882 6.28709L7.14039 16.705C7.1005 20.9051 7.57069 25.0945 8.54064 29.1813C9.47682 33.1083 10.0435 35.3675 11.5148 37.1777H15.4131L16.9131 27.1777C20.6234 26.0308 22.7024 26.0029 26.4131 27.1777L27.5607 37.1777H30.7083C31.3007 36.6404 31.8529 36.0553 32.359 35.4264Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M4.7851 34.0203L5.05114 45.4884C6.27937 52.4838 6.66018 59.6017 6.18535 66.6882C5.74161 70.7821 5.93528 74.92 6.75945 78.9545C7.05351 80.3547 8.87384 88.5182 11.5063 88.5462C13.3127 88.5462 14.7409 84.6955 15.203 83.4633C16.6033 79.6826 15.9311 77.4002 16.3512 73.5215C16.7433 69.9788 17.6534 68.5646 19.3618 63.8037C21.2921 58.3798 22.7949 52.8131 23.8566 47.1547L25.2568 32.9141" fill="#DEE1E9"/>
        <path d="M30.3258 13.6327C30.6478 15.5511 32.0481 25.2128 25.7889 32.3541C23.7497 34.8882 20.9607 36.7116 17.8215 37.5631C17.4434 37.6471 10.2741 39.3134 5.68125 34.9026C3.63688 32.9423 3.04877 30.6318 1.97058 26.109C1.00063 22.0222 0.530434 17.8328 0.57032 13.6327L10.2181 3.21482C10.2181 3.21482 14.5869 -1.51804 19.2357 3.21482C23.8846 7.94769 30.3258 13.6327 30.3258 13.6327Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M21.2239 31.0658C22.8762 32.4661 20.0757 36.5268 17.5412 45.7685C16.6538 49.038 15.9386 52.3518 15.3989 55.6963C14.4747 61.1153 14.7407 61.9975 13.5645 68.9847C13.0744 72.0112 12.7058 74.8527 12.12 78.8403C11.3218 81.4868 11.8142 86.0471 11.5341 86.0331C11.0021 86.0331 9.51778 77.8204 9.67181 74.5858C9.7476 72.926 9.94415 71.274 10.2599 69.6429C10.8817 65.7979 11.2372 61.9146 11.3241 58.0207C11.6462 51.0195 11.8142 47.4908 10.904 43.8221C9.99387 40.1535 10.0359 36.6528 9.19572 33.1382C9.04169 32.4661 8.63562 30.8418 9.41976 30.2537C10.2039 29.6656 11.3801 30.4077 12.8364 30.8558C17.3872 32.27 19.8377 29.8196 21.2239 31.0658Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M4.7851 34.0203L5.05114 45.4884C6.27937 52.4838 6.66018 59.6017 6.18535 66.6882C5.74161 70.7821 5.93528 74.92 6.75945 78.9545C7.05351 80.3547 8.87384 88.5182 11.5063 88.5462C13.3127 88.5462 14.7409 84.6955 15.203 83.4633C16.6033 79.6826 15.9311 77.4002 16.3512 73.5215C16.7433 69.9788 17.6534 68.5646 19.3618 63.8037C21.2921 58.3798 22.7949 52.8131 23.8566 47.1547L25.2568 32.9141" fill="#DEE1E9"/>
        <path d="M30.3258 13.6327C30.6478 15.5511 32.0481 25.2128 25.7889 32.3541C23.7497 34.8882 20.9607 36.7116 17.8215 37.5631C17.4434 37.6471 10.2741 39.3134 5.68125 34.9026C3.63688 32.9423 3.04877 30.6318 1.97058 26.109C1.00063 22.0222 0.530434 17.8328 0.57032 13.6327L10.2181 3.21482C10.2181 3.21482 14.5869 -1.51804 19.2357 3.21482C23.8846 7.94769 30.3258 13.6327 30.3258 13.6327Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.4" d="M21.2239 31.0658C22.8762 32.4661 20.0757 36.5268 17.5412 45.7685C16.6538 49.038 15.9386 52.3518 15.3989 55.6963C14.4747 61.1153 14.7407 61.9975 13.5645 68.9847C13.0744 72.0112 12.7058 74.8527 12.12 78.8403C11.3218 81.4868 11.8142 86.0471 11.5341 86.0331C11.0021 86.0331 9.51778 77.8204 9.67181 74.5858C9.7476 72.926 9.94415 71.274 10.2599 69.6429C10.8817 65.7979 11.2372 61.9146 11.3241 58.0207C11.6462 51.0195 11.8142 47.4908 10.904 43.8221C9.99387 40.1535 10.0359 36.6528 9.19572 33.1382C9.04169 32.4661 8.63562 30.8418 9.41976 30.2537C10.2039 29.6656 11.3801 30.4077 12.8364 30.8558C17.3872 32.27 19.8377 29.8196 21.2239 31.0658Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M4.78534 34.0203L5.05139 45.4884C6.27961 52.4838 6.66042 59.6017 6.18559 66.6882C5.74186 70.7821 5.93552 74.92 6.7597 78.9545C7.05375 80.3547 8.87409 88.5182 11.5066 88.5462C13.3129 88.5462 14.7412 84.6955 15.2032 83.4633C16.6035 79.6826 15.9314 77.4002 16.3515 73.5215C16.7435 69.9788 17.6537 68.5646 19.362 63.8037C21.2923 58.3798 22.7952 52.8131 23.8568 47.1547L25.2571 32.9141" fill="#DEE1E9"/>
        <path d="M30.326 13.6327C30.6481 15.5511 32.0483 25.2128 25.7892 32.3541C23.75 34.8882 20.9609 36.7116 17.8217 37.5631C17.4436 37.6471 10.2743 39.3134 5.6815 34.9026C3.63712 32.9423 3.04902 30.6318 1.97082 26.109C1.00087 22.0222 0.530678 17.8328 0.570564 13.6327L10.2183 3.21482C10.2183 3.21482 14.5871 -1.51804 19.236 3.21482C23.8848 7.94769 30.326 13.6327 30.326 13.6327Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <mask id="mask535" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="26" width="16" height="33">
          <path d="M8.24549 58.707H16.283L23.6548 27.9743L8.24549 26.9204L8.24549 58.707Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask535)">
          <path opacity="0.2" d="M21.2242 31.0658C22.8765 32.4661 20.076 36.5268 17.5415 45.7685C16.654 49.038 15.9389 52.3518 15.3991 55.6963C14.4749 61.1153 14.741 61.9975 13.5648 68.9847C13.0747 72.0112 12.7061 74.8527 12.1202 78.8403C11.3221 81.4868 11.8144 86.0471 11.5344 86.0331C11.0023 86.0331 9.51802 77.8204 9.67205 74.5858C9.74784 72.926 9.9444 71.274 10.2602 69.6429C10.8819 65.7979 11.2375 61.9146 11.3244 58.0207C11.6464 51.0195 11.8144 47.4908 10.9043 43.8221C9.99411 40.1535 10.0361 36.6528 9.19596 33.1382C9.04194 32.4661 8.63586 30.8418 9.42001 30.2537C10.2041 29.6656 11.3804 30.4077 12.8366 30.8558C17.3875 32.27 19.8379 29.8196 21.2242 31.0658Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5035" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="58" width="10" height="29">
          <path d="M9.6112 84.9401L10.3111 86.9751H11.7109L12.699 85.6751L17.2278 58.7438H8.45837L8.82891 70.6946L9.28183 81.2656L9.6112 84.9401Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5035)">
          <path opacity="0.4" d="M21.2242 31.0658C22.8765 32.4661 20.076 36.5268 17.5415 45.7685C16.654 49.038 15.9389 52.3518 15.3991 55.6963C14.4749 61.1153 14.741 61.9975 13.5648 68.9847C13.0747 72.0112 12.7061 74.8527 12.1202 78.8403C11.3221 81.4868 11.8144 86.0471 11.5344 86.0331C11.0023 86.0331 9.51802 77.8204 9.67205 74.5858C9.74784 72.926 9.9444 71.274 10.2602 69.6429C10.8819 65.7979 11.2375 61.9146 11.3244 58.0207C11.6464 51.0195 11.8144 47.4908 10.9043 43.8221C9.99411 40.1535 10.0361 36.6528 9.19596 33.1382C9.04194 32.4661 8.63586 30.8418 9.42001 30.2537C10.2041 29.6656 11.3804 30.4077 12.8366 30.8558C17.3875 32.27 19.8379 29.8196 21.2242 31.0658Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

<g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M0.920134 13.0728C0.874233 17.2732 1.34451 21.4633 2.32039 25.5491C3.21655 29.3718 3.72065 31.6122 5.1209 33.3905L5.38695 44.8586C6.59273 51.8803 6.94535 59.0221 6.43714 66.1285C5.9934 70.2223 6.18707 74.3602 7.01124 78.3947C7.3053 79.795 9.12563 87.9585 11.7581 87.9865C13.5644 87.9865 14.9927 84.1358 15.4548 82.9035C16.855 79.1228 16.1829 76.8404 16.603 72.9617C16.9951 69.4191 17.9052 68.0048 19.6135 63.2439C21.5629 57.8216 23.0845 52.2549 24.1644 46.5949L25.5646 32.3543L26.0547 31.7942C32.2859 24.6529 30.8856 14.9911 30.5635 13.0728C30.5635 13.0728 24.0944 7.47175 19.4735 2.65487C14.8527 -2.16201 10.4559 2.65487 10.4559 2.65487L0.920134 13.0728Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
        <path d="M5.11786 33.6901L5.38391 45.1582C6.61213 52.1536 6.99294 59.2716 6.51811 66.3581C6.07438 70.4519 6.26804 74.5898 7.09222 78.6243C7.38627 80.0246 9.20661 88.1881 11.8391 88.2161C13.6454 88.2161 15.0737 84.3654 15.5358 83.1332C16.936 79.3525 16.2639 77.07 16.684 73.1913C17.076 69.6487 17.9862 68.2344 19.6945 63.4736C21.6248 58.0496 23.1277 52.483 24.1893 46.8245L25.5896 32.5839" fill="#DEE1E9"/>
        <path d="M30.6585 13.3024C30.9806 15.2208 32.3808 24.8825 26.1217 32.0238C24.0825 34.5578 21.2934 36.3813 18.1542 37.2328C17.7762 37.3168 10.6069 38.9831 6.01402 34.5723C3.96964 32.6119 3.38154 30.3015 2.30334 25.7787C1.33339 21.6919 0.863197 17.5025 0.903084 13.3024L10.5508 2.8845C10.5508 2.8845 14.9196 -1.84836 19.5685 2.8845C24.2173 7.61737 30.6585 13.3024 30.6585 13.3024Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth35",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
