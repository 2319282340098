export default {
    primaryKey: "id",
    model: 'notification',
    order: 'id',
    sort: 'desc',
    showTotalRecords: true,
    actions: [],
    actionscomponent: 'PatientDatatableNotificationActions',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'text',
            title: 'text',
            table: true,
            filter: true
        },
        {
            id: 'sent_at',
            title: 'status',
            table: true,
            sortable: true,
            tdcomponent: 'PatientsDatatableTDNotificationStatus',
        },
    ]

}
