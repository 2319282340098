<template>
  <span>
    <EGISZValidatedFlag :egisz-record="object" class="d-inline-block"/>
    <router-link class="ml-2" :to="{ name: 'PatientVisitPage', params: { visitid: visit.id, id: visit.client_id, page: 'description' } }">
      {{ visit.readableStart }}
    </router-link>
  </span>
</template>

<script setup>
import { computed } from "vue"
import EGISZValidatedFlag from "@/components/parts/egisz/EGISZValidatedFlag.vue"

const props = defineProps({
  object: {
    type: Object
  }
})

const visit = computed(() => {
  return props.object?.record || null
})
</script>
