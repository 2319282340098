<template>
  <header class="dentist-container header" id="header">
    <b-row no-gutters class="align-items-center">
      <b-col cols="4" class="d-block d-md-none">
        <MenuToggler view="header"/>
        <router-link to="/calendar">
          <img src="/images/general/logo_short.png" class="header__logo"/>
        </router-link>
      </b-col>
      <b-col lg cols="8" class="text-right">
        <div class="d-flex align-items-center justify-content-end">
          <HeaderUser class="header__item ml-md-4 ml-0"/>
        </div>
      </b-col>
    </b-row>
  </header>
</template>

<script>

import HeaderUser from "../parts/header/HeaderUser"
import MenuToggler from "@/components/parts/header/MenuToggler"
import {mapState} from "vuex"

export default {
  name: "SuperAdminHeader",
  components: {
    MenuToggler,
    HeaderUser,
  },
  data() {
    return {
      showNotifications: false
    }
  },
  computed: {
    ...mapState({
      user:  state => state.auth.user,
      rights: state => state.auth.rights,
    }),
    isPatient() {
      return this.$store.getters.isPatient
    }
  },
  methods: {
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
