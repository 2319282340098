<template>
  <b-modal id="template-options-modal"
           size="lg"
           @hide="selectedOptions = []; appliedTeeth = ['general']"
           :title="$t('treatment_template')+':'"
           centered>
    <div v-if="template">
      <div class="mb-4" v-if="template.diagnoses && template.diagnoses.length">
        {{ $t('diagnoses') }}:
        <span class="d-inline-block mr-2 tag-code" v-for="d in template.diagnoses" :key="`di-i-${d.id}`">{{ d.title }}</span>
      </div>
      <b-row class="row-narrow align-items-center mb-4">
        <b-col cols="auto">
          <ToothSvg/>
        </b-col>
        <b-col cols="auto">
          <div class="font-weight-bold">
            {{ $t('apply_to_teeth') }}:
          </div>
        </b-col>
        <b-col>
          <div class="pl-2 position-relative" style="top: 1px">
            <div v-for="(t, tind) in teeth"
                 :key="`tem-t-${t}`"
                 :class="{ 'ml-3': tind > 0 }"
                 class="d-inline-block">
              <input type="checkbox"
                     :id="`st-${t}`"
                     :value="t"
                     v-model="appliedTeeth"/>
              <label class="ml-1 mb-0" :for="`st-${t}`">{{ filterTeeth(t) }}</label>
            </div>
            <div class="ml-3 d-none">
              <input type="checkbox" id="general" value="general" v-model="appliedTeeth"/>
              <label class="ml-1 mb-0" for="general">{{ $t('general') }}</label>
            </div>
          </div>
        </b-col>
      </b-row>
      <div class="font-weight-bold">{{ template.title }}</div>
      <div class="mt-4 mb-3">{{ $t('choose_fields_for_autocomplete') }}</div>
      <div v-for="(tg, tind) in templateGroups" :key="`template-g-${tind}`">
        <div class="font-weight-bold mt-2 option-label mb-3" v-if="optionsForGroup(tg) > 0">{{ $t(tg) }}</div>
        <template v-for="(o, oind) in template.options">
          <div :key="`tem-g-${tind}-o-${oind}`"
               class="option-row"
               v-if="o.treatment_field === tg && o.text && o.text.trim()">
            <CheckboxGroup :label="o.text"
                           class="sm opaque"
                           :checkboxvalue="o.id"
                           :fid="`option-${tg}-${oind}`"
                           v-model="selectedOptions" />
          </div>
        </template>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button @click="save"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import {mapState} from "vuex"
import {templateGroups} from "@/dictionaries/dictionary"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import {PatientService} from "@/services/api.service"
import ToothSvg from '@/assets/svg-vue/patients/tooth.svg'
import { filterTeeth } from "@/extensions/filters/filters"

export default {
  name: "TemplateOptionsModal",
  components: {
    CheckboxGroup,
    ToothSvg
  },
  props: {
    object: {},
  },
  computed: {
    ...mapState({
      template:  state => state.patient.selectedTemplate,
      patient:  state => state.patient.patient,
      teeth:  state => state.teeth.recordTeeth,
    })
  },
  data() {
    return {
      templateGroups: templateGroups,
      selectedOptions: [],
      appliedTeeth: ['general']
    }
  },
  methods: {
    filterTeeth,
    async save() {
      await this.$parent.save(false, false, false)
      let res = await PatientService.attachTemplate(this.patient.id, this.object.id, this.template.id, this.selectedOptions, this.appliedTeeth, this.teeth)
      this.$store.commit('setVisit', res.data)
      this.$bvModal.hide('template-options-modal')
      await this.$nextTick()
      this.$emit('reload')
    },
    optionsForGroup(group) {
      if(!this.template) return 0
      return this.template.options.filter(el => el.text && el.treatment_field === group).length
    }
  }
}
</script>
