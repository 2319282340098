<template>
  <div class="form-group">
    <label class="form-label">
      {{ $t('text') }}
      <span> *</span>
    </label>
    <jodit-editor v-model="object.text26"
                  :buttons="joditConfig.buttons"
                  :config="joditConfig.config"/>
    <ValidationError validation-id="text26"/>
    <TagsList type="agreement"
              class="mt-4">
      {{ $t('tags_for_document') }}
    </TagsList>
  </div>
</template>

<script>

import { JoditEditor } from 'jodit-vue'
import ValidationError from "@/components/form/ValidationError"
import { mapState } from "vuex"
import TagsList from "@/components/parts/crm/TagsList.vue"
import { joditConfig } from "@/dictionaries/joditConfig"

export default {
  name: "AgreementDatatableFormText",
  components: {
    TagsList,
    JoditEditor,
    ValidationError,
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
  },
  computed: {
    ...mapState({
      options: state => state.datatable.eloquentOptions
    }),
    joditConfig() {
      return joditConfig
    },
  },
  created() {
    JoditEditor.compatConfig = { MODE: 3 }
  },
}
</script>
