<template>
  <div class="">
    <b-tabs nav-class="sub-menu"
            content-class="pt-4"
            class="w-100">
      <b-tab active :title="$t('user_data')">
        <b-row>
          <template v-for="(f, find) in options.fields">
            <div :key="'form-field-'+find"
                 v-if="f.form && !f.form.hidden && !(edit && f.unavailableonedit) && !(f.form.disabledForSelf && isSelf)"
                 :class="f.form && f.form.classes ? f.form.classes : 'col-12'">
              <VariableInput :field="f"
                             :object="object"
                             :edit="edit"
                             :selectModels="selectModels"/>
              <ValidationError :validationId="f.validation_id || f.id"/>
            </div>
          </template>
          <div class="col-12" v-if="options.model === 'doctor'">
            <ServiceMultiSelect :label="$t('chairs')"
                                :dblclick="false"
                                model="chair"
                                title="chair_name"
                                :dbsearch="false"
                                :prepopulatedOptions="selectModels.chairs"
                                v-model="object.chairs"/>
          </div>
          <div class="col-12" v-if="options.model === 'doctor' && loaded && rights.includes('branches')">
            <ServiceMultiSelect :label="$t('branch')"
                                :dblclick="false"
                                model="branch"
                                title="title"
                                :dbsearch="false"
                                :prepopulatedOptions="selectModels.branch_id"
                                v-model="object.branches"/>
          </div>
          <div class="col-12">
            <FileGroup :label="$t('photo')"
                       :fid="`file-doctor-photo`"
                       :deletefile="true"
                       filetype="image"
                       v-model="object.image"/>
          </div>
        </b-row>
      </b-tab>
      <b-tab :title="$t('login_and_password')">
        <b-row>
          <b-col md="4">
            <InputGroup type="text"
                        :label="$t('login')"
                        :key="`input-username-${object.lname}-${userNameKey}`"
                        ref="username"
                        :readonly="loginReadonly && !loginEditing"
                        :required="true"
                        @input="loginKeyup"
                        v-model="object.username">
              <a v-if="!loginEditing" @click="loginEditing = true" class="show-password"><EditSvg/></a>
            </InputGroup>
            <ValidationError validationId="username"/>
          </b-col>
          <b-col md="4">
            <InputGroup type="password"
                        autocomplete="new-password"
                        :label="$t('password')"
                        :required="true"
                        v-model="object.password"/>
            <ValidationError validationId="password"/>
          </b-col>
          <b-col md="4">
            <InputGroup type="password"
                        autocomplete="new-password"
                        :label="$t('confirm_password')"
                        :required="true"
                        v-model="object.password_confirmation"/>
            <ValidationError validationId="confirm_password"/>
            <div class="text-danger small"
                 v-if="object.password && object.password != object.password_confirmation">
              {{ $t('password_does_not_match_confirmation') }}
            </div>
          </b-col>
        </b-row>
        <div v-if="object.password && object.email && rights.includes('password_send')">
          <CheckboxGroup :label="$t('send_mail')"
                         class="mt-4"
                         :fid="`pass-check`"
                         v-model="object.sendPassword"/>
        </div>
      </b-tab>
      <b-tab :title="$t('functionality')"
             v-if="![2, 6].includes(object.role_id) && !isSelf">
        <SelectGroup :options="customRoles"
                     v-if="rights.includes('staff_roles') && customRoles && customRoles.length"
                     title="title"
                     :showlabel="true"
                     variant="white"
                     :label="$t('role')"
                     :info-hint="$t('user_will_assigned_rights_selected_for_that_role')"
                     v-model="object.custom_role_id"/>
        <div v-if="object.userFunctions">
          <b-row v-for="(arr, cat) in allFunctions"
                 :key="`cat-${cat}`"
                 class="mb-4">
            <b-col cols="12">
              <div class="font-weight-bold mb-4">{{ $t(cat) }}</div>
            </b-col>
            <b-col v-for="f in arr"
                   md="4" cols="12"
                   :key="`all_function_${f.id}`">
              <CheckboxGroup :label="f.title"
                             v-if="!object.custom_role_id"
                             :checkboxvalue="f.id"
                             :fid="`checkbox-f-${f.id}`"
                             v-model="object.userFunctions">
                <a v-if="f.note"
                   :title="f.note"
                   class="info ml-2"
                   v-b-tooltip.hover>
                  <InfoSvg class="info-svg"/>
                </a>
              </CheckboxGroup>
              <CheckboxGroup :label="f.title"
                             v-if="!!object.custom_role_id"
                             :readonly="true"
                             :checkboxvalue="f.id"
                             :fid="`checkbox-role-f-${f.id}`"
                             v-model="roleFunctions">
              </CheckboxGroup>
            </b-col>
          </b-row>
        </div>
      </b-tab>
      <b-tab :title="$t('egisz')"
             v-if="rights.includes('egisz_view') && options.model === 'doctor'">
        <b-row>
          <b-col md="6">
            <ServiceMultiSelect :label="$t('doctor_position')"
                                :onlyId="true"
                                :dblclick="false"
                                model="egiszDictionary"
                                title="title"
                                :multiple="false"
                                :dbsearch="false"
                                :prepopulatedOptions="selectModels.egisz_position"
                                v-model="object.egisz_position"/>
          </b-col>
          <b-col md="6">
            <ServiceMultiSelect :label="$t('doctor_specialty')"
                                :onlyId="true"
                                :dblclick="false"
                                model="egiszDictionary"
                                title="title"
                                :multiple="false"
                                :dbsearch="false"
                                :prepopulatedOptions="selectModels.egisz_specialty"
                                v-model="object.egisz_specialty"/>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>

import FileGroup from "@/components/form/FileGroup"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"
import VariableInput from "@/components/form/VariableInput"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import InfoSvg from '@/assets/svg-vue/general/question.svg'
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import { mapState } from "vuex"
import { transliterate } from "@/services/translit.service"
import SelectGroup from "@/components/form/SelectGroup.vue"

export default {
  name: "StaffDatatableFormDoctorForm",
  components: {
    SelectGroup,
    CheckboxGroup,
    InputGroup,
    ValidationError,
    VariableInput,
    ServiceMultiSelect,
    FileGroup,
    InfoSvg,
    EditSvg
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      loaded: false,
      userNameKey: 1,
      loginEditing: false
    }
  },
  computed: {
    ...mapState({
      user: state => state.auth.user,
      clinic: state => state.auth.clinic,
      superadmin: state => state.auth.superadmin,
      rights: state => state.auth.rights,
      model: state => state.datatable.viewModel,
    }),
    allFunctions() {
      const functions = this.selectModels.functions.all_functions
      const order = [
        'general',
        'calendar',
        'treatment',
        'staff',
        'communications',
        'finance',
        'reports',
        'egisz',
        'additional_modules'
      ]
      return Object.keys(functions).sort(function(a,b) {
        return order.indexOf(a) - order.indexOf(b)
      }).reduce((obj, key) => {
            obj[key] = functions[key]
            return obj
          }, {}
      )
    },
    doctorFunctions() {
      return this.selectModels.functions?.doctor_functions
    },
    customRoles() {
      return this.selectModels.functions.custom_roles
    },
    edit() {
      return !!this.object[this.options.primaryKey]
    },
    lname() {
      return this.object.lname
    },
    isSelf() {
      return Number(this.object.id) === Number(this.user.id)
    },
    roleFunctions() {
      if(!this.object.custom_role_id) return []
      const role = this.customRoles.find(obj => Number(obj.id) === Number(this.object.custom_role_id))
      if(!role) return []
      return role.functions.map(obj => obj.id)
    },
    loginReadonly() {
      if(this.superadmin) return false
      return !!this.object.id
      // return !!this.object.id
    }
  },
  mounted() {
    this.$set(this.object, 'userFunctions', this.copyObject(this.doctorFunctions))
    this.$set(this.object, 'image', this.object.photo)
    if(!this.object.id && ['superadministrator', 'administrator', 'doctor', 'curator'].includes(this.model)) {
      let pass = Math.random().toString(36).substr(2, 8)
      this.$set(this.object, 'password', pass)
      this.$set(this.object, 'password_confirmation', pass)
    }
    if(!this.object.branches || !this.object.branches.length) {
      this.$set(this.object, 'branches', this.selectModels.branch_id.filter(b => b.id == this.object.branch_id))
    }
    this.loaded = true
  },
  methods: {
    async loginKeyup() {
      await this.$nextTick()
      const clinicId = this.clinic.id.toString()
      if(!this.object.username.startsWith(clinicId)) {
        if(this.object.username.length < clinicId.length) {
          this.object.username = `${clinicId}`
          this.userNameKey++
          await this.$nextTick()
          this.$refs.username.focus()
        } else {
          this.object.username = `${clinicId}${this.object.username}`
        }
      }
    }
  },
  watch: {
    lname(val) {
      if(!this.object.id && ['superadministrator', 'administrator', 'doctor', 'curator'].includes(this.model)) {
        this.$set(this.object, 'username', this.clinic.id.toString() + transliterate(val))
      }
    }
  }
}
</script>

<style scoped>
.info-svg {
  cursor: help;
}
</style>
