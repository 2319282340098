<template>
  <b-modal id="patients-bulk-edit-modal"
           size="sm"
           @show="onShow"
           hide-footer
           :title="$t('change')"
           centered>
    <div>

      <div v-if="initiated">
        <SelectGroup :label="$t('source')"
                     :showlabel="true"
                     title="title"
                     variant="white"
                     :options="options.source"
                     v-model="source"/>

        <SelectGroup :label="$t('curator')"
                     :showlabel="true"
                     title="full_name"
                     variant="white"
                     :options="options.curator_id"
                     v-model="curator"/>

        <SelectGroup :label="$t('condition')"
                     :showlabel="true"
                     title="title"
                     variant="white"
                     :options="options.patient_condition_id"
                     v-model="condition"/>

        <ServiceMultiSelect :label="$t('client_tag')"
                            :dblclick="false"
                            model="source"
                            title="title"
                            :dbsearch="false"
                            :multiple="true"
                            :prepopulated-options="options.tag_id"
                            v-model="tags"/>
      </div>

      <div class="text-right mt-5">
        <button class="btn btn-themed btn-themed-squared w-100"
                :disabled="loading"
                @click="save">
          {{ $t('save') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import { computed, defineEmits, ref } from "vue"
import { useStore } from "vuex"
// import InputGroup from "@/components/form/InputGroup.vue"
import { ClinicService, EloquentService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"

const props = defineProps({
  selected: {
    type: Array,
    default: () => []
  }
})

const store = useStore()
const emit = defineEmits(['finished'])

const initiated = ref(false)
const source = ref(null)
const curator = ref(null)
const condition = ref(null)
const tags = ref([])
const options = ref({})

const loading = computed(() => store.state.dom.loading)

const onShow = async () => {
  source.value = null
  curator.value = null
  condition.value = null
  tags.value = []
  const res = await EloquentService.prepare('patient')
  options.value = res.data.options
  initiated.value = true
}

const save = () => {
  ClinicService.bulkPatientsChange({
    source_id: source.value || null,
    curator_id: curator.value || null,
    condition_id: condition.value || null,
    tag_ids: tags.value.map(x => x.id),
    patients: props.selected
  }).then(() => {
    emit('finished')
  }).catch((e) => {
    console.log(e)
  })
}
</script>
