<template>
  <div>
    <div v-if="timeInVisibleRange"
         class="time-tracker"
         :style="{ top: position }"></div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment"

export default {
  name: "DTimeTracker",
  data: function () {
    return {
      timer: null,
      timeStart: null,
      timeEnd: null,
      currentTime: null,
      maxSecondsInDisplayRange: 0,
    }
  },
  computed: {
    ...mapState({
      moment: state => state.calendar.moment,
      timeHeight: state => state.calendar.timeHeight,
      timeFromHour: state => state.calendar.timeFromHour,
      timeToHour: state => state.calendar.timeToHour,
      clinic: state => state.auth.clinic,
    }),
    ...mapGetters({
      timeStep: 'employeeTimeStep',
    }),
    timeInVisibleRange() {
      return this.currentTime ? this.currentTime.isBetween(this.timeStart, this.timeEnd) : false
    },
    position() {
      return `${this.top}%`
    },
    top() {
      return this.minutesSkipped * 100 / this.maxSecondsInDisplayRange
    },
    minutesSkipped() {
      return this.timeInVisibleRange ? this.currentTime.diff(this.timeStart, "seconds") : 0;
    }
  },
  created() {
    const now = this.moment()
    this.timeStart = this.getMoment(now, this.timeFromHour)
    const timeEnd = (this.clinic?.time_end || this.timeToHour+':00:00')
    this.timeEnd = this.moment(`${this.getDay(now)} ${timeEnd}`)
    this.maxSecondsInDisplayRange = moment.duration(this.timeEnd.diff(this.timeStart)).asSeconds()
    this.currentTime = now
    this.timer = setInterval(() => this.updateCurrentTime(), 60000)
  },
  methods: {
    getMoment(momentNow, hourInt) {
      return this.moment(`${this.getDay(momentNow)} ${this.toTime(hourInt)}:00:00`)
    },
    getDay(moment) {
      return moment.format('YYYY-MM-DD')
    },
    updateCurrentTime() {
      this.currentTime = this.moment()
    },
    toTime(value) {
      if (value < 10) return `0${value}`
      return value
    }
  },
  unmounted() {
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
.time-tracker {
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #c00;
  z-index: 3;
}
.time-tracker::before {
  content: '';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  left:-4px;
  top:-3px;
  background-color: #c00;
  border-radius: 50%;
}
</style>
