<template>
  <div>
    <div class="form-label mb-3">{{ $t('rights') }}</div>

    <b-row v-for="(arr, cat) in allFunctions"
           :key="`cat-${cat}`"
           class="tickboxes-h-auto">
      <b-col cols="12">
        <div class="font-weight-bold mb-4">{{ $t(cat) }}</div>
      </b-col>
      <b-col v-for="f in arr"
             md="6"
             cols="12"
             :key="`all_function_${f.id}`">
        <CheckboxGroup :label="f.title"
                       :checkboxvalue="f.id"
                       :fid="`checkbox-f-${f.id}`"
                       v-model="object.functionIds">
          <a v-if="f.note"
             :title="f.note"
             class="info ml-2"
             v-b-tooltip.hover>
            <InfoSvg class="info-svg"/>
          </a>
        </CheckboxGroup>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import InfoSvg from "@/assets/svg-vue/general/question.svg.vue"

export default {
  name: "StaffDatatableFormCustomRole",
  components: {
    InfoSvg,
    CheckboxGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  computed: {
    allFunctions() {
      const functions = this.selectOptions.all_functions
      const order = [
        'general',
        'calendar',
        'treatment',
        'staff',
        'communications',
        'finance',
        'reports',
        'egisz',
        'additional_modules'
      ]
      return Object.keys(functions).sort(function(a,b) {
        return order.indexOf(a) - order.indexOf(b)
      }).reduce((obj, key) => {
            obj[key] = functions[key]
            return obj
          }, {}
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.tickboxes-h-auto {
  ::v-deep label {
    height: auto;
  }
}
</style>
