<template>
  <b-modal id="accounting-salary-modal"
           :title="$t('payment')"
           @hide="onHide"
           @show="onShow"
           centered>
    <div v-if="salaryRow">
      <InputGroup :label="$t('employee')"
                  :readonly="true"
                  v-model="salaryRow.name"/>
      <div class="mb-4 mt-4">
        <div class="table-row"
             v-for="(t, tind) in table_rows_start"
             :key="`table-row-${tind}`">
          <b-row>
            <b-col class="text-muted">{{ $t(t.title) }}:</b-col>
            <b-col>{{ formatCurrency(salaryRow[t.id]) }}<span v-if="t.id === 'hourly_rate' && salaryRow.hours_worked > 0"> ({{ salaryRow.hours_worked }} {{ $t('of_hours') }})</span></b-col>
          </b-row>
        </div>
        <div class="table-row">
          <b-row class="align-items-center">
            <b-col cols="6" class="text-muted">{{ $t('deduction') }}:</b-col>
            <b-col>
              <InputGroup class="mb-0 sm" type="number" v-model="adjustmentRemove" v-if="adjustmentRemoveEditing"/>
              <template v-else>{{ formatCurrency(salaryRow.adjustments_detail.remove) }}</template>
            </b-col>
            <b-col cols="3">
              <div class="d-flex w-100">
                <a v-if="!adjustmentRemoveEditing" class="pointer"
                   @click="adjustmentRemoveEditing = true">
                  <EditSvg/>
                </a>
                <a v-if="adjustmentRemoveEditing"
                   class="btn-themed btn-themed-squared btn-themed-outline btn-themed-default h-100 d-block px-2 w-100 text-center mr-2"
                   @click="adjustmentRemoveEditing = false">
                  <CloseSvg class="svg-light svg-size-sm"/>
                </a>
                <a v-if="adjustmentRemoveEditing"
                   class="btn-themed btn-themed-squared btn-themed-outline h-100 d-block px-2 w-100 text-center"
                   @click="saveRemove">
                  <TickSvg class="svg-primary"/>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="table-row">
          <b-row class="align-items-center">
            <b-col cols="6" class="text-muted">{{ $t('accrual') }}:</b-col>
            <b-col>
              <InputGroup class="mb-0 sm"
                          type="number"
                          v-model="adjustmentAdd"
                          v-if="adjustmentAddEditing"/>
              <template v-else>{{ formatCurrency(salaryRow.adjustments_detail.add) }}</template>
            </b-col>
            <b-col cols="3">
              <div class="d-flex w-100">
                <a v-if="!adjustmentAddEditing"
                   class="pointer"
                   @click="adjustmentAddEditing = true">
                  <EditSvg/>
                </a>
                <a v-if="adjustmentAddEditing"
                   class="btn-themed btn-themed-squared btn-themed-outline btn-themed-default h-100 d-block px-2 w-100 text-center mr-2"
                   @click="adjustmentAddEditing = false">
                  <CloseSvg class="svg-light svg-size-sm"/>
                </a>
                <a v-if="adjustmentAddEditing"
                   class="btn-themed btn-themed-squared btn-themed-outline h-100 d-block px-2 w-100 text-center"
                   @click="saveAdd">
                  <TickSvg class="svg-primary"/>
                </a>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="table-row"
             v-for="(t, tind) in table_rows_end"
             :key="`table-row-${tind}`">
          <b-row>
            <b-col class="text-muted">{{ $t(t.title) }}:</b-col>
            <b-col>{{ formatCurrency(salaryRow[t.id]) }}</b-col>
          </b-row>
        </div>
      </div>
      <InputGroup type="number"
                  :label="$t('payment_sum')"
                  :required="true"
                  v-model="amount"/>
      <ValidationError validationId="paid"/>

      <SelectGroup :options="finalPayMethods"
                   :showlabel="true"
                   :required="true"
                   :nullOption="false"
                   :label="$t('payment_type')"
                   variant="white"
                   v-model="forma"/>
      <ValidationError validationId="forma"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons no-print">
        <b-button class="cancel mr-md-3 mr-2 d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('cancel') }}
        </b-button>
        <button :disabled="loading"
                @click="save"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('pay_out') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import {AccountingService, EloquentService} from "@/services/api.service"
import { mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup"
import ValidationError from "@/components/form/ValidationError"
import SelectGroup from "@/components/form/SelectGroup"
import TickSvg from '@/assets/svg-vue/form/tick.svg'
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import CloseSvg from '@/assets/svg-vue/general/close.svg'
import { formatCurrency } from "@/extensions/filters/filters"

export default {
  name: "AccountingSalaryModal",
  components: {
    SelectGroup,
    InputGroup,
    ValidationError,
    TickSvg,
    EditSvg,
    CloseSvg
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
      salaryRow: state => state.accounting.salaryRow,
      accountingmonth: state => state.accounting.accountingmonth,
      pay_methods: state => state.auth.pay_methods,
    }),
    finalPayMethods() {
      return this.pay_methods.filter(x => x.id != 100)
    }
  },
  data() {
    return {
      amount: 0,
      forma: 1,
      adjustmentRemove: 0,
      adjustmentAdd: 0,
      adjustmentRemoveEditing: false,
      adjustmentAddEditing: false,
      table_rows_start: [
        { id: 'fixed', title: 'rate' },
        { id: 'daily_rate', title: 'rate_per_day' },
        { id: 'hourly_rate', title: 'rate_per_hour' },
        { id: 'percent', title: 'percent' },
      ],
      table_rows_end: [
        { id: 'paid', title: 'paid' },
        { id: 'left_to_pay', title: 'left_to_pay' },
      ],
    }
  },
  methods: {
    formatCurrency,
    save() {
      this.$store.commit('cleanValidationErrors')
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        forma: this.forma,
        paid: this.amount,
        bookkeeping_id: this.accountingmonth.id,
        person_id: this.salaryRow.user_id
      })
      EloquentService.create('salary_expense', formData).then(() => {
        this.$bvModal.hide('accounting-salary-modal')
        this.$store.commit('incrementDataTableCounter')
      })
    },
    onHide() {
      this.amount = 0
      this.adjustmentAdd = 0
      this.adjustmentRemove = 0
      this.adjustmentRemoveEditing = false
      this.adjustmentAddEditing = false
      this.$store.commit('cleanValidationErrors')
    },
    onShow() {
      this.adjustmentAdd = this.salaryRow.adjustments_detail.add
      this.adjustmentRemove = this.salaryRow.adjustments_detail.remove
    },
    async saveAdd() {
      await this.changeAdjustment('add')
    },
    async saveRemove() {
      await this.changeAdjustment('remove')
    },
    async changeAdjustment(type) {
      await AccountingService.salaryAdjustment(this.accountingmonth.id, {
        amount: type === 'remove' ? this.adjustmentRemove : this.adjustmentAdd,
        adjustment_type: type,
        user_id: this.salaryRow.user_id
      })
      this.adjustmentRemoveEditing = false
      this.adjustmentAddEditing = false
      this.$emit('selectRow', this.salaryRow)
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">
.table-row {
  padding: 7px 0;
  border-bottom: 1px solid $calendar-border-color;
  &:last-of-type {
    border-bottom: none;
  }
  .btn-themed {
    line-height: 30px;
  }
}
</style>
