<template>
  <div class="selected-bottom-panel"
       :class="{ collapsed: collapsed }"
       v-if="selected.length > 0 && group">
    <b-row class="align-items-center">
      <b-col cols="auto">
        <button class="btn-themed btn-themed-squared"
                @click="$emit('actionWithSelected')">
          {{ view === 'add' ? $t('add') : $t('delete') }}
          <span class="opacity-50">{{ selected.length }}</span>
        </button>
      </b-col>
      <b-col cols="auto">
        <a class="text-primary font-weight-bold pointer d-inline-block"
           @click="$emit('cancel')">
          {{ $t('cancel') }}
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapState} from "vuex"

export default {
  name: "SelectedBottomPanel",
  props: {
    selected: {},
    group: Object,
    view: String
  },
  computed: {
    ...mapState({
      // clinic: state => state.auth.clinic,
      // branch: state => state.auth.branch,
      collapsed: state => state.dom.sidebarCollapsed,
    }),
  },
}
</script>

<style scoped>

</style>
