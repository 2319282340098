export default {
    primaryKey: "id",
    model: 'client_source',
    modaltitle: 'source',
    order: 'id',
    sort: 'desc',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    deletemessage: 'are_you_sure_you_want_to_delete_source',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table_show: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
                component: "SettingsDatatableFormClientSourceTitle",
            },
            // tdcomponent: 'SettingsDatatableTDTagTitle',
        },
        {
            id: 'custom_data_sort_order',
            title: 'sort_order',
            table: true,
            sortable: true,
            form: {
                type: "number",
                note: "higher_number_higher_priority"
            },
            format_number: true
        },
        {
            id: 'custom_data_active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]
}
