<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M8.819 0.614625C8.705 0.520625 8.554 0.481625 8.408 0.508625L0.408 2.00862C0.171 2.05262 0 2.25862 0 2.49962V13.4996C0 13.7396 0.171 13.9466 0.408 13.9906L8.408 15.4906C8.438 15.4966 8.47 15.4996 8.5 15.4996C8.615 15.4996 8.729 15.4596 8.819 15.3846C8.934 15.2896 9 15.1476 9 14.9996V0.999625C9 0.850625 8.934 0.709625 8.819 0.614625ZM8 14.3966L1 13.0846V2.91462L8 1.60262V14.3966Z"/>
  <path
      d="M15.5 2.49976H8.5C8.224 2.49976 8 2.72376 8 2.99976C8 3.27576 8.224 3.49976 8.5 3.49976H15V12.4998H8.5C8.224 12.4998 8 12.7238 8 12.9998C8 13.2758 8.224 13.4998 8.5 13.4998H15.5C15.776 13.4998 16 13.2758 16 12.9998V2.99976C16 2.72376 15.776 2.49976 15.5 2.49976Z"/>
  <path
      d="M10.5 4.49976H8.5C8.224 4.49976 8 4.72376 8 4.99976C8 5.27576 8.224 5.49976 8.5 5.49976H10.5C10.776 5.49976 11 5.27576 11 4.99976C11 4.72376 10.776 4.49976 10.5 4.49976Z"/>
  <path
      d="M10.5 6.49976H8.5C8.224 6.49976 8 6.72376 8 6.99976C8 7.27576 8.224 7.49976 8.5 7.49976H10.5C10.776 7.49976 11 7.27576 11 6.99976C11 6.72376 10.776 6.49976 10.5 6.49976Z"/>
  <path
      d="M10.5 8.49963H8.5C8.224 8.49963 8 8.72363 8 8.99963C8 9.27563 8.224 9.49963 8.5 9.49963H10.5C10.776 9.49963 11 9.27563 11 8.99963C11 8.72363 10.776 8.49963 10.5 8.49963Z"/>
  <path
      d="M10.5 10.4996H8.5C8.224 10.4996 8 10.7236 8 10.9996C8 11.2756 8.224 11.4996 8.5 11.4996H10.5C10.776 11.4996 11 11.2756 11 10.9996C11 10.7236 10.776 10.4996 10.5 10.4996Z"/>
  <path
      d="M13.5 4.49976H12.5C12.224 4.49976 12 4.72376 12 4.99976C12 5.27576 12.224 5.49976 12.5 5.49976H13.5C13.776 5.49976 14 5.27576 14 4.99976C14 4.72376 13.776 4.49976 13.5 4.49976Z"/>
  <path
      d="M13.5 6.49976H12.5C12.224 6.49976 12 6.72376 12 6.99976C12 7.27576 12.224 7.49976 12.5 7.49976H13.5C13.776 7.49976 14 7.27576 14 6.99976C14 6.72376 13.776 6.49976 13.5 6.49976Z"/>
  <path
      d="M13.5 8.49963H12.5C12.224 8.49963 12 8.72363 12 8.99963C12 9.27563 12.224 9.49963 12.5 9.49963H13.5C13.776 9.49963 14 9.27563 14 8.99963C14 8.72363 13.776 8.49963 13.5 8.49963Z"/>
  <path
      d="M13.5 10.4996H12.5C12.224 10.4996 12 10.7236 12 10.9996C12 11.2756 12.224 11.4996 12.5 11.4996H13.5C13.776 11.4996 14 11.2756 14 10.9996C14 10.7236 13.776 10.4996 13.5 10.4996Z"/>
  <path
      d="M6.8771 9.67059L3.3771 5.67059C3.1931 5.46159 2.8781 5.44159 2.6711 5.62359C2.4631 5.80559 2.4421 6.12159 2.6241 6.32859L6.1241 10.3286C6.2231 10.4416 6.3611 10.4996 6.5001 10.4996C6.6171 10.4996 6.7341 10.4586 6.8301 10.3756C7.0381 10.1936 7.0591 9.87859 6.8771 9.67059Z"/>
  <path
      d="M6.80702 5.10569C6.58902 4.93469 6.27502 4.97569 6.10502 5.19269L2.60502 9.69268C2.43602 9.91068 2.47502 10.2257 2.69302 10.3947C2.78502 10.4657 2.89302 10.4997 3.00002 10.4997C3.14802 10.4997 3.29602 10.4337 3.39402 10.3077L6.89402 5.80769C7.06402 5.58869 7.02502 5.27469 6.80702 5.10569Z"/>
</svg>
</template>
