<template>
  <div v-if="object">
    <b-tabs nav-class="sub-menu"
            class="w-100"
            @activate-tab="tabChanged">
      <b-tab active :title="$t('main')"></b-tab>
      <b-tab :title="$t('salaries')" ></b-tab>
      <b-tab :title="$t('materials')" ></b-tab>
      <b-tab :title="$t('insurance')" ></b-tab>
    </b-tabs>
  </div>
</template>

<script>

export default {
  name: "ServicesAdditionalFormComponent",
  components: {
  },
  props: {
    object: {
      type: Object
    },
    options: {},
  },
  data() {
    return {
      views: ['main', 'salaries', 'materials', 'insurance']
    }
  },
  mounted() {
    if(this.object && !this.object.id) {
      this.$store.commit('setFullCard', false)
    }
    this.$store.commit('setFormTabActive', this.views[0])
  },
  methods: {
    tabChanged(tab) {
      this.$store.commit('setFormTabActive', this.views[tab])
    }
  }
}
</script>
