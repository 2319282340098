<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>
    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M11.269 2.40276L13.3528 1.133H16.8365L18.9365 2.40276L17.3248 3.94927L19.4086 5.49578L17.5202 7.04229L19.8155 8.73531L17.9923 10.2818L20.2876 11.6818L18.269 13.0981L20.2876 14.7097L18.5946 16.2562L20.8248 17.6725L19.0667 19.1376L21.362 20.6841L19.2132 22.2306L21.362 23.8423L19.8155 25.1934L21.8993 26.7399L20.076 28.2213L22.1597 29.7678L20.3527 31.5097L21.362 33.1864V47.4469L19.4737 54.7724H13.3528" fill="#8889BE"/>
      <path d="M18.6763 2.40276L16.5926 1.133H13.1252L11.0089 2.40276L12.6368 3.94927L10.5368 5.49578L12.4252 7.04229L10.1461 8.73531L11.9531 10.2818L9.67401 11.6818L11.6926 13.0981L9.67401 14.7097L11.3508 16.2562L9.1368 17.6725L10.8787 19.1376L8.5996 20.6841L10.7484 22.2306L8.5996 23.8423L10.1461 25.1934L8.06239 26.7399L9.86936 28.2213L7.78564 29.7678L9.6089 31.5097L8.5996 33.1864V47.4469L10.4717 54.7724H14.3787" fill="#8889BE"/>
      <path d="M28.0437 71.943C27.1965 73.0995 26.6182 72.8978 24.3456 74.9015C22.3553 76.6632 22.5436 77.0397 20.9299 78.3172C19.5406 79.6969 17.7049 80.536 15.7525 80.684C14.3826 80.3789 13.0795 79.8276 11.9065 79.0568C10.0048 78.1127 8.17548 77.0295 6.43335 75.8159C4.9417 74.9217 3.58357 73.8216 2.39906 72.5482C0.247444 69.5494 0.987063 65.542 1.95529 60.2705C2.26459 58.603 2.78904 56.7069 3.82451 52.8878C3.82451 52.8878 3.82451 52.7802 3.82451 52.713C4.57758 50.9379 9.60698 48.4232 9.60698 48.4232C11.4617 47.401 13.5548 46.8905 15.6719 46.9439C17.5844 47.0458 19.4539 47.5499 21.1585 48.4232C22.3037 48.9479 23.39 49.5925 24.3994 50.3462C24.4935 50.6286 24.5876 50.9244 24.7086 51.2337C25.6634 53.7484 26.282 53.5064 27.3982 56.2631C28.4635 59.2047 28.9653 62.3205 28.8774 65.4478C29.0119 68.8366 29.0119 70.5579 28.0437 71.943Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M5.54391 52.2226C5.08669 53.8228 4.57568 55.4366 3.71503 58.6236C2.67957 62.4293 2.15511 64.3389 1.84582 65.9929C0.877587 71.2644 0.124521 75.2852 2.28959 78.2841C3.47717 79.5504 4.8349 80.6456 6.32387 81.5384C8.07008 82.7568 9.90395 83.8446 11.8105 84.7927C12.9802 85.5694 14.2845 86.1212 15.6565 86.4199C17.6107 86.279 19.4487 85.4387 20.8338 84.0531C22.4476 82.789 22.2458 82.4125 24.2361 80.6508C26.5222 78.6337 27.1004 78.8354 27.9476 77.6924C28.9562 76.3476 28.9293 74.5725 28.8352 71.1703C28.923 68.0474 28.4211 64.9359 27.3559 61.999C26.2532 59.2422 25.6481 59.4709 24.6664 56.9561C23.483 53.8901 23.7116 51.6981 23.6175 47.9328C23.4825 44.1154 23.1368 40.3085 22.582 36.5292C21.6138 29.644 20.5649 30.5316 19.3277 23.3506C17.835 14.6231 17.082 10.266 17.2568 7.6572C17.324 6.67552 17.5795 3.70361 15.7775 1.88818C15.6431 1.75371 14.5404 0.664439 13.8545 0.973734C12.9266 1.39061 13.8545 3.87842 12.8191 7.69754C12.5501 8.73301 12.1332 10.266 11.3398 10.3871C10.5464 10.5081 9.76646 8.4775 8.97305 8.59853C8.17964 8.71956 7.72242 12.0142 7.62829 13.1976C7.33889 16.4455 7.33889 19.7127 7.62829 22.9606C8.50879 28.4917 8.7075 34.1099 8.21998 39.6894C7.67214 43.9333 6.77711 48.1251 5.54391 52.2226Z" fill="#DEE1E9"/>-->
<!--      <path d="M27.955 77.679C27.1078 78.8355 26.5296 78.6338 24.2569 80.6375C22.2667 82.3991 22.455 82.7756 20.8412 84.0532C19.452 85.4328 17.6163 86.272 15.6639 86.4199C14.294 86.1149 12.9908 85.5635 11.8179 84.7928C9.91622 83.8487 8.08685 82.7655 6.34472 81.5519C4.85308 80.6577 3.49495 79.5576 2.31044 78.2842C0.158821 75.2853 0.89844 71.2779 1.86667 66.0065C2.17596 64.339 2.70042 62.4428 3.73589 58.6237C3.73589 58.6237 3.73589 58.5162 3.73589 58.4489C4.48895 56.6738 9.51836 54.1591 9.51836 54.1591C11.3731 53.137 13.4662 52.6265 15.5832 52.6799C17.4958 52.7818 19.3653 53.2858 21.0699 54.1591C22.215 54.6839 23.3014 55.3285 24.3107 56.0821C24.4049 56.3645 24.499 56.6604 24.62 56.9697C25.5748 59.4844 26.1934 59.2423 27.3095 61.9991C28.3748 64.9406 28.8767 68.0565 28.7888 71.1838C28.9233 74.5726 28.9233 76.2939 27.955 77.679Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--      <path opacity="0.2" d="M15.3767 64.0162C14.0319 64.1372 12.1492 59.2558 11.4365 54.6029L11.6113 41.3973C11.7996 37.2017 12.0148 33.7725 12.1627 31.4327C12.5123 26.0536 12.6334 25.4216 12.6334 23.1489C12.6334 17.2992 14.8522 3.43868 15.296 3.31765C15.7398 3.19662 13.7226 16.4251 14.395 19.1146C15.2422 22.5707 15.0943 24.4937 15.296 29.4424C15.3179 33.2605 15.619 37.0718 16.197 40.846C16.7618 43.8986 17.34 45.3644 17.9721 49.7483C18.331 51.7761 18.4214 53.8424 18.241 55.8938C18.088 57.544 17.7358 59.1695 17.1921 60.735C16.3583 62.8732 15.9684 63.9624 15.3767 64.0162Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M5.81002 51.5807C5.3528 53.181 4.84179 54.7947 3.98115 57.9818C2.94568 61.7875 2.42122 63.697 2.11193 65.3511C1.1437 70.6226 0.390634 74.6434 2.5557 77.6422C3.74328 78.9086 5.10102 80.0038 6.58998 80.8965C8.3362 82.115 10.1701 83.2027 12.0766 84.1509C13.2463 84.9276 14.5506 85.4794 15.9226 85.778C17.8768 85.6371 19.7148 84.7969 21.1 83.4112C22.7137 82.1472 22.512 81.7706 24.5022 80.009C26.7883 77.9918 27.3665 78.1936 28.2137 77.0505C29.2223 75.7058 29.1954 73.9307 29.1013 70.5284C29.1891 67.4055 28.6872 64.2941 27.622 61.3572C26.5193 58.6004 25.9142 58.829 24.9325 56.3143C23.7491 53.2482 23.9777 51.0563 23.8836 47.2909C23.7486 43.4736 23.4029 39.6666 22.8481 35.8874C21.8799 29.0022 20.831 29.8897 19.5938 22.7087C18.1011 13.9812 17.3481 9.62419 17.5229 7.01535C17.5901 6.03368 17.8456 3.06176 16.0437 1.24634C15.9092 1.11186 14.8065 0.0225935 14.1206 0.331889C13.1928 0.748765 14.1206 3.23657 13.0852 7.05569C12.8162 8.09116 12.3994 9.62419 11.6059 9.74522C10.8125 9.86625 10.0326 7.83565 9.23916 7.95668C8.44575 8.07771 7.98853 11.3724 7.8944 12.5558C7.605 15.8036 7.605 19.0709 7.8944 22.3187C8.7749 27.8498 8.97362 33.4681 8.4861 39.0476C7.93825 43.2914 7.04323 47.4832 5.81002 51.5807Z" fill="#DEE1E9"/>
        <mask id="414" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="30" height="36">
          <path d="M28.3051 77.2038C27.4579 78.3603 26.8797 78.1586 24.607 80.1623C22.6168 81.924 22.8051 82.3005 21.1913 83.578C19.802 84.9577 17.9664 85.7968 16.014 85.9448C14.6441 85.6397 13.3409 85.0884 12.168 84.3176C10.2663 83.3735 8.43695 82.2903 6.69482 81.0768C5.20317 80.1825 3.84505 79.0824 2.66054 77.809C0.508919 74.8102 1.24854 70.8028 2.21677 65.5313C2.52606 63.8638 3.05052 61.9677 4.08598 58.1486C4.08598 58.1486 4.08598 58.041 4.08598 57.9738C4.83905 56.1987 9.86846 53.684 9.86846 53.684C11.7232 52.6618 13.8163 52.1513 15.9333 52.2047C17.8459 52.3066 19.7154 52.8107 21.42 53.684C22.5651 54.2087 23.6515 54.8533 24.6608 55.607C24.755 55.8894 24.8491 56.1852 24.9701 56.4945C25.9249 59.0092 26.5435 58.7672 27.6596 61.5239C28.7249 64.4655 29.2267 67.5813 29.1389 70.7086C29.2734 74.0974 29.2734 75.8187 28.3051 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#414)">
          <path d="M28.3051 77.2038C27.4579 78.3603 26.8797 78.1586 24.607 80.1623C22.6168 81.924 22.8051 82.3005 21.1913 83.578C19.802 84.9577 17.9664 85.7968 16.014 85.9448C14.6441 85.6397 13.3409 85.0884 12.168 84.3176C10.2663 83.3735 8.43695 82.2903 6.69482 81.0768C5.20317 80.1825 3.84505 79.0824 2.66054 77.809C0.508919 74.8102 1.24854 70.8028 2.21677 65.5313C2.52606 63.8638 3.05052 61.9677 4.08598 58.1486C4.08598 58.1486 4.08598 58.041 4.08598 57.9738C4.83905 56.1987 9.86846 53.684 9.86846 53.684C11.7232 52.6618 13.8163 52.1513 15.9333 52.2047C17.8459 52.3066 19.7154 52.8107 21.42 53.684C22.5651 54.2087 23.6515 54.8533 24.6608 55.607C24.755 55.8894 24.8491 56.1852 24.9701 56.4945C25.9249 59.0092 26.5435 58.7672 27.6596 61.5239C28.7249 64.4655 29.2267 67.5813 29.1389 70.7086C29.2734 74.0974 29.2734 75.8187 28.3051 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M15.7809 69.2009C14.8005 69.2009 13.2661 77.6026 13.2661 87.2009H15.1892H18.2661C18.2661 77.6026 16.7613 69.2009 15.7809 69.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M15.9809 70.1985C14.0304 70.334 11.2998 64.8656 10.2661 59.6533L10.5197 44.86C10.7927 40.1599 11.1048 36.3184 11.3193 33.6972C11.8265 27.6714 12.002 26.9634 12.002 24.4175C12.002 17.8645 15.2202 2.33743 15.8638 2.20184C16.5075 2.06626 13.5818 16.8853 14.5571 19.8982C15.7858 23.7697 15.5713 25.9239 15.8638 31.4677C15.8956 35.7448 16.3324 40.0144 17.1706 44.2424C17.9898 47.662 18.8285 49.304 19.7452 54.215C20.2658 56.4867 20.3969 58.8014 20.1353 61.0995C19.9133 62.948 19.4025 64.769 18.6139 66.5227C17.4047 68.918 16.839 70.1382 15.9809 70.1985Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M5.54391 54.3797C5.08669 55.98 4.57568 57.5937 3.71503 60.7808C2.67957 64.5865 2.15511 66.4961 1.84582 68.1501C0.877587 73.4216 0.124521 77.4424 2.28959 80.4412C3.47717 81.7076 4.8349 82.8028 6.32387 83.6955C8.07008 84.914 9.90395 86.0017 11.8105 86.9499C12.9802 87.7266 14.2845 88.2784 15.6565 88.577C17.6107 88.4361 19.4487 87.5959 20.8338 86.2102C22.4476 84.9462 22.2458 84.5696 24.2361 82.808C26.5222 80.7909 27.1004 80.9926 27.9476 79.8495C28.9562 78.5048 28.9293 76.7297 28.8352 73.3274C28.923 70.2045 28.4211 67.0931 27.3559 64.1562C26.2532 61.3994 25.6481 61.628 24.6664 59.1133C23.483 56.0473 23.7116 53.8553 23.6175 50.09C23.4825 46.2726 23.1368 42.4656 22.582 38.6864C21.6138 31.8012 20.5649 32.6887 19.3277 25.5077C17.835 16.7802 17.082 12.4232 17.2568 9.81436C17.324 8.83269 17.5795 5.86078 15.7775 4.04535C15.6431 3.91087 14.5404 2.8216 13.8545 3.1309C12.9266 3.54778 13.8545 6.03558 12.8191 9.85471C12.5501 10.8902 12.1332 12.4232 11.3398 12.5442C10.5464 12.6653 9.76646 10.6347 8.97305 10.7557C8.17964 10.8767 7.72242 14.1714 7.62829 15.3548C7.33889 18.6027 7.33889 21.8699 7.62829 25.1178C8.50879 30.6489 8.7075 36.2671 8.21998 41.8466C7.67214 46.0905 6.77711 50.2822 5.54391 54.3797Z" fill="#DEE1E9"/>
      <path d="M27.955 79.8362C27.1078 80.9927 26.5296 80.791 24.2569 82.7946C22.2667 84.5563 22.455 84.9328 20.8412 86.2103C19.452 87.59 17.6163 88.4292 15.6639 88.5771C14.294 88.272 12.9908 87.7207 11.8179 86.95C9.91622 86.0059 8.08685 84.9226 6.34472 83.7091C4.85308 82.8148 3.49495 81.7148 2.31044 80.4413C0.158821 77.4425 0.89844 73.4351 1.86667 68.1636C2.17596 66.4961 2.70042 64.6 3.73589 60.7809C3.73589 60.7809 3.73589 60.6733 3.73589 60.6061C4.48895 58.831 9.51836 56.3163 9.51836 56.3163C11.3731 55.2942 13.4662 54.7836 15.5832 54.8371C17.4958 54.939 19.3653 55.443 21.0699 56.3163C22.215 56.841 23.3014 57.4856 24.3107 58.2393C24.4049 58.5217 24.499 58.8175 24.62 59.1268C25.5748 61.6415 26.1934 61.3995 27.3095 64.1562C28.3748 67.0978 28.8767 70.2137 28.7888 73.341C28.9233 76.7298 28.9233 78.4511 27.955 79.8362Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M15.3767 66.1734C14.0319 66.2944 12.1492 61.4129 11.4365 56.7601L11.6113 43.5545C11.7996 39.3588 12.0148 35.9297 12.1627 33.5898C12.5123 28.2108 12.6334 27.5787 12.6334 25.3061C12.6334 19.4564 14.8522 5.59585 15.296 5.47482C15.7398 5.35379 13.7226 18.5823 14.395 21.2718C15.2422 24.7278 15.0943 26.6508 15.296 31.5996C15.3179 35.4176 15.619 39.229 16.197 43.0032C16.7618 46.0558 17.34 47.5215 17.9721 51.9055C18.331 53.9333 18.4214 55.9996 18.241 58.051C18.088 59.7011 17.7358 61.3267 17.1921 62.8922C16.3583 65.0303 15.9684 66.1196 15.3767 66.1734Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M5.63253 51.8381C5.17531 53.4383 4.6643 55.052 3.80366 58.2391C2.76819 62.0448 2.24373 63.9544 1.93444 65.6084C0.96621 70.8799 0.213144 74.9007 2.37821 77.8995C3.56579 79.1659 4.92353 80.2611 6.41249 81.1539C8.15871 82.3723 9.99258 83.46 11.8991 84.4082C13.0689 85.1849 14.3731 85.7367 15.7451 86.0353C17.6993 85.8944 19.5373 85.0542 20.9225 83.6686C22.5362 82.4045 22.3345 82.0279 24.3247 80.2663C26.6108 78.2492 27.1891 78.4509 28.0363 77.3078C29.0448 75.9631 29.0179 74.188 28.9238 70.7857C29.0116 67.6628 28.5097 64.5514 27.4446 61.6145C26.3419 58.8577 25.7367 59.0863 24.755 56.5716C23.5716 53.5056 23.8003 51.3136 23.7061 47.5483C23.5711 43.7309 23.2254 39.9239 22.6707 36.1447C21.7024 29.2595 20.6535 30.1471 19.4163 22.966C17.9236 14.2385 17.1706 9.88151 17.3454 7.27268C17.4126 6.291 17.6681 3.31909 15.8662 1.50366C15.7317 1.36918 14.629 0.279918 13.9432 0.589213C13.0153 1.00609 13.9432 3.4939 12.9077 7.31302C12.6387 8.34848 12.2219 9.88151 11.4285 10.0025C10.635 10.1236 9.85508 8.09298 9.06167 8.21401C8.26826 8.33503 7.81104 11.6297 7.71691 12.8131C7.42751 16.061 7.42751 19.3282 7.71691 22.5761C8.59741 28.1072 8.79613 33.7254 8.30861 39.3049C7.76076 43.5488 6.86574 47.7405 5.63253 51.8381Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M5.63253 51.8381C5.17531 53.4383 4.6643 55.052 3.80366 58.2391C2.76819 62.0448 2.24373 63.9544 1.93444 65.6084C0.96621 70.8799 0.213144 74.9007 2.37821 77.8995C3.56579 79.1659 4.92353 80.2611 6.41249 81.1539C8.15871 82.3723 9.99258 83.46 11.8991 84.4082C13.0689 85.1849 14.3731 85.7367 15.7451 86.0353C17.6993 85.8944 19.5373 85.0542 20.9225 83.6686C22.5362 82.4045 22.3345 82.0279 24.3247 80.2663C26.6108 78.2492 27.1891 78.4509 28.0363 77.3078C29.0448 75.9631 29.0179 74.188 28.9238 70.7857C29.0116 67.6628 28.5097 64.5514 27.4446 61.6145C26.3419 58.8577 25.7367 59.0863 24.755 56.5716C23.5716 53.5056 23.8003 51.3136 23.7061 47.5483C23.5711 43.7309 23.2254 39.9239 22.6707 36.1447C21.7024 29.2595 20.6535 30.1471 19.4163 22.966C17.9236 14.2385 17.1706 9.88151 17.3454 7.27268C17.4126 6.291 17.6681 3.31909 15.8662 1.50366C15.7317 1.36918 14.629 0.279918 13.9432 0.589213C13.0153 1.00609 13.9432 3.4939 12.9077 7.31302C12.6387 8.34848 12.2219 9.88151 11.4285 10.0025C10.635 10.1236 9.85508 8.09298 9.06167 8.21401C8.26826 8.33503 7.81104 11.6297 7.71691 12.8131C7.42751 16.061 7.42751 19.3282 7.71691 22.5761C8.59741 28.1072 8.79613 33.7254 8.30861 39.3049C7.76076 43.5488 6.86574 47.7405 5.63253 51.8381Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M0.753967 60.3764C1.61461 57.1894 1.19202 52.9667 1.64924 51.3664C2.88244 47.2689 3.77747 43.0771 4.32531 38.8333C4.81283 33.2538 4.61412 27.6356 3.73362 22.1044C3.44422 18.8566 3.44422 15.5894 3.73362 12.3415C3.82775 11.1581 4.28497 7.86342 5.07838 7.74239C5.87179 7.62136 6.65175 9.65195 7.44516 9.53092C8.23857 9.40989 8.65544 7.87687 8.92439 6.8414C9.95986 3.02228 9.03197 0.53447 9.95986 0.117594C10.6457 -0.191701 11.7484 0.897566 11.8829 1.03204C13.6848 2.84747 13.4293 5.81938 13.3621 6.80106C13.1895 9.37743 13.9218 13.6588 15.3777 22.1706L15.433 22.4944C16.6702 29.6754 17.7191 28.7879 18.6874 35.6731C19.2421 39.4523 19.5878 43.2593 19.7228 47.0766C19.817 50.842 19.5884 53.034 20.7717 56.1C21.7534 58.6147 22.0039 61.1156 22.0039 61.1156L16.231 60.3764L10.7843 61.1156L5.56689 59.6257L0.753967 60.3764Z" fill="#DEE1E9"/>
      <path d="M2.655 52.4463C9.94366 50.9787 13.6299 51.9211 19.637 56.1461" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M7.71669 40.5412L7.54187 53.7468L7.89003 60.8015L10.2372 59.8789L11.7505 61.0795L13.2974 59.8789C13.8412 58.3134 14.1934 56.6878 14.3464 55.0377C14.5268 52.9863 14.4363 50.92 14.0774 48.8922C13.4454 44.5082 12.8671 43.0425 12.3023 39.9899C11.7244 36.2157 11.4232 32.4043 11.4013 28.5863C11.1996 23.6375 11.3475 21.7145 10.5003 18.2585C9.82796 15.569 11.8451 2.34049 11.4013 2.46152C10.9576 2.58255 8.73871 16.4431 8.73871 22.2928C8.73871 24.5654 8.61768 25.1975 8.26804 30.5765C8.12012 32.9164 7.90496 36.3455 7.71669 40.5412Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M2.82031 58.3554C3.68096 55.1683 4.19197 53.5546 4.64919 51.9544C5.88239 47.8568 6.77742 43.6651 7.32526 39.4212C7.81278 33.8417 7.61407 28.2235 6.73357 22.6924C6.44417 19.4445 6.44417 16.1773 6.73357 12.9294C6.8277 11.746 7.28492 8.45134 8.07833 8.33031C8.87174 8.20928 9.6517 10.2399 10.4451 10.1188C11.2385 9.99781 11.6554 8.46479 11.9243 7.42932C12.9598 3.6102 12.0319 1.12239 12.9598 0.705515C13.6456 0.39622 14.7483 1.48549 14.8828 1.61996C16.6848 3.43539 16.4293 6.4073 16.3621 7.38898C16.1894 9.96535 16.9217 14.2467 18.3776 22.7585L18.433 23.0823C19.6702 30.2634 20.7191 29.3758 21.6873 36.261C22.2421 40.0402 22.5878 43.8472 22.7228 47.6646C22.8169 51.4299 22.5883 53.6219 23.7717 56.6879C24.7534 59.2026 25.3585 58.974 26.4612 61.7308L2.82031 58.3554Z" fill="#DEE1E9"/>
        <path d="M23.3623 80.3693C25.6349 78.3656 26.2131 78.5673 27.0603 77.4108C28.0286 76.0257 28.0286 74.3044 27.8941 70.9156C27.982 67.7883 27.4801 64.6724 26.4149 61.7309C25.2987 58.9741 24.6801 59.2162 23.7253 56.7015C23.6043 56.3922 23.5102 56.0963 23.416 55.8139C22.4067 55.0602 21.3204 54.4156 20.1752 53.8909C18.4706 53.0176 16.6011 52.5136 14.6885 52.4117C12.5715 52.3583 10.4784 52.8688 8.62367 53.8909C8.62367 53.8909 3.59426 56.4056 2.8412 58.1807V58.3555C1.80573 62.1746 1.28127 64.0708 0.971978 65.7383L0.968149 65.7591C0.890337 66.1827 0.814026 66.5982 0.740601 67.0056L5.77588 66.0929L9.63844 67.9272L8.73134 70.5725L9.93892 73.0394L8.73134 75.3528L12.5404 76.8675L11.2017 79.9025L14.7692 79.1313L12.5404 83.4706L11.5821 84.9268C12.577 85.4895 13.6508 85.9027 14.7692 86.1517C16.7216 86.0038 18.5573 85.1646 19.9466 83.785C21.5603 82.5074 21.372 82.1309 23.3623 80.3693Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M5.72776 61.6207C16.4036 65.394 18.7285 69.9414 17.1029 81.8769" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M14.482 63.748C13.1372 63.869 11.2545 58.9875 10.5418 54.3347L10.7166 41.1291C10.9049 36.9334 11.1201 33.5043 11.268 31.1644C11.6176 25.7854 11.7386 25.1533 11.7386 22.8807C11.7386 17.031 13.9575 3.17046 14.4013 3.04943C14.845 2.9284 12.8279 16.1569 13.5003 18.8464C14.3475 22.3024 14.1996 24.2255 14.4013 29.1742C14.4232 32.9922 14.7243 36.8036 15.3023 40.5778C15.8671 43.6304 16.4453 45.0962 17.0773 49.4801C17.4363 51.5079 17.5267 53.5742 17.3463 55.6256C17.1933 57.2758 16.8411 58.9013 16.2974 60.4668C15.4636 62.6049 15.0737 63.6942 14.482 63.748Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M5.89864 51.5665C5.44143 53.1668 4.93042 54.7805 4.06977 57.9676C3.0343 61.7732 2.50985 63.6828 2.20055 65.3369C1.23232 70.6083 0.479257 74.6292 2.64432 77.628C3.8319 78.8943 5.18964 79.9896 6.67861 80.8823C8.42482 82.1008 10.2587 83.1885 12.1652 84.1366C13.335 84.9133 14.6393 85.4652 16.0112 85.7638C17.9654 85.6229 19.8034 84.7827 21.1886 83.397C22.8023 82.1329 22.6006 81.7564 24.5908 79.9948C26.8769 77.9776 27.4552 78.1793 28.3024 77.0363C29.3109 75.6915 29.284 73.9164 29.1899 70.5142C29.2777 67.3913 28.7758 64.2799 27.7107 61.3429C26.608 58.5862 26.0028 58.8148 25.0211 56.3001C23.8378 53.234 24.0664 51.0421 23.9722 47.2767C23.8372 43.4594 23.4915 39.6524 22.9368 35.8732C21.9685 28.988 20.9196 29.8755 19.6824 22.6945C18.1898 13.967 17.4367 9.60997 17.6115 7.00113C17.6788 6.01946 17.9343 3.04754 16.1323 1.23212C15.9978 1.09764 14.8951 0.00837231 14.2093 0.317667C13.2814 0.734543 14.2093 3.22235 13.1738 7.04147C12.9048 8.07694 12.488 9.60997 11.6946 9.731C10.9012 9.85202 10.1212 7.82143 9.32779 7.94246C8.53438 8.06349 8.07716 11.3582 7.98302 12.5415C7.69362 15.7894 7.69362 19.0566 7.98302 22.3045C8.86353 27.8356 9.06224 33.4539 8.57472 39.0334C8.02687 43.2772 7.13185 47.469 5.89864 51.5665Z" fill="#DEE1E9"/>
        <path d="M24.6117 79.9814C26.8843 77.9777 27.4626 78.1794 28.3098 77.0229C29.278 75.6378 29.278 73.9165 29.1435 70.5277C29.2314 67.4004 28.7296 64.2846 27.6643 61.343C26.5481 58.5862 25.9295 58.8283 24.9748 56.3136C24.8537 56.0043 24.7596 55.7085 24.6655 55.4261H20.7597C20.2074 55.4261 19.7597 55.8738 19.7597 56.4261V66.3238C15.9504 67.9009 13.8201 67.9315 10.0098 66.3238V56.4261C10.0098 55.8738 9.5621 55.4261 9.00981 55.4261H5.20187C5.20187 55.4933 4.09062 57.9677 4.09062 57.9677C3.05516 61.7868 2.5307 63.6829 2.2214 65.3504L2.21758 65.3712C1.25123 70.6324 0.516391 74.6332 2.66518 77.6281C3.84969 78.9015 5.20781 80.0016 6.69946 80.8958C8.44159 82.1094 10.271 83.1926 12.1726 84.1367C13.3456 84.9075 14.6487 85.4588 16.0187 85.7639C17.971 85.6159 19.8067 84.7768 21.196 83.3971C22.8097 82.1196 22.6214 81.743 24.6117 79.9814Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M5.89864 51.8173C5.44143 53.4176 4.93042 55.0313 4.06977 58.2183C3.0343 62.024 2.50985 63.9336 2.20055 65.5876C1.23232 70.8591 0.479257 74.8799 2.64432 77.8788C3.8319 79.1451 5.18964 80.2403 6.67861 81.1331C8.42482 82.3515 10.2587 83.4393 12.1652 84.3874C13.335 85.1641 14.6393 85.7159 16.0112 86.0146C17.9654 85.8737 19.8034 85.0334 21.1886 83.6478C22.8023 82.3837 22.6006 82.0072 24.5908 80.2455C26.8769 78.2284 27.4552 78.4301 28.3024 77.2871C29.3109 75.9423 29.284 74.1672 29.1899 70.765C29.2777 67.6421 28.7758 64.5306 27.7107 61.5937C26.608 58.8369 26.0028 59.0656 25.0211 56.5508C23.8378 53.4848 24.0664 51.2928 23.9722 47.5275C23.8372 43.7101 23.4915 39.9032 22.9368 36.1239C21.9685 29.2387 20.9196 30.1263 19.6824 22.9453C18.1898 14.2178 17.4367 9.86074 17.6115 7.2519C17.6788 6.27023 17.9343 3.29831 16.1323 1.48289C15.9978 1.34841 14.8951 0.259143 14.2093 0.568438C13.2814 0.985314 14.2093 3.47312 13.1738 7.29224C12.9048 8.32771 12.488 9.86074 11.6946 9.98177C10.9012 10.1028 10.1212 8.0722 9.32779 8.19323C8.53438 8.31426 8.07716 11.6089 7.98302 12.7923C7.69362 16.0402 7.69362 19.3074 7.98302 22.5553C8.86353 28.0864 9.06224 33.7046 8.57472 39.2841C8.02687 43.528 7.13185 47.7198 5.89864 51.8173Z" fill="#DEE1E9"/>
        <path d="M24.6117 80.2322C26.8843 78.2285 27.4626 78.4302 28.3098 77.2737C29.278 75.8886 29.278 74.1673 29.1435 70.7785C29.2314 67.6512 28.7296 64.5354 27.6643 61.5938C26.5481 58.837 25.9295 59.0791 24.9748 56.5644C24.8537 56.2551 24.7596 55.9592 24.6655 55.6768H20.7597C20.2074 55.6768 19.7597 56.1245 19.7597 56.6768V66.5746C15.9504 68.1516 13.8201 68.1823 10.0098 66.5746V56.6768C10.0098 56.1245 9.5621 55.6768 9.00981 55.6768H5.20187C5.20187 55.7441 4.09062 58.2184 4.09062 58.2184C3.05516 62.0376 2.5307 63.9337 2.2214 65.6012L2.21758 65.622C1.25123 70.8832 0.516391 74.884 2.66518 77.8789C3.84969 79.1523 5.20781 80.2524 6.69946 81.1466C8.44159 82.3602 10.271 83.4434 12.1726 84.3875C13.3456 85.1582 14.6487 85.7096 16.0187 86.0146C17.971 85.8667 19.8067 85.0275 21.196 83.6479C22.8097 82.3703 22.6214 81.9938 24.6117 80.2322Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M5.89864 51.8849C5.44143 53.4852 4.93042 55.0989 4.06977 58.286C3.0343 62.0917 2.50985 64.0012 2.20055 65.6553C1.23232 70.9268 0.479257 74.9476 2.64432 77.9464C3.8319 79.2128 5.18964 80.308 6.67861 81.2007C8.42482 82.4192 10.2587 83.5069 12.1652 84.4551C13.335 85.2318 14.6393 85.7836 16.0112 86.0822C17.9654 85.9413 19.8034 85.1011 21.1886 83.7154C22.8023 82.4514 22.6006 82.0748 24.5908 80.3132C26.8769 78.296 27.4552 78.4978 28.3024 77.3547C29.3109 76.01 29.284 74.2349 29.1899 70.8326C29.2777 67.7097 28.7758 64.5983 27.7107 61.6614C26.608 58.9046 26.0028 59.1332 25.0211 56.6185C23.8378 53.5524 24.0664 51.3605 23.9722 47.5951C23.8372 43.7778 23.4915 39.9708 22.9368 36.1916C21.9685 29.3064 20.9196 30.1939 19.6824 23.0129C18.1898 14.2854 17.4367 9.92839 17.6115 7.31955C17.6788 6.33788 17.9343 3.36596 16.1323 1.55054C15.9978 1.41606 14.8951 0.326793 14.2093 0.636088C13.2814 1.05296 14.2093 3.54077 13.1738 7.35989C12.9048 8.39536 12.488 9.92839 11.6946 10.0494C10.9012 10.1704 10.1212 8.13985 9.32779 8.26088C8.53438 8.38191 8.07716 11.6766 7.98302 12.86C7.69362 16.1078 7.69362 19.3751 7.98302 22.6229C8.86353 28.154 9.06224 33.7723 8.57472 39.3518C8.02687 43.5956 7.13185 47.7874 5.89864 51.8849Z" fill="#DEE1E9"/>
        <path d="M24.6117 80.2998C26.8843 78.2961 27.4626 78.4978 28.3098 77.3413C29.278 75.9562 29.278 74.2349 29.1435 70.8461C29.2314 67.7189 28.7296 64.603 27.6643 61.6614C26.5481 58.9047 25.9295 59.1467 24.9748 56.632C24.8537 56.3227 24.7596 56.0269 24.6655 55.7445H20.6573V66.6422H9.20209V55.7445H5.20187C5.20187 55.8117 4.09062 58.2861 4.09062 58.2861C3.05516 62.1052 2.5307 64.0013 2.2214 65.6688L2.21758 65.6896C1.25123 70.9508 0.516391 74.9516 2.66518 77.9465C3.84969 79.2199 5.20781 80.32 6.69946 81.2143C8.44159 82.4278 10.271 83.511 12.1726 84.4551C13.3456 85.2259 14.6487 85.7772 16.0187 86.0823C17.971 85.9343 19.8067 85.0952 21.196 83.7155C22.8097 82.438 22.6214 82.0615 24.6117 80.2998Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask2014" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="30" height="31">
          <path d="M24.6378 80.2997C26.9104 78.296 27.4887 78.4977 28.3359 77.3412C29.3041 75.9561 29.3041 74.2348 29.1696 70.846C29.2575 67.7187 28.7557 64.6029 27.6904 61.6613C26.5743 58.9046 25.9557 59.1466 25.0009 56.6319C24.8799 56.3226 24.7319 56.9143 24.6378 56.6319H21.635C21.0806 56.6319 20.632 57.0829 20.635 57.6373L20.6834 66.6421C16.5465 67.5778 14.2309 67.6052 10.0946 66.6421L10.0461 57.6265C10.0432 57.0764 9.59635 56.6319 9.04616 56.6319L5.17421 56.6319C5.17421 56.6991 4.11675 58.286 4.11675 58.286C3.08128 62.1051 2.55682 64.0012 2.24753 65.6687L2.2437 65.6895C1.27736 70.9507 0.542515 74.9515 2.6913 77.9464C3.87581 79.2198 5.23394 80.3199 6.72558 81.2141C8.46771 82.4277 10.2971 83.5109 12.1988 84.455C13.3717 85.2258 14.6748 85.7771 16.0448 86.0822C17.9971 85.9342 19.8328 85.0951 21.2221 83.7154C22.8358 82.4379 22.6476 82.0614 24.6378 80.2997Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2014)">
          <path d="M6.81757 44.6177H23.7121V70.1656C17.1144 72.0919 13.4153 72.1115 6.81757 70.1656V44.6177Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M5.75924 52.2507C5.30202 53.8509 4.79101 55.4646 3.93037 58.6517C2.8949 62.4574 2.37044 64.367 2.06115 66.021C1.09292 71.2925 0.339853 75.3133 2.50492 78.3121C3.6925 79.5785 5.05024 80.6737 6.5392 81.5665C8.28542 82.7849 10.1193 83.8726 12.0258 84.8208C13.1956 85.5975 14.4999 86.1493 15.8718 86.4479C17.826 86.307 19.664 85.4668 21.0492 84.0812C22.6629 82.8171 22.4612 82.4405 24.4514 80.6789C26.7375 78.6618 27.3158 78.8635 28.163 77.7204C29.1715 76.3757 29.1446 74.6006 29.0505 71.1983C29.1383 68.0754 28.6364 64.964 27.5713 62.0271C26.4686 59.2703 25.8634 59.4989 24.8817 56.9842C23.6984 53.9182 23.927 51.7262 23.8328 47.9609C23.6978 44.1435 23.3521 40.3365 22.7974 36.5573C21.8291 29.6721 20.7802 30.5597 19.543 23.3786C18.0504 14.6511 17.2973 10.2941 17.4721 7.68528C17.5393 6.7036 17.7949 3.73169 15.9929 1.91626C15.8584 1.78178 14.7557 0.692515 14.0699 1.00181C13.142 1.41869 14.0699 3.90649 13.0344 7.72562C12.7654 8.76108 12.3486 10.2941 11.5552 10.4151C10.7618 10.5362 9.98179 8.50558 9.18838 8.6266C8.39497 8.74763 7.93775 12.0423 7.84362 13.2257C7.55422 16.4736 7.55422 19.7408 7.84362 22.9887C8.72412 28.5198 8.92284 34.138 8.43531 39.7175C7.88747 43.9614 6.99244 48.1531 5.75924 52.2507Z" fill="#DEE1E9"/>
        <path d="M28.1704 77.707C27.3232 78.8635 26.7449 78.6618 24.4723 80.6655C22.482 82.4271 22.6703 82.8037 21.0566 84.0812C19.6673 85.4609 17.8316 86.3 15.8792 86.448C14.5093 86.1429 13.2062 85.5915 12.0332 84.8208C10.1316 83.8767 8.30219 82.7935 6.56005 81.5799C5.06841 80.6857 3.71028 79.5856 2.52577 78.3122C0.374153 75.3133 1.11377 71.306 2.082 66.0345C2.3913 64.367 2.91575 62.4709 3.95122 58.6517C3.95122 58.6517 3.95122 58.5442 3.95122 58.4769C4.70428 56.7018 9.73369 54.1871 9.73369 54.1871C11.5884 53.165 13.6815 52.6545 15.7986 52.7079C17.7111 52.8098 19.5806 53.3138 21.2852 54.1871C22.4304 54.7119 23.5167 55.3565 24.5261 56.1101C24.6202 56.3925 24.7143 56.6884 24.8354 56.9977C25.7901 59.5124 26.4087 59.2703 27.5249 62.0271C28.5902 64.9687 29.092 68.0845 29.0041 71.2118C29.1386 74.6006 29.1386 76.3219 28.1704 77.707Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M15.592 64.0441C14.2472 64.1651 12.3646 59.2837 11.6519 54.6308L11.8267 41.4252C12.0149 37.2296 12.2301 33.8004 12.378 31.4605C12.7277 26.0815 12.8487 25.4495 12.8487 23.1768C12.8487 17.3271 15.0675 3.46657 15.5113 3.34555C15.9551 3.22452 13.9379 16.453 14.6103 19.1425C15.4575 22.5986 15.3096 24.5216 15.5113 29.4703C15.5332 33.2884 15.8344 37.0997 16.4123 40.8739C16.9771 43.9265 17.5554 45.3923 18.1874 49.7762C18.5463 51.804 18.6367 53.8703 18.4563 55.9217C18.3033 57.5719 17.9511 59.1974 17.4074 60.7629C16.5737 62.9011 16.1837 63.9903 15.592 64.0441Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M8.3244 20.5122C12.2912 18.643 14.5063 18.7282 18.4495 20.5122" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M5.81002 54.5808C5.3528 56.1811 4.84179 57.7948 3.98115 60.9819C2.94568 64.7875 2.42122 66.6971 2.11193 68.3512C1.1437 73.6226 0.390634 77.6435 2.5557 80.6423C3.74328 81.9086 5.10102 83.0039 6.58998 83.8966C8.3362 85.115 10.1701 86.2028 12.0766 87.1509C13.2463 87.9276 14.5506 88.4794 15.9226 88.7781C17.8768 88.6372 19.7148 87.7969 21.1 86.4113C22.7137 85.1472 22.512 84.7707 24.5022 83.009C26.7883 80.9919 27.3665 81.1936 28.2137 80.0506C29.2223 78.7058 29.1954 76.9307 29.1013 73.5285C29.1891 70.4056 28.6872 67.2942 27.622 64.3572C26.5193 61.6005 25.9142 61.8291 24.9325 59.3144C23.7491 56.2483 23.9777 54.0563 23.8836 50.291C23.7486 46.4737 23.4029 42.6667 22.8481 38.8874C21.8799 32.0023 20.831 32.8898 19.5938 25.7088C18.1011 16.9813 17.3481 12.6243 17.5229 10.0154C17.5901 9.03374 17.8456 6.06183 16.0437 4.2464C15.9092 4.11192 14.8065 3.02265 14.1206 3.33195C13.1928 3.74883 14.1206 6.23663 13.0852 10.0558C12.8162 11.0912 12.3994 12.6242 11.6059 12.7453C10.8125 12.8663 10.0326 10.8357 9.23916 10.9567C8.44575 11.0778 7.98853 14.3724 7.8944 15.5558C7.605 18.8037 7.605 22.0709 7.8944 25.3188C8.7749 30.8499 8.97362 36.4681 8.4861 42.0476C7.93825 46.2915 7.04323 50.4833 5.81002 54.5808Z" fill="#DEE1E9"/>
        <mask id="mask2214" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="30" height="36">
          <path d="M28.3051 80.204C27.4579 81.3605 26.8797 81.1588 24.607 83.1624C22.6168 84.9241 22.8051 85.3006 21.1913 86.5781C19.802 87.9578 17.9664 88.797 16.014 88.9449C14.6441 88.6398 13.3409 88.0885 12.168 87.3178C10.2663 86.3737 8.43695 85.2904 6.69482 84.0769C5.20317 83.1826 3.84505 82.0825 2.66054 80.8091C0.508919 77.8103 1.24854 73.8029 2.21677 68.5314C2.52606 66.8639 3.05052 64.9678 4.08598 61.1487C4.08598 61.1487 4.08598 61.0411 4.08598 60.9739C4.83905 59.1988 9.86846 56.6841 9.86846 56.6841C11.7232 55.662 13.8163 55.1514 15.9333 55.2048C17.8459 55.3068 19.7154 55.8108 21.42 56.6841C22.5651 57.2088 23.6515 57.8534 24.6608 58.6071C24.755 58.8895 24.8491 59.1853 24.9701 59.4946C25.9249 62.0093 26.5435 61.7673 27.6596 64.524C28.7249 67.4656 29.2267 70.5815 29.1389 73.7088C29.2734 77.0976 29.2734 78.8189 28.3051 80.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2214)">
          <path d="M28.3051 80.204C27.4579 81.3605 26.8797 81.1588 24.607 83.1624C22.6168 84.9241 22.8051 85.3006 21.1913 86.5781C19.802 87.9578 17.9664 88.797 16.014 88.9449C14.6441 88.6398 13.3409 88.0885 12.168 87.3178C10.2663 86.3737 8.43695 85.2904 6.69482 84.0769C5.20317 83.1826 3.84505 82.0825 2.66054 80.8091C0.508919 77.8103 1.24854 73.8029 2.21677 68.5314C2.52606 66.8639 3.05052 64.9678 4.08598 61.1487C4.08598 61.1487 4.08598 61.0411 4.08598 60.9739C4.83905 59.1988 9.86846 56.6841 9.86846 56.6841C11.7232 55.662 13.8163 55.1514 15.9333 55.2048C17.8459 55.3068 19.7154 55.8108 21.42 56.6841C22.5651 57.2088 23.6515 57.8534 24.6608 58.6071C24.755 58.8895 24.8491 59.1853 24.9701 59.4946C25.9249 62.0093 26.5435 61.7673 27.6596 64.524C28.7249 67.4656 29.2267 70.5815 29.1389 73.7088C29.2734 77.0976 29.2734 78.8189 28.3051 80.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M15.7809 72.201C14.8005 72.201 13.2661 80.6027 13.2661 90.201H15.1892H18.2661C18.2661 80.6027 16.7613 72.201 15.7809 72.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M15.9809 73.1986C14.0304 73.3342 11.2998 67.8658 10.2661 62.6534L10.5197 47.8601C10.7927 43.16 11.1048 39.3186 11.3193 36.6974C11.8265 30.6716 12.002 29.9635 12.002 27.4176C12.002 20.8646 15.2202 5.33755 15.8638 5.20197C16.5075 5.06639 13.5818 19.8854 14.5571 22.8983C15.7858 26.7699 15.5713 28.9241 15.8638 34.4678C15.8956 38.7449 16.3324 43.0146 17.1706 47.2425C17.9898 50.6621 18.8285 52.3041 19.7452 57.2152C20.2658 59.4868 20.3969 61.8015 20.1353 64.0996C19.9133 65.9482 19.4025 67.7691 18.6139 69.5228C17.4047 71.9181 16.839 73.1383 15.9809 73.1986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="15" cy="4.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M5.81002 58.5808C5.3528 60.1811 4.84179 61.7948 3.98115 64.9819C2.94568 68.7875 2.42122 70.6971 2.11193 72.3512C1.1437 77.6226 0.390634 81.6435 2.5557 84.6423C3.74328 85.9086 5.10102 87.0039 6.58998 87.8966C8.3362 89.115 10.1701 90.2028 12.0766 91.1509C13.2463 91.9276 14.5506 92.4794 15.9226 92.7781C17.8768 92.6372 19.7148 91.7969 21.1 90.4113C22.7137 89.1472 22.512 88.7707 24.5022 87.009C26.7883 84.9919 27.3665 85.1936 28.2137 84.0506C29.2223 82.7058 29.1954 80.9307 29.1013 77.5285C29.1891 74.4056 28.6872 71.2942 27.622 68.3572C26.5193 65.6005 25.9142 65.8291 24.9325 63.3144C23.7491 60.2483 23.9777 58.0563 23.8836 54.291C23.7486 50.4737 23.4029 46.6667 22.8481 42.8874C21.8799 36.0023 20.831 36.8898 19.5938 29.7088C18.1011 20.9813 17.3481 16.6243 17.5229 14.0154C17.5901 13.0337 17.8456 10.0618 16.0437 8.2464C15.9092 8.11192 14.8065 7.02265 14.1206 7.33195C13.1928 7.74883 14.1206 10.2366 13.0852 14.0558C12.8162 15.0912 12.3994 16.6242 11.6059 16.7453C10.8125 16.8663 10.0326 14.8357 9.23916 14.9567C8.44575 15.0778 7.98853 18.3724 7.8944 19.5558C7.605 22.8037 7.605 26.0709 7.8944 29.3188C8.7749 34.8499 8.97362 40.4681 8.4861 46.0476C7.93825 50.2915 7.04323 54.4833 5.81002 58.5808Z" fill="#DEE1E9"/>
        <mask id="mask2314" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="58" width="30" height="36">
          <path d="M28.3051 84.204C27.4579 85.3605 26.8797 85.1588 24.607 87.1624C22.6168 88.9241 22.8051 89.3006 21.1913 90.5781C19.802 91.9578 17.9664 92.797 16.014 92.9449C14.6441 92.6398 13.3409 92.0885 12.168 91.3178C10.2663 90.3737 8.43695 89.2904 6.69482 88.0769C5.20317 87.1826 3.84505 86.0825 2.66054 84.8091C0.508919 81.8103 1.24854 77.8029 2.21677 72.5314C2.52606 70.8639 3.05052 68.9678 4.08598 65.1487C4.08598 65.1487 4.08598 65.0411 4.08598 64.9739C4.83905 63.1988 9.86846 60.6841 9.86846 60.6841C11.7232 59.662 13.8163 59.1514 15.9333 59.2048C17.8459 59.3068 19.7154 59.8108 21.42 60.6841C22.5651 61.2088 23.6515 61.8534 24.6608 62.6071C24.755 62.8895 24.8491 63.1853 24.9701 63.4946C25.9249 66.0093 26.5435 65.7673 27.6596 68.524C28.7249 71.4656 29.2267 74.5815 29.1389 77.7088C29.2734 81.0976 29.2734 82.8189 28.3051 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2314)">
          <path d="M28.3051 84.204C27.4579 85.3605 26.8797 85.1588 24.607 87.1624C22.6168 88.9241 22.8051 89.3006 21.1913 90.5781C19.802 91.9578 17.9664 92.797 16.014 92.9449C14.6441 92.6398 13.3409 92.0885 12.168 91.3178C10.2663 90.3737 8.43695 89.2904 6.69482 88.0769C5.20317 87.1826 3.84505 86.0825 2.66054 84.8091C0.508919 81.8103 1.24854 77.8029 2.21677 72.5314C2.52606 70.8639 3.05052 68.9678 4.08598 65.1487C4.08598 65.1487 4.08598 65.0411 4.08598 64.9739C4.83905 63.1988 9.86846 60.6841 9.86846 60.6841C11.7232 59.662 13.8163 59.1514 15.9333 59.2048C17.8459 59.3068 19.7154 59.8108 21.42 60.6841C22.5651 61.2088 23.6515 61.8534 24.6608 62.6071C24.755 62.8895 24.8491 63.1853 24.9701 63.4946C25.9249 66.0093 26.5435 65.7673 27.6596 68.524C28.7249 71.4656 29.2267 74.5815 29.1389 77.7088C29.2734 81.0976 29.2734 82.8189 28.3051 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M15.7809 76.201C14.8005 76.201 13.2661 84.6027 13.2661 94.201H15.1892H18.2661C18.2661 84.6027 16.7613 76.201 15.7809 76.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M15.9809 77.1986C14.0304 77.3342 11.2998 71.8658 10.2661 66.6534L10.5197 51.8601C10.7927 47.16 11.1048 43.3186 11.3193 40.6974C11.8265 34.6716 12.002 33.9635 12.002 31.4176C12.002 24.8646 15.2202 9.33755 15.8638 9.20197C16.5075 9.06639 13.5818 23.8854 14.5571 26.8983C15.7858 30.7699 15.5713 32.9241 15.8638 38.4678C15.8956 42.7449 16.3324 47.0146 17.1706 51.2425C17.9898 54.6621 18.8285 56.3041 19.7452 61.2152C20.2658 63.4868 20.3969 65.8015 20.1353 68.0996C19.9133 69.9482 19.4025 71.7691 18.6139 73.5228C17.4047 75.9181 16.839 77.1383 15.9809 77.1986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="15" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2314)">
          <path d="M15 0.200989L15.8229 3.94009L18.8567 1.60472L17.0837 4.99799L20.9088 5.1591L17.3694 6.61879L20.1962 9.20099L16.5465 8.04409L17.0521 11.8391L15 8.60699L12.9479 11.8391L13.4535 8.04409L9.80385 9.20099L12.6306 6.61879L9.09115 5.1591L12.9163 4.99799L11.1433 1.60472L14.1771 3.94009L15 0.200989Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2314" x="8.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.81002 58.5808C5.3528 60.1811 4.84179 61.7948 3.98115 64.9819C2.94568 68.7875 2.42122 70.6971 2.11193 72.3512C1.1437 77.6226 0.390634 81.6435 2.5557 84.6423C3.74328 85.9086 5.10102 87.0039 6.58998 87.8966C8.3362 89.115 10.1701 90.2028 12.0766 91.1509C13.2463 91.9276 14.5506 92.4794 15.9226 92.7781C17.8768 92.6372 19.7148 91.7969 21.1 90.4113C22.7137 89.1472 22.512 88.7707 24.5022 87.009C26.7883 84.9919 27.3665 85.1936 28.2137 84.0506C29.2223 82.7058 29.1954 80.9307 29.1013 77.5285C29.1891 74.4056 28.6872 71.2942 27.622 68.3572C26.5193 65.6005 25.9142 65.8291 24.9325 63.3144C23.7491 60.2483 23.9777 58.0563 23.8836 54.291C23.7486 50.4737 23.4029 46.6667 22.8481 42.8874C21.8799 36.0023 20.831 36.8898 19.5938 29.7088C18.1011 20.9813 17.3481 16.6243 17.5229 14.0154C17.5901 13.0337 17.8456 10.0618 16.0437 8.2464C15.9092 8.11192 14.8065 7.02265 14.1206 7.33195C13.1928 7.74883 14.1206 10.2366 13.0852 14.0558C12.8162 15.0912 12.3994 16.6242 11.6059 16.7453C10.8125 16.8663 10.0326 14.8357 9.23916 14.9567C8.44575 15.0778 7.98853 18.3724 7.8944 19.5558C7.605 22.8037 7.605 26.0709 7.8944 29.3188C8.7749 34.8499 8.97362 40.4681 8.4861 46.0476C7.93825 50.2915 7.04323 54.4833 5.81002 58.5808Z" fill="#DEE1E9"/>
      <mask id="mask2314" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="58" width="30" height="36">
        <path d="M28.3051 84.204C27.4579 85.3605 26.8797 85.1588 24.607 87.1624C22.6168 88.9241 22.8051 89.3006 21.1913 90.5781C19.802 91.9578 17.9664 92.797 16.014 92.9449C14.6441 92.6398 13.3409 92.0885 12.168 91.3178C10.2663 90.3737 8.43695 89.2904 6.69482 88.0769C5.20317 87.1826 3.84505 86.0825 2.66054 84.8091C0.508919 81.8103 1.24854 77.8029 2.21677 72.5314C2.52606 70.8639 3.05052 68.9678 4.08598 65.1487C4.08598 65.1487 4.08598 65.0411 4.08598 64.9739C4.83905 63.1988 9.86846 60.6841 9.86846 60.6841C11.7232 59.662 13.8163 59.1514 15.9333 59.2048C17.8459 59.3068 19.7154 59.8108 21.42 60.6841C22.5651 61.2088 23.6515 61.8534 24.6608 62.6071C24.755 62.8895 24.8491 63.1853 24.9701 63.4946C25.9249 66.0093 26.5435 65.7673 27.6596 68.524C28.7249 71.4656 29.2267 74.5815 29.1389 77.7088C29.2734 81.0976 29.2734 82.8189 28.3051 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2314)">
        <path d="M28.3051 84.204C27.4579 85.3605 26.8797 85.1588 24.607 87.1624C22.6168 88.9241 22.8051 89.3006 21.1913 90.5781C19.802 91.9578 17.9664 92.797 16.014 92.9449C14.6441 92.6398 13.3409 92.0885 12.168 91.3178C10.2663 90.3737 8.43695 89.2904 6.69482 88.0769C5.20317 87.1826 3.84505 86.0825 2.66054 84.8091C0.508919 81.8103 1.24854 77.8029 2.21677 72.5314C2.52606 70.8639 3.05052 68.9678 4.08598 65.1487C4.08598 65.1487 4.08598 65.0411 4.08598 64.9739C4.83905 63.1988 9.86846 60.6841 9.86846 60.6841C11.7232 59.662 13.8163 59.1514 15.9333 59.2048C17.8459 59.3068 19.7154 59.8108 21.42 60.6841C22.5651 61.2088 23.6515 61.8534 24.6608 62.6071C24.755 62.8895 24.8491 63.1853 24.9701 63.4946C25.9249 66.0093 26.5435 65.7673 27.6596 68.524C28.7249 71.4656 29.2267 74.5815 29.1389 77.7088C29.2734 81.0976 29.2734 82.8189 28.3051 84.204Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M15.7809 76.201C14.8005 76.201 13.2661 84.6027 13.2661 94.201H15.1892H18.2661C18.2661 84.6027 16.7613 76.201 15.7809 76.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M15.9809 77.1986C14.0304 77.3342 11.2998 71.8658 10.2661 66.6534L10.5197 51.8601C10.7927 47.16 11.1048 43.3186 11.3193 40.6974C11.8265 34.6716 12.002 33.9635 12.002 31.4176C12.002 24.8646 15.2202 9.33755 15.8638 9.20197C16.5075 9.06639 13.5818 23.8854 14.5571 26.8983C15.7858 30.7699 15.5713 32.9241 15.8638 38.4678C15.8956 42.7449 16.3324 47.0146 17.1706 51.2425C17.9898 54.6621 18.8285 56.3041 19.7452 61.2152C20.2658 63.4868 20.3969 65.8015 20.1353 68.0996C19.9133 69.9482 19.4025 71.7691 18.6139 73.5228C17.4047 75.9181 16.839 77.1383 15.9809 77.1986Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="15" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2314)">
        <path d="M15 0.200989L15.8229 3.94009L18.8567 1.60472L17.0837 4.99799L20.9088 5.1591L17.3694 6.61879L20.1962 9.20099L16.5465 8.04409L17.0521 11.8391L15 8.60699L12.9479 11.8391L13.4535 8.04409L9.80385 9.20099L12.6306 6.61879L9.09115 5.1591L12.9163 4.99799L11.1433 1.60472L14.1771 3.94009L15 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2314" x="8.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M5.81002 54.5807C5.3528 56.181 4.84179 57.7947 3.98115 60.9818C2.94568 64.7875 2.42122 66.697 2.11193 68.3511C1.1437 73.6226 0.390634 77.6434 2.5557 80.6422C3.74328 81.9086 5.10102 83.0038 6.58998 83.8965C8.3362 85.115 10.1701 86.2027 12.0766 87.1509C13.2463 87.9276 14.5506 88.4794 15.9226 88.778C17.8768 88.6371 19.7148 87.7969 21.1 86.4112C22.7137 85.1472 22.512 84.7706 24.5022 83.009C26.7883 80.9918 27.3665 81.1936 28.2137 80.0505C29.2223 78.7058 29.1954 76.9307 29.1013 73.5284C29.1891 70.4055 28.6872 67.2941 27.622 64.3572C26.5193 61.6004 25.9142 61.829 24.9325 59.3143C23.7491 56.2482 23.9777 54.0563 23.8836 50.2909C23.7486 46.4736 23.4029 42.6666 22.8481 38.8874C21.8799 32.0022 20.831 32.8897 19.5938 25.7087C18.1011 16.9812 17.3481 12.6242 17.5229 10.0154C17.5901 9.03368 17.8456 6.06176 16.0437 4.24634C15.9092 4.11186 14.8065 3.02259 14.1206 3.33189C13.1928 3.74876 14.1206 6.23657 13.0852 10.0557C12.8162 11.0912 12.3994 12.6242 11.6059 12.7452C10.8125 12.8662 10.0326 10.8357 9.23916 10.9567C8.44575 11.0777 7.98853 14.3724 7.8944 15.5558C7.605 18.8036 7.605 22.0709 7.8944 25.3187C8.7749 30.8498 8.97362 36.4681 8.4861 42.0476C7.93825 46.2914 7.04323 50.4832 5.81002 54.5807Z" fill="#DEE1E9"/>
        <circle cx="15" cy="4.20093" r="4" fill="rgba(243, 42, 102, 0.6)"/>
        <mask id="mask2514" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="30" height="36">
          <path d="M28.3051 80.2038C27.4579 81.3603 26.8797 81.1586 24.607 83.1623C22.6168 84.924 22.8051 85.3005 21.1913 86.578C19.802 87.9577 17.9664 88.7968 16.014 88.9448C14.6441 88.6397 13.3409 88.0884 12.168 87.3176C10.2663 86.3735 8.43695 85.2903 6.69482 84.0768C5.20317 83.1825 3.84505 82.0824 2.66054 80.809C0.508919 77.8102 1.24854 73.8028 2.21677 68.5313C2.52606 66.8638 3.05052 64.9677 4.08598 61.1486C4.08598 61.1486 4.08598 61.041 4.08598 60.9738C4.83905 59.1987 9.86846 56.684 9.86846 56.684C11.7232 55.6618 13.8163 55.1513 15.9333 55.2047C17.8459 55.3066 19.7154 55.8107 21.42 56.684C22.5651 57.2087 23.6515 57.8533 24.6608 58.607C24.755 58.8894 24.8491 59.1852 24.9701 59.4945C25.9249 62.0092 26.5435 61.7672 27.6596 64.5239C28.7249 67.4655 29.2267 70.5813 29.1389 73.7086C29.2734 77.0974 29.2734 78.8187 28.3051 80.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2514)">
          <path d="M28.3051 80.2038C27.4579 81.3603 26.8797 81.1586 24.607 83.1623C22.6168 84.924 22.8051 85.3005 21.1913 86.578C19.802 87.9577 17.9664 88.7968 16.014 88.9448C14.6441 88.6397 13.3409 88.0884 12.168 87.3176C10.2663 86.3735 8.43695 85.2903 6.69482 84.0768C5.20317 83.1825 3.84505 82.0824 2.66054 80.809C0.508919 77.8102 1.24854 73.8028 2.21677 68.5313C2.52606 66.8638 3.05052 64.9677 4.08598 61.1486C4.08598 61.1486 4.08598 61.041 4.08598 60.9738C4.83905 59.1987 9.86846 56.684 9.86846 56.684C11.7232 55.6618 13.8163 55.1513 15.9333 55.2047C17.8459 55.3066 19.7154 55.8107 21.42 56.684C22.5651 57.2087 23.6515 57.8533 24.6608 58.607C24.755 58.8894 24.8491 59.1852 24.9701 59.4945C25.9249 62.0092 26.5435 61.7672 27.6596 64.5239C28.7249 67.4655 29.2267 70.5813 29.1389 73.7086C29.2734 77.0974 29.2734 78.8187 28.3051 80.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M15.5148 79.2009C14.5344 79.2009 13 84.3353 13 90.2009H14.9231H18C18 84.3353 16.4952 79.2009 15.5148 79.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M15.7147 79.1983C13.7643 79.3438 11.0337 73.4733 10 67.8778L10.2536 51.9967C10.5266 46.951 10.8387 42.8271 11.0532 40.0131C11.5603 33.5443 11.7359 32.7842 11.7359 30.0511C11.7359 23.0162 14.9541 6.34746 15.5977 6.20191C16.2414 6.05636 13.3157 21.965 14.2909 25.1994C15.5197 29.3557 15.3052 31.6683 15.5977 37.6196C15.6295 42.2113 16.0663 46.7948 16.9045 51.3337C17.7237 55.0047 18.5624 56.7675 19.4791 62.0396C19.9997 64.4783 20.1308 66.9632 19.8692 69.4303C19.6472 71.4147 19.1364 73.3696 18.3478 75.2523C17.1386 77.8236 16.5729 79.1336 15.7147 79.1983Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2614" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="87">
          <path d="M5.89864 51.3703C5.44143 52.9706 4.93042 54.5843 4.06977 57.7713C3.0343 61.577 2.50985 63.4866 2.20055 65.1406C1.23232 70.4121 0.479257 74.4329 2.64432 77.4318C3.8319 78.6981 5.18964 79.7934 6.67861 80.6861C8.42482 81.9045 10.2587 82.9923 12.1652 83.9404C13.335 84.7171 14.6393 85.2689 16.0112 85.5676C17.9654 85.4267 19.8034 84.5864 21.1886 83.2008C22.8023 81.9367 22.6006 81.5602 24.5908 79.7985C26.8769 77.7814 27.4552 77.9831 28.3024 76.8401C29.3109 75.4953 29.284 73.7202 29.1899 70.318C29.2777 67.1951 28.7758 64.0836 27.7107 61.1467C26.608 58.3899 26.0028 58.6186 25.0211 56.1039C23.8378 53.0378 24.0664 50.8458 23.9722 47.0805C23.8372 43.2631 23.4915 39.4562 22.9368 35.6769C21.9685 28.7917 20.9196 29.6793 19.6824 22.4983C18.1898 13.7708 17.4367 9.41374 17.6115 6.8049C17.6788 5.82323 17.9343 2.85132 16.1323 1.03589C15.9978 0.901411 14.8951 -0.187856 14.2093 0.121439C13.2814 0.538315 14.2093 3.02612 13.1738 6.84524C12.9048 7.88071 12.488 9.41374 11.6946 9.53477C10.9012 9.6558 10.1212 7.6252 9.32779 7.74623C8.53438 7.86726 8.07716 11.1619 7.98302 12.3453C7.69362 15.5932 7.69362 18.8604 7.98302 22.1083C8.86353 27.6394 9.06224 33.2576 8.57472 38.8371C8.02687 43.081 7.13185 47.2728 5.89864 51.3703Z" fill="#DEE1E9"/>
          <path d="M28.3098 76.8266C27.4626 77.9831 26.8843 77.7814 24.6117 79.7851C22.6214 81.5468 22.8097 81.9233 21.196 83.2008C19.8067 84.5805 17.971 85.4196 16.0187 85.5676C14.6487 85.2625 13.3456 84.7112 12.1726 83.9404C10.271 82.9963 8.44159 81.9131 6.69946 80.6996C5.20781 79.8053 3.84969 78.7052 2.66518 77.4318C0.513558 74.433 1.25318 70.4256 2.2214 65.1541C2.5307 63.4866 3.05516 61.5905 4.09062 57.7714C4.09062 57.7714 4.09062 57.6638 4.09062 57.5966C4.84369 55.8215 9.87309 53.3068 9.87309 53.3068C11.7278 52.2846 13.8209 51.7741 15.938 51.8275C17.8505 51.9294 19.72 52.4335 21.4246 53.3068C22.5698 53.8315 23.6561 54.4761 24.6655 55.2298C24.7596 55.5122 24.8537 55.808 24.9748 56.1173C25.9295 58.632 26.5481 58.39 27.6643 61.1467C28.7296 64.0883 29.2314 67.2041 29.1435 70.3314C29.278 73.7202 29.278 75.4415 28.3098 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2614)">
          <path d="M5.89864 51.3703C5.44143 52.9706 4.93042 54.5843 4.06977 57.7713C3.0343 61.577 2.50985 63.4866 2.20055 65.1406C1.23232 70.4121 0.479257 74.4329 2.64432 77.4318C3.8319 78.6981 5.18964 79.7934 6.67861 80.6861C8.42482 81.9045 10.2587 82.9923 12.1652 83.9404C13.335 84.7171 14.6393 85.2689 16.0112 85.5676C17.9654 85.4267 19.8034 84.5864 21.1886 83.2008C22.8023 81.9367 22.6006 81.5602 24.5908 79.7985C26.8769 77.7814 27.4552 77.9831 28.3024 76.8401C29.3109 75.4953 29.284 73.7202 29.1899 70.318C29.2777 67.1951 28.7758 64.0836 27.7107 61.1467C26.608 58.3899 26.0028 58.6186 25.0211 56.1039C23.8378 53.0378 24.0664 50.8458 23.9722 47.0805C23.8372 43.2631 23.4915 39.4562 22.9368 35.6769C21.9685 28.7917 20.9196 29.6793 19.6824 22.4983C18.1898 13.7708 17.4367 9.41374 17.6115 6.8049C17.6788 5.82323 17.9343 2.85132 16.1323 1.03589C15.9978 0.901411 14.8951 -0.187856 14.2093 0.121439C13.2814 0.538315 14.2093 3.02612 13.1738 6.84524C12.9048 7.88071 12.488 9.41374 11.6946 9.53477C10.9012 9.6558 10.1212 7.6252 9.32779 7.74623C8.53438 7.86726 8.07716 11.1619 7.98302 12.3453C7.69362 15.5932 7.69362 18.8604 7.98302 22.1083C8.86353 27.6394 9.06224 33.2576 8.57472 38.8371C8.02687 43.081 7.13185 47.2728 5.89864 51.3703Z" fill="#DEE1E9"/>
          <path d="M28.3098 76.8266C27.4626 77.9831 26.8843 77.7814 24.6117 79.7851C22.6214 81.5468 22.8097 81.9233 21.196 83.2008C19.8067 84.5805 17.971 85.4196 16.0187 85.5676C14.6487 85.2625 13.3456 84.7112 12.1726 83.9404C10.271 82.9963 8.44159 81.9131 6.69946 80.6996C5.20781 79.8053 3.84969 78.7052 2.66518 77.4318C0.513558 74.433 1.25318 70.4256 2.2214 65.1541C2.5307 63.4866 3.05516 61.5905 4.09062 57.7714C4.09062 57.7714 4.09062 57.6638 4.09062 57.5966C4.84369 55.8215 9.87309 53.3068 9.87309 53.3068C11.7278 52.2846 13.8209 51.7741 15.938 51.8275C17.8505 51.9294 19.72 52.4335 21.4246 53.3068C22.5698 53.8315 23.6561 54.4761 24.6655 55.2298C24.7596 55.5122 24.8537 55.808 24.9748 56.1173C25.9295 58.632 26.5481 58.39 27.6643 61.1467C28.7296 64.0883 29.2314 67.2041 29.1435 70.3314C29.278 73.7202 29.278 75.4415 28.3098 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M17.0259 55.5247H28.9471C26.785 50.1549 26.428 46.8947 26.7245 41.1564C26.7357 40.94 26.4864 40.8054 26.2961 40.932C25.2921 41.6 22.9339 43.4161 22.3622 46.0577C21.6698 49.2577 19.6665 50.0348 19.4539 50.1089C19.441 50.1134 19.4294 50.1179 19.417 50.1236L18.2341 50.6703C18.0895 50.7371 18.0309 50.8996 18.1032 51.0332L18.6321 52.0109C18.6769 52.0936 18.6728 52.1917 18.6213 52.271L16.7749 55.1151C16.6579 55.2954 16.7984 55.5247 17.0259 55.5247Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M25.951 42.0459C24.89 42.8938 23.4821 44.3307 23.0797 46.1903C22.3364 49.6248 20.1605 50.5809 19.7275 50.7361L18.9539 51.0936L19.2865 51.7084C19.4431 51.9979 19.4289 52.3413 19.2487 52.6189L17.8012 54.8485H27.9038C26.1271 50.1838 25.7511 46.9881 25.951 42.0459ZM19.7442 50.7284C19.7444 50.7283 19.7441 50.7285 19.7442 50.7284V50.7284ZM25.8687 40.3832C26.5976 39.8983 27.4933 40.4547 27.4554 41.1886C27.1626 46.8548 27.5118 50.0209 29.6328 55.2889L30 56.2009H17.0259C16.2297 56.2009 15.7379 55.3982 16.1476 54.7672L17.8699 52.114L17.4488 51.3356C17.1958 50.8679 17.4009 50.2992 17.9069 50.0654L19.0898 49.5188C19.1305 49.5 19.1664 49.4864 19.1959 49.4761C19.2325 49.4634 21.0133 48.8432 21.6448 45.9251C22.2743 43.0161 24.8377 41.0691 25.8687 40.3832Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask114" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="87">
          <path d="M5.89864 51.3703C5.44143 52.9706 4.93042 54.5843 4.06977 57.7713C3.0343 61.577 2.50985 63.4866 2.20055 65.1406C1.23232 70.4121 0.479257 74.4329 2.64432 77.4318C3.8319 78.6981 5.18964 79.7934 6.67861 80.6861C8.42482 81.9045 10.2587 82.9923 12.1652 83.9404C13.335 84.7171 14.6393 85.2689 16.0112 85.5676C17.9654 85.4267 19.8034 84.5864 21.1886 83.2008C22.8023 81.9367 22.6006 81.5602 24.5908 79.7985C26.8769 77.7814 27.4552 77.9831 28.3024 76.8401C29.3109 75.4953 29.284 73.7202 29.1899 70.318C29.2777 67.1951 28.7758 64.0836 27.7107 61.1467C26.608 58.3899 26.0028 58.6186 25.0211 56.1039C23.8378 53.0378 24.0664 50.8458 23.9722 47.0805C23.8372 43.2631 23.4915 39.4562 22.9368 35.6769C21.9685 28.7917 20.9196 29.6793 19.6824 22.4983C18.1898 13.7708 17.4367 9.41374 17.6115 6.8049C17.6788 5.82323 17.9343 2.85132 16.1323 1.03589C15.9978 0.901411 14.8951 -0.187856 14.2093 0.121439C13.2814 0.538315 14.2093 3.02612 13.1738 6.84524C12.9048 7.88071 12.488 9.41374 11.6946 9.53477C10.9012 9.6558 10.1212 7.6252 9.32779 7.74623C8.53438 7.86726 8.07716 11.1619 7.98302 12.3453C7.69362 15.5932 7.69362 18.8604 7.98302 22.1083C8.86353 27.6394 9.06224 33.2576 8.57472 38.8371C8.02687 43.081 7.13185 47.2728 5.89864 51.3703Z" fill="#DEE1E9"/>
          <path d="M28.3098 76.8266C27.4626 77.9831 26.8843 77.7814 24.6117 79.7851C22.6214 81.5468 22.8097 81.9233 21.196 83.2008C19.8067 84.5805 17.971 85.4196 16.0187 85.5676C14.6487 85.2625 13.3456 84.7112 12.1726 83.9404C10.271 82.9963 8.44159 81.9131 6.69946 80.6996C5.20781 79.8053 3.84969 78.7052 2.66518 77.4318C0.513558 74.433 1.25318 70.4256 2.2214 65.1541C2.5307 63.4866 3.05516 61.5905 4.09062 57.7714C4.09062 57.7714 4.09062 57.6638 4.09062 57.5966C4.84369 55.8215 9.87309 53.3068 9.87309 53.3068C11.7278 52.2846 13.8209 51.7741 15.938 51.8275C17.8505 51.9294 19.72 52.4335 21.4246 53.3068C22.5698 53.8315 23.6561 54.4761 24.6655 55.2298C24.7596 55.5122 24.8537 55.808 24.9748 56.1173C25.9295 58.632 26.5481 58.39 27.6643 61.1467C28.7296 64.0883 29.2314 67.2041 29.1435 70.3314C29.278 73.7202 29.278 75.4415 28.3098 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask114)">
          <path d="M5.89864 51.3703C5.44143 52.9706 4.93042 54.5843 4.06977 57.7713C3.0343 61.577 2.50985 63.4866 2.20055 65.1406C1.23232 70.4121 0.479257 74.4329 2.64432 77.4318C3.8319 78.6981 5.18964 79.7934 6.67861 80.6861C8.42482 81.9045 10.2587 82.9923 12.1652 83.9404C13.335 84.7171 14.6393 85.2689 16.0112 85.5676C17.9654 85.4267 19.8034 84.5864 21.1886 83.2008C22.8023 81.9367 22.6006 81.5602 24.5908 79.7985C26.8769 77.7814 27.4552 77.9831 28.3024 76.8401C29.3109 75.4953 29.284 73.7202 29.1899 70.318C29.2777 67.1951 28.7758 64.0836 27.7107 61.1467C26.608 58.3899 26.0028 58.6186 25.0211 56.1039C23.8378 53.0378 24.0664 50.8458 23.9722 47.0805C23.8372 43.2631 23.4915 39.4562 22.9368 35.6769C21.9685 28.7917 20.9196 29.6793 19.6824 22.4983C18.1898 13.7708 17.4367 9.41374 17.6115 6.8049C17.6788 5.82323 17.9343 2.85132 16.1323 1.03589C15.9978 0.901411 14.8951 -0.187856 14.2093 0.121439C13.2814 0.538315 14.2093 3.02612 13.1738 6.84524C12.9048 7.88071 12.488 9.41374 11.6946 9.53477C10.9012 9.6558 10.1212 7.6252 9.32779 7.74623C8.53438 7.86726 8.07716 11.1619 7.98302 12.3453C7.69362 15.5932 7.69362 18.8604 7.98302 22.1083C8.86353 27.6394 9.06224 33.2576 8.57472 38.8371C8.02687 43.081 7.13185 47.2728 5.89864 51.3703Z" fill="#DEE1E9"/>
          <path d="M28.3098 76.8266C27.4626 77.9831 26.8843 77.7814 24.6117 79.7851C22.6214 81.5468 22.8097 81.9233 21.196 83.2008C19.8067 84.5805 17.971 85.4196 16.0187 85.5676C14.6487 85.2625 13.3456 84.7112 12.1726 83.9404C10.271 82.9963 8.44159 81.9131 6.69946 80.6996C5.20781 79.8053 3.84969 78.7052 2.66518 77.4318C0.513558 74.433 1.25318 70.4256 2.2214 65.1541C2.5307 63.4866 3.05516 61.5905 4.09062 57.7714C4.09062 57.7714 4.09062 57.6638 4.09062 57.5966C4.84369 55.8215 9.87309 53.3068 9.87309 53.3068C11.7278 52.2846 13.8209 51.7741 15.938 51.8275C17.8505 51.9294 19.72 52.4335 21.4246 53.3068C22.5698 53.8315 23.6561 54.4761 24.6655 55.2298C24.7596 55.5122 24.8537 55.808 24.9748 56.1173C25.9295 58.632 26.5481 58.39 27.6643 61.1467C28.7296 64.0883 29.2314 67.2041 29.1435 70.3314C29.278 73.7202 29.278 75.4415 28.3098 76.8266Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M18.7011 54.5315H26.8479C25.3703 50.561 25.1264 48.1504 25.329 43.9074C25.3367 43.7474 25.1662 43.6479 25.0362 43.7415C24.3501 44.2354 22.7385 45.5782 22.3479 47.5315C21.8746 49.8976 20.5057 50.4722 20.3604 50.527C20.3515 50.5303 20.3436 50.5337 20.3352 50.5379L19.5267 50.9421C19.428 50.9915 19.3879 51.1116 19.4373 51.2104L19.7988 51.9333C19.8293 51.9945 19.8266 52.067 19.7914 52.1257L18.5296 54.2286C18.4496 54.3619 18.5456 54.5315 18.7011 54.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M24.8004 44.5651C24.0753 45.1921 23.1131 46.2545 22.8381 47.6295C22.3302 50.169 20.8432 50.876 20.5473 50.9908L20.0187 51.2551L20.246 51.7097C20.353 51.9238 20.3432 52.1777 20.2201 52.3829L19.2309 54.0315H26.1349C24.9207 50.5824 24.6637 48.2194 24.8004 44.5651ZM20.5587 50.9851C20.5588 50.985 20.5586 50.9851 20.5587 50.9851V50.9851ZM24.7441 43.3357C25.2422 42.9771 25.8543 43.3885 25.8284 43.9312C25.6283 48.1208 25.867 50.4619 27.3164 54.3571L27.5674 55.0315H18.7011C18.157 55.0315 17.8209 54.4379 18.1008 53.9713L19.2779 52.0096L18.9901 51.434C18.8172 51.0882 18.9573 50.6677 19.3031 50.4948L20.1115 50.0906C20.1393 50.0767 20.1638 50.0667 20.184 50.0591C20.209 50.0497 21.426 49.5911 21.8576 47.4334C22.2877 45.2825 24.0395 43.8428 24.7441 43.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M5.81002 51.5807C5.3528 53.181 4.84179 54.7947 3.98115 57.9818C2.94568 61.7875 2.42122 63.697 2.11193 65.3511C1.1437 70.6226 0.390634 74.6434 2.5557 77.6422C3.74328 78.9086 5.10102 80.0038 6.58998 80.8965C8.3362 82.115 10.1701 83.2027 12.0766 84.1509C13.2463 84.9276 14.5506 85.4794 15.9226 85.778C17.8768 85.6371 19.7148 84.7969 21.1 83.4112C22.7137 82.1472 22.512 81.7706 24.5022 80.009C26.7883 77.9918 27.3665 78.1936 28.2137 77.0505C29.2223 75.7058 29.1954 73.9307 29.1013 70.5284C29.1891 67.4055 28.6872 64.2941 27.622 61.3572C26.5193 58.6004 25.9142 58.829 24.9325 56.3143C23.7491 53.2482 23.9777 51.0563 23.8836 47.2909C23.7486 43.4736 23.4029 39.6666 22.8481 35.8874C21.8799 29.0022 20.831 29.8897 19.5938 22.7087C18.1011 13.9812 17.3481 9.62419 17.5229 7.01535C17.5901 6.03368 17.8456 3.06176 16.0437 1.24634C15.9092 1.11186 14.8065 0.0225935 14.1206 0.331889C13.1928 0.748765 14.1206 3.23657 13.0852 7.05569C12.8162 8.09116 12.3994 9.62419 11.6059 9.74522C10.8125 9.86625 10.0326 7.83565 9.23916 7.95668C8.44575 8.07771 7.98853 11.3724 7.8944 12.5558C7.605 15.8036 7.605 19.0709 7.8944 22.3187C8.7749 27.8498 8.97362 33.4681 8.4861 39.0476C7.93825 43.2914 7.04323 47.4832 5.81002 51.5807Z" fill="#DEE1E9"/>
        <mask id="mask214" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="30" height="36">
          <path d="M28.3051 77.2038C27.4579 78.3603 26.8797 78.1586 24.607 80.1623C22.6168 81.924 22.8051 82.3005 21.1913 83.578C19.802 84.9577 17.9664 85.7968 16.014 85.9448C14.6441 85.6397 13.3409 85.0884 12.168 84.3176C10.2663 83.3735 8.43695 82.2903 6.69482 81.0768C5.20317 80.1825 3.84505 79.0824 2.66054 77.809C0.508919 74.8102 1.24854 70.8028 2.21677 65.5313C2.52606 63.8638 3.05052 61.9677 4.08598 58.1486C4.08598 58.1486 4.08598 58.041 4.08598 57.9738C4.83905 56.1987 9.86846 53.684 9.86846 53.684C11.7232 52.6618 13.8163 52.1513 15.9333 52.2047C17.8459 52.3066 19.7154 52.8107 21.42 53.684C22.5651 54.2087 23.6515 54.8533 24.6608 55.607C24.755 55.8894 24.8491 56.1852 24.9701 56.4945C25.9249 59.0092 26.5435 58.7672 27.6596 61.5239C28.7249 64.4655 29.2267 67.5813 29.1389 70.7086C29.2734 74.0974 29.2734 75.8187 28.3051 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask214)">
          <path d="M28.3051 77.2038C27.4579 78.3603 26.8797 78.1586 24.607 80.1623C22.6168 81.924 22.8051 82.3005 21.1913 83.578C19.802 84.9577 17.9664 85.7968 16.014 85.9448C14.6441 85.6397 13.3409 85.0884 12.168 84.3176C10.2663 83.3735 8.43695 82.2903 6.69482 81.0768C5.20317 80.1825 3.84505 79.0824 2.66054 77.809C0.508919 74.8102 1.24854 70.8028 2.21677 65.5313C2.52606 63.8638 3.05052 61.9677 4.08598 58.1486C4.08598 58.1486 4.08598 58.041 4.08598 57.9738C4.83905 56.1987 9.86846 53.684 9.86846 53.684C11.7232 52.6618 13.8163 52.1513 15.9333 52.2047C17.8459 52.3066 19.7154 52.8107 21.42 53.684C22.5651 54.2087 23.6515 54.8533 24.6608 55.607C24.755 55.8894 24.8491 56.1852 24.9701 56.4945C25.9249 59.0092 26.5435 58.7672 27.6596 61.5239C28.7249 64.4655 29.2267 67.5813 29.1389 70.7086C29.2734 74.0974 29.2734 75.8187 28.3051 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M15.7868 69.2009C14.4143 69.2009 12.2661 77.6026 12.2661 87.2009H14.9584H19.2661C19.2661 77.6026 17.1594 69.2009 15.7868 69.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M15.9809 70.1985C14.0304 70.334 11.2998 64.8656 10.2661 59.6533L10.5197 44.86C10.7927 40.1599 11.1048 36.3184 11.3193 33.6972C11.8265 27.6714 12.002 26.9634 12.002 24.4175C12.002 17.8645 15.2202 2.33743 15.8638 2.20184C16.5075 2.06626 13.5818 16.8853 14.5571 19.8982C15.7858 23.7697 15.5713 25.9239 15.8638 31.4677C15.8956 35.7448 16.3324 40.0144 17.1706 44.2424C17.9898 47.662 18.8285 49.304 19.7452 54.215C20.2658 56.4867 20.3969 58.8014 20.1353 61.0995C19.9133 62.948 19.4025 64.769 18.6139 66.5227C17.4047 68.918 16.839 70.1382 15.9809 70.1985Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M5.81002 51.5807C5.3528 53.181 4.84179 54.7947 3.98115 57.9818C2.94568 61.7875 2.42122 63.697 2.11193 65.3511C1.1437 70.6226 0.390634 74.6434 2.5557 77.6422C3.74328 78.9086 5.10102 80.0038 6.58998 80.8965C8.3362 82.115 10.1701 83.2027 12.0766 84.1509C13.2463 84.9276 14.5506 85.4794 15.9226 85.778C17.8768 85.6371 19.7148 84.7969 21.1 83.4112C22.7137 82.1472 22.512 81.7706 24.5022 80.009C26.7883 77.9918 27.3665 78.1936 28.2137 77.0505C29.2223 75.7058 29.1954 73.9307 29.1013 70.5284C29.1891 67.4055 28.6872 64.2941 27.622 61.3572C26.5193 58.6004 25.9142 58.829 24.9325 56.3143C23.7491 53.2482 23.9777 51.0563 23.8836 47.2909C23.7486 43.4736 23.4029 39.6666 22.8481 35.8874C21.8799 29.0022 20.831 29.8897 19.5938 22.7087C18.1011 13.9812 17.3481 9.62419 17.5229 7.01535C17.5901 6.03368 17.8456 3.06176 16.0437 1.24634C15.9092 1.11186 14.8065 0.0225935 14.1206 0.331889C13.1928 0.748765 14.1206 3.23657 13.0852 7.05569C12.8162 8.09116 12.3994 9.62419 11.6059 9.74522C10.8125 9.86625 10.0326 7.83565 9.23916 7.95668C8.44575 8.07771 7.98853 11.3724 7.8944 12.5558C7.605 15.8036 7.605 19.0709 7.8944 22.3187C8.7749 27.8498 8.97362 33.4681 8.4861 39.0476C7.93825 43.2914 7.04323 47.4832 5.81002 51.5807Z" fill="#DEE1E9"/>
        <mask id="mask314" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="30" height="36">
          <path d="M28.3051 77.2038C27.4579 78.3603 26.8797 78.1586 24.607 80.1623C22.6168 81.924 22.8051 82.3005 21.1913 83.578C19.802 84.9577 17.9664 85.7968 16.014 85.9448C14.6441 85.6397 13.3409 85.0884 12.168 84.3176C10.2663 83.3735 8.43695 82.2903 6.69482 81.0768C5.20317 80.1825 3.84505 79.0824 2.66054 77.809C0.508919 74.8102 1.24854 70.8028 2.21677 65.5313C2.52606 63.8638 3.05052 61.9677 4.08598 58.1486C4.08598 58.1486 4.08598 58.041 4.08598 57.9738C4.83905 56.1987 9.86846 53.684 9.86846 53.684C11.7232 52.6618 13.8163 52.1513 15.9333 52.2047C17.8459 52.3066 19.7154 52.8107 21.42 53.684C22.5651 54.2087 23.6515 54.8533 24.6608 55.607C24.755 55.8894 24.8491 56.1852 24.9701 56.4945C25.9249 59.0092 26.5435 58.7672 27.6596 61.5239C28.7249 64.4655 29.2267 67.5813 29.1389 70.7086C29.2734 74.0974 29.2734 75.8187 28.3051 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask314)">
          <path d="M28.3051 77.2038C27.4579 78.3603 26.8797 78.1586 24.607 80.1623C22.6168 81.924 22.8051 82.3005 21.1913 83.578C19.802 84.9577 17.9664 85.7968 16.014 85.9448C14.6441 85.6397 13.3409 85.0884 12.168 84.3176C10.2663 83.3735 8.43695 82.2903 6.69482 81.0768C5.20317 80.1825 3.84505 79.0824 2.66054 77.809C0.508919 74.8102 1.24854 70.8028 2.21677 65.5313C2.52606 63.8638 3.05052 61.9677 4.08598 58.1486C4.08598 58.1486 4.08598 58.041 4.08598 57.9738C4.83905 56.1987 9.86846 53.684 9.86846 53.684C11.7232 52.6618 13.8163 52.1513 15.9333 52.2047C17.8459 52.3066 19.7154 52.8107 21.42 53.684C22.5651 54.2087 23.6515 54.8533 24.6608 55.607C24.755 55.8894 24.8491 56.1852 24.9701 56.4945C25.9249 59.0092 26.5435 58.7672 27.6596 61.5239C28.7249 64.4655 29.2267 67.5813 29.1389 70.7086C29.2734 74.0974 29.2734 75.8187 28.3051 77.2038Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M15.7809 75.2009C14.8005 75.2009 13.2661 81.2688 13.2661 88.2009H15.1892H18.2661C18.2661 81.2688 16.7613 75.2009 15.7809 75.2009Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M5.89864 51.5033C5.44143 53.1036 4.93042 54.7173 4.06977 57.9044C3.0343 61.7101 2.50985 63.6196 2.20055 65.2737C1.23232 70.5452 0.479257 74.566 2.64432 77.5648C3.8319 78.8312 5.18964 79.9264 6.67861 80.8191C8.42482 82.0376 10.2587 83.1253 12.1652 84.0735C13.335 84.8502 14.6393 85.402 16.0112 85.7006C17.9654 85.5597 19.8034 84.7195 21.1886 83.3338C22.8023 82.0698 22.6006 81.6932 24.5908 79.9316C26.8769 77.9145 27.4552 78.1162 28.3024 76.9731C29.3109 75.6284 29.284 73.8533 29.1899 70.451C29.2777 67.3281 28.7758 64.2167 27.7107 61.2798C26.608 58.523 26.0028 58.7516 25.0211 56.2369C23.8378 53.1709 24.0664 50.9789 23.9722 47.2136C23.8372 43.3962 23.4915 39.5892 22.9368 35.81C21.9685 28.9248 20.9196 29.8123 19.6824 22.6313C18.1898 13.9038 17.4367 9.5468 17.6115 6.93796C17.6788 5.95628 17.9343 2.98437 16.1323 1.16894C15.9978 1.03447 14.8951 -0.0547991 14.2093 0.254496C13.2814 0.671372 14.2093 3.15918 13.1738 6.9783C12.9048 8.01377 12.488 9.5468 11.6946 9.66782C10.9012 9.78885 10.1212 7.75826 9.32779 7.87929C8.53438 8.00032 8.07716 11.295 7.98302 12.4784C7.69362 15.7263 7.69362 18.9935 7.98302 22.2413C8.86353 27.7725 9.06224 33.3907 8.57472 38.9702C8.02687 43.214 7.13185 47.4058 5.89864 51.5033Z" fill="#DEE1E9"/>
        <path d="M28.3098 76.9597C27.4626 78.1162 26.8843 77.9145 24.6117 79.9182C22.6214 81.6798 22.8097 82.0563 21.196 83.3339C19.8067 84.7135 17.971 85.5527 16.0187 85.7006C14.6487 85.3956 13.3456 84.8442 12.1726 84.0735C10.271 83.1294 8.44159 82.0462 6.69946 80.8326C5.20781 79.9384 3.84969 78.8383 2.66518 77.5649C0.513558 74.566 1.25318 70.5586 2.2214 65.2872C2.5307 63.6197 3.05516 61.7235 4.09062 57.9044C4.09062 57.9044 4.09062 57.7969 4.09062 57.7296C4.84369 55.9545 9.87309 53.4398 9.87309 53.4398C11.7278 52.4177 13.8209 51.9072 15.938 51.9606C17.8505 52.0625 19.72 52.5665 21.4246 53.4398C22.5698 53.9646 23.6561 54.6092 24.6655 55.3628C24.7596 55.6452 24.8537 55.9411 24.9748 56.2504C25.9295 58.7651 26.5481 58.523 27.6643 61.2798C28.7296 64.2213 29.2314 67.3372 29.1435 70.4645C29.278 73.8533 29.278 75.5746 28.3098 76.9597Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.2661 68.2012L13.1328 70.702L14.7661 69.2063V72.5204L17.3283 71.9525L15.9328 73.7031L18.2661 75.7037L15.9328 77.7044L17.3283 79.455L14.9994 78.7048L14.7661 82.2012L12.5162 80.7041L11.7328 81.7058L10.016 80.7041L7.76611 82.2012L7.06611 80.2053L5.20394 79.455L5.66611 76.7041L4.26611 75.7037L6.59945 74.2032L5.20394 71.9525L7.06611 71.7024L7.76611 69.2063L10.7994 71.2022L11.2661 68.2012Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M5.89864 51.5033C5.44143 53.1036 4.93042 54.7173 4.06977 57.9044C3.0343 61.7101 2.50985 63.6196 2.20055 65.2737C1.23232 70.5452 0.479257 74.566 2.64432 77.5648C3.8319 78.8312 5.18964 79.9264 6.67861 80.8191C8.42482 82.0376 10.2587 83.1253 12.1652 84.0735C13.335 84.8502 14.6393 85.402 16.0112 85.7006C17.9654 85.5597 19.8034 84.7195 21.1886 83.3338C22.8023 82.0698 22.6006 81.6932 24.5908 79.9316C26.8769 77.9145 27.4552 78.1162 28.3024 76.9731C29.3109 75.6284 29.284 73.8533 29.1899 70.451C29.2777 67.3281 28.7758 64.2167 27.7107 61.2798C26.608 58.523 26.0028 58.7516 25.0211 56.2369C23.8378 53.1709 24.0664 50.9789 23.9722 47.2136C23.8372 43.3962 23.4915 39.5892 22.9368 35.81C21.9685 28.9248 20.9196 29.8123 19.6824 22.6313C18.1898 13.9038 17.4367 9.5468 17.6115 6.93796C17.6788 5.95628 17.9343 2.98437 16.1323 1.16894C15.9978 1.03447 14.8951 -0.0547991 14.2093 0.254496C13.2814 0.671372 14.2093 3.15918 13.1738 6.9783C12.9048 8.01377 12.488 9.5468 11.6946 9.66782C10.9012 9.78885 10.1212 7.75826 9.32779 7.87929C8.53438 8.00032 8.07716 11.295 7.98302 12.4784C7.69362 15.7263 7.69362 18.9935 7.98302 22.2413C8.86353 27.7725 9.06224 33.3907 8.57472 38.9702C8.02687 43.214 7.13185 47.4058 5.89864 51.5033Z" fill="#DEE1E9"/>
        <path d="M28.3098 76.9597C27.4626 78.1162 26.8843 77.9145 24.6117 79.9182C22.6214 81.6798 22.8097 82.0563 21.196 83.3339C19.8067 84.7135 17.971 85.5527 16.0187 85.7006C14.6487 85.3956 13.3456 84.8442 12.1726 84.0735C10.271 83.1294 8.44159 82.0462 6.69946 80.8326C5.20781 79.9384 3.84969 78.8383 2.66518 77.5649C0.513558 74.566 1.25318 70.5586 2.2214 65.2872C2.5307 63.6197 3.05516 61.7235 4.09062 57.9044C4.09062 57.9044 4.09062 57.7969 4.09062 57.7296C4.84369 55.9545 9.87309 53.4398 9.87309 53.4398C11.7278 52.4177 13.8209 51.9072 15.938 51.9606C17.8505 52.0625 19.72 52.5665 21.4246 53.4398C22.5698 53.9646 23.6561 54.6092 24.6655 55.3628C24.7596 55.6452 24.8537 55.9411 24.9748 56.2504C25.9295 58.7651 26.5481 58.523 27.6643 61.2798C28.7296 64.2213 29.2314 67.3372 29.1435 70.4645C29.278 73.8533 29.278 75.5746 28.3098 76.9597Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.2661 69.2012L11.8661 71.3448L13.2661 70.0627V72.9033L15.4623 72.4166L14.2661 73.9171L16.2661 75.632L14.2661 77.3468L15.4623 78.8473L13.4661 78.2043L13.2661 81.2012L11.3376 79.918L10.6661 80.7766L9.1946 79.918L7.26611 81.2012L6.66611 79.4904L5.06996 78.8473L5.46611 76.4894L4.26611 75.632L6.26611 74.3458L5.06996 72.4166L6.66611 72.2022L7.26611 70.0627L9.86611 71.7735L10.2661 69.2012Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M5.89864 51.4355C5.44143 53.0357 4.93042 54.6494 4.06977 57.8365C3.0343 61.6422 2.50985 63.5518 2.20055 65.2058C1.23232 70.4773 0.479257 74.4981 2.64432 77.4969C3.8319 78.7633 5.18964 79.8585 6.67861 80.7513C8.42482 81.9697 10.2587 83.0574 12.1652 84.0056C13.335 84.7823 14.6393 85.3341 16.0112 85.6328C17.9654 85.4919 19.8034 84.6516 21.1886 83.266C22.8023 82.0019 22.6006 81.6254 24.5908 79.8637C26.8769 77.8466 27.4552 78.0483 28.3024 76.9053C29.3109 75.5605 29.284 73.7854 29.1899 70.3831C29.2777 67.2603 28.7758 64.1488 27.7107 61.2119C26.608 58.4551 26.0028 58.6837 25.0211 56.169C23.8378 53.103 24.0664 50.911 23.9722 47.1457C23.8372 43.3283 23.4915 39.5214 22.9368 35.7421C21.9685 28.8569 20.9196 29.7445 19.6824 22.5634C18.1898 13.8359 17.4367 9.47893 17.6115 6.87009C17.6788 5.88841 17.9343 2.9165 16.1323 1.10107C15.9978 0.966597 14.8951 -0.12267 14.2093 0.186625C13.2814 0.603501 14.2093 3.09131 13.1738 6.91043C12.9048 7.9459 12.488 9.47892 11.6946 9.59995C10.9012 9.72098 10.1212 7.69039 9.32779 7.81142C8.53438 7.93245 8.07716 11.2271 7.98302 12.4105C7.69362 15.6584 7.69362 18.9256 7.98302 22.1735C8.86353 27.7046 9.06224 33.3228 8.57472 38.9023C8.02687 43.1462 7.13185 47.3379 5.89864 51.4355Z" fill="#DEE1E9"/>
        <path d="M28.3098 76.8918C27.4626 78.0483 26.8843 77.8466 24.6117 79.8503C22.6214 81.6119 22.8097 81.9885 21.196 83.266C19.8067 84.6457 17.971 85.4848 16.0187 85.6328C14.6487 85.3277 13.3456 84.7764 12.1726 84.0056C10.271 83.0615 8.44159 81.9783 6.69946 80.7647C5.20781 79.8705 3.84969 78.7704 2.66518 77.497C0.513558 74.4982 1.25318 70.4908 2.2214 65.2193C2.5307 63.5518 3.05516 61.6557 4.09062 57.8366C4.09062 57.8366 4.09062 57.729 4.09062 57.6617C4.84369 55.8867 9.87309 53.372 9.87309 53.372C11.7278 52.3498 13.8209 51.8393 15.938 51.8927C17.8505 51.9946 19.72 52.4987 21.4246 53.372C22.5698 53.8967 23.6561 54.5413 24.6655 55.295C24.7596 55.5774 24.8537 55.8732 24.9748 56.1825C25.9295 58.6972 26.5481 58.4552 27.6643 61.2119C28.7296 64.1535 29.2314 67.2693 29.1435 70.3966C29.278 73.7854 29.278 75.5067 28.3098 76.8918Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.7661 75.1333L11.6994 76.3837L12.5161 75.6359V77.2929L13.7972 77.0089L13.0994 77.8842L14.2661 78.8846L13.0994 79.8849L13.7972 80.7602L12.6328 80.3851L12.5161 82.1333L11.3912 81.3848L10.9994 81.8856L10.1411 81.3848L9.01611 82.1333L8.66611 81.1354L7.73502 80.7602L7.96611 79.3848L7.26611 78.8846L8.43278 78.1343L7.73502 77.0089L8.66611 76.8839L9.01611 75.6359L10.5328 76.6338L10.7661 75.1333Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M11.535 2.68065L13.6188 1.41089H17.1025L19.2025 2.68065L17.5908 4.22716L19.6746 5.77367L17.7862 7.32018L20.0815 9.0132L18.2583 10.5597L20.5536 11.9597L18.535 13.376L20.5536 14.9876L18.8606 16.5341L21.0908 17.9504L19.3327 19.4155L21.628 20.962L19.4792 22.5085L21.628 24.1201L20.0815 25.4713L22.1652 27.0178L20.342 28.4992L22.4257 30.0457L20.6187 31.7876L21.628 33.4643V47.7247L19.7397 55.0503H13.6188" fill="#8889BE"/>
        <path d="M11.535 2.68065L13.6188 1.41089H17.1025L19.2025 2.68065L17.5908 4.22716L19.6746 5.77367L17.7862 7.32018L20.0815 9.0132L18.2583 10.5597L20.5536 11.9597L18.535 13.376L20.5536 14.9876L18.8606 16.5341L21.0908 17.9504L19.3327 19.4155L21.628 20.962L19.4792 22.5085L21.628 24.1201L20.0815 25.4713L22.1652 27.0178L20.342 28.4992L22.4257 30.0457L20.6187 31.7876L21.628 33.4643V47.7247L19.7397 55.0503H13.6188" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M18.9423 2.68065L16.8586 1.41089H13.3912L11.2749 2.68065L12.9028 4.22716L10.8028 5.77367L12.6912 7.32018L10.4121 9.0132L12.2191 10.5597L9.94 11.9597L11.9586 13.376L9.94 14.9876L11.6167 16.5341L9.4028 17.9504L11.1447 19.4155L8.86559 20.962L11.0144 22.5085L8.86559 24.1201L10.4121 25.4713L8.32838 27.0178L10.1354 28.4992L8.05164 30.0457L9.87489 31.7876L8.86559 33.4643V47.7247L10.7377 55.0503H14.6446" fill="#8889BE"/>
        <path d="M18.9423 2.68065L16.8586 1.41089H13.3912L11.2749 2.68065L12.9028 4.22716L10.8028 5.77367L12.6912 7.32018L10.4121 9.0132L12.2191 10.5597L9.94 11.9597L11.9586 13.376L9.94 14.9876L11.6167 16.5341L9.4028 17.9504L11.1447 19.4155L8.86559 20.962L11.0144 22.5085L8.86559 24.1201L10.4121 25.4713L8.32838 27.0178L10.1354 28.4992L8.05164 30.0457L9.87489 31.7876L8.86559 33.4643V47.7247L10.7377 55.0503H14.6446" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M24.6117 68.9951C26.8843 66.9914 27.4626 67.1931 28.3098 66.0366C29.278 64.6515 29.278 62.9302 29.1435 59.5414C29.2314 56.4141 28.7296 53.2982 27.6643 50.3566C26.5481 47.5999 25.9295 47.8419 24.9748 45.3272C24.8537 45.0179 24.7596 44.7221 24.6655 44.4397H21.6573C21.105 44.4397 20.6573 44.8874 20.6573 45.4397V55.3374C16.1836 56.6248 13.6765 56.5857 9.20209 55.3374V45.4397C9.20209 44.8874 8.75438 44.4397 8.20209 44.4397H5.20187C5.20187 44.5069 4.09062 46.9813 4.09062 46.9813C3.05516 50.8004 2.5307 52.6965 2.2214 54.364L2.21758 54.3849C1.25123 59.6461 0.516391 63.6468 2.66518 66.6417C3.84969 67.9152 5.20781 69.0152 6.69946 69.9095C8.44159 71.123 10.271 72.2063 12.1726 73.1504C13.3456 73.9211 14.6487 74.4724 16.0187 74.7775C17.971 74.6296 19.8067 73.7904 21.196 72.4107C22.8097 71.1332 22.6214 70.7567 24.6117 68.9951Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask514" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="44" width="30" height="32">
          <path d="M24.6378 68.9949C26.9104 66.9912 27.4887 67.1929 28.3359 66.0364C29.3041 64.6513 29.3041 62.93 29.1696 59.5412C29.2575 56.4139 28.7557 53.298 27.6904 50.3565C26.5743 47.5997 25.9557 47.8418 25.0009 45.3271C24.8799 45.0178 24.7319 45.6095 24.6378 45.3271H21.635C21.0806 45.3271 20.632 45.7781 20.635 46.3324L20.6834 55.3373C16.5465 56.2729 14.2309 56.3004 10.0946 55.3373L10.0461 46.3217C10.0432 45.7715 9.59635 45.3271 9.04616 45.3271L5.17421 45.3271C5.17421 45.3943 4.11675 46.9811 4.11675 46.9811C3.08128 50.8002 2.55682 52.6964 2.24753 54.3639L2.2437 54.3847C1.27736 59.6459 0.542515 63.6467 2.6913 66.6415C3.87581 67.915 5.23394 69.0151 6.72558 69.9093C8.46771 71.1228 10.2971 72.2061 12.1988 73.1502C13.3717 73.9209 14.6748 74.4723 16.0448 74.7773C17.9971 74.6294 19.8328 73.7902 21.2221 72.4106C22.8358 71.133 22.6476 70.7565 24.6378 68.9949Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask514)">
          <path d="M6.81757 33.3127H23.7121V58.8607C17.1144 60.787 13.4153 60.8065 6.81757 58.8607V33.3127Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M10.9849 1.95092L13.0686 0.681152H16.5524L18.6524 1.95092L17.0407 3.49743L19.1244 5.04393L17.2361 6.59044L19.5314 8.28346L17.7082 9.82997L20.0035 11.23L17.9849 12.6462L20.0035 14.2579L18.3105 15.8044L20.5407 17.2207L18.7826 18.6858L21.0779 20.2323L18.9291 21.7788L21.0779 23.3904L19.5314 24.7416L21.6151 26.2881L19.7919 27.7695L21.8756 29.316L20.0686 31.0578L21.0779 32.7346V46.995L19.1896 54.3206H13.0686" fill="#8889BE"/>
        <path d="M10.9849 1.95092L13.0686 0.681152H16.5524L18.6524 1.95092L17.0407 3.49743L19.1244 5.04393L17.2361 6.59044L19.5314 8.28346L17.7082 9.82997L20.0035 11.23L17.9849 12.6462L20.0035 14.2579L18.3105 15.8044L20.5407 17.2207L18.7826 18.6858L21.0779 20.2323L18.9291 21.7788L21.0779 23.3904L19.5314 24.7416L21.6151 26.2881L19.7919 27.7695L21.8756 29.316L20.0686 31.0578L21.0779 32.7346V46.995L19.1896 54.3206H13.0686" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M18.3922 1.95092L16.3085 0.681152H12.841L10.7248 1.95092L12.3527 3.49743L10.2527 5.04393L12.1411 6.59044L9.86199 8.28346L11.669 9.82997L9.38989 11.23L11.4085 12.6462L9.38989 14.2579L11.0666 15.8044L8.85269 17.2207L10.5945 18.6858L8.31548 20.2323L10.4643 21.7788L8.31548 23.3904L9.86199 24.7416L7.77827 26.2881L9.58524 27.7695L7.50153 29.316L9.32478 31.0578L8.31548 32.7346V46.995L10.1876 54.3206H14.0945" fill="#8889BE"/>
        <path d="M18.3922 1.95092L16.3085 0.681152H12.841L10.7248 1.95092L12.3527 3.49743L10.2527 5.04393L12.1411 6.59044L9.86199 8.28346L11.669 9.82997L9.38989 11.23L11.4085 12.6462L9.38989 14.2579L11.0666 15.8044L8.85269 17.2207L10.5945 18.6858L8.31548 20.2323L10.4643 21.7788L8.31548 23.3904L9.86199 24.7416L7.77827 26.2881L9.58524 27.7695L7.50153 29.316L9.32478 31.0578L8.31548 32.7346V46.995L10.1876 54.3206H14.0945" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M24.6117 64.4352C26.8843 62.4315 27.4626 62.6332 28.3098 61.4767C29.278 60.0916 29.278 58.3703 29.1435 54.9815C29.2314 51.8543 28.7296 48.7384 27.6643 45.7968C26.5481 43.0401 25.9295 43.2821 24.9748 40.7674C24.8537 40.4581 24.7596 40.1623 24.6655 39.8799H20.7597C20.2074 39.8799 19.7597 40.3276 19.7597 40.8799V50.7776C15.9504 52.3547 13.8201 52.3854 10.0098 50.7776V40.8799C10.0098 40.3276 9.5621 39.8799 9.00981 39.8799H5.20187C5.20187 39.9471 4.09062 42.4215 4.09062 42.4215C3.05516 46.2406 2.5307 48.1367 2.2214 49.8042L2.21758 49.825C1.25123 55.0862 0.516391 59.087 2.66518 62.0819C3.84969 63.3553 5.20781 64.4554 6.69946 65.3497C8.44159 66.5632 10.271 67.6464 12.1726 68.5905C13.3456 69.3613 14.6487 69.9126 16.0187 70.2177C17.971 70.0697 19.8067 69.2306 21.196 67.8509C22.8097 66.5734 22.6214 66.1969 24.6117 64.4352Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M0 83.771H21V88.771H0V83.771Z" fill="#838AA2"/>
        <path d="M22 83.771H43V88.771H22V83.771Z" fill="#838AA2"/>
        <path d="M11.654 52.0739C11.1968 53.6742 10.6858 55.2879 9.82514 58.475C8.78967 62.2806 8.26522 64.1902 7.95592 65.8443C6.98769 71.1157 6.23463 75.1366 8.39969 78.1354C9.58727 79.4017 10.945 80.497 12.434 81.3897C14.1802 82.6081 16.0141 83.6959 17.9206 84.644C19.0903 85.4207 20.3946 85.9725 21.7666 86.2712C23.7208 86.1303 25.5588 85.29 26.9439 83.9044C28.5577 82.6403 28.3559 82.2638 30.3462 80.5022C32.6323 78.485 33.2105 78.6867 34.0577 77.5437C35.0663 76.1989 35.0394 74.4238 34.9453 71.0216C35.0331 67.8987 34.5312 64.7873 33.466 61.8503C32.3633 59.0936 31.7582 59.3222 30.7765 56.8075C29.5931 53.7414 29.8217 51.5494 29.7276 47.7841C29.5926 43.9668 29.2469 40.1598 28.6921 36.3805C27.7239 29.4954 26.675 30.3829 25.4378 23.2019C23.9451 14.4744 23.1921 10.1174 23.3669 7.50852C23.4341 6.52684 23.6896 3.55493 21.8876 1.7395C21.7532 1.60502 20.6505 0.515758 19.9646 0.825053C19.0368 1.24193 19.9646 3.72974 18.9292 7.54886C18.6602 8.58432 18.2433 10.1174 17.4499 10.2384C16.6565 10.3594 15.8766 8.32882 15.0832 8.44985C14.2897 8.57087 13.8325 11.8655 13.7384 13.0489C13.449 16.2968 13.449 19.564 13.7384 22.8119C14.6189 28.343 14.8176 33.9612 14.3301 39.5407C13.7822 43.7846 12.8872 47.9764 11.654 52.0739Z" fill="#DEE1E9"/>
        <path d="M30.3671 80.4889C32.6397 78.4853 33.2179 78.6869 34.0651 77.5305C35.0334 76.1454 35.0334 74.4241 34.8989 71.0353C34.9868 67.908 34.4849 64.7921 33.4197 61.8505C32.3035 59.0938 31.6849 59.3358 30.7301 56.8211C30.6091 56.5118 30.515 56.216 30.4208 55.9336H26.5151C25.9628 55.9336 25.5151 56.3813 25.5151 56.9336V66.8313C21.7057 68.4084 19.5755 68.4391 15.7652 66.8313V56.9336C15.7652 56.3813 15.3175 55.9336 14.7652 55.9336H10.9572C10.9572 56.0008 9.84599 58.4752 9.84599 58.4752C8.81053 62.2943 8.28607 64.1904 7.97678 65.8579L7.97295 65.8788C7.0066 71.14 6.27176 75.1407 8.42055 78.1356C9.60506 79.409 10.9632 80.5091 12.4548 81.4034C14.197 82.6169 16.0263 83.7002 17.928 84.6443C19.1009 85.415 20.4041 85.9663 21.774 86.2714C23.7264 86.1235 25.5621 85.2843 26.9514 83.9046C28.5651 82.6271 28.3768 82.2506 30.3671 80.4889Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M5.54391 51.3801C5.08669 52.9803 4.57568 54.594 3.71503 57.7811C2.67957 61.5868 2.15511 63.4964 1.84582 65.1504C0.877587 70.4219 0.124521 74.4427 2.28959 77.4415C3.47717 78.7079 4.8349 79.8031 6.32387 80.6958C8.07008 81.9143 9.90395 83.002 11.8105 83.9502C12.9802 84.7269 14.2845 85.2787 15.6565 85.5773C17.6107 85.4364 19.4487 84.5962 20.8338 83.2105C22.4476 81.9465 22.2458 81.5699 24.2361 79.8083C26.5222 77.7912 27.1004 77.9929 27.9476 76.8498C28.9562 75.5051 28.9293 73.73 28.8352 70.3277C28.923 67.2048 28.4211 64.0934 27.3559 61.1565C26.2532 58.3997 25.6481 58.6283 24.6664 56.1136C23.483 53.0476 23.7116 50.8556 23.6175 47.0903C23.4825 43.2729 23.1368 39.4659 22.582 35.6867C21.6138 28.8015 20.5649 29.6891 19.3277 22.508C17.835 13.7805 17.082 9.42351 17.2568 6.81467C17.324 5.83299 17.5795 2.86108 15.7775 1.04565C15.6431 0.911177 14.5404 -0.17809 13.8545 0.131205C12.9266 0.548081 13.8545 3.03589 12.8191 6.85501C12.5501 7.89048 12.1332 9.4235 11.3398 9.54453C10.5464 9.66556 9.76646 7.63497 8.97305 7.756C8.17964 7.87703 7.72242 11.1717 7.62829 12.3551C7.33889 15.603 7.33889 18.8702 7.62829 22.1181C8.50879 27.6492 8.7075 33.2674 8.21998 38.8469C7.67214 43.0908 6.77711 47.2825 5.54391 51.3801Z" fill="#DEE1E9"/>
        <path d="M27.955 76.8364C27.1078 77.9929 26.5296 77.7912 24.2569 79.7949C22.2667 81.5565 22.455 81.9331 20.8412 83.2106C19.452 84.5903 17.6163 85.4294 15.6639 85.5774C14.294 85.2723 12.9908 84.7209 11.8179 83.9502C9.91622 83.0061 8.08685 81.9229 6.34472 80.7093C4.85308 79.8151 3.49495 78.715 2.31044 77.4416C0.158821 74.4427 0.89844 70.4353 1.86667 65.1639C2.17596 63.4964 2.70042 61.6003 3.73589 57.7811C3.73589 57.7811 3.73589 57.6736 3.73589 57.6063C4.48895 55.8312 9.51836 53.3165 9.51836 53.3165C11.3731 52.2944 13.4662 51.7839 15.5832 51.8373C17.4958 51.9392 19.3653 52.4432 21.0699 53.3165C22.215 53.8413 23.3014 54.4859 24.3107 55.2395C24.4049 55.5219 24.499 55.8178 24.62 56.1271C25.5748 58.6418 26.1934 58.3997 27.3095 61.1565C28.3748 64.0981 28.8767 67.2139 28.7888 70.3412C28.9233 73.73 28.9233 75.4513 27.955 76.8364Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M15.3767 63.1735C14.0319 63.2945 12.1492 58.4131 11.4365 53.7602L11.6113 40.5546C11.7996 36.359 12.0148 32.9298 12.1627 30.5899C12.5123 25.2109 12.6334 24.5789 12.6334 22.3062C12.6334 16.4565 14.8522 2.59597 15.296 2.47494C15.7398 2.35391 13.7226 15.5824 14.395 18.2719C15.2422 21.728 15.0943 23.651 15.296 28.5997C15.3179 32.4178 15.619 36.2291 16.197 40.0033C16.7618 43.0559 17.34 44.5217 17.9721 48.9056C18.331 50.9334 18.4214 52.9997 18.241 55.0511C18.088 56.7013 17.7358 58.3268 17.1921 59.8923C16.3583 62.0305 15.9684 63.1197 15.3767 63.1735Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M5.54391 51.3801C5.08669 52.9803 4.57568 54.594 3.71503 57.7811C2.67957 61.5868 2.15511 63.4964 1.84582 65.1504C0.877587 70.4219 0.124521 74.4427 2.28959 77.4415C3.47717 78.7079 4.8349 79.8031 6.32387 80.6958C8.07008 81.9143 9.90395 83.002 11.8105 83.9502C12.9802 84.7269 14.2845 85.2787 15.6565 85.5773C17.6107 85.4364 19.4487 84.5962 20.8338 83.2105C22.4476 81.9465 22.2458 81.5699 24.2361 79.8083C26.5222 77.7912 27.1004 77.9929 27.9476 76.8498C28.9562 75.5051 28.9293 73.73 28.8352 70.3277C28.923 67.2048 28.4211 64.0934 27.3559 61.1565C26.2532 58.3997 25.6481 58.6283 24.6664 56.1136C23.483 53.0476 23.7116 50.8556 23.6175 47.0903C23.4825 43.2729 23.1368 39.4659 22.582 35.6867C21.6138 28.8015 20.5649 29.6891 19.3277 22.508C17.835 13.7805 17.082 9.42351 17.2568 6.81467C17.324 5.83299 17.5795 2.86108 15.7775 1.04565C15.6431 0.911177 14.5404 -0.17809 13.8545 0.131205C12.9266 0.548081 13.8545 3.03589 12.8191 6.85501C12.5501 7.89048 12.1332 9.4235 11.3398 9.54453C10.5464 9.66556 9.76646 7.63497 8.97305 7.756C8.17964 7.87703 7.72242 11.1717 7.62829 12.3551C7.33889 15.603 7.33889 18.8702 7.62829 22.1181C8.50879 27.6492 8.7075 33.2674 8.21998 38.8469C7.67214 43.0908 6.77711 47.2825 5.54391 51.3801Z" fill="#DEE1E9"/>
        <path d="M27.955 76.8364C27.1078 77.9929 26.5296 77.7912 24.2569 79.7949C22.2667 81.5565 22.455 81.9331 20.8412 83.2106C19.452 84.5903 17.6163 85.4294 15.6639 85.5774C14.294 85.2723 12.9908 84.7209 11.8179 83.9502C9.91622 83.0061 8.08685 81.9229 6.34472 80.7093C4.85308 79.8151 3.49495 78.715 2.31044 77.4416C0.158821 74.4427 0.89844 70.4353 1.86667 65.1639C2.17596 63.4964 2.70042 61.6003 3.73589 57.7811C3.73589 57.7811 3.73589 57.6736 3.73589 57.6063C4.48895 55.8312 9.51836 53.3165 9.51836 53.3165C11.3731 52.2944 13.4662 51.7839 15.5832 51.8373C17.4958 51.9392 19.3653 52.4432 21.0699 53.3165C22.215 53.8413 23.3014 54.4859 24.3107 55.2395C24.4049 55.5219 24.499 55.8178 24.62 56.1271C25.5748 58.6418 26.1934 58.3997 27.3095 61.1565C28.3748 64.0981 28.8767 67.2139 28.7888 70.3412C28.9233 73.73 28.9233 75.4513 27.955 76.8364Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.4" d="M15.3767 63.1735C14.0319 63.2945 12.1492 58.4131 11.4365 53.7602L11.6113 40.5546C11.7996 36.359 12.0148 32.9298 12.1627 30.5899C12.5123 25.2109 12.6334 24.5789 12.6334 22.3062C12.6334 16.4565 14.8522 2.59597 15.296 2.47494C15.7398 2.35391 13.7226 15.5824 14.395 18.2719C15.2422 21.728 15.0943 23.651 15.296 28.5997C15.3179 32.4178 15.619 36.2291 16.197 40.0033C16.7618 43.0559 17.34 44.5217 17.9721 48.9056C18.331 50.9334 18.4214 52.9997 18.241 55.0511C18.088 56.7013 17.7358 58.3268 17.1921 59.8923C16.3583 62.0305 15.9684 63.1197 15.3767 63.1735Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M5.54403 51.3815C5.08681 52.9818 4.5758 54.5955 3.71516 57.7826C2.67969 61.5882 2.15523 63.4978 1.84594 65.1519C0.877709 70.4233 0.124643 74.4442 2.28971 77.443C3.47729 78.7093 4.83503 79.8046 6.32399 80.6973C8.07021 81.9158 9.90407 83.0035 11.8106 83.9516C12.9803 84.7283 14.2846 85.2802 15.6566 85.5788C17.6108 85.4379 19.4488 84.5977 20.834 83.212C22.4477 81.9479 22.246 81.5714 24.2362 79.8098C26.5223 77.7926 27.1006 77.9943 27.9478 76.8513C28.9563 75.5065 28.9294 73.7314 28.8353 70.3292C28.9231 67.2063 28.4212 64.0949 27.3561 61.1579C26.2534 58.4012 25.6482 58.6298 24.6665 56.1151C23.4831 53.049 23.7118 50.8571 23.6176 47.0917C23.4826 43.2744 23.1369 39.4674 22.5822 35.6882C21.6139 28.803 20.565 29.6905 19.3278 22.5095C17.8351 13.782 17.0821 9.42497 17.2569 6.81613C17.3241 5.83446 17.5796 2.86255 15.7777 1.04712C15.6432 0.912642 14.5405 -0.176625 13.8547 0.13267C12.9268 0.549546 13.8547 3.03735 12.8192 6.85648C12.5502 7.89194 12.1334 9.42497 11.34 9.546C10.5465 9.66703 9.76658 7.63643 8.97317 7.75746C8.17976 7.87849 7.72254 11.1732 7.62841 12.3565C7.33901 15.6044 7.33901 18.8716 7.62841 22.1195C8.50891 27.6506 8.70763 33.2689 8.2201 38.8484C7.67226 43.0922 6.77723 47.284 5.54403 51.3815Z" fill="#DEE1E9"/>
        <path d="M27.9552 76.8379C27.108 77.9944 26.5297 77.7927 24.2571 79.7964C22.2668 81.558 22.4551 81.9345 20.8414 83.212C19.4521 84.5917 17.6164 85.4309 15.664 85.5788C14.2941 85.2737 12.991 84.7224 11.818 83.9517C9.91634 83.0076 8.08698 81.9243 6.34484 80.7108C4.8532 79.8165 3.49507 78.7165 2.31056 77.443C0.158943 74.4442 0.898562 70.4368 1.86679 65.1653C2.17609 63.4978 2.70054 61.6017 3.73601 57.7826C3.73601 57.7826 3.73601 57.675 3.73601 57.6078C4.48907 55.8327 9.51848 53.318 9.51848 53.318C11.3732 52.2959 13.4663 51.7853 15.5834 51.8388C17.4959 51.9407 19.3654 52.4447 21.07 53.318C22.2152 53.8427 23.3015 54.4873 24.3109 55.241C24.405 55.5234 24.4991 55.8193 24.6201 56.1286C25.5749 58.6433 26.1935 58.4012 27.3097 61.158C28.375 64.0995 28.8768 67.2154 28.7889 70.3427C28.9234 73.7315 28.9234 75.4528 27.9552 76.8379Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask514" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="24" width="15" height="42">
          <path d="M9.18158 24.1606L15.9442 24.1606L23.308 63.6676L11.3178 65.0224L9.18158 24.1606Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask514)">
          <path opacity="0.2" d="M15.3768 63.175C14.032 63.296 12.1494 58.4145 11.4366 53.7616L11.6115 40.5561C11.7997 36.3604 12.0149 32.9313 12.1628 30.5914C12.5125 25.2124 12.6335 24.5803 12.6335 22.3077C12.6335 16.458 14.8523 2.59743 15.2961 2.4764C15.7399 2.35538 13.7227 15.5839 14.3951 18.2734C15.2423 21.7294 15.0944 23.6524 15.2961 28.6011C15.318 32.4192 15.6192 36.2306 16.1971 40.0047C16.7619 43.0573 17.3401 44.5231 17.9722 48.9071C18.3311 50.9349 18.4215 53.0012 18.2411 55.0526C18.0881 56.7027 17.7359 58.3283 17.1922 59.8937C16.3585 62.0319 15.9685 63.1212 15.3768 63.175Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5014" mask-type="alpha" maskUnits="userSpaceOnUse" x="9" y="0" width="9" height="25">
          <path d="M16.7231 2.30301L16.0232 0.612793H14.6234L13.6353 1.69251L9.10651 24.0612H17.8759L17.5054 14.1351L17.0525 5.35496L16.7231 2.30301Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5014)">
          <path opacity="0.4" d="M15.3767 63.175C14.032 63.296 12.1493 58.4145 11.4366 53.7616L11.6114 40.5561C11.7997 36.3604 12.0148 32.9313 12.1628 30.5914C12.5124 25.2124 12.6334 24.5803 12.6334 22.3077C12.6334 16.458 14.8523 2.59743 15.296 2.4764C15.7398 2.35538 13.7227 15.5839 14.3951 18.2734C15.2423 21.7294 15.0943 23.6524 15.296 28.6011C15.318 32.4192 15.6191 36.2306 16.197 40.0047C16.7618 43.0573 17.3401 44.5231 17.9721 48.9071C18.331 50.9349 18.4215 53.0012 18.2411 55.0526C18.0881 56.7027 17.7359 58.3283 17.1922 59.8937C16.3584 62.0319 15.9684 63.1212 15.3767 63.175Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M5.63253 51.8381C5.17531 53.4383 4.6643 55.052 3.80366 58.2391C2.76819 62.0448 2.24373 63.9544 1.93444 65.6084C0.96621 70.8799 0.213144 74.9007 2.37821 77.8995C3.56579 79.1659 4.92353 80.2611 6.41249 81.1539C8.15871 82.3723 9.99258 83.46 11.8991 84.4082C13.0689 85.1849 14.3731 85.7367 15.7451 86.0353C17.6993 85.8944 19.5373 85.0542 20.9225 83.6686C22.5362 82.4045 22.3345 82.0279 24.3247 80.2663C26.6108 78.2492 27.1891 78.4509 28.0363 77.3078C29.0448 75.9631 29.0179 74.188 28.9238 70.7857C29.0116 67.6628 28.5097 64.5514 27.4446 61.6145C26.3419 58.8577 25.7367 59.0863 24.755 56.5716C23.5716 53.5056 23.8003 51.3136 23.7061 47.5483C23.5711 43.7309 23.2254 39.9239 22.6707 36.1447C21.7024 29.2595 20.6535 30.1471 19.4163 22.966C17.9236 14.2385 17.1706 9.88151 17.3454 7.27268C17.4126 6.291 17.6681 3.31909 15.8662 1.50366C15.7317 1.36918 14.629 0.279918 13.9432 0.589213C13.0153 1.00609 13.9432 3.4939 12.9077 7.31302C12.6387 8.34848 12.2219 9.88151 11.4285 10.0025C10.635 10.1236 9.85508 8.09298 9.06167 8.21401C8.26826 8.33503 7.81104 11.6297 7.71691 12.8131C7.42751 16.061 7.42751 19.3282 7.71691 22.5761C8.59741 28.1072 8.79613 33.7254 8.30861 39.3049C7.76076 43.5488 6.86574 47.7405 5.63253 51.8381Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M5.63253 52.1752C5.17531 53.7754 4.6643 55.3891 3.80366 58.5762C2.76819 62.3819 2.24373 64.2915 1.93444 65.9455C0.96621 71.217 0.213144 75.2378 2.37821 78.2366C3.56579 79.503 4.92353 80.5982 6.41249 81.491C8.15871 82.7094 9.99258 83.7971 11.8991 84.7453C13.0689 85.522 14.3731 86.0738 15.7451 86.3724C17.6993 86.2315 19.5373 85.3913 20.9225 84.0057C22.5362 82.7416 22.3345 82.365 24.3247 80.6034C26.6108 78.5863 27.1891 78.788 28.0363 77.6449C29.0448 76.3002 29.0179 74.5251 28.9238 71.1228C29.0116 67.9999 28.5097 64.8885 27.4446 61.9516C26.3419 59.1948 25.7367 59.4234 24.755 56.9087C23.5716 53.8427 23.8003 51.6507 23.7061 47.8854C23.5711 44.068 23.2254 40.261 22.6707 36.4818C21.7024 29.5966 20.6535 30.4842 19.4163 23.3031C17.9236 14.5756 17.1706 10.2186 17.3454 7.60977C17.4126 6.6281 17.6681 3.65619 15.8662 1.84076C15.7317 1.70628 14.629 0.617015 13.9432 0.92631C13.0153 1.34319 13.9432 3.83099 12.9077 7.65012C12.6387 8.68558 12.2219 10.2186 11.4285 10.3396C10.635 10.4607 9.85508 8.43007 9.06167 8.5511C8.26826 8.67213 7.81104 11.9668 7.71691 13.1502C7.42751 16.3981 7.42751 19.6653 7.71691 22.9132C8.59741 28.4443 8.79613 34.0625 8.30861 39.642C7.76076 43.8859 6.86574 48.0776 5.63253 52.1752Z" fill="#DEE1E9"/>
      <path d="M28.0437 77.6316C27.1965 78.7881 26.6182 78.5864 24.3456 80.5901C22.3553 82.3517 22.5436 82.7282 20.9299 84.0057C19.5406 85.3854 17.7049 86.2246 15.7525 86.3725C14.3826 86.0674 13.0795 85.5161 11.9065 84.7454C10.0048 83.8013 8.17548 82.718 6.43335 81.5045C4.9417 80.6102 3.58357 79.5102 2.39906 78.2367C0.247444 75.2379 0.987063 71.2305 1.95529 65.9591C2.26459 64.2915 2.78904 62.3954 3.82451 58.5763C3.82451 58.5763 3.82451 58.4687 3.82451 58.4015C4.57758 56.6264 9.60698 54.1117 9.60698 54.1117C11.4617 53.0896 13.5548 52.579 15.6719 52.6325C17.5844 52.7344 19.4539 53.2384 21.1585 54.1117C22.3037 54.6364 23.39 55.281 24.3994 56.0347C24.4935 56.3171 24.5876 56.613 24.7086 56.9223C25.6634 59.437 26.282 59.1949 27.3982 61.9517C28.4635 64.8932 28.9653 68.0091 28.8774 71.1364C29.0119 74.5252 29.0119 76.2465 28.0437 77.6316Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth14",
  props: {
    status: {}
  },
}
</script>
