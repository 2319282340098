<template>
  <div>
    <div>
      <a @click="showMode = !showMode"
         class="text-primary d-block mb-2 pointer">
        {{ showMode ? $t('hide') : $t('add_tooth_jaw') }}
      </a>
    </div>
    <PatientFormula mode="select"
                    class="mb-3"
                    v-if="showMode"
                    @input="patientFormulaPressed"/>
  </div>
</template>

<script>
import PatientFormula from "@/components/pages/patients/PatientFormula"
import { mapState } from "vuex"

export default {
  name: "VisitPayAddTooth",
  components: {
    PatientFormula
  },
  data() {
    return {
      showMode: false
    }
  },
  computed: {
    ...mapState({
      // loading: state => state.dom.loading,
      // patient: state => state.patient.patient,
      // clinic: state => state.auth.clinic,
      // visit: state => state.patient.visit,
      // rights: state => state.auth.rights,
      // branch: state => state.auth.branch,
      services: state => state.treatment.services,
      diagnoses: state => state.treatment.diagnoses,
      recordTeeth: state => state.teeth.recordTeeth,
    }),
    usedTeeth() {
      let arr = []
      for(const ind in this.services) {
        arr = arr.concat(this.copyObject(this.services[ind].teeth))
      }
      for(const ind in this.diagnoses) {
        arr = arr.concat(this.copyObject(this.diagnoses[ind].teeth))
      }
      return [...new Set(arr)]
    },
  },
  methods: {
    patientFormulaPressed(v) {
      v = v.toString()
      if(this.recordTeeth.includes(v) && this.usedTeeth.includes(v)) {
        this.$noty.error(this.$t('tooth_is_assigned'))
        return
      }
      this.$store.commit('toggleRecordTeeth', v)
    },
  }
}
</script>
