<template>
  <div v-if="hasRight" class="mb-4">
    <b-button v-spaceduck-collapse="`uis-c`" class="btn-collapse">UIS</b-button>
    <spaceduck-collapse id="uis-c" @show="opened">
      <div class="mt-3 pb-5">
        <div class="mt-1" v-html="$t('uis_integration_instructions', { url: `https://api2.dentist-plus.com/uis/call/${clinic.id}` })"></div>
        <div class="mt-2" v-if="!localLoading">

          <div v-for="(r, rind) in responsible"
               :key="`resp-${rind}`"
               :class="{'mt-3': rind > 0}">
            <b-row class="align-items-center">
              <b-col>
                <ServiceMultiSelect :label="$t('staff')"
                                    :dblclick="false"
                                    model="staff"
                                    :multiple="false"
                                    title="full_name"
                                    :dbsearch="true"
                                    v-model="responsible[rind].user"/>
              </b-col>
              <b-col>
                <SelectGroup :options="employees"
                             :label="$t('employee') + ' (UIS)'"
                             variant="white"
                             :showlabel="true"
                             v-model="responsible[rind].employeeId"
                             title="title"/>
                <!--                <InputGroup v-model="responsible[rind].tel"-->
                <!--                            :label="$t('phone')"/>-->
              </b-col>
              <b-col cols="auto">
                <a class="mt-3 d-inline-block pointer" @click="removeResponsible(rind)">
                  <RemoveSvg/>
                </a>
              </b-col>
            </b-row>
          </div>

          <a class="mt-3 btn btn-themed-outline btn-themed-squared px-3 d-inline-block pointer"
             @click="addResponsible">
            <AddSvg class="mr-2"/>{{ $t('add_user') }}
          </a>

          <InputGroup v-model="uis_secret"
                      class="mt-3"
                      :label="$t('integration_key')"/>
          <InputGroup v-model="uis_phone"
                      class="mt-3"
                      :label="$t('phone')"/>
          <button class="btn-themed btn-themed-squared d-inline-block mt-4"
                  :disabled="loading"
                  @click="save">
            {{ $t('save') }}
          </button>
        </div>
      </div>
    </spaceduck-collapse>
  </div>

</template>

<script>
import {mapState} from "vuex"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import {BinotelService, ClinicService} from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup"
import AddSvg from '@/assets/svg-vue/general/add.svg'
import RemoveSvg from '@/assets/svg-vue/general/trash.svg'
import SelectGroup from "@/components/form/SelectGroup.vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "UISIntegration",
  components: {
    SpaceduckCollapse,
    SelectGroup,
    InputGroup,
    ServiceMultiSelect,
    AddSvg,
    RemoveSvg
  },
  data() {
    return {
      responsible: [],
      employees: [],
      uis_secret: '',
      uis_phone: '',
      localLoading: false
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        uis: [],
        uis_secret: '',
        uis_phone: '',
      }
    },
    uisUsers() {
      return this.ppoSettings.uis ?? []
    },
    uisUsersIds() {
      return this.uisUsers.map(u => u?.id ?? u)
    },
    hasRight() {
      if(this.superadmin) return this.clinic.rights.includes('crm')
      return this.rights.includes('crm')
    }
  },
  async mounted() {
    this.uis_secret = this.ppoSettings.uis_secret
    this.uis_phone = this.ppoSettings.uis_phone
  },
  methods: {
    async getEmployees() {
      if(!this.uis_secret) return
      this.localLoading = true
      const res = await BinotelService.getUISEmployees()
      this.employees = res.data.map(item => ({
        id: item.id,
        title: item.full_name,
      }))
      this.localLoading = false
    },
    async opened() {
      await this.getInitialUsers()
      await this.getEmployees()
    },
    async save() {
      await ClinicService.savePPOAttribute([
        'uis',
        'uis_secret',
        'uis_phone',
      ], [
        this.responsible.map(x => ({
          id: x.user?.id ?? x.id,
          employeeId: x.employeeId
        })),
        this.uis_secret,
        this.uis_phone,
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
      await this.$store.dispatch('init')
      if(!this.employees.length) await this.getEmployees()
    },
    async getInitialUsers() {
      if(this.uisUsersIds.length) {
        let res = await ClinicService.getUsersList(this.uisUsersIds, this.superadmin)
        this.responsible = []
        this.uisUsers.forEach(obj => {
          const id = obj?.id ?? obj
          const user = res.data.find(user => user.id == id)
          if(user) {
            this.responsible.push({
              user,
              employeeId: obj?.employeeId ?? '',
            })
          }
        })
      }
    },
    addResponsible() {
      this.responsible.push({
        user: null,
        employeeId: '',
      })
    },
    removeResponsible(index) {
      this.responsible.splice(index, 1)
    }
  }
}
</script>
