import {accountingMonthAdapter} from "@/adapters/accounting/accountingMonth"

export default {
    primaryKey: "id",
    model: 'accountingmonth',
    order: 'date',
    sort: 'desc',
    pagination: 6,
    // showTotalRecords: true,
    actions: [
        // "add",
        // "edit",
        // "delete"
    ],
    adapter: accountingMonthAdapter,
    fields: [
        {
            id: 'date',
            title: 'date',
            tabletdclass: "order-mob-0",
            table: true,
            sortable: true,
            tdcomponent: 'AccountingDatatableTDDate',
        },
        {
            id: 'cash_inflow',
            title: 'cash_received',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'refunds',
            title: 'returns',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'revenue',
            title: 'revenue',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'materials_expense',
            title: 'materials_expense',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'salaries',
            title: 'salaries',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'other_expense',
            title: 'other_expense',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'other_income',
            title: 'other_income',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'rent',
            title: 'rent',
            format_number: true,
            table: false,
            form: {
                type: "number",
            }
        },
        {
            id: 'communal',
            title: 'communal',
            format_number: true,
            table: false,
            form: {
                type: "number",
            }
        },
        {
            id: 'total',
            title: 'total',
            format_number: true,
            table: true,
            sortable: false,
        },
        {
            id: 'status',
            title: 'closed',
            table: true,
            tdcomponent: 'AccountingDatatableStatusTD'
        },
    ]
}
