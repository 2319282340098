export const waitingListAdapter =
    ({
         id,
         doctor_id,
         client_id,
         recorded,
         interval_from,
         interval_to,
         time_from,
         time_to,
         comment,
     }) => ({
        id: id ?? null,
        doctor_id: doctor_id ?? null,
        client_id: client_id ?? null,
        interval_from: interval_from ?? null,
        interval_to: interval_to ?? null,
        // time_from: timeToString(time_from),
        // time_to: timeToString(time_to),
        time_from: time_from ?? null,
        time_to: time_to ?? null,
        comment: comment ?? null,
        recorded: recorded ? 1 : 0,
    })

// function timeToString(time) {
//     if(!time || !time.HH) return null
//     let str = time.HH+':'
//     str += (time.mm ? time.mm : '00')
//     str += ':00'
//     return str
// }
