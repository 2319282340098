<template>
  <section id="waiting_list" class="waiting_list">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print">
        <div class="col order-md-0 order-0">
          <h1>
            <span class="d-inline-block align-middle">{{ $t('waiting_list') }}</span>
          </h1>
        </div>
        <div class="col-md-auto col mt-md-0 mt-2 table-view-navigator order-md-1 order-3">
          <SearchTable/>
        </div>
        <div class="col-auto table-view-navigator order-md-2 mt-md-0 mt-2 order-4">
          <DataTableFilterButton/>
        </div>
        <div class="col-auto table-view-navigator order-md-4 order-1">
          <DataTableExcel/>
        </div>
        <div class="col-auto table-view-navigator  order-md-5 order-2">
          <AddObject module="patients"/>
        </div>
      </div>
    </div>
    <div>
      <DataTable :options="options"
                 @totalRows="totalRows"
                 module="patients"/>
      <PatientSMSModal/>
    </div>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTable from "@/components/parts/general/DataTable"
import options from "../../../data/patients/waiting_list"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal"
import {mapState} from "vuex"

export default {
  name: "WaitingList",
  components: {
    DataTable,
    AddObject,
    DataTableExcel,
    DataTableFilterButton,
    SearchTable,
    PatientSMSModal,
  },
  head() {
    return {
      title: this.$t('waiting_list'),
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch,
      rights: state => state.auth.rights,
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  methods: {
    totalRows(rows) {
      this.$store.commit('setHeaderCounter', { counter: 'waiting', value: rows } )
    }
  }
}
</script>

<style scoped>

</style>
