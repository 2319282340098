<template>
  <b-col md="3" cols="6" class="docitemcol d-md-flex d-block">
    <label class="form-label">{{ $t(label) }}</label>
    <span>{{ title }}</span>
  </b-col>
</template>

<script>
export default {
  name: "DocumentItemCol",
  props: {
    title: {
      type: String
    },
    label: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
  .docitemcol {
    margin-bottom: 12px;
    font-size: 15px;
    .form-label {
      margin-bottom: 0;
      min-width: 80px;
    }
  }
  @media screen and (max-width: 768px) {
    .docitemcol {
      font-size: 12px;
      .form-label {
        display: block;
      }
    }
  }
</style>
