<template>
<svg viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.0625 4.0625H6.5V6.5H4.0625V4.0625Z" fill="#D8F0FC"/>
  <path d="M4.0625 45.5H6.5V47.9375H4.0625V45.5Z" fill="#D8F0FC"/>
  <path
      d="M7.3125 12.1875V37.8056L17.0625 25.1875L23.3269 33.2942L35.2422 17.875L44.6875 30.098V12.1875H7.3125ZM17.0625 21.9375C16.4197 21.9375 15.7914 21.7469 15.2569 21.3898C14.7224 21.0327 14.3059 20.5251 14.0599 19.9312C13.8139 19.3374 13.7495 18.6839 13.8749 18.0535C14.0003 17.423 14.3099 16.8439 14.7644 16.3894C15.2189 15.9349 15.798 15.6253 16.4285 15.4999C17.0589 15.3745 17.7124 15.4389 18.3062 15.6849C18.9001 15.9309 19.4077 16.3474 19.7648 16.8819C20.1219 17.4164 20.3125 18.0447 20.3125 18.6875C20.3125 19.5495 19.9701 20.3761 19.3606 20.9856C18.7511 21.5951 17.9245 21.9375 17.0625 21.9375Z"
      fill="#D8F0FC"/>
  <path d="M45.5 4.0625H47.9375V6.5H45.5V4.0625Z" fill="#D8F0FC"/>
  <path d="M45.5 45.5H47.9375V47.9375H45.5V45.5Z" fill="#D8F0FC"/>
  <path d="M1.625 1.625H6.5V6.5H1.625V1.625Z" fill="#D8F0FC"/>
  <path d="M45.5 1.625H50.375V6.5H45.5V1.625Z" fill="#D8F0FC"/>
  <path d="M1.625 45.5H6.5V50.375H1.625V45.5Z" fill="#D8F0FC"/>
  <path d="M45.5 45.5H50.375V50.375H45.5V45.5Z" fill="#D8F0FC"/>
  <path
      d="M7.3125 12.1875V37.8056L17.0625 25.1875L23.3269 33.2942L35.2422 17.875L44.6875 30.098V12.1875H7.3125ZM17.0625 21.9375C16.4197 21.9375 15.7914 21.7469 15.2569 21.3898C14.7224 21.0327 14.3059 20.5251 14.0599 19.9312C13.8139 19.3374 13.7495 18.6839 13.8749 18.0535C14.0003 17.423 14.3099 16.8439 14.7644 16.3894C15.2189 15.9349 15.798 15.6253 16.4285 15.4999C17.0589 15.3745 17.7124 15.4389 18.3062 15.6849C18.9001 15.9309 19.4077 16.3474 19.7648 16.8819C20.1219 17.4164 20.3125 18.0447 20.3125 18.6875C20.3125 19.5495 19.9701 20.3761 19.3606 20.9856C18.7511 21.5951 17.9245 21.9375 17.0625 21.9375Z"
      fill="#D8F0FC"/>
  <path
      d="M50.375 7.3125C50.5905 7.3125 50.7972 7.2269 50.9495 7.07452C51.1019 6.92215 51.1875 6.71549 51.1875 6.5V1.625C51.1875 1.40951 51.1019 1.20285 50.9495 1.05048C50.7972 0.898102 50.5905 0.8125 50.375 0.8125H45.5C45.2845 0.8125 45.0778 0.898102 44.9255 1.05048C44.7731 1.20285 44.6875 1.40951 44.6875 1.625V3.25H7.3125V1.625C7.3125 1.40951 7.2269 1.20285 7.07452 1.05048C6.92215 0.898102 6.71549 0.8125 6.5 0.8125H1.625C1.40951 0.8125 1.20285 0.898102 1.05048 1.05048C0.898102 1.20285 0.8125 1.40951 0.8125 1.625V6.5C0.8125 6.71549 0.898102 6.92215 1.05048 7.07452C1.20285 7.2269 1.40951 7.3125 1.625 7.3125H3.25V44.6875H1.625C1.40951 44.6875 1.20285 44.7731 1.05048 44.9255C0.898102 45.0778 0.8125 45.2845 0.8125 45.5V50.375C0.8125 50.5905 0.898102 50.7972 1.05048 50.9495C1.20285 51.1019 1.40951 51.1875 1.625 51.1875H6.5C6.71549 51.1875 6.92215 51.1019 7.07452 50.9495C7.2269 50.7972 7.3125 50.5905 7.3125 50.375V48.75H44.6875V50.375C44.6875 50.5905 44.7731 50.7972 44.9255 50.9495C45.0778 51.1019 45.2845 51.1875 45.5 51.1875H50.375C50.5905 51.1875 50.7972 51.1019 50.9495 50.9495C51.1019 50.7972 51.1875 50.5905 51.1875 50.375V45.5C51.1875 45.2845 51.1019 45.0778 50.9495 44.9255C50.7972 44.7731 50.5905 44.6875 50.375 44.6875H48.75V7.3125H50.375ZM2.4375 5.6875V2.4375H5.6875V5.6875H2.4375ZM5.6875 49.5625H2.4375V46.3125H5.6875V49.5625ZM44.6875 45.5V47.125H7.3125V45.5C7.3125 45.2845 7.2269 45.0778 7.07452 44.9255C6.92215 44.7731 6.71549 44.6875 6.5 44.6875H4.875V7.3125H6.5C6.71549 7.3125 6.92215 7.2269 7.07452 7.07452C7.2269 6.92215 7.3125 6.71549 7.3125 6.5V4.875H44.6875V6.5C44.6875 6.71549 44.7731 6.92215 44.9255 7.07452C45.0778 7.2269 45.2845 7.3125 45.5 7.3125H47.125V44.6875H45.5C45.2845 44.6875 45.0778 44.7731 44.9255 44.9255C44.7731 45.0778 44.6875 45.2845 44.6875 45.5ZM49.5625 46.3125V49.5625H46.3125V46.3125H49.5625ZM46.3125 5.6875V2.4375H49.5625V5.6875H46.3125Z"
      fill="#0787C9"/>
  <path
      d="M44.6875 11.375H7.3125C7.09701 11.375 6.89035 11.4606 6.73798 11.613C6.5856 11.7653 6.5 11.972 6.5 12.1875V39.8125C6.5 40.028 6.5856 40.2347 6.73798 40.387C6.89035 40.5394 7.09701 40.625 7.3125 40.625H44.6875C44.903 40.625 45.1097 40.5394 45.262 40.387C45.4144 40.2347 45.5 40.028 45.5 39.8125V12.1875C45.5 11.972 45.4144 11.7653 45.262 11.613C45.1097 11.4606 44.903 11.375 44.6875 11.375ZM8.125 39V38.0829L17.0625 26.5159L26.7089 39H8.125ZM43.875 39H28.7625L24.3537 33.2942L35.2422 19.2034L43.875 30.3753V39ZM43.875 27.7174L35.8851 17.3784C35.8092 17.2801 35.7117 17.2006 35.6003 17.1459C35.4888 17.0912 35.3663 17.0627 35.2422 17.0627C35.118 17.0627 34.9955 17.0912 34.8841 17.1459C34.7726 17.2006 34.6752 17.2801 34.5993 17.3784L23.3269 31.9658L17.7054 24.6909C17.6295 24.5926 17.532 24.5131 17.4206 24.4584C17.3092 24.4037 17.1867 24.3752 17.0625 24.3752C16.9383 24.3752 16.8158 24.4037 16.7044 24.4584C16.593 24.5131 16.4955 24.5926 16.4196 24.6909L8.125 35.425V13H43.875V27.7174Z"
      fill="#0787C9"/>
  <path
      d="M17.0625 22.75C17.866 22.75 18.6514 22.5117 19.3195 22.0654C19.9876 21.619 20.5083 20.9845 20.8158 20.2422C21.1232 19.4998 21.2037 18.683 21.0469 17.895C20.8902 17.1069 20.5033 16.383 19.9351 15.8149C19.367 15.2467 18.6431 14.8598 17.8551 14.7031C17.067 14.5463 16.2502 14.6268 15.5078 14.9342C14.7655 15.2417 14.131 15.7624 13.6847 16.4305C13.2383 17.0986 13 17.884 13 18.6875C13.0012 19.7646 13.4296 20.7972 14.1912 21.5588C14.9528 22.3204 15.9854 22.7488 17.0625 22.75ZM17.0625 16.25C17.5446 16.25 18.0159 16.393 18.4167 16.6608C18.8175 16.9286 19.13 17.3093 19.3145 17.7547C19.4989 18.2001 19.5472 18.6902 19.4532 19.163C19.3591 19.6359 19.127 20.0702 18.7861 20.4111C18.4452 20.752 18.0109 20.9841 17.538 21.0782C17.0652 21.1722 16.5751 21.124 16.1297 20.9395C15.6843 20.755 15.3036 20.4426 15.0358 20.0417C14.768 19.6409 14.625 19.1696 14.625 18.6875C14.6258 18.0413 14.8828 17.4217 15.3398 16.9648C15.7967 16.5078 16.4163 16.2508 17.0625 16.25Z"
      fill="#0787C9"/>
</svg>
</template>
