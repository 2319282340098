<template>
  <div>
    <DataTable :options="options"
               module="services"/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/services/complex_service"
import { mapState } from "vuex"

export default {
  name: "ServiceComplexServices",
  head() {
    return {
      title: this.$t('services'),
    }
  },
  components: {
    DataTable,
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
    }),
  },
  data() {
    return {
      options: options,
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
}
</script>
