<template>
  <div :class="`status-${object.status}`">
    {{ $t(object.status) }}
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDTreatmentStatus",
  props: {
    object: {
      type: Object
    }
  },
  computed: {

  }
}
</script>

<style scoped lang="scss">
  .status-treatment_complete {
    color: $green-color;
  }
  .status-active, .status-rejected, .status-stopped {
    color: $danger-red;
  }

</style>
