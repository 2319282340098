import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import AccountingGeneral from "@/components/pages/accounting/AccountingGeneral"
import AccountingMain from "@/components/pages/accounting/AccountingMain"
import AccountingMonthGeneral from "@/components/pages/accounting/AccountingMonthGeneral"
import AccountingMonthSummary from "@/components/pages/accounting/AccountingMonthSummary"
import AccountingMonthSalaries from "@/components/pages/accounting/AccountingMonthSalaries"
import AccountingMonthMaterials from "@/components/pages/accounting/AccountingMonthMaterials"
import AccountingMonthOtherExpense from "@/components/pages/accounting/AccountingMonthOtherExpense"
import AccountingMonthOtherIncome from "@/components/pages/accounting/AccountingMonthOtherIncome"
import AccountingMonthOtherIncomeCategory from "@/components/pages/accounting/AccountingMonthOtherIncomeCategory"

export default {
    path: '/accounting',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
    // component:() => import('../components/pages/accounting/AccountingGeneral.vue'),
    component: AccountingGeneral,
    children: [
        {
            path: '', name: "AccountingMain",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
            // component: () => import('../components/pages/accounting/AccountingMain.vue')
            component: AccountingMain
        },
        {
            path: ':id',
            meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
            // component: () => import('../components/pages/accounting/AccountingMonthGeneral.vue'),
            component: AccountingMonthGeneral,
            children: [
                // { path: '', redirect: '/:id/summary' },
                { path: '', redirect: to => ({ name: 'AccountingMonthSummary', params: { id: to.params.id } }) },
                {
                    path: 'summary', name: "AccountingMonthSummary",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
                    // component: () => import('../components/pages/accounting/AccountingMonthSummary.vue')
                    component: AccountingMonthSummary
                },
                {
                    path: 'salaries', name: "AccountingMonthSalaries",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
                    // component: () => import('../components/pages/accounting/AccountingMonthSalaries.vue')
                    component: AccountingMonthSalaries
                },
                {
                    path: 'materials', name: "AccountingMonthMaterials",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
                    // component: () => import('../components/pages/accounting/AccountingMonthMaterials.vue')
                    component: AccountingMonthMaterials
                },
                {
                    path: 'other_expense', name: "AccountingMonthOtherExpense",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
                    // component: () => import('../components/pages/accounting/AccountingMonthOtherExpense.vue')
                    component: AccountingMonthOtherExpense
                },
                {
                    path: 'other_income', name: "AccountingMonthOtherIncome",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
                    // component: () => import('../components/pages/accounting/AccountingMonthOtherIncome.vue')
                    component: AccountingMonthOtherIncome
                },
                {
                    path: 'categories', name: "AccountingMonthOtherIncomeCategory",
                    meta: { middleware: [authorized, paid], layout: "default", rights: ['accounting'] },
                    // component: () => import('../components/pages/accounting/AccountingMonthOtherIncomeCategory.vue')
                    component: AccountingMonthOtherIncomeCategory
                },
            ]
        },
    ]
}
