export default {
    additional: "Additional",
    alternative: "Alternative",
    amount_completed: "Amount completed",
    apply_plan_treatment: "Apply treatment plan",
    are_you_sure_you_want_to_cancel_plan: "Are you sure you want to cancel the plan?",
    calls_planning: "Calls planning",
    choose_criterias_for_fill: "Select the required criteria to fill out",
    comments: "Comments",
    completed_sum: "Completed sum",
    grouped: "Grouped",
    grouped_by_teeth: "Grouped by teeth",
    grouped_by_services: "Grouped by services",
    last_update: "Last update",
    level_description: "Level description",
    main_complaints: "Main complaints",
    not_signed: "Not signed",
    notification_date: "Notification date",
    plan: "Plan",
    plan_treatment_status: "Treatment plan status",
    plan_type: "Plan type",
    recommended: "Recommended",
    reset_all: "Reset all",
    route_list: "Route list",
    select_all: "Select all",
    sign_without_upload: "Sign without upload",
    signed_at: "Signed",
    total_sum: "Total sum",
    treatment_plan_amount: "Treatment plan amount",
}
