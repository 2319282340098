<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M13.6774 0H2.32257C1.18399 0 0.258057 0.925935 0.258057 2.06452V11.3548C0.258057 12.4934 1.18399 13.4194 2.32257 13.4194V15.4839C2.32257 15.6841 2.43819 15.8668 2.61986 15.9515C2.68902 15.9835 2.76438 16 2.8387 16C2.95638 16 3.07509 15.9587 3.16902 15.8803L6.12232 13.4194H13.6774C14.816 13.4194 15.7419 12.4934 15.7419 11.3548V2.06452C15.7419 0.925935 14.816 0 13.6774 0ZM14.7097 11.3548C14.7097 11.9236 14.2472 12.3871 13.6774 12.3871H5.93548C5.8147 12.3871 5.69806 12.4294 5.60515 12.5068L3.35483 14.3814V12.9032C3.35483 12.6183 3.12361 12.3871 2.8387 12.3871H2.32257C1.75277 12.3871 1.29031 11.9236 1.29031 11.3548V2.06452C1.29031 1.49574 1.75277 1.03226 2.32257 1.03226H13.6774C14.2472 1.03226 14.7097 1.49574 14.7097 2.06452V11.3548Z"/>
  <path
      d="M3.8709 6.19354C3.58703 6.19354 3.35477 5.96232 3.35477 5.67742C3.35477 5.39251 3.58703 5.16129 3.8709 5.16129H4.90316C5.18806 5.16129 5.41928 4.93006 5.41928 4.64516C5.41928 4.36025 5.18806 4.12903 4.90316 4.12903H3.8709C3.01722 4.12903 2.32251 4.82374 2.32251 5.67742C2.32251 6.53109 3.01722 7.2258 3.8709 7.2258C4.15477 7.2258 4.38703 7.45703 4.38703 7.74193C4.38703 8.02683 4.15477 8.25806 3.8709 8.25806H2.83864C2.55374 8.25806 2.32251 8.48929 2.32251 8.77419C2.32251 9.05909 2.55374 9.29032 2.83864 9.29032H3.8709C4.72457 9.29032 5.41928 8.59561 5.41928 7.74193C5.41928 6.88825 4.72457 6.19354 3.8709 6.19354Z"/>
  <path
      d="M12.129 6.19354C11.8451 6.19354 11.6128 5.96232 11.6128 5.67742C11.6128 5.39251 11.8451 5.16129 12.129 5.16129H13.1612C13.4461 5.16129 13.6773 4.93006 13.6773 4.64516C13.6773 4.36025 13.4461 4.12903 13.1612 4.12903H12.129C11.2753 4.12903 10.5806 4.82374 10.5806 5.67742C10.5806 6.53109 11.2753 7.2258 12.129 7.2258C12.4128 7.2258 12.6451 7.45703 12.6451 7.74193C12.6451 8.02683 12.4128 8.25806 12.129 8.25806H11.0967C10.8118 8.25806 10.5806 8.48929 10.5806 8.77419C10.5806 9.05909 10.8118 9.29032 11.0967 9.29032H12.129C12.9826 9.29032 13.6773 8.59561 13.6773 7.74193C13.6773 6.88825 12.9826 6.19354 12.129 6.19354Z"/>
  <path
      d="M9.15102 4.14245C8.91256 4.08877 8.67721 4.19922 8.57089 4.41393L8.00005 5.5556L7.42921 4.41393C7.32289 4.19922 7.0865 4.08877 6.84908 4.14245C6.61579 4.19716 6.45166 4.40567 6.45166 4.64515V8.77417C6.45166 9.05908 6.68289 9.2903 6.96779 9.2903C7.25269 9.2903 7.48392 9.05908 7.48392 8.77417V6.83147L7.53863 6.93986C7.71411 7.28979 8.28702 7.28979 8.4625 6.93986L8.51618 6.83147V8.77417C8.51618 9.05908 8.7474 9.2903 9.03231 9.2903C9.31721 9.2903 9.54843 9.05908 9.54843 8.77417V4.64515C9.54843 4.40567 9.38431 4.19716 9.15102 4.14245Z"/>
</svg>
</template>
