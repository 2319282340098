<template>
  <div>
    <div>{{ $t('status') }}: {{ lead.status }}</div>
    <div><a class="pointer text-primary" @click="leadClicked">{{ lead.title }}</a></div>
    <div v-html="text"></div>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import { getCurrentInstance } from "vue"
import { copyObject } from "@/extensions/prototypes/prototypes"
import { EloquentService } from "@/services/api.service"

const store = useStore()
const instance = (getCurrentInstance())

const props = defineProps({
  text: {
    type: String,
    required: true
  },
  lead: {
    type: Object
  }
})

const leadClicked = async () => {
  const bvModal = instance?.ctx?._bv__modal
  const leadFull = await EloquentService.show('lead', props.lead.id)
  store.commit('setLead', copyObject(leadFull.data))
  bvModal.show('lead-show-modal')
}
</script>
