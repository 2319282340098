<template>
  <div>
    {{ oneLinerFromProperty(object.doctors, 'full_name') }}
  </div>
</template>

<script>

import {oneLinerFromProperty} from "@/extensions/filters/filters"

export default {
  name: "StaffDatatableTDChairDoctors",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    oneLinerFromProperty
  }
}
</script>

<style scoped>

</style>
