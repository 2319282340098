<template>
  <div>
    <b-row>
      <b-col md="12">
        <ServiceMultiSelect :label="$t('patient')"
                            :dblclick="false"
                            model="patient"
                            :multiple="false"
                            title="full_name"
                            :dbsearch="true"
                            v-model="object.patient"/>
      </b-col>
      <b-col md="6">
        <InputGroup :label="$t('name')"
                    :show-errors="true"
                    validation-id="fname"
                    :required="true"
                    v-model="object.fname"/>
      </b-col>
      <b-col md="6">
        <InputGroup :label="$t('surname')"
                    :show-errors="true"
                    validation-id="lname"
                    v-model="object.lname"/>
      </b-col>
      <b-col md="6">
        <InputGroup :label="$t('phone')"
                    :show-errors="true"
                    validation-id="phone"
                    :required="true"
                    v-model="object.phone"/>
      </b-col>
      <b-col md="6">
        <InputGroup :label="$t('email')"
                    :show-errors="true"
                    validation-id="email"
                    v-model="object.email"/>
      </b-col>
      <b-col md="12">
        <ServiceMultiSelect :label="$t('responsible')"
                            :dblclick="false"
                            model="staff"
                            :multiple="true"
                            title="full_name"
                            :dbsearch="true"
                            v-model="object.multiple_responsible"/>
      </b-col>
    </b-row>

  </div>
</template>

<script>

import {mapState} from "vuex"
import InputGroup from "@/components/form/InputGroup"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import {EloquentService} from "@/services/api.service"

export default {
  name: "CRMDatatableLeadFormCard",
  components: {
    ServiceMultiSelect,
    InputGroup,
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      model: state => state.datatable.viewModel,
      loading: state => state.dom.loading
    }),
    edit() {
      return !!this.object[this.options.primaryKey]
    },
    patient() {
      return this.object.patient
    },
    responsible() {
      return this.object.responsible
    }
  },
  methods: {

  },
  mounted() {
    if(this.object.responsible_user_id && !this.object.responsible) {
      EloquentService.show('staff', this.object.responsible_user_id).then(res => {
        this.object.responsible = res.data
      })
    }
  },
  watch: {
    patient: {
      handler(val) {
        if (val) {
          this.object.phone = val.phone ? val.phone : (this.object.phone ? this.object.phone : null)
          this.object.fname = val.fname
          this.object.lname = val.lname
          this.object.email = val.email ? val.email : (this.object.email ? this.object.email : null)
          this.object.patient_id = val.id
          console.log(this.object)
        }
      }
    },
    responsible(val) {
      this.object.responsible_user_id = val.id
    }

  }
}
</script>

<style scoped lang="scss">
.close-svg {
  width: 12px;
  height: 12px;
  position: relative;
  top: -1px;
}
</style>
