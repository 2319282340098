<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>
    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M9.70874 2.40276L11.7925 1.133H15.2762L17.3762 2.40276L15.7645 3.94927L17.8483 5.49578L15.9599 7.04229L18.2552 8.73531L16.432 10.2818L18.7273 11.6818L16.7087 13.0981L18.7273 14.7097L17.0343 16.2562L19.2645 17.6725L17.5064 19.1376L19.8017 20.6841L17.6529 22.2306L19.8017 23.8423L18.2552 25.1934L20.339 26.7399L18.5157 28.2213L20.5994 29.7678L18.7924 31.5097L19.8017 33.1864V47.4469L17.9134 54.7724H11.7925" fill="#8889BE"/>
      <path d="M17.116 2.40276L15.0323 1.133H11.5649L9.44859 2.40276L11.0765 3.94927L8.9765 5.49578L10.8649 7.04229L8.5858 8.73531L10.3928 10.2818L8.11371 11.6818L10.1323 13.0981L8.11371 14.7097L9.79045 16.2562L7.5765 17.6725L9.31836 19.1376L7.03929 20.6841L9.18813 22.2306L7.03929 23.8423L8.5858 25.1934L6.50209 26.7399L8.30906 28.2213L6.22534 29.7678L8.04859 31.5097L7.03929 33.1864V47.4469L8.91138 54.7724H12.8184" fill="#8889BE"/>
      <path d="M25.2076 64.5769C24.6853 66.9948 23.679 69.282 22.2492 71.3007C21.102 73.1439 19.4625 74.6297 17.5156 75.5905C15.8563 76.4333 13.9618 76.6938 12.1366 76.3301C11.007 76.0746 10.9397 75.7384 7.83333 73.8154C4.72694 71.8924 4.33696 71.9596 3.54355 71.1259C2.75014 70.2921 1.79536 69.028 1.32469 65.061C1.00485 61.3348 1.30476 57.5814 2.21223 53.9532C2.40946 52.8595 2.59773 51.7703 2.77703 50.6855L7.24164 47.0008C8.7357 45.4565 10.7359 44.5018 12.8762 44.3113C13.9724 44.234 15.0732 44.371 16.1171 44.7147L22.1954 48.1708C23.5401 51.5327 24.347 52.5009 25.0059 55.567C25.7551 58.5162 25.824 61.5971 25.2076 64.5769Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M5.26978 1.70837C2.68784 7.84048 4.08639 19.6206 4.08639 19.6206C5.13471 27.8828 5.03515 36.2503 3.79054 44.4852C3.1854 48.143 2.68784 51.8142 2.01546 55.445C1.10128 59.0721 0.801282 62.8266 1.12791 66.5528C1.58513 70.5198 2.67439 71.9318 3.34677 72.6176C4.01915 73.3035 4.40913 73.29 7.63656 75.3071C10.864 77.3243 10.8102 77.5664 11.9263 77.8219C13.7515 78.1888 15.647 77.9281 17.3054 77.0822C19.2551 76.1257 20.8957 74.6389 22.0389 72.7924C23.4687 70.7738 24.4751 68.4866 24.9974 66.0686C25.6356 63.0843 25.585 59.9937 24.8495 57.0318C24.1906 53.9658 23.3703 52.9976 22.0389 49.6357C21.3666 47.9278 20.8959 46.4351 19.0805 38.5279C17.7357 32.3689 16.7271 28.2809 15.3824 21.6512C14.5083 17.4286 12.9349 13.3406 12.4105 9.06422C12.2491 7.71946 11.8188 3.68517 9.30406 1.22426C8.87374 0.793936 7.81138 -0.214621 6.78936 0.0408832C5.76734 0.296388 5.4177 1.34529 5.26978 1.70837Z" fill="#DEE1E9"/>-->
<!--      <path d="M25.016 66.0706C24.4937 68.4886 23.4873 70.7758 22.0575 72.7944C20.9104 74.6376 19.2708 76.1235 17.324 77.0842C15.6646 77.927 13.7701 78.1875 11.9449 77.8238C10.8153 77.5683 10.7481 77.2321 7.64168 75.3091C4.53529 73.3861 4.14531 73.4534 3.3519 72.6196C2.55849 71.7859 1.60371 70.5218 1.13304 66.5547C0.8132 62.8285 1.11311 59.0751 2.02058 55.447C2.21781 54.3533 2.40608 53.264 2.58538 52.1792L7.04999 48.4946C8.54405 46.9502 10.5442 45.9955 12.6845 45.8051C13.7808 45.7277 14.8816 45.8647 15.9254 46.2085L22.0037 49.6645C23.3485 53.0264 24.1554 53.9947 24.8143 57.0607C25.5634 60.0099 25.6324 63.0908 25.016 66.0706Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--      <path opacity="0.2" d="M13.193 54.9367C12.1172 55.1249 10.1404 50.6603 9.15874 46.3705L8.21741 34.0256C8.04259 30.0989 7.93501 26.8984 7.86777 24.693C7.71985 19.6904 7.77364 19.0719 7.58537 16.9472C7.11471 11.4874 7.30272 1.53827 7.58511 1.41724C7.86751 1.29621 7.86777 10.5864 8.71497 13.0742C9.5594 16.2105 10.0954 19.4218 10.3152 22.6624C10.6479 26.235 11.2048 29.7832 11.9827 33.286C12.7089 36.11 13.3275 37.4548 14.1882 41.5025C14.6404 43.3746 14.8795 45.2919 14.9009 47.2177C14.8908 48.7518 14.715 50.2802 14.3764 51.7765C13.9192 53.8474 13.6771 54.9367 13.193 54.9367Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M5.53589 2.06653C2.95395 8.19864 4.3525 19.9788 4.3525 19.9788C5.40082 28.241 5.30126 36.6085 4.05665 44.8434C3.45151 48.5011 2.95395 52.1723 2.28157 55.8032C1.3674 59.4302 1.0674 63.1848 1.39403 66.9109C1.85125 70.878 2.9405 72.2899 3.61288 72.9758C4.28526 73.6616 4.67524 73.6482 7.90267 75.6653C11.1301 77.6824 11.0763 77.9245 12.1925 78.18C14.0176 78.5469 15.9131 78.2863 17.5715 77.4404C19.5212 76.4839 21.1618 74.9971 22.3051 73.1506C23.7348 71.1319 24.7412 68.8447 25.2635 66.4268C25.9017 63.4424 25.8511 60.3519 25.1156 57.39C24.4567 54.3239 23.6364 53.3557 22.3051 49.9938C21.6327 48.286 21.162 46.7933 19.3466 38.8861C18.0018 32.7271 16.9933 28.639 15.6485 22.0093C14.7744 17.7868 13.201 13.6987 12.6766 9.42237C12.5152 8.07761 12.0849 4.04333 9.57018 1.58241C9.13985 1.15209 8.07749 0.143533 7.05547 0.399038C6.03345 0.654542 5.68382 1.70344 5.53589 2.06653Z" fill="#DEE1E9"/>
        <mask id="415" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="27" height="34">
          <path d="M25.2821 66.4289C24.7598 68.8468 23.7534 71.134 22.3236 73.1527C21.1765 74.9959 19.5369 76.4817 17.5901 77.4425C15.9308 78.2853 14.0363 78.5458 12.211 78.1821C11.0814 77.9266 11.0142 77.5904 7.9078 75.6674C4.8014 73.7444 4.41142 73.8116 3.61801 72.9778C2.8246 72.1441 1.86982 70.88 1.39915 66.913C1.07931 63.1868 1.37922 59.4334 2.2867 55.8052C2.48393 54.7115 2.67219 53.6222 2.8515 52.5375L7.3161 48.8528C8.81017 47.3085 10.8103 46.3538 12.9507 46.1633C14.0469 46.086 15.1477 46.223 16.1915 46.5667L22.2698 50.0228C23.6146 53.3847 24.4215 54.3529 25.0804 57.419C25.8295 60.3682 25.8985 63.4491 25.2821 66.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#415)">
          <path d="M25.2821 66.4877C24.7598 68.9056 23.7534 71.1928 22.3236 73.2115C21.1765 75.0547 19.5369 76.5405 17.5901 77.5013C15.9308 78.3441 14.0363 78.6046 12.211 78.2409C11.0814 77.9854 11.0142 77.6492 7.9078 75.7262C4.8014 73.8032 4.41142 73.8704 3.61801 73.0367C2.8246 72.2029 1.86982 70.9389 1.39915 66.9718C1.07931 63.2456 1.37922 59.4922 2.2867 55.8641C2.48393 54.7703 2.67219 53.6811 2.8515 52.5963L7.3161 48.9117C8.81017 47.3673 10.8103 46.4126 12.9507 46.2221C14.0469 46.1448 15.1477 46.2818 16.1915 46.6256L22.2698 50.0816C23.6146 53.4435 24.4215 54.4117 25.0804 57.4778C25.8295 60.427 25.8985 63.5079 25.2821 66.4877Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.7809 63.2012C12.8005 63.2012 11.2661 70.6693 11.2661 79.2012H13.1892H16.2661C16.2661 70.6693 14.7613 63.2012 13.7809 63.2012Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M14.2443 65.1942C12.9707 65.4157 10.6304 60.1609 9.46827 55.1119L8.35387 40.5821C8.14691 35.9604 8.01955 32.1934 7.93995 29.5976C7.76483 23.7097 7.82851 22.9816 7.60563 20.4809C7.04843 14.0548 7.271 2.34476 7.60532 2.20231C7.93964 2.05986 7.93995 12.9944 8.94291 15.9225C9.94258 19.6139 10.5771 23.3936 10.8374 27.2076C11.2312 31.4126 11.8905 35.5888 12.8115 39.7115C13.6712 43.0353 14.4035 44.6181 15.4224 49.3823C15.9577 51.5857 16.2408 53.8423 16.2661 56.109C16.2542 57.9146 16.046 59.7136 15.6452 61.4746C15.104 63.9121 14.8174 65.1942 14.2443 65.1942Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M5.26978 4.86554C2.68784 10.9977 4.08639 22.7778 4.08639 22.7778C5.13471 31.04 5.03515 39.4075 3.79054 47.6424C3.1854 51.3001 2.68784 54.9714 2.01546 58.6022C1.10128 62.2292 0.801282 65.9838 1.12791 69.7099C1.58513 73.677 2.67439 75.089 3.34677 75.7748C4.01915 76.4606 4.40913 76.4472 7.63656 78.4643C10.864 80.4815 10.8102 80.7235 11.9263 80.979C13.7515 81.3459 15.647 81.0853 17.3054 80.2394C19.2551 79.2829 20.8957 77.7961 22.0389 75.9496C23.4687 73.9309 24.4751 71.6438 24.9974 69.2258C25.6356 66.2415 25.585 63.1509 24.8495 60.189C24.1906 57.123 23.3703 56.1547 22.0389 52.7928C21.3666 51.085 20.8959 49.5923 19.0805 41.6851C17.7357 35.5261 16.7271 31.438 15.3824 24.8083C14.5083 20.5858 12.9349 16.4977 12.4105 12.2214C12.2491 10.8766 11.8188 6.84234 9.30406 4.38142C8.87374 3.9511 7.81138 2.94254 6.78936 3.19805C5.76734 3.45355 5.4177 4.50245 5.26978 4.86554Z" fill="#DEE1E9"/>
      <path d="M25.016 69.2278C24.4937 71.6458 23.4873 73.9329 22.0575 75.9516C20.9104 77.7948 19.2708 79.2807 17.324 80.2414C15.6646 81.0842 13.7701 81.3447 11.9449 80.981C10.8153 80.7255 10.7481 80.3893 7.64168 78.4663C4.53529 76.5433 4.14531 76.6105 3.3519 75.7768C2.55849 74.943 1.60371 73.679 1.13304 69.7119C0.8132 65.9857 1.11311 62.2323 2.02058 58.6042C2.21781 57.5105 2.40608 56.4212 2.58538 55.3364L7.04999 51.6518C8.54405 50.1074 10.5442 49.1527 12.6845 48.9623C13.7808 48.8849 14.8816 49.0219 15.9254 49.3657L22.0037 52.8217C23.3485 56.1836 24.1554 57.1519 24.8143 60.2179C25.5634 63.1671 25.6324 66.248 25.016 69.2278Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M13.193 58.0938C12.1172 58.2821 10.1404 53.8175 9.15874 49.5277L8.21741 37.1828C8.04259 33.2561 7.93501 30.0555 7.86777 27.8501C7.71985 22.8476 7.77364 22.229 7.58537 20.1043C7.11471 14.6446 7.30272 4.69543 7.58511 4.5744C7.86751 4.45338 7.86777 13.7436 8.71497 16.2314C9.5594 19.3677 10.0954 22.579 10.3152 25.8195C10.6479 29.3922 11.2048 32.9404 11.9827 36.4431C12.7089 39.2671 13.3275 40.6119 14.1882 44.6596C14.6404 46.5318 14.8795 48.449 14.9009 50.3749C14.8908 51.9089 14.715 53.4374 14.3764 54.9336C13.9192 57.0046 13.6771 58.0938 13.193 58.0938Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="7" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M4.58228 2.3833C2.00034 8.51541 3.39889 20.2955 3.39889 20.2955C4.44721 28.5577 4.34765 36.9252 3.10304 45.1602C2.4979 48.8179 2.00034 52.4891 1.32796 56.12C0.413782 59.747 0.113782 63.5015 0.440414 67.2277C0.897633 71.1947 1.98689 72.6067 2.65927 73.2925C3.33165 73.9784 3.72163 73.9649 6.94906 75.9821C10.1765 77.9992 10.1227 78.2413 11.2388 78.4968C13.064 78.8637 14.9595 78.6031 16.6179 77.7572C18.5676 76.8006 20.2082 75.3138 21.3514 73.4674C22.7812 71.4487 23.7876 69.1615 24.3099 66.7436C24.9481 63.7592 24.8975 60.6686 24.162 57.7068C23.5031 54.6407 22.6828 53.6725 21.3514 50.3106C20.6791 48.6027 20.2084 47.1101 18.393 39.2029C17.0482 33.0439 16.0396 28.9558 14.6949 22.3261C13.8208 18.1036 12.2474 14.0155 11.723 9.73915C11.5616 8.39438 11.1313 4.3601 8.61656 1.89919C8.18624 1.46886 7.12388 0.460305 6.10186 0.71581C5.07984 0.971315 4.7302 2.02022 4.58228 2.3833Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M4.58228 2.3833C2.00034 8.51541 3.39889 20.2955 3.39889 20.2955C4.44721 28.5577 4.34765 36.9252 3.10304 45.1602C2.4979 48.8179 2.00034 52.4891 1.32796 56.12C0.413782 59.747 0.113782 63.5015 0.440414 67.2277C0.897633 71.1947 1.98689 72.6067 2.65927 73.2925C3.33165 73.9784 3.72163 73.9649 6.94906 75.9821C10.1765 77.9992 10.1227 78.2413 11.2388 78.4968C13.064 78.8637 14.9595 78.6031 16.6179 77.7572C18.5676 76.8006 20.2082 75.3138 21.3514 73.4674C22.7812 71.4487 23.7876 69.1615 24.3099 66.7436C24.9481 63.7592 24.8975 60.6686 24.162 57.7068C23.5031 54.6407 22.6828 53.6725 21.3514 50.3106C20.6791 48.6027 20.2084 47.1101 18.393 39.2029C17.0482 33.0439 16.0396 28.9558 14.6949 22.3261C13.8208 18.1036 12.2474 14.0155 11.723 9.73915C11.5616 8.39438 11.1313 4.3601 8.61656 1.89919C8.18624 1.46886 7.12388 0.460305 6.10186 0.71581C5.07984 0.971315 4.7302 2.02022 4.58228 2.3833Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M2.19154 19.7645C2.19154 19.7645 0.792989 7.98434 3.37493 1.85223C3.52285 1.48915 3.87249 0.440248 4.89451 0.184743C5.91653 -0.0707615 6.97889 0.937795 7.40921 1.36812C9.92392 3.82903 10.3542 7.86332 10.5156 9.20808C11.0401 13.4844 12.6134 17.5725 13.4875 21.795C14.8323 28.4247 15.8409 32.5128 17.1856 38.6718C19.0011 46.579 19.4717 48.0717 20.1441 49.7795C21.4754 53.1414 21.4913 53.3264 21.4913 53.3264L14.4144 52.3579L8.2387 53.3264L0.665588 52.3579C1.33797 48.727 1.29055 48.2868 1.89569 44.6291C3.1403 36.3942 3.23986 28.0267 2.19154 19.7645Z" fill="#DEE1E9"/>
      <path d="M2.35446 46.2271C9.64313 44.7595 13.3293 45.7019 19.3365 49.9269" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M6.32258 34.1695L7.26391 46.5144L7.63192 53.766L9.54448 53.3015L11.5936 53.766L12.4816 51.9203C12.8202 50.4241 12.996 48.8956 13.006 47.3616C12.9846 45.4357 12.7455 43.5185 12.2933 41.6464C11.4327 37.5986 10.8141 36.2538 10.0879 33.4299C9.30995 29.9271 8.75302 26.3789 8.4204 22.8062C8.20053 19.5657 7.66456 16.3544 6.82014 13.2181C5.97294 10.7303 5.97268 1.44008 5.69028 1.56111C5.40788 1.68214 5.21987 11.6313 5.69054 17.091C5.8788 19.2157 5.82501 19.8343 5.97294 24.8368C6.04017 27.0422 6.14776 30.2428 6.32258 34.1695Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M3.19166 20.3524C3.19166 20.3524 1.79311 8.57227 4.37505 2.44015C4.52297 2.07707 4.87261 1.02817 5.89463 0.772664C6.91665 0.51716 7.97901 1.52572 8.40933 1.95604C10.924 4.41695 11.3544 8.45124 11.5157 9.796C12.0402 14.0723 13.6136 18.1604 14.4877 22.383C15.8324 29.0126 16.841 33.1007 18.1857 39.2597C20.0012 47.1669 20.4718 48.6596 21.1442 50.3674C22.4755 53.7293 23.2958 54.6976 23.9548 57.7636L1.12073 56.1768C1.79311 52.546 2.29067 48.8748 2.89581 45.217C4.14042 36.9821 4.23998 28.6146 3.19166 20.3524Z" fill="#DEE1E9"/>
      <path d="M21.1628 73.5262C22.5926 71.5075 23.599 69.2203 24.1213 66.8024C24.7377 63.8226 24.6687 60.7417 23.9196 57.7925C23.2606 54.7264 22.4538 53.7582 21.109 50.3963L15.0307 46.9403C13.9869 46.5965 12.8861 46.4595 11.7898 46.5368C9.64952 46.7273 7.64935 47.682 6.15528 49.2264L1.69067 52.911C1.51137 53.9958 1.32311 55.085 1.12588 56.1788C1.03816 56.5295 0.956115 56.8814 0.879761 57.2343L4.74232 59.0686L3.83522 61.7138L5.0428 64.1807L3.83522 66.4941L7.64431 68.0089L6.30559 71.0438L9.87311 70.2727L7.64431 74.6119L6.74698 76.0409C9.85337 77.9639 9.92061 78.3001 11.0502 78.5556C12.8754 78.9193 14.7699 78.6588 16.4293 77.816C18.3761 76.8552 20.0157 75.3694 21.1628 73.5262Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M2.11038 55.0693C12.7863 58.8426 15.1111 63.39 13.4856 75.3255" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M12.2983 55.6684C11.2225 55.8567 9.24571 51.3921 8.26403 47.1023L7.3227 34.7574C7.14788 30.8307 7.0403 27.6301 6.97306 25.4247C6.82514 20.4222 6.87893 19.8037 6.69066 17.6789C6.21999 12.2192 6.408 2.27005 6.6904 2.14902C6.9728 2.02799 6.97306 11.3182 7.82026 13.806C8.66468 16.9423 9.20065 20.1536 9.42052 23.3942C9.75314 26.9668 10.3101 30.515 11.088 34.0178C11.8142 36.8418 12.4328 38.1865 13.2934 42.2343C13.7457 44.1064 13.9848 46.0236 14.0062 47.9495C13.9961 49.4835 13.8203 51.012 13.4817 52.5082C13.0245 54.5792 12.7824 55.6684 12.2983 55.6684Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M4.84839 1.74809C2.26645 7.8802 3.665 19.6603 3.665 19.6603C4.71332 27.9225 4.61376 36.29 3.36915 44.5249C2.76401 48.1827 2.26645 51.8539 1.59407 55.4848C0.679896 59.1118 0.379895 62.8663 0.706528 66.5925C1.16375 70.5595 2.253 71.9715 2.92538 72.6573C3.59776 73.3432 3.98774 73.3297 7.21517 75.3469C10.4426 77.364 10.3888 77.6061 11.505 77.8616C13.3301 78.2285 15.2256 77.9678 16.884 77.122C18.8337 76.1654 20.4743 74.6786 21.6176 72.8322C23.0473 70.8135 24.0537 68.5263 24.576 66.1084C25.2142 63.124 25.1636 60.0334 24.4281 57.0716C23.7692 54.0055 22.9489 53.0373 21.6176 49.6754C20.9452 47.9675 20.4745 46.4749 18.6591 38.5677C17.3143 32.4087 16.3058 28.3206 14.961 21.6909C14.0869 17.4684 12.5135 13.3803 11.9891 9.10394C11.8277 7.75918 11.3974 3.72489 8.88268 1.26398C8.45235 0.833654 7.38999 -0.174903 6.36797 0.0806019C5.34595 0.336106 4.99632 1.38501 4.84839 1.74809Z" fill="#DEE1E9"/>
      <path d="M21.6361 72.8341C23.0659 70.8155 24.0723 68.5283 24.5946 66.1103C25.211 63.1306 25.142 60.0497 24.3929 57.1004C23.734 54.0344 22.9271 53.0662 21.5823 49.7043H18C17.4477 49.7043 17 50.152 17 50.7043V58.8971C12.3032 60.275 12.4824 60.3026 7.78732 58.8971V50.7043C7.78732 50.152 7.33961 49.7043 6.78732 49.7043H2.93051C2.75121 50.789 1.79643 54.393 1.5992 55.4867C0.691724 59.1148 0.391814 62.8683 0.711654 66.5945C1.18232 70.5615 2.1371 71.8256 2.93051 72.6593C3.72392 73.4931 4.1139 73.4258 7.2203 75.3489C10.3267 77.2719 10.3939 77.608 11.5235 77.8636C13.3488 78.2273 15.2433 77.9668 16.9026 77.1239C18.8494 76.1632 20.489 74.6773 21.6361 72.8341Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M4.84839 1.99888C2.26645 8.13099 3.665 19.9111 3.665 19.9111C4.71332 28.1733 4.61376 36.5408 3.36915 44.7757C2.76401 48.4335 2.26645 52.1047 1.59407 55.7355C0.679896 59.3626 0.379895 63.1171 0.706528 66.8433C1.16375 70.8103 2.253 72.2223 2.92538 72.9081C3.59776 73.594 3.98774 73.5805 7.21517 75.5976C10.4426 77.6148 10.3888 77.8569 11.505 78.1124C13.3301 78.4793 15.2256 78.2186 16.884 77.3727C18.8337 76.4162 20.4743 74.9294 21.6176 73.083C23.0473 71.0643 24.0537 68.7771 24.576 66.3591C25.2142 63.3748 25.1636 60.2842 24.4281 57.3224C23.7692 54.2563 22.9489 53.2881 21.6176 49.9262C20.9452 48.2183 20.4745 46.7256 18.6591 38.8184C17.3143 32.6594 16.3058 28.5714 14.961 21.9417C14.0869 17.7191 12.5135 13.6311 11.9891 9.35472C11.8277 8.00996 11.3974 3.97568 8.88268 1.51476C8.45235 1.08444 7.38999 0.0758831 6.36797 0.331388C5.34595 0.586892 4.99632 1.63579 4.84839 1.99888Z" fill="#DEE1E9"/>
      <path d="M21.6361 73.0849C23.0659 71.0663 24.0723 68.7791 24.5946 66.3611C25.211 63.3813 25.142 60.3004 24.3929 57.3512C23.734 54.2852 22.9271 53.3169 21.5823 49.955H18C17.4477 49.955 17 50.4027 17 50.955V59.1479C12.3032 60.5258 12.4824 60.5533 7.78732 59.1479V50.955C7.78732 50.4027 7.33961 49.955 6.78732 49.955H2.93051C2.75121 51.0398 1.79643 54.6438 1.5992 55.7375C0.691724 59.3656 0.391814 63.119 0.711654 66.8452C1.18232 70.8123 2.1371 72.0764 2.93051 72.9101C3.72392 73.7439 4.1139 73.6766 7.2203 75.5996C10.3267 77.5226 10.3939 77.8588 11.5235 78.1143C13.3488 78.478 15.2433 78.2176 16.9026 77.3747C18.8494 76.414 20.489 74.9281 21.6361 73.0849Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M4.84839 2.06653C2.26645 8.19864 3.665 19.9788 3.665 19.9788C4.71332 28.241 4.61376 36.6085 3.36915 44.8434C2.76401 48.5011 2.26645 52.1723 1.59407 55.8032C0.679896 59.4302 0.379895 63.1848 0.706528 66.9109C1.16375 70.878 2.253 72.2899 2.92538 72.9758C3.59776 73.6616 3.98774 73.6482 7.21517 75.6653C10.4426 77.6824 10.3888 77.9245 11.505 78.18C13.3301 78.5469 15.2256 78.2863 16.884 77.4404C18.8337 76.4839 20.4743 74.9971 21.6176 73.1506C23.0473 71.1319 24.0537 68.8447 24.576 66.4268C25.2142 63.4424 25.1636 60.3519 24.4281 57.39C23.7692 54.3239 22.9489 53.3557 21.6176 49.9938C20.9452 48.286 20.4745 46.7933 18.6591 38.8861C17.3143 32.7271 16.3058 28.639 14.961 22.0093C14.0869 17.7868 12.5135 13.6987 11.9891 9.42237C11.8277 8.07761 11.3974 4.04333 8.88268 1.58241C8.45235 1.15209 7.38999 0.143533 6.36797 0.399038C5.34595 0.654542 4.99632 1.70344 4.84839 2.06653Z" fill="#DEE1E9"/>
      <path d="M21.6361 73.1526C23.0659 71.1339 24.0723 68.8467 24.5946 66.4288C25.211 63.449 25.142 60.3681 24.3929 57.4189C23.734 54.3528 22.9271 53.3846 21.5823 50.0227H17V59.2155C12.3032 60.5934 12.4824 60.621 7.78732 59.2155V50.0227H2.93051C2.75121 51.1074 1.79643 54.7114 1.5992 55.8051C0.691724 59.4333 0.391814 63.1867 0.711654 66.9129C1.18232 70.8799 2.1371 72.144 2.93051 72.9778C3.72392 73.8115 4.1139 73.7443 7.2203 75.6673C10.3267 77.5903 10.3939 77.9265 11.5235 78.182C13.3488 78.5457 15.2433 78.2852 16.9026 77.4424C18.8494 76.4816 20.489 74.9958 21.6361 73.1526Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask2015" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="26" height="29">
        <path d="M21.6452 73.1526C23.075 71.1339 24.0813 68.8467 24.6036 66.4288C25.22 63.449 25.1511 60.3681 24.4019 57.4189C23.7866 54.5556 23.0422 54.2812 21.8513 51.5195C21.6894 51.144 21.3233 50.8934 20.9143 50.8934H18.009C17.4567 50.8934 17.009 51.3411 17.009 51.8934V58.4668C17.009 58.9106 16.714 59.3023 16.2881 59.4273C12.5428 60.526 12.2591 60.5464 8.51227 59.4296C8.08884 59.3034 7.79636 58.9129 7.79636 58.471V51.8934C7.79636 51.3411 7.34864 50.8934 6.79636 50.8934H3.78782C3.29848 50.8934 2.87591 51.2463 2.75026 51.7192C2.40749 53.0093 1.76587 54.9309 1.60823 55.8051C0.700757 59.4333 0.400847 63.1867 0.720687 66.9129C1.19135 70.8799 2.14613 72.144 2.93954 72.9777C3.73295 73.8115 4.12293 73.7443 7.22933 75.6673C10.3357 77.5903 10.403 77.9265 11.5326 78.182C13.3578 78.5457 15.2523 78.2852 16.9116 77.4424C18.8585 76.4816 20.498 74.9958 21.6452 73.1526Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2015)">
        <path d="M4.26294 36.3316H20.3972V61.8796C14.0964 63.8058 10.5638 63.8254 4.26294 61.8796V36.3316Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M4.93372 1.73621C2.35178 7.86832 3.75033 19.6484 3.75033 19.6484C4.79865 27.9106 4.69909 36.2781 3.45448 44.5131C2.84934 48.1708 2.35178 51.842 1.6794 55.4729C0.765223 59.0999 0.465222 62.8544 0.791855 66.5806C1.24907 70.5476 2.33833 71.9596 3.01071 72.6455C3.68309 73.3313 4.07307 73.3178 7.3005 75.335C10.5279 77.3521 10.4741 77.5942 11.5903 77.8497C13.4154 78.2166 15.311 77.956 16.9693 77.1101C18.9191 76.1535 20.5597 74.6667 21.7029 72.8203C23.1327 70.8016 24.139 68.5144 24.6614 66.0965C25.2995 63.1121 25.2489 60.0216 24.5134 57.0597C23.8545 53.9936 23.0342 53.0254 21.7029 49.6635C21.0305 47.9556 20.5598 46.463 18.7444 38.5558C17.3997 32.3968 16.3911 28.3087 15.0463 21.679C14.1722 17.4565 12.5989 13.3684 12.0744 9.09205C11.913 7.74729 11.4827 3.713 8.968 1.25209C8.53768 0.821768 7.47532 -0.186789 6.4533 0.0687153C5.43128 0.32422 5.08164 1.37312 4.93372 1.73621Z" fill="#DEE1E9"/>
      <path d="M24.6799 66.0985C24.1576 68.5165 23.1512 70.8037 21.7215 72.8223C20.5743 74.6655 18.9348 76.1514 16.9879 77.1121C15.3286 77.955 13.4341 78.2155 11.6089 77.8517C10.4793 77.5962 10.412 77.2601 7.30562 75.337C4.19923 73.414 3.80925 73.4813 3.01584 72.6475C2.22243 71.8138 1.26765 70.5497 0.796981 66.5826C0.477141 62.8565 0.777051 59.103 1.68452 55.4749C1.88175 54.3812 2.07002 53.2919 2.24932 52.2071L6.71393 48.5225C8.20799 46.9782 10.2082 46.0234 12.3485 45.833C13.4447 45.7556 14.5455 45.8927 15.5894 46.2364L21.6677 49.6925C23.0124 53.0544 23.8193 54.0226 24.4782 57.0886C25.2274 60.0378 25.2963 63.1188 24.6799 66.0985Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M12.857 54.9644C11.7812 55.1527 9.80436 50.6881 8.82269 46.3983L7.88135 34.0534C7.70653 30.1267 7.59895 26.9261 7.53171 24.7207C7.38379 19.7182 7.43758 19.0996 7.24931 16.9749C6.77865 11.5152 6.96666 1.56604 7.24906 1.44501C7.53146 1.32398 7.53171 10.6142 8.37891 13.102C9.22334 16.2383 9.7593 19.4496 9.97918 22.6902C10.3118 26.2628 10.8687 29.811 11.6467 33.3138C12.3729 36.1378 12.9914 37.4825 13.8521 41.5303C14.3043 43.4024 14.5434 45.3196 14.5648 47.2455C14.5548 48.7795 14.3789 50.308 14.0404 51.8042C13.5831 53.8752 13.3411 54.9644 12.857 54.9644Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M3.54755 13.5122C7.20556 12.148 9.24824 12.2101 12.8846 13.5122" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M5.53589 5.06659C2.95395 11.1987 4.3525 22.9788 4.3525 22.9788C5.40082 31.241 5.30126 39.6085 4.05665 47.8434C3.45151 51.5012 2.95395 55.1724 2.28157 58.8033C1.3674 62.4303 1.0674 66.1848 1.39403 69.911C1.85125 73.878 2.9405 75.29 3.61288 75.9758C4.28526 76.6617 4.67524 76.6482 7.90267 78.6654C11.1301 80.6825 11.0763 80.9246 12.1925 81.1801C14.0176 81.547 15.9131 81.2863 17.5715 80.4405C19.5212 79.4839 21.1618 77.9971 22.3051 76.1507C23.7348 74.132 24.7412 71.8448 25.2635 69.4269C25.9017 66.4425 25.8511 63.3519 25.1156 60.3901C24.4567 57.324 23.6364 56.3558 22.3051 52.9939C21.6327 51.286 21.162 49.7934 19.3466 41.8862C18.0018 35.7271 16.9933 31.6391 15.6485 25.0094C14.7744 20.7868 13.201 16.6988 12.6766 12.4224C12.5152 11.0777 12.0849 7.04339 9.57018 4.58247C9.13985 4.15215 8.07749 3.14359 7.05547 3.3991C6.03345 3.6546 5.68382 4.7035 5.53589 5.06659Z" fill="#DEE1E9"/>
      <circle cx="7" cy="4.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <mask id="mask2215" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="27" height="34">
        <path d="M25.2821 69.4289C24.7598 71.8468 23.7534 74.134 22.3236 76.1527C21.1765 77.9959 19.5369 79.4817 17.5901 80.4425C15.9308 81.2853 14.0363 81.5458 12.211 81.1821C11.0814 80.9266 11.0142 80.5904 7.9078 78.6674C4.8014 76.7444 4.41142 76.8116 3.61801 75.9778C2.8246 75.1441 1.86982 73.88 1.39915 69.913C1.07931 66.1868 1.37922 62.4334 2.2867 58.8052C2.48393 57.7115 2.67219 56.6222 2.8515 55.5375L7.3161 51.8528C8.81017 50.3085 10.8103 49.3538 12.9507 49.1633C14.0469 49.086 15.1477 49.223 16.1915 49.5667L22.2698 53.0228C23.6146 56.3847 24.4215 57.3529 25.0804 60.419C25.8295 63.3682 25.8985 66.4491 25.2821 69.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2215)">
        <path d="M25.2821 69.4876C24.7598 71.9055 23.7534 74.1927 22.3236 76.2114C21.1765 78.0546 19.5369 79.5404 17.5901 80.5012C15.9308 81.344 14.0363 81.6045 12.211 81.2408C11.0814 80.9853 11.0142 80.6491 7.9078 78.7261C4.8014 76.8031 4.41142 76.8703 3.61801 76.0366C2.8246 75.2028 1.86982 73.9387 1.39915 69.9717C1.07931 66.2455 1.37922 62.4921 2.2867 58.864C2.48393 57.7702 2.67219 56.681 2.8515 55.5962L7.3161 51.9115C8.81017 50.3672 10.8103 49.4125 12.9507 49.222C14.0469 49.1447 15.1477 49.2817 16.1915 49.6254L22.2698 53.0815C23.6146 56.4434 24.4215 57.4116 25.0804 60.4777C25.8295 63.4269 25.8985 66.5078 25.2821 69.4876Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7809 66.201C12.8005 66.201 11.2661 73.6692 11.2661 82.201H13.1892H16.2661C16.2661 73.6692 14.7613 66.201 13.7809 66.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.2443 68.1943C12.9707 68.4159 10.6304 63.1611 9.46827 58.112L8.35387 43.5822C8.14691 38.9605 8.01955 35.1935 7.93995 32.5977C7.76483 26.7098 7.82851 25.9818 7.60563 23.481C7.04843 17.0549 7.271 5.34488 7.60532 5.20243C7.93964 5.05998 7.93995 15.9945 8.94291 18.9226C9.94258 22.614 10.5771 26.3937 10.8374 30.2078C11.2312 34.4127 11.8905 38.5889 12.8115 42.7116C13.6712 46.0355 14.4035 47.6182 15.4224 52.3824C15.9577 54.5859 16.2408 56.8425 16.2661 59.1092C16.2542 60.9147 16.046 62.7137 15.6452 64.4748C15.104 66.9122 14.8174 68.1943 14.2443 68.1943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M5.53589 9.06659C2.95395 15.1987 4.3525 26.9788 4.3525 26.9788C5.40082 35.241 5.30126 43.6085 4.05665 51.8434C3.45151 55.5012 2.95395 59.1724 2.28157 62.8033C1.3674 66.4303 1.0674 70.1848 1.39403 73.911C1.85125 77.878 2.9405 79.29 3.61288 79.9758C4.28526 80.6617 4.67524 80.6482 7.90267 82.6654C11.1301 84.6825 11.0763 84.9246 12.1925 85.1801C14.0176 85.547 15.9131 85.2863 17.5715 84.4405C19.5212 83.4839 21.1618 81.9971 22.3051 80.1507C23.7348 78.132 24.7412 75.8448 25.2635 73.4269C25.9017 70.4425 25.8511 67.3519 25.1156 64.3901C24.4567 61.324 23.6364 60.3558 22.3051 56.9939C21.6327 55.286 21.162 53.7934 19.3466 45.8862C18.0018 39.7271 16.9933 35.6391 15.6485 29.0094C14.7744 24.7868 13.201 20.6988 12.6766 16.4224C12.5152 15.0777 12.0849 11.0434 9.57018 8.58247C9.13985 8.15215 8.07749 7.14359 7.05547 7.3991C6.03345 7.6546 5.68382 8.7035 5.53589 9.06659Z" fill="#DEE1E9"/>
      <circle cx="7" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2315)">
        <path d="M8 0.200989L8.8229 3.94009L11.8567 1.60472L10.0837 4.99799L13.9088 5.1591L10.3694 6.61879L13.1962 9.20099L9.54655 8.04409L10.0521 11.8391L8 8.60699L5.94788 11.8391L6.45345 8.04409L2.80385 9.20099L5.63055 6.61879L2.09115 5.1591L5.91634 4.99799L4.14327 1.60472L7.1771 3.94009L8 0.200989Z" fill="#98B250"/>
      </g>
      <mask id="mask2315" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="52" width="27" height="34">
        <path d="M25.2821 73.4289C24.7598 75.8468 23.7534 78.134 22.3236 80.1527C21.1765 81.9959 19.5369 83.4817 17.5901 84.4425C15.9308 85.2853 14.0363 85.5458 12.211 85.1821C11.0814 84.9266 11.0142 84.5904 7.9078 82.6674C4.8014 80.7444 4.41142 80.8116 3.61801 79.9778C2.8246 79.1441 1.86982 77.88 1.39915 73.913C1.07931 70.1868 1.37922 66.4334 2.2867 62.8052C2.48393 61.7115 2.67219 60.6222 2.8515 59.5375L7.3161 55.8528C8.81017 54.3085 10.8103 53.3538 12.9507 53.1633C14.0469 53.086 15.1477 53.223 16.1915 53.5667L22.2698 57.0228C23.6146 60.3847 24.4215 61.3529 25.0804 64.419C25.8295 67.3682 25.8985 70.4491 25.2821 73.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2315)">
        <path d="M25.2821 73.4876C24.7598 75.9055 23.7534 78.1927 22.3236 80.2114C21.1765 82.0546 19.5369 83.5404 17.5901 84.5012C15.9308 85.344 14.0363 85.6045 12.211 85.2408C11.0814 84.9853 11.0142 84.6491 7.9078 82.7261C4.8014 80.8031 4.41142 80.8703 3.61801 80.0366C2.8246 79.2028 1.86982 77.9387 1.39915 73.9717C1.07931 70.2455 1.37922 66.4921 2.2867 62.864C2.48393 61.7702 2.67219 60.681 2.8515 59.5962L7.3161 55.9115C8.81017 54.3672 10.8103 53.4125 12.9507 53.222C14.0469 53.1447 15.1477 53.2817 16.1915 53.6254L22.2698 57.0815C23.6146 60.4434 24.4215 61.4116 25.0804 64.4777C25.8295 67.4269 25.8985 70.5078 25.2821 73.4876Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7809 70.201C12.8005 70.201 11.2661 77.6692 11.2661 86.201H13.1892H16.2661C16.2661 77.6692 14.7613 70.201 13.7809 70.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.2443 72.1943C12.9707 72.4159 10.6304 67.1611 9.46827 62.112L8.35387 47.5822C8.14691 42.9605 8.01955 39.1935 7.93995 36.5977C7.76483 30.7098 7.82851 29.9818 7.60563 27.481C7.04843 21.0549 7.271 9.34488 7.60532 9.20243C7.93964 9.05998 7.93995 19.9945 8.94291 22.9226C9.94258 26.614 10.5771 30.3937 10.8374 34.2078C11.2312 38.4127 11.8905 42.5889 12.8115 46.7116C13.6712 50.0355 14.4035 51.6182 15.4224 56.3824C15.9577 58.5859 16.2408 60.8425 16.2661 63.1092C16.2542 64.9147 16.046 66.7137 15.6452 68.4748C15.104 70.9122 14.8174 72.1943 14.2443 72.1943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <defs>
        <filter id="filter2315" x="1.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.53589 9.06659C2.95395 15.1987 4.3525 26.9788 4.3525 26.9788C5.40082 35.241 5.30126 43.6085 4.05665 51.8434C3.45151 55.5012 2.95395 59.1724 2.28157 62.8033C1.3674 66.4303 1.0674 70.1848 1.39403 73.911C1.85125 77.878 2.9405 79.29 3.61288 79.9758C4.28526 80.6617 4.67524 80.6482 7.90267 82.6654C11.1301 84.6825 11.0763 84.9246 12.1925 85.1801C14.0176 85.547 15.9131 85.2863 17.5715 84.4405C19.5212 83.4839 21.1618 81.9971 22.3051 80.1507C23.7348 78.132 24.7412 75.8448 25.2635 73.4269C25.9017 70.4425 25.8511 67.3519 25.1156 64.3901C24.4567 61.324 23.6364 60.3558 22.3051 56.9939C21.6327 55.286 21.162 53.7934 19.3466 45.8862C18.0018 39.7271 16.9933 35.6391 15.6485 29.0094C14.7744 24.7868 13.201 20.6988 12.6766 16.4224C12.5152 15.0777 12.0849 11.0434 9.57018 8.58247C9.13985 8.15215 8.07749 7.14359 7.05547 7.3991C6.03345 7.6546 5.68382 8.7035 5.53589 9.06659Z" fill="#DEE1E9"/>
      <circle cx="7" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2315)">
        <path d="M8 0.200989L8.8229 3.94009L11.8567 1.60472L10.0837 4.99799L13.9088 5.1591L10.3694 6.61879L13.1962 9.20099L9.54655 8.04409L10.0521 11.8391L8 8.60699L5.94788 11.8391L6.45345 8.04409L2.80385 9.20099L5.63055 6.61879L2.09115 5.1591L5.91634 4.99799L4.14327 1.60472L7.1771 3.94009L8 0.200989Z" fill="#98B250"/>
      </g>
      <mask id="mask2315" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="52" width="27" height="34">
        <path d="M25.2821 73.4289C24.7598 75.8468 23.7534 78.134 22.3236 80.1527C21.1765 81.9959 19.5369 83.4817 17.5901 84.4425C15.9308 85.2853 14.0363 85.5458 12.211 85.1821C11.0814 84.9266 11.0142 84.5904 7.9078 82.6674C4.8014 80.7444 4.41142 80.8116 3.61801 79.9778C2.8246 79.1441 1.86982 77.88 1.39915 73.913C1.07931 70.1868 1.37922 66.4334 2.2867 62.8052C2.48393 61.7115 2.67219 60.6222 2.8515 59.5375L7.3161 55.8528C8.81017 54.3085 10.8103 53.3538 12.9507 53.1633C14.0469 53.086 15.1477 53.223 16.1915 53.5667L22.2698 57.0228C23.6146 60.3847 24.4215 61.3529 25.0804 64.419C25.8295 67.3682 25.8985 70.4491 25.2821 73.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2315)">
        <path d="M25.2821 73.4876C24.7598 75.9055 23.7534 78.1927 22.3236 80.2114C21.1765 82.0546 19.5369 83.5404 17.5901 84.5012C15.9308 85.344 14.0363 85.6045 12.211 85.2408C11.0814 84.9853 11.0142 84.6491 7.9078 82.7261C4.8014 80.8031 4.41142 80.8703 3.61801 80.0366C2.8246 79.2028 1.86982 77.9387 1.39915 73.9717C1.07931 70.2455 1.37922 66.4921 2.2867 62.864C2.48393 61.7702 2.67219 60.681 2.8515 59.5962L7.3161 55.9115C8.81017 54.3672 10.8103 53.4125 12.9507 53.222C14.0469 53.1447 15.1477 53.2817 16.1915 53.6254L22.2698 57.0815C23.6146 60.4434 24.4215 61.4116 25.0804 64.4777C25.8295 67.4269 25.8985 70.5078 25.2821 73.4876Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.7809 70.201C12.8005 70.201 11.2661 77.6692 11.2661 86.201H13.1892H16.2661C16.2661 77.6692 14.7613 70.201 13.7809 70.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.2443 72.1943C12.9707 72.4159 10.6304 67.1611 9.46827 62.112L8.35387 47.5822C8.14691 42.9605 8.01955 39.1935 7.93995 36.5977C7.76483 30.7098 7.82851 29.9818 7.60563 27.481C7.04843 21.0549 7.271 9.34488 7.60532 9.20243C7.93964 9.05998 7.93995 19.9945 8.94291 22.9226C9.94258 26.614 10.5771 30.3937 10.8374 34.2078C11.2312 38.4127 11.8905 42.5889 12.8115 46.7116C13.6712 50.0355 14.4035 51.6182 15.4224 56.3824C15.9577 58.5859 16.2408 60.8425 16.2661 63.1092C16.2542 64.9147 16.046 66.7137 15.6452 68.4748C15.104 70.9122 14.8174 72.1943 14.2443 72.1943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <defs>
        <filter id="filter2315" x="1.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M5.53589 5.06653C2.95395 11.1986 4.3525 22.9788 4.3525 22.9788C5.40082 31.241 5.30126 39.6085 4.05665 47.8434C3.45151 51.5011 2.95395 55.1723 2.28157 58.8032C1.3674 62.4302 1.0674 66.1848 1.39403 69.9109C1.85125 73.878 2.9405 75.2899 3.61288 75.9758C4.28526 76.6616 4.67524 76.6482 7.90267 78.6653C11.1301 80.6824 11.0763 80.9245 12.1925 81.18C14.0176 81.5469 15.9131 81.2863 17.5715 80.4404C19.5212 79.4839 21.1618 77.9971 22.3051 76.1506C23.7348 74.1319 24.7412 71.8447 25.2635 69.4268C25.9017 66.4424 25.8511 63.3519 25.1156 60.39C24.4567 57.3239 23.6364 56.3557 22.3051 52.9938C21.6327 51.286 21.162 49.7933 19.3466 41.8861C18.0018 35.7271 16.9933 31.639 15.6485 25.0093C14.7744 20.7868 13.201 16.6987 12.6766 12.4224C12.5152 11.0776 12.0849 7.04333 9.57018 4.58241C9.13985 4.15209 8.07749 3.14353 7.05547 3.39904C6.03345 3.65454 5.68382 4.70344 5.53589 5.06653Z" fill="#DEE1E9"/>
      <mask id="mask2515" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="27" height="34">
        <path d="M25.2821 69.4287C24.7598 71.8467 23.7534 74.1339 22.3236 76.1525C21.1765 77.9957 19.5369 79.4816 17.5901 80.4423C15.9308 81.2852 14.0363 81.5457 12.211 81.1819C11.0814 80.9264 11.0142 80.5903 7.9078 78.6672C4.8014 76.7442 4.41142 76.8115 3.61801 75.9777C2.8246 75.144 1.86982 73.8799 1.39915 69.9128C1.07931 66.1867 1.37922 62.4332 2.2867 58.8051C2.48393 57.7114 2.67219 56.6221 2.8515 55.5373L7.3161 51.8527C8.81017 50.3084 10.8103 49.3536 12.9507 49.1632C14.0469 49.0858 15.1477 49.2229 16.1915 49.5666L22.2698 53.0227C23.6146 56.3846 24.4215 57.3528 25.0804 60.4188C25.8295 63.368 25.8985 66.449 25.2821 69.4287Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2515)">
        <path d="M25.2821 69.4874C24.7598 71.9054 23.7534 74.1926 22.3236 76.2113C21.1765 78.0545 19.5369 79.5403 17.5901 80.501C15.9308 81.3439 14.0363 81.6044 12.211 81.2407C11.0814 80.9852 11.0142 80.649 7.9078 78.726C4.8014 76.803 4.41142 76.8702 3.61801 76.0364C2.8246 75.2027 1.86982 73.9386 1.39915 69.9716C1.07931 66.2454 1.37922 62.492 2.2867 58.8638C2.48393 57.7701 2.67219 56.6808 2.8515 55.5961L7.3161 51.9114C8.81017 50.3671 10.8103 49.4123 12.9507 49.2219C14.0469 49.1446 15.1477 49.2816 16.1915 49.6253L22.2698 53.0814C23.6146 56.4433 24.4215 57.4115 25.0804 60.4776C25.8295 63.4268 25.8985 66.5077 25.2821 69.4874Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.5148 72.2009C12.5344 72.2009 11 76.8685 11 82.2009H12.9231H16C16 76.8685 14.4952 72.2009 13.5148 72.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M13.9782 74.1936C12.7046 74.4328 10.3643 68.7609 9.20216 63.3112L8.08776 47.6282C7.8808 42.6397 7.75344 38.5737 7.67384 35.772C7.49872 29.4167 7.5624 28.6309 7.33952 25.9317C6.78232 18.9956 7.00489 6.35617 7.33921 6.20242C7.67353 6.04866 7.67384 17.851 8.6768 21.0115C9.67647 24.9959 10.311 29.0755 10.5713 33.1923C10.965 37.731 11.6244 42.2386 12.5454 46.6885C13.405 50.2762 14.1374 51.9846 15.1562 57.1268C15.6916 59.5052 15.9747 61.9408 16 64.3875C15.9881 66.3363 15.7799 68.2781 15.3791 70.1789C14.8378 72.8098 14.5513 74.1936 13.9782 74.1936Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="7" cy="4.20093" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2615" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="80">
        <path d="M4.84839 2.55188C2.26645 8.68399 3.665 20.4641 3.665 20.4641C4.71332 28.7263 4.61376 37.0938 3.36915 45.3287C2.76401 48.9865 2.26645 52.6577 1.59407 56.2885C0.679896 59.9156 0.379895 63.6701 0.706528 67.3963C1.16375 71.3633 2.253 72.7753 2.92538 73.4611C3.59776 74.147 3.98774 74.1335 7.21517 76.1507C10.4426 78.1678 10.3888 78.4099 11.505 78.6654C13.3301 79.0323 15.2256 78.7716 16.884 77.9258C18.8337 76.9692 20.4743 75.4824 21.6176 73.636C23.0473 71.6173 24.0537 69.3301 24.576 66.9121C25.2142 63.9278 25.1636 60.8372 24.4281 57.8754C23.7692 54.8093 22.9489 53.8411 21.6176 50.4792C20.9452 48.7713 20.4745 47.2786 18.6591 39.3714C17.3143 33.2124 16.3058 29.1244 14.961 22.4947C14.0869 18.2721 12.5135 14.1841 11.9891 9.90772C11.8277 8.56296 11.3974 4.52868 8.88268 2.06776C8.45235 1.63744 7.38999 0.628884 6.36797 0.884389C5.34595 1.13989 4.99632 2.18879 4.84839 2.55188Z" fill="#DEE1E9"/>
        <path d="M24.5946 66.9141C24.0723 69.332 23.0659 71.6192 21.6361 73.6379C20.489 75.4811 18.8494 76.9669 16.9026 77.9277C15.2433 78.7705 13.3488 79.031 11.5235 78.6673C10.3939 78.4118 10.3267 78.0756 7.2203 76.1526C4.1139 74.2296 3.72392 74.2968 2.93051 73.4631C2.1371 72.6293 1.18232 71.3652 0.711654 67.3982C0.391814 63.672 0.691724 59.9186 1.5992 56.2905C1.79643 55.1967 1.98469 54.1075 2.164 53.0227L6.6286 49.3381C8.12267 47.7937 10.1228 46.839 12.2632 46.6485C13.3594 46.5712 14.4602 46.7082 15.504 47.052L21.5823 50.508C22.9271 53.8699 23.734 54.8381 24.3929 57.9042C25.142 60.8534 25.211 63.9343 24.5946 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2615)">
        <path d="M4.84839 2.55188C2.26645 8.68399 3.665 20.4641 3.665 20.4641C4.71332 28.7263 4.61376 37.0938 3.36915 45.3287C2.76401 48.9865 2.26645 52.6577 1.59407 56.2885C0.679896 59.9156 0.379895 63.6701 0.706528 67.3963C1.16375 71.3633 2.253 72.7753 2.92538 73.4611C3.59776 74.147 3.98774 74.1335 7.21517 76.1507C10.4426 78.1678 10.3888 78.4099 11.505 78.6654C13.3301 79.0323 15.2256 78.7716 16.884 77.9258C18.8337 76.9692 20.4743 75.4824 21.6176 73.636C23.0473 71.6173 24.0537 69.3301 24.576 66.9121C25.2142 63.9278 25.1636 60.8372 24.4281 57.8754C23.7692 54.8093 22.9489 53.8411 21.6176 50.4792C20.9452 48.7713 20.4745 47.2786 18.6591 39.3714C17.3143 33.2124 16.3058 29.1244 14.961 22.4947C14.0869 18.2721 12.5135 14.1841 11.9891 9.90772C11.8277 8.56296 11.3974 4.52868 8.88268 2.06776C8.45235 1.63744 7.38999 0.628884 6.36797 0.884389C5.34595 1.13989 4.99632 2.18879 4.84839 2.55188Z" fill="#DEE1E9"/>
        <path d="M24.5946 66.9141C24.0723 69.332 23.0659 71.6192 21.6361 73.6379C20.489 75.4811 18.8494 76.9669 16.9026 77.9277C15.2433 78.7705 13.3488 79.031 11.5235 78.6673C10.3939 78.4118 10.3267 78.0756 7.2203 76.1526C4.1139 74.2296 3.72392 74.2968 2.93051 73.4631C2.1371 72.6293 1.18232 71.3652 0.711654 67.3982C0.391814 63.672 0.691724 59.9186 1.5992 56.2905C1.79643 55.1967 1.98469 54.1075 2.164 53.0227L6.6286 49.3381C8.12267 47.7937 10.1228 46.839 12.2632 46.6485C13.3594 46.5712 14.4602 46.7082 15.504 47.052L21.5823 50.508C22.9271 53.8699 23.734 54.8381 24.3929 57.9042C25.142 60.8534 25.211 63.9343 24.5946 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M12.0259 50.5247H23.9471C21.785 45.1549 21.428 41.8947 21.7245 36.1564C21.7357 35.94 21.4864 35.8054 21.2961 35.932C20.2921 36.6 17.9339 38.4161 17.3622 41.0577C16.6698 44.2577 14.6665 45.0348 14.4539 45.1089C14.441 45.1134 14.4294 45.1179 14.417 45.1236L13.2341 45.6703C13.0895 45.7371 13.0309 45.8996 13.1032 46.0332L13.6321 47.0109C13.6769 47.0936 13.6728 47.1917 13.6213 47.271L11.7749 50.1151C11.6579 50.2954 11.7984 50.5247 12.0259 50.5247Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M20.951 37.0459C19.89 37.8938 18.4821 39.3307 18.0797 41.1903C17.3364 44.6248 15.1605 45.5809 14.7275 45.7361L13.9539 46.0936L14.2865 46.7084C14.4431 46.9979 14.4289 47.3413 14.2487 47.6189L12.8012 49.8485H22.9038C21.1271 45.1838 20.7511 41.9881 20.951 37.0459ZM14.7442 45.7284C14.7444 45.7283 14.7441 45.7285 14.7442 45.7284V45.7284ZM20.8687 35.3832C21.5976 34.8983 22.4933 35.4547 22.4554 36.1886C22.1626 41.8548 22.5118 45.0209 24.6328 50.2889L25 51.2009H12.0259C11.2297 51.2009 10.7379 50.3982 11.1476 49.7672L12.8699 47.114L12.4488 46.3356C12.1958 45.8679 12.4009 45.2992 12.9069 45.0654L14.0898 44.5188C14.1305 44.5 14.1664 44.4864 14.1959 44.4761C14.2325 44.4634 16.0133 43.8432 16.6448 40.9251C17.2743 38.0161 19.8377 36.0691 20.8687 35.3832Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask115" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="80">
          <path d="M4.84839 2.55188C2.26645 8.68399 3.665 20.4641 3.665 20.4641C4.71332 28.7263 4.61376 37.0938 3.36915 45.3287C2.76401 48.9865 2.26645 52.6577 1.59407 56.2885C0.679896 59.9156 0.379895 63.6701 0.706528 67.3963C1.16375 71.3633 2.253 72.7753 2.92538 73.4611C3.59776 74.147 3.98774 74.1335 7.21517 76.1507C10.4426 78.1678 10.3888 78.4099 11.505 78.6654C13.3301 79.0323 15.2256 78.7716 16.884 77.9258C18.8337 76.9692 20.4743 75.4824 21.6176 73.636C23.0473 71.6173 24.0537 69.3301 24.576 66.9121C25.2142 63.9278 25.1636 60.8372 24.4281 57.8754C23.7692 54.8093 22.9489 53.8411 21.6176 50.4792C20.9452 48.7713 20.4745 47.2786 18.6591 39.3714C17.3143 33.2124 16.3058 29.1244 14.961 22.4947C14.0869 18.2721 12.5135 14.1841 11.9891 9.90772C11.8277 8.56296 11.3974 4.52868 8.88268 2.06776C8.45235 1.63744 7.38999 0.628884 6.36797 0.884389C5.34595 1.13989 4.99632 2.18879 4.84839 2.55188Z" fill="#DEE1E9"/>
          <path d="M24.5946 66.9141C24.0723 69.332 23.0659 71.6192 21.6361 73.6379C20.489 75.4811 18.8494 76.9669 16.9026 77.9277C15.2433 78.7705 13.3488 79.031 11.5235 78.6673C10.3939 78.4118 10.3267 78.0756 7.2203 76.1526C4.1139 74.2296 3.72392 74.2968 2.93051 73.4631C2.1371 72.6293 1.18232 71.3652 0.711654 67.3982C0.391814 63.672 0.691724 59.9186 1.5992 56.2905C1.79643 55.1967 1.98469 54.1075 2.164 53.0227L6.6286 49.3381C8.12267 47.7937 10.1228 46.839 12.2632 46.6485C13.3594 46.5712 14.4602 46.7082 15.504 47.052L21.5823 50.508C22.9271 53.8699 23.734 54.8381 24.3929 57.9042C25.142 60.8534 25.211 63.9343 24.5946 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask115)">
          <path d="M4.84839 2.55188C2.26645 8.68399 3.665 20.4641 3.665 20.4641C4.71332 28.7263 4.61376 37.0938 3.36915 45.3287C2.76401 48.9865 2.26645 52.6577 1.59407 56.2885C0.679896 59.9156 0.379895 63.6701 0.706528 67.3963C1.16375 71.3633 2.253 72.7753 2.92538 73.4611C3.59776 74.147 3.98774 74.1335 7.21517 76.1507C10.4426 78.1678 10.3888 78.4099 11.505 78.6654C13.3301 79.0323 15.2256 78.7716 16.884 77.9258C18.8337 76.9692 20.4743 75.4824 21.6176 73.636C23.0473 71.6173 24.0537 69.3301 24.576 66.9121C25.2142 63.9278 25.1636 60.8372 24.4281 57.8754C23.7692 54.8093 22.9489 53.8411 21.6176 50.4792C20.9452 48.7713 20.4745 47.2786 18.6591 39.3714C17.3143 33.2124 16.3058 29.1244 14.961 22.4947C14.0869 18.2721 12.5135 14.1841 11.9891 9.90772C11.8277 8.56296 11.3974 4.52868 8.88268 2.06776C8.45235 1.63744 7.38999 0.628884 6.36797 0.884389C5.34595 1.13989 4.99632 2.18879 4.84839 2.55188Z" fill="#DEE1E9"/>
          <path d="M24.5946 66.9141C24.0723 69.332 23.0659 71.6192 21.6361 73.6379C20.489 75.4811 18.8494 76.9669 16.9026 77.9277C15.2433 78.7705 13.3488 79.031 11.5235 78.6673C10.3939 78.4118 10.3267 78.0756 7.2203 76.1526C4.1139 74.2296 3.72392 74.2968 2.93051 73.4631C2.1371 72.6293 1.18232 71.3652 0.711654 67.3982C0.391814 63.672 0.691724 59.9186 1.5992 56.2905C1.79643 55.1967 1.98469 54.1075 2.164 53.0227L6.6286 49.3381C8.12267 47.7937 10.1228 46.839 12.2632 46.6485C13.3594 46.5712 14.4602 46.7082 15.504 47.052L21.5823 50.508C22.9271 53.8699 23.734 54.8381 24.3929 57.9042C25.142 60.8534 25.211 63.9343 24.5946 66.9141Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M14.7011 49.5315H22.8479C21.3703 45.561 21.1264 43.1504 21.329 38.9074C21.3367 38.7474 21.1662 38.6479 21.0362 38.7415C20.3501 39.2354 18.7385 40.5782 18.3479 42.5315C17.8746 44.8976 16.5057 45.4722 16.3604 45.527C16.3515 45.5303 16.3436 45.5337 16.3352 45.5379L15.5267 45.9421C15.428 45.9915 15.3879 46.1116 15.4373 46.2104L15.7988 46.9333C15.8293 46.9945 15.8266 47.067 15.7914 47.1257L14.5296 49.2286C14.4496 49.3619 14.5456 49.5315 14.7011 49.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8004 39.5651C20.0753 40.1921 19.1131 41.2545 18.8381 42.6295C18.3302 45.169 16.8432 45.876 16.5473 45.9908L16.0187 46.2551L16.246 46.7097C16.353 46.9238 16.3432 47.1777 16.2201 47.3829L15.2309 49.0315H22.1349C20.9207 45.5824 20.6637 43.2194 20.8004 39.5651ZM16.5587 45.9851C16.5588 45.985 16.5586 45.9851 16.5587 45.9851V45.9851ZM20.7441 38.3357C21.2422 37.9771 21.8543 38.3885 21.8284 38.9312C21.6283 43.1208 21.867 45.4619 23.3164 49.3571L23.5674 50.0315H14.7011C14.157 50.0315 13.8209 49.4379 14.1008 48.9713L15.2779 47.0096L14.9901 46.434C14.8172 46.0882 14.9573 45.6677 15.3031 45.4948L16.1115 45.0906C16.1393 45.0767 16.1638 45.0667 16.184 45.0591C16.209 45.0497 17.426 44.5911 17.8576 42.4334C18.2877 40.2825 20.0395 38.8428 20.7441 38.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M5.53589 2.06653C2.95395 8.19864 4.3525 19.9788 4.3525 19.9788C5.40082 28.241 5.30126 36.6085 4.05665 44.8434C3.45151 48.5011 2.95395 52.1723 2.28157 55.8032C1.3674 59.4302 1.0674 63.1848 1.39403 66.9109C1.85125 70.878 2.9405 72.2899 3.61288 72.9758C4.28526 73.6616 4.67524 73.6482 7.90267 75.6653C11.1301 77.6824 11.0763 77.9245 12.1925 78.18C14.0176 78.5469 15.9131 78.2863 17.5715 77.4404C19.5212 76.4839 21.1618 74.9971 22.3051 73.1506C23.7348 71.1319 24.7412 68.8447 25.2635 66.4268C25.9017 63.4424 25.8511 60.3519 25.1156 57.39C24.4567 54.3239 23.6364 53.3557 22.3051 49.9938C21.6327 48.286 21.162 46.7933 19.3466 38.8861C18.0018 32.7271 16.9933 28.639 15.6485 22.0093C14.7744 17.7868 13.201 13.6987 12.6766 9.42237C12.5152 8.07761 12.0849 4.04333 9.57018 1.58241C9.13985 1.15209 8.07749 0.143533 7.05547 0.399038C6.03345 0.654542 5.68382 1.70344 5.53589 2.06653Z" fill="#DEE1E9"/>
        <mask id="mask215" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="27" height="34">
          <path d="M25.2821 66.4289C24.7598 68.8468 23.7534 71.134 22.3236 73.1527C21.1765 74.9959 19.5369 76.4817 17.5901 77.4425C15.9308 78.2853 14.0363 78.5458 12.211 78.1821C11.0814 77.9266 11.0142 77.5904 7.9078 75.6674C4.8014 73.7444 4.41142 73.8116 3.61801 72.9778C2.8246 72.1441 1.86982 70.88 1.39915 66.913C1.07931 63.1868 1.37922 59.4334 2.2867 55.8052C2.48393 54.7115 2.67219 53.6222 2.8515 52.5375L7.3161 48.8528C8.81017 47.3085 10.8103 46.3538 12.9507 46.1633C14.0469 46.086 15.1477 46.223 16.1915 46.5667L22.2698 50.0228C23.6146 53.3847 24.4215 54.3529 25.0804 57.419C25.8295 60.3682 25.8985 63.4491 25.2821 66.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask215)">
          <path d="M25.2821 66.4877C24.7598 68.9056 23.7534 71.1928 22.3236 73.2115C21.1765 75.0547 19.5369 76.5405 17.5901 77.5013C15.9308 78.3441 14.0363 78.6046 12.211 78.2409C11.0814 77.9854 11.0142 77.6492 7.9078 75.7262C4.8014 73.8032 4.41142 73.8704 3.61801 73.0367C2.8246 72.2029 1.86982 70.9389 1.39915 66.9718C1.07931 63.2456 1.37922 59.4922 2.2867 55.8641C2.48393 54.7703 2.67219 53.6811 2.8515 52.5963L7.3161 48.9117C8.81017 47.3673 10.8103 46.4126 12.9507 46.2221C14.0469 46.1448 15.1477 46.2818 16.1915 46.6256L22.2698 50.0816C23.6146 53.4435 24.4215 54.4117 25.0804 57.4778C25.8295 60.427 25.8985 63.5079 25.2821 66.4877Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.7868 63.2012C12.4143 63.2012 10.2661 70.6693 10.2661 79.2012H12.9584H17.2661C17.2661 70.6693 15.1594 63.2012 13.7868 63.2012Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M14.2443 65.1942C12.9707 65.4157 10.6304 60.1609 9.46827 55.1119L8.35387 40.5821C8.14691 35.9604 8.01955 32.1934 7.93995 29.5976C7.76483 23.7097 7.82851 22.9816 7.60563 20.4809C7.04843 14.0548 7.271 2.34476 7.60532 2.20231C7.93964 2.05986 7.93995 12.9944 8.94291 15.9225C9.94258 19.6139 10.5771 23.3936 10.8374 27.2076C11.2312 31.4126 11.8905 35.5888 12.8115 39.7115C13.6712 43.0353 14.4035 44.6181 15.4224 49.3823C15.9577 51.5857 16.2408 53.8423 16.2661 56.109C16.2542 57.9146 16.046 59.7136 15.6452 61.4746C15.104 63.9121 14.8174 65.1942 14.2443 65.1942Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M5.53589 2.06653C2.95395 8.19864 4.3525 19.9788 4.3525 19.9788C5.40082 28.241 5.30126 36.6085 4.05665 44.8434C3.45151 48.5011 2.95395 52.1723 2.28157 55.8032C1.3674 59.4302 1.0674 63.1848 1.39403 66.9109C1.85125 70.878 2.9405 72.2899 3.61288 72.9758C4.28526 73.6616 4.67524 73.6482 7.90267 75.6653C11.1301 77.6824 11.0763 77.9245 12.1925 78.18C14.0176 78.5469 15.9131 78.2863 17.5715 77.4404C19.5212 76.4839 21.1618 74.9971 22.3051 73.1506C23.7348 71.1319 24.7412 68.8447 25.2635 66.4268C25.9017 63.4424 25.8511 60.3519 25.1156 57.39C24.4567 54.3239 23.6364 53.3557 22.3051 49.9938C21.6327 48.286 21.162 46.7933 19.3466 38.8861C18.0018 32.7271 16.9933 28.639 15.6485 22.0093C14.7744 17.7868 13.201 13.6987 12.6766 9.42237C12.5152 8.07761 12.0849 4.04333 9.57018 1.58241C9.13985 1.15209 8.07749 0.143533 7.05547 0.399038C6.03345 0.654542 5.68382 1.70344 5.53589 2.06653Z" fill="#DEE1E9"/>
        <mask id="mask315" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="27" height="34">
          <path d="M25.2821 66.4289C24.7598 68.8468 23.7534 71.134 22.3236 73.1527C21.1765 74.9959 19.5369 76.4817 17.5901 77.4425C15.9308 78.2853 14.0363 78.5458 12.211 78.1821C11.0814 77.9266 11.0142 77.5904 7.9078 75.6674C4.8014 73.7444 4.41142 73.8116 3.61801 72.9778C2.8246 72.1441 1.86982 70.88 1.39915 66.913C1.07931 63.1868 1.37922 59.4334 2.2867 55.8052C2.48393 54.7115 2.67219 53.6222 2.8515 52.5375L7.3161 48.8528C8.81017 47.3085 10.8103 46.3538 12.9507 46.1633C14.0469 46.086 15.1477 46.223 16.1915 46.5667L22.2698 50.0228C23.6146 53.3847 24.4215 54.3529 25.0804 57.419C25.8295 60.3682 25.8985 63.4491 25.2821 66.4289Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask315)">
          <path d="M25.2821 66.4877C24.7598 68.9056 23.7534 71.1928 22.3236 73.2115C21.1765 75.0547 19.5369 76.5405 17.5901 77.5013C15.9308 78.3441 14.0363 78.6046 12.211 78.2409C11.0814 77.9854 11.0142 77.6492 7.9078 75.7262C4.8014 73.8032 4.41142 73.8704 3.61801 73.0367C2.8246 72.2029 1.86982 70.9389 1.39915 66.9718C1.07931 63.2456 1.37922 59.4922 2.2867 55.8641C2.48393 54.7703 2.67219 53.6811 2.8515 52.5963L7.3161 48.9117C8.81017 47.3673 10.8103 46.4126 12.9507 46.2221C14.0469 46.1448 15.1477 46.2818 16.1915 46.6256L22.2698 50.0816C23.6146 53.4435 24.4215 54.4117 25.0804 57.4778C25.8295 60.427 25.8985 63.5079 25.2821 66.4877Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.7809 68.2012C12.8005 68.2012 11.2661 73.8023 11.2661 80.2012H13.1892H16.2661C16.2661 73.8023 14.7613 68.2012 13.7809 68.2012Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M4.84839 2.68494C2.26645 8.81705 3.665 20.5972 3.665 20.5972C4.71332 28.8594 4.61376 37.2269 3.36915 45.4618C2.76401 49.1195 2.26645 52.7907 1.59407 56.4216C0.679896 60.0486 0.379895 63.8032 0.706528 67.5293C1.16375 71.4964 2.253 72.9084 2.92538 73.5942C3.59776 74.28 3.98774 74.2666 7.21517 76.2837C10.4426 78.3008 10.3888 78.5429 11.505 78.7984C13.3301 79.1653 15.2256 78.9047 16.884 78.0588C18.8337 77.1023 20.4743 75.6155 21.6176 73.769C23.0473 71.7503 24.0537 69.4632 24.576 67.0452C25.2142 64.0608 25.1636 60.9703 24.4281 58.0084C23.7692 54.9424 22.9489 53.9741 21.6176 50.6122C20.9452 48.9044 20.4745 47.4117 18.6591 39.5045C17.3143 33.3455 16.3058 29.2574 14.961 22.6277C14.0869 18.4052 12.5135 14.3171 11.9891 10.0408C11.8277 8.69602 11.3974 4.66173 8.88268 2.20082C8.45235 1.7705 7.38999 0.761941 6.36797 1.01745C5.34595 1.27295 4.99632 2.32185 4.84839 2.68494Z" fill="#DEE1E9"/>
        <path d="M24.5946 67.0473C24.0723 69.4652 23.0659 71.7524 21.6361 73.7711C20.489 75.6143 18.8494 77.1001 16.9026 78.0609C15.2433 78.9037 13.3488 79.1642 11.5235 78.8005C10.3939 78.545 10.3267 78.2088 7.2203 76.2858C4.1139 74.3628 3.72392 74.43 2.93051 73.5963C2.1371 72.7625 1.18232 71.4984 0.711654 67.5314C0.391814 63.8052 0.691724 60.0518 1.5992 56.4236C1.79643 55.3299 1.98469 54.2407 2.164 53.1559L6.6286 49.4712C8.12267 47.9269 10.1228 46.9722 12.2632 46.7817C13.3594 46.7044 14.4602 46.8414 15.504 47.1851L21.5823 50.6412C22.9271 54.0031 23.734 54.9713 24.3929 58.0374C25.142 60.9866 25.211 64.0675 24.5946 67.0473Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.2661 61.2009L12.1328 63.7018L13.7661 62.2061V65.5201L16.3283 64.9522L14.9328 66.7028L17.2661 68.7035L14.9328 70.7042L16.3283 72.4548L13.9994 71.7045L13.7661 75.2009L11.5162 73.7039L10.7328 74.7056L9.01602 73.7039L6.76611 75.2009L6.06611 73.2051L4.20394 72.4548L4.66611 69.7038L3.26611 68.7035L5.59945 67.203L4.20394 64.9522L6.06611 64.7021L6.76611 62.2061L9.79945 64.202L10.2661 61.2009Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M4.84839 2.68494C2.26645 8.81705 3.665 20.5972 3.665 20.5972C4.71332 28.8594 4.61376 37.2269 3.36915 45.4618C2.76401 49.1195 2.26645 52.7907 1.59407 56.4216C0.679896 60.0486 0.379895 63.8032 0.706528 67.5293C1.16375 71.4964 2.253 72.9084 2.92538 73.5942C3.59776 74.28 3.98774 74.2666 7.21517 76.2837C10.4426 78.3008 10.3888 78.5429 11.505 78.7984C13.3301 79.1653 15.2256 78.9047 16.884 78.0588C18.8337 77.1023 20.4743 75.6155 21.6176 73.769C23.0473 71.7503 24.0537 69.4632 24.576 67.0452C25.2142 64.0608 25.1636 60.9703 24.4281 58.0084C23.7692 54.9424 22.9489 53.9741 21.6176 50.6122C20.9452 48.9044 20.4745 47.4117 18.6591 39.5045C17.3143 33.3455 16.3058 29.2574 14.961 22.6277C14.0869 18.4052 12.5135 14.3171 11.9891 10.0408C11.8277 8.69602 11.3974 4.66173 8.88268 2.20082C8.45235 1.7705 7.38999 0.761941 6.36797 1.01745C5.34595 1.27295 4.99632 2.32185 4.84839 2.68494Z" fill="#DEE1E9"/>
        <path d="M24.5946 67.0473C24.0723 69.4652 23.0659 71.7524 21.6361 73.7711C20.489 75.6143 18.8494 77.1001 16.9026 78.0609C15.2433 78.9037 13.3488 79.1642 11.5235 78.8005C10.3939 78.545 10.3267 78.2088 7.2203 76.2858C4.1139 74.3628 3.72392 74.43 2.93051 73.5963C2.1371 72.7625 1.18232 71.4984 0.711654 67.5314C0.391814 63.8052 0.691724 60.0518 1.5992 56.4236C1.79643 55.3299 1.98469 54.2407 2.164 53.1559L6.6286 49.4712C8.12267 47.9269 10.1228 46.9722 12.2632 46.7817C13.3594 46.7044 14.4602 46.8414 15.504 47.1851L21.5823 50.6412C22.9271 54.0031 23.734 54.9713 24.3929 58.0374C25.142 60.9866 25.211 64.0675 24.5946 67.0473Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M9.26611 63.2009L10.8661 65.3445L12.2661 64.0625V66.9031L14.4623 66.4163L13.2661 67.9168L15.2661 69.6317L13.2661 71.3466L14.4623 72.8471L12.4661 72.204L12.2661 75.2009L10.3376 73.9178L9.66611 74.7763L8.1946 73.9178L6.26611 75.2009L5.66611 73.4902L4.06996 72.8471L4.46611 70.4891L3.26611 69.6317L5.26611 68.3456L4.06996 66.4163L5.66611 66.202L6.26611 64.0625L8.86611 65.7732L9.26611 63.2009Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M4.84839 2.61731C2.26645 8.74942 3.665 20.5295 3.665 20.5295C4.71332 28.7917 4.61376 37.1592 3.36915 45.3942C2.76401 49.0519 2.26645 52.7231 1.59407 56.354C0.679896 59.981 0.379895 63.7355 0.706528 67.4617C1.16375 71.4287 2.253 72.8407 2.92538 73.5266C3.59776 74.2124 3.98774 74.1989 7.21517 76.2161C10.4426 78.2332 10.3888 78.4753 11.505 78.7308C13.3301 79.0977 15.2256 78.8371 16.884 77.9912C18.8337 77.0346 20.4743 75.5479 21.6176 73.7014C23.0473 71.6827 24.0537 69.3955 24.576 66.9776C25.2142 63.9932 25.1636 60.9027 24.4281 57.9408C23.7692 54.8747 22.9489 53.9065 21.6176 50.5446C20.9452 48.8368 20.4745 47.3441 18.6591 39.4369C17.3143 33.2779 16.3058 29.1898 14.961 22.5601C14.0869 18.3376 12.5135 14.2495 11.9891 9.97315C11.8277 8.62839 11.3974 4.59411 8.88268 2.13319C8.45235 1.70287 7.38999 0.694314 6.36797 0.949819C5.34595 1.20532 4.99632 2.25422 4.84839 2.61731Z" fill="#DEE1E9"/>
        <path d="M24.5946 66.9796C24.0723 69.3976 23.0659 71.6848 21.6361 73.7034C20.489 75.5466 18.8494 77.0325 16.9026 77.9932C15.2433 78.8361 13.3488 79.0966 11.5235 78.7328C10.3939 78.4773 10.3267 78.1412 7.2203 76.2182C4.1139 74.2951 3.72392 74.3624 2.93051 73.5286C2.1371 72.6949 1.18232 71.4308 0.711654 67.4638C0.391814 63.7376 0.691724 59.9841 1.5992 56.356C1.79643 55.2623 1.98469 54.173 2.164 53.0883L6.6286 49.4036C8.12267 47.8593 10.1228 46.9045 12.2632 46.7141C13.3594 46.6368 14.4602 46.7738 15.504 47.1175L21.5823 50.5736C22.9271 53.9355 23.734 54.9037 24.3929 57.9697C25.142 60.919 25.211 63.9999 24.5946 66.9796Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M7.76611 69.1333L8.69945 70.3837L9.51611 69.6359V71.2929L10.7972 71.0089L10.0994 71.8842L11.2661 72.8846L10.0994 73.8849L10.7972 74.7602L9.63278 74.3851L9.51611 76.1333L8.39116 75.3848L7.99945 75.8856L7.14106 75.3848L6.01611 76.1333L5.66611 75.1354L4.73502 74.7602L4.96611 73.3848L4.26611 72.8846L5.43278 72.1343L4.73502 71.0089L5.66611 70.8839L6.01611 69.6359L7.53278 70.6338L7.76611 69.1333Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M8.5968 2.68065L10.6805 1.41089H14.1642L16.2642 2.68065L14.6526 4.22716L16.7363 5.77367L14.848 7.32018L17.1433 9.0132L15.32 10.5597L17.6154 11.9597L15.5968 13.376L17.6154 14.9876L15.9224 16.5341L18.1526 17.9504L16.3945 19.4155L18.6898 20.962L16.541 22.5085L18.6898 24.1201L17.1433 25.4713L19.227 27.0178L17.4038 28.4992L19.4875 30.0457L17.6805 31.7876L18.6898 33.4643V47.7247L16.8014 55.0503H10.6805" fill="#8889BE"/>
        <path d="M8.5968 2.68065L10.6805 1.41089H14.1642L16.2642 2.68065L14.6526 4.22716L16.7363 5.77367L14.848 7.32018L17.1433 9.0132L15.32 10.5597L17.6154 11.9597L15.5968 13.376L17.6154 14.9876L15.9224 16.5341L18.1526 17.9504L16.3945 19.4155L18.6898 20.962L16.541 22.5085L18.6898 24.1201L17.1433 25.4713L19.227 27.0178L17.4038 28.4992L19.4875 30.0457L17.6805 31.7876L18.6898 33.4643V47.7247L16.8014 55.0503H10.6805" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M16.0041 2.68065L13.9204 1.41089H10.4529L8.33665 2.68065L9.96456 4.22716L7.86456 5.77367L9.75293 7.32018L7.47386 9.0132L9.28084 10.5597L7.00177 11.9597L9.02037 13.376L7.00177 14.9876L8.67851 16.5341L6.46456 17.9504L8.20642 19.4155L5.92736 20.962L8.07619 22.5085L5.92736 24.1201L7.47386 25.4713L5.39015 27.0178L7.19712 28.4992L5.1134 30.0457L6.93666 31.7876L5.92736 33.4643V47.7247L7.79944 55.0503H11.7064" fill="#8889BE"/>
        <path d="M16.0041 2.68065L13.9204 1.41089H10.4529L8.33665 2.68065L9.96456 4.22716L7.86456 5.77367L9.75293 7.32018L7.47386 9.0132L9.28084 10.5597L7.00177 11.9597L9.02037 13.376L7.00177 14.9876L8.67851 16.5341L6.46456 17.9504L8.20642 19.4155L5.92736 20.962L8.07619 22.5085L5.92736 24.1201L7.47386 25.4713L5.39015 27.0178L7.19712 28.4992L5.1134 30.0457L6.93666 31.7876L5.92736 33.4643V47.7247L7.79944 55.0503H11.7064" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M21.6361 68.8479C23.0659 66.8292 24.0723 64.542 24.5946 62.1241C25.211 59.1443 25.142 56.0634 24.3929 53.1142C23.734 50.0481 22.9271 49.0799 21.5823 45.718H18C17.4477 45.718 17 46.1657 17 46.718V54.9109C12.3032 56.2887 12.4824 56.3163 7.78732 54.9109V46.718C7.78732 46.1657 7.33961 45.718 6.78732 45.718H2.93051C2.75121 46.8028 1.79643 50.4067 1.5992 51.5005C0.691724 55.1286 0.391814 58.882 0.711654 62.6082C1.18232 66.5753 2.1371 67.8393 2.93051 68.6731C3.72392 69.5068 4.1139 69.4396 7.2203 71.3626C10.3267 73.2856 10.3939 73.6218 11.5235 73.8773C13.3488 74.241 15.2433 73.9805 16.9026 73.1377C18.8494 72.1769 20.489 70.6911 21.6361 68.8479Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask515" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="46" width="26" height="29">
          <path d="M21.6452 68.848C23.075 66.8293 24.0813 64.5421 24.6036 62.1242C25.22 59.1444 25.1511 56.0635 24.4019 53.1143C23.7866 50.251 23.0422 49.9767 21.8513 47.2149C21.6894 46.8394 21.3233 46.5889 20.9143 46.5889H18.009C17.4567 46.5889 17.009 47.0366 17.009 47.5889V54.1622C17.009 54.6061 16.714 54.9978 16.2881 55.1227C12.5428 56.2215 12.2591 56.2418 8.51227 55.1251C8.08884 54.9989 7.79636 54.6083 7.79636 54.1665V47.5889C7.79636 47.0366 7.34864 46.5889 6.79636 46.5889H3.78782C3.29848 46.5889 2.87591 46.9417 2.75026 47.4147C2.40749 48.7048 1.76587 50.6264 1.60823 51.5006C0.700757 55.1287 0.400847 58.8821 0.720687 62.6083C1.19135 66.5753 2.14613 67.8394 2.93954 68.6732C3.73295 69.5069 4.12293 69.4397 7.22933 71.3627C10.3357 73.2857 10.403 73.6219 11.5326 73.8774C13.3578 74.2411 15.2523 73.9806 16.9116 73.1378C18.8585 72.177 20.498 70.6912 21.6452 68.848Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask515)">
          <path d="M4.26294 32.0271H20.3972V57.5751C14.0964 59.5013 10.5638 59.5209 4.26294 57.5751V32.0271Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M8.49615 2.5559L10.5799 1.28613H14.0636L16.1636 2.5559L14.552 4.10241L16.6357 5.64891L14.7473 7.19542L17.0426 8.88844L15.2194 10.435L17.5147 11.8349L15.4961 13.2512L17.5147 14.8628L15.8217 16.4094L18.0519 17.8256L16.2938 19.2907L18.5892 20.8373L16.4403 22.3838L18.5892 23.9954L17.0426 25.3465L19.1264 26.8931L17.3031 28.3744L19.3868 29.921L17.5799 31.6628L18.5892 33.3396V47.6L16.7008 54.9256H10.5799" fill="#8889BE"/>
        <path d="M8.49615 2.5559L10.5799 1.28613H14.0636L16.1636 2.5559L14.552 4.10241L16.6357 5.64891L14.7473 7.19542L17.0426 8.88844L15.2194 10.435L17.5147 11.8349L15.4961 13.2512L17.5147 14.8628L15.8217 16.4094L18.0519 17.8256L16.2938 19.2907L18.5892 20.8373L16.4403 22.3838L18.5892 23.9954L17.0426 25.3465L19.1264 26.8931L17.3031 28.3744L19.3868 29.921L17.5799 31.6628L18.5892 33.3396V47.6L16.7008 54.9256H10.5799" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M15.9034 2.5559L13.8197 1.28613H10.3523L8.23601 2.5559L9.86391 4.10241L7.76391 5.64891L9.65228 7.19542L7.37322 8.88844L9.18019 10.435L6.90112 11.8349L8.91972 13.2512L6.90112 14.8628L8.57787 16.4094L6.36392 17.8256L8.10577 19.2907L5.82671 20.8373L7.97554 22.3838L5.82671 23.9954L7.37322 25.3465L5.2895 26.8931L7.09647 28.3744L5.01276 29.921L6.83601 31.6628L5.82671 33.3396V47.6L7.6988 54.9256H11.6058" fill="#8889BE"/>
        <path d="M15.9034 2.5559L13.8197 1.28613H10.3523L8.23601 2.5559L9.86391 4.10241L7.76391 5.64891L9.65228 7.19542L7.37322 8.88844L9.18019 10.435L6.90112 11.8349L8.91972 13.2512L6.90112 14.8628L8.57787 16.4094L6.36392 17.8256L8.10577 19.2907L5.82671 20.8373L7.97554 22.3838L5.82671 23.9954L7.37322 25.3465L5.2895 26.8931L7.09647 28.3744L5.01276 29.921L6.83601 31.6628L5.82671 33.3396V47.6L7.6988 54.9256H11.6058" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M21.6361 65.2876C23.0659 63.2689 24.0723 60.9817 24.5946 58.5638C25.211 55.584 25.142 52.5031 24.3929 49.5539C23.734 46.4878 22.9271 45.5196 21.5823 42.1577H18C17.4477 42.1577 17 42.6054 17 43.1577V51.3506C12.3032 52.7284 12.4824 52.756 7.78732 51.3506V43.1577C7.78732 42.6054 7.33961 42.1577 6.78732 42.1577H2.93051C2.75121 43.2425 1.79643 46.8464 1.5992 47.9402C0.691724 51.5683 0.391814 55.3217 0.711654 59.0479C1.18232 63.015 2.1371 64.279 2.93051 65.1128C3.72392 65.9465 4.1139 65.8793 7.2203 67.8023C10.3267 69.7253 10.3939 70.0615 11.5235 70.317C13.3488 70.6807 15.2433 70.4202 16.9026 69.5774C18.8494 68.6166 20.489 67.1308 21.6361 65.2876Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M0 76.771H21V81.771H0V76.771Z" fill="#838AA2"/>
        <path d="M22 76.771H43V81.771H22V76.771Z" fill="#838AA2"/>
        <path d="M14.2698 2.25525C11.6878 8.38736 13.0864 20.1675 13.0864 20.1675C14.1347 28.4297 14.0351 36.7972 12.7905 45.0321C12.1854 48.6898 11.6878 52.3611 11.0155 55.9919C10.1013 59.6189 9.80128 63.3735 10.1279 67.0996C10.5851 71.0667 11.6744 72.4787 12.3468 73.1645C13.0192 73.8503 13.4091 73.8369 16.6366 75.854C19.864 77.8712 19.8102 78.1132 20.9263 78.3687C22.7515 78.7356 24.647 78.475 26.3054 77.6291C28.2551 76.6726 29.8957 75.1858 31.0389 73.3393C32.4687 71.3207 33.4751 69.0335 33.9974 66.6155C34.6356 63.6312 34.585 60.5406 33.8495 57.5787C33.1906 54.5127 32.3703 53.5444 31.0389 50.1825C30.3666 48.4747 29.8959 46.982 28.0805 39.0748C26.7357 32.9158 25.7271 28.8277 24.3824 22.1981C23.5083 17.9755 21.9349 13.8874 21.4105 9.61109C21.2491 8.26633 20.8188 4.23205 18.3041 1.77113C17.8737 1.34081 16.8114 0.332254 15.7894 0.587758C14.7673 0.843263 14.4177 1.89216 14.2698 2.25525Z" fill="#DEE1E9"/>
        <path d="M31.0575 73.3413C32.4873 71.3227 33.4937 69.0355 34.016 66.6175C34.6324 63.6377 34.5634 60.5568 33.8143 57.6076C33.1553 54.5416 32.3485 53.5733 31.0037 50.2114H27.4214C26.8691 50.2114 26.4214 50.6591 26.4214 51.2114V59.4043C21.7246 60.7821 21.9038 60.8097 17.2087 59.4043V51.2114C17.2087 50.6591 16.761 50.2114 16.2087 50.2114H12.3519C12.1726 51.2962 11.2178 54.9002 11.0206 55.9939C10.1131 59.622 9.8132 63.3754 10.133 67.1016C10.6037 71.0687 11.5585 72.3328 12.3519 73.1665C13.1453 74.0003 13.5353 73.933 16.6417 75.856C19.7481 77.779 19.8153 78.1152 20.9449 78.3707C22.7701 78.7344 24.6646 78.4739 26.324 77.6311C28.2708 76.6704 29.9104 75.1845 31.0575 73.3413Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M5.26978 1.8656C2.68784 7.99771 4.08639 19.7778 4.08639 19.7778C5.13471 28.04 5.03515 36.4075 3.79054 44.6425C3.1854 48.3002 2.68784 51.9714 2.01546 55.6023C1.10128 59.2293 0.801282 62.9838 1.12791 66.71C1.58513 70.677 2.67439 72.089 3.34677 72.7748C4.01915 73.4607 4.40913 73.4472 7.63656 75.4644C10.864 77.4815 10.8102 77.7236 11.9263 77.9791C13.7515 78.346 15.647 78.0854 17.3054 77.2395C19.2551 76.2829 20.8957 74.7961 22.0389 72.9497C23.4687 70.931 24.4751 68.6438 24.9974 66.2259C25.6356 63.2415 25.585 60.1509 24.8495 57.1891C24.1906 54.123 23.3703 53.1548 22.0389 49.7929C21.3666 48.085 20.8959 46.5924 19.0805 38.6852C17.7357 32.5262 16.7271 28.4381 15.3824 21.8084C14.5083 17.5859 12.9349 13.4978 12.4105 9.22145C12.2491 7.87668 11.8188 3.8424 9.30406 1.38149C8.87374 0.951162 7.81138 -0.0573948 6.78936 0.19811C5.76734 0.453614 5.4177 1.50251 5.26978 1.8656Z" fill="#DEE1E9"/>
        <path d="M25.016 66.2279C24.4937 68.6459 23.4873 70.9331 22.0575 72.9517C20.9104 74.7949 19.2708 76.2808 17.324 77.2415C15.6646 78.0844 13.7701 78.3449 11.9449 77.9811C10.8153 77.7256 10.7481 77.3895 7.64168 75.4664C4.53529 73.5434 4.14531 73.6107 3.3519 72.7769C2.55849 71.9432 1.60371 70.6791 1.13304 66.712C0.8132 62.9859 1.11311 59.2324 2.02058 55.6043C2.21781 54.5106 2.40608 53.4213 2.58538 52.3365L7.04999 48.6519C8.54405 47.1076 10.5442 46.1528 12.6845 45.9624C13.7808 45.885 14.8816 46.0221 15.9254 46.3658L22.0037 49.8218C23.3485 53.1837 24.1554 54.152 24.8143 57.218C25.5634 60.1672 25.6324 63.2481 25.016 66.2279Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M13.193 55.0938C12.1172 55.2821 10.1404 50.8175 9.15874 46.5277L8.21741 34.1828C8.04259 30.2561 7.93501 27.0555 7.86777 24.8501C7.71985 19.8476 7.77364 19.229 7.58537 17.1043C7.11471 11.6446 7.30272 1.69543 7.58511 1.5744C7.86751 1.45338 7.86777 10.7436 8.71497 13.2314C9.5594 16.3677 10.0954 19.579 10.3152 22.8195C10.6479 26.3922 11.2048 29.9404 11.9827 33.4431C12.7089 36.2671 13.3275 37.6119 14.1882 41.6596C14.6404 43.5318 14.8795 45.449 14.9009 47.3749C14.8908 48.9089 14.715 50.4374 14.3764 51.9336C13.9192 54.0046 13.6771 55.0938 13.193 55.0938Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M5.26978 1.8656C2.68784 7.99771 4.08639 19.7778 4.08639 19.7778C5.13471 28.04 5.03515 36.4075 3.79054 44.6425C3.1854 48.3002 2.68784 51.9714 2.01546 55.6023C1.10128 59.2293 0.801282 62.9838 1.12791 66.71C1.58513 70.677 2.67439 72.089 3.34677 72.7748C4.01915 73.4607 4.40913 73.4472 7.63656 75.4644C10.864 77.4815 10.8102 77.7236 11.9263 77.9791C13.7515 78.346 15.647 78.0854 17.3054 77.2395C19.2551 76.2829 20.8957 74.7961 22.0389 72.9497C23.4687 70.931 24.4751 68.6438 24.9974 66.2259C25.6356 63.2415 25.585 60.1509 24.8495 57.1891C24.1906 54.123 23.3703 53.1548 22.0389 49.7929C21.3666 48.085 20.8959 46.5924 19.0805 38.6852C17.7357 32.5262 16.7271 28.4381 15.3824 21.8084C14.5083 17.5859 12.9349 13.4978 12.4105 9.22145C12.2491 7.87668 11.8188 3.8424 9.30406 1.38149C8.87374 0.951162 7.81138 -0.0573948 6.78936 0.19811C5.76734 0.453614 5.4177 1.50251 5.26978 1.8656Z" fill="#DEE1E9"/>
        <path d="M25.016 66.2279C24.4937 68.6459 23.4873 70.9331 22.0575 72.9517C20.9104 74.7949 19.2708 76.2808 17.324 77.2415C15.6646 78.0844 13.7701 78.3449 11.9449 77.9811C10.8153 77.7256 10.7481 77.3895 7.64168 75.4664C4.53529 73.5434 4.14531 73.6107 3.3519 72.7769C2.55849 71.9432 1.60371 70.6791 1.13304 66.712C0.8132 62.9859 1.11311 59.2324 2.02058 55.6043C2.21781 54.5106 2.40608 53.4213 2.58538 52.3365L7.04999 48.6519C8.54405 47.1076 10.5442 46.1528 12.6845 45.9624C13.7808 45.885 14.8816 46.0221 15.9254 46.3658L22.0037 49.8218C23.3485 53.1837 24.1554 54.152 24.8143 57.218C25.5634 60.1672 25.6324 63.2481 25.016 66.2279Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.4" d="M13.193 55.0938C12.1172 55.2821 10.1404 50.8175 9.15874 46.5277L8.21741 34.1828C8.04259 30.2561 7.93501 27.0555 7.86777 24.8501C7.71985 19.8476 7.77364 19.229 7.58537 17.1043C7.11471 11.6446 7.30272 1.69543 7.58511 1.5744C7.86751 1.45338 7.86777 10.7436 8.71497 13.2314C9.5594 16.3677 10.0954 19.579 10.3152 22.8195C10.6479 26.3922 11.2048 29.9404 11.9827 33.4431C12.7089 36.2671 13.3275 37.6119 14.1882 41.6596C14.6404 43.5318 14.8795 45.449 14.9009 47.3749C14.8908 48.9089 14.715 50.4374 14.3764 51.9336C13.9192 54.0046 13.6771 55.0938 13.193 55.0938Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M5.26978 1.86609C2.68784 7.9982 4.08639 19.7783 4.08639 19.7783C5.13471 28.0405 5.03515 36.408 3.79054 44.6429C3.1854 48.3007 2.68784 51.9719 2.01546 55.6028C1.10128 59.2298 0.801282 62.9843 1.12791 66.7105C1.58513 70.6775 2.67439 72.0895 3.34677 72.7753C4.01915 73.4612 4.40913 73.4477 7.63656 75.4649C10.864 77.482 10.8102 77.7241 11.9263 77.9796C13.7515 78.3465 15.647 78.0858 17.3054 77.24C19.2551 76.2834 20.8957 74.7966 22.0389 72.9502C23.4687 70.9315 24.4751 68.6443 24.9974 66.2264C25.6356 63.242 25.585 60.1514 24.8495 57.1896C24.1906 54.1235 23.3703 53.1553 22.0389 49.7934C21.3666 48.0855 20.8959 46.5928 19.0805 38.6857C17.7357 32.5266 16.7271 28.4386 15.3824 21.8089C14.5083 17.5863 12.9349 13.4983 12.4105 9.22193C12.2491 7.87717 11.8188 3.84289 9.30406 1.38197C8.87374 0.95165 7.81138 -0.0569065 6.78936 0.198598C5.76734 0.454103 5.4177 1.503 5.26978 1.86609Z" fill="#DEE1E9"/>
        <path d="M25.016 66.2284C24.4937 68.6464 23.4873 70.9336 22.0575 72.9522C20.9104 74.7954 19.2708 76.2813 17.324 77.242C15.6646 78.0849 13.7701 78.3453 11.9449 77.9816C10.8153 77.7261 10.7481 77.3899 7.64168 75.4669C4.53529 73.5439 4.14531 73.6112 3.3519 72.7774C2.55849 71.9437 1.60371 70.6796 1.13304 66.7125C0.8132 62.9863 1.11311 59.2329 2.02058 55.6048C2.21781 54.5111 2.40608 53.4218 2.58538 52.337L7.04999 48.6524C8.54405 47.1081 10.5442 46.1533 12.6845 45.9629C13.7808 45.8855 14.8816 46.0226 15.9254 46.3663L22.0037 49.8223C23.3485 53.1842 24.1554 54.1525 24.8143 57.2185C25.5634 60.1677 25.6324 63.2486 25.016 66.2284Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask515" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="20" width="15" height="38">
          <path d="M5.30621 20.9844L12.0689 20.9844L19.4687 55.147L6.79372 57.4182L5.30621 20.9844Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask515)">
          <path opacity="0.2" d="M13.193 55.0943C12.1172 55.2826 10.1404 50.818 9.15874 46.5282L8.21741 34.1833C8.04259 30.2566 7.93501 27.056 7.86777 24.8506C7.71985 19.8481 7.77364 19.2295 7.58537 17.1048C7.11471 11.6451 7.30272 1.69592 7.58511 1.57489C7.86751 1.45386 7.86777 10.7441 8.71497 13.2319C9.5594 16.3682 10.0954 19.5795 10.3152 22.82C10.6479 26.3927 11.2048 29.9409 11.9827 33.4436C12.7089 36.2676 13.3275 37.6124 14.1882 41.6601C14.6404 43.5323 14.8795 45.4495 14.9009 47.3754C14.8908 48.9094 14.715 50.4379 14.3764 51.9341C13.9192 54.005 13.6771 55.0943 13.193 55.0943Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5015" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="0" width="10" height="21">
          <path d="M5.93951 1.84046L6.63937 0.358398H8.03918L9.02729 1.30515L13.5561 20.9191H4.78668L5.15722 12.2154L5.61014 4.51657L5.93951 1.84046Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5015)">
          <path opacity="0.4" d="M13.193 55.0943C12.1172 55.2826 10.1404 50.818 9.15874 46.5282L8.21741 34.1833C8.04259 30.2566 7.93501 27.056 7.86777 24.8506C7.71985 19.8481 7.77364 19.2295 7.58537 17.1048C7.11471 11.6451 7.30272 1.69592 7.58511 1.57489C7.86751 1.45386 7.86777 10.7441 8.71497 13.2319C9.5594 16.3682 10.0954 19.5795 10.3152 22.82C10.6479 26.3927 11.2048 29.9409 11.9827 33.4436C12.7089 36.2676 13.3275 37.6124 14.1882 41.6601C14.6404 43.5323 14.8795 45.4495 14.9009 47.3754C14.8908 48.9094 14.715 50.4379 14.3764 51.9341C13.9192 54.005 13.6771 55.0943 13.193 55.0943Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M4.58228 2.3833C2.00034 8.51541 3.39889 20.2955 3.39889 20.2955C4.44721 28.5577 4.34765 36.9252 3.10304 45.1602C2.4979 48.8179 2.00034 52.4891 1.32796 56.12C0.413782 59.747 0.113782 63.5015 0.440414 67.2277C0.897633 71.1947 1.98689 72.6067 2.65927 73.2925C3.33165 73.9784 3.72163 73.9649 6.94906 75.9821C10.1765 77.9992 10.1227 78.2413 11.2388 78.4968C13.064 78.8637 14.9595 78.6031 16.6179 77.7572C18.5676 76.8006 20.2082 75.3138 21.3514 73.4674C22.7812 71.4487 23.7876 69.1615 24.3099 66.7436C24.9481 63.7592 24.8975 60.6686 24.162 57.7068C23.5031 54.6407 22.6828 53.6725 21.3514 50.3106C20.6791 48.6027 20.2084 47.1101 18.393 39.2029C17.0482 33.0439 16.0396 28.9558 14.6949 22.3261C13.8208 18.1036 12.2474 14.0155 11.723 9.73915C11.5616 8.39438 11.1313 4.3601 8.61656 1.89919C8.18624 1.46886 7.12388 0.460305 6.10186 0.71581C5.07984 0.971315 4.7302 2.02022 4.58228 2.3833Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M5.58228 2.35681C3.00034 8.48892 4.39889 20.269 4.39889 20.269C5.44721 28.5312 5.34765 36.8987 4.10304 45.1337C3.4979 48.7914 3.00034 52.4626 2.32796 56.0935C1.41378 59.7205 1.11378 63.475 1.44041 67.2012C1.89763 71.1682 2.98689 72.5802 3.65927 73.2661C4.33165 73.9519 4.72163 73.9384 7.94906 75.9556C11.1765 77.9727 11.1227 78.2148 12.2388 78.4703C14.064 78.8372 15.9595 78.5766 17.6179 77.7307C19.5676 76.7741 21.2082 75.2874 22.3514 73.4409C23.7812 71.4222 24.7876 69.135 25.3099 66.7171C25.9481 63.7327 25.8975 60.6422 25.162 57.6803C24.5031 54.6142 23.6828 53.646 22.3514 50.2841C21.6791 48.5763 21.2084 47.0836 19.393 39.1764C18.0482 33.0174 17.0396 28.9293 15.6949 22.2996C14.8208 18.0771 13.2474 13.989 12.723 9.71266C12.5616 8.3679 12.1313 4.33361 9.61656 1.8727C9.18624 1.44237 8.12388 0.433816 7.10186 0.689321C6.07984 0.944825 5.7302 1.99373 5.58228 2.35681Z" fill="#DEE1E9"/>
      <path d="M25.3285 66.719C24.8062 69.137 23.7998 71.4242 22.37 73.4429C21.2229 75.2861 19.5833 76.7719 17.6365 77.7326C15.9771 78.5755 14.0826 78.836 12.2574 78.4723C11.1278 78.2168 11.0606 77.8806 7.95418 75.9576C4.84779 74.0346 4.45781 74.1018 3.6644 73.268C2.87099 72.4343 1.91621 71.1702 1.44554 67.2032C1.1257 63.477 1.42561 59.7235 2.33308 56.0954C2.53031 55.0017 2.71858 53.9124 2.89788 52.8277L7.36249 49.143C8.85655 47.5987 10.8567 46.6439 12.997 46.4535C14.0933 46.3762 15.1941 46.5132 16.2379 46.8569L22.3162 50.313C23.661 53.6749 24.4679 54.6431 25.1268 57.7092C25.8759 60.6584 25.9449 63.7393 25.3285 66.719Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth15",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
