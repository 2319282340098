export default {
    primaryKey: 'advance_id',
    model: 'advance',
    order: 'advance_id',
    sort: 'desc',
    modaltitle: 'deposit',
    actionscomponent: 'PatientDatatableAdvanceActions',
    deletemessage: 'are_you_sure_you_want_to_delete_transaction',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    rights: {
        edit: "remove_payment",
        delete: "remove_payment",
    },
    fields: [
        {
            id: 'advance_id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'date_payed',
            title: 'date',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            table_show: 'date_timezone',
            form: false,
            format_datetime: true
        },
        {
            id: 'date_timezone',
            title: 'date',
            table: false,
            form: {
                type: "datetime"
            }
        },
        {
            id: 'form',
            title: 'payment_type',
            table: true,
            sortable: true,
            format_translate: true,
            table_show: 'payment_type',
        },
        {
            id: 'cancel',
            title: 'operation_type',
            table: true,
            sortable: true,
            format_translate: true,
            filter_list_select: true,
            form: false,
            // form: {
            //     type: "select",
            //     select_type: "list",
            //     options: {
            //         'Y': { id: 'Y', title: 'write_off' },
            //         'N': { id: 'N', title: 'filling' },
            //     }
            // }
        },
        {
            id: 'signed_paid',
            title: 'sum',
            table: true,
            sortable: false,
            form: false,
            // form: {
            //     type: "number"
            // },
            format_currency: true
        },
        {
            id: 'deposit_account_id',
            title: 'deposit_account',
            table: true,
            sortable: true,
            form: false,
            tdcomponent: "PatientsDatatableTDAdvanceDepositAccount"
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            form: {
                type: "text",
            },
            filter: true
        },
    ]
}
