export default {
    primaryKey: 'id',
    model: 'record',
    order: 'start',
    sort: 'desc',
    // actionscomponent: 'PatientDatatableRecordActions',
    actionscomponent: 'PatientWaitingListDatatableActions',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    showTotalRecords: true,
    actions: [
        // "add",
        // "delete"
    ],
    // rights: {
    //     delete: "patients",
    // },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        // {
        //     id: 'start',
        //     title: 'visit',
        //     table: true,
        //     sortable: true,
        //     tabletdclass: "order-mob-0",
        //     tdcomponent: "PatientsDatatableTDStart"
        // },
        {
            id: 'start',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            form: {
                type: "date",
                required: true
            },
            tabletdclass: "order-mob-0",
            filter: true,
            filter_date: true
        },
        {
            id: 'doctor_id',
            title: 'doctor',
            table: true,
            sortable: true,
            filter: true,
            tdcomponent: 'PlanningDatatableTDDoctorName',
            table_show: 'doctor.full_name',
        },
        {
            id: 'client_id',
            title: 'patient',
            table: true,
            sortable: true,
            filter: true,
            tdcomponent: 'PatientsDatatableTDName',
            table_show: 'patient.full_name',
        },
        {
            id: 'record_type',
            title: 'type',
            table: true,
            sortable: true,
            filter: true,
            format_translate: true,
        },
        {
            id: 'records_statuses_id',
            title: 'status',
            table: true,
            table_show: 'readableStatus',
            sortable: true,
            format_translate: true,
            tdcomponent: "PatientsDatatableTDRecordStatus"
        },
    ]
}
