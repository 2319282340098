<template>
  <div class="patient-card no-print"
       v-if="!treatmentCard">
    <b-row v-if="patient">
      <b-col md="12" cols="12">
        <b-row>
          <b-col cols="12" lg="auto" class="text-center">
            <PatientImg class="p-img"/>
          </b-col>
          <b-col md cols="12">
            <h2 class="text-lg-left text-center">
              <span>{{ patient.full_name }}</span>
              <span class="ml-2 small font-weight-normal"
                    v-if="patient.gender_string !== null">
                  ({{ $t(patient.gender_string+'_short') }})
                </span>
              <span class="ml-2" v-if="!isPatient">
                  <a @click="toggleTag(t, 'remove', tind)"
                     class="circle mr-2 pointer"
                     :style="`background-color: ${t.color}`"
                     v-for="(t, tind) in patient.tags"
                     :title="t.title"
                     v-b-tooltip.hover
                     :key="`made-tag-${tind}`"></a>
                </span>
              <b-dropdown right variant="link"
                          no-caret
                          v-if="patientTags.length < tags.length && !isPatient">
                <template #button-content>
                  <a class="pointer position-relative" style="top: -3px;"><PlusSvg/></a>
                </template>
                <div class="pt-0">
                  <template v-for="(t, tind) in tags">
                    <a class="dropdown-item pointer"
                       :key="`tag-${tind}`"
                       @click="toggleTag(t, 'add')"
                       v-if="!patientTags.includes(t.id)">
                      <span class="circle mr-2" :style="`background-color: ${t.color}`"></span>
                      {{ t.title }}
                    </a>
                  </template>
                </div>
              </b-dropdown>
            </h2>
            <div v-if="patient.clientStatus">
                <span class="badge" :class="'badge-'+patient.clientStatus">
                {{ $t(patient.clientStatus) }}
                </span>
            </div>
            <div>
              <div v-if="patient.blocked == 1">
                <div class="d-inline-block text-danger font-weight-bold">{{ $t('patient_deleted') }}</div>
                <a class="pointer text-underlined ml-2 text-lowercase"
                   tabindex="#"
                   @click="restorePatient">({{ $t('restore') }})</a>
              </div>
              <!--                <div><span class="text-muted">{{ $t('activity') }}:</span> {{ $t(patient.activity_status) }}</div>-->
              <!--                <div v-if="patient.activity_status">{{ $t(patient.activity_status) }}</div>-->
              <div v-if="Number(clinic.country_id) === countries.KAZAKHSTAN && patient.iin">{{ $t('iin') }}: {{ patient.iin }}</div>
              <div>
                {{ $t('years') }}: {{ patient.age || patient.age === 0 ? patient.age : '-' }}
                <span class="text-muted" v-if="patient.date_of_birth">({{ formatDate(patient.date_of_birth) }})</span>
              </div>
              <div v-if="patient.patient_condition">{{ $t('condition') }}: {{ patient.patient_condition.title }}</div>
              <div v-if="patient.email || patient.phone">
                <div v-if="patient.email" class="d-inline-block">
                  <a @click="sendMail"
                     class="link pointer text-primary"
                     v-b-tooltip.hover
                     :title="$t('send_mail')"
                     v-if="rights.includes('emailing')">
                    {{ patient.email }}
                  </a>
                  <span v-else>{{ patient.email }}</span>
                </div>
                <a class="pointer"
                   :class="{ 'ml-3': patient.email }"
                   v-b-tooltip.hover
                   v-if="rights.includes('crm') && (patient.email || patient.phone)"
                   :title="$t('plan_notification')"
                   @click="$bvModal.show('deferred-message-modal')">
                  <ClockSvg class="svg-primary"/>
                </a>
              </div>
              <div v-if="patient.description">{{ patient.description }}</div>
              <div v-if="patient.url">
                <a target="_blank"
                   :href="patient.url"
                   ref="nofollow">
                  {{ patient.url }}
                </a>
              </div>
              <div class="font-italic">{{ $t('source') }}: {{ patient.sourceName}}</div>
            </div>
          </b-col>
          <b-col lg="6" xl="6" class="mt-3 mt-lg-0">
            <b-row class="justify-content-xl-end">
              <b-col cols="auto" md="6" lg="auto">
                <div v-if="patient.mphone && !rights.includes('hide_patient_phone')">
                  <OutgoingCallElement :phone="patient.mphone"
                                       color="black"
                                       class="mphone"/>
                  <a class="ml-3"
                     :href="`tel:${patient.mphone}`"
                     v-b-tooltip.hover
                     :title="$t('call')">
                    <PhoneSvg class="svg-primary"/>
                  </a>
                  <a v-if="clinic && clinic.sms_info && rights.includes('sms_send')"
                     v-b-tooltip.hover
                     :title="$t('send_sms')"
                     class="ml-3 pointer"
                     @click="smsModal(patient.mphone)">
                    <SmsSvg class="svg-primary"/>
                  </a>

                  <WhatsappTelegramIcon :patient-id="patient.id" :phone="patient.phone"/>

                  <div class="phone-comment" v-if="patient.mphone_descr">{{ patient.mphone_descr }}</div>
                </div>
              </b-col>
              <b-col cols="auto" md="6" lg="auto"
                     class="mt-md-0 mt-3"
                     v-if="patient.mphone2 && !rights.includes('hide_patient_phone')">
                <div>
                  <div class="left-separator"></div>
                  <div class="d-inline-block">
                    <OutgoingCallElement :phone="patient.mphone2"
                                         color="black"
                                         class="mphone"/>
                    <a class="ml-3"
                       :href="`tel:${patient.mphone2}`"
                       v-b-tooltip.hover
                       :title="$t('call')">
                      <PhoneSvg class="svg-primary"/>
                    </a>
                    <a v-if="clinic && clinic.sms_info && rights.includes('sms_send')"
                       v-b-tooltip.hover
                       :title="$t('send_sms')"
                       class="ml-3 pointer"
                       @click="smsModal(patient.mphone2)">
                      <SmsSvg class="svg-primary"/>
                    </a>
                    <WhatsappTelegramIcon :patient-id="patient.id" :phone="patient.mphone2"/>

                    <div class="phone-comment" v-if="patient.mphone2_descr">{{ patient.mphone2_descr }}</div>
                  </div>
                </div>
              </b-col>
            </b-row>
            <PatientAddTask :patient="patient"
                            v-if="rights.includes('tasks') && patient"
                            class="mt-4"/>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="12" cols="12" class="mt-3">
        <a class="d-block d-md-none more-info-link collapse-btn-arrow"
           v-spaceduck-collapse="`collapse-patient-info`">
          {{ $t('detailed_information') }}
        </a>
        <spaceduck-collapse id="collapse-patient-info" :visible="!isMobile">
          <b-row>
            <b-col md cols="12" class="mb-md-0 mb-3">
              <div class="card-p">
                <PatientColInline label="card_number" :title="patient.card"/>
                <PatientColInline v-if="patient.activity_status" label="activity" :title="$t(patient.activity_status)"/>
                <PatientColInline label="treating_doctor" :title="patient.doctor ? patient.doctor.full_name : '-'"/>
                <PatientColInline label="discount" :title="((patient.discount ? patient.discount : 0) + '%')"/>
              </div>
            </b-col>
            <b-col md cols="12" class="mb-md-0 mb-3" v-if="rights.includes('payments')">
              <div class="card-p">
                <PatientColInline v-if="usesBonuses"
                                  label="bonuses"
                                  :title="formatCurrency(patient.total_bonuses)">
                  <a class="pointer ml-2 position-relative"
                     style="top: -2px;"
                     v-b-tooltip.hover
                     :title="$t('bonus_adjustment')"
                     @click="bonusRefill"
                     v-if="rights.includes('settings')">
                    <DollarSvg class="svg-light"/>
                  </a>
                </PatientColInline>
                <PatientColInline label="balance"
                                  v-if="rights.includes('deposit')"
                                  :title="formatCurrency(patient.advance_calculated)">
                  <a class="pointer ml-2 position-relative"
                     v-b-tooltip.hover
                     style="top: -2px;"
                     :title="$t('balance_refill')"
                     v-if="rights.includes('payments')"
                     @click="balanceRefill">
                    <DollarSvg class="svg-light"/>
                  </a>
                  <b-dropdown variant="text"
                              class="ml-2"
                              toggle-class="line-height-normal border-0 font-size-0"
                              menu-class="pl-2"
                              toggle-tag="a"
                              v-if="patient.deposit_by_accounts && patient.deposit_by_accounts.length">
                    <template #button-content>
                      <InfoSvg v-b-tooltip.hover
                               :title="$t('deposit_accounts')"
                               class="svg-size-lg svg-light position-relative"
                               style="top: -2px;"/>
                    </template>
                    <DepositByAccountsTable :patient="patient" size="md"/>
                  </b-dropdown>
                </PatientColInline>
                <PatientColInline label="total_paid"
                                  :title="formatCurrency(patient.totalPaidBranch)"/>
                <PatientColInline :label="debtForBranch > 0 ? 'overpayment' : 'debt'"
                                  :class="{ 'text-danger': debtForBranch < 0, 'text-success': debtForBranch > 0 }"
                                  :title="formatCurrency(debtForBranch)">
                  <a v-if="debtForBranch < 0 && rights.includes('payments')"
                     v-b-tooltip.hover
                     :title="$t('payment')"
                     style="top: -2px;"
                     class="ml-2 pointer position-relative"
                     @click="paymentPressed">
                    <DollarSvg class="svg-light"/>
                  </a>
                </PatientColInline>
              </div>
            </b-col>
            <b-col md cols="12" class="mb-md-0 mb-3">
              <div class="card-p">
                <PatientColInline label="last_visit"
                                  :title="patient.lastVisit ? formatDate(patient.lastVisit.start, 'DD.MM.YYYY, HH:mm') : '-'"/>
                <PatientColInline label="next_visit"
                                  :title="patient.nextVisit ? formatDate(patient.nextVisit.start, 'DD.MM.YYYY, HH:mm') : '-'"/>
                <PatientColInline label="curator"
                                  :title="patient.curator ? patient.curator.full_name : '-'"/>
              </div>
            </b-col>
          </b-row>
        </spaceduck-collapse>
      </b-col>
    </b-row>
    <PatientSMSModal :specified-phone="selectedPhone"/>
  </div>
</template>

<script setup>
import DepositByAccountsTable from "@/components/parts/patients/DepositByAccountsTable.vue"
import PatientColInline from "@/components/parts/patients/PatientColInline.vue"
import PatientAddTask from "@/components/parts/patients/PatientAddTask.vue"
import SmsSvg from "@/assets/svg-vue/general/sms_t.svg.vue"
import InfoSvg from "@/assets/svg-vue/patients/arrow_down_circle.svg.vue"
import PatientImg from "@/components/parts/patients/PatientImg.vue"
import PlusSvg from "@/assets/svg-vue/patients/plus_tag.svg.vue"
import DollarSvg from "@/assets/svg-vue/patients/dollar.svg.vue"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"
import ClockSvg from "@/assets/svg-vue/calendar/clock2.svg.vue"
import PhoneSvg from "@/assets/svg-vue/header/phone_t.svg.vue"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import {computed, getCurrentInstance, nextTick, onBeforeMount, onMounted, ref} from "vue"
import {useRoute} from "vue-router"
import {useStore} from "vuex"
import {formatCurrency, formatDate} from "@/extensions/filters/filters"
import {EloquentService, PatientService} from "@/services/api.service"
import {countries} from "@/dictionaries/dictionary"
import PatientSMSModal from "@/components/parts/patients/PatientSMSModal.vue"

const route = useRoute()
const store = useStore()
const instance = getCurrentInstance()

const treatmentCard = computed(() => ['PatientVisitPage'].includes(route.name))

const clinic = computed(() => store.state.auth.clinic)
const branchId = computed(() => store.state.auth.branch)
const patient = computed(() => store.state.patient.patient)
const isPatient = computed(() => store.getters.isPatient)
const usesBonuses = computed(() => store.getters.usesBonuses)
const isMobile = computed(() => store.state.dom.isMobile)
const rights = computed(() => store.state.auth.rights)
const patientTags = computed(() => patient.value?.tags ? patient.value.tags.map(el => el.id) : [])
const debtForBranch = computed(() => {
  if(!patient.value) return 0
  if(patient.value.debt_calculated_branched) {
    return patient.value.debt_calculated_branched[branchId.value]
  }
  return patient.value.debt_calculated
})

const tags = ref([])
const selectedPhone = ref(null)

let $bvModal = null
let i18n = null
let noty = null

const toggleTag = (tag, action = 'add', index = null) => {
  if(action === 'remove') {
    if(!confirm(i18n.t('confirm_tag_deletion'))) return
  }
  PatientService.toggleTag(patient.value.id, tag.id).then(() => {
    if(action === 'add') {
      patient.value.tags.push(tag)
    }
    if(action === 'remove' && index !== null) {
      patient.value.tags.splice(index, 1)
    }
  })
}

const restorePatient = async () => {
  await EloquentService.restore('patient', patient.value.id)
  await store.dispatch('getPatient', patient.value.id)
  noty.info(i18n.t('success_message'))
}

const smsModal = async (phone = null) => {
  selectedPhone.value = phone
  await nextTick()
  $bvModal.show('patient-sms-modal')
}

const paymentPressed = () => $bvModal.show('patient-topay-modal')

const balanceRefill = () => $bvModal.show('refill-balance-modal')

const bonusRefill = () => $bvModal.show('refill-bonus-modal')

onBeforeMount(() => {
  i18n = instance?.ctx?.$i18n
  $bvModal = instance?.ctx?._bv__modal
  noty = instance?.appContext?.app?.noty
})

onMounted(() => {
  EloquentService.autocomplete('client_tag', '', 999).then(res => {
    tags.value = res.data.result
  })
})
</script>

<style scoped lang="scss">
.patient-card {
  padding: 20px;
  font-size: 16px;
  margin-bottom: 20px;
  margin-top: 50px;
  background: #f4f8fb;
  border-radius: 14px;
}
.card-p {
  background: white;
  border-radius: 14px;
  padding: 17px;
  height: 100%;
}
h2 {
  font-size: 22px;
  margin-bottom: 0;
  font-weight: bold;
}
.p-img {
  position: relative;
  top: -50px;
}
.mphone {
  font-size: 16px;
}
.left-separator {
  background: #D9D9D9;
  width: 1px;
  height: 25px;
  display: inline-block;
  margin-right: 20px;
  vertical-align: top;
}
.phone-comment {
  max-width: 200px;
  font-style: italic;
  color: #273849;
}

@media screen and (max-width: 1200px) {
  .left-separator {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .p-img {
    margin-bottom: -40px;
  }
  .mphone {
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .patient-card {
    font-size: 12px;
    margin-bottom: 20px;
    padding-bottom: 0;
  }
  h2 {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .card-p {
    padding: 10px;
  }
  .more-info-link {
    color: $gray-text;
    font-weight: bold;
    margin-top: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-top: 1px solid $calendar-border-color;
  }
  .mphone {
    font-size: 12px;
  }
}
</style>

