<template>
  <div class="doctor-hover-calendar bg-white" v-if="doctor">
    <b-row class="align-items-center no-gutters">
      <b-col cols="auto">
        <img class="round-img mr-2" :src="doctor.photo"/>
      </b-col>
      <b-col>
        <div class="doctor-title">{{ doctor.full_name }}</div>
        <div class="text-muted">{{ times }}</div>
      </b-col>
    </b-row>
    <div class="position-relative">
      <b-spinner v-if="loading" class="position-absolute" style="left: 50%; top: 50%; margin-left: -23px; margin-top: -23px; z-index: 5"></b-spinner>
      <VCalendar class="mt-3"
                 v-if="initiated"
                 ref="datepicker"
                 :locale="{ id: 'ru', firstDayOfWeek: 2, masks: { L: 'DD.MM.YYYY' } }"
                 borderless
                 expanded
                 @did-move="pageChange"
                 :initial-page="initialDate"
                 v-model="date">
        <template #day-content="{day}">
          <div v-if="day"
               class="vc-day-content position-relative"
               :class="{ 'text-muted': !fillData[day.label] }"
               @click="dayClicked(day)">
            <span class="position-relative day-title">{{ day.label }}</span>
            <div class="availability-column"
                 v-if="fillData[day.label]"
                 :style="{ height: fillData[day.label]+'%' }"></div>
          </div>
        </template>
      </VCalendar>
    </div>
  </div>
</template>

<script>
import { Calendar } from 'v-calendar'
import moment from "moment"
import { DoctorService } from "@/services/api.service"

export default {
  name: "DoctorHoverCalendar",
  components: {
    VCalendar: Calendar
    // DatePicker
  },
  props: {
    doctor: {
      type: Object
    },
    chair: {
      type: Object
    },
    day: {}
  },
  data() {
    return {
      date: moment(this.day).format('YYYY-MM'),
      fillData: {},
      loading: false,
      dayLoaded: moment(this.day).format('YYYY-MM'),
      initiated: false,
      initialDate: {}
    }
  },
  computed: {
    doctorSchedules() {
      return this.$store.state.calendar.doctorSchedules
    },
    branchId() {
      return this.$store.state.auth.branch
    },
    times() {
      const day = moment(this.day).format('YYYY-MM-DD')
      if(!this.doctorSchedules) return []
      return this.doctorSchedules[this.doctor.id]?.filter(s => (this.chair ? Number(s.chair_id) === this.chair.id : true) && s.day === day)
          .map(s => `${s.time_from.slice(0, -3)} - ${s.time_to.slice(0, -3)}`)
          .join(', ')
    },
  },
  methods: {
    changeCalendarDays() {
      this.$store.commit('changeCalendarDays', this.date)
    },
    async load(day) {
      if(!this.doctor) return
      this.loading = true
      this.dayLoaded = day
      this.fillData = {}
      const res = await DoctorService.monthAvailability(this.doctor.id, this.branchId, day)
      this.fillData = res.data
      this.loading = false
    },
    pageChange(e) {
      const day = `${e[0].year}-${e[0].month.toString().padStart(2, '0')}`
      if(this.dayLoaded !== day) this.load(day)
    },
    dayClicked(e) {
      this.$store.commit('goToDate', moment(e.date))
    },
  },
  mounted() {
    this.fillData = {}
    this.load(this.dayLoaded)
    this.initialDate = {
      month: moment(this.day).format('MM'),
      year: moment(this.day).format('YYYY')
    }
    this.initiated = true
  }
}
</script>

<style scoped lang="scss">

.doctor-hover-calendar {
  padding: 6px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,.15);
  background: #fff;
  border-radius: 12px;
  img {
    width: 64px;
  }
  .doctor-title {
    font-weight: 700;
    font-size: 14px;
  }
  .day-title {
    z-index: 2;
  }
  .availability-column {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    background: #C1ECA6;
  }
}
::v-deep .vc-highlights {
  display: none;
}
::v-deep .vc-day-content:hover {
  background-color: transparent;
}
</style>
