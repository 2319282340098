export const scheduleCrossing = (schedule) => {
	schedule.map(scheduleItem => {
		let crossedItems = schedule.filter(item => {
			return twoTimeIntervalsCross(scheduleItem, item)
		})
		if(crossedItems.length > 1) crossedItems = [...schedule]
		scheduleItem.rowCount = crossedItems.length
		const sortedArray = [...crossedItems].sort((a, b) => a.doctor_id - b.doctor_id)
		scheduleItem.rowIndex = sortedArray.findIndex(item => item.id === scheduleItem.id)
	})
	return schedule
}

export const twoTimeIntervalsCross = (obj1, obj2) => {
	// if(obj1.id === obj2.id) return false
	if(obj1.time_from >= obj2.time_to) return false
	if(obj1.time_to <= obj2.time_from) return false
	return true
}
