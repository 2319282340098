export default {
    primaryKey: "id",
    model: 'patient_condition',
    modaltitle: 'condition',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    showTotalRecords: true,
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
        },
    ]
}
