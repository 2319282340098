<template>
  <b-modal id="site-form-show-modal"
           :title="$t('site_form')"
           size="lg"
           centered>
    <div>
      <div>1. {{ $t('insert_script_one_time_instruction') }}:</div>
      <code>{{ scriptText }}</code>
      <div class="mt-4">2. {{ $t('insert_widget_button_instruction') }}:</div>
      <code v-if="text">{{ text }}</code>
      <div class="mt-4">
        <div class="text-muted font-italic">* {{ $t('you_also_can_use_link') }}:</div>
        <div>
          <a :href="siteHref" target="_blank">{{ siteHref }}</a>
        </div>
      </div>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons no-print">
        <button :disabled="loading"
                @click="close()"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('done') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"

export default {
  name: "SiteFormShowModal",
  components: {
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      siteForm: state => state.crm.siteForm,
      rights: state => state.auth.rights,
    }),
    siteHref() {
      if(!this.siteForm) return ''
      return `https://dentist-plus.com/form/${this.siteForm.hash}`
    },
    text() {
      if(!this.siteForm) return ''
      console.log(this.$i18n.locale)
      return `<a class="dentist-plus-widget-btn" data-form-id="${this.siteForm.hash}" data-lang="${this.$i18n.locale}">${this.$t('record_myself')}</a>`
    },
    scriptText() {
      let str = `<script src="${process.env.VUE_APP_API_DOMAIN}/js/widget.js" type="text/javascript"><`
      str += '/script>'
      return str
    }
  },
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">

</style>
