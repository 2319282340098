<template>
  <div id="app">
    <component v-if="loaded" :is="layout">
      <router-view/>
    </component>
    <div class="loader" v-if="loading" >
      <b-spinner label="Spinning"></b-spinner>
    </div>
    <AutoLogoutCountdown/>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/app";
</style>

<script>
import { mapState } from "vuex"
import AutoLogoutCountdown from "@/components/parts/general/AutoLogoutCountdown.vue"

export default {
  components: {
    AutoLogoutCountdown
  },
  computed: {
    layout() {
      if(!this.$route) return 'empty-layout'
      return (this.$route.meta.layout || "empty") + '-layout'
    },
    ...mapState({
      loading: state => state.dom.loading,
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      loaded: true,
    }
  },
  metaInfo: {
    titleTemplate: '%s | Dentist+'
  },
  mounted() {
    this.$store.commit('updateMobile')
  },
  created() {
    window.addEventListener("resize", this.resizeEventHandler)
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeEventHandler)
  },
  methods: {
    resizeEventHandler() {
      this.$store.commit('updateMobile')
    },
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -1rem;
  margin-top: -1rem;
  z-index: 5000;
}
</style>
