<template>
  <StarRating :rating="object.rating"
              :show-rating-number="false"/>
</template>

<script>
import StarRating from "@/components/parts/staff/StarRating.vue"

export default {
  name: "RatingTD",
  components: {
    StarRating
  },
  props: {
    object: {
      type: Object
    }
  },
}
</script>
