<template>
  <span>
    <a @click="toggleTechnic"
       :title="object.blocked == 0 ? $t('block') : $t('activate')"
       v-b-tooltip.hover
       class="data-table-action">
      <BlockSvg v-if="object.blocked == 0"/>
      <ActivateSvg class="svg-light" v-if="object.blocked == 1"/>
      <span class="action-button-mob-title">{{ object.blocked == 0 ? $t('block') : $t('activate') }}</span>
    </a>
  </span>
</template>

<script>

import BlockSvg from '@/assets/svg-vue/general/block.svg'
import ActivateSvg from '@/assets/svg-vue/form/tick.svg'
import { EloquentService } from "@/services/api.service"
import { doctorAdapter } from '@/adapters/staff/doctor'
import {mapState} from "vuex"

export default {
  name: "StaffDatatableTechnicActions",
  components: {
    BlockSvg,
    ActivateSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
  },
  methods: {
    toggleTechnic() {
      let formData = new FormData()
      let data = doctorAdapter(this.object)
      delete data.full
      data.blocked = this.object.blocked == 1 ? 0 : 1
      formData = this.appendFormdata(formData, data)
      EloquentService.update('technic', this.object.id, formData).then(() => {
        this.$store.commit('incrementDataTableCounter')
      })
    },
  }
}
</script>
