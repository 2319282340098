<template>
  <div v-if="hasRight" class="mb-4">
    <b-button v-spaceduck-collapse="`binotel-c`" class="btn-collapse">Binotel</b-button>
    <spaceduck-collapse id="binotel-c" @show="onShow">
      <div class="mt-3" v-html="$t('binotel_integration_instructions', { url: `https://dentist-plus.com/binotel/call/${clinic.id}` })"></div>
      <div class="mt-2">
        <div v-for="(r, rind) in responsible"
             :key="`resp-${rind}`"
             :class="{'mt-3': rind > 0}">
          <b-row class="align-items-center">
            <b-col>
              <ServiceMultiSelect :label="$t('staff')"
                                  :dblclick="false"
                                  model="staff"
                                  :multiple="false"
                                  title="full_name"
                                  :dbsearch="true"
                                  v-model="responsible[rind].user"/>
            </b-col>
            <b-col>
              <InputGroup v-model="responsible[rind].tel"
                          :label="$t('binotel_internal')"/>
            </b-col>
            <b-col cols="auto">
              <a class="mt-3 d-inline-block pointer" @click="removeResponsible(rind)">
                <RemoveSvg/>
              </a>
            </b-col>
          </b-row>
        </div>

        <a class="mt-3 btn btn-themed-outline btn-themed-squared px-3 d-inline-block pointer"
           @click="addResponsible">
          <AddSvg class="mr-2"/>{{ $t('add_user') }}
        </a>

        <InputGroup v-model="binotel_key"
                    class="mt-3"
                    :label="$t('binotel_key')"/>
        <InputGroup v-model="binotel_secret"
                    class="mt-3"
                    :label="$t('binotel_secret')"/>
        <InputGroup v-model="binotel_internal"
                    class="mt-3"
                    :label="$t('binotel_internal')"/>
        <button class="btn-themed btn-themed-squared d-inline-block mt-4"
                :disabled="loading"
                @click="saveUsers">
          {{ $t('save') }}
        </button>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>
import {mapState} from "vuex"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { ClinicService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup"
import AddSvg from '@/assets/svg-vue/general/add.svg'
import RemoveSvg from '@/assets/svg-vue/general/trash.svg'
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "BinotelIntegration",
  components: {
    SpaceduckCollapse,
    InputGroup,
    ServiceMultiSelect,
    AddSvg,
    RemoveSvg
  },
  data() {
    return {
      responsible: [],
      binotel_key: '',
      binotel_secret: '',
      binotel_internal: ''
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        binotel: [],
        binotel_key: '',
        binotel_secret: '',
        binotel_internal: '',
      }
    },
    binotelUsers() {
      return this.ppoSettings.binotel ?? []
    },
    binotelUsersIds() {
      return this.binotelUsers.map(u => u?.id ?? u)
    },
    hasRight() {
      if(this.superadmin) return this.clinic.rights.includes('crm')
      return this.rights.includes('crm')
    }
  },
  watch: {

  },
  async mounted() {
    this.binotel_key = this.ppoSettings.binotel_key
    this.binotel_secret = this.ppoSettings.binotel_secret
    this.binotel_internal = this.ppoSettings.binotel_internal
  },
  methods: {
    async onShow() {
      await this.getInitialUsers()
    },
    async saveUsers() {
      await ClinicService.savePPOAttribute([
        'binotel',
        'binotel_key',
        'binotel_secret',
        'binotel_internal',
      ], [
        this.responsible.map(x => {
          return {
            id: x.user?.id ?? x.id,
            tel: x.tel
          }
        }),
        this.binotel_key,
        this.binotel_secret,
        this.binotel_internal,
      ], this.superadmin, this.clinic.id)
      this.$noty.info(this.$t('success_message'))
      await this.$store.dispatch('init')
    },
    async getInitialUsers() {
      if(this.binotelUsersIds.length) {
        let res = await ClinicService.getUsersList(this.binotelUsersIds, this.superadmin)
        this.responsible = []
        this.binotelUsers.forEach(obj => {
          const id = obj?.id ?? obj
          const user = res.data.find(user => user.id == id)
          if(user) {
            this.responsible.push({
              user,
              tel: obj?.tel ?? ''
            })
          }
        })
      }
    },
    addResponsible() {
      this.responsible.push({
        user: null,
        tel: ''
      })
    },
    removeResponsible(index) {
      this.responsible.splice(index, 1)
    }
  }
}
</script>
