<template>
  <span>
  <a class="data-table-action"
     :title="$t('view')"
     v-b-tooltip.hover
     @click="show">
    <EyeSvg class="svg-light"/>
    <span class="action-button-mob-title">{{ $t('view') }}</span>
  </a>
    <a class="data-table-action"
       :title="$t('call')"
       v-if="phone && !rights.includes('hide_patient_phone')"
       v-b-tooltip.hover
       :href="`tel:${phone}`">
    <PhoneSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('call') }}</span>
  </a>
    <a :title="$t('sms_sending')"
       v-if="rights.includes('sms_send') && phone && !rights.includes('hide_patient_phone')"
       v-b-tooltip.hover
       class="data-table-action"
       @click="sendSms">
    <SmsSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('sms_sending') }}</span>
  </a>
    <WhatsappTelegramIcon v-if="patient && !rights.includes('hide_patient_phone')"
                          view="crm"
                          :patient-id="patient.id"
                          :phone="patient.phone"/>
    <a @click="showHistory"
       v-b-tooltip.hover
       class="data-table-action"
       :title="$t('comments')">
      <NoteSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('comments') }}</span>
    </a>
    <!--    <a @click="tickTask"-->
    <!--       v-b-tooltip.hover-->
    <!--       class="data-table-action"-->
    <!--       :title="object.finished ? $t('mark_as_in_progress') : $t('mark_as_completed')">-->
    <!--      <BlockSvg v-if="object.finished"/>-->
    <!--      <TickSvg v-if="!object.finished" class="svg-light"/>-->
    <!--      <span class="action-button-mob-title">{{ object.finished ? $t('mark_as_in_progress') : $t('mark_as_completed') }}</span>-->
    <!--    </a>-->
    <b-dropdown variant="link" right>
      <template #button-content>
        <a v-b-tooltip.hover
           :title="$t('change_status')">
          <ApproveSvg class="svg-light"/>
          <span class="action-button-mob-title">{{ $t('approve') }}</span>
        </a>
      </template>
      <b-dropdown-item @click="changeStatus('cancelled')">{{ $t('cancelled') }}</b-dropdown-item>
      <b-dropdown-item @click="changeStatus('for_rework')">{{ $t('to_rework') }}</b-dropdown-item>
      <b-dropdown-item @click="changeStatus('finished')">{{ $t('done') }}</b-dropdown-item>
    </b-dropdown>

    </span>
</template>

<script>

import EyeSvg from '@/assets/svg-vue/form/eye.svg'
import SmsSvg from '@/assets/svg-vue/general/sms_t.svg'
import PhoneSvg from '@/assets/svg-vue/header/phone_t.svg'
import NoteSvg from '@/assets/svg-vue/header/note_t.svg'
// import TickSvg from '@/assets/svg-vue/form/tick.svg'
// import BlockSvg from '@/assets/svg-vue/general/block.svg'
import ApproveSvg from '@/assets/svg-vue/general/approve_tr.svg'
import { mapState } from "vuex"
import { CalendarService } from "@/services/api.service"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"

export default {
  name: "CRMTaskDatatableActions",
  components: {
    WhatsappTelegramIcon,
    EyeSvg,
    PhoneSvg,
    SmsSvg,
    // TickSvg,
    // BlockSvg,
    NoteSvg,
    ApproveSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    patient() {
      return this.object?.patient || null
    },
    phone() {
      if(this.object.phone) return this.object.phone
      if(this.patient) return this.patient.mphone
      return null
    }
  },
  methods: {
    show() {
      this.$store.commit('setTask', this.copyObject(this.object))
      this.$bvModal.show('task-show-modal')
    },
    sendSms() {
      this.object.patient.mphone = this.phone
      this.$store.commit('setPatient', this.object.patient)
      this.$nextTick(() => {
        this.$bvModal.show('patient-sms-modal')
      })
    },
    showHistory() {
      let obj = this.copyObject(this.object)
      obj.onlyComments = true
      this.$store.commit('setTask', obj)
      this.$bvModal.show('task-show-modal')
    },
    async changeStatus(status) {
      let res = await CalendarService.finishTask(this.object.id, status)
      if(res.data) this.$store.commit('incrementDataTableCounter')
    },
    async tickTask() {
      let res = await CalendarService.finishTask(this.object.id)
      if(res.data) this.$store.commit('incrementDataTableCounter')
    }
  }
}
</script>

<style scoped>
.data-table-action svg {
  width: 16px;
  height: 16px;
}
</style>
