<template>
  <div>
    <div class="sub-menu">
      <router-link v-for="(m, mind) in menu"
                   :to="{ name: m.to }"
                   :key="`settings-link-${mind}`">
        {{ $t(m.title) }}
      </router-link>
    </div>
    <router-view/>
  </div>
</template>

<script>

import {mapActions, mapState} from "vuex"

export default {
  name: "AccountingMonthGeneral",
  head() {
    return {
      title: this.$t('accounting'),
    }
  },
  components: {
  },
  computed: {
    ...mapState({
      counter: state => state.datatable.dataTableCounter
    }),
  },
  data() {
    return {
      menu: [
        { to: 'AccountingMonthSummary', title: 'month' },
        { to: 'AccountingMonthSalaries', title: 'salaries' },
        { to: 'AccountingMonthMaterials', title: 'materials' },
        { to: 'AccountingMonthOtherIncome', title: 'other_income' },
        { to: 'AccountingMonthOtherExpense', title: 'other_expense' },
        { to: 'AccountingMonthOtherIncomeCategory', title: 'categories' },
      ]
    }
  },
  methods: {
      ...mapActions(['getAccountingMonth'])
  },
  mounted() {
    this.getAccountingMonth(this.$route.params.id)
  },
  beforeUnmount() {
    this.$store.commit('setAccountingMonth', null)
  },
  watch: {
    counter() {
      this.getAccountingMonth(this.$route.params.id)
    }
  }
}
</script>

<style scoped>

</style>
