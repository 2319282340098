<template>
  <section id="polises" class="polises">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('polises') }}</h1>
          </div>
<!--          <div class="col-auto table-view-navigator order-md-2 mt-md-0 mt-2 order-4">-->
<!--            <DataTableFilterButton/>-->
<!--          </div>-->
<!--          <div class="col-auto table-view-navigator order-md-4 order-1">-->
<!--            <DataTableExcel/>-->
<!--          </div>-->
        </div>
      </div>
    </transition>
    <DataTable :options="options"
               :params="{ patient_id: patient.id }"
               module="patients"/>
  </section>
</template>

<script setup>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/patients/insurance_polis"
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, onMounted, ref, computed } from "vue"
import { useStore } from "vuex"
// import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton.vue"
// import DataTableExcel from "@/components/parts/datatable/DataTableExcel.vue"

const store = useStore()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('polises')
})

const patient = computed(() => store.state.patient.patient)

onMounted(() => {
  store.commit('setEloquentOptions', options)
})
</script>
