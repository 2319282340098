<template>
  <b-modal id="lead-change-status-modal"
           @show="onShow"
           hide-footer
           :title="$t('change_status')"
           size="sm"
           centered>
    <a v-for="stage in CRMStages"
       class="d-flex align-items-center pointer py-2 text-black border-bottom"
       :key="`stage-${stage.id}`"
       @click="saveStatus(stage.id)">
      <span class="circle mr-2" :style="`background-color: ${stage.color}`"></span>
      {{ stage.color }} {{ stage.translatedTitle }}
    </a>
  </b-modal>
</template>

<script setup>
import { useStore } from "vuex"
import { computed, getCurrentInstance, onMounted } from "vue"
import { CRMService } from "@/services/api.service"

const props = defineProps({
  selected: Array,
})

const store = useStore()

const emit = defineEmits(['changed'])

const CRMStages = computed(() => store.state.auth.CRMStages)

let bvModal = null

onMounted(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
})

const onShow = () => {
  if(!CRMStages.value.length) store.dispatch('getCRMStages')
}

const saveStatus = async (stage) => {
  const res = await CRMService.bulkLeadStatusChange({ selected: props.selected, status_id: stage })
  if(res.data) {
    bvModal.hide('lead-change-status-modal')
    emit('changed')
  }
}
</script>
