import { createI18n } from 'vue-i18n'
let lang = "ru"
import messages from '@/lang/lang'

const i18n = createI18n({
    locale: lang,
    messages,
})

export default i18n
