export default {
    accepted_by: "Kim tərəfindən qəbul edilib",
    additional_income: "Əlavə gəlir",
    additional_costs: "Xərclər",
    admin_statistics_future: "Gələcək tarixlər üçün yeni girişlər haqqında administrator statistikası",
    administrator_percent: "Administrator faizi",
    administrator_sum: "Administrator məbləği",
    apply: "Tətbiq et",
    assistant_percent: "Assistent faizi",
    assistant_sum: "Assistent məbləği",
    average_cheque: "Orta çek",
    average_conversion_per_doctor_per_day: "Həkimlər üzrə gündəlik orta göstərici",
    average_conversion_per_doctor_per_period: "Həkimlər üzrə dövr üçün orta göstərici",
    balance_amount: "Depozitdən ödənilib",
    balance_return: "Depozitin geri qaytarılması",
    by: "Kim tərəfindən",
    by_means: "Ödəniş forması üzrə",
    by_services: "Xidmətlər üzrə",
    came_again: "Yenidən gəldilər",
    came_among_patients: "Onlardan gələnlər",
    cancel_date: "Ləğv tarixi",
    cancel_reason: "Ləğv səbəbi",
    cancelled_visits: "Ləğv edilmiş vizitlər",
    cashdesk_report: "Kassa hesabatı",
    cash_flow_statement: "Pul vəsaitlərinin hərəkəti hesabatı",
    conversion: "Konversiya",
    cos: "Maya dəyəri",
    costs: "Xərclər",
    cost_of_materials: "Materialların dəyəri",
    crm_leads_analytics: "CRM müraciətlərin analitikası",
    curator_id: "Kurator ID",
    curator_name: "Kuratorun adı",
    curator_percent: "Kurator faizi",
    curator_sum: "Kurator məbləği",
    daily_report: "Günlük hesabat",
    debt_payments: "Borc ödənişləri",
    debt_writeoff: "Borcun silinməsi",
    debtor: "Borc sahibi",
    debtors: "Borclular",
    debtors_statement: "Borclular üzrə hesabat",
    details_by_visits: "Vizitlər üzrə detallar",
    did_not_came_again: "Yenidən gəlmədilər",
    did_not_come_among_patients: "Onlardan gəlməyənlər",
    doctor_percent: "Həkim faizi",
    doctor_load_percent: "Həkim yüklənmə faizi",
    doctor_name: "Həkim",
    doctor_salary: "Həkim maaşı",
    doctor_statistics: "Həkimlər üzrə statistika",
    doctor_sum: "Həkim məbləği",
    doctors_conversion: "Həkimlər üzrə konversiya",
    doctors_load: "Həkimlərin yüklənməsi",
    duration: "Müddət",
    f057: "Forma 057",
    first_visit_date: "İlk vizitin tarixi",
    first_visit_doctor: "İlk vizitin həkimi",
    full_name: "Ad, Soyad",
    general_percent: "Ümumi faiz",
    hide_cancelled_visits: "Ləğv edilmiş vizitləri gizlət",
    hidden_columns: "Gizli sütunlar",
    income_by_doctors: "Həkimlər üzrə gəlir",
    income_by_services: "Xidmətlər üzrə gəlir",
    initiator: "Təşəbbüskar",
    invoiced: "Çıxarılan hesablar",
    last_visit_date: "Son vizitin tarixi",
    last_visit_doctor: "Son vizitin həkimi",
    last_visit_recommendation: "Son vizitin tövsiyələri",
    margins: "Ümumi mənfəət",
    materials_movement_analytics: "Materialların hərəkət analitikası",
    next_visit_date: "Növbəti vizitin tarixi",
    next_visit_doctor: "Növbəti vizitin həkimi",
    operation_type: "Əməliyyat növü",
    orthodontist: "Ortodont",
    orthopedist: "Ortoped",
    paid_from_account: "Hesabdan ödənilib",
    paid_invoices: "Ödənilmiş hesablar",
    patient_id: "Pasiyent ID",
    patient_name: "Pasiyent",
    patient_status: "Pasiyent statusu",
    permanent_among_patients: "Onlardan daimi pasiyentlər",
    pl_statement: "Gəlir və xərclər hesabatı",
    plan_treatment_complexes: "Komplekslər",
    plan_treatments: "Müalicə planı",
    previous_visit_date: "Əvvəlki vizitin tarixi",
    price_before_discount: "Endirimdən əvvəl məbləğ",
    primary_among_patients: "Onlardan ilkin pasiyentlər",
    primary_records: "İlkin yazılar",
    primary_records_came: "İlkin yazılar gəldi",
    primary_records_further: "İlkin yazılar daha çox yazıldı",
    primary_records_revenue: "İlkin yazılar üzrə gəlir",
    records: "Yazılar",
    records_count: "Vizitlərin sayı",
    repeated_records: "Təkrar yazılar",
    repeated_records_came: "Təkrar yazılar gəldi",
    repeated_records_further: "Təkrar yazılar daha çox yazıldı",
    repeated_records_revenue: "Təkrar yazılar üzrə gəlir",
    report_is_too_heavy_confirm: "Hesabat səhifədə baxmaq üçün çox böyükdür, hesabatı Excel faylına yükləmək?",
    report_days_limit: "Hesabat dövrü limiti (günlər)",
    revenue: "Gəlir",
    revenue_per_hour: "Saat üzrə gəlir",
    revenues: "Gəlirlər",
    saldo: "Saldo",
    sales_funnel_applications: "Satış huni: Müraciətlər",
    sales_funnel_plans: "Satış huni: Müalicə planları",
    services_amount: "Xidmətlər məbləği",
    show_deleted_doctors: "Silinmiş həkimləri göstər",
    show_only_new_patients: "Yalnız yeni pasiyentləri göstər",
    source_type: "Mənbə növü",
    technic_percent: "Texnik faizi",
    technic_sum: "Texnik məbləği",
    therapist: "Terapevt",
    total_patients: "Yazılmış pasiyentlərin sayı",
    total_primary: "Cəmi ilkin yazılar",
    total_revenue: "Ümumi gəlir",
    unpaid_invoices: "Ödənilməmiş hesablar",
    updated_date: "Dəyişdirilmə tarixi",
    visit_id: "Vizit ID",
    visit_statistics: "Vizitlərin statistikası",
    visits_by_source: "Mənbələr üzrə vizitlər",
    visits_detailed: "Vizitlər (detallı)",
    where_came_from: "Haradan gəlib",
    who_cancelled: "Kim ləğv etdi",
    who_created: "Kim tərəfindən yaradılıb",
    who_updated: "Tərəfindən dəyişdirildi",
    with_patient: "Pasiyent ilə",
    worked_days: "İşlənmiş günlər",
    worked_hours: "İşlənmiş saatlar",
}
