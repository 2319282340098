<template>
 <div>
   <label class="form-label" v-if="showlabel">
     <span>{{ label }}</span>
     <span v-if="required"> *</span>
   </label>
    <b-row>
      <b-col col>
        <DateGroup v-model="date"
                   :showlabel="true"
                   :label="$t('date')"
                   :required="required"/>
      </b-col>
      <b-col cols="auto">
        <TimeGroup v-model="time"
                   :showlabel="true"
                   :label="$t('time')"
                   :required="required"/>
      </b-col>
    </b-row>
 </div>
</template>

<script>

import TimeGroup from "@/components/form/TimeGroup"
import DateGroup from "@/components/form/DateGroup"


export default {
  name: 'DateTimeGroup',
  components: {
    TimeGroup,
    DateGroup
  },
  data() {
    return {
      date: this.value ? this.value.substr(0, 10) : null,
      time: this.value ? this.value.substr(11, 5) : null,
    }
  },
  props: {
    label: {},
    value: {},
    showErrors: {
      type: Boolean,
      default: false
    },
    showlabel: {
      type: Boolean,
      default: true
    },
    calendarIcon: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validationId: {},
    required: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    datetime() {
      return (this.date ? this.date : '0000-00-00')+' '+(this.time ? this.time : '00')+':00'
    },
  },
  mounted() {
  },
  methods: {

  },
  watch: {
    date() {
      this.$emit('input', this.datetime)
    },
    time() {
      this.$emit('input', this.datetime)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
