import { billingPaymentAdapter } from "@/adapters/superadmin/billingPayment"

export default {
    primaryKey: "id",
    model: 'billing_payment',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "delete"
    ],
    rights: {
        add: "superadmin_payment_delete",
        delete: "superadmin_payment_delete",
    },
    showTotalRecords: true,
    actionscomponent: 'SuperAdminPaymentActions',
    deleteCondition(object) {
        return object.paid == 0 || object.pay_system == 'manual'
    },
    adapter: billingPaymentAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_payment',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'clinic_id',
            title: 'clinic',
            table_show: ['clinic_id', 'clinic.name'],
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "autocomplete",
                model: "clinic",
                required: true,
            },
        },
        {
            id: 'manager_id',
            title: 'manager2',
            table_show: ['clinic.manager_id', 'clinic.manager.fname'],
            table: true,
            filter: true,
            filter_model_select: true,
            sortable: false
        },
        {
            id: 'tariff_id',
            title: 'tariff',
            table_show: ['tariff.title'],
            table: true,
            filter: true,
            filter_model_select: true,
            sortable: true
        },
        {
            id: 'sum',
            title: 'sum',
            table: true,
            sortable: true,
            filter: true,
            filter_number: true,
            form: {
                type: "number",
                required: true,
            },
        },
        {
            id: 'currency',
            title: 'currency',
            table: true,
            sortable: true,
            format_translate: false,
            filter: true,
            filter_list_select: true,
            form: {
                component: "BillingPaymentFormCard"
            }
        },
        {
            id: 'pay_system',
            title: 'pay_system',
            table: true,
            sortable: true,
            filter: true,
            format_translate: true,
            filter_list_select: true,
            form: {
                options: {
                    "manual": { id: "manual", title: "manual" },
                    "ioka": { id: "ioka", title: "ioka" },
                }
            }
        },
        {
            id: 'paid',
            title: 'paid',
            table: true,
            sortable: true,
            filter: true,
            filter_list_select: true,
            tdcomponent: "SuperAdminDatatableTDPaymentStatus",
            form: {
                type: 'select',
                options: {
                    "0": { id: "0", title: "unpaid" },
                    "1": { id: "1", title: "paid" },
                }
            }
        },
        {
            id: 'comment',
            title: 'payment_comment',
            table: true,
            filter: true,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'author',
            title: 'author',
            table: true,
            table_show: 'author.full_name',
        },
        {
            id: 'created_at',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true
        }
    ]
}
