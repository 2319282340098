<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M0.625 3.3125H7.90625C8.25144 3.3125 8.53125 3.03269 8.53125 2.6875C8.53125 2.34231 8.25144 2.0625 7.90625 2.0625H0.625C0.279813 2.0625 0 2.34231 0 2.6875C0 3.03269 0.279813 3.3125 0.625 3.3125Z"/>
  <path
      d="M11.6562 5.34375C12.6901 5.34375 13.5312 4.50262 13.5312 3.46875V3.3125H15.375C15.7202 3.3125 16 3.03269 16 2.6875C16 2.34231 15.7202 2.0625 15.375 2.0625H13.5312V1.875C13.5312 0.841125 12.6901 0 11.6562 0C10.6224 0 9.78125 0.841125 9.78125 1.875V3.46875C9.78125 4.50262 10.6224 5.34375 11.6562 5.34375ZM11.0312 1.875C11.0312 1.53038 11.3116 1.25 11.6562 1.25C12.0009 1.25 12.2812 1.53038 12.2812 1.875V3.46875C12.2812 3.81337 12.0009 4.09375 11.6562 4.09375C11.3116 4.09375 11.0312 3.81337 11.0312 3.46875V1.875Z"/>
  <path
      d="M7.90481 12.7188H0.625C0.279813 12.7188 0 12.9986 0 13.3438C0 13.6889 0.279813 13.9688 0.625 13.9688H7.90481C8.25 13.9688 8.52981 13.6889 8.52981 13.3438C8.52981 12.9986 8.25 12.7188 7.90481 12.7188Z"/>
  <path
      d="M0.625 8.62108H2.40625C2.75144 8.62108 3.03125 8.34127 3.03125 7.99609C3.03125 7.6509 2.75144 7.37109 2.40625 7.37109H0.625C0.279813 7.37109 0 7.6509 0 7.99609C0 8.34127 0.279813 8.62108 0.625 8.62108Z"/>
  <path
      d="M15.375 7.37119H8.03125V7.15625C8.03125 6.12238 7.19012 5.28125 6.15625 5.28125C5.12238 5.28125 4.28125 6.12238 4.28125 7.15625V8.78125C4.28125 9.81512 5.12238 10.6562 6.15625 10.6562C7.19012 10.6562 8.03125 9.81512 8.03125 8.78125V8.62119H15.375C15.7202 8.62119 16 8.34138 16 7.99619C16 7.65103 15.7202 7.37119 15.375 7.37119ZM6.78125 8.78125C6.78125 9.12587 6.50087 9.40625 6.15625 9.40625C5.81163 9.40625 5.53125 9.12587 5.53125 8.78125V7.15625C5.53125 6.81163 5.81163 6.53125 6.15625 6.53125C6.50087 6.53125 6.78125 6.81163 6.78125 7.15625V8.78125Z"/>
  <path
      d="M15.3749 12.7188H13.5298V12.5312C13.5298 11.4974 12.6886 10.6562 11.6548 10.6562C10.6209 10.6562 9.77979 11.4974 9.77979 12.5312V14.125C9.77979 15.1589 10.6209 16 11.6548 16C12.6886 16 13.5298 15.1589 13.5298 14.125V13.9688H15.3749C15.7201 13.9688 15.9999 13.6889 15.9999 13.3438C15.9999 12.9986 15.7201 12.7188 15.3749 12.7188ZM12.2798 14.125C12.2798 14.4696 11.9994 14.75 11.6548 14.75C11.3102 14.75 11.0298 14.4696 11.0298 14.125V12.5312C11.0298 12.1866 11.3102 11.9062 11.6548 11.9062C11.9994 11.9062 12.2798 12.1866 12.2798 12.5312V14.125Z"/>
</svg>
</template>
