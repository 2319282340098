<template>
  <span class="">
    <a :title="$t('print')"
       @click="print"
       v-b-tooltip.hover
        class="data-table-action">
      <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
    </a>
  </span>
</template>

<script>

import PrintSvg from '@/assets/svg-vue/general/print2.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"

export default {
  name: "CashdeskDatatableTDView",
  components: {
    PrintSvg
  },
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
  },
  methods: {
    print() {
      let id = this.object.id
      if(!id && this.object.ExternalCheckNumber) id = this.object.ExternalCheckNumber
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/cashdesk/cheque/'+id, '_blank')
    }
  }
}
</script>
