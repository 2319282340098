export default {
    accounting: "Buxgalteriya",
    action_log: "Harakatlar jurnali",
    calendar: "Kalendar",
    control: "Boshqaruv",
    doctors: "Shifokorlar",
    insurance: "Sug‘urta",
    patients: "Shifokorlar",
    reports: "Hisobotlar",
    services: "Xizmatlar",
    settings: "Sozlamalar",
    users: "Foydalanuvchilar",
    warehouse: "Ombor",
}
