<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_5429_64553)">
    <path
        d="M12.25 5.25H10.7625C10.5875 5.25 10.4125 5.1625 10.2375 5.075L9.1 3.9375C8.925 3.675 8.575 3.5 8.1375 3.5H7.875C8.8375 3.5 9.625 2.7125 9.625 1.75C9.625 0.7875 8.8375 0 7.875 0C6.9125 0 6.125 0.7875 6.125 1.75C6.125 2.3625 6.475 2.975 7 3.2375L6.825 3.5H5.075C4.1125 3.5 3.0625 3.9375 2.45 4.8125L1.925 5.5125C1.575 5.8625 1.75 6.3875 2.1 6.65C2.45 6.825 2.8875 6.7375 3.15 6.3875L3.5875 5.775C3.85 5.425 4.2 5.25 4.6375 5.25H5.3375L4.725 6.65C4.4625 7.175 4.375 7.7 4.375 8.3125V10.0625C4.375 10.325 4.2 10.5 3.9375 10.5H1.75C1.225 10.5 0.875 10.85 0.875 11.375C0.875 11.9 1.225 12.25 1.75 12.25H4.8125C5.5125 12.25 6.125 11.6375 6.125 10.9375V8.75L9.45 12.6875C9.975 13.475 10.9375 14 11.9 14H12.6875L7.9625 8.1375C7.7 7.7875 7.7875 7.4375 7.9625 7L8.4875 5.6875L9.1 6.3875C9.45 6.7375 9.975 7 10.5 7H12.25C12.775 7 13.125 6.65 13.125 6.125C13.125 5.6 12.775 5.25 12.25 5.25Z"
        fill="#495A6B"/>
  </g>
  <defs>
    <clipPath id="clip0_5429_64553">
      <rect width="14" height="14" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>
