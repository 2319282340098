<template>
  <div v-if="daysLeft !== null && daysLeft >= 0 && daysLeft <= 3 && show"
       class="text-center bg-danger text-white small tariff-header-panel">
    {{ $tc('tariff_payment_expires_in_days', daysLeft, { day: daysLeft }) }}.
    <router-link class="text-white text-underlined ml-2"
                 v-if="rights.includes('billing')"
                 to="/billing">{{ $t('prolong') }}
    </router-link>
    <a @click="show = false" class="close-svg pointer"><CloseSvg/></a>
  </div>
</template>

<script>

import {mapState} from "vuex"
import moment from "moment-timezone"
import CloseSvg from '@/assets/svg-vue/general/close.svg'

export default {
  name: "TariffEndsHeader",
  components: {
    CloseSvg
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
    }),
    paidTill() {
      if(!this.clinic || !this.clinic.clinic_tariff) return null
      return this.clinic.clinic_tariff.paid_till
    },
    daysLeft() {
      if(!this.paidTill) return null
      let now = moment()
      return Math.ceil(moment(this.paidTill).diff(now, 'days', true))
    }
  },
  data() {
    return {
      show: true
    }
  }
}
</script>

<style scoped>
.tariff-header-panel {
  position: relative;
  padding: 2px 15px;
  z-index: 20;
}
.close-svg svg {
  fill: #fff;
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 6px;
}
@media(max-width: 768px) {
  .close-svg svg {
    width: 8px;
    height: 8px;
    top: 5px;
  }
}
</style>
