<template>
  <div class="opened-visit-header-panel text-center text-white small" v-if="showHeader">
    <div class="d-inline-block">
      {{ $t('visit_in_progress') }}: {{ myOpenedVisit.record.patient.full_name }} {{ myOpenedVisit.record.readableStart }}
    </div>
    <div class="d-inline-block ml-3" v-if="totalSecondsPassed > 0">
      {{ paddedDigit(hours) }}:{{ paddedDigit(minutes) }}:{{ paddedDigit(seconds) }}
    </div>
    <div class="d-inline-block ml-3">
      <router-link class="text-white" :to="{ name: 'PatientVisitPage', params: { id: myOpenedVisit.record.patient.id, visitid: myOpenedVisit.record_id, page: 'teeth' } }">
        {{ $t('move_to_visit') }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex"
import moment from "moment-timezone"

export default {
  name: "OpenedVisitHeader",
  data() {
    return {
      totalSecondsPassed: 0,
      interval: null
    }
  },
  computed: {
    ...mapState({
      myOpenedVisit: state => state.treatment.myOpenedVisit,
      user: state => state.auth.user,
    }),
    hours() {
      return Math.floor(this.totalSecondsPassed / 3600)
    },
    minutes() {
      return Math.floor((this.totalSecondsPassed % 3600) / 60)
    },
    seconds() {
      return this.totalSecondsPassed % 60
    },
    showHeader() {
      return this.myOpenedVisit
          && this.myOpenedVisit.created_by == this.user.id
          && this.myOpenedVisit.record
          && this.myOpenedVisit.record.patient
    }
  },
  methods: {
    paddedDigit(digit) {
      return digit < 10 ? `0${digit}` : digit;
    },
    launchTimer() {
      if(this.interval) clearInterval(this.interval)
      if(!this.myOpenedVisit) return
      this.interval = setInterval(() => {
        this.totalSecondsPassed = moment().diff(this.myOpenedVisit.created_at, 'seconds')
      }, 1000)
    }
  },
  mounted() {
    this.launchTimer()
  },
  watch: {
    myOpenedVisit(val) {
      if(val) this.launchTimer()
      if(!val && this.interval) clearInterval(this.interval)
    }
  }
}
</script>

<style scoped>
.opened-visit-header-panel {
  position: relative;
  padding: 2px 15px;
  z-index: 20;
  background: #5E9246;
}
</style>
