export const kitAdapter =
    ({
         package_id,
         name,
         materials
     }) => ({
        package_id: package_id ?? null,
        name: name ?? null,
        materials: minimizeMaterials(materials)
    })

function minimizeMaterials(materials) {
    let data = {}
    if(!materials) return data
    materials.forEach(item => {
        if(item.material_id) {
            data[item.material_id] = {
                amount: item.pivot.amount,
            }
        }

    })
    return data
}
