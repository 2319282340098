<template>
  <PatientsDatatableFormMPhone :object="object"
                               :field="field"
                               view="mphone2"/>
</template>

<script>

import PatientsDatatableFormMPhone from "@/components/parts/datatable/form/patients/PatientsDatatableFormMPhone.vue"

export default {
  name: "PatientsDatatableFormMPhone2",
  components: {
    PatientsDatatableFormMPhone
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
}
</script>
