<template>
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
      d="M15.25 13.075V5.82498C15.25 5.44041 15.0972 5.0716 14.8253 4.79967C14.5534 4.52774 14.1846 4.37498 13.8 4.37498H8.8961C8.62677 4.37497 8.36276 4.29994 8.13367 4.15832C7.90458 4.01669 7.71947 3.81405 7.59907 3.57313L6.95093 2.27683C6.83048 2.03579 6.64525 1.83308 6.41602 1.69145C6.18679 1.54981 5.92263 1.47485 5.65317 1.47498H2.2C1.81544 1.47498 1.44662 1.62774 1.1747 1.89967C0.902767 2.1716 0.75 2.54041 0.75 2.92498V13.075C0.75 13.4595 0.902767 13.8284 1.1747 14.1003C1.44662 14.3722 1.81544 14.525 2.2 14.525H13.8C14.1846 14.525 14.5534 14.3722 14.8253 14.1003C15.0972 13.8284 15.25 13.4595 15.25 13.075Z"
      stroke="#B5C6D8" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
  <path
      d="M8.44595 6.56055C8.5277 6.56055 8.59459 6.62744 8.59459 6.7092V8.71595H10.6014C10.6831 8.71595 10.75 8.78284 10.75 8.8646V9.75649C10.75 9.83825 10.6831 9.90514 10.6014 9.90514H8.59459V11.9119C8.59459 11.9937 8.5277 12.0605 8.44595 12.0605H7.55405C7.4723 12.0605 7.40541 11.9937 7.40541 11.9119V9.90514H5.39865C5.31689 9.90514 5.25 9.83825 5.25 9.75649V8.8646C5.25 8.78284 5.31689 8.71595 5.39865 8.71595H7.40541V6.7092C7.40541 6.62744 7.4723 6.56055 7.55405 6.56055H8.44595Z"
      fill="#B5C6D8"/>
</svg>
</template>
