<template>
  <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        d="M4.35547 0.5H3.37109C1.78797 0.5 0.5 1.78797 0.5 3.37109V18.6289C0.5 20.212 1.78797 21.5 3.37109 21.5H4.35547C5.93859 21.5 7.22656 20.212 7.22656 18.6289V3.37109C7.22656 1.78797 5.93859 0.5 4.35547 0.5ZM5.58594 18.6289C5.58594 19.3074 5.03395 19.8594 4.35547 19.8594H3.37109C2.69261 19.8594 2.14062 19.3074 2.14062 18.6289V3.37109C2.14062 2.69261 2.69261 2.14062 3.37109 2.14062H4.35547C5.03395 2.14062 5.58594 2.69261 5.58594 3.37109V18.6289Z"/>
    <path
        d="M18.6289 0.5H11.8203C10.2372 0.5 8.94922 1.78797 8.94922 3.37109V4.35547C8.94922 5.93859 10.2372 7.22656 11.8203 7.22656H18.6289C20.212 7.22656 21.5 5.93859 21.5 4.35547V3.37109C21.5 1.78797 20.212 0.5 18.6289 0.5ZM19.8594 4.35547C19.8594 5.03395 19.3074 5.58594 18.6289 5.58594H11.8203C11.1418 5.58594 10.5898 5.03395 10.5898 4.35547V3.37109C10.5898 2.69261 11.1418 2.14062 11.8203 2.14062H18.6289C19.3074 2.14062 19.8594 2.69261 19.8594 3.37109V4.35547Z"/>
    <path
        d="M18.6289 8.99023H11.8203C10.2372 8.99023 8.94922 10.2782 8.94922 11.8613V18.6289C8.94922 20.212 10.2372 21.5 11.8203 21.5H18.6289C20.212 21.5 21.5 20.212 21.5 18.6289C21.5 18.1758 21.1327 17.8086 20.6797 17.8086C20.2266 17.8086 19.8594 18.1758 19.8594 18.6289C19.8594 19.3074 19.3074 19.8594 18.6289 19.8594H11.8203C11.1418 19.8594 10.5898 19.3074 10.5898 18.6289V11.8613C10.5898 11.1828 11.1418 10.6309 11.8203 10.6309H18.6289C19.3074 10.6309 19.8594 11.1828 19.8594 11.8613V14.5273C19.8594 14.9804 20.2266 15.3477 20.6797 15.3477C21.1327 15.3477 21.5 14.9804 21.5 14.5273V11.8613C21.5 10.2782 20.212 8.99023 18.6289 8.99023Z"/>
  </svg>
</template>
