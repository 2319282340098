import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import ReportsGeneral from "@/components/pages/reports/ReportsGeneral"
import ReportsPL from "@/components/pages/reports/ReportsPL"
import ReportsCashFlows from "@/components/pages/reports/ReportsCashFlows"
import ReportsDebtors from "@/components/pages/reports/ReportsDebtors"
import ReportsVisits from "@/components/pages/reports/ReportsVisits"
import ReportsVisitsDetailed from "@/components/pages/reports/ReportsVisitsDetailed"
import ReportsServices from "@/components/pages/reports/ReportsServices"
import ReportsCancelledVisits from "@/components/pages/reports/ReportsCancelledVisits"
import ReportsPatients from "@/components/pages/reports/ReportsPatients"
import ReportsDiagnoses from "@/components/pages/reports/ReportsDiagnoses"
import ReportsSalaries from "@/components/pages/reports/ReportsSalaries"
import ReportsIncomeByDoctors from "@/components/pages/reports/ReportsIncomeByDoctors"
import ReportsIncomeByServices from "@/components/pages/reports/ReportsIncomeByServices"
import ReportsMaterials from "@/components/pages/reports/ReportsMaterials"
import ReportsMaterialsMovement from "@/components/pages/reports/ReportsMaterialsMovement"
import Report57 from "@/components/pages/reports/Report57"
import ReportDay from "@/components/pages/reports/ReportDay"
import ReportStatVisits from "@/components/pages/reports/ReportStatVisits"
import ReportStatAdmins from "@/components/pages/reports/ReportStatAdmins"
import ReportStatDoctors from "@/components/pages/reports/ReportStatDoctors"
import ReportDoctorsLoad from "@/components/pages/reports/ReportDoctorsLoad"
import ReportCashdesk from "@/components/pages/reports/ReportCashdesk"
import ReportGuarantee from "@/components/pages/reports/ReportGuarantee"
import ReportCRMAnalytics from "@/components/pages/reports/ReportCRMAnalytics"
import ReportVisitSourceAnalytics from "@/components/pages/reports/ReportVisitSourceAnalytics"
import ReportDoctorsConversion from "@/components/pages/reports/ReportDoctorsConversion"
import ReportInsurance from "@/components/pages/reports/ReportInsurance"
import ReportBonuses from "@/components/pages/reports/ReportBonuses"

export default {
    path: '/reports', name: "ReportsGeneral",
    meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab'] },
    // component:() => import('../components/pages/reports/ReportsGeneral.vue'),
    component: ReportsGeneral,
    children: [
        // { path: '', redirect: 'cash_flows' },
        {
            path: 'day', name: "ReportDay",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_day'] },
            // component: () => import('../components/pages/reports/ReportsPL.vue')
            component: ReportDay
        },
        {
            path: 'pl', name: "ReportsPL",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_pnl'] },
            component: ReportsPL
        },
        {
            path: 'cash_flows', name: "ReportsCashFlows",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_cash'] },
            component: ReportsCashFlows
        },
        {
            path: 'cashdesk', name: "ReportCashdesk",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_cashdesk'] },
            component: ReportCashdesk
        },
        {
            path: 'debtors', name: "ReportsDebtors",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_debtors'] },
            component: ReportsDebtors
        },
        {
            path: 'visits', name: "ReportsVisits",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_visits'] },
            component: ReportsVisits
        },
        {
            path: 'visits_detailed', name: "ReportsVisitsDetailed",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_visits'] },
            component: ReportsVisitsDetailed
        },
        {
            path: 'cancelled_visits', name: "ReportsCancelledVisits",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_cancelled'] },
            component: ReportsCancelledVisits
        },
        {
            path: 'patients', name: "ReportsPatients",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_patients'] },
            component: ReportsPatients
        },
        {
            path: 'diagnoses', name: "ReportsDiagnoses",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_diagnoses'] },
            component: ReportsDiagnoses
        },
        {
            path: 'salaries', name: "ReportsSalaries",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_salaries'] },
            component: ReportsSalaries
        },
        {
            path: 'income_by_doctors', name: "ReportsIncomeByDoctors",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_doctors_revenues'] },
            component: ReportsIncomeByDoctors
        },
        {
            path: 'income_by_services', name: "ReportsIncomeByServices",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_services_revenues'] },
            component: ReportsIncomeByServices
        },
        {
            path: 'materials', name: "ReportsMaterials",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_materials'] },
            component: ReportsMaterials
        },
        {
            path: 'materials_movement_analytics', name: "ReportsMaterialsMovement",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_materials_movement_analytics'] },
            component: ReportsMaterialsMovement
        },
        {
            path: 'services', name: "ReportsServices",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_services'] },
            component: ReportsServices
        },
        {
            path: 'f57', name: "Report57",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_57'] },
            component: Report57
        },
        {
            path: 'stat_visits', name: "ReportStatVisits",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_stat_visits'] },
            component: ReportStatVisits
        },
        {
            path: 'stat_admins', name: "ReportStatAdmins",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_stat_admins'] },
            component: ReportStatAdmins
        },
        {
            path: 'stat_doctors', name: "ReportStatDoctors",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_stat_doctors'] },
            component: ReportStatDoctors
        },
        {
            path: 'doctors_load', name: "ReportDoctorsLoad",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_doctors_load'] },
            component: ReportDoctorsLoad
        },
        {
            path: 'guarantee', name: "ReportGuarantee",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_warranty_case'] },
            component: ReportGuarantee
        },
        {
            path: 'crm_analytics', name: "ReportCRMAnalytics",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_crm_analytics'] },
            component: ReportCRMAnalytics
        },
        {
            path: 'visits_by_source', name: "ReportVisitSourceAnalytics",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_visit_source'] },
            component: ReportVisitSourceAnalytics
        },
        {
            path: 'doctors_conversion', name: "ReportDoctorsConversion",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'reports_doctors_conversion'] },
            component: ReportDoctorsConversion
        },
        {
            path: 'insurance', name: "ReportInsurance",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'report_insurance'] },
            component: ReportInsurance
        },
        {
            path: 'bonuses', name: "ReportBonuses",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['reports_tab', 'report_bonuses'] },
            component: ReportBonuses
        },
    ]
}
