export const buildHierarchy = (list, level = 1, accumulatedList = [], parentId = null) => {
	const iterationList = list.filter(item => item.parent_id === parentId)
	iterationList.forEach(item => {
		item.title = '--'.repeat((level - 1)) + item.title
		item.level = level
		accumulatedList.push(item)
		accumulatedList = buildHierarchy(list, level + 1, accumulatedList, item.id)
	})
	let ids = []
	accumulatedList.slice().reverse().forEach(item => {
		ids.push(item.id)
		item.includeIds = JSON.parse(JSON.stringify(ids))
		if(!item.parent_id) ids = []
	})
	return accumulatedList
}
