const TOKEN_KEY = "branch_id"

export const getBranch = () => {
  let branch = window.localStorage.getItem(TOKEN_KEY)
  return branch ? parseInt(branch) : null
}

export const saveBranch = (branch_id) => {
  if(!branch_id) {
    window.localStorage.removeItem(TOKEN_KEY)
  } else {
    window.localStorage.setItem(TOKEN_KEY, branch_id)
  }
}


export default { getBranch, saveBranch }
