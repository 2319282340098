<template>
<svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0)">
    <path
        d="M6.12536 5.00285L10.412 0.716256C10.5774 0.556473 10.582 0.292836 10.4222 0.127392C10.2624 -0.038052 9.99878 -0.0426396 9.83334 0.117143C9.82987 0.120486 9.82646 0.123903 9.82309 0.127392L5.53647 4.41398L1.24987 0.127368C1.08443 -0.0324152 0.820788 -0.0278277 0.661005 0.137616C0.505126 0.29901 0.505126 0.554863 0.661005 0.716256L4.9476 5.00285L0.661005 9.28944C0.498415 9.45205 0.498415 9.71569 0.661005 9.8783C0.823618 10.0409 1.08726 10.0409 1.24987 9.8783L5.53647 5.59171L9.82306 9.8783C9.98851 10.0381 10.2521 10.0335 10.4119 9.86805C10.5678 9.70666 10.5678 9.4508 10.4119 9.28944L6.12536 5.00285Z"
        fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0">
      <rect width="10" height="10" fill="white" transform="translate(0.541992)"/>
    </clipPath>
  </defs>
</svg>
</template>
