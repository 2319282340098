<template>
  <b-row class="paginator">
    <b-col>
      <div v-if="paginator && paginator.last_page > 1">
        <button class="pag-item pag-link" :disabled="paginator.current_page < 2" @click="paginatorAction('previous')">«</button>
        <button class="pag-item pag-link" @click="paginatorAction('pagenum',1)" v-if="paginator.current_page > 1">1</button>
        <button class="pag-item" v-if="(paginator.current_page - 1) > 2">...</button>
        <button class="pag-item pag-link" @click="paginatorAction('pagenum',paginator.current_page - 1)" v-if="(paginator.current_page - 1) > 1">{{ paginator.current_page - 1 }}</button>
        <button class="pag-item active">{{ paginator.current_page }}</button>
        <button class="pag-item pag-link" @click="paginatorAction('pagenum',paginator.current_page + 1)" v-if="(paginator.current_page + 1) < paginator.last_page">{{ paginator.current_page + 1 }}</button>
        <button class="pag-item" v-if="(paginator.current_page + 2) < paginator.last_page">...</button>
        <button class="pag-item pag-link" @click="paginatorAction('pagenum',paginator.last_page)" v-if="paginator.last_page > paginator.current_page">{{ paginator.last_page }}</button>
        <button class="pag-item pag-link" :disabled="paginator.current_page >= paginator.last_page" @click="paginatorAction('next')">»</button>
      </div>
    </b-col>
    <b-col cols="auto" class="text-right" v-if="showPerPage && paginator.total > 10">
      <div class="small text-muted d-inline-block">{{ $t('on_page') }}</div>
      <div class="d-inline-block ml-md-3 ml-2">
        <SelectGroup :options="pageOptions"
                     @input="perPageChanged"
                     :translate="false"
                     title="title"
                     :key="`pag-counter-${pageCounter}`"
                     class="small-radius"
                     :value="perPage"/>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import SelectGroup from "@/components/form/SelectGroup"
export default {
  components: {SelectGroup},
  props: {
    paginator: {},
    perPage: {},
    showPerPage: {
      type: Boolean,
      default: false
    },
    showAllOption: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      page: this.$route.query.page ? this.$route.query.page : 1,
      pageOptions: [
        { id: 10, title: 10 },
        { id: 20, title: 20 },
        { id: 30, title: 30 },
        { id: 50, title: 50 },
      ],
      pageCounter: 0
    }
  },
  methods: {
    paginatorAction(action, pagenum) {
      if(action == 'next') {
        this.page++
      }
      if(action == 'previous') {
        this.page--
      }
      if(action == 'pagenum' && pagenum) {
        this.page = pagenum
      }
      let query = JSON.parse(JSON.stringify(this.$route.query))
      query.page = this.page
      this.$router.push({ path: this.$route.path, query: query }).catch(()=>{})
    },
    perPageChanged(val) {
      this.$emit('perPageChanged', val)
    }
  },
  mounted() {
    if(this.showAllOption) {
      this.pageOptions.push({ id: 99999, title: this.$t('all') })
      this.pageCounter++
    }
  }
}
</script>

<style scoped lang="scss">
.paginator {
  margin-top: 20px;
}
</style>
