<template>
  <a :href="url" target="_blank">
    <FileSvg class="d-inline-block align-middle mr-2 file-svg"/>
    <span class="d-inline-block align-middle">{{ object.original }}</span>
  </a>
</template>

<script>

import FileSvg from '@/assets/svg-vue/patients/file.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"

export default {
  name: "PatientsDatatableTDDocumentFile",
  components: {
    FileSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
    url() {
      let link = JwtService.getPrintPrelink(this.clinic)
      return link+'/client/document/'+this.object.id
    }
  }
}
</script>

<style scoped lang="scss">
  .file-svg {
    fill: $blue-color;
  }
</style>
