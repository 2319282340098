<template>
  <div class="mt-4 patient-notifications">
    <div v-if="patient" class="mb-4">
      <router-link :to="`/superadmin/clinic/${clinic.id}/patients`">{{ $t('patients') }}</router-link> / {{ patient.full_name }}
    </div>
    <DataTable :options="options"
               :params="{ recipient_id: patientId }"
               module="superadmin"
    />
  </div>
</template>

<script>

import { mapState } from "vuex"
import DataTable from "@/components/parts/general/DataTable.vue"
import options from "@/data/superadmin/notification"
import { EloquentService } from "@/services/api.service"

export default {
  name: "SuperAdminPatientNotifications",
  components: {
    DataTable
  },
  head() {
    return {
      title: this.$t("notifications"),
    }
  },
  data() {
    return {
      options: options,
      patient: null
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
      clinic: state => state.auth.clinic
    }),
    patientId() {
      return this.$route.params.patientId
    }
  },
  async mounted() {
    this.patient = (await EloquentService.show('patient', this.patientId, true))?.data || null
  }
}
</script>
