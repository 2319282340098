<template>
  <span class="data-table-additional-action-span" v-if="object.patient.mphone && !rights.includes('hide_patient_phone')">
  <a class="data-table-action"
     :title="$t('call')"
     v-b-tooltip.hover
     :href="`tel:${object.patient.mphone}`">
    <PhoneSvg/><span class="action-button-mob-title">{{ $t('call') }}</span>
  </a>
  <a :title="$t('sms_sending')"
     v-if="rights.includes('sms_send')"
     v-b-tooltip.hover
     class="data-table-action"
     @click="sendSms">
    <SmsSvg/><span class="action-button-mob-title">{{ $t('sms_sending') }}</span>
  </a>
    </span>
</template>

<script>

import SmsSvg from '@/assets/svg-vue/general/sms.svg'
import PhoneSvg from '@/assets/svg-vue/header/phone.svg'
import {mapState} from "vuex"

export default {
  name: "PatientWaitingListDatatableActions",
  components: {
    PhoneSvg,
    SmsSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    })
  },
  methods: {
    sendSms() {
      this.$store.commit('setPatient', this.object.patient)
      this.$nextTick(() => {
        this.$bvModal.show('patient-sms-modal')
      })
    }
  }
}
</script>

<style scoped>

</style>
