<template>
  <div class="comment mb-3"
       v-if="hasEnoughData">
    <div>
      <span v-if="commentType !== 'record'"
            class="comment-author text-primary mr-2">
        {{ comment.author ? comment.author.full_name : '' }}
      </span>
      <span class="comment-date text-muted mr-2">
        {{ formatDateTime(commentType == 'record' ? comment.record.updated_at : comment.created_at) }}
      </span>
      <a class="mr-2 pointer"
         @click="togglePin"
         :title="comment.pinned ? $t('unpin') : $t('pin')"
         v-b-tooltip.hover>
        <!--        <PinSvg class="mb-1" :class="{ 'svg-light': !comment.pinned, 'svg-primary': comment.pinned }"/>-->
        <PinSvg class="mb-1 svg-light"/>
        <span v-if="comment.pinned" class="ml-2 pinned d-inline-block">{{ $t('pinned') }}</span>
      </a>
      <a v-if="canBeEdited && !editMode"
         class="mr-2 pointer"
         @click="editComment">
        <EditSvg class="svg-light mb-1"/>
      </a>
      <a class="pointer"
         v-if="$store.state.auth.user.id == comment.created_by && commentType == 'manual'"
         @click="deleteComment">
        <TrashSvg class="svg-light mb-1"/>
      </a>
    </div>
    <div v-if="text || (comment.scans && comment.scans.length) || commentType === 'call' || editMode"
         class="comment-content">
      <div class="comment-header d-flex align-items-center">
        <component :is="icon"
                   class="mr-2"/>
        <span class="mr-2"
              @click="titleClicked"
              :class="{'text-underlined pointer': ['record','treatment_plan'].includes(commentType) }">
          {{ title }}
        </span>
        <span v-if="comment.variables && comment.variables.status" :class="`badge badge-${comment.variables.status}`">
          {{ $t(comment.variables.status) }}
        </span>
      </div>
      <div class="comment-block">
        <div v-if="editMode" class="pb-3 pt-2">
          <AutosizeTextArea ref="change"
                            v-model="changedText"
                            :showlabel="false"/>
          <div class="text-right">
            <button class="btn btn-themed btn-themed-squared btn-themed-outline"
                    @click="cancelEdit">
              {{ $t('cancel') }}
            </button>
            <button class="btn btn-themed btn-themed-squared ml-3"
                    @click="saveComment">
              {{ $t('save') }}
            </button>
          </div>
        </div>
        <TaskCommentText v-else-if="commentType === 'task'"
                         :text="text"
                         :task="comment.task"/>
        <LeadCommentText v-else-if="commentType === 'lead'"
                         :text="text"
                         :lead="comment.lead"/>
        <div v-else v-html="text"></div>
        <div v-if="comment.scans && comment.scans.length">
          <div v-if="showImages || forceShowImage" class="mt-3">
            <div v-for="scan in comment.scans"
                 :key="`scan-${scan.id}`"
                 class="d-inline-block mb-3">
              <expandable-image class="img-preview"
                                alt="image"
                                :src="scan.url ? scan.url : scan.image"/>
            </div>
          </div>
          <div v-else class="text-muted small">
            {{ $t('image_hidden') }}
            <a class="ml-1 pointer" @click="forceShowImage = true"><EyeSvg class="svg-light svg-size-md"/></a>
          </div>
        </div>
        <div v-if="comment.documents && comment.documents.length" class="mt-3">
          <div v-for="doc in comment.documents"
               :key="`doc-${doc.id}`"
               class="d-inline-block mb-3">
            <a :href="documentUrl(doc)" target="_blank">
              {{ doc.original }}
            </a>
          </div>
        </div>
        <div v-if="commentType === 'call'">
          <a class="text-underlined pointer"
             @click="linkToCall"
             v-if="!recordLink">
            {{ $t('listen') }}
          </a>
          <audio controls
                 v-if="recordLink"
                 class="mt-1"
                 autoplay>
            <source :src="recordLink" type="audio/mpeg">
          </audio>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TrashSvg from '@/assets/svg-vue/general/trash_tr.svg'
import PinSvg from '@/assets/svg-vue/patients/pin.svg'
import CommentSvg from '@/assets/svg-vue/patients/comment_comment.svg'
import NotificationSvg from '@/assets/svg-vue/patients/comment_notification.svg'
import PaymentSvg from '@/assets/svg-vue/patients/comment_payment.svg'
import TaskSvg from '@/assets/svg-vue/patients/comment_task.svg'
import TreatmentPlanSvg from '@/assets/svg-vue/patients/comment_treatment_plan.svg'
import VisitSvg from '@/assets/svg-vue/patients/comment_visit.svg'
import BonusSvg from '@/assets/svg-vue/patients/comment_star.svg'
import PhoneSvg from '@/assets/svg-vue/patients/phone.svg'
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import TimeSvg from '@/assets/svg-vue/calendar/clock3.svg'
import PatientsSvg from '@/assets/svg-vue/header/user_lenta.svg'
import EyeSvg from '@/assets/svg-vue/form/eye.svg'
import { EloquentService, BinotelService } from "@/services/api.service"
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import moment from "moment"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import { formatCurrency, formatDateTime } from "@/extensions/filters/filters"
import TaskCommentText from "@/components/parts/patients/comment/TaskCommentText.vue"
import LeadCommentText from "@/components/parts/patients/comment/LeadCommentText.vue"

export default {
  name: "PatientCommentTeaser",
  components: {
    TaskCommentText,
    LeadCommentText,
    AutosizeTextArea,
    TrashSvg,
    PinSvg,
    EditSvg,
    EyeSvg
  },
  props: {
    comment: Object,
    showImages: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dict: {
        manual: { icon: CommentSvg, title: 'comment' },
        payment: { icon: PaymentSvg, title: 'payment' },
        advance: { icon: PaymentSvg, title: 'payment' },
        notification: { icon: NotificationSvg, title: 'mailing' },
        treatment_plan: { icon: TreatmentPlanSvg, title: 'treatment_plan' },
        task: { icon: TaskSvg, title: 'task' },
        lead: { icon: TaskSvg, title: 'lead' },
        record: { icon: VisitSvg, title: 'visit' },
        bonus: { icon: BonusSvg, title: 'bonus' },
        call: { icon: PhoneSvg, title: 'call' },
        visit_timing: { icon: TimeSvg, title: 'visit_time' },
        patient: { icon: PatientsSvg, title: 'patient' },
      },
      recordLink: null,
      editMode: false,
      changedText: '',
      forceShowImage: false
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      user: state => state.auth.user,
    }),
    icon() {
      return this.dict[this.comment.comment_type].icon
    },
    commentType() {
      return this.comment.comment_type
    },
    hasEnoughData() {
      if(!this.comment) return false
      if(this.commentType === 'payment' && !this.comment.payment) return false
      if(this.commentType === 'advance' && !this.comment.advance) return false
      if(this.commentType === 'record' && !this.comment.record) return false
      if(this.commentType === 'task' && !this.comment.task) return false
      if(this.commentType === 'treatment_plan' && !this.comment.plan) return false
      if(this.commentType === 'notification' && !this.comment.notification) return false
      if(this.commentType === 'bonus' && !this.comment.payment && !this.comment.bonus_transaction) return false
      if(this.commentType === 'comment' && !this.comment.variables?.id) return false
      if(this.commentType === 'visit_timing' && !this.comment.record?.id) return false
      if(this.commentType === 'lead' && !this.comment.lead?.title) return false
      // if(this.commentType === 'patient' && !this.comment.lead?.title) return false
      return true
    },
    title() {
      const pretitle = this.$t(this.dict[this.commentType].title)
      switch(this.commentType) {
        case 'record':
          if(this.comment.record) {
            return `${pretitle} ${this.comment.record.time}`
          }
          break;
        case 'payment':
          if(this.comment.payment) {
            return `${pretitle} ${this.comment.payment.time}`
          }
          break;
        case 'advance':
          if(this.comment.advance) {
            return `${pretitle} ${this.comment.advance.time}`
          }
          break;
        case 'notification':
          return pretitle
        case 'task':
          return pretitle
        case 'lead':
          if(this.comment.lead) {
            return `${this.comment.lead.title}`
          }
          return pretitle
        case 'treatment_plan':
          if(this.comment.plan) {
            return `${pretitle} "${this.comment.plan.title}"`
          }
          break;
        case 'bonus':
          return pretitle
        case 'call':
          return this.$t('call_noun')
        case 'patient':
          return pretitle
        case 'visit_timing':
          if(this.comment.action === 'close') return this.$t('visit_finished')
          return this.$t('visit_started')
      }
      return pretitle
    },
    text() {
      switch(this.commentType) {
        case 'record':
          return this.generateTextForVisit()
        case 'payment':
          return this.generateTextForPayment()
        case 'advance':
          return this.generateTextForAdvance()
        case 'notification':
          return this.generateTextForNotification()
        case 'task':
          return this.generateTextForTask()
        case 'lead':
          return this.generateTextForLead()
        case 'treatment_plan':
          return this.generateTextForTreatmentPlan()
        case 'bonus':
          return this.generateTextForBonus()
        case 'call':
          return this.generateTextForCall()
        case 'visit_timing':
          return this.generateTextForVisitTiming()
        case 'patient':
          return this.generateTextForPatient()
      }
      return this.comment.text
    },
    isMine() {
      return this.user.id === this.comment.author?.id
    },
    canBeEdited() {
      return this.commentType === 'manual' && this.isMine
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    async editComment() {
      this.changedText = this.comment.text
      this.editMode = true
      await this.$nextTick()
      this.$refs?.change?.focusCustom()
    },
    cancelEdit() {
      this.editMode = false
    },
    async saveComment() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        text: this.changedText
      })
      this.comment.text = this.changedText
      await EloquentService.update('patient_comment', this.comment.id, formData)
      this.$noty.info(this.$t('success_message'))
      this.$emit('getComments')
      this.editMode = false
    },
    deleteComment() {
      if(confirm(this.$t('are_you_sure_you_want_to_delete_record'))) {
        EloquentService.delete('patient_comment', this.comment.id).then(() => {
          this.$noty.info(this.$t('success_message'))
          this.$emit('getComments')
        })
      }
    },
    generateTextForVisit() {
      if(!this.comment.record) return ''
      const record = this.comment.record
      let str = `${this.$t('visit')} ${record.time}`
      if(record.doctor) str += `<br/>${this.$t('doctor')}: ${record.doctor}`
      if(record.assistant) str += `<br/>${this.$t('assistant')}: ${record.assistant}`
      if(this.comment.author) str += `<br/>${this.$t('date_add')}: ${this.comment.author.full_name}, ${this.formatDateTime(record.created_at)}`
      if(record.confirmed_by) str += `<br/>${this.$t('confirmed')}: ${record.confirmed_by}`

      let block2Str = ''
      if(record.logs.length) {
        record.logs.forEach((log, index) => {
          if(index > 0) block2Str += `<br/>`
          block2Str += `<br/>${log.readable_date}`
          block2Str += `<br/>${log.conclusion}, ${log.author}`
        })
      }
      if(this.comment.variables?.status === 'cancelled' && record.cancelled_by && record.cancelled_at) {
        block2Str += `<br/><br/>${record.cancelled_at}`
        block2Str += `<br/>${this.$t('status')}: ${this.$t('cancelled')}`
        block2Str += `<br/>${this.$t('cancel_initiator')}: ${record.cancelled_by}`
        if(record.reason) block2Str += `, ${this.$t('cancel_reason')}: ${record.reason}`
      }
      if(record.timeMovements) {
        record.timeMovements.forEach(item => {
          block2Str += `<br/>${this.$t('postponed')}: ${moment(item.old_date).format('DD.MM.YYYY, HH:mm')} -> ${moment(item.new_date).format('DD.MM.YYYY, HH:mm')}`
          if(item.user) block2Str += `, ${item.user.full_name}`
        })
      }
      if(block2Str) {
        str += '<br/>================================='
        str += block2Str
      }
      let block3Str = ''
      if(record.diagnoses) block3Str += `<br/>${this.$t('diagnoses')}: ${record.diagnoses}`
      if(record.plan) block3Str += `<br/>${this.$t('treatment_plan')}: ${record.plan}`
      if(record.services) block3Str += `<br/>${this.$t('services')}: ${record.services}`
      if(record.price) block3Str += `<br/>${this.$t('cost')}: ${this.formatCurrency(record.price)}`
      if(record.description) block3Str += `<br/>${this.$t('description')}: ${record.description}`
      if(record.source) block3Str += `<br/>${this.$t('source')}: ${record.source}`
      if(block3Str) {
        str += '<br/>================================='
        str += block3Str
      }
      return str
    },
    generateTextForPayment() {
      if(!this.comment.payment) return ''
      const payment = this.comment.payment
      let str = `${this.$t('payment')} ${this.formatCurrency(payment.sum)}`
      str += `<br/>${this.$t('payment_type')}: ${payment.payment_type}`
      if(payment.visit) str += `<br/>${this.$t('visit')}: #${payment.visit.id} ${this.$t('from')} ${payment.visit.time}`
      return str
    },
    generateTextForAdvance() {
      if(!this.comment.advance) return ''
      const advance = this.comment.advance
      let str = `${this.$t('payment')} ${this.formatCurrency(advance.sum)}`
      str += `<br/>${this.$t('payment_type')}: ${advance.payment_type}`
      if(advance.sum > 0) {
        str += `<br/>${this.$t('balance_refill')}`
      }
      if(advance.sum < 0) {
        str += `<br/>${this.$t('balance_writeoff')}`
      }

      return str
    },
    generateTextForNotification() {
      if(!this.comment.notification) return ''
      const notification = this.comment.notification
      let str = `${this.$t('mailing')} ${notification.sent_at}`
      if(notification.channels) str += `<br/>${this.$t('channel')}: ${notification.channels}`
      if(notification.text) str += `<br/>${this.$t('text')}: ${notification.text}`
      return str
    },
    generateTextForTask() {
      if(!this.comment.task) return ''
      const task = this.comment.task
      let str = `${this.$t('task')}: ${this.$t(task.type)}`
      if(task.description) str += `<br/>${this.$t('description')}: ${task.description}`
      if(task.responsible) str += `<br/>${this.$t('responsible')}: ${task.responsible}`
      if(task.deadline) str += `<br/>${this.$t('deadline')}: ${task.deadline}`
      return str
    },
    generateTextForLead() {
      if(!this.comment.lead) return ''
      const lead = this.comment.lead
      let str = `${this.$t('channel')}: ${lead.channel}`
      if(lead.comment) str += `<br/>${this.$t('comment')}: ${lead.comment}`
      return str
    },
    generateTextForTreatmentPlan() {
      if(!this.comment.plan) return ''
      const plan = this.comment.plan
      const visits = plan.visits
      let str = ''
      visits.forEach(visit => {
        if(str) str += '<br/>'
        str += `${visit.title}, ${this.$t('status')}: ${visit.status}.`
      })
      if(visits.length === 0) {
        str = this.$t('no_complexes')
      }
      return str
    },
    generateTextForBonus() {
      let str = ''
      if(this.comment.payment) {
        const payment = this.comment.payment
        if(payment.sum) { // means paid with bonuses
          str += `${this.$t('bonus_payment')}: ${this.formatCurrency(payment.sum)}`
        }
        if(this.comment.payment.accrued) {
          if(str) str += '<br/>'
          str += `${this.$t('bonus_accrued')}: ${this.formatCurrency(payment.accrued)}`
        }
        str += `<br/>${this.$t('payment')} ${payment.time}`
        if(payment.visit) str += `<br/>${this.$t('visit')}: #${payment.visit.id} ${this.$t('from')} ${payment.visit.time}`
      }
      if(this.comment.bonus_transaction) {
        str += `${this.$t('bonus_adjustment')}: `
        if(this.comment.bonus_transaction.sum < 0) {
          str += this.$t('write_off').toLowerCase()
        } else {
          str += this.$t('accrual').toLowerCase()
        }
        str += ` ${this.formatCurrency(this.comment.bonus_transaction.sum)}`
      }
      return str
    },
    generateTextForCall() {
      return ''
    },
    generateTextForVisitTiming() {
      let str = ''
      if(this.comment.action === 'open') {
        str += `${this.$t('visit_started')}: ${this.comment.date}`
      }
      if(this.comment.action === 'close') {
        str += `${this.$t('visit_finished')}: ${this.comment.date}`
      }
      str += `<br/>${this.$t('visit')} ${this.comment.record.time}`
      // if(record.doctor) str += `<br/>${this.$t('doctor')}: ${record.doctor}`
      return str
    },
    generateTextForPatient() {
      let str = this.comment.text
      if(this.comment.author) str += `<br/>${this.$t('by')}: ${this.comment.author.full_name}`
      if(this.comment.patient?.title) str += `<br/>${this.$t('with_patient')}: ${this.comment.patient.title}`
      return str
    },
    documentUrl(doc) {
      let link = JwtService.getPrintPrelink(this.clinic)
      return link+'/client/document/'+doc.id
    },
    async titleClicked() {
      if(this.commentType === 'record' && this.comment.record) {
        this.$router.push({ name: 'PatientVisitPage', params: {
            id: this.$route.params.id,
            visitid: this.comment.record.id,
            page: 'description'
          }
        }).catch(()=>{})
      }
      if(this.commentType === 'treatment_plan' && this.comment.plan) {
        this.$router.push({ name: 'PatientTreatmentPlan', params: {
            id: this.$route.params.id,
          }
        }).catch(()=>{})
        let res = await EloquentService.dataTable('plan_treatment', 1, { condition: { planning_id: this.comment.plan.id }})
        if(res.data && res.data.result.data.length) {
          this.$store.commit('setVisit', this.copyObject(res.data.result.data[0]))
          this.$bvModal.show('plan-treatment-modal')
        }
      }
    },
    async linkToCall() {
      if(this.comment.variables?.record_link) {
        this.recordLink = this.comment.variables.record_link
        return
      }
      const id = this.comment.variables?.id
      if(!id) return
      const vendor = this.comment.variables?.vendor
      let res = null
      if(vendor === 'uis') {
        res = await BinotelService.getUISCallRecording(this.comment.id)
      } else {
        res = await BinotelService.getCallRecording(id)
      }
      if(res.data?.url) {
        this.recordLink = res.data.url
      } else {
        this.$noty.error(this.$t('record_not_available'))
      }
    },
    async togglePin() {
      let formData = new FormData()
      formData.append('pinned', this.comment.pinned ? 0 : 1)
      await EloquentService.update('patient_comment', this.comment.id, formData)
      this.$emit('getComments')
    }
  }
}
</script>

<style scoped lang="scss">
.comment-content {
  border: 1px solid #B5C6D8;
  border-radius: 5px;
  overflow: hidden;
  .comment-header {
    background: #F2F6FA;
    padding: 5px 16px;
    color: #495A6B;
    font-weight: 600;
    .badge {
      &-not_confirmed, &-postponed, &-planned, &-preliminary, &-inprogress, &-blocked, &-stopped {
        background: #B5C6D8;
        color: #fff;
      }
      &-confirmed, &-came, &-active {
        background: #C6F3B6;
        color: #398320;
      }
      &-in_chair, &-done, &-treatment_complete, &-sent, &-paid {
        background: #71CF50;
        color: #fff;
      }
      &-did_not_come, &-cancelled, &-debt, &-rejected {
        background: #F3C4C3;
        color: #AA2D29;
      }
    }
  }
  .comment-block {
    padding: 5px 16px;
  }
}
.pinned {
  color: #ADAFB8;
  font-size: 13px;
  position: relative;
  top: -1px;
}

@media screen and (max-width: 768px) {
  .pinned {
    font-size: 11px;
  }
}

</style>
