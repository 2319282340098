<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   :initialOrder="false"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script setup>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch } from "vue"
import { useStore } from "vuex"
import { useHead } from "@unhead/vue"

let i18n = null
const title = ref('')
const datatable = ref(null)

useHead({
  title: () => title.value,
})

const props = defineProps({
  from: {},
  to: {},
  doctors: {},
  patients: {},
  services: {},
  insuranceCompanies: {},
  selectedBranches: {}
})

const store = useStore()
const counter = computed(() => store.state.datatable.dataTableCounter)

const rows = ref([])
const columns = computed(() => {
  return [
    {
      id: 'date',
      field: 'date',
      label: i18n?.t('date'),
      sortable: true,
      format_datetime: true
    },
    {
      id: 'polis_number',
      field: 'polis_number',
      label: i18n?.t('polis'),
      sortable: true
    },
    {
      id: 'insurance_company',
      field: 'insurance_company',
      label: i18n?.t('insurance_company'),
      search_select: true,
      sortable: true
    },
    {
      id: 'patient_name',
      field: 'patient_name',
      label: i18n?.t('patient'),
      tdcomponent: 'PatientsDatatableTDName',
      search_select: true,
      sortable: true
    },
    {
      id: 'doctor_name',
      field: 'doctor_name',
      format_html: true,
      label: i18n?.t('doctor'),
      search_select: true,
      search_doctor_one: true,
      sortable: true
    },
    {
      id: 'tooth',
      field: 'tooth',
      label: i18n?.t('tooth'),
      sortable: true
    },
    {
      id: 'diagnosis',
      field: 'diagnosis',
      label: i18n?.t('mkb_code'),
      sortable: true
    },
    {
      id: 'service_name',
      field: 'service_name',
      label: i18n?.t('service'),
      search_select: true,
      sortable: true
    },
    {
      id: 'service_code',
      field: 'service_code',
      label: i18n?.t('service_code'),
      sortable: true
    },
    {
      id: 'price',
      field: 'price',
      label: i18n?.t('sum'),
      sortable: true,
      format_number: true,
      totals: true
    },
  ]
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('insurance')
})

onMounted(() => {
  loadReport()
})

watch(() => counter, () => {
  loadReport()
})

const loadReport = async () => {
  const res = await ReportsService.loadReport('insurance', {
    from: props.from,
    to: props.to,
    doctors: props.doctors.map(s => s.id),
    patients: props.patients.map(s => s.id),
    services: props.services.map(s => s.id),
    insuranceCompanies: props.insuranceCompanies.map(s => s.id),
    branch_id: props.selectedBranches,
  })
  rows.value = res.data
}

const excel = () => {
  datatable.value.excel()
}

defineExpose({
  loadReport,
  excel
})
</script>
