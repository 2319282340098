<template>
  <div>
    {{ showingAll ? object[field.id] : shortVersion }}
    <div v-if="hasMore && !showingAll"
         class="pointer text-primary"
         @click="showingAll = true">
      {{ $t('show_full') }}
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue"

const props = defineProps({
  object: {
    type: Object
  },
  field: {
    type: Object
  }
})

const showingAll = ref(false)

const lenLimit = 100

const shortVersion = computed(() => {
  const str = props.object[props.field.id]?.substring(0, lenLimit) || props.object[props.field.id] || ''
  if(hasMore.value) {
    return str + '...'
  }
  return str
})

const hasMore = computed(() => {
  return props.object[props.field.id]?.length > lenLimit
})
</script>
