<template>
  <InputGroup :label="$t(field.title)"
              type="text"
              :required="true"
              v-model="object[field.id]"/>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"

export default {
  name: "PatientsDatatableFormCard",
  components: {
    InputGroup
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  mounted() {
    if(!this.object.id && this.selectModels && this.selectModels.next_card) {
      this.$emit('input', this.selectModels.next_card)
    }
  },
}
</script>

<style scoped>

</style>
