<template>
  <span class="data-table-additional-action-span" v-if="canBePaused || canBeDuplicated">
  <a class="data-table-action"
     :title="$t('duplicate')"
     v-b-tooltip.hover
     v-if="canBeDuplicated"
     @click="duplicate">
    <RefreshSvg/>
    <span class="action-button-mob-title">{{ $t('duplicate') }}</span>
<!--    <PlaySvg />-->
<!--    <span class="action-button-mob-title">{{ $t('start') }}</span>-->
  </a>
  <a :title="$t('stop_mailing')"
     v-b-tooltip.hover
     v-if="canBePaused"
     class="data-table-action"
     @click="stop">
    <PauseSvg/><span class="action-button-mob-title">{{ $t('sms_sending') }}</span>
  </a>
    </span>
</template>

<script>

// import PlaySvg from '@/assets/svg-vue/marketing/play.svg'
import PauseSvg from '@/assets/svg-vue/marketing/pause.svg'
import RefreshSvg from '@/assets/svg-vue/general/refresh2.svg'
import {mapState} from "vuex"
import {MarketingService} from "@/services/api.service"
import moment from "moment"

export default {
  name: "MarketingMailingDatatableActions",
  components: {
    // PlaySvg,
    PauseSvg,
    RefreshSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  data() {
    return {
      now: moment().format('YYYY-MM-DD HH:mm')+':00',
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),
    totalProcessed() {
      return parseInt(this.object.sent_notifications) + parseInt(this.object.error_notifications)
    },
    canBeDuplicated() {
      return !this.object.send_time || this.object.send_time <= this.now
    },
    canBePaused() {
      return this.totalProcessed < this.object.marketing_group.patientsCount
    }

  },
  methods: {
    // async run() {
    //   let res = await MarketingService.launchSending(this.object.id)
    //   if(res.data) this.$noty.info(this.$t('success_message'))
    // },
    async duplicate() {
      this.$store.commit('setViewModel', 'marketing_mailing')
      this.$store.commit('setModule', 'marketing')
      let newObj = {
        clinic_id: this.object.clinic_id,
        group_id: this.object.group_id,
        notification_channel_id: this.object.notification_channel_id,
        title: this.object.title,
        text: this.object.text,
        send_time: this.object.send_time
      }
      this.$store.commit('setSelectedModel', newObj)
      this.$store.commit('setShowModal', true)
    },
    async stop() {
      let res = await MarketingService.stopSending(this.object.id)
      if(res.data) this.$noty.info(this.$t('success_message'))
    }
  }
}
</script>

<style scoped>

</style>
