<template>
  <div class="scroll-to-event-element" ref="scrolled">
    <div class="scroll-to-event-element__content">
      <slot/>
    </div>
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, ref } from "vue"

const emit = defineEmits(['visible'])

const scrolled = ref(null)
let observer = null

const initializeObserver = () => {
  observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          emit('visible')
        }
      },
      { threshold: 0.1 } // Adjust threshold to detect as per your need
  )
  observer.observe(scrolled.value)
}

onMounted(() => {
  initializeObserver()
})

onBeforeUnmount(() => {
  observer.disconnect()
})
</script>
