<template>
  <a class="position-relative">
    <BellSvg class="pointer" @click.native="notificationsPressed"/>
    <span class="notification-bubble" v-if="notificationsCounter > 0">
      {{ notificationsCounter }}
    </span>
    <b-dropdown menu-class="mt-3 header-notifications"
                right
                ref="dropdown"
                variant="link"
                no-caret>
      <div>
        <div v-for="(n, nind) in notifications"
             :key="`notif-${n.id}`"
             class="dropdown-item">
          <NotificationTeaser :notification="n"
                              @onDelete="notificationDeleted(nind)"
                              :class="{'border-bottom': (nind + 1) < notifications.length }"/>
        </div>
        <div v-if="loaded && !notifications.length" class="dropdown-item">
          {{ $t('no_notifications') }}
        </div>
        <div v-if="more" class="text-center p-3">
          <RefreshSvg class="pointer" @click="loadMore"/>
        </div>
      </div>
    </b-dropdown>
  </a>
</template>

<script>

import BellSvg from '@/assets/svg-vue/header/bell.svg'
import RefreshSvg from '@/assets/svg-vue/general/refresh2.svg'
import {mapState} from "vuex"
import {EloquentService, MarketingService} from "@/services/api.service"
import NotificationTeaser from "@/components/parts/header/NotificationTeaser"

export default {
  name: "HeaderNotifications",
  components: {
    NotificationTeaser,
    BellSvg,
    RefreshSvg
  },
  data() {
    return {
      notifications: [],
      page: 1,
      loaded: false,
      more: false,
      timer: null
    }
  },
  computed: {
    ...mapState({
      headerCounters: state => state.auth.headerCounters,
      user: state => state.auth.user
    }),
    notificationsCounter() {
      return this.headerCounters.unread
    }
  },
  methods: {
    notificationsPressed() {
      if(this.$refs.dropdown.visible) {
        if(this.timer) clearTimeout(this.timer)
        this.$refs.dropdown.hide()
        return
      }
      // if(this.loaded) {
      //   this.$refs.dropdown.show()
      //   return
      // }
      this.page = 1
      this.notifications = []
      this.load()
    },
    loadMore() {
      this.page++
      this.load()
    },
    load() {
      EloquentService.dataTable('notification', this.page, {
        condition: {
          recipient_id: this.user.id,
          show_in_header: 1,
          sent_at: 'whereNotNull'
        },
        order: 'sent_at',
        sort: 'desc',
        pagination:  10,
      }).then(res => {
        this.notifications = this.notifications.concat(res.data.result.data)
        this.more = res.data.result.current_page < res.data.result.last_page
        this.loaded = true
        this.$refs.dropdown.show()
        this.timer = setTimeout(() => {
          let ids = this.notifications.filter(s => !s.is_read).map(s => s.id)
          if(ids.length) {
            MarketingService.readNotifications(ids).then(res => {
              if(!res.data) return
              this.notifications.forEach(item => {
                if(ids.includes(item.id)) item.is_read = true
              })
              this.$store.commit('incrementHeaderUpdateCounter')
            })
          }
        }, 2000)
      })
    },
    notificationDeleted(index) {
      setTimeout(() => {
        this.notifications.splice(index, 1)
      }, 20)

    }
  }
}
</script>

<style scoped>
.notification-bubble {
  top: 0;
  left: 12px;
}
</style>
