<template>
  <router-link :title="$t('stocks')"
               v-b-tooltip.hover
               class="tdclickable data-table-action"
               :to="{ name: 'WarehousesStocks', query: params }">
    <BoxSvg/><span class="action-button-mob-title">{{ $t('stocks') }}</span>
  </router-link>
</template>

<script>
import BoxSvg from '@/assets/svg-vue/warehouse/box.svg'

export default {
  name: "WarehouseDatatableActionsOperation",
  components: {
    BoxSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    params() {
      return {
        filters: JSON.stringify({
          warehouse_id: {
            id: 'warehouse_id',
            condition: this.object.id,
            type: 'equals'
          }
        })
      }
    }
  },
}
</script>

<style scoped>

</style>
