export default {
    model: 'patient_canban',
    order: 'id',
    sort: 'desc',
    deletemessage: 'confirm_deletion',
    modaltitle: 'deal',
    showTotalRecords: true,
    actions: [
        "add",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'patient_id',
            title: 'patient',
            table_show: 'patient.full_name',
            tabletdclass: "order-mob-0",
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                required: true,
                component: "CRMDatatableFormPatient"
            },
            tdcomponent: 'PatientsDatatableTDName',
        },
        {
            id: 'doctor_id',
            title: 'doctor',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "full_name"
                },
            },
            tdcomponent: 'PatientsDatatableTDDoctorName',
        },
        {
            id: 'curator_id',
            title: 'curator',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select: true,
            table_show: 'curator.full_name',
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "full_name"
                },
            },
        },
        {
            id: 'responsible_user_id',
            title: 'responsible',
            table: true,
            sortable: true,
            filter: true,
            filter_model_select: true,
            table_show: 'responsible.full_name',
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "full_name"
                },
            },
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            sortable: true,
            form: {
                type: "textarea",
            }
        },
        // {
        //     id: 'status',
        //     title: 'status',
        //     table: true,
        //     sortable: false,
        //     filter: true,
        //     filter_list_select: true,
        //     form: {
        //         type: "select",
        //         required: true,
        //         select_type: "list",
        //         options: funnelPatientStatuses
        //     }
        // },
        {
            id: 'status_id',
            title: 'status',
            table_show: 'patient_canban_status.translatedTitle',
            filter: true,
            filter_model_select_translate: true,
            table: true,
            sortable: true,
            form: {
                type: "select",
                select_type: "model",
                disabled_on_edit: true,
                required: true,
                select_data: {
                    "title": "translatedTitle",
                },
            }
        },
    ]
}
