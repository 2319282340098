<template>
  <div>
    <span class="text-primary" v-if="object[field.id]">{{ $t('active') }}</span>
    <span v-else class="text-danger">{{ $t('nonactive') }}</span>
  </div>
</template>

<script>
export default {
  name: "ActiveTD",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
