<template>
  <g>
    <!--   SAME AS 22  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M17.1492 2.26976L15.0655 1H11.5817L9.48174 2.26976L11.0934 3.81627L9.00965 5.36278L10.898 6.90929L8.60268 8.60231L10.4259 10.1488L8.13058 11.5488L10.1492 12.9651L8.13058 14.5767L9.8236 16.1232L7.59338 17.5395L9.35151 19.0046L7.05617 20.5511L9.205 22.0976L7.05617 23.7093L8.60268 25.0604L6.51896 26.6069L8.34221 28.0883L6.25849 29.6348L8.06547 31.3767L7.05617 33.0534V47.3139L8.94454 54.6394H15.0655" fill="#8889BE"/>
      <path d="M9.74189 2.26976L11.8256 1H15.293L17.4093 2.26976L15.7814 3.81627L17.8814 5.36278L15.993 6.90929L18.2721 8.60231L16.4651 10.1488L18.7442 11.5488L16.7256 12.9651L18.7442 14.5767L17.0675 16.1232L19.2814 17.5395L17.5396 19.0046L19.8186 20.5511L17.6698 22.0976L19.8186 23.7093L18.2721 25.0604L20.3558 26.6069L18.5489 28.0883L20.6326 29.6348L18.8093 31.3767L19.8186 33.0534V47.3139L17.9465 54.6394H14.0396" fill="#8889BE"/>
      <path d="M3.14644 79.4347C6.69662 81.2098 13.0573 80.3357 13.0573 80.3357C13.0573 80.3357 24.313 80.0264 25.9401 74.9567C27.5673 69.8869 24.313 61.388 24.313 61.388C24.313 61.388 23.3448 56.4258 22.2421 51.1006L21.9193 49.5541L17.495 45.6947C16.3978 44.9372 15.1245 44.4741 13.797 44.3499C12.0853 44.2149 10.3753 44.6295 8.91547 45.5333C8.91547 45.5333 3.80538 48.2228 3.53642 50.1862L1.53273 68.1522C1.53273 68.1522 -0.417173 77.6596 3.14644 79.4347Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M10.9357 0.318871L14.4859 10.2298C14.4859 10.2298 17.4578 17.6394 17.014 21.6333C16.5702 25.6273 18.0495 37.0309 19.2329 39.9893C19.8246 41.4686 21.0752 47.1703 22.1913 52.4956C23.3075 57.8208 24.2623 62.783 24.2623 62.783C24.2623 62.783 27.5704 71.3761 25.9432 76.4055C24.3161 81.4349 13.0604 81.7845 13.0604 81.7845C13.0604 81.7845 6.6997 82.6721 3.14953 80.897C-0.400642 79.1219 1.52237 69.601 1.52237 69.601L3.5933 50.9626L6.108 34.3682L7.88309 20.302L8.32686 8.30674L9.36233 1.05847C9.36233 1.05847 10.6264 -0.703148 10.9357 0.318871Z" fill="#DEE1E9"/>
      <path d="M3.1462 80.8902C6.69637 82.6653 13.0571 81.7912 13.0571 81.7912C13.0571 81.7912 24.3127 81.4819 25.9399 76.4121C27.5671 71.3424 24.3127 62.8435 24.3127 62.8435C24.3127 62.8435 23.3445 57.8813 22.2418 52.5561L21.9191 51.0096L17.4948 47.1501C16.3976 46.3926 15.1242 45.9296 13.7967 45.8054C12.0851 45.6704 10.375 46.085 8.91522 46.9888C8.91522 46.9888 3.80513 49.6783 3.53618 51.6416L1.53249 69.6076C1.53249 69.6076 -0.417418 79.1151 3.1462 80.8902Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M11.5483 51.9846C10.2976 50.2633 11.1179 47.7082 11.091 38.8194C11.091 30.9525 10.4052 30.5222 10.9566 26.34C11.36 23.2605 11.6236 21.4844 12.2422 16.6298C12.7397 12.7166 10.0834 2.17445 10.2447 2.20135C10.4061 2.22824 13.3906 15.2457 13.6999 21.5123C13.9957 27.51 14.1974 26.7972 14.5067 32.768C14.964 41.4417 14.5067 42.4502 15.4077 45.9197C16.1473 48.7303 16.9408 49.954 16.5508 51.2988C16.374 51.7878 16.0668 52.2192 15.6625 52.5463C15.2582 52.8733 14.7721 53.0835 14.2569 53.1541C13.7417 53.2248 13.2169 53.1531 12.7395 52.947C12.2621 52.7409 11.8501 52.408 11.5483 51.9846Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M10.9357 3.47604L14.4859 13.3869C14.4859 13.3869 17.4578 20.7966 17.014 24.7905C16.5702 28.7844 18.0495 40.188 19.2329 43.1465C19.8246 44.6257 21.0752 50.3275 22.1913 55.6528C23.3075 60.978 24.2623 65.9402 24.2623 65.9402C24.2623 65.9402 27.5704 74.5332 25.9432 79.5626C24.3161 84.592 13.0604 84.9417 13.0604 84.9417C13.0604 84.9417 6.6997 85.8292 3.14953 84.0541C-0.400642 82.279 1.52237 72.7581 1.52237 72.7581L3.5933 54.1197L6.108 37.5254L7.88309 23.4592L8.32686 11.4639L9.36233 4.21564C9.36233 4.21564 10.6264 2.45402 10.9357 3.47604Z" fill="#DEE1E9"/>
      <path d="M3.1462 84.0473C6.69637 85.8224 13.0571 84.9483 13.0571 84.9483C13.0571 84.9483 24.3127 84.639 25.9399 79.5693C27.5671 74.4995 24.3127 66.0007 24.3127 66.0007C24.3127 66.0007 23.3445 61.0385 22.2418 55.7132L21.9191 54.1668L17.4948 50.3073C16.3976 49.5498 15.1242 49.0868 13.7967 48.9625C12.0851 48.8276 10.375 49.2421 8.91522 50.1459C8.91522 50.1459 3.80513 52.8354 3.53618 54.7988L1.53249 72.7648C1.53249 72.7648 -0.417418 82.2723 3.1462 84.0473Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M11.5483 55.1418C10.2976 53.4205 11.1179 50.8654 11.091 41.9765C11.091 34.1097 10.4052 33.6794 10.9566 29.4972C11.36 26.4177 11.6236 24.6416 12.2422 19.787C12.7397 15.8738 10.0834 5.33162 10.2447 5.35851C10.4061 5.38541 13.3906 18.4029 13.6999 24.6695C13.9957 30.6671 14.1974 29.9544 14.5067 35.9251C14.964 44.5988 14.5067 45.6074 15.4077 49.0769C16.1473 51.8874 16.9408 53.1112 16.5508 54.4559C16.374 54.945 16.0668 55.3764 15.6625 55.7034C15.2582 56.0305 14.7721 56.2407 14.2569 56.3113C13.7417 56.3819 13.2169 56.3103 12.7395 56.1042C12.2621 55.898 11.8501 55.5652 11.5483 55.1418Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="10" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M9.93594 1.21487L13.4861 11.1258C13.4861 11.1258 16.458 18.5354 16.0143 22.5293C15.5705 26.5233 17.0497 37.9268 18.2331 40.8853C18.8248 42.3646 20.0754 48.0663 21.1916 53.3916C22.3077 58.7168 23.2625 63.679 23.2625 63.679C23.2625 63.679 26.5706 72.2721 24.9435 77.3015C23.3163 82.3309 12.0607 82.6805 12.0607 82.6805C12.0607 82.6805 5.69994 83.568 2.14977 81.793C-1.4004 80.0179 0.522612 70.497 0.522612 70.497L2.59355 51.8586L5.10825 35.2642L6.88333 21.198L7.3271 9.20273L8.36257 1.95447C8.36257 1.95447 9.62665 0.192848 9.93594 1.21487Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M9.93594 1.21487L13.4861 11.1258C13.4861 11.1258 16.458 18.5354 16.0143 22.5293C15.5705 26.5233 17.0497 37.9268 18.2331 40.8853C18.8248 42.3646 20.0754 48.0663 21.1916 53.3916C22.3077 58.7168 23.2625 63.679 23.2625 63.679C23.2625 63.679 26.5706 72.2721 24.9435 77.3015C23.3163 82.3309 12.0607 82.6805 12.0607 82.6805C12.0607 82.6805 5.69994 83.568 2.14977 81.793C-1.4004 80.0179 0.522612 70.497 0.522612 70.497L2.59355 51.8586L5.10825 35.2642L6.88333 21.198L7.3271 9.20273L8.36257 1.95447C8.36257 1.95447 9.62665 0.192848 9.93594 1.21487Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M11.4349 10.5124L7.88474 0.601525C7.57544 -0.420494 6.31137 1.34113 6.31137 1.34113L5.2759 8.58939L4.83213 20.5847L3.05704 34.6509L0.542343 51.2452L0.542263 58.7152L6.09772 57.3353L10.8934 58.7152L16.8653 57.3353L21.2112 58.7152C21.2112 58.7152 20.2565 58.1035 19.1404 52.7782C18.0242 47.453 16.7736 41.7512 16.1819 40.272C14.9985 37.3135 13.5193 25.9099 13.9631 21.916C14.4068 17.922 11.4349 10.5124 11.4349 10.5124Z" fill="#DEE1E9"/>
      <path d="M17.4193 52.0832C10.3542 50.5669 6.79292 51.482 1.00812 55.6586" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M7.9056 29.1174C7.35425 33.7319 8.04008 34.2067 8.04008 42.8866L8.28912 59.3993L10.7936 58.6967L14.3822 59.0665L13.4998 56.6557C13.8898 55.1719 13.0964 53.8217 12.3568 50.7207C11.4558 46.8927 11.913 45.7799 11.4558 36.2097C11.1465 29.6219 10.9448 30.4083 10.6489 23.7908C10.3396 16.8765 7.35513 2.51368 7.19376 2.484C7.03239 2.45433 9.68875 14.086 9.19119 18.4037C8.5726 23.76 8.30903 25.7197 7.9056 29.1174Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M14.4375 11.1003L10.8873 1.18938C10.578 0.167366 9.31397 1.92899 9.31397 1.92899L8.27851 9.17725L7.83474 21.1725L6.05965 35.2387L3.54495 51.8331L2.9204 58.3986L24.2139 63.6535C24.2139 63.6535 23.2591 58.6914 22.143 53.3661C21.0268 48.0408 19.7762 42.3391 19.1845 40.8598C18.0011 37.9014 16.5219 26.4978 16.9657 22.5038C17.4094 18.5099 14.4375 11.1003 14.4375 11.1003Z" fill="#DEE1E9"/>
      <path d="M13.0087 82.6617C13.0087 82.6617 6.64801 83.5358 3.09784 81.7607C-0.465778 79.9856 1.48412 70.4782 1.48412 70.4782L3.48782 52.5121C3.75677 50.5488 8.86686 47.8593 8.86686 47.8593C10.3267 46.9555 12.0367 46.5409 13.7484 46.6759C15.0759 46.8001 16.3492 47.2632 17.4464 48.0206L21.8707 51.8801L22.1935 53.4266C23.2962 58.7518 24.2644 63.714 24.2644 63.714C24.2644 63.714 25.0486 65.7621 25.6547 68.4473H23.7013L18.2214 68.0683L19.1285 70.7136L17.9209 73.1804L19.1285 75.4939L15.3194 77.0086L16.6581 80.0436L17.9209 82.0727C15.2751 82.5994 13.0087 82.6617 13.0087 82.6617Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M21.6457 62.3507C12.0209 65.7759 9.91865 69.8861 11.3632 80.6611" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M11.4999 52.8551C10.2493 51.1338 11.0696 48.5788 11.0427 39.6899C11.0427 31.823 10.3568 31.3927 10.9082 27.2105C11.3116 24.131 11.5752 22.3549 12.1938 17.5004C12.6913 13.5871 10.035 3.04496 10.1964 3.07186C10.3577 3.09875 13.3422 16.1162 13.6515 22.3828C13.9474 28.3805 14.1491 27.6677 14.4584 33.6385C14.9156 42.3122 14.4584 43.3207 15.3594 46.7902C16.099 49.6008 16.8924 50.8245 16.5024 52.1693C16.3256 52.6583 16.0185 53.0897 15.6141 53.4168C15.2098 53.7438 14.7237 53.954 14.2085 54.0247C13.6933 54.0953 13.1686 54.0237 12.6911 53.8175C12.2137 53.6114 11.8018 53.2786 11.4999 52.8551Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M11.3358 1.07636L14.886 10.9872C14.886 10.9872 17.8579 18.3969 17.4142 22.3908C16.9704 26.3848 18.4496 37.7883 19.633 40.7468C20.2247 42.2261 21.4753 47.9278 22.5915 53.2531C23.7076 58.5783 24.6624 63.5405 24.6624 63.5405C24.6624 63.5405 27.9705 72.1336 26.3434 77.163C24.7162 82.1924 13.4606 82.542 13.4606 82.542C13.4606 82.542 7.09984 83.4295 3.54967 81.6545C-0.000495911 79.8794 1.92251 70.3585 1.92251 70.3585L3.99345 51.7201L6.50815 35.1257L8.28324 21.0595L8.72701 9.06423L9.76247 1.81597C9.76247 1.81597 11.0265 0.0543443 11.3358 1.07636Z" fill="#DEE1E9"/>
      <path d="M13.4572 82.5487C13.4572 82.5487 7.09652 83.4228 3.54635 81.6477C-0.017271 79.8726 1.93263 70.3651 1.93263 70.3651L4.19981 51.7671H7.96966C8.52195 51.7671 8.96966 52.2148 8.96966 52.7671V61.6049C13.1653 62.7986 14.0115 62.6835 18.2072 61.6049V52.7671C18.2072 52.2148 18.6549 51.7671 19.2072 51.7671H22.3192L22.642 53.3136C23.7447 58.6388 24.7129 63.601 24.7129 63.601C24.7129 63.601 27.9672 72.0999 26.34 77.1696C24.7129 82.2394 13.4572 82.5487 13.4572 82.5487Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M11.3358 0.327149L14.886 10.238C14.886 10.238 17.8579 17.6477 17.4142 21.6416C16.9704 25.6355 18.4496 37.0391 19.633 39.9976C20.2247 41.4768 21.4753 47.1786 22.5915 52.5039C23.7076 57.8291 24.6624 62.7913 24.6624 62.7913C24.6624 62.7913 27.9705 71.3843 26.3434 76.4137C24.7162 81.4432 13.4606 81.7928 13.4606 81.7928C13.4606 81.7928 7.09984 82.6803 3.54967 80.9052C-0.000495911 79.1302 1.92251 69.6092 1.92251 69.6092L3.99345 50.9708L6.50815 34.3765L8.28324 20.3103L8.72701 8.31501L9.76247 1.06675C9.76247 1.06675 11.0265 -0.69487 11.3358 0.327149Z" fill="#DEE1E9"/>
      <path d="M13.4572 81.7994C13.4572 81.7994 7.09652 82.6735 3.54635 80.8985C-0.017271 79.1234 1.93263 69.6159 1.93263 69.6159L4.19981 51.0179H7.96966C8.52195 51.0179 8.96966 51.4656 8.96966 52.0179V60.8557C13.1653 62.0494 14.0115 61.9343 18.2072 60.8557V52.0179C18.2072 51.4656 18.6549 51.0179 19.2072 51.0179H22.3192L22.642 52.5643C23.7447 57.8896 24.7129 62.8518 24.7129 62.8518C24.7129 62.8518 27.9672 71.3507 26.34 76.4204C24.7129 81.4902 13.4572 81.7994 13.4572 81.7994Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M10.8329 0.394798L14.3831 10.3057C14.3831 10.3057 17.355 17.7153 16.9112 21.7093C16.4675 25.7032 17.9467 37.1068 19.1301 40.0653C19.7218 41.5445 20.9724 47.2463 22.0886 52.5715C23.2047 57.8968 24.1595 62.859 24.1595 62.859C24.1595 62.859 27.4676 71.452 25.8404 76.4814C24.2133 81.5108 12.9576 81.8604 12.9576 81.8604C12.9576 81.8604 6.59691 82.748 3.04675 80.9729C-0.503426 79.1978 1.41958 69.6769 1.41958 69.6769L3.49052 51.0385L6.00522 34.4441L7.78031 20.378L8.22408 8.38266L9.25954 1.1344C9.25954 1.1344 10.5236 -0.62722 10.8329 0.394798Z" fill="#DEE1E9"/>
      <path d="M12.9543 81.8671C12.9543 81.8671 6.59359 82.7412 3.04342 80.9661C-0.520201 79.191 1.4297 69.6836 1.4297 69.6836L3.69688 51.0855H7.48298V60.9234C11.6786 62.117 14.0316 62.0019 18.2273 60.9234V51.0855H21.8163L22.139 52.632C23.2417 57.9572 24.21 62.9194 24.21 62.9194C24.21 62.9194 27.4643 71.4183 25.8371 76.4881C24.21 81.5578 12.9543 81.8671 12.9543 81.8671Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask2022" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="27" height="32">
        <path d="M12.9521 81.9344C12.9521 81.9344 6.59139 82.8085 3.04122 81.0334C-0.522398 79.2583 1.42751 69.7508 1.42751 69.7508L3.69468 51.8633H6.48078C7.03306 51.8633 7.48078 52.311 7.48078 52.8633V60.9906C11.6764 62.1843 14.0294 62.0692 18.2251 60.9906V52.8633C18.2251 52.311 18.6728 51.8633 19.2251 51.8633H21.8141L22.1368 52.6993C23.2395 58.0245 24.2078 62.9867 24.2078 62.9867C24.2078 62.9867 27.4621 71.4856 25.8349 76.5553C24.2078 81.6251 12.9521 81.9344 12.9521 81.9344Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2022)">
        <path d="M21.1846 39.212H4.29002V64.7599C10.8877 66.6862 14.5868 66.7057 21.1846 64.7599V39.212Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M10.9267 1.01272L14.4768 10.9236C14.4768 10.9236 17.4488 18.3332 17.005 22.3272C16.5612 26.3211 18.0405 37.7247 19.2238 40.6832C19.8155 42.1624 21.0662 47.8642 22.1823 53.1894C23.2985 58.5147 24.2532 63.4769 24.2532 63.4769C24.2532 63.4769 27.5614 72.0699 25.9342 77.0993C24.307 82.1287 13.0514 82.4784 13.0514 82.4784C13.0514 82.4784 6.69066 83.3659 3.1405 81.5908C-0.409676 79.8157 1.51333 70.2948 1.51333 70.2948L3.58427 51.6564L6.09897 35.0621L7.87406 20.9959L8.31783 9.00058L9.35329 1.75232C9.35329 1.75232 10.6174 -0.00930011 10.9267 1.01272Z" fill="#DEE1E9"/>
      <path d="M3.13717 81.5839C6.68734 83.359 13.0481 82.4849 13.0481 82.4849C13.0481 82.4849 24.3037 82.1756 25.9309 77.1059C27.558 72.0361 24.3037 63.5372 24.3037 63.5372C24.3037 63.5372 23.3355 58.575 22.2328 53.2498L21.91 51.7033L17.4858 47.8439C16.3885 47.0864 15.1152 46.6233 13.7877 46.4991C12.076 46.3641 10.366 46.7787 8.90619 47.6825C8.90619 47.6825 3.7961 50.372 3.52715 52.3354L1.52345 70.3014C1.52345 70.3014 -0.426451 79.8088 3.13717 81.5839Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M11.5392 52.6783C10.2886 50.957 11.1089 48.402 11.082 39.5131C11.082 31.6463 10.3962 31.2159 10.9475 27.0337C11.351 23.9542 11.6145 22.1782 12.2331 17.3236C12.7307 13.4103 10.0743 2.86818 10.2357 2.89507C10.3971 2.92197 13.3816 15.9395 13.6908 22.206C13.9867 28.2037 14.1884 27.4909 14.4977 33.4617C14.9549 42.1354 14.4977 43.144 15.3987 46.6134C16.1383 49.424 16.9317 50.6477 16.5417 51.9925C16.365 52.4815 16.0578 52.9129 15.6535 53.24C15.2491 53.567 14.7631 53.7772 14.2478 53.8479C13.7326 53.9185 13.2079 53.8469 12.7305 53.6407C12.2531 53.4346 11.8411 53.1018 11.5392 52.6783Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M16.5851 18.7781C13.2867 17.1064 11.4449 17.1826 8.16601 18.7781" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M11.8327 3.3431L15.3828 13.254C15.3828 13.254 18.3548 20.6636 17.911 24.6576C17.4672 28.6515 18.9465 40.0551 20.1298 43.0136C20.7215 44.4928 21.9722 50.1946 23.0883 55.5198C24.2045 60.8451 25.1593 65.8073 25.1593 65.8073C25.1593 65.8073 28.4674 74.4003 26.8402 79.4297C25.213 84.4591 13.9574 84.8087 13.9574 84.8087C13.9574 84.8087 7.59667 85.6963 4.0465 83.9212C0.49633 82.1461 2.41934 72.6252 2.41934 72.6252L4.49027 53.9868L7.00498 37.3924L8.78006 23.3263L9.22383 11.331L10.2593 4.0827C10.2593 4.0827 11.5234 2.32108 11.8327 3.3431Z" fill="#DEE1E9"/>
      <mask id="mask2222" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="28" height="38">
        <path d="M3.60543 84.3107C7.1556 86.0858 13.5163 85.2117 13.5163 85.2117C13.5163 85.2117 24.772 84.9024 26.3991 79.8327C28.0263 74.7629 24.772 66.264 24.772 66.264C24.772 66.264 23.8037 61.3019 22.701 55.9766L22.3783 54.4301L17.954 50.5707C16.8568 49.8132 15.5834 49.3501 14.2559 49.2259C12.5443 49.0909 10.8343 49.5055 9.37445 50.4093C9.37445 50.4093 4.26436 53.0988 3.99541 55.0622L1.99171 73.0282C1.99171 73.0282 0.041811 82.5356 3.60543 84.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2222)">
        <path d="M3.60543 84.3107C7.1556 86.0858 13.5163 85.2117 13.5163 85.2117C13.5163 85.2117 24.772 84.9024 26.3991 79.8327C28.0263 74.7629 24.772 66.264 24.772 66.264C24.772 66.264 23.8037 61.3019 22.701 55.9766L22.3783 54.4301L17.954 50.5707C16.8568 49.8132 15.5834 49.3501 14.2559 49.2259C12.5443 49.0909 10.8343 49.5055 9.37445 50.4093C9.37445 50.4093 4.26436 53.0988 3.99541 55.0622L1.99171 73.0282C1.99171 73.0282 0.041811 82.5356 3.60543 84.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.3399 69.2009C15.3203 69.2009 16.8547 77.6026 16.8547 87.2009H14.9317H11.8547C11.8547 77.6026 13.3596 69.2009 14.3399 69.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.4889 68.676C9.92954 66.4813 10.9524 63.2236 10.9188 51.89C10.9188 41.8596 10.0637 41.3109 10.7512 35.9785C11.2542 32.052 11.5828 29.7875 12.3542 23.5977C12.9746 18.6082 10.6535 5.16664 10.8547 5.20093C11.056 5.23522 13.7861 21.833 14.1718 29.823C14.5407 37.4702 14.7922 36.5614 15.1779 44.1743C15.748 55.2335 15.1779 56.5195 16.3013 60.9431C17.2235 64.5267 18.2128 66.087 17.7266 67.8016C17.5061 68.4252 17.1231 68.9752 16.619 69.3922C16.1148 69.8092 15.5087 70.0772 14.8663 70.1673C14.2239 70.2573 13.5696 70.166 12.9743 69.9031C12.379 69.6403 11.8653 69.216 11.4889 68.676Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 11.1208 4.20099)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M11.8327 8.3431L15.3828 18.254C15.3828 18.254 18.3548 25.6636 17.911 29.6576C17.4672 33.6515 18.9465 45.0551 20.1298 48.0136C20.7215 49.4928 21.9722 55.1946 23.0883 60.5198C24.2045 65.8451 25.1593 70.8073 25.1593 70.8073C25.1593 70.8073 28.4674 79.4003 26.8402 84.4297C25.213 89.4591 13.9574 89.8087 13.9574 89.8087C13.9574 89.8087 7.59667 90.6963 4.0465 88.9212C0.49633 87.1461 2.41934 77.6252 2.41934 77.6252L4.49027 58.9868L7.00498 42.3924L8.78006 28.3263L9.22383 16.331L10.2593 9.0827C10.2593 9.0827 11.5234 7.32108 11.8327 8.3431Z" fill="#DEE1E9"/>
      <mask id="mask2322" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="28" height="38">
        <path d="M3.60543 89.3107C7.1556 91.0858 13.5163 90.2117 13.5163 90.2117C13.5163 90.2117 24.772 89.9024 26.3991 84.8327C28.0263 79.7629 24.772 71.264 24.772 71.264C24.772 71.264 23.8037 66.3019 22.701 60.9766L22.3783 59.4301L17.954 55.5707C16.8568 54.8132 15.5834 54.3501 14.2559 54.2259C12.5443 54.0909 10.8343 54.5055 9.37445 55.4093C9.37445 55.4093 4.26436 58.0988 3.99541 60.0622L1.99171 78.0282C1.99171 78.0282 0.041811 87.5356 3.60543 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2322)">
        <path d="M3.60543 89.3107C7.1556 91.0858 13.5163 90.2117 13.5163 90.2117C13.5163 90.2117 24.772 89.9024 26.3991 84.8327C28.0263 79.7629 24.772 71.264 24.772 71.264C24.772 71.264 23.8037 66.3019 22.701 60.9766L22.3783 59.4301L17.954 55.5707C16.8568 54.8132 15.5834 54.3501 14.2559 54.2259C12.5443 54.0909 10.8343 54.5055 9.37445 55.4093C9.37445 55.4093 4.26436 58.0988 3.99541 60.0622L1.99171 78.0282C1.99171 78.0282 0.041811 87.5356 3.60543 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.3399 74.2009C15.3203 74.2009 16.8547 82.6026 16.8547 92.2009H14.9317H11.8547C11.8547 82.6026 13.3596 74.2009 14.3399 74.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.4889 73.676C9.92954 71.4813 10.9524 68.2236 10.9188 56.89C10.9188 46.8596 10.0637 46.3109 10.7512 40.9785C11.2542 37.052 11.5828 34.7875 12.3542 28.5977C12.9746 23.6082 10.6535 10.1666 10.8547 10.2009C11.056 10.2352 13.7861 26.833 14.1718 34.823C14.5407 42.4702 14.7922 41.5614 15.1779 49.1743C15.748 60.2335 15.1779 61.5195 16.3013 65.9431C17.2235 69.5267 18.2128 71.087 17.7266 72.8016C17.5061 73.4252 17.1231 73.9752 16.619 74.3922C16.1148 74.8092 15.5087 75.0772 14.8663 75.1673C14.2239 75.2573 13.5696 75.166 12.9743 74.9031C12.379 74.6403 11.8653 74.216 11.4889 73.676Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 11.1208 9.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2322)">
        <path d="M10.1208 0.200989L9.29795 3.94009L6.26412 1.60472L8.03719 4.99799L4.212 5.1591L7.7514 6.61879L4.9247 9.20099L8.5743 8.04409L8.06873 11.8391L10.1208 8.60699L12.173 11.8391L11.6674 8.04409L15.317 9.20099L12.4903 6.61879L16.0297 5.1591L12.2045 4.99799L13.9776 1.60472L10.9437 3.94009L10.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2322" x="4.01201" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M11.8327 8.3431L15.3828 18.254C15.3828 18.254 18.3548 25.6636 17.911 29.6576C17.4672 33.6515 18.9465 45.0551 20.1298 48.0136C20.7215 49.4928 21.9722 55.1946 23.0883 60.5198C24.2045 65.8451 25.1593 70.8073 25.1593 70.8073C25.1593 70.8073 28.4674 79.4003 26.8402 84.4297C25.213 89.4591 13.9574 89.8087 13.9574 89.8087C13.9574 89.8087 7.59667 90.6963 4.0465 88.9212C0.49633 87.1461 2.41934 77.6252 2.41934 77.6252L4.49027 58.9868L7.00498 42.3924L8.78006 28.3263L9.22383 16.331L10.2593 9.0827C10.2593 9.0827 11.5234 7.32108 11.8327 8.3431Z" fill="#DEE1E9"/>
      <mask id="mask2322" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="28" height="38">
        <path d="M3.60543 89.3107C7.1556 91.0858 13.5163 90.2117 13.5163 90.2117C13.5163 90.2117 24.772 89.9024 26.3991 84.8327C28.0263 79.7629 24.772 71.264 24.772 71.264C24.772 71.264 23.8037 66.3019 22.701 60.9766L22.3783 59.4301L17.954 55.5707C16.8568 54.8132 15.5834 54.3501 14.2559 54.2259C12.5443 54.0909 10.8343 54.5055 9.37445 55.4093C9.37445 55.4093 4.26436 58.0988 3.99541 60.0622L1.99171 78.0282C1.99171 78.0282 0.041811 87.5356 3.60543 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2322)">
        <path d="M3.60543 89.3107C7.1556 91.0858 13.5163 90.2117 13.5163 90.2117C13.5163 90.2117 24.772 89.9024 26.3991 84.8327C28.0263 79.7629 24.772 71.264 24.772 71.264C24.772 71.264 23.8037 66.3019 22.701 60.9766L22.3783 59.4301L17.954 55.5707C16.8568 54.8132 15.5834 54.3501 14.2559 54.2259C12.5443 54.0909 10.8343 54.5055 9.37445 55.4093C9.37445 55.4093 4.26436 58.0988 3.99541 60.0622L1.99171 78.0282C1.99171 78.0282 0.041811 87.5356 3.60543 89.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.3399 74.2009C15.3203 74.2009 16.8547 82.6026 16.8547 92.2009H14.9317H11.8547C11.8547 82.6026 13.3596 74.2009 14.3399 74.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.4889 73.676C9.92954 71.4813 10.9524 68.2236 10.9188 56.89C10.9188 46.8596 10.0637 46.3109 10.7512 40.9785C11.2542 37.052 11.5828 34.7875 12.3542 28.5977C12.9746 23.6082 10.6535 10.1666 10.8547 10.2009C11.056 10.2352 13.7861 26.833 14.1718 34.823C14.5407 42.4702 14.7922 41.5614 15.1779 49.1743C15.748 60.2335 15.1779 61.5195 16.3013 65.9431C17.2235 69.5267 18.2128 71.087 17.7266 72.8016C17.5061 73.4252 17.1231 73.9752 16.619 74.3922C16.1148 74.8092 15.5087 75.0772 14.8663 75.1673C14.2239 75.2573 13.5696 75.166 12.9743 74.9031C12.379 74.6403 11.8653 74.216 11.4889 73.676Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 11.1208 9.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2322)">
        <path d="M10.1208 0.200989L9.29795 3.94009L6.26412 1.60472L8.03719 4.99799L4.212 5.1591L7.7514 6.61879L4.9247 9.20099L8.5743 8.04409L8.06873 11.8391L10.1208 8.60699L12.173 11.8391L11.6674 8.04409L15.317 9.20099L12.4903 6.61879L16.0297 5.1591L12.2045 4.99799L13.9776 1.60472L10.9437 3.94009L10.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2322" x="4.01201" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M11.8327 5.34304L15.3828 15.2539C15.3828 15.2539 18.3548 22.6636 17.911 26.6575C17.4672 30.6514 18.9465 42.055 20.1298 45.0135C20.7215 46.4927 21.9722 52.1945 23.0883 57.5198C24.2045 62.845 25.1593 67.8072 25.1593 67.8072C25.1593 67.8072 28.4674 76.4002 26.8402 81.4296C25.213 86.459 13.9574 86.8087 13.9574 86.8087C13.9574 86.8087 7.59667 87.6962 4.0465 85.9211C0.49633 84.146 2.41934 74.6251 2.41934 74.6251L4.49027 55.9867L7.00498 39.3924L8.78006 25.3262L9.22383 13.3309L10.2593 6.08264C10.2593 6.08264 11.5234 4.32102 11.8327 5.34304Z" fill="#DEE1E9"/>
      <circle r="4" transform="matrix(-1 0 0 1 11.1208 4.20093)" fill="rgba(243, 42, 102, 0.6)"/>
      <mask id="mask2522" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="28" height="38">
        <path d="M3.60543 86.3107C7.1556 88.0858 13.5163 87.2117 13.5163 87.2117C13.5163 87.2117 24.772 86.9024 26.3991 81.8327C28.0263 76.7629 24.772 68.264 24.772 68.264C24.772 68.264 23.8037 63.3019 22.701 57.9766L22.3783 56.4301L17.954 52.5707C16.8568 51.8132 15.5834 51.3501 14.2559 51.2259C12.5443 51.0909 10.8343 51.5055 9.37445 52.4093C9.37445 52.4093 4.26436 55.0988 3.99541 57.0622L1.99171 75.0282C1.99171 75.0282 0.041811 84.5356 3.60543 86.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2522)">
        <path d="M3.60543 86.3107C7.1556 88.0858 13.5163 87.2117 13.5163 87.2117C13.5163 87.2117 24.772 86.9024 26.3991 81.8327C28.0263 76.7629 24.772 68.264 24.772 68.264C24.772 68.264 23.8037 63.3019 22.701 57.9766L22.3783 56.4301L17.954 52.5707C16.8568 51.8132 15.5834 51.3501 14.2559 51.2259C12.5443 51.0909 10.8343 51.5055 9.37445 52.4093C9.37445 52.4093 4.26436 55.0988 3.99541 57.0622L1.99171 75.0282C1.99171 75.0282 0.041811 84.5356 3.60543 86.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M14.6061 78.2009C15.5864 78.2009 17.1208 83.3353 17.1208 89.2009H15.1978H12.1208C12.1208 83.3353 13.6257 78.2009 14.6061 78.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M11.2118 76.5587C9.51934 74.1952 10.6295 70.6869 10.5931 58.4815C10.5931 47.6795 9.66493 47.0886 10.4111 41.346C10.957 37.1175 11.3137 34.6788 12.1509 28.0129C12.8242 22.6396 10.3051 8.16406 10.5235 8.20099C10.7419 8.23792 13.705 26.1124 14.1236 34.7171C14.524 42.9525 14.797 41.9738 15.2155 50.1723C15.8343 62.0822 15.2155 63.467 16.4349 68.231C17.4358 72.0902 18.5095 73.7705 17.9817 75.617C17.7425 76.2885 17.3268 76.8809 16.7796 77.33C16.2324 77.779 15.5746 78.0677 14.8774 78.1646C14.1802 78.2616 13.47 78.1633 12.8239 77.8802C12.1778 77.5972 11.6203 77.1402 11.2118 76.5587Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2622" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="84">
        <path d="M11.3358 0.88015L14.886 10.791C14.886 10.791 17.8579 18.2007 17.4142 22.1946C16.9704 26.1885 18.4496 37.5921 19.633 40.5506C20.2247 42.0298 21.4753 47.7316 22.5915 53.0569C23.7076 58.3821 24.6624 63.3443 24.6624 63.3443C24.6624 63.3443 27.9705 71.9373 26.3434 76.9667C24.7162 81.9962 13.4606 82.3458 13.4606 82.3458C13.4606 82.3458 7.09984 83.2333 3.54967 81.4582C-0.000495911 79.6832 1.92251 70.1622 1.92251 70.1622L3.99345 51.5238L6.50815 34.9295L8.28324 20.8633L8.72701 8.86801L9.76247 1.61975C9.76247 1.61975 11.0265 -0.141868 11.3358 0.88015Z" fill="#DEE1E9"/>
        <path d="M3.54635 81.4515C7.09652 83.2265 13.4572 82.3525 13.4572 82.3525C13.4572 82.3525 24.7129 82.0432 26.34 76.9734C27.9672 71.9037 24.7129 63.4048 24.7129 63.4048C24.7129 63.4048 23.7447 58.4426 22.642 53.1173L22.3192 51.5709L17.8949 47.7114C16.7977 46.9539 15.5244 46.4909 14.1969 46.3666C12.4852 46.2317 10.7752 46.6462 9.31537 47.55C9.31537 47.55 4.20528 50.2396 3.93633 52.2029L1.93263 70.1689C1.93263 70.1689 -0.017271 79.6764 3.54635 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2622)">
        <path d="M11.3358 0.88015L14.886 10.791C14.886 10.791 17.8579 18.2007 17.4142 22.1946C16.9704 26.1885 18.4496 37.5921 19.633 40.5506C20.2247 42.0298 21.4753 47.7316 22.5915 53.0569C23.7076 58.3821 24.6624 63.3443 24.6624 63.3443C24.6624 63.3443 27.9705 71.9373 26.3434 76.9667C24.7162 81.9962 13.4606 82.3458 13.4606 82.3458C13.4606 82.3458 7.09984 83.2333 3.54967 81.4582C-0.000495911 79.6832 1.92251 70.1622 1.92251 70.1622L3.99345 51.5238L6.50815 34.9295L8.28324 20.8633L8.72701 8.86801L9.76247 1.61975C9.76247 1.61975 11.0265 -0.141868 11.3358 0.88015Z" fill="#DEE1E9"/>
        <path d="M3.54635 81.4515C7.09652 83.2265 13.4572 82.3525 13.4572 82.3525C13.4572 82.3525 24.7129 82.0432 26.34 76.9734C27.9672 71.9037 24.7129 63.4048 24.7129 63.4048C24.7129 63.4048 23.7447 58.4426 22.642 53.1173L22.3192 51.5709L17.8949 47.7114C16.7977 46.9539 15.5244 46.4909 14.1969 46.3666C12.4852 46.2317 10.7752 46.6462 9.31537 47.55C9.31537 47.55 4.20528 50.2396 3.93633 52.2029L1.93263 70.1689C1.93263 70.1689 -0.017271 79.6764 3.54635 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M12.5978 50.5249H0.676672C2.83873 45.155 3.19573 41.8948 2.8992 36.1565C2.88802 35.9401 3.13739 35.8056 3.32761 35.9321C4.33169 36.6001 6.68988 38.4162 7.26153 41.0579C7.954 44.2578 9.9572 45.0349 10.1698 45.109C10.1828 45.1135 10.1944 45.118 10.2067 45.1237L11.3897 45.6704C11.5342 45.7372 11.5928 45.8997 11.5206 46.0333L10.9916 47.011C10.9469 47.0937 10.951 47.1918 11.0024 47.2711L12.8488 50.1152C12.9658 50.2955 12.8253 50.5249 12.5978 50.5249Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.67275 37.0461C4.73373 37.8939 6.14171 39.3308 6.54413 41.1904C7.28734 44.6249 9.46331 45.581 9.89628 45.7363L10.6699 46.0937L10.3373 46.7085C10.1806 46.998 10.1949 47.3414 10.3751 47.619L11.8225 49.8486H1.71999C3.49672 45.184 3.87272 41.9882 3.67275 37.0461ZM9.87957 45.7285C9.87942 45.7285 9.87971 45.7286 9.87957 45.7285V45.7285ZM3.75508 35.3833C3.02622 34.8984 2.13049 35.4548 2.16842 36.1887C2.46123 41.8549 2.11202 45.0211 -0.00900269 50.289L-0.376222 51.201H12.5979C13.3941 51.201 13.8859 50.3983 13.4762 49.7673L11.7539 47.1142L12.175 46.3357C12.428 45.868 12.2229 45.2994 11.7169 45.0655L10.534 44.5189C10.4933 44.5001 10.4574 44.4865 10.4279 44.4762C10.3913 44.4635 8.61049 43.8434 7.97901 40.9252C7.34951 38.0162 4.78613 36.0692 3.75508 35.3833Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask122" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="84">
        <path d="M11.3358 0.88015L14.886 10.791C14.886 10.791 17.8579 18.2007 17.4142 22.1946C16.9704 26.1885 18.4496 37.5921 19.633 40.5506C20.2247 42.0298 21.4753 47.7316 22.5915 53.0569C23.7076 58.3821 24.6624 63.3443 24.6624 63.3443C24.6624 63.3443 27.9705 71.9373 26.3434 76.9667C24.7162 81.9962 13.4606 82.3458 13.4606 82.3458C13.4606 82.3458 7.09984 83.2333 3.54967 81.4582C-0.000495911 79.6832 1.92251 70.1622 1.92251 70.1622L3.99345 51.5238L6.50815 34.9295L8.28324 20.8633L8.72701 8.86801L9.76247 1.61975C9.76247 1.61975 11.0265 -0.141868 11.3358 0.88015Z" fill="#DEE1E9"/>
        <path d="M3.54635 81.4515C7.09652 83.2265 13.4572 82.3525 13.4572 82.3525C13.4572 82.3525 24.7129 82.0432 26.34 76.9734C27.9672 71.9037 24.7129 63.4048 24.7129 63.4048C24.7129 63.4048 23.7447 58.4426 22.642 53.1173L22.3192 51.5709L17.8949 47.7114C16.7977 46.9539 15.5244 46.4909 14.1969 46.3666C12.4852 46.2317 10.7752 46.6462 9.31537 47.55C9.31537 47.55 4.20528 50.2396 3.93633 52.2029L1.93263 70.1689C1.93263 70.1689 -0.017271 79.6764 3.54635 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask122)">
        <path d="M11.3358 0.88015L14.886 10.791C14.886 10.791 17.8579 18.2007 17.4142 22.1946C16.9704 26.1885 18.4496 37.5921 19.633 40.5506C20.2247 42.0298 21.4753 47.7316 22.5915 53.0569C23.7076 58.3821 24.6624 63.3443 24.6624 63.3443C24.6624 63.3443 27.9705 71.9373 26.3434 76.9667C24.7162 81.9962 13.4606 82.3458 13.4606 82.3458C13.4606 82.3458 7.09984 83.2333 3.54967 81.4582C-0.000495911 79.6832 1.92251 70.1622 1.92251 70.1622L3.99345 51.5238L6.50815 34.9295L8.28324 20.8633L8.72701 8.86801L9.76247 1.61975C9.76247 1.61975 11.0265 -0.141868 11.3358 0.88015Z" fill="#DEE1E9"/>
        <path d="M3.54635 81.4515C7.09652 83.2265 13.4572 82.3525 13.4572 82.3525C13.4572 82.3525 24.7129 82.0432 26.34 76.9734C27.9672 71.9037 24.7129 63.4048 24.7129 63.4048C24.7129 63.4048 23.7447 58.4426 22.642 53.1173L22.3192 51.5709L17.8949 47.7114C16.7977 46.9539 15.5244 46.4909 14.1969 46.3666C12.4852 46.2317 10.7752 46.6462 9.31537 47.55C9.31537 47.55 4.20528 50.2396 3.93633 52.2029L1.93263 70.1689C1.93263 70.1689 -0.017271 79.6764 3.54635 81.4515Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M10.9227 51.5315H2.77592C4.25343 47.561 4.49741 45.1504 4.29476 40.9074C4.28712 40.7474 4.45754 40.6479 4.58753 40.7415C5.2737 41.2354 6.88526 42.5782 7.27592 44.5315C7.74913 46.8976 9.1181 47.4722 9.26339 47.527C9.27224 47.5303 9.28016 47.5337 9.28863 47.5379L10.097 47.9421C10.1958 47.9915 10.2359 48.1116 10.1865 48.2104L9.82501 48.9333C9.79443 48.9945 9.79722 49.067 9.8324 49.1257L11.0942 51.2286C11.1742 51.3619 11.0781 51.5315 10.9227 51.5315Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M4.82339 41.5653C5.54845 42.1922 6.51064 43.2546 6.78565 44.6297C7.29354 47.1692 8.78057 47.8761 9.07646 47.9909L9.60512 48.2552L9.37782 48.7098C9.27079 48.9239 9.28054 49.1778 9.40368 49.383L10.3928 51.0316H3.4889C4.70309 47.5825 4.96005 45.2195 4.82339 41.5653ZM9.06504 47.9852C9.06494 47.9851 9.06514 47.9852 9.06504 47.9852V47.9852ZM4.87965 40.3358C4.38156 39.9773 3.76943 40.3887 3.79535 40.9313C3.99545 45.121 3.75681 47.4621 2.30733 51.3572L2.05638 52.0316H10.9227C11.4668 52.0316 11.8029 51.438 11.5229 50.9715L10.3459 49.0097L10.6337 48.4341C10.8066 48.0883 10.6664 47.6679 10.3207 47.495L9.51226 47.0908C9.48443 47.0768 9.45993 47.0668 9.43976 47.0592C9.41475 47.0498 8.19777 46.5913 7.76623 44.4336C7.33604 42.2826 5.58425 40.843 4.87965 40.3358Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M11.0988 0.343163L14.649 10.254C14.649 10.254 17.6209 17.6637 17.1771 21.6576C16.7333 25.6516 18.2126 37.0551 19.396 40.0136C19.9877 41.4929 21.2383 47.1946 22.3544 52.5199C23.4706 57.8451 24.4254 62.8073 24.4254 62.8073C24.4254 62.8073 27.7335 71.4004 26.1063 76.4298C24.4792 81.4592 13.2235 81.8088 13.2235 81.8088C13.2235 81.8088 6.86278 82.6963 3.31261 80.9213C-0.237556 79.1462 1.68545 69.6253 1.68545 69.6253L3.75639 50.9869L6.27109 34.3925L8.04618 20.3263L8.48995 8.33103L9.52541 1.08277C9.52541 1.08277 10.7895 -0.678856 11.0988 0.343163Z" fill="#DEE1E9"/>
      <mask id="mask222" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="27" height="38">
        <path d="M2.87154 81.3107C6.42171 83.0858 12.7824 82.2117 12.7824 82.2117C12.7824 82.2117 24.0381 81.9024 25.6652 76.8327C27.2924 71.7629 24.0381 63.264 24.0381 63.264C24.0381 63.264 23.0699 58.3019 21.9672 52.9766L21.6444 51.4301L17.2201 47.5707C16.1229 46.8132 14.8496 46.3501 13.522 46.2259C11.8104 46.0909 10.1004 46.5055 8.64057 47.4093C8.64057 47.4093 3.53047 50.0988 3.26152 52.0622L1.25783 70.0282C1.25783 70.0282 -0.692076 79.5356 2.87154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask222)">
        <path d="M2.87154 81.3107C6.42171 83.0858 12.7824 82.2117 12.7824 82.2117C12.7824 82.2117 24.0381 81.9024 25.6652 76.8327C27.2924 71.7629 24.0381 63.264 24.0381 63.264C24.0381 63.264 23.0699 58.3019 21.9672 52.9766L21.6444 51.4301L17.2201 47.5707C16.1229 46.8132 14.8496 46.3501 13.522 46.2259C11.8104 46.0909 10.1004 46.5055 8.64057 47.4093C8.64057 47.4093 3.53047 50.0988 3.26152 52.0622L1.25783 70.0282C1.25783 70.0282 -0.692076 79.5356 2.87154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.6001 66.2009C14.9727 66.2009 17.1208 74.6026 17.1208 84.2009H14.4285H10.1208C10.1208 74.6026 12.2276 66.2009 13.6001 66.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M10.7551 65.6761C9.19565 63.4814 10.2185 60.2236 10.185 48.8901C10.185 38.8596 9.32979 38.311 10.0173 32.9785C10.5203 29.0521 10.849 26.7875 11.6203 20.5978C12.2407 15.6083 9.91964 2.1667 10.1208 2.20099C10.3221 2.23528 13.0522 18.833 13.4379 26.8231C13.8068 34.4702 14.0583 33.5615 14.444 41.1744C15.0141 52.2336 14.444 53.5195 15.5674 57.9432C16.4896 61.5267 17.4789 63.087 16.9927 64.8016C16.7723 65.4252 16.3892 65.9753 15.8851 66.3922C15.3809 66.8092 14.7748 67.0773 14.1324 67.1673C13.49 67.2574 12.8357 67.1661 12.2404 66.9032C11.6451 66.6404 11.1315 66.216 10.7551 65.6761Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M11.0988 0.343041L14.649 10.2539C14.649 10.2539 17.6209 17.6636 17.1771 21.6575C16.7333 25.6514 18.2126 37.055 19.396 40.0135C19.9877 41.4927 21.2383 47.1945 22.3544 52.5198C23.4706 57.845 24.4254 62.8072 24.4254 62.8072C24.4254 62.8072 27.7335 71.4002 26.1063 76.4296C24.4792 81.459 13.2235 81.8087 13.2235 81.8087C13.2235 81.8087 6.86278 82.6962 3.31261 80.9211C-0.237556 79.146 1.68545 69.6251 1.68545 69.6251L3.75639 50.9867L6.27109 34.3924L8.04618 20.3262L8.48995 8.3309L9.52541 1.08264C9.52541 1.08264 10.7895 -0.678978 11.0988 0.343041Z" fill="#DEE1E9"/>
      <mask id="mask322" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="45" width="27" height="38">
        <path d="M2.87154 81.3107C6.42171 83.0858 12.7824 82.2117 12.7824 82.2117C12.7824 82.2117 24.0381 81.9024 25.6652 76.8327C27.2924 71.7629 24.0381 63.264 24.0381 63.264C24.0381 63.264 23.0699 58.3019 21.9672 52.9766L21.6444 51.4301L17.2201 47.5707C16.1229 46.8132 14.8496 46.3501 13.522 46.2259C11.8104 46.0909 10.1004 46.5055 8.64057 47.4093C8.64057 47.4093 3.53047 50.0988 3.26152 52.0622L1.25783 70.0282C1.25783 70.0282 -0.692076 79.5356 2.87154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask322)">
        <path d="M2.87154 81.3107C6.42171 83.0858 12.7824 82.2117 12.7824 82.2117C12.7824 82.2117 24.0381 81.9024 25.6652 76.8327C27.2924 71.7629 24.0381 63.264 24.0381 63.264C24.0381 63.264 23.0699 58.3019 21.9672 52.9766L21.6444 51.4301L17.2201 47.5707C16.1229 46.8132 14.8496 46.3501 13.522 46.2259C11.8104 46.0909 10.1004 46.5055 8.64057 47.4093C8.64057 47.4093 3.53047 50.0988 3.26152 52.0622L1.25783 70.0282C1.25783 70.0282 -0.692076 79.5356 2.87154 81.3107Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.6061 72.2009C14.5864 72.2009 16.1208 78.2688 16.1208 85.2009H14.1978H11.1208C11.1208 78.2688 12.6257 72.2009 13.6061 72.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M10.602 0.88015L14.1521 10.791C14.1521 10.791 17.124 18.2007 16.6803 22.1946C16.2365 26.1885 17.7157 37.5921 18.8991 40.5506C19.4908 42.0298 20.7415 47.7316 21.8576 53.0569C22.9738 58.3821 23.9285 63.3443 23.9285 63.3443C23.9285 63.3443 27.2367 71.9373 25.6095 76.9667C23.9823 81.9962 12.7267 82.3458 12.7267 82.3458C12.7267 82.3458 6.36596 83.2333 2.81579 81.4582C-0.734383 79.6832 1.18863 70.1622 1.18863 70.1622L3.25956 51.5238L5.77426 34.9295L7.54935 20.8633L7.99312 8.86801L9.02859 1.61975C9.02859 1.61975 10.2927 -0.141868 10.602 0.88015Z" fill="#DEE1E9"/>
      <path d="M2.81246 81.4516C6.36263 83.2267 12.7234 82.3526 12.7234 82.3526C12.7234 82.3526 23.979 82.0433 25.6062 76.9735C27.2333 71.9038 23.979 63.4049 23.979 63.4049C23.979 63.4049 23.0108 58.4427 21.9081 53.1175L21.5853 51.571L17.1611 47.7115C16.0638 46.954 14.7905 46.491 13.463 46.3668C11.7513 46.2318 10.0413 46.6464 8.58148 47.5502C8.58148 47.5502 3.47139 50.2397 3.20244 52.203L1.19875 70.169C1.19875 70.169 -0.751158 79.6765 2.81246 81.4516Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M16.6238 67.0681L14.7571 69.569L13.1238 68.0733V71.3873L10.5616 70.8194L11.9571 72.57L9.62378 74.5707L11.9571 76.5714L10.5616 78.322L12.8904 77.5717L13.1238 81.0681L15.3737 79.5711L16.1571 80.5728L17.8739 79.5711L20.1238 81.0681L20.8238 79.0722L22.686 78.322L22.2238 75.571L23.6238 74.5707L21.2904 73.0702L22.686 70.8194L20.8238 70.5693L20.1238 68.0733L17.0904 70.0691L16.6238 67.0681Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M10.602 0.88015L14.1521 10.791C14.1521 10.791 17.124 18.2007 16.6803 22.1946C16.2365 26.1885 17.7157 37.5921 18.8991 40.5506C19.4908 42.0298 20.7415 47.7316 21.8576 53.0569C22.9738 58.3821 23.9285 63.3443 23.9285 63.3443C23.9285 63.3443 27.2367 71.9373 25.6095 76.9667C23.9823 81.9962 12.7267 82.3458 12.7267 82.3458C12.7267 82.3458 6.36596 83.2333 2.81579 81.4582C-0.734383 79.6832 1.18863 70.1622 1.18863 70.1622L3.25956 51.5238L5.77426 34.9295L7.54935 20.8633L7.99312 8.86801L9.02859 1.61975C9.02859 1.61975 10.2927 -0.141868 10.602 0.88015Z" fill="#DEE1E9"/>
      <path d="M2.81246 81.4516C6.36263 83.2267 12.7234 82.3526 12.7234 82.3526C12.7234 82.3526 23.979 82.0433 25.6062 76.9735C27.2333 71.9038 23.979 63.4049 23.979 63.4049C23.979 63.4049 23.0108 58.4427 21.9081 53.1175L21.5853 51.571L17.1611 47.7115C16.0638 46.954 14.7905 46.491 13.463 46.3668C11.7513 46.2318 10.0413 46.6464 8.58148 47.5502C8.58148 47.5502 3.47139 50.2397 3.20244 52.203L1.19875 70.169C1.19875 70.169 -0.751158 79.6765 2.81246 81.4516Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M18.6238 68.0681L17.0238 70.2117L15.6238 68.9297V71.7703L13.4276 71.2835L14.6238 72.784L12.6238 74.4989L14.6238 76.2138L13.4276 77.7143L15.4238 77.0712L15.6238 80.0681L17.5523 78.7849L18.2238 79.6435L19.6953 78.7849L21.6238 80.0681L22.2238 78.3574L23.8199 77.7143L23.4238 75.3563L24.6238 74.4989L22.6238 73.2127L23.8199 71.2835L22.2238 71.0691L21.6238 68.9297L19.0238 70.6404L18.6238 68.0681Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M11.3358 0.945336L14.886 10.8562C14.886 10.8562 17.8579 18.2659 17.4142 22.2598C16.9704 26.2537 18.4496 37.6573 19.633 40.6158C20.2247 42.095 21.4753 47.7968 22.5915 53.1221C23.7076 58.4473 24.6624 63.4095 24.6624 63.4095C24.6624 63.4095 27.9705 72.0025 26.3434 77.0319C24.7162 82.0613 13.4606 82.411 13.4606 82.411C13.4606 82.411 7.09984 83.2985 3.54967 81.5234C-0.000495911 79.7483 1.92251 70.2274 1.92251 70.2274L3.99345 51.589L6.50815 34.9947L8.28324 20.9285L8.72701 8.9332L9.76247 1.68494C9.76247 1.68494 11.0265 -0.0766829 11.3358 0.945336Z" fill="#DEE1E9"/>
      <path d="M3.54635 81.5165C7.09652 83.2916 13.4572 82.4175 13.4572 82.4175C13.4572 82.4175 24.7129 82.1082 26.34 77.0385C27.9672 71.9687 24.7129 63.4698 24.7129 63.4698C24.7129 63.4698 23.7447 58.5077 22.642 53.1824L22.3192 51.6359L17.8949 47.7765C16.7977 47.019 15.5244 46.5559 14.1969 46.4317C12.4852 46.2968 10.7752 46.7113 9.31537 47.6151C9.31537 47.6151 4.20528 50.3046 3.93633 52.268L1.93263 70.234C1.93263 70.234 -0.017271 79.7414 3.54635 81.5165Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M20.8577 73.1333L19.9243 74.3837L19.1077 73.6359V75.2929L17.8266 75.0089L18.5243 75.8842L17.3577 76.8846L18.5243 77.8849L17.8266 78.7602L18.991 78.3851L19.1077 80.1333L20.2326 79.3848L20.6243 79.8856L21.4827 79.3848L22.6077 80.1333L22.9577 79.1354L23.8888 78.7602L23.6577 77.3848L24.3577 76.8846L23.191 76.1343L23.8888 75.0089L22.9577 74.8839L22.6077 73.6359L21.091 74.6338L20.8577 73.1333Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M16.6764 2.68065L14.5927 1.41089H11.109L9.009 2.68065L10.6206 4.22716L8.5369 5.77367L10.4253 7.32018L8.12993 9.0132L9.95318 10.5597L7.65784 11.9597L9.67644 13.376L7.65784 14.9876L9.35086 16.5341L7.12063 17.9504L8.87876 19.4155L6.58342 20.962L8.73225 22.5085L6.58342 24.1201L8.12993 25.4713L6.04621 27.0178L7.86946 28.4992L5.78575 30.0457L7.59272 31.7876L6.58342 33.4643V47.7247L8.47179 55.0503H14.5927" fill="#8889BE"/>
      <path d="M16.6764 2.68065L14.5927 1.41089H11.109L9.009 2.68065L10.6206 4.22716L8.5369 5.77367L10.4253 7.32018L8.12993 9.0132L9.95318 10.5597L7.65784 11.9597L9.67644 13.376L7.65784 14.9876L9.35086 16.5341L7.12063 17.9504L8.87876 19.4155L6.58342 20.962L8.73225 22.5085L6.58342 24.1201L8.12993 25.4713L6.04621 27.0178L7.86946 28.4992L5.78575 30.0457L7.59272 31.7876L6.58342 33.4643V47.7247L8.47179 55.0503H14.5927" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M9.26915 2.68065L11.3529 1.41089H14.8203L16.9366 2.68065L15.3087 4.22716L17.4087 5.77367L15.5203 7.32018L17.7994 9.0132L15.9924 10.5597L18.2715 11.9597L16.2529 13.376L18.2715 14.9876L16.5947 16.5341L18.8087 17.9504L17.0668 19.4155L19.3459 20.962L17.197 22.5085L19.3459 24.1201L17.7994 25.4713L19.8831 27.0178L18.0761 28.4992L20.1598 30.0457L18.3366 31.7876L19.3459 33.4643V47.7247L17.4738 55.0503H13.5668" fill="#8889BE"/>
      <path d="M9.26915 2.68065L11.3529 1.41089H14.8203L16.9366 2.68065L15.3087 4.22716L17.4087 5.77367L15.5203 7.32018L17.7994 9.0132L15.9924 10.5597L18.2715 11.9597L16.2529 13.376L18.2715 14.9876L16.5947 16.5341L18.8087 17.9504L17.0668 19.4155L19.3459 20.962L17.197 22.5085L19.3459 24.1201L17.7994 25.4713L19.8831 27.0178L18.0761 28.4992L20.1598 30.0457L18.3366 31.7876L19.3459 33.4643V47.7247L17.4738 55.0503H13.5668" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M13.2205 74.5623C13.2205 74.5623 6.85973 75.4364 3.30956 73.6614C-0.254057 71.8863 1.69585 62.3788 1.69585 62.3788L3.96302 43.7808H6.74912C7.3014 43.7808 7.74912 44.2285 7.74912 44.7808V53.6186C11.9447 54.8123 14.2977 54.6972 18.4935 53.6186V44.7808C18.4935 44.2285 18.9412 43.7808 19.4935 43.7808H22.0824L22.4052 45.3272C23.5079 50.6525 24.4761 55.6147 24.4761 55.6147C24.4761 55.6147 27.7304 64.1136 26.1033 69.1833C24.4761 74.2531 13.2205 74.5623 13.2205 74.5623Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask522" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="44" width="28" height="32">
        <path d="M13.2183 74.6297C13.2183 74.6297 6.85753 75.5038 3.30736 73.7287C-0.256254 71.9536 1.69365 62.4461 1.69365 62.4461L3.96082 44.5586H6.74692C7.29921 44.5586 7.74692 45.0063 7.74692 45.5586V53.686C11.9425 54.8796 14.2955 54.7645 18.4913 53.686V45.5586C18.4913 45.0063 18.939 44.5586 19.4913 44.5586H22.0802L22.403 45.3946C23.5057 50.7198 24.4739 55.682 24.4739 55.682C24.4739 55.682 27.7282 64.1809 26.1011 69.2506C24.4739 74.3204 13.2183 74.6297 13.2183 74.6297Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask522)">
        <path d="M21.4507 31.9072H4.55617V57.4552C11.1539 59.3814 14.853 59.401 21.4507 57.4552V31.9072Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M16.662 2.03344L14.5783 0.763672H11.0946L8.99459 2.03344L10.6062 3.57995L8.5225 5.12645L10.4109 6.67296L8.11552 8.36598L9.93878 9.91249L7.64343 11.3125L9.66203 12.7288L7.64343 14.3404L9.33645 15.8869L7.10622 17.3032L8.86436 18.7683L6.56902 20.3148L8.71785 21.8613L6.56902 23.4729L8.11552 24.8241L6.03181 26.3706L7.85506 27.852L5.77134 29.3985L7.57832 31.1404L6.56902 32.8171V47.0775L8.45738 54.4031H14.5783" fill="#8889BE"/>
      <path d="M16.662 2.03344L14.5783 0.763672H11.0946L8.99459 2.03344L10.6062 3.57995L8.5225 5.12645L10.4109 6.67296L8.11552 8.36598L9.93878 9.91249L7.64343 11.3125L9.66203 12.7288L7.64343 14.3404L9.33645 15.8869L7.10622 17.3032L8.86436 18.7683L6.56902 20.3148L8.71785 21.8613L6.56902 23.4729L8.11552 24.8241L6.03181 26.3706L7.85506 27.852L5.77134 29.3985L7.57832 31.1404L6.56902 32.8171V47.0775L8.45738 54.4031H14.5783" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M9.25474 2.03344L11.3385 0.763672H14.8059L16.9222 2.03344L15.2943 3.57995L17.3943 5.12645L15.5059 6.67296L17.785 8.36598L15.978 9.91249L18.257 11.3125L16.2384 12.7288L18.257 14.3404L16.5803 15.8869L18.7943 17.3032L17.0524 18.7683L19.3315 20.3148L17.1826 21.8613L19.3315 23.4729L17.785 24.8241L19.8687 26.3706L18.0617 27.852L20.1454 29.3985L18.3222 31.1404L19.3315 32.8171V47.0775L17.4594 54.4031H13.5524" fill="#8889BE"/>
      <path d="M9.25474 2.03344L11.3385 0.763672H14.8059L16.9222 2.03344L15.2943 3.57995L17.3943 5.12645L15.5059 6.67296L17.785 8.36598L15.978 9.91249L18.257 11.3125L16.2384 12.7288L18.257 14.3404L16.5803 15.8869L18.7943 17.3032L17.0524 18.7683L19.3315 20.3148L17.1826 21.8613L19.3315 23.4729L17.785 24.8241L19.8687 26.3706L18.0617 27.852L20.1454 29.3985L18.3222 31.1404L19.3315 32.8171V47.0775L17.4594 54.4031H13.5524" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M12.9316 73.0023C12.9316 73.0023 6.57085 73.8764 3.02068 72.1013C-0.542936 70.3262 1.40697 60.8188 1.40697 60.8188L3.67414 42.2207H7.444C7.99628 42.2207 8.444 42.6684 8.444 43.2207V52.0586C12.6396 53.2522 13.4858 53.1371 17.6815 52.0586V43.2207C17.6815 42.6684 18.1292 42.2207 18.6815 42.2207H21.7935L22.1163 43.7672C23.219 49.0924 24.1872 54.0546 24.1872 54.0546C24.1872 54.0546 27.4415 62.5535 25.8144 67.6232C24.1872 72.693 12.9316 73.0023 12.9316 73.0023Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M43.8093 79.771H22.8093V84.771H43.8093V79.771Z" fill="#838AA2"/>
      <path d="M21.8093 79.771H0.809326V84.771H21.8093V79.771Z" fill="#838AA2"/>
      <path d="M20.3495 0.583519L23.8997 10.4944C23.8997 10.4944 26.8716 17.904 26.4278 21.898C25.9841 25.8919 27.4633 37.2955 28.6467 40.254C29.2384 41.7332 30.489 47.435 31.6052 52.7602C32.7213 58.0855 33.6761 63.0477 33.6761 63.0477C33.6761 63.0477 36.9842 71.6407 35.3571 76.6701C33.7299 81.6995 22.4742 82.0492 22.4742 82.0492C22.4742 82.0492 16.1135 82.9367 12.5633 81.1616C9.01318 79.3865 10.9362 69.8656 10.9362 69.8656L13.0071 51.2272L15.5218 34.6329L17.2969 20.5667L17.7407 8.57138L18.7761 1.32312C18.7761 1.32312 20.0402 -0.438499 20.3495 0.583519Z" fill="#DEE1E9"/>
      <path d="M22.4709 82.056C22.4709 82.056 16.1102 82.9301 12.56 81.155C8.9964 79.3799 10.9463 69.8725 10.9463 69.8725L13.2135 51.2744H16.9833C17.5356 51.2744 17.9833 51.7221 17.9833 52.2744V61.1123C22.179 62.3059 23.0251 62.1909 27.2209 61.1123V52.2744C27.2209 51.7221 27.6686 51.2744 28.2209 51.2744H31.3329L31.6556 52.8209C32.7583 58.1461 33.7266 63.1083 33.7266 63.1083C33.7266 63.1083 36.9809 71.6072 35.3537 76.677C33.7266 81.7467 22.4709 82.056 22.4709 82.056Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M10.9357 0.476097L14.4859 10.387C14.4859 10.387 17.4578 17.7966 17.014 21.7906C16.5703 25.7845 18.0495 37.1881 19.2329 40.1466C19.8246 41.6258 21.0752 47.3276 22.1914 52.6528C23.3075 57.9781 24.2623 62.9403 24.2623 62.9403C24.2623 62.9403 27.5704 71.5333 25.9433 76.5627C24.3161 81.5921 13.0605 81.9417 13.0605 81.9417C13.0605 81.9417 6.69973 82.8293 3.14956 81.0542C-0.400612 79.2791 1.5224 69.7582 1.5224 69.7582L3.59333 51.1198L6.10803 34.5254L7.88312 20.4592L8.32689 8.46396L9.36236 1.2157C9.36236 1.2157 10.6264 -0.545921 10.9357 0.476097Z" fill="#DEE1E9"/>
      <path d="M3.14623 81.0473C6.6964 82.8224 13.0571 81.9483 13.0571 81.9483C13.0571 81.9483 24.3128 81.639 25.9399 76.5692C27.5671 71.4995 24.3128 63.0006 24.3128 63.0006C24.3128 63.0006 23.3445 58.0384 22.2418 52.7132L21.9191 51.1667L17.4948 47.3072C16.3976 46.5497 15.1242 46.0867 13.7967 45.9625C12.0851 45.8275 10.3751 46.2421 8.91525 47.1459C8.91525 47.1459 3.80516 49.8354 3.53621 51.7987L1.53252 69.7647C1.53252 69.7647 -0.417387 79.2722 3.14623 81.0473Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M11.5483 52.1417C10.2976 50.4204 11.1179 47.8654 11.091 38.9765C11.091 31.1096 10.4052 30.6793 10.9566 26.4971C11.36 23.4176 11.6236 21.6415 12.2422 16.787C12.7397 12.8737 10.0834 2.33155 10.2447 2.35845C10.4061 2.38535 13.3906 15.4028 13.6999 21.6694C13.9957 27.6671 14.1974 26.9543 14.5067 32.9251C14.964 41.5988 14.5067 42.6073 15.4077 46.0768C16.1473 48.8874 16.9408 50.1111 16.5508 51.4559C16.374 51.9449 16.0668 52.3763 15.6625 52.7034C15.2582 53.0304 14.7721 53.2406 14.2569 53.3112C13.7417 53.3819 13.2169 53.3102 12.7395 53.1041C12.2621 52.898 11.8501 52.5651 11.5483 52.1417Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M10.9357 0.476097L14.4859 10.387C14.4859 10.387 17.4578 17.7966 17.014 21.7906C16.5703 25.7845 18.0495 37.1881 19.2329 40.1466C19.8246 41.6258 21.0752 47.3276 22.1914 52.6528C23.3075 57.9781 24.2623 62.9403 24.2623 62.9403C24.2623 62.9403 27.5704 71.5333 25.9433 76.5627C24.3161 81.5921 13.0605 81.9417 13.0605 81.9417C13.0605 81.9417 6.69973 82.8293 3.14956 81.0542C-0.400612 79.2791 1.5224 69.7582 1.5224 69.7582L3.59333 51.1198L6.10803 34.5254L7.88312 20.4592L8.32689 8.46396L9.36236 1.2157C9.36236 1.2157 10.6264 -0.545921 10.9357 0.476097Z" fill="#DEE1E9"/>
      <path d="M3.14623 81.0473C6.6964 82.8224 13.0571 81.9483 13.0571 81.9483C13.0571 81.9483 24.3128 81.639 25.9399 76.5692C27.5671 71.4995 24.3128 63.0006 24.3128 63.0006C24.3128 63.0006 23.3445 58.0384 22.2418 52.7132L21.9191 51.1667L17.4948 47.3072C16.3976 46.5497 15.1242 46.0867 13.7967 45.9625C12.0851 45.8275 10.3751 46.2421 8.91525 47.1459C8.91525 47.1459 3.80516 49.8354 3.53621 51.7987L1.53252 69.7647C1.53252 69.7647 -0.417387 79.2722 3.14623 81.0473Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.4" d="M11.5483 52.1417C10.2977 50.4204 11.118 47.8654 11.0911 38.9765C11.0911 31.1096 10.4052 30.6793 10.9566 26.4971C11.36 23.4176 11.6236 21.6415 12.2422 16.787C12.7397 12.8737 10.0834 2.33155 10.2448 2.35845C10.4061 2.38535 13.3906 15.4028 13.6999 21.6694C13.9958 27.6671 14.1975 26.9543 14.5068 32.9251C14.964 41.5988 14.5068 42.6073 15.4078 46.0768C16.1474 48.8874 16.9408 50.1111 16.5508 51.4559C16.374 51.9449 16.0669 52.3763 15.6625 52.7034C15.2582 53.0304 14.7721 53.2406 14.2569 53.3112C13.7417 53.3819 13.217 53.3102 12.7395 53.1041C12.2621 52.898 11.8502 52.5651 11.5483 52.1417Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M10.7375 1.14065L14.2876 11.0515C14.2876 11.0515 17.2595 18.4612 16.8158 22.4551C16.372 26.449 17.8512 37.8526 19.0346 40.8111C19.6263 42.2903 20.877 47.9921 21.9931 53.3174C23.1093 58.6426 24.064 63.6048 24.064 63.6048C24.064 63.6048 27.3722 72.1978 25.745 77.2272C24.1178 82.2567 12.8622 82.6063 12.8622 82.6063C12.8622 82.6063 6.50146 83.4938 2.95129 81.7187C-0.598885 79.9437 1.32413 70.4227 1.32413 70.4227L3.39506 51.7843L5.90976 35.19L7.68485 21.1238L8.12862 9.12851L9.16408 1.88025C9.16408 1.88025 10.4282 0.11863 10.7375 1.14065Z" fill="#DEE1E9"/>
      <path d="M2.94796 81.7118C6.49813 83.4869 12.8588 82.6128 12.8588 82.6128C12.8588 82.6128 24.1145 82.3035 25.7417 77.2338C27.3688 72.164 24.1145 63.6651 24.1145 63.6651C24.1145 63.6651 23.1463 58.703 22.0436 53.3777L21.7208 51.8312L17.2966 47.9718C16.1993 47.2143 14.926 46.7513 13.5985 46.627C11.8868 46.4921 10.1768 46.9066 8.71698 47.8104C8.71698 47.8104 3.60689 50.4999 3.33794 52.4633L1.33424 70.4293C1.33424 70.4293 -0.61566 79.9368 2.94796 81.7118Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask522" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="1" width="7" height="20">
        <path d="M9.14956 2.70695L9.51248 2.16339L10.3414 1.62117L11.0109 2.16339L14.9006 20.2772H8.97051L9.51248 12.1741L8.82019 5.17149L9.14956 2.70695Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask522)">
        <path opacity="0.4" d="M11.35 52.8063C10.0994 51.085 10.9197 48.5299 10.8928 39.641C10.8928 31.7742 10.207 31.3439 10.7583 27.1617C11.1618 24.0822 11.4253 22.3061 12.0439 17.4515C12.5415 13.5382 9.88511 2.99611 10.0465 3.023C10.2079 3.0499 13.1923 16.0674 13.5016 22.334C13.7975 28.3316 13.9992 27.6189 14.3085 33.5896C14.7657 42.2633 14.3085 43.2719 15.2095 46.7414C15.9491 49.5519 16.7425 50.7757 16.3525 52.1204C16.1758 52.6095 15.8686 53.0409 15.4643 53.3679C15.0599 53.6949 14.5738 53.9052 14.0586 53.9758C13.5434 54.0464 13.0187 53.9748 12.5413 53.7687C12.0638 53.5625 11.6519 53.2297 11.35 52.8063Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5022" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="20" width="13" height="37">
        <path d="M9.0134 20.3237H15.1134L19.1801 56.4391L7.9967 54.4058L9.0134 20.3237Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5022)">
        <path opacity="0.2" d="M11.35 52.8063C10.0994 51.085 10.9197 48.5299 10.8928 39.641C10.8928 31.7742 10.207 31.3439 10.7583 27.1617C11.1618 24.0822 11.4253 22.3061 12.0439 17.4515C12.5415 13.5382 9.88511 2.99611 10.0465 3.023C10.2079 3.0499 13.1923 16.0674 13.5016 22.334C13.7975 28.3316 13.9992 27.6189 14.3085 33.5896C14.7657 42.2633 14.3085 43.2719 15.2095 46.7414C15.9491 49.5519 16.7425 50.7757 16.3525 52.1204C16.1758 52.6095 15.8686 53.0409 15.4643 53.3679C15.0599 53.6949 14.5738 53.9052 14.0586 53.9758C13.5434 54.0464 13.0187 53.9748 12.5413 53.7687C12.0638 53.5625 11.6519 53.2297 11.35 52.8063Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M9.93594 1.21487L13.4861 11.1258C13.4861 11.1258 16.458 18.5354 16.0143 22.5293C15.5705 26.5233 17.0497 37.9268 18.2331 40.8853C18.8248 42.3646 20.0754 48.0663 21.1916 53.3916C22.3077 58.7168 23.2625 63.679 23.2625 63.679C23.2625 63.679 26.5706 72.2721 24.9435 77.3015C23.3163 82.3309 12.0607 82.6805 12.0607 82.6805C12.0607 82.6805 5.69994 83.568 2.14977 81.793C-1.4004 80.0179 0.522612 70.497 0.522612 70.497L2.59355 51.8586L5.10825 35.2642L6.88333 21.198L7.3271 9.20273L8.36257 1.95447C8.36257 1.95447 9.62665 0.192848 9.93594 1.21487Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M10.9359 0.551903L14.4861 10.4628C14.4861 10.4628 17.458 17.8724 17.0143 21.8664C16.5705 25.8603 18.0497 37.2639 19.2331 40.2224C19.8248 41.7016 21.0754 47.4034 22.1916 52.7286C23.3077 58.0539 24.2625 63.0161 24.2625 63.0161C24.2625 63.0161 27.5706 71.6091 25.9435 76.6385C24.3163 81.6679 13.0607 82.0175 13.0607 82.0175C13.0607 82.0175 6.69994 82.9051 3.14977 81.13C-0.400398 79.3549 1.52261 69.834 1.52261 69.834L3.59355 51.1956L6.10825 34.6012L7.88333 20.5351L8.3271 8.53977L9.36257 1.29151C9.36257 1.29151 10.6266 -0.470116 10.9359 0.551903Z" fill="#DEE1E9"/>
      <path d="M3.14644 81.1232C6.69662 82.8983 13.0573 82.0242 13.0573 82.0242C13.0573 82.0242 24.313 81.7149 25.9401 76.6452C27.5673 71.5754 24.313 63.0765 24.313 63.0765C24.313 63.0765 23.3448 58.1144 22.2421 52.7891L21.9193 51.2426L17.495 47.3832C16.3978 46.6257 15.1245 46.1626 13.797 46.0384C12.0853 45.9034 10.3753 46.318 8.91547 47.2218C8.91547 47.2218 3.80538 49.9113 3.53642 51.8747L1.53273 69.8407C1.53273 69.8407 -0.417173 79.3481 3.14644 81.1232Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth62",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
