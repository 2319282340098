<template>
  <router-link v-if="object.doctor" :to="{ name: 'StaffDoctorVisits', params: {id: object.doctor.id }}">
    {{ object.doctor.full_name }}
  </router-link>
</template>

<script>
export default {
  name: "PlanningDatatableTDDoctorName",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">

</style>
