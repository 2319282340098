<template>
  <b-modal id="form-modal"
           :size="options && options.modalsize ? options.modalsize : 'md'"
           :title="$t((options && options.modaltitle) ? options.modaltitle : modelName.toLowerCase())"
           @hide="onHide"
           no-enforce-focus
           no-close-on-backdrop
           centered>
    <component v-if="options && options.additionalFormComponent"
               :options="options"
               :is="formcomponents[options.additionalFormComponent]"
               :object="object"/>
    <component v-if="options && options.formComponent"
               :options="options"
               @save="save"
               :is="formcomponents[options.formComponent]"
               :edit="edit"
               :selectModels="selectModels"
               :object="object"/>
    <div v-if="options && !options.formComponent && prepared"
         :class="{ invisible: !prepared }"
         class="row">
      <template v-for="(f, find) in options.fields">
        <div :key="'form-field-'+find"
             v-if="elementShouldBeShown(f)"
             :class="[f.form && f.form.classes ? f.form.classes : 'col-12', {
               'd-none': f.form && f.form.full_card && !fullCard
             }]">
          <VariableInput :field="f"
                         :object="object"
                         :edit="edit"
                         @enter="save"
                         :selectModels="selectModels"/>
          <ValidationError :validationId="f.validation_id || f.id"/>
        </div>
      </template>
    </div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button variant="themed"
                  v-if="modalMode === 'show'"
                  @click="close">
          {{ $t('close') }}
        </b-button>
        <b-button variant="themed"
                  class="cancel"
                  @click="close"
                  v-if="modalMode === 'edit'">
          {{ $t('cancel') }}
        </b-button>
        <b-button variant="themed"
                  class="ml-2"
                  :disabled="loading"
                  v-if="modalMode === 'edit'"
                  @click="save">
          {{ $t('save') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapGetters, mapState } from "vuex"
import VariableInput from "@/components/form/VariableInput"
import  { EloquentService } from "@/services/api.service"
import ValidationError from "@/components/form/ValidationError"
import JwtService from "@/services/jwt.service"

export default {
  name: "FormModal",
  components: {
    ValidationError,
    VariableInput
  },
  data() {
    return {
      options: null,
      oldObject: {},
      prepared: false
    }
  },
  computed: {
    ...mapState({
      model: state  => state.datatable.viewModel,
      errors: state => state.datatable.validationErrors,
      eloquentOptions: state => state.datatable.eloquentOptions,
      selectModels: state => state.datatable.selectModels,
      loading: state => state.dom.loading,
      object: state => state.datatable.selectedModel,
      module: state => state.datatable.module,
      fullCard: state => state.datatable.fullCard,
      modalMode: state => state.datatable.modalMode,
      rights: state => state.auth.rights,
      superadmin: state => state.auth.superadmin,
    }),
    ...mapGetters(['modelName']),
    edit() {
      return !!this.object[this.options.primaryKey]
    },
    prepareParams() {
      let params = {}
      if(!this.options || !this.options.prepareParams || this.options.prepareParams.length < 1) {
        return params
      }
      let ids = this.options.prepareParams

      ids.forEach(item => {
        params[item] = this.object[item]
      })
      return params
    },
    formcomponents () {
      const components = require.context(`@/components/parts/datatable/form/`, true, /\.vue$/i)
      let arr = {}
      components.keys().map(x => {
        arr[x.split('/').pop().split('.')[0]] = components(x).default
      })
      return arr
    }
  },
  methods: {
    async save() {
      if(this.loading) return
      const id = this.object[this.options.primaryKey]
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.options.adapter ? this.options.adapter(this.object) : this.object)
      if(id || id === 0) {
        try {
          const res = await EloquentService.update(this.model, id, formData, this.superadmin)
          this.finished(res.data)
        } catch (e) {
          console.log(e)
        }
      }
      if(!id && id !== 0) {
        try {
          let res
          if(this.eloquentOptions?.customCreateFunction) {
            res = await this.eloquentOptions?.customCreateFunction(this.object, this.superadmin)
          } else {
            res = await EloquentService.create(this.model, formData, this.superadmin)
          }
          this.finished(res.data)
        } catch (e) {
          console.log(e)
        }
      }
    },
    finished(newObject) {
      this.$noty.info(this.$t('success_message'))
      this.$bvModal.hide('form-modal')
      this.$store.commit('incrementDataTableCounter')
      newObject.model_name = this.model
      if(['superadministrator', 'doctor', 'assistant', 'technic', 'administrator'].includes(this.model)
          && newObject.api_token
          && Number(newObject.id) === Number(this.$store.state.auth.user.id)) {
        JwtService.saveToken(newObject.api_token, this.superadmin)
      }
      this.$store.commit('setNewObject', newObject)
    },
    parseOptions() {
      if(this.modelName) {
        import("@/data/"+this.module+'/'+this.modelName.toLowerCase()).then(res => {
          this.options = res.default
          if(!this.options) { // if options are functions (in superadmin)
            for (const key in res) {
              if (typeof res[key] === 'function') {
                this.options = res[key]()
              }
            }
          }
          this.loadSelectModels()
        }).catch(err => {
          console.log(err)
        })
      } else {
        this.options = null
      }
    },
    loadSelectModels() {
      if(!this.options) {
        this.$store.commit("setSelectModels", {})
        return
      }
      EloquentService.prepare(this.model, this.prepareParams, this.superadmin).then(res => {
        this.$store.commit("setSelectModels", Array.isArray(res.data.options) ? {} : res.data.options)
        if(res.data.model) {
          this.$store.commit('setSelectedModel', res.data.model)
        }
        this.prepared = true
        this.$nextTick(() => {
          this.$bvModal.show('form-modal')
        })
      })
    },
    onHide() {
      this.$store.commit("setValidationErrors", {})
      this.$store.commit("setShowModal", false)
      this.$store.commit("setFullCard", true)
      this.prepared = false
      if(this.eloquentOptions) { // returning to previous model if this add form was custom
        this.$store.commit('setViewModel', this.eloquentOptions.model)
      }
      this.$store.commit('setModalMode', 'edit')
    },
    elementShouldBeShown(f) {
      if(!f.form) return false
      if(f.form.hidden) return false
      if(this.edit && f.unavailableonedit) return false
      if(f.right_excludes && this.rights.includes(f.right_excludes)) return false
      const tabs = f.form.tabs || (f.form.tab ? [f.form.tab] : null)
      if(tabs && this.$store.state.datatable.formTabActive && !tabs.includes(this.$store.state.datatable.formTabActive)) return false
      // if(f.form.tab && this.$store.state.datatable.formTabActive && f.form.tab !== this.$store.state.datatable.formTabActive) return false
      return this.meetsCondition(f)
    },
    meetsCondition(field) {
      if(field.form.conditionFunc) {
        return field.form.conditionFunc(this.$store, this.object)
      }
      return true
    }

  },
  watch: {
    object: {
      handler(val) {
        var tmp_errors = this.copyObject(this.errors)
        for (let key in val) {
          if ({}.hasOwnProperty.call(val, key)) {
            if(!this.oldObject[key] || val[key] != this.oldObject[key]) {
              delete tmp_errors[key]
            }
          }
        }
        this.$store.commit("setValidationErrors", tmp_errors)
        this.oldObject = this.copyObject(val)
      },
      deep: true
    }
  },
  mounted() {
    this.parseOptions()
  }
}
</script>
