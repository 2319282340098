<template>
  <div v-if="selectModels">
    <SelectGroup :options="selectModels.record_id"
                 variant="white"
                 :title="'start'"
                 :label="$t('record')"
                 :required="true"
                 :translate="true"
                 :showlabel="true"
                 :class="{'d-none': object.record_immutable }"
                 :readonly="object.record_immutable"
                 v-model="object.record_id"/>
    <label class="form-label mb-2">
      <span>{{ $t('teeth') }}</span>
    </label>
    <div v-if="selectModels.teeth && selectModels.teeth.length == 0" class="form-group">{{ $t('no_teeth_in_the_record') }}</div>
    <div v-for="(t, tind) in selectModels.teeth" :key="`tooth-check-${tind}`">
      <div class="checkbox form-group">
        <input type="checkbox"
               :value="t.records_teeth_id"
               v-model="teeth_ids"
               :id="`checkbox-${tind}`"/>
        <label class="mb-0" :for="`checkbox-${tind}`">{{ t.teeth }}</label>
      </div>
    </div>
  </div>
</template>

<script>

import SelectGroup from "@/components/form/SelectGroup"
import  { EloquentService } from "@/services/api.service"

export default {
  name: "PatientsDatatableFormTeeth",
  components: {
    SelectGroup,
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  data() {
    return {
      teeth_ids: this.object.teeth ? this.object.teeth.map(a => a.records_teeth_id) : []
    }
  },
  methods: {
    getTeethForRecord() {
      if(this.object.record_id) {
        EloquentService.show('record', this.object.record_id).then(res => {
          this.selectModels.teeth = res.data.recordTeeth || res.data.record_teeth
        })
      }
    }
  },
  mounted() {
    this.getTeethForRecord()
  },
  computed: {
    record_id() {
      return this.object.record_id
    }
  },
  watch: {
    teeth_ids: {
      handler(val) {
        this.object.teeth_ids = val
      },
      immediate: true
    },
    record_id() {
      this.teeth_ids = []
      this.getTeethForRecord()
    }
  }
}
</script>
