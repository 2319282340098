export const patientAdapter =
    ({
         id,
         branch_id,
         lname,
         mname,
         fname,
         mphone,
         mphone2,
         mphone_descr,
         mphone2_descr,
         description,
         gender,
         card,
         date_of_birth,
         address,
         email,
         iin,
         snils,
         pesel,
         taxcode,
         passport,
         passport_series,
         passport_given_by,
         passport_issue_date,
         passport_dep_code,
         discount,
         doctor_id,
         curator_id,
         status_id,
         source,
         patient_condition_id,
         username,
         password,
         password_confirmation,
         sendPassword,
         representative_fio,
         representative_phone,
         representative_address,
         representative_passport,
         representative_document,
         representative_relation,
         representative_passport_data,
         representative_inn,
         representative_document_type_id,
         representative_date_of_birth,
         representative_gender,
         representative_region_egisz_id,
         representative_zip_code,
         representative_passport_dep_code,
         representative_passport_given_by,
         representative_passport_issue_date,
         representative_passport_series,
         representative_snils,
         zip_code,
         region_egisz_id,
         document_type_id,
         representative,
         clinic_id,
         url,
         send_notifications,
         send_marketing,
         send_to_egisz,
     }) => ({
        id: id ? id : null,
        branch_id: branch_id ? branch_id : null,
        lname: lname ? lname : null,
        mname: mname ? mname : null,
        fname: fname ? fname : null,
        mphone: mphone ? mphone : null,
        mphone2: mphone2 ?? null,
        mphone_descr: mphone_descr ?? null,
        mphone2_descr: mphone2_descr ?? null,
        description: description ?? null,
        gender: gender ? gender : 0,
        card: card ? card : null,
        date_of_birth: date_of_birth ? date_of_birth : null,
        address: address ? address : null,
        email: email ?? null,
        iin: iin ? iin : null,
        snils: snils ?? null,
        pesel: pesel ?? null,
        taxcode: taxcode ?? null,
        passport: passport ?? null,
        passport_series: passport_series ?? null,
        passport_given_by: passport_given_by ?? null,
        passport_issue_date: passport_issue_date ?? null,
        passport_dep_code: passport_dep_code ?? null,
        discount: discount ? discount : null,
        doctor_id: doctor_id ? doctor_id : null,
        curator_id: curator_id || null,
        status_id: status_id ? status_id : null,
        source: source ? source : null,
        patient_condition_id: patient_condition_id === '-' ? '' : (patient_condition_id ?? null),
        username : username ? username : null,
        password: password ? password : null,
        password_confirmation: password_confirmation ? password_confirmation : null,
        sendPassword: sendPassword ? sendPassword : false,
        representative_fio: representative_fio ?? null,
        representative_phone: representative_phone ?? null,
        representative_address: representative_address ?? null,
        representative_passport: representative_passport ?? null,
        representative_document: representative_document ?? null,
        representative_relation: representative_relation ?? null,
        representative_passport_data: representative_passport_data ?? null,
        representative_inn: representative_inn ?? null,
        representative_document_type_id: representative_document_type_id ?? null,
        representative_date_of_birth: representative_date_of_birth ?? null,
        representative_gender: representative_gender ?? null,
        representative_region_egisz_id: representative_region_egisz_id ?? null,
        representative_zip_code: representative_zip_code ?? null,
        representative_passport_dep_code: representative_passport_dep_code ?? null,
        representative_passport_given_by: representative_passport_given_by ?? null,
        representative_passport_issue_date: representative_passport_issue_date ?? null,
        representative_passport_series: representative_passport_series ?? null,
        representative_snils: representative_snils ?? null,
        zip_code: zip_code ?? null,
        region_egisz_id: region_egisz_id ?? null,
        document_type_id: document_type_id ?? null,
        representative_id: representative ? representative.id : '',
        clinic_id: clinic_id ? clinic_id : null,
        url: url ? url : null,
        send_notifications: boolToBin(send_notifications),
        send_marketing: boolToBin(send_marketing),
        send_to_egisz: boolToBin(send_to_egisz),
    })

function boolToBin(val) {
    if(val === true) return 1
    if(val === false) return 0
    return null
}
