export default {
    primaryKey: "id",
    model: 'custom_role',
    order: 'id',
    sort: 'asc',
    modaltitle: 'role',
    modalsize: 'lg',
    prepareParams: ['id', 'clinic_id'],
    // adapter: chairAdapter,
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    // rights: {
    //     edit: "create_chairs",
    //     delete: "create_chairs",
    // },
    // actionscomponent: 'StaffDatatableChairActions',
    // additionalFormComponent: 'StaffDatatableFormCustomRole',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'functions',
            title: 'functions',
            table: false,
            form: {
                component: 'StaffDatatableFormCustomRole'
            }
        }
    ]

}
