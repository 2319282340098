<template>
  <div>
    <b-row class="pt-sm-3 pt-1 align-items-center">
      <b-col sm="auto" cols="12">
        <div class="patient-search-bar form-group">
          <input type="text"
                 class="form-control"
                 v-model="search"
                 :placeholder="$t('patient_search')"/>
        </div>
      </b-col>
    </b-row>
    <DataTable :options="options"
               ref="dataTable"
               class="mt-4"
               @checkboxSelected="selectedChange"
               module="crm">
      <template #bottom-bar>
        <div>
          <b-dropdown-item @click="bulkDelete"
                           v-if="rights.includes('crmtask_delete')">
            {{ $t('delete') }}
          </b-dropdown-item>
          <b-dropdown-item @click="changeStage">
            {{ $t('change_status') }}
          </b-dropdown-item>
          <b-dropdown-item @click="changeToPatientCanban"
                           v-if="rights.includes('crmtask_delete')">
            {{ $t('move_to_funnel_came_and_repeated') }}
          </b-dropdown-item>
        </div>
      </template>
    </DataTable>
    <LeadShowModal/>
    <LeadChangeStatusModal :selected="selected" @changed="selectionReload"/>
  </div>
</template>

<script>

import { mapState } from "vuex"
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/crm/lead"
import LeadShowModal from "@/components/parts/crm/LeadShowModal"
import LeadChangeStatusModal from "@/components/parts/crm/LeadChangeStatusModal"
import {CRMService, EloquentService} from "@/services/api.service"

export default {
  name: "CRMApplications",
  components: {
    LeadShowModal,
    LeadChangeStatusModal,
    DataTable,
  },
  head() {
    return {
      title: this.$t('applications_and_appeals'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      rights: state => state.auth.rights,
    }),
  },
  data() {
    return {
      options: options,
      search: '',
      timer: null,
      selected: []
    }
  },
  methods: {
    selectedChange(selected) {
      this.selected = selected
    },
    async bulkDelete() {
      const answer = confirm(this.$t('confirm_deletion'))
      if(!answer) return
      const res = await EloquentService.deleteBulk('lead', this.selected)
      if(res.data) {
        this.selectionReload()
      }
    },
    changeStage() {
      this.$bvModal.show('lead-change-status-modal')
    },
    async changeToPatientCanban() {
      const res = await CRMService.moveLeadsToCanban({ selected: this.selected })
      if(res.data) {
        this.selectionReload()
      }
    },
    selectionReload() {
      this.$refs.dataTable.cancelSelection()
      this.$refs.dataTable.load()
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  },
  watch: {
    search(val) {
      if(this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let datafilter = this.$refs.dataTable.$refs.datatablefilter
        if(val) {
          datafilter.filters.patient_id = {
            "id": "patient_id",
            "title": "patient",
            "condition": val,
            "type": "text",
            "ctype": "like"
          }
        } else {
          delete datafilter.filters.patient_id
        }
        datafilter.refresh()
      }, 600)
    }
  }
}
</script>

<style scoped lang="scss">
.patient-search-bar {
  input {
    border: none;
    background: #E0E7EE;
    border-radius: 25px;
    max-width: 250px;
    height: 33px;
    padding-left: 25px;
  }
}
@media(max-width: 768px) {
  .patient-search-bar {
    input {
      width: 100%;
      max-width: none;
    }
  }
}
</style>
