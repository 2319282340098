<template>
  <div class="bottom-bar" v-if="selected.length">
    <div class="container py-3">
      <b-row class="align-items-center">
        <b-col>{{ $t('selected') }}: {{ selected.length }}</b-col>
        <b-col cols="auto">
          <b-dropdown variant="link">
            <template #button-content>
              <a class="pointer btn-themed btn-themed-outline btn-themed-squared d-inline-block">
                {{ $t('actions_with_selected') }}
              </a>
            </template>
            <slot/>
<!--            <b-dropdown-item @click="edit"-->
<!--                             v-if="rights.includes('clinic_services')">-->
<!--              {{ $t('edit') }}-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item v-if="rights.includes('clinic_services')"-->
<!--                             @click="sendToArchive">-->
<!--              {{ $t('send_to_archive') }}-->
<!--            </b-dropdown-item>-->
<!--            <b-dropdown-item @click="addToFunnel"-->
<!--                             v-if="rights.includes('crm')">-->
<!--              {{ $t('add_to_funnel_came_and_repeated') }}-->
<!--            </b-dropdown-item>-->
            <b-dropdown-divider/>
            <b-dropdown-item @click="resetSelection">
              {{ $t('reset_selection') }}
            </b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script setup>
// import { ref } from "vue"

defineProps({
  selected: Array,
})

const emit = defineEmits(['resetSelection'])

const resetSelection = () => {
  emit('resetSelection')
}

</script>


