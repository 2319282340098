<template>
  <div class="d-week__day position-relative d-flex align-items-center w-100"
       :class="[{ today: isToday }, 'view-'+scheduleView]">
    <div class="d-week__day_title">
      {{ day.format('LL') }}
    </div>
    <div class="d-week__info"
         @click="goToDate">
      <div v-if="scheduleView === 'doctors'" class="doctor-bl">
        <div v-if="quantity > 0"
             class="d-week__number"
             :class="{
          busy: doctor && future && minutesFree <= 0
      }">
          {{ quantity }}
        </div>
        <div class="d-week__subtitle" :class="{
          busy: doctor && future && minutesFree <= 0 && scheduleMinutes > 0
      }">
          {{ text }}
        </div>
        <div v-if="doctor && future" class="free-time-note">
          <template v-if="scheduleMinutes <= 0">{{ $t('not_working') }}</template>
          <template v-else>
            <div v-if="minutesFree" class="text-primary">{{ $t('free') }}: {{ minutesFree }} {{ $t('min_short') }}</div>
            <div v-else class="text-danger">{{ $t('no_free_time') }}</div>
          </template>
        </div>
      </div>
      <div v-if="scheduleView !== 'doctors'" class="staff-schedule-grid">
        <div v-for="sch in daySchedules"
             class="staff-schedule-block"
             @click="employeeBlockClicked(sch)"
             :class="{
                worked: sch.worked === 1,
                'not-worked': sch.worked === 0,
              }"
             :key="`sch-${dateFormatted}-${sch.id}`">
          {{ sch.user.full_name }} - {{ sch.time }}
        </div>
        <StaffScheduleMenuBlock :show="show"
                                :class="{ bottom: last, top: !last }"
                                class="font-90"
                                :schedule="selectedSchedule"
                                @clicked-outside="clickedOutside"/>
      </div>
    </div>
  </div>
</template>

<script>

import moment from "moment"
import { mapGetters, mapState } from "vuex"
import StaffScheduleMenuBlock from "@/components/parts/calendar/StaffScheduleMenuBlock.vue"

export default {
  name: "DMonthWeekDay",
  components: {
    StaffScheduleMenuBlock
  },
  props: {
    day: Object,
    quantity: Number,
    recordMinutes: Number,
    last: Boolean
  },
  data() {
    return {
      show: false,
      justShowed: false,
      selectedSchedule: null
    }
  },
  computed: {
    text() {
      switch (this.quantity) {
        case null:
          return this.$tc('visits_count', 0)
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
          return this.$tc('visits_count', this.quantity)
      }
      return this.$t('visits_count_much')
    },
    isToday() {
      return moment().format('YYYY-MM-DD') === moment(this.day).format('YYYY-MM-DD')
    },
    ...mapState({
      scheduleView: state => state.calendar.scheduleView,
      rights: state => state.auth.rights,
    }),
    ...mapGetters(['selectedEmployees', 'employeeSchedules', 'staffScheduleEditRight']),
    doctor() {
      if(this.selectedEmployees.length === 1) return this.selectedEmployees[0]
      return null
    },
    selectedEmployeesIds() {
      return this.selectedEmployees.map(e => e.id)
    },
    dateFormatted() {
      return this.day.format('YYYY-MM-DD')
    },
    schedule() {
      if(!this.doctor) return null
      const schedule = this.employeeSchedules[this.doctor.id]
      if(!schedule) return null
      return schedule.find(s => s.day === this.dateFormatted)
    },
    daySchedules() {
      return Object.values(this.employeeSchedules)
          .flat()
          .filter(s => s.day === this.dateFormatted && this.selectedEmployeesIds.includes(s.doctor_id))
          .map(s => ({
            ...s,
            user: this.selectedEmployees.find(e => e.id === s.doctor_id),
            time: `${s.time_from.substring(0,5)} - ${s.time_to.substring(0,5)}`
          }))
    },
    scheduleMinutes() {
      if(!this.schedule) return 0
      return parseInt(this.schedule.minutes)
    },
    minutesFree() {
      return Math.max(0, (this.scheduleMinutes - this.recordMinutes).toFixed(0))
    },
    future() {
      let date = this.day.format('YYYY-MM-DD')
      let today = moment().format('YYYY-MM-DD')
      return date >= today
    },

  },
  methods: {
    goToDate() {
      if(this.scheduleView === 'doctors') {
        this.$store.commit('goToDate', this.day)
      }
    },
    toggleShow() {
      if(!this.rights.includes(this.staffScheduleEditRight)) return
      this.show = !this.show
      this.justShowed = true
      setTimeout(() => {
        this.justShowed = false
      }, 100)
    },
    clickedOutside() {
      if(this.justShowed) return
      this.show = false
    },
    employeeBlockClicked(schedule) {
      this.selectedSchedule = schedule
      this.toggleShow()
    }
  },
}
</script>

<style scoped lang="scss">
.view-doctors {
  .d-week__day {
    height: 100px;
  }
}
.d-week__day {
  border-left:1px solid #E5E5E5;
  padding: 24px 5px 20px 5px;
  background: white;
  height: auto;
  min-height: 100px;
}
.d-week__day.today {
  background-color: #F1FCFF;
}
.d-week__day:last-child {
  border-right: 1px solid #E5E5E5;
}
.d-week__day_title {
  text-transform: uppercase;
  font-size: 11px;
  color: #BAC0C6;
  position: absolute;
  top: 5px;
  left: 5px;
}
.d-week__info {
  width: 100%;
  text-align: center;
  cursor: pointer;
}
.d-week__info:hover {
  .doctor-bl, .staff-schedule-block {
    opacity: .5;
  }
}
.d-week__number {
  font-size: 24px;
  font-weight: bold;
  color: black;
  &.busy {
    color: $danger-red;
  }
  &.free {
    color: $blue-color;
  }
}
.d-week__subtitle {
  text-transform: uppercase;
  font-size: 12px;
  color: #87939E;
  &.busy {
    color: $danger-red;
    opacity: 0.7;
  }
  &.free {
    color: $blue-color;
  }
}
.free-time-note {
  margin-top: 3px;
  font-size: 11px;
  line-height: 1;
  opacity: 0.8;
}
.staff-schedule-grid {
  gap: 4px;
  display: grid;
}
.staff-schedule-block {
  font-size: 12px;
  color: #5D6D7C;
  background: #CFEEFE;
  border-radius: 8px;
  border: 1px solid rgb(7, 135, 201);
  &.worked {
    background: rgba(94, 185, 51, 0.5);
    border: 1px solid rgba(94, 185, 51, 1);
    .schedule-status {
      color: $green-color;
    }
    &:hover {
      background: rgb(99, 167, 68);
      color: white;
    }
  }
  &.not-worked {
    background: $gray-icon;
    border: 1px solid $gray-text;
    color: $gray-text;
    &:hover {
      background: $gray-text;
      color: white;
    }
  }
}
.font-90 {
  font-size: 13px;
}

@media screen and (max-width: 768px) {
  .d-week__subtitle {
    font-size: 9px;
  }
  .d-week__day_title {
    font-size: 8px;
  }
  .d-week__number {
    font-size: 18px;
  }
  .free-time-note {
    font-size: 9px;
  }
}
</style>
