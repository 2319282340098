<template>
  <div class="d-inline-block align-middle calendar-navigator position-relative">
    <div class="form-control gray-input d-flex align-items-center justify-content-center">
      <a class="calendar-navigator__left" @click="prev">
        <LeftSvg/>
      </a>
      <span :class="{ pointer: activeRange === 'DAY' }" class="nowrap" @click="dateClicked">{{ title }}</span>
      <a class="calendar-navigator__right" @click="next">
        <RightSvg/>
      </a>
      <div v-if="activeRange === 'DAY' && show"
           v-click-outside="clickedOutside">
      <VDatePicker :locale="{ id: 'ru', firstDayOfWeek: 2, masks: { L: 'DD.MM.YYYY' } }"
                   @dayclick="handleInput"
                   v-model="day"/>
      </div>
    </div>
  </div>
</template>

<script>
import RightSvg from '@/assets/svg-vue/general/arrow_right.svg'
import LeftSvg from '@/assets/svg-vue/general/arrow_left.svg'
import { DatePicker } from 'v-calendar'
import { mapGetters, mapState } from "vuex"
import moment from "moment/moment"

DatePicker.compatConfig = { MODE: 3 }

export default {
  name: "CalendarNavigator",
  components: {
    RightSvg,
    LeftSvg,
    VDatePicker: DatePicker
  },
  data() {
    return {
      show: false,
      day: moment(new Date()),
      justOpened: false,
      attrs: [{}]
    }
  },
  computed: {
    ...mapState({
      viewDates: state => state.calendar.viewDates,
      today: state => state.calendar.today,
    }),
    ...mapGetters([
      'calendarTitle',
      'activeRange'
    ]),
    title() {
      return this.calendarTitle.replace(/ *\([^)]*\) */g, "")
    }
  },
  methods: {
    next() {
      this.$store.commit('step', 1)
    },
    prev() {
      this.$store.commit('step', -1)
    },
    dateClicked() {
      if(this.activeRange === 'DAY') {
        this.show = !this.show
        this.justOpened = true
        setTimeout(() => {
          this.justOpened = false
        },20)
      }
    },
    handleInput(val) {
      console.log(val)
      if (val?.date) {
        this.$store.commit('goToDate', moment(val.date))
        this.show = false
      }
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.show = false
      }
    },
  },
  watch: {
    viewDates: {
      handler(val) {
        this.day = val[0].toDate()
      },
      immediate: true
    }
  }
}
</script>
