<template>
  <div>
    <div>
      <div class="mb-2">Каким ролям показывать:</div>
      <CheckboxGroup v-for="n in roles"
                     class="d-inline-block mr-4"
                     :fid="`tag-${n.id}`"
                     :key="`ch-${n.id}`"
                     :checkboxvalue="n.id"
                     v-model="object.roles"
                     :label="$t(n.title)"/>
      <ValidationError class="mt-1" validationId="roles"/>
    </div>
  </div>

</template>

<script>

import CheckboxGroup from "@/components/form/CheckboxGroup"
import ValidationError from "@/components/form/ValidationError"

export default {
  name: "PopupFormCard",
  components: {
    ValidationError,
    CheckboxGroup
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      roles: [
        {id: '2', title: 'superadministrator'},
        {id: '3', title: 'manager'},
        {id: '4', title: 'doctor'},
      ]
    }
  },
  mounted() {
    // this.object.roles = []
  }
}
</script>

<style scoped>

</style>
