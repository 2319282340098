<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="debt"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import {mapState} from "vuex"

export default {
  name: "ReportsDiagnoses",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    diagnoses: {
      type: Array
    },
    selectedBranches: {}
  },
  head() {
    return {
      title: this.$t('diagnoses'),
    }
  },
  computed: {
    ...mapState({
      branchId: state => state.auth.branch,
    }),
  },
  data() {
    return {
      columns: [
        {
          id: 'date',
          field: 'date',
          label: this.$t('date'),
          sortable: true,
          format_datetime: true
        },
        {
          id: 'patient_name',
          field: 'patient_name',
          label: this.$t('patient'),
          tdcomponent: 'PatientsDatatableTDName',
          search_select: true,
          sortable: true
        },
        {
          id: 'title',
          field: 'title',
          label: this.$t('diagnosis'),
          search_select: true,
          sortable: true
        },
        {
          id: 'doctor_name',
          field: 'doctor_name',
          label: this.$t('doctor'),
          search_select: true,
          sortable: true
        },
      ],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('diagnoses', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctors: this.doctors.map(a => a.id),
        diagnoses: this.diagnoses.map(a => a.id),
      }).then(res => {
        this.rows = res.data
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>

<style scoped>

</style>
