<template>
  <div>
    <div>{{ formatDateTime(object.signed_at) }}</div>
    <a class="pointer"
       v-if="object.signed_at"
       @click="showDetails">
      <EyeSvg class="svg-light svg-size-md"/>
    </a>
    <PlanSignModal v-if="showModal"
                   :plan="object"
                   @hidden="showModal = false"/>
  </div>
</template>

<script setup>
import EyeSvg from '@/assets/svg-vue/form/eye.svg'
import { formatDateTime } from "@/extensions/filters/filters"
import PlanSignModal from "@/components/parts/planning/PlanSignModal.vue"
import { getCurrentInstance, nextTick, ref } from "vue"

defineProps({
  object: {
    type: Object
  },
})

const instance = (getCurrentInstance())

const showModal = ref(false)

const showDetails = async () => {
  showModal.value = true
  await nextTick()
  instance?.ctx?._bv__modal.show('plan-sign-modal')
}
</script>
