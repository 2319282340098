import { materialAdapter } from "@/adapters/warehouse/material"

export default {
    primaryKey: 'material_id',
    model: 'materialstock',
    order: 'name',
    modaltitle: 'material',
    adapter: materialAdapter,
    showTotalRecords: true,
    sort: 'asc',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    deleteCondition(object) {
        return object.flag === 'active'
    },
    actionscomponent: 'WarehouseDatatableActionsMaterialStock',
    fields: [
        {
            id: 'material_id',
            title: 'id',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
        },
        {
            id: 'barcode',
            title: 'barcode',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text"
            }
        },
        {
            id: 'artikul',
            title: 'artikul',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text"
            }
        },
        {
            id: 'price',
            title: 'price',
            table: true,
            sortable: true,
            format_currency: true,
            filter: true,
            filter_number: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'material_group_id',
            title: 'group',
            table: true,
            table_show: 'group.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                }
            }
        },
        {
            id: 'unit_id',
            title: 'unit_of_measure',
            table: true,
            table_show: 'unit.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                required: true
            }
        },
        {
            id: 'total_quantity',
            title: 'amount',
            table: true,
            thhint: 'amount_of_stock_on_all_warehouses',
            tdcomponent: 'WarehouseDatatableTDMaterialQuantities',
            sortable: true,
            filter: true,
            filter_number: true
        },
        {
            id: 'sum',
            title: 'general_price',
            table: true,
            format_currency: true,
            tdcomponent: 'WarehouseDatatableTDMaterialValue',
            sortable: false
        },
        {
            id: 'min_stock',
            title: 'notify_when_stock',
            table: true,
            tdcomponent: 'WarehouseDatatableTDNotify',
            sortable: true,
            filter: true,
            filter_number: true,
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'warehouse_id',
            title: 'warehouse',
            filter: true,
            filter_model_select: true
        }
    ]
}
