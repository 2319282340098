<template>
  <span class="">
    <template v-for="(p, pind) in object.payments">
      <span :key="`object-cash-${object.id}-${pind}`" v-if="p.type == 'CASH'">{{ formatCurrency(p.sell_sum / 100) }}</span>
    </template>
    <span v-if="object.payments.length == 0">0</span>
  </span>
</template>

<script>

import {formatCurrency} from "@/extensions/filters/filters"

export default {
  name: "CashdeskDatatableTDXReportCash",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  methods: {
    formatCurrency
  }
}
</script>
