export const clientNoteAdapter =
    ({
         id,
         client_id,
         name,
         description,
         type,
     }) => ({
        id,
        client_id,
        name: name ?? null,
        description: description ?? null,
        type: type ? 1 : 0
    })

