<template>
  <div>
    <ServiceMultiSelect :label="$t('prices_price')"
                        :dblclick="false"
                        model="price"
                        title="title"
                        :dbsearch="false"
                        :prepopulatedOptions="selectOptions"
                        v-model="object.prices"/>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import { mapState } from "vuex"

export default {
  name: "StaffDatatableFormPrices",
  components: {
    ServiceMultiSelect
  },
  props: {
    object: {
      type: Object
    },
    selectOptions: {},
    field: {},
    required: {},
    value: {},
    selectModels: {}
  },
  methods: {
  },
  mounted() {
  },
  computed: {
    ...mapState({
      doctors: state => state.auth.doctors,
    })
  },
}
</script>
