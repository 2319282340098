import { EloquentService } from "@/services/api.service"
import { appendFormdata, copyObject } from "@/extensions/prototypes/prototypes"

export async function scanCustomCreate(object, superAdmin) {
	const model = 'scan'
	if(object.image && object.image.length > 1) {
		const lastIndex = object.image.length - 1
		const ids = []
		let res = null
		for(const index in object.image) {
			const data = copyObject(object)
			data.image = [object.image[index]]
			console.log(lastIndex, index)
			if(lastIndex === Number(index)) {
				data.previous_scan_ids = ids
			} else {
				data.skip_comment = 1
			}
			let formData = new FormData()
			formData = appendFormdata(formData, data)
			res = await EloquentService.create(model, formData, superAdmin)
			if(res?.data?.id) {
				ids.push(res.data.id)
			}
			console.log(res.data)
		}
		return res
	}
	let formData = new FormData()
	formData = appendFormdata(formData, object)
	return await EloquentService.create(model, formData, superAdmin)
}
