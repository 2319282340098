<template>
  <b-modal id="send-mail-modal"
           v-if="patient"
           size="lg"
           :title="$t('send_mail')"
           centered>
    <b-row class="align-items-end">
      <b-col cols="6">
        <InputGroup type="text"
                    :label="$t('to_whom')"
                    :required="true"
                    :readonly="true"
                    v-model="patient.full_name"/>
      </b-col>
      <b-col cols="6">
        <InputGroup type="email"
                    :label="$t('email')"
                    :required="true"
                    v-model="email"/>
        <ValidationError validationId="email"/>
      </b-col>
      <b-col cols="12">
        <InputGroup type="text"
                    :label="$t('topic')"
                    :required="true"
                    v-model="topic"/>
        <ValidationError validationId="topic"/>
      </b-col>
      <b-col cols="12">
        <AutosizeTextArea v-model="message"
                          :required="true"
                          :label="$t('text')"/>
        <ValidationError validationId="text"/>
      </b-col>
    </b-row>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel mr-3 d-inline-block align-middle" variant="themed" @click="close()">{{ $t('cancel') }}</b-button>
        <button :disabled="loading || message.length < 1 || topic.length < 1" @click="send" class="btn-themed align-middle btn-themed-squared">{{ $t('send') }}</button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import { SendService } from "@/services/api.service"
import ValidationError from "@/components/form/ValidationError"

export default {
  name: "SendMailModal",
  components: {
    ValidationError,
    InputGroup,
    AutosizeTextArea
  },
  data() {
    return {
      topic: '',
      message: '',
      email: ''
    }
  },
  methods: {
    send() {
      this.$store.commit('cleanValidationErrors')
      SendService.sendMail({
        text: this.message,
        email: this.email,
        topic: this.topic
      }).then(() => {
        this.$bvModal.hide('send-mail-modal')
      })
    }
  },
  computed: {
    ...mapState({
      loading: state => state.dom.loading,
      patient: state => state.patient.patient,
    }),
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'send-mail-modal') {
        if(this.patient) {
          this.email = this.copyObject(this.patient.email)
        }
      }
    })
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      if (modalId === 'send-mail-modal') {
        this.message = ''
        this.topic = ''
        this.$store.commit('cleanValidationErrors')
      }
    })
  }
}
</script>

<style scoped lang="scss">

</style>
