<template>
  <section id="reports" class="reports">
    <div class="section-top-row">
      <div class="row row-narrow align-items-center no-print position-relative">
        <div class="col order-md-0 order-0">
          <h1>{{ $t('reports') }}</h1>
        </div>
        <div class="col-auto table-view-navigator order-md-1 order-1">
          <a class="btn btn-themed gray d-md-inline-flex d-block align-items-center excel-btn"
             @click="printPressed">
            <PrintSvg class="mr-2 file-svg"/>
            <span>{{ $t('print_noun') }}</span>
          </a>
        </div>
        <div class="col-auto order-md-2 order-2 table-view-navigator"
             v-if="excelEnabled">
          <a class="btn btn-themed gray d-md-inline-flex d-block align-items-center excel-btn"
             @click="excel">
            <ExcelSvg class="svg-gray mr-md-2"/>
            <span>{{ $t('excel') }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="sub-menu no-print">
      <template v-for="(m, mind) in menuFiltered">
        <router-link v-if="!m.right || rights.includes(m.right)"
                     :to="{ name: m.to }"
                     @mousedown.native="routerLinkClicked(m)"
                     :key="`settings-link-${mind}}`">
          {{ $t(m.title) }}
        </router-link>
      </template>
    </div>
    <div class="date-select"
         v-if="!['ReportsDebtors'].includes($route.name)">
      <b-row class="mt-4 align-items-end row-narrow">
        <b-col md="2" cols="6">
          <DateGroup :label="$t(['ReportCashdesk'].includes($route.name) ? 'date' : 'date_from')"
                     :key="`from-${counter}`"
                     :min-date="minDate"
                     :required="true"
                     v-model="from"/>
        </b-col>
        <b-col md="2" cols="6"
               :class="{ 'd-none': ['ReportCashdesk'].includes($route.name) }">
          <DateGroup :label="$t('date_to')"
                     :key="`to-${counter}`"
                     :min-date="minDate"
                     :required="true"
                     v-model="to"/>
        </b-col>
        <b-col md="auto" cols="12"
               :class="{ 'd-none': !showNew }">
          <div class="mb-md-2 pb-md-1 no-print">
            <CheckboxGroup :label="$t('show_only_new_patients')"
                           v-model="onlyNew"
                           fid="onlyNew"/>
          </div>
        </b-col>
        <b-col md="auto" cols="12"
               :class="{ 'd-none': !showNew }">
          <div class="mb-md-2 pb-md-1 no-print">
            <CheckboxGroup :label="$t('hide_cancelled_visits')"
                           v-model="hideCancelled"
                           fid="hideCancelled"/>
          </div>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !doctorsSearch || showDeletedDoctors }">
          <ServiceMultiSelect :label="$t('doctors')"
                              :dblclick="false"
                              model="doctor"
                              title="full_name"
                              :dbsearch="false"
                              :prepopulatedOptions="doctors"
                              v-model="selectedDoctors"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !doctorsSearch || !showDeletedDoctors }">
          <ServiceMultiSelect :label="$t('doctors')"
                              :dblclick="false"
                              model="doctor"
                              title="full_name"
                              :dbsearch="true"
                              v-model="selectedDoctors"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !chairsSearch }">
          <ServiceMultiSelect :label="$t('chairs')"
                              :dblclick="false"
                              model="chair"
                              title="chair_name"
                              :dbsearch="false"
                              :prepopulatedOptions="chairs"
                              v-model="selectedChairs"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !professionSearch }">
          <ServiceMultiSelect :label="$t('profession')"
                              :dblclick="false"
                              model="profession"
                              title="title"
                              :dbsearch="true"
                              v-model="selectedProfessions"/>
        </b-col>
        <b-col md="2" cols="12"
               v-if="bLoaded"
               :class="{ 'd-none': !hasMultipleBranches }">
          <ServiceMultiSelect :label="$t('branch')"
                              :dblclick="false"
                              model="branch"
                              title="title"
                              :dbsearch="false"
                              :prepopulatedOptions="branches"
                              v-model="selectedBranches"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !oneDoctorsSearch }">
          <SelectGroup :options="doctors"
                       title="full_name"
                       :label="$t('doctor')"
                       :showlabel="true"
                       variant="white"
                       v-model="oneDoctor"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !oneTechnicSearch }">
          <ServiceMultiSelect :label="$t('technic')"
                              :dblclick="false"
                              model="technic"
                              title="title"
                              :multiple="false"
                              :dbsearch="true"
                              v-model="oneTechnic"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !oneAssistantSearch }">
          <ServiceMultiSelect :label="$t('assistant')"
                              :dblclick="false"
                              model="doctor_assistant"
                              title="title"
                              :multiple="false"
                              :dbsearch="true"
                              v-model="oneAssistant"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !oneAdministratorSearch }">
          <ServiceMultiSelect :label="$t('administrator')"
                              :dblclick="false"
                              model="administrator"
                              title="title"
                              :multiple="false"
                              :dbsearch="true"
                              v-model="oneAdministrator"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !oneCuratorSearch }">
          <ServiceMultiSelect :label="$t('curator')"
                              :dblclick="false"
                              model="curator"
                              title="title"
                              :multiple="false"
                              :dbsearch="true"
                              v-model="oneCurator"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !oneStaffSearch }">
          <ServiceMultiSelect :label="$t('who_created')"
                              :dblclick="false"
                              model="staff"
                              title="title"
                              :multiple="false"

                              :dbsearch="true"
                              v-model="oneStaff"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !f57Search }">
          <SelectGroup :options="f57Types"
                       :label="$t('type')"
                       :showlabel="true"
                       :null-option="false"
                       variant="white"
                       v-model="f57Type"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !bonusOperationTypeSearch }">
          <SelectGroup :options="bonusOperationTypes"
                       :label="$t('type')"
                       :showlabel="true"
                       :null-option="true"
                       variant="white"
                       v-model="bonusOperationType"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !diagnosesSearch }">
          <ServiceMultiSelect :label="$t('diagnoses')"
                              :dblclick="false"
                              model="diagnosis_template"
                              title="title"
                              :dbsearch="true"
                              v-model="diagnoses"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !patientsSearch }">
          <ServiceMultiSelect :label="$t('patients')"
                              :dblclick="false"
                              model="patient"
                              title="full_name"
                              :dbsearch="true"
                              v-model="patients"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !warehousesSearch }">
          <ServiceMultiSelect :label="$t('warehouses')"
                              :dblclick="false"
                              model="warehouse"
                              title="title"
                              :dbsearch="true"
                              v-model="warehouses"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !materialsSearch }">
          <ServiceMultiSelect :label="$t('materials')"
                              :dblclick="false"
                              model="material"
                              title="title"
                              :dbsearch="true"
                              v-model="materials"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !servicesSearch }">
          <ServiceMultiSelect :label="$t('services')"
                              :dblclick="false"
                              v-model="services"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showCategories }">
          <ServiceMultiSelect :label="$t('categories')"
                              :dblclick="false"
                              model="service_category"
                              title="title"
                              :dbsearch="true"
                              v-model="categories"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showMaterialCategories }">
          <ServiceMultiSelect :label="$t('categories')"
                              :dblclick="false"
                              model="materialgroup"
                              title="title"
                              :dbsearch="true"
                              v-model="materialCategories"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showSources }">
          <ServiceMultiSelect :label="$t('source')"
                              :dblclick="false"
                              model="client_source"
                              title="title"
                              :dbsearch="true"
                              v-model="sources"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showInsuranceCompanies }">
          <ServiceMultiSelect :label="$t('insurance_company')"
                              :dblclick="false"
                              model="insurance_company"
                              title="title"
                              :dbsearch="true"
                              v-model="insuranceCompanies"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showChannels }">
          <ServiceMultiSelect :label="$t('channels')"
                              :dblclick="false"
                              model="client_source"
                              title="title"
                              :dbsearch="true"
                              v-model="channels"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showFields }">
          <ServiceMultiSelect :label="$t('fields')"
                              :dblclick="false"
                              model="field"
                              title="translatedTitle"
                              :dbsearch="true"
                              v-model="fields"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showDeletedDoctorsSearch }">
          <CheckboxGroup fid="showdeleteddoctors"
                         :label="$t('show_deleted_doctors')"
                         class="deleted-checkbox"
                         v-model="showDeletedDoctors"/>
        </b-col>
        <b-col md="2" cols="12"
               :class="{ 'd-none': !showWhichSourceToggle }">
          <div class="radio-inline">
            <RadioGroup :options="[{ id: 'visit', title: $t('visit') }, { id: 'patient', title: $t('patient') } ]"
                        :label="$t('source_type')"
                        :showlabel="true"
                        v-model="whichSource"
                        validationId="doctor_salaries_type"/>
          </div>
        </b-col>
        <b-col md="2" cols="12">
          <div class="form-group no-print">
            <button @click="loadReport"
                    class="search-button btn btn-themed btn-themed-squared">
              {{ $t('apply') }}
            </button>
          </div>
        </b-col>
      </b-row>
    </div>
    <router-view v-slot="{ Component }"
                 :from="from"
                 :to="to"
                 @updateFrom="updateFrom"
                 @updateTo="updateTo"
                 v-if="bLoaded"
                 :oneDoctor="parseInt(oneDoctor)"
                 :oneTechnic="oneTechnic"
                 :oneAssistant="oneAssistant"
                 :oneAdministrator="oneAdministrator"
                 :oneCurator="oneCurator"
                 :oneStaff="oneStaff"
                 :f57Type="f57Type"
                 :onlyNew="onlyNew"
                 :hideCancelled="hideCancelled"
                 :doctors="selectedDoctors"
                 :showDeletedDoctors="showDeletedDoctors"
                 :chairs="selectedChairs"
                 :fields="fields"
                 :selectedBranches="selectedBranches.map(x => x.id)"
                 :selectedProfessions="selectedProfessions.map(x => x.code)"
                 :insuranceCompanies="insuranceCompanies"
                 :diagnoses="diagnoses"
                 :services="services"
                 :patients="patients"
                 :bonusOperationType="bonusOperationType"
                 :warehouses="warehouses"
                 :materials="materials"
                 :materialCategories="materialCategories"
                 :sources="sources"
                 :channels="channels"
                 :categories="categories"
                 :whichSource="whichSource"
    >
      <component ref="childRouterView" :is="Component" />
    </router-view>
  </section>
</template>

<script>
import DateGroup from '@/components/form/DateGroup'
import moment from 'moment'
import ExcelSvg from '@/assets/svg-vue/general/excel.svg'
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import SelectGroup from "@/components/form/SelectGroup"
import PrintSvg from '@/assets/svg-vue/general/print.svg'
import { mapState } from "vuex"
import { bonusOperationTypes, countries, f057Types } from "@/dictionaries/dictionary"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import { getInterfaceLangFull } from "@/services/lang.helper"
import RadioGroup from "@/components/form/RadioGroup.vue"

export default {
  name: "ReportsGeneral",
  components: {
    RadioGroup,
    CheckboxGroup,
    DateGroup,
    ExcelSvg,
    ServiceMultiSelect,
    SelectGroup,
    PrintSvg
  },
  data() {
    return {
      menu: [
        { to: 'ReportDay', title: 'daily_report', right: 'reports_day' },
        { to: 'ReportsPL', title: 'pl_statement', right: 'reports_pnl' },
        { to: 'ReportsCashFlows', title: 'cash_flow_statement', right: 'reports_cash' },
        { to: 'ReportCashdesk', title: 'cashdesk_report', right: 'reports_cashdesk' },
        { to: 'ReportsDebtors', title: 'debtors_statement', right: 'reports_debtors' },
        { to: 'ReportsVisits', title: 'visits', right: 'reports_visits' },
        // { to: 'ReportsVisitsDetailed', title: 'visits_detailed', right: 'reports_visits' },
        { to: 'ReportsPatients', title: 'patients', right: 'reports_patients' },
        { to: 'ReportsDiagnoses', title: 'diagnoses', right: 'reports_diagnoses' },
        { to: 'ReportsSalaries', title: 'salaries', right: 'reports_salaries' },
        { to: 'ReportsIncomeByDoctors', title: 'income_by_doctors', right: 'reports_doctors_revenues' },
        { to: 'ReportsIncomeByServices', title: 'income_by_services', right: 'reports_services_revenues' },
        { to: 'ReportsCancelledVisits', title: 'cancelled_visits', right: 'reports_cancelled' },
        { to: 'ReportsMaterials', title: 'materials', right: 'reports_materials' },
        { to: 'ReportsServices', title: 'services', right: 'reports_services' },
        { to: 'Report57', title: 'f057', right: 'reports_57' },
        { to: 'ReportStatVisits', title: 'visit_statistics', right: 'reports_stat_visits' },
        { to: 'ReportStatAdmins', title: 'admin_statistics_future', right: 'reports_stat_admins' },
        { to: 'ReportStatDoctors', title: 'doctor_statistics', right: 'reports_stat_doctors' },
        { to: 'ReportDoctorsLoad', title: 'doctors_load', right: 'reports_doctors_load' },
        { to: 'ReportDoctorsConversion', title: 'doctors_conversion', right: 'reports_doctors_conversion' },
        { to: 'ReportGuarantee', title: 'warranty_case', right: 'reports_warranty_case' },
        { to: 'ReportCRMAnalytics', title: 'crm_leads_analytics', right: 'reports_crm_analytics' },
        { to: 'ReportVisitSourceAnalytics', title: 'visits_by_source', right: 'reports_crm_analytics' },
        { to: 'ReportsMaterialsMovement', title: 'materials_movement_analytics', right: 'reports_visit_source' },
        { to: 'ReportInsurance', title: 'insurance', right: 'report_insurance' },
        { to: 'ReportBonuses', title: 'bonuses', right: 'report_bonuses' },
      ],
      selectedDoctors: [],
      selectedChairs: [],
      selectedProfessions: [],
      oneDoctor: null,
      oneTechnic: null,
      oneAssistant: null,
      oneAdministrator: null,
      oneCurator: null,
      oneStaff: null,
      insuranceCompanies: [],
      showDeletedDoctors: false,
      patients: [],
      diagnoses: [],
      services: [],
      categories: [],
      materialCategories: [],
      sources: [],
      channels: [],
      fields: [],
      warehouses: [],
      materials: [],
      whichSource: 'visit',
      f57Type: 'therapist',
      bonusOperationType: null,
      counter: 1,
      onlyNew: false,
      hideCancelled: true,
      bLoaded: false,
      f57Types: f057Types,
      bonusOperationTypes,
      from: moment().clone().startOf('month').format('YYYY-MM-DD'),
      to: moment().clone().format('YYYY-MM-DD'),
      selectedBranches: [],
    }
  },
  computed: {
    ...mapState({
      doctors: state => state.auth.doctors,
      chairs: state => state.auth.chairs,
      rights: state => state.auth.rights,
      branches: state => state.auth.branches,
      branch: state => state.auth.branch,
      clinic: state => state.auth.clinic,
      user: state => state.auth.user,
    }),
    doctorsSearch() {
      return [
        'ReportsDiagnoses',
        'ReportsVisits',
        'ReportsVisitsDetailed',
        'ReportsIncomeByDoctors',
        'ReportsIncomeByServices',
        'ReportsServices',
        'ReportStatDoctors',
        'ReportStatVisits',
        'ReportDoctorsLoad',
        'ReportGuarantee',
        'ReportCRMAnalytics',
        'ReportsMaterials',
        'ReportVisitSourceAnalytics',
        'ReportDoctorsConversion',
        'ReportInsurance'
      ].includes(this.$route.name)
    },
    chairsSearch() {
      return ['ReportStatVisits', 'ReportDoctorsLoad', 'ReportDoctorsConversion'].includes(this.$route.name)
    },
    oneDoctorsSearch() {
      return ['ReportsSalaries', 'Report57'].includes(this.$route.name)
    },
    oneTechnicSearch() {
      return ['ReportsSalaries'].includes(this.$route.name)
    },
    oneAssistantSearch() {
      return ['ReportsSalaries'].includes(this.$route.name)
    },
    oneAdministratorSearch() {
      return ['ReportsSalaries'].includes(this.$route.name)
    },
    oneCuratorSearch() {
      return ['ReportsSalaries'].includes(this.$route.name)
    },
    oneStaffSearch() {
      return ['ReportStatVisits', 'ReportVisitSourceAnalytics', 'ReportBonuses'].includes(this.$route.name)
    },
    professionSearch() {
      return ['ReportDoctorsLoad', 'ReportDoctorsConversion'].includes(this.$route.name)
    },
    servicesSearch() {
      return ['ReportsServices', 'ReportInsurance'].includes(this.$route.name)
    },
    diagnosesSearch() {
      return ['ReportsDiagnoses'].includes(this.$route.name)
    },
    f57Search() {
      return ['Report57'].includes(this.$route.name)
    },
    bonusOperationTypeSearch() {
      return ['ReportBonuses'].includes(this.$route.name)
    },
    patientsSearch() {
      return [
        'ReportsVisits',
        'ReportsVisitsDetailed',
        'ReportsServices',
        'ReportGuarantee',
        'ReportInsurance',
        'ReportBonuses'
      ].includes(this.$route.name)
    },
    warehousesSearch() {
      return ['ReportsMaterials', 'ReportsMaterialsMovement'].includes(this.$route.name)
    },
    materialsSearch() {
      return ['ReportsMaterials', 'ReportsMaterialsMovement'].includes(this.$route.name)
    },
    excelEnabled() {
      return !['ReportDay', 'Report57'].includes(this.$route.name)
    },
    showNew() {
      return ['ReportsPatients'].includes(this.$route.name)
    },
    showDeletedDoctorsSearch() {
      return ['ReportsIncomeByDoctors'].includes(this.$route.name)
    },
    showCategories() {
      return ['ReportsServices'].includes(this.$route.name)
    },
    showMaterialCategories() {
      return ['ReportsMaterialsMovement'].includes(this.$route.name)
    },
    showSources() {
      return ['ReportCRMAnalytics', 'ReportVisitSourceAnalytics'].includes(this.$route.name)
    },
    showInsuranceCompanies() {
      return ['ReportInsurance'].includes(this.$route.name)
    },
    showChannels() {
      return ['ReportCRMAnalytics'].includes(this.$route.name)
    },
    showFields() {
      return [
        'ReportCRMAnalytics',
        'ReportVisitSourceAnalytics',
        'ReportDoctorsConversion'
      ].includes(this.$route.name)
    },
    showWhichSourceToggle() {
      return ['ReportVisitSourceAnalytics'].includes(this.$route.name)
    },
    hasMultipleBranches() {
      return false
      // return this.branches.length > 1
    },
    daysLimit() {
      return this.user?.user_additional?.report_days_limit || null
    },
    minDate() {
      return this.daysLimit === null ? null : moment().subtract(this.daysLimit, 'days').format('YYYY-MM-DD')
    },
    menuFiltered() {
      return this.menu.filter(item => {
        if(item.to === 'Report57' && this.clinic.country_id !== countries.KAZAKHSTAN) {
          return false
        }
        return item
      })
    }
  },
  methods: {
    getInterfaceLangFull,
    excel() {
      if(this.$refs.childRouterView) this.$refs.childRouterView.excel()
    },
    loadReport() {
      if(this.$refs.childRouterView) this.$refs.childRouterView.loadReport()
    },
    printPressed() {
      if (window) window.print()
    },
    populateDates(route = null) {
      if(!route) route = this.$route.name
      if(route === 'ReportsPL') {
        this.from = moment().clone().startOf('month').format('YYYY-MM-DD')
      } else {
        this.from = moment().clone().format('YYYY-MM-DD')
      }
      this.to = moment().clone().format('YYYY-MM-DD')
      this.counter++
    },
    routerLinkClicked(m) {
      this.populateDates(m.to)
    },
    updateFrom(val) {
      this.from = val
    },
    updateTo(val) {
      this.to = val
    }
  },
  created() {
    this.populateDates()
    if(this.$route.name === 'ReportsGeneral') {
      const redirects = [
        { right: 'reports_day', route: 'ReportDay' },
        { right: 'reports_pnl', route: 'ReportsPL' },
        { right: 'reports_cash', route: 'ReportsCashFlows' },
        { right: 'reports_cash', route: 'ReportsCashdesk' },
        { right: 'reports_debtors', route: 'ReportsDebtors' },
        { right: 'reports_visits', route: 'ReportsVisits' },
        // { right: 'reports_visits', route: 'ReportsVisitsDetailed' },
        { right: 'reports_patients', route: 'ReportsPatients' },
        { right: 'reports_diagnoses', route: 'ReportsDiagnoses' },
        { right: 'reports_salaries', route: 'ReportsSalaries' },
        { right: 'reports_doctors_revenues', route: 'ReportsIncomeByDoctors' },
        { right: 'reports_services_revenues', route: 'ReportsIncomeByServices' },
        { right: 'reports_cancelled', route: 'ReportsCancelledVisits' },
        { right: 'reports_materials', route: 'ReportsMaterials' },
        { right: 'reports_services', route: 'ReportsServices' },
        { right: 'reports_57', route: 'Report57' },
        { right: 'reports_stat_visits', route: 'ReportStatVisits' },
        { right: 'reports_stat_admins', route: 'ReportStatAdmins' },
        { right: 'reports_stat_doctors', route: 'ReportStatDoctors' },
        { right: 'reports_doctors_load', route: 'ReportDoctorsLoad' },
        { right: 'reports_warranty_case', route: 'ReportGuarantee' },
        { right: 'reports_materials_movement_analytics', route: 'ReportsMaterialsMovement' },
        { right: 'reports_crm_analytics', route: 'ReportCRMAnalytics' },
        { right: 'reports_visit_source', route: 'ReportVisitSourceAnalytics' },
        { right: 'report_insurance', route: 'ReportInsurance' },
        { right: 'report_bonuses', route: 'ReportBonuses' },
      ]
      let redirected = false
      redirects.forEach(item => {
        if(!redirected && this.rights.includes(item.right)) {
          this.$router.push({name: item.route}).catch(()=>{})
          redirected = true
        }
      })
    }
  },
  async mounted() {
    this.selectedBranches = [this.branches.find(x => Number(x.id) === Number(this.branch))]
    this.bLoaded = true
  },
  watch: {
    oneDoctor(val) {
      if(val) {
        this.oneTechnic = null
        this.oneAssistant = null
        this.oneAdministrator = null
        this.oneCurator = null
      }
    },
    oneTechnic(val) {
      if(val) {
        this.oneDoctor = null
        this.oneAssistant = null
        this.oneAdministrator = null
        this.oneCurator = null
      }
    },
    oneAssistant(val) {
      if(val) {
        this.oneTechnic = null
        this.oneDoctor = null
        this.oneAdministrator = null
        this.oneCurator = null
      }
    },
    oneAdministrator(val) {
      if(val) {
        this.oneTechnic = null
        this.oneDoctor = null
        this.oneAssistant = null
        this.oneCurator = null
      }
    },
    oneCurator(val) {
      if(val) {
        this.oneTechnic = null
        this.oneDoctor = null
        this.oneAssistant = null
        this.oneAdministrator = null
      }
    },
  }
}
</script>

<style scoped lang="scss">
.search-button {
  height: 46px;
  display: inline-block;
}
.deleted-checkbox {
  padding-bottom: 11px;
}
@media screen and (max-width: 768px) {
  .search-button {
    height: 32px;
    width: 100%;
  }
  .deleted-checkbox {
    padding-bottom: 0;
  }
}
</style>
