<template>
  <section id="warehouse" class="warehouse">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <template v-if="document">
              <h1>
                <span class="d-md-inline d-none">
                <router-link :to="{name: 'WarehousesWarehouses' }">{{ $t('warehouses') }}</router-link>
                / <router-link :to="{name: 'WarehousesDocuments' }">{{ $t('documents') }}</router-link>
                / </span>{{ document.title }}
              </h1>
            </template>
            <h1 v-else>{{ $t('warehouses') }}</h1>
          </div>
          <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator"
               v-if="!document">
            <SearchTable/>
          </div>
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator"
               v-if="!document">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto order-md-3 order-1 table-view-navigator"
               v-if="!document && $route.name == 'WarehousesDocuments'">
            <WarehouseHeaderWarehouseFilter/>
          </div>
          <div class="col-auto order-md-4 order-1 table-view-navigator"
               v-if="!document">
            <DataTableExcel/>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator"
               v-if="!document">
            <AddObject module="warehouse"/>
          </div>
          <div class="col-auto order-md-6 table-view-navigator"
               v-if="document && document.id" >
            <DocumentItemTopButtons :document="document"/>
          </div>
          <div class="col-auto order-md-7 table-view-navigator"
               v-if="document && document.id" >
            <AddObject module="warehouse"/>
          </div>
        </div>
      </div>
    </transition>
    <div class="sub-menu no-print">
      <router-link v-for="(m, mind) in menu"
                   :to="{ name: m.to }"
                   :key="`warehouse-link-${mind}}`">
        {{ $t(m.title) }}
      </router-link>
    </div>
    <router-view/>
  </section>
</template>

<script>

import SearchTable from "@/components/parts/datatable/SearchTable"
import AddObject from "@/components/parts/datatable/AddObject"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import {mapState} from "vuex"
import DocumentItemTopButtons from "@/components/parts/warehouse/DocumentItemTopButtons"
import WarehouseHeaderWarehouseFilter
  from "@/components/parts/datatable/custom/warehouse/WarehouseHeaderWarehouseFilter"

export default {
  name: "Warehouse",
  components: {
    WarehouseHeaderWarehouseFilter,
    DocumentItemTopButtons,
    DataTableFilterButton,
    DataTableExcel,
    AddObject,
    SearchTable,
  },
  data() {
    return {
      menu: [
        { to: 'WarehousesWarehouses', title: 'warehouses' },
        { to: 'WarehousesGroups', title: 'groups' },
        // { to: 'WarehousesMaterials', title: 'nomenclature' },
        { to: 'WarehousesStocks', title: 'stocks' },
        { to: 'WarehousesStocksArchive', title: 'stocks_archive' },
        { to: 'WarehousesKits', title: 'kits' },
        { to: 'WarehousesDocuments', title: 'documents' },
        { to: 'WarehousesSuppliers', title: 'suppliers' },
      ]
    }
  },
  computed: {
    ...mapState({
      document: state => state.warehouseoperation.operation,
    }),
  }
}
</script>
