<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   initialOrder="date"
                   initialSort="desc"
                   :canHideRows="false"
                   :rows="rows"/>
  </div>
</template>

<script>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"

export default {
  name: "ReportsIncomeByDoctors",
  components: {
    DataTableHtml
  },
  props: {
    from: {},
    to: {},
    doctors: {
      type: Array
    },
    selectedBranches: {},
    showDeletedDoctors: {
      type: Boolean,
      default: false
    }
  },
  head() {
    return {
      title: this.$t('by_doctors'),
    }
  },
  data() {
    return {
      columns: [],
      rows: []
    }
  },
  methods: {
    loadReport() {
      ReportsService.loadReport('income_by_doctors', {
        from: this.from,
        to: this.to,
        branch_id: this.selectedBranches,
        doctors: this.doctors.map(a => a.id),
        showDeleted: this.showDeletedDoctors ? 1 : 0
      }).then(res => {
        this.rows = res.data.rows
        this.columns = res.data.columns
      })
    },
    excel() {
      this.$refs.datatable.excel()
    }
  },
  mounted() {
    this.loadReport()
  }
}
</script>
