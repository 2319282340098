<template>
  <g>
    <!--   SAME AS 27  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
        <path d="M25.4691 2.26976L23.3854 1H19.9017L17.8017 2.26976L19.4133 3.81627L17.3296 5.36278L19.218 6.90929L16.9226 8.60231L18.7459 10.1488L16.4505 11.5488L18.4691 12.9651L16.4505 14.5767L18.1436 16.1232L15.9133 17.5395L17.6715 19.0046L15.3761 20.5511L17.525 22.0976L15.3761 23.7093L16.9226 25.0604L14.8389 26.6069L16.6622 28.0883L14.5785 29.6348L16.3854 31.3767L15.3761 33.0534V47.3139L17.2645 54.6394H23.3854" fill="#8889BE"/>
        <path d="M18.0619 2.26976L20.1456 1H23.613L25.7293 2.26976L24.1014 3.81627L26.2014 5.36278L24.313 6.90929L26.5921 8.60231L24.7851 10.1488L27.0642 11.5488L25.0456 12.9651L27.0642 14.5767L25.3874 16.1232L27.6014 17.5395L25.8595 19.0046L28.1386 20.5511L25.9897 22.0976L28.1386 23.7093L26.5921 25.0604L28.6758 26.6069L26.8688 28.0883L28.9525 29.6348L27.1293 31.3767L28.1386 33.0534V47.3139L26.2665 54.6394H22.3595" fill="#8889BE"/>
        <path d="M42.4346 73.154C42.1238 79.7081 35.2994 78.5594 32.6237 77.6946C29.948 76.8297 22.7992 74.6405 19.11 76.3432C15.4208 78.0459 14.2721 81.3162 6.16391 79.1675C-1.94429 77.0189 1.92062 54.0997 1.92062 54.0997C1.92062 54.0997 3.63685 47.3428 12.637 47.032C13.4584 47.0114 14.2733 47.1827 15.017 47.5323C15.7606 47.8818 16.4125 48.4 16.9208 49.0456C18.4073 51.2078 22.9479 51.5051 27.5561 49.7888C32.1642 48.0726 36.1643 48.6266 37.1508 50.3159C37.7241 51.1889 38.2518 52.091 38.7319 53.0186C39.6354 54.7696 40.4297 56.5749 41.1103 58.4241C42.2998 63.2379 42.7464 68.2052 42.4346 73.154Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
        <path d="M20.2319 19.9878C20.3805 20.9338 21.5832 28.2312 23.34 31.6366C25.2725 35.2853 27.3941 31.6366 27.3941 31.6366L29.6104 18.5959C29.4752 17.5283 27.7184 4.24438 26.2725 4.52816C24.8265 4.81195 24.313 3.98761 20.2319 19.9878Z" fill="#DEE1E9"/>
        <path d="M38.8801 56.5602C38.763 53.9295 38.6234 51.2629 38.4612 48.5601C38.1549 43.3168 37.7585 38.2086 37.272 33.2356C36.5853 27.7822 36.3367 22.2826 36.5287 16.7895C36.762 12.626 36.3054 8.45254 35.1774 4.43797C35.1774 4.43797 33.4611 1.73524 32.2719 5.41096C31.3296 8.90657 30.6119 12.4589 30.1233 16.0462L27.5151 31.4518C27.5151 31.4518 25.3529 35.0194 23.461 31.3707C21.5691 27.722 20.3394 19.6138 20.3394 19.6138C20.3394 19.6138 19.4475 10.1542 20.7042 6.10015C21.961 2.04604 19.2177 0.964944 19.2177 0.964944C19.2177 0.964944 15.7312 -1.62968 11.6366 10.0461C7.54191 21.722 8.28516 28.6139 8.28516 28.6139C8.28516 28.6139 9.36625 39.3573 8.28516 42.033C6.90593 45.1883 5.92101 48.5016 5.35269 51.898" fill="#DEE1E9"/>
        <path d="M42.1911 75.9254C41.8803 82.4795 35.0559 81.3308 32.3801 80.466C29.7044 79.6011 22.5557 77.4119 18.8665 79.1146C15.1772 80.8173 14.0286 84.0876 5.92036 81.9389C-2.18784 79.7903 1.67707 56.8711 1.67707 56.8711C1.67707 56.8711 3.39331 50.1142 12.3934 49.8034C13.2149 49.7828 14.0298 49.9541 14.7734 50.3037C15.5171 50.6532 16.1689 51.1714 16.6773 51.817C18.1638 53.9791 22.7044 54.2765 27.3125 52.5602C31.9207 50.844 35.9207 51.398 36.9072 53.0873C37.4806 53.9603 38.0083 54.8624 38.4883 55.79C39.3919 57.541 40.1862 59.3463 40.8667 61.1955C42.0563 66.0092 42.5029 70.9766 42.1911 75.9254Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path opacity="0.2" d="M10.0558 54.0996C9.01522 52.1679 11.0152 50.0299 12.5153 44.8395C13.6256 41.3212 14.085 37.5973 13.8666 33.8838C13.4882 29.2833 12.1504 28.1921 12.299 23.6653C12.5582 21.2722 13.0104 18.9087 13.6504 16.6024C16.2856 5.20429 18.5333 2.00867 18.8577 2.06765C19.182 2.12663 17.2856 4.34906 17.4478 12.223C17.5424 17.0447 17.5559 18.475 17.664 20.7458C17.8126 23.3115 17.9342 23.9013 18.3397 28.2954C19.1099 36.5969 19.1235 39.4723 19.4613 39.487C19.7991 39.5018 19.9478 34.5916 21.2451 27.5581C22.4343 21.0554 24.657 6.0484 25.8732 6.24009C26.5354 6.32856 26.7587 15.3343 27.0425 19.7726C27.6236 28.7377 26.9073 33.4415 27.7182 33.6479C28.529 33.8543 30.6776 28.0152 31.502 25.3611C33.0696 20.4067 33.399 4.70027 33.8044 4.72976C34.2099 4.75925 33.8534 20.849 34.1777 28.0447C34.4615 34.1492 34.9345 37.0098 33.2858 40.2243C32.3669 42.0379 31.9344 41.6988 31.502 43.1733C30.502 46.7564 33.6371 48.7913 33.5155 53.8784C33.5155 55.2202 33.4209 57.6237 31.9479 58.995C30.4749 60.3663 28.3263 59.8797 23.9208 58.995C21.1235 58.4347 21.5829 58.2872 14.3261 56.046C11.2044 55.1023 10.5152 54.94 10.0558 54.0996Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M20.2319 23.145C20.3805 24.0909 21.5832 31.3883 23.34 34.7938C25.2725 38.4425 27.3941 34.7938 27.3941 34.7938L29.6104 21.7531C29.4752 20.6855 27.7184 7.40154 26.2725 7.68533C24.8265 7.96912 24.313 7.14478 20.2319 23.145Z" fill="#DEE1E9"/>
      <path d="M38.8801 59.7174C38.763 57.0867 38.6234 54.42 38.4612 51.7173C38.1549 46.474 37.7585 41.3658 37.272 36.3928C36.5853 30.9394 36.3367 25.4397 36.5287 19.9466C36.762 15.7831 36.3054 11.6097 35.1774 7.59513C35.1774 7.59513 33.4611 4.8924 32.2719 8.56812C31.3296 12.0637 30.6119 15.6161 30.1233 19.2034L27.5151 34.609C27.5151 34.609 25.3529 38.1766 23.461 34.5279C21.5691 30.8792 20.3394 22.771 20.3394 22.771C20.3394 22.771 19.4475 13.3114 20.7042 9.25731C21.961 5.20321 19.2177 4.12211 19.2177 4.12211C19.2177 4.12211 15.7312 1.52749 11.6366 13.2033C7.54191 24.8791 8.28516 31.7711 8.28516 31.7711C8.28516 31.7711 9.36625 42.5145 8.28516 45.1902C6.90593 48.3455 5.92101 51.6588 5.35269 55.0552" fill="#DEE1E9"/>
      <path d="M42.1911 79.0825C41.8803 85.6367 35.0559 84.488 32.3801 83.6231C29.7044 82.7582 22.5557 80.569 18.8665 82.2718C15.1772 83.9745 14.0286 87.2448 5.92036 85.0961C-2.18784 82.9474 1.67707 60.0282 1.67707 60.0282C1.67707 60.0282 3.39331 53.2714 12.3934 52.9606C13.2149 52.94 14.0298 53.1113 14.7734 53.4608C15.5171 53.8104 16.1689 54.3285 16.6773 54.9741C18.1638 57.1363 22.7044 57.4336 27.3125 55.7174C31.9207 54.0011 35.9207 54.5552 36.9072 56.2444C37.4806 57.1174 38.0083 58.0196 38.4883 58.9472C39.3919 60.6982 40.1862 62.5035 40.8667 64.3526C42.0563 69.1664 42.5029 74.1338 42.1911 79.0825Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M10.0558 57.2567C9.01522 55.3251 11.0152 53.187 12.5153 47.9967C13.6256 44.4783 14.085 40.7545 13.8666 37.041C13.4882 32.4405 12.1504 31.3493 12.299 26.8225C12.5582 24.4294 13.0104 22.0659 13.6504 19.7595C16.2856 8.36144 18.5333 5.16582 18.8577 5.2248C19.182 5.28378 17.2856 7.50621 17.4478 15.3802C17.5424 20.2019 17.5559 21.6322 17.664 23.9029C17.8126 26.4686 17.9342 27.0584 18.3397 31.4525C19.1099 39.7541 19.1235 42.6294 19.4613 42.6442C19.7991 42.6589 19.9478 37.7487 21.2451 30.7152C22.4343 24.2126 24.657 9.20555 25.8732 9.39724C26.5354 9.48571 26.7587 18.4914 27.0425 22.9297C27.6236 31.8949 26.9073 36.5986 27.7182 36.805C28.529 37.0115 30.6776 31.1724 31.502 28.5182C33.0696 23.5638 33.399 7.85742 33.8044 7.88691C34.2099 7.9164 33.8534 24.0062 34.1777 31.2018C34.4615 37.3064 34.9345 40.167 33.2858 43.3814C32.3669 45.1951 31.9344 44.8559 31.502 46.3305C30.502 49.9136 33.6371 51.9484 33.5155 57.0355C33.5155 58.3774 33.4209 60.7808 31.9479 62.1522C30.4749 63.5235 28.3263 63.0369 23.9208 62.1522C21.1235 61.5918 21.5829 61.4444 14.3261 59.2031C11.2044 58.2594 10.5152 58.0972 10.0558 57.2567Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="19" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="26" cy="9" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="34" cy="7" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
        <path d="M5.57682 81.5133C13.685 83.662 14.7931 80.3241 18.5094 78.6755C22.2257 77.0268 29.3204 79.2025 32.0231 80.0268C34.7258 80.8512 41.5637 82.162 41.9286 75.5538C42.2393 70.5589 41.7835 65.5458 40.5772 60.6888C40.0063 59.1282 39.3429 57.6029 38.5907 56.1211C38.4826 53.4815 38.3475 50.8148 38.1853 48.121C37.861 42.8777 37.4646 37.7696 36.9961 32.7965C36.3094 27.3431 36.0608 21.8435 36.2529 16.3504C36.4906 12.1867 36.0339 8.01259 34.9015 3.99887C34.9015 3.99887 33.1988 1.29613 32.0096 4.97186C31.0351 8.48816 30.2858 12.063 29.7663 15.6747L29.3744 17.999C29.2393 16.9315 27.4825 3.66103 26.05 3.94482C24.6176 4.2286 24.0365 3.35021 19.9689 19.3369C19.9663 19.3054 19.9663 19.2738 19.9689 19.2423C19.9689 19.2423 19.0635 9.78272 20.3338 5.72862C21.604 1.67452 18.8473 0.593423 18.8473 0.593423C18.8473 0.593423 15.3472 -2.0012 11.2526 9.67462C7.15792 21.3504 7.91468 28.2424 7.91468 28.2424C7.91468 28.2424 8.99578 39.0534 7.91468 41.6615C6.53546 44.8168 5.55054 48.1301 4.98222 51.5265C3.26712 52.7352 2.00259 54.4799 1.38758 56.486C1.38758 56.486 -2.53139 79.3511 5.57682 81.5133Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M5.57682 81.5133C13.685 83.662 14.7931 80.3241 18.5094 78.6755C22.2257 77.0268 29.3204 79.2025 32.0231 80.0268C34.7258 80.8512 41.5637 82.162 41.9286 75.5538C42.2393 70.5589 41.7835 65.5458 40.5772 60.6888C40.0063 59.1282 39.3429 57.6029 38.5907 56.1211C38.4826 53.4815 38.3475 50.8148 38.1853 48.121C37.861 42.8777 37.4646 37.7696 36.9961 32.7965C36.3094 27.3431 36.0608 21.8435 36.2529 16.3504C36.4906 12.1867 36.0339 8.01259 34.9015 3.99887C34.9015 3.99887 33.1988 1.29613 32.0096 4.97186C31.0351 8.48816 30.2858 12.063 29.7663 15.6747L29.3744 17.999C29.2393 16.9315 27.4825 3.66103 26.05 3.94482C24.6176 4.2286 24.0365 3.35021 19.9689 19.3369C19.9663 19.3054 19.9663 19.2738 19.9689 19.2423C19.9689 19.2423 19.0635 9.78272 20.3338 5.72862C21.604 1.67452 18.8473 0.593423 18.8473 0.593423C18.8473 0.593423 15.3472 -2.0012 11.2526 9.67462C7.15792 21.3504 7.91468 28.2424 7.91468 28.2424C7.91468 28.2424 8.99578 39.0534 7.91468 41.6615C6.53546 44.8168 5.55054 48.1301 4.98222 51.5265C3.26712 52.7352 2.00259 54.4799 1.38758 56.486C1.38758 56.486 -2.53139 79.3511 5.57682 81.5133Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M14.4133 19.5048C14.562 20.4507 15.7647 27.7481 17.5215 31.1536C19.4539 34.8023 21.5756 31.1536 21.5756 31.1536L23.7918 18.1129C23.6567 17.0453 21.8999 3.76134 20.4539 4.04513C19.008 4.32892 18.4944 3.50458 14.4133 19.5048Z" fill="#C7CAD5"/>
      <path d="M32.6427 48.0771C32.8048 50.7798 32.9445 53.4465 33.0616 56.0772L19.6269 54.4717L9.49782 56.0772L0.837849 54.4717C1.40617 51.0753 1.08739 44.7053 2.46662 41.55C3.54771 38.8743 2.46662 28.1309 2.46662 28.1309C2.46662 28.1309 1.72336 21.2389 5.81801 9.5631C9.91265 -2.11271 13.3992 0.481912 13.3992 0.481912C13.3992 0.481912 16.1425 1.56301 14.8857 5.61711C13.6289 9.67122 14.5208 19.1308 14.5208 19.1308C14.5208 19.1308 15.7506 27.239 17.6425 30.8877C19.5344 34.5364 21.6966 30.9688 21.6966 30.9688L24.3047 15.5632C24.7933 11.9759 25.511 8.42354 26.4534 4.92792C27.6426 1.2522 29.3588 3.95494 29.3588 3.95494C30.4868 7.96951 30.9434 12.1429 30.7102 16.3064C30.5182 21.7995 30.7667 27.2992 31.4535 32.7526C31.9399 37.7256 32.3364 42.8338 32.6427 48.0771Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M7.94707 38.6437C8.16808 43.0137 7.70313 47.3959 6.57916 51.5363C6.24078 52.8976 5.87726 54.0804 5.52827 55.1219L11.5037 58.0434L20.5529 56.9051L27.041 57.588C26.3474 53.6758 24.9772 52.9959 25.7983 49.5755C26.236 47.8403 26.6737 48.2394 27.6039 46.1051C29.2727 42.3223 28.794 38.956 28.5067 31.7722C28.1784 23.3043 28.5393 4.36984 28.1289 4.33513C27.7185 4.30043 27.385 22.7837 25.7983 28.6141C24.9638 31.7375 22.7889 38.6089 21.9681 38.366C21.1474 38.1231 21.8724 32.5877 21.2842 22.0375C20.9969 16.8145 20.7709 6.2166 20.1006 6.11249C18.8695 5.88691 16.6196 23.5472 15.4158 31.1995C14.1027 39.4765 13.9522 45.2548 13.6102 45.2375C13.2682 45.2201 13.2545 41.8364 12.4748 32.0671C12.0645 26.8962 11.9414 26.2021 11.7909 23.1828C11.6815 20.5106 11.6678 18.8274 11.572 13.1532C11.4079 3.88712 13.3275 1.27176 12.9992 1.20236C12.6709 1.13295 10.3956 4.89355 7.7282 18.3068C7.08039 21.021 6.62264 23.8023 6.36029 26.6185C6.20982 31.9457 7.56405 33.2298 7.94707 38.6437Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M32.1072 43.2378C19.6099 41.7743 13.1571 43.3709 2.43051 49.7034" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M20.416 20.0927C20.5646 21.0387 21.7674 28.336 23.5241 31.7415C25.4566 35.3902 27.5782 31.7415 27.5782 31.7415L29.7945 18.7008C29.6593 17.6332 27.9026 4.34927 26.4566 4.63305C25.0106 4.91684 24.4971 4.0925 20.416 20.0927Z" fill="#C7CAD5"/>
      <path d="M39.0643 56.6651C38.9471 54.0344 38.8075 51.3677 38.6453 48.665C38.339 43.4217 37.9426 38.3135 37.4561 33.3405C36.7694 27.8871 36.5208 22.3875 36.7129 16.8944C36.9461 12.7309 36.4895 8.55743 35.3615 4.54286C35.3615 4.54286 33.6453 1.84012 32.4561 5.51584C31.5137 9.01146 30.796 12.5638 30.3074 16.1511L27.6993 31.5567C27.6993 31.5567 25.5371 35.1243 23.6451 31.4756C21.7532 27.8269 20.5235 19.7187 20.5235 19.7187C20.5235 19.7187 19.6316 10.2591 20.8884 6.20503C22.1451 2.15093 19.4019 1.06983 19.4019 1.06983C19.4019 1.06983 15.9153 -1.52479 11.8207 10.151C7.72603 21.8268 8.46929 28.7188 8.46929 28.7188C8.46929 28.7188 9.55038 39.4622 8.46929 42.1379C7.09006 45.2932 6.10514 48.6065 5.53682 52.0029" fill="#DEE1E9"/>
      <path d="M6.10449 82.0438C14.2127 84.1925 15.3614 80.9222 19.0506 79.2195C21.249 78.2048 24.6758 78.5722 27.6525 79.2178V75.7596L31.4381 78.1575L33.6669 75.7596L31.4381 71.4204L35.0057 72.1915L33.6669 69.1566L37.476 67.6418L36.2684 65.3284L37.476 62.8615L35.0057 59.9097L40.1854 59.1159L38.6725 55.8949C38.1924 54.9673 37.6647 54.0652 37.0914 53.1921C36.1049 51.5029 32.1048 50.9489 27.4966 52.6651C22.8885 54.3813 18.3479 54.084 16.8614 51.9219C16.3531 51.2762 15.7012 50.7581 14.9575 50.4086C14.2139 50.059 13.399 49.8877 12.5775 49.9083C3.57744 50.2191 1.8612 56.976 1.8612 56.976C1.8612 56.976 -2.00372 79.8952 6.10449 82.0438Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M35.6081 55.7875C24.6073 60.1394 22.0877 65.0334 23.3466 77.6219" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M10.0922 63.0214C9.03895 60.7483 11.0635 58.2322 12.5818 52.1242C13.7058 47.9838 14.1708 43.6016 13.9497 39.2315C13.5667 33.8176 12.2125 32.5336 12.363 27.2064C12.6253 24.3902 13.0831 21.6089 13.7309 18.8947C16.3983 5.48145 18.6736 1.72084 19.0019 1.79025C19.3302 1.85965 17.4105 4.47501 17.5747 13.7411C17.6704 19.4153 17.6841 21.0984 17.7936 23.7707C17.944 26.79 18.0671 27.4841 18.4775 32.655C19.2572 42.4243 19.2709 45.808 19.6129 45.8254C19.9549 45.8427 20.1053 40.0644 21.4185 31.7874C22.6223 24.1351 24.8722 6.4748 26.1033 6.70038C26.7736 6.80449 26.9996 17.4024 27.2868 22.6254C27.875 33.1756 27.15 38.711 27.9708 38.9539C28.7915 39.1968 30.9665 32.3254 31.8009 29.202C33.3877 23.3716 33.7212 4.88832 34.1316 4.92302C34.542 4.95773 34.1811 23.8922 34.5094 32.36C34.7966 39.5439 35.2754 42.9102 33.6066 46.693C32.6764 48.8273 32.2387 48.4282 31.8009 50.1634C30.7887 54.38 33.9622 56.7747 33.8391 62.7612C33.8391 64.3402 33.7434 67.1686 32.2523 68.7824C30.7613 70.3962 28.5863 69.8235 24.127 68.7824C21.2954 68.123 21.7605 67.9495 14.4148 65.3119C11.255 64.2014 10.5573 64.0105 10.0922 63.0214Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M20.1415 19.4391C20.2902 20.385 21.4929 27.6824 23.2497 31.0879C25.1821 34.7366 27.3038 31.0879 27.3038 31.0879L29.52 18.0472C29.3849 16.9796 27.6281 3.69565 26.1821 3.97944C24.7362 4.26323 24.2227 3.43889 20.1415 19.4391Z" fill="#C7CAD5"/>
      <path d="M38.3709 72.3971C38.2538 69.7665 38.533 50.7141 38.3709 48.0114C38.0646 42.7681 37.6682 37.6599 37.1817 32.6869C36.4949 27.2335 36.2464 21.7338 36.4384 16.2407C36.6716 12.0772 36.215 7.90382 35.0871 3.88925C35.0871 3.88925 33.3708 1.18651 32.1816 4.86223C31.2392 8.35785 30.5215 11.9102 30.0329 15.4975L27.4248 30.9031C27.4248 30.9031 25.2626 34.4707 23.3707 30.822C21.4788 27.1733 20.249 19.0651 20.249 19.0651C20.249 19.0651 19.3571 9.60553 20.6139 5.55142C21.8707 1.49732 19.1274 0.416223 19.1274 0.416223C19.1274 0.416223 15.6409 -2.1784 11.5462 9.49742C7.45158 21.1732 8.19483 28.0652 8.19483 28.0652C8.19483 28.0652 9.27593 38.8086 8.19483 41.4843C6.81561 44.6396 5.41179 64.3386 4.84347 67.7349" fill="#DEE1E9"/>
      <path d="M32.7166 79.3914C35.3923 80.2563 42.2167 81.4049 42.5275 74.8508C42.8393 69.902 42.3927 64.9347 41.2032 60.1209C40.5226 58.2717 38.9541 52.1223 38.0505 50.3713C34.9304 49.4809 32.1547 48.8262 29.5505 48.3982C28.701 48.2586 29.3696 61.963 28.5505 61.8713C28.3433 63.5277 18.0401 63.4033 15.0819 61.8713C14.2481 61.9634 15.9459 48.2592 15.0819 48.3982C12.3836 48.8323 9.50717 49.4934 6.25679 50.3713C4.75679 50.3713 2.0135 55.7965 2.0135 55.7965C2.0135 55.7965 -1.85142 78.7157 6.25679 80.8644C14.365 83.0131 15.5137 79.7428 19.2029 78.04C22.8921 76.3373 30.0409 78.5265 32.7166 79.3914Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M20.1415 19.6899C20.2902 20.6358 21.4929 27.9332 23.2497 31.3387C25.1821 34.9874 27.3038 31.3387 27.3038 31.3387L29.52 18.298C29.3849 17.2304 27.6281 3.94644 26.1821 4.23023C24.7362 4.51402 24.2227 3.68968 20.1415 19.6899Z" fill="#C7CAD5"/>
      <path d="M38.3709 72.6479C38.2538 70.0173 38.533 50.9649 38.3709 48.2622C38.0646 43.0189 37.6682 37.9107 37.1817 32.9377C36.4949 27.4843 36.2464 21.9846 36.4384 16.4915C36.6716 12.328 36.215 8.15461 35.0871 4.14003C35.0871 4.14003 33.3708 1.4373 32.1816 5.11302C31.2392 8.60864 30.5215 12.161 30.0329 15.7483L27.4248 31.1539C27.4248 31.1539 25.2626 34.7215 23.3707 31.0728C21.4788 27.4241 20.249 19.3159 20.249 19.3159C20.249 19.3159 19.3571 9.85631 20.6139 5.80221C21.8707 1.74811 19.1274 0.667009 19.1274 0.667009C19.1274 0.667009 15.6409 -1.92762 11.5462 9.7482C7.45158 21.424 8.19483 28.316 8.19483 28.316C8.19483 28.316 9.27593 39.0594 8.19483 41.7351C6.81561 44.8904 5.41179 64.5894 4.84347 67.9857" fill="#DEE1E9"/>
      <path d="M32.7166 79.6422C35.3923 80.5071 42.2167 81.6557 42.5275 75.1016C42.8393 70.1528 42.3927 65.1855 41.2032 60.3717C40.5226 58.5225 38.9541 52.3731 38.0505 50.6221C34.9304 49.7317 32.1547 49.077 29.5505 48.649C28.701 48.5094 29.3696 62.2138 28.5505 62.1221C28.3433 63.7785 18.0401 63.654 15.0819 62.1221C14.2481 62.2142 15.9459 48.51 15.0819 48.649C12.3836 49.0831 9.50717 49.7442 6.25679 50.6221C4.75679 50.6221 2.0135 56.0473 2.0135 56.0473C2.0135 56.0473 -1.85142 78.9665 6.25679 81.1152C14.365 83.2638 15.5137 79.9935 19.2029 78.2908C22.8921 76.5881 30.0409 78.7773 32.7166 79.6422Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M19.6386 19.7575C19.7872 20.7035 20.99 28.0009 22.7467 31.4063C24.6792 35.055 26.8008 31.4063 26.8008 31.4063L29.0171 18.3656C28.882 17.298 27.1252 4.01409 25.6792 4.29788C24.2332 4.58167 23.7197 3.75733 19.6386 19.7575Z" fill="#C7CAD5"/>
      <path d="M37.868 72.7156C37.7509 70.0849 38.0301 51.0326 37.8679 48.3298C37.5616 43.0865 37.1652 37.9784 36.6787 33.0053C35.992 27.5519 35.7435 22.0523 35.9355 16.5592C36.1687 12.3957 35.7121 8.22226 34.5841 4.20768C34.5841 4.20768 32.8679 1.50495 31.6787 5.18067C30.7363 8.67629 30.0186 12.2286 29.53 15.8159L26.9219 31.2215C26.9219 31.2215 24.7597 34.7891 22.8678 31.1404C20.9758 27.4917 19.7461 19.3835 19.7461 19.3835C19.7461 19.3835 18.8542 9.92396 20.111 5.86986C21.3677 1.81576 18.6245 0.734658 18.6245 0.734658C18.6245 0.734658 15.1379 -1.85997 11.0433 9.81585C6.94865 21.4917 7.6919 28.3836 7.6919 28.3836C7.6919 28.3836 8.773 39.127 7.6919 41.8027C6.31268 44.958 4.90886 64.657 4.34054 68.0534" fill="#DEE1E9"/>
      <path d="M32.2136 79.7098C34.8893 80.5747 41.7138 81.7234 42.0246 75.1692C42.3364 70.2205 41.8898 65.2531 40.7002 60.4393C40.0197 58.5902 38.4512 52.4408 37.5476 50.6897C34.4274 49.7993 31.6518 49.1446 29.0476 48.7166C28.198 48.577 28.8667 62.2814 28.0476 62.1897C28.0476 62.1897 17.774 61.8368 14.579 62.1897C13.7451 62.2818 15.443 48.5776 14.579 48.7166C11.8807 49.1507 9.00424 49.8118 5.75386 50.6897C4.25386 50.6897 1.51057 56.115 1.51057 56.115C1.51057 56.115 -2.35435 79.0341 5.75386 81.1828C13.8621 83.3315 15.0107 80.0612 18.7 78.3585C22.3892 76.6557 29.5379 78.845 32.2136 79.7098Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask2027" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="43" height="35">
        <path d="M32.2136 79.7098C34.8893 80.5747 41.7138 81.7234 42.0246 75.1692C42.3364 70.2205 41.8898 65.2531 40.7002 60.4393C40.0197 58.5902 38.5244 53.4666 37.6208 51.7156C37.6208 51.7156 35.0484 50.6898 29.1208 49.7156C28.2713 49.576 28.8667 62.2814 28.0476 62.1897C22.789 63.2805 19.8379 63.3529 14.579 62.1897C13.7451 62.2818 14.9848 49.0766 14.1208 49.2156C11.4225 49.6497 9.00424 50.8377 5.75386 51.7156C4.25386 51.7156 1.51057 56.115 1.51057 56.115C1.51057 56.115 -2.35435 79.0341 5.75386 81.1828C13.8621 83.3315 15.0107 80.0612 18.7 78.3585C22.3892 76.6558 29.5379 78.845 32.2136 79.7098Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2027)">
        <path d="M34.1208 40.7156H9.12085V66.2635C18.884 68.1898 24.3577 68.2094 34.1208 66.2635V40.7156Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M20.366 19.4778C20.5147 20.4238 21.7174 27.7211 23.4742 31.1266C25.4066 34.7753 27.5283 31.1266 27.5283 31.1266L29.7445 18.0859C29.6094 17.0183 27.8526 3.73437 26.4066 4.01815C24.9607 4.30194 24.4471 3.4776 20.366 19.4778Z" fill="#DEE1E9"/>
      <path d="M39.0143 56.0504C38.8972 53.4197 38.7575 50.753 38.5954 48.0503C38.289 42.807 37.8926 37.6988 37.4062 32.7258C36.7194 27.2724 36.4709 21.7727 36.6629 16.2796C36.8961 12.1161 36.4395 7.94272 35.3115 3.92814C35.3115 3.92814 33.5953 1.22541 32.4061 4.90113C31.4637 8.39675 30.746 11.9491 30.2574 15.5364L27.6493 30.942C27.6493 30.942 25.4871 34.5096 23.5952 30.8609C21.7033 27.2122 20.4735 19.104 20.4735 19.104C20.4735 19.104 19.5816 9.64442 20.8384 5.59032C22.0952 1.53622 19.3519 0.455117 19.3519 0.455117C19.3519 0.455117 15.8654 -2.13951 11.7707 9.53631C7.67606 21.2121 8.41932 28.1041 8.41932 28.1041C8.41932 28.1041 9.50041 38.8475 8.41932 41.5232C7.04009 44.6785 6.05517 47.9918 5.48685 51.3882" fill="#DEE1E9"/>
      <path d="M42.3252 75.4156C42.0144 81.9697 35.19 80.8211 32.5143 79.9562C29.8386 79.0913 22.6899 76.9021 19.0006 78.6048C15.3114 80.3075 14.1627 83.5779 6.05452 81.4292C-2.05368 79.2805 1.81123 56.3613 1.81123 56.3613C1.81123 56.3613 3.52746 49.6045 12.5276 49.2936C13.349 49.2731 14.1639 49.4444 14.9076 49.7939C15.6512 50.1434 16.3031 50.6616 16.8114 51.3072C18.2979 53.4694 22.8385 53.7667 27.4467 52.0505C32.0548 50.3342 36.0549 50.8883 37.0414 52.5775C37.6147 53.4505 38.1424 54.3526 38.6225 55.2802C39.526 57.0313 40.3204 58.8365 41.0009 60.6857C42.1904 65.4995 42.637 70.4668 42.3252 75.4156Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M10.1899 53.5898C9.14937 51.6582 11.1494 49.5202 12.6494 44.3298C13.7598 40.8115 14.2191 37.0876 14.0008 33.3741C13.6224 28.7736 12.2845 27.6824 12.4332 23.1556C12.6924 20.7625 13.1446 18.399 13.7846 16.0927C16.4197 4.69457 18.6675 1.49895 18.9918 1.55793C19.3162 1.61691 17.4197 3.83934 17.5819 11.7133C17.6765 16.535 17.69 17.9653 17.7981 20.2361C17.9468 22.8017 18.0684 23.3915 18.4738 27.7856C19.2441 36.0872 19.2576 38.9625 19.5954 38.9773C19.9333 38.992 20.0819 34.0819 21.3793 27.0484C22.5685 20.5457 24.7912 5.53868 26.0074 5.73037C26.6696 5.81884 26.8928 14.8246 27.1766 19.2629C27.7577 28.228 27.0415 32.9317 27.8523 33.1382C28.6631 33.3446 30.8118 27.5055 31.6361 24.8513C33.2037 19.8969 33.5332 4.19055 33.9386 4.22004C34.344 4.24953 33.9875 20.3393 34.3118 27.535C34.5956 33.6395 35.0686 36.5001 33.4199 39.7146C32.501 41.5282 32.0686 41.1891 31.6361 42.6636C30.6361 46.2467 33.7713 48.2816 33.6497 53.3687C33.6497 54.7105 33.5551 57.114 32.0821 58.4853C30.6091 59.8566 28.4604 59.37 24.055 58.4853C21.2576 57.925 21.7171 57.7775 14.4602 55.5362C11.3386 54.5925 10.6494 54.4303 10.1899 53.5898Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M36.5177 17.8706C34.1671 16.5064 32.8544 16.5685 30.5177 17.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M28.5177 17.8706C25.7753 16.5064 24.2439 16.5685 21.5177 17.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M19.5177 17.8706C15.9917 16.5064 14.0228 16.5685 10.5177 17.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M20.6386 21.8084C20.7872 22.7543 21.99 30.0517 23.7467 33.4572C25.6792 37.1059 27.8008 33.4572 27.8008 33.4572L30.0171 20.4165C29.882 19.3489 28.1252 6.06493 26.6792 6.34872C25.2332 6.63251 24.7197 5.80817 20.6386 21.8084Z" fill="#DEE1E9"/>
      <path d="M39.2869 58.3808C39.1697 55.7501 39.0301 53.0834 38.8679 50.3807C38.5616 45.1374 38.1652 40.0292 37.6787 35.0562C36.992 29.6028 36.7435 24.1031 36.9355 18.61C37.1687 14.4465 36.7121 10.2731 35.5841 6.25853C35.5841 6.25853 33.8679 3.55579 32.6787 7.23151C31.7363 10.7271 31.0186 14.2795 30.53 17.8668L27.9219 33.2724C27.9219 33.2724 25.7597 36.84 23.8678 33.1913C21.9758 29.5426 20.7461 21.4344 20.7461 21.4344C20.7461 21.4344 19.8542 11.9748 21.111 7.9207C22.3677 3.8666 19.6245 2.7855 19.6245 2.7855C19.6245 2.7855 16.1379 0.190876 12.0433 11.8667C7.94864 23.5425 8.6919 30.4345 8.6919 30.4345C8.6919 30.4345 9.77299 41.1779 8.6919 43.8536C7.31267 47.0089 6.32775 50.3222 5.75943 53.7186" fill="#DEE1E9"/>
      <mask id="mask2227" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="44" height="35">
        <path d="M42.7585 78.3247C42.4476 84.8788 35.6232 83.7301 32.9475 82.8652C30.2718 82.0004 23.1231 79.8112 19.4339 81.5139C15.7446 83.2166 14.596 86.4869 6.48775 84.3382C-1.62046 82.1896 2.24445 59.2704 2.24445 59.2704C2.24445 59.2704 3.96069 52.5135 12.9608 52.2027C13.7822 52.1821 14.5972 52.3534 15.3408 52.703C16.0844 53.0525 16.7363 53.5706 17.2446 54.2163C18.7311 56.3784 23.2717 56.6757 27.8799 54.9595C32.4881 53.2433 36.4881 53.7973 37.4746 55.4865C38.0479 56.3596 38.5757 57.2617 39.0557 58.1893C39.9593 59.9403 40.7536 61.7456 41.4341 63.5947C42.6237 68.4085 43.0703 73.3759 42.7585 78.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2227)">
        <path d="M42.7585 78.3247C42.4476 84.8788 35.6232 83.7301 32.9475 82.8652C30.2718 82.0004 23.1231 79.8112 19.4339 81.5139C15.7446 83.2166 14.596 86.4869 6.48775 84.3382C-1.62046 82.1896 2.24445 59.2704 2.24445 59.2704C2.24445 59.2704 3.96069 52.5135 12.9608 52.2027C13.7822 52.1821 14.5972 52.3534 15.3408 52.703C16.0844 53.0525 16.7363 53.5706 17.2446 54.2163C18.7311 56.3784 23.2717 56.6757 27.8799 54.9595C32.4881 53.2433 36.4881 53.7973 37.4746 55.4865C38.0479 56.3596 38.5757 57.2617 39.0557 58.1893C39.9593 59.9403 40.7536 61.7456 41.4341 63.5947C42.6237 68.4085 43.0703 73.3759 42.7585 78.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M22.3399 67.201C23.3203 67.201 24.8547 74.2024 24.8547 82.2011H22.9317H19.8547C19.8547 74.2024 21.3596 67.201 22.3399 67.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M10.1464 65.4332C9.09313 63.16 11.1176 60.644 12.636 54.536C13.76 50.3956 14.2249 46.0133 14.0039 41.6433C13.6209 36.2294 12.2667 34.9453 12.4171 29.6182C12.6795 26.802 13.1372 24.0206 13.7851 21.3065C16.4525 7.89319 18.7278 4.13258 19.0561 4.20199C19.3844 4.2714 17.4647 6.88676 17.6289 16.1529C17.7246 21.827 17.7383 23.5102 17.8477 26.1824C17.9982 29.2017 18.1213 29.8958 18.5317 35.0668C19.3114 44.8361 19.3251 48.2198 19.6671 48.2371C20.009 48.2545 20.1595 42.4762 21.4727 34.1992C22.6765 26.5468 24.9264 8.88654 26.1575 9.11212C26.8278 9.21624 27.0538 19.8142 27.341 25.0372C27.9292 35.5873 27.2042 41.1227 28.025 41.3656C28.8457 41.6086 31.0207 34.7371 31.8551 31.6137C33.4419 25.7833 33.7754 7.30006 34.1858 7.33476C34.5961 7.36947 34.2353 26.3039 34.5636 34.7718C34.8508 41.9556 35.3296 45.3219 33.6608 49.1047C32.7306 51.2391 32.2928 50.84 31.8551 52.5752C30.8429 56.7918 34.0164 59.1864 33.8933 65.1729C33.8933 66.752 33.7975 69.5804 32.3065 71.1941C30.8155 72.8079 28.6405 72.2353 24.1812 71.1941C21.3496 70.5348 21.8147 70.3612 14.469 67.7237C11.3091 66.6131 10.6115 66.4223 10.1464 65.4332Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 35.1208 6.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 26.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 19.1208 4.20099)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M20.6386 26.8084C20.7872 27.7543 21.99 35.0517 23.7467 38.4572C25.6792 42.1059 27.8008 38.4572 27.8008 38.4572L30.0171 25.4165C29.882 24.3489 28.1252 11.0649 26.6792 11.3487C25.2332 11.6325 24.7197 10.8082 20.6386 26.8084Z" fill="#DEE1E9"/>
      <path d="M39.2869 63.3808C39.1697 60.7501 39.0301 58.0834 38.8679 55.3807C38.5616 50.1374 38.1652 45.0292 37.6787 40.0562C36.992 34.6028 36.7435 29.1031 36.9355 23.61C37.1687 19.4465 36.7121 15.2731 35.5841 11.2585C35.5841 11.2585 33.8679 8.55579 32.6787 12.2315C31.7363 15.7271 31.0186 19.2795 30.53 22.8668L27.9219 38.2724C27.9219 38.2724 25.7597 41.84 23.8678 38.1913C21.9758 34.5426 20.7461 26.4344 20.7461 26.4344C20.7461 26.4344 19.8542 16.9748 21.111 12.9207C22.3677 8.8666 19.6245 7.7855 19.6245 7.7855C19.6245 7.7855 16.1379 5.19088 12.0433 16.8667C7.94864 28.5425 8.6919 35.4345 8.6919 35.4345C8.6919 35.4345 9.77299 46.1779 8.6919 48.8536C7.31267 52.0089 6.32775 55.3222 5.75943 58.7186" fill="#DEE1E9"/>
      <mask id="mask2327" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="44" height="35">
        <path d="M42.7585 83.3247C42.4476 89.8788 35.6232 88.7301 32.9475 87.8652C30.2718 87.0004 23.1231 84.8112 19.4339 86.5139C15.7446 88.2166 14.596 91.4869 6.48775 89.3382C-1.62046 87.1896 2.24445 64.2704 2.24445 64.2704C2.24445 64.2704 3.96069 57.5135 12.9608 57.2027C13.7822 57.1821 14.5972 57.3534 15.3408 57.703C16.0844 58.0525 16.7363 58.5706 17.2446 59.2163C18.7311 61.3784 23.2717 61.6757 27.8799 59.9595C32.4881 58.2433 36.4881 58.7973 37.4746 60.4865C38.0479 61.3596 38.5757 62.2617 39.0557 63.1893C39.9593 64.9403 40.7536 66.7456 41.4341 68.5947C42.6237 73.4085 43.0703 78.3759 42.7585 83.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2327)">
        <path d="M42.7585 83.3247C42.4476 89.8788 35.6232 88.7301 32.9475 87.8652C30.2718 87.0004 23.1231 84.8112 19.4339 86.5139C15.7446 88.2166 14.596 91.4869 6.48775 89.3382C-1.62046 87.1896 2.24445 64.2704 2.24445 64.2704C2.24445 64.2704 3.96069 57.5135 12.9608 57.2027C13.7822 57.1821 14.5972 57.3534 15.3408 57.703C16.0844 58.0525 16.7363 58.5706 17.2446 59.2163C18.7311 61.3784 23.2717 61.6757 27.8799 59.9595C32.4881 58.2433 36.4881 58.7973 37.4746 60.4865C38.0479 61.3596 38.5757 62.2617 39.0557 63.1893C39.9593 64.9403 40.7536 66.7456 41.4341 68.5947C42.6237 73.4085 43.0703 78.3759 42.7585 83.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M22.3399 72.201C23.3203 72.201 24.8547 79.2024 24.8547 87.2011H22.9317H19.8547C19.8547 79.2024 21.3596 72.201 22.3399 72.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M10.1464 70.4332C9.09313 68.16 11.1176 65.644 12.636 59.536C13.76 55.3956 14.2249 51.0133 14.0039 46.6433C13.6209 41.2294 12.2667 39.9453 12.4171 34.6182C12.6795 31.802 13.1372 29.0206 13.7851 26.3065C16.4525 12.8932 18.7278 9.13258 19.0561 9.20199C19.3844 9.2714 17.4647 11.8868 17.6289 21.1529C17.7246 26.827 17.7383 28.5102 17.8477 31.1824C17.9982 34.2017 18.1213 34.8958 18.5317 40.0668C19.3114 49.8361 19.3251 53.2198 19.6671 53.2371C20.009 53.2545 20.1595 47.4762 21.4727 39.1992C22.6765 31.5468 24.9264 13.8865 26.1575 14.1121C26.8278 14.2162 27.0538 24.8142 27.341 30.0372C27.9292 40.5873 27.2042 46.1227 28.025 46.3656C28.8457 46.6086 31.0207 39.7371 31.8551 36.6137C33.4419 30.7833 33.7754 12.3001 34.1858 12.3348C34.5961 12.3695 34.2353 31.3039 34.5636 39.7718C34.8508 46.9556 35.3296 50.3219 33.6608 54.1047C32.7306 56.2391 32.2928 55.84 31.8551 57.5752C30.8429 61.7918 34.0164 64.1864 33.8933 70.1729C33.8933 71.752 33.7975 74.5804 32.3065 76.1941C30.8155 77.8079 28.6405 77.2353 24.1812 76.1941C21.3496 75.5348 21.8147 75.3612 14.469 72.7237C11.3091 71.6131 10.6115 71.4223 10.1464 70.4332Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 35.1208 11.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 26.1208 13.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 19.1208 9.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2327)">
        <path d="M19.1208 0.200989L18.2979 3.94009L15.2641 1.60472L17.0372 4.99799L13.212 5.1591L16.7514 6.61879L13.9247 9.20099L17.5743 8.04409L17.0687 11.8391L19.1208 8.60699L21.173 11.8391L20.6674 8.04409L24.317 9.20099L21.4903 6.61879L25.0297 5.1591L21.2045 4.99799L22.9776 1.60472L19.9437 3.94009L19.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2327" x="13.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M20.6386 26.8084C20.7872 27.7543 21.99 35.0517 23.7467 38.4572C25.6792 42.1059 27.8008 38.4572 27.8008 38.4572L30.0171 25.4165C29.882 24.3489 28.1252 11.0649 26.6792 11.3487C25.2332 11.6325 24.7197 10.8082 20.6386 26.8084Z" fill="#DEE1E9"/>
      <path d="M39.2869 63.3808C39.1697 60.7501 39.0301 58.0834 38.8679 55.3807C38.5616 50.1374 38.1652 45.0292 37.6787 40.0562C36.992 34.6028 36.7435 29.1031 36.9355 23.61C37.1687 19.4465 36.7121 15.2731 35.5841 11.2585C35.5841 11.2585 33.8679 8.55579 32.6787 12.2315C31.7363 15.7271 31.0186 19.2795 30.53 22.8668L27.9219 38.2724C27.9219 38.2724 25.7597 41.84 23.8678 38.1913C21.9758 34.5426 20.7461 26.4344 20.7461 26.4344C20.7461 26.4344 19.8542 16.9748 21.111 12.9207C22.3677 8.8666 19.6245 7.7855 19.6245 7.7855C19.6245 7.7855 16.1379 5.19088 12.0433 16.8667C7.94864 28.5425 8.6919 35.4345 8.6919 35.4345C8.6919 35.4345 9.77299 46.1779 8.6919 48.8536C7.31267 52.0089 6.32775 55.3222 5.75943 58.7186" fill="#DEE1E9"/>
      <mask id="mask2327" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="44" height="35">
        <path d="M42.7585 83.3247C42.4476 89.8788 35.6232 88.7301 32.9475 87.8652C30.2718 87.0004 23.1231 84.8112 19.4339 86.5139C15.7446 88.2166 14.596 91.4869 6.48775 89.3382C-1.62046 87.1896 2.24445 64.2704 2.24445 64.2704C2.24445 64.2704 3.96069 57.5135 12.9608 57.2027C13.7822 57.1821 14.5972 57.3534 15.3408 57.703C16.0844 58.0525 16.7363 58.5706 17.2446 59.2163C18.7311 61.3784 23.2717 61.6757 27.8799 59.9595C32.4881 58.2433 36.4881 58.7973 37.4746 60.4865C38.0479 61.3596 38.5757 62.2617 39.0557 63.1893C39.9593 64.9403 40.7536 66.7456 41.4341 68.5947C42.6237 73.4085 43.0703 78.3759 42.7585 83.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2327)">
        <path d="M42.7585 83.3247C42.4476 89.8788 35.6232 88.7301 32.9475 87.8652C30.2718 87.0004 23.1231 84.8112 19.4339 86.5139C15.7446 88.2166 14.596 91.4869 6.48775 89.3382C-1.62046 87.1896 2.24445 64.2704 2.24445 64.2704C2.24445 64.2704 3.96069 57.5135 12.9608 57.2027C13.7822 57.1821 14.5972 57.3534 15.3408 57.703C16.0844 58.0525 16.7363 58.5706 17.2446 59.2163C18.7311 61.3784 23.2717 61.6757 27.8799 59.9595C32.4881 58.2433 36.4881 58.7973 37.4746 60.4865C38.0479 61.3596 38.5757 62.2617 39.0557 63.1893C39.9593 64.9403 40.7536 66.7456 41.4341 68.5947C42.6237 73.4085 43.0703 78.3759 42.7585 83.3247Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M22.3399 72.201C23.3203 72.201 24.8547 79.2024 24.8547 87.2011H22.9317H19.8547C19.8547 79.2024 21.3596 72.201 22.3399 72.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M10.1464 70.4332C9.09313 68.16 11.1176 65.644 12.636 59.536C13.76 55.3956 14.2249 51.0133 14.0039 46.6433C13.6209 41.2294 12.2667 39.9453 12.4171 34.6182C12.6795 31.802 13.1372 29.0206 13.7851 26.3065C16.4525 12.8932 18.7278 9.13258 19.0561 9.20199C19.3844 9.2714 17.4647 11.8868 17.6289 21.1529C17.7246 26.827 17.7383 28.5102 17.8477 31.1824C17.9982 34.2017 18.1213 34.8958 18.5317 40.0668C19.3114 49.8361 19.3251 53.2198 19.6671 53.2371C20.009 53.2545 20.1595 47.4762 21.4727 39.1992C22.6765 31.5468 24.9264 13.8865 26.1575 14.1121C26.8278 14.2162 27.0538 24.8142 27.341 30.0372C27.9292 40.5873 27.2042 46.1227 28.025 46.3656C28.8457 46.6086 31.0207 39.7371 31.8551 36.6137C33.4419 30.7833 33.7754 12.3001 34.1858 12.3348C34.5961 12.3695 34.2353 31.3039 34.5636 39.7718C34.8508 46.9556 35.3296 50.3219 33.6608 54.1047C32.7306 56.2391 32.2928 55.84 31.8551 57.5752C30.8429 61.7918 34.0164 64.1864 33.8933 70.1729C33.8933 71.752 33.7975 74.5804 32.3065 76.1941C30.8155 77.8079 28.6405 77.2353 24.1812 76.1941C21.3496 75.5348 21.8147 75.3612 14.469 72.7237C11.3091 71.6131 10.6115 71.4223 10.1464 70.4332Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 35.1208 11.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 26.1208 13.201)" fill="rgba(243, 42, 102, 0.6)"/>
      <circle r="4" transform="matrix(-1 0 0 1 19.1208 9.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2327)">
        <path d="M19.1208 0.200989L18.2979 3.94009L15.2641 1.60472L17.0372 4.99799L13.212 5.1591L16.7514 6.61879L13.9247 9.20099L17.5743 8.04409L17.0687 11.8391L19.1208 8.60699L21.173 11.8391L20.6674 8.04409L24.317 9.20099L21.4903 6.61879L25.0297 5.1591L21.2045 4.99799L22.9776 1.60472L19.9437 3.94009L19.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2327" x="13.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M20.6386 19.8084C20.7872 20.7543 21.99 28.0517 23.7467 31.4572C25.6792 35.1059 27.8008 31.4572 27.8008 31.4572L30.0171 18.4165C29.882 17.3489 28.1252 4.06493 26.6792 4.34872C25.2332 4.63251 24.7197 3.80817 20.6386 19.8084Z" fill="#DEE1E9"/>
      <path d="M39.2869 56.3807C39.1697 53.75 39.0301 51.0833 38.8679 48.3806C38.5616 43.1373 38.1652 38.0291 37.6787 33.0561C36.992 27.6027 36.7435 22.1031 36.9355 16.61C37.1687 12.4465 36.7121 8.27304 35.5841 4.25846C35.5841 4.25846 33.8679 1.55573 32.6787 5.23145C31.7363 8.72707 31.0186 12.2794 30.53 15.8667L27.9219 31.2723C27.9219 31.2723 25.7597 34.8399 23.8678 31.1912C21.9758 27.5425 20.7461 19.4343 20.7461 19.4343C20.7461 19.4343 19.8542 9.97474 21.111 5.92064C22.3677 1.86654 19.6245 0.78544 19.6245 0.78544C19.6245 0.78544 16.1379 -1.80918 12.0433 9.86663C7.94864 21.5424 8.6919 28.4344 8.6919 28.4344C8.6919 28.4344 9.77299 39.1778 8.6919 41.8535C7.31267 45.0088 6.32775 48.3221 5.75943 51.7185" fill="#DEE1E9"/>
      <circle r="4" transform="matrix(-1 0 0 1 34.1208 4.20093)" fill="rgba(243, 42, 102, 0.6)"/>
      <mask id="mask2527" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="49" width="44" height="35">
        <path d="M42.7585 76.3245C42.4476 82.8787 35.6232 81.73 32.9475 80.8651C30.2718 80.0003 23.1231 77.811 19.4339 79.5138C15.7446 81.2165 14.596 84.4868 6.48775 82.3381C-1.62046 80.1894 2.24445 57.2703 2.24445 57.2703C2.24445 57.2703 3.96069 50.5134 12.9608 50.2026C13.7822 50.182 14.5972 50.3533 15.3408 50.7028C16.0844 51.0524 16.7363 51.5705 17.2446 52.2161C18.7311 54.3783 23.2717 54.6756 27.8799 52.9594C32.4881 51.2432 36.4881 51.7972 37.4746 53.4864C38.0479 54.3594 38.5757 55.2616 39.0557 56.1892C39.9593 57.9402 40.7536 59.7455 41.4341 61.5946C42.6237 66.4084 43.0703 71.3758 42.7585 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2527)">
        <path d="M42.7585 76.3245C42.4476 82.8787 35.6232 81.73 32.9475 80.8651C30.2718 80.0003 23.1231 77.811 19.4339 79.5138C15.7446 81.2165 14.596 84.4868 6.48775 82.3381C-1.62046 80.1894 2.24445 57.2703 2.24445 57.2703C2.24445 57.2703 3.96069 50.5134 12.9608 50.2026C13.7822 50.182 14.5972 50.3533 15.3408 50.7028C16.0844 51.0524 16.7363 51.5705 17.2446 52.2161C18.7311 54.3783 23.2717 54.6756 27.8799 52.9594C32.4881 51.2432 36.4881 51.7972 37.4746 53.4864C38.0479 54.3594 38.5757 55.2616 39.0557 56.1892C39.9593 57.9402 40.7536 59.7455 41.4341 61.5946C42.6237 66.4084 43.0703 71.3758 42.7585 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M22.3399 70.2009C23.3203 70.2009 24.8547 75.3353 24.8547 81.2009H22.9317H19.8547C19.8547 75.3353 21.3596 70.2009 22.3399 70.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2627" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="84">
        <path d="M20.1415 20.2429C20.2902 21.1889 21.4929 28.4863 23.2497 31.8917C25.1821 35.5404 27.3038 31.8917 27.3038 31.8917L29.52 18.851C29.3849 17.7834 27.6281 4.4995 26.1821 4.78329C24.7362 5.06708 24.2227 4.24274 20.1415 20.2429Z" fill="#C7CAD5"/>
        <path d="M38.7898 56.8153C38.6727 54.1846 38.533 51.5179 38.3709 48.8152C38.0646 43.5719 37.6682 38.4637 37.1817 33.4907C36.4949 28.0373 36.2464 22.5376 36.4384 17.0445C36.6716 12.881 36.215 8.70761 35.087 4.69303C35.087 4.69303 33.3708 1.9903 32.1816 5.66602C31.2392 9.16164 30.5215 12.714 30.0329 16.3013L27.4248 31.7069C27.4248 31.7069 25.2626 35.2745 23.3707 31.6258C21.4788 27.9771 20.249 19.8689 20.249 19.8689C20.249 19.8689 19.3571 10.4093 20.6139 6.35521C21.8707 2.30111 19.1274 1.22001 19.1274 1.22001C19.1274 1.22001 15.6409 -1.37461 11.5462 10.3012C7.45157 21.977 8.19483 28.869 8.19483 28.869C8.19483 28.869 9.27592 39.6124 8.19483 42.2881C6.8156 45.4434 5.83068 48.7567 5.26236 52.1531" fill="#DEE1E9"/>
        <path d="M42.1007 76.1805C41.7899 82.7346 34.9655 81.586 32.2898 80.7211C29.6141 79.8562 22.4654 77.667 18.7761 79.3697C15.0869 81.0724 13.9382 84.3427 5.83003 82.1941C-2.27817 80.0454 1.58674 57.1262 1.58674 57.1262C1.58674 57.1262 3.30297 50.3694 12.3031 50.0585C13.1245 50.038 13.9394 50.2093 14.6831 50.5588C15.4267 50.9083 16.0786 51.4265 16.5869 52.0721C18.0734 54.2343 22.614 54.5316 27.2222 52.8153C31.8303 51.0991 35.8304 51.6532 36.8169 53.3424C37.3902 54.2154 37.918 55.1175 38.398 56.0451C39.3015 57.7961 40.0959 59.6014 40.7764 61.4506C41.9659 66.2644 42.4125 71.2317 42.1007 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2627)">
        <path d="M20.1415 20.2429C20.2902 21.1889 21.4929 28.4863 23.2497 31.8917C25.1821 35.5404 27.3038 31.8917 27.3038 31.8917L29.52 18.851C29.3849 17.7834 27.6281 4.4995 26.1821 4.78329C24.7362 5.06708 24.2227 4.24274 20.1415 20.2429Z" fill="#C7CAD5"/>
        <path d="M38.7898 56.8153C38.6727 54.1846 38.533 51.5179 38.3709 48.8152C38.0646 43.5719 37.6682 38.4637 37.1817 33.4907C36.4949 28.0373 36.2464 22.5376 36.4384 17.0445C36.6716 12.881 36.215 8.70761 35.087 4.69303C35.087 4.69303 33.3708 1.9903 32.1816 5.66602C31.2392 9.16164 30.5215 12.714 30.0329 16.3013L27.4248 31.7069C27.4248 31.7069 25.2626 35.2745 23.3707 31.6258C21.4788 27.9771 20.249 19.8689 20.249 19.8689C20.249 19.8689 19.3571 10.4093 20.6139 6.35521C21.8707 2.30111 19.1274 1.22001 19.1274 1.22001C19.1274 1.22001 15.6409 -1.37461 11.5462 10.3012C7.45157 21.977 8.19483 28.869 8.19483 28.869C8.19483 28.869 9.27592 39.6124 8.19483 42.2881C6.8156 45.4434 5.83068 48.7567 5.26236 52.1531" fill="#DEE1E9"/>
        <path d="M42.1007 76.1805C41.7899 82.7346 34.9655 81.586 32.2898 80.7211C29.6141 79.8562 22.4654 77.667 18.7761 79.3697C15.0869 81.0724 13.9382 84.3427 5.83003 82.1941C-2.27817 80.0454 1.58674 57.1262 1.58674 57.1262C1.58674 57.1262 3.30297 50.3694 12.3031 50.0585C13.1245 50.038 13.9394 50.2093 14.6831 50.5588C15.4267 50.9083 16.0786 51.4265 16.5869 52.0721C18.0734 54.2343 22.614 54.5316 27.2222 52.8153C31.8303 51.0991 35.8304 51.6532 36.8169 53.3424C37.3902 54.2154 37.918 55.1175 38.398 56.0451C39.3015 57.7961 40.0959 59.6014 40.7764 61.4506C41.9659 66.2644 42.4125 71.2317 42.1007 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M17.5978 52.5247H5.67667C7.83873 47.1549 8.19573 43.8947 7.8992 38.1564C7.88802 37.94 8.13739 37.8054 8.32761 37.932C9.33169 38.6 11.6899 40.4161 12.2615 43.0577C12.954 46.2577 14.9572 47.0348 15.1698 47.1089C15.1828 47.1134 15.1944 47.1179 15.2067 47.1236L16.3897 47.6703C16.5342 47.7371 16.5928 47.8996 16.5206 48.0332L15.9916 49.0109C15.9469 49.0936 15.951 49.1917 16.0024 49.271L17.8488 52.1151C17.9658 52.2954 17.8253 52.5247 17.5978 52.5247Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.67275 39.0459C9.73373 39.8938 11.1417 41.3307 11.5441 43.1903C12.2873 46.6248 14.4633 47.5809 14.8963 47.7361L15.6699 48.0936L15.3373 48.7084C15.1806 48.9979 15.1949 49.3413 15.3751 49.6189L16.8225 51.8485H6.71999C8.49672 47.1838 8.87272 43.9881 8.67275 39.0459ZM14.8796 47.7284C14.8794 47.7283 14.8797 47.7285 14.8796 47.7284V47.7284ZM8.75508 37.3832C8.02622 36.8983 7.13049 37.4547 7.16842 38.1886C7.46123 43.8548 7.11202 47.0209 4.991 52.2889L4.62378 53.2009H17.5979C18.3941 53.2009 18.8859 52.3982 18.4762 51.7672L16.7539 49.114L17.175 48.3356C17.428 47.8679 17.2229 47.2992 16.7169 47.0654L15.534 46.5188C15.4933 46.5 15.4574 46.4864 15.4279 46.4761C15.3913 46.4634 13.6105 45.8432 12.979 42.9251C12.3495 40.0161 9.78613 38.0691 8.75508 37.3832Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask127" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="84">
        <path d="M20.1415 20.2429C20.2902 21.1889 21.4929 28.4863 23.2497 31.8917C25.1821 35.5404 27.3038 31.8917 27.3038 31.8917L29.52 18.851C29.3849 17.7834 27.6281 4.4995 26.1821 4.78329C24.7362 5.06708 24.2227 4.24274 20.1415 20.2429Z" fill="#C7CAD5"/>
        <path d="M38.7898 56.8153C38.6727 54.1846 38.533 51.5179 38.3709 48.8152C38.0646 43.5719 37.6682 38.4637 37.1817 33.4907C36.4949 28.0373 36.2464 22.5376 36.4384 17.0445C36.6716 12.881 36.215 8.70761 35.087 4.69303C35.087 4.69303 33.3708 1.9903 32.1816 5.66602C31.2392 9.16164 30.5215 12.714 30.0329 16.3013L27.4248 31.7069C27.4248 31.7069 25.2626 35.2745 23.3707 31.6258C21.4788 27.9771 20.249 19.8689 20.249 19.8689C20.249 19.8689 19.3571 10.4093 20.6139 6.35521C21.8707 2.30111 19.1274 1.22001 19.1274 1.22001C19.1274 1.22001 15.6409 -1.37461 11.5462 10.3012C7.45157 21.977 8.19483 28.869 8.19483 28.869C8.19483 28.869 9.27592 39.6124 8.19483 42.2881C6.8156 45.4434 5.83068 48.7567 5.26236 52.1531" fill="#DEE1E9"/>
        <path d="M42.1007 76.1805C41.7899 82.7346 34.9655 81.586 32.2898 80.7211C29.6141 79.8562 22.4654 77.667 18.7761 79.3697C15.0869 81.0724 13.9382 84.3427 5.83003 82.1941C-2.27817 80.0454 1.58674 57.1262 1.58674 57.1262C1.58674 57.1262 3.30297 50.3694 12.3031 50.0585C13.1245 50.038 13.9394 50.2093 14.6831 50.5588C15.4267 50.9083 16.0786 51.4265 16.5869 52.0721C18.0734 54.2343 22.614 54.5316 27.2222 52.8153C31.8303 51.0991 35.8304 51.6532 36.8169 53.3424C37.3902 54.2154 37.918 55.1175 38.398 56.0451C39.3015 57.7961 40.0959 59.6014 40.7764 61.4506C41.9659 66.2644 42.4125 71.2317 42.1007 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask127)">
        <path d="M20.1415 20.2429C20.2902 21.1889 21.4929 28.4863 23.2497 31.8917C25.1821 35.5404 27.3038 31.8917 27.3038 31.8917L29.52 18.851C29.3849 17.7834 27.6281 4.4995 26.1821 4.78329C24.7362 5.06708 24.2227 4.24274 20.1415 20.2429Z" fill="#C7CAD5"/>
        <path d="M38.7898 56.8153C38.6727 54.1846 38.533 51.5179 38.3709 48.8152C38.0646 43.5719 37.6682 38.4637 37.1817 33.4907C36.4949 28.0373 36.2464 22.5376 36.4384 17.0445C36.6716 12.881 36.215 8.70761 35.087 4.69303C35.087 4.69303 33.3708 1.9903 32.1816 5.66602C31.2392 9.16164 30.5215 12.714 30.0329 16.3013L27.4248 31.7069C27.4248 31.7069 25.2626 35.2745 23.3707 31.6258C21.4788 27.9771 20.249 19.8689 20.249 19.8689C20.249 19.8689 19.3571 10.4093 20.6139 6.35521C21.8707 2.30111 19.1274 1.22001 19.1274 1.22001C19.1274 1.22001 15.6409 -1.37461 11.5462 10.3012C7.45157 21.977 8.19483 28.869 8.19483 28.869C8.19483 28.869 9.27592 39.6124 8.19483 42.2881C6.8156 45.4434 5.83068 48.7567 5.26236 52.1531" fill="#DEE1E9"/>
        <path d="M42.1007 76.1805C41.7899 82.7346 34.9655 81.586 32.2898 80.7211C29.6141 79.8562 22.4654 77.667 18.7761 79.3697C15.0869 81.0724 13.9382 84.3427 5.83003 82.1941C-2.27817 80.0454 1.58674 57.1262 1.58674 57.1262C1.58674 57.1262 3.30297 50.3694 12.3031 50.0585C13.1245 50.038 13.9394 50.2093 14.6831 50.5588C15.4267 50.9083 16.0786 51.4265 16.5869 52.0721C18.0734 54.2343 22.614 54.5316 27.2222 52.8153C31.8303 51.0991 35.8304 51.6532 36.8169 53.3424C37.3902 54.2154 37.918 55.1175 38.398 56.0451C39.3015 57.7961 40.0959 59.6014 40.7764 61.4506C41.9659 66.2644 42.4125 71.2317 42.1007 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M13.9227 52.5315H5.77592C7.25343 48.561 7.49741 46.1504 7.29476 41.9074C7.28712 41.7474 7.45754 41.6479 7.58753 41.7415C8.2737 42.2354 9.88526 43.5782 10.2759 45.5315C10.7491 47.8976 12.1181 48.4722 12.2634 48.527C12.2722 48.5303 12.2802 48.5337 12.2886 48.5379L13.097 48.9421C13.1958 48.9915 13.2359 49.1116 13.1865 49.2104L12.825 49.9333C12.7944 49.9945 12.7972 50.067 12.8324 50.1257L14.0942 52.2286C14.1742 52.3619 14.0781 52.5315 13.9227 52.5315Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.82339 42.5651C8.54845 43.1921 9.51064 44.2545 9.78565 45.6295C10.2935 48.169 11.7806 48.876 12.0765 48.9908L12.6051 49.2551L12.3778 49.7097C12.2708 49.9238 12.2805 50.1777 12.4037 50.3829L13.3928 52.0315H6.4889C7.70309 48.5824 7.96005 46.2194 7.82339 42.5651ZM12.065 48.9851C12.0649 48.985 12.0651 48.9851 12.065 48.9851V48.9851ZM7.87965 41.3357C7.38156 40.9771 6.76943 41.3885 6.79535 41.9312C6.99545 46.1208 6.75681 48.4619 5.30733 52.3571L5.05638 53.0315H13.9227C14.4668 53.0315 14.8029 52.4379 14.5229 51.9713L13.3459 50.0096L13.6337 49.434C13.8066 49.0882 13.6664 48.6677 13.3207 48.4948L12.5123 48.0906C12.4844 48.0767 12.4599 48.0667 12.4398 48.0591C12.4148 48.0497 11.1978 47.5911 10.7662 45.4334C10.336 43.2825 8.58425 41.8428 7.87965 41.3357Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M19.9047 19.8084C20.0534 20.7543 21.2561 28.0517 23.0129 31.4572C24.9453 35.1059 27.067 31.4572 27.067 31.4572L29.2832 18.4165C29.1481 17.3489 27.3913 4.06493 25.9453 4.34872C24.4994 4.63251 23.9858 3.80817 19.9047 19.8084Z" fill="#DEE1E9"/>
      <path d="M38.553 56.3807C38.4359 53.75 38.2962 51.0833 38.1341 48.3806C37.8277 43.1373 37.4313 38.0291 36.9449 33.0561C36.2581 27.6027 36.0096 22.1031 36.2016 16.61C36.4348 12.4465 35.9782 8.27304 34.8502 4.25846C34.8502 4.25846 33.134 1.55573 31.9448 5.23145C31.0024 8.72707 30.2847 12.2794 29.7961 15.8667L27.188 31.2723C27.188 31.2723 25.0258 34.8399 23.1339 31.1912C21.242 27.5425 20.0122 19.4343 20.0122 19.4343C20.0122 19.4343 19.1203 9.97474 20.3771 5.92064C21.6339 1.86654 18.8906 0.78544 18.8906 0.78544C18.8906 0.78544 15.404 -1.80918 11.3094 9.86663C7.21476 21.5424 7.95801 28.4344 7.95801 28.4344C7.95801 28.4344 9.03911 39.1778 7.95801 41.8535C6.57878 45.0088 5.59386 48.3221 5.02554 51.7185" fill="#DEE1E9"/>
      <mask id="mask227" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="49" width="43" height="35">
        <path d="M42.0246 76.3245C41.7138 82.8787 34.8893 81.73 32.2136 80.8651C29.5379 80.0003 22.3892 77.811 18.7 79.5138C15.0107 81.2165 13.8621 84.4868 5.75386 82.3381C-2.35434 80.1894 1.51057 57.2703 1.51057 57.2703C1.51057 57.2703 3.2268 50.5134 12.2269 50.2026C13.0483 50.182 13.8633 50.3533 14.6069 50.7028C15.3506 51.0524 16.0024 51.5705 16.5107 52.2161C17.9973 54.3783 22.5378 54.6756 27.146 52.9594C31.7542 51.2432 35.7542 51.7972 36.7407 53.4864C37.314 54.3594 37.8418 55.2616 38.3218 56.1892C39.2254 57.9402 40.0197 59.7455 40.7002 61.5946C41.8898 66.4084 42.3364 71.3758 42.0246 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask227)">
        <path d="M42.0246 76.3245C41.7138 82.8787 34.8893 81.73 32.2136 80.8651C29.5379 80.0003 22.3892 77.811 18.7 79.5138C15.0107 81.2165 13.8621 84.4868 5.75386 82.3381C-2.35434 80.1894 1.51057 57.2703 1.51057 57.2703C1.51057 57.2703 3.2268 50.5134 12.2269 50.2026C13.0483 50.182 13.8633 50.3533 14.6069 50.7028C15.3506 51.0524 16.0024 51.5705 16.5107 52.2161C17.9973 54.3783 22.5378 54.6756 27.146 52.9594C31.7542 51.2432 35.7542 51.7972 36.7407 53.4864C37.314 54.3594 37.8418 55.2616 38.3218 56.1892C39.2254 57.9402 40.0197 59.7455 40.7002 61.5946C41.8898 66.4084 42.3364 71.3758 42.0246 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M22.2747 67.2009C25.8858 67.2009 30.1208 71.9105 30.1208 80.4423L23.5824 82.0975L13.1208 83.2009C13.1208 74.6691 18.6636 67.2009 22.2747 67.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M9.41253 63.4331C8.35925 61.1599 10.3838 58.6438 11.9021 52.5359C13.0261 48.3954 13.4911 44.0132 13.27 39.6432C12.887 34.2293 11.5328 32.9452 11.6833 27.618C11.9456 24.8018 12.4034 22.0205 13.0512 19.3063C15.7186 5.89307 17.9939 2.13246 18.3222 2.20187C18.6505 2.27128 16.7308 4.88664 16.895 14.1527C16.9907 19.8269 17.0044 21.5101 17.1139 24.1823C17.2643 27.2016 17.3874 27.8957 17.7978 33.0667C18.5775 42.836 18.5912 46.2196 18.9332 46.237C19.2751 46.2543 19.4256 40.4761 20.7388 32.199C21.9426 24.5467 24.1925 6.88642 25.4236 7.112C26.0939 7.21611 26.3199 17.814 26.6071 23.0371C27.1953 33.5872 26.4703 39.1226 27.2911 39.3655C28.1118 39.6085 30.2868 32.737 31.1212 29.6136C32.708 23.7832 33.0415 5.29994 33.4519 5.33464C33.8622 5.36935 33.5014 24.3038 33.8297 32.7717C34.1169 39.9555 34.5957 43.3218 32.9269 47.1046C31.9967 49.2389 31.559 48.8398 31.1212 50.5751C30.109 54.7916 33.2825 57.1863 33.1594 63.1728C33.1594 64.7518 33.0637 67.5803 31.5726 69.194C30.0816 70.8078 27.9066 70.2352 23.4473 69.194C20.6157 68.5346 21.0808 68.3611 13.7351 65.7236C10.5753 64.613 9.87762 64.4221 9.41253 63.4331Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M19.9047 19.8084C20.0534 20.7543 21.2561 28.0517 23.0129 31.4572C24.9453 35.1059 27.067 31.4572 27.067 31.4572L29.2832 18.4165C29.1481 17.3489 27.3913 4.06493 25.9453 4.34872C24.4994 4.63251 23.9858 3.80817 19.9047 19.8084Z" fill="#DEE1E9"/>
      <path d="M38.553 56.3807C38.4359 53.75 38.2962 51.0833 38.1341 48.3806C37.8277 43.1373 37.4313 38.0291 36.9449 33.0561C36.2581 27.6027 36.0096 22.1031 36.2016 16.61C36.4348 12.4465 35.9782 8.27304 34.8502 4.25846C34.8502 4.25846 33.134 1.55573 31.9448 5.23145C31.0024 8.72707 30.2847 12.2794 29.7961 15.8667L27.188 31.2723C27.188 31.2723 25.0258 34.8399 23.1339 31.1912C21.242 27.5425 20.0122 19.4343 20.0122 19.4343C20.0122 19.4343 19.1203 9.97474 20.3771 5.92064C21.6339 1.86654 18.8906 0.78544 18.8906 0.78544C18.8906 0.78544 15.404 -1.80918 11.3094 9.86663C7.21476 21.5424 7.95801 28.4344 7.95801 28.4344C7.95801 28.4344 9.03911 39.1778 7.95801 41.8535C6.57878 45.0088 5.59386 48.3221 5.02554 51.7185" fill="#DEE1E9"/>
      <mask id="mask327" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="49" width="43" height="35">
        <path d="M42.0246 76.3245C41.7138 82.8787 34.8893 81.73 32.2136 80.8651C29.5379 80.0003 22.3892 77.811 18.7 79.5138C15.0107 81.2165 13.8621 84.4868 5.75386 82.3381C-2.35434 80.1894 1.51057 57.2703 1.51057 57.2703C1.51057 57.2703 3.2268 50.5134 12.2269 50.2026C13.0483 50.182 13.8633 50.3533 14.6069 50.7028C15.3506 51.0524 16.0024 51.5705 16.5107 52.2161C17.9973 54.3783 22.5378 54.6756 27.146 52.9594C31.7542 51.2432 35.7542 51.7972 36.7407 53.4864C37.314 54.3594 37.8418 55.2616 38.3218 56.1892C39.2254 57.9402 40.0197 59.7455 40.7002 61.5946C41.8898 66.4084 42.3364 71.3758 42.0246 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask327)">
        <path d="M42.0246 76.3245C41.7138 82.8787 34.8893 81.73 32.2136 80.8651C29.5379 80.0003 22.3892 77.811 18.7 79.5138C15.0107 81.2165 13.8621 84.4868 5.75386 82.3381C-2.35434 80.1894 1.51057 57.2703 1.51057 57.2703C1.51057 57.2703 3.2268 50.5134 12.2269 50.2026C13.0483 50.182 13.8633 50.3533 14.6069 50.7028C15.3506 51.0524 16.0024 51.5705 16.5107 52.2161C17.9973 54.3783 22.5378 54.6756 27.146 52.9594C31.7542 51.2432 35.7542 51.7972 36.7407 53.4864C37.314 54.3594 37.8418 55.2616 38.3218 56.1892C39.2254 57.9402 40.0197 59.7455 40.7002 61.5946C41.8898 66.4084 42.3364 71.3758 42.0246 76.3245Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.6061 70.2009C22.5864 70.2009 24.1208 75.3353 24.1208 81.2009H22.1978H19.1208C19.1208 75.3353 20.6257 70.2009 21.6061 70.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M20.4076 20.2429C20.5563 21.1889 21.759 28.4863 23.5158 31.8917C25.4482 35.5404 27.5699 31.8917 27.5699 31.8917L29.7861 18.851C29.651 17.7834 27.8942 4.4995 26.4483 4.78329C25.0023 5.06708 24.4888 4.24274 20.4076 20.2429Z" fill="#C7CAD5"/>
      <path d="M39.0559 56.8153C38.9388 54.1846 38.7991 51.5179 38.637 48.8152C38.3307 43.5719 37.9343 38.4637 37.4478 33.4907C36.761 28.0373 36.5125 22.5376 36.7045 17.0445C36.9378 12.881 36.4811 8.70761 35.3532 4.69303C35.3532 4.69303 33.6369 1.9903 32.4477 5.66602C31.5053 9.16164 30.7876 12.714 30.299 16.3013L27.6909 31.7069C27.6909 31.7069 25.5287 35.2745 23.6368 31.6258C21.7449 27.9771 20.5151 19.8689 20.5151 19.8689C20.5151 19.8689 19.6232 10.4093 20.88 6.35521C22.1368 2.30111 19.3935 1.22001 19.3935 1.22001C19.3935 1.22001 15.907 -1.37461 11.8123 10.3012C7.71769 21.977 8.46094 28.869 8.46094 28.869C8.46094 28.869 9.54204 39.6124 8.46094 42.2881C7.08171 45.4434 6.09679 48.7567 5.52847 52.1531" fill="#DEE1E9"/>
      <path d="M42.3669 76.1805C42.056 82.7346 35.2316 81.586 32.5559 80.7211C29.8802 79.8562 22.7315 77.667 19.0422 79.3697C15.353 81.0724 14.2044 84.3427 6.09615 82.1941C-2.01206 80.0454 1.85285 57.1262 1.85285 57.1262C1.85285 57.1262 3.56909 50.3694 12.5692 50.0585C13.3906 50.038 14.2056 50.2093 14.9492 50.5588C15.6928 50.9083 16.3447 51.4265 16.853 52.0721C18.3395 54.2343 22.8801 54.5316 27.4883 52.8153C32.0965 51.0991 36.0965 51.6532 37.083 53.3424C37.6563 54.2154 38.1841 55.1175 38.6641 56.0451C39.5677 57.7961 40.362 59.6014 41.0425 61.4506C42.2321 66.2644 42.6787 71.2317 42.3669 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M33.6238 66.0679L31.7571 68.5687L30.1238 67.073V70.3871L27.5616 69.8192L28.9571 71.5698L26.6238 73.5704L28.9571 75.5711L27.5616 77.3217L29.8904 76.5715L30.1238 80.0679L32.3737 78.5708L33.1571 79.5725L34.8739 78.5708L37.1238 80.0679L37.8238 78.072L39.686 77.3217L39.2238 74.5708L40.6238 73.5704L38.2904 72.0699L39.686 69.8192L37.8238 69.5691L37.1238 67.073L34.0904 69.0689L33.6238 66.0679Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M20.4076 20.2429C20.5563 21.1889 21.759 28.4863 23.5158 31.8917C25.4482 35.5404 27.5699 31.8917 27.5699 31.8917L29.7861 18.851C29.651 17.7834 27.8942 4.4995 26.4483 4.78329C25.0023 5.06708 24.4888 4.24274 20.4076 20.2429Z" fill="#C7CAD5"/>
      <path d="M39.0559 56.8153C38.9388 54.1846 38.7991 51.5179 38.637 48.8152C38.3307 43.5719 37.9343 38.4637 37.4478 33.4907C36.761 28.0373 36.5125 22.5376 36.7045 17.0445C36.9378 12.881 36.4811 8.70761 35.3532 4.69303C35.3532 4.69303 33.6369 1.9903 32.4477 5.66602C31.5053 9.16164 30.7876 12.714 30.299 16.3013L27.6909 31.7069C27.6909 31.7069 25.5287 35.2745 23.6368 31.6258C21.7449 27.9771 20.5151 19.8689 20.5151 19.8689C20.5151 19.8689 19.6232 10.4093 20.88 6.35521C22.1368 2.30111 19.3935 1.22001 19.3935 1.22001C19.3935 1.22001 15.907 -1.37461 11.8123 10.3012C7.71769 21.977 8.46094 28.869 8.46094 28.869C8.46094 28.869 9.54204 39.6124 8.46094 42.2881C7.08171 45.4434 6.09679 48.7567 5.52847 52.1531" fill="#DEE1E9"/>
      <path d="M42.3669 76.1805C42.056 82.7346 35.2316 81.586 32.5559 80.7211C29.8802 79.8562 22.7315 77.667 19.0422 79.3697C15.353 81.0724 14.2044 84.3427 6.09615 82.1941C-2.01206 80.0454 1.85285 57.1262 1.85285 57.1262C1.85285 57.1262 3.56909 50.3694 12.5692 50.0585C13.3906 50.038 14.2056 50.2093 14.9492 50.5588C15.6928 50.9083 16.3447 51.4265 16.853 52.0721C18.3395 54.2343 22.8801 54.5316 27.4883 52.8153C32.0965 51.0991 36.0965 51.6532 37.083 53.3424C37.6563 54.2154 38.1841 55.1175 38.6641 56.0451C39.5677 57.7961 40.362 59.6014 41.0425 61.4506C42.2321 66.2644 42.6787 71.2317 42.3669 76.1805Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M34.6238 68.0679L33.0238 70.2115L31.6238 68.9294V71.77L29.4276 71.2833L30.6238 72.7838L28.6238 74.4987L30.6238 76.2135L29.4276 77.714L31.4238 77.071L31.6238 80.0679L33.5523 78.7847L34.2238 79.6433L35.6953 78.7847L37.6238 80.0679L38.2238 78.3571L39.8199 77.714L39.4238 75.3561L40.6238 74.4987L38.6238 73.2125L39.8199 71.2833L38.2238 71.0689L37.6238 68.9294L35.0238 70.6402L34.6238 68.0679Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M20.1415 19.3084C20.2902 20.2543 21.4929 27.5517 23.2497 30.9572C25.1821 34.6059 27.3038 30.9572 27.3038 30.9572L29.52 17.9165C29.3849 16.8489 27.6281 3.56493 26.1821 3.84872C24.7362 4.13251 24.2227 3.30817 20.1415 19.3084Z" fill="#C7CAD5"/>
      <path d="M38.7898 55.8807C38.6727 53.25 38.533 50.5833 38.3709 47.8806C38.0646 42.6373 37.6682 37.5291 37.1817 32.5561C36.4949 27.1027 36.2464 21.6031 36.4384 16.11C36.6716 11.9465 36.215 7.77304 35.087 3.75846C35.087 3.75846 33.3708 1.05573 32.1816 4.73145C31.2392 8.22707 30.5215 11.7794 30.0329 15.3667L27.4248 30.7723C27.4248 30.7723 25.2626 34.3399 23.3707 30.6912C21.4788 27.0425 20.249 18.9343 20.249 18.9343C20.249 18.9343 19.3571 9.47474 20.6139 5.42064C21.8707 1.36654 19.1274 0.28544 19.1274 0.28544C19.1274 0.28544 15.6409 -2.30918 11.5462 9.36663C7.45157 21.0424 8.19483 27.9344 8.19483 27.9344C8.19483 27.9344 9.27592 38.6778 8.19483 41.3535C6.8156 44.5088 5.83068 47.8221 5.26236 51.2185" fill="#DEE1E9"/>
      <path d="M42.1007 75.2459C41.7899 81.8001 34.9655 80.6514 32.2898 79.7865C29.6141 78.9216 22.4654 76.7324 18.7761 78.4351C15.0869 80.1379 13.9382 83.4082 5.83003 81.2595C-2.27817 79.1108 1.58674 56.1916 1.58674 56.1916C1.58674 56.1916 3.30297 49.4348 12.3031 49.124C13.1245 49.1034 13.9394 49.2747 14.6831 49.6242C15.4267 49.9738 16.0786 50.4919 16.5869 51.1375C18.0734 53.2997 22.614 53.597 27.2222 51.8808C31.8303 50.1645 35.8304 50.7186 36.8169 52.4078C37.3902 53.2808 37.918 54.1829 38.398 55.1105C39.3015 56.8616 40.0959 58.6669 40.7764 60.516C41.9659 65.3298 42.4125 70.2971 42.1007 75.2459Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M33.8577 73.1333L32.9243 74.3837L32.1077 73.6359V75.2929L30.8266 75.0089L31.5243 75.8842L30.3577 76.8846L31.5243 77.8849L30.8266 78.7602L31.991 78.3851L32.1077 80.1333L33.2326 79.3848L33.6243 79.8856L34.4827 79.3848L35.6077 80.1333L35.9577 79.1354L36.8888 78.7602L36.6577 77.3848L37.3577 76.8846L36.191 76.1343L36.8888 75.0089L35.9577 74.8839L35.6077 73.6359L34.091 74.6338L33.8577 73.1333Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M25.3251 2.68065L23.2414 1.41089H19.7577L17.6577 2.68065L19.2693 4.22716L17.1856 5.77367L19.074 7.32018L16.7787 9.0132L18.6019 10.5597L16.3066 11.9597L18.3252 13.376L16.3066 14.9876L17.9996 16.5341L15.7694 17.9504L17.5275 19.4155L15.2321 20.962L17.381 22.5085L15.2321 24.1201L16.7787 25.4713L14.6949 27.0178L16.5182 28.4992L14.4345 30.0457L16.2414 31.7876L15.2321 33.4643V47.7247L17.1205 55.0503H23.2414" fill="#8889BE"/>
      <path d="M25.3251 2.68065L23.2414 1.41089H19.7577L17.6577 2.68065L19.2693 4.22716L17.1856 5.77367L19.074 7.32018L16.7787 9.0132L18.6019 10.5597L16.3066 11.9597L18.3252 13.376L16.3066 14.9876L17.9996 16.5341L15.7694 17.9504L17.5275 19.4155L15.2321 20.962L17.381 22.5085L15.2321 24.1201L16.7787 25.4713L14.6949 27.0178L16.5182 28.4992L14.4345 30.0457L16.2414 31.7876L15.2321 33.4643V47.7247L17.1205 55.0503H23.2414" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M17.9179 2.68065L20.0016 1.41089H23.469L25.5853 2.68065L23.9574 4.22716L26.0574 5.77367L24.169 7.32018L26.4481 9.0132L24.6411 10.5597L26.9202 11.9597L24.9016 13.376L26.9202 14.9876L25.2434 16.5341L27.4574 17.9504L25.7155 19.4155L27.9946 20.962L25.8458 22.5085L27.9946 24.1201L26.4481 25.4713L28.5318 27.0178L26.7248 28.4992L28.8085 30.0457L26.9853 31.7876L27.9946 33.4643V47.7247L26.1225 55.0503H22.2155" fill="#8889BE"/>
      <path d="M17.9179 2.68065L20.0016 1.41089H23.469L25.5853 2.68065L23.9574 4.22716L26.0574 5.77367L24.169 7.32018L26.4481 9.0132L24.6411 10.5597L26.9202 11.9597L24.9016 13.376L26.9202 14.9876L25.2434 16.5341L27.4574 17.9504L25.7155 19.4155L27.9946 20.962L25.8458 22.5085L27.9946 24.1201L26.4481 25.4713L28.5318 27.0178L26.7248 28.4992L28.8085 30.0457L26.9853 31.7876L27.9946 33.4643V47.7247L26.1225 55.0503H22.2155" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M32.4798 71.8566C35.1555 72.7214 41.9799 73.8701 42.2907 67.316C42.6025 62.3672 42.1559 57.3998 40.9664 52.5861C40.2858 50.7369 38.7173 44.5875 37.8137 42.8365C34.6936 41.946 31.9179 41.2914 29.3137 40.8634C28.4642 40.7237 29.1328 54.4281 28.3137 54.3364C27.7988 55.9047 17.4739 55.7125 14.8451 54.3364C14.0113 54.4286 15.7091 40.7244 14.8451 40.8634C12.1468 41.2974 9.27037 41.9586 6.01999 42.8365C4.51999 42.8365 1.7767 48.2617 1.7767 48.2617C1.7767 48.2617 -2.08822 71.1809 6.01999 73.3295C14.1282 75.4782 15.2769 72.2079 18.9661 70.5052C22.6553 68.8025 29.8041 70.9917 32.4798 71.8566Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask527" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="40" width="43" height="35">
        <path d="M32.4798 71.8567C35.1555 72.7215 41.9799 73.8702 42.2907 67.3161C42.6025 62.3673 42.1559 57.3999 40.9664 52.5862C40.2858 50.737 38.7905 45.6135 37.887 43.8624C37.887 43.8624 34.2498 42.8366 28.3222 41.8624C27.4727 41.7228 28.0681 54.4282 27.249 54.3365C21.9904 55.4273 21.4063 55.4997 16.1474 54.3365C15.3135 54.4286 16.2191 41.2234 15.3552 41.3624C12.6568 41.7965 9.27037 42.9845 6.01999 43.8624C4.51999 43.8624 1.7767 48.2618 1.7767 48.2618C1.7767 48.2618 -2.08822 71.181 6.01999 73.3296C14.1282 75.4783 15.2769 72.208 18.9661 70.5053C22.6553 68.8026 29.8041 70.9918 32.4798 71.8567Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask527)">
        <path d="M34.387 32.8623H9.38698V58.4103C19.1501 60.3365 24.6239 60.3561 34.387 58.4103V32.8623Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M25.2989 2.17846L23.2152 0.908691H19.7315L17.6315 2.17846L19.2431 3.72496L17.1594 5.27147L19.0478 6.81798L16.7524 8.511L18.5757 10.0575L16.2803 11.4575L18.2989 12.8738L16.2803 14.4854L17.9734 16.0319L15.7431 17.4482L17.5013 18.9133L15.2059 20.4598L17.3547 22.0063L15.2059 23.6179L16.7524 24.9691L14.6687 26.5156L16.492 27.997L14.4082 29.5435L16.2152 31.2854L15.2059 32.9621V47.2225L17.0943 54.5481H23.2152" fill="#8889BE"/>
      <path d="M25.2989 2.17846L23.2152 0.908691H19.7315L17.6315 2.17846L19.2431 3.72496L17.1594 5.27147L19.0478 6.81798L16.7524 8.511L18.5757 10.0575L16.2803 11.4575L18.2989 12.8738L16.2803 14.4854L17.9734 16.0319L15.7431 17.4482L17.5013 18.9133L15.2059 20.4598L17.3547 22.0063L15.2059 23.6179L16.7524 24.9691L14.6687 26.5156L16.492 27.997L14.4082 29.5435L16.2152 31.2854L15.2059 32.9621V47.2225L17.0943 54.5481H23.2152" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M17.8916 2.17846L19.9754 0.908691H23.4428L25.5591 2.17846L23.9312 3.72496L26.0312 5.27147L24.1428 6.81798L26.4219 8.511L24.6149 10.0575L26.894 11.4575L24.8753 12.8738L26.894 14.4854L25.2172 16.0319L27.4312 17.4482L25.6893 18.9133L27.9684 20.4598L25.8195 22.0063L27.9684 23.6179L26.4219 24.9691L28.5056 26.5156L26.6986 27.997L28.7823 29.5435L26.9591 31.2854L27.9684 32.9621V47.2225L26.0963 54.5481H22.1893" fill="#8889BE"/>
      <path d="M17.8916 2.17846L19.9754 0.908691H23.4428L25.5591 2.17846L23.9312 3.72496L26.0312 5.27147L24.1428 6.81798L26.4219 8.511L24.6149 10.0575L26.894 11.4575L24.8753 12.8738L26.894 14.4854L25.2172 16.0319L27.4312 17.4482L25.6893 18.9133L27.9684 20.4598L25.8195 22.0063L27.9684 23.6179L26.4219 24.9691L28.5056 26.5156L26.6986 27.997L28.7823 29.5435L26.9591 31.2854L27.9684 32.9621V47.2225L26.0963 54.5481H22.1893" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M32.1909 67.8449C34.8666 68.7097 41.691 69.8584 42.0018 63.3043C42.3136 58.3555 41.867 53.3881 40.6775 48.5743C39.997 46.7252 38.4284 40.5758 37.5249 38.8248C34.4047 37.9343 31.6291 37.2796 29.0249 36.8516C28.1753 36.712 28.844 50.4164 28.0249 50.3247C27.8176 51.9812 18.1737 51.8567 15.2155 50.3247C14.3817 50.4168 15.4202 36.7127 14.5563 36.8516C11.858 37.2857 8.9815 37.9468 5.73112 38.8248C4.23112 38.8248 1.48783 44.25 1.48783 44.25C1.48783 44.25 -2.37708 67.1692 5.73112 69.3178C13.8393 71.4665 14.988 68.1962 18.6772 66.4935C22.3665 64.7908 29.5152 66.98 32.1909 67.8449Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M43.8093 80.771H22.8093V85.771H43.8093V80.771Z" fill="#838AA2"/>
      <path d="M21.8093 80.771H0.809326V85.771H21.8093V80.771Z" fill="#838AA2"/>
      <path d="M21.3271 19.9461C21.4757 20.892 22.6784 28.1894 24.4352 31.5949C26.3677 35.2435 28.4893 31.5949 28.4893 31.5949L30.7056 18.5542C30.5704 17.4866 28.8137 4.20263 27.3677 4.48642C25.9217 4.7702 25.4082 3.94587 21.3271 19.9461Z" fill="#C7CAD5"/>
      <path d="M39.5564 72.9043C39.4393 70.2736 39.7186 51.2213 39.5564 48.5186C39.2501 43.2752 38.8537 38.1671 38.3672 33.194C37.6805 27.7407 37.4319 22.241 37.624 16.7479C37.8572 12.5844 37.4006 8.41098 36.2726 4.3964C36.2726 4.3964 34.5564 1.69367 33.3672 5.36939C32.4248 8.86501 31.7071 12.4174 31.2185 16.0046L28.6103 31.4102C28.6103 31.4102 26.4482 34.9778 24.5562 31.3292C22.6643 27.6805 21.4346 19.5723 21.4346 19.5723C21.4346 19.5723 20.5427 10.1127 21.7994 6.05858C23.0562 2.00448 20.3129 0.923379 20.3129 0.923379C20.3129 0.923379 16.8264 -1.67125 12.7318 10.0046C8.63713 21.6804 9.38038 28.5724 9.38038 28.5724C9.38038 28.5724 10.4615 39.3157 9.38038 41.9914C8.00115 45.1467 6.59734 64.8457 6.02901 68.2421" fill="#DEE1E9"/>
      <path d="M33.9021 79.8986C36.5778 80.7634 43.4022 81.9121 43.713 75.358C44.0249 70.4092 43.5782 65.4418 42.3887 60.6281C41.7082 58.7789 40.1396 52.6295 39.2361 50.8785C36.1159 49.988 33.3403 49.3334 30.7361 48.9054C29.8865 48.7657 30.5552 62.4701 29.7361 62.3784C29.5288 64.0349 19.2257 63.9104 16.2675 62.3784C15.4336 62.4705 17.1314 48.7664 16.2675 48.9054C13.5692 49.3394 10.6927 50.0005 7.44234 50.8785C5.94234 50.8785 3.19904 56.3037 3.19904 56.3037C3.19904 56.3037 -0.665871 79.2229 7.44234 81.3715C15.5505 83.5202 16.6992 80.2499 20.3884 78.5472C24.0777 76.8445 31.2264 79.0337 33.9021 79.8986Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M20.784 19.3152C20.9326 20.2612 22.1354 27.5585 23.8921 30.964C25.8246 34.6127 27.9462 30.964 27.9462 30.964L30.1625 17.9233C30.0273 16.8557 28.2706 3.57177 26.8246 3.85556C25.3786 4.13934 24.8651 3.31501 20.784 19.3152Z" fill="#DEE1E9"/>
      <path d="M39.4323 55.8878C39.3151 53.2571 39.1755 50.5904 39.0133 47.8877C38.707 42.6444 38.3106 37.5362 37.8241 32.5632C37.1374 27.1098 36.8888 21.6101 37.0809 16.117C37.3141 11.9535 36.8575 7.78012 35.7295 3.76554C35.7295 3.76554 34.0133 1.06281 32.8241 4.73853C31.8817 8.23415 31.164 11.7865 30.6754 15.3738L28.0672 30.7794C28.0672 30.7794 25.9051 34.347 24.0131 30.6983C22.1212 27.0496 20.8915 18.9414 20.8915 18.9414C20.8915 18.9414 19.9996 9.48182 21.2564 5.42772C22.5131 1.37362 19.7698 0.29252 19.7698 0.29252C19.7698 0.29252 16.2833 -2.3021 12.1887 9.37371C8.09403 21.0495 8.83728 27.9415 8.83728 27.9415C8.83728 27.9415 9.91838 38.6849 8.83728 41.3606C7.45806 44.5159 6.47314 47.8292 5.90482 51.2256" fill="#DEE1E9"/>
      <path d="M42.7432 75.253C42.4324 81.8071 35.608 80.6585 32.9323 79.7936C30.2566 78.9287 23.1078 76.7395 19.4186 78.4422C15.7294 80.1449 14.5807 83.4153 6.47249 81.2666C-1.63572 79.1179 2.22919 56.1987 2.22919 56.1987C2.22919 56.1987 3.94543 49.4419 12.9455 49.1311C13.767 49.1105 14.5819 49.2818 15.3255 49.6313C16.0692 49.9808 16.7211 50.499 17.2294 51.1446C18.7159 53.3068 23.2565 53.6041 27.8646 51.8879C32.4728 50.1716 36.4729 50.7257 37.4593 52.4149C38.0327 53.2879 38.5604 54.19 39.0404 55.1176C39.944 56.8687 40.7383 58.6739 41.4189 60.5231C42.6084 65.3369 43.055 70.3042 42.7432 75.253Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M10.6079 53.4272C9.56734 51.4956 11.5674 49.3576 13.0674 44.1672C14.1778 40.6489 14.6371 36.925 14.4188 33.2115C14.0404 28.611 12.7025 27.5198 12.8512 22.993C13.1103 20.5999 13.5626 18.2364 14.2025 15.9301C16.8377 4.53197 19.0855 1.33635 19.4098 1.39533C19.7341 1.45431 17.8377 3.67674 17.9999 11.5507C18.0945 16.3724 18.108 17.8027 18.2161 20.0735C18.3647 22.6391 18.4864 23.229 18.8918 27.623C19.6621 35.9246 19.6756 38.7999 20.0134 38.8147C20.3513 38.8294 20.4999 33.9193 21.7972 26.8858C22.9864 20.3831 25.2091 5.37608 26.4254 5.56777C27.0875 5.65624 27.3108 14.662 27.5946 19.1003C28.1757 28.0654 27.4595 32.7691 28.2703 32.9756C29.0811 33.182 31.2298 27.3429 32.0541 24.6887C33.6217 19.7343 33.9512 4.02795 34.3566 4.05744C34.762 4.08693 34.4055 20.1767 34.7298 27.3724C35.0136 33.4769 35.4866 36.3375 33.8379 39.552C32.919 41.3656 32.4865 41.0265 32.0541 42.501C31.0541 46.0841 34.1893 48.119 34.0676 53.2061C34.0676 54.5479 33.973 56.9514 32.5001 58.3227C31.0271 59.694 28.8784 59.2074 24.4729 58.3227C21.6756 57.7624 22.1351 57.6149 14.8782 55.3736C11.7566 54.4299 11.0674 54.2677 10.6079 53.4272Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M20.8466 19.3474C20.9953 20.2934 22.198 27.5908 23.9548 30.9962C25.8872 34.6449 28.0089 30.9962 28.0089 30.9962L30.2251 17.9555C30.09 16.8879 28.3332 3.604 26.8872 3.88778C25.4413 4.17157 24.9277 3.34723 20.8466 19.3474Z" fill="#DEE1E9"/>
      <path d="M39.4949 55.92C39.3778 53.2893 39.2381 50.6227 39.0759 47.9199C38.7696 42.6766 38.3732 37.5684 37.8867 32.5954C37.2 27.142 36.9515 21.6424 37.1435 16.1493C37.3767 11.9858 36.9201 7.81235 35.7921 3.79777C35.7921 3.79777 34.0759 1.09504 32.8867 4.77076C31.9443 8.26638 31.2266 11.8187 30.738 15.406L28.1299 30.8116C28.1299 30.8116 25.9677 34.3792 24.0758 30.7305C22.1839 27.0818 20.9541 18.9736 20.9541 18.9736C20.9541 18.9736 20.0622 9.51405 21.319 5.45995C22.5757 1.40584 19.8325 0.324746 19.8325 0.324746C19.8325 0.324746 16.3459 -2.26988 12.2513 9.40594C8.15665 21.0818 8.89991 27.9737 8.89991 27.9737C8.89991 27.9737 9.981 38.7171 8.89991 41.3928C7.52068 44.5481 6.53576 47.8614 5.96744 51.2578" fill="#DEE1E9"/>
      <path d="M42.8058 75.2852C42.495 81.8394 35.6706 80.6907 32.9949 79.8258C30.3192 78.9609 23.1704 76.7717 19.4812 78.4745C15.792 80.1772 14.6433 83.4475 6.53511 81.2988C-1.57309 79.1501 2.29182 56.2309 2.29182 56.2309C2.29182 56.2309 4.00805 49.4741 13.0082 49.1633C13.8296 49.1427 14.6445 49.314 15.3882 49.6635C16.1318 50.0131 16.7837 50.5312 17.292 51.1768C18.7785 53.339 23.3191 53.6363 27.9273 51.9201C32.5354 50.2038 36.5355 50.7579 37.522 52.4471C38.0953 53.3201 38.623 54.2223 39.1031 55.1498C40.0066 56.9009 40.8009 58.7062 41.4815 60.5553C42.671 65.3691 43.1176 70.3365 42.8058 75.2852Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.4" d="M10.6705 53.4595C9.62996 51.5278 11.63 49.3898 13.13 44.1995C14.2404 40.6811 14.6997 36.9572 14.4814 33.2437C14.103 28.6432 12.7651 27.552 12.9138 23.0253C13.173 20.6321 13.6252 18.2687 14.2652 15.9623C16.9003 4.5642 19.1481 1.36857 19.4724 1.42756C19.7968 1.48654 17.9003 3.70897 18.0625 11.5829C18.1571 16.4046 18.1706 17.8349 18.2787 20.1057C18.4274 22.6714 18.549 23.2612 18.9544 27.6553C19.7247 35.9568 19.7382 38.8322 20.076 38.8469C20.4139 38.8617 20.5625 33.9515 21.8598 26.918C23.049 20.4153 25.2718 5.40831 26.488 5.6C27.1502 5.68847 27.3734 14.6942 27.6572 19.1325C28.2383 28.0976 27.5221 32.8014 28.3329 33.0078C29.1437 33.2142 31.2924 27.3751 32.1167 24.721C33.6843 19.7666 34.0138 4.06018 34.4192 4.08967C34.8246 4.11916 34.4681 20.2089 34.7924 27.4046C35.0762 33.5091 35.5492 36.3697 33.9005 39.5842C32.9816 41.3978 32.5492 41.0587 32.1167 42.5332C31.1167 46.1163 34.2519 48.1512 34.1303 53.2383C34.1303 54.5801 34.0357 56.9836 32.5627 58.3549C31.0897 59.7262 28.941 59.2396 24.5356 58.3549C21.7382 57.7946 22.1977 57.6471 14.9408 55.4059C11.8192 54.4622 11.13 54.3 10.6705 53.4595Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M20.3499 20.03C20.4986 20.976 21.7013 28.2734 23.458 31.6788C25.3905 35.3275 27.5121 31.6788 27.5121 31.6788L29.7284 18.6381C29.5933 17.5706 27.8365 4.28661 26.3905 4.5704C24.9446 4.85419 24.431 4.02985 20.3499 20.03Z" fill="#DEE1E9"/>
      <path d="M38.9982 56.6026C38.8811 53.972 38.7414 51.3053 38.5792 48.6025C38.2729 43.3592 37.8765 38.2511 37.39 33.278C36.7033 27.8246 36.4548 22.325 36.6468 16.8319C36.88 12.6684 36.4234 8.49496 35.2954 4.48039C35.2954 4.48039 33.5792 1.77765 32.39 5.45337C31.4476 8.94899 30.7299 12.5013 30.2413 16.0886L27.6332 31.4942C27.6332 31.4942 25.471 35.0618 23.5791 31.4131C21.6871 27.7644 20.4574 19.6562 20.4574 19.6562C20.4574 19.6562 19.5655 10.1967 20.8223 6.14256C22.079 2.08846 19.3358 1.00736 19.3358 1.00736C19.3358 1.00736 15.8492 -1.58726 11.7546 10.0886C7.65995 21.7644 8.4032 28.6564 8.4032 28.6564C8.4032 28.6564 9.4843 39.3997 8.4032 42.0754C7.02398 45.2307 6.03905 48.5441 5.47073 51.9404" fill="#DEE1E9"/>
      <path d="M42.3091 75.9678C41.9983 82.522 35.1739 81.3733 32.4982 80.5084C29.8225 79.6436 22.6737 77.4543 18.9845 79.1571C15.2953 80.8598 14.1466 84.1301 6.03841 81.9814C-2.0698 79.8327 1.79511 56.9136 1.79511 56.9136C1.79511 56.9136 3.51135 50.1567 12.5115 49.8459C13.3329 49.8253 14.1478 49.9966 14.8915 50.3461C15.6351 50.6957 16.287 51.2138 16.7953 51.8594C18.2818 54.0216 22.8224 54.3189 27.4306 52.6027C32.0387 50.8865 36.0388 51.4405 37.0253 53.1297C37.5986 54.0027 38.1263 54.9049 38.6064 55.8325C39.5099 57.5835 40.3042 59.3888 40.9848 61.2379C42.1743 66.0517 42.6209 71.0191 42.3091 75.9678Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M12.6333 45.0126C11.1333 50.1578 9.13326 52.2773 10.1738 54.1921C10.6333 55.0253 11.3225 55.1861 14.4441 56.1215C21.701 58.3433 21.2415 58.4895 24.0388 59.0449C28.4443 59.922 30.593 60.4043 32.066 59.0449C33.539 57.6856 33.6336 55.303 33.6336 53.9728C33.7552 48.93 30.62 46.9128 31.62 43.3609C32.0525 41.8992 32.4849 42.2354 33.4038 40.4375C35.0525 37.251 34.5795 34.4153 34.2957 28.3639C34.2598 27.574 34.2323 26.6761 34.2111 25.7036H31.62C30.7957 28.3347 28.647 34.123 27.8362 33.9183C27.215 33.7616 27.4901 30.9883 27.3915 25.7036H21.7514C21.6129 26.499 21.4828 27.2329 21.3631 27.8815C20.0658 34.8538 19.9172 39.7213 19.5793 39.7067C19.2415 39.692 19.228 36.8417 18.4577 28.6124C18.3436 27.3868 18.252 26.4597 18.176 25.7036H12.434C12.6193 28.8748 13.6585 30.2209 13.9847 34.1522C14.203 37.8334 13.7437 41.5249 12.6333 45.0126Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <g opacity="0.4">
        <path d="M13.7684 16.1822C13.1284 18.4151 12.6762 20.7034 12.417 23.0203C12.3965 23.625 12.3457 24.1663 12.3754 24.662H18.0813C17.887 22.7759 17.8888 21.9788 17.782 20.1937C17.6738 17.9952 17.6603 16.6105 17.5657 11.9422C17.4036 4.31893 19.3 2.16725 18.9757 2.11015C18.6513 2.05304 16.4036 5.14694 13.7684 16.1822Z" fill="#462AF3"/>
        <path d="M27.1604 19.2515C26.8767 14.9544 26.6534 6.23542 25.9912 6.14977C24.8974 5.98285 23.1505 17.7186 21.9124 24.662H27.3914C27.3613 23.0862 27.2964 21.2817 27.1604 19.2515Z" fill="#462AF3"/>
        <path d="M33.9224 4.68752C33.517 4.65896 33.5174 19.8654 31.9498 24.662H34.211C34.0409 17.0344 34.2829 4.71291 33.9224 4.68752Z" fill="#462AF3"/>
        <path d="M13.7684 16.1822C13.1284 18.4151 12.6762 20.7034 12.417 23.0203C12.3965 23.625 12.3457 24.1663 12.3754 24.662H18.0813C17.887 22.7759 17.8888 21.9788 17.782 20.1937C17.6738 17.9952 17.6603 16.6105 17.5657 11.9422C17.4036 4.31893 19.3 2.16725 18.9757 2.11015C18.6513 2.05304 16.4036 5.14694 13.7684 16.1822Z" stroke="#462AF3" stroke-miterlimit="10"/>
        <path d="M27.1604 19.2515C26.8767 14.9544 26.6534 6.23542 25.9912 6.14977C24.8974 5.98285 23.1505 17.7186 21.9124 24.662H27.3914C27.3613 23.0862 27.2964 21.2817 27.1604 19.2515Z" stroke="#462AF3" stroke-miterlimit="10"/>
        <path d="M33.9224 4.68752C33.517 4.65896 33.5174 19.8654 31.9498 24.662H34.211C34.0409 17.0344 34.2829 4.71291 33.9224 4.68752Z" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

<g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M5.57682 81.5133C13.685 83.662 14.7931 80.3241 18.5094 78.6755C22.2257 77.0268 29.3204 79.2025 32.0231 80.0268C34.7258 80.8512 41.5637 82.162 41.9286 75.5538C42.2393 70.5589 41.7835 65.5458 40.5772 60.6888C40.0063 59.1282 39.3429 57.6029 38.5907 56.1211C38.4826 53.4815 38.3475 50.8148 38.1853 48.121C37.861 42.8777 37.4646 37.7696 36.9961 32.7965C36.3094 27.3431 36.0608 21.8435 36.2529 16.3504C36.4906 12.1867 36.0339 8.01259 34.9015 3.99887C34.9015 3.99887 33.1988 1.29613 32.0096 4.97186C31.0351 8.48816 30.2858 12.063 29.7663 15.6747L29.3744 17.999C29.2393 16.9315 27.4825 3.66103 26.05 3.94482C24.6176 4.2286 24.0365 3.35021 19.9689 19.3369C19.9663 19.3054 19.9663 19.2738 19.9689 19.2423C19.9689 19.2423 19.0635 9.78272 20.3338 5.72862C21.604 1.67452 18.8473 0.593423 18.8473 0.593423C18.8473 0.593423 15.3472 -2.0012 11.2526 9.67462C7.15792 21.3504 7.91468 28.2424 7.91468 28.2424C7.91468 28.2424 8.99578 39.0534 7.91468 41.6615C6.53546 44.8168 5.55054 48.1301 4.98222 51.5265C3.26712 52.7352 2.00259 54.4799 1.38758 56.486C1.38758 56.486 -2.53139 79.3511 5.57682 81.5133Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
        <path d="M20.2319 19.5854C20.3805 20.5314 21.5832 27.8288 23.34 31.2342C25.2725 34.8829 27.3941 31.2342 27.3941 31.2342L29.6104 18.1935C29.4752 17.1259 27.7184 3.84197 26.2725 4.12576C24.8265 4.40955 24.313 3.58521 20.2319 19.5854Z" fill="#C7CAD5"/>
        <path d="M38.8801 56.1578C38.763 53.5271 38.6234 50.8604 38.4612 48.1577C38.1549 42.9144 37.7585 37.8062 37.272 32.8332C36.5853 27.3798 36.3367 21.8802 36.5287 16.3871C36.762 12.2236 36.3054 8.05014 35.1774 4.03556C35.1774 4.03556 33.4611 1.33283 32.2719 5.00855C31.3296 8.50417 30.6119 12.0565 30.1233 15.6438L27.5151 31.0494C27.5151 31.0494 25.3529 34.617 23.461 30.9683C21.5691 27.3196 20.3394 19.2114 20.3394 19.2114C20.3394 19.2114 19.4475 9.75184 20.7042 5.69774C21.961 1.64364 19.2177 0.562539 19.2177 0.562539C19.2177 0.562539 15.7312 -2.03209 11.6366 9.64373C7.54191 21.3195 8.28516 28.2115 8.28516 28.2115C8.28516 28.2115 9.36625 38.9549 8.28516 41.6306C6.90593 44.7859 5.92101 48.0992 5.35269 51.4956" fill="#DEE1E9"/>
        <path d="M42.1911 75.523C41.8803 82.0771 35.0559 80.9284 32.3801 80.0636C29.7044 79.1987 22.5557 77.0095 18.8665 78.7122C15.1772 80.4149 14.0286 83.6852 5.92036 81.5365C-2.18784 79.3879 1.67707 56.4687 1.67707 56.4687C1.67707 56.4687 3.39331 49.7118 12.3934 49.401C13.2149 49.3804 14.0298 49.5517 14.7734 49.9013C15.5171 50.2508 16.1689 50.769 16.6773 51.4146C18.1638 53.5767 22.7044 53.8741 27.3125 52.1578C31.9207 50.4416 35.9207 50.9956 36.9072 52.6848C37.4806 53.5579 38.0083 54.46 38.4883 55.3876C39.3919 57.1386 40.1862 58.9439 40.8667 60.7931C42.0563 65.6068 42.5029 70.5742 42.1911 75.523Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

  </g>
</template>

<script>
export default {
name: "Tooth64",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
