export default {
    add_to_egisz: "TƏBİB-ə əlavə et",
    comment_to_deal: "Əməliyyat üçün şərh",
    diagnosis_comments: "Diaqnoz üçün şərh",
    download_all: "Hamısını yüklə",
    egisz: "TƏBİB",
    for_rework: "Yenidən işləməyə",
    funnel: "Huni",
    list: "Siyahı",
    main_doctor: "Baş həkim",
    patient_rejected_to_send_to_egisz: "Xəstə məlumatların TƏBİB-ə göndərilməsindən imtina etdi",
    prepare_document_for_sending_to_egisz: "Sənədi TƏBİB-ə göndərmək üçün hazırlamaq?",
    processing_status: "Emal statusu",
    requires_attention: "Diqqət tələb edir",
    representative_relation: "Nümayəndənin xəstəyə kim olduğu",
    send_all: "Hamısını gö",
    send_to_egisz: "TƏBİB-ə göndər",
    sent_to_egisz: "TƏBİB-ə göndərilib",
    sign: "İmzala",
    sign_all: "Hamısını imzala",
    signed_by_doctor: "Həkim tərəfindən imzalanıb",
    signed_by_main_doctor: "Baş həkim tərəfindən imzalanıb",
    show_more: "Daha çox göstər",
    show_only_not_sent: "Yalnız göndərilməyənləri göstər",
    to_egisz_page: "TƏBİB səhifəsinə",
    to_rework: "Yenidən işləməyə",
    to_sign: "İmzalanmağa",
}
