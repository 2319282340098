<template>
  <g>
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M20.1055 2.26976L18.0218 1H14.538L12.438 2.26976L14.0497 3.81627L11.966 5.36278L13.8543 6.90929L11.559 8.60231L13.3822 10.1488L11.0869 11.5488L13.1055 12.9651L11.0869 14.5767L12.7799 16.1232L10.5497 17.5395L12.3078 19.0046L10.0125 20.5511L12.1613 22.0976L10.0125 23.7093L11.559 25.0604L9.47526 26.6069L11.2985 28.0883L9.21479 29.6348L11.0218 31.3767L10.0125 33.0534V47.3139L11.9008 54.6394H18.0218" fill="#8889BE"/>
      <path d="M12.6982 2.26976L14.7819 1H18.2493L20.3656 2.26976L18.7377 3.81627L20.8377 5.36278L18.9493 6.90929L21.2284 8.60231L19.4214 10.1488L21.7005 11.5488L19.6819 12.9651L21.7005 14.5767L20.0238 16.1232L22.2377 17.5395L20.4959 19.0046L22.7749 20.5511L20.6261 22.0976L22.7749 23.7093L21.2284 25.0604L23.3121 26.6069L21.5052 28.0883L23.5889 29.6348L21.7656 31.3767L22.7749 33.0534V47.3139L20.9028 54.6394H16.9959" fill="#8889BE"/>
      <path d="M0.508289 70.4622C0.656214 76.7557 1.73202 78.2753 2.72715 79.0418C3.72227 79.8083 4.91911 79.889 8.80547 79.9293C19.1467 80.0638 18.2995 80.2789 20.0477 80.0772C25.9781 79.4317 28.9365 79.0956 30.8058 77.1188C32.675 75.142 32.8632 72.5466 30.9537 57.4314C30.3351 52.5231 29.9989 50.2773 29.6089 47.4533L27.5649 44.2662C26.3948 41.7962 24.4031 39.8093 21.9303 38.6451C19.3055 37.535 16.375 37.378 13.6466 38.2014C12.0807 38.5684 10.5859 39.1908 9.22234 40.0437L3.60124 45.5303C3.18436 49.1746 3.03644 48.2198 1.67823 56.786C0.847574 61.2962 0.455765 65.8764 0.508289 70.4622Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--      <path d="M29.087 49.6466C30.1763 58.3472 30.4318 59.06 31.4538 67.3034C33.3634 82.4185 33.202 85.0407 31.3059 86.9907C29.4098 88.9406 26.4244 89.3036 20.5478 89.9491C18.7996 90.1508 19.6468 89.9491 9.29214 89.8012C5.41923 89.8012 4.34342 89.8012 3.22727 88.9137C2.11111 88.0261 1.15634 86.6275 1.00841 80.3341C0.937405 75.7583 1.31575 71.1866 2.13801 66.6848C3.48277 58.159 3.64414 59.0734 4.06102 55.4291C4.92167 47.8984 3.89965 47.2395 4.80064 40.6367C5.47119 36.5452 6.41007 32.5022 7.61119 28.5339C8.59677 24.3484 9.88669 20.2404 11.4707 16.2428C14.1602 10.0838 14.9939 10.9848 18.571 3.21204C19.7947 0.522514 20.521 0.299782 21.3547 0.36702C22.1885 0.434258 22.7263 1.168 23.8962 8.86003C25.3755 18.0313 26.1286 24.2575 26.1286 24.2575C26.653 28.6549 26.653 29.0449 27.4733 35.957C27.8767 39.5475 28.455 44.5903 29.087 49.6466Z" fill="#DEE1E9"/>-->
<!--      <path d="M1.01122 80.3366C1.15914 86.6301 2.23495 88.1497 3.23008 88.9162C4.2252 89.6827 5.42204 89.7634 9.3084 89.8037C19.6496 89.9382 18.8024 90.1534 20.5506 89.9517C26.481 89.3062 29.4395 88.97 31.3087 86.9932C33.1779 85.0164 33.3662 82.421 31.4566 67.3059C30.838 62.3975 30.5018 60.1518 30.1118 57.3278L28.0678 54.1407C26.8977 51.6707 24.906 49.6838 22.4333 48.5196C19.8084 47.4095 16.878 47.2525 14.1495 48.0758C12.5836 48.4428 11.0889 49.0652 9.72527 49.9181L4.10417 55.4048C3.68729 59.0491 3.53937 58.0943 2.18116 66.6604C1.3505 71.1707 0.958694 75.7508 1.01122 80.3366Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--      <path opacity="0.2" d="M15.5122 56.3544C13.7482 54.1759 15.1738 51.1233 15.7378 40.298C16.2404 30.7233 15.2763 30.1719 16.4147 25.1022C17.2557 21.3772 18.066 20.3686 19.3479 14.5055C20.3735 9.7719 20.671 2.21592 20.9376 2.25626C21.2043 2.29661 20.9376 11.7353 20.9376 19.3735C20.9376 26.689 21.3273 25.8552 21.3889 33.1304C21.4709 43.7137 20.712 44.9105 21.8401 49.1868C22.7529 52.6563 23.8913 54.2163 23.1939 55.7762C22.1376 58.1833 17.5326 58.8288 15.5122 56.3544Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M29.3531 50.1378C30.4424 58.8384 30.6979 59.5511 31.7199 67.7945C33.6295 82.9096 33.4681 85.5319 31.572 87.4818C29.6759 89.4317 26.6905 89.7948 20.8139 90.4403C19.0657 90.642 19.9129 90.4403 9.55825 90.2924C5.68534 90.2924 4.60953 90.2923 3.49338 89.4048C2.37723 88.5173 1.42245 87.1187 1.27452 80.8252C1.20352 76.2494 1.58187 71.6778 2.40412 67.1759C3.74888 58.6501 3.91026 59.5646 4.32713 55.9203C5.18778 48.3896 4.16576 47.7307 5.06675 41.1279C5.7373 37.0364 6.67618 32.9934 7.8773 29.025C8.86288 24.8395 10.1528 20.7316 11.7368 16.7339C14.4263 10.5749 15.26 11.4759 18.8371 3.70319C20.0608 1.01366 20.7871 0.790932 21.6208 0.85817C22.4546 0.925408 22.9924 1.65915 24.1624 9.35118C25.6416 18.5224 26.3947 24.7487 26.3947 24.7487C26.9191 29.1461 26.9191 29.536 27.7394 36.4481C28.1429 40.0386 28.7211 45.0815 29.3531 50.1378Z" fill="#DEE1E9"/>
        <mask id="421" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="47" width="34" height="45">
          <path d="M1.32958 80.9824C1.4775 87.2759 2.55331 88.7955 3.54844 89.562C4.54356 90.3285 5.74039 90.4092 9.62675 90.4495C19.968 90.584 19.1208 90.7991 20.869 90.5974C26.7994 89.9519 29.7578 89.6158 31.627 87.639C33.4963 85.6622 33.6845 83.0668 31.775 67.9516C31.1564 63.0433 30.8202 60.7975 30.4302 57.9735L28.3862 54.7864C27.2161 52.3164 25.2244 50.3295 22.7516 49.1653C20.1268 48.0552 17.1963 47.8982 14.4679 48.7216C12.902 49.0886 11.4072 49.711 10.0436 50.5639L4.42253 56.0505C4.00565 59.6948 3.85773 58.74 2.49952 67.3062C1.66886 71.8164 1.27705 76.3966 1.32958 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#421)">
          <path d="M1.32958 80.9824C1.4775 87.2759 2.55331 88.7955 3.54844 89.562C4.54356 90.3285 5.74039 90.4092 9.62675 90.4495C19.968 90.584 19.1208 90.7991 20.869 90.5974C26.7994 89.9519 29.7578 89.6158 31.627 87.639C33.4963 85.6622 33.6845 83.0668 31.775 67.9516C31.1564 63.0433 30.8202 60.7975 30.4302 57.9735L28.3862 54.7864C27.2161 52.3164 25.2244 50.3295 22.7516 49.1653C20.1268 48.0552 17.1963 47.8982 14.4679 48.7216C12.902 49.0886 11.4072 49.711 10.0436 50.5639L4.42253 56.0505C4.00565 59.6948 3.85773 58.74 2.49952 67.3062C1.66886 71.8164 1.27705 76.3966 1.32958 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M17.6061 75.2009C18.5864 75.2009 20.1208 83.6026 20.1208 93.2009H18.1978H15.1208C15.1208 83.6026 16.6257 75.2009 17.6061 75.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M14.1943 75.1141C11.9771 72.2182 13.7689 68.1603 14.4779 53.7702C15.1096 41.0425 13.8979 40.3096 15.3287 33.5703C16.3858 28.6187 17.4042 27.278 19.0156 19.4841C20.3047 13.1917 20.6785 3.14751 21.0137 3.20114C21.3488 3.25477 21.0137 15.8016 21.0137 25.9552C21.0137 35.6797 21.5035 34.5714 21.5809 44.2423C21.684 58.3107 20.7301 59.9016 22.1481 65.5862C23.2954 70.1982 24.7263 72.2718 23.8497 74.3454C22.5219 77.5452 16.7339 78.4033 14.1943 75.1141Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M29.087 51.8038C30.1763 60.5044 30.4318 61.2171 31.4538 69.4605C33.3634 84.5756 33.202 87.1979 31.3059 89.1478C29.4098 91.0977 26.4244 91.4608 20.5478 92.1063C18.7996 92.308 19.6468 92.1063 9.29214 91.9584C5.41923 91.9584 4.34342 91.9584 3.22727 91.0708C2.11111 90.1833 1.15634 88.7847 1.00841 82.4912C0.937405 77.9155 1.31575 73.3438 2.13801 68.8419C3.48277 60.3161 3.64414 61.2306 4.06102 57.5863C4.92167 50.0556 3.89965 49.3967 4.80064 42.7939C5.47119 38.7024 6.41007 34.6594 7.61119 30.6911C8.59677 26.5055 9.88669 22.3976 11.4707 18.3999C14.1602 12.2409 14.9939 13.1419 18.571 5.3692C19.7947 2.67968 20.521 2.45695 21.3547 2.52419C22.1885 2.59142 22.7263 3.32516 23.8962 11.0172C25.3755 20.1885 26.1286 26.4147 26.1286 26.4147C26.653 30.8121 26.653 31.202 27.4733 38.1141C27.8767 41.7046 28.455 46.7475 29.087 51.8038Z" fill="#DEE1E9"/>
      <path d="M1.01122 82.4938C1.15914 88.7873 2.23495 90.3069 3.23008 91.0734C4.2252 91.8399 5.42204 91.9206 9.3084 91.9609C19.6496 92.0954 18.8024 92.3106 20.5506 92.1088C26.481 91.4634 29.4395 91.1272 31.3087 89.1504C33.1779 87.1736 33.3662 84.5782 31.4566 69.4631C30.838 64.5547 30.5018 62.3089 30.1118 59.4849L28.0678 56.2979C26.8977 53.8279 24.906 51.8409 22.4333 50.6767C19.8084 49.5666 16.878 49.4097 14.1495 50.233C12.5836 50.6 11.0889 51.2224 9.72527 52.0753L4.10417 57.5619C3.68729 61.2062 3.53937 60.2515 2.18116 68.8176C1.3505 73.3279 0.958694 77.908 1.01122 82.4938Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M15.5122 58.5116C13.7482 56.3331 15.1738 53.2805 15.7378 42.4551C16.2404 32.8804 15.2763 32.3291 16.4147 27.2593C17.2557 23.5343 18.066 22.5258 19.3479 16.6626C20.3735 11.9291 20.671 4.37309 20.9376 4.41343C21.2043 4.45377 20.9376 13.8924 20.9376 21.5307C20.9376 28.8462 21.3273 28.0124 21.3889 35.2876C21.4709 45.8708 20.712 47.0677 21.8401 51.344C22.7529 54.8135 23.8913 56.3734 23.1939 57.9333C22.1376 60.3405 17.5326 60.9859 15.5122 58.5116Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="21" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M28.5841 50.1236C29.6734 58.8242 29.9289 59.537 30.9509 67.7804C32.8604 82.8955 32.6991 85.5178 30.803 87.4677C28.9068 89.4176 25.9215 89.7807 20.0449 90.4261C18.2967 90.6279 19.1439 90.4261 8.78921 90.2782C4.9163 90.2782 3.84049 90.2782 2.72434 89.3907C1.60818 88.5031 0.653406 87.1046 0.505482 80.8111C0.434475 76.2353 0.812822 71.6636 1.63508 67.1618C2.97984 58.636 3.14121 59.5504 3.55809 55.9061C4.41874 48.3755 3.39672 47.7165 4.29771 41.1138C4.96826 37.0222 5.90714 32.9792 7.10826 29.0109C8.09384 24.8254 9.38376 20.7174 10.9677 16.7198C13.6572 10.5608 14.491 11.4618 18.0681 3.68905C19.2918 0.999526 20.521 0.799743 21.3547 0.866982C22.1885 0.93422 22.2234 1.64501 23.3933 9.33704C24.8726 18.5083 25.6256 24.7346 25.6256 24.7346C26.1501 29.1319 26.1501 29.5219 26.9704 36.434C27.3738 40.0245 27.9521 45.0673 28.5841 50.1236Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M28.5841 50.1236C29.6734 58.8242 29.9289 59.537 30.9509 67.7804C32.8604 82.8955 32.6991 85.5178 30.803 87.4677C28.9068 89.4176 25.9215 89.7807 20.0449 90.4261C18.2967 90.6279 19.1439 90.4261 8.78921 90.2782C4.9163 90.2782 3.84049 90.2782 2.72434 89.3907C1.60818 88.5031 0.653406 87.1046 0.505482 80.8111C0.434475 76.2353 0.812822 71.6636 1.63508 67.1618C2.97984 58.636 3.14121 59.5504 3.55809 55.9061C4.41874 48.3755 3.39672 47.7165 4.29771 41.1138C4.96826 37.0222 5.90714 32.9792 7.10826 29.0109C8.09384 24.8254 9.38376 20.7174 10.9677 16.7198C13.6572 10.5608 14.491 11.4618 18.0681 3.68905C19.2918 0.999526 20.521 0.799743 21.3547 0.866982C22.1885 0.93422 22.2234 1.64501 23.3933 9.33704C24.8726 18.5083 25.6256 24.7346 25.6256 24.7346C26.1501 29.1319 26.1501 29.5219 26.9704 36.434C27.3738 40.0245 27.9521 45.0673 28.5841 50.1236Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M29.7665 60.9699C28.7445 52.7265 28.4864 58.2247 27.3971 49.5241C26.7651 44.4678 26.1868 39.425 25.7834 35.8345C24.9631 28.9224 24.9631 28.5324 24.4386 24.135C24.4386 24.135 23.6856 17.9088 22.2063 8.73753C21.0364 1.04549 20.4986 0.311755 19.6648 0.244517C18.8311 0.177279 18.1048 0.400012 16.8811 3.08953C13.304 10.8623 12.4702 9.96126 9.78072 16.1203C8.19676 20.1179 6.90684 24.2259 5.92126 28.4114C4.72013 32.3797 3.78126 36.4227 3.11071 40.5142C2.20972 47.117 2.37109 55.3066 2.37109 55.3066C2.37109 55.3066 1.47252 60.3513 0.450748 60.3513L5.92124 60.4667L11.2195 58.6886L17.7481 60.7163L23.9899 58.2913L29.7665 60.9699Z" fill="#DEE1E9"/>
      <path d="M26.281 51.5419C16.4931 49.0168 11.6125 50.2924 3.77023 56.4463" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M14.0479 43.0747C13.4838 54.725 12.0583 58.0103 13.8223 60.3548H17.3661L19.1747 61.4113L21.4355 60.0549L20.1502 52.641C19.022 48.0387 19.781 46.7507 19.6989 35.3609C19.6374 27.5313 19.2477 28.4286 19.2477 20.5555C19.2477 12.3352 19.5143 2.1772 19.2477 2.13378C18.981 2.09036 18.6836 10.2222 17.658 15.3165C16.376 21.6265 15.5658 22.7119 14.7248 26.7208C13.5864 32.1769 14.5505 32.7703 14.0479 43.0747Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M31.7665 67.7687C30.7445 59.5254 30.489 58.8126 29.3998 50.112C28.7677 45.0557 28.1895 40.0129 27.786 36.4223C26.9657 29.5103 26.9657 29.1203 26.4413 24.7229C26.4413 24.7229 25.6882 18.4967 24.209 9.32542C23.039 1.63338 22.5012 0.899645 21.6675 0.832407C20.8337 0.765169 20.1075 0.987902 18.8837 3.67742C15.3067 11.4501 14.4729 10.5492 11.7834 16.7082C10.1994 20.7058 8.9095 24.8138 7.92392 28.9993C6.7228 32.9676 5.78393 37.0106 5.11337 41.1021C4.21238 47.7049 5.2344 48.3638 4.37375 55.8945C3.95688 59.5388 3.79551 58.6244 2.45074 67.1501L31.7665 67.7687Z" fill="#DEE1E9"/>
      <path d="M3.5428 89.3815C2.54768 88.615 1.47187 87.0955 1.32394 80.802C1.27142 76.2161 1.66323 71.636 2.49388 67.1257C3.85209 58.5596 4.00002 59.5144 4.41689 55.8701L10.038 50.3835C11.4016 49.5306 12.8963 48.9081 14.4623 48.5412C17.1907 47.7178 20.1211 47.8748 22.746 48.9849C25.2188 50.1491 27.2104 52.136 28.3805 54.606L30.4246 57.7931C30.8146 60.6171 31.1507 62.8629 31.7693 67.7712C31.9423 69.1404 32.0981 70.4069 32.2369 71.5795H30.4246L29.217 74.0464L30.4246 76.3598L26.6155 77.8746L27.9542 80.9095L24.3867 80.1384L26.6155 84.4776L24.3867 86.8755L20.6011 84.4776L20.6621 88.4213L20.8633 90.417C19.1151 90.6187 19.9623 90.4036 9.62112 90.2691C5.73476 90.2287 4.53792 90.1481 3.5428 89.3815Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M28.5365 69.26C17.8606 73.0333 15.5357 77.5807 17.1613 89.5162" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M15.8249 56.8198C14.0609 54.6413 15.4865 51.5887 16.0506 40.7634C16.5531 31.1887 15.589 30.6373 16.7275 25.5676C17.5684 21.8426 18.3787 20.834 19.6607 14.9709C20.6863 10.2373 20.9837 2.68131 21.2503 2.72166C21.517 2.762 21.2503 12.2006 21.2503 19.8389C21.2503 27.1544 21.6401 26.3206 21.7016 33.5958C21.7836 44.1791 21.0247 45.3759 22.1529 49.6522C23.0656 53.1217 24.204 54.6817 23.5066 56.2416C22.4503 58.6487 17.8454 59.2942 15.8249 56.8198Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M29.087 49.1091C30.1763 57.8097 30.4318 58.5225 31.4538 66.7659C33.3634 81.881 33.202 84.5033 31.3059 86.4532C29.4098 88.4031 26.4244 88.7661 20.5478 89.4116C18.7996 89.6133 19.6468 89.4116 9.29214 89.2637C5.41923 89.2637 4.34342 89.2637 3.22727 88.3762C2.11111 87.4886 1.15634 86.09 1.00841 79.7966C0.937405 75.2208 1.31575 70.6491 2.13801 66.1473C3.48277 57.6215 3.64414 58.5359 4.06102 54.8916C4.92167 47.3609 3.89965 46.702 4.80064 40.0992C5.47119 36.0077 6.41007 31.9647 7.61119 27.9964C8.59677 23.8109 9.88669 19.7029 11.4707 15.7053C14.1602 9.54626 14.9939 10.4472 18.571 2.67453C19.7947 -0.0149944 20.5239 -0.0518877 21.3577 0.0153503C22.1914 0.0825884 22.7263 0.630487 23.8962 8.32252C25.3755 17.4938 26.1286 23.72 26.1286 23.72C26.653 28.1174 26.653 28.5074 27.4733 35.4194C27.8767 39.01 28.455 44.0528 29.087 49.1091Z" fill="#DEE1E9"/>
      <path d="M3.23008 88.3787C2.23495 87.6122 1.15914 86.0926 1.01122 79.7991C0.958694 75.2133 1.3505 70.6331 2.18116 66.1229C3.53937 57.5567 3.93407 56.2947 4.35094 52.6504H10.2648C10.8171 52.6504 11.2648 53.0981 11.2648 53.6504V66.1229C17.2325 67.3402 16.9173 67.3279 22.8827 66.1229V53.6504C22.8827 53.0981 23.3304 52.6504 23.8827 52.6504H29.2241L30.1118 56.7902C30.5018 59.6142 30.838 61.86 31.4566 66.7684C33.3662 81.8835 33.1779 84.4789 31.3087 86.4557C29.4395 88.4325 26.481 88.7686 20.5506 89.4141C18.8024 89.6158 19.6496 89.4007 9.3084 89.2662C5.42204 89.2259 4.2252 89.1452 3.23008 88.3787Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M29.087 49.2358C30.1763 57.9364 30.4318 58.6492 31.4538 66.8925C33.3634 82.0077 33.202 84.6299 31.3059 86.5798C29.4098 88.5297 26.4244 88.8928 20.5478 89.5383C18.7996 89.74 19.6468 89.5383 9.29214 89.3904C5.41923 89.3904 4.34342 89.3904 3.22727 88.5028C2.11111 87.6153 1.15634 86.2167 1.00841 79.9233C0.937405 75.3475 1.31575 70.7758 2.13801 66.2739C3.48277 57.7482 3.64414 58.6626 4.06102 55.0183C4.92167 47.4876 3.89965 46.8287 4.80064 40.2259C5.47119 36.1344 6.41007 32.0914 7.61119 28.1231C8.59677 23.9375 9.88669 19.8296 11.4707 15.832C14.1602 9.67294 14.9939 10.5739 18.571 2.80121C19.7947 0.111692 20.5239 0.0747984 21.3577 0.142036C22.1914 0.209274 22.7263 0.757173 23.8962 8.44921C25.3755 17.6205 26.1286 23.8467 26.1286 23.8467C26.653 28.2441 26.653 28.6341 27.4733 35.5461C27.8767 39.1366 28.455 44.1795 29.087 49.2358Z" fill="#DEE1E9"/>
      <path d="M3.23008 88.5054C2.23495 87.7388 1.15914 86.2193 1.01122 79.9258C0.958694 75.34 1.3505 70.7598 2.18116 66.2496C3.53937 57.6834 3.93407 56.4214 4.35094 52.7771H10.2648C10.8171 52.7771 11.2648 53.2248 11.2648 53.7771V66.2496C17.2325 67.4669 16.9173 67.4546 22.8827 66.2496V53.7771C22.8827 53.2248 23.3304 52.7771 23.8827 52.7771H29.2241L30.1118 56.9169C30.5018 59.7409 30.838 61.9867 31.4566 66.895C33.3662 82.0102 33.1779 84.6055 31.3087 86.5823C29.4395 88.5591 26.481 88.8953 20.5506 89.5408C18.8024 89.7425 19.6496 89.5274 9.3084 89.3929C5.42204 89.3525 4.2252 89.2719 3.23008 88.5054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M28.5841 49.3035C29.6734 58.0041 29.9289 58.7168 30.9509 66.9602C32.8604 82.0753 32.6991 84.6976 30.803 86.6475C28.9068 88.5974 25.9215 88.9605 20.0449 89.606C18.2967 89.8077 19.1439 89.606 8.78921 89.458C4.9163 89.458 3.84049 89.458 2.72434 88.5705C1.60818 87.6829 0.653406 86.2844 0.505482 79.9909C0.434475 75.4151 0.812822 70.8434 1.63508 66.3416C2.97984 57.8158 3.14121 58.7302 3.55809 55.0859C4.41874 47.5553 3.39672 46.8963 4.29771 40.2936C4.96826 36.202 5.90714 32.159 7.10826 28.1907C8.09384 24.0052 9.38376 19.8972 10.9677 15.8996C13.6572 9.74059 14.491 10.6416 18.0681 2.86886C19.2918 0.179342 20.021 0.142448 20.8547 0.209686C21.6885 0.276924 22.2234 0.824823 23.3933 8.51686C24.8726 17.6881 25.6256 23.9144 25.6256 23.9144C26.1501 28.3117 26.1501 28.7017 26.9704 35.6138C27.3738 39.2043 27.9521 44.2472 28.5841 49.3035Z" fill="#DEE1E9"/>
      <path d="M2.72715 88.573C1.73202 87.8065 0.656214 86.2869 0.508289 79.9934C0.455765 75.4076 0.847572 70.8275 1.67823 66.3172C3.03644 57.7511 3.43114 56.489 3.84801 52.8447L9.47123 52.8447V66.3172C15.4389 67.5345 17.1785 67.5222 23.1439 66.3172V52.8447L28.7211 52.8447L29.6089 56.9846C29.9989 59.8086 30.3351 62.0543 30.9537 66.9627C32.8632 82.0778 32.675 84.6732 30.8058 86.65C28.9365 88.6268 25.9781 88.963 20.0477 89.6085C18.2995 89.8102 19.1467 89.595 8.80547 89.4605C4.91911 89.4202 3.72227 89.3395 2.72715 88.573Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <mask id="mask2021" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="33" height="38">
        <path d="M2.73398 88.573C1.73886 87.8065 0.66305 86.2869 0.515125 79.9934C0.462601 75.4076 0.854408 70.8275 1.68507 66.3172C3.04328 57.7511 3.43797 57.4046 3.85485 53.7603L8.47807 53.7603C9.03035 53.7603 9.47807 54.208 9.47807 54.7603V66.3172C15.4457 67.5345 17.1854 67.5222 23.1507 66.3172V54.7603C23.1507 54.208 23.5985 53.7603 24.1507 53.7603L28.728 53.7603L29.6158 56.9846C30.0057 59.8086 30.3419 62.0543 30.9605 66.9627C32.8701 82.0778 32.6818 84.6732 30.8126 86.65C28.9434 88.6268 25.9849 88.963 20.0545 89.6085C18.3063 89.8102 19.1535 89.595 8.8123 89.4605C4.92594 89.4202 3.7291 89.3395 2.73398 88.573Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2021)">
        <path d="M26.4305 45.5855H6.3864V71.1335C14.2141 73.0597 18.6028 73.0793 26.4305 71.1335V45.5855Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M29.2688 49.8072C30.358 58.5078 30.6136 59.2206 31.6356 67.4639C33.5451 82.579 33.3838 85.2013 31.4876 87.1512C29.5915 89.1011 26.6062 89.4642 20.7296 90.1097C18.9814 90.3114 19.8286 90.1097 9.4739 89.9618C5.60099 89.9618 4.52518 89.9618 3.40903 89.0742C2.29288 88.1867 1.3381 86.7881 1.19017 80.4947C1.11917 75.9189 1.49751 71.3472 2.31977 66.8453C3.66453 58.3195 3.82591 59.234 4.24278 55.5897C5.10343 48.059 4.08141 47.4001 4.9824 40.7973C5.65295 36.7058 6.59183 32.6628 7.79295 28.6945C8.77853 24.5089 10.0685 20.401 11.6524 16.4034C14.3419 10.2444 15.1757 11.1453 18.7528 3.37262C19.9765 0.683098 20.7027 0.460365 21.5365 0.527603C22.3703 0.594841 22.9081 1.32858 24.078 9.02061C25.5572 18.1919 26.3103 24.4181 26.3103 24.4181C26.8348 28.8155 26.8348 29.2055 27.6551 36.1175C28.0585 39.708 28.6368 44.7509 29.2688 49.8072Z" fill="#DEE1E9"/>
      <path d="M1.19298 80.4973C1.34091 86.7908 2.41671 88.3103 3.41184 89.0769C4.40696 89.8434 5.6038 89.924 9.49016 89.9644C19.8314 90.0989 18.9842 90.314 20.7324 90.1123C26.6628 89.4668 29.6212 89.1306 31.4904 87.1538C33.3597 85.177 33.5479 82.5817 31.6384 67.4665C31.0198 62.5582 30.6836 60.3124 30.2936 57.4884L28.2496 54.3013C27.0795 51.8313 25.0878 49.8444 22.615 48.6802C19.9902 47.5701 17.0597 47.4131 14.3313 48.2365C12.7654 48.6035 11.2706 49.2259 9.90703 50.0788L4.28593 55.5654C3.86906 59.2097 3.72113 58.2549 2.36292 66.8211C1.53227 71.3313 1.14046 75.9115 1.19298 80.4973Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M15.694 56.5148C13.93 54.3363 15.3555 51.2837 15.9196 40.4584C16.4222 30.8837 15.4581 30.3323 16.5965 25.2626C17.4375 21.5376 18.2477 20.529 19.5297 14.6659C20.5553 9.9323 20.8527 2.37632 21.1194 2.41666C21.386 2.45701 21.1194 11.8957 21.1194 19.5339C21.1194 26.8494 21.5091 26.0156 21.5706 33.2908C21.6527 43.8741 20.8938 45.0709 22.0219 49.3472C22.9347 52.8167 24.0731 54.3767 23.3757 55.9366C22.3193 58.3437 17.7144 58.9892 15.694 56.5148Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M26.9379 25.7777C19.8989 22.0482 15.9683 22.2182 8.971 25.7777" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M29.087 51.1378C30.1763 59.8385 30.4318 60.5512 31.4538 68.7946C33.3634 83.9097 33.202 86.532 31.3059 88.4819C29.4098 90.4318 26.4244 90.7949 20.5478 91.4403C18.7996 91.6421 19.6468 91.4403 9.29214 91.2924C5.41923 91.2924 4.34342 91.2924 3.22727 90.4049C2.11111 89.5173 1.15634 88.1188 1.00841 81.8253C0.937405 77.2495 1.31575 72.6778 2.13801 68.176C3.48277 59.6502 3.64414 60.5646 4.06102 56.9203C4.92167 49.3897 3.89965 48.7307 4.80064 42.128C5.47119 38.0364 6.41007 33.9934 7.61119 30.0251C8.59677 25.8396 9.88669 21.7316 11.4707 17.734C14.1602 11.575 14.9939 12.476 18.571 4.70325C19.7947 2.01373 20.521 1.79099 21.3547 1.85823C22.1885 1.92547 22.7263 2.65921 23.8962 10.3512C25.3755 19.5225 26.1286 25.7487 26.1286 25.7487C26.653 30.1461 26.653 30.5361 27.4733 37.4482C27.8767 41.0387 28.455 46.0815 29.087 51.1378Z" fill="#DEE1E9"/>
      <mask id="mask2221" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="48" width="34" height="45">
        <path d="M1.06347 81.9825C1.21139 88.276 2.2872 89.7956 3.28232 90.5621C4.27744 91.3286 5.47428 91.4093 9.36064 91.4496C19.7019 91.5841 18.8547 91.7993 20.6028 91.5975C26.5332 90.9521 29.4917 90.6159 31.3609 88.6391C33.2302 86.6623 33.4184 84.0669 31.5089 68.9518C30.8903 64.0434 30.5541 61.7976 30.1641 58.9736L28.1201 55.7866C26.95 53.3166 24.9583 51.3297 22.4855 50.1655C19.8607 49.0553 16.9302 48.8984 14.2018 49.7217C12.6359 50.0887 11.1411 50.7111 9.77752 51.564L4.15642 57.0506C3.73954 60.6949 3.59162 59.7402 2.23341 68.3063C1.40275 72.8166 1.01094 77.3967 1.06347 81.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2221)">
        <path d="M1.06347 81.9825C1.21139 88.276 2.2872 89.7956 3.28232 90.5621C4.27744 91.3286 5.47428 91.4093 9.36064 91.4496C19.7019 91.5841 18.8547 91.7993 20.6028 91.5975C26.5332 90.9521 29.4917 90.6159 31.3609 88.6391C33.2302 86.6623 33.4184 84.0669 31.5089 68.9518C30.8903 64.0434 30.5541 61.7976 30.1641 58.9736L28.1201 55.7866C26.95 53.3166 24.9583 51.3297 22.4855 50.1655C19.8607 49.0553 16.9302 48.8984 14.2018 49.7217C12.6359 50.0887 11.1411 50.7111 9.77752 51.564L4.15642 57.0506C3.73954 60.6949 3.59162 59.7402 2.23341 68.3063C1.40275 72.8166 1.01094 77.3967 1.06347 81.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M17.3399 76.201C18.3203 76.201 19.8547 84.6027 19.8547 94.201H17.9317H14.8547C14.8547 84.6027 16.3596 76.201 17.3399 76.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M13.9282 76.1142C11.711 73.2183 13.5028 69.1605 14.2118 54.7703C14.8435 42.0426 13.6317 41.3097 15.0626 34.5704C16.1197 29.6188 17.1381 28.2781 18.7495 20.4842C20.0386 14.1918 20.4124 4.14764 20.7476 4.20126C21.0827 4.25489 20.7476 16.8017 20.7476 26.9553C20.7476 36.6798 21.2374 35.5715 21.3148 45.2424C21.4179 59.3108 20.464 60.9018 21.882 66.5863C23.0293 71.1983 24.4602 73.2719 23.5836 75.3456C22.2558 78.5454 16.4678 79.4034 13.9282 76.1142Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 21.1208 4.20099)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M29.087 56.1378C30.1763 64.8385 30.4318 65.5512 31.4538 73.7946C33.3634 88.9097 33.202 91.532 31.3059 93.4819C29.4098 95.4318 26.4244 95.7949 20.5478 96.4403C18.7996 96.6421 19.6468 96.4403 9.29214 96.2924C5.41923 96.2924 4.34342 96.2924 3.22727 95.4049C2.11111 94.5173 1.15634 93.1188 1.00841 86.8253C0.937405 82.2495 1.31575 77.6778 2.13801 73.176C3.48277 64.6502 3.64414 65.5646 4.06102 61.9203C4.92167 54.3897 3.89965 53.7307 4.80064 47.128C5.47119 43.0364 6.41007 38.9934 7.61119 35.0251C8.59677 30.8396 9.88669 26.7316 11.4707 22.734C14.1602 16.575 14.9939 17.476 18.571 9.70325C19.7947 7.01373 20.521 6.79099 21.3547 6.85823C22.1885 6.92547 22.7263 7.65921 23.8962 15.3512C25.3755 24.5225 26.1286 30.7487 26.1286 30.7487C26.653 35.1461 26.653 35.5361 27.4733 42.4482C27.8767 46.0387 28.455 51.0815 29.087 56.1378Z" fill="#DEE1E9"/>
      <mask id="mask2321" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="34" height="45">
        <path d="M1.06347 86.9825C1.21139 93.276 2.2872 94.7956 3.28232 95.5621C4.27744 96.3286 5.47428 96.4093 9.36064 96.4496C19.7019 96.5841 18.8547 96.7993 20.6028 96.5975C26.5332 95.9521 29.4917 95.6159 31.3609 93.6391C33.2302 91.6623 33.4184 89.0669 31.5089 73.9518C30.8903 69.0434 30.5541 66.7976 30.1641 63.9736L28.1201 60.7866C26.95 58.3166 24.9583 56.3297 22.4855 55.1655C19.8607 54.0553 16.9302 53.8984 14.2018 54.7217C12.6359 55.0887 11.1411 55.7111 9.77752 56.564L4.15642 62.0506C3.73954 65.6949 3.59162 64.7402 2.23341 73.3063C1.40275 77.8166 1.01094 82.3967 1.06347 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2321)">
        <path d="M1.06347 86.9825C1.21139 93.276 2.2872 94.7956 3.28232 95.5621C4.27744 96.3286 5.47428 96.4093 9.36064 96.4496C19.7019 96.5841 18.8547 96.7993 20.6028 96.5975C26.5332 95.9521 29.4917 95.6159 31.3609 93.6391C33.2302 91.6623 33.4184 89.0669 31.5089 73.9518C30.8903 69.0434 30.5541 66.7976 30.1641 63.9736L28.1201 60.7866C26.95 58.3166 24.9583 56.3297 22.4855 55.1655C19.8607 54.0553 16.9302 53.8984 14.2018 54.7217C12.6359 55.0887 11.1411 55.7111 9.77752 56.564L4.15642 62.0506C3.73954 65.6949 3.59162 64.7402 2.23341 73.3063C1.40275 77.8166 1.01094 82.3967 1.06347 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M17.3399 81.201C18.3203 81.201 19.8547 89.6027 19.8547 99.201H17.9317H14.8547C14.8547 89.6027 16.3596 81.201 17.3399 81.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M13.9282 81.1142C11.711 78.2183 13.5028 74.1605 14.2118 59.7703C14.8435 47.0426 13.6317 46.3097 15.0626 39.5704C16.1197 34.6188 17.1381 33.2781 18.7495 25.4842C20.0386 19.1918 20.4124 9.14764 20.7476 9.20126C21.0827 9.25489 20.7476 21.8017 20.7476 31.9553C20.7476 41.6798 21.2374 40.5715 21.3148 50.2424C21.4179 64.3108 20.464 65.9018 21.882 71.5863C23.0293 76.1983 24.4602 78.2719 23.5836 80.3456C22.2558 83.5454 16.4678 84.4034 13.9282 81.1142Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 21.1208 9.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2321)">
        <path d="M21.1208 0.200989L20.2979 3.94009L17.2641 1.60472L19.0372 4.99799L15.212 5.1591L18.7514 6.61879L15.9247 9.20099L19.5743 8.04409L19.0687 11.8391L21.1208 8.60699L23.173 11.8391L22.6674 8.04409L26.317 9.20099L23.4903 6.61879L27.0297 5.1591L23.2045 4.99799L24.9776 1.60472L21.9437 3.94009L21.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2321" x="15.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M29.087 56.1378C30.1763 64.8385 30.4318 65.5512 31.4538 73.7946C33.3634 88.9097 33.202 91.532 31.3059 93.4819C29.4098 95.4318 26.4244 95.7949 20.5478 96.4403C18.7996 96.6421 19.6468 96.4403 9.29214 96.2924C5.41923 96.2924 4.34342 96.2924 3.22727 95.4049C2.11111 94.5173 1.15634 93.1188 1.00841 86.8253C0.937405 82.2495 1.31575 77.6778 2.13801 73.176C3.48277 64.6502 3.64414 65.5646 4.06102 61.9203C4.92167 54.3897 3.89965 53.7307 4.80064 47.128C5.47119 43.0364 6.41007 38.9934 7.61119 35.0251C8.59677 30.8396 9.88669 26.7316 11.4707 22.734C14.1602 16.575 14.9939 17.476 18.571 9.70325C19.7947 7.01373 20.521 6.79099 21.3547 6.85823C22.1885 6.92547 22.7263 7.65921 23.8962 15.3512C25.3755 24.5225 26.1286 30.7487 26.1286 30.7487C26.653 35.1461 26.653 35.5361 27.4733 42.4482C27.8767 46.0387 28.455 51.0815 29.087 56.1378Z" fill="#DEE1E9"/>
      <mask id="mask2321" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="34" height="45">
        <path d="M1.06347 86.9825C1.21139 93.276 2.2872 94.7956 3.28232 95.5621C4.27744 96.3286 5.47428 96.4093 9.36064 96.4496C19.7019 96.5841 18.8547 96.7993 20.6028 96.5975C26.5332 95.9521 29.4917 95.6159 31.3609 93.6391C33.2302 91.6623 33.4184 89.0669 31.5089 73.9518C30.8903 69.0434 30.5541 66.7976 30.1641 63.9736L28.1201 60.7866C26.95 58.3166 24.9583 56.3297 22.4855 55.1655C19.8607 54.0553 16.9302 53.8984 14.2018 54.7217C12.6359 55.0887 11.1411 55.7111 9.77752 56.564L4.15642 62.0506C3.73954 65.6949 3.59162 64.7402 2.23341 73.3063C1.40275 77.8166 1.01094 82.3967 1.06347 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2321)">
        <path d="M1.06347 86.9825C1.21139 93.276 2.2872 94.7956 3.28232 95.5621C4.27744 96.3286 5.47428 96.4093 9.36064 96.4496C19.7019 96.5841 18.8547 96.7993 20.6028 96.5975C26.5332 95.9521 29.4917 95.6159 31.3609 93.6391C33.2302 91.6623 33.4184 89.0669 31.5089 73.9518C30.8903 69.0434 30.5541 66.7976 30.1641 63.9736L28.1201 60.7866C26.95 58.3166 24.9583 56.3297 22.4855 55.1655C19.8607 54.0553 16.9302 53.8984 14.2018 54.7217C12.6359 55.0887 11.1411 55.7111 9.77752 56.564L4.15642 62.0506C3.73954 65.6949 3.59162 64.7402 2.23341 73.3063C1.40275 77.8166 1.01094 82.3967 1.06347 86.9825Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M17.3399 81.201C18.3203 81.201 19.8547 89.6027 19.8547 99.201H17.9317H14.8547C14.8547 89.6027 16.3596 81.201 17.3399 81.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M13.9282 81.1142C11.711 78.2183 13.5028 74.1605 14.2118 59.7703C14.8435 47.0426 13.6317 46.3097 15.0626 39.5704C16.1197 34.6188 17.1381 33.2781 18.7495 25.4842C20.0386 19.1918 20.4124 9.14764 20.7476 9.20126C21.0827 9.25489 20.7476 21.8017 20.7476 31.9553C20.7476 41.6798 21.2374 40.5715 21.3148 50.2424C21.4179 64.3108 20.464 65.9018 21.882 71.5863C23.0293 76.1983 24.4602 78.2719 23.5836 80.3456C22.2558 83.5454 16.4678 84.4034 13.9282 81.1142Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 21.1208 9.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2321)">
        <path d="M21.1208 0.200989L20.2979 3.94009L17.2641 1.60472L19.0372 4.99799L15.212 5.1591L18.7514 6.61879L15.9247 9.20099L19.5743 8.04409L19.0687 11.8391L21.1208 8.60699L23.173 11.8391L22.6674 8.04409L26.317 9.20099L23.4903 6.61879L27.0297 5.1591L23.2045 4.99799L24.9776 1.60472L21.9437 3.94009L21.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2321" x="15.012" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M29.087 53.1378C30.1763 61.8384 30.4318 62.5511 31.4538 70.7945C33.3634 85.9096 33.202 88.5319 31.3059 90.4818C29.4098 92.4317 26.4244 92.7948 20.5478 93.4403C18.7996 93.642 19.6468 93.4403 9.29214 93.2924C5.41923 93.2924 4.34342 93.2923 3.22727 92.4048C2.11111 91.5173 1.15634 90.1187 1.00841 83.8252C0.937405 79.2494 1.31575 74.6778 2.13801 70.1759C3.48277 61.6501 3.64414 62.5646 4.06102 58.9203C4.92167 51.3896 3.89965 50.7307 4.80064 44.1279C5.47119 40.0364 6.41007 35.9934 7.61119 32.025C8.59677 27.8395 9.88669 23.7316 11.4707 19.7339C14.1602 13.5749 14.9939 14.4759 18.571 6.70319C19.7947 4.01366 20.521 3.79093 21.3547 3.85817C22.1885 3.92541 22.7263 4.65915 23.8962 12.3512C25.3755 21.5224 26.1286 27.7487 26.1286 27.7487C26.653 32.1461 26.653 32.536 27.4733 39.4481C27.8767 43.0386 28.455 48.0815 29.087 53.1378Z" fill="#DEE1E9"/>
      <mask id="mask2521" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="34" height="45">
        <path d="M1.06347 83.9824C1.21139 90.2759 2.2872 91.7955 3.28232 92.562C4.27744 93.3285 5.47428 93.4092 9.36064 93.4495C19.7019 93.584 18.8547 93.7991 20.6028 93.5974C26.5332 92.9519 29.4917 92.6158 31.3609 90.639C33.2302 88.6622 33.4184 86.0668 31.5089 70.9516C30.8903 66.0433 30.5541 63.7975 30.1641 60.9735L28.1201 57.7864C26.95 55.3164 24.9583 53.3295 22.4855 52.1653C19.8607 51.0552 16.9302 50.8982 14.2018 51.7216C12.6359 52.0886 11.1411 52.711 9.77752 53.5639L4.15642 59.0505C3.73954 62.6948 3.59162 61.74 2.23341 70.3062C1.40275 74.8164 1.01094 79.3966 1.06347 83.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2521)">
        <path d="M1.06347 83.9824C1.21139 90.2759 2.2872 91.7955 3.28232 92.562C4.27744 93.3285 5.47428 93.4092 9.36064 93.4495C19.7019 93.584 18.8547 93.7991 20.6028 93.5974C26.5332 92.9519 29.4917 92.6158 31.3609 90.639C33.2302 88.6622 33.4184 86.0668 31.5089 70.9516C30.8903 66.0433 30.5541 63.7975 30.1641 60.9735L28.1201 57.7864C26.95 55.3164 24.9583 53.3295 22.4855 52.1653C19.8607 51.0552 16.9302 50.8982 14.2018 51.7216C12.6359 52.0886 11.1411 52.711 9.77752 53.5639L4.15642 59.0505C3.73954 62.6948 3.59162 61.74 2.23341 70.3062C1.40275 74.8164 1.01094 79.3966 1.06347 83.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M17.6061 85.2009C18.5864 85.2009 20.1208 90.3353 20.1208 96.2009H18.1978H15.1208C15.1208 90.3353 16.6257 85.2009 17.6061 85.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M14.1943 83.9449C11.9771 80.8142 13.7689 76.4273 14.4779 60.8704C15.1096 47.1107 13.8979 46.3184 15.3287 39.0327C16.3858 33.6796 17.4042 32.2302 19.0156 23.8043C20.3047 17.0018 20.6785 6.14318 21.0137 6.20116C21.3488 6.25914 21.0137 19.8233 21.0137 30.8001C21.0137 41.3131 21.5035 40.1149 21.5809 50.57C21.684 65.779 20.7301 67.499 22.1481 73.6445C23.2954 78.6304 24.7263 80.8722 23.8497 83.1139C22.5219 86.5732 16.7339 87.5008 14.1943 83.9449Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 20.1208 4.20093)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2621" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="91">
        <path d="M29.087 49.7888C30.1763 58.4894 30.4318 59.2022 31.4538 67.4455C33.3634 82.5607 33.202 85.1829 31.3059 87.1328C29.4098 89.0827 26.4244 89.4458 20.5478 90.0913C18.7996 90.293 19.6468 90.0913 9.29214 89.9434C5.41923 89.9434 4.34342 89.9434 3.22727 89.0558C2.11111 88.1683 1.15634 86.7697 1.00841 80.4763C0.937405 75.9005 1.31575 71.3288 2.13801 66.8269C3.48277 58.3012 3.64414 59.2156 4.06102 55.5713C4.92167 48.0406 3.89965 47.3817 4.80064 40.7789C5.47119 36.6874 6.41007 32.6444 7.61119 28.6761C8.59677 24.4905 9.88669 20.3826 11.4707 16.385C14.1602 10.2259 14.9939 11.1269 18.571 3.35422C19.7947 0.664693 20.5239 0.6278 21.3577 0.695038C22.1914 0.762276 22.7263 1.31017 23.8962 9.00221C25.3755 18.1735 26.1286 24.3997 26.1286 24.3997C26.653 28.7971 26.653 29.1871 27.4733 36.0991C27.8767 39.6896 28.455 44.7325 29.087 49.7888Z" fill="#DEE1E9"/>
        <path d="M1.01122 80.4787C1.15914 86.7722 2.23495 88.2918 3.23008 89.0583C4.2252 89.8248 5.42204 89.9055 9.3084 89.9458C19.6496 90.0803 18.8024 90.2955 20.5506 90.0938C26.481 89.4483 29.4395 89.1121 31.3087 87.1353C33.1779 85.1585 33.3662 82.5631 31.4566 67.448C30.838 62.5396 30.5018 60.2939 30.1118 57.4699L28.0678 54.2828C26.8977 51.8128 24.906 49.8259 22.4333 48.6617C19.8084 47.5516 16.878 47.3946 14.1495 48.2179C12.5836 48.5849 11.0889 49.2073 9.72527 50.0602L4.10417 55.5469C3.68729 59.1912 3.53937 58.2364 2.18116 66.8025C1.3505 71.3128 0.958694 75.8929 1.01122 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2621)">
        <path d="M29.087 49.7888C30.1763 58.4894 30.4318 59.2022 31.4538 67.4455C33.3634 82.5607 33.202 85.1829 31.3059 87.1328C29.4098 89.0827 26.4244 89.4458 20.5478 90.0913C18.7996 90.293 19.6468 90.0913 9.29214 89.9434C5.41923 89.9434 4.34342 89.9434 3.22727 89.0558C2.11111 88.1683 1.15634 86.7697 1.00841 80.4763C0.937405 75.9005 1.31575 71.3288 2.13801 66.8269C3.48277 58.3012 3.64414 59.2156 4.06102 55.5713C4.92167 48.0406 3.89965 47.3817 4.80064 40.7789C5.47119 36.6874 6.41007 32.6444 7.61119 28.6761C8.59677 24.4905 9.88669 20.3826 11.4707 16.385C14.1602 10.2259 14.9939 11.1269 18.571 3.35422C19.7947 0.664693 20.5239 0.6278 21.3577 0.695038C22.1914 0.762276 22.7263 1.31017 23.8962 9.00221C25.3755 18.1735 26.1286 24.3997 26.1286 24.3997C26.653 28.7971 26.653 29.1871 27.4733 36.0991C27.8767 39.6896 28.455 44.7325 29.087 49.7888Z" fill="#DEE1E9"/>
        <path d="M1.01122 80.4787C1.15914 86.7722 2.23495 88.2918 3.23008 89.0583C4.2252 89.8248 5.42204 89.9055 9.3084 89.9458C19.6496 90.0803 18.8024 90.2955 20.5506 90.0938C26.481 89.4483 29.4395 89.1121 31.3087 87.1353C33.1779 85.1585 33.3662 82.5631 31.4566 67.448C30.838 62.5396 30.5018 60.2939 30.1118 57.4699L28.0678 54.2828C26.8977 51.8128 24.906 49.8259 22.4333 48.6617C19.8084 47.5516 16.878 47.3946 14.1495 48.2179C12.5836 48.5849 11.0889 49.2073 9.72527 50.0602L4.10417 55.5469C3.68729 59.1912 3.53937 58.2364 2.18116 66.8025C1.3505 71.3128 0.958694 75.8929 1.01122 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.5978 55.5247H-0.323328C1.83873 50.1549 2.19573 46.8947 1.8992 41.1564C1.88802 40.94 2.13739 40.8054 2.32761 40.932C3.33169 41.6 5.68988 43.4161 6.26153 46.0577C6.954 49.2577 8.9572 50.0348 9.16981 50.1089C9.18277 50.1134 9.19435 50.1179 9.20674 50.1236L10.3897 50.6703C10.5342 50.7371 10.5928 50.8996 10.5206 51.0332L9.99164 52.0109C9.94689 52.0936 9.95096 52.1917 10.0024 52.271L11.8488 55.1151C11.9658 55.2954 11.8253 55.5247 11.5978 55.5247Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.67275 42.0459C3.73373 42.8938 5.14171 44.3307 5.54413 46.1903C6.28734 49.6248 8.46331 50.5809 8.89628 50.7361L9.66986 51.0936L9.33726 51.7084C9.18064 51.9979 9.19492 52.3413 9.3751 52.6189L10.8225 54.8485H0.719993C2.49672 50.1838 2.87272 46.9881 2.67275 42.0459ZM8.87957 50.7284C8.87942 50.7283 8.87971 50.7285 8.87957 50.7284V50.7284ZM2.75508 40.3832C2.02622 39.8983 1.13049 40.4547 1.16842 41.1886C1.46123 46.8548 1.11202 50.0209 -1.009 55.2889L-1.37622 56.2009H11.5979C12.3941 56.2009 12.8859 55.3982 12.4762 54.7672L10.7539 52.114L11.175 51.3356C11.428 50.8679 11.2229 50.2992 10.7169 50.0654L9.53399 49.5188C9.49326 49.5 9.45742 49.4864 9.4279 49.4761C9.3913 49.4634 7.61049 48.8432 6.97901 45.9251C6.34951 43.0161 3.78613 41.0691 2.75508 40.3832Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask121" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="34" height="91">
          <path d="M29.087 49.7888C30.1763 58.4894 30.4318 59.2022 31.4538 67.4455C33.3634 82.5607 33.202 85.1829 31.3059 87.1328C29.4098 89.0827 26.4244 89.4458 20.5478 90.0913C18.7996 90.293 19.6468 90.0913 9.29214 89.9434C5.41923 89.9434 4.34342 89.9434 3.22727 89.0558C2.11111 88.1683 1.15634 86.7697 1.00841 80.4763C0.937405 75.9005 1.31575 71.3288 2.13801 66.8269C3.48277 58.3012 3.64414 59.2156 4.06102 55.5713C4.92167 48.0406 3.89965 47.3817 4.80064 40.7789C5.47119 36.6874 6.41007 32.6444 7.61119 28.6761C8.59677 24.4905 9.88669 20.3826 11.4707 16.385C14.1602 10.2259 14.9939 11.1269 18.571 3.35422C19.7947 0.664693 20.5239 0.6278 21.3577 0.695038C22.1914 0.762276 22.7263 1.31017 23.8962 9.00221C25.3755 18.1735 26.1286 24.3997 26.1286 24.3997C26.653 28.7971 26.653 29.1871 27.4733 36.0991C27.8767 39.6896 28.455 44.7325 29.087 49.7888Z" fill="#DEE1E9"/>
          <path d="M1.01122 80.4787C1.15914 86.7722 2.23495 88.2918 3.23008 89.0583C4.2252 89.8248 5.42204 89.9055 9.3084 89.9458C19.6496 90.0803 18.8024 90.2955 20.5506 90.0938C26.481 89.4483 29.4395 89.1121 31.3087 87.1353C33.1779 85.1585 33.3662 82.5631 31.4566 67.448C30.838 62.5396 30.5018 60.2939 30.1118 57.4699L28.0678 54.2828C26.8977 51.8128 24.906 49.8259 22.4333 48.6617C19.8084 47.5516 16.878 47.3946 14.1495 48.2179C12.5836 48.5849 11.0889 49.2073 9.72527 50.0602L4.10417 55.5469C3.68729 59.1912 3.53937 58.2364 2.18116 66.8025C1.3505 71.3128 0.958694 75.8929 1.01122 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask121)">
          <path d="M29.087 49.7888C30.1763 58.4894 30.4318 59.2022 31.4538 67.4455C33.3634 82.5607 33.202 85.1829 31.3059 87.1328C29.4098 89.0827 26.4244 89.4458 20.5478 90.0913C18.7996 90.293 19.6468 90.0913 9.29214 89.9434C5.41923 89.9434 4.34342 89.9434 3.22727 89.0558C2.11111 88.1683 1.15634 86.7697 1.00841 80.4763C0.937405 75.9005 1.31575 71.3288 2.13801 66.8269C3.48277 58.3012 3.64414 59.2156 4.06102 55.5713C4.92167 48.0406 3.89965 47.3817 4.80064 40.7789C5.47119 36.6874 6.41007 32.6444 7.61119 28.6761C8.59677 24.4905 9.88669 20.3826 11.4707 16.385C14.1602 10.2259 14.9939 11.1269 18.571 3.35422C19.7947 0.664693 20.5239 0.6278 21.3577 0.695038C22.1914 0.762276 22.7263 1.31017 23.8962 9.00221C25.3755 18.1735 26.1286 24.3997 26.1286 24.3997C26.653 28.7971 26.653 29.1871 27.4733 36.0991C27.8767 39.6896 28.455 44.7325 29.087 49.7888Z" fill="#DEE1E9"/>
          <path d="M1.01122 80.4787C1.15914 86.7722 2.23495 88.2918 3.23008 89.0583C4.2252 89.8248 5.42204 89.9055 9.3084 89.9458C19.6496 90.0803 18.8024 90.2955 20.5506 90.0938C26.481 89.4483 29.4395 89.1121 31.3087 87.1353C33.1779 85.1585 33.3662 82.5631 31.4566 67.448C30.838 62.5396 30.5018 60.2939 30.1118 57.4699L28.0678 54.2828C26.8977 51.8128 24.906 49.8259 22.4333 48.6617C19.8084 47.5516 16.878 47.3946 14.1495 48.2179C12.5836 48.5849 11.0889 49.2073 9.72527 50.0602L4.10417 55.5469C3.68729 59.1912 3.53937 58.2364 2.18116 66.8025C1.3505 71.3128 0.958694 75.8929 1.01122 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M9.92268 55.5315H1.77592C3.25343 51.561 3.49741 49.1504 3.29476 44.9074C3.28712 44.7474 3.45754 44.6479 3.58753 44.7415C4.2737 45.2354 5.88526 46.5782 6.27592 48.5315C6.74913 50.8976 8.1181 51.4722 8.26339 51.527C8.27224 51.5303 8.28016 51.5337 8.28863 51.5379L9.09703 51.9421C9.19583 51.9915 9.23587 52.1116 9.18647 52.2104L8.82501 52.9333C8.79443 52.9945 8.79722 53.067 8.8324 53.1257L10.0942 55.2286C10.1742 55.3619 10.0781 55.5315 9.92268 55.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.82339 45.5651C4.54845 46.1921 5.51064 47.2545 5.78565 48.6295C6.29354 51.169 7.78057 51.876 8.07646 51.9908L8.60512 52.2551L8.37782 52.7097C8.27079 52.9238 8.28054 53.1777 8.40368 53.3829L9.39284 55.0315H2.4889C3.70309 51.5824 3.96005 49.2194 3.82339 45.5651ZM8.06504 51.9851C8.06494 51.985 8.06514 51.9851 8.06504 51.9851V51.9851ZM3.87965 44.3357C3.38156 43.9771 2.76943 44.3885 2.79535 44.9312C2.99545 49.1208 2.75681 51.4619 1.30733 55.3571L1.05638 56.0315H9.9227C10.4668 56.0315 10.8029 55.4379 10.5229 54.9713L9.3459 53.0096L9.63371 52.434C9.8066 52.0882 9.66644 51.6677 9.32066 51.4948L8.51226 51.0906C8.48443 51.0767 8.45993 51.0667 8.43976 51.0591C8.41475 51.0497 7.19777 50.5911 6.76623 48.4334C6.33604 46.2825 4.58425 44.8428 3.87965 44.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M29.3531 50.1378C30.4424 58.8384 30.6979 59.5511 31.7199 67.7945C33.6295 82.9096 33.4681 85.5319 31.572 87.4818C29.6759 89.4317 26.6905 89.7948 20.8139 90.4403C19.0657 90.642 19.9129 90.4403 9.55825 90.2924C5.68534 90.2924 4.60953 90.2923 3.49338 89.4048C2.37723 88.5173 1.42245 87.1187 1.27452 80.8252C1.20352 76.2494 1.58187 71.6778 2.40412 67.1759C3.74888 58.6501 3.91026 59.5646 4.32713 55.9203C5.18778 48.3896 4.16576 47.7307 5.06675 41.1279C5.7373 37.0364 6.67618 32.9934 7.8773 29.025C8.86288 24.8395 10.1528 20.7316 11.7368 16.7339C14.4263 10.5749 15.26 11.4759 18.8371 3.70319C20.0608 1.01366 20.7871 0.790932 21.6208 0.85817C22.4546 0.925408 22.9924 1.65915 24.1624 9.35118C25.6416 18.5224 26.3947 24.7487 26.3947 24.7487C26.9191 29.1461 26.9191 29.536 27.7394 36.4481C28.1429 40.0386 28.7211 45.0815 29.3531 50.1378Z" fill="#DEE1E9"/>
        <mask id="mask221" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="47" width="34" height="45">
          <path d="M1.32958 80.9824C1.4775 87.2759 2.55331 88.7955 3.54844 89.562C4.54356 90.3285 5.74039 90.4092 9.62675 90.4495C19.968 90.584 19.1208 90.7991 20.869 90.5974C26.7994 89.9519 29.7578 89.6158 31.627 87.639C33.4963 85.6622 33.6845 83.0668 31.775 67.9516C31.1564 63.0433 30.8202 60.7975 30.4302 57.9735L28.3862 54.7864C27.2161 52.3164 25.2244 50.3295 22.7516 49.1653C20.1268 48.0552 17.1963 47.8982 14.4679 48.7216C12.902 49.0886 11.4072 49.711 10.0436 50.5639L4.42253 56.0505C4.00565 59.6948 3.85773 58.74 2.49952 67.3062C1.66886 71.8164 1.27705 76.3966 1.32958 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask221)">
          <path d="M1.32958 80.9824C1.4775 87.2759 2.55331 88.7955 3.54844 89.562C4.54356 90.3285 5.74039 90.4092 9.62675 90.4495C19.968 90.584 19.1208 90.7991 20.869 90.5974C26.7994 89.9519 29.7578 89.6158 31.627 87.639C33.4963 85.6622 33.6845 83.0668 31.775 67.9516C31.1564 63.0433 30.8202 60.7975 30.4302 57.9735L28.3862 54.7864C27.2161 52.3164 25.2244 50.3295 22.7516 49.1653C20.1268 48.0552 17.1963 47.8982 14.4679 48.7216C12.902 49.0886 11.4072 49.711 10.0436 50.5639L4.42253 56.0505C4.00565 59.6948 3.85773 58.74 2.49952 67.3062C1.66886 71.8164 1.27705 76.3966 1.32958 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M17.6001 75.2009C18.9727 75.2009 21.1208 83.6026 21.1208 93.2009H18.4285H14.1208C14.1208 83.6026 16.2276 75.2009 17.6001 75.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M14.1943 75.1141C11.9771 72.2182 13.7689 68.1603 14.4779 53.7702C15.1096 41.0425 13.8979 40.3096 15.3287 33.5703C16.3858 28.6187 17.4042 27.278 19.0156 19.4841C20.3047 13.1917 20.6785 3.14751 21.0137 3.20114C21.3488 3.25477 21.0137 15.8016 21.0137 25.9552C21.0137 35.6797 21.5035 34.5714 21.5809 44.2423C21.684 58.3107 20.7301 59.9016 22.1481 65.5862C23.2954 70.1982 24.7263 72.2718 23.8497 74.3454C22.5219 77.5452 16.7339 78.4033 14.1943 75.1141Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M29.3531 50.1378C30.4424 58.8384 30.6979 59.5511 31.7199 67.7945C33.6295 82.9096 33.4681 85.5319 31.572 87.4818C29.6759 89.4317 26.6905 89.7948 20.8139 90.4403C19.0657 90.642 19.9129 90.4403 9.55825 90.2924C5.68534 90.2924 4.60953 90.2923 3.49338 89.4048C2.37723 88.5173 1.42245 87.1187 1.27452 80.8252C1.20352 76.2494 1.58187 71.6778 2.40412 67.1759C3.74888 58.6501 3.91026 59.5646 4.32713 55.9203C5.18778 48.3896 4.16576 47.7307 5.06675 41.1279C5.7373 37.0364 6.67618 32.9934 7.8773 29.025C8.86288 24.8395 10.1528 20.7316 11.7368 16.7339C14.4263 10.5749 15.26 11.4759 18.8371 3.70319C20.0608 1.01366 20.7871 0.790932 21.6208 0.85817C22.4546 0.925408 22.9924 1.65915 24.1624 9.35118C25.6416 18.5224 26.3947 24.7487 26.3947 24.7487C26.9191 29.1461 26.9191 29.536 27.7394 36.4481C28.1429 40.0386 28.7211 45.0815 29.3531 50.1378Z" fill="#DEE1E9"/>
        <mask id="mask321" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="47" width="34" height="45">
          <path d="M1.32958 80.9824C1.4775 87.2759 2.55331 88.7955 3.54844 89.562C4.54356 90.3285 5.74039 90.4092 9.62675 90.4495C19.968 90.584 19.1208 90.7991 20.869 90.5974C26.7994 89.9519 29.7578 89.6158 31.627 87.639C33.4963 85.6622 33.6845 83.0668 31.775 67.9516C31.1564 63.0433 30.8202 60.7975 30.4302 57.9735L28.3862 54.7864C27.2161 52.3164 25.2244 50.3295 22.7516 49.1653C20.1268 48.0552 17.1963 47.8982 14.4679 48.7216C12.902 49.0886 11.4072 49.711 10.0436 50.5639L4.42253 56.0505C4.00565 59.6948 3.85773 58.74 2.49952 67.3062C1.66886 71.8164 1.27705 76.3966 1.32958 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask321)">
          <path d="M1.32958 80.9824C1.4775 87.2759 2.55331 88.7955 3.54844 89.562C4.54356 90.3285 5.74039 90.4092 9.62675 90.4495C19.968 90.584 19.1208 90.7991 20.869 90.5974C26.7994 89.9519 29.7578 89.6158 31.627 87.639C33.4963 85.6622 33.6845 83.0668 31.775 67.9516C31.1564 63.0433 30.8202 60.7975 30.4302 57.9735L28.3862 54.7864C27.2161 52.3164 25.2244 50.3295 22.7516 49.1653C20.1268 48.0552 17.1963 47.8982 14.4679 48.7216C12.902 49.0886 11.4072 49.711 10.0436 50.5639L4.42253 56.0505C4.00565 59.6948 3.85773 58.74 2.49952 67.3062C1.66886 71.8164 1.27705 76.3966 1.32958 80.9824Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M17.6061 81.2009C18.5864 81.2009 20.1208 87.2688 20.1208 94.2009H18.1978H15.1208C15.1208 87.2688 16.6257 81.2009 17.6061 81.2009Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
        <path d="M29.3531 49.7888C30.4424 58.4894 30.6979 59.2022 31.7199 67.4455C33.6295 82.5607 33.4681 85.1829 31.572 87.1328C29.6759 89.0827 26.6905 89.4458 20.8139 90.0913C19.0657 90.293 19.9129 90.0913 9.55825 89.9434C5.68534 89.9434 4.60953 89.9434 3.49338 89.0558C2.37723 88.1683 1.42245 86.7697 1.27452 80.4763C1.20352 75.9005 1.58187 71.3288 2.40412 66.8269C3.74888 58.3012 3.91026 59.2156 4.32713 55.5713C5.18778 48.0406 4.16576 47.3817 5.06675 40.7789C5.7373 36.6874 6.67618 32.6444 7.8773 28.6761C8.86288 24.4905 10.1528 20.3826 11.7368 16.385C14.4263 10.2259 15.26 11.1269 18.8371 3.35422C20.0608 0.664693 20.79 0.6278 21.6238 0.695038C22.4575 0.762276 22.9924 1.31017 24.1624 9.00221C25.6416 18.1735 26.3947 24.3997 26.3947 24.3997C26.9191 28.7971 26.9191 29.1871 27.7394 36.0991C28.1429 39.6896 28.7211 44.7325 29.3531 49.7888Z" fill="#DEE1E9"/>
        <path d="M1.27733 80.4787C1.42526 86.7722 2.50106 88.2918 3.49619 89.0583C4.49131 89.8248 5.68815 89.9055 9.57451 89.9458C19.9157 90.0803 19.0685 90.2955 20.8167 90.0938C26.7471 89.4483 29.7056 89.1121 31.5748 87.1353C33.444 85.1585 33.6323 82.5631 31.7227 67.448C31.1041 62.5396 30.7679 60.2939 30.378 57.4699L28.3339 54.2828C27.1638 51.8128 25.1722 49.8259 22.6994 48.6617C20.0745 47.5516 17.1441 47.3946 14.4156 48.2179C12.8497 48.5849 11.355 49.2073 9.99138 50.0602L4.37028 55.5469C3.95341 59.1912 3.80548 58.2364 2.44727 66.8025C1.61662 71.3128 1.22481 75.8929 1.27733 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.6238 75.0679L18.7571 77.5687L17.1238 76.073V79.3871L14.5616 78.8192L15.9571 80.5698L13.6238 82.5704L15.9571 84.5711L14.5616 86.3217L16.8904 85.5715L17.1238 89.0679L19.3737 87.5708L20.1571 88.5725L21.8739 87.5708L24.1238 89.0679L24.8238 87.072L26.686 86.3217L26.2238 83.5708L27.6238 82.5704L25.2904 81.0699L26.686 78.8192L24.8238 78.5691L24.1238 76.073L21.0904 78.0689L20.6238 75.0679Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
        <path d="M29.3531 49.7888C30.4424 58.4894 30.6979 59.2022 31.7199 67.4455C33.6295 82.5607 33.4681 85.1829 31.572 87.1328C29.6759 89.0827 26.6905 89.4458 20.8139 90.0913C19.0657 90.293 19.9129 90.0913 9.55825 89.9434C5.68534 89.9434 4.60953 89.9434 3.49338 89.0558C2.37723 88.1683 1.42245 86.7697 1.27452 80.4763C1.20352 75.9005 1.58187 71.3288 2.40412 66.8269C3.74888 58.3012 3.91026 59.2156 4.32713 55.5713C5.18778 48.0406 4.16576 47.3817 5.06675 40.7789C5.7373 36.6874 6.67618 32.6444 7.8773 28.6761C8.86288 24.4905 10.1528 20.3826 11.7368 16.385C14.4263 10.2259 15.26 11.1269 18.8371 3.35422C20.0608 0.664693 20.79 0.6278 21.6238 0.695038C22.4575 0.762276 22.9924 1.31017 24.1624 9.00221C25.6416 18.1735 26.3947 24.3997 26.3947 24.3997C26.9191 28.7971 26.9191 29.1871 27.7394 36.0991C28.1429 39.6896 28.7211 44.7325 29.3531 49.7888Z" fill="#DEE1E9"/>
        <path d="M1.27733 80.4787C1.42526 86.7722 2.50106 88.2918 3.49619 89.0583C4.49131 89.8248 5.68815 89.9055 9.57451 89.9458C19.9157 90.0803 19.0685 90.2955 20.8167 90.0938C26.7471 89.4483 29.7056 89.1121 31.5748 87.1353C33.444 85.1585 33.6323 82.5631 31.7227 67.448C31.1041 62.5396 30.7679 60.2939 30.378 57.4699L28.3339 54.2828C27.1638 51.8128 25.1722 49.8259 22.6994 48.6617C20.0745 47.5516 17.1441 47.3946 14.4156 48.2179C12.8497 48.5849 11.355 49.2073 9.99138 50.0602L4.37028 55.5469C3.95341 59.1912 3.80548 58.2364 2.44727 66.8025C1.61662 71.3128 1.22481 75.8929 1.27733 80.4787Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M25.6238 77.0679L24.0238 79.2115L22.6238 77.9294V80.77L20.4276 80.2833L21.6238 81.7838L19.6238 83.4987L21.6238 85.2135L20.4276 86.714L22.4238 86.071L22.6238 89.0679L24.5523 87.7847L25.2238 88.6433L26.6953 87.7847L28.6238 89.0679L29.2238 87.3571L30.8199 86.714L30.4238 84.3561L31.6238 83.4987L29.6238 82.2125L30.8199 80.2833L29.2238 80.0689L28.6238 77.9294L26.0238 79.6402L25.6238 77.0679Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
        <path d="M29.087 49.8542C30.1763 58.5548 30.4318 59.2676 31.4538 67.511C33.3634 82.6261 33.202 85.2484 31.3059 87.1983C29.4098 89.1482 26.4244 89.5113 20.5478 90.1567C18.7996 90.3585 19.6468 90.1567 9.29214 90.0088C5.41923 90.0088 4.34342 90.0088 3.22727 89.1213C2.11111 88.2337 1.15634 86.8352 1.00841 80.5417C0.937405 75.9659 1.31575 71.3942 2.13801 66.8924C3.48277 58.3666 3.64414 59.281 4.06102 55.6367C4.92167 48.1061 3.89965 47.4471 4.80064 40.8443C5.47119 36.7528 6.41007 32.7098 7.61119 28.7415C8.59677 24.556 9.88669 20.448 11.4707 16.4504C14.1602 10.2914 14.9939 11.1924 18.571 3.41965C19.7947 0.730123 20.5239 0.693229 21.3577 0.760468C22.1914 0.827706 22.7263 1.3756 23.8962 9.06764C25.3755 18.2389 26.1286 24.4651 26.1286 24.4651C26.653 28.8625 26.653 29.2525 27.4733 36.1646C27.8767 39.7551 28.455 44.7979 29.087 49.8542Z" fill="#DEE1E9"/>
        <path d="M1.01122 80.5442C1.15914 86.8376 2.23495 88.3572 3.23008 89.1237C4.2252 89.8903 5.42204 89.9709 9.3084 90.0113C19.6496 90.1457 18.8024 90.3609 20.5506 90.1592C26.481 89.5137 29.4395 89.1775 31.3087 87.2007C33.1779 85.2239 33.3662 82.6285 31.4566 67.5134C30.838 62.605 30.5018 60.3593 30.1118 57.5353L28.0678 54.3482C26.8977 51.8782 24.906 49.8913 22.4333 48.7271C19.8084 47.617 16.878 47.46 14.1495 48.2833C12.5836 48.6503 11.0889 49.2728 9.72527 50.1257L4.10417 55.6123C3.68729 59.2566 3.53937 58.3018 2.18116 66.8679C1.3505 71.3782 0.958694 75.9583 1.01122 80.5442Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M23.8577 82.1333L22.9243 83.3837L22.1077 82.6359V84.2929L20.8266 84.0089L21.5243 84.8842L20.3577 85.8846L21.5243 86.8849L20.8266 87.7602L21.991 87.3851L22.1077 89.1333L23.2326 88.3848L23.6243 88.8856L24.4827 88.3848L25.6077 89.1333L25.9577 88.1354L26.8888 87.7602L26.6577 86.3848L27.3577 85.8846L26.191 85.1343L26.8888 84.0089L25.9577 83.8839L25.6077 82.6359L24.091 83.6338L23.8577 82.1333Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
        <path d="M20.3713 2.68065L18.2876 1.41089H14.8039L12.7039 2.68065L14.3155 4.22716L12.2318 5.77367L14.1202 7.32018L11.8248 9.0132L13.6481 10.5597L11.3528 11.9597L13.3714 13.376L11.3528 14.9876L13.0458 16.5341L10.8155 17.9504L12.5737 19.4155L10.2783 20.962L12.4272 22.5085L10.2783 24.1201L11.8248 25.4713L9.74113 27.0178L11.5644 28.4992L9.48066 30.0457L11.2876 31.7876L10.2783 33.4643V47.7247L12.1667 55.0503H18.2876" fill="#8889BE"/>
        <path d="M20.3713 2.68065L18.2876 1.41089H14.8039L12.7039 2.68065L14.3155 4.22716L12.2318 5.77367L14.1202 7.32018L11.8248 9.0132L13.6481 10.5597L11.3528 11.9597L13.3714 13.376L11.3528 14.9876L13.0458 16.5341L10.8155 17.9504L12.5737 19.4155L10.2783 20.962L12.4272 22.5085L10.2783 24.1201L11.8248 25.4713L9.74113 27.0178L11.5644 28.4992L9.48066 30.0457L11.2876 31.7876L10.2783 33.4643V47.7247L12.1667 55.0503H18.2876" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M12.9641 2.68065L15.0478 1.41089H18.5152L20.6315 2.68065L19.0036 4.22716L21.1036 5.77367L19.2152 7.32018L21.4943 9.0132L19.6873 10.5597L21.9664 11.9597L19.9478 13.376L21.9664 14.9876L20.2896 16.5341L22.5036 17.9504L20.7617 19.4155L23.0408 20.962L20.892 22.5085L23.0408 24.1201L21.4943 25.4713L23.578 27.0178L21.771 28.4992L23.8547 30.0457L22.0315 31.7876L23.0408 33.4643V47.7247L21.1687 55.0503H17.2617" fill="#8889BE"/>
        <path d="M12.9641 2.68065L15.0478 1.41089H18.5152L20.6315 2.68065L19.0036 4.22716L21.1036 5.77367L19.2152 7.32018L21.4943 9.0132L19.6873 10.5597L21.9664 11.9597L19.9478 13.376L21.9664 14.9876L20.2896 16.5341L22.5036 17.9504L20.7617 19.4155L23.0408 20.962L20.892 22.5085L23.0408 24.1201L21.4943 25.4713L23.578 27.0178L21.771 28.4992L23.8547 30.0457L22.0315 31.7876L23.0408 33.4643V47.7247L21.1687 55.0503H17.2617" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M2.99329 73.2683C1.99817 72.5018 0.922358 70.9822 0.774433 64.6888C0.721909 60.1029 1.11372 55.5228 1.94437 51.0125C3.30258 42.4464 3.69728 41.1843 4.11416 37.54L8.73738 37.54C9.28966 37.54 9.73738 37.9877 9.73738 38.54V51.0125C15.7051 52.2298 17.4447 52.2176 23.41 51.0125V38.54C23.41 37.9877 23.8578 37.54 24.41 37.54L28.9873 37.54L29.8751 41.6799C30.265 44.5039 30.6012 46.7496 31.2198 51.658C33.1294 66.7731 32.9411 69.3685 31.0719 71.3453C29.2027 73.3221 26.2442 73.6583 20.3138 74.3038C18.5656 74.5055 19.4128 74.2903 9.07161 74.1559C5.18525 74.1155 3.98841 74.0348 2.99329 73.2683Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask521" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="37" width="34" height="38">
          <path d="M3.00013 73.2683C2.005 72.5018 0.929193 70.9822 0.781269 64.6887C0.728745 60.1029 1.12055 55.5228 1.95121 51.0125C3.30942 42.4464 3.70412 42.0999 4.12099 38.4556L8.74421 38.4556C9.2965 38.4556 9.74421 38.9033 9.74421 39.4556V51.0125C15.7119 52.2298 17.4515 52.2175 23.4169 51.0125V39.4556C23.4169 38.9033 23.8646 38.4556 24.4169 38.4556L28.9941 38.4556L29.8819 41.6798C30.2719 44.5038 30.6081 46.7496 31.2267 51.658C33.1362 66.7731 32.948 69.3685 31.0787 71.3453C29.2095 73.3221 26.251 73.6583 20.3206 74.3037C18.5725 74.5055 19.4197 74.2903 9.07845 74.1558C5.19209 74.1155 3.99525 74.0348 3.00013 73.2683Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask521)">
          <path d="M26.6966 30.2808H6.65254V55.8287C14.4802 57.755 18.8689 57.7745 26.6966 55.8287V30.2808Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
        <path d="M21.0825 2.3557L18.9988 1.08594H15.5151L13.4151 2.3557L15.0267 3.90221L12.943 5.44872L14.8314 6.99523L12.536 8.68825L14.3593 10.2348L12.0639 11.6348L14.0825 13.051L12.0639 14.6627L13.757 16.2092L11.5267 17.6254L13.2849 19.0906L10.9895 20.6371L13.1383 22.1836L10.9895 23.7952L12.536 25.1464L10.4523 26.6929L12.2756 28.1743L10.1918 29.7208L11.9988 31.4626L10.9895 33.1394V47.3998L12.8779 54.7254H18.9988" fill="#8889BE"/>
        <path d="M21.0825 2.3557L18.9988 1.08594H15.5151L13.4151 2.3557L15.0267 3.90221L12.943 5.44872L14.8314 6.99523L12.536 8.68825L14.3593 10.2348L12.0639 11.6348L14.0825 13.051L12.0639 14.6627L13.757 16.2092L11.5267 17.6254L13.2849 19.0906L10.9895 20.6371L13.1383 22.1836L10.9895 23.7952L12.536 25.1464L10.4523 26.6929L12.2756 28.1743L10.1918 29.7208L11.9988 31.4626L10.9895 33.1394V47.3998L12.8779 54.7254H18.9988" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M13.6752 2.3557L15.7589 1.08594H19.2264L21.3426 2.3557L19.7147 3.90221L21.8147 5.44872L19.9264 6.99523L22.2054 8.68825L20.3985 10.2348L22.6775 11.6348L20.6589 13.051L22.6775 14.6627L21.0008 16.2092L23.2147 17.6254L21.4729 19.0906L23.7519 20.6371L21.6031 22.1836L23.7519 23.7952L22.2054 25.1464L24.2891 26.6929L22.4822 28.1743L24.5659 29.7208L22.7426 31.4626L23.7519 33.1394V47.3998L21.8798 54.7254H17.9729" fill="#8889BE"/>
        <path d="M13.6752 2.3557L15.7589 1.08594H19.2264L21.3426 2.3557L19.7147 3.90221L21.8147 5.44872L19.9264 6.99523L22.2054 8.68825L20.3985 10.2348L22.6775 11.6348L20.6589 13.051L22.6775 14.6627L21.0008 16.2092L23.2147 17.6254L21.4729 19.0906L23.7519 20.6371L21.6031 22.1836L23.7519 23.7952L22.2054 25.1464L24.2891 26.6929L22.4822 28.1743L24.5659 29.7208L22.7426 31.4626L23.7519 33.1394V47.3998L21.8798 54.7254H17.9729" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M3.70441 73.7083C2.70929 72.9418 1.63348 71.4222 1.48555 65.1287C1.43303 60.5429 1.82484 55.9627 2.65549 51.4525C4.0137 42.8863 4.4084 41.6243 4.82528 37.98H10.7392C11.2915 37.98 11.7392 38.4277 11.7392 38.98V51.4525C17.7069 52.6698 17.3916 52.6575 23.357 51.4525V38.98C23.357 38.4277 23.8047 37.98 24.357 37.98H29.6984L30.5862 42.1198C30.9762 44.9438 31.3124 47.1896 31.9309 52.098C33.8405 67.2131 33.6522 69.8085 31.783 71.7853C29.9138 73.7621 26.9553 74.0982 21.0249 74.7437C19.2767 74.9454 20.1239 74.7303 9.78273 74.5958C5.89637 74.5555 4.69953 74.4748 3.70441 73.7083Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
        <path d="M35.8093 87.771H14.8093V92.771H35.8093V87.771Z" fill="#838AA2"/>
        <path d="M28.087 49.4924C29.1763 58.193 29.4318 58.9058 30.4538 67.1492C32.3634 82.2643 32.202 84.8866 30.3059 86.8365C28.4098 88.7864 25.4244 89.1494 19.5478 89.7949C17.7996 89.9966 18.6468 89.7949 8.29214 89.647C4.41923 89.647 3.34342 89.647 2.22727 88.7595C1.11111 87.8719 0.156336 86.4734 0.00841141 80.1799C-0.0625954 75.6041 0.315752 71.0324 1.13801 66.5306C2.48277 58.0048 2.64414 58.9192 3.06102 55.2749C3.92167 47.7442 2.89965 47.0853 3.80064 40.4825C4.47119 36.391 5.41007 32.348 6.61119 28.3797C7.59677 24.1942 8.88669 20.0862 10.4707 16.0886C13.1602 9.92956 13.9939 10.8305 17.571 3.05783C18.7947 0.368306 19.5239 0.331413 20.3577 0.398651C21.1914 0.465889 21.7263 1.01379 22.8962 8.70582C24.3755 17.8771 25.1286 24.1033 25.1286 24.1033C25.653 28.5007 25.653 28.8907 26.4733 35.8027C26.8767 39.3933 27.455 44.4361 28.087 49.4924Z" fill="#DEE1E9"/>
        <path d="M2.23008 88.762C1.23495 87.9955 0.159143 86.4759 0.011219 80.1824C-0.0413055 75.5966 0.350502 71.0165 1.18116 66.5062C2.53937 57.9401 2.93407 56.678 3.35094 53.0337H9.26484C9.81713 53.0337 10.2648 53.4814 10.2648 54.0337V66.5062C16.2325 67.7235 15.9173 67.7112 21.8827 66.5062V54.0337C21.8827 53.4814 22.3304 53.0337 22.8827 53.0337H28.2241L29.1118 57.1735C29.5018 59.9975 29.838 62.2433 30.4566 67.1517C32.3662 82.2668 32.1779 84.8622 30.3087 86.839C28.4395 88.8158 25.481 89.152 19.5506 89.7974C17.8024 89.9992 18.6496 89.784 8.3084 89.6495C4.42204 89.6092 3.2252 89.5285 2.23008 88.762Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
        <path d="M29.087 49.8038C30.1763 58.5044 30.4318 59.2171 31.4538 67.4605C33.3634 82.5756 33.202 85.1979 31.3059 87.1478C29.4098 89.0977 26.4244 89.4608 20.5478 90.1063C18.7996 90.308 19.6468 90.1063 9.29214 89.9584C5.41923 89.9584 4.34342 89.9584 3.22727 89.0708C2.11111 88.1833 1.15634 86.7847 1.00841 80.4912C0.937405 75.9155 1.31575 71.3438 2.13801 66.8419C3.48277 58.3161 3.64414 59.2306 4.06102 55.5863C4.92167 48.0556 3.89965 47.3967 4.80064 40.7939C5.47119 36.7024 6.41007 32.6594 7.61119 28.6911C8.59677 24.5055 9.88669 20.3976 11.4707 16.3999C14.1602 10.2409 14.9939 11.1419 18.571 3.3692C19.7947 0.67968 20.521 0.456947 21.3547 0.524185C22.1885 0.591423 22.7263 1.32516 23.8962 9.01719C25.3755 18.1885 26.1286 24.4147 26.1286 24.4147C26.653 28.8121 26.653 29.202 27.4733 36.1141C27.8767 39.7046 28.455 44.7475 29.087 49.8038Z" fill="#DEE1E9"/>
        <path d="M1.01122 80.4939C1.15914 86.7873 2.23495 88.3069 3.23008 89.0734C4.2252 89.84 5.42204 89.9206 9.3084 89.961C19.6496 90.0954 18.8024 90.3106 20.5506 90.1089C26.481 89.4634 29.4395 89.1272 31.3087 87.1504C33.1779 85.1736 33.3662 82.5782 31.4566 67.4631C30.838 62.5547 30.5018 60.309 30.1118 57.485L28.0678 54.2979C26.8977 51.8279 24.906 49.841 22.4333 48.6768C19.8084 47.5667 16.878 47.4097 14.1495 48.233C12.5836 48.6 11.0889 49.2225 9.72527 50.0754L4.10417 55.562C3.68729 59.2063 3.53937 58.2515 2.18116 66.8176C1.3505 71.3279 0.958694 75.908 1.01122 80.4939Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M15.5122 56.5114C13.7482 54.3329 15.1738 51.2803 15.7378 40.455C16.2404 30.8803 15.2763 30.3289 16.4147 25.2591C17.2557 21.5342 18.066 20.5256 19.3479 14.6624C20.3735 9.92889 20.671 2.3729 20.9376 2.41325C21.2043 2.45359 20.9376 11.8922 20.9376 19.5305C20.9376 26.846 21.3273 26.0122 21.3889 33.2874C21.4709 43.8706 20.712 45.0675 21.8401 49.3438C22.7529 52.8133 23.8913 54.3732 23.1939 55.9332C22.1376 58.3403 17.5326 58.9858 15.5122 56.5114Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
        <path d="M29.087 49.8038C30.1763 58.5044 30.4318 59.2171 31.4538 67.4605C33.3634 82.5756 33.202 85.1979 31.3059 87.1478C29.4098 89.0977 26.4244 89.4608 20.5478 90.1063C18.7996 90.308 19.6468 90.1063 9.29214 89.9584C5.41923 89.9584 4.34342 89.9584 3.22727 89.0708C2.11111 88.1833 1.15634 86.7847 1.00841 80.4912C0.937405 75.9155 1.31575 71.3438 2.13801 66.8419C3.48277 58.3161 3.64414 59.2306 4.06102 55.5863C4.92167 48.0556 3.89965 47.3967 4.80064 40.7939C5.47119 36.7024 6.41007 32.6594 7.61119 28.6911C8.59677 24.5055 9.88669 20.3976 11.4707 16.3999C14.1602 10.2409 14.9939 11.1419 18.571 3.3692C19.7947 0.67968 20.521 0.456947 21.3547 0.524185C22.1885 0.591423 22.7263 1.32516 23.8962 9.01719C25.3755 18.1885 26.1286 24.4147 26.1286 24.4147C26.653 28.8121 26.653 29.202 27.4733 36.1141C27.8767 39.7046 28.455 44.7475 29.087 49.8038Z" fill="#DEE1E9"/>
        <path d="M1.01122 80.4939C1.15914 86.7873 2.23495 88.3069 3.23008 89.0734C4.2252 89.84 5.42204 89.9206 9.3084 89.961C19.6496 90.0954 18.8024 90.3106 20.5506 90.1089C26.481 89.4634 29.4395 89.1272 31.3087 87.1504C33.1779 85.1736 33.3662 82.5782 31.4566 67.4631C30.838 62.5547 30.5018 60.309 30.1118 57.485L28.0678 54.2979C26.8977 51.8279 24.906 49.841 22.4333 48.6768C19.8084 47.5667 16.878 47.4097 14.1495 48.233C12.5836 48.6 11.0889 49.2225 9.72527 50.0754L4.10417 55.562C3.68729 59.2063 3.53937 58.2515 2.18116 66.8176C1.3505 71.3279 0.958694 75.908 1.01122 80.4939Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.4" d="M15.5122 56.5114C13.7482 54.3329 15.1738 51.2803 15.7378 40.455C16.2404 30.8803 15.2763 30.3289 16.4147 25.2591C17.2557 21.5342 18.066 20.5256 19.3479 14.6624C20.3735 9.92889 20.671 2.3729 20.9376 2.41325C21.2043 2.45359 20.9376 11.8922 20.9376 19.5305C20.9376 26.846 21.3273 26.0122 21.3889 33.2874C21.4709 43.8706 20.712 45.0675 21.8401 49.3438C22.7529 52.8133 23.8913 54.3732 23.1939 55.9332C22.1376 58.3403 17.5326 58.9858 15.5122 56.5114Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
        <path d="M28.9918 49.9371C30.0811 58.6377 30.3366 59.3504 31.3586 67.5938C33.2682 82.7089 33.1068 85.3312 31.2107 87.2811C29.3146 89.231 26.3292 89.5941 20.4526 90.2396C18.7044 90.4413 19.5516 90.2396 9.19693 90.0917C5.32401 90.0917 4.2482 90.0917 3.13205 89.2041C2.0159 88.3166 1.06112 86.918 0.913197 80.6245C0.84219 76.0488 1.22054 71.4771 2.0428 66.9752C3.38756 58.4494 3.54893 59.3639 3.96581 55.7196C4.82645 48.1889 3.80443 47.53 4.70542 40.9272C5.37598 36.8357 6.31485 32.7927 7.51597 28.8244C8.50155 24.6388 9.79147 20.5309 11.3754 16.5332C14.065 10.3742 14.8987 11.2752 18.4758 3.5025C19.6995 0.812981 20.4258 0.590248 21.2595 0.657486C22.0933 0.724724 22.6311 1.45846 23.801 9.1505C25.2803 18.3218 26.0333 24.548 26.0333 24.548C26.5578 28.9454 26.5578 29.3354 27.3781 36.2474C27.7815 39.8379 28.3598 44.8808 28.9918 49.9371Z" fill="#DEE1E9"/>
        <path d="M0.916004 80.6272C1.06393 86.9206 2.13974 88.4402 3.13486 89.2067C4.12998 89.9733 5.32682 90.0539 9.21318 90.0943C19.5544 90.2287 18.7072 90.4439 20.4554 90.2422C26.3858 89.5967 29.3443 89.2605 31.2135 87.2837C33.0827 85.3069 33.271 82.7115 31.3614 67.5964C30.7428 62.688 30.4066 60.4423 30.0166 57.6183L27.9726 54.4312C26.8025 51.9612 24.8108 49.9743 22.338 48.8101C19.7132 47.7 16.7827 47.543 14.0543 48.3663C12.4884 48.7333 10.9936 49.3558 9.63006 50.2087L4.00896 55.6953C3.59208 59.3396 3.44415 58.3848 2.08595 66.9509C1.25529 71.4612 0.86348 76.0413 0.916004 80.6272Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask521" mask-type="alpha" maskUnits="userSpaceOnUse" x="14" y="1" width="9" height="25">
          <path d="M21.8203 3.53054L21.1205 1.84033H19.7206L18.7325 2.92005L14.2037 25.2887H22.9731L22.6026 15.3626L22.1497 6.5825L21.8203 3.53054Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask521)">
          <path opacity="0.4" d="M15.417 56.6447C13.653 54.4662 15.0786 51.4136 15.6426 40.5883C16.1452 31.0136 15.1811 30.4622 16.3195 25.3924C17.1605 21.6675 17.9707 20.6589 19.2527 14.7957C20.2783 10.0622 20.5758 2.5062 20.8424 2.54655C21.1091 2.58689 20.8424 12.0255 20.8424 19.6638C20.8424 26.9793 21.2321 26.1455 21.2937 33.4207C21.3757 44.0039 20.6168 45.2008 21.7449 49.4771C22.6577 52.9466 23.7961 54.5065 23.0987 56.0665C22.0424 58.4736 17.4374 59.1191 15.417 56.6447Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5021" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="25" width="13" height="37">
          <path d="M14.0135 25.355H22.3906L24.1801 61.4703L12.9968 59.437L14.0135 25.355Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5021)">
          <path opacity="0.2" d="M15.417 56.6447C13.653 54.4662 15.0786 51.4136 15.6426 40.5883C16.1452 31.0136 15.1811 30.4622 16.3195 25.3924C17.1605 21.6675 17.9707 20.6589 19.2527 14.7957C20.2783 10.0622 20.5758 2.5062 20.8424 2.54655C21.1091 2.58689 20.8424 12.0255 20.8424 19.6638C20.8424 26.9793 21.2321 26.1455 21.2937 33.4207C21.3757 44.0039 20.6168 45.2008 21.7449 49.4771C22.6577 52.9466 23.7961 54.5065 23.0987 56.0665C22.0424 58.4736 17.4374 59.1191 15.417 56.6447Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M28.5841 50.1236C29.6734 58.8242 29.9289 59.537 30.9509 67.7804C32.8604 82.8955 32.6991 85.5178 30.803 87.4677C28.9068 89.4176 25.9215 89.7807 20.0449 90.4261C18.2967 90.6279 19.1439 90.4261 8.78921 90.2782C4.9163 90.2782 3.84049 90.2782 2.72434 89.3907C1.60818 88.5031 0.653406 87.1046 0.505482 80.8111C0.434475 76.2353 0.812822 71.6636 1.63508 67.1618C2.97984 58.636 3.14121 59.5504 3.55809 55.9061C4.41874 48.3755 3.39672 47.7165 4.29771 41.1138C4.96826 37.0222 5.90714 32.9792 7.10826 29.0109C8.09384 24.8254 9.38376 20.7174 10.9677 16.7198C13.6572 10.5608 14.491 11.4618 18.0681 3.68905C19.2918 0.999526 20.521 0.799743 21.3547 0.866982C22.1885 0.93422 22.2234 1.64501 23.3933 9.33704C24.8726 18.5083 25.6256 24.7346 25.6256 24.7346C26.1501 29.1319 26.1501 29.5219 26.9704 36.434C27.3738 40.0245 27.9521 45.0673 28.5841 50.1236Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M28.5841 49.4608C29.6734 58.1614 29.9289 58.8742 30.9509 67.1175C32.8604 82.2327 32.6991 84.8549 30.803 86.8048C28.9068 88.7547 25.9215 89.1178 20.0449 89.7633C18.2967 89.965 19.1439 89.7633 8.78921 89.6154C4.9163 89.6154 3.84049 89.6154 2.72434 88.7278C1.60818 87.8403 0.653406 86.4417 0.505482 80.1483C0.434475 75.5725 0.812822 71.0008 1.63508 66.4989C2.97984 57.9732 3.14121 58.8876 3.55809 55.2433C4.41874 47.7126 3.39672 47.0537 4.29771 40.4509C4.96826 36.3594 5.90714 32.3164 7.10826 28.3481C8.09384 24.1625 9.38376 20.0546 10.9677 16.0569C13.6572 9.89794 14.491 10.7989 18.0681 3.02621C19.2918 0.33669 20.021 0.299797 20.8547 0.367035C21.6885 0.434273 22.2234 0.982172 23.3933 8.6742C24.8726 17.8455 25.6256 24.0717 25.6256 24.0717C26.1501 28.4691 26.1501 28.8591 26.9704 35.7711C27.3738 39.3616 27.9521 44.4045 28.5841 49.4608Z" fill="#DEE1E9"/>
      <path d="M0.508289 80.1508C0.656214 86.4443 1.73202 87.9638 2.72715 88.7304C3.72227 89.4969 4.91911 89.5775 8.80547 89.6179C19.1467 89.7524 18.2995 89.9675 20.0477 89.7658C25.9781 89.1203 28.9365 88.7841 30.8058 86.8073C32.675 84.8305 32.8632 82.2352 30.9537 67.12C30.3351 62.2117 29.9989 59.9659 29.6089 57.1419L27.5649 53.9548C26.3948 51.4848 24.4031 49.4979 21.9303 48.3337C19.3055 47.2236 16.375 47.0666 13.6466 47.89C12.0807 48.257 10.5859 48.8794 9.22234 49.7323L3.60124 55.2189C3.18436 58.8632 3.03644 57.9084 1.67823 66.4746C0.847574 70.9848 0.455765 75.565 0.508289 80.1508Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth21",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
