<template>
  <div class="patient-canban-card card p-md-3 p-2 mb-3 pointer"
       :data-patient-id="element.patient_id"
       :data-id="element.id">
    <div class="font-weight-bold pr-3">
      <span class="text-primary">{{ element.patient.full_name }}</span>
    </div>
    <div>{{ element.patient.phone }}</div>
    <div v-if="element.comment">{{ element.comment }}</div>
    <div v-if="element.curator" class="mt-2">
      {{ $t('curator') }}: {{ element.curator.full_name }}
    </div>
    <div v-for="treatment_plan in element.treatment_plans"
         :key="`tr-${treatment_plan.planning_id}`"
         class="mt-2">
      <div class="font-weight-bold">{{ $t('treatment_plan') }}: {{ treatment_plan.message }}</div>
      <div>
        <div v-for="visit in treatment_plan.visits"
             :key="`vis-${visit.id}`"
             class="visit-line">
          {{ visit.fullTitle }} ({{ $t(visit.manual_status) }})
          <span class="circle ml-2" :class="`status-${visit.manual_status}`"></span>
        </div>
      </div>
    </div>
    <div class="task-bubbles">
      <div class="d-flex flex-row">
        <div class="task-bubble success" v-if="getTasksNotStarted(element.tasks) > 0">{{ getTasksNotStarted(element.tasks) }}</div>
        <div class="task-bubble danger" v-if="getTasksPassed(element.tasks) > 0">{{ getTasksPassed(element.tasks) }}</div>
        <div class="task-bubble danger" v-if="getNoTasks(element.tasks)">0</div>
      </div>
    </div>
    <slot/>
  </div>
</template>

<script setup>
import moment from "moment"

defineProps({
  element: Object
})

const getTasksNotStarted = (tasks) => {
  return tasks.filter(task => !task.finished && task.start > moment().format('YYYY-MM-DD HH:mm:ss')).length
}

const getTasksPassed = (tasks) => {
  return tasks.filter(task => !task.finished && task.deadline < moment().format('YYYY-MM-DD HH:mm:ss')).length
}

const getNoTasks = (tasks) => {
  return tasks.length === 0 || (tasks.length > 0 && tasks.every(task => task.finished))
}
</script>
