<template>
  <b-modal id="refill-bonus-modal"
           v-if="patient"
           @hide="onHide"
           :title="$t('bonus_adjustment')"
           centered
           hide-footer>
    <b-row>
      <b-col>
        <label class="form-label">{{ $t('bonuses') }}</label>
        <div class="emphasized-text">{{ formatCurrency(bonusForBranch) }}</div>
      </b-col>
    </b-row>
    <hr/>
    <b-row class="align-items-end">
      <b-col>
        <InputGroup type="number"
                    :label="$t('sum')"
                    :required="true"
                    v-model="amount"/>
        <ValidationError validationId="paid"/>
      </b-col>
      <b-col>
        <div class="form-group">
          <b-dropdown right variant="link" no-caret>
            <template #button-content class="w-100">

              <a class="pointer position-relative btn-themed btn-success btn-themed-squared btn-process d-block w-100">{{ $t('process') }}</a>

            </template>
            <div class="pt-0">
              <button :disabled="loading"
                      @click="save(1)"
                      class="btn w-100 text-left">
                {{ $t('filling') }}
              </button>
              <button :disabled="loading"
                      @click="save(-1)"
                      class="btn w-100 text-left">
                {{ $t('write_off') }}
              </button>
            </div>
          </b-dropdown>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

import { mapActions, mapState } from "vuex"
import InputGroup from "@/components/form/InputGroup"
import { EloquentService } from "@/services/api.service"
import ValidationError from "@/components/form/ValidationError"
import {formatCurrency} from "@/extensions/filters/filters"

export default {
  name: "RefillBonusModal",
  components: {
    ValidationError,
    InputGroup
  },
  data() {
    return {
      amount: 0,
    }
  },
  methods: {
    formatCurrency,
    ...mapActions(['getPatient']),
    save(sign) {
      let formData = new FormData()
      formData = this.appendFormdata(formData, {
        patient_id: this.patient.id,
        branch_id: this.branchId,
        value: Math.abs(this.amount) * sign
      })
      EloquentService.create('bonus_manual_transaction', formData).then(() => {
        this.getPatient(this.patient.id)
        this.$bvModal.hide('refill-bonus-modal')
        this.$store.commit('incrementDataTableCounter')
      })
    },
    onHide() {
      this.amount = 0
    }
  },
  computed: {
    ...mapState({
      loading: state  => state.dom.loading,
      patient: state  => state.patient.patient,
      branchId: state => state.auth.branch,
    }),
    bonusForBranch() {
      if(!this.patient) return 0
      return this.patient.total_bonuses
      // if(this.patient.bonuses_branched) return this.patient.bonuses_branched[this.branchId]
      // return 0
    },
  },
}
</script>

<style scoped lang="scss">
.btn-success {
  height: 46px;
  line-height: 44px;
}
.dropdown {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .btn-process {
    height: 32px;
    line-height: 30px;
  }
}
</style>
