<template>
  <div v-if="currencies.length > 0" class="mt-3" style="max-width: 600px;">
    <div class="mb-3">
    <a class="d-inline-block btn-themed btn-themed-squared btn-themed-outline" @click="addItem">{{ $t('add') }}</a>
    </div>
    <div v-for="(c, cind) in clinicRates" :key="`clinic-rate-${cind}`">
      <b-row class="align-items-end">
        <b-col cols="auto">
          <SelectGroup :options="currencies"
                       title="name"
                       :label="$t('currency')"
                       :showlabel="true"
                       variant="white"
                       style="min-width: 100px;"
                       class="d-inline-block align-middle mb-0"
                       v-model="c.currency_id"/>
        </b-col>
        <b-col cols="auto">
          <InputGroup :label="$t('rate')"
                      class="mb-0"
                      type="text"
                      :required="true"
                      v-model="c.value"/>
        </b-col>
        <b-col cols="auto">
          <a class="pointer"
             @click="refreshServices(c)"
             v-b-tooltip.hover
             :title="$t('refresh_prices')">
            <RefreshSvg class="svg-light mb-2 mb-md-3"/>
          </a>
        </b-col>
        <b-col cols="auto">
          <a class="pointer" @click="deleteCurrency(cind)">
            <TrashSvg class="svg-light mb-2 mb-md-3"/>
          </a>
        </b-col>
      </b-row>
      <hr/>
    </div>
    <a class="btn-themed d-inline-block btn-themed-squared mt-4" @click="save">{{ $t('save') }}</a>
  </div>
</template>

<script>

import {ClinicService, EloquentService} from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup"
import InputGroup from "@/components/form/InputGroup"
import TrashSvg from '@/assets/svg-vue/general/trash_tr.svg'
import RefreshSvg from '@/assets/svg-vue/general/refresh2.svg'
import {mapState} from "vuex"
import {currenciesAdapter} from "@/adapters/settings/currencies"

export default {
  name: "SettingsCurrencies",
  components: {
    SelectGroup,
    InputGroup,
    TrashSvg,
    RefreshSvg
  },
  head() {
    return {
      title: this.$t('currency_rates'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      clinicRates: [],
      currencies: []
    }
  },
  created() {
    this.getCurrencies()
    this.getAllCurrencies()
  },
  methods: {
    getCurrencies() {
      EloquentService.dataTable('clinic_currency', 1, { pagination: 999 }).then(res => {
        this.clinicRates = res.data.result.data
      })
    },
    getAllCurrencies() {
      EloquentService.dataTable('currency', 1, { pagination: 999 }).then(res => {
        this.currencies = res.data.result.data
      })
    },
    deleteCurrency(index) {
      this.clinicRates.splice(index, 1)
    },
    async refreshServices(currency) {
      await this.save(false)
      let res = await ClinicService.recountServicesExchangeRate(currency)
      if(res.data) this.$noty.info(this.$t('success_message'))
    },
    addItem() {
      this.clinicRates.push({
        currency_id: null,
        value: 0
      })
    },
    async save(message = true) {
      let res = await ClinicService.saveCurrencyRates(currenciesAdapter(this.clinicRates))
      this.clinicRates = res.data
      if(message) this.$noty.info(this.$t('success_message'))
    }
  }
}
</script>
