<template>
  <div v-if="hasRight" class="mb-4">
    <b-button v-spaceduck-collapse="`tilda-c`" class="btn-collapse">Tilda</b-button>
    <spaceduck-collapse id="tilda-c">
      <div class="mt-3 pb-5">
        <div class="mt-1" v-html="$t('tilda_integration_instructions', { url: `https://api2.dentist-plus.com/tilda/webhook/${clinic.id}` })"></div>
        <div class="mt-2">
          <InputGroup v-model="tilda_key"
                      class="mt-3"
                      :readonly="true"
                      :label="$t('api_key')"/>
          <button class="btn-themed btn-themed-squared d-inline-block mt-4"
                  :disabled="loading"
                  @click="save">
            {{ $t('generate_key') }}
          </button>
        </div>
      </div>
    </spaceduck-collapse>
  </div>

</template>

<script>
import { mapState } from "vuex"
import { BinotelService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "TildaIntegration",
  components: {
    SpaceduckCollapse,
    InputGroup,
  },
  data() {
    return {
      tilda_key: '',
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        tilda_key: '',
      }
    },
    hasRight() {
      if(this.superadmin) return this.clinic.rights.includes('crm')
      return this.rights.includes('crm')
    }
  },
  async mounted() {
    this.tilda_key = this.ppoSettings.tilda_key
  },
  methods: {
    async save() {
      await BinotelService.generateTildaKey()
      this.$noty.info(this.$t('success_message'))
      await this.$store.dispatch('init')
      this.tilda_key = this.ppoSettings.tilda_key
    }
  }
}
</script>
