export const treatmentTemplateAdapter =
    ({
         bite,
         complaints,
         teeth_plansurvey,
         teeth_medication,
         teeth_recommendations,
         inspection,
         objectively,
         subjectively,
         diseases,
         development,
         research,
         description,
         template_id
     }, diagnoses, descriptions) => ({
        bite: bite ?? null,
        complaints: complaints ?? null,
        teeth_plansurvey: teeth_plansurvey ?? null,
        teeth_medication: teeth_medication ?? null,
        teeth_recommendations: teeth_recommendations ?? null,
        inspection: inspection ?? null,
        objectively: objectively ?? null,
        subjectively: subjectively ?? null,
        diseases: diseases ?? null,
        development: development ?? null,
        research: research ?? null,
        description: description ?? null,
        id: template_id ? template_id : null,
        diagnoses: Object.keys(diagnoses),
        options: adaptDescriptions(descriptions)
    })

function adaptDescriptions (descriptions) {
    const options = []
    if(!descriptions) return options
    for(let tooth in descriptions) {
        for(let template in descriptions[tooth]) {
            options.push({
                text: descriptions[tooth][template],
                treatment_field: 'teeth_'+template
            })
        }
    }
    return options
}
