<template>
  <div id="patient-calling-modal"
       class="custom-modal"
       v-if="showCallModal"
       :title="$t('call_noun')">
    <div class="text-right">
      <a class="close-svg" @click="closeCustomModal"><CloseSvg/></a>
    </div>
    <div class="pt-lg-2">
      <div>
        <span class="mr-2">{{ $t('call_from_phone') }}: {{ phone }}</span>
        (<a class="text-primary pointer"
           v-if="!copied"
           @click="copyPhoneToClipboard">{{ $t('copy_to_clipboard') }}</a>
        <a v-else>{{ $t('copied') }}</a>)
      </div>
      <div v-if="patients && patients.length > 1">
        {{ $t('patient') }}:
        <select v-model="patient" class="form-control w-auto pr-5 d-inline-block py-0 ml-2" style="height: 34px;">
          <option v-for="p in patients" :value="p" :key="`p-${p.id}`">{{ p.full_name }}</option>
        </select>
      </div>
      <div v-else-if="patient">{{ $t('patient') }}: {{ patient.full_name }}</div>
      <div v-else>{{ $t('patient_not_derived') }}</div>
    </div>
    <div class="pt-4 pt-lg-5">
      <div class="modal-footer-buttons no-print pl-lg-5">
        <button class="btn-themed btn-themed-squared btn-themed-outline d-inline-block d-sm-inline-block align-middle"
                @click="toCalendar">
          {{ $t('record_on_visit') }}
        </button>
        <button class="ml-3 btn-themed btn-themed-squared btn-themed-outline w-auto px-3 d-inline-block d-sm-inline-block align-middle"
                @click="createTask">
          {{ $t('create_task') }}
        </button>
        <button class="ml-3 btn-themed btn-themed-squared btn-themed-outline w-auto px-3 d-inline-block d-sm-inline-block align-middle"
                @click="createLead">
          {{ $t('create_lead') }}
        </button>
        <button v-if="!patient"
                class="ml-3 btn-themed btn-themed-squared w-auto px-3 d-inline-block d-sm-inline-block align-middle"
                @click="addPatient">
          {{ $t('add_patient') }}
        </button>
        <button :disabled="loading"
                v-if="patient"
                @click="moveToCard"
                class="ml-3 w-auto px-3 btn-themed align-middle d-inline-block d-sm-inline-block btn-themed-squared">
          {{ $t('patient_card') }}
        </button>
      </div>
    </div>
  </div>

</template>

<script>

import { mapState } from "vuex"
import moment from "moment/moment"
import CloseSvg from '@/assets/svg-vue/general/close.svg'

export default {
  name: "PatientCallingModal",
  components: {
    CloseSvg
  },
  props: {
    patients: {},
    phone: {},
    leadId: {},
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      branch: state => state.auth.branch,
      showCallModal: state => state.crm.showCallModal
    }),
  },
  data() {
    return {
      copied: false,
      patient: null
    }
  },
  methods: {
    toCalendar() {
      navigator.clipboard.writeText(this.phone)
      if(this.patient) {
        this.$store.commit('setPatientFromCall', this.copyObject(this.patient))
      }
      this.$store.commit('setLeadForRecord', this.leadId ? { id: this.leadId } : null)
      this.$store.commit('setPhoneFromCall', this.phone)
      this.$router.push({
        name: 'Calendar',
      }).catch(()=>{})
      this.$store.commit('setShowCallModal', false)
      // this.$bvModal.hide('patient-calling-modal')
    },
    createTask() {
      navigator.clipboard.writeText(this.phone)
      this.$store.commit('setShowModal', false)
      this.$nextTick(() => {
        this.$store.commit('setViewModel', 'task')
        this.$store.commit('setModule', 'crm')
        this.$store.commit('setSelectedModel', {
          phone: this.phone,
          patient_id: this.patient ? this.patient.id : null,
          patient: this.patient ?? null,
          task_type: 'task',
          start: moment().format('YYYY-MM-DD'),
          deadline: moment().format('YYYY-MM-DD')
        })
        this.$store.commit('setShowModal', true)
      })
      this.$store.commit('setShowCallModal', false)
    },
    createLead() {
      navigator.clipboard.writeText(this.phone)
      this.$store.commit('setShowModal', false)
      this.$nextTick(() => {
        this.$store.commit('setViewModel', 'lead')
        this.$store.commit('setModule', 'crm')
        this.$store.commit('setSelectedModel', {
          phone: this.phone,
          patient_id: this.patient ? this.patient.id : null,
          fname: this.patient ? this.patient.fname : null,
          lname: this.patient ? this.patient.lname : null,
          patient: this.patient ?? null,
        })
        this.$store.commit('setShowModal', true)
      })
      this.$store.commit('setShowCallModal', false)
    },
    addPatient() {
      this.$store.commit('setShowModal', false)
      this.$nextTick(() => {
        this.$store.commit('setViewModel', 'patient')
        this.$store.commit('setModule', 'patients')
        this.$store.commit('setSelectedModel', {
          branch_id: this.branch,
          mphone: this.phone
        })
        this.$store.commit('setShowModal', true)
      })
      this.$store.commit('setShowCallModal', false)
      // this.$bvModal.hide('patient-calling-modal')
    },
    moveToCard() {
      if(!this.patient) return
      this.$store.commit('setShowCallModal', false)
      // this.$bvModal.hide('patient-calling-modal')
      this.$router.push({
        name: 'PatientVisits',
        params: {
          id: this.patient.id,
        }
      }).catch(()=>{})
    },
    copyPhoneToClipboard() {
      navigator.clipboard.writeText(this.phone)
      this.copied = true
    },
    closeCustomModal() {
      this.$store.commit('setShowCallModal', false)
      this.copied = false
    },
    show() {
      if(this.patients && this.patients.length) {
        this.patient = this.patients[0]
      } else {
        this.patient = null
      }
    }
  },
  // watch: {
  //   showCallModal(val) {
  //     if(val) {
  //       this.show()
  //     }
  //   }
  // }
}
</script>

<style scoped lang="scss">
.custom-modal {
  position: fixed;
  padding: 36px;
  border-radius: 24px;
  box-shadow: 0px 4px 35px -14px rgba(92, 92, 102, 0.38);
  right: 24px;
  bottom: 24px;
  z-index: 30;
  background: #fff;
  .close-svg {
    svg {
      width: 16px;
      height: 16px;
      fill: $gray-text;
      cursor: pointer;
      opacity: 0.7;
    }
    &:hover {
      svg {
        opacity: 1;
      }
    }
  }
}
@media(max-width: 992px) {
  .custom-modal {
    padding: 20px;
  }
}
</style>
