<template>
  <div class="mb-4" v-if="hasRight">
    <b-button v-spaceduck-collapse="`trustme-c`"
              class="btn-collapse">
      TrustMe
    </b-button>
    <spaceduck-collapse id="trustme-c"
                        class="form-group-standard">
      <div class="mt-3 pb-2">
        <AutosizeTextArea v-model="trustme_token"
                    :label="$t('api_token')"/>
        <CheckboxGroup  v-model="trustme_autosign"
                        class="mt-3 mb-0"
                        checkboxvalue="1"
                        :label="$t('turn_on_autosign')"
                        fid="trustme_autosign"/>
        <div class="text-muted small mb-3" style="margin-left: 36px">{{ $t('only_after_accordance_with_trustme') }}</div>
        <button class="btn-themed btn-themed-squared d-inline-block mt-3"
                :disabled="loading"
                @click="save">
          {{ $t('save') }}
        </button>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>
import { mapState } from "vuex"
import { ClinicService, DocumentsService } from "@/services/api.service"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"

export default {
  name: "TrustMeIntegration",
  components: {
    CheckboxGroup,
    AutosizeTextArea,
    SpaceduckCollapse,
  },
  data() {
    return {
      trustme_token: '',
      trustme_autosign: 0
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      superadmin: state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        trustme_token: '',
        trustme_autosign: 0
      }
    },
    hasRight() {
      return true
      // return this.clinic.id === 1843
      // if(this.superadmin) return this.clinic.rights.includes('prodoctorov')
      // return this.rights.includes('prodoctorov')
    }
  },
  async mounted() {
    this.trustme_token = this.ppoSettings.trustme_token
    this.trustme_autosign = this.ppoSettings.trustme_autosign
  },
  methods: {
    async save() {
      await ClinicService.savePPOAttribute([
        'trustme_token',
        'trustme_autosign'
      ], [
        this.trustme_token,
        this.trustme_autosign,
      ], this.superadmin, this.clinic.id)
      await DocumentsService.setTrustMeHook()
      this.$store.dispatch('init')
      this.$noty.info(this.$t('success_message'))
    },
  }
}
</script>
