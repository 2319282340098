<template>
  <span class="data-table-additional-action-span">
  <a class="data-table-action"
     :title="$t('create_mailing')"
     v-b-tooltip.hover
     @click="mail">
    <MailSvg/>
    <span class="action-button-mob-title">{{ $t('create_mailing') }}</span>
  </a>
  <a :title="$t('add_call_task')"
     v-b-tooltip.hover
     class="data-table-action"
     @click="phone">
    <PhoneSvg/><span class="action-button-mob-title">{{ $t('add_call_task') }}</span>
  </a>
    </span>
</template>

<script>

import PhoneSvg from '@/assets/svg-vue/marketing/phone_plus.svg'
import MailSvg from '@/assets/svg-vue/marketing/mail_forward.svg'
import {mapState} from "vuex"
// import {MarketingService} from "@/services/api.service"

export default {
  name: "MarketingGroupDatatableActions",
  components: {
    PhoneSvg,
    MailSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
    }),

  },
  methods: {
    mail() {
      this.$store.commit("setViewModel", 'marketing_mailing')
      this.$store.commit('setModule', 'marketing')
      this.$store.commit('setSelectedModel', {
        group_id: this.object.id
      })
      this.$store.commit('setShowModal', true)
    },
    phone() {
      this.$store.commit("setViewModel", 'task')
      this.$store.commit('setModule', 'crm')
      this.$store.commit('setSelectedModel', {
        task_type: 'call',
        group_id: this.object.id
      })
      this.$store.commit('setShowModal', true)
    }
  }
}
</script>

<style scoped>

</style>
