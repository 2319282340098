<template>
  <div>
    <DataTable :options="options"
               :params="{ branch_id: branch }"
               module="staff"/>
    <DoctorScheduleModal/>
  </div>
</template>

<script>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/staff/administrator"
import { mapState } from "vuex"
import DoctorScheduleModal from "@/components/parts/staff/DoctorScheduleModal.vue"

export default {
  name: "Administrators",
  components: {
    DoctorScheduleModal,
    DataTable
  },
  head() {
    return {
      title: this.$t('administrators'),
    }
  },
  computed: {
    ...mapState({
      branch: state => state.auth.branch
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
