<template>
  <div>
    <router-link class="text-capitalize" :to="{ name: 'AccountingMonthSummary', params: { id: object.id } }">
      {{ formatDate(object.date, 'MMMM, YYYY') }}
    </router-link>
  </div>
</template>

<script>
import {formatDate} from "@/extensions/filters/filters"

export default {
  name: "AccountingDatatableTDDate",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    formatDate
  }
}
</script>
