<template>
  <router-link :to="{ name: 'MarketingGroup', params: {id: object.id }, query: query }" class="font-weight-bold">
    {{ object.title }}
  </router-link>
</template>

<script>
export default {
  name: "MarketingDatatableTDGroupTitle",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    query() {
      return {
        filters: this.object.filters
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped lang="scss">

</style>
