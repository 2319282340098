export const tariffFunctionalityAdapter = (app) => ({
    functionality: getFunctionality(app)
})

function getFunctionality(list) {
    let arr = {}
    list.forEach(item => {
        if(item.status) {
            arr[item.id] = {
                additional_payment: item.payment ? JSON.stringify(item.payment) : null
            }
        }
    })
    return arr
}

