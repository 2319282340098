<template>
  <div>
<!--    <div v-for="visit in object.visits" :key="`treat-visit-${visit.id}`">-->
<!--      [ <span v-for="(t, tind) in visit.teeth" :key="`vt-${visit.id}-${tind}`"><span v-if="tind > 0">, </span>{{ t.tooth }}</span> ]-->
<!--      <span v-for="(service, sind) in visit.services"  :key="`treat-visit-service-${visit.id}-${service.id}-${sind}`">-->
<!--        {{ service.name }}-->
<!--      </span>-->
<!--      <span v-if="visit.manual_status" :class="{-->
<!--        'text-success': visit.manual_status === 'finished', -->
<!--        'text-primary': visit.manual_status === 'in_process', -->
<!--        'text-muted': visit.manual_status === 'new', -->
<!--      }">({{ $t(visit.manual_status) }})</span>-->
<!--      <span v-else-if="visit.records.length" class="text-success">({{ $t('applied') }})</span>-->
<!--    </div>-->
    <div v-for="(visit, vind) in object.visits" :key="`treat-visit-${visit.id}`">
      <span v-if="visit.manual_status" :class="{
        'circle-success': visit.manual_status === 'finished', 
        'circle-warning': visit.manual_status === 'in_process', 
        'circle-gray': visit.manual_status === 'new', 
      }" class="circle sm mr-1"></span>
      {{ visit.title ? visit.title : $t('complex')+' '+(vind + 1) }}
      [ <span v-for="(t, tind) in visit.teeth" :key="`vt-${visit.id}-${tind}`"><span v-if="tind > 0">, </span>{{ t.tooth }}</span> ]
<!--      <span v-for="(service, sind) in visit.services"  :key="`treat-visit-service-${visit.id}-${service.id}-${sind}`">-->
<!--        {{ service.name }}-->
<!--      </span>-->
<!--      <span v-else-if="visit.records.length" class="text-success">({{ $t('applied') }})</span>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "PatientsDatatableTDTreatmentServices",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
  }
}
</script>

<style scoped>

</style>
