export default {
    accept_payment: "Прийняти оплату",
    added: "Додав(-ла)",
    add_new_record: "+ Додати візит",
    additionally: "Додатково",
    are_you_sure_want_to_move_visit: "Ви впевнені, що хочете пересунути візит?",
    are_you_sure_want_to_change_time: "Ви впевнені, що хочете змінити час візиту?",
    are_you_sure_you_want_to_delete_record: "Ви впевнені, що хочете видалити запис?",
    blocked: "Заблоковано",
    by_phone_found: "За номером телефону {phone} знайдено наступних пацієнтів",
    by_phone_nobody_found: "За номером телефону {phone} не знайдено жодного пацієнта",
    calendar_settings: "Налаштування календаря",
    cancel_record: "Відмінити запис",
    change_record: "Змінити запис",
    checkup: "Огляд",
    child_age: "молодше 18-ти",
    choose_all: "Вибрати все",
    clear: "Очистити",
    create_notification: "Створити повідомлення",
    create_patient: "Створити пацієнта",
    create_task: "Створити задачу",
    confirm_visit: "Підтвердити візит",
    curation: "Курація",
    DAY: "На день",
    day_short: "дн",
    days: "днів",
    delete_record: "Видалити візит",
    diagnostics: "Діагностика",
    doctor_available_time: "Доступний час лікаря",
    doctor_has_no_available_time_at_this_day: "У лікаря немає доступного часу в цей день",
    doctor_is_not_working_on_this_date_continue: "Лікар не працює у цей час. Продовжити?",
    doctors_selected: "Вибрано лікарів",
    edit_schedule: "Редагувати розклад",
    edit_visit: "Редагувати візит",
    email_text: "Текст листа",
    email_topic: "Тема листа",
    employees_selected: "Вибрано співробітників",
    fillDoctorColor: "Зафарбовувати запис кольором лікаря",
    fill_treatment: "Заповнити лікування",
    for: "За",
    free_minutes: "Вільних хвилин",
    hideChairsWithoutSchedule: "Сховати крісла без лікарів з графіком роботи у вибраний день",
    hideDoctorsWithoutSchedule: "Ховати лікарів, які не працюють в обраний день",
    hour_short: "г",
    info: "Інфо",
    load_demo_data_to_account: "Завантажити демо-дані в аккаунт?",
    min_short: "хв",
    move_record: "Перенести запис",
    need_to_select_at_least_one_doctor: "Необхідно обрати хоча б одного лікаря",
    need_to_select_at_least_one_employee: "Необхідно обрати хоча б одного співробітника",
    new_visit: "Новий візит",
    no_birthdays_on_this_date: "Немає днів народження на вказану дату",
    no_free_time: "Немає вільного часу",
    no_schedule: "Немає розкладу",
    no_visits: "Візитів немає",
    not_working: "Не працює",
    of_day: "дні",
    online_consultation: "Онлайн консультація",
    patient_came: "Пацієнт прийшов",
    patient_card: "Картка пацієнта",
    patient_notifications: "Повідомлення пацієнту",
    postponed: "Перенесення",
    quick_view: "Швидкий перегляд",
    record_again: "Записати повторно",
    record_conflict: "Конфлікт запису",
    record_has_no_paid_services: "У запису немає платних послуг",
    record_type: "Тип запису",
    related_tasks: "Зв'язані задачі",
    requires_confirmation: "Вимагає підтвердження",
    return_visit: "Повторний візит",
    sanation: "Санація",
    schedule: "Розклад",
    select_a_doctor: "Оберіть лікаря",
    select_an_employee: "Оберіть співробітника",
    selected: "Обрано",
    send_sms: "Відправити SMS",
    sms_text: "Текст SMS",
    showClientTags: "Використовувати кольори поміток пацієнтів",
    showDoctorColor: "Використовувати кольори лікарів",
    showPlannedServices: "Виводити послуги текстом",
    showRecordTagColor: "Використовувати кольори ярликів, як візитний фон",
    showServiceColor: "Використовувати кольори категорій послуг",
    showStatusColor: "Використовувати кольори статусів візиту",
    showOverflowScreen: "Використовувати прокрутку екрану при великому наборі даних",
    till: "до",
    time_has_already_passed: "Час пройшов",
    tomorrow: "Завтра",
    visits_count: "Візитів немає | візит | візити",
    visits_count_much: "візитів",
    warranty_case: "Гарантійний випадок",
    write_reason_for_cancellation: "Укажіть причину відміни",
    yesterday: "Вчора",
    you_have_no_doctors: "У вас не заведено жодного лікаря. Додати лікаря можна <a href='{href}'> тут</a>",
    WEEK: "На тиждень",
    MONTH: "На місяць",
    CHAIR: "По кріслам",
    DOCTOR: "По лікарям",
}
