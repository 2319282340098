<template>
  <section id="tariffs" class="tariffs">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row" >
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('tariffs') }}</h1>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator">
            <AddObject module="superadmin"/>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <DataTable :options="options"
                 module="superadmin"/>
      <TariffFunctionalityModal/>
    </div>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/tariff"
import {mapState} from "vuex"
import AddObject from "@/components/parts/datatable/AddObject"
import TariffFunctionalityModal from "@/components/parts/superadmin/TariffFunctionalityModal"

export default {
  name: "SuperAdminTariffs",
  components: {
    TariffFunctionalityModal,
    AddObject,
    DataTable
  },
  head() {
    return {
      title: this.$t('tariffs'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>

<style scoped>

</style>
