<template>
  <div>
    <a class="tdclickable" v-spaceduck-collapse="'kit-collapse-'+object.id">
      {{ object.title }}
    </a>
    <spaceduck-collapse :id="`kit-collapse-${object.id}`">
      <div v-for="s in object.services" :key="`kit-${object.id}-mat-${s.id}`">
        {{ s.name }}: <b>{{ s.pivot.amount }}</b>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"

export default {
  name: "ServicesDatatableTDComplexServiceTitle",
  components: {
    SpaceduckCollapse
  },
  props: {
    object: {
      type: Object
    }
  }
}
</script>
