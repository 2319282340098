<template>
  <div class="d-inline-block align-middle calendar-navigator position-relative">
    <div class="form-control gray-input d-flex align-items-center justify-content-center">
      <a class="calendar-navigator__left" @click="prev">
        <LeftSvg/>
      </a>
      <span class="nowrap pointer" @click="dateClicked">{{ title }}</span>
      <a class="calendar-navigator__right" @click="next">
        <RightSvg/>
      </a>
      <div v-if="show" v-click-outside="clickedOutside">
      <DatePicker :locale="{ id: 'ru', firstDayOfWeek: 2, masks: { L: 'DD.MM.YYYY' } }"
                  class="date-picker"
                  :class="{ left: left }"
                  @input="handleInput"
                  @dayclick="handleInput"
                  v-model="day"/>
      </div>
    </div>
  </div>
</template>

<script>

import RightSvg from '@/assets/svg-vue/general/arrow_right.svg'
import LeftSvg from '@/assets/svg-vue/general/arrow_left.svg'
import { DatePicker } from 'v-calendar'
import moment from "moment/moment"

export default {
  name: "CRMDateNavigator",
  components: {
    RightSvg,
    LeftSvg,
    DatePicker
  },
  props: {
      left: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      show: false,
      justOpened: false,
      day: new Date()
    }
  },
  computed: {
    title() {
      return moment(this.day).format('DD MMMM YYYY')
    }
  },
  methods: {
    next() {
      this.day = moment(this.day).add(1, 'days').toDate()
    },
    prev() {
      this.day = moment(this.day).add(-1, 'days').toDate()
    },
    dateClicked() {
      this.show = !this.show
      this.justOpened = true
      setTimeout(() => {
        this.justOpened = false
      },20)
    },
    handleInput(e) {
      this.show = false
      this.day = e.date
    },
    clickedOutside() {
      if(!this.justOpened) {
        this.show = false
      }
    }
  },
  watch: {
    day: {
      handler(val) {
        this.$emit('daySelected', moment(val).format('YYYY-MM-DD'))
      },
      immediate: false
    }
  }
}
</script>
