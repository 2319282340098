import Vue from "vue"

Vue.prototype.t = (object, parameter) => {
    let p = parameter + window.lang
    if (!object) {
        return ""
    }
    if (object[p]) {
        return object[p]
    }
    if (object[parameter]) {
        return object[parameter]
    }
    return ""
}

Vue.prototype.copyObject = (object) => {
    return copyObject(object)
}

export function copyObject(object) {
    return object ? JSON.parse(JSON.stringify(object)) : null
}

Vue.prototype.appendFormdata = (FormData, data, name) => {
    return appendFormdata(FormData, data, name)
}

export function appendFormdata(FormData, data, name) {
    name = name || '';
    if (typeof data === 'object' && !['image', 'original','file', 'photo', 'files', 'filename'].includes(name)) {
        for (var index in data) {
            if (Object.prototype.hasOwnProperty.call(data, index)) {
                if (name == '') {
                    appendFormdata(FormData, data[index], index)
                } else {
                    appendFormdata(FormData, data[index], name + '[' + index + ']')
                }
            }
        }
    } else {
        if(name === 'files') {
            if(data && data.length) {
                for (let x = 0; x < data.length; x++) {
                    FormData.append("files[]", data[x])
                }
            }
        } else {
            if(Array.isArray(data)) {
                for (let y = 0; y < data.length; y++) {
                    FormData.append(name+"[]", data[y])
                }
            } else {
                FormData.append(name, data)
            }
        }
    }
    return FormData
}
