<template>
  <b-modal  id="fill-demo-account-modal"
            :title="$t('demo')"
            v-if="canFillDemoData"
            centered>
    {{ $t('load_demo_data_to_account') }}
    <div v-if="loading" class="alert alert-warning mt-3">{{ $t('this_may_take_several_minutes_do_not_close_the_window') }}</div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <div class="row row-narrow">
          <div class="col-sm-auto col-12">
            <b-button class="cancel px-3 show-anymore"
                      variant="themed"
                      :disabled="loading"
                      @click="doNotShowAnymore">
              {{ $t('do_not_show_anymore') }}
            </b-button>
          </div>
          <div class="col-sm-auto col-6 mt-2 mt-sm-0">
            <b-button class="cancel"
                      :disabled="loading"
                      variant="themed"
                      @click="close()">
              {{ $t('no') }}
            </b-button>
          </div>
          <div class="col-sm-auto col-6 mt-2 mt-sm-0">
            <b-button class=""
                      :disabled="loading"
                      variant="themed"
                      @click="loadData">
              {{ $t('yes') }}
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref } from "vue"
import { useStore } from "vuex"
import { ClinicService } from "@/services/api.service"

const store = useStore()
let bvModal = null
let i18n = null
let noty = null

const loading = ref(false)
const clinic = computed(() => store.state.auth.clinic)
const user = computed(() => store.state.auth.user)
const tariffId = computed(() => clinic.value?.clinic_tariff?.tariff_id || null)
const canFillDemoData = computed(() => tariffId.value === 14 && user.value.role_id === 2 && clinic.value.id === 7528 && 1 === 2)
// window.localStorage.removeItem('demo-account-data-already-used')
const alreadyUsed = window.localStorage.getItem('demo-account-data-already-used')

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  noty = getCurrentInstance()?.appContext?.app?.noty
})

onMounted(() => {
  if(canFillDemoData.value && alreadyUsed !== '1') {
    bvModal = (getCurrentInstance())?.ctx?._bv__modal
    bvModal?.show('fill-demo-account-modal')
  }
})

const doNotShowAnymore = () => {
  window.localStorage.setItem('demo-account-data-already-used', "1")
  bvModal?.hide('fill-demo-account-modal')
}

const loadData = async () => {
  try {
    loading.value = true
    const res = await ClinicService.populateDemoData()
    if(res.data) {
      window.localStorage.setItem('demo-account-data-already-used', "1")
      bvModal?.hide('fill-demo-account-modal')
      noty.info(i18n.t('success_message'))
      location.reload()
    }
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }

}
</script>

<style lang="scss" scoped>
.modal-footer-buttons {
  .show-anymore {
    width: auto !important;
  }
}
@media screen and (max-width: 768px) {
  .modal-footer-buttons {
    width: 100%;
    .btn  {
      width: 100% !important;
    }
  }
}
</style>
