<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
        <path d="M20.7668 84.1341L18.6831 85.4039H15.1994L13.0994 84.1341L14.711 82.5876L12.6273 81.0411L14.5157 79.4946L12.2204 77.8016L14.0436 76.2551L11.7483 74.8551L13.7669 73.4388L11.7483 71.8272L13.4413 70.2806L11.2111 68.8644L12.9692 67.3993L10.6738 65.8527L12.8227 64.3062L10.6738 62.6946L12.2204 61.3435L10.1366 59.7969L11.9599 58.3156L9.87617 56.769L11.6831 55.0272L10.6738 53.3504V39.09L12.5622 31.7644H18.6831" fill="#8889BE"/>
        <path d="M13.3596 84.1341L15.4433 85.4039H18.9107L21.027 84.1341L19.3991 82.5876L21.4991 81.0411L19.6107 79.4946L21.8898 77.8016L20.0828 76.2551L22.3619 74.8551L20.3433 73.4388L22.3619 71.8272L20.6851 70.2806L22.8991 68.8644L21.1572 67.3993L23.4363 65.8527L21.2875 64.3062L23.4363 62.6946L21.8898 61.3435L23.9735 59.7969L22.1665 58.3156L24.2502 56.769L22.427 55.0272L23.4363 53.3504V39.09L21.5642 31.7644H17.6572" fill="#8889BE"/>
        <path d="M11.2456 1.34185L1.69587 13.034C1.69587 13.034 -2.50489 28.7589 4.93046 38.2386C12.3658 47.7183 20.0392 44.4837 20.0392 44.4837C20.0392 44.4837 26.2004 43.5175 29.589 34.3179C32.9776 25.1182 28.5108 11.1856 28.5108 11.1856L22.5037 5.94869L14.7603 1.34185C14.7603 1.34185 12.2118 0.10962 11.2456 1.34185Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--        <path d="M27.1306 40.76L22.6218 79.001L18.155 94.2217C18.155 94.2217 14.9064 101.223 13.5201 96.3221C12.1339 91.4212 10.4116 79.001 10.4116 79.001L8.87128 65.6425C8.87128 65.6425 8.87128 43.2384 4.85254 37.3574" fill="#DEE1E9"/>-->
<!--        <path d="M12.106 1.8189L2.55622 13.511C2.55622 13.511 -1.64454 29.2359 5.79082 38.7156C13.2262 48.1954 20.8996 44.9608 20.8996 44.9608C20.8996 44.9608 27.0607 43.9946 30.4493 34.7949C33.8379 25.5952 29.3711 11.6627 29.3711 11.6627L23.364 6.42574L15.6206 1.8189C15.6206 1.8189 13.0721 0.586671 12.106 1.8189Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>-->
<!--        <path opacity="0.2" d="M21.9636 34.7669C23.8259 36.1252 21.4035 40.76 19.8212 50.9959C19.2929 54.5981 18.9471 58.2247 18.785 61.8619C18.463 67.7709 18.785 68.6951 18.435 76.3125C18.2937 79.5981 17.9477 82.8718 17.3988 86.1143C16.8947 89.0408 15.3544 93.8891 15.0743 93.9031C14.5142 93.9031 15.2984 86.2263 15.0743 82.7677C14.9514 80.9821 14.9514 79.1903 15.0743 77.4047C15.2625 73.2027 15.1736 68.9928 14.8083 64.8024C14.3322 57.241 14.0802 53.4743 12.7219 49.6516C11.3637 45.8289 10.9856 42.0903 9.72537 38.4496C9.47333 37.7495 8.87122 36.0551 9.59935 35.327C10.3275 34.5989 11.6437 35.257 13.184 35.5651C18.1409 36.5312 20.3813 33.6187 21.9636 34.7669Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M26.2905 40.1931L21.7817 78.4341L17.3149 93.6549C17.3149 93.6549 14.0663 100.656 12.68 95.7553C11.2938 90.8544 9.57147 78.4341 9.57147 78.4341L8.03119 65.0757C8.03119 65.0757 8.03119 42.6716 4.01245 36.7905" fill="#DEE1E9"/>
        <mask id="mask433" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask433)">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M13.5633 16.7991C12.5012 16.7991 11.2556 11.5008 11.2556 1.90252L13.1787 0.0404528L16.2556 -1.20093C16.2556 8.3974 14.6254 16.7991 13.5633 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.5509 15.1615C23.4986 16.9676 20.9651 23.1309 19.3102 36.7423C18.7576 41.5324 18.3959 46.355 18.2264 51.1915C17.8896 59.0492 18.2264 60.2782 17.8603 70.4076C17.7126 74.7767 17.3507 79.1299 16.7766 83.4417C16.2494 87.3333 14.6384 93.7805 14.3455 93.7991C13.7597 93.7991 14.5798 83.5907 14.3455 78.9915C14.2169 76.6171 14.2169 74.2343 14.3455 71.8599C14.5422 66.2722 14.4493 60.674 14.0672 55.1018C13.5693 45.0469 13.3057 40.038 11.8851 34.9547C10.4646 29.8714 10.0692 24.8998 8.7511 20.0586C8.48749 19.1276 7.85775 16.8745 8.6193 15.9063C9.38084 14.938 10.7575 15.8132 12.3684 16.2228C17.5528 17.5076 19.896 13.6346 21.5509 15.1615Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
        <path d="M27.1306 40.76L22.6218 79.001L18.155 94.2217C18.155 94.2217 14.9064 101.223 13.5201 96.3221C12.1339 91.4212 10.4116 79.001 10.4116 79.001L8.87128 65.6425C8.87128 65.6425 8.87128 43.2384 4.85254 37.3574" fill="#DEE1E9"/>
        <path d="M12.106 1.8189L2.55622 13.511C2.55622 13.511 -1.64454 29.2359 5.79082 38.7156C13.2262 48.1954 20.8996 44.9608 20.8996 44.9608C20.8996 44.9608 27.0607 43.9946 30.4493 34.7949C33.8379 25.5952 29.3711 11.6627 29.3711 11.6627L23.364 6.42574L15.6206 1.8189C15.6206 1.8189 13.0721 0.586671 12.106 1.8189Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M21.9636 34.7669C23.8259 36.1252 21.4035 40.76 19.8212 50.9959C19.2929 54.5981 18.9471 58.2247 18.785 61.8619C18.463 67.7709 18.785 68.6951 18.435 76.3125C18.2937 79.5981 17.9477 82.8718 17.3988 86.1143C16.8947 89.0408 15.3544 93.8891 15.0743 93.9031C14.5142 93.9031 15.2984 86.2263 15.0743 82.7677C14.9514 80.9821 14.9514 79.1903 15.0743 77.4047C15.2625 73.2027 15.1736 68.9928 14.8083 64.8024C14.3322 57.241 14.0802 53.4743 12.7219 49.6516C11.3637 45.8289 10.9856 42.0903 9.72537 38.4496C9.47333 37.7495 8.87122 36.0551 9.59935 35.327C10.3275 34.5989 11.6437 35.257 13.184 35.5651C18.1409 36.5312 20.3813 33.6187 21.9636 34.7669Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="15" cy="97" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
        <path d="M4.13718 36.0634C8.09991 42.0565 8.11391 64.3066 8.11391 64.3066L9.65419 77.665C9.65419 77.665 11.4185 90.0993 12.8188 95.0282C14.219 99.9571 17.4536 92.9278 17.4536 92.9278L21.8644 77.707L26.4153 39.48C27.8406 37.6891 28.9574 35.6731 29.7199 33.5149C33.1225 24.2592 28.6417 10.3827 28.6417 10.3827L22.6206 5.15975L14.9192 0.552911C14.9192 0.552911 12.3427 -0.637306 11.3625 0.552911L1.81276 12.2451C1.81276 12.2451 -1.96793 26.5837 4.13718 36.0634Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M4.13718 36.0634C8.09991 42.0565 8.11391 64.3066 8.11391 64.3066L9.65419 77.665C9.65419 77.665 11.4185 90.0993 12.8188 95.0282C14.219 99.9571 17.4536 92.9278 17.4536 92.9278L21.8644 77.707L26.4153 39.48C27.8406 37.6891 28.9574 35.6731 29.7199 33.5149C33.1225 24.2592 28.6417 10.3827 28.6417 10.3827L22.6206 5.15975L14.9192 0.552911C14.9192 0.552911 12.3427 -0.637306 11.3625 0.552911L1.81276 12.2451C1.81276 12.2451 -1.96793 26.5837 4.13718 36.0634Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
        <path d="M16.8491 45.6385L21.3579 5.50339L15.823 3.10931L12.823 5.50339L7.823 4.10931L4.323 4.60931L0.322998 3.10934C2.06616 5.61789 3.09857 32.28 3.09857 32.28L4.63885 45.6385C4.63885 45.6385 6.36117 58.0587 7.74742 62.9596C9.13368 67.8605 12.3823 60.8592 12.3823 60.8592L16.8491 45.6385Z" fill="#DEE1E9"/>
        <path d="M19.9288 11.5273C12.2014 14.1567 8.36969 13.2022 2.24728 8" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M13.0123 28.4994C13.1744 24.8622 13.5202 21.2356 14.0485 17.6334C15.3309 9.33787 17.165 4.72114 16.823 2.54591L13.8028 0.99997L13.323 1.40439L11.0715 2.90439L8.57147 1.40439L6.07147 2.90439L3.82665 1.96452C3.09851 2.69265 3.70062 4.38697 3.95267 5.08709C5.2129 8.72776 5.59097 12.4664 6.94922 16.2891C8.30746 20.1118 8.55951 23.8785 9.0356 31.4399C9.40087 35.6303 9.48975 39.8402 9.30164 44.0422C9.17871 45.8278 9.17871 47.6196 9.30164 49.4052C9.52569 52.8638 8.74154 60.5406 9.30164 60.5406C9.5817 60.5266 11.122 55.6783 11.6261 52.7518C12.175 49.5093 12.521 46.2356 12.6623 42.95C13.0123 35.3326 12.6903 34.4084 13.0123 28.4994Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M26.6614 40.4887L22.1526 78.7297L17.6857 93.9505C17.6857 93.9505 14.4371 100.952 13.0509 96.0509C11.6646 91.15 9.94232 78.7297 9.94232 78.7297L8.40204 65.3713C8.40204 65.3713 8.40204 42.9672 4.3833 37.0861" fill="#DEE1E9"/>
        <path d="M5.32158 38.4444C-2.11378 28.9647 2.08699 13.2398 2.08699 13.2398L5.32158 17.0947H9.22867L8.32158 14.4495L9.52915 11.9826L8.32158 9.66917L12.1307 8.15443L10.7919 5.11945L9.52915 4.12805L11.6367 1.54766C12.6029 0.315431 15.1514 1.54766 15.1514 1.54766L22.8948 6.1545L28.9019 11.3915C28.9019 11.3915 33.3687 25.324 29.9801 34.5237C26.5915 43.7234 20.4303 44.6895 20.4303 44.6895C20.4303 44.6895 12.7569 47.9241 5.32158 38.4444Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M4.95069 25.1393C15.0497 21.1441 17.3627 16.6513 16.207 5.09474" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M21.4944 34.4957C23.3567 35.8539 20.9343 40.4888 19.352 50.7246C18.8237 54.3269 18.4778 57.9535 18.3158 61.5906C17.9937 67.4997 18.3158 68.4239 17.9657 76.0413C17.8245 79.3269 17.4784 82.6005 16.9295 85.8431C16.4254 88.7696 14.8852 93.6179 14.6051 93.6319C14.045 93.6319 14.8292 85.9551 14.6051 82.4964C14.4822 80.7109 14.4822 78.919 14.6051 77.1335C14.7932 72.9314 14.7043 68.7215 14.3391 64.5312C13.863 56.9698 13.6109 53.2031 12.2527 49.3804C10.8944 45.5577 10.5164 41.819 9.25614 38.1783C9.00409 37.4782 8.40198 35.7839 9.13011 35.0558C9.85825 34.3276 11.1745 34.9858 12.7148 35.2938C17.6717 36.26 19.9121 33.3475 21.4944 34.4957Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M30.2506 23.2494L21.7767 78.8844L17.3099 94.1052C17.3099 94.1052 14.0613 101.106 12.675 96.2056C11.2888 91.3047 9.56647 78.8844 9.56647 78.8844L8.02619 65.5259C8.02619 65.5259 4.76934 28.6305 0.75061 22.7494" fill="#DEE1E9"/>
        <path d="M1.71113 13.3945L11.2609 1.70232C12.2271 0.470094 14.7755 1.70232 14.7755 1.70232L22.5189 6.30916L28.526 11.5461C28.526 11.5461 32.9928 25.4787 29.6042 34.6783C29.1891 35.8052 28.7325 36.8086 28.2506 37.7018H23.2506L21.2506 27.2494C17.5405 25.6957 15.4604 25.6466 11.7506 27.2494L8.75061 37.7018H4.28844C-2.23368 28.1612 1.71113 13.3945 1.71113 13.3945Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M30.6265 23L22.1526 78.635L17.6857 93.8558C17.6857 93.8558 14.4371 100.857 13.0509 95.9562C11.6646 91.0553 9.94232 78.635 9.94232 78.635L8.40204 65.2766C8.40204 65.2766 5.1452 28.3811 1.12646 22.5" fill="#DEE1E9"/>
        <path d="M2.08699 13.1451L11.6367 1.45293C12.6029 0.220704 15.1514 1.45293 15.1514 1.45293L22.8948 6.05977L28.9019 11.2967C28.9019 11.2967 33.3687 25.2293 29.9801 34.429C29.565 35.5558 29.1083 36.5592 28.6265 37.4524H23.6265L21.6265 27C17.9163 25.4463 15.8363 25.3972 12.1265 27L9.12646 37.4524H4.6643C-1.85783 27.9118 2.08699 13.1451 2.08699 13.1451Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M30.2556 23L21.7817 78.635L17.3149 93.8558C17.3149 93.8558 14.0663 100.857 12.68 95.9562C11.2938 91.0553 9.57147 78.635 9.57147 78.635L8.03119 65.2766C8.03119 65.2766 4.77435 28.3811 0.755615 22.5" fill="#DEE1E9"/>
        <mask id="mask2033" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="39">
          <path d="M2.09285 13.2398L11.6426 1.54766C12.6088 0.315431 15.1572 1.54766 15.1572 1.54766L22.9006 6.1545L28.9077 11.3915C28.9077 11.3915 33.3746 25.324 29.9859 34.5237C29.5709 35.6506 29.1142 36.6539 28.6323 37.5471H23.6323L21.6323 27.0947C17.9222 25.541 15.8421 25.4919 12.1323 27.0947L9.13232 37.5471H4.67016C-1.85197 28.0065 2.09285 13.2398 2.09285 13.2398Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2033)">
          <path d="M2.09285 13.2398L11.6426 1.54766C12.6088 0.315431 15.1572 1.54766 15.1572 1.54766L22.9006 6.1545L28.9077 11.3915C28.9077 11.3915 33.3746 25.324 29.9859 34.5237C29.5709 35.6506 29.1142 36.6539 28.6323 37.5471H23.6323L21.6323 27.0947C17.9222 25.541 15.8421 25.4919 12.1323 27.0947L9.13232 37.5471H4.67016C-1.85197 28.0065 2.09285 13.2398 2.09285 13.2398Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M6.05237 49.5H27.0524V23.952C18.8514 22.0258 14.2534 22.0062 6.05237 23.952V49.5Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M27.2311 40.1355L22.7223 78.3765L18.2554 93.5973C18.2554 93.5973 15.0068 100.599 13.6206 95.6977C12.2343 90.7968 10.512 78.3765 10.512 78.3765L8.97174 65.0181C8.97174 65.0181 8.97174 42.614 4.95301 36.7329" fill="#DEE1E9"/>
        <path d="M12.2064 1.19463L2.65669 12.8868C2.65669 12.8868 -1.54408 28.6116 5.89128 38.0914C13.3266 47.5711 21 44.3365 21 44.3365C21 44.3365 27.1612 43.3703 30.5498 34.1707C33.9384 24.971 29.4716 11.0384 29.4716 11.0384L23.4645 5.80147L15.7211 1.19463C15.7211 1.19463 13.1726 -0.0375968 12.2064 1.19463Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M22.0641 34.1426C23.9264 35.5009 21.504 40.1357 19.9217 50.3716C19.3934 53.9738 19.0475 57.6004 18.8855 61.2376C18.5634 67.1467 18.8855 68.0708 18.5354 75.6882C18.3942 78.9739 18.0481 82.2475 17.4992 85.49C16.9951 88.4166 15.4549 93.2649 15.1748 93.2789C14.6147 93.2789 15.3989 85.6021 15.1748 82.1434C15.0519 80.3579 15.0519 78.566 15.1748 76.7804C15.3629 72.5784 15.274 68.3685 14.9088 64.1781C14.4327 56.6168 14.1806 52.8501 12.8224 49.0274C11.4641 45.2047 11.0861 41.466 9.82584 37.8253C9.57379 37.1252 8.97168 35.4309 9.69981 34.7027C10.4279 33.9746 11.7442 34.6327 13.2845 34.9408C18.2414 35.907 20.4818 32.9944 22.0641 34.1426Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M8.98364 71.1315C14.5714 74.5379 17.6917 74.3827 23.2463 71.1315" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M26.2905 40.3941L21.7817 78.6351L17.3149 93.8558C17.3149 93.8558 14.0663 100.857 12.68 95.9562C11.2938 91.0553 9.57147 78.6351 9.57147 78.6351L8.03119 65.2766C8.03119 65.2766 8.03119 42.8725 4.01245 36.9915" fill="#DEE1E9"/>
        <mask id="mask2233" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M11.6426 1.54766L2.09285 13.2398C2.09285 13.2398 -2.10792 28.9647 5.32744 38.4444C12.7628 47.9241 20.4362 44.6895 20.4362 44.6895C20.4362 44.6895 26.5973 43.7234 29.9859 34.5237C33.3746 25.324 28.9077 11.3915 28.9077 11.3915L22.9006 6.1545L15.1572 1.54766C15.1572 1.54766 12.6088 0.315431 11.6426 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2233)">
          <path d="M11.6426 1.54766L2.09285 13.2398C2.09285 13.2398 -2.10792 28.9647 5.32744 38.4444C12.7628 47.9241 20.4362 44.6895 20.4362 44.6895C20.4362 44.6895 26.5973 43.7234 29.9859 34.5237C33.3746 25.324 28.9077 11.3915 28.9077 11.3915L22.9006 6.1545L15.1572 1.54766C15.1572 1.54766 12.6088 0.315431 11.6426 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M13.5633 17C12.5012 17 11.2556 11.7018 11.2556 2.10345L13.1787 0.241381L16.2556 -1C16.2556 8.59833 14.6254 17 13.5633 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.5509 15.3624C23.4986 17.1686 20.9651 23.3318 19.3102 36.9432C18.7576 41.7333 18.3959 46.5559 18.2264 51.3925C17.8896 59.2502 18.2264 60.4791 17.8603 70.6085C17.7126 74.9776 17.3507 79.3308 16.7766 83.6426C16.2494 87.5342 14.6384 93.9814 14.3455 94C13.7597 94 14.5798 83.7916 14.3455 79.1924C14.2169 76.818 14.2169 74.4352 14.3455 72.0609C14.5422 66.4731 14.4493 60.8749 14.0672 55.3027C13.5693 45.2478 13.3057 40.239 11.8851 35.1557C10.4646 30.0724 10.0692 25.1008 8.7511 20.2595C8.48749 19.3285 7.85775 17.0755 8.6193 16.1072C9.38084 15.139 10.7575 16.0141 12.3684 16.4238C17.5528 17.7085 19.896 13.8355 21.5509 15.3624Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 15.2556 95.2009)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M26.2905 40.3941L21.7817 78.6351L17.3149 93.8558C17.3149 93.8558 14.0663 100.857 12.68 95.9562C11.2938 91.0553 9.57147 78.6351 9.57147 78.6351L8.03119 65.2766C8.03119 65.2766 8.03119 42.8725 4.01245 36.9915" fill="#DEE1E9"/>
        <mask id="mask2333" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M11.6426 1.54766L2.09285 13.2398C2.09285 13.2398 -2.10792 28.9647 5.32744 38.4444C12.7628 47.9241 20.4362 44.6895 20.4362 44.6895C20.4362 44.6895 26.5973 43.7234 29.9859 34.5237C33.3746 25.324 28.9077 11.3915 28.9077 11.3915L22.9006 6.1545L15.1572 1.54766C15.1572 1.54766 12.6088 0.315431 11.6426 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2333)">
          <path d="M11.6426 1.54766L2.09285 13.2398C2.09285 13.2398 -2.10792 28.9647 5.32744 38.4444C12.7628 47.9241 20.4362 44.6895 20.4362 44.6895C20.4362 44.6895 26.5973 43.7234 29.9859 34.5237C33.3746 25.324 28.9077 11.3915 28.9077 11.3915L22.9006 6.1545L15.1572 1.54766C15.1572 1.54766 12.6088 0.315431 11.6426 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M13.5633 17C12.5012 17 11.2556 11.7018 11.2556 2.10345L13.1787 0.241381L16.2556 -1C16.2556 8.59833 14.6254 17 13.5633 17Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.5509 15.3624C23.4986 17.1686 20.9651 23.3318 19.3102 36.9432C18.7576 41.7333 18.3959 46.5559 18.2264 51.3925C17.8896 59.2502 18.2264 60.4791 17.8603 70.6085C17.7126 74.9776 17.3507 79.3308 16.7766 83.6426C16.2494 87.5342 14.6384 93.9814 14.3455 94C13.7597 94 14.5798 83.7916 14.3455 79.1924C14.2169 76.818 14.2169 74.4352 14.3455 72.0609C14.5422 66.4731 14.4493 60.8749 14.0672 55.3027C13.5693 45.2478 13.3057 40.239 11.8851 35.1557C10.4646 30.0724 10.0692 25.1008 8.7511 20.2595C8.48749 19.3285 7.85775 17.0755 8.6193 16.1072C9.38084 15.139 10.7575 16.0141 12.3684 16.4238C17.5528 17.7085 19.896 13.8355 21.5509 15.3624Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 15.2556 95.2009)" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2333)">
          <path d="M15.2556 91L14.4327 94.7391L11.3989 92.4037L13.172 95.797L9.34677 95.9581L12.8862 97.4178L10.0595 100L13.7091 98.8431L13.2035 102.638L15.2556 99.406L17.3077 102.638L16.8022 98.8431L20.4518 100L17.6251 97.4178L21.1645 95.9581L17.3393 95.797L19.1123 92.4037L16.0785 94.7391L15.2556 91Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2333" x="9.1468" y="90.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M26.2905 40.3941L21.7817 78.6351L17.3149 93.8558C17.3149 93.8558 14.0663 100.857 12.68 95.9562C11.2938 91.0553 9.57147 78.6351 9.57147 78.6351L8.03119 65.2766C8.03119 65.2766 8.03119 42.8725 4.01245 36.9915" fill="#DEE1E9"/>
      <mask id="mask2333" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
        <path d="M11.6426 1.54766L2.09285 13.2398C2.09285 13.2398 -2.10792 28.9647 5.32744 38.4444C12.7628 47.9241 20.4362 44.6895 20.4362 44.6895C20.4362 44.6895 26.5973 43.7234 29.9859 34.5237C33.3746 25.324 28.9077 11.3915 28.9077 11.3915L22.9006 6.1545L15.1572 1.54766C15.1572 1.54766 12.6088 0.315431 11.6426 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
      </mask>
      <g mask="url(#mask2333)">
        <path d="M11.6426 1.54766L2.09285 13.2398C2.09285 13.2398 -2.10792 28.9647 5.32744 38.4444C12.7628 47.9241 20.4362 44.6895 20.4362 44.6895C20.4362 44.6895 26.5973 43.7234 29.9859 34.5237C33.3746 25.324 28.9077 11.3915 28.9077 11.3915L22.9006 6.1545L15.1572 1.54766C15.1572 1.54766 12.6088 0.315431 11.6426 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M13.5633 17C12.5012 17 11.2556 11.7018 11.2556 2.10345L13.1787 0.241381L16.2556 -1C16.2556 8.59833 14.6254 17 13.5633 17Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M21.5509 15.3624C23.4986 17.1686 20.9651 23.3318 19.3102 36.9432C18.7576 41.7333 18.3959 46.5559 18.2264 51.3925C17.8896 59.2502 18.2264 60.4791 17.8603 70.6085C17.7126 74.9776 17.3507 79.3308 16.7766 83.6426C16.2494 87.5342 14.6384 93.9814 14.3455 94C13.7597 94 14.5798 83.7916 14.3455 79.1924C14.2169 76.818 14.2169 74.4352 14.3455 72.0609C14.5422 66.4731 14.4493 60.8749 14.0672 55.3027C13.5693 45.2478 13.3057 40.239 11.8851 35.1557C10.4646 30.0724 10.0692 25.1008 8.7511 20.2595C8.48749 19.3285 7.85775 17.0755 8.6193 16.1072C9.38084 15.139 10.7575 16.0141 12.3684 16.4238C17.5528 17.7085 19.896 13.8355 21.5509 15.3624Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 15.2556 95.2009)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2333)">
        <path d="M15.2556 91L14.4327 94.7391L11.3989 92.4037L13.172 95.797L9.34677 95.9581L12.8862 97.4178L10.0595 100L13.7091 98.8431L13.2035 102.638L15.2556 99.406L17.3077 102.638L16.8022 98.8431L20.4518 100L17.6251 97.4178L21.1645 95.9581L17.3393 95.797L19.1123 92.4037L16.0785 94.7391L15.2556 91Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2333" x="9.1468" y="90.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M26.2905 40.1931L21.7817 78.4341L17.3149 93.6549C17.3149 93.6549 14.0663 100.656 12.68 95.7553C11.2938 90.8544 9.57147 78.4341 9.57147 78.4341L8.03119 65.0757C8.03119 65.0757 8.03119 42.6716 4.01245 36.7905" fill="#DEE1E9"/>
        <mask id="mask2533" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2533)">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M13.5633 16.7991C12.5012 16.7991 11.2556 11.5008 11.2556 1.90252L13.1787 0.0404528L16.2556 -1.20093C16.2556 8.3974 14.6254 16.7991 13.5633 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.5509 15.1615C23.4986 16.9676 20.9651 23.1309 19.3102 36.7423C18.7576 41.5324 18.3959 46.355 18.2264 51.1915C17.8896 59.0492 18.2264 60.2782 17.8603 70.4076C17.7126 74.7767 17.3507 79.1299 16.7766 83.4417C16.2494 87.3333 14.6384 93.7805 14.3455 93.7991C13.7597 93.7991 14.5798 83.5907 14.3455 78.9915C14.2169 76.6171 14.2169 74.2343 14.3455 71.8599C14.5422 66.2722 14.4493 60.674 14.0672 55.1018C13.5693 45.0469 13.3057 40.038 11.8851 34.9547C10.4646 29.8714 10.0692 24.8998 8.7511 20.0586C8.48749 19.1276 7.85775 16.8745 8.6193 15.9063C9.38084 14.938 10.7575 15.8132 12.3684 16.2228C17.5528 17.5076 19.896 13.6346 21.5509 15.1615Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 15.2556 95)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2633" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="98">
          <path d="M26.375 40.4888L21.8662 78.7298L17.3994 93.9506C17.3994 93.9506 14.1508 100.952 12.7645 96.051C11.3783 91.1501 9.65594 78.7298 9.65594 78.7298L8.11566 65.3713C8.11566 65.3713 8.11566 42.9673 4.09693 37.0862" fill="#DEE1E9"/>
          <path d="M11.3504 1.54766L1.80061 13.2398C1.80061 13.2398 -2.40016 28.9647 5.0352 38.4444C12.4706 47.9241 20.144 44.6895 20.144 44.6895C20.144 44.6895 26.3051 43.7234 29.6937 34.5237C33.0823 25.324 28.6155 11.3915 28.6155 11.3915L22.6084 6.1545L14.865 1.54766C14.865 1.54766 12.3165 0.315431 11.3504 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask2633)">
          <path d="M26.375 40.4888L21.8662 78.7298L17.3994 93.9506C17.3994 93.9506 14.1508 100.952 12.7645 96.051C11.3783 91.1501 9.65594 78.7298 9.65594 78.7298L8.11566 65.3713C8.11566 65.3713 8.11566 42.9673 4.09693 37.0862" fill="#DEE1E9"/>
          <path d="M11.3504 1.54766L1.80061 13.2398C1.80061 13.2398 -2.40016 28.9647 5.0352 38.4444C12.4706 47.9241 20.144 44.6895 20.144 44.6895C20.144 44.6895 26.3051 43.7234 29.6937 34.5237C33.0823 25.324 28.6155 11.3915 28.6155 11.3915L22.6084 6.1545L14.865 1.54766C14.865 1.54766 12.3165 0.315431 11.3504 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M21.9893 23.6762H33.9105C31.7484 29.046 31.3914 32.3063 31.6879 38.0446C31.6991 38.2609 31.4497 38.3955 31.2595 38.2689C30.2554 37.601 27.8972 35.7849 27.3256 33.1432C26.6331 29.9432 24.6299 29.1661 24.4173 29.092C24.4044 29.0875 24.3928 29.083 24.3804 29.0773L23.1975 28.5307C23.0529 28.4639 22.9943 28.3014 23.0666 28.1678L23.5955 27.1901C23.6402 27.1074 23.6362 27.0092 23.5847 26.9299L21.7383 24.0858C21.6213 23.9056 21.7618 23.6762 21.9893 23.6762Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M30.9144 37.155C29.8534 36.3071 28.4454 34.8702 28.043 33.0106C27.2998 29.5761 25.1238 28.6201 24.6909 28.4648L23.9173 28.1073L24.2499 27.4925C24.4065 27.203 24.3922 26.8596 24.2121 26.5821L22.7646 24.3524H32.8672C31.0904 29.0171 30.7144 32.2129 30.9144 37.155ZM24.7076 28.4725C24.7077 28.4726 24.7074 28.4724 24.7076 28.4725V28.4725ZM30.8321 38.8178C31.5609 39.3027 32.4567 38.7463 32.4187 38.0123C32.1259 32.3462 32.4751 29.18 34.5962 23.9121L34.9634 23H21.9893C21.1931 23 20.7013 23.8028 21.1109 24.4338L22.8333 27.0869L22.4122 27.8654C22.1592 28.333 22.3643 28.9017 22.8702 29.1355L24.0532 29.6822C24.0939 29.701 24.1297 29.7145 24.1593 29.7248C24.1959 29.7376 25.9767 30.3577 26.6081 33.2758C27.2376 36.1849 29.801 38.1319 30.8321 38.8178Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask133" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="98">
          <path d="M26.375 40.4888L21.8662 78.7298L17.3994 93.9506C17.3994 93.9506 14.1508 100.952 12.7645 96.051C11.3783 91.1501 9.65594 78.7298 9.65594 78.7298L8.11566 65.3713C8.11566 65.3713 8.11566 42.9673 4.09693 37.0862" fill="#DEE1E9"/>
          <path d="M11.3504 1.54766L1.80061 13.2398C1.80061 13.2398 -2.40016 28.9647 5.0352 38.4444C12.4706 47.9241 20.144 44.6895 20.144 44.6895C20.144 44.6895 26.3051 43.7234 29.6937 34.5237C33.0823 25.324 28.6155 11.3915 28.6155 11.3915L22.6084 6.1545L14.865 1.54766C14.865 1.54766 12.3165 0.315431 11.3504 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask133)">
          <path d="M26.375 40.4888L21.8662 78.7298L17.3994 93.9506C17.3994 93.9506 14.1508 100.952 12.7645 96.051C11.3783 91.1501 9.65594 78.7298 9.65594 78.7298L8.11566 65.3713C8.11566 65.3713 8.11566 42.9673 4.09693 37.0862" fill="#DEE1E9"/>
          <path d="M11.3504 1.54766L1.80061 13.2398C1.80061 13.2398 -2.40016 28.9647 5.0352 38.4444C12.4706 47.9241 20.144 44.6895 20.144 44.6895C20.144 44.6895 26.3051 43.7234 29.6937 34.5237C33.0823 25.324 28.6155 11.3915 28.6155 11.3915L22.6084 6.1545L14.865 1.54766C14.865 1.54766 12.3165 0.315431 11.3504 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M24.0971 30.5H32.2439C30.7663 34.4705 30.5224 36.8811 30.725 41.1241C30.7327 41.2841 30.5622 41.3836 30.4322 41.29C29.7461 40.7961 28.1345 39.4532 27.7439 37.5C27.2706 35.1339 25.9017 34.5593 25.7564 34.5045C25.7475 34.5012 25.7396 34.4978 25.7311 34.4936L24.9227 34.0894C24.824 34.04 24.7839 33.9199 24.8333 33.8211L25.1948 33.0982C25.2253 33.037 25.2226 32.9645 25.1874 32.9058L23.9256 30.8029C23.8456 30.6696 23.9416 30.5 24.0971 30.5Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M30.1964 40.4664C29.4713 39.8394 28.5091 38.777 28.2341 37.4019C27.7262 34.8625 26.2392 34.1555 25.9433 34.0407L25.4147 33.7764L25.642 33.3218C25.749 33.1077 25.7392 32.8538 25.6161 32.6486L24.6269 31H31.5309C30.3167 34.4491 30.0597 36.8121 30.1964 40.4664ZM25.9547 34.0464C25.9548 34.0465 25.9546 34.0464 25.9547 34.0464V34.0464ZM30.1401 41.6958C30.6382 42.0544 31.2503 41.643 31.2244 41.1003C31.0243 36.9106 31.263 34.5695 32.7124 30.6744L32.9634 30H24.0971C23.553 30 23.2169 30.5936 23.4968 31.0601L24.6739 33.0219L24.3861 33.5975C24.2132 33.9433 24.3533 34.3638 24.6991 34.5367L25.5075 34.9409C25.5353 34.9548 25.5598 34.9648 25.58 34.9724C25.605 34.9818 26.822 35.4403 27.2535 37.5981C27.6837 39.749 29.4355 41.1887 30.1401 41.6958Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M26.2905 40.1931L21.7817 78.4341L17.3149 93.6549C17.3149 93.6549 14.0663 100.656 12.68 95.7553C11.2938 90.8544 9.57147 78.4341 9.57147 78.4341L8.03119 65.0757C8.03119 65.0757 8.03119 42.6716 4.01245 36.7905" fill="#DEE1E9"/>
        <mask id="mask233" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask233)">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M13.5633 16.7991C12.5012 16.7991 11.2556 11.5008 11.2556 1.90252L13.1787 0.0404528L16.2556 -1.20093C16.2556 8.3974 14.6254 16.7991 13.5633 16.7991Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M21.5509 15.1615C23.4986 16.9676 20.9651 23.1309 19.3102 36.7423C18.7576 41.5324 18.3959 46.355 18.2264 51.1915C17.8896 59.0492 18.2264 60.2782 17.8603 70.4076C17.7126 74.7767 17.3507 79.1299 16.7766 83.4417C16.2494 87.3333 14.6384 93.7805 14.3455 93.7991C13.7597 93.7991 14.5798 83.5907 14.3455 78.9915C14.2169 76.6171 14.2169 74.2343 14.3455 71.8599C14.5422 66.2722 14.4493 60.674 14.0672 55.1018C13.5693 45.0469 13.3057 40.038 11.8851 34.9547C10.4646 29.8714 10.0692 24.8998 8.7511 20.0586C8.48749 19.1276 7.85775 16.8745 8.6193 15.9063C9.38084 14.938 10.7575 15.8132 12.3684 16.2228C17.5528 17.5076 19.896 13.6346 21.5509 15.1615Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M26.2905 40.1931L21.7817 78.4341L17.3149 93.6549C17.3149 93.6549 14.0663 100.656 12.68 95.7553C11.2938 90.8544 9.57147 78.4341 9.57147 78.4341L8.03119 65.0757C8.03119 65.0757 8.03119 42.6716 4.01245 36.7905" fill="#DEE1E9"/>
        <mask id="mask333" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="46">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask333)">
          <path d="M11.6426 1.34673L2.09285 13.0389C2.09285 13.0389 -2.10792 28.7637 5.32744 38.2435C12.7628 47.7232 20.4362 44.4886 20.4362 44.4886C20.4362 44.4886 26.5973 43.5224 29.9859 34.3228C33.3746 25.1231 28.9077 11.1905 28.9077 11.1905L22.9006 5.95357L15.1572 1.34673C15.1572 1.34673 12.6088 0.114503 11.6426 1.34673Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M13.5633 16.7991C12.5012 16.7991 11.2556 11.5008 11.2556 1.90252L13.1787 0.0404528L16.2556 -1.20093C16.2556 8.3974 14.6254 16.7991 13.5633 16.7991Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M27.0349 40.4888L22.5261 78.7298L18.0593 93.9506C18.0593 93.9506 14.8107 100.952 13.4244 96.051C12.0382 91.1501 10.3159 78.7298 10.3159 78.7298L8.77558 65.3713C8.77558 65.3713 8.77557 42.9673 4.75684 37.0862" fill="#DEE1E9"/>
        <path d="M12.0103 1.54766L2.46052 13.2398C2.46052 13.2398 -1.74025 28.9647 5.69511 38.4444C13.1305 47.9241 20.8039 44.6895 20.8039 44.6895C20.8039 44.6895 26.965 43.7234 30.3536 34.5237C33.7422 25.324 29.2754 11.3915 29.2754 11.3915L23.2683 6.1545L15.5249 1.54766C15.5249 1.54766 12.9764 0.315431 12.0103 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M12 4.09473L10.1333 6.59559L8.5 5.09988V8.41393L5.93782 7.84602L7.33333 9.59662L5 11.5973L7.33333 13.598L5.93782 15.3486L8.26667 14.5983L8.5 18.0947L10.7499 16.5977L11.5333 17.5994L13.2501 16.5977L15.5 18.0947L16.2 16.0989L18.0622 15.3486L17.6 12.5976L19 11.5973L16.6667 10.0968L18.0622 7.84602L16.2 7.59593L15.5 5.09988L12.4667 7.09576L12 4.09473Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M27.0349 40.4888L22.5261 78.7298L18.0593 93.9506C18.0593 93.9506 14.8107 100.952 13.4244 96.051C12.0382 91.1501 10.3159 78.7298 10.3159 78.7298L8.77558 65.3713C8.77558 65.3713 8.77557 42.9673 4.75684 37.0862" fill="#DEE1E9"/>
        <path d="M12.0103 1.54766L2.46052 13.2398C2.46052 13.2398 -1.74025 28.9647 5.69511 38.4444C13.1305 47.9241 20.8039 44.6895 20.8039 44.6895C20.8039 44.6895 26.965 43.7234 30.3536 34.5237C33.7422 25.324 29.2754 11.3915 29.2754 11.3915L23.2683 6.1545L15.5249 1.54766C15.5249 1.54766 12.9764 0.315431 12.0103 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M12 4.09473L10.4 6.23832L9 4.95629V7.7969L6.80385 7.31012L8 8.81063L6 10.5255L8 12.2404L6.80385 13.7409L8.8 13.0978L9 16.0947L10.9285 14.8116L11.6 15.6701L13.0715 14.8116L15 16.0947L15.6 14.384L17.1962 13.7409L16.8 11.3829L18 10.5255L16 9.23935L17.1962 7.31012L15.6 7.09576L15 4.95629L12.4 6.66704L12 4.09473Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M27.0349 40.4888L22.5261 78.7298L18.0593 93.9506C18.0593 93.9506 14.8107 100.952 13.4244 96.051C12.0382 91.1501 10.3159 78.7298 10.3159 78.7298L8.77558 65.3713C8.77558 65.3713 8.77557 42.9673 4.75684 37.0862" fill="#DEE1E9"/>
        <path d="M12.0103 1.54766L2.46052 13.2398C2.46052 13.2398 -1.74025 28.9647 5.69511 38.4444C13.1305 47.9241 20.8039 44.6895 20.8039 44.6895C20.8039 44.6895 26.965 43.7234 30.3536 34.5237C33.7422 25.324 29.2754 11.3915 29.2754 11.3915L23.2683 6.1545L15.5249 1.54766C15.5249 1.54766 12.9764 0.315431 12.0103 1.54766Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path d="M11.5 4.09473L10.5667 5.34516L9.75 4.5973V6.25433L8.46891 5.97037L9.16667 6.84567L8 7.84602L9.16667 8.84636L8.46891 9.72166L9.63333 9.34653L9.75 11.0947L10.875 10.3462L11.2667 10.847L12.125 10.3462L13.25 11.0947L13.6 10.0968L14.5311 9.72166L14.3 8.34619L15 7.84602L13.8333 7.09576L14.5311 5.97037L13.6 5.84533L13.25 4.5973L11.7333 5.59524L11.5 4.09473Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M19.7783 75.6565L17.6946 76.9263H14.2109L12.1109 75.6565L13.7225 74.11L11.6388 72.5635L13.5272 71.017L11.2318 69.324L13.0551 67.7775L10.7597 66.3775L12.7783 64.9612L10.7597 63.3496L12.4528 61.803L10.2225 60.3868L11.9807 58.9217L9.68532 57.3751L11.8341 55.8286L9.68532 54.217L11.2318 52.8659L9.14811 51.3193L10.9714 49.838L8.88764 48.2914L10.6946 46.5496L9.68532 44.8728V30.6124L11.5737 23.2868H17.6946" fill="#8889BE"/>
        <path d="M19.7783 75.6565L17.6946 76.9263H14.2109L12.1109 75.6565L13.7225 74.11L11.6388 72.5635L13.5272 71.017L11.2318 69.324L13.0551 67.7775L10.7597 66.3775L12.7783 64.9612L10.7597 63.3496L12.4528 61.803L10.2225 60.3868L11.9807 58.9217L9.68532 57.3751L11.8341 55.8286L9.68532 54.217L11.2318 52.8659L9.14811 51.3193L10.9714 49.838L8.88764 48.2914L10.6946 46.5496L9.68532 44.8728V30.6124L11.5737 23.2868H17.6946" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M12.371 75.6565L14.4548 76.9263H17.9222L20.0385 75.6565L18.4106 74.11L20.5106 72.5635L18.6222 71.017L20.9013 69.324L19.0943 67.7775L21.3734 66.3775L19.3548 64.9612L21.3734 63.3496L19.6966 61.803L21.9106 60.3868L20.1687 58.9217L22.4478 57.3751L20.2989 55.8286L22.4478 54.217L20.9013 52.8659L22.985 51.3193L21.178 49.838L23.2617 48.2914L21.4385 46.5496L22.4478 44.8728V30.6124L20.5757 23.2868H16.6687" fill="#8889BE"/>
        <path d="M12.371 75.6565L14.4548 76.9263H17.9222L20.0385 75.6565L18.4106 74.11L20.5106 72.5635L18.6222 71.017L20.9013 69.324L19.0943 67.7775L21.3734 66.3775L19.3548 64.9612L21.3734 63.3496L19.6966 61.803L21.9106 60.3868L20.1687 58.9217L22.4478 57.3751L20.2989 55.8286L22.4478 54.217L20.9013 52.8659L22.985 51.3193L21.178 49.838L23.2617 48.2914L21.4385 46.5496L22.4478 44.8728V30.6124L20.5757 23.2868H16.6687" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask533" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="38">
          <path d="M2.08442 12.8924L11.6342 1.20024C12.6003 -0.0319816 15.1488 1.20024 15.1488 1.20024L22.8922 5.80709L28.8993 11.044C28.8993 11.044 33.3661 24.9766 29.9775 34.1763C29.5624 35.3032 29.1058 36.3065 28.6239 37.1997H23.6239L21.6239 26.7473C17.9138 25.1936 15.8337 25.1445 12.1239 26.7473L9.1239 37.1997H4.66174C-1.86039 27.6591 2.08442 12.8924 2.08442 12.8924Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask533)">
          <path d="M2.08442 12.8924L11.6342 1.20024C12.6003 -0.0319816 15.1488 1.20024 15.1488 1.20024L22.8922 5.80709L28.8993 11.044C28.8993 11.044 33.3661 24.9766 29.9775 34.1763C29.5624 35.3032 29.1058 36.3065 28.6239 37.1997H23.6239L21.6239 26.7473C17.9138 25.1936 15.8337 25.1445 12.1239 26.7473L9.1239 37.1997H4.66174C-1.86039 27.6591 2.08442 12.8924 2.08442 12.8924Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
          <path d="M6.04395 49.1526H27.0439V23.6046C18.8429 21.6784 14.245 21.6588 6.04395 23.6046V49.1526Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M20.3704 75.948L18.2866 77.2178H14.8029L12.7029 75.948L14.3146 74.4015L12.2308 72.855L14.1192 71.3085L11.8239 69.6155L13.6471 68.069L11.3518 66.669L13.3704 65.2527L11.3518 63.6411L13.0448 62.0945L10.8146 60.6783L12.5727 59.2132L10.2774 57.6667L12.4262 56.1201L10.2774 54.5085L11.8239 53.1574L9.74015 51.6109L11.5634 50.1295L9.47968 48.5829L11.2867 46.8411L10.2774 45.1644V30.9039L12.1657 23.5784H18.2866" fill="#8889BE"/>
        <path d="M20.3704 75.948L18.2866 77.2178H14.8029L12.7029 75.948L14.3146 74.4015L12.2308 72.855L14.1192 71.3085L11.8239 69.6155L13.6471 68.069L11.3518 66.669L13.3704 65.2527L11.3518 63.6411L13.0448 62.0945L10.8146 60.6783L12.5727 59.2132L10.2774 57.6667L12.4262 56.1201L10.2774 54.5085L11.8239 53.1574L9.74015 51.6109L11.5634 50.1295L9.47968 48.5829L11.2867 46.8411L10.2774 45.1644V30.9039L12.1657 23.5784H18.2866" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M12.9631 75.948L15.0468 77.2178H18.5142L20.6305 75.948L19.0026 74.4015L21.1026 72.855L19.2142 71.3085L21.4933 69.6155L19.6863 68.069L21.9654 66.669L19.9468 65.2527L21.9654 63.6411L20.2887 62.0945L22.5026 60.6783L20.7607 59.2132L23.0398 57.6667L20.891 56.1201L23.0398 54.5085L21.4933 53.1574L23.577 51.6109L21.77 50.1295L23.8538 48.5829L22.0305 46.8411L23.0398 45.1644V30.9039L21.1677 23.5784H17.2608" fill="#8889BE"/>
        <path d="M12.9631 75.948L15.0468 77.2178H18.5142L20.6305 75.948L19.0026 74.4015L21.1026 72.855L19.2142 71.3085L21.4933 69.6155L19.6863 68.069L21.9654 66.669L19.9468 65.2527L21.9654 63.6411L20.2887 62.0945L22.5026 60.6783L20.7607 59.2132L23.0398 57.6667L20.891 56.1201L23.0398 54.5085L21.4933 53.1574L23.577 51.6109L21.77 50.1295L23.8538 48.5829L22.0305 46.8411L23.0398 45.1644V30.9039L21.1677 23.5783H17.2608" stroke="#8889BE" stroke-miterlimit="10"/>
        <mask id="mask533" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="38">
          <path d="M2.67646 13.1836L12.2262 1.4915C13.1924 0.259278 15.7409 1.4915 15.7409 1.4915L23.4843 6.09834L29.4914 11.3353C29.4914 11.3353 33.9582 25.2678 30.5696 34.4675C30.1545 35.5944 29.6978 36.5978 29.2159 37.4909H24.2159L22.2159 27.0386C18.5058 25.4849 16.4258 25.4358 12.7159 27.0386L9.71594 37.4909H5.25378C-1.26835 27.9504 2.67646 13.1836 2.67646 13.1836Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </mask>
        <g mask="url(#mask533)">
          <path d="M2.67646 13.1836L12.2262 1.4915C13.1924 0.259278 15.7409 1.4915 15.7409 1.4915L23.4843 6.09834L29.4914 11.3353C29.4914 11.3353 33.9582 25.2678 30.5696 34.4675C30.1545 35.5944 29.6978 36.5978 29.2159 37.4909H24.2159L22.2159 27.0386C18.5058 25.4849 16.4258 25.4358 12.7159 27.0386L9.71594 37.4909H5.25378C-1.26835 27.9504 2.67646 13.1836 2.67646 13.1836Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        </g>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M43.2556 0.259766H22.2556V5.25977H43.2556V0.259766Z" fill="#838AA2"/>
        <path d="M21.2556 0.259766H0.255554V5.25977H21.2556V0.259766Z" fill="#838AA2"/>
        <path d="M37.6323 25.6777L29.1584 81.3127L24.6915 96.5335C24.6915 96.5335 21.4429 103.535 20.0567 98.6339C18.6704 93.733 16.9481 81.3127 16.9481 81.3127L15.4078 67.9543C15.4078 67.9543 12.151 31.0588 8.13226 25.1777" fill="#DEE1E9"/>
        <path d="M9.09278 15.8228L18.6425 4.13066C19.6087 2.89844 22.1572 4.13066 22.1572 4.13066L29.9006 8.7375L35.9077 13.9745C35.9077 13.9745 40.3745 27.907 36.9859 37.1067C36.5708 38.2336 36.1141 39.2369 35.6323 40.1301H30.6323L28.6323 29.6777C24.9221 28.124 22.8421 28.0749 19.1323 29.6777L16.1323 40.1301H11.6701C5.14797 30.5896 9.09278 15.8228 9.09278 15.8228Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M26.8779 39.9993L22.3691 78.2403L17.9023 93.4611C17.9023 93.4611 14.6537 100.462 13.2674 95.5615C11.8812 90.6606 10.1589 78.2403 10.1589 78.2403L8.61859 64.8818C8.61859 64.8818 8.61859 42.4778 4.59986 36.5967" fill="#DEE1E9"/>
        <path d="M11.8533 1.0584L2.30354 12.7505C2.30354 12.7505 -1.89723 28.4754 5.53813 37.9551C12.9735 47.4349 20.6469 44.2003 20.6469 44.2003C20.6469 44.2003 26.808 43.2341 30.1966 34.0344C33.5853 24.8347 29.1184 10.9022 29.1184 10.9022L23.1113 5.66524L15.3679 1.0584C15.3679 1.0584 12.8195 -0.173827 11.8533 1.0584Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.2" d="M21.7109 34.0064C23.5733 35.3647 21.1508 39.9995 19.5685 50.2354C19.0402 53.8376 18.6944 57.4642 18.5323 61.1014C18.2103 67.0104 18.5323 67.9346 18.1823 75.552C18.041 78.8376 17.695 82.1113 17.1461 85.3538C16.642 88.2803 15.1017 93.1286 14.8217 93.1426C14.2616 93.1426 15.0457 85.4658 14.8217 82.0072C14.6987 80.2216 14.6987 78.4298 14.8217 76.6442C15.0098 72.4422 14.9209 68.2323 14.5556 64.0419C14.0795 56.4805 13.8275 52.7138 12.4692 48.8911C11.111 45.0684 10.7329 41.3298 9.47269 37.6891C9.22064 36.989 8.61853 35.2947 9.34667 34.5665C10.0748 33.8384 11.391 34.4965 12.9313 34.8046C17.8882 35.7707 20.1286 32.8582 21.7109 34.0064Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M26.8779 39.9993L22.3691 78.2403L17.9023 93.4611C17.9023 93.4611 14.6537 100.462 13.2674 95.5615C11.8812 90.6606 10.1589 78.2403 10.1589 78.2403L8.61859 64.8818C8.61859 64.8818 8.61859 42.4778 4.59986 36.5967" fill="#DEE1E9"/>
        <path d="M11.8533 1.0584L2.30354 12.7505C2.30354 12.7505 -1.89723 28.4754 5.53813 37.9551C12.9735 47.4349 20.6469 44.2003 20.6469 44.2003C20.6469 44.2003 26.808 43.2341 30.1966 34.0344C33.5853 24.8347 29.1184 10.9022 29.1184 10.9022L23.1113 5.66524L15.3679 1.0584C15.3679 1.0584 12.8195 -0.173827 11.8533 1.0584Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <path opacity="0.4" d="M21.7109 34.0064C23.5733 35.3647 21.1508 39.9995 19.5685 50.2354C19.0402 53.8376 18.6944 57.4642 18.5323 61.1014C18.2103 67.0104 18.5323 67.9346 18.1823 75.552C18.041 78.8376 17.695 82.1113 17.1461 85.3538C16.642 88.2803 15.1017 93.1286 14.8217 93.1426C14.2616 93.1426 15.0457 85.4658 14.8217 82.0072C14.6987 80.2216 14.6987 78.4298 14.8217 76.6442C15.0098 72.4422 14.9209 68.2323 14.5556 64.0419C14.0795 56.4805 13.8275 52.7138 12.4692 48.8911C11.111 45.0684 10.7329 41.3298 9.47269 37.6891C9.22064 36.989 8.61853 35.2947 9.34667 34.5665C10.0748 33.8384 11.391 34.4965 12.9313 34.8046C17.8882 35.7707 20.1286 32.8582 21.7109 34.0064Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M26.8782 39.9993L22.3693 78.2403L17.9025 93.4611C17.9025 93.4611 14.6539 100.462 13.2677 95.5615C11.8814 90.6606 10.1591 78.2403 10.1591 78.2403L8.61884 64.8818C8.61884 64.8818 8.61884 42.4778 4.6001 36.5967" fill="#DEE1E9"/>
        <path d="M11.8535 1.0584L2.30378 12.7505C2.30378 12.7505 -1.89698 28.4754 5.53837 37.9551C12.9737 47.4349 20.6471 44.2003 20.6471 44.2003C20.6471 44.2003 26.8083 43.2341 30.1969 34.0344C33.5855 24.8347 29.1187 10.9022 29.1187 10.9022L23.1116 5.66524L15.3682 1.0584C15.3682 1.0584 12.8197 -0.173827 11.8535 1.0584Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
        <mask id="mask533" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="31" width="16" height="36">
          <path d="M11.0814 66.3398L19.1583 66.3397L23.8742 31.7083H8.24536L11.0814 66.3398Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask533)">
          <path opacity="0.2" d="M21.7112 34.0064C23.5735 35.3647 21.1511 39.9995 19.5688 50.2354C19.0405 53.8376 18.6946 57.4642 18.5326 61.1014C18.2105 67.0104 18.5326 67.9346 18.1825 75.552C18.0413 78.8376 17.6952 82.1113 17.1463 85.3538C16.6422 88.2803 15.102 93.1286 14.8219 93.1426C14.2618 93.1426 15.0459 85.4658 14.8219 82.0072C14.699 80.2216 14.699 78.4298 14.8219 76.6442C15.01 72.4422 14.9211 68.2323 14.5559 64.0419C14.0798 56.4805 13.8277 52.7138 12.4695 48.8911C11.1112 45.0684 10.7332 41.3298 9.47293 37.6891C9.22089 36.989 8.61878 35.2947 9.34691 34.5665C10.075 33.8384 11.3913 34.4965 12.9316 34.8046C17.8885 35.7707 20.1289 32.8582 21.7112 34.0064Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5033" mask-type="alpha" maskUnits="userSpaceOnUse" x="12" y="66" width="10" height="29">
          <path d="M13.7006 92.6017L14.4004 94.6367H15.8002L16.7883 93.3368L21.3171 66.4055H12.5477L12.9183 78.3562L13.3712 88.9273L13.7006 92.6017Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5033)">
          <path opacity="0.4" d="M21.7112 34.0064C23.5735 35.3647 21.1511 39.9995 19.5688 50.2354C19.0405 53.8376 18.6946 57.4642 18.5326 61.1014C18.2105 67.0104 18.5326 67.9346 18.1825 75.552C18.0413 78.8376 17.6952 82.1113 17.1463 85.3538C16.6422 88.2803 15.102 93.1286 14.8219 93.1426C14.2618 93.1426 15.0459 85.4658 14.8219 82.0072C14.699 80.2216 14.699 78.4298 14.8219 76.6442C15.01 72.4422 14.9211 68.2323 14.5559 64.0419C14.0798 56.4805 13.8277 52.7138 12.4695 48.8911C11.1112 45.0684 10.7332 41.3298 9.47293 37.6891C9.22089 36.989 8.61878 35.2947 9.34691 34.5665C10.075 33.8384 11.3913 34.4965 12.9316 34.8046C17.8885 35.7707 20.1289 32.8582 21.7112 34.0064Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

<g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M4.13718 36.0634C8.09991 42.0565 8.11391 64.3066 8.11391 64.3066L9.65419 77.665C9.65419 77.665 11.4185 90.0993 12.8188 95.0282C14.219 99.9571 17.4536 92.9278 17.4536 92.9278L21.8644 77.707L26.4153 39.48C27.8406 37.6891 28.9574 35.6731 29.7199 33.5149C33.1225 24.2592 28.6417 10.3827 28.6417 10.3827L22.6206 5.15975L14.9192 0.552911C14.9192 0.552911 12.3427 -0.637306 11.3625 0.552911L1.81276 12.2451C1.81276 12.2451 -1.96793 26.5837 4.13718 36.0634Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
        <path d="M27.1306 40.8471L22.6218 79.0881L18.155 94.3089C18.155 94.3089 14.9064 101.31 13.5201 96.4093C12.1339 91.5084 10.4116 79.0881 10.4116 79.0881L8.87128 65.7297C8.87128 65.7297 8.87128 43.3256 4.85254 37.4445" fill="#DEE1E9"/>
        <path d="M12.106 1.90605L2.55622 13.5982C2.55622 13.5982 -1.64454 29.3231 5.79082 38.8028C13.2262 48.2825 20.8996 45.0479 20.8996 45.0479C20.8996 45.0479 27.0607 44.0818 30.4493 34.8821C33.8379 25.6824 29.3711 11.7499 29.3711 11.7499L23.364 6.5129L15.6206 1.90605C15.6206 1.90605 13.0721 0.673829 12.106 1.90605Z" fill="white" stroke="#DEE1E9" stroke-linecap="round" stroke-linejoin="bevel"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth33",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
