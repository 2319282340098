import { planTreatmentTemplateAdapter } from "@/adapters/templates/planning_treatment_template"

export default {
    primaryKey: "id",
    model: 'plan_treatment_template',
    order: 'id',
    sort: 'desc',
    modalsize: 'lg',
    adapter: planTreatmentTemplateAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_template',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    // formComponent: 'TemplateDatatableFormFullTreatmentTemplateForm',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'planned_treatment',
            title: 'planned_treatment',
            table: false,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'planned_result',
            title: 'planned_result',
            table: false,
            form: {
                type: "textarea",
            }
        },
        {
            id: 'description',
            title: 'description',
            table: false,
            form: {
                type: "textarea",
                rows: 1
            }
        },
        {
            id: 'diagnoses',
            title: 'diagnoses',
            table: false,
            form: {
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                component: "PlanTreatmentTemplateDatatableFormDiagnoses"
            }
        },
        {
            id: 'services',
            title: 'services',
            table: false,
            form: {
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                component: "PlanTreatmentTemplateDatatableFormServices"
            }
        },
        {
            id: 'complex_services',
            title: 'complex_services',
            table: false,
            form: {
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                component: "PlanTreatmentTemplateDatatableFormComplexServices"
            }
        },
        {
            id: 'date_to',
            title: 'date_to',
            form: {
                type: "date"
            }
        },
    ]

}
