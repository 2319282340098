import authorized from "@/middleware/authorized"
import paid from "@/middleware/paid"

export default {
    path: '/egisz',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['egisz_view'] },
    component: () => import('../components/pages/egisz/EGISZGeneral.vue'),
    children: [
        {
            path: 'kanban', name: "EGISZKanban",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['egisz_view'] },
            component: () => import('../components/pages/egisz/EGISZKanban.vue'),
        },
        {
            path: 'list', name: "EGISZTable",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['egisz_view'] },
            component: () => import('../components/pages/egisz/EGISZTable.vue'),
        },
    ]
}
