<template>
  <div class="">
    <ServiceMultiSelect :label="$t('diagnoses')"
                        :dblclick="false"
                        model="diagnosis_template"
                        title="title"
                        :dbsearch="true"
                        :prepopulatedOptions="selectModels.diagnoses"
                        v-model="object.diagnoses"/>
  </div>
</template>

<script>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"

export default {
  name: "FullTreatmentTemplateDatatableFormDiagnoses",
  components: {
    ServiceMultiSelect,
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  computed: {

  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
