<template>
  <section id="plan" class="plan">
    <transition name="fade" mode="out-in" >
      <div class="section-top-row">
        <div class="row row-narrow align-items-center no-print">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('plan_treatments') }}</h1>
          </div>
          <div class="col-auto table-view-navigator order-md-2 mt-md-0 mt-2 order-4">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto table-view-navigator order-md-4 order-1">
            <DataTableExcel/>
          </div>
        </div>
      </div>
    </transition>
    <DataTable :options="options"
               :can-hide-rows="true"
               resource="GeneralTreatmentPlan"
               collection="GeneralPlanTreatmentCollection"
               module="patients"/>
    <PlanTreatmentModal/>
    <div>
      <div ref="patientFormula" id="patientFormulaId">
        <PatientFormula style="display: none"
                        id="patientFormula"
                        :imageMaking="true"/>
      </div>
    </div>
  </section>
</template>

<script setup>
import DataTable from "@/components/parts/general/DataTable"
import PlanTreatmentModal from "@/components/parts/patients/PlanTreatmentModal.vue"
import options from "@/data/patients/plan_treatment2"
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, onMounted, ref } from "vue"
import { useStore } from "vuex"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton.vue"
import DataTableExcel from "@/components/parts/datatable/DataTableExcel.vue"
import PatientFormula from "@/components/pages/patients/PatientFormula.vue"

const store = useStore()
let i18n = null
const title = ref('')

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('plan_treatments')
})

onMounted(() => {
  store.commit('setEloquentOptions', options)
})
</script>
