<template>
  <b-modal id="patient-sms-modal"
           v-if="patient"
           @hide="onHide"
           :title="`${$t('sms_sending')} - ${patient.full_name}`"
           centered>
    <b-row>
      <b-col cols="6">
        <div>{{ $t('patient_number') }}:</div>
        <div>
          <a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a>
        </div>
      </b-col>
      <b-col cols="6" v-if="smsInfo && !smsInfo.sms_settings">
        <div>{{ $t('sms_available') }}:</div>
        <div>{{ smsInfo.sms_available }}</div>
      </b-col>
    </b-row>
    <div class="mt-4">
      <label class="form-label">
        <span>{{ $t('message') }} *</span>
      </label>
      <TemplateGroup v-model="message"
                     :dictation="true"
                     :label="$t('message')"
                     :saveAsTemplate="true"
                     model="sms_template"/>
    </div>
    <template #modal-footer="{ close }">
      <div class="w-100">
        <b-row class="align-items-center">
          <b-col md cols="12">
            {{ $t('symbols_amount') }}: {{ message.length }}
          </b-col>
          <b-col md="auto" cols="12" class="mt-md-0 mt-3 text-right">
            <div class="modal-footer-buttons">
              <b-button class="cancel mr-3 d-inline-block align-middle" variant="themed" @click="close()">{{ $t('cancel') }}</b-button>
              <button @click="send" v-if="smsInfo" :disabled="message.length < 1 || (messagesCount > smsInfo.sms_available && !smsInfo.sms_settings)" class="w-auto px-3 btn-themed align-middle btn-themed-squared">{{ $t('send') }} ({{ messagesCount }} {{ $t('un') }})</button>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>

import { mapState } from "vuex"
import TemplateGroup from "@/components/form/TemplateGroup"
import { SendService } from "@/services/api.service"

export default {
  name: "PatientSMSModal",
  components: {
    TemplateGroup
  },
  props: {
    specifiedPhone: {
      type: String,
      default: null
    }
  },
  methods: {
    send() {
      SendService.sendSMS({
        client_id: this.patient.id,
        phone: this.phoneNumber,
        message: this.message
      }).then(res => {
        if(res.data.success) {
          this.$noty.info(this.$t('success_message'))
          this.message = ''
          this.$bvModal.hide('patient-sms-modal')
          this.$store.dispatch('init')
        } else {
          this.$noty.error(res.data.message ? res.data.message : (res.data.error ? res.data.error : this.$t('server_error')))
        }
      })
    },
    onHide() {
      this.message = ''
    }
  },
  computed: {
    ...mapState({
      patient: state => state.patient.patient,
      clinic:  state => state.auth.clinic,
    }),
    smsInfo() {
      return this.clinic && this.clinic.sms_info ? this.clinic.sms_info : null
    },
    messagesCount() {
      return Math.ceil(this.message.length / this.perMessage)
    },
    phoneNumber() {
      return this.specifiedPhone || this.patient.mphone
    }
  },
  data() {
    return {
      message: '',
      perMessage: 70
    }
  }
}
</script>

<style scoped>

</style>
