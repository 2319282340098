import {cashOperations} from "@/dictionaries/dictionary"

export default {
    primaryKey: 'id',
    model: 'cashdesk_movement',
    modaltitle: 'cash_operation',
    order: 'id',
    sort: 'asc',
    showTotalRecords: false,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: false,
            sortable: true
        },
        {
            id: 'date_timezone',
            title: 'date',
            table: true,
            sortable: false,
            format_datetime: true
        },
        {
            id: 'operation_type',
            title: 'type',
            table: true,
            sortable: true,
            format_translate: true,
            tabletdclass: "order-mob-0",
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                required: true,
                select_type: "list",
                options: cashOperations
            }
        },
        {
            id: 'amount',
            title: 'sum',
            table: true,
            sortable: true,
            filter: true,
            format_currency: true,
            form: {
                type: "number",
                required: true
            }
        },
        {
            id: 'comment',
            title: 'comment',
            table: true,
            filter: true,
            form: {
                type: "textarea",
            }
        },
    ]
}
