<template>
  <div>
    <span class="text-primary" v-if="object.blocked == 0">Активен</span>
    <span class="text-secondary" v-if="object.blocked == 1">Заблокирован</span>
    <span class="text-danger" v-if="object.blocked == 2">Удален</span>
  </div>
</template>

<script>
export default {
  name: "SuperAdminDatatableTDUserBlocked",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
