<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   :initialOrder="false"
                   initialSort="asc"
                   :excel-columns-adjustment="excelColumnsAdjustment"
                   :rows="rows"/>
  </div>
</template>

<script setup>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch } from "vue"
import { useStore } from "vuex"
import { useHead } from "@unhead/vue"

let i18n = null
const title = ref('')
const datatable = ref(null)

useHead({
  title: () => title.value,
})

const props = defineProps({
  from: {},
  to: {},
  doctors: {},
  patients: {},
  selectedBranches: {},
  bonusOperationType: {},
  oneStaff: {}
})

const store = useStore()
const counter = computed(() => store.state.datatable.dataTableCounter)

const rows = ref([])
const columns = computed(() => {
  return [
    {
      id: 'date',
      field: 'date',
      label: i18n?.t('date'),
      sortable: true,
      format_datetime: true
    },
    {
      id: 'patient_name',
      field: 'patient_name',
      label: i18n?.t('patient'),
      tdcomponent: 'PatientsDatatableTDName',
      search_select: true,
      sortable: true
    },
    {
      id: 'amount',
      field: 'amount',
      label: i18n?.t('sum'),
      sortable: true,
      format_number: true,
      totals: true
    },
    {
      id: 'operation',
      field: 'operation',
      label: i18n?.t('operation'),
      search_select: true,
      sortable: true
    },
    {
      id: 'visit',
      field: 'visit',
      label: i18n?.t('visit'),
      sortable: true
    },
    {
      id: 'staff_name',
      field: 'staff_name',
      label: i18n?.t('employee'),
      search_select: true,
      sortable: true
    },
  ]
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('bonuses')
})

onMounted(() => {
  loadReport()
})

watch(() => counter, () => {
  loadReport()
})

const loadReport = async () => {
  const res = await ReportsService.loadReport('bonuses', {
    from: props.from,
    to: props.to,
    patients: props.patients.map(s => s.id),
    branch_id: props.selectedBranches,
    created_by: props.oneStaff?.id || null,
    bonusOperationType: props.bonusOperationType,
  })
  rows.value = res.data
}

const excel = () => {
  datatable.value.excel()
}

const excelColumnsAdjustment = (cols) => {
  cols.splice(1, 0, {
    id: 'patient_id',
    field: 'patient_id',
    label: i18n?.t('patient_id'),
  })
  cols.splice(6, 0, {
    id: 'staff_id',
    field: 'staff_id',
    label: i18n?.t('employee_id'),
  })
  return cols
}

defineExpose({
  loadReport,
  excel
})
</script>
