<template>
  <b-dropdown left menu-class="pb-0" variant="link" no-caret>
    <template #button-content>
      <a class="text-primary">
        <span>{{ $t('prices_price') }}</span>
        <ArrowSvg class="ml-2"/>
        <!--        <span class="notification-bubble" v-if="selected.length > 0">{{ selected.length }}</span>-->
      </a>
    </template>
    <div class="pt-3">
      <div v-for="p in prices"
           :key="`doctor-filter-opt-${p.id}`"
           class="px-3"
           style="width: 200px;">
        <CheckboxGroup :label="p.title"
                       :checkboxvalue="p.id"
                       :fid="`checkbox-w-${p.id}`"
                       v-model="selected"/>
      </div>
      <a class="mt-3 btn-themed w-100 d-block text-center btn-themed-squared" @click="apply">{{ $t('apply') }}</a>
    </div>
  </b-dropdown>
</template>

<script>

import { mapState } from "vuex"
import CheckboxGroup from "@/components/form/CheckboxGroup"
import ArrowSvg from '@/assets/svg-vue/general/arrow_down.svg'

export default {
  name: "ServicesHeaderPricesFilter",
  components: {
    CheckboxGroup,
    ArrowSvg
  },
  methods: {
    parseRoute() {
      if(this.$route.query.filters) {
        let js = JSON.parse(this.$route.query.filters)
        if(js.price_id) {
          let f = js.price_id
          if(f.type === 'contains') {
            this.selected = f.condition
          } else {
            if(f.type === 'equals') {
              this.selected = [f.condition]
            }
          }
        } else {
          this.selected = []
        }
      }
      this.$nextTick(() => {
        this.watcher = true
      })
    },
    apply() {
      if(!this.watcher) { return }
      let data = {}
      let arr = []
      for(const v in this.selected) {
        arr.push(this.selected[v])
      }
      data['price_id'] = {
        id:         'price_id',
        condition:  arr,
        type:       'contains',
      }
      if(arr.length === 0) { data = {} }
      this.$store.commit('setFilters', data)
      let query = arr.length > 0 ? { filters: JSON.stringify(data) } : {}
      if(query.filters && this.$route.query && this.$route.query.filters && query.filters == this.$route.query.filters) {
        return
      }
      this.$router.push({ path: this.$route.path, query: query }).catch(()=>{})
    }
  },
  computed: {
    ...mapState({
      prices: state => state.auth.prices,
    }),
  },
  data() {
    return {
      selected: [],
      watcher: false
    }
  },
  mounted() {
    this.parseRoute()
  },

  watch: {
    $route() {
      if(this.watcher) {
        this.parseRoute()
      }
    },
  }
}
</script>

<style scoped lang="scss">
.notification-bubble {
  right: 14px;
}
.btn-themed {
  border-top-left-radius:  0;
  border-top-right-radius:  0;
}
</style>
