export const contractTemplateAdapter =
    ({
       title,
       text26,
       clinic_id,
       filename
     }) => ({
      title: title ? title : null,
      text26: text26 ? text26 : null,
      clinic_id: clinic_id ? clinic_id : null,
      document_type: 'contract',
      filename: ['null', '1', undefined].includes(filename) ? null : filename
    })
