<template>
  <span v-if="role === 'superadmin'">
    <router-link :title="$t('notifications')"
                 :to="`/superadmin/clinic/${object.clinic_id}/patients/${object.id}/notifications`"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
    <IconSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('notifications') }}</span>
  </router-link>
    </span>
</template>

<script>

import IconSvg from '@/assets/svg-vue/patients/mail.svg'

export default {
  name: "SuperAdminPatientsActions",
  components: {
    IconSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    }
  },
}
</script>
