<template>
  <transition name="fade" mode="out-in" >
    <div class="staff-schedule-menu text-center"
         v-if="show"
         v-click-outside="clickedOutside">
      <DMenuItem type=""
                 class="symmetric"
                 @click.native="fixResult(true)"
                 :title="$t('worked')">
        <ActivateSvg class="svg-light"/>
      </DMenuItem>
      <DMenuItem type=""
                 class="symmetric"
                 @click.native="fixResult(false)"
                 :title="$t('not_worked')">
        <BlockSvg class="svg-light svg-size-lg"/>
      </DMenuItem>
      <DMenuItem type=""
                 v-if="rights.includes(editRight)"
                 class="symmetric"
                 @click.native="editSchedule"
                 :title="$t('edit')">
        <EditSvg class="svg-light svg-size-sm"/>
      </DMenuItem>
      <DMenuItem type=""
                 v-if="rights.includes(editRight)"
                 class="symmetric"
                 @click.native="deleteSchedule"
                 :title="$t('delete')">
        <DeleteSvg class="svg-light svg-size-sm"/>
      </DMenuItem>
    </div>
  </transition>
</template>

<script setup>
import { computed, getCurrentInstance, nextTick } from "vue"
import { CalendarService, DoctorService } from "@/services/api.service"
import { useStore } from "vuex"
import DMenuItem from "@/components/pages/calendar/calendar/DMenuItem.vue"
import ActivateSvg from '@/assets/svg-vue/form/tick.svg'
import BlockSvg from '@/assets/svg-vue/general/close2.svg'
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import DeleteSvg from '@/assets/svg-vue/general/trash_tr.svg'

const props = defineProps({
  schedule: Object,
  show: Boolean
})

const emit = defineEmits(['clickedOutside'])

const store = useStore()
const instance = (getCurrentInstance())

const staff = computed(() => store.state.calendar.staff)
const staffPerson = computed(() => staff.value.find(item => item.id === props.schedule.doctor_id))
const rights = computed(() => store.state.auth.rights)
const branchId = computed(() => store.state.auth.branch)
const editRight = computed(() => store.getters.staffScheduleEditRight)

const fixResult = async (worked = false) => {
  await CalendarService.fixStaffWorked({
    schedule_id: props.schedule.id,
    worked
  })
  await refresh()
}

const clickedOutside = () => {
  emit('clickedOutside')
}

const editSchedule = async () => {
  store.commit('setDoctorPreconfig', [props.schedule])
  store.commit('setDoctor', staffPerson.value)
  store.commit('setDoctorScheduleDay', props.schedule.day)
  await nextTick()
  const bvModal = instance?.ctx?._bv__modal
  bvModal.show('doctor-day-edit-modal')
}

const deleteSchedule = async () => {
  if(props.schedule.id) {
    await DoctorService.deleteDoctorScheduleDay(staffPerson.value.id, branchId.value, props.schedule.id)
  } else if(props.schedule.staff_work_id) {
    await DoctorService.deleteStaffWorkBlock(staffPerson.value.id, branchId.value, props.schedule.staff_work_id)
  }
  store.commit('incrementDataTableCounter')
}

const refresh = async () => {
  await store.dispatch('getStaffSchedule')
}
</script>

<style scoped lang="scss">
.staff-schedule-menu {
  position: absolute;
  padding: 10px 0;
  border-radius: 15px;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 160px;
  width: min(90%, 220px);
  background: #fff;
  z-index: 4;
  -webkit-box-shadow: 0px 1px 12px 1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 12px 1px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 12px 1px rgba(34, 60, 80, 0.2);
  &.top {
    top: 50px;
  }
  &.bottom {
    top: auto;
    bottom: 50px;
  }
}
</style>
