<template>
  <div>
    <span v-if="object.category && object.category.parent">{{ object.category.parent.title}} -> </span>
    {{ object.category ? object.category.title : '' }}
  </div>
</template>

<script>

export default {
  name: "ServicesDatatableTDCategory",
  props: {
    object: {
      type: Object
    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style scoped>

</style>
