<template>
  <span v-if="role === 'superadmin'">
    <router-link :title="$t('clinic')"
                 v-b-tooltip.hover
                 class="tdclickable data-table-action"
                 :to="{ name: 'SuperAdminClinicGeneral', params: { id: object.id } }">
      <ActivateSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('clinic') }}</span>
    </router-link>
    <a @click="changeStatus"
       :title="object.blocked ? $t('activate') : $t('block')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
    <RefreshSvg class="svg-light" v-if="object.blocked"/><BlockSvg v-if="!object.blocked"/><span class="action-button-mob-title">{{ $t('activate') }}</span>
  </a>
    <a v-if="object.clinic_tariff && object.clinic_tariff.tariff_id === 14"
       v-b-tooltip.hover
       title="Очистить данные"
       class="tdclickable data-table-action"
       @click="deleteClinicData">
      <CleanSvg class="svg-stroke-light" style="width: 19px; height: 19px;"/><span class="action-button-mob-title">Очистить данные</span>
    </a>
    </span>
</template>

<script>

import ActivateSvg from '@/assets/svg-vue/header/note.svg'
import BlockSvg from '@/assets/svg-vue/general/block.svg'
import RefreshSvg from '@/assets/svg-vue/form/tick.svg'
import CleanSvg from '@/assets/svg-vue/general/skull.svg'
import { SuperadminService } from "@/services/api.service"

export default {
  name: "SuperAdminClinicsActions",
  components: {
    ActivateSvg,
    RefreshSvg,
    BlockSvg,
    CleanSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    role() {
      return this.$store.state.auth.user.role
    }
  },
  methods: {
    async changeStatus() {
      try {
        const res = await SuperadminService.toggleBlockedClinic(this.object.id, !this.object.blocked)
        if(res.data) this.$store.commit('incrementDataTableCounter')
      } catch (e) {
        console.log(e)
      }
    },
    async deleteClinicData() {
      if(!confirm('Вы точно хотите удалить все данные этой клиники? Это действие нельзя будет отменить')) return
      const res = await SuperadminService.cleanClinicData(this.object.id)
      if(res.data) {
        this.$noty.info(this.$t('success_message'))
      } else {
        this.$noty.error(this.$t('error_message'))
      }
    }
  }
}
</script>
