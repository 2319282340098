<template>
  <div>
    <span class="text-primary" v-if="object[field.id]">{{ $t('closed') }}</span>
    <span v-else class="text-secondary">{{ $t('unclosed') }}</span>
  </div>
</template>

<script>
export default {
  name: "AccountingDatatableStatusTD",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
}
</script>

<style scoped>

</style>
