import { doctorAdapter } from "@/adapters/staff/doctor"
import options from "@/data/staff/doctor"
import { copyObject } from "@/extensions/prototypes/prototypes"

let administrator_options = copyObject(options)
administrator_options.model = 'administrator'

administrator_options.fields.forEach(item => {
    // if(['percent','rate','warehouse_id'].includes(item.id)) { delete item.form }
    if(['sort_order'].includes(item.id)) { item.table = false }
    if(['warehouse_id', 'doctors_profession_id'].includes(item.id)) { delete item.form }
    if(['full_name'].includes(item.id)) { item.tdcomponent = 'StaffDatatableTDStaffName' }
    if(item.form && item.form.type === 'separator') { delete item.form }
})
administrator_options.fields.push({
    id: 'rate_per_day',
    title: 'rate_per_day',
    table: false,
    form: {
        type: "text",
        classes: 'col-md-4',
    },
})
administrator_options.fields.push({
    id: 'rate_per_hour',
    title: 'rate_per_hour',
    table: false,
    form: {
        type: "text",
        disabledForSelf: true,
        classes: 'col-md-4',
    },
})
administrator_options.actionscomponent = 'StaffDatatableAdministratorActions'
administrator_options.adapter = doctorAdapter
export default administrator_options
