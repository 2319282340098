<template>
<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g>
    <path
        d="M11.8513 0.648286C11.7524 0.549446 11.6351 0.499862 11.4997 0.499862C11.3643 0.499862 11.2472 0.549446 11.1481 0.648286L10.1326 1.65625C9.57529 1.13014 8.93718 0.722497 8.21849 0.433504C7.49985 0.144483 6.76006 0 5.99961 0C4.56726 0 3.31731 0.433531 2.24956 1.30079C1.18178 2.16793 0.478687 3.29933 0.140137 4.69526V4.74991C0.140137 4.81761 0.164847 4.87625 0.214348 4.92567C0.26385 4.97517 0.32241 4.99988 0.390109 4.99988H1.94478C2.05941 4.99988 2.13748 4.93995 2.17918 4.82023C2.3979 4.29944 2.53587 3.99476 2.59323 3.90619C2.95777 3.31244 3.44215 2.84626 4.04635 2.50771C4.65061 2.16919 5.30163 1.99986 5.9995 1.99986C7.04648 1.99986 7.9553 2.35666 8.72621 3.07021L7.64789 4.14828C7.54919 4.24725 7.49955 4.36451 7.49955 4.49993C7.49955 4.63533 7.54916 4.75253 7.64789 4.85148C7.74698 4.95046 7.86421 4.99991 7.99963 4.99991H11.4997C11.6351 4.99991 11.7523 4.95046 11.8513 4.85148C11.9501 4.75253 11.9996 4.63536 11.9996 4.49993V0.999861C11.9996 0.864435 11.9504 0.747289 11.8513 0.648286Z"
        fill="white"/>
    <path
        d="M11.5546 7.00008H10.0546C9.94002 7.00008 9.8619 7.05993 9.82033 7.17976C9.60169 7.70061 9.46356 8.00514 9.40634 8.09372C9.04168 8.68733 8.55728 9.15368 7.95324 9.4922C7.34896 9.83083 6.69794 9.99997 6.00004 9.99997C5.49487 9.99997 5.00784 9.9063 4.53915 9.71886C4.0704 9.53119 3.65107 9.26551 3.28132 8.92174L4.35168 7.85147C4.4506 7.7526 4.5001 7.63526 4.5001 7.49992C4.5001 7.36452 4.4506 7.24718 4.35168 7.14837C4.2527 7.04931 4.13555 6.99976 4.00013 6.99976H0.500054C0.364546 6.99976 0.247345 7.04931 0.148396 7.14837C0.0494471 7.24721 0 7.36455 0 7.49995V11C0 11.1353 0.0494471 11.2526 0.148423 11.3516C0.247455 11.4504 0.364655 11.5001 0.500081 11.5001C0.635479 11.5001 0.752625 11.4504 0.851629 11.3516L1.85943 10.3437C2.41675 10.8748 3.05092 11.2838 3.76187 11.5702C4.47282 11.8568 5.2085 11.9999 5.96887 11.9999C7.39605 11.9999 8.6408 11.5663 9.70329 10.6991C10.7658 9.83201 11.4637 8.70039 11.7972 7.30459C11.8024 7.2942 11.8049 7.27594 11.8049 7.25C11.8049 7.18219 11.7801 7.1238 11.7306 7.07413C11.6811 7.0249 11.6224 7.00008 11.5546 7.00008Z"
        fill="white"/>
  </g>
</svg>
</template>
