import { clinicAccountTypes, clinicLanguages } from "@/dictionaries/dictionary"
import { clinicAdapter } from "@/adapters/superadmin/clinic"

export default {
    primaryKey: "id",
    model: 'clinic',
    order: 'id',
    sort: 'desc',
    adapter: clinicAdapter,
    actionscomponent: 'SuperAdminClinicsActions',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        edit: "clinics_update",
        delete: "clinics_delete",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
            },
            tdcomponent: 'SuperAdminDatatableTDClinicTitle',
        },
        {
            id: 'type',
            table_show: ['readable_type'],
            title: 'type',
            table: true,
            sortable: true,
            format_translate: true,
            filter: true,
            filter_list_select: true,
            form: {
                type: "select",
                select_type: "list",
                options: clinicAccountTypes,
                hideNullOption: true,
                required: true,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'clinic_tariff',
            title: 'payment',
            table: true,
            tdcomponent: 'SuperAdminDatatableTDClinicPayStatus'
            // filter: true,
        },
        {
            id: 'tariff_id',
            title: 'tariff',
            table: true,
            table_show: 'clinic_tariff.tariff.title',
            filter: true,
            filter_model_select: true
        },
        {
            id: 'tariff_payable_with_currency',
            title: 'subscription_sum',
            table: true,
            filter: false,
        },
        {
            id: 'idcity',
            title: 'country',
            table: true,
            sortable: false,
            filter: false,
            table_show: 'city.country.name',
        },
        {
            id: 'idcity',
            title: 'city',
            table: true,
            sortable: false,
            filter: true,
            filter_model_select: true,
            table_show: 'city.name',
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "full_title",
                },
                required: true,
            }
        },
        {
            id: 'date_of_registration',
            title: 'registration',
            table: true,
            sortable: true,
            filter: true,
            format_date: true
        },
        {
            id: 'last_login',
            title: 'last_login',
            table: true,
            sortable: true,
            filter: false,
            filter_date: true,
            format_datetime: true
        },
        {
            id: 'address',
            title: 'address',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'bin',
            title: 'bin',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: false,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'phone',
            title: 'phone',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'email',
            title: 'email',
            table: false,
            filter: true,
            form: {
                type: "text",
                required: true,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'currency_id',
            title: 'currency',
            table: false,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "name",
                },
                hideNullOption: true,
                required: true,
            }
        },
        {
            id: 'language',
            title: 'language',
            table: false,
            filter: true,
            form: {
                type: "select",
                select_type: "list",
                options: clinicLanguages,
                hideNullOption: true,
                required: true,
            }
        },
        {
            id: 'source',
            title: 'source',
            table: false,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "name",
                },
                hideNullOption: true,
                required: true,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'total_docs',
            title: 'max_doctor_amount',
            table: false,
            form: {
                type: "number",
                required: true,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'quote_value_readable',
            title: 'available_disk_space',
            table_show: 'quote_value_readable',
            table: false,
            form: {
                type: "text",
                required: true,
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'time_zone',
            title: 'time_zone',
            table: false,
            filter: true,
            filter_model_select: true,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title",
                },
                hideNullOption: true,
                required: true,
            }
        },
        {
            id: 'manager_id',
            title: 'manager2',
            table: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "full_name"
                },
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'comment',
            title: 'comment',
            table: false,
            filter: true,
            form: {
                type: "textarea",
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'blocked',
            title: 'blocked',
            table: true,
            sortable: true,
            filter: true,
            filter_boolean: true,
            tdcomponent: 'SuperAdminDatatableTDUserBlocked',
        },
        {
            id: 'use_proxy',
            title: 'use_proxy',
            table: false,
            filter: true,
            filter_boolean: true,
            form: {
                type: "checkbox",
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            },
            tdcomponent: 'SuperAdminDatatableTDNewVersion',
        },
        {
            id: 'egisz_prod',
            title: 'egisz_prod',
            table: false,
            filter: true,
            filter_boolean: true,
            form: {
                type: "checkbox",
                conditionFunc(store) {
                    return store.state.auth.user?.role !== 'tech_specialist'
                },
            }
        },
        {
            id: 'warehouse_connected',
            title: 'warehouse_connected',
            table: false,
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'crm_connected',
            title: 'crm_connected',
            table: false,
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'branches_connected',
            title: 'branches_connected',
            table: false,
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'jetapi_connected',
            title: 'jetapi_connected',
            table: false,
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'assistants_connected',
            title: 'assistants_connected',
            table: false,
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'has_additional_doctors',
            title: 'has_additional_doctors',
            table: false,
            filter: true,
            filter_boolean: true,
        },
        {
            id: 'paid_till',
            title: 'payment_expires_at',
            table: false,
            filter: true,
            filter_date: true
        },
    ]

}
