<template>
  <div class="d-calendar__staff-schedule-slot"
       @click="toggleShow"
       :class="{
         worked: schedule.worked === 1,
         'not-worked': schedule.worked === 0,
         show,
         weekly: view === 'WEEK'
       }"
       :style="{
        'height': height,
        'width': (100 / schedule.rowCount) + '%',
        'left': (100 / schedule.rowCount) * schedule.rowIndex + '%',
        'top': top,
      }"
  >
    <div class="text-left pt-2 px-2 w-100">
      <div class="row no-gutters">
        <div class="col">
          <div class="schedule-status">
            <div v-if="schedule.worked === 1" class="mb-1 d-flex align-items-center">
              <ActivateSvg class="mr-2 svg-hover"/>
              <span>{{ $t('worked') }}</span>
            </div>
            <div v-if="schedule.worked === 0" class="mb-1 d-flex align-items-center">
              <BlockSvg class="svg-size-lg svg-hover mr-2"/>
              <span>{{ $t('not_worked') }}</span>
            </div>
          </div>
          <div class="">
            {{ schedule.time_from.substring(0, 5) }} - {{ schedule.time_to.substring(0, 5) }}
          </div>
          <div class="staff-name" v-if="view === 'WEEK'">
            {{ staffPerson.full_name }}
          </div>
        </div>
        <div class="col-auto">
          <MenuSvg class="svg-light-hover"/>
        </div>
      </div>
    </div>
    <StaffScheduleMenuBlock :show="show"
                            :schedule="schedule"
                            @clicked-outside="clickedOutside"/>
  </div>
</template>

<script setup>
import { computed, ref } from "vue"
import { useStore } from "vuex"
import ActivateSvg from '@/assets/svg-vue/form/tick.svg'
import BlockSvg from '@/assets/svg-vue/general/close2.svg'
import MenuSvg from '@/assets/svg-vue/calendar/more_t.svg'
import StaffScheduleMenuBlock from "@/components/parts/calendar/StaffScheduleMenuBlock.vue"

const props = defineProps({
  schedule: Object,
  height: String,
  top: String,
  color: String
})

const show = ref(false)
const justShowed = ref(false)
const store = useStore()

const view = computed(() => store.getters.activeRange)
const staff = computed(() => store.state.calendar.staff)
const staffPerson = computed(() => staff.value.find(item => item.id === props.schedule.doctor_id))
const rights = computed(() => store.state.auth.rights)
const editRight = computed(() => store.getters.staffScheduleEditRight)

const toggleShow = () => {
  if(!rights.value.includes(editRight.value)) return
  show.value = !show.value
  justShowed.value = true
  setTimeout(() => {
    justShowed.value = false
  }, 100)
}

const clickedOutside = () => {
  if(justShowed.value) return
  show.value = false
}
</script>

<style scoped lang="scss">
.d-calendar__staff-schedule-slot {
  word-break: break-all;
  background: #CFEEFE;
  position: absolute;
  display: flex;
  font-size: 90%;
  color: $gray-text;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  left: 0;
  width: 100%;
  z-index: 4;
  border-radius: 8px;
  border: 1px solid $blue-color;
  transition: all 0.1s ease-in-out;
  .staff-name, .schedule-status {
    color: #000;
    font-weight: 600;
  }
  &.show {
    z-index: 5;
  }
  &:hover {
    background: rgba(7, 135, 201, 1);
    color: #fff;
    .staff-name, .schedule-status {
      color: #fff;
    }
    .svg-hover, .svg-light-hover {
      fill: white;
    }
    box-shadow: 0 0 10px 0 rgba(0,0,0,0.15);
  }
  .svg-hover {
    fill: #000;
  }
  .svg-light-hover {
    fill: $gray-text;
  }
  &.worked {
    background: rgba(94, 185, 51, 0.5);
    border: 1px solid rgba(94, 185, 51, 1);
    .schedule-status {
      color: $green-color;
      .svg-hover {
        fill: $green-color;
      }
    }
    &:hover {
      background: rgb(99, 167, 68);
      .schedule-status {
        color: white;
        .svg-hover {
          fill: white;
        }
      }
    }
  }
  &.not-worked {
    background: $gray-icon;
    border: 1px solid $gray-text;
    .schedule-status {
      color: $gray-text;
      .svg-hover {
        fill: $gray-text;
      }
    }
    &:hover {
      background: $gray-text;
      .schedule-status {
        color: white;
        .svg-hover {
          fill: white;
        }
      }
    }
  }
  &.weekly {
    &:hover {
      z-index: 6;
      left: 0 !important;
      width: 100% !important;
    }
  }
}
</style>
