<template>
  <div class="text-md-right">
    <a class="btn btn-themed-outline btn-themed font-weight-normal bg-transparent btn-sm"
       @click="addTask">
      {{ $t('create_task') }}
    </a>
  </div>
</template>

<script setup>
import { useStore } from "vuex"

const props = defineProps({
  patient: {
    type: Object
  }
})

const store = useStore()

const addTask = () => {
  store.commit('setModule', 'crm')
  store.commit('setViewModel', 'task')
  store.commit('setSelectedModel', {
    patient_id: props.patient.id,
    phone: props.patient.phone || props.patient.mphone,
    patient: props.patient,
    task_type: 'task'
  })
  store.commit('setShowModal', true)
}
</script>
