<template>
  <div>
    <a class="btn btn-themed btn-themed-squared btn-themed-outline w-100"
       @click="fillFromPrevious">
      {{ $t('fill_anamnez_from_previous_visits') }}
    </a>
    <b-modal id="fill-from-previous-modal"
             size="md"
             :title="$t('fill_anamnez_from_previous_visits')"
             @hide="onHide"
             @show="onShow"
             centered>
      <SelectGroup :options="recordOptions"
                   variant="white"
                   :label="$t('select_visit')"
                   v-model="selectedRecordId"
                   :showlabel="true"/>
      <div v-if="selectedRecord">
        <div v-if="selectedRecordHasData" class="mb-3">
          <div class="mt-4">{{ $t('choose_criterias_for_fill') }}</div>
          <div class="mt-2">
            <a class="text-primary pointer"
               @click="selectAll">{{ $t('select_all') }}</a>
            <span class="text-muted opacity-50 mx-2">|</span>
            <a class="text-primary pointer"
               @click="resetAll">{{ $t('reset_all') }}</a>
          </div>
        </div>
        <template  v-for="field in fields">

          <div v-if="selectedRecord[field.id]"
               class="mb-3"
               :key="`f-${field.id}`">

            <div class="checkbox">
              <input type="checkbox"
                     :value="field.id"
                     v-model="fieldsToFill"
                     :id="`service-${field.id}`"
              />
              <label class="mb-0 h-auto"
                     :for="`service-${field.id}`">
                <div class="label font-weight-bold">{{ $t(field.title) }}</div>
                {{ selectedRecord[field.id] }}
              </label>
            </div>
          </div>
        </template>
        <div v-if="!selectedRecordHasData"
             class="alert alert-warning">
          {{ $t('no_data_available') }}
        </div>
      </div>

      <template #modal-footer="{ close }">
        <div class="modal-footer-buttons">
          <b-button class="ml-auto ml-sm-0 cancel d-inline-block d-sm-inline-block align-middle"
                    variant="themed"
                    @click="close()">
            {{ $t('cancel') }}
          </b-button>
          <button :disabled="loading || !selectedRecord || !selectedRecordHasData"
                  @click="save"
                  class="ml-3 btn-themed align-middle d-inline-block d-sm-inline-block btn-themed-squared">
            {{ $t('save') }}
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script setup>

import { computed, defineEmits, getCurrentInstance, onMounted, ref, watch } from "vue"
import { EloquentService, PatientService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"

let bvModal = null
const records = ref([])
const selectedRecordId = ref(null)
const selectedRecord = ref(null)
const fieldsToFill = ref([])
const loading = ref(false)

const props = defineProps({
  visit: {}
})

const emit = defineEmits(['reloadVisit'])

const fillFromPrevious = () => {
  bvModal?.show('fill-from-previous-modal')
}

const fields = [
  { id: 'complaints', title: 'complaints' },
  { id: 'development', title: 'disease_anamnez' },
  { id: 'diseases', title: 'life_anamnez' },
  { id: 'inspection', title: 'inspection' },
  { id: 'objectively', title: 'objectively' },
  { id: 'subjectively', title: 'subjectively' },
  { id: 'bite', title: 'bite' },
]

const onShow = async () => {
  const res = await EloquentService.dataTable('record', 1, {
    sort: 'desc',
    order: 'start',
    pagination: 100,
    condition: {
      client_id: props.visit.client_id,
      status: [0,1,3]
    },
  })
  records.value = res.data.result.data
}

const onHide = () => {
  selectedRecordId.value = null
  selectedRecord.value = null
  fieldsToFill.value = []
}

const recordOptions = computed(() => {
  return records.value.map(obj => ({
    id: obj.id,
    title: `${obj.readableStart}, ${obj.doctor?.full_name || '-'}`
  }))
})

const selectedRecordHasData = computed(() => {
  return fieldsThatSelectedRecordHas.value.length > 0
})

const fieldsThatSelectedRecordHas = computed(() => {
  if(!selectedRecord.value) return []
  return fields.filter(field => selectedRecord.value[field.id])
})

const save = async () => {
  const res = await PatientService.fillDescriptionsFromVisit({
    sourceVisitId: selectedRecordId.value,
    destinationVisitId: props.visit.id,
    patientId: props.visit.client_id,
    fields: fieldsToFill.value
  })
  if(res.data) {
    emit('reloadVisit')
    bvModal?.hide('fill-from-previous-modal')
  }
}

const selectAll = () => {
  fieldsToFill.value = fieldsThatSelectedRecordHas.value.map(field => field.id)
}

const resetAll = () => {
  fieldsToFill.value = []
}

watch(() => selectedRecordId.value, async (visitId) => {
  if(!visitId) return;
  const res = await EloquentService.show('record', visitId)
  selectedRecord.value = res.data
  selectAll()
})

onMounted(() => {
  bvModal = (getCurrentInstance())?.ctx?._bv__modal
})
</script>
