<template>
  <b-modal id="sort-modal"
           size="lg"
           @show="onShow"
           @hide="onHide"
           hide-footer
           :title="$t('sorting')"
           centered>

    <div class="text-muted small mb-3">{{ $t('sorting_instructions') }}</div>

    <draggable
        :scroll-sensitivity="200"
        :force-fallback="true"
        item-key="id"
        v-model="sortData"
        @end="dragEnd">
      <template #item="{ element }">
        <div class="sort-item d-flex align-items-center">
          <MoveSvg class="mr-2 svg-stroke-gray"/>
          {{ element.title }}
        </div>
      </template>
    </draggable>

  </b-modal>
</template>

<script setup>
import { EloquentService } from "@/services/api.service"
import { ref } from "vue"
import draggable from "vuedraggable"
import { useStore } from "vuex"
import MoveSvg from "@/assets/svg-vue/general/move.svg"

draggable.compatConfig = { MODE: 3 }

const props = defineProps({
  modelName: {
    type: String,
    required: true
  }
})

const store = useStore()

const sortData = ref([])

const loadData = async () => {
  const res = await EloquentService.loadSortingData(props.modelName)
  sortData.value = res.data
}

const dragEnd = async () => {
  const sorting = sortData.value.map((item, index) => {
    return {
      id: item.id,
      sort_order: (index + 1)
    }
  })
  try {
    await EloquentService.saveSortingData(props.modelName, { sorting })
  } catch (e) {
    console.log(e)
  }
}

const onShow = () => {
  loadData()
}

const onHide = () => {
  store.commit('incrementDataTableCounter')
}
</script>

<style scoped lang="scss">
.sort-item {
  user-select: none;
  cursor: move;
  padding: 5px 10px;
  border-bottom: 1px solid $calendar-border-color;
  background: #fff;
  &:hover {
    background: $light-back;
  }
}
</style>
