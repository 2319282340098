export default {
    primaryKey: "id",
    model: 'notification',
    order: 'id',
    sort: 'desc',
    showTotalRecords: true,
    actionscomponent: 'MarketingNotificationDatatableActions',
    actions: [
        // "add",
        // "edit",
        "delete"
    ],
    deletemessage: 'are_you_sure_you_want_to_delete_tag',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'recipient_id',
            title: 'recipient',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            filter: true,
            // tdcomponent: 'PatientsDatatableTDName',
            table_show: 'recipient.full_name',
        },
        {
            id: 'text',
            title: 'text',
            table: true,
            filter: true,
            sortable: true,
        },
        {
            id: 'marketing_mailing_id',
            title: 'channel',
            table: true,
            sortable: true,
            tdcomponent: 'MarketingDatatableTDNotificationChannel',
        },
        {
            id: 'sent_at',
            title: 'status',
            table: true,
            sortable: true,
            tdcomponent: 'MarketingDatatableTDNotificationStatus',
        },

    ]
}
