<template>
  <a class="pointer">
    <template v-if="object.scans.length > 0">
      <div style="width: 100px;">
        <expandable-image :src="object.scans[0].image"/>
      </div>
    </template>
    <template v-else @click="addScan">
      <AddSvg/>
    </template>

  </a>
</template>

<script>

// import ImgSvg from '@/assets/svg-vue/general/img.svg'
import AddSvg from '@/assets/svg-vue/general/add.svg'

export default {
  name: "PatientsDatatableTDScan",
  components: {
    // ImgSvg,
    AddSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    addScan() {
      this.$store.commit('setViewModel', 'scan')
      this.$store.commit('setModule', 'patients')
      this.$store.commit('setSelectedModel', {})
      this.$store.commit('setShowModal', true)
      // this.$bvModal.show('scan-modal')
    }
  }
}
</script>

<style scoped lang="scss">
  //.img-preview {
  //  max-width: 80px;
  //  max-height: 80px;
  //  object-position: left center;
  //  object-fit: contain;
  //}
</style>
