import  { PatientService } from "@/services/api.service"

const state = {
    patient: null,
    visit: null,
    printAgreements: false,
    selectedTemplate: null,
    plannedCall: null,
}

const getters = {

}

const mutations = {
    setPatient(state, patient) {
        state.patient = patient
    },
    setVisit(state, visit) {
        state.visit = visit
    },
    setPrintAgreements(state, value) {
        state.printAgreements = value
    },
    setSelectedTemplate(state, value) {
        state.selectedTemplate = value
    },
    setPlannedCall(state, value) {
        state.plannedCall = value
    }
}

const actions = {
    getPatient(context, id) {
        return new Promise((resolve, reject) => {
            PatientService.getPatient(id).then(data => {
                let d = data.data
                context.commit('setPatient', d)
                resolve(d)
            }).catch(err => {
                reject(err.response.data.errors)
            })
        })
    },
    getClientVisit(context, { id, visit_id }) {
        return new Promise((resolve, reject) => {
            PatientService.getClientVisit(id, visit_id).then(data => {
                let d = data.data
                // if(d.opened_visit && context.rootState.auth?.user?.id == d.opened_visit.created_by) context.commit('setMyOpenedVisit', d.opened_visit)
                context.commit('setVisit', d)
                resolve(d)
            }).catch(err => {
                reject(err.response ? err.response.data.errors : [])
            })
        })
    },
    getClientPlan(context, { id, plan_id }) {
        return new Promise((resolve, reject) => {
            PatientService.getClientPlan(id, plan_id).then(data => {
                const d = data.data
                d.visits.forEach(item => {
                    item.insurance_polis = d.insurance_polis
                })
                context.commit('setVisit', d)
                resolve(d)
            }).catch(err => {
                reject(err.response ? err.response.data.errors : [])
            })
        })
    }
};

export default {
    state,
    actions,
    mutations,
    getters
}
