<template>
  <span>
<!--  <a class="data-table-action"-->
<!--     @click="openReceipt"-->
<!--     v-b-tooltip.hover-->
<!--     :title="$t('receipt')"-->
<!--  >-->
<!--    <PrintSvg/><span class="action-button-mob-title">{{ $t('receipt') }}</span>-->
<!--  </a>-->

    <a class="data-table-action"
       v-if="object.paid > 0"
       v-b-tooltip.hover
       @click="returnPayment"
       :title="$t('return')">
    <ReturnSvg/><span class="action-button-mob-title">{{ $t('return') }}</span>
    </a>

    <a class="data-table-action"
       v-b-tooltip.hover
       :title="$t('print')">
      <b-dropdown right variant="link" no-caret>
        <template #button-content>
          <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
        </template>
        <a class="dropdown-item pointer" @click="openReceipt">
          {{ $t('receipt') }}
        </a>
        <a class="dropdown-item pointer" @click="openCheque">
          {{ $t('cheque') }}
        </a>
      </b-dropdown>
    </a>

<!--    <a class="data-table-action"-->
<!--       v-b-tooltip.hover-->
<!--       :title="$t('edit')">-->
<!--      <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>-->
<!--    </a>-->



<!--    <a class="data-table-action"-->
<!--       v-if="object.treatment_id"-->
<!--       @click="openAkt"-->
<!--       v-b-tooltip.hover-->
<!--       :title="$t('akt_print')"-->
<!--    >-->
<!--    <NoteSvg/><span class="action-button-mob-title">{{ $t('akt_print') }}</span>-->
<!--  </a>-->
    <PaymentReturnModal v-if="showReturnModal"
                        @hide="hideRefund"
                        :payment="object"/>
    </span>

</template>

<script>

import PrintSvg from '@/assets/svg-vue/general/print2.svg'
// import EditSvg from '@/assets/svg-vue/general/edit.svg'
// import NoteSvg from '@/assets/svg-vue/header/note.svg'
import ReturnSvg from '@/assets/svg-vue/accounting/back.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import PaymentReturnModal from "@/components/parts/patients/PaymentReturnModal.vue"

export default {
  name: "PaymentDatatableActions",
  components: {
    PaymentReturnModal,
    PrintSvg,
    ReturnSvg
    // EditSvg
    // NoteSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  data() {
    return {
        showReturnModal: false
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  methods: {
    openReceipt() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/receipt/'+this.object.id, '_blank')
    },
    openCheque() {
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/cheque/'+this.object.id, '_blank')
    },
    openAkt() {
      let signer = 'manager'
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/akt/'+this.object.treatment_id+'?signer='+signer, '_blank')
    },
    async returnPayment() {
      this.showReturnModal = true
      await this.$nextTick()
      this.$bvModal.show('payment-return-modal')
    },
    hideRefund() {
      this.showReturnModal = false
      this.$store.commit('incrementDataTableCounter')
    }
  }
}
</script>
