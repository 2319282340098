import { diagnosisTemplateAdapter } from "@/adapters/templates/diagnosis_template"

export default {
    primaryKey: "id",
    model: 'diagnosis_template',
    modaltitle: 'diagnosis',
    order: 'id',
    sort: 'desc',
    adapter: diagnosisTemplateAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_template',
    prepareParams: ['id', 'template_type'],
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title_plain',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            }
        },
        {
            id: 'mkb_code',
            title: 'mkb_code',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                // component: "GeneralTemplateMKBInput"
            }
        },
        {
            id: 'template_type',
            title: 'type',
            table: true,
            sortable: true,
            format_translate: true,
        },
    ]

}
