<template>
  <g>
    <!--   SAME AS 48  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M16.9253 78.7741L19.009 80.0439H22.4927L24.5927 78.7741L22.9811 77.2276L25.0648 75.6811L23.1764 74.1346L25.4718 72.4416L23.6485 70.8951L25.9439 69.4951L23.9253 68.0788L25.9439 66.4672L24.2509 64.9207L26.4811 63.5044L24.723 62.0393L27.0183 60.4928L24.8695 58.9463L27.0183 57.3346L25.4718 55.9835L27.5555 54.437L25.7323 52.9556L27.816 51.4091L26.009 49.6672L27.0183 47.9905V33.73L25.1299 26.4045H19.009" fill="#8889BE"/>
      <path d="M24.3326 78.7741L22.2489 80.0439H18.7814L16.6651 78.7741L18.293 77.2276L16.1931 75.6811L18.0814 74.1346L15.8024 72.4416L17.6093 70.8951L15.3303 69.4951L17.3489 68.0788L15.3303 66.4672L17.007 64.9207L14.7931 63.5044L16.5349 62.0393L14.2558 60.4928L16.4047 58.9463L14.2558 57.3346L15.8024 55.9835L13.7186 54.437L15.5256 52.9556L13.4419 51.4091L15.2651 49.6672L14.2558 47.9905V33.73L16.1279 26.4045H20.0349" fill="#8889BE"/>
      <path d="M41.1513 20.5353C39.9331 26.4864 39.0089 27.8867 37.7627 33.0256L35.9003 33.8658C33.6203 33.0373 31.1317 32.9783 28.815 33.6978C24.5022 34.832 25.4264 29.637 18.4811 32.4095C11.5359 35.182 9.08542 32.4095 9.08542 32.4095L3.37237 29.0209C3.37237 28.4188 3.27436 27.8867 3.20434 27.3966C2.56023 22.8598 0.93593 22.0616 1.21598 17.9169C1.42328 14.9997 2.40441 12.1904 4.05833 9.77846C5.71225 7.3665 7.97919 5.43897 10.6257 4.19436C12.7121 3.20018 14.4484 2.37403 16.4788 2.79411C19.2793 3.38222 19.8674 5.69264 21.7297 5.41258C23.312 5.17454 23.368 3.41022 25.2724 2.17799C27.9749 0.41367 31.5875 1.54788 33.1278 2.02396C33.5199 2.14999 39.919 4.25038 41.9214 10.2015C42.7476 12.6659 42.2295 15.2844 41.1513 20.5353Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M4.80678 8.59471C2.75077 11.2109 1.51779 14.3786 1.26413 17.6964C0.984082 21.8971 2.66439 22.6393 3.25249 27.1761C3.32251 27.6662 3.37852 28.1983 3.42053 28.8004C3.74259 33.2392 2.66439 33.3652 2.95844 38.1961C3.2805 43.4891 4.59674 43.5731 4.96081 48.376C5.22686 51.8906 4.68076 54.9852 3.56055 61.1743C3.18739 64.6042 3.18739 68.0644 3.56055 71.4942C3.56055 71.9843 3.70058 73.2865 4.48472 73.6506C5.26886 74.0147 6.09502 72.9365 7.41126 73.1885C8.47545 73.3986 8.57347 74.2527 9.72168 74.7288C10.7968 75.0631 11.9569 74.9934 12.9843 74.5328C15.321 73.6848 17.393 72.2359 18.9914 70.332C23.7382 64.731 25.5306 66.2713 29.4793 61.3984C33.1059 56.9035 34.2262 52.2827 35.6404 46.4436C37.1947 39.9885 36.0465 40.0725 37.7968 32.8752C39.043 27.7222 39.9672 26.308 41.1854 20.3849C42.2636 15.1759 42.7817 12.5154 41.9556 10.065C39.9672 4.1139 33.554 2.01351 33.176 1.88749C31.6357 1.4114 28.009 0.277195 25.3065 2.04152C23.3602 3.20373 23.3602 4.96805 21.7639 5.2061C19.9015 5.48615 19.2714 3.17572 16.5269 2.58762C14.4966 2.15354 12.7602 2.97969 10.6598 3.98787C8.3915 5.07071 6.39225 6.64428 4.80678 8.59471Z" fill="#DEE1E9"/>
      <path d="M41.1513 20.3171C39.9331 26.2682 39.0089 27.6684 37.7627 32.8074L35.9003 33.6475C33.6203 32.8191 31.1317 32.76 28.815 33.4795C24.5022 34.6137 25.4264 29.4188 18.4811 32.1913C11.5359 34.9638 9.08542 32.1913 9.08542 32.1913L3.37237 28.8026C3.37237 28.2005 3.27436 27.6684 3.20434 27.1783C2.56023 22.6415 0.93593 21.8434 1.21598 17.6986C1.42328 14.7814 2.40441 11.9722 4.05833 9.5602C5.71225 7.14823 7.97919 5.22071 10.6257 3.9761C12.7121 2.98192 14.4484 2.15577 16.4788 2.57585C19.2793 3.16395 19.8674 5.47437 21.7297 5.19432C23.312 4.95628 23.368 3.19196 25.2724 1.95973C27.9749 0.195408 31.5875 1.32961 33.1278 1.8057C33.5199 1.93172 39.919 4.03211 41.9214 9.9832C42.7476 12.4477 42.2295 15.0661 41.1513 20.3171Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M8.64426 27.3581C9.3864 31.2929 7.38403 32.8331 7.72009 36.9219C7.91613 39.4284 8.56025 39.3303 9.26038 42.5229C10.1285 46.4716 9.75046 49.9303 8.95232 56.7075C8.42022 61.2023 5.03129 72.0853 5.68941 72.2673C6.34753 72.4494 9.91849 62.7146 11.1367 62.8546C12.3549 62.9947 10.9736 73.0218 12.1078 73.3718C13.5921 73.7779 16.7797 62.8546 16.9898 62.5746C18.7261 59.7741 18.068 58.3738 19.1462 55.7833C20.5604 52.1287 21.0983 51.5254 23.6187 47.9267C25.5511 45.1262 26.2175 45.4495 28.3739 40.0025C31.0624 33.2252 33.3168 31.895 32.6867 28.6044C32.4954 27.5467 32.0588 26.5485 31.412 25.6901C30.7651 24.8317 29.926 24.1369 28.962 23.6615C26.5815 22.7233 25.4053 25.6358 21.5686 25.8179C19.6083 25.9159 19.6643 25.2298 14.1613 23.9695C9.21837 22.8353 8.25219 25.2718 8.64426 27.3581Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M4.80678 8.59471C2.75077 11.2109 1.51779 14.3786 1.26413 17.6964C0.984082 21.8971 2.66439 22.6393 3.25249 27.1761C3.32251 27.6662 3.37852 28.1983 3.42053 28.8004C3.74259 33.2392 2.66439 33.3652 2.95844 38.1961C3.2805 43.4891 4.59674 43.5731 4.96081 48.376C5.22686 51.8906 4.68076 54.9852 3.56055 61.1743C3.18739 64.6042 3.18739 68.0644 3.56055 71.4942C3.56055 71.9843 3.70058 73.2865 4.48472 73.6506C5.26886 74.0147 6.09502 72.9365 7.41126 73.1885C8.47545 73.3986 8.57347 74.2527 9.72168 74.7288C10.7968 75.0631 11.9569 74.9934 12.9843 74.5328C15.321 73.6848 17.393 72.2359 18.9914 70.332C23.7382 64.731 25.5306 66.2713 29.4793 61.3984C33.1059 56.9035 34.2262 52.2827 35.6404 46.4436C37.1947 39.9885 36.0465 40.0725 37.7968 32.8752C39.043 27.7222 39.9672 26.308 41.1854 20.3849C42.2636 15.1759 42.7817 12.5154 41.9556 10.065C39.9672 4.1139 33.554 2.01351 33.176 1.88749C31.6357 1.4114 28.009 0.277195 25.3065 2.04152C23.3602 3.20373 23.3602 4.96805 21.7639 5.2061C19.9015 5.48615 19.2714 3.17572 16.5269 2.58762C14.4966 2.15354 12.7602 2.97969 10.6598 3.98787C8.3915 5.07071 6.39225 6.64428 4.80678 8.59471Z" fill="#DEE1E9"/>
      <path d="M41.1513 20.3171C39.9331 26.2682 39.0089 27.6684 37.7627 32.8074L35.9003 33.6475C33.6203 32.8191 31.1317 32.76 28.815 33.4795C24.5022 34.6137 25.4264 29.4188 18.4811 32.1913C11.5359 34.9638 9.08542 32.1913 9.08542 32.1913L3.37237 28.8026C3.37237 28.2005 3.27436 27.6684 3.20434 27.1783C2.56023 22.6415 0.93593 21.8434 1.21598 17.6986C1.42328 14.7814 2.40441 11.9722 4.05833 9.5602C5.71225 7.14823 7.97919 5.22071 10.6257 3.9761C12.7121 2.98192 14.4484 2.15577 16.4788 2.57585C19.2793 3.16395 19.8674 5.47437 21.7297 5.19432C23.312 4.95628 23.368 3.19196 25.2724 1.95973C27.9749 0.195408 31.5875 1.32961 33.1278 1.8057C33.5199 1.93172 39.919 4.03211 41.9214 9.9832C42.7476 12.4477 42.2295 15.0661 41.1513 20.3171Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M8.64426 27.3581C9.3864 31.2929 7.38403 32.8331 7.72009 36.9219C7.91613 39.4284 8.56025 39.3303 9.26038 42.5229C10.1285 46.4716 9.75046 49.9303 8.95232 56.7075C8.42022 61.2023 5.03129 72.0853 5.68941 72.2673C6.34753 72.4494 9.91849 62.7146 11.1367 62.8546C12.3549 62.9947 10.9736 73.0218 12.1078 73.3718C13.5921 73.7779 16.7797 62.8546 16.9898 62.5746C18.7261 59.7741 18.068 58.3738 19.1462 55.7833C20.5604 52.1287 21.0983 51.5254 23.6187 47.9267C25.5511 45.1262 26.2175 45.4495 28.3739 40.0025C31.0624 33.2252 33.3168 31.895 32.6867 28.6044C32.4954 27.5467 32.0588 26.5485 31.412 25.6901C30.7651 24.8317 29.926 24.1369 28.962 23.6615C26.5815 22.7233 25.4053 25.6358 21.5686 25.8179C19.6083 25.9159 19.6643 25.2298 14.1613 23.9695C9.21837 22.8353 8.25219 25.2718 8.64426 27.3581Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="5" cy="74" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="13" cy="75" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M3.80678 8.05004C1.75077 10.6662 0.517793 13.834 0.264133 17.1517C-0.0159184 21.3525 1.66439 22.0946 2.25249 26.6314C2.32251 27.1215 2.37852 27.6536 2.42053 28.2557C2.74259 32.6945 1.66439 32.8206 1.95844 37.6514C2.2805 42.9444 3.59674 43.0284 3.96081 47.8313C4.22686 51.346 3.68076 54.4405 2.56055 60.6296C2.18739 64.0595 2.18739 67.5197 2.56055 70.9495C2.56055 71.4396 2.70058 72.7419 3.48472 73.1059C4.26886 73.47 5.09502 72.3918 6.41126 72.6438C7.47545 72.8539 7.57347 73.708 8.72168 74.1841C9.79678 74.5184 10.9569 74.4487 11.9843 73.9881C14.321 73.1401 16.393 71.6912 17.9914 69.7873C22.7382 64.1863 24.5306 65.7266 28.4793 60.8537C32.1059 56.3589 33.2262 51.738 34.6404 45.899C36.1947 39.4438 35.0465 39.5278 36.7968 32.3305C38.043 27.1775 38.9672 25.7633 40.1854 19.8402C41.2636 14.6312 41.7817 11.9708 40.9556 9.52031C38.9672 3.56922 32.554 1.46883 32.176 1.34281C30.6357 0.866724 27.009 -0.267483 24.3065 1.49684C22.3602 2.65905 22.3602 4.42337 20.7639 4.66142C18.9015 4.94147 18.2714 2.63105 15.5269 2.04294C13.4966 1.60886 11.7602 2.43501 9.65985 3.44319C7.3915 4.52603 5.39225 6.0996 3.80678 8.05004Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M3.80678 8.05004C1.75077 10.6662 0.517793 13.834 0.264133 17.1517C-0.0159184 21.3525 1.66439 22.0946 2.25249 26.6314C2.32251 27.1215 2.37852 27.6536 2.42053 28.2557C2.74259 32.6945 1.66439 32.8206 1.95844 37.6514C2.2805 42.9444 3.59674 43.0284 3.96081 47.8313C4.22686 51.346 3.68076 54.4405 2.56055 60.6296C2.18739 64.0595 2.18739 67.5197 2.56055 70.9495C2.56055 71.4396 2.70058 72.7419 3.48472 73.1059C4.26886 73.47 5.09502 72.3918 6.41126 72.6438C7.47545 72.8539 7.57347 73.708 8.72168 74.1841C9.79678 74.5184 10.9569 74.4487 11.9843 73.9881C14.321 73.1401 16.393 71.6912 17.9914 69.7873C22.7382 64.1863 24.5306 65.7266 28.4793 60.8537C32.1059 56.3589 33.2262 51.738 34.6404 45.899C36.1947 39.4438 35.0465 39.5278 36.7968 32.3305C38.043 27.1775 38.9672 25.7633 40.1854 19.8402C41.2636 14.6312 41.7817 11.9708 40.9556 9.52031C38.9672 3.56922 32.554 1.46883 32.176 1.34281C30.6357 0.866724 27.009 -0.267483 24.3065 1.49684C22.3602 2.65905 22.3602 4.42337 20.7639 4.66142C18.9015 4.94147 18.2714 2.63105 15.5269 2.04294C13.4966 1.60886 11.7602 2.43501 9.65985 3.44319C7.3915 4.52603 5.39225 6.0996 3.80678 8.05004Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M1.23667 6.29217C1.19467 5.69006 1.13866 5.15796 1.06864 4.66787L9.5 6.29217L20.5 4.66787L29 7.49798L37 4.66782C36.3884 6.68803 36.3193 7.44632 35.613 10.3669C33.8626 17.5642 35.0108 17.4802 33.4566 23.9354C32.0423 29.7745 30.9221 34.3953 27.2954 38.8901C23.3467 43.763 21.5544 42.2227 16.8075 47.8238C15.2091 49.7276 13.1372 51.1765 10.8004 52.0245C9.77309 52.4852 8.61292 52.5549 7.53783 52.2206C6.38962 51.7445 6.2916 50.8903 5.2274 50.6803C3.91116 50.4282 3.08501 51.5064 2.30087 51.1424C1.51673 50.7783 1.3767 49.4761 1.3767 48.986C1.00354 45.5561 1.00354 42.0959 1.3767 38.6661C2.49691 32.477 3.043 29.3824 2.77696 25.8678C2.41289 21.0649 1.09665 20.9809 0.77459 15.6879C0.480536 10.857 1.55873 10.731 1.23667 6.29217Z" fill="#DEE1E9"/>
      <path d="M5.0223 9.73224C15.6756 13.6389 21.5818 13.3503 32.0342 9.265" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M5.53626 14.4136C5.20019 10.3249 7.20256 8.7846 6.46042 4.84988C6.34377 4.22912 6.34736 3.57737 6.54014 2.9979L10.5706 1L14.5 2.4979L19.5425 1L23.7515 2.4979L27.5 1.56521C28.1634 1.99901 28.7486 2.54547 29.2281 3.18184C29.875 4.04027 30.3116 5.0384 30.5028 6.09611C31.1329 9.38671 28.8785 10.717 26.19 17.4942C24.0336 22.9412 23.3673 22.6179 21.4349 25.4184C18.9144 29.0171 18.3766 29.6204 16.9623 33.2751C15.8841 35.8656 16.5423 37.2658 14.8059 40.0663C14.5959 40.3464 11.4083 51.2696 9.924 50.8636C8.78979 50.5135 10.1711 40.4864 8.95288 40.3464C7.73466 40.2064 4.16369 49.9411 3.50557 49.7591C2.84745 49.5771 6.23638 38.6941 6.76848 34.1993C7.56663 27.422 7.9447 23.9634 7.07654 20.0147C6.37641 16.8221 5.73229 16.9201 5.53626 14.4136Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8626 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 36.8261 15.9504 36 13.5C34.0116 7.54891 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
      <path d="M37.5788 32.8013C38.825 27.6623 39.7492 26.2621 40.9674 20.311C41.2762 18.8071 41.5391 17.5191 41.7376 16.3889L41.1463 15.5201L37 15.5L38.9175 10.5L35.1085 10L38.9175 6.51912L35.1085 5.00439L35.7165 3.06688C34.2635 2.23097 33.1039 1.85101 32.944 1.7996L32.9132 1.79007C31.356 1.30865 27.7732 0.200964 25.0885 1.95363C23.1842 3.18585 23.1282 4.95018 21.5459 5.18822C19.6835 5.46827 19.0954 3.15785 16.2949 2.56974C14.2646 2.14967 12.5282 2.97582 10.4419 3.97C7.79535 5.2146 5.52842 7.14213 3.87449 9.5541C2.22057 11.9661 1.23944 14.7753 1.03214 17.6925C0.752092 21.8373 2.37639 22.6354 3.02051 27.1722C3.09052 27.6623 3.18854 28.1944 3.18854 28.7965L8.90158 32.1852C8.90158 32.1852 11.352 34.9577 18.2973 32.1852C25.2426 29.4126 24.3184 34.6076 28.6312 33.4734C30.9479 32.7539 33.4365 32.813 35.7165 33.6414L37.5788 32.8013Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M39.0755 23.8344C28.0747 19.4825 25.5551 14.5884 26.814 1.99999" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M8.46042 27.352C9.20256 31.2868 7.20019 32.827 7.53626 36.9158C7.73229 39.4222 8.37641 39.3242 9.07654 42.5168C9.9447 46.4655 9.56663 49.9242 8.76848 56.7014C8.23638 61.1962 4.84745 72.0792 5.50557 72.2612C6.16369 72.4433 9.73466 62.7085 10.9529 62.8485C12.1711 62.9886 10.7898 73.0157 11.924 73.3657C13.4083 73.7718 16.5959 62.8485 16.8059 62.5685C18.5423 59.768 17.8841 58.3677 18.9623 55.7772C20.3766 52.1226 20.9144 51.5193 23.4349 47.9206C25.3673 45.1201 26.0336 45.4433 28.19 39.9964C30.8785 33.2191 33.1329 31.8889 32.5028 28.5983C32.3116 27.5406 31.875 26.5424 31.2281 25.684C30.5813 24.8256 29.7422 24.1308 28.7781 23.6554C26.3977 22.7172 25.2215 25.6297 21.3848 25.8118C19.4244 25.9098 19.4804 25.2237 13.9774 23.9634C9.03453 22.8292 8.06835 25.2657 8.46042 27.352Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M4.62294 8.838C2.56693 11.4542 1.33395 14.6219 1.08029 17.9397C0.800244 22.1404 2.48055 22.8826 3.06866 27.4194C3.13867 27.9095 3.19468 28.4416 3.23669 29.0437C3.55875 33.4825 2.48055 33.6085 2.7746 38.4394C3.09666 43.7324 4.4129 43.8164 4.77697 48.6193C5.04302 52.1339 4.49692 55.2285 3.37671 61.4176C3.00356 64.8475 3.00356 68.3077 3.37671 71.7375C3.37671 72.2276 3.51674 73.5298 4.30088 73.8939C5.08503 74.258 5.91118 73.1798 7.22742 73.4318C8.29161 73.6418 8.38963 74.496 9.53784 74.9721C10.6129 75.3064 11.7731 75.2367 12.8004 74.7761C15.1372 73.9281 17.2092 72.4791 18.8075 70.5753C23.5544 64.9743 25.3467 66.5145 29.2954 61.6417C32.9221 57.1468 34.0423 52.526 35.4566 46.6869C37.0109 40.2317 35.8627 40.3158 37.613 33.1184C38.8592 27.9655 39.7834 26.5512 41.0016 20.6282C42.0798 15.4192 42.5979 12.7587 41.7717 10.3083C39.7834 4.35718 33.3702 2.2568 32.9921 2.13078C31.4518 1.65469 27.8252 0.520481 25.1227 2.2848C23.1763 3.44702 23.1763 5.21134 21.58 5.44938C19.7177 5.72943 19.0876 3.41901 16.3431 2.8309C14.3127 2.39682 12.5764 3.22297 10.476 4.23116C8.20767 5.31399 6.20842 6.88757 4.62294 8.838Z" fill="#DEE1E9"/>
      <path d="M40.9674 20.5604C39.7492 26.5115 38.825 27.9117 37.5788 33.0506L35.7165 33.8908C33.7465 33.175 31.8962 32.5319 29.9352 32.8342C29.4048 32.916 28.8563 32.6512 28.6934 32.1399C27 26.826 27 21.7494 27 21.7494C22.1189 19.9398 19.3811 19.8996 14.5 21.7494L12.212 30.9013C12.0906 31.387 11.6287 31.708 11.1312 31.6526L3.02051 30.7494C3.02051 30.1473 3.09052 27.9117 3.02051 27.4216C2.37639 22.8848 0.752092 22.0866 1.03214 17.9419C1.23944 15.0247 2.22057 12.2155 3.87449 9.80349C5.52842 7.39152 7.79535 5.46399 10.4419 4.21939C12.5282 3.22521 14.2646 2.39906 16.2949 2.81913C19.0954 3.40724 19.6835 5.71766 21.5459 5.43761C23.1282 5.19957 23.1842 3.43524 25.0885 2.20302C27.791 0.438694 31.4037 1.5729 32.944 2.04899C33.336 2.17501 39.7352 4.2754 41.7376 10.2265C42.5637 12.6909 42.0456 15.3094 40.9674 20.5604Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
      <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.7465 32.9256 31.8962 32.2825 29.9352 32.5848C29.4048 32.6666 28.8563 32.4018 28.6934 31.8905C27 26.5766 27 21.5 27 21.5C22.1189 19.6904 19.3811 19.6502 14.5 21.5L12.212 30.6519C12.0906 31.1376 11.6287 31.4587 11.1312 31.4032L3.02051 30.5C3.02051 29.8979 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2048" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.7465 32.9256 31.8962 32.2825 29.9352 32.5848C29.4048 32.6666 28.8563 32.4018 28.6934 31.8905C27 26.5766 27 21.5 27 21.5C22.1189 19.6904 19.3811 19.6502 14.5 21.5L12.212 30.6519C12.0906 31.1376 11.6287 31.4587 11.1312 31.4032L3.02051 30.5C3.02051 29.8979 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2048)">
        <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.7465 32.9256 31.8962 32.2825 29.9352 32.5848C29.4048 32.6666 28.8563 32.4018 28.6934 31.8905C27 26.5766 27 21.5 27 21.5C22.1189 19.6904 19.3811 19.6502 14.5 21.5L12.212 30.6519C12.0906 31.1376 11.6287 31.4587 11.1312 31.4032L3.02051 30.5C3.02051 29.8979 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M32 44H8V18.452C17.3726 16.5258 22.6274 16.5062 32 18.452V44Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M4.62294 8.23534C2.56693 10.8515 1.33395 14.0193 1.08029 17.337C0.800244 21.5378 2.48055 22.2799 3.06866 26.8167C3.13867 27.3068 3.19468 27.8389 3.23669 28.441C3.55875 32.8798 2.48055 33.0059 2.7746 37.8367C3.09666 43.1297 4.4129 43.2137 4.77697 48.0166C5.04302 51.5313 4.49692 54.6258 3.37671 60.8149C3.00356 64.2448 3.00356 67.705 3.37671 71.1348C3.37671 71.6249 3.51674 72.9272 4.30088 73.2912C5.08503 73.6553 5.91118 72.5771 7.22742 72.8291C8.29161 73.0392 8.38963 73.8933 9.53784 74.3694C10.6129 74.7037 11.7731 74.634 12.8004 74.1734C15.1372 73.3254 17.2092 71.8765 18.8075 69.9726C23.5544 64.3716 25.3467 65.9119 29.2954 61.039C32.9221 56.5442 34.0423 51.9233 35.4566 46.0843C37.0109 39.6291 35.8627 39.7131 37.613 32.5158C38.8592 27.3628 39.7834 25.9486 41.0016 20.0255C42.0798 14.8165 42.5979 12.1561 41.7717 9.70561C39.7834 3.75452 33.3702 1.65414 32.9921 1.52811C31.4518 1.05203 27.8252 -0.0821804 25.1227 1.68214C23.1763 2.84435 23.1763 4.60868 21.58 4.84672C19.7177 5.12677 19.0876 2.81635 16.3431 2.22824C14.3127 1.79416 12.5764 2.62031 10.476 3.6285C8.20767 4.71133 6.20842 6.2849 4.62294 8.23534Z" fill="#DEE1E9"/>
      <path d="M40.9674 19.958C39.7492 25.909 38.825 27.3093 37.5788 32.4482L35.7165 33.2884C33.4365 32.4599 30.9479 32.4009 28.6312 33.1204C24.3184 34.2546 25.2426 29.0596 18.2973 31.8321C11.352 34.6046 8.90158 31.8321 8.90158 31.8321L3.18854 28.4435C3.18854 27.8414 3.09052 27.3093 3.02051 26.8192C2.37639 22.2824 0.752092 21.4842 1.03214 17.3395C1.23944 14.4223 2.22057 11.613 3.87449 9.20107C5.52842 6.7891 7.79535 4.86158 10.4419 3.61697C12.5282 2.62279 14.2646 1.79664 16.2949 2.21672C19.0954 2.80482 19.6835 5.11524 21.5459 4.83519C23.1282 4.59715 23.1842 2.83282 25.0885 1.6006C27.791 -0.163723 31.4037 0.970483 32.944 1.44657C33.336 1.57259 39.7352 3.67298 41.7376 9.62407C42.5637 12.0885 42.0456 14.707 40.9674 19.958Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M8.46042 26.9991C9.20256 30.9338 7.20019 32.4741 7.53626 36.5628C7.73229 39.0693 8.37641 38.9713 9.07654 42.1638C9.9447 46.1126 9.56663 49.5712 8.76848 56.3484C8.23638 60.8433 4.84745 71.7262 5.50557 71.9083C6.16369 72.0903 9.73466 62.3555 10.9529 62.4956C12.1711 62.6356 10.7898 72.6627 11.924 73.0128C13.4083 73.4188 16.5959 62.4956 16.8059 62.2155C18.5423 59.415 17.8841 58.0147 18.9623 55.4243C20.3766 51.7696 20.9144 51.1663 23.4349 47.5676C25.3673 44.7671 26.0336 45.0904 28.19 39.6434C30.8785 32.8661 33.1329 31.5359 32.5028 28.2453C32.3116 27.1876 31.875 26.1895 31.2281 25.331C30.5813 24.4726 29.7422 23.7778 28.7781 23.3024C26.3977 22.3642 25.2215 25.2768 21.3848 25.4588C19.4244 25.5568 19.4804 24.8707 13.9774 23.6105C9.03453 22.4762 8.06835 24.9127 8.46042 26.9991Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M30.8946 57.3775C20.3295 62.8417 14.4299 62.5927 3.92738 57.3775" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M5.62294 8.58861C3.56693 11.2048 2.33395 14.3725 2.08029 17.6903C1.80024 21.891 3.48055 22.6332 4.06866 27.17C4.13867 27.6601 4.19468 28.1922 4.23669 28.7943C4.55875 33.2331 3.48055 33.3591 3.7746 38.19C4.09666 43.483 5.4129 43.567 5.77697 48.3699C6.04302 51.8845 5.49692 54.9791 4.37671 61.1682C4.00356 64.5981 4.00356 68.0583 4.37671 71.4881C4.37671 71.9782 4.51674 73.2804 5.30088 73.6445C6.08503 74.0086 6.91118 72.9304 8.22742 73.1824C9.29161 73.3925 9.38963 74.2466 10.5378 74.7227C11.6129 75.057 12.7731 74.9873 13.8004 74.5267C16.1372 73.6787 18.2092 72.2298 19.8075 70.3259C24.5544 64.7249 26.3467 66.2652 30.2954 61.3923C33.9221 56.8974 35.0423 52.2766 36.4566 46.4375C38.0109 39.9823 36.8627 40.0664 38.613 32.869C39.8592 27.7161 40.7834 26.3018 42.0016 20.3788C43.0798 15.1698 43.5979 12.5093 42.7717 10.0589C40.7834 4.10779 34.3702 2.00741 33.9921 1.88139C32.4518 1.4053 28.8252 0.271091 26.1227 2.03541C24.1763 3.19763 24.1763 4.96195 22.58 5.19999C20.7177 5.48004 20.0876 3.16962 17.3431 2.58151C15.3127 2.14743 13.5764 2.97358 11.476 3.98177C9.20767 5.0646 7.20842 6.63818 5.62294 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2248" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="43" height="35">
        <path d="M41.9674 20.311C40.7492 26.2621 39.825 27.6623 38.5788 32.8013L36.7165 33.6414C34.4365 32.813 31.9479 32.7539 29.6312 33.4734C25.3184 34.6076 26.2426 29.4126 19.2973 32.1852C12.352 34.9577 9.90158 32.1852 9.90158 32.1852L4.18854 28.7965C4.18854 28.1944 4.09052 27.6623 4.02051 27.1722C3.37639 22.6354 1.75209 21.8373 2.03214 17.6925C2.23944 14.7753 3.22057 11.9661 4.87449 9.5541C6.52842 7.14213 8.79535 5.2146 11.4419 3.97C13.5282 2.97582 15.2646 2.14967 17.2949 2.56974C20.0954 3.15785 20.6835 5.46827 22.5459 5.18822C24.1282 4.95018 24.1842 3.18585 26.0885 1.95363C28.791 0.189304 32.4037 1.32351 33.944 1.7996C34.336 1.92562 40.7352 4.02601 42.7376 9.9771C43.5637 12.4415 43.0456 15.06 41.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2248)">
        <path d="M41.9674 20.311C40.7492 26.2621 39.825 27.6623 38.5788 32.8013L36.7165 33.6414C34.4365 32.813 31.9479 32.7539 29.6312 33.4734C25.3184 34.6076 26.2426 29.4126 19.2973 32.1852C12.352 34.9577 9.90158 32.1852 9.90158 32.1852L4.18854 28.7965C4.18854 28.1944 4.09052 27.6623 4.02051 27.1722C3.37639 22.6354 1.75209 21.8373 2.03214 17.6925C2.23944 14.7753 3.22057 11.9661 4.87449 9.5541C6.52842 7.14213 8.79535 5.2146 11.4419 3.97C13.5282 2.97582 15.2646 2.14967 17.2949 2.56974C20.0954 3.15785 20.6835 5.46827 22.5459 5.18822C24.1282 4.95018 24.1842 3.18585 26.0885 1.95363C28.791 0.189304 32.4037 1.32351 33.944 1.7996C34.336 1.92562 40.7352 4.02601 42.7376 9.9771C43.5637 12.4415 43.0456 15.06 41.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M22.6923 20C23.7544 20 25 14.7018 25 5.10345L23.0769 3.24138L20 2C20 11.5983 21.6302 20 22.6923 20Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M9.13001 20.4341C9.8943 24.9281 7.83216 26.6873 8.17826 31.3571C8.38014 34.2198 9.04349 34.1078 9.76452 37.7542C10.6586 42.2641 10.2692 46.2143 9.44726 53.9547C8.89928 59.0883 5.40918 71.518 6.08695 71.7259C6.76472 71.9338 10.4423 60.8155 11.6969 60.9755C12.9515 61.1354 11.5289 72.5876 12.697 72.9874C14.2256 73.4512 17.5084 60.9755 17.7247 60.6556C19.5128 57.4571 18.8351 55.8578 19.9454 52.8992C21.4019 48.7251 21.9558 48.0361 24.5515 43.926C26.5416 40.7274 27.2278 41.0966 29.4486 34.8755C32.2174 27.1351 34.5391 25.6158 33.8901 21.8575C33.6932 20.6495 33.2436 19.5095 32.5774 18.529C31.9112 17.5486 31.0471 16.7551 30.0543 16.2121C27.6028 15.1406 26.3914 18.4671 22.4402 18.675C20.4213 18.7869 20.479 18.0033 14.8117 16.5639C9.72126 15.2685 8.72624 18.0512 9.13001 20.4341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="4" cy="72" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="13" cy="73" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M5.62294 8.58861C3.56693 11.2048 2.33395 14.3725 2.08029 17.6903C1.80024 21.891 3.48055 22.6332 4.06866 27.17C4.13867 27.6601 4.19468 28.1922 4.23669 28.7943C4.55875 33.2331 3.48055 33.3591 3.7746 38.19C4.09666 43.483 5.4129 43.567 5.77697 48.3699C6.04302 51.8845 5.49692 54.9791 4.37671 61.1682C4.00356 64.5981 4.00356 68.0583 4.37671 71.4881C4.37671 71.9782 4.51674 73.2804 5.30088 73.6445C6.08503 74.0086 6.91118 72.9304 8.22742 73.1824C9.29161 73.3925 9.38963 74.2466 10.5378 74.7227C11.6129 75.057 12.7731 74.9873 13.8004 74.5267C16.1372 73.6787 18.2092 72.2298 19.8075 70.3259C24.5544 64.7249 26.3467 66.2652 30.2954 61.3923C33.9221 56.8974 35.0423 52.2766 36.4566 46.4375C38.0109 39.9823 36.8627 40.0664 38.613 32.869C39.8592 27.7161 40.7834 26.3018 42.0016 20.3788C43.0798 15.1698 43.5979 12.5093 42.7717 10.0589C40.7834 4.10779 34.3702 2.00741 33.9921 1.88139C32.4518 1.4053 28.8252 0.271091 26.1227 2.03541C24.1763 3.19763 24.1763 4.96195 22.58 5.19999C20.7177 5.48004 20.0876 3.16962 17.3431 2.58151C15.3127 2.14743 13.5764 2.97358 11.476 3.98177C9.20767 5.0646 7.20842 6.63818 5.62294 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2348" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="43" height="35">
        <path d="M41.9674 20.311C40.7492 26.2621 39.825 27.6623 38.5788 32.8013L36.7165 33.6414C34.4365 32.813 31.9479 32.7539 29.6312 33.4734C25.3184 34.6076 26.2426 29.4126 19.2973 32.1852C12.352 34.9577 9.90158 32.1852 9.90158 32.1852L4.18854 28.7965C4.18854 28.1944 4.09052 27.6623 4.02051 27.1722C3.37639 22.6354 1.75209 21.8373 2.03214 17.6925C2.23944 14.7753 3.22057 11.9661 4.87449 9.5541C6.52842 7.14213 8.79535 5.2146 11.4419 3.97C13.5282 2.97582 15.2646 2.14967 17.2949 2.56974C20.0954 3.15785 20.6835 5.46827 22.5459 5.18822C24.1282 4.95018 24.1842 3.18585 26.0885 1.95363C28.791 0.189304 32.4037 1.32351 33.944 1.7996C34.336 1.92562 40.7352 4.02601 42.7376 9.9771C43.5637 12.4415 43.0456 15.06 41.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2348)">
        <path d="M41.9674 20.311C40.7492 26.2621 39.825 27.6623 38.5788 32.8013L36.7165 33.6414C34.4365 32.813 31.9479 32.7539 29.6312 33.4734C25.3184 34.6076 26.2426 29.4126 19.2973 32.1852C12.352 34.9577 9.90158 32.1852 9.90158 32.1852L4.18854 28.7965C4.18854 28.1944 4.09052 27.6623 4.02051 27.1722C3.37639 22.6354 1.75209 21.8373 2.03214 17.6925C2.23944 14.7753 3.22057 11.9661 4.87449 9.5541C6.52842 7.14213 8.79535 5.2146 11.4419 3.97C13.5282 2.97582 15.2646 2.14967 17.2949 2.56974C20.0954 3.15785 20.6835 5.46827 22.5459 5.18822C24.1282 4.95018 24.1842 3.18585 26.0885 1.95363C28.791 0.189304 32.4037 1.32351 33.944 1.7996C34.336 1.92562 40.7352 4.02601 42.7376 9.9771C43.5637 12.4415 43.0456 15.06 41.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M22.6923 20C23.7544 20 25 14.7018 25 5.10345L23.0769 3.24138L20 2C20 11.5983 21.6302 20 22.6923 20Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M9.13001 20.4341C9.8943 24.9281 7.83216 26.6873 8.17826 31.3571C8.38014 34.2198 9.04349 34.1078 9.76452 37.7542C10.6586 42.2641 10.2692 46.2143 9.44726 53.9547C8.89928 59.0883 5.40918 71.518 6.08695 71.7259C6.76472 71.9338 10.4423 60.8155 11.6969 60.9755C12.9515 61.1354 11.5289 72.5876 12.697 72.9874C14.2256 73.4512 17.5084 60.9755 17.7247 60.6556C19.5128 57.4571 18.8351 55.8578 19.9454 52.8992C21.4019 48.7251 21.9558 48.0361 24.5515 43.926C26.5416 40.7274 27.2278 41.0966 29.4486 34.8755C32.2174 27.1351 34.5391 25.6158 33.8901 21.8575C33.6932 20.6495 33.2436 19.5095 32.5774 18.529C31.9112 17.5486 31.0471 16.7551 30.0543 16.2121C27.6028 15.1406 26.3914 18.4671 22.4402 18.675C20.4213 18.7869 20.479 18.0033 14.8117 16.5639C9.72126 15.2685 8.72624 18.0512 9.13001 20.4341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="4" cy="72" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="13" cy="73" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2348)">
        <path d="M13 70L13.8229 73.7391L16.8567 71.4037L15.0837 74.797L18.9088 74.9581L15.3694 76.4178L18.1962 79L14.5465 77.8431L15.0521 81.6382L13 78.406L10.9479 81.6382L11.4535 77.8431L7.80385 79L10.6306 76.4178L7.09115 74.9581L10.9163 74.797L9.14327 71.4037L12.1771 73.7391L13 70Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2348" x="6.89113" y="69.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.62294 8.58861C3.56693 11.2048 2.33395 14.3725 2.08029 17.6903C1.80024 21.891 3.48055 22.6332 4.06866 27.17C4.13867 27.6601 4.19468 28.1922 4.23669 28.7943C4.55875 33.2331 3.48055 33.3591 3.7746 38.19C4.09666 43.483 5.4129 43.567 5.77697 48.3699C6.04302 51.8845 5.49692 54.9791 4.37671 61.1682C4.00356 64.5981 4.00356 68.0583 4.37671 71.4881C4.37671 71.9782 4.51674 73.2804 5.30088 73.6445C6.08503 74.0086 6.91118 72.9304 8.22742 73.1824C9.29161 73.3925 9.38963 74.2466 10.5378 74.7227C11.6129 75.057 12.7731 74.9873 13.8004 74.5267C16.1372 73.6787 18.2092 72.2298 19.8075 70.3259C24.5544 64.7249 26.3467 66.2652 30.2954 61.3923C33.9221 56.8974 35.0423 52.2766 36.4566 46.4375C38.0109 39.9823 36.8627 40.0664 38.613 32.869C39.8592 27.7161 40.7834 26.3018 42.0016 20.3788C43.0798 15.1698 43.5979 12.5093 42.7717 10.0589C40.7834 4.10779 34.3702 2.00741 33.9921 1.88139C32.4518 1.4053 28.8252 0.271091 26.1227 2.03541C24.1763 3.19763 24.1763 4.96195 22.58 5.19999C20.7177 5.48004 20.0876 3.16962 17.3431 2.58151C15.3127 2.14743 13.5764 2.97358 11.476 3.98177C9.20767 5.0646 7.20842 6.63818 5.62294 8.58861Z" fill="#DEE1E9"/>
      <mask id="mask2348" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="0" width="43" height="35">
        <path d="M41.9674 20.311C40.7492 26.2621 39.825 27.6623 38.5788 32.8013L36.7165 33.6414C34.4365 32.813 31.9479 32.7539 29.6312 33.4734C25.3184 34.6076 26.2426 29.4126 19.2973 32.1852C12.352 34.9577 9.90158 32.1852 9.90158 32.1852L4.18854 28.7965C4.18854 28.1944 4.09052 27.6623 4.02051 27.1722C3.37639 22.6354 1.75209 21.8373 2.03214 17.6925C2.23944 14.7753 3.22057 11.9661 4.87449 9.5541C6.52842 7.14213 8.79535 5.2146 11.4419 3.97C13.5282 2.97582 15.2646 2.14967 17.2949 2.56974C20.0954 3.15785 20.6835 5.46827 22.5459 5.18822C24.1282 4.95018 24.1842 3.18585 26.0885 1.95363C28.791 0.189304 32.4037 1.32351 33.944 1.7996C34.336 1.92562 40.7352 4.02601 42.7376 9.9771C43.5637 12.4415 43.0456 15.06 41.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2348)">
        <path d="M41.9674 20.311C40.7492 26.2621 39.825 27.6623 38.5788 32.8013L36.7165 33.6414C34.4365 32.813 31.9479 32.7539 29.6312 33.4734C25.3184 34.6076 26.2426 29.4126 19.2973 32.1852C12.352 34.9577 9.90158 32.1852 9.90158 32.1852L4.18854 28.7965C4.18854 28.1944 4.09052 27.6623 4.02051 27.1722C3.37639 22.6354 1.75209 21.8373 2.03214 17.6925C2.23944 14.7753 3.22057 11.9661 4.87449 9.5541C6.52842 7.14213 8.79535 5.2146 11.4419 3.97C13.5282 2.97582 15.2646 2.14967 17.2949 2.56974C20.0954 3.15785 20.6835 5.46827 22.5459 5.18822C24.1282 4.95018 24.1842 3.18585 26.0885 1.95363C28.791 0.189304 32.4037 1.32351 33.944 1.7996C34.336 1.92562 40.7352 4.02601 42.7376 9.9771C43.5637 12.4415 43.0456 15.06 41.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M22.6923 20C23.7544 20 25 14.7018 25 5.10345L23.0769 3.24138L20 2C20 11.5983 21.6302 20 22.6923 20Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M9.13001 20.4341C9.8943 24.9281 7.83216 26.6873 8.17826 31.3571C8.38014 34.2198 9.04349 34.1078 9.76452 37.7542C10.6586 42.2641 10.2692 46.2143 9.44726 53.9547C8.89928 59.0883 5.40918 71.518 6.08695 71.7259C6.76472 71.9338 10.4423 60.8155 11.6969 60.9755C12.9515 61.1354 11.5289 72.5876 12.697 72.9874C14.2256 73.4512 17.5084 60.9755 17.7247 60.6556C19.5128 57.4571 18.8351 55.8578 19.9454 52.8992C21.4019 48.7251 21.9558 48.0361 24.5515 43.926C26.5416 40.7274 27.2278 41.0966 29.4486 34.8755C32.2174 27.1351 34.5391 25.6158 33.8901 21.8575C33.6932 20.6495 33.2436 19.5095 32.5774 18.529C31.9112 17.5486 31.0471 16.7551 30.0543 16.2121C27.6028 15.1406 26.3914 18.4671 22.4402 18.675C20.4213 18.7869 20.479 18.0033 14.8117 16.5639C9.72126 15.2685 8.72624 18.0512 9.13001 20.4341Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="4" cy="72" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="13" cy="73" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2348)">
        <path d="M13 70L13.8229 73.7391L16.8567 71.4037L15.0837 74.797L18.9088 74.9581L15.3694 76.4178L18.1962 79L14.5465 77.8431L15.0521 81.6382L13 78.406L10.9479 81.6382L11.4535 77.8431L7.80385 79L10.6306 76.4178L7.09115 74.9581L10.9163 74.797L9.14327 71.4037L12.1771 73.7391L13 70Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2348" x="6.89113" y="69.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M4.62294 8.38768C2.56693 11.0039 1.33395 14.1716 1.08029 17.4893C0.800244 21.6901 2.48055 22.4322 3.06866 26.9691C3.13867 27.4592 3.19468 27.9913 3.23669 28.5934C3.55875 33.0322 2.48055 33.1582 2.7746 37.9891C3.09666 43.2821 4.4129 43.3661 4.77697 48.169C5.04302 51.6836 4.49692 54.7782 3.37671 60.9673C3.00356 64.3971 3.00356 67.8573 3.37671 71.2872C3.37671 71.7773 3.51674 73.0795 4.30088 73.4436C5.08503 73.8076 5.91118 72.7294 7.22742 72.9815C8.29161 73.1915 8.38963 74.0457 9.53784 74.5218C10.6129 74.8561 11.7731 74.7864 12.8004 74.3257C15.1372 73.4778 17.2092 72.0288 18.8075 70.125C23.5544 64.5239 25.3467 66.0642 29.2954 61.1913C32.9221 56.6965 34.0423 52.0757 35.4566 46.2366C37.0109 39.7814 35.8627 39.8654 37.613 32.6681C38.8592 27.5152 39.7834 26.1009 41.0016 20.1778C42.0798 14.9689 42.5979 12.3084 41.7717 9.85795C39.7834 3.90686 33.3702 1.80648 32.9921 1.68046C31.4518 1.20437 27.8252 0.0701634 25.1227 1.83449C23.1763 2.9967 23.1763 4.76102 21.58 4.99906C19.7177 5.27912 19.0876 2.96869 16.3431 2.38059C14.3127 1.94651 12.5764 2.77266 10.476 3.78084C8.20767 4.86368 6.20842 6.43725 4.62294 8.38768Z" fill="#DEE1E9"/>
      <mask id="mask2548" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M40.9674 20.1101C39.7492 26.0611 38.825 27.4614 37.5788 32.6003L35.7165 33.4405C33.4365 32.612 30.9479 32.553 28.6312 33.2725C24.3184 34.4067 25.2426 29.2117 18.2973 31.9842C11.352 34.7567 8.90158 31.9842 8.90158 31.9842L3.18854 28.5956C3.18854 27.9935 3.09052 27.4614 3.02051 26.9713C2.37639 22.4345 0.752092 21.6363 1.03214 17.4916C1.23944 14.5744 2.22057 11.7651 3.87449 9.35317C5.52842 6.9412 7.79535 5.01368 10.4419 3.76907C12.5282 2.77489 14.2646 1.94874 16.2949 2.36882C19.0954 2.95692 19.6835 5.26734 21.5459 4.98729C23.1282 4.74925 23.1842 2.98492 25.0885 1.7527C27.791 -0.0116233 31.4037 1.12258 32.944 1.59867C33.336 1.72469 39.7352 3.82508 41.7376 9.77617C42.5637 12.2406 42.0456 14.8591 40.9674 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2548)">
        <path d="M40.9674 20.1101C39.7492 26.0611 38.825 27.4614 37.5788 32.6003L35.7165 33.4405C33.4365 32.612 30.9479 32.553 28.6312 33.2725C24.3184 34.4067 25.2426 29.2117 18.2973 31.9842C11.352 34.7567 8.90158 31.9842 8.90158 31.9842L3.18854 28.5956C3.18854 27.9935 3.09052 27.4614 3.02051 26.9713C2.37639 22.4345 0.752092 21.6363 1.03214 17.4916C1.23944 14.5744 2.22057 11.7651 3.87449 9.35317C5.52842 6.9412 7.79535 5.01368 10.4419 3.76907C12.5282 2.77489 14.2646 1.94874 16.2949 2.36882C19.0954 2.95692 19.6835 5.26734 21.5459 4.98729C23.1282 4.74925 23.1842 2.98492 25.0885 1.7527C27.791 -0.0116233 31.4037 1.12258 32.944 1.59867C33.336 1.72469 39.7352 3.82508 41.7376 9.77617C42.5637 12.2406 42.0456 14.8591 40.9674 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.6923 19.7991C22.7544 19.7991 24 14.5008 24 4.90252L22.0769 3.04045L19 1.79907C19 11.3974 20.6302 19.7991 21.6923 19.7991Z" fill="#404040"/>
      </g>
      <circle cx="4" cy="71" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2648" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="75">
        <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
        <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.4365 32.813 30.9479 32.7539 28.6312 33.4734C24.3184 34.6076 25.2426 29.4126 18.2973 32.1852C11.352 34.9577 8.90158 32.1852 8.90158 32.1852L3.18854 28.7965C3.18854 28.1944 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2648)">
        <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
        <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.4365 32.813 30.9479 32.7539 28.6312 33.4734C24.3184 34.6076 25.2426 29.4126 18.2973 32.1852C11.352 34.9577 8.90158 32.1852 8.90158 32.1852L3.18854 28.7965C3.18854 28.1944 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M11.9741 20.6762H0.0529232C2.21498 26.046 2.57199 29.3063 2.27545 35.0446C2.26427 35.2609 2.51364 35.3955 2.70387 35.2689C3.70794 34.601 6.06614 32.7849 6.63778 30.1432C7.33025 26.9432 9.33345 26.1661 9.54606 26.092C9.55902 26.0875 9.57061 26.083 9.58299 26.0773L10.7659 25.5307C10.9105 25.4639 10.9691 25.3014 10.8968 25.1678L10.3679 24.1901C10.3231 24.1074 10.3272 24.0092 10.3787 23.9299L12.2251 21.0858C12.3421 20.9056 12.2016 20.6762 11.9741 20.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.04897 34.155C4.10995 33.3071 5.51793 31.8702 5.92035 30.0106C6.66356 26.5761 8.83953 25.6201 9.2725 25.4648L10.0461 25.1073L9.71349 24.4925C9.55687 24.203 9.57114 23.8596 9.75132 23.5821L11.1988 21.3524H1.09621C2.87294 26.0171 3.24894 29.2129 3.04897 34.155ZM9.25579 25.4725C9.25565 25.4726 9.25593 25.4724 9.25579 25.4725V25.4725ZM3.1313 35.8178C2.40244 36.3027 1.50671 35.7463 1.54464 35.0123C1.83745 29.3462 1.48824 26.18 -0.632782 20.9121L-1 20H11.9741C12.7703 20 13.2621 20.8028 12.8524 21.4338L11.1301 24.0869L11.5512 24.8654C11.8042 25.333 11.5991 25.9017 11.0931 26.1355L9.91021 26.6822C9.86949 26.701 9.83364 26.7145 9.80412 26.7248C9.76753 26.7376 7.98671 27.3577 7.35523 30.2758C6.72573 33.1849 4.16235 35.1319 3.1313 35.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask148" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="75">
        <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
        <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.4365 32.813 30.9479 32.7539 28.6312 33.4734C24.3184 34.6076 25.2426 29.4126 18.2973 32.1852C11.352 34.9577 8.90158 32.1852 8.90158 32.1852L3.18854 28.7965C3.18854 28.1944 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask148)">
        <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
        <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.4365 32.813 30.9479 32.7539 28.6312 33.4734C24.3184 34.6076 25.2426 29.4126 18.2973 32.1852C11.352 34.9577 8.90158 32.1852 8.90158 32.1852L3.18854 28.7965C3.18854 28.1944 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M9.86628 24.5H1.71952C3.19704 28.4705 3.44101 30.8811 3.23836 35.1241C3.23072 35.2841 3.40114 35.3836 3.53113 35.29C4.2173 34.7961 5.82886 33.4532 6.21952 31.5C6.69274 29.1339 8.0617 28.5593 8.20699 28.5045C8.21585 28.5012 8.22377 28.4978 8.23223 28.4936L9.04063 28.0894C9.13943 28.04 9.17947 27.9199 9.13008 27.8211L8.76862 27.0982C8.73804 27.037 8.74082 26.9645 8.776 26.9058L10.0378 24.8029C10.1178 24.6696 10.0217 24.5 9.86628 24.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76699 34.4664C4.49205 33.8394 5.45424 32.777 5.72925 31.4019C6.23715 28.8625 7.72418 28.1555 8.02006 28.0407L8.54872 27.7764L8.32143 27.3218C8.21439 27.1077 8.22415 26.8538 8.34728 26.6486L9.33644 25H2.4325C3.64669 28.4491 3.90365 30.8121 3.76699 34.4664ZM8.00864 28.0464C8.00854 28.0465 8.00874 28.0464 8.00864 28.0464V28.0464ZM3.82326 35.6958C3.32516 36.0544 2.71303 35.643 2.73895 35.1003C2.93905 30.9106 2.70041 28.5695 1.25093 24.6744L0.999982 24H9.8663C10.4104 24 10.7465 24.5936 10.4665 25.0601L9.2895 27.0219L9.57731 27.5975C9.7502 27.9433 9.61005 28.3638 9.26426 28.5367L8.45586 28.9409C8.42803 28.9548 8.40354 28.9648 8.38336 28.9724C8.35836 28.9818 7.14137 29.4403 6.70983 31.5981C6.27964 33.749 4.52786 35.1887 3.82326 35.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M4.62294 8.38768C2.56693 11.0039 1.33395 14.1716 1.08029 17.4893C0.800244 21.6901 2.48055 22.4322 3.06866 26.9691C3.13867 27.4592 3.19468 27.9913 3.23669 28.5934C3.55875 33.0322 2.48055 33.1582 2.7746 37.9891C3.09666 43.2821 4.4129 43.3661 4.77697 48.169C5.04302 51.6836 4.49692 54.7782 3.37671 60.9673C3.00356 64.3971 3.00356 67.8573 3.37671 71.2872C3.37671 71.7773 3.51674 73.0795 4.30088 73.4436C5.08503 73.8076 5.91118 72.7294 7.22742 72.9815C8.29161 73.1915 8.38963 74.0457 9.53784 74.5218C10.6129 74.8561 11.7731 74.7864 12.8004 74.3257C15.1372 73.4778 17.2092 72.0288 18.8075 70.125C23.5544 64.5239 25.3467 66.0642 29.2954 61.1913C32.9221 56.6965 34.0423 52.0757 35.4566 46.2366C37.0109 39.7814 35.8627 39.8654 37.613 32.6681C38.8592 27.5152 39.7834 26.1009 41.0016 20.1778C42.0798 14.9689 42.5979 12.3084 41.7717 9.85795C39.7834 3.90686 33.3702 1.80648 32.9921 1.68046C31.4518 1.20437 27.8252 0.0701634 25.1227 1.83449C23.1763 2.9967 23.1763 4.76102 21.58 4.99906C19.7177 5.27912 19.0876 2.96869 16.3431 2.38059C14.3127 1.94651 12.5764 2.77266 10.476 3.78084C8.20767 4.86368 6.20842 6.43725 4.62294 8.38768Z" fill="#DEE1E9"/>
      <mask id="mask248" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M40.9674 20.1101C39.7492 26.0611 38.825 27.4614 37.5788 32.6003L35.7165 33.4405C33.4365 32.612 30.9479 32.553 28.6312 33.2725C24.3184 34.4067 25.2426 29.2117 18.2973 31.9842C11.352 34.7567 8.90158 31.9842 8.90158 31.9842L3.18854 28.5956C3.18854 27.9935 3.09052 27.4614 3.02051 26.9713C2.37639 22.4345 0.752092 21.6363 1.03214 17.4916C1.23944 14.5744 2.22057 11.7651 3.87449 9.35317C5.52842 6.9412 7.79535 5.01368 10.4419 3.76907C12.5282 2.77489 14.2646 1.94874 16.2949 2.36882C19.0954 2.95692 19.6835 5.26734 21.5459 4.98729C23.1282 4.74925 23.1842 2.98492 25.0885 1.7527C27.791 -0.0116233 31.4037 1.12258 32.944 1.59867C33.336 1.72469 39.7352 3.82508 41.7376 9.77617C42.5637 12.2406 42.0456 14.8591 40.9674 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask248)">
        <path d="M40.9674 20.1101C39.7492 26.0611 38.825 27.4614 37.5788 32.6003L35.7165 33.4405C33.4365 32.612 30.9479 32.553 28.6312 33.2725C24.3184 34.4067 25.2426 29.2117 18.2973 31.9842C11.352 34.7567 8.90158 31.9842 8.90158 31.9842L3.18854 28.5956C3.18854 27.9935 3.09052 27.4614 3.02051 26.9713C2.37639 22.4345 0.752092 21.6363 1.03214 17.4916C1.23944 14.5744 2.22057 11.7651 3.87449 9.35317C5.52842 6.9412 7.79535 5.01368 10.4419 3.76907C12.5282 2.77489 14.2646 1.94874 16.2949 2.36882C19.0954 2.95692 19.6835 5.26734 21.5459 4.98729C23.1282 4.74925 23.1842 2.98492 25.0885 1.7527C27.791 -0.0116233 31.4037 1.12258 32.944 1.59867C33.336 1.72469 39.7352 3.82508 41.7376 9.77617C42.5637 12.2406 42.0456 14.8591 40.9674 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.8462 17C18.2351 17 13.5 9.53185 13.5 1L20.5385 2.10345L31 1C31 9.53185 25.4572 17 21.8462 17Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M8.13001 18.5897C8.8943 23.2413 6.83216 25.0623 7.17826 29.896C7.38014 32.8591 8.04349 32.7432 8.76452 36.5175C9.65859 41.1856 9.26924 45.2744 8.44726 53.2864C7.89928 58.6002 4.40918 71.466 5.08695 71.6812C5.76472 71.8964 9.44229 60.388 10.6969 60.5536C11.9515 60.7191 10.5289 72.5731 11.697 72.9869C13.2256 73.467 16.5084 60.5536 16.7247 60.2225C18.5128 56.9117 17.8351 55.2563 18.9454 52.1939C20.4019 47.8734 20.9558 47.1601 23.5515 42.9058C25.5416 39.5951 26.2278 39.9772 28.4486 33.5378C31.2174 25.5258 33.5391 23.9532 32.8901 20.063C32.6932 18.8126 32.2436 17.6326 31.5774 16.6178C30.9112 15.6029 30.0471 14.7816 29.0543 14.2195C26.6028 13.1104 25.3914 16.5536 21.4402 16.7688C19.4213 16.8847 19.479 16.0736 13.8117 14.5837C8.72126 13.2429 7.72624 16.1232 8.13001 18.5897Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M4.62294 8.38768C2.56693 11.0039 1.33395 14.1716 1.08029 17.4893C0.800244 21.6901 2.48055 22.4322 3.06866 26.9691C3.13867 27.4592 3.19468 27.9913 3.23669 28.5934C3.55875 33.0322 2.48055 33.1582 2.7746 37.9891C3.09666 43.2821 4.4129 43.3661 4.77697 48.169C5.04302 51.6836 4.49692 54.7782 3.37671 60.9673C3.00356 64.3971 3.00356 67.8573 3.37671 71.2872C3.37671 71.7773 3.51674 73.0795 4.30088 73.4436C5.08503 73.8076 5.91118 72.7294 7.22742 72.9815C8.29161 73.1915 8.38963 74.0457 9.53784 74.5218C10.6129 74.8561 11.7731 74.7864 12.8004 74.3257C15.1372 73.4778 17.2092 72.0288 18.8075 70.125C23.5544 64.5239 25.3467 66.0642 29.2954 61.1913C32.9221 56.6965 34.0423 52.0757 35.4566 46.2366C37.0109 39.7814 35.8627 39.8654 37.613 32.6681C38.8592 27.5152 39.7834 26.1009 41.0016 20.1778C42.0798 14.9689 42.5979 12.3084 41.7717 9.85795C39.7834 3.90686 33.3702 1.80648 32.9921 1.68046C31.4518 1.20437 27.8252 0.0701634 25.1227 1.83449C23.1763 2.9967 23.1763 4.76102 21.58 4.99906C19.7177 5.27912 19.0876 2.96869 16.3431 2.38059C14.3127 1.94651 12.5764 2.77266 10.476 3.78084C8.20767 4.86368 6.20842 6.43725 4.62294 8.38768Z" fill="#DEE1E9"/>
      <mask id="mask348" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M40.9674 20.1101C39.7492 26.0611 38.825 27.4614 37.5788 32.6003L35.7165 33.4405C33.4365 32.612 30.9479 32.553 28.6312 33.2725C24.3184 34.4067 25.2426 29.2117 18.2973 31.9842C11.352 34.7567 8.90158 31.9842 8.90158 31.9842L3.18854 28.5956C3.18854 27.9935 3.09052 27.4614 3.02051 26.9713C2.37639 22.4345 0.752092 21.6363 1.03214 17.4916C1.23944 14.5744 2.22057 11.7651 3.87449 9.35317C5.52842 6.9412 7.79535 5.01368 10.4419 3.76907C12.5282 2.77489 14.2646 1.94874 16.2949 2.36882C19.0954 2.95692 19.6835 5.26734 21.5459 4.98729C23.1282 4.74925 23.1842 2.98492 25.0885 1.7527C27.791 -0.0116233 31.4037 1.12258 32.944 1.59867C33.336 1.72469 39.7352 3.82508 41.7376 9.77617C42.5637 12.2406 42.0456 14.8591 40.9674 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask348)">
        <path d="M40.9674 20.1101C39.7492 26.0611 38.825 27.4614 37.5788 32.6003L35.7165 33.4405C33.4365 32.612 30.9479 32.553 28.6312 33.2725C24.3184 34.4067 25.2426 29.2117 18.2973 31.9842C11.352 34.7567 8.90158 31.9842 8.90158 31.9842L3.18854 28.5956C3.18854 27.9935 3.09052 27.4614 3.02051 26.9713C2.37639 22.4345 0.752092 21.6363 1.03214 17.4916C1.23944 14.5744 2.22057 11.7651 3.87449 9.35317C5.52842 6.9412 7.79535 5.01368 10.4419 3.76907C12.5282 2.77489 14.2646 1.94874 16.2949 2.36882C19.0954 2.95692 19.6835 5.26734 21.5459 4.98729C23.1282 4.74925 23.1842 2.98492 25.0885 1.7527C27.791 -0.0116233 31.4037 1.12258 32.944 1.59867C33.336 1.72469 39.7352 3.82508 41.7376 9.77617C42.5637 12.2406 42.0456 14.8591 40.9674 20.1101Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.6923 19.7991C22.7544 19.7991 24 14.5008 24 4.90252L22.0769 3.04045L19 1.79907C19 11.3974 20.6302 19.7991 21.6923 19.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
      <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.4365 32.813 30.9479 32.7539 28.6312 33.4734C24.3184 34.6076 25.2426 29.4126 18.2973 32.1852C11.352 34.9577 8.90158 32.1852 8.90158 32.1852L3.18854 28.7965C3.18854 28.1944 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M33 3L34.8667 5.50086L36.5 4.00515V7.3192L39.0622 6.75129L37.6667 8.50189L40 10.5026L37.6667 12.5033L39.0622 14.2539L36.7333 13.5036L36.5 17L34.2501 15.503L33.4667 16.5046L31.7499 15.503L29.5 17L28.8 15.0041L26.9378 14.2539L27.4 11.5029L26 10.5026L28.3333 9.00206L26.9378 6.75129L28.8 6.5012L29.5 4.00515L32.5333 6.00103L33 3Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
      <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.4365 32.813 30.9479 32.7539 28.6312 33.4734C24.3184 34.6076 25.2426 29.4126 18.2973 32.1852C11.352 34.9577 8.90158 32.1852 8.90158 32.1852L3.18854 28.7965C3.18854 28.1944 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M34 3L35.6 5.14359L37 3.86156V6.70217L39.1962 6.21539L38 7.71591L40 9.43078L38 11.1457L39.1962 12.6462L37.2 12.0031L37 15L35.0715 13.7168L34.4 14.5754L32.9285 13.7168L31 15L30.4 13.2892L28.8038 12.6462L29.2 10.2882L28 9.43078L30 8.14462L28.8038 6.21539L30.4 6.00103L31 3.86156L33.6 5.57231L34 3Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M4.62294 8.58861C2.56693 11.2048 1.33395 14.3725 1.08029 17.6903C0.800244 21.891 2.48055 22.6332 3.06866 27.17C3.13867 27.6601 3.19468 28.1922 3.23669 28.7943C3.55875 33.2331 2.48055 33.3591 2.7746 38.19C3.09666 43.483 4.4129 43.567 4.77697 48.3699C5.04302 51.8845 4.49692 54.9791 3.37671 61.1682C3.00356 64.5981 3.00356 68.0583 3.37671 71.4881C3.37671 71.9782 3.51674 73.2804 4.30088 73.6445C5.08503 74.0086 5.91118 72.9304 7.22742 73.1824C8.29161 73.3925 8.38963 74.2466 9.53784 74.7227C10.6129 75.057 11.7731 74.9873 12.8004 74.5267C15.1372 73.6787 17.2092 72.2298 18.8075 70.3259C23.5544 64.7249 25.3467 66.2652 29.2954 61.3923C32.9221 56.8974 34.0423 52.2766 35.4566 46.4375C37.0109 39.9823 35.8627 40.0664 37.613 32.869C38.8592 27.7161 39.7834 26.3018 41.0016 20.3788C42.0798 15.1698 42.5979 12.5093 41.7717 10.0589C39.7834 4.10779 33.3702 2.00741 32.9921 1.88139C31.4518 1.4053 27.8252 0.271091 25.1227 2.03541C23.1763 3.19763 23.1763 4.96195 21.58 5.19999C19.7177 5.48004 19.0876 3.16962 16.3431 2.58151C14.3127 2.14743 12.5764 2.97358 10.476 3.98177C8.20767 5.0646 6.20842 6.63818 4.62294 8.58861Z" fill="#DEE1E9"/>
      <path d="M40.9674 20.311C39.7492 26.2621 38.825 27.6623 37.5788 32.8013L35.7165 33.6414C33.4365 32.813 30.9479 32.7539 28.6312 33.4734C24.3184 34.6076 25.2426 29.4126 18.2973 32.1852C11.352 34.9577 8.90158 32.1852 8.90158 32.1852L3.18854 28.7965C3.18854 28.1944 3.09052 27.6623 3.02051 27.1722C2.37639 22.6354 0.752092 21.8373 1.03214 17.6925C1.23944 14.7753 2.22057 11.9661 3.87449 9.5541C5.52842 7.14213 7.79535 5.2146 10.4419 3.97C12.5282 2.97582 14.2646 2.14967 16.2949 2.56974C19.0954 3.15785 19.6835 5.46827 21.5459 5.18822C23.1282 4.95018 23.1842 3.18585 25.0885 1.95363C27.791 0.189304 31.4037 1.32351 32.944 1.7996C33.336 1.92562 39.7352 4.02601 41.7376 9.9771C42.5637 12.4415 42.0456 15.06 40.9674 20.311Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M35.5 3L36.4333 4.25043L37.25 3.50258V5.1596L38.5311 4.87564L37.8333 5.75094L39 6.75129L37.8333 7.75163L38.5311 8.62693L37.3667 8.2518L37.25 10L36.125 9.25148L35.7333 9.75232L34.875 9.25148L33.75 10L33.4 9.00206L32.4689 8.62693L32.7 7.25146L32 6.75129L33.1667 6.00103L32.4689 4.87564L33.4 4.7506L33.75 3.50258L35.2667 4.50052L35.5 3Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M16.7213 72.6565L18.805 73.9263H22.2887L24.3887 72.6565L22.7771 71.11L24.8608 69.5635L22.9725 68.017L25.2678 66.324L23.4446 64.7775L25.7399 63.3775L23.7213 61.9612L25.7399 60.3496L24.0469 58.803L26.2771 57.3868L24.519 55.9217L26.8143 54.3751L24.6655 52.8286L26.8143 51.217L25.2678 49.8659L27.3515 48.3193L25.5283 46.838L27.612 45.2914L25.805 43.5496L26.8143 41.8728V27.6124L24.9259 20.2868H18.805" fill="#8889BE"/>
      <path d="M16.7213 72.6565L18.805 73.9263H22.2887L24.3887 72.6565L22.7771 71.11L24.8608 69.5635L22.9725 68.017L25.2678 66.324L23.4446 64.7775L25.7399 63.3775L23.7213 61.9612L25.7399 60.3496L24.0469 58.803L26.2771 57.3868L24.519 55.9217L26.8143 54.3751L24.6655 52.8286L26.8143 51.217L25.2678 49.8659L27.3515 48.3193L25.5283 46.838L27.612 45.2914L25.805 43.5496L26.8143 41.8728V27.6124L24.9259 20.2868H18.805" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M24.1286 72.6565L22.0449 73.9263H18.5774L16.4612 72.6565L18.0891 71.11L15.9891 69.5635L17.8774 68.017L15.5984 66.324L17.4053 64.7775L15.1263 63.3775L17.1449 61.9612L15.1263 60.3496L16.803 58.803L14.5891 57.3868L16.3309 55.9217L14.0519 54.3751L16.2007 52.8286L14.0519 51.217L15.5984 49.8659L13.5147 48.3193L15.3216 46.838L13.2379 45.2914L15.0612 43.5496L14.0519 41.8728V27.6124L15.924 20.2868H19.8309" fill="#8889BE"/>
      <path d="M24.1286 72.6565L22.0449 73.9263H18.5774L16.4612 72.6565L18.0891 71.11L15.9891 69.5635L17.8774 68.017L15.5984 66.324L17.4053 64.7775L15.1263 63.3775L17.1449 61.9612L15.1263 60.3496L16.803 58.803L14.5891 57.3868L16.3309 55.9217L14.0519 54.3751L16.2007 52.8286L14.0519 51.217L15.5984 49.8659L13.5147 48.3193L15.3216 46.838L13.2379 45.2914L15.0612 43.5496L14.0519 41.8728V27.6124L15.924 20.2868H19.8309" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask548" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M40.9674 19.915C39.7492 25.8661 38.825 27.2663 37.5788 32.4053L35.7165 33.2454C33.7336 32.5249 31.228 31.878 29.0847 32.1949C28.5539 32.2734 28.0051 32.0058 27.8405 31.4951C26.1285 26.1809 25.9571 21.104 25.9571 21.104C21.076 19.2944 20.0799 19.2542 15.1988 21.104L12.7311 30.3037C12.6034 30.7798 12.1478 31.0918 11.6577 31.0388L3.02051 30.104C3.02051 29.5019 3.09052 27.2663 3.02051 26.7762C2.37639 22.2394 0.752092 21.4413 1.03214 17.2965C1.23944 14.3793 2.22057 11.5701 3.87449 9.1581C5.52842 6.74613 7.79535 4.81861 10.4419 3.574C12.5282 2.57982 14.2646 1.75367 16.2949 2.17375C19.0954 2.76185 19.6835 5.07227 21.5459 4.79222C23.1282 4.55418 23.1842 2.78986 25.0885 1.55763C27.791 -0.206692 31.4037 0.927515 32.944 1.4036C33.336 1.52962 39.7352 3.63001 41.7376 9.5811C42.5637 12.0456 42.0456 14.664 40.9674 19.915Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask548)">
        <path d="M40.9674 19.915C39.7492 25.8661 38.825 27.2663 37.5788 32.4053L35.7165 33.2454C33.7465 32.5296 31.8962 31.8865 29.9352 32.1888C29.4048 32.2706 28.8563 32.0058 28.6934 31.4945C27 26.1806 27 21.104 27 21.104C22.1189 19.2944 19.3811 19.2542 14.5 21.104L12.212 30.2559C12.0906 30.7416 11.6287 31.0627 11.1312 31.0072L3.02051 30.104C3.02051 29.5019 3.09052 27.2663 3.02051 26.7762C2.37639 22.2394 0.752092 21.4413 1.03214 17.2965C1.23944 14.3793 2.22057 11.5701 3.87449 9.1581C5.52842 6.74613 7.79535 4.81861 10.4419 3.574C12.5282 2.57982 14.2646 1.75367 16.2949 2.17375C19.0954 2.76185 19.6835 5.07227 21.5459 4.79222C23.1282 4.55418 23.1842 2.78986 25.0885 1.55763C27.791 -0.206692 31.4037 0.927515 32.944 1.4036C33.336 1.52962 39.7352 3.63001 41.7376 9.5811C42.5637 12.0456 42.0456 14.664 40.9674 19.915Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M32 43.604H8V18.056C17.3726 16.1298 22.6274 16.1102 32 18.056V43.604Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M16.8404 72.948L18.9241 74.2178H22.4078L24.5078 72.948L22.8962 71.4015L24.9799 69.855L23.0915 68.3085L25.3869 66.6155L23.5636 65.069L25.859 63.669L23.8404 62.2527L25.859 60.6411L24.166 59.0945L26.3962 57.6783L24.6381 56.2132L26.9334 54.6667L24.7846 53.1201L26.9334 51.5085L25.3869 50.1574L27.4706 48.6109L25.6474 47.1295L27.7311 45.5829L25.9241 43.8411L26.9334 42.1644V27.9039L25.045 20.5784H18.9241" fill="#8889BE"/>
      <path d="M16.8404 72.948L18.9241 74.2178H22.4078L24.5078 72.948L22.8962 71.4015L24.9799 69.855L23.0915 68.3085L25.3869 66.6155L23.5636 65.069L25.859 63.669L23.8404 62.2527L25.859 60.6411L24.166 59.0945L26.3962 57.6783L24.6381 56.2132L26.9334 54.6667L24.7846 53.1201L26.9334 51.5085L25.3869 50.1574L27.4706 48.6109L25.6474 47.1295L27.7311 45.5829L25.9241 43.8411L26.9334 42.1644V27.9039L25.045 20.5784H18.9241" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M24.2477 72.948L22.164 74.2178H18.6965L16.5802 72.948L18.2081 71.4015L16.1082 69.855L17.9965 68.3085L15.7175 66.6155L17.5244 65.069L15.2454 63.669L17.264 62.2527L15.2454 60.6411L16.9221 59.0945L14.7082 57.6783L16.45 56.2132L14.1709 54.6667L16.3198 53.1201L14.1709 51.5085L15.7175 50.1574L13.6337 48.6109L15.4407 47.1295L13.357 45.5829L15.1802 43.8411L14.1709 42.1644V27.9039L16.043 20.5784H19.95" fill="#8889BE"/>
      <path d="M24.2477 72.948L22.164 74.2178H18.6965L16.5802 72.948L18.2081 71.4015L16.1082 69.855L17.9965 68.3085L15.7175 66.6155L17.5244 65.069L15.2454 63.669L17.264 62.2527L15.2454 60.6411L16.9221 59.0945L14.7082 57.6783L16.45 56.2132L14.1709 54.6667L16.3198 53.1201L14.1709 51.5085L15.7175 50.1574L13.6337 48.6109L15.4407 47.1295L13.357 45.5829L15.1802 43.8411L14.1709 42.1644V27.9039L16.043 20.5783H19.95" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask548" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M41.0865 20.2065C39.8683 26.1576 38.9441 27.5578 37.6979 32.6968L35.8356 33.5369C33.8527 32.8164 31.3471 32.1695 29.2038 32.4864C28.673 32.5649 28.1243 32.2973 27.9584 31.7869C26.2352 26.4831 25.9584 21.5 25.9584 21.5C21.0774 19.6904 20.2864 19.6502 15.4053 21.5L12.8566 30.6066C12.7249 31.0774 12.2721 31.3839 11.786 31.3313L3.13959 30.3955C3.13959 29.7934 3.2096 27.5578 3.13959 27.0677C2.49547 22.5309 0.871171 21.7328 1.15122 17.588C1.35852 14.6708 2.33965 11.8616 3.99357 9.44961C5.6475 7.03764 7.91443 5.11011 10.5609 3.86551C12.6473 2.87132 14.3836 2.04517 16.414 2.46525C19.2145 3.05336 19.8026 5.36378 21.665 5.08373C23.2473 4.84568 23.3033 3.08136 25.2076 1.84913C27.9101 0.0848122 31.5228 1.21902 33.0631 1.69511C33.4551 1.82113 39.8543 3.92152 41.8567 9.87261C42.6828 12.3371 42.1647 14.9555 41.0865 20.2065Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask548)">
        <path d="M41.0865 20.2065C39.8683 26.1576 38.9441 27.5578 37.6979 32.6968L35.8356 33.5369C33.8656 32.8211 32.0153 32.178 30.0542 32.4803C29.5239 32.5621 28.9761 32.2973 28.8062 31.7883C27.032 26.4735 26.2874 21.3955 26.2874 21.3955C21.4063 19.5859 19.826 19.5457 14.9449 21.3955L12.3496 30.5798C12.2166 31.0504 11.7627 31.3558 11.2766 31.3017L3.13959 30.3955C3.13959 29.7934 3.2096 27.5578 3.13959 27.0677C2.49547 22.5309 0.871171 21.7328 1.15122 17.588C1.35852 14.6708 2.33965 11.8616 3.99357 9.44961C5.6475 7.03764 7.91443 5.11011 10.5609 3.86551C12.6473 2.87132 14.3836 2.04517 16.414 2.46525C19.2145 3.05336 19.8026 5.36378 21.665 5.08373C23.2473 4.84568 23.3033 3.08136 25.2076 1.84913C27.9101 0.0848122 31.5228 1.21902 33.0631 1.69511C33.4551 1.82113 39.8543 3.92152 41.8567 9.87261C42.6828 12.3371 42.1647 14.9555 41.0865 20.2065Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M22 0.259766H43V5.25977H22V0.259766Z" fill="#838AA2"/>
      <path d="M3.62294 10.2666C1.56693 12.8828 0.333955 16.0505 0.0802949 19.3683C-0.199756 23.569 1.48055 24.3112 2.06866 28.848C2.13867 29.3381 2.19468 29.8702 2.23669 30.4723C2.55875 34.9111 1.48055 35.0371 1.7746 39.868C2.09666 45.161 3.4129 45.245 3.77697 50.0479C4.04302 53.5625 3.49692 56.6571 2.37671 62.8462C2.00356 66.276 2.00356 69.7362 2.37671 73.1661C2.37671 73.6562 2.51674 74.9584 3.30088 75.3225C4.08503 75.6865 4.91118 74.6084 6.22742 74.8604C7.29161 75.0704 7.38963 75.9246 8.53784 76.4007C9.61294 76.735 10.7731 76.6653 11.8004 76.2046C14.1372 75.3567 16.2092 73.9077 17.8075 72.0039C22.5544 66.4029 24.3467 67.9431 28.2954 63.0702C31.9221 58.5754 33.0423 53.9546 34.4566 48.1155C36.0109 41.6603 34.8627 41.7443 36.613 34.547C37.8592 29.3941 38.7834 27.9798 40.0016 22.0567C41.0798 16.8478 41.5979 14.1873 40.7717 11.7369C38.7834 5.78577 32.3702 3.68539 31.9921 3.55936C30.4518 3.08328 26.8252 1.94907 24.1227 3.71339C22.1763 4.8756 22.1763 6.63993 20.58 6.87797C18.7177 7.15802 18.0876 4.8476 15.3431 4.25949C13.3127 3.82541 11.5764 4.65156 9.47601 5.65975C7.20767 6.74258 5.20842 8.31615 3.62294 10.2666Z" fill="#DEE1E9"/>
      <path d="M39.9674 21.9887C38.7492 27.9398 37.825 29.3401 36.5788 34.479L34.7165 35.3191C32.7465 34.6034 30.8962 33.9602 28.9352 34.2626C28.4048 34.3443 27.8563 34.0796 27.6934 33.5683C26 28.2543 26 23.1777 26 23.1777C21.1189 21.3682 18.3811 21.328 13.5 23.1777L11.212 32.3297C11.0906 32.8153 10.6287 33.1364 10.1312 33.081L2.02051 32.1777C2.02051 31.5756 2.09052 29.3401 2.02051 28.85C1.37639 24.3131 -0.247908 23.515 0.0321428 19.3702C0.239445 16.453 1.22057 13.6438 2.87449 11.2318C4.52842 8.81986 6.79535 6.89234 9.44186 5.64773C11.5282 4.65355 13.2646 3.8274 15.2949 4.24748C18.0954 4.83558 18.6835 7.14601 20.5459 6.86595C22.1282 6.62791 22.1842 4.86359 24.0885 3.63136C26.791 1.86704 30.4037 3.00125 31.944 3.47733C32.336 3.60336 38.7352 5.70374 40.7376 11.6548C41.5637 14.1193 41.0456 16.7378 39.9674 21.9887Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M4.62294 9.01171C2.56693 11.6279 1.33395 14.7956 1.08029 18.1134C0.800244 22.3141 2.48055 23.0563 3.06866 27.5931C3.13867 28.0832 3.19468 28.6153 3.23669 29.2174C3.55875 33.6562 2.48055 33.7822 2.7746 38.6131C3.09666 43.9061 4.4129 43.9901 4.77697 48.793C5.04302 52.3076 4.49692 55.4022 3.37671 61.5913C3.00356 65.0212 3.00356 68.4814 3.37671 71.9112C3.37671 72.4013 3.51674 73.7035 4.30088 74.0676C5.08503 74.4317 5.91118 73.3535 7.22742 73.6055C8.29161 73.8156 8.38963 74.6697 9.53784 75.1458C10.6129 75.4801 11.7731 75.4104 12.8004 74.9498C15.1372 74.1018 17.2092 72.6528 18.8075 70.749C23.5544 65.148 25.3467 66.6882 29.2954 61.8154C32.9221 57.3205 34.0423 52.6997 35.4566 46.8606C37.0109 40.4054 35.8627 40.4895 37.613 33.2921C38.8592 28.1392 39.7834 26.7249 41.0016 20.8019C42.0798 15.5929 42.5979 12.9324 41.7717 10.482C39.7834 4.53089 33.3702 2.4305 32.9921 2.30448C31.4518 1.82839 27.8252 0.694187 25.1227 2.45851C23.1763 3.62072 23.1763 5.38504 21.58 5.62309C19.7177 5.90314 19.0876 3.59272 16.3431 3.00461C14.3127 2.57053 12.5764 3.39668 10.476 4.40486C8.20767 5.4877 6.20842 7.06127 4.62294 9.01171Z" fill="#DEE1E9"/>
      <mask id="mask548" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="35">
        <path d="M40.9674 20.7338C39.7492 26.6849 38.825 28.0852 37.5788 33.2241L35.7165 34.0643C33.4365 33.2358 30.9479 33.1768 28.6312 33.8962C24.3184 35.0304 25.2426 29.8355 18.2973 32.608C11.352 35.3805 8.90158 32.608 8.90158 32.608L3.18854 29.2194C3.18854 28.6173 3.09052 28.0852 3.02051 27.5951C2.37639 23.0583 0.752092 22.2601 1.03214 18.1154C1.23944 15.1981 2.22057 12.3889 3.87449 9.97695C5.52842 7.56498 7.79535 5.63745 10.4419 4.39285C12.5282 3.39867 14.2646 2.57252 16.2949 2.99259C19.0954 3.5807 19.6835 5.89112 21.5459 5.61107C23.1282 5.37303 23.1842 3.6087 25.0885 2.37648C27.791 0.612156 31.4037 1.74636 32.944 2.22245C33.336 2.34847 39.7352 4.44886 41.7376 10.3999C42.5637 12.8644 42.0456 15.4829 40.9674 20.7338Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask548)">
        <path d="M40.9674 20.7338C39.7492 26.6849 38.825 28.0852 37.5788 33.2241L35.7165 34.0643C33.4365 33.2358 30.9479 33.1768 28.6312 33.8962C24.3184 35.0304 25.2426 29.8355 18.2973 32.608C11.352 35.3805 8.90158 32.608 8.90158 32.608L3.18854 29.2194C3.18854 28.6173 3.09052 28.0852 3.02051 27.5951C2.37639 23.0583 0.752092 22.2601 1.03214 18.1154C1.23944 15.1981 2.22057 12.3889 3.87449 9.97695C5.52842 7.56498 7.79535 5.63745 10.4419 4.39285C12.5282 3.39867 14.2646 2.57252 16.2949 2.99259C19.0954 3.5807 19.6835 5.89112 21.5459 5.61107C23.1282 5.37303 23.1842 3.6087 25.0885 2.37648C27.791 0.612156 31.4037 1.74636 32.944 2.22245C33.336 2.34847 39.7352 4.44886 41.7376 10.3999C42.5637 12.8644 42.0456 15.4829 40.9674 20.7338Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <g opacity="0.2">
        <path d="M8.76451 38.177C8.04348 34.5307 7.38014 34.6426 7.17825 31.78C6.83216 27.1101 8.8943 25.3509 8.13001 20.857C7.72623 18.4741 8.72125 15.6914 13.8117 16.9868C19.479 18.4261 19.4213 19.2098 21.4402 19.0978C25.3914 18.8899 26.6028 15.5634 29.0543 16.6349C30.0471 17.1779 30.9112 17.9715 31.5774 18.9519C32.2436 19.9323 32.6932 21.0723 32.8901 22.2803C33.5391 26.0386 31.2174 27.5579 28.4486 35.2983L25.5 44.6933C25.5721 47.9818 25.1759 49.9011 24.7798 53.3781C24.459 56.1944 24.2853 57.8651 22.5474 62.3797C21.0229 66.4747 18.2317 69.5137 17.5 69.5137C16.7682 69.5137 18.5 66.5137 19.5441 64.689C20.5 61.5137 20.7485 60.9464 21.0686 57.2206C21.2058 55.5423 20.5564 52.9535 19.9313 52.7857C19.0319 52.5508 17.2634 57.3842 16.8823 58.3408C13.315 67.991 11.1434 71.3123 10 71.5137C8.85662 71.7151 11.0212 69.2638 12.5 62.0137C13.0793 59.1774 13.5245 57.9221 14.7746 54.2971C17.0309 47.8189 15.7694 41.5413 15.3425 41.4238C14.9157 41.3063 11.8083 56.008 8.28673 63.5603C7.23482 65.8092 4.84744 68.6815 4.23764 68.5137C3.62784 68.3458 7.39886 64.7354 7.49033 58.5425C7.49033 54.1454 8.95121 44.1932 8.73778 37.5137" stroke="#F32A66" fill="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M4.62294 8.10253C2.56693 10.7187 1.33395 13.8865 1.08029 17.2042C0.800244 21.405 2.48055 22.1471 3.06866 26.6839C3.13867 27.174 3.19468 27.7061 3.23669 28.3082C3.55875 32.747 2.48055 32.8731 2.7746 37.7039C3.09666 42.9969 4.4129 43.0809 4.77697 47.8838C5.04302 51.3984 4.49692 54.493 3.37671 60.6821C3.00356 64.112 3.00356 67.5722 3.37671 71.002C3.37671 71.4921 3.51674 72.7943 4.30088 73.1584C5.08503 73.5225 5.91118 72.4443 7.22742 72.6963C8.29161 72.9064 8.38963 73.7605 9.53784 74.2366C10.6129 74.5709 11.7731 74.5012 12.8004 74.0406C15.1372 73.1926 17.2092 71.7437 18.8075 69.8398C23.5544 64.2388 25.3467 65.7791 29.2954 60.9062C32.9221 56.4114 34.0423 51.7905 35.4566 45.9514C37.0109 39.4963 35.8627 39.5803 37.613 32.383C38.8592 27.23 39.7834 25.8158 41.0016 19.8927C42.0798 14.6837 42.5979 12.0232 41.7717 9.5728C39.7834 3.62171 33.3702 1.52132 32.9921 1.3953C31.4518 0.919214 27.8252 -0.214993 25.1227 1.54933C23.1763 2.71154 23.1763 4.47586 21.58 4.71391C19.7177 4.99396 19.0876 2.68354 16.3431 2.09543C14.3127 1.66135 12.5764 2.4875 10.476 3.49569C8.20767 4.57852 6.20842 6.15209 4.62294 8.10253Z" fill="#DEE1E9"/>
      <mask id="mask548" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M40.9674 19.8247C39.7492 25.7757 38.825 27.176 37.5788 32.3149L35.7165 33.1551C33.4365 32.3266 30.9479 32.2676 28.6312 32.9871C24.3184 34.1213 25.2426 28.9263 18.2973 31.6988C11.352 34.4713 8.90158 31.6988 8.90158 31.6988L3.18854 28.3102C3.18854 27.7081 3.09052 27.176 3.02051 26.6859C2.37639 22.1491 0.752092 21.3509 1.03214 17.2062C1.23944 14.289 2.22057 11.4797 3.87449 9.06777C5.52842 6.6558 7.79535 4.72827 10.4419 3.48367C12.5282 2.48949 14.2646 1.66334 16.2949 2.08341C19.0954 2.67152 19.6835 4.98194 21.5459 4.70189C23.1282 4.46385 23.1842 2.69952 25.0885 1.4673C27.791 -0.297024 31.4037 0.837183 32.944 1.31327C33.336 1.43929 39.7352 3.53968 41.7376 9.49077C42.5637 11.9552 42.0456 14.5737 40.9674 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask548)">
        <path d="M40.9674 19.8247C39.7492 25.7757 38.825 27.176 37.5788 32.3149L35.7165 33.1551C33.4365 32.3266 30.9479 32.2676 28.6312 32.9871C24.3184 34.1213 25.2426 28.9263 18.2973 31.6988C11.352 34.4713 8.90158 31.6988 8.90158 31.6988L3.18854 28.3102C3.18854 27.7081 3.09052 27.176 3.02051 26.6859C2.37639 22.1491 0.752092 21.3509 1.03214 17.2062C1.23944 14.289 2.22057 11.4797 3.87449 9.06777C5.52842 6.6558 7.79535 4.72827 10.4419 3.48367C12.5282 2.48949 14.2646 1.66334 16.2949 2.08341C19.0954 2.67152 19.6835 4.98194 21.5459 4.70189C23.1282 4.46385 23.1842 2.69952 25.0885 1.4673C27.791 -0.297024 31.4037 0.837183 32.944 1.31327C33.336 1.43929 39.7352 3.53968 41.7376 9.49077C42.5637 11.9552 42.0456 14.5737 40.9674 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <g opacity="0.4">
        <path d="M7.17825 30.8708C6.83216 26.2009 8.8943 24.4417 8.13001 19.9478C7.72623 17.5649 8.72125 14.7822 13.8117 16.0776C19.479 17.5169 19.4213 18.3006 21.4402 18.1886C25.3914 17.9807 26.6028 14.6543 29.0543 15.7258C30.0471 16.2687 30.9112 17.0623 31.5774 18.0427C32.2436 19.0231 32.6932 20.1631 32.8901 21.3712C33.5391 25.1294 31.2174 26.6487 28.4486 34.3892L25.5 43.7841C25.5721 47.0727 25.1759 48.9919 24.7798 52.4689L24.767 52.5812C24.454 55.3304 24.2621 57.0162 22.5474 61.4705C21.0229 65.5655 18.2317 68.6045 17.5 68.6045C16.7826 68.6045 18.4328 65.7214 19.4817 63.8888L19.5441 63.7798C20.5 60.6045 20.7485 60.0372 21.0686 56.3114C21.2058 54.6332 20.5564 52.0444 19.9313 51.8765C19.0319 51.6416 17.2634 56.475 16.8823 57.4317C13.315 67.0818 11.1434 70.4031 10 70.6045C8.85662 70.8059 11.0212 68.3547 12.5 61.1045C13.0793 58.2682 13.5245 57.013 14.7746 53.3879C17.0309 46.9097 15.7694 40.6321 15.3425 40.5146C14.9157 40.3971 11.8083 55.0989 8.28673 62.6511C7.23482 64.9 4.84744 67.7723 4.23764 67.6045C3.62784 67.4367 7.39886 63.8262 7.49033 57.6333C7.49033 53.3666 8.86586 43.8693 8.75243 37.207C8.03608 33.6227 7.37901 33.7175 7.17825 30.8708Z" fill="#462AF3"/>
        <path d="M8.76451 37.2678C8.04348 33.6215 7.38014 33.7335 7.17825 30.8708C6.83216 26.2009 8.8943 24.4417 8.13001 19.9478C7.72623 17.5649 8.72125 14.7822 13.8117 16.0776C19.479 17.5169 19.4213 18.3006 21.4402 18.1886C25.3914 17.9807 26.6028 14.6543 29.0543 15.7258C30.0471 16.2687 30.9112 17.0623 31.5774 18.0427C32.2436 19.0231 32.6932 20.1631 32.8901 21.3712C33.5391 25.1294 31.2174 26.6487 28.4486 34.3892L25.5 43.7841C25.5721 47.0727 25.1759 48.9919 24.7798 52.4689C24.459 55.2852 24.2853 56.9559 22.5474 61.4705C21.0229 65.5655 18.2317 68.6045 17.5 68.6045C16.7682 68.6045 18.5 65.6045 19.5441 63.7798C20.5 60.6045 20.7485 60.0372 21.0686 56.3114C21.2058 54.6332 20.5564 52.0444 19.9313 51.8765C19.0319 51.6416 17.2634 56.475 16.8823 57.4317C13.315 67.0818 11.1434 70.4031 10 70.6045C8.85662 70.8059 11.0212 68.3547 12.5 61.1045C13.0793 58.2682 13.5245 57.013 14.7746 53.3879C17.0309 46.9097 15.7694 40.6321 15.3425 40.5146C14.9157 40.3971 11.8083 55.0989 8.28673 62.6511C7.23482 64.9 4.84744 67.7723 4.23764 67.6045C3.62784 67.4367 7.39886 63.8262 7.49033 57.6333C7.49033 53.2362 8.95121 43.284 8.73778 36.6045" stroke="#462AF3" fill="#462AF3" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

      <path d="M4.62294 8.10253C2.56693 10.7187 1.33395 13.8865 1.08029 17.2042C0.800244 21.405 2.48055 22.1471 3.06866 26.6839C3.13867 27.174 3.19468 27.7061 3.23669 28.3082C3.55875 32.747 2.48055 32.8731 2.7746 37.7039C3.09666 42.9969 4.4129 43.0809 4.77697 47.8838C5.04302 51.3984 4.49692 54.493 3.37671 60.6821C3.00356 64.112 3.00356 67.5722 3.37671 71.002C3.37671 71.4921 3.51674 72.7943 4.30088 73.1584C5.08503 73.5225 5.91118 72.4443 7.22742 72.6963C8.29161 72.9064 8.38963 73.7605 9.53784 74.2366C10.6129 74.5709 11.7731 74.5012 12.8004 74.0406C15.1372 73.1926 17.2092 71.7437 18.8075 69.8398C23.5544 64.2388 25.3467 65.7791 29.2954 60.9062C32.9221 56.4114 34.0423 51.7905 35.4566 45.9514C37.0109 39.4963 35.8627 39.5803 37.613 32.383C38.8592 27.23 39.7834 25.8158 41.0016 19.8927C42.0798 14.6837 42.5979 12.0232 41.7717 9.5728C39.7834 3.62171 33.3702 1.52132 32.9921 1.3953C31.4518 0.919214 27.8252 -0.214993 25.1227 1.54933C23.1763 2.71154 23.1763 4.47586 21.58 4.71391C19.7177 4.99396 19.0876 2.68354 16.3431 2.09543C14.3127 1.66135 12.5764 2.4875 10.476 3.49569C8.20767 4.57852 6.20842 6.15209 4.62294 8.10253Z" fill="#DEE1E9"/>
      <mask id="mask548" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="43" height="34">
        <path d="M40.9674 19.8247C39.7492 25.7757 38.825 27.176 37.5788 32.3149L35.7165 33.1551C33.4365 32.3266 30.9479 32.2676 28.6312 32.9871C24.3184 34.1213 25.2426 28.9263 18.2973 31.6988C11.352 34.4713 8.90158 31.6988 8.90158 31.6988L3.18854 28.3102C3.18854 27.7081 3.09052 27.176 3.02051 26.6859C2.37639 22.1491 0.752092 21.3509 1.03214 17.2062C1.23944 14.289 2.22057 11.4797 3.87449 9.06777C5.52842 6.6558 7.79535 4.72827 10.4419 3.48367C12.5282 2.48949 14.2646 1.66334 16.2949 2.08341C19.0954 2.67152 19.6835 4.98194 21.5459 4.70189C23.1282 4.46385 23.1842 2.69952 25.0885 1.4673C27.791 -0.297024 31.4037 0.837183 32.944 1.31327C33.336 1.43929 39.7352 3.53968 41.7376 9.49077C42.5637 11.9552 42.0456 14.5737 40.9674 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask548)">
        <path d="M40.9674 19.8247C39.7492 25.7757 38.825 27.176 37.5788 32.3149L35.7165 33.1551C33.4365 32.3266 30.9479 32.2676 28.6312 32.9871C24.3184 34.1213 25.2426 28.9263 18.2973 31.6988C11.352 34.4713 8.90158 31.6988 8.90158 31.6988L3.18854 28.3102C3.18854 27.7081 3.09052 27.176 3.02051 26.6859C2.37639 22.1491 0.752092 21.3509 1.03214 17.2062C1.23944 14.289 2.22057 11.4797 3.87449 9.06777C5.52842 6.6558 7.79535 4.72827 10.4419 3.48367C12.5282 2.48949 14.2646 1.66334 16.2949 2.08341C19.0954 2.67152 19.6835 4.98194 21.5459 4.70189C23.1282 4.46385 23.1842 2.69952 25.0885 1.4673C27.791 -0.297024 31.4037 0.837183 32.944 1.31327C33.336 1.43929 39.7352 3.53968 41.7376 9.49077C42.5637 11.9552 42.0456 14.5737 40.9674 19.8247Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5048" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="36" width="29" height="33">
        <rect width="29" height="32" transform="matrix(-1 0 0 1 35 36.5137)" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5048)">
        <g opacity="0.4">
          <path d="M9.00729 27.8708C8.6612 23.2009 10.7233 21.4417 9.95905 16.9478C9.55527 14.5649 10.5503 11.7822 15.6408 13.0776C21.308 14.5169 21.2504 15.3006 23.2692 15.1886C27.2205 14.9807 28.4318 11.6543 30.8833 12.7258C31.8761 13.2687 32.7403 14.0623 33.4064 15.0427C34.0726 16.0231 34.5222 17.1631 34.7192 18.3712C35.3681 22.1294 33.0464 23.6487 30.2776 31.3892L27.329 40.7841C27.4011 44.0727 27.005 45.9919 26.6088 49.4689L26.5961 49.5812C26.2831 52.3304 26.0912 54.0162 24.3764 58.4705C22.8519 62.5655 20.0608 65.6045 19.329 65.6045C18.6117 65.6045 20.2619 62.7214 21.3107 60.8888L21.3731 60.7798C22.329 57.6045 22.5775 57.0372 22.8977 53.3114C23.0349 51.6332 22.3854 49.0444 21.7604 48.8765C20.8609 48.6416 19.0925 53.475 18.7113 54.4317C15.144 64.0818 12.9724 67.4031 11.829 67.6045C10.6857 67.8059 12.8503 65.3547 14.329 58.1045C14.9084 55.2682 15.3535 54.013 16.6036 50.3879C18.8599 43.9097 17.5984 37.6321 17.1716 37.5146C16.7447 37.3971 13.6374 52.0989 10.1158 59.6511C9.06386 61.9 6.67648 64.7723 6.06668 64.6045C5.45688 64.4367 9.2279 60.8262 9.31937 54.6333C9.31937 50.3666 10.6949 40.8693 10.5815 34.207C9.86512 30.6227 9.20805 30.7175 9.00729 27.8708Z" fill="#462AF3"/>
          <path d="M10.5936 34.2678C9.87253 30.6215 9.20918 30.7335 9.00729 27.8708C8.6612 23.2009 10.7233 21.4417 9.95905 16.9478C9.55527 14.5649 10.5503 11.7822 15.6408 13.0776C21.308 14.5169 21.2504 15.3006 23.2692 15.1886C27.2205 14.9807 28.4318 11.6543 30.8833 12.7258C31.8761 13.2687 32.7403 14.0623 33.4064 15.0427C34.0726 16.0231 34.5222 17.1631 34.7192 18.3712C35.3681 22.1294 33.0464 23.6487 30.2776 31.3892L27.329 40.7841C27.4011 44.0727 27.005 45.9919 26.6088 49.4689C26.288 52.2852 26.1144 53.9559 24.3764 58.4705C22.8519 62.5655 20.0608 65.6045 19.329 65.6045C18.5973 65.6045 20.329 62.6045 21.3731 60.7798C22.329 57.6045 22.5775 57.0372 22.8977 53.3114C23.0349 51.6332 22.3854 49.0444 21.7604 48.8765C20.8609 48.6416 19.0925 53.475 18.7113 54.4317C15.144 64.0818 12.9724 67.4031 11.829 67.6045C10.6857 67.8059 12.8503 65.3547 14.329 58.1045C14.9084 55.2682 15.3535 54.013 16.6036 50.3879C18.8599 43.9097 17.5984 37.6321 17.1716 37.5146C16.7447 37.3971 13.6374 52.0989 10.1158 59.6511C9.06386 61.9 6.67648 64.7723 6.06668 64.6045C5.45688 64.4367 9.2279 60.8262 9.31937 54.6333C9.31937 50.2362 10.7802 40.284 10.5668 33.6045" stroke="#462AF3" fill="#462AF3" stroke-miterlimit="10"/>
        </g>
      </g>
      <mask id="mask50048" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="1" width="30" height="36">
        <rect width="29.1809" height="35.4105" transform="matrix(-1 0 0 1 34.9517 1.10352)" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask50048)">
        <g opacity="0.2">
          <path d="M9.00729 27.8708C8.6612 23.2009 10.7233 21.4417 9.95905 16.9478C9.55527 14.5649 10.5503 11.7822 15.6408 13.0776C21.308 14.5169 21.2504 15.3006 23.2692 15.1886C27.2205 14.9807 28.4318 11.6543 30.8833 12.7258C31.8761 13.2687 32.7403 14.0623 33.4064 15.0427C34.0726 16.0231 34.5222 17.1631 34.7192 18.3712C35.3681 22.1294 33.0464 23.6487 30.2776 31.3892L27.329 40.7841C27.4011 44.0727 27.005 45.9919 26.6088 49.4689L26.5961 49.5812C26.2831 52.3304 26.0912 54.0162 24.3764 58.4705C22.8519 62.5655 20.0608 65.6045 19.329 65.6045C18.6117 65.6045 20.2619 62.7214 21.3107 60.8888L21.3731 60.7798C22.329 57.6045 22.5775 57.0372 22.8977 53.3114C23.0349 51.6332 22.3854 49.0444 21.7604 48.8765C20.8609 48.6416 19.0925 53.475 18.7113 54.4317C15.144 64.0818 12.9724 67.4031 11.829 67.6045C10.6857 67.8059 12.8503 65.3547 14.329 58.1045C14.9084 55.2682 15.3535 54.013 16.6036 50.3879C18.8599 43.9097 17.5984 37.6321 17.1716 37.5146C16.7447 37.3971 13.6374 52.0989 10.1158 59.6511C9.06386 61.9 6.67648 64.7723 6.06668 64.6045C5.45688 64.4367 9.2279 60.8262 9.31937 54.6333C9.31937 50.3666 10.6949 40.8693 10.5815 34.207C9.86512 30.6227 9.20805 30.7175 9.00729 27.8708Z" fill="#F32A66"/>
          <path d="M10.5936 34.2678C9.87253 30.6215 9.20918 30.7335 9.00729 27.8708C8.6612 23.2009 10.7233 21.4417 9.95905 16.9478C9.55527 14.5649 10.5503 11.7822 15.6408 13.0776C21.308 14.5169 21.2504 15.3006 23.2692 15.1886C27.2205 14.9807 28.4318 11.6543 30.8833 12.7258C31.8761 13.2687 32.7403 14.0623 33.4064 15.0427C34.0726 16.0231 34.5222 17.1631 34.7192 18.3712C35.3681 22.1294 33.0464 23.6487 30.2776 31.3892L27.329 40.7841C27.4011 44.0727 27.005 45.9919 26.6088 49.4689C26.288 52.2852 26.1144 53.9559 24.3764 58.4705C22.8519 62.5655 20.0608 65.6045 19.329 65.6045C18.5973 65.6045 20.329 62.6045 21.3731 60.7798C22.329 57.6045 22.5775 57.0372 22.8977 53.3114C23.0349 51.6332 22.3854 49.0444 21.7604 48.8765C20.8609 48.6416 19.0925 53.475 18.7113 54.4317C15.144 64.0818 12.9724 67.4031 11.829 67.6045C10.6857 67.8059 12.8503 65.3547 14.329 58.1045C14.9084 55.2682 15.3535 54.013 16.6036 50.3879C18.8599 43.9097 17.5984 37.6321 17.1716 37.5146C16.7447 37.3971 13.6374 52.0989 10.1158 59.6511C9.06386 61.9 6.67648 64.7723 6.06668 64.6045C5.45688 64.4367 9.2279 60.8262 9.31937 54.6333C9.31937 50.2362 10.7802 40.284 10.5668 33.6045" stroke="#F32A66" fill="#F32A66" stroke-miterlimit="10"/>
        </g>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M3.80678 8.05004C1.75077 10.6662 0.517793 13.834 0.264133 17.1517C-0.0159184 21.3525 1.66439 22.0946 2.25249 26.6314C2.32251 27.1215 2.37852 27.6536 2.42053 28.2557C2.74259 32.6945 1.66439 32.8206 1.95844 37.6514C2.2805 42.9444 3.59674 43.0284 3.96081 47.8313C4.22686 51.346 3.68076 54.4405 2.56055 60.6296C2.18739 64.0595 2.18739 67.5197 2.56055 70.9495C2.56055 71.4396 2.70058 72.7419 3.48472 73.1059C4.26886 73.47 5.09502 72.3918 6.41126 72.6438C7.47545 72.8539 7.57347 73.708 8.72168 74.1841C9.79678 74.5184 10.9569 74.4487 11.9843 73.9881C14.321 73.1401 16.393 71.6912 17.9914 69.7873C22.7382 64.1863 24.5306 65.7266 28.4793 60.8537C32.1059 56.3589 33.2262 51.738 34.6404 45.899C36.1947 39.4438 35.0465 39.5278 36.7968 32.3305C38.043 27.1775 38.9672 25.7633 40.1854 19.8402C41.2636 14.6312 41.7817 11.9708 40.9556 9.52031C38.9672 3.56922 32.554 1.46883 32.176 1.34281C30.6357 0.866724 27.009 -0.267483 24.3065 1.49684C22.3602 2.65905 22.3602 4.42337 20.7639 4.66142C18.9015 4.94147 18.2714 2.63105 15.5269 2.04294C13.4966 1.60886 11.7602 2.43501 9.65985 3.44319C7.3915 4.52603 5.39225 6.0996 3.80678 8.05004Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M4.80678 8.68199C2.75077 11.2982 1.51779 14.4659 1.26413 17.7837C0.984082 21.9844 2.66439 22.7266 3.25249 27.2634C3.32251 27.7535 3.37852 28.2856 3.42053 28.8877C3.74259 33.3265 2.66439 33.4525 2.95844 38.2834C3.2805 43.5764 4.59674 43.6604 4.96081 48.4633C5.22686 51.9779 4.68076 55.0725 3.56055 61.2616C3.18739 64.6914 3.18739 68.1516 3.56055 71.5815C3.56055 72.0716 3.70058 73.3738 4.48472 73.7379C5.26886 74.102 6.09502 73.0238 7.41126 73.2758C8.47545 73.4858 8.57347 74.34 9.72168 74.8161C10.7968 75.1504 11.9569 75.0807 12.9843 74.62C15.321 73.7721 17.393 72.3231 18.9914 70.4193C23.7382 64.8183 25.5306 66.3585 29.4793 61.4856C33.1059 56.9908 34.2262 52.37 35.6404 46.5309C37.1947 40.0757 36.0465 40.1597 37.7968 32.9624C39.043 27.8095 39.9672 26.3952 41.1854 20.4722C42.2636 15.2632 42.7817 12.6027 41.9556 10.1523C39.9672 4.20118 33.554 2.10079 33.176 1.97477C31.6357 1.49868 28.009 0.364475 25.3065 2.1288C23.3602 3.29101 23.3602 5.05533 21.7639 5.29338C19.9015 5.57343 19.2714 3.263 16.5269 2.6749C14.4966 2.24082 12.7602 3.06697 10.6598 4.07515C8.3915 5.15799 6.39225 6.73156 4.80678 8.68199Z" fill="#DEE1E9"/>
      <path d="M41.1513 20.4044C39.9331 26.3555 39.0089 27.7557 37.7627 32.8946L35.9003 33.7348C33.6203 32.9063 31.1317 32.8473 28.815 33.5668C24.5022 34.701 25.4264 29.506 18.4811 32.2785C11.5359 35.051 9.08542 32.2785 9.08542 32.2785L3.37237 28.8899C3.37237 28.2878 3.27436 27.7557 3.20434 27.2656C2.56023 22.7288 0.93593 21.9306 1.21598 17.7859C1.42328 14.8687 2.40441 12.0595 4.05833 9.64748C5.71225 7.23551 7.97919 5.30799 10.6257 4.06338C12.7121 3.0692 14.4484 2.24305 16.4788 2.66313C19.2793 3.25123 19.8674 5.56165 21.7297 5.2816C23.312 5.04356 23.368 3.27924 25.2724 2.04701C27.9749 0.282688 31.5875 1.41689 33.1278 1.89298C33.5199 2.019 39.919 4.11939 41.9214 10.0705C42.7476 12.5349 42.2295 15.1534 41.1513 20.4044Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth85",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
