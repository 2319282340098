<template>
  <g>
    <!--   SAME AS 18  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>
    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M19.8608 2.40276L21.9446 1.133H25.4283L27.5283 2.40276L25.9166 3.94927L28.0004 5.49578L26.112 7.04229L28.4073 8.73531L26.5841 10.2818L28.8794 11.6818L26.8608 13.0981L28.8794 14.7097L27.1864 16.2562L29.4166 17.6725L27.6585 19.1376L29.9538 20.6841L27.805 22.2306L29.9538 23.8423L28.4073 25.1934L30.4911 26.7399L28.6678 28.2213L30.7515 29.7678L28.9445 31.5097L29.9538 33.1864V47.4469L28.0655 54.7724H21.9446" fill="#8889BE"/>
      <path d="M27.2681 2.40276L25.1844 1.133H21.717L19.6007 2.40276L21.2286 3.94927L19.1286 5.49578L21.017 7.04229L18.7379 8.73531L20.5449 10.2818L18.2658 11.6818L20.2844 13.0981L18.2658 14.7097L19.9426 16.2562L17.7286 17.6725L19.4705 19.1376L17.1914 20.6841L19.3402 22.2306L17.1914 23.8423L18.7379 25.1934L16.6542 26.7399L18.4612 28.2213L16.3774 29.7678L18.2007 31.5097L17.1914 33.1864V47.4469L19.0635 54.7724H22.9705" fill="#8889BE"/>
      <path d="M42.9933 67.2879C42.9933 67.2879 44.1374 78.7022 31.9649 80.6351C19.7924 82.5681 26.7954 78.4623 17.9176 78.7022C9.03979 78.942 8.81922 76.4447 6.22757 73.891C5.47443 73.141 4.89333 72.2293 4.52621 71.2216C4.15909 70.214 4.01509 69.1355 4.10461 68.0639C4.10461 68.0639 3.49805 57.9618 6.99955 54.9283C8.97086 53.2211 8.56445 47.1824 10.5645 47.6542L16.6356 49.976C18.5787 49.8339 20.5065 49.5224 22.3979 49.0448C25.8442 48.1983 27.1262 47.3376 29.6903 47.1824C31.6535 47.0792 33.6161 47.3964 35.4526 48.1136L39.1747 49.976C40.0156 52.7978 40.9668 54.6038 41.6009 55.0271C43.1863 56.1135 43.0622 59.5279 43.0622 59.5279L42.9933 67.2879Z" fill="white" stroke="#DEE1E9"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M24.0645 7.5263L21.9096 21.1816L17.0882 9.92963C16.9401 2.63768 17.5327 2.63768 17.5327 2.63768C19.5393 -1.34968 20.8726 0.452827 22.2059 1.58622C23.5392 2.71961 24.0645 7.5263 24.0645 7.5263Z" fill="#DEE1E9"/>
      <path d="M43.0208 70.5737C43.0208 70.5737 44.1646 81.7595 31.9964 83.6676C19.8282 85.5757 26.7874 81.5383 17.9127 81.801C9.03803 82.0637 8.81754 79.6025 6.2268 77.0861C5.47393 76.3511 4.89304 75.4577 4.52605 74.4702C4.15905 73.4827 4.01511 72.4257 4.1046 71.3757C4.1046 71.3757 3.49826 61.4896 6.99851 58.5168C8.96913 56.8438 9.53413 53.4977 9.65816 50.8983C9.71427 49.6765 9.68665 48.4523 9.57547 47.2342C9.17401 43.3083 9.24804 39.3481 9.79596 35.4401C10.0716 33.532 11.4221 24.8903 11.8493 19.8297C11.9861 18.4047 11.8647 16.9666 11.491 15.5849C11.1638 14.3876 10.6423 13.2524 9.94755 12.225C9.44775 11.2866 9.14357 10.2559 9.05355 9.19572C8.96352 8.13554 9.08952 7.06807 9.42389 6.05833C12.4556 3.84606 16.8516 9.86067 16.8516 9.86067L21.785 21.2815L23.9899 7.45482C23.9899 7.45482 24.4447 2.89201 27.0216 3.1962C27.7788 3.32563 28.4772 3.68783 29.0203 4.23275C29.5634 4.77767 29.9244 5.47839 30.0534 6.23807C29.9018 13.0132 31.4314 13.9948 31.4314 13.9948C31.4314 13.9948 39.0107 21.005 37.6464 34.3892C36.9137 40.8254 37.4332 47.3431 39.1761 53.5807C40.0167 56.346 40.9675 58.102 41.6014 58.5168C43.1862 59.5815 43.0622 62.9414 43.0622 62.9414L43.0208 70.5737Z" fill="#DEE1E9"/>
      <path d="M42.9933 70.3153C42.9933 70.3153 44.1374 81.7296 31.9649 83.6626C19.7924 85.5955 26.7954 81.4898 17.9176 81.7296C9.03979 81.9695 8.81922 79.4722 6.22757 76.9184C5.47443 76.1685 4.89333 75.2568 4.52621 74.2491C4.15909 73.2414 4.01509 72.1629 4.10461 71.0913C4.10461 71.0913 3.49805 60.9892 6.99955 57.9558C8.97086 56.2486 8.56445 50.2098 10.5645 50.6816L16.6356 53.0034C18.5787 52.8614 20.5065 52.5498 22.3979 52.0723C25.8442 51.2257 27.1262 50.365 29.6903 50.2098C31.6535 50.1066 33.6161 50.4238 35.4526 51.141L39.1747 53.0034C40.0156 55.8253 40.9668 57.6312 41.6009 58.0545C43.1863 59.1409 43.0622 62.5553 43.0622 62.5553L42.9933 70.3153Z" stroke="#DEE1E9" fill="white" stroke-linejoin="round"/>
      <path opacity="0.2" d="M36.3047 61.2785C38.4695 57.7205 32.2994 53.3067 31.5677 44.9153C31.1103 39.3937 31.9793 39.0612 31.8268 30.502C31.8989 27.2135 31.5028 25.2942 31.1066 21.8172C30.7858 19.0009 30.6122 17.3302 28.8742 12.8156C27.3497 8.72064 27.9453 4.4393 27.2136 4.4393C26.4818 4.4393 25.9472 8.1903 25.8709 10.5063C25.7642 13.393 27.0753 14.2489 27.3955 17.9747C27.5327 19.6529 26.8832 22.2417 26.2582 22.4096C25.3587 22.6445 23.5903 17.8111 23.2091 16.8544C19.6418 7.20434 21.2078 1.38303 20.0645 1.18164C18.9211 0.980247 17.3481 5.93146 18.8268 13.1816C19.4062 16.0179 19.8513 17.2731 21.1014 20.8982C23.3577 27.3764 22.0963 33.654 21.6694 33.7715C21.2425 33.889 18.1352 19.1873 14.6136 11.635C13.5617 9.3861 11.1743 6.5138 10.5645 6.68162C9.95468 6.84945 13.7257 10.4599 13.8172 16.6528C13.8172 21.0499 15.2781 31.0021 15.0646 37.6816C14.9122 42.7165 14.827 41.9277 14.6136 45.7206C14.4001 49.7988 12.8425 52.6354 12.8273 57.838C12.8273 61.2785 13.0254 62.4869 13.8334 63.3931C15.2817 65.0714 17.7514 64.7022 19.3674 64.5008C24.3678 63.8798 25.191 60.8757 28.4382 61.4463C30.7707 61.8659 31.6702 63.6617 33.713 63.1246C34.7509 62.8734 35.6713 62.2178 36.3047 61.2785Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M21.0645 10.6835L18.9096 24.3388L14.0882 13.0868C13.9401 5.79484 14.5327 5.79485 14.5327 5.79485C16.5393 1.80749 17.8726 3.60999 19.2059 4.74339C20.5392 5.87678 21.0645 10.6835 21.0645 10.6835Z" fill="#DEE1E9"/>
      <path d="M40.0208 73.7309C40.0208 73.7309 41.1646 84.9167 28.9964 86.8248C16.8282 88.7328 23.7874 84.6954 14.9127 84.9582C6.03803 85.2209 5.81754 82.7597 3.2268 80.2432C2.47393 79.5083 1.89304 78.6149 1.52605 77.6273C1.15905 76.6398 1.01511 75.5829 1.1046 74.5328C1.1046 74.5328 0.498255 64.6467 3.99851 61.674C5.96913 60.001 6.53413 56.6549 6.65816 54.0555C6.71427 52.8337 6.68665 51.6094 6.57547 50.3914C6.17401 46.4655 6.24804 42.5053 6.79596 38.5972C7.07157 36.6891 8.42206 28.0475 8.84926 22.9869C8.98608 21.5619 8.86469 20.1237 8.49097 18.7421C8.16378 17.5447 7.64233 16.4096 6.94755 15.3822C6.44775 14.4438 6.14357 13.4131 6.05355 12.3529C5.96352 11.2927 6.08952 10.2252 6.42389 9.21549C9.4556 7.00322 13.8516 13.0178 13.8516 13.0178L18.785 24.4387L20.9899 10.612C20.9899 10.612 21.4447 6.04918 24.0216 6.35337C24.7788 6.4828 25.4772 6.845 26.0203 7.38992C26.5634 7.93483 26.9244 8.63555 27.0534 9.39524C26.9018 16.1703 28.4314 17.152 28.4314 17.152C28.4314 17.152 36.0107 24.1622 34.6464 37.5464C33.9137 43.9826 34.4332 50.5003 36.1761 56.7379C37.0167 59.5032 37.9675 61.2592 38.6014 61.674C40.1862 62.7387 40.0622 66.0985 40.0622 66.0985L40.0208 73.7309Z" fill="#DEE1E9"/>
      <path d="M39.9933 73.4725C39.9933 73.4725 41.1374 84.8868 28.9649 86.8197C16.7924 88.7527 23.7954 84.6469 14.9176 84.8868C6.03979 85.1266 5.81922 82.6293 3.22757 80.0756C2.47443 79.3256 1.89333 78.4139 1.52621 77.4062C1.15909 76.3986 1.01509 75.3201 1.10461 74.2485C1.10461 74.2485 0.498055 64.1464 3.99955 61.1129C5.97086 59.4057 5.56445 53.367 7.56445 53.8388L13.6356 56.1606C15.5787 56.0185 17.5065 55.707 19.3979 55.2294C22.8442 54.3829 24.1262 53.5222 26.6903 53.367C28.6535 53.2638 30.6161 53.581 32.4526 54.2982L36.1747 56.1606C37.0156 58.9824 37.9668 60.7884 38.6009 61.2117C40.1863 62.2981 40.0622 65.7125 40.0622 65.7125L39.9933 73.4725Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M33.3047 64.4357C35.4695 60.8777 29.2994 56.4638 28.5677 48.0724C28.1103 42.5509 28.9793 42.2184 28.8268 33.6591C28.8989 30.3706 28.5028 28.4513 28.1066 24.9744C27.7858 22.158 27.6122 20.4874 25.8742 15.9728C24.3497 11.8778 24.9453 7.59647 24.2136 7.59647C23.4818 7.59647 22.9472 11.3475 22.8709 13.6635C22.7642 16.5501 24.0753 17.4061 24.3955 21.1318C24.5327 22.8101 23.8832 25.3989 23.2582 25.5667C22.3587 25.8017 20.5903 20.9682 20.2091 20.0116C16.6418 10.3615 18.2078 4.5402 17.0645 4.33881C15.9211 4.13741 14.3481 9.08862 15.8268 16.3388C16.4062 19.1751 16.8513 20.4303 18.1014 24.0554C20.3577 30.5336 19.0963 36.8112 18.6694 36.9287C18.2425 37.0461 15.1352 22.3444 11.6136 14.7922C10.5617 12.5433 8.17429 9.67096 7.56448 9.83879C6.95468 10.0066 10.7257 13.6171 10.8172 19.81C10.8172 24.207 12.2781 34.1592 12.0646 40.8388C11.9122 45.8736 11.827 45.0848 11.6136 48.8778C11.4001 52.956 9.8425 55.7925 9.82725 60.9952C9.82725 64.4357 10.0254 65.644 10.8334 66.5503C12.2817 68.2286 14.7514 67.8593 16.3674 67.6579C21.3678 67.037 22.191 64.0329 25.4382 64.6035C27.7707 65.0231 28.6702 66.8188 30.713 66.2818C31.7509 66.0306 32.6713 65.375 33.3047 64.4357Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="6" cy="10" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="17" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="25" cy="7" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M39.0208 68.067C39.0208 68.067 40.1646 79.2528 27.9964 81.1609C15.8282 83.069 22.7874 79.0316 13.9127 79.2943C5.03803 79.557 4.81754 77.0958 2.2268 74.5794C1.47393 73.8444 0.893039 72.951 0.526046 71.9635C0.159053 70.976 0.0151073 69.919 0.104598 68.8689C0.104598 68.8689 -0.501745 58.9829 2.99851 56.0101C4.96913 54.3371 5.53413 50.991 5.65816 48.3916C5.71427 47.1698 5.68665 45.9455 5.57547 44.7275C5.17401 40.8016 5.24804 36.8414 5.79596 32.9333C6.07157 31.0253 7.42206 22.3836 7.84926 17.323C7.98608 15.898 7.86469 14.4599 7.49097 13.0782C7.16378 11.8808 6.64233 10.7457 5.94755 9.71832C5.44775 8.77988 5.14357 7.74919 5.05355 6.68901C4.96352 5.62883 5.08952 4.56136 5.42389 3.55161C8.4556 1.33934 12.8516 7.35396 12.8516 7.35396L17.785 18.7748L19.9899 4.94811C19.9899 4.94811 20.4447 0.3853 23.0216 0.689487C23.7788 0.818916 24.4772 1.18112 25.0203 1.72604C25.5634 2.27095 25.9244 2.97167 26.0534 3.73136C25.9018 10.5064 27.4314 11.4881 27.4314 11.4881C27.4314 11.4881 35.0107 18.4983 33.6464 31.8825C32.9137 38.3187 33.4332 44.8364 35.1761 51.074C36.0167 53.8393 36.9675 55.5953 37.6014 56.0101C39.1862 57.0748 39.0622 60.4347 39.0622 60.4347L39.0208 68.067Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
        <path d="M39.0208 68.067C39.0208 68.067 40.1646 79.2528 27.9964 81.1609C15.8282 83.069 22.7874 79.0316 13.9127 79.2943C5.03803 79.557 4.81754 77.0958 2.2268 74.5794C1.47393 73.8444 0.893039 72.951 0.526046 71.9635C0.159053 70.976 0.0151073 69.919 0.104598 68.8689C0.104598 68.8689 -0.501745 58.9829 2.99851 56.0101C4.96913 54.3371 5.53413 50.991 5.65816 48.3916C5.71427 47.1698 5.68665 45.9455 5.57547 44.7275C5.17401 40.8016 5.24804 36.8414 5.79596 32.9333C6.07157 31.0253 7.42206 22.3836 7.84926 17.323C7.98608 15.898 7.86469 14.4599 7.49097 13.0782C7.16378 11.8808 6.64233 10.7457 5.94755 9.71832C5.44775 8.77988 5.14357 7.74919 5.05355 6.68901C4.96352 5.62883 5.08952 4.56136 5.42389 3.55161C8.4556 1.33934 12.8516 7.35396 12.8516 7.35396L17.785 18.7748L19.9899 4.94811C19.9899 4.94811 20.4447 0.3853 23.0216 0.689487C23.7788 0.818916 24.4772 1.18112 25.0203 1.72604C25.5634 2.27095 25.9244 2.97167 26.0534 3.73136C25.9018 10.5064 27.4314 11.4881 27.4314 11.4881C27.4314 11.4881 35.0107 18.4983 33.6464 31.8825C32.9137 38.3187 33.4332 44.8364 35.1761 51.074C36.0167 53.8393 36.9675 55.5953 37.6014 56.0101C39.1862 57.0748 39.0622 60.4347 39.0622 60.4347L39.0208 68.067Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M15.3306 7.96209L13.1757 21.6174L8.35434 10.3654C8.2062 3.07347 8.79877 3.07347 8.79877 3.07347C10.8054 -0.913886 12.1387 0.888618 13.472 2.02201C14.8053 3.15541 15.3306 7.96209 15.3306 7.96209Z" fill="#C7CAD5"/>
      <path d="M0.841578 47.67C0.952753 48.888 1.11818 56.7363 1.06207 57.9581C4.6448 58.7707 6.69426 57.1148 9.39074 57.0934C13.0511 57.9581 19.1028 57.851 22.6975 56.5614C25.2316 55.6523 27.4326 58.6268 30.1852 57.9581C28.4423 51.7205 28.1798 41.2612 28.9125 34.825C30.2768 21.4408 22.6975 14.4306 22.6975 14.4306C22.6975 14.4306 21.1679 13.4489 21.3195 6.67386C21.1905 5.91418 20.8295 5.21346 20.2864 4.66854C19.7433 4.12362 19.0449 3.76142 18.2877 3.63199C15.7108 3.3278 15.256 7.89061 15.256 7.89061L13.0511 21.7173L8.1177 10.2965C8.1177 10.2965 3.72171 4.28185 0.689991 6.49412C0.355629 7.50387 0.229623 8.57133 0.319651 9.63151C0.409678 10.6917 0.713853 11.7224 1.21365 12.6608C1.90843 13.6882 2.42988 14.8233 2.75707 16.0207C3.13079 17.4024 3.25218 18.8405 3.11537 20.2655C2.68817 25.3261 1.33768 33.9678 1.06207 35.8759C0.514146 39.7839 0.440111 43.7441 0.841578 47.67Z" fill="#DEE1E9"/>
      <path d="M15.3306 7.96209L13.1757 21.6174L8.35434 10.3654C8.2062 3.07347 8.79877 3.07347 8.79877 3.07347C10.8054 -0.913886 12.1387 0.888618 13.472 2.02201C14.8053 3.15541 15.3306 7.96209 15.3306 7.96209Z" fill="#C7CAD5"/>
      <path opacity="0.2" d="M23.7565 34.4502C23.9132 44.1542 23.0199 44.5312 23.4901 50.7913C23.7402 53.9554 24.6088 56.6206 25.5748 58.9173L22.5663 60.4152L18.3573 58.9173L13.3148 60.4152L9.38538 58.9173L4.63878 60.4152C5.13334 57.3125 5.91317 54.8301 6.06149 51.7043C6.28089 47.4041 6.36847 48.2984 6.52518 42.5901C6.74459 35.0171 5.24283 23.7338 5.24283 18.7486C5.1488 11.7274 1.27224 7.63403 1.89911 7.44376C2.52598 7.25348 4.98017 10.51 6.06152 13.0597C9.68168 21.6221 12.876 38.2902 13.3148 38.157C13.7536 38.0238 15.0504 30.9065 12.7309 23.5619C11.4459 19.4519 10.9882 18.0288 10.3927 14.8132C8.87254 6.59325 10.4896 0.979803 11.6649 1.20813C12.8403 1.43646 11.2304 8.03639 14.8976 18.9772C15.2894 20.0618 17.1073 25.5417 18.032 25.2754C18.6745 25.0851 19.3421 22.15 19.2011 20.2473C18.872 16.0232 17.5242 15.0528 17.6339 11.78C17.7123 9.15423 18.2619 4.90152 19.0141 4.90152C19.7664 4.90152 19.1541 9.75549 20.7213 14.3982C22.484 19.4482 22.6813 21.3595 23.003 24.4765L23.0161 24.6038C23.4233 28.5458 23.8306 30.7218 23.7565 34.4502Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M1.19232 41.2548C12.4046 39.5113 18.1398 40.9516 27.589 47.0058" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M19.3306 7.54998L17.1757 21.2053L12.3543 9.95331C12.2062 2.66136 12.7988 2.66136 12.7988 2.66136C14.8054 -1.326 16.1387 0.476509 17.472 1.6099C18.8053 2.7433 19.3306 7.54998 19.3306 7.54998Z" fill="#C7CAD5"/>
      <path d="M4.84158 47.2579C4.95275 48.4759 4.98038 49.7002 4.92426 50.922C13.8504 63.899 20.318 64.4644 34.4422 53.6044C32.6993 47.3668 32.1798 40.8491 32.9125 34.4129C34.2768 21.0287 26.6975 14.0185 26.6975 14.0185C26.6975 14.0185 25.1679 13.0368 25.3195 6.26175C25.1905 5.50207 24.8295 4.80135 24.2864 4.25643C23.7433 3.71151 23.0449 3.34931 22.2877 3.21988C19.7108 2.9157 19.256 7.4785 19.256 7.4785L17.0511 21.3052L12.1177 9.88435C12.1177 9.88435 7.72171 3.86974 4.68999 6.08201C4.35563 7.09176 4.22962 8.15923 4.31965 9.2194C4.40968 10.2796 4.71385 11.3103 5.21365 12.2487C5.90843 13.2761 6.42988 14.4112 6.75707 15.6086C7.13079 16.9903 7.25218 18.4284 7.11537 19.8534C6.68817 24.914 5.33768 33.5557 5.06207 35.4637C4.51415 39.3718 4.44011 43.332 4.84158 47.2579Z" fill="#DEE1E9"/>
      <path d="M19.3306 7.54998L17.1757 21.2053L12.3543 9.95331C12.2062 2.66136 12.7988 2.66136 12.7988 2.66136C14.8054 -1.326 16.1387 0.476509 17.472 1.6099C18.8053 2.7433 19.3306 7.54998 19.3306 7.54998Z" fill="#C7CAD5"/>
      <path d="M27.231 83.6863C39.4035 81.7533 38.2593 70.339 38.2593 70.339L38.3283 62.579C38.3283 62.579 38.4523 59.1646 36.867 58.0782C36.2329 57.6549 35.2817 55.849 34.4408 53.0271L30.7187 51.1647C28.8822 50.4475 26.9196 50.1303 24.9564 50.2335C22.3923 50.3887 21.1103 51.2494 17.6639 52.0959C15.7725 52.5735 13.8448 52.8851 11.9016 53.0271L5.83054 50.7053C3.83054 50.2335 2.95917 58.4336 0.987854 60.1408L5.83054 61.0921L3.36018 64.044L4.56775 66.5109L3.36018 68.8243L7.16926 70.339L5.83054 73.374L9.39806 72.6029L7.16926 76.9421L9.39806 79.34L13.1837 76.9421L14.2284 79.34L13.1837 81.7533C22.0615 81.5134 15.0585 85.6192 27.231 83.6863Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M32.3596 68.931C34.585 64.8972 28.2423 59.8929 27.4901 50.3792C27.0199 44.1191 27.9132 43.7421 27.7565 34.038C27.8306 30.3097 27.4233 28.1337 27.0161 24.1916C26.6863 20.9986 26.5078 19.1045 24.7213 13.9861C23.1541 9.34338 23.7664 4.4894 23.0141 4.4894C22.2619 4.4894 21.7123 8.74211 21.6339 11.3679C21.5242 14.6406 22.872 15.6111 23.2011 19.8352C23.3421 21.7379 22.6745 24.673 22.032 24.8633C21.1073 25.1296 19.2894 19.6497 18.8976 18.5651C15.2304 7.62427 16.8403 1.02435 15.6649 0.796019C14.4896 0.567688 12.8725 6.18114 14.3927 14.401C14.9882 17.6167 15.4459 19.0398 16.7309 23.1498C19.0504 30.4944 17.7536 37.6117 17.3148 37.7449C16.876 37.8781 13.6817 21.2099 10.0615 12.6475C8.98017 10.0979 6.52598 6.84137 5.89911 7.03164C5.27225 7.22192 9.1488 11.3153 9.24283 18.3365C9.24283 23.3217 10.7446 34.605 10.5252 42.178C10.3685 47.8862 10.2809 46.992 10.0615 51.2922C9.84209 55.9159 8.24088 59.1319 8.2252 65.0304C8.2252 68.931 8.42894 70.301 9.25953 71.3285C10.7483 73.2313 13.2872 72.8126 14.9484 72.5843C20.0887 71.8803 20.935 68.4744 24.273 69.1213C26.6708 69.597 27.5954 71.6329 29.6954 71.0241C30.7623 70.7393 31.7085 69.9959 32.3596 68.931Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M3.79282 58.2173C14.7936 62.5692 17.3132 67.4632 16.0542 80.0516" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M21.3306 7.44273L19.1757 21.0981L14.3543 9.84606C14.2062 2.55411 14.7988 2.55411 14.7988 2.55411C16.8054 -1.43325 18.1387 0.369255 19.472 1.50265C20.8053 2.63604 21.3306 7.44273 21.3306 7.44273Z" fill="#C7CAD5"/>
      <path d="M40.287 70.4901C40.287 70.4901 41.4307 81.6759 29.2625 83.584C17.0943 85.4921 24.0535 81.4547 15.1788 81.7174C6.30414 81.9801 6.08366 79.519 3.49292 77.0025C2.74004 76.2676 2.15915 75.3741 1.79216 74.3866C1.42517 73.3991 1.28122 72.3422 1.37071 71.2921C1.37071 71.2921 0.764368 61.406 4.26462 58.4333C6.23524 56.7602 6.80024 53.4142 6.92427 50.8147C6.98039 49.5929 6.95276 48.3687 6.84159 47.1507C6.44012 43.2248 6.51415 39.2645 7.06207 35.3565C7.33769 33.4484 8.68818 24.8067 9.11537 19.7461C9.25219 18.3211 9.1308 16.883 8.75708 15.5013C8.42989 14.304 7.90844 13.1688 7.21366 12.1415C6.71386 11.203 6.40969 10.1723 6.31966 9.11215C6.22963 8.05197 6.35564 6.9845 6.69 5.97476C9.72172 3.76248 14.1177 9.7771 14.1177 9.7771L19.0511 21.1979L21.256 7.37125C21.256 7.37125 21.7108 2.80844 24.2877 3.11263C25.0449 3.24206 25.7433 3.60426 26.2864 4.14918C26.8295 4.6941 27.1905 5.39482 27.3195 6.1545C27.1679 12.9296 28.6975 13.9113 28.6975 13.9113C28.6975 13.9113 36.2768 20.9214 34.9125 34.3057C34.1798 40.7419 34.6993 47.2596 36.4422 53.4971C37.2828 56.2625 38.2336 58.0185 38.8676 58.4333C40.4523 59.4979 40.3283 62.8578 40.3283 62.8578L40.287 70.4901Z" fill="#DEE1E9"/>
      <path d="M39.9288 70.057C39.9288 70.057 41.073 81.4713 28.9005 83.4043C16.728 85.3372 23.7309 81.2314 14.8531 81.4713C5.97534 81.7112 5.75477 79.2138 3.16311 76.6601C2.40997 75.9101 1.82888 74.9984 1.46176 73.9908C1.09463 72.9831 0.950637 71.9046 1.04016 70.833C1.04016 70.833 0.433602 60.7309 3.9351 57.6974C5.90641 55.9902 5.5 49.9515 7.5 50.4233L13.5711 52.7451C15.5142 52.6031 14.7239 63.866 14.7239 63.866C18.6291 65.6074 24.5423 65.6077 28.4475 63.866C28.4475 63.866 28.6634 50.1799 30.5 50.8971L36.1102 52.7451C36.9512 55.567 37.9023 57.3729 38.5365 57.7962C40.1218 58.8826 39.9977 62.297 39.9977 62.297L39.9288 70.057Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M21.3306 7.69351L19.1757 21.3489L14.3543 10.0968C14.2062 2.80489 14.7988 2.8049 14.7988 2.8049C16.8054 -1.18246 18.1387 0.620041 19.472 1.75343C20.8053 2.88683 21.3306 7.69351 21.3306 7.69351Z" fill="#C7CAD5"/>
      <path d="M40.287 70.7409C40.287 70.7409 41.4307 81.9267 29.2625 83.8348C17.0943 85.7429 24.0535 81.7055 15.1788 81.9682C6.30414 82.2309 6.08366 79.7698 3.49292 77.2533C2.74004 76.5184 2.15915 75.6249 1.79216 74.6374C1.42517 73.6499 1.28122 72.593 1.37071 71.5429C1.37071 71.5429 0.764368 61.6568 4.26462 58.684C6.23524 57.011 6.80024 53.6649 6.92427 51.0655C6.98039 49.8437 6.95276 48.6195 6.84159 47.4015C6.44012 43.4755 6.51415 39.5153 7.06207 35.6073C7.33769 33.6992 8.68818 25.0575 9.11537 19.9969C9.25219 18.5719 9.1308 17.1338 8.75708 15.7521C8.42989 14.5548 7.90844 13.4196 7.21366 12.3922C6.71386 11.4538 6.40969 10.4231 6.31966 9.36294C6.22963 8.30276 6.35564 7.23529 6.69 6.22554C9.72172 4.01327 14.1177 10.0279 14.1177 10.0279L19.0511 21.4487L21.256 7.62204C21.256 7.62204 21.7108 3.05923 24.2877 3.36341C25.0449 3.49284 25.7433 3.85505 26.2864 4.39996C26.8295 4.94488 27.1905 5.6456 27.3195 6.40528C27.1679 13.1804 28.6975 14.1621 28.6975 14.1621C28.6975 14.1621 36.2768 21.1722 34.9125 34.5564C34.1798 40.9926 34.6993 47.5103 36.4422 53.7479C37.2828 56.5132 38.2336 58.2692 38.8676 58.684C40.4523 59.7487 40.3283 63.1086 40.3283 63.1086L40.287 70.7409Z" fill="#DEE1E9"/>
      <path d="M39.9288 70.3078C39.9288 70.3078 41.073 81.7221 28.9005 83.655C16.728 85.588 23.7309 81.4822 14.8531 81.7221C5.97534 81.9619 5.75477 79.4646 3.16311 76.9109C2.40997 76.1609 1.82888 75.2492 1.46176 74.2416C1.09463 73.2339 0.950637 72.1554 1.04016 71.0838C1.04016 71.0838 0.433602 60.9817 3.9351 57.9482C5.90641 56.241 5.5 50.2023 7.5 50.6741L13.5711 52.9959C15.5142 52.8538 14.7239 64.1168 14.7239 64.1168C18.6291 65.8582 24.5423 65.8585 28.4475 64.1168C28.4475 64.1168 28.6634 50.4307 30.5 51.1479L36.1102 52.9959C36.9512 55.8177 37.9023 57.6237 38.5365 58.047C40.1218 59.1334 39.9977 62.5478 39.9977 62.5478L39.9288 70.3078Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M21.3306 7.76116L19.1757 21.4165L14.3543 10.1645C14.2062 2.87254 14.7988 2.87255 14.7988 2.87255C16.8054 -1.11481 18.1387 0.687691 19.472 1.82108C20.8053 2.95448 21.3306 7.76116 21.3306 7.76116Z" fill="#C7CAD5"/>
      <path d="M40.287 70.8086C40.287 70.8086 41.4307 81.9944 29.2625 83.9025C17.0943 85.8105 24.0535 81.7731 15.1788 82.0359C6.30414 82.2986 6.08366 79.8374 3.49292 77.3209C2.74004 76.586 2.15915 75.6926 1.79216 74.705C1.42517 73.7175 1.28122 72.6606 1.37071 71.6105C1.37071 71.6105 0.764368 61.7244 4.26462 58.7517C6.23524 57.0787 6.80024 53.7326 6.92427 51.1332C6.98039 49.9114 6.95276 48.6871 6.84159 47.4691C6.44012 43.5432 6.51415 39.583 7.06207 35.6749C7.33769 33.7668 8.68818 25.1252 9.11537 20.0646C9.25219 18.6395 9.1308 17.2014 8.75708 15.8198C8.42989 14.6224 7.90844 13.4873 7.21366 12.4599C6.71386 11.5215 6.40969 10.4908 6.31966 9.43059C6.22963 8.37041 6.35564 7.30294 6.69 6.29319C9.72172 4.08092 14.1177 10.0955 14.1177 10.0955L19.0511 21.5164L21.256 7.68968C21.256 7.68968 21.7108 3.12688 24.2877 3.43106C25.0449 3.56049 25.7433 3.9227 26.2864 4.46761C26.8295 5.01253 27.1905 5.71325 27.3195 6.47293C27.1679 13.248 28.6975 14.2297 28.6975 14.2297C28.6975 14.2297 36.2768 21.2398 34.9125 34.6241C34.1798 41.0603 34.6993 47.578 36.4422 53.8156C37.2828 56.5809 38.2336 58.3369 38.8676 58.7517C40.4523 59.8163 40.3283 63.1762 40.3283 63.1762L40.287 70.8086Z" fill="#DEE1E9"/>
      <path d="M39.9288 70.3755C39.9288 70.3755 41.073 81.7897 28.9005 83.7227C16.728 85.6556 23.7309 81.5499 14.8531 81.7897C5.97534 82.0296 5.75477 79.5323 3.16311 76.9785C2.40997 76.2286 1.82888 75.3169 1.46176 74.3092C1.09463 73.3015 0.950637 72.223 1.04016 71.1515C1.04016 71.1515 0.433602 61.0493 3.9351 58.0159C5.90641 56.3087 5.5 50.27 7.5 50.7418L13.5711 53.0636C15.5142 52.9215 14.278 64.0184 16.1694 63.5408C19.6158 62.6943 23.6053 63.696 26.1694 63.5408C28.1326 63.4376 28.6634 50.4983 30.5 51.2156L36.1102 53.0636C36.9512 55.8854 37.9023 57.6914 38.5365 58.1146C40.1218 59.201 39.9977 62.6154 39.9977 62.6154L39.9288 70.3755Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask2018" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="35">
        <path d="M39.9288 70.3754C39.9288 70.3754 41.073 81.7897 28.9005 83.7227C16.728 85.6556 23.7309 81.5499 14.8531 81.7897C5.97534 82.0296 5.75477 79.5323 3.16311 76.9785C2.40997 76.2286 1.82888 75.3169 1.46176 74.3092C1.09463 73.3015 0.950637 72.223 1.04016 71.1515C1.04016 71.1515 0.433602 61.0493 3.9351 58.0159C5.90641 56.3087 4.9289 50.922 6.9289 51.3938L13 53.7156C14.9432 53.5735 14.278 64.0184 16.1694 63.5408C19.7492 64.03 23.7191 64.8393 26.1694 63.5408C28.1326 63.4376 28.6634 51.4983 30.5 52.2156L36 54.2156C36.8409 57.0374 37.9023 57.6914 38.5365 58.1146C40.1218 59.201 39.9977 62.6154 39.9977 62.6154L39.9288 70.3754Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2018)">
        <path d="M10 42.7156H33V68.2635C24.0179 70.1898 18.9821 70.2094 10 68.2635V42.7156Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M21 7.55413L18.8452 21.2095L14.0238 9.95746C13.8756 2.66551 14.4682 2.66551 14.4682 2.66551C16.4749 -1.32185 17.8082 0.480659 19.1415 1.61405C20.4748 2.74745 21 7.55413 21 7.55413Z" fill="#DEE1E9"/>
      <path d="M39.9564 70.6015C39.9564 70.6015 41.1002 81.7873 28.932 83.6954C16.7637 85.6035 23.7229 81.5661 14.8482 81.8288C5.97358 82.0915 5.75309 79.6304 3.16235 77.1139C2.40948 76.379 1.82859 75.4855 1.46159 74.498C1.0946 73.5105 0.950654 72.4536 1.04014 71.4035C1.04014 71.4035 0.433802 61.5174 3.93406 58.5447C5.90468 56.8716 6.46968 53.5256 6.5937 50.9261C6.64982 49.7043 6.6222 48.4801 6.51102 47.2621C6.10955 43.3362 6.18359 39.3759 6.73151 35.4679C7.00712 33.5598 8.35761 24.9181 8.78481 19.8575C8.92162 18.4325 8.80024 16.9944 8.42651 15.6128C8.09932 14.4154 7.57788 13.2802 6.88309 12.2529C6.3833 11.3144 6.07912 10.2837 5.98909 9.22355C5.89907 8.16338 6.02507 7.09591 6.35943 6.08616C9.39115 3.87389 13.7871 9.8885 13.7871 9.8885L18.7206 21.3094L20.9255 7.48265C20.9255 7.48265 21.3802 2.91985 23.9572 3.22403C24.7143 3.35346 25.4127 3.71566 25.9558 4.26058C26.4989 4.8055 26.8599 5.50622 26.9889 6.2659C26.8373 13.041 28.367 14.0227 28.367 14.0227C28.367 14.0227 35.9462 21.0328 34.582 34.4171C33.8493 40.8533 34.3688 47.371 36.1116 53.6085C36.9522 56.3739 37.9031 58.1299 38.537 58.5447C40.1217 59.6093 39.9977 62.9692 39.9977 62.9692L39.9564 70.6015Z" fill="#DEE1E9"/>
      <path d="M39.9288 70.3433C39.9288 70.3433 41.073 81.7576 28.9005 83.6905C16.728 85.6235 23.7309 81.5177 14.8531 81.7576C5.97534 81.9974 5.75477 79.5001 3.16311 76.9464C2.40997 76.1964 1.82888 75.2847 1.46176 74.277C1.09463 73.2694 0.950637 72.1908 1.04016 71.1193C1.04016 71.1193 0.433602 61.0172 3.9351 57.9837C5.90641 56.2765 5.5 50.2378 7.5 50.7096L13.5711 53.0314C15.5142 52.8893 17.442 52.5778 19.3334 52.1002C22.7797 51.2537 24.0618 50.393 26.6259 50.2378C28.589 50.1346 30.5516 50.4517 32.3882 51.169L36.1102 53.0314C36.9512 55.8532 37.9023 57.6592 38.5365 58.0825C40.1218 59.1689 39.9977 62.5833 39.9977 62.5833L39.9288 70.3433Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M33.2402 61.3064C35.405 57.7484 29.235 53.3346 28.5032 44.9432C28.0459 39.4216 28.9148 39.0891 28.7624 30.5299C28.8345 27.2414 28.4383 25.3221 28.0422 21.8451C27.7213 19.0288 27.5477 17.3581 25.8098 12.8435C24.2853 8.74853 24.8809 4.4672 24.1491 4.4672C23.4174 4.4672 22.8827 8.21819 22.8065 10.5342C22.6998 13.4209 24.0109 14.2768 24.331 18.0026C24.4682 19.6808 23.8188 22.2696 23.1937 22.4375C22.2942 22.6724 20.5258 17.839 20.1447 16.8823C16.5773 7.23223 18.1434 1.41093 17 1.20953C15.8566 1.00814 14.2836 5.95935 15.7624 13.2095C16.3417 16.0458 16.7869 17.301 18.037 20.9261C20.2933 27.4043 19.0318 33.6819 18.6049 33.7994C18.1781 33.9169 15.0707 19.2151 11.5491 11.6629C10.4972 9.41399 8.10983 6.54169 7.50003 6.70952C6.89023 6.87734 10.6613 10.4878 10.7527 16.6807C10.7527 21.0778 12.2136 31.03 12.0002 37.7095C11.8477 42.7444 11.7625 41.9556 11.5491 45.7485C11.3357 49.8267 9.77804 52.6633 9.7628 57.8659C9.7628 61.3064 9.96098 62.5148 10.769 63.421C12.2173 65.0993 14.6869 64.7301 16.3029 64.5287C21.3033 63.9077 22.1266 60.9036 25.3738 61.4742C27.7063 61.8938 28.6057 63.6895 30.6486 63.1525C31.6864 62.9013 32.6068 62.2457 33.2402 61.3064Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M9 18.8706C11.7424 17.5064 13.2738 17.5685 16 18.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M17 18.8706C19.3506 17.5064 20.6633 17.5685 23 18.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M24 18.8706C26.7424 17.5064 28.2738 17.5685 31 18.8706" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M21.3306 9.88451L19.1757 23.5399L14.3543 12.2878C14.2062 4.99589 14.7988 4.9959 14.7988 4.9959C16.8054 1.00854 18.1387 2.81104 19.472 3.94444C20.8053 5.07783 21.3306 9.88451 21.3306 9.88451Z" fill="#DEE1E9"/>
      <path d="M40.287 72.9319C40.287 72.9319 41.4307 84.1177 29.2625 86.0258C17.0943 87.9339 24.0535 83.8965 15.1788 84.1592C6.30414 84.4219 6.08366 81.9608 3.49292 79.4443C2.74004 78.7094 2.15915 77.8159 1.79216 76.8284C1.42517 75.8409 1.28122 74.784 1.37071 73.7339C1.37071 73.7339 0.764368 63.8478 4.26462 60.875C6.23524 59.202 6.80024 55.8559 6.92427 53.2565C6.98039 52.0347 6.95276 50.8105 6.84159 49.5925C6.44012 45.6665 6.51415 41.7063 7.06207 37.7983C7.33769 35.8902 8.68818 27.2485 9.11537 22.1879C9.25219 20.7629 9.1308 19.3248 8.75708 17.9431C8.42989 16.7458 7.90844 15.6106 7.21366 14.5832C6.71386 13.6448 6.40969 12.6141 6.31966 11.5539C6.22963 10.4938 6.35564 9.42629 6.69 8.41654C9.72172 6.20427 14.1177 12.2189 14.1177 12.2189L19.0511 23.6397L21.256 9.81304C21.256 9.81304 21.7108 5.25023 24.2877 5.55442C25.0449 5.68384 25.7433 6.04605 26.2864 6.59097C26.8295 7.13588 27.1905 7.8366 27.3195 8.59629C27.1679 15.3714 28.6975 16.3531 28.6975 16.3531C28.6975 16.3531 36.2768 23.3632 34.9125 36.7474C34.1798 43.1836 34.6993 49.7013 36.4422 55.9389C37.2828 58.7042 38.2336 60.4602 38.8676 60.875C40.4523 61.9397 40.3283 65.2996 40.3283 65.2996L40.287 72.9319Z" fill="#DEE1E9"/>
      <mask id="mask2218" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="52" width="41" height="36">
        <path d="M40.1949 73.3256C40.1949 73.3256 41.3391 84.7399 29.1666 86.6728C16.9941 88.6058 23.9971 84.5 15.1193 84.7399C6.24145 84.9797 6.02088 82.4824 3.42923 79.9287C2.67609 79.1787 2.09499 78.267 1.72787 77.2593C1.36075 76.2517 1.21675 75.1731 1.30627 74.1016C1.30627 74.1016 0.699715 63.9995 4.20121 60.966C6.17252 59.2588 5.76611 53.2201 7.76611 53.6919L13.8372 56.0137C15.7804 55.8716 17.7081 55.5601 19.5995 55.0825C23.0459 54.236 24.3279 53.3753 26.892 53.2201C28.8551 53.1169 30.8177 53.434 32.6543 54.1513L36.3764 56.0137C37.2173 58.8355 38.1685 60.6415 38.8026 61.0648C40.3879 62.1512 40.2638 65.5656 40.2638 65.5656L40.1949 73.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2218)">
        <path d="M40.1949 73.3256C40.1949 73.3256 41.3391 84.7399 29.1666 86.6728C16.9941 88.6058 23.9971 84.5 15.1193 84.7399C6.24145 84.9797 6.02088 82.4824 3.42923 79.9287C2.67609 79.1787 2.09499 78.267 1.72787 77.2593C1.36075 76.2517 1.21675 75.1731 1.30627 74.1016C1.30627 74.1016 0.699715 63.9995 4.20121 60.966C6.17252 59.2588 5.76611 53.2201 7.76611 53.6919L13.8372 56.0137C15.7804 55.8716 17.7081 55.5601 19.5995 55.0825C23.0459 54.236 24.3279 53.3753 26.892 53.2201C28.8551 53.1169 30.8177 53.434 32.6543 54.1513L36.3764 56.0137C37.2173 58.8355 38.1685 60.6415 38.8026 61.0648C40.3879 62.1512 40.2638 65.5656 40.2638 65.5656L40.1949 73.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.5738 71.201C20.5117 71.201 19.2661 76.4993 19.2661 86.0976L21.1892 87.9597L24.2661 89.201C24.2661 79.6027 22.6359 71.201 21.5738 71.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M33.7952 71.3428C36.0206 67.3089 29.6778 62.3047 28.9256 52.7909C28.4554 46.5308 29.3487 46.1538 29.192 36.4498C29.2661 32.7214 28.8589 30.5454 28.4517 26.6034C28.1219 23.4104 27.9434 21.5163 26.1568 16.3978C24.5896 11.7551 25.2019 6.90115 24.4497 6.90115C23.6974 6.90115 23.1478 11.1539 23.0695 13.7797C22.9598 17.0524 24.3075 18.0228 24.6366 22.2469C24.7777 24.1497 24.1101 27.0847 23.4675 27.275C22.5429 27.5414 20.725 22.0614 20.3332 20.9769C16.666 10.036 18.2759 3.43609 17.1005 3.20776C15.9251 2.97943 14.3081 8.59288 15.8282 16.8128C16.4238 20.0284 16.8814 21.4516 18.1665 25.5615C20.4859 32.9061 19.1891 40.0234 18.7503 40.1566C18.3115 40.2898 15.1172 23.6217 11.4971 15.0593C10.4157 12.5096 7.96153 9.25311 7.33466 9.44339C6.70779 9.63366 10.5843 13.7271 10.6784 20.7482C10.6784 25.7334 12.1801 37.0168 11.9607 44.5897C11.804 50.298 11.7164 49.4037 11.497 53.7039C11.2776 58.3276 9.67642 61.5436 9.66075 67.4421C9.66075 71.3428 9.86448 72.7128 10.6951 73.7402C12.1839 75.643 14.7227 75.2244 16.3839 74.996C21.5242 74.292 22.3705 70.8861 25.7086 71.533C28.1064 72.0087 29.031 74.0447 31.131 73.4358C32.1979 73.151 33.1441 72.4077 33.7952 71.3428Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="8" cy="11.201" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="17" cy="4.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="25" cy="7.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M21.3306 13.8845L19.1757 27.5399L14.3543 16.2878C14.2062 8.99589 14.7988 8.9959 14.7988 8.9959C16.8054 5.00854 18.1387 6.81104 19.472 7.94444C20.8053 9.07783 21.3306 13.8845 21.3306 13.8845Z" fill="#DEE1E9"/>
      <path d="M40.287 76.9319C40.287 76.9319 41.4307 88.1177 29.2625 90.0258C17.0943 91.9339 24.0535 87.8965 15.1788 88.1592C6.30414 88.4219 6.08366 85.9608 3.49292 83.4443C2.74004 82.7094 2.15915 81.8159 1.79216 80.8284C1.42517 79.8409 1.28122 78.784 1.37071 77.7339C1.37071 77.7339 0.764368 67.8478 4.26462 64.875C6.23524 63.202 6.80024 59.8559 6.92427 57.2565C6.98039 56.0347 6.95276 54.8105 6.84159 53.5925C6.44012 49.6665 6.51415 45.7063 7.06207 41.7983C7.33769 39.8902 8.68818 31.2485 9.11537 26.1879C9.25219 24.7629 9.1308 23.3248 8.75708 21.9431C8.42989 20.7458 7.90844 19.6106 7.21366 18.5832C6.71386 17.6448 6.40969 16.6141 6.31966 15.5539C6.22963 14.4938 6.35564 13.4263 6.69 12.4165C9.72172 10.2043 14.1177 16.2189 14.1177 16.2189L19.0511 27.6397L21.256 13.813C21.256 13.813 21.7108 9.25023 24.2877 9.55442C25.0449 9.68384 25.7433 10.046 26.2864 10.591C26.8295 11.1359 27.1905 11.8366 27.3195 12.5963C27.1679 19.3714 28.6975 20.3531 28.6975 20.3531C28.6975 20.3531 36.2768 27.3632 34.9125 40.7474C34.1798 47.1836 34.6993 53.7013 36.4422 59.9389C37.2828 62.7042 38.2336 64.4602 38.8676 64.875C40.4523 65.9397 40.3283 69.2996 40.3283 69.2996L40.287 76.9319Z" fill="#DEE1E9"/>
      <mask id="mask2318" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="41" height="36">
        <path d="M40.1949 77.3256C40.1949 77.3256 41.3391 88.7399 29.1666 90.6728C16.9941 92.6058 23.9971 88.5 15.1193 88.7399C6.24145 88.9797 6.02088 86.4824 3.42923 83.9287C2.67609 83.1787 2.09499 82.267 1.72787 81.2593C1.36075 80.2517 1.21675 79.1731 1.30627 78.1016C1.30627 78.1016 0.699715 67.9995 4.20121 64.966C6.17252 63.2588 5.76611 57.2201 7.76611 57.6919L13.8372 60.0137C15.7804 59.8716 17.7081 59.5601 19.5995 59.0825C23.0459 58.236 24.3279 57.3753 26.892 57.2201C28.8551 57.1169 30.8177 57.434 32.6543 58.1513L36.3764 60.0137C37.2173 62.8355 38.1685 64.6415 38.8026 65.0648C40.3879 66.1512 40.2638 69.5656 40.2638 69.5656L40.1949 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2318)">
        <path d="M40.1949 77.3256C40.1949 77.3256 41.3391 88.7399 29.1666 90.6728C16.9941 92.6058 23.9971 88.5 15.1193 88.7399C6.24145 88.9797 6.02088 86.4824 3.42923 83.9287C2.67609 83.1787 2.09499 82.267 1.72787 81.2593C1.36075 80.2517 1.21675 79.1731 1.30627 78.1016C1.30627 78.1016 0.699715 67.9995 4.20121 64.966C6.17252 63.2588 5.76611 57.2201 7.76611 57.6919L13.8372 60.0137C15.7804 59.8716 17.7081 59.5601 19.5995 59.0825C23.0459 58.236 24.3279 57.3753 26.892 57.2201C28.8551 57.1169 30.8177 57.434 32.6543 58.1513L36.3764 60.0137C37.2173 62.8355 38.1685 64.6415 38.8026 65.0648C40.3879 66.1512 40.2638 69.5656 40.2638 69.5656L40.1949 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.5738 75.201C20.5117 75.201 19.2661 80.4993 19.2661 90.0976L21.1892 91.9597L24.2661 93.201C24.2661 83.6027 22.6359 75.201 21.5738 75.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M33.7952 75.3428C36.0206 71.3089 29.6778 66.3047 28.9256 56.7909C28.4554 50.5308 29.3487 50.1538 29.192 40.4498C29.2661 36.7214 28.8589 34.5454 28.4517 30.6034C28.1219 27.4104 27.9434 25.5163 26.1568 20.3978C24.5896 15.7551 25.2019 10.9011 24.4497 10.9011C23.6974 10.9011 23.1478 15.1539 23.0695 17.7797C22.9598 21.0524 24.3075 22.0228 24.6366 26.2469C24.7777 28.1497 24.1101 31.0847 23.4675 31.275C22.5429 31.5414 20.725 26.0614 20.3332 24.9769C16.666 14.036 18.2759 7.43609 17.1005 7.20776C15.9251 6.97943 14.3081 12.5929 15.8282 20.8128C16.4238 24.0284 16.8814 25.4516 18.1665 29.5615C20.4859 36.9061 19.1891 44.0234 18.7503 44.1566C18.3115 44.2898 15.1172 27.6217 11.4971 19.0593C10.4157 16.5096 7.96153 13.2531 7.33466 13.4434C6.70779 13.6337 10.5843 17.7271 10.6784 24.7482C10.6784 29.7334 12.1801 41.0168 11.9607 48.5897C11.804 54.298 11.7164 53.4037 11.497 57.7039C11.2776 62.3276 9.67642 65.5436 9.66075 71.4421C9.66075 75.3428 9.86448 76.7128 10.6951 77.7402C12.1839 79.643 14.7227 79.2244 16.3839 78.996C21.5242 78.292 22.3705 74.8861 25.7086 75.533C28.1064 76.0087 29.031 78.0447 31.131 77.4358C32.1979 77.151 33.1441 76.4077 33.7952 75.3428Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="8" cy="15.201" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="17" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2318)">
        <path d="M16 0.200989L16.8229 3.94009L19.8567 1.60472L18.0837 4.99799L21.9088 5.1591L18.3694 6.61879L21.1962 9.20099L17.5465 8.04409L18.0521 11.8391L16 8.60699L13.9479 11.8391L14.4535 8.04409L10.8038 9.20099L13.6306 6.61879L10.0912 5.1591L13.9163 4.99799L12.1433 1.60472L15.1771 3.94009L16 0.200989Z" fill="#98B250"/>
      </g>
      <circle cx="25" cy="11.201" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <defs>
        <filter id="filter2318" x="9.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M21.3306 13.8845L19.1757 27.5399L14.3543 16.2878C14.2062 8.99589 14.7988 8.9959 14.7988 8.9959C16.8054 5.00854 18.1387 6.81104 19.472 7.94444C20.8053 9.07783 21.3306 13.8845 21.3306 13.8845Z" fill="#DEE1E9"/>
      <path d="M40.287 76.9319C40.287 76.9319 41.4307 88.1177 29.2625 90.0258C17.0943 91.9339 24.0535 87.8965 15.1788 88.1592C6.30414 88.4219 6.08366 85.9608 3.49292 83.4443C2.74004 82.7094 2.15915 81.8159 1.79216 80.8284C1.42517 79.8409 1.28122 78.784 1.37071 77.7339C1.37071 77.7339 0.764368 67.8478 4.26462 64.875C6.23524 63.202 6.80024 59.8559 6.92427 57.2565C6.98039 56.0347 6.95276 54.8105 6.84159 53.5925C6.44012 49.6665 6.51415 45.7063 7.06207 41.7983C7.33769 39.8902 8.68818 31.2485 9.11537 26.1879C9.25219 24.7629 9.1308 23.3248 8.75708 21.9431C8.42989 20.7458 7.90844 19.6106 7.21366 18.5832C6.71386 17.6448 6.40969 16.6141 6.31966 15.5539C6.22963 14.4938 6.35564 13.4263 6.69 12.4165C9.72172 10.2043 14.1177 16.2189 14.1177 16.2189L19.0511 27.6397L21.256 13.813C21.256 13.813 21.7108 9.25023 24.2877 9.55442C25.0449 9.68384 25.7433 10.046 26.2864 10.591C26.8295 11.1359 27.1905 11.8366 27.3195 12.5963C27.1679 19.3714 28.6975 20.3531 28.6975 20.3531C28.6975 20.3531 36.2768 27.3632 34.9125 40.7474C34.1798 47.1836 34.6993 53.7013 36.4422 59.9389C37.2828 62.7042 38.2336 64.4602 38.8676 64.875C40.4523 65.9397 40.3283 69.2996 40.3283 69.2996L40.287 76.9319Z" fill="#DEE1E9"/>
      <mask id="mask2318" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="56" width="41" height="36">
        <path d="M40.1949 77.3256C40.1949 77.3256 41.3391 88.7399 29.1666 90.6728C16.9941 92.6058 23.9971 88.5 15.1193 88.7399C6.24145 88.9797 6.02088 86.4824 3.42923 83.9287C2.67609 83.1787 2.09499 82.267 1.72787 81.2593C1.36075 80.2517 1.21675 79.1731 1.30627 78.1016C1.30627 78.1016 0.699715 67.9995 4.20121 64.966C6.17252 63.2588 5.76611 57.2201 7.76611 57.6919L13.8372 60.0137C15.7804 59.8716 17.7081 59.5601 19.5995 59.0825C23.0459 58.236 24.3279 57.3753 26.892 57.2201C28.8551 57.1169 30.8177 57.434 32.6543 58.1513L36.3764 60.0137C37.2173 62.8355 38.1685 64.6415 38.8026 65.0648C40.3879 66.1512 40.2638 69.5656 40.2638 69.5656L40.1949 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2318)">
        <path d="M40.1949 77.3256C40.1949 77.3256 41.3391 88.7399 29.1666 90.6728C16.9941 92.6058 23.9971 88.5 15.1193 88.7399C6.24145 88.9797 6.02088 86.4824 3.42923 83.9287C2.67609 83.1787 2.09499 82.267 1.72787 81.2593C1.36075 80.2517 1.21675 79.1731 1.30627 78.1016C1.30627 78.1016 0.699715 67.9995 4.20121 64.966C6.17252 63.2588 5.76611 57.2201 7.76611 57.6919L13.8372 60.0137C15.7804 59.8716 17.7081 59.5601 19.5995 59.0825C23.0459 58.236 24.3279 57.3753 26.892 57.2201C28.8551 57.1169 30.8177 57.434 32.6543 58.1513L36.3764 60.0137C37.2173 62.8355 38.1685 64.6415 38.8026 65.0648C40.3879 66.1512 40.2638 69.5656 40.2638 69.5656L40.1949 77.3256Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.5738 75.201C20.5117 75.201 19.2661 80.4993 19.2661 90.0976L21.1892 91.9597L24.2661 93.201C24.2661 83.6027 22.6359 75.201 21.5738 75.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M33.7952 75.3428C36.0206 71.3089 29.6778 66.3047 28.9256 56.7909C28.4554 50.5308 29.3487 50.1538 29.192 40.4498C29.2661 36.7214 28.8589 34.5454 28.4517 30.6034C28.1219 27.4104 27.9434 25.5163 26.1568 20.3978C24.5896 15.7551 25.2019 10.9011 24.4497 10.9011C23.6974 10.9011 23.1478 15.1539 23.0695 17.7797C22.9598 21.0524 24.3075 22.0228 24.6366 26.2469C24.7777 28.1497 24.1101 31.0847 23.4675 31.275C22.5429 31.5414 20.725 26.0614 20.3332 24.9769C16.666 14.036 18.2759 7.43609 17.1005 7.20776C15.9251 6.97943 14.3081 12.5929 15.8282 20.8128C16.4238 24.0284 16.8814 25.4516 18.1665 29.5615C20.4859 36.9061 19.1891 44.0234 18.7503 44.1566C18.3115 44.2898 15.1172 27.6217 11.4971 19.0593C10.4157 16.5096 7.96153 13.2531 7.33466 13.4434C6.70779 13.6337 10.5843 17.7271 10.6784 24.7482C10.6784 29.7334 12.1801 41.0168 11.9607 48.5897C11.804 54.298 11.7164 53.4037 11.497 57.7039C11.2776 62.3276 9.67642 65.5436 9.66075 71.4421C9.66075 75.3428 9.86448 76.7128 10.6951 77.7402C12.1839 79.643 14.7227 79.2244 16.3839 78.996C21.5242 78.292 22.3705 74.8861 25.7086 75.533C28.1064 76.0087 29.031 78.0447 31.131 77.4358C32.1979 77.151 33.1441 76.4077 33.7952 75.3428Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="8" cy="15.201" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="17" cy="8.20099" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2318)">
        <path d="M16 0.200989L16.8229 3.94009L19.8567 1.60472L18.0837 4.99799L21.9088 5.1591L18.3694 6.61879L21.1962 9.20099L17.5465 8.04409L18.0521 11.8391L16 8.60699L13.9479 11.8391L14.4535 8.04409L10.8038 9.20099L13.6306 6.61879L10.0912 5.1591L13.9163 4.99799L12.1433 1.60472L15.1771 3.94009L16 0.200989Z" fill="#98B250"/>
      </g>
      <circle cx="25" cy="11.201" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <defs>
        <filter id="filter2318" x="9.89113" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M21.3306 7.88445L19.1757 21.5398L14.3543 10.2878C14.2062 2.99583 14.7988 2.99584 14.7988 2.99584C16.8054 -0.991523 18.1387 0.810982 19.472 1.94438C20.8053 3.07777 21.3306 7.88445 21.3306 7.88445Z" fill="#DEE1E9"/>
      <path d="M40.287 70.9319C40.287 70.9319 41.4307 82.1177 29.2625 84.0257C17.0943 85.9338 24.0535 81.8964 15.1788 82.1591C6.30414 82.4218 6.08366 79.9607 3.49292 77.4442C2.74004 76.7093 2.15915 75.8158 1.79216 74.8283C1.42517 73.8408 1.28122 72.7839 1.37071 71.7338C1.37071 71.7338 0.764368 61.8477 4.26462 58.875C6.23524 57.202 6.80024 53.8559 6.92427 51.2565C6.98039 50.0347 6.95276 48.8104 6.84159 47.5924C6.44012 43.6665 6.51415 39.7062 7.06207 35.7982C7.33769 33.8901 8.68818 25.2484 9.11537 20.1879C9.25219 18.7628 9.1308 17.3247 8.75708 15.9431C8.42989 14.7457 7.90844 13.6106 7.21366 12.5832C6.71386 11.6448 6.40969 10.6141 6.31966 9.55388C6.22963 8.4937 6.35564 7.42623 6.69 6.41648C9.72172 4.20421 14.1177 10.2188 14.1177 10.2188L19.0511 21.6397L21.256 7.81298C21.256 7.81298 21.7108 3.25017 24.2877 3.55436C25.0449 3.68378 25.7433 4.04599 26.2864 4.5909C26.8295 5.13582 27.1905 5.83654 27.3195 6.59623C27.1679 13.3713 28.6975 14.353 28.6975 14.353C28.6975 14.353 36.2768 21.3631 34.9125 34.7474C34.1798 41.1836 34.6993 47.7013 36.4422 53.9388C37.2828 56.7042 38.2336 58.4602 38.8676 58.875C40.4523 59.9396 40.3283 63.2995 40.3283 63.2995L40.287 70.9319Z" fill="#DEE1E9"/>
      <circle cx="8" cy="7.20093" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <mask id="mask2518" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="36">
        <path d="M40.1949 71.3255C40.1949 71.3255 41.3391 82.7398 29.1666 84.6727C16.9941 86.6057 23.9971 82.4999 15.1193 82.7397C6.24145 82.9796 6.02088 80.4823 3.42923 77.9285C2.67609 77.1786 2.09499 76.2669 1.72787 75.2592C1.36075 74.2515 1.21675 73.173 1.30627 72.1015C1.30627 72.1015 0.699715 61.9993 4.20121 58.9659C6.17252 57.2587 5.76611 51.22 7.76611 51.6918L13.8372 54.0136C15.7804 53.8715 17.7081 53.56 19.5995 53.0824C23.0459 52.2358 24.3279 51.3752 26.892 51.22C28.8551 51.1168 30.8177 51.4339 32.6543 52.1512L36.3764 54.0136C37.2173 56.8354 38.1685 58.6414 38.8026 59.0646C40.3879 60.1511 40.2638 63.5655 40.2638 63.5655L40.1949 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2518)">
        <path d="M40.1949 71.3255C40.1949 71.3255 41.3391 82.7398 29.1666 84.6727C16.9941 86.6057 23.9971 82.4999 15.1193 82.7397C6.24145 82.9796 6.02088 80.4823 3.42923 77.9285C2.67609 77.1786 2.09499 76.2669 1.72787 75.2592C1.36075 74.2515 1.21675 73.173 1.30627 72.1015C1.30627 72.1015 0.699715 61.9993 4.20121 58.9659C6.17252 57.2587 5.76611 51.22 7.76611 51.6918L13.8372 54.0136C15.7804 53.8715 17.7081 53.56 19.5995 53.0824C23.0459 52.2358 24.3279 51.3752 26.892 51.22C28.8551 51.1168 30.8177 51.4339 32.6543 52.1512L36.3764 54.0136C37.2173 56.8354 38.1685 58.6414 38.8026 59.0646C40.3879 60.1511 40.2638 63.5655 40.2638 63.5655L40.1949 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.5738 73.2009C20.5117 73.2009 19.2661 77.3218 19.2661 84.7871L21.1892 86.2354L24.2661 87.2009C24.2661 79.7356 22.6359 73.2009 21.5738 73.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2618" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="86">
        <path d="M21.3306 8.24651L19.1757 21.9019L14.3543 10.6498C14.2062 3.35789 14.7988 3.3579 14.7988 3.3579C16.8054 -0.629462 18.1387 1.17304 19.472 2.30644C20.8053 3.43983 21.3306 8.24651 21.3306 8.24651Z" fill="#C7CAD5"/>
        <path d="M40.287 71.2939C40.287 71.2939 41.4307 82.4797 29.2625 84.3878C17.0943 86.2959 24.0535 82.2585 15.1788 82.5212C6.30414 82.7839 6.08366 80.3228 3.49292 77.8063C2.74004 77.0714 2.15915 76.1779 1.79216 75.1904C1.42517 74.2029 1.28122 73.146 1.37071 72.0959C1.37071 72.0959 0.764368 62.2098 4.26462 59.237C6.23524 57.564 6.80024 54.2179 6.92427 51.6185C6.98039 50.3967 6.95276 49.1725 6.84159 47.9545C6.44012 44.0285 6.51415 40.0683 7.06207 36.1603C7.33769 34.2522 8.68818 25.6105 9.11537 20.5499C9.25219 19.1249 9.1308 17.6868 8.75708 16.3051C8.42989 15.1078 7.90844 13.9726 7.21366 12.9452C6.71386 12.0068 6.40969 10.9761 6.31966 9.91594C6.22963 8.85576 6.35564 7.78829 6.69 6.77854C9.72172 4.56627 14.1177 10.5809 14.1177 10.5809L19.0511 22.0017L21.256 8.17504C21.256 8.17504 21.7108 3.61223 24.2877 3.91642C25.0449 4.04584 25.7433 4.40805 26.2864 4.95296C26.8295 5.49788 27.1905 6.1986 27.3195 6.95829C27.1679 13.7334 28.6975 14.7151 28.6975 14.7151C28.6975 14.7151 36.2768 21.7252 34.9125 35.1094C34.1798 41.5456 34.6993 48.0633 36.4422 54.3009C37.2828 57.0662 38.2336 58.8222 38.8676 59.237C40.4523 60.3017 40.3283 63.6616 40.3283 63.6616L40.287 71.2939Z" fill="#DEE1E9"/>
        <path d="M40.2594 71.0356C40.2594 71.0356 41.4036 82.4498 29.231 84.3828C17.0585 86.3157 24.0615 82.21 15.1837 82.4498C6.3059 82.6897 6.08534 80.1924 3.49368 77.6386C2.74054 76.8887 2.15944 75.977 1.79232 74.9693C1.4252 73.9616 1.2812 72.8831 1.37073 71.8116C1.37073 71.8116 0.764168 61.7094 4.26566 58.676C6.23698 56.9688 5.83057 50.9301 7.83057 51.4019L13.9017 53.7237C15.8448 53.5816 17.7726 53.2701 19.664 52.7925C23.1103 51.9459 24.3924 51.0853 26.9564 50.9301C28.9196 50.8268 30.8822 51.144 32.7188 51.8613L36.4408 53.7237C37.2817 56.5455 38.2329 58.3515 38.867 58.7747C40.4524 59.8611 40.3283 63.2755 40.3283 63.2755L40.2594 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask2618)">
        <path d="M21.3306 8.24651L19.1757 21.9019L14.3543 10.6498C14.2062 3.35789 14.7988 3.3579 14.7988 3.3579C16.8054 -0.629462 18.1387 1.17304 19.472 2.30644C20.8053 3.43983 21.3306 8.24651 21.3306 8.24651Z" fill="#C7CAD5"/>
        <path d="M40.287 71.2939C40.287 71.2939 41.4307 82.4797 29.2625 84.3878C17.0943 86.2959 24.0535 82.2585 15.1788 82.5212C6.30414 82.7839 6.08366 80.3228 3.49292 77.8063C2.74004 77.0714 2.15915 76.1779 1.79216 75.1904C1.42517 74.2029 1.28122 73.146 1.37071 72.0959C1.37071 72.0959 0.764368 62.2098 4.26462 59.237C6.23524 57.564 6.80024 54.2179 6.92427 51.6185C6.98039 50.3967 6.95276 49.1725 6.84159 47.9545C6.44012 44.0285 6.51415 40.0683 7.06207 36.1603C7.33769 34.2522 8.68818 25.6105 9.11537 20.5499C9.25219 19.1249 9.1308 17.6868 8.75708 16.3051C8.42989 15.1078 7.90844 13.9726 7.21366 12.9452C6.71386 12.0068 6.40969 10.9761 6.31966 9.91594C6.22963 8.85576 6.35564 7.78829 6.69 6.77854C9.72172 4.56627 14.1177 10.5809 14.1177 10.5809L19.0511 22.0017L21.256 8.17504C21.256 8.17504 21.7108 3.61223 24.2877 3.91642C25.0449 4.04584 25.7433 4.40805 26.2864 4.95296C26.8295 5.49788 27.1905 6.1986 27.3195 6.95829C27.1679 13.7334 28.6975 14.7151 28.6975 14.7151C28.6975 14.7151 36.2768 21.7252 34.9125 35.1094C34.1798 41.5456 34.6993 48.0633 36.4422 54.3009C37.2828 57.0662 38.2336 58.8222 38.8676 59.237C40.4523 60.3017 40.3283 63.6616 40.3283 63.6616L40.287 71.2939Z" fill="#DEE1E9"/>
        <path d="M40.2594 71.0356C40.2594 71.0356 41.4036 82.4498 29.231 84.3828C17.0585 86.3157 24.0615 82.21 15.1837 82.4498C6.3059 82.6897 6.08534 80.1924 3.49368 77.6386C2.74054 76.8887 2.15944 75.977 1.79232 74.9693C1.4252 73.9616 1.2812 72.8831 1.37073 71.8116C1.37073 71.8116 0.764168 61.7094 4.26566 58.676C6.23698 56.9688 5.83057 50.9301 7.83057 51.4019L13.9017 53.7237C15.8448 53.5816 17.7726 53.2701 19.664 52.7925C23.1103 51.9459 24.3924 51.0853 26.9564 50.9301C28.9196 50.8268 30.8822 51.144 32.7188 51.8613L36.4408 53.7237C37.2817 56.5455 38.2329 58.3515 38.867 58.7747C40.4524 59.8611 40.3283 63.2755 40.3283 63.2755L40.2594 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M24.0259 53.5247H35.9471C33.785 48.1549 33.428 44.8947 33.7245 39.1564C33.7357 38.94 33.4864 38.8054 33.2961 38.932C32.2921 39.6 29.9339 41.4161 29.3622 44.0577C28.6698 47.2577 26.6665 48.0348 26.4539 48.1089C26.441 48.1134 26.4294 48.1179 26.417 48.1236L25.2341 48.6703C25.0895 48.7371 25.0309 48.8996 25.1032 49.0332L25.6321 50.0109C25.6769 50.0936 25.6728 50.1917 25.6213 50.271L23.7749 53.1151C23.6579 53.2954 23.7984 53.5247 24.0259 53.5247Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M32.951 40.0459C31.89 40.8938 30.4821 42.3307 30.0797 44.1903C29.3364 47.6248 27.1605 48.5809 26.7275 48.7361L25.9539 49.0936L26.2865 49.7084C26.4431 49.9979 26.4289 50.3413 26.2487 50.6189L24.8012 52.8485H34.9038C33.1271 48.1838 32.7511 44.9881 32.951 40.0459ZM26.7442 48.7284C26.7444 48.7283 26.7441 48.7285 26.7442 48.7284V48.7284ZM32.8687 38.3832C33.5976 37.8983 34.4933 38.4547 34.4554 39.1886C34.1626 44.8548 34.5118 48.0209 36.6328 53.2889L37 54.2009H24.0259C23.2297 54.2009 22.7379 53.3982 23.1476 52.7672L24.8699 50.114L24.4488 49.3356C24.1958 48.8679 24.4009 48.2992 24.9069 48.0654L26.0898 47.5188C26.1305 47.5 26.1664 47.4864 26.1959 47.4761C26.2325 47.4634 28.0133 46.8432 28.6448 43.9251C29.2743 41.0161 31.8377 39.0691 32.8687 38.3832Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask118" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="41" height="86">
        <path d="M21.3306 8.24651L19.1757 21.9019L14.3543 10.6498C14.2062 3.35789 14.7988 3.3579 14.7988 3.3579C16.8054 -0.629462 18.1387 1.17304 19.472 2.30644C20.8053 3.43983 21.3306 8.24651 21.3306 8.24651Z" fill="#C7CAD5"/>
        <path d="M40.287 71.2939C40.287 71.2939 41.4307 82.4797 29.2625 84.3878C17.0943 86.2959 24.0535 82.2585 15.1788 82.5212C6.30414 82.7839 6.08366 80.3228 3.49292 77.8063C2.74004 77.0714 2.15915 76.1779 1.79216 75.1904C1.42517 74.2029 1.28122 73.146 1.37071 72.0959C1.37071 72.0959 0.764368 62.2098 4.26462 59.237C6.23524 57.564 6.80024 54.2179 6.92427 51.6185C6.98039 50.3967 6.95276 49.1725 6.84159 47.9545C6.44012 44.0285 6.51415 40.0683 7.06207 36.1603C7.33769 34.2522 8.68818 25.6105 9.11537 20.5499C9.25219 19.1249 9.1308 17.6868 8.75708 16.3051C8.42989 15.1078 7.90844 13.9726 7.21366 12.9452C6.71386 12.0068 6.40969 10.9761 6.31966 9.91594C6.22963 8.85576 6.35564 7.78829 6.69 6.77854C9.72172 4.56627 14.1177 10.5809 14.1177 10.5809L19.0511 22.0017L21.256 8.17504C21.256 8.17504 21.7108 3.61223 24.2877 3.91642C25.0449 4.04584 25.7433 4.40805 26.2864 4.95296C26.8295 5.49788 27.1905 6.1986 27.3195 6.95829C27.1679 13.7334 28.6975 14.7151 28.6975 14.7151C28.6975 14.7151 36.2768 21.7252 34.9125 35.1094C34.1798 41.5456 34.6993 48.0633 36.4422 54.3009C37.2828 57.0662 38.2336 58.8222 38.8676 59.237C40.4523 60.3017 40.3283 63.6616 40.3283 63.6616L40.287 71.2939Z" fill="#DEE1E9"/>
        <path d="M40.2594 71.0356C40.2594 71.0356 41.4036 82.4498 29.231 84.3828C17.0585 86.3157 24.0615 82.21 15.1837 82.4498C6.3059 82.6897 6.08534 80.1924 3.49368 77.6386C2.74054 76.8887 2.15944 75.977 1.79232 74.9693C1.4252 73.9616 1.2812 72.8831 1.37073 71.8116C1.37073 71.8116 0.764168 61.7094 4.26566 58.676C6.23698 56.9688 5.83057 50.9301 7.83057 51.4019L13.9017 53.7237C15.8448 53.5816 17.7726 53.2701 19.664 52.7925C23.1103 51.9459 24.3924 51.0853 26.9564 50.9301C28.9196 50.8268 30.8822 51.144 32.7188 51.8613L36.4408 53.7237C37.2817 56.5455 38.2329 58.3515 38.867 58.7747C40.4524 59.8611 40.3283 63.2755 40.3283 63.2755L40.2594 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask118)">
        <path d="M21.3306 8.24651L19.1757 21.9019L14.3543 10.6498C14.2062 3.35789 14.7988 3.3579 14.7988 3.3579C16.8054 -0.629462 18.1387 1.17304 19.472 2.30644C20.8053 3.43983 21.3306 8.24651 21.3306 8.24651Z" fill="#C7CAD5"/>
        <path d="M40.287 71.2939C40.287 71.2939 41.4307 82.4797 29.2625 84.3878C17.0943 86.2959 24.0535 82.2585 15.1788 82.5212C6.30414 82.7839 6.08366 80.3228 3.49292 77.8063C2.74004 77.0714 2.15915 76.1779 1.79216 75.1904C1.42517 74.2029 1.28122 73.146 1.37071 72.0959C1.37071 72.0959 0.764368 62.2098 4.26462 59.237C6.23524 57.564 6.80024 54.2179 6.92427 51.6185C6.98039 50.3967 6.95276 49.1725 6.84159 47.9545C6.44012 44.0285 6.51415 40.0683 7.06207 36.1603C7.33769 34.2522 8.68818 25.6105 9.11537 20.5499C9.25219 19.1249 9.1308 17.6868 8.75708 16.3051C8.42989 15.1078 7.90844 13.9726 7.21366 12.9452C6.71386 12.0068 6.40969 10.9761 6.31966 9.91594C6.22963 8.85576 6.35564 7.78829 6.69 6.77854C9.72172 4.56627 14.1177 10.5809 14.1177 10.5809L19.0511 22.0017L21.256 8.17504C21.256 8.17504 21.7108 3.61223 24.2877 3.91642C25.0449 4.04584 25.7433 4.40805 26.2864 4.95296C26.8295 5.49788 27.1905 6.1986 27.3195 6.95829C27.1679 13.7334 28.6975 14.7151 28.6975 14.7151C28.6975 14.7151 36.2768 21.7252 34.9125 35.1094C34.1798 41.5456 34.6993 48.0633 36.4422 54.3009C37.2828 57.0662 38.2336 58.8222 38.8676 59.237C40.4523 60.3017 40.3283 63.6616 40.3283 63.6616L40.287 71.2939Z" fill="#DEE1E9"/>
        <path d="M40.2594 71.0356C40.2594 71.0356 41.4036 82.4498 29.231 84.3828C17.0585 86.3157 24.0615 82.21 15.1837 82.4498C6.3059 82.6897 6.08534 80.1924 3.49368 77.6386C2.74054 76.8887 2.15944 75.977 1.79232 74.9693C1.4252 73.9616 1.2812 72.8831 1.37073 71.8116C1.37073 71.8116 0.764168 61.7094 4.26566 58.676C6.23698 56.9688 5.83057 50.9301 7.83057 51.4019L13.9017 53.7237C15.8448 53.5816 17.7726 53.2701 19.664 52.7925C23.1103 51.9459 24.3924 51.0853 26.9564 50.9301C28.9196 50.8268 30.8822 51.144 32.7188 51.8613L36.4408 53.7237C37.2817 56.5455 38.2329 58.3515 38.867 58.7747C40.4524 59.8611 40.3283 63.2755 40.3283 63.2755L40.2594 71.0356Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M27.7011 52.5315H35.8479C34.3703 48.561 34.1264 46.1504 34.329 41.9074C34.3367 41.7474 34.1662 41.6479 34.0362 41.7415C33.3501 42.2354 31.7385 43.5782 31.3479 45.5315C30.8746 47.8976 29.5057 48.4722 29.3604 48.527C29.3515 48.5303 29.3436 48.5337 29.3352 48.5379L28.5267 48.9421C28.428 48.9915 28.3879 49.1116 28.4373 49.2104L28.7988 49.9333C28.8293 49.9945 28.8266 50.067 28.7914 50.1257L27.5296 52.2286C27.4496 52.3619 27.5456 52.5315 27.7011 52.5315Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M33.8004 42.5651C33.0753 43.1921 32.1131 44.2545 31.8381 45.6295C31.3302 48.169 29.8432 48.876 29.5473 48.9908L29.0187 49.2551L29.246 49.7097C29.353 49.9238 29.3432 50.1777 29.2201 50.3829L28.2309 52.0315H35.1349C33.9207 48.5824 33.6637 46.2194 33.8004 42.5651ZM29.5587 48.9851C29.5588 48.985 29.5586 48.9851 29.5587 48.9851V48.9851ZM33.7441 41.3357C34.2422 40.9771 34.8543 41.3885 34.8284 41.9312C34.6283 46.1208 34.867 48.4619 36.3164 52.3571L36.5674 53.0315H27.7011C27.157 53.0315 26.8209 52.4379 27.1008 51.9713L28.2779 50.0096L27.9901 49.434C27.8172 49.0882 27.9573 48.6677 28.3031 48.4948L29.1115 48.0906C29.1393 48.0767 29.1638 48.0667 29.184 48.0591C29.209 48.0497 30.426 47.5911 30.8576 45.4334C31.2877 43.2825 33.0395 41.8428 33.7441 41.3357Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M21.3306 7.88445L19.1757 21.5398L14.3543 10.2878C14.2062 2.99583 14.7988 2.99584 14.7988 2.99584C16.8054 -0.991523 18.1387 0.810982 19.472 1.94438C20.8053 3.07777 21.3306 7.88445 21.3306 7.88445Z" fill="#DEE1E9"/>
      <path d="M40.287 70.9319C40.287 70.9319 41.4307 82.1177 29.2625 84.0257C17.0943 85.9338 24.0535 81.8964 15.1788 82.1591C6.30414 82.4218 6.08366 79.9607 3.49292 77.4442C2.74004 76.7093 2.15915 75.8158 1.79216 74.8283C1.42517 73.8408 1.28122 72.7839 1.37071 71.7338C1.37071 71.7338 0.764368 61.8477 4.26462 58.875C6.23524 57.202 6.80024 53.8559 6.92427 51.2565C6.98039 50.0347 6.95276 48.8104 6.84159 47.5924C6.44012 43.6665 6.51415 39.7062 7.06207 35.7982C7.33769 33.8901 8.68818 25.2484 9.11537 20.1879C9.25219 18.7628 9.1308 17.3247 8.75708 15.9431C8.42989 14.7457 7.90844 13.6106 7.21366 12.5832C6.71386 11.6448 6.40969 10.6141 6.31966 9.55388C6.22963 8.4937 6.35564 7.42623 6.69 6.41648C9.72172 4.20421 14.1177 10.2188 14.1177 10.2188L19.0511 21.6397L21.256 7.81298C21.256 7.81298 21.7108 3.25017 24.2877 3.55436C25.0449 3.68378 25.7433 4.04599 26.2864 4.5909C26.8295 5.13582 27.1905 5.83654 27.3195 6.59623C27.1679 13.3713 28.6975 14.353 28.6975 14.353C28.6975 14.353 36.2768 21.3631 34.9125 34.7474C34.1798 41.1836 34.6993 47.7013 36.4422 53.9388C37.2828 56.7042 38.2336 58.4602 38.8676 58.875C40.4523 59.9396 40.3283 63.2995 40.3283 63.2995L40.287 70.9319Z" fill="#DEE1E9"/>
      <mask id="mask218" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="36">
        <path d="M40.1949 71.3255C40.1949 71.3255 41.3391 82.7398 29.1666 84.6727C16.9941 86.6057 23.9971 82.4999 15.1193 82.7397C6.24145 82.9796 6.02088 80.4823 3.42923 77.9285C2.67609 77.1786 2.09499 76.2669 1.72787 75.2592C1.36075 74.2515 1.21675 73.173 1.30627 72.1015C1.30627 72.1015 0.699715 61.9993 4.20121 58.9659C6.17252 57.2587 5.76611 51.22 7.76611 51.6918L13.8372 54.0136C15.7804 53.8715 17.7081 53.56 19.5995 53.0824C23.0459 52.2358 24.3279 51.3752 26.892 51.22C28.8551 51.1168 30.8177 51.4339 32.6543 52.1512L36.3764 54.0136C37.2173 56.8354 38.1685 58.6414 38.8026 59.0646C40.3879 60.1511 40.2638 63.5655 40.2638 63.5655L40.1949 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask218)">
        <path d="M40.1949 71.3255C40.1949 71.3255 41.3391 82.7398 29.1666 84.6727C16.9941 86.6057 23.9971 82.4999 15.1193 82.7397C6.24145 82.9796 6.02088 80.4823 3.42923 77.9285C2.67609 77.1786 2.09499 76.2669 1.72787 75.2592C1.36075 74.2515 1.21675 73.173 1.30627 72.1015C1.30627 72.1015 0.699715 61.9993 4.20121 58.9659C6.17252 57.2587 5.76611 51.22 7.76611 51.6918L13.8372 54.0136C15.7804 53.8715 17.7081 53.56 19.5995 53.0824C23.0459 52.2358 24.3279 51.3752 26.892 51.22C28.8551 51.1168 30.8177 51.4339 32.6543 52.1512L36.3764 54.0136C37.2173 56.8354 38.1685 58.6414 38.8026 59.0646C40.3879 60.1511 40.2638 63.5655 40.2638 63.5655L40.1949 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.1123 71.2009C17.5012 71.2009 13.2661 75.9105 13.2661 84.4423L19.8046 86.0975L30.2661 87.2009C30.2661 78.6691 24.7234 71.2009 21.1123 71.2009Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M33.7952 69.3426C36.0206 65.3088 29.6778 60.3046 28.9256 50.7908C28.4554 44.5307 29.3487 44.1537 29.192 34.4497C29.2661 30.7213 28.8589 28.5453 28.4517 24.6033C28.1219 21.4102 27.9434 19.5161 26.1568 14.3977C24.5896 9.755 25.2019 4.90103 24.4497 4.90103C23.6974 4.90103 23.1478 9.15373 23.0695 11.7795C22.9598 15.0523 24.3075 16.0227 24.6366 20.2468C24.7777 22.1495 24.1101 25.0846 23.4675 25.2749C22.5429 25.5413 20.725 20.0613 20.3332 18.9767C16.666 8.03589 18.2759 1.43597 17.1005 1.20764C15.9251 0.97931 14.3081 6.59276 15.8282 14.8127C16.4238 18.0283 16.8814 19.4514 18.1665 23.5614C20.4859 30.906 19.1891 38.0233 18.7503 38.1565C18.3115 38.2897 15.1172 21.6216 11.4971 13.0592C10.4157 10.5095 7.96153 7.25299 7.33466 7.44326C6.70779 7.63354 10.5843 11.7269 10.6784 18.7481C10.6784 23.7333 12.1801 35.0166 11.9607 42.5896C11.804 48.2979 11.7164 47.4036 11.497 51.7038C11.2776 56.3275 9.67642 59.5435 9.66075 65.442C9.66075 69.3427 9.86448 70.7126 10.6951 71.7401C12.1839 73.6429 14.7227 73.2242 16.3839 72.9959C21.5242 72.2919 22.3705 68.886 25.7086 69.5329C28.1064 70.0086 29.031 72.0446 31.131 71.4357C32.1979 71.1509 33.1441 70.4076 33.7952 69.3426Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M21.3306 7.88445L19.1757 21.5398L14.3543 10.2878C14.2062 2.99583 14.7988 2.99584 14.7988 2.99584C16.8054 -0.991523 18.1387 0.810982 19.472 1.94438C20.8053 3.07777 21.3306 7.88445 21.3306 7.88445Z" fill="#DEE1E9"/>
      <path d="M40.287 70.9319C40.287 70.9319 41.4307 82.1177 29.2625 84.0257C17.0943 85.9338 24.0535 81.8964 15.1788 82.1591C6.30414 82.4218 6.08366 79.9607 3.49292 77.4442C2.74004 76.7093 2.15915 75.8158 1.79216 74.8283C1.42517 73.8408 1.28122 72.7839 1.37071 71.7338C1.37071 71.7338 0.764368 61.8477 4.26462 58.875C6.23524 57.202 6.80024 53.8559 6.92427 51.2565C6.98039 50.0347 6.95276 48.8104 6.84159 47.5924C6.44012 43.6665 6.51415 39.7062 7.06207 35.7982C7.33769 33.8901 8.68818 25.2484 9.11537 20.1879C9.25219 18.7628 9.1308 17.3247 8.75708 15.9431C8.42989 14.7457 7.90844 13.6106 7.21366 12.5832C6.71386 11.6448 6.40969 10.6141 6.31966 9.55388C6.22963 8.4937 6.35564 7.42623 6.69 6.41648C9.72172 4.20421 14.1177 10.2188 14.1177 10.2188L19.0511 21.6397L21.256 7.81298C21.256 7.81298 21.7108 3.25017 24.2877 3.55436C25.0449 3.68378 25.7433 4.04599 26.2864 4.5909C26.8295 5.13582 27.1905 5.83654 27.3195 6.59623C27.1679 13.3713 28.6975 14.353 28.6975 14.353C28.6975 14.353 36.2768 21.3631 34.9125 34.7474C34.1798 41.1836 34.6993 47.7013 36.4422 53.9388C37.2828 56.7042 38.2336 58.4602 38.8676 58.875C40.4523 59.9396 40.3283 63.2995 40.3283 63.2995L40.287 70.9319Z" fill="#DEE1E9"/>
      <mask id="mask318" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="50" width="41" height="36">
        <path d="M40.1949 71.3255C40.1949 71.3255 41.3391 82.7398 29.1666 84.6727C16.9941 86.6057 23.9971 82.4999 15.1193 82.7397C6.24145 82.9796 6.02088 80.4823 3.42923 77.9285C2.67609 77.1786 2.09499 76.2669 1.72787 75.2592C1.36075 74.2515 1.21675 73.173 1.30627 72.1015C1.30627 72.1015 0.699715 61.9993 4.20121 58.9659C6.17252 57.2587 5.76611 51.22 7.76611 51.6918L13.8372 54.0136C15.7804 53.8715 17.7081 53.56 19.5995 53.0824C23.0459 52.2358 24.3279 51.3752 26.892 51.22C28.8551 51.1168 30.8177 51.4339 32.6543 52.1512L36.3764 54.0136C37.2173 56.8354 38.1685 58.6414 38.8026 59.0646C40.3879 60.1511 40.2638 63.5655 40.2638 63.5655L40.1949 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask318)">
        <path d="M40.1949 71.3255C40.1949 71.3255 41.3391 82.7398 29.1666 84.6727C16.9941 86.6057 23.9971 82.4999 15.1193 82.7397C6.24145 82.9796 6.02088 80.4823 3.42923 77.9285C2.67609 77.1786 2.09499 76.2669 1.72787 75.2592C1.36075 74.2515 1.21675 73.173 1.30627 72.1015C1.30627 72.1015 0.699715 61.9993 4.20121 58.9659C6.17252 57.2587 5.76611 51.22 7.76611 51.6918L13.8372 54.0136C15.7804 53.8715 17.7081 53.56 19.5995 53.0824C23.0459 52.2358 24.3279 51.3752 26.892 51.22C28.8551 51.1168 30.8177 51.4339 32.6543 52.1512L36.3764 54.0136C37.2173 56.8354 38.1685 58.6414 38.8026 59.0646C40.3879 60.1511 40.2638 63.5655 40.2638 63.5655L40.1949 71.3255Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
        <path d="M21.5738 73.2009C20.5117 73.2009 19.2661 77.3218 19.2661 84.7871L21.1892 86.2354L24.2661 87.2009C24.2661 79.7356 22.6359 73.2009 21.5738 73.2009Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M21.3306 7.37957L19.1757 21.0349L14.3543 9.7829C14.2062 2.49095 14.7988 2.49095 14.7988 2.49095C16.8054 -1.49641 18.1387 0.306099 19.472 1.43949C20.8053 2.57289 21.3306 7.37957 21.3306 7.37957Z" fill="#C7CAD5"/>
      <path d="M40.287 70.427C40.287 70.427 41.4307 81.6128 29.2625 83.5209C17.0943 85.4289 24.0535 81.3915 15.1788 81.6543C6.30414 81.917 6.08366 79.4558 3.49292 76.9393C2.74004 76.2044 2.15915 75.311 1.79216 74.3235C1.42517 73.336 1.28122 72.279 1.37071 71.2289C1.37071 71.2289 0.764368 61.3428 4.26462 58.3701C6.23524 56.6971 6.80024 53.351 6.92427 50.7516C6.98039 49.5298 6.95276 48.3055 6.84159 47.0875C6.44012 43.1616 6.51415 39.2014 7.06207 35.2933C7.33769 33.3853 8.68818 24.7436 9.11537 19.683C9.25219 18.258 9.1308 16.8198 8.75708 15.4382C8.42989 14.2408 7.90844 13.1057 7.21366 12.0783C6.71386 11.1399 6.40969 10.1092 6.31966 9.04899C6.22963 7.98882 6.35564 6.92135 6.69 5.9116C9.72172 3.69933 14.1177 9.71394 14.1177 9.71394L19.0511 21.1348L21.256 7.30809C21.256 7.30809 21.7108 2.74529 24.2877 3.04947C25.0449 3.1789 25.7433 3.5411 26.2864 4.08602C26.8295 4.63094 27.1905 5.33166 27.3195 6.09134C27.1679 12.8664 28.6975 13.8481 28.6975 13.8481C28.6975 13.8481 36.2768 20.8583 34.9125 34.2425C34.1798 40.6787 34.6993 47.1964 36.4422 53.434C37.2828 56.1993 38.2336 57.9553 38.8676 58.3701C40.4523 59.4348 40.3283 62.7946 40.3283 62.7946L40.287 70.427Z" fill="#DEE1E9"/>
      <path d="M40.2594 70.1685C40.2594 70.1685 41.4036 81.5828 29.231 83.5157C17.0585 85.4487 24.0615 81.3429 15.1837 81.5828C6.3059 81.8226 6.08534 79.3253 3.49368 76.7716C2.74054 76.0216 2.15944 75.1099 1.79232 74.1022C1.4252 73.0946 1.2812 72.016 1.37073 70.9445C1.37073 70.9445 0.764168 60.8424 4.26566 57.8089C6.23698 56.1017 5.83057 50.063 7.83057 50.5348L13.9017 52.8566C15.8448 52.7145 17.7726 52.403 19.664 51.9254C23.1103 51.0789 24.3924 50.2182 26.9564 50.063C28.9196 49.9598 30.8822 50.2769 32.7188 50.9942L36.4408 52.8566C37.2817 55.6784 38.2329 57.4844 38.867 57.9077C40.4524 58.9941 40.3283 62.4085 40.3283 62.4085L40.2594 70.1685Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M9.26611 65.2009L11.1328 67.7018L12.7661 66.2061V69.5201L15.3283 68.9522L13.9328 70.7028L16.2661 72.7035L13.9328 74.7042L15.3283 76.4548L12.9994 75.7045L12.7661 79.2009L10.5162 77.7039L9.73278 78.7056L8.01602 77.7039L5.76611 79.2009L5.06611 77.2051L3.20394 76.4548L3.66611 73.7038L2.26611 72.7035L4.59945 71.203L3.20394 68.9522L5.06611 68.7021L5.76611 66.2061L8.79945 68.202L9.26611 65.2009Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M40.287 67.427C40.287 67.427 41.4307 78.6128 29.2625 80.5209C17.0943 82.4289 24.0535 78.3915 15.1788 78.6543C6.30414 78.917 6.08366 76.4558 3.49292 73.9393C2.74004 73.2044 2.15915 72.311 1.79216 71.3235C1.42517 70.336 1.28122 69.279 1.37071 68.2289C1.37071 68.2289 0.764368 58.3428 4.26462 55.3701C6.23524 53.6971 6.80024 50.351 6.92427 47.7516C6.98039 46.5298 6.95276 45.3055 6.84159 44.0875C6.44012 40.1616 6.51415 36.2014 7.06207 32.2933C7.33769 30.3853 8.68818 21.7436 9.11537 16.683C9.25219 15.258 9.1308 13.8198 8.75708 12.4382C8.42989 11.2408 7.90844 10.1057 7.21366 9.07831C6.71386 8.13987 6.40969 7.10917 6.31966 6.04899C6.22963 4.98882 6.35564 3.92135 6.69 2.9116C9.72172 0.699327 14.1177 6.71394 14.1177 6.71394L19.0511 18.1348L21.256 4.30809C21.256 4.30809 21.7108 -0.254715 24.2877 0.0494726C25.0449 0.178901 25.7433 0.541104 26.2864 1.08602C26.8295 1.63094 27.1905 2.33166 27.3195 3.09134C27.1679 9.86642 28.6975 10.8481 28.6975 10.8481C28.6975 10.8481 36.2768 17.8583 34.9125 31.2425C34.1798 37.6787 34.6993 44.1964 36.4422 50.434C37.2828 53.1993 38.2336 54.9553 38.8676 55.3701C40.4523 56.4348 40.3283 59.7946 40.3283 59.7946L40.287 67.427Z" fill="#DEE1E9"/>
      <path d="M40.2594 67.1685C40.2594 67.1685 41.4036 78.5828 29.231 80.5157C17.0585 82.4487 24.0615 78.3429 15.1837 78.5828C6.3059 78.8226 6.08534 76.3253 3.49368 73.7716C2.74054 73.0216 2.15944 72.1099 1.79232 71.1022C1.4252 70.0946 1.2812 69.016 1.37073 67.9445C1.37073 67.9445 0.764168 57.8424 4.26566 54.8089C6.23698 53.1017 5.83057 47.063 7.83057 47.5348L13.9017 49.8566C15.8448 49.7145 17.7726 49.403 19.664 48.9254C23.1103 48.0789 24.3924 47.2182 26.9564 47.063C28.9196 46.9598 30.8822 47.2769 32.7188 47.9942L36.4408 49.8566C37.2817 52.6784 38.2329 54.4844 38.867 54.9077C40.4524 55.9941 40.3283 59.4085 40.3283 59.4085L40.2594 67.1685Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M8.26611 64.2009L9.86611 66.3445L11.2661 65.0625V67.9031L13.4623 67.4163L12.2661 68.9168L14.2661 70.6317L12.2661 72.3466L13.4623 73.8471L11.4661 73.204L11.2661 76.2009L9.33762 74.9178L8.66611 75.7763L7.1946 74.9178L5.26611 76.2009L4.66611 74.4902L3.06996 73.8471L3.46611 71.4891L2.26611 70.6317L4.26611 69.3456L3.06996 67.4163L4.66611 67.202L5.26611 65.0625L7.86611 66.7732L8.26611 64.2009Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M21.3306 8.31194L19.1757 21.9673L14.3543 10.7153C14.2062 3.42332 14.7988 3.42333 14.7988 3.42333C16.8054 -0.564033 18.1387 1.23847 19.472 2.37187C20.8053 3.50526 21.3306 8.31194 21.3306 8.31194Z" fill="#C7CAD5"/>
      <path d="M40.287 71.3593C40.287 71.3593 41.4307 82.5452 29.2625 84.4532C17.0943 86.3613 24.0535 82.3239 15.1788 82.5866C6.30414 82.8493 6.08366 80.3882 3.49292 77.8717C2.74004 77.1368 2.15915 76.2433 1.79216 75.2558C1.42517 74.2683 1.28122 73.2114 1.37071 72.1613C1.37071 72.1613 0.764368 62.2752 4.26462 59.3025C6.23524 57.6294 6.80024 54.2834 6.92427 51.684C6.98039 50.4621 6.95276 49.2379 6.84159 48.0199C6.44012 44.094 6.51415 40.1337 7.06207 36.2257C7.33769 34.3176 8.68818 25.6759 9.11537 20.6154C9.25219 19.1903 9.1308 17.7522 8.75708 16.3706C8.42989 15.1732 7.90844 14.0381 7.21366 13.0107C6.71386 12.0722 6.40969 11.0415 6.31966 9.98137C6.22963 8.92119 6.35564 7.85372 6.69 6.84397C9.72172 4.6317 14.1177 10.6463 14.1177 10.6463L19.0511 22.0672L21.256 8.24047C21.256 8.24047 21.7108 3.67766 24.2877 3.98185C25.0449 4.11127 25.7433 4.47348 26.2864 5.01839C26.8295 5.56331 27.1905 6.26403 27.3195 7.02372C27.1679 13.7988 28.6975 14.7805 28.6975 14.7805C28.6975 14.7805 36.2768 21.7906 34.9125 35.1749C34.1798 41.6111 34.6993 48.1288 36.4422 54.3663C37.2828 57.1317 38.2336 58.8877 38.8676 59.3025C40.4523 60.3671 40.3283 63.727 40.3283 63.727L40.287 71.3593Z" fill="#DEE1E9"/>
      <path d="M40.2594 71.1011C40.2594 71.1011 41.4036 82.5154 29.231 84.4483C17.0585 86.3813 24.0615 82.2755 15.1837 82.5154C6.3059 82.7552 6.08534 80.2579 3.49368 77.7042C2.74054 76.9542 2.15944 76.0425 1.79232 75.0348C1.4252 74.0272 1.2812 72.9487 1.37073 71.8771C1.37073 71.8771 0.764168 61.775 4.26566 58.7415C6.23698 57.0343 5.83057 50.9956 7.83057 51.4674L13.9017 53.7892C15.8448 53.6471 17.7726 53.3356 19.664 52.858C23.1103 52.0115 24.3924 51.1508 26.9564 50.9956C28.9196 50.8924 30.8822 51.2096 32.7188 51.9268L36.4408 53.7892C37.2817 56.611 38.2329 58.417 38.867 58.8403C40.4524 59.9267 40.3283 63.3411 40.3283 63.3411L40.2594 71.1011Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path d="M6.76611 72.1333L7.69945 73.3837L8.51611 72.6359V74.2929L9.7972 74.0089L9.09945 74.8842L10.2661 75.8846L9.09945 76.8849L9.7972 77.7602L8.63278 77.3851L8.51611 79.1333L7.39116 78.3848L6.99945 78.8856L6.14106 78.3848L5.01611 79.1333L4.66611 78.1354L3.73502 77.7602L3.96611 76.3848L3.26611 75.8846L4.43278 75.1343L3.73502 74.0089L4.66611 73.8839L5.01611 72.6359L6.53278 73.6338L6.76611 72.1333Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

      <path d="M17.6992 2.68065L19.7829 1.41089H23.2666L25.3666 2.68065L23.755 4.22716L25.8387 5.77367L23.9503 7.32018L26.2457 9.0132L24.4224 10.5597L26.7177 11.9597L24.6991 13.376L26.7177 14.9876L25.0247 16.5341L27.255 17.9504L25.4968 19.4155L27.7922 20.962L25.6433 22.5085L27.7922 24.1201L26.2457 25.4713L28.3294 27.0178L26.5061 28.4992L28.5898 30.0457L26.7829 31.7876L27.7922 33.4643V47.7247L25.9038 55.0503H19.7829" fill="#8889BE"/>
      <path d="M17.6992 2.68065L19.7829 1.41089H23.2666L25.3666 2.68065L23.755 4.22716L25.8387 5.77367L23.9503 7.32018L26.2457 9.0132L24.4224 10.5597L26.7177 11.9597L24.6991 13.376L26.7177 14.9876L25.0247 16.5341L27.255 17.9504L25.4968 19.4155L27.7922 20.962L25.6433 22.5085L27.7922 24.1201L26.2457 25.4713L28.3294 27.0178L26.5061 28.4992L28.5898 30.0457L26.7829 31.7876L27.7922 33.4643V47.7247L25.9038 55.0503H19.7829" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M25.1064 2.68065L23.0227 1.41089H19.5553L17.439 2.68065L19.0669 4.22716L16.9669 5.77367L18.8553 7.32018L16.5762 9.0132L18.3832 10.5597L16.1041 11.9597L18.1227 13.376L16.1041 14.9876L17.7809 16.5341L15.5669 17.9504L17.3088 19.4155L15.0297 20.962L17.1785 22.5085L15.0297 24.1201L16.5762 25.4713L14.4925 27.0178L16.2995 28.4992L14.2158 30.0457L16.039 31.7876L15.0297 33.4643V47.7247L16.9018 55.0503H20.8088" fill="#8889BE"/>
      <path d="M25.1064 2.68065L23.0227 1.41089H19.5553L17.439 2.68065L19.0669 4.22716L16.9669 5.77367L18.8553 7.32018L16.5762 9.0132L18.3832 10.5597L16.1041 11.9597L18.1227 13.376L16.1041 14.9876L17.7809 16.5341L15.5669 17.9504L17.3088 19.4155L15.0297 20.962L17.1785 22.5085L15.0297 24.1201L16.5762 25.4713L14.4925 27.0178L16.2995 28.4992L14.2158 30.0457L16.039 31.7876L15.0297 33.4643V47.7247L16.9018 55.0503H20.8088" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M39.9288 60.3466C39.9288 60.3466 41.073 71.7609 28.9005 73.6939C16.728 75.6268 23.7309 71.5211 14.8531 71.7609C5.97534 72.0008 5.75477 69.5035 3.16311 66.9497C2.40997 66.1998 1.82888 65.2881 1.46176 64.2804C1.09463 63.2727 0.950637 62.1942 1.04016 61.1227C1.04016 61.1227 0.433602 51.0205 3.9351 47.9871C5.90641 46.2799 5.5 40.2411 7.5 40.713C7.5 40.713 11.9386 43.1012 13.2207 43.6962C14.5027 44.2913 14.278 53.9896 16.1694 53.512C19.6363 54.1052 23.6233 55.0522 26.1694 53.512C28.1326 53.4088 28.3733 41.6884 30.6339 42.1622C32.8945 42.636 36.1102 43.0348 36.1102 43.0348C36.9512 45.8566 37.9023 47.6626 38.5365 48.0858C40.1218 49.1722 39.9977 52.5866 39.9977 52.5866L39.9288 60.3466Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask518" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="40" width="41" height="35">
        <path d="M39.9288 60.3466C39.9288 60.3466 41.073 71.761 28.9005 73.6939C16.728 75.6269 23.7309 71.5211 14.8531 71.7609C5.97534 72.0008 5.75477 69.5035 3.16311 66.9497C2.40997 66.1998 1.82888 65.2881 1.46176 64.2804C1.09463 63.2727 0.950637 62.1942 1.04016 61.1227C1.04016 61.1227 0.433602 51.0205 3.9351 47.9871C5.90641 46.2799 5.7806 41.0103 7.7806 41.4822L12.8817 43.486C14.8249 43.3439 14.278 53.9896 16.1694 53.512C19.7492 54.0012 23.7191 54.8105 26.1694 53.512C28.1326 53.4088 28.0484 42.3018 30.5534 42.3018L36 44.1868C36.8409 47.0086 37.9023 47.6626 38.5365 48.0858C40.1218 49.1722 39.9977 52.5866 39.9977 52.5866L39.9288 60.3466Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      </mask>
      <g mask="url(#mask518)">
        <path d="M10 32.6868H33V58.2347C24.0179 60.161 18.9821 60.1805 10 58.2347V32.6868Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M17.9445 2.04711L20.0282 0.777344H23.5119L25.6119 2.04711L24.0003 3.59362L26.084 5.14013L24.1956 6.68663L26.491 8.37965L24.6677 9.92616L26.963 11.3262L24.9444 12.7424L26.963 14.3541L25.27 15.9006L27.5003 17.3168L25.7421 18.782L28.0375 20.3285L25.8886 21.875L28.0375 23.4866L26.491 24.8378L28.5747 26.3843L26.7514 27.8657L28.8351 29.4122L27.0282 31.154L28.0375 32.8308V47.0912L26.1491 54.4168H20.0282" fill="#8889BE"/>
      <path d="M17.9445 2.04711L20.0282 0.777344H23.5119L25.6119 2.04711L24.0003 3.59362L26.084 5.14013L24.1956 6.68663L26.491 8.37965L24.6677 9.92616L26.963 11.3262L24.9444 12.7424L26.963 14.3541L25.27 15.9006L27.5003 17.3168L25.7421 18.782L28.0375 20.3285L25.8886 21.875L28.0375 23.4866L26.491 24.8378L28.5747 26.3843L26.7514 27.8657L28.8351 29.4122L27.0282 31.154L28.0375 32.8308V47.0912L26.1491 54.4168H20.0282" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M25.3517 2.04711L23.268 0.777344H19.8006L17.6843 2.04711L19.3122 3.59362L17.2122 5.14013L19.1006 6.68663L16.8215 8.37965L18.6285 9.92616L16.3494 11.3262L18.368 12.7424L16.3494 14.3541L18.0262 15.9006L15.8122 17.3168L17.5541 18.782L15.275 20.3285L17.4238 21.875L15.275 23.4866L16.8215 24.8378L14.7378 26.3843L16.5448 27.8657L14.4611 29.4122L16.2843 31.154L15.275 32.8308V47.0912L17.1471 54.4168H21.0541" fill="#8889BE"/>
      <path d="M25.3517 2.04711L23.268 0.777344H19.8006L17.6843 2.04711L19.3122 3.59362L17.2122 5.14013L19.1006 6.68663L16.8215 8.37965L18.6285 9.92616L16.3494 11.3262L18.368 12.7424L16.3494 14.3541L18.0262 15.9006L15.8122 17.3168L17.5541 18.782L15.275 20.3285L17.4238 21.875L15.275 23.4866L16.8215 24.8378L14.7378 26.3843L16.5448 27.8657L14.4611 29.4122L16.2843 31.154L15.275 32.8308V47.0912L17.1471 54.4168H21.0541" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M39.9288 56.511C39.9288 56.511 41.073 67.9252 28.9005 69.8582C16.728 71.7911 23.7309 67.6854 14.8531 67.9252C5.97534 68.1651 5.75477 65.6678 3.16311 63.114C2.40997 62.3641 1.82888 61.4524 1.46176 60.4447C1.09463 59.437 0.950637 58.3585 1.04016 57.287C1.04016 57.287 0.433602 47.1848 3.9351 44.1514C5.90641 42.4442 5.5 36.4055 7.5 36.8773L13.5711 39.1991C15.5142 39.057 15.089 50.3199 15.089 50.3199C18.9942 52.0614 24.1376 52.0617 28.0429 50.3199C28.0429 50.3199 28.6634 36.6338 30.5 37.3511L36.1102 39.1991C36.9512 42.0209 37.9023 43.8269 38.5365 44.2501C40.1218 45.3365 39.9977 48.7509 39.9977 48.7509L39.9288 56.511Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-linejoin="round"/>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M21 82.771H42V87.771H21V82.771Z" fill="#838AA2"/>
      <path d="M20.3306 7.95013L18.1757 21.6055L13.3543 10.3535C13.2062 3.06151 13.7988 3.06151 13.7988 3.06151C15.8054 -0.925849 17.1387 0.876656 18.472 2.01005C19.8053 3.14344 20.3306 7.95013 20.3306 7.95013Z" fill="#C7CAD5"/>
      <path d="M39.287 70.9975C39.287 70.9975 40.4307 82.1833 28.2625 84.0914C16.0943 85.9995 23.0535 81.9621 14.1788 82.2248C5.30414 82.4875 5.08366 80.0264 2.49292 77.5099C1.74004 76.775 1.15915 75.8815 0.792159 74.894C0.425166 73.9065 0.281221 72.8496 0.370711 71.7995C0.370711 71.7995 -0.235632 61.9134 3.26462 58.9407C5.23524 57.2676 5.80024 53.9216 5.92427 51.3221C5.98039 50.1003 5.95276 48.8761 5.84159 47.6581C5.44012 43.7322 5.51415 39.7719 6.06207 35.8639C6.33769 33.9558 7.68818 25.3141 8.11537 20.2535C8.25219 18.8285 8.1308 17.3904 7.75708 16.0087C7.42989 14.8114 6.90844 13.6762 6.21366 12.6489C5.71386 11.7104 5.40969 10.6797 5.31966 9.61955C5.22963 8.55937 5.35564 7.4919 5.69 6.48216C8.72172 4.26988 13.1177 10.2845 13.1177 10.2845L18.0511 21.7053L20.256 7.87865C20.256 7.87865 20.7108 3.31584 23.2877 3.62003C24.0449 3.74946 24.7433 4.11166 25.2864 4.65658C25.8295 5.2015 26.1905 5.90222 26.3195 6.6619C26.1679 13.437 27.6975 14.4187 27.6975 14.4187C27.6975 14.4187 35.2768 21.4288 33.9125 34.8131C33.1798 41.2493 33.6993 47.767 35.4422 54.0045C36.2828 56.7699 37.2336 58.5259 37.8676 58.9407C39.4523 60.0053 39.3283 63.3652 39.3283 63.3652L39.287 70.9975Z" fill="#DEE1E9"/>
      <path d="M38.9288 70.5642C38.9288 70.5642 40.073 81.9785 27.9005 83.9114C15.728 85.8444 22.7309 81.7386 13.8531 81.9785C4.97534 82.2183 4.75477 79.721 2.16311 77.1672C1.40997 76.4173 0.828879 75.5056 0.461756 74.4979C0.0946328 73.4902 -0.0493633 72.4117 0.0401592 71.3402C0.0401592 71.3402 -0.566398 61.2381 2.9351 58.2046C4.90641 56.4974 4.5 50.4587 6.5 50.9305L12.5711 53.2523C14.5142 53.1102 13.7239 64.3731 13.7239 64.3731C17.6291 66.1146 23.5423 66.1149 27.4475 64.3731C27.4475 64.3731 27.6634 50.687 29.5 51.4043L35.1102 53.2523C35.9512 56.0741 36.9023 57.8801 37.5365 58.3034C39.1218 59.3898 38.9977 62.8042 38.9977 62.8042L38.9288 70.5642Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M21.0001 7.58782L18.8453 21.2432L14.0239 9.99115C13.8758 2.6992 14.4683 2.69921 14.4683 2.69921C16.475 -1.28815 17.8083 0.514351 19.1416 1.64774C20.4749 2.78114 21.0001 7.58782 21.0001 7.58782Z" fill="#DEE1E9"/>
      <path d="M39.9565 70.6352C39.9565 70.6352 41.1003 81.821 28.9321 83.7291C16.7639 85.6372 23.723 81.5998 14.8484 81.8625C5.9737 82.1252 5.75321 79.6641 3.16247 77.1476C2.4096 76.4127 1.82871 75.5192 1.46171 74.5317C1.09472 73.5442 0.950776 72.4873 1.04027 71.4372C1.04027 71.4372 0.433924 61.5511 3.93418 58.5784C5.9048 56.9053 6.4698 53.5593 6.59382 50.9598C6.64994 49.738 6.62232 48.5138 6.51114 47.2958C6.10967 43.3699 6.18371 39.4096 6.73163 35.5016C7.00724 33.5935 8.35773 24.9518 8.78493 19.8912C8.92175 18.4662 8.80036 17.0281 8.42664 15.6464C8.09945 14.4491 7.578 13.3139 6.88321 12.2866C6.38342 11.3481 6.07924 10.3174 5.98921 9.25725C5.89919 8.19707 6.02519 7.1296 6.35956 6.11985C9.39127 3.90758 13.7873 9.92219 13.7873 9.92219L18.7207 21.343L20.9256 7.51635C20.9256 7.51635 21.3803 2.95354 23.9573 3.25772C24.7144 3.38715 25.4128 3.74936 25.9559 4.29427C26.499 4.83919 26.86 5.53991 26.989 6.29959C26.8374 13.0747 28.3671 14.0564 28.3671 14.0564C28.3671 14.0564 35.9464 21.0665 34.5821 34.4508C33.8494 40.8869 34.3689 47.4047 36.1117 53.6422C36.9524 56.4076 37.9032 58.1636 38.5371 58.5784C40.1219 59.643 39.9978 63.0029 39.9978 63.0029L39.9565 70.6352Z" fill="#DEE1E9"/>
      <path d="M39.9289 70.377C39.9289 70.377 41.0731 81.7913 28.9006 83.7242C16.7281 85.6572 23.7311 81.5514 14.8533 81.7913C5.97546 82.0311 5.75489 79.5338 3.16323 76.9801C2.4101 76.2301 1.829 75.3184 1.46188 74.3107C1.09475 73.3031 0.950759 72.2245 1.04028 71.153C1.04028 71.153 0.433724 61.0509 3.93522 58.0174C5.90653 56.3102 5.50012 50.2715 7.50012 50.7433L13.5712 53.0651C15.5144 52.923 17.4421 52.6115 19.3335 52.1339C22.7799 51.2874 24.0619 50.4267 26.626 50.2715C28.5891 50.1683 30.5517 50.4854 32.3883 51.2027L36.1104 53.0651C36.9513 55.8869 37.9025 57.6929 38.5366 58.1162C40.1219 59.2026 39.9979 62.617 39.9979 62.617L39.9289 70.377Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.2" d="M33.2403 61.3401C35.4051 57.7821 29.2351 53.3683 28.5033 44.9769C28.046 39.4553 28.9149 39.1228 28.7625 30.5636C28.8346 27.275 28.4384 25.3558 28.0423 21.8788C27.7215 19.0624 27.5478 17.3918 25.8099 12.8772C24.2854 8.78222 24.881 4.50089 24.1492 4.50089C23.4175 4.50089 22.8828 8.25189 22.8066 10.5679C22.6999 13.4546 24.011 14.3105 24.3311 18.0363C24.4683 19.7145 23.8189 22.3033 23.1938 22.4712C22.2944 22.7061 20.5259 17.8726 20.1448 16.916C16.5775 7.26592 18.1435 1.44462 17.0001 1.24323C15.8567 1.04183 14.2837 5.99304 15.7625 13.2432C16.3418 16.0795 16.787 17.3347 18.0371 20.9598C20.2934 27.438 19.0319 33.7156 18.6051 33.8331C18.1782 33.9506 15.0709 19.2488 11.5492 11.6966C10.4973 9.44769 8.10995 6.57538 7.50015 6.74321C6.89035 6.91104 10.6614 10.5215 10.7528 16.7144C10.7528 21.1115 12.2137 31.0637 12.0003 37.7432C11.8478 42.778 11.7627 41.9893 11.5492 45.7822C11.3358 49.8604 9.77817 52.697 9.76292 57.8996C9.76292 61.3401 9.96111 62.5485 10.7691 63.4547C12.2174 65.133 14.6871 64.7638 16.303 64.5624C21.3034 63.9414 22.1267 60.9373 25.3739 61.5079C27.7064 61.9275 28.6058 63.7232 30.6487 63.1862C31.6865 62.935 32.607 62.2794 33.2403 61.3401Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M21.0001 7.42376L18.8453 21.0791L14.0239 9.82709C13.8758 2.53514 14.4683 2.53514 14.4683 2.53514C16.475 -1.45222 17.8083 0.350288 19.1416 1.48368C20.4749 2.61708 21.0001 7.42376 21.0001 7.42376Z" fill="#DEE1E9"/>
      <path d="M39.9565 70.4712C39.9565 70.4712 41.1003 81.657 28.9321 83.565C16.7639 85.4731 23.723 81.4357 14.8484 81.6984C5.9737 81.9612 5.75321 79.5 3.16247 76.9835C2.4096 76.2486 1.82871 75.3551 1.46171 74.3676C1.09472 73.3801 0.950776 72.3232 1.04027 71.2731C1.04027 71.2731 0.433924 61.387 3.93418 58.4143C5.9048 56.7413 6.4698 53.3952 6.59382 50.7958C6.64994 49.574 6.62232 48.3497 6.51114 47.1317C6.10967 43.2058 6.18371 39.2456 6.73163 35.3375C7.00724 33.4294 8.35773 24.7878 8.78493 19.7272C8.92175 18.3021 8.80036 16.864 8.42664 15.4824C8.09945 14.285 7.578 13.1499 6.88321 12.1225C6.38342 11.1841 6.07924 10.1534 5.98921 9.09318C5.89919 8.03301 6.02519 6.96554 6.35956 5.95579C9.39127 3.74352 13.7873 9.75813 13.7873 9.75813L18.7207 21.179L20.9256 7.35228C20.9256 7.35228 21.3803 2.78947 23.9573 3.09366C24.7144 3.22309 25.4128 3.58529 25.9559 4.13021C26.499 4.67513 26.86 5.37585 26.989 6.13553C26.8374 12.9106 28.3671 13.8923 28.3671 13.8923C28.3671 13.8923 35.9464 20.9024 34.5821 34.2867C33.8494 40.7229 34.3689 47.2406 36.1117 53.4781C36.9524 56.2435 37.9032 57.9995 38.5371 58.4143C40.1219 59.4789 39.9978 62.8388 39.9978 62.8388L39.9565 70.4712Z" fill="#DEE1E9"/>
      <path d="M39.9289 70.2129C39.9289 70.2129 41.0731 81.6272 28.9006 83.5602C16.7281 85.4931 23.7311 81.3873 14.8533 81.6272C5.97546 81.8671 5.75489 79.3697 3.16323 76.816C2.4101 76.066 1.829 75.1543 1.46188 74.1467C1.09475 73.139 0.950759 72.0605 1.04028 70.9889C1.04028 70.9889 0.433724 60.8868 3.93522 57.8533C5.90653 56.1461 5.50012 50.1074 7.50012 50.5792L13.5712 52.901C15.5144 52.759 17.4421 52.4474 19.3335 51.9698C22.7799 51.1233 24.0619 50.2626 26.626 50.1074C28.5891 50.0042 30.5517 50.3214 32.3883 51.0386L36.1104 52.901C36.9513 55.7229 37.9025 57.5288 38.5366 57.9521C40.1219 59.0385 39.9979 62.4529 39.9979 62.4529L39.9289 70.2129Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <path opacity="0.4" d="M33.2403 61.176C35.4051 57.6181 29.2351 53.2042 28.5033 44.8128C28.046 39.2913 28.9149 38.9587 28.7625 30.3995C28.8346 27.111 28.4384 25.1917 28.0423 21.7147C27.7215 18.8984 27.5478 17.2277 25.8099 12.7132C24.2854 8.61816 24.881 4.33683 24.1492 4.33683C23.4175 4.33683 22.8828 8.08782 22.8066 10.4038C22.6999 13.2905 24.011 14.1464 24.3311 17.8722C24.4683 19.5505 23.8189 22.1393 23.1938 22.3071C22.2944 22.542 20.5259 17.7086 20.1448 16.752C16.5775 7.10186 18.1435 1.28056 17.0001 1.07916C15.8567 0.877769 14.2837 5.82898 15.7625 13.0791C16.3418 15.9154 16.787 17.1707 18.0371 20.7958C20.2934 27.2739 19.0319 33.5515 18.6051 33.669C18.1782 33.7865 15.0709 19.0848 11.5492 11.5325C10.4973 9.28362 8.10995 6.41132 7.50015 6.57915C6.89035 6.74697 10.6614 10.3575 10.7528 16.5503C10.7528 20.9474 12.2137 30.8996 12.0003 37.5791C11.8478 42.614 11.7627 41.8252 11.5492 45.6181C11.3358 49.6963 9.77817 52.5329 9.76292 57.7356C9.76292 61.176 9.96111 62.3844 10.7691 63.2907C12.2174 64.9689 14.6871 64.5997 16.303 64.3983C21.3034 63.7773 22.1267 60.7732 25.3739 61.3439C27.7064 61.7634 28.6058 63.5592 30.6487 63.0221C31.6865 62.771 32.607 62.1153 33.2403 61.176Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M21 9.10149L18.8452 22.7568L14.0238 11.5048C13.8756 4.21287 14.4682 4.21288 14.4682 4.21288C16.4749 0.225518 17.8082 2.02802 19.1415 3.16142C20.4748 4.29481 21 9.10149 21 9.10149Z" fill="#DEE1E9"/>
      <path d="M39.9564 72.1489C39.9564 72.1489 41.1002 83.3347 28.932 85.2428C16.7637 87.1509 23.7229 83.1135 14.8482 83.3762C5.97358 83.6389 5.75309 81.1777 3.16235 78.6613C2.40948 77.9263 1.82859 77.0329 1.46159 76.0454C1.0946 75.0579 0.950654 74.0009 1.04014 72.9509C1.04014 72.9509 0.433802 63.0648 3.93406 60.092C5.90468 58.419 6.46968 55.0729 6.5937 52.4735C6.64982 51.2517 6.6222 50.0275 6.51102 48.8094C6.10955 44.8835 6.18359 40.9233 6.73151 37.0153C7.00712 35.1072 8.35761 26.4655 8.78481 21.4049C8.92163 19.9799 8.80024 18.5418 8.42651 17.1601C8.09932 15.9627 7.57788 14.8276 6.88309 13.8002C6.3833 12.8618 6.07912 11.8311 5.98909 10.7709C5.89907 9.71074 6.02507 8.64327 6.35943 7.63352C9.39115 5.42125 13.7871 11.4359 13.7871 11.4359L18.7206 22.8567L20.9255 9.03002C20.9255 9.03002 21.3802 4.46721 23.9572 4.7714C24.7143 4.90082 25.4127 5.26303 25.9558 5.80795C26.4989 6.35286 26.8599 7.05358 26.9889 7.81327C26.8373 14.5883 28.367 15.57 28.367 15.57C28.367 15.57 35.9462 22.5802 34.582 35.9644C33.8493 42.4006 34.3688 48.9183 36.1116 55.1559C36.9522 57.9212 37.9031 59.6772 38.537 60.092C40.1217 61.1567 39.9977 64.5166 39.9977 64.5166L39.9564 72.1489Z" fill="#DEE1E9"/>
      <path d="M39.9288 71.8902C39.9288 71.8902 41.073 83.3045 28.9005 85.2374C16.728 87.1704 23.7309 83.0646 14.8531 83.3044C5.97534 83.5443 5.75477 81.047 3.16311 78.4932C2.40997 77.7433 1.82888 76.8316 1.46176 75.8239C1.09463 74.8162 0.950637 73.7377 1.04016 72.6662C1.04016 72.6662 0.433602 62.564 3.9351 59.5306C5.90641 57.8234 5.5 51.7847 7.5 52.2565L13.5711 54.5783C15.5142 54.4362 17.442 54.1247 19.3334 53.6471C22.7797 52.8005 24.0618 51.9399 26.6259 51.7847C28.589 51.6815 30.5516 51.9986 32.3882 52.7159L36.1102 54.5783C36.9512 57.4001 37.9023 59.2061 38.5365 59.6293C40.1218 60.7157 39.9977 64.1301 39.9977 64.1301L39.9288 71.8902Z" fill="white" stroke="#DEE1E9" stroke-linejoin="round"/>
      <mask id="mask518" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="0" width="27" height="28">
        <path d="M9.11901 6.34887L12.8274 7.28878L15.9488 0.850098L25.5417 4.65554L31.5894 27.6238H10.2139L9.11901 17.4819L5.99092 7.28878L9.11901 6.34887Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask518)">
        <path opacity="0.4" d="M33.1744 62.468C35.3392 58.9101 29.1691 54.4962 28.4373 46.1048C27.98 40.5832 28.849 40.2507 28.6965 31.6915C28.7686 28.403 28.3725 26.4837 27.9763 23.0067C27.6555 20.1904 27.4819 18.5197 25.7439 14.0052C24.2194 9.91015 24.815 5.62882 24.0833 5.62882C23.3515 5.62882 22.8169 9.37982 22.7406 11.6958C22.6339 14.5825 23.945 15.4384 24.2651 19.1642C24.4024 20.8425 23.7529 23.4313 23.1278 23.5991C22.2284 23.834 20.46 19.0006 20.0788 18.044C16.5115 8.39385 18.0775 2.57255 16.9341 2.37115C15.7908 2.16976 14.2178 7.12097 15.6965 14.3711C16.2758 17.2074 16.721 18.4627 17.9711 22.0877C20.2274 28.5659 18.9659 34.8435 18.5391 34.961C18.1122 35.0785 15.0049 20.3768 11.4833 12.8245C10.4314 10.5756 8.04398 7.70331 7.43417 7.87114C6.82437 8.03897 10.5954 11.6495 10.6869 17.8423C10.6869 22.2394 12.1477 32.1916 11.9343 38.8711C11.7819 43.906 11.6967 43.1172 11.4832 46.9101C11.2698 50.9883 9.71219 53.8249 9.69694 59.0276C9.69694 62.468 9.89513 63.6764 10.7031 64.5827C12.1514 66.2609 14.6211 65.8917 16.2371 65.6903C21.2375 65.0693 22.0607 62.0652 25.3079 62.6358C27.6404 63.0554 28.5399 64.8512 30.5827 64.3141C31.6206 64.063 32.541 63.4073 33.1744 62.468Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5018" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="27" width="27" height="42">
        <path d="M8.87689 27.6582H31.5376L31.2925 36.0383L34.7364 64.7854L8.87689 68.0882V27.6582Z" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5018)">
        <path opacity="0.2" d="M33.1744 62.4731C35.3392 58.9182 29.1691 54.5082 28.4373 46.124C27.98 40.6073 28.849 40.275 28.6965 31.7232C28.7686 28.4376 28.3725 26.5199 27.9763 23.046C27.6555 20.2321 27.4819 18.5629 25.7439 14.0522C24.2194 9.96075 24.815 5.68312 24.0833 5.68312C23.3515 5.68312 22.8169 9.43087 22.7406 11.7449C22.6339 14.629 23.945 15.4842 24.2651 19.2068C24.4024 20.8836 23.7529 23.4701 23.1278 23.6378C22.2284 23.8726 20.46 19.0433 20.0788 18.0875C16.5115 8.44576 18.0775 2.6295 16.9341 2.42828C15.7908 2.22706 14.2178 7.17398 15.6965 14.4179C16.2758 17.2517 16.721 18.5059 17.9711 22.1278C20.2274 28.6003 18.9659 34.8725 18.5391 34.9899C18.1122 35.1073 15.0049 20.4183 11.4833 12.8726C10.4314 10.6256 8.04398 7.75582 7.43417 7.9235C6.82437 8.09118 10.5954 11.6985 10.6869 17.886C10.6869 22.2793 12.1477 32.2229 11.9343 38.8967C11.7819 43.9271 11.6967 43.139 11.4832 46.9287C11.2698 51.0033 9.71219 53.8375 9.69694 59.0356C9.69694 62.4731 9.89513 63.6804 10.7031 64.5859C12.1514 66.2627 14.6211 65.8938 16.2371 65.6926C21.2375 65.0722 22.0607 62.0707 25.3079 62.6408C27.6404 63.06 28.5399 64.8542 30.5827 64.3176C31.6206 64.0667 32.541 63.4116 33.1744 62.4731Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

<g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M39.0208 68.067C39.0208 68.067 40.1646 79.2528 27.9964 81.1609C15.8282 83.069 22.7874 79.0316 13.9127 79.2943C5.03803 79.557 4.81754 77.0958 2.2268 74.5794C1.47393 73.8444 0.893039 72.951 0.526046 71.9635C0.159053 70.976 0.0151073 69.919 0.104598 68.8689C0.104598 68.8689 -0.501745 58.9829 2.99851 56.0101C4.96913 54.3371 5.53413 50.991 5.65816 48.3916C5.71427 47.1698 5.68665 45.9455 5.57547 44.7275C5.17401 40.8016 5.24804 36.8414 5.79596 32.9333C6.07157 31.0253 7.42206 22.3836 7.84926 17.323C7.98608 15.898 7.86469 14.4599 7.49097 13.0782C7.16378 11.8808 6.64233 10.7457 5.94755 9.71832C5.44775 8.77988 5.14357 7.74919 5.05355 6.68901C4.96352 5.62883 5.08952 4.56136 5.42389 3.55161C8.4556 1.33934 12.8516 7.35396 12.8516 7.35396L17.785 18.7748L19.9899 4.94811C19.9899 4.94811 20.4447 0.3853 23.0216 0.689487C23.7788 0.818916 24.4772 1.18112 25.0203 1.72604C25.5634 2.27095 25.9244 2.97167 26.0534 3.73136C25.9018 10.5064 27.4314 11.4881 27.4314 11.4881C27.4314 11.4881 35.0107 18.4983 33.6464 31.8825C32.9137 38.3187 33.4332 44.8364 35.1761 51.074C36.0167 53.8393 36.9675 55.5953 37.6014 56.0101C39.1862 57.0748 39.0622 60.4347 39.0622 60.4347L39.0208 68.067Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M24.0645 8.05145L21.9096 21.7068L17.0882 10.4548C16.9401 3.16282 17.5327 3.16283 17.5327 3.16283C19.5393 -0.824531 20.8726 0.977974 22.2059 2.11137C23.5392 3.24476 24.0645 8.05145 24.0645 8.05145Z" fill="#C7CAD5"/>
      <path d="M43.0208 71.0988C43.0208 71.0988 44.1646 82.2847 31.9964 84.1927C19.8282 86.1008 26.7874 82.0634 17.9127 82.3261C9.03803 82.5888 8.81754 80.1277 6.2268 77.6112C5.47393 76.8763 4.89304 75.9828 4.52605 74.9953C4.15905 74.0078 4.01511 72.9509 4.1046 71.9008C4.1046 71.9008 3.49826 62.0147 6.99851 59.042C8.96913 57.3689 9.53413 54.0229 9.65816 51.4235C9.71427 50.2016 9.68665 48.9774 9.57547 47.7594C9.17401 43.8335 9.24804 39.8732 9.79596 35.9652C10.0716 34.0571 11.4221 25.4154 11.8493 20.3549C11.9861 18.9298 11.8647 17.4917 11.491 16.1101C11.1638 14.9127 10.6423 13.7776 9.94755 12.7502C9.44775 11.8117 9.14357 10.781 9.05355 9.72087C8.96352 8.66069 9.08952 7.59322 9.42389 6.58347C12.4556 4.3712 16.8516 10.3858 16.8516 10.3858L21.785 21.8067L23.9899 7.97997C23.9899 7.97997 24.4447 3.41716 27.0216 3.72135C27.7788 3.85078 28.4772 4.21298 29.0203 4.7579C29.5634 5.30281 29.9244 6.00353 30.0534 6.76322C29.9018 13.5383 31.4314 14.52 31.4314 14.52C31.4314 14.52 39.0107 21.5301 37.6464 34.9144C36.9137 41.3506 37.4332 47.8683 39.1761 54.1058C40.0167 56.8712 40.9675 58.6272 41.6014 59.042C43.1862 60.1066 43.0622 63.4665 43.0622 63.4665L43.0208 71.0988Z" fill="#DEE1E9"/>
      <path d="M42.9933 70.8405C42.9933 70.8405 44.1374 82.2548 31.9649 84.1877C19.7924 86.1207 26.7954 82.0149 17.9176 82.2548C9.03979 82.4946 8.81922 79.9973 6.22757 77.4436C5.47443 76.6936 4.89333 75.7819 4.52621 74.7742C4.15909 73.7666 4.01509 72.688 4.10461 71.6165C4.10461 71.6165 3.49805 61.5144 6.99955 58.4809C8.97086 56.7737 8.56445 50.735 10.5645 51.2068L16.6356 53.5286C18.5787 53.3865 20.5065 53.075 22.3979 52.5974C25.8442 51.7509 27.1262 50.8902 29.6903 50.735C31.6535 50.6318 33.6161 50.9489 35.4526 51.6662L39.1747 53.5286C40.0156 56.3504 40.9668 58.1564 41.6009 58.5797C43.1863 59.6661 43.0622 63.0805 43.0622 63.0805L42.9933 70.8405Z" stroke="#DEE1E9" stroke-linejoin="round" fill="white"/>
    </g>
  </g>
</template>

<script>
export default {
  name: "Tooth55",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
