<template>
  <div>
    <span v-if="object.age || object.age === 0">{{ $t('years') }}: {{ object.age }}</span>&nbsp;
    <span class="text-muted" v-if="object.date_of_birth">({{ formatDate(object.date_of_birth) }})</span>
  </div>
</template>

<script>
import {formatDate} from "@/extensions/filters/filters"

export default {
  name: "PatientsDatatableTDAge",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    formatDate
  }
}
</script>
