<template>
 <span v-if="object.balance">
   {{ formatCurrency(object.balance.card_sales / 100) }}
  </span>
</template>

<script>

import { formatCurrency } from "@/extensions/filters/filters"

export default {
  name: "CashdeskDatatableTDSalesCard",
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    }
  },
  methods: {
    formatCurrency
  }
}
</script>
