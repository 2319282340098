<template>
  <section id="payments" class="payments">
    <transition name="fade" mode="out-in">
      <div class="section-top-row" >
        <div class="row row-narrow align-items-center no-print position-relative">
          <div class="col order-md-0 order-0">
            <h1>{{ $t('payments') }}</h1>
          </div>
          <div class="col-md-auto col mt-md-0 mt-2 order-md-1 order-3 table-view-navigator">
            <SearchTable/>
          </div>
          <div class="col-auto order-md-2 mt-md-0 mt-2 order-4 table-view-navigator">
            <DataTableFilterButton/>
          </div>
          <div class="col-auto order-md-5 order-2 table-view-navigator" v-if="['superadmin', 'supermanager'].includes(role)">
            <AddObject module="superadmin"/>
          </div>
        </div>
      </div>
    </transition>
    <div>
      <DataTable :options="options"
                 module="superadmin"/>
      <TariffFunctionalityModal/>
    </div>
    <PayStatusModal/>
  </section>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/billing_payment"
import {mapState} from "vuex"
import DataTableFilterButton from "@/components/parts/datatable/DataTableFilterButton"
import AddObject from "@/components/parts/datatable/AddObject"
import SearchTable from "@/components/parts/datatable/SearchTable"
import TariffFunctionalityModal from "@/components/parts/superadmin/TariffFunctionalityModal"
import PayStatusModal from "@/components/parts/superadmin/PayStatusModal"

export default {
  name: "SuperAdminTariffs",
  components: {
    PayStatusModal,
    TariffFunctionalityModal,
    AddObject,
    SearchTable,
    DataTableFilterButton,
    DataTable
  },
  head() {
    return {
      title: this.$t('payments'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
    role() {
      return this.$store.state.auth.user.role
    },
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
