<template>
  <div v-if="visible && chairs.length" class="position-relative">
    <a ref="viewBtn" href="#" @click="showPopup" class="d-inline-block mr-md-3 mr-2 view-active-type">
      {{ $t(curView) }} <Arrow />
    </a>
    <transition name="fade" mode="out-in">
      <div v-if="popupShowed">
        <div class="view-view-selector view-dd-selector position-absolute"
             v-closable="{
            exclude: ['viewBtn'],
            handler: 'onClose'
        }">
          <a href="#" v-if="chairs.length" @click="clickView('CHAIR')">{{ $t('CHAIR') }}</a>
          <a href="#" @click="clickView('DOCTOR')">{{ $t('DOCTOR') }}</a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex"
import Arrow from '@/assets/svg-vue/calendar/arrow.svg'
import { ViewRange } from "@/components/pages/calendar/calendar/const/CalendarConst"
import { Closable } from "@/components/pages/calendar/calendar/directives/CloseOutside"

export default {
  name: "DViewSwitcher",
  directives: {
    Closable
  },
  components: {
    Arrow
  },
  data: function() {
    return {
      popupShowed: false,
    }
  },
  computed: {
    ...mapState({
      curView: state => state.calendar.curView,
      chairs: state => state.auth.chairs,
    }),
    ...mapGetters([
      'activeRange'
    ]),
    visible() {
      return this.activeRange === ViewRange.DAY
    }
  },
  watch: {
  },
  methods: {
    ...mapActions([
      'changeView'
    ]),
    showPopup() {
      this.popupShowed = !this.popupShowed
    },
    onClose() {
      this.popupShowed = false
    },
    clickView(value) {
      this.popupShowed = false
      this.changeView(value)
    }
  },
}
</script>

<style scoped>
.view-view-selector {
  top:30px;
  min-width: 150px;
  padding:10px 0;
}
.view-view-selector a {
  font-size: 16px;
  display: block;
  padding: 9px 20px;
  color: #5D6D7C;
}
.view-view-selector a:hover {
  background-color: #F3F3F3;
  text-decoration: none;
}
</style>
