<template>
  <div>
    {{ formatCurrency(object.cost_branch) }}
    <a class="ml-1" v-b-popover.hover.html="string">
      <InfoSvg/>
    </a>
  </div>
</template>

<script>

import InfoSvg from '@/assets/svg-vue/general/info.svg'
import {formatCurrency} from "@/extensions/filters/filters"

export default {
  name: "ServicesDatatableTDCost",
  components: {
    InfoSvg,
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    string() {
      let str = this.$t('packages_cost')+":"
      str += '<br>'+this.formatCurrency(this.object.packages ? this.object.packages.reduce((acc, cur) => acc + cur.cost, 0) : 0)
      if(this.object.technics && this.object.technics.length > 0) {
        str += '<br><br>'+this.$t('technic_reward') + ':'
        this.object.technics.forEach(item => {
          str += '<br>'+item.lname+': '+this.formatCurrency((item.pivot.value * this.object.cost) / 100)
        })
      }
      if(this.object.doctors && this.object.doctors.length > 0) {
        str += '<br><br>'+this.$t('doctor_reward') + ':'
        this.object.doctors.forEach(item => {
          str += '<br>'+item.lname+': '+this.formatCurrency((item.pivot.percent * this.object.cost) / 100)
        })
      }
      if(this.object.managers && this.object.managers.length > 0) {
        str += '<br><br>'+this.$t('manager_reward') + ':'
        this.object.managers.forEach(item => {
          str += '<br>'+item.lname+': '+this.formatCurrency((item.pivot.percent * this.object.cost) / 100)
        })
      }
      return str
    }
  },
  methods: {
    formatCurrency
  }
}
</script>
