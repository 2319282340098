<template>
  <span>
    <img class="doctor-img mr-2"
         :style="`border-color: ${object.color}`"
         :src="object.photo"
         :alt="object.full_name"
         :title="object.full_name"/>{{ object.full_name }}
    </span>
</template>

<script>
export default {
  name: "StaffDatatableTDStaffName",
  props: {
    object: {
      type: Object
    }
  },
}
</script>

<style scoped lang="scss">
  .doctor-img {
    border-radius: 100%;
    width: 36px;
    height: 36px;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
</style>
