export const getCountryNumber = (countryId) => {
  const arr = {
    1: '804',
    2: '616',
    3: '643',
    4: '643',
    5: '398',
    6: '112',
    7: '031',
    8: '051',
    9: '498',
    10: '417',
    11: '762',
    12: '860',
    13: '688'
  }
  return arr[countryId] || '643';
}
