<template>
  <section class="auth-section" :class="{ superadmin: superadmin }">
    <b-row class="no-gutters h-100">
      <b-col lg="5">
        <div class="left-part h-100 bg-light d-flex justify-content-center align-items-center">
          <div class="auth-image-block">
            <div class="auth__logoimg">
              <img src="/images/general/logo.png" alt="logo"/>
            </div>
            <div class="auth__slogan">{{ $t('slogan') }}</div>
            <div class="auth__logochair">
              <img src="/images/login/seat.png" class="img" alt="logo"/>
            </div>
          </div>
        </div>
      </b-col>
      <b-col lg="6">
        <div class="right-part h-100 d-flex justify-content-center align-items-center">
          <div class="right__content">
            <h1 class="mb-3" v-if="!superadmin">{{ $t('authorize_in_system') }}</h1>
            <h1 class="mb-3" v-if="superadmin">Superadmin</h1>
            <p v-if="!superadmin && !flutterApp" class="auth__note mt-3 mb-4">Хотите стать пользователем нашей системы? Пожалуйста, обратитесь в наш <a href="https://dentist-plus.com" target="_blank">отдел
              продаж</a>.</p>
            <InputGroup :label="$t('login')"
                        type="text"
                        v-model="form.username"/>
            <ValidationError validation-id="username"/>
            <InputGroup :label="$t('password')"
                        v-model="form.password"
                        type="password"
                        @enter="login"/>
            <ValidationError v-if="form.password.length < 1" validation-id="password"/>
            <div class="form-group text-right mt-4">
              <button @click="login"
                      :disabled="loading"
                      class="btn-themed">{{ $t('signin') }}
              </button>
            </div>
            <div v-if="errors && errors.general" class="alert alert-danger">
              {{ errors.general.join(', ') }}
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

  </section>
</template>

<script>

import InputGroup from "@/components/form/InputGroup"
import { mapState } from "vuex"
import ValidationError from "@/components/form/ValidationError"
// import JwtService from "@/services/jwt.service"
// import axios from "axios"

export default {
  name: 'Login',
  components: {
    ValidationError,
    InputGroup
  },
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
    }
  },
  methods: {
    login() {
      let params = this.form
      params.superadmin = this.superadmin
      this.$store.dispatch('login', params).then(() => {
        if(this.urlAfterLogin) {
          this.$router.push(this.urlAfterLogin).then(() => {
            this.$store.commit('setUrlAfterLogin', null)
          }).catch(()=>{})
          return
        }
        this.$router.push(this.superadmin ? '/superadmin' : '/').catch(()=>{})
      })
    },
  },
  watch: {
    email() {
      this.error = ''
    },
    password() {
      this.error = ''
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      loading: state => state.dom.loading,
      flutterApp: state => state.auth.flutterApp,
      urlAfterLogin: state => state.auth.urlAfterLogin
    }),
    superadmin() {
      return this.$route.name === 'AdminLogin'
    }
  },
  head() {
    return {
      title: this.$t('authorization'),
    }
  },
  mounted() {
    // if(this.$route.query.tkn && btoa(this.$route.query.ti) === 'aHVudGVyamFt') {
    //   JwtService.saveToken(this.$route.query.tkn, this.$route.query.spr && this.$route.query.spr == 1)
    //   location.href = '/'
    // }
    // if(window?.location?.origin.includes('my.dentist-plus.com')) {
    //   axios.defaults.baseURL = process.env.VUE_APP_API_URL
    //   this.$store.commit('setUseProxy', false)
    // }
  }
}
</script>


