export default {
    primaryKey: "id",
    model: 'popup',
    order: 'id',
    sort: 'desc',
    actions: [
        "add",
        "edit",
        "delete"
    ],
    showTotalRecords: true,
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    // formComponent: "CRMDatatableTaskForm",
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
            filter: true,
        },
        {
            id: 'text',
            title: 'text',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            filter: true,
            form: {
                type: "textarea",
                required: true
            }
        },
        {
            id: 'additional',
            form: {
                component: 'PopupFormCard'
            }
        },
        {
            id: 'url',
            title: 'link',
            form: {
                type: "text",
                note: 'Ссылка для перехода по нажатию кнопки (полная, например: https://google.com)',
                noteDoNotTransate: true
            },
        },
        {
            id: 'button_title',
            title: 'button_title',
            form: {
                type: "text",
                note: 'Название кнопки перехода',
                noteDoNotTransate: true
            },
        },
        {
            id: 'video_url',
            title: 'link',
            form: {
                type: "text",
                note: 'Ссылка на видео в youtube (например: https://www.youtube.com/watch?v=FTQbiNvZqaY)',
                noteDoNotTransate: true
            },
        },
        {
            id: 'image',
            title: 'file',
            form: {
                type: "file",
                filetype: "image",
                note: 'Изображение в окне',
                noteDoNotTransate: true,
                deletefile: true
            }
        },
        {
            id: 'active',
            title: 'active',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]
}
