<template>
  <div>
    <div class="rating d-inline-flex align-items-center justify-content-center gap-3" v-if="rating > 0 || selectable">
      <div class="rating__value text-muted bolder mr-2" v-if="showRatingNumber">{{ rating }}</div>
      <div class="rating__stars d-flex align-items-center">
        <div
            class="rating__star"
            :class="{ pointer: selectable }"
            @mouseover="mouseover(i)"
            @mouseleave="mouseleave(i)"
            @click="ratingChosen(i)"
            v-for="i in 5"
            :key="i">
          <svg
              class="rating__star--empty"
              v-if="selectable"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 40 38">
            <path
                d="M19.8652 1.08223C19.883 1.04617 19.9022 1.03019 19.9184 1.0206C19.9382 1.00889 19.9665 1 20 1C20.0335 1 20.0618 1.00889 20.0816 1.02061C20.0978 1.03019 20.117 1.04617 20.1348 1.08223L25.4246 11.7569C25.7386 12.3906 26.3444 12.8278 27.043 12.9289L38.8713 14.6407C38.9984 14.6591 39.0376 14.8062 38.955 14.8864L30.396 23.1955C29.8888 23.6879 29.6563 24.3986 29.7765 25.0961L31.797 36.8287C31.8037 36.8679 31.7976 36.8909 31.7908 36.9068C31.7822 36.9266 31.7654 36.9495 31.7389 36.9686C31.7124 36.9878 31.6845 36.9973 31.661 36.9995C31.6415 37.0013 31.6154 36.9994 31.5783 36.98L20.9988 31.4406C20.3734 31.1132 19.6266 31.1132 19.0012 31.4406L8.42166 36.98C8.38458 36.9995 8.35846 37.0013 8.33902 36.9995C8.31551 36.9973 8.28758 36.9878 8.26109 36.9687C8.2346 36.9495 8.21782 36.9266 8.20925 36.9068C8.20236 36.8909 8.19629 36.8679 8.20304 36.8287L10.2235 25.0961C10.3437 24.3986 10.1112 23.6879 9.60399 23.1955L1.04497 14.8864C1.01552 14.8578 1.00675 14.835 1.00299 14.8183C0.998318 14.7976 0.99848 14.7702 1.00821 14.7404C1.01793 14.7106 1.03422 14.6876 1.05116 14.6728C1.06502 14.6606 1.08684 14.6468 1.12875 14.6407L12.957 12.9289C13.6556 12.8278 14.2614 12.3906 14.5754 11.7569L19.8652 1.08223Z"
                stroke="#ACACAC"
                stroke-width="2" />
          </svg>
          <svg
              class="rating__star--full"
              v-if="!selectable || hoveredIndex >= i || selectedIndex >= i"
              fill="#F0BB00"
              :style="countClipPath(i)"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 22 21">
            <path
                d="M10.433 0.352447C10.6649 -0.117483 11.3351 -0.117482 11.567 0.352447L14.4763 6.24748C14.5684 6.43409 14.7465 6.56343 14.9524 6.59336L21.458 7.53867C21.9766 7.61403 22.1836 8.25133 21.8084 8.61712L17.1009 13.2058C16.9519 13.351 16.8839 13.5603 16.9191 13.7654L18.0304 20.2447C18.1189 20.7612 17.5768 21.1551 17.113 20.9112L11.2942 17.8521C11.11 17.7553 10.89 17.7553 10.7058 17.8521L4.88703 20.9112C4.42319 21.1551 3.88106 20.7612 3.96965 20.2447L5.08093 13.7654C5.11611 13.5603 5.04811 13.351 4.89909 13.2058L0.191628 8.61712C-0.183633 8.25133 0.023441 7.61403 0.542039 7.53867L7.0476 6.59336C7.25353 6.56343 7.43155 6.43409 7.52365 6.24748L10.433 0.352447Z"
                fill="#F0BB00" />
          </svg>
        </div>
      </div>
    </div>
    <div v-else class="text-muted small">{{ $t('no_rating') }}</div>
  </div>
</template>

<script>

export default {
  name: 'StarRating',
  props: {
    rating: {
      type: Number,
      default: 0
    },
    showRatingNumber: {
      type: Boolean,
      default: true
    },
    selectable: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hoveredIndex: 0,
      selectedIndex: 0
    }
  },
  methods: {
    countClipPath(index) {
      if (this.selectable) return ''
      const difference = this.rating - index + 1
      if (difference >= 1) return ''
      let width = 0
      if (difference > 0) {
        width = difference * 100
      }
      return `clip-path: polygon(0% 0%, ${width}% 0%, ${width}% 100%, 0% 100%);`
    },
    mouseover(index) {
      this.hoveredIndex = index
    },
    mouseleave() {
      this.hoveredIndex = 0
    },
    ratingChosen(index) {
      this.selectedIndex = index
      this.hoveredIndex = 0
      this.$emit('rating-chosen', index)
    }
  }
}


</script>

<style scoped lang="scss">
.rating__stars {
  gap: 5px;
}
.rating__star {
  width: 20px;
  height: 20px;
  position: relative;
  .rating__star--full,
  .rating__star--empty {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
