import {kitAdapter} from "@/adapters/warehouse/kit"

export default {
    primaryKey: 'package_id',
    model: 'kit',
    order: 'name',
    sort: 'asc',
    adapter: kitAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_kit',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'package_id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'name',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
            tdcomponent: 'WarehouseDatatableTDKitTitle',
        },
        {
            id: 'cost',
            title: 'cost',
            table: true,
            format_currency: true,
            sortable: false
        },
        {
            id: 'materials',
            title: 'materials',
            form: {
                component: 'WarehouseDatatableFormKitMaterials',
                required: true
            }
        }
    ]
}
