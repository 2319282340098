<template>
  <div>{{ formatDateTime(object.sent_at_timezoned ? object.sent_at_timezoned : object.ignore_till_timezoned) }}</div>
</template>

<script>

import { formatDateTime } from "@/extensions/filters/filters"

export default {
  name: "MarketingDatatableTDNotificationDate",
  methods: {
    formatDateTime
  },
  components: {
  },
  props: {
    object: {
      type: Object
    }
  },
}
</script>
