import { scanCustomCreate } from "@/services/scan.service"

export default {
    primaryKey: 'id',
    model: 'scan',
    order: 'id',
    sort: 'desc',
    prepareParams: ['id', 'client_id'],
    deletemessage: 'are_you_sure_you_want_to_delete_scan',
    customCreateFunction: scanCustomCreate,
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'date_add',
            title: 'upload_date',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            format_date: true
        },
        {
            id: 'scan',
            title: 'scan',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDScanImg",
        },
        {
            id: 'record',
            title: 'visit',
            // table_show: 'record.start',
            format_date: true,
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDPaymentVisit"
        },
        // {
        //     id: 'record_id',
        //     title: 'visit',
        //     table: false,
            // form: {
            //     type: "select",
            //     select_type: "model",
            //     select_data: {
            //         "title": "start"
            //     },
            // }
        // },
        {
            id: 'tooth',
            title: 'teeth',
            table: true,
            sortable: false,
            tdcomponent: "PatientsDatatableTDTeeth",
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                component: "PatientsDatatableFormTeeth"
            }
        },
        {
            id: 'image',
            title: 'file',
            form: {
                type: "file",
                filetype: "image",
                multiple: true,
                required: true,
                disabled_on_edit: true,
            },
            validation_id: ['image','image.0'],
        },
        {
            id: 'url',
            title: 'link',
            form: {
                type: "text",
                note: 'foto_link_note'
            },
        },
        {
            id: 'comment',
            title: 'description',
            table: true,
            sortable: false,
            form: {
                type: "textarea"
            }
        },
    ]
}
