<template>
  <g>

    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 2.30529L7.00901 1.03552H10.4927L12.5927 2.30529L10.9811 3.8518L13.0648 5.3983L11.1764 6.94481L13.4718 8.63783L11.6485 10.1843L13.9439 11.5843L11.9253 13.0006L13.9439 14.6122L12.2509 16.1587L14.4811 17.575L12.723 19.0401L15.0183 20.5866L12.8695 22.1332L15.0183 23.7448L13.4718 25.0959L15.5555 26.6424L13.7323 28.1238L15.816 29.6703L14.009 31.4122L15.0183 33.0889V47.3494L13.1299 54.6749H7.00901" fill="#8889BE"/>
      <path d="M12.3326 2.30529L10.2489 1.03552H6.78142L4.66514 2.30529L6.29305 3.8518L4.19305 5.3983L6.08142 6.94481L3.80235 8.63783L5.60933 10.1843L3.33026 11.5843L5.34886 13.0006L3.33026 14.6122L5.007 16.1587L2.79305 17.575L4.53491 19.0401L2.25585 20.5866L4.40468 22.1332L2.25585 23.7448L3.80235 25.0959L1.71864 26.6424L3.52561 28.1238L1.44189 29.6703L3.26515 31.4122L2.25585 33.0889V47.3494L4.12794 54.6749H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
        <path d="M20.5864 2.26976L18.5027 1H15.019L12.919 2.26976L14.5306 3.81627L12.4469 5.36278L14.3353 6.90929L12.0399 8.60231L13.8632 10.1488L11.5678 11.5488L13.5864 12.9651L11.5678 14.5767L13.2609 16.1232L11.0306 17.5395L12.7888 19.0046L10.4934 20.5511L12.6423 22.0976L10.4934 23.7093L12.0399 25.0604L9.95621 26.6069L11.7795 28.0883L9.69575 29.6348L11.5027 31.3767L10.4934 33.0534V47.3139L12.3818 54.6394H18.5027" fill="#8889BE"/>
        <path d="M13.1791 2.26976L15.2629 1H18.7303L20.8466 2.26976L19.2187 3.81627L21.3187 5.36278L19.4303 6.90929L21.7094 8.60231L19.9024 10.1488L22.1815 11.5488L20.1629 12.9651L22.1815 14.5767L20.5047 16.1232L22.7187 17.5395L20.9768 19.0046L23.2559 20.5511L21.107 22.0976L23.2559 23.7093L21.7094 25.0604L23.7931 26.6069L21.9861 28.0883L24.0698 29.6348L22.2466 31.3767L23.2559 33.0534V47.3139L21.3838 54.6394H17.4768" fill="#8889BE"/>
        <path d="M2.06197 71.0785C2.7209 72.4233 4.29427 73.768 7.44101 76.2693C8.80516 77.5088 10.2973 78.5997 11.8922 79.5236C13.7739 80.6135 15.934 81.1278 18.105 81.0028C21.1307 80.5994 21.2921 78.5285 28.0293 72.7057C30.4768 70.5944 31.2702 70.1641 31.8753 68.6714C32.8436 66.3315 32.2115 64.0588 31.1357 60.6835C29.4279 55.3045 28.5807 52.6149 27.4376 49.9254C27.2224 49.4144 26.8728 48.2445 26.3484 46.3618L23.9681 44.4657C22.9851 43.0669 21.6613 41.9421 20.1221 41.1979C18.3256 40.3498 16.3215 40.0415 14.3531 40.3104C14.3531 40.3104 10.6819 39.517 4.60357 48.0427C4.06566 50.2885 3.43362 52.8704 3.25881 53.6235C2.32175 57.2626 1.72747 60.9814 1.48372 64.7312C1.21477 69.2093 1.76612 70.4868 2.06197 71.0785Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
<!--        <path d="M16.4224 21.4311C16.6645 22.48 17.0141 24.0265 17.4579 25.8688C18.2244 29.1366 19.6229 35.0401 22.4873 45.852C25.1768 56.1529 26.5216 61.3033 27.0729 62.5808C28.216 65.2703 29.0766 67.9599 30.7845 73.3389C31.8468 76.7277 32.4789 78.9869 31.5241 81.3402C30.9189 82.7926 30.1793 83.2095 27.6646 85.3745C20.9408 91.1839 20.7794 93.2682 17.7537 93.6582C15.5774 93.7947 13.4098 93.2798 11.5275 92.179C9.9343 91.2589 8.44626 90.1676 7.08978 88.9247C3.98338 86.3831 2.42345 85.0787 1.71073 83.7474C1.41488 83.1557 0.87698 81.8781 1.11904 77.3732C1.36165 73.6276 1.95596 69.9132 2.89412 66.2789C3.21686 64.9342 5.07263 57.2556 5.11298 57.0942C6.37705 51.7151 6.00052 41.9253 6.00052 39.3299C6.00052 29.3383 5.69122 27.2271 6.29637 21.5656C6.61256 18.4195 7.15637 15.3004 7.92353 12.233C8.75188 9.22421 9.73943 6.26155 10.882 3.35753C11.4737 1.77071 11.8502 0.842834 12.3612 0.842834C13.585 0.842834 14.5129 6.04705 14.7415 7.32457C15.4273 11.2244 15.0777 12.8919 15.7769 17.8675C16.0324 19.414 16.261 20.6512 16.4224 21.4311Z" fill="#DEE1E9"/>-->
<!--        <path d="M1.70479 83.737C2.36372 85.0818 3.93709 86.4265 7.08383 88.9278C8.44798 90.1673 9.94009 91.2582 11.535 92.1821C13.4168 93.272 15.5768 93.7863 17.7478 93.6614C20.7735 93.2579 20.9349 91.187 27.6721 85.3642C30.1196 83.2529 30.913 82.8226 31.5181 81.3299C32.4864 78.99 31.8543 76.7174 30.7785 73.342C29.0707 67.963 28.2235 65.2734 27.0804 62.5839C26.8653 62.0729 26.5156 60.903 25.9912 59.0203L23.6109 57.1242C22.6279 55.7254 21.3041 54.6006 19.7649 53.8564C17.9684 53.0083 15.9643 52.7 13.9959 52.9689C13.9959 52.9689 10.3247 52.1755 4.24639 60.7013C3.70848 62.947 3.07645 65.5289 2.90163 66.282C1.96458 69.9211 1.37029 73.6399 1.12654 77.3897C0.857592 81.8678 1.40894 83.1453 1.70479 83.737Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>-->
<!--        <path opacity="0.2" d="M15.1407 69.9905C16.8754 69.9905 18.7446 63.7642 19.0808 57.9952L17.1444 41.8581C16.3509 36.7345 15.6382 32.5792 15.1407 29.7552C13.9976 23.26 13.7959 22.48 13.4463 19.6964C12.6932 12.5288 13.0025 3.61375 12.4108 3.53307C11.8191 3.45238 11.1602 11.5606 10.6357 14.9628C9.97678 19.2795 10.4205 21.6866 10.7836 27.6843C11.2491 32.3581 11.348 37.0612 11.0795 41.7505C10.7298 45.5562 10.165 47.4254 9.89609 52.8582C9.69493 55.3813 9.84428 57.9202 10.3399 60.4023C10.7935 62.4066 11.4889 64.3483 12.4108 66.1848C13.6749 68.7129 14.3204 69.9905 15.1407 69.9905Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>-->

        <path d="M16.6023 20.7892C16.8444 21.8381 17.194 23.3846 17.6378 25.2269C18.4043 28.4947 19.8029 34.3982 22.6672 45.2101C25.3567 55.511 26.7015 60.6614 27.2529 61.9389C28.3959 64.6284 29.2566 67.318 30.9644 72.697C32.0268 76.0858 32.6588 78.345 31.704 80.6983C31.0989 82.1507 30.3593 82.5676 27.8446 84.7326C21.1207 90.542 20.9594 92.6263 17.9337 93.0163C15.7574 93.1528 13.5898 92.6379 11.7074 91.5371C10.1142 90.6169 8.62619 89.5257 7.26971 88.2828C4.16331 85.7412 2.60339 84.4368 1.89066 83.1054C1.59481 82.5137 1.05691 81.2362 1.29897 76.7313C1.54158 72.9857 2.13589 69.2713 3.07405 65.637C3.3968 64.2923 5.25257 56.6137 5.29291 56.4523C6.55698 51.0732 6.18045 41.2834 6.18045 38.688C6.18045 28.6964 5.87115 26.5851 6.4763 20.9237C6.79249 17.7776 7.3363 14.6585 8.10346 11.5911C8.93182 8.5823 9.91936 5.61964 11.0619 2.71562C11.6536 1.12881 12.0302 0.200928 12.5412 0.200928C13.7649 0.200928 14.6928 5.40514 14.9214 6.68267C15.6072 10.5825 15.2576 12.25 15.9569 17.2256C16.2124 18.7721 16.441 20.0093 16.6023 20.7892Z" fill="#DEE1E9"/>
        <mask id="mask423" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="33" height="43">
          <path d="M1.8293 83.0635C2.48823 84.4082 4.0616 85.753 7.20835 88.2543C8.57249 89.4938 10.0646 90.5847 11.6595 91.5086C13.5413 92.5985 15.7013 93.1128 17.8723 92.9878C20.898 92.5844 21.0594 90.5135 27.7966 84.6907C30.2441 82.5794 31.0375 82.1491 31.6427 80.6564C32.6109 78.3165 31.9788 76.0438 30.903 72.6685C29.1952 67.2894 28.348 64.5999 27.2049 61.9104C26.9898 61.3994 26.6401 60.2294 26.1157 58.3468L23.7355 56.4507C22.7524 55.0519 21.4286 53.9271 19.8894 53.1829C18.0929 52.3348 16.0888 52.0265 14.1204 52.2954C14.1204 52.2954 10.4492 51.5019 4.3709 60.0277C3.833 62.2735 3.20096 64.8554 3.02614 65.6085C2.08909 69.2476 1.4948 72.9664 1.25105 76.7162C0.982103 81.1943 1.53345 82.4718 1.8293 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask423)">
          <path d="M1.8293 83.0635C2.48823 84.4082 4.0616 85.753 7.20835 88.2543C8.57249 89.4938 10.0646 90.5847 11.6595 91.5086C13.5413 92.5985 15.7013 93.1128 17.8723 92.9878C20.898 92.5844 21.0594 90.5135 27.7966 84.6907C30.2441 82.5794 31.0375 82.1491 31.6427 80.6564C32.6109 78.3165 31.9788 76.0438 30.903 72.6685C29.1952 67.2894 28.348 64.5999 27.2049 61.9104C26.9898 61.3994 26.6401 60.2294 26.1157 58.3468L23.7355 56.4507C22.7524 55.0519 21.4286 53.9271 19.8894 53.1829C18.0929 52.3348 16.0888 52.0265 14.1204 52.2954C14.1204 52.2954 10.4492 51.5019 4.3709 60.0277C3.833 62.2735 3.20096 64.8554 3.02614 65.6085C2.08909 69.2476 1.4948 72.9664 1.25105 76.7162C0.982103 81.1943 1.53345 82.4718 1.8293 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.6061 76.2009C17.5864 76.2009 19.1208 84.6026 19.1208 94.2009H17.1978H14.1208C14.1208 84.6026 15.6257 76.2009 16.6061 76.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.4461 78.2009C18.5043 78.2009 20.722 71.1744 21.1208 64.6639L18.8234 46.4526C17.882 40.6705 17.0364 35.9811 16.4461 32.7941C15.0899 25.4641 14.8506 24.5839 14.4358 21.4424C13.5423 13.3536 13.9093 3.29266 13.2073 3.2016C12.5052 3.11054 11.7235 12.2609 11.1012 16.1005C10.3194 20.972 10.8459 23.6885 11.2767 30.457C11.829 35.7316 11.9464 41.0392 11.6277 46.3312C11.2129 50.626 10.5428 52.7355 10.2237 58.8666C9.98503 61.714 10.1622 64.5792 10.7502 67.3804C11.2884 69.6422 12.1135 71.8335 13.2073 73.9061C14.707 76.7592 15.4728 78.2009 16.4461 78.2009Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="peridontit" v-if="status && status.id == 13">
        <path d="M16.4224 23.5883C16.6645 24.6372 17.0141 26.1837 17.4579 28.026C18.2244 31.2938 19.6229 37.1973 22.4873 48.0092C25.1768 58.31 26.5216 63.4605 27.0729 64.738C28.216 67.4275 29.0766 70.117 30.7845 75.4961C31.8468 78.8849 32.4789 81.1441 31.5241 83.4974C30.9189 84.9497 30.1793 85.3666 27.6646 87.5317C20.9408 93.3411 20.7794 95.4254 17.7537 95.8154C15.5774 95.9519 13.4098 95.4369 11.5275 94.3362C9.9343 93.416 8.44626 92.3248 7.08978 91.0819C3.98338 88.5403 2.42345 87.2358 1.71073 85.9045C1.41488 85.3128 0.87698 84.0353 1.11904 79.5304C1.36165 75.7848 1.95596 72.0704 2.89412 68.4361C3.21686 67.0913 5.07263 59.4127 5.11298 59.2514C6.37705 53.8723 6.00052 44.0824 6.00052 41.4871C6.00052 31.4955 5.69122 29.3842 6.29637 23.7228C6.61256 20.5767 7.15637 17.4576 7.92353 14.3901C8.75188 11.3814 9.73943 8.41872 10.882 5.5147C11.4737 3.92788 11.8502 3 12.3612 3C13.585 3 14.5129 8.20422 14.7415 9.48174C15.4273 13.3815 15.0777 15.0491 15.7769 20.0247C16.0324 21.5712 16.261 22.8083 16.4224 23.5883Z" fill="#DEE1E9"/>
        <path d="M1.70479 85.8942C2.36372 87.2389 3.93709 88.5837 7.08383 91.0849C8.44798 92.3245 9.94009 93.4154 11.535 94.3393C13.4168 95.4292 15.5768 95.9435 17.7478 95.8185C20.7735 95.4151 20.9349 93.3442 27.6721 87.5213C30.1196 85.4101 30.913 84.9797 31.5181 83.4871C32.4864 81.1472 31.8543 78.8745 30.7785 75.4992C29.0707 70.1201 28.2235 67.4306 27.0804 64.7411C26.8653 64.2301 26.5156 63.0601 25.9912 61.1775L23.6109 59.2814C22.6279 57.8826 21.3041 56.7578 19.7649 56.0136C17.9684 55.1655 15.9643 54.8571 13.9959 55.126C13.9959 55.126 10.3247 54.3326 4.24639 62.8584C3.70848 65.1042 3.07645 67.6861 2.90163 68.4392C1.96458 72.0782 1.37029 75.7971 1.12654 79.5469C0.857592 84.025 1.40894 85.3025 1.70479 85.8942Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M15.1407 72.1476C16.8754 72.1476 18.7446 65.9214 19.0808 60.1524L17.1444 44.0152C16.3509 38.8917 15.6382 34.7364 15.1407 31.9124C13.9976 25.4172 13.7959 24.6372 13.4463 21.8536C12.6932 14.686 13.0025 5.77092 12.4108 5.69023C11.8191 5.60955 11.1602 13.7178 10.6357 17.12C9.97678 21.4367 10.4205 23.8438 10.7836 29.8414C11.2491 34.5153 11.348 39.2184 11.0795 43.9076C10.7298 47.7133 10.165 49.5825 9.89609 55.0154C9.69493 57.5385 9.84428 60.0773 10.3399 62.5595C10.7935 64.5637 11.4889 66.5054 12.4108 68.342C13.6749 70.8701 14.3204 72.1476 15.1407 72.1476Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle cx="12" cy="4" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
        <path d="M15.7796 21.1241C16.0217 22.173 16.3713 23.7194 16.8151 25.5618C17.5816 28.8295 18.9801 34.733 21.8445 45.5449C24.534 55.8458 25.8788 60.9962 26.4301 62.2738C27.5732 64.9633 28.4338 67.6528 30.1416 73.0318C31.204 76.4206 31.836 78.6798 30.8813 81.0332C30.2761 82.4855 29.5365 82.9024 27.0218 85.0675C20.298 90.8768 20.1366 92.9612 17.1109 93.3512C14.9346 93.4877 12.767 92.9727 10.8847 91.8719C9.29148 90.9518 7.80344 89.8606 6.44695 88.6176C3.34056 86.076 1.78063 84.7716 1.06791 83.4403C0.77206 82.8486 0.234158 81.5711 0.476215 77.0661C0.71883 73.3206 1.31314 69.6061 2.2513 65.9718C2.57404 64.6271 4.42981 56.9485 4.47015 56.7871C5.73423 51.4081 5.3577 41.6182 5.3577 39.0228C5.3577 29.0312 5.0484 26.92 5.65354 21.2585C5.96974 18.1124 6.51355 14.9934 7.28071 11.9259C8.10906 8.91714 9.09661 5.95448 10.2392 3.05046C10.8309 1.46365 11.2074 0.535767 11.7184 0.535767C12.9421 0.535767 13.87 5.73998 14.0986 7.0175C14.7845 10.9173 14.4348 12.5848 15.1341 17.5604C15.3896 19.1069 15.6182 20.3441 15.7796 21.1241Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M15.7796 21.1241C16.0217 22.173 16.3713 23.7194 16.8151 25.5618C17.5816 28.8295 18.9801 34.733 21.8445 45.5449C24.534 55.8458 25.8788 60.9962 26.4301 62.2738C27.5732 64.9633 28.4338 67.6528 30.1416 73.0318C31.204 76.4206 31.836 78.6798 30.8813 81.0332C30.2761 82.4855 29.5365 82.9024 27.0218 85.0675C20.298 90.8768 20.1366 92.9612 17.1109 93.3512C14.9346 93.4877 12.767 92.9727 10.8847 91.8719C9.29148 90.9518 7.80344 89.8606 6.44695 88.6176C3.34056 86.076 1.78063 84.7716 1.06791 83.4403C0.77206 82.8486 0.234158 81.5711 0.476215 77.0661C0.71883 73.3206 1.31314 69.6061 2.2513 65.9718C2.57404 64.6271 4.42981 56.9485 4.47015 56.7871C5.73423 51.4081 5.3577 41.6182 5.3577 39.0228C5.3577 29.0312 5.0484 26.92 5.65354 21.2585C5.96974 18.1124 6.51355 14.9934 7.28071 11.9259C8.10906 8.91714 9.09661 5.95448 10.2392 3.05046C10.8309 1.46365 11.2074 0.535767 11.7184 0.535767C12.9421 0.535767 13.87 5.73998 14.0986 7.0175C14.7845 10.9173 14.4348 12.5848 15.1341 17.5604C15.3896 19.1069 15.6182 20.3441 15.7796 21.1241Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
        <path d="M15.2159 25.3527C14.7721 23.5104 14.4225 21.9639 14.1804 20.915C14.019 20.135 13.7904 18.8979 13.5349 17.3514C12.8356 12.3758 13.1853 10.7083 12.4994 6.80846L12.4976 6.79789C12.2658 5.50251 11.3396 0.326721 10.1192 0.326721C9.60821 0.326721 9.23168 1.2546 8.63998 2.84142C7.49741 5.74544 6.50986 8.70809 5.68151 11.7168C4.91435 14.7843 4.37054 17.9034 4.05434 21.0495C3.4492 26.7109 3.7585 28.8222 3.7585 38.8138C3.7585 41.4092 4.13503 51.199 2.87096 56.5781C2.83061 56.7395 0.974842 64.418 0.6521 65.7628L7.45223 64.1347L14.1804 65.3544L19.3192 63.6852L24.8032 65.0337C24.2518 63.7562 22.9348 55.6367 20.2453 45.3359C17.3809 34.524 15.9824 28.6205 15.2159 25.3527Z" fill="#DEE1E9"/>
        <path d="M22.8435 58.7874C14.0226 57.6536 9.4807 58.7824 1.95039 63.3393" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M14.9023 41.342L16.8388 57.4791V66.0977L14.2556 65.6687H10.1688C9.24689 63.8321 8.55149 61.8904 8.09786 59.8862C7.60227 57.4041 7.45293 54.8652 7.65409 52.3421C7.92304 46.9093 8.48784 45.04 8.83748 41.2344C9.10604 36.5451 9.00712 31.842 8.54163 27.1682C8.17854 21.1705 7.73477 18.7634 8.3937 14.4467C8.91816 11.0445 9.5771 2.93627 10.1688 3.01696C10.7605 3.09764 10.4512 12.0127 11.2043 19.1803C11.5539 21.9639 11.7556 22.7439 12.8987 29.2391C13.3962 32.0631 14.1089 36.2184 14.9023 41.342Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
        <path d="M17.2186 25.9406C16.7748 24.0983 16.4252 22.5518 16.1831 21.5029C16.0217 20.723 15.7931 19.4858 15.5376 17.9393C14.8383 12.9637 15.188 11.2962 14.5022 7.39638L14.5003 7.38581C14.2685 6.09043 13.3423 0.914642 12.1219 0.914642C11.6109 0.914642 11.2344 1.84252 10.6427 3.42934C9.50012 6.33336 8.51258 9.29601 7.68422 12.3048C6.91706 15.3723 6.37325 18.4913 6.05706 21.6374C5.45191 27.2989 5.76121 29.4101 5.76121 39.4017C5.76121 41.9971 6.13774 51.787 4.87367 57.166C4.83333 57.3274 2.97755 65.006 2.65481 66.3507L26.8336 62.6526C26.2823 61.3751 24.9375 56.2248 22.248 45.924L22.248 45.9238C19.3836 35.1119 17.9851 29.2084 17.2186 25.9406Z" fill="#DEE1E9"/>
        <path d="M6.84451 88.9996C3.69777 86.4983 2.1244 85.1536 1.46547 83.8088C1.16962 83.2171 0.618269 81.9396 0.88722 77.4616C1.13097 73.7117 1.72526 69.9929 2.66231 66.3538C2.83712 65.6007 3.46916 63.0188 4.00707 60.7731C10.0854 52.2473 13.7566 53.0407 13.7566 53.0407C15.725 52.7718 17.7291 53.0801 19.5256 53.9282C21.0648 54.6724 22.3886 55.7972 23.3716 57.196L25.7519 59.0921C26.2763 60.9748 26.6259 62.1447 26.8411 62.6557C27.9585 65.2848 28.7931 67.9139 30.4254 73.0553L30.5392 73.4138C30.7385 74.0392 30.9226 74.6267 31.0841 75.1838L26.8411 78.5688L21.3612 78.1898L22.2683 80.835L21.0607 83.3019L22.2683 85.6153L18.4592 87.1301L19.7979 90.1651L20.8174 91.8822C19.7016 92.9984 18.9141 93.5457 17.5085 93.7332C15.3375 93.8581 13.1774 93.3438 11.2957 92.2539C9.70077 91.33 8.20866 90.2391 6.84451 88.9996Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M27.3249 70.8955C16.649 74.6688 14.3242 79.2162 15.9497 91.1517" stroke="#404040" stroke-width="1.4" fill="none"/>
        <path opacity="0.2" d="M14.9013 70.0623C16.6361 70.0623 18.5053 63.836 18.8415 58.067L16.905 41.9299C16.1116 36.8063 15.3989 32.651 14.9013 29.827C13.7583 23.3318 13.5566 22.5519 13.2069 19.7682C12.4539 12.6006 12.7632 3.68556 12.1715 3.60487C11.5798 3.52419 10.9208 11.6324 10.3964 15.0347C9.73746 19.3513 10.1812 21.7584 10.5443 27.7561C11.0098 32.4299 11.1087 37.133 10.8402 41.8223C10.4905 45.628 9.92572 47.4972 9.65677 52.93C9.45561 55.4531 9.60496 57.992 10.1005 60.4741C10.5542 62.4784 11.2496 64.4201 12.1715 66.2566C13.4356 68.7848 14.081 70.0623 14.9013 70.0623Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
        <path d="M16.2511 20.9854C16.4931 22.0344 16.8428 23.5808 17.2865 25.4232C18.053 28.6909 19.4516 34.5944 22.3159 45.4063C25.0055 55.7072 26.3502 60.8576 26.9016 62.1351C28.0446 64.8247 28.9053 67.5142 30.6131 72.8932C31.6755 76.282 32.3075 78.5412 31.3527 80.8946C30.7476 82.3469 30.008 82.7638 27.4933 84.9288C20.7695 90.7382 20.6081 92.8226 17.5824 93.2126C15.4061 93.3491 13.2385 92.8341 11.3561 91.7333C9.76295 90.8132 8.2749 89.7219 6.91842 88.479C3.81202 85.9374 2.2521 84.633 1.53937 83.3017C1.24353 82.71 0.705624 81.4325 0.947681 76.9275C1.1903 73.182 1.78461 69.4675 2.72276 65.8332C3.04551 64.4885 4.90128 56.8099 4.94162 56.6485C6.2057 51.2695 5.82916 41.4796 5.82916 38.8842C5.82916 28.8926 5.51987 26.7814 6.12501 21.1199C6.4412 17.9738 6.98501 14.8548 7.75217 11.7873C8.58053 8.77853 9.56808 5.81587 10.7106 2.91185C11.3023 1.32503 11.6789 0.397156 12.1899 0.397156C13.4136 0.397156 14.3415 5.60137 14.5701 6.87889C15.2559 10.7787 14.9063 12.4462 15.6056 17.4218C15.8611 18.9683 16.0897 20.2055 16.2511 20.9854Z" fill="#DEE1E9"/>
        <path d="M6.9159 89.4446C3.76915 86.9434 2.19578 85.5986 1.53685 84.2539C1.241 83.6622 0.689653 82.3847 0.958605 77.9066C1.20235 74.1567 1.79664 70.4379 2.73369 66.7989C2.90851 66.0458 4.01506 61.7829 4.55297 59.5372H8.3528C8.90508 59.5372 9.3528 59.9849 9.3528 60.5372V70.508C13.8369 71.6248 16.3568 71.613 20.8409 70.508V60.5372C20.8409 59.9849 21.2886 59.5372 21.8409 59.5372H25.8232C26.3477 61.4198 26.6973 62.5898 26.9125 63.1008C28.0555 65.7903 28.9027 68.4798 30.6106 73.8589C31.6864 77.2342 32.3184 79.5069 31.3502 81.8468C30.7451 83.3394 29.9517 83.7698 27.5042 85.881C20.7669 91.7038 20.6056 93.7748 17.5799 94.1782C15.4088 94.3032 13.2488 93.7889 11.3671 92.699C9.77216 91.7751 8.28004 90.6842 6.9159 89.4446Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
        <path d="M16.2511 21.2362C16.4931 22.2851 16.8428 23.8316 17.2865 25.6739C18.053 28.9417 19.4516 34.8452 22.3159 45.6571C25.0055 55.958 26.3502 61.1084 26.9016 62.3859C28.0446 65.0754 28.9053 67.765 30.6131 73.144C31.6755 76.5328 32.3075 78.792 31.3527 81.1453C30.7476 82.5977 30.008 83.0145 27.4933 85.1796C20.7695 90.989 20.6081 93.0733 17.5824 93.4633C15.4061 93.5998 13.2385 93.0849 11.3561 91.9841C9.76295 91.0639 8.2749 89.9727 6.91842 88.7298C3.81202 86.1882 2.2521 84.8838 1.53937 83.5524C1.24353 82.9607 0.705624 81.6832 0.947681 77.1783C1.1903 73.4327 1.78461 69.7183 2.72276 66.084C3.04551 64.7393 4.90128 57.0607 4.94162 56.8993C6.2057 51.5202 5.82916 41.7304 5.82916 39.135C5.82916 29.1434 5.51987 27.0321 6.12501 21.3707C6.4412 18.2246 6.98501 15.1055 7.75217 12.0381C8.58053 9.0293 9.56808 6.06664 10.7106 3.16262C11.3023 1.57581 11.6789 0.647926 12.1899 0.647926C13.4136 0.647926 14.3415 5.85214 14.5701 7.12966C15.2559 11.0295 14.9063 12.697 15.6056 17.6726C15.8611 19.2191 16.0897 20.4563 16.2511 21.2362Z" fill="#DEE1E9"/>
        <path d="M6.9159 89.6954C3.76915 87.1942 2.19578 85.8494 1.53685 84.5046C1.241 83.9129 0.689653 82.6354 0.958605 78.1574C1.20235 74.4075 1.79664 70.6887 2.73369 67.0496C2.90851 66.2966 4.01506 62.0337 4.55297 59.7879H8.3528C8.90508 59.7879 9.3528 60.2357 9.3528 60.7879V70.7588C13.8369 71.8756 16.3568 71.8637 20.8409 70.7588V60.7879C20.8409 60.2357 21.2886 59.7879 21.8409 59.7879H25.8232C26.3477 61.6706 26.6973 62.8405 26.9125 63.3516C28.0555 66.0411 28.9027 68.7306 30.6106 74.1096C31.6864 77.485 32.3184 79.7576 31.3502 82.0975C30.7451 83.5902 29.9517 84.0205 27.5042 86.1318C20.7669 91.9546 20.6056 94.0256 17.5799 94.429C15.4088 94.5539 13.2488 94.0397 11.3671 92.9498C9.77216 92.0259 8.28004 90.9349 6.9159 89.6954Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
        <path d="M16.7481 21.3039C16.9902 22.3528 17.3398 23.8993 17.7836 25.7416C18.5501 29.0094 19.9487 34.9129 22.813 45.7247C25.5025 56.0256 26.8473 61.176 27.3986 62.4536C28.5417 65.1431 29.4023 67.8326 31.1102 73.2117C32.1725 76.6004 32.8046 78.8596 31.8498 81.213C31.2447 82.6653 30.505 83.0822 27.9903 85.2473C21.2665 91.0566 21.1052 93.141 18.0794 93.531C15.9032 93.6675 13.7355 93.1525 11.8532 92.0518C10.26 91.1316 8.77197 90.0404 7.41549 88.7974C4.30909 86.2558 2.74917 84.9514 2.03644 83.6201C1.7406 83.0284 1.20269 81.7509 1.44475 77.2459C1.68737 73.5004 2.28168 69.7859 3.21984 66.1517C3.54258 64.8069 5.39835 57.1283 5.43869 56.9669C6.70277 51.5879 6.32623 41.798 6.32623 39.2026C6.32623 29.2111 6.01694 27.0998 6.62208 21.4384C6.93827 18.2922 7.48208 15.1732 8.24924 12.1057C9.0776 9.09695 10.0651 6.13429 11.2077 3.23027C11.7994 1.64345 12.1759 0.715576 12.687 0.715576C13.9107 0.715576 14.8386 5.91979 15.0672 7.19731C15.753 11.0971 15.4034 12.7646 16.1026 17.7403C16.3582 19.2867 16.5868 20.5239 16.7481 21.3039Z" fill="#DEE1E9"/>
        <path d="M7.41297 89.7631C4.26622 87.2618 2.69285 85.917 2.03392 84.5723C1.73807 83.9806 1.18672 82.7031 1.45568 78.225C1.69942 74.4752 2.29371 70.7564 3.23076 67.1173C3.40558 66.3642 4.51213 62.1013 5.05004 59.8556H9.84987V70.8264C14.3339 71.9432 16.8539 71.9314 21.338 70.8264V59.8556H26.3203C26.8448 61.7383 27.1944 62.9082 27.4096 63.4192C28.5526 66.1087 29.3998 68.7982 31.1077 74.1773C32.1835 77.5526 32.8155 79.8253 31.8473 82.1652C31.2421 83.6579 30.4487 84.0882 28.0013 86.1995C21.264 92.0223 21.1026 94.0932 18.0769 94.4966C15.9059 94.6216 13.7459 94.1073 11.8641 93.0174C10.2692 92.0935 8.77711 91.0026 7.41297 89.7631Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask2023" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="60" width="33" height="36">
          <path d="M7.413 89.7631C4.26625 87.2618 2.69288 85.917 2.03395 84.5723C1.7381 83.9806 1.18675 82.7031 1.45571 78.225C1.69945 74.4752 2.29374 70.7563 3.23079 67.1173C3.40561 66.3642 4.51216 63.033 5.05007 60.7873H8.8499C9.40218 60.7873 9.8499 61.235 9.8499 61.7873V70.8264C14.334 71.9432 16.8539 71.9314 21.338 70.8264V61.7873C21.338 61.235 21.7857 60.7873 22.338 60.7873L26.3203 60.7873C26.8448 62.6699 27.1944 62.9082 27.4096 63.4192C28.5526 66.1087 29.3998 68.7982 31.1077 74.1773C32.1835 77.5526 32.8155 79.8253 31.8473 82.1652C31.2422 83.6578 30.4488 84.0882 28.0013 86.1994C21.264 92.0223 21.1027 94.0932 18.077 94.4966C15.9059 94.6216 13.7459 94.1073 11.8642 93.0174C10.2693 92.0935 8.77714 91.0026 7.413 89.7631Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2023)">
          <path d="M24.147 49.6524H7.25249V75.2004C13.8502 77.1266 17.5493 77.1462 24.147 75.2004V49.6524Z" fill="#A2A5AE"/>
        </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
        <path d="M16.7542 21.4589C16.9963 22.5078 17.3459 24.0543 17.7897 25.8966C18.5562 29.1644 19.9547 35.0679 22.8191 45.8798C25.5086 56.1806 26.8534 61.3311 27.4047 62.6086C28.5478 65.2981 29.4084 67.9876 31.1163 73.3667C32.1786 76.7555 32.8107 79.0147 31.8559 81.368C31.2507 82.8204 30.5111 83.2372 27.9964 85.4023C21.2726 91.2117 21.1112 93.296 18.0855 93.686C15.9092 93.8225 13.7416 93.3075 11.8593 92.2068C10.2661 91.2866 8.77805 90.1954 7.42156 88.9525C4.31516 86.4109 2.75524 85.1064 2.04252 83.7751C1.74667 83.1834 1.20877 81.9059 1.45082 77.401C1.69344 73.6554 2.28775 69.941 3.22591 66.3067C3.54865 64.9619 5.40442 57.2833 5.44476 57.122C6.70884 51.7429 6.33231 41.9531 6.33231 39.3577C6.33231 29.3661 6.02301 27.2548 6.62815 21.5934C6.94435 18.4473 7.48816 15.3282 8.25532 12.2607C9.08367 9.25198 10.0712 6.28932 11.2138 3.3853C11.8055 1.79848 12.182 0.870605 12.693 0.870605C13.9168 0.870605 14.8446 6.07482 15.0733 7.35234C15.7591 11.2522 15.4094 12.9197 16.1087 17.8953C16.3642 19.4418 16.5928 20.6789 16.7542 21.4589Z" fill="#DEE1E9"/>
        <path d="M2.03658 83.7649C2.69551 85.1097 4.26888 86.4544 7.41562 88.9557C8.77977 90.1952 10.2719 91.2861 11.8668 92.21C13.7486 93.2999 15.9086 93.8142 18.0796 93.6892C21.1053 93.2858 21.2667 91.2149 28.0039 85.3921C30.4514 83.2808 31.2448 82.8505 31.8499 81.3578C32.8182 79.0179 32.1861 76.7452 31.1103 73.3699C29.4025 67.9909 28.5553 65.3013 27.4122 62.6118C27.1971 62.1008 26.8474 60.9309 26.323 59.0482L23.9427 57.1521C22.9597 55.7533 21.6359 54.6285 20.0967 53.8843C18.3002 53.0362 16.2961 52.7279 14.3277 52.9968C14.3277 52.9968 10.6565 52.2034 4.57818 60.7291C4.04027 62.9749 3.40823 65.5568 3.23342 66.3099C2.29636 69.949 1.70208 73.6678 1.45833 77.4176C1.18938 81.8957 1.74073 83.1732 2.03658 83.7649Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M15.4724 70.0181C17.2072 70.0181 19.0764 63.7918 19.4126 58.0228L17.4761 41.8857C16.6827 36.7621 15.97 32.6068 15.4724 29.7828C14.3294 23.2876 14.1277 22.5076 13.778 19.724C13.025 12.5564 13.3343 3.64134 12.7426 3.56065C12.1509 3.47997 11.492 11.5882 10.9675 14.9904C10.3086 19.3071 10.7523 21.7142 11.1154 27.7119C11.5809 32.3857 11.6798 37.0888 11.4113 41.7781C11.0616 45.5837 10.4968 47.453 10.2279 52.8858C10.0267 55.4089 10.1761 57.9478 10.6716 60.4299C11.1253 62.4341 11.8207 64.3759 12.7426 66.2124C14.0067 68.7405 14.6521 70.0181 15.4724 70.0181Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <path d="M18.1159 27.8272C13.3762 25.2332 10.7295 25.3514 6.01793 27.8272" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
        <path d="M16.3362 22.7893C16.5783 23.8382 16.9279 25.3847 17.3717 27.227C18.1382 30.4948 19.5368 36.3983 22.4011 47.2101C25.0906 57.511 26.4354 62.6615 26.9867 63.939C28.1298 66.6285 28.9904 69.318 30.6983 74.6971C31.7606 78.0859 32.3927 80.3451 31.4379 82.6984C30.8328 84.1507 30.0931 84.5676 27.5784 86.7327C20.8546 92.542 20.6933 94.6264 17.6675 95.0164C15.4913 95.1529 13.3236 94.6379 11.4413 93.5372C9.84812 92.617 8.36008 91.5258 7.00359 90.2828C3.8972 87.7412 2.33727 86.4368 1.62455 85.1055C1.3287 84.5138 0.790798 83.2363 1.03286 78.7313C1.27547 74.9858 1.86978 71.2713 2.80794 67.6371C3.13068 66.2923 4.98645 58.6137 5.02679 58.4524C6.29087 53.0733 5.91434 43.2834 5.91434 40.688C5.91434 30.6965 5.60504 28.5852 6.21018 22.9238C6.52638 19.7777 7.07019 16.6586 7.83735 13.5911C8.6657 10.5824 9.65325 7.61971 10.7958 4.71569C11.3875 3.12887 11.764 2.20099 12.2751 2.20099C13.4988 2.20099 14.4267 7.4052 14.6553 8.68273C15.3411 12.5825 14.9915 14.2501 15.6908 19.2257C15.9463 20.7721 16.1749 22.0093 16.3362 22.7893Z" fill="#DEE1E9"/>
        <mask id="mask2223" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="53" width="33" height="43">
          <path d="M1.56319 85.0635C2.22212 86.4083 3.79549 87.7531 6.94223 90.2543C8.30638 91.4938 9.79849 92.5848 11.3934 93.5087C13.2752 94.5986 15.4352 95.1128 17.6062 94.9879C20.6319 94.5845 20.7933 92.5135 27.5305 86.6907C29.978 84.5794 30.7714 84.1491 31.3765 82.6564C32.3448 80.3165 31.7127 78.0439 30.6369 74.6685C28.9291 69.2895 28.0819 66.6 26.9388 63.9105C26.7237 63.3994 26.374 62.2295 25.8496 60.3468L23.4693 58.4507C22.4863 57.0519 21.1625 55.9272 19.6233 55.183C17.8268 54.3348 15.8227 54.0265 13.8543 54.2954C13.8543 54.2954 10.1831 53.502 4.10479 62.0278C3.56688 64.2735 2.93484 66.8555 2.76003 67.6085C1.82298 71.2476 1.22869 74.9664 0.984941 78.7163C0.71599 83.1943 1.26734 84.4718 1.56319 85.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2223)">
          <path d="M1.56319 85.0635C2.22212 86.4083 3.79549 87.7531 6.94223 90.2543C8.30638 91.4938 9.79849 92.5848 11.3934 93.5087C13.2752 94.5986 15.4352 95.1128 17.6062 94.9879C20.6319 94.5845 20.7933 92.5135 27.5305 86.6907C29.978 84.5794 30.7714 84.1491 31.3765 82.6564C32.3448 80.3165 31.7127 78.0439 30.6369 74.6685C28.9291 69.2895 28.0819 66.6 26.9388 63.9105C26.7237 63.3994 26.374 62.2295 25.8496 60.3468L23.4693 58.4507C22.4863 57.0519 21.1625 55.9272 19.6233 55.183C17.8268 54.3348 15.8227 54.0265 13.8543 54.2954C13.8543 54.2954 10.1831 53.502 4.10479 62.0278C3.56688 64.2735 2.93484 66.8555 2.76003 67.6085C1.82298 71.2476 1.22869 74.9664 0.984941 78.7163C0.71599 83.1943 1.26734 84.4718 1.56319 85.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.3399 78.201C17.3203 78.201 18.8547 86.6027 18.8547 96.201H16.9317H13.8547C13.8547 86.6027 15.3596 78.201 16.3399 78.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.18 80.201C18.2381 80.201 20.4559 73.1745 20.8547 66.6639L18.5572 48.4527C17.6159 42.6706 16.7703 37.9812 16.18 34.7942C14.8238 27.4642 14.5845 26.5839 14.1697 23.4425C13.2762 15.3537 13.6432 5.29272 12.9411 5.20166C12.2391 5.11061 11.4573 14.261 10.8351 18.1005C10.0533 22.972 10.5798 25.6886 11.0106 32.4571C11.5629 37.7316 11.6802 43.0393 11.3616 48.3312C10.9468 52.6261 10.2767 54.7355 9.95759 60.8667C9.71892 63.7141 9.89611 66.5793 10.4841 69.3804C11.0223 71.6423 11.8474 73.8336 12.9411 75.9062C14.4409 78.7592 15.2067 80.201 16.18 80.201Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 12.1208 4.20099)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
        <path d="M16.3362 26.7893C16.5783 27.8382 16.9279 29.3847 17.3717 31.227C18.1382 34.4948 19.5368 40.3983 22.4011 51.2101C25.0906 61.511 26.4354 66.6615 26.9867 67.939C28.1298 70.6285 28.9904 73.318 30.6983 78.6971C31.7606 82.0859 32.3927 84.3451 31.4379 86.6984C30.8328 88.1507 30.0931 88.5676 27.5784 90.7327C20.8546 96.542 20.6933 98.6264 17.6675 99.0164C15.4913 99.1529 13.3236 98.6379 11.4413 97.5372C9.84812 96.617 8.36008 95.5258 7.00359 94.2828C3.8972 91.7412 2.33727 90.4368 1.62455 89.1055C1.3287 88.5138 0.790798 87.2363 1.03286 82.7313C1.27547 78.9858 1.86978 75.2713 2.80794 71.6371C3.13068 70.2923 4.98645 62.6137 5.02679 62.4524C6.29087 57.0733 5.91434 47.2834 5.91434 44.688C5.91434 34.6965 5.60504 32.5852 6.21018 26.9238C6.52638 23.7777 7.07019 20.6586 7.83735 17.5911C8.6657 14.5824 9.65325 11.6197 10.7958 8.71569C11.3875 7.12887 11.764 6.20099 12.2751 6.20099C13.4988 6.20099 14.4267 11.4052 14.6553 12.6827C15.3411 16.5825 14.9915 18.2501 15.6908 23.2257C15.9463 24.7721 16.1749 26.0093 16.3362 26.7893Z" fill="#DEE1E9"/>
        <mask id="mask2323" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="57" width="33" height="43">
          <path d="M1.56319 89.0635C2.22212 90.4083 3.79549 91.7531 6.94223 94.2543C8.30638 95.4938 9.79849 96.5848 11.3934 97.5087C13.2752 98.5986 15.4352 99.1128 17.6062 98.9879C20.6319 98.5845 20.7933 96.5135 27.5305 90.6907C29.978 88.5794 30.7714 88.1491 31.3765 86.6564C32.3448 84.3165 31.7127 82.0439 30.6369 78.6685C28.9291 73.2895 28.0819 70.6 26.9388 67.9105C26.7237 67.3994 26.374 66.2295 25.8496 64.3468L23.4693 62.4507C22.4863 61.0519 21.1625 59.9272 19.6233 59.183C17.8268 58.3348 15.8227 58.0265 13.8543 58.2954C13.8543 58.2954 10.1831 57.502 4.10479 66.0278C3.56688 68.2735 2.93484 70.8555 2.76003 71.6085C1.82298 75.2476 1.22869 78.9664 0.984941 82.7163C0.71599 87.1943 1.26734 88.4718 1.56319 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2323)">
          <path d="M1.56319 89.0635C2.22212 90.4083 3.79549 91.7531 6.94223 94.2543C8.30638 95.4938 9.79849 96.5848 11.3934 97.5087C13.2752 98.5986 15.4352 99.1128 17.6062 98.9879C20.6319 98.5845 20.7933 96.5135 27.5305 90.6907C29.978 88.5794 30.7714 88.1491 31.3765 86.6564C32.3448 84.3165 31.7127 82.0439 30.6369 78.6685C28.9291 73.2895 28.0819 70.6 26.9388 67.9105C26.7237 67.3994 26.374 66.2295 25.8496 64.3468L23.4693 62.4507C22.4863 61.0519 21.1625 59.9272 19.6233 59.183C17.8268 58.3348 15.8227 58.0265 13.8543 58.2954C13.8543 58.2954 10.1831 57.502 4.10479 66.0278C3.56688 68.2735 2.93484 70.8555 2.76003 71.6085C1.82298 75.2476 1.22869 78.9664 0.984941 82.7163C0.71599 87.1943 1.26734 88.4718 1.56319 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.3399 82.201C17.3203 82.201 18.8547 90.6027 18.8547 100.201H16.9317H13.8547C13.8547 90.6027 15.3596 82.201 16.3399 82.201Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.18 84.201C18.2381 84.201 20.4559 77.1745 20.8547 70.6639L18.5572 52.4527C17.6159 46.6706 16.7703 41.9812 16.18 38.7942C14.8238 31.4642 14.5845 30.5839 14.1697 27.4425C13.2762 19.3537 13.6432 9.29272 12.9411 9.20166C12.2391 9.11061 11.4573 18.261 10.8351 22.1005C10.0533 26.972 10.5798 29.6886 11.0106 36.4571C11.5629 41.7316 11.6802 47.0393 11.3616 52.3312C10.9468 56.6261 10.2767 58.7355 9.95759 64.8667C9.71892 67.7141 9.89611 70.5793 10.4841 73.3804C11.0223 75.6423 11.8474 77.8336 12.9411 79.9062C14.4409 82.7592 15.2067 84.201 16.18 84.201Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 12.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
        <g filter="url(#filter2323)">
          <path d="M13.1208 0.200989L12.2979 3.94009L9.26412 1.60472L11.0372 4.99799L7.212 5.1591L10.7514 6.61879L7.9247 9.20099L11.5743 8.04409L11.0687 11.8391L13.1208 8.60699L15.173 11.8391L14.6674 8.04409L18.317 9.20099L15.4903 6.61879L19.0297 5.1591L15.2045 4.99799L16.9776 1.60472L13.9437 3.94009L13.1208 0.200989Z" fill="#98B250"/>
        </g>
        <defs>
          <filter id="filter2323" x="7.01201" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
            <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
          </filter>
        </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M16.3362 26.7893C16.5783 27.8382 16.9279 29.3847 17.3717 31.227C18.1382 34.4948 19.5368 40.3983 22.4011 51.2101C25.0906 61.511 26.4354 66.6615 26.9867 67.939C28.1298 70.6285 28.9904 73.318 30.6983 78.6971C31.7606 82.0859 32.3927 84.3451 31.4379 86.6984C30.8328 88.1507 30.0931 88.5676 27.5784 90.7327C20.8546 96.542 20.6933 98.6264 17.6675 99.0164C15.4913 99.1529 13.3236 98.6379 11.4413 97.5372C9.84812 96.617 8.36008 95.5258 7.00359 94.2828C3.8972 91.7412 2.33727 90.4368 1.62455 89.1055C1.3287 88.5138 0.790798 87.2363 1.03286 82.7313C1.27547 78.9858 1.86978 75.2713 2.80794 71.6371C3.13068 70.2923 4.98645 62.6137 5.02679 62.4524C6.29087 57.0733 5.91434 47.2834 5.91434 44.688C5.91434 34.6965 5.60504 32.5852 6.21018 26.9238C6.52638 23.7777 7.07019 20.6586 7.83735 17.5911C8.6657 14.5824 9.65325 11.6197 10.7958 8.71569C11.3875 7.12887 11.764 6.20099 12.2751 6.20099C13.4988 6.20099 14.4267 11.4052 14.6553 12.6827C15.3411 16.5825 14.9915 18.2501 15.6908 23.2257C15.9463 24.7721 16.1749 26.0093 16.3362 26.7893Z" fill="#DEE1E9"/>
      <mask id="mask2323" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="57" width="33" height="43">
        <path d="M1.56319 89.0635C2.22212 90.4083 3.79549 91.7531 6.94223 94.2543C8.30638 95.4938 9.79849 96.5848 11.3934 97.5087C13.2752 98.5986 15.4352 99.1128 17.6062 98.9879C20.6319 98.5845 20.7933 96.5135 27.5305 90.6907C29.978 88.5794 30.7714 88.1491 31.3765 86.6564C32.3448 84.3165 31.7127 82.0439 30.6369 78.6685C28.9291 73.2895 28.0819 70.6 26.9388 67.9105C26.7237 67.3994 26.374 66.2295 25.8496 64.3468L23.4693 62.4507C22.4863 61.0519 21.1625 59.9272 19.6233 59.183C17.8268 58.3348 15.8227 58.0265 13.8543 58.2954C13.8543 58.2954 10.1831 57.502 4.10479 66.0278C3.56688 68.2735 2.93484 70.8555 2.76003 71.6085C1.82298 75.2476 1.22869 78.9664 0.984941 82.7163C0.71599 87.1943 1.26734 88.4718 1.56319 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2323)">
        <path d="M1.56319 89.0635C2.22212 90.4083 3.79549 91.7531 6.94223 94.2543C8.30638 95.4938 9.79849 96.5848 11.3934 97.5087C13.2752 98.5986 15.4352 99.1128 17.6062 98.9879C20.6319 98.5845 20.7933 96.5135 27.5305 90.6907C29.978 88.5794 30.7714 88.1491 31.3765 86.6564C32.3448 84.3165 31.7127 82.0439 30.6369 78.6685C28.9291 73.2895 28.0819 70.6 26.9388 67.9105C26.7237 67.3994 26.374 66.2295 25.8496 64.3468L23.4693 62.4507C22.4863 61.0519 21.1625 59.9272 19.6233 59.183C17.8268 58.3348 15.8227 58.0265 13.8543 58.2954C13.8543 58.2954 10.1831 57.502 4.10479 66.0278C3.56688 68.2735 2.93484 70.8555 2.76003 71.6085C1.82298 75.2476 1.22869 78.9664 0.984941 82.7163C0.71599 87.1943 1.26734 88.4718 1.56319 89.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M16.3399 82.201C17.3203 82.201 18.8547 90.6027 18.8547 100.201H16.9317H13.8547C13.8547 90.6027 15.3596 82.201 16.3399 82.201Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M16.18 84.201C18.2381 84.201 20.4559 77.1745 20.8547 70.6639L18.5572 52.4527C17.6159 46.6706 16.7703 41.9812 16.18 38.7942C14.8238 31.4642 14.5845 30.5839 14.1697 27.4425C13.2762 19.3537 13.6432 9.29272 12.9411 9.20166C12.2391 9.11061 11.4573 18.261 10.8351 22.1005C10.0533 26.972 10.5798 29.6886 11.0106 36.4571C11.5629 41.7316 11.6802 47.0393 11.3616 52.3312C10.9468 56.6261 10.2767 58.7355 9.95759 64.8667C9.71892 67.7141 9.89611 70.5793 10.4841 73.3804C11.0223 75.6423 11.8474 77.8336 12.9411 79.9062C14.4409 82.7592 15.2067 84.201 16.18 84.201Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle r="4" transform="matrix(-1 0 0 1 12.1208 8.20099)" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2323)">
        <path d="M13.1208 0.200989L12.2979 3.94009L9.26412 1.60472L11.0372 4.99799L7.212 5.1591L10.7514 6.61879L7.9247 9.20099L11.5743 8.04409L11.0687 11.8391L13.1208 8.60699L15.173 11.8391L14.6674 8.04409L18.317 9.20099L15.4903 6.61879L19.0297 5.1591L15.2045 4.99799L16.9776 1.60472L13.9437 3.94009L13.1208 0.200989Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2323" x="7.01201" y="0.000988767" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
        <path d="M16.3362 23.7892C16.5783 24.8381 16.9279 26.3846 17.3717 28.2269C18.1382 31.4947 19.5368 37.3982 22.4011 48.2101C25.0906 58.511 26.4354 63.6614 26.9867 64.9389C28.1298 67.6284 28.9904 70.318 30.6983 75.697C31.7606 79.0858 32.3927 81.345 31.4379 83.6983C30.8328 85.1507 30.0931 85.5676 27.5784 87.7326C20.8546 93.542 20.6933 95.6263 17.6675 96.0163C15.4913 96.1528 13.3236 95.6379 11.4413 94.5371C9.84812 93.6169 8.36008 92.5257 7.00359 91.2828C3.8972 88.7412 2.33727 87.4368 1.62455 86.1054C1.3287 85.5137 0.790798 84.2362 1.03286 79.7313C1.27547 75.9857 1.86978 72.2713 2.80794 68.637C3.13068 67.2923 4.98645 59.6137 5.02679 59.4523C6.29087 54.0732 5.91434 44.2834 5.91434 41.688C5.91434 31.6964 5.60504 29.5851 6.21018 23.9237C6.52638 20.7776 7.07019 17.6585 7.83735 14.5911C8.6657 11.5823 9.65325 8.61964 10.7958 5.71562C11.3875 4.12881 11.764 3.20093 12.2751 3.20093C13.4988 3.20093 14.4267 8.40514 14.6553 9.68267C15.3411 13.5825 14.9915 15.25 15.6908 20.2256C15.9463 21.7721 16.1749 23.0093 16.3362 23.7892Z" fill="#DEE1E9"/>
        <mask id="mask2523" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="54" width="33" height="43">
          <path d="M1.56319 86.0635C2.22212 87.4082 3.79549 88.753 6.94223 91.2543C8.30638 92.4938 9.79849 93.5847 11.3934 94.5086C13.2752 95.5985 15.4352 96.1128 17.6062 95.9878C20.6319 95.5844 20.7933 93.5135 27.5305 87.6907C29.978 85.5794 30.7714 85.1491 31.3765 83.6564C32.3448 81.3165 31.7127 79.0438 30.6369 75.6685C28.9291 70.2894 28.0819 67.5999 26.9388 64.9104C26.7237 64.3994 26.374 63.2294 25.8496 61.3468L23.4693 59.4507C22.4863 58.0519 21.1625 56.9271 19.6233 56.1829C17.8268 55.3348 15.8227 55.0265 13.8543 55.2954C13.8543 55.2954 10.1831 54.5019 4.10479 63.0277C3.56688 65.2735 2.93484 67.8554 2.76003 68.6085C1.82298 72.2476 1.22869 75.9664 0.984941 79.7162C0.71599 84.1943 1.26734 85.4718 1.56319 86.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2523)">
          <path d="M1.56319 86.0635C2.22212 87.4082 3.79549 88.753 6.94223 91.2543C8.30638 92.4938 9.79849 93.5847 11.3934 94.5086C13.2752 95.5985 15.4352 96.1128 17.6062 95.9878C20.6319 95.5844 20.7933 93.5135 27.5305 87.6907C29.978 85.5794 30.7714 85.1491 31.3765 83.6564C32.3448 81.3165 31.7127 79.0438 30.6369 75.6685C28.9291 70.2894 28.0819 67.5999 26.9388 64.9104C26.7237 64.3994 26.374 63.2294 25.8496 61.3468L23.4693 59.4507C22.4863 58.0519 21.1625 56.9271 19.6233 56.1829C17.8268 55.3348 15.8227 55.0265 13.8543 55.2954C13.8543 55.2954 10.1831 54.5019 4.10479 63.0277C3.56688 65.2735 2.93484 67.8554 2.76003 68.6085C1.82298 72.2476 1.22869 75.9664 0.984941 79.7162C0.71599 84.1943 1.26734 85.4718 1.56319 86.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.6061 86.2009C17.5864 86.2009 19.1208 91.3353 19.1208 97.2009H17.1978H14.1208C14.1208 91.3353 15.6257 86.2009 16.6061 86.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.4461 87.2009C18.5043 87.2009 20.722 79.6123 21.1208 72.5809L18.8234 52.9127C17.882 46.6681 17.0364 41.6035 16.4461 38.1616C15.0899 30.2452 14.8506 29.2945 14.4358 25.9018C13.5423 17.1658 13.9093 6.3 13.2073 6.20165C12.5052 6.10331 11.7235 15.9857 11.1012 20.1324C10.3194 25.3937 10.8459 28.3275 11.2767 35.6375C11.829 41.334 11.9464 47.0663 11.6277 52.7816C11.2129 57.42 10.5428 59.6982 10.2237 66.3199C9.98503 69.3951 10.1622 72.4895 10.7502 75.5147C11.2884 77.9575 12.1135 80.3241 13.2073 82.5625C14.707 85.6438 15.4728 87.2009 16.4461 87.2009Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        <circle r="4" transform="matrix(-1 0 0 1 12.1208 4.20093)" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
        <mask id="mask2623" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="95">
          <path d="M16.251 20.7892C16.4931 21.8381 16.8427 23.3846 17.2865 25.2269C18.053 28.4947 19.4516 34.3982 22.3159 45.2101C25.0054 55.511 26.3502 60.6614 26.9015 61.9389C28.0446 64.6284 28.9052 67.318 30.6131 72.697C31.6754 76.0858 32.3075 78.345 31.3527 80.6983C30.7476 82.1507 30.0079 82.5676 27.4932 84.7326C20.7694 90.542 20.6081 92.6263 17.5823 93.0163C15.4061 93.1528 13.2384 92.6379 11.3561 91.5371C9.76291 90.6169 8.27487 89.5257 6.91839 88.2828C3.81199 85.7412 2.25207 84.4368 1.53934 83.1054C1.2435 82.5137 0.705593 81.2362 0.947651 76.7313C1.19027 72.9857 1.78458 69.2713 2.72273 65.637C3.04548 64.2923 4.90125 56.6137 4.94159 56.4523C6.20567 51.0732 5.82913 41.2834 5.82913 38.688C5.82913 28.6964 5.51984 26.5851 6.12498 20.9237C6.44117 17.7776 6.98498 14.6585 7.75214 11.5911C8.5805 8.5823 9.56804 5.61964 10.7106 2.71562C11.3023 1.12881 11.6788 0.200928 12.1899 0.200928C13.4136 0.200928 14.3415 5.40514 14.5701 6.68267C15.2559 10.5825 14.9063 12.25 15.6055 17.2256C15.861 18.7721 16.0897 20.0093 16.251 20.7892Z" fill="#DEE1E9"/>
          <path d="M2.06612 84.0576C2.72505 85.4024 4.29842 86.7471 7.44516 89.2484C8.80931 90.4879 10.3014 91.5788 11.8963 92.5027C13.7781 93.5926 15.9381 94.1069 18.1091 93.982C21.1348 93.5785 21.2962 91.5076 28.0335 85.6848C30.4809 83.5735 31.2743 83.1432 31.8795 81.6505C32.8477 79.3106 32.2157 77.038 31.1399 73.6626C29.432 68.2836 28.5848 65.5941 27.4418 62.9045C27.2266 62.3935 26.877 61.2236 26.3525 59.3409L23.9723 57.4448C22.9892 56.046 21.6655 54.9213 20.1263 54.177C18.3297 53.3289 16.3256 53.0206 14.3572 53.2895C14.3572 53.2895 10.686 52.4961 4.60772 61.0219C4.06981 63.2676 3.43777 65.8496 3.26296 66.6026C2.3259 70.2417 1.73162 73.9605 1.48787 77.7104C1.21892 82.1884 1.77027 83.4659 2.06612 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask2623)">
          <path d="M16.251 20.7892C16.4931 21.8381 16.8427 23.3846 17.2865 25.2269C18.053 28.4947 19.4516 34.3982 22.3159 45.2101C25.0054 55.511 26.3502 60.6614 26.9015 61.9389C28.0446 64.6284 28.9052 67.318 30.6131 72.697C31.6754 76.0858 32.3075 78.345 31.3527 80.6983C30.7476 82.1507 30.0079 82.5676 27.4932 84.7326C20.7694 90.542 20.6081 92.6263 17.5823 93.0163C15.4061 93.1528 13.2384 92.6379 11.3561 91.5371C9.76291 90.6169 8.27487 89.5257 6.91839 88.2828C3.81199 85.7412 2.25207 84.4368 1.53934 83.1054C1.2435 82.5137 0.705593 81.2362 0.947651 76.7313C1.19027 72.9857 1.78458 69.2713 2.72273 65.637C3.04548 64.2923 4.90125 56.6137 4.94159 56.4523C6.20567 51.0732 5.82913 41.2834 5.82913 38.688C5.82913 28.6964 5.51984 26.5851 6.12498 20.9237C6.44117 17.7776 6.98498 14.6585 7.75214 11.5911C8.5805 8.5823 9.56804 5.61964 10.7106 2.71562C11.3023 1.12881 11.6788 0.200928 12.1899 0.200928C13.4136 0.200928 14.3415 5.40514 14.5701 6.68267C15.2559 10.5825 14.9063 12.25 15.6055 17.2256C15.861 18.7721 16.0897 20.0093 16.251 20.7892Z" fill="#DEE1E9"/>
          <path d="M2.06612 84.0576C2.72505 85.4024 4.29842 86.7471 7.44516 89.2484C8.80931 90.4879 10.3014 91.5788 11.8963 92.5027C13.7781 93.5926 15.9381 94.1069 18.1091 93.982C21.1348 93.5785 21.2962 91.5076 28.0335 85.6848C30.4809 83.5735 31.2743 83.1432 31.8795 81.6505C32.8477 79.3106 32.2157 77.038 31.1399 73.6626C29.432 68.2836 28.5848 65.5941 27.4418 62.9045C27.2266 62.3935 26.877 61.2236 26.3525 59.3409L23.9723 57.4448C22.9892 56.046 21.6655 54.9213 20.1263 54.177C18.3297 53.3289 16.3256 53.0206 14.3572 53.2895C14.3572 53.2895 10.686 52.4961 4.60772 61.0219C4.06981 63.2676 3.43777 65.8496 3.26296 66.6026C2.3259 70.2417 1.73162 73.9605 1.48787 77.7104C1.21892 82.1884 1.77027 83.4659 2.06612 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M13.5978 60.5247H1.67667C3.83873 55.1549 4.19573 51.8947 3.8992 46.1564C3.88802 45.94 4.13739 45.8054 4.32761 45.932C5.33169 46.6 7.68988 48.4161 8.26153 51.0577C8.954 54.2577 10.9572 55.0348 11.1698 55.1089C11.1828 55.1134 11.1944 55.1179 11.2067 55.1236L12.3897 55.6703C12.5342 55.7371 12.5928 55.8996 12.5206 56.0332L11.9916 57.0109C11.9469 57.0936 11.951 57.1917 12.0024 57.271L13.8488 60.1151C13.9658 60.2954 13.8253 60.5247 13.5978 60.5247Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.67275 47.0459C5.73373 47.8938 7.14171 49.3307 7.54413 51.1903C8.28734 54.6248 10.4633 55.5809 10.8963 55.7361L11.6699 56.0936L11.3373 56.7084C11.1806 56.9979 11.1949 57.3413 11.3751 57.6189L12.8225 59.8485H2.71999C4.49672 55.1838 4.87272 51.9881 4.67275 47.0459ZM10.8796 55.7284C10.8794 55.7283 10.8797 55.7285 10.8796 55.7284V55.7284ZM4.75508 45.3832C4.02622 44.8983 3.13049 45.4547 3.16842 46.1886C3.46123 51.8548 3.11202 55.0209 0.990997 60.2889L0.623778 61.2009H13.5979C14.3941 61.2009 14.8859 60.3982 14.4762 59.7672L12.7539 57.114L13.175 56.3356C13.428 55.8679 13.2229 55.2992 12.7169 55.0654L11.534 54.5188C11.4933 54.5 11.4574 54.4864 11.4279 54.4761C11.3913 54.4634 9.61049 53.8432 8.97901 50.9251C8.34951 48.0161 5.78613 46.0691 4.75508 45.3832Z" fill="#404040"/>
        </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
        <mask id="mask123" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="33" height="95">
          <path d="M16.251 20.7892C16.4931 21.8381 16.8427 23.3846 17.2865 25.2269C18.053 28.4947 19.4516 34.3982 22.3159 45.2101C25.0054 55.511 26.3502 60.6614 26.9015 61.9389C28.0446 64.6284 28.9052 67.318 30.6131 72.697C31.6754 76.0858 32.3075 78.345 31.3527 80.6983C30.7476 82.1507 30.0079 82.5676 27.4932 84.7326C20.7694 90.542 20.6081 92.6263 17.5823 93.0163C15.4061 93.1528 13.2384 92.6379 11.3561 91.5371C9.76291 90.6169 8.27487 89.5257 6.91839 88.2828C3.81199 85.7412 2.25207 84.4368 1.53934 83.1054C1.2435 82.5137 0.705593 81.2362 0.947651 76.7313C1.19027 72.9857 1.78458 69.2713 2.72273 65.637C3.04548 64.2923 4.90125 56.6137 4.94159 56.4523C6.20567 51.0732 5.82913 41.2834 5.82913 38.688C5.82913 28.6964 5.51984 26.5851 6.12498 20.9237C6.44117 17.7776 6.98498 14.6585 7.75214 11.5911C8.5805 8.5823 9.56804 5.61964 10.7106 2.71562C11.3023 1.12881 11.6788 0.200928 12.1899 0.200928C13.4136 0.200928 14.3415 5.40514 14.5701 6.68267C15.2559 10.5825 14.9063 12.25 15.6055 17.2256C15.861 18.7721 16.0897 20.0093 16.251 20.7892Z" fill="#DEE1E9"/>
          <path d="M2.06612 84.0576C2.72505 85.4024 4.29842 86.7471 7.44516 89.2484C8.80931 90.4879 10.3014 91.5788 11.8963 92.5027C13.7781 93.5926 15.9381 94.1069 18.1091 93.982C21.1348 93.5785 21.2962 91.5076 28.0335 85.6848C30.4809 83.5735 31.2743 83.1432 31.8795 81.6505C32.8477 79.3106 32.2157 77.038 31.1399 73.6626C29.432 68.2836 28.5848 65.5941 27.4418 62.9045C27.2266 62.3935 26.877 61.2236 26.3525 59.3409L23.9723 57.4448C22.9892 56.046 21.6655 54.9213 20.1263 54.177C18.3297 53.3289 16.3256 53.0206 14.3572 53.2895C14.3572 53.2895 10.686 52.4961 4.60772 61.0219C4.06981 63.2676 3.43777 65.8496 3.26296 66.6026C2.3259 70.2417 1.73162 73.9605 1.48787 77.7104C1.21892 82.1884 1.77027 83.4659 2.06612 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask123)">
          <path d="M16.251 20.7892C16.4931 21.8381 16.8427 23.3846 17.2865 25.2269C18.053 28.4947 19.4516 34.3982 22.3159 45.2101C25.0054 55.511 26.3502 60.6614 26.9015 61.9389C28.0446 64.6284 28.9052 67.318 30.6131 72.697C31.6754 76.0858 32.3075 78.345 31.3527 80.6983C30.7476 82.1507 30.0079 82.5676 27.4932 84.7326C20.7694 90.542 20.6081 92.6263 17.5823 93.0163C15.4061 93.1528 13.2384 92.6379 11.3561 91.5371C9.76291 90.6169 8.27487 89.5257 6.91839 88.2828C3.81199 85.7412 2.25207 84.4368 1.53934 83.1054C1.2435 82.5137 0.705593 81.2362 0.947651 76.7313C1.19027 72.9857 1.78458 69.2713 2.72273 65.637C3.04548 64.2923 4.90125 56.6137 4.94159 56.4523C6.20567 51.0732 5.82913 41.2834 5.82913 38.688C5.82913 28.6964 5.51984 26.5851 6.12498 20.9237C6.44117 17.7776 6.98498 14.6585 7.75214 11.5911C8.5805 8.5823 9.56804 5.61964 10.7106 2.71562C11.3023 1.12881 11.6788 0.200928 12.1899 0.200928C13.4136 0.200928 14.3415 5.40514 14.5701 6.68267C15.2559 10.5825 14.9063 12.25 15.6055 17.2256C15.861 18.7721 16.0897 20.0093 16.251 20.7892Z" fill="#DEE1E9"/>
          <path d="M2.06612 84.0576C2.72505 85.4024 4.29842 86.7471 7.44516 89.2484C8.80931 90.4879 10.3014 91.5788 11.8963 92.5027C13.7781 93.5926 15.9381 94.1069 18.1091 93.982C21.1348 93.5785 21.2962 91.5076 28.0335 85.6848C30.4809 83.5735 31.2743 83.1432 31.8795 81.6505C32.8477 79.3106 32.2157 77.038 31.1399 73.6626C29.432 68.2836 28.5848 65.5941 27.4418 62.9045C27.2266 62.3935 26.877 61.2236 26.3525 59.3409L23.9723 57.4448C22.9892 56.046 21.6655 54.9213 20.1263 54.177C18.3297 53.3289 16.3256 53.0206 14.3572 53.2895C14.3572 53.2895 10.686 52.4961 4.60772 61.0219C4.06981 63.2676 3.43777 65.8496 3.26296 66.6026C2.3259 70.2417 1.73162 73.9605 1.48787 77.7104C1.21892 82.1884 1.77027 83.4659 2.06612 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M10.9227 60.5315H2.77592C4.25343 56.561 4.49741 54.1504 4.29476 49.9074C4.28712 49.7474 4.45754 49.6479 4.58753 49.7415C5.2737 50.2354 6.88526 51.5782 7.27592 53.5315C7.74913 55.8976 9.1181 56.4722 9.26339 56.527C9.27224 56.5303 9.28016 56.5337 9.28863 56.5379L10.097 56.9421C10.1958 56.9915 10.2359 57.1116 10.1865 57.2104L9.82501 57.9333C9.79443 57.9945 9.79722 58.067 9.8324 58.1257L11.0942 60.2286C11.1742 60.3619 11.0781 60.5315 10.9227 60.5315Z" fill="#404040"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M4.82339 50.5651C5.54845 51.1921 6.51064 52.2545 6.78565 53.6295C7.29354 56.169 8.78057 56.876 9.07646 56.9908L9.60512 57.2551L9.37782 57.7097C9.27079 57.9238 9.28054 58.1777 9.40368 58.3829L10.3928 60.0315H3.4889C4.70309 56.5824 4.96005 54.2194 4.82339 50.5651ZM9.06504 56.9851C9.06494 56.985 9.06514 56.9851 9.06504 56.9851V56.9851ZM4.87965 49.3357C4.38156 48.9771 3.76943 49.3885 3.79535 49.9312C3.99545 54.1208 3.75681 56.4619 2.30733 60.3571L2.05638 61.0315H10.9227C11.4668 61.0315 11.8029 60.4379 11.5229 59.9713L10.3459 58.0096L10.6337 57.434C10.8066 57.0882 10.6664 56.6677 10.3207 56.4948L9.51226 56.0906C9.48443 56.0767 9.45993 56.0667 9.43976 56.0591C9.41475 56.0497 8.19777 55.5911 7.76623 53.4334C7.33604 51.2825 5.58425 49.8428 4.87965 49.3357Z" fill="#404040"/>
        </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
        <path d="M16.6023 20.7892C16.8444 21.8381 17.194 23.3846 17.6378 25.2269C18.4043 28.4947 19.8029 34.3982 22.6672 45.2101C25.3567 55.511 26.7015 60.6614 27.2529 61.9389C28.3959 64.6284 29.2566 67.318 30.9644 72.697C32.0268 76.0858 32.6588 78.345 31.704 80.6983C31.0989 82.1507 30.3593 82.5676 27.8446 84.7326C21.1207 90.542 20.9594 92.6263 17.9337 93.0163C15.7574 93.1528 13.5898 92.6379 11.7074 91.5371C10.1142 90.6169 8.62619 89.5257 7.26971 88.2828C4.16331 85.7412 2.60339 84.4368 1.89066 83.1054C1.59481 82.5137 1.05691 81.2362 1.29897 76.7313C1.54158 72.9857 2.13589 69.2713 3.07405 65.637C3.3968 64.2923 5.25257 56.6137 5.29291 56.4523C6.55698 51.0732 6.18045 41.2834 6.18045 38.688C6.18045 28.6964 5.87115 26.5851 6.4763 20.9237C6.79249 17.7776 7.3363 14.6585 8.10346 11.5911C8.93182 8.5823 9.91936 5.61964 11.0619 2.71562C11.6536 1.12881 12.0302 0.200928 12.5412 0.200928C13.7649 0.200928 14.6928 5.40514 14.9214 6.68267C15.6072 10.5825 15.2576 12.25 15.9569 17.2256C16.2124 18.7721 16.441 20.0093 16.6023 20.7892Z" fill="#DEE1E9"/>
        <mask id="mask223" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="33" height="43">
          <path d="M1.8293 83.0635C2.48823 84.4082 4.0616 85.753 7.20835 88.2543C8.57249 89.4938 10.0646 90.5847 11.6595 91.5086C13.5413 92.5985 15.7013 93.1128 17.8723 92.9878C20.898 92.5844 21.0594 90.5135 27.7966 84.6907C30.2441 82.5794 31.0375 82.1491 31.6427 80.6564C32.6109 78.3165 31.9788 76.0438 30.903 72.6685C29.1952 67.2894 28.348 64.5999 27.2049 61.9104C26.9898 61.3994 26.6401 60.2294 26.1157 58.3468L23.7355 56.4507C22.7524 55.0519 21.4286 53.9271 19.8894 53.1829C18.0929 52.3348 16.0888 52.0265 14.1204 52.2954C14.1204 52.2954 10.4492 51.5019 4.3709 60.0277C3.833 62.2735 3.20096 64.8554 3.02614 65.6085C2.08909 69.2476 1.4948 72.9664 1.25105 76.7162C0.982103 81.1943 1.53345 82.4718 1.8293 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask223)">
          <path d="M1.8293 83.0635C2.48823 84.4082 4.0616 85.753 7.20835 88.2543C8.57249 89.4938 10.0646 90.5847 11.6595 91.5086C13.5413 92.5985 15.7013 93.1128 17.8723 92.9878C20.898 92.5844 21.0594 90.5135 27.7966 84.6907C30.2441 82.5794 31.0375 82.1491 31.6427 80.6564C32.6109 78.3165 31.9788 76.0438 30.903 72.6685C29.1952 67.2894 28.348 64.5999 27.2049 61.9104C26.9898 61.3994 26.6401 60.2294 26.1157 58.3468L23.7355 56.4507C22.7524 55.0519 21.4286 53.9271 19.8894 53.1829C18.0929 52.3348 16.0888 52.0265 14.1204 52.2954C14.1204 52.2954 10.4492 51.5019 4.3709 60.0277C3.833 62.2735 3.20096 64.8554 3.02614 65.6085C2.08909 69.2476 1.4948 72.9664 1.25105 76.7162C0.982103 81.1943 1.53345 82.4718 1.8293 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.6001 76.2009C17.9727 76.2009 20.1208 84.6026 20.1208 94.2009H17.4285H13.1208C13.1208 84.6026 15.2276 76.2009 16.6001 76.2009Z" fill="#404040"/>
        </g>
        <path opacity="0.2" d="M16.4461 78.2009C18.5043 78.2009 20.722 71.1744 21.1208 64.6639L18.8234 46.4526C17.882 40.6705 17.0364 35.9811 16.4461 32.7941C15.0899 25.4641 14.8506 24.5839 14.4358 21.4424C13.5423 13.3536 13.9093 3.29266 13.2073 3.2016C12.5052 3.11054 11.7235 12.2609 11.1012 16.1005C10.3194 20.972 10.8459 23.6885 11.2767 30.457C11.829 35.7316 11.9464 41.0392 11.6277 46.3312C11.2129 50.626 10.5428 52.7355 10.2237 58.8666C9.98503 61.714 10.1622 64.5792 10.7502 67.3804C11.2884 69.6422 12.1135 71.8335 13.2073 73.9061C14.707 76.7592 15.4728 78.2009 16.4461 78.2009Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
        <path d="M16.6023 20.7892C16.8444 21.8381 17.194 23.3846 17.6378 25.2269C18.4043 28.4947 19.8029 34.3982 22.6672 45.2101C25.3567 55.511 26.7015 60.6614 27.2529 61.9389C28.3959 64.6284 29.2566 67.318 30.9644 72.697C32.0268 76.0858 32.6588 78.345 31.704 80.6983C31.0989 82.1507 30.3593 82.5676 27.8446 84.7326C21.1207 90.542 20.9594 92.6263 17.9337 93.0163C15.7574 93.1528 13.5898 92.6379 11.7074 91.5371C10.1142 90.6169 8.62619 89.5257 7.26971 88.2828C4.16331 85.7412 2.60339 84.4368 1.89066 83.1054C1.59481 82.5137 1.05691 81.2362 1.29897 76.7313C1.54158 72.9857 2.13589 69.2713 3.07405 65.637C3.3968 64.2923 5.25257 56.6137 5.29291 56.4523C6.55698 51.0732 6.18045 41.2834 6.18045 38.688C6.18045 28.6964 5.87115 26.5851 6.4763 20.9237C6.79249 17.7776 7.3363 14.6585 8.10346 11.5911C8.93182 8.5823 9.91936 5.61964 11.0619 2.71562C11.6536 1.12881 12.0302 0.200928 12.5412 0.200928C13.7649 0.200928 14.6928 5.40514 14.9214 6.68267C15.6072 10.5825 15.2576 12.25 15.9569 17.2256C16.2124 18.7721 16.441 20.0093 16.6023 20.7892Z" fill="#DEE1E9"/>
        <mask id="mask323" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="51" width="33" height="43">
          <path d="M1.8293 83.0635C2.48823 84.4082 4.0616 85.753 7.20835 88.2543C8.57249 89.4938 10.0646 90.5847 11.6595 91.5086C13.5413 92.5985 15.7013 93.1128 17.8723 92.9878C20.898 92.5844 21.0594 90.5135 27.7966 84.6907C30.2441 82.5794 31.0375 82.1491 31.6427 80.6564C32.6109 78.3165 31.9788 76.0438 30.903 72.6685C29.1952 67.2894 28.348 64.5999 27.2049 61.9104C26.9898 61.3994 26.6401 60.2294 26.1157 58.3468L23.7355 56.4507C22.7524 55.0519 21.4286 53.9271 19.8894 53.1829C18.0929 52.3348 16.0888 52.0265 14.1204 52.2954C14.1204 52.2954 10.4492 51.5019 4.3709 60.0277C3.833 62.2735 3.20096 64.8554 3.02614 65.6085C2.08909 69.2476 1.4948 72.9664 1.25105 76.7162C0.982103 81.1943 1.53345 82.4718 1.8293 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask323)">
          <path d="M1.8293 83.0635C2.48823 84.4082 4.0616 85.753 7.20835 88.2543C8.57249 89.4938 10.0646 90.5847 11.6595 91.5086C13.5413 92.5985 15.7013 93.1128 17.8723 92.9878C20.898 92.5844 21.0594 90.5135 27.7966 84.6907C30.2441 82.5794 31.0375 82.1491 31.6427 80.6564C32.6109 78.3165 31.9788 76.0438 30.903 72.6685C29.1952 67.2894 28.348 64.5999 27.2049 61.9104C26.9898 61.3994 26.6401 60.2294 26.1157 58.3468L23.7355 56.4507C22.7524 55.0519 21.4286 53.9271 19.8894 53.1829C18.0929 52.3348 16.0888 52.0265 14.1204 52.2954C14.1204 52.2954 10.4492 51.5019 4.3709 60.0277C3.833 62.2735 3.20096 64.8554 3.02614 65.6085C2.08909 69.2476 1.4948 72.9664 1.25105 76.7162C0.982103 81.1943 1.53345 82.4718 1.8293 83.0635Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
          <path d="M16.6061 82.2009C17.5864 82.2009 19.1208 88.2688 19.1208 95.2009H17.1978H14.1208C14.1208 88.2688 15.6257 82.2009 16.6061 82.2009Z" fill="#404040"/>
        </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">

        <path d="M15.5171 20.7892C15.7592 21.8381 16.1088 23.3846 16.5526 25.2269C17.3191 28.4947 18.7177 34.3982 21.582 45.2101C24.2715 55.511 25.6163 60.6614 26.1677 61.9389C27.3107 64.6284 28.1713 67.318 29.8792 72.697C30.9416 76.0858 31.5736 78.345 30.6188 80.6983C30.0137 82.1507 29.2741 82.5676 26.7593 84.7326C20.0355 90.542 19.8742 92.6263 16.8485 93.0163C14.6722 93.1528 12.5046 92.6379 10.6222 91.5371C9.02903 90.6169 7.54099 89.5257 6.1845 88.2828C3.0781 85.7412 1.51818 84.4368 0.805456 83.1054C0.509609 82.5137 -0.0282936 81.2362 0.213764 76.7313C0.456379 72.9857 1.05069 69.2713 1.98885 65.637C2.31159 64.2923 4.16736 56.6137 4.2077 56.4523C5.47178 51.0732 5.09525 41.2834 5.09525 38.688C5.09525 28.6964 4.78595 26.5851 5.39109 20.9237C5.70728 17.7776 6.2511 14.6585 7.01826 11.5911C7.84661 8.5823 8.83416 5.61964 9.97673 2.71562C10.5684 1.12881 10.945 0.200928 11.456 0.200928C12.6797 0.200928 13.6076 5.40514 13.8362 6.68267C14.522 10.5825 14.1724 12.25 14.8717 17.2256C15.1272 18.7721 15.3558 20.0093 15.5171 20.7892Z" fill="#DEE1E9"/>
        <path d="M1.33223 84.0576C1.99116 85.4024 3.56453 86.7471 6.71128 89.2484C8.07542 90.4879 9.56754 91.5788 11.1624 92.5027C13.0442 93.5926 15.2042 94.1069 17.3752 93.982C20.4009 93.5785 20.5623 91.5076 27.2996 85.6848C29.747 83.5735 30.5404 83.1432 31.1456 81.6505C32.1138 79.3106 31.4818 77.038 30.406 73.6626C28.6981 68.2836 27.8509 65.5941 26.7079 62.9045C26.4927 62.3935 26.1431 61.2236 25.6186 59.3409L23.2384 57.4448C22.2554 56.046 20.9316 54.9213 19.3924 54.177C17.5958 53.3289 15.5917 53.0206 13.6234 53.2895C13.6234 53.2895 9.95215 52.4961 3.87383 61.0219C3.33593 63.2676 2.70389 65.8496 2.52907 66.6026C1.59202 70.2417 0.997732 73.9605 0.753984 77.7104C0.485033 82.1884 1.03638 83.4659 1.33223 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M19.6238 76.0679L17.7571 78.5687L16.1238 77.073V80.3871L13.5616 79.8192L14.9571 81.5698L12.6238 83.5704L14.9571 85.5711L13.5616 87.3217L15.8904 86.5715L16.1238 90.0679L18.3737 88.5708L19.1571 89.5725L20.8739 88.5708L23.1238 90.0679L23.8238 88.072L25.686 87.3217L25.2238 84.5708L26.6238 83.5704L24.2904 82.0699L25.686 79.8192L23.8238 79.5691L23.1238 77.073L20.0904 79.0689L19.6238 76.0679Z" fill="#404040"/>


    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">

        <path d="M15.5171 20.7892C15.7592 21.8381 16.1088 23.3846 16.5526 25.2269C17.3191 28.4947 18.7177 34.3982 21.582 45.2101C24.2715 55.511 25.6163 60.6614 26.1677 61.9389C27.3107 64.6284 28.1713 67.318 29.8792 72.697C30.9416 76.0858 31.5736 78.345 30.6188 80.6983C30.0137 82.1507 29.2741 82.5676 26.7593 84.7326C20.0355 90.542 19.8742 92.6263 16.8485 93.0163C14.6722 93.1528 12.5046 92.6379 10.6222 91.5371C9.02903 90.6169 7.54099 89.5257 6.1845 88.2828C3.0781 85.7412 1.51818 84.4368 0.805456 83.1054C0.509609 82.5137 -0.0282936 81.2362 0.213764 76.7313C0.456379 72.9857 1.05069 69.2713 1.98885 65.637C2.31159 64.2923 4.16736 56.6137 4.2077 56.4523C5.47178 51.0732 5.09525 41.2834 5.09525 38.688C5.09525 28.6964 4.78595 26.5851 5.39109 20.9237C5.70728 17.7776 6.2511 14.6585 7.01826 11.5911C7.84661 8.5823 8.83416 5.61964 9.97673 2.71562C10.5684 1.12881 10.945 0.200928 11.456 0.200928C12.6797 0.200928 13.6076 5.40514 13.8362 6.68267C14.522 10.5825 14.1724 12.25 14.8717 17.2256C15.1272 18.7721 15.3558 20.0093 15.5171 20.7892Z" fill="#DEE1E9"/>
        <path d="M1.33223 84.0576C1.99116 85.4024 3.56453 86.7471 6.71128 89.2484C8.07542 90.4879 9.56754 91.5788 11.1624 92.5027C13.0442 93.5926 15.2042 94.1069 17.3752 93.982C20.4009 93.5785 20.5623 91.5076 27.2996 85.6848C29.747 83.5735 30.5404 83.1432 31.1456 81.6505C32.1138 79.3106 31.4818 77.038 30.406 73.6626C28.6981 68.2836 27.8509 65.5941 26.7079 62.9045C26.4927 62.3935 26.1431 61.2236 25.6186 59.3409L23.2384 57.4448C22.2554 56.046 20.9316 54.9213 19.3924 54.177C17.5958 53.3289 15.5917 53.0206 13.6234 53.2895C13.6234 53.2895 9.95215 52.4961 3.87383 61.0219C3.33593 63.2676 2.70389 65.8496 2.52907 66.6026C1.59202 70.2417 0.997732 73.9605 0.753984 77.7104C0.485033 82.1884 1.03638 83.4659 1.33223 84.0576Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.6238 77.0679L19.0238 79.2115L17.6238 77.9294V80.77L15.4276 80.2833L16.6238 81.7838L14.6238 83.4987L16.6238 85.2135L15.4276 86.714L17.4238 86.071L17.6238 89.0679L19.5523 87.7847L20.2238 88.6433L21.6953 87.7847L23.6238 89.0679L24.2238 87.3571L25.8199 86.714L25.4238 84.3561L26.6238 83.4987L24.6238 82.2125L25.8199 80.2833L24.2238 80.0689L23.6238 77.9294L21.0238 79.6402L20.6238 77.0679Z" fill="#404040"/>


    </g>

    <g class="caries-light" v-if="status && status.id == 1872">

        <path d="M16.251 20.8546C16.4931 21.9036 16.8427 23.45 17.2865 25.2924C18.053 28.5601 19.4516 34.4636 22.3159 45.2755C25.0054 55.5764 26.3502 60.7268 26.9015 62.0043C28.0446 64.6939 28.9052 67.3834 30.6131 72.7624C31.6754 76.1512 32.3075 78.4104 31.3527 80.7638C30.7476 82.2161 30.0079 82.633 27.4932 84.798C20.7694 90.6074 20.6081 92.6918 17.5823 93.0818C15.4061 93.2183 13.2384 92.7033 11.3561 91.6025C9.76291 90.6824 8.27487 89.5911 6.91839 88.3482C3.81199 85.8066 2.25207 84.5022 1.53934 83.1709C1.2435 82.5792 0.705593 81.3017 0.947651 76.7967C1.19027 73.0512 1.78458 69.3367 2.72273 65.7024C3.04548 64.3577 4.90125 56.6791 4.94159 56.5177C6.20567 51.1387 5.82913 41.3488 5.82913 38.7534C5.82913 28.7618 5.51984 26.6506 6.12498 20.9891C6.44117 17.843 6.98498 14.724 7.75214 11.6565C8.5805 8.64773 9.56804 5.68507 10.7106 2.78105C11.3023 1.19424 11.6788 0.266357 12.1899 0.266357C13.4136 0.266357 14.3415 5.47057 14.5701 6.7481C15.2559 10.6479 14.9063 12.3154 15.6055 17.291C15.861 18.8375 16.0897 20.0747 16.251 20.8546Z" fill="#DEE1E9"/>
        <path d="M2.06612 84.1231C2.72505 85.4678 4.29842 86.8126 7.44516 89.3138C8.80931 90.5533 10.3014 91.6443 11.8963 92.5682C13.7781 93.6581 15.9381 94.1723 18.1091 94.0474C21.1348 93.644 21.2962 91.573 28.0335 85.7502C30.4809 83.6389 31.2743 83.2086 31.8795 81.7159C32.8477 79.3761 32.2157 77.1034 31.1399 73.7281C29.432 68.349 28.5848 65.6595 27.4418 62.97C27.2266 62.459 26.877 61.289 26.3525 59.4064L23.9723 57.5102C22.9892 56.1114 21.6655 54.9867 20.1263 54.2425C18.3297 53.3943 16.3256 53.086 14.3572 53.3549C14.3572 53.3549 10.686 52.5615 4.60772 61.0873C4.06981 63.3331 3.43777 65.915 3.26296 66.6681C2.3259 70.3071 1.73162 74.0259 1.48787 77.7758C1.21892 82.2539 1.77027 83.5314 2.06612 84.1231Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M20.8577 84.1333L19.9243 85.3837L19.1077 84.6359V86.2929L17.8266 86.0089L18.5243 86.8842L17.3577 87.8846L18.5243 88.8849L17.8266 89.7602L18.991 89.3851L19.1077 91.1333L20.2326 90.3848L20.6243 90.8856L21.4827 90.3848L22.6077 91.1333L22.9577 90.1354L23.8888 89.7602L23.6577 88.3848L24.3577 87.8846L23.191 87.1343L23.8888 86.0089L22.9577 85.8839L22.6077 84.6359L21.091 85.6338L20.8577 84.1333Z" fill="#404040"/>


    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">

        <path d="M18.7952 2.68065L16.7114 1.41089H13.2277L11.1277 2.68065L12.7394 4.22716L10.6556 5.77367L12.544 7.32018L10.2487 9.0132L12.0719 10.5597L9.77658 11.9597L11.7952 13.376L9.77658 14.9876L11.4696 16.5341L9.23937 17.9504L10.9975 19.4155L8.70216 20.962L10.851 22.5085L8.70216 24.1201L10.2487 25.4713L8.16496 27.0178L9.98821 28.4992L7.90449 30.0457L9.71146 31.7876L8.70216 33.4643V47.7247L10.5905 55.0503H16.7114" fill="#8889BE"/>
        <path d="M18.7952 2.68065L16.7114 1.41089H13.2277L11.1277 2.68065L12.7394 4.22716L10.6556 5.77367L12.544 7.32018L10.2487 9.0132L12.0719 10.5597L9.77658 11.9597L11.7952 13.376L9.77658 14.9876L11.4696 16.5341L9.23937 17.9504L10.9975 19.4155L8.70216 20.962L10.851 22.5085L8.70216 24.1201L10.2487 25.4713L8.16496 27.0178L9.98821 28.4992L7.90449 30.0457L9.71146 31.7876L8.70216 33.4643V47.7247L10.5905 55.0503H16.7114" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M11.3879 2.68065L13.4716 1.41089H16.939L19.0553 2.68065L17.4274 4.22716L19.5274 5.77367L17.639 7.32018L19.9181 9.0132L18.1111 10.5597L20.3902 11.9597L18.3716 13.376L20.3902 14.9876L18.7135 16.5341L20.9274 17.9504L19.1855 19.4155L21.4646 20.962L19.3158 22.5085L21.4646 24.1201L19.9181 25.4713L22.0018 27.0178L20.1948 28.4992L22.2786 30.0457L20.4553 31.7876L21.4646 33.4643V47.7247L19.5925 55.0503H15.6856" fill="#8889BE"/>
        <path d="M11.3879 2.68065L13.4716 1.41089H16.939L19.0553 2.68065L17.4274 4.22716L19.5274 5.77367L17.639 7.32018L19.9181 9.0132L18.1111 10.5597L20.3902 11.9597L18.3716 13.376L20.3902 14.9876L18.7135 16.5341L20.9274 17.9504L19.1855 19.4155L21.4646 20.962L19.3158 22.5085L21.4646 24.1201L19.9181 25.4713L22.0018 27.0178L20.1948 28.4992L22.2786 30.0457L20.4553 31.7876L21.4646 33.4643V47.7247L19.5925 55.0503H15.6856" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M6.67908 71.4585C3.53234 68.9572 1.95897 67.6125 1.30003 66.2677C1.00419 65.676 0.452837 64.3985 0.721788 59.9205C0.965534 56.1706 1.55982 52.4518 2.49687 48.8127C2.67169 48.0597 3.77825 43.7968 4.31615 41.551H8.11598C8.66827 41.551 9.11598 41.9987 9.11598 42.551V52.5219C13.6001 53.6386 16.12 53.6268 20.6041 52.5219V42.551C20.6041 41.9987 21.0518 41.551 21.6041 41.551H25.5864C26.1109 43.4337 26.4605 44.6036 26.6757 45.1146C27.8187 47.8042 28.6659 50.4937 30.3738 55.8727C31.4496 59.2481 32.0816 61.5207 31.1134 63.8606C30.5082 65.3533 29.7148 65.7836 27.2674 67.8949C20.5301 73.7177 20.3687 75.7886 17.343 76.1921C15.172 76.317 13.012 75.8027 11.1302 74.7128C9.53534 73.789 8.04323 72.698 6.67908 71.4585Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask523" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="41" width="33" height="36">
          <path d="M6.67911 71.4585C3.53237 68.9572 1.959 67.6124 1.30006 66.2677C1.00422 65.676 0.452868 64.3985 0.721819 59.9204C0.965565 56.1706 1.55985 52.4518 2.4969 48.8127C2.67172 48.0596 3.77828 44.7284 4.31618 42.4827H8.11601C8.6683 42.4827 9.11601 42.9304 9.11601 43.4827V52.5218C13.6001 53.6386 16.1201 53.6268 20.6041 52.5218V43.4827C20.6041 42.9304 21.0518 42.4827 21.6041 42.4827L25.5865 42.4827C26.1109 44.3653 26.4605 44.6036 26.6757 45.1146C27.8188 47.8041 28.666 50.4936 30.3738 55.8727C31.4496 59.248 32.0816 61.5207 31.1134 63.8606C30.5083 65.3533 29.7149 65.7836 27.2674 67.8948C20.5301 73.7177 20.3688 75.7886 17.3431 76.192C15.1721 76.317 13.012 75.8027 11.1303 74.7128C9.53537 73.7889 8.04326 72.698 6.67911 71.4585Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        </mask>
        <g mask="url(#mask523)">
          <path d="M23.4131 31.3479H6.5186V56.8959C13.1163 58.8221 16.8154 58.8417 23.4131 56.8959V31.3479Z" fill="#A2A5AE"/>
        </g>


    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">

        <path d="M19.186 2.03344L17.1023 0.763672H13.6186L11.5186 2.03344L13.1302 3.57995L11.0465 5.12645L12.9349 6.67296L10.6395 8.36598L12.4628 9.91249L10.1674 11.3125L12.186 12.7288L10.1674 14.3404L11.8604 15.8869L9.63021 17.3032L11.3883 18.7683L9.093 20.3148L11.2418 21.8613L9.093 23.4729L10.6395 24.8241L8.55579 26.3706L10.379 27.852L8.29533 29.3985L10.1023 31.1404L9.093 32.8171V47.0775L10.9814 54.4031H17.1023" fill="#8889BE"/>
        <path d="M19.186 2.03344L17.1023 0.763672H13.6186L11.5186 2.03344L13.1302 3.57995L11.0465 5.12645L12.9349 6.67296L10.6395 8.36598L12.4628 9.91249L10.1674 11.3125L12.186 12.7288L10.1674 14.3404L11.8604 15.8869L9.63021 17.3032L11.3883 18.7683L9.093 20.3148L11.2418 21.8613L9.093 23.4729L10.6395 24.8241L8.55579 26.3706L10.379 27.852L8.29533 29.3985L10.1023 31.1404L9.093 32.8171V47.0775L10.9814 54.4031H17.1023" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M11.7787 2.03344L13.8624 0.763672H17.3299L19.4462 2.03344L17.8183 3.57995L19.9182 5.12645L18.0299 6.67296L20.3089 8.36598L18.502 9.91249L20.781 11.3125L18.7624 12.7288L20.781 14.3404L19.1043 15.8869L21.3182 17.3032L19.5764 18.7683L21.8555 20.3148L19.7066 21.8613L21.8555 23.4729L20.3089 24.8241L22.3927 26.3706L20.5857 27.852L22.6694 29.3985L20.8462 31.1404L21.8555 32.8171V47.0775L19.9834 54.4031H16.0764" fill="#8889BE"/>
        <path d="M11.7787 2.03344L13.8624 0.763672H17.3299L19.4462 2.03344L17.8183 3.57995L19.9182 5.12645L18.0299 6.67296L20.3089 8.36598L18.502 9.91249L20.781 11.3125L18.7624 12.7288L20.781 14.3404L19.1043 15.8869L21.3182 17.3032L19.5764 18.7683L21.8555 20.3148L19.7066 21.8613L21.8555 23.4729L20.3089 24.8241L22.3927 26.3706L20.5857 27.852L22.6694 29.3985L20.8462 31.1404L21.8555 32.8171V47.0775L19.9834 54.4031H16.0764" stroke="#8889BE" stroke-miterlimit="10"/>
        <path d="M7.39026 69.8982C4.24352 67.3969 2.67015 66.0522 2.01122 64.7074C1.71537 64.1157 1.16402 62.8382 1.43297 58.3602C1.67672 54.6103 2.27101 50.8915 3.20806 47.2524C3.38287 46.4994 4.48943 42.2365 5.02733 39.9907H8.82716C9.37945 39.9907 9.82716 40.4384 9.82716 40.9907V50.9616C14.3112 52.0783 16.8312 52.0665 21.3153 50.9616V40.9907C21.3153 40.4384 21.763 39.9907 22.3153 39.9907H26.2976C26.8221 41.8734 27.1717 43.0433 27.3869 43.5543C28.5299 46.2439 29.3771 48.9334 31.085 54.3124C32.1608 57.6878 32.7928 59.9604 31.8246 62.3003C31.2194 63.793 30.426 64.2233 27.9786 66.3346C21.2413 72.1574 21.0799 74.2283 18.0542 74.6318C15.8832 74.7567 13.7232 74.2424 11.8414 73.1525C10.2465 72.2286 8.75441 71.1377 7.39026 69.8982Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>


    </g>

    <g class="bridges" v-if="status && status.id == 1875">

        <path d="M43.8093 90.771H22.8093V95.771H43.8093V90.771Z" fill="#838AA2"/>
        <path d="M21.8093 90.771H0.809326V95.771H21.8093V90.771Z" fill="#838AA2"/>
        <path d="M22.232 21.4926C22.474 22.5415 22.8237 24.088 23.2674 25.9303C24.0339 29.1981 25.4325 35.1016 28.2968 45.9135C30.9864 56.2143 32.3311 61.3648 32.8825 62.6423C34.0255 65.3318 34.8862 68.0213 36.594 73.4004C37.6564 76.7892 38.2884 79.0484 37.3336 81.4017C36.7285 82.854 35.9889 83.2709 33.4742 85.436C26.7504 91.2454 26.589 93.3297 23.5633 93.7197C21.387 93.8562 19.2194 93.3412 17.337 92.2405C15.7438 91.3203 14.2558 90.2291 12.8993 88.9862C9.79292 86.4445 8.23299 85.1401 7.52027 83.8088C7.22442 83.2171 6.68652 81.9396 6.92858 77.4347C7.17119 73.6891 7.7655 69.9747 8.70366 66.3404C9.0264 64.9956 10.8822 57.317 10.9225 57.1557C12.1866 51.7766 11.8101 41.9867 11.8101 39.3914C11.8101 29.3998 11.5008 27.2885 12.1059 21.6271C12.4221 18.481 12.9659 15.3619 13.7331 12.2944C14.5614 9.28567 15.549 6.32301 16.6915 3.41899C17.2832 1.83218 17.6598 0.904297 18.1708 0.904297C19.3945 0.904297 20.3224 6.10851 20.551 7.38603C21.2368 11.2858 20.8872 12.9534 21.5865 17.929C21.842 19.4755 22.0706 20.7126 22.232 21.4926Z" fill="#DEE1E9"/>
        <path d="M12.8968 89.9519C9.75005 87.4506 8.17668 86.1059 7.51775 84.7611C7.2219 84.1694 6.67055 82.8919 6.9395 78.4139C7.18325 74.664 7.77754 70.9452 8.71459 67.3061C8.8894 66.5531 9.99596 62.2902 10.5339 60.0444H14.3337C14.886 60.0444 15.3337 60.4921 15.3337 61.0444V71.0153C19.8178 72.132 22.3377 72.1202 26.8218 71.0153V61.0444C26.8218 60.4921 27.2695 60.0444 27.8218 60.0444H31.8041C32.3286 61.9271 32.6782 63.097 32.8934 63.608C34.0364 66.2976 34.8836 68.9871 36.5915 74.3661C37.6673 77.7415 38.2993 80.0141 37.3311 82.354C36.726 83.8467 35.9326 84.277 33.4851 86.3883C26.7478 92.2111 26.5865 94.2821 23.5607 94.6855C21.3897 94.8104 19.2297 94.2962 17.348 93.2063C15.7531 92.2824 14.2609 91.1914 12.8968 89.9519Z" fill="#838AA2"/>


    </g>

    <g class="channel" v-if="status && status.id == 1876">

        <path d="M16.4224 20.5883C16.6645 21.6372 17.0141 23.1837 17.4579 25.026C18.2244 28.2938 19.6229 34.1973 22.4873 45.0092C25.1768 55.31 26.5216 60.4605 27.0729 61.738C28.216 64.4275 29.0766 67.117 30.7845 72.4961C31.8468 75.8849 32.4789 78.1441 31.5241 80.4974C30.9189 81.9497 30.1793 82.3666 27.6646 84.5317C20.9408 90.3411 20.7794 92.4254 17.7537 92.8154C15.5774 92.9519 13.4098 92.4369 11.5275 91.3362C9.9343 90.416 8.44626 89.3248 7.08978 88.0819C3.98338 85.5403 2.42345 84.2358 1.71073 82.9045C1.41488 82.3128 0.87698 81.0353 1.11904 76.5304C1.36165 72.7848 1.95596 69.0704 2.89412 65.4361C3.21686 64.0913 5.07263 56.4127 5.11298 56.2514C6.37705 50.8723 6.00052 41.0824 6.00052 38.4871C6.00052 28.4955 5.69122 26.3842 6.29637 20.7228C6.61256 17.5767 7.15637 14.4576 7.92353 11.3901C8.75188 8.38137 9.73943 5.41872 10.882 2.5147C11.4737 0.927879 11.8502 0 12.3612 0C13.585 0 14.5129 5.20422 14.7415 6.48174C15.4273 10.3815 15.0777 12.0491 15.7769 17.0247C16.0324 18.5712 16.261 19.8083 16.4224 20.5883Z" fill="#DEE1E9"/>
        <path d="M1.70479 82.8943C2.36372 84.2391 3.93709 85.5838 7.08383 88.0851C8.44798 89.3246 9.94009 90.4155 11.535 91.3394C13.4168 92.4293 15.5768 92.9436 17.7478 92.8186C20.7735 92.4152 20.9349 90.3443 27.6721 84.5215C30.1196 82.4102 30.913 81.9799 31.5181 80.4872C32.4864 78.1473 31.8543 75.8746 30.7785 72.4993C29.0707 67.1202 28.2235 64.4307 27.0804 61.7412C26.8653 61.2302 26.5156 60.0603 25.9912 58.1776L23.6109 56.2815C22.6279 54.8827 21.3041 53.7579 19.7649 53.0137C17.9684 52.1656 15.9643 51.8573 13.9959 52.1262C13.9959 52.1262 10.3247 51.3328 4.24639 59.8585C3.70848 62.1043 3.07645 64.6862 2.90163 65.4393C1.96458 69.0784 1.37029 72.7972 1.12654 76.547C0.857592 81.0251 1.40894 82.3026 1.70479 82.8943Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.2" d="M15.1407 69.1475C16.8754 69.1475 18.7446 62.9212 19.0808 57.1522L17.1444 41.0151C16.3509 35.8915 15.6382 31.7362 15.1407 28.9122C13.9976 22.417 13.7959 21.637 13.4463 18.8534C12.6932 11.6858 13.0025 2.77073 12.4108 2.69005C11.8191 2.60936 11.1602 10.7176 10.6357 14.1198C9.97678 18.4365 10.4205 20.8436 10.7836 26.8413C11.2491 31.5151 11.348 36.2182 11.0795 40.9075C10.7298 44.7131 10.165 46.5824 9.89609 52.0152C9.69493 54.5383 9.84428 57.0772 10.3399 59.5593C10.7935 61.5635 11.4889 63.5052 12.4108 65.3418C13.6749 67.8699 14.3204 69.1475 15.1407 69.1475Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>


    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">

        <path d="M16.4224 20.5883C16.6645 21.6372 17.0141 23.1837 17.4579 25.026C18.2244 28.2938 19.6229 34.1973 22.4873 45.0092C25.1768 55.31 26.5216 60.4605 27.0729 61.738C28.216 64.4275 29.0766 67.117 30.7845 72.4961C31.8468 75.8849 32.4789 78.1441 31.5241 80.4974C30.9189 81.9497 30.1793 82.3666 27.6646 84.5317C20.9408 90.3411 20.7794 92.4254 17.7537 92.8154C15.5774 92.9519 13.4098 92.4369 11.5275 91.3362C9.9343 90.416 8.44626 89.3248 7.08978 88.0819C3.98338 85.5403 2.42345 84.2358 1.71073 82.9045C1.41488 82.3128 0.87698 81.0353 1.11904 76.5304C1.36165 72.7848 1.95596 69.0704 2.89412 65.4361C3.21686 64.0913 5.07263 56.4127 5.11298 56.2514C6.37705 50.8723 6.00052 41.0824 6.00052 38.4871C6.00052 28.4955 5.69122 26.3842 6.29637 20.7228C6.61256 17.5767 7.15637 14.4576 7.92353 11.3901C8.75188 8.38137 9.73943 5.41872 10.882 2.5147C11.4737 0.927879 11.8502 0 12.3612 0C13.585 0 14.5129 5.20422 14.7415 6.48174C15.4273 10.3815 15.0777 12.0491 15.7769 17.0247C16.0324 18.5712 16.261 19.8083 16.4224 20.5883Z" fill="#DEE1E9"/>
        <path d="M1.70479 82.8943C2.36372 84.2391 3.93709 85.5838 7.08383 88.0851C8.44798 89.3246 9.94009 90.4155 11.535 91.3394C13.4168 92.4293 15.5768 92.9436 17.7478 92.8186C20.7735 92.4152 20.9349 90.3443 27.6721 84.5215C30.1196 82.4102 30.913 81.9799 31.5181 80.4872C32.4864 78.1473 31.8543 75.8746 30.7785 72.4993C29.0707 67.1202 28.2235 64.4307 27.0804 61.7412C26.8653 61.2302 26.5156 60.0603 25.9912 58.1776L23.6109 56.2815C22.6279 54.8827 21.3041 53.7579 19.7649 53.0137C17.9684 52.1656 15.9643 51.8573 13.9959 52.1262C13.9959 52.1262 10.3247 51.3328 4.24639 59.8585C3.70848 62.1043 3.07645 64.6862 2.90163 65.4393C1.96458 69.0784 1.37029 72.7972 1.12654 76.547C0.857592 81.0251 1.40894 82.3026 1.70479 82.8943Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path opacity="0.4" d="M15.1407 69.1475C16.8754 69.1475 18.7446 62.9212 19.0808 57.1522L17.1444 41.0151C16.3509 35.8915 15.6382 31.7362 15.1407 28.9122C13.9976 22.417 13.7959 21.637 13.4463 18.8534C12.6932 11.6858 13.0025 2.77073 12.4108 2.69005C11.8191 2.60936 11.1602 10.7176 10.6357 14.1198C9.97678 18.4365 10.4205 20.8436 10.7836 26.8413C11.2491 31.5151 11.348 36.2182 11.0795 40.9075C10.7298 44.7131 10.165 46.5824 9.89609 52.0152C9.69493 54.5383 9.84428 57.0772 10.3399 59.5593C10.7935 61.5635 11.4889 63.5052 12.4108 65.3418C13.6749 67.8699 14.3204 69.1475 15.1407 69.1475Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>


    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">

        <path d="M16.241 20.5883C16.4831 21.6372 16.8327 23.1837 17.2765 25.026C18.043 28.2938 19.4416 34.1973 22.3059 45.0092C24.9954 55.31 26.3402 60.4605 26.8915 61.738C28.0346 64.4275 28.8952 67.117 30.6031 72.4961C31.6654 75.8849 32.2975 78.1441 31.3427 80.4974C30.7375 81.9497 29.9979 82.3666 27.4832 84.5317C20.7594 90.3411 20.598 92.4254 17.5723 92.8154C15.396 92.9519 13.2284 92.4369 11.3461 91.3362C9.7529 90.416 8.26486 89.3248 6.90838 88.0819C3.80198 85.5403 2.24206 84.2358 1.52933 82.9045C1.23349 82.3128 0.695583 81.0353 0.937641 76.5304C1.18026 72.7848 1.77457 69.0704 2.71272 65.4361C3.03547 64.0913 4.89124 56.4127 4.93158 56.2514C6.19566 50.8723 5.81912 41.0824 5.81912 38.4871C5.81912 28.4955 5.50983 26.3842 6.11497 20.7228C6.43116 17.5767 6.97497 14.4576 7.74213 11.3901C8.57049 8.38137 9.55803 5.41872 10.7006 2.5147C11.2923 0.927879 11.6688 0 12.1798 0C13.4036 0 14.3315 5.20422 14.5601 6.48174C15.2459 10.3815 14.8963 12.0491 15.5955 17.0247C15.851 18.5712 16.0796 19.8083 16.241 20.5883Z" fill="#DEE1E9"/>
        <path d="M1.52339 82.8943C2.18233 84.2391 3.7557 85.5838 6.90244 88.0851C8.26658 89.3246 9.7587 90.4155 11.3536 91.3394C13.2354 92.4293 15.3954 92.9436 17.5664 92.8186C20.5921 92.4152 20.7535 90.3443 27.4907 84.5215C29.9382 82.4102 30.7316 81.9799 31.3367 80.4872C32.305 78.1473 31.6729 75.8746 30.5971 72.4993C28.8893 67.1202 28.0421 64.4307 26.899 61.7412C26.6839 61.2302 26.3342 60.0603 25.8098 58.1776L23.4296 56.2815C22.4465 54.8827 21.1227 53.7579 19.5835 53.0137C17.787 52.1656 15.7829 51.8573 13.8145 52.1262C13.8145 52.1262 10.1433 51.3328 4.06499 59.8585C3.52709 62.1043 2.89505 64.6862 2.72023 65.4393C1.78318 69.0784 1.18889 72.7972 0.945147 76.547C0.676195 81.0251 1.22754 82.3026 1.52339 82.8943Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <mask id="mask523" mask-type="alpha" maskUnits="userSpaceOnUse" x="7" y="28" width="17" height="45">
          <path d="M9.28105 28.3593H16.0437L23.4075 68.1474L7.64313 72.962L9.28105 28.3593Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask523)">
          <path opacity="0.2" d="M14.9593 69.1475C16.694 69.1475 18.5632 62.9212 18.8994 57.1522L16.963 41.0151C16.1695 35.8915 15.4568 31.7362 14.9593 28.9122C13.8162 22.417 13.6145 21.637 13.2649 18.8534C12.5118 11.6858 12.8211 2.77073 12.2294 2.69005C11.6377 2.60936 10.9788 10.7176 10.4543 14.1198C9.79538 18.4365 10.2392 20.8436 10.6022 26.8413C11.0677 31.5151 11.1666 36.2182 10.8981 40.9075C10.5484 44.7131 9.98365 46.5824 9.71469 52.0152C9.51354 54.5383 9.66288 57.0772 10.1585 59.5593C10.6121 61.5635 11.3075 63.5052 12.2294 65.3418C13.4935 67.8699 14.139 69.1475 14.9593 69.1475Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
        </g>
        <mask id="mask5023" mask-type="alpha" maskUnits="userSpaceOnUse" x="8" y="0" width="10" height="29">
          <path d="M10.0201 2.03498L10.72 0H12.1198L13.1079 1.29995L17.6367 28.2313H8.86731L9.23785 16.2805L9.69077 5.70945L10.0201 2.03498Z" fill="#C4C4C4"/>
        </mask>
        <g mask="url(#mask5023)">
          <path opacity="0.4" d="M14.9593 69.1475C16.694 69.1475 18.5632 62.9212 18.8994 57.1522L16.963 41.0151C16.1695 35.8915 15.4568 31.7362 14.9593 28.9122C13.8162 22.417 13.6145 21.637 13.2649 18.8534C12.5118 11.6858 12.8211 2.77073 12.2294 2.69005C11.6377 2.60936 10.9788 10.7176 10.4543 14.1198C9.79538 18.4365 10.2392 20.8436 10.6022 26.8413C11.0677 31.5151 11.1666 36.2182 10.8981 40.9075C10.5484 44.7131 9.98365 46.5824 9.71469 52.0152C9.51354 54.5383 9.66288 57.0772 10.1585 59.5593C10.6121 61.5635 11.3075 63.5052 12.2294 65.3418C13.4935 67.8699 14.139 69.1475 14.9593 69.1475Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
        </g>


    </g>

<g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M15.7796 21.1241C16.0217 22.173 16.3713 23.7194 16.8151 25.5618C17.5816 28.8295 18.9801 34.733 21.8445 45.5449C24.534 55.8458 25.8788 60.9962 26.4301 62.2738C27.5732 64.9633 28.4338 67.6528 30.1416 73.0318C31.204 76.4206 31.836 78.6798 30.8813 81.0332C30.2761 82.4855 29.5365 82.9024 27.0218 85.0675C20.298 90.8768 20.1366 92.9612 17.1109 93.3512C14.9346 93.4877 12.767 92.9727 10.8847 91.8719C9.29148 90.9518 7.80344 89.8606 6.44695 88.6176C3.34056 86.076 1.78063 84.7716 1.06791 83.4403C0.77206 82.8486 0.234158 81.5711 0.476215 77.0661C0.71883 73.3206 1.31314 69.6061 2.2513 65.9718C2.57404 64.6271 4.42981 56.9485 4.47015 56.7871C5.73423 51.4081 5.3577 41.6182 5.3577 39.0228C5.3577 29.0312 5.0484 26.92 5.65354 21.2585C5.96974 18.1124 6.51355 14.9934 7.28071 11.9259C8.10906 8.91714 9.09661 5.95448 10.2392 3.05046C10.8309 1.46365 11.2074 0.535767 11.7184 0.535767C12.9421 0.535767 13.87 5.73998 14.0986 7.0175C14.7845 10.9173 14.4348 12.5848 15.1341 17.5604C15.3896 19.1069 15.6182 20.3441 15.7796 21.1241Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
        <path d="M16.7796 21.4612C17.0217 22.5101 17.3713 24.0565 17.8151 25.8989C18.5816 29.1666 19.9801 35.0701 22.8445 45.882C25.534 56.1829 26.8788 61.3333 27.4301 62.6109C28.5732 65.3004 29.4338 67.9899 31.1416 73.3689C32.204 76.7577 32.836 79.0169 31.8813 81.3703C31.2761 82.8226 30.5365 83.2395 28.0218 85.4045C21.298 91.2139 21.1366 93.2983 18.1109 93.6883C15.9346 93.8248 13.767 93.3098 11.8847 92.209C10.2915 91.2889 8.80344 90.1977 7.44695 88.9547C4.34056 86.4131 2.78063 85.1087 2.06791 83.7774C1.77206 83.1857 1.23416 81.9082 1.47622 77.4032C1.71883 73.6577 2.31314 69.9432 3.2513 66.3089C3.57404 64.9642 5.42981 57.2856 5.47015 57.1242C6.73423 51.7452 6.3577 41.9553 6.3577 39.3599C6.3577 29.3683 6.0484 27.2571 6.65354 21.5956C6.96974 18.4495 7.51355 15.3305 8.28071 12.263C9.10906 9.25424 10.0966 6.29158 11.2392 3.38756C11.8309 1.80074 12.2074 0.872864 12.7184 0.872864C13.9421 0.872864 14.87 6.07708 15.0986 7.3546C15.7845 11.2544 15.4348 12.9219 16.1341 17.8975C16.3896 19.444 16.6182 20.6812 16.7796 21.4612Z" fill="#DEE1E9"/>
        <path d="M2.06197 83.767C2.7209 85.1118 4.29427 86.4566 7.44101 88.9578C8.80516 90.1973 10.2973 91.2883 11.8922 92.2122C13.7739 93.302 15.934 93.8163 18.105 93.6914C21.1307 93.288 21.2921 91.217 28.0293 85.3942C30.4768 83.2829 31.2702 82.8526 31.8753 81.3599C32.8436 79.02 32.2115 76.7474 31.1357 73.372C29.4279 67.993 28.5807 65.3035 27.4376 62.6139C27.2224 62.1029 26.8728 60.933 26.3484 59.0503L23.9681 57.1542C22.9851 55.7554 21.6613 54.6307 20.1221 53.8864C18.3256 53.0383 16.3215 52.73 14.3531 52.9989C14.3531 52.9989 10.6819 52.2055 4.60357 60.7313C4.06566 62.977 3.43362 65.559 3.25881 66.312C2.32175 69.9511 1.72747 73.6699 1.48372 77.4198C1.21477 81.8978 1.76612 83.1753 2.06197 83.767Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth23",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>

