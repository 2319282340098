<template>
  <div>
    <div v-if="!comments || !comments.length && loaded">
      {{ $t('no_data_available') }}
    </div>
    <PatientCommentTeaser v-for="c in comments"
                          :key="`comment-${c.id}`"
                          :comment="c"
                          :show-images="showImages"
                          @getComments="getComments(true)"
                          class="mb-3">
    </PatientCommentTeaser>
    <div v-if="hasMore"
         class="mb-3">
      <button :disabled="loading"
              class="btn btn-themed btn-themed-outline btn-themed-squared"
              @click="loadMore">
        {{ $t('load_more') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import PatientCommentTeaser from "@/components/parts/patients/PatientCommentTeaser.vue"
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue"
import { useStore } from "vuex"
import { EloquentService } from "@/services/api.service"

const props = defineProps({
  showImages: Boolean,
  patient: Object,
  checked: {
    type: Array,
    default: () => []
  }
})

const store = useStore()

const loading = computed(() => store.state.dom.loading)
const counter = computed(() => store.state.datatable.dataTableCounter)
const dataTableWatcher = ref(true)
const loaded = ref(false)
const page = ref(1)
const paginator = ref(null)
const comments = ref([])

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})

const getComments = async (flush = false) => {
  if(!props.patient) return
  if(flush) page.value = 1
  const res = await EloquentService.dataTable('patient_comment', page.value, {
    pagination: 10,
    order: 'created_at',
    sort: 'desc',
    condition: {
      user_id: props.patient.id,
      comment_type: props.checked
    },
  })
  if(flush) comments.value = []
  comments.value = comments.value.concat(res.data.result.data)
  paginator.value = res.data.result
  loaded.value = true
  // dataTableWatcher.value = false
  // await nextTick()
  // store.commit('incrementDataTableCounter')
  // setTimeout(() => {
  //   dataTableWatcher.value = true
  // }, 100)
}

const handleScroll = () => {
  if(!loaded.value || loading.value || !hasMore.value) return
  const bottomOfWindow = (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight) > (document.documentElement.offsetHeight - 40)
  if (bottomOfWindow) {
    loadMore()
  }
}

const hasMore = computed(() => {
  if(!paginator.value) return false
  return page.value < paginator.value.last_page
})

const loadMore = () => {
  page.value++
  getComments()
}

watch(counter, () => {
  if(dataTableWatcher.value) getComments(true)
})

watch(() => props.checked, () => {
  getComments(true)
}, {
  deep: true,
})

defineExpose({
  getComments
})
</script>
