export default {
    accounting: "Бухгалтерия",
    action_log: "Журнал действий",
    calendar: "Календарь",
    control: "Управление",
    doctors: "Доктора",
    insurance: "Страховые",
    patients: "Пациенты",
    reports: "Отчеты",
    services: "Услуги",
    settings: "Настройки",
    users: "Пользователи",
    warehouse: "Склад",
}
