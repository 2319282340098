<template>
  <div class="form-group position-relative">
    <label class="form-label" v-if="label">{{ label }}</label>
    <div>
      <vue-timepicker v-model="content"
                      :hour-label="$t('hours')"
                      :minute-label="$t('minutes')"
                      :minute-interval="5"
                      format="HH:mm"
                      locale="ru"
                      :disabled="readonly"
                      placeholder="__:__"
                      close-on-complete>
      </vue-timepicker>
    </div>
    <ValidationError class="mt-1"
                     v-if="showErrors"
                     :validationId="validationId"/>
  </div>
</template>

<script>

import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import ValidationError from "@/components/form/ValidationError"

export default {
  components: {
    ValidationError,
    VueTimepicker
  },
  data() {
    return {
      show: false,
      content: this.value,
      timer: null,
    }
  },
  props: {
    label: {},
    value: {},
    showErrors: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validationId: {},
  },
  mounted() {
  },
  watch: {
    content(val) {
      if(typeof val === 'object' && val !== null) {
        let str = val.HH ? val.HH : '00'
        str += ':'
        str += val.mm ? val.mm : '00'
        this.$emit('input', str)
      } else {
        this.$emit('input', val)
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.w-100 {
  ::v-deep .vue__time-picker {
    width: 100% !important;
    input.display-time {
      width: 100% !important;
    }
  }
}
</style>
