<template>
  <div :class="{
    'text-danger': object.status === 'error',
    'text-muted': object.status === 'waiting',
    'text-success': object.status === 'sent'
  }">
    <WarningSvg v-if="object.status === 'error'" class="mr-2"/>
    <ClockSvg v-if="object.status === 'waiting'" class="mr-2"/>
    {{ $t(object.status) }}
  </div>
</template>

<script>

import WarningSvg from '@/assets/svg-vue/general/warning.svg'
import ClockSvg from '@/assets/svg-vue/header/clock3.svg'

export default {
  name: "MarketingDatatableTDNotificationStatus",
  components: {
    WarningSvg,
    ClockSvg
  },
  props: {
    object: {
      type: Object
    }
  },
}
</script>
