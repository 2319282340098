<template>
  <div style="max-width: 400px;">
      <div class="mt-4">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline w-100"
                @click="closeShift">
          {{ $t('close_shift') }}
        </button>
      </div>
      <div class="mt-3">
        <button class="btn btn-themed btn-themed-squared btn-themed-outline w-100"
                @click="printTotals">
          {{ $t('print_totals') }}
        </button>
      </div>
    <div class="mt-3" v-if="data">
      <div v-html="data" style="white-space: pre-line"/>
    </div>
  </div>
</template>

<script setup>
import { KKMService } from "@/services/kkm.service"
import { ref } from "vue"

const data = ref(null)

const closeShift = async () => {
  const res = await KKMService.closeShiftByCard(0)
  data.value = res?.data?.Slip || res?.error
}

const printTotals = async () => {
  const res = await KKMService.terminalReport(0)
  data.value = res?.data?.Slip || res?.error
}
</script>
