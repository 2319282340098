<template>
  <div v-if="hasRight" class="mb-4">
    <b-button v-spaceduck-collapse="`voip-c`"
              class="btn-collapse">
      VoIP
    </b-button>
    <spaceduck-collapse id="voip-c"
                        @show="opened">
      <div class="mt-3 pb-5">
        <InputGroup v-model="voip_uuid"
                    type="password"
                    :readonly="true"
                    class="mt-3"
                    :label="$t('integration_key')"/>

        <button class="mt-3 btn btn-themed-outline btn-themed-squared px-3 d-inline-block pointer"
                :disabled="loading"
                @click="generateVoIPToken">{{ $t('generate') }}
        </button>
      </div>
    </spaceduck-collapse>
  </div>

</template>

<script>
import { mapState } from "vuex"
import { ClinicService } from "@/services/api.service"
import InputGroup from "@/components/form/InputGroup"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import { generateUUIDV4 } from "@/services/jwt.service"

export default {
  name: "VoipIntegration",
  components: {
    SpaceduckCollapse,
    InputGroup,
  },
  data() {
    return {
      employees: [],
      voip_uuid: '',
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        voip_uuid: '',
      }
    },
    hasRight() {
      if(this.superadmin) return this.clinic.rights.includes('voip')
      return this.rights.includes('voip')
    }
  },
  async mounted() {
    this.voip_uuid = this.ppoSettings.voip_uuid
  },
  methods: {
    async opened() {
    },
    // async save() {
    //   await ClinicService.savePPOAttribute([
    //     'voip_uuid',
    //   ], [
    //     this.voip_uuid,
    //   ], this.superadmin, this.clinic.id)
    //   this.$noty.info(this.$t('success_message'))
    //   await this.$store.dispatch('init')
    // },
    async generateVoIPToken() {
      const uuid = generateUUIDV4()
      await ClinicService.savePPOAttribute(['voip_uuid'], [ uuid ], this.superadmin, this.clinic.id)
      this.voip_uuid = uuid
    }
  }
}
</script>
