<template>
  <div>
    <div v-if="object && !object.id">
      <b-tabs nav-class="sub-menu"
              content-class="pt-4"
              class="w-100"
              @activate-tab="tabChanged">
        <b-tab active :title="$t('quick_card')"></b-tab>
        <b-tab :title="$t('full_card')" ></b-tab>
        <b-tab :title="$t('representative_noun')" ></b-tab>
      </b-tabs>
    </div>
    <div v-if="object && object.id">
      <b-tabs nav-class="sub-menu"
              content-class="pt-4"
              class="w-100"
              @activate-tab="tabSelected">
        <b-tab active :title="$t('general_info')"></b-tab>
        <b-tab :title="$t('passport_data')" ></b-tab>
        <b-tab :title="$t('representative_noun')" ></b-tab>
<!--        <b-tab v-if="rights.includes('egisz_view')" :title="$t('egisz')" ></b-tab>-->
      </b-tabs>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex"

export default {
  name: "PatientsAdditionalFormComponent",
  components: {
  },
  props: {
    object: {
      type: Object
    },
    options: {},
  },
  computed: {
    newPatientViews() {
      return ['quick_card', 'full_card', 'representative']
    },
    views() {
      return ['general_info', 'passport_data', 'representative', 'egisz']
    },
    representativePerson() {
      return this.object.representative
    },
    ...mapState({
      rights: state => state.auth.rights,
    })
  },
  mounted() {
    this.$store.commit('setFullCard', false)
    if(this.object && !this.object.id) {
      this.$store.commit('setFormTabActive', this.newPatientViews[0])
    }
    if(this.object && this.object.id) {
      this.$store.commit('setFormTabActive', this.views[0])
    }
  },
  methods: {
    tabChanged(tab) {
      this.$store.commit('setFormTabActive', this.newPatientViews[tab])
    },
    tabSelected(tab) {
      this.$store.commit('setFormTabActive', this.views[tab])
    }
  },
  watch: {
    representativePerson(val) {
      if(val) {
        this.object.representative_fio = val.full_name
        this.object.representative_phone = val.phone
        this.object.representative_address = val.address
        this.object.representative_passport = val.passport
      }
    }
  }
}
</script>
