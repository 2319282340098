<template>
  <div class="pt-3">
    <b-row>
      <b-col cols="auto">
        <img class="patient-img"
             :src="clinic.photo"
             :alt="clinic.name"
             :title="clinic.name"/>
      </b-col>
      <b-col>
        <h2>
          {{ clinic.name }}
        </h2>
        <div v-if="['tech_specialist'].includes(role)" class="mb-3">
          <a class="text-primary pointer"
             @click="edit">
            {{ $t('edit') }}
          </a>
        </div>
        <div>{{ clinic.phone }}</div>
        <div>{{ clinic.email }}</div>
        <div class="mt-3">{{ $t('tariff') }}: <b>{{ clinic.tariffInfo && clinic.tariffInfo.tariff ? clinic.tariffInfo.tariff.title : '-' }}</b></div>
        <div v-if="clinic.tariffInfo && clinic.tariffInfo.tariff_id == 14 && clinic.doctors_count == 0"
             class="mt-3">
          <button :disabled="loading"
                  class="btn btn-themed-default btn-themed btn-themed-outline btn-themed-squared h-auto"
                  @click="loadDemoData">
            Загрузить демо-данные
          </button>
        </div>
      </b-col>
      <b-col md="8" cols="12">
        <b-row>
          <b-col md cols="12">
            <PatientCol label="city" :title="clinic.city ? clinic.city.name + (clinic.city.country ? ', '+clinic.city.country.name : '') : ''"/>
            <PatientCol label="address" :title="clinic.address"/>
            <PatientCol label="time_zone" :title="clinic.time_zone"/>
            <PatientCol label="date_of_registration" :title="formatDateTime(clinic.date_of_registration)"/>
            <PatientCol label="created" :title="clinic.author ? clinic.author.full_name : 'Демо'"/>

          </b-col>
          <b-col md cols="12">
            <PatientCol label="language" :title="$t(clinic.language)"/>
            <PatientCol label="currency" :title="clinic.currency ? clinic.currency.title : '-'"/>
            <PatientCol label="source" :title="clinic.source_object ? clinic.source_object.name : '-'"/>
            <PatientCol label="manager2" :title="clinic.manager ? clinic.manager.full_name : '-'"/>
            <PatientCol label="comment" :title="clinic.comment"/>
          </b-col>
          <b-col md cols="12">
            <PatientCol label="doctors" :title="clinic.doctors_count"/>
            <PatientCol label="patients" :title="clinic.patients_count"/>
            <PatientCol label="administrators" :title="clinic.managers_count"/>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script setup>
import { useStore } from "vuex"
import PatientCol from "@/components/parts/patients/PatientCol"
import { formatDateTime } from "@/extensions/filters/filters"
import { SuperadminService } from "@/services/api.service"
import {computed, getCurrentInstance, onBeforeMount, ref, watch} from "vue"
import { useHead } from "@unhead/vue"
import options from "@/data/superadmin/clinic"
import { copyObject } from "@/extensions/prototypes/prototypes"

const store = useStore()

let i18n = null
let noty = null

const title = ref('')
const loading = ref(false)
const clinic = computed(() => store.state.auth.clinic)
const role = computed(() => store.state.auth.user.role)
const counter = computed(() => store.state.datatable.dataTableCounter)

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('general')
  noty = getCurrentInstance()?.appContext?.app?.noty
})


const loadDemoData = async () =>  {
  loading.value = true
  try {
    const res = await SuperadminService.populateDemoData(clinic.value.id)
    if(res.data) noty.info(i18n.t('success_message'))
  } catch (e) {
    console.log(e)
  } finally {
    loading.value = false
  }
}

const edit = () => {
  store.commit('setEloquentOptions', options)
  store.commit('setModule', 'superadmin')
  store.commit('setViewModel', 'clinic')
  store.commit('setSelectedModel', copyObject(clinic.value))
  store.commit('setShowModal', true)
}

const reload = async () => {
  const res = await store.dispatch('getSuperAdminClinic', clinic.value.id)
  store.commit('setClinic', { clinic: res })
}

watch(counter, reload)

</script>

<style scoped lang="scss">
.patient-img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 100%;
}
h2 {
  font-size: 22px;
  margin-bottom: 16px;
  font-weight: bold;
}
</style>
