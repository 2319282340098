import store from "@/store"

export default {
    beforeMount(el, binding) {
        setListener(el, binding)
    },
    mounted(el, binding) {
        if(store.state?.dom?.spaceduckCollapse?.[binding.value]) {
            el.setAttribute("aria-expanded", true)
            const content = document.getElementById(binding.value)
            if(content) {
                content.style.overflow = 'visible'
                setTimeout(() => {
                    content.classList.add('show')
                }, 100)
            }
        }
    },
    update(el, binding) {
        setListener(el, binding)
    },
    unmounted(el, binding) {
        store.commit('removeSpaceduckCollapse', binding.value)
        el.removeEventListener('click', el.clickEvent)
    },
}

const setListener = (el, binding) => {
    el.timer = null

    if(el.clickEvent) {
        el.removeEventListener('click', el.clickEvent)
    }
    el.clickEvent = function () {
        store.commit('toggleSpaceduckCollapse', binding.value)
        const content = document.getElementById(binding.value)
        if(!content) return
        content.style.maxHeight = content.scrollHeight + "px"; // even when we are hiding it, we need to set height, otherwise it will not animate
        if(content.classList.contains('show')) { // hiding
            el.setAttribute("aria-expanded", false)
            setTimeout(() => {
                content.style.maxHeight = null
                content.style.overflow = null
            }, 1)
        } else {
            el.setAttribute("aria-expanded", true)
        }
        if(el.timer) clearTimeout(el.timer)
        el.timer = setTimeout(() => { // this is for the case when contents of the collapse are changing, so we should cancel max height
            if(content?.style) {
                content.style.maxHeight = null
                if(content.classList.contains('show')) {
                    content.style.overflow = 'visible'
                }
            }
        }, 150)

    }
    el.addEventListener('click', el.clickEvent)
}
