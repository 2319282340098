export default {
    primaryKey: "id",
    model: 'feedback',
    order: 'id',
    sort: 'desc',
    deletemessage: 'are_you_sure_you_want_to_delete_record',
    actionscomponent: 'CRMFeedbackDatatableActions',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "delete"
    ],
    rights: {
        edit: "publish_feedbacks",
        delete: "publish_feedbacks",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true,
        },
        {
            id: 'created_at',
            title: 'date',
            table: true,
            sortable: true,
            format_datetime: true,
            filter: true
        },
        {
            id: 'author',
            title: 'patient',
            table: true,
            // sortable: true,
            table_show: 'author.full_name',
            // filter: true,
        },
        {
            id: 'text',
            title: 'text',
            table: true,
            filter: true,
            // form: {
            //     type: "textarea",
            // }
        },
        {
            id: 'rating',
            title: 'rating',
            table: true,
            tdcomponent: 'RatingTD'
        },
        {
            id: 'fileUrl',
            title: '',
            table: true,
            format_image: true
        },
        {
            id: 'active',
            title: 'published',
            table: true,
            sortable: true,
            form: {
                type: "checkbox"
            },
            tdcomponent: 'ActiveTD'
        },
    ]

}
