<template>
  <div class="py-4">
    <h5>{{ title }}</h5>
    <div class="lead-page py-3 px-3 my-3">
      <LeadShowContent/>
    </div>
  </div>
</template>

<script setup>
import { useHead } from "@unhead/vue"
import { getCurrentInstance, onBeforeMount, ref } from "vue"
import LeadShowContent from "@/components/parts/crm/LeadShowContent.vue"
import { useRoute } from "vue-router"

const route = useRoute()
const title = ref('')
let i18n = null

useHead({
  title: () => title.value,
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = `${i18n.t('lead')} #${route.params.id || '-'}`
})
</script>

<style scoped lang="scss">
.lead-page {
  max-width: 1000px;
  border: 1px solid $gray-icon;
  border-radius: 16px;
}
</style>
