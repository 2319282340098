import { complexServiceAdapter } from "@/adapters/services/complexService"

export default {
    primaryKey: "id",
    model: 'complex_service',
    order: 'title',
    sort: 'asc',
    adapter: complexServiceAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_service',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            filter: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true
            },
            tdcomponent: 'ServicesDatatableTDComplexServiceTitle',
        },
        {
            id: 'cost',
            title: 'cost',
            table: true,
            format_currency: true,
            sortable: false
        },
        // {
        //     id: 'services',
        //     title: 'services',
        //     table: false,
        //     form: {
        //         select_type: "model",
        //         select_data: {
        //             "title": "title"
        //         },
        //         component: "PlanTreatmentTemplateDatatableFormServices"
        //     }
        // },
        {
            id: 'services',
            title: 'services',
            form: {
                component: 'ServiceDatatableFormComplexServiceServices',
                required: true
            }
        }
        // {
        //     id: 'materials',
        //     title: 'materials',
        //     form: {
        //         component: 'WarehouseDatatableFormKitMaterials',
        //         required: true
        //     }
        // }
    ]
}
