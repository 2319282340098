<template>
  <div>
    <div>{{ object.egisz_status_text ? object.egisz_status_text : '-' }}</div>
    <div v-if="object.egisz_status_message">
      <a v-if="!showAll"
         class="pointer text-primary"
         @click="showAll = true">
        {{ $t('show_more') }}
      </a>
      <template v-else>
        {{ object.egisz_status_message }}
      </template>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"

defineProps({
  object: {
    type: Object
  }
})

const showAll = ref(false)
</script>
