<template>
  <div>
    <a style="margin-bottom: 10px;"
       v-if="rights.includes('add_records')"
       @click="addVisit"
       class="btn btn-themed d-inline-flex align-items-center btn-themed-outline btn-themed-squared">
      {{ $t('add') }}
    </a>
    <DataTable :options="options"
               :params="{ client_id: patient.id }"
               :enable-filter="false"
               module="patients"/>
    <ReasonPrompt ref="reasonPrompt"/>
  </div>
</template>

<script>

import DataTable from "../../parts/general/DataTable"
import options from "../../../data/patients/record"
import { mapState } from "vuex"
import moment from "moment/moment"
import ReasonPrompt from "@/components/parts/general/ReasonPrompt.vue"

export default {
  name: "PatientVisits",
  head() {
    return {
      title: this.$t("visits"),
    }
  },
  components: {
    ReasonPrompt,
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  computed: {
    ...mapState({
      patient:  state => state.patient.patient,
      rights: state => state.auth.rights,
      branches: state => state.auth.branches,
    }),
    patient_id() {
      return this.patient.id
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    if(this.patient) this.$store.commit('setAdditionalParams', { client_id: this.patient.id })
    this.options = this.filterOptions()
  },
  methods: {
    filterOptions() {
      if(this.branches && this.branches.length > 1) return options
      options.fields = options.fields.filter(x => x.id !== 'branch_id')
      return options
    },
    addVisit() {
      let now = moment().format('YYYY-MM-DD HH')+":30:00"
      if(moment().format('mm') > 30) {
        now = moment().add(1, 'hours').format('YYYY-MM-DD HH')+":00:00"
      }
      this.$store.commit('setNewVisit', {
        time: now,
        client_id: this.patient
      })
      this.$bvModal.show('add-new-visit-modal')
    }
  },
  beforeUnmount() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>
