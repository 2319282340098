<template>
  <div class="mb-4" v-if="hasRight">
    <b-button v-spaceduck-collapse="`jet-c`" class="btn-collapse">WhatsApp / Telegram API</b-button>
    <spaceduck-collapse id="jet-c">
      <div class="mt-3 pb-2">
        <div v-if="!ppoSettings.jetAPIRealInstanceId">
          <InputGroup v-model="phone"
                      :show-errors="true"
                      validation-id="phone"
                      :label="$t('phone')"/>
          <InputGroup v-model="email"
                      :show-errors="true"
                      validation-id="email"
                      :label="$t('email')"/>
          <button class="btn btn-themed btn-themed-squared"
                  @click="createInstance"
                  :disabled="loading">
            {{ $t('connect') }}
          </button>
        </div>
        <div v-if="ppoSettings.jetAPIRealInstanceId">
          <div class="mt-3">
            <div>Whatsapp:</div>
            <div class="mt-3" v-if="qr_code && !success.whatsapp">
              <p class="mb-3">{{ $t('whatsapp_qr_instructions') }}</p>
              <qrcode-vue :value="qr_code" :size="300"/>
            </div>
            <div v-if="success.whatsapp">
              <div class="alert alert-success mt-3">
                {{ $t('connection_is_right_can_be_used') }}
              </div>
            </div>

            <div>
              <b-spinner class="mt-3" v-if="!error && !qr_code && !success.whatsapp"/>
            </div>
            <div class="mb-4 mt-3" v-if="!qr_code">
              <button class="btn btn-themed btn-themed-squared btn-themed-outline"
                      @click="whatsappReset">
                {{ $t('reset') }}
              </button>
            </div>
            <div class="alert alert-danger mt-3" v-if="error">{{ error }}</div>
          </div>
          <div>
            <div>Telegram:</div>
            <div class="mt-3" v-if="!success.telegram">
              <div v-if="telegramStatus === 'wait_phone'">
                <InputGroup v-model="telegramPhone"
                            :show-errors="true"
                            validation-id="telegram-phone"
                            :label="$t('phone')"/>
                <button class="btn btn-themed btn-themed-squared"
                        @click="telegramSendPhone">
                  {{ $t('send') }}
                </button>
              </div>
              <div v-if="telegramStatus === 'wait_code'">
                <div>Проверьте сообщения в чате телеграм - от телеграм бота вам должно прийти сообщение с кодом. Введите его в поле ниже:</div>
                <InputGroup v-model="telegramCode"
                            :show-errors="true"
                            validation-id="code"
                            :label="$t('code')"/>
                <button class="btn btn-themed btn-themed-squared"
                        @click="telegramSendCode">
                  {{ $t('send') }}
                </button>
              </div>
              <div v-if="telegramStatus === 'wait_password'">
                <div>Введите пароль в поле ниже:</div>
                <InputGroup v-model="telegramPassword"
                            :label="$t('password')"/>
                <button class="btn btn-themed btn-themed-squared"
                        @click="telegramSendPassword">
                  {{ $t('send') }}
                </button>
              </div>
            </div>
            <div class="mt-3">
              <b-spinner v-if="!error && !success.telegram && (!telegramStatus || telegramStatus == 'ok')"/>
            </div>
            <div v-if="success.telegram"
                 class="alert alert-success mt-3">
              {{ $t('connection_is_right_can_be_used') }}
            </div>
            <div class="mt-3">
              <button class="btn btn-themed btn-themed-squared btn-themed-outline"
                      @click="telegramReset">
                {{ $t('reset') }}
              </button>
            </div>
          </div>
          <hr/>
          <div class="mt-3">
            <div>{{ $t('settings') }}:</div>
            <CheckboxGroup  v-model="showPanel"
                            class="mt-3 mb-3"
                            :label="$t('turn_on_chat_panel')"
                            fid="show_panel"/>
            <InputGroup v-model="panelPassword"
                        type="password"
                        v-if="showPanel"
                        :show-errors="true"
                        validation-id="password"
                        :label="$t('password')"/>
            <div v-if="passwordValidationError" class="text-danger small mb-4">
              {{ $t('password_validation_error') }}
            </div>
            <button :disabled="loading"
                    class="btn-themed btn-themed-squared d-inline-block"
                    @click="saveSettings">
              {{ $t('save') }}
            </button>
          </div>
        </div>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import InputGroup from "@/components/form/InputGroup"
import { mapState } from "vuex"
import { ClinicService, WhatsappService } from "@/services/api.service"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"

export default {
  name: "JetAPIIntegration",
  components: {
    CheckboxGroup,
    InputGroup,
    QrcodeVue,
    SpaceduckCollapse
  },
  computed: {
    ...mapState({
      clinic: state   => state.auth.clinic,
      loading: state  => state.dom.loading,
      rights: state => state.auth.rights,
      superadmin: state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        jetAPIRealInstanceId: '',
        jetAPICustomerId: '',
        jetAPIToken: '',
        jetAPIShowPanel: false,
        jetAPIPanelPassword: '',
        jetapi_iframe_url: ''
      }
    },
    hasRight() {
      if(this.superadmin) return this.clinic.rights.includes('jet_api')
      return this.rights.includes('jet_api')
    }
  },
  data() {
    return {
      phone: '',
      email: '',
      panelPassword: '',
      showPanel: false,
      whatsappSocket: null,
      qr_code: null,
      success: {
        whatsapp: false,
        telegram: false
      },
      error: '',
      passwordValidationError: false,
      telegramSocket: null,
      telegramStatus: null,
      telegramPhone: '',
      telegramCode: '',
      telegramPassword: ''
    }
  },
  async mounted() {
    if(!this.hasRight) return
    const jetAPIRealInstanceId = this.ppoSettings.jetAPIRealInstanceId
    if(jetAPIRealInstanceId) {
      const res = await WhatsappService.getJetAPIStatus(this.superadmin, this.clinic.id)
      console.log(res.data)
      if(res.data) {
        this.whatsappConnect()
        this.telegramConnect()
      }
    }
    this.showPanel = this.ppoSettings.jetAPIShowPanel
    this.panelPassword = this.ppoSettings.jetAPIPanelPassword
  },
  methods: {
    async createInstance() {
      try {
        const res = await WhatsappService.createJetAPIInstance(this.superadmin, {
          phone: this.phone,
          email: this.email
        }, this.clinic.id)
        if(!res.data) return
        await this.$store.dispatch('init')
        this.connect()
      } catch (e) {
        console.log(e)
      }
    },
    whatsappConnect() {
      this.whatsappSocket = new WebSocket(`wss://msg-farmer.pushsms.ru/socket/websocket?token=${this.ppoSettings.jetAPIToken}&vsn=2.0.0`)

      this.whatsappSocket.onopen = () => {
        this.whatsappSocket.send(`["1","1","room:v1:whats_app:auth:${this.ppoSettings.jetAPICustomerId}","phx_join",{}]`)
      };

      this.whatsappSocket.onerror = () => {
        this.error = this.$t('connection_failed')
      };

      this.whatsappSocket.onmessage = (event) => {
        console.log("Whatsapp Socket got data")
        const data = JSON.parse(event.data)
        console.log(data)
        if(!data || !Array.isArray(data) || data.length < 5) return
        const resp = data[4]
        if(resp.qr_code) {
          this.qr_code = resp.qr_code
        }
        if(resp.auth === true) {
          this.success.whatsapp = true
        }
      }
    },
    telegramConnect() {
      this.telegramSocket = new WebSocket(`wss://api.pushsms.ru/socket/websocket?token=${this.ppoSettings.jetAPIToken}&vsn=2.0.0`)

      this.telegramSocket.onopen = () => {
        this.telegramSocket.send(`["1","1","room:v1:telegram:auth","phx_join",{}]`)
      };

      this.telegramSocket.onerror = () => {
        this.error = this.$t('connection_failed')
      };

      this.telegramSocket.onmessage = (event) => {
        console.log("Telegram Socket got data");
        const data = JSON.parse(event.data)
        console.log(data)
        if(!data || !Array.isArray(data) || data.length < 5) return
        const resp = data[4]
        if(resp.status) this.telegramStatus = resp.status
        if(!this.telegramStatus) return
        this.success.telegram = this.telegramStatus === 'authorized'
      };

    },
    async telegramSendPhone() {
      await this.telegramSendMessage(`["1","1","room:v1:telegram:auth","send_phone",{"phone":"${this.telegramPhone}"}]`)
      this.telegramPhone = ''
    },
    async telegramSendCode() {
      await this.telegramSendMessage(`["1","1","room:v1:telegram:auth","send_code",{"code":"${this.telegramCode}"}]`)
      this.telegramCode = ''
    },
    async telegramSendPassword() {
      await this.telegramSendMessage(`["1","1","room:v1:telegram:auth","send_password",{"password":"${this.telegramPassword}"}]`)
      this.telegramPassword = ''
    },
    async telegramReset() {
      await this.telegramSendMessage(`["1","1","room:v1:telegram:auth","reset",{}]`)
    },
    async whatsappReset() {
      await WhatsappService.breakJetAPIConnection(this.superadmin, this.clinic.id)
      location.reload()
    },
    async telegramSendMessage(msg) {
      if(!this.telegramSocket) return
      if(this.telegramSocket.readyState === 3) {
        this.telegramSocket.close()
        this.telegramConnect()
        while (this.telegramSocket.readyState !== 1) {
          await new Promise(r => setTimeout(r, 250))
        }
      }
      this.telegramSocket.send(msg)
    },
    async saveSettings() {
      this.passwordValidationError = false
      if(this.showPanel) {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/;
        if(!regex.test(this.panelPassword)) {
          this.passwordValidationError = true
          return;
        }
      }
      let iframeNeedsUpdate = false
      if(this.panelPassword !== this.ppoSettings.jetAPIPanelPassword) {
        iframeNeedsUpdate = true
      }
      await ClinicService.savePPOAttribute([
        'jetAPIShowPanel',
        'jetAPIPanelPassword',
      ], [
        this.showPanel,
        this.panelPassword,
      ], this.superadmin, this.clinic.id)
      if(iframeNeedsUpdate) {
        let link = this.ppoSettings.jetapi_iframe_url
        const linkRes = await WhatsappService.getJetApiIFrameLink()
        link = linkRes?.data?.link || null
        await ClinicService.savePPOAttribute([
          'jetapi_iframe_url',
        ], [
          link,
        ], this.superadmin, this.clinic.id)
      }
      await this.$store.dispatch('init')
    }
  },
  beforeUnmount() {
    if(this.whatsappSocket) this.whatsappSocket.close()
    if(this.telegramSocket) this.telegramSocket.close()
  },
  watch: {
    telegramStatus(val) {
      const status = val && val === 'authorized' ? 1 : 0
      ClinicService.savePPOAttribute([
        'jetAPITelegram',
      ], [
        status,
      ], this.superadmin, this.clinic.id)
    }
  }
}
</script>
