<template>
  <div>
    <DataTable :options="options"
               :params="{ client_id: patient.id, branch_id: branch }"
               module="patients"/>
  </div>
</template>

<script>
import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/patients/advance"
import { mapState } from "vuex"

export default {
  name: "PatientDepositTransactions",
  head() {
    return {
      title: this.$t("deposit"),
    }
  },
  components: {
    DataTable
  },
  data() {
    return {
      options: options
    }
  },
  computed: {
    ...mapState({
      spaceduckCollapse:  state => state.dom.spaceduckCollapse,
      patient:  state => state.patient.patient,
      branch: state => state.auth.branch,
    })
  },
  beforeMount() {
    this.$store.commit("setViewModel", 'advance')
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
    if(this.patient) this.$store.commit('setAdditionalParams', { client_id: this.patient.id })
  },
  beforeUnmount() {
    this.$store.commit('setAdditionalParams', {})
  }
}
</script>
