<template>
    <span :class="{ negative: quantity < 0 }">
    {{ formatCurrency(quantity * object.price) }}
  </span>
</template>

<script>
import {mapState} from "vuex"
import {formatCurrency} from "@/extensions/filters/filters"

export default {
  name: "WarehouseDatatableTDMaterialValue",
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      filters: state => state.datatable.filters
    }),
    quantity() {
      if(this.filters && this.filters.warehouse_id) {
        return this.object.quantities?.[this.filters.warehouse_id.condition] || 0
      }
      return this.object.total_quantity
    }
  },
  methods: {
    formatCurrency
  }
}
</script>

<style scoped lang="scss">

</style>
