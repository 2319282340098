export default {
    primaryKey: "id",
    model: 'record_reason',
    order: 'sort_order',
    sort: 'desc',
    modaltitle: 'cancel_and_move_reason',
    showTotalRecords: true,
    actions: [
        "add",
        "edit",
        "delete"
    ],
    editCondition(object) {
        return !object.is_default
    },
    deleteCondition(object) {
        return !object.is_default
    },
    deletemessage: 'confirm_deletion',
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'title',
            title: 'title',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            form: {
                type: "text",
                required: true,
            },
        },
        // {
        //     id: 'sort_order',
        //     title: 'sort_order',
        //     table: true,
        //     sortable: true,
        //     form: {
        //         type: "number",
        //         note: "higher_number_higher_priority"
        //     },
        //     format_number: true
        // },
    ]
}
