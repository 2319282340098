import  { EloquentService } from "@/services/api.service"

const state = {
    doctor: null,
    doctorScheduleDay: null,
    doctorScheduleDayPreconfig: null
}

const getters = {

}

const mutations = {
    setDoctor(state, doctor) {
        state.doctor = doctor
    },
    setDoctorScheduleDay(state, day) {
        state.doctorScheduleDay = day
    },
    setDoctorPreconfig(state, preconfig) {
        state.doctorScheduleDayPreconfig = preconfig
    }
}

const actions = {
    getDoctor(context, id) {
        return new Promise((resolve, reject) => {
            EloquentService.show('doctor', id).then(data => {
                let d = data.data
                context.commit('setDoctor', d)
                resolve(d)
            }).catch(err => {
                reject(err.response ? err.response.data.errors : [])
            })
        })
    },
};

export default {
    state,
    actions,
    mutations,
    getters
}
