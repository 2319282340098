import moment from "moment/moment"

const today = moment().format('YYYY-MM-DD')
import store from "@/store"

export default function paid({ next, router }) {
    if(store.state.auth.free_day && store.state.auth.free_day == today) {
        return next()
    }
    if(store.state.auth.paid_till && store.state.auth.paid_till >= today) {
        return next()
    }
    if(store.state.auth.clinicTariff && store.state.auth.clinicTariff.isFree) {
        return next()
    }
    if(store.state.auth.superadmin) {
        router.push('/superadmin')
        return
    }
    // if(router.app.$store.state.auth.rights.includes('settings')) {
    //     router.push('/settings/billing')
    //     return
    // }
    if(store.state.auth.rights.includes('billing')) {
        router.push('/billing')
        return
    }
    router.push('/unpaid')
    // router.push('/404')



}
