<template>
  <div class="mt-3">
    <DataTableHtml ref="datatable"
                   :columns="columns"
                   :initialOrder="false"
                   initialSort="asc"
                   :rows="rows"/>
  </div>
</template>

<script setup>

import { ReportsService } from "@/services/api.service"
import DataTableHtml from "@/components/parts/general/DataTableHtml"
import { computed, getCurrentInstance, onBeforeMount, onMounted, ref, watch } from "vue"
import { useStore } from "vuex"
import { useHead } from "@unhead/vue"

let i18n = null
const title = ref('')
const datatable = ref(null)

useHead({
  title: () => title.value,
})

const props = defineProps({
  from: {},
  to: {},
  sources: {},
  selectedBranches: {},
  fields: {},
  oneStaff: {},
  doctors: {},
  whichSource: {},
})

const store = useStore()
const counter = computed(() => store.state.datatable.dataTableCounter)

const rows = ref([])
const columns = computed(() => {
  return [
    {
      id: 'source',
      field: 'source',
      label: i18n?.t('source'),
      search_select: true,
      sortable: true,
    },
    {
      id: 'cnt',
      field: 'cnt',
      label: i18n?.t('amount'),
      format_number: true,
      totals: true
    },
    {
      id: 'primary_records',
      field: 'primary_records',
      label: i18n?.t('primary_records'),
      format_number: true,
      totals: true
    },
    {
      id: 'primary_records_came',
      field: 'primary_records_came',
      label: i18n?.t('primary_records_came'),
      format_number: true,
      totals: true
    },
    {
      id: 'primary_records_revenue',
      field: 'primary_records_revenue',
      label: i18n?.t('primary_records_revenue'),
      format_number: true,
      totals: true
    },
    {
      id: 'primary_records_further',
      field: 'primary_records_further',
      label: i18n?.t('primary_records_further'),
      format_number: true,
      totals: true
    },
    {
      id: 'repeated_records',
      field: 'repeated_records',
      label: i18n?.t('repeated_records'),
      format_number: true,
      totals: true
    },
    {
      id: 'repeated_records_came',
      field: 'repeated_records_came',
      label: i18n?.t('repeated_records_came'),
      format_number: true,
      totals: true
    },
    {
      id: 'repeated_records_revenue',
      field: 'repeated_records_revenue',
      label: i18n?.t('repeated_records_revenue'),
      format_number: true,
      totals: true
    },
    {
      id: 'repeated_records_further',
      field: 'repeated_records_further',
      label: i18n?.t('repeated_records_further'),
      format_number: true,
      totals: true
    },
    {
      id: 'total_revenue',
      field: 'total_revenue',
      label: i18n?.t('total_revenue'),
      format_number: true,
      totals: true
    },
  ]
})

onBeforeMount(() => {
  i18n = (getCurrentInstance())?.ctx?.$i18n
  title.value = i18n.t('visits_by_source')
})

onMounted(() => {
  loadReport()
})

watch(() => counter, () => {
  loadReport()
})

const loadReport = async () => {
  const res = await ReportsService.loadReport('visits_by_source', {
    from: props.from,
    to: props.to,
    whichSource: props.whichSource,
    doctors: props.doctors.map(s => s.id),
    fields: props.fields.map(s => s.id),
    sources: props.sources.map(s => s.id),
    branch_id: props.selectedBranches,
    created_by: props.oneStaff?.id || null,
  })
  rows.value = res.data
}

const excel = () => {
  datatable.value.excel()
}

defineExpose({
  loadReport,
  excel
})

</script>
