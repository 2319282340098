<template>
    <div class="container pt-5">
    {{ signature }}
      <button @click="connectAndSign">sign</button>
    </div>
</template>

<script>
// https://sigex.kz/support/developers/#document-registration
// https://sigex.kz/blog/digital-document-workflow/
// https://sigex.kz/blog/2024-02-02-integration-quick-start/
import { mapState } from "vuex"
import { NCALayerClient } from "ncalayer-js-client"
// import axios from "axios"

export default {
  name: "TestNCLayer",
  components: {
  },
  data() {
    return {
      signature: null
    }
  },
  computed: {
    ...mapState({
    }),
  },
  methods: {
    async connectAndSign() {
      const ncalayerClient = new NCALayerClient()

      try {
        await ncalayerClient.connect();
      } catch (error) {
        alert(`Не удалось подключиться к NCALayer: ${error.toString()}`)
        return;
      }

      const documentInBase64 = btoa('MTEK')

      let base64EncodedSignature;
      try {
        base64EncodedSignature = await ncalayerClient.basicsSignCMS(
            NCALayerClient.basicsStorageAll,
            documentInBase64, // здесь поддерживаются String | ArrayBuffer | Blob | File, строки интерпретируются как Base64
            NCALayerClient.basicsCMSParamsDetached,
            NCALayerClient.basicsSignerSignAny,
        );
        this.signature = base64EncodedSignature

        // const res = await axios.post('https://sigex.kz/api', {
        //   title: '1',
        //   description: '2',
        //   signType: 'cms',
        //   signature: base64EncodedSignature
        // })
        // console.log(res)
      } catch (error) {
        if (error.canceledByUser) {
          alert('Действие отменено пользователем.')
        } else {
          alert(error.toString())
        }
      }

      // return base64EncodedSignature;
    }

  },
}
</script>
