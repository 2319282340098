<template>
  <a @click="showDetails" class="pointer text-primary">
    {{ object.title }}
  </a>
</template>

<script>
export default {
  name: "CRMDatatableTDTaskTitle",
  props: {
    object: {
      type: Object
    }
  },
  methods: {
    showDetails() {
      const queryParams = { ...this.$route.query }
      queryParams.id = this.object.id
      this.$router.replace({ 'query': queryParams }).catch(() => {})
      // this.$router.replace({ 'query': { id: this.object.id } }).catch(() => {})
      this.$store.commit('setTask', this.copyObject(this.object))
      this.$bvModal.show('task-show-modal')
    }
  }
}
</script>
