<template>
  <div>
    <div class="font-weight-bold">{{ formatNumber(object[field.id][1]) }}</div>
    <div class="text-muted">{{ formatNumber(object[field.id][0]) }}</div>
  </div>
</template>

<script>

import {formatNumber} from "@/extensions/filters/filters"

export default {
  name: "ReportByServicesDatatableTDService",
  components: {
  },
  props: {
    object: {
      type: Object
    },
    field: {
      type: Object
    },
  },
  methods: {
    formatNumber
  }
}
</script>
