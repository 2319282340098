<template>
  <g>
    <!--   SAME AS 47  -->
    <g class="implant" v-if="status && status.id == 10">
      <path d="M4.92529 53.4052L7.00901 54.675H10.4927L12.5927 53.4052L10.9811 51.8587L13.0648 50.3122L11.1764 48.7657L13.4718 47.0726L11.6485 45.5261L13.9439 44.1261L11.9253 42.7099L13.9439 41.0982L12.2509 39.5517L14.4811 38.1355L12.723 36.6703L15.0183 35.1238L12.8695 33.5773L15.0183 31.9657L13.4718 30.6145L15.5555 29.068L13.7323 27.5866L15.816 26.0401L14.009 24.2983L15.0183 22.6215V8.3611L13.1299 1.03553H7.00901" fill="#8889BE"/>
      <path d="M12.3326 53.4052L10.2489 54.675H6.78142L4.66514 53.4052L6.29305 51.8587L4.19305 50.3122L6.08142 48.7657L3.80235 47.0726L5.60933 45.5261L3.33026 44.1261L5.34886 42.7099L3.33026 41.0982L5.007 39.5517L2.79305 38.1355L4.53491 36.6703L2.25585 35.1238L4.40468 33.5773L2.25585 31.9657L3.80235 30.6145L1.71864 29.068L3.52561 27.5866L1.44189 26.0401L3.26515 24.2983L2.25585 22.6215V8.3611L4.12794 1.03553H8.0349" fill="#8889BE"/>
    </g>

    <g class="implant_corona" v-if="status && status.id == 11">
      <path d="M16.3696 72.7742L18.4533 74.0439H21.9371L24.0371 72.7742L22.4254 71.2277L24.5091 69.6812L22.6208 68.1347L24.9161 66.4416L23.0929 64.8951L25.3882 63.4951L23.3696 62.0789L25.3882 60.4672L23.6952 58.9207L25.9254 57.5044L24.1673 56.0393L26.4626 54.4928L24.3138 52.9463L26.4626 51.3347L24.9161 49.9835L26.9998 48.437L25.1766 46.9556L27.2603 45.4091L25.4533 43.6673L26.4626 41.9905V27.7301L24.5743 20.4045H18.4533" fill="#8889BE"/>
      <path d="M23.7769 72.7742L21.6932 74.0439H18.2258L16.1095 72.7742L17.7374 71.2277L15.6374 69.6812L17.5258 68.1347L15.2467 66.4416L17.0537 64.8951L14.7746 63.4951L16.7932 62.0789L14.7746 60.4672L16.4513 58.9207L14.2374 57.5044L15.9792 56.0393L13.7002 54.4928L15.849 52.9463L13.7002 51.3347L15.2467 49.9835L13.163 48.437L14.9699 46.9556L12.8862 45.4091L14.7095 43.6673L13.7002 41.9905V27.7301L15.5723 20.4045H19.4792" fill="#8889BE"/>
      <path d="M39.9779 21.1533C39.7119 21.8954 39.2358 22.9456 38.3536 24.8639L32.1085 26.6983C32.1085 26.6983 28.1038 27.3144 25.6393 25.7881C23.1749 24.2618 18.0779 25.7881 18.0779 25.7881C18.0779 25.7881 10.9927 26.6983 8.68223 26.6983C7.11226 26.5898 5.62811 25.9418 4.48146 24.8639C2.82916 21.6573 0.0846571 15.4822 1.68095 10.2033C2.44383 7.95624 3.8393 5.97731 5.69968 4.50421C7.18568 3.30869 8.92365 2.46604 10.7826 2.03976C12.4013 1.67903 14.0735 1.62677 15.7115 1.88573C17.065 2.17215 18.4019 2.53208 19.7162 2.96392C22.2087 3.69205 22.6288 3.9161 23.4269 3.73407C24.6872 3.45402 24.6872 2.68388 26.3535 1.88573C27.5171 1.36979 28.7772 1.10726 30.0501 1.11558C31.6173 0.961279 33.199 1.17217 34.671 1.7317C36.9152 2.76379 38.714 4.5676 39.7399 6.81463C40.6906 8.63763 41.2667 10.6325 41.4342 12.6817C41.5929 15.5792 41.0951 18.4751 39.9779 21.1533Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit" v-if="status && status.id == 12">
      <path d="M5.71201 4.72122C3.87257 6.1703 2.48354 8.11299 1.70728 10.3222C-0.183066 16.3573 3.70965 23.5967 5.0959 26.2011C5.42623 26.7839 5.68483 27.4046 5.86604 28.0495C6.26762 29.4115 6.37736 30.8429 6.1881 32.2502C5.82669 34.9143 5.25548 37.5456 4.47979 40.1197C3.60944 43.3126 3.1946 46.6125 3.24756 49.9215C3.49437 54.0754 4.49059 58.1504 6.1881 61.9497C6.94424 63.966 8.87659 69.119 10.0388 68.951C10.5149 68.8529 10.7109 67.8447 10.963 65.4083C11.4811 60.1573 11.187 58.407 11.4251 55.6065C11.4251 55.0184 12.0412 48.7733 15.4298 44.5025C15.7999 43.9474 16.2763 43.471 16.8315 43.1009C17.3867 42.7308 18.0096 42.4743 18.6644 42.3461C19.1307 42.2699 19.6081 42.2996 20.0613 42.4329C20.5146 42.5662 20.9321 42.7997 21.2829 43.1162C21.6049 43.4803 21.801 43.9704 21.7449 46.0428C21.7791 47.3336 21.6759 48.6245 21.4369 49.8935C20.8488 52.694 19.9246 53.016 18.9724 55.6065C18.5103 56.8807 18.3563 59.4852 18.0483 64.6942C17.8942 67.3687 17.8942 68.5589 18.5103 68.8949C19.1265 69.231 20.7088 67.9288 21.1288 67.4947C23.0892 65.5763 23.0472 63.952 24.3774 61.3336C25.7077 58.7151 26.1838 59.4152 28.6902 55.6345C30.0019 53.7321 31.0874 51.6832 31.9248 49.5294C32.849 47.079 32.821 45.8467 33.4651 41.5199C33.842 37.9747 34.5117 34.4668 35.4675 31.032C35.8735 29.8138 36.1676 29.7018 37.8059 26.1451C39.0241 23.5127 39.6402 22.1964 39.9483 21.3283C41.0475 18.6411 41.5261 15.7405 41.3485 12.8427C41.1849 10.7848 40.5986 8.7827 39.6262 6.96163C38.629 4.75275 36.8832 2.96734 34.6973 1.92071C33.228 1.34897 31.6478 1.11913 30.0765 1.24859C28.8042 1.24694 27.5455 1.50917 26.3798 2.01873C24.6995 2.81688 24.7135 3.60102 23.4533 3.88107C22.6551 4.0491 22.235 3.88107 19.7426 3.09693C17.5862 2.46681 16.494 2.14475 15.7378 2.01873C14.0993 1.7673 12.4286 1.81952 10.8089 2.17276C8.9407 2.63021 7.19892 3.5011 5.71201 4.72122Z" fill="#DEE1E9"/>
      <path d="M39.9779 21.3002C39.7119 22.0424 39.2358 23.0925 38.3536 25.0109L32.1085 26.8452C32.1085 26.8452 28.1038 27.4613 25.6393 25.9351C23.1749 24.4088 18.0779 25.9351 18.0779 25.9351C18.0779 25.9351 10.9927 26.8452 8.68223 26.8452C7.11226 26.7368 5.62811 26.0887 4.48146 25.0109C2.82916 21.8043 0.0846571 15.6292 1.68095 10.3502C2.44383 8.10322 3.8393 6.12428 5.69968 4.65118C7.18568 3.45566 8.92365 2.61301 10.7826 2.18673C12.4013 1.826 14.0735 1.77374 15.7115 2.0327C17.065 2.31913 18.4019 2.67905 19.7162 3.11089C22.2087 3.83903 22.6288 4.06307 23.4269 3.88104C24.6872 3.60099 24.6872 2.83085 26.3535 2.0327C27.5171 1.51676 28.7772 1.25424 30.0501 1.26256C31.6173 1.10825 33.199 1.31914 34.671 1.87867C36.9152 2.91077 38.714 4.71457 39.7399 6.9616C40.6906 8.78461 41.2667 10.7795 41.4342 12.8287C41.5929 15.7262 41.0951 18.6221 39.9779 21.3002Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M7.85407 19.2979C6.53783 21.7764 9.04429 23.4987 10.0105 29.9399C10.4081 32.7318 10.3037 35.5722 9.70241 38.3274C8.93227 41.394 7.89608 42.1361 6.9019 44.4885C3.93336 51.7419 7.26597 60.2834 7.9801 60.2134C8.23214 60.2134 7.9801 58.8132 8.42818 54.5144C8.5296 52.1701 8.896 49.8449 9.52038 47.5831C10.9206 43.5924 12.5729 44.0265 14.1552 39.7137C15.1214 37.0672 14.8834 35.849 16.3116 34.4767C16.912 33.8826 17.641 33.4346 18.4423 33.1675C19.2437 32.9004 20.0957 32.8213 20.9325 32.9364C23.4529 33.5245 24.4611 36.4651 24.7832 37.4032C25.4273 39.2656 25.2172 41.0019 24.7832 44.4885C24.6553 46.1375 24.3645 47.7698 23.915 49.3614C23.0889 52.0499 22.5148 52.1619 21.6046 55.2145C21.4085 55.8866 21.1705 57.0488 20.6804 59.4153C20.2743 61.3336 20.2463 61.6697 20.3724 61.7257C20.8484 61.9637 23.1729 58.043 23.761 56.9508C24.9574 54.9084 26.0373 52.8 26.9956 50.6357C28.1128 48.3117 28.9946 45.8818 29.6281 43.3823C30.0806 40.9381 30.3891 38.4694 30.5522 35.989C30.703 33.4772 31.0542 30.9815 31.6024 28.5256C32.9327 24.983 34.795 24.1988 34.4029 22.1965C34.0669 20.6002 32.5546 19.592 31.1683 18.6538C29.8643 17.5521 28.2378 16.9035 26.5335 16.8055C24.7272 16.8055 24.2091 17.8557 22.2207 18.4998C20.194 19.0339 18.0571 18.9805 16.0596 18.3458C13.2591 17.6596 12.6289 16.6094 10.9626 16.9455C10.3078 17.0961 9.69231 17.3835 9.15654 17.789C8.62077 18.1944 8.17689 18.7087 7.85407 19.2979Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="peridontit" v-if="status && status.id == 13">
      <path d="M5.71201 4.72122C3.87257 6.1703 2.48354 8.11299 1.70728 10.3222C-0.183066 16.3573 3.70965 23.5967 5.0959 26.2011C5.42623 26.7839 5.68483 27.4046 5.86604 28.0495C6.26762 29.4115 6.37736 30.8429 6.1881 32.2502C5.82669 34.9143 5.25548 37.5456 4.47979 40.1197C3.60944 43.3126 3.1946 46.6125 3.24756 49.9215C3.49437 54.0754 4.49059 58.1504 6.1881 61.9497C6.94424 63.966 8.87659 69.119 10.0388 68.951C10.5149 68.8529 10.7109 67.8447 10.963 65.4083C11.4811 60.1573 11.187 58.407 11.4251 55.6065C11.4251 55.0184 12.0412 48.7733 15.4298 44.5025C15.7999 43.9474 16.2763 43.471 16.8315 43.1009C17.3867 42.7308 18.0096 42.4743 18.6644 42.3461C19.1307 42.2699 19.6081 42.2996 20.0613 42.4329C20.5146 42.5662 20.9321 42.7997 21.2829 43.1162C21.6049 43.4803 21.801 43.9704 21.7449 46.0428C21.7791 47.3336 21.6759 48.6245 21.4369 49.8935C20.8488 52.694 19.9246 53.016 18.9724 55.6065C18.5103 56.8807 18.3563 59.4852 18.0483 64.6942C17.8942 67.3687 17.8942 68.5589 18.5103 68.8949C19.1265 69.231 20.7088 67.9288 21.1288 67.4947C23.0892 65.5763 23.0472 63.952 24.3774 61.3336C25.7077 58.7151 26.1838 59.4152 28.6902 55.6345C30.0019 53.7321 31.0874 51.6832 31.9248 49.5294C32.849 47.079 32.821 45.8467 33.4651 41.5199C33.842 37.9747 34.5117 34.4668 35.4675 31.032C35.8735 29.8138 36.1676 29.7018 37.8059 26.1451C39.0241 23.5127 39.6402 22.1964 39.9483 21.3283C41.0475 18.6411 41.5261 15.7405 41.3485 12.8427C41.1849 10.7848 40.5986 8.7827 39.6262 6.96163C38.629 4.75275 36.8832 2.96734 34.6973 1.92071C33.228 1.34897 31.6478 1.11913 30.0765 1.24859C28.8042 1.24694 27.5455 1.50917 26.3798 2.01873C24.6995 2.81688 24.7135 3.60102 23.4533 3.88107C22.6551 4.0491 22.235 3.88107 19.7426 3.09693C17.5862 2.46681 16.494 2.14475 15.7378 2.01873C14.0993 1.7673 12.4286 1.81952 10.8089 2.17276C8.9407 2.63021 7.19892 3.5011 5.71201 4.72122Z" fill="#DEE1E9"/>
      <path d="M39.9779 21.3002C39.7119 22.0424 39.2358 23.0925 38.3536 25.0109L32.1085 26.8452C32.1085 26.8452 28.1038 27.4613 25.6393 25.9351C23.1749 24.4088 18.0779 25.9351 18.0779 25.9351C18.0779 25.9351 10.9927 26.8452 8.68223 26.8452C7.11226 26.7368 5.62811 26.0887 4.48146 25.0109C2.82916 21.8043 0.0846571 15.6292 1.68095 10.3502C2.44383 8.10322 3.8393 6.12428 5.69968 4.65118C7.18568 3.45566 8.92365 2.61301 10.7826 2.18673C12.4013 1.826 14.0735 1.77374 15.7115 2.0327C17.065 2.31913 18.4019 2.67905 19.7162 3.11089C22.2087 3.83903 22.6288 4.06307 23.4269 3.88104C24.6872 3.60099 24.6872 2.83085 26.3535 2.0327C27.5171 1.51676 28.7772 1.25424 30.0501 1.26256C31.6173 1.10825 33.199 1.31914 34.671 1.87867C36.9152 2.91077 38.714 4.71457 39.7399 6.9616C40.6906 8.78461 41.2667 10.7795 41.4342 12.8287C41.5929 15.7262 41.0951 18.6221 39.9779 21.3002Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M7.85407 19.2979C6.53783 21.7764 9.04428 23.4987 10.0105 29.9399C10.4081 32.7318 10.3037 35.5722 9.7024 38.3274C8.93226 41.394 7.89607 42.1361 6.90189 44.4885C3.93335 51.7419 8.80625 67.07 9.52038 67C9.77242 67 7.98009 58.8132 8.42817 54.5144C8.5296 52.1701 8.89599 49.8449 9.52037 47.5831C10.9206 43.5924 12.5729 44.0265 14.1552 39.7137C15.1214 37.0672 14.8833 35.849 16.3116 34.4767C16.912 33.8826 17.641 33.4346 18.4423 33.1675C19.2436 32.9004 20.0957 32.8213 20.9325 32.9364C23.4529 33.5245 24.4611 36.4651 24.7832 37.4032C25.4273 39.2656 25.2172 41.0019 24.7832 44.4885C24.6553 46.1375 24.3645 47.7698 23.915 49.3614C23.0889 52.0499 22.5147 52.1619 21.6046 55.2145C21.4085 55.8866 21.1705 57.0488 20.6804 59.4153C20.2743 61.3336 18.874 67.444 19 67.5C19.4761 67.738 23.1729 58.043 23.761 56.9508C24.9574 54.9084 26.0373 52.8 26.9956 50.6357C28.1127 48.3117 28.9946 45.8818 29.628 43.3823C30.0805 40.9381 30.3891 38.4694 30.5522 35.989C30.703 33.4772 31.0542 30.9815 31.6024 28.5256C32.9327 24.983 34.795 24.1988 34.4029 22.1965C34.0669 20.6002 32.5546 19.592 31.1683 18.6538C29.8643 17.5521 28.2378 16.9035 26.5335 16.8055C24.7271 16.8055 24.2091 17.8557 22.2207 18.4998C20.194 19.0339 18.0571 18.9805 16.0596 18.3458C13.259 17.6596 12.6289 16.6094 10.9626 16.9455C10.3078 17.0961 9.6923 17.3835 9.15653 17.789C8.62076 18.1944 8.17688 18.7087 7.85407 19.2979Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="10" cy="68" r="3" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="19" cy="68" r="3" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="absent" v-if="status && status.id == 14">
      <path d="M4.71201 4.17642C2.87257 5.6255 1.48354 7.56819 0.70728 9.77745C-1.18307 15.8125 2.70965 23.0519 4.0959 25.6563C4.42623 26.2391 4.68483 26.8598 4.86604 27.5047C5.26762 28.8667 5.37736 30.2981 5.1881 31.7055C4.82669 34.3695 4.25548 37.0008 3.47979 39.5749C2.60944 42.7678 2.1946 46.0677 2.24756 49.3767C2.49437 53.5306 3.49059 57.6056 5.1881 61.4049C5.94424 63.4212 7.87659 68.5742 9.0388 68.4062C9.51489 68.3081 9.71093 67.2999 9.96297 64.8635C10.4811 59.6125 10.187 57.8622 10.4251 55.0617C10.4251 54.4736 11.0412 48.2285 14.4298 43.9577C14.7999 43.4026 15.2763 42.9262 15.8315 42.5561C16.3867 42.186 17.0096 41.9295 17.6644 41.8013C18.1307 41.7251 18.6081 41.7548 19.0613 41.8881C19.5146 42.0214 19.9321 42.2549 20.2829 42.5714C20.6049 42.9355 20.801 43.4256 20.7449 45.498C20.7791 46.7888 20.6759 48.0797 20.4369 49.3487C19.8488 52.1492 18.9246 52.4712 17.9724 55.0617C17.5103 56.3359 17.3563 58.9404 17.0483 64.1494C16.8942 66.8239 16.8942 68.0141 17.5103 68.3501C18.1265 68.6862 19.7088 67.384 20.1288 66.9499C22.0892 65.0315 22.0472 63.4072 23.3774 60.7888C24.7077 58.1703 25.1838 58.8704 27.6902 55.0897C29.0019 53.1873 30.0874 51.1384 30.9248 48.9846C31.849 46.5342 31.821 45.3019 32.4651 40.9751C32.842 37.4299 33.5117 33.922 34.4675 30.4872C34.8735 29.269 35.1676 29.157 36.8059 25.6003C38.0241 22.9679 38.6402 21.6516 38.9483 20.7835C40.0475 18.0963 40.5261 15.1957 40.3485 12.2979C40.1849 10.24 39.5986 8.2379 38.6262 6.41683C37.629 4.20795 35.8832 2.42254 33.6973 1.37591C32.228 0.80417 30.6478 0.574325 29.0765 0.703789C27.8042 0.702135 26.5455 0.964372 25.3798 1.47393C23.6995 2.27208 23.7135 3.05622 22.4533 3.33627C21.6551 3.5043 21.235 3.33627 18.7426 2.55213C16.5862 1.92201 15.494 1.59995 14.7378 1.47393C13.0993 1.2225 11.4286 1.27472 9.80894 1.62796C7.9407 2.08541 6.19892 2.9563 4.71201 4.17642Z"/>
    </g>

    <g class="removed" v-if="status && status.id == 15">
      <path d="M4.71201 4.17642C2.87257 5.6255 1.48354 7.56819 0.70728 9.77745C-1.18307 15.8125 2.70965 23.0519 4.0959 25.6563C4.42623 26.2391 4.68483 26.8598 4.86604 27.5047C5.26762 28.8667 5.37736 30.2981 5.1881 31.7055C4.82669 34.3695 4.25548 37.0008 3.47979 39.5749C2.60944 42.7678 2.1946 46.0677 2.24756 49.3767C2.49437 53.5306 3.49059 57.6056 5.1881 61.4049C5.94424 63.4212 7.87659 68.5742 9.0388 68.4062C9.51489 68.3081 9.71093 67.2999 9.96297 64.8635C10.4811 59.6125 10.187 57.8622 10.4251 55.0617C10.4251 54.4736 11.0412 48.2285 14.4298 43.9577C14.7999 43.4026 15.2763 42.9262 15.8315 42.5561C16.3867 42.186 17.0096 41.9295 17.6644 41.8013C18.1307 41.7251 18.6081 41.7548 19.0613 41.8881C19.5146 42.0214 19.9321 42.2549 20.2829 42.5714C20.6049 42.9355 20.801 43.4256 20.7449 45.498C20.7791 46.7888 20.6759 48.0797 20.4369 49.3487C19.8488 52.1492 18.9246 52.4712 17.9724 55.0617C17.5103 56.3359 17.3563 58.9404 17.0483 64.1494C16.8942 66.8239 16.8942 68.0141 17.5103 68.3501C18.1265 68.6862 19.7088 67.384 20.1288 66.9499C22.0892 65.0315 22.0472 63.4072 23.3774 60.7888C24.7077 58.1703 25.1838 58.8704 27.6902 55.0897C29.0019 53.1873 30.0874 51.1384 30.9248 48.9846C31.849 46.5342 31.821 45.3019 32.4651 40.9751C32.842 37.4299 33.5117 33.922 34.4675 30.4872C34.8735 29.269 35.1676 29.157 36.8059 25.6003C38.0241 22.9679 38.6402 21.6516 38.9483 20.7835C40.0475 18.0963 40.5261 15.1957 40.3485 12.2979C40.1849 10.24 39.5986 8.2379 38.6262 6.41683C37.629 4.20795 35.8832 2.42254 33.6973 1.37591C32.228 0.80417 30.6478 0.574325 29.0765 0.703789C27.8042 0.702135 26.5455 0.964372 25.3798 1.47393C23.6995 2.27208 23.7135 3.05622 22.4533 3.33627C21.6551 3.5043 21.235 3.33627 18.7426 2.55213C16.5862 1.92201 15.494 1.59995 14.7378 1.47393C13.0993 1.2225 11.4286 1.27472 9.80894 1.62796C7.9407 2.08541 6.19892 2.9563 4.71201 4.17642Z" fill="#ADAFB8"/>
    </g>

    <g class="skolpol" v-if="status && status.id == 16">
      <path d="M2.62298 9.75156C2.44176 9.10664 2.18317 8.48602 1.85284 7.90322C1.53543 7.30688 1.08662 6.46755 0.605909 5.45533L6.94078 3.03033L13.1825 5.45533L19.7112 3.42764L25.0094 5.20577L36.2277 4.20855C35.8645 5.04044 35.3344 6.17979 34.5628 7.84721C32.9245 11.4039 32.6305 11.5159 32.2244 12.7341C31.2686 16.1688 30.5989 19.6768 30.222 23.222C29.5779 27.5488 29.6059 28.781 28.6817 31.2315C27.8444 33.3853 26.7589 35.4341 25.4472 37.3366C22.9407 41.1173 22.4646 40.4172 21.1344 43.0356C19.8041 45.6541 19.8461 47.2784 17.8858 49.1968C17.4657 49.6308 15.8834 50.9331 15.2673 50.597C14.6512 50.261 14.6512 49.0707 14.8052 46.3963C15.1133 41.1873 15.2673 38.5828 15.7294 37.3086C16.6815 34.7181 17.6057 34.3961 18.1938 31.5956C18.4328 30.3265 18.5361 29.0357 18.5019 27.7448C18.5579 25.6725 18.3619 25.1824 18.0398 24.8183C17.689 24.5018 17.2715 24.2683 16.8183 24.135C16.365 24.0017 15.8876 23.972 15.4213 24.0482C14.7665 24.1764 14.1436 24.4329 13.5884 24.803C13.0333 25.1731 12.5569 25.6494 12.1867 26.2046C8.79811 30.4753 8.18199 36.7205 8.18199 37.3086C7.94395 40.1091 8.23801 41.8594 7.71991 47.1104C7.46786 49.5468 7.27183 50.555 6.79574 50.653C5.63353 50.8211 3.70118 45.6681 2.94504 43.6518C1.24753 39.8525 0.251312 35.7775 0.00449969 31.6236C-0.0484651 28.3146 0.366379 25.0147 1.23673 21.8218C2.01242 19.2477 2.58363 16.6164 2.94504 13.9523C3.1343 12.545 3.02456 11.1136 2.62298 9.75156Z" fill="#DEE1E9"/>
      <path opacity="0.2" d="M6.45936 20.0295C7.0607 17.2743 7.16502 14.4338 6.76742 11.6419C5.89545 5.82885 3.76895 3.85921 4.33005 1.70853L8.71753 1L12.6469 2.4979L17.6894 1L21.8984 2.4979L25.6469 1.56521L30.6457 2.70853C30.8875 3.06817 31.0678 3.46129 31.1599 3.89853C31.552 5.9009 29.6896 6.68504 28.3594 10.2277C27.8112 12.6835 27.46 15.1793 27.3092 17.691C27.1461 20.1715 26.8375 22.6402 26.385 25.0844C25.7516 27.5839 24.8697 30.0138 23.7525 32.3377C22.7943 34.5021 21.7144 36.6105 20.5179 38.6529C19.9298 39.7451 16.233 49.4401 15.757 49.2021C15.6309 49.1461 17.0313 43.0357 17.4374 41.1173L17.5001 40.8146C17.9481 38.6505 18.174 37.5594 18.3615 36.9166C19.2717 33.864 19.8458 33.752 20.672 31.0635C21.1214 29.4718 21.4122 27.8396 21.5401 26.1906C21.9742 22.704 22.1842 20.9676 21.5401 19.1053L21.5396 19.1038C21.217 18.164 20.2085 15.2263 17.6894 14.6385C16.8526 14.5234 16.0006 14.6024 15.1993 14.8696C14.398 15.1367 13.6689 15.5846 13.0686 16.1788C11.6403 17.551 11.8783 18.7692 10.9122 21.4157C9.32988 25.7285 7.67758 25.2944 6.27733 29.2852C5.65295 31.547 5.28655 33.8722 5.18513 36.2164C4.73705 40.5152 6.52938 48.7021 6.27733 48.7021C5.5632 48.7721 0.690309 33.4439 3.65885 26.1906C4.65303 23.8382 5.68922 23.096 6.45936 20.0295Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M4.97505 8.43184C15.8157 11.7838 21.6991 11.191 31.9269 6.57201" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="skoltop" v-if="status && status.id == 17">
      <path d="M5.52817 4.50772C3.68873 5.9568 2.2997 7.89949 1.52344 10.1087C-0.366904 16.1438 3.52581 23.3832 4.91206 25.9876C5.24239 26.5704 5.50099 27.1911 5.6822 27.836C6.08378 29.198 6.19353 30.6294 6.00426 32.0367C5.64285 34.7008 5.07164 37.3321 4.29595 39.9062C3.4256 43.0991 3.01076 46.399 3.06372 49.708C3.31054 53.8619 4.30676 57.9369 6.00426 61.7362C6.7604 63.7525 8.69275 68.9055 9.85497 68.7374C10.3311 68.6394 10.5271 67.6312 10.7791 65.1948C11.2972 59.9438 11.0032 58.1935 11.2412 55.393C11.2412 54.8049 11.8573 48.5598 15.246 44.289C15.6161 43.7339 16.0925 43.2575 16.6477 42.8874C17.2028 42.5173 17.8258 42.2608 18.4805 42.1326C18.9468 42.0564 19.4242 42.0861 19.8775 42.2194C20.3308 42.3527 20.7482 42.5862 21.099 42.9027C21.4211 43.2668 21.6171 43.7569 21.5611 45.8293C21.5953 47.1201 21.492 48.411 21.253 49.68C20.6649 52.4805 19.7408 52.8025 18.7886 55.393C18.3265 56.6672 18.1725 59.2717 17.8644 64.4807C17.7104 67.1552 17.7104 68.3454 18.3265 68.6814C18.9426 69.0175 20.5249 67.7153 20.945 67.2812C22.9053 65.3628 22.8633 63.7385 24.1936 61.1201C25.5238 58.5016 25.9999 59.2017 28.5064 55.421C29.8181 53.5186 30.9036 51.4697 31.741 49.3159C32.6651 46.8655 32.6371 45.6332 33.2812 41.3064C33.6581 37.7612 34.3279 34.2533 35.2836 30.8185C35.6897 29.6003 35.9837 29.4883 37.622 25.9316C38.8403 23.2992 39.4564 21.9829 39.7644 21.1148C40.8636 18.4276 35.4612 18.1908 35.2836 15.293C35.12 13.235 31.9724 12.114 31 10.293C30.0028 8.08409 36.6994 2.75384 34.5135 1.70721C33.0441 1.13547 31.464 0.905624 29.8926 1.03509C28.6204 1.03343 27.3617 1.29567 26.1959 1.80523C24.5156 2.60337 24.5296 3.38752 23.2694 3.66757C22.4713 3.8356 22.0512 3.66757 19.5587 2.88343C17.4023 2.25331 16.3101 1.93125 15.554 1.80523C13.9155 1.5538 12.2447 1.60601 10.6251 1.95926C8.75686 2.41671 7.01509 3.2876 5.52817 4.50772Z" fill="#DEE1E9"/>
      <path d="M38.1698 24.7974C39.052 22.879 39.5281 21.8289 39.7941 21.0867C40.5705 19.2256 41.0477 17.2593 41.2134 15.2604H40.5H36.5929L37.5 12.6152L36.2924 10.1483L37.5 7.83487L33.6909 6.32013L35.0296 3.28516L35.8086 2.39453C35.3887 2.12085 34.9473 1.87679 34.4871 1.66517C33.0151 1.10564 31.4335 0.894751 29.8663 1.04906C28.5934 1.04073 27.3333 1.30326 26.1696 1.8192C24.5033 2.61735 24.5033 3.38749 23.2431 3.66754C22.4449 3.84957 22.0249 3.62553 19.5324 2.89739C18.2181 2.46555 16.8812 2.10563 15.5277 1.8192C13.8896 1.56024 12.2174 1.6125 10.5988 1.97323C8.73981 2.39951 7.00184 3.24216 5.51584 4.43768C3.65546 5.91078 2.25999 7.88971 1.49711 10.1367C-0.0991808 15.4157 2.64532 21.5908 4.29762 24.7974C5.44427 25.8752 6.92842 26.5233 8.49839 26.6317C10.8088 26.6317 17.8941 25.7216 17.8941 25.7216C17.8941 25.7216 22.991 24.1953 25.4555 25.7216C27.9199 27.2478 31.9247 26.6317 31.9247 26.6317L38.1698 24.7974Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M37.6758 23.8375C27.5767 19.8424 25.2637 15.3495 26.4195 3.79298" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path opacity="0.2" d="M7.67023 19.0844C6.35399 21.5629 8.86045 23.2852 9.82662 29.7264C10.2242 32.5183 10.1199 35.3587 9.51857 38.1139C8.74843 41.1805 7.71224 41.9226 6.71805 44.275C3.74951 51.5284 8.62241 66.8565 9.33654 66.7865C9.58858 66.7865 7.79625 58.5997 8.24433 54.3009C8.34576 51.9566 8.71216 49.6314 9.33653 47.3696C10.7368 43.3789 12.3891 43.813 13.9714 39.5002C14.9376 36.8537 14.6995 35.6355 16.1278 34.2632C16.7281 33.6691 17.4572 33.2211 18.2585 32.954C19.0598 32.6869 19.9118 32.6078 20.7486 32.7229C23.2691 33.311 24.2773 36.2516 24.5993 37.1897C25.2434 39.0521 25.0334 40.7884 24.5993 44.275C24.4714 45.924 24.1806 47.5563 23.7312 49.1479C22.905 51.8364 22.3309 51.9484 21.4207 55.001C21.2247 55.6731 20.9867 56.8353 20.4966 59.2018C20.0905 61.1201 18.6901 67.2305 18.8162 67.2865C19.2922 67.5245 22.989 57.8295 23.5771 56.7373C24.7736 54.6949 25.8535 52.5865 26.8117 50.4222C27.9289 48.0982 28.8108 45.6683 29.4442 43.1688C29.8967 40.7246 30.2053 38.2559 30.3684 35.7755C30.5192 33.2637 30.8704 30.768 31.4186 28.3121C32.7488 24.7695 34.6112 23.9853 34.2191 21.983C33.883 20.3867 32.3707 19.3785 30.9845 18.4403C29.6804 17.3386 28.054 16.69 26.3496 16.592C24.5433 16.592 24.0252 17.6422 22.0369 18.2863C20.0101 18.8204 17.8732 18.767 15.8757 18.1323C13.0752 17.4461 12.4451 16.3959 10.7788 16.732C10.124 16.8826 9.50847 17.17 8.9727 17.5755C8.43692 17.9809 7.99304 18.4952 7.67023 19.0844Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metal" v-if="status && status.id == 18">
      <path d="M5.52817 4.96512C3.68873 6.4142 2.2997 8.35689 1.52344 10.5661C-0.366904 16.6012 3.52581 23.8406 4.91206 26.445C5.24239 27.0278 5.50099 27.6485 5.6822 28.2934C6.08378 29.6554 6.19353 31.0868 6.00426 32.4941C5.64285 35.1582 5.07164 37.7895 4.29595 40.3636C3.4256 43.5565 3.01076 46.8564 3.06372 50.1654C3.31054 54.3193 4.30676 58.3943 6.00426 62.1936C6.7604 64.2099 8.69275 69.3629 9.85497 69.1948C10.3311 69.0968 10.5271 68.0886 10.7791 65.6522C11.2972 60.4012 11.0032 58.6509 11.2412 55.8504C11.2412 55.2623 11.8573 49.0172 15.246 44.7464C15.6161 44.1913 16.0925 43.7149 16.6477 43.3448C17.2028 42.9747 17.8258 42.7182 18.4805 42.59C18.9468 42.5138 19.4242 42.5435 19.8775 42.6768C20.3308 42.8101 20.7482 43.0436 21.099 43.3601C21.4211 43.7242 21.6171 44.2143 21.5611 46.2867C21.5953 47.5775 21.492 48.8684 21.253 50.1374C20.6649 52.9379 19.7408 53.2599 18.7886 55.8504C18.3265 57.1246 18.1725 59.7291 17.8644 64.9381C17.7104 67.6126 17.7104 68.8028 18.3265 69.1388C18.9426 69.4749 20.5249 68.1727 20.945 67.7386C22.9053 65.8202 22.8633 64.1959 24.1936 61.5775C25.5238 58.959 25.9999 59.6591 28.5064 55.8784C29.8181 53.9759 30.9036 51.9271 31.741 49.7733C32.6651 47.3229 32.6371 46.0906 33.2812 41.7638C33.6581 38.2186 34.3279 34.7106 35.2836 31.2759C35.6897 30.0577 35.9837 29.9457 37.622 26.389C38.8403 23.7565 39.4564 22.4403 39.7644 21.5722C40.8636 18.885 41.3423 15.9844 41.1647 13.0866C41.0011 11.0287 40.4148 9.02659 39.4424 7.20553C38.4451 4.99665 36.6994 3.21123 34.5135 2.16461C33.0441 1.59287 31.464 1.36302 29.8926 1.49249C28.6204 1.49083 27.3617 1.75307 26.1959 2.26263C24.5156 3.06077 24.5296 3.84492 23.2694 4.12497C22.4713 4.293 22.0512 4.12497 19.5587 3.34082C17.4023 2.71071 16.3101 2.38865 15.554 2.26263C13.9155 2.0112 12.2447 2.06341 10.6251 2.41665C8.75686 2.87411 7.01509 3.745 5.52817 4.96512Z" fill="#DEE1E9"/>
      <path d="M38.1698 25.2548C39.052 23.3364 39.5281 22.2863 39.7941 21.5441C40.9113 18.866 41.4091 15.9701 41.2504 13.0726C41.0828 11.0234 40.5067 9.0285 39.5561 7.20549C38.5302 4.95847 36.7313 3.15466 34.4871 2.12257C33.0151 1.56304 31.4335 1.35215 29.8663 1.50645C28.5934 1.49813 27.3333 1.76066 26.1696 2.2766C24.5033 3.07474 24.5033 3.84488 23.2431 4.12494C22.4449 4.30697 22.0249 4.08292 19.5324 3.35479C18.2181 2.92295 16.8812 2.56302 15.5277 2.2766C13.8896 2.01764 12.2174 2.06989 10.5988 2.43063C8.73981 2.85691 7.00184 3.69956 5.51584 4.89508C3.65546 6.36817 2.25999 8.34711 1.49711 10.5941C-0.0991808 15.8731 2.64532 22.0482 4.29762 25.2548C5.44427 26.3326 6.92842 26.9807 8.49839 27.0891C9.39826 27.0891 12.7802 26.952 14.6478 26.7835C14.8758 26.7629 15.0759 26.6478 15.1361 26.4269C15.5868 24.7738 16 17.7494 16 17.7494C20.6864 16.7692 22.3135 16.6027 27 17.7494L28.3375 25.7746C28.4276 26.3148 28.9354 26.6821 29.4767 26.5984L38.1698 25.2548Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-plastic" v-if="status && status.id == 19">
      <path d="M5.52817 4.71573C3.68873 6.16481 2.2997 8.1075 1.52344 10.3168C-0.366904 16.3519 3.52581 23.5912 4.91206 26.1957C5.24239 26.7784 5.50099 27.3991 5.6822 28.044C6.08378 29.406 6.19353 30.8374 6.00426 32.2448C5.64285 34.9088 5.07164 37.5401 4.29595 40.1142C3.4256 43.3071 3.01076 46.607 3.06372 49.916C3.31054 54.0699 4.30676 58.1449 6.00426 61.9442C6.7604 63.9605 8.69275 69.1135 9.85497 68.9455C10.3311 68.8474 10.5271 67.8393 10.7791 65.4028C11.2972 60.1519 11.0032 58.4015 11.2412 55.601C11.2412 55.0129 11.8573 48.7678 15.246 44.497C15.6161 43.9419 16.0925 43.4655 16.6477 43.0954C17.2028 42.7253 17.8258 42.4688 18.4805 42.3406C18.9468 42.2644 19.4242 42.2941 19.8775 42.4274C20.3308 42.5607 20.7482 42.7943 21.099 43.1107C21.4211 43.4748 21.6171 43.9649 21.5611 46.0373C21.5953 47.3281 21.492 48.619 21.253 49.888C20.6649 52.6885 19.7408 53.0105 18.7886 55.601C18.3265 56.8753 18.1725 59.4797 17.8644 64.6887C17.7104 67.3632 17.7104 68.5534 18.3265 68.8895C18.9426 69.2255 20.5249 67.9233 20.945 67.4892C22.9053 65.5708 22.8633 63.9465 24.1936 61.3281C25.5238 58.7096 25.9999 59.4097 28.5064 55.629C29.8181 53.7266 30.9036 51.6777 31.741 49.5239C32.6651 47.0735 32.6371 45.8412 33.2812 41.5145C33.6581 37.9692 34.3279 34.4613 35.2836 31.0265C35.6897 29.8083 35.9837 29.6963 37.622 26.1396C38.8403 23.5072 39.4564 22.1909 39.7644 21.3228C40.8636 18.6356 41.3423 15.7351 41.1647 12.8372C41.0011 10.7793 40.4148 8.7772 39.4424 6.95614C38.4451 4.74726 36.6994 2.96184 34.5135 1.91522C33.0441 1.34348 31.464 1.11363 29.8926 1.2431C28.6204 1.24144 27.3617 1.50368 26.1959 2.01324C24.5156 2.81138 24.5296 3.59553 23.2694 3.87558C22.4713 4.04361 22.0512 3.87558 19.5587 3.09143C17.4023 2.46132 16.3101 2.13926 15.554 2.01324C13.9155 1.76181 12.2447 1.81402 10.6251 2.16726C8.75686 2.62472 7.01509 3.49561 5.52817 4.71573Z" fill="#DEE1E9"/>
      <path d="M38.1698 25.0054C39.052 23.0871 39.5281 22.0369 39.7941 21.2947C40.9113 18.6166 41.4091 15.7207 41.2504 12.8232C41.0828 10.774 40.5067 8.77911 39.5561 6.9561C38.5302 4.70908 36.7313 2.90527 34.4871 1.87318C33.0151 1.31365 31.4335 1.10276 29.8663 1.25706C28.5934 1.24874 27.3333 1.51127 26.1696 2.02721C24.5033 2.82535 24.5033 3.59549 23.2431 3.87555C22.4449 4.05758 22.0249 3.83353 19.5324 3.1054C18.2181 2.67356 16.8812 2.31363 15.5277 2.02721C13.8896 1.76825 12.2174 1.82051 10.5988 2.18124C8.73981 2.60752 7.00184 3.45017 5.51584 4.64569C3.65546 6.11878 2.25999 8.09772 1.49711 10.3447C-0.0991808 15.6237 2.64532 21.7988 4.29762 25.0054C5.44427 26.0832 6.92842 26.7313 8.49839 26.8397C9.39826 26.8397 12.7802 26.7026 14.6478 26.5341C14.8758 26.5135 15.0759 26.3984 15.1361 26.1775C15.5868 24.5244 16 17.5 16 17.5C20.6864 16.5198 22.3135 16.3533 27 17.5L28.3375 25.5252C28.4276 26.0654 28.9354 26.4327 29.4767 26.349L38.1698 25.0054Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

    <g class="ceramic-metalplastic" v-if="status && status.id == 20">
      <path d="M5.52817 4.71573C3.68873 6.16481 2.2997 8.1075 1.52344 10.3168C-0.366904 16.3519 3.52581 23.5912 4.91206 26.1957C5.24239 26.7784 5.50099 27.3991 5.6822 28.044C6.08378 29.406 6.19353 30.8374 6.00426 32.2448C5.64285 34.9088 5.07164 37.5401 4.29595 40.1142C3.4256 43.3071 3.01076 46.607 3.06372 49.916C3.31054 54.0699 4.30676 58.1449 6.00426 61.9442C6.7604 63.9605 8.69275 69.1135 9.85497 68.9455C10.3311 68.8474 10.5271 67.8393 10.7791 65.4028C11.2972 60.1519 11.0032 58.4015 11.2412 55.601C11.2412 55.0129 11.8573 48.7678 15.246 44.497C15.6161 43.9419 16.0925 43.4655 16.6477 43.0954C17.2028 42.7253 17.8258 42.4688 18.4805 42.3406C18.9468 42.2644 19.4242 42.2941 19.8775 42.4274C20.3308 42.5607 20.7482 42.7943 21.099 43.1107C21.4211 43.4748 21.6171 43.9649 21.5611 46.0373C21.5953 47.3281 21.492 48.619 21.253 49.888C20.6649 52.6885 19.7408 53.0105 18.7886 55.601C18.3265 56.8753 18.1725 59.4797 17.8644 64.6887C17.7104 67.3632 17.7104 68.5534 18.3265 68.8895C18.9426 69.2255 20.5249 67.9233 20.945 67.4892C22.9053 65.5708 22.8633 63.9465 24.1936 61.3281C25.5238 58.7096 25.9999 59.4097 28.5064 55.629C29.8181 53.7266 30.9036 51.6777 31.741 49.5239C32.6651 47.0735 32.6371 45.8412 33.2812 41.5145C33.6581 37.9692 34.3279 34.4613 35.2836 31.0265C35.6897 29.8083 35.9837 29.6963 37.622 26.1396C38.8403 23.5072 39.4564 22.1909 39.7644 21.3228C40.8636 18.6356 41.3423 15.7351 41.1647 12.8372C41.0011 10.7793 40.4148 8.7772 39.4424 6.95614C38.4451 4.74726 36.6994 2.96184 34.5135 1.91522C33.0441 1.34348 31.464 1.11363 29.8926 1.2431C28.6204 1.24144 27.3617 1.50368 26.1959 2.01324C24.5156 2.81138 24.5296 3.59553 23.2694 3.87558C22.4713 4.04361 22.0512 3.87558 19.5587 3.09143C17.4023 2.46132 16.3101 2.13926 15.554 2.01324C13.9155 1.76181 12.2447 1.81402 10.6251 2.16726C8.75686 2.62472 7.01509 3.49561 5.52817 4.71573Z" fill="#DEE1E9"/>
      <mask id="mask2047" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M38.1544 24.7974C39.0366 22.879 39.5127 21.8289 39.7787 21.0867C40.8959 18.4086 41.3937 15.5127 41.235 12.6152C41.0675 10.566 40.4914 8.57111 39.5407 6.7481C38.5148 4.50107 36.7159 2.69726 34.4718 1.66517C32.9997 1.10564 31.4181 0.894751 29.8509 1.04906C28.578 1.04073 27.3179 1.30326 26.1542 1.8192C24.4879 2.61735 24.4879 3.38749 23.2277 3.66754C22.4296 3.84957 22.0095 3.62553 19.517 2.89739C18.2027 2.46555 16.8658 2.10563 15.5123 1.8192C13.8743 1.56024 12.2021 1.6125 10.5834 1.97323C8.72443 2.39951 6.98646 3.24216 5.50046 4.43768C3.64008 5.91078 2.24461 7.88971 1.48173 10.1367C-0.114562 15.4157 2.62994 21.5908 4.28224 24.7974C5.42889 25.8752 6.91304 26.5233 8.48301 26.6317C9.38287 26.6317 12.7648 26.4946 14.6324 26.3261C14.8604 26.3055 15.0605 26.1904 15.1207 25.9695C15.5715 24.3164 15.9846 17.292 15.9846 17.292C20.671 16.3118 22.2981 16.1453 26.9846 17.292L28.3221 25.3172C28.4122 25.8574 28.92 26.2247 29.4613 26.141L38.1544 24.7974Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2047)">
        <path d="M38.1544 24.7974C39.0366 22.879 39.5127 21.8289 39.7787 21.0867C40.8959 18.4086 41.3937 15.5127 41.235 12.6152C41.0675 10.566 40.4914 8.57111 39.5407 6.7481C38.5148 4.50107 36.7159 2.69726 34.4718 1.66517C32.9997 1.10564 31.4181 0.894751 29.8509 1.04906C28.578 1.04073 27.3179 1.30326 26.1542 1.8192C24.4879 2.61735 24.4879 3.38749 23.2277 3.66754C22.4296 3.84957 22.0095 3.62553 19.517 2.89739C18.2027 2.46555 16.8658 2.10563 15.5123 1.8192C13.8743 1.56024 12.2021 1.6125 10.5834 1.97323C8.72443 2.39951 6.98646 3.24216 5.50046 4.43768C3.64008 5.91078 2.24461 7.88971 1.48173 10.1367C-0.114562 15.4157 2.62994 21.5908 4.28224 24.7974C5.42889 25.8752 6.91304 26.5233 8.48301 26.6317C9.38287 26.6317 12.7648 26.4946 14.6324 26.3261C14.8604 26.3055 15.0605 26.1904 15.1207 25.9695C15.5715 24.3164 15.9846 17.292 15.9846 17.292C20.671 16.3118 22.2981 16.1453 26.9846 17.292L28.3221 25.3172C28.4122 25.8574 28.92 26.2247 29.4613 26.141L38.1544 24.7974Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M34 40H10V14.452C19.3726 12.5258 24.6274 12.5062 34 14.452V40Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="broken" v-if="status && status.id == 21">
      <path d="M5.69181 4.1547C3.85236 5.60377 2.46334 7.54646 1.68708 9.75572C-0.203268 15.7908 3.68944 23.0301 5.0757 25.6346C5.40603 26.2174 5.66462 26.838 5.84584 27.483C6.24742 28.845 6.35716 30.2764 6.1679 31.6837C5.80649 34.3477 5.23528 36.9791 4.45959 39.5532C3.58924 42.7461 3.17439 46.046 3.22736 49.3549C3.47417 53.5089 4.47039 57.5839 6.1679 61.3831C6.92404 63.3995 8.85639 68.5525 10.0186 68.3844C10.4947 68.2864 10.6907 67.2782 10.9428 64.8418C11.4609 59.5908 11.1668 57.8405 11.4049 55.04C11.4049 54.4519 12.021 48.2067 15.4096 43.936C15.7797 43.3808 16.2561 42.9045 16.8113 42.5344C17.3665 42.1643 17.9894 41.9077 18.6442 41.7796C19.1105 41.7034 19.5879 41.7331 20.0411 41.8664C20.4944 41.9997 20.9119 42.2332 21.2627 42.5497C21.5847 42.9138 21.7808 43.4039 21.7247 45.4762C21.7589 46.7671 21.6557 48.0579 21.4167 49.3269C20.8286 52.1275 19.9044 52.4495 18.9522 55.04C18.4901 56.3142 18.3361 58.9187 18.0281 64.1276C17.874 66.8021 17.874 67.9924 18.4901 68.3284C19.1063 68.6645 20.6886 67.3622 21.1086 66.9282C23.069 65.0098 23.027 63.3855 24.3572 60.767C25.6875 58.1486 26.1636 58.8487 28.67 55.068C29.9817 53.1655 31.0672 51.1167 31.9046 48.9629C32.8288 46.5124 32.8008 45.2802 33.4449 40.9534C33.8218 37.4082 34.4915 33.9002 35.4473 30.4655C35.8533 29.2473 36.1474 29.1353 37.7857 25.5786C39.0039 22.9461 39.62 21.6299 39.9281 20.7617C41.0273 18.0746 41.5059 15.174 41.3283 12.2762C41.1647 10.2182 40.5784 8.21617 39.606 6.3951C38.6088 4.18623 36.863 2.40081 34.6771 1.35418C33.2078 0.782442 31.6276 0.552597 30.0563 0.682061C28.784 0.680407 27.5253 0.942643 26.3596 1.4522C24.6793 2.25035 24.6933 3.03449 23.4331 3.31454C22.6349 3.48257 22.2148 3.31454 19.7224 2.5304C17.566 1.90028 16.4738 1.57822 15.7176 1.4522C14.0791 1.20078 12.4084 1.25299 10.7887 1.60623C8.92049 2.06368 7.17872 2.93457 5.69181 4.1547Z" fill="#DEE1E9"/>
      <path d="M39.9577 20.7337C39.6917 21.4758 39.2156 22.526 38.3334 24.4444L32.0883 26.2787C32.0883 26.2787 28.0836 26.8948 25.6191 25.3685C23.1547 23.8423 18.0577 25.3685 18.0577 25.3685C18.0577 25.3685 10.9724 26.2787 8.66202 26.2787C7.09206 26.1703 5.60791 25.5222 4.46126 24.4444C2.80896 21.2378 0.0644544 15.0627 1.66075 9.7837C2.42363 7.53669 3.8191 5.55775 5.67948 4.08466C7.16548 2.88913 8.90344 2.04648 10.7624 1.6202C12.3811 1.25947 14.0533 1.20721 15.6913 1.46617C17.0448 1.7526 18.3817 2.11253 19.696 2.54437C22.1885 3.2725 22.6086 3.49654 23.4067 3.31451C24.6669 3.03446 24.667 2.26432 26.3333 1.46617C27.4969 0.950234 28.757 0.687708 30.0299 0.696029C31.5971 0.541723 33.1788 0.752611 34.6508 1.31214C36.895 2.34424 38.6938 4.14804 39.7197 6.39507C40.6704 8.21808 41.2465 10.213 41.414 12.2621C41.5727 15.1596 41.0749 18.0555 39.9577 20.7337Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path opacity="0.2" d="M7.83386 18.7312C6.51762 21.2097 9.02408 22.932 9.99026 29.3732C10.3879 32.1651 10.2835 35.0055 9.6822 37.7607C8.91206 40.8273 7.87587 41.5694 6.88169 43.9218C3.91315 51.1752 8.78604 66.5033 9.50017 66.4333C9.75222 66.4333 7.95989 58.2464 8.40797 53.9477C8.50939 51.6034 8.87579 49.2782 9.50017 47.0164C10.9004 43.0257 12.5527 43.4597 14.135 39.147C15.1012 36.5005 14.8631 35.2822 16.2914 33.91C16.8918 33.3159 17.6208 32.8679 18.4221 32.6008C19.2234 32.3337 20.0755 32.2546 20.9123 32.3697C23.4327 32.9578 24.4409 35.8984 24.763 36.8365C25.4071 38.6989 25.197 40.4352 24.763 43.9218C24.6351 45.5708 24.3442 47.203 23.8948 48.7947C23.0686 51.4832 22.4945 51.5952 21.5844 54.6478C21.3883 55.3199 21.1503 56.4821 20.6602 58.8486C20.2541 60.7669 18.8538 66.8773 18.9798 66.9333C19.4559 67.1713 23.1527 57.4763 23.7408 56.3841C24.9372 54.3417 26.0171 52.2333 26.9754 50.069C28.0925 47.745 28.9744 45.3151 29.6078 42.8156C30.0603 40.3714 30.3689 37.9027 30.532 35.4223C30.6828 32.9105 31.034 30.4148 31.5822 27.9589C32.9125 24.4163 34.7748 23.6321 34.3827 21.6298C34.0467 20.0335 32.5344 19.0253 31.1481 18.0871C29.8441 16.9854 28.2176 16.3368 26.5133 16.2388C24.7069 16.2388 24.1889 17.289 22.2005 17.9331C20.1737 18.4672 18.0369 18.4138 16.0394 17.7791C13.2388 17.0929 12.6087 16.0427 10.9424 16.3788C10.2876 16.5294 9.6721 16.8168 9.13633 17.2223C8.60056 17.6277 8.15668 18.142 7.83386 18.7312Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <path d="M11.1224 57.1731C8.61396 59.2551 7.21322 59.1602 4.71967 57.1731" stroke="#404040" stroke-width="1.4" fill="none"/>
      <path d="M26.8944 57.1731C23.5996 59.2551 21.7597 59.1602 18.4843 57.1731" stroke="#404040" stroke-width="1.4" fill="none"/>
    </g>

    <g class="periodontit-obostr" v-if="status && status.id == 22">
      <path d="M5.52817 4.71475C3.68873 6.16383 2.2997 8.10652 1.52344 10.3158C-0.366904 16.3509 3.52581 23.5902 4.91206 26.1947C5.24239 26.7775 5.50099 27.3981 5.6822 28.043C6.08378 29.405 6.19353 30.8365 6.00426 32.2438C5.64285 34.9078 5.07164 37.5391 4.29595 40.1132C3.4256 43.3061 3.01076 46.606 3.06372 49.915C3.31054 54.0689 4.30676 58.1439 6.00426 61.9432C6.7604 63.9596 8.69275 69.1125 9.85497 68.9445C10.3311 68.8465 10.5271 67.8383 10.7791 65.4018C11.2972 60.1509 11.0032 58.4006 11.2412 55.6C11.2412 55.0119 11.8573 48.7668 15.246 44.496C15.6161 43.9409 16.0925 43.4645 16.6477 43.0944C17.2028 42.7243 17.8258 42.4678 18.4805 42.3396C18.9468 42.2635 19.4242 42.2931 19.8775 42.4264C20.3308 42.5597 20.7482 42.7933 21.099 43.1098C21.4211 43.4738 21.6171 43.9639 21.5611 46.0363C21.5953 47.3272 21.492 48.618 21.253 49.887C20.6649 52.6875 19.7408 53.0096 18.7886 55.6C18.3265 56.8743 18.1725 59.4788 17.8644 64.6877C17.7104 67.3622 17.7104 68.5524 18.3265 68.8885C18.9426 69.2245 20.5249 67.9223 20.945 67.4882C22.9053 65.5699 22.8633 63.9456 24.1936 61.3271C25.5238 58.7086 25.9999 59.4087 28.5064 55.6281C29.8181 53.7256 30.9036 51.6767 31.741 49.5229C32.6651 47.0725 32.6371 45.8403 33.2812 41.5135C33.6581 37.9682 34.3279 34.4603 35.2836 31.0256C35.6897 29.8073 35.9837 29.6953 37.622 26.1387C38.8403 23.5062 39.4564 22.1899 39.7644 21.3218C40.8636 18.6346 41.3423 15.7341 41.1647 12.8362C41.0011 10.7783 40.4148 8.77623 39.4424 6.95516C38.4451 4.74628 36.6994 2.96087 34.5135 1.91424C33.0441 1.3425 31.464 1.11266 29.8926 1.24212C28.6204 1.24047 27.3617 1.5027 26.1959 2.01226C24.5156 2.81041 24.5296 3.59455 23.2694 3.8746C22.4713 4.04263 22.0512 3.8746 19.5587 3.09046C17.4023 2.46034 16.3101 2.13828 15.554 2.01226C13.9155 1.76083 12.2447 1.81305 10.6251 2.16629C8.75686 2.62374 7.01509 3.49463 5.52817 4.71475Z" fill="#DEE1E9"/>
      <mask id="mask2247" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2247)">
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.6923 19C22.7544 19 24 13.7018 24 4.10345L22.0769 2.24138L19 1C19 10.5983 20.6302 19 21.6923 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.67023 19.2915C6.35399 21.77 8.86045 23.4923 9.82662 29.9335C10.2242 32.7254 10.1199 35.5658 9.51857 38.321C8.74843 41.3876 7.71224 42.1297 6.71805 44.4821C3.74951 51.7355 8.62241 67.0636 9.33654 66.9936C9.58858 66.9936 7.79625 58.8067 8.24433 54.508C8.34576 52.1637 8.71216 49.8385 9.33653 47.5767C10.7368 43.586 12.3891 44.02 13.9714 39.7073C14.9376 37.0608 14.6995 35.8426 16.1278 34.4703C16.7281 33.8762 17.4572 33.4282 18.2585 33.1611C19.0598 32.894 19.9118 32.8149 20.7486 32.93C23.2691 33.5181 24.2773 36.4587 24.5993 37.3968C25.2434 39.2592 25.0334 40.9955 24.5993 44.4821C24.4714 46.1311 24.1806 47.7633 23.7312 49.355C22.905 52.0435 22.3309 52.1555 21.4207 55.2081C21.2247 55.8802 20.9867 57.0424 20.4966 59.4089C20.0905 61.3272 18.6901 67.4376 18.8162 67.4936C19.2922 67.7316 22.989 58.0366 23.5771 56.9444C24.7736 54.902 25.8535 52.7936 26.8117 50.6293C27.9289 48.3053 28.8108 45.8754 29.4442 43.3759C29.8967 40.9317 30.2053 38.463 30.3684 35.9826C30.5192 33.4708 30.8704 30.9751 31.4186 28.5192C32.7488 24.9766 34.6112 24.1924 34.2191 22.1901C33.883 20.5938 32.3707 19.5856 30.9845 18.6474C29.6804 17.5457 28.054 16.8971 26.3496 16.7991C24.5433 16.7991 24.0252 17.8493 22.0369 18.4934C20.0101 19.0275 17.8732 18.9741 15.8757 18.3394C13.0752 17.6532 12.4451 16.603 10.7788 16.9391C10.124 17.0897 9.50847 17.3771 8.9727 17.7826C8.43692 18.188 7.99304 18.7023 7.67023 19.2915Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="9" cy="67" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="19" cy="67" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-granulir" v-if="status && status.id == 23">
      <path d="M5.52817 4.71475C3.68873 6.16383 2.2997 8.10652 1.52344 10.3158C-0.366904 16.3509 3.52581 23.5902 4.91206 26.1947C5.24239 26.7775 5.50099 27.3981 5.6822 28.043C6.08378 29.405 6.19353 30.8365 6.00426 32.2438C5.64285 34.9078 5.07164 37.5391 4.29595 40.1132C3.4256 43.3061 3.01076 46.606 3.06372 49.915C3.31054 54.0689 4.30676 58.1439 6.00426 61.9432C6.7604 63.9596 8.69275 69.1125 9.85497 68.9445C10.3311 68.8465 10.5271 67.8383 10.7791 65.4018C11.2972 60.1509 11.0032 58.4006 11.2412 55.6C11.2412 55.0119 11.8573 48.7668 15.246 44.496C15.6161 43.9409 16.0925 43.4645 16.6477 43.0944C17.2028 42.7243 17.8258 42.4678 18.4805 42.3396C18.9468 42.2635 19.4242 42.2931 19.8775 42.4264C20.3308 42.5597 20.7482 42.7933 21.099 43.1098C21.4211 43.4738 21.6171 43.9639 21.5611 46.0363C21.5953 47.3272 21.492 48.618 21.253 49.887C20.6649 52.6875 19.7408 53.0096 18.7886 55.6C18.3265 56.8743 18.1725 59.4788 17.8644 64.6877C17.7104 67.3622 17.7104 68.5524 18.3265 68.8885C18.9426 69.2245 20.5249 67.9223 20.945 67.4882C22.9053 65.5699 22.8633 63.9456 24.1936 61.3271C25.5238 58.7086 25.9999 59.4087 28.5064 55.6281C29.8181 53.7256 30.9036 51.6767 31.741 49.5229C32.6651 47.0725 32.6371 45.8403 33.2812 41.5135C33.6581 37.9682 34.3279 34.4603 35.2836 31.0256C35.6897 29.8073 35.9837 29.6953 37.622 26.1387C38.8403 23.5062 39.4564 22.1899 39.7644 21.3218C40.8636 18.6346 41.3423 15.7341 41.1647 12.8362C41.0011 10.7783 40.4148 8.77623 39.4424 6.95516C38.4451 4.74628 36.6994 2.96087 34.5135 1.91424C33.0441 1.3425 31.464 1.11266 29.8926 1.24212C28.6204 1.24047 27.3617 1.5027 26.1959 2.01226C24.5156 2.81041 24.5296 3.59455 23.2694 3.8746C22.4713 4.04263 22.0512 3.8746 19.5587 3.09046C17.4023 2.46034 16.3101 2.13828 15.554 2.01226C13.9155 1.76083 12.2447 1.81305 10.6251 2.16629C8.75686 2.62374 7.01509 3.49463 5.52817 4.71475Z" fill="#DEE1E9"/>
      <mask id="mask2347" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2347)">
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.6923 19C22.7544 19 24 13.7018 24 4.10345L22.0769 2.24138L19 1C19 10.5983 20.6302 19 21.6923 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.67023 19.2915C6.35399 21.77 8.86045 23.4923 9.82662 29.9335C10.2242 32.7254 10.1199 35.5658 9.51857 38.321C8.74843 41.3876 7.71224 42.1297 6.71805 44.4821C3.74951 51.7355 8.62241 67.0636 9.33654 66.9936C9.58858 66.9936 7.79625 58.8067 8.24433 54.508C8.34576 52.1637 8.71216 49.8385 9.33653 47.5767C10.7368 43.586 12.3891 44.02 13.9714 39.7073C14.9376 37.0608 14.6995 35.8426 16.1278 34.4703C16.7281 33.8762 17.4572 33.4282 18.2585 33.1611C19.0598 32.894 19.9118 32.8149 20.7486 32.93C23.2691 33.5181 24.2773 36.4587 24.5993 37.3968C25.2434 39.2592 25.0334 40.9955 24.5993 44.4821C24.4714 46.1311 24.1806 47.7633 23.7312 49.355C22.905 52.0435 22.3309 52.1555 21.4207 55.2081C21.2247 55.8802 20.9867 57.0424 20.4966 59.4089C20.0905 61.3272 18.6901 67.4376 18.8162 67.4936C19.2922 67.7316 22.989 58.0366 23.5771 56.9444C24.7736 54.902 25.8535 52.7936 26.8117 50.6293C27.9289 48.3053 28.8108 45.8754 29.4442 43.3759C29.8967 40.9317 30.2053 38.463 30.3684 35.9826C30.5192 33.4708 30.8704 30.9751 31.4186 28.5192C32.7488 24.9766 34.6112 24.1924 34.2191 22.1901C33.883 20.5938 32.3707 19.5856 30.9845 18.6474C29.6804 17.5457 28.054 16.8971 26.3496 16.7991C24.5433 16.7991 24.0252 17.8493 22.0369 18.4934C20.0101 19.0275 17.8732 18.9741 15.8757 18.3394C13.0752 17.6532 12.4451 16.603 10.7788 16.9391C10.124 17.0897 9.50847 17.3771 8.9727 17.7826C8.43692 18.188 7.99304 18.7023 7.67023 19.2915Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="9" cy="67" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="19" cy="67" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2347)">
        <path d="M19 64L19.8229 67.7391L22.8567 65.4037L21.0837 68.797L24.9088 68.9581L21.3694 70.4178L24.1962 73L20.5465 71.8431L21.0521 75.6382L19 72.406L16.9479 75.6382L17.4535 71.8431L13.8038 73L16.6306 70.4178L13.0912 68.9581L16.9163 68.797L15.1433 65.4037L18.1771 67.7391L19 64Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2347" x="12.8911" y="63.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-granulematoz" v-if="status && status.id == 24">
      <path d="M5.52817 4.71475C3.68873 6.16383 2.2997 8.10652 1.52344 10.3158C-0.366904 16.3509 3.52581 23.5902 4.91206 26.1947C5.24239 26.7775 5.50099 27.3981 5.6822 28.043C6.08378 29.405 6.19353 30.8365 6.00426 32.2438C5.64285 34.9078 5.07164 37.5391 4.29595 40.1132C3.4256 43.3061 3.01076 46.606 3.06372 49.915C3.31054 54.0689 4.30676 58.1439 6.00426 61.9432C6.7604 63.9596 8.69275 69.1125 9.85497 68.9445C10.3311 68.8465 10.5271 67.8383 10.7791 65.4018C11.2972 60.1509 11.0032 58.4006 11.2412 55.6C11.2412 55.0119 11.8573 48.7668 15.246 44.496C15.6161 43.9409 16.0925 43.4645 16.6477 43.0944C17.2028 42.7243 17.8258 42.4678 18.4805 42.3396C18.9468 42.2635 19.4242 42.2931 19.8775 42.4264C20.3308 42.5597 20.7482 42.7933 21.099 43.1098C21.4211 43.4738 21.6171 43.9639 21.5611 46.0363C21.5953 47.3272 21.492 48.618 21.253 49.887C20.6649 52.6875 19.7408 53.0096 18.7886 55.6C18.3265 56.8743 18.1725 59.4788 17.8644 64.6877C17.7104 67.3622 17.7104 68.5524 18.3265 68.8885C18.9426 69.2245 20.5249 67.9223 20.945 67.4882C22.9053 65.5699 22.8633 63.9456 24.1936 61.3271C25.5238 58.7086 25.9999 59.4087 28.5064 55.6281C29.8181 53.7256 30.9036 51.6767 31.741 49.5229C32.6651 47.0725 32.6371 45.8403 33.2812 41.5135C33.6581 37.9682 34.3279 34.4603 35.2836 31.0256C35.6897 29.8073 35.9837 29.6953 37.622 26.1387C38.8403 23.5062 39.4564 22.1899 39.7644 21.3218C40.8636 18.6346 41.3423 15.7341 41.1647 12.8362C41.0011 10.7783 40.4148 8.77623 39.4424 6.95516C38.4451 4.74628 36.6994 2.96087 34.5135 1.91424C33.0441 1.3425 31.464 1.11266 29.8926 1.24212C28.6204 1.24047 27.3617 1.5027 26.1959 2.01226C24.5156 2.81041 24.5296 3.59455 23.2694 3.8746C22.4713 4.04263 22.0512 3.8746 19.5587 3.09046C17.4023 2.46034 16.3101 2.13828 15.554 2.01226C13.9155 1.76083 12.2447 1.81305 10.6251 2.16629C8.75686 2.62374 7.01509 3.49463 5.52817 4.71475Z" fill="#DEE1E9"/>
      <mask id="mask2347" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2347)">
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.6923 19C22.7544 19 24 13.7018 24 4.10345L22.0769 2.24138L19 1C19 10.5983 20.6302 19 21.6923 19Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.67023 19.2915C6.35399 21.77 8.86045 23.4923 9.82662 29.9335C10.2242 32.7254 10.1199 35.5658 9.51857 38.321C8.74843 41.3876 7.71224 42.1297 6.71805 44.4821C3.74951 51.7355 8.62241 67.0636 9.33654 66.9936C9.58858 66.9936 7.79625 58.8067 8.24433 54.508C8.34576 52.1637 8.71216 49.8385 9.33653 47.5767C10.7368 43.586 12.3891 44.02 13.9714 39.7073C14.9376 37.0608 14.6995 35.8426 16.1278 34.4703C16.7281 33.8762 17.4572 33.4282 18.2585 33.1611C19.0598 32.894 19.9118 32.8149 20.7486 32.93C23.2691 33.5181 24.2773 36.4587 24.5993 37.3968C25.2434 39.2592 25.0334 40.9955 24.5993 44.4821C24.4714 46.1311 24.1806 47.7633 23.7312 49.355C22.905 52.0435 22.3309 52.1555 21.4207 55.2081C21.2247 55.8802 20.9867 57.0424 20.4966 59.4089C20.0905 61.3272 18.6901 67.4376 18.8162 67.4936C19.2922 67.7316 22.989 58.0366 23.5771 56.9444C24.7736 54.902 25.8535 52.7936 26.8117 50.6293C27.9289 48.3053 28.8108 45.8754 29.4442 43.3759C29.8967 40.9317 30.2053 38.463 30.3684 35.9826C30.5192 33.4708 30.8704 30.9751 31.4186 28.5192C32.7488 24.9766 34.6112 24.1924 34.2191 22.1901C33.883 20.5938 32.3707 19.5856 30.9845 18.6474C29.6804 17.5457 28.054 16.8971 26.3496 16.7991C24.5433 16.7991 24.0252 17.8493 22.0369 18.4934C20.0101 19.0275 17.8732 18.9741 15.8757 18.3394C13.0752 17.6532 12.4451 16.603 10.7788 16.9391C10.124 17.0897 9.50847 17.3771 8.9727 17.7826C8.43692 18.188 7.99304 18.7023 7.67023 19.2915Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      <circle cx="9" cy="67" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <circle cx="19" cy="67" r="4" fill="rgba(243, 42, 102, 0.6)"/>
      <g filter="url(#filter2347)">
        <path d="M19 64L19.8229 67.7391L22.8567 65.4037L21.0837 68.797L24.9088 68.9581L21.3694 70.4178L24.1962 73L20.5465 71.8431L21.0521 75.6382L19 72.406L16.9479 75.6382L17.4535 71.8431L13.8038 73L16.6306 70.4178L13.0912 68.9581L16.9163 68.797L15.1433 65.4037L18.1771 67.7391L19 64Z" fill="#98B250"/>
      </g>
      <defs>
        <filter id="filter2347" x="12.8911" y="63.8" width="12.2177" height="12.0382" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
          <feGaussianBlur stdDeviation="0.1" result="effect1_foregroundBlur"/>
        </filter>
      </defs>
    </g>

    <g class="periodontit-fibrioz" v-if="status && status.id == 25">
      <path d="M5.52817 4.51383C3.68873 5.9629 2.2997 7.90559 1.52344 10.1148C-0.366904 16.15 3.52581 23.3893 4.91206 25.9937C5.24239 26.5765 5.50099 27.1972 5.6822 27.8421C6.08378 29.2041 6.19353 30.6355 6.00426 32.0429C5.64285 34.7069 5.07164 37.3382 4.29595 39.9123C3.4256 43.1052 3.01076 46.4051 3.06372 49.7141C3.31054 53.868 4.30676 57.943 6.00426 61.7423C6.7604 63.7586 8.69275 68.9116 9.85497 68.7436C10.3311 68.6455 10.5271 67.6374 10.7791 65.2009C11.2972 59.9499 11.0032 58.1996 11.2412 55.3991C11.2412 54.811 11.8573 48.5659 15.246 44.2951C15.6161 43.74 16.0925 43.2636 16.6477 42.8935C17.2028 42.5234 17.8258 42.2669 18.4805 42.1387C18.9468 42.0625 19.4242 42.0922 19.8775 42.2255C20.3308 42.3588 20.7482 42.5924 21.099 42.9088C21.4211 43.2729 21.6171 43.763 21.5611 45.8354C21.5953 47.1262 21.492 48.4171 21.253 49.6861C20.6649 52.4866 19.7408 52.8086 18.7886 55.3991C18.3265 56.6734 18.1725 59.2778 17.8644 64.4868C17.7104 67.1613 17.7104 68.3515 18.3265 68.6875C18.9426 69.0236 20.5249 67.7214 20.945 67.2873C22.9053 65.3689 22.8633 63.7446 24.1936 61.1262C25.5238 58.5077 25.9999 59.2078 28.5064 55.4271C29.8181 53.5247 30.9036 51.4758 31.741 49.322C32.6651 46.8716 32.6371 45.6393 33.2812 41.3125C33.6581 37.7673 34.3279 34.2594 35.2836 30.8246C35.6897 29.6064 35.9837 29.4944 37.622 25.9377C38.8403 23.3053 39.4564 21.989 39.7644 21.1209C40.8636 18.4337 41.3423 15.5331 41.1647 12.6353C41.0011 10.5774 40.4148 8.5753 39.4424 6.75423C38.4451 4.54536 36.6994 2.75994 34.5135 1.71331C33.0441 1.14157 31.464 0.911728 29.8926 1.04119C28.6204 1.03954 27.3617 1.30177 26.1959 1.81133C24.5156 2.60948 24.5296 3.39362 23.2694 3.67367C22.4713 3.8417 22.0512 3.67367 19.5587 2.88953C17.4023 2.25941 16.3101 1.93736 15.554 1.81133C13.9155 1.55991 12.2447 1.61212 10.6251 1.96536C8.75686 2.42282 7.01509 3.2937 5.52817 4.51383Z" fill="#DEE1E9"/>
      <mask id="mask2547" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M39.7787 20.8858C39.5127 21.6279 39.0366 22.6781 38.1544 24.5965L31.9093 26.4308C31.9093 26.4308 27.9046 27.0469 25.4401 25.5206C22.9757 23.9944 17.8787 25.5206 17.8787 25.5206C17.8787 25.5206 10.7934 26.4308 8.48301 26.4308C6.91304 26.3224 5.42889 25.6743 4.28224 24.5965C2.62994 21.3899 -0.114562 15.2148 1.48173 9.9358C2.24461 7.68879 3.64008 5.70985 5.50046 4.23676C6.98646 3.04123 8.72443 2.19858 10.5834 1.7723C12.2021 1.41157 13.8743 1.35931 15.5123 1.61827C16.8658 1.9047 18.2027 2.26463 19.517 2.69647C22.0095 3.4246 22.4296 3.64864 23.2277 3.46661C24.4879 3.18656 24.4879 2.41642 26.1542 1.61827C27.3179 1.10233 28.578 0.839807 29.8509 0.848129C31.4181 0.693823 32.9997 0.904711 34.4718 1.46424C36.7159 2.49634 38.5148 4.30014 39.5407 6.54717C40.4914 8.37018 41.0675 10.3651 41.235 12.4142C41.3937 15.3117 40.8959 18.2076 39.7787 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2547)">
        <path d="M39.7787 20.8858C39.5127 21.6279 39.0366 22.6781 38.1544 24.5965L31.9093 26.4308C31.9093 26.4308 27.9046 27.0469 25.4401 25.5206C22.9757 23.9944 17.8787 25.5206 17.8787 25.5206C17.8787 25.5206 10.7934 26.4308 8.48301 26.4308C6.91304 26.3224 5.42889 25.6743 4.28224 24.5965C2.62994 21.3899 -0.114562 15.2148 1.48173 9.9358C2.24461 7.68879 3.64008 5.70985 5.50046 4.23676C6.98646 3.04123 8.72443 2.19858 10.5834 1.7723C12.2021 1.41157 13.8743 1.35931 15.5123 1.61827C16.8658 1.9047 18.2027 2.26463 19.517 2.69647C22.0095 3.4246 22.4296 3.64864 23.2277 3.46661C24.4879 3.18656 24.4879 2.41642 26.1542 1.61827C27.3179 1.10233 28.578 0.839807 29.8509 0.848129C31.4181 0.693823 32.9997 0.904711 34.4718 1.46424C36.7159 2.49634 38.5148 4.30014 39.5407 6.54717C40.4914 8.37018 41.0675 10.3651 41.235 12.4142C41.3937 15.3117 40.8959 18.2076 39.7787 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.6923 18.7991C22.7544 18.7991 24 13.5008 24 3.90252L22.0769 2.04045L19 0.799072C19 10.3974 20.6302 18.7991 21.6923 18.7991Z" fill="#404040"/>
      </g>
      <circle cx="18" cy="67" r="4" fill="rgba(243, 42, 102, 0.6)"/>
    </g>

    <g class="periodontit-complicated" v-if="status && status.id == 26">
      <mask id="mask2647" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="69">
        <path d="M5.51279 4.50772C3.67335 5.9568 2.28432 7.89949 1.50806 10.1087C-0.382285 16.1438 3.51043 23.3832 4.89668 25.9876C5.22701 26.5704 5.48561 27.1911 5.66682 27.836C6.0684 29.198 6.17814 30.6294 5.98888 32.0367C5.62747 34.7008 5.05626 37.3321 4.28057 39.9062C3.41022 43.0991 2.99538 46.399 3.04834 49.708C3.29516 53.8619 4.29138 57.9369 5.98888 61.7362C6.74502 63.7525 8.67737 68.9055 9.83959 68.7374C10.3157 68.6394 10.5117 67.6312 10.7638 65.1948C11.2818 59.9438 10.9878 58.1935 11.2258 55.393C11.2258 54.8049 11.842 48.5598 15.2306 44.289C15.6007 43.7339 16.0771 43.2575 16.6323 42.8874C17.1874 42.5173 17.8104 42.2608 18.4652 42.1326C18.9314 42.0564 19.4089 42.0861 19.8621 42.2194C20.3154 42.3527 20.7329 42.5862 21.0836 42.9027C21.4057 43.2668 21.6017 43.7569 21.5457 45.8293C21.5799 47.1201 21.4766 48.411 21.2377 49.68C20.6496 52.4805 19.7254 52.8025 18.7732 55.393C18.3111 56.6672 18.1571 59.2717 17.849 64.4807C17.695 67.1552 17.695 68.3454 18.3111 68.6814C18.9272 69.0175 20.5095 67.7153 20.9296 67.2812C22.89 65.3628 22.848 63.7385 24.1782 61.1201C25.5085 58.5016 25.9845 59.2017 28.491 55.421C29.8027 53.5186 30.8882 51.4697 31.7256 49.3159C32.6498 46.8655 32.6217 45.6332 33.2659 41.3064C33.6427 37.7612 34.3125 34.2533 35.2682 30.8185C35.6743 29.6003 35.9684 29.4883 37.6067 25.9316C38.8249 23.2992 39.441 21.9829 39.7491 21.1148C40.8482 18.4276 41.3269 15.527 41.1493 12.6292C40.9857 10.5713 40.3994 8.5692 39.427 6.74813C38.4297 4.53925 36.684 2.75384 34.4981 1.70721C33.0288 1.13547 31.4486 0.905624 29.8772 1.03509C28.605 1.03343 27.3463 1.29567 26.1806 1.80523C24.5003 2.60337 24.5143 3.38752 23.254 3.66757C22.4559 3.8356 22.0358 3.66757 19.5434 2.88343C17.387 2.25331 16.2948 1.93125 15.5386 1.80523C13.9001 1.5538 12.2293 1.60601 10.6097 1.95926C8.74148 2.41671 6.99971 3.2876 5.51279 4.50772Z" fill="#DEE1E9"/>
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask2647)">
        <path d="M5.51279 4.50772C3.67335 5.9568 2.28432 7.89949 1.50806 10.1087C-0.382285 16.1438 3.51043 23.3832 4.89668 25.9876C5.22701 26.5704 5.48561 27.1911 5.66682 27.836C6.0684 29.198 6.17814 30.6294 5.98888 32.0367C5.62747 34.7008 5.05626 37.3321 4.28057 39.9062C3.41022 43.0991 2.99538 46.399 3.04834 49.708C3.29516 53.8619 4.29138 57.9369 5.98888 61.7362C6.74502 63.7525 8.67737 68.9055 9.83959 68.7374C10.3157 68.6394 10.5117 67.6312 10.7638 65.1948C11.2818 59.9438 10.9878 58.1935 11.2258 55.393C11.2258 54.8049 11.842 48.5598 15.2306 44.289C15.6007 43.7339 16.0771 43.2575 16.6323 42.8874C17.1874 42.5173 17.8104 42.2608 18.4652 42.1326C18.9314 42.0564 19.4089 42.0861 19.8621 42.2194C20.3154 42.3527 20.7329 42.5862 21.0836 42.9027C21.4057 43.2668 21.6017 43.7569 21.5457 45.8293C21.5799 47.1201 21.4766 48.411 21.2377 49.68C20.6496 52.4805 19.7254 52.8025 18.7732 55.393C18.3111 56.6672 18.1571 59.2717 17.849 64.4807C17.695 67.1552 17.695 68.3454 18.3111 68.6814C18.9272 69.0175 20.5095 67.7153 20.9296 67.2812C22.89 65.3628 22.848 63.7385 24.1782 61.1201C25.5085 58.5016 25.9845 59.2017 28.491 55.421C29.8027 53.5186 30.8882 51.4697 31.7256 49.3159C32.6498 46.8655 32.6217 45.6332 33.2659 41.3064C33.6427 37.7612 34.3125 34.2533 35.2682 30.8185C35.6743 29.6003 35.9684 29.4883 37.6067 25.9316C38.8249 23.2992 39.441 21.9829 39.7491 21.1148C40.8482 18.4276 41.3269 15.527 41.1493 12.6292C40.9857 10.5713 40.3994 8.5692 39.427 6.74813C38.4297 4.53925 36.684 2.75384 34.4981 1.70721C33.0288 1.13547 31.4486 0.905624 29.8772 1.03509C28.605 1.03343 27.3463 1.29567 26.1806 1.80523C24.5003 2.60337 24.5143 3.38752 23.254 3.66757C22.4559 3.8356 22.0358 3.66757 19.5434 2.88343C17.387 2.25331 16.2948 1.93125 15.5386 1.80523C13.9001 1.5538 12.2293 1.60601 10.6097 1.95926C8.74148 2.41671 6.99971 3.2876 5.51279 4.50772Z" fill="#DEE1E9"/>
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M13.9741 19.6762H2.05292C4.21498 25.046 4.57199 28.3063 4.27545 34.0446C4.26427 34.2609 4.51364 34.3955 4.70387 34.2689C5.70794 33.601 8.06614 31.7849 8.63778 29.1432C9.33025 25.9432 11.3335 25.1661 11.5461 25.092C11.559 25.0875 11.5706 25.083 11.583 25.0773L12.7659 24.5307C12.9105 24.4639 12.9691 24.3014 12.8968 24.1678L12.3679 23.1901C12.3231 23.1074 12.3272 23.0092 12.3787 22.9299L14.2251 20.0858C14.3421 19.9056 14.2016 19.6762 13.9741 19.6762Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.04897 33.155C6.10995 32.3071 7.51793 30.8702 7.92035 29.0106C8.66356 25.5761 10.8395 24.6201 11.2725 24.4648L12.0461 24.1073L11.7135 23.4925C11.5569 23.203 11.5711 22.8596 11.7513 22.5821L13.1988 20.3524H3.09621C4.87294 25.0171 5.24894 28.2129 5.04897 33.155ZM11.2558 24.4725C11.2556 24.4726 11.2559 24.4724 11.2558 24.4725V24.4725ZM5.1313 34.8178C4.40244 35.3027 3.50671 34.7463 3.54464 34.0123C3.83745 28.3462 3.48824 25.18 1.36722 19.9121L0.999999 19H13.9741C14.7703 19 15.2621 19.8028 14.8524 20.4338L13.1301 23.0869L13.5512 23.8654C13.8042 24.333 13.5991 24.9017 13.0931 25.1355L11.9102 25.6822C11.8695 25.701 11.8336 25.7145 11.8041 25.7248C11.7675 25.7376 9.98671 26.3577 9.35523 29.2758C8.72573 32.1849 6.16235 34.1319 5.1313 34.8178Z" fill="#404040"/>
      </g>
    </g>

    <g class="periodontit-moderate" v-if="status && status.id == 1867">
      <mask id="mask147" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="69">
        <path d="M5.51279 4.50772C3.67335 5.9568 2.28432 7.89949 1.50806 10.1087C-0.382285 16.1438 3.51043 23.3832 4.89668 25.9876C5.22701 26.5704 5.48561 27.1911 5.66682 27.836C6.0684 29.198 6.17814 30.6294 5.98888 32.0367C5.62747 34.7008 5.05626 37.3321 4.28057 39.9062C3.41022 43.0991 2.99538 46.399 3.04834 49.708C3.29516 53.8619 4.29138 57.9369 5.98888 61.7362C6.74502 63.7525 8.67737 68.9055 9.83959 68.7374C10.3157 68.6394 10.5117 67.6312 10.7638 65.1948C11.2818 59.9438 10.9878 58.1935 11.2258 55.393C11.2258 54.8049 11.842 48.5598 15.2306 44.289C15.6007 43.7339 16.0771 43.2575 16.6323 42.8874C17.1874 42.5173 17.8104 42.2608 18.4652 42.1326C18.9314 42.0564 19.4089 42.0861 19.8621 42.2194C20.3154 42.3527 20.7329 42.5862 21.0836 42.9027C21.4057 43.2668 21.6017 43.7569 21.5457 45.8293C21.5799 47.1201 21.4766 48.411 21.2377 49.68C20.6496 52.4805 19.7254 52.8025 18.7732 55.393C18.3111 56.6672 18.1571 59.2717 17.849 64.4807C17.695 67.1552 17.695 68.3454 18.3111 68.6814C18.9272 69.0175 20.5095 67.7153 20.9296 67.2812C22.89 65.3628 22.848 63.7385 24.1782 61.1201C25.5085 58.5016 25.9845 59.2017 28.491 55.421C29.8027 53.5186 30.8882 51.4697 31.7256 49.3159C32.6498 46.8655 32.6217 45.6332 33.2659 41.3064C33.6427 37.7612 34.3125 34.2533 35.2682 30.8185C35.6743 29.6003 35.9684 29.4883 37.6067 25.9316C38.8249 23.2992 39.441 21.9829 39.7491 21.1148C40.8482 18.4276 41.3269 15.527 41.1493 12.6292C40.9857 10.5713 40.3994 8.5692 39.427 6.74813C38.4297 4.53925 36.684 2.75384 34.4981 1.70721C33.0288 1.13547 31.4486 0.905624 29.8772 1.03509C28.605 1.03343 27.3463 1.29567 26.1806 1.80523C24.5003 2.60337 24.5143 3.38752 23.254 3.66757C22.4559 3.8356 22.0358 3.66757 19.5434 2.88343C17.387 2.25331 16.2948 1.93125 15.5386 1.80523C13.9001 1.5538 12.2293 1.60601 10.6097 1.95926C8.74148 2.41671 6.99971 3.2876 5.51279 4.50772Z" fill="#DEE1E9"/>
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask147)">
        <path d="M5.51279 4.50772C3.67335 5.9568 2.28432 7.89949 1.50806 10.1087C-0.382285 16.1438 3.51043 23.3832 4.89668 25.9876C5.22701 26.5704 5.48561 27.1911 5.66682 27.836C6.0684 29.198 6.17814 30.6294 5.98888 32.0367C5.62747 34.7008 5.05626 37.3321 4.28057 39.9062C3.41022 43.0991 2.99538 46.399 3.04834 49.708C3.29516 53.8619 4.29138 57.9369 5.98888 61.7362C6.74502 63.7525 8.67737 68.9055 9.83959 68.7374C10.3157 68.6394 10.5117 67.6312 10.7638 65.1948C11.2818 59.9438 10.9878 58.1935 11.2258 55.393C11.2258 54.8049 11.842 48.5598 15.2306 44.289C15.6007 43.7339 16.0771 43.2575 16.6323 42.8874C17.1874 42.5173 17.8104 42.2608 18.4652 42.1326C18.9314 42.0564 19.4089 42.0861 19.8621 42.2194C20.3154 42.3527 20.7329 42.5862 21.0836 42.9027C21.4057 43.2668 21.6017 43.7569 21.5457 45.8293C21.5799 47.1201 21.4766 48.411 21.2377 49.68C20.6496 52.4805 19.7254 52.8025 18.7732 55.393C18.3111 56.6672 18.1571 59.2717 17.849 64.4807C17.695 67.1552 17.695 68.3454 18.3111 68.6814C18.9272 69.0175 20.5095 67.7153 20.9296 67.2812C22.89 65.3628 22.848 63.7385 24.1782 61.1201C25.5085 58.5016 25.9845 59.2017 28.491 55.421C29.8027 53.5186 30.8882 51.4697 31.7256 49.3159C32.6498 46.8655 32.6217 45.6332 33.2659 41.3064C33.6427 37.7612 34.3125 34.2533 35.2682 30.8185C35.6743 29.6003 35.9684 29.4883 37.6067 25.9316C38.8249 23.2992 39.441 21.9829 39.7491 21.1148C40.8482 18.4276 41.3269 15.527 41.1493 12.6292C40.9857 10.5713 40.3994 8.5692 39.427 6.74813C38.4297 4.53925 36.684 2.75384 34.4981 1.70721C33.0288 1.13547 31.4486 0.905624 29.8772 1.03509C28.605 1.03343 27.3463 1.29567 26.1806 1.80523C24.5003 2.60337 24.5143 3.38752 23.254 3.66757C22.4559 3.8356 22.0358 3.66757 19.5434 2.88343C17.387 2.25331 16.2948 1.93125 15.5386 1.80523C13.9001 1.5538 12.2293 1.60601 10.6097 1.95926C8.74148 2.41671 6.99971 3.2876 5.51279 4.50772Z" fill="#DEE1E9"/>
        <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M9.86628 19.5H1.71952C3.19704 23.4705 3.44101 25.8811 3.23836 30.1241C3.23072 30.2841 3.40114 30.3836 3.53113 30.29C4.2173 29.7961 5.82886 28.4532 6.21952 26.5C6.69274 24.1339 8.0617 23.5593 8.20699 23.5045C8.21585 23.5012 8.22377 23.4978 8.23223 23.4936L9.04063 23.0894C9.13943 23.04 9.17947 22.9199 9.13008 22.8211L8.76862 22.0982C8.73804 22.037 8.74082 21.9645 8.776 21.9058L10.0378 19.8029C10.1178 19.6696 10.0217 19.5 9.86628 19.5Z" fill="#404040"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.76699 29.4664C4.49205 28.8394 5.45424 27.777 5.72925 26.4019C6.23715 23.8625 7.72418 23.1555 8.02006 23.0407L8.54872 22.7764L8.32143 22.3218C8.21439 22.1077 8.22415 21.8538 8.34728 21.6486L9.33644 20H2.4325C3.64669 23.4491 3.90365 25.8121 3.76699 29.4664ZM8.00864 23.0464C8.00854 23.0465 8.00874 23.0464 8.00864 23.0464V23.0464ZM3.82326 30.6958C3.32516 31.0544 2.71303 30.643 2.73895 30.1003C2.93905 25.9106 2.70041 23.5695 1.25093 19.6744L0.999982 19H9.8663C10.4104 19 10.7465 19.5936 10.4665 20.0601L9.2895 22.0219L9.57731 22.5975C9.7502 22.9433 9.61005 23.3638 9.26426 23.5367L8.45586 23.9409C8.42803 23.9548 8.40354 23.9648 8.38336 23.9724C8.35836 23.9818 7.14137 24.4403 6.70983 26.5981C6.27964 28.749 4.52786 30.1887 3.82326 30.6958Z" fill="#404040"/>
      </g>
    </g>

    <g class="pulpit-chronical" v-if="status && status.id == 1868">
      <path d="M5.52817 4.51383C3.68873 5.9629 2.2997 7.90559 1.52344 10.1148C-0.366904 16.15 3.52581 23.3893 4.91206 25.9937C5.24239 26.5765 5.50099 27.1972 5.6822 27.8421C6.08378 29.2041 6.19353 30.6355 6.00426 32.0429C5.64285 34.7069 5.07164 37.3382 4.29595 39.9123C3.4256 43.1052 3.01076 46.4051 3.06372 49.7141C3.31054 53.868 4.30676 57.943 6.00426 61.7423C6.7604 63.7586 8.69275 68.9116 9.85497 68.7436C10.3311 68.6455 10.5271 67.6374 10.7791 65.2009C11.2972 59.9499 11.0032 58.1996 11.2412 55.3991C11.2412 54.811 11.8573 48.5659 15.246 44.2951C15.6161 43.74 16.0925 43.2636 16.6477 42.8935C17.2028 42.5234 17.8258 42.2669 18.4805 42.1387C18.9468 42.0625 19.4242 42.0922 19.8775 42.2255C20.3308 42.3588 20.7482 42.5924 21.099 42.9088C21.4211 43.2729 21.6171 43.763 21.5611 45.8354C21.5953 47.1262 21.492 48.4171 21.253 49.6861C20.6649 52.4866 19.7408 52.8086 18.7886 55.3991C18.3265 56.6734 18.1725 59.2778 17.8644 64.4868C17.7104 67.1613 17.7104 68.3515 18.3265 68.6875C18.9426 69.0236 20.5249 67.7214 20.945 67.2873C22.9053 65.3689 22.8633 63.7446 24.1936 61.1262C25.5238 58.5077 25.9999 59.2078 28.5064 55.4271C29.8181 53.5247 30.9036 51.4758 31.741 49.322C32.6651 46.8716 32.6371 45.6393 33.2812 41.3125C33.6581 37.7673 34.3279 34.2594 35.2836 30.8246C35.6897 29.6064 35.9837 29.4944 37.622 25.9377C38.8403 23.3053 39.4564 21.989 39.7644 21.1209C40.8636 18.4337 41.3423 15.5331 41.1647 12.6353C41.0011 10.5774 40.4148 8.5753 39.4424 6.75423C38.4451 4.54536 36.6994 2.75994 34.5135 1.71331C33.0441 1.14157 31.464 0.911728 29.8926 1.04119C28.6204 1.03954 27.3617 1.30177 26.1959 1.81133C24.5156 2.60948 24.5296 3.39362 23.2694 3.67367C22.4713 3.8417 22.0512 3.67367 19.5587 2.88953C17.4023 2.25941 16.3101 1.93736 15.554 1.81133C13.9155 1.55991 12.2447 1.61212 10.6251 1.96536C8.75686 2.42282 7.01509 3.2937 5.52817 4.51383Z" fill="#DEE1E9"/>
      <mask id="mask247" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M39.7787 20.8858C39.5127 21.6279 39.0366 22.6781 38.1544 24.5965L31.9093 26.4308C31.9093 26.4308 27.9046 27.0469 25.4401 25.5206C22.9757 23.9944 17.8787 25.5206 17.8787 25.5206C17.8787 25.5206 10.7934 26.4308 8.48301 26.4308C6.91304 26.3224 5.42889 25.6743 4.28224 24.5965C2.62994 21.3899 -0.114562 15.2148 1.48173 9.9358C2.24461 7.68879 3.64008 5.70985 5.50046 4.23676C6.98646 3.04123 8.72443 2.19858 10.5834 1.7723C12.2021 1.41157 13.8743 1.35931 15.5123 1.61827C16.8658 1.9047 18.2027 2.26463 19.517 2.69647C22.0095 3.4246 22.4296 3.64864 23.2277 3.46661C24.4879 3.18656 24.4879 2.41642 26.1542 1.61827C27.3179 1.10233 28.578 0.839807 29.8509 0.848129C31.4181 0.693823 32.9997 0.904711 34.4718 1.46424C36.7159 2.49634 38.5148 4.30014 39.5407 6.54717C40.4914 8.37018 41.0675 10.3651 41.235 12.4142C41.3937 15.3117 40.8959 18.2076 39.7787 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask247)">
        <path d="M39.7787 20.8858C39.5127 21.6279 39.0366 22.6781 38.1544 24.5965L31.9093 26.4308C31.9093 26.4308 27.9046 27.0469 25.4401 25.5206C22.9757 23.9944 17.8787 25.5206 17.8787 25.5206C17.8787 25.5206 10.7934 26.4308 8.48301 26.4308C6.91304 26.3224 5.42889 25.6743 4.28224 24.5965C2.62994 21.3899 -0.114562 15.2148 1.48173 9.9358C2.24461 7.68879 3.64008 5.70985 5.50046 4.23676C6.98646 3.04123 8.72443 2.19858 10.5834 1.7723C12.2021 1.41157 13.8743 1.35931 15.5123 1.61827C16.8658 1.9047 18.2027 2.26463 19.517 2.69647C22.0095 3.4246 22.4296 3.64864 23.2277 3.46661C24.4879 3.18656 24.4879 2.41642 26.1542 1.61827C27.3179 1.10233 28.578 0.839807 29.8509 0.848129C31.4181 0.693823 32.9997 0.904711 34.4718 1.46424C36.7159 2.49634 38.5148 4.30014 39.5407 6.54717C40.4914 8.37018 41.0675 10.3651 41.235 12.4142C41.3937 15.3117 40.8959 18.2076 39.7787 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.3462 16C17.7351 16 13 8.53185 13 0L20.0385 1.10345L30.5 0C30.5 8.53185 24.9572 16 21.3462 16Z" fill="#404040"/>
      </g>
      <path opacity="0.2" d="M7.86841 15.6547C6.57544 18.2946 9.03758 20.129 9.98667 26.9896C10.3772 29.9633 10.2748 32.9887 9.68406 35.9233C8.92754 39.1895 7.90967 39.98 6.93307 42.4856C4.01702 50.2112 8.80375 66.5374 9.50525 66.4628C9.75284 66.4628 7.9922 57.7429 8.43236 53.1642C8.53199 50.6674 8.89191 48.1908 9.50524 45.7817C10.8807 41.5311 12.5038 41.9934 14.0581 37.3998C15.0072 34.581 14.7734 33.2835 16.1764 31.8219C16.7661 31.189 17.4823 30.7119 18.2694 30.4274C19.0566 30.1429 19.8936 30.0587 20.7155 30.1813C23.1914 30.8077 24.1818 33.9397 24.4981 34.939C25.1309 36.9226 24.9245 38.7719 24.4981 42.4856C24.3725 44.2419 24.0868 45.9805 23.6453 47.6758C22.8338 50.5393 22.2698 50.6586 21.3758 53.91C21.1832 54.6258 20.9494 55.8637 20.4679 58.3842C20.069 60.4275 18.6935 66.9357 18.8172 66.9954C19.2849 67.249 22.9163 56.9226 23.494 55.7593C24.6693 53.584 25.7301 51.3383 26.6714 49.033C27.7689 46.5577 28.6351 43.9696 29.2574 41.3074C29.7019 38.704 30.005 36.0745 30.1652 33.4326C30.3133 30.7573 30.6583 28.099 31.1968 25.4833C32.5035 21.71 34.3329 20.8748 33.9478 18.742C33.6177 17.0418 32.1321 15.9679 30.7704 14.9687C29.4894 13.7953 27.8917 13.1044 26.2175 13C24.4431 13 23.9342 14.1186 21.981 14.8046C19.9901 15.3735 17.891 15.3166 15.9288 14.6406C13.1778 13.9098 12.5588 12.7912 10.922 13.1491C10.2788 13.3095 9.67414 13.6157 9.14784 14.0475C8.62154 14.4794 8.18551 15.0271 7.86841 15.6547Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="pulpit-strong" v-if="status && status.id == 1869">
      <path d="M5.52817 4.51383C3.68873 5.9629 2.2997 7.90559 1.52344 10.1148C-0.366904 16.15 3.52581 23.3893 4.91206 25.9937C5.24239 26.5765 5.50099 27.1972 5.6822 27.8421C6.08378 29.2041 6.19353 30.6355 6.00426 32.0429C5.64285 34.7069 5.07164 37.3382 4.29595 39.9123C3.4256 43.1052 3.01076 46.4051 3.06372 49.7141C3.31054 53.868 4.30676 57.943 6.00426 61.7423C6.7604 63.7586 8.69275 68.9116 9.85497 68.7436C10.3311 68.6455 10.5271 67.6374 10.7791 65.2009C11.2972 59.9499 11.0032 58.1996 11.2412 55.3991C11.2412 54.811 11.8573 48.5659 15.246 44.2951C15.6161 43.74 16.0925 43.2636 16.6477 42.8935C17.2028 42.5234 17.8258 42.2669 18.4805 42.1387C18.9468 42.0625 19.4242 42.0922 19.8775 42.2255C20.3308 42.3588 20.7482 42.5924 21.099 42.9088C21.4211 43.2729 21.6171 43.763 21.5611 45.8354C21.5953 47.1262 21.492 48.4171 21.253 49.6861C20.6649 52.4866 19.7408 52.8086 18.7886 55.3991C18.3265 56.6734 18.1725 59.2778 17.8644 64.4868C17.7104 67.1613 17.7104 68.3515 18.3265 68.6875C18.9426 69.0236 20.5249 67.7214 20.945 67.2873C22.9053 65.3689 22.8633 63.7446 24.1936 61.1262C25.5238 58.5077 25.9999 59.2078 28.5064 55.4271C29.8181 53.5247 30.9036 51.4758 31.741 49.322C32.6651 46.8716 32.6371 45.6393 33.2812 41.3125C33.6581 37.7673 34.3279 34.2594 35.2836 30.8246C35.6897 29.6064 35.9837 29.4944 37.622 25.9377C38.8403 23.3053 39.4564 21.989 39.7644 21.1209C40.8636 18.4337 41.3423 15.5331 41.1647 12.6353C41.0011 10.5774 40.4148 8.5753 39.4424 6.75423C38.4451 4.54536 36.6994 2.75994 34.5135 1.71331C33.0441 1.14157 31.464 0.911728 29.8926 1.04119C28.6204 1.03954 27.3617 1.30177 26.1959 1.81133C24.5156 2.60948 24.5296 3.39362 23.2694 3.67367C22.4713 3.8417 22.0512 3.67367 19.5587 2.88953C17.4023 2.25941 16.3101 1.93736 15.554 1.81133C13.9155 1.55991 12.2447 1.61212 10.6251 1.96536C8.75686 2.42282 7.01509 3.2937 5.52817 4.51383Z" fill="#DEE1E9"/>
      <mask id="mask347" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M39.7787 20.8858C39.5127 21.6279 39.0366 22.6781 38.1544 24.5965L31.9093 26.4308C31.9093 26.4308 27.9046 27.0469 25.4401 25.5206C22.9757 23.9944 17.8787 25.5206 17.8787 25.5206C17.8787 25.5206 10.7934 26.4308 8.48301 26.4308C6.91304 26.3224 5.42889 25.6743 4.28224 24.5965C2.62994 21.3899 -0.114562 15.2148 1.48173 9.9358C2.24461 7.68879 3.64008 5.70985 5.50046 4.23676C6.98646 3.04123 8.72443 2.19858 10.5834 1.7723C12.2021 1.41157 13.8743 1.35931 15.5123 1.61827C16.8658 1.9047 18.2027 2.26463 19.517 2.69647C22.0095 3.4246 22.4296 3.64864 23.2277 3.46661C24.4879 3.18656 24.4879 2.41642 26.1542 1.61827C27.3179 1.10233 28.578 0.839807 29.8509 0.848129C31.4181 0.693823 32.9997 0.904711 34.4718 1.46424C36.7159 2.49634 38.5148 4.30014 39.5407 6.54717C40.4914 8.37018 41.0675 10.3651 41.235 12.4142C41.3937 15.3117 40.8959 18.2076 39.7787 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask347)">
        <path d="M39.7787 20.8858C39.5127 21.6279 39.0366 22.6781 38.1544 24.5965L31.9093 26.4308C31.9093 26.4308 27.9046 27.0469 25.4401 25.5206C22.9757 23.9944 17.8787 25.5206 17.8787 25.5206C17.8787 25.5206 10.7934 26.4308 8.48301 26.4308C6.91304 26.3224 5.42889 25.6743 4.28224 24.5965C2.62994 21.3899 -0.114562 15.2148 1.48173 9.9358C2.24461 7.68879 3.64008 5.70985 5.50046 4.23676C6.98646 3.04123 8.72443 2.19858 10.5834 1.7723C12.2021 1.41157 13.8743 1.35931 15.5123 1.61827C16.8658 1.9047 18.2027 2.26463 19.517 2.69647C22.0095 3.4246 22.4296 3.64864 23.2277 3.46661C24.4879 3.18656 24.4879 2.41642 26.1542 1.61827C27.3179 1.10233 28.578 0.839807 29.8509 0.848129C31.4181 0.693823 32.9997 0.904711 34.4718 1.46424C36.7159 2.49634 38.5148 4.30014 39.5407 6.54717C40.4914 8.37018 41.0675 10.3651 41.235 12.4142C41.3937 15.3117 40.8959 18.2076 39.7787 20.8858Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M21.6923 18.7991C22.7544 18.7991 24 13.5008 24 3.90252L22.0769 2.04045L19 0.799072C19 10.3974 20.6302 18.7991 21.6923 18.7991Z" fill="#404040"/>
      </g>
    </g>

    <g class="caries-deep" v-if="status && status.id == 1870">
      <path d="M5.52817 4.71573C3.68873 6.16481 2.2997 8.1075 1.52344 10.3168C-0.366904 16.3519 3.52581 23.5912 4.91206 26.1957C5.24239 26.7784 5.50099 27.3991 5.6822 28.044C6.08378 29.406 6.19353 30.8374 6.00426 32.2448C5.64285 34.9088 5.07164 37.5401 4.29595 40.1142C3.4256 43.3071 3.01076 46.607 3.06372 49.916C3.31054 54.0699 4.30676 58.1449 6.00426 61.9442C6.7604 63.9605 8.69275 69.1135 9.85497 68.9455C10.3311 68.8474 10.5271 67.8393 10.7791 65.4028C11.2972 60.1519 11.0032 58.4015 11.2412 55.601C11.2412 55.0129 11.8573 48.7678 15.246 44.497C15.6161 43.9419 16.0925 43.4655 16.6477 43.0954C17.2028 42.7253 17.8258 42.4688 18.4805 42.3406C18.9468 42.2644 19.4242 42.2941 19.8775 42.4274C20.3308 42.5607 20.7482 42.7943 21.099 43.1107C21.4211 43.4748 21.6171 43.9649 21.5611 46.0373C21.5953 47.3281 21.492 48.619 21.253 49.888C20.6649 52.6885 19.7408 53.0105 18.7886 55.601C18.3265 56.8753 18.1725 59.4797 17.8644 64.6887C17.7104 67.3632 17.7104 68.5534 18.3265 68.8895C18.9426 69.2255 20.5249 67.9233 20.945 67.4892C22.9053 65.5708 22.8633 63.9465 24.1936 61.3281C25.5238 58.7096 25.9999 59.4097 28.5064 55.629C29.8181 53.7266 30.9036 51.6777 31.741 49.5239C32.6651 47.0735 32.6371 45.8412 33.2812 41.5145C33.6581 37.9692 34.3279 34.4613 35.2836 31.0265C35.6897 29.8083 35.9837 29.6963 37.622 26.1396C38.8403 23.5072 39.4564 22.1909 39.7644 21.3228C40.8636 18.6356 41.3423 15.7351 41.1647 12.8372C41.0011 10.7793 40.4148 8.7772 39.4424 6.95614C38.4451 4.74726 36.6994 2.96184 34.5135 1.91522C33.0441 1.34348 31.464 1.11363 29.8926 1.2431C28.6204 1.24144 27.3617 1.50368 26.1959 2.01324C24.5156 2.81138 24.5296 3.59553 23.2694 3.87558C22.4713 4.04361 22.0512 3.87558 19.5587 3.09143C17.4023 2.46132 16.3101 2.13926 15.554 2.01324C13.9155 1.76181 12.2447 1.81402 10.6251 2.16726C8.75686 2.62472 7.01509 3.49561 5.52817 4.71573Z" fill="#DEE1E9"/>
      <path d="M39.7941 21.2947C39.5281 22.0369 39.052 23.0871 38.1698 25.0054L31.9247 26.8397C31.9247 26.8397 27.9199 27.4559 25.4555 25.9296C22.991 24.4033 17.8941 25.9296 17.8941 25.9296C17.8941 25.9296 10.8088 26.8397 8.49839 26.8397C6.92842 26.7313 5.44427 26.0832 4.29762 25.0054C2.64532 21.7988 -0.0991808 15.6237 1.49711 10.3447C2.25999 8.09772 3.65546 6.11878 5.51584 4.64569C7.00184 3.45017 8.73981 2.60752 10.5988 2.18124C12.2174 1.82051 13.8896 1.76825 15.5277 2.02721C16.8812 2.31363 18.2181 2.67356 19.5324 3.1054C22.0249 3.83353 22.4449 4.05758 23.2431 3.87555C24.5033 3.5955 24.5033 2.82535 26.1696 2.02721C27.3333 1.51127 28.5934 1.24874 29.8663 1.25706C31.4335 1.10276 33.0151 1.31365 34.4871 1.87318C36.7313 2.90527 38.5302 4.70908 39.5561 6.9561C40.5067 8.77911 41.0828 10.774 41.2504 12.8232C41.4091 15.7207 40.9113 18.6166 39.7941 21.2947Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M33 1L34.8667 3.50086L36.5 2.00515V5.3192L39.0622 4.75129L37.6667 6.50189L40 8.50258L37.6667 10.5033L39.0622 12.2539L36.7333 11.5036L36.5 15L34.2501 13.503L33.4667 14.5046L31.7499 13.503L29.5 15L28.8 13.0041L26.9378 12.2539L27.4 9.50292L26 8.50258L28.3333 7.00206L26.9378 4.75129L28.8 4.5012L29.5 2.00515L32.5333 4.00103L33 1Z" fill="#404040"/>
    </g>

    <g class="caries-medium" v-if="status && status.id == 1871">
      <path d="M5.52817 4.50772C3.68873 5.9568 2.2997 7.89949 1.52344 10.1087C-0.366904 16.1438 3.52581 23.3832 4.91206 25.9876C5.24239 26.5704 5.50099 27.1911 5.6822 27.836C6.08378 29.198 6.19353 30.6294 6.00426 32.0367C5.64285 34.7008 5.07164 37.3321 4.29595 39.9062C3.4256 43.0991 3.01076 46.399 3.06372 49.708C3.31054 53.8619 4.30676 57.9369 6.00426 61.7362C6.7604 63.7525 8.69275 68.9055 9.85497 68.7374C10.3311 68.6394 10.5271 67.6312 10.7791 65.1948C11.2972 59.9438 11.0032 58.1935 11.2412 55.393C11.2412 54.8049 11.8573 48.5598 15.246 44.289C15.6161 43.7339 16.0925 43.2575 16.6477 42.8874C17.2028 42.5173 17.8258 42.2608 18.4805 42.1326C18.9468 42.0564 19.4242 42.0861 19.8775 42.2194C20.3308 42.3527 20.7482 42.5862 21.099 42.9027C21.4211 43.2668 21.6171 43.7569 21.5611 45.8293C21.5953 47.1201 21.492 48.411 21.253 49.68C20.6649 52.4805 19.7408 52.8025 18.7886 55.393C18.3265 56.6672 18.1725 59.2717 17.8644 64.4807C17.7104 67.1552 17.7104 68.3454 18.3265 68.6814C18.9426 69.0175 20.5249 67.7153 20.945 67.2812C22.9053 65.3628 22.8633 63.7385 24.1936 61.1201C25.5238 58.5016 25.9999 59.2017 28.5064 55.421C29.8181 53.5186 30.9036 51.4697 31.741 49.3159C32.6651 46.8655 32.6371 45.6332 33.2812 41.3064C33.6581 37.7612 34.3279 34.2533 35.2836 30.8185C35.6897 29.6003 35.9837 29.4883 37.622 25.9316C38.8403 23.2992 39.4564 21.9829 39.7644 21.1148C40.8636 18.4276 41.3423 15.527 41.1647 12.6292C41.0011 10.5713 40.4148 8.5692 39.4424 6.74813C38.4451 4.53925 36.6994 2.75384 34.5135 1.70721C33.0441 1.13547 31.464 0.905624 29.8926 1.03509C28.6204 1.03343 27.3617 1.29567 26.1959 1.80523C24.5156 2.60337 24.5296 3.38752 23.2694 3.66757C22.4713 3.8356 22.0512 3.66757 19.5587 2.88343C17.4023 2.25331 16.3101 1.93125 15.554 1.80523C13.9155 1.5538 12.2447 1.60601 10.6251 1.95926C8.75686 2.41671 7.01509 3.2876 5.52817 4.50772Z" fill="#DEE1E9"/>
      <path d="M39.7941 21.0867C39.5281 21.8289 39.052 22.879 38.1698 24.7974L31.9247 26.6317C31.9247 26.6317 27.9199 27.2478 25.4555 25.7216C22.991 24.1953 17.8941 25.7216 17.8941 25.7216C17.8941 25.7216 10.8088 26.6317 8.49839 26.6317C6.92842 26.5233 5.44427 25.8752 4.29762 24.7974C2.64532 21.5908 -0.0991808 15.4157 1.49711 10.1367C2.25999 7.88971 3.65546 5.91078 5.51584 4.43768C7.00184 3.24216 8.73981 2.39951 10.5988 1.97323C12.2174 1.6125 13.8896 1.56024 15.5277 1.8192C16.8812 2.10563 18.2181 2.46555 19.5324 2.89739C22.0249 3.62553 22.4449 3.84957 23.2431 3.66754C24.5033 3.38749 24.5033 2.61735 26.1696 1.8192C27.3333 1.30326 28.5934 1.04073 29.8663 1.04906C31.4335 0.894751 33.0151 1.10564 34.4871 1.66517C36.7313 2.69726 38.5302 4.50107 39.5561 6.7481C40.5067 8.57111 41.0828 10.566 41.2504 12.6152C41.4091 15.5127 40.9113 18.4086 39.7941 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M33 1.79199L34.6 3.93559L36 2.65355V5.49416L38.1962 5.00738L37 6.5079L39 8.22277L37 9.93765L38.1962 11.4382L36.2 10.7951L36 13.792L34.0715 12.5088L33.4 13.3674L31.9285 12.5088L30 13.792L29.4 12.0812L27.8038 11.4382L28.2 9.08021L27 8.22277L29 6.93662L27.8038 5.00738L29.4 4.79302L30 2.65355L32.6 4.3643L33 1.79199Z" fill="#404040"/>
    </g>

    <g class="caries-light" v-if="status && status.id == 1872">
      <path d="M5.51279 4.50772C3.67335 5.9568 2.28432 7.89949 1.50806 10.1087C-0.382285 16.1438 3.51043 23.3832 4.89668 25.9876C5.22701 26.5704 5.48561 27.1911 5.66682 27.836C6.0684 29.198 6.17814 30.6294 5.98888 32.0367C5.62747 34.7008 5.05626 37.3321 4.28057 39.9062C3.41022 43.0991 2.99538 46.399 3.04834 49.708C3.29516 53.8619 4.29138 57.9369 5.98888 61.7362C6.74502 63.7525 8.67737 68.9055 9.83959 68.7374C10.3157 68.6394 10.5117 67.6312 10.7638 65.1948C11.2818 59.9438 10.9878 58.1935 11.2258 55.393C11.2258 54.8049 11.842 48.5598 15.2306 44.289C15.6007 43.7339 16.0771 43.2575 16.6323 42.8874C17.1874 42.5173 17.8104 42.2608 18.4652 42.1326C18.9314 42.0564 19.4089 42.0861 19.8621 42.2194C20.3154 42.3527 20.7329 42.5862 21.0836 42.9027C21.4057 43.2668 21.6017 43.7569 21.5457 45.8293C21.5799 47.1201 21.4766 48.411 21.2377 49.68C20.6496 52.4805 19.7254 52.8025 18.7732 55.393C18.3111 56.6672 18.1571 59.2717 17.849 64.4807C17.695 67.1552 17.695 68.3454 18.3111 68.6814C18.9272 69.0175 20.5095 67.7153 20.9296 67.2812C22.89 65.3628 22.848 63.7385 24.1782 61.1201C25.5085 58.5016 25.9845 59.2017 28.491 55.421C29.8027 53.5186 30.8882 51.4697 31.7256 49.3159C32.6498 46.8655 32.6217 45.6332 33.2659 41.3064C33.6427 37.7612 34.3125 34.2533 35.2682 30.8185C35.6743 29.6003 35.9684 29.4883 37.6067 25.9316C38.8249 23.2992 39.441 21.9829 39.7491 21.1148C40.8482 18.4276 41.3269 15.527 41.1493 12.6292C40.9857 10.5713 40.3994 8.5692 39.427 6.74813C38.4297 4.53925 36.684 2.75384 34.4981 1.70721C33.0288 1.13547 31.4486 0.905624 29.8772 1.03509C28.605 1.03343 27.3463 1.29567 26.1806 1.80523C24.5003 2.60337 24.5143 3.38752 23.254 3.66757C22.4559 3.8356 22.0358 3.66757 19.5434 2.88343C17.387 2.25331 16.2948 1.93125 15.5386 1.80523C13.9001 1.5538 12.2293 1.60601 10.6097 1.95926C8.74148 2.41671 6.99971 3.2876 5.51279 4.50772Z" fill="#DEE1E9"/>
      <path d="M39.7787 21.0867C39.5127 21.8289 39.0366 22.879 38.1544 24.7974L31.9093 26.6317C31.9093 26.6317 27.9046 27.2478 25.4401 25.7216C22.9757 24.1953 17.8787 25.7216 17.8787 25.7216C17.8787 25.7216 10.7934 26.6317 8.48301 26.6317C6.91304 26.5233 5.42889 25.8752 4.28224 24.7974C2.62994 21.5908 -0.114562 15.4157 1.48173 10.1367C2.24461 7.88971 3.64008 5.91078 5.50046 4.43768C6.98646 3.24216 8.72443 2.39951 10.5834 1.97323C12.2021 1.6125 13.8743 1.56024 15.5123 1.8192C16.8658 2.10563 18.2027 2.46555 19.517 2.89739C22.0095 3.62553 22.4296 3.84957 23.2277 3.66754C24.4879 3.38749 24.4879 2.61735 26.1542 1.8192C27.3179 1.30326 28.578 1.04073 29.8509 1.04906C31.4181 0.894751 32.9997 1.10564 34.4718 1.66517C36.7159 2.69726 38.5148 4.50107 39.5407 6.7481C40.4914 8.57111 41.0675 10.566 41.235 12.6152C41.3937 15.5127 40.8959 18.4086 39.7787 21.0867Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      <path d="M34.4846 1.79199L35.418 3.04242L36.2346 2.29457V3.95159L37.5157 3.66764L36.818 4.54294L37.9846 5.54328L36.818 6.54362L37.5157 7.41892L36.3513 7.0438L36.2346 8.79199L35.1097 8.04347L34.718 8.54431L33.8596 8.04347L32.7346 8.79199L32.3846 7.79405L31.4535 7.41892L31.6846 6.04345L30.9846 5.54328L32.1513 4.79302L31.4535 3.66764L32.3846 3.54259L32.7346 2.29457L34.2513 3.29251L34.4846 1.79199Z" fill="#404040"/>
    </g>

    <g class="implant-metalceramics" v-if="status && status.id == 1873">
      <path d="M17.8929 69.6565L19.9766 70.9263H23.4603L25.5603 69.6565L23.9487 68.11L26.0324 66.5635L24.144 65.017L26.4394 63.324L24.6161 61.7775L26.9115 60.3775L24.8929 58.9612L26.9115 57.3496L25.2185 55.803L27.4487 54.3868L25.6905 52.9217L27.9859 51.3751L25.8371 49.8286L27.9859 48.217L26.4394 46.8659L28.5231 45.3193L26.6998 43.838L28.7836 42.2914L26.9766 40.5496L27.9859 38.8728V24.6124L26.0975 17.2868H19.9766" fill="#8889BE"/>
      <path d="M17.8929 69.6565L19.9766 70.9263H23.4603L25.5603 69.6565L23.9487 68.11L26.0324 66.5635L24.144 65.017L26.4394 63.324L24.6161 61.7775L26.9115 60.3775L24.8929 58.9612L26.9115 57.3496L25.2185 55.803L27.4487 54.3868L25.6905 52.9217L27.9859 51.3751L25.8371 49.8286L27.9859 48.217L26.4394 46.8659L28.5231 45.3193L26.6998 43.838L28.7836 42.2914L26.9766 40.5496L27.9859 38.8728V24.6124L26.0975 17.2868H19.9766" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M25.3002 69.6565L23.2164 70.9263H19.749L17.6327 69.6565L19.2606 68.11L17.1606 66.5635L19.049 65.017L16.7699 63.324L18.5769 61.7775L16.2979 60.3775L18.3165 58.9612L16.2979 57.3496L17.9746 55.803L15.7606 54.3868L17.5025 52.9217L15.2234 51.3751L17.3723 49.8286L15.2234 48.217L16.7699 46.8659L14.6862 45.3193L16.4932 43.838L14.4095 42.2914L16.2327 40.5496L15.2234 38.8728V24.6124L17.0955 17.2868H21.0025" fill="#8889BE"/>
      <path d="M25.3002 69.6565L23.2164 70.9263H19.749L17.6327 69.6565L19.2606 68.11L17.1606 66.5635L19.049 65.017L16.7699 63.324L18.5769 61.7775L16.2979 60.3775L18.3165 58.9612L16.2979 57.3496L17.9746 55.803L15.7606 54.3868L17.5025 52.9217L15.2234 51.3751L17.3723 49.8286L15.2234 48.217L16.7699 46.8659L14.6862 45.3193L16.4932 43.838L14.4095 42.2914L16.2327 40.5496L15.2234 38.8728V24.6124L17.0955 17.2868H21.0025" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask547" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M38.1544 24.8726C39.0366 22.9542 39.5127 21.9041 39.7787 21.1619C40.8959 18.4838 41.3937 15.5878 41.235 12.6904C41.0675 10.6412 40.4914 8.6463 39.5407 6.82329C38.5148 4.57627 36.7159 2.77246 34.4718 1.74037C32.9997 1.18083 31.4181 0.969946 29.8509 1.12425C28.578 1.11593 27.3179 1.37846 26.1542 1.8944C24.4879 2.69254 24.4879 3.46268 23.2277 3.74273C22.4296 3.92477 22.0095 3.70072 19.517 2.97259C18.2027 2.54075 16.8658 2.18082 15.5123 1.8944C13.8743 1.63544 12.2021 1.68769 10.5834 2.04843C8.72443 2.4747 6.98646 3.31736 5.50046 4.51288C3.64008 5.98597 2.24461 7.96491 1.48173 10.2119C-0.114562 15.4909 2.62994 21.666 4.28224 24.8726C5.42889 25.9504 6.91304 26.5985 8.48301 26.7069C9.38287 26.7069 12.7648 26.5698 14.6324 26.4013C14.8604 26.3807 15.0605 26.2656 15.1207 26.0447C15.5715 24.3916 15.9846 17.3672 15.9846 17.3672C20.671 16.387 22.2981 16.2205 26.9846 17.3672L28.3221 25.3924C28.4122 25.9326 28.92 26.2999 29.4613 26.2162L38.1544 24.8726Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask547)">
        <path d="M38.1544 24.8726C39.0366 22.9542 39.5127 21.9041 39.7787 21.1619C40.8959 18.4838 41.3937 15.5878 41.235 12.6904C41.0675 10.6412 40.4914 8.6463 39.5407 6.82329C38.5148 4.57627 36.7159 2.77246 34.4718 1.74037C32.9997 1.18083 31.4181 0.969946 29.8509 1.12425C28.578 1.11593 27.3179 1.37846 26.1542 1.8944C24.4879 2.69254 24.4879 3.46268 23.2277 3.74273C22.4296 3.92477 22.0095 3.70072 19.517 2.97259C18.2027 2.54075 16.8658 2.18082 15.5123 1.8944C13.8743 1.63544 12.2021 1.68769 10.5834 2.04843C8.72443 2.4747 6.98646 3.31736 5.50046 4.51288C3.64008 5.98597 2.24461 7.96491 1.48173 10.2119C-0.114562 15.4909 2.62994 21.666 4.28224 24.8726C5.42889 25.9504 6.91304 26.5985 8.48301 26.7069C9.38287 26.7069 12.7648 26.5698 14.6324 26.4013C14.8604 26.3807 15.0605 26.2656 15.1207 26.0447C15.5715 24.3916 15.9846 17.3672 15.9846 17.3672C20.671 16.387 22.2981 16.2205 26.9846 17.3672L28.3221 25.3924C28.4122 25.9326 28.92 26.2999 29.4613 26.2162L38.1544 24.8726Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
        <path d="M34 40.0752H10V14.5272C19.3726 12.601 24.6274 12.5814 34 14.5272V40.0752Z" fill="#A2A5AE"/>
      </g>
    </g>

    <g class="implant-metal" v-if="status && status.id == 1874">
      <path d="M17.8929 69.9475L19.9766 71.2173H23.4603L25.5603 69.9475L23.9487 68.401L26.0324 66.8545L24.144 65.308L26.4394 63.615L24.6161 62.0685L26.9115 60.6685L24.8929 59.2522L26.9115 57.6406L25.2185 56.0941L27.4487 54.6778L25.6905 53.2127L27.9859 51.6662L25.8371 50.1197L27.9859 48.508L26.4394 47.1569L28.5231 45.6104L26.6998 44.129L28.7836 42.5825L26.9766 40.8406L27.9859 39.1639V24.9034L26.0975 17.5779H19.9766" fill="#8889BE"/>
      <path d="M17.8929 69.9475L19.9766 71.2173H23.4603L25.5603 69.9475L23.9487 68.401L26.0324 66.8545L24.144 65.308L26.4394 63.615L24.6161 62.0685L26.9115 60.6685L24.8929 59.2522L26.9115 57.6406L25.2185 56.0941L27.4487 54.6778L25.6905 53.2127L27.9859 51.6662L25.8371 50.1197L27.9859 48.508L26.4394 47.1569L28.5231 45.6104L26.6998 44.129L28.7836 42.5825L26.9766 40.8406L27.9859 39.1639V24.9034L26.0975 17.5779H19.9766" stroke="#8889BE" stroke-miterlimit="10"/>
      <path d="M25.3002 69.9475L23.2164 71.2173H19.749L17.6327 69.9475L19.2606 68.401L17.1606 66.8545L19.049 65.308L16.7699 63.615L18.5769 62.0685L16.2979 60.6685L18.3165 59.2522L16.2979 57.6406L17.9746 56.0941L15.7606 54.6778L17.5025 53.2127L15.2234 51.6662L17.3723 50.1197L15.2234 48.508L16.7699 47.1569L14.6862 45.6104L16.4932 44.129L14.4095 42.5825L16.2327 40.8406L15.2234 39.1639V24.9034L17.0955 17.5779H21.0025" fill="#8889BE"/>
      <path d="M25.3002 69.9475L23.2164 71.2173H19.749L17.6327 69.9475L19.2606 68.401L17.1606 66.8545L19.049 65.308L16.7699 63.615L18.5769 62.0685L16.2979 60.6685L18.3165 59.2522L16.2979 57.6406L17.9746 56.0941L15.7606 54.6778L17.5025 53.2127L15.2234 51.6662L17.3723 50.1197L15.2234 48.508L16.7699 47.1569L14.6862 45.6104L16.4932 44.129L14.4095 42.5825L16.2327 40.8406L15.2234 39.1639V24.9034L17.0955 17.5779H21.0025" stroke="#8889BE" stroke-miterlimit="10"/>
      <mask id="mask547" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M38.1544 25.1636C39.0366 23.2453 39.5127 22.1951 39.7787 21.4529C40.8959 18.7748 41.3937 15.8789 41.235 12.9814C41.0675 10.9322 40.4914 8.93732 39.5407 7.11431C38.5148 4.86728 36.7159 3.06348 34.4718 2.03138C32.9997 1.47185 31.4181 1.26096 29.8509 1.41527C28.578 1.40695 27.3179 1.66947 26.1542 2.18541C24.4879 2.98356 24.4879 3.7537 23.2277 4.03375C22.4296 4.21578 22.0095 3.99174 19.517 3.2636C18.2027 2.83176 16.8658 2.47184 15.5123 2.18541C13.8743 1.92645 12.2021 1.97871 10.5834 2.33944C8.72443 2.76572 6.98646 3.60837 5.50046 4.80389C3.64008 6.27699 2.24461 8.25593 1.48173 10.5029C-0.114562 15.7819 2.62994 21.957 4.28224 25.1636C5.42889 26.2414 6.91304 26.8895 8.48301 26.9979C9.38287 26.9979 12.7648 26.8608 14.6324 26.6923C14.8604 26.6717 15.0605 26.5566 15.1207 26.3357C15.5715 24.6826 15.9846 17.6582 15.9846 17.6582C20.671 16.678 22.2981 16.5115 26.9846 17.6582L28.3221 25.6834C28.4122 26.2236 28.92 26.5909 29.4613 26.5072L38.1544 25.1636Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask547)">
        <path d="M38.1544 25.1636C39.0366 23.2453 39.5127 22.1951 39.7787 21.4529C40.8959 18.7748 41.3937 15.8789 41.235 12.9814C41.0675 10.9322 40.4914 8.93732 39.5407 7.11431C38.5148 4.86728 36.7159 3.06348 34.4718 2.03138C32.9997 1.47185 31.4181 1.26096 29.8509 1.41527C28.578 1.40695 27.3179 1.66947 26.1542 2.18541C24.4879 2.98356 24.4879 3.7537 23.2277 4.03375C22.4296 4.21578 22.0095 3.99174 19.517 3.2636C18.2027 2.83176 16.8658 2.47184 15.5123 2.18541C13.8743 1.92645 12.2021 1.97871 10.5834 2.33944C8.72443 2.76572 6.98646 3.60837 5.50046 4.80389C3.64008 6.27699 2.24461 8.25593 1.48173 10.5029C-0.114562 15.7819 2.62994 21.957 4.28224 25.1636C5.42889 26.2414 6.91304 26.8895 8.48301 26.9979C9.38287 26.9979 12.7648 26.8608 14.6324 26.6923C14.8604 26.6717 15.0605 26.5566 15.1207 26.3357C15.5715 24.6826 15.9846 17.6582 15.9846 17.6582C20.671 16.678 22.2981 16.5115 26.9846 17.6582L28.3221 25.6834C28.4122 26.2236 28.92 26.5909 29.4613 26.5072L38.1544 25.1636Z" fill="#A2A5AE" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="bridges" v-if="status && status.id == 1875">
      <path d="M0 0.259766H21V5.25977H0V0.259766Z" fill="#838AA2"/>
      <path d="M22 0.259766H43V5.25977H22V0.259766Z" fill="#838AA2"/>
      <path d="M6.51279 8.39346C4.67335 9.84254 3.28432 11.7852 2.50806 13.9945C0.617715 20.0296 4.51043 27.2689 5.89668 29.8734C6.22701 30.4562 6.48561 31.0768 6.66682 31.7217C7.0684 33.0837 7.17814 34.5152 6.98888 35.9225C6.62747 38.5865 6.05626 41.2178 5.28057 43.7919C4.41022 46.9848 3.99538 50.2847 4.04834 53.5937C4.29516 57.7476 5.29138 61.8226 6.98888 65.6219C7.74502 67.6383 9.67737 72.7912 10.8396 72.6232C11.3157 72.5252 11.5117 71.517 11.7638 69.0805C12.2818 63.8296 11.9878 62.0793 12.2258 59.2788C12.2258 58.6906 12.842 52.4455 16.2306 48.1747C16.6007 47.6196 17.0771 47.1433 17.6323 46.7732C18.1874 46.403 18.8104 46.1465 19.4652 46.0183C19.9314 45.9422 20.4089 45.9718 20.8621 46.1051C21.3154 46.2384 21.7329 46.472 22.0836 46.7885C22.4057 47.1525 22.6017 47.6426 22.5457 49.715C22.5799 51.0059 22.4766 52.2967 22.2377 53.5657C21.6496 56.3662 20.7254 56.6883 19.7732 59.2788C19.3111 60.553 19.1571 63.1575 18.849 68.3664C18.695 71.0409 18.695 72.2311 19.3111 72.5672C19.9272 72.9032 21.5095 71.601 21.9296 71.1669C23.89 69.2486 23.848 67.6243 25.1782 65.0058C26.5085 62.3873 26.9845 63.0875 29.491 59.3068C30.8027 57.4043 31.8882 55.3554 32.7256 53.2016C33.6498 50.7512 33.6217 49.519 34.2659 45.1922C34.6427 41.6469 35.3125 38.139 36.2682 34.7043C36.6743 33.486 36.9684 33.374 38.6067 29.8174C39.8249 27.1849 40.441 25.8687 40.7491 25.0005C41.8482 22.3133 42.3269 19.4128 42.1493 16.5149C41.9857 14.457 41.3994 12.4549 40.427 10.6339C39.4297 8.425 37.684 6.63958 35.4981 5.59295C34.0288 5.02121 32.4486 4.79137 30.8772 4.92083C29.605 4.91918 28.3463 5.18141 27.1806 5.69097C25.5003 6.48912 25.5143 7.27326 24.254 7.55331C23.4559 7.72134 23.0358 7.55331 20.5434 6.76917C18.387 6.13905 17.2948 5.81699 16.5386 5.69097C14.9001 5.43955 13.2293 5.49176 11.6097 5.845C9.74148 6.30245 7.99971 7.17334 6.51279 8.39346Z" fill="#DEE1E9"/>
      <path d="M39.1544 28.6831C40.0366 26.7648 40.5127 25.7146 40.7787 24.9725C41.8959 22.2943 42.3937 19.3984 42.235 16.5009C42.0675 14.4517 41.4914 12.4568 40.5407 10.6338C39.5148 8.38681 37.7159 6.58301 35.4718 5.55091C33.9997 4.99138 32.4181 4.78049 30.8509 4.9348C29.578 4.92648 28.3179 5.189 27.1542 5.70494C25.4879 6.50309 25.4879 7.27323 24.2277 7.55328C23.4296 7.73531 23.0095 7.51127 20.517 6.78314C19.2027 6.3513 17.8658 5.99137 16.5123 5.70494C14.8743 5.44598 13.2021 5.49824 11.5834 5.85897C9.72443 6.28525 7.98646 7.1279 6.50046 8.32343C4.64008 9.79652 3.24461 11.7755 2.48173 14.0225C0.885438 19.3014 3.62994 25.4766 5.28224 28.6831C6.42889 29.761 7.91304 30.4091 9.48301 30.5175C10.3829 30.5175 13.7648 30.3803 15.6324 30.2118C15.8604 30.1913 16.0605 30.0761 16.1207 29.8552C16.5715 28.2021 16.9846 21.1777 16.9846 21.1777C21.671 20.1976 23.2981 20.031 27.9846 21.1777L29.3221 29.2029C29.4122 29.7431 29.92 30.1104 30.4613 30.0268L39.1544 28.6831Z" fill="#838AA2"/>
    </g>

    <g class="channel" v-if="status && status.id == 1876">
      <path d="M5.52817 5.13761C3.68873 6.58668 2.2997 8.52937 1.52344 10.7386C-0.366904 16.7737 3.52581 24.0131 4.91206 26.6175C5.24239 27.2003 5.50099 27.8209 5.6822 28.4659C6.08378 29.8279 6.19353 31.2593 6.00426 32.6666C5.64285 35.3307 5.07164 37.962 4.29595 40.5361C3.4256 43.729 3.01076 47.0289 3.06372 50.3379C3.31054 54.4918 4.30676 58.5668 6.00426 62.3661C6.7604 64.3824 8.69275 69.5354 9.85497 69.3673C10.3311 69.2693 10.5271 68.2611 10.7791 65.8247C11.2972 60.5737 11.0032 58.8234 11.2412 56.0229C11.2412 55.4348 11.8573 49.1896 15.246 44.9189C15.6161 44.3637 16.0925 43.8874 16.6477 43.5173C17.2028 43.1472 17.8258 42.8907 18.4805 42.7625C18.9468 42.6863 19.4242 42.716 19.8775 42.8493C20.3308 42.9826 20.7482 43.2161 21.099 43.5326C21.4211 43.8967 21.6171 44.3868 21.5611 46.4591C21.5953 47.75 21.492 49.0408 21.253 50.3099C20.6649 53.1104 19.7408 53.4324 18.7886 56.0229C18.3265 57.2971 18.1725 59.9016 17.8644 65.1106C17.7104 67.785 17.7104 68.9753 18.3265 69.3113C18.9426 69.6474 20.5249 68.3452 20.945 67.9111C22.9053 65.9927 22.8633 64.3684 24.1936 61.7499C25.5238 59.1315 25.9999 59.8316 28.5064 56.0509C29.8181 54.1484 30.9036 52.0996 31.741 49.9458C32.6651 47.4953 32.6371 46.2631 33.2812 41.9363C33.6581 38.3911 34.3279 34.8831 35.2836 31.4484C35.6897 30.2302 35.9837 30.1182 37.622 26.5615C38.8403 23.929 39.4564 22.6128 39.7644 21.7446C40.8636 19.0575 41.3423 16.1569 41.1647 13.2591C41.0011 11.2012 40.4148 9.19908 39.4424 7.37801C38.4451 5.16914 36.6994 3.38372 34.5135 2.33709C33.0441 1.76535 31.464 1.53551 29.8926 1.66497C28.6204 1.66332 27.3617 1.92555 26.1959 2.43511C24.5156 3.23326 24.5296 4.0174 23.2694 4.29745C22.4713 4.46548 22.0512 4.29745 19.5587 3.51331C17.4023 2.88319 16.3101 2.56113 15.554 2.43511C13.9155 2.18369 12.2447 2.2359 10.6251 2.58914C8.75686 3.04659 7.01509 3.91748 5.52817 5.13761Z" fill="#DEE1E9"/>
      <path d="M18.1173 60.417L23.5 47.5137L20.5 35.5137L11.1411 46.0137V58.0137C10.993 65.3056 11.5855 65.3056 11.5855 65.3056C13.5922 69.293 14.9255 67.4905 16.2588 66.3571C17.5921 65.2237 18.1173 60.417 18.1173 60.417Z" fill="#DEE1E9"/>
      <mask id="mask547" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="28">
        <path d="M39.7787 21.5096C39.5127 22.2517 39.0366 23.3019 38.1544 25.2202L31.9093 27.0546C31.9093 27.0546 27.9046 27.6707 25.4401 26.1444C22.9757 24.6181 17.8787 26.1444 17.8787 26.1444C17.8787 26.1444 10.7934 27.0546 8.48301 27.0546C6.91304 26.9462 5.42889 26.2981 4.28224 25.2202C2.62994 22.0137 -0.114562 15.8385 1.48173 10.5596C2.24461 8.31257 3.64008 6.33363 5.50046 4.86053C6.98646 3.66501 8.72443 2.82236 10.5834 2.39608C12.2021 2.03535 13.8743 1.98309 15.5123 2.24205C16.8658 2.52848 18.2027 2.88841 19.517 3.32025C22.0095 4.04838 22.4296 4.27242 23.2277 4.09039C24.4879 3.81034 24.4879 3.0402 26.1542 2.24205C27.3179 1.72611 28.578 1.46359 29.8509 1.47191C31.4181 1.3176 32.9997 1.52849 34.4718 2.08802C36.7159 3.12012 38.5148 4.92392 39.5407 7.17095C40.4914 8.99396 41.0675 10.9889 41.235 13.038C41.3937 15.9355 40.8959 18.8314 39.7787 21.5096Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask547)">
        <path d="M39.7787 21.5096C39.5127 22.2517 39.0366 23.3019 38.1544 25.2202L31.9093 27.0546C31.9093 27.0546 27.9046 27.6707 25.4401 26.1444C22.9757 24.6181 17.8787 26.1444 17.8787 26.1444C17.8787 26.1444 10.7934 27.0546 8.48301 27.0546C6.91304 26.9462 5.42889 26.2981 4.28224 25.2202C2.62994 22.0137 -0.114562 15.8385 1.48173 10.5596C2.24461 8.31257 3.64008 6.33363 5.50046 4.86053C6.98646 3.66501 8.72443 2.82236 10.5834 2.39608C12.2021 2.03535 13.8743 1.98309 15.5123 2.24205C16.8658 2.52848 18.2027 2.88841 19.517 3.32025C22.0095 4.04838 22.4296 4.27242 23.2277 4.09039C24.4879 3.81034 24.4879 3.0402 26.1542 2.24205C27.3179 1.72611 28.578 1.46359 29.8509 1.47191C31.4181 1.3176 32.9997 1.52849 34.4718 2.08802C36.7159 3.12012 38.5148 4.92392 39.5407 7.17095C40.4914 8.99396 41.0675 10.9889 41.235 13.038C41.3937 15.9355 40.8959 18.8314 39.7787 21.5096Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <path opacity="0.2" d="M29.5632 18.5047C30.3666 23.4417 29.8346 28.3786 29.7369 32.3651C29.6283 36.7343 30.1386 36.0061 29.9215 39.499C29.7025 43.0347 29.0971 46.5256 28.1191 49.8912C27.7717 51.0637 27.3374 52.2856 26.8705 54.3838C26.4036 56.482 21.1846 63.0137 21 63.0137C20.5766 63.0137 25.9693 53.3358 25.9693 47.7381C26.2299 44.1835 26.0996 42.6833 25.9693 39.499C25.8607 36.5245 25.383 33.1797 24.8836 33.1797C24.3841 33.1797 24.395 35.1422 23.7978 38.2771C22.9618 42.7327 22.2126 46.7563 20.0302 50.5083C18.3256 53.4458 17.2941 53.5569 15.6872 57.025C14.5363 59.5922 13.4614 65.5749 13.1791 65.5132C12.8968 65.4515 13.6677 57.926 14.4277 54.3838C15.1877 50.8416 16.0455 50.0393 16.9467 46.8427C18.3364 41.9057 18.445 36.0555 17.305 35.6235C16.5015 35.3396 15.1335 37.6723 13.1791 41.1282C11.659 43.7694 11.0836 45.2382 9.41151 48.6693C7.73945 52.1005 8.91259 61.7235 8.5 61.5137C8.08741 61.3039 5.79895 54.2727 7.10186 49.8912C8.28534 45.917 8.43433 45.3521 9.41151 41.9456C10.1613 39.6196 10.5791 36.8254 11.0293 34.4016C11.4787 31.9933 11.6575 29.5298 11.5613 27.0703C11.4093 23.96 10.8339 22.7999 10.8447 18.912C10.8447 16.3202 11.1162 15.3204 11.7459 14.6293C12.3756 13.9381 13.5591 13.7159 15.3289 13.6172C16.9623 13.366 18.6249 13.5736 20.1714 14.222C22.712 15.4562 22.7338 17.5791 24.5144 17.6901C26.295 17.8012 27.2939 15.6907 28.4557 16.2708C29.2266 16.6287 29.4437 17.8012 29.5632 18.5047Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
    </g>

    <g class="channel-sealed" v-if="status && status.id == 1877">
      <path d="M5.52817 4.22843C3.68873 5.6775 2.2997 7.62019 1.52344 9.82945C-0.366904 15.8645 3.52581 23.1039 4.91206 25.7083C5.24239 26.2911 5.50099 26.9118 5.6822 27.5567C6.08378 28.9187 6.19353 30.3501 6.00426 31.7575C5.64285 34.4215 5.07164 37.0528 4.29595 39.6269C3.4256 42.8198 3.01076 46.1197 3.06372 49.4287C3.31054 53.5826 4.30676 57.6576 6.00426 61.4569C6.7604 63.4732 8.69275 68.6262 9.85497 68.4582C10.3311 68.3601 10.5271 67.352 10.7791 64.9155C11.2972 59.6645 11.0032 57.9142 11.2412 55.1137C11.2412 54.5256 11.8573 48.2805 15.246 44.0097C15.6161 43.4546 16.0925 42.9782 16.6477 42.6081C17.2028 42.238 17.8258 41.9815 18.4805 41.8533C18.9468 41.7771 19.4242 41.8068 19.8775 41.9401C20.3308 42.0734 20.7482 42.3069 21.099 42.6234C21.4211 42.9875 21.6171 43.4776 21.5611 45.55C21.5953 46.8408 21.492 48.1317 21.253 49.4007C20.6649 52.2012 19.7408 52.5232 18.7886 55.1137C18.3265 56.3879 18.1725 58.9924 17.8644 64.2014C17.7104 66.8759 17.7104 68.0661 18.3265 68.4021C18.9426 68.7382 20.5249 67.436 20.945 67.0019C22.9053 65.0835 22.8633 63.4592 24.1936 60.8408C25.5238 58.2223 25.9999 58.9224 28.5064 55.1417C29.8181 53.2393 30.9036 51.1904 31.741 49.0366C32.6651 46.5862 32.6371 45.3539 33.2812 41.0271C33.6581 37.4819 34.3279 33.974 35.2836 30.5392C35.6897 29.321 35.9837 29.209 37.622 25.6523C38.8403 23.0199 39.4564 21.7036 39.7644 20.8355C40.8636 18.1483 41.3423 15.2477 41.1647 12.3499C41.0011 10.292 40.4148 8.2899 39.4424 6.46883C38.4451 4.25996 36.6994 2.47454 34.5135 1.42791C33.0441 0.856172 31.464 0.626327 29.8926 0.755791C28.6204 0.754137 27.3617 1.01637 26.1959 1.52593C24.5156 2.32408 24.5296 3.10822 23.2694 3.38827C22.4713 3.5563 22.0512 3.38827 19.5587 2.60413C17.4023 1.97401 16.3101 1.65196 15.554 1.52593C13.9155 1.27451 12.2447 1.32672 10.6251 1.67996C8.75686 2.13741 7.01509 3.0083 5.52817 4.22843Z" fill="#DEE1E9"/>
      <path d="M18.1173 59.5078L23.5 46.6045L20.5 34.6045L11.1411 45.1045V57.1045C10.993 64.3964 11.5855 64.3964 11.5855 64.3964C13.5922 68.3838 14.9255 66.5813 16.2588 65.4479C17.5921 64.3145 18.1173 59.5078 18.1173 59.5078Z" fill="#DEE1E9"/>
      <mask id="mask547" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="42" height="27">
        <path d="M39.7787 20.6004C39.5127 21.3425 39.0366 22.3927 38.1544 24.3111L31.9093 26.1454C31.9093 26.1454 27.9046 26.7615 25.4401 25.2352C22.9757 23.709 17.8787 25.2352 17.8787 25.2352C17.8787 25.2352 10.7934 26.1454 8.48301 26.1454C6.91304 26.037 5.42889 25.3889 4.28224 24.3111C2.62994 21.1045 -0.114562 14.9294 1.48173 9.6504C2.24461 7.40339 3.64008 5.42445 5.50046 3.95136C6.98646 2.75583 8.72443 1.91318 10.5834 1.4869C12.2021 1.12617 13.8743 1.07391 15.5123 1.33287C16.8658 1.6193 18.2027 1.97923 19.517 2.41107C22.0095 3.1392 22.4296 3.36324 23.2277 3.18121C24.4879 2.90116 24.4879 2.13102 26.1542 1.33287C27.3179 0.816933 28.578 0.554407 29.8509 0.562728C31.4181 0.408423 32.9997 0.619311 34.4718 1.17884C36.7159 2.21094 38.5148 4.01474 39.5407 6.26177C40.4914 8.08478 41.0675 10.0797 41.235 12.1288C41.3937 15.0263 40.8959 17.9222 39.7787 20.6004Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask547)">
        <path d="M39.7787 20.6004C39.5127 21.3425 39.0366 22.3927 38.1544 24.3111L31.9093 26.1454C31.9093 26.1454 27.9046 26.7615 25.4401 25.2352C22.9757 23.709 17.8787 25.2352 17.8787 25.2352C17.8787 25.2352 10.7934 26.1454 8.48301 26.1454C6.91304 26.037 5.42889 25.3889 4.28224 24.3111C2.62994 21.1045 -0.114562 14.9294 1.48173 9.6504C2.24461 7.40339 3.64008 5.42445 5.50046 3.95136C6.98646 2.75583 8.72443 1.91318 10.5834 1.4869C12.2021 1.12617 13.8743 1.07391 15.5123 1.33287C16.8658 1.6193 18.2027 1.97923 19.517 2.41107C22.0095 3.1392 22.4296 3.36324 23.2277 3.18121C24.4879 2.90116 24.4879 2.13102 26.1542 1.33287C27.3179 0.816933 28.578 0.554407 29.8509 0.562728C31.4181 0.408423 32.9997 0.619311 34.4718 1.17884C36.7159 2.21094 38.5148 4.01474 39.5407 6.26177C40.4914 8.08478 41.0675 10.0797 41.235 12.1288C41.3937 15.0263 40.8959 17.9222 39.7787 20.6004Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <path opacity="0.4" d="M29.5632 17.5956C30.3666 22.5325 29.8346 27.4694 29.7369 31.456C29.6283 35.8251 30.1386 35.0969 29.9215 38.5898C29.7025 42.1255 29.0971 45.6164 28.1191 48.982C27.7717 50.1545 27.3374 51.3764 26.8705 53.4746C26.4036 55.5728 21.1846 62.1045 21 62.1045C20.5766 62.1045 25.9693 52.4267 25.9693 46.8289C26.2299 43.2743 26.0996 41.7741 25.9693 38.5898C25.8607 35.6153 25.383 32.2706 24.8836 32.2706C24.3841 32.2706 24.395 34.233 23.7978 37.3679C22.9618 41.8235 22.2126 45.8471 20.0302 49.5991C18.3256 52.5366 17.2941 52.6477 15.6872 56.1159C14.5363 58.6831 13.4614 64.6657 13.1791 64.604C12.8968 64.5423 13.6677 57.0169 14.4277 53.4746C15.1877 49.9324 16.0455 49.1301 16.9467 45.9335C18.3364 40.9966 18.445 35.1463 17.305 34.7143C16.5015 34.4305 15.1335 36.7632 13.1791 40.219C11.659 42.8603 11.0836 44.329 9.41151 47.7601C7.73945 51.1913 8.91259 60.8143 8.5 60.6045C8.08741 60.3947 5.79895 53.3635 7.10186 48.982C8.28534 45.0078 8.43433 44.4429 9.41151 41.0365C10.1613 38.7104 10.5791 35.9162 11.0293 33.4924C11.4787 31.0841 11.6575 28.6206 11.5613 26.1611C11.4093 23.0509 10.8339 21.8907 10.8447 18.0029C10.8447 15.411 11.1162 14.4113 11.7459 13.7201C12.3756 13.0289 13.5591 12.8067 15.3289 12.708C16.9623 12.4568 18.6249 12.6645 20.1714 13.3128C22.712 14.547 22.7338 16.6699 24.5144 16.781C26.295 16.892 27.2939 14.7815 28.4557 15.3616C29.2266 15.7195 29.4437 16.8921 29.5632 17.5956Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
    </g>

    <g class="channel-halfsealed" v-if="status && status.id == 1878">
      <path d="M5.52817 5.22794C3.68873 6.67702 2.2997 8.6197 1.52344 10.829C-0.366904 16.8641 3.52581 24.1034 4.91206 26.7079C5.24239 27.2906 5.50099 27.9113 5.6822 28.5562C6.08378 29.9182 6.19353 31.3496 6.00426 32.757C5.64285 35.421 5.07164 38.0523 4.29595 40.6264C3.4256 43.8193 3.01076 47.1192 3.06372 50.4282C3.31054 54.5821 4.30676 58.6571 6.00426 62.4564C6.7604 64.4728 8.69275 69.6257 9.85497 69.4577C10.3311 69.3596 10.5271 68.3515 10.7791 65.915C11.2972 60.6641 11.0032 58.9137 11.2412 56.1132C11.2412 55.5251 11.8573 49.28 15.246 45.0092C15.6161 44.4541 16.0925 43.9777 16.6477 43.6076C17.2028 43.2375 17.8258 42.981 18.4805 42.8528C18.9468 42.7766 19.4242 42.8063 19.8775 42.9396C20.3308 43.0729 20.7482 43.3065 21.099 43.6229C21.4211 43.987 21.6171 44.4771 21.5611 46.5495C21.5953 47.8403 21.492 49.1312 21.253 50.4002C20.6649 53.2007 19.7408 53.5228 18.7886 56.1132C18.3265 57.3875 18.1725 59.9919 17.8644 65.2009C17.7104 67.8754 17.7104 69.0656 18.3265 69.4017C18.9426 69.7377 20.5249 68.4355 20.945 68.0014C22.9053 66.0831 22.8633 64.4588 24.1936 61.8403C25.5238 59.2218 25.9999 59.9219 28.5064 56.1412C29.8181 54.2388 30.9036 52.1899 31.741 50.0361C32.6651 47.5857 32.6371 46.3534 33.2812 42.0267C33.6581 38.4814 34.3279 34.9735 35.2836 31.5387C35.6897 30.3205 35.9837 30.2085 37.622 26.6518C38.8403 24.0194 39.4564 22.7031 39.7644 21.835C40.8636 19.1478 41.3423 16.2473 41.1647 13.3494C41.0011 11.2915 40.4148 9.28941 39.4424 7.46835C38.4451 5.25947 36.6994 3.47405 34.5135 2.42743C33.0441 1.85568 31.464 1.62584 29.8926 1.7553C28.6204 1.75365 27.3617 2.01589 26.1959 2.52544C24.5156 3.32359 24.5296 4.10773 23.2694 4.38778C22.4713 4.55581 22.0512 4.38778 19.5587 3.60364C17.4023 2.97353 16.3101 2.65147 15.554 2.52544C13.9155 2.27402 12.2447 2.32623 10.6251 2.67947C8.75686 3.13693 7.01509 4.00781 5.52817 5.22794Z" fill="#DEE1E9"/>
      <path d="M18.1173 60.5073L23.5 47.604L20.5 35.604L11.1411 46.104V58.104C10.993 65.396 11.5855 65.396 11.5855 65.396C13.5922 69.3833 14.9255 67.5808 16.2588 66.4474C17.5921 65.314 18.1173 60.5073 18.1173 60.5073Z" fill="#DEE1E9"/>
      <mask id="mask547" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="1" width="42" height="27">
        <path d="M39.7787 21.5999C39.5127 22.342 39.0366 23.3922 38.1544 25.3106L31.9093 27.1449C31.9093 27.1449 27.9046 27.761 25.4401 26.2348C22.9757 24.7085 17.8787 26.2348 17.8787 26.2348C17.8787 26.2348 10.7934 27.1449 8.48301 27.1449C6.91304 27.0365 5.42889 26.3884 4.28224 25.3106C2.62994 22.104 -0.114562 15.9289 1.48173 10.6499C2.24461 8.4029 3.64008 6.42396 5.50046 4.95087C6.98646 3.75535 8.72443 2.91269 10.5834 2.48641C12.2021 2.12568 13.8743 2.07342 15.5123 2.33238C16.8658 2.61881 18.2027 2.97874 19.517 3.41058C22.0095 4.13871 22.4296 4.36276 23.2277 4.18072C24.4879 3.90067 24.4879 3.13053 26.1542 2.33238C27.3179 1.81645 28.578 1.55392 29.8509 1.56224C31.4181 1.40793 32.9997 1.61882 34.4718 2.17836C36.7159 3.21045 38.5148 5.01425 39.5407 7.26128C40.4914 9.08429 41.0675 11.0792 41.235 13.1284C41.3937 16.0258 40.8959 18.9218 39.7787 21.5999Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </mask>
      <g mask="url(#mask547)">
        <path d="M39.7787 21.5999C39.5127 22.342 39.0366 23.3922 38.1544 25.3106L31.9093 27.1449C31.9093 27.1449 27.9046 27.761 25.4401 26.2348C22.9757 24.7085 17.8787 26.2348 17.8787 26.2348C17.8787 26.2348 10.7934 27.1449 8.48301 27.1449C6.91304 27.0365 5.42889 26.3884 4.28224 25.3106C2.62994 22.104 -0.114562 15.9289 1.48173 10.6499C2.24461 8.4029 3.64008 6.42396 5.50046 4.95087C6.98646 3.75535 8.72443 2.91269 10.5834 2.48641C12.2021 2.12568 13.8743 2.07342 15.5123 2.33238C16.8658 2.61881 18.2027 2.97874 19.517 3.41058C22.0095 4.13871 22.4296 4.36276 23.2277 4.18072C24.4879 3.90067 24.4879 3.13053 26.1542 2.33238C27.3179 1.81645 28.578 1.55392 29.8509 1.56224C31.4181 1.40793 32.9997 1.61882 34.4718 2.17836C36.7159 3.21045 38.5148 5.01425 39.5407 7.26128C40.4914 9.08429 41.0675 11.0792 41.235 13.1284C41.3937 16.0258 40.8959 18.9218 39.7787 21.5999Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
      </g>
      <mask id="mask5047" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="35" width="30" height="36">
        <rect width="29.1809" height="35.4105" transform="matrix(-1 0 0 1 33.9518 35.5137)" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask5047)">
        <path opacity="0.4" d="M30.858 16.5043C31.6615 21.4412 31.1295 26.3781 31.0318 30.3647C30.9232 34.7338 31.4335 34.0056 31.2163 37.4985C30.9974 41.0342 30.3919 44.5251 29.414 47.8907C29.0665 49.0632 28.6322 50.2851 28.1654 52.3833C27.6985 54.4815 22.4794 61.0132 22.2949 61.0132C21.8714 61.0132 27.2642 51.3353 27.2642 45.7376C27.5248 42.183 27.3945 40.6828 27.2642 37.4985C27.1556 34.524 26.6779 31.1792 26.1784 31.1792C25.679 31.1792 25.6898 33.1417 25.0927 36.2766C24.2566 40.7322 23.5075 44.7558 21.3251 48.5078C19.6204 51.4453 18.589 51.5564 16.9821 55.0246C15.8312 57.5918 14.7563 63.5744 14.474 63.5127C14.1917 63.451 14.9625 55.9256 15.7226 52.3833C16.4826 48.8411 17.3404 48.0388 18.2415 44.8422C19.6313 39.9052 19.7399 34.055 18.5998 33.623C17.7964 33.3391 16.4283 35.6719 14.474 39.1277C12.9539 41.7689 12.3784 43.2377 10.7064 46.6688C9.03431 50.1 10.2074 59.723 9.79486 59.5132C9.38227 59.3034 7.09381 52.2722 8.39672 47.8907C9.5802 43.9165 9.72919 43.3516 10.7064 39.9451C11.4562 37.6191 11.8739 34.8249 12.3242 32.4011C12.7736 29.9928 12.9523 27.5293 12.8562 25.0698C12.7042 21.9596 12.1287 20.7994 12.1396 16.9116C12.1396 14.3197 12.411 13.3199 13.0408 12.6288C13.6705 11.9376 14.854 11.7154 16.6238 11.6167C18.2571 11.3655 19.9198 11.5732 21.4662 12.2215C24.0069 13.4557 24.0286 15.5786 25.8093 15.6897C27.5899 15.8007 28.5888 13.6902 29.7506 14.2703C30.5215 14.6282 30.7386 15.8007 30.858 16.5043Z" fill="#462AF3" stroke="#462AF3" stroke-miterlimit="10"/>
      </g>
      <mask id="mask50047" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="0" width="30" height="36">
        <rect width="29.1809" height="35.4105" transform="matrix(-1 0 0 1 33.9517 0.103027)" fill="#C4C4C4"/>
      </mask>
      <g mask="url(#mask50047)">
        <path opacity="0.2" d="M30.858 16.5043C31.6615 21.4412 31.1295 26.3781 31.0318 30.3647C30.9232 34.7338 31.4335 34.0056 31.2163 37.4985C30.9974 41.0342 30.3919 44.5251 29.414 47.8907C29.0665 49.0632 28.6322 50.2851 28.1654 52.3833C27.6985 54.4815 22.4794 61.0132 22.2949 61.0132C21.8714 61.0132 27.2642 51.3353 27.2642 45.7376C27.5248 42.183 27.3945 40.6828 27.2642 37.4985C27.1556 34.524 26.6779 31.1792 26.1784 31.1792C25.679 31.1792 25.6898 33.1417 25.0927 36.2766C24.2566 40.7322 23.5075 44.7558 21.3251 48.5078C19.6204 51.4453 18.589 51.5564 16.9821 55.0246C15.8312 57.5918 14.7563 63.5744 14.474 63.5127C14.1917 63.451 14.9625 55.9256 15.7226 52.3833C16.4826 48.8411 17.3404 48.0388 18.2415 44.8422C19.6313 39.9052 19.7399 34.055 18.5998 33.623C17.7964 33.3391 16.4283 35.6719 14.474 39.1277C12.9539 41.7689 12.3784 43.2377 10.7064 46.6688C9.03431 50.1 10.2074 59.723 9.79486 59.5132C9.38227 59.3034 7.09381 52.2722 8.39672 47.8907C9.5802 43.9165 9.72919 43.3516 10.7064 39.9451C11.4562 37.6191 11.8739 34.8249 12.3242 32.4011C12.7736 29.9928 12.9523 27.5293 12.8562 25.0698C12.7042 21.9596 12.1287 20.7994 12.1396 16.9116C12.1396 14.3197 12.411 13.3199 13.0408 12.6288C13.6705 11.9376 14.854 11.7154 16.6238 11.6167C18.2571 11.3655 19.9198 11.5732 21.4662 12.2215C24.0069 13.4557 24.0286 15.5786 25.8093 15.6897C27.5899 15.8007 28.5888 13.6902 29.7506 14.2703C30.5215 14.6282 30.7386 15.8007 30.858 16.5043Z" fill="#F32A66" stroke="#F32A66" stroke-miterlimit="10"/>
      </g>
    </g>

    <g class="custom" v-if="status && status.id > 26 && ![1867,1868,1869,1870,1871,1872,1873,1874,1875,1876,1877,1878].includes(status.id)">
      <path d="M4.71201 4.17642C2.87257 5.6255 1.48354 7.56819 0.70728 9.77745C-1.18307 15.8125 2.70965 23.0519 4.0959 25.6563C4.42623 26.2391 4.68483 26.8598 4.86604 27.5047C5.26762 28.8667 5.37736 30.2981 5.1881 31.7055C4.82669 34.3695 4.25548 37.0008 3.47979 39.5749C2.60944 42.7678 2.1946 46.0677 2.24756 49.3767C2.49437 53.5306 3.49059 57.6056 5.1881 61.4049C5.94424 63.4212 7.87659 68.5742 9.0388 68.4062C9.51489 68.3081 9.71093 67.2999 9.96297 64.8635C10.4811 59.6125 10.187 57.8622 10.4251 55.0617C10.4251 54.4736 11.0412 48.2285 14.4298 43.9577C14.7999 43.4026 15.2763 42.9262 15.8315 42.5561C16.3867 42.186 17.0096 41.9295 17.6644 41.8013C18.1307 41.7251 18.6081 41.7548 19.0613 41.8881C19.5146 42.0214 19.9321 42.2549 20.2829 42.5714C20.6049 42.9355 20.801 43.4256 20.7449 45.498C20.7791 46.7888 20.6759 48.0797 20.4369 49.3487C19.8488 52.1492 18.9246 52.4712 17.9724 55.0617C17.5103 56.3359 17.3563 58.9404 17.0483 64.1494C16.8942 66.8239 16.8942 68.0141 17.5103 68.3501C18.1265 68.6862 19.7088 67.384 20.1288 66.9499C22.0892 65.0315 22.0472 63.4072 23.3774 60.7888C24.7077 58.1703 25.1838 58.8704 27.6902 55.0897C29.0019 53.1873 30.0874 51.1384 30.9248 48.9846C31.849 46.5342 31.821 45.3019 32.4651 40.9751C32.842 37.4299 33.5117 33.922 34.4675 30.4872C34.8735 29.269 35.1676 29.157 36.8059 25.6003C38.0241 22.9679 38.6402 21.6516 38.9483 20.7835C40.0475 18.0963 40.5261 15.1957 40.3485 12.2979C40.1849 10.24 39.5986 8.2379 38.6262 6.41683C37.629 4.20795 35.8832 2.42254 33.6973 1.37591C32.228 0.80417 30.6478 0.574325 29.0765 0.703789C27.8042 0.702135 26.5455 0.964372 25.3798 1.47393C23.6995 2.27208 23.7135 3.05622 22.4533 3.33627C21.6551 3.5043 21.235 3.33627 18.7426 2.55213C16.5862 1.92201 15.494 1.59995 14.7378 1.47393C13.0993 1.2225 11.4286 1.27472 9.80894 1.62796C7.9407 2.08541 6.19892 2.9563 4.71201 4.17642Z" :fill="status.color"/>
    </g>

    <g v-if="!status || status.id == 0">
      <path d="M5.71201 4.8085C3.87257 6.25758 2.48354 8.20027 1.70728 10.4095C-0.183066 16.4446 3.70965 23.684 5.0959 26.2884C5.42623 26.8712 5.68483 27.4918 5.86604 28.1368C6.26762 29.4988 6.37736 30.9302 6.1881 32.3375C5.82669 35.0016 5.25548 37.6329 4.47979 40.207C3.60944 43.3999 3.1946 46.6998 3.24756 50.0088C3.49437 54.1627 4.49059 58.2377 6.1881 62.037C6.94424 64.0533 8.87659 69.2063 10.0388 69.0382C10.5149 68.9402 10.7109 67.932 10.963 65.4956C11.4811 60.2446 11.187 58.4943 11.4251 55.6938C11.4251 55.1057 12.0412 48.8605 15.4298 44.5898C15.7999 44.0346 16.2763 43.5583 16.8315 43.1882C17.3867 42.8181 18.0096 42.5616 18.6644 42.4334C19.1307 42.3572 19.6081 42.3869 20.0613 42.5202C20.5146 42.6535 20.9321 42.887 21.2829 43.2035C21.6049 43.5676 21.801 44.0577 21.7449 46.13C21.7791 47.4209 21.6759 48.7117 21.4369 49.9808C20.8488 52.7813 19.9246 53.1033 18.9724 55.6938C18.5103 56.968 18.3563 59.5725 18.0483 64.7815C17.8942 67.4559 17.8942 68.6462 18.5103 68.9822C19.1265 69.3183 20.7088 68.016 21.1288 67.582C23.0892 65.6636 23.0472 64.0393 24.3774 61.4208C25.7077 58.8024 26.1838 59.5025 28.6902 55.7218C30.0019 53.8193 31.0874 51.7705 31.9248 49.6167C32.849 47.1662 32.821 45.934 33.4651 41.6072C33.842 38.062 34.5117 34.554 35.4675 31.1193C35.8735 29.9011 36.1676 29.7891 37.8059 26.2324C39.0241 23.5999 39.6402 22.2837 39.9483 21.4155C41.0475 18.7284 41.5261 15.8278 41.3485 12.93C41.1849 10.8721 40.5986 8.86998 39.6262 7.04891C38.629 4.84003 36.8832 3.05462 34.6973 2.00799C33.228 1.43625 31.6478 1.20641 30.0765 1.33587C28.8042 1.33422 27.5455 1.59645 26.3798 2.10601C24.6995 2.90416 24.7135 3.6883 23.4533 3.96835C22.6551 4.13638 22.235 3.96835 19.7426 3.18421C17.5862 2.55409 16.494 2.23203 15.7378 2.10601C14.0993 1.85458 12.4286 1.9068 10.8089 2.26004C8.9407 2.71749 7.19892 3.58838 5.71201 4.8085Z" fill="#DEE1E9"/>
      <path d="M39.9779 21.3875C39.7119 22.1296 39.2358 23.1798 38.3536 25.0982L32.1085 26.9325C32.1085 26.9325 28.1038 27.5486 25.6393 26.0223C23.1749 24.4961 18.0779 26.0223 18.0779 26.0223C18.0779 26.0223 10.9927 26.9325 8.68223 26.9325C7.11226 26.8241 5.62811 26.176 4.48146 25.0982C2.82916 21.8916 0.0846571 15.7165 1.68095 10.4375C2.44383 8.1905 3.8393 6.21156 5.69968 4.73846C7.18568 3.54294 8.92365 2.70029 10.7826 2.27401C12.4013 1.91328 14.0735 1.86102 15.7115 2.11998C17.065 2.40641 18.4019 2.76634 19.7162 3.19817C22.2087 3.92631 22.6288 4.15035 23.4269 3.96832C24.6872 3.68827 24.6872 2.91813 26.3535 2.11998C27.5171 1.60404 28.7772 1.34152 30.0501 1.34984C31.6173 1.19553 33.199 1.40642 34.671 1.96595C36.9152 2.99805 38.714 4.80185 39.7399 7.04888C40.6906 8.87189 41.2667 10.8668 41.4342 12.916C41.5929 15.8134 41.0951 18.7093 39.9779 21.3875Z" fill="white" stroke="#DEE1E9" stroke-miterlimit="10"/>
    </g>

  </g>
</template>

<script>
export default {
  name: "Tooth84",
  props: {
    status: {}
  },
}
</script>

<style scoped>

</style>
