<template>
  <div class="position-relative d-time-cell">
    <!--    <div v-if="showTime"-->
    <!--         :class="classObject"-->
    <!--         class="time-marker">-->
    <!--      {{ time }}-->
    <!--    </div>-->
    <div v-if="isShown"
         class="d-calendar__step position-relative"
         :data-time="time"
         :data-date="date"
         :data-doctor="doctor"
         :data-chair="chair"
         :class="{ highlight: highlight, moveMode: (recordToMove || recordToCopy) }"
         :style="{ height: timeHeight + 'px' }">
<!--      <div class="add-new-visit align-items-center justify-content-center" v-if="rights.includes('add_records')">-->
<!--        <span>{{ $t('add_new_record') }}</span>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex"

export default {
  name: "DTimeCell",
  props: {
    time: String,
    date: String,
    doctor: Number,
    chair: Number,
    highlight: Boolean,
    showTime: Boolean,
    timeHeight: {
      type: Number,
      default: 30
    },
    showAddNewButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      recordToMove: state => state.calendar.recordToMove,
      recordToCopy: state => state.calendar.recordToCopy,
    }),
    classObject() {
      return {
        // 'invisible': !this.time.includes(':00') && !this.time.includes(':30')
        'invisible': false
      }
    },
    isShown: function() {
      //return this.time !== '21:00'
      return true
    },
  }
}
</script>

<style scoped lang="scss">
.d-time-cell {
  z-index: 1;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  &.blank {
    .d-calendar__step {
      background-color: #F2F6FA;
      border-bottom: none;
    }
  }
}
.d-calendar__step {
  background-color: white;
  border-bottom: 1px dashed #E5E5E5;
  cursor: pointer;
  &:hover {
    .add-new-visit {
      display: flex;
    }
    background-color: $light-blue2;
  }
  &.moveMode {
    &:hover {
      cursor: move;
      background-color: $calendar-border-color;
    }
  }
}
.highlight {
  background-color: #F1FCFF;
}
.add-new-visit {
  height: calc(100% - 6px);
  display: none;
  width: calc(100% - 6px);
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
  margin: 3px;
  //background: white;
  border-radius: 3px;
  border-width: thin;
  border-style: dashed;
  color: #0787C9;
  font-weight: bold;
  text-align: center;
}
</style>
