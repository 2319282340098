<template>
  <b-dropdown right
              variant="link"
              no-caret
              class="d-inline-block align-middle">
    <template #button-content>
      <a class="pointer position-relative"
         :class="{ 'ml-3': view === 'patientCard' }"
         :style="topPosition !== null ? `top: ${topPosition}px` : (view === 'patientCard' ? 'top: -3px' : 'top: -2px')"
         v-b-tooltip.hover
         :title="$t('write_in_messenger')">
        <WhatsappSvg :class="iconClass" style="width: 16px;"/></a>
    </template>
    <b-dropdown-item v-if="wazzupEnabled && patientId" @click="wazzupIframe('whatsapp')">
      Whatsapp
    </b-dropdown-item>
    <b-dropdown-item v-if="wazzupEnabled && patientId" @click="wazzupIframe('telegram')">
      Telegram
    </b-dropdown-item>
    <b-dropdown-item v-if="!wazzupEnabled || !patientId" target="_blank" :href="`https://wa.me/${formatPhone(phone, false)}`">
      Whatsapp
    </b-dropdown-item>
    <b-dropdown-item v-if="!wazzupEnabled || !patientId" target="_blank" :href="`https://t.me/${formatPhone(phone)}`">
      Telegram
    </b-dropdown-item>
    <b-dropdown-item v-if="jetAPILink" @click="launchJetAPISidebar">
      Whatsapp ({{ $t('chat_panel') }})
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { WhatsappService } from "@/services/api.service"
import WhatsappSvg from "@/assets/svg-vue/crm/whatsapp.svg"
import {mapGetters, mapState} from "vuex"
import {formatPhone} from "@/extensions/filters/filters"

export default {
  name: "WhatsappTelegramIcon",
  components: {
    WhatsappSvg
  },
  props: {
    patientId: {},
    phone: {},
    view: {
      type: String,
      default: 'patientCard'
    },
    topPosition: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      wazzupEnabled: state => state.crm.wazzupEnabled,
    }),
    ...mapGetters(['getPPOAttribute']),
    iconClass() {
      return this.view === 'crm' ? 'svg-light' : 'svg-primary'
    },
    jetAPILink() {
      return this.getPPOAttribute('jetapi_iframe_url')
      // const url = this.getPPOAttribute('jetapi_iframe_url')
      // if(!url) return null
      // return `${url}&phone=${this.formatPhone(this.phone, false)}`
    }
  },
  methods: {
    formatPhone,
    async wazzupIframe(type = 'whatsapp') {
      let res = await WhatsappService.createIframe(this.patientId, type)
      if(res.data.url) {
        this.$store.commit('setWazzupIframeUrl', res.data.url)
        await this.$nextTick()
        this.$bvModal.show('wazzup-iframe-modal')
        return
      }
      if(res.data.error) {
        this.$noty.error(res.data.error)
      }
    },
    launchJetAPISidebar() {
      this.$store.commit('setPhoneForJetApi', this.formatPhone(this.phone, false, true))
      this.$store.commit('setRightPaneOpened', true)
    }
  }
}
</script>
